import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import startCase from "lodash/startCase";
import Panel from "../../../../components/Panel";
import { Range } from "../../../../interfaces/Lab";
import { useAppSelector } from "../../../../store/hooks";
import { LabTestGroup, Test } from "../../../../interfaces/LabInterfaces";
import hasOwnProperty from "../../../../helpers/object";

const rangeFormatter = (range: Range): JSX.Element => {
  if (hasOwnProperty(range, "other")) {
    return (
      <>
        {range.other.label && <p>{startCase(range.other.label)}</p>}
        <span>{`Min: ${range.other.min || ""} Max: ${range.other.min || ""}`}</span>
      </>
    );
  }
  return (
    <div>
      {Object.keys(range || {}).map((item) => (
        <>
          {item && <p>{startCase(item)}</p>}
          <span>{`Min: ${range[item]?.min || ""} Max: ${range[item]?.max || ""}`}</span>
        </>
      ))}
    </div>
  );
};

const HeadRow = () => (
  <TableRow>
    <TableCell>Test name</TableCell>
    <TableCell>Unit</TableCell>
    <TableCell>Ranges</TableCell>
    <TableCell>Methods</TableCell>
    <TableCell>Default value/options</TableCell>
  </TableRow>
);

const Rows = ({ row, subTestOffset = 2 }: { row: Test; subTestOffset?: number }) => (
  <>
    <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row" sx={{ pl: subTestOffset }}>
        {row.name}
      </TableCell>
      <TableCell>{row.unit || ""}</TableCell>
      <TableCell>{rangeFormatter(row.ranges)}</TableCell>
      <TableCell>{row.methods || ""}</TableCell>
      <TableCell>{row.formData?.reading || ""}</TableCell>
    </TableRow>
    {row.subTests?.map((sub) => (
      <Rows row={sub} key={sub.id} subTestOffset={subTestOffset + 2} />
    ))}
  </>
);

interface Props {
  onEdit: () => void;
  onClose: () => void;
}

const LabTestGroupDetailsPanel = ({ onClose, onEdit }: Props): JSX.Element => {
  const labGroup = useAppSelector((state) => state.labSettings.draftGroup as LabTestGroup);

  return (
    <Panel
      onClose={onClose}
      title={labGroup?.name || ""}
      wrapperStyle={{ minWidth: 900 }}
      editButton={
        <Button onClick={onEdit} variant="contained">
          Edit
        </Button>
      }
    >
      {labGroup && (
        <Box p={4} py={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <HeadRow />
              </TableHead>
              <TableBody>
                {labGroup.labTests?.map((row) => (
                  <Rows row={row} key={row.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Panel>
  );
};

export default LabTestGroupDetailsPanel;

import produce from 'immer';
import { ReducerBuilder } from './ReducerBuilder';
import * as feedActions from '../actions/feed';

const INITIAL_STATE = {
  collection: [],
  draft: null
};

const fetchFeeds = (state, feeds) =>
  produce(state, (draft) => {
    draft.collection = feeds;
  });

const fetchFeed = (state, feed) =>
  produce(state, (draft) => {
    const foundIndex = draft.collection.findIndex((existingFeed) => existingFeed.id === feed.id);
    if (foundIndex === -1) {
      draft.collection.unshift(feed);
    } else {
      draft.collection[foundIndex] = feed;
    }
  });

const initDraft = (state, payload) =>
  produce(state, (draft) => {
    draft.draft = payload;
  });

const clearDraft = (state) =>
  produce(state, (draft) => {
    draft.draft = null;
  });

const updateDraft = (state, payload) =>
  produce(state, (draft) => {
    draft.draft = payload;
  });

const deleteFeed = (state, payload) =>
  produce(state, (draft) => {
    draft.collection = draft.collection.filter((feed) => feed.id !== payload);
  });

const deactivateFeed = (state, payload) =>
  produce(state, (draft) => {
    const foundIndex = draft.collection.findIndex((existingFeed) => existingFeed.id === payload.id);
    draft.collection[foundIndex]['deactivated'] = payload.deactivated;
  });

const publishFeed = (state, payload) =>
  produce(state, (draft) => {
    const foundIndex = draft.collection.findIndex((existingFeed) => existingFeed.id === payload.id);

    if (foundIndex !== -1) {
      draft.collection[foundIndex]['published'] = payload.published;
    } else {
      draft.collection.push(payload);
    }
  });

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(feedActions.Type.INIT_DRAFT, initDraft)
  .mapAction(feedActions.Type.CLEAR_DRAFT, clearDraft)
  .mapAction(feedActions.Type.UPDATE_DRAFT, updateDraft)
  .mapAction(feedActions.Type.DELETE_FEED, deleteFeed)
  .mapAction(feedActions.Type.FETCH_FEED, fetchFeed)
  .mapAction(feedActions.Type.FETCH_FEEDS, fetchFeeds)
  .mapAction(feedActions.Type.PUBLISH_FEED, publishFeed)
  .mapAction(feedActions.Type.DEACTIVE_FEED, deactivateFeed)
  .build();

export default reducer;

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Tabs, Tab } from "@mui/material";
import { resourceCentreActions, ResourceCentreActionsType } from "../../actions";
import { AddonsSettings } from "./Settings/AddonsSettings";
import HmisSettings from "./Settings/HmisSettings";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import SectionHeading from "./SectionHeading";

interface SettingsProps {
  resourceCentre: ResourceCentre;
  actions: ResourceCentreActionsType;
}

export const DEFAULT_SETTINGS = {
  settings: {
    clientSettings: {
      enablePictureUpload: false
    },
    assessmentSettings: {
      attachmentCount: 3,
      components: {
        attachments: true
      }
    },
    hmisSettings: {
      username: "",
      password: "",
      orgUnit: "",
      hmisFormDataIds: [],
      government: false
    }
  }
};

export type SettingsType = typeof DEFAULT_SETTINGS;

enum TAB_ITEMS {
  ADDONS = "Addons",
  HMIS = "HMIS"
}

const Settings: React.FC<SettingsProps> = ({ resourceCentre, actions }) => {
  const { id } = resourceCentre;
  const [tab, setTab] = React.useState(TAB_ITEMS.ADDONS);

  const { enablePictureUpload } = resourceCentre.settings.clientSettings;

  const { hmisSettings } = resourceCentre.settings;

  const { attachments } = resourceCentre.settings.assessmentSettings.components;

  const attachmentCount = resourceCentre.settings.assessmentSettings?.attachmentCount ?? 3;

  const [state, setState] = React.useState(DEFAULT_SETTINGS);

  React.useEffect(() => {
    setState({
      ...resourceCentre,
      settings: {
        ...resourceCentre.settings,
        clientSettings: {
          enablePictureUpload
        },
        assessmentSettings: {
          attachmentCount,
          components: {
            ...resourceCentre.settings.assessmentSettings.components,
            attachments
          }
        },
        hmisSettings: {
          username: hmisSettings?.username,
          password: hmisSettings?.password,
          orgUnit: hmisSettings?.orgUnit,
          hmisFormDataIds: hmisSettings?.hmisFormDataIds,
          government: hmisSettings?.government
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCentre]);

  return (
    <Box width="100%">
      <SectionHeading name="Super Admin Settings" />
      <Box sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>
        <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <Tab value={TAB_ITEMS.ADDONS} label={TAB_ITEMS.ADDONS} />
          <Tab value={TAB_ITEMS.HMIS} label={TAB_ITEMS.HMIS} />
        </Tabs>
      </Box>
      <Box pt={2}>
        {tab === TAB_ITEMS.ADDONS && (
          <AddonsSettings
            id={id}
            initialSubscriptions={resourceCentre?.subscriptions}
            state={state}
            setState={setState}
            actions={actions}
          />
        )}
        {tab === TAB_ITEMS.HMIS && <HmisSettings state={state} actions={actions} />}
      </Box>
    </Box>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch)
}))(Settings);

import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BillIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 200 200">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4167 33.3333C33.4167 24.1667 40.8333 16.6667 50 16.6667H116.667L166.667 66.6667V166.667C166.667 175.833 159.167 183.333 150 183.333H49.9167C40.75 183.333 33.3333 175.833 33.3333 166.667L33.4167 33.3333ZM108.333 29.1667V75H154.167L108.333 29.1667ZM98.808 106.752H136.152V96H63V106.752H85.368V117.984C85.368 122.848 82.424 125.28 76.536 125.28H69.24V135.84L88.248 160.416H104.568L84.888 135.264H85.656C89.048 135.072 91.864 133.792 94.104 131.424V131.52V136.32H105.048V131.904C105.048 129.6 105.656 127.84 106.872 126.624C108.152 125.408 109.784 124.8 111.768 124.8C113.752 124.8 115.352 125.536 116.568 127.008C117.784 128.416 118.392 130.208 118.392 132.384C118.392 135.264 117.656 137.408 116.184 138.816C114.712 140.224 112.856 140.928 110.616 140.928C109.08 140.928 107.448 140.64 105.72 140.064V151.584C107.448 152.224 109.816 152.544 112.824 152.544C118.328 152.544 122.872 150.688 126.456 146.976C130.104 143.2 131.928 138.144 131.928 131.808C131.928 126.496 130.104 121.984 126.456 118.272C122.872 114.496 117.976 112.608 111.768 112.608C106.392 112.608 102.072 114.432 98.808 118.08V106.752Z"
      />
    </SvgIcon>
  );
}

export default BillIcon;

import { TextField, TextFieldProps } from "@mui/material";
import * as React from "react";

const DELIMITER_START = "{{";
const DELIMITER_END = "}}";

export interface Placeholder {
  name: string;
  reference: string;
}

interface IndexedValue {
  startPos: number;
  endPos: number | null;
  reference: string | null;
  value: string | null;
}

export interface InputValue {
  rootValue: string;
  references: Array<string>;
}

interface PlaceholderInputProps {
  id: string;
  inputValue: InputValue;
  placeholderRenderer: (onNodeAdd: (placeholder: Placeholder) => void) => React.ReactNode;
  onUpdate: (v: InputValue) => void;
}

function createIndexes(rootValue: string, references: Array<string>): Array<IndexedValue> {
  const indexedVariablePositions: Array<IndexedValue> = [];
  let pos = 0;
  for (let i = 1; i < rootValue.length - 1; i += 1) {
    if (`${rootValue[i]}${rootValue[i - 1]}` === DELIMITER_START)
      indexedVariablePositions.push({
        startPos: i - 1,
        endPos: null,
        reference: null,
        value: null
      });
    if (`${rootValue[i]}${rootValue[i + 1]}` === DELIMITER_END) {
      const lastEl = indexedVariablePositions[indexedVariablePositions.length - 1];
      indexedVariablePositions[indexedVariablePositions.length - 1] = {
        ...lastEl,
        endPos: i + 1,
        value: rootValue.substring(lastEl.startPos + 2, i),
        reference: references[pos]
      };
      pos += 1;
    }
  }
  return indexedVariablePositions;
}

function isCursorInPlaceholder(indexedValues: Array<IndexedValue>, currentCursorPosition: number) {
  return indexedValues.some(
    (el) => currentCursorPosition > el.startPos && currentCursorPosition <= el.endPos
  );
}

function getPlaceholderInOrAroundCursorDelete(
  indexedValues: Array<IndexedValue>,
  currentCursorPosition: number
) {
  return indexedValues.find(
    (el) => currentCursorPosition <= el.endPos + 1 && currentCursorPosition > el.startPos
  );
}

function getPreviousReferenceIndex(
  indexedValues: Array<IndexedValue>,
  currentCursorPosition: number
) {
  const filtered = indexedValues.filter((el) => el.startPos < currentCursorPosition);
  const previousIndex = [...filtered].sort((a, b) => a.startPos - b.startPos).pop();
  if (previousIndex) {
    return indexedValues.findIndex((el) => el.reference === previousIndex.reference);
  }
  return 0;
}

function removeStrBetweenIndicies(str: string, start: number, end: number) {
  return str.substring(0, start) + str.substring(end);
}

export function checkNumberEvalValidity(rootValue: string): string | undefined {
  const stripped = rootValue.replace(/{{.*?}}/g, "1");
  try {
    // eslint-disable-next-line
    return eval(stripped);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error while processing eval");
  }
  return undefined;
}

export function PlaceholderInput(
  props: PlaceholderInputProps & Omit<TextFieldProps, "value" | "onChange" | "onKeyDown" | "id">
): JSX.Element {
  const { id, inputValue, placeholderRenderer, onUpdate, ...textFieldProps } = props;

  const onNodeAdd = (placeholder: Placeholder) => {
    const indexedValues = createIndexes(inputValue.rootValue, inputValue.references);
    const inputContainer = document.getElementById(id) as HTMLInputElement;
    const currentCursorPosition = inputContainer.selectionStart;

    // exit when trying to add a placeholder inside another placeholder
    if (isCursorInPlaceholder(indexedValues, currentCursorPosition)) {
      return;
    }
    const tokenizedValue = DELIMITER_START + placeholder.name + DELIMITER_END;

    // newvalue = split old value by cursor position,
    // then add new placeholder in cursor position then join with rest
    const updatedRootValue =
      inputValue.rootValue.substring(0, currentCursorPosition) +
      tokenizedValue +
      inputValue.rootValue.substring(currentCursorPosition);
    const updatedReferences = [...inputValue.references];
    const previousReferenceIndex = getPreviousReferenceIndex(indexedValues, currentCursorPosition);

    // just like rootvalue above, insert new placeholder reference in appropriate locaiton
    updatedReferences.splice(previousReferenceIndex + 1, 0, placeholder.reference);

    onUpdate({ rootValue: updatedRootValue, references: updatedReferences });

    // if placeholder is added at the end, focus the input so that user can continue typing
    // @TODO -> focus at particular index
    if (currentCursorPosition >= inputValue.rootValue.length) {
      inputContainer.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const eventTarget = e.target as HTMLInputElement;
    const indexedValues = createIndexes(inputValue.rootValue, inputValue.references);
    if (e.key === "Backspace") {
      const placeholderIndex = getPlaceholderInOrAroundCursorDelete(
        indexedValues,
        eventTarget.selectionStart
      );
      if (placeholderIndex) {
        const updatedRootValue = removeStrBetweenIndicies(
          inputValue.rootValue,
          placeholderIndex.startPos,
          placeholderIndex.endPos + 1
        );
        const updatedReferences = inputValue.references.filter(
          (el) => el !== placeholderIndex.reference
        );
        onUpdate({ rootValue: updatedRootValue, references: updatedReferences });

        // stop passing this event to other event handlers like onchange
        e.preventDefault();
      }
    }
  };
  const errored = inputValue.rootValue && !checkNumberEvalValidity(inputValue.rootValue);

  return (
    <div>
      <TextField
        id={id}
        value={inputValue.rootValue}
        onChange={(e) => onUpdate({ ...inputValue, rootValue: e.target.value })}
        onKeyDown={handleKeyDown}
        error={!!errored}
        helperText={errored && "Please enter formula in correct format"}
        // eslint-disable-next-line
        {...textFieldProps}
        variant="outlined"
      />
      {placeholderRenderer(onNodeAdd)}
    </div>
  );
}

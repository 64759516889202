/* eslint-disable import/no-extraneous-dependencies */
import { Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import * as moment from "moment-timezone";
import { push } from "connected-react-router";
import PersonIcon from "@mui/icons-material/Person";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { ref, set, update } from "firebase/database";
import React from "react";
import Panel from "../../components/Panel";
import { db } from "../../../firebase/config";
import { createPublicClient, getPublicClientDetail } from "../../api/client";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import { RootState } from "../../store";
import CreateClientConfirmation from "./CreateClientConfirmation";
import { UserProfile, sendChatNotification } from "../../api/user";
import { ChatThread } from "../../hooks/useGetFirebaseChat";
import SenderThread from "../../components/Messages/MessageBox";
import { notificationAdd } from "../../actions/notification";
import OkhatiDialog from "../../components/Dialog/Dialog";
import ClientActionButtons from "./ClientActionButtons";

export const isSameDate = (previousDate: string, todaysDate: string): moment.Moment =>
  moment(new Date(todaysDate), "YYYY/MM/DD").isSame(
    moment(new Date(previousDate), "YYYY/MM/DD"),
    "date"
  );

interface MessageThreadProps {
  onClose: () => void;
  selectedChat: ChatThread;
  profile: UserProfile;
  resourceCentreId: number;
}

export enum NotificationTypes {
  CHAT_MESSAGE = "chat"
}

const MessageThread = ({
  onClose,
  selectedChat,
  profile,
  resourceCentreId
}: MessageThreadProps): JSX.Element => {
  const [message, setMessage] = React.useState("");
  const [client, setClient] = React.useState([]);
  const lastDivRef = React.useRef<HTMLDivElement>(null);
  const [showClientInfo, setShowClientInfo] = React.useState(false);
  const [createClient, setCreateClient] = React.useState(false);
  const [createBooking, setCreateBooking] = React.useState(false);

  const resourceCentreName = useSelector(
    (state: RootState) => state.userContext?.resourceCentre?.name
  );
  const [openChatCloseModal, setOpenChatCloseModal] = React.useState(false);
  const dispatch = useDispatch();

  const clientCreate = async () => {
    try {
      const clientInfo = await createPublicClient({ clientId: profile.authenticableId });
      setClient([clientInfo]);
      if (createBooking) {
        dispatch(push(`/calendar/?client=${clientInfo.id}`));
      } else {
        setShowClientInfo(true);
      }
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to create client.",
          autoTimeout: true
        })
      );
    }
  };
  const logo = useSelector(
    (state: RootState) => state.userContext.resourceCentre?.resourceCentreLogo?.s3ResourceURL
  );
  const scrollToBottom = () => {
    lastDivRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const getClientDetails = async () => {
    try {
      const clientInfo = await getPublicClientDetail({ id: profile.authenticableId });
      setClient(clientInfo);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to fetch client info",
          autoTimeout: true
        })
      );
    }
  };
  React.useEffect(() => {
    scrollToBottom();
    getClientDetails();
  }, []);

  React.useEffect(() => {
    selectedChat.messages.forEach((msg) => {
      if (msg.from !== resourceCentreId && !msg.read) {
        update(ref(db, `chats/${selectedChat.chatKey}/chatItems/${msg.timestamp}`), { read: true });
      }
    });
  }, [selectedChat, resourceCentreId]);

  const sendMessage = async (enteredMessage) => {
    try {
      if (!enteredMessage?.trim().length) {
        return;
      }
      const timestamp = Date.now();
      await set(ref(db, `chats/${selectedChat.chatKey}/chatItems/${timestamp}`), {
        from: selectedChat.resourceCentreId || null,
        message: enteredMessage,
        read: false,
        timestamp
      });
      scrollToBottom();
      setMessage("");
      update(ref(db, `chats/${selectedChat.chatKey}`), {
        lastMsgTimestamp: timestamp
      });
      sendChatNotification({
        message: enteredMessage,
        title: resourceCentreName,
        userId: profile?.id,
        notificationType: NotificationTypes.CHAT_MESSAGE
      });
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to send mesage to user",
          autoTimeout: true
        })
      );
    }
  };

  const closeCase = async () => {
    try {
      await update(ref(db, `chats/${selectedChat.chatKey}`), {
        chatClosedBy: resourceCentreId
      });
      setOpenChatCloseModal(false);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to close the case for user.",
          autoTimeout: true
        })
      );
    }
  };

  useHotkeys(
    "enter",
    () => {
      sendMessage(message);
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"]
    }
  );

  return (
    <>
      <Panel
        onClose={onClose}
        title={
          <Typography pb={1} fontSize={18} fontWeight={600}>
            {profile?.name || "Unknown User"}
          </Typography>
        }
        footer={
          <Box
            display="flex"
            width="100%"
            position="absolute"
            bottom={0}
            left={0}
            height="50px"
            alignItems="center"
            px={1}
          >
            {!selectedChat.chatClosedBy && (
              <Button
                variant="contained"
                sx={{ paddingX: 1 }}
                onClick={() => setOpenChatCloseModal(true)}
              >
                Close Case
              </Button>
            )}
            {client?.length ? (
              <Tooltip title="View Client">
                <Button
                  variant="contained"
                  sx={{ marginX: 1 }}
                  onClick={() => setShowClientInfo(true)}
                >
                  <PersonIcon />
                </Button>
              </Tooltip>
            ) : (
              <ClientActionButtons
                onClientCreate={() => setCreateClient(true)}
                onBookingCreate={() => {
                  setCreateClient(true);
                  setCreateBooking(true);
                }}
              />
            )}
            <Box display="flex" flex={1} alignItems="center">
              <TextField
                disabled={!!selectedChat.chatClosedBy}
                variant="outlined"
                fullWidth
                multiline
                onChange={(e) => {
                  if (!e.target.value?.trim()) return;
                  setMessage(e.target.value);
                }}
                value={message}
                size="small"
              />
              {!selectedChat.chatClosedBy && (
                <SendOutlinedIcon
                  sx={{ marginX: 1, cursor: "pointer" }}
                  width="16px"
                  onClick={() => sendMessage(message)}
                />
              )}
            </Box>
          </Box>
        }
      >
        <Box paddingX="16px" height="calc(100% - 100px)" style={{ overflowY: "auto" }}>
          {selectedChat?.messages?.map((userMessage, i) => {
            const previousMessage = selectedChat.messages[i - 1];
            return (
              <>
                {!isSameDate(previousMessage?.timestamp, userMessage.timestamp) && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flex={1}
                    mt={2}
                    mb={1}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "#E9EAED",
                        borderRadius: "5px",
                        padding: 0.5,
                        fontWeight: "bold",
                        fontSize: "14px"
                      }}
                    >
                      {moment(new Date(userMessage.timestamp)).format("dddd, D MMM")}
                    </Typography>
                  </Box>
                )}

                <SenderThread
                  key={userMessage.timestamp}
                  message={userMessage.message}
                  timestamp={userMessage.timestamp}
                  photoURL={logo}
                  displayName={profile?.name}
                  alignMessage={userMessage.from === resourceCentreId ? "right" : "left"}
                />

                <div ref={lastDivRef} />
              </>
            );
          })}
        </Box>
      </Panel>
      {showClientInfo && client.length > 0 && (
        <ClientInfo id={profile.authenticableId} handleViewClose={() => setShowClientInfo(false)} />
      )}
      {openChatCloseModal && (
        <OkhatiDialog
          open={openChatCloseModal}
          nextButtonText="Confirm"
          next={closeCase}
          cancel={() => setOpenChatCloseModal(false)}
          title="Confirm Case Close"
          description="Are you sure you want to close the case? Please click 'Confirm' to proceed."
          readMode={false}
        />
      )}
      <CreateClientConfirmation
        open={createClient}
        setOpen={setCreateClient}
        client={profile}
        onConfirm={clientCreate}
      />
    </>
  );
};

export default MessageThread;

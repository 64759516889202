import React from "react";
import { Box, Typography } from "@mui/material";
import { scaleFont } from "../LabPrintFunctions";
import { InfoFieldProps } from "./LabPrintInfofield";

const LabGovPrintInfoField: React.FC<InfoFieldProps> = ({ label, data }) => {
  const baseLabelStyle = {
    fontSize: scaleFont("0.28cm", 1),
    color: "black",
    fontWeight: 400,
    minWidth: "2.4cm"
  };
  const baseValueStyle = {
    fontSize: scaleFont("0.28cm", 1),
    fontWeight: 500
  };
  return (
    <Box display="flex">
      <Typography sx={{ ...baseLabelStyle }}>{label}</Typography>
      <Typography sx={{ ...baseValueStyle }}>{data}</Typography>
    </Box>
  );
};

export default LabGovPrintInfoField;

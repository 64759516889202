import "./styles.scss";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { Test } from "../../../../interfaces/Lab";
import {
  getLabTestGroups,
  getLabTests,
  getLabTestGroupById,
  removeDraftGroup
} from "../../../../slices/labTestSettingsSlice";
import List from "../../../../components/List";
import LabTestGroupDetailsPanel from "./LabTestGroupDetailsPanel";
import LabTestGroupEditPanel from "./LabTestGroupEditPanel";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

export enum MODE {
  READ = "read",
  EDIT = "edit"
}

export default function SettingsV2(): JSX.Element {
  const dispatch = useAppDispatch();
  const [selectedGroupName, setSelectedGroupName] = React.useState("");
  const [mode, setMode] = React.useState<MODE | null>(null);
  const labTestGroups: Array<Test> = useAppSelector((state) => state.labSettings.labTestGroups);

  React.useEffect(() => {
    dispatch(getLabTestGroups());
    dispatch(getLabTests());
    return () => {
      dispatch(removeDraftGroup());
    };
  }, [dispatch]);

  return (
    <Box height="100%">
      <Box className="labTestGroupList">
        <List<Test>
          defaultSortOrder={1}
          title="Lab test group"
          rowHeight={50}
          hideCreateButton
          defaultSortColumn="name"
          isResponsive
          columns={[
            {
              key: "name",
              label: "Name",
              formatter: ({ name }) => <Typography>{name}</Typography>,
              sortable: true
            },
            {
              key: "type",
              label: "Type",
              sortable: true,
              formatter: ({ type }) => <Typography>{type}</Typography>
            },
            {
              key: "category",
              label: "Category",
              formatter: ({ category }) => <Typography>{category}</Typography>
            }
          ]}
          data={labTestGroups}
          activeRow={null}
          onRowClick={(row) => {
            setSelectedGroupName(row.name);
            setMode(MODE.READ);
            dispatch(getLabTestGroupById(row.id));
          }}
          automation="labTestGroupList"
        />
        {mode === MODE.READ && (
          <LabTestGroupDetailsPanel
            onEdit={() => setMode(MODE.EDIT)}
            onClose={() => setMode(null)}
          />
        )}
        {mode === MODE.EDIT && (
          <LabTestGroupEditPanel groupName={selectedGroupName} onClose={() => setMode("read")} />
        )}
      </Box>
    </Box>
  );
}

import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { push } from "connected-react-router";
import queryString from "query-string";
import React from "react";
import { connect, useDispatch } from "react-redux";
import * as labTest from "../../actions/labTest";
import { batchFinaliseLabTestRecords, getLabTestsRecords } from "../../actions/labTest";
import { navigateRemoveModal, showDialog } from "../../actions/navigation";
import * as NotificationActions from "../../actions/notification";
import PageControl from "../../components/PageControl";
import { tl } from "../../components/translate";
import classNames from "../../helpers/classNames";
import { LabRecord, LabStatuses } from "../../interfaces/Lab";
import { IThunkDispatch, RootState } from "../../store";
import Can from "../Policy/Can";
import LabEntry from "./EntryV2";
import styles from "./Lab.module.css";
import LabDetails from "./LabDetails";
import "./LabList.scss";
import ListTab from "./ListTab";
import { LabRecordType } from "./index";

export enum LabTestStatusTypes {
  ORDERED = "Ordered",
  SAMPLE_TAKEN = "Sample Taken",
  INCOMPLETE_RESULT = "Incomplete Result",
  PENDING_APPROVAL = "Pending Approval",
  RESULT_READY = "Result Ready",
  DISPATCHED = "Dispatched",
  CANCELED = "Canceled"
}

const useStyles = makeStyles((theme) => ({
  filtersRoot: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
      whiteSpace: "pre"
    }
  }
}));

function TabPanel(props) {
  const { children, value, activeTab, ...other } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div role="tabpanel" hidden={value !== activeTab} {...other}>
      {value === activeTab && (
        <Box
          className={
            [
              LabTestStatusTypes.ORDERED,
              LabTestStatusTypes.SAMPLE_TAKEN,
              LabTestStatusTypes.INCOMPLETE_RESULT
            ].includes(activeTab)
              ? styles.nonPaginatedListViewHeight
              : styles.paginatedListViewHeight
          }
        >
          {children}
        </Box>
      )}
    </div>
  );
}

interface LabListInterface {
  navigateTo: (url) => void;
  batchFinalise: (ids: number[], filters, requireApproval?) => void;
  labId: number;
  labList?: [];
  labTestRecords: Array<LabRecord>;
  getLabsTestRecords: (filters) => void;
  total: number;
  mode?: string;
  location: { search: string };
  clientId?: number;
  match: unknown;
  permissionGroup: string;
  classNames?: unknown;
  labTabs?: Array<string>;
  batchDelete: (ids: Array<number>) => void;
  testId?: string;
  setTestId?: (id: string) => void;
  labRecordType: LabRecordType;
}
function LabList(props: LabListInterface): JSX.Element {
  const {
    navigateTo,
    labId,
    labTestRecords,
    getLabsTestRecords,
    total,
    mode,
    location,
    batchFinalise,
    clientId,
    permissionGroup,
    batchDelete,
    testId,
    setTestId,
    labRecordType
  } = props;
  const statusTab = queryString.parse(location.search)?.status || LabTestStatusTypes.ORDERED;
  const [labList, setLabList] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [multiSelectedItems, setMultiSelectedItems] = React.useState(null);
  const pageSize = 20;
  const classes = useStyles();
  const labTabs = [
    "all",
    "ordered",
    "sampleTaken",
    "incompleteResult",
    "pendingApproval",
    "resultReady",
    "dispatched",
    "canceled"
  ];
  const [editMode, setEditMode] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (labTestRecords) {
      setLabList(labTestRecords || []);
    }
  }, [labTestRecords]);

  React.useEffect(() => {
    if (labId) {
      const labItem = labList.find(({ id }) => labId === id);
      setSelectedItem(labItem);
    } else {
      setSelectedItem(null);
    }
  }, [labList, labId]);

  const getLabTestRecords = React.useCallback(
    ({
      _page,
      _pageSize,
      _status,
      _clientId,
      _testId,
      _labRecordType
    }: {
      _page: number;
      _pageSize: number;
      _status: string;
      _clientId: number;
      _testId: string;
      _labRecordType: string;
    }): void => {
      if (!window.location.pathname.includes("entry")) {
        getLabsTestRecords(
          _status !== "All"
            ? {
                page: _page,
                pageSize: _pageSize,
                status: _status,
                clientId: _clientId,
                testId: _testId,
                labRecordType: _labRecordType
              }
            : {
                page: _page,
                pageSize: _pageSize,
                clientId: _clientId,
                testId: _testId,
                labRecordType: _labRecordType
              }
        );
      }
    },
    [getLabsTestRecords]
  );

  function handleGetLabsTestRecords(forcePageReset?: boolean) {
    if (!window.location.pathname.includes("entry")) {
      const updatedPage = forcePageReset ? 1 : page;
      getLabsTestRecords(
        statusTab !== "All"
          ? {
              page: updatedPage,
              pageSize,
              status: statusTab,
              clientId,
              testId,
              labRecordType
            }
          : {
              page: updatedPage,
              pageSize,
              clientId,
              testId,
              labRecordType
            }
      );
    }
  }

  React.useEffect(() => {
    if (setTestId) setTestId("");
  }, [statusTab, setTestId]);

  React.useEffect(() => {
    getLabTestRecords({
      _page: 0,
      _pageSize: pageSize,
      _status: statusTab,
      _clientId: clientId,
      _testId: testId || undefined,
      _labRecordType: labRecordType
    });
    setPage(0);
  }, [clientId, testId, statusTab, getLabTestRecords, labRecordType]);

  const handleViewClose = () => {
    setSelectedItem(null);
    navigateTo(`/lab/labRecords/${labRecordType}?status=${statusTab}`);
  };

  const multiSelectedItemsCount = multiSelectedItems?.length || 0;
  const showPageControl = true;

  const debouncedFunction = React.useRef<NodeJS.Timeout | null>(null);

  const onPageChange = (val) => {
    setPage(val);
    if (debouncedFunction.current !== null) {
      clearTimeout(debouncedFunction.current);
    }

    debouncedFunction.current = setTimeout(() => {
      getLabTestRecords({
        _page: val,
        _pageSize: pageSize,
        _status: statusTab,
        _clientId: clientId,
        _testId: testId,
        _labRecordType: labRecordType
      });
    }, 300);
  };

  return (
    <Can policyAccessKey="lab:listLab">
      <Box>
        <Box display="flex" justifyContent="space-between" className={styles.filterRoot}>
          <Box className={classes.filtersRoot}>
            {[
              "All",
              LabTestStatusTypes.ORDERED,
              LabTestStatusTypes.SAMPLE_TAKEN,
              LabTestStatusTypes.INCOMPLETE_RESULT,
              LabTestStatusTypes.PENDING_APPROVAL,
              LabTestStatusTypes.RESULT_READY,
              LabTestStatusTypes.DISPATCHED,
              LabTestStatusTypes.CANCELED
            ].map((f, index) => (
              <Typography
                key={f}
                className={classNames(styles.filter, {
                  [styles.active]: statusTab === f
                })}
                onClick={() => {
                  navigateTo(`/lab/labRecords/${labRecordType}?status=${f}`);
                }}
                component="span"
                sx={{ marginRight: "16px" }}
              >
                {tl(`lab.${labTabs[index]}`)}
              </Typography>
            ))}
          </Box>
        </Box>

        {[
          "All",
          LabTestStatusTypes.ORDERED,
          LabTestStatusTypes.SAMPLE_TAKEN,
          LabTestStatusTypes.INCOMPLETE_RESULT,
          LabTestStatusTypes.PENDING_APPROVAL,
          LabTestStatusTypes.RESULT_READY,
          LabTestStatusTypes.DISPATCHED,
          LabTestStatusTypes.CANCELED
        ].map((ltStatus) => (
          <TabPanel value={ltStatus} activeTab={statusTab} key={ltStatus}>
            <ListTab
              labRecordType={labRecordType}
              selectedItem={selectedItem}
              navigateTo={navigateTo}
              currentTab={statusTab}
              labTestsFiltered={
                ltStatus === "All"
                  ? labList.filter((lt) => lt?.client?.active)
                  : labList.filter((lt) => lt.status === ltStatus && lt?.client?.active)
              }
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(ltStatus === LabTestStatusTypes.INCOMPLETE_RESULT ||
              ltStatus === LabTestStatusTypes.CANCELED ||
              ltStatus === LabTestStatusTypes.PENDING_APPROVAL ||
              ltStatus === LabTestStatusTypes.RESULT_READY
                ? {
                    onMultiSelect:
                      permissionGroup === "resourceCentreReadOnlyEmployee"
                        ? null
                        : (selectedRowIDS) => {
                            setMultiSelectedItems(selectedRowIDS);
                          },
                    multiSelectContextHeader: (
                      <>
                        <Can policyAccessKey="lab:batchFinalize">
                          {ltStatus === LabTestStatusTypes.INCOMPLETE_RESULT && (
                            <Box display="flex" alignItems="flex-end" flexGrow={1}>
                              <Typography>
                                <Box display="flex" alignItems="center">
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      batchFinalise(multiSelectedItems, {
                                        page: 0,
                                        pageSize: 100,
                                        status: statusTab,
                                        clientId: undefined,
                                        testId: undefined
                                      })
                                    }
                                    data-testmation="batchFinalizeSelected"
                                  >
                                    Finalise Selected
                                  </Button>
                                  <Box marginLeft="16px">
                                    ({multiSelectedItemsCount} selected items)
                                  </Box>
                                </Box>
                              </Typography>
                            </Box>
                          )}
                        </Can>
                        {ltStatus === LabTestStatusTypes.CANCELED && (
                          <Box display="flex" alignItems="flex-end">
                            <Typography>
                              <Box display="flex" alignItems="center">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    dispatch(
                                      showDialog({
                                        title: "Confirmation",
                                        description:
                                          "Are you sure you want to delete the lab tests? This action cannot be undone!",
                                        next: () => {
                                          batchDelete(multiSelectedItems);
                                          dispatch(navigateRemoveModal("Dialog"));
                                        },
                                        onCancel: null
                                      })
                                    );
                                  }}
                                  data-testmation="batchFinalizeSelected"
                                >
                                  Delete Selected
                                </Button>
                                <Box marginLeft="16px">
                                  ({multiSelectedItemsCount} selected items)
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                        )}
                        {ltStatus === LabTestStatusTypes.RESULT_READY && (
                          <Box display="flex" alignItems="flex-end">
                            <Typography>
                              <Box display="flex" alignItems="center">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    dispatch(
                                      showDialog({
                                        title: "Confirmation",
                                        description:
                                          "Are you sure you want to dispatch the lab tests? This action cannot be undone!",
                                        next: async () => {
                                          await dispatch(
                                            labTest.batchDispatchLabTestRecords(
                                              multiSelectedItems || []
                                            )
                                          );
                                          dispatch(navigateRemoveModal("Dialog"));
                                          dispatch(
                                            push(
                                              `/lab/labRecords/all?status=${LabStatuses.DISPATCHED}`
                                            )
                                          );
                                        },
                                        onCancel: null
                                      })
                                    );
                                  }}
                                  data-testmation="batchDispatchSelected"
                                >
                                  Dispatch Selected
                                </Button>
                                <Box marginLeft="16px">
                                  ({multiSelectedItemsCount} selected items)
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                        )}
                        {ltStatus === LabTestStatusTypes.PENDING_APPROVAL && (
                          <Box display="flex" alignItems="flex-end">
                            <Typography>
                              <Box display="flex" alignItems="center">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    batchFinalise(
                                      multiSelectedItems,
                                      {
                                        page: 0,
                                        pageSize: 100,
                                        status: statusTab,
                                        clientId: undefined,
                                        testId: undefined
                                      },
                                      true
                                    )
                                  }
                                  data-testmation="batchApproveSelected"
                                >
                                  Approve Selected
                                </Button>
                                <Box marginLeft="16px">
                                  ({multiSelectedItemsCount} selected items)
                                </Box>
                              </Box>
                            </Typography>
                          </Box>
                        )}
                      </>
                    )
                  }
                : { onMultiSelect: null, multiSelectContextHeader: null })}
              handleGetLabsTestRecords={() => handleGetLabsTestRecords()}
              showPageControl={showPageControl}
            />
          </TabPanel>
        ))}
        {showPageControl && (
          <Box className="labNavigatePage">
            <PageControl
              page={page}
              pageSize={statusTab === LabStatuses.INCOMPLETE_RESULT ? 100 : pageSize}
              onSetPage={onPageChange}
              maximumDataCount={total}
              allowNextAtEnd={statusTab === LabStatuses.RESULT_READY}
              endAlertMessage="The lab records shown here are the records that were made ready in past 30 days. If you want to view older lab records, Please visit lab reports page."
              pushTo="/reports/labReport"
            />
          </Box>
        )}
        {(selectedItem || mode === "entry") &&
          (mode === "entry" ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <LabEntry
              id={labId}
              onClose={() => navigateTo(`/lab/labRecords/${labRecordType}?status=${statusTab}`)}
              labRecordType={labRecordType}
            />
          ) : (
            <LabDetails
              labRecordType={labRecordType}
              editMode={editMode}
              setEditMode={setEditMode}
              labObj={selectedItem}
              labId={selectedItem.id}
              handleViewClose={handleViewClose}
              navigateTo={navigateTo}
            />
          ))}
      </Box>
    </Can>
  );
}

LabList.defaultProps = {
  mode: "",
  clientId: undefined,
  testId: undefined,
  setTestId: () => ({}),
  labList: undefined,
  classNames: undefined,
  labTabs: undefined
};

export default connect(
  (state: RootState) => {
    const { userContext } = state;
    const { labTestRecords, total } = state.labTest;
    return {
      labTestRecords,
      total,
      permissionGroup: userContext?.userCreds?.userGroups[0]
    };
  },
  (dispatch: IThunkDispatch) => ({
    navigateTo: (path) => {
      dispatch(push(path));
    },
    getLabsTestRecords: (filters) => {
      dispatch(labTest.getLabTestsRecords(filters));
    },
    batchDelete: (ids = []) => {
      dispatch(labTest.batchDeleteLabTestRecords(ids));
    },
    batchFinalise: (ids = [], filters, requireApproval) => {
      dispatch(
        showDialog({
          title:
            filters.status === LabStatuses.PENDING_APPROVAL
              ? tl("lab.batchApprove.warningTitle")
              : tl("lab.batchFinalise.warningTitle"),
          description: `Are you sure you want to ${
            filters.status === LabStatuses.PENDING_APPROVAL ? "approve" : "finalise"
          } all ${ids.length} items at once? 
          Do remember that this action is not reversible.`,
          next: async () => {
            await dispatch(batchFinaliseLabTestRecords(ids, requireApproval));
            dispatch(navigateRemoveModal("Dialog"));
            dispatch(getLabTestsRecords(filters));
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message:
                  filters.status === LabStatuses.PENDING_APPROVAL
                    ? tl("lab.batchApproveSuccess")
                    : tl("lab.batchFinaliseSuccess"),
                timeout: 15000
              })
            );
          },
          onCancel: () => dispatch(navigateRemoveModal("Dialog"))
        })
      );
    }
  })
)(LabList);

import * as React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getLabTests } from "../../slices/labTestSettingsSlice";
import { LabTest } from "../../interfaces/LabInterfaces";

interface LabTestsNameAutoSelectProps {
  multiple: boolean;
  onChange: (ids: number[]) => void;
  isDisabled: boolean;
  variant: "standard" | "filled" | "outlined";
}

const LabTestsNameAutoSelect = ({
  multiple,
  onChange,
  isDisabled,
  variant
}: LabTestsNameAutoSelectProps): JSX.Element => {
  const dispatch = useDispatch();
  const labTests: LabTest[] = useSelector((state: RootState) => state.labSettings.labTests);
  const hasLabTests = labTests.length > 0;

  React.useEffect(() => {
    if (!hasLabTests) {
      dispatch(getLabTests());
    }
  }, [hasLabTests, dispatch]);

  return (
    <Autocomplete
      multiple={multiple}
      options={labTests}
      getOptionLabel={(option) => option.name || ""}
      onChange={(e, values) => {
        onChange((values as LabTest[])?.map((value) => value.id));
      }}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {option.name || ""}
        </li>
      )}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant={variant}
          label="Lab Tests"
          placeholder="Search and select Lab Tests"
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

export default LabTestsNameAutoSelect;

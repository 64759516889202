import { Box, Link, Tooltip, Typography } from "@mui/material";
import produce from "immer";
import { isEmpty, round } from "lodash";
import moment from "moment";
import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import logo from "../../../../../../assets/images/poweredByOkhati.png";
import { t, tl } from "../../../../../components/translate";
import capitalizedName from "../../../../../helpers/nameCapitalizer";
import { numberToWords, rupeeDisplay } from "../../../../../helpers/rupee";
import usePrintShortcut from "../../../../../hooks/usePrintShortcut";
import usePrintStyles from "../../../../../hooks/usePrintStyles";
import { BillItemType, BillType, PrintTemplate } from "../../../../../interfaces/BillInterfaces";
import { DiscountBasis } from "../../../../../interfaces/StockInterfaces";
import { RootState } from "../../../../../store";
import {
  BillClientInfo,
  InfoField,
  checkWalkInCustomer
} from "../../../../Billing/PrintBill/BillPrintHelpers";
import { getSectionStyle } from "../../../../Lab/LabPrint/LabPrintFunctions";
import LabPrintLetterHead from "../../../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import LabPrintLetterHeadCentered from "../../../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import IpdBillDescription from "./IpdBillDescription";
import { getCustomerNumber } from "../../../../Client/ClientList";

interface PrintBillProps {
  buttonText?: string;
  bills: BillType[];
  labelStyle?: React.CSSProperties;
  forceTemplate?: string | null;
  onPrintClick?: () => void;
}

const PrintBill: FunctionComponent<PrintBillProps> = ({
  bills,
  buttonText,
  labelStyle,
  forceTemplate,
  onPrintClick
}) => {
  const getCustomPrintStyle = () => {
    if (forceTemplate === PrintTemplate.BankPaper) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({
        pageStyle: "size: 13cm 24.5cm; margin: 0mm"
      }).applyPrintStyles();
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({
        pageStyle: "size: A4; margin: 0mm"
      }).applyPrintStyles();
    }
  };

  const getGrossTotal = (settings, billItem): number => {
    if (settings.discountSettings.discountBasis === DiscountBasis.TOTAL) {
      return (
        +billItem.grossTotal +
        +billItem.vatAmtAfterDiscount -
        (+billItem.vatAmtBeforeDiscount + +billItem.discountAmt)
      );
    }
    return billItem.grossTotal;
  };

  const combinedBillItemsAndCalculateGrossTotal = (billDocuments: BillType[]): BillType => {
    const billItems = [] as Partial<BillItemType>[];
    const sortedBills = [...billDocuments].sort(
      (a, b) => new Date(b.issueDate).getTime() - new Date(a.issueDate).getTime()
    );
    sortedBills.forEach((bill) => {
      if (bill.document.billItems?.length) {
        billItems.push(
          ...bill.document.billItems?.map((billItem) => ({
            ...billItem,
            grossTotal: getGrossTotal(bill.document.settings, billItem),
            issueDate: bill.issueDate
          }))
        );
      }
    });
    return produce(sortedBills[0], (draft) => {
      draft.document.billItems = billItems;
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const billDocument = React.useMemo(() => combinedBillItemsAndCalculateGrossTotal(bills), [bills]);

  const { client, customer } = billDocument || {};
  const resourceCentre = useSelector((state: RootState) => state.resources.resourceCentres[0]);
  const user = useSelector((state: RootState) => state.userContext.user);
  const SPs = useSelector((state: RootState) => state.resources.resourceCentreServiceProviders);

  const printRef = React.useRef();
  const reactToPrintRef = React.useRef(null);
  usePrintShortcut(reactToPrintRef, getCustomPrintStyle);

  const billSum = useMemo(
    () =>
      bills.reduce(
        (acc, bill) => ({
          total: acc.total + Number(bill.summary.totalAmount || 0),
          paid: acc.paid + Number(bill.paymentInfo.paidAmount || 0)
        }),
        {
          total: 0,
          paid: 0
        }
      ),
    [bills]
  );

  if (isEmpty(billDocument)) return null;
  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showFooter = resourceCentre?.settings?.printSettings.billPrintSettings?.includeFooter;

  const hideFooterCompletely =
    resourceCentre.settings.printSettings.billPrintSettings.hideFooterLogoCompletely;

  const showReferrer = resourceCentre?.settings?.printSettings.includeReferrer;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const customSubtitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
  const hideRcLogo = Boolean(customTitle);

  let letterHeadComponent = <Box />;
  if (showLetterhead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <LabPrintLetterHeadCentered
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    } else {
      letterHeadComponent = (
        <LabPrintLetterHead
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
          additionalContent={<></>}
        />
      );
    }
  }

  return (
    <>
      <ReactToPrint
        onAfterPrint={() => {
          // after cancelling print, shortcut works only after clicking on window.
          window.focus();
        }}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          <Box>
            <Tooltip arrow title="Alt + P / Ctrl + P">
              <Link
                type="submit"
                className="actionBtn"
                component="button"
                onMouseEnter={() => {
                  getCustomPrintStyle();
                }}
                ref={reactToPrintRef}
                onClick={() => ({})}
                style={{ textDecoration: "none", padding: "5px 15px" }}
              >
                <Typography component="span" style={labelStyle}>
                  {buttonText || "Print"}
                </Typography>
              </Link>
            </Tooltip>
          </Box>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterhead && (
                <tr>
                  <td>
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box m="0.2cm 1cm" pt="0.2cm" borderTop="1px solid #dddfdf">
                    {checkWalkInCustomer(client, "isWalkInCustomer") && (
                      <BillClientInfo
                        client={client}
                        customerNumber={getCustomerNumber(customer.customerNumber)}
                      />
                    )}
                  </Box>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <IpdBillDescription
                      billData={billDocument}
                      SPs={SPs}
                      relatedSettings={{
                        showReferrer,
                        isBordered: false,
                        headerColor
                      }}
                    />
                  </Box>
                  <Box m="0.5cm">
                    <Box alignItems="center" display="flex" gap={2}>
                      <Box
                        fontSize="0.3cm"
                        fontWeight={600}
                        style={{ textTransform: "uppercase" }}
                        width="3cm"
                      >
                        {tl("billing.grandTotal")}
                      </Box>
                      <Box fontWeight={500} fontSize="0.6cm">
                        {rupeeDisplay(billSum.total.toFixed(2))}
                      </Box>
                    </Box>
                    <Box>
                      <Typography>
                        <Box fontWeight={400} fontSize="0.3cm">
                          {numberToWords(billSum.total)} only
                        </Box>
                      </Typography>
                    </Box>
                    <Box alignItems="center" gap={2} marginTop="0.2cm" display="flex">
                      <Box fontSize="0.3cm" fontWeight={600} style={{ textTransform: "uppercase" }}>
                        {t("reports.paidAmount")}
                      </Box>
                      <Box fontWeight={500} fontSize="0.35cm">
                        {round(billSum.paid, 2).toFixed(2)}
                      </Box>
                    </Box>
                    {billSum.total - billSum.paid > 0 && (
                      <Box alignItems="center" gap={2} marginTop="0.2cm" display="flex">
                        <Box
                          fontSize="0.3cm"
                          fontWeight={600}
                          style={{ textTransform: "uppercase" }}
                        >
                          {t("reports.dueAmount")}
                        </Box>
                        <Box fontWeight={500} fontSize="0.35cm">
                          {round(billSum.total - billSum.paid, 2).toFixed(2)}
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box m="0cm 0.5cm" component="div" width="35%">
                    <InfoField
                      label={tl("billing.printedDateTime")}
                      data={
                        <>
                          {moment().format("YYYY/MM/DD")} {moment().format("h:mm a")}
                        </>
                      }
                      labelStyle={{ fontSize: "0.3cm" }}
                      valueStyle={{ fontSize: "0.3cm" }}
                    />
                    <InfoField
                      label={tl("billing.rePrintUser")}
                      data={<>{capitalizedName(`${user?.firstName} ${user?.lastName}`)}</>}
                      labelStyle={{ fontSize: "0.3cm" }}
                      valueStyle={{ fontSize: "0.3cm" }}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showFooter ? (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <Box>
                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          ) : (
            <Box width="100%" className="billFooterPos">
              <Box style={{ paddingRight: "10mm" }} mt={2} display="flex" justifyContent="flex-end">
                {!hideFooterCompletely && <img src={logo} alt="logo" height="35px" />}
              </Box>
              <Box height="2cm" />
            </Box>
          )}
        </div>
      </iframe>
    </>
  );
};

PrintBill.defaultProps = {
  buttonText: "Print",
  labelStyle: {},
  forceTemplate: null,
  onPrintClick: () => null
};

export default PrintBill;

import React from "react";
import { Button } from "@mui/material";
import Modal from "../../../components/Modal/Modal";
import { getResourceCentreNotifications } from "../../../slices/resourceCentreNotificationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const ResourceCentreNotificationModal = (): JSX.Element => {
  const [showModal, setShowModal] = React.useState(true);
  const resourceCentreNotifications = useAppSelector(
    (storeState) => storeState.resourceCentreNotification.notification
  );
  const showNotification = useAppSelector(
    (storeState) =>
      storeState.resources.resourceCentres[0]?.settings?.notificationSettings?.showNotification
  );
  const showNotificationModal =
    Boolean(resourceCentreNotifications.length) && showNotification && showModal;
  const notificationContent = resourceCentreNotifications[0]?.remark || "";

  const dispatch = useAppDispatch();
  const resourceCentreId = useAppSelector((state) => state.userContext?.resourceCentreId);
  React.useEffect(() => {
    if (resourceCentreId) {
      dispatch(getResourceCentreNotifications());
    }
  }, [dispatch, resourceCentreId]);
  return (
    <Modal
      title="Notice"
      open={showNotificationModal}
      footer={<Button onClick={() => setShowModal((prevValue) => !prevValue)}>Ok</Button>}
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: notificationContent }}
      />
    </Modal>
  );
};

export default ResourceCentreNotificationModal;

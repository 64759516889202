import { push } from "connected-react-router";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/labTest";
import * as NotificationActions from "./notification";
import { AppThunk, IThunkDispatch } from "../store";
import { LabRecord, LabStatuses, LabTestRecordFilters, Test } from "../interfaces/Lab";
import { PatchMedicalTestInterface } from "./medicalTest";
import {
  commonErrorMessage,
  createErrorMessage,
  createSuccessMessage,
  MODULE,
  updateErrorMessage,
  updateSuccessMessage
} from "../helpers/messages";
import { LabRecordType } from "../containers/Lab";
import { Client } from "../interfaces/ClientInterface";

export enum Type {
  GET_LAB_TEST_RECORD = "GET_LAB_TEST_RECORD",
  GET_LAB_TEST_RECORDS = "GET_LAB_TEST_RECORDS",
  POST_OR_EDIT_LAB_TEST_RECORD = "POST_OR_EDIT_LAB_TEST_RECORD",
  UPDATE_LAB_TEST_RECORD = "UPDATE_LAB_TEST_RECORD",
  UPDATE_LAB_TEST_SAMPLE = "UPDATE_LAB_TEST_SAMPLE",
  BATCH_FINALISE = "BATCH_FINALISE",
  BATCH_DELETE = "BATCH_DELETE",
  BATCH_DISPATCH = "BATCH_DISPATCH",
  UPDATE_CLIENT_NAME = "UPDATE_CLIENT_NAME",
  UPDATE_CLIENT_IMAGE = "UPDATE_CLIENT_IMAGE",
  UPDATE_LABTEST_ON_CLIENT_EDIT = "UPDATE_LABTEST_ON_CLIENT_EDIT"
}

export const getLabTestRecord =
  (labTestRecordId: string): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_LAB_TEST_RECORD, async () =>
        api.getLabTestRecord(labTestRecordId)
      )
    );
  };

export const getLabTestsRecords =
  (filters?: Partial<LabTestRecordFilters>): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_LAB_TEST_RECORDS, () => api.getLabTestsRecords(filters))
    );
  };

export const updateLabTestOnClientEdit =
  (client: Client): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({
      type: Type.UPDATE_LABTEST_ON_CLIENT_EDIT,
      payload: client
    });

export const postLabTestRecord =
  (
    data: { info: Record<string, string>; requestedTests: Array<Test>; id?: number } & {
      mode?: "edit";
    },
    createAnother = true,
    labRecordType: LabRecordType
  ): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    dispatch(
      actionCreatorAsync(Type.POST_OR_EDIT_LAB_TEST_RECORD, async () => {
        let result;
        if (data.mode === "edit") {
          try {
            result = await api.updatePostedLabTestRecord(data);
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: updateSuccessMessage(MODULE.LAB_TEST),
                autoTimeout: true
              })
            );
          } catch (error) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: error?.data?.message || updateErrorMessage(MODULE.LAB_TEST),
                autoTimeout: true
              })
            );
          }
        } else {
          try {
            result = (await api.postLabTestRecord(data)) as [LabRecord];
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: createSuccessMessage(MODULE.LAB_TEST),
                autoTimeout: true
              })
            );
          } catch (error) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: error?.data?.message || createErrorMessage(MODULE.LAB_TEST),
                autoTimeout: true
              })
            );
          }
        }
        if (result?.length && result[0]?.id && !createAnother) {
          dispatch(getLabTestsRecords({ status: result[0].status as LabStatuses }));
          if (result[0].status === "Ordered") {
            dispatch(push(`/lab/labRecords/${labRecordType}/${result[0].id}`));
          }
          if (result[0].status === "Sample Taken") {
            dispatch(push(`/lab/labRecords/${labRecordType}/${result[0].id}?status=Sample Taken`));
          }
        }
        return result;
      })
    );
  };

export const updateLabTestsRecord =
  (
    id: number,
    labTestRecord: PatchMedicalTestInterface,
    editAnother: boolean,
    labRecordType: LabRecordType
  ): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_LAB_TEST_RECORD, async () => {
        try {
          const result = (await api.patchLabTestsRecord(id, labTestRecord)) as LabRecord;
          if (result?.id && !editAnother) {
            if (result.status === "Incomplete Result")
              dispatch(
                push(`/lab/labRecords/${labRecordType}/${result.id}?status=Incomplete Result`)
              );
            if (result.status === "Result Ready")
              dispatch(push(`/lab/labRecords/${labRecordType}/${result.id}?status=Result Ready`));
            if (result.status === "Pending Approval")
              dispatch(
                push(`/lab/labRecords/${labRecordType}/${result.id}?status=Pending Approval`)
              );
          }
          return result;
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getTime(),
              variant: "error",
              autoTimeout: true,
              message: error?.data?.message || error?.data || commonErrorMessage
            })
          );
          return null;
        }
      })
    );
  };

export const updateLabTestsRecordSample =
  (
    labTestId: number,
    status: string,
    remark: string,
    id: number,
    name: string,
    collectionDate: string
  ): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_LAB_TEST_SAMPLE, () =>
        api.patchLabTestRecordStatus(labTestId, status, remark, id, name, collectionDate)
      )
    );
  };

export const batchFinaliseLabTestRecords =
  (labTestIds: Array<number>, requireApproval: boolean): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.BATCH_FINALISE, () =>
        api.batchFinaliseLabTests(labTestIds, requireApproval)
      )
    );
  };

export const updateClientName =
  (firstName: string, lastName: string, id: number): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({
      type: Type.UPDATE_CLIENT_NAME,
      payload: { firstName, lastName, id }
    });

export const updateClientImage =
  (id: number, profileImageS3Url: string): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({
      type: Type.UPDATE_CLIENT_IMAGE,
      payload: { id, profileImageS3Url }
    });

export const batchDeleteLabTestRecords =
  (ids: Array<number>): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.BATCH_DELETE, async () => {
        const res = await api.batchDeleteLabTestRecords(ids);
        if (res) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Successfully deleted.",
              autoTimeout: true
            })
          );
        }
        return ids.map((id) => Number(id));
      })
    );
  };

export const batchDispatchLabTestRecords =
  (ids: Array<number>): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.BATCH_DISPATCH, async () => {
        const res = await api.batchDispatchLabTestRecords(ids);
        if (res) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Successfully Dispatched.",
              autoTimeout: true
            })
          );
        }
        return ids;
      })
    );
  };

import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Control, Controller, FieldValues, useWatch } from "react-hook-form";
import { IServiceTag } from "../../../interfaces/ServiceTagsInterface";
import { RootState } from "../../../store";

interface Props {
  control: Control<FieldValues>;
  name: string;
}

interface OptionHash {
  [P: string]: IServiceTag;
}

function SpecialitySearch({ control, name }: Props): JSX.Element {
  const { specialities } = useWatch({ control });
  const options: IServiceTag[] = useSelector(
    (state: RootState) =>
      state.serviceTags.collection.filter((item: IServiceTag) => Boolean(item.noun)) || []
  );

  const optionsHash: OptionHash = options.reduce(
    (accumulator, current) => ({ ...accumulator, ...{ [current.id]: current } }),
    {}
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          options={options}
          getOptionLabel={(option) => option?.noun || ""}
          onChange={(_, value) => onChange(value ? [value.id] : [])}
          value={optionsHash[specialities ? specialities[0] : null] || null}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              {option?.noun || ""}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Select Speciality"
              margin="dense"
              error={!!error}
              helperText={error?.message || " "}
              sx={{ my: 0 }}
            />
          )}
        />
      )}
    />
  );
}

export default SpecialitySearch;

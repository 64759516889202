import React, { useState } from "react";
import Box from "@mui/material/Box";
import ArrowRight from "@mui/icons-material/ChevronRight";
import ArrowDown from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

interface CollapseProps {
  label: string | JSX.Element;
  collapsed?: boolean;
  hideLabel?: boolean;
  onCollapse?: () => void;
  children: React.ReactNode;
  onClick?: () => void;
}

const Collapse: React.FC<CollapseProps> = ({
  label,
  collapsed = true,
  children,
  hideLabel,
  onCollapse,
  onClick
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  React.useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);
  React.useEffect(() => {
    if (isCollapsed) {
      if (onCollapse) {
        onCollapse();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);
  return (
    <Box width="100%" data-testmation="collapse">
      {!hideLabel && (
        <Box
          style={{ background: "#f4f4f4", cursor: "pointer" }}
          height="34px"
          onClick={() => {
            if (onClick && isCollapsed) onClick();
            setIsCollapsed(!isCollapsed);
          }}
          display="flex"
          alignItems="center"
          paddingLeft="20px"
        >
          {isCollapsed ? <ArrowRight /> : <ArrowDown />}
          <Typography>
            <Box component="span" fontSize="14px" fontWeight="600">
              {label}
            </Box>
          </Typography>
        </Box>
      )}
      <Box paddingBottom="4px">
        {!isCollapsed && (
          <Box padding="16px 42px" pl={hideLabel ? "0px" : "42px"}>
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};

Collapse.defaultProps = {
  collapsed: false,
  hideLabel: false,
  onCollapse: undefined,
  onClick: undefined
};

export default Collapse;

import * as React from "react";
import {
  Autocomplete,
  Button,
  Box,
  Divider,
  TextField,
  Typography,
  TextFieldProps,
  Alert
} from "@mui/material";
import startCase from "lodash/startCase";
import { abs } from "mathjs";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { useHotkeys } from "react-hotkeys-hook";
import { tl, t } from "../../../components/translate";
import PercentFormat from "../../../components/FormattingInput/PercentInput";
import NrsFormat from "../../../components/FormattingInput/NrsInput";
import PaymentInfo from "./PaymentInfo";
import {
  BillDocumentType,
  DiscountBasedOn,
  DiscountTypes
} from "../../../interfaces/BillInterfaces";
import styles from "./BillEditor.module.css";
import { RootState } from "../../../store";
import { ChildGeneralLedger, LedgerType } from "../../../interfaces/Accounts";
import { getAllChildGlWithLedgerTypeFilter } from "../../accounts/hooks";
import LedgerSelect from "../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import Modal from "../../../components/Modal/Modal";
import { resourceCentreActions } from "../../../actions";
import RemindOn from "../RemindOn";
import DiscountTypeSelect from "./DiscountTypeSelect";
import { notificationAdd } from "../../../actions/notification";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { isRemarksValid } from "./BilllEditorActions";
import { getPaymentOptions } from "../../../actions/helpers/billHelper";

export enum paymentOptionsEnum {
  cash = "cash",
  card = "card",
  cheque = "cheque",
  onlinePayment = "onlinePayment",
  noPayment = "noPayment",
  balance = "balance",
  bankTransfer = "bankTransfer",
  khalti = "khalti",
  eSewa = "eSewa",
  fonePay = "fonePay"
}

export const paymentOptions = [
  paymentOptionsEnum.cash,
  paymentOptionsEnum.card,
  paymentOptionsEnum.cheque,
  paymentOptionsEnum.onlinePayment,
  paymentOptionsEnum.noPayment,
  paymentOptionsEnum.balance,
  paymentOptionsEnum.bankTransfer,
  paymentOptionsEnum.khalti,
  paymentOptionsEnum.eSewa,
  paymentOptionsEnum.fonePay
];

interface PaymentMethodSelectProps {
  isCreditNote?: boolean;
  selectedPaymentMethod: string;
  onPaymentMethodChange: (e, v) => void;
  TxtFieldProps?: TextFieldProps;
  paymentOptionsProp?: Array<paymentOptionsEnum>;
  disabled?: boolean;
}

interface SummaryProps {
  draft: BillDocumentType;
  onItemAdd: () => void;
  updateDraft: (draft: BillDocumentType) => void;
  isCreditNote: boolean;
  lastItemRef: React.RefObject<HTMLInputElement> | null;
}

export const PaymentMethodSelect: React.FC<PaymentMethodSelectProps> = ({
  isCreditNote,
  selectedPaymentMethod,
  onPaymentMethodChange,
  TxtFieldProps,
  paymentOptionsProp,
  disabled = false
}) => {
  const useStyles = makeStyles(() => ({
    inputRoot: {
      background: "white"
    }
  }));

  const dispatch = useDispatch();

  const classes = useStyles();
  const { isAccountSubscribed, paymentMethods } = useSelector((state: RootState) => ({
    isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed,

    paymentMethods: state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment
  }));
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (
      isAccountSubscribed &&
      paymentMethods &&
      !paymentMethods[selectedPaymentMethod]?.ledgerId &&
      ![paymentOptionsEnum.balance, paymentOptionsEnum.noPayment].includes(
        selectedPaymentMethod as paymentOptionsEnum
      )
    ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [selectedPaymentMethod, isAccountSubscribed, paymentMethods]);
  const [showLedgerSelect, setShowLedgerSelect] = React.useState<boolean>(false);
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger>(null);
  const cashAndBankLedgers = getAllChildGlWithLedgerTypeFilter([
    LedgerType.BANK_LEDGER,
    LedgerType.CASH_LEDGER
  ]);
  const resourceCentre = useSelector((state: RootState) => state.resources.resourceCentres[0]);

  return (
    <Box width="300px">
      <Autocomplete
        value={selectedPaymentMethod}
        options={
          paymentOptionsProp ||
          (isCreditNote
            ? paymentOptions
            : paymentOptions.filter((option) => option !== paymentOptionsEnum.noPayment))
        }
        disabled={disabled}
        classes={classes}
        getOptionLabel={(option) => startCase(option)}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line  react/jsx-props-no-spreading
            {...TxtFieldProps}
            // eslint-disable-next-line  react/jsx-props-no-spreading
            {...params}
            data-testmation="paymentMethodSelect"
            fullWidth
            variant="outlined"
            margin="dense"
            placeholder={t("billing.selectPaymentMethod")}
          />
        )}
        onChange={onPaymentMethodChange}
      />
      {showAlert && (
        <Alert
          action={
            <Button
              onClick={() => setShowLedgerSelect(true)}
              variant="outlined"
              color="inherit"
              size="small"
            >
              Map Ledger
            </Button>
          }
          severity="warning"
          sx={{ padding: "0px" }}
        >
          Not mapped to ledger
        </Alert>
      )}
      <Modal
        open={showLedgerSelect}
        title="Select Ledger for Supplier"
        footer={
          <>
            <Button
              onClick={() => {
                setShowLedgerSelect(false);
                setSelectedLedger(null);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedLedger}
              onClick={() => {
                if (selectedLedger) {
                  dispatch(
                    resourceCentreActions.putResourceCentre(
                      produce(resourceCentre, (draft) => {
                        const { modeOfPayment } = draft.settings.accountSettings;
                        if (modeOfPayment[selectedPaymentMethod]) {
                          draft.settings.accountSettings.modeOfPayment[
                            selectedPaymentMethod
                          ].ledgerId = selectedLedger.id;
                        } else {
                          draft.settings.accountSettings.modeOfPayment[selectedPaymentMethod] = {
                            ledgerId: selectedLedger.id
                          };
                        }
                      })
                    )
                  );
                  setSelectedLedger(null);
                  setShowLedgerSelect(false);
                  setShowAlert(false);
                }
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <LedgerSelect
          options={cashAndBankLedgers || []}
          selected={selectedLedger}
          onChange={(value) => setSelectedLedger(value)}
        />
      </Modal>
    </Box>
  );
};

const Summary = React.forwardRef<HTMLAnchorElement, SummaryProps>(
  ({ draft, onItemAdd, updateDraft, isCreditNote, lastItemRef }, ref) => {
    const dispatch = useDispatch();
    const isMobile = useMobileScreen();

    // get payment options
    const { enablePaymentSelection, paymentMethodList, selectedPaymentMethod } = getPaymentOptions(
      draft,
      isCreditNote
    );

    const resourceCentre =
      useSelector((state: RootState) => state.resources.resourceCentres[0]) || {};
    useHotkeys(
      "alt+n",
      () => {
        const emptyRow = draft?.billItems?.find((item) => !item.description);
        if (!emptyRow) {
          onItemAdd();
        } else if (draft.billItems.length > 1) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: `Row no: ${emptyRow.sNo} is empty, please select product or service.`,
              variant: "error",
              autoTimeout: true
            })
          );
        }
        if (lastItemRef?.current) {
          lastItemRef.current.focus();
        }
      },
      { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
    );

    const handleDiscountTypeChange = (value) => {
      updateDraft(
        produce(draft, (draftItem) => {
          draftItem.settings.discountSettings.discountType = value;
        })
      );

      dispatch(
        resourceCentreActions.putResourceCentre(
          produce(resourceCentre, (draftItem) => {
            draftItem.settings.billingSettings.discountSettings.discountType = value;
          })
        )
      );
    };

    return (
      <Box
        display="flex"
        flexWrap="wrap"
        px={isMobile ? 2 : 4}
        pt={2}
        pb={8}
        borderTop="1px solid lightgrey"
      >
        <Box flexGrow={1}>
          {!isCreditNote && (
            <Box marginBottom={1}>
              <Button
                ref={ref}
                onClick={onItemAdd}
                variant="outlined"
                style={{
                  border: "2px solid",
                  display: "flex",
                  justifyContent: "space-between",
                  minWidth: "210px"
                }}
              >
                <Typography data-testmation="addBillItem" fontWeight={600}>
                  {tl("billing.addBillItem")}
                </Typography>
                <Typography>Alt + N</Typography>
              </Button>
            </Box>
          )}
          <div>
            <RemindOn draft={draft} updateDraft={updateDraft} isCreditNote={isCreditNote} />
          </div>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
            width={0.7}
          >
            <Box flexBasis="200px" maxWidth="300px">
              <Typography>
                <Box component="span" fontWeight={500} marginBottom="8px">
                  {tl("billing.paymentMethod")}
                </Box>
              </Typography>
              <PaymentMethodSelect
                selectedPaymentMethod={selectedPaymentMethod}
                disabled={
                  !enablePaymentSelection ||
                  (draft.paymentInfo.paymentMethod === paymentOptionsEnum.balance &&
                    !!draft.client.balance)
                }
                onPaymentMethodChange={(e, v) => {
                  if (!v) return;
                  updateDraft({
                    ...draft,
                    paymentInfo: {
                      ...draft.paymentInfo,
                      paymentMethod: v,
                      ...(v !== paymentOptionsEnum.cash &&
                        !isCreditNote && {
                          tenderAmount: 0,
                          changeAmount: 0
                        })
                    }
                  });
                }}
                isCreditNote={isCreditNote}
                paymentOptionsProp={
                  /**
                   * @todo need to revisit after handling payment through balance for invoice bill,
                   * since credit note's payment method too will have 'balance'
                   * @done temporarily solved by forcing payemnt method to be cash if its
                   * credit note, even if original bill utilized amount in 'balance' */
                  paymentMethodList
                }
              />
            </Box>
          </Box>
          <Box maxWidth="500px" paddingRight="64px">
            <Typography>
              <Box component="span" fontWeight={500} marginBottom="8px">
                {tl("billing.remarks")}
              </Box>
            </Typography>
            <TextField
              sx={{
                width: "300px"
              }}
              variant="outlined"
              multiline
              error={!isRemarksValid(draft)}
              value={draft.remarks}
              onChange={(e) => {
                updateDraft({
                  ...draft,
                  remarks: e.target.value
                });
              }}
              rows={2}
            />
          </Box>
        </Box>
        <Box flexBasis="250px">
          <Typography>
            <Box component="span" marginBottom="8px" display="flex">
              <Box component="span" display="inline-block" width="100%" fontWeight={500}>
                {tl("billing.grossTotalAmount")}
              </Box>
              <Box component="span" marginLeft="16px">
                <TextField
                  value={draft.summary.totalPerUnitTimesQty?.toFixed(2) || 0}
                  margin="dense"
                  className={styles.nrsReadInputRoot}
                  disabled
                  InputProps={{
                    inputComponent: NrsFormat,
                    startAdornment: t("rs"),
                    classes: {
                      root: styles.nrsReadInputRoot,
                      input: styles.nrsReadInput
                    }
                  }}
                />
              </Box>
            </Box>
          </Typography>
          <Typography>
            <Box component="span" marginBottom="16px" display="flex">
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                fontWeight={500}
              >
                {tl("billing.discount")}
              </Box>
              <Box component="span" marginLeft="16px">
                {draft.settings.discountSettings.discountType === DiscountTypes.percentage &&
                draft.settings.discountSettings.discountBasis === DiscountBasedOn.invoice ? (
                  <TextField
                    disabled={
                      isCreditNote ||
                      draft.settings.discountSettings.discountBasis !== DiscountBasedOn.invoice
                    }
                    data-testmation="billing.discountPct"
                    value={draft.summary.discountPct}
                    margin="dense"
                    variant={
                      draft.settings.discountSettings.discountBasis !== DiscountBasedOn.invoice
                        ? "standard"
                        : "outlined"
                    }
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      if (!isCreditNote)
                        updateDraft({
                          ...draft,
                          summary: {
                            ...draft.summary,
                            discountPct:
                              Number(e.target.value) <= 100 ? Number(e.target.value) : 100,
                            discountType: "percent"
                          }
                        });
                    }}
                    InputProps={{
                      ...(isCreditNote && { disableUnderline: true }),
                      inputComponent: PercentFormat,
                      classes: { input: styles.discountBillInput },
                      endAdornment: "%",
                      startAdornment: (
                        <DiscountTypeSelect
                          disabled={isCreditNote}
                          fieldValue={draft.settings.discountSettings.discountType}
                          onChange={(value) => {
                            handleDiscountTypeChange(value);
                          }}
                        />
                      )
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                ) : (
                  <TextField
                    disabled={
                      isCreditNote ||
                      draft.settings.discountSettings.discountBasis === DiscountBasedOn.inline
                    }
                    data-testmation="billing.discountAmount"
                    value={draft.summary.discount.toFixed(2)}
                    margin="dense"
                    variant={isCreditNote ? "standard" : "outlined"}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      const maxAllowedDiscount = draft.summary.totalPerUnitTimesQty;
                      const discount = Number(e.target.value);
                      if (!isCreditNote)
                        updateDraft({
                          ...draft,
                          summary: {
                            ...draft.summary,
                            discount:
                              discount <= maxAllowedDiscount ? discount : maxAllowedDiscount,
                            discountType: "amount"
                          }
                        });
                    }}
                    InputProps={{
                      ...(isCreditNote && { disableUnderline: true }),
                      inputComponent: NrsFormat,
                      classes: { input: styles.discountBillInput },
                      startAdornment: (
                        <DiscountTypeSelect
                          disabled={
                            isCreditNote ||
                            draft.settings.discountSettings.discountBasis === DiscountBasedOn.inline
                          }
                          fieldValue={draft.settings.discountSettings.discountType}
                          onChange={(value) => {
                            handleDiscountTypeChange(value);
                          }}
                        />
                      )
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box>
            </Box>
          </Typography>

          <Box>
            <Typography>
              <Box component="span" marginBottom="8px" display="flex">
                <Box component="span" display="inline-block" width="100%" fontWeight={500}>
                  {tl("billing.taxableAmount")}
                </Box>
                <Box component="span" marginLeft="16px">
                  <TextField
                    value={draft.summary.taxableAmount.toFixed(2)}
                    margin="dense"
                    className={styles.nrsReadInputRoot}
                    disabled
                    InputProps={{
                      inputComponent: NrsFormat,
                      startAdornment: t("rs"),
                      classes: {
                        root: styles.nrsReadInputRoot,
                        input: styles.nrsReadInput
                      }
                    }}
                  />
                </Box>
              </Box>
            </Typography>
            {Object.keys(draft.summary.taxAmtWithRates || {}).map((vatPct, index) => {
              const key = `${vatPct}-${index}`;
              return (
                <Box component="span" marginBottom="8px" display="flex" key={key}>
                  <Box component="span" display="inline-block" width="100%" fontWeight={500}>
                    {tl("billing.VAT")} {vatPct}%
                  </Box>
                  <Box component="span" marginLeft="16px" textAlign="left">
                    <TextField
                      value={draft.summary.taxAmtWithRates[vatPct].toFixed(2)}
                      margin="dense"
                      className={styles.nrsReadInputRoot}
                      disabled
                      InputProps={{
                        inputComponent: NrsFormat,
                        startAdornment: t("rs"),
                        classes: {
                          root: styles.nrsReadInputRoot,
                          input: styles.nrsReadInput
                        }
                      }}
                    />
                  </Box>
                </Box>
              );
            })}

            {Boolean(draft.summary.taxAmount) && (
              <Typography>
                <Box component="span" marginBottom="8px" display="flex">
                  <Box component="span" display="inline-block" width="100%" fontWeight={500}>
                    {tl("billing.taxAmount")}
                  </Box>
                  <Box component="span" marginLeft="16px">
                    <TextField
                      value={draft.summary.taxAmount.toFixed(2)}
                      margin="dense"
                      className={styles.nrsReadInputRoot}
                      disabled
                      InputProps={{
                        inputComponent: NrsFormat,
                        startAdornment: t("rs"),
                        classes: {
                          root: styles.nrsReadInputRoot,
                          input: styles.nrsReadInput
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Typography>
            )}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            textAlign="right"
            marginBottom="8px"
            justifyContent="space-between"
            width="100%"
          >
            <Typography style={{ fontWeight: 500 }}>{tl("stock.roundingOff")}</Typography>
            <Box component="span" marginLeft="16px" textAlign="right">
              <TextField
                disabled={isCreditNote}
                type="number"
                value={draft.summary?.roundOffAmt}
                onFocus={(e) => e.target.select()}
                variant="outlined"
                InputProps={{
                  startAdornment: tl("rs"),
                  classes: { input: styles.nrsInput }
                }}
                onChange={(e) => {
                  const roundOffAmt = e.target.value;
                  if (Number(roundOffAmt) >= -5 && Number(roundOffAmt) <= 5) {
                    const newState = produce(draft, (draftItem) => {
                      draftItem.summary.roundOffAmt = roundOffAmt;
                    });

                    updateDraft(newState);
                  }
                }}
              />
            </Box>
          </Box>

          <Divider />
          <Typography>
            <Box component="span" marginTop="8px" display="flex">
              <Box
                component="span"
                fontSize="0.83em"
                display="inline-block"
                width="150px"
                fontWeight={600}
              >
                {tl("billing.total")}
              </Box>
              <Box
                component="span"
                fontSize="0.83em"
                fontWeight={500}
                textAlign="right"
                width="220px"
              >
                <TextField
                  value={draft.summary.totalAmount.toFixed(2)}
                  margin="dense"
                  className={styles.nrsReadInputRoot}
                  disabled
                  InputProps={{
                    inputComponent: NrsFormat,
                    startAdornment: t("rs"),
                    classes: {
                      root: styles.nrsReadInputRoot,
                      input: styles.nrsReadInput
                    }
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{ allowNegative: isCreditNote }}
                />
              </Box>
            </Box>
          </Typography>
          <Box component="div" marginTop="8px">
            <Typography>
              <Box component="span" fontSize="0.8em" fontWeight={500}>
                {" "}
                {draft.summary.inWords}
              </Box>
            </Typography>
          </Box>
          {isCreditNote ? (
            <Box>
              <Divider />
              <Typography>
                {Object.keys(draft.refundInfo || {}).map((key, i) =>
                  key === "previousPaymentMethod" ? null : (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box component="span" marginTop="8px" display="flex" key={i}>
                      <Box
                        component="span"
                        fontSize="0.83em"
                        display="inline-block"
                        width="150px"
                        fontWeight={600}
                      >
                        {tl(`billing.${key}`)}
                      </Box>
                      <Box
                        component="span"
                        fontSize="0.83em"
                        marginLeft="16px"
                        fontWeight={500}
                        textAlign="right"
                      >
                        <TextField
                          value={draft.refundInfo[key].toFixed(2)}
                          margin="dense"
                          className={styles.nrsReadInputRoot}
                          disabled
                          InputProps={{
                            inputComponent: NrsFormat,
                            startAdornment: t("rs"),
                            classes: {
                              root: styles.nrsReadInputRoot,
                              input: styles.nrsReadInput
                            }
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{ allowNegative: isCreditNote }}
                        />
                      </Box>
                    </Box>
                  )
                )}
              </Typography>
            </Box>
          ) : null}
          <Divider />
          <Box component="div" marginTop="8px">
            <PaymentInfo
              draft={
                isCreditNote
                  ? {
                      ...draft,
                      paymentInfo: {
                        ...draft.paymentInfo,
                        paidAmount: abs(draft.paymentInfo.paidAmount)
                      }
                    }
                  : draft
              }
              updateDraft={(value) => {
                // eslint-disable-next-line no-unused-expressions
                isCreditNote
                  ? updateDraft({
                      ...value,
                      paymentInfo: {
                        ...value.paymentInfo,
                        paidAmount: -abs(value.paymentInfo.paidAmount)
                      }
                    })
                  : updateDraft(value);
              }}
              isCreditNote={isCreditNote}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);

PaymentMethodSelect.defaultProps = {
  isCreditNote: false,
  TxtFieldProps: {},
  paymentOptionsProp: null,
  disabled: false
};

export default Summary;

import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import Panel from "../../components/Panel";
import { Referrer } from "../../interfaces/ReferrerInterface";
import useIsAccountSubscribed from "../../hooks/accounts";
import { useGetAllChildLedgers } from "../accounts/hooks";
import ReferrerProductServices from "./ReferrerProductServices";
import { Mode } from "./Referrers";
import ReferrerTransactionsAndLedgers from "./ReferrerTransactionsAndLedgers";
import Can from "../Policy/Can";
import { deleteReferrer } from "../../actions/referrers";
import Modal from "../../components/Modal/Modal";
import { RootState } from "../../store";
import { getStateFromProps } from "./ReferrerCreateEdit";
import { tl } from "../../components/translate";

interface Props {
  onEdit: () => void;
  referrer: Referrer | null;
  onClose: () => void;
}

enum ReferrerDetailKey {
  REFERRER = "referrer",
  ADDRESS = "address",
  EMAIL = "email",
  PHONE = "phone",
  TDS = "tds"
}

enum ReferrerDetailForAccountUser {
  LEDGER_ID = "ledgerId",
  OPENING_BALANCE = "openingBalance",
  OPENING_BALANCE_DATE = "openingBalanceDate",
  TRANSACTION_TYPE = "transactionType"
}

const InfoField = ({ value, label, key }): JSX.Element | null => {
  if (!value) return null;
  return (
    <Box display="flex" gap={1} key={key}>
      <Typography fontSize="0.9rem" sx={{ width: "180px", fontWeight: "bold" }}>
        {label}
      </Typography>
      <Typography fontSize="0.9rem">{value || ""}</Typography>
    </Box>
  );
};

export default function ReferrerDetails({ referrer, onEdit, onClose }: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const isAccountSubscribed = useIsAccountSubscribed();
  const allLedgers = useGetAllChildLedgers();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean>(false);
  const selectedReferrer = useSelector((state: RootState) =>
    state.referrers.referrers.find((r) => r.id === referrer?.id)
  );
  const referrerLedger = allLedgers?.find((ledger) => ledger.id === selectedReferrer?.ledgerId);

  if (!referrer) return null;
  const productServices =
    getStateFromProps(selectedReferrer, isAccountSubscribed, Mode.READ_ONLY)?.productService || [];
  return (
    <Panel
      title={tl("referrerInformation")}
      deleteButton={
        selectedReferrer.referrerType !== "root" && (
          <Can policyAccessKey="clinic:deleteReferrers">
            <IconButton onClick={() => setOpenDeleteConfirmation(true)}>
              <Delete style={{ cursor: "pointer" }} />
            </IconButton>
          </Can>
        )
      }
      editButton={
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      }
      onClose={onClose}
    >
      <Box m={3}>
        {[
          ...Object.values(ReferrerDetailKey),
          ...(isAccountSubscribed ? Object.values(ReferrerDetailForAccountUser) : [])
        ].map((key) => {
          if (key === ReferrerDetailForAccountUser.LEDGER_ID) {
            return <InfoField key={key} value={referrerLedger?.ledgerName || ""} label="Ledger" />;
          }
          if (key === ReferrerDetailForAccountUser.OPENING_BALANCE_DATE) {
            return (
              <InfoField
                key={key}
                value={
                  selectedReferrer[key]
                    ? convertADtoBS(new Date(selectedReferrer[key])).formatted4
                    : ""
                }
                label={startCase(key)}
              />
            );
          }
          return <InfoField key={key} value={selectedReferrer[key]} label={startCase(key)} />;
        })}
        {productServices?.length > 1 && (
          <ReferrerProductServices
            mode={Mode.READ_ONLY}
            handleUpdates={() => ({})}
            propState={productServices}
          />
        )}
        <ReferrerTransactionsAndLedgers referrerId={selectedReferrer.id} />
      </Box>
      <Modal
        open={openDeleteConfirmation}
        title={tl("confirmation")}
        onClose={() => setOpenDeleteConfirmation(false)}
        onSave={() => {
          dispatch(deleteReferrer(selectedReferrer.id));
          setOpenDeleteConfirmation(false);
          onClose();
        }}
      >
        <Typography>{tl("ConfirmationStmt")}</Typography>
      </Modal>
    </Panel>
  );
}

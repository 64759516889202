import { ReducerBuilder } from "./ReducerBuilder";
import * as ARCs from "../actions/accessingResourceCentres";

const INITIAL_STATE = {
  collection: [],
};

const setAccessingResourceCentres = (state, payload) => {
  return { ...state, collection: payload };
};

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(ARCs.Type.GET_ACCESSING_RCS, setAccessingResourceCentres)
  .build();

export default reducer;

import { Button, ButtonGroup, Menu, Typography } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useCoaAll } from "./hooks";
import { downloadCSV } from "../../helpers/files";
import { PrimaryLedger } from "../../interfaces/Accounts";
import CsvUploader from "../../components/CsvUploader/CsvUploader";
import { coaColumns } from "../../components/CsvUploader/csvHelpers";
import { batchUploadCOA } from "../../api/accounts";

interface Props {
  setShowCreatePanel: (val) => void;
  setSelectedParent: (val) => void;
}

const columns = [
  "Primary Ledger",
  "Secondary Ledger",
  "Account Group",
  "Code",
  "Ledger Name",
  "Transaction Type",
  "Opening Balance",
  "Ledger Type",
  "Ledger Nature"
];

const formatData = (coa: PrimaryLedger[]) => {
  const data = [] as { [key: string]: string | number }[];
  coa?.forEach((primaryLedger) => {
    primaryLedger?.secondaryLedgers?.forEach((secondaryLedger) => {
      secondaryLedger?.accountingGroups?.forEach((accountGroup) => {
        accountGroup?.childGeneralLedgers?.forEach((childLedger) => {
          data.push({
            primaryLedgerName: primaryLedger.primaryLedgerName,
            secondaryLedgerName: secondaryLedger.secondaryLedgerName,
            accountingGroupName: accountGroup.accountingGroupName,
            code: childLedger.code,
            ledgerName: childLedger.ledgerName,
            transactionType: childLedger.transactionType || "",
            openingBalance: childLedger.openingBalance || "",
            ledgerType: childLedger.ledgerType,
            ledgerNature: childLedger.ledgerNature
          });
        });
      });
    });
  });
  return data;
};

const docRowProcessor = ({
  primaryLedgerName,
  secondaryLedgerName,
  accountingGroupName,
  code,
  ledgerName,
  transactionType,
  openingBalance,
  ledgerType,
  ledgerNature
}) => [
  primaryLedgerName,
  secondaryLedgerName,
  accountingGroupName,
  code,
  ledgerName,
  transactionType,
  openingBalance,
  ledgerType,
  ledgerNature
];

const menuItems = (coa) => [
  {
    label: "Export to Excel",
    onClick: () => downloadCSV("COA Data", formatData(coa), columns, (row) => docRowProcessor(row))
  }
];

export default function ButtonGroupAccount({
  setShowCreatePanel,
  setSelectedParent
}: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data: coa = [], refetch } = useCoaAll();

  return (
    <div>
      <ButtonGroup variant="contained" aria-label="split button">
        <Button
          data-testmation="addAccountGroupButton"
          onClick={() => {
            setShowCreatePanel(true);
            setSelectedParent(null);
          }}
        >
          Add Account Group
        </Button>
        <Button size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ArrowDropDownIcon />
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {menuItems(coa).map((item, index) => (
            <Typography
              sx={{
                cursor: "pointer",
                margin: "3px",
                borderBottom: `${index === 0 ? "1px solid #ededed" : ""}`
              }}
              onClick={item.onClick}
              key={item.label}
            >
              {item.label}
            </Typography>
          ))}
          <CsvUploader
            buttonText="Upload COA (.csv)"
            columns={coaColumns}
            requiredFieldInfoText="Cannot change the Ledger Name, Except OpeningBalance and TransactionType all fields are required."
            createDataApi={batchUploadCOA}
            useBatchUpload
            runAfterSave={refetch}
          />
        </Menu>
      </ButtonGroup>
    </div>
  );
}

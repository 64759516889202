import React, { useState, useEffect } from "react";
import { Box, Button, DialogActions, DialogContent, Paper, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DrawingBoard from "./DrawingBoard";
import { tl, t } from "../../components/translate";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface StatusProps {
  status: string;
  assessmentImage: string;
  onChange: (status: string) => void;
  onChangeImage: (image: string) => void;
  isOpd?: boolean;
}
const Status: React.FC<StatusProps> = ({
  status,
  assessmentImage,
  onChange,
  onChangeImage,
  isOpd = true
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [boardData, setBoardData] = useState([]);
  const [bgImageIndex, setbgImageIndex] = useState(0);
  const [viewImage, setViewImage] = React.useState(false);
  const assessmentLabels = useAssessmentLabel();
  useEffect(() => {
    setImage(assessmentImage);
  }, [assessmentImage]);

  useEffect(() => {
    onChangeImage(image);
  }, [image]);

  return (
    <Box className={styles.assessmentRow} height="auto">
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {isOpd ? assessmentLabels.examination : tl("assessment.status")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
          alignItems: "center"
        }}
      >
        <Box className={styles.assessmentField} sx={{ flexGrow: 1 }}>
          <DebouncedTextField
            margin="dense"
            id="outlined-basic"
            variant="outlined"
            data-testmation="examinationTextField"
            fullWidth
            multiline
            value={status}
            rows={3}
            rowsMax="3"
            placeholder={t("assessment.typeStatusHere")}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 1000 }}
            onChange={(e) => onChange(e.target.value)}
            showTextCounter
            counterLength={1000}
            FormHelperTextProps={{
              style: { position: "absolute", right: 0, bottom: 0, margin: 5 }
            }}
            debounceAt={400}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <Box
                  title="Draw image"
                  data-testmation="pencilButton"
                  sx={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    border: "1px solid grey",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "lightgrey",
                    borderRadius: "2px",
                    cursor: "pointer"
                  }}
                  onClick={() => setOpen(true)}
                >
                  <CreateIcon />
                </Box>
              )
            }}
            style={{ padding: 0 }}
          />
        </Box>
        {image && (
          <Box sx={{ width: "70px", display: "flex", gap: 0.5, flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CloseIcon
                sx={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  setViewImage(false);
                  setImage("");
                  setBoardData([]);
                }}
                data-testmation="examinationOpenImageModalButton"
              />
              <CreateIcon
                sx={{
                  cursor: "pointer"
                }}
                onClick={() => setOpen(true)}
              />
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                px: 0.5
              }}
              component={Paper}
              onClick={() => setViewImage(true)}
            >
              <img
                src={image}
                style={{ margin: "auto", height: "50px", width: "50px" }}
                alt="assessment"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Dialog open={viewImage} onClose={() => setViewImage(false)}>
        <DialogContent>
          <img src={image} alt="assessment" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewImage(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} classes={{ paper: "slate" }} fullScreen={fullScreen}>
        <DrawingBoard
          closeModal={() => {
            setOpen(false);
          }}
          title={tl("assessment.statusDescription")}
          handleImageSave={(img, drawingBoardData, imageIndex) => {
            setImage(img);
            setBoardData(drawingBoardData);
            setbgImageIndex(imageIndex);
          }}
          width={fullScreen ? window.innerWidth - 32 : 500}
          height={fullScreen ? window.innerHeight - 180 : 500}
          boardData={boardData}
          setBoardData={setBoardData}
          imageIndex={bgImageIndex}
          setImageIndex={setbgImageIndex}
          isAssessmentForm
        />
      </Dialog>
    </Box>
  );
};

export default Status;

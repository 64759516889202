import { Box, Typography } from "@mui/material";
import React from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import usePrintStyles from "../../../hooks/usePrintStyles";
import { RootState } from "../../../store";
import LabPrintLetterHeadCentered from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import logo from "../../../../assets/images/poweredByOkhati.png";

interface Props {
  printData: Array<Array<string>>;
}

export const ReportPrint = ({ printData }: Props): JSX.Element | null => {
  const printRef = React.useRef();
  const resourceCentre = useSelector((state: RootState) =>
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId)
  );

  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  if (!resourceCentre) return null;

  return (
    <Box>
      <ReactToPrint
        trigger={() => (
          <Typography
            onMouseEnter={() => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              usePrintStyles({
                pageStyle: "size: A4; margin: 0mm"
              }).applyPrintStyles();
            }}
            fontSize="10px"
            fontWeight={600}
          >
            Print Selected Columns
          </Typography>
        )}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printIframe">
        <div ref={printRef}>
          <Box p="1cm" style={{ ...getSectionStyle(headerColor).headerStyle }}>
            <LabPrintLetterHeadCentered
              resourceCentre={resourceCentre}
              titleFontScale={clinicNameFontScale}
            />
          </Box>
          <Typography
            variant="h5"
            color="black"
            mx={1}
            display="flex"
            justifyContent="center"
            fontWeight="bold"
          >
            Assessment Report
          </Typography>
          <Box m="1cm 0.5cm">
            {printData.map((items, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box display="flex" key={index}>
                {items.map((item, innerIndex) => (
                  <Typography
                    sx={{
                      paddingLeft: "10px",
                      display: "flex",
                      fontWeight: `${index === 0 ? "bold" : ""}`,
                      flex: "1 0 100px",
                      borderTop: `${index === 0 ? "1px solid black" : ""}`,
                      borderBottom: "1px solid black",
                      flexWrap: "wrap",
                      borderLeft: `${innerIndex === 0 ? "1px solid black" : ""}`,
                      borderRight: `${innerIndex === items.length - 1 ? "1px solid black" : ""}`
                    }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item}-${innerIndex}`}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
          <Box
            component="div"
            paddingTop="0.5rem"
            display="flex"
            width="100%"
            className="billFooterPos borderTopBlack1 billFooter"
            style={{ ...getSectionStyle(headerColor).footerStyle }}
            flexDirection="row-reverse"
          >
            <Box>
              <img src={logo} alt="logo" height="35px" />
            </Box>
          </Box>
        </div>
      </iframe>
    </Box>
  );
};

export default ReportPrint;

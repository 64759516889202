import * as React from 'react';
import * as calF from '../functions/calendarFunctions';
import MonthView from './MonthView';
import YearMonthSwitch from './YearMonthSwitch';
export interface CalendarProps {
  defaultDate?: Date;
  className?: string;
  maxValidDate?: Date;
  minValidDate?: Date;
  onChange?: (date: Date) => void;
  highlightedDays: Array<any>;
  activeDate: any;
}

export interface CalendarStates {
  activeDate: Date;
  activeBsDate: { bsYear: number; bsMonth: number; bsDate: number };
}

const Calendar: React.FC<CalendarProps> = ({
  defaultDate,
  className,
  maxValidDate,
  minValidDate,
  onChange,
  highlightedDays,
  activeDate,
}) => {
  const date = defaultDate || new Date();

  const [state, setState] = React.useState({
    activeDate: date,
    activeBsDate: calF.convertADtoBS(date),
  });

  React.useEffect(() => {
    setState({ activeDate: activeDate, activeBsDate: calF.convertADtoBS(activeDate) });
    // switchViewsYearMonth(state.activeBsDate.bsYear, state.activeBsDate.bsMonth);
  }, [activeDate]);

  const switchViewsYearMonth = (year: any, month: any) => {
    setState({
      ...state,
      activeBsDate: {
        ...state.activeBsDate,
        bsYear: year,
        bsMonth: month,
        bsDate: state.activeBsDate.bsDate,
      },
    });
  };

  const { bsMonth, bsYear } = state.activeBsDate;

  return (
    <>
      <div
        className={`${className || ''}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <YearMonthSwitch
          defaultMonth={bsMonth}
          defaultYear={bsYear}
          onSwitch={(year: any, month: any) => switchViewsYearMonth(year, month)}
        />
        <MonthView
          viewBsYear={bsYear}
          viewBsMonth={bsMonth}
          maxValidDate={maxValidDate}
          minValidDate={minValidDate}
          defaultActiveDate={state.activeDate}
          onDayClicked={(date) => {
            onChange && onChange(date);
          }}
          highlightedDays={highlightedDays}
        />
      </div>
    </>
  );
};

export default Calendar;

import React, { FunctionComponent } from "react";
import { Typography, Link, Box, Tooltip } from "@mui/material";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import Barcode from "react-barcode";
import "./PrintBill.scss";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { tl, t } from "../../../components/translate";
import { registerPrint } from "../../../actions/bill";
import { DocumentTypes, BillStatus, BillType } from "../../../interfaces/BillInterfaces";
import { dateWithZeroBeforeSingleDigits } from "../../../helpers/formatters";
import { isOldFormatBill } from "../../../actions/helpers/billHelper";
import DescribeBillV1 from "./BillDescriptionV1";
import usePrintStyles from "../../../hooks/usePrintStyles";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import {
  BillClientInfoBankPaper,
  checkWalkInCustomer,
  ForceTemplate,
  InfoField,
  InfoFieldForBankPaper
} from "./BillPrintHelpers";
import logo from "../../../../assets/images/poweredByOkhati.png";
import BankPaperLetterHeadCentered from "./BankPaperLetterHeadCentered";
import BankPaperLetterHead from "./BankPaperLetterHead";
import BillDescriptionBankPaper from "./BillDescriptionBankPaper";
import { RootState } from "../../../store";
import usePrintShortcut from "../../../hooks/usePrintShortcut";
import useLabIdsFromBills from "../../../hooks/useLabIdsFromBills";
import { getCustomerNumber } from "../../Client/ClientList";
import useOpenPrintWindow from "../../../hooks/useOpenPrintWindow";

interface PrintBillProps {
  buttonText?: string;
  billId: number;
  billData: BillType;
  customerNumber: string | null;
  useBordered: boolean;
  labelStyle?: React.CSSProperties;
  forceTemplate?: string | null;
  clientBalance: number;
  addPrintCount?: boolean;
  onPrintClick?: () => void;
  showDobOnPrint?: boolean;
  hidePaymentMethodOnPrint?: boolean;
  isForPreview?: boolean;
}

const getPrintTitle = (billStatus: string, billType: string): string => {
  if (billType === DocumentTypes.CREDITNOTE) {
    return `Credit Note`;
  }
  if (billStatus === BillStatus.draft) {
    return `${t("billing.draft")}`;
  }
  if (billStatus === BillStatus.cancelled) {
    return `${t("billing.invoice")} (${t("billing.cancelled")})`;
  }
  return `${t("billing.invoice")}`;
};

const BankPaperPrint: FunctionComponent<PrintBillProps> = ({
  billId,
  billData,
  buttonText,
  labelStyle,
  useBordered = false,
  forceTemplate,
  clientBalance,
  addPrintCount,
  onPrintClick,
  showDobOnPrint = false,
  hidePaymentMethodOnPrint = false,
  isForPreview = false
}) => {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const dispatch = useDispatch();
  const printRef = React.useRef();
  const reactToPrintRef = React.useRef(null);
  const { resourceCentres } = useSelector((state: RootState) => state.resources);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const resourceCentre = resourceCentres.find((rc) => rc.id === rcId);
  const SPs = useSelector((state: RootState) => state.resources.resourceCentreServiceProviders);
  const labIdsForBill = useLabIdsFromBills(billData);
  const getCustomPrintStyle = () => {
    if (forceTemplate === ForceTemplate.BANK_PAPER) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({
        pageStyle: "size: 13cm 24.5cm; margin: 0mm"
      }).applyPrintStyles();
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({
        pageStyle: "size: A4; margin: 0mm"
      }).applyPrintStyles();
    }
  };
  usePrintShortcut(reactToPrintRef, getCustomPrintStyle);

  useOpenPrintWindow(reactToPrintRef, getCustomPrintStyle);

  if (!billData || !billData.document) {
    return null;
  }

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showFooter = resourceCentre?.settings?.printSettings.billPrintSettings?.includeFooter;

  const hideFooterCompletely =
    resourceCentre.settings.printSettings.billPrintSettings.hideFooterLogoCompletely;

  const showReferrer = resourceCentre?.settings?.printSettings.includeReferrer;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const customSubtitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
  const hideRcLogo = Boolean(customTitle);
  let letterHeadComponent = <></>;
  if (showLetterhead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <BankPaperLetterHeadCentered
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          hideRcLogo={hideRcLogo}
        />
      );
    } else {
      letterHeadComponent = (
        <BankPaperLetterHead
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    }
  }
  return (
    <>
      <ReactToPrint
        onAfterPrint={() => {
          window.focus();
          if (addPrintCount) {
            dispatch(registerPrint(billId));
          }
          setIsPrinting(false);
        }}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div>
            <Tooltip arrow title="Alt + P / Ctrl + P">
              <Link
                className="actionBtn"
                component="button"
                onMouseEnter={() => {
                  getCustomPrintStyle();
                }}
                onClick={() => {
                  if (isPrinting) return;
                  setIsPrinting(true);
                }}
                style={{ textDecoration: "none", padding: "5px 15px" }}
                ref={reactToPrintRef}
              >
                <Typography component="span" style={labelStyle}>
                  <Box className="actionBtnLabel" component="span">
                    {buttonText || "Print"}
                  </Box>
                </Typography>
              </Link>
            </Tooltip>
          </div>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterhead && (
                <tr>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box
                      style={{ ...getSectionStyle(headerColor).headerStyle, marginBottom: "0px" }}
                    >
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <Box
                      m="0.3cm 0cm"
                      textAlign="left"
                      fontWeight={400}
                      style={{ textTransform: "uppercase" }}
                    >
                      {getPrintTitle(billData.status, billData.type)}
                      {!isForPreview && billData?.printCount
                        ? ` (COPY - ${billData?.printCount})`
                        : ""}
                    </Box>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="space-between"
                      borderTop="1px solid black"
                      borderRight={useBordered ? "1px solid black" : ""}
                      borderBottom={useBordered ? "1px solid black" : ""}
                      borderLeft={useBordered ? "1px solid black" : ""}
                      pt={1}
                    >
                      <Box component="div" width="50%">
                        {checkWalkInCustomer(billData.client, "isWalkInCustomer") ? (
                          <BillClientInfoBankPaper
                            forceTemplate={forceTemplate}
                            client={billData.client}
                            customerNumber={getCustomerNumber(billData.customer?.customerNumber)}
                            showDobOnPrint={showDobOnPrint}
                          />
                        ) : (
                          <InfoFieldForBankPaper
                            labelStyle={{ width: "50px" }}
                            label={
                              billData.type === DocumentTypes.CREDITNOTE
                                ? tl("billing.creditNoteNumber")
                                : tl("billnumber")
                            }
                            data={<>{billData.billNumber}</>}
                          />
                        )}
                        {labIdsForBill && (
                          <InfoField label={tl("billing.labId")} data={<>{labIdsForBill}</>} />
                        )}
                        <Barcode
                          value={billId}
                          width={1}
                          displayValue={false}
                          fontSize={5}
                          height={20}
                          margin={2}
                          font="arial"
                        />
                      </Box>

                      <Box component="div" width="40%">
                        {billData.ipd && (
                          <InfoFieldForBankPaper
                            label={<>IPD No.</>}
                            data={<>{billData.ipd.ipdNumber || ""}</>}
                          />
                        )}
                        {billData.document.bed?.name && (
                          <InfoFieldForBankPaper
                            label={<>Bed No.</>}
                            data={<>{billData.document.bed.name}</>}
                          />
                        )}
                        {checkWalkInCustomer(billData.client, "isWalkInCustomer") && (
                          <InfoFieldForBankPaper
                            label={
                              billData.type === DocumentTypes.CREDITNOTE
                                ? tl("billing.creditNoteNumber")
                                : tl("billnumber")
                            }
                            data={<>{billData.billNumber}</>}
                          />
                        )}
                        {billData.type === DocumentTypes.CREDITNOTE && (
                          <InfoFieldForBankPaper
                            label={tl("billing.creditNoteForBill")}
                            data={<>{billData.related?.billNumber}</>}
                          />
                        )}
                        <InfoFieldForBankPaper
                          label={tl("billing.issueDate")}
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                convertADtoBS(moment(billData.issueDate)).formatted
                              )}{" "}
                              {moment(billData.issueDate).format("h:mm a")}
                            </>
                          }
                        />
                        <InfoFieldForBankPaper
                          label={tl("billing.dateAD")}
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                moment(billData.issueDate).format("YYYY/MM/DD")
                              )}{" "}
                            </>
                          }
                        />
                        {billData.document.referredBy && (
                          <InfoFieldForBankPaper
                            label={tl("booking.referredBy")}
                            data={<>{billData.document.referredBy.toUpperCase()}</>}
                          />
                        )}
                        {billData.document.extraReferrer && (
                          <InfoFieldForBankPaper
                            label="Extra Referrer"
                            data={<>{billData.document.extraReferrer.name.toUpperCase()}</>}
                          />
                        )}
                        {billData.ipd?.doctorInCharge && (
                          <InfoFieldForBankPaper
                            label={<>Consulted by</>}
                            data={
                              <>
                                {`${billData.ipd.doctorInCharge?.firstName || ""} ${
                                  billData.ipd.doctorInCharge?.lastName || ""
                                }`}
                              </>
                            }
                          />
                        )}
                        {billData.insuranceNumber && (
                          <InfoFieldForBankPaper
                            label={tl("billing.insuranceNumber")}
                            data={<>{billData.insuranceNumber} </>}
                          />
                        )}
                        {billData.claimNumber && (
                          <InfoFieldForBankPaper
                            label={tl("billing.claimNumber")}
                            data={<>{billData.claimNumber} </>}
                          />
                        )}
                        {billData?.document?.ssf?.id && (
                          <InfoFieldForBankPaper
                            label={tl("billing.ssid")}
                            data={<>{billData.document.ssf.id}</>}
                          />
                        )}

                        {billData?.document?.ssf?.id && billData?.document?.ssf?.scheme?.value && (
                          <InfoFieldForBankPaper
                            label={tl("billing.scheme")}
                            data={
                              <>
                                {`${billData.document.ssf.scheme.label} (${billData.document.ssf.scheme.value})`}
                              </>
                            }
                          />
                        )}

                        {billData?.document?.ssf?.id &&
                          billData?.document?.ssf?.subProduct?.value && (
                            <InfoFieldForBankPaper
                              label={tl("billing.subScheme")}
                              data={
                                <>
                                  {`${billData.document.ssf.subProduct.label} (${billData.document.ssf.subProduct.value})`}
                                </>
                              }
                            />
                          )}
                      </Box>
                    </Box>

                    {/* check the version and load bill content accordingly,
                    may use switch case if third version comes */}
                    {isOldFormatBill(billData.document.version) ? (
                      <DescribeBillV1 billData={billData} SPs={SPs} />
                    ) : (
                      <BillDescriptionBankPaper
                        hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                        billData={billData}
                        SPs={SPs}
                        relatedSettings={{
                          showReferrer,
                          isBordered: useBordered,
                          headerColor
                        }}
                        forceTemplate={forceTemplate}
                        clientBalance={clientBalance}
                      />
                    )}
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showFooter ? (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <img src={logo} alt="logo" height="28px" />
            </Box>
          ) : (
            <Box width="100%" className="billFooterPos">
              <Box style={{ paddingRight: "10mm" }} mt={2} display="flex" justifyContent="flex-end">
                {!hideFooterCompletely && <img src={logo} alt="logo" height="28px" />}
              </Box>
              <Box height="2cm" />
            </Box>
          )}
        </div>
      </iframe>
    </>
  );
};

BankPaperPrint.defaultProps = {
  buttonText: "Print",
  labelStyle: {},
  forceTemplate: null,
  addPrintCount: true,
  onPrintClick: () => null,
  showDobOnPrint: false
};

export default BankPaperPrint;

import React, { useCallback, useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';

interface FilePreviewProps {
  onClose: () => any;
  fileDetails: {
    name: string;
    url: string;
  };
}
const useStyles = makeStyles((theme) => ({
  main: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(8, 8, 8, 0.8)',
    color: 'white !important',
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  download: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px'
  },
  mainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    maxHeight: '100%',
    objectFit: 'contain'
  }
}));

const IMAGE_EXTENSIONS = ['bmp', 'gif', 'ico', 'jpg', 'jpeg', 'png'];

export const FilePreview: React.FC<FilePreviewProps> = ({ onClose, fileDetails }) => {
  const classes = useStyles();
  const extension = fileDetails.name?.substr(fileDetails.name.lastIndexOf('.') + 1);
  const showPreview = IMAGE_EXTENSIONS.includes(extension.toLowerCase());

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Box className={classes.main}>
      <Box className={classes.container}>
        <Typography>{fileDetails.name}</Typography>
        <Box className={classes.innerContainer}>
          {fileDetails && fileDetails.url && (
            <Typography className={classes.download}>
              <GetAppSharpIcon />
              <Link
                href={fileDetails.url}
                target="_blank"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <p>Download</p>
              </Link>
            </Typography>
          )}
          <CancelIcon onClick={onClose}></CancelIcon>
        </Box>
      </Box>
      {showPreview ? (
        <img src={fileDetails.url} className={classes.mainContent} />
      ) : (
        <Typography className={classes.mainContent} style={{ height: '50%' }}>
          Preview not available
        </Typography>
      )}
    </Box>
  );
};

import moment from "moment";
import * as React from "react";
import * as calF from "../functions/calendarFunctions";
import MonthView from "./MonthView";
import YearMonthSwitch from "./YearMonthSwitch";

export interface CalendarProps {
  defaultDate?: Date;
  className?: string;
  maxValidDate?: Date;
  minValidDate?: Date | null;
  onChange?: (date: Date) => void;
}

export interface CalendarStates {
  activeDate: Date;
  activeBsDate: { bsYear: number; bsMonth: number; bsDate: number };
}

export default class Calendar extends React.Component<CalendarProps, CalendarStates> {
  public constructor(props: CalendarProps, state: Record<string, unknown>) {
    super(props, state);
    const date = props.defaultDate || new Date();
    this.state = {
      activeDate: date,
      activeBsDate: calF.convertADtoBS(date)
    };
  }

  private switchViewsYearMonth(year: number, month: number) {
    const { activeBsDate } = this.state;
    this.setState((previousState) => ({
      ...previousState,
      activeBsDate: { bsYear: year, bsMonth: month, bsDate: activeBsDate.bsDate }
    }));
  }

  public render(): JSX.Element {
    const {
      activeBsDate: { bsYear, bsMonth },
      activeDate
    } = this.state;
    const { className, onChange, maxValidDate, minValidDate } = this.props;
    return (
      <div
        role="none"
        className={`${className || ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <YearMonthSwitch
          defaultMonth={bsMonth}
          defaultYear={bsYear}
          onSwitch={(year, month) => this.switchViewsYearMonth(year, month)}
        />
        <MonthView
          viewBsYear={bsYear}
          viewBsMonth={bsMonth}
          maxValidDate={maxValidDate}
          minValidDate={minValidDate}
          defaultActiveDate={activeDate}
          onDayClicked={(date): void => onChange && onChange(date)}
        />
      </div>
    );
  }
}
Calendar.defaultProps = {
  defaultDate: new Date(),
  className: "",
  maxValidDate: "",
  minValidDate: moment(),
  onChange: () => ({})
};

import React from "react";
import "./styles.scss";
import { Box, Typography } from "@mui/material";
import List from "../../../../components/List";
import { tl } from "../../../../components/translate";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { BillDocumentType } from "../../../../interfaces/BillInterfaces";

const processBillItems = (billItems) =>
  billItems.reduce((acc, item) => {
    acc.push(item);
    if (item.subItems) {
      item.subItems.forEach((subItem) => {
        acc.push({
          ...subItem,
          description: `${subItem.description}`
        });
      });
    }
    return acc;
  }, []);

const BillDraftItems = ({ draft }: BillDocumentType): JSX.Element => {
  const billItemsProcessed = React.useMemo(
    () => processBillItems(draft?.billItems),
    [draft?.billItems]
  );

  return (
    <Box className="billItemsList">
      <List
        // rowHeight={30}
        showListHeader={false}
        data={billItemsProcessed}
        activeRow={null}
        adjustHeightToContents
        columns={[
          {
            key: "description",
            label: tl("billItem.description"),
            formatter: ({ description }) => <Typography fontSize="12px">{description}</Typography>
          },
          {
            key: "quantity",
            label: tl("billItem.quantity"),
            formatter: ({ quantity, unit }) => (
              <Typography fontSize="12px">
                {quantity} {unit}
              </Typography>
            )
          },
          {
            key: "perUnit",
            label: tl("billItem.perUnit"),
            formatter: ({ perUnit }) => (
              <Typography fontSize="12px">{rupeeDisplay(perUnit)}</Typography>
            )
          },
          {
            key: "total",
            label: tl("billItem.grossTotal"),
            formatter: ({ grossTotal }) => (
              <Typography fontSize="12px">{rupeeDisplay(grossTotal)}</Typography>
            )
          }
        ]}
      />
    </Box>
  );
};

export default BillDraftItems;

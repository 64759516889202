import { KeyIndicators, WeeklyRevenues } from "../interfaces/DashboardInterface";
import { Get } from "./apiHelper";

const baseRoot = "/api/stat";

export async function getDashboardKeyIndicatorsData(
  startTime: string,
  endTime: string,
  branchId?: string,
  refreshData?: boolean,
  departmentId?: boolean
): Promise<KeyIndicators> {
  const response = await Get(
    `${baseRoot}/keyIndicators?from=${startTime}&until=${endTime}${
      branchId ? `&branchId=${branchId}` : ""
    }${refreshData ? `&refreshData=${refreshData}` : ""}${
      departmentId ? `&departmentId=${departmentId}` : ""
    }`
  );
  return response?.data as KeyIndicators;
}
export async function getDashboardWeeklyRevenueData(): Promise<WeeklyRevenues> {
  const response = await Get(`${baseRoot}/weeklyRevenues`);
  return response.data as WeeklyRevenues;
}

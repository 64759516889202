import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { EmptyView } from "../../../../components/OList";
import {
  FiltersInterface,
  TrialBalanceInterface
} from "../../../../interfaces/AccountReportsInterface";
import { roundOffWithRupeeDisplay } from "../../../../helpers/number";

const TrialBalanceList = ({
  data,
  filters
}: {
  data: TrialBalanceInterface[];
  filters: FiltersInterface;
}): React.ReactElement => {
  const date = filters?.periodDate?.toISOString() || new Date().toISOString();
  const dispatch = useDispatch();
  if (!data) {
    return (
      <EmptyView>
        <Box textAlign="center" padding="50px">
          No items to show.
        </Box>
      </EmptyView>
    );
  }
  return (
    <div>
      {data.map((primaryLedger) => (
        <div key={primaryLedger.primaryLedgerName}>
          <Box className="primary-ledger-cell">
            <Typography className="accounts-list-primary-name">
              {primaryLedger.primaryLedgerName}
            </Typography>
          </Box>
          {primaryLedger.secondaryLedgers?.map((secondaryLedger) => (
            <div key={secondaryLedger.id}>
              <Box className="secondary-ledger-cell">
                <Typography className="account-list-secondary-name">
                  {secondaryLedger.secondaryLedgerName}
                </Typography>
              </Box>
              {secondaryLedger.accountingGroups.map((acGroup) => (
                <>
                  <Box className="cell" key={acGroup.id}>
                    <Typography style={{ fontWeight: 500, fontSize: "16px", paddingLeft: "54px" }}>
                      {acGroup.accountingGroupName}
                    </Typography>
                  </Box>
                  {acGroup.childGeneralLedgers.map((childGl) => (
                    <Box key={childGl.id}>
                      <Box className="child-ledger-cell">
                        <Box
                          display="flex"
                          justifyContent="center"
                          className="child-ledger-cell-ledgerId"
                        >
                          <Typography
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                push(
                                  `/reports/account/generalLedger?ledgerId=${childGl.id}&date=${date}`
                                )
                              );
                            }}
                            color="primary"
                            sx={{ textDecoration: "underline" }}
                          >
                            {childGl.code}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ flexGrow: `${filters.showExtraColumns ? "" : 1}` }}
                          className="child-ledger-body"
                        >
                          {childGl.ledgerName}
                        </Typography>
                        {filters.showExtraColumns && (
                          <>
                            <Typography className="child-ledger-body">
                              {childGl.debitOpeningBalance !== 0 &&
                                `${roundOffWithRupeeDisplay(childGl.debitOpeningBalance)}`}
                            </Typography>
                            <Typography className="child-ledger-body">
                              {childGl.creditOpeningBalance !== 0 &&
                                `${roundOffWithRupeeDisplay(childGl.creditOpeningBalance)}`}
                            </Typography>
                            <Typography className="child-ledger-body">
                              {childGl.duringTheYearDebitBalance !== 0 &&
                                `${roundOffWithRupeeDisplay(childGl.duringTheYearDebitBalance)}`}
                            </Typography>
                            <Typography className="child-ledger-body">
                              {childGl.duringTheYearCreditBalance !== 0 &&
                                `${roundOffWithRupeeDisplay(childGl.duringTheYearCreditBalance)}`}
                            </Typography>
                          </>
                        )}
                        <Typography className="child-ledger-body">
                          {childGl.debit !== 0 && `${roundOffWithRupeeDisplay(childGl.debit)}`}
                        </Typography>
                        <Typography className="child-ledger-body">
                          {childGl.credit !== 0 && `${roundOffWithRupeeDisplay(childGl.credit)}`}
                        </Typography>
                      </Box>
                      {!filters.showTrialBalanceSummary &&
                        childGl.subLedgers?.map((subLedger, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Box key={i} className="child-sub-ledger-cell">
                            <Box className="child-ledger-cell-ledgerId" />
                            <Typography className="child-ledger-body">
                              {subLedger.subLedgerName}
                            </Typography>
                            <Typography className="child-ledger-body">
                              {subLedger.debit !== 0 &&
                                `${roundOffWithRupeeDisplay(Math.abs(subLedger.debit))}`}
                            </Typography>
                            <Typography className="child-ledger-body">
                              {subLedger.credit !== 0 &&
                                `${roundOffWithRupeeDisplay(Math.abs(subLedger.credit))}`}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  ))}
                </>
              ))}
            </div>
          ))}
        </div>
      ))}
      <Box sx={{ padding: "1rem 1.625rem 1rem 1rem", display: "flex" }}>
        <Typography
          className="total-amount-value"
          sx={{ flexBasis: "160px", flexGrow: 1, paddingLeft: "2rem" }}
        >
          Total Amount
        </Typography>
        <Typography className="total-amount-value">
          {roundOffWithRupeeDisplay(data.reduce((acc, child) => child.totalDebit + acc, 0))}
        </Typography>
        <Typography className="total-amount-value">
          {roundOffWithRupeeDisplay(data.reduce((acc, child) => acc + child.totalCredit, 0))}
        </Typography>
      </Box>
    </div>
  );
};

export default TrialBalanceList;

import { createSlice } from "@reduxjs/toolkit";

const appStatus = createSlice({
  name: "inMaintenance",
  initialState: false as boolean,
  reducers: {
    turnOnMaintenance() {
      return true;
    },
  },
});

export const { turnOnMaintenance } = appStatus.actions;
export default appStatus.reducer;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Box, AppBar, Tabs, Tab } from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import produce from "immer";
import { tl } from "../../../components/translate";
import OrderableSort from "../../../components/OrderableSort";
import { resourceCentreActions } from "../../../actions";
import { notificationAdd } from "../../../actions/notification";
import SettingsV2 from "../SettingsV2/List";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box style={{ height: "calc(100vh - 176px)", overflowY: "auto" }}>{children}</Box>
      )}
    </div>
  );
}

const LabSettings = ({ categorySortOrder, resourceCentres, actions, addNotification }) => {
  const resourceCentre = resourceCentres.length > 0 ? resourceCentres[0] : null;

  const [tab, setTab] = useState(0);
  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
  }
  return (
    <Box sx={{ width: "100%", height: "auto", px: "20px" }}>
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: "none", borderBottom: "1px solid lightgrey" }}
      >
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={tl("lab.masterData")} data-testmation="v2settings" {...a11yProps(0)} />
          <Tab label={tl("lab.order")} data-testmation="labTests" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0} style={{ padding: 0 }}>
        <SettingsV2 />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div>
          <OrderableSort
            toOrder={categorySortOrder.map((item) => ({ ...item, label: item.category }))}
            onSave={async (order) => {
              const orderedCategorySortOrder = order.map((id) => {
                const relatedItem = categorySortOrder.find((item) => item.id === id);
                return relatedItem;
              });
              if (resourceCentre) {
                const updatedResourceCentre = produce(resourceCentre, (draft) => {
                  draft.labSettings.categoryOrder = orderedCategorySortOrder;
                });
                await actions.putResourceCentre(updatedResourceCentre);
                addNotification();
              }
            }}
          />
        </div>
      </TabPanel>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    categorySortOrder: state.userContext?.resourceCentre.labSettings.categoryOrder || [],
    resourceCentres: state.resources.resourceCentres
  };
}

export default connect(mapStateToProps, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch),
  addNotification: () => {
    dispatch(
      notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "success",
        message: "Category order updated successfully!",
        autoTimeout: true
      })
    );
  }
}))(LabSettings);

import React from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import SettingsIcon from "@mui/icons-material/Settings";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  ClickAwayListener,
  IconButton
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { tl } from "../../../components/translate";
import { updateStockSettings } from "../../../actions/stock";
import {
  DiscountBasis,
  DiscountSettings,
  DiscountTypes
} from "../../../interfaces/StockInterfaces";
import { IThunkDispatch, RootState } from "../../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  buttonStyle: {
    height: "40px",
    marginLeft: "100px",
    backgroundColor: "#e0e0e0",
    padding: "0px 20px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.64)"
  }
}));

interface PurchaseSettingsProps {
  discountSettings: DiscountSettings;
  actions: {
    updateStockSettings: (settings: DiscountSettings) => void;
  };
}

const RadioComponent = ({ name, checked, label, changeHandler }) => (
  <Box minWidth="200px">
    <FormControlLabel
      control={
        <Radio
          size="small"
          name={name}
          onChange={changeHandler}
          checked={checked}
          color="primary"
        />
      }
      label={label}
    />
  </Box>
);

const PurchaseDiscountSetting = ({ discountSettings, actions }: PurchaseSettingsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement>(null);
  const [disable, setDisable] = React.useState<boolean>(true);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const [state, setState] = React.useState(
    discountSettings || {
      discountType: DiscountTypes.PERCENT,
      discountBasis: DiscountBasis.INLINE
    }
  );

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setState(discountSettings);
      setDisable(true);
    }, 500);
  };

  const changeHandler = ({ target }) => {
    setDisable(false);
    if (Object.values(DiscountTypes).includes(target.name) && state.discountType !== target.name) {
      setState({ ...state, discountType: target.name });
    }
    if (Object.values(DiscountBasis).includes(target.name) && state.discountBasis !== target.name) {
      setState({ ...state, discountBasis: target.name });
    }
  };

  React.useEffect(() => {
    if (discountSettings) {
      setState(discountSettings);
      actions.updateStockSettings(discountSettings);
    }
  }, [discountSettings, actions.updateStockSettings]);

  return (
    <div className={classes.root}>
      <IconButton color="primary" aria-haspopup="true" onClick={openMenu} tabIndex={-1}>
        <SettingsIcon />
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom"
        style={{ zIndex: 999 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper style={{ padding: "10px" }}>
            <Box style={{ marginLeft: "20px" }}>
              <Box style={{ margin: "20px 0px" }} fontSize="20px" fontWeight="bold">
                {tl("stock.setting")}
              </Box>
              <Typography>{tl("billingSettings.discountBasis")}</Typography>
              <Box marginBottom="15px" display="flex">
                <RadioComponent
                  name="invoice"
                  changeHandler={changeHandler}
                  checked={state.discountBasis === DiscountBasis.TOTAL}
                  label={tl("billingSettings.discountBasedOn.invoiceTotal")}
                />
                <RadioComponent
                  name="inline"
                  changeHandler={changeHandler}
                  checked={state.discountBasis === DiscountBasis.INLINE}
                  label={tl("billingSettings.discountBasedOn.invoiceLine")}
                />
              </Box>
              <Typography>{tl("billingSettings.discountType")}</Typography>
              <Box marginBottom="20px" display="flex">
                <RadioComponent
                  changeHandler={changeHandler}
                  name="percent"
                  checked={state.discountType === DiscountTypes.PERCENT}
                  label={tl("billingSettings.discountType.percentage")}
                />
                <RadioComponent
                  changeHandler={changeHandler}
                  name="amount"
                  checked={state.discountType === DiscountTypes.AMOUNT}
                  label={tl("billingSettings.discountType.amount")}
                />
              </Box>
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  disabled={disable}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAnchorEl(null);
                    setDisable(true);
                    actions.updateStockSettings(state);
                  }}
                >
                  {tl("stock.save")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    discountSettings: state.resources.resourceCentres[0]?.settings?.stockSettings?.discountSettings
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      updateStockSettings: (setting) => dispatch(updateStockSettings(setting))
    }
  })
)(PurchaseDiscountSetting);

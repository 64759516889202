import actionCreatorFactory, { Action, ActionCreator, AnyAction } from 'typescript-fsa';
import upperFirst from 'lodash/upperFirst';
import { api } from '../api/apiHelper';
import * as errorActions from './error';

export type AsyncActionCreator<T> = ActionCreator<() => Promise<T>>;

export type AsyncAction<T> = Action<() => Promise<T>>;

export const actionCreator: any = actionCreatorFactory();

export type ErrorActionCreator<ErrorType> = (error: ErrorType) => Action<ErrorType>;

export function actionCreatorAsync<T, E = {}>(
  type: string,
  handler: () => Promise<T>,
  errorActionCreator?: ErrorActionCreator<E>
): AsyncAction<T> {
  const asyncCreator: AsyncActionCreator<T> = Object.assign(
    (payload: () => Promise<T>) => ({ type, payload, meta: { async: true, errorActionCreator } }),
    { type, match: (action: AnyAction): action is Action<() => Promise<T>> => true }
  );
  return asyncCreator(handler);
}

export function createResourceActions(resource) {
  const resourceName = upperFirst(resource);
  const getItemsType = `GET_${resource.toUpperCase()}s`;
  const getItemByIdType = `GET_${resource.toUpperCase()}`;
  const postItemType = `POST_${resource.toUpperCase()}`;
  const putItemType = `PUT_${resource.toUpperCase()}`;
  const deleteItemType = `DELETE_${resource.toUpperCase()}`;
  return {
    [`get${resourceName}s`]: (options = {}, next?) => actionCreatorAsync(
      getItemsType,
      async () => {
        const response = await api(resource).getAll(options);
        next && next(response.data);
        return response.data;
      },
      errorActions.addGeneralErrorCreator(getItemsType)
    ),
    [`get${resourceName}`]: (id, next?) => actionCreatorAsync(
      getItemByIdType,
      async () => {
        const response = await api(resource).get(id);
        next && next(response.data);
        return response.data;
      },
      errorActions.addGeneralErrorCreator(getItemByIdType)
    ),
    [`post${resourceName}`]: (data, next?) => actionCreatorAsync(
      postItemType,
      async () => {
        const response = await api(resource).post(data);
        next && next(response.data);
        return response.data;
      },
      errorActions.addGeneralErrorCreator(postItemType)
    ),
    [`put${resourceName}`]: (data, next?) => actionCreatorAsync(
      putItemType,
      async () => {
        const response = await api(resource).put(data.id, data);
        next && next(response.data);
        return response.data;
      },
      errorActions.addGeneralErrorCreator(putItemType)
    ),
    [`delete${resourceName}`]: (id, next?) => actionCreatorAsync(
      deleteItemType,
      async () => {
        const response = await api(resource).delete(id);
        next && next(response.data);
        return id;
      },
      errorActions.addGeneralErrorCreator(deleteItemType)
    )
  };
}

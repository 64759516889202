import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { employeeFullNameSelector, employeesSortedSelector } from "../../../reducers/employee";
import { RootState } from "../../../store";
import { serviceProvidersSortedSelector } from "../../../reducers/serviceProvider";
import List from "../../../components/List";
import { EmptyView } from "../../../components/OList";
import { t, tl } from "../../../components/translate";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { Employee } from "../../../interfaces/User";
import style from "../style.module.css";
import UserDetails from "./UserDetails";

export type EmployeeInterface = Employee & ServiceProvider;

const getUserType = (nonUserEmployee, isSp) => {
  if (nonUserEmployee) return "Employee Only";
  if (isSp) return "Service Provider";
  return "Employee";
};

export default function AllUsers(): JSX.Element {
  const employees = useSelector((state: RootState) => employeesSortedSelector(state));

  const serviceProviders = useSelector((state: RootState) => serviceProvidersSortedSelector(state));
  const allUsers = React.useMemo(
    () =>
      [...employees, ...serviceProviders.map((sp) => ({ ...sp, isSp: true }))].map(
        // adding index as identifier for list component
        (item, index) => ({ ...item, id: index })
      ),
    [employees, serviceProviders]
  );
  const [selectedEmployee, setSelectedEmployee] = React.useState<EmployeeInterface | null>(null);
  return (
    <div className={style.listContainer}>
      <List
        automation="allUsersList"
        rowHeight={50}
        hideCreateButton
        columns={[
          {
            key: "name",
            label: "Name",
            sortable: true,
            sortFunction: (a, b) =>
              `${a.firstName}${a.lastName}`.toLowerCase() >
              `${b.firstName}${b.lastName}`.toLowerCase()
                ? 1
                : -1,
            formatter: (emp) => (
              <Typography variant="body2">
                {employeeFullNameSelector({ firstName: emp.firstName, lastName: emp.lastName })}
              </Typography>
            )
          },
          {
            key: "userType",
            label: "User Type",
            formatter: ({ nonUserEmployee, isSp }) => (
              <Typography>{getUserType(nonUserEmployee, isSp)}</Typography>
            )
          },
          { key: "phone", label: tl("employee.phone") },
          { key: "email", label: tl("employee.email") }
        ]}
        activeRow={selectedEmployee?.index}
        title={`${t("allActiveUsers")} (${allUsers?.length || 0})`}
        data={allUsers}
        onRowClick={(row) => setSelectedEmployee(row)}
      >
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No items to show.
          </Box>
        </EmptyView>
      </List>
      {selectedEmployee && (
        <UserDetails user={selectedEmployee} onClose={() => setSelectedEmployee(null)} />
      )}
    </div>
  );
}

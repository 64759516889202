import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import Ledgers from "./Ledgers";
import Transactions from "./Transactions";
import { tl } from "../../../components/translate";

enum ReferrerTabs {
  TRANSACTIONS = "transactions",
  LEDGERS = "ledgers"
}

type TabTypes = ReferrerTabs.LEDGERS | ReferrerTabs.TRANSACTIONS;
interface Props {
  referrerId: number;
}

export default function ReferrerTransactionAndLedgers({ referrerId }: Props): JSX.Element | null {
  const [tab, setTab] = React.useState<TabTypes>(ReferrerTabs.TRANSACTIONS);
  if (!referrerId) return null;

  const handleChange = (_: React.SyntheticEvent, newValue: TabTypes) => {
    setTab(newValue);
  };
  return (
    <Box mt={1}>
      <TabContext value={tab}>
        <TabList value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={tl("ReferrerTransInBill")} value={ReferrerTabs.TRANSACTIONS} />
          <Tab label={tl("ReferrerLedger")} value={ReferrerTabs.LEDGERS} />
        </TabList>
        <TabPanel value={ReferrerTabs.TRANSACTIONS}>
          <Transactions referrerId={referrerId} />
        </TabPanel>
        <TabPanel value={ReferrerTabs.LEDGERS}>
          <Ledgers referrerId={referrerId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

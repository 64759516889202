import React from "react";
import { upperCase } from "lodash";
import { RichText } from "prismic-reactjs";
import { Grid, Box } from "@mui/material";
import moment from "moment";
import { ReleaseNote } from "../../../api/prismicQuery";
import styles from "./ReleaseNoteContent.module.css";

const RELEASE_COLOR = {
  Update: "#8691a4",
  Feature: "#82cc8a",
  Bugs: "#8b69db"
};

function ReleaseNoteContent({ releaseNotes }: { releaseNotes: ReleaseNote[] }): JSX.Element {
  function renderContents(contents) {
    return (
      <div className={styles.contentWrapper}>
        {contents.map(({ category, category_content: categoryContent }) => (
          <Grid key={category} container spacing={2}>
            <Grid item display="flex" alignItems="center" minWidth={100}>
              <Box
                textAlign="center"
                color="#fff"
                fontSize={10}
                bgcolor={RELEASE_COLOR[category]}
                py={0.5}
                px={1}
                borderRadius={1}
                width={60}
              >
                {upperCase(category)}
              </Box>
            </Grid>
            <Grid item className={styles.content}>
              <RichText render={categoryContent} />
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }
  return (
    <>
      {releaseNotes.map(({ id, data: { body, released_on: releasedOn, version } }) => {
        const items = body[0]?.items || [];
        return (
          <div key={id}>
            <Box pt={3} pb={1}>
              <Box fontSize={14} color="#8691a4">
                {moment(releasedOn).format("DD.MM.YYYY")}
              </Box>
              <Box
                fontWeight="bold"
                fontSize={24}
                color="#000000de"
                borderBottom="2px solid #000000de"
              >
                {version || null}
              </Box>
            </Box>
            {renderContents(items)}
          </div>
        );
      })}
    </>
  );
}

export default ReleaseNoteContent;

import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { push } from "connected-react-router";
import startCase from "lodash/startCase";
import moment from "moment-timezone";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { match as matchInterface } from "react-router";
import * as ipdActions from "../../actions/ipd";
import List, { Filters, MultipleHeadersInterface } from "../../components/List";
import Panel from "../../components/Panel";
import useMobileScreen from "../../hooks/useMobileScreen";
import { IPD, IpdStatus } from "../../interfaces/IpdInterface";
import { fetchBeds, fetchWards } from "../../slices/bedManagementSlice";
import { IThunkDispatch, RootState } from "../../store";
import Can from "../Policy/Can";
import CreateIpdAdmission from "./IpdAdmission/CreateIpdAdmission";
import IpdInfo from "./IpdInfo";
import "./IpdList.scss";
import { getFullName } from "../../helpers/formatters";
import { getIpdtDetails } from "../../api/ipd";
import { notificationAdd } from "../../actions/notification";
import useQueryParams from "../../hooks/useQueryParams";
import PageControl from "../../components/PageControl";
import DischargeForm from "./Discharge/DischargeForm";
import { t, tl } from "../../components/translate";

export enum IpdFilter {
  ALL = "all",
  ADMITTED = "admitted",
  DISCHARGED = "discharged"
}
interface IpdListprops {
  actions: { goto: (url) => void; loadIpdRecords: ({ filter, page, pageSize }) => void };
  mode?: string;
  ipdRecords: Array<IPD>;
  match: matchInterface<{ [x: string]: string }>;
  policies: { [k: string]: boolean };
}
const exposeClientName = (ipd: Array<IPD>): Array<IPD> => {
  const data = ipd.map((item) => ({
    ...item,
    patientFullName:
      `${item?.ipdRecord?.patientRelatedInformation?.firstName} ${item?.ipdRecord?.patientRelatedInformation?.lastName}` ||
      ""
  }));
  return data.sort((a, b) => {
    const c1: Date = new Date(a.created_at);
    const c2: Date = new Date(b.created_at);
    return c2.getTime() - c1.getTime();
  });
};

export const ipdMultipleHeaders = (url: string): MultipleHeadersInterface => ({
  headers: [
    { key: "beds", title: tl("ipd.dashboard"), goto: "/beds" },
    { key: "ipd", title: tl("ipd.clients"), goto: "/ipd?filter=admitted" }
  ],
  url
});

const pageSize = 25;

export const IpdList = ({
  actions,
  mode,
  ipdRecords,
  match,
  policies
}: IpdListprops): JSX.Element => {
  const [page, setPage] = React.useState<number>(0);

  const ipdTabs = Object.values(IpdFilter);
  const query = useQueryParams();
  const filterParam = query.get("filter");

  const [openDischargeForm, setOpenDischargeForm] = React.useState(false);

  const [filter, setFilter] = React.useState(() =>
    ipdTabs.indexOf(filterParam) !== -1 ? filterParam : IpdFilter.ALL
  );
  const totalIpdCount = useSelector((state: RootState) => state.ipd.totalIpds);
  const [showAdmissionEditPanel, setShowAdmissionEditPanel] = React.useState(false);

  const dispatch = useDispatch();
  const { wards, beds } = useSelector((state: RootState) => state.bedManagement);
  React.useEffect(() => {
    if (!wards.length) {
      dispatch(fetchWards());
    }
  }, [wards.length, dispatch]);

  React.useEffect(() => {
    if (!beds.length) {
      dispatch(fetchBeds());
    }
  }, [beds.length, dispatch]);

  React.useEffect(() => {
    setFilter(filterParam || IpdFilter.ALL);
    setPage(0);
  }, [filterParam]);

  React.useEffect(() => {
    actions.loadIpdRecords({ filter, page, pageSize });
  }, [filter, page, actions]);

  const selectedIpd = useSelector((state: RootState) => state.ipd.selectedIpd);
  const isDischargeSummaryEditable =
    moment
      .tz("Asia/kathmandu")
      .diff(moment.tz(selectedIpd?.dateOfDischarge, "Asia/kathmandu"), "day", true) < 3;

  React.useEffect(() => {
    const ipdId = match.params.id;
    const fetchIpdRecord = async (id) => {
      try {
        const selectedIpdRecord = await getIpdtDetails(id);
        dispatch(ipdActions.setSelectedIpdRecord(selectedIpdRecord));
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Error, while fetching ipd details",
            autoTimeout: true
          })
        );
      }
    };
    if (ipdId) {
      fetchIpdRecord(ipdId);
    } else {
      dispatch(ipdActions.setSelectedIpdRecord(null));
    }
  }, [match.params.id, dispatch]);

  const isMobileScreen = useMobileScreen();

  const editButton =
    selectedIpd?.status === IpdStatus.Admitted ? (
      <IconButton
        data-testmation="editButton"
        disabled={selectedIpd?.status === IpdStatus.Discharged && !isDischargeSummaryEditable}
        onClick={() => {
          setShowAdmissionEditPanel(true);
        }}
      >
        <EditIcon />
      </IconButton>
    ) : (
      <Tooltip title="Discharge summary can only be edited within 24 hours discharge." arrow>
        <IconButton
          data-testmation="editButton"
          disabled={selectedIpd?.status === IpdStatus.Discharged && !isDischargeSummaryEditable}
          onClick={() => {
            setOpenDischargeForm(true);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );

  const filterData = {
    filters: [
      { key: IpdFilter.ADMITTED, title: `${t("admitted")}` },
      { key: IpdFilter.DISCHARGED, title: `${t("discharged")}` },
      {
        key: IpdFilter.ALL,
        title: `${t("all")}`
      }
    ]
  };

  return (
    <>
      <Can policyAccessKey="ipd:listIpd">
        <Box className="ipdList">
          <List
            automation="Ipdclients"
            createLabel={isMobileScreen ? "Create" : `${t("createIPD")}`}
            testmationLabel="createIpd"
            onCreateNew={() => actions.goto(`/ipd/new?filter=${filterParam}`)}
            hideCreateButton={!policies["ipd:createIpd"]}
            rowHeight={50}
            defaultSortOrder={-1}
            defaultSortColumn="name"
            multipleHeaders={ipdMultipleHeaders(`/ipd?filter=${IpdFilter.ADMITTED}`)}
            additionalHeaderContent={
              <Filters
                filter={filter}
                onFilter={(newFilter) => {
                  setFilter(newFilter);
                  dispatch(push(`/ipd?filter=${newFilter}`));
                }}
                filterData={filterData}
              />
            }
            isResponsive
            columns={[
              {
                key: "Ipd Number",
                label: "Admission Number",

                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    {row.ipdNumber}
                  </Typography>
                )
              },
              {
                key: "client name",
                label: `${t("ipd.clientName")}`,
                sortable: true,
                sortFunction: (a, b) => (`${a.patientFullName}` > `${b.patientFullName}` ? 1 : -1),

                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    <Box>{row.patientFullName.toUpperCase()}</Box>
                  </Typography>
                )
              },
              {
                key: "Status",
                label: `${t("ipd.status")}`,
                hideInNarrowView: true,
                formatter: (row) => (
                  <Typography
                    component="div"
                    style={{
                      color: `${row.status === "discharged" ? "red" : "green"}`
                    }}
                  >
                    {startCase(row.status)}
                  </Typography>
                )
              },
              {
                key: "Case",
                label: `${t("ipd.caseType")}`,
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    {row.ipdRecord?.case}
                  </Typography>
                )
              },
              {
                key: "Bed",
                label: `${t("ipd.bedNo.")}`,
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    <Box>{row.bed?.number}</Box>
                  </Typography>
                )
              },
              {
                hideInNarrowView: true,
                key: "Doctor In Charge",
                label: `${t("ipd.docInCharge.")}`,
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    <Box>{getFullName(row.doctorInCharge)}</Box>
                  </Typography>
                )
              },
              {
                key: "Ward Code",
                label: `${t("ipd.wardCode")}`,
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    <Box>{row.ward?.shortCode}</Box>
                  </Typography>
                )
              }
            ]}
            data={ipdRecords}
            isLoading={!ipdRecords}
            activeRow={selectedIpd && selectedIpd.id}
            onRowClick={async (ipd) => {
              if (showAdmissionEditPanel) {
                setShowAdmissionEditPanel(false);
              }
              if (openDischargeForm) {
                setOpenDischargeForm(false);
              }
              const notSelected = !selectedIpd || selectedIpd.id !== ipd.id;
              dispatch(ipdActions.setSelectedIpdRecord(notSelected ? ipd : null));
              actions.goto(
                notSelected
                  ? `/ipd/${ipd.id}?filter=${filterParam || IpdFilter.ALL}`
                  : `/ipd?filter=${filterParam || IpdFilter.ALL}`
              );
            }}
          />
        </Box>
        <Box className="navigatePage">
          <PageControl
            page={page}
            pageSize={pageSize}
            onSetPage={(v) => {
              setPage(v);
            }}
            maximumDataCount={totalIpdCount || 0}
          />
        </Box>
      </Can>
      {mode === "create" && (
        <Can policyAccessKey="ipd:createIpd">
          <CreateIpdAdmission
            mode={mode}
            onCancel={() => actions.goto(`/ipd?filter=${filterParam || IpdFilter.ALL}`)}
            stayOnPage={false}
          />
        </Can>
      )}
      {selectedIpd && (
        <Panel
          title={
            selectedIpd.status === IpdStatus.Admitted
              ? `${t("admissionInfo")}`
              : `${t("dischargeInfo")}`
          }
          wrapperStyle={{ minWidth: "860px" }}
          onClose={() => actions.goto(`/ipd?filter=${filterParam || IpdFilter.ALL}`)}
          editButton={editButton}
        >
          <Box pl={2}>
            <IpdInfo
              ipdRecord={selectedIpd}
              id={Number(match.params.id)}
              onDischargeFormOpen={() => setOpenDischargeForm(true)}
            />
          </Box>
        </Panel>
      )}
      {selectedIpd && showAdmissionEditPanel && selectedIpd.status === IpdStatus.Admitted && (
        <CreateIpdAdmission
          mode="edit"
          record={selectedIpd}
          onCancel={() => setShowAdmissionEditPanel(false)}
          setShowAdmissionEditPanel={setShowAdmissionEditPanel}
          stayOnPage={false}
        />
      )}
      {openDischargeForm && selectedIpd && (
        <DischargeForm ipdData={selectedIpd} setOpenDischargeForm={setOpenDischargeForm} />
      )}
    </>
  );
};

IpdList.defaultProps = {
  mode: undefined
};

export default connect(
  (state: RootState) => ({
    ipdRecords: exposeClientName(state.ipd.collection || []),
    policies: state.userContext.policies || {}
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      goto: (url) => dispatch(push(url)),
      loadIpdRecords: ({ filter, page, pageSize: pageLimit }) =>
        dispatch(ipdActions.getIpdRecords({ filter, page, pageSize: pageLimit }))
    }
  })
)(IpdList);

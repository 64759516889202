import React from "react";
import { Box, Typography } from "@mui/material";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import moment from "moment";
import List from "../../../components/List";
import * as hmisActions from "../../../actions/hmis";
import { HmisSummary } from "../../../interfaces/HmisInterface";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import * as calendarFns from "../../../components/Calendar/functions/calendarFunctions";
import { IThunkDispatch, RootState } from "../../../store";
import HmisDetailView from "./HmisDetailView";

interface HmisReportListProps {
  actions: {
    goto: (url) => void;
    getHmisSummary: () => void;
    postSyncHmisSummary: (id) => void;
  };
  summaryRecords: Array<HmisSummary>;
}

const HmisReportList = ({ actions, summaryRecords }: HmisReportListProps) => {
  const [disabledId, setDisabledId] = React.useState<number | null>(null);
  const [selected, setSelected] = React.useState<HmisSummary | null>(null);
  React.useEffect(() => {
    actions.getHmisSummary();
  }, []);

  return (
    <Box overflow="auto hidden">
      <Box height="calc(100vh - 102px)" minWidth="800px">
        <List
          automation="hmisReportList"
          withoutSearch
          hideCreateButton
          rowHeight={50}
          defaultSortOrder={-1}
          defaultSortColumn="name"
          isResponsive
          columns={[
            {
              key: "reportMonth",
              label: "Report Month",

              formatter: ({ period }) => (
                <Typography component="div" variant="body2">
                  {`${period.slice(0, 4)}-${period.slice(4)}`}
                </Typography>
              )
            },
            {
              key: "categoryName",
              label: "Category Name",
              formatter: ({ hmisFormData }) => (
                <Typography component="div" variant="body2">
                  {hmisFormData?.categoryName}
                </Typography>
              )
            },
            {
              key: "createdAt",
              label: "Creation On",
              formatter: ({ createdAt }) => (
                <Typography component="div" variant="body2">
                  {calendarFns.convertADtoBS(new Date(createdAt)).formatted4}
                </Typography>
              )
            },
            {
              key: "Status",
              label: "Status",
              formatter: ({ syncDate, id, hmisFormData }) => (
                <Typography component="div">
                  {syncDate ? (
                    `${calendarFns.convertADtoBS(moment(syncDate)).formatted2} ${moment(
                      syncDate
                    ).format("hh:mm:ss a")}`
                  ) : (
                    <StatefulButton
                      variant="contained"
                      color="primary"
                      onClick={async (e) => {
                        e.stopPropagation(); // prevent
                        setDisabledId(id);
                        await actions.postSyncHmisSummary(id);
                        setDisabledId(null);
                      }}
                      disabled={disabledId === id || !hmisFormData}
                      isLoading={disabledId === id}
                      circularProgressProps={{ size: 16 }}
                    >
                      Sync Now
                    </StatefulButton>
                  )}
                </Typography>
              )
            }
          ]}
          data={[...summaryRecords].sort((a, b) => b.id - a.id)}
          onRowClick={(row) => setSelected(row as HmisSummary)}
        />
        {selected && <HmisDetailView onClose={() => setSelected(null)} data={selected} />}
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    summaryRecords: state.hmis.summary
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      goto: (url) => dispatch(push(url)),
      getHmisSummary: () => dispatch(hmisActions.getHmisSummary()),
      postSyncHmisSummary: (id) => dispatch(hmisActions.postSyncHmisSummary(id))
    }
  })
)(HmisReportList);

import { SHOW_SIGNATURE_ON_LAB_PRINT } from "../components/MultipleButtonGroup";

const useGetShowSignatureOnLabPrint = (): { showSignatureOnLabPrint: boolean } => {
  let showSignatureOnLabPrint = true;
  try {
    const storedValue = localStorage.getItem(SHOW_SIGNATURE_ON_LAB_PRINT);
    showSignatureOnLabPrint = storedValue ? JSON.parse(storedValue) : true;
  } catch (e) {
    // do nothing
    console.log("Please enable access to local storage");
  }
  return { showSignatureOnLabPrint };
};

export default useGetShowSignatureOnLabPrint;

import * as React from "react";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import moment, { Moment } from "moment";
import { Box, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import Calendar, { CalendarFunctions as calFns } from "../Calendar";
import { tl } from "../translate";
import styles from "./CalendarDropdown.module.css";

export interface CalendarDropdownPropsInterface {
  date?: Date;
  maxValidDate?: Date | Moment;
  minValidDate?: Date | Moment;
  onChange?: (date) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  format?: string;
  withTimeSelector?: boolean;
  TextFieldProps?: MuiTextFieldProps;
  TextFieldInputProps?: MuiTextFieldProps;
  fullwidth?: boolean;
  allowNull?: boolean;
  disable?: boolean;
  width?: string;
}

interface CalendarDropdownState {
  date: Date;
  open: boolean;
  anchorEl: HTMLElement;
  isChanged: boolean;
}

export default class CalendarDropdown extends React.Component<
  CalendarDropdownPropsInterface,
  CalendarDropdownState
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    const { allowNull: propAllowNull, date: propDate } = this.props;
    const allowNull = propAllowNull ? "" : new Date();
    this.state = {
      date: propDate || allowNull,
      open: false,
      anchorEl: null,
      isChanged: false
    };
  }

  componentDidUpdate(prevProps: CalendarDropdownPropsInterface): void {
    const { date: propDate } = this.props;
    if (propDate?.toISOString() !== prevProps.date?.toISOString()) {
      this.setState({ date: propDate });
    }
  }

  onChange(date: Date): void {
    const { onChange, withTimeSelector } = this.props;
    if (withTimeSelector) {
      this.setState({
        date,
        isChanged: true
      });
    } else {
      this.setState({
        date,
        open: false
      });
    }
    onChange(date);
  }

  getDisplayDate(): string {
    const { format, withTimeSelector } = this.props;
    const { date } = this.state;
    if (!date) return "Click to select date";
    let formattedDate = calFns.convertADtoBS(date)[format || "formatted4"];
    if (withTimeSelector) {
      formattedDate = `${formattedDate} ${moment(date).format("HH:mm")}`;
    }
    return formattedDate;
  }

  render(): JSX.Element {
    const {
      date: propsDate,
      fullwidth,
      label,
      placeholder,
      disable,
      TextFieldProps,
      TextFieldInputProps,
      maxValidDate,
      minValidDate,
      withTimeSelector,
      width = "120px",
      onChange
    } = this.props;
    const { date: stateDate, open, anchorEl, isChanged } = this.state;

    return (
      <Box sx={{ width: fullwidth ? "100%" : width }}>
        <TextField
          disabled={disable}
          data-testmation="calendarDropdownField"
          margin="dense"
          size="small"
          fullWidth={fullwidth}
          id="calender"
          label={label}
          placeholder={placeholder}
          /* eslint-disable react/jsx-props-no-spreading */
          {...(TextFieldProps || {})}
          value={this.getDisplayDate()}
          onClick={(e) => !disable && this.setState({ open: true, anchorEl: e.currentTarget })}
          InputProps={
            TextFieldInputProps || {
              disableUnderline: true,
              style: { fontSize: "12px", background: "white" }
            }
          }
          style={{ padding: 0, fontSize: "12px", width: fullwidth ? "100%" : width }}
        />
        <Popover
          className={`calendarDropdown ${styles.calendarPopover} ${styles.className}`}
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            this.setState({
              open: false,
              anchorEl: null
            });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Calendar
            defaultDate={propsDate}
            maxValidDate={maxValidDate}
            minValidDate={minValidDate}
            onChange={(d) => {
              const dateNew = moment(d);
              if (stateDate) {
                dateNew.set("hour", stateDate.getHours()).set("minute", stateDate.getMinutes());
              }
              this.onChange(dateNew.toDate());
            }}
          />
          {withTimeSelector && (
            <div className={styles.timeSelect}>
              <Box component="span" paddingRight="16px">
                {tl("time")}
              </Box>
              <TextField
                id="time"
                type="time"
                variant="outlined"
                value={moment(stateDate).format("HH:mm")}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 600, // 5 min
                  className: styles.timeSelectorInput
                }}
                onChange={({ target }) => {
                  const [hour, minute] = target.value.split(":").map(Number);
                  let date: Moment | Date = moment(stateDate).set("hour", hour);
                  date = minute ? date.set("minute", minute) : date.set("minute", 0);
                  date = date.toDate();
                  this.setState({ date, isChanged: true });
                  onChange(date);
                }}
              />
              <Button
                disabled={!isChanged}
                style={{ marginLeft: 16 }}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({ open: false });
                }}
              >
                {tl("periodPicker.ok")}
              </Button>
            </div>
          )}
        </Popover>
      </Box>
    );
  }
}
CalendarDropdown.defaultProps = {
  date: "",
  maxValidDate: "",
  minValidDate: "",
  onChange: () => ({}),
  label: "",
  placeholder: "",
  className: "",
  format: "",
  withTimeSelector: false,
  TextFieldProps: null,
  TextFieldInputProps: null,
  fullwidth: false,
  allowNull: false,
  disable: false,
  width: "120px"
};

type TranslateFunc = (key: string) => string;
export const bsMonths = (t: TranslateFunc): string[] => [
  t("cal.np.months.1"),
  t("cal.np.months.2"),
  t("cal.np.months.3"),
  t("cal.np.months.4"),
  t("cal.np.months.5"),
  t("cal.np.months.6"),
  t("cal.np.months.7"),
  t("cal.np.months.8"),
  t("cal.np.months.9"),
  t("cal.np.months.10"),
  t("cal.np.months.11"),
  t("cal.np.months.12")
];

export const bsDays = (t: TranslateFunc): string[] => [
  t("cal.days.s1"),
  t("cal.days.s2"),
  t("cal.days.s3"),
  t("cal.days.s4"),
  t("cal.days.s5"),
  t("cal.days.s6"),
  t("cal.days.s7")
];

export const bsDaysFull = (t: TranslateFunc): string[] => [
  t("cal.days.1"),
  t("cal.days.2"),
  t("cal.days.3"),
  t("cal.days.4"),
  t("cal.days.5"),
  t("cal.days.6"),
  t("cal.days.7")
];

export const nepaliNumbers = (t: TranslateFunc): string[] => [
  t("num.0"),
  t("num.1"),
  t("num.2"),
  t("num.3"),
  t("num.4"),
  t("num.5"),
  t("num.6"),
  t("num.7"),
  t("num.8"),
  t("num.9")
];

export const bsMonthUpperDays: number[][] = [
  [30, 31],
  [31, 32],
  [31, 32],
  [31, 32],
  [31, 32],
  [30, 31],
  [29, 30],
  [29, 30],
  [29, 30],
  [29, 30],
  [29, 30],
  [30, 31]
];
export const extractedBsMonthData: number[][] = [
  [
    0, 1, 1, 22, 1, 3, 1, 1, 1, 3, 1, 22, 1, 3, 1, 3, 1, 22, 1, 3, 1, 19, 1, 3, 1, 1, 3, 1, 2, 2, 1,
    3, 1
  ],
  [
    1, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 3, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 1, 0, 1, 1, 2, 2, 2,
    2, 2, 1, 3, 1, 1, 2
  ],
  [
    0, 1, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 1, 0, 1, 1, 2, 2,
    2, 2, 2, 1, 3, 1, 1, 2
  ],
  [
    1, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2,
    1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 2, 2, 1, 3, 1, 2,
    2, 2, 1, 2
  ],
  [59, 1, 26, 1, 28, 1, 2, 1, 12],
  [
    0, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1,
    3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 5, 1, 1, 2, 2, 1,
    3, 1, 2, 1, 2
  ],
  [0, 12, 1, 3, 1, 3, 1, 5, 1, 11, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 27, 1, 2],
  [
    1, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 3, 1, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2,
    2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 15, 2, 4
  ],
  [
    0, 1, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 3, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2,
    2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 2, 2, 2, 15, 2, 4
  ],
  [
    1, 1, 3, 1, 3, 1, 14, 1, 3, 1, 1, 1, 3, 1, 14, 1, 3, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 3, 1, 14,
    1, 3, 15, 1, 2, 1, 1
  ],
  [
    0, 1, 1, 3, 1, 3, 1, 10, 1, 3, 1, 3, 1, 1, 1, 3, 1, 3, 1, 10, 1, 3, 1, 3, 1, 3, 1, 3, 1, 14, 1,
    3, 1, 3, 1, 3, 1, 3, 1, 10, 1, 3, 1, 16, 1, 1, 1
  ],
  [
    1, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 3, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2,
    2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 3, 1, 2, 2, 2, 2, 2, 2, 2, 1, 3, 1, 3, 1, 3, 1, 16, 3
  ]
];
export const minBsYear = 1970;
export const maxBsYear = 2100;
export const minAdDateEqBsDate = {
  ad: {
    year: 1913,
    month: 3,
    date: 13
  },
  bs: {
    year: 1970,
    month: 1,
    date: 1
  }
};

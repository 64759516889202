import { Box, Typography, Link } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { startCase } from "lodash";
import * as moment from "moment-timezone";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import Timeline, { Event, EventVariantEnum } from "../Timeline";
import * as calFns from "../Calendar/functions/calendarFunctions";
import { tl } from "../translate";

interface Activity {
  // eslint-disable-next-line camelcase
  created_at: Date;
  username: string;
  entity: string;
  id: number;
  event: string;
  entityId: number | null;
}
interface Props {
  activities: Array<Activity>;
  onActivityClick?: (data: Activity) => void;
  showTime?: boolean;
}

const ActivityTimeline = ({
  activities,
  onActivityClick,
  showTime = false
}: Props): JSX.Element => (
  <Timeline automation="clientActivityTimeline">
    {activities.map((history, i) => (
      <Event
        key={history.id}
        variant={i === 0 ? EventVariantEnum.big : EventVariantEnum.small}
        colour={i === 0 ? "green" : "gray"}
        automation="clientVisitsTimeline"
      >
        <Box paddingLeft="8px" marginBottom="16px">
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (onActivityClick) {
                onActivityClick(history);
              }
            }}
          >
            <Typography color={i === 0 ? "green" : "gray"}>
              <Box
                component="span"
                fontSize="0.7rem"
                fontWeight={600}
                position="relative"
                top="-5px"
              >
                {calFns.convertADtoBS(moment(history.created_at)).formatted2}
                {showTime ? ` (${moment(history.created_at).format("hh:mm a")})` : ""}
              </Box>
            </Typography>
          </Link>
          <Box display="flex" alignItems="center">
            <PersonIcon />
            <Typography ml={2}>{startCase(history.username)}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <EventRepeatIcon sx={{ fontSize: "20px" }} />
            <Typography ml={2}>
              {startCase(history.entity)} {tl(`event.${history.event}`)}
            </Typography>
          </Box>
        </Box>
      </Event>
    ))}
  </Timeline>
);

export default ActivityTimeline;

import { Box, Checkbox, FormControlLabel, Tab, Tabs, TextField, Typography } from "@mui/material";
import produce from "immer";
import React from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { resourceCentreActions } from "../../../actions";
import { AsyncAction } from "../../../actions/actionHelpers";
import ContentEditableWithTextField from "../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import { t, tl } from "../../../components/translate";
import { ReverseMap } from "../../../helpers/types";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { RootState } from "../../../store";
import Can from "../../Policy/Can";
import DiscountSetting from "../ClinicSettings/DiscountSettings";
import ProductCategoryAddition from "../ProductCategoryAddition";
import SectionHeading from "../SectionHeading";
import BillingSetting from "../Settings/BillingSettings";
import CalendarSettings from "../Settings/CalendarSettings";
import AssessmentSettings from "./AssessmentSettings";
import IpdSettings from "./IpdSettings";
import HmisSettings from "../Settings/HmisSettings";
import LabSettings from "./LabSettings";
import QrPaymentInfoForm from "./QrPayment/QrPaymentInfoForm";

enum TAB_ITEMS {
  CALENDAR = "Calendar",
  OPD = "OPD",
  LAB = "Lab",
  BILLING = "Billing",
  STOCK = "Stock",
  IPD = "IPD",
  HMIS = "HMIS"
}

type TabValues = ReverseMap<typeof TAB_ITEMS>;

export interface ModuleSettingsProps {
  actions: {
    [x: string]: (options?: Record<string, unknown>, next?: unknown) => AsyncAction<unknown>;
  };
  resourceCentre: ResourceCentre;
}

const ModuleSettings = ({ resourceCentre, actions }: ModuleSettingsProps) => {
  const [tab, setTab] = React.useState(TAB_ITEMS.BILLING);

  const [settings, setSettings] = React.useState({
    settings: {
      hmisSettings: {
        username: "",
        password: "",
        orgUnit: "",
        hmisFormDataIds: [],
        government: false
      }
    }
  });
  const { hmisSettings } = resourceCentre.settings;
  const [billDefaultRemarks, setBillDefaultRemarks] = React.useState(
    resourceCentre.settings.billingSettings.defaultRemarks || ""
  );

  React.useEffect(() => {
    setSettings({
      ...resourceCentre,
      settings: {
        ...resourceCentre.settings,
        hmisSettings: {
          username: hmisSettings?.username,
          password: hmisSettings?.password,
          orgUnit: hmisSettings?.orgUnit,
          hmisFormDataIds: hmisSettings?.hmisFormDataIds,
          government: hmisSettings?.government
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCentre]);

  const handleChange = (newValue: TabValues) => {
    setTab(newValue);
  };

  const serviceProviders: ServiceProvider[] = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );
  const userGroup: string = useSelector((state) => state.userContext?.userCreds?.userGroups[0]);

  return (
    <Box
      sx={{
        pb: 4,
        height: "calc(100vh - 70px)",
        overflowY: "auto"
      }}
    >
      <SectionHeading name={tl("module.moduleSetting")} />
      <Box
        sx={(theme) => ({
          width: "100%",
          borderBottom: `1px solid ${theme.palette.divider}`,
          marginBottom: "2rem"
        })}
      >
        <Tabs
          value={tab}
          onChange={(_, newValue) => handleChange(newValue)}
          aria-label="modules setting items tab"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab value={TAB_ITEMS.BILLING} label={TAB_ITEMS.BILLING} data-testmation="billingTab" />
          <Tab
            value={TAB_ITEMS.CALENDAR}
            label={TAB_ITEMS.CALENDAR}
            data-testmation="calendarTab"
          />
          <Tab value={TAB_ITEMS.OPD} label={tl("TAB_ITEMS.OPD")} data-testmation="opdTab" />
          <Tab value={TAB_ITEMS.LAB} label={tl("TAB_ITEMS.LAB")} data-testmation="labModuleTab" />
          <Tab value={TAB_ITEMS.STOCK} label={tl("TAB_ITEMS.STOCK")} data-testmation="stockTab" />
          <Tab value={TAB_ITEMS.IPD} label={tl("TAB_ITEMS.IPD")} data-testmation="ipdTab" />
          <Tab value={TAB_ITEMS.HMIS} label={tl("TAB_ITEMS.HMIS")} data-testmation="hmisTab" />
        </Tabs>
      </Box>
      {tab === TAB_ITEMS.CALENDAR && (
        <CalendarSettings
          resourceCentre={resourceCentre}
          updateRc={(newRc) => actions.putResourceCentre(newRc)}
        />
      )}
      {tab === TAB_ITEMS.OPD && (
        <AssessmentSettings resourceCentre={resourceCentre} actions={actions} />
      )}
      {tab === TAB_ITEMS.LAB && (
        <LabSettings
          resourceCentre={resourceCentre}
          actions={actions}
          serviceProviders={serviceProviders}
          userGroup={userGroup}
        />
      )}
      {tab === TAB_ITEMS.BILLING && (
        <>
          <Box display="flex" alignItems="center">
            <BillingSetting
              billingSettings={resourceCentre.settings.billingSettings}
              onChange={(billingSettings) => {
                const updatedResourceCenter = produce(resourceCentre, (draft) => {
                  draft.settings.billingSettings = billingSettings;
                });
                actions.putResourceCentre(updatedResourceCenter);
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" width={0.3}>
            <DiscountSetting
              discountSettings={resourceCentre.settings.billingSettings.discountSettings}
              onChange={(discountSettings) => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.billingSettings.discountSettings = discountSettings;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.billingSettings.syncWithIRD}
                  onChange={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.billingSettings.syncWithIRD =
                        !draft.settings.billingSettings.syncWithIRD;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              }
              label={tl("settings.billSyncWithIRD")}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.billingSettings.enableLabTestCreation}
                  onChange={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.billingSettings.enableLabTestCreation =
                        !draft.settings.billingSettings.enableLabTestCreation;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              }
              label={tl("settings.enableLabTestCreationFromBill")}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.billingSettings.enableCounterBilling}
                  onChange={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.billingSettings.enableCounterBilling =
                        !draft.settings.billingSettings.enableCounterBilling;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              }
              label={tl("settings.enableCounterBilling")}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={
                    resourceCentre.settings.billingSettings.remarksCompulsoryForDiscountedBill
                  }
                  onChange={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.billingSettings.remarksCompulsoryForDiscountedBill =
                        !draft.settings.billingSettings.remarksCompulsoryForDiscountedBill;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              }
              label={tl("settings.remarksCompulsoryForDiscountedBill")}
            />
          </Box>

          <Box mt="8px">
            <Typography fontWeight={600} fontSize="14px">
              {tl("module.remindOn")}
            </Typography>
            <Box display="flex" width="100%" alignItems="center">
              <Checkbox
                size="small"
                checked={resourceCentre.settings.billingSettings.showReminder}
                value={resourceCentre.settings.billingSettings.showReminder}
                onChange={async () => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.settings.billingSettings.showReminder =
                      !resourceCentre.settings.billingSettings.showReminder;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
              <Typography>Show Remind On</Typography>
            </Box>
          </Box>
          <Box mt="8px">
            <Typography fontWeight={600} fontSize="14px">
              {tl("billing.defaultRemarks")}
            </Typography>
            <Box width="400px">
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder={t("billing.defaultRemarks")}
                value={billDefaultRemarks}
                onChange={(event) => {
                  setBillDefaultRemarks(event.target.value);
                }}
                onBlur={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.settings.billingSettings.defaultRemarks = billDefaultRemarks;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            </Box>
          </Box>

          <Box mt="8px">
            <Typography style={{ fontWeight: 600 }}>{tl("settings.billPrint")}</Typography>
            <Box pl="16px">
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        resourceCentre.settings.printSettings.billPrintSettings.includeLetterhead
                      }
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.settings.printSettings.billPrintSettings.includeLetterhead =
                            !resourceCentre.settings.printSettings.billPrintSettings
                              .includeLetterhead;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.includeLetterhead")}
                />
              </Box>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        resourceCentre.settings.printSettings.billPrintSettings.includeFooter
                      }
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.settings.printSettings.billPrintSettings.includeFooter =
                            !resourceCentre.settings.printSettings.billPrintSettings.includeFooter;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.includeFooter")}
                />
                {!resourceCentre.settings.printSettings.billPrintSettings.includeFooter && (
                  <Can policyAccessKey="deny" superAdminPass>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={
                            resourceCentre.settings.printSettings.billPrintSettings
                              .hideFooterLogoCompletely
                          }
                          onChange={() => {
                            const newRc = produce(resourceCentre, (draft) => {
                              // eslint-disable-next-line max-len
                              draft.settings.printSettings.billPrintSettings.hideFooterLogoCompletely =
                                !resourceCentre.settings.printSettings.billPrintSettings
                                  .hideFooterLogoCompletely;
                            });
                            actions.putResourceCentre(newRc);
                          }}
                        />
                      }
                      label={tl("settings.hideFooterCompletely")}
                    />
                  </Can>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={resourceCentre.settings.printSettings.includeReferrer}
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.settings.printSettings.includeReferrer =
                            !draft.settings.printSettings.includeReferrer;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.includeReferrer")}
                />
              </Box>
              <Box mt={2} border="1px solid #E0E0E0" p={1} pb={0} borderRadius={2} maxWidth={600}>
                <Typography fontWeight={600} fontSize={15} mb={1}>
                  A5 Template Settings
                </Typography>
                <ContentEditableWithTextField
                  label={tl("settings.customHeaderTitle")}
                  placeholder="Custom bill print title"
                  saveOnFocusOut
                  value={resourceCentre.settings.printSettings.billPrintSettings.customTitle}
                  onSave={(val) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.printSettings.billPrintSettings.customTitle = val || "";
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  width="300px"
                />
                <ContentEditableWithTextField
                  label={tl("settings.customHeaderSubTitle")}
                  placeholder="Custom bill sub title"
                  saveOnFocusOut
                  value={resourceCentre.settings.printSettings.billPrintSettings.customSubtitle}
                  onSave={(val) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.printSettings.billPrintSettings.customSubtitle = val || "";
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  width="300px"
                />
              </Box>
            </Box>
          </Box>

          <Box mt="20px">
            <QrPaymentInfoForm resourceCentre={resourceCentre} />
          </Box>
        </>
      )}
      {tab === TAB_ITEMS.STOCK && (
        <>
          <Box display="flex" alignItems="center">
            <DiscountSetting
              discountSettings={resourceCentre.settings.stockSettings.discountSettings}
              onChange={(discountSettings) => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.stockSettings.discountSettings = discountSettings;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          </Box>
          <ProductCategoryAddition resourceCentre={resourceCentre} />
        </>
      )}
      {tab === TAB_ITEMS.IPD && (
        <>
          <IpdSettings resourceCentre={resourceCentre} />
        </>
      )}
      {tab === TAB_ITEMS.HMIS && <HmisSettings state={settings} actions={actions} />}
    </Box>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch)
}))(ModuleSettings);

import { ProductInterface } from "./ProductInterface";

export interface TransactionItemInterface {
  batchId: string;
  enteredQuantityUnit: string;
  expiryDate: string;
  grossTotal: number;
  packagePriceExcVAT: string;
  packagePriceIncVAT: string;
  productId: number;
  purchasePrice: number;
  quantity: number;
  salesPriceCalculationOn: string;
  unit: string;
  unitPriceExcVAT: string;
  unitPriceIncVAT: string;
  unitsPerPackage: number;
  vatPct: string;
  productName: string;
  hasFreeItems: boolean;
  freeItemQuantity: number;
  grossTotalOfFreeItems: number;
  vatAmt: number;
}

export interface SupplierInfo {
  name: string;
  id: number;
  ledgerId: number;
}

export interface StockTransactions {
  billId: null | string;
  createdAt: string;
  date: string;
  id: number;
  note: null | string;
  paymentType: string;
  product: Array<ProductInterface>;
  resourceCentreId: number;
  stockTransactionItems: Array<TransactionItemInterface>;
  supplier: SupplierInfo;
  supplierId: number;
  supplierInvoiceId: string;
  totalAmount: number;
  transactionType: string;
  updatedAt: null | string;
  paidAll: boolean;
  paidAmount: number;
  status: string;
  settings: {
    discountSettings: DiscountSettings;
  };
  summary: Summary;
  receipts: Receipts;
  supplierLedgerId: number;
  referenceTo: number;
  dueAmount?: number;
  related: { [key: string]: string | number };
  voucherDate: Date | string;
  returnedTrx: {
    id: number;
    paidAmount: number;
    referenceTo: number;
    summary: {
      discountAmt: number;
      roundOffAmt: string;
      taxAmt: number;
      totalCcAmt: number;
      totalDiscountPct: number;
      totalIncVat: number;
      totalVatAmtOfFreeItems: number;
    };
    supplierInvoiceId: "0120";
    totalAmount: 100;
  }[];
}

export interface Receipts {
  enteredBy: string;
  id: number;
  paidAmount: number;
  paymentMethod: string;
  receivedOn: string | Date;
  remarks: string;
  stockTransactionId: number;
}

export enum Status {
  Draft = "Draft",
  Finalize = "Finalize"
}

export enum TransactionType {
  PURCHASE = "purchase",
  SALES = "sales",
  PURCHASE_RETURN = "purchaseReturn",
  SALES_RETURN = "salesReturn",
  EXPIRY_OR_DISCARDMENT = "expiryOrDiscardment",
  SHORTAGE = "shortage",
  EXCESS = "excess",
  LOSS = "loss",
  INTERNAL_USE = "internalUse",
  INTERNAL_RETURN = "internalReturn",
  UNUSED_RETURN = "unusedReturn",
  INITIAL_STOCK = "initialStock",
  EDIT_PURCHASE = "editPurchase",
  OPENING_STOCK = "openingStock",
  EDIT_OPENING_STOCK = "editOpeningStock",
  ADJUSTMENT = "adjustment",
  OPENING_STOCK_ADJUSTMENT = "openingStockAdjustment",
  KITCHEN_PHARMACY_PURCHASE = "kitchenPharmacyPurchase"
}

export enum VoucherType {
  PURCHASE = "purchase",
  PURCHASE_RETURN = "purchaseReturn",
  PAYMENT = "payment",
  RECEIPT = "receipt",
  PURCHASE_EDIT = "editPurchase",
  JOURNAL = "journal"
}

export interface Stock {
  avgPurchasePrice: string;
  batchId: string;
  createdAt: string;
  expiryDate: string;
  id: number;
  product: StockProducts;
  productId: number;
  quantity: string;
  resourceCentreId: number;
  totalPurchasedQuantity: string;
  unitPriceExcVAT: string;
  unitPriceIncVAT: string;
  vatPct: string;
  purchasePrice: number;
  supplierId: number;
}

export interface StructuredInfo {
  brand?: string;
  code?: string;
  form?: string;
  genericCode?: string;
  genericName?: string;
  strength?: string;
  isNarcotics?: boolean;
}
export interface StockProducts {
  active: true;
  category: string;
  description: string;
  entity: string;
  id: number;
  minStockQuantity: number;
  name: string;
  package: string;
  packagePriceExcVAT: string;
  packagePriceIncVAT: string;
  priceCalculationOn: string;
  productType: number;
  resourceCentreId: number;
  stocks: Array<Stock>;
  structuredInfo: StructuredInfo;
  unit: string;
  unitPriceExcVAT: string;
  unitPriceIncVAT: string;
  unitsPerPackage: number;
  vatPct: string;
  discountPct: number;
  paidAmount: number;
  discountAmt: number;
  purchasePricePerUnit: number;
  purchasePricePerPackage: number;
  purchasePriceCalculationOn: PriceCalculatedOn;
  transactionType: TransactionType;
  nonDiscountable: boolean;
  productCode: string;
  manufactureBy: string;
  purchaseLedgerId: number;
  purchaseTaxationId: number;
  salesLedgerId: number;
  internalExpenseLedgerId: number | null;
  barCode: string;
}

export interface Entry {
  id?: number;
  paymentType?: string;
  date: string;
  paidAll: boolean;
  paidAmount: number;
  totalAmount: number;
  transactionType?: string;
  supplierId: number;
  stockItems: Array<StockItemInterface>;
  summary: Summary;
  supplierInvoiceId?: string;
  note?: string;
  totalDiscountPct?: number;
  settings: {
    discountSettings: DiscountSettings;
  };
  status?: string;
  diffInTotalAmt?: number;
  currPaidAmt?: number;
  paymentInfo: {
    balance: number;
    fromBalance: boolean;
    paidFromBalance: number;
    cashPayment: number;
  };
  supplierLedgerId?: number;
  currentStockItem?: StockItemInterface;
  voucherDate: Date | string;
  kitchenPharmacyId: number | null;
  kitchenPharmacyBillId: number | null;
}

export interface Supplier {
  address: string;
  detail: string;
  email: string;
  id: number;
  name: string;
  panNo: string;
  phone: string;
  resourceCentreId: number;
  ageingDays: number;
  ledgerId: number;
  active: boolean;
}

export interface StockItemInterface {
  id?: number;
  sNo?: number;
  productName?: string;
  productId: number;
  expiryDate: string;
  quantity: number;
  unit: string;
  price: number;
  batchId: string;
  grossTotal: number;
  package?: string;
  unitsPerPackage?: number;
  unitPriceExcVAT: number;
  unitPriceIncVAT: number;
  calculationBasis?: "perUnit" | "grossTotal";
  isFree: boolean;
  vatPct: number;
  discountPct: number;
  discountAmt: number;
  vatAmt?: number;
  totalIncVat?: number;
  packagePriceExcVAT?: number;
  packagePriceIncVAT?: number;
  paidAll?: boolean;
  paidAmount?: number;
  productType?: number;
  salesPriceCalculationOn?: string;
  enteredQuantityUnit?: "unit" | "package";
  hasFreeItems: boolean;
  freeItemQuantity: number;
  netTotal: number;
  salesVatPct: number;
  carryingChargePct: number;
  carryingChargeAmt: number;
  carryingChargePctOfFreeItems: number;
  carryingChargeAmtOfFreeItems: number;
  rateOfFreeItems: number;
  netTotalOfFreeItems: number;
  discountPctOfFreeItems: number;
  discountAmtOfFreeItems: number;
  vatPctOfFreeItems: number;
  vatAmtOfFreeItems: number;
  grossTotalOfFreeItems: number;
  grossTotalIncVatOfFreeItems: number;
  purchasePrice?: number;
  variance?: VarianceType;
  diffInQty?: number;
  diffInPrice?: number;
  diffInGrossTotal?: number;
  diffInGrossTotalIncVat?: number;
  diffInGrossTotalExcVat?: number;
  purchasePricePerUnit?: number;
  purchasePricePerPackage?: number;
  currency?: Currency;
  relatedProductUnitInfo?: string;
  relatedProductPackageInfo?: string;
  relatedProductUnitsPerPackage?: number;
  purchaseLedgerId: number | null;
  purchaseTaxationId: number | null;
  purchasePriceCalculationOn?: PriceCalculatedOn;
  name?: string;
  stocks?: Stock[];
  priceCalculationOn?: string;
  internalExpenseLedgerId?: number;
  profitMargin: number;
  productDetails: {
    productType: number;
  };
}

export interface Summary {
  taxAmt: number;
  discountAmt: number;
  totalIncVat: number;
  rates: {
    [key: number]: number;
  };
  roundOffAmt: string;
  totalDiscountPct: number;
  totalCcAmt: number;
  totalVatAmtOfFreeItems: number;
}
export enum DiscountTypes {
  PERCENT = "percent",
  AMOUNT = "amount"
}

export enum DiscountBasis {
  INLINE = "inline",
  TOTAL = "invoice"
}

export interface StateStockInterface {
  entry: {
    date: string;
    billId?: number;
    supplierId?: number;
    paymentType: string;
    supplierInvoiceId?: number;
    note?: string;
    transactionType?: string;
    resourceCentreId?: number;
    stockItems?: StockItemInterface[];
    totalAmount: number;
    paidAll: boolean;
    paidAmount: number;
    settings: {
      discountSettings: DiscountSettings;
    };
    summary: Summary;
    status: string;
    paymentInfo: {
      balance: number;
      fromBalance: boolean;
      paidFromBalance: number;
      cashPayment: number;
    };
  };
  stocks: Array<Stock>;
  stockTransactions: Array<StockTransactions>;
  recordPayments: PaymentHistory[];
  total: number;
}

export enum PriceCalculatedOn {
  unit = "unit",
  package = "package"
}

export interface DiscountSettings {
  discountBasis: string;
  discountType: string;
}

export interface Error {
  rowIdx: number;
  errorColumn: string;
}

export enum Mode {
  Create = "Create",
  Edit = "Edit"
}

export enum paymentOptionsEnum {
  cash = "cash",
  card = "card",
  cheque = "cheque",
  onlinePayment = "onlinePayment",
  credit = "credit"
}
export enum VarianceType {
  Price = "Price",
  Quantity = "Quantity"
}

export enum Currency {
  Rs = "Rs",
  Inr = "INR"
}
export interface EntryErrors {
  rowIdx: number;
  errorColumn:
    | "itemType"
    | "item"
    | "expiryDate"
    | "batchId"
    | "quantity"
    | "unit"
    | "price"
    | "grossTotal";
}
export interface ErrorState {
  entryErrors: Array<EntryErrors>;
}

export interface StockTrxPaymentInterface {
  paidAmount: number;
  paymentMethod: string;
  remarks: string;
  supplierInvoiceId: string;
  transactionDate: Date;
}
export interface PaymentHistory {
  id: number;
  supplierId: number;
  stockTransactionId: number;
  voucherType: VoucherType;
  supplierInvoiceId: number;
  paymentMethod: string | null;
  transactionType: TransactionType;
  amount: number;
  // eslint-disable-next-line camelcase
  created_at: Date;
}
export interface PaymentInfoStateProps {
  supplierInvoiceId: string;
  paidAmount: number | null;
  remarks: string;
  paymentMethod: paymentOptionsEnum;
  transactionDate: Date;
  transactionItems: TransactionItemsInterface[];
  supplierId?: number | null;
  advanceAmt?: number;
  transactionId?: number | null;
}

export interface TransactionItemsInterface extends Entry {
  isSelectedTrx?: boolean;
  sNo?: number;
  currPaidAmt?: number;
  dueAmount?: number;
}
export interface QueryParams {
  [key: string]: string | number | boolean;
}

export type TransactionTypes = TransactionType.PURCHASE | TransactionType.PURCHASE_RETURN;

import produce from "immer";
import cloneDeep from "lodash/cloneDeep";
import { ReducerBuilder } from "./ReducerBuilder";
import * as UserActions from "../actions/user";
import { UserContext } from "../interfaces/UserContext";

const INITIAL_STATE = {} as UserContext;

function setAccountInfo(state, payload) {
  const newState = cloneDeep(state);
  const { user, userCreds } = payload;
  if (!userCreds) return {};
  const { authenticable } = userCreds;
  const { resourceCentre } = user;
  newState.user = user;
  newState.userCreds = userCreds;
  newState.resourceCentre = resourceCentre;
  if (payload.policies) {
    newState.policies = payload.policies;
  }
  newState.resourceCentreId = resourceCentre ? resourceCentre.id : null;
  newState.mode = authenticable;
  return newState;
}

function getByUserId(state, payload) {
  return [payload] as unknown as UserContext;
}

function verifyUserAccessToken(state, payload) {
  return payload;
}

function updateUserId(state, payload) {
  return payload;
}

function updateProfilePicture(state, payload) {
  const newState = cloneDeep(state);
  newState.userCreds.profileImage = payload;
  return newState;
}

export const isSuperAdmin = (userContext: UserContext): boolean =>
  userContext?.userCreds?.authenticable === "admin";

function updateUserRc(state: UserContext, payload): UserContext {
  return produce(state, (draft) => {
    draft.resourceCentre = payload;
  });
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(UserActions.Type.GET_ACCOUNT, setAccountInfo)
  .mapAction(UserActions.Type.GET_BY_REFERENCEID, getByUserId)
  .mapAction(UserActions.Type.UPDATE_USER, updateUserId)
  .mapAction(UserActions.Type.VERIFY_TOKEN, verifyUserAccessToken)
  .mapAction(UserActions.Type.CHANGE_PROFILE_PICTURE, updateProfilePicture)
  .mapAction(UserActions.Type.UPDATE_ACCOUNT, setAccountInfo)
  .mapAction(UserActions.Type.UPDATE_USER_RC, updateUserRc)
  .build();

export default reducer;

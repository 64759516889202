import { Box, Typography } from "@mui/material";
import moment from "moment";
import * as momentTimezone from "moment-timezone";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Event } from "../../../interfaces/EventsInterface";
import BookingStatus from "../../../containers/Calendar/Booking/BookingStatus";
import { isResourceCentreUser } from "../../../containers/NavigationBar/SideBar";
import HandleBooking from "../../../containers/Calendar/Booking/HandleBooking";
import { EmptyView } from "../../OList";

interface PatientFlowListViewProps {
  selectedDate: Date | string;
  events: Event[];
}

export default function PatientFlowListView({
  selectedDate,
  events
}: PatientFlowListViewProps): JSX.Element {
  const startDate = momentTimezone(selectedDate).tz("Asia/Kathmandu").startOf("day").toISOString();
  const endDate = momentTimezone(selectedDate).tz("Asia/Kathmandu").endOf("day").toISOString();
  const userMode = useSelector((state: RootState) => state.userContext.mode);
  const filteredData = events
    .filter(
      (item) =>
        moment(item.start).isAfter(startDate) &&
        moment(item.start).isBefore(endDate) &&
        item.clientId
    )
    .sort((a, b) => a.id - b.id);

  return (
    <Box m={3}>
      {filteredData.length > 0 ? (
        filteredData.map((event, index) => (
          <Box
            borderBottom="1px solid #d7d7d7"
            m="15px 0"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            display="flex"
            alignItems="center"
            p="10px 0"
          >
            <Box display="flex" flexBasis="150px">
              <Typography>{moment(event.start).format("h:mma")}</Typography>
              {event.followUp && (
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "orange",
                    borderRadius: "50%",
                    marginTop: "5px",
                    marginLeft: "3px"
                  }}
                />
              )}
            </Box>
            <Typography flexBasis="200px">{`${event.client?.firstName} ${event.client?.lastName}`}</Typography>
            <Typography flexBasis="200px">{event.client?.phone}</Typography>
            <Typography flexBasis="200px">{`${event.serviceProvider?.firstName || ""} ${
              event.serviceProvider?.lastName || ""
            }`}</Typography>
            <Typography flexBasis="200px">
              {event.reasonOfVisit ? event.reasonOfVisit : "No reason"}
            </Typography>
            <Typography flexBasis="100px">
              <BookingStatus booking={{ status: event.status }} hideLabel />
            </Typography>
            <Box display="flex" flexBasis="300px" flexDirection="row-reverse">
              {isResourceCentreUser(userMode) && (
                <HandleBooking
                  booking={event}
                  serviceProvider={event.serviceProvider}
                  onClose={() => ({})}
                />
              )}
            </Box>
          </Box>
        ))
      ) : (
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No items to show.
          </Box>
        </EmptyView>
      )}
    </Box>
  );
}

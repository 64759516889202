import * as React from "react";
import Box from "@mui/material/Box";
import * as moment from "moment";
import RowLines from "./RowLines";
import Columns, { getEventTop } from "./Columns";
import CalendarHeader from "./CalendarHeader";

import styles from "./styles.module.css";
import PatientFlowListView from "./PatientFlowListView";
import { Event } from "../../../interfaces/EventsInterface";

export const getDays = (
  date: Date | moment.Moment = new Date(),
  daysCount = 7,
  isCalendarView: boolean = true
): Array<moment.Moment> => {
  if (daysCount === 1 || !isCalendarView) {
    return [moment(date)];
  }
  const startDay = moment(date).startOf("week");
  const days = [];
  Array.from(Array(daysCount)).forEach((it, idx) => {
    days.push(startDay.clone().add(idx, "day"));
  });
  return days;
};

export const daysCount = (columnsMode: string): number => (columnsMode === "week" ? 7 : 1);

export const getStartAndEnd = (date: Date, mode: string): Array<moment.Moment> => {
  const days = getDays(date, daysCount(mode));
  return [moment(days[0]).startOf("day"), moment(days[days.length - 1]).endOf("day")];
};

interface CalendarProps {
  selectedDate: Date;
  columnsMode: string;
  events: Event[];
  placeholderEvent: any;
  onEventClick: (event, el) => void;
  onChange: (date, columnsDates) => void;
  onNewEvent: (event) => void;
  onScheduleSelect: (divRef) => void;
  isCalendarView: boolean;
  setIsCalendarView: () => void;
}

const goBefore = (date, count) => moment(date).subtract(count, "d");

const goAfter = (date, count) => moment(date).add(count, "d");

const getCurrentTimeScrollPosition = () => {
  const scrollPosition = getEventTop(moment());
  if (scrollPosition > 100) {
    return scrollPosition - 100;
  }
  return scrollPosition;
};

const Calendar: React.FC<CalendarProps> = (props) => {
  const {
    selectedDate = new Date(),
    columnsMode = "week",
    events,
    placeholderEvent,
    onChange,
    onNewEvent,
    onEventClick,
    onScheduleSelect,
    isCalendarView,
    setIsCalendarView
  } = props;
  const columnsContainerRef = React.useRef(null);
  React.useEffect(() => {
    if (columnsContainerRef.current) {
      columnsContainerRef.current.scrollTop = getCurrentTimeScrollPosition();
    }
  }, [isCalendarView]);
  const days = React.useMemo(
    () => getDays(selectedDate, daysCount(columnsMode), isCalendarView),
    [selectedDate, columnsMode, isCalendarView]
  );
  return (
    <Box className={styles.calendarContainer}>
      <CalendarHeader
        isCalendarView={isCalendarView}
        onCalendarViewChange={() => setIsCalendarView()}
        selectedDate={selectedDate}
        days={days}
        goBack={() => {
          const dCount = daysCount(columnsMode);
          const newSelectedDate = goBefore(selectedDate, dCount);
          onChange(newSelectedDate, getDays(newSelectedDate, dCount));
        }}
        goForward={() => {
          const dCount = daysCount(columnsMode);
          const newSelectedDate = goAfter(selectedDate, dCount);
          onChange(newSelectedDate, getDays(newSelectedDate, dCount));
        }}
        goto={(date) => {
          const dCount = daysCount(columnsMode);
          if (moment(selectedDate).isSame(date, "day")) {
            if (columnsContainerRef.current) {
              columnsContainerRef.current.scrollTop = getCurrentTimeScrollPosition();
            }
          } else {
            onChange(date, getDays(date, dCount));
          }
        }}
      />
      {isCalendarView ? (
        <div
          data-testmation="calendarColumns"
          ref={columnsContainerRef}
          className={styles.calendarColumns}
        >
          <RowLines />
          <Columns
            days={days}
            events={events}
            placeholderEvent={placeholderEvent}
            onNewEvent={onNewEvent}
            onEventClick={onEventClick}
            onScheduleSelect={onScheduleSelect}
          />
        </div>
      ) : (
        <PatientFlowListView selectedDate={selectedDate} events={events} />
      )}
    </Box>
  );
};

export default Calendar;

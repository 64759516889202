import { Box } from "@mui/material";
import React from "react";
import Panel from "../../../components/Panel";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import DashboardCardOrder from "./DashboardCardOrder";

interface Props {
  onClose: () => void;
  resourceCentre: ResourceCentre;
}

const SettingsPanel = ({ onClose, resourceCentre }: Props): JSX.Element => (
  <Panel onClose={onClose} title="Dashboard settings">
    <Box px={2}>
      <DashboardCardOrder resourceCentre={resourceCentre} />
    </Box>
  </Panel>
);

export default SettingsPanel;

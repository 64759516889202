/* eslint-disable no-param-reassign */
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
  Select,
  InputLabel,
  Box,
  Alert
} from "@mui/material";
import React from "react";
import { capitalize, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import StatByGender from "./StatByGender";
import StatByAgeGroup from "./StateByAgeGroup";
import NepalMap from "./NepalMap";
import LabStatistics from "./LabStatistics";
import { AddressOptions } from "../../../../hooks/query/useAddressOption";
import RegionDescription from "./RegionDescription";
import LineChartComponent from "./LineChartComponent";
import PeriodPicker from "../../../../components/PeriodPicker/index";
import {
  getAgeStatChartData,
  getDistrictIdByName,
  getFilteredData,
  getGenderCountChartData
} from "./helper";
import useAddressOptions from "../../../../hooks/useAddressOptions";
import { getLabSummary } from "../../../../slices/labSummarySlice";
import { RootState } from "../../../../store";

export interface PieChartFormat {
  name: string;
  label: string;
  value: number;
}

export interface AgeGroupStatInterface {
  ageGroup: string;
  male: number;
  female: number;
}

enum ProvinceOptions {
  PROVINCE_1 = "Province 1"
}

const genderFilterOptions = ["female", "male", "all"];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "400px"
    }
  }
};
export interface FilterProps {
  from: string | null;
  until: string | null;
  districts: string[];
  palikas: string[];
  gender: string;
  provinces: string[];
}

const initialFilterState = {
  from: moment().tz("Asia/Kathmandu").subtract(30, "days").startOf("day").toISOString(),
  until: moment().toISOString(),
  districts: ["All"],
  palikas: ["All"],
  gender: "all",
  provinces: [ProvinceOptions.PROVINCE_1]
};

const getPalikaNameList = (filters: FilterProps, addressMasterData: AddressOptions[]) => {
  let selectedDistrictIds = [] as number[];
  if (filters.districts.includes("All")) {
    selectedDistrictIds = [...Array(14)].map((_, i) => i + 1);
  } else {
    selectedDistrictIds = filters.districts.map((district) => {
      const districtId = getDistrictIdByName(district, addressMasterData);
      return districtId as number;
    });
  }
  return addressMasterData
    .filter((address) => selectedDistrictIds.includes(address.districtId))
    .map((item) => item.palikaName);
};

const CentralMonitoringLabReport = (): JSX.Element => {
  const addressMasterData = useAddressOptions();
  const [filters, setFilters] = React.useState<FilterProps>({ ...initialFilterState });
  const labSummary = useSelector((state: RootState) => state.labSummary);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLabSummary(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const palikaNameListForDistrict = ["All", ...getPalikaNameList(filters, addressMasterData)];

  const districtOptions = [
    "All",
    ...uniq(
      addressMasterData
        .filter((address) => address.provinceId === 1)
        .map((address) => address.districtName)
    )
  ];

  const filteredSummary = getFilteredData(
    labSummary,
    addressMasterData,
    filters.palikas,
    filters.gender
  );

  const genderCountChartData = getGenderCountChartData(filteredSummary);
  const ageGroupStatisticsChartData = getAgeStatChartData(filteredSummary);

  if (!addressMasterData.length) return <Typography>Address Not Found.</Typography>;

  return (
    <div
      style={{
        padding: "10px 25px"
      }}
    >
      <Box sx={{ width: "100%" }}>
        <LabStatistics labSummary={labSummary} />
      </Box>
      <Typography fontSize="16px" fontWeight="bold" marginBottom="9px">
        Filters
      </Typography>
      <Box
        style={{
          marginTop: "10px",
          marginBottom: "10px"
        }}
      >
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel sx={{ left: "10px", top: "-5px" }} id="district-multiple-checkbox-label">
                Province
              </InputLabel>
              <Select
                sx={{ height: "36px" }}
                labelId="district-multiple-checkbox-label"
                id="district-multiple-checkbox"
                multiple
                disabled
                value={filters.provinces}
                onChange={(e) => {
                  const {
                    target: { value }
                  } = e;
                  if (value.includes("All")) {
                    setFilters({ ...filters, provinces: ["All"] });
                  } else {
                    setFilters({
                      ...filters,
                      provinces: typeof value === "string" ? value.split(",") : value
                    });
                  }
                }}
                input={<OutlinedInput label="Province" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {["All", ProvinceOptions.PROVINCE_1].map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={
                        filters.districts.indexOf(name) > -1 || filters.districts.includes("All")
                      }
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel sx={{ left: "10px", top: "-5px" }} id="district-multiple-checkbox-label">
                District
              </InputLabel>
              <Select
                sx={{ height: "36px" }}
                labelId="district-multiple-checkbox-label"
                id="district-multiple-checkbox"
                multiple
                value={filters.districts}
                onChange={(e) => {
                  const {
                    target: { value }
                  } = e;
                  if (value.includes("All")) {
                    setFilters({ ...filters, districts: ["All"] });
                  } else {
                    setFilters({
                      ...filters,
                      districts: typeof value === "string" ? value.split(",") : value
                    });
                  }
                }}
                input={<OutlinedInput label="District" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {districtOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={
                        filters.districts.indexOf(name) > -1 || filters.districts.includes("All")
                      }
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel sx={{ left: "10px", top: "-5px" }} id="district-multiple-checkbox-label">
                Palika
              </InputLabel>
              <Select
                sx={{ height: "36px" }}
                labelId="palika-multiple-checkbox-label"
                id="palika-multiple-checkbox"
                multiple
                value={filters.palikas}
                onChange={(e) => {
                  const {
                    target: { value }
                  } = e;
                  if (value.includes("All")) {
                    setFilters({ ...filters, palikas: ["All"] });
                  } else {
                    setFilters({
                      ...filters,
                      palikas: typeof value === "string" ? value.split(", ") : value
                    });
                  }
                }}
                input={<OutlinedInput label="District" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {palikaNameListForDistrict.map((palikaName, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={`${palikaName}-${index}`} value={palikaName}>
                    <Checkbox
                      checked={
                        filters.palikas.indexOf(palikaName) > -1 || filters.palikas.includes("All")
                      }
                    />
                    <ListItemText primary={palikaName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid marginTop="-33px" item xs={6} sm={6} md={1} lg={1}>
            <TextField
              label="Select Gender"
              select
              fullWidth
              variant="outlined"
              value={filters.gender}
              onChange={(e) => setFilters({ ...filters, gender: e.target.value })}
              style={{ marginTop: "33px" }}
              defaultValue="all"
            >
              {genderFilterOptions.map((genderOption) => (
                <MenuItem key={genderOption} value={genderOption}>
                  {capitalize(genderOption)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid marginTop="-8px" item xs={6} sm={6} md={4} lg={4}>
            <PeriodPicker
              start={moment().subtract(30, "d").startOf("d")}
              end={new Date()}
              onChange={({ start, end }) => {
                setFilters({
                  ...filters,
                  from: start.toISOString(),
                  until: end.toISOString()
                });
              }}
              limitToMonth
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => dispatch(getLabSummary(filters))}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
      {!labSummary.length && <Alert severity="warning">No Lab Data found on selected Date.</Alert>}
      <Box sx={{ marginTop: "10px", marginBottom: "10px", display: "absolute" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <NepalMap
              labSummary={labSummary}
              addressMasterData={addressMasterData}
              districts={filters.districts}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <RegionDescription
              labSummary={labSummary}
              palikas={filters.palikas}
              addressMasterData={addressMasterData}
              palikaNameListForDistrict={palikaNameListForDistrict}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: "10px", marginBottom: "10px", display: "absolute" }}>
        <LineChartComponent data={filteredSummary} filters={filters} />
      </Box>

      <Grid container sx={{ mt: "24px" }}>
        <Grid item container spacing={10}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <StatByAgeGroup ageGroupStatistics={ageGroupStatisticsChartData} />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <StatByGender genderCount={genderCountChartData} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CentralMonitoringLabReport;

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as Sentry from "@sentry/browser";
import ReactGA4 from "react-ga4";
import { store, history } from "./app/store";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

const isDevEnvironment = process.env.REACT_APP_ENV === "development" || !process.env.REACT_APP_ENV;

// Initialize sentry.
Sentry.init({
  dsn: isDevEnvironment ? null : "https://033ce4dc18e44fdd910279473ca913b0@sentry.io/1884665",
  environment: process.env.REACT_APP_ENV
});

// Initialize google analytics.
ReactGA4.initialize("G-GMD9G6FF64", {
  testMode: isDevEnvironment
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root") || document.createElement("div") // for test purpose only,,
);

serviceWorker.unregister();

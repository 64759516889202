import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { match as MatchProps, Route, Switch } from "react-router";

import DaybookList from "./DaybookList";
import DaybookEntryInfo from "./DaybookEntryInfo";
import { getDaybookData } from "../../api/daybook";
import DaybookCreateEdit from "./DaybookCreateEdit";
import { DaybookFormMode } from "../../enum/Daybook";
import { DaybookInterface } from "../../interfaces/Daybook";
import { notificationAdd } from "../../actions/notification";

const Daybook = ({ match }: { match: MatchProps }): JSX.Element => {
  const pageSize = 20;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [selectedRow, setSelectedRow] = useState<Partial<DaybookInterface> | null>(null);

  const [daybookData, setDaybookData] = useState<{ results: DaybookInterface[]; total: number }>({
    results: [],
    total: 0
  });

  const onClose = () => {
    setSelectedRow(null);
    dispatch(push(match.path));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getDaybookData({ page, pageSize });
        setDaybookData(response.daybookRecords);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get daybook data",
            autoTimeout: true
          })
        );
      }
    })();
  }, [page, dispatch]);

  return (
    <Box height="100%" pt={2} data-testmation="daybook">
      <DaybookList
        page={page}
        setPage={setPage}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        daybookData={daybookData}
      />

      <Switch>
        <Route
          exact
          path={`${match.path}/create`}
          render={(props) => (
            <DaybookCreateEdit
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onClose={onClose}
              mode={DaybookFormMode.CREATE}
              setDaybookData={setDaybookData}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/:id`}
          render={(props) => (
            <DaybookEntryInfo
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onClose={onClose}
              selectedRow={selectedRow}
              daybookData={daybookData}
              setDaybookData={setDaybookData}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/:id/edit`}
          render={(props) => (
            <DaybookCreateEdit
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              onClose={onClose}
              mode={DaybookFormMode.EDIT}
              selectedRow={selectedRow}
              setDaybookData={setDaybookData}
            />
          )}
        />
      </Switch>
    </Box>
  );
};

export default Daybook;

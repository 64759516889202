import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import StatusIndicator from "./StatusIndicator";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { Booking } from "../../../interfaces/BookingInterfaces";
import useGetBookedServices from "../../../hooks/useGetBookedServices";
import HtmlTooltip from "../../../components/HtmlTooltip";

interface PastBookingsProps {
  bookings: Booking[];
  setClientId: (id: number) => void;
  setShowClientInfoPanel: (show: boolean) => void;
  setBooking: (booking: Booking) => void;
  handleClick: (e) => void;
  isToday: boolean;
}

const useStyles = makeStyles({
  root: {},
  titleContainer: {
    display: "flex",
    alignItems: "center",
    color: "#009654",
    cursor: "pointer"
  },
  title: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px"
  },
  rotateUp: {
    transform: "rotate(180deg)",
    transition: "all 0.5s"
  },
  rotateDown: {
    transform: "rotate(360deg)",
    transition: "all 0.5s"
  },
  bookingContainer: {
    marginTop: "20px",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px"
  },
  bookingDetail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    cursor: "pointer",
    borderBottom: "1px solid  rgba(90, 96, 127, 0.25)",
    "&:hover": {
      backgroundColor: "#F1FFF3"
    }
  },
  timeContainer: {
    flexBasis: "20%",
    textTransform: "uppercase",
    fontFamily: "Noto Sans Display",
    display: "flex",
    gap: 2,
    alignItems: "center"
  },
  ptName: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "18px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    },
    flexBasis: "33%",
    textAlign: "right",
    textTransform: "capitalize"
  },
  drName: {
    textAlign: "right",
    flexBasis: "33%",
    textTransform: "capitalize"
  },
  bookableResourceAndServiceContainer: {
    display: "flex",
    maxWidth: "15.5rem",
    flexBasis: "50%",
    gap: "0.5rem"
  },
  bookableResourceAndService: {
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
});

const BookingDetail = ({
  setClientId,
  setShowClientInfoPanel,
  setBooking,
  booking,
  handleClick
}) => {
  const classes = useStyles();
  const { start, serviceProvider, client, status, bookableResource, services } = booking;
  const bookedServices = useGetBookedServices(services);
  const bookedServicesString = (bookedServices || []).map((service) => service.name).join(", ");
  return (
    <Box
      className={classes.bookingDetail}
      onClick={(e) => {
        setBooking(booking);
        handleClick(e);
      }}
    >
      <div className={classes.timeContainer}>
        <Typography whiteSpace="pre">{moment(start).format("hh:mm a")}</Typography>
        <StatusIndicator status={status} />
        {booking.remarks && <HtmlTooltip description={booking.remarks} />}
      </div>
      <Box className={classes.bookableResourceAndServiceContainer}>
        {booking.bookableResourceId && (
          <Typography
            className={classes.bookableResourceAndService}
            title={booking.bookableResource.name}
          >
            {booking.bookableResource.name}
          </Typography>
        )}
        <Typography className={classes.bookableResourceAndService} title={bookedServicesString}>
          {bookedServicesString}
        </Typography>
      </Box>
      <Box
        className={classes.ptName}
        onClick={(e) => {
          e.stopPropagation();
          setClientId(client?.id);
          setShowClientInfoPanel(true);
        }}
      >
        {`${client?.firstName} ${client?.lastName}`}
      </Box>
      {(bookableResource || serviceProvider) && (
        <Typography className={classes.drName}>
          {serviceProvider ? spFullNameSelector(serviceProvider) : bookableResource.name}
        </Typography>
      )}
    </Box>
  );
};

const PastBookings = ({
  bookings,
  setClientId,
  setShowClientInfoPanel,
  setBooking,
  handleClick,
  isToday
}: PastBookingsProps): React.ReactElement => {
  const classes = useStyles();

  const oneHourBookings = bookings.filter(
    (booking) => moment().diff(moment(booking.end), "hours", true) < 1
  );
  const [showPastBooking, setShowPastBooking] = React.useState(false);

  return (
    <div className={classes.root}>
      {isToday && (
        <Box
          className={classes.titleContainer}
          onClick={() => {
            setShowPastBooking((previousState) => !previousState);
          }}
        >
          <Typography variant="h5" className={classes.title}>
            {bookings.length} Past Bookings
          </Typography>
          <KeyboardArrowDownIcon
            className={showPastBooking ? classes.rotateUp : classes.rotateDown}
          />
        </Box>
      )}
      <div className={classes.bookingContainer}>
        {showPastBooking || !isToday
          ? bookings?.map((booking) => (
              <BookingDetail
                key={booking.id}
                booking={booking}
                setClientId={setClientId}
                setShowClientInfoPanel={setShowClientInfoPanel}
                setBooking={setBooking}
                handleClick={handleClick}
              />
            ))
          : oneHourBookings?.map((booking) => (
              <BookingDetail
                key={booking.id}
                booking={booking}
                setClientId={setClientId}
                setShowClientInfoPanel={setShowClientInfoPanel}
                setBooking={setBooking}
                handleClick={handleClick}
              />
            ))}
      </div>
    </div>
  );
};

export default PastBookings;

import React from "react";
import { Box, Typography } from "@mui/material";
import "./styles.scss";

export const headers = [
  { key: "code", label: "GL CODE" },
  { key: "ledgerName", label: "GL DESCRIPTION" },
  { key: "ledgerType", label: "TYPE" },
  { key: "validFrom", label: "FROM" },
  { key: "validTo", label: "TO" },
  { key: "bankRecoInfo", label: "B. RECO" },
  { key: "ledgerNature", label: "NATURE" },
  { key: "okhatiIntegrated", label: "INTEGRATION" }
];

export default function Headers(): JSX.Element {
  return (
    <Box className="accounts-list-headers">
      {headers.map((header) => (
        <Typography className="headercell" key={header.key} fontSize="0.8rem">
          {header.label}
        </Typography>
      ))}
    </Box>
  );
}

import { Box, Grid, Typography, Button } from "@mui/material";
import { capitalize, startCase } from "lodash";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IPD, IpdRecord } from "../../interfaces/IpdInterface";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import { ageFormatter } from "../../helpers/formatters";
import { getGenderCode } from "../Billing/PrintBill/EightyMmPrint/ClientInfo";
import { tl } from "../../components/translate";

interface IpdDetailsProps {
  ipdRecord: IPD | null;
}

const getPatientNameForIpd = (ipdRecord: IpdRecord): string => {
  if (ipdRecord) {
    return startCase(
      `${ipdRecord.patientRelatedInformation.firstName} ${ipdRecord.patientRelatedInformation.lastName}`
    );
  }
  return "";
};
const IpdDetails: React.FC<IpdDetailsProps> = ({ ipdRecord }): JSX.Element | null => {
  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [showMore, setShowMore] = React.useState<boolean>(false);

  if (!ipdRecord) return null;

  return (
    <>
      <Box pt={5}>
        <div>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setShowClientInfoPanel(true);
              }}
            >
              {getPatientNameForIpd(ipdRecord?.ipdRecord).toUpperCase()}
            </Typography>
            <Box display="flex" fontSize="14px">
              <Typography style={{ width: "200px", fontWeight: 600 }}>
                {tl("admissionNumber")}
              </Typography>
              <Typography>{ipdRecord?.ipdNumber}</Typography>
            </Box>
            <Grid item xs={12}>
              {ipdRecord?.referenceNumber && (
                <Box display="flex" fontSize="14px">
                  <Typography sx={{ width: "200px", fontWeight: 600 }}>
                    File/Reference Number
                  </Typography>
                  <Typography>{ipdRecord.referenceNumber}</Typography>
                </Box>
              )}
              <Box display="flex" fontSize="14px">
                <Typography style={{ width: "200px", fontWeight: 600 }}>
                  {tl("bedNumber")}
                </Typography>
                <Typography>{ipdRecord?.bed?.number}</Typography>
              </Box>
              <Box display="flex" fontSize="14px">
                <Typography style={{ width: "200px", fontWeight: 600 }}>
                  {tl("wardNumber")}
                </Typography>
                <Typography>{ipdRecord?.ward?.name}</Typography>
              </Box>
              <Box display="flex" fontSize="14px">
                <Typography style={{ width: "200px", fontWeight: 600 }}>
                  {tl("roomType")}
                </Typography>
                <Typography>{ipdRecord?.roomType}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" fontSize="14px">
                <Typography style={{ width: "200px", fontWeight: 600 }}>
                  Doctor in Charge
                </Typography>
                <Typography>
                  {startCase(
                    ipdRecord?.doctorInCharge?.fullname ||
                      ipdRecord?.ipdRecord?.doctorInCharge?.fullname
                  )}
                </Typography>
              </Box>
              <Box display="flex" fontSize="14px">
                <Typography style={{ width: "200px", fontWeight: 600 }}>{tl("case")}</Typography>
                <Typography>{ipdRecord?.ipdRecord?.case}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Button
            onClick={() => setShowMore((prev) => !prev)}
            endIcon={showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
            {tl("showMore")}
          </Button>
          {showMore && (
            <div>
              <Grid item xs={12}>
                <Box marginTop="12px" width="100%">
                  <Typography variant="h6" style={{ color: "gray" }}>
                    {tl("clientInformation")}
                  </Typography>
                  <Box display="flex" fontSize="14px">
                    <Typography width="200px" style={{ fontWeight: 600 }}>
                      {tl("clientName")}
                    </Typography>
                    <Typography
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        setShowClientInfoPanel(true);
                      }}
                    >
                      {`${capitalize(
                        ipdRecord?.ipdRecord?.patientRelatedInformation.firstName
                      )} ${capitalize(ipdRecord?.ipdRecord?.patientRelatedInformation.lastName)}`}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography width="200px">{tl("bloodGroup")}</Typography>
                    <Typography>{ipdRecord?.client?.bloodGroup || ""}</Typography>
                  </Box>
                  {ipdRecord?.client?.ssfId && (
                    <Box display="flex">
                      <Typography width="200px">SsfId</Typography>
                      <Typography>{ipdRecord.client.ssfId}</Typography>
                    </Box>
                  )}
                  <Box display="flex">
                    <Typography width="200px">{tl("age/sex")}</Typography>
                    <Typography>
                      {ipdRecord?.client?.dob
                        ? ageFormatter(new Date(ipdRecord?.client?.dob))
                        : "-"}
                      {ipdRecord?.client?.gender
                        ? `/${getGenderCode(ipdRecord?.client?.gender)}`
                        : "/-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box marginTop="12px" width="100%">
                  <Typography variant="h6" style={{ color: "gray" }}>
                    {tl("otherInformation")}
                  </Typography>
                  <Box display="flex" fontSize="14px" fontWeight={600}>
                    <Typography width="200px" style={{ fontWeight: 600 }}>
                      Provisional Diagnosis
                    </Typography>
                    <Typography>{`${ipdRecord?.ipdRecord?.provisionalDiagnosis}`}</Typography>
                  </Box>
                  <Box display="flex" fontSize="14px" fontWeight={600}>
                    <Typography width="200px" style={{ fontWeight: 600 }}>
                      HOPI
                    </Typography>
                    <Typography>{ipdRecord?.ipdRecord?.hopi}</Typography>
                  </Box>
                  <Box display="flex" fontSize="14px" fontWeight={600}>
                    <Typography width="200px" style={{ fontWeight: 600 }}>
                      {tl("admissionCondition")}
                    </Typography>
                    <Typography>{ipdRecord?.ipdRecord?.admissionCondition}</Typography>
                  </Box>
                </Box>
              </Grid>
            </div>
          )}
        </div>
      </Box>
      {showClientInfoPanel &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={ipdRecord?.clientId}
            mode="edit"
            onCancel={() => {
              setClientEditMode(false);
            }}
            stayOnCurrentPage
          />
        ) : (
          <ClientInfo
            wrapperStyle={{ minWidth: "796px", maxWidth: "796px" }}
            id={ipdRecord?.clientId}
            handleViewClose={() => setShowClientInfoPanel(false)}
            setEditMode={setClientEditMode}
            stayOnCurrentPage
          />
        ))}
    </>
  );
};

export default IpdDetails;

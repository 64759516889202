import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { push } from "connected-react-router";
import produce from "immer";
import { pick, startCase, throttle } from "lodash";
import { round } from "mathjs";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { connect, useDispatch, useSelector } from "react-redux";
import { notificationAdd } from "../../../actions/notification";
import * as stockActions from "../../../actions/stock";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import Modal from "../../../components/Modal/Modal";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { tl } from "../../../components/translate";
import { isDateFormate, validate } from "../../../helpers/validators";
import useIsAccountSubscribed from "../../../hooks/accounts";
import { AppliedAccount } from "../../../interfaces/Accounts";
import {
  Currency,
  Entry,
  Error,
  Status,
  StockItemInterface,
  TransactionItemInterface,
  TransactionType
} from "../../../interfaces/StockInterfaces";
import { IThunkDispatch, RootState } from "../../../store";
import Can from "../../Policy/Can";
import { LOCALSTORAGE_STOCK_PURCHASE } from "./PurchaseEntry";
import styles from "./PurchaseEntry.module.css";
import { editPurchaseData } from "./purchaseEditHelper";
import useKitchenPharmacy from "../../../hooks/useKitchenPharmacy";

interface Loading {
  saveBtn: boolean;
  saveAsDraftBtn: boolean;
}
interface StockEntryActionsProps {
  entry: Entry;
  actions: {
    saveStockTransaction: (entry: Entry) => void;
    navigateTo: (url: string) => void;
    clearEntry: () => void;
    applyInitialState: (entry: Entry) => void;
    editOpeningStock: (entry: Entry) => void;
    saveStockAdjustment: (entry: Entry) => Promise<void>;
  };
  errors: { entryErrors: Error[] };
  transactions: TransactionItemInterface[];
}

function processQuantities(enteredQuantityUnit, unitsPerPackage, quantity, freeItemQuantity = 0) {
  if (enteredQuantityUnit === "package") {
    return (quantity + freeItemQuantity) * unitsPerPackage;
  }
  return quantity + freeItemQuantity;
}

function processPackagePriceExcVat(currency, unitPricePerPackage) {
  if (currency === Currency.Inr) {
    return round(unitPricePerPackage * 1.6, 2);
  }
  return unitPricePerPackage;
}

function processUnitPriceExcVat(currency, unitPrice) {
  if (currency === Currency.Inr) {
    return round(unitPrice * 1.6, 2);
  }
  return unitPrice;
}

function processTransactionItems(item: StockItemInterface) {
  const selectors = [
    "productId",
    "quantity",
    "unit",
    "batchId",
    "enteredQuantityUnit",
    "purchasePrice",
    "salesPriceCalculationOn",
    "unitPriceExcVAT",
    "unitPriceIncVAT",
    "packagePriceExcVAT",
    "packagePriceIncVAT",
    "expiryDate",
    "grossTotal",
    "unitsPerPackage",
    "vatPct",
    "productName",
    "isFree",
    "discountPct",
    "hasFreeItems",
    "freeItemQuantity",
    "salesVatPct",
    "carryingChargePct",
    "carryingChargeAmt",
    "carryingChargePctOfFreeItems",
    "carryingChargeAmtOfFreeItems",
    "rateOfFreeItems",
    "netTotalOfFreeItems",
    "discountPctOfFreeItems",
    "discountAmtOfFreeItems",
    "vatPctOfFreeItems",
    "vatAmtOfFreeItems",
    "grossTotalOfFreeItems",
    "grossTotalIncVatOfFreeItems",
    "totalIncVat",
    "discountAmt",
    "variance",
    "diffInPrice",
    "diffInQty",
    "diffInGrossTotal",
    "diffInGrossTotalIncVat",
    "diffInGrossTotalExcVat",
    "netTotal",
    "relatedProductPackageInfo",
    "relatedProductUnitsPerPackage",
    "relatedProductUnitInfo",
    "currency",
    "purchaseLedgerId",
    "purchaseTaxationId",
    "vatAmt",
    "internalExpenseLedgerId",
    "package",
    "kitchenPharmacyProductId"
  ];
  return {
    ...pick(item, selectors),
    quantity: processQuantities(
      item.enteredQuantityUnit,
      item.unitsPerPackage,
      item.quantity,
      item.freeItemQuantity
    ),
    currency: Currency.Rs,
    unitPriceExcVAT: processUnitPriceExcVat(item.currency, item.unitPriceExcVAT),
    packagePriceExcVAT: processPackagePriceExcVat(item.currency, item.packagePriceExcVAT)
  };
}

const getLedgerMappingError = (entry, rcMappedPaymentMethods): string[] => {
  const errors = [] as string[];
  if (entry.supplierId && !entry.supplierLedgerId) {
    errors.push("Supplier is not mapped with ledger.");
  }
  if (entry.paymentType !== "credit" && !rcMappedPaymentMethods[entry.paymentType]?.ledgerId) {
    errors.push("Payment Method is not mapped with ledger.");
  }
  if (entry.stockItems.some((item) => !item.purchaseLedgerId)) {
    errors.push("Stock Item is not mapped with ledger.");
  }
  if (
    [TransactionType.INTERNAL_RETURN, TransactionType.INTERNAL_USE].includes(
      entry.transactionType
    ) &&
    entry.stockItems.some((item) => !item.internalExpenseLedgerId)
  ) {
    errors.push("Stock Item is not mapped with ledger for Internal Use.");
  }
  return errors;
};

const getTransactionText = (entry) =>
  entry.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
    ? "stock transfer request"
    : entry.transactionType;

const StockEntryActions = ({ entry, actions, errors, transactions }: StockEntryActionsProps) => {
  const [loading, setLoading] = React.useState<Loading>({
    saveBtn: false,
    saveAsDraftBtn: false
  });
  const [disableSave, setDisableSave] = React.useState(false);
  const [isConfirmEntryDialogOpen, setIsConfirmEntryDialogOpen] = React.useState(false);
  const [addItemWarningDialog, setAddItemWarningDialog] = React.useState(false);
  const isAccountSubscribed = useIsAccountSubscribed();
  const rcMappedPaymentMethods = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment || {}
  );
  const [ledgerMappingErrors, setLedgerMappingErrors] = React.useState<string[]>([]);

  const isKitchenPharmacyPurchaseEntry = useSelector(
    (state: RootState) =>
      state.stock?.entry?.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
  );
  const kitchenPharmacy = useKitchenPharmacy();

  React.useEffect(() => {
    const hasStockItem =
      entry.stockItems
        ?.map((stockItem) => stockItem.productId)
        .find((stockItem) => stockItem === null) === undefined;
    const isInvalidExpDate =
      entry.stockItems
        ?.map((stockItem) =>
          validate(stockItem.expiryDate.slice(0, 10), [isDateFormate({ msg: "" })]).isValid
            ? stockItem.expiryDate
            : null
        )
        .find((stockItem) => stockItem === null) === undefined;
    const isBatchEmpty =
      entry.stockItems
        ?.map((stockItem) => stockItem.batchId)
        .find((stockItem) => stockItem === "" || stockItem === null) === undefined;
    const isPriceEntered = entry.stockItems
      ?.map((stockItem) => stockItem)
      .find((stockItem) => stockItem.price === 0 && !stockItem.isFree);

    const isSupplierSelected =
      entry.supplierId ||
      entry.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE ||
      kitchenPharmacy?.id;
    const isInvoiceIdSelected = entry.supplierInvoiceId;
    const validationChecks = [
      "internalUse",
      "internalReturn",
      "excess",
      "openingStock",
      "editOpeningStock"
    ].includes(entry.transactionType)
      ? hasStockItem && isInvalidExpDate && isBatchEmpty
      : hasStockItem && isInvalidExpDate && isBatchEmpty && !isPriceEntered && isSupplierSelected;
    if (["purchase", "purchaseReturn"].includes(entry.transactionType)) {
      if (isInvoiceIdSelected && validationChecks) {
        setDisableSave(false);
      } else {
        setDisableSave(true);
      }
    } else if (validationChecks) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    if (["adjustment", TransactionType.KITCHEN_PHARMACY_PURCHASE].includes(entry.transactionType)) {
      setDisableSave(false);
    }
  }, [entry, kitchenPharmacy?.id]);

  const isLoading = (state) => Object.values(state).some((item) => item);
  const editedEntry =
    entry.transactionType === TransactionType.EDIT_PURCHASE &&
    editPurchaseData(entry, transactions);

  const dispatch = useDispatch();

  const finalizeEntryFnDisableCase = () =>
    errors.entryErrors.length > 0 ||
    disableSave ||
    isLoading(loading) ||
    (entry.transactionType === TransactionType.EDIT_PURCHASE && !editedEntry?.stockItems?.length);

  const draftSaveFnDisableCase = () =>
    errors.entryErrors.length > 0 || disableSave || isLoading(loading);

  const draftSaveFn = async () => {
    setLoading({ ...loading, saveAsDraftBtn: true });
    const formattedEntry = produce(entry, (draft: Entry) => {
      draft.stockItems = draft.stockItems.map((item) =>
        processTransactionItems({
          ...item,
          purchasePrice: item.price
        })
      );
    });
    await actions.saveStockTransaction({
      ...formattedEntry,
      status: Status.Draft,
      // When we edit existing draft, status is Draft
      // When we create purchase return from purchase status is Finalise
      ...(formattedEntry.status === Status.Draft &&
      formattedEntry.transactionType === TransactionType.PURCHASE_RETURN
        ? {}
        : { id: undefined, referenceTo: entry.id })
    });
    setLoading({ ...loading, saveAsDraftBtn: false });
    localStorage.removeItem(LOCALSTORAGE_STOCK_PURCHASE);
  };

  const finalizeEntryFn = async () => {
    setLoading({ ...loading, saveBtn: true });
    if (entry.transactionType === TransactionType.EDIT_PURCHASE) {
      const formattedEntry = produce(editedEntry, (draft) => {
        draft.stockItems = draft.stockItems.map((item) =>
          processTransactionItems({
            ...item,
            purchasePrice: item.price
          })
        );
      });
      await actions.saveStockTransaction({
        ...formattedEntry,
        status: Status.Finalize
      });
      setLoading({ ...loading, saveBtn: false });
    } else if (entry.transactionType === TransactionType.EDIT_OPENING_STOCK) {
      const formattedEntry = produce(entry, (draft) => {
        draft.stockItems = draft.stockItems.map((item) =>
          processTransactionItems({
            ...item,
            purchasePrice: item.price
          })
        );
      });
      await actions.editOpeningStock({
        ...formattedEntry,
        status: Status.Finalize
      });
      setLoading({ ...loading, saveBtn: false });
    } else if (entry.transactionType === TransactionType.ADJUSTMENT) {
      const formattedEntry = produce(entry, (draft) => {
        draft.stockItems = draft.stockItems.map((item) =>
          processTransactionItems({
            ...item,
            purchasePrice: item.price
          })
        );
      });
      await actions.saveStockAdjustment({
        ...formattedEntry,
        status: Status.Finalize
      });
      setLoading({ ...loading, saveBtn: false });
    } else {
      const formattedEntry = produce(entry, (draft) => {
        draft.stockItems = draft.stockItems.map((item) =>
          processTransactionItems({
            ...item,
            purchasePrice: item.price
          })
        );
      });
      await actions.saveStockTransaction({
        ...formattedEntry,
        status: Status.Finalize,
        // When we edit existing return draft, status is Draft
        // When we create purchase return from purchase status is Finalise
        ...(formattedEntry.status === Status.Draft &&
        formattedEntry.transactionType === TransactionType.PURCHASE_RETURN
          ? { draftReturnId: entry.id, id: entry.referenceTo }
          : {}),
        ...(isKitchenPharmacyPurchaseEntry &&
          kitchenPharmacy.id && {
            kitchenPharmacyId: kitchenPharmacy.id,
            kitchenPharmacyFormStatus: Status.Draft,
            // For Kitchen Pharmacy Requisition Entry, the status should be Draft
            // Not affecting the current stock
            status: Status.Draft
          })
      });
      setLoading({ ...loading, saveBtn: false });
      localStorage.removeItem(LOCALSTORAGE_STOCK_PURCHASE);
    }
  };

  const throttledFinalizeFn = throttle(finalizeEntryFn, 5000, { trailing: false });
  const throttledDraftFn = throttle(draftSaveFn, 5000, { trailing: false });

  useHotkeys(
    "alt+t",
    () => {
      if (!draftSaveFnDisableCase()) {
        throttledDraftFn();
      } else {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Cannot save currently!",
            autoTimeout: true
          })
        );
      }
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] },
    [entry]
  );

  useHotkeys(
    "alt+s",
    () => {
      if (!finalizeEntryFnDisableCase()) {
        throttledFinalizeFn();
      } else {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Cannot save currently!",
            autoTimeout: true
          })
        );
      }
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] },
    [entry]
  );

  useHotkeys(
    "alt+c",
    () => {
      actions.navigateTo("/stock/stockProducts");
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );
  const [disableDialogSave, setDisableDialogSave] = React.useState(false);
  return (
    <Box className={styles.stockActions} position="fixed" bottom={0} padding="8px 32px">
      <Box display="flex" justifyContent="flex-end">
        <Button
          data-testmation="cancelStockTransaction"
          className={styles.stockActionBtn}
          onClick={() => {
            actions.navigateTo("/stock/stockProducts");
          }}
        >
          {tl("billing.cancel")}
        </Button>

        {[
          TransactionType.PURCHASE,
          TransactionType.OPENING_STOCK,
          TransactionType.PURCHASE_RETURN
        ].includes(entry.transactionType as TransactionType) && (
          <>
            <Button
              data-testmation="clearAllFields"
              className={styles.stockActionBtn}
              onClick={() => {
                actions.applyInitialState(entry);
              }}
            >
              {tl("stock.clearAllFields")}
            </Button>
            <StatefulButton
              disabled={errors.entryErrors.length > 0 || disableSave || isLoading(loading)}
              onClick={async () => {
                draftSaveFn();
              }}
              isLoading={loading.saveAsDraftBtn}
              circularProgressProps={{ size: 16 }}
              data-testmation="saveAsDraftStockTransaction"
              className={styles.stockActionBtn}
            >
              {tl("stock.saveAsDraft")}
            </StatefulButton>
          </>
        )}
        <Can policyAccessKey="stock:finalizePurchaseEntry">
          <StatefulButton
            variant="contained"
            color="primary"
            disabled={
              errors.entryErrors.length > 0 ||
              !entry?.stockItems?.length ||
              disableSave ||
              isLoading(loading) ||
              (entry.transactionType === TransactionType.EDIT_PURCHASE &&
                !editedEntry?.stockItems?.length)
            }
            onClick={() => {
              if (entry.stockItems?.length && !entry?.currentStockItem?.productName) {
                if (
                  isAccountSubscribed &&
                  Object.values(AppliedAccount).includes(entry.transactionType)
                ) {
                  const mappingErrors = getLedgerMappingError(entry, rcMappedPaymentMethods);
                  setLedgerMappingErrors(mappingErrors);
                  if (mappingErrors.length) {
                    return null;
                  }
                }
                setIsConfirmEntryDialogOpen(true);
              } else {
                setAddItemWarningDialog(true);
              }
              return null;
            }}
            isLoading={loading.saveBtn}
            circularProgressProps={{ size: 16 }}
            data-testmation="saveStockTransaction"
            className={styles.stockActionBtn}
          >
            {tl("stock.save")}
          </StatefulButton>
          <Modal
            open={Boolean(ledgerMappingErrors.length)}
            title={
              <Box alignItems="centre" display="flex">
                <IconButton
                  sx={{
                    color: "#f3b500"
                  }}
                >
                  <WarningIcon />
                </IconButton>
                <Typography marginTop="8px">Warning!</Typography>
              </Box>
            }
            footer={
              <Button onClick={() => setLedgerMappingErrors([])} variant="contained">
                Ok
              </Button>
            }
          >
            {ledgerMappingErrors.map((message, index) => (
              <Typography key={message}>{`${index + 1}. ${message}`}</Typography>
            ))}
          </Modal>
        </Can>

        <OkhatiDialog
          title="Warning!"
          // eslint-disable-next-line max-len
          description="Please add item in the entry to save."
          readMode
          next={() => {
            setAddItemWarningDialog(false);
          }}
          open={addItemWarningDialog}
        />

        <OkhatiDialog
          title={`Confirm ${startCase(getTransactionText(entry))}`}
          disableConfirmBtn={disableDialogSave}
          // eslint-disable-next-line max-len
          description={
            <div>
              <Typography>
                {`Are you sure you want to finalize the ${startCase(
                  getTransactionText(entry)
                )} ? This cannot be edited again!`}
              </Typography>
              {[TransactionType.PURCHASE, TransactionType.PURCHASE_RETURN].includes(
                entry.transactionType as TransactionType
              ) && (
                <Box mt={1}>
                  <Typography>
                    Payment method: <strong>{entry.paymentType}</strong>
                  </Typography>
                  <Typography>
                    Total amount: <strong>{entry.summary.totalIncVat}</strong>
                  </Typography>
                </Box>
              )}
            </div>
          }
          next={async () => {
            setDisableDialogSave(true);
            try {
              await finalizeEntryFn();
              setIsConfirmEntryDialogOpen(false);
            } catch (err) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Failed to complete stock transaction",
                  autoTimeout: false
                })
              );
            }
            setDisableDialogSave(false);
          }}
          readMode={false}
          cancel={() => {
            setIsConfirmEntryDialogOpen(false);
          }}
          open={isConfirmEntryDialogOpen}
        />
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    transactions: state.stock.stockTransactions
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      saveStockTransaction: async (entry) => {
        await dispatch(stockActions.postStockTransaction(entry));
      },
      navigateTo: (url) => {
        dispatch(push(url));
      },
      clearEntry: () => {
        dispatch(stockActions.clearEntry());
      },
      editOpeningStock: async (data) => {
        await dispatch(stockActions.editOpeningStock(data));
      },
      saveStockAdjustment: async (data) => {
        await dispatch(stockActions.saveStockAdjustment(data));
      },
      applyInitialState: (entry) => {
        const transactionType = window.location.pathname.split("/").pop();
        const newEntry = {
          ...entry,
          stockItems: [],
          transactionType,
          summary: { ...entry.summary, discountAmt: 0, totalDiscountPct: 0 }
        };
        dispatch(stockActions.updateEntry(newEntry));
      }
    }
  })
)(StockEntryActions);

import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Typography, Box, Autocomplete, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Panel from "../../../components/Panel";
import { IPD } from "../../../interfaces/IpdInterface";
import { getIpdProducts } from "../../../api/ipd";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";
import Footer from "../../Services/Footer";
import { postIpdRequestForm } from "../../../api/requestForms";
import { getStockProducts } from "../../../api/stockProducts";
import IpdClientInfo from "./IpdClientInfo";
import { notificationAdd } from "../../../actions/notification";
import { IpdTabs } from "../../Billing";

interface Props {
  onClose: () => void;
  ipd: IPD;
  requestType?: RequestType;
}
export enum RequestType {
  SERVICE = "service",
  PRODUCT = "product"
}
const LabRequestForm = ({
  onClose,
  ipd,
  requestType = RequestType.SERVICE
}: Props): JSX.Element => {
  const [productOptions, setProductOptions] = React.useState<Array<ServiceInterface>>([]);
  const [query, setQuery] = React.useState("");
  const [selectedIpdProducts, setSelectedIpdProducts] = React.useState<Array<ServiceInterface>>([]);
  const dispatch = useDispatch();
  const footer = (
    <Footer
      disableCreateNewAfter
      editable
      saveFn={async () => {
        try {
          await postIpdRequestForm({
            ipdId: ipd.id,
            requestedItems: selectedIpdProducts,
            requestedItemsType: requestType
          });
          dispatch(push(`/billing/ipd?tab=${IpdTabs.RequestForms}`));
        } catch (err) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: "Failed to create request form",
              autoTimeout: true
            })
          );
        }
      }}
      saveDisabled={!selectedIpdProducts.length}
      goBack={onClose}
    />
  );

  React.useEffect(() => {
    const products = async () => {
      try {
        if (query && query.length > 2) {
          const response =
            requestType === RequestType.SERVICE
              ? await getIpdProducts(query)
              : await getStockProducts({ search: query });
          setProductOptions(response);
        }
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to fetch products",
            autoTimeout: true
          })
        );
      }
    };
    products();
  }, [query, requestType]);

  return (
    <Panel
      onClose={onClose}
      title={requestType === RequestType.SERVICE ? "Lab Request Form" : "Medical Request Form"}
      footer={footer}
    >
      <Box pt={1} px={3} flex={1}>
        <IpdClientInfo
          ipd={ipd}
          client={{ ...ipd.client, customerNumber: ipd?.customer?.customerNumber || "" }}
        />

        <Box mt={1} p={2}>
          <Typography fontWeight="bold" mb={0.5}>
            {requestType === "service" ? "Test Particulars" : "Product Medications"}
          </Typography>
          <Autocomplete
            value={null}
            filterSelectedOptions
            options={productOptions}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {
              if (value) {
                setSelectedIpdProducts([...selectedIpdProducts, value]);
              }
            }}
            renderInput={(props) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                value=""
                variant="outlined"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            )}
          />
        </Box>
        <Box p={2}>
          {selectedIpdProducts.map((ipdProd, i) => (
            <Box key={ipdProd.id} display="flex" alignItems="center" mt={0.5}>
              <Typography>{`${i + 1}. ${ipdProd.name}`}</Typography>
              <CloseIcon
                sx={{ marginLeft: 1, cursor: "pointer" }}
                onClick={() => {
                  setSelectedIpdProducts((prevState) =>
                    prevState.filter((product) => product.id !== ipdProd.id)
                  );
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Panel>
  );
};
export default LabRequestForm;

import axios from 'axios';
import { Get, Post, Delete, Put } from './apiHelper';

const audienceRoot = '/api/audience';

export async function getAudienceDetails(id, next) {
  const response = await Get(`${audienceRoot}/${id}`, true);
  if (response) {
    if (next) next(response.data);
    return response.data;
  }
}

export async function getBatchAudience() {
  const response = await Get(`${audienceRoot}`, true);
  if (response) return response.data;
}

export async function createAudience(audienceData) {
  const response = await Post(`${audienceRoot}`, audienceData, true);
  return response.data;
}

export async function updateAudience(id, audienceData) {
  const response = await Put(`${audienceRoot}/${id}`, audienceData, true);
  return response.data;
}

export const deleteAudience = async (id) => {
  const response = await Delete(`${audienceRoot}/${id}`, true);
  return response.data;
};

export const getAudienceSizeByRules = async (audienceData, next) => {
  const response = await Post(`${audienceRoot}/audienceSizeByRules`, audienceData, true);
  if (response) {
    next(response.data);
    return response.data;
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/reminders";
import { ResourceCentreNotification } from "../interfaces/ReminderInterface";

interface State {
  notification: ResourceCentreNotification[];
}

const initialState: State = {
  notification: []
};

export const getResourceCentreNotifications = createAsyncThunk(
  "resourceCenterNotification/getNotifications",
  async () => {
    const response = await api.getResourceCentreNotification();
    return response;
  }
);

export const postResourceCentreNotifications = createAsyncThunk(
  "resourceCentreNotification/postNotifications",
  async ({ ids, data, type }: { ids: number[]; data: string; type: string }) => {
    const response = await api.postResourceCentreNotification(ids, data, type);
    return response;
  }
);

const resourceCentreNotificationSlice = createSlice({
  name: "resourceCentreNotification",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getResourceCentreNotifications.fulfilled, (draft, { payload }) => {
      draft.notification = payload;
    });
  }
});

export default resourceCentreNotificationSlice.reducer;

import React, { useState } from "react";
import { Box } from "@mui/material";
import History from "../../Assessment/History";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { getAssessments } from "../../../api/assessment";
import { Assessment as IAssessment, Assessment } from "../../../interfaces/AssessmentInterfaces";
import { useAppDispatch } from "../../../store/hooks";
import * as NotificationActions from "../../../actions/notification";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";

interface AssessmentState {
  results: Assessment[];
  total: number | null;
}

const sortList = (list: Assessment[]) =>
  list.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

const ClientAssessments: React.FC<{
  clientId: number;
  setAssessmentCopy?: React.Dispatch<React.SetStateAction<IAssessment>> | null;
  numberOfHistoryToLoad: number;
  afterFetch?: (list: Assessment[]) => void;
}> = ({ clientId, setAssessmentCopy, numberOfHistoryToLoad = 20, afterFetch }) => {
  const [clientAssessments, setClientAssessments] = useState<AssessmentState>({
    results: [],
    total: 0
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (clientId) {
      (async () => {
        try {
          setLoading(true);
          const res: AssessmentState = await getAssessments({
            clientId,
            page,
            pageSize: numberOfHistoryToLoad
          });
          const assessmentList =
            page === 0
              ? sortList(res.results)
              : clientAssessments.results.concat(sortList(res.results));
          setClientAssessments({
            results: assessmentList,
            total: res.total
          });
          if (afterFetch) afterFetch(assessmentList);
        } catch (e) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorFetchMessage(MODULE.ASSESSMENT),
              autoTimeout: true
            })
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [clientId, page]);

  return (
    <>
      <History assessments={clientAssessments.results} setAssessmentCopy={setAssessmentCopy} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        {clientAssessments.results.length !== clientAssessments.total && (
          <StatefulButton
            isLoading={loading}
            circularProgressProps={{ size: 16 }}
            onClick={() => setPage((prevState) => prevState + 1)}
          >
            Load more
          </StatefulButton>
        )}
      </Box>
    </>
  );
};

export default ClientAssessments;

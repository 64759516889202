import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSupplierLedgerApi, getSupplierTransactionsApi } from "../api/suppliers";
import { StockTransactions } from "../interfaces/StockInterfaces";
import { SupplierLedger } from "../interfaces/SupplierLedger";

export const getSupplierTransactions = createAsyncThunk(
  "supplier/getTransactions",
  async (supplierId: number) => {
    const response = await getSupplierTransactionsApi(supplierId);
    return response;
  }
);

export const getSupplierLedger = createAsyncThunk(
  "supplier/getLedger",
  async (supplierId: number) => {
    const response = await getSupplierLedgerApi(supplierId);
    return response;
  }
);

interface State {
  transactions: Array<StockTransactions>;
  ledgers: Array<SupplierLedger>;
}

const initialState: State = {
  transactions: [],
  ledgers: []
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupplierTransactions.fulfilled, (draft, { payload }) => {
      draft.transactions = payload;
    });
    builder.addCase(getSupplierLedger.fulfilled, (draft, { payload }) => {
      draft.ledgers = payload;
    });
  }
});

export default supplierSlice.reducer;

import React from "react";
import { TextField } from "@mui/material";
import { round } from "mathjs";
import { StockItemInterface } from "../../../../interfaces/StockInterfaces";
import { calculateSalesPrice } from "../../helper";

interface Props {
  item: StockItemInterface;
  updateRow: (v) => void;
}

export default function ProfitMargin({ item, updateRow }: Props): JSX.Element {
  return (
    <TextField
      variant="outlined"
      type="number"
      onFocus={(e) => e.target.select()}
      value={item.profitMargin}
      InputProps={{
        endAdornment: "%"
      }}
      onChange={({ target }) => {
        const profitMargin = Number(target.value);
        if (profitMargin > 0 && profitMargin <= 100) {
          const salesPrice = calculateSalesPrice({
            ...item,
            profitMargin,
            purchasePricePerUnit: Number(item.price)
          });
          if (item.salesPriceCalculationOn === item.unit) {
            updateRow({
              ...item,
              profitMargin,
              unitPriceExcVAT: salesPrice,
              packagePriceExcVAT: round(Number(salesPrice) * (item.unitsPerPackage || 1) || 0, 2)
            });
          } else {
            updateRow({
              ...item,
              packagePriceExcVAT: Number(salesPrice),
              unitPriceExcVAT: round(Number(salesPrice) / (item.unitsPerPackage || 1) || 0, 2)
            });
          }
        }
      }}
      label="Profit Margin %"
    />
  );
}

import React from 'react';
import { Typography, Box } from '@mui/material';

import { getSectionStyle } from '../../Lab/LabPrint/LabPrintFunctions';
import { tl, t } from '../../../components/translate';
import { scaleFont } from '../../../components/Print/Print';

import { CalendarFunctions } from '../../../components/Calendar';
import moment from 'moment';
import { rupeeDisplay } from '../../../helpers/rupee';
import { chain } from 'mathjs';
import classNames from '../../../helpers/classNames';
import CheckIcon from '@mui/icons-material/Check';

const PrintClientBillDescription = ({ relatedSettings, clientBills }) => {
  const billColumns = ['billNumber', 'issueDate', 'total', 'paidAmount', 'balance'];
  return (
    <Box>
      <Box
        component="div"
        display="flex"
        borderTop={'1px solid black'}
        borderRight={relatedSettings.isBordered && '1px solid black'}
        borderBottom={relatedSettings.isBordered && '1px solid black'}
        borderLeft={relatedSettings.isBordered && '1px solid black'}
        paddingBottom={'0.2cm'}
        p={'0.3cm'}
        className={!relatedSettings.isBordered && 'borderBotBlack1'}
        style={
          relatedSettings.isBordered
            ? getSectionStyle(relatedSettings.headerColor).rawHeaderStyle
            : {}
        }
      >
        {billColumns.map((column, i) => (
          <Box
            component="div"
            key={column}
            className={classNames({ grow2: i === 0, flex2: i === 1, flex1: i > 1 })}
          >
            <Typography component={'div'}>
              <Box
                textAlign={'left'}
                style={{ textTransform: 'uppercase' }}
                fontWeight={600}
                fontSize={scaleFont('0.27cm')}
              >
                {tl(`billing.${column}`)}
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>
      <Box component={'div'} paddingBottom={'0.45cm'}>
        {clientBills.map((item, index) => (
          <Box
            component="div"
            key={`main-item-${index}`}
            display="flex"
            style={
              relatedSettings.isBordered
                ? {
                    pageBreakInside: 'avoid'
                  }
                : {}
            }
            borderTop={relatedSettings.isBordered && index != 0 && '0.5px solid lightgrey'}
            borderBottom={
              index == clientBills.length - 1
                ? relatedSettings.isBordered && '1px solid black'
                : relatedSettings.isBordered && '0.5px solid lightgrey'
            }
            className={
              index == clientBills.length - 1 && !relatedSettings.isBordered && 'borderBotBlack1'
            }
          >
            <Box
              borderLeft={relatedSettings.isBordered && '1px solid black'}
              paddingLeft={'0.3cm'}
              component="div"
              key={`item-${index}`}
              className={'flex1'}
              paddingBottom={'0.2cm'}
              paddingTop={'0.2cm'}
            >
              <Typography component={'div'}>
                <Box
                  className="blockContent"
                  fontWeight={400}
                  fontSize={scaleFont('0.33cm')}
                  textAlign={'left'}
                >
                  {item.isDraft ? '-' : item.billNumber}
                </Box>
              </Typography>
            </Box>

            <Box component="div" key={`item-${index}`} className={'flex2'} paddingBottom={'0.2cm'}>
              <Typography component={'div'}>
                <Box
                  className="blockContent"
                  fontWeight={400}
                  fontSize={scaleFont('0.33cm')}
                  textAlign={'left'}
                  paddingTop={'0.2cm'}
                >
                  {CalendarFunctions.convertADtoBS(moment(item.issueDate)).formatted3}
                  {', '}
                  {CalendarFunctions.convertADtoBS(moment(item.issueDate)).bsYear}
                </Box>
              </Typography>
            </Box>
            <Box component="div" key={`item-${index}`} className={'flex1'} paddingBottom={'0.2cm'}>
              <Typography component={'div'}>
                <Box
                  className="blockContent"
                  fontWeight={400}
                  fontSize={scaleFont('0.33cm')}
                  textAlign={'left'}
                  paddingTop={'0.2cm'}
                >
                  {rupeeDisplay(item.total)}
                </Box>
              </Typography>
            </Box>
            <Box component="div" key={`item-${index}`} className={'flex1'} paddingBottom={'0.2cm'}>
              <Typography component={'div'}>
                <Box
                  className="blockContent"
                  fontWeight={400}
                  fontSize={scaleFont('0.33cm')}
                  textAlign={'left'}
                  paddingTop={'0.2cm'}
                >
                  {!item.paymentInfo.paid && rupeeDisplay(item.paymentInfo.paidAmount)}
                  {item.paymentInfo.paid && <CheckIcon color="black" />}
                </Box>
              </Typography>
            </Box>
            <Box
              borderRight={relatedSettings.isBordered && '1px solid black'} //last
              // paddingRight={'0.3cm'} //last
              component="div"
              key={`item-${index}`}
              className={'flex1'}
              paddingBottom={'0.2cm'}
            >
              <Typography component={'div'}>
                <Box
                  className="blockContent"
                  fontWeight={400}
                  fontSize={scaleFont('0.33cm')}
                  textAlign={'left'}
                  paddingTop={'0.2cm'}
                >
                  {rupeeDisplay(
                    chain(item.summary?.totalAmount)
                      .subtract(item.paymentInfo?.paidAmount || 0)
                      .done()
                  )}
                </Box>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PrintClientBillDescription;

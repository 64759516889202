import { GeneralError } from '../interfaces/ErrorInterfaces';
import { Action } from 'typescript-fsa';

export const addGeneralErrorCreator = (key) => {
  const addGeneralError = (error: GeneralError): Action<GeneralError> => ({
    type: 'ERROR_ADD',
    payload: { ...error, key },
    error: true
  });
  return addGeneralError;
}

export const removeGeneralError = (error: GeneralError): Action<GeneralError> => ({
  type: 'ERROR_REMOVE',
  payload: error,
  error: true
});

export const removeGeneralErrorByKey = (key: string): Action<string> => ({
  type: 'ERROR_REMOVE_WITH_KEY',
  payload: key,
  error: true
});
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { AddressOptions } from "../../../../hooks/query/useAddressOption";
import { LabSummary } from "../../../../interfaces/Lab";
import { calculateTestCountForPalika } from "./helper";

export interface TestCountInterface {
  noOfCompleteLabTest: number;
  noOfIncompleteLabTest: number;
  palikaName: string;
}

const RegionDescription = ({
  labSummary,
  palikas,
  addressMasterData,
  palikaNameListForDistrict
}: {
  labSummary: LabSummary[];
  palikas: string[];
  addressMasterData: AddressOptions[];
  palikaNameListForDistrict: string[];
}): JSX.Element => {
  const testCountDetail = React.useMemo<TestCountInterface[]>(
    () =>
      calculateTestCountForPalika(
        palikas,
        palikaNameListForDistrict,
        addressMasterData,
        labSummary
      ),
    [labSummary, addressMasterData, palikas, palikaNameListForDistrict]
  );

  return (
    <Box
      sx={{
        textAlign: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 16px rgba(0, 150, 84, 0.16)",
        justifyContent: "center",
        height: "60vh",
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em"
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "10px"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#d7d7d7",
          borderRadius: "10px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#d7d7d7"
        }
      }}
    >
      <Grid container spacing={2}>
        {testCountDetail.length > 0 &&
          testCountDetail.map((item) => (
            <Grid key={item.palikaName} margin="15px" item xs={12} sm={12} md={12} lg={12}>
              <Box textAlign="left" p="10px" sx={{ border: "1px solid #b3b3b3" }}>
                <Typography fontSize="16px" fontWeight="medium">
                  {`${item.palikaName} Nagarpalika`}
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography color="#838383">Lab Tests Completed</Typography>
                  <Typography color="#838383">{item.noOfCompleteLabTest}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography color="#838383">Lab in Progress</Typography>
                  <Typography color="#838383">{item.noOfIncompleteLabTest}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default RegionDescription;

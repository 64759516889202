import { Box } from "@mui/material";
import React from "react";
import ServiceProviderAutoSelect from "../ServiceProvider/ServiceProviderAutoSelect";

interface ServiceProviderSelector {
  resourceCentreId: number;
  spID: number;
  onChange: (v) => void;
  hideLabel?: boolean;
  width?: string;
}

const ServiceProviderSelector: React.FC<ServiceProviderSelector> = ({
  resourceCentreId,
  spID,
  onChange,
  hideLabel = false,
  width
}) => (
  <Box data-testmation="serviceProviderSelector" width={width}>
    <ServiceProviderAutoSelect
      hideLabel={hideLabel}
      resourceCentreId={resourceCentreId}
      serviceProviderId={spID}
      onChange={(v) => {
        onChange(v);
      }}
    />
  </Box>
);

export default ServiceProviderSelector;

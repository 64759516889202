import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { produce } from "immer";
import { DOMParser as PDOMParser } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { connect } from "react-redux";
import LabEntryFooter from "./LabEntryFooter";
import { tl } from "../../../components/translate";
import styles from "../Lab.module.css";
import Modal from "../../../components/Modal/Modal";
import useProseMirror from "../../../components/RichTextfield/useProsemirror";
import {
  customSchema,
  deserializeNode,
  proseMirrorOptions,
  RichTextfield
} from "../../../components/RichTextfield";
import { LabRecord, LabTest, LabTestRecordResultData } from "../../../interfaces/Lab";
import { RootState } from "../../../store";

interface RadiologyContentInterface {
  labRecord: LabRecord;
  onLabRecordResultsChange: (val) => void;
  onCommentChange: (val) => void;
  onFilesChange: (files) => void;
  additionallabdata: boolean;
}

interface LabTestRowInterface {
  labTest: LabTestRecordResultData;
  additionallabdata: boolean;
  onRowChange: (newRow) => void;
}

interface LabTestSubRowInterface {
  test: LabTest;
  additionallabdata: boolean;
  onSubRowChange: (newSubRow) => void;
}

function useParsedProseMirror(
  document,
  proseMirrorOptionsParam,
  schema = customSchema
): [EditorState<any>, React.Dispatch<React.SetStateAction<EditorState<any>>>] {
  const parser = new DOMParser();
  const doc = PDOMParser.fromSchema(schema).parse(
    parser.parseFromString(`${document}`, "text/html").documentElement
  );
  const [state, setState] = useProseMirror({
    ...proseMirrorOptionsParam,
    doc
  });
  return [state, setState];
}

function AdditionalData({ lt, onSubRowChange }) {
  const [openModal, setOpenModal] = React.useState(false);
  const [state, setState] = useParsedProseMirror(lt.additionalTestData || "", proseMirrorOptions);
  const additionalDataView = (
    <Box>
      <Typography>Additional Test Data</Typography>
      <RichTextfield state={state} setState={setState} />
    </Box>
  );

  return (
    <>
      <Button onClick={() => setOpenModal(true)}> More</Button>
      <Modal
        onSave={() => {
          const mutatedLt = produce(lt, (draft) => {
            draft.additionalTestData = deserializeNode(state.doc);
          });
          onSubRowChange(mutatedLt);
          setOpenModal(false);
        }}
        title="Additional Test Data"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        {additionalDataView}
      </Modal>
    </>
  );
}

const LabTestSubRow: React.FC<LabTestSubRowInterface> = ({
  additionallabdata,
  test,
  children = <></>,
  onSubRowChange
}) => {
  const [lt, setLt] = React.useState(test);
  React.useEffect(() => {
    setLt(test);
  }, [test]);
  const [showMore, setShowMore] = React.useState(false);

  return (
    <Box
      marginLeft="10px"
      fontWeight="400"
      onMouseEnter={() => setShowMore(true)}
      onMouseLeave={() => setShowMore(false)}
    >
      {lt.name}
      {showMore && additionallabdata && lt?.additionalTestData && (
        <AdditionalData lt={lt} onSubRowChange={onSubRowChange} />
      )}
      {children}
    </Box>
  );
};

const LabTestRow: React.FC<LabTestRowInterface> = ({ additionallabdata, labTest, onRowChange }) => {
  const [labTestRow, setLabTestRow] = React.useState({} as LabTestRecordResultData);
  React.useEffect(() => {
    setLabTestRow(labTest);
  }, [labTest]);
  React.useEffect(() => {
    if (onRowChange && Object.keys(labTestRow).length) onRowChange(labTestRow);
  }, [labTestRow]);
  return (
    <Box margin="10px 0px">
      <Box>
        <Box component="div" fontWeight="600">
          {labTestRow.name}
        </Box>

        {labTestRow?.labTests?.length > 0 &&
          labTestRow.labTests.map((lt, index) => (
            <LabTestSubRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              additionallabdata={additionallabdata}
              test={lt}
              onSubRowChange={(newSubTest) => {
                const newRow = produce(labTestRow.labTests, (draft) => {
                  draft.splice(index, 1, newSubTest);
                });
                setLabTestRow({ ...labTestRow, labTests: newRow });
              }}
            >
              {lt.subTests && lt.subTests.length > 0 && (
                <>
                  {lt.subTests.map((subT, ind) => (
                    <LabTestSubRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={ind}
                      additionallabdata={additionallabdata}
                      test={subT}
                      onSubRowChange={(newSubTest) => {
                        const newRow = produce(labTestRow.labTests, (draft) => {
                          draft[index].subTests.splice(ind, 1, newSubTest);
                        });
                        setLabTestRow({ ...labTestRow, labTests: newRow });
                      }}
                    />
                  ))}
                </>
              )}
            </LabTestSubRow>
          ))}
      </Box>
    </Box>
  );
};

const RadiologyContent: React.FC<RadiologyContentInterface> = ({
  labRecord,
  onCommentChange,
  onFilesChange,
  additionallabdata,
  onLabRecordResultsChange
}) => {
  if (!labRecord) return null;
  return (
    <Box>
      <Box>
        <Typography className={styles.fifthWidthCent}>
          <Box marginLeft="30px" component="span" fontWeight="600">
            {tl("lab.test")}
          </Box>
        </Typography>
      </Box>
      <Box borderTop="1px solid gray" borderBottom="1px solid gray">
        <Box p="8px 32px">
          {labRecord?.results.data.map((test, ind) => (
            <LabTestRow
              // eslint-disable-next-line react/no-array-index-key
              key={ind}
              onRowChange={(newRow) => {
                const newResults = produce(labRecord.results.data, (draft) => {
                  draft.splice(ind, 1, newRow);
                });
                onLabRecordResultsChange(newResults);
              }}
              additionallabdata={additionallabdata}
              labTest={test}
            />
          ))}
          <LabEntryFooter
            onCommentChange={onCommentChange}
            onFilesChange={onFilesChange}
            labRecord={labRecord}
            printMode={false}
            status=""
          />
        </Box>
      </Box>
    </Box>
  );
};

export default connect((state: RootState) => {
  const rc =
    state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  const additionallabdata: boolean = rc?.labSettings?.additionallabdata;
  return {
    additionallabdata
  };
}, null)(RadiologyContent);

import { ReducerBuilder } from './ReducerBuilder';
import * as SchedulesActions from '../actions/schedules';
import cloneDeep from 'lodash/cloneDeep';
import produce from 'immer';

const INITIAL_STATE = {
  collection: [],
  lastTouched: null
};

function setSchedules(state, payload) {
  const newState = cloneDeep(state);
  newState.collection = payload;
  return newState;
}

function deleteSchedule(state, payload) {
  const newState = produce(state, (draft) => {
    draft.collection = draft.collection.filter((item) => item.id !== payload.id);
  });
  return newState;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(SchedulesActions.Type.GET_SCHEDULES, setSchedules)
  .mapAction(SchedulesActions.Type.DELETE_SCHEDULE, deleteSchedule)
  .build();
export default reducer;

import { Box } from "@mui/material";
import React from "react";
import * as calFns from "../../../../components/Calendar/functions/calendarFunctions";
import Headers from "../Headers";
import DayBookList from "./DayBookList";
import useDayBook from "./hooks/useDayBook";
import Filters from "../Filters";

const DayBook = (): JSX.Element => {
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date()
  });

  const data = useDayBook(filters);

  const headers = [
    { key: "date", label: "Date" },
    { key: "voucherCode", label: "Voucher Code" },
    { key: "debitLedger", label: "Debit Ledger", className: "flex-1" },
    { key: "entryAmt", label: "Entry Amt" },
    { key: "narration", label: "Narration" }
  ];

  return (
    <Box className="dayBookReport">
      <Box minWidth="900px">
        <Box margin="0 2rem">
          <Filters filters={filters} setFilters={setFilters} />
        </Box>
        <Headers headers={headers} />
        <Box
          sx={{
            height: "calc(100vh - 230px)",
            overflow: "auto",
            scrollbarGutter: "stable"
          }}
        >
          <DayBookList data={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default DayBook;

import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { TaxationInterface } from "../../../interfaces/ProductInterface";
import { RootState } from "../../../store";

interface VatOptionSelection {
  onChange: (val: TaxationInterface) => void;
  vatPercentageOptions: { id: number; description: string }[];
  value: TaxationInterface;
  sx: { [key: string]: string };
  dataTestmation: string;
}

export default function VatOptionSelection({
  vatPercentageOptions,
  onChange,
  value,
  sx,
  dataTestmation
}: VatOptionSelection): JSX.Element {
  const isVatRegistered = useSelector(
    (state: RootState) =>
      state.userContext?.resourceCentre?.settings?.billingSettings?.vatRegistered
  );

  return (
    <Autocomplete
      options={vatPercentageOptions}
      data-testmation={dataTestmation}
      value={value || null}
      onChange={(e, v) => {
        onChange(v as TaxationInterface);
      }}
      sx={{ margin: "0 10px", ...sx }}
      disabled={!isVatRegistered}
      getOptionLabel={(option) => `${option.description}`}
      renderInput={(params) => (
        <TextField
          data-testmation="ledgerMapping"
          //   eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          variant="outlined"
          margin="dense"
          label="Vat Mapping"
          placeholder="Vat Mapping"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

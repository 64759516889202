import produce from "immer";
import { round } from "mathjs";
import hasOwnProperty from "../../../helpers/object";
import { Entry, StockItemInterface, VarianceType } from "../../../interfaces/StockInterfaces";

const calculateTaxableAmt = (stockItems: StockItemInterface[]) =>
  round(
    stockItems?.reduce(
      (acc, stockItem) => acc + stockItem.grossTotal + stockItem.grossTotalOfFreeItems,
      0
    ),
    2
  );
const calculateRates = (stockItems) => {
  const rates = {};
  stockItems?.forEach((stockItem) => {
    const vatPctKey = stockItem.vatPct.toString();
    if (rates[vatPctKey]) {
      rates[vatPctKey] += stockItem.vatAmt;
    } else {
      rates[vatPctKey] = stockItem.vatAmt;
    }
  });
  return rates;
};

const calculateTotalVatAmt = (stockItems: StockItemInterface[]) =>
  round(
    stockItems?.reduce((acc, stockItem) => acc + stockItem.totalIncVat, 0),
    2
  );

const calculateTotalCcAmt = (stockItems) =>
  round(stockItems?.reduce((acc, stockItem) => acc + stockItem.grossTotalOfFreeItems, 0) || 0, 2);

const calculateDiscountAmt = (stockItems: StockItemInterface[]) =>
  round(
    stockItems?.reduce((acc, stockItem) => acc + stockItem.discountAmt, 0),
    2
  );

const calculateDiffInTotalAmt = (stockItems: StockItemInterface[]) => {
  const diffInTotalAmt = stockItems.reduce(
    (acc, stockItem) => acc + stockItem.diffInGrossTotalIncVat,
    0
  );
  return diffInTotalAmt;
};

const selectChangedItems = (
  oldTrnx: StockItemInterface[],
  newTrnx: StockItemInterface[]
): StockItemInterface[] => {
  const changedItems = [];
  newTrnx?.forEach((item, i) => {
    const updatedItem = produce(item, (draft) => {
      draft.diffInGrossTotalIncVat = draft.totalIncVat - oldTrnx[i].totalIncVat;
      draft.diffInGrossTotalExcVat = draft.grossTotal - oldTrnx[i].grossTotal;
      draft.diffInGrossTotal = draft.grossTotal - oldTrnx[i].grossTotal;
      if (draft?.quantity !== oldTrnx[i]?.quantity && draft.price !== oldTrnx[i].purchasePrice) {
        draft.variance = VarianceType.Quantity;
        draft.diffInPrice = draft.price - oldTrnx[i].purchasePrice;
        draft.diffInQty = draft.quantity - oldTrnx[i].quantity;
      } else {
        if (draft?.quantity !== oldTrnx[i]?.quantity) {
          draft.variance = VarianceType.Quantity;
          draft.diffInQty = draft.quantity - oldTrnx[i].quantity;
        }
        if (draft.price !== oldTrnx[i].purchasePrice) {
          draft.variance = VarianceType.Price;
          draft.diffInPrice = draft.price - oldTrnx[i].purchasePrice;
        }
      }
    });
    hasOwnProperty(updatedItem, "variance") && changedItems.push(updatedItem);
  });
  return changedItems;
};

export const editPurchaseData = (entry: Entry, transactions): Entry => {
  const selectedTransaction = transactions.find((item) => item.id === entry.id);
  const changedItems =
    (selectedTransaction &&
      selectChangedItems(selectedTransaction?.stockTransactionItems, entry.stockItems)) ||
    [];
  return produce(entry, (draft) => {
    draft.stockItems = changedItems;
    draft.summary.totalCcAmt = calculateTotalCcAmt(changedItems);
    draft.summary.taxAmt = calculateTaxableAmt(changedItems);
    draft.summary.discountAmt = calculateDiscountAmt(changedItems);
    draft.summary.totalDiscountPct = 0;
    draft.summary.rates = calculateRates(changedItems);
    draft.summary.totalIncVat = calculateTotalVatAmt(changedItems);
    draft.totalAmount = draft.summary.totalIncVat;
    draft.paidAmount = draft.summary.totalIncVat;
    draft.diffInTotalAmt = calculateDiffInTotalAmt(changedItems);
  });
};

import * as React from "react";
import { Box } from "@mui/material";
import Panel from "../Panel";
import ClientAssessments from "../../containers/Client/ClientInfo/ClientAssessments";

interface Props {
  onClose: () => void;
  clientId: number;
}

function AssessmentHistoryPanel({ onClose, clientId }: Props): JSX.Element {
  return (
    <Panel onClose={onClose}>
      <Box px={5} py={2}>
        <ClientAssessments clientId={clientId} />
      </Box>
    </Panel>
  );
}

export default AssessmentHistoryPanel;

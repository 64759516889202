/* eslint-disable camelcase */
import { Client } from "./ClientInterface";
import { Referrer } from "./ReferrerInterface";
import { ReverseMap } from "../helpers/types";

export enum HMISServiceType {
  GENERAL_TREATMENT = "general_treatment",
  IMCI = "IMCI",
  NUTRITION = "nutrition",
  SAFE_MOTHERHOOD = "safe_motherhood",
  FAMILY_PLANNING = "family_planning",
  LEPROSY = "leprosy",
  TUBERCULOSIS = "tuberculosis",
  VECTOR_BORNE_DISEASE = "vector_borne_disease",
  SEXUAL_DISEASE = "sexual_disease",
  OTHERS = "others"
}

export enum HmisReportType {
  REPORT_TYPE_1 = "report_1",
  REPORT_TYPE_2 = "report_2"
}

export enum ServiceCharge {
  FREE = "free",
  PAID = "paid"
}

export type ServiceChargeType = ReverseMap<typeof ServiceCharge>;

export interface HMIS {
  id: number;
  clientId: number;
  resourceCentreId: number;
  mulDartaNumber: string;
  followup: boolean;
  himsServiceType: HMISServiceType;
  serviceCharge: ServiceChargeType;
  referrerId: number;
  active: boolean;
  creatorUserId: number;
  created_at: Date | string;
  updated_at: Date | string;
  client: Client;
  referrer: Referrer;
}

export interface DisplayDataInterface {
  ageGroup: string;
  recent: { male: number; female: number };
  total: { male: number; female: number };
}

export interface HmisSummary {
  id: number;
  reportType: string;
  hmisFormDataId: number;
  period: string;
  syncDate: Date;
  hmisFormData: HmisFormData;
  displayData: DisplayDataInterface[];
  categoryName: string;
  createdAt: string;
}

export interface HmisFormData {
  id: number;
  categoryName: string;
  client: string;
  followUp: boolean;
  ipdCase: string;
}

import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/bookableResources";
import * as NotificationActions from "./notification";
import { IBookableResource } from "../interfaces/BookableResources";
import { IThunkDispatch } from "../store";

export enum Type {
  POST_BOOKING_RESOURCE = "POST_BOOKING_RESOURCE",
  UPDATE_BOOKING_RESOURCE = "UPDATE_BOOKING_RESOURCE",
  GET_BOOKING_RESOURCE = "GET_BOOKING_RESOURCE",
  DEACTIVATE_BOOKING_RESOURCE = "DEACTIVATE_BOOKING_RESOURCE",
  GET_SERVICETAGS = "GET_SERVICETAGS",
  UPLOAD_ICON_IMAGE = "UPLOAD_ICON_IMAGE",
  UPDATE_ICON_IMAGE = "UPDATE_ICON_IMAGE"
}

export const postBookableResource =
  (data: IBookableResource, next?: (value: IBookableResource) => void) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.POST_BOOKING_RESOURCE, async () => {
        const res = await api.postBookableResource(data);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Bookable Resource Created!",
              autoTimeout: true
            })
          );
        }
        if (next) {
          next(res);
        }
        return res;
      })
    );
  };

export const updateBookableResource =
  (data: IBookableResource, next?: (value: IBookableResource) => void) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_BOOKING_RESOURCE, async () => {
        const res = await api.updateBookableResource(data);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Resource Updated Sucessfully!",
              autoTimeout: true
            })
          );
          if (next) {
            next(res);
          }
        }
        return res;
      })
    );
  };

export const deactivateBookableResource =
  (id: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.DEACTIVATE_BOOKING_RESOURCE, async () => {
        const res = await api.deactivateBookableResource(id);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Resource deleted Sucessfully!",
              autoTimeout: true
            })
          );
        }
        return res;
      })
    );
  };

export const getBookableResources =
  () =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_BOOKING_RESOURCE, async () => {
        const res = await api.getBookingResources();
        return res;
      })
    );
  };

export const getServicetags =
  () =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_SERVICETAGS, async () => {
        const res = await api.getServicetags();
        return res;
      })
    );
  };

export const postWithIconImage =
  (data: IBookableResource, pictureData: string, next?: (value: IBookableResource) => void) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPLOAD_ICON_IMAGE, async () => {
        const res = await api.uploadIconImage(data, pictureData);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Bookable Resource Created!",
              autoTimeout: true
            })
          );
        }
        if (next) {
          next(res);
        }
        return res;
      })
    );
  };

export const updateWithIconImage =
  (data: IBookableResource, pictureData: string, next?: (value: IBookableResource) => void) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_ICON_IMAGE, async () => {
        const res = await api.uploadIconImage(data, pictureData);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Bookable Resource Updated!",
              autoTimeout: true
            })
          );
        }
        if (next) {
          next(res);
        }
        return res;
      })
    );
  };

import React from "react";
import { Box, Typography } from "@mui/material";
import { EmptyView } from "../../../../components/OList";
import "../styles.scss";
import { BalanceSheetInterface } from "../../../../interfaces/AccountReportsInterface";
import { displayAmountByLedgerGroup } from "../helper";

const ListBody = ({
  data,
  showPreviousYearPeriod
}: {
  data: BalanceSheetInterface[];
  showPreviousYearPeriod: boolean;
}): React.ReactElement => {
  if (!data) {
    return (
      <EmptyView>
        <Box textAlign="center" padding="50px">
          No items to show.
        </Box>
      </EmptyView>
    );
  }
  return (
    <Box>
      {data.map((primaryLedger) => (
        <div key={primaryLedger.primaryLedgerName}>
          <Box className="primary-ledger-cell">
            <Typography className="accounts-list-primary-name">
              {primaryLedger.primaryLedgerName}
            </Typography>
          </Box>
          {primaryLedger.secondaryLedgers?.map((secondaryLedger) => (
            <div key={secondaryLedger.id}>
              <Box className="secondary-ledger-cell">
                <Typography className="account-list-secondary-name">
                  {secondaryLedger.secondaryLedgerName}
                </Typography>
              </Box>
              {secondaryLedger.accountingGroups?.map((acGroup) => (
                <Box key={acGroup.id}>
                  <Box className="accounting-group-cell">
                    <Typography className="accounting-group-name">
                      {acGroup.accountingGroupName}
                    </Typography>
                    <Typography className="accounting-group-balance">
                      {displayAmountByLedgerGroup(primaryLedger.primaryLedgerName, acGroup.balance)}
                    </Typography>

                    {showPreviousYearPeriod && (
                      <Typography className="accounting-group-balance">
                        {displayAmountByLedgerGroup(
                          primaryLedger.primaryLedgerName,
                          acGroup.previousYearBalance
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </div>
          ))}
          <Box
            sx={{
              padding: "1rem 1.625rem 1rem 1rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "2px solid black"
            }}
          >
            <Typography fontSize="medium" className="accounts-list-primary-name">
              Total
            </Typography>
            <Typography className="total-amount-value">
              {displayAmountByLedgerGroup(
                primaryLedger.primaryLedgerName,
                primaryLedger.totalBalance
              )}
            </Typography>
            {showPreviousYearPeriod && (
              <Typography className="total-amount-value">
                {displayAmountByLedgerGroup(
                  primaryLedger.primaryLedgerName,
                  primaryLedger.totalPreviousYearBalance
                )}
              </Typography>
            )}
          </Box>
        </div>
      ))}
    </Box>
  );
};

export default ListBody;

import * as React from "react";
import Box from "@mui/material/Box";
// eslint-disable-next-line import/no-extraneous-dependencies
import { localPoint } from "@vx/event";
import moment from "moment";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import classNames from "../../../helpers/classNames";
import styles from "./styles.module.css";
import useInterval from "../../../hooks/useInterval";
import { getColorCode } from "../../../helpers/bookings";
import HtmlTooltip from "../../HtmlTooltip";

const CAL_HEIGHT = 2400;

const BUTTON = {
  LEFT: 0,
  RIGHT: 2
};

const getProps = (e, parent, column, duration = 15) => {
  const coords = localPoint(parent, e);
  const y = coords.y < 0 ? 0 : coords.y;
  const cell = Math.floor((y * (1440 / duration)) / 2400);
  return { yPos: cell * ((2400 / 1440) * duration), column, cell };
};

const getScheduleUnderneath = (e, parent, day, events) => {
  const coords = localPoint(parent, e);
  const y = coords.y < 0 ? 0 : coords.y;
  const min = Math.floor(y / (2400 / 1440));
  const clickTime = moment(day).startOf("day").add(min, "minutes");
  const underneathEvent = events.find((event) => {
    if (event.type === "schedule") {
      return (
        moment(event.start).isSameOrBefore(clickTime) && moment(event.end).isSameOrAfter(clickTime)
      );
    }
    return null;
  });
  return underneathEvent;
};

const getEventFromProps = (day, startProps, endProps, duration = 15) => {
  const start = startProps.yPos > endProps.yPos ? endProps : startProps;
  const end = startProps.yPos <= endProps.yPos ? endProps : startProps;
  return {
    start: moment(day)
      .startOf("day")
      .add(start.yPos * (60 / (CAL_HEIGHT / 24)), "minutes"),
    end: moment(day)
      .startOf("day")
      .add(end.yPos * (60 / (CAL_HEIGHT / 24)) + duration, "minutes"),
    height: `${end.yPos - start.yPos}px`,
    column: start.column,
    cell: start.cell
  };
};
const getHeight = (startProps, currentProps, duration = 15) => {
  const topProps = startProps.cell < currentProps.cell ? startProps : currentProps;
  const bottomProps = startProps.cell > currentProps.cell ? startProps : currentProps;
  const unitHeight = (CAL_HEIGHT / 1440) * duration;
  return `${bottomProps.yPos + unitHeight - topProps.yPos}px`;
};

const getTop = (startProps, currentProps) => {
  const topProps = startProps.cell < currentProps.cell ? startProps : currentProps;
  return `${topProps.yPos}px`;
};

const mapEvents = (events) => {
  const map = {};
  events.forEach((event) => {
    const date = moment(event.start).date();
    map[date] = map[date] || [];
    map[date].push(event);
  });
  return map;
};

export const getEventTop = (start: moment.Moment): number =>
  moment(start).diff(moment(start).startOf("day"), "minutes") * (CAL_HEIGHT / 1440);

const getEventHeight = (start, end) =>
  moment(end).diff(moment(start), "minutes") * (CAL_HEIGHT / 1440);

const EventContent = ({ event, boxHeight = 40, detailed = false }) => {
  if (detailed || boxHeight >= 50) {
    return (
      <Typography style={{ paddingLeft: "8px" }}>
        <Box whiteSpace="nowrap" overflow="hidden" fontSize="12px">
          {moment(event.start).format("h:mm")} - {moment(event.end).format("h:mm a")}
        </Box>
        {event.name && (
          <Box fontSize="12px">
            {event.name}, {event.address}
          </Box>
        )}
      </Typography>
    );
  }
  return (
    <>
      <Typography style={{ paddingLeft: "4px" }}>
        <Box whiteSpace="nowrap" overflow="hidden" fontSize="12px" component="span">
          {event.name.toUpperCase()} {moment(event.start).format("h:mm a")}
        </Box>
      </Typography>
    </>
  );
};

const TimeIndicator = () => {
  const [currentTime, setCurrentTime] = React.useState(moment());
  useInterval(() => {
    setCurrentTime(moment());
  }, 60000);
  return (
    <Box
      position="absolute"
      width="100%"
      height="3px"
      top={`${getEventTop(currentTime) - 1.5}px`}
      style={{
        background: "red",
        pointerEvents: "none"
      }}
      data-testmation="currentTimeIndicator"
    >
      <Box
        position="relative"
        top="-3px"
        left="-5px"
        width="10px"
        height="10px"
        style={{ background: "red" }}
        borderRadius="50%"
      />
    </Box>
  );
};

const Columns = ({
  days,
  events = [],
  placeholderEvent,
  onNewEvent,
  onEventClick,
  onScheduleSelect
}: {
  days: Array<moment.Moment>;
  events: Array<unknown>;
  placeholderEvent: unknown & { end: moment.Moment; start: moment.Moment; column: number };
  onNewEvent: (v, e) => void;
  onEventClick: (v, e) => void;
  onScheduleSelect: (v) => void;
}): JSX.Element => {
  const columnRefs = [
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef()
  ];
  const newEventPlaceholderRef = React.useRef(null);
  const mouseDown = React.useRef(false);
  const [newEventProps, setNewEventProps] = React.useState({
    startProps: null,
    currentProps: null,
    currentSchedule: null
  });
  const mappedEvents = React.useMemo(() => mapEvents(events), [events]);

  const calendarParams = new URLSearchParams(window.location.search);

  const highlightDate = calendarParams.get("date");

  const doesOverlap = (dts1: { start: Date; end: Date }, dts2: { start: Date; end: Date }) =>
    dts1.start < dts2.end && dts1.end > dts2.start;

  const bookingOverlapFrequencyHash = React.useMemo(() => {
    const ret = {};
    days.forEach((day) => {
      let mappedEventsArr = mappedEvents[moment(day).date()] || [];
      mappedEventsArr = mappedEventsArr
        .filter((e) => e.type !== "schedule")
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      for (let i = 0; i < mappedEventsArr.length - 1; i += 1) {
        const overlappedIdsForI = [];
        for (let j = i + 1; j < mappedEventsArr.length; j += 1) {
          const dtrOverlaps = doesOverlap(
            { start: new Date(mappedEventsArr[i].start), end: new Date(mappedEventsArr[i].end) },
            { start: new Date(mappedEventsArr[j].start), end: new Date(mappedEventsArr[j].end) }
          );
          if (dtrOverlaps) {
            overlappedIdsForI.push(mappedEventsArr[j].id);
          }
        }
        ret[mappedEventsArr[i].id] = overlappedIdsForI;
      }
    });
    return ret;
  }, [days, mappedEvents]);

  const normalizeSorting = (mEvents, bOverlapFrequencyHash) => {
    function sortByFrequency(a, b) {
      return (
        (bOverlapFrequencyHash[a.id]?.length || 0) - (bOverlapFrequencyHash[b.id]?.length || 0)
      );
    }
    function sortByDuration(a, b) {
      return moment(a.start).diff(moment(a.end)) - moment(b.start).diff(moment(b.end));
    }
    return mEvents.sort(sortByFrequency).sort(sortByDuration);
  };

  const getBookingWidthFromFrequency = (frequency) => {
    if (frequency === 0) return "95%";
    if (frequency === 1) return "60%";
    if (frequency === 2) return "40%";
    return "25%";
  };

  return (
    <Box position="absolute" height={`${CAL_HEIGHT}px`} width="100%">
      <Box
        position="relative"
        height="100%"
        width="30px"
        style={{ float: "left" }}
        paddingLeft="4px"
        borderRight="1px solid lightgray"
      >
        {Array.from(Array(24)).map((it, ix) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={ix} height={CAL_HEIGHT / 24}>
            <Typography>
              <Box fontSize="10px" component="span">
                {moment().startOf("d").add(ix, "hours").format("h:mm a")}
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>
      {days.map((day, idx) => (
        <div
          key={day.toISOString()}
          ref={columnRefs[idx]}
          className={day.isSame(highlightDate, "day") ? styles.fadeHightlight : ""}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          style={{
            position: "relative",
            height: "100%",
            width: `calc((100% - 30px) / ${days.length})`,
            float: "left",
            boxSizing: "border-box",
            ...(day.isSame(moment(), "day") ? { border: "1px solid #009654" } : {})
          }}
        >
          {/* schedules */}
          {(mappedEvents[moment(day).date()] || []).map((event) => {
            if (event.type !== "schedule") return null;
            return (
              <div
                key={event.start}
                id={`schedule-${event.id}`}
                className={`${classNames(styles.calSchedule, {
                  [styles.publicBookingSchedule]: Boolean(event.publicBooking),
                  [styles.publicScheduleOCHours]: Boolean(event.publicSchedule)
                })} ${new Date(event.start).getDay()}xthDay`}
                style={{
                  pointerEvents: "none",
                  position: "absolute",
                  width: "100%",
                  boxSizing: "border-box",
                  height: getEventHeight(event.start, event.end),
                  top: getEventTop(event.start)
                }}
                data-testmation="calendar_Schedule"
              />
            );
          })}
          {
            // bookings
            normalizeSorting(
              mappedEvents[moment(day).date()] || [],
              bookingOverlapFrequencyHash
            ).map((event) => {
              if (event.type === "schedule") return null;
              const overlapFrequency = bookingOverlapFrequencyHash[event.id]?.length || 0;
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  role="button"
                  tabIndex={0}
                  key={event.id}
                  className={classNames(styles.calEvent)}
                  style={{
                    position: "absolute",
                    right: 0,
                    width: getBookingWidthFromFrequency(overlapFrequency),
                    borderRadius: "3px",
                    boxSizing: "border-box",
                    height: `${getEventHeight(event.start, event.end)}px`,
                    top: getEventTop(event.start),
                    border: `${getColorCode(event).border}`,
                    backgroundColor: `${getColorCode(event).background}`,
                    ...(event.status === "cancelled"
                      ? {
                          backgroundImage:
                            "linear-gradient(120deg, rgba(238, 97, 125, 0.50) 10%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 50%, rgba(238, 97, 125, .5) 50%, rgba(238, 97, 125, .5) 60%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%)",
                          backgroundSize: "5.77px 10.00px"
                        }
                      : {})
                  }}
                  onClick={(e) => {
                    onEventClick(event, e.currentTarget);
                  }}
                  data-testmation="calendarBooking"
                >
                  {event.followUp && (
                    <div
                      style={{ backgroundColor: "#ffcc00" }}
                      className={styles.followupIndicator}
                    />
                  )}
                  {event.remarks && (
                    <div className={styles.remarksIndicator}>
                      <HtmlTooltip description={event.remarks} />
                    </div>
                  )}
                  {event.paymentInfo?.isPaid && (
                    <div>
                      <CheckIcon
                        className={styles.isPaidIndicator}
                        style={{ fill: "white", height: "12px", width: "12px" }}
                      />
                    </div>
                  )}
                  {event.bookableResourceId && (
                    <Box
                      height="100%"
                      position="absolute"
                      width="4px"
                      bgcolor="#C71585"
                      marginTop="-4px"
                      marginLeft={event.serviceProviderId ? "0px" : "-4px"}
                      borderRadius="undefinedpx"
                    />
                  )}
                  {event.serviceProviderId && (
                    <Box
                      height="100%"
                      position="absolute"
                      width="4px"
                      bgcolor="primary.main"
                      marginTop="-4px"
                      marginLeft="-4px"
                      borderRadius="2px 0 0 2pxpx"
                    />
                  )}
                  <EventContent event={event} boxHeight={getEventHeight(event.start, event.end)} />
                </div>
              );
            })
          }
          {newEventProps.startProps &&
            newEventProps.currentProps &&
            newEventProps.startProps.column === idx && (
              <div
                ref={newEventPlaceholderRef}
                style={{
                  boxShadow: "0 0 5px 0px #0000008c",
                  background: "#79d2f1",
                  pointerEvents: "none",
                  position: "absolute",
                  width: "100%",
                  borderRadius: "5px",
                  padding: "2px",
                  boxSizing: "border-box",
                  height: getHeight(
                    newEventProps.startProps,
                    newEventProps.currentProps,
                    newEventProps.currentSchedule?.duration
                  ),
                  top: getTop(newEventProps.startProps, newEventProps.currentProps)
                }}
              >
                <EventContent
                  event={getEventFromProps(
                    day,
                    newEventProps.startProps,
                    newEventProps.currentProps,
                    newEventProps.currentSchedule?.duration
                  )}
                  detailed
                />
              </div>
            )}
          {moment(day).isSame(new Date(), "day") && <TimeIndicator />}
          <Box
            borderRight="1px solid lightgrey"
            height="100%"
            onMouseDown={(e) => {
              if (e.button === BUTTON.LEFT) {
                mouseDown.current = true;
                const schedule = getScheduleUnderneath(e, columnRefs[idx].current, day, events);
                setNewEventProps({
                  ...newEventProps,
                  startProps: getProps(e, columnRefs[idx].current, idx, schedule?.duration),
                  currentProps: getProps(e, columnRefs[idx].current, idx, schedule?.duration),
                  currentSchedule: schedule
                });
              }
              if (e.button === BUTTON.RIGHT) {
                const schedule = getScheduleUnderneath(e, columnRefs[idx].current, day, events);
                if (schedule) {
                  const boundingEls = document.querySelectorAll(`#schedule-${schedule.id}`);
                  boundingEls.forEach((el: unknown, i) => {
                    onScheduleSelect(el);
                    const element = el;
                    element.style.border = "4px solid lightblue";
                    if (boundingEls.length !== 1) {
                      if (i === 0) {
                        element.style.borderRight = "none";
                      } else if (i === boundingEls.length - 1) {
                        element.style.borderLeft = "none";
                      } else {
                        element.style.borderLeft = "none";
                        element.style.borderRight = "none";
                      }
                    }
                    if (
                      el.className.includes(`${new Date(day as unknown as string).getDay()}xthDay`)
                    ) {
                      element.style.border = "4px solid #16BADA";
                    }
                  });

                  const getPlaceholderBoundingElIdx = () => {
                    if (boundingEls.length < 3) {
                      return 0;
                    }
                    return Math.floor(Math.cbrt(Array.from(schedule.exceptions).length || 0));
                  };

                  onEventClick(schedule, boundingEls[getPlaceholderBoundingElIdx()]);
                }
              }
            }}
            onMouseMove={(e) => {
              if (mouseDown.current) {
                let { startProps } = newEventProps;
                if (newEventProps.startProps.column !== idx) {
                  startProps = getProps(
                    e,
                    columnRefs[idx].current,
                    idx,
                    newEventProps.currentSchedule?.duration
                  );
                }
                setNewEventProps({
                  ...newEventProps,
                  startProps,
                  currentProps: getProps(
                    e,
                    columnRefs[idx].current,
                    idx,
                    newEventProps.currentSchedule?.duration
                  )
                });
              }
            }}
            onMouseUp={() => {
              if (mouseDown.current) {
                onNewEvent(
                  getEventFromProps(
                    day,
                    newEventProps.startProps,
                    newEventProps.currentProps,
                    newEventProps.currentSchedule?.duration
                  ),
                  newEventPlaceholderRef.current
                );
                setNewEventProps({
                  ...newEventProps,
                  startProps: null,
                  currentProps: null,
                  currentSchedule: null
                });
                mouseDown.current = false;
              }
            }}
          />
          {Boolean(placeholderEvent) && placeholderEvent.column === idx && (
            <div
              style={{
                boxShadow: "0 0 5px 0px #0000008c",
                background: "#79d2f1",
                pointerEvents: "none",
                position: "absolute",
                width: "95%",
                borderRadius: "5px",
                padding: "4px",
                boxSizing: "border-box",
                height: getEventHeight(placeholderEvent.start, placeholderEvent.end),
                top: getEventTop(placeholderEvent.start)
              }}
            >
              <EventContent
                detailed
                event={placeholderEvent}
                boxHeight={getEventHeight(placeholderEvent.start, placeholderEvent.end)}
              />
            </div>
          )}
        </div>
      ))}
    </Box>
  );
};

export default Columns;

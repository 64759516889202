import { round } from "mathjs";
import * as React from "react";
import MenuedTextField from "../../../../components/MenuedTextField";
import { Currency, StockItemInterface } from "../../../../interfaces/StockInterfaces";
import { convertToINR, convertToRs, currencyStartAdornment } from "../../StockUtils";

interface Props {
  item: StockItemInterface;
  updateRow: (v) => void;
}

export default function StockSalesPriceExclVat(props: Props): JSX.Element {
  const { item, updateRow } = props;

  const onChangeCurrency = ({ target }) => {
    if (target.value === Currency.Inr) {
      updateRow({
        ...item,
        currency: target.value,
        packagePriceExcVAT: convertToINR(item.packagePriceExcVAT),
        unitPriceExcVAT: convertToINR(item.unitPriceExcVAT)
      });
    } else {
      updateRow({
        ...item,
        currency: target.value,
        packagePriceExcVAT: convertToRs(item.packagePriceExcVAT),
        unitPriceExcVAT: convertToRs(item.unitPriceExcVAT)
      });
    }
  };

  return (
    <div>
      <MenuedTextField
        fullWidth
        menuItems={[item.unit, item.package]}
        style={{
          height: "35px"
        }}
        InputLabelProps={{ shrink: true }}
        label="Sales price Excl VAT"
        fieldValue={
          item.salesPriceCalculationOn === item.unit
            ? round(item.unitPriceExcVAT || 0, 2)
            : round(item.packagePriceExcVAT || 0, 2)
        }
        onFocus={(e) => e.target.select()}
        onTextChange={(value) => {
          if (item.salesPriceCalculationOn === item.unit) {
            updateRow({
              ...item,
              unitPriceExcVAT: Number(value),
              packagePriceExcVAT: Number(value) * item.unitsPerPackage
            });
          } else {
            updateRow({
              ...item,
              packagePriceExcVAT: Number(value),
              unitPriceExcVAT: Number(value) / item.unitsPerPackage
            });
          }
        }}
        onSelectedMenuChange={(value) => {
          if (value === item.unit) {
            updateRow({ ...item, salesPriceCalculationOn: value });
          } else {
            updateRow({
              ...item,
              salesPriceCalculationOn: value
            });
          }
        }}
        selectedMenuItem={item.salesPriceCalculationOn}
        startAdornment={currencyStartAdornment(item.currency, onChangeCurrency)}
        type="number"
      />
    </div>
  );
}

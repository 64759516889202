import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

const TooltipContent = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "black",
    maxWidth: 300,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13.5),
    border: "1px solid #dadde9"
  }
}));

interface HtmlTooltipProps {
  title?: string;
  description: string;
  alert?: boolean;
}

const HtmlTooltip: React.FC<HtmlTooltipProps> = ({ title, description, alert }): JSX.Element => (
  <TooltipContent
    title={
      <>
        <Typography>{title}</Typography>
        {description}
      </>
    }
    enterTouchDelay={0}
    leaveTouchDelay={4000}
    placement="right-start"
    arrow
  >
    <InfoIcon sx={{ color: `${alert ? "red" : "#676766"}` }} />
  </TooltipContent>
);

HtmlTooltip.defaultProps = {
  title: "",
  alert: false
};

export default HtmlTooltip;

import moment from "moment";
import { useEffect, useRef } from "react";

// Reusable component that also takes dependencies
export default (
  cb: ({ date: Date }) => void,
  deps: Array<string | number | (() => void)>
): void => {
  const frame = useRef<number>();
  const last = useRef(moment().valueOf());
  const animate = () => {
    const now = moment().valueOf();
    const date = now;
    cb({ date });
    last.current = now;
    frame.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    frame.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frame.current);
  }, deps);
};

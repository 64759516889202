import { actionCreatorAsync } from './actionHelpers';
import * as api from '../api/referrers';
import * as NotificationActions from './notification';

export enum Type {
  GET_REFERRERS = 'GET_REFERRERS',
  POST_REFERRER = 'POST_REFERRER',
  PATCH_REFERRER = 'PATCH_REFERRER',
  DELETE_REFERRER = 'DELETE_REFERRER'
}

export const getReferrers = () => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_REFERRERS, async () => {
        return api.getReferrers();
      })
    );
  };
};

export const postReferrer = (data, next?: (response) => void) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.POST_REFERRER, async () => {
        try {
          const response = await api.postReferrer(data);
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: 'Sucessfully Created!',
              autoTimeout: true
            })
          );
          next && next(response);
          return response;
        } catch (e) {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'error',
              message: 'Couldnt create referrer!',
              autoTimeout: true
            })
          );
        }
      })
    );
  };
};

export const patchReferrer = (data, id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.PATCH_REFERRER, async () => {
        try {
          const response = await api.patchReferrer(data, id);
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: 'Sucessfully updated!',
              autoTimeout: true
            })
          );
          return response;
        } catch (e) {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'error',
              message: 'Couldnt update referrer!',
              autoTimeout: true
            })
          );
        }
      })
    );
  };
};

export const deleteReferrer = (id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.DELETE_REFERRER, async () => {
        try {
          const response = await api.deleteReferrer(id);
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: 'Sucessfully deleted!',
              autoTimeout: true
            })
          );
          return id;
        } catch (e) {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'error',
              message: 'Couldnt delete referrer',
              autoTimeout: true
            })
          );
        }
      })
    );
  };
};

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { slotFromTimeSelector, slotUntilTimeSelector } from '../../reducers/slots';
import SingleBookedSlot from './SingleBookedSlotPill';
import WatchIcon from '@mui/icons-material/Schedule';
import Popper from '../../components/Popper/Popper';
import BookingCreateEdit from './BookingCreateEdit';
import { filterCancelledBookings } from '../../reducers/slots';
import { ResponsivePopperOrModal } from './SingleBookedSlotPill';
import classNames from '../../helpers/classNames';
import styles from './styles.module.css';

interface propsType {
  style: any;
  event: any;
  onSlotClicked: (event: any) => void;
}

export const isEventOnCalendarDropdown = ({ target }) => {
  // This is a special fn used by booking popover to prevent booking popover closing unintentionally,
  // when only calendar dropdown on folowup section should close
  const calendarDD = document.getElementsByClassName('calendarDropdown');
  return calendarDD && calendarDD[0] && calendarDD[0].contains(target);
};

export const EmptySlot = ({ event, style }) => {
  const pillRef = React.useRef(null);
  const [bookingFormOpen, toggleOpen] = React.useState(false);
  return (
    <>
      <div
        ref={pillRef}
        style={style}
        onClick={() => toggleOpen(true)}
        className={classNames(styles.event, styles.slotPill, {
          [styles.highlightedBookedPill]: bookingFormOpen,
        })}
      >
        <Typography>
          <Box
            className={styles.alignVerticallyMiddle}
            component={'span'}
            fontSize={'0.800rem'}
            fontWeight={400}
          >
            <WatchIcon className={styles.icon} />
            {slotFromTimeSelector(event)} - {slotUntilTimeSelector(event)}
          </Box>
        </Typography>
      </div>
      {bookingFormOpen && (
        <ResponsivePopperOrModal
          open={bookingFormOpen}
          onClose={(e) => {
            if (!isEventOnCalendarDropdown(e)) {
              toggleOpen(false);
            }
          }}
          anchorEl={() => pillRef}
        >
          <BookingCreateEdit
            onClose={() => toggleOpen(false)}
            serviceProviderId={event.serviceProviderId}
            slotId={event.id}
          />
        </ResponsivePopperOrModal>
      )}
    </>
  );
};
export default class EventPill extends React.Component<propsType> {
  state = {
    anchorEl: null,
  };
  render() {
    const { style, event } = this.props;
    const bookings = filterCancelledBookings(event.bookings);
    return (
      <>
        {!(bookings && bookings.length) && <EmptySlot event={event} style={style} />}
        {bookings && bookings.length === 1 && (
          <SingleBookedSlot event={{ ...event, bookings }} style={style} />
        )}
      </>
    );
  }
}

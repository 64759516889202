import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import List, { EmptyView } from "../../components/OList";
import * as reportsApi from "../../api/reports";
import { tl, t } from "../../components/translate";
import useMobileScreen from "../../hooks/useMobileScreen";
import "./Reports.scss";
import Filters, { FilterProps } from "./Filters";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import * as NotificationActions from "../../actions/notification";

const CustomerDueReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState<FilterProps>({
    clientIds: []
  } as FilterProps);

  const [reportData, setReportData] = React.useState([]);

  const filterHandler = async (newFilter: FilterProps) => {
    setFilters(newFilter);
    try {
      const response = await reportsApi.getCustomerDueReport(newFilter);
      setReportData(response);
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry ! Something Went Wrong.",
          autoTimeout: true
        })
      );
    }
  };

  const isMobileScreen = useMobileScreen();

  return (
    <Box marginTop="32px">
      <Box margin="0px 32px">
        <Box marginTop="32px">
          <Filters
            showPeriod={false}
            filters={filters as FilterProps}
            onSetFilters={filterHandler}
          />
        </Box>
      </Box>
      <Box
        width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
        style={{ overflowX: isMobileScreen ? "scroll" : "auto" }}
        mx="auto"
      >
        <Box
          marginTop="32px"
          width={isMobileScreen ? "960px" : "auto"}
          height="calc(100vh - 300px)"
        >
          <List
            automation="salesReportList"
            data={reportData}
            rowHeight={50}
            defaultSortColumn="billingDate"
            defaultSortOrder={-1}
            activeRow={0}
            adjustHeightToContents
            columns={[
              {
                key: "clientName",
                label: tl("reports.client"),
                sortable: true,
                formatter: ({ client }) => (
                  <Box
                    style={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {`${client.firstName} ${client.lastName}
                ${client.active ? "" : "(deleted)"}`}
                  </Box>
                )
              },
              {
                key: "transactionDate",
                label: "transactionDate",
                sortable: true,
                formatter: ({ transactionDate }) => (
                  <Typography>{convertADtoBS(new Date(transactionDate)).formatted4}</Typography>
                )
              },
              {
                key: "balance",
                label: "Due",
                sortable: true,
                formatter: ({ balance }) => <Typography>{Math.abs(balance)}</Typography>
              }
            ]}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
          </List>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="230px" justifyContent="flex-end" paddingRight="42px">
              <Typography width="200px">
                Rs: {Math.abs(reportData.reduce((acc, cur) => acc + cur.balance, 0)).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerDueReport;

import { Get, Post, Patch } from "./apiHelper";
import { ConsentData } from "../containers/Client/Consent/ConsentCreateEditForm";

const consentFormRoot = "/api/consentForm";

export async function getConsentForms(clientId: number): Promise<ConsentData[]> {
  const response = await Get(`${consentFormRoot}?clientId=${clientId}`, true);
  return response.data as ConsentData[];
}

export async function postConsentForm(data: Partial<ConsentData>): Promise<ConsentData> {
  const response = await Post(consentFormRoot, data, true);
  return response.data as ConsentData;
}

export async function patchConsentForm({
  data,
  id
}: {
  data: Partial<ConsentData>;
  id: number;
}): Promise<ConsentData> {
  const response = await Patch(`${consentFormRoot}/${id}`, data, true);
  return response.data as ConsentData;
}

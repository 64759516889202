import { Box, Typography } from "@mui/material";
import { push } from "connected-react-router";
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { startCase } from "lodash";
import { notificationAdd } from "../../actions/notification";
import { getLabTestRecord } from "../../api/labTest";
import * as reportsApi from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { bsFullDate } from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadCSV } from "../../helpers/files";
import { commonErrorMessage, errorFetchMessage, MODULE } from "../../helpers/messages";
import useMobileScreen from "../../hooks/useMobileScreen";
import { LabReport as LabReportInterface } from "../../interfaces/Lab";
import { LabObject } from "../../interfaces/LabInterfaces";
import { IThunkDispatch, RootState } from "../../store";
import LabDetails from "../Lab/LabDetails";
import LabStatusChip from "../Lab/LabStatusChip";
import { getTurnAroundTime } from "../Lab/ListTab";
import Can from "../Policy/Can";
import Filters from "./Filters";
import styles from "./Reports.module.css";
import "./Reports.scss";
import { ageFormatter } from "../../helpers/formatters";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  getDefaultKeyValuesColumns,
  labReportDefaultCols,
  labReportHiddenCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import { getGenderCode } from "../Billing/PrintBill/EightyMmPrint/ClientInfo";
import ReportPrint from "./Print/ReportPrint";
import PageControl from "../../components/PageControl";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

enum LabKeys {
  ID = "id",
  BILLING_DATE_BS = "billingDateBS",
  BILLING_DATE_AD = "billingDateAD",
  CLIENT_NAME = "clientName",
  PHONE = "phone",
  AGE = "age",
  GENDER = "gender",
  ADDRESS = "address",
  TESTS = "tests",
  STATUS = "status",
  TURN_AROUND_TIME = "turnAroundTime",
  TEST_RESULTS = "testResults",
  REFERRERS = "referrers",
  TEST_GROUP = "testGroup",
  CATEGORY = "category",
  TYPE = "type",
  DOB = "dob",
  CLIENT_AGE = "clientaGE",
  CLIENT_GENDER = "clientGender",
  CREATED_AT = "created_at",
  COLLECTION_DATE = "collectionDate"
}

// Excel Headers
const docColumns = () => [
  t("lab.testId"),
  t("reports.billingDateBS"),
  t("reports.billingDateAD"),
  t("reports.clientName"),
  t("clients.phone"),
  t("clients.age"),
  t("clients.gender"),
  t("clients.address"),
  t("lab.tests"),
  t("reports.status"),
  t("lab.turnAroundTime"),
  t("lab.testResults"),
  t("lab.referrers"),
  "Test Group",
  "Category",
  "Type"
];

const getClientGender = (g) => {
  if (g === "1") return "Male";
  if (g === "2") return "Female";
  if (g === undefined) return "Unknown";
  return "other";
};

// Excel values
const docRowProcessor = ({
  id,
  // eslint-disable-next-line camelcase
  created_at,
  clientFirstName,
  clientLastName,
  clientPhone,
  clientDob,
  clientGender,
  clientAddress,
  status,
  testName,
  testResult,
  referrers,
  testGroup,
  category,
  type,
  // eslint-disable-next-line camelcase
  turnAroundTime,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    id,
    calFns.bsShortDate(created_at),
    calFns.adShortDate(created_at),
    `${clientFirstName} ${clientLastName}`,
    clientPhone,
    clientDob ? ageFormatter(clientDob) : "-",
    getClientGender(clientGender),
    clientAddress,
    testName,
    status,
    turnAroundTime,
    testResult || "-",
    referrers,
    testGroup,
    category,
    type
  ];
};

// For Print Values
const formatter = (
  {
    id,
    // eslint-disable-next-line camelcase
    created_at,
    clientFirstName,
    clientLastName,
    clientDob,
    clientGender,
    status,
    testName,
    referrers,
    testGroup,
    category,
    type,
    collectionDate,
    turnAroundTime
  },
  filteredHeaders
) =>
  [
    { key: LabKeys.ID, value: id },
    { key: LabKeys.CLIENT_NAME, value: `${clientFirstName} ${clientLastName}` },
    { key: LabKeys.CLIENT_AGE, value: `${clientDob ? ageFormatter(new Date(clientDob)) : ""}` },
    { key: LabKeys.CLIENT_GENDER, value: `${getGenderCode(clientGender)}` },
    { key: LabKeys.TESTS, value: testName },
    { key: LabKeys.CREATED_AT, value: calFns.bsFullDate(created_at) },
    { key: LabKeys.TYPE, value: `${type}` },
    { key: LabKeys.CATEGORY, value: `${category}` },
    { key: LabKeys.COLLECTION_DATE, value: `${bsFullDate(collectionDate)}` },
    { key: LabKeys.STATUS, value: status },
    { key: LabKeys.TEST_GROUP, value: testGroup },
    { key: LabKeys.TURN_AROUND_TIME, value: turnAroundTime },
    { key: LabKeys.REFERRERS, value: referrers }
  ]
    .filter((row) => filteredHeaders.includes(row.key))
    .map((row) => row.value);

const getPrintData = (headers, data) => [
  headers.map((h) => startCase(h)),
  ...data
    // eslint-disable-next-line no-underscore-dangle
    .filter((v) => v.__meta__row_type !== "segment_summary")
    .map((item) => formatter(item, headers))
];

interface LabReport extends LabReportInterface {
  referrers: string;
  turnAroundTime: string;
}
const LabReport = () => {
  const search = new URLSearchParams(window.location.search);
  const clientId = search.get("clientId");
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    referrersName: [],
    labStatus: [],
    labTestsIds: [],
    labTestGroupIds: [],
    clientIds: [...(clientId ? [+clientId] : [])],
    labRecordType: null,
    showLabTestInSingleRow: false
  });

  const dispatch = useDispatch();

  const [reportData, setReportData] = React.useState<Array<LabReport>>([]);
  const [selectedItem, setSelectedItem] = React.useState<LabObject | null>(null);
  const [page, setPage] = React.useState<number>(0);
  const pageSize = 20;
  const [hasMoreResult, setHasMoreResult] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const data = await reportsApi.labReport({
          ...filters,
          page,
          pageSize
        });

        setReportData(
          data.map((datum) => ({
            ...datum,
            referrers: datum.referrers || "",
            turnAroundTime: getTurnAroundTime(datum)
          }))
        );
        setHasMoreResult(data?.length <= 0);
      } catch (e) {
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: errorFetchMessage(MODULE.REPORT),
          autoTimeout: true
        });
      }
    })();
  }, [filters, page]);

  const clickHandler = async (id) => {
    try {
      const res = await getLabTestRecord(id);
      setSelectedItem(res[0]);
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: commonErrorMessage,
          autoTimeout: true,
          variant: "error"
        })
      );
    }
  };

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(labReportDefaultCols, labReportHiddenCols)
  );
  const [open, setOpen] = React.useState(false);

  const isMobileScreen = useMobileScreen();

  const filteredHeaders = [...labReportDefaultCols, ...labReportHiddenCols].filter(
    (h) => listColumns[h]
  );
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:labReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1000px" : "auto"}>
          <Box margin="0px 32px">
            <Filters filters={filters} onSetFilters={(f) => setFilters(f)} hideFiscalYear />
          </Box>
          <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}>
            <Box
              className={["labReportList", styles.labReportListStyles].join(" ")}
              marginTop="32px"
              width={isMobileScreen ? "960px" : "auto"}
              height="100%"
            >
              <List
                automation="labReportList"
                data={reportData}
                rowHeight={50}
                defaultSortColumn="testName"
                defaultSortOrder={-1}
                adjustHeightToContents
                columns={[
                  {
                    key: "id",
                    label: tl("labReport.TestId"),
                    sortable: true,
                    formatter: (row) => (
                      <Typography style={{ fontSize: "0.75rem" }}>{row.id}</Typography>
                    ),
                    segmentable: true
                  },
                  {
                    key: "clientName",
                    label: tl("labReport.ClientName"),
                    sortable: true,
                    sortFunction: (a, b) => (a.clientFirstName > b.clientFirstName ? 1 : -1),
                    formatter: ({ clientFirstName, clientLastName, clientPhone }) => (
                      <Box>
                        <Typography style={{ fontSize: "0.9rem" }}>
                          {`${clientFirstName} ${clientLastName}`.toUpperCase()}
                        </Typography>
                        <Typography fontSize="0.7.5rem" color="grey">
                          {clientPhone}
                        </Typography>
                      </Box>
                    )
                  },
                  {
                    key: "clientAge",
                    label: tl("labReport.ClientAge"),
                    formatter: ({ clientDob }) => (
                      <Typography>{clientDob ? ageFormatter(new Date(clientDob)) : "-"}</Typography>
                    )
                  },
                  {
                    key: "clientGender",
                    label: tl("labReport.Gender"),
                    formatter: ({ clientGender }) => (
                      <Typography>{getGenderCode(clientGender)}</Typography>
                    )
                  },
                  {
                    key: "tests",
                    label: tl("labReport.tests"),
                    cellRenderer: ({ testName }) => <Typography>{testName}</Typography>
                  },
                  {
                    key: "created_at",
                    label: tl("labReport.orderDate"),
                    sortable: true,
                    sortFunction: (a, b) => (a.created_at > b.created_at ? 1 : -1),
                    // eslint-disable-next-line camelcase
                    formatter: ({ created_at }) => calFns.bsFullDate(created_at)
                  },
                  {
                    key: "type",
                    label: tl("labReport.type"),
                    formatter: ({ type }) => <Typography>{type || "-"}</Typography>
                  },
                  {
                    key: "category",
                    label: tl("labReport.category"),
                    sortable: true,
                    formatter: ({ category }) => <Typography>{category || "-"}</Typography>
                  },
                  {
                    key: "collectionDate",
                    label: tl("lab.collected"),
                    hideInNarrowView: true,
                    cellRenderer: ({ collectionDate }) => (
                      <Box display="flex">
                        {collectionDate && <Typography>{bsFullDate(collectionDate)}</Typography>}
                      </Box>
                    )
                  },
                  {
                    key: "testGroup",
                    label: tl("labReport.testGroup"),
                    sortable: true,
                    formatter: ({ testGroup }) => <Typography>{testGroup || "-"}</Typography>
                  },
                  {
                    key: "status",
                    label: tl("reports.status"),
                    sortable: true,
                    formatter: ({ status }) => (
                      <Box display="flex" alignItems="center">
                        <LabStatusChip status={status.toLowerCase()} />
                      </Box>
                    )
                  },
                  {
                    key: "turnAroundTime",
                    label: tl("lab.turnAroundTime"),
                    sortable: false,
                    formatter: ({ turnAroundTime }) => <Typography>{turnAroundTime}</Typography>
                  },
                  {
                    key: "referrers",
                    label: tl("labReport.referrer"),
                    sortable: true,
                    formatter: ({ referrers }) => <Typography>{referrers || "-"}</Typography>,
                    segmentable: true,
                    segmentBy: (row) => row.referrers
                  }
                ].filter((row) => listColumns[row.key])}
                activeRow={selectedItem && selectedItem.id}
                onRowClick={(item) => {
                  clickHandler(item.id);
                }}
                segementSummaryRenderer={(acc) => (
                  <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                    <Box display="flex" flexGrow={1} justifyContent="space-between">
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight="500"
                        alignItems="center"
                      >
                        {acc.segment || "-"}
                      </Box>
                      <Box p={2} pr={4} fontWeight={600}>
                        {acc.rows.length}
                      </Box>
                    </Box>
                  </Box>
                )}
              >
                <EmptyView>
                  <Box textAlign="center" padding="50px">
                    No items to show.
                  </Box>
                </EmptyView>
                <ListActions>
                  {({ getProcessedData }) => (
                    <Menu>
                      <MenuItem
                        onClick={() =>
                          downloadCSV(
                            t("reports.labReport", "en"),
                            getProcessedData(),
                            docColumns(),
                            (row) => docRowProcessor(row)
                          )
                        }
                      >
                        {tl("reports.excel")}
                      </MenuItem>
                      <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                      <MenuItem onClick={() => ({})}>
                        <ReportPrint
                          printData={getPrintData(filteredHeaders, getProcessedData())}
                        />
                      </MenuItem>
                    </Menu>
                  )}
                </ListActions>
              </List>
              <Box className="navigatePage">
                <PageControl
                  page={page}
                  pageSize={pageSize}
                  onSetPage={(v) => {
                    setPage(v);
                  }}
                  disableNextButton={hasMoreResult}
                  ignoreMaxDataCount
                />
              </Box>
              <ListHeaderShowHideDialog
                onChange={(updatedColumns) => setListColumns(updatedColumns)}
                open={open}
                onClose={() => setOpen(false)}
                columns={listColumns}
              />
              {selectedItem && (
                <LabDetails
                  hideActionButton
                  editMode={false}
                  setEditMode={() => ({})}
                  labObj={selectedItem}
                  labId={selectedItem.id}
                  handleViewClose={() => setSelectedItem(null)}
                  navigateTo={(path) => dispatch(push(path))}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Can>
  );
};

export default connect(
  (state: RootState) => ({
    resourceCentre: state.userContext.resourceCentre
  }),
  (dispatch: IThunkDispatch) => ({
    navigateTo: (path) => {
      dispatch(push(path));
    }
  })
)(LabReport);

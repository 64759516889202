import React from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { push } from "connected-react-router";
import { Box } from "@mui/material";
import { BillType } from "../../../interfaces/BillInterfaces";
import { getDraftAssessmentBills } from "../../../api/bill";
import { getPrescribedLabTests } from "../../../api/labTest";
import { Filters } from "../../../components/List";
import AssessmentBills from "./AssessmentBills";
import AssessmentLabs from "./AssessmentLabs";
import { LabTestRecordResultData } from "../../../interfaces/Lab";
import { notificationAdd } from "../../../actions/notification";

export enum PrescribedLabTests {
  assessmentBills = "bills",
  prescribedTests = "prescirbedTests"
}
const filterData = {
  filters: [
    { key: PrescribedLabTests.assessmentBills, title: "To Be Billed" },
    { key: PrescribedLabTests.prescribedTests, title: "Lab Tests" }
  ]
};
const PrescribedLabs = (): JSX.Element => {
  const [assessmentBills, setAssessmentBills] = React.useState<Array<BillType>>([]);
  const [assessmentLabs, setAssessmentLabs] = React.useState<Array<LabTestRecordResultData>>([]);
  const [labPage, setLabPage] = React.useState(0);
  const [totalLabs, setTotalLabs] = React.useState(0);
  const [filter, setFilter] = React.useState(PrescribedLabTests.assessmentBills);
  const dispatch = useDispatch();
  const pageSize = 15;
  React.useEffect(() => {
    (async () => {
      try {
        if (filter === PrescribedLabTests.assessmentBills) {
          const bills = await getDraftAssessmentBills();
          setAssessmentBills(bills);
        } else {
          const labs = await getPrescribedLabTests({ page: labPage, pageSize });
          setAssessmentLabs(labs.results);
          setTotalLabs(labs.total);
        }
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: err.message,
            autoTimeout: true
          })
        );
      }
    })();
  }, [filter, labPage]);
  const { tab } = queryString.parse(window.location.search);

  React.useEffect(() => {
    if (tab) {
      setFilter(tab);
    } else {
      setFilter(PrescribedLabTests.assessmentBills);
    }
  }, [tab]);
  return (
    <Box height="100%">
      <Box pl={2} pt={2}>
        <Filters
          filter={filter}
          onFilter={(key) => {
            dispatch(push(`/assessment/labTest?tab=${key}`));
          }}
          filterData={filterData}
        />
      </Box>
      {filter === PrescribedLabTests.assessmentBills ? (
        <AssessmentBills bills={assessmentBills} />
      ) : (
        <AssessmentLabs
          page={labPage}
          setPage={setLabPage}
          labs={assessmentLabs}
          updateLabs={setAssessmentLabs}
          totalLabs={totalLabs}
        />
      )}
    </Box>
  );
};

export default PrescribedLabs;

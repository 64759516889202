import { ProductInterface } from "./ProductInterface";
import { StockProducts } from "./StockInterfaces";

export enum ReferrerProductServiceEntities {
  product = "product",
  service = "service"
}

export interface ProductService {
  id: number;
  entityType: ReferrerProductServiceEntities;
  productId: number | null;
  serviceId: number | null;
  price: number;
  referrerId: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
  product: StockProducts | null;
  service: ProductInterface | null;
  openingBalance: number;
}

export interface Referrer {
  referrerType: "root" | null;
  id: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  email: string;
  address: string;
  phone: string;
  referrer: string;
  resourceCentreId: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
  info;
  productService: Array<ProductService>;
  ledgerId: number;
  tds: number;
  rate: number;
  openingBalance: number;
  openingBalanceDate: string | Date;
  transactionType: string;
}

export interface ReferrerInfo {
  productRelations: Array<{
    id: number;
    productDetails: Partial<ProductInterface>;
  }>;
}

export interface ReferrerLedger {
  amount: number;
  transactionType: string;
  transactionDate: string;
  voucherType: string;
  dueAmount: amount;
}

import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { scaleFont } from "../LabPrintFunctions";
import { RootState } from "../../../../store";
import { UserGroups } from "../../../ResourceCentre/UserSettings/SPCreateEdit";
import useGetShowSignatureOnLabPrint from "../../../../hooks/useGetShowSignatureOnLabPrint";

export interface AuthorizedSp {
  id: number;
  signature: string;
  designation: string;
  name: string;
  fullname?: string;
  qualification: string;
  registrationNumber: string;
  specialities: string;
  role: string;
  speciality: string;
  title: string;
  userGroup: string;
}

interface AuthorizedSpInfoProps {
  rootStyles?: React.CSSProperties;
  imageStyles?: React.CSSProperties;
  sp: AuthorizedSp;
}

const AuthorizedSpInfo: React.FC<AuthorizedSpInfoProps> = ({
  sp,
  rootStyles = {},
  imageStyles = {}
}) => {
  const serviceProviders = useSelector(
    (state: RootState) => state.resources?.resourceCentreServiceProviders
  );
  const spWithSignature = serviceProviders?.find((serviceProvider) => serviceProvider.id === sp.id);

  const { showSignatureOnLabPrint } = useGetShowSignatureOnLabPrint();
  return (
    <Box style={rootStyles}>
      <Box style={{ width: "6cm" }}>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop="0.2cm"
          minHeight="2cm"
        >
          {sp.signature && showSignatureOnLabPrint && (
            <img src={sp.signature} style={{ ...imageStyles, height: "1.8cm" }} alt="signature" />
          )}
        </Box>
        <Typography
          data-testmation="authorizedSp"
          style={{
            fontSize: scaleFont("0.33cm", 0.95),
            width: "6cm",
            textAlign: "left",
            borderTop: "1px solid lightgrey",
            fontWeight: 600,
            marginTop: "6px",
            textTransform: "capitalize",
            paddingTop: "0.18cm"
          }}
        >
          {sp.title || ""} {sp.name || sp.fullname}
        </Typography>
      </Box>
      {spWithSignature?.designation ? (
        <Typography sx={{ whiteSpace: "pre-wrap" }}>{spWithSignature.designation}</Typography>
      ) : (
        <div>
          <Box display="flex" flexDirection="column">
            <Typography>
              <Typography>{sp.qualification}</Typography>
              {sp.userGroup === UserGroups.ServiceProviderFull
                ? sp.specialities
                : sp.speciality || ""}
              {sp.registrationNumber && <Typography>{sp.registrationNumber}</Typography>}
            </Typography>
          </Box>
        </div>
      )}
    </Box>
  );
};

AuthorizedSpInfo.defaultProps = {
  rootStyles: {},
  imageStyles: {}
};

export default AuthorizedSpInfo;

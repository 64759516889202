import "../styles.scss";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination
} from "@mui/material";
import { push } from "connected-react-router";
import { MultipleHeader } from "../../../../components/List";
import { hmisMultipleHeaders } from "../../index";
import {
  EmptyView,
  getSerialNumber,
  LoadingView,
  TableCell,
  TableRow
} from "../../../../components/ListWithNestedHeader/Index";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import * as hmisActions from "../../../../actions/hmis";
import { t } from "../../../../components/translate";
import { HMIS_PAGE_SIZE } from "../../constants";
import { Imnci242 } from "../../interfaces";
import ListRow from "./ListRow";
import CreateEditImnciBelowTwoMonthsPanel from "./CreateEditImnciBelowTwoMonthsPanel";

const headerRows = [
  [
    { label: "SN, MRN, SRN", colSpan: 1, rowSpan: 2 },
    { label: "Date, Name, Caste, Ethnicity code, and Address", colSpan: 4, rowSpan: 2 },
    { label: "Sex, Age, Weight, Temperature, and Referral", colSpan: 2, rowSpan: 2 },
    {
      label: "Patients signs and symptoms(Circle all the codes that apply)",
      colSpan: 9,
      rowSpan: 1
    },
    { label: "Classification and Code", colSpan: 3, rowSpan: 1 },
    { label: "Treatment and counseling", colSpan: 6, rowSpan: 1 },
    { label: "Referred to", colSpan: 1, rowSpan: 2 },
    { label: "Follow up", colSpan: 3, rowSpan: 1 },
    { label: "Remarks", colSpan: 1, rowSpan: 2 }
  ],
  [
    { label: "1.PSBI/LBI/NBI/Pneumonia", colSpan: 2, rowSpan: 1 },
    { label: "2.Diarrhoea", colSpan: 2, rowSpan: 1 },
    { label: "3.Breast feeding problem/Low weight", colSpan: 2, rowSpan: 1 },
    { label: "4.Assess breast feeding", colSpan: 3, rowSpan: 1 },
    { label: "Major classification", colSpan: 1, rowSpan: 1 },
    { label: "Number", colSpan: 1, rowSpan: 1 },
    { label: "ICD Code", colSpan: 1, rowSpan: 1 },
    { label: "Medicine", colSpan: 2, rowSpan: 1 },
    { label: "Counselling to mother", colSpan: 2, rowSpan: 1 },
    { label: "Treatment Outcome", colSpan: 2, rowSpan: 1 },
    { label: "Date (DDMMYY)", colSpan: 1, rowSpan: 1 },
    { label: "Result", colSpan: 2, rowSpan: 1 }
  ]
];

interface Props {
  match: { params: { id: string; clientId: string } };
  mode?: string;
}

const ImnciRegisterBelowTwoMonthsList = ({ match, mode }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [pagination, setPagination] = useState({ page: 0, pageSize: HMIS_PAGE_SIZE });
  const [isLoading, setIsLoading] = useState(false);
  const data: { results: Imnci242[]; total: number } = useAppSelector(
    (state) => state.hmis.imnci242
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await dispatch(
        hmisActions.getImnci242({
          pageSize: pagination.pageSize,
          page: pagination.page
        })
      );
      setIsLoading(false);
    })();
  }, [pagination.page]);

  return (
    <div>
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <MultipleHeader multipleHeaders={hmisMultipleHeaders(match)} />
        <Button
          variant="contained"
          onClick={() => dispatch(push("/hmis/imnci/belowTwoMonths/new"))}
        >
          {t("hmis.createNewImnci241")}
        </Button>
      </Box>
      <Box
        sx={{
          px: 2,
          height: "calc(100vh - 160px)"
        }}
      >
        <TableContainer className="nestedHeaderList imnciList">
          <Table
            size="small"
            className="nestedHeaderTable"
            sx={{
              minWidth: 1000
            }}
          >
            <TableHead>
              {headerRows.map((row) => (
                <TableRow key={Math.random()}>
                  {row.map((cell) => (
                    <TableCell
                      isHeaderCell
                      rowSpan={cell.rowSpan}
                      colSpan={cell.colSpan}
                      key={cell.label}
                    >
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {isLoading ? (
                <LoadingView />
              ) : (
                <>
                  {data.results.map((row, rowIndex) => {
                    const serialNumber = getSerialNumber({
                      page: pagination.page,
                      pageSize: pagination.pageSize,
                      currentIndex: rowIndex
                    });

                    return (
                      <React.Fragment key={row.id}>
                        <ListRow row={row} serialNumber={serialNumber} />
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
          {!isLoading && data.results.length === 0 && <EmptyView content="No items to dispaly." />}
        </TableContainer>

        <TablePagination
          component="div"
          nextIconButtonProps={{
            disabled:
              pagination.page >= Math.ceil(data.total / pagination.pageSize) - 1 || isLoading
          }}
          backIconButtonProps={{
            disabled: pagination.page === 0 || isLoading
          }}
          rowsPerPageOptions={[]}
          count={data.total}
          rowsPerPage={pagination.pageSize}
          page={pagination.page}
          onPageChange={(_, page) => {
            setIsLoading(true);
            setPagination({
              page,
              pageSize: pagination.pageSize
            });
          }}
        />
      </Box>
      {mode === "create" && (
        <CreateEditImnciBelowTwoMonthsPanel
          onClose={() => dispatch(push("/hmis/imnci/belowTwoMonths"))}
        />
      )}
    </div>
  );
};

export default ImnciRegisterBelowTwoMonthsList;

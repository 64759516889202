import React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { omit } from 'lodash';
import { Label } from './GenericTextField';
import * as feedActions from '../../../actions/feed';
import GenericTextField from './GenericTextField';

const singleEntityKey = {
  [feedActions.NativeViewActions.OPEN_RC]: 'rcId',
  [feedActions.NativeViewActions.OPEN_SP]: 'spId'
};

const entityLabel = {
  [feedActions.NativeViewActions.OPEN_RC]: 'Resource centre id',
  [feedActions.NativeViewActions.OPEN_SP]: 'Service provider id'
};

const NATIVE_VIEW_OPTIONS = [
  { label: 'Open resource center', value: feedActions.NativeViewActions.OPEN_RC },
  { label: 'Open service provider', value: feedActions.NativeViewActions.OPEN_SP },
  { label: 'Open appointment page', value: feedActions.NativeViewActions.OPEN_APPOINTMENTS }
];

const OPEN_APPOINTMENTS_OPTIONS = [
  { label: 'Resource center', value: 'resourceCentre' },
  { label: 'Service provider', value: 'serviceProvider' },
  { label: 'Service tag', value: 'serviceTag' }
];

function NativeViewFields({ draft, handleOnChange }) {
  const [currentEntityKey, setCurrentEntityKey] = React.useState<any>('');

  React.useEffect(() => {
    if (!draft.actionProps.nativeViewAction) {
      handleOnChange({
        actionProps: { ...draft.actionProps, nativeViewAction: NATIVE_VIEW_OPTIONS[2].value }
      });
    }
    setCurrentEntityKey(getEntityKey());
  }, [draft.actionProps.nativeViewAction]);

  const isOpenAppointmentMode =
    draft.actionProps.nativeViewAction === feedActions.NativeViewActions.OPEN_APPOINTMENTS;

  function getEntityKey() {
    switch (draft.actionProps.nativeViewAction) {
      case feedActions.NativeViewActions.OPEN_APPOINTMENTS:
        const currKey = OPEN_APPOINTMENTS_OPTIONS.map((option) => option.value).find((optionKey) =>
          Object.keys(draft.actionProps).includes(optionKey)
        );
        return currKey || OPEN_APPOINTMENTS_OPTIONS[2].value;

      case feedActions.NativeViewActions.OPEN_RC:
        return singleEntityKey[feedActions.NativeViewActions.OPEN_RC];

      case feedActions.NativeViewActions.OPEN_SP:
        return singleEntityKey[feedActions.NativeViewActions.OPEN_SP];

      default:
        return '';
    }
  }

  return <>
    <Box
      display={'grid'}
      columnGap={48}
      gridTemplateColumns="repeat(auto-fit,minmax(15rem, 1fr))"
      my={1}
    >
      <Box component="div" display="flex" alignItems="center" maxWidth="240px">
        <Label>Action type</Label>
        <Select
          autoWidth
          fullWidth
          variant="outlined"
          margin="dense"
          value={draft.actionProps.nativeViewAction || ''}
          onChange={(e) => {
            handleOnChange({
              actionProps: omit(
                {
                  ...draft.actionProps,
                  nativeViewAction: e.target.value
                },
                Object.values(singleEntityKey)
              )
            });
          }}
        >
          {NATIVE_VIEW_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {!isOpenAppointmentMode ? (
        <Box component="div" display={'flex'} alignItems="center">
          <Label>
            <span>{entityLabel[draft.actionProps.nativeViewAction]}</span>
          </Label>
          <GenericTextField
            dataTestmation="Resource id"
            value={draft.actionProps?.[currentEntityKey] || ''}
            placeholderText={entityLabel[draft.actionProps.nativeViewAction]}
            maxLength={24}
            onChange={(e) => {
              handleOnChange({
                actionProps: omit(
                  {
                    ...draft.actionProps,
                    [currentEntityKey]: e.target.value
                  },
                  OPEN_APPOINTMENTS_OPTIONS.map((option) => option.value)
                )
              });
            }}
          />
        </Box>
      ) : (
        <Box component="div" display={'flex'} alignItems="center">
          <Select
            style={{ marginRight: 8, minWidth: 120 }}
            autoWidth
            variant="outlined"
            margin="dense"
            value={currentEntityKey}
            onChange={(e) => {
              setCurrentEntityKey(e.target.value);
            }}
          >
            {OPEN_APPOINTMENTS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </Box>
    {isOpenAppointmentMode && (
      <Box component="div" display={'flex'} alignItems="center">
        <Label>
          <></>
        </Label>
        <Box
          width="100%"
          component="div"
          display={'grid'}
          columnGap={8}
          gridTemplateColumns="repeat(auto-fill,minmax(320px, 1fr))"
        >
          {['id', 'name'].map((field) => {
            let currentlabel = OPEN_APPOINTMENTS_OPTIONS.find(
              (option) => option.value === currentEntityKey
            );
            currentlabel = `${currentlabel?.label} ${field}`;
            return (
              <GenericTextField
                key={field}
                dataTestmation="Resource id"
                value={draft.actionProps?.[currentEntityKey]?.[field]}
                placeholderText={currentlabel}
                maxLength={120}
                onChange={(e) => {
                  handleOnChange({
                    actionProps: omit(
                      {
                        ...draft.actionProps,
                        [currentEntityKey]: {
                          ...draft.actionProps?.[currentEntityKey],
                          [field]: e.target.value
                        }
                      },
                      [
                        ...Object.values(singleEntityKey),
                        ...OPEN_APPOINTMENTS_OPTIONS.map((option) => option.value).filter(
                          (opt) => opt !== currentEntityKey
                        )
                      ]
                    )
                  });
                }}
              />
            );
          })}
        </Box>
      </Box>
    )}
  </>;
}

export default NativeViewFields;

import * as React from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import { match as matchProps } from "react-router";
import StockList from "./StockList";
import StockTransactionsList from "./StockTransactions/StockTransactionsList";
import styles from "./Stock.module.css";
import { supplierActions as actions } from "../../actions";
import { suppliersSortedSelector } from "../../reducers/suppliers";
import { getRCFromState } from "../../actions/resourceCentre";
import { IThunkDispatch, RootState } from "../../store";
import PurchaseRequisitionForm from "../Reports/PurchaseRequisitionForm";
import CentralStore from "./CentralStore";

interface MatchInterface extends matchProps {
  params: { id: number };
}

interface StockInterface {
  match: matchProps;
  id?: number;
}

const Stock: React.FC<StockInterface> = ({ match, id }) => (
  <Box height="calc(100% - 40px)">
    <Box className={styles.stockRoot} height="100%">
      <Box height="100%">
        {match.path.includes("stockTransactions") && (
          <StockTransactionsList match={match as MatchInterface} />
        )}
        {match.path.includes("stockProducts") && (
          <StockList match={match} id={id} isInternal={false} />
        )}
        {match.path.includes("stockInternal") && <StockList match={match} isInternal id={id} />}
        {match.path.includes("kitchenPharmacy") && <CentralStore match={match} id={id} />}
        {match.path.includes("stockTransfers") && <PurchaseRequisitionForm match={match} />}
        {/* {match.path.includes("stockServices") && (
            <StockServicesList match={match as MatchInterface} id={id} />
          )} */}
      </Box>
    </Box>
  </Box>
);

Stock.defaultProps = {
  id: undefined
};

export default connect(
  (state: RootState) => {
    const resourceCentre = getRCFromState(state);
    return {
      suppliers: suppliersSortedSelector(state),
      resourceCentreId: state.userContext?.resourceCentreId,
      subscriptions: resourceCentre?.subscriptions.features
    };
  },
  (dispatch: IThunkDispatch) => ({
    loadSuppliers: (id) => dispatch(actions.getSuppliers(id))
  })
)(Stock);

import * as React from "react";
import { Box, ButtonGroup, Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { tl } from "../../../components/translate";
import Can from "../../Policy/Can";

const EntryButton = ({ navigateTo }: { navigateTo: (url: string) => void }): JSX.Element => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };
  return (
    <Box pl={2}>
      <ButtonGroup
        size="small"
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Can policyAccessKey="campaign:sendMessage">
          <Button
            data-testmation="sendMessage"
            onClick={() => navigateTo("/campaign/campaigns/message")}
            color="primary"
            style={{ width: "145px", height: "32px" }}
          >
            {tl("campaign.sendMessage")}
          </Button>
        </Can>
        <Can policyAccessKey="campaign:createCampaign">
          <Button
            size="small"
            color="primary"
            aria-owns={openMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            data-testmation="campaignDownArrowButton"
          >
            <ArrowDropDownIcon />
          </Button>
        </Can>
      </ButtonGroup>
      <Popper
        style={{ zIndex: 1 }}
        open={openMenu}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "right bottom" : "right bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <Can policyAccessKey="campaign:createCampaign">
                    <MenuItem
                      data-testmation="createCampaign"
                      onClick={() => {
                        navigateTo("/campaign/campaigns/new");
                      }}
                      color="primary"
                      style={{ width: "200px" }}
                    >
                      {tl("campaign.createCampaign")}
                    </MenuItem>
                  </Can>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default EntryButton;

import { createSelector } from "reselect";
import startCase from "lodash/startCase";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";
import { RootState } from "../store";

// selectors
export const spFullNameSelector = (
  serviceProvider: { title: string; firstName: string; lastName: string } = {
    title: "",
    firstName: "",
    lastName: ""
  }
): string => {
  const { title, firstName, lastName } = serviceProvider;
  return `${startCase(title)} ${startCase(firstName)} ${startCase(lastName)}`;
};

export const spFromIdSelector = (
  SPs: ServiceProvider[],
  id: number
): ServiceProvider | undefined => {
  if (!SPs || !id) return null;
  return SPs.find((item) => Number(id) === Number(item.id)) ?? undefined;
};

export const serviceProvidersSelector = (state: RootState): ServiceProvider[] =>
  state.resources.resourceCentreServiceProviders;

export const activeServiceProviders = createSelector(serviceProvidersSelector, (SPs) =>
  SPs.filter(({ active }) => active)
);

export const serviceProvidersSortedSelector = createSelector(activeServiceProviders, (SPs) =>
  SPs.sort((a, b) =>
    `${a.firstName || ""}${a.lastName || ""}`.toLowerCase() >
    `${b.firstName || ""}${b.lastName || ""}`.toLowerCase()
      ? 1
      : -1
  )
);

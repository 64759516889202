import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  MenuList
} from "@mui/material";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import * as ipdClientActions from "../../actions/ipd";
import { getIpdtDetails } from "../../api/ipd";
import { StyledTab, StyledTabs } from "../../components/StyledTabs";
import useMobileScreen from "../../hooks/useMobileScreen";
import { IMedicine, IPD, IpdStatus, MedicationSummary } from "../../interfaces/IpdInterface";
import { CanActions } from "../../interfaces/policy";
import { IThunkDispatch, RootState } from "../../store";
import { DischargeContent } from "./Discharge/DischargeDetails";
import IpdDetails from "./IpdDetails";
import IpdMedication from "./IpdProgress/IpdMedication";
import IpdMedicationShow from "./IpdProgress/IpdMedicationShow";
import IpdProgressRecord from "./IpdProgress/IpdProgressRecord";
import IpdProgressRecordShow from "./IpdProgress/IpdProgressRecordShow";
import IpdVitalsshow from "./IpdProgress/IpdVitalsshow";
import IpdBills from "./IpdBills";
import { notificationAdd } from "../../actions/notification";
import { postIpdRequestForm } from "../../api/requestForms";
import { RequestType } from "./LabRequest/LabRequestForm";
import { Medicine } from "../../interfaces/AssessmentInterfaces";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";
import { MEDICINE_COMPONENT } from "../ResourceCentre/ModuleSettings/IpdSettings";
import IpdProductMedication from "./IpdProgress/IpdProductMedication";

const switchTabRenderer = (activeTab, ipdRecord) => {
  switch (activeTab) {
    case 0:
      return <IpdProgressRecordShow ipdRecord={ipdRecord} />;
    case 1:
      return <IpdVitalsshow ipdRecord={ipdRecord} />;
    case 2:
      return <IpdMedicationShow medicineData={ipdRecord.prescribedMedications} />;
    case 3:
      return <IpdBills ipdRecord={ipdRecord} />;
    case 4:
      if (ipdRecord.status === IpdStatus.Discharged) {
        return <DischargeContent ipdData={ipdRecord} />;
      }
      return "";

    default:
      throw new Error(`Unknown activeTab: ${activeTab}`);
  }
};

interface Props {
  id: number;
  ipdRecord: IPD;
  createMedicationPrescription: (id: number, medication: Medicine[]) => void;
  policies: Partial<CanActions>;
  onDischargeFormOpen: () => void;
}

export function IpdInfo({
  id,
  ipdRecord,
  createMedicationPrescription,
  policies,
  onDischargeFormOpen
}: Props): JSX.Element {
  const isMobileScreen = useMobileScreen();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [openMenu, setOpenMenu] = React.useState(false);
  const rc = useCurrentResourceCentre();

  const [activeTab, setActiveTab] = React.useState(0);
  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };
  const [showProgressPanel, setShowProgressPanel] = React.useState(false);
  const [medicationDialogOpen, setMedicationDialogOpen] = React.useState(false);
  const [medication, setMedication] = React.useState([] as Array<IMedicine | Medicine>);
  const [fetchedIpdRecord, setFetchedIpdRecord] = React.useState<IPD | null>(null);

  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchIpdRecord = async () => {
      try {
        const selectedIpdRecord = await getIpdtDetails(id);
        setFetchedIpdRecord(selectedIpdRecord);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Error, while fetching ipd details",
            autoTimeout: true
          })
        );
      }
    };
    if (!ipdRecord) {
      fetchIpdRecord();
    } else {
      setFetchedIpdRecord(ipdRecord);
    }
  }, [id, dispatch, ipdRecord]);

  React.useEffect(() => {
    if (fetchedIpdRecord?.status === IpdStatus.Discharged) {
      setActiveTab(4);
    }
  }, [fetchedIpdRecord?.status]);

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  if (!fetchedIpdRecord) return <></>;

  return (
    <>
      <Box px={`${isMobileScreen ? "16px" : "32px"}`} width="100%">
        <IpdDetails ipdRecord={fetchedIpdRecord} />
        <Box textAlign="left" marginTop="64px">
          {fetchedIpdRecord?.status === IpdStatus.Admitted && (
            <ButtonGroup
              size="small"
              variant="outlined"
              color="primary"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button
                data-testmation="createProgressRecord"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowProgressPanel(true);
                }}
                disabled={!policies["ipd:createProgressRecord"]}
              >
                Progress Record
              </Button>
              <Button
                data-testmation="clientinfoDropdown"
                size="small"
                color="primary"
                aria-owns={openMenu ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          )}

          <Popper
            style={{ zIndex: 1 }}
            open={openMenu}
            anchorEl={anchorRef.current}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "right bottom" : "right bottom"
                }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem
                        data-testmation="ipdMedication"
                        onClick={() => setMedicationDialogOpen(true)}
                        color="primary"
                        style={{ minWidth: "200px" }}
                        disabled={!policies["ipd:prescribeMedication"]}
                      >
                        Prescribe Medicine
                      </MenuItem>
                      <MenuItem
                        data-testmation="ipdDischarge"
                        onClick={onDischargeFormOpen}
                        color="primary"
                        style={{ minWidth: "200px" }}
                        disabled={!policies["ipd:createDischarge"]}
                      >
                        Discharge
                      </MenuItem>
                      <MenuItem
                        data-testmation="createBill"
                        onClick={() => {
                          if (fetchedIpdRecord?.clientId && fetchedIpdRecord?.bedId) {
                            dispatch(
                              push(
                                `/billing/ipd?clientId=${fetchedIpdRecord.clientId}&bedId=${fetchedIpdRecord.bedId}&ipdId=${fetchedIpdRecord.id}`
                              )
                            );
                          }
                        }}
                        color="primary"
                        style={{ minWidth: "200px" }}
                      >
                        Create IPD Bill
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
        <Box marginTop="32px">
          <StyledTabs
            value={activeTab}
            onChange={(e, tab) => setActiveTab(tab)}
            aria-label="ipd client info tabs"
          >
            <StyledTab label="ProgressRecord" />
            <StyledTab label="Vitals" />
            <StyledTab label="PrescribedMedicine" />
            <StyledTab label="Bills" />
            {fetchedIpdRecord?.status === IpdStatus.Discharged && (
              <StyledTab label="DischargeSummary" />
            )}
          </StyledTabs>
          {switchTabRenderer(activeTab, fetchedIpdRecord)}
        </Box>
        {showProgressPanel && ipdRecord && (
          <IpdProgressRecord
            onCancel={() => setShowProgressPanel(false)}
            stayOnPage
            id={id}
            ipdRecord={fetchedIpdRecord}
          />
        )}
        {medicationDialogOpen && (
          <Dialog
            fullWidth
            onClose={() => setMedicationDialogOpen(false)}
            open={medicationDialogOpen}
          >
            <DialogTitle
              style={{
                borderBottom: "1px solid #ececec",
                background: "#f9f9f9",
                textTransform: "uppercase"
              }}
            >
              {" Medications"}
            </DialogTitle>
            <DialogContent style={{ padding: "24px 32px" }}>
              <Box style={{ width: "100%", display: "flex" }}>
                {rc.settings.ipdSettings.medicationComponent === MEDICINE_COMPONENT.MEDICATION ? (
                  <IpdMedication
                    id={fetchedIpdRecord?.id}
                    medication={medication}
                    setMedication={setMedication}
                  />
                ) : (
                  <IpdProductMedication productMedication={medication} onChange={setMedication} />
                )}
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                background: "#00800014",
                borderTop: "1px solid #00800017",
                justifyContent: "flex-start"
              }}
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                padding="0px 24px"
                alignContent="center"
              >
                <Box />
                <Box>
                  <Button
                    style={{ marginRight: "8px" }}
                    data-testmation="medication.cancelButton"
                    color="primary"
                    onClick={() => {
                      setMedicationDialogOpen(false);
                    }}
                  >
                    cancel
                  </Button>
                  <Button
                    data-testmation="medicationSaveButton"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      createMedicationPrescription(id, medication);
                      setMedicationDialogOpen(false);
                    }}
                  >
                    save
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </>
  );
}

export default connect(
  (state: RootState) => ({
    ipdRecords: state.ipd.collection || [],
    policies: state.userContext.policies || {}
  }),
  (dispatch: IThunkDispatch) => ({
    createMedicationPrescription: async (id: number, medications: MedicationSummary[]) => {
      await dispatch(ipdClientActions.createIpdPrescribeMedication(id, medications));
      // create request form for prescribed medications
      const medicationFromStock = medications?.filter((item) => Boolean(item.isFromStock));
      if (medicationFromStock?.length) {
        try {
          await postIpdRequestForm({
            ipdId: id,
            requestedItems: medications,
            requestedItemsType: RequestType.PRODUCT
          });
        } catch (err) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err.message || "Failed to create request form",
              autoTimeout: true
            })
          );
        }
      }
    }
  })
)(IpdInfo);

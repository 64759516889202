import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { match as MatchProps, Route, Switch } from "react-router";

import OpdRequestForm from "./OpdRequestForm";
import OpdRequestList from "./OpdRequestList";
import OpdRequestInfo from "./OpdRequestInfo";
import { OPDFormMode } from "../../../enum/OpdRequestForm";
import { Client } from "../../../interfaces/ClientInterface";
import { notificationAdd } from "../../../actions/notification";
import { getOpdRequests, RequestForm } from "../../../api/requestForms";

const OpdRequests = ({ match }: { match: MatchProps }): JSX.Element => {
  const pageSize = 20;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [selectedRow, setSelectedRow] = useState<Partial<RequestForm> | null>(null);
  const [selectedClient, setSelectedClient] = useState<Partial<Client> | null>(null);
  const [opdRequestData, setOpdRequestData] = useState<{ results: RequestForm[]; total: number }>({
    results: [],
    total: 0
  });

  const onClose = () => {
    setSelectedRow(null);
    dispatch(push(match.path));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getOpdRequests({ page, pageSize, clientIds: [selectedClient?.id] });
        setOpdRequestData(response);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get request forms",
            autoTimeout: true
          })
        );
      }
    })();
  }, [page, dispatch, selectedClient?.id]);

  return (
    <Box height="100%" pt={2}>
      <OpdRequestList
        page={page}
        setPage={setPage}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        opdRequestData={opdRequestData}
      />

      <Switch>
        <Route
          exact
          path={`${match.path}/create`}
          render={(props) => (
            <OpdRequestForm
              onClose={onClose}
              mode={OPDFormMode.CREATE}
              opdRequestData={opdRequestData}
              setOpdRequestData={setOpdRequestData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/:id`}
          render={(props) => (
            <OpdRequestInfo
              onClose={onClose}
              selectedRow={selectedRow}
              opdRequestData={opdRequestData}
              setOpdRequestData={setOpdRequestData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/:id/edit`}
          render={(props) => (
            <OpdRequestForm
              onClose={onClose}
              mode={OPDFormMode.EDIT}
              opdRequest={selectedRow}
              opdRequestData={opdRequestData}
              setOpdRequestData={setOpdRequestData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </Switch>
    </Box>
  );
};

export default OpdRequests;

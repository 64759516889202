import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import produce from "immer";
import { RootState } from "../../store";
import { searchStockProduct } from "../../api/stockProducts";
import { getServices } from "../../actions";
import { ProductInterface } from "../../interfaces/ProductInterface";
import { ReferrerProductServiceEntities } from "../../interfaces/ReferrerInterface";
import { Mode, Modes } from "./Referrers";
import { tl } from "../../components/translate";

interface Props {
  handleUpdates: (v: Array<StateItem>) => void;
  propState: Array<StateItem>;
  mode?: Modes;
}

interface StockProductOption {
  id: number;
  name: string;
  unit: string;
  unitPriceExcVAT: number;
  unitPriceIncVAT: number;
  active: boolean;
}

export interface StateItem {
  productId: number;
  serviceId: number;
  entityType: ReferrerProductServiceEntities;
  price: number;
  name: string;
  originalPrice: number | string;
}

function getFormattedOptions(
  services: Array<ProductInterface>,
  products: Array<StockProductOption>
) {
  const ret = [] as Array<{
    productId: number;
    serviceId: number;
    entityType: ReferrerProductServiceEntities;
    price: number;
    name: string;
    originalPrice: number;
  }>;
  services.forEach((el) => {
    if (el.active) {
      ret.push({
        productId: null,
        serviceId: el.id,
        entityType: ReferrerProductServiceEntities.service,
        price: el.servicePriceExcVAT,
        name: el.name,
        originalPrice: el.servicePriceExcVAT
      });
    }
  });

  products.forEach((el) => {
    if (el.active) {
      ret.push({
        serviceId: null,
        productId: el.id,
        entityType: ReferrerProductServiceEntities.product,
        price: el.unitPriceExcVAT,
        name: el.name,
        originalPrice: el.unitPriceExcVAT
      });
    }
  });
  return ret;
}

const initialState = {
  productId: null,
  serviceId: null,
  entityType: null,
  price: null,
  name: "",
  originalPrice: null
};

export default function ReferrerProductServices(props: Props): JSX.Element {
  const { handleUpdates, propState, mode } = props;

  const serviceProducts = useSelector((state: RootState) => state.services.collection);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!serviceProducts.length) {
      dispatch(getServices());
    }
  }, [dispatch, serviceProducts.length]);

  const [productServiceItems, setProductServiceItems] = React.useState<Array<StateItem>>([
    initialState
  ]);

  React.useEffect(() => {
    if (propState) {
      setProductServiceItems(propState);
    }
  }, [propState]);

  const [stockProducts, setStockProducts] = React.useState<Array<StockProductOption>>([]);

  return (
    <div
      onBlur={() => {
        handleUpdates(productServiceItems);
      }}
      tabIndex={-1}
    >
      <Paper elevation={6} style={{ marginTop: "16px", padding: "8px" }}>
        <Typography fontSize="1rem">{tl("AssignReferrer")}</Typography>
        <Box display="flex" border="1px solid lightgrey" p="4px" mt="16px">
          <Typography style={{ width: "50%" }}>{tl("product")}</Typography>
          <Typography style={{ width: "20%" }}>{tl("pricePerUnit")}</Typography>
          <Typography style={{ width: "20%", textAlign: "right" }}>
            {tl("originalPrice")}
          </Typography>
          <Box width="10%" />
        </Box>
        <Box border="1px solid lightgrey" p="4px">
          {productServiceItems.map((item, i) => (
            <Box display="flex" key={`${item.productId || item.serviceId}${item.entityType}`}>
              <Box width="50%" px="4px">
                <Autocomplete
                  disabled={mode === Mode.READ_ONLY}
                  options={getFormattedOptions(serviceProducts, stockProducts)}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  value={productServiceItems[i]}
                  onChange={(_, v) => {
                    if (typeof v === "string") return;
                    const updatedItemsState = produce(productServiceItems, (draft) => {
                      draft[i].name = v?.name || "";
                      draft[i].productId = v?.productId || null;
                      draft[i].serviceId = v?.serviceId || null;
                      draft[i].entityType = v?.entityType || null;
                      draft[i].originalPrice = v?.originalPrice || null;
                    });
                    setProductServiceItems(updatedItemsState);
                  }}
                  renderOption={(params, option) => (
                    <li
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      key={`${option.productId || option.serviceId}${option.entityType}`}
                    >
                      <Box display="flex" justifyContent="space-between" width="100%">
                        <Typography>{startCase(option.name)} </Typography>
                        <span style={{ fontSize: "0.875rem", color: "grey", marginLeft: "16px" }}>
                          ({startCase(option.entityType)})
                        </span>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Products Or Services"
                      placeholder="Search and select products and services"
                      margin="dense"
                      onChange={async (e) => {
                        const options = (await searchStockProduct({
                          q: e.target.value
                        })) as unknown as Array<StockProductOption>;
                        setStockProducts(options);
                      }}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box width="20%" px="4px" mt="8px">
                <TextField
                  label="Custom Price"
                  placeholder="Custom Price"
                  fullWidth
                  disabled={mode === Mode.READ_ONLY}
                  value={productServiceItems[i].price}
                  onChange={(e) => {
                    const updatedItemsState = produce(productServiceItems, (draft) => {
                      draft[i].price = Number(e.target.value);
                    });
                    setProductServiceItems(updatedItemsState);
                  }}
                />
              </Box>
              <Box textAlign="right" width="20%" px="8px">
                <Typography style={{ marginTop: "24px" }}>
                  Rs {item.originalPrice || "-"}
                </Typography>
              </Box>
              {mode !== Mode.READ_ONLY && (
                <Box
                  width="10%"
                  textAlign="center"
                  pt="24px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const updatedItemsState = produce(productServiceItems, (draft) => {
                      delete draft[i];
                    });
                    setProductServiceItems(updatedItemsState.filter(Boolean));
                  }}
                >
                  <CloseIcon />
                </Box>
              )}
            </Box>
          ))}
          {mode !== Mode.READ_ONLY && (
            <Typography
              style={{
                textDecoration: "underline",
                fontWeight: 600,
                marginTop: "16px",
                cursor: "pointer",
                width: "200px"
              }}
              onClick={() => {
                setProductServiceItems([...productServiceItems, initialState]);
              }}
            >
              Add Product Or Service Price
            </Typography>
          )}
        </Box>
      </Paper>
    </div>
  );
}

ReferrerProductServices.defaultProps = {
  mode: ""
};

import "./styles.scss";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { v4 as uuidv4 } from "uuid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { RootState } from "../../../store";
import {
  setCheckedUUIDs,
  updateDraft,
  reorderDraft,
  addTestsAtParentLevel
} from "../../../slices/labTestSettingsSlice";
import { Test } from "../../../interfaces/Lab";
import AdvancedRangesEdit, {
  handleRangesSanitization
} from "../Settings/LabMasterDataEdit/AdvancedRangesEdit";
import FormulaDialog from "./FormulaDialog";
import Panel from "../../../components/Panel";
import AddLabTestsDialog from "./List/AddLabTestsDialog";
import { useAppDispatch } from "../../../store/hooks";

function LabTestSelectOptionsDialog({ rowLevel }): JSX.Element {
  const dispatch = useDispatch();
  const addOptionInputRef = React.useRef<HTMLInputElement>();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Tooltip arrow title="Enable/Add select options">
        <IconButton onClick={() => setOpen(true)} size="large">
          <SettingsApplicationsIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Edit select options for test</DialogTitle>
        <DialogContent>
          <Box>
            <Checkbox
              checked={rowLevel.info?.inputType === "select"}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateDraft({
                      uuid: rowLevel.uuid,
                      key: "info",
                      value: {
                        ...rowLevel.info,
                        inputType: "select"
                      }
                    })
                  );
                } else {
                  dispatch(
                    updateDraft({
                      uuid: rowLevel.uuid,
                      key: "info",
                      value: {
                        ...rowLevel.info,
                        inputType: undefined
                      }
                    })
                  );
                }
              }}
            />
            Enable select mode for this labtest
          </Box>
          {rowLevel.info?.inputType === "select" && (
            <Box>
              {rowLevel.info?.options?.map((opt, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box display="flex" key={i} alignItems="center">
                  <Box>{opt}</Box>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => {
                      const updatedOptions = rowLevel.info.options.filter((_, idx) => idx !== i);
                      dispatch(
                        updateDraft({
                          uuid: rowLevel.uuid,
                          key: "info",
                          value: {
                            ...rowLevel.info,
                            options: updatedOptions
                          }
                        })
                      );
                    }}
                  />
                </Box>
              ))}
              <TextField inputRef={addOptionInputRef} />

              <Button
                onClick={() => {
                  dispatch(
                    updateDraft({
                      uuid: rowLevel.uuid,
                      key: "info",
                      value: {
                        ...rowLevel.info,
                        options: [
                          ...(rowLevel.info.options || []),
                          addOptionInputRef.current?.value || ""
                        ]
                      }
                    })
                  );
                  if (addOptionInputRef.current) {
                    addOptionInputRef.current.value = "";
                    addOptionInputRef.current.focus();
                  }
                }}
              >
                Add
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function MoreActionsPanel({
  rowLevel,
  onClose
}: {
  rowLevel: Test;
  onClose: () => void;
}): JSX.Element {
  const labTestOptions = useSelector((state: RootState) => state.labSettings.labTests);

  const nameRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (nameRef.current) {
      nameRef.current.value = rowLevel.name;
    }
  }, [rowLevel.name]);

  const [testsToAdd, setTestsToAdd] = React.useState([]);

  const dispatch = useDispatch();

  return (
    <Panel
      onClose={onClose}
      title="More Actions"
      footer={
        <Box className="panelFooter">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (nameRef.current.value) {
                dispatch(
                  updateDraft({ uuid: rowLevel.uuid, key: "name", value: nameRef.current.value })
                );
              }
              if (testsToAdd.length) {
                let newSubTests = [];
                if (rowLevel.subTests) {
                  newSubTests = [
                    ...rowLevel.subTests,
                    ...testsToAdd.map((testToAdd) => ({ ...testToAdd, uuid: uuidv4() }))
                  ];
                } else {
                  newSubTests = testsToAdd.map((testToAdd) => ({ ...testToAdd, uuid: uuidv4() }));
                }
                dispatch(updateDraft({ uuid: rowLevel.uuid, key: "subTests", value: newSubTests }));
              }
              onClose();
            }}
            variant="contained"
          >
            Ok
          </Button>
        </Box>
      }
    >
      <Box className="panelContent">
        <TextField
          label="Test Name"
          margin="dense"
          fullWidth
          variant="outlined"
          autoFocus
          defaultValue={rowLevel.name}
          inputRef={nameRef}
          onFocus={(e) => e.target.select()}
          onBlur={(e) => {
            dispatch(
              updateDraft({ uuid: rowLevel.uuid, key: "name", value: e.currentTarget?.value })
            );
          }}
        />
        <Autocomplete
          disablePortal
          multiple
          id="combo-box-demo"
          options={labTestOptions as Array<Test>}
          onChange={(e, v) => {
            if (typeof v === "string") return;
            if (!v) {
              setTestsToAdd([]);
              return;
            }
            setTestsToAdd(v);
          }}
          fullWidth
          getOptionLabel={(option) => option.name}
          renderOption={(params, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...params} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Select Lab Tests To Add Under This Test"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          )}
        />
      </Box>
    </Panel>
  );
}

function GenerateLevelRow({ rowLevel, index, placeholders }): JSX.Element {
  const dispatch = useDispatch();

  const unitRef = React.useRef<HTMLInputElement>();
  const methodsRef = React.useRef<HTMLInputElement>();
  const defaultValueRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (unitRef.current) {
      unitRef.current.value = rowLevel.unit;
    }
  }, [rowLevel.unit]);

  React.useEffect(() => {
    if (methodsRef.current) {
      methodsRef.current.value = rowLevel.methods;
    }
  }, [rowLevel.methods]);

  React.useEffect(() => {
    if (defaultValueRef.current) {
      defaultValueRef.current.value = rowLevel.formData.reading || "";
    }
  }, [rowLevel.formData.reading]);

  const [moreActionsPanel, toggleMoreActionsPanel] = React.useState(false);

  const checkedUUIDs = useSelector((state: RootState) => state.labSettings.checkedUUIDs);

  const filteredPlaceholders = placeholders.filter(
    (placeholder) => Number(placeholder.reference.split("__")[0]) !== rowLevel.id
  );

  return (
    <Box className="row_container">
      <Box className="row_wrapper">
        <Box className="row_cell" display="flex" alignItems="center" ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedUUIDs.includes(rowLevel.uuid)}
                onChange={() => {
                  dispatch(setCheckedUUIDs(rowLevel.uuid));
                }}
              />
            }
            label={rowLevel.name}
          />
          <Tooltip title="Add tests" arrow>
            <IconButton size="small" onClick={() => toggleMoreActionsPanel(true)}>
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move up" arrow>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(reorderDraft({ uuid: rowLevel.uuid, index, direction: "up" }));
              }}
            >
              <ArrowUpwardIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move down" arrow>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(reorderDraft({ uuid: rowLevel.uuid, index, direction: "down" }));
              }}
            >
              <ArrowDownwardIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography className="row_cell">
          <TextField
            label="Unit"
            margin="dense"
            defaultValue={rowLevel.unit}
            inputRef={unitRef}
            onFocus={(e) => e.target.select()}
            variant="outlined"
            onBlur={(e) => {
              dispatch(
                updateDraft({ uuid: rowLevel.uuid, key: "unit", value: e.currentTarget.value })
              );
            }}
          />
        </Typography>
        <Typography className="row_cell">
          <AdvancedRangesEdit
            ranges={handleRangesSanitization(rowLevel.ranges || {})}
            onUpdate={(v) => {
              dispatch(updateDraft({ uuid: rowLevel.uuid, key: "ranges", value: v }));
            }}
            buttonText="Edit Range"
          />
        </Typography>
        <Typography className="row_cell">
          <TextField
            label="Methods"
            margin="dense"
            variant="outlined"
            defaultValue={rowLevel.methods}
            inputRef={methodsRef}
            onFocus={(e) => e.target.select()}
            onBlur={(e) => {
              dispatch(
                updateDraft({
                  uuid: rowLevel.uuid,
                  key: "methods",
                  value: e.currentTarget.value
                })
              );
            }}
          />
        </Typography>
        <Typography className="row_cell">
          <Box display="flex" alignItems="center">
            <TextField
              label="Default Value"
              variant="outlined"
              margin="dense"
              defaultValue={rowLevel.formData.reading || ""}
              inputRef={defaultValueRef}
              onFocus={(e) => e.target.select()}
              onBlur={(e) => {
                dispatch(
                  updateDraft({
                    uuid: rowLevel.uuid,
                    key: "formData",
                    value: { ...rowLevel.formData, reading: e.currentTarget.value }
                  })
                );
              }}
            />
            <LabTestSelectOptionsDialog rowLevel={rowLevel} />
          </Box>
          <FormulaDialog
            onUpdate={(v) => {
              dispatch(
                updateDraft({
                  uuid: rowLevel.uuid,
                  key: "formData",
                  value: { ...rowLevel.formData, formula: v }
                })
              );
            }}
            value={rowLevel.formData.formula}
            placeholders={filteredPlaceholders}
          />
        </Typography>
      </Box>
      {rowLevel.subTests && (
        <Box pl="16px">
          {rowLevel.subTests.map((el, i) => (
            <GenerateLevelRow rowLevel={el} key={el.uuid} index={i} placeholders={placeholders} />
          ))}
        </Box>
      )}
      {moreActionsPanel && (
        <MoreActionsPanel onClose={() => toggleMoreActionsPanel(false)} rowLevel={rowLevel} />
      )}
    </Box>
  );
}

function generatePlaceholders(tests, placeholders = []) {
  tests.forEach((test) => {
    placeholders.push({ name: test.name, reference: `${test.id}__${uuidv4()}` });
    if (test.subTests) {
      generatePlaceholders(test.subTests, placeholders);
    }
  });
  return placeholders;
}

function ChildrenLabTestEdit(): JSX.Element {
  const draftGroup = useSelector((state: RootState) => state.labSettings.draftGroup);
  const placeholders = generatePlaceholders(draftGroup.labTests || []);

  const [isAddingTest, setIsAddingTest] = React.useState(false);
  const dispatch = useAppDispatch();

  return (
    <Box className="tests_edit_container">
      <Box className="headers_wrapper">
        {["Test Name", "Unit", "Ranges", "Methods", "Default Value/ Options", ""].map((el) => (
          <Typography key={el} className="header_cell">
            {el}
          </Typography>
        ))}
      </Box>
      <Box className="tests_wrapper">
        {(draftGroup.labTests || []).map((labTest, index) => (
          <GenerateLevelRow
            rowLevel={labTest}
            key={labTest.uuid}
            index={index}
            placeholders={placeholders}
          />
        ))}
      </Box>
      <Box mt={1}>
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          onClick={() => setIsAddingTest(true)}
        >
          {draftGroup.labTests?.length > 0 ? "Add more lab tests" : "Add lab tests to this group"}
        </Button>
        <AddLabTestsDialog
          isOpen={isAddingTest}
          onClose={() => setIsAddingTest(false)}
          onSave={(value) => {
            if (value.length) {
              dispatch(addTestsAtParentLevel(value.map((el) => ({ ...el, uuid: uuidv4() }))));
            }
            setIsAddingTest(false);
          }}
        />
      </Box>
    </Box>
  );
}

export default React.memo(ChildrenLabTestEdit);

import produce from 'immer';
import moment from 'moment';

import { ReducerBuilder } from './ReducerBuilder';
import * as ReminderActions from '../actions/reminders';

const INITIAL_STATE = [];

const sortNotificationByDate = (data = []) =>
  data.sort((a, b) => {
    return moment(b.on).valueOf() - moment(a.on).valueOf();
  });

function _getReminders(state, payload) {
  return [...sortNotificationByDate(payload)];
}

// function _reminderMarkRead(state, payload) {
//   return produce(state, (draft) => {
//     const payloadIndex = state.findIndex((reminder) => reminder.id === payload.id);
//     if (payloadIndex !== -1) {
//       draft[payloadIndex] = payload;
//     }
//   });
// }

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(ReminderActions.Type.GET_REMINDERS, _getReminders)
  // .mapAction(ReminderActions.Type.REMINDER_MARK_READ, _reminderMarkRead)
  .build();

export default reducer;

import { Box, Button } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, isEqual } from "lodash";
import { Prompt } from "react-router";
import { RootState } from "../../../store";
import ChildrenLabTestEdit from "./ChildrenLabTestEdit";
import TestGroupNameAndCategoryEdit from "./TestGroupNameAndCategoryEdit";
import * as NotificationActions from "../../../actions/notification";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { postTestGroup, setInitialState } from "../../../slices/labTestSettingsSlice";
import { DocumentRecordGroup, TestTypes } from "../../../interfaces/Lab";
import LabGroupAdditionalSettingsEdit from "./LabGroupAdditionalSettingsEdit";

const isEqualState = (
  finalState: DocumentRecordGroup,
  initialState: DocumentRecordGroup
): boolean => {
  if (finalState === null && initialState === null) return true;
  const formattedFinal = cloneDeep(finalState);
  const removeUUIDS = (testsArray) => {
    testsArray?.forEach((test) => {
      // eslint-disable-next-line no-param-reassign
      delete test.uuid;
      if (test.subTests?.length > 0) {
        removeUUIDS(test.subTests);
      }
    });
  };
  removeUUIDS(formattedFinal.labTests);
  return isEqual(formattedFinal, initialState);
};

export default function EditScreen({
  onCancel,
  heightOffset = 110
}: {
  onCancel: () => void;
  heightOffset?: number;
}): JSX.Element | null {
  const draftGroup = useSelector((state: RootState) => state.labSettings.draftGroup);
  const initialState = useSelector((state: RootState) => state.labSettings.initState);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  if (!draftGroup) return null;

  if (
    (!draftGroup.labTests || !draftGroup.labTests.length) &&
    draftGroup.type !== TestTypes.RADIOLOGY
  ) {
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "error",
        message: "Test can only be saved if lab test list is not empty!"
      })
    );
  }

  return (
    <div>
      <Prompt
        when={!isEqualState(draftGroup, initialState)}
        message={() =>
          `You have unsaved changes!
Are you sure you want to leave this page?`
        }
      />
      <Box
        sx={{
          height: `calc(100vh - ${heightOffset}px)`,
          overflowY: "auto",
          borderBottom: "1px solid lightgrey"
        }}
        p={2}
      >
        <TestGroupNameAndCategoryEdit />
        <ChildrenLabTestEdit />
        <LabGroupAdditionalSettingsEdit />
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" mt="8px" px="8px">
        <Button sx={{ marginRight: "16px" }} onClick={onCancel}>
          Cancel
        </Button>
        <StatefulButton
          color="primary"
          variant="contained"
          disabled={loading || !draftGroup?.labTests || !draftGroup?.labTests?.length}
          onClick={async () => {
            try {
              setLoading(true);
              dispatch(setInitialState(draftGroup));
              await dispatch(postTestGroup(draftGroup));
              onCancel();
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "success",
                  message: "Succesfully Updated Testgroup!",
                  autoTimeout: true
                })
              );
            } catch (e) {
              onCancel();
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Something went wrong! Failed To Update Testgroup!",
                  autoTimeout: true
                })
              );
            } finally {
              setLoading(false);
            }
          }}
          isLoading={loading}
          circularProgressProps={{ size: 16 }}
          data-testmation="saveTestGroupEdit"
        >
          Save
        </StatefulButton>
      </Box>
    </div>
  );
}

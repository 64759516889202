import * as React from "react";
import { connect } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { suppliersSortedSelector } from "../../reducers/suppliers";
import { navigateAddModal } from "../../actions/navigation";
import { Supplier } from "../../interfaces/StockInterfaces";
import hasOwnProperty from "../../helpers/object";
import { t } from "../../components/translate";

interface Props {
  suppliers: Array<Supplier>;
  onChange: (s: Supplier, b?: boolean) => void;
  actions: {
    createNewSupplier: (name: string, next: (s: Supplier) => void) => void;
  };
  error?: boolean;
}

export const SuppliersAutoSelect = ({
  suppliers,
  onChange,
  actions,
  error
}: Props): JSX.Element => (
  <Autocomplete
    options={suppliers as Array<Supplier | { inputValue: string; name: string }>}
    clearOnBlur
    style={{ width: 300 }}
    freeSolo
    onChange={(_, v) => {
      if (typeof v === "string") {
        return;
      }
      if (hasOwnProperty(v, "inputValue") && v?.inputValue) {
        actions.createNewSupplier(v.inputValue, (_supplier) => {
          onChange(_supplier, true);
        });
      } else {
        onChange(v as Supplier);
      }
    }}
    filterOptions={(options, params) => {
      if (params.inputValue !== "") {
        options.push({
          inputValue: params.inputValue,
          name: `Add "${params.inputValue}"`
        });
      }
      return options.filter((opt) => {
        const filterString = (hasOwnProperty(opt, "name") ? opt.name : "").toLowerCase();
        return (
          filterString.includes(params.inputValue.toLowerCase()) || filterString.includes("add")
        );
      });
    }}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        data-testmation="selectSupplier"
        autoFocus
        error={error}
        variant="outlined"
        margin="dense"
        placeholder={t("stock.selectSupplier")}
      />
    )}
    renderOption={(ownProps, option) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li {...ownProps} key={"id" in option ? option.id : "AddSupplierKey"}>
        {option.name}
      </li>
    )}
    getOptionLabel={(option) => option.name}
  />
);

SuppliersAutoSelect.defaultProps = {
  error: false
};

export default connect(
  (state) => ({
    suppliers: suppliersSortedSelector(state).filter((item) => Boolean(item.active))
  }),
  (dispatch) => ({
    actions: {
      createNewSupplier: (supplierName, next) => {
        dispatch(
          navigateAddModal({
            component: "SupplierCreateModal",
            props: {
              next,
              supplierDefaultData: {
                name: supplierName
              }
            }
          })
        );
      }
    }
  })
)(SuppliersAutoSelect);

import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Box, Button, Typography } from "@mui/material";
import IpdClientInfo from "./IpdClientInfo";
import Panel from "../../../components/Panel";
import { RequestType } from "./LabRequestForm";
import ProductInfo from "../../Services/ProductInfo";
import ProductCreate from "../../Services/ProductCreate";
import { updateStatus, RequestForm } from "../../../api/requestForms";
import { IpdTabs } from "../../Billing";
import { notificationAdd } from "../../../actions/notification";

export enum RequestFormStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  HANDLED = "handled"
}

interface Props {
  onClose: () => void;
  onStatusChange: (value: RequestForm) => void;
  labRequest: RequestForm;
}
const LabRequestDetails = ({ onClose, labRequest, onStatusChange }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = React.useState({
    show: false,
    id: null
  });
  const [editingService, setEditingService] = React.useState({
    show: false,
    id: null
  });

  return (
    <Panel onClose={() => onClose()} title="Request Details">
      <Box pt={1} px={3} flex={1}>
        <IpdClientInfo
          ipd={labRequest.ipd}
          client={{
            ...labRequest.client,
            customerNumber: labRequest?.customer?.customerNumber || ""
          }}
        />
        <Box p={2}>
          <Typography fontWeight="bold">
            Requested{" "}
            {labRequest.requestedItemsType === RequestType.SERVICE
              ? "Lab Services"
              : "Product Medications"}
          </Typography>
          <div>
            {labRequest.requestedItems.map((item, i) => (
              <Box key={item.id} mt={0.5} display="flex" gap={1}>
                <span>{i + 1}.</span>
                <Typography
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    if (labRequest.requestedItemsType === RequestType.SERVICE) {
                      setSelectedService({
                        show: true,
                        id: item.id
                      });
                    }
                  }}
                >
                  {item.name || item.brand}
                </Typography>
                {!item.id && (
                  <Typography color="error">
                    (Product not in stock, will not be included in the bill)
                  </Typography>
                )}
              </Box>
            ))}
          </div>
        </Box>
        {labRequest.status === RequestFormStatus.PENDING && (
          <Box pt={2} display="flex" gap={2}>
            <Button
              variant="contained"
              onClick={() => {
                if (labRequest.requestedItemsType === RequestType.SERVICE) {
                  dispatch(
                    push(
                      `/billing/ipd?tab=${IpdTabs.NewBill}&clientId=${labRequest.clientId}&bedId=${labRequest.ipd.bedId}&ipdId=${labRequest.ipdId}&labRequestId=${labRequest.id}`
                    )
                  );
                } else {
                  dispatch(
                    push(
                      `/billing/ipd?tab=${IpdTabs.NewBill}&clientId=${labRequest.clientId}&bedId=${labRequest.ipd.bedId}&ipdId=${labRequest.ipdId}&productMedicationRequestId=${labRequest.id}`
                    )
                  );
                }
              }}
            >
              Create Bill
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const data = await updateStatus(labRequest.id, RequestFormStatus.HANDLED);
                  onStatusChange(data);
                  dispatch(
                    notificationAdd({
                      id: new Date().getUTCMilliseconds(),
                      variant: "success",
                      message: "Successfully marked as handled",
                      autoTimeout: false
                    })
                  );
                } catch (error) {
                  dispatch(
                    notificationAdd({
                      id: new Date().getUTCMilliseconds(),
                      variant: "error",
                      message: error.message || "Failed to mark request as handled.",
                      autoTimeout: false
                    })
                  );
                }
              }}
            >
              Mark Handled
            </Button>
          </Box>
        )}
      </Box>
      {selectedService.show && selectedService.id && (
        <ProductInfo
          productId={selectedService.id}
          handleClose={() => setSelectedService({ show: false, id: null })}
          onEditClick={() => setEditingService({ show: true, id: selectedService.id })}
          match={{}}
        />
      )}
      {editingService && editingService.id && (
        <ProductCreate
          isOpd
          productId={editingService.id}
          handleClose={() => {
            setSelectedService({ show: false, id: null });
            setEditingService({ show: false, id: null });
          }}
          redirectAfterUpdate={false}
        />
      )}
    </Panel>
  );
};

export default LabRequestDetails;

import React from "react";
import { Box } from "@mui/material";
import {
  autoRefractionHeaders,
  refractionHeaders,
  unAidedHeaders,
  aidedHeaders,
  pinHoleHeaders,
  bestCorrectedHeaders,
  anteriorSegmentHeaders,
  posteriorHeaders,
  glassPrescriptionHeaders,
  sortColumnOrder,
  sortRowOrder,
  fieldOfVisionHeaders,
  colorVisionHeaders,
  initialState
} from "../../Assessment/EyeExamination/EyeExamination";

const Row = ({ data, row, headers }) => {
  const values = Object.values(data).filter((item) => item !== null && item !== "");
  if (values.length > 0) {
    return (
      <Box display="flex">
        <Box pl={3} style={{ width: "24%" }}>
          {row}
        </Box>
        <Box>
          {Object.keys(data).map((col, index) => (
            <Box key={col} component="span" style={{ fontWeight: "500" }} mr={4}>{`${
              headers[index + 1]
            }( ${data[col] === null || data[col] === "" ? "-" : data[col]} )`}</Box>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};

function hasValue(test) {
  return Object.keys(test).some((row) => {
    const containsValue = Object.keys(test[row]).some((col) => Boolean(test[row][col]));
    return containsValue;
  });
}

const Title = ({ label, subTitle = false }) => (
  <Box display="flex">
    <Box
      style={{ paddingLeft: `${subTitle ? "16px" : ""}` }}
      width="24%"
      fontWeight={`${subTitle ? "500" : "600"}`}
    >
      {label}
    </Box>
  </Box>
);

const EyeAssessmentContent = ({
  eyeExaminationDoc
}: {
  eyeExaminationDoc: typeof initialState;
}): JSX.Element => {
  const {
    vision: visionData,
    anteriorSegment: anteriorSegData,
    posteriorSegment: posteriorSegData,
    glassPrescription: glassPrescriptionData
  } = eyeExaminationDoc;

  const sortData = (data) =>
    Object.keys(data)
      .sort((a, b) => sortRowOrder.indexOf(a) - sortRowOrder.indexOf(b))
      .reduce((a, b) => {
        const sortedInside = Object.keys(data[b])
          .sort((ax, bx) => sortColumnOrder.indexOf(ax) - sortColumnOrder.indexOf(bx))
          .reduce((x, y) => {
            // eslint-disable-next-line no-param-reassign
            x[y] = data[b][y];
            return x;
          }, {});
        // eslint-disable-next-line no-param-reassign
        a[b] = sortedInside;
        return a;
      }, {});

  const showAutoRefraction = hasValue(visionData.autoRefraction);
  const showRefraction = hasValue(visionData.refraction);
  const showUnaided = hasValue(visionData.unAided);
  const showAided = hasValue(visionData.aided);
  const showFieldOfVision = hasValue(visionData.fieldOfVision);
  const showColorVision = hasValue(visionData.colorVision);
  const showBestCorrectiveVisualAcuity = hasValue(visionData.bestCorrectiveVisualAcuity);
  const showPinHole = hasValue(visionData.pinHole);
  const showAnteriorSegment = hasValue(anteriorSegData);
  const showPosteriorSegment = hasValue(posteriorSegData);
  const showGlassPrescription = hasValue(glassPrescriptionData);

  const sortedAnteriorData = sortData(anteriorSegData);
  const sortedPosteriorsegmentData = sortData(posteriorSegData);
  const sortedGlassPrescriptionData = sortData(glassPrescriptionData);
  const sortedAutoRefractionData = sortData(visionData.autoRefraction);
  const sortedRefractionData = sortData(visionData.refraction);
  const sortedUnaidedData = sortData(visionData.unAided);
  const sortAidedData = sortData(visionData.aided);
  const sortFieldOfVisionData = sortData(visionData.fieldOfVision);
  const sortColorVisionData = sortData(visionData.colorVision);
  const bestCorrectedData = sortData(visionData.bestCorrectiveVisualAcuity);
  const pinHoleData = sortData(visionData.pinHole);

  return (
    <Box>
      <Box>
        {(showAutoRefraction ||
          showRefraction ||
          showUnaided ||
          showAided ||
          showBestCorrectiveVisualAcuity ||
          showPinHole) && <Title label="Vision" />}
        <Box>
          {showAutoRefraction && <Title label="Auto Refraction" />}
          {Object.keys(sortedAutoRefractionData).map((row) => (
            <Row
              key={row}
              data={sortedAutoRefractionData[row]}
              row={row}
              headers={autoRefractionHeaders}
            />
          ))}
        </Box>
        <Box>
          {showRefraction && <Title label="Refraction" subTitle />}
          {Object.keys(sortedRefractionData).map((row) => (
            <Row key={row} data={sortedRefractionData[row]} row={row} headers={refractionHeaders} />
          ))}
        </Box>
        <Box>
          {showUnaided && <Title label="Unaided" />}
          {Object.keys(sortedUnaidedData).map((row) => (
            <Row key={row} data={sortedUnaidedData[row]} row={row} headers={unAidedHeaders} />
          ))}
        </Box>
        <Box>
          {showAided && <Title label="Aided" />}
          {Object.keys(sortAidedData).map((row) => (
            <Row key={row} data={sortAidedData[row]} row={row} headers={aidedHeaders} />
          ))}
        </Box>
        <Box>
          {showFieldOfVision && <Title label="Field of Vision" />}
          {Object.keys(sortFieldOfVisionData).map((row) => (
            <Row
              key={row}
              data={sortFieldOfVisionData[row]}
              row={row}
              headers={fieldOfVisionHeaders}
            />
          ))}
        </Box>
        <Box>
          {showColorVision && <Title label="Color Vision" />}
          {Object.keys(sortColorVisionData).map((row) => (
            <Row key={row} data={sortColorVisionData[row]} row={row} headers={colorVisionHeaders} />
          ))}
        </Box>
        <Box>
          {showBestCorrectiveVisualAcuity && <Title label="Best Corrected Visual Acuity" />}
          {Object.keys(bestCorrectedData).map((row) => (
            <Row key={row} data={bestCorrectedData[row]} row={row} headers={bestCorrectedHeaders} />
          ))}
        </Box>
        <Box>
          {showPinHole && <Title label="Pin Hole" />}
          {Object.keys(pinHoleData).map((row) => (
            <Row data={pinHoleData[row]} row={row} headers={pinHoleHeaders} key={row} />
          ))}
        </Box>
      </Box>
      <Box>
        {showAnteriorSegment && <Title label="Anterior Segment" />}
        {Object.keys(sortedAnteriorData).map((row) => (
          <Row
            data={sortedAnteriorData[row]}
            row={row}
            headers={anteriorSegmentHeaders}
            key={row}
          />
        ))}
      </Box>
      <Box>
        {showPosteriorSegment && <Title label="Posterior Segment" />}
        {Object.keys(sortedPosteriorsegmentData).map((row) => (
          <Row
            data={sortedPosteriorsegmentData[row]}
            row={row}
            headers={posteriorHeaders}
            key={row}
          />
        ))}
      </Box>
      <Box>
        {showGlassPrescription && <Title label="Glass Prescription" />}
        {Object.keys(sortedGlassPrescriptionData).map((row) => (
          <Row
            data={sortedGlassPrescriptionData[row]}
            row={row}
            headers={glassPrescriptionHeaders}
            key={row}
          />
        ))}
      </Box>
    </Box>
  );
};

export default EyeAssessmentContent;

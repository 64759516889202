/* eslint-disable no-param-reassign */
import produce from "immer";
import moment from "moment";
import { ReducerBuilder } from "./ReducerBuilder";
import * as LabTestActions from "../actions/labTest";
import { Specialities } from "../models/ResourceCentreServiceProvider";
import { LabClient, LabRecord, LabStatuses } from "../interfaces/Lab";

interface State {
  labTestRecords: Array<LabRecord>;
  lastTouched: LabRecord;
  total?: number;
}

const INITIAL_STATE: State = {
  labTestRecords: [],
  lastTouched: null
};

export function handleLabTestVersions(labObj: LabRecord): LabRecord {
  return produce(labObj, (labObject) => {
    if (
      (labObject.version === "v1" || !labObject.version) &&
      labObject.status === LabStatuses.RESULT_READY
    ) {
      if (labObject.results.approvedBy) {
        const { authorizedSP1, authorizedSP2, authorizedSP3 } = labObject.results.approvedBy;
        if (authorizedSP1 && authorizedSP1.role === "1") {
          labObject.results.approvedBy.authorizedSP1 = {
            ...authorizedSP1,
            specialities: Specialities[authorizedSP1.specialities[0]]
          };
        }
        if (authorizedSP2 && authorizedSP2.role === "1") {
          labObject.results.approvedBy.authorizedSP2 = {
            ...authorizedSP2,
            specialities: Specialities[authorizedSP2.specialities[0]]
          };
        }
        if (authorizedSP3 && authorizedSP3.role === "1") {
          labObject.results.approvedBy.authorizedSP3 = {
            ...authorizedSP3,
            specialities: Specialities[authorizedSP3.specialities[0]]
          };
        }
      }
    }
  }) as unknown as LabRecord;
}

export function applyDefaultValuesToTest(labObj: LabRecord): LabRecord {
  return labObj;
}

export function applyDefaultValuesToTests(tests: Array<LabRecord>): Array<LabRecord> {
  const updatedTests = Array.from(tests).map((item) => applyDefaultValuesToTest(item));
  return updatedTests;
}

export function applyMedicalTestsSort(
  toSort: Array<Record<string, unknown>>
): Array<Record<string, unknown>> {
  return toSort;
}

function setLabTests(state, payload) {
  const { results, total } = payload;

  const data = results || payload;

  const formattedData = produce(data, (draft) => {
    draft.forEach((draftItem) => {
      if (draftItem.status === LabStatuses.ORDERED) {
        draftItem.results.comment = draftItem.results.data.reduce((acc, cur) => {
          if (cur.info?.defaultRemarks) {
            acc += cur.info?.defaultRemarks;
          }
          return acc;
        }, "");
      }
    });
  });

  return { ...state, labTestRecords: formattedData, total };
}

function setLabTest(state, payload) {
  if (!payload) return state;
  const data = payload[0] || payload;
  const idxToReplace = state.labTestRecords.findIndex((item) => item.id === data.id);
  const newState = produce(state, (draft) => {
    if (data.status === LabStatuses.ORDERED || data.status === LabStatuses.SAMPLE_TAKEN) {
      data.results.comment = data.results.data.reduce((acc, cur) => {
        if (cur.info?.defaultRemarks) {
          acc += cur.info?.defaultRemarks;
        }
        return acc;
      }, "");
    }
    draft.labTestRecords[idxToReplace] = data;
  });
  return newState as unknown as State;
}

function setLabTestOnCreate(state) {
  return { ...state };
}

function deleteLabTests(state, payload) {
  payload = payload || [];
  return {
    ...state,
    labTestRecords: state.labTestRecords.filter((item) => !payload.includes(item.id))
  };
}

export function allowRecordEdit(record: {
  resultDate: string;
  status: LabStatuses | "string";
}): boolean {
  if (!record.resultDate) return true;
  if (record.status === LabStatuses.RESULT_READY) {
    if (moment().diff(moment(record.resultDate), "hours") < 24) {
      return true;
    }
    return false;
  }
  return true;
}

function updateClientNames(state, payload) {
  const { firstName, lastName, id } = payload;
  const newState = produce(state, (draft) => {
    draft.labTestRecords = draft.labTestRecords.map((record) => {
      if (record.clientId === id) {
        if (allowRecordEdit(record)) {
          return {
            ...record,
            client: { ...record.client, firstName, lastName }
          };
        }
      }
      return record;
    });
  });
  return newState as unknown as State;
}

const updateLabTestOnClientEdit = (state: State, payload: LabClient) => {
  if (!payload?.id) return state;
  const newCollection = state.labTestRecords.map((lab) => {
    if (payload.id === lab.client.id) {
      return produce(lab, (draft) => {
        draft.client = payload;
      });
    }
    return lab;
  });
  return { ...state, labTestRecords: newCollection };
};

export function updateClientImage(
  state: State,
  payload: { id: number; profileImageS3Url: string }
): State {
  const { id, profileImageS3Url } = payload;
  const newState = produce(state, (draft) => {
    draft.labTestRecords = draft.labTestRecords.map((record) => {
      if (record.client.id === id && allowRecordEdit(record)) {
        if (record.customer?.customerDetails) {
          record.customer.customerDetails.profileImageS3Url = profileImageS3Url;
        } else {
          record.customer.customerDetails = { profileImageS3Url };
        }
      }
      return record;
    });
  });
  return newState as State;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(LabTestActions.Type.GET_LAB_TEST_RECORD, setLabTest)
  .mapAction(LabTestActions.Type.GET_LAB_TEST_RECORDS, setLabTests)
  .mapAction(LabTestActions.Type.POST_OR_EDIT_LAB_TEST_RECORD, setLabTestOnCreate)
  .mapAction(LabTestActions.Type.UPDATE_LAB_TEST_RECORD, setLabTest)
  .mapAction(LabTestActions.Type.UPDATE_LAB_TEST_SAMPLE, setLabTest)
  .mapAction(LabTestActions.Type.BATCH_DELETE, deleteLabTests)
  .mapAction(LabTestActions.Type.UPDATE_CLIENT_NAME, updateClientNames)
  .mapAction(LabTestActions.Type.UPDATE_CLIENT_IMAGE, updateClientImage)
  .mapAction(LabTestActions.Type.UPDATE_LABTEST_ON_CLIENT_EDIT, updateLabTestOnClientEdit)
  .build();
export default reducer;

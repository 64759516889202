import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Panel from "../../../components/Panel";
import ClientSearch from "../../../components/ClientSearch";
import { Client } from "../../../interfaces/ClientInterface";
import { clientSearchSecondaryLabel } from "../../../models/Client";
import MulDartaForm from "./MulDartaForm";
import { useAppSelector } from "../../../store/hooks";
import { t } from "../../../components/translate";

interface Props {
  onCancel: () => void;
  nextMulDartaNumber: string;
}

export const CreateMulDartaPanel = ({ onCancel, nextMulDartaNumber }: Props): JSX.Element => {
  const [client, setClient] = useState<Client>({} as Client);
  const addressOptions = useAppSelector((state) => state.addressOptions.collection);

  return (
    <Panel
      onClose={onCancel}
      titleJsx={
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <Typography variant="h5" component="p">
            {t("hmis.mulDarta")}
          </Typography>
          <Typography variant="body2">HMIS 1.1</Typography>
        </Box>
      }
    >
      <Box
        sx={{
          px: 4,
          pt: 2,
          height: "calc(100vh - 120px)",
          overflowY: "auto"
        }}
      >
        <ClientSearch
          onCreateNewClient={(name) => setClient({ firstName: name } as Client)}
          client={client}
          setClient={setClient}
          variant="outlined"
          margin="dense"
          label={t("hmis.form.search/CreateClient")}
          placeholder={t("hmis.form.search/CreateClient")}
          secondaryText={[
            {
              type: clientSearchSecondaryLabel.PALIKA,
              formatter: (value) => {
                const selectedPalika = addressOptions.find((item) => item.palikaId === value);
                return `${selectedPalika.palikaName} , ${selectedPalika.districtName} , ${selectedPalika.provinceName}`;
              }
            }
          ]}
        />
        <MulDartaForm client={client} onClose={onCancel} nextMulDartaNumber={nextMulDartaNumber} />
      </Box>
    </Panel>
  );
};

export default CreateMulDartaPanel;

import * as Sentry from '@sentry/browser';
import { UserMode } from '../interfaces/User';

type sentryParams = {
  tagKey: string;
  tagValue: string;
  severity: Sentry.Severity;
  user: Sentry.User;
  extras: Partial<{
    userRole: UserMode;
    resourceCentreId: number;
    resourceCentreName: string;
  }>;
  error: any;
};

export function sendToSentry(params: sentryParams): void {
  const { tagKey, tagValue, severity, user, extras, error } = params;
  Sentry.withScope((scope) => {
    scope.setTag(tagKey, tagValue);
    scope.setLevel(severity);
    scope.setUser({
      id: user.id,
      username: user.username,
      email: user.email
    });
    scope.setExtras({
      userRole: extras.userRole,
      resourceCentreId: extras.resourceCentreId,
      resourceCentreName: extras.resourceCentreName
    });
    Sentry.captureException(error);
  });
}

import { Autocomplete, TextField } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import { VisitType, VisitTypes } from "../../interfaces/BillInterfaces";

interface Props {
  value: VisitTypes | null;
  onChange: (value: VisitTypes) => void;
  internalValue: string | null;
}

export default function SelectVisitType({ internalValue, onChange }: Props): JSX.Element {
  return (
    <Autocomplete
      options={Object.values(VisitType).map((v) => startCase(v))}
      value={internalValue}
      onChange={(_, v) => onChange(v)}
      getOptionLabel={(option) => `${option}`}
      renderInput={(params) => (
        <TextField
          data-testmation="visitTypeSelect"
          //   eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          variant="standard"
          margin="dense"
          label="Visit Type"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

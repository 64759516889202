import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';

const TextBox = ({
  value = '',
  onSave,
  counterLength = 255, //text length default 255 if not passed as from parents
  showTextCounter = false, // to show helper text in textfield
  ...props
}: TextFieldProps & {
  value: string;
  onSave: (value) => void;
  counterLength?: number;
  showTextCounter?: boolean;
}) => {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <>
      <TextField
        helperText={showTextCounter ? `${text?.length || 0}/${counterLength}` : ''}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onBlur={() => onSave(text)}
        {...props}
      />
    </>
  );
};

export default TextBox;

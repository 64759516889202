export enum CONFIRMATION {
  YES = "yes",
  NO = "no"
}

export enum MUAC_OPTIONS {
  RED = "red",
  GREEN = "green",
  YELLOW = "yellow"
}

export enum RESULT_OPTIONS {
  IMPROVED = "improved",
  SAME = "same",
  WORSE = "worse"
}

export enum OUTCOME {
  IMPROVED = "improved",
  REFERRED = "referred",
  DEATH = "death",
  LAMA_ABSCONDED = "lamaAbsconded"
}

export enum PANELS {
  NONE = "NONE",
  VITALS_AND_REFERRAL = "VITALS_AND_REFERRAL",
  PATIENT_SIGN_AND_SYMPTOMS = "PATIENT_SIGN_AND_SYMPTOMS",
  CLASSIFICATION_AND_CODE = "CLASSIFICATION_AND_CODE",
  TREATMENT_AND_COUNSELLING = "TREATMENT_AND_COUNSELLING",
  REFERRED_TO_AND_FOLLOW_UP = "REFERRED_TO_AND_FOLLOW_UP",
  REMARKS = "REMARKS"
}

export enum REFERRED_BY {
  FCHV = "FCHV",
  PHC_ORC = "PHC/ORC",
  HF = "HF"
}

export const HMIS_PAGE_SIZE = 20;

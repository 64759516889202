import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Grid } from "@mui/material";
import DatePicker from "../../components/DatePickers";
import ButtonGroup from "./ButtonGroup";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

interface HeaderProps {
  selectedDate: moment.Moment;
  onDateChange: (date) => void;
  showButtonGroup?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  selectedDate,
  onDateChange,
  showButtonGroup = true
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={6}>
          <DatePicker date={selectedDate} onChange={(date) => onDateChange(date)} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ justifyContent: "end" }}>
          {showButtonGroup && <ButtonGroup />}
        </Grid>
      </Grid>
    </div>
  );
};

Header.defaultProps = {
  showButtonGroup: true
};

export default Header;

import { push } from "connected-react-router";
import { actionCreator, actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/stock";
import * as NotificationActions from "./notification";
import {
  DiscountSettings,
  Entry,
  QueryParams,
  StockProducts,
  PaymentInfoStateProps,
  TransactionType
} from "../interfaces/StockInterfaces";
import { AppThunk, IThunkDispatch } from "../store";
import { tl } from "../components/translate";
import { PaymentHistoryProps } from "../containers/Stock/StockTransactions/StockTransactionView";

export enum CentralStoreTabs {
  PRODUCTS = "Products",
  STOCK_REQUESTED = "Requested Stocks",
  STOCK_RECEIVED = "Stock Received"
}

export enum Type {
  UPDATE_STOCK_TRANSACTION_ENTRY = "UPDATE_STOCK_TRANSACTION_ENTRY",
  UPDATE_ITEM_ENTRY = "UPDATE_ITEM_ENTRY",
  ADD_NEW_ITEM_ROW = "ADD_NEW_ITEM_ROW",
  REMOVE_ITEM_ROW = "REMOVE_ITEM_ROW",
  POST_STOCK_TRANSACTION = "POST_STOCK_TRANSACTION",
  GET_STOCKS = "GET_STOCKS",
  GET_STOCKS_BY_PRODUCT_ID = "GET_STOCKS_BY_PRODUCT_ID",
  GET_STOCKS_BY_PRODUCT_IDS = "GET_STOCKS_BY_PRODUCT_IDS",
  CLEAR_ENTRY = "CLEAR_ENTRY",
  GET_STOCK_TRANSACTIONS = "GET_STOCK_TRANSACTIONS",
  UPDATE_STOCK_TRANSACTION = "UPDATE_STOCK_TRANSACTION",
  UPDATE_STOCK_SETTINGS = "UPDATE_STOCK_SETTINGS",
  UPDATE_STOCK_ENTRY_ON_PRODUCT_UPDATE = "UPDATE_STOCK_ENTRY_ON_PRODUCT_UPDATE",
  EDIT_OPENING_STOCK = "EDIT_OPENING_STOCK",
  SAVE_STOCK_ADJUSTMENT = "SAVE_STOCK_ADJUSTMENT",
  GET_RECORD_PAYMENT_BY_TRX_ID = "GET_RECORD_PAYMENT_BY_TRX_ID",
  UPDATE_TRX_RECORD_PAYMENT = "UPDATE_TRX_RECORD_PAYMENT",
  CANCEL_PAYMENT = "CANCEL_PAYMENT"
}

export const updateStockTransactionEntry = actionCreator(Type.UPDATE_STOCK_TRANSACTION_ENTRY);
export const addNewRow = actionCreator(Type.ADD_NEW_ITEM_ROW);
export const removeRow = actionCreator(Type.REMOVE_ITEM_ROW);
export const clearEntry = actionCreator(Type.CLEAR_ENTRY);
export const updateRecordPayment = actionCreator(Type.UPDATE_TRX_RECORD_PAYMENT);

export const updateEntry =
  (data: Entry): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(updateStockTransactionEntry(data));
  };

export const editOpeningStock =
  (data: Entry): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.EDIT_OPENING_STOCK, async () => {
        const res = await api.editOpeningStock(data);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Stock Transaction updated.",
              autoTimeout: true
            })
          );
          dispatch({ type: Type.CLEAR_ENTRY });
          dispatch(push(`/stock/stockTransactions/${res.id}`));
        } else {
          dispatch(push(`/stock/stockTransactions`));
        }
      })
    );
  };

export const saveStockAdjustment =
  (data: Entry): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.SAVE_STOCK_ADJUSTMENT, async () => {
        const res = await api.saveStockAdjustment(data);
        if (res?.id) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Successfully stock adjusted.",
              autoTimeout: true
            })
          );
          dispatch({ type: Type.CLEAR_ENTRY });
          dispatch(push(`/stock/stockTransactions/${res.id}`));
        } else {
          dispatch(push(`/stock/stockTransactions`));
        }
      })
    );
  };

export const updateEntryOnProductUpdate =
  (productId: number, productDetails: StockProducts): AppThunk =>
  (dispatch) => {
    dispatch({
      type: Type.UPDATE_STOCK_ENTRY_ON_PRODUCT_UPDATE,
      payload: { productId, productDetails }
    });
  };

export const postStockTransaction =
  (data: Entry): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.POST_STOCK_TRANSACTION, async () => {
        try {
          const res = await api.postStockTransaction(data);
          if (res?.id) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: "Stock Transaction created.",
                autoTimeout: true
              })
            );
            dispatch({ type: Type.CLEAR_ENTRY });
            dispatch(
              push(
                data.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
                  ? `/stock/kitchenPharmacy/${res.id}?tab=${CentralStoreTabs.STOCK_REQUESTED}`
                  : `/stock/stockTransactions/${res.id}`
              )
            );
          }
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: "Sorry, Something went wrong!.",
              autoTimeout: true
            })
          );
        }
      })
    );
  };

export const paymentCancellation =
  (data: PaymentHistoryProps): AppThunk =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_STOCK_TRANSACTION, async () => {
        try {
          await api.cancelPayment(data);
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Payment Cancellation Successful.",
              autoTimeout: true
            })
          );
          const response = await api.getStockTransaction({
            page: 0,
            pageSize: 100,
            filter: "all",
            search: ""
          });
          return response;
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: "Sorry, Can't save you payment cancellation.",
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );
  };

export const recordPayment =
  (trxData: PaymentInfoStateProps): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_STOCK_TRANSACTION, async () => {
        try {
          await api.recordPayment(trxData);
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("stock.recordPayment.successSaving"),
              autoTimeout: true
            })
          );
          const response = await api.getStockTransaction({
            page: 0,
            pageSize: 100,
            filter: "all",
            search: ""
          });
          return response;
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: error?.data?.message || "Sorry, cannot make your payment.",
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );
  };

export const getStocks = (): AppThunk => async (dispatch: IThunkDispatch) => {
  await dispatch(actionCreatorAsync(Type.GET_STOCKS, async () => api.getStocks()));
};

export const getStocksByProductId =
  (productId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_STOCKS_BY_PRODUCT_ID, async () =>
        api.getStockByProductId(productId)
      )
    );
  };

export const getStocksByProductIds =
  (productIds: number[]) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_STOCKS_BY_PRODUCT_IDS, async () =>
        api.getStockProductByIds(productIds)
      )
    );
  };

export const getStockTransactions =
  (query: QueryParams): AppThunk =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_STOCK_TRANSACTIONS, () => api.getStockTransaction(query))
    );
  };

export const updateStockSettings =
  (setting: DiscountSettings): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({ type: Type.UPDATE_STOCK_SETTINGS, payload: setting });

export const getRecordPaymentByTrxId =
  (id: number): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_RECORD_PAYMENT_BY_TRX_ID, () => api.getRecordPaymentByTrxId(id))
    );
  };

export const updateTrxRecordPayment =
  (id: number): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_TRX_RECORD_PAYMENT, () => api.getRecordPaymentByTrxId(id))
    );
  };

import React from 'react';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/material';
import './BillStatusChip.scss';
import { tl } from '../../../components/translate';
import classNames from '../../../helpers/classNames';

const BillStatusChip: React.FC<{ bill: any }> = ({ bill }) => {
  const status = bill.status || 'draft';
  return (
    <Box component="span" >
      <Chip
        className={classNames('billStatus', status)}
        size="small" label={tl(`billing.status.${status}`)} />
    </Box>
  );
};

export default BillStatusChip;
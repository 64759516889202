/* eslint-disable no-param-reassign */
import { uniq } from "lodash";
import { PieChartFormat } from ".";
import { AddressOptions } from "../../../../hooks/query/useAddressOption";
import { LabSummary } from "../../../../interfaces/Lab";
import { TestCountInterface } from "./RegionDescription";

export const getDistrictIdByName = (
  districtName: string,
  addressMasterData: AddressOptions[]
): number | undefined =>
  addressMasterData?.find((address) => address.districtName === districtName)?.districtId;

export const getPalikaIdByName = (
  palikaName: string,
  addressMasterData: AddressOptions[]
): number | undefined =>
  addressMasterData?.find((address) => address.palikaName === palikaName)?.palikaId;

export const getDistrictOfProvinceOne = (addressMasterData: AddressOptions[]): string[] =>
  [...Array(14)].map(
    (_, i) => addressMasterData.find((item) => item.districtId === i + 1)?.districtName || ""
  );

export const getPalikaNameById = (palikaId: number, addressOptions: AddressOptions[]): string =>
  addressOptions.find((address) => address.palikaId === Number(palikaId))?.palikaName || "";

export const calculateTestCountForPalika = (
  palikas: string[],
  palikaNameListForDistrict: string[],
  addressMasterData: AddressOptions[],
  labSummary: LabSummary[]
): TestCountInterface[] => {
  const data = [] as TestCountInterface[];
  (palikas.length === 1 && palikas.includes("All") ? palikaNameListForDistrict : palikas).forEach(
    (palika) => {
      if (palika !== "All") {
        const palikaId = getPalikaIdByName(palika, addressMasterData);
        const noOfCompleteLabTest = labSummary
          .filter((item) => item.palikaId === palikaId && item.labStatus === "Ready")
          .reduce((ac, cu) => ac + Number(cu.testCount), 0);
        const noOfIncompleteLabTest = labSummary
          .filter((item) => item.palikaId === palikaId && item.labStatus !== "Ready")
          .reduce((ac, cu) => ac + Number(cu.testCount), 0);
        if (noOfCompleteLabTest || noOfIncompleteLabTest)
          data.push({ noOfCompleteLabTest, noOfIncompleteLabTest, palikaName: palika });
      }
    }
  );
  return data;
};

export const getFilteredData = (
  labSummary: LabSummary[],
  addressMasterData: AddressOptions[],
  palikas: string[],
  gender: string
): LabSummary[] => {
  let filteredSummary = labSummary || [];
  const selectedPalikasIds = palikas
    .filter((item) => item !== "All")
    .map((item) => getPalikaIdByName(item, addressMasterData));
  if (selectedPalikasIds.length)
    filteredSummary = filteredSummary?.filter((labData) =>
      selectedPalikasIds.includes(labData.palikaId)
    );
  if (gender !== "all")
    filteredSummary = filteredSummary?.filter((labReport) => labReport.gender === gender);
  return filteredSummary;
};

export const getAgeStatChartData = (
  filteredSummary: LabSummary[]
): { ageGroup: string; male: number; female: number }[] => {
  const groupedData = filteredSummary.reduce((prev, curr) => {
    if (!prev[curr.ageRange]) {
      prev[curr.ageRange] = { male: 0, female: 0 };
    }
    prev[curr.ageRange][curr.gender] =
      Number(curr.testCount) + Number(prev[curr.ageRange][curr.gender]);
    return prev;
  }, {});
  return ["0-9", "10-19", "20-59", "60-"].map((item) => {
    const selectedData = groupedData[item] || { ageGroup: item, male: 0, female: 0 };
    return { ageGroup: item, male: selectedData.male, female: selectedData.female };
  });
};

export const getGenderCountChartData = (filteredSummary: LabSummary[]): PieChartFormat[] => {
  // Aggregate gender count
  const total = filteredSummary?.reduce(
    (totalCount, currItem) => {
      if (currItem.gender === "male") {
        totalCount.male += currItem.testCount;
      }
      if (currItem.gender === "female") {
        totalCount.female += currItem.testCount;
      }
      return totalCount;
    },
    { male: 0, female: 0 }
  );

  // Format data for chart
  const genders = [
    {
      name: "male",
      label: "Male"
    },
    {
      name: "female",
      label: "Female"
    }
  ];
  return genders.map((group) => ({ ...group, value: total[group.name] || 0 }));
};

export const formatDataForLineChart = (
  summaryData: LabSummary[],
  palikas: string[],
  addressOptions: AddressOptions[]
): { [key: string]: string | number }[] => {
  const selectedPalikas = palikas.includes("All")
    ? []
    : palikas.map((item) => getPalikaIdByName(item, addressOptions));
  const uniquePalikaIds = selectedPalikas.length
    ? selectedPalikas
    : uniq(summaryData.filter((item) => item.palikaId).map((item) => item.palikaId));
  const uniqueDates = uniq(summaryData.map((item) => new Date(item.date).getTime())).sort(
    (a, b) => a - b
  );
  const data = [] as { [key: string]: string | number }[];
  uniqueDates.forEach((date) => {
    const palikaTotalCount = {} as { [key: string]: string | number };
    uniquePalikaIds.forEach((palikaId) => {
      const totalCount = summaryData
        .filter((item) => new Date(item.date).getTime() === date && item.palikaId === palikaId)
        .reduce((acc, curr) => acc + curr.testCount, 0);
      const key = `testCount${palikaId}`;
      palikaTotalCount[key] = totalCount;
    });
    data.push({ ...palikaTotalCount, date });
  });
  return data;
};

/* eslint-disable camelcase */
import { Box, Typography } from "@mui/material";
import React from "react";
import Panel from "../../../components/Panel";
import { tl } from "../../../components/translate";
import { HmisReportType, HmisSummary } from "../../../interfaces/HmisInterface";
import HmisTableDetails from "./HmisTableDetails";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import EthnicityReportDetails from "./EthnicityReportDetails";

export default function HmisDetailView({
  data,
  onClose
}: {
  data: HmisSummary;
  onClose: () => void;
}): JSX.Element | null {
  if (!data) return null;
  const { period, hmisFormData, createdAt, reportType } = data;
  const keyValues = [
    { label: tl("assessment.month"), value: `${period.slice(0, 4)}-${period.slice(4)}`, idx: 1 },
    {
      label: tl("services.createdDate"),
      value: convertADtoBS(new Date(createdAt)).formatted4,
      idx: 2
    },
    { label: tl("hmis.categoryName"), value: `${hmisFormData?.categoryName || ""}`, idx: 3 }
  ];
  return (
    <Panel title={tl("hmis.details")} onClose={onClose}>
      <Box m={3}>
        {keyValues.map((item) => (
          <Box key={item.idx} display="flex" mb="5px">
            <Typography width="140px">{item.label}</Typography>
            <Typography>
              {item.value ? ":" : ""} {item.value}
            </Typography>
          </Box>
        ))}
        {reportType === HmisReportType.REPORT_TYPE_1 || !reportType ? (
          <HmisTableDetails data={data} />
        ) : (
          <EthnicityReportDetails data={data} />
        )}
      </Box>
    </Panel>
  );
}

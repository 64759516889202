import * as React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import useReferrers from "../../hooks/userReferrers";

const ReferrersAutoSelect = ({
  multiple = true,
  onChange,
  isDisabled = false,
  variant = "standard"
}: {
  multiple: boolean;
  onChange: (referrer) => void;
  isDisabled: boolean;
  variant: "standard" | "filled" | "outlined";
}): JSX.Element => {
  const referrers = useReferrers();
  return (
    <Autocomplete
      multiple={multiple}
      options={referrers}
      getOptionLabel={(option) => option.referrer}
      onChange={(e, values) => {
        onChange(values);
      }}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {option.referrer}
        </li>
      )}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant={variant}
          label="Referrers"
          placeholder="Search and select referrers"
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

export default ReferrersAutoSelect;

import React from "react";
import { Box } from "@mui/material";
import Headers, { Reports } from "../Headers";
import TrialBalanceList from "./TrialBalanceList";
import useTrialBalance from "./hooks/useTrialBalance";
import Filters from "../Filters";
import { t } from "../../../../components/translate";

const TrialBalance = (): React.ReactElement => {
  const [filters, setFilters] = React.useState({
    periodDate: new Date(),
    showExtraColumns: false,
    showTrialBalanceSummary: true
  });
  const data = useTrialBalance(filters);

  const headers = [
    { key: "glCode", label: `${t("GLCode")}` },
    { key: "glDescription", label: `${t("GLDesc")}`, className: "flex-1" },
    ...(filters.showExtraColumns
      ? [
          { key: "openingDr", label: "Opening Dr" },
          { key: "openingCr", label: "Opening Cr" },
          { key: "duringThePeriodDr", label: "During The Period Dr" },
          { key: "duringThePeriodCr", label: "During The Period Cr" }
        ]
      : []),
    {
      key: "closingDebit",
      label: `${t("closingDebit")}`,
      className: `${filters.showExtraColumns ? "" : "center-text"}`
    },
    {
      key: "closingCredit",
      label: `${t("closingCredit")}`,
      className: `${filters.showExtraColumns ? "" : "center-text"}`
    }
  ];
  return (
    <Box className="trialBalanceReport">
      <Box minWidth="900px">
        <Box margin="0 2rem">
          <Filters report={Reports.TRIAL_BALANCE} filters={filters} setFilters={setFilters} />
        </Box>
        <Headers filters={filters} report={Reports.TRIAL_BALANCE} headers={headers} data={data} />
        <Box
          sx={{
            height: "calc(100vh - 230px)",
            overflow: "auto",
            scrollbarGutter: "stable"
          }}
        >
          <TrialBalanceList filters={filters} data={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default TrialBalance;

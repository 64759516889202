import React from "react";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { push } from "connected-react-router";
import { match as MatchProps } from "react-router";
import { Box, IconButton, Typography } from "@mui/material";

import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import { rupeeDisplay } from "../../helpers/rupee";
import OkhatiDialog from "../../components/Dialog/Dialog";
import useMobileScreen from "../../hooks/useMobileScreen";
import { DaybookInterface } from "../../interfaces/Daybook";
import { notificationAdd } from "../../actions/notification";
import { deleteDaybookEntry, getDaybookEntryById } from "../../api/daybook";
import { DaybookParticulars } from "../../enum/Daybook";

interface Props {
  onClose: () => void;
  match?: MatchProps;
  selectedRow: Partial<DaybookInterface> | null;
  daybookData: { results: DaybookInterface[]; total: number };
  isInfoRenderedInReport?: boolean;
  setDaybookData: React.Dispatch<
    React.SetStateAction<{ results: DaybookInterface[]; total: number }>
  >;
}

const DaybookEntryInfo = ({
  onClose,
  selectedRow,
  match,
  daybookData,
  setDaybookData,
  isInfoRenderedInReport = false
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isMobileScreen = useMobileScreen();
  const daybookEntryId = Number(match?.params?.id || selectedRow?.id);
  const [daybookEntry, setDaybookEntry] = React.useState(selectedRow || null);
  const [dialogToggle, setDialogToggle] = React.useState(false);
  const dialogToggler = () => setDialogToggle(!dialogToggle);

  const deleteDaybookEntryFn = async () => {
    try {
      if (!daybookEntryId) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to delete request",
            autoTimeout: true
          })
        );
        return;
      }

      await deleteDaybookEntry(daybookEntryId);
      setDaybookData((prev) => ({
        ...prev,
        total: prev.total === 0 ? 0 : prev.total - 1,
        results: daybookData.results.filter((item) => item.id !== daybookEntryId)
      }));
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Request deleted successfully",
          autoTimeout: true
        })
      );
      onClose();
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to delete",
          autoTimeout: true
        })
      );
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const res = await getDaybookEntryById(daybookEntryId);
        setDaybookEntry(res);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get daybook entry",
            autoTimeout: true
          })
        );
      }
    })();
  }, [selectedRow, dispatch, daybookEntryId]);

  /**
   * Leaving it commented for now, might have to use in future.
   */

  // const deleteButton = daybookEntryId ? (
  //     <IconButton
  //         onClick={() => setDialogToggle(!dialogToggle)}
  //     >
  //         <Delete />
  //     </IconButton>
  // ) : null;

  const editButton =
    daybookEntryId && !isInfoRenderedInReport ? (
      <IconButton
        onClick={() => dispatch(push(`${match?.url}/edit`))}
        data-testmation="daybookEdit"
      >
        <Edit />
      </IconButton>
    ) : null;

  return (
    <Panel onClose={() => onClose()} editButton={editButton}>
      {dialogToggle && (
        <OkhatiDialog
          title={tl("daybook.deleteDaybookEntry")}
          description="Are you sure you want to delete this entry?"
          next={deleteDaybookEntryFn}
          cancel={dialogToggler}
          readMode={false}
        />
      )}
      <Box
        pt={4}
        display="flex"
        flexDirection="column"
        gap="5px"
        px={`${isMobileScreen ? "16px" : "32px"}`}
      >
        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("daybook.particulars")}
          </Typography>
          <Typography>{daybookEntry?.particulars ?? ""}</Typography>
        </Box>

        {daybookEntry?.particulars !== DaybookParticulars.OPENING_BALANCE && (
          <Box display="flex" alignItems="center">
            <Typography width="30%" fontWeight={600}>
              {tl("daybook.transactionType")}
            </Typography>
            <Typography>{daybookEntry?.transactionType ?? ""}</Typography>
          </Box>
        )}

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("daybook.amount")}
          </Typography>
          <Typography>{rupeeDisplay(daybookEntry?.amount ?? "")}</Typography>
        </Box>

        {daybookEntry?.particulars !== DaybookParticulars.OPENING_BALANCE && (
          <Box display="flex" alignItems="center">
            <Typography width="30%" fontWeight={600}>
              {tl("daybook.enteredBy")}
            </Typography>
            <Typography>{daybookEntry?.creator?.name ?? ""}</Typography>
          </Box>
        )}

        {daybookEntry?.remarks && (
          <Box display="flex" alignItems="flex-start">
            <Typography width="30%" flexShrink={0} fontWeight={600}>
              {tl("daybook.remarks")}
            </Typography>
            <Typography flexGrow={1} sx={{ wordBreak: "break-word" }}>
              {daybookEntry.remarks}
            </Typography>
          </Box>
        )}
      </Box>
    </Panel>
  );
};

export default DaybookEntryInfo;

import actionCreatorFactory from 'typescript-fsa';

export const actionCreator = actionCreatorFactory();

export enum Type {
  ONGOING_ADD = 'ONGOING_ADD',
  ONGOING_REMOVE = 'ONGOING_REMOVE'
};

export const ongoingAdd = actionCreator(Type.ONGOING_ADD);
export const ongoingRemove = actionCreator(Type.ONGOING_REMOVE);
import React from "react";
import PhoneNumber from "awesome-phonenumber";
import { Box } from "@mui/material";
import MuiPhoneNumber from "../PhoneNumber";
import { tl } from "../translate";
import hasOwnProperty from "../../helpers/object";
import { isStartWith970, isNewPhoneNumber } from "../../helpers/phoneNumber";

const InputPhoneNumber = ({
  data,
  field,
  isFocused,
  isBlurred,
  error,
  errorMessages,
  onChange
}: {
  data: { field: { key: string } };
  field: { key: string; inputType: string; required: boolean; label: string };
  isFocused: () => void;
  isBlurred: () => void;
  error: boolean;
  errorMessages: string[];
  onChange: (data: string) => void;
}): JSX.Element => {
  const [phone, setPhone] = React.useState(data[field.key]);
  const [showError, setShowError] = React.useState(false);
  const requireMobileNumber = hasOwnProperty(field, "mobileNumber") ? field.mobileNumber : false;

  const helperText = () => {
    if (error) {
      errorMessages.map((er) => (
        <div key={er}>
          - {er} <br />
        </div>
      ));
    }
    if (showError) {
      return requireMobileNumber ? "- Invalid mobile number" : "- Invalid phone number";
    }
    return "";
  };

  return (
    <Box mb={1}>
      <MuiPhoneNumber
        fullWidth
        disableAreaCodes
        margin="dense"
        variant="outlined"
        defaultCountry="np"
        key={field.key}
        label={tl(field.label)}
        data-testmation={field.key}
        onFocus={() => isFocused()}
        onBlur={() => {
          isBlurred();
        }}
        type={field.inputType || ""}
        value={phone}
        required={hasOwnProperty(field, "required") ? field.required : true}
        preferredCountries={["NP", "IN"].map((countryCode) => countryCode.toLowerCase())}
        autoFormat={false}
        error={error || showError}
        helperText={helperText()}
        onChange={(phNumber, value) => {
          let isValid;
          const ph = new PhoneNumber(phNumber, value.countryCode);
          const countryCode = ph.getCountryCode();
          if (value.countryCode?.length > 0) {
            if (requireMobileNumber) isValid = ph.isValid() && ph.isMobile();
            else isValid = ph.isValid();
          } else isValid = false;
          if (countryCode === phNumber.split("+")[1]) {
            onChange("");
            setShowError(false);
          } else {
            const phoneNumber = ph.getNumber("significant");
            if (
              value.countryCode === "np" &&
              isStartWith970(phNumber) &&
              isNewPhoneNumber(phoneNumber)
            ) {
              isValid = true;
            }
            onChange(phNumber);
            setShowError(!isValid);
          }
          setPhone(phNumber);
        }}
      />
    </Box>
  );
};
export default InputPhoneNumber;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { slotFromTimeSelector, slotUntilTimeSelector } from '../../reducers/slots';
import Popper from '../../components/Popper/Popper';
import Dialog from '@mui/material/Dialog';
import BookingInfo from './BookingInfo';
import BookingCreateEdit from './BookingCreateEdit';
import FaceIcon from '@mui/icons-material/Face';
import WatchIcon from '@mui/icons-material/Schedule';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useMobileScreen from '../../hooks/useMobileScreen';
import BookingProps from './Bookings/BookingProps';
import classNames from '../../helpers/classNames';
import styles from './styles.module.css';
import { isEventOnCalendarDropdown } from './EventPill';
import { clientFullNameSelector } from '../../reducers/client';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ResponsivePopperOrModal: React.FC<React.PropsWithChildren<{}>> = ({ children, ...props }) => {
  const isMobileScreen = useMobileScreen();
  if (isMobileScreen) {
    return (<Dialog open={props.open}
      TransitionComponent={Transition}
      fullScreen={isMobileScreen}>
      {children}
    </Dialog>)
  }
  return <Popper {...props}>
    {children}
  </Popper>
}

const SingleBookedSlot = ({ event, style }) => {
  const { bookings } = event;
  const { client, reasonOfVisit } = bookings[0];
  const pillRef = React.useRef(null);
  const [infoOpen, toggleOpen] = React.useState(false);
  const [editOpen, toggleEditOpen] = React.useState(false);

  return (
    <>
      <div ref={pillRef} className={infoOpen ? styles.highlightedBookedPill : ''}>
        <Box
          component="div"
          style={style}
          onClick={() => toggleOpen(true)}
          className={classNames(styles.event, styles.bookedPill, {
            [styles.booked]: bookings[0].status === 'reserved',
            [styles.handled]: bookings[0].status === 'handled',
            [styles.referred]: bookings[0].status === 'referred',
          })}
        >
          <Typography>
            <Box
              className={styles.alignVerticallyMiddle}
              component={'span'}
              fontSize={'0.800rem'}
              fontWeight={400}
            >
              <WatchIcon className={styles.icon} />
              {slotFromTimeSelector(event)} - {slotUntilTimeSelector(event)}
            </Box>
          </Typography>
          <Typography component={'div'}>
            <Box
              className={styles.alignVerticallyMiddle}
              component={'span'}
              fontSize={'0.875rem'}
              fontWeight={500}
              display={'flex'}
            >
              <Box>
                <FaceIcon className={styles.icon} />
              </Box>
              <Box style={{ maxHeight: '1.5em', overflow: 'hidden' }}>
                {clientFullNameSelector(client)}
              </Box>
            </Box>
          </Typography>
          <Typography component={'div'} style={{ maxHeight: '3em', overflow: 'hidden' }}>
            <Box component={'span'} fontSize={'0.800rem'} fontWeight={400}>
              {reasonOfVisit || '---'}
            </Box>
          </Typography>
          <BookingProps booking={bookings[0]} />
        </Box>
      </div>
      {infoOpen && (
        <ResponsivePopperOrModal onClose={() => toggleOpen(false)} open={infoOpen} anchorEl={() => pillRef}>
          <BookingInfo
            event={event}
            onClose={() => toggleOpen(false)}
            editBooking={() => {
              toggleOpen(false);
              toggleEditOpen(true);
            }}
          />
        </ResponsivePopperOrModal>
      )}
      {editOpen && (
        <ResponsivePopperOrModal
          open={editOpen}
          anchorEl={() => pillRef}
          onClose={() => {
            if (!isEventOnCalendarDropdown) {
              toggleEditOpen(false);
            }
          }}
        >
          <BookingCreateEdit
            booking={event.bookings[0]}
            next={() => toggleEditOpen(false)}
            onClose={() => toggleEditOpen(false)}
            serviceProviderId={event.serviceProviderId}
            slotId={event.id}
          />
        </ResponsivePopperOrModal>
      )}
    </>
  );
};

export default SingleBookedSlot;

import React from "react";
import { Theme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabsProps } from "@mui/material/Tabs/Tabs";

export const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: TabsProps) => <Tabs {...props} variant="scrollable" scrollButtons="auto" />);

export const StyledTab = withStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        textTransform: "none",
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4)
      },
      selected: {}
    })
  // eslint-disable-next-line react/jsx-props-no-spreading
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
}

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import produce from "immer";
import CloseIcon from "@mui/icons-material/Close";
import { tl } from "../../../components/translate";
import QuestionnairePopUp from "./QuestionnairePopUp";
import styles from "../Assessment.module.css";
import { QUESTIONNAIRE } from "./QuestionnaireRow";
import { Questionnaire } from "../../../interfaces/AssessmentInterfaces";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

function applyQuestionnaireTypes<T>(assessmentQuestionnaire: T[]): T[] {
  return assessmentQuestionnaire?.map((item) => {
    const relatedType =
      QUESTIONNAIRE.find((question) => question.name === item.name)?.type || "text";
    return { ...item, type: relatedType };
  });
}

const QuestionnaireResponse = ({
  assessmentQuestionnaire,
  onChange
}: {
  assessmentQuestionnaire: Questionnaire[];
  onChange: (data) => void;
}): JSX.Element => {
  const previousQuestionnaire = applyQuestionnaireTypes(assessmentQuestionnaire);
  const [showDialog, setShowDialog] = React.useState(false);
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow} height="auto" alignItems="flex-start">
      <Box className={styles.assessmentLabel} pt="4px">
        <Typography component="span" fontWeight="600">
          {assessmentLabels.questionnaire}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        {previousQuestionnaire?.map((v, index) => (
          <Box key={v.name} display="flex" justifyContent="space-between">
            <Typography>{`${v.name} ${v.reading} ${v.unit}`}</Typography>
            <CloseIcon
              data-testmation="questionnaire.RemoveButton"
              style={{ cursor: "pointer" }}
              onClick={() => {
                const updatedQuestionnaire = produce(previousQuestionnaire, (draft) => {
                  draft.splice(index, 1);
                });
                onChange(updatedQuestionnaire);
              }}
            />
          </Box>
        ))}
        <Box
          data-testmation="questionnaire.AddNewButton"
          onClick={() => setShowDialog(true)}
          style={{ cursor: "pointer" }}
        >
          <Button size="small">
            <u>{tl("assessment.addNew")}</u>
          </Button>
        </Box>
      </Box>
      <QuestionnairePopUp
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        previousQuestionnaire={previousQuestionnaire}
        onSave={onChange}
      />
    </Box>
  );
};

export default QuestionnaireResponse;

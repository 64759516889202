/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { isAuthenticated } from "../auth/authentication";

const authenticateSlice = createSlice({
  name: "authenticated",
  initialState: isAuthenticated() as boolean,
  reducers: {
    logIn() {
      return true;
    },
    logOut() {
      return false;
    }
  }
});

export const { logIn, logOut } = authenticateSlice.actions;
export default authenticateSlice.reducer;

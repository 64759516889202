import React, { useState } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  Radio
} from "@mui/material";
import produce from "immer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useDispatch } from "react-redux";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { resourceCentreActions } from "../../../actions";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { removeGeneralErrorByKey } from "../../../actions/error";
import { ReverseMap } from "../../../helpers/types";
import EditableTable, { TableState } from "../../../components/EditableTable/EditableTable";
import { SORT_ORDER, SortOrderType } from "../../Assessment/AssessmentForm/OPDComponents";
import HtmlTooltip from "../../../components/HtmlTooltip";

const orderOPDComponentLabels = ({
  componentLabels,
  sortOrder
}: {
  componentLabels: string[];
  sortOrder: SortOrderType[];
}): SortOrderType[] =>
  componentLabels
    .concat(["tabular"])
    .sort(
      (a, b) => sortOrder.indexOf(a as SortOrderType) - sortOrder.indexOf(b as SortOrderType)
    ) as SortOrderType[];

export enum ORDER_ACTION {
  UP = "up",
  DOWN = "down"
}

export type OrderActionType = ReverseMap<typeof ORDER_ACTION>;

export const OrderButtons = ({
  onClick,
  isDisabled
}: {
  isDisabled: {
    up: boolean;
    down: boolean;
  };
  onClick: {
    up: () => void;
    down: () => void;
  };
}): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 0.5,
      width: "100px"
    }}
  >
    <IconButton title="Move upward" color="primary" onClick={onClick.up} disabled={isDisabled.up}>
      <ArrowUpwardIcon />
    </IconButton>
    <IconButton
      title="Move downward"
      color="primary"
      onClick={onClick.down}
      disabled={isDisabled.down}
    >
      <ArrowDownwardIcon />
    </IconButton>
  </Box>
);

interface LabelInputFieldProps {
  value: string;
  onFocus: () => void;
  onChange: (e) => void;
  disabled?: boolean;
}

const LabelInputField = ({ value, disabled = false, onFocus, onChange }: LabelInputFieldProps) => (
  <TextField
    variant="outlined"
    value={value}
    onFocus={onFocus}
    onChange={onChange}
    disabled={disabled}
    sx={{ width: "240px" }}
  />
);

const AssessmentLabels = ({ resourceCentre }: { resourceCentre: ResourceCentre }): JSX.Element => {
  const [settings, setSettings] = useState(resourceCentre.settings.assessmentSettings);
  const [isDirty, setIsDirty] = useState(false);

  const [showTable, setShowTable] = useState(
    resourceCentre.settings.assessmentSettings.components.tabular
  );

  const [orderedComponentLabels, setOrderedComponentLabels] = useState(
    orderOPDComponentLabels({
      componentLabels:
        Object.keys(resourceCentre.settings.assessmentSettings.componentLabels || {}) || [],
      sortOrder: resourceCentre.settings.assessmentSettings.sortOrder || []
    })
  );

  const handleOrdering = ({ action, label }: { action: OrderActionType; label: SortOrderType }) => {
    const isLabLabel = label === SORT_ORDER.LAB_TEST;
    const currentIndex = orderedComponentLabels.indexOf(label);

    const isUpwardLabLabel = orderedComponentLabels[currentIndex - 1] === SORT_ORDER.SERVICE;
    const isDownwardLabLabel = orderedComponentLabels[currentIndex + 1] === SORT_ORDER.LAB_TEST;

    let updatedIndex: number[];

    if (action === ORDER_ACTION.UP) {
      if (isLabLabel) {
        updatedIndex = [currentIndex - 1, currentIndex];
      } else {
        updatedIndex = isUpwardLabLabel ? [currentIndex - 2] : [currentIndex - 1];
      }
    } else if (action === ORDER_ACTION.DOWN) {
      if (isLabLabel) {
        updatedIndex = [currentIndex + 1, currentIndex + 2];
      } else {
        updatedIndex = isDownwardLabLabel ? [currentIndex + 2] : [currentIndex + 1];
      }
    }

    setOrderedComponentLabels(() =>
      produce(orderedComponentLabels, (draft) => {
        draft.splice(currentIndex, 1);
        if (isLabLabel) {
          draft.splice(currentIndex, 1);
        }
        draft.splice(updatedIndex[0], 0, label);
        if (isLabLabel) {
          draft.splice(updatedIndex[1], 0, SORT_ORDER.SERVICE);
        }
      })
    );
  };

  const [errorMessage, setErrorMessage] = React.useState("");
  const dispatch = useDispatch();

  const handleIsDirty = () => {
    if (!isDirty) setIsDirty(true);
  };

  return (
    <Box ml={2}>
      <Typography variant="h6" my={2}>
        Manage Components
      </Typography>
      {errorMessage && (
        <Typography color="red" my={2}>
          {errorMessage}
        </Typography>
      )}
      <Box display="flex" mb={2} gap={2}>
        <Typography width="260px" variant="body1" fontWeight="bold">
          Components
        </Typography>
        <Typography width="240px" variant="body1" fontWeight="bold">
          Labels
        </Typography>
        <Typography width="100px" variant="body1" fontWeight="bold">
          Ordering
        </Typography>
      </Box>
      <Box sx={{ height: "30rem", overflowY: "scroll", maxWidth: "41rem" }}>
        {orderedComponentLabels.map((componentLabel, index) => {
          if (componentLabel === SORT_ORDER.TABULAR) {
            return (
              <div key={componentLabel}>
                <Box display="flex" my={1} gap={2}>
                  <FormControlLabel
                    sx={{ width: "260px" }}
                    label="Tabular Component"
                    control={
                      <Checkbox
                        checked={settings.components.tabular}
                        onChange={() => {
                          handleIsDirty();
                          setShowTable(!settings.components.tabular);
                          const newState = produce(settings, (draft) => {
                            draft.components.tabular = !settings.components.tabular;
                          });
                          setSettings(newState);
                        }}
                      />
                    }
                  />

                  <LabelInputField
                    value={settings.tabularComponent.title}
                    onFocus={() => setErrorMessage("")}
                    onChange={(e) => {
                      handleIsDirty();
                      if (e.target.value.length > 40) {
                        setErrorMessage("**Label names can not exceed more than 40 characters");
                        return;
                      }
                      setErrorMessage("");
                      setSettings(() =>
                        produce(settings, (draft) => {
                          draft.tabularComponent.title = e.target.value;
                        })
                      );
                    }}
                  />
                  <OrderButtons
                    isDisabled={{ up: index < 2, down: index > orderedComponentLabels.length - 2 }}
                    onClick={{
                      up: () => {
                        handleIsDirty();
                        handleOrdering({
                          action: ORDER_ACTION.UP,
                          label: componentLabel
                        });
                      },
                      down: () => {
                        handleIsDirty();
                        handleOrdering({
                          action: ORDER_ACTION.DOWN,
                          label: componentLabel
                        });
                      }
                    }}
                  />
                </Box>
                {showTable && (
                  <Box
                    sx={{
                      display: "inline-flex",
                      gap: 2,
                      p: 1,
                      my: 1,
                      ml: 3.75,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      component: "fieldset",
                      border: "1px solid rgba(0,0,0,0.12)",
                      borderRadius: "0.23rem"
                    }}
                  >
                    <Typography px={2} py={1}>
                      Customize Tabular Component
                    </Typography>
                    <EditableTable
                      maxColCount={10}
                      onChange={(value: TableState) => {
                        const newState = produce(settings, (draft) => {
                          draft.tabularComponent = value;
                        });
                        setSettings(newState);
                        setIsDirty(true);
                      }}
                      initValue={settings.tabularComponent}
                      hideControls={false}
                      hideLabel
                    />
                  </Box>
                )}
              </div>
            );
          }
          if (componentLabel === SORT_ORDER.ATTACHMENTS) {
            return (
              <Box key={componentLabel} display="flex" my={1} gap={2}>
                <FormControlLabel
                  sx={{
                    width: "260px",
                    "& .MuiFormControlLabel-label.Mui-disabled": { color: "text.primary" }
                  }}
                  label={settings.componentLabels[componentLabel]?.defaultLabel}
                  control={
                    <Checkbox
                      // attachment can only be enabled/disabled from superadmin
                      disabled
                      checked={settings.components.attachments}
                      onChange={() => null}
                    />
                  }
                />

                <LabelInputField
                  disabled={!settings.components.attachments}
                  value={
                    settings.componentLabels[componentLabel]?.customLabel ||
                    settings.componentLabels[componentLabel]?.defaultLabel
                  }
                  onFocus={() => setErrorMessage("")}
                  onChange={(e) => {
                    handleIsDirty();
                    if (e.target.value.length > 40) {
                      setErrorMessage("**Label names can not exceed more than 40 characters");
                      return;
                    }
                    setErrorMessage("");
                    setSettings(() =>
                      produce(settings, (draft) => {
                        draft.componentLabels.attachments = {
                          ...settings.componentLabels.attachments,
                          customLabel: e.target.value
                        };
                      })
                    );
                  }}
                />
                <OrderButtons
                  isDisabled={{ up: index < 2, down: index > orderedComponentLabels.length - 2 }}
                  onClick={{
                    up: () => {
                      handleIsDirty();
                      handleOrdering({
                        action: ORDER_ACTION.UP,
                        label: componentLabel
                      });
                    },
                    down: () => {
                      handleIsDirty();
                      handleOrdering({
                        action: ORDER_ACTION.DOWN,
                        label: componentLabel
                      });
                    }
                  }}
                />
              </Box>
            );
          }

          return (
            <Box key={componentLabel} display="flex" my={1} gap={2}>
              {[SORT_ORDER.LAB_TEST, SORT_ORDER.SERVICE].includes(componentLabel) ? (
                <Box sx={{ width: "265px", display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    label={settings.componentLabels[componentLabel]?.defaultLabel}
                    control={
                      <Radio
                        checked={settings.components[componentLabel]}
                        onChange={() => {
                          handleIsDirty();
                          const newState = produce(settings, (draft) => {
                            if (componentLabel === SORT_ORDER.LAB_TEST) {
                              draft.components.labTest = true;
                              draft.components.service = false;
                            } else {
                              draft.components.labTest = false;
                              draft.components.service = true;
                            }
                          });
                          setSettings(newState);
                        }}
                      />
                    }
                  />
                  <HtmlTooltip
                    description={
                      componentLabel === SORT_ORDER.LAB_TEST
                        ? "Lab tests will be created from the opd if any test is selected."
                        : "A draft bill will be created with every selected service as item in the bill."
                    }
                  />
                </Box>
              ) : (
                <FormControlLabel
                  sx={{ width: "260px" }}
                  label={settings.componentLabels[componentLabel]?.defaultLabel}
                  control={
                    <Checkbox
                      checked={settings.components[componentLabel]}
                      onChange={() => {
                        handleIsDirty();
                        const newState = produce(settings, (draft) => {
                          draft.components[componentLabel] = !settings.components[componentLabel];
                        });
                        setSettings(newState);
                      }}
                    />
                  }
                />
              )}

              <LabelInputField
                value={
                  settings.componentLabels[componentLabel]?.customLabel ||
                  settings.componentLabels[componentLabel]?.defaultLabel
                }
                onFocus={() => setErrorMessage("")}
                onChange={(e) => {
                  handleIsDirty();
                  if (e.target.value.length > 40) {
                    setErrorMessage("**Label names can not exceed more than 40 characters");
                    return;
                  }
                  setErrorMessage("");

                  setSettings(() =>
                    produce(settings, (draft) => {
                      draft.componentLabels[componentLabel] = {
                        ...settings.componentLabels[componentLabel],
                        customLabel: e.target.value
                      };
                    })
                  );
                }}
              />
              {/* opd duration is always needs to be on top and service is tied with labtest, so hiding controls */}
              {!["opdDuration", "service"].includes(componentLabel) ? (
                <OrderButtons
                  isDisabled={{
                    up: index < 2,
                    down:
                      index >
                      orderedComponentLabels.length -
                        (componentLabel === SORT_ORDER.LAB_TEST ? 3 : 2)
                  }}
                  onClick={{
                    up: () => {
                      handleIsDirty();
                      handleOrdering({
                        action: ORDER_ACTION.UP,
                        label: componentLabel
                      });
                    },
                    down: () => {
                      handleIsDirty();
                      handleOrdering({
                        action: ORDER_ACTION.DOWN,
                        label: componentLabel
                      });
                    }
                  }}
                />
              ) : (
                <Box minWidth={75} />
              )}
            </Box>
          );
        })}
      </Box>
      <div>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          disabled={!isDirty || Boolean(errorMessage)}
          onClick={() => {
            setIsDirty(false);
            const orderedComponentLabelsWithoutOpdDuration = produce(
              orderedComponentLabels,
              (componentsDraft) => {
                // remove opdDuration from sortOrder as we dont want to include it in orders settings
                const opdDurationIndex = componentsDraft.indexOf("opdDuration");
                if (opdDurationIndex > -1) {
                  componentsDraft.splice(opdDurationIndex, 1);
                }
                return componentsDraft;
              }
            );
            const updatedRc = produce(resourceCentre, (draft) => {
              draft.settings.assessmentSettings = {
                ...draft.settings.assessmentSettings,
                ...settings,
                sortOrder: orderedComponentLabelsWithoutOpdDuration
              };
            });
            dispatch(async (dispatchInner, getState) => {
              await dispatchInner(resourceCentreActions.putResourceCentre(updatedRc));
              const currentState = getState();
              const errorObject = currentState.error.filter(
                ({ key }) => key === "PUT_RESOURCECENTRE"
              );
              if (errorObject.length) {
                setIsDirty(true);
                dispatchInner(
                  notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "error",
                    message: errorObject[0].message || commonErrorMessage,
                    autoTimeout: true
                  })
                );
                dispatchInner(removeGeneralErrorByKey("PUT_RESOURCECENTRE"));
              } else {
                dispatchInner(
                  notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "success",
                    message: "Assessment Labels updated successfully.",
                    autoTimeout: true
                  })
                );
              }
            });
          }}
        >
          Save Changes
        </Button>
      </div>
    </Box>
  );
};

export default AssessmentLabels;

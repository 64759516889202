/* eslint-disable camelcase */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { tl } from "../../components/translate";
import EventPopup from "../Calendar/PopUp";
import { clientFullNameSelector } from "../../reducers/client";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import { eventsSelector } from "../../reducers/bookings";

export const StatusBackgroundMap = {
  cancelled: "#C81111",
  confirmed: "#2250C8",
  handled: "#009654",
  referred: "#7522C8",
  reserved: "#FFFFFF",
  unhandled: "#d97016",
  waiting: "#504F4F",
  engaged: "#9C970E"
};

const getFlowStatus = ({ status, end }) => {
  const currentTime = new Date().getTime();
  const r_until = new Date(end).getTime();
  let flowStatus = status;
  if ((status === "confirmed" || status === "reserved") && currentTime > r_until) {
    flowStatus = "unhandled";
  }
  return flowStatus;
};

const FlowItem = ({ booking, bookingState, afterUpdate, events }) => {
  const [showBooking, setShowBooking] = React.useState(false);
  const anchorEl = React.useRef();
  const handleClick = (e) => {
    setShowBooking(true);
    e.stopPropagation();
  };
  const [editOpen, toggleEditOpen] = React.useState(false);
  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);

  const handleViewClose = () => {
    setClientInfoPanel(false);
  };
  return (
    <>
      <Box
        ref={anchorEl}
        className={`FlowItem ${booking.status} ${editOpen ? "selected" : ""}`}
        onClick={handleClick}
        style={{ padding: 0, borderRadius: "5px" }}
      >
        {bookingState?.isConfirmed && (bookingState.isCurrent || bookingState.isNext) && (
          <Box
            display="flex"
            alignItems="center"
            bgcolor={`${bookingState.isNext ? StatusBackgroundMap.confirmed : "white"}`}
            color={`${bookingState.isCurrent ? StatusBackgroundMap.confirmed : "white"}`}
            fontSize="12px"
            borderRight="1px solid lightgrey"
            position="relative"
            borderRadius="5px 0px 0px 5px"
            width="24px"
          >
            <Box className="ChipLabel" position="absolute" padding={0} left="-14px" margin="auto">
              {bookingState.isCurrent && (
                <Typography>
                  <Box component="span" fontWeight="bold" fontSize="12px">
                    Ongoing
                  </Box>
                </Typography>
              )}
              {bookingState.isNext && (
                <Typography>
                  <Box pl="12px" pr="12px" fontWeight="bold" component="span" fontSize="12px">
                    Next
                  </Box>
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <div className="FlowItem__info" style={{ float: "right", padding: 10 }}>
          <Box display="flex" flexDirection="column" style={{ float: "left" }}>
            <Typography>
              <Box component="span" fontWeight="bold" height={22}>
                {moment(booking.start).format("h:mm a")}{" "}
                {booking.throughPublic && (
                  <Typography style={{ display: "inline-block", marginLeft: "16px" }}>
                    <Box fontSize="0.675rem" fontWeight={500}>
                      Public Booking
                    </Box>
                  </Typography>
                )}
              </Box>
            </Typography>

            <Box className="FlowItem__status" marginTop={1}>
              <Box className="StatusText" bgcolor={StatusBackgroundMap[getFlowStatus(booking)]}>
                {getFlowStatus(booking)}
              </Box>
              {booking.followUp && (
                <Box
                  className="StatusText"
                  component="span"
                  ml="5px"
                  borderRadius="3px"
                  border="1px solid #707070"
                  style={{ color: "#585858" }}
                  fontSize="12px"
                >
                  {tl("booking.followUp")}
                </Box>
              )}
            </Box>
          </Box>

          <Box
            fontWeight={500}
            style={{
              float: "right",
              display: "flex",
              flexDirection: "column",
              flexBasis: 50,
              textAlign: "right"
            }}
          >
            <Typography
              style={{
                textDecoration: `${booking.client.active ? "underline" : ""}`,
                cursor: "pointer"
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (booking.client.active) {
                  setClientInfoPanel(true);
                }
              }}
            >
              {`${clientFullNameSelector(booking.client)} ${
                !booking.client.active ? "(deleted)" : ""
              }`}
            </Typography>
            <Typography>
              <Box
                className="serviceProvider"
                fontWeight={500}
                fontSize="12px"
                marginTop={1}
                component="span"
              >
                {booking.serviceProvider && (
                  <>
                    {spFullNameSelector(booking.serviceProvider)}
                    <br />
                  </>
                )}
                {booking.bookableResource && booking.bookableResource.name}
              </Box>
            </Typography>
          </Box>
        </div>
      </Box>
      {showBooking && (
        <EventPopup
          disableEdit
          anchorEl={() => anchorEl}
          open={showBooking}
          infoMode={!editOpen}
          onClose={(e) => {
            if (e && !anchorEl.current.contains(e.target)) {
              setShowBooking(false);
              toggleEditOpen(false);
              afterUpdate();
            }
          }}
          events={events}
          event={booking}
          onSave={async () => {
            await afterUpdate();
            toggleEditOpen(false);
          }}
          onEdit={() => toggleEditOpen(true)}
          onTabChange={() => ({})}
        />
      )}
      {showClientInfoPanel &&
        booking.client?.id &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={booking.client?.id}
            mode="edit"
            stayOnCurrentPage
            onCancel={() => {
              setClientEditMode(false);
            }}
          />
        ) : (
          <ClientInfo
            id={booking.client?.id}
            handleViewClose={handleViewClose}
            setEditMode={setClientEditMode}
            stayOnCurrentPage
          />
        ))}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (url) => dispatch(push(url))
});

export default connect(
  (state) => ({
    events: eventsSelector(state)
  }),
  mapDispatchToProps
)(FlowItem);

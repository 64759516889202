import React from "react";
import "../styles.scss";
import AccountingList from "../AccountList";
import { AccountingGroup, PrimaryLedger, PrimaryLedgerNames } from "../../../interfaces/Accounts";

interface EquityAndLiabilitiesProps {
  data: PrimaryLedger;
  setSelectedParent: (data: Partial<AccountingGroup>) => void;
}

export default function EquityAndLiabilities({
  data,
  setSelectedParent
}: EquityAndLiabilitiesProps): JSX.Element {
  return (
    <AccountingList
      header={PrimaryLedgerNames.EQUITY_AND_LIABILITIES}
      setSelectedParent={setSelectedParent}
      data={data}
    />
  );
}

import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import List from "../../components/OList";
import { LedgerInterface } from "../../interfaces/Employee";
import { EmptyView } from "../../components/OList/List";
import { TransactionType } from "../../interfaces/Accounts";
import "./style.scss";
import { t, tl } from "../../components/translate";

export default function EmployeeLedger({ data }: { data: LedgerInterface[] }): JSX.Element {
  return (
    <Box marginTop="32px" height="calc(100vh - 350px)" className="ledgerStyle">
      <Typography fontWeight="bold">{tl("employee.ledger")}</Typography>
      <List
        className="ledgerStyle"
        rowHeight={40}
        data={data}
        adjustHeightToContents
        columns={[
          {
            key: "transactionDate",
            label: `${t("date")}`,
            sortable: true,
            formatter: ({ transactionDate }) => (
              <Typography>
                {transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : ""}
              </Typography>
            )
          },
          {
            key: "voucherType",
            label: `${t("type")}`,
            formatter: (row) => <Typography>{row.voucherType}</Typography>
          },
          {
            key: "paymentMethod",
            label: `${t("paymentMethod")}`,
            formatter: (row) => <Typography>{row.paymentMethod}</Typography>
          },
          {
            key: "debit",
            label: `${t("debit")}`,
            formatter: ({ transactionType, amount }) => (
              <Typography>{transactionType === TransactionType.DEBIT ? amount : ""}</Typography>
            )
          },
          {
            key: "credit",
            label: `${t("credit")}`,
            formatter: ({ transactionType, amount }) => (
              <Typography>{transactionType === TransactionType.CREDIT ? amount : ""}</Typography>
            )
          },
          {
            key: "balance",
            label: `${t("balance")}`,
            formatter: ({ dueAmount }) => <Typography>{dueAmount}</Typography>
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            {tl("noLedger")}
          </Box>
        </EmptyView>
      </List>
    </Box>
  );
}

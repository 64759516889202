import * as React from 'react';
import times from 'lodash/times';
import * as moment from 'moment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { WeekCalendarHeader } from './WeekCalendar';
import { tl } from '../../translate';
import useMobileScreen from '../../../hooks/useMobileScreen';
import styles from './WeekEventsCalendar.module.css';

export interface WeekCalendarProps {
  events: Array<any>;
  onWeekChange?: () => void;
  onEventClicked?: () => void;
  onAddSlotsClicked?: () => void;
  selectedDate: moment.Moment | Date;
  renderEventContent: (event, style) => JSX.Element;
}

const DayColumn = ({ day, events, renderEventContent }) => {
  const getEventsForTheDay = (day) => {
    if (!events) return [];
    return events.filter((e) => {
      return moment(day).isSame(e.from, 'day');
    }).sort((a, b) => {
      return new Date(a.from).getTime() - new Date(b.from).getTime()
    });
  }
  return <div key={day.toISOString()} className={styles.weekcalendarDayColumn}>
    <div className={styles.weekcalendarGrid}>
      <div className={styles.weekcalendarEvents}>
        {
          getEventsForTheDay(day).map((e, i) => {
            return <div key={i} className={styles.weekcalendarEvent}>{renderEventContent(e)}</div>;
          })
        }
      </div>
    </div>
  </div>
};

const getDays = (date: Date | moment.Moment = new Date(), daysCount = 7) => {
  if (daysCount === 1) {
    return [moment(date)]
  }
  const startDay = moment(date).startOf('week');
  const days = [];
  times(daysCount, (i) => {
    days.push(startDay.clone().add(i, 'day'));
  });
  return days;
}

const WeekCalendar: React.FC<WeekCalendarProps> = ({ events, selectedDate, renderEventContent }) => {
  const isMobileScreen = useMobileScreen();
  const dayColumnsCount = isMobileScreen ? 1 : 7;
  return (
    <div className={styles.weekcalendarContainer}>
      {!isMobileScreen && <WeekCalendarHeader days={getDays(selectedDate, dayColumnsCount)} hideTimeColumn={true} />}
      {events && events.length == 0 ? (
        <div className={styles.emptyCalendar}>
          <Typography component="div">
            <Box className={styles.emptyCalendarText}>{tl('booking.noCalendarEvents')}</Box>
          </Typography>
        </div>
      ) : (
          <div className={styles.weekcalendarDayColumns}>
            {getDays(selectedDate, dayColumnsCount)
              .map((day) => <DayColumn day={day} events={events} renderEventContent={renderEventContent} />)}
          </div>
        )}
    </div>
  );
}

export default WeekCalendar;
import * as React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { LabTestGroup } from "../../interfaces/LabInterfaces";
import { getLabTestGroups } from "../../slices/labTestSettingsSlice";

interface LabTestsGroupNameAutoSelectProps {
  onChange: (ids: number[]) => void;
}

const LabTestsGroupNameAutoSelect = ({
  onChange
}: LabTestsGroupNameAutoSelectProps): JSX.Element => {
  const dispatch = useDispatch();

  const labTestGroups: Array<LabTestGroup> = useSelector(
    (state: RootState) => state.labSettings.labTestGroups
  );

  React.useEffect(() => {
    dispatch(getLabTestGroups());
  }, [dispatch]);

  return (
    <Autocomplete
      multiple
      options={labTestGroups}
      getOptionLabel={(option) => option.name || ""}
      onChange={(e, values) => {
        onChange(values?.map((value) => value.id));
      }}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {option.name || ""}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Lab Test Groups"
          placeholder="Search and select Lab Tests"
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

export default LabTestsGroupNameAutoSelect;

import { TableState } from "../components/EditableTable/EditableTable";
import { LabRecordType } from "../containers/Lab";
import { ReagentProps } from "../containers/Lab/SettingsV2/StockReagent";

export enum LabStatuses {
  ORDERED = "Ordered",
  SAMPLE_TAKEN = "Sample Taken",
  INCOMPLETE_RESULT = "Incomplete Result",
  PENDING_APPROVAL = "Pending Approval",
  RESULT_READY = "Result Ready",
  DISPATCHED = "Dispatched",
  CANCELED = "Canceled"
}

export enum TestTypes {
  PATHOLOGY = "pathology",
  RADIOLOGY = "radiology",
  CYTOLOGY = "cytology"
}

export enum LabCategories {
  Haematology = "Haematology"
}

export interface LabFormData {
  flag: string;
  reading: string;
  formula: { references: Array<string>; rootValue: string };
}

interface BaseRange {
  min: string | number;
  max: string | number;
}

export interface MaleFemaleChildRange {
  male: BaseRange;
  female: BaseRange;
  children: BaseRange;
}

export interface DefaultRange {
  default: BaseRange;
}

export interface SpecialRange {
  other: BaseRange & { label: string };
}

export type Range = DefaultRange | MaleFemaleChildRange | SpecialRange;

export interface LabTestRecordFilters {
  clientId?: number;
  page: number;
  pageSize: number;
  status?: string;
  testId?: number;
}

export interface LabClient {
  active: boolean;
  address: null | string;
  dob: string | null;
  email: string | null;
  firstName: string;
  gender: "1" | "2" | null;
  id: number;
  ipdNo: null | number;
  lastName: string;
  nationality: null | string;
  passportInfo: null | string;
  phone: string;
  registrationNo: null | string;
  sendEmail: boolean;
  wardNo: null | string;
}

export interface LabCustomer {
  clientId: number;
  customerDetails: { profileImageS3Url: string } | null;
  customerNumber: string;
  externalIdentifier: null | number;
  id: number;
}

export interface LabResourceCentre {
  address: string;
  city: string;
  email: string;
  id: number;
  name: string;
  phone: string;
}

export interface MinifiedLabResult {
  result: null | string | number;
  testName: string;
}

export interface LabTestRecordResultData {
  category: LabCategories;
  code: string;
  common: boolean;
  email: null | string;
  id: number;
  info: Record<string, unknown>;
  name: string;
  status: LabStatuses;
  testId: number;
  type: TestTypes;
  labTests: Array<LabTest>;
  additionalTestData: string;
  interpretationTemplate: TableState;
  sampleTaken: boolean;
  collectionDate: Date | string;
  remarks: string;
  sampleInfo?: { sampleType?: string; sampleContainer?: string };
  collectedBy?: { name: string; id: number };
}

export interface LabRecordApprovedBySp {
  id: number;
  name: string;
  qualification: string;
  registrationNumber: string;
  role: string;
  signature: string;
  specialities: string;
  speciality: null | string;
  title: string;
}

interface Service {
  id?: number;
  name: string;
  productisableId: number;
}

type LabService = Service & { subItems?: Array<Service> };
export interface LabRecordResult {
  approvedBy?: {
    authorizedSP1: null | LabRecordApprovedBySp;
    authorizedSP2: null | LabRecordApprovedBySp;
    authorizedSP3: null | LabRecordApprovedBySp;
    authorizedSP4: null | LabRecordApprovedBySp;
  };
  client: LabClient;
  data: Array<LabTestRecordResultData>;
  billNumber?: string | number;
  comment?: string;
  showComment: boolean;
  labTechnicianId?: number;
  relatedServices: Array<LabService>;
}

export interface LabRecord {
  isAssessedBySp?: boolean;
  accessingResourceCentre: null | LabResourceCentre;
  accessingResourceCentreId: null | number;
  assessmentId: null | number;
  client: LabClient;
  clientId: number;
  collectionDate: string | null;
  customer: LabCustomer;
  id: number;
  labIdentifier: string;
  extraReferrer?: { name: string; id: number };
  labTestType: "labTest";
  minifiedResult: MinifiedLabResult;
  parentId: null;
  referrerId: null | number;
  referrers: string;
  remarks: null | string;
  resourceCentre: LabResourceCentre;
  resourceCentreId: number;
  resultDate: null | string;
  results: LabRecordResult;
  serviceProvider: null;
  serviceProviderId: null;
  status: LabStatuses;
  type: TestTypes;
  /* eslint-disable camelcase */
  created_at: string;
  updated_at: null | string;
  /* eslint-enable */
  uuid: string;
  version: "v1" | "v2";
  attachments: Array<File>;
  labTestNumber: string;
}

export interface LabSummary {
  resourceCentreId: number;
  date: Date | number;
  palikaId: number;
  districtId: number;
  provinceId: number;
  ageRange: string;
  gender: string;
  ethnicity: string;
  labStatus: string;
  testCount: number;
  // eslint-disable-next-line camelcase
  created_at: Date;
  // eslint-disable-next-line camelcase
  update_at: Date;
}

export interface LabTest {
  additionalTestData: string | null;
  category: LabCategories;
  code: string;
  formData: LabFormData;
  id: number;
  info: Record<string, string> | null;
  methods: null;
  name: string;
  parentId: null | number;
  ranges: DefaultRange | MaleFemaleChildRange | SpecialRange;
  sortCode: string;
  specimen: string;
  unit: string;
  /* eslint-disable camelcase */
  updated_at: null | string;
  created_at: null | string;
  /* eslint-enable */
  subTests?: Array<Omit<this, "subTests">>;
}

// TODO - Change `Test` to more appropriate name

export interface ReagentInfoProps {
  hasEnoughQtyToUse: boolean;
  productName: string;
  productId: number;
  batchId: string;
  availableQuantity: number;
  quantityUsed: number;
}
export interface Test {
  id: number;
  name: string;
  code: string;
  common: boolean;
  type: TestTypes;
  category: string;
  referenceId?: number;
  documentRecord: DocumentRecordGroup;
  publicBooking?: boolean;
  isSubTest?: boolean;
  reagentInfo: ReagentInfoProps[];
  stockReagent: ReagentProps[];
  sampleTaken: boolean;
  sampleInfo: {
    collectionDate: Date | null;
    remarks: string;
  };
  parentId: number;
  subTests: Test[];
}

export interface DocumentRecordTest {
  id: number;
  code: string;
  info: null;
  name: string;
  unit: string;
  ranges: Record<string, unknown>;
  category: string;
  parentId: number;
  specimen: string;
  subTests?: Array<DocumentRecordTest>;
  // eslint-disable-next-line camelcase
  created_at: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
}

export interface DocumentRecordGroup {
  id: number;
  code: string;
  name: string;
  type: TestTypes;
  common: boolean;
  info: Record<string, unknown>;
  category: string;
  labTests: Array<DocumentRecordTest>;
  stockReagent: ReagentProps[];
}

export interface DocumentRecord {
  data: [DocumentRecordGroup];
}

export interface LabReport {
  testName: string;
  testResult: string;
  id: number;
  status: LabStatuses;
  collectionDate: string;
  resultDate: string;
  // eslint-disable-next-line camelcase
  created_at: string;
  referrers: string;
  clientId: number;
  clientFirstName: string;
  clientLastName: string;
  clientPhone: string;
  clientEmail: string;
  clientDob: string;
  clientGender: string;
  testGroup: string;
  category: string;
  results: Record<string, unknown>[];
  type: LabRecordType;
}

import { createSelector } from 'reselect';
import startCase from 'lodash/startCase';

import { ReducerBuilder } from './ReducerBuilder';
import * as AudienceActions from '../actions/audience';

const INITIAL_STATE = {
  collection: [],
  lastTouched: null
};

function setAudiences(state, payload) {
  return { ...state, collection: payload || [] };
}

function setAudience(state, payload) {
  const newCollection = [...state.collection].filter(({ id }) => id !== payload.id);
  newCollection.push(payload);
  return { ...state, collection: newCollection, lastTouched: payload };
}

function removeAudience(state, id) {
  const newCollection = [...state.collection].filter((audience) => audience.id !== id);
  return { ...state, collection: newCollection };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(AudienceActions.Type.AUDIENCES_GET, setAudiences)
  .mapAction(AudienceActions.Type.AUDIENCE_GET, setAudience)
  .mapAction(AudienceActions.Type.AUDIENCE_CREATE, setAudience)
  .mapAction(AudienceActions.Type.AUDIENCE_UPDATE, setAudience)
  .mapAction(AudienceActions.Type.AUDIENCE_DELETE, removeAudience)
  .build();
export default reducer;

export const audiencesSelector = (state) => state.audience.collection;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import styles from "./Dialog.module.css";
import { tl } from "../translate";

interface OkhatiDialogProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  next?: () => void;
  cancel?: () => void;
  readMode: boolean;
  open?: boolean;
  nextButtonText?: string;
  cancelButtonText?: string;
  disableConfirmBtn?: boolean;
  isLoading?: boolean;
  promptBeforeSave?: boolean;
  promptComparisonValue?: string;
}

const OkhatiDialog = ({
  title,
  description,
  next = () => {
    // do nothing
  },
  cancel = () => {
    // do nothing
  },
  readMode,
  open = true,
  nextButtonText = "",
  cancelButtonText = "",
  disableConfirmBtn = false,
  isLoading = false,
  promptBeforeSave,
  promptComparisonValue
}: OkhatiDialogProps): JSX.Element => {
  const [prompt, setPrompt] = React.useState("");
  const isPromptModal = promptBeforeSave && promptComparisonValue;
  return (
    <Dialog open={open} classes={{ root: styles.dialog }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
        {isPromptModal && (
          <Box py={1}>
            <Typography>
              Enter{" "}
              <Typography fontWeight="bold" sx={{ display: "inline" }}>
                {promptComparisonValue}
              </Typography>{" "}
              below to confirm.
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {readMode ? (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await next();
              setPrompt("");
            }}
            data-testmation="dialogOK"
          >
            {tl("ok")}
          </Button>
        ) : (
          <>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              onClick={async () => {
                await next();
                setPrompt("");
              }}
              data-testmation="dialogConfirm"
              disabled={disableConfirmBtn || (isPromptModal && promptComparisonValue !== prompt)}
            >
              {nextButtonText || tl("confirm")}
            </LoadingButton>
            <Button
              variant="text"
              color="secondary"
              onClick={cancel}
              data-testmation="dialogCancel"
            >
              {cancelButtonText || tl("cancel")}
            </Button>{" "}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

OkhatiDialog.defaultProps = {
  open: true,
  nextButtonText: "",
  cancelButtonText: "",
  next: () => {
    // do nothing
  },
  cancel: () => {
    // do nothing
  },
  disableConfirmBtn: false
};

export default OkhatiDialog;

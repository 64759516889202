import { useSelector } from "react-redux";
import { RootState } from "../store";

const useDepartmentSubscription = (): { isDepartmentSubscribed: boolean } => {
  const isSubscribed = useSelector(
    (state: RootState) =>
      state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
  );

  return { isDepartmentSubscribed: Boolean(isSubscribed) };
};

export default useDepartmentSubscription;

import React from "react";
import moment from "moment-timezone";
import startCase from "lodash/startCase";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import logo from "../../../../assets/images/poweredByOkhati.png";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import { RootState } from "../../../store";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import SurveyFormContent from "./SurveyFormContent";
import { SurveyData, StyledTypography } from "./SurveyCreateEditForm";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { clientFullNameSelector } from "../../../reducers/client";
import LabPrintLetterHead from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import LabPrintLetterHeadCentered from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";

const SurveyFormPrint = React.forwardRef(
  ({ data }: { data: SurveyData }, ref: React.ForwardedRef<HTMLDivElement | null>) => {
    const resourceCentre = useSelector(
      (state: RootState) =>
        state.resources.resourceCentres.find(
          (rc: ResourceCentre) => rc.id === state.userContext.resourceCentreId
        ) || state.userContext.resourceCentre
    );
    const headerColor =
      (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
        resourceCentre?.settings.printSettings.labPrintSettings.color) ||
      null;
    const showLetterhead = Boolean(
      resourceCentre?.settings.printSettings.assessmentPrintSettings?.includeLetterhead
    );
    const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
    const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
    const panNo = resourceCentre?.settings?.billingSettings?.panNo;
    const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
    const customSubtitle =
      resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
    const hideRcLogo = Boolean(customTitle);
    let letterHeadComponent;
    if (showLetterhead) {
      if (centralizedHeader) {
        letterHeadComponent = (
          <LabPrintLetterHead
            resourceCentre={{
              ...resourceCentre,
              panNo,
              subtitle: customSubtitle
            }}
            hideRcLogo={hideRcLogo}
            titleFontScale={1}
          />
        );
      } else {
        letterHeadComponent = (
          <LabPrintLetterHeadCentered
            resourceCentre={{
              ...resourceCentre,
              panNo,
              subtitle: customSubtitle
            }}
            titleFontScale={clinicNameFontScale}
            hideRcLogo={hideRcLogo}
          />
        );
      }
    }

    return (
      <iframe className="ifrm" title="Consent Form Print">
        <div ref={ref}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <Box height="4.5cm" />
                </td>
              </tr>
            </tfoot>
            <thead>
              {!showLetterhead && (
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle, padding: 0 }}>
                    <div>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "0.5cm",
                          fontWeight: 600,
                          textTransform: "uppercase",
                          pb: 2
                        }}
                      >
                        Survey Form
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: "1px solid lightgrey",
                          px: "10mm",
                          pb: "1mm"
                        }}
                      >
                        {data.client && (
                          <>
                            <StyledTypography
                              text={`Name: ${startCase(clientFullNameSelector(data.client))} ${
                                data.client.phone ? `(${data.client.phone})` : ""
                              }`}
                            />
                            <StyledTypography
                              text={`Date: ${convertADtoBS(moment(data.created_at)).formatted2}`}
                            />
                          </>
                        )}
                      </Box>
                    </div>
                    <Box mt={2} px="10mm">
                      <SurveyFormContent data={data} />
                    </Box>
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>

          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              borderTop: "1px solid lightgrey",
              px: "10mm",
              py: "5mm",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <img src={logo} alt="logo" height="30px" />
          </Box>
        </div>
      </iframe>
    );
  }
);

export default SurveyFormPrint;

import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import Panel from "../../../../components/Panel";
import ImnciAboveTwoMonthsForm from "./ImnciAboveTwoMonthsForm";
import { HMIS } from "../../../../interfaces/HmisInterface";
import { InfoRow } from "../../MulDarta/MulDartaInfoPanel";
import { clientFullNameWithPhoneSelector } from "../../../../reducers/client";
import { Client } from "../../../../interfaces/ClientInterface";
import ClientSelect from "../../OpdRegister/ClientSelect";

interface Props {
  mulDarta?: HMIS;
  onClose: () => void;
}

const CreateEditImnciAboveTwoMonthsPanel = ({ onClose, mulDarta }: Props): JSX.Element => {
  const [client, setClient] = useState<(Client & { mulDartaNumber: string }) | null>(null);

  return (
    <Panel
      onClose={onClose}
      titleJsx={
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <Typography variant="h5" component="p">
            Imnci Register Aged 2 - 59 Months
          </Typography>
          <Typography variant="body2">HMIS 2.41</Typography>
        </Box>
      }
    >
      <Box
        sx={{
          px: 4,
          pt: 2,
          height: "calc(100vh - 120px)",
          overflowY: "auto"
        }}
      >
        <Box mb={2}>
          <>
            {!mulDarta && <ClientSelect value={client} onChange={(value) => setClient(value)} />}
            {(mulDarta?.client || client) && (
              <Box mt={2}>
                <Typography gutterBottom variant="h6" component="p">
                  {clientFullNameWithPhoneSelector(mulDarta?.client || client)}
                </Typography>
                <InfoRow
                  label="Mul Darta Number"
                  value={mulDarta?.mulDartaNumber || client?.mulDartaNumber || ""}
                />
              </Box>
            )}
          </>
        </Box>
        <Divider variant="fullWidth" sx={{ mb: 2 }} />
        <ImnciAboveTwoMonthsForm
          onClose={onClose}
          mulDartaId={mulDarta?.id || client?.mulDartaId}
          clientId={mulDarta?.clientId || client?.id}
        />
      </Box>
    </Panel>
  );
};

export default CreateEditImnciAboveTwoMonthsPanel;

import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { match as MatchInterface } from "react-router";
import { ReverseMap } from "../../helpers/types";
import style from "./style.module.css";
import Admins from "./UserSettings/Admins";
import Details from "./Details";
import Settings from "./Settings";
import ServiceProviders from "./ServiceProviders";
import SummaryReport from "./SummaryReport";
import Suppliers from "./Suppliers";
import PublicBooking from "./PublicBooking";
import Subscriptions from "./SASubscriptions/index";
import {
  resourceCentreActions,
  resourceCentreEmployeeActions,
  serviceProviderActions
} from "../../actions";
import { employeesSortedSelector } from "../../reducers/employee";
import Referrers from "../Referrers/Referrers";
import { RootState } from "../../store";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { Employee, PermissionGroups, UserMode } from "../../interfaces/User";
import { User } from "../../interfaces/UserContext";
import { AsyncAction } from "../../actions/actionHelpers";
import useCan from "../../hooks/useCan";
import { getRCUserGroups } from "../../slices/resourceCentreUserGroupsSlice";
import AccountSettings from "./Settings/AccountSettings";
import UserSettings from "./UserSettings/UserSettings";
import ModuleSettings from "./ModuleSettings";
import OtherSettings from "./OtherSettings";
import useIsAccountSubscribed from "../../hooks/accounts";
import { ledgerFindBySubLedgerType } from "../accounts/hooks";
import { ChildGeneralLedger, SubLedgerTypes } from "../../interfaces/Accounts";
import { getRcDepartments } from "../../slices/departmentSlice";
import VendorSettings from "./VendorSettings";
import AllUsers from "./UserSettings/AllUsers";
import AssociateCompanyList from "../AsscoiateCompany/AssociateCompanyList";
import Can from "../Policy/Can";
import { tl } from "../../components/translate";
import ResourceCentreInvoices from "../ResourceCentreInvoices/ResourceCentreInvoices";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import BookableResource from "./UserSettings/BookableResource";

interface ResourceCentreShowProps {
  actions: {
    [x: string]: (options?: Record<string, unknown>, next?: unknown) => AsyncAction<unknown>;
  };
  resourceCentres: ResourceCentre[];
  resourceCentreEmployees: Employee[];
  employeeId?: number;
  supplierId?: number;
  match?: MatchInterface<{ [x: string]: string }>;
  navigateTo: (path: string) => void;
  createMode?: boolean;
  editMode?: boolean;
  user: User & { role: UserMode; permissionGroup: PermissionGroups };
  referrerSubscribed: boolean;
  editSPMode?: boolean;
  createSPMode?: boolean;
  serviceProviderId?: number;
}

export enum SETTING_ROUTES {
  DETAILS = "details",
  ACCOUNT_SETTINGS = "accountSettings",
  EMPLOYEES = "employees",
  SERVICE_PROVIDERS = "serviceProviders",
  MODULE_SETTINGS = "moduleSettings",
  OTHER_SETTINGS = "otherSettings",
  SUBSCRIPTIONS = "subscriptions",
  SUPPLIER = "supplier",
  REFERRERS = "referrers",
  PUBLIC = "public",
  SETTINGS = "settings",
  SUMMARY_REPORT = "summaryReport",
  NON_USER_EMPLOYEE = "nonUserEmployee",
  ALL_USERS = "allUsers",
  ASSOCIATE_COMPANIES = "associateCompanies",
  PAYMENTS = "payments",
  BOOKABLE_RESOURCE = "bookableResource"
}

type RoutesValues = ReverseMap<typeof SETTING_ROUTES>;

interface StyledNavItemProps {
  isActive: boolean;
  title: string;
  subTitle: string;
  dataTestmation: string;
  onClick: () => void;
}

function StyledNavItem({ isActive, title, subTitle, dataTestmation, onClick }: StyledNavItemProps) {
  return (
    <Box
      data-testmation={dataTestmation}
      className={`${style.settings_list_row} ${isActive ? style.settings_list_row_active : ""}`}
      onClick={onClick}
    >
      <Typography fontWeight="500">{title}</Typography>
      <Box
        fontSize="small"
        color="gray"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {subTitle}
      </Box>
    </Box>
  );
}

function ResourceCentreShow(props: ResourceCentreShowProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [activeTab, setActiveTab] = React.useState("" as RoutesValues);
  const [selectedEmployee, setSelectedEmployee] = React.useState<number | null>(null);
  const isAccountSubscribed = useIsAccountSubscribed();
  const spLedger = ledgerFindBySubLedgerType(SubLedgerTypes.SERVICE_PROVIDER) as ChildGeneralLedger;

  const {
    actions,
    match,
    resourceCentres,
    user,
    navigateTo,
    referrerSubscribed,
    editMode,
    createMode,
    employeeId,
    resourceCentreEmployees,
    editSPMode,
    createSPMode,
    serviceProviderId,
    supplierId
  } = props;

  React.useEffect(() => {
    dispatch(getRCUserGroups(Number(match?.params.id)));
    dispatch(getRcDepartments());

    actions.getResourceCentre(match?.params.id);
    actions.getResourceCentreEmployees({
      resourceCentreId: match?.params.id
    });
    actions.getResourceCentreServiceProviders({
      resourceCentreId: match?.params.id
    });

    const settingRoutes = Object.values(SETTING_ROUTES);
    settingRoutes.forEach((value) => {
      if (match?.url.includes(value)) {
        setActiveTab(value);
      }
    });
  }, [actions, dispatch, match?.params.id, match?.url]);

  React.useEffect(() => {
    const pathName = (match?.url.split("/").at(-1) as SETTING_ROUTES) || SETTING_ROUTES.DETAILS;
    switch (pathName) {
      case SETTING_ROUTES.SUPPLIER:
      case SETTING_ROUTES.REFERRERS:
        setActiveTab(SETTING_ROUTES.SUPPLIER);
        break;
      case SETTING_ROUTES.EMPLOYEES:
      case SETTING_ROUTES.SERVICE_PROVIDERS:
        setActiveTab(SETTING_ROUTES.EMPLOYEES);
        break;
      default:
        setActiveTab(pathName);
    }
  }, [match?.url]);

  const canAccessAdmin = useCan("clinic:listAdmin", { superAdminPass: true });
  const canAccessSupplier = useCan("clinic:listSuppliers", { superAdminPass: true });
  const canAccessClinicSettings = useCan("clinic:accessClinicAdminSetting", {
    superAdminPass: true
  });
  const canAccessAccountSettings = useCan("account:listAccount", { superAdminPassReverse: true });
  const canAccessSettingsAndSubscriptions = useCan("clinic:accessSettingsAndSubscriptions", {
    superAdminPass: true,
    restrictSupportAdmin: true
  });
  const canAccessReferrers = useCan("clinic:listReferrers", { superAdminPass: true });

  const fetchResourceCenter = () => {
    actions.getResourceCentre(match.params.id as unknown);
  };

  const resourceCentre = resourceCentres.find((rc) => rc.id === Number(match.params.id));
  if (!resourceCentre) return null;
  const showSettingsTab = ["superAdmin", "admin"].includes(user.role);
  const isAdmin = [
    PermissionGroups.ResourceCentreAdmin,
    PermissionGroups.SuperAdmin,
    PermissionGroups.SupportAdmin
  ].includes(user.permissionGroup);

  return (
    <div className={style.resourceCentrePage}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: isMobileScreen ? "column" : "row",
          alignItems: isMobileScreen ? "start" : "unset"
        }}
      >
        <Box sx={{ borderRight: "1px solid lightgray", width: isMobileScreen ? "100%" : "auto" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobileScreen ? "row" : "column",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              overflowX: "auto"
            }}
          >
            <StyledNavItem
              isActive={activeTab === SETTING_ROUTES.DETAILS}
              title={tl("Setting.CompanyProfile")}
              subTitle={tl("Setting.CompanyProfile.Sub")}
              dataTestmation="companyProfileTab"
              onClick={() =>
                navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.DETAILS}`)
              }
            />
            {canAccessClinicSettings && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.ACCOUNT_SETTINGS}
                title={tl("Setting.Account")}
                subTitle={tl("Setting.Account.Sub")}
                dataTestmation="accountTab"
                onClick={() =>
                  navigateTo(
                    `/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.ACCOUNT_SETTINGS}`
                  )
                }
              />
            )}
            {canAccessAdmin && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.EMPLOYEES}
                title={tl("Setting.Users")}
                subTitle={tl("Setting.Users.Sub")}
                dataTestmation="usersTab"
                onClick={() =>
                  navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.EMPLOYEES}`)
                }
              />
            )}
            <StyledNavItem
              isActive={activeTab === SETTING_ROUTES.MODULE_SETTINGS}
              title={tl("Setting.Modules")}
              subTitle={tl("Setting.Modules.Sub")}
              dataTestmation="modulesSettings"
              onClick={() =>
                navigateTo(
                  `/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.MODULE_SETTINGS}`
                )
              }
            />
            <StyledNavItem
              isActive={activeTab === SETTING_ROUTES.OTHER_SETTINGS}
              title={tl("Setting.Others")}
              subTitle={tl("Setting.Others.Sub")}
              dataTestmation="othersTab"
              onClick={() =>
                navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.OTHER_SETTINGS}`)
              }
            />
            {canAccessSupplier && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.SUPPLIER}
                title={tl("Setting.Vendors")}
                subTitle={tl("Setting.Vendors.Sub")}
                dataTestmation="vendorTab"
                onClick={() =>
                  navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.SUPPLIER}`)
                }
              />
            )}
            <Can
              policyAccessKey="allow"
              superAdminAgentPassReverse
              superAdminPass
              supportAccountAdminPass
            >
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.PAYMENTS}
                title={tl("subscription.invoices")}
                subTitle={tl("subscription.invoices.Sub")}
                dataTestmation="paymentsTab"
                onClick={() => {
                  mixpanelAnalytics.track(EVENT.SETTINGS_GO_TO_PAYMENTS);
                  navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.PAYMENTS}`);
                }}
              />
            </Can>
            {canAccessAdmin && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.SUBSCRIPTIONS}
                title={tl("subscription.SubscriptionInformation")}
                subTitle={tl("subscription.SubscriptionInformation.Sub")}
                dataTestmation="subscriptionTab"
                onClick={() =>
                  navigateTo(
                    `/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.SUBSCRIPTIONS}`
                  )
                }
              />
            )}
            {resourceCentre?.publicBooking && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.PUBLIC}
                title={tl("subscription.PublicBooking")}
                dataTestmation="publicBooking"
                subTitle={tl("subscription.PublicBooking.Sub")}
                onClick={() =>
                  navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.PUBLIC}`)
                }
              />
            )}
            {showSettingsTab && canAccessSettingsAndSubscriptions && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.SETTINGS}
                title="Superadmin"
                subTitle="Super admin related settings."
                dataTestmation="superadminTab"
                onClick={() =>
                  navigateTo(`/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.SETTINGS}`)
                }
              />
            )}
            {showSettingsTab && (
              <StyledNavItem
                isActive={activeTab === SETTING_ROUTES.SUMMARY_REPORT}
                title="Summary Report"
                subTitle="View summary report"
                dataTestmation="summaryReportTab"
                onClick={() =>
                  navigateTo(
                    `/resourcecentres/${resourceCentre.id}/${SETTING_ROUTES.SUMMARY_REPORT}`
                  )
                }
              />
            )}
          </Box>
        </Box>

        <Box sx={{ width: "100%", height: "100%" }} pt={isMobileScreen ? 1 : 3} pl={2}>
          {match?.url.includes(SETTING_ROUTES.DETAILS) && (
            <Details
              resourceCentre={resourceCentre}
              fetchResourceCenter={fetchResourceCenter}
              user={user}
              editMode={!!editMode}
            />
          )}
          {(match?.url.includes(SETTING_ROUTES.EMPLOYEES) ||
            match?.url.includes(SETTING_ROUTES.SERVICE_PROVIDERS) ||
            match?.url.includes(SETTING_ROUTES.BOOKABLE_RESOURCE) ||
            match?.url.includes(SETTING_ROUTES.NON_USER_EMPLOYEE) ||
            match?.url.includes(SETTING_ROUTES.ALL_USERS)) && (
            <UserSettings resourceCentreId={resourceCentre.id}>
              {match?.url.includes(SETTING_ROUTES.EMPLOYEES) && (
                <Admins
                  resourceCentre={resourceCentre}
                  editMode={editMode}
                  createMode={createMode}
                  employeeId={employeeId}
                  resourceCentreState={{ selectedEmployee }}
                  onRowClick={(id) => setSelectedEmployee(selectedEmployee === id ? null : id)}
                  closeInfo={() => setSelectedEmployee(null)}
                  onClose={() => ({})}
                  user={user}
                  resourceCentreEmployees={resourceCentreEmployees.filter(
                    (employee) => !employee.nonUserEmployee
                  )}
                />
              )}
              {match?.url.includes(SETTING_ROUTES.SERVICE_PROVIDERS) && (
                <Box overflow="auto hidden" height="100%">
                  <Box height="100%" minWidth={1000}>
                    <ServiceProviders
                      resourceCentre={resourceCentre}
                      editSPMode={editSPMode}
                      createSPMode={createSPMode}
                      serviceProviderId={serviceProviderId}
                      isAccountSubscribed={isAccountSubscribed}
                      spLedger={spLedger}
                    />
                  </Box>
                </Box>
              )}
              {match?.url.includes(SETTING_ROUTES.NON_USER_EMPLOYEE) && (
                <Admins
                  resourceCentre={resourceCentre}
                  editMode={editMode}
                  createMode={createMode}
                  employeeId={employeeId}
                  resourceCentreState={{ selectedEmployee }}
                  onRowClick={(id) => setSelectedEmployee(selectedEmployee === id ? null : id)}
                  closeInfo={() => setSelectedEmployee(null)}
                  onClose={() => ({})}
                  user={user}
                  resourceCentreEmployees={resourceCentreEmployees.filter(
                    (employee) => employee.nonUserEmployee
                  )}
                  isEmployeeOnlyTab
                />
              )}
              {match?.url.includes(SETTING_ROUTES.ALL_USERS) && <AllUsers />}
              {match?.url.includes(SETTING_ROUTES.BOOKABLE_RESOURCE) && <BookableResource />}
            </UserSettings>
          )}
          {match?.url.includes(SETTING_ROUTES.OTHER_SETTINGS) && (
            <OtherSettings resourceCentre={resourceCentre} />
          )}
          {(match?.url.includes(SETTING_ROUTES.SUPPLIER) ||
            match?.url.includes(SETTING_ROUTES.REFERRERS) ||
            match?.url.includes(SETTING_ROUTES.ASSOCIATE_COMPANIES)) && (
            <VendorSettings resourceCentreId={resourceCentre.id}>
              {match?.url.includes(SETTING_ROUTES.SUPPLIER) && (
                <Suppliers
                  resourceCentre={resourceCentre}
                  editMode={editMode}
                  createMode={createMode}
                  supplierId={supplierId}
                />
              )}
              {match?.url.includes(SETTING_ROUTES.REFERRERS) &&
                referrerSubscribed &&
                canAccessReferrers && <Referrers />}
              {match?.url.includes(SETTING_ROUTES.ASSOCIATE_COMPANIES) && (
                <AssociateCompanyList match={match} createMode={createMode} editMode={editMode} />
              )}
            </VendorSettings>
          )}
          {match?.url.includes(SETTING_ROUTES.MODULE_SETTINGS) && (
            <ModuleSettings resourceCentre={resourceCentre} />
          )}
          {match?.url.includes(SETTING_ROUTES.ACCOUNT_SETTINGS) && (
            <AccountSettings
              canAccessAccountSettings={canAccessAccountSettings}
              resourceCentre={resourceCentre}
              updateRc={(newRc) => actions.putResourceCentre(newRc)}
            />
          )}
          {match?.url.includes(SETTING_ROUTES.PUBLIC) && resourceCentre?.publicBooking && (
            <PublicBooking resourceCentre={resourceCentre} />
          )}
          {showSettingsTab && match?.url.includes(SETTING_ROUTES.SETTINGS) && (
            <Settings resourceCentre={resourceCentre} />
          )}
          {showSettingsTab && match?.url.includes(SETTING_ROUTES.SUMMARY_REPORT) && (
            <SummaryReport resourceCentreId={resourceCentre.id} />
          )}
          {match?.url.includes(SETTING_ROUTES.PAYMENTS) && (
            <ResourceCentreInvoices resourceCentre={resourceCentre} />
          )}
          {isAdmin && match?.url.includes(SETTING_ROUTES.SUBSCRIPTIONS) && (
            <Subscriptions
              resourceCentre={resourceCentre}
              canAccessSettingsAndSubscriptions={canAccessSettingsAndSubscriptions}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}

ResourceCentreShow.defaultProps = {
  employeeId: undefined,
  supplierId: undefined,
  match: undefined,
  createMode: false,
  editMode: false,
  editSPMode: false,
  createSPMode: false,
  serviceProviderId: undefined
};

export default connect(
  (state: RootState) => ({
    user: {
      ...state.userContext.user,
      role: state.userContext.mode,
      permissionGroup:
        state?.userContext?.userCreds?.userGroups && state.userContext.userCreds.userGroups[0]
    },
    resourceCentres: state.resources.resourceCentres,
    resourceCentreEmployees: employeesSortedSelector(state as unknown as never),
    stateError: state.error,
    referrerSubscribed:
      !!state.userContext?.resourceCentre?.subscriptions?.features?.referrer?.subscribed
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      {
        ...resourceCentreActions,
        ...resourceCentreEmployeeActions,
        ...serviceProviderActions
      },
      dispatch
    ),
    navigateTo: (url: string) => {
      dispatch(push(url));
    }
  })
)(ResourceCentreShow);

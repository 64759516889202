import produce from "immer";
import { ReducerBuilder } from "./ReducerBuilder";
import * as MedicalTestActions from "../actions/medicalTest";
import { applyDefaultValuesToTest, applyMedicalTestsSort } from "./labTest";
import { LabRecord } from "../interfaces/Lab";

interface State {
  collection: Array<any>;
}

const INITIAL_STATE: State = {
  collection: []
};

function setMedicalTest(state, payload) {
  return {
    ...state,
    collection: applyMedicalTestsSort(payload).map((item) =>
      applyDefaultValuesToTest(item as LabRecord)
    )
  };
}

function postMedicalTest(state, payload) {
  const newState = produce(state as State, (draft) => {
    draft.collection.push(applyDefaultValuesToTest(applyMedicalTestsSort(payload)[0] as LabRecord));
  });
  return newState;
}

function updateMedicalTest(state, payload) {
  if (!payload) return state;
  return produce(state as State, (draft) => {
    draft.collection = state.collection.map((item) => {
      if (item.id === payload.id) {
        return { ...payload, customer: item.customer };
      }
      return item;
    });
  });
}

function setSingleMedicalTest(state, payload) {
  const newState = produce(state as State, (draft) => {
    draft.collection = state.collection.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  });
  return newState;
}

export function updateClientImage(state: State, payload: unknown): State {
  const { id, profileImageS3Url } = payload as { id: number; profileImageS3Url: string };
  const newState = produce(state as State, (draft) => {
    draft.collection = draft.collection.map((draftItem) => {
      if (draftItem.client.id === id) {
        if (draftItem.customer?.customerDetails) {
          draftItem.customer.customerDetails.profileImageS3Url = profileImageS3Url;
        } else {
          draftItem.customer.customerDetails = { profileImageS3Url };
        }
      }
      return draftItem;
    });
  });
  return newState;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(MedicalTestActions.Type.GET_MEDICAL_TEST, setMedicalTest)
  .mapAction(MedicalTestActions.Type.POST_MEDICAL_TEST, postMedicalTest)
  .mapAction(MedicalTestActions.Type.PATCH_MEDICAL_TEST, updateMedicalTest)
  .mapAction(MedicalTestActions.Type.UPDATE_MEDICAL_TEST_SAMPLE, updateMedicalTest)
  .mapAction(MedicalTestActions.Type.UPDATE_CLIENT_IMAGE_MEDICAL, updateClientImage)
  .mapAction(MedicalTestActions.Type.GET_SINGLE_MEDICAL_TEST, setSingleMedicalTest)
  .build();
export default reducer;

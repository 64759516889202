import { useEffect } from "react";

// usePrintStyles.runPrintStyles() for componentRerenderCycle
// usePrintStyles.applyPrintStyles() for dom events
// accepts pageStyle string prop => e.g {pageStyle : 'size: A4'}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function usePrintStyles({ pageStyle }): {
  applyPrintStyles: () => void;
  runPrintStyles: () => void;
} {
  const applyPrintStyles = () => {
    const printStyle = document.getElementById("useReactPrint");
    if (printStyle) printStyle.remove();

    document.head.insertAdjacentHTML(
      "beforeend",
      `<style id="useReactPrint">@media print{@page {${pageStyle};} 
      tfoot{display: table-footer-group;}}</style>`
    );
  };
  return {
    applyPrintStyles,
    runPrintStyles: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        applyPrintStyles();
      }),
  };
}

export default usePrintStyles;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDepartments,
  postDepartment,
  putDepartment,
  deleteDepartment
} from "../api/departments";
import { Department } from "../interfaces/DepartmentInterface";
import { RootState } from "../store/index";
import { notificationAdd } from "../actions/notification";

export const getRcDepartments = createAsyncThunk("departments", async (rcId: number | null) => {
  const response = await getDepartments(rcId);
  return (await response) as Department[];
});

export const postRcDepartment = createAsyncThunk(
  "department/postDepartment",
  async (data: Department) => {
    const response = await postDepartment(data);
    return (await response) as Department;
  }
);

export const patchDepartment = createAsyncThunk(
  "department/patchDepartment",
  async (data: Partial<Department>) => {
    const response = await putDepartment(data);
    return (await response) as Department;
  }
);

export const softDeleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (data: Partial<Department>, { rejectWithValue, dispatch }) => {
    try {
      await deleteDepartment(data);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Department deleted successfully.",
          autoTimeout: true
        })
      );
      return data;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to delete department",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

const departmentSlice = createSlice({
  name: "departments",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRcDepartments.fulfilled, (_, { payload }) => payload);
    builder.addCase(
      postRcDepartment.fulfilled,
      (state: RootState, { payload }: { payload: Department }) => {
        state.push(payload as Department);
      }
    );
    builder.addCase(
      patchDepartment.fulfilled,
      (state: RootState, { payload }: { payload: Department }) => {
        const index = state.findIndex((department) => department.id === payload.id);
        // eslint-disable-next-line no-param-reassign
        if (index !== -1) state[index] = payload;
      }
    );

    builder.addCase(softDeleteDepartment.fulfilled, (draft, { payload }) =>
      draft.filter((department) => department.id !== payload.id)
    );
  }
});

export default departmentSlice.reducer;

import { ProductInterface, TaxationInterface } from "../interfaces/ProductInterface";
import { QueryParams, StockProducts } from "../interfaces/StockInterfaces";
import { Get, Post, Delete, Put, Patch } from "./apiHelper";

const productsRoot = "/api/products";

export async function searchStockProduct(query: QueryParams): Promise<ProductInterface[]> {
  const response = await Get(`${productsRoot}/search`, true, query);
  return response.data as Promise<ProductInterface[]>;
}

export async function getStockProducts(
  query?: QueryParams
): Promise<{ results: ProductInterface[]; total: number } | ProductInterface[]> {
  const response = await Get(`${productsRoot}`, true, query || {});
  return response?.data as Promise<
    { results: ProductInterface[]; total: number } | ProductInterface[]
  >;
}

export async function getStockProductAssociations(id: number): Promise<Array<number>> {
  const response = await Get(`${productsRoot}/associations?id=${id}`, true);
  return response.data as Array<number>;
}

export async function deleteStockProduct(id: number): Promise<string> {
  const response = await Delete(`${productsRoot}/${id}`, true);
  return response.data as Promise<string>;
}

export async function postStockProduct(
  product: Record<string, unknown>
): Promise<ProductInterface> {
  const response = await Post(`${productsRoot}`, product, true);
  return response.data as Promise<ProductInterface>;
}

export async function putStockProduct(product: StockProducts): Promise<StockProducts> {
  const response = await Put(`${productsRoot}/${product.id}`, product, true);
  return response.data as Promise<StockProducts>;
}

export async function batchUploadStock(data: ProductInterface[]): Promise<string> {
  try {
    const response = await Post(`${productsRoot}/batch`, { data }, true);
    return response.data as Promise<string>;
  } catch (e) {
    return e;
  }
}

export async function productGetById(id: number): Promise<ProductInterface> {
  const response = await Get(`${productsRoot}/${id}`, true);
  return response.data as Promise<ProductInterface>;
}

export async function productsGetByIds(ids: Array<number>): Promise<Array<ProductInterface>> {
  const response = await Get(`${productsRoot}byIds?productIds=${ids}`, true);
  return response.data as Promise<Array<ProductInterface>>;
}

export async function productsGetByReferenceIds(
  ids: Array<number>
): Promise<Array<ProductInterface>> {
  const response = await Get(`/api/kitchenProductByIds?kitchenPharmacyProductIds=${ids}`, true);
  return response.data as Promise<Array<ProductInterface>>;
}

export const getTaxations = async (): Promise<TaxationInterface[]> => {
  const response = await Get("/api/taxations", true);
  return response.data as TaxationInterface[];
};

export const patchTaxation = async (
  data: Partial<TaxationInterface>
): Promise<Partial<TaxationInterface>> => {
  const response = await Patch(`/api/taxations/${data.id}`, data, true);
  return response.data as Partial<TaxationInterface>;
};

export const productWithOpeningStock = async (data: StockProducts[]): Promise<string> => {
  const response = await Post(`/api/stockTransactions/openingStock/csv`, data, true);
  return response.data as string;
};

export const createKitchenPharmacyProduct = async (data: {
  productIds: Array<number>;
}): Promise<Array<ProductInterface>> => {
  const response = await Post("/api/kitchenPharmacy/products", data, true);
  return response.data as Array<ProductInterface>;
};

export const getKitchenPharmacyProducts = async (): Promise<Array<ProductInterface>> => {
  const response = await Get("/api/kitchenPharmacy/products", true);
  return response.data as Array<ProductInterface>;
};

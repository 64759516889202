import {
  BalanceSheetInterface,
  FiltersInterface,
  GeneralLedgerInterface,
  ProfitLossInterface,
  TrialBalanceInterface
} from "../interfaces/AccountReportsInterface";
import { Get } from "./apiHelper";

export const reportsRoot = "/api/reports/accounts";

export const getBalanceSheetReport = async (
  queryParams: string
): Promise<BalanceSheetInterface[]> => {
  const response = await Get(`${reportsRoot}/balanceSheet?${queryParams}`, true);
  return response.data as BalanceSheetInterface[];
};

export const getTrialBalanceReport = async (
  queryParams: string
): Promise<TrialBalanceInterface[]> => {
  const response = await Get(`${reportsRoot}/trialBalance?${queryParams}`, true);
  return response.data as TrialBalanceInterface[];
};

export const getProfitLossReport = async (queryParams: string): Promise<ProfitLossInterface[]> => {
  const response = await Get(`${reportsRoot}/profitLoss?${queryParams}`, true);
  return response.data as ProfitLossInterface[];
};

export const getDayBookReport = async (queryParams: string): Promise<unknown> => {
  const response = await Get(`${reportsRoot}/dayBook?${queryParams}`, true);
  return response.data as unknown;
};
export const getGeneralLedgerReport = async (
  filters: FiltersInterface
): Promise<GeneralLedgerInterface[]> => {
  const query = `${reportsRoot}/generalLedger?from=${filters.from?.toISOString()}&until=${filters?.until?.toISOString()}&ledgerIds=${
    filters.ledgerIds
  }`;
  const response = await Get(query, true);
  return response.data as GeneralLedgerInterface[];
};

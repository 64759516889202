import React from "react";
import { useDispatch } from "react-redux";
import { startCase } from "lodash";
import { Box, Typography } from "@mui/material";
import { notificationAdd } from "../../actions/notification";
import { MODULE, errorFetchMessage } from "../../helpers/messages";
import Filters, { Entity, FilterProps } from "./Filters";
import { CalendarFunctions } from "../../components/Calendar";
import { getHistoryReportData } from "../../api/reports";
import useMobileScreen from "../../hooks/useMobileScreen";
import List, { EmptyView } from "../../components/OList";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { History } from "../../interfaces/HistoryInterface";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import useCan from "../../hooks/useCan";
import { TypeOfCustomer, VendorType } from "../../interfaces/Accounts";

const entityOptions = [
  ...Object.values(Entity).filter((item) => item !== Entity.RESOURCE_CENTRE),
  TypeOfCustomer.CLIENT,
  VendorType.SERVICE_PROVIDER,
  VendorType.EMPLOYEE
];

export default function HistoryReport(): JSX.Element {
  const dispatch = useDispatch();
  const [historyData, setHistoryData] = React.useState<History[]>([]);
  const [selectedClientId, setSelectedClientId] = React.useState<number | null>(null);
  const [filters, setFilters] = React.useState<FilterProps>({
    from: CalendarFunctions.startOfDay(new Date()).toDate(),
    until: new Date(),
    entity: Entity.BILL
  });

  const handleClose = () => setSelectedClientId(null);

  React.useEffect(() => {
    const getHistory = async () => {
      try {
        const history = await getHistoryReportData(filters);
        setHistoryData(history);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: error?.data?.message || errorFetchMessage(MODULE.HISTORY),
            variant: "error",
            autoTimeout: true
          })
        );
      }
    };
    getHistory();
  }, [filters, dispatch]);

  const isMobileScreen = useMobileScreen();
  const isSuperAdmin = useCan("deny", { superAdminPass: true, supportAccountAdminPass: true });

  return (
    <div>
      <Box marginTop="32px">
        <Box margin="0px 32px">
          <Box marginTop="32px">
            <Filters
              filters={filters}
              onSetFilters={(updatedFilters) => setFilters(updatedFilters)}
              entityOptions={[...entityOptions, ...(isSuperAdmin ? [Entity.RESOURCE_CENTRE] : [])]}
            />
          </Box>
        </Box>
        <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"} mx="auto" overflow="auto">
          <Box
            marginTop="32px"
            width={isMobileScreen ? "960px" : "auto"}
            height="calc(100vh - 230px)"
          >
            <List
              automation="historyReport"
              data={historyData}
              rowHeight={50}
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              columns={[
                {
                  key: "id",
                  label: "Entity Id",
                  sortable: true,
                  formatter: ({ entityId }) => <Typography>{entityId}</Typography>
                },
                {
                  key: "entity",
                  label: "Module",
                  sortable: true,
                  formatter: ({ entity }) => <Typography>{startCase(entity)}</Typography>
                },
                {
                  key: "event",
                  label: "Action",
                  sortable: true,
                  formatter: ({ event }) => <Typography>{startCase(event)}</Typography>
                },
                {
                  key: "username",
                  label: "Created By",
                  sortable: true,
                  formatter: ({ username }) => (
                    // Previously if the activity done by super admin the null null value was set.
                    <Typography>{username === "null null" ? "" : startCase(username)}</Typography>
                  )
                },
                {
                  key: "created_at",
                  label: "Date",
                  sortable: true,
                  // eslint-disable-next-line camelcase
                  formatter: ({ created_at }) => (
                    <Typography>{convertADtoBS(new Date(created_at)).formatted4}</Typography>
                  )
                },
                {
                  key: "clientId",
                  label: "Client ID",
                  sortable: true,
                  formatter: ({ clientId }) => (
                    <Typography
                      onClick={() => setSelectedClientId(clientId)}
                      sx={{ textDecoration: "underline" }}
                      color="primary"
                    >
                      {clientId}
                    </Typography>
                  )
                },
                ...(isSuperAdmin
                  ? [
                      {
                        key: "resourceCentreId",
                        label: "Resource Centre Id",
                        sortable: true,
                        formatter: ({ resourceCentreId }) => (
                          <Typography>{resourceCentreId}</Typography>
                        )
                      }
                    ]
                  : [])
              ]}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
            </List>
            {selectedClientId && <ClientInfo id={selectedClientId} handleViewClose={handleClose} />}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationAdd } from "../actions/notification";
import * as publicApi from "../api/publicBooking";
import { formatPublicEvents } from "../containers/Bookings/helpers";
import {
  FilterProps,
  PublicEvent,
  PublicService,
  PublicServiceInfo,
  ResourceCentreLocation
} from "../interfaces/PublicLabInterface";
import { IThunkDispatch, RootState } from "../store/index";

interface PublicInterface {
  publicServices: PublicService[];
  publicEvents?: PublicEvent[] | null;
  resourceCentreLocations?: ResourceCentreLocation[];
  hasMore: boolean;
}

const initialState: PublicInterface = {
  publicServices: [],
  hasMore: false
};

export const getPublicServices = createAsyncThunk(
  "public/publicService",
  async (
    { filters, dispatch }: { filters: FilterProps; dispatch: IThunkDispatch },
    { rejectWithValue }
  ) => {
    try {
      const response = await publicApi.getPublicServices(filters);
      return response as PublicServiceInfo;
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry! Something Went Wrong while fetching Services.",
          autoTimeout: true
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadMorePublicServices = createAsyncThunk(
  "public/loadMorePublicServices",
  async (
    { filters, dispatch }: { filters: FilterProps; dispatch: IThunkDispatch },
    { rejectWithValue }
  ) => {
    try {
      const response = await publicApi.getPublicServices(filters);
      return response as PublicServiceInfo;
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry! Something Went Wrong while fetching Services.",
          autoTimeout: true
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPublicEvents = createAsyncThunk(
  "public/getEvents",
  async (rcId: number, { rejectWithValue }) => {
    try {
      const response = await publicApi.getPublicEvents(rcId);
      return response.data as PublicEvent[];
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getResourceCentreLocations = createAsyncThunk(
  "public/resourceCentreLocations",
  async (dispatch: IThunkDispatch, { rejectWithValue }) => {
    try {
      const response = await publicApi.getResourceCentreLocations();
      return response.data as ResourceCentreLocation[];
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry! Something Went Wrong while fetching Locations.",
          autoTimeout: true
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const publicLabSlice = createSlice({
  name: "public",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPublicServices.fulfilled,
      (state: RootState, { payload }: { payload: PublicServiceInfo }) => {
        state.publicServices = payload.results;
        state.hasMore = payload.hasMore;
      }
    );
    builder.addCase(
      loadMorePublicServices.fulfilled,
      (state: RootState, { payload }: { payload: PublicServiceInfo }) => {
        state.publicServices = state.publicServices.concat(payload.results);
        state.hasMore = payload.hasMore;
      }
    );
    builder.addCase(getPublicServices.rejected, (state: RootState, action) => {
      console.log(action.payload);
      state.publicServices = [];
    });
    builder.addCase(
      getPublicEvents.fulfilled,
      (state: RootState, { payload }: { payload: PublicEvent[] | null }) => {
        state.publicEvents = formatPublicEvents(payload || []);
      }
    );
    builder.addCase(
      getResourceCentreLocations.fulfilled,
      (state: RootState, { payload }: { payload: ResourceCentreLocation[] | null }) => {
        state.resourceCentreLocations = payload;
      }
    );
    builder.addCase(getResourceCentreLocations.rejected, (state: RootState, action) => {
      console.log(action.payload);
      state.resourceCentreLocations = [];
    });
  }
});

export default publicLabSlice.reducer;

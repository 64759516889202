import { Box, Chip, Link, Typography } from "@mui/material";
import produce from "immer";
import capitalize from "lodash/capitalize";
import { round } from "mathjs";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import * as reportsApi from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import { DocumentTypes, VisitType } from "../../interfaces/BillInterfaces";
import BillShow from "../Billing/BillShow/BillShow";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import Can from "../Policy/Can";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import Filters from "./Filters";
import "./Reports.scss";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  dueReportDefaultCols,
  dueReportHiddenCols,
  getDefaultKeyValuesColumns
} from "../../components/ListHeaderShowHideDialog/helpers";
import { getCustomerNumber } from "../Client/ClientList";
import { BranchReportProps } from "./SalesByServicesReport";
import { RootState } from "../../store";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const or = (val) => val || "";
export const getResourceCentreName = (
  resourceCentres: Array<ResourceCentre>,
  resourceCentreId: number
): string => resourceCentres?.find((rc) => rc.id === resourceCentreId)?.name || "";

const docColumns = (isBranchReport) => [
  ...(isBranchReport ? [t("reports.resourceCentreName")] : []),
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.billedBy"),
  t("reports.services"),
  t("reports.billNumber"),
  t("reports.billingDateBS"),
  t("reports.billingDateAD"),
  t("reports.taxableAmount"),
  t("reports.taxAmount"),
  t("reports.totalAmount"),
  t("reports.cumPayment"),
  t("reports.ageingDays"),
  t("reports.receivableDue")
];

interface DocRowInterface {
  resourceCentreName: string;
  customerNumber: string;
  clientFirstName: string;
  clientLastName: string;
  clientName: string;
  enteredBy: string;
  services: string[];
  billInfo: { [key: string]: string };
  billingDate: Date;
  taxableAmount: number;
  taxAmount: number;
  totalAmount: number;
  cumPayment: number;
  ageingDays: number;
  receivableDue: number;
  // eslint-disable-next-line camelcase
  __meta__row_type: string;
}

const makeDocRowProcessor =
  (isBranchReport) =>
  ({
    customerNumber,
    clientFirstName,
    clientLastName,
    clientName,
    services,
    billInfo,
    billingDate,
    taxableAmount,
    taxAmount,
    totalAmount,
    cumPayment,
    ageingDays,
    receivableDue,
    enteredBy,
    resourceCentreName,
    // eslint-disable-next-line camelcase
    __meta__row_type
  }: DocRowInterface) => {
    // eslint-disable-next-line camelcase
    if (__meta__row_type === "segment_summary") return null;
    return [
      ...(isBranchReport ? [resourceCentreName] : []),
      or(getCustomerNumber(customerNumber)),
      or(clientName),
      or(clientFirstName),
      or(clientLastName),
      or(enteredBy),
      services,
      billInfo.billNumber,
      calFns.bsShortDate(billingDate),
      calFns.adShortDate(billingDate),
      taxableAmount,
      taxAmount,
      totalAmount,
      cumPayment,
      ageingDays,
      receivableDue
    ];
  };

const selectData = (item, resourceCentres) => ({
  clientName: `${capitalize(item.clientFirstName)} ${capitalize(item.clientLastName)}`,
  clientId: item.clientId,
  customerNumber: item.customerNumber,
  clientFirstName: item.clientFirstName,
  clientLastName: item.clientLastName,
  active: item.active,
  services: item.services,
  billInfo: {
    billNumber: item.billNumber,
    billId: item.billId,
    related: item.related,
    type: item.type
  },
  billingDate: new Date(item.issueDate),
  receivableDue: item.receivableDue,
  ageingDays: moment().diff(item.issueDate, "days"), // UI has upto ageing days & remaining for csv
  taxableAmount: item.taxableAmount,
  taxAmount: item.taxAmount,
  totalAmount: item.totalAmount,
  cumPayment: item.paidAmount,
  referrers: item.referrers,
  enteredBy: item.enteredBy,
  resourceCentreId: item.resourceCentreId,
  resourceCentreName: getResourceCentreName(resourceCentres, item.resourceCentreId)
});

const processData = (data, resourceCentres) =>
  data.map((item) => selectData(item, resourceCentres));

const DueReport = ({ isBranchReport = false }: BranchReportProps): JSX.Element => {
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentre?.id);
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    ...(isBranchReport
      ? { resourceCentreIds: resourceCentreId ? [resourceCentreId] : [] }
      : {
          clientIds: [],
          referrersName: [],
          employeeIds: []
        }),
    billType: null,
    usePeriodFilter: false,
    visitType: VisitType.OPD
  });

  const [reportData, setReportData] = React.useState([]);
  const resourceCentres = useSelector((state: RootState) => state.userBranch.collection) || [];
  React.useEffect(() => {
    const processFilters = (newFilters) => {
      if (!newFilters.usePeriodFilter) {
        const updatedFilters = produce(newFilters, (draft) => {
          delete draft.from;
          draft.until = new Date();
        });
        return updatedFilters;
      }
      return newFilters;
    };
    reportsApi.dueReport(processFilters(filters)).then((data) => {
      const grouppedData = data.sort((a, b) => {
        if (a.referrers === null) return 1;
        if (b.referrers === null) return -1;
        return a.referrers > b.referrers ? -1 : 1;
      });
      const newGrouppedData = grouppedData.filter((item) => item.active);
      setReportData(processData(newGrouppedData, resourceCentres));
    });
  }, [filters, resourceCentres]);
  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };
  const [selectedBill, setSelectedBill] = React.useState(null);
  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const handleViewClose = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(
      [...dueReportDefaultCols, ...(isBranchReport ? ["resourceCentre"] : [])],
      dueReportHiddenCols
    )
  );
  const [open, setOpen] = React.useState(false);

  const [clientEditMode, setClientEditMode] = React.useState(false);
  const { isRnWebView } = useIsReactNativeWebView();
  const dispatch = useDispatch();

  return (
    <Can policyAccessKey="report:dueReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1100px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              filters={filters}
              onSetFilters={(newFilters) => applyFilters(newFilters)}
              showPeriodToggle
              hideFiscalYear
            />
          </Box>
          <Box margin="0.5rem 2rem 0">{tl("reports.dueReportFilterInfo")}</Box>
          <Box marginTop="32px" height="calc(100vh - 350px)" className="dueReportList">
            <List
              automation="salesReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="referrers"
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              columns={[
                {
                  key: "referrers",
                  label: "Referrer",
                  sortable: true,
                  ...(isBranchReport
                    ? {}
                    : { segmentable: true, segmentBy: (row) => row.referrers }),
                  formatter: ({ referrers }) => <Typography>{referrers}</Typography>
                },
                ...(isBranchReport
                  ? [
                      {
                        key: "resourceCentre",
                        label: "Branch",
                        formatter: ({ resourceCentreName }) => (
                          <Typography>{resourceCentreName}</Typography>
                        ),
                        sortable: true,
                        segmentable: true,
                        segmentBy: (row) => row.resourceCentreName,
                        sortFunction: (a, b) =>
                          or(a.resourceCentreName).toLowerCase() >
                          or(b.resourceCentreName).toLowerCase()
                            ? 1
                            : -1
                      }
                    ]
                  : []),
                {
                  key: "clientName",
                  label: tl("reports.client"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.clientName).toLowerCase() > or(b.clientName).toLowerCase() ? 1 : -1,
                  formatter: ({
                    clientFirstName,
                    clientLastName,
                    clientId,
                    customerNumber,
                    active
                  }) => (
                    <Typography
                      component="div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      <Box
                        style={{
                          textDecoration: `${active && !isBranchReport ? "underline" : ""}`,
                          cursor: "pointer",
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (active && !isBranchReport) {
                            setSelectedClientId(clientId);
                            setClientInfoPanel(true);
                          }
                        }}
                      >
                        {`${clientFirstName} ${clientLastName}`.toUpperCase()}
                        {active ? "" : "(deleted)"}
                      </Box>
                      <Box fontSize="small" color="gray">
                        {getCustomerNumber(customerNumber)}
                      </Box>
                    </Typography>
                  )
                },
                {
                  key: "services",
                  label: tl("reports.services"),
                  formatter: ({ services }) => {
                    const title = (services || []).length ? `- ${services.join("\n- ")}` : "";
                    return (
                      <Box title={title} justifyContent="center" alignItems="center">
                        {services.map(
                          (service, index) =>
                            index < 2 &&
                            service.length > 0 && (
                              <Chip
                                size="small"
                                label={service}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${service}_${index}`}
                                style={{
                                  maxWidth: services.length > 1 ? "140px" : "100%"
                                }}
                              />
                            )
                        )}
                        {services.length > 2 && "..."}
                      </Box>
                    );
                  }
                },
                {
                  key: "billInfo",
                  label: tl("reports.billNumber"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.billInfo.billNumber).toLowerCase() >
                    or(b.billInfo.billNumber).toLowerCase()
                      ? 1
                      : -1,
                  formatter: ({ billInfo }) => (
                    <Box flex={1} flexDirection="column" alignItems="center" component={Typography}>
                      {isBranchReport ? (
                        <Typography>{billInfo.billNumber}</Typography>
                      ) : (
                        <Link onClick={() => setSelectedBill(billInfo.billId)}>
                          {billInfo.billNumber}
                        </Link>
                      )}
                      {billInfo.type === DocumentTypes.CREDITNOTE && billInfo.related && (
                        <Box fontSize="small" color="gray">
                          {tl("bills.creditNoteFor")} {billInfo.related.billNumber}
                        </Box>
                      )}
                    </Box>
                  )
                },
                {
                  key: "enteredBy",
                  label: "Billed by",
                  formatter: ({ enteredBy }) => <Typography>{enteredBy}</Typography>
                },
                {
                  key: "billingDate",
                  label: tl("reports.billingDate"),
                  formatter: ({ billingDate }) => (
                    <Typography>{calFns.bsFullDate(billingDate)}</Typography>
                  ),
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.billingDate) > new Date(b.billingDate) ? 1 : -1
                },
                {
                  key: "ageingDays",
                  label: tl("reports.ageingDays"),
                  formatter: ({ ageingDays }) => <Typography>{ageingDays}</Typography>
                },
                {
                  key: "taxableAmount",
                  label: "Taxable Amount",
                  formatter: ({ taxableAmount }) => <Typography>{taxableAmount}</Typography>
                },

                {
                  key: "cumPayment",
                  label: "Paid Amt",
                  formatter: ({ cumPayment }) => <Typography>{cumPayment}</Typography>
                },
                {
                  key: "taxAmount",
                  label: "Tax Amt",
                  formatter: ({ taxAmount }) => <Typography>{taxAmount}</Typography>
                },
                {
                  key: "totalAmount",
                  label: "Total Amt",
                  formatter: ({ totalAmount }) => <Typography>{totalAmount}</Typography>
                },
                {
                  key: "receivableDue",
                  label: tl("reports.receivableDue"),
                  formatter: ({ receivableDue }) => (
                    <Typography>{rupeeDisplay(round(receivableDue || 0, 2))}</Typography>
                  )
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(item) => (
                <Box
                  style={{ background: "#e6e6e6" }}
                  display="flex"
                  flexGrow={1}
                  alignItems="center"
                >
                  <Box display="flex" flexGrow={1} component={Typography}>
                    <Box
                      component="span"
                      flexGrow={1}
                      display="flex"
                      padding="8px 32px 4px 20px"
                      fontWeight={500}
                    >
                      {item.segment || "N/A"}
                    </Box>
                  </Box>
                  <Box
                    flexBasis="200px"
                    pt="4px"
                    textAlign="right"
                    paddingRight="30px"
                    component={Typography}
                  >
                    {rupeeDisplay(
                      round(
                        item.rows.reduce((acc, cur) => acc + Number(cur.receivableDue), 0) || 0,
                        2
                      )
                    )}
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            t("reports.dueReport", "en"),
                            formatHeaderForExcel(docColumns(isBranchReport)),
                            formatDataForExcel(
                              getProcessedData(),
                              makeDocRowProcessor(isBranchReport)
                            )
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["referrers"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box
              display="flex"
              flexBasis="230px"
              justifyContent="flex-end"
              paddingRight="30px"
              component={Typography}
            >
              {rupeeDisplay(
                Number.parseFloat(
                  reportData.reduce((acc, cur) => acc + cur.receivableDue, 0)
                ).toFixed(2) || 0
              )}
            </Box>
          </Box>
        </Box>
        {selectedBill && (
          <BillShow
            billId={selectedBill}
            handleViewClose={() => setSelectedBill(null)}
            hideActions
          />
        )}
        {showClientInfoPanel &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClientId}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClientId}
              handleViewClose={handleViewClose}
              stayOnCurrentPage
              setEditMode={setClientEditMode}
            />
          ))}
      </Box>
    </Can>
  );
};

export default DueReport;

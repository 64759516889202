import { chain, round } from "mathjs";
import produce from "immer";
import * as moment from "moment";
import flow from "lodash/flow";
import * as stockActions from "../actions/stock";
import { ReducerBuilder } from "./ReducerBuilder";
import * as calFns from "../components/Calendar/functions/calendarFunctions";
import { DiscountBasedOn, DiscountTypes } from "../interfaces/BillInterfaces";
import {
  StateStockInterface,
  StockItemInterface,
  paymentOptionsEnum,
  Currency,
  Entry
} from "../interfaces/StockInterfaces";

export const defaultDiscountSettings: { discountBasis; discountType } = {
  discountBasis: DiscountBasedOn.inline,
  discountType: DiscountTypes.percentage
};

function generateBatchId() {
  const date = calFns.convertADtoBS(new Date(moment().toISOString()))?.formatted.split("/");
  const monthDay = (index) => (date[index].length > 1 ? date[index] : 0 + date[index]);
  const batchId = date[0].slice(2, 4) + monthDay(1) + monthDay(2);
  return batchId;
}

export const getTemplateStockItem = (): StockItemInterface => ({
  sNo: 1,
  productName: "",
  productId: null,
  expiryDate: moment().add(2, "years").month("jan").date(1).toISOString(),
  quantity: 1,
  unit: "pcs",
  price: 0,
  batchId: generateBatchId(),
  grossTotal: 0,
  package: "Box",
  unitsPerPackage: 0,
  unitPriceExcVAT: 0,
  unitPriceIncVAT: 0,
  calculationBasis: "perUnit",
  isFree: false,
  vatPct: 0,
  discountPct: 0,
  netTotal: 0,
  discountAmt: 0,
  salesVatPct: 0,
  hasFreeItems: false,
  freeItemQuantity: 0,
  carryingChargePct: 0,
  carryingChargeAmt: 0,
  carryingChargePctOfFreeItems: 0,
  carryingChargeAmtOfFreeItems: 0,
  rateOfFreeItems: 0,
  netTotalOfFreeItems: 0,
  discountPctOfFreeItems: 0,
  discountAmtOfFreeItems: 0,
  vatPctOfFreeItems: 0,
  vatAmtOfFreeItems: 0,
  grossTotalOfFreeItems: 0,
  grossTotalIncVatOfFreeItems: 0,
  currency: Currency.Rs,
  purchaseLedgerId: null,
  purchaseTaxationId: null
});

export const initialEntryState = {
  paymentType: paymentOptionsEnum.credit,
  date: moment().toISOString(),
  voucherDate: moment().toISOString(),
  paidAll: false,
  paidAmount: 0,
  totalAmount: 0,
  settings: {
    discountSettings: defaultDiscountSettings
  },
  summary: {
    taxAmt: 0,
    discountAmt: 0,
    totalIncVat: 0,
    rates: {},
    roundOffAmt: "",
    totalDiscountPct: 0,
    totalCcAmt: 0,
    totalVatAmtOfFreeItems: 0
  },
  status: "",
  paymentInfo: {
    balance: 0,
    fromBalance: false,
    paidFromBalance: 0,
    cashPayment: 0
  },
  supplierId: null,
  stockItems: []
};

const INITIAL_STATE: StateStockInterface = {
  entry: initialEntryState,
  stocks: [],
  stockTransactions: [],
  recordPayments: [],
  total: 0
};

function clearEntry(state) {
  return {
    ...state,
    entry: {
      settings: {
        discountSettings: defaultDiscountSettings
      },
      stockItems: [],
      paymentType: paymentOptionsEnum.credit,
      date: null,
      paidAll: true,
      paidAmount: 0,
      summary: {
        taxAmt: 0,
        discountAmt: 0,
        totalIncVat: 0,
        rates: {},
        roundOffAmt: "",
        totalDiscountPct: 0
      },
      paymentInfo: {
        balance: 0,
        fromBalance: false,
        paidFromBalance: 0,
        cashPayment: 0
      },
      supplierId: null
    }
  };
}

function getStockTransactions(state, payload) {
  return { ...state, stockTransactions: payload.results, total: payload.total };
}

function getStocks(state, payload) {
  return { ...state, stocks: [...state.stocks, ...payload] };
}

export const updatesNo = (entry: Entry): Entry => ({
  ...entry,
  stockItems: entry.stockItems.map((stockItem, i) => ({
    ...stockItem,
    sNo: i + 1
  }))
});

function addNewRow(state) {
  let newEntry = {
    ...state.entry,
    stockItems: [...state.entry.stockItems, getTemplateStockItem()]
  };
  newEntry = updatesNo(newEntry);
  return {
    ...state,
    entry: newEntry
  };
}

function calculateStockItems(entry) {
  const stockItems = entry.stockItems?.map((stockItem) => {
    let newStockItem;
    if (stockItem.calculationBasis === "grossTotal") {
      newStockItem = produce(stockItem, (draft) => {
        draft.grossTotal = round(draft.grossTotal, 2);

        draft.discountAmt = round(
          chain(draft.grossTotal)
            .multiply(draft.discountPct / 100)
            .done(),
          2
        );

        draft.grossTotal = round(
          chain(draft.grossTotal)
            .multiply(1 - draft.discountPct / 100)
            .done(),
          2
        );

        draft.vatAmt = round(
          chain(draft.grossTotal)
            .multiply(draft.vatPct / 100)
            .done(),
          2
        );

        draft.totalIncVat = round(
          chain(draft.grossTotal)
            .multiply(1 + draft.vatPct / 100)
            .done(),
          2
        );

        draft.quantity = draft.quantity || 1;
        draft.price = round(chain(draft.grossTotal).divide(draft.quantity).done(), 2);
        draft.packagePriceExcVAT = round(
          chain(draft.unitPriceExcVAT).multiply(draft.unitsPerPackage).done(),
          2
        );
        draft.packagePriceIncVAT = round(
          chain(draft.unitPriceExcVAT)
            .add((draft.vatPct / 100) * draft.unitPriceExcVAT)
            .multiply(draft.unitsPerPackage)
            .done(),
          2
        );
        draft.unitPriceIncVAT = round(
          chain(draft.unitPriceExcVAT)
            .add((draft.vatPct / 100) * draft.unitPriceExcVAT)
            .done(),
          2
        );
      });
    } else {
      newStockItem = produce(stockItem, (draft) => {
        draft.rateOfFreeItems = round(
          chain(draft.price * (draft.carryingChargePctOfFreeItems / 100)).done(),
          2
        );

        draft.netTotalOfFreeItems = round(draft.freeItemQuantity * draft.rateOfFreeItems || 0, 2);

        if (entry.settings.discountSettings.discountType === DiscountTypes.percentage) {
          draft.discountAmtOfFreeItems = round(
            chain(draft.netTotalOfFreeItems)
              .multiply(draft.discountPctOfFreeItems / 100)
              .done() || 0,
            2
          );
        }
        if (entry.settings.discountSettings.discountType === DiscountTypes.amount) {
          draft.discountPctOfFreeItems = round(
            chain(draft.discountAmtOfFreeItems / (draft.netTotalOfFreeItems || 1))
              .multiply(100)
              .done() || 0,
            2
          );
        }

        draft.grossTotalOfFreeItems = round(
          draft.netTotalOfFreeItems - draft.discountAmtOfFreeItems,
          2
        );

        draft.vatAmtOfFreeItems = round(
          chain(draft.grossTotalOfFreeItems)
            .multiply(draft.vatPctOfFreeItems / 100)
            .done() || 0,
          2
        );

        draft.grossTotalIncVatOfFreeItems = round(
          draft.grossTotalOfFreeItems + draft.vatAmtOfFreeItems || 0,
          2
        );

        draft.grossTotal = round(draft.netTotal || 0, 2);

        if (entry.settings.discountSettings.discountBasis === DiscountBasedOn.inline) {
          if (entry.settings.discountSettings.discountType === DiscountTypes.percentage) {
            draft.discountAmt = round(
              chain(draft.grossTotal)
                .multiply(draft.discountPct / 100)
                .done(),
              2
            );
            draft.grossTotal = round(
              chain(draft.grossTotal)
                .multiply(1 - draft.discountPct / 100)
                .done(),
              2
            );
          }
          if (entry.settings.discountSettings.discountType === DiscountTypes.amount) {
            draft.discountPct =
              (draft.discountAmt &&
                round(chain(draft.discountAmt).divide(draft.netTotal).multiply(100).done(), 2)) ||
              0;
            draft.grossTotal = round(chain(draft.grossTotal).subtract(draft.discountAmt).done(), 2);
          }
        }

        draft.vatAmt = round(
          chain(draft.grossTotal)
            .multiply((draft.vatPct || 0) / 100)
            .done(),
          2
        );

        draft.totalIncVat = round(
          chain(draft.grossTotal)
            .multiply(1 + (draft.vatPct || 0) / 100)
            .done(),
          2
        );

        draft.packagePriceExcVAT = round(
          chain(draft.unitPriceExcVAT || 0)
            .multiply(draft.unitsPerPackage)
            .done(),
          2
        );
        draft.packagePriceIncVAT = round(
          chain(draft.unitPriceExcVAT || 0)
            .add(((draft.vatPct || 0) / 100) * draft.unitPriceExcVAT)
            .multiply(draft.unitsPerPackage)
            .done(),
          2
        );
        draft.unitPriceIncVAT = round(
          chain(draft.unitPriceExcVAT || 0)
            .add(((draft.vatPct || 0) / 100) * draft.unitPriceExcVAT)
            .done(),
          2
        );
      });
    }
    return newStockItem;
  });
  return { ...entry, stockItems };
}

function calculateStockSummary(entry: Entry) {
  let totalDiscountPct = 0;
  let totalDiscount = 0;
  let totalSum = 0;
  let totalIncVat = 0;
  let stockItems;

  const totalCcAmt = round(
    entry.stockItems?.reduce((acc, stockItem) => acc + stockItem.grossTotalOfFreeItems, 0) || 0,
    2
  );

  const totalVatAmtOfFreeItems = round(
    entry.stockItems?.reduce(
      (acc, stockItem: StockItemInterface) => acc + stockItem.vatAmtOfFreeItems,
      0
    ) || 0,
    2
  );
  stockItems = entry.stockItems;
  if (entry.settings.discountSettings.discountBasis === DiscountBasedOn.inline) {
    totalSum = round(
      entry.stockItems?.reduce(
        (acc, stockItem) => acc + stockItem.grossTotal + stockItem.grossTotalOfFreeItems,
        0
      ) || 0,
      2
    );

    totalDiscount = round(
      entry.stockItems?.reduce((acc, stockItem) => acc + Number(stockItem.discountAmt), 0) || 0,
      2
    );
    totalDiscountPct =
      totalDiscount &&
      round(
        chain(totalDiscount)
          .divide(totalSum + totalDiscount)
          .multiply(100)
          .done(),
        2
      );
    totalIncVat = round(
      entry.stockItems?.reduce((acc, stockItem) => acc + stockItem.totalIncVat, 0) || 0,
      2
    );
  }
  if (entry.settings.discountSettings.discountBasis === DiscountBasedOn.invoice) {
    if (entry.settings.discountSettings.discountType === DiscountTypes.amount) {
      totalDiscount = entry.summary?.discountAmt || 0;
      const totalPrice = round(
        entry.stockItems?.reduce((acc, stockItem) => acc + stockItem.netTotal, 0) || 0,
        2
      );
      totalDiscountPct = round(
        chain(entry.summary.discountAmt)
          .divide(totalPrice || 1)
          .multiply(100)
          .done(),
        2
      );
      stockItems = entry.stockItems?.map((stockItem) => {
        const newStockItem = produce(stockItem, (draft) => {
          draft.discountPct = totalDiscountPct;
          const disPctWithoutRound = chain(entry.summary.discountAmt)
            .divide(totalPrice || 1)
            .multiply(100)
            .done();
          draft.discountAmt = round(
            chain(draft.netTotal).multiply(disPctWithoutRound).divide(100).done(),
            2
          );
          draft.grossTotal = round(
            chain(draft.netTotal || 0)
              .subtract(draft.discountAmt)
              .done(),
            2
          );
          draft.vatAmt = round(
            chain(draft.grossTotal)
              .multiply((draft.vatPct || 0) / 100)
              .done(),
            2
          );
          draft.totalIncVat = round(
            chain(draft.grossTotal)
              .multiply(1 + (draft.vatPct || 0) / 100)
              .done(),
            2
          );
        });
        return newStockItem;
      });

      totalSum = round(
        stockItems?.reduce(
          (acc, stockItem) => acc + stockItem.grossTotal + stockItem.grossTotalOfFreeItems,
          0
        ) || 0,
        2
      );
      totalIncVat = round(
        stockItems?.reduce((acc, stockItem) => acc + stockItem.totalIncVat, 0) || 0,
        2
      );
    }
    if (entry.settings.discountSettings.discountType === DiscountTypes.percentage) {
      totalDiscountPct = entry.summary.totalDiscountPct;

      stockItems = entry.stockItems?.map((stockItem) => {
        const newStockItem = produce(stockItem, (draft) => {
          draft.discountPct = totalDiscountPct;
          draft.discountAmt = round(
            chain(draft.netTotal || 0)
              .multiply(draft.discountPct)
              .divide(100)
              .done(),
            2
          );
          draft.grossTotal = round(
            chain(draft.netTotal || 0)
              .subtract(draft.discountAmt)
              .done(),
            2
          );
          draft.vatAmt = round(
            chain(draft.grossTotal)
              .multiply((draft.vatPct || 0) / 100)
              .done(),
            2
          );
          draft.totalIncVat = round(
            chain(draft.grossTotal)
              .multiply(1 + (draft.vatPct || 0) / 100)
              .done(),
            2
          );
        });
        return newStockItem;
      });
      totalSum = round(
        stockItems?.reduce(
          (acc, stockItem) => acc + stockItem.grossTotal + stockItem.grossTotalOfFreeItems,
          0
        ) || 0,
        2
      );
      totalIncVat = round(
        stockItems?.reduce((acc, stockItem) => acc + stockItem.totalIncVat, 0) || 0,
        2
      );
      const netTotal = round(
        stockItems?.reduce((acc, stockItem) => acc + stockItem.netTotal, 0) || 0,
        2
      );
      totalDiscount = round(chain(totalDiscountPct).multiply(netTotal).divide(100).done(), 2);
    }
  }

  const rates = {};
  stockItems?.forEach((stockItem) => {
    const vatPctKey = stockItem.vatPct.toString();
    if (rates[vatPctKey]) {
      rates[vatPctKey] += stockItem.vatAmt;
    } else {
      rates[vatPctKey] = stockItem.vatAmt;
    }
  });

  const totalAddingRoundOff = (Number(entry.summary?.roundOffAmt) || 0) + totalIncVat;
  const paidAmount =
    entry.paymentType === paymentOptionsEnum.credit ? 0 : round(entry.paidAmount, 2);
  return {
    ...entry,
    paidAmount,
    stockItems,
    summary: {
      taxAmt: totalSum,
      discountAmt: totalDiscount,
      totalIncVat: round(totalAddingRoundOff + totalCcAmt + totalVatAmtOfFreeItems, 2),
      rates,
      roundOffAmt: entry.summary?.roundOffAmt,
      totalDiscountPct,
      totalCcAmt,
      totalVatAmtOfFreeItems
    }
  };
}

function removeRow(state, sNo) {
  const updatedItems = produce(state.entry.stockItems, (draft) =>
    draft.filter((item, index) => index !== sNo)
  );
  let newEntry = {
    ...state.entry,
    stockItems: updatedItems
  };
  newEntry = updatesNo(newEntry);
  newEntry = calculateStockSummary(newEntry);
  return {
    ...state,
    entry: newEntry
  };
}

export const calculateBalanceAmount = (entry: Entry): Entry =>
  produce(entry, (draft) => {
    if (draft?.paymentInfo?.fromBalance) {
      const remaining = entry.paymentInfo.balance - Number(entry.totalAmount);
      if (remaining >= 0) {
        draft.paymentInfo.paidFromBalance = Number(entry.totalAmount);
        draft.paymentInfo.cashPayment = 0;
        draft.paidAmount =
          entry.paymentType === paymentOptionsEnum.credit
            ? 0
            : draft?.paymentInfo?.cashPayment || 0 + draft?.paymentInfo?.paidFromBalance || 0;
      } else {
        draft.paymentInfo.paidFromBalance = Number(draft.paymentInfo.balance);
        draft.paidAmount =
          entry.paymentType === paymentOptionsEnum.credit
            ? 0
            : (draft?.paymentInfo?.paidFromBalance || 0) + (draft?.paymentInfo?.cashPayment || 0);
      }
      draft.paidAll = draft.totalAmount <= draft.paidAmount;
    } else {
      draft.paymentInfo.paidFromBalance = 0;
      draft.paymentInfo.cashPayment = 0;
    }
  });

function calculateEntryValues(entry) {
  return flow([calculateStockItems, calculateStockSummary, calculateBalanceAmount])(entry);
}

function updateStockTransactionEntry(state, payload) {
  const calculatedEntry = calculateEntryValues(payload);
  return { ...state, entry: updatesNo(calculatedEntry) };
}

export function getRelatedStock(
  stocks: StockItemInterface[],
  productId: number,
  batchId: number
): StockItemInterface | null {
  if (!productId || !batchId) return null;
  return (
    stocks.find(
      (stock) => stock.productId === productId && Number(stock.batchId) === Number(productId)
    ) || null
  );
}

const updateStockTransaction = (state, payload) => {
  if (!payload) return state;
  return {
    ...state,
    stockTransactions: payload.results,
    total: payload.total
  };
};

const cancelPayment = (state, payload) => {
  const updatedTransactions = produce(state, (draft) => {
    const index = draft.stockTransactions.findIndex((transaction) => transaction.id === payload.id);
    if (index !== -1) draft.stockTransactions[index] = payload;
  });
  return updatedTransactions;
};

const updateStockSettings = (state, payload) => {
  const updatedState = produce(state, (draft) => {
    draft.entry.settings.discountSettings = payload;
  });
  return updatedState;
};

const updateEntryOnProductUpdate = (state, payload) => {
  if (Array.isArray(state.entry.stockItems) && state.entry.stockItems.length) {
    const newState = produce(state, (draft) => {
      draft.entry.stockItems = draft.entry.stockItems.map((item) => {
        if (item.productId === payload.productId) {
          return {
            ...item,
            package: payload.productDetails.package,
            unit: payload.productDetails.unit,
            unitsPerPackage: payload.productDetails.unitsPerPackage
          };
        }
        return item;
      });
    });
    return newState;
  }
  return state;
};

const editOpeningStock = (state, payload) => {
  const updatedState = produce(state, (draft) => {
    draft.stockTransactions.filter((item) => item.id !== payload.id);
    draft.stockTransactions.push(payload);
  });
  return updatedState;
};

const getRecordPaymentByTrxId = (state, payload) =>
  produce(state, (draft) => {
    draft.recordPayments = payload;
  });

const updateTrxRecordPayment = (state, payload) =>
  produce(state, (draft) => {
    if (payload.length) {
      const trxId = payload[0].stockTransactionId;
      draft.recordPayments = draft.recordPayments.filter(
        ({ stockTransactionId }) => Number(stockTransactionId) !== Number(trxId)
      );
    }
    draft.recordPayments = [...draft.recordPayments, ...payload];
  });

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(stockActions.Type.UPDATE_STOCK_TRANSACTION_ENTRY, updateStockTransactionEntry)
  .mapAction(stockActions.Type.GET_STOCK_TRANSACTIONS, getStockTransactions)
  .mapAction(stockActions.Type.ADD_NEW_ITEM_ROW, addNewRow)
  .mapAction(stockActions.Type.REMOVE_ITEM_ROW, removeRow)
  .mapAction(stockActions.Type.GET_STOCKS, getStocks)
  .mapAction(stockActions.Type.GET_STOCKS_BY_PRODUCT_ID, getStocks)
  .mapAction(stockActions.Type.GET_STOCKS_BY_PRODUCT_IDS, getStocks)
  .mapAction(stockActions.Type.CLEAR_ENTRY, clearEntry)
  .mapAction(stockActions.Type.UPDATE_STOCK_TRANSACTION, updateStockTransaction)
  .mapAction(stockActions.Type.UPDATE_STOCK_SETTINGS, updateStockSettings)
  .mapAction(stockActions.Type.UPDATE_STOCK_ENTRY_ON_PRODUCT_UPDATE, updateEntryOnProductUpdate)
  .mapAction(stockActions.Type.EDIT_OPENING_STOCK, editOpeningStock)
  .mapAction(stockActions.Type.GET_RECORD_PAYMENT_BY_TRX_ID, getRecordPaymentByTrxId)
  .mapAction(stockActions.Type.UPDATE_TRX_RECORD_PAYMENT, updateTrxRecordPayment)
  .mapAction(stockActions.Type.CANCEL_PAYMENT, cancelPayment)
  .build();
export default reducer;

import produce from "immer";

import { ReducerBuilder } from "./ReducerBuilder";
import * as IpdClientActions from "../actions/ipd";
import { IPD } from "../interfaces/IpdInterface";

interface State {
  collection: Array<any>;
  selectedIpd: IPD | null;
  totalIpds: number;
}

const INITIAL_STATE: State = {
  collection: [],
  selectedIpd: null,
  totalIpds: 0
};

const setIpdRecords = (state, payload) => ({
  ...state,
  collection: payload.results || [],
  totalIpds: payload.total
});

const createIpdRecord = (state, payload) => {
  const updatedState = produce(state, (draft) => {
    draft.collection.push(payload);
  });
  return updatedState as any;
};

const updateIpdRecord = (state, payload) => {
  const updatedState = produce(state as State, (draft) => {
    draft.collection = state.collection.map((item) => (item.id === payload.id ? payload : item));
    draft.selectedIpd = payload;
  });
  return updatedState;
};
function reoveIpdRecord(state, id) {
  const newCollection = [...state.collection].filter((client) => client.id !== id);
  return { ...state, collection: newCollection };
}
function createIpdProgressRecord(state, payload) {
  const newCollection = state.collection.map((ipd) => (ipd.id === payload.id ? payload : ipd));
  return { ...state, collection: newCollection, selectedIpd: payload };
}
function createIpdPrescribeMedication(state, payload) {
  const newCollection = [...state.collection].filter((ipd) => ipd.id !== payload.id);
  newCollection.push(payload);
  return { ...state, collection: newCollection, selectedIpd: payload };
}

function createDischargeSummary(state, payload) {
  const newCollection = [...state.collection].filter((ipd) => ipd.id !== payload.id);
  newCollection.push(payload);
  return { ...state, collection: newCollection };
}

function setIpdDetails(state, payload) {
  return { ...state, selectedIpd: payload };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(IpdClientActions.Type.GET_IPD_RECORDS, setIpdRecords)
  .mapAction(IpdClientActions.Type.GET_IPD_RECORD, setIpdDetails)
  .mapAction(IpdClientActions.Type.CREATE_IPD_RECORD, createIpdRecord)
  .mapAction(IpdClientActions.Type.DELETE_IPD_RECORD, reoveIpdRecord)
  .mapAction(IpdClientActions.Type.UPDATE_IPD_RECORD, updateIpdRecord)
  .mapAction(IpdClientActions.Type.CREATE_DISCHARGE_SUMMARY, createDischargeSummary)
  .mapAction(IpdClientActions.Type.CREATE_IPD_PROGRESS_RECORD, createIpdProgressRecord)
  .mapAction(IpdClientActions.Type.CREATE_IPD_PRESCRIBE_MEDICATION, createIpdPrescribeMedication)

  .build();
export default reducer;

// selectors

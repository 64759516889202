/* eslint-disable func-names, no-undef, one-var, no-var */
const FB_PAGE_ID = "194506957840321";

// Code snippet from facebook developer console.

// initialize facebook chat
export function initialize() {
  var chatbox = document.getElementById("fb-customer-chat");
  chatbox.setAttribute("page_id", FB_PAGE_ID);
  chatbox.setAttribute("attribution", "biz_inbox");

  window.fbAsyncInit = function () {
    FB.init({
      xfbml: true,
      version: "v14.0"
    });
  };
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
}

// cleanup facebook chat
export function cleanup() {
  (function (d, id) {
    var target = d.getElementById(id);
    if (target) {
      target.parentNode.removeChild(target);
    }
  })(document, "facebook-jssdk");

  delete window.FB;
}

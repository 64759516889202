import { Button } from "@mui/material";
import React from "react";
import LedgerSelect from "../../containers/ResourceCentre/Settings/AccountSettings/LedgerSelect";
import { ChildGeneralLedger } from "../../interfaces/Accounts";
import Modal from "../Modal/Modal";

interface Props {
  options: ChildGeneralLedger[];
  onSave: (val: ChildGeneralLedger) => void;
  open: boolean;
  title: string;
  onCancel: () => void;
}

export default function LedgerSelectionModal({
  options,
  onSave,
  open,
  title,
  onCancel
}: Props): JSX.Element {
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger | null>(null);

  return (
    <Modal
      open={open}
      title={title || ""}
      footer={
        <>
          <Button
            onClick={() => {
              setSelectedLedger(null);
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedLedger}
            onClick={async () => {
              if (selectedLedger) {
                onSave(selectedLedger);
              }
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <LedgerSelect
        options={options || []}
        selected={selectedLedger}
        onChange={(value) => setSelectedLedger(value)}
      />
    </Modal>
  );
}

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { buildResourcesReducer } from "./ReducerBuilder";
import ongoing from "./ongoing";
import flashMessages from "./flashMessages";
import userContext from "./userContext";
import clients from "./client";
import slots from "./slots";
import navigation from "./navigation";
import assessments from "./assessments";
import bills from "./bills";
import services from "./services";
import bookings from "./bookings";
import dashboard from "./dashboard";
import reminders from "./reminders";
import error from "./error";
import labTest from "./labTest";
import ui from "./ui";
import schedules from "./schedules";
import vitals from "./vitals";
import referrers from "./referrers";
import stock from "./stock";
import stockProducts from "./stockProducts";
import bookableResources from "./bookableResources";
import audience from "./audience";
import template from "./template";
import campaigns from "./campaigns";
import feeds from "./feeds";
import serviceTags from "./serviceTags";
import addressOptions from "./addressOptions";
import ipd from "./ipd";
import ondemandProducts from "./ondemandProducts";
import medicalTest from "./medicalTest";
import hmis from "./hmis";
import accessingResourceCentres from "./accessingResourceCentres";
import authenticateSlice from "../slices/authenticateSlice";
import resourceCentreNotificationSlice from "../slices/resourceCentreNotificationSlice";
import subscriptionsSlice from "../slices/subscriptionSlice";
import appStatusSlice from "../slices/appStatusSlice";
import { RootState } from "../store";
import resourceCentreUserGroupsSlice from "../slices/resourceCentreUserGroupsSlice";
import journalEntrySlice from "../slices/journalEntry";
import supplierSlice from "../slices/supplierSlice";
import labTestSettingsSlice from "../slices/labTestSettingsSlice";
import labRecordSlice from "../slices/labRecordSlice";
import estimatesSlice from "../slices/estiamtesSlice";
import departmentSlice from "../slices/departmentSlice";
import commissionSlice from "../slices/commission";
import publicProfileSlice from "../slices/publicProfileSlice";
import publicServiceSlice from "../slices/publicServiceSlice";
import labSummarySlice from "../slices/labSummarySlice";
import publicLabSlice from "../slices/publicLab";
import bedManagemetSlice from "../slices/bedManagementSlice";
import departmentServiceProviderSlice from "../slices/departmentServiceProviderSlice";
import associateCompanySlice from "../slices/associateCompanySlice";
import userBranchSlice from "../slices/userBranchSlice";
import twoFactorAuthInfoSlice from "../slices/twoFactorInfoSlice";
import userGroupSlice from "../slices/userGroupSlice";
import chatSlice from "../slices/chatSlice";

const resources = buildResourcesReducer([
  "resourceCentre",
  "resourceCentreEmployee",
  "resourceCentreServiceProvider",
  "supplier"
]);

const appReducer = (history) => {
  const router = connectRouter(history);
  return combineReducers({
    authenticated: authenticateSlice,
    resourceCentreNotification: resourceCentreNotificationSlice,
    inMaintenance: appStatusSlice,
    ongoing,
    resources,
    flashMessages,
    router,
    userContext,
    slots,
    clients,
    audience,
    template,
    campaigns,
    navigation,
    assessments,
    bills,
    services,
    bookings,
    dashboard,
    reminders,
    error,
    labTest,
    ui,
    schedules,
    vitals,
    referrers,
    stock,
    stockProducts,
    bookableResources,
    feeds,
    serviceTags,
    addressOptions,
    ipd,
    ondemandProducts,
    medicalTest,
    hmis,
    accessingResourceCentres,
    subscriptions: subscriptionsSlice,
    resourceCentreUserGroups: resourceCentreUserGroupsSlice,
    accounts: journalEntrySlice,
    suppliers: supplierSlice,
    labSettings: labTestSettingsSlice,
    labRecords: labRecordSlice,
    departments: departmentSlice,
    commissions: commissionSlice,
    publicProfile: publicProfileSlice,
    labSummary: labSummarySlice,
    public: publicLabSlice,
    publicServices: publicServiceSlice,
    bedManagement: bedManagemetSlice,
    departmentServiceProviders: departmentServiceProviderSlice,
    estimates: estimatesSlice,
    associateCompanies: associateCompanySlice,
    userBranch: userBranchSlice,
    twoFactorAuthInfo: twoFactorAuthInfoSlice,
    userGroups: userGroupSlice,
    chats: chatSlice
  });
};

export default (history: History) =>
  (state: RootState, action?: { type: string }): RootState => {
    if (action.type === "authenticated/logOut") {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
    return appReducer(history)(state, action);
  };

import React from "react";
import { push } from "connected-react-router";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import ArrowDropRight from "@mui/icons-material/KeyboardArrowRight";
import ArrowDropDown from "@mui/icons-material/KeyboardArrowDown";
import { Box, Link, StepLabel, Typography, Button } from "@mui/material";
import moment from "moment";
import startCase from "lodash/startCase";
import className from "../../helpers/classNames";
import { tl, t } from "../../components/translate";
import * as calendarFns from "../../components/Calendar/functions/calendarFunctions";
import styles from "./Assessment.module.css";
import OkhatiDialog from "../../components/Dialog/Dialog";
import AssessmentInfoPanel from "./AssessmentInfoPanel";
import { Assessment as IAssessment, Assessment } from "../../interfaces/AssessmentInterfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toStartCase } from "../../helpers/formatters";
import useGetDepartments from "../../hooks/department";
import { spFullNameSelector } from "../../reducers/serviceProvider";

const CircleStepIcon = ({ order, assessment, isOpen }): JSX.Element => (
  <Button className="btnCollapseItem">
    <Box display="flex" component="div" alignItems="center">
      <Box
        width="1.5rem"
        borderRadius="50%"
        height="1.5rem"
        component="span"
        fontSize="12px"
        fontWeight={600}
        bgcolor="primary.main"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#fff"
      >
        {order}
      </Box>
      <Box component="div" display="flex" px={1}>
        <Typography>
          <Box component="span" fontSize="14px" fontWeight={600}>
            {calendarFns.convertADtoBS(moment(assessment.createdDate) || moment()).formatted2}
          </Box>
          <Box component="span" fontSize="14px" fontWeight={600} mx={0.5}>
            -
          </Box>
          <Box component="span" fontSize="14px" fontWeight={600}>
            {tl("assessment.history.visit")}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {isOpen ? <ArrowDropDown /> : <ArrowDropRight />}
      </Box>
    </Box>
  </Button>
);

const ToggleStepper = ({ initIsOpen, children, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(initIsOpen || false);
  return children(isOpen, setIsOpen, props);
};

interface Props {
  assessments: Assessment[];
  setAssessmentCopy?: React.Dispatch<React.SetStateAction<IAssessment>>;
}

const History = ({ assessments, setAssessmentCopy }: Props): JSX.Element => {
  const [collapseHistory, setCollapseHistory] = React.useState(true);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [currentSelectedAssessment, setCurrentSelectedAssessment] = React.useState({});
  const [isInfoPanelOpen, setIsInfoPanelOpen] = React.useState(false);
  const authenticable = useAppSelector((state) => state.userContext.userCreds.authenticable);
  const serviceProviders = useAppSelector(
    (state) => state.resources.resourceCentreServiceProviders
  );
  const departments = useGetDepartments();
  const dispatch = useAppDispatch();

  return (
    <Box pl="8px" pr="8px">
      <Box
        pl="8px"
        pt={2}
        pb={2}
        display="flex"
        alignItems="center"
        onClick={() => setCollapseHistory(!collapseHistory)}
      >
        <Typography>
          <Box fontSize="16px" fontWeight={700} component="span">
            {tl("assessment.history")}
          </Box>
        </Typography>
        {collapseHistory ? (
          <ArrowDropRight className={styles.titleArrow} />
        ) : (
          <ArrowDropDown className={styles.titleArrow} />
        )}
      </Box>
      <Box
        className={className(styles.historyBlock, {
          [styles.collapseHistory]: collapseHistory
        })}
      >
        {assessments.length > 0 ? (
          <Stepper orientation="vertical">
            {assessments.map((assessment, i) => {
              const servicesAndTests = [...(assessment?.tests || []), ...assessment?.services];
              const currentServiceProvider = serviceProviders.find(
                (item) => item.id === assessment.serviceProviderId
              );
              const department = departments.find((item) => item.id === assessment.departmentId);
              const referToSp = serviceProviders.find((item) => item.id === assessment.referToSpId);
              const referToSpDepartment = departments.find(
                (item) => item.id === assessment.referToSpDepartmentId
              );

              return (
                <ToggleStepper key={assessment.id} initIsOpen={i === 0 || false}>
                  {(isOpen, setIsOpen, props) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Step {...props} completed={false} disabled={false} active>
                      <StepLabel
                        StepIconComponent={() => (
                          <CircleStepIcon order={i + 1} assessment={assessment} isOpen={isOpen} />
                        )}
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      />
                      <StepContent>
                        {isOpen && (
                          <>
                            {currentServiceProvider && (
                              <Typography gutterBottom>{`${spFullNameSelector(
                                currentServiceProvider
                              )} ${department ? `(${department.name})` : ""}`}</Typography>
                            )}
                            {referToSp && (
                              <Typography gutterBottom>{`Refer to ${spFullNameSelector(
                                referToSp
                              )} ${
                                referToSpDepartment ? `(${referToSpDepartment.name})` : ""
                              }`}</Typography>
                            )}
                            {assessment?.diagnosis?.diagnosis && (
                              <>
                                <Typography>
                                  <Box
                                    display="block"
                                    component="span"
                                    fontSize="14px"
                                    fontWeight={600}
                                  >
                                    {tl("reports.diagnosis")}
                                  </Box>

                                  <Box component="span" fontSize="14px">
                                    {assessment.diagnosis.diagnosis}
                                  </Box>
                                </Typography>
                              </>
                            )}
                            {assessment?.symptoms?.length > 0 && (
                              <>
                                <Typography>
                                  <Box
                                    display="block"
                                    component="span"
                                    fontSize="14px"
                                    fontWeight={600}
                                  >
                                    {tl("reports.symptoms")}
                                  </Box>

                                  <Box component="span" fontSize="14px">
                                    {assessment?.symptoms?.length &&
                                      Array.isArray(assessment.symptoms) &&
                                      assessment.symptoms.map((sData, index) => (
                                        <Box component="span" key={sData.symptom}>
                                          {`${index ? ", " : ""}${toStartCase(sData.symptom)}`}
                                        </Box>
                                      ))}
                                  </Box>
                                </Typography>
                              </>
                            )}
                            {servicesAndTests.length > 0 && (
                              <>
                                <Typography>
                                  <Box
                                    display="block"
                                    component="span"
                                    fontSize="14px"
                                    fontWeight={600}
                                  >
                                    {tl("assessment.tests")}
                                  </Box>
                                  <Box component="span" fontSize="14px">
                                    {servicesAndTests.length &&
                                      servicesAndTests.map((sData, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Box component="span" key={index}>
                                          {`${index ? ", " : ""}${startCase(sData.name)}`}
                                        </Box>
                                      ))}
                                  </Box>
                                </Typography>
                              </>
                            )}
                            {assessment?.intraOral &&
                              Object.keys(assessment.intraOral).length &&
                              (Object.keys(assessment.intraOral.permanent).length ||
                                Object.keys(assessment.intraOral.primary).length > 0) && (
                                <Typography>
                                  <Box
                                    display="block"
                                    component="span"
                                    fontSize="14px"
                                    fontWeight="600"
                                  >
                                    {tl("assessment.intraOral")}
                                  </Box>
                                  <Box component="span" fontSize="14px">
                                    {Object.keys(assessment.intraOral.permanent).length > 0 && (
                                      <Box display="flex">
                                        <Typography>
                                          <Box component="span" fontSize="14px" fontWeight="600">
                                            {`${t("assessment.permanent")}: `}
                                          </Box>
                                          {Object.keys(assessment.intraOral.permanent).map(
                                            (item, index) => {
                                              if (item.charAt(0) === "1") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.upperRight"
                                                )}`;
                                              }
                                              if (item.charAt(0) === "2") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.upperLeft"
                                                )}`;
                                              }
                                              if (item.charAt(0) === "3") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.lowerLeft"
                                                )}`;
                                              }
                                              if (item.charAt(0) === "4") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.lowerRight"
                                                )}`;
                                              }
                                              return "";
                                            }
                                          )}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                  <Box component="span" fontSize="14px">
                                    {Object.keys(assessment.intraOral.primary).length > 0 && (
                                      <Box display="flex">
                                        <Typography>
                                          <Box component="span" fontSize="14px" fontWeight="600">
                                            {`${t("assessment.primary")}: `}
                                          </Box>
                                          {Object.keys(assessment.intraOral.primary).map(
                                            (item, index) => {
                                              if (item.charAt(0) === "5") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.upperRight"
                                                )}`;
                                              }
                                              if (item.charAt(0) === "6") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.upperLeft"
                                                )}`;
                                              }
                                              if (item.charAt(0) === "7") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.lowerLeft"
                                                )}`;
                                              }
                                              if (item.charAt(0) === "8") {
                                                return `${index ? ", " : ""}${t(
                                                  "history.lowerRight"
                                                )}`;
                                              }
                                              return "";
                                            }
                                          )}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Typography>
                              )}
                            <Box display="flex">
                              {authenticable !== "client" &&
                                moment().diff(moment(assessment.created_at), "day", true) < 3 && (
                                  <Box pr={1}>
                                    <Link
                                      color="primary"
                                      component="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(
                                          push(
                                            `/assessment/new/${assessment.id}/edit/?clientId=${assessment.clientId}`
                                          )
                                        );
                                      }}
                                    >
                                      <Typography>
                                        <Box
                                          data-testmation="editButton"
                                          fontWeight={500}
                                          component="span"
                                          fontSize="14px"
                                        >
                                          {tl("edit")}
                                        </Box>
                                      </Typography>
                                    </Link>
                                  </Box>
                                )}
                              <Box pr={1}>
                                <Link
                                  color="primary"
                                  component="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentSelectedAssessment(assessment);
                                    setIsInfoPanelOpen(true);
                                  }}
                                >
                                  <Typography>
                                    <Box
                                      data-testmation="seeFullButton"
                                      fontWeight={500}
                                      component="span"
                                      fontSize="14px"
                                    >
                                      {tl("assessment.seeFull")}
                                    </Box>
                                  </Typography>
                                </Link>
                              </Box>

                              {setAssessmentCopy && (
                                <Box>
                                  <Link
                                    color="primary"
                                    component="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCurrentSelectedAssessment(assessment);
                                      setIsDialogOpen(true);
                                    }}
                                  >
                                    <Typography>
                                      <Box
                                        data-testmation="carryForwardButton"
                                        fontWeight={500}
                                        component="span"
                                        fontSize="14px"
                                      >
                                        {tl("assessment.carryForward")}
                                      </Box>
                                    </Typography>
                                  </Link>
                                </Box>
                              )}
                            </Box>
                          </>
                        )}
                      </StepContent>
                    </Step>
                  )}
                </ToggleStepper>
              );
            })}
          </Stepper>
        ) : (
          <Box textAlign="center" mb={3} width={1}>
            <div className="Dashboard__liveFlow-null-appointments" />
            {tl("assessment.noHistory")}
          </Box>
        )}
      </Box>
      {isInfoPanelOpen && (
        <AssessmentInfoPanel
          onClose={() => setIsInfoPanelOpen(false)}
          assessment={currentSelectedAssessment}
          setAssessmentCopy={setAssessmentCopy}
        />
      )}
      {isDialogOpen && (
        <OkhatiDialog
          title={tl("assessment.carryForwardTitle")}
          description={tl("assessment.confirmCarryForward")}
          next={async () => {
            setAssessmentCopy({ ...currentSelectedAssessment, createdDate: new Date() });
            dispatch(push(`/assessment/new/copy?clientId=${currentSelectedAssessment.clientId}`));
            setIsDialogOpen(false);
          }}
          cancel={() => {
            setIsDialogOpen(false);
          }}
          readMode={false}
        />
      )}
    </Box>
  );
};

export default History;

import "./List.scss";
import * as React from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import VirtualizedList from "react-virtualized/dist/commonjs/List";
import CheckBox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import ListActions from "./ListActions";
import * as ListInterface from "./ListInterface";
import classNames from "../../helpers/classNames";
import hasOwnProperty from "../../helpers/object";

interface State<T> {
  activeColumn: string;
  activeRow: string | number;
  shouldSort: boolean;
  sortOrder: number; // -1 | 1
  processedData: Array<T | ListInterface.SegmentRowType<T>>;
  originalData: ListInterface.DataType[];
  selectedRows: { [key: string]: boolean };
  testmationLabel?: string;
}

export const EmptyView = (props) => (
  <div style={{ height: "100%", width: "100%" }}>{props.children}</div>
);

export const LoadingView = (props) => (
  <div style={{ height: "100%", width: "100%" }}>{props.children}</div>
);

export default class List<T extends ListInterface.DataType> extends React.Component<
  ListInterface.PropsType<T>,
  State<T>
> {
  public static sortFunction = (key) => (a, b) => a[key] > b[key] ? 1 : a[key] === b[key] ? 0 : -1;

  public static sortData = (data, sortOrder, sortFunction) =>
    data.sort((a, b) => sortOrder * sortFunction(a, b, sortOrder));

  /**
   * Inserts Segment row data to the data array.
   *
   * @static
   * @memberof List
   */
  public static segmentize<U>(rowData, segmentBy) {
    const segmentedData = [];
    let currentSegment: ListInterface.SegmentRowType<U>;
    rowData.forEach((datum) => {
      const segment = segmentBy(datum);
      if (!currentSegment || currentSegment.segment !== segment) {
        currentSegment = {
          id: btoa(unescape(encodeURIComponent(segment))),
          segment,
          rows: [],
          __meta__row_type: "segment_summary"
        };
        segmentedData.push(currentSegment);
      }
      currentSegment.rows.push(datum);
      segmentedData.push(datum);
    });
    return segmentedData;
  }

  constructor(props) {
    super(props);
    this.state = {
      activeColumn: props.defaultSortColumn,
      activeRow: props.defaultActiveRow,
      sortOrder: props.defaultSortOrder || 1,
      originalData: props.data,
      processedData: props.data,
      selectedRows: {}
    };
  }

  public componentDidMount() {
    const activeColumnConfig = this.columnConfig();
    if (activeColumnConfig && activeColumnConfig.sortable) {
      this.setState({ processedData: this.processData() });
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.data, nextProps.data)) {
      const state = {
        originalData: nextProps.data,
        processedData: this.processData(nextProps.data)
      };
      if (this.props.multiSelectable) {
        state.selectedRows = this.trimSelectedRows(nextProps.data);
      }
      this.setState(state);
    }
    if (
      nextProps.defaultSortColumn &&
      nextProps.defaultSortColumn !== this.props.defaultSortColumn
    ) {
      this.setState({
        activeColumn: nextProps.defaultSortColumn
      });
    }
  }

  private trimSelectedRows(newData) {
    if (!Object.keys(this.state.selectedRows).length) {
      return {};
    }
    const selectedRows = {};
    newData.forEach((el) => {
      if (this.state.selectedRows[el.id]) {
        selectedRows[el.id] = true;
      }
    });
    return selectedRows;
  }

  private onColumnHeaderClick(column) {
    const sortOrder = this.state.activeColumn === column ? this.state.sortOrder * -1 : 1;
    this.setState({
      activeColumn: column,
      sortOrder,
      processedData: this.processData(this.props.data, sortOrder, column)
    });
    this.columnConfig(column).onCustomSort && this.columnConfig(column).onCustomSort();
  }

  private onRowClick(row, excludeRowClick = false) {
    if (excludeRowClick) {
      return;
    }
    if (this.props.activeRow === undefined) {
      this.setState({ activeRow: row.id });
    }
    if (this.isAtleastOneSelected() && !this.state.selectedRows[row.id]) {
      this.clearAllSelected();
    }

    if (this.props.onRowClick) {
      this.props.onRowClick(row);
    }
  }

  private columnConfig(activeColumn = this.state.activeColumn) {
    return this.props.columns.find((c) => c.key === activeColumn);
  }

  private processData(
    data = this.props.data,
    order = this.state.sortOrder,
    column = this.state.activeColumn
  ) {
    const columnConfig = this.columnConfig(column);
    if (data.length === 0 || !columnConfig) {
      return data;
    }
    const { sortable, segmentable, sortFunction } = columnConfig;
    let processedData = cloneDeep(data);
    const shouldSort = this.props.shouldSort !== undefined ? this.props.shouldSort : true;
    if (sortable && shouldSort) {
      processedData = List.sortData(
        processedData,
        order,
        sortFunction || List.sortFunction(column)
      );
    }
    if (segmentable) {
      processedData = this.segmentizeData(processedData, column);
    }
    return processedData;
  }

  private segmentizeData(data, column = this.state.activeColumn) {
    const { segmentable, segmentBy } = this.columnConfig(column);
    let segmentedData = data;
    if (segmentable && segmentBy) {
      segmentedData = List.segmentize(data, segmentBy);
    }
    return segmentedData;
  }

  private getChildComponent(componentType) {
    return (
      React.Children.toArray(this.props.children).find((child) => child.type === componentType) ||
      null
    );
  }

  private getActiveRow() {
    return this.props.activeRow ? this.props.activeRow : this.state.activeRow;
  }

  private selectRowToggle(id) {
    const selectedRows = { ...this.state.selectedRows };
    selectedRows[id] = !selectedRows[id];
    if (!selectedRows[id]) {
      delete selectedRows[id];
    }
    this.setState({ selectedRows });
    if (this.props.onMultiSelect) {
      this.props.onMultiSelect(
        Object.keys(selectedRows)
          .filter((id) => selectedRows[id])
          .map((id) => Number(id))
      );
    }
  }

  private selectAllRowsToggle() {
    let selectedRows = {};
    let hasAllSelected: string | boolean = "empty";
    for (const k in this.state.selectedRows) {
      hasAllSelected = Boolean(hasAllSelected) && Boolean(this.state.selectedRows[k]);
    }
    if (hasAllSelected && hasAllSelected !== "empty") {
      selectedRows = {};
    } else {
      selectedRows = this.props.data.reduce((acc, d) => {
        acc[d.id] = true;
        return acc;
      }, {});
    }
    this.setState({ selectedRows }, () => {
      if (this.props.onMultiSelect) {
        this.props.onMultiSelect(Object.keys(selectedRows));
      }
    });
  }

  private clearAllSelected() {
    this.setState({ selectedRows: {} });
    if (this.props.onMultiSelect) {
      this.props.onMultiSelect([]);
    }
  }

  private areAllRowsSelected() {
    const selectedRows = Object.keys(this.state.selectedRows);
    return selectedRows.length && selectedRows.length === this.props.data.length;
  }

  private isMultiSelected() {
    return Object.values(this.state.selectedRows).filter((v) => v).length > 0;
  }

  private isAtleastOneSelected() {
    return Object.values(this.state.selectedRows).find((v) => v);
  }

  private renderColumnHeaders() {
    const headers = [];
    if (this.props.multiSelectable) {
      headers.push(
        <div key="selectHeader" className="okhati-list-headercell">
          <CheckBox
            checked={this.areAllRowsSelected()}
            onChange={(e) => {
              this.selectAllRowsToggle();
            }}
          />
        </div>
      );
    }
    if (
      this.props.multiSelectable &&
      this.isMultiSelected() &&
      this.props.multiSelectContextHeader
    ) {
      headers.push(this.props.multiSelectContextHeader);
    } else {
      this.props.columns.forEach((c, index) => {
        let caret;
        const classes = `okhati-list-headercell headercell-${c.key}`;
        if (this.state.activeColumn === c.key && this.columnConfig(c.key).sortable) {
          caret = this.state.sortOrder === 1 ? <ArrowUpIcon /> : <ArrowDownIcon />;
        }
        headers.push(
          <div
            key={c.key}
            className={classNames(classes, {
              hideInNarrowView: this.props.isResponsive && c.hideInNarrowView,
              alwaysHidden: c.alwaysHidden
            })}
            data-automation={`${this.props.automation}-column-header-${c.key}`}
            onClick={() => this.onColumnHeaderClick(c.key)}
            style={{
              paddingLeft: this.props.columns[0].hideInNarrowView && index == 1 && "20px"
            }}
          >
            <Typography variant="caption">
              <Box component="span" fontSize="0.8rem" fontWeight={500}>
                {c.label || c.key}
              </Box>
            </Typography>
            {caret}
          </div>
        );
      });
    }
    if (this.getChildComponent(ListActions)) {
      headers.push(
        <div key="okhati-list-actions" className="okhati-list-headercell actions">
          {this.renderListActions()}
        </div>
      );
    }
    return headers;
  }

  private renderSegment(datum, datumKey, style) {
    const { key } = this.columnConfig();
    return (
      <div key={datumKey} className="okhati-list-row okhati-list-segment-sumary" style={style}>
        {this.props.segementSummaryRenderer
          ? this.props.segementSummaryRenderer(datum, key)
          : datum.segment}
      </div>
    );
  }

  private getRowCells(datum) {
    let rowCells = this.props.columns.map((c, index) => {
      const { formatter, cellRenderer, hideInNarrowView } = this.columnConfig(c.key);
      const classes = `okhati-list-rowcell rowcell-${c.key}`;
      return (
        <div
          key={c.key}
          className={classNames(classes, {
            hideInNarrowView: this.props.isResponsive && hideInNarrowView,
            alwaysHidden: c.alwaysHidden
          })}
          data-automation={`${this.props.automation}-column-cell-${c.key}`}
          onClick={() => this.onRowClick(datum, c.excludeRowClick)}
          style={{
            paddingLeft: this.props.columns[0].hideInNarrowView && index == 1 && "20px",
            overflow: "hidden"
          }}
        >
          {cellRenderer
            ? cellRenderer(datum)
            : formatter
            ? formatter(datum)
            : <Typography variant="body2">{datum[c.key]}</Typography> || ""}
        </div>
      );
    });
    if (this.props.multiSelectable) {
      const rowSelectCell = (
        <div
          key="selectorCell"
          className="okhati-list-rowcell"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <CheckBox
            checked={Boolean(this.state.selectedRows[datum.id])}
            onChange={(e) => {
              this.selectRowToggle(datum.id);
              e.stopPropagation();
            }}
          />
        </div>
      );
      rowCells = [rowSelectCell].concat(rowCells);
    }
    return rowCells;
  }

  private renderRow({ index, isScrolling, key, style }) {
    const datum = this.state.processedData[index];
    const isMultiSelected = this.state.selectedRows[datum.id];
    if (datum.__meta__row_type === "segment_summary") {
      return this.renderSegment(datum, key, style);
    }
    if (this.props.rowRenderer) {
      return this.props.rowRenderer(datum, key, style);
    }
    const className = `okhati-list-row ${
      this.getActiveRow() === datum.id || isMultiSelected ? "active" : ""
    }`;

    return (
      <div
        key={key}
        className={classNames(className, datum.__meta__classname)}
        style={{
          ...style,
          ...(this.props.errorRows?.length
            ? {
                backgroundColor: this.props.errorRows?.includes(index) ? "#ff6961" : "#fafafa"
              }
            : {})
        }}
        data-automation={`${this.props.automation}-row-${datum.id}`}
        onClick={() => this.onRowClick(datum)}
      >
        {this.getRowCells(datum)}
      </div>
    );
  }

  public renderListActions() {
    const listActions = this.getChildComponent(ListActions);
    if (listActions) {
      return (
        <ListActions {...listActions.props} getProcessedData={() => this.state.processedData} />
      );
    }
  }

  public render() {
    let style = {};
    if (this.props.adjustHeightToContents) {
      const height = this.props.rowHeight * this.state.processedData.length + 10;
      style = { height };
    }
    return (
      <div className="okhati-list-container" data-automation={this.props.automation}>
        {!this.props.hideHeader && (
          <div className="okhati-list-header">{this.renderColumnHeaders()}</div>
        )}
        {this.props.isLoading && this.getChildComponent(LoadingView)}
        {!this.props.isLoading &&
          this.state.processedData.length === 0 &&
          this.getChildComponent(EmptyView)}
        <div className="okhati-list-rows" style={style}>
          <AutoSizer sortState={this.state.sortOrder} activeColumn={this.state.activeColumn}>
            {({ height, width }) => (
              <VirtualizedList
                height={this.props.listHeight || height}
                width={this.props.listWidth || width}
                rowCount={this.state.processedData.length}
                rowHeight={this.props.rowHeight}
                rowRenderer={this.renderRow.bind(this)}
                sortState={this.state.sortOrder + this.state.activeColumn}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

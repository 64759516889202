import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { serviceProviderActions } from "../../../actions";

interface Props {
  onChange: (sp: ServiceProvider) => void;
  value: ServiceProvider | null;
  dataTestmation: string;
}

export default function ServiceProviderSelect({
  onChange,
  value,
  dataTestmation
}: Props): JSX.Element {
  const serviceProviders = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );
  const resourceCentreId = useAppSelector((state) => state.userContext.user.resourceCentreId);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!serviceProviders.length) {
      dispatch(serviceProviderActions.getResourceCentreServiceProviders({ resourceCentreId }));
    }
  }, []);

  return (
    <Autocomplete
      data-testmation={dataTestmation || ""}
      options={serviceProviders}
      getOptionLabel={(option) =>
        `${spFullNameSelector(option)} ${option.active ? "" : "(Deleted)"}`
      }
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {`${spFullNameSelector(option)} ${option.active ? "" : "(Deleted)"}`}
        </li>
      )}
      getOptionDisabled={(option) => !option.active}
      style={{ width: "100%" }}
      onChange={(e, v) => onChange(v)}
      value={value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} />}
    />
  );
}

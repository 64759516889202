/* eslint-disable camelcase */
import { Box, Tooltip, Typography } from "@mui/material";
import { push } from "connected-react-router";
import capitalize from "lodash/capitalize";
import { round } from "mathjs";
import moment from "moment";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import * as reportsApi from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel, downloadXML } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import Can from "../Policy/Can";
import Filters, { ReceiptModeType } from "./Filters";
import styles from "./Reports.module.css";
import "./Reports.scss";
import {
  getDefaultKeyValuesColumns,
  receiptReportDefaultCol
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import { getCustomerNumber } from "../Client/ClientList";
import { RootState } from "../../store";
import { getResourceCentreName } from "./DueReport";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { spFullNameSelector } from "../../reducers/serviceProvider";

export const or = (val: string): string => val || "";

const docColumns = (isBranchReport) => [
  ...(isBranchReport ? [t("reports.resourceCentreName")] : []),
  t("reports.customerNumber"),
  t("reports.clientName"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("services.serviceProviderPayables"),
  t("reports.documentNumber"),
  t("reports.voucherType"),
  t("reports.transactionType"),
  t("reports.transactionDateBS"),
  t("reports.transactionDateAD"),
  t("reports.paymentMethod"),
  t("reports.amount"),
  t("reports.remarks"),
  t("reports.enteredBy")
];

interface DocRowInterface {
  resourceCentreName: string;
  customerNumber: string;
  clientFirstName: string;
  clientLastName: string;
  clientName: string;
  serviceProviderCharge: string;
  enteredBy: string;
  documentNumber: string;
  voucherType: string;
  transactionType: string;
  transactionDate: string;
  paymentMethod: string;
  amount: number;
  remarks: string;
  // eslint-disable-next-line camelcase
  __meta__row_type: string;
}

const makeDocRowProcessor =
  (isBranchReport) =>
  ({
    customerNumber,
    clientName,
    clientFirstName,
    clientLastName,
    serviceProviderCharge,
    documentNumber,
    voucherType,
    transactionType,
    transactionDate,
    paymentMethod,
    amount,
    remarks,
    enteredBy,
    resourceCentreName,
    // eslint-disable-next-line camelcase
    __meta__row_type
  }: DocRowInterface) => {
    // eslint-disable-next-line camelcase
    if (__meta__row_type === "segment_summary") return;
    // eslint-disable-next-line consistent-return
    return [
      ...(isBranchReport ? [resourceCentreName] : []),
      or(getCustomerNumber(customerNumber)),
      or(clientName),
      or(clientFirstName),
      or(clientLastName),
      serviceProviderCharge,
      documentNumber,
      voucherType,
      transactionType,
      calFns.bsShortDate(transactionDate),
      calFns.adShortDate(transactionDate),
      or(paymentMethod),
      amount,
      or(remarks),
      or(enteredBy)
    ];
  };

const selectData = (item, resourceCentres) => ({
  documentNumber: item.documentNumber,
  clientId: item.clientId,
  clientName: `${capitalize(item.clientFirstName)} ${capitalize(item.clientLastName)}`,
  active: item.active,
  customerNumber: item.customerNumber,
  clientFirstName: item.clientFirstName,
  clientLastName: item.clientLastName,
  transactionDate: new Date(item.transactionDate),
  transactionType: item.transactionType,
  paymentMethod: item.paymentMethod,
  voucherType: item.voucherType,
  amount: item.amount,
  remarks: item.remarks,
  enteredBy: item.enteredBy,
  createdAt: item.createdAt,
  resourceCentreId: item.resourceCentreid,
  resourceCentreName: getResourceCentreName(resourceCentres, item.resourceCentreId),
  serviceProviderCharge:
    item.spChargeDetails
      ?.map(
        (chargeItem) =>
          `${spFullNameSelector(chargeItem.serviceProvider)} - ${rupeeDisplay(chargeItem.charge)}`
      )
      .join(", ") || ""
});

const processData = (data, resourceCentres: ResourceCentre[]) =>
  data.map((item) => selectData(item, resourceCentres));

const ReceiptReport = ({ actions, isBranchReport = false }) => {
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentre?.id);
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    receiptMode: [] as ReceiptModeType[],
    ...(isBranchReport
      ? { resourceCentreIds: resourceCentreId ? [resourceCentreId] : [] }
      : { clientIds: [], employeeIds: [] })
  });
  const [reportData, setReportData] = React.useState([]);
  const resourceCentres = useSelector((state: RootState) => state.userBranch.collection) || [];
  React.useEffect(() => {
    reportsApi.reciept(filters).then((data) => {
      const grouppedData = data.sort((a, b) =>
        new Date(a.transactionDate) > new Date(b.transactionDate) ? -1 : 1
      );
      setReportData(processData(grouppedData, resourceCentres));
    });
  }, [filters, resourceCentres]);
  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const handleViewClose = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns([
      ...receiptReportDefaultCol,
      ...(isBranchReport ? ["resourceCentre"] : [])
    ])
  );
  const [open, setOpen] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const dispatch = useDispatch();
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:receiptReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1100px" : "auto"}>
          <Box margin="0px 32px">
            <Filters filters={filters} onSetFilters={(newFilters) => applyFilters(newFilters)} />
          </Box>
          <Box
            className={["receiptReportList", styles.receiptReportListStyles].join(" ")}
            marginTop="32px"
          >
            <List
              automation="receiptReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="transactionDate"
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              columns={[
                {
                  key: "documentNumber",
                  label: tl("reports.documentNumber"),
                  sortable: true
                },
                ...(isBranchReport
                  ? [
                      {
                        key: "resourceCentre",
                        label: "Branch",
                        formatter: ({ resourceCentreName }) => (
                          <Typography>{resourceCentreName}</Typography>
                        ),
                        segmentable: true,
                        segmentBy: (row) => row.resourceCentreName,
                        sortable: true,
                        sortFunction: (a, b) =>
                          or(a.resourceCentreName).toLowerCase() >
                          or(b.resourceCentreName).toLowerCase()
                            ? 1
                            : -1
                      }
                    ]
                  : []),
                {
                  key: "clientName",
                  label: tl("reports.client"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.clientName).toLowerCase() > or(b.clientName).toLowerCase() ? 1 : -1,
                  formatter: ({
                    clientFirstName,
                    clientLastName,
                    clientId,
                    customerNumber,
                    active
                  }) => (
                    <Typography
                      component="div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      <Box
                        style={{
                          textDecoration: `${active && !isBranchReport ? "underline" : ""}`,
                          cursor: "pointer",
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                        onClick={() => {
                          if (active && !isBranchReport) {
                            setSelectedClientId(clientId);
                            setClientInfoPanel(true);
                          }
                        }}
                      >
                        {`${clientFirstName} ${clientLastName}`.toUpperCase()}
                        {active ? "" : "(deleted)"}
                      </Box>
                      <Box fontSize="small" color="gray">
                        {getCustomerNumber(customerNumber)}
                      </Box>
                    </Typography>
                  )
                },
                {
                  key: "serviceProviderCharge",
                  label: "Sp Payable",
                  formatter: ({ serviceProviderCharge }) => (
                    <Tooltip title={serviceProviderCharge} arrow>
                      <Typography>{serviceProviderCharge}</Typography>
                    </Tooltip>
                  )
                },

                {
                  key: "enteredBy",
                  label: "Billed by",
                  formatter: ({ enteredBy }) => <Typography>{enteredBy}</Typography>
                },
                {
                  key: "voucherType",
                  label: tl("reports.voucherType")
                },
                {
                  key: "transactionType",
                  label: tl("reports.transactionType")
                },
                {
                  key: "transactionDateBS",
                  label: tl("reports.date"),
                  formatter: ({ transactionDate, createdAt }) => (
                    <div>
                      {transactionDate && calFns.bsFullDate(transactionDate)}
                      {createdAt && !moment(transactionDate).isSame(createdAt, "day") && (
                        <Typography fontSize="0.8rem" color="gray">
                          {calFns.bsFullDate(createdAt)}
                        </Typography>
                      )}
                    </div>
                  ),
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.transactionDate) > new Date(b.transactionDate) ? 1 : -1
                },
                {
                  key: "paymentMethod",
                  label: tl("reports.paymentMethod"),
                  sortable: true,
                  segmentable: true,
                  segmentBy: ({ paymentMethod }) => paymentMethod,
                  formatter: ({ paymentMethod }) => <Typography>{paymentMethod}</Typography>
                },
                {
                  key: "amount",
                  label: tl("reports.amount"),
                  formatter: ({ amount, transactionType }) =>
                    amount ? rupeeDisplay(amount * (transactionType === "debit" ? -1 : 1)) : ""
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box
                  sx={{
                    background: "#e6e6e6",
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 2.5
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      fontWeight: 500
                    }}
                  >
                    {acc.segment || "N/A"}
                  </Typography>
                  <Typography sx={{ pr: 1.25 }}>
                    {rupeeDisplay(
                      round(
                        acc.rows.reduce(
                          (accumulator, current) =>
                            accumulator +
                            Number(current.amount * (current.transactionType === "debit" ? -1 : 1)),
                          0
                        ) || 0,
                        2
                      )
                    )}
                  </Typography>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            t("reports.receiptReport", "en"),
                            formatHeaderForExcel(docColumns(isBranchReport)),
                            formatDataForExcel(
                              getProcessedData(),
                              makeDocRowProcessor(isBranchReport)
                            )
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        reportsApi
                          .getReceiptTally(filters)
                          .then((response) => {
                            downloadXML(
                              `${t("reports.receiptReport")}-${
                                calFns.convertADtoBS(filters.from).formatted
                              }-${calFns.convertADtoBS(filters.until).formatted}`,
                              response.data
                            );
                            actions.addNotification(response.status);
                          })
                          .catch(() => {
                            actions.addNotification(500);
                          })
                      }
                    >
                      {tl("reports.tally")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
        </Box>
        <Box className="totalBar">
          <Box display="flex" paddingLeft="20px">
            {t("reports.totalReceipt")}
          </Box>
          <Box display="flex" flexBasis="192px" paddingRight="30px" justifyContent="flex-end">
            <Typography>
              {rupeeDisplay(
                Number.parseFloat(
                  reportData.reduce(
                    (acc, cur) =>
                      cur.transactionType === "debit" || cur.voucherType === "CN"
                        ? acc - cur.amount
                        : acc + cur.amount,

                    0
                  )
                ).toFixed(2) || 0
              )}
            </Typography>
          </Box>
        </Box>
        {showClientInfoPanel &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClientId}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClientId}
              handleViewClose={handleViewClose}
              stayOnCurrentPage
              setEditMode={setClientEditMode}
            />
          ))}
      </Box>
    </Can>
  );
};

export default connect(null, (dispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    addNotification: (statusCode) => {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: statusCode === 200 ? "success" : "error",
          message:
            statusCode === 200 ? tl("reports.downloadSuccess") : tl("reports.downloadFailure"),
          autoTimeout: true
        })
      );
    }
  }
}))(ReceiptReport);

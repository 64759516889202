import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { scaleFont } from "../../../../../components/Print/Print";
import { tl } from "../../../../../components/translate";
import classNames from "../../../../../helpers/classNames";
import hasOwnProperty from "../../../../../helpers/object";
import { BillType } from "../../../../../interfaces/BillInterfaces";
import { ServiceProvider } from "../../../../../interfaces/ServiceProvidersInterface";
import { spFromIdSelector, spFullNameSelector } from "../../../../../reducers/serviceProvider";
import { getSectionStyle } from "../../../../Lab/LabPrint/LabPrintFunctions";
import { showUnitAfterQuantity } from "../../../../../actions/helpers/billHelper";

const getValue = (item, column) => {
  if (column === "date") {
    return moment(item.issueDate).format("DD/MM/YY");
  }
  return ["perUnit", "grossTotal"].includes(column) && item[column] !== null
    ? Number(item[column]).toFixed(2)
    : item[column];
};

interface Props {
  billData: BillType;
  SPs: ServiceProvider[];
  relatedSettings: { [key: string]: string };
}

export const IpdBillDescription = ({
  billData,
  SPs,
  relatedSettings
}: Props): JSX.Element | null => {
  if (!billData || !billData.document) return null;

  const hasDiscountAmount = billData.document.billItems.some((billItem) => billItem.discountAmt);
  const hasVatAmount = billData.document.billItems.some((billItem) => billItem.vatPct);

  const billColumns = [
    "date",
    "description",
    "quantity",
    "perUnit",
    ...(hasDiscountAmount ? ["discountAmt"] : []),
    ...(hasVatAmount ? ["vatPct"] : []),
    "grossTotal"
  ];

  return (
    <div>
      <div>
        <Box
          component="div"
          display="flex"
          borderTop="1px solid black"
          borderRight="1px solid black"
          borderBottom="1px solid black"
          borderLeft="1px solid black"
          paddingBottom="0.2cm"
          p="0.3cm"
          className="borderBotBlack1"
          style={getSectionStyle(relatedSettings.headerColor).rawHeaderStyle}
        >
          {billColumns.map((column, i) => (
            <Box
              component="div"
              key={column}
              className={classNames({ grow2: i === 0, flex3: i === 1, flex1: i > 2 })}
            >
              <Typography component="div">
                <Box
                  textAlign={i > 2 ? "right" : "left"}
                  style={{ textTransform: "uppercase" }}
                  fontWeight={600}
                  fontSize={scaleFont("0.27cm")}
                >
                  {tl(`billing.billItem.${column}`)}
                </Box>
              </Typography>
            </Box>
          ))}
        </Box>

        <Box component="div" paddingBottom="0.45cm">
          {billData.document.billItems.map((item, index) => (
            <Box
              component="div"
              key={item.sNo}
              display="flex"
              style={{ pageBreakInside: "avoid" }}
              borderBottom="1px solid gray"
              borderRight="0.5px solid black"
              borderLeft="0.5px solid black"
            >
              {billColumns.map((column, i) => (
                <Box
                  paddingLeft={i === 0 ? "0.3cm" : ""}
                  paddingRight={i === billColumns.length - 1 ? "0.3cm" : ""}
                  key={column}
                  className={classNames({
                    grow2: i === 0,
                    flex3: i === 1,
                    flex1: i > 2
                  })}
                  paddingTop="0.2cm"
                  paddingBottom="0.2cm"
                >
                  <Typography component="div">
                    <Box
                      className="blockContent"
                      sx={{ display: column === "description" ? "inline" : "block" }}
                      fontWeight={["description", "grossTotal"].includes(column) ? 500 : 400}
                      fontSize={scaleFont("0.33cm")}
                      textAlign={i > 2 ? "right" : "left"}
                      gap={1}
                    >
                      {getValue(item, column, index)}
                      {showUnitAfterQuantity(column) && item.unit}
                      {column === "description" &&
                        item.serviceProviderId &&
                        `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                    </Box>
                    {column === "description" && (
                      <Box
                        sx={{ whiteSpace: "pre-wrap", display: "inline" }}
                        fontSize={scaleFont("0.23cm")}
                      >
                        {item.batchId && `Batch ${item.batchId}`}
                        {item.expiryDate &&
                          `, Expiring ${moment(item.expiryDate).format("YYYY/MM/DD")}`}
                      </Box>
                    )}
                    {column === "description" &&
                      item?.subItems?.map((subitem) => (
                        <Box key={subitem.productId} paddingTop="0.05cm" className="blockContent">
                          {`◦ ${subitem.description}`}
                          {hasOwnProperty(subitem, "serviceProviderId") &&
                            subitem.serviceProviderId && (
                              <span style={{ marginLeft: "8px" }}>
                                {`(${spFullNameSelector(
                                  spFromIdSelector(SPs, subitem.serviceProviderId)
                                )})`}
                              </span>
                            )}
                        </Box>
                      ))}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </div>
      <Box component="div" display="flex" paddingTop="1cm" className="blockContent">
        <Box component="div" width="50%">
          {Boolean(billData.document.remarks) && (
            <Box marginBottom="0.3cm">
              <Typography variant="subtitle2" align="left" component="div" display="block">
                <Box fontSize={scaleFont("0.3cm")} style={{ textTransform: "uppercase" }}>
                  {tl("billing.remarks")}
                </Box>
              </Typography>
              <Typography align="left" component="div">
                <Box
                  fontWeight={400}
                  fontSize={scaleFont("0.33cm")}
                  marginRight="2rem"
                  marginTop="0.1cm"
                >
                  {billData.document.remarks}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
        <Box width="50%" component="div" />
      </Box>
    </div>
  );
};

export default IpdBillDescription;

import { push } from "connected-react-router";
import { actionCreatorAsync, AsyncAction } from "./actionHelpers";
import * as api from "../api/assessment";
import { IThunkDispatch } from "../store";
import { pageSize as limit } from "../containers/Assessment/AssessmentList";
import { Assessment } from "../interfaces/AssessmentInterfaces";
import { notificationAdd } from "./notification";
import { createErrorMessage, MODULE, updateErrorMessage } from "../helpers/messages";

export enum Type {
  GET_ASSESSMENTS = "UPDATE_BOOKING",
  GET_ASSESSMENT_BY_ID = "GET_ASSESSMENT_BY_ID",
  GET_ASSESSMENT_BY_UUID = "GET_ASSESSMENT_BY_UUID",
  GET_ASSESSMENTS_BY_CLIENT = "GET_ASSESSMENTS_BY_CLIENT",
  UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT",
  CREATE_ASSESSMENT = "CREATE_ASSESSMENT",
  DELETE_ASSESSMENT = "DELETE_ASSESSMENT",
  GET_SYMPTOMS = "GET_SYMPTOMS",
  GET_LABTEST = "GET_LABTEST",
  CREATE_TEMPLATE_ASSESSMENT = "CREATE_TEMPLATE_ASSESSMENT",
  GET_TEMPLATE_ASSESSMENT = "GET_TEMPLATE_ASSESSMENT"
}

export const getAssessments =
  ({
    clientId,
    searchQuery,
    page,
    pageSize
  }: {
    clientId?: number;
    searchQuery?: string;
    pageSize: number;
    page: number;
  }) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_ASSESSMENTS, async () =>
        api.getAssessments({ clientId, searchQuery, page, pageSize })
      )
    );
  };

export const getClientAssessments =
  (clientId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_ASSESSMENTS_BY_CLIENT, async () =>
        api.getAssessments({ clientId, limit })
      )
    );
  };

export const getAssessmentById =
  (assessmentId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_ASSESSMENT_BY_ID, async () => api.getAssessmentById(assessmentId))
    );
  };

export const getAssessmentByUUId =
  (assessmentId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_ASSESSMENT_BY_UUID, async () =>
        api.getAssessmentByUUID(assessmentId)
      )
    );
  };

export const updateAssessment =
  (assessmentId: number, assessmentData: Assessment) =>
  async (dispatch: IThunkDispatch): Promise<AsyncAction<Assessment>> =>
    dispatch(
      actionCreatorAsync(Type.UPDATE_ASSESSMENT, async () => {
        try {
          let imageInfo = {};
          if (
            assessmentData.assessmentImage &&
            assessmentData.assessmentImage.startsWith("data:image/")
          ) {
            imageInfo = await api.uploadAssessmentImage(assessmentData.assessmentImage);
          }
          let audioInfo = {};
          if (assessmentData.prescriptionAudioUrl?.startsWith("blob:")) {
            audioInfo = await api.uploadAssessmentAudio(assessmentData.prescriptionAudioUrl);
          }
          const assessment = await api.updateAssessment(assessmentId, {
            ...assessmentData,
            ...imageInfo,
            ...audioInfo
          });

          dispatch(push(`/assessment/assessments/?id=${assessment.id}`));
          return assessment;
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.message || updateErrorMessage(MODULE.ASSESSMENT),
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );

export const createAssessment =
  (assessmentData: Assessment) =>
  async (dispatch: IThunkDispatch): Promise<AsyncAction<Assessment>> =>
    dispatch(
      actionCreatorAsync(Type.CREATE_ASSESSMENT, async () => {
        try {
          let imageInfo = {};
          if (assessmentData.assessmentImage?.startsWith("data:image/")) {
            imageInfo = await api.uploadAssessmentImage(assessmentData.assessmentImage);
          }
          let audioInfo = {};
          if (assessmentData.prescriptionAudioUrl?.startsWith("blob:")) {
            audioInfo = await api.uploadAssessmentAudio(assessmentData.prescriptionAudioUrl);
          }
          const assessment = await api.saveAssessment({
            ...assessmentData,
            ...imageInfo,
            ...audioInfo
          });

          dispatch(push(`/assessment/assessments/?id=${assessment.id}`));
          return assessment;
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.message || createErrorMessage(MODULE.ASSESSMENT),
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );

export const deleteAssessment =
  (assessmentId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.DELETE_ASSESSMENT, async () => api.deleteAssessment(assessmentId))
    );
  };

export const getBatchSymptoms =
  (forceUpdate?: boolean) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_SYMPTOMS, async () => api.getBatchSymptoms(forceUpdate))
    );
  };

export const getBatchLabTests =
  (forceUpdate?: boolean) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(actionCreatorAsync(Type.GET_LABTEST, async () => api.getLabTest(forceUpdate)));
  };

export const createTemplateAssessment =
  (assessmentData: unknown) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.CREATE_TEMPLATE_ASSESSMENT, async () =>
        api.saveTemplateAssessment(assessmentData)
      )
    );
  };
export const getTemplateAssessment =
  () =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_TEMPLATE_ASSESSMENT, async () => {
        try {
          const template = await api.getTemplateAssessment();
          return template;
        } catch (e) {
          return undefined;
        }
      })
    );
  };

import React from 'react';
import { Box } from '@mui/material';

interface IconWithFieldInterface {
  style?: {
    display?: string;
    marginBottom?: string;
    height?: string;
    minHeight?: string;
    alignItems?: string;
  };
  iconStyle?: {
    alignItems?: string;
    paddingTop?: string;
  };
  LabelIcon: JSX.Element;
  children: any;
}

const IconWithField: React.FC<IconWithFieldInterface> = (props) => {
  const {
    style = {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
      paddingTop: '8px',
      height: '16px',
      minHeight: '16px',
    },
    iconStyle = {
      paddingTop: '0px',
      alignItems: 'center',
    },
    LabelIcon,
    children,
  } = props;
  return (
    <Box style={style}>
      <Box
        style={{
          marginRight: '20px',
          display: 'flex',
          alignItems: iconStyle.alignItems,
          paddingTop: iconStyle.paddingTop,
          height: '20px',
          width: '20px',
        }}
      >
        {LabelIcon}
      </Box>
      {children}
    </Box>
  );
};

export default IconWithField;

import React from "react";

export const Bottle = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 511 511"
    >
      <g>
        <path
          d="M370.057,159.452l-35.058-35.057L335,109.766c9.29-3.138,16-11.93,16-22.266v-64C351,10.542,340.458,0,327.5,0h-144
		C170.542,0,160,10.542,160,23.5v64c0,10.336,6.71,19.128,16,22.266l0.001,14.629l-35.058,35.057
		C127.438,172.956,120,190.912,120,210.01V471.5c0,21.78,17.72,39.5,39.5,39.5h192c21.78,0,39.5-17.72,39.5-39.5V210.01
		C391,190.912,383.563,172.956,370.057,159.452z M247.5,295H376v17h-40.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H376v17
		h-64.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H376v25H215.5c-4.687,0-8.5-3.813-8.5-8.5v-112c0-4.687,3.813-8.5,8.5-8.5H376
		v25H247.5c-4.142,0-7.5,3.358-7.5,7.5S243.358,295,247.5,295z M295,96V15h17v81H295z M263,96V15h17v81H263z M231,96V15h17v81H231z
		 M199,96V15h17v81H199z M336,23.5v64c0,4.687-3.813,8.5-8.5,8.5H327V15h0.5C332.187,15,336,18.813,336,23.5z M175,23.5
		c0-4.687,3.813-8.5,8.5-8.5h0.5v81h-0.5c-4.687,0-8.5-3.813-8.5-8.5V23.5z M351.5,496h-192c-13.509,0-24.5-10.991-24.5-24.5V210.01
		c0-15.092,5.877-29.28,16.549-39.952L186.608,135H271.5c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-80.499l0-9h129l0,9H303.5
		c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h20.892l35.059,35.058C370.123,180.73,376,194.918,376,210.01V240H215.5
		c-12.958,0-23.5,10.542-23.5,23.5v112c0,12.958,10.542,23.5,23.5,23.5H376v72.5C376,485.009,365.009,496,351.5,496z"
        />
        <path d="M287.5,327h16c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-16c-4.142,0-7.5,3.358-7.5,7.5S283.358,327,287.5,327z" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

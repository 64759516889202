import React from "react";

export const Flask = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M288.069,94.59c-1.859-1.861-4.439-2.93-7.069-2.93s-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07
			c0,2.64,1.069,5.21,2.93,7.069c1.86,1.87,4.44,2.931,7.07,2.931s5.21-1.061,7.069-2.931c1.86-1.859,2.931-4.439,2.931-7.069
			C291,99.03,289.93,96.46,288.069,94.59z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M281,133.727c-5.522,0-10,4.478-10,10v72.606c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-72.606
			C291,138.204,286.522,133.727,281,133.727z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M443.937,451.708L328,250.899V66.497c13.882-4.28,24-17.228,24-32.497c0-18.748-15.252-34-34-34H194
			c-18.748,0-34,15.252-34,34c0,15.269,10.118,28.217,24,32.497v184.402l-40.673,70.448c-2.762,4.783-1.123,10.898,3.66,13.66
			c1.575,0.909,3.294,1.342,4.99,1.342c3.456,0,6.818-1.794,8.67-5.002l37.724-65.34c0.757-0.276,1.496-0.635,2.193-1.107
			c1.203-0.812,29.666-19.682,48.147-1.197c9.186,9.186,19.802,12.58,30.288,12.58c12.589-0.001,24.973-4.899,34.475-10.542
			l113.142,195.967c3.65,6.323,3.65,13.872,0,20.195c-3.65,6.322-10.188,10.097-17.488,10.097H102.872
			c-7.301,0-13.838-3.774-17.488-10.097c-3.65-6.323-3.65-13.872,0-20.195l33.882-58.686c2.762-4.783,1.123-10.898-3.66-13.66
			c-4.784-2.762-10.9-1.123-13.66,3.66l-33.882,58.686c-7.266,12.584-7.266,27.61,0,40.195C75.329,504.487,88.341,512,102.872,512
			h306.256c14.531,0,27.543-7.513,34.809-20.097C451.203,479.318,451.203,464.292,443.937,451.708z M308,245.379
			c-3.249,2.28-9.199,6.048-16.186,8.536c-18.142,6.463-26.284-1.678-28.96-4.354c-18.031-18.031-41.772-15.496-58.854-8.824V68h104
			V245.379z M318,48H195.178H194c-7.72,0-14-6.28-14-14c0-7.72,6.28-14,14-14h124c7.72,0,14,6.28,14,14S325.72,48,318,48z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M138.88,354.22c-1.86-1.86-4.43-2.93-7.061-2.93c-2.639,0-5.219,1.069-7.079,2.93c-1.86,1.86-2.931,4.43-2.931,7.07
			c0,2.63,1.07,5.2,2.931,7.069c1.869,1.86,4.449,2.931,7.079,2.931c2.631,0,5.2-1.07,7.061-2.931
			c1.87-1.869,2.939-4.439,2.939-7.069C141.819,358.649,140.75,356.08,138.88,354.22z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M472,54h-14V40c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10v14h-14c-5.522,0-10,4.478-10,10s4.478,10,10,10h14v14
			c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10V74h14c5.522,0,10-4.478,10-10C482,58.478,477.522,54,472,54z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M143,222h-14v-14c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10v14H95c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10
			h14v14c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-14h14c5.522,0,10-4.478,10-10C153,226.478,148.522,222,143,222z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M34,30C15.252,30,0,45.252,0,64s15.252,34,34,34s34-15.252,34-34S52.748,30,34,30z M34,78c-7.72,0-14-6.28-14-14
			c0-7.72,6.28-14,14-14c7.72,0,14,6.28,14,14C48,71.72,41.72,78,34,78z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M478,292.48c-18.748,0-34,15.252-34,34s15.252,34,34,34s34-15.252,34-34S496.748,292.48,478,292.48z M478,340.48
			c-7.72,0-14-6.28-14-14c0-7.72,6.28-14,14-14c7.72,0,14,6.28,14,14C492,334.2,485.72,340.48,478,340.48z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M311.218,392.073c-18.748,0-34,15.252-34,34s15.252,34,34,34s34-15.252,34-34S329.966,392.073,311.218,392.073z
			 M311.218,440.073c-7.72,0-14-6.28-14-14c0-7.72,6.28-14,14-14c7.72,0,14,6.28,14,14
			C325.218,433.793,318.938,440.073,311.218,440.073z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M213,340.48c-16.542,0-30,13.458-30,30s13.458,30,30,30s30-13.458,30-30S229.542,340.48,213,340.48z M213,380.48
			c-5.514,0-10-4.486-10-10s4.486-10,10-10c5.514,0,10,4.486,10,10C223,375.994,218.514,380.48,213,380.48z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M168.02,431.57c-1.859-1.859-4.439-2.92-7.069-2.92c-2.631,0-5.21,1.061-7.07,2.92c-1.86,1.87-2.93,4.44-2.93,7.08
			c0,2.63,1.069,5.21,2.93,7.07c1.861,1.86,4.439,2.93,7.07,2.93c2.629-0.001,5.209-1.07,7.069-2.93
			c1.86-1.87,2.931-4.45,2.931-7.07C170.951,436.02,169.881,433.44,168.02,431.57z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M294.29,295.411c-1.87-1.86-4.44-2.931-7.07-2.931s-5.21,1.07-7.07,2.931c-1.87,1.859-2.93,4.439-2.93,7.069
			s1.06,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07
			S296.15,297.27,294.29,295.411z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M247.069,193.93C245.21,192.069,242.63,191,240,191s-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07
			c0,2.64,1.069,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.069-2.931C248.93,206.21,250,203.63,250,201
			S248.93,195.79,247.069,193.93z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M233.399,117.93c-1.859-1.86-4.43-2.93-7.069-2.93c-2.63,0-5.21,1.069-7.07,2.93s-2.93,4.44-2.93,7.07
			s1.069,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.21-1.07,7.069-2.931c1.871-1.859,2.931-4.439,2.931-7.069
			S235.269,119.79,233.399,117.93z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M412.069,177.93C410.21,176.07,407.63,175,405,175s-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07
			s1.069,5.21,2.93,7.069c1.86,1.861,4.44,2.931,7.07,2.931s5.21-1.07,7.069-2.931C413.93,190.21,415,187.63,415,185
			S413.93,179.79,412.069,177.93z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M126.069,127.93C124.21,126.07,121.63,125,119,125c-2.641,0-5.21,1.069-7.07,2.93S109,132.37,109,135
			s1.069,5.21,2.93,7.069c1.86,1.861,4.44,2.931,7.07,2.931s5.21-1.07,7.069-2.931C127.93,140.21,129,137.63,129,135
			S127.93,129.79,126.069,127.93z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

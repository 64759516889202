import * as React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const EnsureUser = ({ user, component: Component, ...rest }) => {
  return user ? (
    <Component {...rest} />
  ) : (
    <Box component="div" textAlign="center" width={'100%'}>
      <CircularProgress />
    </Box>
  );
};

export default connect(
  ({ userContext }) => ({ user: userContext.userCreds }),
  () => ({})
)(EnsureUser);

import { Box, Button } from "@mui/material";
import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import * as ipdClientActions from "../../../actions/ipd";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import style from "../styles.module.css";
import IpdAdmissionForm from "./IpdAdmissionForm";
import { t } from "../../../components/translate";

import {
  AuthorizedPersonnelInfo,
  DoctorInCharge,
  EmergencyContact,
  IpdObjectClient,
  IpdTypes
} from "../../../interfaces/IpdInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { IThunkDispatch, RootState } from "../../../store";
import { notificationAdd } from "../../../actions/notification";

export interface IPDFormState {
  id: number | null;
  ipdNumber: number | null;
  client: IpdObjectClient;
  claimCode: string;
  bedNumber: number | null;
  wardNumber: number | null;
  referenceNumber: string;
  roomType: string;
  emergencyContact: EmergencyContact;
  doctorInChargeId: DoctorInCharge | null;
  authorizedPersonnelId: AuthorizedPersonnelInfo | null;
  ipdCase: IpdTypes;
  expectedLengthOfStay: number | null;
  provisionalDiagnosis: string;
  admissionCondition: string;
  hopi: string;
  referredBy: string;
  doctorInCharge: DoctorInCharge | null;
  authorizedPersonnel: AuthorizedPersonnelInfo | null;
}

const initialIpdState: IPDFormState = {
  id: null,
  ipdNumber: null,
  client: {} as IpdObjectClient,
  bedNumber: null,
  referenceNumber: "",
  wardNumber: null,
  roomType: "",
  emergencyContact: {
    name: "",
    phone: "",
    relation: ""
  },
  claimCode: "",
  doctorInCharge: null,
  doctorInChargeId: null,
  authorizedPersonnel: null,
  authorizedPersonnelId: null,
  ipdCase: IpdTypes.regular,
  expectedLengthOfStay: null,
  provisionalDiagnosis: "",
  admissionCondition: "",
  hopi: "",
  referredBy: ""
};

interface Props {
  onSave: (ipd, stayOnPage) => Promise<void>;
  onCancel: () => void;
  mode: string;
  navigateTo: (url) => void;
  setEditMode?: (v) => void;
  stayOnPage: boolean;
  serviceProviders: Array<ServiceProvider>;
  record?: Record<string, any>;
  setShowAdmissionEditPanel?: (v) => void;
  isFromDashboard?: boolean;
  showIpdPanel?: () => void;
  wardInfo?: {
    wardId: number;
    bedId: number;
    roomType: string;
  } | null;
}

export const CreateIpdAdmission = ({
  onSave,
  onCancel,
  mode,
  navigateTo,
  setEditMode,
  stayOnPage,
  serviceProviders,
  record,
  setShowAdmissionEditPanel,
  isFromDashboard,
  showIpdPanel,
  wardInfo = null
}: Props): JSX.Element => {
  const [ipdFormData, setIpdFormData] = React.useState(initialIpdState);
  const [saveAttempted, setSaveAttempted] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const isValid = (data: IPDFormState) => {
    if (
      data.ipdCase &&
      data.client?.id &&
      data.emergencyContact.name &&
      data.emergencyContact.phone &&
      data.doctorInChargeId &&
      data.authorizedPersonnelId
    ) {
      return false;
    }
    return true;
  };

  const disable = isValid(ipdFormData);

  React.useEffect(() => {
    if (mode === "edit" && record) {
      setIpdFormData({
        id: record.id,
        ipdNumber: record.ipdNumber,
        claimCode: record.claimCode || "",
        client: record.client,
        referenceNumber: record.referenceNumber,
        bedNumber: record.bedId,
        wardNumber: record.wardId,
        roomType: record.roomType,
        emergencyContact: record.emergencyContact,
        doctorInChargeId: record.doctorInCharge?.id || record.ipdRecord.doctorInCharge.id,
        doctorInCharge: record.doctorInCharge || record.ipdRecord.doctorInCharge,
        authorizedPersonnelId:
          record.authorizedPersonnelInfo?.id || record.ipdRecord.authorizedPersonnelInfo.id,
        authorizedPersonnel:
          record.authorizedPersonnelInfo || record.ipdRecord.authorizedPersonnelInfo,
        ipdCase: record.ipdRecord.case,
        expectedLengthOfStay: record.expectedLengthOfStay,
        provisionalDiagnosis: record.ipdRecord.provisionalDiagnosis,
        admissionCondition: record.ipdRecord.admissionCondition,
        hopi: record.ipdRecord.hopi,
        referredBy: record.ipdRecord.referredBy
      });
    }
  }, [record, mode]);

  React.useEffect(() => {
    if (isFromDashboard && wardInfo) {
      setIpdFormData({
        ...ipdFormData,
        bedNumber: wardInfo.bedId,
        wardNumber: wardInfo.wardId,
        roomType: wardInfo.roomType
      });
    }
  }, [isFromDashboard, wardInfo]);
  return (
    <Panel
      wrapperStyle={{ minWidth: "780px", maxWidth: "780px" }}
      onClose={onCancel}
      title={mode === "edit" ? "Update IPD Form" : `${t("CreateIPDForm")}`}
      footer={
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          mr={2}
          width="100%"
        >
          <Button
            data-testmation="ipd.cancel"
            onClick={() => {
              if (mode === "edit") {
                onCancel();
              } else {
                navigateTo("/ipd");
              }
              if (setEditMode) {
                setEditMode(false);
              }
            }}
          >
            Cancel
          </Button>
          <StatefulButton
            data-testmation="ipdSave"
            variant="contained"
            color="primary"
            disabled={saveAttempted || saving || disable}
            onClick={async () => {
              if (ipdFormData) {
                setSaving(true);
                if (mode === "edit") {
                  await onSave(ipdFormData, stayOnPage).then(() => {
                    if (setShowAdmissionEditPanel) {
                      setShowAdmissionEditPanel(false);
                    }
                    setSaving(false);
                  });
                } else {
                  await onSave(ipdFormData, isFromDashboard ? true : stayOnPage).then(() => {
                    setSaving(false);
                    if (isFromDashboard && showIpdPanel) {
                      showIpdPanel();
                    }
                    if (setEditMode) {
                      setEditMode(false);
                    }
                  });
                }
              } else {
                setSaveAttempted(true);
              }
            }}
            isLoading={saving}
            circularProgressProps={{ size: 16 }}
          >
            Save
          </StatefulButton>
        </Box>
      }
    >
      <Box pl={5} width="100%" className={style.clientForm}>
        <IpdAdmissionForm
          setIpdFormData={setIpdFormData}
          ipdFormData={ipdFormData}
          serviceProviders={serviceProviders}
          mode={mode}
          isFromDashboard={isFromDashboard}
        />
      </Box>
    </Panel>
  );
};

export default connect(
  (state: RootState) => {
    const serviceProviders = state.resources.resourceCentreServiceProviders || [];
    return {
      serviceProviders
    };
  },
  (dispatch: IThunkDispatch) => ({
    onSave: async (ipd, stayOnPage) => {
      try {
        await dispatch(ipdClientActions.saveIpdRecord(ipd, stayOnPage));
      } catch (err) {
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err?.data?.message || "Failed to create admission",
          autoTimeout: true
        });
      }
    },
    navigateTo: (url) => dispatch(push(url))
  })
)(CreateIpdAdmission);

import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { getVendorsGl } from "../../containers/accounts/hooks";
import { ChildGeneralLedger } from "../../interfaces/Accounts";

interface VendorMappingProps {
  onChange: (item: ChildGeneralLedger) => void;
  id: number;
  subLedgerType?: string;
}

export default function VendorMapping({
  onChange,
  id,
  subLedgerType
}: VendorMappingProps): JSX.Element {
  const vendorsGlList = getVendorsGl();
  const [selectedGl, setSelectedGl] = React.useState<ChildGeneralLedger | null>(null);

  React.useEffect(() => {
    if (vendorsGlList.length && id) {
      setSelectedGl(vendorsGlList.find((gl) => gl.id === id) || null);
    }
  }, [id, vendorsGlList]);

  return (
    <Autocomplete
      options={
        subLedgerType
          ? [vendorsGlList.find((gl) => gl.subLedgerType === subLedgerType)]
          : (vendorsGlList as Array<ChildGeneralLedger>)
      }
      value={selectedGl}
      onChange={(e, v) => {
        onChange(v as ChildGeneralLedger);
        setSelectedGl(v as ChildGeneralLedger);
      }}
      disabled={Boolean(id)}
      getOptionLabel={(option) => `${option?.ledgerName || ""}`}
      renderInput={(params) => (
        <TextField
          data-testmation="ledgerMapping"
          //   eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          variant="outlined"
          margin="dense"
          label="Ledger Mapping"
        />
      )}
    />
  );
}

VendorMapping.defaultProps = {
  subLedgerType: ""
};

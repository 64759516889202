import React from "react";
import { Box, Link } from "@mui/material";
import moment from "moment";
import { getBillsMaterializedView } from "../../api/resourceCentre";
import Filters from "./Filters";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { t, tl } from "../../components/translate";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import useMobileScreen from "../../hooks/useMobileScreen";
import { rupeeDisplay } from "../../helpers/rupee";
import { downloadCSV } from "../../helpers/files";
import { IRDReportInterface } from "../../interfaces/IRDReportsInterface";

const formatBoolNull = (val) => {
  if (val === true || val === false) {
    return `${val}`;
  }
  return val;
};

const docColumns = () => [
  "Fiscal Year",
  "Bill_no",
  "Customer_name",
  "Customer_Pan",
  "Bill_Date",
  "Amount",
  "Discount",
  "Taxable_Amount",
  "Tax_Amount",
  "Total_Amount",
  "Sync With IRD",
  "IS_Bill_Printed",
  "Is_bill_active",
  "Printed_Time",
  "Entered_By",
  "Printed_by",
  "Is_realtime",
  "Payment_Method",
  "VAT_Refund_Amount",
  "Transaction_Id"
];

const docRowProcessor = ({
  fiscalyear,
  billNumber,
  client,
  clientPan,
  issueDate,
  grossAmount,
  discount,
  taxableAmount,
  taxAmount,
  totalAmount,
  syncWithIrd,
  isBillPrinted,
  isBillActive,
  printedTime,
  enteredBy,
  printedBy,
  isRealTime,
  paymentMethod,
  vatRefundAmount,
  billId
}: IRDReportInterface): unknown => [
  fiscalyear,
  billNumber,
  client,
  clientPan,
  issueDate,
  grossAmount,
  discount,
  taxableAmount,
  taxAmount,
  totalAmount,
  syncWithIrd,
  isBillPrinted,
  isBillActive,
  printedTime,
  enteredBy,
  printedBy,
  isRealTime,
  paymentMethod,
  vatRefundAmount,
  billId
];

export default function IRDReport(): JSX.Element {
  const [billsMaterializedView, setBillsMaterializedView] = React.useState([]);

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    clinicSelect: null
  });

  React.useEffect(() => {
    getBillsMaterializedView(filters.clinicSelect, filters.from, filters.until).then((response) =>
      setBillsMaterializedView(response)
    );
  }, [filters]);

  function getIRDSyncedResourceCentres(resourceCentres) {
    return resourceCentres.filter((rc) => Boolean(rc.settings.billingSettings.syncWithIRD));
  }

  const isMobileScreen = useMobileScreen();
  return (
    <Box>
      <Box margin="0px 32px">
        <Box maxWidth="100%">
          <Filters
            filters={filters}
            onSetFilters={(filter) => setFilters(filter)}
            modifiers={{ clinicSelect: getIRDSyncedResourceCentres }}
          />
        </Box>
        <Box marginTop="8px">
          <Link
            onClick={(e) => {
              downloadCSV(
                t("reports.salesReport", "en"),
                billsMaterializedView,
                docColumns(),
                (row) => docRowProcessor(row)
              );
              e.preventDefault();
            }}
          >
            Download Data
          </Link>
        </Box>
      </Box>
      <Box
        width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
        style={{ overflowX: "auto", height: "calc(100vh - 252px)" }}
      >
        <Box style={{ minWidth: "2000px" }}>
          <List
            automation="billsMaterializedView"
            data={billsMaterializedView}
            rowHeight={40}
            defaultSortColumn="issueDate"
            defaultSortOrder={-1}
            activeRow={0}
            adjustHeightToContents
            columns={[
              {
                key: "fiscalyear",
                label: "Fiscal Year"
              },
              {
                key: "billNumber",
                label: "Bill Number"
              },
              {
                key: "client",
                label: "Customer"
              },
              {
                key: "clientPan",
                label: "Customer pan"
              },
              {
                key: "issueDate",
                label: "Date",
                formatter: ({ issueDate }) => calFns.bsFullDate(issueDate),
                sortable: true,
                sortFunction: (a, b) => (new Date(b.issueDate) > new Date(a.issueDate) ? 1 : -1)
              },
              {
                key: "grossAmount",
                label: "Amount",
                formatter: ({ grossAmount }) => (grossAmount ? rupeeDisplay(grossAmount) : "Rs. 0")
              },
              {
                key: "discount",
                label: "Discount",
                formatter: ({ discount }) => (discount ? rupeeDisplay(discount) : "Rs. 0")
              },
              {
                key: "taxableAmount",
                label: "Taxable Amount",
                formatter: ({ taxableAmount }) =>
                  taxableAmount ? rupeeDisplay(taxableAmount) : "Rs. 0"
              },
              {
                key: "taxAmount",
                label: "Tax Amount",
                formatter: ({ taxAmount }) => (taxAmount ? rupeeDisplay(taxAmount) : "Rs. 0")
              },
              {
                key: "totalAmount",
                label: "Total Amount",
                formatter: ({ totalAmount }) => (totalAmount ? rupeeDisplay(totalAmount) : "Rs. 0")
              },
              {
                key: "syncWithIrd",
                label: "Sync With Ird",
                formatter: ({ syncWithIrd }) => formatBoolNull(syncWithIrd)
              },
              {
                key: "isBillPrinted",
                label: "Is Bill Printed",
                formatter: ({ isBillPrinted }) => formatBoolNull(isBillPrinted)
              },
              {
                key: "isBillActive",
                label: "Is Bill Active",
                formatter: ({ isBillActive }) => formatBoolNull(isBillActive)
              },
              {
                key: "printedTime",
                label: "Printed Time",
                formatter: ({ printedTime }) =>
                  printedTime ? moment(printedTime).format("HH:mm") : ""
              },
              {
                key: "enteredBy",
                label: "Entered By"
              },
              {
                key: "printedBy",
                label: "Printed By"
              },
              {
                key: "isRealTime",
                label: "Is Real Time",
                formatter: ({ isRealTime }) => formatBoolNull(isRealTime)
              },
              {
                key: "paymentMethod",
                label: "Payment Method"
              },
              {
                key: "vatRefundAmount",
                label: "VAT Refund Amount"
              },
              {
                key: "billId",
                label: "Transaction Id"
              }
            ]}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
            <ListActions>
              {({ getProcessedData }) => (
                <Menu>
                  <MenuItem
                    onClick={() =>
                      downloadCSV(
                        t("reports.salesReport", "en"),
                        getProcessedData(),
                        docColumns(),
                        (row) => docRowProcessor(row)
                      )
                    }
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
        </Box>
      </Box>
    </Box>
  );
}

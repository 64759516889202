import { useSelector } from "react-redux";
import { BillType } from "../interfaces/BillInterfaces";
import { RootState } from "../store";

// createdLabTestNumbers for custom lab test ids, createdLabTestIds for lab ids like previous
// labId for previous bills which didn't have createdLabTestNumbers or createdLabTestIds

const useLabIdsFromBills = (bill: BillType): string | number => {
  const useCustomTestNumber = useSelector((state: RootState) =>
    Boolean(state.resources.resourceCentres[0]?.labSettings?.useCustomLabTestNumber)
  );

  if (useCustomTestNumber && bill?.document?.createdLabTestNumbers?.length) {
    return bill.document.createdLabTestNumbers.join(", ");
  }
  if (bill?.document?.createdLabTestIds?.length) {
    return bill.document.createdLabTestIds.join(", ");
  }
  return bill?.labId;
};

export default useLabIdsFromBills;

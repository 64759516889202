import * as React from 'react';
import Status from '../../../components/Status/Status';
import DoneIcon from '@mui/icons-material/Done';
import ReferredIcon from '@mui/icons-material/CallMade';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export const statuses = [
  {
    label: 'reserved',
    color: '#707070',
  },
  {
    label: 'handled',
    color: '#009654',
    icon: DoneIcon,
  },
  {
    label: 'referred',
    color: '#663399',
    icon: ReferredIcon,
  },
  {
    label: 'confirmed',
    color: '#0a5d91',
    icon: AssignmentTurnedInIcon,
  },
];
const BookingStatus: React.FC<{ booking: any; hideLabel: boolean }> = ({
  booking,
  hideLabel = false,
}) => {
  return (
    <Status
      statuses={statuses}
      selected={booking.status}
      selectable={false}
      hideLabel={hideLabel}
    />
  );
};

export default BookingStatus;

import React from 'react';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import upperFirst from 'lodash/upperFirst';
import startCase from 'lodash/startCase';
import { t } from '../../../components/translate';
import IntraOralContent from './IntraOralContent';

export default function IntraOral(props) {
  const { type, showField, onAddClicked, onClearClicked, stateValue, onNodeDelete } = props;

  const intraOralDataDisplay = (data, item) => {
    let st = upperFirst(data);
    if (data != 'impacted' && data != 'missing') {
      if (data == 'decayed') {
        st = startCase(Object.keys(stateValue[type][item][data].values)[0]);
        if (Object.keys(stateValue[type][item][data].values)[0] == 'caries') {
          st += `(Class ${
            stateValue[type][item][data].values[Object.keys(stateValue[type][item][data].values)[0]]
              .level
          })`;
        }
      } else if (data == 'mobile' || data == 'fractured') {
        st += `(Class ${stateValue[type][item][data].level})`;
      }
    }
    return st;
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="650px">
        <Box display="flex" alignItems="center">
          <Typography>
            <Box component="span" pr={1} fontWeight="600">
              {upperFirst(t(`assessment.${type}`))}
            </Box>
          </Typography>
          {!showField ? (
            <AddCircleOutlineIcon
              data-testmation="intraOral.addButton"
              style={{ cursor: 'pointer' }}
              onClick={() => onAddClicked()}
            />
          ) : (
            <RemoveCircleOutlineOutlinedIcon
              data-testmation="intraOral.removeButton"
              style={{ cursor: 'pointer' }}
              onClick={() => onClearClicked()}
            />
          )}
        </Box>
      </Box>

      <Box>
        {showField && (
          <Box textAlign="center" pb={2}>
            <IntraOralContent
              themeColor={props.themeColor}
              numbering={props.numbering}
              type={props.type}
              stateValue={props.stateValue}
              onNumberSave={props.onNumberSave}
              onNodeDelete={onNodeDelete}
            />
            <Box pt="8px">
              {Object.keys(stateValue[type]).map((item) => {
                return (
                  <Box key={item} display="flex" width="100%" justifyContent="space-between">
                    <Box display="flex">
                      <Box width="50px">
                        <Typography>
                          <Box component="span" fontWeight="600">
                            {item}
                          </Box>
                        </Typography>
                      </Box>
                      <Box minWidth="250px" display="flex" justifyContent="flex-start">
                        <Typography>
                          {Object.keys(stateValue[type][item])
                            .map((val) => {
                              if (stateValue[type][item][val].checked) {
                                return val;
                              }
                            })
                            .filter((item) => item)
                            .map((data, index) => {
                              return `${index ? ', ' : ''}${intraOralDataDisplay(data, item)}`;
                            })}

                          {'others' in stateValue[type][item] &&
                            `  ${stateValue[type][item].others}`}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <ClearIcon
                        data-testmation="intraOral.clearButton"
                        style={{ cursor: 'pointer' }}
                        onClick={() => onNodeDelete(item)}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import FactCheckIcon from "@mui/icons-material/FactCheckOutlined";
import produce from "immer";
import { LabRecord } from "../../interfaces/Lab";
import { BillType } from "../../interfaces/BillInterfaces";
import { ClientDobOnPrint } from "../../containers/Billing/BillActions/BillActions";
import useGetShowSignatureOnLabPrint from "../../hooks/useGetShowSignatureOnLabPrint";

export enum PRINT_FROM {
  BILL = "bill",
  LAB = "lab",
  ASSESSMENT = "assessment"
}

export const SHOW_SIGNATURE_ON_LAB_PRINT = "showSignatureOnLabPrint";

// use this component for multiple prints supporting localstorage state
interface Props {
  options: Array<{
    label: string;
    onClickAction: () => void;
    component?: React.ReactNode;
    previewComponent?: React.ReactNode;
  }>;
  printFrom?: PRINT_FROM.BILL | PRINT_FROM.LAB | PRINT_FROM.ASSESSMENT;
  labData?: LabRecord;
  setFilteredLabObj?: (filteredLabObj) => void;
  billData?: BillType;
  setFilterBillSubItems?: (filterBillSubItems) => void;
  setShowClientDobOnPrint?: React.Dispatch<React.SetStateAction<ClientDobOnPrint>>;
  showClientDobOnPrint?: ClientDobOnPrint;
  setHidePaymentMethodOnPrint?: (value: Record<number, boolean>) => void;
  hidePaymentMethodOnPrint?: Record<number, boolean>;
  isRiskInfoOnPrintVisible?: boolean;
  onShowRiskInfoToggle?: (id: number) => void;
  assessmentId?: number;
  showDobOnLabPrint?: boolean;
  toggleShowDobOnLabPrint: () => void;
}

interface BillItemsStateType {
  [key: number]: boolean;
}

const getDialogTitle = (printFrom: PRINT_FROM) => {
  let dialogTitle = "";
  switch (printFrom) {
    case PRINT_FROM.LAB:
      dialogTitle = "Configure this lab print";
      break;
    case PRINT_FROM.BILL:
      dialogTitle = "Configure this bill print";
      break;
    case PRINT_FROM.ASSESSMENT:
      dialogTitle = "Configure this assessment print";
      break;
    default:
      dialogTitle = "Configure this print";
  }
  return dialogTitle;
};

export default function MultipleButtonGroup({
  options,
  printFrom,
  labData,
  setFilteredLabObj,
  billData,
  setFilterBillSubItems,
  showClientDobOnPrint,
  setShowClientDobOnPrint,
  setHidePaymentMethodOnPrint,
  hidePaymentMethodOnPrint,
  isRiskInfoOnPrintVisible,
  onShowRiskInfoToggle,
  assessmentId,
  showDobOnLabPrint,
  toggleShowDobOnLabPrint
}: Props): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setdialogOpen] = React.useState(false);
  const [labTestsState, setlabTestsState] = React.useState({});
  const [billItemsState, setBillItemsState] = React.useState({} as BillItemsStateType);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const getBillItemsStateFromLocalStorage = () => {
    const billItemsStateLS = localStorage.getItem("billItemsInitialState");
    return JSON.parse(billItemsStateLS as string) || {};
  };

  React.useEffect(() => {
    const labTestInitialState =
      labData?.results?.data?.reduce((draft, labTest) => {
        draft[labTest.id] = true;
        return draft;
      }, {}) || {};

    const defaultBillItemsState =
      billData?.document?.billItems?.reduce((draft, billItem) => {
        if (billItem?.subItems) {
          draft[billItem.productId] = true;
        }
        return draft;
      }, {}) || {};
    const billItemsStateLS = getBillItemsStateFromLocalStorage();

    const billItemsInitialState = {
      ...defaultBillItemsState,
      ...billItemsStateLS
    };

    setlabTestsState(labTestInitialState);
    setBillItemsState(billItemsInitialState);
  }, [labData, billData]);

  const getCurrentIndex = () => {
    if (printFrom === PRINT_FROM.BILL) {
      return Number(localStorage.getItem("billPrintTemplateIndex")) || 0;
    }
    if (printFrom === PRINT_FROM.ASSESSMENT) {
      return Number(localStorage.getItem("assessmentPrintTemplateIndex")) || 0;
    }
    return Number(localStorage.getItem("labPrintTemplateIndex")) || 0;
  };

  const [selectedIndex, setSelectedIndex] = React.useState(getCurrentIndex());

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    if (printFrom === PRINT_FROM.BILL) {
      localStorage.setItem("billPrintTemplateIndex", index.toString());
    } else if (printFrom === PRINT_FROM.ASSESSMENT) {
      localStorage.setItem("assessmentPrintTemplateIndex", index.toString());
    } else {
      localStorage.setItem("labPrintTemplateIndex", index.toString());
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const saveBillItemsStateToLocalStorage = () => {
    const billItemsStateLS = {
      ...getBillItemsStateFromLocalStorage(),
      ...billItemsState
    };
    localStorage.setItem("billItemsInitialState", JSON.stringify(billItemsStateLS));
  };

  const filterBillSubItemsData = () => {
    const filteredBillSubItemsData = produce(billData, (draft) => {
      if (billData) {
        draft?.document?.billItems.forEach((draftItem) => {
          if (!billItemsState[draftItem.productId]) {
            draftItem.subItems = null;
          }
        });
      }
    });
    saveBillItemsStateToLocalStorage();
    setFilterBillSubItems(filteredBillSubItemsData);
  };

  const handleShowDobOnPrintClick = () => {
    if (setShowClientDobOnPrint) {
      const newState = {
        ...showClientDobOnPrint,
        [billData.id]: !showClientDobOnPrint[billData.id]
      };
      setShowClientDobOnPrint(newState);
      localStorage.setItem("showDobOnBillPrint", JSON.stringify(newState));
    }
  };

  const handleShowPaymentMethodOnPrintClick = () => {
    if (setHidePaymentMethodOnPrint) {
      const newState = {
        ...hidePaymentMethodOnPrint,
        [billData.id]: !hidePaymentMethodOnPrint[billData.id]
      };
      setHidePaymentMethodOnPrint(newState);
      localStorage.setItem("showPaymentMethodOnBillPrint", JSON.stringify(newState));
    }
  };

  const { showSignatureOnLabPrint } = useGetShowSignatureOnLabPrint();
  const [showSignatureOnPrint, setShowSignatureOnPrint] = React.useState<boolean>(
    !!(showSignatureOnLabPrint || showSignatureOnLabPrint === null)
  );
  React.useEffect(() => {
    // set default to true
    if (showSignatureOnLabPrint === null) {
      localStorage.setItem(SHOW_SIGNATURE_ON_LAB_PRINT, "true");
    }
  }, []);

  const handleShowSignatureOnPrint = ({ target }) => setShowSignatureOnPrint(target.checked);

  React.useEffect(() => {
    if (printFrom === PRINT_FROM.BILL) filterBillSubItemsData();
  }, [billItemsState]);

  const onSave = () => {
    if (printFrom === PRINT_FROM.LAB) {
      localStorage.setItem(SHOW_SIGNATURE_ON_LAB_PRINT, JSON.stringify(showSignatureOnPrint));
      const filteredLabData = produce(labData, (draft) => {
        draft.results.data = draft.results.data.filter((labTest) => labTestsState[labTest.id]);
      });
      setFilteredLabObj(filteredLabData);
    }
    setdialogOpen(false);
  };
  return (
    <Box>
      <Grid container direction="column" alignItems="flex-end">
        <Grid item xs={12}>
          <ButtonGroup ref={anchorRef}>
            {printFrom === PRINT_FROM.BILL && (
              <Button style={{ padding: "0px" }}>
                {options[selectedIndex]
                  ? options[selectedIndex].previewComponent
                  : options[0].previewComponent}
              </Button>
            )}
            <Button style={{ padding: "0px" }}>
              {options[selectedIndex] ? options[selectedIndex].component : options[0].component}
            </Button>
            <Button style={{ padding: "5px" }} onClick={() => setdialogOpen(true)}>
              <FactCheckIcon fontSize="small" color="primary" style={{ alignSelf: "center" }} />
            </Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <SettingsIcon fontSize="small" />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                          style={{ minWidth: "140px" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} fullWidth onClose={() => setdialogOpen(false)}>
        <DialogTitle
          style={{
            borderBottom: "1px solid #ececec",
            background: "#f9f9f9"
          }}
        >
          {getDialogTitle(printFrom)}
        </DialogTitle>

        <DialogContent>
          <Box style={{ width: "100%" }}>
            <FormGroup>
              {printFrom === PRINT_FROM.LAB && (
                <>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        sx={{ ml: "4px" }}
                        checked={showDobOnLabPrint}
                        onChange={() => toggleShowDobOnLabPrint()}
                      />
                    }
                    label="Show Date of Birth in Print"
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        sx={{ ml: "4px" }}
                        checked={showSignatureOnPrint}
                        onChange={handleShowSignatureOnPrint}
                      />
                    }
                    label="Show Signature in Print"
                  />
                  <Typography variant="h6" my={1}>
                    Select lab tests to print
                  </Typography>
                  {labData?.results?.data.map((labTest) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={labTestsState[labTest.id]}
                          onChange={() =>
                            setlabTestsState({
                              ...labTestsState,
                              [labTest.id]: !labTestsState[labTest.id]
                            })
                          }
                        />
                      }
                      label={labTest.name}
                      key={labTest.id}
                    />
                  ))}
                </>
              )}
              {printFrom === PRINT_FROM.BILL && (
                <>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        sx={{ ml: "4px" }}
                        checked={showClientDobOnPrint[billData.id]}
                        onChange={handleShowDobOnPrintClick}
                      />
                    }
                    label="Show date of birth in AD"
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        sx={{ ml: "4px" }}
                        checked={hidePaymentMethodOnPrint[billData.id]}
                        onChange={handleShowPaymentMethodOnPrintClick}
                      />
                    }
                    label="Hide payment method"
                  />
                  <Typography variant="h6" my={1}>
                    Select bill packages to print
                  </Typography>
                  {billData?.document?.billItems.map((billItem) => (
                    <>
                      <FormLabel component="legend">{billItem?.description}</FormLabel>
                      {billItem?.subItems && (
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              sx={{ ml: "4px" }}
                              checked={!billItemsState[billItem.productId]}
                              onChange={() => {
                                setBillItemsState({
                                  ...billItemsState,
                                  [billItem.productId]: !billItemsState[billItem.productId]
                                });
                              }}
                            />
                          }
                          label="Do not print items"
                          key={billItem.productId}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
              {printFrom === PRINT_FROM.ASSESSMENT && (
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      sx={{ ml: "4px" }}
                      checked={isRiskInfoOnPrintVisible}
                      onChange={() => onShowRiskInfoToggle(assessmentId)}
                    />
                  }
                  label="Show risk information on print for this assessment"
                />
              )}
            </FormGroup>
          </Box>
        </DialogContent>
        {printFrom === PRINT_FROM.LAB && (
          <DialogActions
            style={{
              background: "#00800014",
              borderTop: "1px solid #00800017",
              justifyContent: "flex-start"
            }}
          >
            <Box width="100%" display="flex" justifyContent="flex-end" padding="0px 24px">
              <Button
                style={{ marginRight: "8px" }}
                color="primary"
                onClick={() => {
                  setdialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={onSave}>
                Save
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

MultipleButtonGroup.defaultProps = {
  billData: {},
  printFrom: undefined,
  setFilterBillSubItems: undefined,
  labData: {},
  setFilteredLabObj: null,
  showClientDobOnPrint: false,
  setShowClientDobOnPrint: () => null
};

import styles from './styles.module.css';
import * as React from 'react';
import * as calendarData from '../functions/calendarData';
import * as calFns from '../functions/calendarFunctions';
import classNames from '../../../helpers/classNames';
import moment from 'moment';
import { t } from '../../translate';
import times from 'lodash/times';
import { Typography } from '@mui/material';

interface MonthViewProps {
  onDayClicked: (date: Date) => void;
  viewBsYear: number;
  viewBsMonth: number;
  defaultActiveDate: Date;
  maxValidDate?: Date;
  minValidDate?: Date;
  highlightedDays: Array<any>;
}

interface MonthViewState {
  selectedDate: Date;
}

const MonthView: React.FC<MonthViewProps> = ({
  viewBsYear,
  onDayClicked,
  viewBsMonth,
  defaultActiveDate,
  maxValidDate,
  minValidDate,
  highlightedDays
}) => {
  const [selectedDate, setSelectedDate] = React.useState(defaultActiveDate || new Date());

  const getDayInfo = (date: Date) => {
    const bsDate = calFns.convertADtoBS(date);
    return { adDate: new Date(date), ...bsDate };
  };

  const getDays = () => {
    let startDay, lastDay;
    startDay = calFns.convertBStoAD(viewBsYear, viewBsMonth, 1);
    startDay.setDate(startDay.getDate() - startDay.getDay()); // Sunday, the first day in the view
    lastDay = calFns.convertBStoAD(
      viewBsYear,
      viewBsMonth,
      calFns.getBsMonthDays(viewBsYear, viewBsMonth)
    );
    lastDay.setDate(lastDay.getDate() + (6 - lastDay.getDay())); // Saturday, the last day in the view
    const days = [];
    while (startDay <= lastDay) {
      days.push(getDayInfo(startDay));
      startDay.setDate(startDay.getDate() + 1);
    }
    return days;
  };

  const isSameDate = (adDate: Date, toMatch: Date = new Date()) => {
    return (
      adDate.getDate() == toMatch.getDate() &&
      adDate.getMonth() == toMatch.getMonth() &&
      adDate.getFullYear() == toMatch.getFullYear()
    );
  };

  const isSameWeek = (date, highlightedDays) => {
    const weekDays = highlightedDays.map((item) => item._d.toString());
    return weekDays.includes(date.toString());
  };

  const isDateDisabled = (adDate: any) => {
    if (maxValidDate) {
      let disabled = moment(adDate).isAfter(moment(maxValidDate), 'day');
      if (disabled) return disabled;
    }
    if (minValidDate) {
      let disabled = moment(adDate).isBefore(moment(minValidDate), 'day');
      if (disabled) return disabled;
    }
    return false;
  };

  const onDaySelect = (date: Date) => {
    setSelectedDate(date);
    onDayClicked(date);
  };

  const getWeekDays = (date = moment()) => {
    const startDay = moment(date).startOf('week');
    const days: Array<moment.Moment> = [];
    times(7, (i) => {
      days.push(startDay.clone().add(i, 'day'));
    });
    return days;
  };

  return (
    <div className={`r-n-cal-month-view ${styles.calendar}`}>
      <div className={`r-n-cal-weekdays ${styles.weekdays}`}>
        {calendarData.bsDays(t).map((day) => (
          <Typography key={day} className={styles.weekday} style={{ fontSize: '12px' }}>
            {day}
          </Typography>
        ))}
      </div>
      <div className={`r-n-cal-days ${styles.days}`}>
        {getDays().map(({ adDate, bsDate, bsMonth }, i) => (
          <div
            className={classNames('r-n-cal-day', styles.day, {
              [styles.weekend]: i % 7 == 6,
              [styles.dayMuted]: bsMonth !== viewBsMonth,
              [styles.today]: isSameDate(adDate),
              [styles.highlightedDays]: isSameWeek(adDate, highlightedDays),
              [styles.disabled]: isDateDisabled(adDate)
            })}
            key={`${bsDate} ${bsMonth}`}
            onClick={() => {
              if (!isDateDisabled(adDate)) {
                onDaySelect(adDate);
              }
            }}
          >
            <Typography
              style={{
                borderRadius: '50%',
                width: '28px',
                height: '28px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {calFns.toDevanagariDigits(bsDate)}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthView;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLabSummaryData } from "../api/labTest";
import { FilterProps } from "../containers/Reports/SummaryReport/CentralMonitoringLabReport";
import { LabSummary } from "../interfaces/Lab";

export const getLabSummary = createAsyncThunk("labSummary", async (filters: FilterProps) => {
  const response = await getLabSummaryData(filters);
  return (await response) as LabSummary[];
});

const labSummarySlice = createSlice({
  name: "labSummary",
  initialState: [] as LabSummary[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLabSummary.fulfilled, (_, { payload }) => payload);
  }
});

export default labSummarySlice.reducer;

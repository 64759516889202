import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { removeCommasFromNumber, roundTwoDecimalPoint } from "../helpers/number";
import { LabRecord, LabTest, LabTestRecordResultData } from "../interfaces/Lab";
import { assignUniqueIdAtLevel, findDFS } from "./labTestSettingsSlice";
import { getLabTestRecord } from "../api/labTest";

interface State {
  entryDraft: LabRecord;
}

const initialState: State = {
  entryDraft: null
};

function assignUniqueIds(draft) {
  const draftForMutation = cloneDeep(draft);
  draftForMutation.results.data = draftForMutation.results.data.map((datum) => ({
    ...datum,
    labTests: assignUniqueIdAtLevel(datum.labTests)
  }));
  return draftForMutation;
}

// assigns formula value and evaluates value for each test
function handleFormulas(testGroup: LabTestRecordResultData, id: number) {
  const forEachTestHandleFormula = (labTests: Array<LabTest>) => {
    labTests.forEach((test) => {
      // allow changing of value when the test itself is edited
      if (test.id !== id) {
        if (
          test.formData.formula &&
          test.formData.formula.rootValue &&
          test.formData.formula.references
        ) {
          const referenceIds = test.formData.formula.references.map(
            // reference is in the form of {labId}__{uuid}
            (reference) => reference.split("__")[0]
          );

          // find value of tests from ids
          const referenceValues = referenceIds.map((referenceId) => {
            const foundTest = findDFS(testGroup.labTests, Number(referenceId), "id");
            return foundTest?.formData.reading;
          });

          // remove everything inside "{{}}", and remove whitespaces
          const updatedFormDataReading = test.formData.formula.rootValue
            .replace(/{{.*?}}/g, "{{}}")
            .replace(/ /g, "")
            .split("");

          // replace {{}} with real values in updatedFormDataReading
          for (let i = 0; i < updatedFormDataReading.length; i += 1) {
            if (updatedFormDataReading[i] === "{") {
              const relatedReference = referenceValues.shift();
              updatedFormDataReading.splice(i, 4, relatedReference);
            }
          }

          try {
            // eslint-disable-next-line no-param-reassign
            test.formData.reading = roundTwoDecimalPoint(
              // eslint-disable-next-line no-eval
              eval(
                updatedFormDataReading
                  .filter((el) => el !== "")
                  .map((el) => removeCommasFromNumber(el))
                  .join("")
              )
            );
          } catch (e) {
            // eslint-disable-next-line no-param-reassign
            test.formData.reading = "";
          }
        }
      }
      if (test.subTests) {
        forEachTestHandleFormula(test.subTests);
      }
    });
  };
  forEachTestHandleFormula(testGroup.labTests);
}

function updateDraft(draft, groupIndex, uuid, key, value) {
  const testGroup = draft.entryDraft.results.data[groupIndex];
  const foundObject = findDFS(testGroup.labTests, uuid);
  foundObject[key] = value;
  handleFormulas(testGroup, foundObject.id);
}

export const fetchLabEntry = createAsyncThunk(
  "labRecord/getSingleLabRecord",
  async (labRecordId: number) => {
    const response = await getLabTestRecord(labRecordId);
    return response[0];
  }
);

const labRecordsSlice = createSlice({
  name: "labRecordsSlice",
  initialState,
  reducers: {
    createEntry: (draft, action: PayloadAction<LabRecord>) => {
      draft.entryDraft = (action.payload && assignUniqueIds(action.payload)) || null;
    },
    updateEntryDraft: (
      draft,
      action: PayloadAction<{ groupIndex: number; uuid: string; key: string; value: unknown }>
    ) => {
      const { groupIndex, uuid, key, value } = action.payload;
      updateDraft(draft, groupIndex, uuid, key, value);
    },
    updateComment: (draft, action: PayloadAction<string>) => {
      if (draft.entryDraft?.results) {
        draft.entryDraft.results.comment = action.payload;
      }
    },
    toggleComment: (draft, action: PayloadAction<boolean>) => {
      if (draft.entryDraft?.results) {
        draft.entryDraft.results.showComment = action.payload;
      }
    },
    updateName: (draft, action: PayloadAction<{ groupIndex: number; value: string }>) => {
      const { groupIndex, value } = action.payload;
      if (draft.entryDraft?.results) {
        draft.entryDraft.results.data[groupIndex].name = value;
      }
    },
    setAttachments: (draft, action: PayloadAction<Array<File>>) => {
      if (draft.entryDraft) {
        draft.entryDraft.attachments = action.payload;
      }
    },
    updateGroupRoot: (
      draft,
      action: PayloadAction<{ groupIndex: number; key: string; value: unknown }>
    ) => {
      const { groupIndex, key, value } = action.payload;
      draft.entryDraft.results.data[groupIndex][key] = value;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchLabEntry.fulfilled, (draft, { payload }) => {
      draft.entryDraft = (payload && assignUniqueIds(payload)) || null;
    });
  }
});

export default labRecordsSlice.reducer;
export const {
  createEntry,
  updateEntryDraft,
  updateComment,
  updateName,
  setAttachments,
  updateGroupRoot,
  toggleComment
} = labRecordsSlice.actions;

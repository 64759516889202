import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import ArrowDropRight from "@mui/icons-material/KeyboardArrowRight";
import ArrowDropDown from "@mui/icons-material/KeyboardArrowDown";
import { startCase } from "lodash";
import {
  Box,
  StepLabel,
  Typography,
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from "@mui/material";
import moment from "moment";
import className from "../../../helpers/classNames";
import * as calendarFns from "../../../components/Calendar/functions/calendarFunctions";
import styles from "../../Assessment/Assessment.module.css";
import { IPD } from "../../../interfaces/IpdInterface";
import modalStyles from "./styles.module.scss";
import { tl } from "../../../components/translate";

interface ProgressRecordShowProps {
  ipdRecord: IPD;
}
interface ToggleStepperProps {
  initIsOpen: boolean;
  children: (a, b, c) => React.ReactElement<any, any>;
  props: { key: number };
}
interface IProgressRecordVital {
  reading: string;
  name: string;
  unit: string;
  extraInfo?: string;
}
const CircleStepIcon = ({ order, record, isOpen }) => (
  <Button className="btnCollapseItem">
    <Box display="flex" component="div" alignItems="center">
      <Box
        width="1.5rem"
        borderRadius="50%"
        height="1.5rem"
        component="span"
        fontSize="12px"
        fontWeight={600}
        bgcolor="primary.main"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#fff"
      >
        {order}
      </Box>
      <Box component="div" display="flex" px={1}>
        <Typography component="div">
          <Box component="span" fontSize="14px" fontWeight={600}>
            {`${calendarFns.convertADtoBS(moment(record.date)).formatted2} ${moment(
              record.date
            ).format("hh:mm:ss a")}`}
          </Box>
          <Box component="span" fontSize="14px" fontWeight={600} mx={0.5}>
            -
          </Box>
          <Box component="span" fontSize="14px" fontWeight={600}>
            visit
          </Box>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {isOpen ? <ArrowDropDown /> : <ArrowDropRight />}
      </Box>
    </Box>
  </Button>
);
const ToggleStepper: React.FC<ToggleStepperProps> = ({ initIsOpen, children, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(initIsOpen || false);
  return children(isOpen, setIsOpen, props);
};

const IpdProgressRecordShow: React.FC<ProgressRecordShowProps> = ({ ipdRecord }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  const [collapseHistory, setCollapseHistory] = React.useState(true);
  return (
    <Box pl="8px" pr="8px">
      <Box
        pl="8px"
        pt={2}
        pb={2}
        display="flex"
        alignItems="center"
        onClick={() => setCollapseHistory(!collapseHistory)}
      >
        <Typography component="div">
          <Box fontSize="16px" fontWeight={700} component="span">
            History
          </Box>
        </Typography>
        {collapseHistory ? (
          <ArrowDropRight className={styles.titleArrow} />
        ) : (
          <ArrowDropDown className={styles.titleArrow} />
        )}
      </Box>
      <Box
        className={className(styles.historyBlock, { [styles.collapseHistory]: collapseHistory })}
      >
        {ipdRecord?.ipdRecord?.progressRecord?.length > 0 ? (
          <Stepper orientation="vertical">
            {ipdRecord?.ipdRecord.progressRecord
              .map((record, i) => (
                <ToggleStepper
                  props={{ key: record.id }}
                  key={record.id}
                  initIsOpen={i === 0 || false}
                >
                  {(isOpen, setIsOpen, props) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Step {...props} completed={false} disabled={false} active>
                      <StepLabel
                        StepIconComponent={() => (
                          <CircleStepIcon order={i + 1} record={record} isOpen={isOpen} />
                        )}
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      />

                      <StepContent>
                        {isOpen && (
                          <>
                            {record && (
                              <>
                                {record.diagnosis?.diagnosis && (
                                  <Typography component="div">
                                    <Box
                                      display="block"
                                      component="span"
                                      fontSize="14px"
                                      fontWeight={600}
                                    >
                                      Diagnosis
                                    </Box>

                                    <Box component="span" fontSize="14px">
                                      {record.diagnosis.diagnosis}
                                    </Box>
                                  </Typography>
                                )}

                                {record.examination && (
                                  <Typography component="div">
                                    <Box
                                      display="block"
                                      component="span"
                                      fontSize="14px"
                                      fontWeight={600}
                                    >
                                      Examination
                                    </Box>

                                    <Box component="span" fontSize="14px">
                                      {record.examination}
                                    </Box>
                                  </Typography>
                                )}
                                <Box>
                                  <Link
                                    color="primary"
                                    component="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setModalOpen(true);
                                      setModalData(record);
                                    }}
                                  >
                                    <Typography>
                                      <Box
                                        data-testmation="seeFullButton"
                                        fontWeight={500}
                                        component="span"
                                        fontSize="14px"
                                      >
                                        See Full
                                      </Box>
                                    </Typography>
                                  </Link>
                                </Box>
                              </>
                            )}
                            <Dialog
                              open={modalOpen}
                              onClose={() => setModalOpen(false)}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              fullWidth
                              maxWidth="md"
                            >
                              <DialogTitle id="alert-dialog-title">
                                <div className={modalStyles.modalHeader}>
                                  <Typography
                                    variant="h6"
                                    component="h3"
                                    display="inline"
                                    style={{ fontSize: "1.16rem" }}
                                  >
                                    Progress Record
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="span"
                                    display="initial"
                                  >
                                    {calendarFns.convertADtoBS(moment()).formatted2}
                                  </Typography>
                                </div>
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  <div className={modalStyles.modalBody}>
                                    <Typography>
                                      <Box pb={2} component="div">
                                        {tl("progressRecord.Date")}:{" "}
                                        {`${
                                          calendarFns.convertADtoBS(moment(record.date)).formatted2
                                        } ${moment(record.date).format("hh:mm:ss a")}`}
                                      </Box>
                                    </Typography>
                                    <Box>
                                      {modalData.diagnosis && (
                                        <Box pb={2}>
                                          <Typography>
                                            <Box pt={3} fontWeight={600} component="span">
                                              {tl("progressRecord.Diagnosis")}
                                            </Box>
                                          </Typography>
                                          <Typography>
                                            <Box component="span">
                                              {modalData.diagnosis?.diagnosis}
                                            </Box>
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box>
                                      {modalData.examination && (
                                        <Box pb={2}>
                                          <Typography>
                                            <Box pt={3} fontWeight={600} component="span">
                                              {tl("progressRecord.Examination")}
                                            </Box>
                                          </Typography>
                                          <Typography>
                                            <Box component="span">{modalData.examination}</Box>
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box>
                                      {modalData.treatmentProvided && (
                                        <Box pb={2}>
                                          <Typography>
                                            <Box pt={3} fontWeight={600} component="span">
                                              {tl("progressRecord.TreatmentProvided")}
                                            </Box>
                                          </Typography>
                                          <Typography>
                                            <Box component="span">
                                              {modalData.treatmentProvided}
                                            </Box>
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box>
                                      {modalData.operationSummary && (
                                        <Box pb={2}>
                                          <Typography>
                                            <Box pt={3} fontWeight={600} component="span">
                                              {tl("progressRecord.OperationSummary")}
                                            </Box>
                                          </Typography>
                                          <Typography>
                                            <Box component="span">{modalData.operationSummary}</Box>
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box>
                                      {modalData.vitals && (
                                        <Box pb={2}>
                                          <Typography>
                                            <Box pt={3} fontWeight={600} component="span">
                                              {tl("progressRecord.Vitals")}
                                            </Box>
                                          </Typography>
                                          <Box mt={1}>
                                            {modalData.vitals?.map(
                                              (vital: IProgressRecordVital, index: number) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Typography key={index}>
                                                  {`${index + 1}. ${startCase(vital.name)} ${
                                                    vital.reading
                                                  } ${vital.unit} ${vital.extraInfo || ""}`}{" "}
                                                </Typography>
                                              )
                                            )}
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box>
                                      {modalData.remarks && (
                                        <Box pb={2}>
                                          <Typography>
                                            <Box pt={3} fontWeight={600} component="span">
                                              {tl("progressRecord.Remarks")}
                                            </Box>
                                          </Typography>
                                          <Typography>
                                            <Box component="span">{modalData.remarks}</Box>
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </div>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setModalOpen(false)}
                                  color="primary"
                                  autoFocus
                                >
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </StepContent>
                    </Step>
                  )}
                </ToggleStepper>
              ))
              .reverse()}
          </Stepper>
        ) : (
          <Box textAlign="center" mb={3} width={1}>
            <div className="Dashboard__liveFlow-null-appointments" />
            No History found
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IpdProgressRecordShow;

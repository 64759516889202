import mixpanel from "mixpanel-browser";
import EVENT from "./event";

mixpanel.init("a265c1ff4726259f63e29a78c399fe0f");

const isProdEnv = process.env.NODE_ENV === "production";

const mixpanelAnalytics = {
  identify: (id: string): void => {
    if (isProdEnv) {
      mixpanel.identify(id);
    }
  },
  alias: (id: string, original?: string): void => {
    if (isProdEnv) {
      mixpanel.alias(id, original);
    }
  },
  track: (name: EVENT, props?: Record<string, unknown>): void => {
    if (isProdEnv) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props: Record<string, unknown>): void => {
      if (isProdEnv) {
        mixpanel.people.set(props);
      }
    }
  },
  setGroup: (groupKey: string, groupIds: string | number | string[] | number[]): void => {
    if (isProdEnv) {
      mixpanel.set_group(groupKey, groupIds);
    }
  }
};

export default mixpanelAnalytics;

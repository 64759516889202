import * as React from "react";
import Button from "@mui/material/Button";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import { ButtonProps, ButtonTypeMap, ExtendButtonBase } from "@mui/material";

export const loadingIcon: React.FC<CircularProgressProps> = ({ size, thickness, style }) => (
  <CircularProgress style={style} thickness={thickness} size={size} />
);

interface StatefulButtonProps {
  isLoading: boolean;
  circularProgressProps: CircularProgressProps;
  variant?: string;
}

const StatefulButton: React.FC<StatefulButtonProps & Partial<ButtonProps>> = (props) => {
  const {
    children,
    isLoading,
    circularProgressProps = {
      size: 1,
      thickness: 1,
      style: { color: "white" }
    }
  } = props;
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(props as unknown as ExtendButtonBase<ButtonTypeMap<Record<string, any>, "button">>)}
    >
      {isLoading ? loadingIcon(circularProgressProps) : children}
    </Button>
  );
};

StatefulButton.defaultProps = {
  variant: "text"
};

export default StatefulButton;

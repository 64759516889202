import { ReducerBuilder } from './ReducerBuilder';
import * as OnDemandActions from '../actions/onDemandProducts';
import produce from 'immer';
import { OnDemandProducts } from '../interfaces/OnDemandProductInterface';

interface State {
  collection: Array<OnDemandProducts>;
}

const INITIAL_STATE: State = {
  collection: []
};

function setOnDemandProducts(state, payload) {
  return { ...state, collection: payload };
}

function setOnDemandProduct(state, payload) {
  if (payload) {
    const newState = produce(state as State, (state) => {
      state.collection.push(payload);
    });
    return newState;
  }
  return state;
}

function patchOnDemandProduct(state, payload) {
  return {
    ...state,
    collection: state.collection.map((item) => {
      if (item.id === payload.id) return payload;
      else return item;
    })
  };
}

function removeOnDemandProduct(state, payload) {
  return { ...state, collection: state.collection.filter((item) => item.id !== payload) };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(OnDemandActions.Type.GET_ONDEMAND_PRODUCTS, setOnDemandProducts)
  .mapAction(OnDemandActions.Type.POST_ONDEMAND_PRODUCT, setOnDemandProduct)
  .mapAction(OnDemandActions.Type.PATCH_ONDEMAND_PRODUCT, patchOnDemandProduct)
  .mapAction(OnDemandActions.Type.DELETE_ONDEMAND_PRODUCT, removeOnDemandProduct)
  .build();

export default reducer;

import PhoneNumber from "awesome-phonenumber";

const newNcellNumberRegex = /^(970)\d{7}$/;

export const isNewPhoneNumber = (phoneNumber: string): boolean =>
  newNcellNumberRegex.test(phoneNumber);

export const isStartWith970 = (phNumber: string): boolean => phNumber.slice(4, 7) === "970";

export const isValidPhone = (value: string): boolean => {
  const ph = new PhoneNumber(value);
  const phoneNumber = ph.getNumber("significant");
  if (ph.getCountryCode() === 977 && isStartWith970(value)) {
    return isNewPhoneNumber(phoneNumber);
  }
  return ph.isValid() && ph.isMobile();
};

import { ReducerBuilder } from './ReducerBuilder';
import * as SlotActions from '../actions/slot';
import { createSelector } from 'reselect'
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

const INITIAL_STATE = [];

function _addSlots(state, payload) {
  return payload;
}

function _createSlots(state, payload) {
  const newState = state.concat(payload);
  return newState;
}

function _updateSlot(state, payload) {
  const newState = [...state];
  const slotIndex = newState.findIndex(({ id }) => id === payload.id);
  if (~slotIndex) {
    newState[slotIndex] = payload;
  } else {
    newState.push(payload);
  }
  return newState;
}

function _removeSlot(state, payload) {
  const newState = state.filter(({ id }) => id !== Number(payload));
  return newState;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(SlotActions.Type.GET_SLOTS, _addSlots)
  .mapAction(SlotActions.Type.BOOK_SLOT, _updateSlot)
  .mapAction(SlotActions.Type.SLOT_REMOVE, _removeSlot)
  .mapAction(SlotActions.Type.SLOT_GET, _updateSlot)
  .build();
export default reducer;


//selectors
export const slotStartTimeSelector = (event) => moment(event.start).format('h:mm a');
export const slotEndTimeSelector = (event) => moment(event.end).format('h:mm a');
export const slotFromTimeSelector = (event) => moment(event.from).format('h:mm a');
export const slotUntilTimeSelector = (event) => moment(event.until).format('h:mm a');

export const getSlots = (state) => state.slots;
export const getServiceProviders = (state) => state.resources.resourceCentreServiceProviders;

export const getBookingsSortedByTime = createSelector([getSlots, getServiceProviders], (slots, serviceProviders) => {
  const bookings = [];
  slots.forEach((slot) => {
    if (!slot.bookings || !slot.bookings.length) return;
    let serviceProvider = serviceProviders.find((sp) => sp.id === slot.serviceProviderId)
    slot.bookings.forEach((booking) => {
      booking = cloneDeep(booking);
      booking = { ...booking, ...slot, serviceProvider };
      bookings.push(booking);
    });
  });
  return bookings;
});

export const getBookingsSummaryPerSP = createSelector([getSlots], (slots) => {
  const summary = {};
  slots.forEach(({ serviceProviderId, bookings }) => {
    let spSummary = summary[serviceProviderId] || { handled: 0, reserved: 0, cancelled: 0, empty: 0, referred: 0 };
    if (!bookings || !bookings.length) {
      spSummary.empty += 1;
    } else {
      bookings.forEach((b) => {
        spSummary[b.status || 'reserved'] += 1;
      });
    }
    summary[serviceProviderId] = spSummary;
  });
  return summary;
});

export const filterCancelledBookings = (bookings = []) => bookings.filter((b) => b.status !== 'cancelled');
import { Alert, Box, Button, TextField, Typography, Autocomplete } from "@mui/material";
import * as React from "react";
import { pick, startCase } from "lodash";
import { connect } from "react-redux";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import { FormState, getInitialState } from "../../models/Referrer";
import { postReferrer, patchReferrer } from "../../actions/referrers";
import { Referrer, ReferrerProductServiceEntities } from "../../interfaces/ReferrerInterface";
import InputPhoneNumber from "../../components/InputPhoneNumber";
import { isEmail, isValidMobileNumber, isISODateString } from "../../helpers/validators";
import ReferrerProductServices from "./ReferrerProductServices";
import { ledgerFindBySubLedgerType } from "../accounts/hooks";
import { TransactionType, SubLedgerTypes } from "../../interfaces/Accounts";
import VendorMapping from "../../components/VendorMapping";
import ToggleInputDate from "../../components/ToggleADBS";
import { bsDateField } from "../../models/Accounts";
import useIsAccountSubscribed from "../../hooks/accounts";
import { Mode, Modes } from "./Referrers";

const { useState, useEffect } = React;

const referrerField = ["referrer", "phone", "email", "address", "productService", "tds", "rate"];
const extraField = ["ledgerId", "openingBalance", "openingBalanceDate", "transactionType"];

interface Props {
  mode: Modes;
  handleClose: () => void;
  selected: Referrer;
  postReferrer: (data) => void;
  patchReferrer: (data, id) => void;
}

export function getStateFromProps(
  selected: Referrer,
  isAccountSubscribed: boolean,
  mode: Modes
): FormState | null {
  if (mode === Mode.CREATE) return getInitialState();
  if (!selected) return null;
  const toBeExtract = isAccountSubscribed ? [...referrerField, ...extraField] : referrerField;
  const data = pick(selected, toBeExtract);

  return {
    ...data,
    productService:
      data.productService?.map((item) => ({
        productId: item.productId,
        serviceId: item.serviceId,
        entityType: item.entityType,
        price: item.price,
        originalPrice:
          item.entityType === ReferrerProductServiceEntities.service
            ? item.service.servicePriceExcVAT
            : item.product.unitPriceExcVAT,
        name: item.service?.name || item.product.name
      })) || []
  } as FormState;
}

function isValidProductServices(productServices) {
  return productServices.every((item) => (item.productId || item.serviceId) && item.price);
}

function ReferrerCreateEdit(props: Props) {
  const {
    mode,
    handleClose,
    selected,
    postReferrer: postReferrerAction,
    patchReferrer: patchReferrerAction
  } = props;

  const isAccountSubscribed = useIsAccountSubscribed();
  const otherMapGl = ledgerFindBySubLedgerType(SubLedgerTypes.REFERRER);
  const title = mode === "create" ? tl("referrer.createReferrer") : tl("referrer.editReferrer");

  const [form, setForm] = useState<FormState | null>(
    getStateFromProps(selected, isAccountSubscribed, mode) || {
      ...getInitialState()
    }
  );

  React.useEffect(() => {
    if (isAccountSubscribed && otherMapGl && mode === "create") {
      setForm({ ...form, ledgerId: otherMapGl.id } as FormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountSubscribed, otherMapGl, mode]);
  const [saveAttempted, setSaveAttempted] = useState(false);
  const [errorState, setErrorState] = useState({
    referrer: true,
    address: true,
    email: true,
    phone: true,
    productService: false
  });

  function handleCloseActions() {
    handleClose();
    setForm(getInitialState());
    setSaveAttempted(false);
  }

  useEffect(() => {
    if (selected?.id) {
      setForm(getStateFromProps(selected, isAccountSubscribed, mode));
    }
  }, [selected?.id, selected, isAccountSubscribed, mode]);

  function validateForm(formP) {
    const errorStateInitial = {
      referrer: true,
      address: false,
      email: true,
      phone: true,
      productService: false
    };
    if (formP.referrer?.length > 2) {
      errorStateInitial.referrer = false;
    }
    if (isEmail({ msg: "" })(formP.email) || !formP.email) {
      errorStateInitial.email = false;
    }
    if (isValidMobileNumber({ msg: "" })(formP.phone) || !formP.phone) {
      errorStateInitial.phone = false;
    }
    if (form.productService && !isValidProductServices(form.productService)) {
      errorStateInitial.productService = true;
    }
    setErrorState(errorStateInitial);
    return Object.keys(errorStateInitial).some((key) => errorStateInitial[key]);
  }

  return (
    <Panel title={title} onClose={() => handleCloseActions()}>
      <Box pl={3} pr={3} height="calc(100vh - 90px)" style={{ overflowY: "auto" }}>
        <TextField
          label="Name*"
          margin="dense"
          placeholder="Name"
          variant="outlined"
          data-testmation="referrerName"
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={saveAttempted && errorState.referrer}
          value={form.referrer}
          helperText={saveAttempted && errorState.referrer && "Please enter valid referrer"}
          onChange={(e) => setForm({ ...form, referrer: e.target.value })}
        />
        <TextField
          label="Email"
          margin="dense"
          variant="outlined"
          placeholder="Email"
          data-testmation="referrerEmail"
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={saveAttempted && errorState.email}
          value={form.email}
          helperText={saveAttempted && errorState.email && "Please enter valid email"}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
        <InputPhoneNumber
          field={{ key: "phone", label: "phone" }}
          data={{ phone: form.phone }}
          errorMessages={saveAttempted && errorState.phone && ["Please enter valid phone"]}
          error={saveAttempted && errorState.phone}
          onChange={(value) => {
            setForm({ ...form, phone: value });
          }}
          isFocused={() => ({})}
          isBlurred={() => ({})}
        />
        <TextField
          data-testmation="referrerAddress"
          label="Address"
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder="Address"
          InputLabelProps={{ shrink: true }}
          error={saveAttempted && errorState.address}
          value={form.address}
          onChange={(e) => setForm({ ...form, address: e.target.value })}
          helperText={saveAttempted && errorState.address && "Please enter valid address"}
        />
        <Box display="flex" gap={2}>
          <TextField
            data-testmation="referrerTds"
            label="TDS(%)"
            margin="dense"
            variant="outlined"
            type="number"
            fullWidth
            placeholder="TDS"
            InputLabelProps={{ shrink: true }}
            value={form.tds}
            onFocus={(e) => e.target.select()}
            onChange={(e) => setForm({ ...form, tds: Number(e.target.value) })}
          />
          <TextField
            data-testmation="referrerRate"
            label="Rate(%)"
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder="Rate"
            InputLabelProps={{ shrink: true }}
            value={form.rate}
            onFocus={(e) => e.target.select()}
            type="number"
            onChange={(e) => setForm({ ...form, rate: Number(e.target.value) })}
          />
        </Box>
        {isAccountSubscribed && otherMapGl && (
          <>
            <Typography m="10px 0">{tl("accounts.accountInformation")}</Typography>
            <Box display="flex" flexDirection="column" justifyContent="space-between" gap={1.5}>
              <VendorMapping
                subLedgerType={SubLedgerTypes.REFERRER}
                onChange={(value) => {
                  if (value) {
                    setForm({ ...form, ledgerId: value.id });
                  }
                }}
                id={form.ledgerId}
              />
              <TextField
                label="Opening Balance"
                margin="dense"
                variant="outlined"
                data-testmation="referrerOpeningBalance"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={form.openingBalance}
                onChange={(e) => setForm({ ...form, openingBalance: Number(e.target.value) })}
              />
              <ToggleInputDate
                key="openingBalanceDate"
                field={{
                  ...bsDateField,
                  key: "openingBalanceDate",
                  label: "openingBalanceDate"
                }}
                data={{ openingBalanceDate: form?.openingBalanceDate }}
                isFocused={() => ({})}
                isBlurred={() => ({})}
                showAgeField={false}
                changeDate={(value) => {
                  if (isISODateString(value)) {
                    setForm({ ...form, openingBalanceDate: value });
                  } else {
                    setForm({ ...form, openingBalanceDate: null });
                  }
                }}
              />
              <Autocomplete
                options={[TransactionType.DEBIT, TransactionType.CREDIT]}
                value={form?.transactionType}
                onChange={(e, value) => {
                  setForm({ ...form, transactionType: value as TransactionType });
                }}
                getOptionLabel={(option) => startCase(option)}
                renderInput={(params) => (
                  <TextField
                    //   eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="Transaction Type"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>
          </>
        )}

        <div>
          <ReferrerProductServices
            handleUpdates={(productService) => {
              setForm({ ...form, productService });
            }}
            propState={form.productService}
          />
          {saveAttempted && errorState.productService && (
            <Alert severity="error" style={{ marginTop: "16px" }}>
              Please fill out all the product service fields!
            </Alert>
          )}
        </div>
      </Box>
      <Box display="flex" justifyContent="flex-end" mr="8px">
        <Button onClick={() => handleCloseActions()} style={{ marginRight: "8px" }}>
          Cancel
        </Button>
        {selected?.referrerType !== "root" && (
          <Button
            data-testmation="referrerSave"
            onClick={async () => {
              const hasErrors = validateForm(form);
              if (hasErrors) {
                setSaveAttempted(true);
              } else {
                if (selected) {
                  await patchReferrerAction(form, selected.id);
                } else {
                  await postReferrerAction(form);
                }
                handleClose();
              }
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
      </Box>
    </Panel>
  );
}

export default connect(null, { postReferrer, patchReferrer })(ReferrerCreateEdit);

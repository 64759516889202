import { useEffect } from "react";
import { useHistory } from "react-router";

export default function useLogoutListener(): void {
  const history = useHistory();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // Check if the user key is removed from localStorage
      if (event.key === "user" && !event.newValue) {
        // Redirect to the login page
        history.push("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [history]);
}

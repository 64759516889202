import { Box, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { isISODateString } from "../../../../helpers/validators";
import { TransactionType } from "../../../../interfaces/StockInterfaces";
import { tl } from "../../../../components/translate";

interface Props {
  transactionType: TransactionType;
  value: string;
  onExpDateUpdate: (v) => void;
  error: boolean;
}

export default function StockExpiryDate(props: Props): JSX.Element {
  const { transactionType, value, onExpDateUpdate, error } = props;

  return (
    <Box>
      <TextField
        fullWidth
        label={tl("StockEntry.ExpiryDate")}
        error={error}
        disabled={[
          TransactionType.EDIT_PURCHASE,
          TransactionType.EDIT_OPENING_STOCK,
          TransactionType.ADJUSTMENT
        ].includes(transactionType as TransactionType)}
        InputLabelProps={{ shrink: true }}
        value={
          value
            ? moment(value).format("YYYY-MM-DD")
            : moment(moment().add(2, "y").endOf("y")).format("YYYY-MM-DD")
        }
        placeholder="YYYY-MM-DD"
        type="date"
        variant="outlined"
        onChange={(e) => {
          if (isISODateString(e.target.value)) {
            onExpDateUpdate(new Date(e.target.value).toISOString());
          }
        }}
      />
    </Box>
  );
}

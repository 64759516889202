import React from "react";

export const Pills = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 491.1 491.1"
    >
      <g>
        <path
          d="M488.4,179.35c-8.2-70.6-112.2-125.2-243.9-125.2C107.2,54.15,0,113.45,0,188.35c0,0.2,0,114.4,0,114.4
		c0,74.9,107.2,134.2,244.5,134.2s244.5-59.3,246.6-134.2C491,302.75,490.1,182.25,488.4,179.35z M448.4,189.35
		c0,29.5-36.7,60.8-96,78.7l-168-168c18.8-2.8,38.9-4.3,60.2-4.3C364.1,95.75,448.4,144.65,448.4,189.35z M136.6,110.65l168,168
		c-18.8,2.8-38.9,4.3-60.2,4.3c-112.9,0-193-43.7-202.9-85.1C41.6,197.95,25.1,146.65,136.6,110.65z M245.5,396.35
		c-120.7,0-203.9-49.9-203.9-93.6v-38.7c43.5,35.5,117.3,58.5,202.9,58.5c86.9,0,161.7-23.3,204.9-59.7v40
		C449.4,347.55,365.1,396.35,245.5,396.35z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import * as React from "react";
import { Autocomplete, createFilterOptions, TextField, TextFieldVariants } from "@mui/material";
import { knownUsFromDefaultOptions } from "../../models/Client";
import { t, tl } from "../translate";

interface Props {
  value: string;
  onChange: (value: string) => void;
  width?: string;
  variant?: TextFieldVariants;
  isLabelShrunk?: boolean;
  required?: boolean;
  error?: { value: boolean; message: string };
  isBookingWindow?: boolean;
}

interface KnownUsFrom {
  value: string;
  newCreated?: boolean;
}

const filter = createFilterOptions<{ value: string }>();

const KnownUsFromSelect = ({
  value,
  onChange,
  width,
  error = { value: false, message: "" },
  variant = "outlined",
  isLabelShrunk = false,
  required = false,
  isBookingWindow = false
}: Props): JSX.Element => {
  const [knownUsFrom, setKnownUsFrom] = React.useState<KnownUsFrom | null>(null);

  const [knownUsFromOptions, setKnownUsFromOptions] =
    React.useState<KnownUsFrom[]>(knownUsFromDefaultOptions);

  const selectedValue = knownUsFromDefaultOptions.find((item) => item.value === value);

  React.useEffect(() => {
    if (selectedValue) {
      setKnownUsFrom(selectedValue);
    } else if (value && !selectedValue) {
      // set selected value if it is other than default options (custom words)
      setKnownUsFromOptions((prevState) => [...prevState, { value }]);
      setKnownUsFrom({ value });
    }
  }, [value, selectedValue]);

  return (
    <Autocomplete
      freeSolo
      clearOnBlur
      forcePopupIcon={isBookingWindow}
      value={knownUsFrom}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.value);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            newCreated: true,
            value: inputValue
          });
        }
        return filtered;
      }}
      options={knownUsFromOptions}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setKnownUsFrom({
            value: newValue
          });
          onChange(newValue);
        } else if (newValue && newValue.newCreated) {
          // Create a new value from the user input
          setKnownUsFrom({
            value: newValue.value
          });
          setKnownUsFromOptions([...knownUsFromOptions, { value: newValue.value }]);
          onChange(newValue.value);
        } else {
          setKnownUsFrom(newValue);
          onChange(newValue?.value || "");
        }
      }}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.value;
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          required={required}
          error={error.value}
          helperText={error.message}
          variant={isBookingWindow ? undefined : variant}
          data-testmation="knownUsFromInput"
          InputLabelProps={isLabelShrunk ? { shrink: true } : {}}
          placeholder={t("CreateCLient.WhereDidYouHearUsAbout")}
          label={isBookingWindow ? "" : tl("CreateClient.KnownUsFrom")}
          sx={{ width: width || "100%" }}
          InputProps={
            isBookingWindow
              ? {
                  ...params.InputProps,
                  disableUnderline: true,
                  style: {
                    fontSize: "12px",
                    width: "200px"
                  }
                }
              : {
                  ...params.InputProps,
                  style: {
                    width: width || "100%"
                  }
                }
          }
        />
      )}
    />
  );
};

export default KnownUsFromSelect;

import React from 'react';
import * as moment from 'moment';
import { Box } from '@mui/material';
import { Label } from './GenericTextField';
import CalendarDropdown from '../../../components/CalendarDropdown/CalendarDropdown';

function SetFeedDate({ selectedDate, onChange, label }) {
  const date = moment(selectedDate).toDate();
  return (
    <Box component="div" display={'flex'}>
      <Label>{label}</Label>
      <CalendarDropdown
        fullwidth
        disable={false}
        withTimeSelector
        TextFieldProps={{
          fullWidth: true,
          variant: 'outlined',
          margin: 'dense'
        }}
        date={date}
        onChange={onChange}
      />
    </Box>
  );
}

export default SetFeedDate;

import { Box, Button, Link, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { useHotkeys } from "react-hotkeys-hook";
import logo from "../../../../../assets/images/poweredByOkhati.png";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { t, tl } from "../../../../components/translate";
import { dateWithZeroBeforeSingleDigits } from "../../../../helpers/formatters";
import usePrintStyles from "../../../../hooks/usePrintStyles";
import { BillStatus, BillType, DocumentTypes } from "../../../../interfaces/BillInterfaces";
import { RootState } from "../../../../store";
import { getSectionStyle } from "../../../Lab/LabPrint/LabPrintFunctions";
import LabPrintLetterHead from "../../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import LabPrintLetterHeadCentered from "../../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import DescribeBillV2 from "../../PrintBill/BillDescriptionV2";
import { BillClientInfo, InfoField, checkWalkInCustomer } from "../../PrintBill/BillPrintHelpers";
import "./styles.scss";
import { getCustomerNumber } from "../../../Client/ClientList";

interface PrintBillProps {
  billData: BillType;
  useBordered?: boolean;
  labelStyle?: React.CSSProperties;
  forceTemplate?: string | null;
  onPrintClick?: () => void;
}

export const disclaimerText =
  "Disclaimer: This is just the preview of bill. This cannot be issued to customer yet.";

const getPrintTitle = (billStatus: string): string => {
  if (billStatus === BillStatus.draft) {
    return `${t("billing.draft")}`;
  }
  if (billStatus === BillStatus.cancelled) {
    return `${t("billing.invoice")} (${t("billing.cancelled")})`;
  }
  return `${t("billing.invoice")}`;
};

const PrintBill: FunctionComponent<PrintBillProps> = ({
  billData,
  labelStyle,
  useBordered,
  forceTemplate,
  onPrintClick
}) => {
  const printRef = React.useRef();
  const reactToPrintRef = React.useRef(null);
  const resourceCentre = useSelector(
    (state: RootState) => state.resources.resourceCentres[0] || state.userContext.resourceCentre
  );
  const SPs = useSelector((state: RootState) => state.resources.resourceCentreServiceProviders);

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showFooter = resourceCentre?.settings?.printSettings.billPrintSettings?.includeFooter;
  const isVatRegistered = resourceCentre?.settings?.billingSettings.vatRegistered;

  const hideFooterCompletely =
    resourceCentre.settings.printSettings.billPrintSettings.hideFooterLogoCompletely;

  const showReferrer = resourceCentre?.settings?.printSettings.includeReferrer;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const customSubtitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
  const hideRcLogo = Boolean(customTitle);

  let letterHeadComponent = <Box />;
  if (showLetterhead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <LabPrintLetterHeadCentered
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    } else {
      letterHeadComponent = (
        <LabPrintLetterHead
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    }
  }

  const getCustomPrintStyle = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePrintStyles({
      pageStyle: "size: A4; margin: 0mm"
    }).applyPrintStyles();
  };

  useHotkeys(
    "alt + p",
    (event) => {
      event.preventDefault();
      getCustomPrintStyle();
      reactToPrintRef.current.click();
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  if (!billData || !billData.document) {
    return null;
  }

  return (
    <>
      <ReactToPrint
        onAfterPrint={() => {
          // after cancelling print, shortcut works only after clicking on window.
          window.focus();
        }}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          <div>
            <Tooltip arrow title="Alt + P">
              <Link
                type="submit"
                className="actionBtn"
                component="button"
                onMouseEnter={() => {
                  getCustomPrintStyle();
                }}
                ref={reactToPrintRef}
                onClick={() => ({})}
                style={{ textDecoration: "none", fontSize: "15px" }}
              >
                <Button
                  component="span"
                  sx={{ textTransform: "uppercase", fontSize: "25px" }}
                  style={labelStyle}
                >
                  PRINT PREVIEW
                </Button>
              </Link>
            </Tooltip>
          </div>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents">
        <div ref={printRef}>
          <Box
            component="table"
            width="100%"
            className="water-marks"
            waterMarkText={disclaimerText}
          >
            <tfoot>
              <tr>
                <td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterhead && (
                <tr>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="0.8cm"
                      width="100%"
                      justifyContent="center"
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Box
                          fontSize="0.45cm"
                          marginTop="0.5cm"
                          marginBottom="0.3cm"
                          fontWeight={400}
                          style={{ textTransform: "uppercase" }}
                        >
                          {billData.type === DocumentTypes.CREDITNOTE && tl("billing.creditNote")}{" "}
                          {billData.type !== DocumentTypes.CREDITNOTE &&
                            getPrintTitle(billData.status)}
                          {billData?.printCount ? ` (COPY - ${billData?.printCount})` : ""}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="1cm"
                      justifyContent="space-between"
                      borderTop="1px solid black"
                      borderRight={useBordered ? "1px solid black" : ""}
                      borderBottom={useBordered ? "1px solid black" : ""}
                      borderLeft={useBordered ? "1px solid black" : ""}
                      p="0.3cm"
                    >
                      <Box component="div" width="50%">
                        {checkWalkInCustomer(billData.client, "isWalkInCustomer") && (
                          <BillClientInfo
                            client={billData.client}
                            customerNumber={getCustomerNumber(billData.customerNumber) || ""}
                            showDobOnPrint={false}
                          />
                        )}
                      </Box>

                      <Box component="div" width="40%">
                        <InfoField
                          label={
                            billData.type === DocumentTypes.CREDITNOTE
                              ? tl("billing.creditNoteNumber")
                              : tl("billnumber")
                          }
                          data={<>: {billData.isDraft ? "-" : billData.billNumber}</>}
                        />
                        {billData.type === DocumentTypes.CREDITNOTE && (
                          <InfoField
                            label={tl("billing.creditNoteForBill")}
                            data={<>: {billData.referencedBillNum}</>}
                          />
                        )}
                        <InfoField
                          label={tl("billing.issueDate")}
                          data={
                            <>
                              :{" "}
                              {dateWithZeroBeforeSingleDigits(
                                convertADtoBS(moment(billData.issueDate)).formatted
                              )}{" "}
                              {moment(billData.issueDate).format("h:mm a")}
                            </>
                          }
                        />
                        <InfoField
                          label={tl("billing.dateAD")}
                          data={
                            <>
                              :{" "}
                              {dateWithZeroBeforeSingleDigits(
                                moment(billData.issueDate).format("YYYY/MM/DD")
                              )}{" "}
                            </>
                          }
                        />
                        {billData.referredBy && (
                          <InfoField
                            label={tl("booking.referredBy")}
                            data={<>: {billData.referredBy.toUpperCase()}</>}
                          />
                        )}

                        {billData.insuranceNumber && (
                          <InfoField
                            label={tl("billing.insuranceNumber")}
                            data={<>: {billData.insuranceNumber} </>}
                          />
                        )}
                        {billData.claimNumber && (
                          <InfoField
                            label={tl("billing.claimNumber")}
                            data={<>: {billData.claimNumber} </>}
                          />
                        )}
                      </Box>
                    </Box>
                    <DescribeBillV2
                      isVatRegistered={isVatRegistered}
                      billData={billData}
                      SPs={SPs}
                      relatedSettings={{
                        showReferrer,
                        isBordered: useBordered,
                        headerColor
                      }}
                      forceTemplate={forceTemplate}
                      clientBalance={0}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showFooter ? (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <img src={logo} alt="logo" height="35px" />
            </Box>
          ) : (
            <Box width="100%" className="billFooterPos">
              <Box style={{ paddingRight: "10mm" }} mt={2} display="flex" justifyContent="flex-end">
                {!hideFooterCompletely && <img src={logo} alt="logo" height="35px" />}
              </Box>
              <Box height="2cm" />
            </Box>
          )}
        </div>
      </iframe>
    </>
  );
};

PrintBill.defaultProps = {
  labelStyle: {},
  forceTemplate: null,
  onPrintClick: () => null,
  useBordered: false
};

export default PrintBill;

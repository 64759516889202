import React from "react";
import { useLocation } from "react-router";

const useOpenPrintWindow = (
  ref: React.RefObject<HTMLElement> | null,
  getPrintStyles?: () => void
): void => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.openPrintWindow) {
      if (getPrintStyles) {
        getPrintStyles();
      }
      if (ref?.current) {
        ref.current.click();
      }
    }
  }, [ref?.current]);
};

export default useOpenPrintWindow;

import actionCreatorFactory from "typescript-fsa";
import { NotificationActions } from "../interfaces/ActionTypes";

const actionCreator = actionCreatorFactory();

interface NotificationActionInterface {
  id: string | number;
  variant: "success" | "warning" | "error" | "info";
  message: string | React.ReactElement;
  autoTimeout?: boolean;
  timeout?: number;
}

export const notificationAdd = actionCreator<NotificationActionInterface>(
  NotificationActions.NOTIFICATION_ADD
);
export const notificationRemove = actionCreator(
  NotificationActions.NOTIFICATION_REMOVE
);

import fscreen from "fscreen";
import { useRef, useState, useEffect, useCallback } from "react";

interface FullScreenInterface {
  fullscreenRef: React.MutableRefObject<undefined>;
  fullscreenEnabled: boolean;
  fullscreenActive: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
}

export default function useFullscreen(): FullScreenInterface {
  const fullscreenRef = useRef();
  const [active, setActive] = useState(false);
  useEffect(() => {
    const handleChange = () => {
      setActive(fscreen.fullscreenElement === fullscreenRef.current);
    };
    fscreen.addEventListener("fullscreenchange", handleChange);
    return () => fscreen.removeEventListener("fullscreenchange", handleChange);
  }, []);
  const enterFullscreen = useCallback(async () => {
    if (fscreen.fullscreenElement) {
      await fscreen.exitFullscreen();
    }
    return fscreen.requestFullscreen(fullscreenRef.current);
  }, []);
  const exitFullscreen = useCallback(async () => {
    if (fscreen.fullscreenElement === fullscreenRef.current) {
      return fscreen.exitFullscreen();
    }
    return "";
  }, []);
  return {
    fullscreenRef,
    fullscreenEnabled: fscreen.fullscreenEnabled,
    fullscreenActive: active,
    enterFullscreen,
    exitFullscreen
  };
}

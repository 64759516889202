import { useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { FileUploadOutlined } from "@mui/icons-material";

import { tl } from "../../../../components/translate";
import Dialog from "../../../../components/Dialog/Dialog";
import { notificationAdd } from "../../../../actions/notification";
import ImageCrop from "../../../../components/ImageCrop/ImageCrop";
import { QrPaymentInfoType } from "../../../../interfaces/QrPayment";
import { uploadQrPaymentImage } from "../../../../api/resourceCentre";
import { deleteQrImage, updateQrData } from "../../../../actions/resourceCentre";

interface QrImageUploaderProps {
  initialImage: string;
  activePaymentMethod: string;
  rcId: number;
  qrPaymentInfo: QrPaymentInfoType;
  setQrPaymentInfo: Dispatch<SetStateAction<QrPaymentInfoType>>;
}

function QrImageUploader({
  initialImage,
  rcId,
  qrPaymentInfo,
  activePaymentMethod,
  setQrPaymentInfo
}: QrImageUploaderProps): React.ReactElement {
  const dispatch = useDispatch();
  const imageRef = React.useRef(null);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [cropperOpen, setCropperOpen] = React.useState<boolean>(false);
  const [deleteQrDialogOpen, setDeleteQrDialogOpen] = React.useState<boolean>(false);
  const [temporaryQr, setTemporaryQr] = React.useState<string | null>(initialImage);

  function toggleDialog() {
    setDialogOpen(!dialogOpen);
  }

  function handleImageChange(e) {
    const file = e.target.files[0];
    e.preventDefault();
    if (!file || file?.size >= 2097152) {
      return;
    }

    const nameOnly = file.name.lastIndexOf(".") + 1;
    const ext = file.name.substr(nameOnly, file.name.length).toLowerCase();

    if (["jpg", "jpeg", "png", "gif"].includes(ext) && file) {
      setTemporaryQr(URL.createObjectURL(file));
      setCropperOpen(true);
    } else {
      toggleDialog();
    }
  }

  const handleDeleteQrImage = () => {
    const qrImageKey = qrPaymentInfo[activePaymentMethod].images[0].key;

    dispatch(deleteQrImage(rcId, activePaymentMethod, qrImageKey));

    const updatedMethodInfo = {
      ...qrPaymentInfo[activePaymentMethod],
      images: [
        {
          ...qrPaymentInfo[activePaymentMethod].images[0],
          url: "",
          key: ""
        }
      ]
    };

    const updatedQrData = {
      ...qrPaymentInfo,
      [activePaymentMethod]: updatedMethodInfo
    };

    setQrPaymentInfo(updatedQrData);
    setDeleteQrDialogOpen(false);
  };

  return (
    <Box display="flex" width="100%">
      {deleteQrDialogOpen && (
        <Dialog
          title={tl("qrPayment.deleteQrImage")}
          description="Are you sure you want to delete this QR image?"
          next={handleDeleteQrImage}
          cancel={() => setDeleteQrDialogOpen(false)}
          readMode={false}
        />
      )}
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center"
        }}
      >
        {initialImage ? (
          <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
            <Box
              sx={{
                height: "250px",
                width: "100%",
                borderRadius: "5px",
                border: "2px dashed #b2beb5",
                textAlign: "center",
                position: "relative"
              }}
            >
              <img src={initialImage} alt="QR Code" style={{ height: "100%" }} />
            </Box>
            <Button
              type="button"
              onClick={() => setDeleteQrDialogOpen(true)}
              sx={{
                width: "100%",
                textAlign: "center",
                borderRadius: "5px",
                padding: "8px",
                color: "#fff",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "red"
                }
              }}
            >
              Remove QR
            </Button>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "10px"
            }}
          >
            <Box
              style={{
                border: "2px dashed #b2beb5",
                height: "250px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px"
              }}
            >
              <FileUploadOutlined style={{ fontSize: "50px", color: "#b2beb5" }} />
              <p style={{ color: "#b2beb5" }}>Upload QR Code</p>
            </Box>

            <Button
              sx={(theme) => ({
                width: "100%",
                textAlign: "center",
                borderRadius: "5px",
                padding: "8px",
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main
                }
              })}
              onClick={() => imageRef?.current?.click()}
            >
              Upload QR Code
            </Button>
          </Box>
        )}
      </Box>

      <ImageCrop
        image={temporaryQr}
        isOpen={cropperOpen}
        dynamicRatio={false}
        onCancel={() => {
          setCropperOpen(false);
          setTemporaryQr(initialImage);
        }}
        onSave={async (img) => {
          try {
            const { qrImageUrl, qrImageKey } = await uploadQrPaymentImage(img);
            if (!qrImageUrl || !qrImageKey) {
              return;
            }

            const updatedMethodInfo = {
              ...qrPaymentInfo[activePaymentMethod],
              images: [
                {
                  ...qrPaymentInfo[activePaymentMethod].images[0],
                  url: qrImageUrl,
                  key: qrImageKey
                }
              ]
            };

            const updatedQrData = {
              ...qrPaymentInfo,
              [activePaymentMethod]: updatedMethodInfo
            };
            // update qr image in database and in redux
            setQrPaymentInfo(updatedQrData);
            dispatch(updateQrData(rcId, updatedQrData));
            setCropperOpen(false);
            setTemporaryQr(qrImageUrl);
          } catch (err) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Failed to upload qr image",
                autoTimeout: true
              })
            );
          }
        }}
      />
      <input
        type="file"
        accept="image/*"
        ref={imageRef}
        style={{ display: "none" }}
        id="upload-qr"
        onChange={handleImageChange}
      />
      {dialogOpen && (
        <Dialog
          title="Cannot upload image"
          description="File size limit is only 2MB or wrong file format"
          // eslint-disable-next-line react/jsx-no-bind
          next={toggleDialog}
          readMode
        />
      )}
    </Box>
  );
}

export default QrImageUploader;

import React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import OkIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/HighlightOff';
import styles from './ContentEditable.module.css';
import { Typography } from '@mui/material';
import ClickAwayTrigger from '../ClickAwayTrigger/ClickAwayTrigger';

interface ContentEditablePropsInterface {
  label: string;
  placeholder: string;
  displayText: string;
  editMode: boolean;
  showActions: boolean;
  setEditMode: (mode: boolean) => void;
  onConfirmEdit: (e) => void;
  onCancelEdit: (e) => void;
  onBlur: (e) => void;
  textArea: boolean;
  hidelabel?: boolean;
  disabled?: boolean;
}

const ContentEditable: React.FC<ContentEditablePropsInterface> = ({
  label,
  placeholder,
  displayText,
  children,
  editMode,
  setEditMode,
  showActions,
  onCancelEdit,
  onConfirmEdit,
  onBlur,
  textArea,
  hidelabel,
  disabled
}) => {
  return (
    <Box className={styles.contentEditable} width={'100%'} display={'flex'} paddingBottom={'8px'}>
      {!hidelabel && (
        <Box minWidth={'160px'} fontWeight={'500'} display={'flex'}>
          <Typography>
            <Box component="span" fontSize={'14px'} fontWeight={'500'} fontSize="0.875rem">
              {label}
            </Box>
          </Typography>
        </Box>
      )}
      <Box className={styles.editableArea} display={'flex'}>
        {!editMode ? (
          <Box display={'flex'} onClick={() => setEditMode(true)}>
            {displayText ? (
              textArea ? (
                <Typography>
                  <Box
                    fontSize={'14px'}
                    style={{
                      width: '40vw',
                      maxHeight: '200px',
                      overflow: 'auto',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {displayText}
                  </Box>
                </Typography>
              ) : (
                <Typography>
                  <Box component="span" fontSize={'14px'}>
                    {displayText}
                  </Box>
                </Typography>
              )
            ) : (
              <Box className={styles.placeholder}>{placeholder}</Box>
            )}
            {!disabled && <EditIcon className={styles.editIcon} />}
          </Box>
        ) : (
          <ClickAwayTrigger onClickAway={onBlur}>
            <Box display={'flex'}>
              <Box>{children}</Box>
              <Box display={'flex'} flexBasis={'64px'}>
                {showActions && (
                  <>
                    <OkIcon className={styles.actionIcons} onClick={onConfirmEdit} />
                    <CancelIcon className={styles.actionIcons} onClick={onCancelEdit} />
                  </>
                )}
              </Box>
            </Box>
          </ClickAwayTrigger>
        )}
      </Box>
    </Box>
  );
};

export default ContentEditable;

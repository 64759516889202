import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import List from '../../../components/List';
import { Box, Button, IconButton, Container } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import * as FeedActions from '../../../actions/feed';
import './FeedList.scss';
import { CalendarFunctions } from '../../../components/Calendar';
import { FeedInterface, MODE } from '../../../interfaces/FeedInterfaces';
import FeedInfo from './FeedInfo';
import { isEmpty } from 'lodash';

interface FeedProps {
  feeds: Array<FeedInterface>;
  actions: ActionTypes;
  feed: FeedInterface;
  mode: string;
}

interface ActionButtonsProps {
  row: FeedInterface;
  actions: ActionTypes;
}

interface ActionTypes {
  navigateTo: (url: string) => void;
  deactivateFeed: (feedId: string) => void;
  deleteFeed: (feedId: string) => void;
  publishFeed: (feedId: string) => void;
  loadFeeds: () => void;
  clearDraft: () => void;
  gotoFeed: () => void;
}

const ActionButtons = ({ row, actions }: ActionButtonsProps) => {
  const { id, published, deactivated } = row;
  return <>
    {!published && (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          actions.navigateTo(`/feeds/${id}/edit`);
        }}
        color="primary"
        aria-label="edit feed"
        component="span"
        size="large">
        <EditIcon />
      </IconButton>
    )}
    {published && (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          actions.deactivateFeed(id);
        }}
        variant="text"
        color="primary"
        aria-label="edit feed"
        component="span"
      >
        {deactivated ? <span>activate</span> : <span>deactivate</span>}
      </Button>
    )}
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        if (!window.confirm('Do you really want to delete?')) {
          return;
        }
        actions.deleteFeed(id);
      }}
      style={{ color: '#FF0000' }}
      aria-label="delete feed"
      component="span"
      size="large">
      <DeleteIcon />
    </IconButton>
    {!published && (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          if (!window.confirm('Do you really want to publish?')) {
            return;
          }
          actions.publishFeed(id);
        }}
        color="primary"
        aria-label="edit feed"
        component="span"
        size="large">
        <SendIcon />
      </IconButton>
    )}
  </>;
};

const FeedLists = ({ feeds = [], actions, mode, feed }: FeedProps) => {
  React.useEffect(() => {
    actions.loadFeeds();
  }, []);

  const [selectedFeed, setSelectedFeed] = React.useState<FeedInterface>(
    {} as FeedInterface
  );

  React.useEffect(() => {
    if (feed && mode === MODE.Show) {
      setSelectedFeed(feed);
    }
  }, [feed, mode]);

  const customButtonGroup = (
    <Button
      color="primary"
      variant="contained"
      onClick={() => {
        actions.clearDraft();
        actions.gotoFeed();
      }}
      data-testmation="createFeed"
    >
      Create new
    </Button>
  );

  const closeDetailPanel = () => {
    setSelectedFeed({} as FeedInterface);
    actions.navigateTo(`/feeds`);
  };

  return (
    <Box height={'calc(100% - 4px)'} className="feedList" component="div">
      <Box height={'calc(100% - 84px)'} component="div">
        <List
          automation={'Feeds'}
          data={feeds}
          title="Feeds"
          hideCreateButton={true}
          customButtonGroup={customButtonGroup}
          rowHeight={50}
          withoutSearch={true}
          defaultSortColumn={'startDate'}
          isResponsive={true}
          columns={[
            {
              key: 'title',
              label: 'Title',
              sortable: true,
            },
            {
              key: 'startDate',
              label: 'Start date',
              sortable: true,
              formatter: ({ startDate }) => {
                return CalendarFunctions.bsFullDate(startDate);
              },
            },
            {
              key: 'endDate',
              label: 'End date',
              sortable: true,
              formatter: ({ endDate }) => {
                return CalendarFunctions.bsFullDate(endDate);
              },
            },
            {
              key: 'status',
              label: 'Status',
              sortable: false,
              formatter: ({ published, deactivated }) => {
                if (!published) {
                  return 'draft';
                }
                if (deactivated) {
                  return 'deactivated';
                }
                return 'published';
              },
            },
            {
              key: 'actions',
              label: 'Actions',
              sortable: false,
              cellRenderer: (row: FeedInterface) => {
                return <ActionButtons row={row} actions={actions} />;
              },
            },
          ]}
          activeRow={selectedFeed && selectedFeed.id}
          onRowClick={(row) => {
            if (row.id === selectedFeed.id) {
              setSelectedFeed({} as FeedInterface);
              actions.navigateTo(`/feeds`);
            } else {
              actions.navigateTo(`/feeds/${row.id}`);
            }
          }}
        />
      </Box>
      {!isEmpty(selectedFeed) && (
        <FeedInfo feedId={selectedFeed.id} handleViewClose={closeDetailPanel} />
      )}
    </Box>
  );
};

export default connect(
  (state, { feedId }) => ({
    feeds: state.feeds.collection,
    draft: state.feeds.draft,
    feed: state.feeds.collection.find((item) => item.id === feedId),
  }),
  (dispatch) => ({
    actions: {
      loadFeeds: () => dispatch(FeedActions.fetchFeeds()),
      clearDraft: () => dispatch(FeedActions.clearDraft()),
      deleteFeed: (feedId) => dispatch(FeedActions.deleteDraft(feedId)),
      deactivateFeed: (feedId) => dispatch(FeedActions.deactivateFeed(feedId)),
      publishFeed: (feedId) => dispatch(FeedActions.publishDraft(feedId)),
      gotoFeed: () => dispatch(push('/feeds/new')),
      navigateTo: (url) => dispatch(push(url)),
    },
  })
)(FeedLists);

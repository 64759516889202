import React, { ReactElement } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  ReceiptLong as ReceiptLongIcon,
  Receipt as ReceiptIcon,
  Person as PersonIcon,
  People as PeopleIcon,
  Medication as MedicationIcon,
  Paid as PaidIcon,
  AllInbox as AllInboxIcon,
  Info as InfoIcon,
  Science as ScienceIcon,
  Textsms as TextsmsIcon
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import Tooltip from "@mui/material/Tooltip";
import { roundTwoDecimalPoint } from "../../helpers/number";
import Can from "../Policy/Can";
import { RootState } from "../../store";
import { getRCFromState } from "../../actions/resourceCentre";
import { t, tl } from "../../components/translate";
import { LabStatuses } from "../../interfaces/Lab";
import { KeyIndicators } from "../../interfaces/DashboardInterface";

interface CardWrapperProps {
  label: string;
  children: React.ReactElement;
  toolTipInfo?: string;
}

const CardWrapper = ({ label, children, toolTipInfo }: CardWrapperProps) => (
  <Box
    p={2}
    bgcolor="#fff"
    borderRadius={2}
    boxShadow="0px 4px 20px rgba(0, 150, 84, 0.12)"
    height="100%"
  >
    <Box sx={{ display: "flex", gap: 1 }}>
      <Typography mb={1} fontWeight={700}>
        {label}
      </Typography>
      {toolTipInfo && (
        <Tooltip
          title={
            <Box component="span" sx={{ fontSize: "0.75rem" }}>
              {toolTipInfo}
            </Box>
          }
          placement="top"
          arrow
        >
          <InfoIcon color="action" />
        </Tooltip>
      )}
    </Box>
    {children}
  </Box>
);

export const StatCard = ({
  title,
  totalValue,
  isMoney = false,
  icon,
  cssStyle = {},
  onClick,
  toolTipInfo
}: {
  title: string;
  totalValue: unknown;
  isMoney?: boolean;
  icon: unknown;
  cssStyle?: React.CSSProperties;
  onClick?: () => void;
  toolTipInfo?: string;
}): JSX.Element => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Card
      sx={{
        borderRadius: "8px",
        boxShadow: "0px 0px 4px rgba(0, 150, 84, 0.3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        flexGrow: 1,
        py: isSmScreen ? 1 : 0,
        marginTop: cssStyle?.marginTop,
        textDecoration: "none",
        "&:hover": onClick && {
          boxShadow: "0px 4px 10px rgb(0 150 84 / 25%)",
          cursor: "pointer"
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(!toolTipInfo && { title: "Click to view details" })}
      onClick={() => onClick && onClick()}
    >
      <CardContent
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: isSmScreen ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(0, 150, 29, 0.08)",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#009654",
            p: 0.5,
            mb: isSmScreen ? 0 : 1
          }}
        >
          {icon}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: isSmScreen ? "flex-end" : "center",
            flexDirection: "column"
          }}
        >
          <Box sx={{ display: "flex", gap: 1, textAlign: isSmScreen ? "" : "center" }}>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 500,
                color: "#5A607F"
              }}
              variant="h3"
            >
              {title}
            </Typography>
            {toolTipInfo && (
              <Tooltip
                title={
                  <Box component="span" sx={{ fontSize: "0.75rem" }}>
                    {toolTipInfo}
                  </Box>
                }
                placement="right"
                arrow
              >
                <InfoIcon color="action" />
              </Tooltip>
            )}
          </Box>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700
            }}
            variant="h2"
          >
            <>
              {isMoney && "Rs"} {totalValue}
            </>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const CardStats = ({ data, hideKpi }: { data: KeyIndicators; hideKpi: boolean }): ReactElement => {
  const dispatch = useDispatch();

  const iconSize = { width: "24px", height: "24px" };
  const resourceCentre = useSelector(({ resources, userContext }: RootState) =>
    getRCFromState({ userContext, resources })
  );

  const navigateTo = (url: string) => dispatch(push(url));

  return (
    <>
      <Can policyAccessKey="dashboard:accessStatistics">
        <Grid container spacing={1.5}>
          {!hideKpi && (
            <>
              <Can policyAccessKey="dashboard:financialKpi">
                <Grid item xs={12} sm={6} md={6}>
                  <CardWrapper label="Financial">
                    <Box display="flex" gap={2}>
                      <StatCard
                        title={tl("card.sales")}
                        totalValue={roundTwoDecimalPoint(data?.totalSalesToday || 0)}
                        isMoney
                        icon={<ReceiptLongIcon style={iconSize} />}
                        onClick={() => navigateTo("/reports/financial/salesReport")}
                      />
                      <StatCard
                        title={tl("card.receipt")}
                        totalValue={roundTwoDecimalPoint(data?.totalReceiptToday || 0)}
                        isMoney
                        icon={<ReceiptIcon style={iconSize} />}
                        onClick={() => navigateTo("/reports/financial/receiptReport")}
                      />
                    </Box>
                  </CardWrapper>
                </Grid>
              </Can>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper label="Client" toolTipInfo="WalkIn Customers are counted as 1 client.">
                  <Box display="flex" gap={2}>
                    <StatCard
                      title={tl("card.newClients")}
                      totalValue={data?.customerCountToday || 0}
                      icon={<PersonIcon style={iconSize} />}
                      onClick={() => navigateTo("/clients?filter=visitedToday")}
                    />

                    <StatCard
                      title={tl("card.oldClients")}
                      totalValue={data?.followupCustomerCountToday || 0}
                      icon={<PersonIcon style={iconSize} />}
                      onClick={() => navigateTo("/clients?filter=visitedToday")}
                    />

                    <StatCard
                      title={tl("card.totalClients")}
                      totalValue={data?.totalCustomerCountToday || 0}
                      icon={<PeopleIcon style={iconSize} />}
                      onClick={() => navigateTo("/clients?filter=visitedToday")}
                    />
                  </Box>
                </CardWrapper>
              </Grid>
              <Can policyAccessKey="allow" superAdminPassReverse>
                <Grid item xs={12} sm={6} md={6}>
                  <CardWrapper label="SMS">
                    <Box display="flex" gap={2}>
                      <StatCard
                        title={tl("card.remainingSms")}
                        totalValue={data?.totalMessagesLeft || 0}
                        isMoney={false}
                        icon={<TextsmsIcon style={iconSize} />}
                        onClick={() =>
                          navigateTo(`/resourcecentres/${resourceCentre.id}/subscriptions?tab=sms`)
                        }
                      />
                      <StatCard
                        title={tl("dashboard.smsDelivered")}
                        totalValue={data?.smsCountToday || 0}
                        isMoney={false}
                        icon={<TextsmsIcon style={iconSize} />}
                        onClick={() =>
                          navigateTo(`/resourcecentres/${resourceCentre.id}/subscriptions?tab=sms`)
                        }
                      />
                    </Box>
                  </CardWrapper>
                </Grid>
              </Can>
              <Can policyAccessKey="allow" superAdminPassReverse>
                <Grid item xs={12} sm={6} md={6}>
                  <CardWrapper label="Lab">
                    <Box display="flex" gap={2}>
                      <StatCard
                        title={t("dashboard.createdTests")}
                        totalValue={data?.createdLabRecordsToday || 0}
                        isMoney={false}
                        icon={<ScienceIcon style={iconSize} />}
                        onClick={() =>
                          navigateTo(`/lab/labRecords/all?status=${LabStatuses.ORDERED}`)
                        }
                      />

                      <StatCard
                        title={t("dashboard.finalizedTests")}
                        totalValue={data?.finalizedLabRecordsToday || 0}
                        isMoney={false}
                        icon={<ScienceIcon style={iconSize} />}
                        onClick={() =>
                          navigateTo(`/lab/labRecords/all?status=${LabStatuses.RESULT_READY}`)
                        }
                      />
                    </Box>
                  </CardWrapper>
                </Grid>
              </Can>
            </>
          )}
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid item xs={12} sm={6} md={6}>
              <CardWrapper label="OPD">
                <Box display="flex" gap={2}>
                  <StatCard
                    title={t("dashboard.prescriptionCount")}
                    totalValue={data?.assessmentsToday || 0}
                    isMoney={false}
                    icon={<MedicationIcon style={iconSize} />}
                    onClick={() => navigateTo(`/assessment/assessments`)}
                  />
                  <StatCard
                    title={t("dashboard.followUp")}
                    totalValue={data?.followupAssessmentsToday || 0}
                    isMoney={false}
                    icon={<MedicationIcon style={iconSize} />}
                    onClick={() => navigateTo(`/assessment/assessments`)}
                  />
                </Box>
              </CardWrapper>
            </Grid>
          </Can>
          {!hideKpi && (
            <Can policyAccessKey="stock:listStock">
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper label="Stock">
                  <Box display="flex" gap={2}>
                    <StatCard
                      title={tl("card.stockValuation")}
                      totalValue={(data?.totalStockValuation || 0).toFixed(2)}
                      isMoney
                      icon={<PaidIcon style={iconSize} />}
                      onClick={() =>
                        navigateTo("/reports/stock/stockAuditReport?filter=totalStockValuation")
                      }
                    />
                    <StatCard
                      title={tl("card.expiringStocks")}
                      totalValue={(data?.expiringStockCount || 0).toFixed(2)}
                      icon={<MedicationIcon style={iconSize} />}
                      onClick={() =>
                        navigateTo("/reports/stock/stockAuditReport?filter=showExpiring")
                      }
                    />
                    <StatCard
                      title={t("card.minimalStocks")}
                      totalValue={(data?.minimalStockCount || 0).toFixed(2)}
                      icon={<AllInboxIcon style={iconSize} />}
                      onClick={() =>
                        navigateTo("/reports/stock/stockAuditReport?filter=showOnlyMinStocks")
                      }
                    />
                  </Box>
                </CardWrapper>
              </Grid>
            </Can>
          )}
        </Grid>
      </Can>
    </>
  );
};

export default CardStats;

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import startCase from "lodash/startCase";
import { tl } from "../../../components/translate";
import VitalsPopUp from "./VitalsPopUp";
import styles from "../Assessment.module.css";
import { vitals } from "./VitalRow";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

export interface AssessmentVitals {
  assessmentId: number;
  clientId: number;
  date: string;
  extraInfo: any;
  name: string;
  reading: number;
  unit: string;
}

function applyTypes<T extends AssessmentVitals>(assessmentVitals: T[]): T[] {
  return assessmentVitals?.map((item) => {
    const relatedType = vitals.find((vital) => vital.name === item.name)?.type || "text";
    return { ...item, type: relatedType };
  });
}

const Vitals = ({
  assessmentVitals,
  onChange
}: {
  assessmentVitals: AssessmentVitals[];
  onChange: (data) => void;
}): JSX.Element => {
  const aVitals = applyTypes(assessmentVitals);
  const [dialog, setDialog] = React.useState(false);
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow} height="auto" alignItems="flex-start">
      <Box className={styles.assessmentLabel} pt="4px">
        <Typography component="span" fontWeight="600">
          {assessmentLabels.vitals}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <Box>
          {aVitals?.map((v, index) => (
            <Box key={v.name} display="flex" justifyContent="space-between">
              <Typography>{`${startCase(v.name)} ${v.reading} ${v.unit} ${
                v.extraInfo ? `(${v.extraInfo})` : ""
              }`}</Typography>
              <CloseIcon
                data-testmation="vitals.RemoveButton"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const newVitals = assessmentVitals;
                  newVitals.splice(index, 1);
                  onChange(newVitals);
                }}
              />
            </Box>
          ))}
        </Box>

        <Box
          data-testmation="vitals.AddNewButton"
          onClick={() => setDialog(true)}
          style={{ cursor: "pointer" }}
        >
          <Button size="small">
            <u>{tl("assessment.addNew")}</u>
          </Button>
        </Box>
      </Box>
      <VitalsPopUp
        isOpen={dialog}
        onClose={() => setDialog(false)}
        aVitals={aVitals}
        onSave={onChange}
      />
    </Box>
  );
};

export default Vitals;

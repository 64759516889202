import React, { JSX } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IBookableResource } from "../../../interfaces/BookableResources";
import List from "../../../components/List";
import { tl } from "../../../components/translate";
import { getBookableResources } from "../../../actions/bookableResource";
import { getServicetags } from "../../../api/bookableResources";
import { IServiceTag } from "../../../interfaces/ServiceTagsInterface";
import ResourcePanel from "../../Calendar/ResourcePanel";
import Panel from "../../../components/Panel";
import { MODE } from "../../Assessment/AssessmentForm/Form";

const DetailRow = ({ label, value }: { label: React.ReactNode; value: string }) => (
  <>
    <Grid item xs={3}>
      <Typography fontWeight={700}>{label}</Typography>
    </Grid>
    <Grid item xs={9}>
      {value}
    </Grid>
  </>
);

const DetailsPanel = ({
  data,
  onClose,
  onEdit
}: {
  data: IBookableResource;
  onClose: () => void;
  onEdit: () => void;
}) => (
  <Panel
    title={data.name}
    onClose={onClose}
    editButton={
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>
    }
  >
    <Box p={2}>
      <Grid container>
        <DetailRow label={tl("Name")} value={data.name} />
        <DetailRow label={tl("ondemand.serviceTag")} value={data.name} />
        <DetailRow label={tl("description")} value={data.details?.description || ""} />
        <DetailRow label={tl("publicInfo")} value={data.details?.publicInfo || ""} />
      </Grid>
    </Box>
  </Panel>
);

const BookableResource = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [serviceTags, setServiceTags] = React.useState<IServiceTag[]>([]);
  const [selectedResource, setSelectedResource] = React.useState<null | IBookableResource>(null);
  const [mode, setMode] = React.useState<MODE | null>(null);

  const bookableResources = useAppSelector((state) =>
    state.bookableResources.collection.filter((item: IBookableResource) => item.active)
  );
  const serviceOptionsHash = {};

  serviceTags.forEach((option) => {
    serviceOptionsHash[option.id] = {
      name: option.tag || "",
      mappedName: option.mappedName
    };
  });

  React.useEffect(() => {
    if (!bookableResources.length) {
      dispatch(getBookableResources());
    }
    (async () => {
      const tags = await getServicetags();
      setServiceTags(tags);
    })();
  }, []);

  return (
    <Box overflow="auto hidden" height="100%">
      <Box height="100%">
        <List<IBookableResource>
          automation="serviceProviders"
          title={tl("booking.bookableResources")}
          createLabel={tl("create")}
          onCreateNew={() => {
            setMode(MODE.CREATE);
          }}
          testmationLabel="createBookableResource"
          rowHeight={50}
          isResponsive
          onRowClick={(row) => {
            if (row.id === selectedResource?.id) {
              setSelectedResource(null);
            } else {
              setSelectedResource(row);
            }
            setMode(null);
          }}
          columns={[
            {
              key: "name",
              label: tl("Name"),
              sortable: true,
              formatter: ({ name }) => <Typography>{name}</Typography>
            },
            {
              key: "serviceTags",
              label: tl("ondemand.serviceTag"),
              formatter: ({ serviceTagIds }) => (
                <Typography>
                  {(serviceTagIds || []).map(
                    (item, index) =>
                      `${serviceOptionsHash[item]?.name || ""}${
                        index === serviceTagIds.length - 1 ? "" : ", "
                      }`
                  )}
                </Typography>
              )
            },
            {
              key: "description",
              label: tl("description"),
              formatter: ({ details }) => <Typography>{details?.description || ""}</Typography>
            },
            {
              key: "publicInfo",
              label: tl("publicInfo"),
              formatter: ({ details }) => <Typography>{details?.publicInfo || ""}</Typography>
            }
          ]}
          data={bookableResources}
        />
        {selectedResource && (
          <DetailsPanel
            data={selectedResource}
            onClose={() => setSelectedResource(null)}
            onEdit={() => {
              setMode(MODE.EDIT);
            }}
          />
        )}
        {(mode === MODE.CREATE || mode === MODE.EDIT) && (
          <ResourcePanel
            handleClose={() => {
              setMode(null);
              setSelectedResource(null);
            }}
            title={selectedResource ? "Edit Resource" : "Create Resource"}
            data={selectedResource}
          />
        )}
      </Box>
    </Box>
  );
};

export default BookableResource;

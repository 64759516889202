import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import React from "react";
import { getClientBalance } from "../../api/bill";
import { BillType } from "../../interfaces/BillInterfaces";
import EVENT from "../../mixpanel-analytics/event";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import IndividualBillPrint from "./Print/Bill/IndividualBillPrint/IndividualBillPrint";
import IpdBillPrint from "./Print/Bill/ItemWisePrint/IpdBillPrint";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

interface Props {
  clientId: number;
  bills: BillType[];
}

export default function IpdBillPrintBtnGroup({ clientId, bills }: Props): JSX.Element {
  const rc = useCurrentResourceCentre();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [clientBalance, setClientBalance] = React.useState<number>(0);

  const dropDownButton = [
    {
      label: "Print Summary",
      url: "/accounts/paymentVoucher",
      component: (
        <IpdBillPrint
          bills={bills}
          buttonText="Print Summary"
          labelStyle={{
            fontSize: "0.95em",
            textTransform: "none"
          }}
          clientBalance={clientBalance}
          onPrintClick={() =>
            mixpanelAnalytics.track(EVENT.PRINT_BILL_DEFAULT, { rcId: rc.id, rcName: rc.name })
          }
        />
      )
    }
  ];

  React.useEffect(() => {
    (async () => {
      try {
        if (clientId) {
          const { balance } = await getClientBalance(clientId);
          setClientBalance(balance);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    })();
  }, [clientId]);
  return (
    <Box ml="10px">
      <ButtonGroup size="small" variant="contained">
        <Button size="small" onClick={() => ({})}>
          <IndividualBillPrint bills={bills} />
        </Button>
        <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        sx={{
          "& .MuiMenu-list": {
            padding: "2px 0"
          }
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {dropDownButton.map((item) => (
          <MenuItem onClick={() => ({})} key={item.label}>
            {item.component}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

import React from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import "./OpdRequestStatusChip.scss";
import classNames from "../../../helpers/classNames";

interface OpdRequestStatusChipInterface {
  status: string;
}

const OpdRequestStatusChip: React.FC<OpdRequestStatusChipInterface> = ({ status }) => {
  const modifiedStatus = status.replace(/ /g, "");
  const statusText = status;

  return (
    <Box component="span">
      <Chip
        className={classNames("labStatus", modifiedStatus)}
        size="medium"
        label={
          <Box
            style={{
              whiteSpace: "normal",
              margin: "auto",
              textAlign: "center"
            }}
          >
            {statusText}
          </Box>
        }
      />
    </Box>
  );
};

export default OpdRequestStatusChip;

import React from "react";
import { Box, Typography } from "@mui/material";

export default function VideoGrid({
  data
}: {
  data: { title: string; url: string }[];
}): JSX.Element {
  return (
    <div>
      <Box display="flex" gap={3} flexWrap="wrap">
        {data.map((video) => (
          <Box key={video.title} flexBasis="40%">
            <Typography mb={1}>{video.title}</Typography>
            <iframe
              src={video.url}
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay; encrypted-media; picture-in-picture;"
            />
          </Box>
        ))}
      </Box>
    </div>
  );
}

import { ReducerBuilder } from './ReducerBuilder';
import * as BookingActions from '../actions/booking';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import capitalize from 'lodash/capitalize';

const INITIAL_STATE = {
  collection: [],
  allBookingsMinimal: [],
  lastTouched: null
};

function setBookings(state, payload) {
  const newState = cloneDeep(state);
  newState.collection = payload;
  return newState;
}

function setBooking(state, payload) {
  const newCollection = state.collection.filter(({ id }) => payload.id !== id);
  newCollection.push(payload);
  return { ...state, collection: newCollection };
}

function setUpdateBooking(state, payload) {
  const previousBooking = state.collection.find(({ id }) => payload.id === id); //needed because not full object provided by payload
  const updatedBooking = { ...previousBooking, ...payload };
  const newCollection = state.collection.filter(({ id }) => payload.id !== id);
  newCollection.push(updatedBooking);
  return {
    ...state,
    collection: newCollection,
    lastTouched: payload
  };
}

function setDeleteBooking(state, payload) {
  const newCollection = state.collection.filter((item) => item.id !== payload); //payload is bookingId
  return { ...state, collection: newCollection };
}

function setBookingsMinimal(state, payload) {
  return { ...state, allBookingsMinimal: payload };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(BookingActions.Type.GET_BOOKING, setBooking)
  .mapAction(BookingActions.Type.GET_BOOKINGS, setBookings)
  .mapAction(BookingActions.Type.MODIFY_BOOKING, setUpdateBooking)
  .mapAction(BookingActions.Type.DELETE_BOOKING, setDeleteBooking)
  .mapAction(BookingActions.Type.GET_BOOKINGS_MINIMAL, setBookingsMinimal)
  .build();
export default reducer;

const bookingsSelector = (state) => state.bookings.collection;
const schedulesSelector = (state) => state.schedules.collection;

export const schdulesFromOkhatiEvents = (events) => {
  const schedules = [];
  events?.forEach((e) => {
    e.schedules.forEach((s) => {
      schedules.push({
        ...e,
        type: 'schedule',
        start: s.start,
        end: s.end
      });
    });
  });
  return schedules;
};

const bookingToCalendarEvent = (booking) => {
  return {
    ...booking,
    type: 'booking',
    name: `${capitalize(booking?.client?.firstName || '')} ${capitalize(
      booking?.client?.lastName || ''
    )}`.trim()
  };
};

export const eventsSelector = createSelector(
  [bookingsSelector, schedulesSelector],
  (bookings, schedules) => {
    return [...bookings.map(bookingToCalendarEvent), ...schdulesFromOkhatiEvents(schedules)];
  }
);

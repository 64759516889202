import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  onFileSelected: (file: File) => void;
  label?: string;
}

function AttachmentUploader({ onFileSelected, label }: Props): JSX.Element {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // ssf supports file size upto 5MB
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setError("Only PDF files are allowed.");
      } else if (selectedFile.size > maxSize) {
        setError("File size must be less than 5MB.");
      } else {
        setError("");
        setValue("");
        onFileSelected(selectedFile);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={2}>
        <Typography gutterBottom>{label || "Upload pdf file"}</Typography>
        <input type="file" accept="application/pdf" value={value} onChange={handleFileChange} />
      </Box>
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default AttachmentUploader;

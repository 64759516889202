import React, { useEffect } from "react";
import produce from "immer";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Checkbox, Typography, Grid, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch } from "react-redux";
import styles from "../style.module.css";
import { tl, t } from "../../../components/translate";
import { insertSsfServices, setResourceCentreSubscriptions } from "../../../api/resourceCentre";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { SettingsType } from "../Settings";
import { ResourceCentreActionsType } from "../../../actions";
import ContentEditableWithTextField from "../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import { notificationAdd } from "../../../actions/notification";
import { updateErrorMessage } from "../../../helpers/messages";

export const subscriptionsModal = {
  plan: { name: "free", validFrom: null, validUntil: null },
  features: {
    billing: {
      subscribed: false
    },
    sms: {
      subscribed: false,
      volumeLeft: 0
    },
    email: {
      subscribed: false
    },
    video: {
      subscribed: false
    },
    publicBooking: {
      subscribed: false
    },
    stock: {
      subscribed: false
    },
    campaign: {
      subscribed: false
    },
    ipd: {
      subscribed: false
    },
    referrer: {
      subscribed: false
    },
    medical: {
      subscribed: false
    },
    commission: {
      subscribed: false
    },
    hmis: {
      subscribed: false
    },
    accessingLab: {
      subscribed: false
    },
    salesSummary: {
      subscribed: false
    },
    tally: {
      subscribed: false
    },
    department: {
      subscribed: false
    },
    twoFactorAuthentication: {
      subscribed: false
    },
    ssf: {
      subscribed: false
    },
    daybook: {
      subscribed: false
    }
  }
};

interface Props {
  id: number;
  initialSubscriptions: typeof subscriptionsModal;
  state: SettingsType;
  setState: React.Dispatch<React.SetStateAction<SettingsType>>;
  actions: ResourceCentreActionsType;
}

export const AddonsSettings = ({
  id,
  initialSubscriptions,
  state,
  setState,
  actions
}: Props): JSX.Element => {
  const [subscriptions, setSubscriptions] = React.useState(subscriptionsModal);
  const dispatch = useDispatch();
  useEffect(() => {
    setSubscriptions((prevSubscriptions) => ({
      ...prevSubscriptions,
      plan: {
        ...prevSubscriptions.plan,
        ...initialSubscriptions.plan
      },
      features: {
        ...prevSubscriptions.features,
        ...initialSubscriptions.features
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box pl={2} width="100%" display="flex">
          <Box display="flex" marginTop="6px">
            <Typography>
              <Box width="10rem" fontSize="14px" fontWeight="600" flexBasis="100px">
                {t("resourceCentre.chooseOptions")}
              </Box>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" rowGap="8px">
            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.sms.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.sms.subscribed = !draft.features.sms.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res: ResourceCentre) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("resourceCentre.SMS")}
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.email.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.email.subscribed = !draft.features.email.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("resourceCentre.email")}
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.video.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.video.subscribed = !draft.features.video.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("resourceCentre.enableVideo")}
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.publicBooking.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.publicBooking.subscribed =
                      !draft.features.publicBooking.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              Public Booking
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.referrer?.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.referrer.subscribed = !draft.features?.referrer?.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("settings.referrer")}
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.commission?.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.commission.subscribed = !draft.features?.commission?.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("settings.commission")}
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.accessingLab?.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    if (!draft.features.accessingLab) {
                      draft.features.accessingLab = { subscribed: false };
                    }
                    draft.features.accessingLab.subscribed =
                      !draft.features?.accessingLab?.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("settings.accessingLab")}
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.salesSummary?.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.salesSummary.subscribed =
                      !draft.features?.salesSummary?.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("settings.salesSummary")}
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                className={styles.optPadding}
                size="small"
                checked={state.settings.clientSettings.enablePictureUpload}
                value={state.settings.clientSettings.enablePictureUpload}
                onChange={() => {
                  setState((prevState) => {
                    const newState = produce(prevState, (draft) => {
                      draft.settings.clientSettings.enablePictureUpload =
                        !draft.settings.clientSettings.enablePictureUpload;
                    });
                    actions.putResourceCentre(newState);
                    return newState;
                  });
                }}
              />
              Client Picture Upload
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.department?.subscribed}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.department.subscribed = !draft.features.department.subscribed;
                  });
                  setResourceCentreSubscriptions(mutatedState, id).then((res) =>
                    setSubscriptions(res.subscriptions)
                  );
                }}
              />
              {tl("settings.department")}
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features.twoFactorAuthentication.subscribed}
                onChange={async () => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    draft.features.twoFactorAuthentication.subscribed =
                      !draft.features.twoFactorAuthentication.subscribed;
                  });
                  try {
                    const subscription = await setResourceCentreSubscriptions(mutatedState, id);
                    setSubscriptions(subscription.subscriptions);
                  } catch (error) {
                    dispatch(
                      notificationAdd({
                        id: new Date().getTime(),
                        variant: "error",
                        message: error?.message || updateErrorMessage("Settings")
                      })
                    );
                  }
                }}
              />
              Two Factor Authentication
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features?.ssf?.subscribed || false}
                onChange={() => {
                  const mutatedState = produce(subscriptions, (draft) => {
                    // Initialize the ssf feature if it doesn't exist
                    if (!draft.features.ssf) {
                      draft.features.ssf = { subscribed: false };
                    }
                    draft.features.ssf.subscribed = !draft.features.ssf.subscribed;
                  });

                  setResourceCentreSubscriptions(mutatedState, id)
                    .then((res) => {
                      // Update the subscriptions state with the response from the backend
                      setSubscriptions(res.subscriptions);

                      if (res.subscriptions.features.ssf?.subscribed) {
                        // Add ssf services if subscribed
                        insertSsfServices(Number(id));
                      }
                    })
                    .catch((error) => {
                      dispatch(
                        notificationAdd({
                          id: new Date().getTime(),
                          variant: "error",
                          message: error?.message,
                          autoTimeout: true
                        })
                      );
                    });
                }}
              />
              {tl("resourceCentre.enableSsf")}
              <Tooltip
                sx={{ marginLeft: "5px" }}
                placement="top"
                title="SSF services are only added when enabling for first the time. Disableing does not delete them."
              >
                <InfoIcon color="action" />
              </Tooltip>
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                className={styles.optPadding}
                checked={subscriptions.features?.daybook?.subscribed}
                onChange={async () => {
                  try {
                    const mutatedState = produce(subscriptions, (draft) => {
                      draft.features.daybook.subscribed = !draft.features.daybook.subscribed;
                    });

                    const subscription = await setResourceCentreSubscriptions(mutatedState, id);
                    setSubscriptions(subscription.subscriptions);
                  } catch (error) {
                    dispatch(
                      notificationAdd({
                        id: new Date().getTime(),
                        variant: "error",
                        message: error?.message,
                        autoTimeout: true
                      })
                    );
                  }
                }}
              />
              {tl("resourceCentre.daybook")}
              <LockIcon color="action" fontSize="small" sx={{ marginLeft: "5px" }} />
            </Box>

            <Box display="flex" alignItems="center">
              <Checkbox
                className={styles.optPadding}
                size="small"
                checked={state.settings.assessmentSettings.components.attachments}
                onChange={() => {
                  const newState = produce(state, (draft) => {
                    draft.settings.assessmentSettings.components.attachments =
                      !draft.settings.assessmentSettings.components.attachments;
                  });
                  setState(newState);
                  actions.putResourceCentre(newState);
                }}
              />
              <Typography>Attachments</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box display="flex" width="10rem" alignItems="center">
                <Typography>
                  <Box component="span" fontSize="14px" fontWeight="600" flexBasis="100px">
                    {tl("resourceCentre.attachmentCount")}
                  </Box>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" pt="8px">
                <ContentEditableWithTextField
                  hidelabel
                  placeholder="Type attachment count.."
                  saveOnFocusOut
                  value={state.settings.assessmentSettings.attachmentCount ?? 3}
                  onSave={(val) => {
                    const newState = produce(state, (draft) => {
                      // Maximum Value Allowed: 15
                      draft.settings.assessmentSettings.attachmentCount =
                        Number(val) > 15 ? 15 : Number(val);
                    });
                    setState(newState);
                    actions.putResourceCentre(newState);
                  }}
                  width="300px"
                  type="number"
                  maxValue={15}
                  label="Attachment count"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { AutoSizer } from "react-virtualized";
import { useSelector } from "react-redux";
import moment from "moment";
import { tl, t } from "../../components/translate";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calendarFns from "../../components/Calendar/functions/calendarFunctions";
import { clientFullNameSelector } from "../../reducers/client";
import LabStatusChip from "./LabStatusChip";
import "./LabList.scss";
import classNames from "../../helpers/classNames";
import { tekuLabImportColumns } from "../../components/CsvUploader/csvHelpers";
import CsvUploader from "../../components/CsvUploader/CsvUploader";
import { createlabTestWithPCR } from "../../api/labTest";
import Can from "../Policy/Can";
import hasOwnProperty from "../../helpers/object";
import { RootState } from "../../store";
import { LabTestStatusTypes } from "./LabList";
import { LabObject } from "../../interfaces/LabInterfaces";
import { LabRecordType } from "./index";
import { getCustomerNumber } from "../Client/ClientList";

function downloadLabUploadSample() {
  window.open("https://docs.google.com/spreadsheets/d/1ugbK2sh8F74BXBFwBfQyu1MIL7z8vVkG", "_blank");
}
interface ListTabProps {
  labTestsFiltered: any;
  selectedItem: any;
  navigateTo: (url: string) => void;
  onMultiSelect: any;
  multiSelectContextHeader: any;
  handleGetLabsTestRecords: any;
  currentTab: string;
  showPageControl: boolean;
  labRecordType: LabRecordType;
}
export const getTurnAroundTime = (labObj: LabObject | Partial<LabObject>): string => {
  let diffInHours = 0;
  if ([LabTestStatusTypes.RESULT_READY].includes(labObj.status as LabTestStatusTypes)) {
    diffInHours = moment(labObj.resultDate).diff(
      labObj.labCreatedTime || labObj.created_at,
      "hours"
    );
  } else if ([LabTestStatusTypes.CANCELED].includes(labObj.status as LabTestStatusTypes)) {
    diffInHours = moment(labObj.updated_at).diff(
      labObj.labCreatedTime || labObj.created_at,
      "hours"
    );
  } else {
    diffInHours = moment(new Date()).diff(labObj.labCreatedTime || labObj.created_at, "hours");
  }

  if (diffInHours < 1) {
    return "Below 1 hr";
  }

  if (diffInHours > 24) {
    return `${Math.floor(diffInHours / 24)} day ${diffInHours % 24} hrs`;
  }
  return `${diffInHours} hrs`;
};

function ListTab({
  labTestsFiltered,
  selectedItem,
  navigateTo,
  onMultiSelect = null,
  multiSelectContextHeader = null,
  handleGetLabsTestRecords,
  currentTab,
  showPageControl,
  labRecordType
}: ListTabProps): JSX.Element {
  const currentRCId = useSelector((state: any) => state.userContext.resourceCentreId);
  const useCustomLabTestNumber = useSelector((state: RootState) =>
    Boolean(state.resources?.resourceCentres[0]?.labSettings?.useCustomLabTestNumber)
  );
  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        const height = showPageControl ? "calc(100vh - 168px)" : "calc(100vh - 128px)";
        return (
          <Box
            component="div"
            className={classNames("labRecordList", {
              withMultiSelect: Boolean(onMultiSelect)
            })}
            width={width - 1}
            height={height}
            overflow="auto hidden"
          >
            <Box height="100%" minWidth="800px">
              <List
                automation="LabList"
                data={labTestsFiltered}
                rowHeight={50}
                defaultSortColumn="createdAt"
                adjustHeightToContents
                isResponsive
                multiSelectable={Boolean(onMultiSelect)}
                onMultiSelect={onMultiSelect}
                multiSelectContextHeader={multiSelectContextHeader}
                columns={[
                  {
                    key: "testId",
                    label: tl("lab.testId"),
                    cellRenderer: ({
                      id,
                      accessingResourceCentre,
                      resourceCentre,
                      labTestNumber
                    }) => (
                      <Typography component="div">
                        {useCustomLabTestNumber ? labTestNumber || id : id}
                        {accessingResourceCentre && (
                          <Box>
                            {accessingResourceCentre.id !== currentRCId ? (
                              <Typography style={{ fontSize: "0.75rem", color: "grey" }}>
                                {accessingResourceCentre.name}
                              </Typography>
                            ) : (
                              <Typography style={{ fontSize: "0.75rem", color: "grey" }}>
                                {resourceCentre.name}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Typography>
                    )
                  },
                  {
                    key: "clientId",
                    label: tl("lab.client"),
                    cellRenderer: ({ client, customer }) => (
                      <Typography
                        component="div"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          whiteSpace: "pre"
                        }}
                        title={
                          customer?.externalIdentifier &&
                          `${t("labPrint.customerIdentifier")}: ${customer.externalIdentifier}`
                        }
                      >
                        {`${clientFullNameSelector(client)?.toUpperCase()} ${
                          !client?.active ? "(DELETED)" : ""
                        }`}
                        <Box fontSize="small" color="gray">
                          {getCustomerNumber(customer?.customerNumber)}
                          {customer?.externalIdentifier
                            ? `(${t("labPrint.customerIdentifier")}: ${
                                customer.externalIdentifier
                              })`
                            : ""}
                        </Box>
                      </Typography>
                    ),
                    sortable: false,
                    sortFunction: (a, b) =>
                      clientFullNameSelector(a.client) > clientFullNameSelector(b.client) ? 1 : -1
                  },
                  {
                    key: "tests",
                    label: tl("lab.tests"),
                    cellRenderer: ({ results, related }) => {
                      const allTests = [...results.data].filter(Boolean);
                      if (related?.length > 0) {
                        related.map((rl) => {
                          rl.results.data.map((resd) => allTests.push(resd));
                          return null;
                        });
                      }
                      const filtered = allTests.filter(
                        (v, i, a) => a.findIndex((item) => item.id === v.id) === i
                      );

                      if (!filtered.length) {
                        return null;
                      }

                      const mainLabReading =
                        filtered[0].labTests?.length > 0 &&
                        filtered[0].labTests[0]?.subTests?.length > 0 &&
                        hasOwnProperty(filtered[0].labTests[0], "formData") &&
                        filtered[0].labTests[0].formData.reading;

                      const readingColor = {
                        Positive: "#FF6347",
                        Negative: "#009654"
                      };
                      const testNamesString = filtered.map((lt, i) => {
                        if (i < 2) {
                          return `${i ? ", " : ""}${lt.name}`;
                        }
                        return null;
                      });

                      return (
                        <Box
                          title={testNamesString.join()}
                          sx={{
                            whiteSpace: "pre"
                          }}
                        >
                          <Typography>
                            {testNamesString}
                            {filtered.length > 2 ? "..." : ""}
                          </Typography>
                          {mainLabReading && (
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                color: readingColor[mainLabReading],
                                fontWeight: 600
                              }}
                            >
                              {mainLabReading}
                            </Typography>
                          )}
                        </Box>
                      );
                    }
                  },
                  {
                    key: "createdAt",
                    label: tl("lab.orderedOn"),
                    // eslint-disable-next-line camelcase
                    cellRenderer: ({ created_at }) => (
                      <Box display="flex">
                        <Typography>{calendarFns.bsFullDate(created_at)}</Typography>
                      </Box>
                    )
                  },
                  {
                    key: "collectionDate",
                    label: tl("lab.collected"),
                    cellRenderer: ({ collectionDate }) => (
                      <Box display="flex">
                        <Typography>
                          {collectionDate && calendarFns.bsFullDate(collectionDate)}
                        </Typography>
                      </Box>
                    )
                  },
                  {
                    key: "status",
                    label: tl("lab.status"),
                    cellRenderer: ({ status }) => (
                      <Box display="flex" alignItems="center">
                        <LabStatusChip status={status?.toLowerCase()} />
                      </Box>
                    ),
                    sortable: false,
                    sortFunction: (a, b) => (a > b ? 1 : -1)
                  },
                  {
                    key: "turnAroundTime",
                    label: tl("lab.turnAroundTime"),
                    cellRenderer: (data) => <Box>{getTurnAroundTime(data)}</Box>
                  }
                ]}
                activeRow={selectedItem && selectedItem.id}
                onRowClick={(item) => {
                  if (item.client.active) {
                    const isAlreadySelected = selectedItem && selectedItem.id === item.id;
                    if (isAlreadySelected) {
                      navigateTo(`/lab/labRecords/${labRecordType}?status=${currentTab}`);
                    } else {
                      navigateTo(
                        `/lab/labRecords/${labRecordType}/${item.id}?status=${currentTab}`
                      );
                    }
                  }
                }}
              >
                <EmptyView>
                  <Box textAlign="center" padding="50px">
                    No items to show.
                  </Box>
                </EmptyView>

                <ListActions>
                  {() => (
                    <>
                      <Menu>
                        <Can policyAccessKey="lab:importCSV">
                          <CsvUploader
                            buttonText="Upload Lab Records (.csv)"
                            columns={tekuLabImportColumns}
                            requiredFieldInfoText="Note: Identifier, Patients name,  Mobile number as well as PCR test result are mandatory information for importing."
                            createDataApi={createlabTestWithPCR}
                            runAfterSave={handleGetLabsTestRecords}
                            renderAdditionalInfo={() => (
                              <Typography
                                style={{
                                  cursor: "pointer",
                                  position: "fixed",
                                  right: 20
                                }}
                              >
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link onClick={downloadLabUploadSample}>
                                  {tl("lab.viewSample")}
                                </Link>
                              </Typography>
                            )}
                          />
                          <MenuItem onClick={downloadLabUploadSample}>
                            {tl("lab.viewSample")}
                          </MenuItem>
                        </Can>
                      </Menu>
                    </>
                  )}
                </ListActions>
              </List>
            </Box>
          </Box>
        );
      }}
    </AutoSizer>
  );
}

export default ListTab;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { HmisSummary } from "../../../interfaces/HmisInterface";
import { ethnicityMappedValue } from "../../../models/Client";
import { tl } from "../../../components/translate";

const header = ["genderAndSexWiseReport"];
const mainHeader = ["ethnicity", "newClientFromOpd"];
const subHeader = ["clients.gender.1", "clients.gender.2"];
const rows = ["ethnicity", "male", "female"];

const useStyles = makeStyles({
  style: {
    borderCollapse: "collapse",
    padding: "10px",
    border: ".5px solid gray"
  }
});

const mapEthnicityValues = (reportData) =>
  reportData.map((item) => ({ ...item, ethnicity: ethnicityMappedValue[item.ethnicity] }));

export default function EthnicityReportDetails({ data }: { data: HmisSummary }): JSX.Element {
  const hmisData = React.useMemo(() => mapEthnicityValues(data.displayData), [data.displayData]);
  const classes = useStyles();
  return (
    <div>
      {!hmisData?.length && <Typography>No Data Found</Typography>}
      {hmisData?.length > 0 && (
        <table className={classes.style}>
          <tr>
            {header.map((item) => (
              <th className={classes.style} key={item} colSpan={3}>
                {tl(`${item}`)}
              </th>
            ))}
          </tr>
          <tr>
            {mainHeader.map((item, index) => (
              <th
                className={classes.style}
                key={item}
                rowSpan={index === 0 ? 2 : 1}
                colSpan={index === 1 ? 2 : 1}
              >
                {tl(`${item}`)}
              </th>
            ))}
          </tr>
          <tr>
            {subHeader.map((item) => (
              <th className={classes.style} key={item}>
                {tl(`${item}`)}
              </th>
            ))}
          </tr>
          {hmisData.map((item) => (
            <tr className={classes.style} key={item.ageGroup}>
              {rows.map((cl) => (
                <td className={classes.style} key={cl}>
                  {item[cl]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      )}
    </div>
  );
}

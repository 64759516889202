import RemoveIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import useMobileScreen from "../../hooks/useMobileScreen";
import DebouncedTextField from "../DebouncedTextField";
import styles from "./Search.module.css";

const getSearchBoxWidth = (focused, isAlwaysFocus) => {
  if (isAlwaysFocus) {
    return "370px";
  }
  if (focused) {
    return "200px";
  }
  return "50px";
};

const Search: React.FC<{
  onSearch: (searchText) => void;
  testmation: string;
  customDebounce?: number | null;
  optionButton?: JSX.Element | null;
  alwaysFocus?: boolean;
  placeHolder?: string;
  value?: string;
}> = ({
  onSearch,
  testmation,
  customDebounce,
  optionButton,
  alwaysFocus = false,
  placeHolder,
  value
}) => {
  const [searchText, setSearch] = React.useState("");
  const [focused, setFocus] = React.useState(true);
  const inputRef = React.useRef(null);
  const ios =
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !(window as unknown as typeof Window & { MSStream: unknown }).MSStream;
  const isMobileScreen = useMobileScreen();

  // setting search text null if the search value is null and option button is changed.
  React.useEffect(() => {
    if (!value && optionButton) {
      setSearch("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box
      display="flex"
      className={`${styles.search} ${focused ? styles.searchInputFocused : ""}`}
      style={{ width: `${getSearchBoxWidth(focused, alwaysFocus)}` }}
    >
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <DebouncedTextField
        debounceAt={customDebounce ?? 200}
        data-testmation={`search${testmation}`}
        autoFocus={!(ios || isMobileScreen)}
        classes={{
          root: `${styles.inputRoot} ${searchText && styles.searchInputActive}`
        }}
        inputRef={inputRef}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          if (!searchText && !alwaysFocus) {
            setFocus(false);
          }
        }}
        value={searchText}
        onChange={(e) => {
          setSearch(e.target.value);
          onSearch(e.target.value);
        }}
        inputProps={{
          "aria-label": "Search",
          className: styles.inputInput
        }}
        placeholder={placeHolder || ""}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {searchText && (
                <IconButton
                  onClick={() => {
                    inputRef.current.focus();
                    setSearch("");
                    onSearch("");
                  }}
                  size="small"
                >
                  <RemoveIcon />
                </IconButton>
              )}
              {optionButton}
            </>
          )
        }}
      />
    </Box>
  );
};

Search.defaultProps = {
  customDebounce: null,
  optionButton: null,
  alwaysFocus: false,
  placeHolder: "",
  value: ""
};

export default Search;

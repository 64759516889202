import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Box, Typography } from "@mui/material";
import { getTestReportBaseUrl } from "../../../../helpers/urls";
import { t } from "../../../../components/translate";
import { getSectionStyle } from "../LabPrintFunctions";
import logo from "../../../../../assets/images/poweredByOkhati1.svg";
import QRWithLogo from "../QRWithLogo";

interface LabPrintFooterProps {
  unfixFooter?: boolean;
  uuid: string;
  headerColor: string;
  showFooter: boolean;
  hideFooterLogoCompletely: boolean;
  showQRCode?: boolean;
}

const LabPrintFooter: React.FC<LabPrintFooterProps> = ({
  unfixFooter = false,
  uuid,
  headerColor,
  showFooter,
  hideFooterLogoCompletely,
  showQRCode = false
}) => (
  <>
    {showFooter && (
      <Box component="div" width="100%" className={unfixFooter ? "" : "billFooterPos"}>
        <Box
          paddingTop="0.5rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ ...getSectionStyle(headerColor).footerStyle }}
        >
          <Box component="span">
            <Box display="flex" justifyContent="space-between">
              <QRCodeSVG size={60} value={`${getTestReportBaseUrl()}/${uuid}`} />
              <Box ml="8px">
                <Typography sx={{ maxWidth: "110px", fontSize: "0.8rem" }}>
                  {t("labPrint.qrUrlFooter")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box component="div">
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100px",
                height: "33px"
              }}
            />
          </Box>
        </Box>
      </Box>
    )}

    {!showFooter && (
      <Box width="100%" className={unfixFooter ? "" : "billFooterPos"}>
        {showQRCode ? (
          <QRWithLogo uuid={uuid} hideFooterCompletely={hideFooterLogoCompletely} />
        ) : (
          <Box display="flex" justifyContent="flex-end" mb={-1} paddingRight="10mm">
            {!hideFooterLogoCompletely && (
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "100px",
                  height: "33px"
                }}
              />
            )}
          </Box>
        )}
        <Box height="3.5cm" />
      </Box>
    )}
  </>
);

LabPrintFooter.defaultProps = {
  unfixFooter: false
};

export default LabPrintFooter;

import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/HighlightOff";
import OkIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch } from "react-redux";
import { createWards, updateWardData } from "../../../slices/bedManagementSlice";
import { notificationAdd } from "../../../actions/notification";
import { Bed, updateWard } from "../../../api/wards";

interface Ward {
  id: number;
  name: string;
  shortCode: string;
  resourceCentreId: number;
  isRoom: boolean;
}

export enum WardType {
  WARD = "Ward",
  CABIN = "Cabin"
}

export const defaultWards = [
  {
    name: "General",
    shortCode: "Gen",
    isRoom: false
  },
  {
    name: "Surgical",
    shortCode: "Surg",
    isRoom: false
  },
  {
    name: "Medical",
    shortCode: "Medi",
    isRoom: false
  },
  {
    name: "Pediatric",
    shortCode: "Pedi",
    isRoom: false
  },
  {
    name: "Ear Nose Throat",
    shortCode: "ENT"
  },
  {
    name: "Opthalmology",
    shortCode: "Opth",
    isRoom: false
  },
  {
    name: "Intensive Care Unit",
    shortCode: "ICU",
    isRoom: false
  },
  {
    name: "Post Operative ICU",
    shortCode: "POICU",
    isRoom: false
  },
  {
    name: "Post Operative",
    shortCode: "PO",
    isRoom: false
  },
  {
    name: "Cabin 1",
    shortCode: "CAB1",
    isRoom: true
  }
];

const CreateWard = ({
  wardList,
  bedList
}: {
  wardList: Array<Ward>;
  bedList: Array<Bed>;
}): JSX.Element => {
  const [wardData, setWardData] = React.useState({
    id: null,
    name: "",
    shortCode: "",
    numberOfBeds: 0
  });
  const [wardName, setWardName] = React.useState("");
  const [wardType, setWardType] = React.useState(WardType.WARD);
  const [editingWardInfo, setEditingWardInfo] = React.useState({
    wardId: null,
    wardName: "",
    wardCode: ""
  });
  const dispatch = useDispatch();

  const saveWard = () => {
    if (wardData.id && wardData.numberOfBeds === 0) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Please enter number of beds to create for the ward.",
          autoTimeout: false
        })
      );
      return;
    }

    const wardWithSameCode = wardList.find(
      (ward) => ward.shortCode.toLowerCase() === wardData.shortCode.trim().toLowerCase()
    );

    if (!wardData.id && wardWithSameCode) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Ward with same short code already exists.",
          autoTimeout: false
        })
      );
      return;
    }
    const payload = {
      id: wardData.id || null,
      name: wardData.name || wardName,
      shortCode: wardData.shortCode,
      isRoom: wardType === WardType.CABIN,
      numberOfBeds: wardData.numberOfBeds
    };
    dispatch(createWards(payload));
    setWardData({
      id: null,
      name: "",
      shortCode: "",
      numberOfBeds: 0
    });
    setWardName("");
    setWardType(WardType.WARD);
  };
  const filteredDefaultWards = defaultWards.filter(
    (dWard) => !wardList.find((ward) => ward.name === dWard.name)
  );
  const handleWardUpdate = async () => {
    try {
      if (editingWardInfo.wardId) {
        await updateWard({
          name: editingWardInfo.wardName,
          id: editingWardInfo.wardId
        });
        dispatch(
          updateWardData({
            id: editingWardInfo.wardId,
            name: editingWardInfo.wardName
          })
        );
        setEditingWardInfo({
          wardId: null,
          wardName: "",
          wardCode: ""
        });
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: "Ward updated successfully.",
            autoTimeout: false
          })
        );
      }
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to update ward.",
          autoTimeout: false
        })
      );
    }
  };
  return (
    <Grid container spacing={2} data-testmation="wardContainer">
      <Grid item xs={12}>
        <Typography fontWeight="600" fontSize="18px">
          Add Wards and Cabins
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Autocomplete
          options={Object.values(WardType)}
          value={wardType}
          onChange={(e, v) => {
            if (v) {
              setWardType(v as WardType);
            }
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              margin="dense"
              label="Choose Type"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={3}>
        <Autocomplete
          data-testmation="wardInput"
          freeSolo
          options={[...wardList, ...filteredDefaultWards]}
          getOptionLabel={(option) => option.name}
          value={wardData.name ? wardData : { name: wardName }}
          onChange={(e, v) => {
            if (!v || typeof v === "string") {
              setWardData({
                ...wardData,
                name: "",
                shortCode: "",
                numberOfBeds: 0
              });
              return;
            }
            setWardData({
              ...wardData,
              id: v.id || null,
              name: v.name,
              shortCode: v.shortCode
            });
            setWardType(v.isRoom ? WardType.CABIN : WardType.WARD);
          }}
          onInputChange={(_, value) => {
            setWardName(value);
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              margin="dense"
              label="Ward Name"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={3}>
        <TextField
          data-testmation="wardShortCodeInput"
          variant="outlined"
          margin="dense"
          label="Short Code"
          fullWidth
          value={wardData.shortCode}
          onChange={(e) => {
            setWardData({
              ...wardData,
              shortCode: e.target.value
            });
          }}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={3}>
        <TextField
          data-testmation="bedsInput"
          variant="outlined"
          margin="dense"
          label="Number of beds"
          fullWidth
          value={wardData.numberOfBeds}
          onChange={(e) => {
            setWardData({
              ...wardData,
              numberOfBeds: Number(e.target.value)
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          data-testmation="addWardButton"
          disabled={(!wardData.name && !wardName) || !wardData.shortCode}
          variant="contained"
          onClick={() => {
            saveWard();
          }}
        >
          {`Add ${wardData.id ? "Beds" : "Ward and Beds"}`}
        </Button>
      </Grid>

      {wardList.length > 0 && (
        <>
          <Grid item xs={3}>
            <Typography fontWeight="600" fontSize="16px">
              Type
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight="600" fontSize="16px">
              Ward Name
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight="600" fontSize="16px">
              Short Code
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight="600" fontSize="16px">
              Number of beds
            </Typography>
          </Grid>
          {wardList.map((ward) => {
            const bedCount = bedList.filter(({ wardId }) => wardId === ward.id).length;
            return (
              <>
                <Grid spacing={2} item container xs={12} key={ward.id}>
                  <Grid item xs={3}>
                    <Typography fontSize="16px">{ward.isRoom ? "Cabin" : "Ward"}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {editingWardInfo.wardId === ward.id ? (
                      <TextField
                        variant="outlined"
                        value={editingWardInfo.wardName}
                        onChange={(e) =>
                          setEditingWardInfo({
                            ...editingWardInfo,
                            wardName: e.target.value
                          })
                        }
                      />
                    ) : (
                      <Typography fontSize="16px">{ward.name}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontSize="16px">{ward.shortCode}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography fontSize="16px">{bedCount}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {editingWardInfo?.wardId === ward.id ? (
                      <Box>
                        <OkIcon
                          sx={{
                            cursor: "pointer"
                          }}
                          onClick={handleWardUpdate}
                        />
                        <CancelIcon
                          onClick={() =>
                            setEditingWardInfo({
                              wardId: null,
                              wardName: "",
                              wardCode: ""
                            })
                          }
                          sx={{ marginLeft: "10px", cursor: "pointer" }}
                        />
                      </Box>
                    ) : (
                      <EditIcon
                        sx={{ cursor: "pointer", fontSize: "16px", color: "rgba(0, 0, 0, 0.54)" }}
                        onClick={() => {
                          setEditingWardInfo({
                            wardId: ward.id,
                            wardName: ward.name,
                            wardCode: ward.shortCode
                          });
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default CreateWard;

import * as React from "react";
import { connect } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoneIcon from "@mui/icons-material/Done";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/material";
import { push } from "connected-react-router";
import { tl } from "../../../components/translate";
import styles from "./styles.module.css";
import { updateBooking } from "../../../actions/booking";

const options = {
  serviceProviderLimited: {
    reserved: ["createAssessment", "markHandled", "markReferred", "confirmBooking"],
    handled: ["createAssessment"],
    referred: ["createAssessment"],
    confirmed: ["createAssessment", "markHandled", "markReferred"]
  },
  serviceProvider: {
    reserved: [
      "createAssessment",
      "markHandled",
      "markHandledAndCreateBill",
      "markReferred",
      "confirmBooking"
    ],
    handled: ["createAssessment", "createBill"],
    referred: ["createAssessment", "createBill"],
    confirmed: ["createAssessment", "markHandled", "markHandledAndCreateBill", "markReferred"]
  },
  employee: {
    reserved: [
      "markHandled",
      "markHandledAndCreateBill",
      "markReferred",
      "createAssessment",
      "confirmBooking"
    ],
    handled: ["createBill", "createAssessment"],
    referred: ["createBill", "createAssessment"],
    confirmed: ["markHandled", "markHandledAndCreateBill", "markReferred", "createAssessment"]
  }
};

const getOptions = (userMode, booking) => options[userMode][booking.status] || [];

const formatRemarksLength = (newRemarks, remarks) => {
  let allRemarks = remarks.length ? `${remarks}\n${newRemarks}` : newRemarks;

  if (allRemarks.length > 1000) {
    allRemarks = allRemarks
      .slice(-1000)
      .split("\n")
      .slice(1)
      .reduce((t, c) => `${t}\n${c}`);
  }

  return allRemarks;
};

const BookingHandlingOptions = (props) => {
  const { booking, user, userMode, afterUpdate } = props;

  const [openMenu, setOpenMenu] = React.useState(false);
  const [openHandledRemarks, setOpenHandledRemarks] = React.useState(false);
  const [actionAfter, setActionAfter] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [remarks, setRemarks] = React.useState("");
  const anchorRef = React.useRef<HTMLDivElement>(null);
  /* eslint-disable no-underscore-dangle */
  const _options = React.useMemo(() => getOptions(userMode, booking), [userMode, booking]);

  if (userMode === "serviceProvider" && booking.serviceProviderId !== user.id) {
    return null;
  }

  const handleClick = (action) => {
    if (
      ["markHandled", "markHandledAndCreateBill", "markReferred", "confirmBooking"].includes(action)
    ) {
      setActionAfter(action);
      setOpenHandledRemarks(true);
    } else {
      /* eslint-disable react/destructuring-assignment */
      props[action](booking);
    }
  };
  const markHandled = (action) => {
    /* eslint-disable react/destructuring-assignment */
    props[action](booking, formatRemarksLength(remarks, booking.remarks), afterUpdate);
  };
  const handleMenuItemClick = (option: string, index: number) => {
    setSelectedIndex(index);
    handleClick(option);
    setOpenMenu(false);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };
  if (!_options.length) return null;
  return (
    <>
      <ButtonGroup
        size="small"
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          onClick={() => {
            handleClick(_options[0]);
          }}
        >
          {tl(_options[0])}
        </Button>
        {_options.length > 1 && (
          <Button
            size="small"
            color="primary"
            aria-owns={openMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        style={{ zIndex: 1 }}
        open={openMenu}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {_options.map((option, index) => {
                    if (index === 0) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(option, index, event)}
                        style={{ minWidth: "200px", maxWidth: "auto" }}
                      >
                        {tl(option)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Popper
        style={{ zIndex: 1 }}
        open={openHandledRemarks}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={() => setOpenHandledRemarks(false)}>
                <div className={styles.handleBookingRemarks}>
                  <TextField
                    id="outlined-dense-multiline"
                    label={tl("booking.remarks")}
                    margin="dense"
                    variant="outlined"
                    multiline
                    maxRows="2"
                    classes={{ root: styles.handledRemarksText }}
                    value={remarks}
                    onChange={({ target }) => setRemarks(target.value)}
                    autoFocus
                  />
                  <Box component="div" textAlign="right" marginTop="16px">
                    <Button
                      classes={{ root: styles.buttonCancel }}
                      onClick={() => setOpenHandledRemarks(false)}
                    >
                      {tl("booking.cancel")}
                    </Button>
                    <Button
                      classes={{ root: styles.buttonMarkHandled }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        markHandled(actionAfter);
                        setRemarks("");
                        setOpenHandledRemarks(false);
                      }}
                    >
                      {tl(actionAfter)}
                      <DoneIcon />
                    </Button>
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default connect(
  ({ userContext }) => ({
    user: userContext.user,
    userMode: userContext.userCreds.userGroups.includes("serviceProviderLimited")
      ? "serviceProviderLimited"
      : userContext.mode
  }),
  (dispatch) => ({
    markHandled: (booking, remarks, next) => {
      dispatch(updateBooking(booking.id, { status: "handled", remarks }, next));
    },
    markReferred: (booking, remarks, next) => {
      dispatch(updateBooking(booking.id, { status: "referred", remarks }, next));
    },
    createAssessment: (booking) => {
      dispatch(
        push(`/assessment/new?clientId=${booking.clientId}&spId=${booking.serviceProviderId}`)
      );
    },
    markHandledAndCreateBill: (booking, remarks, next) => {
      dispatch(updateBooking(booking.id, { status: "handled", remarks }, next));
      dispatch(push(`/billing/new?clientId=${booking.clientId}&bookingId=${booking.id}`));
    },
    createBill: (booking) => {
      dispatch(push(`/billing/new?clientId=${booking.clientId}&bookingId=${booking.id}`));
    },
    confirmBooking: (booking, remarks, next) => {
      dispatch(updateBooking(booking.id, { status: "confirmed", remarks }, next));
    }
  })
)(BookingHandlingOptions);

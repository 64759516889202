import * as React from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { TextField, Checkbox, Typography, Autocomplete } from "@mui/material";
import { push } from "connected-react-router";
import ResourcePanel from "./ResourcePanel";
import { getSpeciality } from "../../reducers/serviceTags";
import { RootState, IThunkDispatch } from "../../store";
import { UserGroups } from "../ResourceCentre/UserSettings/SPCreateEdit";
import BookableResourceSelectCreate from "./BookableResourceSelectCreate";
import { IBookableResource } from "../../interfaces/BookableResources";

// customize Tooltip

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: "2px",
    maxWidth: "250px"
  }
}))(Tooltip);

// checks if a checkbox can be deselected
export function checkAllowDeselect(
  checkFor: "resource" | "sp" | "public",
  spTickedState: boolean,
  resourceTickedState: boolean,
  publicTickedState: boolean
): boolean {
  if (checkFor === "resource") {
    return spTickedState || publicTickedState;
  }
  if (checkFor === "public") {
    return spTickedState || resourceTickedState;
  }
  return resourceTickedState || publicTickedState;
}

function getSpecialityFromOption(option, serviceTags) {
  let { speciality } = option;
  const userGroup = option.user.userGroups[0]?.name;
  if (userGroup === UserGroups.ServiceProviderFull) {
    if (option.specialities) {
      speciality = getSpeciality(serviceTags, option.specialities[0]);
    } else {
      speciality = "";
    }
  }
  return speciality || "";
}

const SidePanel = ({
  serviceProviderId,
  onServiceProviderSelect,
  bookableResources,
  onBookableResourceSelect,
  selectedBookableResource,
  applyServiceProviderOwner,
  changeApplyServiceProviderOwner,
  serviceTags,
  policies,
  onPublicEventToggle,
  publicEventChecked,
  serviceProviderList
}) => {
  const [resourcePanel, setResourcePanel] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [currentBookableResource, setCurrentBookableResource] =
    React.useState<IBookableResource | null>(null);

  return (
    <>
      <Box pr={1}>
        {serviceProviderList?.length > 0 && (
          <Box display="flex" justifyContent="space-between">
            <Checkbox
              checked={applyServiceProviderOwner}
              data-testmation="serviceProviderCheckbox"
              onChange={(e) => {
                if (e.target.checked) {
                  changeApplyServiceProviderOwner(e.target.checked);
                  sessionStorage.setItem("spApplied", "true");
                } else {
                  if (
                    checkAllowDeselect(
                      "sp",
                      applyServiceProviderOwner,
                      selectedBookableResource,
                      publicEventChecked
                    )
                  ) {
                    changeApplyServiceProviderOwner(null);
                  }
                  sessionStorage.removeItem("spApplied");
                }
              }}
            />
            <Autocomplete
              options={serviceProviderList}
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props} key={option.id}>
                  <Typography
                    component="div"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      textTransform: "capitalize"
                    }}
                  >
                    {option.title} {option.firstName.toUpperCase()} {option.lastName.toUpperCase()}
                    <Box fontSize="small" color="gray" component="span">
                      {option.speciality || ""}
                    </Box>
                  </Typography>
                </li>
              )}
              getOptionLabel={(option: unknown) =>
                `${
                  option.title
                    ? `${option.title.charAt(0).toUpperCase() + option.title.slice(1)} `
                    : ""
                }${option.firstName.toLocaleUpperCase()} ${option.lastName.toLocaleUpperCase()} ${getSpecialityFromOption(
                  option,
                  serviceTags
                )}`
              }
              fullWidth
              disabled={!policies["booking:viewOtherSpCalendar"]}
              value={serviceProviderList.find((sp) => sp.id === serviceProviderId) || null}
              onChange={(e, v) => {
                if (v) {
                  onServiceProviderSelect(v.id);
                }
              }}
              disableClearable
              renderInput={(params) => (
                <>
                  <LightTooltip title={(params.inputProps as { value: string }).value}>
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Service Provider"
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true
                      }}
                      data-testmation="sidePanelServiceProviderSelect"
                    />
                  </LightTooltip>
                </>
              )}
            />
          </Box>
        )}

        <Box>
          <Checkbox
            checked={publicEventChecked}
            onChange={() => {
              if (
                checkAllowDeselect(
                  "public",
                  applyServiceProviderOwner,
                  selectedBookableResource,
                  publicEventChecked
                )
              ) {
                onPublicEventToggle();
              }
            }}
          />
          Public Schedule
        </Box>

        <Box display="flex">
          <Checkbox
            checked={!!selectedBookableResource}
            onChange={() => {
              if (!selectedBookableResource) {
                onBookableResourceSelect(currentBookableResource);
              } else if (
                checkAllowDeselect(
                  "resource",
                  applyServiceProviderOwner,
                  selectedBookableResource,
                  publicEventChecked
                )
              ) {
                onBookableResourceSelect(null);
              }
            }}
          />
          <BookableResourceSelectCreate
            value={currentBookableResource}
            bookableResources={bookableResources}
            onChange={(newValue) => {
              if (newValue) {
                onBookableResourceSelect(newValue);
                setCurrentBookableResource(newValue);
              }
              setSelectedItem(null);
              setResourcePanel(false);
            }}
          />
        </Box>
      </Box>
      {resourcePanel && (
        <ResourcePanel
          handleClose={() => {
            setResourcePanel(false);
            setSelectedItem(null);
          }}
          title={selectedItem ? "Edit Resource" : "Create Resource"}
          data={selectedItem}
        />
      )}
    </>
  );
};

export default connect(
  (state: RootState) => ({
    resourceCentreId: state.userContext.resourceCentreId,
    user: { ...state.userContext.user, role: state.userContext.mode },
    bookableResources: state.bookableResources.collection.filter((item) => item.active),
    serviceTags: state.serviceTags.collection,
    policies: state.userContext.policies || {}
  }),
  (dispatch: IThunkDispatch) => ({
    navigateTo: (url) => dispatch(push(url))
  })
)(SidePanel);

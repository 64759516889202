/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Typography
} from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../components/Panel";
import MessageThread from "./MessageThread";
import { RootState } from "../../store";
import { updateSelectedChat } from "../../slices/chatSlice";
import { ReadableChats } from "../../hooks/useGetFirebaseChat";
import Chat from "../../Svgicons/Chat";
import EmptyChat from "./EmptyChat";
import { PermissionGroups } from "../../interfaces/User";

const MessageTitle = () => (
  <Box display="flex" pb={1}>
    <Typography fontSize={18} fontWeight={600}>
      Digi Health
    </Typography>
  </Box>
);

const DigiHealthPromo = () => (
  <Box
    display="flex"
    height="calc(100% - 100px)"
    sx={{ justifyContent: "center", alignItems: "center", paddingX: 2 }}
  >
    <Box
      width="100%"
      sx={{
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box display="flex" flex={1} justifyContent="flex-start" alignItems="center" mb={2} pl={3}>
        <Chat height="60" width="60" />
        <Typography variant="h3" pl={1}>
          Digi Health is Here!
        </Typography>
      </Box>
      <Box px={3}>
        <Typography variant="h5">
          <strong style={{ color: "#009654" }}>Digi Health</strong> will let you communicate with
          your patients and clients directly. To enable this feature for your clinic, contact Okhati
          sales.
        </Typography>
      </Box>
      <Box px={3} mt={2}>
        <Typography variant="h6">Okhati Sales,</Typography>
        <Typography variant="h6">9851184810</Typography>
      </Box>
    </Box>
  </Box>
);

enum MessageTab {
  Messages = "1",
  ClosedMessages = "2"
}

const MessagePanel = ({
  onClose,
  formattedChats
}: {
  onClose: () => void;
  formattedChats: ReadableChats;
}): JSX.Element => {
  const dispatch = useDispatch();
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentre?.id);
  const chats = useSelector((state: RootState) => state.chats.chatLists);
  const chatProfiles = useSelector((state: RootState) => state.chats.chatProfiles);
  const selectedChat = useSelector((state: RootState) => state.chats.selectedChat);
  const [showMessageThread, setShowMessageThread] = React.useState(false);

  const [currentTab, setCurrentTab] = React.useState("1");
  const isRcPublic = useSelector(
    (state: RootState) => state.userContext.resourceCentre?.publicBooking
  );
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext.userCreds?.userGroups[0]
  );
  const isSuperAdmin = [PermissionGroups.SuperAdmin, PermissionGroups.PublicBookingAdmin].includes(
    permissionGroup
  );

  React.useEffect(() => {
    if (Object.keys(selectedChat || {})?.length) {
      const updatedSelectedChat = formattedChats[selectedChat.userId];
      dispatch(updateSelectedChat(updatedSelectedChat));
    }
  }, [formattedChats]);

  if (!isRcPublic && !isSuperAdmin) {
    return (
      <Panel onClose={onClose} title={<MessageTitle />}>
        <DigiHealthPromo />
      </Panel>
    );
  }
  return (
    <>
      <Panel onClose={onClose} title={<MessageTitle />}>
        <Box display="flex" ml={2}>
          <TabContext value={currentTab}>
            <TabList onChange={(_, v) => setCurrentTab(v)}>
              {Object.keys(MessageTab).map((key) => (
                <Tab label={startCase(key)} value={MessageTab[key]} key={key} />
              ))}
            </TabList>
          </TabContext>
        </Box>
        {chats.length > 0 ? (
          <div>
            <List sx={{ bgcolor: "background.paper" }}>
              {chats
                .filter((chat) => {
                  if (currentTab === MessageTab.ClosedMessages) {
                    return chat.chatClosedBy;
                  }
                  return !chat.chatClosedBy;
                })
                .map((chat) => {
                  const lastMessage = formattedChats[chat.userId]?.lastMessage;
                  const isLastMessageNotRead =
                    !lastMessage?.read && lastMessage?.from !== resourceCentreId;

                  return (
                    <Box key={chat.chatKey}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowMessageThread(!showMessageThread);
                          dispatch(updateSelectedChat(formattedChats[chat.userId] || {}));
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={`${chatProfiles[chat.userId]?.name || "Anonymous User"}`}
                            src="./broken-image.png"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography fontSize="15px" fontWeight="bold">
                              {chatProfiles[chat.userId]?.name || "Unknown User"}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Box display="flex">
                                {lastMessage?.from === resourceCentreId ? (
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                    mr={1}
                                    fontWeight={isLastMessageNotRead ? "bold" : "normal"}
                                  >
                                    You:
                                  </Typography>
                                ) : (
                                  <></>
                                )}

                                <Typography
                                  variant="body1"
                                  noWrap
                                  color="text.primary"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  paddingRight={1}
                                  fontWeight={isLastMessageNotRead ? "bold" : "normal"}
                                  flex={1}
                                >
                                  {lastMessage?.message || ""}
                                </Typography>
                                {isLastMessageNotRead && (
                                  <Box
                                    height="10px"
                                    width="10px"
                                    borderRadius="5px"
                                    sx={{ backgroundColor: "#009654", marginRight: 1 }}
                                  />
                                )}
                              </Box>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </Box>
                  );
                })}
            </List>
          </div>
        ) : (
          <EmptyChat />
        )}
      </Panel>
      {showMessageThread && selectedChat && (
        <MessageThread
          onClose={() => setShowMessageThread(false)}
          selectedChat={selectedChat}
          profile={chatProfiles[selectedChat.userId]}
          resourceCentreId={resourceCentreId}
        />
      )}
    </>
  );
};

export default MessagePanel;

import React from "react";
import produce from "immer";
import { Box, Checkbox, Typography, Grid } from "@mui/material";
import { tl, t } from "../../../components/translate";
import styles from "../style.module.css";
import { SettingsType } from "./index";
import HtmlTooltip from "../../../components/HtmlTooltip/index";
import { ResourceCentreActionsType } from "../../../actions";

interface Props {
  rcSettings: SettingsType;
  setRcSettings: React.Dispatch<React.SetStateAction<SettingsType>>;
  actions: ResourceCentreActionsType;
}

const SmsSettings = ({ rcSettings, setRcSettings, actions }: Props): JSX.Element => (
  <Grid container>
    <Grid item xs={12}>
      <Box pl={2} width="100%" display="flex" flexWrap="wrap" gap={4}>
        <Typography fontSize="14px" fontWeight="600" flexBasis="100px">
          {tl("resourceCentre.sendSMSFor")}
        </Typography>
        <Box display="flex" flexDirection="column" rowGap="8px">
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              // disabled={!isSubscribed}
              checked={rcSettings.settings.smsSettings.creation}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.creation = !draft.settings.smsSettings.creation;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingCreation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.confirmation}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.confirmation =
                      !draft.settings.smsSettings.confirmation;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingConfirmation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.cancellation}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.cancellation =
                      !draft.settings.smsSettings.cancellation;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingCancellation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              className={styles.optPadding}
              size="small"
              checked={rcSettings.settings.smsSettings.assessment}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.assessment = !draft.settings.smsSettings.assessment;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.assessment")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.serviceProviders}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.serviceProviders =
                      !draft.settings.smsSettings.serviceProviders;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.serviceProviderBookingCreationAndDataChange")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.bookingConfirmationSP}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.bookingConfirmationSP =
                      !draft.settings.smsSettings.bookingConfirmationSP;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.serviceProviderBookingConfirmation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.labTests}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.labTests = !draft.settings.smsSettings.labTests;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.labTestUpdates")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.labTestsDispatched}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.labTestsDispatched =
                      !draft.settings.smsSettings.labTestsDispatched;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.labTestDispatched")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.campaign}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.smsSettings.campaign = !draft.settings.smsSettings.campaign;
                });
                actions.putResourceCentre(newState);
              }}
            />
            {tl("resourceCentre.campaign")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.referrer}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.smsSettings.referrer = !draft.settings.smsSettings.referrer;
                });
                actions.putResourceCentre(newState);
              }}
            />
            {tl("resourceCenter.referrer")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              className={styles.optPadding}
              size="small"
              checked={rcSettings.settings.smsSettings.remindClient}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.remindClient =
                      !draft.settings.smsSettings.remindClient;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingReminderToClient")}
            <HtmlTooltip description={tl("resourceCentreSms.bookingReminder")} />
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              className={styles.optPadding}
              size="small"
              checked={rcSettings.settings.smsSettings.nextDaySummarySP}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.smsSettings.nextDaySummarySP =
                      !draft.settings.smsSettings.nextDaySummarySP;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingSummaryToSP")}
            <HtmlTooltip description={t("resourceCentreSms.bookingSummary")} />
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.salesSummary}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.smsSettings.salesSummary =
                    !draft.settings.smsSettings.salesSummary;
                });
                actions.putResourceCentre(newState);
              }}
            />
            {tl("resourceCenter.salesSummary")}
            <HtmlTooltip description={tl("resourceCenterSms.salesSummary")} />
          </Box>

          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.publicBooking}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.smsSettings.publicBooking =
                    !draft.settings.smsSettings.publicBooking;
                });
                actions.putResourceCentre(newState);
              }}
            />
            Send For Public Booking
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.smsSettings.billReminder}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.smsSettings.billReminder =
                    !draft.settings.smsSettings.billReminder;
                });
                actions.putResourceCentre(newState);
              }}
            />
            Send For Bill Reminders
          </Box>
        </Box>
      </Box>
    </Grid>
  </Grid>
);

export default SmsSettings;

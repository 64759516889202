import React from "react";

const useDebounce = (
  deps: { delay?: number } = {},
  callback = () => {
    // do nothing
  }
): void => {
  const debouncedFunction = React.useRef<NodeJS.Timeout | null>(null);
  React.useEffect(() => {
    if ((typeof deps !== "object" && deps !== null) || typeof callback !== "function") {
      throw new Error("Invalid parameter.");
    }

    if (deps.delay === undefined || deps.delay === 0) {
      callback();
      return {};
    }

    debouncedFunction.current = setTimeout(callback, deps.delay);

    return () => debouncedFunction.current && clearTimeout(debouncedFunction.current);
  }, [deps, callback]);
};

export default useDebounce;

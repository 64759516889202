import React from "react";
import { Box, Modal } from "@mui/material";
import BookingInfo from "../Calendar/Booking/BookingInfo";
import { Booking } from "../../interfaces/BookingInterfaces";

export default function PatientFlowDetailView({
  data,
  onClose
}: {
  data: Booking;
  onClose: () => void;
}): JSX.Element | null {
  if (!data) return null;

  return (
    <div>
      <Modal open onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "10px"
          }}
        >
          <BookingInfo booking={data} onClose={onClose} onEdit={() => ({})} disableEdit />
        </Box>
      </Modal>
    </div>
  );
}

import * as React from "react";
import { connect } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import { navigateRemoveModal } from "../../../actions/navigation";
import { supplierActions } from "../../../actions";
import { tl } from "../../../components/translate";
import * as NotificationActions from "../../../actions/notification";
import { removeGeneralErrorByKey } from "../../../actions/error";
import { Supplier, SupplierInfo } from "../../../interfaces/StockInterfaces";
import SupplierCreationForm from "../../ResourceCentre/CreateSupplierForm/SupplierForm";

interface PropsType {
  next: (supplier: SupplierInfo) => void;
  supplierDefaultData: Supplier;
  actions: {
    onClose: () => void;
    onSave: (data, cb: (supplier: SupplierInfo) => void) => Promise<void>;
  };
}

const SupplierCreateModal: React.FC<PropsType> = ({ supplierDefaultData, actions, next }) => (
  <Modal open title={tl("employee.createSupplier")}>
    <SupplierCreationForm
      createMode
      onCancel={actions.onClose}
      data={supplierDefaultData || {}}
      onSave={(data) => {
        actions.onSave(data, (supplier) => {
          if (next) {
            next(supplier);
          }
        });
        actions.onClose();
      }}
    />
  </Modal>
);

export default connect(null, (dispatch) => ({
  actions: {
    onClose: () => {
      dispatch(navigateRemoveModal("SupplierCreateModal"));
    },
    onSave: async (data, cb) => {
      try {
        await dispatch(supplierActions.postSupplier(data, cb));
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: tl("successSupplier"),
            autoTimeout: true
          })
        );
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: tl("phoneNumberAlreadyExists"),
            autoTimeout: true
          })
        );
        dispatch(removeGeneralErrorByKey("POST_SUPPLIER"));
      }
    }
  }
}))(SupplierCreateModal);

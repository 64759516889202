import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import * as VitalActions from '../../../actions/vitals';
import startCase from 'lodash/startCase';

const ClientVitals = ({ clientId, getVitalsById, vitals }) => {
  React.useEffect(() => {
    clientId && getVitalsById(clientId);
  }, [clientId]);
  return (
    <Box m={3}>
      {vitals?.map((vital, index) => (
        <Typography>{`${index + 1}. ${startCase(vital.name)} ${vital.reading} ${vital.unit} ${
          vital.extraInfo ? `(${vital.extraInfo})` : ''
        }`}</Typography>
      ))}
    </Box>
  );
};

export default connect(
  (state) => {
    return { vitals: state.vitals.collection };
  },
  (dispatch) => ({
    getVitalsById: (id: number) => dispatch(VitalActions.getVitalsById(id)),
  }),
)(ClientVitals);

import { Box, Link, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import usePrintShortcut from "../../../../../hooks/usePrintShortcut";
import usePrintStyles from "../../../../../hooks/usePrintStyles";
import { BillType } from "../../../../../interfaces/BillInterfaces";
import { RootState } from "../../../../../store";
import { getSectionStyle } from "../../../../Lab/LabPrint/LabPrintFunctions";
import LabPrintLetterHeadCentered from "../../../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import BillDescription from "./BillDescription";

interface Props {
  bills: BillType[];
}

const IndividualBillPrint = ({ bills }: Props): JSX.Element | null => {
  const printRef = React.useRef();
  const reactToPrintRef = React.useRef(null);
  const { resourceCentres } = useSelector((state: RootState) => state.resources);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const resourceCentre = resourceCentres.find((rc) => rc.id === rcId);
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const customSubtitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;

  const hideRcLogo = Boolean(customTitle);

  const getCustomPrintStyle = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePrintStyles({
      pageStyle: "size: A4; margin: 0mm"
    }).applyPrintStyles();
  };
  usePrintShortcut(reactToPrintRef, getCustomPrintStyle);

  if (!bills.length) return null;

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;

  return (
    <>
      <ReactToPrint
        onAfterPrint={() => window.focus()}
        trigger={() => (
          <div>
            <Tooltip arrow title="Alt + P / Ctrl + P">
              <Link
                component="button"
                onMouseEnter={() => {
                  getCustomPrintStyle();
                }}
                onClick={() => ({})}
                style={{ textDecoration: "none", padding: "5px 15px", color: "white" }}
                ref={reactToPrintRef}
              >
                <Typography component="span">Print Details</Typography>
              </Link>
            </Tooltip>
          </div>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents" height="99%">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>
            <thead>
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      <LabPrintLetterHeadCentered
                        resourceCentre={{
                          ...resourceCentre,
                          panNo,
                          subtitle: customSubtitle
                        }}
                        titleFontScale={clinicNameFontScale}
                        hideRcLogo={hideRcLogo}
                      />
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    {bills.map((bill) => (
                      <Box key={bill.id} mt={0.5} sx={{ pageBreakInside: "avoid" }}>
                        <BillDescription bill={bill} headerColor={headerColor} />
                      </Box>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </Box>
        </div>
      </iframe>
    </>
  );
};

export default IndividualBillPrint;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDepartmentServiceProviders } from "../api/departments";
import { notificationAdd } from "../actions/notification";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";

export const getDepartmentSps = createAsyncThunk(
  "departmentSp",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getDepartmentServiceProviders();
      return response as Array<ServiceProvider>;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to fetch service providers",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

const departmentServiceProvidersSlice = createSlice({
  name: "departmentServiceProviders",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepartmentSps.fulfilled, (_, { payload }) => payload);
  }
});

export default departmentServiceProvidersSlice.reducer;

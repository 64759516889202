import { Autocomplete, Box, Chip, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import { getPublicLabs } from "../../api/publicBooking";
import { useResourceCentreLocations } from "../../hooks/usePublicLabs";
import {
  FilterProps,
  PublicLabDetailsProps,
  PublicLabInterface,
  PublicService,
  ResourceCentreLocation
} from "../../interfaces/PublicLabInterface";
import { getPublicServices } from "../../slices/publicLab";
import { RootState } from "../../store";
import PublicLabDetails from "./Public/PublicLabDetails";

const formatData = (
  publicServices: PublicService[],
  selectedLab: PublicLabInterface | null
): PublicLabDetailsProps => ({
  publicServices,
  labDetails: selectedLab?.publicDescription || ""
});

const getBackgroundColor = (option: ResourceCentreLocation): string => {
  if (option?.name) {
    return "#0288D1";
  }
  return "#ED6C02";
};

const DEFAULT_LOCATION = "Kathmandu";

export default function CreateBooking(): JSX.Element {
  const dispatch = useDispatch();
  const publicServices = useSelector((state: RootState) => state.public.publicServices);
  const resourceCentreLocations = useResourceCentreLocations();
  const [selectedRcLocation, setSelectedRcLocation] = React.useState<ResourceCentreLocation | null>(
    null
  );

  const [filters, setFilters] = React.useState<FilterProps>({
    referenceId: null,
    rcIds: [],
    page: 0,
    size: 10
  });

  const [selectedLab, setSelectedLab] = React.useState<PublicLabInterface | null>(null);

  const [publicLabs, setPublicLabs] = React.useState<PublicLabInterface[]>([]);

  const publicLabDetail = React.useMemo(
    () => formatData(publicServices, selectedLab),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publicServices]
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchPublicLabs = async (rcIds: number[] = []) => {
    setLoading(true);
    try {
      const res = await getPublicLabs({ ...filters, rcIds });
      setPublicLabs((res || []) as PublicLabInterface[]);
      setLoading(false);
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry! Something went wrong while fetching Public Labs.",
          autoTimeout: true
        })
      );
    }
  };

  React.useEffect(() => {
    (() => {
      const defaultSelectedLocation = resourceCentreLocations?.find(
        (location) => location.city === DEFAULT_LOCATION && !location.name
      );
      if (defaultSelectedLocation) {
        setSelectedRcLocation(defaultSelectedLocation);
        setFilters({ ...filters, rcIds: defaultSelectedLocation.rcIds });
        fetchPublicLabs(defaultSelectedLocation.rcIds);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCentreLocations]);

  React.useEffect(() => {
    if (filters.rcIds?.length) {
      dispatch(getPublicServices({ filters, dispatch }));
    }
  }, [filters, dispatch, selectedLab]);

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            value={selectedLab}
            onChange={(_, value) => {
              setSelectedLab(value);
              setFilters({ ...filters, referenceId: value ? value.id : null });
            }}
            options={publicLabs}
            getOptionLabel={(option) => option.name}
            renderOption={(renderProps, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...renderProps} key={option.name}>
                <Typography>{option.name || ""}</Typography>
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                data-testmation="labTestSearchInput"
                variant="outlined"
                //   eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                label="Lab Package"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            value={selectedRcLocation}
            onChange={(_, value) => {
              if (value) {
                setSelectedRcLocation(value);
                setFilters({ ...filters, rcIds: value?.rcIds || [] });
                fetchPublicLabs(value?.rcIds);
              }
            }}
            options={resourceCentreLocations}
            getOptionLabel={(option) => {
              if (option.name) {
                return option.name;
              }
              return option.city;
            }}
            renderOption={(renderProps, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...renderProps} key={`${option.name}${option.city}`}>
                <Box gap={2} display="flex">
                  <Chip
                    sx={{ backgroundColor: getBackgroundColor(option) }}
                    size="small"
                    label={`${option.name ? "Lab" : "City"}`}
                  />
                  <Typography>{option.name || option.city}</Typography>
                </Box>
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                data-testmation="labTestLocationInput"
                variant="outlined"
                //   eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                label="City or Lab Name"
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          justifyContent: "center",
          height: "75vh",
          overflow: "auto",
          scrollbarWidth: "thin",
          marginTop: "20px",
          "&::-webkit-scrollbar": {
            width: "0.4em"
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "10px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#d7d7d7",
            borderRadius: "10px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#d7d7d7"
          }
        }}
      >
        <PublicLabDetails
          filters={filters}
          loading={loading}
          data={publicLabDetail}
          selectedLab={selectedLab}
          selectedRcLocation={selectedRcLocation}
        />
      </Box>
    </Box>
  );
}

import { startCase } from "lodash";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";
import { spFullNameSelector } from "../reducers/serviceProvider";
import { Employee } from "../interfaces/User";
import { Client } from "../interfaces/ClientInterface";
import { ResourceCentre } from "../interfaces/ResourceCentreInterface";
import { getAppBaseUrl } from "./urls";
import { getNameInitials } from "./util";

export enum MODULE {
  ASSESSMENT = "assessment",
  BILL = "bill",
  LAB_TEST = "labTest",
  STOCK = "stock",
  INTERNAL_STOCK = "internalStock",
  CLIENT = "client",
  EMPLOYEE_LEDGER = "employeeLedger",
  SERVICE_PROVIDER_LEDGERS = "serviceProviderLedger",
  HISTORY = "history",
  ESTIMATES = "estimates",
  ASSOCIATE_COMPANY = "associateCompany",
  CONSENT_FORM = "consentForm",
  SURVERY_FORM = "surveyForm",
  SURVERY_FORM_TEMPLATE = "surveyFormTemplate",
  STOCK_EXPIRY_REPORT = "stockExpiryReport",
  STOCK_LEDGER_REPORT = "stockLedgerReport",
  STOCK_SUMMARY_REPORT = "stockSummaryReport",
  MUL_DARTA = "mulDarta",
  OPD_REGISTER = "opdRegister",
  IMNCI_REGISTER = "imnciRegister",
  IPD_NUMBER = "ipdNumber",
  RISK_INFO = "riskInformation",
  REPORT = "report",
  USER = " user",
  SUPPLIER = "supplier",
  OPD_REQUEST_FORM = "opdRequestForm",
  SMS_PROVIDER = "smsProvider",
  SSF_SETTING = "ssfSettings"
}

type Modules = (typeof MODULE)[keyof typeof MODULE];

export const commonSuccessMessage = "Successfully created.";
export const commonErrorMessage = `Sorry!, Something went wrong.`;
export const commonDeleteMessage = "Successfully Deleted.";
export const authorizedSpDeletedErrorMessage = (deletedSps: ServiceProvider[]): string =>
  `${deletedSps.map((sp) => spFullNameSelector(sp)).join(", ")} ${
    deletedSps.length > 1 ? "are" : "is"
  } deleted. Please remove from settings or assign another authorized person.`;

export const createSuccessMessage = (module: Modules): string =>
  `Successfully, created ${startCase(module)}.`;
export const updateSuccessMessage = (module: Modules): string =>
  `Successfully, updated ${startCase(module)}.`;
export const createErrorMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while creating ${startCase(module)}.`;
export const updateErrorMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while updating ${startCase(module)}.`;
export const errorFetchMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while getting ${startCase(module)}.`;
export const deleteSuccessMessage = (module: Modules): string => `Successfully, deleted ${module}.`;
export const deleteErrorMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while deleting ${module}.`;

// get sample message function
export enum MessageTemplateEnum {
  REQUEST_FOR_DAYBOOK = "requestForDaybook",
  SEND_BILL_INVOICE_OR_ESTIMATE = "sendBillInvoiceOrEstimate",
  LAB_TEST_RESULT_AVAILABLE = "labTestResultAvailable"
}

interface GetMessageTemplateInterface {
  messageTemplateType: MessageTemplateEnum;
  client?: Client | ServiceProvider | Employee;
  activeUser?: Employee;
  activeRc?: ResourceCentre;
  billUuid?: string;
  labResultUrl?: string;
}

export function getMessageTemplate({
  client,
  activeUser,
  activeRc,
  messageTemplateType,
  billUuid,
  labResultUrl
}: GetMessageTemplateInterface): { subject: string; emailBody: string; smsBody: string } {
  let subject = "";
  let emailBody = "";
  let smsBody = "";

  const clientName =
    client && client.firstName && client.lastName ? `${client.firstName} ${client.lastName}` : "";
  const activeRcName = activeRc ? activeRc?.name : "";
  const activeRcInitials = getNameInitials(activeRcName);
  const activeUserName =
    activeUser && activeUser.firstName && activeUser.lastName
      ? `${activeUser.firstName} ${activeUser.lastName}`
      : "";

  switch (messageTemplateType) {
    case MessageTemplateEnum.REQUEST_FOR_DAYBOOK:
      subject = "Request for Access to Daybook Feature";
      smsBody = `Dear Okhati, \n\nI am ${activeUserName} from ${activeRcName} writing to request access to the daybook feature. \n\nThank You!`;
      emailBody = `<p>Dear Okhati,</p> <p></p><p>I am ${activeUserName} from <strong>${activeRcName}</strong> writing to request access to the Daybook feature on okhati.app. I think it can be useful feature for us to use.</p> <p></p><p>Best regards, ${activeRcName}</p>`;
      break;

    case MessageTemplateEnum.SEND_BILL_INVOICE_OR_ESTIMATE:
      subject = "Bill Invoice/Estimate Available";
      emailBody = `<p>Dear Customer,</p> <p></p><p>Greetings from Okhati! Please find the attached herewith the invoice for DHS usage. And please kindly release the payment.</p> <p></p><p>Invoice link: <a href="${getAppBaseUrl()}/my-bill/${billUuid}">${getAppBaseUrl()}/my-bill/${billUuid}</a></p> <p></p><p>Best Regards, Team Okhati</p>`;
      break;

    case MessageTemplateEnum.LAB_TEST_RESULT_AVAILABLE:
      subject = "Lab Test Result Available";
      emailBody = `<p>Dear ${clientName},</p> <p></p><p> Please follow your lab results at <a href=${labResultUrl}>${labResultUrl}</a></p> <p></p><p>Regards, ${activeRcName}</p>`;
      smsBody = `Dear ${clientName}, please follow your lab results at ${labResultUrl}. \n\nRegards, ${activeRcInitials}`;
      break;

    default:
  }

  return { subject, emailBody, smsBody };
}

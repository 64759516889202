import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resourceCentreEmployeeActions } from "../../actions";
import { RootState } from "../../store";

export enum IdSelector {
  EMPLOYEE = "employee",
  USER = "user"
}

export default function EmployeeMultiSelect({
  onChange,
  idSelector = IdSelector.USER
}: {
  onChange: (ids: number[]) => void;
  idSelector?: IdSelector.EMPLOYEE | IdSelector.USER;
}): JSX.Element {
  const [value, setValue] = React.useState([]);

  const rcEmployees = useSelector((state: RootState) => state.resources.resourceCentreEmployees);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!rcEmployees?.length) {
      dispatch(
        resourceCentreEmployeeActions.getResourceCentreEmployees({ resourceCentreId: rcId })
      );
    }
  }, [rcId, dispatch, rcEmployees?.length]);

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (idSelector === IdSelector.USER) {
          onChange(newValue.map(({ user }) => user.id));
          return;
        }
        onChange(newValue.map(({ id }) => id));
      }}
      options={(rcEmployees || []).filter((item) => !item.nonUserEmployee)}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.id}
            label={`${option.firstName} ${option.lastName}`}
            //   eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ marginTop: "8px" }}
      renderInput={(params) => (
        //   eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} fullWidth label="Select Employees" />
      )}
    />
  );
}

EmployeeMultiSelect.defaultProps = {
  idSelector: IdSelector.USER
};

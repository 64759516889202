import { round } from "mathjs";
import { rupeeDisplay } from "./rupee";

// eslint-disable-next-line import/prefer-default-export
export const roundTwoDecimalPoint = (value: string | number): string => {
  const formatted = Number((Math.round(Math.abs(Number(value)) * 100) / 100).toFixed(2));
  return String(formatted);
};

export const roundOffWithRupeeDisplay = (num = 0): string =>
  rupeeDisplay(round(Number(num) || 0, 2), true);

export const removeCommasFromNumber = (num: string): string => {
  if (typeof num !== "string") return num;
  return num?.replace(/,/g, "");
};

import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import startCase from "lodash/startCase";
import { connect } from "react-redux";

interface ServiceProviderWithAvatarInterface {
  serviceProvider: {
    firstName: string;
    lastName: string;
    speciality: string;
  };
}

const ServiceProviderWithAvatar: React.FC<ServiceProviderWithAvatarInterface> = ({
  serviceProvider
}) => {
  if (!serviceProvider) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center" height="30px" py={3}>
      <Box pr={2}>
        <Avatar
          style={{ height: "30px", width: "30px" }}
          alt={serviceProvider && serviceProvider.firstName}
          src="https://picsum.photos/100/100/?random"
        />
      </Box>

      <Typography>
        <Box fontWeight={500} fontSize="14px" paddingRight="8px">
          {serviceProvider && startCase(`${serviceProvider.firstName} ${serviceProvider.lastName}`)}
        </Box>
      </Typography>
      <Typography style={{ fontSize: "14px" }}>
        {serviceProvider?.speciality && `(${serviceProvider.speciality})`}
      </Typography>
    </Box>
  );
};
export default connect(
  (state) => ({
    serviceProviders: state.resources.resourceCentreServiceProviders
  }),
  null
)(ServiceProviderWithAvatar);

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { QRCodeSVG } from "qrcode.react";
import { getTestReportBaseUrl } from "../../../helpers/urls";
import logo from "../../../../assets/images/poweredByOkhati1.svg";

interface Props {
  uuid: string;
  hideFooterCompletely: boolean;
}

const QRWithLogo = ({ uuid, hideFooterCompletely }: Props): JSX.Element => (
  <Box sx={{ display: "flex", justifyContent: "space-between", px: "10mm" }}>
    <Box sx={{ display: "flex", gap: 1 }}>
      <QRCodeSVG size={60} value={`${getTestReportBaseUrl()}/${uuid}`} />
      <Typography>
        Scan QR Code <br />
        to check report online
      </Typography>
    </Box>
    {!hideFooterCompletely && (
      <img
        src={logo}
        alt="logo"
        style={{
          width: "100px",
          height: "33px"
        }}
      />
    )}
  </Box>
);

export default QRWithLogo;

import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import startCase from "lodash/startCase";
import EVENT from "../../mixpanel-analytics/event";
import { allTags } from "./RelatedArticleList";
import Collapse from "../../components/CollapseBar/CollapseBar";
import { KnowledgeBaseDocumentTag, useKnowledgeBaseByTags } from "../../api/knowledgeBase";
import { KnowledgeBaseDocument } from "../../interfaces/KnowledgeBase";
import SelectedArticle from "./SelectedArticle";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";

const getCollapsedValue = (tag, currentTag) => {
  if (!currentTag.length) return true;
  if (tag === currentTag[0]) return false;
  return true;
};

export default function ModuleWiseArticles(): JSX.Element {
  const [currentTag, setCurrentTab] = React.useState<Array<keyof typeof KnowledgeBaseDocumentTag>>(
    []
  );
  const { data = [], isLoading } = useKnowledgeBaseByTags(currentTag);
  const [selectedArticle, setSelectedArticle] = React.useState<KnowledgeBaseDocument | null>(null);
  const resourceCentre = useCurrentResourceCentre();

  return (
    <div>
      {allTags.map((tag) => (
        <Collapse
          key={tag}
          label={startCase(tag)}
          collapsed={getCollapsedValue(tag, currentTag)}
          onClick={() => {
            setSelectedArticle(null);
            setCurrentTab([tag]);
            mixpanelAnalytics.track(`${EVENT.HELP_PAGE_OPEN_MODULE}_${tag}`, {
              rcId: resourceCentre.id,
              rcName: resourceCentre.name
            });
          }}
        >
          {isLoading && <CircularProgress size={30} />}
          {selectedArticle ? (
            <SelectedArticle data={selectedArticle} onClose={() => setSelectedArticle(null)} />
          ) : (
            <Box component="ul" m={0} p={0}>
              {data.map((article) => (
                <Box key={article.id} component="li" onClick={() => setSelectedArticle(article)}>
                  <Button
                    fullWidth
                    variant="text"
                    sx={{
                      "&.MuiButton-text": {
                        color: "#626262",
                        fontSize: 14,
                        fontWeight: 500,
                        justifyContent: "flex-start",
                        textTransform: "none"
                      }
                    }}
                  >
                    {article.data.title}
                  </Button>
                </Box>
              ))}
            </Box>
          )}
          {!data.length && !isLoading && "No data found."}
        </Collapse>
      ))}
    </div>
  );
}

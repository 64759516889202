/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { connect } from "react-redux";
import { getServices } from "../../actions";
import { getBookingsForSA } from "../../actions/booking";
import { getLabTestRecord } from "../../api/labTest";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import PageControl from "../../components/PageControl";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import { Client } from "../../interfaces/ClientInterface";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { ServiceInterface } from "../../interfaces/ServiceInterface";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { IThunkDispatch, RootState } from "../../store";
import BookingInfo from "../Calendar/Booking/BookingInfo";
import BookingStatusComponent from "../Calendar/Booking/BookingStatus";
import LabDetails from "../Lab/LabDetails";
import "./BookingList.scss";
import BookingLists from "./BookingLists";
import Search from "./Search";
import { Booking } from "../../interfaces/BookingInterfaces";
import { LabRecord } from "../../interfaces/Lab";
import { ReverseMap } from "../../helpers/types";

export enum BookingStatus {
  reserved = "reserved",
  handled = "handled",
  cancelled = "cancelled",
  referred = "referred",
  arrived = "arrived",
  confirmed = "confirmed",
  deleted = "deleted",
  draft = "draft",
  waiting = "waiting",
  engaged = "engaged"
}

export type BookingStatusType = ReverseMap<typeof BookingStatus>;

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent"
  },
  tabRoot: {
    fontWeight: 500,
    textTransform: "none",
    fontSize: "14px"
  },
  activeTabRoot: {
    fontWeight: 600,
    textTransform: "none",
    fontSize: "14px"
  }
}));

const tabs = ["all", "unconfirmed", "confirmed", "handled", "draft"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box className="listViewHeight">{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

interface ManageBookingProps {
  mode: string;
  services: ServiceInterface[];
  getBookingsForSupportAdmin: (value) => void;
  getServicesForSupportAdmin: () => void;
  bookings: { results: Booking[]; total: number };
}

interface FilterProps {
  start: Date | string;
  end: Date | string;
  serviceProvider: ServiceProvider | null;
  resourceCentre: ResourceCentre | null;
  client: Client | null;
}

const ManageBooking = ({
  mode,
  bookings,
  services,
  getBookingsForSupportAdmin,
  getServicesForSupportAdmin
}: ManageBookingProps) => {
  const [allBookings, setAllBookings] = React.useState<Booking[]>([]);
  const [confirmedBookings, setConfirmedBookings] = React.useState<Booking[]>([]);
  const [unconfirmedBookings, setUnconfirmedBookings] = React.useState<Booking[]>([]);
  const [handledBookings, setHandledBookings] = React.useState<Booking[]>([]);
  const [draftBookings, setDraftBookings] = React.useState<Booking[]>([]);
  const [filters, setFilters] = React.useState<FilterProps>({
    start: calFns.startOfDay(new Date()).toDate(),
    end: new Date(),
    serviceProvider: null,
    resourceCentre: null,
    client: null
  });
  const [labInfo, setLabInfo] = React.useState<LabRecord | null>(null);
  const pageSize = 50;
  const [page, setPage] = React.useState(0);
  const [selectedBooking, setSelectedBooking] = React.useState<Booking | null>(null);

  const classes = useStyles();

  React.useEffect(() => {
    getServicesForSupportAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const data = {
      start: filters.start,
      end: filters.end,
      serviceProviderId: filters.serviceProvider?.id,
      clientId: filters.client?.id,
      resourceCentreId: filters.resourceCentre?.id
    };
    getBookingsForSupportAdmin({ ...data, page, pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page]);

  React.useEffect(() => {
    if (bookings?.results && services.length) {
      const CB = [] as Booking[];
      const UCB = [] as Booking[];
      const HB = [] as Booking[];
      const DB = [] as Booking[];
      const NDB = [] as Booking[];

      bookings.results.forEach((booking) => {
        let servicesName = [] as string[];
        if (booking.services.length > 0) {
          const bookingServices = services.filter((service) =>
            booking.services.includes(service.id)
          );
          servicesName = bookingServices.map((service) => service.name);
        }
        booking.servicesName = servicesName;
        if (booking.status === BookingStatus.confirmed) {
          CB.push(booking);
        } else if (
          [BookingStatus.handled, BookingStatus.referred].includes(booking.status as BookingStatus)
        ) {
          HB.push(booking);
        } else if (BookingStatus.draft === booking.status) {
          DB.push(booking);
        } else if (BookingStatus.reserved === booking.status) {
          UCB.push(booking);
        }

        if (booking.status !== BookingStatus.draft) NDB.push(booking);
      });

      setConfirmedBookings(CB);
      setUnconfirmedBookings(UCB);
      setHandledBookings(HB);
      setDraftBookings(DB);
      setAllBookings(NDB);
    }
  }, [bookings, services]);

  const [tab, setTab] = React.useState(0);

  if (mode !== "admin") return null;
  const handleShowLabInfo = async () => {
    try {
      const labRecordId = selectedBooking?.extra?.labRecordId;
      if (labRecordId) {
        const res = await getLabTestRecord(labRecordId, selectedBooking?.resourceCentre?.id);
        setLabInfo(res as LabRecord);
      }
    } catch (error) {
      setLabInfo(null);
    }
  };

  return (
    <Box>
      <Search
        filters={filters}
        onSetFilters={(data) => {
          setFilters(data);
          setPage(0);
        }}
      />
      <AppBar
        position="static"
        color="transparent"
        className="appBarTabs"
        classes={{ root: classes.root }}
      >
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          style={{ boxShadow: "none", paddingLeft: 16 }}
        >
          {tabs.map((item, index) => (
            <Tab
              key={item}
              label={tl(`booking.${item}`)}
              data-testmation={item}
              {...a11yProps(index)}
              style={{ fontSize: "0.75rem" }}
              classes={{ root: tab === index ? classes.activeTabRoot : classes.tabRoot }}
            />
          ))}
        </Tabs>
        {[allBookings, unconfirmedBookings, confirmedBookings, handledBookings, draftBookings].map(
          (item, index) => (
            <TabPanel key={tabs[index]} value={tab} index={index}>
              <BookingLists
                filteredBookings={item}
                selectedBooking={selectedBooking}
                onBookingSelect={setSelectedBooking}
              />
            </TabPanel>
          )
        )}
      </AppBar>
      <Box className="navigatePage">
        <PageControl
          page={page}
          onSetPage={(val) => setPage(val)}
          pageSize={pageSize}
          maximumDataCount={bookings.total}
        />
      </Box>
      {selectedBooking && (
        <>
          <Panel
            onClose={() => setSelectedBooking(null)}
            titleJsx={
              <Box display="flex" flexDirection="row">
                <Box pb={1}>
                  <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                    {tl("booking.details")}
                  </Typography>
                </Box>
                <Box ml={2}>
                  <BookingStatusComponent booking={selectedBooking} hideLabel />
                </Box>
              </Box>
            }
          >
            <Box pl="12px">
              <Typography style={{ marginLeft: "20px", fontWeight: 600 }}>
                ID: {selectedBooking.id}
              </Typography>
              <BookingInfo onShowLab={handleShowLabInfo} fromSuperAdmin booking={selectedBooking} />
            </Box>
          </Panel>

          {labInfo && (
            <LabDetails
              editMode={false}
              handleViewClose={() => setLabInfo(null)}
              labObj={labInfo[0]}
              labId={selectedBooking.extra.labRecordId}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    mode: state.userContext.mode,
    userCreds: state.userContext.userCreds,
    bookings: state.bookings.collection,
    services: state.services.collection
  }),
  (dispatch: IThunkDispatch) => ({
    getBookingsForSupportAdmin: (filters) => dispatch(getBookingsForSA(filters)),
    getServicesForSupportAdmin: () => dispatch(getServices())
  })
)(ManageBooking);

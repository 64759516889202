import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import * as BookingActions from '../actions/booking';
import { ReducerBuilder } from './ReducerBuilder';

const INITIAL_STATE = [];

function _addBookings(state, payload) {
  return payload;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(BookingActions.Type.GET_BOOKINGS_FOR_DASHBOARD, _addBookings)
  .build();
export default reducer;

export const slotFromTimeSelector = (event) => moment(event.from).format('h:mm a');
export const slotUntilTimeSelector = (event) => moment(event.until).format('h:mm a');

export const getBookings = (state) => state.dashboard;
export const getServiceProviders = (state) => state.resources.resourceCentreServiceProviders;

export const getBookingsSortedByTime = createSelector([getBookings, getServiceProviders], (bookings, serviceProviders) => bookings.sort((a, b) => {
  moment(a.start).isBefore(moment(b.start)) ? 1 : -1;
}).map((booking) => {
  const serviceProvider = serviceProviders.find((sp) => sp.id === booking.serviceProviderId);
  return { ...booking, serviceProvider };
}));

export const getBookingsSummaryPerSP = createSelector([getBookings], (bookings) => {
  const summary = {};
  bookings.forEach(({ serviceProviderId, status }) => {
    const spSummary = summary[serviceProviderId] || {
      handled: 0, reserved: 0, cancelled: 0, empty: 0, referred: 0, confirmed: 0, engaged: 0, waiting: 0
    };
    spSummary[status || 'reserved'] += 1;
    summary[serviceProviderId] = spSummary;
  });
  return summary;
});

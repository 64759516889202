import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { getService, deleteServices, getServices } from "../../actions/services";
import * as NotificationActions from "../../actions/notification";
import Panel from "../../components/Panel";
import Dialog from "../../components/Dialog/Dialog";
import { tl } from "../../components/translate";
import styles from "./Service.module.css";
import { rupeeDisplay } from "../../helpers/rupee";
import Can from "../Policy/Can";
import { RootState } from "../../store";
import { isServicePublic } from "./ServiceList";
import { ServiceInterface } from "../../interfaces/ServiceInterface";
import { PublicServiceStatus } from ".";
import ProductCreate from "./ProductCreate";

interface PackageInfoInterface {
  productPackage: { [key: string]: any };
  packageId: number;
  actions: {
    getPackage: (packageId: number) => void;
    goBack: (isPublicTab: boolean) => void;
    navigateTo: (url) => void;
    deleteService: (id, isPublicTab: boolean) => void;
    loadServices: () => void;
  };
  handleClose: () => void;
  isPublicTab?: boolean;
}

const PackageInfo: React.FC<PackageInfoInterface> = (props) => {
  const { productPackage, packageId, actions, handleClose, isPublicTab = false, match } = props;

  const [activeProduct, setActiveProduct] = React.useState(null);

  React.useEffect(() => {
    actions.getPackage(packageId);
    actions.loadServices();
  }, []);

  const [dialogToggle, setDialogToggle] = React.useState(false);
  const dialogToggler = () => {
    setDialogToggle(!dialogToggle);
  };

  const deleteServiceFn = async () => {
    await actions.deleteService(packageId, isPublicTab);
    actions.loadServices();
  };

  const deleteButton = !(isPublicTab && !productPackage?.resourceCentreId) ? (
    <Can policyAccessKey="services:deleteProduct">
      <Delete
        style={{ cursor: "pointer" }}
        data-testmation="productInfoDeleteButton"
        onClick={() => setDialogToggle(true)}
      />
    </Can>
  ) : null;

  const editButton = !(isPublicTab && !productPackage?.resourceCentreId) ? (
    <Can policyAccessKey="services:editProduct">
      <Edit
        style={{ cursor: "pointer" }}
        data-testmation="productInfoEditButton"
        onClick={() => actions.navigateTo(`${match.url}/edit`)}
      />
    </Can>
  ) : (
    <Can policyAccessKey="services:editProduct">
      <Button
        style={{
          backgroundColor: "primary"
        }}
        variant="contained"
        onClick={() => actions.navigateTo(`${match.url}/edit`)}
      >
        <Typography>Make It Public</Typography>
      </Button>
    </Can>
  );

  if (!productPackage) return null;

  return (
    <Panel
      onClose={() => {
        handleClose();
        actions.goBack(isPublicTab);
      }}
      deleteButton={deleteButton}
      editButton={editButton}
    >
      {dialogToggle && (
        <Dialog
          title={tl("services.deletePackage")}
          description={tl("services.deletePackageDescription")}
          next={deleteServiceFn}
          cancel={dialogToggler}
        />
      )}
      {activeProduct && (
        <ProductCreate
          productUnderPackage={activeProduct}
          isForEditingServiceUnderPackage
          packageId={packageId}
          setActivePackageProduct={setActiveProduct}
          handleClose={() => {
            setActiveProduct(null);
          }}
        />
      )}
      <Box pl={5} pr={5} width="100%" className={styles.boxHeight}>
        <div>
          <Box display="flex">
            <Typography fontSize="1.4em" fontWeight={600} pb={1}>
              {productPackage.name}
            </Typography>

            {isPublicTab && (
              <Typography
                fontWeight={400}
                ml={3}
                sx={{
                  backgroundColor: isServicePublic(productPackage as ServiceInterface)
                    ? "#009654"
                    : "#e39130",
                  borderRadius: "35px",
                  color: "#fff",
                  minWidth: "80px"
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {isServicePublic(productPackage as ServiceInterface)
                  ? PublicServiceStatus.PUBLIC
                  : PublicServiceStatus.NOTPUBLIC}
              </Typography>
            )}
          </Box>
          <Box component="span" display="flex" fontSize="1rem" fontWeight={300}>
            {tl("services.package")}
          </Box>
        </div>
        <Box display="flex" marginTop="16px">
          <Box width="30%">
            <Typography>{tl("services.vatPct")}</Typography>
          </Box>

          <Typography>
            <Box fontWeight="600">{`${productPackage.vatPct} %`} </Box>
          </Typography>
        </Box>

        <Box display="flex">
          <Box width="30%">
            <Typography>{tl("services.clinicPriceExVAT")}</Typography>
          </Box>

          <Typography>
            <Box fontWeight="600">
              {rupeeDisplay(
                productPackage.vatPct !== 0
                  ? productPackage.servicePriceExcVAT
                  : productPackage.grossTotalPrice
              )}
            </Box>
          </Typography>
        </Box>

        <Box display="flex">
          <Box width="30%">
            <Typography>{tl("services.clinicPrice")}</Typography>
          </Box>

          <Typography>
            <Box fontWeight="600">{rupeeDisplay(productPackage.grossTotalPrice)}</Box>
          </Typography>
        </Box>

        {productPackage?.document?.products && (
          <>
            <Box pt={5} pb={2}>
              <Typography fontWeight="500" color="gray">
                {tl("services.packageContents")}
              </Typography>
            </Box>

            <Box>
              {productPackage?.document?.products?.map((product) => (
                <Box
                  key={product.id}
                  display="flex"
                  borderBottom="1.5px solid lightgray"
                  pt={1}
                  pb={1}
                  style={{ color: product.active ? "inherit" : "#db2323" }}
                >
                  <Typography>{product.name}</Typography>
                  <IconButton
                    style={{ marginLeft: "auto" }}
                    onClick={() => setActiveProduct(product)}
                    data-testmation="packageInfoEditButton"
                  >
                    <Edit />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Panel>
  );
};

export default connect(
  (state: RootState, { packageId, isPublicTab }) => {
    const productPackage = [
      ...(isPublicTab ? state?.publicServices?.collection : []),
      ...state.services.collection
    ].find(({ id }) => id === packageId);
    return {
      productPackage
    };
  },
  (dispatch) => ({
    actions: {
      getPackage: (packageId) => dispatch(getService(packageId)),
      goBack: (isPublicTab) => dispatch(push(isPublicTab ? `/publicServices` : `/services`)),
      navigateTo: (url) => {
        dispatch(push(url));
      },
      deleteService: async (id, isPublicTab) => {
        await dispatch(deleteServices(id));
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: tl("services.successPackageDelete"),
            autoTimeout: true
          })
        );
        dispatch(push(isPublicTab ? `/publicServices` : `/services`));
      },
      loadServices: () => dispatch(getServices())
    }
  })
)(PackageInfo);

PackageInfo.defaultProps = {
  isPublicTab: false
};

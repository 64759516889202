import * as classnames from 'classnames';

export function setBaseClass(baseClass: string) {
  return (name?): string =>
    name
      ? classnames(name)
        .split(' ')
        .map((c) => (c ? `${baseClass}${c}` : baseClass))
        .join(' ')
      : baseClass;
}
import React from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  label: JSX.Element | string;
  children: JSX.Element | JSX.Element[] | string;
  nextLineChildren?: boolean;
  forceBreakPage?: boolean;
}

function PrintRow(props: Props): JSX.Element {
  const { label, children, forceBreakPage = false, nextLineChildren = false } = props;
  return (
    <Box
      display="flex"
      justifyContent={nextLineChildren ? "center" : "flex-start"}
      flexDirection={nextLineChildren ? "column" : "row"}
      alignItems="baseline"
      gap={1}
      mt="0.3cm"
      width="100%"
      style={{
        pageBreakInside: forceBreakPage ? "auto" : "avoid",
        pageBreakAfter: "auto"
      }}
    >
      <Typography fontWeight={600} width={120}>
        {label}:
      </Typography>
      {!nextLineChildren && (
        <Typography variant="subtitle1" component="div" width="100%">
          {children}
        </Typography>
      )}
      {nextLineChildren && <Typography variant="subtitle1">{children}</Typography>}
    </Box>
  );
}

PrintRow.defaultProps = {
  forceBreakPage: false,
  nextLineChildren: false
};

export default PrintRow;

import * as React from "react";
import { Route } from "react-router";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import queryString from "query-string";
import * as clientActions from "../../actions/client";
import * as assessmentActions from "../../actions/assessments";
import AssessmentForm, { MODE } from "./AssessmentForm/Form";
import styles from "./Assessment.module.css";
import { Assessment as IAssessment } from "../../interfaces/AssessmentInterfaces";

interface Props {
  match: Record<string, any>;
  clientId: number;
  getClient: (id: number) => void;
  getAssessments: (id: number) => void;
  assessmentCopy: IAssessment;
  setAssessmentCopy: React.Dispatch<React.SetStateAction<IAssessment>>;
}

const Assessment = ({
  match,
  clientId: client,
  getClient,
  assessmentCopy,
  setAssessmentCopy
}: Props) => {
  const [clientId, setClientId] = React.useState(client ? Number(client) : null);

  React.useEffect(() => {
    if (clientId) {
      getClient(clientId);
    }
  }, [clientId]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexDirection: "column"
      }}
    >
      <Box className={styles.assessmentRightPanel}>
        <Route
          exact
          path={`${match.path}`}
          render={(props) => (
            <AssessmentForm
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              clientId={clientId}
              mode={MODE.CREATE}
              setClientId={setClientId}
              setAssessmentCopy={setAssessmentCopy}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/copy`}
          render={(props) => (
            <AssessmentForm
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              clientId={clientId}
              setClientId={setClientId}
              assessmentCopy={assessmentCopy}
              setAssessmentCopy={setAssessmentCopy}
              mode={MODE.COPY}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/:id/edit`}
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <AssessmentForm
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              clientId={clientId}
              mode={MODE.EDIT}
              setClientId={setClientId}
              setAssessmentCopy={setAssessmentCopy}
            />
          )}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { clientId } = queryString.parse(ownProps.location.search);
  return { clientId };
};

export default connect(mapStateToProps, (dispatch) => ({
  getClient: (clientId) => dispatch(clientActions.getClientById(clientId)),
  getAssessments: (clientId) => dispatch(assessmentActions.getClientAssessments(clientId))
}))(Assessment);

import React from "react";
import { QRCodeSVG } from "qrcode.react";

interface QrProps {
  qrValue: Record<string, string>;
}

const Qr = ({ qrValue }: QrProps): JSX.Element => {
  const value = JSON.stringify(qrValue);

  return (
    <QRCodeSVG
      value={value}
      size={180}
      bgColor="#ffffff"
      fgColor="#000000"
      level="L"
      includeMargin={false}
    />
  );
};

export default Qr;

import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as clientActions from '../../../actions/client';
import { Box, Typography, Link } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import DoctorIcon from '../../../../assets/icons/Doctor.icon';
import { tl } from '../../../components/translate';
import { spFullNameSelector } from '../../../reducers/serviceProvider';
import * as moment from 'moment';
import * as calFns from '../../../components/Calendar/functions/calendarFunctions';
import Timeline, {
  Event,
  EventVariantEnum,
  EventColourEnum,
} from '../../../components/Timeline';
import { clientVisitsSorted } from '../../../reducers/client';

const statusColor = {
  referred: EventColourEnum.blue,
  handled: EventColourEnum.green,
  cancelled: EventColourEnum.red,
  confirmed: EventColourEnum.gray,
  reserved: EventColourEnum.gray,
};

const EventInfo: React.FC<{ visit; serviceProviders; services }> = ({
  visit,
  serviceProviders,
  services,
}) => {
  const spMemo = React.useMemo(
    () => serviceProviders.find(({ id }) => id === visit.serviceProviderId),
    [visit.serviceProviderId, serviceProviders]
  );
  const servicesMemo = React.useMemo(
    () =>
      (visit.services || [])
        .map((v) => services.find(({ id, name }) => id === v)?.name)
        .filter((s) => Boolean(s)),
    [services]
  );
  return (
    <Box>
      {visit.serviceProviderId && (
        <Box>
          <Typography>
            <Box className={'eventIcon'} component={'span'} fontSize={'0.7rem'}>
              <DoctorIcon style={{ marginRight: 5 }} fontSize="small" />
              {spFullNameSelector(spMemo)}
            </Box>
          </Typography>
        </Box>
      )}
      {visit.isVideoAppointment && (
        <Box>
          <Typography>
            <Box
              component={'span'}
              fontSize={'0.7rem'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <VideocamIcon style={{ marginRight: 5 }} fontSize="small" />
              {tl('booking.videoAppointment')}
            </Box>
          </Typography>
        </Box>
      )}
      <Box>
        <Typography>
          <Box component={'span'} fontSize={'0.7rem'}>
            {tl('booking.servicesOrProducts')}: {servicesMemo.join(',')}
          </Box>
        </Typography>
      </Box>
      <Box>
        <Typography>
          <Box component={'span'} fontSize={'0.7rem'}>
            {tl('booking.notes')}: {visit.remarks}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

const UpcomingVisit: React.FC<{
  visit: any;
  onClick;
  serviceProviders;
  services;
}> = ({ visit, serviceProviders, services, onClick }) => {
  return (
    <Box paddingLeft={'8px'} marginBottom={'16px'}>
      <Link style={{ cursor: 'pointer' }} onClick={onClick}>
        <Typography>
          <Box
            component={'span'}
            fontSize={'0.7rem'}
            fontWeight={600}
            position={'relative'}
            top={'-5px'}
          >
            {calFns.convertADtoBS(moment(visit.start)).formatted2} ({' '}
            {tl('clients.upcoming')} )
          </Box>
        </Typography>
      </Link>
      <Box>
        <EventInfo
          visit={visit}
          serviceProviders={serviceProviders}
          services={services}
        />
      </Box>
    </Box>
  );
};

const PastVisit: React.FC<{ visit: any; onClick; serviceProviders; services }> =
  ({ visit, serviceProviders, services, onClick }) => {
    return (
      <Box paddingLeft={'8px'} marginBottom={'16px'}>
        <Link style={{ cursor: 'pointer', color: '#252525' }} onClick={onClick}>
          <Typography>
            <Box
              component={'span'}
              fontSize={'0.6rem'}
              position={'relative'}
              top={'-5px'}
            >
              {calFns.convertADtoBS(moment(visit.start)).formatted2}
            </Box>
          </Typography>
        </Link>
        <Box>
          <EventInfo
            visit={visit}
            serviceProviders={serviceProviders}
            services={services}
          />
        </Box>
      </Box>
    );
  };

const ClientVisits = ({
  authenticable,
  clientId,
  clientVisits,
  serviceProviders,
  services,
  getClientVisits,
  navigateTo,
}) => {
  const isUpcoming = (visit) => {
    const isUpcoming = moment(visit.start).isSameOrAfter(moment());
    return isUpcoming;
  };
  const onClickVisitDate = ({ start, serviceProviderId }) => {
    authenticable != 'client' &&
      navigateTo(
        `/calendar?serviceProviderId=${serviceProviderId}&date=${start}`
      );
  };

  return (
    <Box paddingTop={'16px'}>
      {clientVisits.length ? (
        <Timeline automation={'clientVisitsTimeline'}>
          {clientVisits.map((visit) => (
            <Event
              automation={'clientVisitsTimeline'}
              variant={
                isUpcoming(visit)
                  ? EventVariantEnum.big
                  : EventVariantEnum.small
              }
              colour={statusColor[visit.status]}
            >
              {isUpcoming(visit) ? (
                <UpcomingVisit
                  key={visit.id}
                  visit={visit}
                  serviceProviders={serviceProviders}
                  services={services}
                  onClick={() => onClickVisitDate(visit)}
                />
              ) : (
                <PastVisit
                  key={visit.id}
                  visit={visit}
                  serviceProviders={serviceProviders}
                  services={services}
                  onClick={() => onClickVisitDate(visit)}
                />
              )}
            </Event>
          ))}
        </Timeline>
      ) : (
        <Box>{tl('clients.noVisitsYet')}</Box>
      )}
    </Box>
  );
};

export default connect(
  (state, { clientId }) => ({
    authenticable: state.userContext?.userCreds?.authenticable,
    clientVisits: clientVisitsSorted(state, clientId),
    services: state.services.collection,
    serviceProviders: state.resources.resourceCentreServiceProviders,
  }),
  (dispatch) => ({
    getClientVisits: (id: number) =>
      dispatch(clientActions.getClientVisits(id)),
    navigateTo: (url) => dispatch(push(url)),
  })
)(ClientVisits);

import React from "react";
import { capitalize } from "lodash";
import { Typography } from "@mui/material";
import { Client } from "../../../../interfaces/ClientInterface";
import { clientFullNameSelector } from "../../../../reducers/client";
import { tl } from "../../../../components/translate";
import { InfoField, scaleFont } from "../BillPrintHelpers";

interface Props {
  client: Client;
}

export default function CorporateBillClientInfo({ client }: Props): JSX.Element {
  return (
    <>
      <Typography
        sx={{
          fontSize: scaleFont("0.4cm"),
          fontWeight: 600,
          textTransform: "uppercase"
        }}
      >
        {clientFullNameSelector(client)}
      </Typography>
      {client.panVatNumber && (
        <InfoField label={tl("panVatNo")} data={<>{client.panVatNumber}</>} />
      )}
      {(client.address || client.city) && (
        <Typography>
          {client.address && capitalize(client.address)}
          {client.address && client.city && `, ${capitalize(client.city)}`}
          {client.city && capitalize(client.city)}
        </Typography>
      )}
      {client.phone && (
        <Typography>
          {client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}
        </Typography>
      )}
      {client.email && <Typography>{client.email}</Typography>}
    </>
  );
}

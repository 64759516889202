import { actionCreatorAsync } from './actionHelpers';
import * as api from '../api/ondemandProducts';
import * as NotificationActions from './notification';

export enum Type {
  GET_ONDEMAND_PRODUCTS = 'GET_ONDEMAND_PRODUCTS',
  POST_ONDEMAND_PRODUCT = 'POST_ONDEMAND_PRODUCT',
  PATCH_ONDEMAND_PRODUCT = 'PATCH_ONDEMAND_PRODUCT',
  DELETE_ONDEMAND_PRODUCT = 'DELETE_ONDEMAND_PRODUCT'
}
export const getOnDemandProducts = () => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_ONDEMAND_PRODUCTS, async () => {
        const res = await api.getOnDemandProudcts();
        return res;
      })
    );
  };
};

export const postOnDemandProduct = (data) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.POST_ONDEMAND_PRODUCT, async () => {
        try {
          const res = await api.postOnDemandProduct(data);
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: 'Sucessfully Created!',
              autoTimeout: true
            })
          );
          return res;
        } catch (e) {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'error',
              message: JSON.stringify(e),
              autoTimeout: true
            })
          );
        }
      })
    );
  };
};

export const patchOnDemandProduct = (data, id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.PATCH_ONDEMAND_PRODUCT, async () => {
        try {
          const res = await api.patchOnDemandProduct(data, id);
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: 'Sucessfully Updated!',
              autoTimeout: true
            })
          );
          return res;
        } catch (e) {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'error',
              message: JSON.stringify(e),
              autoTimeout: true
            })
          );
        }
      })
    );
  };
};

export const deleteOnDemandProduct = (id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.DELETE_ONDEMAND_PRODUCT, async () => {
        const data = await api.deleteOnDemandProduct(id);
        try {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: 'Sucessfully Deleted!',
              autoTimeout: true
            })
          );
          return id;
        } catch (e) {
          dispatch(
            //@ts-ignore
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: 'success',
              message: JSON.stringify(e),
              autoTimeout: true
            })
          );
        }
      })
    );
  };
};

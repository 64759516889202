import React from "react";
import { Box, TextField, ButtonGroup, Button, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { tl, t } from "../../components/translate";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface FollowUpInterface {
  assessmentFollowUp: Record<string, unknown>;
  onChange: (key, value) => void;
}

const FollowUp: React.FC<FollowUpInterface> = (props) => {
  const { assessmentFollowUp, onChange } = props;
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box display="flex" alignItems="flex-start" height="auto" p={1}>
      <Box className={styles.assessmentLabel} pt="4px">
        <Typography component="span" fontWeight="600">
          {assessmentLabels.followup}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <Box display="flex" gap={1} flexDirection="column">
          <Box className={styles.buttonGroups}>
            <Box marginRight="16px" display="flex" gap={0.5}>
              <Button
                data-testmation="followUpMinusButton"
                style={{ minWidth: "10px" }}
                size="small"
                variant="outlined"
                disabled={assessmentFollowUp.days <= 0}
                onClick={() => {
                  onChange("days", Number(assessmentFollowUp.days) - 1);
                }}
              >
                <Remove />
              </Button>
              <TextField
                data-testmation="followUpNumberTextField"
                type="number"
                size="small"
                variant="outlined"
                color="primary"
                value={assessmentFollowUp.days}
                onChange={(e) => {
                  onChange("days", e.target.value);
                }}
                inputProps={{
                  style: { textAlign: "center", width: "20px", height: "11px" }
                }}
              />
              <Button
                data-testmation="followUpPlusButton"
                style={{ minWidth: "10px" }}
                size="small"
                variant="outlined"
                onClick={() => {
                  onChange("days", Number(assessmentFollowUp.days) + 1);
                }}
              >
                <Add />
              </Button>
            </Box>

            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                data-testmation="followUpFormatDaysButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "days" ? "primary" : "inherit"}
                onClick={() => {
                  onChange("format", "days");
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.days")}
                  </Box>
                </Typography>
              </Button>
              <Button
                data-testmation="followUpFormatWeeksButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "weeks" ? "primary" : "inherit"}
                onClick={() => {
                  onChange("format", "weeks");
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.week")}
                  </Box>
                </Typography>
              </Button>
              <Button
                data-testmation="followUpFormatWeeksButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "months" ? "primary" : "inherit"}
                onClick={() => {
                  onChange("format", "months");
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.month")}
                  </Box>
                </Typography>
              </Button>

              <Button
                data-testmation="followUpFormatYearsButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "years" ? "primary" : "inherit"}
                onClick={() => {
                  onChange("format", "years");
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.years")}
                  </Box>
                </Typography>
              </Button>
            </ButtonGroup>
          </Box>
          <DebouncedTextField
            data-testmation="followUpRemarksTextField"
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder={t("assessment.followUpRemarks")}
            InputLabelProps={{ shrink: true }}
            value={assessmentFollowUp.note}
            onChange={(e) => onChange("note", e.target.value)}
            debounceAt={400}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FollowUp;

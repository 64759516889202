import { useMemo } from "react";
import useCan from "../../../hooks/useCan";

export interface Report {
  label: string;
  path: string;
}

export interface ReportType {
  type: string;
  permission?: string;
  reports: Report[];
  canUserAccess: unknown;
}

export const REPORT_LIST = [
  {
    type: "Account Reports",
    permission: "account:listAccount",
    reports: [
      { label: "Trial Balance", path: "/reports/account/trialBalance" },
      { label: "General Ledger", path: "/reports/account/generalLedger" },
      { label: "Customer Report", path: "/reports/account/customerReport" },
      { label: "Vendor Report", path: "/reports/account/vendorReport" },
      { label: "Bank Reconciliation Report", path: "/reports/account/bankReconciliation" },
      { label: "Balance Sheet", path: "/reports/account/balanceSheet" },
      { label: "Profit and Loss", path: "/reports/account/profitLoss" },
      { label: "Day Book", path: "/reports/account/dayBookReport" }
    ]
  },
  {
    type: "Stock Reports",
    permission: "stock:listStock",
    reports: [
      { label: "Stock Audit Report", path: "/reports/stock/stockAuditReport" },
      { label: "Stock Cash Flow Report", path: "/reports/stock/stockCashFlowReport" },
      { label: "Supplier Ledger Report", path: "/reports/stock/supplierLedgerReport" },
      { label: "Stock Transactions Report", path: "/reports/stock/stockTransactions" },
      { label: "Stock History Report", path: "/reports/stock/stockHistory" },
      { label: "Stock Summary Report", path: "/reports/stock/stockSummary" },
      { label: "Stock Expiry Report", path: "/reports/stock/stockExpiry" },
      { label: "Stock Ledger Report", path: "/reports/stock/stockLedger" }
    ]
  },
  {
    type: "Patient Reports",
    permission: "allow",
    reports: [
      { label: "Appointment Report", path: "/reports/patient/appointmentReport" },
      { label: "Patient Prescription Report", path: "/reports/patient/patientAssessmentReport" },
      { label: "Client Report", path: "/reports/patient/clientReport" },
      { label: "Reminder Report", path: "/reports/patient/reminders" }
    ]
  },
  {
    type: "Financial Reports",
    permission: "allow",
    reports: [
      { label: "Sales Report", path: "/reports/financial/salesReport" },
      { label: "Fees and Referral Report", path: "reports/financial/commissionReport" },
      { label: "Sales by Service Report", path: "/reports/financial/salesByServiceReport" },
      { label: "Due Report", path: "/reports/financial/dueReport" },
      { label: "Receipt Report", path: "/reports/financial/receiptReport" },
      { label: "Client Ledger Report", path: "/reports/financial/clientLedger" },
      {
        label: "Service Provider Charges Report",
        path: "/reports/financial/serviceProviderChargesReport"
      }
    ]
  },
  {
    type: "Other Reports",
    permission: "allow",
    reports: [
      { label: "Summary Report", path: "/reports/otherReport/serviceCenterSummaryReport" },
      { label: "HMIS Lab Report", path: "/reports/otherReport/hmislab" },
      { label: "HMIS Main Report", path: "/reports/otherReport/hmis" },
      { label: "Activity History Report", path: "/reports/otherReport/history" }
    ]
  },
  {
    type: "Message Reports",
    permission: "report:smsReport",
    reports: [{ label: "Message Report", path: "/reports/messageReport" }]
  },
  {
    type: "Lab Reports",
    permission: "lab:listLab",
    reports: [{ label: "Lab Report", path: "/reports/labReport" }]
  },
  {
    type: "Super Admin Summary Report",
    permission: "deny",
    reports: [{ label: "Super Admin Summary Report", path: "/reports/superAdminSummaryReport" }]
  },
  {
    type: "IRD Report",
    permission: "allow",
    reports: [{ label: "IRD Report", path: "/reports/irdReport" }]
  },
  {
    type: "Customer Due Report",
    permission: "report:dueReport",
    reports: [{ label: "Customer Due Report", path: "/reports/customerDueReport" }]
  },
  {
    type: "Branch Reports",
    permission: "report:cmsReport",
    reports: [
      { label: "Sales By Service Report", path: "/reports/branchReports/salesByServiceReport" },
      { label: "Stock Audit Report", path: "/reports/branchReports/stockAuditReport" },
      { label: "Due Report", path: "/reports/branchReports/dueReport" },
      { label: "Receipt Report", path: "/reports/branchReports/receiptReport" }
    ]
  }
];

const useReportList = (): ReportType[] => {
  const canAccessAccountReport = useCan("account:listAccount", {});
  const canAccessStockReport = useCan("stock:listStock", {});
  const canAccessMessageReport = useCan("report:smsReport", {});
  const canAccessLabReport = useCan("lab:listLab", {});
  const canAccessCustomerDueReport = useCan("report:dueReport", {});
  const canAccessBranchReportsReport = useCan("report:cmsReport", { superAdminPass: true });

  const reports = useMemo(
    () =>
      REPORT_LIST.map(({ permission, ...reportList }) => {
        let canUserAccess: boolean;

        switch (permission) {
          case "account:listAccount":
            canUserAccess = canAccessAccountReport;
            break;
          case "stock:listStock":
            canUserAccess = canAccessStockReport;
            break;
          case "report:smsReport":
            canUserAccess = canAccessMessageReport;
            break;
          case "lab:listLab":
            canUserAccess = canAccessLabReport;
            break;
          case "report:cmsReport":
            canUserAccess = canAccessBranchReportsReport;
            break;
          case "reports:dueReport":
            canUserAccess = canAccessCustomerDueReport;
            break;
          case "allow":
            canUserAccess = true;
            break;
          case "deny":
            canUserAccess = false;
            break;
          default:
            canUserAccess = false;
        }

        return {
          ...reportList,
          canUserAccess
        };
        // eslint-disable-next-line max-len
      }),
    [
      canAccessAccountReport,
      canAccessBranchReportsReport,
      canAccessCustomerDueReport,
      canAccessLabReport,
      canAccessMessageReport,
      canAccessStockReport
    ]
  );

  return reports;
};

export default useReportList;

import axios, { AxiosPromise } from "axios";
import { encode } from "blurhash";
import { Get, Put, Post, Patch } from "./apiHelper";

import { store } from "../store";
import * as NotificationActions from "../actions/notification";
import { Employee, MobileUser, ResourceCentreUserGroups, User } from "../interfaces/User";
import { LedgerInterface } from "../interfaces/Employee";
import { dataURLtoBlob } from "./assessment";
import { getImageData } from "../containers/ResourceCentre/PublicBooking";
import { NotificationTypes } from "../containers/Messages/MessageThread";
import { ResourceCentre } from "../interfaces/ResourceCentreInterface";

const userRoot = "/api/users";
const employeeRoot = "/api/resourceCentreEmployees";
const spRoot = "/api/resourceCentreServiceProviders";

export interface UserProfile {
  id: number;
  name: string;
  profileImageRef: string | null;
  authenticableId: number;
  phone: string;
  email: string;
  gender: number;
  dob: Date | string;
}

export interface ChatProfileResponse {
  [key: string]: UserProfile | ResourceCentre | Record<string, unknown>;
}

export interface MobileUsersQueryParams {
  page: number;
  pageSize: number;
  query?: string;
}

export async function getResourceCentreUserGroups(resourceCentreId: number): Promise<{
  resourceCentreUserGroups: Array<ResourceCentreUserGroups>;
  globalUserGroups: Array<ResourceCentreUserGroups>;
}> {
  const response = await Get(`/api/userGroups?resourceCentreId=${resourceCentreId}`, true);

  return response.data as {
    resourceCentreUserGroups: Array<ResourceCentreUserGroups>;
    globalUserGroups: Array<ResourceCentreUserGroups>;
  };
}

export async function getAccount(): Promise<unknown> {
  const response = await Get(`${userRoot}/account`);
  return response.data;
}

export async function updatePassword(passwords: string): Promise<unknown> {
  const response = await Put(`${userRoot}/updatePassword`, passwords);
  return response.data;
}

export async function updateUserId(referenceId: number, attributes: unknown): Promise<unknown> {
  const response = await axios.put(`${userRoot}/facebook/${referenceId}`, attributes);
  return response.data;
}

export const getByUserReferenceId = async (referenceId: number): Promise<unknown> => {
  const response = await axios.get(`${userRoot}/facebook/${referenceId}`);
  return response.data;
};

export const verifyUserAccessToken = async (token: string): Promise<unknown> => {
  try {
    const response = await axios.post(`${userRoot}/auth/facebook`, token);
    return response.data;
  } catch (err) {
    if (Number(err.response.status) === 401) {
      const n = NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "error",
        message: err.response.data.map((e) => e.msg).join(" "),
        autoTimeout: true
      });
      store.dispatch(n);
    }
    return null;
  }
};

export const updatePermission = async (
  entityId: number,
  entityName: string,
  permissionGroup: string
): Promise<unknown> => Put(`/api/${entityName}s/${entityId}/updatePermission`, { permissionGroup });

export const profilePicUploadURL = (userId: number): string => `${userRoot}/${userId}/profilePic`;

export async function uploadProfilePic(
  userId: number,
  pic: Record<string, unknown>
): Promise<unknown> {
  const response = await Post(
    profilePicUploadURL(userId),
    pic,
    true,
    {},
    {
      "Content-Type": "multipart/form-data"
    }
  );
  return response.data;
}

export async function userUpdate(userObj: Record<string, unknown>): Promise<unknown> {
  const response = await Put(`${userRoot}/account`, userObj, true);
  return response.data;
}

export const resetPasswordFor = async (userId: number): Promise<unknown> =>
  Get(`${userRoot}/resetPasswordFor/${userId}`);

export const getVendorLedgers = async (
  employeeId: number,
  vendorType: string
): Promise<LedgerInterface[]> => {
  const response = await Get(`/api/vendor/ledgers/${vendorType}/${employeeId}`);
  return response.data as LedgerInterface[];
};

export const batchUploadEmployee = async (data: Array<Employee>): Promise<string> => {
  const response = await Post(`${employeeRoot}/batch`, { employees: data }, true);
  return response.data as string;
};

export const batchUploadNonUserEmployee = async (data: Array<Employee>): Promise<string> => {
  const response = await Post(
    `${employeeRoot}/batch`,
    { employees: data, isNonUserEmployees: true },
    true
  );
  return response.data as string;
};

export const batchUploadSp = async (data: Array<Employee>): Promise<string> => {
  const response = await Post(`${spRoot}/batch`, { serviceProviders: data }, true);
  return response.data as string;
};

export const verifyUsersToken = async (data: {
  userId: number;
  token: string;
}): Promise<{ [key: string]: string | boolean }> => {
  const response = await axios.post(`${userRoot}/verifyToken`, data);
  return response.data as { [key: string]: string | boolean };
};

export const updateEmployeeTwoFactorAuth = async (data: {
  id: number;
  enforcedTwoFactorAuth: boolean;
  rcId: number;
}): Promise<AxiosPromise> => {
  const response = await Patch(`${userRoot}/twoFactorAuth/${data.id}`, data, true);
  return response.data as AxiosPromise;
};

export const resetUserPassword = async (data: {
  username: string;
  email: string;
}): Promise<AxiosPromise> => {
  const response = await Post(`${userRoot}/reset-password`, data, false);
  return response.data as AxiosPromise;
};

export const getS3UploadPostDataForSignature = async (
  fileName: string,
  contentType: string
): Promise<unknown> => {
  const response = await Post(`/api/serviceProviders/signature`, { fileName, contentType });
  return response.data;
};

function getContentTypeFromBase64(base64String) {
  // Check if the string starts with "data:image/png;base64," or "data:image/svg+xml;base64,"
  if (base64String.startsWith("data:image/png;base64,")) {
    return "image/png";
  }
  if (base64String.startsWith("data:image/svg+xml;base64,")) {
    return "image/svg+xml";
  }
  // Default to binary data if no match
  return "application/octet-stream";
}

export const getS3UploadPostData = async (fileName: string): Promise<unknown> => {
  const response = await Post(`/api/serviceProviders/profileImage`, { fileName });
  return response.data;
};

export const spProfileImagePresignedS3 = async (
  pictureData: Blob
): Promise<{ profileKey: string; profileImage: string }> => {
  const profileInfo = {
    profileKey: "",
    profileImage: "",
    blurHash: ""
  };
  try {
    const fileName = `${Date.now()}.${pictureData.type.split("/")[1]}`;
    const postData = (await getS3UploadPostData(fileName)) as {
      id: number;
      data: Record<string, unknown>;
    };
    const s3FileName = `${postData.id}/${fileName}`;
    const formData = new FormData();

    [
      "key",
      "bucket",
      "acl",
      "X-Amz-Algorithm",
      "X-Amz-Credential",
      "X-Amz-Date",
      "Policy",
      "X-Amz-Signature"
    ].forEach((key) => {
      formData.append(key, postData.data.fields[key]);
    });
    formData.append("file", pictureData, s3FileName);
    const response = await axios.post(postData.data.url, formData, {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    });
    const imageData = await getImageData(pictureData);
    const hash = encode(imageData.data, imageData.width, imageData.height, 4, 3);
    profileInfo.profileImage =
      response.headers.location || `${postData.data.url}/${postData.data.fields.key}` || "";
    profileInfo.profileKey = postData.data.fields.key;
    profileInfo.blurHash = hash;
    return profileInfo;
  } catch (err) {
    return null;
  }
};

export const serviceProviderSignature = async (
  pictureData: string
): Promise<{ signatureKey: string; signature: string }> => {
  const signatureInfo = {
    signatureKey: "",
    signature: ""
  };
  try {
    const contentType = getContentTypeFromBase64(pictureData);
    const fileName = `${Date.now()}-signature.${contentType === "image/svg+xml" ? "svg" : "png"}`;

    const postData = (await getS3UploadPostDataForSignature(fileName, contentType)) as {
      id: number;
      data: Record<string, unknown>;
    };
    const s3FileName = `${postData.id}/${fileName}`;
    const formData = new FormData();
    [
      "key",
      "bucket",
      "acl",
      "X-Amz-Algorithm",
      "X-Amz-Credential",
      "X-Amz-Date",
      "Policy",
      "X-Amz-Signature"
    ].forEach((k) => {
      formData.append(k, postData.data.fields[k]);
    });
    formData.append("Content-Type", contentType);
    const blobImage = dataURLtoBlob(pictureData);

    formData.append("file", blobImage, s3FileName);
    const response = await axios.post(postData.data.url, formData, {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    });
    signatureInfo.signature =
      response.headers.location || `${postData.data.url}/${postData.data.fields.key}` || "";
    signatureInfo.signatureKey = postData.data.fields.key;
    return signatureInfo;
  } catch (err) {
    signatureInfo.signature = "";
    signatureInfo.signatureKey = "";
    return signatureInfo;
  }
};

export const getUsersWithVerificationCodes = async (
  query?: string
): Promise<Array<Partial<User>>> => {
  const response = await Get("/api/admin/verificationCodes", true, { query });
  return response.data as User[];
};

export const getMobileUsers = async ({
  query,
  page,
  pageSize
}: MobileUsersQueryParams): Promise<Array<Partial<MobileUser>>> => {
  const response = await Get(`/api/admin/mobileUsers?page=${page}&pageSize=${pageSize}`, true, {
    query
  });
  return response?.data as MobileUser[];
};

export const regenerateToken = async (payload: {
  id: number;
  phone: string;
}): Promise<Partial<User>> => {
  const response = await Patch(
    `/api/admin/verificationCodes/${payload.id}`,
    { phone: payload.phone },
    true
  );
  return response.data as User;
};

export const getUserAndRcProfiles = async (
  userIds: Array<number>,
  rcIds?: Array<number>
): Promise<ChatProfileResponse> => {
  const response = await Get(
    `/api/public/v1/getChatProfiles?userIds=${userIds?.toString() || ""}&resourceCentreIds=${
      rcIds?.toString() || ""
    }`,
    true
  );
  return response.data as ChatProfileResponse;
};

export const sendChatNotification = async (data: {
  message: string;
  userId: number;
  title: string;
  notificationType: NotificationTypes;
}): Promise<string> => {
  const response = await Post(`/api/notifications/chat`, data, true);
  return response.data as string;
};

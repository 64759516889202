import { ReducerBuilder } from './ReducerBuilder';
import * as AddressActions from '../actions/addressOptions';

const INITIAL_STATE = {
  collection: []
};

function setAddressOptions(state, payload) {
  return { ...state, collection: payload || [] };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(AddressActions.Type.GET_ADDRESS_OPTIONS, setAddressOptions)
  .build();
export default reducer;

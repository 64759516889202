import axios from "axios";

export const prismicServiceStatusRootUrl = "https://okhati-service-status.prismic.io/api/v2";

export const prismicKnowledgeBaseRootUrl = "https://okhati-knowledge-base.cdn.prismic.io/api/v2";

export const getPrismicRef = async (rootUrl: string): Promise<string> => {
  const {
    data: { refs }
  } = await axios.get<{ refs: Array<{ ref: string }> }>(rootUrl);
  return refs[0]?.ref;
};

export const getPrismicDocuments = async <T>(
  rootUrl: string,
  query: string,
  ref: string
): Promise<Array<T>> => {
  const {
    data: { results }
  } = await axios.get<{ results: Array<T> }>(`${rootUrl}${query}&ref=${ref}`);
  return results;
};

import { Box, Typography } from "@mui/material";
import React from "react";
import Chat from "../../Svgicons/Chat";

const EmptyChat = (): JSX.Element => (
  <Box
    display="flex"
    height="calc(100% - 100px)"
    sx={{ justifyContent: "center", alignItems: "center", paddingX: 2 }}
  >
    <Box
      width="100%"
      sx={{
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box display="flex" flex={1} justifyContent="flex-start" alignItems="center" mb={2} pl={3}>
        <Chat height="30" width="30" />
        <Typography variant="h5" pl={1}>
          You don&apos;t have any messages currently!
        </Typography>
      </Box>
      <Box px={3}>
        <Typography variant="h5">
          You have enabled
          <strong style={{ color: "#009654" }}> Digi Health</strong>. You will be able to see all of
          your client&apos;s messages here.
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default EmptyChat;

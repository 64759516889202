import { useParams } from "react-router";
import { encode } from "blurhash";
import Close from "@mui/icons-material/Close";
import {
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Select,
  MenuItem,
  Checkbox
} from "@mui/material";
import produce from "immer";
import { startCase } from "lodash";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import TimePicker from "react-time-picker";
import { MapContainer, TileLayer, useMapEvents, Marker } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import ImageCrop from "../../components/ImageCrop/ImageCrop";
import Dialog from "../../components/Dialog/Dialog";
import { notificationAdd } from "../../actions/notification";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import {
  getResourceCentrePublicProfile,
  PublicProfileState,
  setResourceCentrePublicProfile,
  updateProfile,
  updateResourceCentreBanner
} from "../../slices/publicProfileSlice";
import { RootState } from "../../store";
import styles from "./style.module.css";
import "leaflet/dist/leaflet.css";
import "./leaflet.css";
import { t, tl } from "../../components/translate";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Props {
  resourceCentre: ResourceCentre;
}

const nphlCategories = [
  {
    label: "None",
    value: null
  },
  {
    label: "A",
    value: "A"
  },
  {
    label: "B",
    value: "B"
  },
  {
    label: "C",
    value: "C"
  },
  {
    label: "D",
    value: "D"
  }
];

function LocationMarker(props: {
  setCoordinates: (v) => void;
  coordinates: { lat: number; lng: number };
}) {
  const { setCoordinates, coordinates } = props;
  const map = useMapEvents({
    click(e) {
      map.locate();
      map.flyTo(e.latlng, map.getZoom());
      setCoordinates(e.latlng);
    }
  });

  React.useEffect(() => {
    if (coordinates) {
      map.setView(coordinates);
    }
  }, [coordinates]);

  return coordinates === null ? null : (
    <Marker position={coordinates}>
      <Box bgcolor="white">You are here</Box>
      {/* <Popup>You are here</Popup> */}
    </Marker>
  );
}

export const getImageData = async (blob: Blob): Promise<ImageData> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      resolve(context.getImageData(0, 0, img.width, img.height));
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(blob);
  });

export default function PublicBooking(props: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const { resourceCentre } = props;
  const params = useParams<{ id: string }>();

  const profile = useSelector((s: RootState) => s.publicProfile.profileDetails);

  const [showErrorDialog, setShowErrorDialog] = React.useState(false);
  const [isCropperOpen, setIsCropperOpen] = React.useState(false);
  const [tempImg, setTempImg] = React.useState(null);
  const [dateToAdd, setDateToAdd] = React.useState<null | Date>(new Date());
  const coordinates = {
    lat: profile.address?.lat || 27.700769,
    lng: profile.address?.lng || 85.30014
  };

  React.useEffect(() => {
    dispatch(getResourceCentrePublicProfile(Number(params.id)));
  }, [params.id, dispatch]);

  const toggleErrorDialog = () => {
    setShowErrorDialog(!showErrorDialog);
  };

  const fileUploader = React.useRef<HTMLInputElement>();

  const permissionGroup = useSelector((s: RootState) => s.userContext?.userCreds?.userGroups[0]);
  const disabledEditing = !["superAdmin", "resourceCentreAdmin"].includes(permissionGroup);

  async function handleImageChange(e) {
    if (!e?.target?.files?.length) {
      return;
    }
    if (e?.target?.files[0]?.size >= 200000) {
      // show error dialog if file size exceeds 200KB
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      toggleErrorDialog();
    }
    const file = e?.target?.files[0];
    const extensionIndex = file?.name?.lastIndexOf(".") + 1; // getting starting index of file extension
    // getting extension name
    const extension = file?.name?.substr(extensionIndex)?.toLowerCase();
    if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif") {
      setTempImg(URL.createObjectURL(file));
      setIsCropperOpen(true);
    } else {
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      toggleErrorDialog(); // if file is not a image type then show error dalog
    }
  }

  if (!resourceCentre) return null;

  return (
    <Box p={2} height="calc(100vh - 100px)" sx={{ overflowY: "auto" }}>
      <Typography variant="h5">{tl("publicBook.PublicProfile")}</Typography>
      <Grid container spacing={4} mt="32px">
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.PublicName")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            data-testmation="publicName"
            fullWidth
            value={profile.publicName}
            onChange={(e) => dispatch(updateProfile({ ...profile, publicName: e.target.value }))}
            variant="outlined"
            placeholder={t("publicBook.NamePlaceHolder")}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.BannerImage")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box>
            {!profile?.bannerUrl ? (
              <Typography variant="subtitle1">{tl("publicBook.BannerImagenotSet")}</Typography>
            ) : (
              <img src={profile.bannerUrl} alt="banner-img" className={styles.banner} />
            )}
          </Box>
          <label htmlFor="fileUploader">
            <input
              className="imageUpload"
              data-testmation="uploadBannerInput"
              type="file"
              accept=".jpg,.jpeg,.gif,.png"
              ref={fileUploader}
              name="fileUploader"
              disabled={disabledEditing}
              onChange={handleImageChange}
              id="fileUploader"
              style={{
                display: "none"
              }}
            />
            <Button variant="contained" component="span">
              {tl("publicBook.BannerImageUpload")}
            </Button>
          </label>
          <ImageCrop
            image={tempImg}
            dynamicRatio
            isOpen={isCropperOpen}
            onClose={() => setIsCropperOpen(false)}
            onSave={async (img) => {
              const imageData = await getImageData(img);
              const hash = encode(imageData.data, imageData.width, imageData.height, 4, 3);
              dispatch(
                updateProfile({ ...profile, blurHash: { ...profile.blurHash, banner: hash } })
              );
              const formData = new FormData();
              formData.append("qqfile", img);
              const rcObj = { id: resourceCentre.id, formData };
              dispatch(updateResourceCentreBanner(rcObj));
            }}
          />
          {showErrorDialog && (
            <Dialog
              title="Cannot upload image"
              description="File size limit is only 200KB or wrong file format"
              next={toggleErrorDialog}
              readMode
            />
          )}
        </Grid>{" "}
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.Accreditation")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            data-testmation="accreditationText"
            rows={1}
            fullWidth
            variant="outlined"
            placeholder={t("publicBook.AccreditationPlaceHolder")}
            value={profile.accreditation}
            onChange={(e) => dispatch(updateProfile({ ...profile, accreditation: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.NPHLCategory")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Select
            data-testmation="nphlCategory"
            value={profile.nphlCategory}
            label="Nphl Category"
            onChange={(e) => dispatch(updateProfile({ ...profile, nphlCategory: e.target.value }))}
            style={{ width: "120px" }}
          >
            {nphlCategories.map((category) => (
              <MenuItem
                value={category.value}
                key={category.label}
                data-testmation={`option${category.label}`}
              >
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.OkhatiRating")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            data-testmation="okhatiIndex"
            rows={1}
            fullWidth
            variant="outlined"
            placeholder={t("publicBook.OkhatiRatingPlaceholder")}
            value={profile.okhatiIndex}
            onChange={(e) => dispatch(updateProfile({ ...profile, okhatiIndex: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.About")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            data-testmation="aboutThisClinic"
            rows={8}
            multiline
            fullWidth
            value={profile.about}
            onChange={(e) => dispatch(updateProfile({ ...profile, about: e.target.value }))}
            variant="outlined"
            placeholder={t("publicBook.AboutPlaceholder")}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.Location")}</Typography>
        </Grid>
        <Grid item xs={12} height="300px" md={10}>
          <Box data-testmation="mapLocation">
            <MapContainer center={coordinates} zoom={13}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker
                coordinates={profile.address}
                setCoordinates={(v) => dispatch(updateProfile({ ...profile, address: v }))}
              />
            </MapContainer>
          </Box>
          {profile.address && (
            <>
              <TextField
                type="number"
                onFocus={(e) => e.target.select()}
                variant="outlined"
                margin="dense"
                value={profile.address?.lat}
                onChange={(e) =>
                  dispatch(
                    updateProfile({
                      ...profile,
                      address: { ...profile.address, lat: e.target.value }
                    })
                  )
                }
                label="Latitude"
              />
              <TextField
                onFocus={(e) => e.target.select()}
                type="number"
                variant="outlined"
                margin="dense"
                value={profile.address?.lng}
                onChange={(e) =>
                  dispatch(
                    updateProfile({
                      ...profile,
                      address: { ...profile.address, lng: e.target.value }
                    })
                  )
                }
                label="Longitude"
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1">{tl("publicBook.OpeningHoursandHolidays")}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          {" "}
          <TableContainer component={Paper} sx={{ width: "80%" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    td: { padding: "4px" },
                    th: { padding: "4px" }
                  }}
                >
                  <TableCell>{tl("publicBook.Day")}</TableCell>
                  <TableCell align="center">{tl("publicBook.IsHoliday")}</TableCell>
                  <TableCell align="center">{tl("publicBook.StartTime")}</TableCell>
                  <TableCell align="center">{tl("publicBook.EndTime")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profile.event.ocHours.map((row, i) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      td: { padding: "4px" },
                      th: { padding: "4px" }
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography fontWeight={600}>{startCase(row.name)}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Box sx={{ textAlign: "center" }}>
                        <Checkbox
                          checked={row.isHoliday || false}
                          onChange={() => {
                            const updatedState = produce(
                              profile as PublicProfileState["profileDetails"],
                              (draft) => {
                                draft.event.ocHours[i].isHoliday =
                                  !draft.event.ocHours[i].isHoliday;
                              }
                            );
                            dispatch(updateProfile(updatedState));
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="center" data-testmation={`startTime${row.name}`}>
                      <TimePicker
                        value={row.startTime}
                        onChange={(v) => {
                          const updatedState = produce(
                            profile as PublicProfileState["profileDetails"],
                            (draft) => {
                              draft.event.ocHours[i].startTime = v.toString();
                            }
                          );
                          dispatch(updateProfile(updatedState));
                        }}
                        disableClock
                        className={styles.timepicker_root}
                        clearIcon={null}
                      />
                    </TableCell>
                    <TableCell align="center" data-testmation={`endTime${row.name}`}>
                      <TimePicker
                        value={row.endTime}
                        onChange={(v) => {
                          const updatedState = produce(
                            profile as PublicProfileState["profileDetails"],
                            (draft) => {
                              draft.event.ocHours[i].endTime = v.toString();
                            }
                          );
                          dispatch(updateProfile(updatedState));
                        }}
                        disableClock
                        className={styles.timepicker_root}
                        clearIcon={null}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper sx={{ p: 1, mt: 4, width: "80%" }}>
            <Typography fontWeight={600}>{tl("publicBook.Holidays")}</Typography>
            {profile.event.closingDays.length === 0 ? (
              <Typography mt="8px">No holidays found</Typography>
            ) : (
              profile.event.closingDays.map((holiday, i) => (
                <Box key={holiday} display="flex" alignItems="center">
                  <Typography>{moment(holiday).format("YYYY-MM-DD")}</Typography>
                  <Close
                    data-testmation="deleteHoliday"
                    sx={{ ml: "64px", cursor: "pointer" }}
                    onClick={() => {
                      const updatedState = produce(
                        profile as PublicProfileState["profileDetails"],
                        (draft) => {
                          draft.event.closingDays.splice(i, 1);
                        }
                      );
                      dispatch(updateProfile(updatedState));
                    }}
                  />
                </Box>
              ))
            )}
            <Box display="flex" alignItems="center">
              <TextField
                data-testmation="addHolidayDateInput"
                type="date"
                value={moment(dateToAdd).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setDateToAdd(new Date(moment(e.target.value).toISOString()));
                }}
              />
              <Button
                data-testmation="addHolidayBtn"
                sx={{ ml: "32px" }}
                variant="contained"
                onClick={() => {
                  if (dateToAdd) {
                    const updatedState = produce(profile, (draft) => {
                      draft.event.closingDays = [...draft.event.closingDays, dateToAdd];
                    });
                    dispatch(updateProfile(updatedState));
                    setDateToAdd(null);
                  }
                }}
              >
                {tl("addHoliday")}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box display="flex" width="100%" justifyContent="center">
        <Button
          data-testmation="savePublicProfileBtn"
          sx={{ mt: "32px" }}
          onClick={async () => {
            const formattedProfile = produce(
              profile as PublicProfileState["profileDetails"],
              (draft) => {
                draft.event.closingDays = draft.event.closingDays.map((d) => moment(d).format());
              }
            );
            await dispatch(
              setResourceCentrePublicProfile({
                resourceCentreId: Number(params.id),
                ...formattedProfile
              })
            );
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: "Public profile saved!",
                autoTimeout: true
              })
            );
          }}
          variant="contained"
        >
          {tl("save")}
        </Button>
      </Box>
    </Box>
  );
}

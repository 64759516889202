import { Box, Typography } from "@mui/material";
import React from "react";
import { ServiceProvider } from "../../../../interfaces/ServiceProvidersInterface";
import { spFullNameSelector } from "../../../../reducers/serviceProvider";
import { scaleFont } from "../LabPrintFunctions";

interface DoctorLetterHeadProps {
  serviceProvider: ServiceProvider;
  titleFontScale?: number;
  isCentered?: boolean;
}

const DoctorLetterHead: React.FC<DoctorLetterHeadProps> = ({
  serviceProvider,
  titleFontScale,
  isCentered
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: isCentered ? "center" : "flex-start",
      textAlign: isCentered ? "center" : "initial"
    }}
  >
    <Box>
      <Typography style={{ fontWeight: 500, fontSize: scaleFont("0.6cm", titleFontScale) }}>
        {spFullNameSelector(serviceProvider)}
      </Typography>

      <Typography
        style={{
          fontWeight: 500,
          fontSize: scaleFont("0.5cm", titleFontScale)
        }}
      >
        {serviceProvider?.qualification}
      </Typography>

      <Typography style={{ textTransform: "capitalize", fontSize: "0.4cm" }}>
        {serviceProvider?.registrationNumber}
      </Typography>

      <Typography style={{ textTransform: "capitalize", fontSize: "0.4cm" }}>
        {serviceProvider?.address} {serviceProvider?.city}
      </Typography>
    </Box>
  </Box>
);

DoctorLetterHead.defaultProps = {
  titleFontScale: 1,
  isCentered: false
};

export default DoctorLetterHead;

import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  FormControlLabel
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import produce from "immer";
import ActionBar from "../../../components/ActionBar/ActionBar";
import { CalendarFunctions as calFns } from "../../../components/Calendar";
import { deleteSchedule, getSchedules, saveSchedule } from "../../../actions/schedules";
import ServiceProviderWithAvatar from "../../../components/ServiceProviderWithAvatar";
import IconWithField from "../../../components/IconWithField";
import styles from "../Booking/styles.module.css";
import { Schedule } from "../../../interfaces/ScheduleInterface";
import { IBookableResource } from "../../../interfaces/BookableResources";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { CanActions } from "../../../interfaces/policy";
import { IThunkDispatch, RootState } from "../../../store";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 4
  }
}));

interface SlotInfoProps {
  schedule: Schedule;
  onClose: () => void;
  serviceProvider: any;
  videoService: ProductInterface;
  consultService: ProductInterface;
  homeService: ProductInterface;
  deleteEvent: (id, next) => void;
  postException: (schedule) => void;
  loadSchedules: (serviceProviderId, start, end, resourceId?) => void;
  calStart: moment.Moment;
  calEnd: moment.Moment;
  selectedSP: any;
  selectedResource: IBookableResource;
}

const SlotInfo: React.FC<SlotInfoProps> = ({
  schedule,
  onClose,
  serviceProvider,
  videoService,
  consultService,
  deleteEvent,
  postException,
  loadSchedules,
  calStart,
  calEnd,
  selectedSP,
  selectedResource,
  homeService
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteState, setDeleteState] = React.useState<"all" | "thisDay">("all");

  const actions: Array<{
    label: string;
    action: () => void;
    Icon: React.ReactNode;
    dataTestmation: string;
    name: string;
    accessKey: CanActions;
  }> = [
    {
      label: "Delete Schedule",
      action: () => setDeleteDialogOpen(true),
      Icon: DeleteIcon,
      dataTestmation: "ScheduleEditDeleteBtn",
      name: "Delete Schedule",
      accessKey: "booking:deleteSchedule"
    }
  ];

  const classes = useStyles();

  const findServiceDetails = (id) => schedule?.details?.services.find((item) => item.id === id);

  const consultServiceDetails = consultService && findServiceDetails(consultService.id);
  const homeServiceDetails = homeService && findServiceDetails(homeService.id);
  const videoServiceDetails = videoService && findServiceDetails(videoService.id);

  let scheduleType = "Video";

  if (schedule.onSite) {
    scheduleType = "On Site";
  } else if (schedule.homeServiceId) {
    scheduleType = "Home";
  }

  return (
    <Box
      data-testmation="bookingInfoContainer"
      p={2}
      className={styles.bookingInfoContainer}
      width="450px"
    >
      <ActionBar actions={actions} onClose={onClose} />
      {serviceProvider && (
        <Box mb="24px">
          <ServiceProviderWithAvatar
            serviceProvider={serviceProvider}
            onServiceProviderChange={() => ({})}
          />
        </Box>
      )}
      <Box className={styles.createEditBody}>
        <IconWithField
          LabelIcon={<ScheduleIcon />}
          style={{
            display: "flex",
            marginBottom: "8px",
            minHeight: "32px"
          }}
          iconStyle={{
            alignItems: "flex-start"
          }}
        >
          <Box>
            <Box display="flex">
              {schedule.start && (
                <Typography style={{ fontSize: "12px" }}>
                  {calFns.convertADtoBS(new Date(schedule.start)).formatted4}
                </Typography>
              )}
              <Typography style={{ marginLeft: "16px" }}>
                {moment(schedule.start).format("LT")} - {moment(schedule.end).format("LT")}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography style={{ fontSize: "0.75rem", color: "#585858" }}>
                Slot Duration
              </Typography>
              <Typography style={{ marginLeft: "8px", fontSize: "0.75rem", color: "#585858" }}>
                {schedule.duration} minutes
              </Typography>
              <Typography style={{ marginLeft: "24px", fontSize: "0.75rem", color: "#585858" }}>
                {schedule.daysInWeek.length > 1 ? "Repeats" : "Does not repeat"}
              </Typography>
            </Box>
          </Box>
        </IconWithField>

        <IconWithField
          LabelIcon={<LocationOnIcon />}
          style={{
            display: "flex",
            marginBottom: "8px",
            minHeight: "32px"
          }}
          iconStyle={{
            alignItems: "flex-start"
          }}
        >
          <Typography>{scheduleType}</Typography>
        </IconWithField>

        {(consultService || videoService) && (
          <>
            <IconWithField
              LabelIcon={<CardGiftcardIcon />}
              style={{
                display: "flex",
                minHeight: "32px"
              }}
              iconStyle={{
                alignItems: "flex-start"
              }}
            >
              <Box display="flex">
                {!consultService && !videoService && !homeService && <Typography>-</Typography>}
                {consultService && <Typography>{consultService.name}</Typography>}
                {videoService && (
                  <Typography>
                    {consultService ? ", " : ""}
                    {videoService.name}
                  </Typography>
                )}
                {(consultService || videoService) && (
                  <Typography style={{ fontSize: "0.75rem", marginLeft: "16px", color: "grey" }}>
                    Price Rs{" "}
                    {consultService?.grossTotalPrice || videoService?.grossTotalPrice || ""}
                  </Typography>
                )}
              </Box>
            </IconWithField>
            {(consultServiceDetails?.originalPrice || videoServiceDetails?.originalPrice) && (
              <Box display="flex" ml="40px" mb="8px">
                <Typography>Strike-Through Price</Typography>
                <Typography style={{ fontSize: "0.75rem", marginLeft: "16px", color: "grey" }}>
                  {consultServiceDetails?.originalPrice || ""}
                  {videoServiceDetails?.originalPrice || ""}
                </Typography>
              </Box>
            )}
          </>
        )}
        {homeService && (
          <>
            <IconWithField
              LabelIcon={<HomeIcon />}
              style={{
                display: "flex",
                minHeight: "32px"
              }}
              iconStyle={{
                alignItems: "flex-start"
              }}
            >
              <Box display="flex">
                {homeService && <Typography>{homeService.name}</Typography>}
                {homeService && (
                  <Typography style={{ fontSize: "0.75rem", marginLeft: "16px", color: "grey" }}>
                    Price Rs {homeService?.grossTotalPrice || ""}
                  </Typography>
                )}
              </Box>
            </IconWithField>

            {homeServiceDetails?.originalPrice && (
              <Box display="flex" ml="40px" mb="8px">
                <Typography>Strike-Through Price</Typography>
                <Typography style={{ fontSize: "0.75rem", marginLeft: "16px", color: "grey" }}>
                  {homeServiceDetails?.originalPrice || ""}
                </Typography>
              </Box>
            )}
          </>
        )}

        <IconWithField
          LabelIcon={<EventAvailableIcon />}
          style={{
            display: "flex",
            minHeight: "32px",
            alignItems: "center"
          }}
          iconStyle={{
            alignItems: "flex-start"
          }}
        >
          <Typography style={{ display: "flex", alignItems: "center" }}>
            Public Booking{" "}
            {schedule.publicBooking ? (
              <CheckIcon color="primary" style={{ marginLeft: "8px" }} />
            ) : (
              <ClearIcon htmlColor="red" style={{ marginLeft: "8px" }} />
            )}
          </Typography>
        </IconWithField>
        {!serviceProvider && (
          <Box
            style={{
              display: "flex",
              minHeight: "32px",
              alignItems: "center",
              paddingLeft: "40px"
            }}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              Allow Pay Later{" "}
              {schedule.payLater ? (
                <CheckIcon color="primary" style={{ marginLeft: "8px" }} />
              ) : (
                <ClearIcon htmlColor="red" style={{ marginLeft: "8px" }} />
              )}
            </Typography>
          </Box>
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.root }}
      >
        <DialogTitle id="alert-dialog-title">Delete schedule</DialogTitle>
        <DialogContent style={{ width: "320px", minHeight: "120px" }}>
          <FormControlLabel
            control={
              <Radio
                value="all"
                checked={deleteState === "all"}
                onChange={() => setDeleteState("all")}
              />
            }
            label="Delete all events"
          />
          <FormControlLabel
            control={
              <Radio
                value="thisDay"
                checked={deleteState === "thisDay"}
                onChange={() => setDeleteState("thisDay")}
              />
            }
            label="Delete current day"
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testmation="delete"
            onClick={async () => {
              if (deleteState === "all") {
                await deleteEvent(schedule.id, () => onClose());
              } else if (deleteState === "thisDay") {
                const newException = produce(schedule, (draft) => {
                  draft.parentId = draft.id;
                  draft.id = null;
                  draft.eStart = draft.start;
                  draft.eEnd = draft.end;
                });
                await postException(newException);
                loadSchedules(selectedSP, calStart, calEnd, selectedResource);
              }
              setDeleteDialogOpen(false);
              onClose();
            }}
            style={{ backgroundColor: "#d97016", color: "white" }}
          >
            Delete
          </Button>
          <Button onClick={() => setDeleteDialogOpen(false)} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default connect(
  (state: RootState, ownProps: { schedule: Schedule }) => ({
    serviceProvider: state.resources.resourceCentreServiceProviders.find(
      (item) => Number(item.id) === Number(ownProps.schedule.serviceProviderId)
    ),
    consultService: state.services.collection.find(
      (item) => item.id === ownProps.schedule.consultServiceId
    ),
    videoService: state.services.collection.find(
      (item) => item.id === ownProps.schedule.videoServiceId
    ),
    homeService: state.services.collection.find(
      (item) => item.id === ownProps.schedule.homeServiceId
    )
  }),
  (dispatch: IThunkDispatch) => ({
    deleteEvent: (id, next) => dispatch(deleteSchedule(id, next)),
    postException: (schedule) => dispatch(saveSchedule(schedule)),
    loadSchedules: (serviceProviderId, start, end, resourceId?) =>
      dispatch(getSchedules(serviceProviderId, start, end, resourceId))
  })
)(SlotInfo);

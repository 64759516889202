import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import SuppliersList from "./SuppliersList";

export default function Suppliers(): JSX.Element {
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentreId);

  return <SuppliersList resourceCentreId={resourceCentreId} navigateToResourceSettingsSp={false} />;
}

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface State {
  imageUrl: string;
  required2FAToken: boolean;
  userId: number | null;
}

const initialState: State = {
  imageUrl: "",
  required2FAToken: false,
  userId: null
};

const twoFactorAuthInfoSlice = createSlice({
  name: "twoFactorAuthInfo",
  initialState,
  reducers: {
    updateTwoFactorInfo: (
      state,
      {
        payload
      }: PayloadAction<{
        required2FAToken: boolean;
        imageUrl: string;
        userId: null | number;
      }>
    ) => {
      state.imageUrl = payload.imageUrl;
      state.required2FAToken = payload.required2FAToken;
      state.userId = payload.userId;
    },
    clearQrImage: (state) => {
      state.imageUrl = "";
    }
  }
});

export const { updateTwoFactorInfo, clearQrImage } = twoFactorAuthInfoSlice.actions;
export default twoFactorAuthInfoSlice.reducer;

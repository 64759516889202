import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/services";
import * as errorActions from "./error";
import * as NotificationActions from "./notification";
import { tl, t } from "../components/translate";
import { ServiceActions } from "../interfaces/ActionTypes";
import hasOwnProperty from "../helpers/object";
import { ServiceInterface } from "../interfaces/ServiceInterface";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createServices = (service: ServiceInterface) => async (dispatch) => {
  await dispatch(
    actionCreatorAsync(
      ServiceActions.CREATE_SERVICES,
      async () => api.postBatchService(service),
      errorActions.addGeneralErrorCreator("CREATE_SERVICES")
    )
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateServices = (service: ServiceInterface) => async (dispatch) => {
  await dispatch(
    actionCreatorAsync(
      ServiceActions.UPDATE_SERVICES,
      async () => api.putBatchService(service),
      errorActions.addGeneralErrorCreator("UPDATE_SERVICES")
    )
  );
};

export const updateServiceUnderPackage =
  ({ data, packageId }: { data: ServiceInterface; packageId: string }) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(
        ServiceActions.UPDATE_SERVICE_UNDER_PACKAGE,
        async () => api.updateServiceUnderPackage({ data, packageId }),
        errorActions.addGeneralErrorCreator(ServiceActions.UPDATE_SERVICE_UNDER_PACKAGE)
      )
    );
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getService = (id: number) => async (dispatch) => {
  await dispatch(actionCreatorAsync(ServiceActions.GET_SERVICE, async () => api.getService(id)));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getServices = () => async (dispatch) => {
  await dispatch(
    actionCreatorAsync(ServiceActions.GET_SERVICES, async () => api.getBatchService())
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteServices = (id: number) => async (dispatch) => {
  await dispatch(
    // eslint-disable-next-line consistent-return
    actionCreatorAsync(ServiceActions.DELETE_SERVICES, async () => {
      try {
        const data = await api.deleteBatchService(id);
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: tl("services.successProductDelete"),
            autoTimeout: true
          })
        );
        return data;
      } catch (err) {
        if (hasOwnProperty(err, "status") && err.status === 405) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message:
                err.data.type === "associatedPackageFound"
                  ? (t(`services.${err.data.type}`) || "").replace("%d", err.data?.data?.name || "")
                  : tl(`services.${err.data.type}`),
              timeout: 4000
            })
          );
        } else {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: tl("services.failureProductDelete"),
              autoTimeout: true
            })
          );
        }
      }
    })
  );
};

import axios from "axios";
import { store } from "../store";
import * as NotificationActions from "../actions/notification";
import { Post } from "./apiHelper";

export async function login(credentials: unknown): Promise<unknown> {
  try {
    const response = await axios.post("/api/login", credentials);
    return response.data;
  } catch (err) {
    const n = NotificationActions.notificationAdd({
      id: new Date().getUTCMilliseconds(),
      variant: "error",
      message: err.response.data.map((e) => e.msg).join(" "),
      autoTimeout: true
    });
    store.dispatch(n);

    return null;
  }
}

export async function adminImpoersonate(rcId: number): Promise<unknown> {
  try {
    const response = await Post(`/api/impersonate/${rcId}`, {});
    return response?.data;
  } catch (e) {
    console.log("DA FUQ!");
  }

  return null;
}

export async function loginViaFacebook(credentials: unknown): Promise<unknown> {
  try {
    const response = await axios.post("/api/loginViaFacebook", credentials);
    return response.data;
  } catch (err) {
    const n = NotificationActions.notificationAdd({
      id: new Date().getUTCMilliseconds(),
      variant: "error",
      message: err.response.data.map((e) => e.msg).join(" "),
      autoTimeout: true
    });
    store.dispatch(n);

    return null;
  }
}

import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { scaleFont } from "../../LabPrintFunctions";
import { RootState } from "../../../../../store";
import { UserGroups } from "../../../../ResourceCentre/UserSettings/SPCreateEdit";
import useGetShowSignatureOnLabPrint from "../../../../../hooks/useGetShowSignatureOnLabPrint";

export interface AuthorizedSp {
  signature: string;
  name: string;
  qualification: string;
  registrationNumber: string;
  specialities: string;
  role: string;
  speciality: string;
  title: string;
  userGroup: string;
}

interface AuthorizedSpInfoProps {
  rootStyles?: { [k: string]: string | number };
  imageStyles?: { [k: string]: string | number };
  sp: AuthorizedSp;
}

const AuthorizedSpInfo: React.FC<AuthorizedSpInfoProps> = ({
  sp,
  rootStyles = {},
  imageStyles = {}
}) => {
  const serviceProviders = useSelector(
    (state: RootState) => state.resources?.resourceCentreServiceProviders
  );
  const spWithSignature = serviceProviders?.find((serviceProvider) => serviceProvider.id === sp.id);
  const { showSignatureOnLabPrint } = useGetShowSignatureOnLabPrint();

  return (
    <Box style={rootStyles}>
      <Box style={{ width: "4cm" }}>
        <Box component="div" display="flex" alignItems="center" marginTop="0.2cm" minHeight="2cm">
          {sp.signature && showSignatureOnLabPrint && (
            <img
              src={sp.signature}
              style={{ ...imageStyles, height: "2cm" }}
              alt="serviceprovider_signature"
            />
          )}
        </Box>
        <Typography
          style={{
            fontSize: scaleFont("0.33cm", 0.85),
            width: "4cm",
            textAlign: "left",
            borderTop: "1px solid lightgrey",
            fontWeight: 600,
            marginTop: "8px",
            textTransform: "capitalize",
            paddingTop: "0.2cm"
          }}
        >
          {sp.title || ""} {sp.name}
        </Typography>
      </Box>

      {spWithSignature?.designation ? (
        <Typography style={{ whiteSpace: "pre-wrap", fontSize: scaleFont("0.33cm", 0.8) }}>
          {spWithSignature.designation}
        </Typography>
      ) : (
        <div>
          <Typography style={{ fontSize: scaleFont("0.33cm", 0.8) }}>
            {`${sp.qualification || ""}${
              sp.userGroup === UserGroups.ServiceProviderFull
                ? `, ${sp.specialities}`
                : `${sp.speciality || ""}`
            }`}
          </Typography>
          <Typography style={{ fontSize: scaleFont("0.33cm", 0.8) }}>
            {sp.registrationNumber ? `${sp.registrationNumber}` : ""}
          </Typography>
        </div>
      )}
    </Box>
  );
};

AuthorizedSpInfo.defaultProps = {
  rootStyles: {},
  imageStyles: {}
};

export default AuthorizedSpInfo;

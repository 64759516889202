import React from "react";
import { Box } from "@mui/material";
import ImageCrop from "../ImageCrop/ImageCrop";
import Dialog from "../Dialog/Dialog";
import dummy from "../../../assets/images/dummyProfile.png";

function ImageUploader({
  readOnly,
  action,
  initialImage
}: {
  readOnly?: boolean;
  action: (...args) => void;
  initialImage: string;
}): React.ReactElement {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [cropperOpen, setCropperOpen] = React.useState(false);
  const [tmpImage, setTmpImage] = React.useState(initialImage);
  const uploaderInputRef = React.useRef(null);

  React.useEffect(() => {
    setTmpImage(initialImage || dummy);
  }, [initialImage]);

  function toggleDialog() {
    setDialogOpen(!dialogOpen);
  }

  function handleImageChange(e) {
    e.preventDefault();
    if (e.target.files[0]?.size >= 2097152) {
      toggleDialog();
    } else {
      const file = e.target.files[0];
      if (!file) return;
      const nameOnly = file.name.lastIndexOf(".") + 1;
      const ext = file.name.substr(nameOnly, file.name.length).toLowerCase();
      if (["jpg", "jpeg", "png", "gif"].includes(ext) && file) {
        setTmpImage(URL.createObjectURL(file));
        setCropperOpen(true);
      } else {
        toggleDialog();
      }
    }
  }

  return (
    <Box display="flex">
      <Box
        onClick={() => {
          if (!readOnly) {
            uploaderInputRef?.current.click();
          }
        }}
      >
        <img
          src={tmpImage}
          alt="client"
          style={{
            height: "64px",
            width: "64px",
            borderRadius: "50%",
            border: "2px solid grey",
            cursor: `${readOnly ? "" : "pointer"}`
          }}
        />
      </Box>
      <ImageCrop
        image={tmpImage}
        isOpen={cropperOpen}
        dynamicRatio={false}
        onCancel={() => {
          setCropperOpen(false);
          setTmpImage(initialImage);
        }}
        onSave={(croppedImage) => {
          action(croppedImage);
          setCropperOpen(false);
        }}
      />
      <input
        type="file"
        ref={uploaderInputRef}
        name="fileUploader"
        style={{ display: "none" }}
        onChange={handleImageChange}
        accept="image/gif, image/jpeg, image/png"
      />
      {dialogOpen && (
        <Dialog
          title="Cannot upload image"
          description="File size limit is only 2MB or wrong file format"
          // eslint-disable-next-line react/jsx-no-bind
          next={toggleDialog}
          readMode
        />
      )}
    </Box>
  );
}
ImageUploader.defaultProps = {
  readOnly: false
};

export default ImageUploader;

import React from "react";
import { Box, Typography, TextField, InputAdornment, Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Questionnaire } from "../../../interfaces/AssessmentInterfaces";

enum INPUT_TYPE {
  AUTO_COMPLETE = "autoComplete",
  TEXT = "text"
}

export const QUESTIONNAIRE = [
  {
    name: "Occupation",
    unit: "",
    label: "OCCUPATION",
    dbKey: "occupation",
    defaultValue: "",
    dropdownValues: [],
    reading: "",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "NPRS",
    unit: "",
    label: "NPRS",
    dbKey: "nprs",
    defaultValue: "0",
    dropdownValues: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Trauma",
    unit: "",
    label: "TRAUMA",
    dbKey: "trauma",
    defaultValue: "Negative",
    dropdownValues: ["Negative", "Gradual", "Positive"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Surgery",
    unit: "",
    label: "SURGERY",
    dbKey: "surgery",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Metal/Pacemakers",
    unit: "",
    label: "METAL/PACEMAKERS",
    dbKey: "metal/pacemakers",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Allergy",
    unit: "",
    label: "ALLERGY",
    dbKey: "allergy",
    defaultValue: "",
    dropdownValues: [],
    reading: "",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Pregnant",
    unit: "",
    label: "PREGNANT",
    dbKey: "pregnant",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Breastfeed",
    unit: "",
    label: "BREASTFEED",
    dbKey: "breastfeed",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Diet",
    unit: "",
    label: "DIET",
    dbKey: "diet",
    defaultValue: "Veg",
    dropdownValues: ["Veg", "Non-Veg"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Addiction",
    unit: "",
    label: "ADDICTION",
    dbKey: "addiction",
    defaultValue: "smoking",
    dropdownValues: ["Smoking", "Alcohol", "Drugs"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "B/B",
    unit: "",
    label: "B/B",
    dbKey: "b/b",
    defaultValue: "",
    dropdownValues: [],
    reading: "",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Lifestyle",
    unit: "",
    label: "LIFE STYLE",
    dbKey: "lifestyle",
    defaultValue: "Sed",
    dropdownValues: ["Sed", "Mod", "Active"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Water",
    unit: "litre",
    label: "WATER",
    dbKey: "water",
    defaultValue: "",
    dropdownValues: [],
    reading: "",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Sunbath",
    unit: "",
    label: "SUNBATH",
    dbKey: "sunbath",
    defaultValue: "",
    dropdownValues: [],
    reading: "",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Sleep",
    unit: "",
    label: "SLEEP",
    dbKey: "sleep",
    defaultValue: "Increase",
    dropdownValues: ["Increase", "Decrease"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },

  {
    name: "HTN",
    unit: "",
    label: "HTN",
    dbKey: "htn",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "DMII",
    unit: "",
    label: "DMII",
    dbKey: "dmii",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "APD",
    unit: "",
    label: "APD",
    dbKey: "apd",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  },
  {
    name: "Kidneydis",
    unit: "",
    label: "KIDNEY DIS",
    dbKey: "kidneydis",
    defaultValue: "Positive",
    dropdownValues: ["Positive", "Negative"],
    reading: "",
    type: INPUT_TYPE.AUTO_COMPLETE
  }
];

interface QuestionnaireRowInterface {
  question: Questionnaire;
  onRemove: () => void;
  onChange: (rowQuestion: Questionnaire) => void;
  remainingQuestionnaires: Questionnaire[];
}

export const defaultRow = {
  name: "",
  unit: "",
  label: "",
  dbKey: "",
  defaultValue: "",
  dropdownValues: [],
  reading: "",
  type: INPUT_TYPE.TEXT,
  placeholder: "Enter value"
};

const QuestionnaireRow: React.FC<QuestionnaireRowInterface> = ({
  question,
  onChange,
  onRemove,
  remainingQuestionnaires
}): JSX.Element => {
  const [rowQuestion, setRowQuestion] = React.useState(question);

  React.useEffect(() => {
    setRowQuestion(question);
  }, [question]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Box width="20%">
        {rowQuestion.name ? (
          <Typography fontWeight="600" marginTop="15px">
            {rowQuestion.name}
          </Typography>
        ) : (
          <Box sx={{ marginRight: "5px" }}>
            <Autocomplete
              freeSolo
              value={rowQuestion}
              onChange={(_, newValue = "") => {
                if (!newValue) {
                  setRowQuestion({ ...defaultRow });
                  onChange({ ...defaultRow });
                }
                if (newValue && typeof newValue === "object") {
                  setRowQuestion(newValue);
                  onChange(newValue);
                }
              }}
              options={remainingQuestionnaires}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  autoFocus
                  onBlur={(event) => {
                    const newValue = event.target.value;
                    setRowQuestion({
                      name: newValue,
                      unit: "",
                      label: newValue,
                      dbKey: newValue,
                      defaultValue: "",
                      dropdownValues: [],
                      reading: "",
                      type: "text"
                    });
                  }}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
              )}
            />
          </Box>
        )}
      </Box>
      <Box width="80%">
        {rowQuestion.type === INPUT_TYPE.TEXT && (
          <TextField
            data-testmation="questionnaireReading"
            variant="outlined"
            margin="dense"
            placeholder={rowQuestion.placeholder}
            fullWidth
            value={rowQuestion.reading}
            onChange={(e) => setRowQuestion({ ...rowQuestion, reading: e.target.value })}
            onBlur={() => onChange(rowQuestion)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>{rowQuestion.unit}</Typography>
                </InputAdornment>
              )
            }}
          />
        )}
        {rowQuestion.type === INPUT_TYPE.AUTO_COMPLETE && (
          <Autocomplete
            freeSolo
            inputValue={rowQuestion.reading}
            onInputChange={(_, newValue) => {
              setRowQuestion({ ...rowQuestion, reading: newValue || "" });
            }}
            value={rowQuestion.reading}
            onBlur={() => onChange(rowQuestion)}
            onChange={(_, newValue) => {
              setRowQuestion({ ...rowQuestion, reading: (newValue as string) || "" });
            }}
            options={rowQuestion.dropdownValues}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                fullWidth
                data-testmation="questionnaireReading"
                margin="dense"
              />
            )}
          />
        )}
      </Box>
      <IconButton data-testmation="vitals.removeButton" onClick={onRemove} sx={{ ml: 1 }}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default QuestionnaireRow;

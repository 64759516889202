import React from "react";
import { Autocomplete, TextField } from "@mui/material";

import { connect } from "react-redux";
import { RootState } from "../../store";

function ServiceTagsSearch({
  value = [],
  required = false,
  onChange,
  onFocus,
  onBlur,
  helperText,
  error,
  options = []
}) {
  const optionsHash = {};

  options.forEach((option) => {
    optionsHash[option.id] = {
      name: option.tag,
      mappedName: option.mappedName,
      tag: option.tag,
      adjective: option.adjective
    };
  });

  return (
    <Autocomplete
      options={options.map((o) => o.id)}
      value={value || []}
      multiple
      getOptionLabel={(option) =>
        `${optionsHash[option]?.tag || ""} ${
          optionsHash[option]?.adjective ? `(${optionsHash[option]?.adjective})` : ""
        })`
      }
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option}>
          {`${optionsHash[option]?.tag || ""} ${
            optionsHash[option]?.adjective ? `(${optionsHash[option]?.adjective})` : ""
          })`}
        </li>
      )}
      onChange={(e, v) => {
        onChange(v);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder="Select services for the doctor"
          margin="dense"
          label="Select Service"
          required={required}
          onFocus={onFocus}
          onBlur={onBlur}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
}

export default connect(
  (state:RootState) => ({
    options: state.serviceTags.collection
  }),
  null
)(ServiceTagsSearch);

import React from "react";
import produce from "immer";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import Can from "../../../Policy/Can";
import { ResourceCentre } from "../../../../interfaces/ResourceCentreInterface";
import { getTaxation } from "../../../../slices/journalEntry";
import useIsAccountSubscribed from "../../../../hooks/accounts";
import AccountingVoucherSeriesList from "../../../accounts/AccountingVoucherSeries/AccountingVoucherSeriesList";
import FiscalPeriods from "./FiscalPeriods";
import TaxationsMapping from "./TaxationsMapping";
import LedgerSelect from "./LedgerSelect";
import { paymentOptionsEnum } from "../../../Billing/Editor/BillSummary";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../../accounts/hooks";
import { LedgerType } from "../../../../interfaces/Accounts";
import SectionHeading from "../../SectionHeading";
import { tl } from "../../../../components/translate";

const modeOfPaymentFields = [
  {
    value: paymentOptionsEnum.cash,
    label: "Cash"
  },
  {
    value: paymentOptionsEnum.card,
    label: "Card"
  },
  {
    value: paymentOptionsEnum.cheque,
    label: "Cheque"
  },
  {
    value: paymentOptionsEnum.onlinePayment,
    label: "Online Payment"
  },
  {
    value: paymentOptionsEnum.bankTransfer,
    label: "Bank Transfer"
  },
  {
    value: paymentOptionsEnum.khalti,
    label: "Khalti"
  },
  {
    value: paymentOptionsEnum.eSewa,
    label: "Esewa"
  },
  {
    value: paymentOptionsEnum.fonePay,
    label: "FonePay"
  }
];

enum TAB_ITEMS {
  FISCAL_PERIOD = "Fiscal Period",
  VOUCHER_NUMBER = "Voucher Number",
  TAXATION = "Taxation",
  GL_MAPPING = "Payment mode - GL Mapping"
}

interface AccountSettingsProps {
  canAccessAccountSettings: boolean;
  updateRc: (newRc: ResourceCentre) => void;
  resourceCentre: ResourceCentre;
}

export default function AccountSettings({
  canAccessAccountSettings,
  resourceCentre,
  updateRc
}: AccountSettingsProps): JSX.Element | null {
  const isAccountSubscribed = useIsAccountSubscribed();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isAccountSubscribed) {
      dispatch(getTaxation());
    }
  }, [dispatch, isAccountSubscribed]);

  const { assets } = extractSecondaryData(useCoaAll());
  const assetsChildGl = getAllChildGl(assets).filter((gl) =>
    [LedgerType.BANK_LEDGER, LedgerType.CASH_LEDGER].includes(gl.ledgerType)
  );
  const search = new URLSearchParams(window.location.search);
  const activeTab = search.get("activeTab");
  const [tab, setTab] = React.useState(activeTab || TAB_ITEMS.FISCAL_PERIOD);
  if (!resourceCentre) return null;

  return (
    <Box>
      <SectionHeading name={tl("account.accSetting")} />
      <Box sx={(theme) => ({ width: "100%", borderBottom: `1px solid ${theme.palette.divider}` })}>
        <Tabs
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
          aria-label="account setting tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab value={TAB_ITEMS.FISCAL_PERIOD} label={tl("TAB_ITEMS.FISCAL_PERIOD")} />
          <Tab value={TAB_ITEMS.VOUCHER_NUMBER} label={tl("TAB_ITEMS.VOUCHER_NUMBER")} />
          {canAccessAccountSettings && (
            <Tab value={TAB_ITEMS.GL_MAPPING} label={tl("TAB_ITEMS.GL_MAPPING")} />
          )}
          {canAccessAccountSettings && (
            <Tab value={TAB_ITEMS.TAXATION} label={tl("TAB_ITEMS.TAXATION")} />
          )}
        </Tabs>
      </Box>

      {tab === TAB_ITEMS.FISCAL_PERIOD && <FiscalPeriods resourceCentre={resourceCentre} />}

      {tab === TAB_ITEMS.VOUCHER_NUMBER && (
        <Box height="600px">
          <AccountingVoucherSeriesList resourceCentreId={resourceCentre.id} />
        </Box>
      )}

      {tab === TAB_ITEMS.TAXATION && <TaxationsMapping />}

      {tab === TAB_ITEMS.GL_MAPPING && (
        <Can policyAccessKey="account:listAccount" superAdminPass>
          <Box display="flex" m="10px 0">
            <Typography width="200px" fontWeight="medium">
              {tl("modeOf Payment")}
            </Typography>
            <Typography fontWeight="medium">{tl("AccLedger")}</Typography>
          </Box>
          <Box ml="10px">
            {modeOfPaymentFields.map((mode) => {
              const ledgerId =
                resourceCentre.settings?.accountSettings?.modeOfPayment?.[mode.value]?.ledgerId;
              const selected = assetsChildGl.find((ledger) => ledger.id === ledgerId);
              return (
                <Box key={mode.label} display="flex" mb="10px" alignItems="center">
                  <Typography width="200px">{mode.label}</Typography>
                  <LedgerSelect
                    options={assetsChildGl}
                    selected={selected}
                    onChange={(v) => {
                      updateRc(
                        produce(resourceCentre, (draft) => {
                          const { modeOfPayment } = draft.settings.accountSettings;
                          if (modeOfPayment[mode.value]) {
                            draft.settings.accountSettings.modeOfPayment[mode.value].ledgerId =
                              v?.id;
                          } else {
                            draft.settings.accountSettings.modeOfPayment[mode.value] = {
                              ledgerId: v?.id
                            };
                          }
                        })
                      );
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Can>
      )}
    </Box>
  );
}

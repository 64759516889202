import React from "react";
import { tl } from "../../../components/translate";
import { BillSummaryRow } from "../BillPrintSummary";
import { rupeeDisplay } from "../../../helpers/rupee";

interface Props {
  tenderAmount: number;
  changeAmount: number;
  smallFont?: boolean;
  noMarginTop?: boolean;
}

const TenderAmountPrint = ({
  tenderAmount,
  changeAmount,
  smallFont = false,
  noMarginTop = false
}: Props): JSX.Element => (
  <>
    <BillSummaryRow
      label={tl("billing.tenderAmount")}
      value={rupeeDisplay(tenderAmount.toFixed(2))}
      smallFont={smallFont}
      noMarginTop={noMarginTop}
    />
    <BillSummaryRow
      label={tl("billing.changeAmount")}
      value={rupeeDisplay(changeAmount.toFixed(2))}
      smallFont={smallFont}
      noMarginTop={noMarginTop}
    />
  </>
);

export default TenderAmountPrint;

import * as React from "react";
import { Box, Typography, Button, FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { tl } from "../../../components/translate";
import styles from "../Assessment.module.css";
import { RootState } from "../../../store";

interface Footer {
  sendToClient?: boolean;
  onSendToClientChange?: () => void;
  mode: string;
  onSave: () => void;
  navigateTo?: () => void;
  goBack?: () => void;
  printJSX: React.ReactNode;
  saveDisabled: boolean;
  goBackToClient?: () => void;
  onTemplateSave: () => void;
}

const Footer = (props: Footer): JSX.Element => {
  const {
    mode,
    onSave,
    saveDisabled,
    navigateTo,
    goBack,
    printJSX,
    goBackToClient,
    onTemplateSave,
    sendToClient,
    onSendToClientChange
  } = props;

  const isSmsSubscribed = useSelector(
    (state: RootState) => state.userContext.resourceCentre.subscriptions.features.sms.subscribed
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = React.useState(false);

  const isNotViewMode = mode === "create" || mode === "edit" || mode === "copy";

  return (
    <Box className={styles.formFooter}>
      <Box>
        {mode === "view" && (
          <Box pr={2}>
            <Button
              data-testmation="backToClientButton"
              variant="text"
              color="primary"
              onClick={() => {
                if (goBackToClient) goBackToClient();
              }}
            >
              <Typography>{tl("assessment.backToClient")}</Typography>
            </Button>
          </Box>
        )}
      </Box>
      {isSmsSubscribed && isNotViewMode && (
        <Box mr="auto" ml={2}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked checked={sendToClient} onChange={onSendToClientChange} />
            }
            label="Send message to client"
          />
        </Box>
      )}
      <Box display="flex">
        {isNotViewMode && (
          <Box
            sx={{
              display: "flex",
              gap: 2
            }}
          >
            <Button
              data-testmation="cancelButton"
              variant="text"
              color="primary"
              onClick={() => {
                if (goBack) goBack();
              }}
            >
              <Typography>{tl("cancel")}</Typography>
            </Button>
            <StatefulButton
              variant="text"
              color="primary"
              disabled={isTemplateLoading}
              onClick={async () => {
                setIsTemplateLoading(true);
                await onTemplateSave();
                setIsTemplateLoading(false);
              }}
              isLoading={isTemplateLoading}
              circularProgressProps={{ size: 16 }}
              data-testmation="assessmentSaveAsTemplateButton"
            >
              <Typography>{tl("assessment.saveAsTemplate")}</Typography>
            </StatefulButton>
            <StatefulButton
              variant="contained"
              color="primary"
              disabled={saveDisabled || isLoading}
              onClick={async () => {
                setIsLoading(true);
                await onSave();
                setIsLoading(false);
              }}
              isLoading={isLoading}
              circularProgressProps={{ size: 16 }}
              data-testmation="assessmentSaveButton"
            >
              <Typography>{tl("save")}</Typography>
            </StatefulButton>
          </Box>
        )}

        {mode === "view" && (
          <Box alignItems="center" display="flex">
            <Box pr={2}>
              <Button
                data-testmation="assessmentEditButton"
                variant="contained"
                color="primary"
                onClick={() => {
                  if (navigateTo) navigateTo();
                }}
              >
                <Typography>{tl("edit")}</Typography>
              </Button>
            </Box>
            <Box data-testmation="print">{printJSX}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Footer.defaultProps = {
  navigateTo: () => ({}),
  goBack: () => ({}),
  goBackToClient: () => ({}),
  sendToClient: false,
  onSendToClientChange: () => null
};

export default Footer;

import { useSelector, useDispatch } from "react-redux";
import { ChildGeneralLedger, FiscalPeriodProps, PrimaryLedger } from "../interfaces/Accounts";
import { getCurrentSubscription } from "../slices/subscriptionSlice";
import { RootState } from "../store";
import { useFiscalPeriod } from "./query/useFiscalPeriods";

const useIsAccountSubscribed = (): boolean => {
  const dispatch = useDispatch();
  const subscription = useSelector((state: RootState) => state.subscriptions.currentSubscription);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  if (!subscription && rcId) {
    dispatch(getCurrentSubscription(rcId));
  }
  return subscription?.features?.account?.subscribed || false;
};
export default useIsAccountSubscribed;

export const useCurrentActiveFiscalPeriod = (): FiscalPeriodProps | null => {
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const { data = [] } = useFiscalPeriod(resourceCentreId);
  return [...data].sort((a, b) => a.id - b.id).find(({ allowPeriod }) => allowPeriod) || null;
};

export const extractAdministrativeAndOtherExp = (
  ledgerGroups: PrimaryLedger
): ChildGeneralLedger[] =>
  ledgerGroups?.secondaryLedgers
    .find((secondaryLg) => secondaryLg.code === "401")
    ?.accountingGroups.find((accGroup) => accGroup.code === "40105")?.childGeneralLedgers || [];

import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiltersInterface } from "../../../interfaces/AccountReportsInterface";
import { ChildGeneralLedger } from "../../../interfaces/Accounts";
import { getCurrentSubscription } from "../../../slices/subscriptionSlice";
import { RootState } from "../../../store";
import { getAllChildGlWithLedgerTypeFilter } from "../hooks";

export default function MultipleLedgerSelection({
  ledgerFilter,
  onChange,
  filters
}: {
  ledgerFilter?: string[];
  onChange: (ids: number[]) => void;
  filters: FiltersInterface;
}): JSX.Element {
  const [value, setValue] = React.useState<ChildGeneralLedger[]>([]);
  const childLedgers = ledgerFilter?.length
    ? getAllChildGlWithLedgerTypeFilter(ledgerFilter)
    : getAllChildGlWithLedgerTypeFilter();
  const { currentSubscription, rcId } = useSelector((state: RootState) => ({
    currentSubscription: state.subscriptions.currentSubscription,
    rcId: state.userContext.resourceCentreId
  }));
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!currentSubscription) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [currentSubscription, rcId, dispatch]);

  const foundLg = childLedgers.find((item) => item.id === Number(filters.ledgerIds[0])) || null;
  React.useEffect(() => {
    if (foundLg && filters.ledgerIds.length) {
      setValue([foundLg]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLg]);

  return (
    <Autocomplete
      multiple
      value={value}
      defaultValue={[foundLg] || null}
      onChange={(event, newValue) => {
        setValue(newValue);
        onChange(newValue.map(({ id }) => id));
      }}
      options={childLedgers}
      getOptionLabel={(option) => option.ledgerName}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.id}
            label={option.ledgerName}
            //   eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        //   eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} fullWidth label="Select ledgers" />
      )}
    />
  );
}

MultipleLedgerSelection.defaultProps = {
  ledgerFilter: null
};

import { IpdStats } from "../containers/IPD/BedManagement/BedStats";
import { IpdFilter } from "../containers/IPD/IpdList";
import {
  DischargeSummary,
  IMedicine,
  IPD,
  MaternityDischargeSummary
} from "../interfaces/IpdInterface";
import { ServiceInterface } from "../interfaces/ServiceInterface";
import { Delete, Get, Patch, Post } from "./apiHelper";
import { NextIpdNumber } from "../hooks/useGetNextIpdNumber";

const ipdRoot = "/api/ipd";

export async function getIpdRecords({
  filter,
  page,
  pageSize
}: {
  filter: IpdFilter;
  page: number | string;
  pageSize: number | string;
}): Promise<Array<IPD>> {
  const response = await Get(`${ipdRoot}?filter=${filter}&page=${page}&pageSize=${pageSize}`, true);
  return response.data as Array<IPD>;
}

export const getNextIpdNumber = async (): Promise<NextIpdNumber> => {
  const response = await Get(`${ipdRoot}/getNextIpdNumber`, true);
  return response.data as NextIpdNumber;
};

export async function createIpdRecord(ipdData: Partial<IPD>): Promise<IPD> {
  const response = await Post(`${ipdRoot}`, ipdData, true);
  return response.data as IPD;
}
export async function getIpdtDetails(id: number): Promise<IPD> {
  const response = await Get(`${ipdRoot}/${id}`, true);
  return response.data as IPD;
}
export async function updateIpdRecord(id: number, ipdData: IPD): Promise<IPD> {
  const response = await Patch(`${ipdRoot}/${id}`, ipdData, true);
  return response.data as IPD;
}

export async function deleteIpdClient(id: number): Promise<IPD> {
  const response = await Delete(`${ipdRoot}/${id}`, true);
  return response.data as IPD;
}

export async function createDischargeSummary(
  id: number,
  ipdData: DischargeSummary | MaternityDischargeSummary
): Promise<IPD> {
  const response = await Patch(`${ipdRoot}/${id}/discharge`, ipdData, true);
  return response.data as IPD;
}

export async function createProgressRecord(id: number, ipdData: IPD): Promise<IPD> {
  const response = await Patch(`${ipdRoot}/${id}/progressRecord`, ipdData, true);
  return response.data as IPD;
}

export async function prescribedMedications(
  id: number,
  medications: Array<IMedicine>
): Promise<IPD> {
  const response = await Patch(`/api/ipd/${id}/prescribedMedication`, medications, true);
  return response.data as IPD;
}

export async function getActiveIPD(): Promise<IPD> {
  const response = await Get(`${ipdRoot}/active`, true);
  return response.data as IPD;
}

export async function getIpdStatsForToday(query: {
  from: string;
  until: string;
}): Promise<IpdStats> {
  const response = await Get(`${ipdRoot}/keyStats?from=${query.from}&until=${query.until}`, true);
  return response.data as IpdStats;
}

export async function getIpdDetailsByClientId(clientId: number): Promise<IPD> {
  const response = await Get(`${ipdRoot}/getIpdDetailsByClientId/${clientId}`, true);
  return response.data as IPD;
}

export async function getIpdProducts(q: string): Promise<Array<ServiceInterface>> {
  const response = await Get(`${ipdRoot}/search-products?key=${q || ""}`, true);
  return response.data as Array<ServiceInterface>;
}

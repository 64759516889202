import * as React from "react";
import * as moment from "moment";
import { Box, List, ListItem, CircularProgress, Typography } from "@mui/material";
import { getHistory } from "../../api/history";
import { tl } from "../../components/translate";
import styles from "./styles.module.css";
import ActivityTimeline from "../../components/ActivityTimeline/ActivityTimeline";

interface EventsPropsInterface {
  items: Array<EventItemInterface>;
  loading: boolean;
}
interface EventItemInterface {
  // eslint-disable-next-line camelcase
  created_at: string;
  entity: string;
  entityId: number;
  event: string;
  id: number;
  username: string;
}

interface HistoryItemsPropsInterface {
  entity: string;
  entityId: number;
}

export const Events = ({ items, loading }: EventsPropsInterface): JSX.Element =>
  loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <List classes={{ root: styles.list }} component="div">
      <Box fontSize="12px" fontWeight={600}>
        {tl("activitiesHistory")}
      </Box>
      {!items.length && <Box fontSize="12px"> ... no history items</Box>}
      {items.map((item) => (
        <ListItem key={item.id} classes={{ root: styles.listItem }}>
          {moment(item.created_at).format("YYYY-MM-DD")}{" "}
          {tl(`historyEvent.${item.entity}.${item.event}`)} by {item.username}
        </ListItem>
      ))}
    </List>
  );

const HistoryItems = ({ entity, entityId }: HistoryItemsPropsInterface): JSX.Element => {
  const [events, setEvents] = React.useState([]);
  React.useEffect(() => {
    getHistory({ entity, entityId }).then((data) => {
      setEvents(data);
    });
  }, [entity, entityId]);
  return (
    <Box>
      <Typography my={1}>Activities History</Typography>
      <ActivityTimeline activities={events} />
    </Box>
  );
};

export default HistoryItems;

import { Box, Link, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import startCase from "lodash/startCase";
import produce from "immer";
import { round } from "lodash";
import { notificationAdd } from "../../actions/notification";
import * as reportsApi from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import useGetDepartments from "../../hooks/department";
import useIpdBeds from "../../hooks/useIpdBeds";
import { VisitType } from "../../interfaces/BillInterfaces";
import { ServiceType } from "../../interfaces/ProductInterface";
import BillShow from "../Billing/BillShow/BillShow";
import Can from "../Policy/Can";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import Filters, { BILLING_SOURCES } from "./Filters";
import styles from "./Reports.module.css";
import "./Reports.scss";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  salesByServiceDefaultCols,
  salesByServiceReportHiddenCols,
  getDefaultKeyValuesColumns
} from "../../components/ListHeaderShowHideDialog/helpers";
import { isSelfDataAccessEmployee } from "../NavigationBar/SideBar";
import { RootState } from "../../store";
import useDepartmentSubscription from "../../hooks/useDepartmentSubscription";
import { getCustomerNumber } from "../Client/ClientList";
import { getResourceCentreName } from "./DueReport";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const or = (val) => val || "";

const docColumns = (isBranchReport) => [
  ...(isBranchReport ? [t("reports.resourceCentreName")] : []),
  t("reports.billingDateBS"),
  t("reports.billingDateAD"),
  t("reports.billedBy"),
  t("reports.packageName"),
  t("reports.productCategory"),
  t("reports.productName"),
  t("reports.source"),
  t("reports.originalPackageProductPrice"),
  t("reports.billNumber"),
  t("reports.quantity"),
  t("reports.price"),
  t("reports.discountPercent"),
  t("reports.discountAmount"),
  t("reports.vatPercent"),
  t("reports.itemGrossTotal"),
  t("reports.billTotal"),
  t("reports.serviceProvider"),
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.clientDOB"),
  t("reports.clientEmail"),
  t("reports.clientPhone"),
  t("reports.department"),
  t("reports.admissionDate"),
  t("reports.dischargeDate"),
  t("reports.bed"),
  t("billItem.info")
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const expandPackage = (data) => {
  const expandedPackageData = [];
  data.forEach((item) => {
    if (!item.subItems) {
      expandedPackageData.push(item);
    } else {
      expandedPackageData.push(item);
      item.subItems.forEach((sItem) => {
        expandedPackageData.push(sItem);
      });
    }
  });
  return expandedPackageData;
};

// can be used for both excel and csv
// when using with csv, row data can be passed as param to returned function
// when using with excel, the returned function can be passed to formatDataForExcel function

const makeDocRowProcessor =
  (departments, isBranchReport) =>
  ({
    issueDate,
    packageName,
    productCategory,
    productName,
    billNumber,
    quantity,
    price,
    discountPercent,
    discountAmt,
    vatPercent,
    itemGrossTotal,
    billTotal,
    serviceProvider,
    customerNumber,
    clientFirstName,
    clientLastName,
    clientName,
    clientDob,
    clientEmail,
    clientPhone,
    enteredBy,
    departmentId,
    originalPricePerUnit,
    admissionDate,
    dischargeDate,
    bedName,
    source,
    info,
    resourceCentreName,
    // eslint-disable-next-line camelcase
    __meta__row_type
  }) => {
    const departmentName =
      departments.find((department) => department.id === departmentId)?.name || "";
    // eslint-disable-next-line camelcase
    if (__meta__row_type === "segment_summary") return;
    // eslint-disable-next-line consistent-return
    return [
      ...(isBranchReport ? [resourceCentreName] : []),
      issueDate ? calFns.bsShortDate(issueDate) : "",
      issueDate ? calFns.adShortDate(issueDate) : "",
      or(enteredBy),
      packageName || "N/A",
      or(productCategory),
      or(productName),
      source,
      or(originalPricePerUnit),
      or(billNumber),
      quantity,
      price,
      or(discountPercent),
      or(discountAmt),
      or(vatPercent),
      or(itemGrossTotal),
      or(billTotal),
      or(serviceProvider),
      or(getCustomerNumber(customerNumber)),
      or(clientName),
      or(clientFirstName),
      or(clientLastName),
      or(clientDob),
      or(clientEmail),
      or(clientPhone),
      departmentName,
      admissionDate ? convertADtoBS(new Date(admissionDate)).formatted4 : "",
      dischargeDate ? convertADtoBS(new Date(dischargeDate)).formatted4 : "",
      bedName,
      info
    ];
  };

const selectData = (item, index, beds, resourceCentres) => ({
  ...(item.serviceProvider
    ? {
        serviceProvider: `${capitalize(or(item.serviceProvider?.firstName))} ${capitalize(
          or(item.serviceProvider?.lastName)
        )}`
      }
    : {}),
  clientName: `${capitalize(item.clientFirstName)} ${capitalize(item.clientLastName)}`,
  clientId: item.clientId,
  customerNumber: item.customerNumber,
  clientFirstName: item.clientFirstName,
  clientLastName: item.clientLastName,
  clientDob: item.clientDob,
  clientEmail: item.clientEmail,
  clientPhone: item.clientPhone,
  serviceId: item.serviceId,
  issueDate: item.issueDate,
  billId: item.billId,
  billNumber: item.billNumber,
  delivered: item.delivered,
  quantity: item.quantity,
  price: item.price,
  discountPercent: item.discountPercent,
  discountAmt: item.discountAmt,
  vatPercent: item.vatPercent,
  vatAmt: item.vatAmt,
  itemGrossTotal: item.itemGrossTotal,
  billTotal: item.billTotal,
  subItems: item.subItems?.map((sItem) => {
    const serviceProvider = `${capitalize(or(sItem.serviceProvider?.firstName))} ${capitalize(
      or(sItem.serviceProvider?.lastName)
    )}`;
    return { ...sItem, serviceProvider };
  }),
  packageName: item.packageName,
  productCategory: item.productCategory,
  productName: item.productName,
  unit: item.unit,
  departmentId: item.departmentId,
  id: index,
  enteredBy: item.enteredBy,
  originalPricePerUnit: item.originalPricePerUnit,
  createdAt: item.createdAt,
  admissionDate: item.admissionDate,
  dischargeDate: item.dischargeDate,
  source: item.source,
  bedId: item.bedId,
  bedName: beds.find((bed) => bed.id === item.bedId)?.name || "",
  info: item.info || "",
  resourceCentreId: item.resourceCentreId,
  resourceCentreName: getResourceCentreName(resourceCentres, item.resourceCentreId)
});

const showDepartmentShortCode = (departments, departmentId) => {
  const relatedDepartment = departments.find((department) => department.id === departmentId);
  if (relatedDepartment) return relatedDepartment.shortCode;
  return "";
};
const processData = (data, beds, resourceCentres) =>
  data.map((item, index) => selectData(item, index, beds, resourceCentres));

export interface BranchReportProps {
  isBranchReport?: boolean;
}

const SalesByServiceReport = ({ isBranchReport = false }: BranchReportProps): JSX.Element => {
  const deptId = useSelector((state: RootState) => state.userContext.user.departmentId);
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentre?.id);
  const isCounterBillingEnabled =
    useSelector(
      (state: RootState) =>
        state.userContext.resourceCentre?.settings?.billingSettings?.enableCounterBilling
    ) || false;
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    productCategory: null,
    serviceType: null,
    ...(isBranchReport
      ? { resourceCentreIds: resourceCentreId ? [resourceCentreId] : [] }
      : { serviceIds: [], employeeIds: [], departmentId: deptId || null }),
    includeDrafts: false,
    visitType: VisitType.OPD,
    billingSource: BILLING_SOURCES.SERVICES,
    ...(isCounterBillingEnabled ? { counter: "" } : {})
  });
  const resourceCentres = useSelector((state: RootState) => state.userBranch.collection) || [];
  const [reportData, setReportData] = React.useState([]);
  const beds = useIpdBeds();
  const { isDepartmentSubscribed } = useDepartmentSubscription();

  React.useEffect(() => {
    reportsApi.salesByServiceReport(filters).then((data) => {
      setReportData(processData(data, beds, resourceCentres));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, resourceCentres]);
  const departments = useGetDepartments({
    showDeletedDepartments: true
  });

  const applyFilters = (f: typeof filters) => {
    const filterProps = produce(f, (draft) => {
      if (draft.serviceType === ServiceType.package) {
        draft.productCategory = null;
      }
      if (draft.billingSource === BILLING_SOURCES.All) {
        draft.billingSource = null;
      }
    });
    setFilters(filterProps);
  };

  const [selectedBill, setSelectedBill] = React.useState(null);
  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(
      [...salesByServiceDefaultCols, ...(isBranchReport ? ["resourceCentre"] : [])],
      salesByServiceReportHiddenCols,
      !isDepartmentSubscribed
    )
  );
  const [open, setOpen] = React.useState(false);

  const userContext = useSelector((state: RootState) => state.userContext);
  const permissionGroup =
    userContext && userContext.userCreds.userGroups && userContext.userCreds.userGroups[0];

  const hideEmployeeSelect = isSelfDataAccessEmployee(permissionGroup);

  const dispatch = useDispatch();
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:salesByServiceReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1100px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              filters={filters}
              onSetFilters={(f) => applyFilters(f)}
              disableDepartment={!!deptId}
              hideEmployeeSelect={hideEmployeeSelect}
            />
          </Box>
          <Box
            className={["salesByServiceReportList", styles.salesByServicesReportListStyles].join(
              " "
            )}
            marginTop="32px"
          >
            <List
              automation="salesReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="billingDate"
              defaultSortOrder={-1}
              activeRow={0}
              adjustHeightToContents
              columns={[
                ...(isBranchReport
                  ? [
                      {
                        key: "resourceCentre",
                        label: "Branch",
                        formatter: ({ resourceCentreName }) => (
                          <Typography>{resourceCentreName}</Typography>
                        ),
                        segmentable: true,
                        segmentBy: (row) => row.resourceCentreName,
                        sortable: true,
                        sortFunction: (a, b) =>
                          or(a.resourceCentreName).toLowerCase() >
                          or(b.resourceCentreName).toLowerCase()
                            ? 1
                            : -1
                      }
                    ]
                  : []),
                {
                  key: "productName",
                  label: tl("reports.productName"),
                  sortable: true,
                  segmentable: true,
                  segmentBy: (row) => row.productName,
                  sortFunction: (a, b) =>
                    or(a.productName).toLowerCase() > or(b.productName).toLowerCase() ? 1 : -1,
                  formatter: ({ productName }) => (
                    <Typography
                      title={productName}
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      {productName}
                    </Typography>
                  )
                },
                {
                  key: "productCategory",
                  label: tl("reports.productCategory"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.productCategory).toLowerCase() > or(b.productCategory).toLowerCase()
                      ? 1
                      : -1,
                  formatter: ({ productCategory }) => <Typography>{productCategory}</Typography>
                },
                {
                  key: "packageName",
                  label: tl("reports.packageName"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.packageName).toLowerCase() > or(b.packageName).toLowerCase() ? 1 : -1,
                  formatter: ({ packageName }) => <Typography>{packageName || "-"}</Typography>
                },
                {
                  key: "billingDate",
                  label: tl("reports.billingDate"),
                  formatter: ({ issueDate, createdAt }) => (
                    <div>
                      <Typography>{calFns.bsFullDate(issueDate)}</Typography>
                      {createdAt && !moment(issueDate).isSame(createdAt, "day") && (
                        <Typography fontSize="0.8rem" color="gray">
                          {calFns.bsFullDate(createdAt)}
                        </Typography>
                      )}
                    </div>
                  ),
                  sortable: true,
                  sortFunction: (a, b) => (new Date(a.issueDate) > new Date(b.issueDate) ? 1 : -1)
                },
                {
                  key: "billInfo",
                  label: tl("reports.billNumber"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.billNumber).toLowerCase() > or(b.billNumber).toLowerCase() ? 1 : -1,
                  formatter: ({ billId, billNumber }) =>
                    isBranchReport ? (
                      <Typography>{billNumber}</Typography>
                    ) : (
                      <Link onClick={() => setSelectedBill(billId)}>
                        <Typography>{billNumber}</Typography>
                      </Link>
                    )
                },
                {
                  key: "department",
                  label: tl("reports.department"),
                  formatter: ({ departmentId }) => (
                    <Typography>{showDepartmentShortCode(departments, departmentId)}</Typography>
                  )
                },
                {
                  key: "source",
                  label: tl("reports.source"),
                  formatter: ({ source }) => <Typography>{startCase(source)}</Typography>
                },
                {
                  key: "enteredBy",
                  label: tl("reports.billedBy"),
                  formatter: ({ enteredBy }) => <Typography>{enteredBy}</Typography>
                },
                {
                  key: "quantity",
                  label: tl("reports.quantity"),
                  sortable: true,
                  formatter: ({ quantity, unit }) => {
                    const qty = unit ? `(${unit})` : "";
                    return <Typography>{`${quantity} ${qty}`}</Typography>;
                  }
                },
                {
                  key: "price",
                  label: tl("reports.price"),
                  sortable: true,
                  sortFunction: (a, b) => (a.price > b.price ? 1 : -1),
                  formatter: ({ price }) => <Typography>{price}</Typography>
                },
                {
                  key: "clientFullName",
                  label: tl("reports.client"),
                  formatter: ({ clientFirstName, clientLastName }) => (
                    <Typography>
                      {clientFirstName} {clientLastName}
                    </Typography>
                  )
                },
                {
                  key: "serviceProvider",
                  label: tl("reports.serviceProvider"),
                  formatter: ({ serviceProvider }) => <Typography>{serviceProvider}</Typography>
                },
                {
                  key: "info",
                  label: tl("billItem.info"),
                  formatter: ({ info }) => <Typography>{info}</Typography>
                },
                {
                  key: "billTotal",
                  label: tl("reports.billTotal"),
                  sortable: true,
                  sortFunction: (a, b) => (a.billTotal > b.billTotal ? 1 : -1),
                  formatter: ({ billTotal }) => <Typography>{billTotal}</Typography>
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(item) => (
                <Box
                  sx={{
                    background: "#e6e6e6",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pl: 2.5,
                    pr: 3.5
                  }}
                >
                  <Typography>{item.segment || "N/A"}</Typography>
                  <Typography>
                    {`${rupeeDisplay(
                      round(item.rows.reduce((acc, cur) => acc + Number(cur.billTotal), 0) || 0, 2)
                    )} (${item.rows.length})`}
                  </Typography>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            `${t("reports.salesByServiceReport")}-${
                              calFns.convertADtoBS(filters.from).formatted
                            }-${calFns.convertADtoBS(filters.until).formatted}`,
                            formatHeaderForExcel(docColumns(isBranchReport)),
                            formatDataForExcel(
                              getProcessedData(),
                              makeDocRowProcessor(departments, isBranchReport)
                            )
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="230px" justifyContent="flex-end" paddingRight="30px">
              <Typography>
                {rupeeDisplay(
                  Number.parseFloat(
                    reportData.reduce((acc, cur) => acc + cur.itemGrossTotal, 0)
                  ).toFixed(2) || 0
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        {selectedBill && (
          <BillShow
            billId={selectedBill}
            handleViewClose={() => setSelectedBill(null)}
            hideActions
          />
        )}
      </Box>
    </Can>
  );
};

export default SalesByServiceReport;

import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Box, Button, Link, Typography } from "@mui/material";
import * as moment from "moment-timezone";
import { useHotkeys } from "react-hotkeys-hook";
import "./StockList.scss";
import { match as MatchProps } from "react-router";
import { useState } from "react";
import { tl } from "../../components/translate";
import * as stockActions from "../../actions/stock";
import { supplierActions } from "../../actions";
import EntryButton from "./EntryButton";
import { suppliersSortedSelector } from "../../reducers/suppliers";
import List, { Filters, MultipleHeader, MultipleHeadersInterface } from "../../components/List";
import StockProductEdit from "./StockCreateEdit/StockProductEdit";
import CreateStockProduct, {
  DefaultInternalUseCategory,
  ExtraFilters,
  SellableProductCategory
} from "./StockCreateEdit/CreateStockProduct";
import Panel from "../../components/Panel";
import { ListActions, Menu, MenuItem } from "../../components/OList";
import CsvUploader from "../../components/CsvUploader/CsvUploader";
import { productImportColumns } from "../../components/CsvUploader/csvHelpers";
import * as NotificationActions from "../../actions/notification";
import {
  batchUploadStock,
  createKitchenPharmacyProduct,
  getStockProducts as fetchStockProducts
} from "../../api/stockProducts";
import { Stock, StockProducts, Supplier } from "../../interfaces/StockInterfaces";
import { QueryProps } from "../../interfaces/ProductInterface";
import Can from "../Policy/Can";
import StockRecordPayment from "./StockTransactions/StockRecordPayment";
import PageControl from "../../components/PageControl";
import { RootState } from "../../store";
import ProductCreate from "../Services/ProductCreate";
import useGetDepartments from "../../hooks/department";
import { downloadExcel } from "../../helpers/files";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import { getStockProducts } from "../../actions/stockProductActions";
import { translate as t } from "../../../translations/translate";
import { useAppSelector } from "../../store/hooks";
import ProductSearchBar, { PRODUCT_SEARCH_BY } from "./ProductSearchBar";
import StockProductDetails from "./StockProductDetails";
import useKitchenPharmacy from "../../hooks/useKitchenPharmacy";
import { centralStoreHeaders } from "./CentralStore";
import { CentralStoreTabs } from "../../actions/stock";

function downloadStockUploadSample() {
  window.open(
    "https://drive.google.com/file/d/1f4Hn26cgnHhMckxcV73ZW-JRRsXsauL1/view?usp=sharing",
    "_blank"
  );
}

interface StockInterfaceProps {
  suppliers: Supplier;
  stockProducts: Array<StockProducts>;
  actions: {
    navigateTo: (url: string) => void;
    loadStockProducts: (query: QueryProps) => void;
    goto: (url: string) => void;
    clearEntry: () => void;
    createSuccessNotification: () => void;
  };
  id: number;
  isInternal: boolean;
  match: unknown;
  isKitchenPharmacyTab?: boolean;
}

function getTotalQuantity(stocks: Array<Stock>) {
  return stocks.reduce((acc, cur) => acc + Number(cur.quantity), 0);
}

const docColumns = [
  t("Product Name"),
  t("Product Type"),
  t("Category"),
  t("Unit"),
  t("reports.packageName"),
  t("reports.unitsPerPackage"),
  t("products.unitPriceExcVAT"),
  t("products.unitPriceIncVAT"),
  t("products.packagePriceExcVat"),
  t("products.packagePriceIncVat"),
  t("products.vatPct"),
  t("products.minStockQty"),
  t("products.priceCalcOn"),
  t("products.purchasePricePerUnit"),
  t("products.purchasePriceCalculationOn"),
  t("products.isNarcotics"),
  t("products.productCode"),
  t("products.qty")
];

const docRowProcessor = ({
  name,
  productType,
  unit,
  package: pkg,
  unitsPerPackage,
  productCode,
  structuredInfo,
  stocks,
  category,
  unitPriceExcVAT,
  unitPriceIncVAT,
  vatPct,
  packagePriceExcVAT,
  packagePriceIncVAT,
  minStockQuantity,
  priceCalculationOn,
  purchasePricePerUnit,
  purchasePriceCalculationOn
}) => [
  name,
  productType,
  category,
  unit,
  pkg,
  unitsPerPackage,
  unitPriceExcVAT || 0,
  unitPriceIncVAT || 0,
  packagePriceExcVAT || 0,
  packagePriceIncVAT || 0,
  vatPct || 0,
  minStockQuantity,
  priceCalculationOn,
  purchasePricePerUnit || 0,
  purchasePriceCalculationOn,
  structuredInfo?.isNarcotics ? "Yes" : "No",
  productCode || "N/A",
  getTotalQuantity(stocks || [])
];

export function getStyleBasedOnExpiryDate(
  expiryDate: string
): Record<string, never> | { color: string } {
  if (moment().diff(expiryDate, "months", true) > 0) {
    return { color: "red" };
  }
  if (moment().diff(expiryDate, "months", true) > -2) {
    return { color: "#FF8C00" };
  }
  return {};
}

function getStyleBasedOnStockQuantity(minimumStock: number, quantity: number) {
  if (quantity <= minimumStock) {
    return { color: "red" };
  }
  return {};
}

function getBatchWithMostRecentExpiryDate(stocks: Array<Stock>): string | null {
  let mostRecentExpiryDateWithQuantity: string | null = null;

  stocks.forEach((stock) => {
    if (Number(stock.quantity) > 0) {
      if (
        !mostRecentExpiryDateWithQuantity ||
        new Date(stock.expiryDate) < new Date(mostRecentExpiryDateWithQuantity)
      ) {
        mostRecentExpiryDateWithQuantity = stock.expiryDate;
      }
    }
  });

  return mostRecentExpiryDateWithQuantity;
}

function expirySort(a, b) {
  if (a.expiryDate === b.expiryDate) return 0;
  if (a.expiryDate === null) return 1;
  if (b.expiryDate === null) return -1;
  return new Date(a.expiryDate).getMilliseconds() < new Date(b.expiryDate).getMilliseconds()
    ? -1
    : 1;
}

function exposeKeys(list) {
  if (!list?.length) return [];
  return list
    .map((item) => {
      const expiryDate = getBatchWithMostRecentExpiryDate(item.stocks);
      return {
        ...item,
        expiryDate,
        genericName: item.structuredInfo?.genericName || ""
      };
    })
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
}

const filterData = (isInternal) => ({
  filters: isInternal
    ? [
        { key: ExtraFilters.All, title: tl(`stock.all`) },
        { key: DefaultInternalUseCategory.Stationery, title: tl(`stock.stationery`) },
        { key: DefaultInternalUseCategory.MedicalKits, title: tl(`stock.medicalkits`) },
        { key: DefaultInternalUseCategory.LabAccessories, title: tl(`stock.labAccessories`) },
        { key: ExtraFilters.Other, title: tl(`stock.other`) }
      ]
    : [
        { key: ExtraFilters.All, title: tl(`stock.all`) },
        { key: SellableProductCategory.Medicine, title: tl(`stock.medicine`) },
        { key: SellableProductCategory.Suppliments, title: tl(`stock.suppliments`) },
        { key: SellableProductCategory.Kits, title: tl(`stock.kits`) },
        { key: SellableProductCategory.Derma, title: tl(`stock.derma`) },
        { key: SellableProductCategory.Surgical, title: tl(`stock.surgical`) },
        { key: SellableProductCategory.Hair, title: tl(`stock.hair`) },
        { key: ExtraFilters.Other, title: tl(`stock.other`) }
      ]
});

export const multipleHeaders = (
  match: MatchProps,
  isKitchenPharmacy: boolean,
  isCentralStore: boolean
): MultipleHeadersInterface => ({
  headers: [
    { key: "stockProducts", title: tl(`stock.products`), goto: "/stock/stockProducts" },
    // { key: "stockServices", title: tl("stock.services"), goto: "/stock/stockServices" },
    { key: "stockInternal", title: tl(`stock.internalInventory`), goto: "/stock/stockInternal" },
    {
      key: "stockTransactions",
      title: tl(`stock.transactions`),
      goto: "/stock/stockTransactions"
    },
    ...(isCentralStore
      ? [
          {
            key: "stockTransfers",
            title: "Request From Outlets",
            goto: "/stock/stockTransfers"
          }
        ]
      : []),
    ...(isKitchenPharmacy
      ? [
          {
            key: "kitchenPharmacy",
            title: "Central Store",
            goto: "/stock/kitchenPharmacy"
          }
        ]
      : [])
  ],
  url: match.url
});

const StockList = ({
  actions,
  stockProducts,
  suppliers,
  isInternal = false,
  id,
  match,
  isKitchenPharmacyTab = false
}: StockInterfaceProps) => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [query, setQuery] = React.useState<QueryProps>({
    page: 0,
    pageSize: 50,
    search: "",
    filter: ExtraFilters.All,
    productType: isInternal ? 2 : 1,
    intangible: false,
    searchBy: PRODUCT_SEARCH_BY.DEFAULT,
    withQuantity: true,
    isKitchenPharmacyBranch: isKitchenPharmacyTab
  });
  const [searchLoading, setSearchLoading] = useState(false);

  const totalNoOfProducts = useSelector((state: RootState) => state.stockProducts.total);
  const supplierList = useAppSelector((state) => state.resources.suppliers);

  const isRcKitchenPharmacy = Boolean(useKitchenPharmacy());
  const isCentralStore =
    useAppSelector((state) => state.userContext.resourceCentre?.isKitchenPharmacy) || false;
  React.useEffect(() => {
    actions.clearEntry();
    if (!supplierList.length) {
      dispatch(supplierActions.getSuppliers());
    }
  }, [actions]);

  React.useEffect(() => {
    setSelectedRow(stockProducts?.find((item) => item.id === Number(id)));
  }, [id, stockProducts]);

  const [filter, setFilter] = React.useState(ExtraFilters.All);
  const [showCreatePanel, setShowCreatePanel] = React.useState(false);
  const [showCreateServicePanel, setShowCreateServicePanel] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState<Array<number>>([]);

  React.useEffect(() => {
    (async () => {
      if (query.search.length > 2 || query.search.length === 0) {
        setSearchLoading(true);
        await actions.loadStockProducts({ ...query, filter });
        setSearchLoading(false);
      }
    })();
  }, [query, actions]);

  const requiredFieldInfoText =
    "Note: Product Name, Unit, Package, Product Type are required fields.";

  const [showRecordPayment, setShowRecordPayment] = React.useState<boolean>(false);

  useHotkeys(
    "alt + i",
    () => {
      actions.navigateTo("/stock/purchase");
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"]
    }
  );

  const departments = useGetDepartments();
  const departmentSubscription = useSelector(
    (state: RootState) =>
      state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
  );

  const onCreatePharmacyProduct = async () => {
    try {
      await createKitchenPharmacyProduct({
        productIds: selectedProducts
      });
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: `${selectedProducts.length} products created from kitchen pharmacy.`,
          autoTimeout: true
        })
      );
      dispatch(push("/stock/stockProducts"));
    } catch (err) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err?.data?.message || "Failed to create pharmacy products",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Box className="stockList">
      <Box height="100%">
        <List
          multiSelectable={isKitchenPharmacyTab}
          onMultiSelect={(ids) => setSelectedProducts(ids.map(Number))}
          multiSelectContextHeader={
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Typography>
                <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onCreatePharmacyProduct()}
                    data-testmation="batchCreateSelected"
                  >
                    Create Selected
                  </Button>
                  <Box marginLeft="16px">({selectedProducts.length} selected items)</Box>
                </Box>
              </Typography>
            </Box>
          }
          additionalHeaderFilters={
            <ProductSearchBar
              value={query.search || ""}
              loading={searchLoading}
              onLoadingChange={(value) => setSearchLoading(value)}
              onSearchByChange={(value) => setQuery({ ...query, searchBy: value })}
              onInputChange={(value) => {
                if (!value || value.length > 2) {
                  setQuery({ ...query, search: value || "", page: 0 });
                }
              }}
            />
          }
          withoutSearch
          isResponsive
          additionalHeaderContent={
            <Box display="flex" flexDirection="column">
              {isKitchenPharmacyTab && (
                <MultipleHeader
                  multipleHeaders={centralStoreHeaders(CentralStoreTabs.PRODUCTS)}
                  useAsSubHeader
                />
              )}
              <Filters
                filter={filter}
                onFilter={(v) => {
                  setFilter(v);
                  setQuery({ ...query, filter: v });
                }}
                filterData={filterData(isInternal)}
              />
            </Box>
          }
          multipleHeaders={multipleHeaders(match, isRcKitchenPharmacy, isCentralStore)}
          rowHeight={40}
          customCreateButton={
            <EntryButton
              navigateTo={actions.navigateTo}
              suppliers={suppliers}
              setShowCreatePanel={setShowCreatePanel}
              setShowRecordPayment={setShowRecordPayment}
              setShowCreateServicePanel={setShowCreateServicePanel}
            />
          }
          data={exposeKeys(stockProducts) || []}
          onRowClick={({ id: datumId }) => {
            if (!(selectedRow?.id === datumId)) {
              if (isKitchenPharmacyTab) {
                actions.navigateTo(`/stock/kitchenPharmacy/${datumId}`);
              } else {
                actions.navigateTo(
                  isInternal ? `/stock/stockInternal/${datumId}` : `/stock/stockProducts/${datumId}`
                );
              }
            } else if (isKitchenPharmacyTab) {
              actions.navigateTo(`/stock/kitchenPharmacy`);
            } else {
              actions.navigateTo(isInternal ? "/stock/stockInternal" : "/stock/stockProducts");
            }
          }}
          columns={[
            {
              key: "name",
              label: `${t("Stock.name")}`,
              sortable: true,
              formatter: ({ name, structuredInfo }) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    ...(isKitchenPharmacyTab
                      ? {
                          flexGrow: 1,
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          height: "100%"
                        }
                      : {})
                  }}
                >
                  <Typography>{(name || "").split("", 50).join("")}</Typography>
                  {structuredInfo?.genericName && (
                    <Typography style={{ fontSize: "0.65rem", color: "grey" }}>
                      {structuredInfo.genericName}
                    </Typography>
                  )}
                </Box>
              )
            },
            ...(!isKitchenPharmacyTab
              ? [
                  {
                    key: "quantity",
                    label: `${t("Stock.quantity")}`,
                    formatter: ({ stocks, minStockQuantity }) => (
                      <Typography
                        style={getStyleBasedOnStockQuantity(
                          Number(minStockQuantity),
                          getTotalQuantity(stocks)
                        )}
                      >
                        {getTotalQuantity(stocks)}
                      </Typography>
                    )
                  }
                ]
              : []),
            ...(!isInternal
              ? [
                  {
                    key: "unit",
                    label: `${t("Stock.unit/package")}`,
                    formatter: ({ unit, package: p, unitsPerPackage }) => (
                      <Typography>{`${unitsPerPackage} ${unit}/${p} `}</Typography>
                    )
                  },
                  {
                    key: "structuredInfo",
                    hideInNarrowView: true,
                    label: `${t("Stock.IsNarcotics")}`,
                    formatter: ({ structuredInfo }) => (
                      <Typography>{`${structuredInfo?.isNarcotics ? "Yes" : "No"} `}</Typography>
                    )
                  }
                ]
              : []),
            {
              key: "expiryDate",
              label: `${t("Stock.ExpiryDate")}`,
              key: "expiryDate",
              label: "Expiry Date",
              sortable: true,
              sortFunction: expirySort,
              formatter: ({ expiryDate }) => (
                <Typography style={getStyleBasedOnExpiryDate(expiryDate)}>
                  {expiryDate ? moment(expiryDate).format("LL") : "-"}
                </Typography>
              )
            },
            {
              key: "productCode",
              label: `${t("Stock.ProductCode")}`,
              hideInNarrowView: true,
              formatter: ({ productCode }) => <Typography>{productCode}</Typography>
            },
            ...(departmentSubscription
              ? [
                  {
                    key: "department",
                    label: `${t("Stock.Department")}`,
                    hideInNarrowView: true,
                    formatter: ({ departmentId }) => (
                      <Typography>
                        {departments.find((dept) => dept.id === departmentId)?.name || ""}
                      </Typography>
                    )
                  }
                ]
              : []),
            ...(!isKitchenPharmacyTab
              ? [
                  {
                    key: "kitchenPharmacy",
                    label: "Is Central Store Product",
                    formatter: ({ kitchenPharmacyProductId }) => (
                      <Typography>{`${kitchenPharmacyProductId ? "Yes" : "No"} `}</Typography>
                    )
                  }
                ]
              : ([] as ColumnType<unknown>))
          ]}
        >
          <ListActions>
            {() => (
              <Menu>
                <Can policyAccessKey="stock:importCSV">
                  <CsvUploader
                    buttonText="Upload stocks (.csv)"
                    columns={productImportColumns}
                    requiredFieldInfoText={requiredFieldInfoText}
                    createDataApi={batchUploadStock}
                    useBatchUpload
                    runAfterSave={() => actions.loadStockProducts(query)}
                    renderAdditionalInfo={() => (
                      <Typography
                        style={{
                          cursor: "pointer",
                          position: "fixed",
                          right: 20
                        }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={downloadStockUploadSample}>{tl("stock.viewSample")}</Link>
                      </Typography>
                    )}
                  />
                  <MenuItem
                    onClick={async () => {
                      try {
                        const productData = await fetchStockProducts();
                        await downloadExcel(
                          "Product-List",
                          formatHeaderForExcel(docColumns),
                          formatDataForExcel(productData, docRowProcessor)
                        );
                      } catch (err) {
                        dispatch(
                          NotificationActions.notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: "Failed to download Excel.",
                            autoTimeout: true
                          })
                        );
                      }
                    }}
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                  <MenuItem onClick={downloadStockUploadSample}>{tl("stock.viewSample")}</MenuItem>
                </Can>
              </Menu>
            )}
          </ListActions>
        </List>

        {selectedRow && !isKitchenPharmacyTab && (
          <StockProductEdit
            wrapperStyle={{}}
            handleClose={() =>
              actions.navigateTo(isInternal ? "/stock/stockInternal" : "/stock/stockProducts")
            }
            editMode
            data={selectedRow}
            id={id}
          />
        )}

        {selectedRow && isKitchenPharmacyTab && (
          <StockProductDetails
            id={id}
            selectedProduct={selectedRow}
            onClose={() => actions.navigateTo("/stock/kitchenPharmacy")}
          />
        )}

        {showCreatePanel && (
          <Panel onClose={() => setShowCreatePanel(false)} title="Create Product">
            <CreateStockProduct
              productName=""
              handleClose={() => setShowCreatePanel(false)}
              saveAction={() => {
                actions.createSuccessNotification();
                actions.loadStockProducts(query);
              }}
              mode="Create"
            />
          </Panel>
        )}
        {showCreateServicePanel && (
          <ProductCreate isAccount handleClose={() => setShowCreateServicePanel(false)} />
        )}

        <Box className="navigatePage">
          <PageControl
            page={query.page}
            pageSize={query.pageSize}
            onSetPage={(value) => setQuery({ ...query, page: value })}
            maximumDataCount={totalNoOfProducts}
          />
          {showRecordPayment && <StockRecordPayment onClose={() => setShowRecordPayment(false)} />}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  stockProducts: state.stockProducts.collection?.filter(
    (product) => !product.intangible && product.active
  ),
  suppliers: suppliersSortedSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    loadStockProducts: (query) => dispatch(getStockProducts(query)),
    goto: (url) => dispatch(push(url)),
    clearEntry: () => dispatch(stockActions.clearEntry()),
    createSuccessNotification: () =>
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Stock Transaction created.",
          autoTimeout: true
        })
      )
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StockList);

import { createSelector } from "reselect";
import produce from "immer";
import { ReducerBuilder } from "./ReducerBuilder";
import * as ClientActions from "../actions/client";
import { RootState } from "../store";
import { Client, ClientVisit } from "../interfaces/ClientInterface";
import { BillDocumentType } from "../interfaces/BillInterfaces";

const INITIAL_STATE = {
  collection: [],
  lastTouched: null,
  visits: [],
  bills: [],
  walkInCustomer: null
};

function setClients(state, payload) {
  return { ...state, collection: payload.results, total: payload.total || [] };
}

function setClient(state, payload) {
  if (!payload) return state;
  const newCollection = produce(state.collection, (draft) => {
    const index = draft.findIndex((client) => client.id === payload.id);
    if (index !== -1) draft[index] = payload;
    else {
      draft.push(payload);
    }
  });
  return { ...state, collection: newCollection, lastTouched: payload };
}

function setClientVisits(state, payload) {
  return { ...state, visits: payload };
}

function setClientBills(state, payload) {
  return { ...state, bills: payload };
}

function removeClient(state, id) {
  if (!id) return state;
  const newCollection = [...state.collection].filter((client) => client.id !== id);
  return { ...state, collection: newCollection };
}

function clearLastTouched(state) {
  return { ...state, lastTouched: null };
}

function uploadClientPicture(state, payload) {
  const updatedCollection = state.collection.map((item) => {
    if (item.id === payload.clientId) {
      return { ...item, customerDetails: { profileImageS3Url: payload.profileImageS3Url } };
    }
    return item;
  });
  return { ...state, collection: updatedCollection };
}

function setWalkInCustomer(state, payload) {
  return { ...state, walkInCustomer: payload[0] || { id: null } };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(ClientActions.Type.CLIENTS_GET, setClients)
  .mapAction(ClientActions.Type.CLIENT_GET, setClient)
  .mapAction(ClientActions.Type.CLIENT_CREATE, setClient)
  .mapAction(ClientActions.Type.CLIENT_UPDATE, setClient)
  .mapAction(ClientActions.Type.GET_CLIENT_VISITS, setClientVisits)
  .mapAction(ClientActions.Type.GET_CLIENT_BILLS, setClientBills)
  .mapAction(ClientActions.Type.CLIENT_DELETE, removeClient)
  .mapAction(ClientActions.Type.CLEAR_LAST_TOUCHED, clearLastTouched)
  .mapAction(ClientActions.Type.UPLOAD_CLIENT_PICTURE, uploadClientPicture)
  .mapAction(ClientActions.Type.WALK_IN_CUSTOMER_GET, setWalkInCustomer)
  .build();
export default reducer;

export const clientFullNameSelector = (client: Partial<Client>): string => {
  if (!client) return ``;
  const { firstName, lastName } = client;
  return `${firstName} ${lastName}`;
};

export const clientFullNameWithPhoneSelector = (client: Client): string =>
  `${clientFullNameSelector(client)} ${client.phone ? client.phone : ""}`;

export const clientsSelector = (state: RootState): Array<Client> => state.clients.collection;

export const activeClientsSelector = createSelector(clientsSelector, (clients) =>
  clients.filter((client) => client.active !== false)
);

export const clientsSortedSelector = createSelector(activeClientsSelector, (clients) =>
  clients
    ? clients.sort((a, b) =>
        `${a.firstName || ""}${a.lastName || ""}`.toLowerCase() >
        `${b.firstName || ""}${b.lastName || ""}`.toLowerCase()
          ? 1
          : -1
      )
    : []
);

export const clientVisitsSelector = (state: RootState, clientId: string): Array<ClientVisit> =>
  state.clients.visits.filter((visit) => visit.clientId === clientId);

export const clientVisitsSorted = createSelector(clientVisitsSelector, (visits) =>
  visits.sort((a, b) => new Date(b.from).getTime() - new Date(a.from).getTime())
);

export const clientBillsSelector = (state: RootState, clientId: string): Array<BillDocumentType> =>
  state.clients.bills.filter((bill) => bill.client.id === Number(clientId));

export const clientBillsSorted = createSelector(clientBillsSelector, (bills) =>
  bills.sort((a, b) => new Date(b.issueDate).getTime() - new Date(a.issueDate).getTime())
);

import { UseMutateFunction, useMutation, useQuery, useQueryClient } from "react-query";
import { getAccountingVoucherSeries, patchAccountingVoucherSeries } from "../../../../api/accounts";
import { AccountingVoucherSeriesInterface } from "../../../../interfaces/Accounts";
import { queryKeys } from "../../../../react-query/constants";

export default function useAccountingVoucherSeries(): AccountingVoucherSeriesInterface[] {
  const { data = [] } = useQuery(
    [queryKeys.account, "accountingVoucherSeries"],
    getAccountingVoucherSeries
  );
  return data as AccountingVoucherSeriesInterface[];
}

export function usePatchAccVoucherSeries(): UseMutateFunction<
  void,
  unknown,
  Partial<AccountingVoucherSeriesInterface>,
  unknown
> {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (voucher: Partial<AccountingVoucherSeriesInterface>) =>
      patchAccountingVoucherSeries(voucher.id, voucher),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.account, "accountingVoucherSeries"]);
      }
    }
  );
  return mutate as UseMutateFunction<
    void,
    unknown,
    Partial<AccountingVoucherSeriesInterface>,
    unknown
  >;
}

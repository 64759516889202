import React, { JSX, useMemo } from "react";
import "./style.scss";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell as MTableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow as MTableRow,
  TableRowProps,
  Typography
} from "@mui/material";
import classNames from "../../helpers/classNames";

export const TableCell = ({
  children,
  className,
  isHeaderCell = false,
  ...cellProps
}: TableCellProps & { children: React.ReactNode; isHeaderCell?: boolean }): JSX.Element => (
  <MTableCell
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...cellProps}
    className={classNames(
      "nestedHeaderTableCell",
      isHeaderCell && "nestedHeaderTableHeaderCell",
      className
    )}
  >
    {children}
  </MTableCell>
);

export const TableRow = ({
  children,
  ...rowProps
}: TableRowProps & { children: React.ReactNode }): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MTableRow {...rowProps}>{children}</MTableRow>
);

export const EmptyView = ({
  content = "There are no items to display..."
}: {
  content?: React.ReactNode;
}): JSX.Element => (
  <Box className="emptyViewContainer">
    <div className="emptyListViewImage" />
    <Typography textAlign="center" marginTop="16px">
      {content}
    </Typography>
  </Box>
);

export const LoadingView = (): JSX.Element => (
  <Box className="loadingViewContainer">
    <CircularProgress />
  </Box>
);

export const getSerialNumber = ({
  pageSize,
  page,
  currentIndex
}: {
  pageSize: number;
  page: number;
  currentIndex: number;
}): number => page * pageSize + currentIndex + 1;

interface BaseColumn<T> {
  key: string;
  label: string;
  formatter?: (row: T, index: number) => React.ReactNode;
}

interface Column<T> extends BaseColumn<T> {
  children?: BaseColumn<T>[];
}

interface Props<T> {
  data: T[];
  columns: Column<T>[][];
  dataTestmation: string;
  pagination?: {
    rowsPerPage: number;
    page: number;
    onPageChange: (page: number) => void;
    total: number;
  };
  minWidth?: number;
  isLoading?: boolean;
  emptyViewContent?: React.ReactNode;
  dense?: boolean;
  onRowClick?: (row: T) => void;
}

const ListWithNestedHeader = <T,>({
  data,
  columns,
  dataTestmation,
  pagination,
  minWidth = 900,
  isLoading = false,
  emptyViewContent,
  dense = false,
  onRowClick
}: Props<T>): JSX.Element => {
  // only the first index/row of columns will be used to render data
  const dataCells = useMemo(
    () =>
      columns[0].reduce((acc, cur) => {
        if (cur.children) {
          return [...acc, ...cur.children];
        }
        return [...acc, cur];
      }, [] as Column<T>[]),
    [columns]
  );

  const getHeaderCells = () =>
    columns.map((headerRow) => {
      const { items, children } = headerRow.reduce(
        (accum, current) => {
          const hasChildren = (current.children || []).length > 0;
          const totalHeaders = { ...accum };
          if (hasChildren) {
            totalHeaders.items.push({
              label: current.label,
              childrenCount: current.children.length,
              key: current.key
            });
            totalHeaders.children.push(...current.children);
          } else {
            totalHeaders.items.push({ label: current.label, key: current.key });
          }
          return totalHeaders;
        },
        {
          items: [] as { label: string; childrenCount?: number; key: string }[],
          children: [] as { label: string; key: string }[]
        }
      );
      return { items, children };
    });

  return (
    <>
      <TableContainer data-testmation={dataTestmation} className="nestedHeaderList">
        <Table
          size={dense ? "small" : "medium"}
          className="nestedHeaderTable"
          sx={{
            minWidth
          }}
        >
          <TableHead>
            {getHeaderCells().map((col) => (
              <React.Fragment key={Math.random() * 100}>
                <TableRow>
                  {col.items.map((item) => (
                    <TableCell
                      isHeaderCell
                      rowSpan={item.childrenCount ? 1 : 2}
                      colSpan={item.childrenCount || 1}
                      key={item.key}
                    >
                      {item.label}
                    </TableCell>
                  ))}
                </TableRow>
                {col.children.length > 0 && (
                  <TableRow>
                    {col.children.map((item) => (
                      <TableCell isHeaderCell key={item.key}>
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={row.id}
                hover
                className={onRowClick ? "tableRowHover" : ""}
                onClick={() => {
                  if (onRowClick) onRowClick(row);
                }}
              >
                {dataCells.map((item) => (
                  <TableCell key={row.id}>{item.formatter(row, rowIndex)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isLoading && <LoadingView />}
        {!isLoading && data.length === 0 && <EmptyView content={emptyViewContent} />}
      </TableContainer>
      {pagination && (
        <TablePagination
          component="div"
          nextIconButtonProps={{
            disabled:
              pagination.page >= Math.ceil(pagination.total / pagination.rowsPerPage) - 1 ||
              isLoading
          }}
          backIconButtonProps={{
            disabled: pagination.page === 0 || isLoading
          }}
          rowsPerPageOptions={[]}
          count={pagination.total}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={(_, page) => pagination.onPageChange(page)}
        />
      )}
    </>
  );
};

export default ListWithNestedHeader;

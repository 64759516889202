import React, { ReactNode, useEffect, useState } from "react";
import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Typography, CircularProgress, IconButton } from "@mui/material";
import { startCase } from "lodash";
import Panel from "../../../components/Panel";
import { clientFullNameSelector } from "../../../reducers/client";
import ClientImageUploader from "../../../components/ImageUploader/ClientImageUploader";
import { ageFormatter } from "../../../helpers/formatters";
import { tl } from "../../../components/translate";
import { Claim } from "../../../interfaces/ssf";
import { getSchemeFromClaim, getTotalBookedAmount } from "../../../models/ssf";
import { getClaimStatus, getSsfClaimById } from "../../../api/ssf";
import { notificationAdd as addNotification } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";

export const InfoRow = ({ label, value }: { label: string; value: ReactNode }): JSX.Element => (
  <Box display="flex" gap={2}>
    <Typography width="200px" fontWeight={600}>
      {label}
    </Typography>
    <Typography fontWeight={500}>{value}</Typography>
  </Box>
);

const ClaimDetailsContent = ({
  data,
  onBillClick,
  setClaims
}: {
  data: Claim;
  onBillClick: (id: number) => void;
  setClaims: React.Dispatch<React.SetStateAction<Claim[]>>;
}) => {
  const { client } = data;
  const dispatch = useAppDispatch();
  const age = ageFormatter(client.dob);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [claimStatus, setStatus] = useState<string>(data.status || "");
  const [statusCheckedAt, setStatusCheckedAt] = useState<string>(data.statusCheckedAt || "");

  const { scheme, subProduct } = getSchemeFromClaim({ data });

  const totalBookedAmount = getTotalBookedAmount({
    bookings: data.bookings,
    scheme,
    subProduct
  });

  const billNumbers = (data.bill || []).map((item) => (
    <Typography
      key={item.id}
      onClick={() => onBillClick(item.id)}
      sx={{ textDecoration: "underline", cursor: "pointer" }}
    >
      {item.billNumber}
    </Typography>
  ));

  const refetchClaimStatus = async () => {
    setRefetchLoading(true);
    try {
      const statusRes = await getClaimStatus(data.ssfClaimUuid);
      setStatus(statusRes.status);
      setStatusCheckedAt(statusRes.statusCheckedAt);
      setClaims((prev) => prev.map((claim) => (claim.id === data.id ? statusRes : claim)));
    } catch (e) {
      dispatch(
        addNotification({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message || commonErrorMessage,
          autoTimeout: true
        })
      );
    } finally {
      setRefetchLoading(false);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={4}>
        <Typography variant="h6"> {clientFullNameSelector(client).toUpperCase()} </Typography>
        <ClientImageUploader
          readOnly
          initialImage={client?.customerDetails?.profileImageS3Url || ""}
        />
      </Box>
      <Typography>
        {age} {client.gender ? tl(`clients.gender.${client.gender}`) : ""}
      </Typography>
      <Box mt={2} />
      <InfoRow label="Phone No." value={client.phone} />
      <InfoRow label="Email" value={client.email} />
      <InfoRow label="SSF ID" value={client.ssfId} />
      <InfoRow label="Hospital Claim Code" value={data.claimCode} />
      <InfoRow label="Scheme Type" value={startCase(scheme.label)} />
      <InfoRow label="Sub Product" value={startCase(subProduct.label)} />
      <InfoRow label="Booked Amount" value={`Rs. ${totalBookedAmount}`} />
      <InfoRow label="Issued Bills" value={billNumbers} />
      <InfoRow label="Claim Status" value={claimStatus} />
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <InfoRow
          label="Status Checked At"
          value={moment(statusCheckedAt).format("YYYY-MM-DD (hh:mm A)") || "-"}
        />

        {refetchLoading ? (
          <span>Loading...</span>
        ) : (
          <IconButton onClick={refetchClaimStatus}>
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

interface Props {
  onClose: () => void;
  onClaimCreate: (data: Claim) => void;
  id: number;
  onBillClick: (id: number) => void;
  setClaims: React.Dispatch<React.SetStateAction<Claim[]>>;
}

const ClaimDetails = ({
  onClose,
  id,
  onClaimCreate,
  onBillClick,
  setClaims
}: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<Claim>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await getSsfClaimById(id);
        setData(res);
      } catch (e) {
        dispatch(
          addNotification({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: e.message || commonErrorMessage,
            autoTimeout: true
          })
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return (
    <Panel
      onClose={onClose}
      title="Claim Details"
      genericButton={
        data?.status === "booked" && (
          <Button variant="contained" onClick={() => onClaimCreate(data)}>
            Create Claim
          </Button>
        )
      }
    >
      <Box
        sx={{
          px: 2,
          pt: 1,
          overflowY: "auto"
        }}
      >
        <Box px={2}>
          {isLoading && (
            <Box
              sx={{
                padding: 2,
                display: "flex",
                placeItems: "center",
                flexDirection: "column",
                gap: "1rem"
              }}
            >
              <CircularProgress color="primary" size={20} />
              <Typography>Loading claim</Typography>
            </Box>
          )}
          {!isLoading && data && (
            <ClaimDetailsContent setClaims={setClaims} data={data} onBillClick={onBillClick} />
          )}
        </Box>
      </Box>
    </Panel>
  );
};

export default ClaimDetails;

import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { RootState } from "../store/index";
import { getTaxation } from "../slices/journalEntry";
import { TaxationInterface } from "../interfaces/ProductInterface";
import useIsAccountSubscribed from "./accounts";

const useTaxations = (): TaxationInterface[] => {
  const dispatch = useDispatch();
  const taxations: TaxationInterface[] = useSelector(
    (state: RootState) => state.accounts.taxations
  );
  const isAccountSubscribed = useIsAccountSubscribed();
  const hasTaxations = taxations.length > 0;
  React.useEffect(() => {
    if (!hasTaxations && isAccountSubscribed) {
      dispatch(getTaxation());
    }
  }, [hasTaxations, dispatch, isAccountSubscribed]);
  return taxations;
};
export default useTaxations;

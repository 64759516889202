const backgroundColorCodes = {
  cancelled: '#FFEDED',
  unhandled: '#FFEBD9',
  handled: '#F0FFF9',
  referred: '#F0FFF9',
  reserved: '#FFFFFF',
  confirmed: '#F0F7FD',
};

const borderColorCodes = {
  cancelled: '#db2323',
  unhandled: '#d97016',
  handled: '#009654',
  referred: '#009654',
  reserved: 'lightgrey',
  confirmed: '#005196',
  publicReserved: '#557000',
};

const borderPrefixes = {
  cancelled: '1px solid ',
  unhandled: '1px solid ',
  handled: '1px solid ',
  referred: '1px solid ',
  reserved: '1px solid ',
  confirmed: '1px solid ',
  public: '1px dashed ',
};

export const getColorCode = (event) => {
  const currentTime = new Date().getTime();
  const endTime = new Date(event.end).getTime();
  if ((event.status == 'confirmed' || event.status == 'reserved') && currentTime > endTime) {
    return {
      background: backgroundColorCodes['unhandled'],
      border: event.throughPublic
        ? borderPrefixes['public'] + borderColorCodes['unhandled']
        : borderPrefixes['unhandled'] + borderColorCodes['unhandled'],
    };
  }
  return {
    background: backgroundColorCodes[event.status],
    border: event.throughPublic
      ? borderPrefixes['public'] + borderColorCodes[event.status]
      : borderPrefixes[event.status] + borderColorCodes[event.status],
  };
};

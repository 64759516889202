import * as React from 'react';
import NumberFormat from 'react-number-format';

const PercentInput = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={','}
      decimalSeparator={'.'}
      thousandsGroupStyle={'lakh'}
      allowNegative={false}
      isNumericString
      // suffix=" %"
    />
  );
};

export default PercentInput;

import React from "react";
import { Box, TextField, Button, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(() =>
  createStyles({
    endAdornment: {
      paddingRight: 0
    }
  })
);

interface MenuedTextFieldProps {
  width?: number;
  label?: string;
  menuItems: string[];
  onTextChange: (text: string) => void;
  onSelectedMenuChange: (text: string) => void;
  fieldValue: number | string;
  selectedMenuItem: string;
  startAdornment?: JSX.Element;
  [x: string]: any;
}

const MenuedTextField: React.FC<MenuedTextFieldProps> = ({
  width = 260,
  menuItems,
  label = "",
  onTextChange,
  onSelectedMenuChange,
  fieldValue,
  selectedMenuItem,
  startAdornment,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <TextField
      data-testmation="textField"
      margin="dense"
      variant="outlined"
      label={label}
      style={{ width: `${width}px` }}
      onChange={(e) => onTextChange(e.target.value)}
      value={fieldValue}
      InputProps={{
        startAdornment: startAdornment || <Box width="40px" />,
        endAdornment: (
          <Box width="240px">
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ borderRadius: 0 }}
              fullWidth
              endIcon={<ExpandMoreIcon />}
            >
              {selectedMenuItem}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {menuItems.map((item, i) => (
                <MenuItem
                  onClick={() => {
                    onSelectedMenuChange(item);
                    handleClose();
                  }}
                  style={{ width: "120px" }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ),
        classes: {
          adornedEnd: classes.endAdornment
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

MenuedTextField.defaultProps = {
  width: null,
  label: "",
  startAdornment: null
};

export default MenuedTextField;

import React from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { getStockSummaryReports } from "../../api/reports";
import Filters from "./Filters";
import { notificationAdd } from "../../actions/notification";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { getValue } from "../../helpers/formatters";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import { ProductType } from "../Stock/StockCreateEdit/CreateStockProduct";
import { endOfMonth, startOfMonth } from "../../components/Calendar/functions/calendarFunctions";
import { rupeeDisplay } from "../../helpers/rupee";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

export interface StockSummaryData {
  date: string;
  name: string;
  productId: number;
  batchId: string;
  openingUnit: number;
  stockIn: number;
  stockOut: number;
  avgPurchasePrice: number;
  unit: string;
  package: string;
  balanceUnit: number;
  stockValue: number;
  totalStockValue?: number;
}

const docColumns = [
  "Product Name",
  "Batch No.",
  "Unit/Package",
  "Average Purchase Price",
  "Opening Units",
  "Stock In Units",
  "Stock Out Units",
  "Balance Units",
  "Stock Value",
  "Total Stock Value"
];

const docRowProcessor = ({
  name,
  batchId,
  unit,
  package: p,
  avgPurchasePrice,
  openingUnit,
  stockOut,
  stockIn,
  balanceUnit,
  stockValue,
  totalStockValue,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
Partial<StockSummaryData & { __meta__row_type: string }>) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    name,
    batchId,
    `${unit}/${p}`,
    avgPurchasePrice,
    openingUnit,
    stockIn,
    stockOut,
    balanceUnit,
    stockValue,
    totalStockValue
  ].map((item) => getValue(item));
};

const StockSummaryReport = (): JSX.Element => {
  const [filters, setFilters] = React.useState({
    from: startOfMonth(new Date()).toDate(),
    until: endOfMonth(new Date()).toDate(),
    productItems: [],
    productType: ProductType.Sellable
  });
  const dispatch = useDispatch();

  const [stockSummaryData, setStockSummaryData] = React.useState<StockSummaryData[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getStockSummaryReports(filters);
        setStockSummaryData(response?.map((item, index) => ({ ...item, id: index })));
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            message: error?.data?.message || errorFetchMessage(MODULE.STOCK_SUMMARY_REPORT),
            autoTimeout: true
          })
        );
      }
    })();
  }, [filters, dispatch]);
  const { isRnWebView } = useIsReactNativeWebView();

  const totalStockValue = rupeeDisplay(
    Math.floor(
      stockSummaryData.reduce(
        (prev, cur) =>
          prev + cur.avgPurchasePrice * ((+cur.openingUnit || 0) + +cur.stockIn - +cur.stockOut),
        0
      ) || 0
    )
  );

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1000px" : "auto"}>
        <Box mx={4} mb={2}>
          <Filters
            filters={filters}
            onSetFilters={(newFilters) => setFilters(newFilters)}
            showMonthOnly
            disableMonthsBefore
          />
        </Box>
        <Box className="stockSummaryReport">
          <List
            automation="stockCashFlowList"
            data={stockSummaryData}
            rowHeight={50}
            adjustHeightToContents
            defaultSortColumn="name"
            columns={[
              {
                key: "name",
                label: tl("reports.stock.productName"),
                segmentable: true,
                segmentBy: ({ name }) => name,
                sortable: true,
                formatter: ({ name }) => <Typography>{name}</Typography>
              },
              {
                key: "batchId",
                label: tl("reports.batchId"),
                sortable: true,
                formatter: ({ batchId }) => <Typography>{batchId}</Typography>
              },
              {
                key: "unit/package",
                label: "Unit/Package",
                formatter: ({ unit = "", package: p = "" }) => (
                  <Typography>{`${unit}/${p}`}</Typography>
                )
              },
              {
                key: "openingUnit",
                label: "Opening Stock Unit ",
                sortable: true,
                formatter: ({ openingUnit }) => <Typography>{openingUnit}</Typography>
              },
              {
                key: "stockIn",
                label: "Stock In",
                sortable: true,
                formatter: ({ stockIn }) => <Typography>{stockIn}</Typography>
              },
              {
                key: "stockOut",
                label: "Stock Out",
                sortable: true,
                formatter: ({ stockOut }) => <Typography>{stockOut}</Typography>
              },
              {
                key: "balanceUnit",
                label: "Balance Unit",
                sortable: true,
                formatter: ({ balanceUnit }) => <Typography>{balanceUnit}</Typography>
              },
              {
                key: "avgPurchasePrice",
                label: "Purchase Price",
                sortable: true,
                formatter: ({ avgPurchasePrice }) => (
                  <Typography>{(avgPurchasePrice || 0).toFixed(2)}</Typography>
                )
              },
              {
                key: "stockValue",
                label: "Stock Value",
                sortable: true,
                formatter: ({ stockValue }) => (
                  <Typography>{(stockValue || 0).toFixed(2)}</Typography>
                )
              }
            ]}
            segementSummaryRenderer={(acc) => (
              <Box
                sx={{ background: "#e6e6e6" }}
                pr="20px"
                display="flex"
                flexGrow={1}
                alignItems="center"
              >
                <Typography ml="20px" fontWeight={500}>
                  {acc.segment}
                </Typography>
                <span />
                <span />
                <Typography>{acc.rows.reduce((prev, cur) => prev + cur.openingUnit, 0)}</Typography>
                <Typography>{acc.rows.reduce((prev, cur) => prev + cur.stockIn, 0)}</Typography>
                <Typography>{acc.rows.reduce((prev, cur) => prev + cur.stockOut, 0)}</Typography>
                <Typography>{acc.rows.reduce((prev, cur) => prev + cur.balanceUnit, 0)}</Typography>
                <span />
                <Typography>
                  {rupeeDisplay(
                    Math.floor(acc.rows.reduce((prev, cur) => prev + cur.stockValue, 0) || 0)
                  )}
                </Typography>
              </Box>
            )}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
            <ListActions>
              {({ getProcessedData }) => (
                <Menu>
                  <MenuItem
                    onClick={async () => {
                      try {
                        const formatedData = formatDataForExcel(
                          getProcessedData(),
                          docRowProcessor
                        );
                        formatedData[0][9] = { value: totalStockValue };
                        await downloadExcel(
                          "Stock Summary Report",
                          formatHeaderForExcel(docColumns),
                          formatedData
                        );
                      } catch (err) {
                        dispatch(
                          notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: "Failed to download Excel.",
                            autoTimeout: true
                          })
                        );
                      }
                    }}
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="192px" paddingRight="26px" justifyContent="flex-end">
              <Typography>{totalStockValue}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StockSummaryReport;

import { t } from '../components/translate';
import { abs } from 'mathjs';

export const rupeeDisplay = (val, onlyValue = false) => {
  if (val === null || val === undefined || isNaN(val)) return '';
  if (val < 0) {
    val = abs(val).toString();
    let afterPoint = '';
    if (val.indexOf('.') > 0) afterPoint = val.substring(val.indexOf('.'), val.length);
    val = Math.floor(val);
    val = val.toString();
    let lastThree = val.substring(val.length - 3);
    let otherNumbers = val.substring(0, val.length - 3);
    if (otherNumbers != '') lastThree = ',' + lastThree;
    const value = '-' + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
    return onlyValue ? value : `${t('rs')} ${value}`;
  } else {
    val = abs(val).toString();
    let afterPoint = '';
    if (val.indexOf('.') > 0) afterPoint = val.substring(val.indexOf('.'), val.length);
    val = Math.floor(val);
    val = val.toString();
    let lastThree = val.substring(val.length - 3);
    let otherNumbers = val.substring(0, val.length - 3);
    if (otherNumbers != '') lastThree = ',' + lastThree;
    const value = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;

    return onlyValue ? value : `${t('rs')} ${value}`;
  }
};

export const numberToWords = (price, tl = (a) => a, withCurrencyLabel = true) => {
  const sglDigit = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'],
    dblDigit = [
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ],
    tensPlace = [
      '',
      'Ten',
      'Twenty',
      'Thirty',
      'Forty',
      'Fifty',
      'Sixty',
      'Seventy',
      'Eighty',
      'Ninety',
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 == dgt ? '' : ' ' + (1 == dgt ? tl(dblDigit[prevDgt]) : tl(tensPlace[dgt]));
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 != dgt && 1 != nxtDgt ? ' ' + tl(sglDigit[dgt]) : '') +
        (0 != nxtDgt || dgt > 0 ? ' ' + denom : '')
      );
    };

  let str = '',
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];

  price += '';
  if (isNaN(parseInt(price))) return '';

  if (parseInt(price) === 0) return `${tl('Zero')} ${tl('rupees')}`;
  const priceBrokenDown = price.split('.');
  price = priceBrokenDown[0];
  let decimalPrice = priceBrokenDown[1];
  if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
      switch (
        ((digit = price[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
        price.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ''));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 != digit
              ? ' ' +
                  tl(sglDigit[digit]) +
                  ` ${tl('Hundred')}` +
                  (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? ` ${tl('And')}` : '')
              : '',
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, tl('Thousand')));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, tl('Lakh')));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, tl('Crore')));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 != digit
              ? ' ' +
                  sglDigit[digit] +
                  ` ${tl('Hundred')}` +
                  (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                    ? ` ${tl('And')}`
                    : ` ${tl('Crore')}`)
              : '',
          );
      }
    str = words
      .reverse()
      .join('')
      .concat(withCurrencyLabel ? ` ${tl('rupees')}` : '');
  }
  if (parseInt(decimalPrice) > 0 && decimalPrice.length) {
    decimalPrice = decimalPrice.padEnd(2, 0);
    str += ` ${tl('And')}  ${numberToWords(Number(decimalPrice), undefined, false)} ${tl('paisa')}`;
  }
  return str;
};

import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete
} from "@mui/material";

import Delete from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import produce from "immer";
import {
  deactivateBookableResource,
  postBookableResource,
  updateBookableResource,
  postWithIconImage,
  updateWithIconImage,
  getBookableResources
} from "../../actions/bookableResource";
import { IBookableResource } from "../../interfaces/BookableResources";
import Panel from "../../components/Panel";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { getServicetags } from "../../api/bookableResources";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import MappedNameAdder from "../../components/MappedNameAdder/MappedNameAdder";
import { IThunkDispatch, RootState } from "../../store";
import { useAppDispatch } from "../../store/hooks";

type State = IBookableResource & { mappedNamesToAdd: Array<string> };

interface ResourcePanelProps {
  handleClose: (newBookableResource?: IBookableResource) => void;
  title: string;
  data?: State;
  newNameToCreate?: string;
  saveResource: (data: IBookableResource, next) => void;
  updateResource: (data: IBookableResource, next) => void;
  saveResourceWithUpload: (data: IBookableResource, pictureData, next) => void;
  updateResourceWithUpload: (data: IBookableResource, pictureData, next) => void;
  deactivateResource: (id) => void;
  allowPublicBooking: boolean;
}

const bookableIcons = [
  /*  eslint-disable global-require */
  { name: "ambulance", path: require("../../../assets/icons/resourceBooking/ambulance.svg") },
  {
    name: "assistive-listening-systems",
    path: require("../../../assets/icons/resourceBooking/assistive-listening-systems.svg")
  },
  { name: "band-aid", path: require("../../../assets/icons/resourceBooking/band-aid.svg") },
  { name: "capsule", path: require("../../../assets/icons/resourceBooking/capsule.svg") },
  { name: "coronavirus", path: require("../../../assets/icons/resourceBooking/coronavirus.svg") },
  { name: "dental", path: require("../../../assets/icons/resourceBooking/dental.svg") },
  { name: "derma", path: require("../../../assets/icons/resourceBooking/derma.svg") },
  { name: "diabities", path: require("../../../assets/icons/resourceBooking/diabities.svg") },
  { name: "ear", path: require("../../../assets/icons/resourceBooking/ear.svg") },
  { name: "ent", path: require("../../../assets/icons/resourceBooking/ent.svg") },
  { name: "eye", path: require("../../../assets/icons/resourceBooking/eye.svg") },
  { name: "gastro", path: require("../../../assets/icons/resourceBooking/gastro.svg") },
  { name: "general", path: require("../../../assets/icons/resourceBooking/general.svg") },
  { name: "gyno", path: require("../../../assets/icons/resourceBooking/gyno.svg") },
  { name: "heart", path: require("../../../assets/icons/resourceBooking/heart.svg") },
  {
    name: "heart-medical",
    path: require("../../../assets/icons/resourceBooking/heart-medical.svg")
  },
  { name: "hospital", path: require("../../../assets/icons/resourceBooking/hospital.svg") },
  { name: "house-user", path: require("../../../assets/icons/resourceBooking/house-user.svg") },
  { name: "lab", path: require("../../../assets/icons/resourceBooking/lab.svg") },
  {
    name: "medical-square",
    path: require("../../../assets/icons/resourceBooking/medical-square.svg")
  },
  { name: "medkit", path: require("../../../assets/icons/resourceBooking/medkit.svg") },
  { name: "men", path: require("../../../assets/icons/resourceBooking/men.svg") },
  { name: "microscope", path: require("../../../assets/icons/resourceBooking/microscope.svg") },
  { name: "neuro", path: require("../../../assets/icons/resourceBooking/neuro.svg") },
  { name: "orthopedic", path: require("../../../assets/icons/resourceBooking/orthopedic.svg") },
  { name: "pediatric", path: require("../../../assets/icons/resourceBooking/pediatric.svg") },
  {
    name: "prescription-bottle",
    path: require("../../../assets/icons/resourceBooking/prescription-bottle.svg")
  },
  { name: "psychiatry", path: require("../../../assets/icons/resourceBooking/psychiatry.svg") },
  { name: "radiology", path: require("../../../assets/icons/resourceBooking/radiology.svg") },
  {
    name: "sanitizer-alt",
    path: require("../../../assets/icons/resourceBooking/sanitizer-alt.svg")
  },
  { name: "stethoscope", path: require("../../../assets/icons/resourceBooking/stethoscope.svg") },
  { name: "syringe", path: require("../../../assets/icons/resourceBooking/syringe.svg") },
  { name: "tablets", path: require("../../../assets/icons/resourceBooking/tablets.svg") },
  { name: "thermometer", path: require("../../../assets/icons/resourceBooking/thermometer.svg") },
  {
    name: "wheelchair-alt",
    path: require("../../../assets/icons/resourceBooking/wheelchair-alt.svg")
  }
  /* eslint-enable */
];

const ResourcePanel: React.FC<ResourcePanelProps> = ({
  handleClose,
  title,
  data,
  saveResource,
  updateResource,
  saveResourceWithUpload,
  updateResourceWithUpload,
  deactivateResource,
  allowPublicBooking,
  newNameToCreate = ""
}) => {
  const [serviceTags, setServiceTags] = useState([]);
  const [filteredIcons, setFilteredIcons] = useState(bookableIcons);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<State>(
    data || {
      name: newNameToCreate,
      publicBooking: false,
      details: {},
      serviceTagIds: [],
      mappedNamesToAdd: []
    }
  );

  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [imageData, setImageData] = useState(null);
  const [isCustomIcon, setIsCustomIcon] = useState(data?.details?.iconUrl || false);
  const [saving, setSaving] = useState(false);

  const serviceOptionsHash = {};

  const handleAfterSave = async (newBookableResource: IBookableResource) => {
    setSaving(false);
    await dispatch(getBookableResources());
    handleClose(newBookableResource);
  };

  serviceTags.forEach((option) => {
    serviceOptionsHash[option.id] = {
      name: option.tag,
      mappedName: option.mappedName
    };
  });

  useEffect(() => {
    (async () => {
      const tags = await getServicetags();
      setServiceTags(tags);
    })();
  }, []);

  useEffect(() => {
    if (state.serviceTagIds.length === 0) {
      setFilteredIcons(bookableIcons);
      return;
    }
    const checkArr = serviceTags.filter((tag) => state.serviceTagIds.includes(tag.id));
    const filtered = bookableIcons.filter((icon) =>
      checkArr.some(
        (el) => el.details?.iconUrl?.includes(icon.name) || el.details?.iconKey?.includes(icon.name)
      )
    );
    setFilteredIcons(filtered);
  }, [state.serviceTagIds, serviceTags]);

  useEffect(() => {
    if (isCustomIcon) {
      const updatedState = produce(state, (draft) => {
        delete draft.details.iconKey;
      });
      setState(updatedState);
    } else {
      setImageData(null);
      const updatedState = produce(state, (draft) => {
        delete draft.details.iconUrl;
      });
      setState(updatedState);
    }
  }, [isCustomIcon]);

  const panelProps = {
    onClose: handleClose,
    title,
    ...(data && {
      deleteButton: (
        <Delete
          style={{ cursor: "pointer" }}
          data-testmation="resourceDelete"
          onClick={() => {
            setDeleteDialog(true);
          }}
        />
      )
    })
  };

  return (
    <Panel
      onClose={() => panelProps.onClose()}
      title={panelProps.title}
      deleteButton={panelProps.deleteButton}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={() => handleClose()}>Cancel</Button>
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: "32px" }}
              disabled={saving}
              onClick={() => {
                setSaving(true);
                if (imageData) {
                  if (data) {
                    updateResourceWithUpload(state, imageData, (newBookableRes) =>
                      handleAfterSave(newBookableRes)
                    );
                  } else {
                    saveResourceWithUpload(state, imageData, (newBookableRes) =>
                      handleAfterSave(newBookableRes)
                    );
                  }
                } else if (data) {
                  updateResource(state, (newBookableRes) => handleAfterSave(newBookableRes));
                } else {
                  saveResource(state, (newBookableRes) => handleAfterSave(newBookableRes));
                }
              }}
            >
              {data ? "Update" : "Save"}
            </Button>
          </Box>
        </Box>
      }
    >
      <Box
        pt={2}
        pl={5}
        pr={5}
        height="calc(100vh - 100px)"
        style={{
          overflowY: "auto"
        }}
      >
        <TextField
          data-testmation="name"
          fullWidth
          label="Name"
          placeholder="Name"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          margin="dense"
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
        />
        <Autocomplete
          data-testmation="selectServiceTag"
          options={serviceTags.map((serviceTag) => serviceTag.id)}
          multiple
          getOptionLabel={(option) => serviceOptionsHash[option]?.name || ""}
          value={state.serviceTagIds || []}
          onChange={(e, v) => {
            if (v.length > 1) return;
            setState({ ...state, serviceTagIds: v });
          }}
          fullWidth
          style={{ marginTop: "8px", marginBottom: "8px" }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Service tags"
              placeholder="Select service tags"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="dense"
            />
          )}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option}>
              {serviceOptionsHash[option]?.name || ""}
            </li>
          )}
        />
        <MappedNameAdder
          serviceTag={
            serviceOptionsHash[state.serviceTagIds?.length > 0 ? state.serviceTagIds[0] : null]
          }
          onChange={(v: Array<string>) => {
            setState({ ...state, mappedNamesToAdd: v });
          }}
          addedMappedNames={state.mappedNamesToAdd}
        />
        <Box mt={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCustomIcon}
                onChange={(event) => setIsCustomIcon(event.target.checked)}
                color="primary"
              />
            }
            label="Use custom icon"
          />
          {isCustomIcon ? (
            <Box ml={2}>
              <ImageUploader
                action={(image) => {
                  if (image) {
                    setImageData(image);
                  }
                }}
                initialImage={
                  (imageData && URL.createObjectURL(imageData)) || state.details.iconUrl || ""
                }
              />
            </Box>
          ) : (
            <Box>
              <TextField
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                placeholder="Icon"
                select
                style={{ width: "200px" }}
                margin="dense"
                label="Icon"
                value={state.details.iconKey || ""}
                onChange={(e) => {
                  const updatedState = produce(state, (draft) => {
                    draft.details.iconKey = e.target.value;
                  });
                  setState(updatedState);
                }}
              >
                {filteredIcons.map((option) => (
                  <MenuItem key={option.name} value={option.name} id={`unqxsmi-${option.name}`}>
                    <img src={option.path} alt="resourceIcon" />
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
        </Box>
        <TextField
          data-testmation="description"
          fullWidth
          multiline
          rows={8}
          variant="outlined"
          label="Description"
          placeholder="Write description"
          InputLabelProps={{ shrink: true }}
          value={state.details.description || ""}
          onChange={(e) => {
            const updatedState = produce(state, (draft) => {
              draft.details.description = e.target.value;
            });
            setState(updatedState);
          }}
          style={{ marginTop: "16px" }}
        />

        {allowPublicBooking && (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.publicBooking}
                onChange={() => setState({ ...state, publicBooking: !state.publicBooking })}
                color="primary"
              />
            }
            label="Public booking"
          />
        )}

        {allowPublicBooking && state.publicBooking && (
          <TextField
            fullWidth
            multiline
            rows={8}
            variant="outlined"
            label="Public Info"
            value={state.details.publicInfo || ""}
            onChange={(e) => {
              const updatedState = produce(state, (draft) => {
                draft.details.publicInfo = e.target.value;
              });
              setState(updatedState);
            }}
            style={{ marginTop: "8px" }}
          />
        )}
      </Box>

      <OkhatiDialog
        title="Confirm Deletion"
        description="Are you sure you want to delete this bookable resource? This action is irreversible!"
        next={async () => {
          deactivateResource(data.id);
          handleClose();
        }}
        readMode={false}
        cancel={() => {
          setDeleteDialog(false);
        }}
        open={deleteDialog}
      />
    </Panel>
  );
};

export default connect(
  (state: RootState) => ({
    allowPublicBooking:
      state.userContext.resourceCentre.subscriptions?.features?.publicBooking?.subscribed
  }),
  (dispatch: IThunkDispatch) => ({
    saveResource: (data, next) => {
      dispatch(postBookableResource(data, next));
    },
    saveResourceWithUpload: (data, pictureData, next) => {
      dispatch(postWithIconImage(data, pictureData, next));
    },
    updateResourceWithUpload: (data, pictureData, next) => {
      dispatch(updateWithIconImage(data, pictureData, next));
    },
    updateResource: (data, next) => dispatch(updateBookableResource(data, next)),
    deactivateResource: (id) => dispatch(deactivateBookableResource(id))
  })
)(ResourcePanel);

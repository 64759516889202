import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { tl } from "../../../components/translate";
import ContentEditableWithTextField from "../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import styles from "../style.module.css";
import { DEFAULT_SETTINGS } from "../Settings";
import { BillingSettings } from "../../../interfaces/ResourceCentreInterface";

const BillingSetting = ({
  billingSettings,
  onChange
}: {
  billingSettings: BillingSettings;
  onChange: (data) => void;
}): JSX.Element => {
  const [billSettings, setBillSettings] = React.useState(
    billingSettings || DEFAULT_SETTINGS.settings.billingSettings
  );

  React.useEffect(() => {
    if (!isEqual(billingSettings, billSettings)) onChange(billSettings);
  }, [billSettings]);
  return (
    <div>
      <Box width="100%" display="flex" alignItems="center">
        <Typography component="span" fontSize="14px" fontWeight="600" flexBasis="100px">
          {tl("vatRegistered")}
        </Typography>
        <Checkbox
          className={styles.optPadding}
          size="small"
          checked={billSettings.vatRegistered}
          value={billSettings.vatRegistered}
          onChange={() =>
            setBillSettings({ ...billSettings, vatRegistered: !billSettings.vatRegistered })
          }
        />
      </Box>
      <Box width="100%" display="flex" alignItems="center">
        <ContentEditableWithTextField
          label={tl("billing.VAT")}
          placeholder="Type vat number here.."
          saveOnFocusOut
          value={billSettings.vatNo}
          disabled={!billSettings.vatRegistered}
          onSave={(val) => setBillSettings({ ...billSettings, vatNo: val })}
          width="300px"
        />
      </Box>
      <Box width="100%" display="flex" alignItems="center">
        <ContentEditableWithTextField
          label={tl("panNo")}
          placeholder="Type your pan number here.."
          saveOnFocusOut
          value={billSettings.panNo}
          onSave={(val) => setBillSettings({ ...billSettings, panNo: val })}
          width="300px"
        />
      </Box>
    </div>
  );
};

export default BillingSetting;

import * as React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import Select from 'react-select';

import { serviceProviderActions as actions } from '../../../actions';
import { serviceProvidersSortedSelector, spFullNameSelector } from '../../../reducers/serviceProvider';

interface ServiceProviderModalProps {
  title: string;
  data: any;
  cancel: () => void;
  saveFn: (...any) => void;
  serviceProviders: any;
  loadServiceProviders: (id: string) => void;
  resourceCentreId: string;
}

const useStyles = makeStyles({
  root: { overflow: 'visible' },
});

const ServiceProviderModal: React.FunctionComponent<ServiceProviderModalProps> = ({
  title,
  data,
  saveFn,
  cancel,
  serviceProviders,
  loadServiceProviders,
  resourceCentreId,
}) => {
  React.useEffect(() => {
    !Boolean(serviceProviders.length) && loadServiceProviders(resourceCentreId);
  }, []);
  const [serviceProviderId, setServiceProviderId] = React.useState(null);
  const optionsForSelect = (serviceProviders) => {
    return serviceProviders.map((sp) => {
      return { label: spFullNameSelector(sp), value: sp.id };
    });
  };
  const classes = useStyles();
  return (
    <Dialog open={true} classes={{ paperScrollPaper: classes.root }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.root}>
        <Select
          placeholder="Service provider..."
          value={optionsForSelect(serviceProviders).find(({ value }) => value == serviceProviderId)}
          options={optionsForSelect(serviceProviders)}
          onChange={({ value }) => setServiceProviderId(value)}
        ></Select>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button
          disabled={!Boolean(serviceProviderId)}
          variant="contained"
          color="primary"
          onClick={() => {
            saveFn({ ...data, serviceProviderId });
            cancel();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    serviceProviders: serviceProvidersSortedSelector(state),
    resourceCentreId: state.userContext.resourceCentreId,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    loadServiceProviders: (id) =>
      dispatch(actions.getResourceCentreServiceProviders({ resourceCentreId: id })),
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(ServiceProviderModal);

import * as React from "react";
import * as classnames from "classnames";
import "./styles.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/Launch";
import ClickAwayTrigger from "../ClickAwayTrigger/ClickAwayTrigger";
import { setBaseClass } from "../../helpers/styles";
import OList from "../OList";

const getActiveItems = (items, maxAllowed, sortFn) => {
  const filtered = maxAllowed < 0 ? items : items.slice(0, maxAllowed);
  if (sortFn) {
    return [...filtered].sort(sortFn);
  }
  return filtered;
};

const getInactiveItems = (items, maxAllowed) => {
  if (maxAllowed < 0) {
    return [];
  }
  return items.slice(maxAllowed - 1, items.length);
};

interface DropSelectProps<T> {
  open: boolean;
  testmation: string;
  items: T[];
  selectedItem: T;
  placeholder?: string;
  noItemsFoundText?: string;
  showSearchIcon?: boolean;
  className?: string;
  highlight?: boolean;
  hasError?: boolean;
  maxAllowed?: {
    limit: number;
    breaker?: JSX.Element;
  };
  onOpen: () => void;
  renderItem?: (item: T) => JSX.Element | string;
  getSelectedText: (item: T) => string;
  renderAction: (item: string) => string;
  onActionClick: (searchText) => void;
  filterFn: (item: T, searchText: string) => boolean;
  sortFn?: (a: T, b: T) => void;
  onSelect: (item: T) => void;
  onClose: () => void;
  onClick: () => void;
  onClickOutside: () => void;
  onChange?: (val: string) => void;
  rootStyles?: { [key: string]: string };
  closeIconStyles?: { [key: string]: string };
  inputProps?: { [key: string]: any };
  listItemAction?: (item) => void;
  onBlur?: () => void;
  openAbove?: boolean;
  portalHeight?: number;
}
const getItem = (item, items) => items.find((i) => i.id === item.id);

const cls = setBaseClass("okhatiVirtualSelect");

function DropSelect<T extends { id: number; __meta?: { disabled: boolean } }>(
  props: React.PropsWithChildren<DropSelectProps<T>>
): JSX.Element {
  const {
    open,
    testmation,
    items,
    placeholder,
    className,
    renderItem,
    noItemsFoundText,
    highlight,
    hasError,
    maxAllowed = { limit: -1 },
    selectedItem,
    getSelectedText,
    showSearchIcon,
    onSelect,
    filterFn,
    sortFn,
    onOpen,
    onClose,
    onActionClick,
    renderAction,
    onClickOutside,
    onChange,
    rootStyles = {},
    closeIconStyles = {},
    inputProps = {},
    listItemAction = false,
    onBlur = () => ({}),
    openAbove = false,
    portalHeight
  } = props;
  const [filterProps, setFilterProps] = React.useState({
    searchText: "",
    filteredActiveItems: getActiveItems(items, maxAllowed.limit, sortFn),
    filteredInactiveItems: getInactiveItems(items, maxAllowed.limit)
  });
  const inputRef = React.useRef<HTMLInputElement>();

  const filterItems = (searchText) => {
    setFilterProps({
      searchText,
      filteredActiveItems: getActiveItems(items, maxAllowed.limit, sortFn).filter((item) =>
        filterFn(item, searchText)
      ),
      filteredInactiveItems: getInactiveItems(items, maxAllowed.limit).filter((item) =>
        filterFn(item, searchText)
      )
    });
  };

  React.useEffect(() => {
    filterItems(filterProps.searchText);
  }, [items.length, items]);

  React.useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <ClickAwayTrigger
      onClickAway={() => {
        onClose();
        onClickOutside();
      }}
    >
      <div
        data-testmation={testmation}
        className={classnames(cls(), className || "")}
        style={{
          ...rootStyles,
          ...(openAbove && {
            display: "flex",
            flexDirection: openAbove ? "column-reverse" : "column"
          })
        }}
      >
        <div
          className={classnames(cls("__inputContainer"), {
            [cls("__inputContainer-active")]: open,
            [cls("__inputContainer-highlight")]: highlight,
            [cls("__inputContainer-error")]: hasError
          })}
          onClick={() => {
            onOpen();
            if (inputRef && inputRef.current) {
              inputRef.current.focus();
            }
          }}
          onKeyDown={null}
          role="textbox"
          tabIndex={0}
        >
          {showSearchIcon && <div className={cls("__searchIcon")} />}
          <div className={cls("__input")}>
            <input
              ref={inputRef}
              data-testmation={`${testmation}-input`}
              type="text"
              value={
                selectedItem
                  ? getSelectedText(getItem(selectedItem, items))
                  : filterProps.searchText
              }
              onBlur={onBlur}
              placeholder={placeholder || ""}
              onChange={(e) => {
                onOpen();
                const val = e.target.value;
                filterItems(val);
                if (selectedItem) {
                  onSelect(null);
                }
                // eslint-disable-next-line no-unused-expressions
                onChange && onChange(val);
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              onFocus={(e) => e.target.select()}
            />
          </div>
          {Boolean(filterProps.searchText && !selectedItem) && (
            <div
              className={cls("__clearIcon")}
              onClick={() => {
                filterItems("");
                onSelect(null);
              }}
              style={closeIconStyles}
              onKeyDown={null}
              role="button"
              tabIndex={0}
            >
              <CloseIcon fontSize="small" />
            </div>
          )}
          <div
            className={cls("__dropIcon")}
            onClick={(e) => {
              if (open) {
                onClose();
                e.stopPropagation();
              }
            }}
            onKeyDown={null}
            role="button"
            tabIndex={0}
          >
            <ExpandMoreIcon />
          </div>
        </div>
        {open && (
          <div className={cls("__menuContainer")} style={openAbove ? { marginBottom: "32px" } : {}}>
            {Boolean(items.length) &&
              !filterProps.filteredActiveItems.length &&
              !filterProps.filteredInactiveItems.length && (
                <div className={cls("__menuEmptyMessage")}>
                  {noItemsFoundText || "No items found"}
                </div>
              )}
            {Boolean(
              filterProps.filteredActiveItems.length || filterProps.filteredInactiveItems.length
            ) && (
              <div
                className={cls("__menuItems")}
                style={{
                  height:
                    filterProps.filteredActiveItems.length > 6
                      ? portalHeight || 280
                      : filterProps.filteredActiveItems.length * 40
                }}
              >
                <OList
                  automation="dropListOList"
                  data={filterProps.filteredActiveItems}
                  rowHeight={40}
                  columns={[]}
                  hideHeader
                  adjustHeightToContents
                  rowRenderer={(item, key, style) => (
                    <div
                      key={item.id}
                      style={{ display: "flex", justifyContent: "space-between", ...style }}
                      data-testmation={`${testmation}-option-${item.id}`}
                      className={cls("__menuItem")}
                      onClick={() => {
                        filterItems(getSelectedText(item));
                        onSelect(item);
                        onClose();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onSelect(item);
                          onClose();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {listItemAction ? (
                        <>
                          <div style={{ whiteSpace: "initial" }}>{renderItem(item)}</div>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              listItemAction(item);
                            }}
                            onKeyDown={null}
                            role="button"
                            tabIndex={0}
                          >
                            <LaunchIcon />
                          </div>
                        </>
                      ) : (
                        renderItem(item)
                      )}
                    </div>
                  )}
                />
              </div>
            )}
            {renderAction && filterProps.searchText && filterProps.searchText.length > 1 && (
              <div
                className={cls("__menuAction")}
                onClick={() => onActionClick(filterProps.searchText)}
                onKeyDown={null}
                role="textbox"
                tabIndex={0}
              >
                {renderAction(filterProps.searchText)}
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayTrigger>
  );
}

DropSelect.defaultProps = {
  placeholder: "",
  noItemsFoundText: "",
  showSearchIcon: false,
  className: "",
  highlight: false,
  hasError: false,
  maxAllowed: {
    limit: -1,
    breaker: {}
  },
  renderItem: null,
  sortFn: null,
  onChange: null,
  rootStyles: {},
  closeIconStyles: {},
  inputProps: {},
  listItemAction: null,
  onBlur: () => ({}),
  openAbove: false,
  portalHeight: null
};

export default DropSelect;

import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { StateProps } from "./ScheduleDetails";
import { RootState } from "../../../store";
import { PublicSchedule } from "../../../interfaces/PublicLabInterface";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";

interface Props {
  currentState: StateProps;
  onChange: (newState: StateProps) => void;
}

// sorting by current Day.
const sortByDay = (events) => {
  if (!events?.length) return [];
  const sortedEvents = [...events].sort((a, b) => a.day - b.day);
  const today = new Date().getDay();
  return [...sortedEvents.splice(today), ...sortedEvents.splice(0, today)];
};

export default function ScheduleList({ currentState, onChange }: Props): JSX.Element {
  const publicEvents: PublicSchedule[] = sortByDay(
    useSelector((state: RootState) => state.public?.publicEvents || [])
  );

  return (
    <Grid justifyContent="center" container spacing={2}>
      {publicEvents.map((sc) => (
        <Grid item sm={1.7} key={sc.day} data-testmation="timeSlot">
          <>
            <Typography
              textAlign="center"
              fontSize="1.3rem"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {moment(sc.schedules[0]).format("ddd")}
            </Typography>
            <Typography
              textAlign="center"
              fontSize="1rem"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {convertADtoBS(moment(sc.schedules[0])).formatted3}
            </Typography>
          </>
          {sc.schedules.map((ev) => {
            const start = moment(ev);
            const end = moment(ev).add(1, "h");
            const selected =
              currentState.start === start.toISOString() && currentState.end === end.toISOString();
            return (
              <Box
                sx={{
                  "&:hover": {
                    backgroundColor: "#E4F2FF",
                    cursor: "pointer"
                  },
                  border: `${selected ? "0.1px solid green" : ""}`,
                  backgroundColor: `${selected ? "#E4F2FF" : ""}`
                }}
                onClick={() =>
                  onChange({
                    ...currentState,
                    start: start.toISOString(),
                    end: end.toISOString()
                  })
                }
                p="10px 0"
                m="10px 0"
                key={ev}
                display="flex"
                justifyContent="center"
              >
                <Typography textAlign="center">{`${start.format("hh:mmA")}-`}</Typography>
                <Typography textAlign="center" key={ev}>
                  {end.format("hh:mmA")}
                </Typography>
              </Box>
            );
          })}
        </Grid>
      ))}
    </Grid>
  );
}

import * as moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import {
  isEmail,
  isRequired,
  hasLength,
  isValidPhoneNumber,
  isADDate,
  isBSDate,
  isADDateString,
  isValidMobileNumber
} from "../helpers/validators";
import { convertADtoBS, convertBStoAD } from "../components/Calendar/functions/calendarFunctions";

export interface ClientType {
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  phone: string;
  secondaryPhone: string;
  address: string;
  city: string;
  ethnicity: string;
  ipdNo: string;
  registrationNo: string;
  maritalStatus: string;
  passportInfo: PassportInfo;
  nationality: string;
  appliedCountry: string;
  bloodGroup: string;
}

export interface PassportInfo {
  passportNumber: number;
  issueDate: string;
  expiryDate: string;
  issuingAuthority: string;
}

// should follow keywords as received from client search api
export enum clientSearchSecondaryLabel {
  PHONE = "phone",
  CUSTOMER_NUMBER = "customerNumber",
  EXTERNAL_IDENTIFIER = "externalIdentifier",
  PALIKA = "palikaId"
}

export const genderOptions = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Other" }
];

export const ethnicityMappedValue = {
  1: "Dalit",
  2: "Janjati",
  3: "Madeshi",
  4: "Muslim",
  5: "Brahmin/Chetteri",
  6: "Others"
};

export const ethnicityOptions = Object.entries(ethnicityMappedValue).map(([key, value]) => ({
  value: key,
  label: value
}));

export const bloodGroups = [
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "O+", value: "O+" },
  { label: "O-", value: "O-" },
  { label: "AB+", value: "AB+" },
  { label: "AB-", value: "AB-" }
];

export const maritalStatusOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Separated", label: "Separated" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" }
];

export const fields = [
  {
    key: "firstName",
    label: "firstName",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [
      isRequired({ msg: "Firstname is required" }),
      hasLength({ gt: 2, lt: 35, msg: "Firstname length should be between 3 and 35 charceters" })
    ]
  },
  {
    key: "lastName",
    label: "lastName",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [
      isRequired({ msg: "Lastname is required" }),
      hasLength({ gt: 2, lt: 35, msg: "Lastname length should be between 3 and 35 charceters" })
    ]
  },
  {
    key: "phone",
    label: "mobileNumber",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: true,
    mobileNumber: true,
    validators: [
      isRequired({ msg: "Phone is required" }),
      isValidMobileNumber({ msg: "Should be valid mobile number" })
    ]
  },
  {
    key: "secondaryPhone",
    label: "secondaryPhone",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    validators: [isValidPhoneNumber({ msg: "Should be valid phone number" })],
    required: false
  },
  {
    key: "dob",
    label: "dob",
    value: "",
    inputType: "bsDate",
    editable: true,
    creatable: true,
    required: false,
    formatter: (dateString: Date | moment.Moment | string): Date | moment.Moment | string => {
      if (!dateString) return dateString;
      const isValid = moment(dateString).toISOString() === dateString;
      if (!isValid) return dateString;
      return convertADtoBS(moment.utc(dateString)).formatted4;
    },
    reverseFormatter: (
      dateString: Date | moment.Moment | string
    ): Date | moment.Moment | string => {
      if (!dateString) return null;
      const isValid = isBSDate({ msg: "" })(dateString);
      if (!dateString || !isValid) return dateString;
      const [year, month, date] = dateString.split("-");
      return moment
        .utc(
          // when we have a date string without timezone, it is better to force them at +0 UTC
          moment(convertBStoAD(Number(year), Number(month), Number(date))).format("YYYYMMDD")
        )
        .toISOString();
    },
    ADFormatter: (dateString: Date | moment.Moment | string): Date | moment.Moment | string => {
      if (!dateString) return null;
      const isValid = isADDate({ msg: "" })(dateString);
      if (!dateString || !isValid) return dateString;
      return moment.utc(dateString).toISOString();
    },
    validators: [isADDateString({ msg: "Date must be in specified format" })]
  },
  {
    key: "gender",
    label: "gender",
    value: "",
    inputType: "select",
    editable: true,
    creatable: true,
    options: genderOptions,
    required: false
  },
  {
    key: "ethnicity",
    label: "ethnicity",
    value: "",
    inputType: "select",
    required: false,
    editable: true,
    creatable: true,
    options: ethnicityOptions
  },
  {
    key: "maritalStatus",
    label: "maritalStatus",
    value: "",
    inputType: "select",
    editable: true,
    creatable: true,
    options: maritalStatusOptions,
    required: false
  },
  {
    key: "email",
    label: "email",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true,
    validators: [isEmail({ msg: "Should be valid email id" })]
  },
  {
    key: "address",
    label: "address",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },
  {
    key: "city",
    label: "city",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },
  {
    key: "passportInfo",
    label: "passportInfo",
    value: "",
    inputType: "nested",
    children: [
      {
        key: "passportNumber",
        label: "passportNumber",
        value: "",
        inputType: "text",
        required: false,
        editable: true,
        creatable: true
      },
      {
        key: "issuingAuthority",
        label: "passportIssuingAuthority",
        value: "",
        inputType: "text",
        required: false,
        editable: true,
        creatable: true
      },
      {
        key: "issueDate",
        label: "passportIssueDate",
        value: "",
        inputType: "date",
        required: false,
        editable: true,
        creatable: true,
        validators: [isADDateString({ msg: "Date must be in specified format" })]
      },
      {
        key: "expiryDate",
        label: "passportExpiryDate",
        value: "",
        inputType: "date",
        required: false,
        editable: true,
        creatable: true,
        validators: [isADDateString({ msg: "Date must be in specified format" })]
      }
    ],
    required: false,
    editable: true,
    creatable: true
  },

  {
    key: "nationality",
    label: "nationality",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },
  {
    key: "appliedCountry",
    label: "appliedCountry",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },

  {
    key: "ipdNo",
    label: "ipdNo",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },
  {
    key: "registrationNo",
    label: "registrationNo",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },
  {
    key: "wardNo",
    label: "wardNo",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  },
  {
    key: "bloodGroup",
    label: "Blood Group",
    value: "",
    inputType: "text",
    required: false,
    editable: true,
    creatable: true
  }
];

export const applyRequiredModifier = (...args: string[]): unknown => {
  // send in keys of fields which you want to make required = true
  const applyModifierFor = [...args];
  const modifiedFields = fields.map((item) => {
    if (applyModifierFor.includes(item.key)) {
      const currentField = cloneDeep(item);
      currentField.required = true;
      return currentField;
    }
    return item;
  });
  return modifiedFields;
};

export const getRequiredFormKeysFromSettings = (formSettings: unknown): unknown => {
  // returns only those formKeys(as specified in conditionalFields)
  // which have true value in formSettings i.e {requireEmail: true}
  // add more conditional fields here later according to formSettings
  const conditionalFields = { email: formSettings?.requireEmail };
  const requiredKeys = [];
  Object.keys(conditionalFields).forEach((key) => {
    if (conditionalFields[key]) requiredKeys.push(key);
  });
  return requiredKeys;
};

export const knownUsFromDefaultOptions = [
  { value: "Facebook" },
  { value: "Instagram" },
  { value: "Website" },
  { value: "Google Business" }
];

const mapData = {
  type: "FeatureCollection",
  name: "Nepal Map",
  features: [
    {
      type: "Feature",
      properties: {
        Name: "BHOJPUR",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.26315507020138, 27.177412006784316],
            [87.24683499545434, 27.19196849738384],
            [87.23760501195464, 27.217150177541875],
            [87.20259716770171, 27.24650234332198],
            [87.20152233427139, 27.301440290902146],
            [87.1822531323421, 27.31660929681059],
            [87.19224171781742, 27.35244293578036],
            [87.16860021458413, 27.375872007526198],
            [87.13122591606081, 27.39485203987172],
            [87.12398372134919, 27.426287381280673],
            [87.14033336137365, 27.447558228407313],
            [87.10998230657277, 27.45948769462362],
            [87.07317297783449, 27.45994962399283],
            [87.05585009466822, 27.44125484619727],
            [87.04480309117454, 27.447251681906483],
            [87.01007975016833, 27.436071556471447],
            [86.98042236915994, 27.44192801343838],
            [86.94016530823029, 27.45922270810498],
            [86.93079611167103, 27.44155573304249],
            [86.90601846552686, 27.43349732302484],
            [86.91043758555801, 27.407586008237985],
            [86.9305472060157, 27.385056481530945],
            [86.92383558805919, 27.37064832618102],
            [86.94807364774239, 27.33779084659325],
            [86.98445788053938, 27.30745348113917],
            [86.96527965362323, 27.295170791899732],
            [86.94824488892613, 27.266170805289487],
            [86.91233024897332, 27.266782434449368],
            [86.89790670798456, 27.251857813399425],
            [86.92834086413146, 27.223242935707106],
            [86.92967373952904, 27.20385635814477],
            [86.91277712804859, 27.169616897462475],
            [86.92234231287989, 27.158417052553215],
            [86.91487333518415, 27.12749496225314],
            [86.89706140734057, 27.115963856433975],
            [86.9243123875278, 27.091534734421447],
            [86.94661805421926, 27.085335294334143],
            [86.96463905015142, 27.058082759105325],
            [86.94505279703216, 27.020233188168127],
            [86.96712938762067, 27.00747738241226],
            [86.95838928397295, 26.981110237819276],
            [86.97257310693666, 26.973331043647182],
            [86.96506369421911, 26.927772793884948],
            [86.95155813794786, 26.904610220358595],
            [86.97628519531247, 26.906455611957224],
            [87.00331173222398, 26.88675362424058],
            [87.0421423658023, 26.907290745815434],
            [87.05026822667766, 26.90246812334313],
            [87.11564753258199, 26.923092640941167],
            [87.14907401002068, 26.92559538137107],
            [87.1560986267474, 26.91565462701478],
            [87.1486926416484, 26.93902194592589],
            [87.17331847240314, 26.983349797686117],
            [87.19714825249844, 27.00035491059067],
            [87.17884249007453, 27.05234820578888],
            [87.18316631497521, 27.074516894926536],
            [87.21839987702423, 27.107620605386508],
            [87.2455784698923, 27.118787979260844],
            [87.27112691971033, 27.14312622873425],
            [87.27543466036539, 27.16052621153249],
            [87.26315507020138, 27.177412006784316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DHANKUTA",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.4067503734144, 27.136397363159205],
            [87.3570210579744, 27.190912116727315],
            [87.31239742847032, 27.18235626069018],
            [87.29226836760911, 27.19041894221843],
            [87.26315507020138, 27.177412006784316],
            [87.27543466036539, 27.16052621153249],
            [87.27112691971033, 27.14312622873425],
            [87.2455784698923, 27.118787979260844],
            [87.21839987702423, 27.107620605386508],
            [87.18316631497521, 27.074516894926536],
            [87.17884249007453, 27.05234820578888],
            [87.19714825249844, 27.00035491059067],
            [87.17331847240314, 26.983349797686117],
            [87.1486926416484, 26.93902194592589],
            [87.1560986267474, 26.91565462701478],
            [87.16827352169611, 26.899542691729533],
            [87.16480987963924, 26.87794058862888],
            [87.21798653828566, 26.863618651557278],
            [87.23312124510731, 26.87867472238181],
            [87.25682219642317, 26.865988188566018],
            [87.29690729956097, 26.87491998656162],
            [87.32326176413709, 26.854580608172988],
            [87.34748510511558, 26.849678464996316],
            [87.36898271601409, 26.845763125109716],
            [87.39195823234657, 26.86586539656282],
            [87.42141043598969, 26.870526073007117],
            [87.45320671067962, 26.85822915123598],
            [87.49048844037992, 26.87240857332163],
            [87.52039779053331, 26.86221518547174],
            [87.58011194818718, 26.861407384612438],
            [87.5628727707214, 26.882685527965943],
            [87.52223967490225, 26.88485211278344],
            [87.53130122888567, 26.89990501970461],
            [87.52230787532753, 26.926077442957567],
            [87.52483291286684, 26.96022612234221],
            [87.49256784978512, 26.98537790389863],
            [87.46383464933551, 26.96808715641304],
            [87.43898649341466, 26.971111385853067],
            [87.44096201113216, 27.006601496415136],
            [87.42664842279706, 27.04632738030133],
            [87.40150201281638, 27.073866638581535],
            [87.39203472872894, 27.1025478250714],
            [87.4067503734144, 27.136397363159205]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "ILAM",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.6872013145813, 26.75004752632236],
            [87.70767256014237, 26.709883145041864],
            [87.70464645107346, 26.70144088920161],
            [87.76508894409466, 26.66531786850348],
            [87.7907798537088, 26.666337053125602],
            [87.81450998406538, 26.694074689539605],
            [87.84117816383959, 26.67733887644788],
            [87.86537430794608, 26.673747937307343],
            [87.87931207798309, 26.684637678499136],
            [87.92427711034125, 26.697538618250196],
            [87.93387856656493, 26.688829237222127],
            [87.93551482139745, 26.726534173278257],
            [87.95030779940808, 26.72875768539547],
            [87.9586509083559, 26.781532429313067],
            [87.99467396592152, 26.752923825282505],
            [88.02647716060402, 26.778381125999626],
            [88.04710303854809, 26.74624886096509],
            [88.06503409358325, 26.78514530354427],
            [88.09203398936556, 26.80143106739221],
            [88.10230848894493, 26.751347113056976],
            [88.11815182406694, 26.748438780804417],
            [88.12553716847779, 26.765629795978032],
            [88.1454709919198, 26.77027144512502],
            [88.15467991537048, 26.79556635140451],
            [88.18485593001766, 26.783225729175996],
            [88.16754760392395, 26.837777164781397],
            [88.17231103299045, 26.868632628672483],
            [88.13638316862726, 26.898747093656546],
            [88.14604016026102, 26.92105332749853],
            [88.12045297386335, 26.95064190099262],
            [88.13554523602853, 26.984926563500174],
            [88.09248741519593, 27.004433753931163],
            [88.07738909055395, 27.035505761923073],
            [88.03763383111794, 27.03665730381502],
            [88.01604994607203, 27.07705068789149],
            [88.02110604556613, 27.08775609477123],
            [87.99162114236188, 27.105482563536818],
            [87.9518403399601, 27.106184083078535],
            [87.89501977026366, 27.09217139254429],
            [87.85194371952943, 27.062283408917285],
            [87.84072931867858, 27.04760580152358],
            [87.77798879616645, 27.01286451304508],
            [87.74387121382478, 27.021259784288638],
            [87.69021440210575, 26.974739948236092],
            [87.67728302139196, 26.95219350050434],
            [87.70627659672907, 26.929114917026766],
            [87.6881918279308, 26.88105223060927],
            [87.63666564015593, 26.860721769608716],
            [87.59961228018061, 26.86219697689447],
            [87.6478354809198, 26.83102350240571],
            [87.65603370636366, 26.80130874447156],
            [87.67921439732791, 26.78153888557126],
            [87.66312612493722, 26.76087815046948],
            [87.6872013145813, 26.75004752632236]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "JHAPA",
        Province: "Province 1",
        LabTestReady: 100,
        LabTestInProgress: 50
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.18485593001766, 26.783225729175996],
            [88.15467991537048, 26.79556635140451],
            [88.1454709919198, 26.77027144512502],
            [88.12553716847779, 26.765629795978032],
            [88.11815182406694, 26.748438780804417],
            [88.10230848894493, 26.751347113056976],
            [88.09203398936556, 26.80143106739221],
            [88.06503409358325, 26.78514530354427],
            [88.04710303854809, 26.74624886096509],
            [88.02647716060402, 26.778381125999626],
            [87.99467396592152, 26.752923825282505],
            [87.9586509083559, 26.781532429313067],
            [87.95030779940808, 26.72875768539547],
            [87.93551482139745, 26.726534173278257],
            [87.93387856656493, 26.688829237222127],
            [87.92427711034125, 26.697538618250196],
            [87.87931207798309, 26.684637678499136],
            [87.86537430794608, 26.673747937307343],
            [87.84117816383959, 26.67733887644788],
            [87.81450998406538, 26.694074689539605],
            [87.7907798537088, 26.666337053125602],
            [87.76508894409466, 26.66531786850348],
            [87.70464645107346, 26.70144088920161],
            [87.70767256014237, 26.709883145041864],
            [87.6872013145813, 26.75004752632236],
            [87.67834989830244, 26.730264718328897],
            [87.65527925389087, 26.716267839107964],
            [87.64174869709905, 26.65091585681537],
            [87.65455735486711, 26.614042358986516],
            [87.63877068426028, 26.598018285924017],
            [87.64589543269858, 26.547663107152896],
            [87.64261779787697, 26.50752703950826],
            [87.65897207493266, 26.458383474910697],
            [87.67570290218364, 26.436175199823033],
            [87.71113264964137, 26.427441898170514],
            [87.73363917666818, 26.407865371051336],
            [87.74743905247807, 26.417941679271454],
            [87.76462519063395, 26.410386139973678],
            [87.77774905422382, 26.43637399384297],
            [87.7738378215698, 26.44999986684887],
            [87.78892276444796, 26.46933033282022],
            [87.84865028877122, 26.436573115574014],
            [87.85797911965068, 26.463419516271905],
            [87.90003045158468, 26.47755491815032],
            [87.92281994278687, 26.44698753996581],
            [87.91638181766527, 26.43078901613916],
            [87.96576876382382, 26.3972665557572],
            [87.99161910187595, 26.39265804381686],
            [87.99190931959689, 26.372663592264566],
            [88.00765104872895, 26.36118105600508],
            [88.0298609207344, 26.364974126870834],
            [88.02718563510173, 26.382106408878876],
            [88.09581583904115, 26.43966660209488],
            [88.09189544383625, 26.46316105938563],
            [88.10665943809188, 26.47349905637755],
            [88.09870704979434, 26.50585404684552],
            [88.11151645415994, 26.545279924544307],
            [88.10541126164689, 26.561020511275576],
            [88.12296049699663, 26.572356847217307],
            [88.14201496137272, 26.62587930107578],
            [88.16306904250341, 26.646083307027112],
            [88.1671476777997, 26.69964635580136],
            [88.18204149318295, 26.71956577332655],
            [88.18485593001766, 26.783225729175996]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KHOTANG",
        Province: "Province 1",
        LabTestReady: 100,
        LabTestInProgress: 50
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.90601846552686, 27.43349732302484],
            [86.8719914645726, 27.428323495690012],
            [86.83085556915147, 27.432201180342215],
            [86.82296605444985, 27.439980290050134],
            [86.8037784165713, 27.415387446413018],
            [86.77120231844039, 27.41174576241363],
            [86.75175342702842, 27.394489827594697],
            [86.68091453452772, 27.39022797308224],
            [86.67858532512898, 27.353550183825536],
            [86.68581814899126, 27.309506710846932],
            [86.6672491479173, 27.29755007328572],
            [86.6707196830432, 27.27210182383129],
            [86.6345356871715, 27.25645883390871],
            [86.61718992464989, 27.234627092409045],
            [86.59080961562837, 27.226206134025915],
            [86.55696783715365, 27.226850058593406],
            [86.52678598634996, 27.193970764370114],
            [86.4903108566922, 27.186218639820687],
            [86.43347713780902, 27.149099832178045],
            [86.45944266744176, 27.105633618325925],
            [86.49235594876727, 27.104783878136914],
            [86.52148627986011, 27.118966058178135],
            [86.53318990681161, 27.13512295443769],
            [86.57632861142305, 27.151435937266356],
            [86.64033477294312, 27.13306228388419],
            [86.66881358520786, 27.120054109852788],
            [86.69926912938323, 27.093036331674735],
            [86.72673057280261, 27.03948584107968],
            [86.74696100013863, 27.02483635573422],
            [86.7546897975666, 26.975643010954037],
            [86.7644931580865, 26.95754471083866],
            [86.7608418874053, 26.925214244317033],
            [86.7730504709191, 26.884915061997553],
            [86.80527928164749, 26.872981039581965],
            [86.83379646834037, 26.874881362663192],
            [86.8627580168653, 26.895130893749755],
            [86.91389853906507, 26.895739973088453],
            [86.93180645557551, 26.907560369007996],
            [86.95155813794786, 26.904610220358595],
            [86.96506369421911, 26.927772793884948],
            [86.97257310693666, 26.973331043647182],
            [86.95838928397295, 26.981110237819276],
            [86.96712938762067, 27.00747738241226],
            [86.94505279703216, 27.020233188168127],
            [86.96463905015142, 27.058082759105325],
            [86.94661805421926, 27.085335294334143],
            [86.9243123875278, 27.091534734421447],
            [86.89706140734057, 27.115963856433975],
            [86.91487333518415, 27.12749496225314],
            [86.92234231287989, 27.158417052553215],
            [86.91277712804859, 27.169616897462475],
            [86.92967373952904, 27.20385635814477],
            [86.92834086413146, 27.223242935707106],
            [86.89790670798456, 27.251857813399425],
            [86.91233024897332, 27.266782434449368],
            [86.94824488892613, 27.266170805289487],
            [86.96527965362323, 27.295170791899732],
            [86.98445788053938, 27.30745348113917],
            [86.94807364774239, 27.33779084659325],
            [86.92383558805919, 27.37064832618102],
            [86.9305472060157, 27.385056481530945],
            [86.91043758555801, 27.407586008237985],
            [86.90601846552686, 27.43349732302484]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MORANG",
        Province: "Province 1",
        LabTestReady: 100,
        LabTestInProgress: 50
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.58011194818718, 26.861407384612438],
            [87.52039779053331, 26.86221518547174],
            [87.49048844037992, 26.87240857332163],
            [87.45320671067962, 26.85822915123598],
            [87.42141043598969, 26.870526073007117],
            [87.39195823234657, 26.86586539656282],
            [87.36898271601409, 26.845763125109716],
            [87.34748510511558, 26.849678464996316],
            [87.3495791873069, 26.82215503656967],
            [87.32792989186822, 26.79541287041917],
            [87.32393892545642, 26.77464611717254],
            [87.33888151888252, 26.736051380198067],
            [87.3140020277237, 26.70571278273679],
            [87.30908503218836, 26.68592448829292],
            [87.28509862627898, 26.6576862229806],
            [87.30059497605136, 26.615239450396306],
            [87.28525042596567, 26.590886245519453],
            [87.28400883312865, 26.55916986605166],
            [87.2459945062681, 26.521182642802614],
            [87.25248730200941, 26.50320394806289],
            [87.23859043507251, 26.470034972910735],
            [87.24508564167519, 26.464935217337832],
            [87.24511084464928, 26.414336499715553],
            [87.26617049946849, 26.40546626138049],
            [87.2658797092837, 26.37395918554606],
            [87.3123339467281, 26.37155907370455],
            [87.34097375500353, 26.347837070322743],
            [87.35830735731568, 26.36114071484218],
            [87.35638221203716, 26.388281884137687],
            [87.38851514914045, 26.419145946759706],
            [87.42776232059742, 26.42250240230205],
            [87.46659666809241, 26.440465519566708],
            [87.51637950805923, 26.43101065211643],
            [87.54753044386607, 26.41861427660241],
            [87.5528245382233, 26.40581473159734],
            [87.58744328885017, 26.392947207868435],
            [87.58712525023618, 26.38221157183282],
            [87.65183047156859, 26.39359366166532],
            [87.65025728012675, 26.405960185523494],
            [87.67831950745358, 26.415901006211794],
            [87.67570290218364, 26.436175199823033],
            [87.65897207493266, 26.458383474910697],
            [87.64261779787697, 26.50752703950826],
            [87.64589543269858, 26.547663107152896],
            [87.63877068426028, 26.598018285924017],
            [87.65455735486711, 26.614042358986516],
            [87.64174869709905, 26.65091585681537],
            [87.65527925389087, 26.716267839107964],
            [87.67834989830244, 26.730264718328897],
            [87.6872013145813, 26.75004752632236],
            [87.66312612493722, 26.76087815046948],
            [87.67921439732791, 26.78153888557126],
            [87.65603370636366, 26.80130874447156],
            [87.6478354809198, 26.83102350240571],
            [87.59961228018061, 26.86219697689447],
            [87.58011194818718, 26.861407384612438]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "OKHALDHUNGA",
        Province: "Province 1",
        LabTestReady: 100,
        LabTestInProgress: 50
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.67858532512898, 27.353550183825536],
            [86.65783526930203, 27.340050770597745],
            [86.57076889147434, 27.340778327462754],
            [86.56503719218176, 27.364452129269424],
            [86.50892620216553, 27.411766026379954],
            [86.5025936737292, 27.425425829351784],
            [86.47006424304733, 27.42272475980676],
            [86.46741784643217, 27.445573184379082],
            [86.44033497992945, 27.48507139517286],
            [86.42538042496633, 27.485788886895055],
            [86.39825378198206, 27.518207224465844],
            [86.37587382823155, 27.5159764764937],
            [86.35560319789273, 27.52626947915657],
            [86.35494549376064, 27.52486079434453],
            [86.34653688929606, 27.523524176919576],
            [86.34138003864682, 27.52188892171629],
            [86.33827683144142, 27.520082742804057],
            [86.33441849858986, 27.515718825477542],
            [86.3332099569757, 27.510871156943335],
            [86.33080185392986, 27.509936793280893],
            [86.32603542249127, 27.504591324630645],
            [86.32501658770569, 27.500295001650187],
            [86.32399178622198, 27.499376273300847],
            [86.32022024026271, 27.499211266157598],
            [86.3187302087422, 27.498141273444904],
            [86.31554861667671, 27.492451223544915],
            [86.31291296778696, 27.49168239731077],
            [86.3122998258993, 27.489939810587],
            [86.30645153659829, 27.48512528482755],
            [86.30539341157768, 27.482857031151173],
            [86.30298703614199, 27.48254041278033],
            [86.29728473360105, 27.479558311429017],
            [86.29539248814696, 27.47926449281389],
            [86.29294930538704, 27.476501759546],
            [86.29193867847974, 27.47304682327495],
            [86.29215964183695, 27.470631516885284],
            [86.28918090882726, 27.468493220545447],
            [86.28513741948768, 27.4664983291883],
            [86.27676031853595, 27.464158207287593],
            [86.274778978123, 27.462448969307857],
            [86.26761403675121, 27.45923244300256],
            [86.26410064408188, 27.45645455242749],
            [86.26177326678636, 27.453301543429763],
            [86.25975093668738, 27.448990455168307],
            [86.25702805584767, 27.445503594151706],
            [86.25589552379844, 27.43959976047225],
            [86.25585203284719, 27.435621781578824],
            [86.25126019862667, 27.432526448655896],
            [86.25198732851732, 27.428566140448847],
            [86.24796317411652, 27.424499052717056],
            [86.24846644015405, 27.421539268264866],
            [86.24742448662138, 27.419435399434974],
            [86.2447132783325, 27.419971281547557],
            [86.24122983523442, 27.41821056656651],
            [86.24031167022021, 27.415588291891574],
            [86.2375607324978, 27.412714621125662],
            [86.23758529752082, 27.410144909686313],
            [86.23444938234526, 27.40786468433173],
            [86.23609523349934, 27.406524501615337],
            [86.2354566511766, 27.403709695954753],
            [86.23238545715762, 27.40238426178193],
            [86.23059499777447, 27.399378082189667],
            [86.2277157899502, 27.397900143960705],
            [86.22658597812826, 27.393079053572308],
            [86.22750199276743, 27.39130394570684],
            [86.22692574662594, 27.388703817153882],
            [86.2249219463748, 27.387072717026076],
            [86.22562442055832, 27.38401714938446],
            [86.22210754028974, 27.38249716872468],
            [86.22381115080772, 27.378546986290328],
            [86.22376647840723, 27.375169098361376],
            [86.22472281475589, 27.370587472076533],
            [86.2220748618428, 27.3675561215552],
            [86.21926087141786, 27.362610520358693],
            [86.21947351960947, 27.361025565247086],
            [86.22184964395024, 27.360380003016957],
            [86.21996081240377, 27.35643907401621],
            [86.21712907107664, 27.35285157814614],
            [86.21841462166203, 27.35077851350582],
            [86.21662351963677, 27.34246094915757],
            [86.21788109811094, 27.340487003120984],
            [86.21702763349016, 27.33879453009316],
            [86.2178853545129, 27.336416738555624],
            [86.2157007202864, 27.33388650738876],
            [86.21710386296809, 27.32857637353577],
            [86.22196658618944, 27.32777097352255],
            [86.22193793610505, 27.32658176072135],
            [86.21897682577791, 27.323117676453936],
            [86.22117024787403, 27.32120758106682],
            [86.22841944409119, 27.32017832086994],
            [86.22880528803165, 27.31625454548099],
            [86.23142825082947, 27.31437372700318],
            [86.23143048761213, 27.312537135876784],
            [86.23312068790294, 27.310418760447618],
            [86.2336877830212, 27.307748183285383],
            [86.23647126361602, 27.306770641067956],
            [86.2378902305179, 27.302504918876153],
            [86.23543433646746, 27.297938470403448],
            [86.23580802835173, 27.295417950535406],
            [86.23794945920594, 27.29133453678678],
            [86.24018319390501, 27.2900425017339],
            [86.24016353767604, 27.288338933012817],
            [86.23556434361358, 27.286517874431127],
            [86.23347515647141, 27.285015040868416],
            [86.23155748510288, 27.281852497317583],
            [86.22897226674412, 27.280802640544017],
            [86.22686225049316, 27.27773148111721],
            [86.22344096713995, 27.277055955916083],
            [86.22305208284736, 27.27433273234704],
            [86.21942586877, 27.27305805082877],
            [86.21884027346469, 27.27071729478947],
            [86.21585347420843, 27.267900556992895],
            [86.21420032916038, 27.267266272937583],
            [86.21137137624399, 27.263841117863926],
            [86.21113423915652, 27.26094721971638],
            [86.20840555960775, 27.25953293218506],
            [86.20428127402838, 27.253968125361965],
            [86.20512206158902, 27.250401179549556],
            [86.2035805162654, 27.250270602687838],
            [86.20725624554642, 27.247728334471077],
            [86.21112428640107, 27.24581203375686],
            [86.2139479534488, 27.245164605410164],
            [86.21841594000242, 27.24176223259038],
            [86.22351144378129, 27.239058256930015],
            [86.22916430097146, 27.237397266840365],
            [86.23903450700446, 27.240946277462037],
            [86.2419309348337, 27.24619430506706],
            [86.24414625782892, 27.24752847022501],
            [86.24836750538472, 27.248228083623108],
            [86.25559276383927, 27.253363642575795],
            [86.25705815760432, 27.257802809228508],
            [86.25896251932082, 27.258595838408105],
            [86.26157561332901, 27.257495037676915],
            [86.26462373915508, 27.2606698968016],
            [86.2670781556737, 27.25995398966179],
            [86.26630633682723, 27.25450097260937],
            [86.26863832081943, 27.252640489581328],
            [86.27238262712406, 27.252199686033798],
            [86.27712182402236, 27.250204849035697],
            [86.27924127729513, 27.247106585958846],
            [86.28176480990838, 27.245772508216962],
            [86.28531050118112, 27.246449523724166],
            [86.28788644761134, 27.245693243748395],
            [86.29125259864935, 27.243185516725998],
            [86.29427544153897, 27.238945325315626],
            [86.29557391146453, 27.238241159728823],
            [86.30611467840765, 27.23721305423248],
            [86.30830314841745, 27.233956692538634],
            [86.31162699426189, 27.2324974406889],
            [86.31588220971177, 27.231439793997122],
            [86.3190172683509, 27.231739351427784],
            [86.32828486338782, 27.230168554232442],
            [86.33166020370444, 27.231125607037853],
            [86.33861970486934, 27.229861256587544],
            [86.34098435067776, 27.229023995892188],
            [86.34145768795914, 27.22493549135564],
            [86.34306946435048, 27.22146157108365],
            [86.3430757118588, 27.219105990673324],
            [86.34015252457428, 27.214166673781016],
            [86.33999906671494, 27.21017677480706],
            [86.34128017540638, 27.208935031558784],
            [86.34265050561335, 27.204365624437944],
            [86.34493139679626, 27.202108684188836],
            [86.35132225851902, 27.200825215538373],
            [86.3552668592885, 27.20156769921836],
            [86.35878754992376, 27.201150643035806],
            [86.3651791940226, 27.196188691019536],
            [86.36873928625613, 27.192553998077628],
            [86.37129846379601, 27.1926173931001],
            [86.37667861033134, 27.194044888455622],
            [86.38005592678311, 27.192189324879365],
            [86.38372142152059, 27.183094352888954],
            [86.38647703417227, 27.180592838745834],
            [86.38986266424817, 27.179939727182987],
            [86.43347713780902, 27.149099832178045],
            [86.4903108566922, 27.186218639820687],
            [86.52678598634996, 27.193970764370114],
            [86.55696783715365, 27.226850058593406],
            [86.59080961562837, 27.226206134025915],
            [86.61718992464989, 27.234627092409045],
            [86.6345356871715, 27.25645883390871],
            [86.6707196830432, 27.27210182383129],
            [86.6672491479173, 27.29755007328572],
            [86.68581814899126, 27.309506710846932],
            [86.67858532512898, 27.353550183825536]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "PANCHTHAR",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.99162114236188, 27.105482563536818],
            [87.99121373683396, 27.130841149176156],
            [88.00701515475745, 27.143246354532902],
            [88.01923986624972, 27.235936085964365],
            [88.0308585949247, 27.250078623954273],
            [88.03171789256619, 27.286284090045037],
            [88.05251692911696, 27.324571992430585],
            [88.06648596883016, 27.336304097885293],
            [88.04181037688608, 27.37024015928075],
            [88.05434645998847, 27.4173648158858],
            [88.07970382690172, 27.42662391337696],
            [88.0679768860526, 27.43835353844599],
            [88.03736756531197, 27.41355970856014],
            [88.01753414888456, 27.354285421192735],
            [88.00384351090521, 27.329131125421867],
            [87.95043957838544, 27.28270408642076],
            [87.89440594847788, 27.286464659680668],
            [87.86350580606923, 27.279517825632112],
            [87.83970174124323, 27.286217174558644],
            [87.78171057547004, 27.272905029843127],
            [87.7318031117526, 27.27866229831676],
            [87.696247876462, 27.291397779429477],
            [87.67985309565636, 27.27107464225336],
            [87.75121253910076, 27.21810197269162],
            [87.74494990302091, 27.19943512214536],
            [87.7199682444174, 27.189594968143407],
            [87.71115625488663, 27.15697954520357],
            [87.58866996351256, 27.098887737065233],
            [87.56304773320484, 27.062421037253895],
            [87.53308261768417, 27.034691368425573],
            [87.54211270714747, 27.02302505338615],
            [87.50147446346394, 26.98452295056853],
            [87.49256784978512, 26.98537790389863],
            [87.52483291286684, 26.96022612234221],
            [87.52230787532753, 26.926077442957567],
            [87.53130122888567, 26.89990501970461],
            [87.52223967490225, 26.88485211278344],
            [87.5628727707214, 26.882685527965943],
            [87.58011194818718, 26.861407384612438],
            [87.59961228018061, 26.86219697689447],
            [87.63666564015593, 26.860721769608716],
            [87.6881918279308, 26.88105223060927],
            [87.70627659672907, 26.929114917026766],
            [87.67728302139196, 26.95219350050434],
            [87.69021440210575, 26.974739948236092],
            [87.74387121382478, 27.021259784288638],
            [87.77798879616645, 27.01286451304508],
            [87.84072931867858, 27.04760580152358],
            [87.85194371952943, 27.062283408917285],
            [87.89501977026366, 27.09217139254429],
            [87.9518403399601, 27.106184083078535],
            [87.99162114236188, 27.105482563536818]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SANKHUWASABHA",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.66546594802459, 27.80692101895406],
            [87.66951219990266, 27.83493167376876],
            [87.63252408389206, 27.832651502679642],
            [87.61094909943968, 27.810857223313775],
            [87.5910949905359, 27.819501214937336],
            [87.57879379012412, 27.86204340111073],
            [87.55213157574416, 27.865209814943892],
            [87.53543202741477, 27.842991603778326],
            [87.49009755868893, 27.84651720598175],
            [87.44979995119856, 27.822357672976246],
            [87.40361952976829, 27.834115220296404],
            [87.43317825755466, 27.846249621738533],
            [87.41920500191637, 27.86060371549548],
            [87.3723089500895, 27.844385422710605],
            [87.36066514323362, 27.82892983300147],
            [87.31772281663233, 27.826535449919728],
            [87.30412146200261, 27.84321234506085],
            [87.26517769532303, 27.850882516869987],
            [87.2386514920019, 27.83951454574458],
            [87.22584611989907, 27.819846187153548],
            [87.15673343723283, 27.82451977080275],
            [87.115905235614, 27.840376123054895],
            [87.10752050457928, 27.8739643568433],
            [87.08882306923786, 27.882654995574274],
            [87.07861372743804, 27.91515266623723],
            [87.05338302397124, 27.92663300044507],
            [87.03913197437889, 27.950435996204344],
            [86.99220721914554, 27.95483638369201],
            [86.98328932353874, 27.949511010938686],
            [86.98109297419582, 27.919880239382625],
            [86.96706258040106, 27.902716955556127],
            [86.98979490679929, 27.850613506615442],
            [86.97673749085942, 27.825926659086615],
            [87.01015698745356, 27.814789249247173],
            [86.98310525706718, 27.79108468754281],
            [86.97895872187173, 27.750284493939755],
            [87.00824299633179, 27.7260063635725],
            [86.98967685745508, 27.693060335836687],
            [86.98369256643143, 27.646314320003604],
            [86.9596646187103, 27.61976418823324],
            [86.95345299110048, 27.572359723607658],
            [86.9667073819952, 27.541569440110397],
            [86.94321500322272, 27.51691158603846],
            [86.96293148010852, 27.483547142319686],
            [86.94016530823029, 27.45922270810498],
            [86.98042236915994, 27.44192801343838],
            [87.01007975016833, 27.436071556471447],
            [87.04480309117454, 27.447251681906483],
            [87.05585009466822, 27.44125484619727],
            [87.07317297783449, 27.45994962399283],
            [87.10998230657277, 27.45948769462362],
            [87.14033336137365, 27.447558228407313],
            [87.12398372134919, 27.426287381280673],
            [87.13122591606081, 27.39485203987172],
            [87.16860021458413, 27.375872007526198],
            [87.19224171781742, 27.35244293578036],
            [87.1822531323421, 27.31660929681059],
            [87.20152233427139, 27.301440290902146],
            [87.20259716770171, 27.24650234332198],
            [87.23760501195464, 27.217150177541875],
            [87.24683499545434, 27.19196849738384],
            [87.26315507020138, 27.177412006784316],
            [87.29226836760911, 27.19041894221843],
            [87.31239742847032, 27.18235626069018],
            [87.3570210579744, 27.190912116727315],
            [87.4067503734144, 27.136397363159205],
            [87.4202579729089, 27.176379402104825],
            [87.45081624421853, 27.179452555029307],
            [87.46912034897474, 27.19685460361098],
            [87.46737462411696, 27.21693318115608],
            [87.479595352597, 27.249988305250024],
            [87.51653974101401, 27.29672348338491],
            [87.49252867821293, 27.33252114623404],
            [87.45850678127526, 27.371664768376572],
            [87.44444376553766, 27.406234277417028],
            [87.46109167568412, 27.446741878948117],
            [87.45885127668598, 27.474316716178784],
            [87.48703676785235, 27.546367737809245],
            [87.48183403160252, 27.56717505362267],
            [87.51279332573098, 27.598976618708953],
            [87.52772959577572, 27.603078903866276],
            [87.5442883252377, 27.64579755308595],
            [87.54936760341846, 27.68077367571955],
            [87.56103860965877, 27.69838845553415],
            [87.5813610384856, 27.67604949390269],
            [87.60783147710733, 27.67532898195685],
            [87.6219079787277, 27.711866382633016],
            [87.63937474082063, 27.71011048524127],
            [87.64121557676174, 27.730973859875085],
            [87.66385209731538, 27.75081158436592],
            [87.65773381917144, 27.759432270278207],
            [87.67687363855926, 27.788593104323162],
            [87.66546594802459, 27.80692101895406]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SOLUKHUMBU",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.98328932353874, 27.949511010938686],
            [86.93347706418818, 27.962613322354727],
            [86.92535093097172, 27.987889607460527],
            [86.88992356534989, 27.99697403595885],
            [86.85407281815283, 28.02812517285029],
            [86.82809202495521, 28.015273419596973],
            [86.79009693675938, 28.018936538959036],
            [86.77291357687108, 28.03800977681425],
            [86.74719309872161, 28.045678570008413],
            [86.76490761081662, 28.07470810037817],
            [86.7382784555542, 28.103784147262424],
            [86.68909769406652, 28.108667521498546],
            [86.66118024024289, 28.095039933838407],
            [86.65794854271576, 28.0811026875135],
            [86.63480969769313, 28.07073258123306],
            [86.60835712421535, 28.076017952001912],
            [86.60248381484176, 28.101923714048084],
            [86.57440801277502, 28.113061281451635],
            [86.56234129770097, 28.10716197216761],
            [86.56534063441237, 28.06899382140108],
            [86.53651208779603, 28.050843877158567],
            [86.51283686763702, 27.98475938012047],
            [86.51556950910974, 27.956621571758177],
            [86.51822079870233, 27.953203799103626],
            [86.51992831928757, 27.944061155259337],
            [86.52173220103295, 27.94043504992991],
            [86.52379517521175, 27.939821781730004],
            [86.5284548395408, 27.941399034816747],
            [86.53448377103885, 27.941688043886206],
            [86.53881215879096, 27.93570126949647],
            [86.5420643343205, 27.93331835300985],
            [86.54415734080463, 27.93275902066554],
            [86.5478027129026, 27.93045847168814],
            [86.54871838456951, 27.92887314710977],
            [86.54978877805245, 27.919134525495092],
            [86.55342389990558, 27.911827492663534],
            [86.55471600998935, 27.907041830173313],
            [86.55424899414231, 27.902706260150072],
            [86.55174971561138, 27.899092789368392],
            [86.5522627923373, 27.89220415330927],
            [86.55462245140777, 27.88553805137975],
            [86.55126009711924, 27.87826231975124],
            [86.55251418300925, 27.867879030102674],
            [86.55445012530647, 27.861022001059386],
            [86.5541026423939, 27.85714703151674],
            [86.55014177928393, 27.848414064176236],
            [86.54914052082354, 27.841628691293504],
            [86.54734184312626, 27.83737220823831],
            [86.54710985026442, 27.83486934588837],
            [86.54539767097366, 27.831659891234988],
            [86.54384266415747, 27.82708152294834],
            [86.54353314063485, 27.821507765309732],
            [86.54073214578654, 27.81813676738623],
            [86.540352555756, 27.814877557256768],
            [86.5424263271506, 27.811170794021326],
            [86.54637911375293, 27.811463586958666],
            [86.55295493457047, 27.808755006243615],
            [86.56073812908635, 27.80622373404808],
            [86.56217426614613, 27.80646068120728],
            [86.56708297303058, 27.804007879681745],
            [86.56563069757864, 27.79961953899775],
            [86.56881507969167, 27.795387869338168],
            [86.56883192308399, 27.79111919977104],
            [86.56616967329198, 27.787277591923928],
            [86.56559953456525, 27.78280404368894],
            [86.56619654549196, 27.780511334980826],
            [86.56588689133508, 27.776241632407107],
            [86.56391825460697, 27.770486634960417],
            [86.5660715433834, 27.762621540258312],
            [86.57036096238936, 27.759354385748978],
            [86.57276265428666, 27.754598997813737],
            [86.56918234889494, 27.751916513780554],
            [86.5628872545068, 27.749347231296255],
            [86.5632763631731, 27.744937568910046],
            [86.55825929000962, 27.738439562266876],
            [86.55627261693047, 27.731450227417568],
            [86.55689843602546, 27.72858685334029],
            [86.55436911872184, 27.72401890406944],
            [86.55399296642243, 27.721824655670236],
            [86.55354892331252, 27.719580544352326],
            [86.55170041175778, 27.718015523884304],
            [86.55107852733784, 27.716565011345224],
            [86.55149433526469, 27.71377542315327],
            [86.55096239718866, 27.710878969991533],
            [86.54878902711317, 27.70967836378805],
            [86.54791075607797, 27.708026217450374],
            [86.54326393889555, 27.7042362959765],
            [86.5409890615135, 27.702886322674818],
            [86.54004341921778, 27.700947347798785],
            [86.53911447398471, 27.696623580809824],
            [86.54033641926085, 27.694163862024286],
            [86.54054663238648, 27.692235496891364],
            [86.53919697113203, 27.689882276270296],
            [86.53890248533773, 27.68599158732204],
            [86.53898691132355, 27.683164778238925],
            [86.53669269682439, 27.67818218239821],
            [86.53881017641382, 27.674992128518866],
            [86.53803385551055, 27.670312401879205],
            [86.53749771213329, 27.66982100400755],
            [86.53548288363586, 27.66835897425639],
            [86.53391193255166, 27.664130073771013],
            [86.53131556113405, 27.66240424492458],
            [86.52933051615943, 27.66195311440712],
            [86.52718345743334, 27.659554201867564],
            [86.52882870502694, 27.655618165824155],
            [86.52694568772615, 27.65312764019134],
            [86.5262178053352, 27.650415412038356],
            [86.5221323019561, 27.649365809607396],
            [86.52039928845537, 27.64848889490606],
            [86.51758586560769, 27.64699227665437],
            [86.513706242676, 27.648203858829532],
            [86.51353700814093, 27.648085941069798],
            [86.51231706467016, 27.643582726471557],
            [86.51138567640515, 27.64243561551623],
            [86.50696074348984, 27.640199737957566],
            [86.5068993299773, 27.63850057838883],
            [86.50598739957692, 27.635020474933665],
            [86.50408852615327, 27.63307557281358],
            [86.50395354055, 27.62937488195162],
            [86.50049261760208, 27.624741690348387],
            [86.49986367058001, 27.6203712562258],
            [86.4954780918702, 27.618843816305368],
            [86.49416899089098, 27.617022768329623],
            [86.49177350376198, 27.61406283549918],
            [86.48461211682286, 27.61716359033379],
            [86.48036710578417, 27.617055229400997],
            [86.47662256138189, 27.61583406971031],
            [86.47290790664195, 27.61698196650114],
            [86.469694762523, 27.617132147550716],
            [86.467239343954, 27.616163870493732],
            [86.4636391424895, 27.61608682346398],
            [86.45474883250584, 27.613994493706834],
            [86.44741269846085, 27.612918583060978],
            [86.44637655508303, 27.612201496321322],
            [86.43479109292218, 27.610288880238226],
            [86.43022502393997, 27.604248334605817],
            [86.42467504205996, 27.602007567847252],
            [86.41987933562412, 27.598264843240873],
            [86.41795991435569, 27.591393345095593],
            [86.41345424004497, 27.58680775312539],
            [86.41092544802427, 27.586756464142898],
            [86.40781315564615, 27.58448923750466],
            [86.4026239045071, 27.58423462516256],
            [86.40106844504972, 27.58314042905244],
            [86.398241562158, 27.58331998941228],
            [86.39272598328616, 27.577100386042762],
            [86.38974997132983, 27.57548541621596],
            [86.38774352333361, 27.572444197965734],
            [86.38182177692262, 27.571813300828637],
            [86.37629331253211, 27.568079348742277],
            [86.37515051907378, 27.565565287056646],
            [86.37163951316127, 27.562079361603786],
            [86.3687470770548, 27.556443751371965],
            [86.37066691985271, 27.55194900672051],
            [86.36896062653906, 27.548868280985168],
            [86.36482330269902, 27.546687990338093],
            [86.3585360892324, 27.539784372259742],
            [86.35743799594184, 27.533405421418596],
            [86.35765302078524, 27.52961593686731],
            [86.35560319789273, 27.52626947915657],
            [86.37587382823155, 27.5159764764937],
            [86.39825378198206, 27.518207224465844],
            [86.42538042496633, 27.485788886895055],
            [86.44033497992945, 27.48507139517286],
            [86.46741784643217, 27.445573184379082],
            [86.47006424304733, 27.42272475980676],
            [86.5025936737292, 27.425425829351784],
            [86.50892620216553, 27.411766026379954],
            [86.56503719218176, 27.364452129269424],
            [86.57076889147434, 27.340778327462754],
            [86.65783526930203, 27.340050770597745],
            [86.67858532512898, 27.353550183825536],
            [86.68091453452772, 27.39022797308224],
            [86.75175342702842, 27.394489827594697],
            [86.77120231844039, 27.41174576241363],
            [86.8037784165713, 27.415387446413018],
            [86.82296605444985, 27.439980290050134],
            [86.83085556915147, 27.432201180342215],
            [86.8719914645726, 27.428323495690012],
            [86.90601846552686, 27.43349732302484],
            [86.93079611167103, 27.44155573304249],
            [86.94016530823029, 27.45922270810498],
            [86.96293148010852, 27.483547142319686],
            [86.94321500322272, 27.51691158603846],
            [86.9667073819952, 27.541569440110397],
            [86.95345299110048, 27.572359723607658],
            [86.9596646187103, 27.61976418823324],
            [86.98369256643143, 27.646314320003604],
            [86.98967685745508, 27.693060335836687],
            [87.00824299633179, 27.7260063635725],
            [86.97895872187173, 27.750284493939755],
            [86.98310525706718, 27.79108468754281],
            [87.01015698745356, 27.814789249247173],
            [86.97673749085942, 27.825926659086615],
            [86.98979490679929, 27.850613506615442],
            [86.96706258040106, 27.902716955556127],
            [86.98109297419582, 27.919880239382625],
            [86.98328932353874, 27.949511010938686]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SUNSARI",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.34748510511558, 26.849678464996316],
            [87.32326176413709, 26.854580608172988],
            [87.29690729956097, 26.87491998656162],
            [87.25682219642317, 26.865988188566018],
            [87.23312124510731, 26.87867472238181],
            [87.21798653828566, 26.863618651557278],
            [87.16480987963924, 26.87794058862888],
            [87.1523150200778, 26.868497510739264],
            [87.1441851299434, 26.822824748324443],
            [87.11299192684994, 26.765116949007503],
            [87.09916451800659, 26.751351039456612],
            [87.06461820863144, 26.744605718301116],
            [87.04331228463025, 26.756314135755517],
            [87.00999783304415, 26.710886539441386],
            [87.01284383605837, 26.670100784819237],
            [86.99111409256746, 26.64959539274754],
            [86.97883989097498, 26.625236250778496],
            [86.99242600257598, 26.57238991848934],
            [86.96628331089356, 26.546712464700857],
            [86.9332895522463, 26.533619169608933],
            [86.9040946658046, 26.48835231455536],
            [86.92985823654337, 26.489678387952292],
            [86.93262074363875, 26.5168204387948],
            [86.97509554438086, 26.52010862780862],
            [87.01528483113742, 26.532171637928545],
            [87.04232543245689, 26.568394125432626],
            [87.04464185369822, 26.58734232180761],
            [87.07178180576508, 26.585846357822888],
            [87.0726699995247, 26.54203351317547],
            [87.08185193651711, 26.512604178260553],
            [87.0915105068903, 26.450416270000183],
            [87.1390386086872, 26.415009236005012],
            [87.16248074542894, 26.40412540014969],
            [87.2113359225371, 26.413210138969315],
            [87.22070090978102, 26.40391654743918],
            [87.24511084464928, 26.414336499715553],
            [87.24508564167519, 26.464935217337832],
            [87.23859043507251, 26.470034972910735],
            [87.25248730200941, 26.50320394806289],
            [87.2459945062681, 26.521182642802614],
            [87.28400883312865, 26.55916986605166],
            [87.28525042596567, 26.590886245519453],
            [87.30059497605136, 26.615239450396306],
            [87.28509862627898, 26.6576862229806],
            [87.30908503218836, 26.68592448829292],
            [87.3140020277237, 26.70571278273679],
            [87.33888151888252, 26.736051380198067],
            [87.32393892545642, 26.77464611717254],
            [87.32792989186822, 26.79541287041917],
            [87.3495791873069, 26.82215503656967],
            [87.34748510511558, 26.849678464996316]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "TAPLEJUNG",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.0679768860526, 27.43835353844599],
            [88.06705035842994, 27.45795504811807],
            [88.04396268463128, 27.4785569298784],
            [88.04457176233804, 27.498471626889135],
            [88.0608658544349, 27.513507046418148],
            [88.05905601019333, 27.53278344110722],
            [88.08508676955068, 27.590256994276103],
            [88.11366632094278, 27.610680295678414],
            [88.1198475736097, 27.645136688898752],
            [88.14462205080866, 27.665689720334907],
            [88.15478069477332, 27.690735079704606],
            [88.14745566589899, 27.703037043850767],
            [88.15842377199573, 27.740857665632152],
            [88.17855169170485, 27.74709687205471],
            [88.18198166391447, 27.77653052745815],
            [88.19729057688984, 27.790886551478938],
            [88.17320355570075, 27.82073645757523],
            [88.20125987425116, 27.837520759402793],
            [88.1957251200661, 27.854601145013547],
            [88.1785833011953, 27.856702320132282],
            [88.13509836107919, 27.8817335920258],
            [88.09580776576064, 27.867254563201968],
            [88.08322446613433, 27.895921413624045],
            [88.06454138070826, 27.886370779112415],
            [88.02725053290878, 27.905486693131497],
            [87.9848445916591, 27.88600211592986],
            [87.95608010883173, 27.90885954882463],
            [87.93064670752113, 27.918035193672814],
            [87.86589970369211, 27.910834238027526],
            [87.85402182640236, 27.928794360240573],
            [87.85647235074299, 27.948438289406354],
            [87.83180565242623, 27.952923365499583],
            [87.82516327928211, 27.921013195861516],
            [87.78586990049476, 27.900340417778075],
            [87.7541651055772, 27.836751274430597],
            [87.72535273063377, 27.805344832895965],
            [87.6871360527077, 27.812412371795684],
            [87.66546594802459, 27.80692101895406],
            [87.67687363855926, 27.788593104323162],
            [87.65773381917144, 27.759432270278207],
            [87.66385209731538, 27.75081158436592],
            [87.64121557676174, 27.730973859875085],
            [87.63937474082063, 27.71011048524127],
            [87.6219079787277, 27.711866382633016],
            [87.60783147710733, 27.67532898195685],
            [87.5813610384856, 27.67604949390269],
            [87.56103860965877, 27.69838845553415],
            [87.54936760341846, 27.68077367571955],
            [87.5442883252377, 27.64579755308595],
            [87.52772959577572, 27.603078903866276],
            [87.51279332573098, 27.598976618708953],
            [87.48183403160252, 27.56717505362267],
            [87.48703676785235, 27.546367737809245],
            [87.45885127668598, 27.474316716178784],
            [87.46109167568412, 27.446741878948117],
            [87.44444376553766, 27.406234277417028],
            [87.45850678127526, 27.371664768376572],
            [87.49252867821293, 27.33252114623404],
            [87.51653974101401, 27.29672348338491],
            [87.55043136922511, 27.286880650731792],
            [87.60672962399724, 27.283633332543648],
            [87.65137236174465, 27.268184440273256],
            [87.67985309565636, 27.27107464225336],
            [87.696247876462, 27.291397779429477],
            [87.7318031117526, 27.27866229831676],
            [87.78171057547004, 27.272905029843127],
            [87.83970174124323, 27.286217174558644],
            [87.86350580606923, 27.279517825632112],
            [87.89440594847788, 27.286464659680668],
            [87.95043957838544, 27.28270408642076],
            [88.00384351090521, 27.329131125421867],
            [88.01753414888456, 27.354285421192735],
            [88.03736756531197, 27.41355970856014],
            [88.0679768860526, 27.43835353844599]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "TERHATHUM",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.67985309565636, 27.27107464225336],
            [87.65137236174465, 27.268184440273256],
            [87.60672962399724, 27.283633332543648],
            [87.55043136922511, 27.286880650731792],
            [87.51653974101401, 27.29672348338491],
            [87.479595352597, 27.249988305250024],
            [87.46737462411696, 27.21693318115608],
            [87.46912034897474, 27.19685460361098],
            [87.45081624421853, 27.179452555029307],
            [87.4202579729089, 27.176379402104825],
            [87.4067503734144, 27.136397363159205],
            [87.39203472872894, 27.1025478250714],
            [87.40150201281638, 27.073866638581535],
            [87.42664842279706, 27.04632738030133],
            [87.44096201113216, 27.006601496415136],
            [87.43898649341466, 26.971111385853067],
            [87.46383464933551, 26.96808715641304],
            [87.49256784978512, 26.98537790389863],
            [87.50147446346394, 26.98452295056853],
            [87.54211270714747, 27.02302505338615],
            [87.53308261768417, 27.034691368425573],
            [87.56304773320484, 27.062421037253895],
            [87.58866996351256, 27.098887737065233],
            [87.71115625488663, 27.15697954520357],
            [87.7199682444174, 27.189594968143407],
            [87.74494990302091, 27.19943512214536],
            [87.75121253910076, 27.21810197269162],
            [87.67985309565636, 27.27107464225336]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "UDAYAPUR",
        Province: "Province 1"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.43347713780902, 27.149099832178045],
            [86.38986266424817, 27.179939727182987],
            [86.38428188913328, 27.175271742683876],
            [86.38487277705732, 27.173965680864846],
            [86.38118727802117, 27.17012039638448],
            [86.38093453312227, 27.16849022395332],
            [86.3781392107829, 27.165558123705704],
            [86.37909865019971, 27.16398743498708],
            [86.37739276500598, 27.161705452193342],
            [86.37520031962652, 27.1608586150258],
            [86.37364049862647, 27.15870134829681],
            [86.37372248316065, 27.154969696031067],
            [86.3704605002681, 27.151065137022673],
            [86.37422239805494, 27.14843301107236],
            [86.37110275581135, 27.14734727706293],
            [86.3686290124905, 27.143710198735448],
            [86.3681839338186, 27.140892255858706],
            [86.36352648635018, 27.142130267322962],
            [86.36030721073148, 27.139378668878035],
            [86.35556535674415, 27.143292073653953],
            [86.35030820218931, 27.14275564800472],
            [86.34859718939943, 27.143853333810643],
            [86.34483858394316, 27.143996031718434],
            [86.34367215759286, 27.147341281679296],
            [86.34118952716423, 27.14494242843389],
            [86.34102261525989, 27.14114871197871],
            [86.33643458768162, 27.136664038850352],
            [86.33766777362175, 27.135020455194375],
            [86.3343372399769, 27.130595853149266],
            [86.33519317900802, 27.127759085177033],
            [86.33215334200412, 27.121517119949697],
            [86.33073386780731, 27.117191682475465],
            [86.32902861829292, 27.115931249144147],
            [86.32450308596991, 27.10995051464734],
            [86.31323747670308, 27.110884179550922],
            [86.31189618056612, 27.10953058925408],
            [86.3074416361708, 27.109617006534037],
            [86.30643057843523, 27.10889901221564],
            [86.31119438593558, 27.102976942627077],
            [86.3176679607955, 27.101936733647282],
            [86.3249353808632, 27.09919878596243],
            [86.32783200526646, 27.100550691835203],
            [86.33193351525209, 27.099830160610335],
            [86.33406581999692, 27.100891749501407],
            [86.34040452043533, 27.106354976157547],
            [86.34405524500026, 27.105677097771604],
            [86.34813279725421, 27.106228585783523],
            [86.34972861568843, 27.104949849756952],
            [86.35490608206044, 27.10429003444147],
            [86.35616335040329, 27.10256516373054],
            [86.35583092854638, 27.10057126359838],
            [86.35786882558017, 27.099220027375228],
            [86.36000564602574, 27.099861529379147],
            [86.36243979459644, 27.09903324612044],
            [86.35936681722674, 27.09554668723758],
            [86.35702772263281, 27.094195719252436],
            [86.35878283521004, 27.091845824734673],
            [86.3578712649035, 27.090226073093227],
            [86.35827934217679, 27.08402969060321],
            [86.35538243435585, 27.081696883168622],
            [86.3543900264061, 27.075676801265075],
            [86.35148612199447, 27.073840305552036],
            [86.35122041349551, 27.07035522866759],
            [86.34692955084002, 27.07035564603252],
            [86.34577106559829, 27.068292473134743],
            [86.34688961516001, 27.06457690642485],
            [86.35034311853539, 27.06376253667847],
            [86.35116161464187, 27.061631783554414],
            [86.35604658300292, 27.059266975580584],
            [86.36090052148617, 27.054837258484447],
            [86.36349104358236, 27.05370275663381],
            [86.36423257677848, 27.049849938597713],
            [86.36411902086944, 27.04227248153046],
            [86.3667927637868, 27.037760445493426],
            [86.37094890901554, 27.03712698315203],
            [86.37522685224849, 27.03479489232622],
            [86.37916446146869, 27.034640806061873],
            [86.38228247905867, 27.032364277063163],
            [86.37937037196293, 27.031446651706943],
            [86.37949247837301, 27.02905540902122],
            [86.37773235567805, 27.027989411030205],
            [86.37409255055583, 27.022941474862108],
            [86.37544104854113, 27.020842239264592],
            [86.3740451913403, 27.01966722450871],
            [86.37199148966671, 27.015659716221858],
            [86.3740071433914, 27.013405534661512],
            [86.37220899413764, 27.010804921661652],
            [86.37405986667626, 27.008658307176844],
            [86.37374656856029, 27.005818339289004],
            [86.3731816476021, 27.00371960368285],
            [86.37101395431856, 27.002593041680008],
            [86.36960340659813, 27.001731531522683],
            [86.36825058868781, 27.001195241615687],
            [86.36711247986923, 27.000549313809678],
            [86.36678462476937, 26.99994082973284],
            [86.36707715974629, 26.999432254587344],
            [86.36734363245705, 26.999074665664565],
            [86.36815549299743, 26.996914409359295],
            [86.36781092317435, 26.994227726691058],
            [86.36668589655014, 26.990583092177932],
            [86.36646770171095, 26.985755652607224],
            [86.3662361657308, 26.983529770081944],
            [86.36571932868162, 26.981605001027663],
            [86.36515105899062, 26.979982338186442],
            [86.3637485452128, 26.977803088606095],
            [86.36156267843013, 26.97481925273047],
            [86.35814238568243, 26.971879409802863],
            [86.35675401565838, 26.96924212233164],
            [86.35541840167721, 26.967697129298777],
            [86.35509602707155, 26.967095471514007],
            [86.35298045866082, 26.96461047038277],
            [86.34612026404479, 26.963703337632637],
            [86.34366288861821, 26.964136434693092],
            [86.34308202758062, 26.964370572722576],
            [86.34198976015878, 26.964647612012786],
            [86.34053311178647, 26.964302348427548],
            [86.33901481842213, 26.963900358423906],
            [86.33799748538591, 26.96379631034034],
            [86.33633103788345, 26.96407729774438],
            [86.33543159106378, 26.964264856545725],
            [86.33479365392333, 26.96424831013641],
            [86.33376374125915, 26.964509716423343],
            [86.33337448854957, 26.964708685051175],
            [86.33260674409718, 26.96476597650295],
            [86.33058897733751, 26.963930566409633],
            [86.32975489978767, 26.964025879345066],
            [86.32597899222505, 26.964127478267788],
            [86.32438872974046, 26.96381980747157],
            [86.32304071277802, 26.96395771095386],
            [86.32246347730481, 26.964002321542683],
            [86.3179379559726, 26.9633916120475],
            [86.31697050067359, 26.963635106350257],
            [86.31605400984847, 26.963612623422794],
            [86.3140187566854, 26.963198830397804],
            [86.31236830965206, 26.962755305957526],
            [86.3097167961, 26.961559996003174],
            [86.30735628496528, 26.959930552772256],
            [86.30489982730025, 26.95795316683595],
            [86.30221854453214, 26.9556812794585],
            [86.29879744486544, 26.953890766930176],
            [86.296961220196, 26.952579681883],
            [86.29275355406438, 26.950712910083716],
            [86.2834711278585, 26.94919266357597],
            [86.28121408953044, 26.94717974108921],
            [86.28042467980347, 26.941794203519613],
            [86.27956881679108, 26.940582671197273],
            [86.27971380894981, 26.938669953433674],
            [86.2789109848063, 26.935757349718646],
            [86.27814325554816, 26.934453751097756],
            [86.27332149608615, 26.932125188602974],
            [86.26776982753529, 26.9300070953521],
            [86.26450635334328, 26.92821882373821],
            [86.26166871270902, 26.925749056928797],
            [86.26111758199615, 26.925006071057457],
            [86.26012606479055, 26.92337175552136],
            [86.25947143517321, 26.9198280430665],
            [86.25968448408882, 26.91914316959652],
            [86.22452332682155, 26.903996795606172],
            [86.22099792044617, 26.888507541930764],
            [86.15748879425729, 26.92998750980133],
            [86.15600690656395, 26.918800598697732],
            [86.17913228174602, 26.8996471746791],
            [86.22744499070531, 26.880686152155388],
            [86.24697797627397, 26.86389552163618],
            [86.27103139097383, 26.88239791500375],
            [86.29883225672606, 26.885699837942177],
            [86.36473854790714, 26.87298506278638],
            [86.38267953415553, 26.90822675581978],
            [86.41870444091145, 26.906141295726886],
            [86.45687266742344, 26.887463023110858],
            [86.48012608905813, 26.886708856907187],
            [86.4817202341781, 26.869894990398983],
            [86.50457160461376, 26.856259764773387],
            [86.52080913246662, 26.834533827095946],
            [86.55463362002303, 26.83067061392509],
            [86.5534715407219, 26.81487817154581],
            [86.53127597750769, 26.788831775773648],
            [86.55756265005434, 26.790247657580547],
            [86.5772257067397, 26.77575995153209],
            [86.55326780028827, 26.74712562686552],
            [86.57392433242585, 26.739582388470396],
            [86.57357815142022, 26.720524932226624],
            [86.58833415228803, 26.69743658425805],
            [86.6111181081203, 26.68935467850921],
            [86.63423053456323, 26.69856419808169],
            [86.64954135340865, 26.689757105254248],
            [86.66490998339319, 26.704488884867743],
            [86.69049253637202, 26.689434764841696],
            [86.70595121970047, 26.707003422947476],
            [86.7383885878364, 26.711884671064457],
            [86.75398424105659, 26.688538984623143],
            [86.79995575761602, 26.712673026385197],
            [86.83755724468992, 26.709855228661446],
            [86.84045962554103, 26.691799111417435],
            [86.88415145634119, 26.712155200135015],
            [86.91139367704639, 26.74108381910019],
            [86.92075098235723, 26.766457692294246],
            [86.9325097546286, 26.740729900892656],
            [86.96708537238044, 26.735700937365284],
            [86.99089201406238, 26.698543103422985],
            [87.00999783304415, 26.710886539441386],
            [87.04331228463025, 26.756314135755517],
            [87.06461820863144, 26.744605718301116],
            [87.09916451800659, 26.751351039456612],
            [87.11299192684994, 26.765116949007503],
            [87.1441851299434, 26.822824748324443],
            [87.1523150200778, 26.868497510739264],
            [87.16480987963924, 26.87794058862888],
            [87.16827352169611, 26.899542691729533],
            [87.1560986267474, 26.91565462701478],
            [87.14907401002068, 26.92559538137107],
            [87.11564753258199, 26.923092640941167],
            [87.05026822667766, 26.90246812334313],
            [87.0421423658023, 26.907290745815434],
            [87.00331173222398, 26.88675362424058],
            [86.97628519531247, 26.906455611957224],
            [86.95155813794786, 26.904610220358595],
            [86.93180645557551, 26.907560369007996],
            [86.91389853906507, 26.895739973088453],
            [86.8627580168653, 26.895130893749755],
            [86.83379646834037, 26.874881362663192],
            [86.80527928164749, 26.872981039581965],
            [86.7730504709191, 26.884915061997553],
            [86.7608418874053, 26.925214244317033],
            [86.7644931580865, 26.95754471083866],
            [86.7546897975666, 26.975643010954037],
            [86.74696100013863, 27.02483635573422],
            [86.72673057280261, 27.03948584107968],
            [86.69926912938323, 27.093036331674735],
            [86.66881358520786, 27.120054109852788],
            [86.64033477294312, 27.13306228388419],
            [86.57632861142305, 27.151435937266356],
            [86.53318990681161, 27.13512295443769],
            [86.52148627986011, 27.118966058178135],
            [86.49235594876727, 27.104783878136914],
            [86.45944266744176, 27.105633618325925],
            [86.43347713780902, 27.149099832178045]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BARA",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.17637099903689, 26.86926624480249],
            [85.1791119845599, 26.925245375486643],
            [85.163980966295, 26.93832403759942],
            [85.17229830703278, 26.976879363664608],
            [85.16362939332463, 26.979572925252704],
            [85.18277217405672, 27.01864662375966],
            [85.200183805479, 27.0386154329231],
            [85.1832249921206, 27.050313061229723],
            [85.20239450568928, 27.08348765869279],
            [85.19719527804446, 27.107496999469944],
            [85.23124208416569, 27.134204220531156],
            [85.24639009869095, 27.159052620561255],
            [85.25122529408627, 27.189539290897144],
            [85.26934130750803, 27.228077467631152],
            [85.26737643650321, 27.226285894384862],
            [85.26384919582443, 27.227159260211597],
            [85.26082322251848, 27.2269879772691],
            [85.25698751743107, 27.23067509459245],
            [85.2580942850589, 27.234216245809233],
            [85.25427274894312, 27.237228567373386],
            [85.25155540408905, 27.243014941396442],
            [85.25152062699857, 27.244982564232128],
            [85.24903887345945, 27.246408016194817],
            [85.24556646462621, 27.24660367064281],
            [85.24296645266155, 27.248697879737666],
            [85.2389543467913, 27.248602561629802],
            [85.23321392675784, 27.245433765858458],
            [85.23179752877317, 27.243399987399158],
            [85.22471378091446, 27.24549938190788],
            [85.22035752314704, 27.243985219392385],
            [85.21722009300618, 27.244691619277877],
            [85.21519606178937, 27.246816407343392],
            [85.2091762034129, 27.245223823975167],
            [85.20766215664598, 27.24591144170071],
            [85.20673935464265, 27.248349271823198],
            [85.20245406849453, 27.25025647087593],
            [85.19974550634178, 27.2493185740926],
            [85.19707566478435, 27.245625615418856],
            [85.19290186657412, 27.245094967575557],
            [85.18977029363468, 27.246193258197998],
            [85.1821478857007, 27.247343022684323],
            [85.17735434101135, 27.249684665787345],
            [85.17947691372235, 27.251622858872107],
            [85.18095975053559, 27.254590679798525],
            [85.17543499614727, 27.253687360291558],
            [85.17010994548946, 27.255453410147872],
            [85.16830025122198, 27.25983638524439],
            [85.16491047901204, 27.260507642310525],
            [85.16144185963387, 27.262102198998857],
            [85.15599476392471, 27.26214054668281],
            [85.1548554390961, 27.26402479775461],
            [85.15179449542431, 27.265757906856887],
            [85.15131579386195, 27.268365443786855],
            [85.14556283905986, 27.272523337329062],
            [85.14422769211994, 27.280090053301628],
            [85.14478324759833, 27.282989149238187],
            [85.14253802308538, 27.284862052706558],
            [85.13875761237017, 27.286566902871677],
            [85.13745903814991, 27.28947887197285],
            [85.13218758871398, 27.29203047521381],
            [85.12663733918554, 27.292630293708623],
            [85.12712026691194, 27.295627077768017],
            [85.12507517896111, 27.30162458975665],
            [85.12127977840775, 27.30185810329831],
            [85.12060386811214, 27.30603962409307],
            [85.11905782011733, 27.309276060162446],
            [85.11902020194756, 27.314397755396058],
            [85.11778846639857, 27.31662763254861],
            [85.11679544668343, 27.317917063979426],
            [85.1120867382185, 27.31875778915664],
            [85.10991446958823, 27.318380226009115],
            [85.1057671616886, 27.31910349826519],
            [85.1039187818622, 27.320248435974694],
            [85.10323112768027, 27.32331542866499],
            [85.09934135554403, 27.32699653214396],
            [85.09887259439849, 27.328644943891668],
            [85.09490059617299, 27.33247546354663],
            [85.09275632362106, 27.33806491299157],
            [85.09190768804986, 27.341954394128166],
            [85.09302663787943, 27.343344412462393],
            [85.09309746106187, 27.3486277532668],
            [85.09217262879201, 27.352614756782774],
            [85.0906308721828, 27.35340068583241],
            [85.09133997142206, 27.356594341689156],
            [85.08806655902306, 27.358215002967615],
            [85.08706150770921, 27.360930163505795],
            [85.0807931776541, 27.36277922917238],
            [85.07834474767995, 27.362926739686674],
            [85.07653266177793, 27.363552154999677],
            [85.07570209002928, 27.36324041975449],
            [85.07310829001355, 27.364104176355454],
            [85.0729166893168, 27.36415304394684],
            [85.07081677361238, 27.364415112238028],
            [85.06937477536654, 27.364243402810274],
            [85.06988359455693, 27.363219729297676],
            [85.06971807931514, 27.362496776877244],
            [85.06941612792153, 27.362171978385856],
            [85.06823340915787, 27.36216071067411],
            [85.06744907785827, 27.360540021110523],
            [85.06678254704595, 27.360599263047142],
            [85.06623883705547, 27.36220302075891],
            [85.06361933232324, 27.361241580413758],
            [85.06333895171585, 27.36105648207649],
            [85.0624431298337, 27.361185081389426],
            [85.06180714965606, 27.3608695741479],
            [85.0614771798481, 27.3604501844367],
            [85.06011321176129, 27.363095748465465],
            [85.056762427342, 27.36568410882926],
            [85.05434193413893, 27.3650594188567],
            [85.05326745990396, 27.36460500505025],
            [85.05294432756246, 27.36397349398364],
            [85.05237610524728, 27.36320617870048],
            [85.0494680596346, 27.362790357449988],
            [85.04938926239645, 27.362545003172432],
            [85.04679535506506, 27.362336627466814],
            [85.04646365257366, 27.36233235096252],
            [85.0445449063994, 27.361372058010357],
            [85.04245548334143, 27.358912676461184],
            [85.0399844415515, 27.359346265936086],
            [85.03561799282014, 27.357442929879156],
            [85.03116826766983, 27.36058257847752],
            [85.02930022602463, 27.35935097176225],
            [85.02456271201265, 27.358704444335043],
            [85.02150460968691, 27.357197166566685],
            [85.02210266040592, 27.353806292130827],
            [85.02149577270978, 27.352100568608364],
            [85.01868248985019, 27.35364400269852],
            [85.01600433310638, 27.353873365319064],
            [85.01320411520824, 27.35540759875684],
            [85.00867339509877, 27.354761294786403],
            [85.00445599286952, 27.352919075536455],
            [85.00091833484605, 27.355775971665405],
            [85.00128906776231, 27.35829568685911],
            [85.00064101257425, 27.362099745762237],
            [84.99922413681476, 27.365421891072412],
            [84.99747450592422, 27.364753110893158],
            [84.99446167398723, 27.365318330434732],
            [84.99312252597191, 27.363710189401456],
            [84.9909452442399, 27.36360834029429],
            [84.98772776736575, 27.361688576331964],
            [84.9834122194906, 27.362459094814767],
            [84.9801918236822, 27.361552169565783],
            [84.97772796151182, 27.358162617204798],
            [84.9755996834112, 27.356361268006758],
            [84.97233994794689, 27.355188241312696],
            [84.96928646529533, 27.35579159469949],
            [84.96888184183085, 27.320379009456353],
            [84.95760647397545, 27.300322377791964],
            [84.97289173570249, 27.26393481096673],
            [84.96014184470566, 27.19744843087904],
            [84.94505452596343, 27.165167017164322],
            [84.95517146982078, 27.13495499843755],
            [84.93128871630532, 27.068665855818853],
            [84.9177514836658, 27.072662726347176],
            [84.90006126768112, 27.045938336207666],
            [84.91695707774059, 27.034475558148653],
            [84.91129307449727, 27.018246605053914],
            [84.8662164831977, 26.983812852801925],
            [84.90994603324309, 26.96802968526236],
            [84.96265056150757, 26.960355685430713],
            [84.9782758935864, 26.940343022478686],
            [84.97168679906356, 26.91564805604872],
            [84.99951983162013, 26.912231729325885],
            [85.00532284448363, 26.89473125142888],
            [85.05319722568714, 26.889107183156458],
            [85.02893768711596, 26.87490875605873],
            [85.02202523072322, 26.854929612575273],
            [85.05741906751437, 26.84922266948196],
            [85.08456014076818, 26.855054948454924],
            [85.09950255469778, 26.871130627658225],
            [85.17637099903689, 26.86926624480249]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DHANUSA",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.15748879425729, 26.92998750980133],
            [86.22099792044617, 26.888507541930764],
            [86.22452332682155, 26.903996795606172],
            [86.25968448408882, 26.91914316959652],
            [86.25855799283458, 26.91978484412907],
            [86.25745571568126, 26.92100205080622],
            [86.25724618947969, 26.921571789439156],
            [86.25682151583553, 26.92357329310974],
            [86.25687986536077, 26.923747316908926],
            [86.25727621877522, 26.924676783565268],
            [86.25715466119384, 26.926515101392784],
            [86.25720767770764, 26.927551076286527],
            [86.25792664660463, 26.92837393121306],
            [86.25524284722746, 26.932581533723802],
            [86.25429990317912, 26.932806685591313],
            [86.25087278576375, 26.935615801137704],
            [86.24917756634373, 26.93608968509584],
            [86.2476681244568, 26.936740487343716],
            [86.24452550206658, 26.938321222989476],
            [86.24364315884301, 26.93934992972232],
            [86.24085081438915, 26.9403909161553],
            [86.2397816977581, 26.942282785758138],
            [86.23717040186, 26.94427463664059],
            [86.23528176087488, 26.947039751868807],
            [86.23515890584339, 26.949248087858166],
            [86.23258530456033, 26.95052024972528],
            [86.22871506087068, 26.949235566211648],
            [86.22558134860138, 26.95020669295191],
            [86.22482547723705, 26.95207536643443],
            [86.2190158305261, 26.95655831088401],
            [86.21628154641981, 26.960351930024594],
            [86.21369768341128, 26.962900761259856],
            [86.20840973843737, 26.966501679496346],
            [86.2073433889799, 26.96698082007608],
            [86.20631789429211, 26.967157787333196],
            [86.20343079097849, 26.968908262531453],
            [86.19984573860265, 26.97007706839003],
            [86.19808132598067, 26.97110723839825],
            [86.19581883449798, 26.972894917650923],
            [86.1923923251513, 26.975472409683576],
            [86.1919402300494, 26.978254191699804],
            [86.19152109048505, 26.978583468101377],
            [86.18851781713353, 26.97753957284138],
            [86.18751130787994, 26.97733519320861],
            [86.18795402332576, 26.97960087477833],
            [86.18813312528042, 26.980170521315205],
            [86.18768426532768, 26.982270828128957],
            [86.18383187556529, 26.983148873790384],
            [86.18178073683585, 26.984168145772813],
            [86.18101559166392, 26.984448024486614],
            [86.17841124050223, 26.984642713880827],
            [86.17638858039582, 26.98667288166168],
            [86.17425267189535, 26.986908581804084],
            [86.17216448513318, 26.98810349128175],
            [86.17011260741312, 26.989111305479483],
            [86.16853212640908, 26.989115528785398],
            [86.16719134175425, 26.988809748575036],
            [86.16308675632835, 26.991894681028523],
            [86.16098242191926, 26.992313120274552],
            [86.15236293694564, 26.994687141822773],
            [86.14870521615643, 26.996758946773397],
            [86.14862181064778, 26.99679680061707],
            [86.1461716737746, 26.997585231192883],
            [86.14476360309031, 26.997800068560142],
            [86.14371367977944, 26.99832392620424],
            [86.14222513511473, 26.9991858232487],
            [86.13884650049691, 27.000496451128132],
            [86.13510220286058, 27.00255159901237],
            [86.1337615038683, 27.003249574902433],
            [86.13101339876285, 27.004225455442008],
            [86.12845918811456, 27.005448402199043],
            [86.12718192998287, 27.006106087760717],
            [86.12609867937472, 27.006938746074578],
            [86.12577931780615, 27.007074404868774],
            [86.1239255103336, 27.007696892863876],
            [86.12309397162588, 27.007894810976513],
            [86.12210517320074, 27.00830830850356],
            [86.12125265427443, 27.00856927124157],
            [86.11619544892821, 27.01093157988203],
            [86.1155971815186, 27.01129109287501],
            [86.11184718138618, 27.012327969822202],
            [86.10988323009764, 27.013324161529816],
            [86.10981872449025, 27.013400452280244],
            [86.10819449045147, 27.014434847675506],
            [86.10589058190567, 27.015408513959986],
            [86.10322814207757, 27.01599853482864],
            [86.09870953995878, 27.017429525318406],
            [86.09317867691063, 27.018079936812985],
            [86.09104902130478, 27.018619005129167],
            [86.08849440647927, 27.019173351159147],
            [86.0857746968548, 27.019117348351227],
            [86.08365183256068, 27.018853098835695],
            [86.08310212602159, 27.018486260010523],
            [86.08080705195998, 27.018450975061057],
            [86.0794449798459, 27.01846463875827],
            [86.07652626286323, 27.01879301018715],
            [86.07488692322097, 27.018861220299062],
            [86.07412167042126, 27.01874340079602],
            [86.07352531021922, 27.018777887854178],
            [86.07071455002351, 27.018917203917376],
            [86.06990543852864, 27.01897055177233],
            [86.06896720463556, 27.019176444160912],
            [86.06564852097027, 27.01896944495884],
            [86.06318245545668, 27.018630408218748],
            [86.06164905258012, 27.01871719636815],
            [86.05866957089302, 27.018546162144617],
            [86.05654239521441, 27.018283731035947],
            [86.05142851324317, 27.01878639593167],
            [86.05057562219585, 27.018913745531247],
            [86.04861311971912, 27.019360798448815],
            [86.0486251940038, 27.020509324180036],
            [86.04862325547437, 27.020739453501722],
            [86.0483432424907, 27.02115723267827],
            [86.04821457028832, 27.021253374265754],
            [86.04753215649748, 27.021402224785014],
            [86.04678825751738, 27.021223353306336],
            [86.04568917200733, 27.020220986367804],
            [86.04545619172401, 27.02006820527015],
            [86.04111546800164, 27.019731891463746],
            [86.03890081894235, 27.01979572415698],
            [86.03685713101113, 27.019973547830467],
            [86.03566504851388, 27.020100768597388],
            [86.03515298185756, 27.020289043308374],
            [86.03034088834266, 27.02079749677015],
            [86.02753742188996, 27.020225373105525],
            [86.0268544428973, 27.02050946165071],
            [86.02651323563751, 27.020601885322442],
            [86.02530224521738, 27.02040395739131],
            [86.02160963668955, 27.01934722388466],
            [86.01530433629175, 27.02016742597819],
            [86.01246448829589, 27.018692259695698],
            [86.0052440874098, 27.022050653567177],
            [86.00289056488492, 27.02100062979571],
            [85.99981901496442, 27.021879081034974],
            [85.99857525300845, 27.023000654255004],
            [85.9983186666538, 27.023170306096745],
            [85.99635987203499, 27.02348800639492],
            [85.99580262478723, 27.023860839167405],
            [85.99413649062339, 27.024559687112262],
            [85.98982273401332, 27.026211969664246],
            [85.98898595546461, 27.02684677461423],
            [85.9881443576204, 27.027842422516628],
            [85.98717389929075, 27.028952370664115],
            [85.98702227322957, 27.029179162371555],
            [85.98604746199007, 27.03231061608263],
            [85.98597815930117, 27.032768139562602],
            [85.9857600593176, 27.03320428311996],
            [85.98364337418953, 27.034285562958353],
            [85.9825720778437, 27.036714591815777],
            [85.98194921502098, 27.03726061686694],
            [85.97758030509281, 27.04173019348305],
            [85.97486809929792, 27.04523474296471],
            [85.97392954215613, 27.047754913683733],
            [85.97165649984076, 27.04987501055622],
            [85.96981040808534, 27.053904703645806],
            [85.97062013817039, 27.055817154278138],
            [85.96943879344258, 27.05882286835186],
            [85.9696907147842, 27.061040363444103],
            [85.96781143156474, 27.065496249912503],
            [85.96613480641155, 27.067047486525045],
            [85.96678396965568, 27.06987933891317],
            [85.96533539206031, 27.07385999243079],
            [85.96321487245876, 27.07323067391443],
            [85.96179273856647, 27.076715093376176],
            [85.96218763006593, 27.079470668056196],
            [85.95560345957448, 27.08424572929179],
            [85.9533757628013, 27.083538724333636],
            [85.9502228551804, 27.08393038756138],
            [85.951072503136, 27.086515609809386],
            [85.95048345468803, 27.088189857554372],
            [85.94807864960227, 27.088223774796244],
            [85.94637647050014, 27.09065229041204],
            [85.9441296103625, 27.092081626553398],
            [85.94321183477298, 27.094822850768473],
            [85.94079501075949, 27.09859738013829],
            [85.9370199281453, 27.10669352548985],
            [85.93589823940222, 27.11020251214703],
            [85.9361700316949, 27.112821790409583],
            [85.93821789305358, 27.118971955803616],
            [85.93812904134656, 27.121437360558986],
            [85.92997002963823, 27.125355378144157],
            [85.9213553013763, 27.12733360104653],
            [85.9197628577891, 27.128844275027472],
            [85.91391964736185, 27.129848366457434],
            [85.912071296199, 27.132221098392915],
            [85.90559508078336, 27.133851667088912],
            [85.92165691909285, 27.10471963469361],
            [85.93687415097017, 27.096805650295234],
            [85.94516962292987, 27.060565313530756],
            [85.91719033520447, 27.042999972524555],
            [85.92688529964475, 27.028553100063174],
            [85.91148354773014, 27.008099409458275],
            [85.90747170456558, 26.958859392728925],
            [85.91776404048882, 26.95909222838975],
            [85.89744157235255, 26.873264303345934],
            [85.91658787492098, 26.870501860577612],
            [85.91557429384984, 26.819461043312508],
            [85.89565398288516, 26.765798771347598],
            [85.87936888093871, 26.746196737329985],
            [85.89356709448984, 26.721111869728457],
            [85.88687061328858, 26.698421763070282],
            [85.90614699354306, 26.681797979808213],
            [85.90544933097584, 26.65400341921474],
            [85.85490288051949, 26.61894569121837],
            [85.85262590783718, 26.604303944817],
            [85.86011248544379, 26.570277438258277],
            [85.91391500548109, 26.60896152572015],
            [85.94558548389541, 26.61283694800188],
            [85.95662618391184, 26.649352097925508],
            [85.9760424082971, 26.658275127647492],
            [86.00981921385835, 26.653974619692058],
            [86.02700227687852, 26.666735900989156],
            [86.06991943883841, 26.657027395626624],
            [86.11938728657628, 26.630091515116632],
            [86.13869404619702, 26.628822006188553],
            [86.14663547810206, 26.60893407372715],
            [86.15239361591162, 26.639020021406704],
            [86.16869484641816, 26.643078682379393],
            [86.19668714895687, 26.666945918262655],
            [86.19961873363799, 26.686909584458043],
            [86.17368902644147, 26.74919223294851],
            [86.1593135880735, 26.752289222589024],
            [86.14459098611432, 26.78694542401131],
            [86.15492547247841, 26.819332737961187],
            [86.15234969134264, 26.85238702829261],
            [86.13117467604101, 26.893326812431418],
            [86.13251024239746, 26.914991607967472],
            [86.15748879425729, 26.92998750980133]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MAHOTTARI",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.90559508078336, 27.133851667088912],
            [85.9043378210212, 27.134708250061266],
            [85.90143574302526, 27.135721174786365],
            [85.89806977475885, 27.13739372080444],
            [85.89409392038402, 27.1404535085992],
            [85.89305433812993, 27.141119836414852],
            [85.89087525783297, 27.14132599926601],
            [85.88886897041047, 27.14321661639122],
            [85.88827509426616, 27.144008350178044],
            [85.88696117640998, 27.145532147969945],
            [85.88495813722163, 27.145588464580992],
            [85.88276778374302, 27.146347173616913],
            [85.88089677404957, 27.143552640955775],
            [85.87587736119552, 27.14063813651706],
            [85.87419661110204, 27.13880392348077],
            [85.8738118175999, 27.137975082071012],
            [85.87168454892908, 27.13660427837894],
            [85.86973651354155, 27.13496866615471],
            [85.8683011924384, 27.13360112976633],
            [85.86759087154788, 27.132458282057573],
            [85.86739738418412, 27.132172449570543],
            [85.86595999608397, 27.13051834336895],
            [85.8651241898954, 27.129660989857296],
            [85.86379095333047, 27.127721186562987],
            [85.86246794914872, 27.127171246747917],
            [85.85923482275821, 27.1303510817185],
            [85.85801867913402, 27.132933519903517],
            [85.85481413683627, 27.134146047034488],
            [85.85367008410057, 27.13792937973884],
            [85.848940345462, 27.140180664531925],
            [85.84554002573587, 27.140937916378],
            [85.84121677335652, 27.144309108386384],
            [85.83834493572493, 27.144763678464816],
            [85.83557057842653, 27.147245067304265],
            [85.83458253928399, 27.149373424500755],
            [85.83764320871572, 27.15186028668656],
            [85.83975609712292, 27.152595232737596],
            [85.84060711558331, 27.155880507954706],
            [85.8434866526206, 27.160032998891914],
            [85.83529075330094, 27.164071448148388],
            [85.8306884342648, 27.167175966295776],
            [85.82533710968956, 27.16843308854857],
            [85.8225853064209, 27.167087911236255],
            [85.82002845816397, 27.163790491074035],
            [85.8158621243843, 27.161307431681035],
            [85.79215123443811, 27.130796253618],
            [85.80095417278918, 27.104883011785088],
            [85.74772277107071, 27.03456600913543],
            [85.72658766290755, 26.954463144801228],
            [85.70702971113083, 26.924145077404184],
            [85.70355556470695, 26.898135638099234],
            [85.67619757272296, 26.864465678416373],
            [85.68025452813971, 26.843311366825297],
            [85.7212386300751, 26.82011051869103],
            [85.73443326390067, 26.795929620569808],
            [85.72591768976586, 26.720939611530422],
            [85.72537807596892, 26.670606797725114],
            [85.73222382302978, 26.654077741620487],
            [85.7692935232654, 26.62999733692249],
            [85.8013963819767, 26.62433966506455],
            [85.81751289497075, 26.602813884843414],
            [85.83120431241227, 26.61136566429188],
            [85.85262590783718, 26.604303944817],
            [85.85490288051949, 26.61894569121837],
            [85.90544933097584, 26.65400341921474],
            [85.90614699354306, 26.681797979808213],
            [85.88687061328858, 26.698421763070282],
            [85.89356709448984, 26.721111869728457],
            [85.87936888093871, 26.746196737329985],
            [85.89565398288516, 26.765798771347598],
            [85.91557429384984, 26.819461043312508],
            [85.91658787492098, 26.870501860577612],
            [85.89744157235255, 26.873264303345934],
            [85.91776404048882, 26.95909222838975],
            [85.90747170456558, 26.958859392728925],
            [85.91148354773014, 27.008099409458275],
            [85.92688529964475, 27.028553100063174],
            [85.91719033520447, 27.042999972524555],
            [85.94516962292987, 27.060565313530756],
            [85.93687415097017, 27.096805650295234],
            [85.92165691909285, 27.10471963469361],
            [85.90559508078336, 27.133851667088912]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "PARSA",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.96928646529533, 27.35579159469949],
            [84.96558227428045, 27.358572099874113],
            [84.96490071241816, 27.363098121216368],
            [84.96189416629394, 27.36265184994758],
            [84.95772616449655, 27.359451903494772],
            [84.95530445277281, 27.36125763518667],
            [84.9564060128062, 27.363517637540376],
            [84.95438240431363, 27.366374217267143],
            [84.95179066368677, 27.367012363350845],
            [84.95030015578755, 27.365440931745578],
            [84.94916030973832, 27.36209371371188],
            [84.94723775076312, 27.36155176938064],
            [84.9471097586812, 27.36135860542642],
            [84.94676933296346, 27.361072142662234],
            [84.94591495220249, 27.360743971138884],
            [84.9457228916632, 27.360666331630267],
            [84.94463184940868, 27.360662436835053],
            [84.9446521026914, 27.360890175044567],
            [84.94533269704264, 27.36173393579992],
            [84.94477083193647, 27.36282067042002],
            [84.944190942355, 27.36327584221893],
            [84.94361307141763, 27.363369986134398],
            [84.94322724449833, 27.363539551504918],
            [84.94085086988044, 27.36379699927937],
            [84.94099909656765, 27.36414346434108],
            [84.94004981384226, 27.365819424267393],
            [84.9397930222478, 27.365857258349415],
            [84.93716188430506, 27.365730574367095],
            [84.93660331548271, 27.36616748791048],
            [84.93504245360762, 27.365970381771977],
            [84.93433744560245, 27.365681784382495],
            [84.93384933838276, 27.364877348796377],
            [84.9328239158634, 27.36464279121216],
            [84.93269536955901, 27.364682035677475],
            [84.93082884579579, 27.36570522719192],
            [84.93044408563549, 27.36562660319633],
            [84.93012356736037, 27.365529419372493],
            [84.92954770223405, 27.365106856276203],
            [84.9289079923547, 27.36468469349015],
            [84.92852339460646, 27.36462633477081],
            [84.92683508638159, 27.36421790130681],
            [84.9262356686207, 27.364233161994417],
            [84.92617136424519, 27.36425165932776],
            [84.9260189976994, 27.364843791378696],
            [84.92580295471751, 27.365300934525493],
            [84.92522063967488, 27.366159910916423],
            [84.92405788895024, 27.3676431683412],
            [84.92306692952354, 27.369053055361825],
            [84.92248806432455, 27.369262215033885],
            [84.92223027986974, 27.369566246243203],
            [84.9219475499199, 27.370443538188734],
            [84.91697290361253, 27.37259937529203],
            [84.91665302087657, 27.372502208115513],
            [84.9161385726321, 27.372710895376024],
            [84.91523976218454, 27.37289727553624],
            [84.91485547470992, 27.37287724523969],
            [84.91370062144091, 27.373119454634942],
            [84.91204905125059, 27.374966802118774],
            [84.91011150466865, 27.37592479874854],
            [84.90974658020825, 27.37618889565181],
            [84.90762696744716, 27.377371407404656],
            [84.90669111398827, 27.37743842193666],
            [84.90544414247628, 27.376848610739454],
            [84.9039278864718, 27.375779964754628],
            [84.9031315506095, 27.375281998195437],
            [84.90246091383051, 27.374704225673348],
            [84.90060333739859, 27.375171967128175],
            [84.8982813918846, 27.378456198849435],
            [84.89753607373106, 27.380209541065938],
            [84.89748492167188, 27.380839350310755],
            [84.89733039604869, 27.384554050931435],
            [84.89582594628125, 27.385818250404082],
            [84.8946215852397, 27.386838952518595],
            [84.89368019993815, 27.387327768938523],
            [84.89297827617371, 27.387339049108714],
            [84.89234381836525, 27.387101705757125],
            [84.89164203542079, 27.387133254428782],
            [84.89114826342264, 27.387490633831973],
            [84.8904950177483, 27.388690591506283],
            [84.88963538140347, 27.389370709880982],
            [84.88798110592319, 27.389063086534765],
            [84.88425186654814, 27.38814142323249],
            [84.88150690851332, 27.3883799070762],
            [84.88072248777938, 27.389788220290146],
            [84.88032977469028, 27.390548770946147],
            [84.87989425434951, 27.391329944081566],
            [84.87958959626884, 27.39188007748205],
            [84.87852945900197, 27.393267561465763],
            [84.87847842132439, 27.395526358124407],
            [84.87672779789821, 27.397669513520114],
            [84.87645080991159, 27.397723118572067],
            [84.8733388573229, 27.398340525263176],
            [84.8730201498677, 27.39831770981746],
            [84.87245297219437, 27.398355099639254],
            [84.87181361561422, 27.39910364475778],
            [84.87061046035915, 27.398714074034135],
            [84.8701276538185, 27.398475650354715],
            [84.86995955259864, 27.398395467412943],
            [84.86810940813508, 27.397531609560783],
            [84.86519401358325, 27.397427917067784],
            [84.86435194443521, 27.39706537389862],
            [84.86351949077208, 27.397122418252547],
            [84.86129152632687, 27.39756709350256],
            [84.86049898379484, 27.39772090557634],
            [84.85997168698694, 27.398263385401886],
            [84.85806636955397, 27.398600012895564],
            [84.85543677311145, 27.398920755484287],
            [84.85471399465261, 27.39886656153647],
            [84.854374915334, 27.39882124779558],
            [84.85189386172877, 27.398574678916223],
            [84.8517422578605, 27.398665853194064],
            [84.85044298323436, 27.40271689938369],
            [84.850408710237, 27.403193181611304],
            [84.85055093949215, 27.403445009937826],
            [84.85090556020496, 27.403702311106407],
            [84.84920364198949, 27.40610426745109],
            [84.8489424230643, 27.406326989598504],
            [84.84689289156067, 27.40898821882161],
            [84.84462915265448, 27.41092200993984],
            [84.84460277741377, 27.411111680012496],
            [84.84428347698237, 27.41192357778517],
            [84.841919284925, 27.412041657852736],
            [84.8411149576463, 27.411908882956993],
            [84.84075836685004, 27.413312140586456],
            [84.84075334480556, 27.413483642899052],
            [84.8410411873649, 27.413851925596894],
            [84.84014147995025, 27.414899707906148],
            [84.83937739486524, 27.414825390123713],
            [84.83770924889215, 27.414386403420732],
            [84.83753676748881, 27.4144776839249],
            [84.83657156362821, 27.415561967663567],
            [84.83595050187543, 27.41652005949407],
            [84.83552765696356, 27.416434592262256],
            [84.83403711273334, 27.41725800899525],
            [84.8337045245419, 27.417747351763204],
            [84.83281739591149, 27.41753598185767],
            [84.83198157933813, 27.41700182921583],
            [84.83019168172952, 27.4162543820892],
            [84.82838740866625, 27.4174767003685],
            [84.82723005461442, 27.41840637892208],
            [84.82381228065312, 27.41932457375606],
            [84.82288111655843, 27.419054815276514],
            [84.82245648644924, 27.41898962699077],
            [84.8214240273709, 27.4192912847599],
            [84.81939149010239, 27.419495023570335],
            [84.81873934046837, 27.419173959722098],
            [84.81677373160441, 27.41928469923457],
            [84.81548034544605, 27.419008141349078],
            [84.81471046063932, 27.418322128382663],
            [84.8135705092472, 27.41715102816543],
            [84.81242585301092, 27.416895970678095],
            [84.81012608691739, 27.415986599742304],
            [84.81001678714678, 27.41607973978878],
            [84.80986318385209, 27.416944764368083],
            [84.80919732624848, 27.420894748937],
            [84.8089560887664, 27.421882156561246],
            [84.80862583812859, 27.42374767855818],
            [84.80935677920303, 27.4242984663484],
            [84.80924337397524, 27.42452473639805],
            [84.80742659727639, 27.425381443644774],
            [84.80682173433875, 27.425655597096373],
            [84.80671393536493, 27.425692342115514],
            [84.80352524362529, 27.42827606044602],
            [84.8018097958932, 27.429346373957507],
            [84.80055341499, 27.43000567131495],
            [84.80010416128724, 27.430035286643985],
            [84.79970576653274, 27.430540735058425],
            [84.79936367153633, 27.43131653508539],
            [84.79866835984762, 27.4317605144763],
            [84.7978002968727, 27.43227536211985],
            [84.79745507543214, 27.433145966086176],
            [84.79700721560229, 27.435391257553675],
            [84.79361175829021, 27.436978687059664],
            [84.79124568322123, 27.437632903906056],
            [84.7862156130491, 27.436871706256028],
            [84.78463976970825, 27.435366672394892],
            [84.78004571345872, 27.435094644555058],
            [84.77956466364238, 27.43626831195899],
            [84.77513418672221, 27.437698646701726],
            [84.77465920508277, 27.438662445460754],
            [84.77028422493733, 27.43891006337279],
            [84.76862873792498, 27.437211241105675],
            [84.75790601790351, 27.4374662613739],
            [84.75548555045152, 27.43573082539215],
            [84.7491250960976, 27.437592708328108],
            [84.74332186945524, 27.442075320711727],
            [84.73962420189072, 27.440488530669768],
            [84.73812823793475, 27.4423691510134],
            [84.73450584687424, 27.442115304961288],
            [84.7340110616966, 27.444065059665366],
            [84.72787865842577, 27.44419423890265],
            [84.72745417316956, 27.444349869618353],
            [84.7257520199967, 27.44451670317044],
            [84.7251454360488, 27.44332180105668],
            [84.72503452364782, 27.442787659804207],
            [84.72358656592503, 27.440435138978742],
            [84.72242635393444, 27.439545431679097],
            [84.72132777942183, 27.43840946513447],
            [84.72080947904738, 27.43774429981183],
            [84.72061153101338, 27.4371540970032],
            [84.72094580192318, 27.43644399999115],
            [84.72074715355276, 27.43574108027615],
            [84.71888883494202, 27.433253045567277],
            [84.7189071506141, 27.43296638409437],
            [84.7194270200751, 27.431625746762485],
            [84.71492849770267, 27.431714325110097],
            [84.71331614806257, 27.432548397293857],
            [84.70989636136038, 27.431694921182153],
            [84.704918936633, 27.43302653648835],
            [84.70262869418082, 27.432144642645994],
            [84.6906060265746, 27.432529609066318],
            [84.68590728837707, 27.431738419774984],
            [84.68401878996521, 27.43047056901689],
            [84.67786781295037, 27.429422129073686],
            [84.67070472558817, 27.42908448509372],
            [84.66478537492496, 27.42952347900784],
            [84.66450889314218, 27.429481943471],
            [84.6626990725033, 27.430338935962045],
            [84.66234844877758, 27.432172661846963],
            [84.66147886453355, 27.43296196951062],
            [84.65991762370052, 27.432362436803405],
            [84.65863513858181, 27.432084216283137],
            [84.65678248552825, 27.43264799680629],
            [84.65644129197572, 27.43269019039575],
            [84.65562691075583, 27.43212104142213],
            [84.64952520571474, 27.42952774249077],
            [84.64766377192775, 27.429141560894305],
            [84.64666152414596, 27.429416958155333],
            [84.64617719462663, 27.43037361472466],
            [84.64588883254208, 27.431672306095603],
            [84.64545464369901, 27.43352670857453],
            [84.64544037521246, 27.434442817123735],
            [84.64457185682573, 27.435439591967747],
            [84.64387124804374, 27.43595951453242],
            [84.64345099174642, 27.43685942737784],
            [84.64334986366248, 27.437624787368716],
            [84.64297906150922, 27.442915188473055],
            [84.64108672835137, 27.44399562462277],
            [84.64048774807945, 27.443732132785552],
            [84.63893151319063, 27.444010027497956],
            [84.63694658727486, 27.444061992135328],
            [84.63590407697087, 27.44462178738669],
            [84.63513368987805, 27.444456081546004],
            [84.63334940283254, 27.445881148049928],
            [84.63307446505137, 27.44622533698838],
            [84.6324579429859, 27.446668126048273],
            [84.63228776262936, 27.446747849456706],
            [84.63167128691104, 27.44719069192738],
            [84.63008096562294, 27.44882233321409],
            [84.62891023384529, 27.44942326111281],
            [84.62828053677146, 27.450876886071867],
            [84.62682355362205, 27.45039157933688],
            [84.62575245186122, 27.450055717967018],
            [84.6251744683255, 27.449927408066035],
            [84.62438332363293, 27.44989489876159],
            [84.62353023545977, 27.450090485937885],
            [84.62286212469505, 27.449788879046846],
            [84.62203229169639, 27.450413058888774],
            [84.62165601164165, 27.45186551537573],
            [84.62127338895712, 27.451285106462624],
            [84.61716455691152, 27.451463564042676],
            [84.61198411068202, 27.452009892130725],
            [84.60924019394393, 27.45227459933813],
            [84.60847665320449, 27.452898409417436],
            [84.60682722638263, 27.45432479513144],
            [84.60333644607799, 27.45318926189105],
            [84.60194680836466, 27.452283741548833],
            [84.6015906221452, 27.451434658384713],
            [84.5994623088352, 27.452590025725826],
            [84.59927563865503, 27.453148126965928],
            [84.5980639732639, 27.454170920505184],
            [84.59556934419838, 27.45459900800576],
            [84.59198259390965, 27.453233459810765],
            [84.58974664052602, 27.453678403518808],
            [84.58681226304333, 27.454776067544508],
            [84.58571350726373, 27.456127616599144],
            [84.58432655993197, 27.456431317910617],
            [84.58243630828046, 27.45650016676032],
            [84.58096554491652, 27.454207168354188],
            [84.57779177888308, 27.454283535265613],
            [84.57571086893265, 27.454172575673645],
            [84.5738423936205, 27.45598532140912],
            [84.5735150548207, 27.456266430495138],
            [84.57340868643871, 27.45673843451664],
            [84.57318279308586, 27.45877677280719],
            [84.56960960316738, 27.4601474505855],
            [84.56827472256283, 27.460389811846152],
            [84.56715199319906, 27.46106901125412],
            [84.56526818899519, 27.463161467455663],
            [84.56481867639833, 27.463179085058794],
            [84.56300098356672, 27.462744215278583],
            [84.56165302859682, 27.46303620434243],
            [84.55823994169307, 27.46009421165811],
            [84.55778615021117, 27.45888212616084],
            [84.55805666824696, 27.456099068066607],
            [84.55820443065983, 27.454045274170795],
            [84.55684508554548, 27.451925301231956],
            [84.55718833808463, 27.44951873643484],
            [84.55619578805062, 27.44324800732082],
            [84.55589448613586, 27.44152990713839],
            [84.5536290134002, 27.43946312058548],
            [84.55305184205086, 27.43934587224841],
            [84.55090312529832, 27.435308847673085],
            [84.54993316761258, 27.430044251946875],
            [84.54781746527385, 27.426762922834403],
            [84.54779348991042, 27.42521745321989],
            [84.54813572411807, 27.42078701285117],
            [84.54804784416025, 27.417838387155673],
            [84.54662296523055, 27.418067353380348],
            [84.54343483421307, 27.417971513874715],
            [84.5421172378748, 27.419337190168537],
            [84.5417210370533, 27.41950566894109],
            [84.54066016924467, 27.419058552021692],
            [84.53969392933325, 27.41897881273931],
            [84.53832187247157, 27.41798457943988],
            [84.53809784793825, 27.417669563214872],
            [84.53668871949574, 27.41540739749492],
            [84.53396672331935, 27.414440880074025],
            [84.53334620015924, 27.41473433095414],
            [84.53256646177563, 27.414653828297222],
            [84.5324857179382, 27.41442399457073],
            [84.5329324754445, 27.41347690017148],
            [84.53411555305566, 27.412524804559315],
            [84.53553424908847, 27.41207030468052],
            [84.54022227368695, 27.411036844522492],
            [84.54176483322226, 27.410329181800595],
            [84.54496201929933, 27.409994071797517],
            [84.5462357197824, 27.408222439769997],
            [84.54688882676751, 27.404675206040686],
            [84.54587353965886, 27.403842077809703],
            [84.54202936153438, 27.403658542092735],
            [84.53582306509402, 27.403418524092068],
            [84.53472886470249, 27.40180045638904],
            [84.5345533939183, 27.40092794133699],
            [84.53462996552271, 27.400559887651674],
            [84.53494454265869, 27.397639029925188],
            [84.53514796620948, 27.396965843532023],
            [84.53541923534772, 27.39410835443309],
            [84.53541650669305, 27.393018577302655],
            [84.53610326431554, 27.391149943221336],
            [84.53796136293478, 27.389491183247557],
            [84.53952661459145, 27.38752216593454],
            [84.53952647689728, 27.385802851101463],
            [84.53844729907063, 27.385175267623755],
            [84.53249098690708, 27.384002282705968],
            [84.53148903166365, 27.382997542356545],
            [84.53097912942532, 27.38142230104828],
            [84.53002494573123, 27.381143906457023],
            [84.5241589314976, 27.383381770488555],
            [84.51987291401173, 27.38409047514108],
            [84.51533677517193, 27.383610912882855],
            [84.51400034639738, 27.382943466746838],
            [84.51102766206144, 27.382198515635608],
            [84.50996154690554, 27.380801222370774],
            [84.50606818036896, 27.381147069201614],
            [84.50255285495278, 27.380002305755234],
            [84.50218201493928, 27.376935062011768],
            [84.50050016688117, 27.37655301732751],
            [84.49756637723888, 27.376683091685788],
            [84.49555665576892, 27.376737617045094],
            [84.49452005527498, 27.37609372154286],
            [84.49392752434669, 27.375276785878533],
            [84.49298778881355, 27.373238150731325],
            [84.4926326525861, 27.372943827118863],
            [84.49059433077754, 27.372517795291998],
            [84.48909472869964, 27.37189126615784],
            [84.48770573945944, 27.371135750305093],
            [84.4876139484532, 27.369777784723944],
            [84.4871645391086, 27.369021244501166],
            [84.48651988638161, 27.36839622830066],
            [84.48453843231216, 27.366634168824817],
            [84.4840496620645, 27.366545601780587],
            [84.48388995650862, 27.366142274090684],
            [84.48469718146161, 27.362321808646865],
            [84.48530405925425, 27.361247914385707],
            [84.48570489505481, 27.35913911960441],
            [84.56503740078676, 27.34759742341839],
            [84.60869971832709, 27.33643934562457],
            [84.63212406597904, 27.323163022209986],
            [84.6895685874151, 27.223548709672784],
            [84.680641949822, 27.2009952791305],
            [84.68644104838859, 27.15930159960434],
            [84.67245395104537, 27.113906070197604],
            [84.67465936789402, 27.096517096114923],
            [84.64716619946603, 27.07588995675356],
            [84.64284951649925, 27.046477519167297],
            [84.70143660269255, 27.027070397974903],
            [84.75676196414473, 27.00289802846757],
            [84.77335968155823, 27.01774270872095],
            [84.79381834212823, 26.995974652851842],
            [84.80866583711848, 27.01813102977028],
            [84.85469915348669, 27.008271994093114],
            [84.8662164831977, 26.983812852801925],
            [84.91129307449727, 27.018246605053914],
            [84.91695707774059, 27.034475558148653],
            [84.90006126768112, 27.045938336207666],
            [84.9177514836658, 27.072662726347176],
            [84.93128871630532, 27.068665855818853],
            [84.95517146982078, 27.13495499843755],
            [84.94505452596343, 27.165167017164322],
            [84.96014184470566, 27.19744843087904],
            [84.97289173570249, 27.26393481096673],
            [84.95760647397545, 27.300322377791964],
            [84.96888184183085, 27.320379009456353],
            [84.96928646529533, 27.35579159469949]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "RAUTAHAT",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.32616459732816, 26.744689210867882],
            [85.35126728769869, 26.761736845959934],
            [85.33117161121592, 26.777564751553452],
            [85.34038267332772, 26.789352164484757],
            [85.32210828788548, 26.803686043013137],
            [85.33560324120192, 26.833783149265674],
            [85.34924720470786, 26.83684615566924],
            [85.34225238405409, 26.858488375101665],
            [85.39551498090427, 26.89240564695821],
            [85.38623489273465, 26.920502255537738],
            [85.39757401920383, 26.936851046640765],
            [85.40131222059432, 26.97760873580455],
            [85.38597005578842, 27.00863285904436],
            [85.43444603694091, 27.069688079061624],
            [85.43203715866268, 27.085676430603908],
            [85.47146963185885, 27.118617569673642],
            [85.48917510719002, 27.153371761053624],
            [85.49212900742404, 27.160778336716202],
            [85.49562531099019, 27.16323848652898],
            [85.50268281258273, 27.16255817999967],
            [85.50374070008083, 27.165454857563574],
            [85.50078469116079, 27.16656033645091],
            [85.49966780011772, 27.16843440156506],
            [85.50215661494748, 27.17035375032668],
            [85.50313948871002, 27.173653492830503],
            [85.50094271885024, 27.175782204900006],
            [85.49773769081199, 27.17403337185517],
            [85.49518496351685, 27.1736793436741],
            [85.49405598690089, 27.169923889594457],
            [85.48905087074553, 27.1659208292034],
            [85.48620227246309, 27.164416996363386],
            [85.48020570273415, 27.16293504662145],
            [85.47407333774908, 27.162672499237868],
            [85.47257828648196, 27.16185348261935],
            [85.46871934501615, 27.16329716666657],
            [85.46749170876424, 27.16566525469267],
            [85.4631891924105, 27.166621662762566],
            [85.45721410421567, 27.16614691564398],
            [85.45581831797064, 27.167724819267825],
            [85.45292426091781, 27.1684293719114],
            [85.45229627833123, 27.170091783141636],
            [85.45250384246627, 27.175603307344012],
            [85.4533824515274, 27.177417043391447],
            [85.4515226826854, 27.179703599517566],
            [85.44948346526364, 27.18046014706633],
            [85.45005181678299, 27.184127017033372],
            [85.44810337212733, 27.18678896258672],
            [85.4438949334785, 27.187486618649125],
            [85.43935153253896, 27.187395741164607],
            [85.4369094805917, 27.187294398421585],
            [85.4343957709085, 27.189289680212433],
            [85.43421541572437, 27.189483285060955],
            [85.43330588610297, 27.19051454121968],
            [85.43082858698982, 27.19067518689855],
            [85.42663128075147, 27.193107102864584],
            [85.42291141316934, 27.192025939271815],
            [85.42140303883791, 27.19110498312328],
            [85.41900727695409, 27.190134357611026],
            [85.41593765307778, 27.191435526146233],
            [85.41354753281023, 27.19446244668517],
            [85.41315926493596, 27.194782161954034],
            [85.41198047325551, 27.19523847735076],
            [85.41032633010497, 27.19646210652367],
            [85.40779843077169, 27.196724402748547],
            [85.40658881191943, 27.196937330588096],
            [85.40512253202972, 27.196753482188907],
            [85.39933373337682, 27.196619651188307],
            [85.3981416902875, 27.19723395229969],
            [85.39728498478611, 27.197675610151798],
            [85.3962761907414, 27.20009060522066],
            [85.3961278775594, 27.201096013216425],
            [85.39727156197034, 27.2062102631628],
            [85.39718673678445, 27.206438997853287],
            [85.39631793814114, 27.207740360134057],
            [85.3930450375164, 27.210207213977977],
            [85.39190408566762, 27.209056673241275],
            [85.39161929613354, 27.208930906809826],
            [85.39086625961734, 27.208994784024366],
            [85.3893152179878, 27.209718563902026],
            [85.38884464594331, 27.209466041456377],
            [85.38516386471427, 27.207431438073552],
            [85.3809465280926, 27.20898912844739],
            [85.37897481630577, 27.210238072908975],
            [85.37737106895335, 27.211505946118667],
            [85.37333523792445, 27.21018968197667],
            [85.37299585423173, 27.210086974062982],
            [85.36839506043852, 27.211778865936076],
            [85.36792059487168, 27.211772241359657],
            [85.36534088969194, 27.212275710177614],
            [85.36255799564746, 27.212778808215397],
            [85.36160075125707, 27.21254443877632],
            [85.35953335156377, 27.210870236584793],
            [85.3584678163795, 27.213310331748456],
            [85.35623611694172, 27.21364328588532],
            [85.35102357886672, 27.21884195769369],
            [85.34886198501833, 27.222120505649343],
            [85.34411636798751, 27.221227607495226],
            [85.33882502107244, 27.21959074991193],
            [85.33688650358056, 27.220453026933253],
            [85.33281557814922, 27.223016356340793],
            [85.33071933638591, 27.22303852968502],
            [85.33038732652795, 27.223131925581246],
            [85.32666942129845, 27.22441475825469],
            [85.32562878636432, 27.224605124048054],
            [85.32319852364529, 27.225408699247343],
            [85.32277328459533, 27.22562252927924],
            [85.32096087530788, 27.226767644535897],
            [85.31528927638608, 27.228201748417085],
            [85.31231360133526, 27.230555663451998],
            [85.31085475445228, 27.2305490205633],
            [85.30739517810578, 27.23184024360706],
            [85.30650361800078, 27.23162522998571],
            [85.30403891372208, 27.22993134174335],
            [85.29811510649249, 27.231145951218693],
            [85.29302742796685, 27.232925711136694],
            [85.28788545478595, 27.23104410944541],
            [85.28063146839632, 27.231223477655956],
            [85.27953719901252, 27.228778836687397],
            [85.27380668964253, 27.229645517335832],
            [85.26934130750803, 27.228077467631152],
            [85.25122529408627, 27.189539290897144],
            [85.24639009869095, 27.159052620561255],
            [85.23124208416569, 27.134204220531156],
            [85.19719527804446, 27.107496999469944],
            [85.20239450568928, 27.08348765869279],
            [85.1832249921206, 27.050313061229723],
            [85.200183805479, 27.0386154329231],
            [85.18277217405672, 27.01864662375966],
            [85.16362939332463, 26.979572925252704],
            [85.17229830703278, 26.976879363664608],
            [85.163980966295, 26.93832403759942],
            [85.1791119845599, 26.925245375486643],
            [85.17637099903689, 26.86926624480249],
            [85.19284189635873, 26.863022807500915],
            [85.19118129490386, 26.831282474916904],
            [85.1767597934083, 26.809731787254368],
            [85.21178345521868, 26.757953115879673],
            [85.23439777400286, 26.762658552576575],
            [85.30554115364528, 26.75216591151555],
            [85.32616459732816, 26.744689210867882]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SAPTARI",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.48593609531247, 26.549860378813086],
            [86.49730096029653, 26.54237072525257],
            [86.54183987690422, 26.538537930805767],
            [86.57026899018386, 26.496545335455046],
            [86.61447295851647, 26.486512267015925],
            [86.63961073415672, 26.463542261359066],
            [86.69607077447327, 26.448930863006158],
            [86.72506917266588, 26.426229023001213],
            [86.74718243601038, 26.428968781160886],
            [86.76628570093958, 26.459364572162293],
            [86.83342910760719, 26.439085542531735],
            [86.86024927019177, 26.456355114008222],
            [86.89405507714233, 26.46229441978782],
            [86.89014279857682, 26.4818363337359],
            [86.9040946658046, 26.48835231455536],
            [86.9332895522463, 26.533619169608933],
            [86.96628331089356, 26.546712464700857],
            [86.99242600257598, 26.57238991848934],
            [86.97883989097498, 26.625236250778496],
            [86.99111409256746, 26.64959539274754],
            [87.01284383605837, 26.670100784819237],
            [87.00999783304415, 26.710886539441386],
            [86.99089201406238, 26.698543103422985],
            [86.96708537238044, 26.735700937365284],
            [86.9325097546286, 26.740729900892656],
            [86.92075098235723, 26.766457692294246],
            [86.91139367704639, 26.74108381910019],
            [86.88415145634119, 26.712155200135015],
            [86.84045962554103, 26.691799111417435],
            [86.83755724468992, 26.709855228661446],
            [86.79995575761602, 26.712673026385197],
            [86.75398424105659, 26.688538984623143],
            [86.7383885878364, 26.711884671064457],
            [86.70595121970047, 26.707003422947476],
            [86.69049253637202, 26.689434764841696],
            [86.66490998339319, 26.704488884867743],
            [86.64954135340865, 26.689757105254248],
            [86.63423053456323, 26.69856419808169],
            [86.6111181081203, 26.68935467850921],
            [86.58833415228803, 26.69743658425805],
            [86.57357815142022, 26.720524932226624],
            [86.57392433242585, 26.739582388470396],
            [86.55326780028827, 26.74712562686552],
            [86.5772257067397, 26.77575995153209],
            [86.55756265005434, 26.790247657580547],
            [86.53127597750769, 26.788831775773648],
            [86.52030085322347, 26.76151605585519],
            [86.52852644591198, 26.74472354597956],
            [86.50284390622006, 26.72413304894661],
            [86.50870396243525, 26.681816365994067],
            [86.49845679867472, 26.657617507044016],
            [86.51194752044321, 26.62262018075521],
            [86.51157799667237, 26.598954313811213],
            [86.49416650510958, 26.578072166985596],
            [86.48593609531247, 26.549860378813086]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SIRAHA",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.48593609531247, 26.549860378813086],
            [86.49416650510958, 26.578072166985596],
            [86.51157799667237, 26.598954313811213],
            [86.51194752044321, 26.62262018075521],
            [86.49845679867472, 26.657617507044016],
            [86.50870396243525, 26.681816365994067],
            [86.50284390622006, 26.72413304894661],
            [86.52852644591198, 26.74472354597956],
            [86.52030085322347, 26.76151605585519],
            [86.53127597750769, 26.788831775773648],
            [86.5534715407219, 26.81487817154581],
            [86.55463362002303, 26.83067061392509],
            [86.52080913246662, 26.834533827095946],
            [86.50457160461376, 26.856259764773387],
            [86.4817202341781, 26.869894990398983],
            [86.48012608905813, 26.886708856907187],
            [86.45687266742344, 26.887463023110858],
            [86.41870444091145, 26.906141295726886],
            [86.38267953415553, 26.90822675581978],
            [86.36473854790714, 26.87298506278638],
            [86.29883225672606, 26.885699837942177],
            [86.27103139097383, 26.88239791500375],
            [86.24697797627397, 26.86389552163618],
            [86.22744499070531, 26.880686152155388],
            [86.17913228174602, 26.8996471746791],
            [86.15600690656395, 26.918800598697732],
            [86.15748879425729, 26.92998750980133],
            [86.13251024239746, 26.914991607967472],
            [86.13117467604101, 26.893326812431418],
            [86.15234969134264, 26.85238702829261],
            [86.15492547247841, 26.819332737961187],
            [86.14459098611432, 26.78694542401131],
            [86.1593135880735, 26.752289222589024],
            [86.17368902644147, 26.74919223294851],
            [86.19961873363799, 26.686909584458043],
            [86.19668714895687, 26.666945918262655],
            [86.16869484641816, 26.643078682379393],
            [86.15239361591162, 26.639020021406704],
            [86.14663547810206, 26.60893407372715],
            [86.19225360427312, 26.61474899970332],
            [86.19671170794689, 26.593423385668725],
            [86.23430643552756, 26.593506497564967],
            [86.25327452959084, 26.614854434124847],
            [86.2928751547778, 26.613319223855736],
            [86.30786031589274, 26.62004963054536],
            [86.34253067606447, 26.61708652843048],
            [86.34091117561556, 26.60607171922816],
            [86.39599320273712, 26.59357348197457],
            [86.39497650750943, 26.58519063851504],
            [86.45549098819791, 26.56815864093985],
            [86.48593609531247, 26.549860378813086]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SARLAHI",
        Province: "Province 2"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.8158621243843, 27.161307431681035],
            [85.81263609358022, 27.162108182553666],
            [85.80925114318964, 27.16163054129654],
            [85.80569737542852, 27.164041487530948],
            [85.79849190294752, 27.167393480690613],
            [85.79628056304053, 27.167403880165967],
            [85.79320494341793, 27.168762709753935],
            [85.79005084342415, 27.1711360864407],
            [85.78859679652672, 27.173483455777404],
            [85.78343440173829, 27.17539483497596],
            [85.7738495983028, 27.178370663277864],
            [85.76842235303938, 27.18239090438633],
            [85.7631429555545, 27.182522686141834],
            [85.75908865602514, 27.18467529833153],
            [85.75398584151104, 27.185934084923904],
            [85.74879465346716, 27.18667526439898],
            [85.74754888104695, 27.18835394581044],
            [85.7446577976392, 27.187522658214576],
            [85.74168462147715, 27.188023965566206],
            [85.73777164723606, 27.190481745436564],
            [85.73331297735281, 27.190730275377383],
            [85.72961335277476, 27.19224453893328],
            [85.72552004017865, 27.194887653107177],
            [85.72228326824505, 27.194420483690262],
            [85.72085109211683, 27.19105260615262],
            [85.71782267576188, 27.1882341503338],
            [85.71455105648702, 27.187800351692175],
            [85.7050093725747, 27.184975887084583],
            [85.70272392466602, 27.18487354636778],
            [85.69835926345912, 27.186064934827026],
            [85.69489181855452, 27.184099236287715],
            [85.6937381197191, 27.181051802148662],
            [85.69012897299491, 27.17776703545235],
            [85.68599051917046, 27.176455969299596],
            [85.68566168771319, 27.174539749660713],
            [85.68171774860792, 27.16935433408987],
            [85.67753390435034, 27.167191958235275],
            [85.67342118479003, 27.167191495056457],
            [85.67107726658888, 27.16646991726714],
            [85.66961600788083, 27.16403979711689],
            [85.66559351977223, 27.16176130927609],
            [85.66518823551264, 27.159916529258464],
            [85.66244522635121, 27.1561701157054],
            [85.65606559385785, 27.155080373431932],
            [85.65633304570228, 27.152623826011766],
            [85.65386929634673, 27.15296795311497],
            [85.65052514883702, 27.150625614833615],
            [85.64793360263845, 27.14566439111933],
            [85.64484305513984, 27.14335817098525],
            [85.64173821526241, 27.142152777116262],
            [85.64109337458316, 27.14253457962661],
            [85.63635962813325, 27.141859226921145],
            [85.63345845290237, 27.14135498169457],
            [85.6323366399073, 27.139692615494482],
            [85.63145335391573, 27.138246908043218],
            [85.63033692281348, 27.13757046470529],
            [85.62832483215445, 27.13744929538524],
            [85.6265857279472, 27.13825575437122],
            [85.62280456481352, 27.140682301054692],
            [85.62250710396546, 27.140677171683354],
            [85.62108734176718, 27.139832982243576],
            [85.61968030590764, 27.13887612051782],
            [85.61743759044676, 27.140205427921828],
            [85.61675748369188, 27.140679265701937],
            [85.6132783540731, 27.141477423525643],
            [85.61110449887894, 27.14133184703389],
            [85.60902557117736, 27.140187746509763],
            [85.60800857695804, 27.139715174071174],
            [85.60651497323595, 27.1396958120574],
            [85.6045796999092, 27.139737530405494],
            [85.60303419128579, 27.141766138449533],
            [85.59954705949599, 27.143572354269487],
            [85.5982140518899, 27.143213841107666],
            [85.59718975366754, 27.14292160168869],
            [85.59583738252161, 27.141489026648895],
            [85.5923778005432, 27.14111364431161],
            [85.5883549791303, 27.142945853649515],
            [85.58600700399121, 27.143488457019085],
            [85.58491164753246, 27.143434552931815],
            [85.58291175186253, 27.143166183390477],
            [85.58186250420876, 27.143681245576833],
            [85.57858067092877, 27.14346082121876],
            [85.57653918177357, 27.140981054737065],
            [85.57473721379928, 27.139403823277462],
            [85.57277149990276, 27.13801897717046],
            [85.57095631115322, 27.13712065722989],
            [85.57002671786508, 27.138288839166393],
            [85.56941544486513, 27.140721379686525],
            [85.56891945728893, 27.143504801771932],
            [85.56719168361077, 27.1435322713731],
            [85.5665198987882, 27.143435144146526],
            [85.56562095045766, 27.143626800050843],
            [85.5612531319565, 27.144471029281302],
            [85.56030999998875, 27.143807020078704],
            [85.5587143795675, 27.14400045378687],
            [85.55543256113654, 27.145593335290037],
            [85.55462732861845, 27.146733328652747],
            [85.54976692762033, 27.145719857438767],
            [85.54934333346776, 27.14234725658371],
            [85.54716664761882, 27.142451106286448],
            [85.54467486668835, 27.144712864755785],
            [85.53754166235528, 27.148609277756595],
            [85.53468058562586, 27.148128246431355],
            [85.53296916490902, 27.14610252016378],
            [85.53235282319521, 27.142585728782766],
            [85.53078917151652, 27.141008212622662],
            [85.52809472850699, 27.14040695755835],
            [85.52122845069886, 27.140864947401653],
            [85.51596240213888, 27.142663798881905],
            [85.51259582257283, 27.141978465264458],
            [85.50964924146047, 27.143424854625696],
            [85.50425538062521, 27.143838977690102],
            [85.5010364275342, 27.143551964486722],
            [85.49570431661458, 27.1465456218394],
            [85.49122363272504, 27.15408995107245],
            [85.4895200368713, 27.154613406066233],
            [85.48917510719002, 27.153371761053624],
            [85.47146963185885, 27.118617569673642],
            [85.43203715866268, 27.085676430603908],
            [85.43444603694091, 27.069688079061624],
            [85.38597005578842, 27.00863285904436],
            [85.40131222059432, 26.97760873580455],
            [85.39757401920383, 26.936851046640765],
            [85.38623489273465, 26.920502255537738],
            [85.39551498090427, 26.89240564695821],
            [85.34225238405409, 26.858488375101665],
            [85.34924720470786, 26.83684615566924],
            [85.33560324120192, 26.833783149265674],
            [85.32210828788548, 26.803686043013137],
            [85.34038267332772, 26.789352164484757],
            [85.33117161121592, 26.777564751553452],
            [85.35126728769869, 26.761736845959934],
            [85.32616459732816, 26.744689210867882],
            [85.36938435632167, 26.758307310626076],
            [85.4077121876453, 26.791293494153678],
            [85.45163093830581, 26.78157967604899],
            [85.49558693334465, 26.79731891833452],
            [85.4977894819665, 26.811873414231638],
            [85.51980248496865, 26.814471722531387],
            [85.54242631291994, 26.837603784701237],
            [85.56570407154636, 26.841009355436764],
            [85.57532766084748, 26.86110935384545],
            [85.58577300307532, 26.847424796182235],
            [85.62346641843412, 26.873640891504262],
            [85.64236003892208, 26.853292685313942],
            [85.68025452813971, 26.843311366825297],
            [85.67619757272296, 26.864465678416373],
            [85.70355556470695, 26.898135638099234],
            [85.70702971113083, 26.924145077404184],
            [85.72658766290755, 26.954463144801228],
            [85.74772277107071, 27.03456600913543],
            [85.80095417278918, 27.104883011785088],
            [85.79215123443811, 27.130796253618],
            [85.8158621243843, 27.161307431681035]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SINDHUPALCHOK",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.06501164706093, 27.899783910223466],
            [86.00301070519033, 27.91134448324337],
            [85.94827815318007, 27.942074489984805],
            [85.97830251029502, 27.987896056674206],
            [85.9149026655109, 28.049378590219472],
            [85.90137005160373, 28.053453458841023],
            [85.89805060155285, 28.10907774281341],
            [85.87012881035447, 28.123770925770824],
            [85.87235560976409, 28.142941647794533],
            [85.8513066670464, 28.16719551666287],
            [85.84851913035645, 28.184174464362442],
            [85.82980181373347, 28.184735357652936],
            [85.8166146158915, 28.202596464266566],
            [85.80001311244614, 28.196419812517867],
            [85.77843608332381, 28.174358767789247],
            [85.74969055989415, 28.17528359397723],
            [85.68855727954235, 28.156072182638145],
            [85.65086680053675, 28.151163033493884],
            [85.63212225803977, 28.129989572252036],
            [85.61651005931273, 28.132742790657915],
            [85.60161918232876, 28.15811987874391],
            [85.58396882454083, 28.15345415153898],
            [85.55452532294474, 28.168307247712836],
            [85.53296490319934, 28.167475686820275],
            [85.49033645047469, 28.13871634860862],
            [85.48924380049021, 28.105788331588176],
            [85.4751717321696, 28.105620122661517],
            [85.44179921335613, 28.08259217295607],
            [85.47845486958558, 28.05634030251686],
            [85.49710819382034, 28.030206475996014],
            [85.48302862487489, 27.969612256164183],
            [85.48753893806254, 27.919835568278128],
            [85.4702567313691, 27.869494048636618],
            [85.45164185096407, 27.849567314325807],
            [85.4485930541312, 27.817652643872194],
            [85.47447238838585, 27.77782320577957],
            [85.53348974386223, 27.791914029130066],
            [85.56574542796848, 27.7642126532779],
            [85.5500172210171, 27.756935943727253],
            [85.56726507871197, 27.744846214326547],
            [85.60392965376015, 27.7487967167835],
            [85.61330370734994, 27.733418004895505],
            [85.65467505574092, 27.720182829975666],
            [85.68005317608254, 27.699632167729597],
            [85.68129222833777, 27.68537300559741],
            [85.70627968490199, 27.65231093449313],
            [85.7463023093242, 27.677767266265466],
            [85.78725740343276, 27.665602119270318],
            [85.81259020404303, 27.6690520873958],
            [85.81579838215964, 27.646427046173535],
            [85.84224033164696, 27.626113837947035],
            [85.85657857479369, 27.632574465968077],
            [85.88099126910087, 27.614567342877756],
            [85.88553476510985, 27.6121403097682],
            [85.91062766190944, 27.63709524690977],
            [85.91824740631644, 27.66353260842818],
            [85.93915058588993, 27.669201215130972],
            [85.93676064494345, 27.71018423205279],
            [85.96651689764518, 27.742190472233943],
            [85.97724751565677, 27.735368986828],
            [86.0046146270427, 27.74523578544544],
            [86.02774212079014, 27.739473606358697],
            [86.03409339873554, 27.75662808592324],
            [86.01979001869185, 27.77246645194079],
            [86.0197689743692, 27.80956244618997],
            [86.00251330214473, 27.8301966163773],
            [86.04248646462987, 27.867823824133698],
            [86.06501164706093, 27.899783910223466]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KAVREPALANCHOK",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.88099126910087, 27.614567342877756],
            [85.85657857479369, 27.632574465968077],
            [85.84224033164696, 27.626113837947035],
            [85.81579838215964, 27.646427046173535],
            [85.81259020404303, 27.6690520873958],
            [85.78725740343276, 27.665602119270318],
            [85.7463023093242, 27.677767266265466],
            [85.70627968490199, 27.65231093449313],
            [85.68129222833777, 27.68537300559741],
            [85.68005317608254, 27.699632167729597],
            [85.65467505574092, 27.720182829975666],
            [85.61330370734994, 27.733418004895505],
            [85.60392965376015, 27.7487967167835],
            [85.56726507871197, 27.744846214326547],
            [85.5500172210171, 27.756935943727253],
            [85.5385433075881, 27.75493152607309],
            [85.52513886918338, 27.725637201385915],
            [85.52174717879528, 27.70671070375013],
            [85.52174751272638, 27.70640391430755],
            [85.52168377684016, 27.70629044213097],
            [85.5215339841122, 27.706309144879008],
            [85.52142691569217, 27.706425304531123],
            [85.52127692245534, 27.706482353974284],
            [85.50600883859335, 27.69274061313941],
            [85.49144691333782, 27.656190153425833],
            [85.46064676315082, 27.62529484983991],
            [85.45404700397533, 27.63711950212048],
            [85.42048007958516, 27.620191871179834],
            [85.43569442098494, 27.606518010027905],
            [85.42127687375135, 27.59284231948664],
            [85.40252864626112, 27.602092722429862],
            [85.40136810618323, 27.572759604877632],
            [85.38692058066118, 27.563050772398224],
            [85.40402376158846, 27.545111365968896],
            [85.43694803970155, 27.541589504986696],
            [85.44165161557468, 27.47653228165293],
            [85.40669821413073, 27.44750397844845],
            [85.41865805184018, 27.408284890063538],
            [85.44311315019134, 27.402121118332364],
            [85.44776663584189, 27.38821239379903],
            [85.47002407233106, 27.38827290314512],
            [85.47261191498829, 27.37013726079076],
            [85.48723058767521, 27.376474794055653],
            [85.52326356875854, 27.35002620586836],
            [85.53320647136775, 27.356725131677194],
            [85.55905928751673, 27.339364014905108],
            [85.61126677769127, 27.34295875248125],
            [85.63836799116537, 27.351863850551858],
            [85.6508414739152, 27.333566828360837],
            [85.67980077435625, 27.345211648000436],
            [85.70366474209422, 27.34488021182346],
            [85.69527992396957, 27.364413836661797],
            [85.71580147175038, 27.407518437068227],
            [85.72796061898188, 27.411916349450937],
            [85.77349426820763, 27.398217190732332],
            [85.78622641027201, 27.438197667532286],
            [85.82151660793107, 27.45103080746803],
            [85.79751664110319, 27.500287296533024],
            [85.81890116051149, 27.51786008941079],
            [85.8285684623848, 27.546728988375296],
            [85.82325061655261, 27.567148554938],
            [85.84675095904453, 27.57926882386392],
            [85.88099126910087, 27.614567342877756]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "LALITPUR",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.35203376909453, 27.66797714868666],
            [85.30681188340674, 27.693514999709418],
            [85.2823585993324, 27.643912028883346],
            [85.29249784065537, 27.634525624042087],
            [85.29185867699911, 27.59776625422639],
            [85.26306144811879, 27.57088281662218],
            [85.23156601379205, 27.537212771783064],
            [85.25085704240063, 27.528587275389622],
            [85.23400943355745, 27.504961672440746],
            [85.26825531956426, 27.453054198489497],
            [85.29311855976478, 27.45255713157465],
            [85.31779712776665, 27.436019499483727],
            [85.36877410070458, 27.42434522646218],
            [85.3988175831435, 27.404280222206207],
            [85.41865805184018, 27.408284890063538],
            [85.40669821413073, 27.44750397844845],
            [85.44165161557468, 27.47653228165293],
            [85.43694803970155, 27.541589504986696],
            [85.40402376158846, 27.545111365968896],
            [85.38692058066118, 27.563050772398224],
            [85.40136810618323, 27.572759604877632],
            [85.40252864626112, 27.602092722429862],
            [85.42127687375135, 27.59284231948664],
            [85.43569442098494, 27.606518010027905],
            [85.42048007958516, 27.620191871179834],
            [85.35203376909453, 27.66797714868666]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BHAKTAPUR",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.52513886918338, 27.725637201385915],
            [85.47504281149325, 27.715757340893138],
            [85.47368186976328, 27.716105701328534],
            [85.47332035608741, 27.716224597480053],
            [85.47184336352797, 27.717228365567365],
            [85.47124616322132, 27.717257273847633],
            [85.4366554559158, 27.727785520148217],
            [85.37553921238697, 27.694595434304453],
            [85.35203376909453, 27.66797714868666],
            [85.42048007958516, 27.620191871179834],
            [85.45404700397533, 27.63711950212048],
            [85.46064676315082, 27.62529484983991],
            [85.49144691333782, 27.656190153425833],
            [85.50600883859335, 27.69274061313941],
            [85.52127692245534, 27.706482353974284],
            [85.52142691569217, 27.706425304531123],
            [85.5215339841122, 27.706309144879008],
            [85.52168377684016, 27.70629044213097],
            [85.52174751272638, 27.70640391430755],
            [85.52174717879528, 27.70671070375013],
            [85.52513886918338, 27.725637201385915]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KATHMANDU",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.5500172210171, 27.756935943727253],
            [85.56574542796848, 27.7642126532779],
            [85.53348974386223, 27.791914029130066],
            [85.47447238838585, 27.77782320577957],
            [85.4485930541312, 27.817652643872194],
            [85.41143192191053, 27.809993244865954],
            [85.39176677355441, 27.817260398760034],
            [85.37284685031226, 27.809148280124234],
            [85.33227647198086, 27.815189841867756],
            [85.29174153757778, 27.808985532291484],
            [85.25262844772834, 27.77567847282356],
            [85.25500478807442, 27.77306485605051],
            [85.25679971371345, 27.773441077773093],
            [85.2584237912573, 27.7715877217749],
            [85.25890305118944, 27.771635243903322],
            [85.26321835963326, 27.769267760982803],
            [85.26356639254047, 27.768384769867883],
            [85.26347843475193, 27.76785091989813],
            [85.26301157602428, 27.76717389709792],
            [85.26844774087944, 27.762634837083322],
            [85.26955771574447, 27.760817654604445],
            [85.27016056210805, 27.759663852873338],
            [85.27024206979613, 27.758383995564774],
            [85.26700372034784, 27.745570715179255],
            [85.2374783894389, 27.745303376985465],
            [85.21740275942261, 27.72222939048921],
            [85.19720839429164, 27.716341517372125],
            [85.18869324377265, 27.681417871373945],
            [85.23320252866495, 27.649803892717546],
            [85.22265824255017, 27.62620494509824],
            [85.2355400630006, 27.62001994900282],
            [85.24263634441189, 27.578168927279986],
            [85.26306144811879, 27.57088281662218],
            [85.29185867699911, 27.59776625422639],
            [85.29249784065537, 27.634525624042087],
            [85.2823585993324, 27.643912028883346],
            [85.30681188340674, 27.693514999709418],
            [85.35203376909453, 27.66797714868666],
            [85.37553921238697, 27.694595434304453],
            [85.4366554559158, 27.727785520148217],
            [85.47124616322132, 27.717257273847633],
            [85.47184336352797, 27.717228365567365],
            [85.47332035608741, 27.716224597480053],
            [85.47368186976328, 27.716105701328534],
            [85.47504281149325, 27.715757340893138],
            [85.52513886918338, 27.725637201385915],
            [85.5385433075881, 27.75493152607309],
            [85.5500172210171, 27.756935943727253]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "NUWAKOT",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.44179921335613, 28.08259217295607],
            [85.40987795288069, 28.060612236576965],
            [85.40402711118007, 28.040383865343067],
            [85.35497730034402, 28.011729377349464],
            [85.33346746195609, 27.992999929025455],
            [85.28992419968695, 27.983167028200054],
            [85.27630377472477, 27.96515478462201],
            [85.25353047545028, 27.971527174111884],
            [85.18793121273818, 27.976057084486964],
            [85.18033357716187, 27.98808099409723],
            [85.18743044400468, 28.018699956046273],
            [85.14027390497915, 28.029167424023335],
            [85.13304513698601, 28.038174134958233],
            [85.17044077936141, 28.08134034385717],
            [85.12248004913994, 28.091254135165368],
            [85.11447859906991, 28.07672148177629],
            [85.06224506971655, 28.066966036805],
            [85.04162524926566, 28.03703214831256],
            [84.9956100575243, 28.024999432181776],
            [85.00314840786895, 28.000228287379624],
            [85.02966342951927, 27.995500051400374],
            [85.0286850561429, 27.971950630638123],
            [85.00804967496312, 27.951194991335395],
            [85.01610207970074, 27.943682033886894],
            [84.98893952054841, 27.885717941214093],
            [84.99233197673212, 27.855036627194124],
            [85.02250986536149, 27.83346765609051],
            [85.01254988792164, 27.809489664174464],
            [85.04727952001394, 27.812941804810382],
            [85.04659288508172, 27.801660194496126],
            [85.08809085872198, 27.787702041372107],
            [85.12477927749308, 27.76067932888427],
            [85.25262844772834, 27.77567847282356],
            [85.29174153757778, 27.808985532291484],
            [85.33227647198086, 27.815189841867756],
            [85.37284685031226, 27.809148280124234],
            [85.39176677355441, 27.817260398760034],
            [85.41143192191053, 27.809993244865954],
            [85.4485930541312, 27.817652643872194],
            [85.45164185096407, 27.849567314325807],
            [85.4702567313691, 27.869494048636618],
            [85.48753893806254, 27.919835568278128],
            [85.48302862487489, 27.969612256164183],
            [85.49710819382034, 28.030206475996014],
            [85.47845486958558, 28.05634030251686],
            [85.44179921335613, 28.08259217295607]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "RASUWA",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.80001311244614, 28.196419812517867],
            [85.74831108233388, 28.24029430523771],
            [85.73318504544807, 28.29098651120079],
            [85.73724489919344, 28.319270739938737],
            [85.72177777543794, 28.35417872446841],
            [85.72432389272666, 28.37336955056774],
            [85.71148176327608, 28.38628457732883],
            [85.6837824544334, 28.382282110493],
            [85.68619374314696, 28.346956051316397],
            [85.66648221068588, 28.348645996705226],
            [85.65368097292996, 28.316471092386347],
            [85.66072445074829, 28.302972237064992],
            [85.63682659307882, 28.27523176380632],
            [85.60868851087233, 28.2568821075327],
            [85.59309801524985, 28.308474868326915],
            [85.54727219420396, 28.315436248855814],
            [85.50652794895129, 28.334013682996346],
            [85.4580034412209, 28.33702907339808],
            [85.4153872100944, 28.327135693435864],
            [85.41237296340587, 28.308925278343054],
            [85.3774554387162, 28.278667738507583],
            [85.34826419702621, 28.28850295824975],
            [85.33981096802225, 28.303934081302952],
            [85.29293391265813, 28.287562775440882],
            [85.25477754144642, 28.294471691609097],
            [85.24145610415434, 28.317827851064113],
            [85.20404988130427, 28.340044034059826],
            [85.18224942964358, 28.324525441066946],
            [85.15984249276025, 28.331925009814647],
            [85.17728010456335, 28.305883674470056],
            [85.18061326543703, 28.28093508553251],
            [85.2004292419735, 28.256634361690836],
            [85.18859449862614, 28.219501113782698],
            [85.20438162540205, 28.204037021446045],
            [85.19401296227082, 28.167802327272767],
            [85.18265054327323, 28.16701889217404],
            [85.15444898587751, 28.139487710968055],
            [85.1496425751602, 28.117278529409614],
            [85.12603005121304, 28.10723053874458],
            [85.12248004913994, 28.091254135165368],
            [85.17044077936141, 28.08134034385717],
            [85.13304513698601, 28.038174134958233],
            [85.14027390497915, 28.029167424023335],
            [85.18743044400468, 28.018699956046273],
            [85.18033357716187, 27.98808099409723],
            [85.18793121273818, 27.976057084486964],
            [85.25353047545028, 27.971527174111884],
            [85.27630377472477, 27.96515478462201],
            [85.28992419968695, 27.983167028200054],
            [85.33346746195609, 27.992999929025455],
            [85.35497730034402, 28.011729377349464],
            [85.40402711118007, 28.040383865343067],
            [85.40987795288069, 28.060612236576965],
            [85.44179921335613, 28.08259217295607],
            [85.4751717321696, 28.105620122661517],
            [85.48924380049021, 28.105788331588176],
            [85.49033645047469, 28.13871634860862],
            [85.53296490319934, 28.167475686820275],
            [85.55452532294474, 28.168307247712836],
            [85.58396882454083, 28.15345415153898],
            [85.60161918232876, 28.15811987874391],
            [85.61651005931273, 28.132742790657915],
            [85.63212225803977, 28.129989572252036],
            [85.65086680053675, 28.151163033493884],
            [85.68855727954235, 28.156072182638145],
            [85.74969055989415, 28.17528359397723],
            [85.77843608332381, 28.174358767789247],
            [85.80001311244614, 28.196419812517867]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DHADING",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.15984249276025, 28.331925009814647],
            [85.12077318060867, 28.335770626168657],
            [85.1079553746433, 28.346603985690198],
            [85.10311904701672, 28.345564930382785],
            [85.09515515556387, 28.344742552511715],
            [85.09114665144043, 28.345036344804075],
            [85.08836823245419, 28.34637144267705],
            [85.08417843355582, 28.34564234487345],
            [85.08054926781598, 28.345883192971176],
            [85.07331664379166, 28.342270070082982],
            [85.07116083644773, 28.340683121904597],
            [85.06737071865527, 28.339284890801178],
            [85.06605577722917, 28.337567205610632],
            [85.05299310573307, 28.327014357140698],
            [85.05014230836942, 28.32598282162889],
            [85.0466574481176, 28.325867478764813],
            [85.04475336070819, 28.321661382799196],
            [85.04213536877788, 28.31775402059411],
            [85.04265213848338, 28.31539265901971],
            [85.04009551711995, 28.310431246054897],
            [85.03739757005764, 28.308351546786493],
            [85.03324525975896, 28.303860078265064],
            [85.03272779726116, 28.302205938395243],
            [85.03397450888559, 28.299604521679576],
            [85.03708237945455, 28.29549558338001],
            [85.03799295825878, 28.29189055034636],
            [85.03727053947023, 28.28960187125179],
            [85.0350911255394, 28.28738509939957],
            [85.03486812733235, 28.28520762009755],
            [85.0318253538113, 28.279263908079514],
            [85.03123656427249, 28.2729022881458],
            [85.0301786836673, 28.270870946392467],
            [85.02694212179784, 28.261522250603345],
            [85.021403071229, 28.260313895142012],
            [85.01723477449414, 28.258229002392483],
            [85.01348874547553, 28.257474024111403],
            [85.00970353380009, 28.255796614772308],
            [85.00590639592717, 28.2556193295849],
            [85.00077614314273, 28.252370166453094],
            [85.00088348440582, 28.250496994788502],
            [85.00269068026576, 28.24807897508054],
            [84.99912998739534, 28.244473095195836],
            [84.99778143708039, 28.240471936210174],
            [84.99411508791614, 28.243968211621528],
            [84.99160439280801, 28.244706075233502],
            [84.98960247516146, 28.242886522038987],
            [84.98444343946474, 28.235486109608278],
            [84.98255977008326, 28.234509305081037],
            [84.9794821452121, 28.22942628177648],
            [84.97706196411943, 28.22672297548567],
            [84.97136610420311, 28.22397975379973],
            [84.96914818442828, 28.222274247563337],
            [84.96725369146903, 28.21900076375806],
            [84.96473287302129, 28.217364996304017],
            [84.962080985879, 28.21274778387828],
            [84.96416056178407, 28.208871007260754],
            [84.96692439185296, 28.205345809094307],
            [84.96753106869045, 28.201948626909676],
            [84.97062996494681, 28.19911580885611],
            [84.97113238018608, 28.193921412869866],
            [84.97349824796453, 28.191080190454947],
            [84.97335261323505, 28.189127602549814],
            [84.96797610290301, 28.18528102003828],
            [84.9640761572191, 28.17747366463291],
            [84.96537761531064, 28.173701685242143],
            [84.96354571148505, 28.172189572452865],
            [84.96190980794799, 28.16895933876316],
            [84.96059153515239, 28.162018052180674],
            [84.96092499907208, 28.15995376391365],
            [84.95724451641867, 28.155548916118104],
            [84.9571662143709, 28.149462966261936],
            [84.95792314662037, 28.147862685959108],
            [84.95419966877061, 28.143496396449233],
            [84.95702311181066, 28.139163337747892],
            [84.95848377235039, 28.133783994509837],
            [84.95691722710419, 28.13204433064435],
            [84.95716385877662, 28.127223918250976],
            [84.95183094179339, 28.12421554224296],
            [84.94842019127377, 28.124902411640658],
            [84.9449908541216, 28.124059821116816],
            [84.94247653121027, 28.12257691492122],
            [84.94093123532899, 28.124969809802284],
            [84.93543899044715, 28.12662716966938],
            [84.93149868106926, 28.12547185091688],
            [84.92881695155054, 28.126320269142],
            [84.92536751499172, 28.12149554990964],
            [84.9207677387204, 28.11600847291591],
            [84.92049631247512, 28.114167135328245],
            [84.9215821433986, 28.10836203662725],
            [84.92127906564357, 28.10587114926363],
            [84.91629641668374, 28.10275078924566],
            [84.91289105060689, 28.1030532071292],
            [84.90898884237176, 28.10468990047142],
            [84.90364045548934, 28.10523734771483],
            [84.90008684538796, 28.106609784960046],
            [84.89697838522478, 28.104543379070133],
            [84.89244943679229, 28.09997073523555],
            [84.88920549775489, 28.10341411650113],
            [84.88538165697592, 28.10551204270642],
            [84.8841073697081, 28.109513409803668],
            [84.88099557285254, 28.11276848377508],
            [84.87826722999422, 28.113690631084314],
            [84.87148344419658, 28.117663843912915],
            [84.86936810568973, 28.12047018071924],
            [84.86302061459148, 28.12157735362261],
            [84.85904223260259, 28.120072761279737],
            [84.85469238741115, 28.122660496993173],
            [84.8495973989168, 28.118538913857126],
            [84.84437694281226, 28.116712183290183],
            [84.84209783204543, 28.114118302842897],
            [84.84245863249568, 28.110716320883828],
            [84.84033637009973, 28.108967408336525],
            [84.83714965969035, 28.104143268995518],
            [84.83784686464735, 28.101203983720726],
            [84.83704171714739, 28.098016669465842],
            [84.83701693609662, 28.09449526595841],
            [84.83180959453087, 28.089568205113522],
            [84.83151116290877, 28.084512064935854],
            [84.82903739594185, 28.080540679230847],
            [84.82824816178588, 28.076055999687476],
            [84.82499910690962, 28.069359413183772],
            [84.8243964869554, 28.06671225242836],
            [84.82092512004557, 28.063150512696087],
            [84.8212494392692, 28.05906066098039],
            [84.82064730764526, 28.05637511667399],
            [84.81848602705007, 28.054321508793358],
            [84.81850145317783, 28.0480474407774],
            [84.8177028438686, 28.046851997573732],
            [84.81399274197248, 28.044816548750912],
            [84.8121301508874, 28.04295733525933],
            [84.8095006040131, 28.03798930812951],
            [84.80946805430021, 28.034659611414426],
            [84.81261633207365, 28.031523204009517],
            [84.81341421577693, 28.027401239077772],
            [84.81237674283406, 28.02509269589649],
            [84.81310757515774, 28.022462344498635],
            [84.81240775625783, 28.02050375673629],
            [84.81007893547162, 28.018365229583093],
            [84.8101553284344, 28.013884323841566],
            [84.81328350486714, 28.00933122320085],
            [84.81132914362077, 28.00294470750232],
            [84.80984186100592, 28.001965334947347],
            [84.80385212533855, 28.000428003734633],
            [84.80153018214108, 28.000511521735856],
            [84.79741600373576, 28.002369600651775],
            [84.79690644962004, 27.998144719801534],
            [84.7907656973801, 27.998238830101574],
            [84.78806845102115, 27.99529215497967],
            [84.78778377739219, 27.98908513447394],
            [84.78487136071057, 27.986741940341894],
            [84.78406622457187, 27.983299301535215],
            [84.77882683044292, 27.981571624648012],
            [84.77728436003633, 27.977864623049257],
            [84.77536394329867, 27.977088100321648],
            [84.77163024644243, 27.977355016921294],
            [84.77146171720645, 27.97598878782681],
            [84.77515965791083, 27.97312311875793],
            [84.77472101838357, 27.971392917854836],
            [84.77224149800787, 27.970138991951185],
            [84.76915046579852, 27.967297887437713],
            [84.76390976321377, 27.967586509970797],
            [84.76071124871245, 27.967222956248175],
            [84.75823553856914, 27.964299173167472],
            [84.75450098371661, 27.96237728792304],
            [84.74654522845205, 27.960597760326678],
            [84.74584908562883, 27.959866065497053],
            [84.74653287517206, 27.955844312092758],
            [84.74556887491164, 27.953101647024894],
            [84.74206486615945, 27.949140850793327],
            [84.7398123954075, 27.94370007333701],
            [84.73954863039194, 27.937052841803006],
            [84.73793467563277, 27.935335601455197],
            [84.73443930447901, 27.933932959798607],
            [84.7342048950332, 27.931197599339384],
            [84.73641210988959, 27.93079773289364],
            [84.73643308650496, 27.92854833456438],
            [84.73310916646325, 27.927580161092404],
            [84.73423198886451, 27.91697513583858],
            [84.73509914572921, 27.91544311729969],
            [84.73450938124446, 27.911146167231763],
            [84.73322612553538, 27.90745529314049],
            [84.73400796371757, 27.904107608126424],
            [84.73641465527604, 27.900428603706615],
            [84.7376265492123, 27.897047023745447],
            [84.7357188793102, 27.893391074484775],
            [84.73667936650763, 27.891238126093405],
            [84.741502359597, 27.889980462224823],
            [84.74343863585268, 27.88851259959784],
            [84.74883828456555, 27.886500246318622],
            [84.74846570003146, 27.883826544549013],
            [84.7459192914619, 27.880532926955862],
            [84.74695586800637, 27.878591607081027],
            [84.75253908356667, 27.875113942352552],
            [84.7550929544265, 27.869498106870587],
            [84.75423286639288, 27.865360616586866],
            [84.75603326164428, 27.86177484394631],
            [84.75934468230557, 27.859392298964124],
            [84.76373293627431, 27.860092265937293],
            [84.76566085153141, 27.8580037175322],
            [84.76535010042315, 27.853786546885754],
            [84.76803893176107, 27.851729753399997],
            [84.76756034151073, 27.843791025283473],
            [84.76993215747255, 27.842870883786386],
            [84.77035793009584, 27.8395497649611],
            [84.77517559839427, 27.83667335078769],
            [84.7759579917375, 27.835344639415137],
            [84.77311642199614, 27.82947442301094],
            [84.77371791474309, 27.8242008025532],
            [84.77742122552591, 27.818532961456707],
            [84.78193599419248, 27.81577759396527],
            [84.78239165799366, 27.81393857965076],
            [84.77934833566732, 27.81273745250464],
            [84.77484575596387, 27.80853937578103],
            [84.77252086991503, 27.80888630288093],
            [84.76928351558577, 27.810903283502245],
            [84.7685463138606, 27.812421235553455],
            [84.76943703997404, 27.815137141948497],
            [84.76464360749962, 27.818561444696268],
            [84.76011998998403, 27.816939457255344],
            [84.75664738231717, 27.81410003381268],
            [84.75625878534008, 27.80901242443864],
            [84.75384795194591, 27.80646940677917],
            [84.75051281122764, 27.80519255367221],
            [84.74757531668263, 27.805655215625084],
            [84.74492941812723, 27.804415094506407],
            [84.73857360761306, 27.803934854135147],
            [84.73674845160743, 27.79881866221657],
            [84.7318683408073, 27.79568829787553],
            [84.72950996527345, 27.796133915635885],
            [84.72727611476321, 27.800037536377268],
            [84.72195817940833, 27.801170904660676],
            [84.71922124650348, 27.80348421527943],
            [84.71753191253691, 27.803262833698692],
            [84.71382658433946, 27.8004867760566],
            [84.7102250506714, 27.800766991218552],
            [84.70796088375562, 27.802565496193633],
            [84.70821984097655, 27.804937584290144],
            [84.70713315971923, 27.806784164800543],
            [84.69669663602106, 27.808470598544968],
            [84.69308534226732, 27.809458929071123],
            [84.68505951807018, 27.81034274675422],
            [84.67878896070356, 27.814103111154388],
            [84.67686505835955, 27.81586780667219],
            [84.67113354627048, 27.817014863143687],
            [84.6680331702976, 27.818541482997258],
            [84.66769658460782, 27.82232214234929],
            [84.6656961033793, 27.8253076537829],
            [84.62336874305566, 27.816773956258306],
            [84.64571729153545, 27.799795321317543],
            [84.63805187282605, 27.782943660580354],
            [84.67265044433434, 27.780351985090753],
            [84.68124220576652, 27.75948568451226],
            [84.67019674432412, 27.73820094812887],
            [84.69293074206135, 27.721391150023834],
            [84.75748822557733, 27.73568618761019],
            [84.79647683810232, 27.70923073518191],
            [84.79219159647569, 27.68696244247225],
            [84.80011658606045, 27.671442030784483],
            [84.82969954129149, 27.692448597634215],
            [84.87985174206315, 27.66878153477889],
            [84.90726210964515, 27.698586130207296],
            [84.95149977988055, 27.683489319760216],
            [84.99143949826754, 27.7079582780444],
            [85.01081730207065, 27.707741444028475],
            [85.04266793117279, 27.690212639446177],
            [85.07595233600095, 27.70903055408269],
            [85.10063341768564, 27.708308619855462],
            [85.12025277205852, 27.683581006812627],
            [85.15052946267811, 27.669911861384932],
            [85.18869324377265, 27.681417871373945],
            [85.19720839429164, 27.716341517372125],
            [85.21740275942261, 27.72222939048921],
            [85.2374783894389, 27.745303376985465],
            [85.26700372034784, 27.745570715179255],
            [85.27024206979613, 27.758383995564774],
            [85.27016056210805, 27.759663852873338],
            [85.26955771574447, 27.760817654604445],
            [85.26844774087944, 27.762634837083322],
            [85.26301157602428, 27.76717389709792],
            [85.26347843475193, 27.76785091989813],
            [85.26356639254047, 27.768384769867883],
            [85.26321835963326, 27.769267760982803],
            [85.25890305118944, 27.771635243903322],
            [85.2584237912573, 27.7715877217749],
            [85.25679971371345, 27.773441077773093],
            [85.25500478807442, 27.77306485605051],
            [85.25262844772834, 27.77567847282356],
            [85.12477927749308, 27.76067932888427],
            [85.08809085872198, 27.787702041372107],
            [85.04659288508172, 27.801660194496126],
            [85.04727952001394, 27.812941804810382],
            [85.01254988792164, 27.809489664174464],
            [85.02250986536149, 27.83346765609051],
            [84.99233197673212, 27.855036627194124],
            [84.98893952054841, 27.885717941214093],
            [85.01610207970074, 27.943682033886894],
            [85.00804967496312, 27.951194991335395],
            [85.0286850561429, 27.971950630638123],
            [85.02966342951927, 27.995500051400374],
            [85.00314840786895, 28.000228287379624],
            [84.9956100575243, 28.024999432181776],
            [85.04162524926566, 28.03703214831256],
            [85.06224506971655, 28.066966036805],
            [85.11447859906991, 28.07672148177629],
            [85.12248004913994, 28.091254135165368],
            [85.12603005121304, 28.10723053874458],
            [85.1496425751602, 28.117278529409614],
            [85.15444898587751, 28.139487710968055],
            [85.18265054327323, 28.16701889217404],
            [85.19401296227082, 28.167802327272767],
            [85.20438162540205, 28.204037021446045],
            [85.18859449862614, 28.219501113782698],
            [85.2004292419735, 28.256634361690836],
            [85.18061326543703, 28.28093508553251],
            [85.17728010456335, 28.305883674470056],
            [85.15984249276025, 28.331925009814647]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SINDHULI",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.2035805162654, 27.250270602687838],
            [86.15613219579235, 27.251989446791768],
            [86.15134490856543, 27.2422360075499],
            [86.11167615708126, 27.27261853188077],
            [86.09214463787113, 27.268371024843788],
            [86.0742166635436, 27.291576181796334],
            [86.05454268657309, 27.288624294199508],
            [85.99657011088748, 27.33790387516945],
            [85.98320038606117, 27.336033847016083],
            [85.97660797372312, 27.3716173293188],
            [85.9584159940955, 27.3889092581336],
            [85.94590392314512, 27.380287065585943],
            [85.92460539809194, 27.404971332775485],
            [85.87850807689384, 27.416707748417124],
            [85.82977386201166, 27.437873063895424],
            [85.82151660793107, 27.45103080746803],
            [85.78622641027201, 27.438197667532286],
            [85.77349426820763, 27.398217190732332],
            [85.72796061898188, 27.411916349450937],
            [85.71580147175038, 27.407518437068227],
            [85.69527992396957, 27.364413836661797],
            [85.70366474209422, 27.34488021182346],
            [85.67980077435625, 27.345211648000436],
            [85.6508414739152, 27.333566828360837],
            [85.63836799116537, 27.351863850551858],
            [85.61126677769127, 27.34295875248125],
            [85.55905928751673, 27.339364014905108],
            [85.53320647136775, 27.356725131677194],
            [85.52326356875854, 27.35002620586836],
            [85.48723058767521, 27.376474794055653],
            [85.47261191498829, 27.37013726079076],
            [85.44681888925986, 27.347894895269032],
            [85.44629881505307, 27.336688179630208],
            [85.41816208089699, 27.32116228528345],
            [85.43052196676102, 27.29220717962907],
            [85.47906452242361, 27.269496789707393],
            [85.48872288427607, 27.250160070894882],
            [85.4883305534676, 27.22019601815082],
            [85.50164014813653, 27.22323952400682],
            [85.50383491678167, 27.194059441092985],
            [85.48904027400951, 27.19874772530633],
            [85.48302880081908, 27.17554101294072],
            [85.49518496351685, 27.1736793436741],
            [85.49773769081199, 27.17403337185517],
            [85.50094271885024, 27.175782204900006],
            [85.50313948871002, 27.173653492830503],
            [85.50215661494748, 27.17035375032668],
            [85.49966780011772, 27.16843440156506],
            [85.50078469116079, 27.16656033645091],
            [85.50374070008083, 27.165454857563574],
            [85.50268281258273, 27.16255817999967],
            [85.49562531099019, 27.16323848652898],
            [85.49212900742404, 27.160778336716202],
            [85.4895200368713, 27.154613406066233],
            [85.49122363272504, 27.15408995107245],
            [85.49570431661458, 27.1465456218394],
            [85.5010364275342, 27.143551964486722],
            [85.50425538062521, 27.143838977690102],
            [85.50964924146047, 27.143424854625696],
            [85.51259582257283, 27.141978465264458],
            [85.51596240213888, 27.142663798881905],
            [85.52122845069886, 27.140864947401653],
            [85.52809472850699, 27.14040695755835],
            [85.53078917151652, 27.141008212622662],
            [85.53235282319521, 27.142585728782766],
            [85.53296916490902, 27.14610252016378],
            [85.53468058562586, 27.148128246431355],
            [85.53754166235528, 27.148609277756595],
            [85.54467486668835, 27.144712864755785],
            [85.54716664761882, 27.142451106286448],
            [85.54934333346776, 27.14234725658371],
            [85.54976692762033, 27.145719857438767],
            [85.55462732861845, 27.146733328652747],
            [85.55543256113654, 27.145593335290037],
            [85.5587143795675, 27.14400045378687],
            [85.56030999998875, 27.143807020078704],
            [85.5612531319565, 27.144471029281302],
            [85.56562095045766, 27.143626800050843],
            [85.5665198987882, 27.143435144146526],
            [85.56719168361077, 27.1435322713731],
            [85.56891945728893, 27.143504801771932],
            [85.56941544486513, 27.140721379686525],
            [85.57002671786508, 27.138288839166393],
            [85.57095631115322, 27.13712065722989],
            [85.57277149990276, 27.13801897717046],
            [85.57473721379928, 27.139403823277462],
            [85.57653918177357, 27.140981054737065],
            [85.57858067092877, 27.14346082121876],
            [85.58186250420876, 27.143681245576833],
            [85.58291175186253, 27.143166183390477],
            [85.58491164753246, 27.143434552931815],
            [85.58600700399121, 27.143488457019085],
            [85.5883549791303, 27.142945853649515],
            [85.5923778005432, 27.14111364431161],
            [85.59583738252161, 27.141489026648895],
            [85.59718975366754, 27.14292160168869],
            [85.5982140518899, 27.143213841107666],
            [85.59954705949599, 27.143572354269487],
            [85.60303419128579, 27.141766138449533],
            [85.6045796999092, 27.139737530405494],
            [85.60651497323595, 27.1396958120574],
            [85.60800857695804, 27.139715174071174],
            [85.60902557117736, 27.140187746509763],
            [85.61110449887894, 27.14133184703389],
            [85.6132783540731, 27.141477423525643],
            [85.61675748369188, 27.140679265701937],
            [85.61743759044676, 27.140205427921828],
            [85.61968030590764, 27.13887612051782],
            [85.62108734176718, 27.139832982243576],
            [85.62250710396546, 27.140677171683354],
            [85.62280456481352, 27.140682301054692],
            [85.6265857279472, 27.13825575437122],
            [85.62832483215445, 27.13744929538524],
            [85.63033692281348, 27.13757046470529],
            [85.63145335391573, 27.138246908043218],
            [85.6323366399073, 27.139692615494482],
            [85.63345845290237, 27.14135498169457],
            [85.63635962813325, 27.141859226921145],
            [85.64109337458316, 27.14253457962661],
            [85.64173821526241, 27.142152777116262],
            [85.64484305513984, 27.14335817098525],
            [85.64793360263845, 27.14566439111933],
            [85.65052514883702, 27.150625614833615],
            [85.65386929634673, 27.15296795311497],
            [85.65633304570228, 27.152623826011766],
            [85.65606559385785, 27.155080373431932],
            [85.66244522635121, 27.1561701157054],
            [85.66518823551264, 27.159916529258464],
            [85.66559351977223, 27.16176130927609],
            [85.66961600788083, 27.16403979711689],
            [85.67107726658888, 27.16646991726714],
            [85.67342118479003, 27.167191495056457],
            [85.67753390435034, 27.167191958235275],
            [85.68171774860792, 27.16935433408987],
            [85.68566168771319, 27.174539749660713],
            [85.68599051917046, 27.176455969299596],
            [85.69012897299491, 27.17776703545235],
            [85.6937381197191, 27.181051802148662],
            [85.69489181855452, 27.184099236287715],
            [85.69835926345912, 27.186064934827026],
            [85.70272392466602, 27.18487354636778],
            [85.7050093725747, 27.184975887084583],
            [85.71455105648702, 27.187800351692175],
            [85.71782267576188, 27.1882341503338],
            [85.72085109211683, 27.19105260615262],
            [85.72228326824505, 27.194420483690262],
            [85.72552004017865, 27.194887653107177],
            [85.72961335277476, 27.19224453893328],
            [85.73331297735281, 27.190730275377383],
            [85.73777164723606, 27.190481745436564],
            [85.74168462147715, 27.188023965566206],
            [85.7446577976392, 27.187522658214576],
            [85.74754888104695, 27.18835394581044],
            [85.74879465346716, 27.18667526439898],
            [85.75398584151104, 27.185934084923904],
            [85.75908865602514, 27.18467529833153],
            [85.7631429555545, 27.182522686141834],
            [85.76842235303938, 27.18239090438633],
            [85.7738495983028, 27.178370663277864],
            [85.78343440173829, 27.17539483497596],
            [85.78859679652672, 27.173483455777404],
            [85.79005084342415, 27.1711360864407],
            [85.79320494341793, 27.168762709753935],
            [85.79628056304053, 27.167403880165967],
            [85.79849190294752, 27.167393480690613],
            [85.80569737542852, 27.164041487530948],
            [85.80925114318964, 27.16163054129654],
            [85.81263609358022, 27.162108182553666],
            [85.8158621243843, 27.161307431681035],
            [85.82002845816397, 27.163790491074035],
            [85.8225853064209, 27.167087911236255],
            [85.82533710968956, 27.16843308854857],
            [85.8306884342648, 27.167175966295776],
            [85.83529075330094, 27.164071448148388],
            [85.8434866526206, 27.160032998891914],
            [85.84060711558331, 27.155880507954706],
            [85.83975609712292, 27.152595232737596],
            [85.83764320871572, 27.15186028668656],
            [85.83458253928399, 27.149373424500755],
            [85.83557057842653, 27.147245067304265],
            [85.83834493572493, 27.144763678464816],
            [85.84121677335652, 27.144309108386384],
            [85.84554002573587, 27.140937916378],
            [85.848940345462, 27.140180664531925],
            [85.85367008410057, 27.13792937973884],
            [85.85481413683627, 27.134146047034488],
            [85.85801867913402, 27.132933519903517],
            [85.85923482275821, 27.1303510817185],
            [85.86246794914872, 27.127171246747917],
            [85.86379095333047, 27.127721186562987],
            [85.8651241898954, 27.129660989857296],
            [85.86595999608397, 27.13051834336895],
            [85.86739738418412, 27.132172449570543],
            [85.86759087154788, 27.132458282057573],
            [85.8683011924384, 27.13360112976633],
            [85.86973651354155, 27.13496866615471],
            [85.87168454892908, 27.13660427837894],
            [85.8738118175999, 27.137975082071012],
            [85.87419661110204, 27.13880392348077],
            [85.87587736119552, 27.14063813651706],
            [85.88089677404957, 27.143552640955775],
            [85.88276778374302, 27.146347173616913],
            [85.88495813722163, 27.145588464580992],
            [85.88696117640998, 27.145532147969945],
            [85.88827509426616, 27.144008350178044],
            [85.88886897041047, 27.14321661639122],
            [85.89087525783297, 27.14132599926601],
            [85.89305433812993, 27.141119836414852],
            [85.89409392038402, 27.1404535085992],
            [85.89806977475885, 27.13739372080444],
            [85.90143574302526, 27.135721174786365],
            [85.9043378210212, 27.134708250061266],
            [85.90559508078336, 27.133851667088912],
            [85.912071296199, 27.132221098392915],
            [85.91391964736185, 27.129848366457434],
            [85.9197628577891, 27.128844275027472],
            [85.9213553013763, 27.12733360104653],
            [85.92997002963823, 27.125355378144157],
            [85.93812904134656, 27.121437360558986],
            [85.93821789305358, 27.118971955803616],
            [85.9361700316949, 27.112821790409583],
            [85.93589823940222, 27.11020251214703],
            [85.9370199281453, 27.10669352548985],
            [85.94079501075949, 27.09859738013829],
            [85.94321183477298, 27.094822850768473],
            [85.9441296103625, 27.092081626553398],
            [85.94637647050014, 27.09065229041204],
            [85.94807864960227, 27.088223774796244],
            [85.95048345468803, 27.088189857554372],
            [85.951072503136, 27.086515609809386],
            [85.9502228551804, 27.08393038756138],
            [85.9533757628013, 27.083538724333636],
            [85.95560345957448, 27.08424572929179],
            [85.96218763006593, 27.079470668056196],
            [85.96179273856647, 27.076715093376176],
            [85.96321487245876, 27.07323067391443],
            [85.96533539206031, 27.07385999243079],
            [85.96678396965568, 27.06987933891317],
            [85.96613480641155, 27.067047486525045],
            [85.96781143156474, 27.065496249912503],
            [85.9696907147842, 27.061040363444103],
            [85.96943879344258, 27.05882286835186],
            [85.97062013817039, 27.055817154278138],
            [85.96981040808534, 27.053904703645806],
            [85.97165649984076, 27.04987501055622],
            [85.97392954215613, 27.047754913683733],
            [85.97486809929792, 27.04523474296471],
            [85.97758030509281, 27.04173019348305],
            [85.98194921502098, 27.03726061686694],
            [85.9825720778437, 27.036714591815777],
            [85.98364337418953, 27.034285562958353],
            [85.9857600593176, 27.03320428311996],
            [85.98597815930117, 27.032768139562602],
            [85.98604746199007, 27.03231061608263],
            [85.98702227322957, 27.029179162371555],
            [85.98717389929075, 27.028952370664115],
            [85.9881443576204, 27.027842422516628],
            [85.98898595546461, 27.02684677461423],
            [85.98982273401332, 27.026211969664246],
            [85.99413649062339, 27.024559687112262],
            [85.99580262478723, 27.023860839167405],
            [85.99635987203499, 27.02348800639492],
            [85.9983186666538, 27.023170306096745],
            [85.99857525300845, 27.023000654255004],
            [85.99981901496442, 27.021879081034974],
            [86.00289056488492, 27.02100062979571],
            [86.0052440874098, 27.022050653567177],
            [86.01246448829589, 27.018692259695698],
            [86.01530433629175, 27.02016742597819],
            [86.02160963668955, 27.01934722388466],
            [86.02530224521738, 27.02040395739131],
            [86.02651323563751, 27.020601885322442],
            [86.0268544428973, 27.02050946165071],
            [86.02753742188996, 27.020225373105525],
            [86.03034088834266, 27.02079749677015],
            [86.03515298185756, 27.020289043308374],
            [86.03566504851388, 27.020100768597388],
            [86.03685713101113, 27.019973547830467],
            [86.03890081894235, 27.01979572415698],
            [86.04111546800164, 27.019731891463746],
            [86.04545619172401, 27.02006820527015],
            [86.04568917200733, 27.020220986367804],
            [86.04678825751738, 27.021223353306336],
            [86.04753215649748, 27.021402224785014],
            [86.04821457028832, 27.021253374265754],
            [86.0483432424907, 27.02115723267827],
            [86.04862325547437, 27.020739453501722],
            [86.0486251940038, 27.020509324180036],
            [86.04861311971912, 27.019360798448815],
            [86.05057562219585, 27.018913745531247],
            [86.05142851324317, 27.01878639593167],
            [86.05654239521441, 27.018283731035947],
            [86.05866957089302, 27.018546162144617],
            [86.06164905258012, 27.01871719636815],
            [86.06318245545668, 27.018630408218748],
            [86.06564852097027, 27.01896944495884],
            [86.06896720463556, 27.019176444160912],
            [86.06990543852864, 27.01897055177233],
            [86.07071455002351, 27.018917203917376],
            [86.07352531021922, 27.018777887854178],
            [86.07412167042126, 27.01874340079602],
            [86.07488692322097, 27.018861220299062],
            [86.07652626286323, 27.01879301018715],
            [86.0794449798459, 27.01846463875827],
            [86.08080705195998, 27.018450975061057],
            [86.08310212602159, 27.018486260010523],
            [86.08365183256068, 27.018853098835695],
            [86.0857746968548, 27.019117348351227],
            [86.08849440647927, 27.019173351159147],
            [86.09104902130478, 27.018619005129167],
            [86.09317867691063, 27.018079936812985],
            [86.09870953995878, 27.017429525318406],
            [86.10322814207757, 27.01599853482864],
            [86.10589058190567, 27.015408513959986],
            [86.10819449045147, 27.014434847675506],
            [86.10981872449025, 27.013400452280244],
            [86.10988323009764, 27.013324161529816],
            [86.11184718138618, 27.012327969822202],
            [86.1155971815186, 27.01129109287501],
            [86.11619544892821, 27.01093157988203],
            [86.12125265427443, 27.00856927124157],
            [86.12210517320074, 27.00830830850356],
            [86.12309397162588, 27.007894810976513],
            [86.1239255103336, 27.007696892863876],
            [86.12577931780615, 27.007074404868774],
            [86.12609867937472, 27.006938746074578],
            [86.12718192998287, 27.006106087760717],
            [86.12845918811456, 27.005448402199043],
            [86.13101339876285, 27.004225455442008],
            [86.1337615038683, 27.003249574902433],
            [86.13510220286058, 27.00255159901237],
            [86.13884650049691, 27.000496451128132],
            [86.14222513511473, 26.9991858232487],
            [86.14371367977944, 26.99832392620424],
            [86.14476360309031, 26.997800068560142],
            [86.1461716737746, 26.997585231192883],
            [86.14862181064778, 26.99679680061707],
            [86.14870521615643, 26.996758946773397],
            [86.15236293694564, 26.994687141822773],
            [86.16098242191926, 26.992313120274552],
            [86.16308675632835, 26.991894681028523],
            [86.16719134175425, 26.988809748575036],
            [86.16853212640908, 26.989115528785398],
            [86.17011260741312, 26.989111305479483],
            [86.17216448513318, 26.98810349128175],
            [86.17425267189535, 26.986908581804084],
            [86.17638858039582, 26.98667288166168],
            [86.17841124050223, 26.984642713880827],
            [86.18101559166392, 26.984448024486614],
            [86.18178073683585, 26.984168145772813],
            [86.18383187556529, 26.983148873790384],
            [86.18768426532768, 26.982270828128957],
            [86.18813312528042, 26.980170521315205],
            [86.18795402332576, 26.97960087477833],
            [86.18751130787994, 26.97733519320861],
            [86.18851781713353, 26.97753957284138],
            [86.19152109048505, 26.978583468101377],
            [86.1919402300494, 26.978254191699804],
            [86.1923923251513, 26.975472409683576],
            [86.19581883449798, 26.972894917650923],
            [86.19808132598067, 26.97110723839825],
            [86.19984573860265, 26.97007706839003],
            [86.20343079097849, 26.968908262531453],
            [86.20631789429211, 26.967157787333196],
            [86.2073433889799, 26.96698082007608],
            [86.20840973843737, 26.966501679496346],
            [86.21369768341128, 26.962900761259856],
            [86.21628154641981, 26.960351930024594],
            [86.2190158305261, 26.95655831088401],
            [86.22482547723705, 26.95207536643443],
            [86.22558134860138, 26.95020669295191],
            [86.22871506087068, 26.949235566211648],
            [86.23258530456033, 26.95052024972528],
            [86.23515890584339, 26.949248087858166],
            [86.23528176087488, 26.947039751868807],
            [86.23717040186, 26.94427463664059],
            [86.2397816977581, 26.942282785758138],
            [86.24085081438915, 26.9403909161553],
            [86.24364315884301, 26.93934992972232],
            [86.24452550206658, 26.938321222989476],
            [86.2476681244568, 26.936740487343716],
            [86.24917756634373, 26.93608968509584],
            [86.25087278576375, 26.935615801137704],
            [86.25429990317912, 26.932806685591313],
            [86.25524284722746, 26.932581533723802],
            [86.25792664660463, 26.92837393121306],
            [86.25720767770764, 26.927551076286527],
            [86.25715466119384, 26.926515101392784],
            [86.25727621877522, 26.924676783565268],
            [86.25687986536077, 26.923747316908926],
            [86.25682151583553, 26.92357329310974],
            [86.25724618947969, 26.921571789439156],
            [86.25745571568126, 26.92100205080622],
            [86.25855799283458, 26.91978484412907],
            [86.25968448408882, 26.91914316959652],
            [86.25947143517321, 26.9198280430665],
            [86.26012606479055, 26.92337175552136],
            [86.26111758199615, 26.925006071057457],
            [86.26166871270902, 26.925749056928797],
            [86.26450635334328, 26.92821882373821],
            [86.26776982753529, 26.9300070953521],
            [86.27332149608615, 26.932125188602974],
            [86.27814325554816, 26.934453751097756],
            [86.2789109848063, 26.935757349718646],
            [86.27971380894981, 26.938669953433674],
            [86.27956881679108, 26.940582671197273],
            [86.28042467980347, 26.941794203519613],
            [86.28121408953044, 26.94717974108921],
            [86.2834711278585, 26.94919266357597],
            [86.29275355406438, 26.950712910083716],
            [86.296961220196, 26.952579681883],
            [86.29879744486544, 26.953890766930176],
            [86.30221854453214, 26.9556812794585],
            [86.30489982730025, 26.95795316683595],
            [86.30735628496528, 26.959930552772256],
            [86.3097167961, 26.961559996003174],
            [86.31236830965206, 26.962755305957526],
            [86.3140187566854, 26.963198830397804],
            [86.31605400984847, 26.963612623422794],
            [86.31697050067359, 26.963635106350257],
            [86.3179379559726, 26.9633916120475],
            [86.32246347730481, 26.964002321542683],
            [86.32304071277802, 26.96395771095386],
            [86.32438872974046, 26.96381980747157],
            [86.32597899222505, 26.964127478267788],
            [86.32975489978767, 26.964025879345066],
            [86.33058897733751, 26.963930566409633],
            [86.33260674409718, 26.96476597650295],
            [86.33337448854957, 26.964708685051175],
            [86.33376374125915, 26.964509716423343],
            [86.33479365392333, 26.96424831013641],
            [86.33543159106378, 26.964264856545725],
            [86.33633103788345, 26.96407729774438],
            [86.33799748538591, 26.96379631034034],
            [86.33901481842213, 26.963900358423906],
            [86.34053311178647, 26.964302348427548],
            [86.34198976015878, 26.964647612012786],
            [86.34308202758062, 26.964370572722576],
            [86.34366288861821, 26.964136434693092],
            [86.34612026404479, 26.963703337632637],
            [86.35298045866082, 26.96461047038277],
            [86.35509602707155, 26.967095471514007],
            [86.35541840167721, 26.967697129298777],
            [86.35675401565838, 26.96924212233164],
            [86.35814238568243, 26.971879409802863],
            [86.36156267843013, 26.97481925273047],
            [86.3637485452128, 26.977803088606095],
            [86.36515105899062, 26.979982338186442],
            [86.36571932868162, 26.981605001027663],
            [86.3662361657308, 26.983529770081944],
            [86.36646770171095, 26.985755652607224],
            [86.36668589655014, 26.990583092177932],
            [86.36781092317435, 26.994227726691058],
            [86.36815549299743, 26.996914409359295],
            [86.36734363245705, 26.999074665664565],
            [86.36707715974629, 26.999432254587344],
            [86.36678462476937, 26.99994082973284],
            [86.36711247986923, 27.000549313809678],
            [86.36825058868781, 27.001195241615687],
            [86.36960340659813, 27.001731531522683],
            [86.37101395431856, 27.002593041680008],
            [86.3731816476021, 27.00371960368285],
            [86.37374656856029, 27.005818339289004],
            [86.37405986667626, 27.008658307176844],
            [86.37220899413764, 27.010804921661652],
            [86.3740071433914, 27.013405534661512],
            [86.37199148966671, 27.015659716221858],
            [86.3740451913403, 27.01966722450871],
            [86.37544104854113, 27.020842239264592],
            [86.37409255055583, 27.022941474862108],
            [86.37773235567805, 27.027989411030205],
            [86.37949247837301, 27.02905540902122],
            [86.37937037196293, 27.031446651706943],
            [86.38228247905867, 27.032364277063163],
            [86.37916446146869, 27.034640806061873],
            [86.37522685224849, 27.03479489232622],
            [86.37094890901554, 27.03712698315203],
            [86.3667927637868, 27.037760445493426],
            [86.36411902086944, 27.04227248153046],
            [86.36423257677848, 27.049849938597713],
            [86.36349104358236, 27.05370275663381],
            [86.36090052148617, 27.054837258484447],
            [86.35604658300292, 27.059266975580584],
            [86.35116161464187, 27.061631783554414],
            [86.35034311853539, 27.06376253667847],
            [86.34688961516001, 27.06457690642485],
            [86.34577106559829, 27.068292473134743],
            [86.34692955084002, 27.07035564603252],
            [86.35122041349551, 27.07035522866759],
            [86.35148612199447, 27.073840305552036],
            [86.3543900264061, 27.075676801265075],
            [86.35538243435585, 27.081696883168622],
            [86.35827934217679, 27.08402969060321],
            [86.3578712649035, 27.090226073093227],
            [86.35878283521004, 27.091845824734673],
            [86.35702772263281, 27.094195719252436],
            [86.35936681722674, 27.09554668723758],
            [86.36243979459644, 27.09903324612044],
            [86.36000564602574, 27.099861529379147],
            [86.35786882558017, 27.099220027375228],
            [86.35583092854638, 27.10057126359838],
            [86.35616335040329, 27.10256516373054],
            [86.35490608206044, 27.10429003444147],
            [86.34972861568843, 27.104949849756952],
            [86.34813279725421, 27.106228585783523],
            [86.34405524500026, 27.105677097771604],
            [86.34040452043533, 27.106354976157547],
            [86.33406581999692, 27.100891749501407],
            [86.33193351525209, 27.099830160610335],
            [86.32783200526646, 27.100550691835203],
            [86.3249353808632, 27.09919878596243],
            [86.3176679607955, 27.101936733647282],
            [86.31119438593558, 27.102976942627077],
            [86.30643057843523, 27.10889901221564],
            [86.3074416361708, 27.109617006534037],
            [86.31189618056612, 27.10953058925408],
            [86.31323747670308, 27.110884179550922],
            [86.32450308596991, 27.10995051464734],
            [86.32902861829292, 27.115931249144147],
            [86.33073386780731, 27.117191682475465],
            [86.33215334200412, 27.121517119949697],
            [86.33519317900802, 27.127759085177033],
            [86.3343372399769, 27.130595853149266],
            [86.33766777362175, 27.135020455194375],
            [86.33643458768162, 27.136664038850352],
            [86.34102261525989, 27.14114871197871],
            [86.34118952716423, 27.14494242843389],
            [86.34367215759286, 27.147341281679296],
            [86.34483858394316, 27.143996031718434],
            [86.34859718939943, 27.143853333810643],
            [86.35030820218931, 27.14275564800472],
            [86.35556535674415, 27.143292073653953],
            [86.36030721073148, 27.139378668878035],
            [86.36352648635018, 27.142130267322962],
            [86.3681839338186, 27.140892255858706],
            [86.3686290124905, 27.143710198735448],
            [86.37110275581135, 27.14734727706293],
            [86.37422239805494, 27.14843301107236],
            [86.3704605002681, 27.151065137022673],
            [86.37372248316065, 27.154969696031067],
            [86.37364049862647, 27.15870134829681],
            [86.37520031962652, 27.1608586150258],
            [86.37739276500598, 27.161705452193342],
            [86.37909865019971, 27.16398743498708],
            [86.3781392107829, 27.165558123705704],
            [86.38093453312227, 27.16849022395332],
            [86.38118727802117, 27.17012039638448],
            [86.38487277705732, 27.173965680864846],
            [86.38428188913328, 27.175271742683876],
            [86.38986266424817, 27.179939727182987],
            [86.38647703417227, 27.180592838745834],
            [86.38372142152059, 27.183094352888954],
            [86.38005592678311, 27.192189324879365],
            [86.37667861033134, 27.194044888455622],
            [86.37129846379601, 27.1926173931001],
            [86.36873928625613, 27.192553998077628],
            [86.3651791940226, 27.196188691019536],
            [86.35878754992376, 27.201150643035806],
            [86.3552668592885, 27.20156769921836],
            [86.35132225851902, 27.200825215538373],
            [86.34493139679626, 27.202108684188836],
            [86.34265050561335, 27.204365624437944],
            [86.34128017540638, 27.208935031558784],
            [86.33999906671494, 27.21017677480706],
            [86.34015252457428, 27.214166673781016],
            [86.3430757118588, 27.219105990673324],
            [86.34306946435048, 27.22146157108365],
            [86.34145768795914, 27.22493549135564],
            [86.34098435067776, 27.229023995892188],
            [86.33861970486934, 27.229861256587544],
            [86.33166020370444, 27.231125607037853],
            [86.32828486338782, 27.230168554232442],
            [86.3190172683509, 27.231739351427784],
            [86.31588220971177, 27.231439793997122],
            [86.31162699426189, 27.2324974406889],
            [86.30830314841745, 27.233956692538634],
            [86.30611467840765, 27.23721305423248],
            [86.29557391146453, 27.238241159728823],
            [86.29427544153897, 27.238945325315626],
            [86.29125259864935, 27.243185516725998],
            [86.28788644761134, 27.245693243748395],
            [86.28531050118112, 27.246449523724166],
            [86.28176480990838, 27.245772508216962],
            [86.27924127729513, 27.247106585958846],
            [86.27712182402236, 27.250204849035697],
            [86.27238262712406, 27.252199686033798],
            [86.26863832081943, 27.252640489581328],
            [86.26630633682723, 27.25450097260937],
            [86.2670781556737, 27.25995398966179],
            [86.26462373915508, 27.2606698968016],
            [86.26157561332901, 27.257495037676915],
            [86.25896251932082, 27.258595838408105],
            [86.25705815760432, 27.257802809228508],
            [86.25559276383927, 27.253363642575795],
            [86.24836750538472, 27.248228083623108],
            [86.24414625782892, 27.24752847022501],
            [86.2419309348337, 27.24619430506706],
            [86.23903450700446, 27.240946277462037],
            [86.22916430097146, 27.237397266840365],
            [86.22351144378129, 27.239058256930015],
            [86.21841594000242, 27.24176223259038],
            [86.2139479534488, 27.245164605410164],
            [86.21112428640107, 27.24581203375686],
            [86.20725624554642, 27.247728334471077],
            [86.2035805162654, 27.250270602687838]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "RAMECHHAP",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.5424263271506, 27.811170794021326],
            [86.48667223186072, 27.808890088851744],
            [86.44998277165556, 27.832526569388797],
            [86.41972570926895, 27.813538971397456],
            [86.42219309841826, 27.78649564827949],
            [86.41054176714657, 27.74728830991328],
            [86.42753385785022, 27.73382482492943],
            [86.37577489735871, 27.69238732974803],
            [86.3519024095889, 27.69396530443785],
            [86.31169916777698, 27.646037359040264],
            [86.30263505743575, 27.61545229212239],
            [86.2888383674019, 27.603419936858323],
            [86.20282641728417, 27.580422450659267],
            [86.15243637520992, 27.531717989627264],
            [86.14199410626057, 27.496229707643927],
            [86.11516122188988, 27.47801903418869],
            [86.09860417013127, 27.498432681748795],
            [86.00064648325966, 27.502381929068218],
            [85.98778039152687, 27.519618182379656],
            [86.00059156232405, 27.54123872964121],
            [85.95225364351201, 27.589075096600382],
            [85.88553476510985, 27.6121403097682],
            [85.88099126910087, 27.614567342877756],
            [85.84675095904453, 27.57926882386392],
            [85.82325061655261, 27.567148554938],
            [85.8285684623848, 27.546728988375296],
            [85.81890116051149, 27.51786008941079],
            [85.79751664110319, 27.500287296533024],
            [85.82151660793107, 27.45103080746803],
            [85.82977386201166, 27.437873063895424],
            [85.87850807689384, 27.416707748417124],
            [85.92460539809194, 27.404971332775485],
            [85.94590392314512, 27.380287065585943],
            [85.9584159940955, 27.3889092581336],
            [85.97660797372312, 27.3716173293188],
            [85.98320038606117, 27.336033847016083],
            [85.99657011088748, 27.33790387516945],
            [86.05454268657309, 27.288624294199508],
            [86.0742166635436, 27.291576181796334],
            [86.09214463787113, 27.268371024843788],
            [86.11167615708126, 27.27261853188077],
            [86.15134490856543, 27.2422360075499],
            [86.15613219579235, 27.251989446791768],
            [86.2035805162654, 27.250270602687838],
            [86.20512206158902, 27.250401179549556],
            [86.20428127402838, 27.253968125361965],
            [86.20840555960775, 27.25953293218506],
            [86.21113423915652, 27.26094721971638],
            [86.21137137624399, 27.263841117863926],
            [86.21420032916038, 27.267266272937583],
            [86.21585347420843, 27.267900556992895],
            [86.21884027346469, 27.27071729478947],
            [86.21942586877, 27.27305805082877],
            [86.22305208284736, 27.27433273234704],
            [86.22344096713995, 27.277055955916083],
            [86.22686225049316, 27.27773148111721],
            [86.22897226674412, 27.280802640544017],
            [86.23155748510288, 27.281852497317583],
            [86.23347515647141, 27.285015040868416],
            [86.23556434361358, 27.286517874431127],
            [86.24016353767604, 27.288338933012817],
            [86.24018319390501, 27.2900425017339],
            [86.23794945920594, 27.29133453678678],
            [86.23580802835173, 27.295417950535406],
            [86.23543433646746, 27.297938470403448],
            [86.2378902305179, 27.302504918876153],
            [86.23647126361602, 27.306770641067956],
            [86.2336877830212, 27.307748183285383],
            [86.23312068790294, 27.310418760447618],
            [86.23143048761213, 27.312537135876784],
            [86.23142825082947, 27.31437372700318],
            [86.22880528803165, 27.31625454548099],
            [86.22841944409119, 27.32017832086994],
            [86.22117024787403, 27.32120758106682],
            [86.21897682577791, 27.323117676453936],
            [86.22193793610505, 27.32658176072135],
            [86.22196658618944, 27.32777097352255],
            [86.21710386296809, 27.32857637353577],
            [86.2157007202864, 27.33388650738876],
            [86.2178853545129, 27.336416738555624],
            [86.21702763349016, 27.33879453009316],
            [86.21788109811094, 27.340487003120984],
            [86.21662351963677, 27.34246094915757],
            [86.21841462166203, 27.35077851350582],
            [86.21712907107664, 27.35285157814614],
            [86.21996081240377, 27.35643907401621],
            [86.22184964395024, 27.360380003016957],
            [86.21947351960947, 27.361025565247086],
            [86.21926087141786, 27.362610520358693],
            [86.2220748618428, 27.3675561215552],
            [86.22472281475589, 27.370587472076533],
            [86.22376647840723, 27.375169098361376],
            [86.22381115080772, 27.378546986290328],
            [86.22210754028974, 27.38249716872468],
            [86.22562442055832, 27.38401714938446],
            [86.2249219463748, 27.387072717026076],
            [86.22692574662594, 27.388703817153882],
            [86.22750199276743, 27.39130394570684],
            [86.22658597812826, 27.393079053572308],
            [86.2277157899502, 27.397900143960705],
            [86.23059499777447, 27.399378082189667],
            [86.23238545715762, 27.40238426178193],
            [86.2354566511766, 27.403709695954753],
            [86.23609523349934, 27.406524501615337],
            [86.23444938234526, 27.40786468433173],
            [86.23758529752082, 27.410144909686313],
            [86.2375607324978, 27.412714621125662],
            [86.24031167022021, 27.415588291891574],
            [86.24122983523442, 27.41821056656651],
            [86.2447132783325, 27.419971281547557],
            [86.24742448662138, 27.419435399434974],
            [86.24846644015405, 27.421539268264866],
            [86.24796317411652, 27.424499052717056],
            [86.25198732851732, 27.428566140448847],
            [86.25126019862667, 27.432526448655896],
            [86.25585203284719, 27.435621781578824],
            [86.25589552379844, 27.43959976047225],
            [86.25702805584767, 27.445503594151706],
            [86.25975093668738, 27.448990455168307],
            [86.26177326678636, 27.453301543429763],
            [86.26410064408188, 27.45645455242749],
            [86.26761403675121, 27.45923244300256],
            [86.274778978123, 27.462448969307857],
            [86.27676031853595, 27.464158207287593],
            [86.28513741948768, 27.4664983291883],
            [86.28918090882726, 27.468493220545447],
            [86.29215964183695, 27.470631516885284],
            [86.29193867847974, 27.47304682327495],
            [86.29294930538704, 27.476501759546],
            [86.29539248814696, 27.47926449281389],
            [86.29728473360105, 27.479558311429017],
            [86.30298703614199, 27.48254041278033],
            [86.30539341157768, 27.482857031151173],
            [86.30645153659829, 27.48512528482755],
            [86.3122998258993, 27.489939810587],
            [86.31291296778696, 27.49168239731077],
            [86.31554861667671, 27.492451223544915],
            [86.3187302087422, 27.498141273444904],
            [86.32022024026271, 27.499211266157598],
            [86.32399178622198, 27.499376273300847],
            [86.32501658770569, 27.500295001650187],
            [86.32603542249127, 27.504591324630645],
            [86.33080185392986, 27.509936793280893],
            [86.3332099569757, 27.510871156943335],
            [86.33441849858986, 27.515718825477542],
            [86.33827683144142, 27.520082742804057],
            [86.34138003864682, 27.52188892171629],
            [86.34653688929606, 27.523524176919576],
            [86.35494549376064, 27.52486079434453],
            [86.35560319789273, 27.52626947915657],
            [86.35765302078524, 27.52961593686731],
            [86.35743799594184, 27.533405421418596],
            [86.3585360892324, 27.539784372259742],
            [86.36482330269902, 27.546687990338093],
            [86.36896062653906, 27.548868280985168],
            [86.37066691985271, 27.55194900672051],
            [86.3687470770548, 27.556443751371965],
            [86.37163951316127, 27.562079361603786],
            [86.37515051907378, 27.565565287056646],
            [86.37629331253211, 27.568079348742277],
            [86.38182177692262, 27.571813300828637],
            [86.38774352333361, 27.572444197965734],
            [86.38974997132983, 27.57548541621596],
            [86.39272598328616, 27.577100386042762],
            [86.398241562158, 27.58331998941228],
            [86.40106844504972, 27.58314042905244],
            [86.4026239045071, 27.58423462516256],
            [86.40781315564615, 27.58448923750466],
            [86.41092544802427, 27.586756464142898],
            [86.41345424004497, 27.58680775312539],
            [86.41795991435569, 27.591393345095593],
            [86.41987933562412, 27.598264843240873],
            [86.42467504205996, 27.602007567847252],
            [86.43022502393997, 27.604248334605817],
            [86.43479109292218, 27.610288880238226],
            [86.44637655508303, 27.612201496321322],
            [86.44741269846085, 27.612918583060978],
            [86.45474883250584, 27.613994493706834],
            [86.4636391424895, 27.61608682346398],
            [86.467239343954, 27.616163870493732],
            [86.469694762523, 27.617132147550716],
            [86.47290790664195, 27.61698196650114],
            [86.47662256138189, 27.61583406971031],
            [86.48036710578417, 27.617055229400997],
            [86.48461211682286, 27.61716359033379],
            [86.49177350376198, 27.61406283549918],
            [86.49416899089098, 27.617022768329623],
            [86.4954780918702, 27.618843816305368],
            [86.49986367058001, 27.6203712562258],
            [86.50049261760208, 27.624741690348387],
            [86.50395354055, 27.62937488195162],
            [86.50408852615327, 27.63307557281358],
            [86.50598739957692, 27.635020474933665],
            [86.5068993299773, 27.63850057838883],
            [86.50696074348984, 27.640199737957566],
            [86.51138567640515, 27.64243561551623],
            [86.51231706467016, 27.643582726471557],
            [86.51353700814093, 27.648085941069798],
            [86.513706242676, 27.648203858829532],
            [86.51758586560769, 27.64699227665437],
            [86.52039928845537, 27.64848889490606],
            [86.5221323019561, 27.649365809607396],
            [86.5262178053352, 27.650415412038356],
            [86.52694568772615, 27.65312764019134],
            [86.52882870502694, 27.655618165824155],
            [86.52718345743334, 27.659554201867564],
            [86.52933051615943, 27.66195311440712],
            [86.53131556113405, 27.66240424492458],
            [86.53391193255166, 27.664130073771013],
            [86.53548288363586, 27.66835897425639],
            [86.53749771213329, 27.66982100400755],
            [86.53803385551055, 27.670312401879205],
            [86.53881017641382, 27.674992128518866],
            [86.53669269682439, 27.67818218239821],
            [86.53898691132355, 27.683164778238925],
            [86.53890248533773, 27.68599158732204],
            [86.53919697113203, 27.689882276270296],
            [86.54054663238648, 27.692235496891364],
            [86.54033641926085, 27.694163862024286],
            [86.53911447398471, 27.696623580809824],
            [86.54004341921778, 27.700947347798785],
            [86.5409890615135, 27.702886322674818],
            [86.54326393889555, 27.7042362959765],
            [86.54791075607797, 27.708026217450374],
            [86.54878902711317, 27.70967836378805],
            [86.55096239718866, 27.710878969991533],
            [86.55149433526469, 27.71377542315327],
            [86.55107852733784, 27.716565011345224],
            [86.55170041175778, 27.718015523884304],
            [86.55354892331252, 27.719580544352326],
            [86.55399296642243, 27.721824655670236],
            [86.55436911872184, 27.72401890406944],
            [86.55689843602546, 27.72858685334029],
            [86.55627261693047, 27.731450227417568],
            [86.55825929000962, 27.738439562266876],
            [86.5632763631731, 27.744937568910046],
            [86.5628872545068, 27.749347231296255],
            [86.56918234889494, 27.751916513780554],
            [86.57276265428666, 27.754598997813737],
            [86.57036096238936, 27.759354385748978],
            [86.5660715433834, 27.762621540258312],
            [86.56391825460697, 27.770486634960417],
            [86.56588689133508, 27.776241632407107],
            [86.56619654549196, 27.780511334980826],
            [86.56559953456525, 27.78280404368894],
            [86.56616967329198, 27.787277591923928],
            [86.56883192308399, 27.79111919977104],
            [86.56881507969167, 27.795387869338168],
            [86.56563069757864, 27.79961953899775],
            [86.56708297303058, 27.804007879681745],
            [86.56217426614613, 27.80646068120728],
            [86.56073812908635, 27.80622373404808],
            [86.55295493457047, 27.808755006243615],
            [86.54637911375293, 27.811463586958666],
            [86.5424263271506, 27.811170794021326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DOLAKHA",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [86.5424263271506, 27.811170794021326],
            [86.540352555756, 27.814877557256768],
            [86.54073214578654, 27.81813676738623],
            [86.54353314063485, 27.821507765309732],
            [86.54384266415747, 27.82708152294834],
            [86.54539767097366, 27.831659891234988],
            [86.54710985026442, 27.83486934588837],
            [86.54734184312626, 27.83737220823831],
            [86.54914052082354, 27.841628691293504],
            [86.55014177928393, 27.848414064176236],
            [86.5541026423939, 27.85714703151674],
            [86.55445012530647, 27.861022001059386],
            [86.55251418300925, 27.867879030102674],
            [86.55126009711924, 27.87826231975124],
            [86.55462245140777, 27.88553805137975],
            [86.5522627923373, 27.89220415330927],
            [86.55174971561138, 27.899092789368392],
            [86.55424899414231, 27.902706260150072],
            [86.55471600998935, 27.907041830173313],
            [86.55342389990558, 27.911827492663534],
            [86.54978877805245, 27.919134525495092],
            [86.54871838456951, 27.92887314710977],
            [86.5478027129026, 27.93045847168814],
            [86.54415734080463, 27.93275902066554],
            [86.5420643343205, 27.93331835300985],
            [86.53881215879096, 27.93570126949647],
            [86.53448377103885, 27.941688043886206],
            [86.5284548395408, 27.941399034816747],
            [86.52379517521175, 27.939821781730004],
            [86.52173220103295, 27.94043504992991],
            [86.51992831928757, 27.944061155259337],
            [86.51822079870233, 27.953203799103626],
            [86.51556950910974, 27.956621571758177],
            [86.49771323017204, 27.957223137034514],
            [86.4664417415308, 27.941330631877552],
            [86.46988200359296, 27.930045217521904],
            [86.44245697169033, 27.905724128284426],
            [86.41075582487811, 27.906584916102478],
            [86.38131694687517, 27.937870038144595],
            [86.34794500152307, 27.937573916584938],
            [86.33533417712015, 27.96509437669588],
            [86.31877111916229, 27.947880641580287],
            [86.28878024150201, 27.97839583605483],
            [86.23739823691771, 27.973082808186383],
            [86.21999039171104, 28.010997386150247],
            [86.22540903308445, 28.02920495513363],
            [86.20442099398173, 28.08512239432884],
            [86.2225192355626, 28.095770760576077],
            [86.21822445881752, 28.114872822575723],
            [86.19719017357171, 28.138274586463233],
            [86.18672638537251, 28.174175562520787],
            [86.17405592471773, 28.168953841541665],
            [86.17731554189551, 28.142844666267347],
            [86.13235877555428, 28.116634939622106],
            [86.11785172645118, 28.091399475281317],
            [86.08278064347242, 28.090932615296623],
            [86.09485459494793, 28.06186778265759],
            [86.07953787793487, 28.022078626205897],
            [86.10358573221716, 27.984217103593085],
            [86.12398274681206, 27.927643218357197],
            [86.09045042965109, 27.920055403508762],
            [86.06501164706093, 27.899783910223466],
            [86.04248646462987, 27.867823824133698],
            [86.00251330214473, 27.8301966163773],
            [86.0197689743692, 27.80956244618997],
            [86.01979001869185, 27.77246645194079],
            [86.03409339873554, 27.75662808592324],
            [86.02774212079014, 27.739473606358697],
            [86.0046146270427, 27.74523578544544],
            [85.97724751565677, 27.735368986828],
            [85.96651689764518, 27.742190472233943],
            [85.93676064494345, 27.71018423205279],
            [85.93915058588993, 27.669201215130972],
            [85.91824740631644, 27.66353260842818],
            [85.91062766190944, 27.63709524690977],
            [85.88553476510985, 27.6121403097682],
            [85.95225364351201, 27.589075096600382],
            [86.00059156232405, 27.54123872964121],
            [85.98778039152687, 27.519618182379656],
            [86.00064648325966, 27.502381929068218],
            [86.09860417013127, 27.498432681748795],
            [86.11516122188988, 27.47801903418869],
            [86.14199410626057, 27.496229707643927],
            [86.15243637520992, 27.531717989627264],
            [86.20282641728417, 27.580422450659267],
            [86.2888383674019, 27.603419936858323],
            [86.30263505743575, 27.61545229212239],
            [86.31169916777698, 27.646037359040264],
            [86.3519024095889, 27.69396530443785],
            [86.37577489735871, 27.69238732974803],
            [86.42753385785022, 27.73382482492943],
            [86.41054176714657, 27.74728830991328],
            [86.42219309841826, 27.78649564827949],
            [86.41972570926895, 27.813538971397456],
            [86.44998277165556, 27.832526569388797],
            [86.48667223186072, 27.808890088851744],
            [86.5424263271506, 27.811170794021326]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MAKWANPUR",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [85.18869324377265, 27.681417871373945],
            [85.15052946267811, 27.669911861384932],
            [85.12025277205852, 27.683581006812627],
            [85.10063341768564, 27.708308619855462],
            [85.07595233600095, 27.70903055408269],
            [85.04266793117279, 27.690212639446177],
            [85.01081730207065, 27.707741444028475],
            [84.99143949826754, 27.7079582780444],
            [84.95149977988055, 27.683489319760216],
            [84.90726210964515, 27.698586130207296],
            [84.87985174206315, 27.66878153477889],
            [84.82969954129149, 27.692448597634215],
            [84.80011658606045, 27.671442030784483],
            [84.79219159647569, 27.68696244247225],
            [84.76754371993019, 27.65676950614305],
            [84.73152527315767, 27.65583392437472],
            [84.7178986887637, 27.63974644414217],
            [84.73618524840383, 27.604774586337157],
            [84.73096438299788, 27.582073344070313],
            [84.7036822463131, 27.558533162191516],
            [84.68008015874318, 27.550970532194146],
            [84.6791323273119, 27.524135171449167],
            [84.69675493795499, 27.475857232095578],
            [84.66478537492496, 27.42952347900784],
            [84.67070472558817, 27.42908448509372],
            [84.67786781295037, 27.429422129073686],
            [84.68401878996521, 27.43047056901689],
            [84.68590728837707, 27.431738419774984],
            [84.6906060265746, 27.432529609066318],
            [84.70262869418082, 27.432144642645994],
            [84.704918936633, 27.43302653648835],
            [84.70989636136038, 27.431694921182153],
            [84.71331614806257, 27.432548397293857],
            [84.71492849770267, 27.431714325110097],
            [84.7194270200751, 27.431625746762485],
            [84.7189071506141, 27.43296638409437],
            [84.71888883494202, 27.433253045567277],
            [84.72074715355276, 27.43574108027615],
            [84.72094580192318, 27.43644399999115],
            [84.72061153101338, 27.4371540970032],
            [84.72080947904738, 27.43774429981183],
            [84.72132777942183, 27.43840946513447],
            [84.72242635393444, 27.439545431679097],
            [84.72358656592503, 27.440435138978742],
            [84.72503452364782, 27.442787659804207],
            [84.7251454360488, 27.44332180105668],
            [84.7257520199967, 27.44451670317044],
            [84.72745417316956, 27.444349869618353],
            [84.72787865842577, 27.44419423890265],
            [84.7340110616966, 27.444065059665366],
            [84.73450584687424, 27.442115304961288],
            [84.73812823793475, 27.4423691510134],
            [84.73962420189072, 27.440488530669768],
            [84.74332186945524, 27.442075320711727],
            [84.7491250960976, 27.437592708328108],
            [84.75548555045152, 27.43573082539215],
            [84.75790601790351, 27.4374662613739],
            [84.76862873792498, 27.437211241105675],
            [84.77028422493733, 27.43891006337279],
            [84.77465920508277, 27.438662445460754],
            [84.77513418672221, 27.437698646701726],
            [84.77956466364238, 27.43626831195899],
            [84.78004571345872, 27.435094644555058],
            [84.78463976970825, 27.435366672394892],
            [84.7862156130491, 27.436871706256028],
            [84.79124568322123, 27.437632903906056],
            [84.79361175829021, 27.436978687059664],
            [84.79700721560229, 27.435391257553675],
            [84.79745507543214, 27.433145966086176],
            [84.7978002968727, 27.43227536211985],
            [84.79866835984762, 27.4317605144763],
            [84.79936367153633, 27.43131653508539],
            [84.79970576653274, 27.430540735058425],
            [84.80010416128724, 27.430035286643985],
            [84.80055341499, 27.43000567131495],
            [84.8018097958932, 27.429346373957507],
            [84.80352524362529, 27.42827606044602],
            [84.80671393536493, 27.425692342115514],
            [84.80682173433875, 27.425655597096373],
            [84.80742659727639, 27.425381443644774],
            [84.80924337397524, 27.42452473639805],
            [84.80935677920303, 27.4242984663484],
            [84.80862583812859, 27.42374767855818],
            [84.8089560887664, 27.421882156561246],
            [84.80919732624848, 27.420894748937],
            [84.80986318385209, 27.416944764368083],
            [84.81001678714678, 27.41607973978878],
            [84.81012608691739, 27.415986599742304],
            [84.81242585301092, 27.416895970678095],
            [84.8135705092472, 27.41715102816543],
            [84.81471046063932, 27.418322128382663],
            [84.81548034544605, 27.419008141349078],
            [84.81677373160441, 27.41928469923457],
            [84.81873934046837, 27.419173959722098],
            [84.81939149010239, 27.419495023570335],
            [84.8214240273709, 27.4192912847599],
            [84.82245648644924, 27.41898962699077],
            [84.82288111655843, 27.419054815276514],
            [84.82381228065312, 27.41932457375606],
            [84.82723005461442, 27.41840637892208],
            [84.82838740866625, 27.4174767003685],
            [84.83019168172952, 27.4162543820892],
            [84.83198157933813, 27.41700182921583],
            [84.83281739591149, 27.41753598185767],
            [84.8337045245419, 27.417747351763204],
            [84.83403711273334, 27.41725800899525],
            [84.83552765696356, 27.416434592262256],
            [84.83595050187543, 27.41652005949407],
            [84.83657156362821, 27.415561967663567],
            [84.83753676748881, 27.4144776839249],
            [84.83770924889215, 27.414386403420732],
            [84.83937739486524, 27.414825390123713],
            [84.84014147995025, 27.414899707906148],
            [84.8410411873649, 27.413851925596894],
            [84.84075334480556, 27.413483642899052],
            [84.84075836685004, 27.413312140586456],
            [84.8411149576463, 27.411908882956993],
            [84.841919284925, 27.412041657852736],
            [84.84428347698237, 27.41192357778517],
            [84.84460277741377, 27.411111680012496],
            [84.84462915265448, 27.41092200993984],
            [84.84689289156067, 27.40898821882161],
            [84.8489424230643, 27.406326989598504],
            [84.84920364198949, 27.40610426745109],
            [84.85090556020496, 27.403702311106407],
            [84.85055093949215, 27.403445009937826],
            [84.850408710237, 27.403193181611304],
            [84.85044298323436, 27.40271689938369],
            [84.8517422578605, 27.398665853194064],
            [84.85189386172877, 27.398574678916223],
            [84.854374915334, 27.39882124779558],
            [84.85471399465261, 27.39886656153647],
            [84.85543677311145, 27.398920755484287],
            [84.85806636955397, 27.398600012895564],
            [84.85997168698694, 27.398263385401886],
            [84.86049898379484, 27.39772090557634],
            [84.86129152632687, 27.39756709350256],
            [84.86351949077208, 27.397122418252547],
            [84.86435194443521, 27.39706537389862],
            [84.86519401358325, 27.397427917067784],
            [84.86810940813508, 27.397531609560783],
            [84.86995955259864, 27.398395467412943],
            [84.8701276538185, 27.398475650354715],
            [84.87061046035915, 27.398714074034135],
            [84.87181361561422, 27.39910364475778],
            [84.87245297219437, 27.398355099639254],
            [84.8730201498677, 27.39831770981746],
            [84.8733388573229, 27.398340525263176],
            [84.87645080991159, 27.397723118572067],
            [84.87672779789821, 27.397669513520114],
            [84.87847842132439, 27.395526358124407],
            [84.87852945900197, 27.393267561465763],
            [84.87958959626884, 27.39188007748205],
            [84.87989425434951, 27.391329944081566],
            [84.88032977469028, 27.390548770946147],
            [84.88072248777938, 27.389788220290146],
            [84.88150690851332, 27.3883799070762],
            [84.88425186654814, 27.38814142323249],
            [84.88798110592319, 27.389063086534765],
            [84.88963538140347, 27.389370709880982],
            [84.8904950177483, 27.388690591506283],
            [84.89114826342264, 27.387490633831973],
            [84.89164203542079, 27.387133254428782],
            [84.89234381836525, 27.387101705757125],
            [84.89297827617371, 27.387339049108714],
            [84.89368019993815, 27.387327768938523],
            [84.8946215852397, 27.386838952518595],
            [84.89582594628125, 27.385818250404082],
            [84.89733039604869, 27.384554050931435],
            [84.89748492167188, 27.380839350310755],
            [84.89753607373106, 27.380209541065938],
            [84.8982813918846, 27.378456198849435],
            [84.90060333739859, 27.375171967128175],
            [84.90246091383051, 27.374704225673348],
            [84.9031315506095, 27.375281998195437],
            [84.9039278864718, 27.375779964754628],
            [84.90544414247628, 27.376848610739454],
            [84.90669111398827, 27.37743842193666],
            [84.90762696744716, 27.377371407404656],
            [84.90974658020825, 27.37618889565181],
            [84.91011150466865, 27.37592479874854],
            [84.91204905125059, 27.374966802118774],
            [84.91370062144091, 27.373119454634942],
            [84.91485547470992, 27.37287724523969],
            [84.91523976218454, 27.37289727553624],
            [84.9161385726321, 27.372710895376024],
            [84.91665302087657, 27.372502208115513],
            [84.91697290361253, 27.37259937529203],
            [84.9219475499199, 27.370443538188734],
            [84.92223027986974, 27.369566246243203],
            [84.92248806432455, 27.369262215033885],
            [84.92306692952354, 27.369053055361825],
            [84.92405788895024, 27.3676431683412],
            [84.92522063967488, 27.366159910916423],
            [84.92580295471751, 27.365300934525493],
            [84.9260189976994, 27.364843791378696],
            [84.92617136424519, 27.36425165932776],
            [84.9262356686207, 27.364233161994417],
            [84.92683508638159, 27.36421790130681],
            [84.92852339460646, 27.36462633477081],
            [84.9289079923547, 27.36468469349015],
            [84.92954770223405, 27.365106856276203],
            [84.93012356736037, 27.365529419372493],
            [84.93044408563549, 27.36562660319633],
            [84.93082884579579, 27.36570522719192],
            [84.93269536955901, 27.364682035677475],
            [84.9328239158634, 27.36464279121216],
            [84.93384933838276, 27.364877348796377],
            [84.93433744560245, 27.365681784382495],
            [84.93504245360762, 27.365970381771977],
            [84.93660331548271, 27.36616748791048],
            [84.93716188430506, 27.365730574367095],
            [84.9397930222478, 27.365857258349415],
            [84.94004981384226, 27.365819424267393],
            [84.94099909656765, 27.36414346434108],
            [84.94085086988044, 27.36379699927937],
            [84.94322724449833, 27.363539551504918],
            [84.94361307141763, 27.363369986134398],
            [84.944190942355, 27.36327584221893],
            [84.94477083193647, 27.36282067042002],
            [84.94533269704264, 27.36173393579992],
            [84.9446521026914, 27.360890175044567],
            [84.94463184940868, 27.360662436835053],
            [84.9457228916632, 27.360666331630267],
            [84.94591495220249, 27.360743971138884],
            [84.94676933296346, 27.361072142662234],
            [84.9471097586812, 27.36135860542642],
            [84.94723775076312, 27.36155176938064],
            [84.94916030973832, 27.36209371371188],
            [84.95030015578755, 27.365440931745578],
            [84.95179066368677, 27.367012363350845],
            [84.95438240431363, 27.366374217267143],
            [84.9564060128062, 27.363517637540376],
            [84.95530445277281, 27.36125763518667],
            [84.95772616449655, 27.359451903494772],
            [84.96189416629394, 27.36265184994758],
            [84.96490071241816, 27.363098121216368],
            [84.96558227428045, 27.358572099874113],
            [84.97233994794689, 27.355188241312696],
            [84.9755996834112, 27.356361268006758],
            [84.97772796151182, 27.358162617204798],
            [84.9801918236822, 27.361552169565783],
            [84.9834122194906, 27.362459094814767],
            [84.98772776736575, 27.361688576331964],
            [84.9909452442399, 27.36360834029429],
            [84.99312252597191, 27.363710189401456],
            [84.99446167398723, 27.365318330434732],
            [84.99747450592422, 27.364753110893158],
            [84.99922413681476, 27.365421891072412],
            [85.00064101257425, 27.362099745762237],
            [85.00128906776231, 27.35829568685911],
            [85.00091833484605, 27.355775971665405],
            [85.00445599286952, 27.352919075536455],
            [85.00867339509877, 27.354761294786403],
            [85.01320411520824, 27.35540759875684],
            [85.01600433310638, 27.353873365319064],
            [85.01868248985019, 27.35364400269852],
            [85.02149577270978, 27.352100568608364],
            [85.02210266040592, 27.353806292130827],
            [85.02150460968691, 27.357197166566685],
            [85.02456271201265, 27.358704444335043],
            [85.02930022602463, 27.35935097176225],
            [85.03116826766983, 27.36058257847752],
            [85.03561799282014, 27.357442929879156],
            [85.0399844415515, 27.359346265936086],
            [85.04245548334143, 27.358912676461184],
            [85.0445449063994, 27.361372058010357],
            [85.04646365257366, 27.36233235096252],
            [85.04679535506506, 27.362336627466814],
            [85.04938926239645, 27.362545003172432],
            [85.0494680596346, 27.362790357449988],
            [85.05237610524728, 27.36320617870048],
            [85.05294432756246, 27.36397349398364],
            [85.05326745990396, 27.36460500505025],
            [85.05434193413893, 27.3650594188567],
            [85.056762427342, 27.36568410882926],
            [85.06011321176129, 27.363095748465465],
            [85.0614771798481, 27.3604501844367],
            [85.06180714965606, 27.3608695741479],
            [85.0624431298337, 27.361185081389426],
            [85.06333895171585, 27.36105648207649],
            [85.06361933232324, 27.361241580413758],
            [85.06623883705547, 27.36220302075891],
            [85.06678254704595, 27.360599263047142],
            [85.06744907785827, 27.360540021110523],
            [85.06823340915787, 27.36216071067411],
            [85.06941612792153, 27.362171978385856],
            [85.06971807931514, 27.362496776877244],
            [85.06988359455693, 27.363219729297676],
            [85.06937477536654, 27.364243402810274],
            [85.07081677361238, 27.364415112238028],
            [85.0729166893168, 27.36415304394684],
            [85.07310829001355, 27.364104176355454],
            [85.07570209002928, 27.36324041975449],
            [85.07653266177793, 27.363552154999677],
            [85.07834474767995, 27.362926739686674],
            [85.0807931776541, 27.36277922917238],
            [85.08706150770921, 27.360930163505795],
            [85.08806655902306, 27.358215002967615],
            [85.09133997142206, 27.356594341689156],
            [85.0906308721828, 27.35340068583241],
            [85.09217262879201, 27.352614756782774],
            [85.09309746106187, 27.3486277532668],
            [85.09302663787943, 27.343344412462393],
            [85.09190768804986, 27.341954394128166],
            [85.09275632362106, 27.33806491299157],
            [85.09490059617299, 27.33247546354663],
            [85.09887259439849, 27.328644943891668],
            [85.09934135554403, 27.32699653214396],
            [85.10323112768027, 27.32331542866499],
            [85.1039187818622, 27.320248435974694],
            [85.1057671616886, 27.31910349826519],
            [85.10991446958823, 27.318380226009115],
            [85.1120867382185, 27.31875778915664],
            [85.11679544668343, 27.317917063979426],
            [85.11778846639857, 27.31662763254861],
            [85.11902020194756, 27.314397755396058],
            [85.11905782011733, 27.309276060162446],
            [85.12060386811214, 27.30603962409307],
            [85.12127977840775, 27.30185810329831],
            [85.12507517896111, 27.30162458975665],
            [85.12712026691194, 27.295627077768017],
            [85.12663733918554, 27.292630293708623],
            [85.13218758871398, 27.29203047521381],
            [85.13745903814991, 27.28947887197285],
            [85.13875761237017, 27.286566902871677],
            [85.14253802308538, 27.284862052706558],
            [85.14478324759833, 27.282989149238187],
            [85.14422769211994, 27.280090053301628],
            [85.14556283905986, 27.272523337329062],
            [85.15131579386195, 27.268365443786855],
            [85.15179449542431, 27.265757906856887],
            [85.1548554390961, 27.26402479775461],
            [85.15599476392471, 27.26214054668281],
            [85.16144185963387, 27.262102198998857],
            [85.16491047901204, 27.260507642310525],
            [85.16830025122198, 27.25983638524439],
            [85.17010994548946, 27.255453410147872],
            [85.17543499614727, 27.253687360291558],
            [85.18095975053559, 27.254590679798525],
            [85.17947691372235, 27.251622858872107],
            [85.17735434101135, 27.249684665787345],
            [85.1821478857007, 27.247343022684323],
            [85.18977029363468, 27.246193258197998],
            [85.19290186657412, 27.245094967575557],
            [85.19707566478435, 27.245625615418856],
            [85.19974550634178, 27.2493185740926],
            [85.20245406849453, 27.25025647087593],
            [85.20673935464265, 27.248349271823198],
            [85.20766215664598, 27.24591144170071],
            [85.2091762034129, 27.245223823975167],
            [85.21519606178937, 27.246816407343392],
            [85.21722009300618, 27.244691619277877],
            [85.22035752314704, 27.243985219392385],
            [85.22471378091446, 27.24549938190788],
            [85.23179752877317, 27.243399987399158],
            [85.23321392675784, 27.245433765858458],
            [85.2389543467913, 27.248602561629802],
            [85.24296645266155, 27.248697879737666],
            [85.24556646462621, 27.24660367064281],
            [85.24903887345945, 27.246408016194817],
            [85.25152062699857, 27.244982564232128],
            [85.25155540408905, 27.243014941396442],
            [85.25427274894312, 27.237228567373386],
            [85.2580942850589, 27.234216245809233],
            [85.25698751743107, 27.23067509459245],
            [85.26082322251848, 27.2269879772691],
            [85.26384919582443, 27.227159260211597],
            [85.26737643650321, 27.226285894384862],
            [85.26934130750803, 27.228077467631152],
            [85.27380668964253, 27.229645517335832],
            [85.27953719901252, 27.228778836687397],
            [85.28063146839632, 27.231223477655956],
            [85.28788545478595, 27.23104410944541],
            [85.29302742796685, 27.232925711136694],
            [85.29811510649249, 27.231145951218693],
            [85.30403891372208, 27.22993134174335],
            [85.30650361800078, 27.23162522998571],
            [85.30739517810578, 27.23184024360706],
            [85.31085475445228, 27.2305490205633],
            [85.31231360133526, 27.230555663451998],
            [85.31528927638608, 27.228201748417085],
            [85.32096087530788, 27.226767644535897],
            [85.32277328459533, 27.22562252927924],
            [85.32319852364529, 27.225408699247343],
            [85.32562878636432, 27.224605124048054],
            [85.32666942129845, 27.22441475825469],
            [85.33038732652795, 27.223131925581246],
            [85.33071933638591, 27.22303852968502],
            [85.33281557814922, 27.223016356340793],
            [85.33688650358056, 27.220453026933253],
            [85.33882502107244, 27.21959074991193],
            [85.34411636798751, 27.221227607495226],
            [85.34886198501833, 27.222120505649343],
            [85.35102357886672, 27.21884195769369],
            [85.35623611694172, 27.21364328588532],
            [85.3584678163795, 27.213310331748456],
            [85.35953335156377, 27.210870236584793],
            [85.36160075125707, 27.21254443877632],
            [85.36255799564746, 27.212778808215397],
            [85.36534088969194, 27.212275710177614],
            [85.36792059487168, 27.211772241359657],
            [85.36839506043852, 27.211778865936076],
            [85.37299585423173, 27.210086974062982],
            [85.37333523792445, 27.21018968197667],
            [85.37737106895335, 27.211505946118667],
            [85.37897481630577, 27.210238072908975],
            [85.3809465280926, 27.20898912844739],
            [85.38516386471427, 27.207431438073552],
            [85.38884464594331, 27.209466041456377],
            [85.3893152179878, 27.209718563902026],
            [85.39086625961734, 27.208994784024366],
            [85.39161929613354, 27.208930906809826],
            [85.39190408566762, 27.209056673241275],
            [85.3930450375164, 27.210207213977977],
            [85.39631793814114, 27.207740360134057],
            [85.39718673678445, 27.206438997853287],
            [85.39727156197034, 27.2062102631628],
            [85.3961278775594, 27.201096013216425],
            [85.3962761907414, 27.20009060522066],
            [85.39728498478611, 27.197675610151798],
            [85.3981416902875, 27.19723395229969],
            [85.39933373337682, 27.196619651188307],
            [85.40512253202972, 27.196753482188907],
            [85.40658881191943, 27.196937330588096],
            [85.40779843077169, 27.196724402748547],
            [85.41032633010497, 27.19646210652367],
            [85.41198047325551, 27.19523847735076],
            [85.41315926493596, 27.194782161954034],
            [85.41354753281023, 27.19446244668517],
            [85.41593765307778, 27.191435526146233],
            [85.41900727695409, 27.190134357611026],
            [85.42140303883791, 27.19110498312328],
            [85.42291141316934, 27.192025939271815],
            [85.42663128075147, 27.193107102864584],
            [85.43082858698982, 27.19067518689855],
            [85.43330588610297, 27.19051454121968],
            [85.43421541572437, 27.189483285060955],
            [85.4343957709085, 27.189289680212433],
            [85.4369094805917, 27.187294398421585],
            [85.43935153253896, 27.187395741164607],
            [85.4438949334785, 27.187486618649125],
            [85.44810337212733, 27.18678896258672],
            [85.45005181678299, 27.184127017033372],
            [85.44948346526364, 27.18046014706633],
            [85.4515226826854, 27.179703599517566],
            [85.4533824515274, 27.177417043391447],
            [85.45250384246627, 27.175603307344012],
            [85.45229627833123, 27.170091783141636],
            [85.45292426091781, 27.1684293719114],
            [85.45581831797064, 27.167724819267825],
            [85.45721410421567, 27.16614691564398],
            [85.4631891924105, 27.166621662762566],
            [85.46749170876424, 27.16566525469267],
            [85.46871934501615, 27.16329716666657],
            [85.47257828648196, 27.16185348261935],
            [85.47407333774908, 27.162672499237868],
            [85.48020570273415, 27.16293504662145],
            [85.48620227246309, 27.164416996363386],
            [85.48905087074553, 27.1659208292034],
            [85.49405598690089, 27.169923889594457],
            [85.49518496351685, 27.1736793436741],
            [85.48302880081908, 27.17554101294072],
            [85.48904027400951, 27.19874772530633],
            [85.50383491678167, 27.194059441092985],
            [85.50164014813653, 27.22323952400682],
            [85.4883305534676, 27.22019601815082],
            [85.48872288427607, 27.250160070894882],
            [85.47906452242361, 27.269496789707393],
            [85.43052196676102, 27.29220717962907],
            [85.41816208089699, 27.32116228528345],
            [85.44629881505307, 27.336688179630208],
            [85.44681888925986, 27.347894895269032],
            [85.47261191498829, 27.37013726079076],
            [85.47002407233106, 27.38827290314512],
            [85.44776663584189, 27.38821239379903],
            [85.44311315019134, 27.402121118332364],
            [85.41865805184018, 27.408284890063538],
            [85.3988175831435, 27.404280222206207],
            [85.36877410070458, 27.42434522646218],
            [85.31779712776665, 27.436019499483727],
            [85.29311855976478, 27.45255713157465],
            [85.26825531956426, 27.453054198489497],
            [85.23400943355745, 27.504961672440746],
            [85.25085704240063, 27.528587275389622],
            [85.23156601379205, 27.537212771783064],
            [85.26306144811879, 27.57088281662218],
            [85.24263634441189, 27.578168927279986],
            [85.2355400630006, 27.62001994900282],
            [85.22265824255017, 27.62620494509824],
            [85.23320252866495, 27.649803892717546],
            [85.18869324377265, 27.681417871373945]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "CHITAWAN",
        Province: "Bagmati"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.6656961033793, 27.8253076537829],
            [84.66129109612953, 27.828737814389797],
            [84.66109740246792, 27.831010665984287],
            [84.66327756204919, 27.836146438703324],
            [84.6639860883852, 27.8405943716834],
            [84.6615827440873, 27.845650612403013],
            [84.66071539241298, 27.84901413873558],
            [84.65872469628192, 27.851106059882],
            [84.65483444412774, 27.852437657077157],
            [84.64913232100109, 27.852701481280565],
            [84.64688125907638, 27.855135163518405],
            [84.64987222883146, 27.858336055461965],
            [84.64631535016242, 27.860626908268355],
            [84.64190885919409, 27.859828540651847],
            [84.63596959006954, 27.860092942749876],
            [84.63373215536733, 27.863311468282358],
            [84.63380784683855, 27.868901776590537],
            [84.62849400968246, 27.871062685421936],
            [84.62677825243874, 27.8734619416028],
            [84.62737562971142, 27.87722245606886],
            [84.6248576239815, 27.87965471072153],
            [84.61987727867735, 27.880534506430305],
            [84.61795448758099, 27.879728674170078],
            [84.61423129051728, 27.875948261077816],
            [84.61307615010644, 27.87269554622269],
            [84.60813626592271, 27.87157806991896],
            [84.60489924501252, 27.872253333186837],
            [84.60402137002005, 27.874982635142075],
            [84.60168609534723, 27.876111871472162],
            [84.59604058536408, 27.876303335140356],
            [84.59116170293997, 27.879751402341324],
            [84.58996570543091, 27.879740734901976],
            [84.58440220437312, 27.87608916565234],
            [84.5805371888775, 27.874395383419653],
            [84.57694723435837, 27.870931453093156],
            [84.57699373530396, 27.864253120266287],
            [84.57342799838908, 27.861438759842375],
            [84.56742833131156, 27.85893668849704],
            [84.55878725049946, 27.857965718833402],
            [84.55698272732916, 27.853690825535672],
            [84.55821665525072, 27.847544624729178],
            [84.55674880900794, 27.838460511458077],
            [84.55517573855418, 27.835815875941293],
            [84.54994394832197, 27.8328587243389],
            [84.54568745132029, 27.831763300454124],
            [84.54111079847023, 27.83339666312763],
            [84.53224721444454, 27.833043126602302],
            [84.5287125498041, 27.833708740555128],
            [84.52503554291104, 27.833415937335072],
            [84.52076626516495, 27.831462479782935],
            [84.5201772604592, 27.826893712809262],
            [84.52050112952641, 27.821901854731898],
            [84.51802219238225, 27.816773869222875],
            [84.51270445531605, 27.81549643248123],
            [84.50803364169816, 27.81689670293998],
            [84.50297650825058, 27.81970612041605],
            [84.49599247575473, 27.820863969809096],
            [84.4927942334398, 27.819420203527915],
            [84.48857499969422, 27.820885915714946],
            [84.48053250349857, 27.82160904018367],
            [84.47434171411517, 27.823629417114667],
            [84.46852960985738, 27.824355445039124],
            [84.46540522556644, 27.824115576452797],
            [84.4661171655158, 27.819826439637843],
            [84.46492764052425, 27.818936974863885],
            [84.45413503159969, 27.820439436224685],
            [84.4458023466482, 27.823171749945164],
            [84.4437571302842, 27.82197128182372],
            [84.44110612580312, 27.817483206250763],
            [84.43848793733686, 27.815740746596628],
            [84.4353401397502, 27.81527465077635],
            [84.43221058990007, 27.81236046494817],
            [84.43065196185644, 27.802074844412886],
            [84.43097036498769, 27.800778817101296],
            [84.4299842728548, 27.790694488079374],
            [84.43127455075867, 27.786888727072256],
            [84.43412678425142, 27.78292922481705],
            [84.43933658158448, 27.778477111962655],
            [84.44301714347067, 27.778115625234584],
            [84.4484013157321, 27.77844344255195],
            [84.45535502637499, 27.77646235220368],
            [84.4600122310287, 27.774183970399942],
            [84.46736830562735, 27.767201196429703],
            [84.47056002774673, 27.76092943033258],
            [84.47198197246482, 27.754097319578957],
            [84.47146594433669, 27.75264613020084],
            [84.46862608624059, 27.751886404926584],
            [84.46475024166305, 27.753205808410037],
            [84.46029911369631, 27.757514241189046],
            [84.45710118785217, 27.759541890220667],
            [84.4498587506886, 27.759060279264595],
            [84.44785211550995, 27.757223476744926],
            [84.44180425296004, 27.749134109259078],
            [84.43861653558005, 27.744088083501907],
            [84.43306555791806, 27.74122215255837],
            [84.42757209125008, 27.742464361652512],
            [84.42448331933166, 27.742444514064484],
            [84.42204209786436, 27.741335188169515],
            [84.42351807284953, 27.735791679590058],
            [84.42574147756153, 27.73062941076842],
            [84.42743980120115, 27.722695838541316],
            [84.43074855700007, 27.713195940000162],
            [84.43136028106794, 27.70967658467647],
            [84.43066663124768, 27.705888294912413],
            [84.4279547348807, 27.70376006194285],
            [84.41948919427327, 27.70043998772247],
            [84.41722192100013, 27.6999933620001],
            [84.40819918166734, 27.696457644752286],
            [84.39450071100009, 27.68912174400009],
            [84.38695964070625, 27.685551073694388],
            [84.38048915400014, 27.681598021000127],
            [84.37811596209352, 27.681322490003303],
            [84.37291237936333, 27.682900166469498],
            [84.36967831898296, 27.684767973486725],
            [84.36519672092854, 27.688606438078523],
            [84.36392061259131, 27.690349354648475],
            [84.36261676936095, 27.695600777202106],
            [84.36041509793448, 27.69804551346612],
            [84.3546787971722, 27.7011987451434],
            [84.34867289800019, 27.703185958000063],
            [84.34311847000015, 27.702064912000083],
            [84.33736479400017, 27.69897672800016],
            [84.32993418226904, 27.69656693778895],
            [84.32604400900004, 27.694489148000116],
            [84.32406605177327, 27.69226460626023],
            [84.32381692299998, 27.688370916000167],
            [84.32065048100003, 27.684443410000085],
            [84.30965812914714, 27.67790498192637],
            [84.30577137, 27.675986759000182],
            [84.297005576, 27.670556991000126],
            [84.29237165170827, 27.66836700941223],
            [84.2868641068494, 27.666607360312415],
            [84.27564742200013, 27.663581921000173],
            [84.2702283480001, 27.662568242000077],
            [84.2663203680001, 27.65925235700007],
            [84.26149529932214, 27.655852328515152],
            [84.25853555100014, 27.651473941000063],
            [84.25620980600019, 27.651054131000137],
            [84.25076176175199, 27.654186732638376],
            [84.2410545444434, 27.65185518178822],
            [84.2349885320001, 27.64929796700011],
            [84.23059827094005, 27.646148823539832],
            [84.2252273466363, 27.644510576929044],
            [84.21817541594594, 27.643605806935042],
            [84.2121768520002, 27.640688712000042],
            [84.20591378900008, 27.63841707199998],
            [84.20104644234826, 27.63431330084956],
            [84.19497956439936, 27.63018621303448],
            [84.19082864185097, 27.628479473091055],
            [84.18408544876044, 27.623766199374053],
            [84.17461806206477, 27.617751918308144],
            [84.16798086, 27.610458773000175],
            [84.16426285700015, 27.606997592000027],
            [84.15979332700016, 27.603627392000078],
            [84.14977632601651, 27.597404451880294],
            [84.1416593063774, 27.58887742836251],
            [84.13526807000017, 27.584590053000113],
            [84.12911734599999, 27.57686359600001],
            [84.12619507127346, 27.571369959688628],
            [84.12564694021289, 27.56612230653959],
            [84.11980002700011, 27.560042225000075],
            [84.11940020857979, 27.556407670402024],
            [84.1147135495857, 27.553347558234226],
            [84.10942646643636, 27.551790459170423],
            [84.10730314645289, 27.5517695517217],
            [84.10068518100002, 27.553906741000105],
            [84.09026948246063, 27.554721624571627],
            [84.08347002, 27.55768614200008],
            [84.07769205131713, 27.559298241106877],
            [84.07466213158878, 27.559568402274007],
            [84.07087082300018, 27.55743591300012],
            [84.06701900300015, 27.55181511300009],
            [84.06442714181334, 27.55139893183001],
            [84.05991076905461, 27.553397782999664],
            [84.05777718025654, 27.55496230616013],
            [84.05250656079357, 27.556564117877024],
            [84.0497574416936, 27.55634175523021],
            [84.04574391000011, 27.55413421000003],
            [84.0431531708053, 27.550854370396763],
            [84.04178753800016, 27.54534938200004],
            [84.03885425854108, 27.540582665264022],
            [84.03648852261149, 27.538769232552067],
            [84.0311750912047, 27.537265565961786],
            [84.02538373818125, 27.5371516720714],
            [84.01959860617299, 27.538152153955185],
            [84.01436400384281, 27.539881127649966],
            [84.00810266100007, 27.544019686000183],
            [84.0055612340002, 27.544823076],
            [83.99792859423835, 27.545452683591286],
            [83.99594908400019, 27.544259046000093],
            [83.99201293400012, 27.543478183000047],
            [83.98651583981543, 27.54374853144434],
            [83.98140955900004, 27.54527098800014],
            [83.97831958224629, 27.54773664882373],
            [83.97464954519734, 27.549633582839537],
            [83.96919683100009, 27.550591326000188],
            [83.96232863800003, 27.54955181800011],
            [83.95775726361555, 27.548451682657458],
            [83.95489302969352, 27.543608744631868],
            [83.95130681900014, 27.541326397000148],
            [83.94796420856213, 27.54018124545794],
            [83.94520027100009, 27.540459947000045],
            [83.93941893239206, 27.54213851739005],
            [83.93234513700008, 27.545379810999975],
            [83.92445131265366, 27.544642460170056],
            [83.92000204300007, 27.541185570000152],
            [83.91877283512147, 27.533084694887666],
            [83.92005456100014, 27.52644284000013],
            [83.92212556400017, 27.52496612900012],
            [83.92145830400005, 27.52017111500004],
            [83.92200925142782, 27.518122671415654],
            [83.92564528600008, 27.514785288000155],
            [83.93010760000016, 27.513097632000154],
            [83.93538620300018, 27.513544669999987],
            [83.94192860400017, 27.51578812700012],
            [83.94611153878385, 27.518364242002253],
            [83.947661074, 27.520163129000082],
            [83.95093339500016, 27.526515854000138],
            [83.95429180400009, 27.527611305000107],
            [83.95653632684318, 27.527131421852268],
            [83.96488365465265, 27.523259686735866],
            [83.9679028286031, 27.51936375735719],
            [83.96939463800004, 27.516110505000142],
            [83.96872677559672, 27.509767820750465],
            [83.96653005733782, 27.506265499400968],
            [83.96184201700015, 27.50269929100017],
            [83.95778415540124, 27.500421501908953],
            [83.94114721683329, 27.49390822145103],
            [83.93875429000008, 27.491587702000118],
            [83.93854793900016, 27.48843323400007],
            [83.94011136400007, 27.484627538000154],
            [83.94136328835779, 27.4788721457803],
            [83.94365547500013, 27.4735820250001],
            [83.9444896194331, 27.469870664570248],
            [83.94460293000009, 27.465847674000088],
            [83.94293185403343, 27.461555442983684],
            [83.9393558663014, 27.455177524663927],
            [83.93501651685206, 27.450904319768977],
            [83.9333097760001, 27.450614694000024],
            [83.94646423498368, 27.4412272308793],
            [83.9968470541264, 27.44609763963991],
            [84.01580635699692, 27.434833114106457],
            [84.0517731998333, 27.442696775722126],
            [84.07063634723269, 27.48169601188663],
            [84.09564618854566, 27.48996179442265],
            [84.09786679380512, 27.517288807699664],
            [84.15091070778693, 27.516941282397877],
            [84.14911797261658, 27.48592785559933],
            [84.2062204687473, 27.469415406290818],
            [84.20838916856559, 27.44277395261349],
            [84.23169893022637, 27.456358605393493],
            [84.25417816041, 27.45221063279798],
            [84.2726207433014, 27.428874661404414],
            [84.28200018878067, 27.39673705561597],
            [84.3480734168816, 27.374241085860074],
            [84.39113213106127, 27.368560690340363],
            [84.42425351251127, 27.37204024430504],
            [84.47186304969696, 27.35529442060903],
            [84.48570489505481, 27.35913911960441],
            [84.48530405925425, 27.361247914385707],
            [84.48469718146161, 27.362321808646865],
            [84.48388995650862, 27.366142274090684],
            [84.4840496620645, 27.366545601780587],
            [84.48453843231216, 27.366634168824817],
            [84.48651988638161, 27.36839622830066],
            [84.4871645391086, 27.369021244501166],
            [84.4876139484532, 27.369777784723944],
            [84.48770573945944, 27.371135750305093],
            [84.48909472869964, 27.37189126615784],
            [84.49059433077754, 27.372517795291998],
            [84.4926326525861, 27.372943827118863],
            [84.49298778881355, 27.373238150731325],
            [84.49392752434669, 27.375276785878533],
            [84.49452005527498, 27.37609372154286],
            [84.49555665576892, 27.376737617045094],
            [84.49756637723888, 27.376683091685788],
            [84.50050016688117, 27.37655301732751],
            [84.50218201493928, 27.376935062011768],
            [84.50255285495278, 27.380002305755234],
            [84.50606818036896, 27.381147069201614],
            [84.50996154690554, 27.380801222370774],
            [84.51102766206144, 27.382198515635608],
            [84.51400034639738, 27.382943466746838],
            [84.51533677517193, 27.383610912882855],
            [84.51987291401173, 27.38409047514108],
            [84.5241589314976, 27.383381770488555],
            [84.53002494573123, 27.381143906457023],
            [84.53097912942532, 27.38142230104828],
            [84.53148903166365, 27.382997542356545],
            [84.53249098690708, 27.384002282705968],
            [84.53844729907063, 27.385175267623755],
            [84.53952647689728, 27.385802851101463],
            [84.53952661459145, 27.38752216593454],
            [84.53796136293478, 27.389491183247557],
            [84.53610326431554, 27.391149943221336],
            [84.53541650669305, 27.393018577302655],
            [84.53541923534772, 27.39410835443309],
            [84.53514796620948, 27.396965843532023],
            [84.53494454265869, 27.397639029925188],
            [84.53462996552271, 27.400559887651674],
            [84.5345533939183, 27.40092794133699],
            [84.53472886470249, 27.40180045638904],
            [84.53582306509402, 27.403418524092068],
            [84.54202936153438, 27.403658542092735],
            [84.54587353965886, 27.403842077809703],
            [84.54688882676751, 27.404675206040686],
            [84.5462357197824, 27.408222439769997],
            [84.54496201929933, 27.409994071797517],
            [84.54176483322226, 27.410329181800595],
            [84.54022227368695, 27.411036844522492],
            [84.53553424908847, 27.41207030468052],
            [84.53411555305566, 27.412524804559315],
            [84.5329324754445, 27.41347690017148],
            [84.5324857179382, 27.41442399457073],
            [84.53256646177563, 27.414653828297222],
            [84.53334620015924, 27.41473433095414],
            [84.53396672331935, 27.414440880074025],
            [84.53668871949574, 27.41540739749492],
            [84.53809784793825, 27.417669563214872],
            [84.53832187247157, 27.41798457943988],
            [84.53969392933325, 27.41897881273931],
            [84.54066016924467, 27.419058552021692],
            [84.5417210370533, 27.41950566894109],
            [84.5421172378748, 27.419337190168537],
            [84.54343483421307, 27.417971513874715],
            [84.54662296523055, 27.418067353380348],
            [84.54804784416025, 27.417838387155673],
            [84.54813572411807, 27.42078701285117],
            [84.54779348991042, 27.42521745321989],
            [84.54781746527385, 27.426762922834403],
            [84.54993316761258, 27.430044251946875],
            [84.55090312529832, 27.435308847673085],
            [84.55305184205086, 27.43934587224841],
            [84.5536290134002, 27.43946312058548],
            [84.55589448613586, 27.44152990713839],
            [84.55619578805062, 27.44324800732082],
            [84.55718833808463, 27.44951873643484],
            [84.55684508554548, 27.451925301231956],
            [84.55820443065983, 27.454045274170795],
            [84.55805666824696, 27.456099068066607],
            [84.55778615021117, 27.45888212616084],
            [84.55823994169307, 27.46009421165811],
            [84.56165302859682, 27.46303620434243],
            [84.56300098356672, 27.462744215278583],
            [84.56481867639833, 27.463179085058794],
            [84.56526818899519, 27.463161467455663],
            [84.56715199319906, 27.46106901125412],
            [84.56827472256283, 27.460389811846152],
            [84.56960960316738, 27.4601474505855],
            [84.57318279308586, 27.45877677280719],
            [84.57340868643871, 27.45673843451664],
            [84.5735150548207, 27.456266430495138],
            [84.5738423936205, 27.45598532140912],
            [84.57571086893265, 27.454172575673645],
            [84.57779177888308, 27.454283535265613],
            [84.58096554491652, 27.454207168354188],
            [84.58243630828046, 27.45650016676032],
            [84.58432655993197, 27.456431317910617],
            [84.58571350726373, 27.456127616599144],
            [84.58681226304333, 27.454776067544508],
            [84.58974664052602, 27.453678403518808],
            [84.59198259390965, 27.453233459810765],
            [84.59556934419838, 27.45459900800576],
            [84.5980639732639, 27.454170920505184],
            [84.59927563865503, 27.453148126965928],
            [84.5994623088352, 27.452590025725826],
            [84.6015906221452, 27.451434658384713],
            [84.60194680836466, 27.452283741548833],
            [84.60333644607799, 27.45318926189105],
            [84.60682722638263, 27.45432479513144],
            [84.60847665320449, 27.452898409417436],
            [84.60924019394393, 27.45227459933813],
            [84.61198411068202, 27.452009892130725],
            [84.61716455691152, 27.451463564042676],
            [84.62127338895712, 27.451285106462624],
            [84.62165601164165, 27.45186551537573],
            [84.62203229169639, 27.450413058888774],
            [84.62286212469505, 27.449788879046846],
            [84.62353023545977, 27.450090485937885],
            [84.62438332363293, 27.44989489876159],
            [84.6251744683255, 27.449927408066035],
            [84.62575245186122, 27.450055717967018],
            [84.62682355362205, 27.45039157933688],
            [84.62828053677146, 27.450876886071867],
            [84.62891023384529, 27.44942326111281],
            [84.63008096562294, 27.44882233321409],
            [84.63167128691104, 27.44719069192738],
            [84.63228776262936, 27.446747849456706],
            [84.6324579429859, 27.446668126048273],
            [84.63307446505137, 27.44622533698838],
            [84.63334940283254, 27.445881148049928],
            [84.63513368987805, 27.444456081546004],
            [84.63590407697087, 27.44462178738669],
            [84.63694658727486, 27.444061992135328],
            [84.63893151319063, 27.444010027497956],
            [84.64048774807945, 27.443732132785552],
            [84.64108672835137, 27.44399562462277],
            [84.64297906150922, 27.442915188473055],
            [84.64334986366248, 27.437624787368716],
            [84.64345099174642, 27.43685942737784],
            [84.64387124804374, 27.43595951453242],
            [84.64457185682573, 27.435439591967747],
            [84.64544037521246, 27.434442817123735],
            [84.64545464369901, 27.43352670857453],
            [84.64588883254208, 27.431672306095603],
            [84.64617719462663, 27.43037361472466],
            [84.64666152414596, 27.429416958155333],
            [84.64766377192775, 27.429141560894305],
            [84.64952520571474, 27.42952774249077],
            [84.65562691075583, 27.43212104142213],
            [84.65644129197572, 27.43269019039575],
            [84.65678248552825, 27.43264799680629],
            [84.65863513858181, 27.432084216283137],
            [84.65991762370052, 27.432362436803405],
            [84.66147886453355, 27.43296196951062],
            [84.66234844877758, 27.432172661846963],
            [84.6626990725033, 27.430338935962045],
            [84.66450889314218, 27.429481943471],
            [84.66478537492496, 27.42952347900784],
            [84.69675493795499, 27.475857232095578],
            [84.6791323273119, 27.524135171449167],
            [84.68008015874318, 27.550970532194146],
            [84.7036822463131, 27.558533162191516],
            [84.73096438299788, 27.582073344070313],
            [84.73618524840383, 27.604774586337157],
            [84.7178986887637, 27.63974644414217],
            [84.73152527315767, 27.65583392437472],
            [84.76754371993019, 27.65676950614305],
            [84.79219159647569, 27.68696244247225],
            [84.79647683810232, 27.70923073518191],
            [84.75748822557733, 27.73568618761019],
            [84.69293074206135, 27.721391150023834],
            [84.67019674432412, 27.73820094812887],
            [84.68124220576652, 27.75948568451226],
            [84.67265044433434, 27.780351985090753],
            [84.63805187282605, 27.782943660580354],
            [84.64571729153545, 27.799795321317543],
            [84.62336874305566, 27.816773956258306],
            [84.6656961033793, 27.8253076537829]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BAGLUNG",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.10741118290912, 28.632230889645374],
            [83.09721862431935, 28.63526964882109],
            [83.05752513005879, 28.62012696748146],
            [83.04282086537881, 28.629983221043222],
            [83.01912727423058, 28.621738137842613],
            [82.9829352340019, 28.630798967200985],
            [82.95733579181358, 28.611509438789042],
            [82.96229192118082, 28.581897739012643],
            [82.98570836283214, 28.569907180801046],
            [82.97381491143808, 28.542346922723173],
            [82.92841919525367, 28.567132175145403],
            [82.91624257290948, 28.564450372569354],
            [82.91406648071049, 28.52760548521363],
            [82.87763630995568, 28.514823766412427],
            [82.91158323375862, 28.480913685575594],
            [82.87930878793306, 28.476361081279414],
            [82.88634727642136, 28.452473879771922],
            [82.91424547709272, 28.43435075735078],
            [82.91591750344793, 28.403468620776568],
            [82.93590433874822, 28.402135153617753],
            [82.95474738312859, 28.360171528616398],
            [82.98008411486015, 28.359429458934127],
            [83.00336908994585, 28.33316526626841],
            [83.06120916177365, 28.317777570514025],
            [83.09691323293517, 28.277353541359542],
            [83.09357092965496, 28.257021357184847],
            [83.13509933075126, 28.25152208374836],
            [83.15539418945684, 28.270792860485702],
            [83.16764352863312, 28.258126510580148],
            [83.1854297939839, 28.270187912506092],
            [83.23378082378531, 28.221869600609782],
            [83.25180542304912, 28.214202063825763],
            [83.273890204279, 28.187567196587853],
            [83.3078569637755, 28.197665016466235],
            [83.33816935385218, 28.19008590126294],
            [83.35274581877142, 28.197830530579115],
            [83.42660819809174, 28.126022923244985],
            [83.47825351926306, 28.130154740596886],
            [83.48906963343266, 28.113464485827926],
            [83.50581940736483, 28.125988401228234],
            [83.55451331384467, 28.11676771034439],
            [83.56220426461282, 28.094536164847117],
            [83.58067898894207, 28.07746105747539],
            [83.60478020480342, 28.110607015357658],
            [83.62963140722903, 28.122457739913433],
            [83.63650416817589, 28.159221049605243],
            [83.6727361646905, 28.223373668858514],
            [83.61039721240793, 28.25888074536521],
            [83.60160395077325, 28.298482743102184],
            [83.56187979778547, 28.31701608156945],
            [83.53556508259, 28.3034358712471],
            [83.52706500137636, 28.31509517817769],
            [83.44265336083214, 28.329542044858666],
            [83.41133878537057, 28.357513270782725],
            [83.37827795040695, 28.355345233036502],
            [83.36357369777977, 28.37072173250419],
            [83.33674173733388, 28.370722401152726],
            [83.31193186970079, 28.35461151491161],
            [83.28592803747945, 28.368584228444732],
            [83.28246198581974, 28.392912133726767],
            [83.29637767663566, 28.41641705561542],
            [83.27300545333644, 28.442494569795006],
            [83.30047021971541, 28.461430296058204],
            [83.27441818870618, 28.493124627880466],
            [83.21945306629208, 28.513856230575694],
            [83.16891743283985, 28.493440995264415],
            [83.13124919935389, 28.496165134739396],
            [83.12630256012386, 28.525969259377003],
            [83.10154048141729, 28.541359483898365],
            [83.10864591562027, 28.584109089710164],
            [83.10741118290912, 28.632230889645374]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "GORKHA",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.55878725049946, 27.857965718833402],
            [84.56742833131156, 27.85893668849704],
            [84.57342799838908, 27.861438759842375],
            [84.57699373530396, 27.864253120266287],
            [84.57694723435837, 27.870931453093156],
            [84.5805371888775, 27.874395383419653],
            [84.58440220437312, 27.87608916565234],
            [84.58996570543091, 27.879740734901976],
            [84.59116170293997, 27.879751402341324],
            [84.59604058536408, 27.876303335140356],
            [84.60168609534723, 27.876111871472162],
            [84.60402137002005, 27.874982635142075],
            [84.60489924501252, 27.872253333186837],
            [84.60813626592271, 27.87157806991896],
            [84.61307615010644, 27.87269554622269],
            [84.61423129051728, 27.875948261077816],
            [84.61795448758099, 27.879728674170078],
            [84.61987727867735, 27.880534506430305],
            [84.6248576239815, 27.87965471072153],
            [84.62737562971142, 27.87722245606886],
            [84.62677825243874, 27.8734619416028],
            [84.62849400968246, 27.871062685421936],
            [84.63380784683855, 27.868901776590537],
            [84.63373215536733, 27.863311468282358],
            [84.63596959006954, 27.860092942749876],
            [84.64190885919409, 27.859828540651847],
            [84.64631535016242, 27.860626908268355],
            [84.64987222883146, 27.858336055461965],
            [84.64688125907638, 27.855135163518405],
            [84.64913232100109, 27.852701481280565],
            [84.65483444412774, 27.852437657077157],
            [84.65872469628192, 27.851106059882],
            [84.66071539241298, 27.84901413873558],
            [84.6615827440873, 27.845650612403013],
            [84.6639860883852, 27.8405943716834],
            [84.66327756204919, 27.836146438703324],
            [84.66109740246792, 27.831010665984287],
            [84.66129109612953, 27.828737814389797],
            [84.6656961033793, 27.8253076537829],
            [84.66769658460782, 27.82232214234929],
            [84.6680331702976, 27.818541482997258],
            [84.67113354627048, 27.817014863143687],
            [84.67686505835955, 27.81586780667219],
            [84.67878896070356, 27.814103111154388],
            [84.68505951807018, 27.81034274675422],
            [84.69308534226732, 27.809458929071123],
            [84.69669663602106, 27.808470598544968],
            [84.70713315971923, 27.806784164800543],
            [84.70821984097655, 27.804937584290144],
            [84.70796088375562, 27.802565496193633],
            [84.7102250506714, 27.800766991218552],
            [84.71382658433946, 27.8004867760566],
            [84.71753191253691, 27.803262833698692],
            [84.71922124650348, 27.80348421527943],
            [84.72195817940833, 27.801170904660676],
            [84.72727611476321, 27.800037536377268],
            [84.72950996527345, 27.796133915635885],
            [84.7318683408073, 27.79568829787553],
            [84.73674845160743, 27.79881866221657],
            [84.73857360761306, 27.803934854135147],
            [84.74492941812723, 27.804415094506407],
            [84.74757531668263, 27.805655215625084],
            [84.75051281122764, 27.80519255367221],
            [84.75384795194591, 27.80646940677917],
            [84.75625878534008, 27.80901242443864],
            [84.75664738231717, 27.81410003381268],
            [84.76011998998403, 27.816939457255344],
            [84.76464360749962, 27.818561444696268],
            [84.76943703997404, 27.815137141948497],
            [84.7685463138606, 27.812421235553455],
            [84.76928351558577, 27.810903283502245],
            [84.77252086991503, 27.80888630288093],
            [84.77484575596387, 27.80853937578103],
            [84.77934833566732, 27.81273745250464],
            [84.78239165799366, 27.81393857965076],
            [84.78193599419248, 27.81577759396527],
            [84.77742122552591, 27.818532961456707],
            [84.77371791474309, 27.8242008025532],
            [84.77311642199614, 27.82947442301094],
            [84.7759579917375, 27.835344639415137],
            [84.77517559839427, 27.83667335078769],
            [84.77035793009584, 27.8395497649611],
            [84.76993215747255, 27.842870883786386],
            [84.76756034151073, 27.843791025283473],
            [84.76803893176107, 27.851729753399997],
            [84.76535010042315, 27.853786546885754],
            [84.76566085153141, 27.8580037175322],
            [84.76373293627431, 27.860092265937293],
            [84.75934468230557, 27.859392298964124],
            [84.75603326164428, 27.86177484394631],
            [84.75423286639288, 27.865360616586866],
            [84.7550929544265, 27.869498106870587],
            [84.75253908356667, 27.875113942352552],
            [84.74695586800637, 27.878591607081027],
            [84.7459192914619, 27.880532926955862],
            [84.74846570003146, 27.883826544549013],
            [84.74883828456555, 27.886500246318622],
            [84.74343863585268, 27.88851259959784],
            [84.741502359597, 27.889980462224823],
            [84.73667936650763, 27.891238126093405],
            [84.7357188793102, 27.893391074484775],
            [84.7376265492123, 27.897047023745447],
            [84.73641465527604, 27.900428603706615],
            [84.73400796371757, 27.904107608126424],
            [84.73322612553538, 27.90745529314049],
            [84.73450938124446, 27.911146167231763],
            [84.73509914572921, 27.91544311729969],
            [84.73423198886451, 27.91697513583858],
            [84.73310916646325, 27.927580161092404],
            [84.73643308650496, 27.92854833456438],
            [84.73641210988959, 27.93079773289364],
            [84.7342048950332, 27.931197599339384],
            [84.73443930447901, 27.933932959798607],
            [84.73793467563277, 27.935335601455197],
            [84.73954863039194, 27.937052841803006],
            [84.7398123954075, 27.94370007333701],
            [84.74206486615945, 27.949140850793327],
            [84.74556887491164, 27.953101647024894],
            [84.74653287517206, 27.955844312092758],
            [84.74584908562883, 27.959866065497053],
            [84.74654522845205, 27.960597760326678],
            [84.75450098371661, 27.96237728792304],
            [84.75823553856914, 27.964299173167472],
            [84.76071124871245, 27.967222956248175],
            [84.76390976321377, 27.967586509970797],
            [84.76915046579852, 27.967297887437713],
            [84.77224149800787, 27.970138991951185],
            [84.77472101838357, 27.971392917854836],
            [84.77515965791083, 27.97312311875793],
            [84.77146171720645, 27.97598878782681],
            [84.77163024644243, 27.977355016921294],
            [84.77536394329867, 27.977088100321648],
            [84.77728436003633, 27.977864623049257],
            [84.77882683044292, 27.981571624648012],
            [84.78406622457187, 27.983299301535215],
            [84.78487136071057, 27.986741940341894],
            [84.78778377739219, 27.98908513447394],
            [84.78806845102115, 27.99529215497967],
            [84.7907656973801, 27.998238830101574],
            [84.79690644962004, 27.998144719801534],
            [84.79741600373576, 28.002369600651775],
            [84.80153018214108, 28.000511521735856],
            [84.80385212533855, 28.000428003734633],
            [84.80984186100592, 28.001965334947347],
            [84.81132914362077, 28.00294470750232],
            [84.81328350486714, 28.00933122320085],
            [84.8101553284344, 28.013884323841566],
            [84.81007893547162, 28.018365229583093],
            [84.81240775625783, 28.02050375673629],
            [84.81310757515774, 28.022462344498635],
            [84.81237674283406, 28.02509269589649],
            [84.81341421577693, 28.027401239077772],
            [84.81261633207365, 28.031523204009517],
            [84.80946805430021, 28.034659611414426],
            [84.8095006040131, 28.03798930812951],
            [84.8121301508874, 28.04295733525933],
            [84.81399274197248, 28.044816548750912],
            [84.8177028438686, 28.046851997573732],
            [84.81850145317783, 28.0480474407774],
            [84.81848602705007, 28.054321508793358],
            [84.82064730764526, 28.05637511667399],
            [84.8212494392692, 28.05906066098039],
            [84.82092512004557, 28.063150512696087],
            [84.8243964869554, 28.06671225242836],
            [84.82499910690962, 28.069359413183772],
            [84.82824816178588, 28.076055999687476],
            [84.82903739594185, 28.080540679230847],
            [84.83151116290877, 28.084512064935854],
            [84.83180959453087, 28.089568205113522],
            [84.83701693609662, 28.09449526595841],
            [84.83704171714739, 28.098016669465842],
            [84.83784686464735, 28.101203983720726],
            [84.83714965969035, 28.104143268995518],
            [84.84033637009973, 28.108967408336525],
            [84.84245863249568, 28.110716320883828],
            [84.84209783204543, 28.114118302842897],
            [84.84437694281226, 28.116712183290183],
            [84.8495973989168, 28.118538913857126],
            [84.85469238741115, 28.122660496993173],
            [84.85904223260259, 28.120072761279737],
            [84.86302061459148, 28.12157735362261],
            [84.86936810568973, 28.12047018071924],
            [84.87148344419658, 28.117663843912915],
            [84.87826722999422, 28.113690631084314],
            [84.88099557285254, 28.11276848377508],
            [84.8841073697081, 28.109513409803668],
            [84.88538165697592, 28.10551204270642],
            [84.88920549775489, 28.10341411650113],
            [84.89244943679229, 28.09997073523555],
            [84.89697838522478, 28.104543379070133],
            [84.90008684538796, 28.106609784960046],
            [84.90364045548934, 28.10523734771483],
            [84.90898884237176, 28.10468990047142],
            [84.91289105060689, 28.1030532071292],
            [84.91629641668374, 28.10275078924566],
            [84.92127906564357, 28.10587114926363],
            [84.9215821433986, 28.10836203662725],
            [84.92049631247512, 28.114167135328245],
            [84.9207677387204, 28.11600847291591],
            [84.92536751499172, 28.12149554990964],
            [84.92881695155054, 28.126320269142],
            [84.93149868106926, 28.12547185091688],
            [84.93543899044715, 28.12662716966938],
            [84.94093123532899, 28.124969809802284],
            [84.94247653121027, 28.12257691492122],
            [84.9449908541216, 28.124059821116816],
            [84.94842019127377, 28.124902411640658],
            [84.95183094179339, 28.12421554224296],
            [84.95716385877662, 28.127223918250976],
            [84.95691722710419, 28.13204433064435],
            [84.95848377235039, 28.133783994509837],
            [84.95702311181066, 28.139163337747892],
            [84.95419966877061, 28.143496396449233],
            [84.95792314662037, 28.147862685959108],
            [84.9571662143709, 28.149462966261936],
            [84.95724451641867, 28.155548916118104],
            [84.96092499907208, 28.15995376391365],
            [84.96059153515239, 28.162018052180674],
            [84.96190980794799, 28.16895933876316],
            [84.96354571148505, 28.172189572452865],
            [84.96537761531064, 28.173701685242143],
            [84.9640761572191, 28.17747366463291],
            [84.96797610290301, 28.18528102003828],
            [84.97335261323505, 28.189127602549814],
            [84.97349824796453, 28.191080190454947],
            [84.97113238018608, 28.193921412869866],
            [84.97062996494681, 28.19911580885611],
            [84.96753106869045, 28.201948626909676],
            [84.96692439185296, 28.205345809094307],
            [84.96416056178407, 28.208871007260754],
            [84.962080985879, 28.21274778387828],
            [84.96473287302129, 28.217364996304017],
            [84.96725369146903, 28.21900076375806],
            [84.96914818442828, 28.222274247563337],
            [84.97136610420311, 28.22397975379973],
            [84.97706196411943, 28.22672297548567],
            [84.9794821452121, 28.22942628177648],
            [84.98255977008326, 28.234509305081037],
            [84.98444343946474, 28.235486109608278],
            [84.98960247516146, 28.242886522038987],
            [84.99160439280801, 28.244706075233502],
            [84.99411508791614, 28.243968211621528],
            [84.99778143708039, 28.240471936210174],
            [84.99912998739534, 28.244473095195836],
            [85.00269068026576, 28.24807897508054],
            [85.00088348440582, 28.250496994788502],
            [85.00077614314273, 28.252370166453094],
            [85.00590639592717, 28.2556193295849],
            [85.00970353380009, 28.255796614772308],
            [85.01348874547553, 28.257474024111403],
            [85.01723477449414, 28.258229002392483],
            [85.021403071229, 28.260313895142012],
            [85.02694212179784, 28.261522250603345],
            [85.0301786836673, 28.270870946392467],
            [85.03123656427249, 28.2729022881458],
            [85.0318253538113, 28.279263908079514],
            [85.03486812733235, 28.28520762009755],
            [85.0350911255394, 28.28738509939957],
            [85.03727053947023, 28.28960187125179],
            [85.03799295825878, 28.29189055034636],
            [85.03708237945455, 28.29549558338001],
            [85.03397450888559, 28.299604521679576],
            [85.03272779726116, 28.302205938395243],
            [85.03324525975896, 28.303860078265064],
            [85.03739757005764, 28.308351546786493],
            [85.04009551711995, 28.310431246054897],
            [85.04265213848338, 28.31539265901971],
            [85.04213536877788, 28.31775402059411],
            [85.04475336070819, 28.321661382799196],
            [85.0466574481176, 28.325867478764813],
            [85.05014230836942, 28.32598282162889],
            [85.05299310573307, 28.327014357140698],
            [85.06605577722917, 28.337567205610632],
            [85.06737071865527, 28.339284890801178],
            [85.07116083644773, 28.340683121904597],
            [85.07331664379166, 28.342270070082982],
            [85.08054926781598, 28.345883192971176],
            [85.08417843355582, 28.34564234487345],
            [85.08836823245419, 28.34637144267705],
            [85.09114665144043, 28.345036344804075],
            [85.09515515556387, 28.344742552511715],
            [85.10311904701672, 28.345564930382785],
            [85.1079553746433, 28.346603985690198],
            [85.12487894853624, 28.396918624320175],
            [85.11651530277554, 28.406283573715978],
            [85.11430044585107, 28.44321993086262],
            [85.09950925319623, 28.45690188137715],
            [85.12449152214744, 28.487856665176025],
            [85.15668109549333, 28.49732689741707],
            [85.1661240998815, 28.53212438821496],
            [85.18546218895051, 28.5331276795246],
            [85.19155755012758, 28.569057448604276],
            [85.17366389309876, 28.59748643864683],
            [85.19801633403458, 28.626744053341096],
            [85.18848692835961, 28.640871857896162],
            [85.15909035759215, 28.643447697461863],
            [85.11537839666393, 28.686587352012584],
            [85.08042282029605, 28.671583891841678],
            [85.0585929412929, 28.6824687882036],
            [84.98584032916699, 28.606263540871392],
            [84.98770595935027, 28.594192859395],
            [84.94901316455667, 28.580370423245114],
            [84.9453465403212, 28.594715742919455],
            [84.91871196428617, 28.595052276882644],
            [84.86527534288913, 28.58044298809356],
            [84.85689741263889, 28.570814167459414],
            [84.78149531063798, 28.60917438111499],
            [84.72518928491654, 28.62067179533326],
            [84.69478683432193, 28.636486797398764],
            [84.70503669022784, 28.670733958313313],
            [84.66834808440915, 28.683451328332715],
            [84.65074692117335, 28.714473832059145],
            [84.62634173074763, 28.73652710806007],
            [84.57168095255606, 28.735224035401096],
            [84.55671523086353, 28.75221442680073],
            [84.52490294848121, 28.742246704506336],
            [84.51472711788556, 28.748620332758975],
            [84.49188190130576, 28.734782047331745],
            [84.483880135339, 28.71778366379152],
            [84.50098967010553, 28.698848733179158],
            [84.51937728219475, 28.69521960923089],
            [84.51257130423986, 28.67462900984652],
            [84.53141779869324, 28.646389331836282],
            [84.5489379786093, 28.58427091516523],
            [84.54787410475794, 28.55453098997782],
            [84.55942733655313, 28.550177321867388],
            [84.5705552316569, 28.51007934750744],
            [84.56813498653005, 28.482833232558562],
            [84.58305885960205, 28.477103180586656],
            [84.59903174266753, 28.451798805360983],
            [84.63036475983455, 28.445002237451703],
            [84.6470200131037, 28.418622094957996],
            [84.69483243651068, 28.360990856896226],
            [84.66953544005989, 28.342395207552865],
            [84.6464257194644, 28.28350838356082],
            [84.6516714050551, 28.26762384873176],
            [84.63290123576424, 28.229775115716105],
            [84.64024637572045, 28.20536026289127],
            [84.61198961400578, 28.17255829456493],
            [84.60963780328697, 28.160088452156195],
            [84.55620875601815, 28.12461145533668],
            [84.54216897971965, 28.10065640884284],
            [84.51366690301285, 28.087974112516488],
            [84.47788186913492, 28.087311549852085],
            [84.47875645250262, 28.05838537602473],
            [84.47762648168042, 28.058197562908813],
            [84.46160075329774, 28.03863777470504],
            [84.45730095071457, 28.00390579507531],
            [84.42780316536657, 27.986116386533485],
            [84.42560393788841, 27.952245047518183],
            [84.47397043967895, 27.939651252654006],
            [84.50145757115479, 27.91562754357805],
            [84.53199500065693, 27.913011006489892],
            [84.54059157786733, 27.876590498756652],
            [84.55878725049946, 27.857965718833402]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KASKI",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.26902738137265, 28.45656928647642],
            [84.2665177408651, 28.4803659481151],
            [84.18915160748067, 28.489145064079032],
            [84.18531340691733, 28.50616501052509],
            [84.16319644360965, 28.511799463654086],
            [84.13665601067314, 28.539417211829594],
            [84.08525010381499, 28.538502685856823],
            [84.0591726598482, 28.563760906982118],
            [84.01347838364335, 28.587219364249503],
            [83.98589714999422, 28.586975711237564],
            [83.96378400377924, 28.60530806509046],
            [83.94447610723044, 28.601484493641756],
            [83.88752708144708, 28.60682288005086],
            [83.87158828092124, 28.613296854562677],
            [83.80929291719536, 28.589752625824463],
            [83.80711153003949, 28.563781099954973],
            [83.82766507397301, 28.541921354779582],
            [83.75440313442722, 28.462436339765084],
            [83.73185690517086, 28.420524650454087],
            [83.75700793871778, 28.393055231878225],
            [83.70062039099479, 28.39856269100193],
            [83.72218183543416, 28.374665265154086],
            [83.72594744301395, 28.349321953060002],
            [83.77446290588988, 28.309068238987408],
            [83.76182866071828, 28.292776162806554],
            [83.76676086718041, 28.273669107877947],
            [83.82065010139972, 28.26017571452862],
            [83.7983874376948, 28.228559640717478],
            [83.80930345386032, 28.220011210308858],
            [83.83782216733671, 28.212038930025702],
            [83.85604457635698, 28.194044409026525],
            [83.89087309627926, 28.19461582048208],
            [83.92359853889491, 28.16845151365482],
            [83.92389793262197, 28.149188961204022],
            [83.9680117810117, 28.14313518116009],
            [84.00254067400225, 28.129960905370787],
            [83.9989762514939, 28.121477936133],
            [84.0064053686438, 28.115833000454867],
            [84.04719090596696, 28.084469350122134],
            [84.06969053383405, 28.077740992350527],
            [84.10306994700724, 28.110336848977933],
            [84.14356437236091, 28.106293524108118],
            [84.15843962721095, 28.09722332707596],
            [84.22599795474467, 28.095908109822993],
            [84.22848112951617, 28.09879638550338],
            [84.21855242604188, 28.132099266742816],
            [84.21881532753878, 28.179892047307934],
            [84.19081448766187, 28.196556785824743],
            [84.20175727244796, 28.199959082296072],
            [84.21417317985303, 28.244263176491415],
            [84.21097962323597, 28.26558568426709],
            [84.18680709059909, 28.291531757871574],
            [84.21765479538223, 28.360252530022397],
            [84.21241180017023, 28.385880297139295],
            [84.24537444732798, 28.43958571866094],
            [84.26902738137265, 28.45656928647642]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "LAMJUNG",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.56813498653005, 28.482833232558562],
            [84.49081631266263, 28.4616079407731],
            [84.46138783880446, 28.50017957908105],
            [84.44181768855891, 28.51026784811599],
            [84.42296566345175, 28.503295999336284],
            [84.41509391982221, 28.471587832903875],
            [84.39812174140724, 28.456602495880606],
            [84.32801593959992, 28.452916043318375],
            [84.29106984658198, 28.445268788132772],
            [84.26902738137265, 28.45656928647642],
            [84.24537444732798, 28.43958571866094],
            [84.21241180017023, 28.385880297139295],
            [84.21765479538223, 28.360252530022397],
            [84.18680709059909, 28.291531757871574],
            [84.21097962323597, 28.26558568426709],
            [84.21417317985303, 28.244263176491415],
            [84.20175727244796, 28.199959082296072],
            [84.19081448766187, 28.196556785824743],
            [84.21881532753878, 28.179892047307934],
            [84.21855242604188, 28.132099266742816],
            [84.22848112951617, 28.09879638550338],
            [84.2257734987503, 28.095188796755405],
            [84.23185506891681, 28.078916556924824],
            [84.30929712582522, 28.107226992398576],
            [84.32797637558532, 28.123580920604926],
            [84.39567947830638, 28.129757661863145],
            [84.44276513558704, 28.10485540831606],
            [84.47753498780908, 28.05850240882058],
            [84.47875645250262, 28.05838537602473],
            [84.47788186913492, 28.087311549852085],
            [84.51366690301285, 28.087974112516488],
            [84.54216897971965, 28.10065640884284],
            [84.55620875601815, 28.12461145533668],
            [84.60963780328697, 28.160088452156195],
            [84.61198961400578, 28.17255829456493],
            [84.64024637572045, 28.20536026289127],
            [84.63290123576424, 28.229775115716105],
            [84.6516714050551, 28.26762384873176],
            [84.6464257194644, 28.28350838356082],
            [84.66953544005989, 28.342395207552865],
            [84.69483243651068, 28.360990856896226],
            [84.6470200131037, 28.418622094957996],
            [84.63036475983455, 28.445002237451703],
            [84.59903174266753, 28.451798805360983],
            [84.58305885960205, 28.477103180586656],
            [84.56813498653005, 28.482833232558562]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MANANG",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.22590951193702, 28.894042706793464],
            [84.18405901093223, 28.900167858272457],
            [84.15599521967317, 28.872494523632533],
            [84.12645986066656, 28.88869550745006],
            [84.09580269544294, 28.8874056453742],
            [84.08907999509353, 28.855433293082363],
            [84.07214192221403, 28.841761535378307],
            [84.05182432285788, 28.842632638150246],
            [84.04736503988907, 28.824348062216767],
            [84.0140258687863, 28.797618812864076],
            [83.99790405745978, 28.812120416851506],
            [83.96915108374797, 28.81654518884108],
            [83.95925080750699, 28.834413704446572],
            [83.93828756963508, 28.824728020668893],
            [83.93324132344199, 28.783320937872716],
            [83.89568842530224, 28.76800664134197],
            [83.8911325813187, 28.743740801644492],
            [83.86412137559729, 28.722790436688047],
            [83.84472364988022, 28.726963380552974],
            [83.81270539080994, 28.71426030758264],
            [83.78926482888782, 28.693257066981648],
            [83.83696016757494, 28.664810221410068],
            [83.85603431811442, 28.644492316195],
            [83.87158828092124, 28.613296854562677],
            [83.88752708144708, 28.60682288005086],
            [83.94447610723044, 28.601484493641756],
            [83.96378400377924, 28.60530806509046],
            [83.98589714999422, 28.586975711237564],
            [84.01347838364335, 28.587219364249503],
            [84.0591726598482, 28.563760906982118],
            [84.08525010381499, 28.538502685856823],
            [84.13665601067314, 28.539417211829594],
            [84.16319644360965, 28.511799463654086],
            [84.18531340691733, 28.50616501052509],
            [84.18915160748067, 28.489145064079032],
            [84.2665177408651, 28.4803659481151],
            [84.26902738137265, 28.45656928647642],
            [84.29106984658198, 28.445268788132772],
            [84.32801593959992, 28.452916043318375],
            [84.39812174140724, 28.456602495880606],
            [84.41509391982221, 28.471587832903875],
            [84.42296566345175, 28.503295999336284],
            [84.44181768855891, 28.51026784811599],
            [84.46138783880446, 28.50017957908105],
            [84.49081631266263, 28.4616079407731],
            [84.56813498653005, 28.482833232558562],
            [84.5705552316569, 28.51007934750744],
            [84.55942733655313, 28.550177321867388],
            [84.54787410475794, 28.55453098997782],
            [84.5489379786093, 28.58427091516523],
            [84.53141779869324, 28.646389331836282],
            [84.51257130423986, 28.67462900984652],
            [84.51937728219475, 28.69521960923089],
            [84.50098967010553, 28.698848733179158],
            [84.483880135339, 28.71778366379152],
            [84.49188190130576, 28.734782047331745],
            [84.43659228725626, 28.76939899277935],
            [84.4453664335517, 28.793223823548548],
            [84.43205911030157, 28.801498664840942],
            [84.43531234328, 28.820604339391206],
            [84.40062523467908, 28.830290147321634],
            [84.40860630712783, 28.853700001181135],
            [84.34456664831792, 28.872151224253496],
            [84.32362052874002, 28.862728569273727],
            [84.2740245095541, 28.88675276302863],
            [84.22590951193702, 28.894042706793464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MUSTANG",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.22590951193702, 28.894042706793464],
            [84.22048092463957, 28.915689818142525],
            [84.24180675779564, 28.976177341138925],
            [84.23949819450213, 28.997440347414646],
            [84.24975683292439, 29.036084014503253],
            [84.22084773726658, 29.05360356645421],
            [84.18968275451829, 29.046996618616554],
            [84.18613310094706, 29.080798009457084],
            [84.2017145293412, 29.10383413267235],
            [84.20059294288183, 29.124467211945586],
            [84.17628448348167, 29.12973252780204],
            [84.16384295780504, 29.194132510977553],
            [84.19973820919428, 29.21398174887363],
            [84.20102186974461, 29.240117105163588],
            [84.14158665137298, 29.236265952676018],
            [84.112399205875, 29.248731899737237],
            [84.12178823643404, 29.273694678268143],
            [84.0857972863476, 29.294615234656217],
            [84.07011041055956, 29.285186420884234],
            [84.04386813037678, 29.300850118776374],
            [84.02433170427679, 29.28875086721312],
            [83.98778333472583, 29.300536449873224],
            [83.99919718831651, 29.31478412334413],
            [83.9648758021854, 29.33108613519712],
            [83.94849723878188, 29.31711110922465],
            [83.9047828425865, 29.325787431425063],
            [83.85608500654696, 29.3094462453037],
            [83.84911836704146, 29.298060691219902],
            [83.8127637606025, 29.302099812374585],
            [83.79387283020654, 29.27351222927298],
            [83.80181686683201, 29.255713063797188],
            [83.76760078916142, 29.240876093909456],
            [83.71128119886899, 29.244628187076948],
            [83.6842430463559, 29.213158247056484],
            [83.65590718303754, 29.2040180323074],
            [83.65740384929119, 29.17617025870581],
            [83.67647941095014, 29.147316909665562],
            [83.6788572201312, 29.125110125547412],
            [83.65168368674597, 29.119569845967987],
            [83.64992322324001, 29.10453733436472],
            [83.62879122340053, 29.106569322828257],
            [83.61500120204674, 29.094374114875148],
            [83.6310722517398, 29.08015830015168],
            [83.61262454946043, 29.036159148020136],
            [83.61698605166991, 29.006237291575705],
            [83.60928997568115, 28.980311756252394],
            [83.59854344167955, 28.985818146691393],
            [83.57896768455056, 28.955149118650333],
            [83.49945567660447, 28.877252230629864],
            [83.50600607937203, 28.856128027953194],
            [83.48165651972403, 28.8447281505169],
            [83.4869302327658, 28.790587846120445],
            [83.50512129743655, 28.79304920502724],
            [83.55357564098152, 28.77083085609906],
            [83.55870561362015, 28.74631281608965],
            [83.52755779940219, 28.688450235888038],
            [83.54309289546539, 28.656140440904394],
            [83.5600066878612, 28.646191484542985],
            [83.5693443178801, 28.5937803163573],
            [83.66337351507177, 28.56698562840868],
            [83.70182654974172, 28.624332069288233],
            [83.70617623492672, 28.6393796280655],
            [83.73019675847581, 28.6553474631469],
            [83.74449222580301, 28.689827637223697],
            [83.78926482888782, 28.693257066981648],
            [83.81270539080994, 28.71426030758264],
            [83.84472364988022, 28.726963380552974],
            [83.86412137559729, 28.722790436688047],
            [83.8911325813187, 28.743740801644492],
            [83.89568842530224, 28.76800664134197],
            [83.93324132344199, 28.783320937872716],
            [83.93828756963508, 28.824728020668893],
            [83.95925080750699, 28.834413704446572],
            [83.96915108374797, 28.81654518884108],
            [83.99790405745978, 28.812120416851506],
            [84.0140258687863, 28.797618812864076],
            [84.04736503988907, 28.824348062216767],
            [84.05182432285788, 28.842632638150246],
            [84.07214192221403, 28.841761535378307],
            [84.08907999509353, 28.855433293082363],
            [84.09580269544294, 28.8874056453742],
            [84.12645986066656, 28.88869550745006],
            [84.15599521967317, 28.872494523632533],
            [84.18405901093223, 28.900167858272457],
            [84.22590951193702, 28.894042706793464]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MYAGDI",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.78926482888782, 28.693257066981648],
            [83.74449222580301, 28.689827637223697],
            [83.73019675847581, 28.6553474631469],
            [83.70617623492672, 28.6393796280655],
            [83.70182654974172, 28.624332069288233],
            [83.66337351507177, 28.56698562840868],
            [83.5693443178801, 28.5937803163573],
            [83.5600066878612, 28.646191484542985],
            [83.54309289546539, 28.656140440904394],
            [83.52755779940219, 28.688450235888038],
            [83.55870561362015, 28.74631281608965],
            [83.55357564098152, 28.77083085609906],
            [83.50512129743655, 28.79304920502724],
            [83.4869302327658, 28.790587846120445],
            [83.48346184881362, 28.782888941051805],
            [83.41044492462908, 28.76067220841868],
            [83.3885178317509, 28.76310578220804],
            [83.36202070530413, 28.7341853031644],
            [83.33128320642457, 28.73821910013584],
            [83.27694414932168, 28.7219839797663],
            [83.22929742345703, 28.742889349643278],
            [83.20640276652934, 28.732621584553726],
            [83.18624452524014, 28.74365439578377],
            [83.1454322119335, 28.747158411360815],
            [83.1436884214196, 28.745205191024496],
            [83.15253568431513, 28.701124198662228],
            [83.12501121611518, 28.664017154579824],
            [83.11478958977642, 28.630846874596614],
            [83.10741118290912, 28.632230889645374],
            [83.10864591562027, 28.584109089710164],
            [83.10154048141729, 28.541359483898365],
            [83.12630256012386, 28.525969259377003],
            [83.13124919935389, 28.496165134739396],
            [83.16891743283985, 28.493440995264415],
            [83.21945306629208, 28.513856230575694],
            [83.27441818870618, 28.493124627880466],
            [83.30047021971541, 28.461430296058204],
            [83.27300545333644, 28.442494569795006],
            [83.29637767663566, 28.41641705561542],
            [83.28246198581974, 28.392912133726767],
            [83.28592803747945, 28.368584228444732],
            [83.31193186970079, 28.35461151491161],
            [83.33674173733388, 28.370722401152726],
            [83.36357369777977, 28.37072173250419],
            [83.37827795040695, 28.355345233036502],
            [83.41133878537057, 28.357513270782725],
            [83.44265336083214, 28.329542044858666],
            [83.52706500137636, 28.31509517817769],
            [83.53556508259, 28.3034358712471],
            [83.56187979778547, 28.31701608156945],
            [83.60160395077325, 28.298482743102184],
            [83.59632563310038, 28.316235727878375],
            [83.56857770382396, 28.34050228150005],
            [83.57062615248135, 28.374754925710256],
            [83.59282947975956, 28.39153103273758],
            [83.62670270327637, 28.3808736397845],
            [83.65811936769084, 28.348135544052514],
            [83.67941007120916, 28.37125505972466],
            [83.68854146718142, 28.397558788888194],
            [83.70062039099479, 28.39856269100193],
            [83.75700793871778, 28.393055231878225],
            [83.73185690517086, 28.420524650454087],
            [83.75440313442722, 28.462436339765084],
            [83.82766507397301, 28.541921354779582],
            [83.80711153003949, 28.563781099954973],
            [83.80929291719536, 28.589752625824463],
            [83.87158828092124, 28.613296854562677],
            [83.85603431811442, 28.644492316195],
            [83.83696016757494, 28.664810221410068],
            [83.78926482888782, 28.693257066981648]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "PARBAT",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.70062039099479, 28.39856269100193],
            [83.68854146718142, 28.397558788888194],
            [83.67941007120916, 28.37125505972466],
            [83.65811936769084, 28.348135544052514],
            [83.62670270327637, 28.3808736397845],
            [83.59282947975956, 28.39153103273758],
            [83.57062615248135, 28.374754925710256],
            [83.56857770382396, 28.34050228150005],
            [83.59632563310038, 28.316235727878375],
            [83.60160395077325, 28.298482743102184],
            [83.61039721240793, 28.25888074536521],
            [83.6727361646905, 28.223373668858514],
            [83.63650416817589, 28.159221049605243],
            [83.62963140722903, 28.122457739913433],
            [83.60478020480342, 28.110607015357658],
            [83.58067898894207, 28.07746105747539],
            [83.56117848903524, 28.06320925703902],
            [83.56156063900882, 28.03576945191377],
            [83.57793841839805, 28.034088033937373],
            [83.60491984936404, 28.012067313431885],
            [83.6273624682088, 28.005778967597884],
            [83.63821978240051, 28.018300175031282],
            [83.65576006038098, 28.0118227745747],
            [83.69335637914148, 28.02685865001086],
            [83.69240804139262, 28.04816338270735],
            [83.71240014871958, 28.06316712547453],
            [83.70938181947355, 28.085367744858324],
            [83.72915140315125, 28.103102860417952],
            [83.70638764428809, 28.119036068242853],
            [83.73078957991068, 28.148063534990715],
            [83.75945821914851, 28.146205945695428],
            [83.76604712179432, 28.170653126953255],
            [83.75625274479631, 28.190270815166357],
            [83.78368494275539, 28.19720696876999],
            [83.80930345386032, 28.220011210308858],
            [83.7983874376948, 28.228559640717478],
            [83.82065010139972, 28.26017571452862],
            [83.76676086718041, 28.273669107877947],
            [83.76182866071828, 28.292776162806554],
            [83.77446290588988, 28.309068238987408],
            [83.72594744301395, 28.349321953060002],
            [83.72218183543416, 28.374665265154086],
            [83.70062039099479, 28.39856269100193]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SYANGJA",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.80930345386032, 28.220011210308858],
            [83.78368494275539, 28.19720696876999],
            [83.75625274479631, 28.190270815166357],
            [83.76604712179432, 28.170653126953255],
            [83.75945821914851, 28.146205945695428],
            [83.73078957991068, 28.148063534990715],
            [83.70638764428809, 28.119036068242853],
            [83.72915140315125, 28.103102860417952],
            [83.70938181947355, 28.085367744858324],
            [83.71240014871958, 28.06316712547453],
            [83.69240804139262, 28.04816338270735],
            [83.69335637914148, 28.02685865001086],
            [83.65576006038098, 28.0118227745747],
            [83.63821978240051, 28.018300175031282],
            [83.6273624682088, 28.005778967597884],
            [83.60491984936404, 28.012067313431885],
            [83.60332033962942, 27.99730082247961],
            [83.57681855969713, 27.974351459833315],
            [83.54997478796494, 27.97682696486565],
            [83.52274272869427, 27.96397679836348],
            [83.48096038158936, 27.980486932777236],
            [83.44070790730653, 27.93547538374721],
            [83.48387818647853, 27.9227366105568],
            [83.51612860128076, 27.937653973629164],
            [83.52408678848114, 27.924158381218806],
            [83.56460331295776, 27.934300676800618],
            [83.61687008814926, 27.9306499024872],
            [83.63334214646966, 27.90011248554915],
            [83.65497683882292, 27.902512461162445],
            [83.67566054725103, 27.92752492920641],
            [83.6810465177154, 27.917416365725845],
            [83.71731600112749, 27.920121719020596],
            [83.74629313979598, 27.899883863535994],
            [83.76101242121157, 27.91010359066],
            [83.80760658854224, 27.87322688419338],
            [83.84550968616087, 27.86852525095122],
            [83.87714778175388, 27.888292053595208],
            [83.92573543423221, 27.868318782093752],
            [83.93670635807653, 27.87761304058933],
            [83.94225977312787, 27.87602939270433],
            [83.94063896059026, 27.893956428516216],
            [83.9741702622146, 27.902707325896927],
            [83.96701771886084, 27.958158244560483],
            [83.98087076419722, 27.976819382953163],
            [83.9802571856447, 27.998223615787836],
            [84.02939764805154, 28.02333282729894],
            [83.99636181283275, 28.035925244013463],
            [83.99564369593574, 28.04697804038315],
            [83.96740545775098, 28.037559407195296],
            [83.9396493817341, 28.068450177090522],
            [84.00481339045271, 28.116002273354677],
            [83.9989762514939, 28.121477936133],
            [84.00254067400225, 28.129960905370787],
            [83.9680117810117, 28.14313518116009],
            [83.92389793262197, 28.149188961204022],
            [83.92359853889491, 28.16845151365482],
            [83.89087309627926, 28.19461582048208],
            [83.85604457635698, 28.194044409026525],
            [83.83782216733671, 28.212038930025702],
            [83.80930345386032, 28.220011210308858]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "TANAHU",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [84.47753498780908, 28.05850240882058],
            [84.44276513558704, 28.10485540831606],
            [84.39567947830638, 28.129757661863145],
            [84.32797637558532, 28.123580920604926],
            [84.30929712582522, 28.107226992398576],
            [84.23185506891681, 28.078916556924824],
            [84.2257734987503, 28.095188796755405],
            [84.22599795474467, 28.095908109822993],
            [84.15843962721095, 28.09722332707596],
            [84.14356437236091, 28.106293524108118],
            [84.10306994700724, 28.110336848977933],
            [84.06969053383405, 28.077740992350527],
            [84.04719090596696, 28.084469350122134],
            [84.0064053686438, 28.115833000454867],
            [84.00481339045271, 28.116002273354677],
            [83.9396493817341, 28.068450177090522],
            [83.96740545775098, 28.037559407195296],
            [83.99564369593574, 28.04697804038315],
            [83.99636181283275, 28.035925244013463],
            [84.02939764805154, 28.02333282729894],
            [83.9802571856447, 27.998223615787836],
            [83.98087076419722, 27.976819382953163],
            [83.96701771886084, 27.958158244560483],
            [83.9741702622146, 27.902707325896927],
            [83.94063896059026, 27.893956428516216],
            [83.94225977312787, 27.87602939270433],
            [83.94522602739346, 27.87145047782738],
            [83.99428158993437, 27.866274247726768],
            [84.0002080193286, 27.854797065658218],
            [84.02854046395633, 27.8564569047074],
            [84.05365163491656, 27.849128010984433],
            [84.07806236683062, 27.869618522114273],
            [84.10036214300015, 27.86657176100016],
            [84.12918632956153, 27.845820017332315],
            [84.20691615100003, 27.850145308000037],
            [84.2514661300001, 27.838842200000045],
            [84.2913547170001, 27.83793795600002],
            [84.305740243, 27.818537370000115],
            [84.30010649899998, 27.792391275000114],
            [84.31387724100006, 27.78543636500001],
            [84.31666919800006, 27.762055647000125],
            [84.33896814169634, 27.744291470110117],
            [84.36074625500004, 27.753613212000175],
            [84.36817691466857, 27.775027639734482],
            [84.39212509780235, 27.775546148836376],
            [84.41717233800006, 27.759223635000126],
            [84.42128466075698, 27.74351474307134],
            [84.42204209786436, 27.741335188169515],
            [84.42448331933166, 27.742444514064484],
            [84.42757209125008, 27.742464361652512],
            [84.43306555791806, 27.74122215255837],
            [84.43861653558005, 27.744088083501907],
            [84.44180425296004, 27.749134109259078],
            [84.44785211550995, 27.757223476744926],
            [84.4498587506886, 27.759060279264595],
            [84.45710118785217, 27.759541890220667],
            [84.46029911369631, 27.757514241189046],
            [84.46475024166305, 27.753205808410037],
            [84.46862608624059, 27.751886404926584],
            [84.47146594433669, 27.75264613020084],
            [84.47198197246482, 27.754097319578957],
            [84.47056002774673, 27.76092943033258],
            [84.46736830562735, 27.767201196429703],
            [84.4600122310287, 27.774183970399942],
            [84.45535502637499, 27.77646235220368],
            [84.4484013157321, 27.77844344255195],
            [84.44301714347067, 27.778115625234584],
            [84.43933658158448, 27.778477111962655],
            [84.43412678425142, 27.78292922481705],
            [84.43127455075867, 27.786888727072256],
            [84.4299842728548, 27.790694488079374],
            [84.43097036498769, 27.800778817101296],
            [84.43065196185644, 27.802074844412886],
            [84.43221058990007, 27.81236046494817],
            [84.4353401397502, 27.81527465077635],
            [84.43848793733686, 27.815740746596628],
            [84.44110612580312, 27.817483206250763],
            [84.4437571302842, 27.82197128182372],
            [84.4458023466482, 27.823171749945164],
            [84.45413503159969, 27.820439436224685],
            [84.46492764052425, 27.818936974863885],
            [84.4661171655158, 27.819826439637843],
            [84.46540522556644, 27.824115576452797],
            [84.46852960985738, 27.824355445039124],
            [84.47434171411517, 27.823629417114667],
            [84.48053250349857, 27.82160904018367],
            [84.48857499969422, 27.820885915714946],
            [84.4927942334398, 27.819420203527915],
            [84.49599247575473, 27.820863969809096],
            [84.50297650825058, 27.81970612041605],
            [84.50803364169816, 27.81689670293998],
            [84.51270445531605, 27.81549643248123],
            [84.51802219238225, 27.816773869222875],
            [84.52050112952641, 27.821901854731898],
            [84.5201772604592, 27.826893712809262],
            [84.52076626516495, 27.831462479782935],
            [84.52503554291104, 27.833415937335072],
            [84.5287125498041, 27.833708740555128],
            [84.53224721444454, 27.833043126602302],
            [84.54111079847023, 27.83339666312763],
            [84.54568745132029, 27.831763300454124],
            [84.54994394832197, 27.8328587243389],
            [84.55517573855418, 27.835815875941293],
            [84.55674880900794, 27.838460511458077],
            [84.55821665525072, 27.847544624729178],
            [84.55698272732916, 27.853690825535672],
            [84.55878725049946, 27.857965718833402],
            [84.54059157786733, 27.876590498756652],
            [84.53199500065693, 27.913011006489892],
            [84.50145757115479, 27.91562754357805],
            [84.47397043967895, 27.939651252654006],
            [84.42560393788841, 27.952245047518183],
            [84.42780316536657, 27.986116386533485],
            [84.45730095071457, 28.00390579507531],
            [84.46160075329774, 28.03863777470504],
            [84.47762648168042, 28.058197562908813],
            [84.47753498780908, 28.05850240882058]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "NAWALPARASI EAST",
        Province: "Gandaki"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.8955577750001, 27.437822915000083],
            [83.9333097760001, 27.450614694000024],
            [83.93501651685206, 27.450904319768977],
            [83.9393558663014, 27.455177524663927],
            [83.94293185403343, 27.461555442983684],
            [83.94460293000009, 27.465847674000088],
            [83.9444896194331, 27.469870664570248],
            [83.94365547500013, 27.4735820250001],
            [83.94136328835779, 27.4788721457803],
            [83.94011136400007, 27.484627538000154],
            [83.93854793900016, 27.48843323400007],
            [83.93875429000008, 27.491587702000118],
            [83.94114721683329, 27.49390822145103],
            [83.95778415540124, 27.500421501908953],
            [83.96184201700015, 27.50269929100017],
            [83.96653005733782, 27.506265499400968],
            [83.96872677559672, 27.509767820750465],
            [83.96939463800004, 27.516110505000142],
            [83.9679028286031, 27.51936375735719],
            [83.96488365465265, 27.523259686735866],
            [83.95653632684318, 27.527131421852268],
            [83.95429180400009, 27.527611305000107],
            [83.95093339500016, 27.526515854000138],
            [83.947661074, 27.520163129000082],
            [83.94611153878385, 27.518364242002253],
            [83.94192860400017, 27.51578812700012],
            [83.93538620300018, 27.513544669999987],
            [83.93010760000016, 27.513097632000154],
            [83.92564528600008, 27.514785288000155],
            [83.92200925142782, 27.518122671415654],
            [83.92145830400005, 27.52017111500004],
            [83.92212556400017, 27.52496612900012],
            [83.92005456100014, 27.52644284000013],
            [83.91877283512147, 27.533084694887666],
            [83.92000204300007, 27.541185570000152],
            [83.92445131265366, 27.544642460170056],
            [83.93234513700008, 27.545379810999975],
            [83.93941893239206, 27.54213851739005],
            [83.94520027100009, 27.540459947000045],
            [83.94796420856213, 27.54018124545794],
            [83.95130681900014, 27.541326397000148],
            [83.95489302969352, 27.543608744631868],
            [83.95775726361555, 27.548451682657458],
            [83.96232863800003, 27.54955181800011],
            [83.96919683100009, 27.550591326000188],
            [83.97464954519734, 27.549633582839537],
            [83.97831958224629, 27.54773664882373],
            [83.98140955900004, 27.54527098800014],
            [83.98651583981543, 27.54374853144434],
            [83.99201293400012, 27.543478183000047],
            [83.99594908400019, 27.544259046000093],
            [83.99792859423835, 27.545452683591286],
            [84.0055612340002, 27.544823076],
            [84.00810266100007, 27.544019686000183],
            [84.01436400384281, 27.539881127649966],
            [84.01959860617299, 27.538152153955185],
            [84.02538373818125, 27.5371516720714],
            [84.0311750912047, 27.537265565961786],
            [84.03648852261149, 27.538769232552067],
            [84.03885425854108, 27.540582665264022],
            [84.04178753800016, 27.54534938200004],
            [84.0431531708053, 27.550854370396763],
            [84.04574391000011, 27.55413421000003],
            [84.0497574416936, 27.55634175523021],
            [84.05250656079357, 27.556564117877024],
            [84.05777718025654, 27.55496230616013],
            [84.05991076905461, 27.553397782999664],
            [84.06442714181334, 27.55139893183001],
            [84.06701900300015, 27.55181511300009],
            [84.07087082300018, 27.55743591300012],
            [84.07466213158878, 27.559568402274007],
            [84.07769205131713, 27.559298241106877],
            [84.08347002, 27.55768614200008],
            [84.09026948246063, 27.554721624571627],
            [84.10068518100002, 27.553906741000105],
            [84.10730314645289, 27.5517695517217],
            [84.10942646643636, 27.551790459170423],
            [84.1147135495857, 27.553347558234226],
            [84.11940020857979, 27.556407670402024],
            [84.11980002700011, 27.560042225000075],
            [84.12564694021289, 27.56612230653959],
            [84.12619507127346, 27.571369959688628],
            [84.12911734599999, 27.57686359600001],
            [84.13526807000017, 27.584590053000113],
            [84.1416593063774, 27.58887742836251],
            [84.14977632601651, 27.597404451880294],
            [84.15979332700016, 27.603627392000078],
            [84.16426285700015, 27.606997592000027],
            [84.16798086, 27.610458773000175],
            [84.17461806206477, 27.617751918308144],
            [84.18408544876044, 27.623766199374053],
            [84.19082864185097, 27.628479473091055],
            [84.19497956439936, 27.63018621303448],
            [84.20104644234826, 27.63431330084956],
            [84.20591378900008, 27.63841707199998],
            [84.2121768520002, 27.640688712000042],
            [84.21817541594594, 27.643605806935042],
            [84.2252273466363, 27.644510576929044],
            [84.23059827094005, 27.646148823539832],
            [84.2349885320001, 27.64929796700011],
            [84.2410545444434, 27.65185518178822],
            [84.25076176175199, 27.654186732638376],
            [84.25620980600019, 27.651054131000137],
            [84.25853555100014, 27.651473941000063],
            [84.26149529932214, 27.655852328515152],
            [84.2663203680001, 27.65925235700007],
            [84.2702283480001, 27.662568242000077],
            [84.27564742200013, 27.663581921000173],
            [84.2868641068494, 27.666607360312415],
            [84.29237165170827, 27.66836700941223],
            [84.297005576, 27.670556991000126],
            [84.30577137, 27.675986759000182],
            [84.30965812914714, 27.67790498192637],
            [84.32065048100003, 27.684443410000085],
            [84.32381692299998, 27.688370916000167],
            [84.32406605177327, 27.69226460626023],
            [84.32604400900004, 27.694489148000116],
            [84.32993418226904, 27.69656693778895],
            [84.33736479400017, 27.69897672800016],
            [84.34311847000015, 27.702064912000083],
            [84.34867289800019, 27.703185958000063],
            [84.3546787971722, 27.7011987451434],
            [84.36041509793448, 27.69804551346612],
            [84.36261676936095, 27.695600777202106],
            [84.36392061259131, 27.690349354648475],
            [84.36519672092854, 27.688606438078523],
            [84.36967831898296, 27.684767973486725],
            [84.37291237936333, 27.682900166469498],
            [84.37811596209352, 27.681322490003303],
            [84.38048915400014, 27.681598021000127],
            [84.38695964070625, 27.685551073694388],
            [84.39450071100009, 27.68912174400009],
            [84.40819918166734, 27.696457644752286],
            [84.41722192100013, 27.6999933620001],
            [84.41948919427327, 27.70043998772247],
            [84.4279547348807, 27.70376006194285],
            [84.43066663124768, 27.705888294912413],
            [84.43136028106794, 27.70967658467647],
            [84.43074855700007, 27.713195940000162],
            [84.42743980120115, 27.722695838541316],
            [84.42574147756153, 27.73062941076842],
            [84.42351807284953, 27.735791679590058],
            [84.42128466075698, 27.74351474307134],
            [84.41717233800006, 27.759223635000126],
            [84.39212509780235, 27.775546148836376],
            [84.36817691466857, 27.775027639734482],
            [84.36074625500004, 27.753613212000175],
            [84.33896814169634, 27.744291470110117],
            [84.31666919800006, 27.762055647000125],
            [84.31387724100006, 27.78543636500001],
            [84.30010649899998, 27.792391275000114],
            [84.305740243, 27.818537370000115],
            [84.2913547170001, 27.83793795600002],
            [84.2514661300001, 27.838842200000045],
            [84.20691615100003, 27.850145308000037],
            [84.12918632956153, 27.845820017332315],
            [84.10036214300015, 27.86657176100016],
            [84.07806236683062, 27.869618522114273],
            [84.05365163491656, 27.849128010984433],
            [84.02854046395633, 27.8564569047074],
            [84.02473438681166, 27.81827799660894],
            [84.00043718299997, 27.788773305000063],
            [83.99235142600003, 27.75363674200014],
            [83.94742093500014, 27.745488682000087],
            [83.9241780678343, 27.726863897295846],
            [83.88355250800004, 27.73509579600011],
            [83.8453102868528, 27.735112475732333],
            [83.8462830740001, 27.709538995000003],
            [83.82106797100005, 27.710727076000182],
            [83.7823317359839, 27.725095762843186],
            [83.72949518658329, 27.709617261268495],
            [83.6819835550001, 27.720966658000066],
            [83.70446834200004, 27.682141509000132],
            [83.69624228994053, 27.663137360045823],
            [83.69528931000013, 27.66217180900003],
            [83.72518907400007, 27.67250481100018],
            [83.74479206900014, 27.666872360000184],
            [83.81185497500013, 27.66241512800019],
            [83.80515670300014, 27.620861229000013],
            [83.78989787100011, 27.60669884800018],
            [83.80818271000015, 27.588749491000158],
            [83.82372626800014, 27.592098105000105],
            [83.84104061100004, 27.574208666000118],
            [83.83731265200004, 27.551580407000188],
            [83.8506938590001, 27.55905474200017],
            [83.8827431380002, 27.523379377000083],
            [83.87568150100009, 27.510658922000175],
            [83.89725214000003, 27.500288427000157],
            [83.87475813700007, 27.489723986000115],
            [83.88512460900017, 27.481030299000167],
            [83.8955577750001, 27.437822915000083]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "ARGHAKHANCHI",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.05103547598031, 28.111629125173636],
            [83.02708459163836, 28.099692063578992],
            [83.00252756372757, 28.114003279763622],
            [82.98512993420677, 28.073862205537065],
            [82.96454625693237, 28.05301925790706],
            [82.9766597934046, 28.02323234039151],
            [82.9736143728851, 28.00673211801151],
            [82.95434505512682, 27.9976804230815],
            [82.99805403942908, 27.978667715416528],
            [83.01661994173851, 27.956990832888653],
            [83.01591992459213, 27.928337001368224],
            [82.9430391357566, 27.931072598480295],
            [82.91578157777064, 27.904190794759987],
            [82.89534457545922, 27.914153161039255],
            [82.87788076450188, 27.901487010446232],
            [82.82431605303815, 27.905701127298425],
            [82.80524085124293, 27.877780286327393],
            [82.80576693530855, 27.87610413418938],
            [82.80387566535713, 27.873682291907063],
            [82.80552860306754, 27.87194213587163],
            [82.80929947517316, 27.871592104101023],
            [82.8090461989288, 27.86813753215507],
            [82.8054548471433, 27.86751451150338],
            [82.80242636681201, 27.868443263375983],
            [82.79795483438393, 27.866728319054317],
            [82.79327999213899, 27.86593138740088],
            [82.79210524814212, 27.86448763997123],
            [82.79584319923336, 27.861733731903666],
            [82.8068269618663, 27.859439795281435],
            [82.8076596178478, 27.85780511662327],
            [82.80522620001076, 27.8559246086859],
            [82.80318176838267, 27.855559598785092],
            [82.79572123142799, 27.856836106013905],
            [82.79309707802844, 27.85644700595239],
            [82.79036615135281, 27.854696181796985],
            [82.78927209433589, 27.85055636221701],
            [82.78377427011043, 27.846889558092634],
            [82.7811589081122, 27.84315574080901],
            [82.77971716000633, 27.8423375429229],
            [82.7747205794012, 27.842131498771856],
            [82.77101320599641, 27.84123126098049],
            [82.7631712928776, 27.838143436702758],
            [82.75883028268325, 27.837102355594148],
            [82.7541241118219, 27.83419927443363],
            [82.75225310632766, 27.829668443043754],
            [82.74877497472198, 27.82809391361767],
            [82.7485098235104, 27.82311279098692],
            [82.75278173484669, 27.821827414418873],
            [82.75709910433034, 27.817706164300066],
            [82.75686305764252, 27.813643784696456],
            [82.75952539842685, 27.811439127684555],
            [82.76304209410756, 27.810542804283433],
            [82.76756571397821, 27.81084992201967],
            [82.77001443868429, 27.809883018390444],
            [82.77342440994269, 27.804832867174664],
            [82.77945848876868, 27.80279990257665],
            [82.78462148084719, 27.796355118339005],
            [82.78821598562351, 27.79570533416105],
            [82.78662567417943, 27.802109621216076],
            [82.78827359619086, 27.80466878561417],
            [82.7908212371625, 27.805692727533486],
            [82.79483713574263, 27.805766151532296],
            [82.7988232895542, 27.8022072189614],
            [82.79758361359556, 27.796533177840548],
            [82.80014481865426, 27.794027358359212],
            [82.80431674216611, 27.795056134910087],
            [82.8063242223183, 27.795120844364835],
            [82.84218556484907, 27.790580256661205],
            [82.9021656218653, 27.792958125971822],
            [82.92991081180467, 27.784372921337507],
            [82.96069243169501, 27.795646512838328],
            [82.99647319516245, 27.792892516146736],
            [83.01618088077564, 27.809947739496845],
            [83.06677853022644, 27.75864187576665],
            [83.09344128089987, 27.783022407859956],
            [83.12148359880476, 27.761888041302463],
            [83.18552918043581, 27.75417396491611],
            [83.21049838421037, 27.758950573409244],
            [83.21235725359226, 27.759362687418275],
            [83.24610151779032, 27.751268592562038],
            [83.2558549908736, 27.7671526623208],
            [83.25846056928168, 27.785046509347282],
            [83.23385549573432, 27.822259895634556],
            [83.24817634456856, 27.831915846535743],
            [83.29028946993738, 27.82832590230905],
            [83.33051638910119, 27.877335769422032],
            [83.30892967465326, 27.88964015011027],
            [83.29749262910273, 27.910841553794285],
            [83.30897756344469, 27.926164323044315],
            [83.28183807416504, 27.925808901789544],
            [83.30106298939856, 27.967557372816227],
            [83.28017571782416, 27.967740742241727],
            [83.25408787408053, 27.986031510372136],
            [83.23366284620266, 28.02086766198251],
            [83.22986278918039, 28.048381282493178],
            [83.1839129757524, 28.087659282525486],
            [83.15037460309873, 28.05978591086229],
            [83.12430808220333, 28.070832152931608],
            [83.1256757810255, 28.084176015189577],
            [83.10230241480028, 28.077241377244384],
            [83.09875744889511, 28.09462748682865],
            [83.07443577736767, 28.090885356563174],
            [83.05103547598031, 28.111629125173636]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BANKE",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.74378343059085, 28.31305918482805],
            [81.72488336814995, 28.316296701679267],
            [81.68583703529528, 28.33789169825345],
            [81.66214176310527, 28.287377930655936],
            [81.64111679719849, 28.27946507625092],
            [81.64698722495258, 28.266097761324595],
            [81.59080220169457, 28.235023028966786],
            [81.56160253720537, 28.20733377117284],
            [81.52774017442452, 28.19195831821394],
            [81.51713933772943, 28.162385910642318],
            [81.49878117602343, 28.14410992428849],
            [81.51017454747026, 28.091889633192228],
            [81.5238290479279, 28.08615289831468],
            [81.50496712181597, 28.068930744609197],
            [81.5473610963904, 28.049271157923254],
            [81.64353146883116, 27.993889609677645],
            [81.69839385617696, 27.98827051921169],
            [81.80477122692275, 27.904003258887084],
            [81.82121227261051, 27.903527265631666],
            [81.88555232085534, 27.8570649258281],
            [81.9262249318921, 27.85984345106595],
            [81.92529917166169, 27.875553936086234],
            [81.96829162854301, 27.929019407526592],
            [82.06273086302491, 27.921785251918106],
            [82.06797760239716, 27.925085706113716],
            [82.06524840266684, 27.92533245991877],
            [82.06548657692227, 27.928156427740305],
            [82.06799265321028, 27.929392248910148],
            [82.06893267721127, 27.93160705823422],
            [82.067444070167, 27.935018350717844],
            [82.0669739302686, 27.938813734207468],
            [82.06556267422901, 27.939279065937367],
            [82.06485318193035, 27.94250027295735],
            [82.06641697726661, 27.943709441763037],
            [82.06732464040964, 27.95043224105267],
            [82.06915541710805, 27.952327995916058],
            [82.07100911868297, 27.951853134191296],
            [82.07509791134584, 27.952627500815],
            [82.07891972426341, 27.95261801553434],
            [82.08383696208058, 27.951645230193947],
            [82.08805855414771, 27.949995772714146],
            [82.09012582461077, 27.95103711694501],
            [82.09107170395309, 27.95332192357677],
            [82.09523675976965, 27.955318789643105],
            [82.0952793897476, 27.953657197266892],
            [82.09717445186759, 27.95017898218296],
            [82.10288551313289, 27.948689753705214],
            [82.10700740793438, 27.950859973587495],
            [82.10988079206304, 27.950508242225197],
            [82.114307222504, 27.95270359963459],
            [82.11379666578877, 27.950295540246778],
            [82.11955610911787, 27.94882518574922],
            [82.12315620066086, 27.950161387770695],
            [82.12796778816778, 27.954798588936903],
            [82.13046395534052, 27.958342422110608],
            [82.13211223883019, 27.958654925963597],
            [82.13332508934985, 27.96222135694471],
            [82.13671210201463, 27.961222042953768],
            [82.14131204988175, 27.958769478722377],
            [82.14710955436965, 27.962084223804407],
            [82.15019904642007, 27.961929783855506],
            [82.15678235652352, 27.960293516446875],
            [82.16286925388292, 27.95657451548105],
            [82.16773611795493, 27.95549336696811],
            [82.17417186823785, 27.95236830178716],
            [82.17713498938095, 27.95177253530753],
            [82.1828562647818, 27.951862248778912],
            [82.18595171514487, 27.9527997318579],
            [82.19080500145674, 27.953285106488774],
            [82.19268665744184, 27.954424422805392],
            [82.19156777444486, 27.957061320759088],
            [82.19239104984722, 27.959432834677486],
            [82.191643992927, 27.960911905624034],
            [82.19229998014836, 27.964012978924917],
            [82.19015552129582, 27.967787663761126],
            [82.18997938000244, 27.97014663259513],
            [82.19320761171707, 27.972532336194256],
            [82.19208613620567, 27.97874207888705],
            [82.19018662893426, 27.981301480643484],
            [82.18904992532642, 27.98582146715298],
            [82.18716320214861, 27.98822487448289],
            [82.18595831001274, 27.99510216503733],
            [82.18772234951733, 27.99994979209086],
            [82.19074989815921, 28.003504520772694],
            [82.18987344390573, 28.0061729285095],
            [82.18928369841302, 28.012157269794077],
            [82.18968259801619, 28.014223105920284],
            [82.1931738861074, 28.018142210173217],
            [82.19418034371417, 28.023705400645824],
            [82.19150549349554, 28.030975743360784],
            [82.18920931959707, 28.033033552941482],
            [82.18328026088388, 28.03393544093505],
            [82.18025250513573, 28.0361722576262],
            [82.17877155856456, 28.04107341293176],
            [82.17627905971649, 28.042346762705627],
            [82.17437900781297, 28.045731114109387],
            [82.1729377763482, 28.046772613665617],
            [82.16514787562637, 28.04567437090736],
            [82.16244498327113, 28.046026614949113],
            [82.15600277584099, 28.04812648056661],
            [82.1517044856606, 28.050010793179904],
            [82.14707524234727, 28.05317394092688],
            [82.14014326520292, 28.055983823714833],
            [82.13360403992685, 28.057747966126474],
            [82.12988710699601, 28.06117597672499],
            [82.1252144230226, 28.063417786820835],
            [82.12315476843162, 28.063137404864936],
            [82.11854716185482, 28.06740941199314],
            [82.11400824735794, 28.069512963819403],
            [82.1104677537142, 28.07028131903513],
            [82.10746305247072, 28.072779864721053],
            [82.10239467426821, 28.07270605126693],
            [82.09953560536954, 28.073665175087736],
            [82.09574537600726, 28.076740967049666],
            [82.09092350401326, 28.07798628846218],
            [82.08832982754994, 28.07950030234291],
            [82.0852479711606, 28.077061297950916],
            [82.08294537506403, 28.07890728660545],
            [82.07913880061656, 28.080032468294],
            [82.07726000492644, 28.079519965824332],
            [82.07471194921052, 28.080925052841966],
            [82.07150258057794, 28.083925734444207],
            [82.065977058372, 28.084880135819713],
            [82.06268429835687, 28.086753128614674],
            [82.05833390787754, 28.0881111123698],
            [82.05623521257692, 28.09050280218477],
            [82.05384293856133, 28.091608674562508],
            [82.0502271196935, 28.089993250757523],
            [82.043994655104, 28.09523789471782],
            [82.04114163464392, 28.095895831667697],
            [82.03695079466915, 28.095076000943408],
            [82.03210200952236, 28.096485028362924],
            [82.02300896275658, 28.09844738919858],
            [82.01954959903993, 28.10110340370657],
            [82.01927706482498, 28.105312763072533],
            [82.01750769050817, 28.10828790357327],
            [82.0182333928387, 28.111036576735646],
            [82.01647878155275, 28.116191642792412],
            [82.01715002216703, 28.119494875740397],
            [82.01855220674332, 28.12122460276754],
            [82.01819501196641, 28.12521640928283],
            [82.01855342756976, 28.132190946486865],
            [82.0160054390922, 28.13894612017877],
            [82.0145331426228, 28.14023153538913],
            [82.01216023088568, 28.144907214630283],
            [82.00645206501014, 28.152168158413183],
            [82.00257919113001, 28.158071074674233],
            [81.99923700359679, 28.161574009770373],
            [81.99818492239235, 28.163677427254434],
            [81.9854531973118, 28.17652274755995],
            [81.93869342744354, 28.201657018486152],
            [81.91888504969128, 28.226870621633797],
            [81.91313540446794, 28.229031985240777],
            [81.90931000033332, 28.23228112169369],
            [81.90369644398852, 28.232349978800958],
            [81.90070109682179, 28.233315331519144],
            [81.89617747350782, 28.233473567236253],
            [81.89240609659718, 28.235807948763],
            [81.88863752418104, 28.236901798286954],
            [81.88709136206569, 28.240367958430795],
            [81.88290031163383, 28.24354886000968],
            [81.87861684698896, 28.24119357159151],
            [81.87667927578019, 28.239194828958063],
            [81.87196191351522, 28.239772689085378],
            [81.86375683818453, 28.238418439603006],
            [81.86057358882798, 28.23896280144039],
            [81.86356604596511, 28.243373272073796],
            [81.8648627759551, 28.24886213246413],
            [81.86149856205162, 28.255115707786256],
            [81.85853872921065, 28.25673867288754],
            [81.85389187975255, 28.260476667503006],
            [81.8503133364506, 28.265771069699273],
            [81.84513547958491, 28.269409752430192],
            [81.84383673432883, 28.271814302189036],
            [81.83806093863546, 28.272583030373674],
            [81.82953537912427, 28.275052871550816],
            [81.82624733370369, 28.277015716281436],
            [81.82208902313184, 28.276835490814673],
            [81.8172804567068, 28.278515710202605],
            [81.81453032303482, 28.278807897646576],
            [81.80916751461028, 28.28287964097271],
            [81.80584918211764, 28.28251723465136],
            [81.80444559901044, 28.280367832649027],
            [81.79810654015687, 28.27366594478316],
            [81.79330614383095, 28.27402331035993],
            [81.79143927801582, 28.277947460117066],
            [81.7922401658351, 28.281318435829036],
            [81.79090986330378, 28.283990421860466],
            [81.79069591262163, 28.286507061839202],
            [81.78924976405476, 28.28832054351575],
            [81.78803634393985, 28.2934053279284],
            [81.78524468376024, 28.295629961056452],
            [81.78028311057247, 28.295227495726174],
            [81.77645583126959, 28.297992565424483],
            [81.77378691684815, 28.297463326641825],
            [81.77092624022853, 28.29986060517167],
            [81.7674473762093, 28.300793501512132],
            [81.76423291673947, 28.305652760918225],
            [81.76122888775207, 28.309231703739012],
            [81.75380043530971, 28.30749670023286],
            [81.74915471150294, 28.31050043630255],
            [81.74806904865441, 28.312487606097363],
            [81.74378343059085, 28.31305918482805]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BARDIYA",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.50496712181597, 28.068930744609197],
            [81.5238290479279, 28.08615289831468],
            [81.51017454747026, 28.091889633192228],
            [81.49878117602343, 28.14410992428849],
            [81.51713933772943, 28.162385910642318],
            [81.52774017442452, 28.19195831821394],
            [81.56160253720537, 28.20733377117284],
            [81.59080220169457, 28.235023028966786],
            [81.64698722495258, 28.266097761324595],
            [81.64111679719849, 28.27946507625092],
            [81.66214176310527, 28.287377930655936],
            [81.68583703529528, 28.33789169825345],
            [81.72488336814995, 28.316296701679267],
            [81.74378343059085, 28.31305918482805],
            [81.745088747022, 28.31328676227423],
            [81.74352174139904, 28.31905694015791],
            [81.74605582119734, 28.323719667724347],
            [81.74634720900872, 28.32635281772573],
            [81.75114238841626, 28.330935692868962],
            [81.75209123147324, 28.33446409750742],
            [81.75127494167332, 28.337748237867494],
            [81.75165626002273, 28.343721507076758],
            [81.75510416772252, 28.350459592536783],
            [81.75780456632825, 28.352977095539288],
            [81.75947271634283, 28.356133412073547],
            [81.760069710563, 28.35912595594625],
            [81.76535539142957, 28.365381384810945],
            [81.76671100285343, 28.37017664705284],
            [81.76429256464317, 28.371076782181103],
            [81.72957156617528, 28.378806363304545],
            [81.67430745341642, 28.417638238946417],
            [81.65885095905294, 28.415250445885356],
            [81.55734774623998, 28.495009306963553],
            [81.53904216959428, 28.494609357033156],
            [81.51151114904391, 28.524785142179788],
            [81.47424091416798, 28.54203618673284],
            [81.46639997900661, 28.553252795420242],
            [81.43466207264463, 28.56212243904505],
            [81.42494285255287, 28.58714667949337],
            [81.4008395451937, 28.60694365698622],
            [81.36252839849384, 28.624070262536588],
            [81.31694844432897, 28.6364070958286],
            [81.31838245295593, 28.669687583639895],
            [81.2853381305857, 28.6733143244241],
            [81.28631595311658, 28.645498922526688],
            [81.24865196771826, 28.594146902537414],
            [81.16745113429685, 28.52332357857457],
            [81.15277240842477, 28.501888696268104],
            [81.08939576154222, 28.454679721465617],
            [81.08499319775686, 28.43734514966448],
            [81.05920102890062, 28.40852462668337],
            [81.06738595382062, 28.39451096516038],
            [81.08941841374792, 28.38216225882725],
            [81.21070969974276, 28.360641581289155],
            [81.2346232881004, 28.324711333118202],
            [81.23278292150121, 28.28904636014557],
            [81.29196372472254, 28.222942965298227],
            [81.32064825749406, 28.197326567819875],
            [81.32130243760908, 28.168896201278137],
            [81.30450839990502, 28.168575832112516],
            [81.33450405017119, 28.14026194787989],
            [81.37135053733569, 28.142454710062847],
            [81.37521958336937, 28.177032130383164],
            [81.41486177079409, 28.17433000674988],
            [81.4459281584634, 28.16138634547504],
            [81.45000168886513, 28.14094884500133],
            [81.48410138848003, 28.118319302740463],
            [81.47846753893518, 28.082609522380576],
            [81.50496712181597, 28.068930744609197]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DANG",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.39683591248212, 28.16892247132062],
            [82.39251027255727, 28.189361589695007],
            [82.3671459407322, 28.189868551913943],
            [82.33757017516197, 28.22355645841274],
            [82.3129610683528, 28.2232109254123],
            [82.30135796432153, 28.24386163920795],
            [82.27501713727652, 28.24826303735655],
            [82.23318331676829, 28.23999106116638],
            [82.21691184584807, 28.249562943170126],
            [82.19869542019897, 28.242958156980205],
            [82.16842591722069, 28.251398897260174],
            [82.14373313889622, 28.237192033325456],
            [82.13325915166666, 28.2512325006103],
            [82.1007316654995, 28.259024773353335],
            [82.06831911290314, 28.237063243061357],
            [82.05213165234288, 28.241056647280715],
            [82.02299960660437, 28.20073862202695],
            [82.00761403051011, 28.15110995943164],
            [82.01216023088568, 28.144907214630283],
            [82.0145331426228, 28.14023153538913],
            [82.0160054390922, 28.13894612017877],
            [82.01855342756976, 28.132190946486865],
            [82.01819501196641, 28.12521640928283],
            [82.01855220674332, 28.12122460276754],
            [82.01715002216703, 28.119494875740397],
            [82.01647878155275, 28.116191642792412],
            [82.0182333928387, 28.111036576735646],
            [82.01750769050817, 28.10828790357327],
            [82.01927706482498, 28.105312763072533],
            [82.01954959903993, 28.10110340370657],
            [82.02300896275658, 28.09844738919858],
            [82.03210200952236, 28.096485028362924],
            [82.03695079466915, 28.095076000943408],
            [82.04114163464392, 28.095895831667697],
            [82.043994655104, 28.09523789471782],
            [82.0502271196935, 28.089993250757523],
            [82.05384293856133, 28.091608674562508],
            [82.05623521257692, 28.09050280218477],
            [82.05833390787754, 28.0881111123698],
            [82.06268429835687, 28.086753128614674],
            [82.065977058372, 28.084880135819713],
            [82.07150258057794, 28.083925734444207],
            [82.07471194921052, 28.080925052841966],
            [82.07726000492644, 28.079519965824332],
            [82.07913880061656, 28.080032468294],
            [82.08294537506403, 28.07890728660545],
            [82.0852479711606, 28.077061297950916],
            [82.08832982754994, 28.07950030234291],
            [82.09092350401326, 28.07798628846218],
            [82.09574537600726, 28.076740967049666],
            [82.09953560536954, 28.073665175087736],
            [82.10239467426821, 28.07270605126693],
            [82.10746305247072, 28.072779864721053],
            [82.1104677537142, 28.07028131903513],
            [82.11400824735794, 28.069512963819403],
            [82.11854716185482, 28.06740941199314],
            [82.12315476843162, 28.063137404864936],
            [82.1252144230226, 28.063417786820835],
            [82.12988710699601, 28.06117597672499],
            [82.13360403992685, 28.057747966126474],
            [82.14014326520292, 28.055983823714833],
            [82.14707524234727, 28.05317394092688],
            [82.1517044856606, 28.050010793179904],
            [82.15600277584099, 28.04812648056661],
            [82.16244498327113, 28.046026614949113],
            [82.16514787562637, 28.04567437090736],
            [82.1729377763482, 28.046772613665617],
            [82.17437900781297, 28.045731114109387],
            [82.17627905971649, 28.042346762705627],
            [82.17877155856456, 28.04107341293176],
            [82.18025250513573, 28.0361722576262],
            [82.18328026088388, 28.03393544093505],
            [82.18920931959707, 28.033033552941482],
            [82.19150549349554, 28.030975743360784],
            [82.19418034371417, 28.023705400645824],
            [82.1931738861074, 28.018142210173217],
            [82.18968259801619, 28.014223105920284],
            [82.18928369841302, 28.012157269794077],
            [82.18987344390573, 28.0061729285095],
            [82.19074989815921, 28.003504520772694],
            [82.18772234951733, 27.99994979209086],
            [82.18595831001274, 27.99510216503733],
            [82.18716320214861, 27.98822487448289],
            [82.18904992532642, 27.98582146715298],
            [82.19018662893426, 27.981301480643484],
            [82.19208613620567, 27.97874207888705],
            [82.19320761171707, 27.972532336194256],
            [82.18997938000244, 27.97014663259513],
            [82.19015552129582, 27.967787663761126],
            [82.19229998014836, 27.964012978924917],
            [82.191643992927, 27.960911905624034],
            [82.19239104984722, 27.959432834677486],
            [82.19156777444486, 27.957061320759088],
            [82.19268665744184, 27.954424422805392],
            [82.19080500145674, 27.953285106488774],
            [82.18595171514487, 27.9527997318579],
            [82.1828562647818, 27.951862248778912],
            [82.17713498938095, 27.95177253530753],
            [82.17417186823785, 27.95236830178716],
            [82.16773611795493, 27.95549336696811],
            [82.16286925388292, 27.95657451548105],
            [82.15678235652352, 27.960293516446875],
            [82.15019904642007, 27.961929783855506],
            [82.14710955436965, 27.962084223804407],
            [82.14131204988175, 27.958769478722377],
            [82.13671210201463, 27.961222042953768],
            [82.13332508934985, 27.96222135694471],
            [82.13211223883019, 27.958654925963597],
            [82.13046395534052, 27.958342422110608],
            [82.12796778816778, 27.954798588936903],
            [82.12315620066086, 27.950161387770695],
            [82.11955610911787, 27.94882518574922],
            [82.11379666578877, 27.950295540246778],
            [82.114307222504, 27.95270359963459],
            [82.10988079206304, 27.950508242225197],
            [82.10700740793438, 27.950859973587495],
            [82.10288551313289, 27.948689753705214],
            [82.09717445186759, 27.95017898218296],
            [82.0952793897476, 27.953657197266892],
            [82.09523675976965, 27.955318789643105],
            [82.09107170395309, 27.95332192357677],
            [82.09012582461077, 27.95103711694501],
            [82.08805855414771, 27.949995772714146],
            [82.08383696208058, 27.951645230193947],
            [82.07891972426341, 27.95261801553434],
            [82.07509791134584, 27.952627500815],
            [82.07100911868297, 27.951853134191296],
            [82.06915541710805, 27.952327995916058],
            [82.06732464040964, 27.95043224105267],
            [82.06641697726661, 27.943709441763037],
            [82.06485318193035, 27.94250027295735],
            [82.06556267422901, 27.939279065937367],
            [82.0669739302686, 27.938813734207468],
            [82.067444070167, 27.935018350717844],
            [82.06893267721127, 27.93160705823422],
            [82.06799265321028, 27.929392248910148],
            [82.06548657692227, 27.928156427740305],
            [82.06524840266684, 27.92533245991877],
            [82.06797760239716, 27.925085706113716],
            [82.12341747103405, 27.865090769459457],
            [82.15406824162848, 27.86625264479763],
            [82.20859622256832, 27.84301445437191],
            [82.30395967490172, 27.772669607111748],
            [82.36722989719034, 27.742718281421862],
            [82.40184049871941, 27.70283782023313],
            [82.4488011701905, 27.678764187068413],
            [82.4732806568427, 27.676550287456337],
            [82.51650278148996, 27.690456082727987],
            [82.54120353403407, 27.685774303713483],
            [82.60509081320977, 27.70600457534463],
            [82.61889823286539, 27.702302937579287],
            [82.70805796435171, 27.721925785673246],
            [82.70818795469388, 27.724885110196784],
            [82.70624268785909, 27.726702621016948],
            [82.70669271921487, 27.729160975487048],
            [82.705937466279, 27.731691786018494],
            [82.7062577890342, 27.735660621096628],
            [82.70390819174963, 27.739013401994924],
            [82.70093857311765, 27.745334241163302],
            [82.70178731200697, 27.750059728861398],
            [82.699636090698, 27.75121163280138],
            [82.69917735694425, 27.753357735273163],
            [82.69662804144424, 27.75518584599104],
            [82.6961574567483, 27.756719505503053],
            [82.69969475570616, 27.75879255923728],
            [82.7031450167257, 27.758443687545178],
            [82.70643282439733, 27.759664324594766],
            [82.71021649378287, 27.75988179459224],
            [82.71214416666426, 27.757927809446098],
            [82.715380941599, 27.75763712107436],
            [82.71819578969901, 27.75888254492625],
            [82.7218574227354, 27.758089210688148],
            [82.72383020834442, 27.75901974584272],
            [82.72390039711303, 27.761995912901313],
            [82.7260910541726, 27.764737680059323],
            [82.73118502366634, 27.766920001769805],
            [82.73198004388406, 27.771307978085954],
            [82.73563857859898, 27.776173969213314],
            [82.74222937198337, 27.77684999853002],
            [82.75630635114742, 27.777503008429242],
            [82.76532884021083, 27.77697030674669],
            [82.77244468999828, 27.777554789377024],
            [82.77804769018367, 27.777070189366324],
            [82.78164064611452, 27.777526053857965],
            [82.78276203026803, 27.77676892101934],
            [82.78382198638455, 27.770028008180102],
            [82.78579057386168, 27.772237515797777],
            [82.79026658784437, 27.77208559731378],
            [82.79788066226716, 27.773886851074057],
            [82.79946797576065, 27.77597393277851],
            [82.80230593864347, 27.775891891405244],
            [82.80418248648947, 27.77772089799692],
            [82.8051876927563, 27.780537595967072],
            [82.80533114635736, 27.782088706521073],
            [82.8037551482764, 27.786236427223944],
            [82.80351101323456, 27.78778123535606],
            [82.80352626600146, 27.788008189358294],
            [82.80392290262176, 27.79022051752208],
            [82.80573811324635, 27.79156041093815],
            [82.80538556181027, 27.79361934327633],
            [82.80507965730787, 27.794072360216795],
            [82.80431674216611, 27.795056134910087],
            [82.80014481865426, 27.794027358359212],
            [82.79758361359556, 27.796533177840548],
            [82.7988232895542, 27.8022072189614],
            [82.79483713574263, 27.805766151532296],
            [82.7908212371625, 27.805692727533486],
            [82.78827359619086, 27.80466878561417],
            [82.78662567417943, 27.802109621216076],
            [82.78821598562351, 27.79570533416105],
            [82.78462148084719, 27.796355118339005],
            [82.77945848876868, 27.80279990257665],
            [82.77342440994269, 27.804832867174664],
            [82.77001443868429, 27.809883018390444],
            [82.76756571397821, 27.81084992201967],
            [82.76304209410756, 27.810542804283433],
            [82.75952539842685, 27.811439127684555],
            [82.75686305764252, 27.813643784696456],
            [82.75709910433034, 27.817706164300066],
            [82.75278173484669, 27.821827414418873],
            [82.7485098235104, 27.82311279098692],
            [82.74877497472198, 27.82809391361767],
            [82.75225310632766, 27.829668443043754],
            [82.7541241118219, 27.83419927443363],
            [82.75883028268325, 27.837102355594148],
            [82.7631712928776, 27.838143436702758],
            [82.77101320599641, 27.84123126098049],
            [82.7747205794012, 27.842131498771856],
            [82.77971716000633, 27.8423375429229],
            [82.7811589081122, 27.84315574080901],
            [82.78377427011043, 27.846889558092634],
            [82.78927209433589, 27.85055636221701],
            [82.79036615135281, 27.854696181796985],
            [82.79309707802844, 27.85644700595239],
            [82.79572123142799, 27.856836106013905],
            [82.80318176838267, 27.855559598785092],
            [82.80522620001076, 27.8559246086859],
            [82.8076596178478, 27.85780511662327],
            [82.8068269618663, 27.859439795281435],
            [82.79584319923336, 27.861733731903666],
            [82.79210524814212, 27.86448763997123],
            [82.79327999213899, 27.86593138740088],
            [82.79795483438393, 27.866728319054317],
            [82.80242636681201, 27.868443263375983],
            [82.8054548471433, 27.86751451150338],
            [82.8090461989288, 27.86813753215507],
            [82.80929947517316, 27.871592104101023],
            [82.80552860306754, 27.87194213587163],
            [82.80387566535713, 27.873682291907063],
            [82.80576693530855, 27.87610413418938],
            [82.80524085124293, 27.877780286327393],
            [82.80328603230113, 27.878819101777317],
            [82.80049621389804, 27.87835863676552],
            [82.7973532580725, 27.882123355482477],
            [82.79439347618454, 27.880609017791173],
            [82.79111322463841, 27.883525041955906],
            [82.7897504784101, 27.889021731829896],
            [82.78768668093878, 27.8896735811036],
            [82.78708452641246, 27.892382137854348],
            [82.78296403213544, 27.89146603924722],
            [82.78013305878001, 27.897297342276172],
            [82.7778514352294, 27.89683047821276],
            [82.77733393009126, 27.893960858138694],
            [82.7741822774833, 27.894666062438752],
            [82.77453648513493, 27.899783473690217],
            [82.7721411224904, 27.90283682664119],
            [82.77078140638234, 27.906131842785168],
            [82.77152295839772, 27.917939732009216],
            [82.76934820331927, 27.921470358905204],
            [82.77571702080219, 27.92594641342792],
            [82.77106435951255, 27.93147259370861],
            [82.76973529923151, 27.934361748678512],
            [82.7673572174955, 27.935809083330735],
            [82.76524444986194, 27.938277751891466],
            [82.76444341719399, 27.941319683776296],
            [82.76230402596505, 27.94255620886498],
            [82.75740040980963, 27.943892581046768],
            [82.7554247157143, 27.94511100470921],
            [82.7537475479353, 27.942564714172743],
            [82.7516505999964, 27.943455541214284],
            [82.75163530868404, 27.945418023340938],
            [82.75322847386491, 27.947171035868216],
            [82.75824131162805, 27.95489295693513],
            [82.76182659745939, 27.957664130991414],
            [82.76349715618505, 27.960763312396114],
            [82.76621208160634, 27.962844595928694],
            [82.7662734877788, 27.967692965965412],
            [82.76566347744433, 27.968485491061085],
            [82.75959127577607, 27.969498116097807],
            [82.76005485693092, 27.972775977119724],
            [82.76271114871034, 27.976920881581307],
            [82.75703562579578, 27.977742794697424],
            [82.75428942730774, 27.97687880658186],
            [82.74888855221253, 27.976203591810606],
            [82.7465161555621, 27.97467972664086],
            [82.74500249107723, 27.976855707175798],
            [82.74516581335583, 27.98150929867226],
            [82.74186892573357, 27.986669390977994],
            [82.73529984935547, 27.99134643431598],
            [82.72981503498124, 27.994187848148705],
            [82.7214548301664, 28.002062199035475],
            [82.71266018379468, 28.002844130370853],
            [82.70520034381876, 28.006063610719156],
            [82.70242258008176, 28.008651394351258],
            [82.70020123555217, 28.009650022139006],
            [82.69682262062004, 28.01352743288875],
            [82.69553501442839, 28.016478554285097],
            [82.69494641978463, 28.021481678770407],
            [82.69672352964932, 28.022994189967367],
            [82.69652653357814, 28.02607546785543],
            [82.70076780555667, 28.03187142860035],
            [82.70156110993918, 28.034489989549062],
            [82.70349106435151, 28.03581550228979],
            [82.7081138515539, 28.036124528681956],
            [82.71285094467024, 28.043211653146848],
            [82.71835093814985, 28.046709486736706],
            [82.71389593765196, 28.048523147274256],
            [82.71005118638625, 28.0514944709125],
            [82.70461212722411, 28.053247088217756],
            [82.69997112635964, 28.05304092146541],
            [82.69587444635455, 28.05190346873376],
            [82.69098659407675, 28.052380294701035],
            [82.68985058723615, 28.05078361265802],
            [82.6858005010699, 28.051058531272105],
            [82.67731626101805, 28.054832192950972],
            [82.67308338487715, 28.055224473320163],
            [82.66979439385716, 28.058492878343685],
            [82.66784236971826, 28.057000925444303],
            [82.6645755263448, 28.058816651343715],
            [82.66233445834254, 28.058470027038638],
            [82.66042848775193, 28.059940655764773],
            [82.65638485396614, 28.060072172846496],
            [82.65357051954028, 28.06353939795102],
            [82.65075192748326, 28.06335773133932],
            [82.64822129759709, 28.0651870911995],
            [82.64303567964397, 28.06698742251027],
            [82.6398393568332, 28.06901851846619],
            [82.63293219756987, 28.0703412823526],
            [82.62883921794065, 28.07068142191939],
            [82.62677957448638, 28.069744971069724],
            [82.62481906730032, 28.070660387422915],
            [82.62551946862446, 28.07244404964724],
            [82.62479483257782, 28.07505355049534],
            [82.62229397042302, 28.076715629517675],
            [82.61987384433972, 28.079984843027507],
            [82.61882156098602, 28.08281200686602],
            [82.61609957384876, 28.08583183482928],
            [82.61252182978998, 28.08802884961933],
            [82.61076486537361, 28.09150784842836],
            [82.60802100700425, 28.092844401284967],
            [82.60044346264509, 28.093744375793783],
            [82.59712497936191, 28.093266186302696],
            [82.5971200082398, 28.096172268970417],
            [82.59593753444729, 28.100528237545657],
            [82.59150540643114, 28.103106675277484],
            [82.58876543035376, 28.103069547065736],
            [82.58798868811657, 28.10538188501933],
            [82.58554756088347, 28.108172046515477],
            [82.58128325607535, 28.110807701767882],
            [82.57801833041911, 28.109869327242276],
            [82.57453897373959, 28.11336646898229],
            [82.57122901862544, 28.11346044171655],
            [82.56725460235415, 28.115502969957696],
            [82.56471944721339, 28.118846955891463],
            [82.56499586948851, 28.12115968506745],
            [82.56211295137848, 28.12291799040539],
            [82.56093806501427, 28.129615968163677],
            [82.55920639415935, 28.13138580145009],
            [82.561912314647, 28.13365568371774],
            [82.55724581752604, 28.136273860500694],
            [82.55385118223612, 28.13690318100559],
            [82.5522392711276, 28.13543732438546],
            [82.54840431417013, 28.133867415574286],
            [82.54461082311347, 28.13392417060285],
            [82.54214172891979, 28.131585819549123],
            [82.53378862500918, 28.133857148358498],
            [82.53102712085473, 28.135831035062203],
            [82.52728922562753, 28.13551248563368],
            [82.52411246412095, 28.136901240249294],
            [82.52231273570705, 28.138778918183604],
            [82.5165865608092, 28.139122618092244],
            [82.51588473805563, 28.1398549631081],
            [82.51829476410411, 28.143023598375237],
            [82.51978005607971, 28.14706831009153],
            [82.51616683218296, 28.14874048876699],
            [82.51052946330465, 28.14898834381765],
            [82.50727062137355, 28.150167228634164],
            [82.50402143745794, 28.14992876836428],
            [82.50234056251918, 28.148554746387973],
            [82.50105415755058, 28.14229017871399],
            [82.49793517914863, 28.140255222937544],
            [82.49546869248115, 28.13677410827632],
            [82.48135827441753, 28.1369386495565],
            [82.48196688796004, 28.140067433225408],
            [82.47750347614932, 28.144336042684834],
            [82.47515682494287, 28.142839130600567],
            [82.47330761026765, 28.14033229017335],
            [82.46981700882039, 28.14043411154338],
            [82.46679614911578, 28.14190831531341],
            [82.46436010265882, 28.141671044663354],
            [82.45944796267621, 28.14457246102264],
            [82.45479599216468, 28.146503157565387],
            [82.44779122610227, 28.147689452757337],
            [82.44567207504396, 28.147608056228925],
            [82.43716744039318, 28.149096046805496],
            [82.41395282007515, 28.15962826877965],
            [82.40898804097984, 28.160981166942932],
            [82.40317516696685, 28.16428621919267],
            [82.40000932630697, 28.16819206719927],
            [82.39683591248212, 28.16892247132062]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "GULMI",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.44070790730653, 27.93547538374721],
            [83.48096038158936, 27.980486932777236],
            [83.52274272869427, 27.96397679836348],
            [83.54997478796494, 27.97682696486565],
            [83.57681855969713, 27.974351459833315],
            [83.60332033962942, 27.99730082247961],
            [83.60491984936404, 28.012067313431885],
            [83.57793841839805, 28.034088033937373],
            [83.56156063900882, 28.03576945191377],
            [83.56117848903524, 28.06320925703902],
            [83.58067898894207, 28.07746105747539],
            [83.56220426461282, 28.094536164847117],
            [83.55451331384467, 28.11676771034439],
            [83.50581940736483, 28.125988401228234],
            [83.48906963343266, 28.113464485827926],
            [83.47825351926306, 28.130154740596886],
            [83.42660819809174, 28.126022923244985],
            [83.35274581877142, 28.197830530579115],
            [83.33816935385218, 28.19008590126294],
            [83.3078569637755, 28.197665016466235],
            [83.273890204279, 28.187567196587853],
            [83.25180542304912, 28.214202063825763],
            [83.23378082378531, 28.221869600609782],
            [83.1854297939839, 28.270187912506092],
            [83.16764352863312, 28.258126510580148],
            [83.15539418945684, 28.270792860485702],
            [83.13509933075126, 28.25152208374836],
            [83.09357092965496, 28.257021357184847],
            [83.07780603536776, 28.233711517137927],
            [83.05099514013173, 28.243677469477433],
            [83.02503334756531, 28.238387395413934],
            [83.03728574300752, 28.191569298135],
            [83.03850884837291, 28.156125998939157],
            [83.05336459125503, 28.1500256444888],
            [83.05912243030461, 28.12670273632164],
            [83.05103547598031, 28.111629125173636],
            [83.07443577736767, 28.090885356563174],
            [83.09875744889511, 28.09462748682865],
            [83.10230241480028, 28.077241377244384],
            [83.1256757810255, 28.084176015189577],
            [83.12430808220333, 28.070832152931608],
            [83.15037460309873, 28.05978591086229],
            [83.1839129757524, 28.087659282525486],
            [83.22986278918039, 28.048381282493178],
            [83.23366284620266, 28.02086766198251],
            [83.25408787408053, 27.986031510372136],
            [83.28017571782416, 27.967740742241727],
            [83.30106298939856, 27.967557372816227],
            [83.28183807416504, 27.925808901789544],
            [83.30897756344469, 27.926164323044315],
            [83.3441919118862, 27.926474115912583],
            [83.34506393507128, 27.949519840807742],
            [83.38322648045862, 27.955052077131157],
            [83.39892911841503, 27.935371535836524],
            [83.44070790730653, 27.93547538374721]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KAPILBASTU",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.21049838421037, 27.758950573409244],
            [83.18552918043581, 27.75417396491611],
            [83.12148359880476, 27.761888041302463],
            [83.09344128089987, 27.783022407859956],
            [83.06677853022644, 27.75864187576665],
            [83.01618088077564, 27.809947739496845],
            [82.99647319516245, 27.792892516146736],
            [82.96069243169501, 27.795646512838328],
            [82.92991081180467, 27.784372921337507],
            [82.9021656218653, 27.792958125971822],
            [82.84218556484907, 27.790580256661205],
            [82.8063242223183, 27.795120844364835],
            [82.80507965730787, 27.794072360216795],
            [82.80538556181027, 27.79361934327633],
            [82.80573811324635, 27.79156041093815],
            [82.80392290262176, 27.79022051752208],
            [82.80352626600146, 27.788008189358294],
            [82.80351101323456, 27.78778123535606],
            [82.8037551482764, 27.786236427223944],
            [82.80533114635736, 27.782088706521073],
            [82.8051876927563, 27.780537595967072],
            [82.80418248648947, 27.77772089799692],
            [82.80230593864347, 27.775891891405244],
            [82.79946797576065, 27.77597393277851],
            [82.79788066226716, 27.773886851074057],
            [82.79026658784437, 27.77208559731378],
            [82.78579057386168, 27.772237515797777],
            [82.78382198638455, 27.770028008180102],
            [82.78276203026803, 27.77676892101934],
            [82.78164064611452, 27.777526053857965],
            [82.77804769018367, 27.777070189366324],
            [82.77244468999828, 27.777554789377024],
            [82.76532884021083, 27.77697030674669],
            [82.75630635114742, 27.777503008429242],
            [82.74222937198337, 27.77684999853002],
            [82.73563857859898, 27.776173969213314],
            [82.73198004388406, 27.771307978085954],
            [82.73118502366634, 27.766920001769805],
            [82.7260910541726, 27.764737680059323],
            [82.72390039711303, 27.761995912901313],
            [82.72383020834442, 27.75901974584272],
            [82.7218574227354, 27.758089210688148],
            [82.71819578969901, 27.75888254492625],
            [82.715380941599, 27.75763712107436],
            [82.71214416666426, 27.757927809446098],
            [82.71021649378287, 27.75988179459224],
            [82.70643282439733, 27.759664324594766],
            [82.7031450167257, 27.758443687545178],
            [82.69969475570616, 27.75879255923728],
            [82.6961574567483, 27.756719505503053],
            [82.69662804144424, 27.75518584599104],
            [82.69917735694425, 27.753357735273163],
            [82.699636090698, 27.75121163280138],
            [82.70178731200697, 27.750059728861398],
            [82.70093857311765, 27.745334241163302],
            [82.70390819174963, 27.739013401994924],
            [82.7062577890342, 27.735660621096628],
            [82.705937466279, 27.731691786018494],
            [82.70669271921487, 27.729160975487048],
            [82.70624268785909, 27.726702621016948],
            [82.70818795469388, 27.724885110196784],
            [82.70805796435171, 27.721925785673246],
            [82.71834456053878, 27.68749437722293],
            [82.71317761116079, 27.6631454616703],
            [82.7307080169563, 27.652307126891632],
            [82.73334656736955, 27.618191768655205],
            [82.75485050403793, 27.589387997095837],
            [82.73829088204815, 27.550868710412026],
            [82.74952980063811, 27.522892094923787],
            [82.73584660236835, 27.50260193888839],
            [82.80199237446229, 27.49658607945876],
            [82.8330230113864, 27.502126690521546],
            [82.87986331879584, 27.498721520655486],
            [82.92949452729513, 27.50123747897635],
            [82.95427323869065, 27.468006212978487],
            [83.03419441548598, 27.448939053193566],
            [83.16454476471213, 27.457710020853543],
            [83.18791820780777, 27.454486516891958],
            [83.21707322195653, 27.41792892579982],
            [83.2313485300322, 27.430828271874883],
            [83.21688071358211, 27.444544513195122],
            [83.22734082365515, 27.469010694880108],
            [83.22119024240138, 27.491558815010396],
            [83.23305494540185, 27.51423642755785],
            [83.20429646552532, 27.559889005760603],
            [83.2314338019999, 27.607884035425574],
            [83.22064212675367, 27.645711572764316],
            [83.22927027708704, 27.69506577432603],
            [83.21083180942767, 27.753688931777308],
            [83.21049838421037, 27.758950573409244]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "PALPA",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.44070790730653, 27.93547538374721],
            [83.39892911841503, 27.935371535836524],
            [83.38322648045862, 27.955052077131157],
            [83.34506393507128, 27.949519840807742],
            [83.3441919118862, 27.926474115912583],
            [83.30897756344469, 27.926164323044315],
            [83.29749262910273, 27.910841553794285],
            [83.30892967465326, 27.88964015011027],
            [83.33051638910119, 27.877335769422032],
            [83.29028946993738, 27.82832590230905],
            [83.24817634456856, 27.831915846535743],
            [83.23385549573432, 27.822259895634556],
            [83.25846056928168, 27.785046509347282],
            [83.2558549908736, 27.7671526623208],
            [83.28340138346871, 27.756308542698623],
            [83.30010384144202, 27.766623469110733],
            [83.33470711910307, 27.765370642361244],
            [83.37105706715893, 27.735118664801465],
            [83.38876926516194, 27.748899792760604],
            [83.40853556644784, 27.735108018456707],
            [83.43207609693708, 27.7394256537576],
            [83.44245154954683, 27.72441010504262],
            [83.47114846760857, 27.72123093402494],
            [83.48647201254313, 27.730047168148253],
            [83.5312309291893, 27.72607492887883],
            [83.57801513637044, 27.705330680179003],
            [83.63220506701646, 27.693698651602034],
            [83.63914387900007, 27.691031995000174],
            [83.68411232767039, 27.663949546027283],
            [83.69366447200014, 27.665092333000075],
            [83.69624228994053, 27.663137360045823],
            [83.70446834200004, 27.682141509000132],
            [83.6819835550001, 27.720966658000066],
            [83.72949518658329, 27.709617261268495],
            [83.7823317359839, 27.725095762843186],
            [83.82106797100005, 27.710727076000182],
            [83.8462830740001, 27.709538995000003],
            [83.8453102868528, 27.735112475732333],
            [83.88355250800004, 27.73509579600011],
            [83.9241780678343, 27.726863897295846],
            [83.94742093500014, 27.745488682000087],
            [83.99235142600003, 27.75363674200014],
            [84.00043718299997, 27.788773305000063],
            [84.02473438681166, 27.81827799660894],
            [84.02854046395633, 27.8564569047074],
            [84.0002080193286, 27.854797065658218],
            [83.99428158993437, 27.866274247726768],
            [83.94522602739346, 27.87145047782738],
            [83.93670635807653, 27.87761304058933],
            [83.92573543423221, 27.868318782093752],
            [83.87714778175388, 27.888292053595208],
            [83.84550968616087, 27.86852525095122],
            [83.80760658854224, 27.87322688419338],
            [83.76101242121157, 27.91010359066],
            [83.74629313979598, 27.899883863535994],
            [83.71731600112749, 27.920121719020596],
            [83.6810465177154, 27.917416365725845],
            [83.67566054725103, 27.92752492920641],
            [83.65497683882292, 27.902512461162445],
            [83.63334214646966, 27.90011248554915],
            [83.61687008814926, 27.9306499024872],
            [83.56460331295776, 27.934300676800618],
            [83.52408678848114, 27.924158381218806],
            [83.51612860128076, 27.937653973629164],
            [83.48387818647853, 27.9227366105568],
            [83.44070790730653, 27.93547538374721]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "PYUTHAN",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.95474738312859, 28.360171528616398],
            [82.92734759014193, 28.34865025852431],
            [82.91814592773444, 28.31907809330839],
            [82.88681870865422, 28.291120152596708],
            [82.88877732318666, 28.27715221246963],
            [82.8487900160584, 28.256056270070353],
            [82.85286821914319, 28.238648487497578],
            [82.81652655518499, 28.21346609770984],
            [82.82506111397907, 28.198531587652386],
            [82.7866197103703, 28.17789156506963],
            [82.7580805080089, 28.17994110201647],
            [82.73251476321275, 28.207577083840285],
            [82.69739142584115, 28.2108721655409],
            [82.68526822886827, 28.187050370043877],
            [82.65793033725328, 28.190793520877385],
            [82.62463597384038, 28.178180528352488],
            [82.6070465495485, 28.152043745384553],
            [82.58847231958127, 28.14006290011403],
            [82.6043010415777, 28.13086596261931],
            [82.60044346264509, 28.093744375793783],
            [82.60802100700425, 28.092844401284967],
            [82.61076486537361, 28.09150784842836],
            [82.61252182978998, 28.08802884961933],
            [82.61609957384876, 28.08583183482928],
            [82.61882156098602, 28.08281200686602],
            [82.61987384433972, 28.079984843027507],
            [82.62229397042302, 28.076715629517675],
            [82.62479483257782, 28.07505355049534],
            [82.62551946862446, 28.07244404964724],
            [82.62481906730032, 28.070660387422915],
            [82.62677957448638, 28.069744971069724],
            [82.62883921794065, 28.07068142191939],
            [82.63293219756987, 28.0703412823526],
            [82.6398393568332, 28.06901851846619],
            [82.64303567964397, 28.06698742251027],
            [82.64822129759709, 28.0651870911995],
            [82.65075192748326, 28.06335773133932],
            [82.65357051954028, 28.06353939795102],
            [82.65638485396614, 28.060072172846496],
            [82.66042848775193, 28.059940655764773],
            [82.66233445834254, 28.058470027038638],
            [82.6645755263448, 28.058816651343715],
            [82.66784236971826, 28.057000925444303],
            [82.66979439385716, 28.058492878343685],
            [82.67308338487715, 28.055224473320163],
            [82.67731626101805, 28.054832192950972],
            [82.6858005010699, 28.051058531272105],
            [82.68985058723615, 28.05078361265802],
            [82.69098659407675, 28.052380294701035],
            [82.69587444635455, 28.05190346873376],
            [82.69997112635964, 28.05304092146541],
            [82.70461212722411, 28.053247088217756],
            [82.71005118638625, 28.0514944709125],
            [82.71389593765196, 28.048523147274256],
            [82.71835093814985, 28.046709486736706],
            [82.71285094467024, 28.043211653146848],
            [82.7081138515539, 28.036124528681956],
            [82.70349106435151, 28.03581550228979],
            [82.70156110993918, 28.034489989549062],
            [82.70076780555667, 28.03187142860035],
            [82.69652653357814, 28.02607546785543],
            [82.69672352964932, 28.022994189967367],
            [82.69494641978463, 28.021481678770407],
            [82.69553501442839, 28.016478554285097],
            [82.69682262062004, 28.01352743288875],
            [82.70020123555217, 28.009650022139006],
            [82.70242258008176, 28.008651394351258],
            [82.70520034381876, 28.006063610719156],
            [82.71266018379468, 28.002844130370853],
            [82.7214548301664, 28.002062199035475],
            [82.72981503498124, 27.994187848148705],
            [82.73529984935547, 27.99134643431598],
            [82.74186892573357, 27.986669390977994],
            [82.74516581335583, 27.98150929867226],
            [82.74500249107723, 27.976855707175798],
            [82.7465161555621, 27.97467972664086],
            [82.74888855221253, 27.976203591810606],
            [82.75428942730774, 27.97687880658186],
            [82.75703562579578, 27.977742794697424],
            [82.76271114871034, 27.976920881581307],
            [82.76005485693092, 27.972775977119724],
            [82.75959127577607, 27.969498116097807],
            [82.76566347744433, 27.968485491061085],
            [82.7662734877788, 27.967692965965412],
            [82.76621208160634, 27.962844595928694],
            [82.76349715618505, 27.960763312396114],
            [82.76182659745939, 27.957664130991414],
            [82.75824131162805, 27.95489295693513],
            [82.75322847386491, 27.947171035868216],
            [82.75163530868404, 27.945418023340938],
            [82.7516505999964, 27.943455541214284],
            [82.7537475479353, 27.942564714172743],
            [82.7554247157143, 27.94511100470921],
            [82.75740040980963, 27.943892581046768],
            [82.76230402596505, 27.94255620886498],
            [82.76444341719399, 27.941319683776296],
            [82.76524444986194, 27.938277751891466],
            [82.7673572174955, 27.935809083330735],
            [82.76973529923151, 27.934361748678512],
            [82.77106435951255, 27.93147259370861],
            [82.77571702080219, 27.92594641342792],
            [82.76934820331927, 27.921470358905204],
            [82.77152295839772, 27.917939732009216],
            [82.77078140638234, 27.906131842785168],
            [82.7721411224904, 27.90283682664119],
            [82.77453648513493, 27.899783473690217],
            [82.7741822774833, 27.894666062438752],
            [82.77733393009126, 27.893960858138694],
            [82.7778514352294, 27.89683047821276],
            [82.78013305878001, 27.897297342276172],
            [82.78296403213544, 27.89146603924722],
            [82.78708452641246, 27.892382137854348],
            [82.78768668093878, 27.8896735811036],
            [82.7897504784101, 27.889021731829896],
            [82.79111322463841, 27.883525041955906],
            [82.79439347618454, 27.880609017791173],
            [82.7973532580725, 27.882123355482477],
            [82.80049621389804, 27.87835863676552],
            [82.80328603230113, 27.878819101777317],
            [82.80524085124293, 27.877780286327393],
            [82.82431605303815, 27.905701127298425],
            [82.87788076450188, 27.901487010446232],
            [82.89534457545922, 27.914153161039255],
            [82.91578157777064, 27.904190794759987],
            [82.9430391357566, 27.931072598480295],
            [83.01591992459213, 27.928337001368224],
            [83.01661994173851, 27.956990832888653],
            [82.99805403942908, 27.978667715416528],
            [82.95434505512682, 27.9976804230815],
            [82.9736143728851, 28.00673211801151],
            [82.9766597934046, 28.02323234039151],
            [82.96454625693237, 28.05301925790706],
            [82.98512993420677, 28.073862205537065],
            [83.00252756372757, 28.114003279763622],
            [83.02708459163836, 28.099692063578992],
            [83.05103547598031, 28.111629125173636],
            [83.05912243030461, 28.12670273632164],
            [83.05336459125503, 28.1500256444888],
            [83.03850884837291, 28.156125998939157],
            [83.03728574300752, 28.191569298135],
            [83.02503334756531, 28.238387395413934],
            [83.05099514013173, 28.243677469477433],
            [83.07780603536776, 28.233711517137927],
            [83.09357092965496, 28.257021357184847],
            [83.09691323293517, 28.277353541359542],
            [83.06120916177365, 28.317777570514025],
            [83.00336908994585, 28.33316526626841],
            [82.98008411486015, 28.359429458934127],
            [82.95474738312859, 28.360171528616398]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "ROLPA",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.95474738312859, 28.360171528616398],
            [82.93590433874822, 28.402135153617753],
            [82.91591750344793, 28.403468620776568],
            [82.91424547709272, 28.43435075735078],
            [82.88634727642136, 28.452473879771922],
            [82.87930878793306, 28.476361081279414],
            [82.83294737219967, 28.47825387196795],
            [82.7884730772681, 28.500871032773976],
            [82.74268066469861, 28.51237869910722],
            [82.74240859700384, 28.525028342035228],
            [82.70292308158541, 28.50919218134975],
            [82.6872001241294, 28.523842796826084],
            [82.65731252583862, 28.49280836186449],
            [82.61560348145974, 28.50911546097434],
            [82.54429235119784, 28.526514171769406],
            [82.53456790921402, 28.554692248493254],
            [82.52634595595218, 28.558209871625554],
            [82.49218704129721, 28.552633535568695],
            [82.49101952025656, 28.53812054639962],
            [82.45983784569987, 28.52101462966324],
            [82.42108652875203, 28.527211271168767],
            [82.39661335971817, 28.462011387087454],
            [82.37394990513145, 28.448525569053416],
            [82.37661798940768, 28.430381216451043],
            [82.35390679729001, 28.414935773129997],
            [82.36359010969001, 28.388342600309176],
            [82.38359049722318, 28.372847489914403],
            [82.3793171302295, 28.35177543487901],
            [82.36319099488978, 28.340864406373903],
            [82.38328907721464, 28.29206098688592],
            [82.4035433064921, 28.26230475181935],
            [82.4267300313219, 28.252357899712415],
            [82.42832770133357, 28.231149518677885],
            [82.40710542107455, 28.221099339481977],
            [82.40000932630697, 28.16819206719927],
            [82.40317516696685, 28.16428621919267],
            [82.40898804097984, 28.160981166942932],
            [82.41395282007515, 28.15962826877965],
            [82.43716744039318, 28.149096046805496],
            [82.44567207504396, 28.147608056228925],
            [82.44779122610227, 28.147689452757337],
            [82.45479599216468, 28.146503157565387],
            [82.45944796267621, 28.14457246102264],
            [82.46436010265882, 28.141671044663354],
            [82.46679614911578, 28.14190831531341],
            [82.46981700882039, 28.14043411154338],
            [82.47330761026765, 28.14033229017335],
            [82.47515682494287, 28.142839130600567],
            [82.47750347614932, 28.144336042684834],
            [82.48196688796004, 28.140067433225408],
            [82.48135827441753, 28.1369386495565],
            [82.49546869248115, 28.13677410827632],
            [82.49793517914863, 28.140255222937544],
            [82.50105415755058, 28.14229017871399],
            [82.50234056251918, 28.148554746387973],
            [82.50402143745794, 28.14992876836428],
            [82.50727062137355, 28.150167228634164],
            [82.51052946330465, 28.14898834381765],
            [82.51616683218296, 28.14874048876699],
            [82.51978005607971, 28.14706831009153],
            [82.51829476410411, 28.143023598375237],
            [82.51588473805563, 28.1398549631081],
            [82.5165865608092, 28.139122618092244],
            [82.52231273570705, 28.138778918183604],
            [82.52411246412095, 28.136901240249294],
            [82.52728922562753, 28.13551248563368],
            [82.53102712085473, 28.135831035062203],
            [82.53378862500918, 28.133857148358498],
            [82.54214172891979, 28.131585819549123],
            [82.54461082311347, 28.13392417060285],
            [82.54840431417013, 28.133867415574286],
            [82.5522392711276, 28.13543732438546],
            [82.55385118223612, 28.13690318100559],
            [82.55724581752604, 28.136273860500694],
            [82.561912314647, 28.13365568371774],
            [82.55920639415935, 28.13138580145009],
            [82.56093806501427, 28.129615968163677],
            [82.56211295137848, 28.12291799040539],
            [82.56499586948851, 28.12115968506745],
            [82.56471944721339, 28.118846955891463],
            [82.56725460235415, 28.115502969957696],
            [82.57122901862544, 28.11346044171655],
            [82.57453897373959, 28.11336646898229],
            [82.57801833041911, 28.109869327242276],
            [82.58128325607535, 28.110807701767882],
            [82.58554756088347, 28.108172046515477],
            [82.58798868811657, 28.10538188501933],
            [82.58876543035376, 28.103069547065736],
            [82.59150540643114, 28.103106675277484],
            [82.59593753444729, 28.100528237545657],
            [82.5971200082398, 28.096172268970417],
            [82.59712497936191, 28.093266186302696],
            [82.60044346264509, 28.093744375793783],
            [82.6043010415777, 28.13086596261931],
            [82.58847231958127, 28.14006290011403],
            [82.6070465495485, 28.152043745384553],
            [82.62463597384038, 28.178180528352488],
            [82.65793033725328, 28.190793520877385],
            [82.68526822886827, 28.187050370043877],
            [82.69739142584115, 28.2108721655409],
            [82.73251476321275, 28.207577083840285],
            [82.7580805080089, 28.17994110201647],
            [82.7866197103703, 28.17789156506963],
            [82.82506111397907, 28.198531587652386],
            [82.81652655518499, 28.21346609770984],
            [82.85286821914319, 28.238648487497578],
            [82.8487900160584, 28.256056270070353],
            [82.88877732318666, 28.27715221246963],
            [82.88681870865422, 28.291120152596708],
            [82.91814592773444, 28.31907809330839],
            [82.92734759014193, 28.34865025852431],
            [82.95474738312859, 28.360171528616398]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "RUPANDEHI",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.63220506701646, 27.693698651602034],
            [83.57801513637044, 27.705330680179003],
            [83.5312309291893, 27.72607492887883],
            [83.48647201254313, 27.730047168148253],
            [83.47114846760857, 27.72123093402494],
            [83.44245154954683, 27.72441010504262],
            [83.43207609693708, 27.7394256537576],
            [83.40853556644784, 27.735108018456707],
            [83.38876926516194, 27.748899792760604],
            [83.37105706715893, 27.735118664801465],
            [83.33470711910307, 27.765370642361244],
            [83.30010384144202, 27.766623469110733],
            [83.28340138346871, 27.756308542698623],
            [83.2558549908736, 27.7671526623208],
            [83.24610151779032, 27.751268592562038],
            [83.21235725359226, 27.759362687418275],
            [83.21083180942767, 27.753688931777308],
            [83.22927027708704, 27.69506577432603],
            [83.22064212675367, 27.645711572764316],
            [83.2314338019999, 27.607884035425574],
            [83.20429646552532, 27.559889005760603],
            [83.23305494540185, 27.51423642755785],
            [83.22119024240138, 27.491558815010396],
            [83.22734082365515, 27.469010694880108],
            [83.21688071358211, 27.444544513195122],
            [83.2313485300322, 27.430828271874883],
            [83.21707322195653, 27.41792892579982],
            [83.27232803581914, 27.38368955413509],
            [83.27284126639316, 27.352722799435213],
            [83.30481551476173, 27.331469047474084],
            [83.33761406546263, 27.332873884806244],
            [83.35952276195955, 27.34426197901024],
            [83.3882847389252, 27.376380672628816],
            [83.38467535756273, 27.389609175340595],
            [83.40353806120405, 27.402166121403134],
            [83.40562210945157, 27.418878264891948],
            [83.38398037909384, 27.442448073901318],
            [83.40193428536779, 27.457316109959166],
            [83.38891074101616, 27.479743269012786],
            [83.61468425969605, 27.46963293389074],
            [83.61417027393885, 27.472600908116743],
            [83.58981982500012, 27.524013029999992],
            [83.59448739700014, 27.574986921000118],
            [83.58063318000006, 27.574929975000032],
            [83.57455051674984, 27.617861622863902],
            [83.59305457957609, 27.626605912168365],
            [83.59965404564079, 27.645254943215075],
            [83.62944972390605, 27.664434639508283],
            [83.63252341151887, 27.689667584292064],
            [83.63220506701646, 27.693698651602034]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "RUKUM EAST",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.69567770210136, 28.86473641766844],
            [82.67364740541223, 28.852580542290525],
            [82.64859467668566, 28.869228019089178],
            [82.61368399215968, 28.826586911179504],
            [82.62009153071102, 28.811233798487706],
            [82.56408661841641, 28.76578502752824],
            [82.56616016315769, 28.74439984811727],
            [82.60141786166758, 28.74291067005538],
            [82.61469704787118, 28.688864229794547],
            [82.59984501343621, 28.670400664577755],
            [82.56069266947304, 28.646568379423343],
            [82.52154628931675, 28.659386771592892],
            [82.48993337432108, 28.659320523542476],
            [82.48581457805454, 28.640649441977587],
            [82.56123981448536, 28.61009269406992],
            [82.58060990689768, 28.612446435671927],
            [82.5809934339926, 28.587233019030805],
            [82.55395768360995, 28.581651405823663],
            [82.52905677668048, 28.558259592347383],
            [82.53456790921402, 28.554692248493254],
            [82.54429235119784, 28.526514171769406],
            [82.61560348145974, 28.50911546097434],
            [82.65731252583862, 28.49280836186449],
            [82.6872001241294, 28.523842796826084],
            [82.70292308158541, 28.50919218134975],
            [82.74240859700384, 28.525028342035228],
            [82.74268066469861, 28.51237869910722],
            [82.7884730772681, 28.500871032773976],
            [82.83294737219967, 28.47825387196795],
            [82.87930878793306, 28.476361081279414],
            [82.91158323375862, 28.480913685575594],
            [82.87763630995568, 28.514823766412427],
            [82.91406648071049, 28.52760548521363],
            [82.91624257290948, 28.564450372569354],
            [82.92841919525367, 28.567132175145403],
            [82.97381491143808, 28.542346922723173],
            [82.98570836283214, 28.569907180801046],
            [82.96229192118082, 28.581897739012643],
            [82.95733579181358, 28.611509438789042],
            [82.9829352340019, 28.630798967200985],
            [83.01912727423058, 28.621738137842613],
            [83.04282086537881, 28.629983221043222],
            [83.05752513005879, 28.62012696748146],
            [83.09721862431935, 28.63526964882109],
            [83.11478958977642, 28.630846874596614],
            [83.12501121611518, 28.664017154579824],
            [83.15253568431513, 28.701124198662228],
            [83.1436884214196, 28.745205191024496],
            [83.13777335695758, 28.747836111663197],
            [83.11503725999795, 28.743222767209563],
            [83.08048895299565, 28.781351243096665],
            [82.99738268381827, 28.8039192606268],
            [82.9814237123236, 28.800641831768296],
            [82.94347558704249, 28.827389059312967],
            [82.86823537654399, 28.834118787348377],
            [82.84280520335938, 28.826554183448934],
            [82.81712246040932, 28.831343433785158],
            [82.78148059843666, 28.824139839174403],
            [82.76747219402402, 28.809793278570954],
            [82.74178725121452, 28.845864573194685],
            [82.70468579405531, 28.854659666786006],
            [82.69567770210136, 28.86473641766844]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "NAWALPARASI WEST",
        Province: "Province 5"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [83.69528931000013, 27.66217180900003],
            [83.69366447200014, 27.665092333000075],
            [83.68411232767039, 27.663949546027283],
            [83.63914387900007, 27.691031995000174],
            [83.63252341151887, 27.689667584292064],
            [83.62944972390605, 27.664434639508283],
            [83.59965404564079, 27.645254943215075],
            [83.59305457957609, 27.626605912168365],
            [83.57455051674984, 27.617861622863902],
            [83.58063318000006, 27.574929975000032],
            [83.59448739700014, 27.574986921000118],
            [83.58981982500012, 27.524013029999992],
            [83.61417027393885, 27.472600908116743],
            [83.61484811300011, 27.46960406400018],
            [83.8300049720001, 27.370365723000134],
            [83.86278393200007, 27.346721211000045],
            [83.89063889900007, 27.36163783800015],
            [83.90526743800012, 27.38979889000018],
            [83.87316096800015, 27.39214258100003],
            [83.86937808800013, 27.429716323000093],
            [83.8955577750001, 27.437822915000083],
            [83.88512460900017, 27.481030299000167],
            [83.87475813700007, 27.489723986000115],
            [83.89725214000003, 27.500288427000157],
            [83.87568150100009, 27.510658922000175],
            [83.8827431380002, 27.523379377000083],
            [83.8506938590001, 27.55905474200017],
            [83.83731265200004, 27.551580407000188],
            [83.84104061100004, 27.574208666000118],
            [83.82372626800014, 27.592098105000105],
            [83.80818271000015, 27.588749491000158],
            [83.78989787100011, 27.60669884800018],
            [83.80515670300014, 27.620861229000013],
            [83.81185497500013, 27.66241512800019],
            [83.74479206900014, 27.666872360000184],
            [83.72518907400007, 27.67250481100018],
            [83.69528931000013, 27.66217180900003]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DAILEKH",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.9197449257738, 28.977716712638482],
            [81.88978025529366, 28.984398935697588],
            [81.8750455952037, 28.972197623176868],
            [81.8488523419764, 28.97219170865366],
            [81.8221689628988, 28.983293191176706],
            [81.7933915021766, 29.011971445524292],
            [81.76680306875046, 29.01840117754955],
            [81.75127957068784, 29.01026454089656],
            [81.7161318703288, 29.02000041406805],
            [81.691371167393, 29.049504144935483],
            [81.64447095896452, 29.040254321853464],
            [81.63118166038564, 29.04649587573512],
            [81.61261626871938, 29.034455003299975],
            [81.5637450923389, 29.053678251043642],
            [81.56775920261676, 29.095647447767615],
            [81.57857500856998, 29.1085288250976],
            [81.5792308856803, 29.135964583016793],
            [81.56555879170489, 29.126165442737317],
            [81.50990754175845, 29.090743455639974],
            [81.4688134272106, 29.07754152436008],
            [81.41538734989805, 29.017167964389905],
            [81.41456358687542, 28.99499377363973],
            [81.4447726452733, 28.94034976487846],
            [81.4665348145682, 28.928915864626397],
            [81.48285896093283, 28.903446576543487],
            [81.50838046417937, 28.890181083347134],
            [81.53172086077973, 28.85640484672219],
            [81.55689380790334, 28.797579525625302],
            [81.57931660891511, 28.78076494548594],
            [81.58130782713303, 28.759240222936192],
            [81.54650297296854, 28.749096523584004],
            [81.56352047622063, 28.723016607234303],
            [81.60060783008437, 28.707463099560638],
            [81.64200081087115, 28.675790333000172],
            [81.68977135599496, 28.67446352132712],
            [81.7016282250883, 28.66107504488549],
            [81.77580039223491, 28.633397528706045],
            [81.78157552038691, 28.64967172100842],
            [81.81074735587401, 28.68038013460649],
            [81.82006303235572, 28.72410907570714],
            [81.83619681047458, 28.740685001993715],
            [81.881729231486, 28.749714397171118],
            [81.88341442688765, 28.761376899561167],
            [81.91228950211476, 28.780004682288276],
            [81.88935325657495, 28.811438142418446],
            [81.87837366010658, 28.84891245012055],
            [81.89394514680306, 28.900752589143117],
            [81.9245375104987, 28.92486925822118],
            [81.9197449257738, 28.977716712638482]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DOLPA",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.57842783740091, 28.989934516207583],
            [82.58169491347036, 28.988913469858684],
            [82.64051498692837, 28.962876972287255],
            [82.66707445863975, 28.962558567927577],
            [82.68649835187362, 28.917264607338343],
            [82.69910253775035, 28.90958281168738],
            [82.69567770210136, 28.86473641766844],
            [82.70468579405531, 28.854659666786006],
            [82.74178725121452, 28.845864573194685],
            [82.76747219402402, 28.809793278570954],
            [82.78148059843666, 28.824139839174403],
            [82.81712246040932, 28.831343433785158],
            [82.84280520335938, 28.826554183448934],
            [82.86823537654399, 28.834118787348377],
            [82.94347558704249, 28.827389059312967],
            [82.9814237123236, 28.800641831768296],
            [82.99738268381827, 28.8039192606268],
            [83.08048895299565, 28.781351243096665],
            [83.11503725999795, 28.743222767209563],
            [83.13777335695758, 28.747836111663197],
            [83.1454322119335, 28.747158411360815],
            [83.18624452524014, 28.74365439578377],
            [83.20640276652934, 28.732621584553726],
            [83.22929742345703, 28.742889349643278],
            [83.27694414932168, 28.7219839797663],
            [83.33128320642457, 28.73821910013584],
            [83.36202070530413, 28.7341853031644],
            [83.3885178317509, 28.76310578220804],
            [83.41044492462908, 28.76067220841868],
            [83.48346184881362, 28.782888941051805],
            [83.4869302327658, 28.790587846120445],
            [83.48165651972403, 28.8447281505169],
            [83.50600607937203, 28.856128027953194],
            [83.49945567660447, 28.877252230629864],
            [83.57896768455056, 28.955149118650333],
            [83.59854344167955, 28.985818146691393],
            [83.60928997568115, 28.980311756252394],
            [83.61698605166991, 29.006237291575705],
            [83.61262454946043, 29.036159148020136],
            [83.6310722517398, 29.08015830015168],
            [83.61500120204674, 29.094374114875148],
            [83.62879122340053, 29.106569322828257],
            [83.64992322324001, 29.10453733436472],
            [83.65168368674597, 29.119569845967987],
            [83.6788572201312, 29.125110125547412],
            [83.67647941095014, 29.147316909665562],
            [83.65740384929119, 29.17617025870581],
            [83.63340031865768, 29.158514149616437],
            [83.61082880475756, 29.180545451539775],
            [83.58861561285295, 29.174559978261506],
            [83.5720813535021, 29.202578505229337],
            [83.53606838364313, 29.203886396491985],
            [83.51516898046114, 29.228070273785672],
            [83.51416772823404, 29.253923736340077],
            [83.47881278592108, 29.28108667757065],
            [83.4625576947159, 29.276856072644247],
            [83.43821485034665, 29.30916995302807],
            [83.4465722603018, 29.329149474975534],
            [83.43693151693944, 29.350533342009054],
            [83.41515998415716, 29.359090090738725],
            [83.41191256404412, 29.38040743523542],
            [83.42613250706245, 29.40268739311223],
            [83.4147191411487, 29.42031964218655],
            [83.38015188716878, 29.423616684482994],
            [83.34238573379396, 29.46587474092987],
            [83.34534658006132, 29.494321012330516],
            [83.3158525426198, 29.50895563880024],
            [83.28211269954915, 29.498763076969823],
            [83.25423826372064, 29.544674813833566],
            [83.25628760622337, 29.557511335468202],
            [83.28402760597851, 29.562912439681494],
            [83.26086552246694, 29.577660838161773],
            [83.23203805977786, 29.577896442332666],
            [83.21451427784145, 29.589312924831017],
            [83.21803629061381, 29.605532839465184],
            [83.19684187268354, 29.61117355521978],
            [83.17538147510334, 29.59231899129472],
            [83.15895365575328, 29.598159198723753],
            [83.16519945992505, 29.615728340148753],
            [83.13140594925517, 29.61794512442558],
            [83.06936948148189, 29.609961876716643],
            [83.06568979426204, 29.625911456741708],
            [83.04338323202127, 29.630874596748114],
            [83.03348987039413, 29.654246014591337],
            [83.02106525577368, 29.650710784342756],
            [83.00822676355527, 29.67253580210176],
            [82.95717847669816, 29.66230137316761],
            [82.9409607665134, 29.674155773213133],
            [82.94845515515205, 29.69161362649542],
            [82.93446418453769, 29.70449740026695],
            [82.88790262160424, 29.699714343997044],
            [82.86069874692541, 29.684597731019764],
            [82.82713290672012, 29.689841681784916],
            [82.82703402060321, 29.649099993217547],
            [82.7965140275685, 29.637473006762782],
            [82.794383511381, 29.627421853270523],
            [82.81922312380163, 29.559474133791902],
            [82.80898157306217, 29.556597314735793],
            [82.78648398552113, 29.518744646588875],
            [82.73268482097833, 29.508179035152402],
            [82.72358704100833, 29.47957288713345],
            [82.73485125341408, 29.469982321736683],
            [82.71484838831664, 29.43171985272714],
            [82.69026085517054, 29.425232913854206],
            [82.68724687866964, 29.41261598519444],
            [82.64239267744034, 29.385485493219225],
            [82.6239363221237, 29.398260034864883],
            [82.62277748166808, 29.42473035967916],
            [82.58506328931918, 29.421994071722963],
            [82.55847935331538, 29.411496919813374],
            [82.54813359129687, 29.380470980474723],
            [82.59051515165464, 29.32637782268225],
            [82.59073261920187, 29.303113807432904],
            [82.5592021712731, 29.288791878430395],
            [82.55198901715804, 29.265770108613655],
            [82.53095122934774, 29.25008008298441],
            [82.54980782978262, 29.207627868879783],
            [82.58214326879954, 29.189854610087473],
            [82.55280333834386, 29.16492969897646],
            [82.51447213690666, 29.15638851125448],
            [82.472595376167, 29.16366086546025],
            [82.44661263647073, 29.15782966578087],
            [82.43432102202222, 29.13919466450457],
            [82.41769851158247, 29.136732721757618],
            [82.41182955989234, 29.095581343821227],
            [82.40370216304647, 29.04064958856807],
            [82.39219014019318, 29.017770553937794],
            [82.43975712396326, 28.990411388698572],
            [82.4612055738686, 28.991359477253827],
            [82.49020075093804, 29.016272571636367],
            [82.53555506693648, 29.011376855014735],
            [82.57842783740091, 28.989934516207583]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "HUMLA",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.49763456533474, 29.94736743732087],
            [82.4761670619117, 29.973658651240576],
            [82.42369800858542, 29.995822428681862],
            [82.38127402856801, 30.02741808854908],
            [82.36301560334991, 30.010446799309086],
            [82.33413061697007, 30.04692019518043],
            [82.30512039810698, 30.03744860833671],
            [82.28299851108902, 30.059552831049132],
            [82.24407536030267, 30.075413339079677],
            [82.2314387286048, 30.06496220683426],
            [82.16623065066746, 30.07608203915534],
            [82.1864445291379, 30.111100458710958],
            [82.17175929663759, 30.11760775958853],
            [82.1972936802416, 30.136742816944935],
            [82.2054986666481, 30.153888314641563],
            [82.18242808026504, 30.16068340505695],
            [82.18698972705543, 30.185848466930636],
            [82.16258063831904, 30.189339350411586],
            [82.13263666791993, 30.206986691770975],
            [82.12650851931386, 30.22632146299499],
            [82.09840126159419, 30.23932445708811],
            [82.11712246735459, 30.257604618922862],
            [82.11023325844529, 30.27574749072427],
            [82.12829920876968, 30.30225817608036],
            [82.11130907415388, 30.34353777278166],
            [82.08534685000441, 30.359167670526467],
            [82.06593109465612, 30.355353880044817],
            [82.05601993330981, 30.333592926381627],
            [82.01139952873378, 30.342632622810417],
            [81.99814645416886, 30.320678578359246],
            [81.9391094527982, 30.347468016013917],
            [81.88216829529911, 30.360793887086164],
            [81.8677714075209, 30.377299305959017],
            [81.82577004774271, 30.375140358950137],
            [81.80881497104795, 30.387262345768278],
            [81.77406069060258, 30.384349386516757],
            [81.73170314673743, 30.398282847780266],
            [81.72925996890513, 30.4091962145581],
            [81.6201616915569, 30.447276331573793],
            [81.62202102020348, 30.425668786731713],
            [81.60681942826098, 30.41217479712041],
            [81.56469922971105, 30.429815164699523],
            [81.55200311223118, 30.40561835337581],
            [81.55565237348871, 30.374021906689993],
            [81.533303435439, 30.371755940558185],
            [81.48784320863506, 30.382021592966865],
            [81.43924919137785, 30.41851808444213],
            [81.40831641296349, 30.421266214664],
            [81.4014544493188, 30.395606187590836],
            [81.42922478092079, 30.383007637976796],
            [81.42011120098144, 30.370578820932487],
            [81.39741764502958, 30.370618259260752],
            [81.4057258648448, 30.336417623139152],
            [81.3966105481233, 30.323188631890797],
            [81.42328288180481, 30.305105086366506],
            [81.40204546097682, 30.293288608686115],
            [81.41524917969186, 30.284129032378328],
            [81.40875516745332, 30.261593521923636],
            [81.39200792468004, 30.250534783037075],
            [81.39645111820633, 30.209002097393476],
            [81.35064267008079, 30.17804872992651],
            [81.33213734820471, 30.153226461070595],
            [81.30443280180512, 30.159620171246438],
            [81.25810033312275, 30.151299904563093],
            [81.29045962690641, 30.11224272741618],
            [81.28748322232009, 30.06981095928085],
            [81.2719689146494, 30.045266265636126],
            [81.28466880234772, 30.037546040783578],
            [81.38195045308431, 30.007216335812554],
            [81.4020707505951, 30.021207843951277],
            [81.4545136222697, 30.002432013093404],
            [81.47005278436676, 29.975113556118085],
            [81.45276258564988, 29.95116921221851],
            [81.46267488806437, 29.920742346185804],
            [81.45678805421953, 29.90054698762416],
            [81.47069139688901, 29.889834186311845],
            [81.50560108457806, 29.89440997398642],
            [81.5165061514694, 29.922432536705774],
            [81.55026437041907, 29.923547762703834],
            [81.61289566738436, 29.943238940279446],
            [81.64629087157147, 29.94798044752212],
            [81.67071427465467, 29.940688407241574],
            [81.68735970948624, 29.94928151096108],
            [81.71621022019563, 29.909373861024832],
            [81.78557524801462, 29.910365970427996],
            [81.79921922148925, 29.901690914325275],
            [81.79421011661324, 29.848849640529743],
            [81.78078081814846, 29.839285482392697],
            [81.78036039529721, 29.80706682527371],
            [81.73979465432392, 29.792506516197044],
            [81.72649479124543, 29.733173359956186],
            [81.71500165525434, 29.72396363278193],
            [81.69734010145868, 29.680407116699435],
            [81.70005626673252, 29.643226400984172],
            [81.73854903348158, 29.606230089513435],
            [81.77377297266095, 29.605828669426295],
            [81.79112071453903, 29.627377006271843],
            [81.85298602086328, 29.61943091257616],
            [81.87303316862214, 29.63600231854479],
            [81.89166272250581, 29.668449588271734],
            [81.90977121556648, 29.6631975816448],
            [81.94917952160783, 29.676521561751624],
            [81.98767744913017, 29.70025817738929],
            [82.00610018705858, 29.727202400771542],
            [82.03550694917108, 29.74916193362418],
            [82.06034710482162, 29.746218057673705],
            [82.05946779705008, 29.732092935797706],
            [82.1011855964934, 29.684240766021418],
            [82.11370027371446, 29.652928444082942],
            [82.10283772387065, 29.63775246662093],
            [82.16956443475262, 29.65394719504729],
            [82.23394891245138, 29.6628584540512],
            [82.23294281568005, 29.699072603151915],
            [82.26125843800675, 29.702908331630997],
            [82.28182495039431, 29.692320742275967],
            [82.3392697257042, 29.715888222786905],
            [82.39252125368546, 29.696518801319595],
            [82.41407408460992, 29.731781051492064],
            [82.39765355293649, 29.762946827753645],
            [82.41166954052765, 29.77142192067059],
            [82.40636145572137, 29.811003314107833],
            [82.42267417432969, 29.807127912847136],
            [82.43986387612706, 29.824211930438874],
            [82.42988190710372, 29.83521062805576],
            [82.43503171770274, 29.860782611317195],
            [82.46260835997266, 29.865355579762223],
            [82.44762808921051, 29.888699293502267],
            [82.4743084721842, 29.906677238953737],
            [82.4702231124662, 29.91877885404389],
            [82.49763456533474, 29.94736743732087]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "JAJARKOT",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.41182955989234, 29.095581343821227],
            [82.35110117022843, 29.11669083890816],
            [82.32316733400799, 29.110060939989417],
            [82.27818662729123, 29.127521748330672],
            [82.26817581212903, 29.113007960463435],
            [82.22382974349586, 29.093873750626024],
            [82.1805083277661, 29.061840820649653],
            [82.17112015296807, 29.06951978848611],
            [82.147842133178, 29.048211826101745],
            [82.1466933179668, 29.03258826457222],
            [82.11754498090568, 29.007043553463568],
            [82.12363817080946, 28.99552527137075],
            [82.07836996727607, 28.96939476932427],
            [82.04979364597338, 28.98077719482663],
            [82.0387693322305, 29.012527339233714],
            [82.01166564986154, 29.02962425245079],
            [81.95829086301389, 28.985637934692164],
            [81.9197449257738, 28.977716712638482],
            [81.9245375104987, 28.92486925822118],
            [81.89394514680306, 28.900752589143117],
            [81.87837366010658, 28.84891245012055],
            [81.88935325657495, 28.811438142418446],
            [81.91228950211476, 28.780004682288276],
            [81.88341442688765, 28.761376899561167],
            [81.881729231486, 28.749714397171118],
            [81.83619681047458, 28.740685001993715],
            [81.82006303235572, 28.72410907570714],
            [81.85966195807235, 28.692419856424156],
            [81.86801943705275, 28.66406072301302],
            [81.8862462650231, 28.64309684715456],
            [81.95733742835279, 28.640740891726615],
            [82.03222285115282, 28.64445218039965],
            [82.03598945292813, 28.638014827470133],
            [82.03871222908309, 28.636879594353747],
            [82.04335986908357, 28.636579310061915],
            [82.04636541786275, 28.638070759143023],
            [82.04561522963685, 28.640634277294204],
            [82.04766979046695, 28.64252593053972],
            [82.05199506623872, 28.642842579514593],
            [82.05418288186154, 28.64427180613183],
            [82.05709841265441, 28.644442765777743],
            [82.05887587238799, 28.641484766031894],
            [82.05414637143971, 28.63667290715032],
            [82.05481007668043, 28.634498740020913],
            [82.05992110182767, 28.63320193210393],
            [82.06413726267223, 28.63114228157642],
            [82.06811401283016, 28.6303222130817],
            [82.0691590988524, 28.630299341147918],
            [82.07524647319899, 28.626028717777135],
            [82.07896931158736, 28.622308422929695],
            [82.08070099524545, 28.619695620620565],
            [82.08326183885106, 28.618105997201845],
            [82.08502629800158, 28.618471274670306],
            [82.08684664769041, 28.619178133966944],
            [82.08747498307346, 28.61957937478876],
            [82.0909992667763, 28.62071418988529],
            [82.09424897739774, 28.619091230445395],
            [82.09670250138386, 28.61919789059251],
            [82.09865814418197, 28.620626563694103],
            [82.10116523114908, 28.621579918053197],
            [82.10365472245458, 28.620577044920164],
            [82.10860555195313, 28.61929930672078],
            [82.11198490555016, 28.619930626502725],
            [82.11543761759586, 28.621300824894124],
            [82.11629130953716, 28.621591559563754],
            [82.11895498359914, 28.62320269657161],
            [82.1225621260012, 28.622893211671798],
            [82.1232138071261, 28.622688549691617],
            [82.12790526465673, 28.621853271993697],
            [82.1289732385087, 28.621675919487615],
            [82.12960637184516, 28.622866003479473],
            [82.13088028766606, 28.624054189000425],
            [82.13827212665049, 28.626594642659782],
            [82.14122398184192, 28.626163724936806],
            [82.14199477630731, 28.626095014161393],
            [82.14467779566269, 28.624386436388075],
            [82.14459609254354, 28.62354134606253],
            [82.14149907273504, 28.618075348667315],
            [82.14248316556987, 28.61612432495017],
            [82.14493735638699, 28.615318591631134],
            [82.1485683655542, 28.615301392582037],
            [82.15019545790445, 28.615113628544798],
            [82.15481674134807, 28.613387342172704],
            [82.15661092632325, 28.614234509428982],
            [82.15970041394229, 28.614194500841542],
            [82.1600513220955, 28.613996159711814],
            [82.16088203886754, 28.61379569939866],
            [82.16180585679919, 28.613351559395763],
            [82.16304166430321, 28.612623036447484],
            [82.16403751685775, 28.612092649926378],
            [82.16469703469785, 28.611726688058727],
            [82.16714485929336, 28.611366696892357],
            [82.16869429568436, 28.611791586318688],
            [82.17061659292945, 28.614661906594915],
            [82.17290355802437, 28.616223138439647],
            [82.17865312664522, 28.617867252136946],
            [82.1801732150353, 28.618925487432136],
            [82.18073075922462, 28.619234216463656],
            [82.18189884458653, 28.62020239541133],
            [82.19024731224812, 28.628340628545573],
            [82.19455114460709, 28.668826002304286],
            [82.22796822380212, 28.687963937575518],
            [82.24814206188132, 28.712438010345203],
            [82.27213058478813, 28.69370488734414],
            [82.29013142311058, 28.73820907615443],
            [82.28432510130096, 28.765385728016696],
            [82.2942962761059, 28.785076603152895],
            [82.34561667146168, 28.78685758592783],
            [82.36419462151788, 28.795806264441975],
            [82.38908605372438, 28.834710629404526],
            [82.43078973191444, 28.851369166912587],
            [82.46210237759573, 28.87498641540142],
            [82.49737533724021, 28.879211518903706],
            [82.50748493264128, 28.894937890285416],
            [82.5044046822441, 28.921330836249023],
            [82.51327058432187, 28.949976178173234],
            [82.54310002072214, 28.981955443777665],
            [82.57563318125892, 28.9918311129855],
            [82.57842783740091, 28.989934516207583],
            [82.53555506693648, 29.011376855014735],
            [82.49020075093804, 29.016272571636367],
            [82.4612055738686, 28.991359477253827],
            [82.43975712396326, 28.990411388698572],
            [82.39219014019318, 29.017770553937794],
            [82.40370216304647, 29.04064958856807],
            [82.41182955989234, 29.095581343821227]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "JUMLA",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.55847935331538, 29.411496919813374],
            [82.51311385771868, 29.420540487306333],
            [82.50032434082145, 29.457755764462078],
            [82.4956801780327, 29.493578161542324],
            [82.45270813969117, 29.464343342950002],
            [82.43234424041471, 29.443400322906445],
            [82.41055788892959, 29.46391496979783],
            [82.38594241329864, 29.501828385746308],
            [82.36465015170437, 29.490431462093436],
            [82.32867572883816, 29.508044553707304],
            [82.30989561813251, 29.50377431258753],
            [82.29370908248379, 29.442340957220356],
            [82.26346842459141, 29.44390563489808],
            [82.2219426219405, 29.428185084738125],
            [82.21343566723016, 29.407068690641474],
            [82.18083923580326, 29.406973618614117],
            [82.13942218853774, 29.43815493562319],
            [82.13095632751671, 29.46990253299093],
            [82.10620289268877, 29.459509973773713],
            [82.08485396706665, 29.481545613080602],
            [82.04115953044038, 29.48283976029009],
            [82.0148116835683, 29.462349315252045],
            [81.97912454887292, 29.4730031993038],
            [81.9648997831518, 29.446291443505483],
            [81.9309200173185, 29.437375874116476],
            [81.92446933283794, 29.3850811830555],
            [81.92780842165521, 29.330051878531243],
            [81.90741234589613, 29.32196604368281],
            [81.88060278445923, 29.27421889357037],
            [81.84786813357239, 29.26639618015622],
            [81.84992161442467, 29.2445375639645],
            [81.86963838898609, 29.225515546692424],
            [81.90237684481286, 29.21265831773916],
            [81.91071254892854, 29.20054337515947],
            [81.8957738297823, 29.175929345454733],
            [81.90367220895897, 29.157253883580307],
            [81.92646485568613, 29.15732831144457],
            [81.95017108612615, 29.142119553322633],
            [81.98087432998166, 29.107746177789032],
            [82.01710005704783, 29.092054760194834],
            [82.01166564986154, 29.02962425245079],
            [82.0387693322305, 29.012527339233714],
            [82.04979364597338, 28.98077719482663],
            [82.07836996727607, 28.96939476932427],
            [82.12363817080946, 28.99552527137075],
            [82.11754498090568, 29.007043553463568],
            [82.1466933179668, 29.03258826457222],
            [82.147842133178, 29.048211826101745],
            [82.17112015296807, 29.06951978848611],
            [82.1805083277661, 29.061840820649653],
            [82.22382974349586, 29.093873750626024],
            [82.26817581212903, 29.113007960463435],
            [82.27818662729123, 29.127521748330672],
            [82.32316733400799, 29.110060939989417],
            [82.35110117022843, 29.11669083890816],
            [82.41182955989234, 29.095581343821227],
            [82.41769851158247, 29.136732721757618],
            [82.43432102202222, 29.13919466450457],
            [82.44661263647073, 29.15782966578087],
            [82.472595376167, 29.16366086546025],
            [82.51447213690666, 29.15638851125448],
            [82.55280333834386, 29.16492969897646],
            [82.58214326879954, 29.189854610087473],
            [82.54980782978262, 29.207627868879783],
            [82.53095122934774, 29.25008008298441],
            [82.55198901715804, 29.265770108613655],
            [82.5592021712731, 29.288791878430395],
            [82.59073261920187, 29.303113807432904],
            [82.59051515165464, 29.32637782268225],
            [82.54813359129687, 29.380470980474723],
            [82.55847935331538, 29.411496919813374]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KALIKOT",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.9309200173185, 29.437375874116476],
            [81.88482151243196, 29.443450798149424],
            [81.8671084997201, 29.460569576452297],
            [81.80782552431272, 29.4539548879506],
            [81.78934632251799, 29.389042157860693],
            [81.76883875215805, 29.392252079215755],
            [81.74968156946044, 29.366575225488667],
            [81.73965919773813, 29.333983517374964],
            [81.7031926695558, 29.330616716471994],
            [81.67004630674737, 29.31691286705393],
            [81.64837193933955, 29.321203987284065],
            [81.64409213250671, 29.35435782430021],
            [81.6175085398161, 29.370660940586397],
            [81.55840658509328, 29.357725989266736],
            [81.52388553558919, 29.315134772090385],
            [81.50796678021322, 29.314621046780246],
            [81.48386704748845, 29.27961888224981],
            [81.46806658654761, 29.271904333856256],
            [81.49475869661963, 29.257173253121152],
            [81.49823453683365, 29.238857576261164],
            [81.48227304552887, 29.201284132237564],
            [81.49909659849892, 29.151756736237587],
            [81.52467328255841, 29.15275754228331],
            [81.53444320873614, 29.137707140575586],
            [81.56555879170489, 29.126165442737317],
            [81.5792308856803, 29.135964583016793],
            [81.57857500856998, 29.1085288250976],
            [81.56775920261676, 29.095647447767615],
            [81.5637450923389, 29.053678251043642],
            [81.61261626871938, 29.034455003299975],
            [81.63118166038564, 29.04649587573512],
            [81.64447095896452, 29.040254321853464],
            [81.691371167393, 29.049504144935483],
            [81.7161318703288, 29.02000041406805],
            [81.75127957068784, 29.01026454089656],
            [81.76680306875046, 29.01840117754955],
            [81.7933915021766, 29.011971445524292],
            [81.8221689628988, 28.983293191176706],
            [81.8488523419764, 28.97219170865366],
            [81.8750455952037, 28.972197623176868],
            [81.88978025529366, 28.984398935697588],
            [81.9197449257738, 28.977716712638482],
            [81.95829086301389, 28.985637934692164],
            [82.01166564986154, 29.02962425245079],
            [82.01710005704783, 29.092054760194834],
            [81.98087432998166, 29.107746177789032],
            [81.95017108612615, 29.142119553322633],
            [81.92646485568613, 29.15732831144457],
            [81.90367220895897, 29.157253883580307],
            [81.8957738297823, 29.175929345454733],
            [81.91071254892854, 29.20054337515947],
            [81.90237684481286, 29.21265831773916],
            [81.86963838898609, 29.225515546692424],
            [81.84992161442467, 29.2445375639645],
            [81.84786813357239, 29.26639618015622],
            [81.88060278445923, 29.27421889357037],
            [81.90741234589613, 29.32196604368281],
            [81.92780842165521, 29.330051878531243],
            [81.92446933283794, 29.3850811830555],
            [81.9309200173185, 29.437375874116476]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "MUGU",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.82713290672012, 29.689841681784916],
            [82.81790624525898, 29.721160800534243],
            [82.79764267341719, 29.7190658228375],
            [82.76294292025322, 29.73120673702779],
            [82.76674899590155, 29.742478174388577],
            [82.7447561610288, 29.769510879532902],
            [82.69327053365885, 29.761211151887792],
            [82.694125776874, 29.791194352513678],
            [82.71303494630088, 29.79441314060907],
            [82.73734173971718, 29.814523361573993],
            [82.72450835862668, 29.837539075613364],
            [82.69721149129433, 29.855366438275897],
            [82.6392096400568, 29.83390479015878],
            [82.61992937920722, 29.838165483273915],
            [82.64052890051614, 29.862945270096695],
            [82.63772367871331, 29.8748802330226],
            [82.60861634615078, 29.88449940344832],
            [82.58478164566455, 29.92131415748887],
            [82.5666788437713, 29.93248234301216],
            [82.56309820814342, 29.955229338796606],
            [82.53148585132575, 29.97117856447659],
            [82.49763456533474, 29.94736743732087],
            [82.4702231124662, 29.91877885404389],
            [82.4743084721842, 29.906677238953737],
            [82.44762808921051, 29.888699293502267],
            [82.46260835997266, 29.865355579762223],
            [82.43503171770274, 29.860782611317195],
            [82.42988190710372, 29.83521062805576],
            [82.43986387612706, 29.824211930438874],
            [82.42267417432969, 29.807127912847136],
            [82.40636145572137, 29.811003314107833],
            [82.41166954052765, 29.77142192067059],
            [82.39765355293649, 29.762946827753645],
            [82.41407408460992, 29.731781051492064],
            [82.39252125368546, 29.696518801319595],
            [82.3392697257042, 29.715888222786905],
            [82.28182495039431, 29.692320742275967],
            [82.26125843800675, 29.702908331630997],
            [82.23294281568005, 29.699072603151915],
            [82.23394891245138, 29.6628584540512],
            [82.16956443475262, 29.65394719504729],
            [82.10283772387065, 29.63775246662093],
            [82.11370027371446, 29.652928444082942],
            [82.1011855964934, 29.684240766021418],
            [82.05946779705008, 29.732092935797706],
            [82.06034710482162, 29.746218057673705],
            [82.03550694917108, 29.74916193362418],
            [82.00610018705858, 29.727202400771542],
            [81.98767744913017, 29.70025817738929],
            [81.94917952160783, 29.676521561751624],
            [81.90977121556648, 29.6631975816448],
            [81.89166272250581, 29.668449588271734],
            [81.87303316862214, 29.63600231854479],
            [81.85298602086328, 29.61943091257616],
            [81.79112071453903, 29.627377006271843],
            [81.77377297266095, 29.605828669426295],
            [81.7896925839385, 29.596678996253402],
            [81.79901691543864, 29.559231265517646],
            [81.77969495498687, 29.50877242609269],
            [81.80362302520668, 29.475644859395924],
            [81.80782552431272, 29.4539548879506],
            [81.8671084997201, 29.460569576452297],
            [81.88482151243196, 29.443450798149424],
            [81.9309200173185, 29.437375874116476],
            [81.9648997831518, 29.446291443505483],
            [81.97912454887292, 29.4730031993038],
            [82.0148116835683, 29.462349315252045],
            [82.04115953044038, 29.48283976029009],
            [82.08485396706665, 29.481545613080602],
            [82.10620289268877, 29.459509973773713],
            [82.13095632751671, 29.46990253299093],
            [82.13942218853774, 29.43815493562319],
            [82.18083923580326, 29.406973618614117],
            [82.21343566723016, 29.407068690641474],
            [82.2219426219405, 29.428185084738125],
            [82.26346842459141, 29.44390563489808],
            [82.29370908248379, 29.442340957220356],
            [82.30989561813251, 29.50377431258753],
            [82.32867572883816, 29.508044553707304],
            [82.36465015170437, 29.490431462093436],
            [82.38594241329864, 29.501828385746308],
            [82.41055788892959, 29.46391496979783],
            [82.43234424041471, 29.443400322906445],
            [82.45270813969117, 29.464343342950002],
            [82.4956801780327, 29.493578161542324],
            [82.50032434082145, 29.457755764462078],
            [82.51311385771868, 29.420540487306333],
            [82.55847935331538, 29.411496919813374],
            [82.58506328931918, 29.421994071722963],
            [82.62277748166808, 29.42473035967916],
            [82.6239363221237, 29.398260034864883],
            [82.64239267744034, 29.385485493219225],
            [82.68724687866964, 29.41261598519444],
            [82.69026085517054, 29.425232913854206],
            [82.71484838831664, 29.43171985272714],
            [82.73485125341408, 29.469982321736683],
            [82.72358704100833, 29.47957288713345],
            [82.73268482097833, 29.508179035152402],
            [82.78648398552113, 29.518744646588875],
            [82.80898157306217, 29.556597314735793],
            [82.81922312380163, 29.559474133791902],
            [82.794383511381, 29.627421853270523],
            [82.7965140275685, 29.637473006762782],
            [82.82703402060321, 29.649099993217547],
            [82.82713290672012, 29.689841681784916]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SALYAN",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.4267300313219, 28.252357899712415],
            [82.40362353408423, 28.262232926117218],
            [82.38329341480073, 28.29206465050212],
            [82.36319181579603, 28.34087274820001],
            [82.37890606936178, 28.35121365535111],
            [82.3835895067542, 28.372860132574342],
            [82.36358765950234, 28.38835373087419],
            [82.35390679729001, 28.414935773129997],
            [82.37660854149031, 28.430399441705195],
            [82.37381624395891, 28.448351620496293],
            [82.396601930037, 28.462033991167768],
            [82.41167503767943, 28.49271679322516],
            [82.4170599910535, 28.530682952843293],
            [82.39213819256702, 28.526793883777167],
            [82.37845264751301, 28.541440780060068],
            [82.37499655565783, 28.566464742490076],
            [82.33959290919753, 28.589594441335812],
            [82.29953622962589, 28.597634528905346],
            [82.27475147985979, 28.59458996880801],
            [82.23770813444116, 28.609580208392792],
            [82.22728779315312, 28.609423677766358],
            [82.2199264222677, 28.609452184744164],
            [82.20445591742951, 28.619981471140083],
            [82.18473621140896, 28.623130910473378],
            [82.18189884458653, 28.62020239541133],
            [82.18073075922462, 28.619234216463656],
            [82.1801732150353, 28.618925487432136],
            [82.17865312664522, 28.617867252136946],
            [82.17290355802437, 28.616223138439647],
            [82.17061659292945, 28.614661906594915],
            [82.16869429568436, 28.611791586318688],
            [82.16714485929336, 28.611366696892357],
            [82.16469703469785, 28.611726688058727],
            [82.16403751685775, 28.612092649926378],
            [82.16304166430321, 28.612623036447484],
            [82.16180585679919, 28.613351559395763],
            [82.16088203886754, 28.61379569939866],
            [82.1600513220955, 28.613996159711814],
            [82.15970041394229, 28.614194500841542],
            [82.15661092632325, 28.614234509428982],
            [82.15481674134807, 28.613387342172704],
            [82.15019545790445, 28.615113628544798],
            [82.1485683655542, 28.615301392582037],
            [82.14493735638699, 28.615318591631134],
            [82.14248316556987, 28.61612432495017],
            [82.14149907273504, 28.618075348667315],
            [82.14459609254354, 28.62354134606253],
            [82.14467779566269, 28.624386436388075],
            [82.14199477630731, 28.626095014161393],
            [82.14122398184192, 28.626163724936806],
            [82.13827212665049, 28.626594642659782],
            [82.13088028766606, 28.624054189000425],
            [82.12960637184516, 28.622866003479473],
            [82.1289732385087, 28.621675919487615],
            [82.12790526465673, 28.621853271993697],
            [82.1232138071261, 28.622688549691617],
            [82.1225621260012, 28.622893211671798],
            [82.11895498359914, 28.62320269657161],
            [82.11629130953716, 28.621591559563754],
            [82.11543761759586, 28.621300824894124],
            [82.11198490555016, 28.619930626502725],
            [82.10860555195313, 28.61929930672078],
            [82.10365472245458, 28.620577044920164],
            [82.10116523114908, 28.621579918053197],
            [82.09865814418197, 28.620626563694103],
            [82.09670250138386, 28.61919789059251],
            [82.09424897739774, 28.619091230445395],
            [82.0909992667763, 28.62071418988529],
            [82.08747498307346, 28.61957937478876],
            [82.08684664769041, 28.619178133966944],
            [82.08502629800158, 28.618471274670306],
            [82.08326183885106, 28.618105997201845],
            [82.08070099524545, 28.619695620620565],
            [82.07896931158736, 28.622308422929695],
            [82.07524647319899, 28.626028717777135],
            [82.0691590988524, 28.630299341147918],
            [82.06811401283016, 28.6303222130817],
            [82.06413726267223, 28.63114228157642],
            [82.05992110182767, 28.63320193210393],
            [82.05481007668043, 28.634498740020913],
            [82.05414637143971, 28.63667290715032],
            [82.05887587238799, 28.641484766031894],
            [82.05709841265441, 28.644442765777743],
            [82.05418288186154, 28.64427180613183],
            [82.05199506623872, 28.642842579514593],
            [82.04766979046695, 28.64252593053972],
            [82.04561522963685, 28.640634277294204],
            [82.04636541786275, 28.638070759143023],
            [82.04335986908357, 28.636579310061915],
            [82.03871222908309, 28.636879594353747],
            [82.03598945292813, 28.638014827470133],
            [82.03186386361386, 28.640265718402098],
            [82.02870283140945, 28.640628362103968],
            [82.02702746947223, 28.63853422097841],
            [82.02629529320913, 28.63253968957969],
            [82.02269965764178, 28.629013856894872],
            [82.02174551168486, 28.626853231636648],
            [82.0235530785802, 28.623298085226043],
            [82.02451810710447, 28.619700583639855],
            [82.02639471350095, 28.618007040160307],
            [82.02682956799933, 28.615819519693733],
            [82.02331979950883, 28.612354845406976],
            [82.02067353523599, 28.61260966623206],
            [82.01515055802403, 28.615096544209898],
            [82.0073772547738, 28.61522612730033],
            [82.00420036672568, 28.613526542063717],
            [82.00546266017616, 28.608030341468513],
            [82.00826237321304, 28.602001808273755],
            [82.01002455802559, 28.600083216880705],
            [82.01160369837632, 28.596066733741885],
            [82.01034506176043, 28.593566414222288],
            [82.00304478906968, 28.589505946725975],
            [82.00071448527048, 28.586916146416943],
            [82.00328100178653, 28.58031935402363],
            [82.00125139571882, 28.57702288187063],
            [81.99630155248938, 28.57582952504159],
            [81.99559192449382, 28.57263639935953],
            [81.99409454773613, 28.569985562097575],
            [81.99595842762464, 28.56703745695394],
            [81.99817826597456, 28.565155251438274],
            [82.00224425418695, 28.56379725586977],
            [82.0052956431592, 28.56124740767726],
            [82.00704259764333, 28.557797356700302],
            [82.00855789837578, 28.552155156215303],
            [82.01500385774344, 28.547442178818745],
            [82.01852191163516, 28.542092158498466],
            [82.01843796509395, 28.536020219024174],
            [82.01888847571855, 28.531866234290465],
            [82.01836192989309, 28.52777366823255],
            [82.02032133043834, 28.525421912043342],
            [82.02342667975066, 28.524306806874524],
            [82.02584713033023, 28.521906535328988],
            [82.02756066916473, 28.517869145219407],
            [82.02666346875351, 28.515891324940235],
            [82.02168981027481, 28.51389826312332],
            [82.02054360022582, 28.511632877638384],
            [82.02045242960344, 28.50773554926751],
            [82.02522724445153, 28.499141157928964],
            [82.01841003798155, 28.489244202649672],
            [82.01493155276181, 28.486421540943955],
            [82.00891786886363, 28.478342924230333],
            [82.00910812182207, 28.47667214370177],
            [82.0114303653382, 28.472941599791685],
            [82.01087814538329, 28.46864184706368],
            [82.0088825925182, 28.467077010598754],
            [82.00436580812587, 28.466741060511097],
            [82.00168909639355, 28.464118889926407],
            [81.99882690996377, 28.464363643579507],
            [81.99694426988366, 28.46320719803789],
            [81.99085685678071, 28.46198080477796],
            [81.98804205191509, 28.458401004576334],
            [81.98444743598948, 28.4558872346007],
            [81.9836939868817, 28.452427682527993],
            [81.98626477073455, 28.450947751090936],
            [81.98712376600916, 28.44833571814794],
            [81.98579951212534, 28.44676340860356],
            [81.98006708676832, 28.445140606598258],
            [81.97041309809332, 28.447032114471398],
            [81.96730069822412, 28.448643633619525],
            [81.96450867071427, 28.451363224201593],
            [81.96190701210091, 28.4504901823993],
            [81.96166336647325, 28.446949989334854],
            [81.9660343696562, 28.444467169021788],
            [81.96492840113034, 28.441382996629667],
            [81.9656606647096, 28.437465498472687],
            [81.96465896469039, 28.435144574548048],
            [81.96064141951013, 28.43207975239794],
            [81.96216376587152, 28.429928648167426],
            [81.96135092119282, 28.428335982361297],
            [81.9620375342339, 28.424800093797128],
            [81.96502025257743, 28.42315233655042],
            [81.96544429208129, 28.42072641698284],
            [81.96730782472801, 28.418707753287922],
            [81.97145867828836, 28.41639977180676],
            [81.97369953329645, 28.41081637986519],
            [81.97271751151989, 28.4090927636516],
            [81.97409069375716, 28.404593762020937],
            [81.97795689336027, 28.403623600886778],
            [81.98003129919528, 28.40004445905334],
            [81.98271697673225, 28.397681275578957],
            [81.98985542655703, 28.395394019492212],
            [81.98902512569698, 28.393958640940028],
            [81.98136058665223, 28.390984438317027],
            [81.97682633849956, 28.386471482036875],
            [81.97785947705037, 28.3828024588348],
            [81.97562263026076, 28.37521887979739],
            [81.9773256502011, 28.373487820858664],
            [81.9793320063447, 28.36783117366464],
            [81.98127728580131, 28.365098221229314],
            [81.98060273495608, 28.362608985028277],
            [81.97632654541638, 28.361094999983276],
            [81.97084188205709, 28.353410928483562],
            [81.9666615766618, 28.351154687651597],
            [81.96071141966632, 28.353078945712948],
            [81.95764924942787, 28.349225727227413],
            [81.95295023294422, 28.347074925768],
            [81.94616304710746, 28.3420532365339],
            [81.94291099689126, 28.341423813945678],
            [81.93947424749794, 28.341616556144803],
            [81.93702561554777, 28.337306313496377],
            [81.93079825420546, 28.337926913446584],
            [81.92683226844862, 28.339183369915737],
            [81.92418444223524, 28.34265288962471],
            [81.91445936362672, 28.347342952883732],
            [81.90741165932084, 28.349181189865465],
            [81.9024733547752, 28.35488522294169],
            [81.90049211562423, 28.35528409739151],
            [81.89526002516806, 28.35476973974274],
            [81.89153569581568, 28.35555276656911],
            [81.8889207994666, 28.35802901379096],
            [81.88714099821473, 28.357990733361458],
            [81.88494530402853, 28.36114291329057],
            [81.87918475945135, 28.361748656197427],
            [81.87235953910508, 28.36288254899058],
            [81.86888485430154, 28.364506015483293],
            [81.8659208129161, 28.36476170272218],
            [81.86290028995093, 28.367493967205633],
            [81.86275210768078, 28.368957460912924],
            [81.85511926498796, 28.373053272321222],
            [81.84996015171002, 28.37247128004087],
            [81.84548336370786, 28.373532372977106],
            [81.84219390560636, 28.375167928524533],
            [81.84214908052097, 28.3762735758082],
            [81.84221036542199, 28.37741922079519],
            [81.84188396097616, 28.378559104646474],
            [81.8410657943122, 28.37968893354466],
            [81.84026464765657, 28.379980396055625],
            [81.83819942060327, 28.380973536395537],
            [81.83551139421216, 28.381212032107012],
            [81.83465932758705, 28.380872052280083],
            [81.83211201397062, 28.379337102184902],
            [81.83101352574687, 28.379565948403577],
            [81.82990740819683, 28.37985200969622],
            [81.82850210606007, 28.38034339496556],
            [81.82659668131679, 28.38086467464791],
            [81.82360636168406, 28.380929479121935],
            [81.81867080410552, 28.381305127099754],
            [81.81841082482167, 28.381530089916392],
            [81.81683789359057, 28.382647153505996],
            [81.81447515104487, 28.38445953507202],
            [81.81344425954649, 28.38444063063361],
            [81.81331546447856, 28.384439129076945],
            [81.81235440525144, 28.388067877382213],
            [81.81112580443424, 28.389250468216485],
            [81.80913518909554, 28.389808265476155],
            [81.80747221898592, 28.390066529819418],
            [81.80410052022795, 28.387814114842513],
            [81.80067536059339, 28.389267335167382],
            [81.79868101137374, 28.3914874102788],
            [81.79400544171479, 28.392329146014838],
            [81.79227652896971, 28.389475106536317],
            [81.78650185647184, 28.389558946239983],
            [81.78377088152912, 28.3908004795912],
            [81.78001660923223, 28.3907472333845],
            [81.77605848659053, 28.38897197046784],
            [81.77580690868444, 28.38850795380581],
            [81.7757458830582, 28.38843166610924],
            [81.77560095058907, 28.388048322556763],
            [81.77157320018591, 28.38653206764803],
            [81.77086779384142, 28.38640476457803],
            [81.76990986846046, 28.385950044029702],
            [81.76974240696518, 28.385698609293048],
            [81.76844317933967, 28.382962488364168],
            [81.76823015889813, 28.378445090497635],
            [81.76821660466072, 28.378061625752537],
            [81.76820636629476, 28.377450369598773],
            [81.76873563203436, 28.375619189945187],
            [81.76883125797474, 28.375145778235854],
            [81.76915521699586, 28.374129693791204],
            [81.76888584044978, 28.373514429681954],
            [81.76800792292542, 28.37194475069842],
            [81.7675445580312, 28.371349549614962],
            [81.76733209113092, 28.370967578012667],
            [81.76671100285343, 28.37017664705284],
            [81.76535539142957, 28.365381384810945],
            [81.760069710563, 28.35912595594625],
            [81.75947271634283, 28.356133412073547],
            [81.75780456632825, 28.352977095539288],
            [81.75510416772252, 28.350459592536783],
            [81.75165626002273, 28.343721507076758],
            [81.75127494167332, 28.337748237867494],
            [81.75209123147324, 28.33446409750742],
            [81.75114238841626, 28.330935692868962],
            [81.74634720900872, 28.32635281772573],
            [81.74605582119734, 28.323719667724347],
            [81.74352174139904, 28.31905694015791],
            [81.745088747022, 28.31328676227423],
            [81.74806904865441, 28.312487606097363],
            [81.74915471150294, 28.31050043630255],
            [81.75380043530971, 28.30749670023286],
            [81.76122888775207, 28.309231703739012],
            [81.76423291673947, 28.305652760918225],
            [81.7674473762093, 28.300793501512132],
            [81.77092624022853, 28.29986060517167],
            [81.77378691684815, 28.297463326641825],
            [81.77645583126959, 28.297992565424483],
            [81.78028311057247, 28.295227495726174],
            [81.78524468376024, 28.295629961056452],
            [81.78803634393985, 28.2934053279284],
            [81.78924976405476, 28.28832054351575],
            [81.79069591262163, 28.286507061839202],
            [81.79090986330378, 28.283990421860466],
            [81.7922401658351, 28.281318435829036],
            [81.79143927801582, 28.277947460117066],
            [81.79330614383095, 28.27402331035993],
            [81.79810654015687, 28.27366594478316],
            [81.80444559901044, 28.280367832649027],
            [81.80584918211764, 28.28251723465136],
            [81.80916751461028, 28.28287964097271],
            [81.81453032303482, 28.278807897646576],
            [81.8172804567068, 28.278515710202605],
            [81.82208902313184, 28.276835490814673],
            [81.82624733370369, 28.277015716281436],
            [81.82953537912427, 28.275052871550816],
            [81.83806093863546, 28.272583030373674],
            [81.84383673432883, 28.271814302189036],
            [81.84513547958491, 28.269409752430192],
            [81.8503133364506, 28.265771069699273],
            [81.85389187975255, 28.260476667503006],
            [81.85853872921065, 28.25673867288754],
            [81.86149856205162, 28.255115707786256],
            [81.8648627759551, 28.24886213246413],
            [81.86356604596511, 28.243373272073796],
            [81.86057358882798, 28.23896280144039],
            [81.86375683818453, 28.238418439603006],
            [81.87196191351522, 28.239772689085378],
            [81.87667927578019, 28.239194828958063],
            [81.87861684698896, 28.24119357159151],
            [81.88290031163383, 28.24354886000968],
            [81.88709136206569, 28.240367958430795],
            [81.88863752418104, 28.236901798286954],
            [81.89240609659718, 28.235807948763],
            [81.89617747350782, 28.233473567236253],
            [81.90070109682179, 28.233315331519144],
            [81.90369644398852, 28.232349978800958],
            [81.90931000033332, 28.23228112169369],
            [81.91313540446794, 28.229031985240777],
            [81.91888504969128, 28.226870621633797],
            [81.92670659547873, 28.209495084992508],
            [81.9854531973118, 28.17652274755995],
            [81.99818492239235, 28.163677427254434],
            [81.99923700359679, 28.161574009770373],
            [82.00257919113001, 28.158071074674233],
            [82.00645206501014, 28.152168158413183],
            [82.00761403051011, 28.15110995943164],
            [82.02299960660437, 28.20073862202695],
            [82.05213165234288, 28.241056647280715],
            [82.06831911290314, 28.237063243061357],
            [82.1007316654995, 28.259024773353335],
            [82.13325915166666, 28.2512325006103],
            [82.14373313889622, 28.237192033325456],
            [82.16842591722069, 28.251398897260174],
            [82.19869542019897, 28.242958156980205],
            [82.21691184584807, 28.249562943170126],
            [82.23318331676829, 28.23999106116638],
            [82.27501713727652, 28.24826303735655],
            [82.30135796432153, 28.24386163920795],
            [82.3129610683528, 28.2232109254123],
            [82.33757017516197, 28.22355645841274],
            [82.3671459407322, 28.189868551913943],
            [82.39251027255727, 28.189361589695007],
            [82.39683591248212, 28.16892247132062],
            [82.40711804424758, 28.221097153805616],
            [82.42833998760153, 28.2311482477035],
            [82.4267300313219, 28.252357899712415]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "SURKHET",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.54650297296854, 28.749096523584004],
            [81.52235014090617, 28.748278409760125],
            [81.53505488798125, 28.761109632296098],
            [81.51547528453462, 28.780455202846518],
            [81.52322696991294, 28.799748946531334],
            [81.4982418149529, 28.82128299181323],
            [81.48799822680323, 28.84242157797367],
            [81.46280983194663, 28.853954176117696],
            [81.4633574485803, 28.894656453924142],
            [81.43083185136037, 28.91079427659518],
            [81.39586282820906, 28.90888262586372],
            [81.36951432844526, 28.891501842579952],
            [81.34907363383125, 28.890033652875882],
            [81.33640163560031, 28.907131332650994],
            [81.30319178900142, 28.92538776428483],
            [81.28657071782858, 28.922904282102436],
            [81.26289171932093, 28.902361269368804],
            [81.20421117515963, 28.90810716983523],
            [81.1687866539856, 28.921454409914542],
            [81.12073333556731, 28.962627828975176],
            [81.10382977658456, 28.96567151749217],
            [81.04816547389906, 28.97802434575099],
            [81.0189205689916, 28.95222032554883],
            [80.99863235284245, 28.954935441416534],
            [80.98105119931388, 28.938782292352755],
            [81.02159655001009, 28.91946054455933],
            [81.05763988600506, 28.873613153911236],
            [81.07392639300556, 28.875154965498353],
            [81.10179127398044, 28.84526454359203],
            [81.12551819455238, 28.85137152424275],
            [81.14443264980447, 28.87245173529451],
            [81.1577072481563, 28.843902379004177],
            [81.20507067363006, 28.81753743894808],
            [81.1952103851946, 28.833061241521015],
            [81.20024421343048, 28.8626801691522],
            [81.23375426282928, 28.82948081662724],
            [81.24227216842736, 28.81039682867388],
            [81.22611770988784, 28.797051990830415],
            [81.2457017942531, 28.78199814278072],
            [81.25956143339151, 28.750166408430008],
            [81.25612423195447, 28.729984867937763],
            [81.27049211404982, 28.72864398850447],
            [81.26703395120057, 28.7062722758416],
            [81.2853381305857, 28.6733143244241],
            [81.31838245295593, 28.669687583639895],
            [81.31694844432897, 28.6364070958286],
            [81.36252839849384, 28.624070262536588],
            [81.4008395451937, 28.60694365698622],
            [81.42494285255287, 28.58714667949337],
            [81.43466207264463, 28.56212243904505],
            [81.46639997900661, 28.553252795420242],
            [81.47424091416798, 28.54203618673284],
            [81.51151114904391, 28.524785142179788],
            [81.53904216959428, 28.494609357033156],
            [81.55734774623998, 28.495009306963553],
            [81.65885095905294, 28.415250445885356],
            [81.67430745341642, 28.417638238946417],
            [81.72957156617528, 28.378806363304545],
            [81.76429256464317, 28.371076782181103],
            [81.76733209113092, 28.370967578012667],
            [81.7675445580312, 28.371349549614962],
            [81.76800792292542, 28.37194475069842],
            [81.76888584044978, 28.373514429681954],
            [81.76915521699586, 28.374129693791204],
            [81.76883125797474, 28.375145778235854],
            [81.76873563203436, 28.375619189945187],
            [81.76820636629476, 28.377450369598773],
            [81.76821660466072, 28.378061625752537],
            [81.76823015889813, 28.378445090497635],
            [81.76844317933967, 28.382962488364168],
            [81.76974240696518, 28.385698609293048],
            [81.76990986846046, 28.385950044029702],
            [81.77086779384142, 28.38640476457803],
            [81.77157320018591, 28.38653206764803],
            [81.77560095058907, 28.388048322556763],
            [81.7757458830582, 28.38843166610924],
            [81.77580690868444, 28.38850795380581],
            [81.77605848659053, 28.38897197046784],
            [81.78001660923223, 28.3907472333845],
            [81.78377088152912, 28.3908004795912],
            [81.78650185647184, 28.389558946239983],
            [81.79227652896971, 28.389475106536317],
            [81.79400544171479, 28.392329146014838],
            [81.79868101137374, 28.3914874102788],
            [81.80067536059339, 28.389267335167382],
            [81.80410052022795, 28.387814114842513],
            [81.80747221898592, 28.390066529819418],
            [81.80913518909554, 28.389808265476155],
            [81.81112580443424, 28.389250468216485],
            [81.81235440525144, 28.388067877382213],
            [81.81331546447856, 28.384439129076945],
            [81.81344425954649, 28.38444063063361],
            [81.81447515104487, 28.38445953507202],
            [81.81683789359057, 28.382647153505996],
            [81.81841082482167, 28.381530089916392],
            [81.81867080410552, 28.381305127099754],
            [81.82360636168406, 28.380929479121935],
            [81.82659668131679, 28.38086467464791],
            [81.82850210606007, 28.38034339496556],
            [81.82990740819683, 28.37985200969622],
            [81.83101352574687, 28.379565948403577],
            [81.83211201397062, 28.379337102184902],
            [81.83465932758705, 28.380872052280083],
            [81.83551139421216, 28.381212032107012],
            [81.83819942060327, 28.380973536395537],
            [81.84026464765657, 28.379980396055625],
            [81.8410657943122, 28.37968893354466],
            [81.84188396097616, 28.378559104646474],
            [81.84221036542199, 28.37741922079519],
            [81.84214908052097, 28.3762735758082],
            [81.84219390560636, 28.375167928524533],
            [81.84548336370786, 28.373532372977106],
            [81.84996015171002, 28.37247128004087],
            [81.85511926498796, 28.373053272321222],
            [81.86275210768078, 28.368957460912924],
            [81.86290028995093, 28.367493967205633],
            [81.8659208129161, 28.36476170272218],
            [81.86888485430154, 28.364506015483293],
            [81.87235953910508, 28.36288254899058],
            [81.87918475945135, 28.361748656197427],
            [81.88494530402853, 28.36114291329057],
            [81.88714099821473, 28.357990733361458],
            [81.8889207994666, 28.35802901379096],
            [81.89153569581568, 28.35555276656911],
            [81.89526002516806, 28.35476973974274],
            [81.90049211562423, 28.35528409739151],
            [81.9024733547752, 28.35488522294169],
            [81.90741165932084, 28.349181189865465],
            [81.91445936362672, 28.347342952883732],
            [81.92418444223524, 28.34265288962471],
            [81.92683226844862, 28.339183369915737],
            [81.93079825420546, 28.337926913446584],
            [81.93702561554777, 28.337306313496377],
            [81.93947424749794, 28.341616556144803],
            [81.94291099689126, 28.341423813945678],
            [81.94616304710746, 28.3420532365339],
            [81.95295023294422, 28.347074925768],
            [81.95764924942787, 28.349225727227413],
            [81.96071141966632, 28.353078945712948],
            [81.9666615766618, 28.351154687651597],
            [81.97084188205709, 28.353410928483562],
            [81.97632654541638, 28.361094999983276],
            [81.98060273495608, 28.362608985028277],
            [81.98127728580131, 28.365098221229314],
            [81.9793320063447, 28.36783117366464],
            [81.9773256502011, 28.373487820858664],
            [81.97562263026076, 28.37521887979739],
            [81.97785947705037, 28.3828024588348],
            [81.97682633849956, 28.386471482036875],
            [81.98136058665223, 28.390984438317027],
            [81.98902512569698, 28.393958640940028],
            [81.98985542655703, 28.395394019492212],
            [81.98271697673225, 28.397681275578957],
            [81.98003129919528, 28.40004445905334],
            [81.97795689336027, 28.403623600886778],
            [81.97409069375716, 28.404593762020937],
            [81.97271751151989, 28.4090927636516],
            [81.97369953329645, 28.41081637986519],
            [81.97145867828836, 28.41639977180676],
            [81.96730782472801, 28.418707753287922],
            [81.96544429208129, 28.42072641698284],
            [81.96502025257743, 28.42315233655042],
            [81.9620375342339, 28.424800093797128],
            [81.96135092119282, 28.428335982361297],
            [81.96216376587152, 28.429928648167426],
            [81.96064141951013, 28.43207975239794],
            [81.96465896469039, 28.435144574548048],
            [81.9656606647096, 28.437465498472687],
            [81.96492840113034, 28.441382996629667],
            [81.9660343696562, 28.444467169021788],
            [81.96166336647325, 28.446949989334854],
            [81.96190701210091, 28.4504901823993],
            [81.96450867071427, 28.451363224201593],
            [81.96730069822412, 28.448643633619525],
            [81.97041309809332, 28.447032114471398],
            [81.98006708676832, 28.445140606598258],
            [81.98579951212534, 28.44676340860356],
            [81.98712376600916, 28.44833571814794],
            [81.98626477073455, 28.450947751090936],
            [81.9836939868817, 28.452427682527993],
            [81.98444743598948, 28.4558872346007],
            [81.98804205191509, 28.458401004576334],
            [81.99085685678071, 28.46198080477796],
            [81.99694426988366, 28.46320719803789],
            [81.99882690996377, 28.464363643579507],
            [82.00168909639355, 28.464118889926407],
            [82.00436580812587, 28.466741060511097],
            [82.0088825925182, 28.467077010598754],
            [82.01087814538329, 28.46864184706368],
            [82.0114303653382, 28.472941599791685],
            [82.00910812182207, 28.47667214370177],
            [82.00891786886363, 28.478342924230333],
            [82.01493155276181, 28.486421540943955],
            [82.01841003798155, 28.489244202649672],
            [82.02522724445153, 28.499141157928964],
            [82.02045242960344, 28.50773554926751],
            [82.02054360022582, 28.511632877638384],
            [82.02168981027481, 28.51389826312332],
            [82.02666346875351, 28.515891324940235],
            [82.02756066916473, 28.517869145219407],
            [82.02584713033023, 28.521906535328988],
            [82.02342667975066, 28.524306806874524],
            [82.02032133043834, 28.525421912043342],
            [82.01836192989309, 28.52777366823255],
            [82.01888847571855, 28.531866234290465],
            [82.01843796509395, 28.536020219024174],
            [82.01852191163516, 28.542092158498466],
            [82.01500385774344, 28.547442178818745],
            [82.00855789837578, 28.552155156215303],
            [82.00704259764333, 28.557797356700302],
            [82.0052956431592, 28.56124740767726],
            [82.00224425418695, 28.56379725586977],
            [81.99817826597456, 28.565155251438274],
            [81.99595842762464, 28.56703745695394],
            [81.99409454773613, 28.569985562097575],
            [81.99559192449382, 28.57263639935953],
            [81.99630155248938, 28.57582952504159],
            [82.00125139571882, 28.57702288187063],
            [82.00328100178653, 28.58031935402363],
            [82.00071448527048, 28.586916146416943],
            [82.00304478906968, 28.589505946725975],
            [82.01034506176043, 28.593566414222288],
            [82.01160369837632, 28.596066733741885],
            [82.01002455802559, 28.600083216880705],
            [82.00826237321304, 28.602001808273755],
            [82.00546266017616, 28.608030341468513],
            [82.00420036672568, 28.613526542063717],
            [82.0073772547738, 28.61522612730033],
            [82.01515055802403, 28.615096544209898],
            [82.02067353523599, 28.61260966623206],
            [82.02331979950883, 28.612354845406976],
            [82.02682956799933, 28.615819519693733],
            [82.02639471350095, 28.618007040160307],
            [82.02451810710447, 28.619700583639855],
            [82.0235530785802, 28.623298085226043],
            [82.02174551168486, 28.626853231636648],
            [82.02269965764178, 28.629013856894872],
            [82.02629529320913, 28.63253968957969],
            [82.02702746947223, 28.63853422097841],
            [82.02870283140945, 28.640628362103968],
            [82.03186386361386, 28.640265718402098],
            [82.03598945292813, 28.638014827470133],
            [82.03222285115282, 28.64445218039965],
            [81.95733742835279, 28.640740891726615],
            [81.8862462650231, 28.64309684715456],
            [81.86801943705275, 28.66406072301302],
            [81.85966195807235, 28.692419856424156],
            [81.82006303235572, 28.72410907570714],
            [81.81074735587401, 28.68038013460649],
            [81.78157552038691, 28.64967172100842],
            [81.77580039223491, 28.633397528706045],
            [81.7016282250883, 28.66107504488549],
            [81.68977135599496, 28.67446352132712],
            [81.64200081087115, 28.675790333000172],
            [81.60060783008437, 28.707463099560638],
            [81.56352047622063, 28.723016607234303],
            [81.54650297296854, 28.749096523584004]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "RUKUM WEST",
        Province: "Karnali"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [82.27475147985979, 28.59458996880801],
            [82.33981639924292, 28.589643195337203],
            [82.37500155582978, 28.5664800707803],
            [82.38289137868139, 28.53040926094843],
            [82.42108652875203, 28.527211271168767],
            [82.45983784569987, 28.52101462966324],
            [82.49101952025656, 28.53812054639962],
            [82.49218704129721, 28.552633535568695],
            [82.52634595595218, 28.558209871625554],
            [82.52905677668048, 28.558259592347383],
            [82.55395768360995, 28.581651405823663],
            [82.5809934339926, 28.587233019030805],
            [82.58060990689768, 28.612446435671927],
            [82.56123981448536, 28.61009269406992],
            [82.48581457805454, 28.640649441977587],
            [82.48993337432108, 28.659320523542476],
            [82.52154628931675, 28.659386771592892],
            [82.56069266947304, 28.646568379423343],
            [82.59984501343621, 28.670400664577755],
            [82.61469704787118, 28.688864229794547],
            [82.60141786166758, 28.74291067005538],
            [82.56616016315769, 28.74439984811727],
            [82.56408661841641, 28.76578502752824],
            [82.62009153071102, 28.811233798487706],
            [82.61368399215968, 28.826586911179504],
            [82.64859467668566, 28.869228019089178],
            [82.67364740541223, 28.852580542290525],
            [82.69567770210136, 28.86473641766844],
            [82.69910253775035, 28.90958281168738],
            [82.68649835187362, 28.917264607338343],
            [82.66707445863975, 28.962558567927577],
            [82.64051498692837, 28.962876972287255],
            [82.58169491347036, 28.988913469858684],
            [82.57563318125892, 28.9918311129855],
            [82.54310002072214, 28.981955443777665],
            [82.51327058432187, 28.949976178173234],
            [82.5044046822441, 28.921330836249023],
            [82.50748493264128, 28.894937890285416],
            [82.49737533724021, 28.879211518903706],
            [82.46210237759573, 28.87498641540142],
            [82.43078973191444, 28.851369166912587],
            [82.38908605372438, 28.834710629404526],
            [82.36419462151788, 28.795806264441975],
            [82.34561667146168, 28.78685758592783],
            [82.2942962761059, 28.785076603152895],
            [82.28432510130096, 28.765385728016696],
            [82.29013142311058, 28.73820907615443],
            [82.27213058478813, 28.69370488734414],
            [82.24814206188132, 28.712438010345203],
            [82.22796822380212, 28.687963937575518],
            [82.19455114460709, 28.668826002304286],
            [82.19024731224812, 28.628340628545573],
            [82.18473621140896, 28.623130910473378],
            [82.20445591742951, 28.619981471140083],
            [82.2199264222677, 28.609452184744164],
            [82.22728779315312, 28.609423677766358],
            [82.23770813444116, 28.609580208392792],
            [82.27475147985979, 28.59458996880801]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "ACHHAM",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.46806658654761, 29.271904333856256],
            [81.44300217922685, 29.287438985860454],
            [81.41735320933807, 29.278476175813566],
            [81.38782668525909, 29.30004401710116],
            [81.35535464259425, 29.284044466511688],
            [81.30881180691723, 29.324852969984907],
            [81.29508461561096, 29.310386647856053],
            [81.26922188884703, 29.30362907015758],
            [81.24998092943677, 29.34952488930427],
            [81.20137318866605, 29.365029303390248],
            [81.17000548911318, 29.386671240168813],
            [81.15326280238372, 29.38160499978504],
            [81.13568161932407, 29.30488491365312],
            [81.12069026104498, 29.299370488490656],
            [81.09789249576691, 29.247281770199564],
            [81.11509935990604, 29.234347203371016],
            [81.09965092058097, 29.214799900709757],
            [81.063787281266, 29.1982827412008],
            [81.03545557688508, 29.199032997762735],
            [81.0268342949157, 29.189024013953603],
            [81.04329633266188, 29.17545130771875],
            [81.06503444451718, 29.13650335789396],
            [81.10197074286359, 29.100782609941074],
            [81.14510490312833, 29.033817799021396],
            [81.14529160615716, 28.993467822745774],
            [81.10382977658456, 28.96567151749217],
            [81.12073333556731, 28.962627828975176],
            [81.1687866539856, 28.921454409914542],
            [81.20421117515963, 28.90810716983523],
            [81.26289171932093, 28.902361269368804],
            [81.28657071782858, 28.922904282102436],
            [81.30319178900142, 28.92538776428483],
            [81.33640163560031, 28.907131332650994],
            [81.34907363383125, 28.890033652875882],
            [81.36951432844526, 28.891501842579952],
            [81.39586282820906, 28.90888262586372],
            [81.43083185136037, 28.91079427659518],
            [81.4633574485803, 28.894656453924142],
            [81.46280983194663, 28.853954176117696],
            [81.48799822680323, 28.84242157797367],
            [81.4982418149529, 28.82128299181323],
            [81.52322696991294, 28.799748946531334],
            [81.51547528453462, 28.780455202846518],
            [81.53505488798125, 28.761109632296098],
            [81.52235014090617, 28.748278409760125],
            [81.54650297296854, 28.749096523584004],
            [81.58130782713303, 28.759240222936192],
            [81.57931660891511, 28.78076494548594],
            [81.55689380790334, 28.797579525625302],
            [81.53172086077973, 28.85640484672219],
            [81.50838046417937, 28.890181083347134],
            [81.48285896093283, 28.903446576543487],
            [81.4665348145682, 28.928915864626397],
            [81.4447726452733, 28.94034976487846],
            [81.41456358687542, 28.99499377363973],
            [81.41538734989805, 29.017167964389905],
            [81.4688134272106, 29.07754152436008],
            [81.50990754175845, 29.090743455639974],
            [81.56555879170489, 29.126165442737317],
            [81.53444320873614, 29.137707140575586],
            [81.52467328255841, 29.15275754228331],
            [81.49909659849892, 29.151756736237587],
            [81.48227304552887, 29.201284132237564],
            [81.49823453683365, 29.238857576261164],
            [81.49475869661963, 29.257173253121152],
            [81.46806658654761, 29.271904333856256]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BAITADI",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.79602009167765, 29.688323979081407],
            [80.75490223066282, 29.707873424787927],
            [80.70730178474874, 29.699643102961346],
            [80.6856542513364, 29.704003106475938],
            [80.67437182805996, 29.687246298724098],
            [80.57292602786258, 29.687120544651698],
            [80.56938045604187, 29.6792574314742],
            [80.50643610821662, 29.644762475302997],
            [80.4951962362619, 29.624167470498733],
            [80.44987344858689, 29.62532705349886],
            [80.40894802631045, 29.605015596695715],
            [80.37967326947849, 29.57176369373579],
            [80.37904083274631, 29.561484407047136],
            [80.34277676557288, 29.552932773922237],
            [80.35807829894604, 29.53254507270037],
            [80.34136214120909, 29.509979750429373],
            [80.3152162033963, 29.500024623743823],
            [80.3131477321046, 29.48453849803216],
            [80.28669426532902, 29.47506569791039],
            [80.30258386584484, 29.453119710387426],
            [80.26926103695547, 29.455061188419634],
            [80.2461002233843, 29.446755631443292],
            [80.24173074823901, 29.411615956611268],
            [80.27565209319023, 29.391522383289004],
            [80.27120893765358, 29.364539123508198],
            [80.29628968900772, 29.324248658016312],
            [80.31708850633855, 29.313387493244793],
            [80.32651422704507, 29.308547437341613],
            [80.36757639789228, 29.348995623326324],
            [80.4019672460323, 29.360709873798942],
            [80.43119854472647, 29.3925384803707],
            [80.45857761825413, 29.39540435642277],
            [80.47987541328617, 29.3857380270431],
            [80.50260308633531, 29.398884941572735],
            [80.52899501717883, 29.391816868337518],
            [80.54799211347877, 29.39874663644021],
            [80.55060962996707, 29.418199148720642],
            [80.57635432598775, 29.41185262608486],
            [80.5816694063503, 29.396506136962113],
            [80.61566668138751, 29.396574675089244],
            [80.65055956175578, 29.382565988269857],
            [80.67873951510205, 29.40166341523206],
            [80.66895920044077, 29.418965352164143],
            [80.68212673242188, 29.42514187172734],
            [80.74652655574572, 29.400501431852526],
            [80.76756200076548, 29.39957653198493],
            [80.76252835652944, 29.385568912066724],
            [80.78580241793152, 29.366428128956272],
            [80.81467573911041, 29.364847327146045],
            [80.83581845018469, 29.38121761574412],
            [80.85316286664082, 29.378659512183752],
            [80.85200851388987, 29.405943056291026],
            [80.87336343171904, 29.41166741222816],
            [80.9082703449273, 29.441461988081823],
            [80.88535884981516, 29.442030706841287],
            [80.87614333163593, 29.462826507768426],
            [80.8564069333493, 29.469093168194938],
            [80.84491523599992, 29.48940829736951],
            [80.81629366043478, 29.51596368429537],
            [80.78630659614788, 29.507755705051817],
            [80.78065774078065, 29.52327885851453],
            [80.74752944707623, 29.53250007303649],
            [80.7473410991929, 29.55145822056],
            [80.77131005290357, 29.551875857414142],
            [80.79088182573129, 29.64139290833154],
            [80.76198350658365, 29.64749521747152],
            [80.79602009167765, 29.688323979081407]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BAJHANG",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.09091193968261, 30.054222151691363],
            [81.06862207035249, 30.04351768716176],
            [81.03812338644306, 30.015095233081368],
            [81.01498435116973, 29.980717314568725],
            [81.02159781916798, 29.958646366316156],
            [81.00300110922939, 29.92327272463182],
            [81.02586990848049, 29.908151515251486],
            [81.04079568252291, 29.88171077363721],
            [81.04005523549797, 29.840673832946187],
            [81.06985984780482, 29.828409316980455],
            [81.06783830514155, 29.79594848360021],
            [81.05676497754085, 29.757746761323997],
            [81.03279401110858, 29.743571852319146],
            [80.98724888236595, 29.740478369383577],
            [80.97523240907428, 29.72191778016027],
            [80.9398985347538, 29.735460234854443],
            [80.86469966731661, 29.70635739775204],
            [80.8404225061378, 29.68742406465989],
            [80.79602009167765, 29.688323979081407],
            [80.76198350658365, 29.64749521747152],
            [80.79088182573129, 29.64139290833154],
            [80.77131005290357, 29.551875857414142],
            [80.7473410991929, 29.55145822056],
            [80.74752944707623, 29.53250007303649],
            [80.78065774078065, 29.52327885851453],
            [80.78630659614788, 29.507755705051817],
            [80.81629366043478, 29.51596368429537],
            [80.84491523599992, 29.48940829736951],
            [80.8564069333493, 29.469093168194938],
            [80.87614333163593, 29.462826507768426],
            [80.88535884981516, 29.442030706841287],
            [80.9082703449273, 29.441461988081823],
            [80.92659172453651, 29.425480599161105],
            [80.93935299694164, 29.397839048465617],
            [80.98687711781893, 29.40517190093375],
            [81.00847722847985, 29.419843234476232],
            [81.06401767652011, 29.40550454340334],
            [81.09568042847815, 29.407842792723134],
            [81.14398553956491, 29.390940315631738],
            [81.16371077652003, 29.394756774288194],
            [81.17066754010649, 29.411629591502106],
            [81.18678869179823, 29.397861843616734],
            [81.20202964253592, 29.39574630228444],
            [81.22645546737567, 29.41176523956749],
            [81.25184109298209, 29.41333473483676],
            [81.26214545935831, 29.433857253677076],
            [81.30774577539763, 29.4599320245955],
            [81.29214432579653, 29.47584251255629],
            [81.33870732723652, 29.505060001781562],
            [81.37149968883699, 29.55001720621964],
            [81.39878482815577, 29.557248574125477],
            [81.4020937747465, 29.584687036751177],
            [81.41905139438381, 29.598833555443527],
            [81.42039503818852, 29.633892398497174],
            [81.51585347549802, 29.679444758207755],
            [81.52444031982512, 29.710221787282833],
            [81.50797983459144, 29.74167166563722],
            [81.51230998462411, 29.752664187704198],
            [81.53981874877266, 29.747103269021387],
            [81.56060485780243, 29.77062526954949],
            [81.54853927398462, 29.801711613467003],
            [81.55838471830327, 29.80975861240067],
            [81.56468129338549, 29.851877911597693],
            [81.54130415909711, 29.878164239676902],
            [81.50560108457806, 29.89440997398642],
            [81.47069139688901, 29.889834186311845],
            [81.45678805421953, 29.90054698762416],
            [81.46267488806437, 29.920742346185804],
            [81.45276258564988, 29.95116921221851],
            [81.47005278436676, 29.975113556118085],
            [81.4545136222697, 30.002432013093404],
            [81.4020707505951, 30.021207843951277],
            [81.38195045308431, 30.007216335812554],
            [81.28466880234772, 30.037546040783578],
            [81.2719689146494, 30.045266265636126],
            [81.23744721905949, 30.031568042450562],
            [81.24540448021263, 30.011211388331816],
            [81.21747220480873, 30.00731113790927],
            [81.20772737925314, 30.02044470271412],
            [81.14920146431862, 30.011612705164083],
            [81.12388998584163, 30.020299986992026],
            [81.09091193968261, 30.054222151691363]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "BAJURA",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.46806658654761, 29.271904333856256],
            [81.48386704748845, 29.27961888224981],
            [81.50796678021322, 29.314621046780246],
            [81.52388553558919, 29.315134772090385],
            [81.55840658509328, 29.357725989266736],
            [81.6175085398161, 29.370660940586397],
            [81.64409213250671, 29.35435782430021],
            [81.64837193933955, 29.321203987284065],
            [81.67004630674737, 29.31691286705393],
            [81.7031926695558, 29.330616716471994],
            [81.73965919773813, 29.333983517374964],
            [81.74968156946044, 29.366575225488667],
            [81.76883875215805, 29.392252079215755],
            [81.78934632251799, 29.389042157860693],
            [81.80782552431272, 29.4539548879506],
            [81.80362302520668, 29.475644859395924],
            [81.77969495498687, 29.50877242609269],
            [81.79901691543864, 29.559231265517646],
            [81.7896925839385, 29.596678996253402],
            [81.77377297266095, 29.605828669426295],
            [81.73854903348158, 29.606230089513435],
            [81.70005626673252, 29.643226400984172],
            [81.69734010145868, 29.680407116699435],
            [81.71500165525434, 29.72396363278193],
            [81.72649479124543, 29.733173359956186],
            [81.73979465432392, 29.792506516197044],
            [81.78036039529721, 29.80706682527371],
            [81.78078081814846, 29.839285482392697],
            [81.79421011661324, 29.848849640529743],
            [81.79921922148925, 29.901690914325275],
            [81.78557524801462, 29.910365970427996],
            [81.71621022019563, 29.909373861024832],
            [81.68735970948624, 29.94928151096108],
            [81.67071427465467, 29.940688407241574],
            [81.64629087157147, 29.94798044752212],
            [81.61289566738436, 29.943238940279446],
            [81.55026437041907, 29.923547762703834],
            [81.5165061514694, 29.922432536705774],
            [81.50560108457806, 29.89440997398642],
            [81.54130415909711, 29.878164239676902],
            [81.56468129338549, 29.851877911597693],
            [81.55838471830327, 29.80975861240067],
            [81.54853927398462, 29.801711613467003],
            [81.56060485780243, 29.77062526954949],
            [81.53981874877266, 29.747103269021387],
            [81.51230998462411, 29.752664187704198],
            [81.50797983459144, 29.74167166563722],
            [81.52444031982512, 29.710221787282833],
            [81.51585347549802, 29.679444758207755],
            [81.42039503818852, 29.633892398497174],
            [81.41905139438381, 29.598833555443527],
            [81.4020937747465, 29.584687036751177],
            [81.39878482815577, 29.557248574125477],
            [81.37149968883699, 29.55001720621964],
            [81.33870732723652, 29.505060001781562],
            [81.29214432579653, 29.47584251255629],
            [81.30774577539763, 29.4599320245955],
            [81.26214545935831, 29.433857253677076],
            [81.25184109298209, 29.41333473483676],
            [81.22645546737567, 29.41176523956749],
            [81.20202964253592, 29.39574630228444],
            [81.18678869179823, 29.397861843616734],
            [81.17000548911318, 29.386671240168813],
            [81.20137318866605, 29.365029303390248],
            [81.24998092943677, 29.34952488930427],
            [81.26922188884703, 29.30362907015758],
            [81.29508461561096, 29.310386647856053],
            [81.30881180691723, 29.324852969984907],
            [81.35535464259425, 29.284044466511688],
            [81.38782668525909, 29.30004401710116],
            [81.41735320933807, 29.278476175813566],
            [81.44300217922685, 29.287438985860454],
            [81.46806658654761, 29.271904333856256]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DADELDHURA",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.78580241793152, 29.366428128956272],
            [80.76252835652944, 29.385568912066724],
            [80.76756200076548, 29.39957653198493],
            [80.74652655574572, 29.400501431852526],
            [80.68212673242188, 29.42514187172734],
            [80.66895920044077, 29.418965352164143],
            [80.67873951510205, 29.40166341523206],
            [80.65055956175578, 29.382565988269857],
            [80.61566668138751, 29.396574675089244],
            [80.5816694063503, 29.396506136962113],
            [80.57635432598775, 29.41185262608486],
            [80.55060962996707, 29.418199148720642],
            [80.54799211347877, 29.39874663644021],
            [80.52899501717883, 29.391816868337518],
            [80.50260308633531, 29.398884941572735],
            [80.47987541328617, 29.3857380270431],
            [80.45857761825413, 29.39540435642277],
            [80.43119854472647, 29.3925384803707],
            [80.4019672460323, 29.360709873798942],
            [80.36757639789228, 29.348995623326324],
            [80.32651422704507, 29.308547437341613],
            [80.31708850633855, 29.313387493244793],
            [80.30437787558606, 29.28531592103016],
            [80.29921585083237, 29.240090240206424],
            [80.29083556397183, 29.231918971107433],
            [80.29748243781148, 29.205571778690178],
            [80.28809832420859, 29.19505815511265],
            [80.24917749911151, 29.22137877769679],
            [80.24112955502835, 29.20422198318947],
            [80.2580130022702, 29.1873812054916],
            [80.25844121468745, 29.165812613344205],
            [80.27288908021593, 29.143547128013953],
            [80.23527404512103, 29.119111851672486],
            [80.18551261240606, 29.135199313088],
            [80.18653777685519, 29.086740315791403],
            [80.22774501725945, 29.04558504196757],
            [80.25127787780836, 29.052564415623152],
            [80.27234041040803, 29.045944536863985],
            [80.30128463178144, 29.058529637563936],
            [80.34737852013428, 29.05408772944852],
            [80.4152463842961, 29.032673051655244],
            [80.42034247118282, 29.026498629368646],
            [80.46052362990818, 29.03681689579257],
            [80.47829424957848, 29.017708589115724],
            [80.47183464564384, 28.996846778789983],
            [80.48573172499421, 28.998240565906734],
            [80.50146215209838, 28.993357401017],
            [80.53553104856769, 29.01401606548146],
            [80.53426291192966, 29.0297660740939],
            [80.56117175461715, 29.068159677107843],
            [80.53165135923717, 29.091540125183787],
            [80.52675305176925, 29.105959712871538],
            [80.5669733414248, 29.119248955966036],
            [80.56749967585499, 29.14488493167146],
            [80.58872748230124, 29.147399267727142],
            [80.5922011960291, 29.163044404268163],
            [80.61980719420926, 29.181108359548915],
            [80.62938495917136, 29.176391420045636],
            [80.704509738989, 29.192391032084007],
            [80.7068373554084, 29.2314023981308],
            [80.73896258330359, 29.2469725844865],
            [80.75689578283637, 29.26837492226701],
            [80.74382702924225, 29.27484246443707],
            [80.73766861662018, 29.30220137926506],
            [80.77208254158086, 29.301502061526698],
            [80.77612144266078, 29.315862012330655],
            [80.75766281300918, 29.344741129163076],
            [80.78580241793152, 29.366428128956272]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DARCHULA",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.60803075791318, 30.47311097529132],
            [80.6084553179586, 30.471699093638335],
            [80.60850384807418, 30.471536631110666],
            [80.60851229630549, 30.471508346533028],
            [80.6091490693741, 30.469376588159093],
            [80.6098908463855, 30.467575867532332],
            [80.61020990156578, 30.466868887089504],
            [80.6103210649652, 30.466622560083124],
            [80.61042480895782, 30.466392674482393],
            [80.61050865455081, 30.466206879044307],
            [80.61079596276352, 30.46557022378687],
            [80.61091006065084, 30.465317391084795],
            [80.61214185846006, 30.463861313149152],
            [80.61270365864698, 30.46319720608625],
            [80.61306134690369, 30.462774373638467],
            [80.61333432981553, 30.462580902486707],
            [80.61437949581716, 30.461840149803095],
            [80.61545462902797, 30.461031710545058],
            [80.61719218397218, 30.46107042096321],
            [80.617306266571, 30.461072961547984],
            [80.61815095300415, 30.46146144349086],
            [80.61885767714017, 30.4617864701695],
            [80.62099964641658, 30.462852909634137],
            [80.6238180065958, 30.463907875548045],
            [80.62384701242974, 30.463917938062423],
            [80.62485055690775, 30.464266033250908],
            [80.62516397963401, 30.46437474599776],
            [80.62520563443252, 30.464291317690197],
            [80.62526647806556, 30.46418808001596],
            [80.6257603830353, 30.463350008995747],
            [80.62598385017458, 30.46298120691722],
            [80.62603298373529, 30.46290011864562],
            [80.6266339323102, 30.461908319313522],
            [80.62827884539246, 30.460516564487932],
            [80.62894189665127, 30.459667029809623],
            [80.62960799571249, 30.45881357048995],
            [80.62991227952813, 30.45872220656463],
            [80.63204945002218, 30.458080479130842],
            [80.63332173710853, 30.456818898473443],
            [80.63347543574281, 30.456714558230317],
            [80.63424239107354, 30.456193895731758],
            [80.63456487806673, 30.45597496657149],
            [80.63661454452568, 30.454122141923335],
            [80.63665570919369, 30.454084929775718],
            [80.63677255630847, 30.453979299904802],
            [80.63725456504619, 30.453582272603853],
            [80.63804532003553, 30.452930921524853],
            [80.6395584293781, 30.452827690145853],
            [80.64102052197956, 30.453111010764587],
            [80.64221194472555, 30.45318239445197],
            [80.64270119750233, 30.453211705156093],
            [80.6428086458024, 30.45314131252161],
            [80.64293292131731, 30.453059895997455],
            [80.6434414204852, 30.452726761031272],
            [80.64372452166941, 30.45256827350704],
            [80.64454811810123, 30.452107195590656],
            [80.64505385994772, 30.4518240584336],
            [80.64537540894804, 30.451726722110607],
            [80.64642938650707, 30.451407662433724],
            [80.64674200613871, 30.451313025875493],
            [80.64786623693732, 30.449747391628875],
            [80.64828169854161, 30.44916879300547],
            [80.64956492408203, 30.448511322939225],
            [80.64978334692398, 30.448399409505214],
            [80.65072354315703, 30.44823086486258],
            [80.65130524444152, 30.448126582176087],
            [80.6522880289694, 30.447827401113727],
            [80.65244533118715, 30.447779514912725],
            [80.65369941689465, 30.44739772842246],
            [80.65371295259075, 30.44739614291774],
            [80.65410417836517, 30.447350299077073],
            [80.6550858909012, 30.44723525780097],
            [80.65576534309616, 30.446764406052523],
            [80.65712442465252, 30.44576539955358],
            [80.65716682588834, 30.445754321704612],
            [80.65837268994773, 30.445439253219035],
            [80.65848123362207, 30.445410891299673],
            [80.65863174685768, 30.445406016074855],
            [80.65970497800237, 30.445371245586557],
            [80.66008357369805, 30.445358977934518],
            [80.66037460690262, 30.444858443162673],
            [80.66128576042956, 30.44337025942997],
            [80.66160762868918, 30.442844540942872],
            [80.66218719138584, 30.44255958985326],
            [80.66228378576818, 30.442512097555323],
            [80.66238038015047, 30.442464606156705],
            [80.66255707894612, 30.442391322201786],
            [80.66286711572485, 30.44226273713565],
            [80.66308715644715, 30.44217147663238],
            [80.66323867602415, 30.44210863470579],
            [80.66399827220255, 30.441793592300485],
            [80.66450465076906, 30.441583568227145],
            [80.66453261878536, 30.44157196787205],
            [80.6647255746256, 30.441491937203182],
            [80.66484418710962, 30.441421683963597],
            [80.66540976175116, 30.441086697293315],
            [80.66689752999707, 30.44020547590202],
            [80.66732842037038, 30.439791748190657],
            [80.66892080854853, 30.438262742431675],
            [80.66903760620062, 30.437918833587332],
            [80.66948761076469, 30.43659376349143],
            [80.67091131310667, 30.435957615451628],
            [80.67291146109511, 30.434291665428077],
            [80.6748634395961, 30.433838216460572],
            [80.6760694457484, 30.43336647168536],
            [80.67779842385823, 30.432690132044],
            [80.67987683175045, 30.430623231876723],
            [80.67999020298555, 30.42964956647438],
            [80.67950993173804, 30.42840375812699],
            [80.6791362481394, 30.427434408571173],
            [80.67903961238824, 30.427183732443325],
            [80.67950673914476, 30.425857645214194],
            [80.68068961653262, 30.424666985093324],
            [80.68300445259348, 30.424376623682292],
            [80.68506529942408, 30.424257480598328],
            [80.68636692959018, 30.423805694477267],
            [80.68640026745845, 30.423794122900517],
            [80.68741484212131, 30.423441959179115],
            [80.68795303600388, 30.422783954915644],
            [80.68891918397321, 30.421602698106085],
            [80.68897793488384, 30.420099693546092],
            [80.689003869533, 30.419436191726902],
            [80.68954404911864, 30.418059630947255],
            [80.68968142955441, 30.417709531169976],
            [80.69188188803878, 30.417147262436288],
            [80.69265542191027, 30.41694959684645],
            [80.69371715792056, 30.416678275881793],
            [80.69779640088325, 30.41579977044313],
            [80.69869827970189, 30.41579727122712],
            [80.7015534239514, 30.415789314925007],
            [80.70252105130555, 30.41642172178507],
            [80.70283381392943, 30.41719490851824],
            [80.7031762973483, 30.41804155367481],
            [80.7033635290037, 30.41850439696043],
            [80.7034150538617, 30.418631768841124],
            [80.70350975966772, 30.41879354518602],
            [80.70446735508443, 30.420429268995633],
            [80.70568147402088, 30.420639764313705],
            [80.70794014472193, 30.42065438369292],
            [80.70977086493355, 30.42007600900064],
            [80.71109681906302, 30.418789903831055],
            [80.71334875653992, 30.41732440100037],
            [80.71426010072315, 30.41704506527759],
            [80.71588509112411, 30.416546967668467],
            [80.71880970800169, 30.414825022257048],
            [80.71909905857473, 30.413813503490417],
            [80.7196968028652, 30.412831293629324],
            [80.72161671873664, 30.411804162632052],
            [80.72250568508935, 30.411082578098558],
            [80.72311848852746, 30.410585147786378],
            [80.72462000740745, 30.409442501570254],
            [80.72492575262146, 30.409122549765925],
            [80.72726072090558, 30.406678977552815],
            [80.72868459501808, 30.40568889862385],
            [80.73042679136341, 30.404594224943594],
            [80.73086168551845, 30.40432096144326],
            [80.7310020409115, 30.404232768527493],
            [80.7335481628175, 30.40373191809357],
            [80.73558566594579, 30.403917479707957],
            [80.7362823797281, 30.40291629415168],
            [80.73743339143795, 30.40092297291693],
            [80.73919844135503, 30.399981205568224],
            [80.74054589647602, 30.398647095487092],
            [80.74151265418573, 30.397211170459173],
            [80.74200028998143, 30.396486865476504],
            [80.74245906293544, 30.395805421283228],
            [80.74469668140677, 30.393782755170093],
            [80.74645369498211, 30.392194440021456],
            [80.74695343565259, 30.391742668289567],
            [80.75088880957094, 30.39053756595598],
            [80.75164239917746, 30.388724778225992],
            [80.75243145894473, 30.386870312315125],
            [80.75242175705847, 30.386752698078624],
            [80.75229741049714, 30.385245276948012],
            [80.7522711466961, 30.384926879172212],
            [80.75277415460363, 30.383042243704608],
            [80.75452056786997, 30.38245630481464],
            [80.7560114450722, 30.381668487910417],
            [80.76041173791145, 30.37915393670488],
            [80.7615106231168, 30.37794928043502],
            [80.76407878290956, 30.37480291592186],
            [80.76504247303177, 30.37478078000902],
            [80.76583880651577, 30.375665452998305],
            [80.76710191242336, 30.375458750121254],
            [80.7677598906065, 30.373571213440812],
            [80.76776720839001, 30.37355021966698],
            [80.76957926676982, 30.371013506468216],
            [80.77084081954825, 30.370331787082364],
            [80.77118614302611, 30.3701451768585],
            [80.77126507202553, 30.370102523812477],
            [80.77149188464284, 30.369979955210795],
            [80.77220354695618, 30.369595370030368],
            [80.77405706318297, 30.369196437965286],
            [80.77551996967094, 30.36754705525243],
            [80.77742123450241, 30.365759909892006],
            [80.77809581956649, 30.364197782101996],
            [80.77885715503373, 30.36243470170018],
            [80.77900914135887, 30.36211171198596],
            [80.77986566376944, 30.360291471574214],
            [80.7814545643767, 30.358823654787898],
            [80.78295050566135, 30.358517046224733],
            [80.78619045092864, 30.358334958691444],
            [80.78843007219012, 30.357573634915354],
            [80.78796084102004, 30.356875938174085],
            [80.7872863404923, 30.35587300883236],
            [80.78670559698577, 30.353932502982673],
            [80.78615284937456, 30.35052036002662],
            [80.78784016818929, 30.346696502941313],
            [80.78936996445236, 30.3452945668908],
            [80.7896603618363, 30.34502843591366],
            [80.79207601639507, 30.342791494631797],
            [80.79266057392641, 30.342250162115363],
            [80.7949739638774, 30.341566308638278],
            [80.79628441819142, 30.340947972570916],
            [80.79725937412087, 30.340487928874893],
            [80.79892636286144, 30.339377718506967],
            [80.80056904652434, 30.338283650969743],
            [80.801797117543, 30.337394529034327],
            [80.80284160005988, 30.336973675095066],
            [80.80444135278628, 30.336329061736876],
            [80.80437651616336, 30.335717852796733],
            [80.80369728340298, 30.334160751317654],
            [80.80362455792704, 30.333994026003552],
            [80.8034012139949, 30.33348200868778],
            [80.80286668035308, 30.331074473208446],
            [80.80283622481198, 30.330937296019442],
            [80.80282674775628, 30.330894607899893],
            [80.80276804001312, 30.3306301802387],
            [80.80285754414041, 30.330179665759033],
            [80.80307384188433, 30.329090922208366],
            [80.80403997546455, 30.327612332443266],
            [80.80605966841904, 30.32714447453793],
            [80.80971468678342, 30.32690157754581],
            [80.81072455439471, 30.326926370955334],
            [80.81343509935039, 30.326992874921302],
            [80.81500632288356, 30.326847784597646],
            [80.8150780303269, 30.326841162889423],
            [80.81569639607187, 30.32678405593947],
            [80.81669392768265, 30.326691925791806],
            [80.81853470591199, 30.32672571601904],
            [80.82063452756148, 30.32676422858634],
            [80.82201042733942, 30.325878721925505],
            [80.82210024892743, 30.3258209135044],
            [80.82208398648686, 30.324383510890357],
            [80.8227258515169, 30.322474649485514],
            [80.825339265195, 30.319816475649873],
            [80.82773048697243, 30.318253665274483],
            [80.82907609218796, 30.318294344308583],
            [80.83070213839301, 30.31834348146657],
            [80.8319247766072, 30.31810536796843],
            [80.83206727058814, 30.318077615789377],
            [80.83251258968431, 30.31799088517124],
            [80.83332625760079, 30.31783240753964],
            [80.83357583385771, 30.3177837982837],
            [80.83612007797927, 30.316755752675874],
            [80.8371529169736, 30.317025970172438],
            [80.8396406647849, 30.317676787953474],
            [80.84157826582435, 30.318097042944203],
            [80.84335962134952, 30.31848338090333],
            [80.84458113361251, 30.319024748493405],
            [80.84530959436233, 30.31934759161811],
            [80.84618991463293, 30.319737726514916],
            [80.84757230571739, 30.32035034918931],
            [80.84933943396777, 30.32010499345074],
            [80.85036865588881, 30.319962081285098],
            [80.85282990337737, 30.319209917104047],
            [80.85360615050348, 30.318972682243555],
            [80.8577445463738, 30.317989403088518],
            [80.85838283749803, 30.317877678512104],
            [80.86133635678749, 30.31736065916715],
            [80.86430816648874, 30.317339903713616],
            [80.8658647814346, 30.315921143146284],
            [80.86628291042803, 30.31522285015444],
            [80.86685760239646, 30.31426307388182],
            [80.8670495527947, 30.314063313770987],
            [80.86723563061992, 30.31386966455341],
            [80.86738806750537, 30.3137110250438],
            [80.8683654156315, 30.312693888212436],
            [80.86857450440993, 30.31247628285422],
            [80.86890370304354, 30.31213367442956],
            [80.86929610333254, 30.31172528419455],
            [80.87063614893918, 30.31087067194403],
            [80.8716505869051, 30.31022369696609],
            [80.87230772602078, 30.31001183198049],
            [80.87374369331684, 30.309548855595267],
            [80.8744140839429, 30.30837582668312],
            [80.87457562286676, 30.308093166166714],
            [80.8747819255455, 30.307732174700163],
            [80.87522173809282, 30.306962578060563],
            [80.8752284164583, 30.30661943543862],
            [80.87525705087228, 30.305148359509246],
            [80.87588597196009, 30.303859883726716],
            [80.87903336619695, 30.303910574013855],
            [80.88039959286698, 30.30405819772767],
            [80.88081596008857, 30.304103183615098],
            [80.88273773036201, 30.30333067856799],
            [80.8850602736128, 30.303385494944678],
            [80.88536510961217, 30.303392685923768],
            [80.88723703765919, 30.302350427430326],
            [80.88989327165723, 30.302832512610394],
            [80.89207123090262, 30.303770099910253],
            [80.89360037965383, 30.304286981658947],
            [80.89558127315729, 30.304317223161377],
            [80.89919146661293, 30.303823520539083],
            [80.90061001134302, 30.303445373605143],
            [80.90110485700359, 30.303313456550825],
            [80.90254890470823, 30.30292848646053],
            [80.90462930010216, 30.302462133121026],
            [80.90530159189501, 30.30143135986941],
            [80.90667700154245, 30.300857024931815],
            [80.90840615771805, 30.300134945771163],
            [80.909981753755, 30.298025773870393],
            [80.91339621786125, 30.294514150607597],
            [80.91559425806861, 30.29164675399454],
            [80.91764839595675, 30.290255580130975],
            [80.92053195968225, 30.28947914054993],
            [80.92234841844481, 30.288358255229355],
            [80.92401307344466, 30.287845987902074],
            [80.92731502516835, 30.286546848858052],
            [80.92870527902545, 30.285019816211616],
            [80.93015034386332, 30.28371957999468],
            [80.93144440533507, 30.281953747667387],
            [80.93245435298604, 30.281293022954685],
            [80.93250906414204, 30.280960092134592],
            [80.93271951449407, 30.27967942156812],
            [80.93272024384424, 30.27860996397868],
            [80.93193942546418, 30.27811302469638],
            [80.93088388038683, 30.27715759754517],
            [80.9297551295956, 30.27685165358099],
            [80.92851889093413, 30.276516562589336],
            [80.9280792123858, 30.275962494773353],
            [80.92791651063851, 30.275122067529423],
            [80.92785605191534, 30.27480976445912],
            [80.92777231783828, 30.27437723372242],
            [80.92792736185834, 30.27298320181336],
            [80.92844867186875, 30.27157517555395],
            [80.92857773087763, 30.271226587537058],
            [80.92978075218002, 30.270308966985283],
            [80.93154168410149, 30.26927863350221],
            [80.93303874594142, 30.267961678888412],
            [80.933963311058, 30.267255540211067],
            [80.93541619460177, 30.266110413664705],
            [80.93748502561351, 30.265061054124374],
            [80.93788100610362, 30.265137633195252],
            [80.93790279038154, 30.26548139814804],
            [80.9379242167293, 30.266398088800827],
            [80.93871600863696, 30.266837705295927],
            [80.9397712974075, 30.26815592345713],
            [80.94120116909875, 30.268614914047077],
            [80.94190469254755, 30.26955100577436],
            [80.94379679148881, 30.26976190398875],
            [80.94479114759343, 30.270075530861106],
            [80.9462826579184, 30.270545944639707],
            [80.94879031851843, 30.271807385902207],
            [80.94939487517343, 30.27195115242381],
            [80.9513643733672, 30.272419487869115],
            [80.9526307680955, 30.272744332884713],
            [80.95404844048318, 30.27310796655945],
            [80.95640290786798, 30.272860503709353],
            [80.95840536891268, 30.27247920555095],
            [80.9615959279098, 30.27223191267268],
            [80.96513832057707, 30.272557560782843],
            [80.96931914466285, 30.271871096573875],
            [80.97077125658825, 30.272176990176035],
            [80.9750177644583, 30.27255981898054],
            [80.97820819844964, 30.272713167778534],
            [80.97880234365329, 30.27244590095762],
            [80.9792206084444, 30.271471994536967],
            [80.97938161496961, 30.270506664051368],
            [80.97940386419702, 30.270373265813646],
            [80.97948497495167, 30.26988694302804],
            [80.98192746977696, 30.268779645564223],
            [80.98436977754324, 30.26849349927562],
            [80.98541475468721, 30.26831703610236],
            [80.98685609543253, 30.268073625597367],
            [80.98866018581896, 30.2688758910096],
            [80.98971614818174, 30.270346486701044],
            [80.99116829158345, 30.270747640091543],
            [80.99262049883703, 30.27046126807312],
            [80.9950408388637, 30.269716578157613],
            [80.99708712387172, 30.268246130854322],
            [80.99999142146697, 30.267596847316725],
            [81.00142155576026, 30.26734857147943],
            [81.00329171933743, 30.267023880247905],
            [81.00518384795629, 30.266202625650692],
            [81.00705393239315, 30.265247661650335],
            [81.00876992519193, 30.263757950868808],
            [81.01017789839136, 30.262726582265998],
            [81.01077182775771, 30.261828950046038],
            [81.01072772950118, 30.260931369087473],
            [81.01059562268989, 30.259976503113194],
            [81.00971544091493, 30.258295986770406],
            [81.00956136096806, 30.257474804118942],
            [81.00980331187338, 30.25699734685014],
            [81.01017729584561, 30.25688273275199],
            [81.01151923182715, 30.256481573066196],
            [81.01301515692398, 30.256099481705746],
            [81.01446709078368, 30.255851061077635],
            [81.01589700564233, 30.255526236746505],
            [81.01677452902135, 30.25523906702938],
            [81.01741487869373, 30.255029507905533],
            [81.01902072612569, 30.254494551582354],
            [81.019774753702, 30.25432659419704],
            [81.02073658132821, 30.254112340612096],
            [81.02136482342792, 30.254040206890068],
            [81.02190250559619, 30.253978467532306],
            [81.0221224186148, 30.25363467470055],
            [81.022320314431, 30.25321449615211],
            [81.0226940951564, 30.25233594304939],
            [81.02338502090203, 30.25146498202338],
            [81.02444977773513, 30.251000376965862],
            [81.02621345798474, 30.250711993164032],
            [81.02737937955476, 30.25074994905094],
            [81.02907315909908, 30.250424925969583],
            [81.031074942055, 30.2502334899836],
            [81.03325258204109, 30.249679126290687],
            [81.03371445046156, 30.24941164183383],
            [81.03365799192284, 30.24910588043207],
            [81.03384611840369, 30.24847582709782],
            [81.03378734590933, 30.24791706572006],
            [81.03367707463735, 30.247089532955613],
            [81.03366329072833, 30.246986091135227],
            [81.0333744194939, 30.246532598101],
            [81.03250804770829, 30.245852464219922],
            [81.03210128884348, 30.245379786847764],
            [81.03173785571755, 30.244957452624362],
            [81.03129770952171, 30.244313015533294],
            [81.03080891899646, 30.24311702423131],
            [81.03071963790103, 30.24221241686962],
            [81.03076410218165, 30.241771929830747],
            [81.03082928054693, 30.241126215702366],
            [81.03110396317743, 30.240266758803955],
            [81.03151447041654, 30.239828629787496],
            [81.03158496197648, 30.2397533961024],
            [81.03192848591095, 30.239251999780038],
            [81.03230299598653, 30.238469077885668],
            [81.03219336683048, 30.237937754823577],
            [81.03192862530585, 30.23709414218098],
            [81.0313220298878, 30.236127517570935],
            [81.03111419746227, 30.23518457660697],
            [81.0308474693353, 30.234387244875506],
            [81.03060970207616, 30.23297046011868],
            [81.03051163820157, 30.2328870219186],
            [81.03042263859396, 30.232801334514022],
            [81.0304218786668, 30.23279608786919],
            [81.03041382613725, 30.232740460304058],
            [81.03040507663303, 30.232670521827174],
            [81.03038713875549, 30.232616397029176],
            [81.03033357603374, 30.232553271816073],
            [81.03030695070527, 30.23247658392728],
            [81.03007563788043, 30.232205959037856],
            [81.02988851414369, 30.232005208773444],
            [81.02975501338324, 30.231851896847616],
            [81.02969295116884, 30.231734583883963],
            [81.02947920120425, 30.23133314720701],
            [81.02939020159658, 30.231195585108196],
            [81.02923479335107, 30.230967811115647],
            [81.02922095098614, 30.230947521510984],
            [81.02911863871424, 30.230807647255176],
            [81.02904745107907, 30.230708460127403],
            [81.02891395211725, 30.23052352174318],
            [81.02878045045748, 30.230345333670243],
            [81.0286826383931, 30.230182959276192],
            [81.02854913853196, 30.23002058488214],
            [81.02845107555675, 30.229873960315047],
            [81.02830876413822, 30.229641708598024],
            [81.02822851403477, 30.229510959763047],
            [81.0281661388563, 30.22940939662641],
            [81.02813076402362, 30.229325958426273],
            [81.02808626377009, 30.229179334758555],
            [81.02808163855684, 30.22907783367515],
            [81.02805495117514, 30.22887033489809],
            [81.02802826379337, 30.228676334045645],
            [81.02801038796906, 30.22852970857923],
            [81.02799245189021, 30.228360646826445],
            [81.02793913738134, 30.228173334232054],
            [81.02788551260636, 30.2279883337946],
            [81.02781438882306, 30.22777189665578],
            [81.02775195069205, 30.227564396979403],
            [81.02767201355266, 30.227338835319074],
            [81.02758301394499, 30.227185459541374],
            [81.02752938917001, 30.227061397165812],
            [81.02753395053145, 30.226984710176282],
            [81.02755176340321, 30.226892270661835],
            [81.02758713733658, 30.226815521619073],
            [81.02764945046181, 30.22678395991187],
            [81.0277205760438, 30.22674558404151],
            [81.02788095034566, 30.226621520766628],
            [81.02802313855705, 30.22652902189691],
            [81.02833445147382, 30.226411646880024],
            [81.02885038803504, 30.22628070918745],
            [81.0288695175143, 30.226275308758545],
            [81.02901045117142, 30.22623552185189],
            [81.02925976392703, 30.22618814646586],
            [81.02962438865467, 30.226133896662077],
            [81.029882513867, 30.22611808478183],
            [81.03006045102904, 30.22611802182928],
            [81.03028288844462, 30.22615633384777],
            [81.03058526389884, 30.22618783350174],
            [81.03079007640099, 30.226156208842042],
            [81.03087901395537, 30.22612683428605],
            [81.03098551347074, 30.22598695913092],
            [81.03107445102518, 30.225885458946834],
            [81.03114576366613, 30.22574789684802],
            [81.03124345162405, 30.225454583862017],
            [81.03131032701003, 30.225292146515415],
            [81.0314257639879, 30.224989896966235],
            [81.03148807531448, 30.22471921002358],
            [81.03153245146154, 30.2245658351452],
            [81.03160351409093, 30.224387585019088],
            [81.03162132606343, 30.22428608393568],
            [81.03163888892362, 30.224234209241388],
            [81.03163882597107, 30.2241552091956],
            [81.0316387639179, 30.223938709104175],
            [81.03164782638612, 30.2238395219764],
            [81.03167438786278, 30.223692834456813],
            [81.03166557630539, 30.223460521585878],
            [81.031643137321, 30.223390584907634],
            [81.03149182548742, 30.22324402149445],
            [81.0314385136765, 30.223151583778645],
            [81.03132288874036, 30.222973396605028],
            [81.03123388913275, 30.222835833606894],
            [81.03110913787646, 30.222619333515468],
            [81.03108245139407, 30.22250202145119],
            [81.03104670064477, 30.22239377185514],
            [81.03093101365539, 30.22213889679273],
            [81.03088195114117, 30.222001333794594],
            [81.03086112014466, 30.221935329851647],
            [81.030810763506, 30.221775772134208],
            [81.03078413817747, 30.221653959862408],
            [81.03072170094578, 30.22139908390068],
            [81.03065926371409, 30.221119396542974],
            [81.03063251337983, 30.220841960188352],
            [81.03061463845484, 30.220648022288458],
            [81.03061457640166, 30.22031645843788],
            [81.03061451344911, 30.22021489709988],
            [81.03065888869679, 30.22009989719254],
            [81.03071670161455, 30.219975834816978],
            [81.03077026343698, 30.2198765217841],
            [81.03089457582405, 30.219736646628974],
            [81.03105495102523, 30.219596833527078],
            [81.0312328261341, 30.219535772258155],
            [81.03128607679105, 30.219513272119855],
            [81.03158863840497, 30.219366521647714],
            [81.03187332599327, 30.219265021463627],
            [81.03204213863387, 30.21920408520043],
            [81.03240701337302, 30.219095708799955],
            [81.03264695069629, 30.21904827226001],
            [81.03302970036043, 30.218978271729895],
            [81.03321638882534, 30.21893983380636],
            [81.03334526347317, 30.21891052220286],
            [81.03367445131494, 30.218840583726035],
            [81.03369520496977, 30.21883762315781],
            [81.033896951683, 30.218808834060496],
            [81.03446632685956, 30.218801958743427],
            [81.03484876355964, 30.218770272030497],
            [81.03516013763033, 30.21873858531751],
            [81.0354448261179, 30.218668583888075],
            [81.03573832616286, 30.218569271754518],
            [81.03594282570089, 30.218454209794004],
            [81.0359837007872, 30.218404522250978],
            [81.03612976417736, 30.218228584281803],
            [81.03623651370418, 30.2180661469352],
            [81.03627188853687, 30.217982646681833],
            [81.0364497006932, 30.21782020843591],
            [81.03671651335645, 30.217581084099777],
            [81.0368457009684, 30.217479521862515],
            [81.03700570205154, 30.217339584654155],
            [81.03718357716042, 30.21719296008706],
            [81.03729032578798, 30.217084646639194],
            [81.03731915265683, 30.21705516326523],
            [81.03758370082721, 30.216784585140545],
            [81.03769926371012, 30.216683022003906],
            [81.03783263856548, 30.216545459005772],
            [81.03804620057178, 30.216335584219905],
            [81.0382415755883, 30.216182147288293],
            [81.03849088834386, 30.21601964608982],
            [81.03862426409859, 30.21595645882354],
            [81.03914888901005, 30.21580964719749],
            [81.03975395108404, 30.215678646552362],
            [81.03990076360941, 30.21565608346151],
            [81.0402653253845, 30.21560183455705],
            [81.04027354608735, 30.215602027911302],
            [81.0405500759253, 30.215608521016463],
            [81.04087013834629, 30.215585833819205],
            [81.04124382644147, 30.21554739679499],
            [81.04134188851742, 30.21553833522603],
            [81.04161751363739, 30.215531459909016],
            [81.04176876251842, 30.215484084522927],
            [81.04189307580486, 30.215400584269617],
            [81.04207095181306, 30.21525389674997],
            [81.04213326403897, 30.215213271675225],
            [81.04220432576909, 30.21512077190613],
            [81.04226657594177, 30.21495158424824],
            [81.04233757561866, 30.214744021619367],
            [81.04239988874394, 30.21460414646424],
            [81.04244043287974, 30.214536231461977],
            [81.04253320064674, 30.214380834008352],
            [81.04272007617061, 30.21411683352511],
            [81.04288007545517, 30.213931833986976],
            [81.04298682588137, 30.21372202215366],
            [81.04306238871834, 30.21365433378054],
            [81.04322238890217, 30.213453521462895],
            [81.04333788883258, 30.213313647207087],
            [81.04343557589118, 30.213173772052016],
            [81.04354232541806, 30.213042897311936],
            [81.04357807706668, 30.213004521441576],
            [81.04368476364101, 30.212842084994293],
            [81.04376463872717, 30.21269545952788],
            [81.04383588841557, 30.212517208502447],
            [81.04387126414758, 30.212456335191746],
            [81.04400457605038, 30.212239709195273],
            [81.04410257697242, 30.21208408421319],
            [81.04417782594595, 30.21198477207963],
            [81.04431120080136, 30.211860709704013],
            [81.04436476262373, 30.21181327226475],
            [81.0444890768095, 30.211752335102233],
            [81.04488057597786, 30.211682271619566],
            [81.04517401307032, 30.211612271089507],
            [81.04547657558356, 30.211535459993513],
            [81.04571211432267, 30.21151079158983],
            [81.0457791380968, 30.211503771481944],
            [81.04606345066776, 30.211420210074664],
            [81.0463037009551, 30.211264522140027],
            [81.04643701375721, 30.21116520910715],
            [81.04657032566007, 30.21094864696255],
            [81.0466326387853, 30.210802020596816],
            [81.04671263887724, 30.210508710308773],
            [81.04674382556715, 30.210445585095727],
            [81.04678820171421, 30.21030795914504],
            [81.04685920049178, 30.21009139700044],
            [81.04687701336354, 30.209951521845312],
            [81.04689438916478, 30.209626710104658],
            [81.04686770088375, 30.209556770728454],
            [81.04683226309851, 30.20941920862964],
            [81.04677857537104, 30.20920952180205],
            [81.04673879745758, 30.209063596008207],
            [81.04669863913097, 30.2089162717686],
            [81.04669851412518, 30.208792208493662],
            [81.04673388895787, 30.208661334652902],
            [81.04683182602798, 30.208429021781967],
            [81.04689832639673, 30.20836808372013],
            [81.04709043957217, 30.208225424263958],
            [81.04713845077896, 30.208189771540788],
            [81.04721820175865, 30.208081521944735],
            [81.04733407580699, 30.20788070872777],
            [81.04739632597972, 30.207711584921753],
            [81.04740501253139, 30.207594209904926],
            [81.04737826399577, 30.20733258453123],
            [81.04729801299294, 30.20712283385177],
            [81.04718232690294, 30.20688377156887],
            [81.04707545147096, 30.206784583541776],
            [81.0468617635596, 30.206613209631996],
            [81.04671957534816, 30.20652977143186],
            [81.04666632559054, 30.20649827177789],
            [81.04663932614403, 30.206459896806848],
            [81.046630512788, 30.20635839662276],
            [81.04656813850886, 30.20628170873391],
            [81.04644382612173, 30.20615771021022],
            [81.04625245039034, 30.205995396970025],
            [81.04604751378179, 30.20578795844756],
            [81.0458250754669, 30.205555709428495],
            [81.04574513742818, 30.205447459832442],
            [81.04565613871989, 30.20532339655756],
            [81.045558076644, 30.205239959256744],
            [81.04539795145433, 30.205068646500877],
            [81.04534463874415, 30.20496939731987],
            [81.0452378253654, 30.204852083456956],
            [81.0451130759078, 30.204743896813454],
            [81.0449351387457, 30.20465145909759],
            [81.04471276338336, 30.20453652214286],
            [81.04457051401806, 30.20445077178573],
            [81.04419682592282, 30.204288584450637],
            [81.0438766384961, 30.20415783381702],
            [81.04372538781644, 30.204103771971575],
            [81.04354738860116, 30.203988772064235],
            [81.04336495033226, 30.203880583622094],
            [81.04324932539612, 30.20377914728988],
            [81.04310676396602, 30.203695709089743],
            [81.04296457665396, 30.203641645445657],
            [81.04274213833907, 30.2035559589404],
            [81.0422973885137, 30.203470396541604],
            [81.04211038888337, 30.203441146991395],
            [81.04190582549353, 30.203371271467063],
            [81.04175457571313, 30.203333021501805],
            [81.04162113880454, 30.20326308392424],
            [81.04140776385725, 30.203139146554406],
            [81.04129220097434, 30.203078271445122],
            [81.04113176371999, 30.20299933345251],
            [81.04090070090666, 30.202906959588574],
            [81.04075845064204, 30.2028618972588],
            [81.04058051348, 30.202776209854278],
            [81.04032238916699, 30.20269958401866],
            [81.04020226402332, 30.202690645656844],
            [81.039944138811, 30.202668147317183],
            [81.03974876379448, 30.202677208886143],
            [81.03943209271694, 30.20269839061831],
            [81.03941051258516, 30.20269983403017],
            [81.03905476416611, 30.202729333591947],
            [81.03875257577079, 30.202754208839735],
            [81.03847657563352, 30.202700146994232],
            [81.03819188804528, 30.202576208725077],
            [81.03783601372112, 30.202375522312536],
            [81.03763145033122, 30.202289896061927],
            [81.03734676364229, 30.202190709833417],
            [81.03713345074823, 30.20215245986816],
            [81.03688445185605, 30.202089333755794],
            [81.03658182549088, 30.201990208681252],
            [81.03650620150006, 30.201927022314294],
            [81.036275013681, 30.201780520954344],
            [81.0361503271759, 30.20167227045897],
            [81.0359811386187, 30.20152570974375],
            [81.03583013884986, 30.20135658413909],
            [81.03576776277208, 30.201255083955004],
            [81.03572326341794, 30.2010926466084],
            [81.03569657693555, 30.201016021672103],
            [81.03571407594393, 30.20086258384123],
            [81.0357763261166, 30.200668584787422],
            [81.03586520071849, 30.20046783362369],
            [81.03598976401645, 30.200305397176407],
            [81.03622076387722, 30.199964709703636],
            [81.03631420073981, 30.199811333925993],
            [81.0363586389401, 30.199718835056217],
            [81.03646538756766, 30.199531584515057],
            [81.03659870036978, 30.199292460178924],
            [81.03671420119946, 30.199145771760016],
            [81.03675863850043, 30.19906233355988],
            [81.03686538802731, 30.19891339683585],
            [81.03694520106023, 30.198760021957526],
            [81.03698088885699, 30.198635958682587],
            [81.03698957540865, 30.198444208833223],
            [81.03696282597372, 30.19837427215498],
            [81.03685601349429, 30.19812620945703],
            [81.03675370032306, 30.197979646943224],
            [81.03662932678208, 30.197855583668286],
            [81.03653126380686, 30.197778958731988],
            [81.03637995107397, 30.19763233416495],
            [81.03616663907917, 30.197501583531334],
            [81.03592638879189, 30.197400147199062],
            [81.03559720095012, 30.197276208929964],
            [81.03537488854033, 30.19715445871134],
            [81.03530370180448, 30.19705295852725],
            [81.03532151377692, 30.19697627153778],
            [81.03540138886308, 30.196937896566737],
            [81.03584601278334, 30.196845271791915],
            [81.036015138388, 30.196813709185392],
            [81.03645101370336, 30.19672108351125],
            [81.0366733890657, 30.19666689666002],
            [81.03701126345908, 30.19656527147015],
            [81.0371267633895, 30.196520147087142],
            [81.03731338890185, 30.196450146557083],
            [81.0375269509081, 30.196348585219084],
            [81.03772263888874, 30.196265084066454],
            [81.03784726334061, 30.196179396661876],
            [81.0379760759352, 30.19610933407853],
            [81.0383051387712, 30.195931083952416],
            [81.03845607558748, 30.19583177181886],
            [81.0387673255517, 30.19566252120842],
            [81.03909638748837, 30.195468397148886],
            [81.03928326391156, 30.195344334773267],
            [81.03952345034702, 30.1952292098602],
            [81.04005695156712, 30.194935833921647],
            [81.04020782543085, 30.19483433463688],
            [81.04049245096587, 30.19463339731351],
            [81.04069688845073, 30.19450927198534],
            [81.04088382602782, 30.194385146657226],
            [81.04108826351262, 30.19428583362435],
            [81.04135501412264, 30.194184272286407],
            [81.0415150134072, 30.19412333422457],
            [81.04181751386722, 30.194053333694455],
            [81.04196876364756, 30.194021584028917],
            [81.04197199311307, 30.19402135020522],
            [81.04215538915992, 30.19400808430572],
            [81.04238682609122, 30.19400802225249],
            [81.04266245031187, 30.194046272217747],
            [81.04283151386335, 30.19405295957648],
            [81.04318726408104, 30.194046084259412],
            [81.0433115755688, 30.194021209011623],
            [81.04354301339941, 30.193982834040582],
            [81.04370301358324, 30.193944397016367],
            [81.04384513884213, 30.193867709127517],
            [81.0440499504449, 30.193759334525737],
            [81.04416545037532, 30.19366683385732],
            [81.04421863897909, 30.19362852183889],
            [81.04426307717932, 30.19352695870225],
            [81.04426301242813, 30.19340289722595],
            [81.04419188864478, 30.193310396557592],
            [81.04412076396216, 30.19321120942982],
            [81.04410288903716, 30.193093896466223],
            [81.04411138852981, 30.192622459259155],
            [81.0441025760731, 30.19248489626102],
            [81.04410238901409, 30.192083396631517],
            [81.04411107556575, 30.19185783497113],
            [81.04411107376711, 30.191853812303634],
            [81.04411101351252, 30.19169539672521],
            [81.04413782590001, 30.1914180215245],
            [81.04415520080192, 30.191147271629347],
            [81.04418207614196, 30.190908147293214],
            [81.04422645049033, 30.19073002217283],
            [81.04426176416916, 30.190529208955866],
            [81.04431513803326, 30.19029908413546],
            [81.04437713909374, 30.19015920898039],
            [81.04449707717845, 30.189872647204936],
            [81.04456813890852, 30.18974189657132],
            [81.04471920073058, 30.1894237721894],
            [81.04484376402854, 30.18921620956047],
            [81.04498576338233, 30.18890708379496],
            [81.04502003474687, 30.188821407182274],
            [81.04504801355506, 30.188751459712137],
            [81.04510151332425, 30.188521396944964],
            [81.04511895207804, 30.18845145846808],
            [81.04514582561939, 30.188304834800363],
            [81.04517232594213, 30.187980022160332],
            [81.0452121380298, 30.18781758481373],
            [81.04524776377332, 30.18764839715584],
            [81.04528307655278, 30.187422896649423],
            [81.04531870049766, 30.187292021909343],
            [81.04538970107393, 30.18712958366342],
            [81.04540751304637, 30.18699870982266],
            [81.04543407542229, 30.186867897135812],
            [81.04541613754475, 30.18669645847484],
            [81.04534495170822, 30.18653408408079],
            [81.04523813832947, 30.186441647264246],
            [81.04521992615872, 30.18642022001717],
            [81.045140387419, 30.186326646457644],
            [81.04507801403918, 30.186186835154388],
            [81.04511363798406, 30.185979271626138],
            [81.0451903141817, 30.185831439269577],
            [81.04519788917133, 30.185816835178855],
            [81.04532251362315, 30.185654396932932],
            [81.04539351419936, 30.18549195958633],
            [81.04544670190381, 30.185392709506004],
            [81.04547320042792, 30.185205458964788],
            [81.04545557551444, 30.184921272298936],
            [81.04541982566445, 30.184774708885755],
            [81.0453486389286, 30.184603272023423],
            [81.04514407643802, 30.18437102210504],
            [81.04503918491054, 30.184263536932747],
            [81.04495701385514, 30.184179334308908],
            [81.04483232555145, 30.184039522106332],
            [81.04478363895385, 30.183962897170034],
            [81.0447033888504, 30.183832084483186],
            [81.04463226416772, 30.18369227228061],
            [81.0445698260367, 30.18344414663011],
            [81.04456976398347, 30.18327495987154],
            [81.04461407537934, 30.182997521718278],
            [81.04467632645134, 30.182812460126968],
            [81.04476513900005, 30.182557585064558],
            [81.04479170047665, 30.182440272100905],
            [81.04486270105286, 30.182239521836493],
            [81.04498713844572, 30.181876335124798],
            [81.0451693267031, 30.18138908513822],
            [81.04528476278165, 30.181188271921258],
            [81.04538238868633, 30.181057397181178],
            [81.04549813862826, 30.180840834137257],
            [81.0456223260096, 30.180617521681313],
            [81.04570245110727, 30.18049339725252],
            [81.04589807613536, 30.18025420996389],
            [81.0460578883609, 30.180076021890955],
            [81.04635126340014, 30.179890896447773],
            [81.04681363903825, 30.179696771488864],
            [81.04696032565857, 30.179651584153362],
            [81.04733376284292, 30.17955895847922],
            [81.04751170090435, 30.179565646737274],
            [81.04770738888493, 30.179597208444477],
            [81.04787626357876, 30.17964220962034],
            [81.04812426872007, 30.17967898649607],
            [81.04813420173207, 30.179680459585597],
            [81.04835651414186, 30.179689397048094],
            [81.04863207541001, 30.179673522215353],
            [81.04883682585887, 30.17963508429176],
            [81.04901463891451, 30.179580959493762],
            [81.04922788885602, 30.179542458617618],
            [81.04933290179201, 30.17951979480273],
            [81.04940570191167, 30.179504083646577],
            [81.04965463875061, 30.17946564662236],
            [81.04980582557846, 30.179449771789564],
            [81.04995707715744, 30.179424896541775],
            [81.05024163884059, 30.179370646737993],
            [81.05039288862099, 30.179332271766953],
            [81.05048182617537, 30.179325458503172],
            [81.05051713805551, 30.179325458503172],
            [81.05062851369502, 30.179347896588183],
            [81.05085285677399, 30.179406857940194],
            [81.05086870192912, 30.179411021801286],
            [81.05107326352038, 30.17947183485734],
            [81.05128645050934, 30.17957102198511],
            [81.05165138820104, 30.179758083668673],
            [81.05180263798144, 30.179812147312816],
            [81.05193601373611, 30.17983464655174],
            [81.05213138875263, 30.179841333910474],
            [81.05229170100125, 30.179818771719],
            [81.05241601248906, 30.17977358528276],
            [81.05266520113815, 30.179649397002095],
            [81.05286957656978, 30.179541083554227],
            [81.05309176397378, 30.179331209667623],
            [81.05344726417997, 30.178976896566553],
            [81.05359845100776, 30.178791959081707],
            [81.05370476346411, 30.178620397213592],
            [81.05383801241442, 30.178381271978196],
            [81.05394470078738, 30.17819627154074],
            [81.05400842854618, 30.17806923420784],
            [81.05406920203211, 30.177948083837066],
            [81.05412226383146, 30.177686397309458],
            [81.05415788777634, 30.177514959547807],
            [81.05418895035984, 30.177393208429862],
            [81.05424213896362, 30.177237583447777],
            [81.05432220110873, 30.17703670997622],
            [81.05436657545715, 30.176896834821093],
            [81.05441963905514, 30.176682521880934],
            [81.05447307587178, 30.176418583450925],
            [81.05452620062374, 30.176303521490354],
            [81.05464163850087, 30.17614108414375],
            [81.05471263907708, 30.175994459576714],
            [81.05483713762385, 30.175807146982322],
            [81.05502370108297, 30.1756153971329],
            [81.05525495185458, 30.175414521862763],
            [81.05548595081603, 30.17527452170185],
            [81.05562382587891, 30.175204584124288],
            [81.05585482573969, 30.175096146569956],
            [81.05613063791867, 30.17499683353708],
            [81.05625488915189, 30.17495845856604],
            [81.0564862640299, 30.17484108354921],
            [81.05660170190708, 30.174764334506506],
            [81.05683263881531, 30.174531896629787],
            [81.05691007134277, 30.174460331279306],
            [81.05695720031463, 30.174416771716665],
            [81.05717938861795, 30.174200145720192],
            [81.05727732568806, 30.17411439716176],
            [81.0574372629194, 30.173967771695345],
            [81.05757926407182, 30.173798396978498],
            [81.05770388852369, 30.173642771996356],
            [81.05777945136066, 30.17354352191603],
            [81.05788576381701, 30.173435208468163],
            [81.05796582596213, 30.173288521847837],
            [81.05806326390854, 30.17304033504348],
            [81.05817901385046, 30.17274708501003],
            [81.05824982556908, 30.172399647225973],
            [81.05826763844084, 30.17225308381279],
            [81.05832070113951, 30.171912459292628],
            [81.05834720056293, 30.171704896663698],
            [81.05834317339878, 30.1716448165551],
            [81.0583295126969, 30.17144102208556],
            [81.05836026411498, 30.17134852231652],
            [81.05836926273133, 30.171224459940902],
            [81.05842238838255, 30.17100789689698],
            [81.05843138789828, 30.17084770875482],
            [81.05841338886688, 30.170646896437177],
            [81.05842207631781, 30.170475521628077],
            [81.05842201336526, 30.17036052172074],
            [81.05844857574124, 30.170290584143174],
            [81.05848420058544, 30.170243208757142],
            [81.05857301403341, 30.170067209634055],
            [81.05869757553273, 30.169920522114467],
            [81.05885745071083, 30.169758083868544],
            [81.05910620049082, 30.16958652200043],
            [81.05944382577201, 30.169324708668398],
            [81.05962163882765, 30.169193834827638],
            [81.05984845054559, 30.16906964654703],
            [81.06008851377396, 30.168952271530145],
            [81.0602571375569, 30.168821333837514],
            [81.06039045215772, 30.1686430837114],
            [81.0605149516038, 30.16856633466864],
            [81.06082613771616, 30.16846695868327],
            [81.06111945160148, 30.168365271440166],
            [81.06127963884433, 30.168256958891618],
            [81.06142176320384, 30.168164397069347],
            [81.0615638264095, 30.168103459007455],
            [81.06185780759182, 30.16803067957227],
            [81.06191070211725, 30.168017584543918],
            [81.0621417010787, 30.16797908456715],
            [81.06233732610679, 30.167931647127887],
            [81.06252426278456, 30.16788645889301],
            [81.0630132637512, 30.16786139658626],
            [81.06309763904488, 30.167854584221743],
            [81.06328420070537, 30.167831959077716],
            [81.06331988850212, 30.167822896609437],
            [81.06333770047456, 30.167784521638396],
            [81.06336420169663, 30.167646958640262],
            [81.06343513842097, 30.1674213349267],
            [81.06351507645968, 30.167082959610923],
            [81.06359482564073, 30.16692052226432],
            [81.0636660753292, 30.166758084018397],
            [81.06376370033456, 30.166633958690284],
            [81.06383463795822, 30.166487334123246],
            [81.06399451313632, 30.16630227163256],
            [81.0641634507827, 30.16607670907291],
            [81.06421238919046, 30.16596833447113],
            [81.06428326386157, 30.165706647044203],
            [81.06434532607602, 30.165366021624664],
            [81.06438963837115, 30.165095334682007],
            [81.06442482614489, 30.164849397082037],
            [81.06447801384934, 30.164301271986176],
            [81.06450451237339, 30.164084708942198],
            [81.0645043891663, 30.16393808527448],
            [81.0645353887972, 30.163705771504226],
            [81.0645709506889, 30.16348920846025],
            [81.0645884505966, 30.163403459002495],
            [81.06457150377196, 30.163204644978407],
            [81.06457076362989, 30.163195958426797],
            [81.06455276369917, 30.162970396766468],
            [81.06455270164594, 30.162832834667654],
            [81.06456126319182, 30.162577896652635],
            [81.06459682688211, 30.16240645889104],
            [81.06463182579819, 30.161820021776634],
            [81.06467201290315, 30.16169589644852],
            [81.06472507650113, 30.161488396772143],
            [81.06486701380169, 30.161201834996746],
            [81.0650624508715, 30.160885959819268],
            [81.06510623256662, 30.16081364803148],
            [81.06515126342015, 30.160739272299622],
            [81.06525795179311, 30.16056777158542],
            [81.06536457541489, 30.16043014653411],
            [81.06556888879322, 30.160227022059473],
            [81.06571120111113, 30.160066833917313],
            [81.06573320032697, 30.15997208404451],
            [81.06576870106477, 30.159787084506377],
            [81.06578613891924, 30.159554770736122],
            [81.06578416310867, 30.159447025659745],
            [81.06577707645096, 30.1590607722369],
            [81.0657948254709, 30.158884834267724],
            [81.0658392016179, 30.158805835121257],
            [81.06594588819223, 30.15874489705942],
            [81.06614113820302, 30.15857564554966],
            [81.06631007674872, 30.158426646772398],
            [81.06669220048474, 30.158212209725775],
            [81.06697663806148, 30.158024834178832],
            [81.06723863755315, 30.157839708735594],
            [81.06759413865865, 30.1576072088057],
            [81.06788763780429, 30.157453708921594],
            [81.06818095079029, 30.157367833558737],
            [81.06871426405212, 30.157243460017696],
            [81.06887420038407, 30.15722083397435],
            [81.06913984932464, 30.157201028204952],
            [81.0693008891248, 30.157189022255636],
            [81.0694255126773, 30.15721377159832],
            [81.0698255760895, 30.15726770843804],
            [81.07008357539672, 30.157321710028953],
            [81.07030588870583, 30.1573350838471],
            [81.07039476330772, 30.157289958564775],
            [81.07047451338815, 30.157188397226776],
            [81.07050138872819, 30.157150020457152],
            [81.07060357599431, 30.15712064680048],
            [81.07067470067693, 30.157158959718288],
            [81.07085251283326, 30.157213021563734],
            [81.07098588858793, 30.15721970892247],
            [81.07117238909456, 30.157165397065455],
            [81.07149263857445, 30.1570885850702],
            [81.07174145040767, 30.15702533395205],
            [81.07183907631236, 30.156973459257813],
            [81.07211470053306, 30.15674089727463],
            [81.07245226376102, 30.156485834253886],
            [81.07256727805748, 30.156400237680884],
            [81.072701013541, 30.156300708810704],
            [81.07278113863867, 30.15626908505027],
            [81.07300332694206, 30.15616070864985],
            [81.07312751342408, 30.15610652179862],
            [81.07329657697551, 30.156059022306067],
            [81.07365213743623, 30.155991146873987],
            [81.07383901385947, 30.155991083921435],
            [81.07417676234775, 30.155950271787674],
            [81.07436776396122, 30.15590495854707],
            [81.07472338737449, 30.155857459054516],
            [81.07487457690024, 30.155841584221776],
            [81.07553226370305, 30.155773583783912],
            [81.07578138850033, 30.155732833703382],
            [81.07602113786527, 30.15568758431459],
            [81.07639445094321, 30.15560164689856],
            [81.07662440848969, 30.155568941253705],
            [81.07677676353688, 30.155547272088995],
            [81.07723907532312, 30.1554770836006],
            [81.07739926256596, 30.155447646991377],
            [81.07779932597816, 30.155377521455478],
            [81.07802576267886, 30.1553232086992],
            [81.07831026410747, 30.155253147015173],
            [81.07857688791313, 30.155167271652317],
            [81.07879032671224, 30.155074646877495],
            [81.0791191386374, 30.154912021572613],
            [81.0792255140463, 30.15480370902401],
            [81.07937663882086, 30.154697583626614],
            [81.07950120211882, 30.154634397259656],
            [81.07958095040061, 30.154564396729597],
            [81.07964313852011, 30.1544718969605],
            [81.07975851344469, 30.154280084158586],
            [81.07991857658112, 30.154009271310883],
            [81.07994507600449, 30.153907772026116],
            [81.07996235917557, 30.15365440152948],
            [81.07997138836896, 30.153522022223626],
            [81.07997988876093, 30.153197209583595],
            [81.08002870036427, 30.152980646539675],
            [81.08015307660327, 30.152718959112747],
            [81.08025057660291, 30.152518146795103],
            [81.08033057579547, 30.15234664698022],
            [81.08040151341919, 30.15219995856131],
            [81.0804815755643, 30.152021708435143],
            [81.08065007614016, 30.15182089701682],
            [81.08076551401734, 30.151728335194548],
            [81.08084551410923, 30.151613271435394],
            [81.08104101413153, 30.151380771505444],
            [81.0812274516856, 30.151195709014814],
            [81.08148520098132, 30.150972209499912],
            [81.08160513906597, 30.15088646004216],
            [81.08184507548992, 30.15070139665221],
            [81.08199582614651, 30.15054558461111],
            [81.08218263781856, 30.150430458798667],
            [81.08246701334207, 30.15026108408182],
            [81.08279557615498, 30.150121021867733],
            [81.08314226390439, 30.14995164625151],
            [81.08322019465629, 30.149904452528517],
            [81.08337345082413, 30.149811646989974],
            [81.083524263534, 30.149687584614412],
            [81.08373757552874, 30.14949339670295],
            [81.08397745079878, 30.14923164722279],
            [81.08406626334744, 30.149116460256494],
            [81.08411063859518, 30.14906239661235],
            [81.08420813859476, 30.148976521249494],
            [81.0844215764946, 30.148813958897165],
            [81.08462126376037, 30.148635646717764],
            [81.08472788918078, 30.1485205218047],
            [81.0848078883734, 30.148396458529817],
            [81.08488757550128, 30.148218209302968],
            [81.08488757550128, 30.14816408360565],
            [81.08488751344805, 30.148096397031168],
            [81.08483420073782, 30.147988146535795],
            [81.08462951234219, 30.14765446028514],
            [81.08443370115447, 30.147431271935716],
            [81.08423788906737, 30.147221584208808],
            [81.08412905401269, 30.14708385303743],
            [81.0840686375576, 30.147007397173695],
            [81.08393513859585, 30.1467909591355],
            [81.08384182583967, 30.146565458629084],
            [81.08379720147974, 30.146319646934217],
            [81.08375257532117, 30.14614145886128],
            [81.08373451333716, 30.145870833971856],
            [81.08371632544811, 30.14546933524167],
            [81.0837337642019, 30.145013646962298],
            [81.08379145031523, 30.144905333514373],
            [81.08384679369465, 30.14480894327818],
            [81.08391582655423, 30.14468871021586],
            [81.08403113852626, 30.144472085118707],
            [81.08411988902174, 30.144280333470647],
            [81.0842617013165, 30.143971209503775],
            [81.08429726410753, 30.143838084659933],
            [81.08430595065914, 30.143738834579608],
            [81.08430582565342, 30.14356064650667],
            [81.08429695114341, 30.143405021524586],
            [81.08424326251662, 30.143258459010724],
            [81.08415426290895, 30.143143459103385],
            [81.08401201264434, 30.142965334882376],
            [81.08386070081076, 30.142818833522426],
            [81.08381201331383, 30.142733147017168],
            [81.0837762005113, 30.14264070930136],
            [81.08374938902313, 30.142401584065908],
            [81.0837047017107, 30.142115147296238],
            [81.08364220062714, 30.141846771611256],
            [81.08360645167647, 30.141738522015203],
            [81.08360497498967, 30.14173167367784],
            [81.08357970134222, 30.141614521692873],
            [81.08353507608297, 30.14133708353961],
            [81.08354401354546, 30.14120395869577],
            [81.083534701965, 30.140926521441884],
            [81.0835390753681, 30.14070320898594],
            [81.08355663732902, 30.140423520728973],
            [81.0835738260713, 30.140006210118543],
            [81.08358270148057, 30.13977621030392],
            [81.08359126302645, 30.13951220892136],
            [81.08359076390269, 30.138948333998485],
            [81.08356838877017, 30.138623522257774],
            [81.0835682008119, 30.138361833931526],
            [81.08360326357979, 30.13796708371399],
            [81.08362101259974, 30.137852021753474],
            [81.08366520078846, 30.137574583600212],
            [81.08370945192974, 30.137371522078126],
            [81.08384286005997, 30.13703833045463],
            [81.08386032579347, 30.136994708838813],
            [81.08390920034935, 30.13687739677448],
            [81.08410426240187, 30.136561521597002],
            [81.08420213831806, 30.13643739716821],
            [81.08442420071634, 30.136259022036313],
            [81.08449513744068, 30.136128147296233],
            [81.08456601391049, 30.13594314595946],
            [81.08457501252684, 30.13584164667469],
            [81.08457488842043, 30.135780709512176],
            [81.08445032692111, 30.135618397171356],
            [81.08437913748725, 30.135541772235058],
            [81.08415663891782, 30.135223834012777],
            [81.08387176337123, 30.134790959735028],
            [81.08372488789331, 30.13446852130528],
            [81.08366393004638, 30.1344010145952],
            [81.08362713878148, 30.134360271709227],
            [81.08344926277334, 30.134143895724264],
            [81.0833868255416, 30.134013083936736],
            [81.0833688265102, 30.133841647074405],
            [81.08333301370766, 30.133688272196082],
            [81.08327963894425, 30.133510147075697],
            [81.08323957594575, 30.133340958518488],
            [81.08317707576151, 30.133070333629064],
            [81.08312345098653, 30.132892146455447],
            [81.08309663769973, 30.13266889695211],
            [81.0830966368004, 30.132667163059182],
            [81.08309638768822, 30.13237339681484],
            [81.08312282595773, 30.132159083874683],
            [81.08314051292439, 30.13200345889254],
            [81.083131701367, 30.131904271764768],
            [81.08305138831099, 30.131694459032133],
            [81.08293570132162, 30.131462210013012],
            [81.08281551412472, 30.131270584270112],
            [81.08261088868164, 30.130945896635865],
            [81.0823793258453, 30.130652771608197],
            [81.08219263917903, 30.130398020652194],
            [81.08209488916788, 30.130280771540413],
            [81.08205038891435, 30.130188333824606],
            [81.08193432600842, 30.12991770893518],
            [81.0818988891225, 30.129834271634365],
            [81.08181863901905, 30.129662896825266],
            [81.08175201274526, 30.12948477170488],
            [81.08166295108435, 30.129299834220035],
            [81.08158276303413, 30.12916908448574],
            [81.08137813849038, 30.128875958558694],
            [81.08124463862924, 30.12865270995468],
            [81.08118232550396, 30.128528709632292],
            [81.08116432647256, 30.128382084165878],
            [81.08112882573477, 30.128235521652073],
            [81.08106632555052, 30.127949083983083],
            [81.08103051274804, 30.127847584698316],
            [81.08099507676144, 30.127694208920673],
            [81.08090607535513, 30.127570208598286],
            [81.08074151380987, 30.12741695872569],
            [81.08064586191705, 30.127343297954894],
            [81.08051913754821, 30.127245708922374],
            [81.08043020179241, 30.127108084770327],
            [81.08037651406494, 30.126905146455385],
            [81.08033188880569, 30.126697645879688],
            [81.08028732559967, 30.126542021796922],
            [81.08026063911728, 30.126442771716597],
            [81.08020732640705, 30.126280396423226],
            [81.08016276410035, 30.126172146827173],
            [81.08014476416957, 30.1259782098266],
            [81.08013563784948, 30.12586314606739],
            [81.08010888841454, 30.125678209481862],
            [81.08008207602705, 30.12543008383136],
            [81.08005076343204, 30.125254209714058],
            [81.08002395194387, 30.124974521457034],
            [81.08001507563529, 30.124843646716954],
            [81.0800058890606, 30.124681271423583],
            [81.08001482562378, 30.12451883407698],
            [81.08007663872593, 30.124365459198657],
            [81.08025420087074, 30.12408564683517],
            [81.08034332638346, 30.123999833525545],
            [81.08040520063958, 30.12393889726235],
            [81.08064501385638, 30.12366133500268],
            [81.08087570165236, 30.123345396872594],
            [81.08101338875696, 30.123158084278202],
            [81.08120888877926, 30.12298202220262],
            [81.0812798255036, 30.12293458386398],
            [81.08139526338078, 30.12284208409494],
            [81.08156413807455, 30.12274945932012],
            [81.08191957712683, 30.122640959712555],
            [81.08207063894889, 30.122570959182497],
            [81.08216793660102, 30.12252501911439],
            [81.08238157684838, 30.122424146657067],
            [81.08277226392892, 30.122184772309424],
            [81.08302126282109, 30.122067333440725],
            [81.08325207562285, 30.12194539616314],
            [81.08343882614099, 30.121837021561362],
            [81.08360738877002, 30.121728646060205],
            [81.08364301361422, 30.12169027198854],
            [81.0837762005113, 30.121502958494773],
            [81.08395376355543, 30.121302084123954],
            [81.0840023880998, 30.12120283404363],
            [81.08406451416607, 30.121085459926064],
            [81.08417988909065, 30.12091620931568],
            [81.08425988918259, 30.12083045985787],
            [81.08430426263169, 30.12076277148475],
            [81.08444620083156, 30.120613771808166],
            [81.08465070216823, 30.120390333447176],
            [81.08475732579001, 30.120275271486662],
            [81.08480170103775, 30.12019627144082],
            [81.08488132701171, 30.120027021729697],
            [81.08499651397801, 30.119733709643015],
            [81.0850677627671, 30.119578021708378],
            [81.08516526366606, 30.1193930221703],
            [81.08517845042519, 30.11931633428145],
            [81.08522026170579, 30.119182973815214],
            [81.08523151402323, 30.11914708457033],
            [81.08528488878665, 30.11900720851594],
            [81.0853735754302, 30.11877483359177],
            [81.08540888910898, 30.118729710108084],
            [81.08552631448788, 30.118566523626214],
            [81.0855420130535, 30.118544708771367],
            [81.08580870071103, 30.118235459798655],
            [81.0858261385655, 30.118140709925854],
            [81.08587945037641, 30.117917334517415],
            [81.08591470110264, 30.117748146859526],
            [81.08600338864551, 30.1175766470447],
            [81.08606551381246, 30.11740745848749],
            [81.0861807637313, 30.117175083563325],
            [81.08622970213912, 30.117075770530448],
            [81.08625613860994, 30.11689752220292],
            [81.08623807572667, 30.116665208432664],
            [81.08617791737703, 30.116240601622508],
            [81.08617576350076, 30.11622539678467],
            [81.0861487011017, 30.116056272079334],
            [81.0861309511825, 30.115808083476338],
            [81.0861038887835, 30.115553272265743],
            [81.08609463835694, 30.11509764693892],
            [81.08609907561191, 30.114989396443548],
            [81.08610757600388, 30.114664520851022],
            [81.08613401337408, 30.114486334576725],
            [81.08616951411182, 30.11429458382804],
            [81.08617813861025, 30.114116396654424],
            [81.08622232679897, 30.11382989693226],
            [81.08624907533459, 30.11365164680609],
            [81.08629186507756, 30.11345093970914],
            [81.08629332557655, 30.113444085076537],
            [81.08631976384606, 30.11328170888379],
            [81.08634626416881, 30.1131125212259],
            [81.08634607531121, 30.11292527068474],
            [81.08633257558796, 30.11273358378793],
            [81.08629670073219, 30.112494458552476],
            [81.08625449464927, 30.112343762754506],
            [81.08620763817203, 30.112176459176283],
            [81.08617213833355, 30.11210658365195],
            [81.08615426250924, 30.112007333571626],
            [81.08607376239428, 30.11166002169267],
            [81.08602932599268, 30.11153602047102],
            [81.08601132606191, 30.11141195899478],
            [81.08602895097539, 30.111134520841517],
            [81.08601095104461, 30.11094052178771],
            [81.08600201358212, 30.110748771938347],
            [81.0859885759121, 30.1105863957456],
            [81.0859704509756, 30.110261584004945],
            [81.08596151261378, 30.110051834224805],
            [81.08596132555476, 30.109805959577443],
            [81.08594332562404, 30.10965933411103],
            [81.0859074516676, 30.1094112723124],
            [81.08589820034172, 30.108761647032452],
            [81.08591113888798, 30.108423271716674],
            [81.0859291208323, 30.10832842291842],
            [81.08594663872645, 30.108236021175514],
            [81.08603526331683, 30.107951771557055],
            [81.08608826396227, 30.107719396632888],
            [81.08615032617666, 30.10749602212377],
            [81.08620332592284, 30.107254646784554],
            [81.08622101378882, 30.107123772044474],
            [81.08623876370802, 30.106992958458306],
            [81.08622070172407, 30.106738084295216],
            [81.08618495187409, 30.106629833799843],
            [81.08612713895633, 30.106537396983356],
            [81.086047201817, 30.10639758388146],
            [81.08592257556649, 30.106266835046426],
            [81.08581576398637, 30.106151897192376],
            [81.08569145069993, 30.106005334678514],
            [81.08554013886635, 30.10581820914308],
            [81.08536226285821, 30.105601772004206],
            [81.08516645077117, 30.105340271636294],
            [81.08501507688436, 30.10503127177583],
            [81.0849525758008, 30.10483739592911],
            [81.08484601333288, 30.104499083565884],
            [81.08476120096873, 30.104336772124384],
            [81.08465470055404, 30.104158647004],
            [81.08452995109644, 30.103935395702024],
            [81.08440526369202, 30.10374827196523],
            [81.08432538860586, 30.10358814677562],
            [81.08428963875588, 30.10351827215061],
            [81.08418276332395, 30.103263397088142],
            [81.08407588879129, 30.103062771829514],
            [81.08400470115612, 30.102868771876388],
            [81.08388901416674, 30.102598209939515],
            [81.08383995165252, 30.10246739725261],
            [81.08382636109775, 30.102429081636842],
            [81.0837687640173, 30.10226670994075],
            [81.08370638883883, 30.102135897253902],
            [81.08356407562167, 30.101872083829676],
            [81.08343063871308, 30.101639833911236],
            [81.08326145195451, 30.101371522078125],
            [81.08308351389309, 30.101076146946696],
            [81.08303895068707, 30.100967896451323],
            [81.0829588255894, 30.100550646994805],
            [81.08303432547382, 30.100428771770453],
            [81.0831940135929, 30.10021889608521],
            [81.08330938851753, 30.10011958395171],
            [81.08333588884028, 30.100065459153655],
            [81.08338026318864, 30.099957146605107],
            [81.08350476353405, 30.09987127214157],
            [81.08389538856142, 30.099756084275896],
            [81.08399326267897, 30.099708646836632],
            [81.08442832590657, 30.099539209167233],
            [81.08487282572037, 30.099468958625607],
            [81.08501482597347, 30.099430520702015],
            [81.08543245044729, 30.099337771820785],
            [81.08574338834677, 30.099283459064452],
            [81.08591756993997, 30.099275379555195],
            [81.08608970107889, 30.099267397172696],
            [81.08636982640644, 30.09922889719593],
            [81.08683157701574, 30.099181208845778],
            [81.08780882711574, 30.099065521856403],
            [81.08822567457526, 30.099024102680232],
            [81.08828876381546, 30.099017833506252],
            [81.08849301334197, 30.099010896136008],
            [81.08878651338694, 30.09900170956132],
            [81.08932838909396, 30.099001271591476],
            [81.08985270194069, 30.098978396435882],
            [81.08998145068347, 30.098987334797698],
            [81.0903901385941, 30.09905477046135],
            [81.09065676419834, 30.09914927212128],
            [81.09104795220128, 30.099295646676808],
            [81.09129682698705, 30.099394708798798],
            [81.09150113856674, 30.099464585222506],
            [81.09153384151364, 30.099478228837256],
            [81.09198126412412, 30.099664896617753],
            [81.09211463897947, 30.0997189584632],
            [81.09225701335055, 30.099766208843505],
            [81.092532388459, 30.09979539723986],
            [81.0927283597261, 30.099809185645427],
            [81.09275445085729, 30.09981102206109],
            [81.09301238811128, 30.099858209488843],
            [81.09318557595361, 30.099903208866067],
            [81.09354982566396, 30.099966084067603],
            [81.09383426234137, 30.100033521529838],
            [81.09396763899542, 30.100103334101618],
            [81.09421638877535, 30.100188895601093],
            [81.09452751373385, 30.100312771817016],
            [81.09453165691048, 30.100315168510292],
            [81.0947143883584, 30.100420897306606],
            [81.09498107601593, 30.100519958529333],
            [81.0953099517929, 30.10061220918618],
            [81.09579882685443, 30.100744896959498],
            [81.09628757601087, 30.100812210315326],
            [81.09638976417631, 30.10083695965801],
            [81.09658532715116, 30.10085033347616],
            [81.09690507570872, 30.100843334952003],
            [81.09709176417363, 30.100836458735614],
            [81.0972960766527, 30.100788960142438],
            [81.09749157577568, 30.10075039721312],
            [81.09794457598144, 30.10064858496429],
            [81.0981578888755, 30.100610084987466],
            [81.09849538735233, 30.100533147087106],
            [81.0986552004772, 30.100494647110338],
            [81.09890382615072, 30.10041777126321],
            [81.09901051362436, 30.10037033382389],
            [81.09926820086685, 30.100300210086687],
            [81.09960570114225, 30.100191708680484],
            [81.09984982570893, 30.10011483373262],
            [81.1000543270456, 30.10003795878481],
            [81.1002497641154, 30.09992052171475],
            [81.10051613880881, 30.09972858390705],
            [81.10064026233829, 30.09962702166979],
            [81.10067334210112, 30.0995983665714],
            [81.1009243887496, 30.09938089701086],
            [81.10117288851802, 30.09914839618159],
            [81.10124382614174, 30.099087459019074],
            [81.10142138738718, 30.098963208685177],
            [81.10167907552898, 30.098816397059124],
            [81.10190988833074, 30.098746333576457],
            [81.10196349332062, 30.098718559813733],
            [81.10214970064823, 30.098622084141937],
            [81.10226507647218, 30.098529520521026],
            [81.10242513870924, 30.098405334039],
            [81.10261145035821, 30.09823589726892],
            [81.10285113856924, 30.09801927217171],
            [81.10318388862561, 30.09772577212675],
            [81.10345920078157, 30.09743908444625],
            [81.10370763759744, 30.09715239676575],
            [81.10370751349103, 30.097044147169697],
            [81.10367176274173, 30.09696070896962],
            [81.1036685836383, 30.09695094503013],
            [81.10365413782824, 30.096906584171563],
            [81.1036717006885, 30.096868210099842],
            [81.10376082710053, 30.096814021449973],
            [81.10383170087238, 30.09672827199222],
            [81.10383145086081, 30.09646433356221],
            [81.10385332597025, 30.09631095868383],
            [81.10386220137957, 30.096078583759663],
            [81.10386195046868, 30.0958868339103],
            [81.10387057676576, 30.095747021707723],
            [81.10391470200193, 30.095460521985558],
            [81.10393245102188, 30.095383772043476],
            [81.10402982601573, 30.095167146946324],
            [81.10412757602688, 30.094997897235203],
            [81.10430507611841, 30.09481058374149],
            [81.10475763745507, 30.094395147318266],
            [81.10501932668063, 30.09409945922272],
            [81.10509057546972, 30.094015958969408],
            [81.1051793259652, 30.093876021761048],
            [81.10526795055551, 30.093706772049927],
            [81.10548970178837, 30.093334459018138],
            [81.10555151399126, 30.093219334105015],
            [81.1057288261245, 30.092856021488274],
            [81.10578220088792, 30.09273189705948],
            [81.10586163890355, 30.092407021466897],
            [81.10592832543193, 30.092330271524872],
            [81.10604363920265, 30.09219933383224],
            [81.10607013862608, 30.092151958446152],
            [81.10607913904107, 30.09202789607059],
            [81.10608770058695, 30.09186770882775],
            [81.10619407599586, 30.09159695983186],
            [81.10629145188904, 30.09138033383539],
            [81.1063713881291, 30.09122464680007],
            [81.10652226379148, 30.091007958750367],
            [81.10664626231522, 30.09083195872796],
            [81.10690376339801, 30.09060614705612],
            [81.10699707615419, 30.09052939711404],
            [81.10722776395022, 30.09037358417362],
            [81.10741445061649, 30.09026739672305],
            [81.10762738759394, 30.090172458891914],
            [81.10784932588575, 30.090025709319093],
            [81.10796463875715, 30.089926334232985],
            [81.10824001386561, 30.08970958413005],
            [81.10841757601037, 30.089562771604676],
            [81.10858632569841, 30.089422833496997],
            [81.10879920062268, 30.089276020971624],
            [81.1090211389145, 30.089129208446252],
            [81.10902477217553, 30.08912670743166],
            [81.10923438885601, 30.0889823968202],
            [81.10943851337674, 30.088819833568493],
            [81.10949157697473, 30.088758897305297],
            [81.10960713895838, 30.088580584226634],
            [81.10972232592468, 30.088348145450595],
            [81.10984195104527, 30.088048084851266],
            [81.10992138816158, 30.08776152217655],
            [81.10997476382437, 30.08762164702142],
            [81.1100455134897, 30.087382459732794],
            [81.11009832617685, 30.087080147231063],
            [81.11015163798777, 30.086895146793665],
            [81.11022245060565, 30.086725896183225],
            [81.11031976264701, 30.086493522158378],
            [81.1104084510892, 30.08632433360117],
            [81.11046595194216, 30.08617758402835],
            [81.11047482645216, 30.08596783424821],
            [81.11039463840189, 30.085868646221115],
            [81.11029688839074, 30.085751397109334],
            [81.1102612014933, 30.08570633388024],
            [81.11028770091673, 30.08565214612969],
            [81.11034988903623, 30.085575397086984],
            [81.11034095067441, 30.085419771205522],
            [81.1103047637539, 30.084957333514183],
            [81.11022457570368, 30.084547021427966],
            [81.11016663867952, 30.08435302147484],
            [81.1100775761193, 30.084145583851637],
            [81.10997590146673, 30.083976128195786],
            [81.10997070068737, 30.083967459630628],
            [81.10991707681171, 30.083868271603478],
            [81.10978351399802, 30.083597709666606],
            [81.109756825717, 30.083505208998247],
            [81.10967695063084, 30.083381145723365],
            [81.10953438920075, 30.083196397096117],
            [81.10947201402229, 30.08307239677373],
            [81.10934751367688, 30.082785959104797],
            [81.10924057619172, 30.082562771654636],
            [81.10915607589226, 30.082362083443456],
            [81.10898670207467, 30.081951709304008],
            [81.10886182671197, 30.08162702077044],
            [81.10875526244547, 30.081403771267105],
            [81.1086928261131, 30.081218834681522],
            [81.1086482638064, 30.08109483525851],
            [81.10856801370295, 30.080970834036805],
            [81.1084523887668, 30.080770146724944],
            [81.10835432579154, 30.080607835283445],
            [81.10824782717555, 30.080508709309527],
            [81.10820763737263, 30.08048389701429],
            [81.10819882581524, 30.0804455849958],
            [81.10824313900969, 30.080346271063604],
            [81.10824307695651, 30.08027633528468],
            [81.10824295105141, 30.080199583544015],
            [81.10821632572294, 30.08007564617418],
            [81.10811813864126, 30.07987495886232],
            [81.10803820150187, 30.079735145760424],
            [81.10800245075256, 30.079658520824125],
            [81.10794913894165, 30.079534458448563],
            [81.10792245066062, 30.079448771943305],
            [81.1078866387574, 30.079286396649934],
            [81.10789526415516, 30.079193895981575],
            [81.10789496468095, 30.07888580173841],
            [81.1078949511911, 30.078871333445306],
            [81.10792170062604, 30.078722458774507],
            [81.10798345167501, 30.078553209063386],
            [81.10808988913715, 30.078374958937275],
            [81.10820095091321, 30.078253084612243],
            [81.10829845091286, 30.07813570869604],
            [81.10840501338072, 30.078058895801462],
            [81.10861826332223, 30.0779955835294],
            [81.10881338832729, 30.077950271188115],
            [81.10893782572015, 30.07790283374885],
            [81.10895563859191, 30.07786439582526],
            [81.10895563859191, 30.07783514627505],
            [81.10891995169447, 30.077819396448035],
            [81.10861801331072, 30.077749709781358],
            [81.10834232613752, 30.077673208951467],
            [81.10817345054437, 30.077664333542202],
            [81.10794251363615, 30.077603647290516],
            [81.10761370171105, 30.077527210312553],
            [81.10736495103174, 30.07744170906767],
            [81.10716932600366, 30.07734939725691],
            [81.10686732556667, 30.07722564694609],
            [81.10652938912006, 30.077070209022963],
            [81.10633407615677, 30.076971083948422],
            [81.10603176365504, 30.076824708493575],
            [81.10584070088771, 30.076748209462323],
            [81.10564513791286, 30.076640083972734],
            [81.10552963888176, 30.07657702171224],
            [81.10525388875601, 30.076414835276466],
            [81.10516495120157, 30.076347209855896],
            [81.10495195037225, 30.0762684589223],
            [81.10490697078012, 30.076251066033933],
            [81.1046317638449, 30.076144646558248],
            [81.1036807640561, 30.07571458356125],
            [81.10356526412568, 30.075660521715747],
            [81.10331645049388, 30.07551408420767],
            [81.10325420032115, 30.075482583654377],
            [81.10297870200554, 30.07528877165953],
            [81.10289857600856, 30.075234708914763],
            [81.10274732532883, 30.07506564716192],
            [81.1025607007158, 30.07483346019609],
            [81.10243176401474, 30.074641834453132],
            [81.10236020046295, 30.074416333946715],
            [81.10233357603374, 30.07434639726847],
            [81.10233338897478, 30.074193021490828],
            [81.10238657667924, 30.07388395957713],
            [81.10240395158115, 30.07372833369567],
            [81.10243491793716, 30.073417179958938],
            [81.10243920050874, 30.073374146499702],
            [81.10246570083149, 30.0732117091531],
            [81.10255426336863, 30.073010896835456],
            [81.1025806386856, 30.072832646709344],
            [81.10259832565225, 30.072692833607448],
            [81.10259401340306, 30.072532647263927],
            [81.10254932609058, 30.07239283416203],
            [81.10249582542207, 30.072307146757453],
            [81.10246913804036, 30.0721988971614],
            [81.10239795220377, 30.071991458638934],
            [81.10236213760265, 30.071890022306718],
            [81.10228188839847, 30.071689271142986],
            [81.10217538798383, 30.07151114692192],
            [81.10214832558484, 30.071389396703353],
            [81.10206838844545, 30.071202209114688],
            [81.10200595031444, 30.07101727162984],
            [81.10193438766191, 30.07050083414623],
            [81.10190313891877, 30.070322646972613],
            [81.1018850760355, 30.070067771910203],
            [81.10185832570124, 30.069927833802524],
            [81.10182220173328, 30.069526459178803],
            [81.10176001361378, 30.069264833805107],
            [81.1017372508735, 30.06917776594122],
            [81.10169751253022, 30.069025771522206],
            [81.10169738302784, 30.068897354629257],
            [81.1016970754597, 30.0685927101855],
            [81.10170570175677, 30.068484396737574],
            [81.1016877629799, 30.068360335261332],
            [81.10166563875828, 30.068283708526394],
            [81.10158545070806, 30.068159710002647],
            [81.10155001382213, 30.068019833948256],
            [81.10147820205742, 30.06761164606064],
            [81.10146063739853, 30.067503396464645],
            [81.10141607599115, 30.067347771482503],
            [81.10136922940649, 30.067276867133785],
            [81.10134463834441, 30.067239646892233],
            [81.10124676332754, 30.06696220963829],
            [81.10113107633816, 30.066660022142344],
            [81.1011131375613, 30.066567521473985],
            [81.10105970074466, 30.066321708879798],
            [81.10104011890644, 30.066196166220152],
            [81.10102838904896, 30.066120958615386],
            [81.10101051412397, 30.065996959192375],
            [81.10102820109068, 30.065920209250294],
            [81.10099232713424, 30.065733021661686],
            [81.10091201407823, 30.065455645561656],
            [81.10083207693884, 30.065254959149115],
            [81.10076088840435, 30.06507683402873],
            [81.1006717637909, 30.064767834168265],
            [81.10064476344513, 30.064697958643933],
            [81.10058270033142, 30.064582958736594],
            [81.1004581388321, 30.064436459175283],
            [81.10031595062065, 30.064258334054898],
            [81.10019132616884, 30.064143397100167],
            [81.10011895052918, 30.06406902226763],
            [81.09993238796937, 30.063884271841744],
            [81.09979026271048, 30.063753459154896],
            [81.09977689248967, 30.06374190916182],
            [81.09959463858178, 30.063584458555965],
            [81.09935451419955, 30.063343271175086],
            [81.09923901247049, 30.063275708707067],
            [81.09919457696822, 30.063228334220298],
            [81.0989545137399, 30.063021020703616],
            [81.09889220061461, 30.062957897289152],
            [81.0986521373863, 30.062764083495722],
            [81.0983854515274, 30.062563522088908],
            [81.09818988765323, 30.06245539659932],
            [81.09795013918762, 30.06227964658848],
            [81.09748782650206, 30.061862710096023],
            [81.09738557538407, 30.061738708874373],
            [81.09726963838318, 30.06150645985531],
            [81.09716313886781, 30.061299022232163],
            [81.09697613833816, 30.061028458496594],
            [81.09687838832701, 30.060859333791257],
            [81.09676276249155, 30.060627083872873],
            [81.09647813875517, 30.060279959052934],
            [81.0963933884442, 30.060117583759506],
            [81.09626895105129, 30.0598537712346],
            [81.09611763831839, 30.059529083600353],
            [81.09601070083323, 30.05920652196346],
            [81.09595720106404, 30.05888858374118],
            [81.09592138736218, 30.058764522264937],
            [81.09593038867655, 30.058640459889375],
            [81.09599201382042, 30.05836289673033],
            [81.09599497798592, 30.058321772531826],
            [81.0960009503836, 30.05823889730732],
            [81.09600088833037, 30.058139647226994],
            [81.09594301335943, 30.05799989707765],
            [81.09589845195205, 30.057814896640195],
            [81.09577382570154, 30.057652585198696],
            [81.0954713890934, 30.057321210205714],
            [81.09542695089311, 30.057258084992668],
            [81.09532926383451, 30.057120584047766],
            [81.09522238840253, 30.056926646147872],
            [81.09506245207058, 30.056786958951022],
            [81.0949470141934, 30.056703520750943],
            [81.09474257580928, 30.05650295844481],
            [81.09465370030807, 30.056424085203446],
            [81.09424457712561, 30.05599352218337],
            [81.09414226395438, 30.055869521860984],
            [81.09398232672305, 30.055675646014322],
            [81.09379545119918, 30.055459271828],
            [81.09360007618261, 30.05523608347852],
            [81.09350590996985, 30.05515736671913],
            [81.09336007590684, 30.05503545911921],
            [81.09303988848012, 30.054774083757025],
            [81.09284463846933, 30.05467264562617],
            [81.09276445041911, 30.054557708671382],
            [81.09269332573643, 30.054386333862283],
            [81.09263551371805, 30.054242021452183],
            [81.09258408508754, 30.054241221055577],
            [81.09235445219628, 30.054237647149762],
            [81.09227438915178, 30.05423320989479],
            [81.09222320064026, 30.054219709272218],
            [81.09216582659167, 30.05421077180972],
            [81.09205270177085, 30.054201835246545],
            [81.09178107683528, 30.05421102182123],
            [81.09169907665114, 30.054211084773783],
            [81.09150873064345, 30.0542362981667],
            [81.09144238855544, 30.054245084543084],
            [81.0913629505398, 30.054254147011306],
            [81.0910398268266, 30.05429508415085],
            [81.09081420041508, 30.054313272039906],
            [81.0907270767932, 30.054331271970682],
            [81.09046551347274, 30.054336021290396],
            [81.09022951338142, 30.0543361471955],
            [81.09011645061378, 30.05433621014805],
            [81.0899420136131, 30.054313772062983],
            [81.08983438904585, 30.054291334877234],
            [81.08975232680848, 30.054264271578916],
            [81.0895522636261, 30.054190021752163],
            [81.08943913880529, 30.05413595990666],
            [81.08927763855223, 30.054045835247166],
            [81.08912888888716, 30.053994020807465],
            [81.08903638911812, 30.053962521153494],
            [81.08893388888794, 30.053894896632244],
            [81.08876945054976, 30.05377095926241],
            [81.08849770150778, 30.053611022031077],
            [81.08838970192329, 30.053557022238863],
            [81.08816401255916, 30.053444333589198],
            [81.08800482536248, 30.05336995965598],
            [81.08790751332111, 30.053338460001953],
            [81.0877943885003, 30.05331595896439],
            [81.08768676393305, 30.05326639642709],
            [81.08758426370287, 30.053230396565596],
            [81.08739432598742, 30.053133521594816],
            [81.08730701350794, 30.053083959956837],
            [81.08707095046407, 30.05296902210273],
            [81.08698632605814, 30.05292852113439],
            [81.08683226319846, 30.052842896682364],
            [81.08668863876971, 30.052800145610263],
            [81.08647820190754, 30.052732646994116],
            [81.0863293883906, 30.0526695838343],
            [81.08618057577303, 30.052595209001765],
            [81.08605245115973, 30.052532146741214],
            [81.08597038892236, 30.052473583788924],
            [81.08580595058419, 30.052354146626612],
            [81.08567782687027, 30.05223689661551],
            [81.08548251390692, 30.0519844596152],
            [81.08542607695193, 30.051912271933872],
            [81.08538257674456, 30.051840084252547],
            [81.08522332659533, 30.051729646605963],
            [81.08514126345858, 30.051680084068664],
            [81.08504357639998, 30.051612521600646],
            [81.08493070069136, 30.051470460193627],
            [81.08480745040356, 30.051346521924472],
            [81.08469976288382, 30.05122020854583],
            [81.08456601391049, 30.050996959042493],
            [81.08446863801731, 30.050823397082183],
            [81.08436832673692, 30.050708397174844],
            [81.08432628612928, 30.05063111213616],
            [81.08431188888261, 30.050604646886995],
            [81.08427057672577, 30.050462583681337],
            [81.0841784510747, 30.050316022066795],
            [81.08413201368154, 30.05020777157148],
            [81.08403426367039, 30.050002584951358],
            [81.0839829510524, 30.04992820921956],
            [81.08369032694708, 30.049596772173345],
            [81.08362088849327, 30.049508833765742],
            [81.0834311387361, 30.04932177208218],
            [81.08327707677574, 30.04915495863446],
            [81.0832053891175, 30.049085021956273],
            [81.08304095167864, 30.049017458588878],
            [81.08283057597038, 30.04894995907341],
            [81.0826618181884, 30.04889994507647],
            [81.08261020070029, 30.048884646709155],
            [81.08252545038926, 30.048853147055127],
            [81.08236107590301, 30.04874045930478],
            [81.08231501352714, 30.048702147286292],
            [81.08220738895989, 30.048675146041205],
            [81.0820738261462, 30.048634645972186],
            [81.08194057539731, 30.04859864611069],
            [81.08181738896138, 30.048567083504167],
            [81.08169420072682, 30.04851758391942],
            [81.08151457643663, 30.04845677176263],
            [81.08135051401507, 30.048380208879564],
            [81.0812016384449, 30.04825170924903],
            [81.08113738907929, 30.04818408382846],
            [81.08107576393542, 30.048096147219496],
            [81.08108601350875, 30.04797433404832],
            [81.08105513888358, 30.047895396955084],
            [81.08090620215955, 30.047636083738325],
            [81.0808032630602, 30.04743989663399],
            [81.08075195134154, 30.047331647037936],
            [81.08068507595556, 30.047171521848327],
            [81.08067243058827, 30.047145481079156],
            [81.08060295076564, 30.04700239624367],
            [81.08054901392592, 30.046851333522284],
            [81.08052857683242, 30.046801709831072],
            [81.08050776382231, 30.04672502104296],
            [81.08042563863239, 30.046573959220893],
            [81.08037438896696, 30.046519833523575],
            [81.08023595092845, 30.046440958483515],
            [81.08003070045652, 30.046400522266367],
            [81.079871575313, 30.046355459037272],
            [81.0797587005037, 30.046296896984245],
            [81.07969188807022, 30.04624508524256],
            [81.07944063907428, 30.046132459545447],
            [81.07930707715997, 30.046078395901304],
            [81.07918157586909, 30.046013020584496],
            [81.07884282553601, 30.045900459638574],
            [81.07862720048496, 30.04584420884322],
            [81.0784220138649, 30.045794709258473],
            [81.07821682634551, 30.045754147136222],
            [81.07798082535487, 30.045691208982134],
            [81.07784232706177, 30.045664208636367],
            [81.07764245093841, 30.045659833434627],
            [81.07736038847122, 30.04559008381534],
            [81.07721163880615, 30.045572083884622],
            [81.07706545130958, 30.045536084922446],
            [81.07694745036463, 30.045504585268475],
            [81.07674738898089, 30.045448333573802],
            [81.07661388732112, 30.0454258334355],
            [81.07615232556941, 30.045372021601565],
            [81.0759879510831, 30.04537658386232],
            [81.07580095055346, 30.045349583516497],
            [81.07548295117732, 30.04531820886831],
            [81.07531863874425, 30.045309270506493],
            [81.07506226361261, 30.04530039599655],
            [81.07471632859574, 30.045327221873833],
            [81.07465201357962, 30.04533220861458],
            [81.07445720063936, 30.045368396434412],
            [81.0742825756804, 30.04539102157844],
            [81.07419538910597, 30.045413645823146],
            [81.0739594519672, 30.04543183461152],
            [81.07378763918825, 30.045436458925508],
            [81.0735620766286, 30.045436645984466],
            [81.07341851335377, 30.045445647298834],
            [81.07314145201644, 30.045470646653087],
            [81.07305426364343, 30.04547970912131],
            [81.073008138315, 30.04545045867178],
            [81.07295695070275, 30.04536477216658],
            [81.07280288784307, 30.04527464660771],
            [81.07263363903127, 30.04518670909937],
            [81.07241282579133, 30.044977085224332],
            [81.07228476413064, 30.044895959181247],
            [81.0721667011324, 30.044835084071906],
            [81.07203832560828, 30.044758459135608],
            [81.07178182547091, 30.044598458951782],
            [81.07169201377548, 30.044535333738736],
            [81.07147107552976, 30.044339208687575],
            [81.07131226335031, 30.044219770625944],
            [81.07114140385278, 30.044060845131924],
            [81.07113776339713, 30.044057459184444],
            [81.07104532568127, 30.04396952167616],
            [81.07100413853021, 30.04393795906958],
            [81.07079543646029, 30.04381427890587],
            [81.07068088891197, 30.043746396279232],
            [81.07046526386091, 30.04360889713297],
            [81.0703423885904, 30.04353683355805],
            [81.07020357553466, 30.04340377166676],
            [81.07004726347049, 30.043259521309892],
            [81.06981632566294, 30.043058896051264],
            [81.06971870065757, 30.042961959926572],
            [81.0696211386047, 30.042876270723355],
            [81.06945182594109, 30.042716210284937],
            [81.06934413842129, 30.04259220906323],
            [81.06929257579179, 30.04252458364266],
            [81.06915926298967, 30.042359959144846],
            [81.06909757579257, 30.04224720844195],
            [81.06903851281714, 30.042118646758183],
            [81.06896326834021, 30.04196856879446],
            [81.06893563846899, 30.0419134592388],
            [81.06888951314056, 30.041800708535902],
            [81.06881257613952, 30.041604521431566],
            [81.06874570075354, 30.041421834050425],
            [81.06868401355644, 30.041306834143143],
            [81.06852995159602, 30.041106146831282],
            [81.06847351374171, 30.041002395644114],
            [81.06838363909372, 30.040860335136415],
            [81.06827070133187, 30.04070027199998],
            [81.06817313837968, 30.040578521781413],
            [81.0681013886682, 30.040449959198327],
            [81.06793201395135, 30.04021320907242],
            [81.06787038880748, 30.04012527156408],
            [81.06779320089555, 30.039998959084755],
            [81.06771107570563, 30.039865959246697],
            [81.06761863888914, 30.039748710134916],
            [81.06754438906239, 30.039678770758712],
            [81.06744157586809, 30.039548021024416],
            [81.0673390756379, 30.0394104589256],
            [81.06726188772598, 30.039309020794747],
            [81.06721979585694, 30.039249794142734],
            [81.06715932634188, 30.039164709283966],
            [81.06709770209733, 30.039045209169103],
            [81.06698476343615, 30.03884445890469],
            [81.06687188862679, 30.038684396667634],
            [81.06676907633187, 30.038553583980786],
            [81.06663576352975, 30.038416083935203],
            [81.0665818887432, 30.038301084027864],
            [81.06654070069283, 30.038197333740015],
            [81.06645338911267, 30.038064208896174],
            [81.06637126302343, 30.03787708515938],
            [81.06633001381914, 30.037737272057484],
            [81.06632350002957, 30.037642655284344],
            [81.06631463900942, 30.037513958702277],
            [81.06628901372704, 30.037457583800517],
            [81.06628370053244, 30.037256833536105],
            [81.06629113882508, 30.037153084147576],
            [81.06628095220424, 30.03701545999553],
            [81.06621413887149, 30.03689820908511],
            [81.06612170115562, 30.036796770954254],
            [81.06601401363588, 30.036702084034005],
            [81.06596763919526, 30.036636708717197],
            [81.06581913864238, 30.036510459190367],
            [81.0656751373977, 30.036336834277506],
            [81.06544426344209, 30.036131646758122],
            [81.06531101359246, 30.035989646505016],
            [81.06510313799947, 30.03582052179968],
            [81.06502626305166, 30.035721333772585],
            [81.06497463836894, 30.03563789647177],
            [81.06489257703089, 30.035529646875773],
            [81.06472857576324, 30.03532446025571],
            [81.06467201380246, 30.035241022055573],
            [81.06458970065421, 30.03516889642748],
            [81.06442038888991, 30.03498627199889],
            [81.06430751318123, 30.03483520927756],
            [81.06425107712556, 30.034740520558614],
            [81.06415345032156, 30.034621022242447],
            [81.06408926390844, 30.034548833661802],
            [81.06405357611169, 30.03450827153955],
            [81.063909951683, 30.03439327163221],
            [81.06375076268762, 30.034316708749145],
            [81.06360195096931, 30.034262583951147],
            [81.06354929476419, 30.03425664752632],
            [81.0634428258258, 30.034244646972923],
            [81.06322763784527, 30.034215396523393],
            [81.06308413842231, 30.034183896869422],
            [81.0628788888497, 30.03412083460887],
            [81.0627657631295, 30.034084833848055],
            [81.06272426391371, 30.03407127207163],
            [81.06265813856231, 30.034035209257524],
            [81.06260688889688, 30.033978897308316],
            [81.06246838880514, 30.0338007101347],
            [81.06233995032852, 30.03362933352696],
            [81.06227057572653, 30.03350533410395],
            [81.06218332709892, 30.033385833989087],
            [81.06205520068704, 30.033203146608002],
            [81.06198832620038, 30.033063335304746],
            [81.06191632647733, 30.03299795908862],
            [81.06177788843888, 30.032876209769313],
            [81.06170101349102, 30.03279502167294],
            [81.06161382601732, 30.032707147117208],
            [81.06140857554539, 30.032506458906028],
            [81.06131473488716, 30.032386930912196],
            [81.06130057596084, 30.032368896807213],
            [81.06113907570784, 30.032215583982065],
            [81.06106707688417, 30.0321322096338],
            [81.06097982645787, 30.032005897154477],
            [81.0608413875201, 30.031877396624623],
            [81.06073895114173, 30.031782709704316],
            [81.06066176412918, 30.03173989657904],
            [81.06039495056655, 30.03157308403064],
            [81.06037770516701, 30.03156241717187],
            [81.0602309510976, 30.031471646799105],
            [81.06016676288584, 30.031417522001107],
            [81.06004363850315, 30.031367958564488],
            [81.05990513841147, 30.031243959141477],
            [81.05983313868848, 30.03117633372085],
            [81.05979226270284, 30.031097396627615],
            [81.05969463769742, 30.030971147100786],
            [81.05959701269205, 30.030847083825904],
            [81.05949451336119, 30.030723083503574],
            [81.05942596163885, 30.03063265577248],
            [81.05938170060506, 30.03057427088595],
            [81.05930445063996, 30.03041414569634],
            [81.05926101338514, 30.030339771763124],
            [81.05913776399666, 30.030227021959547],
            [81.05906095110208, 30.0301413966082],
            [81.05895295061822, 30.02998120846604],
            [81.05886057585496, 30.029861709250554],
            [81.05876838904999, 30.029735520877637],
            [81.05870670095356, 30.029642959055366],
            [81.05866557585568, 30.029584334049844],
            [81.05850670072374, 30.029469397095056],
            [81.05836270037838, 30.02934764687643],
            [81.0582655133428, 30.029268708883876],
            [81.05816779750592, 30.029196054454417],
            [81.0580654510598, 30.029119959218804],
            [81.05797570141755, 30.02904102212551],
            [81.05783695041498, 30.028964396289894],
            [81.05779088893843, 30.02891933396012],
            [81.05771888831612, 30.028790772276352],
            [81.05761120079632, 30.028621646671695],
            [81.05755476384132, 30.028544958782845],
            [81.05748276411833, 30.028411896891555],
            [81.0573546377064, 30.028220272047975],
            [81.05731863874422, 30.02814808346733],
            [81.0571133891716, 30.027915833548946],
            [81.05701320109841, 30.027809834056654],
            [81.05693113886105, 30.02768808383803],
            [81.05682345134124, 30.027485083469855],
            [81.05675663890781, 30.027390395650286],
            [81.05665413867763, 30.02726189691907],
            [81.05650013787113, 30.02706114575534],
            [81.05643851362657, 30.02698452171836],
            [81.05631526243945, 30.02683796010382],
            [81.05621763833341, 30.026702646310127],
            [81.05616382560015, 30.026614708801844],
            [81.0560922008944, 30.026474896599268],
            [81.05607132583106, 30.026337271547902],
            [81.05606638765374, 30.02623127205561],
            [81.05606626354728, 30.026012396854696],
            [81.05605552294406, 30.02587486803077],
            [81.05603526391633, 30.02561545858657],
            [81.05603520096383, 30.025498208575527],
            [81.05601695102155, 30.02536058352416],
            [81.05599651392805, 30.025270396811436],
            [81.0559503877003, 30.02508314716954],
            [81.05591957602769, 30.024990708554412],
            [81.05584757720396, 30.024896021634163],
            [81.05577070045751, 30.024808084125823],
            [81.0557038889234, 30.024677271438975],
            [81.05565751358347, 30.024584771669936],
            [81.05558582682448, 30.02452164645689],
            [81.05549345116191, 30.02448108343532],
            [81.05533957536119, 30.024427021589815],
            [81.05527532599558, 30.02437970915628],
            [81.0552652634812, 30.024321021198205],
            [81.05527526394235, 30.02421058355162],
            [81.05526513847542, 30.024120271833112],
            [81.05515232571929, 30.02402108380602],
            [81.05505476366648, 30.023969209111783],
            [81.05492645199422, 30.023910647058756],
            [81.05477282530569, 30.023879146505465],
            [81.05465482615938, 30.023838585282533],
            [81.05447526392237, 30.02375520913563],
            [81.05433170064754, 30.023710146805854],
            [81.05420595114379, 30.023647021592808],
            [81.0540675140046, 30.023568146552748],
            [81.0538723889996, 30.023450896541704],
            [81.05376995082264, 30.023376521709167],
            [81.05341601363818, 30.023153334259007],
            [81.05325688849467, 30.023072210014504],
            [81.05306207555441, 30.023004646647166],
            [81.05294413846127, 30.02298889592089],
            [81.05266182598257, 30.022885208585535],
            [81.05236951394198, 30.022786083510994],
            [81.05229013887885, 30.022761335067628],
            [81.05199257569689, 30.022671208609438],
            [81.05185938879981, 30.02262608422643],
            [81.0516490760441, 30.02257433363866],
            [81.05150032637903, 30.022538333777106],
            [81.05146476089004, 30.02253052046717],
            [81.05137713904378, 30.022511272277427],
            [81.05120282614956, 30.022448209117613],
            [81.05104401397011, 30.022398583627762],
            [81.05092607687698, 30.02237389543899],
            [81.05080807683134, 30.022355897306852],
            [81.0506181391159, 30.022301833662766],
            [81.0505258255065, 30.022252209072235],
            [81.05037188855187, 30.022211646949984],
            [81.05028470107817, 30.02219814722679],
            [81.05021570149347, 30.02217570914172],
            [81.05006695092908, 30.022168958830434],
            [81.04988738779275, 30.02216445862291],
            [81.04956951252302, 30.02211952129892],
            [81.04937957660621, 30.022101522267462],
            [81.04926688795655, 30.022074521921695],
            [81.04908213842998, 30.02201139670865],
            [81.04895407676929, 30.021909896524562],
            [81.0487897634369, 30.021740833872457],
            [81.04868726410604, 30.02161002118561],
            [81.04855370039303, 30.02146795887927],
            [81.04843570034734, 30.021353021924483],
            [81.04832288849059, 30.02121770903011],
            [81.04824863866378, 30.021120709053548],
            [81.04813063861815, 30.020985397058496],
            [81.04807986919064, 30.020902123434325],
            [81.04803301361272, 30.02082527096951],
            [81.04792538904553, 30.020705771754024],
            [81.04776101365991, 30.020561458444604],
            [81.0475765770974, 30.02045102169734],
            [81.04734582544955, 30.020295459667807],
            [81.04718663735349, 30.020173709449182],
            [81.04713801370843, 30.020117333648102],
            [81.04697363742349, 30.01986702174571],
            [81.04688638879588, 30.019740709266387],
            [81.04679920042281, 30.019594145853205],
            [81.0467683887502, 30.01949714587664],
            [81.04671707613221, 30.019316709498582],
            [81.0466402011844, 30.019174647192244],
            [81.04650138902792, 30.018919772129834],
            [81.04646313816335, 30.018854396813026],
            [81.04633476353854, 30.018644709086118],
            [81.04619632550009, 30.018509334138514],
            [81.04605757719548, 30.018367271832176],
            [81.04596032630809, 30.01828389658459],
            [81.04580638845414, 30.018189208765023],
            [81.04569876388689, 30.018121584243772],
            [81.0455192628038, 30.018038146043637],
            [81.0453241386981, 30.017948022283463],
            [81.0450933888489, 30.017846584152608],
            [81.0448857021135, 30.017806022030356],
            [81.04478326393655, 30.01777452147701],
            [81.04469088917324, 30.017724896886534],
            [81.04460376375278, 30.01772039667901],
            [81.0444138880905, 30.017715959424038],
            [81.04426507637226, 30.01771602147727],
            [81.044044792833, 30.017731749720497],
            [81.04394732610825, 30.01773870867453],
            [81.0437217635486, 30.01776583402608],
            [81.04363982631696, 30.017770334233603],
            [81.04355263884327, 30.017752335202147],
            [81.04348070117345, 30.017684708882257],
            [81.04331670080518, 30.017538147267715],
            [81.043178263666, 30.017414146945384],
            [81.04312670193582, 30.017346458572263],
            [81.04310107665344, 30.017253958803224],
            [81.04301907556993, 30.017150270568607],
            [81.0428702629523, 30.016936021480262],
            [81.04282157545538, 30.016834522195495],
            [81.04278557559388, 30.016699210200443],
            [81.04275359300397, 30.016649195304183],
            [81.04265720186845, 30.01649845903671],
            [81.04256476415259, 30.016374458714324],
            [81.04247270145407, 30.016214333524715],
            [81.0423903271519, 30.01606995906144],
            [81.04227751349651, 30.015936960122644],
            [81.0421750753195, 30.01583995924676],
            [81.04197476392426, 30.015691146629138],
            [81.04188276237966, 30.015634771727377],
            [81.04161306289262, 30.015565282012233],
            [81.04160307592127, 30.015562709051835],
            [81.04148513792882, 30.015522146929584],
            [81.0412492637426, 30.015407147022245],
            [81.04113645188579, 30.01536214584638],
            [81.04095176351314, 30.015321521670955],
            [81.04079295133369, 30.015281021601936],
            [81.04047995038945, 30.01519764725367],
            [81.04036720058588, 30.015170583955353],
            [81.0400956386029, 30.015139085200644],
            [81.03989045198284, 30.015121083471286],
            [81.03983901256044, 30.015112084854877],
            [81.03983157606643, 30.015080522248354],
            [81.0398315140132, 30.01504895874251],
            [81.03985188905347, 30.014929396574416],
            [81.03993370217864, 30.014437647279635],
            [81.04000026370124, 30.014081209080587],
            [81.04003620061025, 30.0139526464975],
            [81.04012551408124, 30.0137563352867],
            [81.04020257608806, 30.01355558412297],
            [81.04024332616865, 30.0133773339968],
            [81.040340764115, 30.01313145845012],
            [81.04036107620277, 30.01299158419431],
            [81.04038182536101, 30.012894584217747],
            [81.04039659492696, 30.012831404146027],
            [81.04042770067792, 30.01269833416086],
            [81.04043545103531, 30.01261264675628],
            [81.04046620155401, 30.012420896906917],
            [81.04047132589102, 30.012274271440504],
            [81.04044557650224, 30.012170522051974],
            [81.0404503887745, 30.011877272018523],
            [81.04044545059713, 30.011671958594093],
            [81.0404477007009, 30.011568209205564],
            [81.04041201380346, 30.0114487090907],
            [81.04037082575309, 30.01131558424686],
            [81.04032476247784, 30.011202833543962],
            [81.04024887498565, 30.01109128343603],
            [81.04022195198155, 30.0110517087694],
            [81.04013476360853, 30.01086908524013],
            [81.04005270137111, 30.010704395991127],
            [81.03999107532792, 30.010521708610042],
            [81.03993201415119, 30.010311958829902],
            [81.03990632591626, 30.010115708773014],
            [81.03989588838459, 30.009896896524594],
            [81.03990095156774, 30.00956983378086],
            [81.0399112020404, 30.00930589625017],
            [81.03992638889179, 30.009245022040204],
            [81.03994697976935, 30.0091053186556],
            [81.03994932699987, 30.009089396158743],
            [81.03997520049518, 30.00896752183371],
            [81.04002113876459, 30.00872164718629],
            [81.0400362635628, 30.008547959320936],
            [81.04004145085236, 30.008482584004128],
            [81.04004663904124, 30.008441958929325],
            [81.04000057666536, 30.008311146242477],
            [81.03991820056456, 30.008155522159655],
            [81.039833577058, 30.008054021975568],
            [81.03970557565185, 30.007918709980515],
            [81.03960801359898, 30.007785647189905],
            [81.03956201237702, 30.007686460062132],
            [81.03953601387599, 30.00760752206952],
            [81.03947476374941, 30.007499208621653],
            [81.03936157597599, 30.007294022001588],
            [81.03930026379618, 30.007147396535174],
            [81.03918713897536, 30.00698277203736],
            [81.03910257572335, 30.006856459558037],
            [81.03907737851824, 30.006818766273113],
            [81.03902570167486, 30.006741458751378],
            [81.03898976386654, 30.006682834645176],
            [81.03888220045326, 30.00658133446109],
            [81.03869220068458, 30.006412208856432],
            [81.03857426359144, 30.00635814701093],
            [81.03841545051267, 30.00625439672308],
            [81.03832313780265, 30.006166459214796],
            [81.03827676336198, 30.006103334901013],
            [81.03824595168936, 30.006001833817606],
            [81.03819470112461, 30.005875522237602],
            [81.03815863831056, 30.005706333680394],
            [81.03812557563487, 30.005577771097308],
            [81.03808438848381, 30.005404084131214],
            [81.0380380140432, 30.0052733334976],
            [81.03799713895688, 30.005194333451755],
            [81.03796113909533, 30.005054522148555],
            [81.03788426414752, 30.004862770500495],
            [81.0378071391882, 30.004711708678485],
            [81.0377354515299, 30.004596645818594],
            [81.03756107568313, 30.004425271908815],
            [81.03745576417225, 30.004341833708736],
            [81.03730701360786, 30.00424714678843],
            [81.03713788890252, 30.0041502088651],
            [81.03698395104857, 30.00408258344453],
            [81.03683038911123, 30.003985647319837],
            [81.03675326415186, 30.00387289661694],
            [81.03669682539822, 30.003694708544003],
            [81.03666070143026, 30.003575083423414],
            [81.0365687016843, 30.003394709997906],
            [81.03645070163861, 30.00319852109493],
            [81.036370012666, 30.003058646839122],
            [81.03629801384233, 30.0028827718225],
            [81.03620176390035, 30.002709083957086],
            [81.03612163880263, 30.002492583865717],
            [81.03608145079835, 30.002368521490098],
            [81.0349813883804, 30.00000708346016],
            [81.03493464251977, 29.999889341519975],
            [81.03453813772495, 29.998890647085773],
            [81.03411130239476, 29.998542304583736],
            [81.03402970061023, 29.99847570978625],
            [81.03367582547901, 29.998022395717044],
            [81.03346901288467, 29.997670584529885],
            [81.03290863902254, 29.9968563968053],
            [81.0328451387922, 29.996795521696015],
            [81.03283701341752, 29.996739145894935],
            [81.03277345113389, 29.996648897128978],
            [81.03254307540266, 29.99638052144394],
            [81.03215732649949, 29.995672335010283],
            [81.0318235754977, 29.99531827192078],
            [81.03122332569598, 29.994443209086853],
            [81.03061545054265, 29.993902022260556],
            [81.03055151414111, 29.99375539589488],
            [81.03045632539914, 29.993665209182097],
            [81.0304404505664, 29.993597521708296],
            [81.0303688258607, 29.993514085306856],
            [81.03036101345009, 29.993444146829972],
            [81.02993163843445, 29.99297508383313],
            [81.02982832701576, 29.992864522080083],
            [81.02978876404029, 29.992767584156752],
            [81.02972488879266, 29.992704396890474],
            [81.02960982683209, 29.992487896799048],
            [81.02956220053517, 29.992361585219044],
            [81.02953820212639, 29.99226677059505],
            [81.02936313919759, 29.991795459293087],
            [81.0293313265795, 29.99163533410342],
            [81.02908501306297, 29.991242896942197],
            [81.02904126284409, 29.991100771683307],
            [81.02880270058421, 29.990651958720946],
            [81.02876282554399, 29.990512084465195],
            [81.02842126393023, 29.989959522114304],
            [81.02842126393023, 29.989848958562618],
            [81.02824232582276, 29.98943620931368],
            [81.02816288870639, 29.989285146592294],
            [81.0280436386031, 29.988987395452],
            [81.02799563908752, 29.98880695907394],
            [81.0278963890072, 29.98843702115164],
            [81.02786463754302, 29.988299459052826],
            [81.02785685930667, 29.988262991543877],
            [81.02782470044963, 29.988112208511666],
            [81.02779295168341, 29.98789114615954],
            [81.027745262434, 29.98774683374944],
            [81.02765901385249, 29.987532521708545],
            [81.02736482672543, 29.987185208930327],
            [81.0268778888036, 29.98671839693651],
            [81.02650169249915, 29.98643849014428],
            [81.02635045081269, 29.986325958875966],
            [81.02625907609553, 29.986260583559158],
            [81.02604457609635, 29.98616364653509],
            [81.02589363838075, 29.986046396524046],
            [81.02575851344471, 29.985949396547483],
            [81.025305639144, 29.985644958947773],
            [81.02512257674556, 29.985520958625386],
            [81.0249479517866, 29.985360833435777],
            [81.02478107538639, 29.985180397057718],
            [81.0246777639677, 29.985069896458583],
            [81.02429926270088, 29.98451958421151],
            [81.02410232556196, 29.984359459021846],
            [81.02407141676252, 29.984310756236482],
            [81.02393057573556, 29.98408883503174],
            [81.02383532583968, 29.98401214624363],
            [81.02343776344208, 29.983588146475824],
            [81.02332345151615, 29.98348889729482],
            [81.02306295119428, 29.983360395865645],
            [81.02289132547435, 29.983340083777875],
            [81.02285307550909, 29.983310772174434],
            [81.02271313740141, 29.983310834227666],
            [81.02255445202638, 29.98328820908364],
            [81.02195357539722, 29.98315302209437],
            [81.02173751417502, 29.98311470917656],
            [81.02156582550248, 29.98310345955707],
            [81.02154057613672, 29.983076397158072],
            [81.02145214759861, 29.983073415006174],
            [81.02140713832881, 29.98307189695055],
            [81.02138716978214, 29.9830507206143],
            [81.02136888836355, 29.983031333928977],
            [81.02132788377486, 29.983030014623523],
            [81.02122895115525, 29.983026833721453],
            [81.0211972005904, 29.982988521702964],
            [81.02107026398153, 29.982993021910488],
            [81.02045057533383, 29.982873583848857],
            [81.0203676389554, 29.982846520550538],
            [81.02034238869032, 29.98283070956967],
            [81.02017720031762, 29.982830771622844],
            [81.01989732680028, 29.982747333422765],
            [81.01970688906175, 29.982670646433235],
            [81.01913482581159, 29.98245421019368],
            [81.01895051335555, 29.982377522304887],
            [81.01865151395629, 29.982199397184502],
            [81.01834345118937, 29.982075333909563],
            [81.0178031376048, 29.981649084937374],
            [81.01765051366033, 29.981534084130715],
            [81.01750732540279, 29.981459647245003],
            [81.01730388886341, 29.98138083425812],
            [81.01709432614228, 29.981209333543973],
            [81.01669620166842, 29.98073120897817],
            [81.0169632625446, 29.979754396847966],
            [81.0170804514018, 29.979546896272268],
            [81.01712126353556, 29.97939120923695],
            [81.01712576374308, 29.97935289721846],
            [81.01717626337393, 29.979073146908263],
            [81.01718820097477, 29.978829583518518],
            [81.01749970184983, 29.978310708872186],
            [81.01760876353364, 29.97814833357876],
            [81.01788795086827, 29.977839208712567],
            [81.01812788909086, 29.977575272081197],
            [81.01856082632116, 29.9771353345281],
            [81.01887657649286, 29.976914209223423],
            [81.01908707540827, 29.976783396536575],
            [81.01936557656018, 29.976546458452333],
            [81.01963726354893, 29.976341145927222],
            [81.01988857549742, 29.976115584266836],
            [81.02022438864469, 29.975813271765105],
            [81.02030791138105, 29.975758176598617],
            [81.02046732610626, 29.975653020670393],
            [81.020670576486, 29.975427459010064],
            [81.02104851387787, 29.975158959218618],
            [81.02121820155884, 29.974969521526248],
            [81.02175413904234, 29.974518208448615],
            [81.0220912013479, 29.97426102122921],
            [81.0222443253154, 29.97401514658179],
            [81.02227538879822, 29.97385495843963],
            [81.02219176353913, 29.97351208381565],
            [81.02212920040233, 29.97333614674585],
            [81.02193251417424, 29.972799334221975],
            [81.02187901350572, 29.972643709239833],
            [81.02182520077247, 29.972472333531414],
            [81.02180738790071, 29.972285083889574],
            [81.02174870174127, 29.97210920887295],
            [81.02161038870855, 29.971879084052546],
            [81.02133470063598, 29.971506896925803],
            [81.0212467523358, 29.971380998134634],
            [81.02121970072864, 29.97134227152867],
            [81.02109788845684, 29.971195646961576],
            [81.0208250764162, 29.970681396629175],
            [81.02064070100761, 29.970243834185624],
            [81.02060732536785, 29.97010852219057],
            [81.02055451268069, 29.970029584198016],
            [81.0205522005237, 29.96993933363342],
            [81.02051401351099, 29.96983558424489],
            [81.02036557591066, 29.9695762089749],
            [81.01997551385892, 29.968908583764176],
            [81.01994445037616, 29.96880027211489],
            [81.01987032555513, 29.96868527220761],
            [81.01971926373307, 29.9684935835121],
            [81.01936776371139, 29.967783021968955],
            [81.01940170052814, 29.967413084046655],
            [81.0193685757992, 29.96691464649382],
            [81.01931888915544, 29.966131897269292],
            [81.01935407602986, 29.965725834479713],
            [81.01941688737952, 29.965452896533975],
            [81.0195191375982, 29.9652137721979],
            [81.01970882620145, 29.964740021035198],
            [81.01983698948555, 29.96457725543604],
            [81.01989888892263, 29.964498646595302],
            [81.01999795104467, 29.96430908479647],
            [81.02009701316666, 29.964218897184423],
            [81.02017145095175, 29.964067771510486],
            [81.02017563909453, 29.963869272249156],
            [81.0203076388865, 29.963377521155735],
            [81.02059638871242, 29.962678209232024],
            [81.02066663835473, 29.96246389629181],
            [81.02084820068399, 29.96204202162204],
            [81.02088538854991, 29.96190214646691],
            [81.02089382598933, 29.961780397147663],
            [81.02094720165206, 29.961471333435327],
            [81.02102426365889, 29.96123895851116],
            [81.02111257708384, 29.96101114674707],
            [81.02151851396832, 29.960537396483687],
            [81.02177726331013, 29.96034558368177],
            [81.0220550755813, 29.96019439685398],
            [81.02270651389455, 29.95971833353434],
            [81.02316457548483, 29.959194958680428],
            [81.02327170182764, 29.958940021564786],
            [81.0233102018044, 29.958658084103376],
            [81.02320588854099, 29.958468583458398],
            [81.02300763839185, 29.958155084289785],
            [81.02288645114885, 29.958069396885207],
            [81.02240851364206, 29.95805820931895],
            [81.0216898878781, 29.957841771280755],
            [81.02145870095836, 29.957708708490145],
            [81.02111570042928, 29.957413272204803],
            [81.02070270116877, 29.957007335320327],
            [81.02057870084644, 29.956871958574084],
            [81.020413513373, 29.956738895783474],
            [81.02024020052488, 29.956493084088606],
            [81.02013270096342, 29.9563938349076],
            [81.02012330484672, 29.95637818400604],
            [81.02002707648847, 29.956217896938426],
            [81.0199178888995, 29.95596527108046],
            [81.01975270142611, 29.95576683387236],
            [81.01958332670921, 29.955588646698743],
            [81.01939738917821, 29.955347272258848],
            [81.01903788878758, 29.95492327069246],
            [81.01893070039159, 29.954772145917843],
            [81.01860838876621, 29.954206020891206],
            [81.01857113884705, 29.953987208642843],
            [81.01841820193852, 29.95362183388056],
            [81.01837576383048, 29.953475209313524],
            [81.01836045107399, 29.953364645761837],
            [81.01832326410744, 29.953285708668545],
            [81.01830251404982, 29.953152646777255],
            [81.01825713785666, 29.95294739630532],
            [81.01819107545782, 29.952771522188073],
            [81.01807245218197, 29.952304520437337],
            [81.01794707589687, 29.952038396654757],
            [81.01779420104162, 29.951801521523066],
            [81.01767857610548, 29.951474458779273],
            [81.01745963885134, 29.95106395873478],
            [81.01741007541472, 29.950971458965682],
            [81.01735238840206, 29.9507617091856],
            [81.01728213786043, 29.950556458713663],
            [81.01722026360432, 29.95041208425033],
            [81.01722026360432, 29.950263209579532],
            [81.01720419541732, 29.950218031237227],
            [81.01700770074484, 29.949665521046995],
            [81.0169267006068, 29.94952114658372],
            [81.01683988904972, 29.949448958902394],
            [81.0167864513337, 29.94934070930634],
            [81.01650532596005, 29.948975270692245],
            [81.01645288918957, 29.94885352227226],
            [81.01640201364205, 29.948756522295696],
            [81.01624945175087, 29.948377584857724],
            [81.01598907553546, 29.947903895748254],
            [81.01571638760129, 29.947522709105783],
            [81.0152002010285, 29.94726333383585],
            [81.0149605137168, 29.947105458749945],
            [81.01475983000222, 29.94697364691632],
            [81.01461376391416, 29.946877709039086],
            [81.01415363837975, 29.946649895476355],
            [81.01392826287906, 29.946573271439377],
            [81.01374663849663, 29.946487521981624],
            [81.01340788816356, 29.946363520759917],
            [81.01293707688467, 29.94608839656229],
            [81.01271401354097, 29.945914708696876],
            [81.01262332680517, 29.945883146989672],
            [81.01249120200742, 29.945752334302824],
            [81.01246207656362, 29.945684646829022],
            [81.01234257644882, 29.945580896541173],
            [81.01227620198517, 29.945450083854325],
            [81.01197082549328, 29.944998959634347],
            [81.01188826413215, 29.94481858351088],
            [81.011768389, 29.944692208978324],
            [81.01155951336091, 29.944297458760786],
            [81.01144591189939, 29.943962698719645],
            [81.01135513882866, 29.94369520886687],
            [81.01130976353483, 29.943329772051413],
            [81.01109932577333, 29.94308845966475],
            [81.01095063906081, 29.942862897105044],
            [81.01080582662763, 29.941958333810135],
            [81.0098189519831, 29.94157045890961],
            [81.00970332614764, 29.94149602112452],
            [81.00953401258465, 29.941376458956483],
            [81.00917070176655, 29.941159958865057],
            [81.00904245034889, 29.94109902170254],
            [81.00879057632409, 29.94089608428692],
            [81.00826220034031, 29.940523896260856],
            [81.00795232633885, 29.940284833977955],
            [81.00789470048011, 29.940266771094684],
            [81.00771695037702, 29.940124646735114],
            [81.00736595037836, 29.93992845873146],
            [81.0068291387538, 29.939452521316866],
            [81.00652376316123, 29.939161520487858],
            [81.00623575887323, 29.938890539466854],
            [81.00620970101693, 29.938866022149227],
            [81.00611907543504, 29.938816458712665],
            [81.00582849908602, 29.9384252041599],
            [81.0057638890923, 29.93833820914108],
            [81.00568513006476, 29.938246990905952],
            [81.00562757615177, 29.938180334055232],
            [81.00561780501772, 29.938161955509884],
            [81.005441700674, 29.937830709119964],
            [81.00526426353497, 29.937616459132357],
            [81.00525163885209, 29.9375217092595],
            [81.0048413888191, 29.93695327117655],
            [81.00474942144871, 29.93688029209187],
            [81.0042359508285, 29.93647283355449],
            [81.00400057666536, 29.936163771640793],
            [81.00392226370155, 29.935987833671618],
            [81.00391382536282, 29.935845710211424],
            [81.00388076358644, 29.93577358368401],
            [81.00388076268712, 29.93536752179375],
            [81.00392645094502, 29.93525245893386],
            [81.00393032612374, 29.935056208876972],
            [81.00395739931457, 29.93479798383993],
            [81.00398401385121, 29.93454414569584],
            [81.00402126377037, 29.934399834185058],
            [81.00402545101383, 29.934282522120725],
            [81.00407076335512, 29.934214833747603],
            [81.00407076335512, 29.93409077227136],
            [81.00411188845294, 29.933896772318235],
            [81.0041160756964, 29.93364639656403],
            [81.0041947007249, 29.933145646854143],
            [81.00425657677965, 29.9327757089319],
            [81.00430182616839, 29.93264939645252],
            [81.00426882644524, 29.93244189587682],
            [81.00418207604201, 29.932232084043505],
            [81.00400882704577, 29.93180345996177],
            [81.00386832686183, 29.931553084207508],
            [81.00381038803903, 29.931401959432947],
            [81.00370738868509, 29.93119670896101],
            [81.00361226379499, 29.931059083909645],
            [81.00346395120044, 29.93072077154642],
            [81.00339757583754, 29.930519959228775],
            [81.00319945069413, 29.929969584029152],
            [81.00287732612776, 29.929204896788633],
            [81.0028546794, 29.92915927148323],
            [81.00274520133001, 29.92893870915418],
            [81.00242632601419, 29.928525959005867],
            [81.00232401374228, 29.928194334001375],
            [81.00238063865561, 29.927833458547298],
            [81.00290607565495, 29.927032646439557],
            [81.00304358019713, 29.92679146085726],
            [81.00324045078622, 29.926446145473278],
            [81.00352526338025, 29.926010771979577],
            [81.00390532587016, 29.925586709259278],
            [81.00411151343565, 29.9252483339435],
            [81.004157138741, 29.92506108430166],
            [81.0042232011399, 29.924952833806287],
            [81.00423282658375, 29.92478589625216],
            [81.0042872643458, 29.924342510698352],
            [81.00430151320433, 29.924226458584258],
            [81.00433451382679, 29.923960334801677],
            [81.00440895071256, 29.923777583568665],
            [81.00457238900458, 29.923477584123248],
            [81.00471032612069, 29.92328133406636],
            [81.00517676399653, 29.923026396950718],
            [81.00548620092746, 29.92290458377954],
            [81.00577951391347, 29.92282108352623],
            [81.00598492716273, 29.922753021035135],
            [81.00603132588503, 29.92273764712479],
            [81.00669201372551, 29.922550396583574],
            [81.00716226292815, 29.92236764624994],
            [81.00743488880914, 29.922223271786606],
            [81.00755463893552, 29.922166833932295],
            [81.00770326269549, 29.922155584312804],
            [81.00806251397393, 29.92182170920455],
            [81.0083680757262, 29.921580333865393],
            [81.00892932642734, 29.921221645562525],
            [81.00934207567633, 29.920957708931155],
            [81.00962688916968, 29.920743333937764],
            [81.0096572044165, 29.920721354506952],
            [81.01006870090987, 29.920423020605995],
            [81.01046551417221, 29.92013427078001],
            [81.01074163841594, 29.919940208773653],
            [81.01114201389288, 29.919667271727235],
            [81.01141032662531, 29.919545458556115],
            [81.01184657965598, 29.919434404873925],
            [81.01195120138686, 29.919407771451517],
            [81.01267432645903, 29.91913027124508],
            [81.01335470135842, 29.918767021580834],
            [81.01356926341083, 29.91854595922871],
            [81.01370970064221, 29.91828202169802],
            [81.01380876276426, 29.918173709149414],
            [81.01388707662733, 29.918033833994286],
            [81.01406857600404, 29.917632333465463],
            [81.01430885776762, 29.917163442239143],
            [81.01434138894399, 29.91709995909588],
            [81.01449382582945, 29.91688789625948],
            [81.01456401341852, 29.916671335014144],
            [81.01468832580565, 29.916526958752172],
            [81.01504688910273, 29.916132146481402],
            [81.01525820100522, 29.91586595884695],
            [81.01549251396841, 29.91561102083199],
            [81.01572782607838, 29.91539895889491],
            [81.0160210140586, 29.91496358360257],
            [81.01624788872914, 29.914728958574642],
            [81.01650807698621, 29.91450333396176],
            [81.01674726337558, 29.91438820904864],
            [81.01687963908415, 29.91430477174788],
            [81.01749229143616, 29.914143920805373],
            [81.01812607695695, 29.913977521945128],
            [81.01878238779699, 29.913485708798476],
            [81.01913345164752, 29.91323765689242],
            [81.01919113866018, 29.913196896919317],
            [81.01946751381479, 29.91305702086487],
            [81.01954858769722, 29.913030425214004],
            [81.0198184508609, 29.912941895951803],
            [81.02029320117038, 29.91275914651743],
            [81.02065632582884, 29.91262827177735],
            [81.02110620089053, 29.912483833462204],
            [81.02193182619288, 29.91205958368289],
            [81.02214245191266, 29.911897147235607],
            [81.02227451375785, 29.91183395907001],
            [81.02269132614384, 29.911423334019673],
            [81.02286606981335, 29.911271408848506],
            [81.0231038874345, 29.911064645716863],
            [81.02332688872497, 29.910834458843226],
            [81.02363238842406, 29.91067877090859],
            [81.02385926399387, 29.910536645649756],
            [81.02443138839789, 29.9101147089267],
            [81.0248248876585, 29.909866522122343],
            [81.02509738853371, 29.909722083807196],
            [81.02583207708875, 29.90924377218238],
            [81.02598051378976, 29.909167022240354],
            [81.02627370176998, 29.909000021733675],
            [81.02651320112341, 29.908857895575466],
            [81.02711576398139, 29.908456208887003],
            [81.02717122697061, 29.90843017621171],
            [81.02720245053274, 29.90841552085965],
            [81.02735763664566, 29.90828275394597],
            [81.02745813858229, 29.90819677066446],
            [81.02747165179534, 29.908192585219695],
            [81.0275820138989, 29.908158396592796],
            [81.02799076296338, 29.9078222722801],
            [81.02830851232807, 29.907528895442226],
            [81.0284735127425, 29.907341583747154],
            [81.02860777792665, 29.907206981317188],
            [81.02867151377939, 29.907143083586504],
            [81.02867576307608, 29.907106958719226],
            [81.02884417282036, 29.906971465061133],
            [81.02895620046831, 29.906881334106345],
            [81.02942251333837, 29.906412021097935],
            [81.02944320044338, 29.906337584212167],
            [81.0296040136144, 29.90617964707309],
            [81.02976931080508, 29.905883762026065],
            [81.02978170076494, 29.90586158384508],
            [81.02979692358923, 29.905844218835682],
            [81.03007445077532, 29.905527646683595],
            [81.03044163857084, 29.90507414645475],
            [81.03052026359933, 29.90489827053881],
            [81.03075120140687, 29.904577833999895],
            [81.03091201367857, 29.904280021705688],
            [81.03121320112837, 29.903770146575027],
            [81.03127488832547, 29.90363933388818],
            [81.03125026398857, 29.903377645561932],
            [81.03121295201618, 29.903219772274724],
            [81.03116757582302, 29.90293783391394],
            [81.03102704416284, 29.902464504533327],
            [81.03099813815362, 29.902367146626545],
            [81.03095663893777, 29.902150583582625],
            [81.03091188867279, 29.90197245846224],
            [81.03091951402439, 29.90141752100186],
            [81.03099345088708, 29.900860272283808],
            [81.03107232682646, 29.900555771731547],
            [81.03110495153237, 29.900420458837175],
            [81.03114176348168, 29.900163270718394],
            [81.03120916497102, 29.900051080293167],
            [81.03122038851018, 29.90003239687769],
            [81.03132732599533, 29.899511334180772],
            [81.03133151413812, 29.899342146522883],
            [81.03138101282354, 29.899276709152844],
            [81.03143463849784, 29.89901952103412],
            [81.03149645159999, 29.898958646824155],
            [81.03154588913151, 29.898683396721424],
            [81.03168620045784, 29.898248021429083],
            [81.03171920197963, 29.898155520760724],
            [81.03173970202567, 29.897652521846453],
            [81.03171476382533, 29.897465271305293],
            [81.03170496031572, 29.897014195648637],
            [81.03170226414824, 29.896890083810376],
            [81.03169801395222, 29.89668477218453],
            [81.03174745148374, 29.896493021435845],
            [81.03174732647801, 29.896368959060283],
            [81.0317926388193, 29.896170458899633],
            [81.03179682696208, 29.896032833848267],
            [81.03205682546218, 29.895450833988832],
            [81.03232882631431, 29.895080834013356],
            [81.03258418970864, 29.89475249423225],
            [81.03262188838949, 29.894704020773986],
            [81.03304595110984, 29.894160271779185],
            [81.03317057646098, 29.89402264672782],
            [81.03320776342758, 29.89394820894273],
            [81.03334820155834, 29.893742897316884],
            [81.0334015133692, 29.89369327182709],
            [81.0335088888242, 29.893566895495894],
            [81.0335253886858, 29.893519521908445],
            [81.03403301371264, 29.8930660216796],
            [81.03440820097774, 29.892477146503154],
            [81.03446607684805, 29.89236889600778],
            [81.0345773265824, 29.892213208972464],
            [81.03461870079246, 29.89206658350605],
            [81.03468051389467, 29.891922209042775],
            [81.03472182695083, 29.891771022214982],
            [81.03474632718132, 29.8914258956886],
            [81.03477513786237, 29.89133564692264],
            [81.03496495057209, 29.891017583694577],
            [81.03539382556465, 29.890444522197015],
            [81.03575701407499, 29.890058646489422],
            [81.03592626288679, 29.889873646051967],
            [81.03608707605775, 29.88970664644461],
            [81.03641695188088, 29.889293771290568],
            [81.03654082539884, 29.889093021925476],
            [81.03661488906596, 29.889009520772845],
            [81.03676757596293, 29.888761333968432],
            [81.03709770089824, 29.888070958607898],
            [81.03715888267635, 29.887983436586353],
            [81.03722545049419, 29.887888209173582],
            [81.03742238853243, 29.887603958655802],
            [81.03749357616766, 29.88747083381196],
            [81.03762551390639, 29.887362521263412],
            [81.0380091386109, 29.886812021957326],
            [81.03807507690334, 29.88665408391887],
            [81.0384341384248, 29.886315646549917],
            [81.03845870160785, 29.886250209179877],
            [81.03879701307176, 29.88583733402578],
            [81.03882570144503, 29.885740334049217],
            [81.03900332654234, 29.88550339686435],
            [81.03906926393546, 29.885307146807406],
            [81.03925488850234, 29.88508827160649],
            [81.039547700566, 29.884855833729773],
            [81.03969226388693, 29.884761083856972],
            [81.0399629355411, 29.884577938720952],
            [81.04000576395492, 29.884548959866663],
            [81.04041782612182, 29.884117958876743],
            [81.04063967358212, 29.883764794209924],
            [81.04070270076909, 29.8836644586479],
            [81.04076269814004, 29.883603669873537],
            [81.04092982545114, 29.883434333827495],
            [81.04118945163196, 29.883120645801228],
            [81.04128457562274, 29.882987583909937],
            [81.0416102642024, 29.882606272261683],
            [81.04169532657812, 29.8825047091251],
            [81.0418495755975, 29.88230839611566],
            [81.04185763891894, 29.88226783399341],
            [81.04250938749828, 29.881714958678458],
            [81.04220401370435, 29.881142084239855],
            [81.04198513850338, 29.880772209270106],
            [81.041572075391, 29.8802332086957],
            [81.04139951347685, 29.88005058426711],
            [81.0413585898271, 29.87999584073549],
            [81.04124607654524, 29.879845333795174],
            [81.04108088817253, 29.879561147129266],
            [81.04078757608585, 29.879064958780873],
            [81.04070076362939, 29.87893645915034],
            [81.04063082605182, 29.878762772184245],
            [81.04047801414907, 29.87854408404229],
            [81.04040351251217, 29.87835908360489],
            [81.04037470093175, 29.878194397053846],
            [81.04022601331991, 29.87781770882026],
            [81.04018451410411, 29.877614708452086],
            [81.04006882711474, 29.8772877086609],
            [81.03999051415099, 29.877177083955303],
            [81.03994901313649, 29.87690652111911],
            [81.03984170153336, 29.87651852211218],
            [81.03981257698888, 29.876358396922512],
            [81.03972582568639, 29.876144146035585],
            [81.03970920171832, 29.876015583452443],
            [81.03961013869701, 29.87587127194172],
            [81.03959770197241, 29.87549452075558],
            [81.0396627013726, 29.875188448188396],
            [81.03970070042703, 29.875009521772085],
            [81.03968832575566, 29.87475683386083],
            [81.03969882534057, 29.87434858392004],
            [81.03969876418665, 29.874129771671676],
            [81.03962701357585, 29.873615459286043],
            [81.03959688898527, 29.873455334995754],
            [81.03951851396829, 29.87327489681911],
            [81.03951426377233, 29.873184646254458],
            [81.03947313867445, 29.873085396174133],
            [81.03948120109663, 29.873001958873374],
            [81.03914626388905, 29.871172584047542],
            [81.03937682577993, 29.870031145620942],
            [81.03951288870888, 29.869449146660827],
            [81.03950470038166, 29.86932058407774],
            [81.03947157565273, 29.869182959026375],
            [81.03942170105074, 29.869011584217276],
            [81.03915751350848, 29.868497333884875],
            [81.03908332573496, 29.86806877185637],
            [81.03907901348572, 29.86797402108425],
            [81.03903757542383, 29.867886083575968],
            [81.0390375133706, 29.867784459285417],
            [81.0389963891721, 29.867701084037833],
            [81.03889138792726, 29.86724545871101],
            [81.03883782610484, 29.866746959104944],
            [81.03885132672741, 29.86649433414624],
            [81.03881826405171, 29.866352208887406],
            [81.03872757641659, 29.86617627181755],
            [81.03869457579407, 29.866104147088777],
            [81.03868220112275, 29.865943958946616],
            [81.0386452911473, 29.86575940996886],
            [81.0384173885517, 29.86461989699069],
            [81.03848732702858, 29.863900334133177],
            [81.03856163890856, 29.863731083522737],
            [81.03867707588643, 29.863543833880897],
            [81.03878407542481, 29.863320521424953],
            [81.03916788898692, 29.862900833906394],
            [81.03932863830607, 29.862754145487486],
            [81.03948938852454, 29.862505958683073],
            [81.03962132536395, 29.862372833839288],
            [81.03973707530582, 29.86224877056435],
            [81.03992651389757, 29.86186745891615],
            [81.04005838868375, 29.861637334095747],
            [81.0401779517511, 29.861296708676207],
            [81.04027688886737, 29.861109396081815],
            [81.04071051587766, 29.86084831120064],
            [81.04080151377889, 29.860793521803657],
            [81.04115570187417, 29.860689583557473],
            [81.04152276376459, 29.86052708415764],
            [81.04221326413091, 29.860062147250346],
            [81.04193482593155, 29.859419272281627],
            [81.04182957557452, 29.859150958649877],
            [81.04174270106483, 29.858776522318692],
            [81.04152807605993, 29.858438209056146],
            [81.04146201366103, 29.858244271156252],
            [81.0413214505246, 29.857923958723745],
            [81.04122657564602, 29.857705208528614],
            [81.04120706215627, 29.857655823157756],
            [81.04116863862185, 29.85755858396152],
            [81.04106957560049, 29.85740752213951],
            [81.04078882614346, 29.856744397136254],
            [81.04075576346776, 29.85652333388481],
            [81.04071038907324, 29.85637902057539],
            [81.04065238909652, 29.85618052131406],
            [81.04062776386024, 29.85601133365617],
            [81.04058207560234, 29.855896271695656],
            [81.04058626374513, 29.855738395710432],
            [81.04049938833617, 29.855325645562118],
            [81.04050357647895, 29.85523539589684],
            [81.04036726353843, 29.85498052083443],
            [81.04037551391889, 29.854763958689773],
            [81.04032995066677, 29.854653459889334],
            [81.04032601343482, 29.85452264630311],
            [81.04029488879814, 29.85437152152855],
            [81.04024326411542, 29.85391133394097],
            [81.04025113857926, 29.85341958374687],
            [81.04028581284007, 29.85323091587412],
            [81.04029632681409, 29.85317370909945],
            [81.04029238868287, 29.852961646262997],
            [81.04030026314666, 29.852476647279502],
            [81.04029201366558, 29.85210670845788],
            [81.0402628891211, 29.85202102195268],
            [81.04022588831413, 29.851811334225772],
            [81.04018007594982, 29.851240583985827],
            [81.04015395064437, 29.85066758364212],
            [81.04019207560384, 29.849679583945715],
            [81.0402162637696, 29.849444958917786],
            [81.04019813883309, 29.8487276470633],
            [81.04043101378033, 29.84784333380287],
            [81.04014538909848, 29.847060645732256],
            [81.04009907581178, 29.846929833944728],
            [81.040091433373, 29.84691938382258],
            [81.04001332545465, 29.846812583933627],
            [81.03996801401269, 29.846686270554983],
            [81.03995957567395, 29.8466185839805],
            [81.03986632587032, 29.846444896115088],
            [81.03987520038027, 29.846002772310158],
            [81.0400085140817, 29.84531252105603],
            [81.04002113876459, 29.845215521079467],
            [81.04005788866067, 29.84504402126464],
            [81.04023640948276, 29.84473257614752],
            [81.04030357714845, 29.844615397182906],
            [81.04054851380295, 29.844098708788408],
            [81.04054851380295, 29.84399720860432],
            [81.04058988801296, 29.843918270611766],
            [81.04059788838191, 29.84375358406072],
            [81.04064276275335, 29.84347839691054],
            [81.04066054684682, 29.843064451563237],
            [81.04066776390624, 29.842896459104338],
            [81.04076238877326, 29.84263470872486],
            [81.04088620113731, 29.842442895922943],
            [81.04090263894568, 29.842325583858667],
            [81.04100995144813, 29.842181208496015],
            [81.04116213742276, 29.841768334241294],
            [81.04131088888647, 29.84159458342333],
            [81.0414097639495, 29.84140733378149],
            [81.04155813859728, 29.841242645431805],
            [81.04162826233448, 29.841059958949984],
            [81.04176813838893, 29.840674147094262],
            [81.04201551400485, 29.84040789560794],
            [81.04222176362356, 29.840209272240145],
            [81.04244032586041, 29.84009420848099],
            [81.04305470041413, 29.839719521238976],
            [81.04317013919064, 29.839640521193132],
            [81.04336838844046, 29.839541209059576],
            [81.04364876377946, 29.83943727081345],
            [81.04382952751075, 29.83938887919345],
            [81.04402813918733, 29.83933570857613],
            [81.04428651261253, 29.839286021033104],
            [81.04512557648422, 29.839342146822673],
            [81.04566263901961, 29.83905095893465],
            [81.04604582575433, 29.83889739699731],
            [81.04631007534977, 29.838791333653205],
            [81.04665151285707, 29.83855433351573],
            [81.04714620113634, 29.838204520622185],
            [81.04748857573725, 29.837906646274746],
            [81.04765345114589, 29.837730646252396],
            [81.04816026318565, 29.837053708561825],
            [81.04842307656378, 29.83682802099645],
            [81.0484809506354, 29.83671520913964],
            [81.048684504986, 29.836556736903958],
            [81.04873888878876, 29.836514396821997],
            [81.04939595056271, 29.836153209303234],
            [81.0499765771574, 29.835661271150798],
            [81.05033026343096, 29.83535658353952],
            [81.05061995214913, 29.835176022155736],
            [81.05089057703856, 29.834961584209793],
            [81.05111626280535, 29.834814896690204],
            [81.05160220068109, 29.834571084188212],
            [81.05190876247946, 29.834444645803785],
            [81.05232751380379, 29.834264020568128],
            [81.05245001315762, 29.83417377090285],
            [81.05300245499933, 29.834114843725104],
            [81.05317188817213, 29.83409677094926],
            [81.05346163804421, 29.834040271041715],
            [81.05382895084546, 29.83385289729341],
            [81.05426701331209, 29.833618084307204],
            [81.05454420055446, 29.833471333835064],
            [81.05471163813166, 29.83335395881818],
            [81.0551435141619, 29.83309439558991],
            [81.05548813796725, 29.832785208670487],
            [81.05574251390595, 29.832494083735014],
            [81.05589051263712, 29.83231358350514],
            [81.05597413879553, 29.832065395801408],
            [81.056014575912, 29.83157061579135],
            [81.05602557551993, 29.83143602145526],
            [81.05608976373168, 29.831217209206898],
            [81.05621232693738, 29.831081834259294],
            [81.05636020066277, 29.830707272023062],
            [81.05654982631347, 29.830314708956678],
            [81.05684301339443, 29.8300213339175],
            [81.05716532591913, 29.829811397078345],
            [81.0580028267691, 29.82961027179664],
            [81.05829288870592, 29.829535709005825],
            [81.05843664893229, 29.82950647474405],
            [81.0589822640203, 29.829395521785955],
            [81.06020670087861, 29.828833333991213],
            [81.06080595063418, 29.82883302102715],
            [81.06105076408153, 29.828772021811403],
            [81.06135370161212, 29.828670395722213],
            [81.06181120202541, 29.82849645874461],
            [81.06249013890886, 29.828123958653805],
            [81.06317038880246, 29.827825834294856],
            [81.06393732704606, 29.82758414599158],
            [81.06405307608867, 29.827545708967364],
            [81.06411341969863, 29.827493104922894],
            [81.06434301301971, 29.827292959002932],
            [81.06457520178418, 29.827213896903856],
            [81.06467182584413, 29.827128083594232],
            [81.06525807589952, 29.82703308370992],
            [81.06544501347668, 29.82704870853115],
            [81.06563426411003, 29.82714114714628],
            [81.06584451391325, 29.82718389552042],
            [81.06598620120229, 29.82721314596995],
            [81.06601207649618, 29.8272357090608],
            [81.06617345084413, 29.82723564610825],
            [81.06635391510116, 29.82726067693875],
            [81.06677276355225, 29.827318772243586],
            [81.06706288844163, 29.827330021863077],
            [81.0674557635727, 29.82735227109049],
            [81.0678224837207, 29.827357827102105],
            [81.06817770064043, 29.827363208645238],
            [81.06868663867749, 29.82735614716853],
            [81.06878982598971, 29.827322271505693],
            [81.06931182578018, 29.827367146776453],
            [81.06989626370165, 29.827504395911205],
            [81.0702466386715, 29.82763052223089],
            [81.07052738992718, 29.82779414128663],
            [81.07074938847353, 29.827923520454135],
            [81.07157663885084, 29.8284080841658],
            [81.07186820085684, 29.827264271528975],
            [81.07186976387857, 29.827201084262697],
            [81.07189588918402, 29.827067958519535],
            [81.07189966004131, 29.827021269316162],
            [81.07190226357864, 29.826989020526923],
            [81.07193063898785, 29.826916770792366],
            [81.07196170067203, 29.82685364647864],
            [81.07197776346305, 29.82670470885529],
            [81.07216126383133, 29.82603470853502],
            [81.07228230448482, 29.82582078500127],
            [81.07229532576872, 29.825797772249416],
            [81.0723876384788, 29.825572145837896],
            [81.07243595095844, 29.825281145908207],
            [81.07254501354157, 29.824809583695355],
            [81.07256776369138, 29.824658458920794],
            [81.07259382604428, 29.824464458967668],
            [81.07254776366841, 29.824293021206017],
            [81.0725541389624, 29.824103583513647],
            [81.0725274407888, 29.823818026280946],
            [81.07250457552578, 29.823573459247825],
            [81.07252420143078, 29.82344045851039],
            [81.07248470140792, 29.823390770967364],
            [81.07246495139646, 29.823287020679516],
            [81.07236613748734, 29.823075020795613],
            [81.07232013896328, 29.8229374586968],
            [81.07206007571199, 29.822412021697517],
            [81.07204676394707, 29.822333083704905],
            [81.07196807596597, 29.822211271433105],
            [81.07192851388987, 29.82206245881548],
            [81.07182976383257, 29.82189102105383],
            [81.07182963882684, 29.821726396556016],
            [81.0718784513295, 29.82123008410116],
            [81.07188513778891, 29.82111502124127],
            [81.07191776249482, 29.82105864544019],
            [81.07192445075287, 29.820948083687142],
            [81.07196295072964, 29.820896208992906],
            [81.07196351370527, 29.82078789554498],
            [81.07200288872241, 29.82074277206135],
            [81.07201476247138, 29.82061420857889],
            [81.07204545093686, 29.820566772038944],
            [81.07205220034882, 29.820465270955538],
            [81.07215695068277, 29.81995545877743],
            [81.07215688862954, 29.819926084221436],
            [81.07218982540019, 29.819880958939166],
            [81.07218970219304, 29.81970277086623],
            [81.07222882540003, 29.819657646483222],
            [81.07224520115517, 29.819459084269397],
            [81.07228457707163, 29.819407208675784],
            [81.07233032558412, 29.81917708385538],
            [81.07246807564121, 29.818667146671544],
            [81.07246788858225, 29.81846195915216],
            [81.07248438844385, 29.818430333593085],
            [81.07252376346099, 29.818315271632514],
            [81.07252705767763, 29.818280410312752],
            [81.07254976376066, 29.818040083583014],
            [81.07273651337943, 29.81745345851033],
            [81.07274957603215, 29.817137647184722],
            [81.07270363866206, 29.817081272282962],
            [81.07269707540974, 29.816977521095794],
            [81.07265757538687, 29.81692564730082],
            [81.07260801374889, 29.81677458368017],
            [81.07239295437142, 29.816507154981252],
            [81.07221995089009, 29.816292021859397],
            [81.07204226373955, 29.816131958722963],
            [81.07187145190602, 29.815949334294373],
            [81.07163132572515, 29.81568783392646],
            [81.07128270083604, 29.815230083501604],
            [81.07103601410086, 29.81482645877412],
            [81.07091732607381, 29.81435052225885],
            [81.07093676402047, 29.813899396240174],
            [81.07095645197876, 29.813847458593386],
            [81.0709728259352, 29.81368277204234],
            [81.07137326346538, 29.812938208931257],
            [81.07152732542579, 29.81267870865554],
            [81.07168532641674, 29.81256127068616],
            [81.07175320184882, 29.812507146787425],
            [81.07176876371756, 29.812441708518065],
            [81.07197327854402, 29.811859217628864],
            [81.07200007564302, 29.811782895764054],
            [81.07201888856093, 29.810551208571496],
            [81.07194632586231, 29.810357271570865],
            [81.07188057552821, 29.810167833878495],
            [81.07181482609343, 29.810064083590646],
            [81.07180459900309, 29.81004463485209],
            [81.07150888842455, 29.809482271689546],
            [81.0715020140068, 29.809443958771737],
            [81.07141657571447, 29.80929508410088],
            [81.07139676364977, 29.809168771621557],
            [81.07135726272753, 29.80906502223303],
            [81.07129795063997, 29.80892070892361],
            [81.0711365753927, 29.808408708695026],
            [81.0711077017591, 29.808275646803736],
            [81.07107113802266, 29.808212583643865],
            [81.07105095094067, 29.808025334002025],
            [81.07101820212836, 29.80788770895066],
            [81.07096538764256, 29.80771633414156],
            [81.07099207592364, 29.80752002203144],
            [81.07102132637317, 29.80730789714181],
            [81.07104738872607, 29.80712077070706],
            [81.07107607709935, 29.80697408408679],
            [81.07108632577336, 29.80684777070809],
            [81.07109757539286, 29.80674402221888],
            [81.07111238902559, 29.80663570877101],
            [81.0711317010672, 29.806545459105678],
            [81.07119032697204, 29.80619808427423],
            [81.07122282667217, 29.80600402046923],
            [81.07124888902507, 29.8058325836069],
            [81.07128506155641, 29.805616712141614],
            [81.07128782697168, 29.805600208682733],
            [81.07135651359226, 29.8052933968728],
            [81.07106726374326, 29.803206958934084],
            [81.07094870162132, 29.802995021103413],
            [81.07088307539368, 29.8028574590046],
            [81.07085676392859, 29.80276489718233],
            [81.07081142730556, 29.80267199271833],
            [81.07080726344452, 29.802663459051473],
            [81.07078101313328, 29.802566521128085],
            [81.0707152636985, 29.802435708441237],
            [81.07056388891237, 29.801903397024205],
            [81.07051757652499, 29.801759083714785],
            [81.07043526337674, 29.80153808431521],
            [81.07037601334241, 29.801400459263846],
            [81.0703038265604, 29.80126289716503],
            [81.07028401359639, 29.8011659592417],
            [81.0701588891215, 29.800696772138394],
            [81.07010926273233, 29.800435145865322],
            [81.07000407532786, 29.800275083628264],
            [81.069991076527, 29.80015552056085],
            [81.06990532616993, 29.800036022244683],
            [81.06986582704633, 29.79989164598271],
            [81.0697373885697, 29.79939545853364],
            [81.06971113915779, 29.799235272190117],
            [81.0696846388351, 29.798874396736096],
            [81.06972032573248, 29.798585646910112],
            [81.06969388836234, 29.79820670857282],
            [81.06974207313823, 29.797701376817145],
            [81.06974938912305, 29.797624645760834],
            [81.06950870086592, 29.796420208925554],
            [81.06948882584868, 29.796223958868666],
            [81.06951495205345, 29.796156272294184],
            [81.06954145147688, 29.796081833609776],
            [81.06955426411804, 29.79594420855841],
            [81.06950182554885, 29.795835958962414],
            [81.0694008883404, 29.79576383423364],
            [81.06928995157006, 29.79569395870925],
            [81.06870326354482, 29.795373959240862],
            [81.06847301371869, 29.795299646461558],
            [81.06829582659117, 29.79525914549322],
            [81.06815776357001, 29.79520277059146],
            [81.06797382613121, 29.795144271490983],
            [81.06780951369814, 29.79512852076465],
            [81.06730020064379, 29.794930271514886],
            [81.06714232645726, 29.794781458897262],
            [81.06689270073758, 29.79463277218474],
            [81.06669538858137, 29.794547083880843],
            [81.06639437470068, 29.79447763373588],
            [81.06639307607963, 29.794477334261614],
            [81.06565382616321, 29.794240834147217],
            [81.06506920118272, 29.794085522129194],
            [81.06487845137946, 29.794081083974902],
            [81.06477338898077, 29.794069833456092],
            [81.06472101336414, 29.794074395716848],
            [81.06460920065416, 29.794081208980685],
            [81.06449763885502, 29.794069958461876],
            [81.0644252020615, 29.794040708911666],
            [81.06425445048257, 29.79393252046947],
            [81.06410307659576, 29.7938130221533],
            [81.06405701332056, 29.793634834080365],
            [81.06394857576623, 29.793499521185993],
            [81.06392876370154, 29.79325820879933],
            [81.06402020047193, 29.792685147301768],
            [81.06403988843016, 29.79264683348464],
            [81.06406276358575, 29.79244152185879],
            [81.06408920185521, 29.792418958767996],
            [81.06410207565034, 29.792321958791433],
            [81.06412851391985, 29.79220239662334],
            [81.06412845186662, 29.792076084144014],
            [81.06416095156669, 29.791879772033894],
            [81.06418531510025, 29.791448131625998],
            [81.0641952013475, 29.791272958979903],
            [81.0641966375648, 29.791040646108968],
            [81.06423601348132, 29.79090977046957],
            [81.06424232582276, 29.790686458913],
            [81.0642817637924, 29.790652584149484],
            [81.06432426395367, 29.79036833363176],
            [81.06446838750611, 29.78984720888161],
            [81.06447507576416, 29.78968702163877],
            [81.06450770047007, 29.789635146045214],
            [81.06456345034303, 29.789287709160476],
            [81.06460926360671, 29.789220020787354],
            [81.06461595186477, 29.78906439670459],
            [81.06482238854244, 29.788344708841294],
            [81.06478938791997, 29.788270271056206],
            [81.06479545204854, 29.787629584138074],
            [81.06477377748791, 29.787385154701155],
            [81.0647524518642, 29.787144646308434],
            [81.06474588861192, 29.786995770738258],
            [81.06475226390592, 29.786806333945208],
            [81.06479838833502, 29.78672727094687],
            [81.06488363866907, 29.786542272308054],
            [81.06509663769975, 29.78597820852758],
            [81.06516213892161, 29.78580677166525],
            [81.06522120099771, 29.78569170880536],
            [81.06524099777391, 29.785666152770773],
            [81.06543795110065, 29.78541189554261],
            [81.06554288759423, 29.78525614555474],
            [81.06555545202252, 29.785186208876496],
            [81.06560776378734, 29.785062145601614],
            [81.06564682584042, 29.78487939706656],
            [81.06583126420156, 29.784283770679508],
            [81.06582713901133, 29.78384389607902],
            [81.06583495052263, 29.783449146760745],
            [81.06580157578213, 29.782842396659305],
            [81.06578907610503, 29.782564896452868],
            [81.0657563263934, 29.782407020467645],
            [81.06575613843512, 29.78212958411308],
            [81.06574513073326, 29.78212202890853],
            [81.06572326281838, 29.782107021022227],
            [81.06570663885037, 29.781991959061713],
            [81.0656406385047, 29.781412270459953],
            [81.06562407658993, 29.781319833643465],
            [81.06558713873551, 29.781229583978188],
            [81.06554607659024, 29.78120027057605],
            [81.06555407695913, 29.781071708892284],
            [81.06550063834385, 29.78090483429071],
            [81.06551263889725, 29.78049877060181],
            [81.06556557569081, 29.779808459093147],
            [81.0655653256793, 29.7794926459689],
            [81.06555713735207, 29.779278396880557],
            [81.06552413852825, 29.779228770491443],
            [81.0655324518612, 29.779113708530872],
            [81.06548301253105, 29.779064083940398],
            [81.06538007612966, 29.778378395846346],
            [81.06537126367294, 29.77774902060088],
            [81.06537871995204, 29.77728716746884],
            [81.0654069514697, 29.7755383970794],
            [81.06534126408815, 29.775353396642004],
            [81.06526707541525, 29.775182021832904],
            [81.06517270055974, 29.775055770507436],
            [81.0649753263503, 29.77486864587138],
            [81.06486020053791, 29.774769395791054],
            [81.06436732619119, 29.774347834085347],
            [81.06394013832676, 29.773996147004652],
            [81.06363226351823, 29.773757145875607],
            [81.06357824034359, 29.773739168427937],
            [81.06315182589611, 29.773597271596884],
            [81.06249057597938, 29.773164458473047],
            [81.06208607531221, 29.772891708485588],
            [81.06188676396306, 29.772557895430566],
            [81.06186526387091, 29.772348146549803],
            [81.06197532560083, 29.772090958431022],
            [81.0627477640981, 29.770888270777107],
            [81.06285432566665, 29.77080927073132],
            [81.06296932557399, 29.770644522127043],
            [81.06307595189372, 29.770479770824807],
            [81.06320928178297, 29.7702942083111],
            [81.063215389079, 29.77028570881845],
            [81.06359676278043, 29.769796020769775],
            [81.06371976395599, 29.76956589684869],
            [81.06378538838504, 29.769502708683092],
            [81.06391682700001, 29.769250020771892],
            [81.06403976252506, 29.769094271683343],
            [81.06428157583412, 29.768624958674934],
            [81.0643309513124, 29.76853920921718],
            [81.06435538859034, 29.768381334131334],
            [81.06433070220021, 29.76815120931093],
            [81.06420751396564, 29.768000145690223],
            [81.06407582713774, 29.76781527205719],
            [81.06383338879994, 29.767287459049044],
            [81.06375120065746, 29.767089021840945],
            [81.0637406021471, 29.767072577737338],
            [81.06367707583644, 29.766974021034287],
            [81.06366057597484, 29.766859022026324],
            [81.06357032541024, 29.766743959166433],
            [81.06356601406037, 29.766604146064537],
            [81.06334007558405, 29.766085396423932],
            [81.06330276361172, 29.765889209319596],
            [81.06323707533085, 29.765767396148476],
            [81.06321245099389, 29.765622958732592],
            [81.06315482603446, 29.765517021293533],
            [81.06314670065979, 29.765386208606685],
            [81.0629822011677, 29.764806583856796],
            [81.06287538778895, 29.764664520651138],
            [81.06284651415535, 29.764445709302095],
            [81.06278888739729, 29.764344209118008],
            [81.06272320091506, 29.764188584135923],
            [81.06272313796251, 29.76404420877327],
            [81.06240560443513, 29.763290833205417],
            [81.06233245178129, 29.763117272144427],
            [81.06200357600426, 29.762278271225284],
            [81.06184726394014, 29.76200764543654],
            [81.061514575937, 29.76171683346513],
            [81.06141322773834, 29.761648781765928],
            [81.06098076355147, 29.76135839607315],
            [81.0606933267357, 29.76113070931484],
            [81.06034838906697, 29.76097295923472],
            [81.05990895063763, 29.760497208879144],
            [81.0597200138676, 29.760273958476432],
            [81.05958863910445, 29.760059708488825],
            [81.05946545176926, 29.759888333679726],
            [81.05914063733059, 29.759360645677305],
            [81.0589843873197, 29.759103522309772],
            [81.05887763869214, 29.758889271422788],
            [81.05879538849643, 29.758659208655615],
            [81.05865909444174, 29.758235214283786],
            [81.05861051396408, 29.75808408411325],
            [81.05856101348007, 29.758032208519637],
            [81.05854451361847, 29.757896896524585],
            [81.05847876418369, 29.757739020539418],
            [81.058347513527, 29.75758114545357],
            [81.05782176266428, 29.757209209237658],
            [81.05746870052019, 29.7569228336219],
            [81.05729607565343, 29.75681689708216],
            [81.05719751265514, 29.756765083541836],
            [81.05707851346267, 29.756661334153307],
            [81.05645470052406, 29.75651952095916],
            [81.05625751337362, 29.75646095890619],
            [81.05613724793568, 29.75646099667773],
            [81.05606038917568, 29.75646102095942],
            [81.05601126370891, 29.756424959044693],
            [81.05578145095325, 29.756411522273993],
            [81.05550226361862, 29.75640489696849],
            [81.05487426343655, 29.756344208918165],
            [81.05447195081996, 29.756344395977123],
            [81.05438982562998, 29.756308334062396],
            [81.05417613861795, 29.756222708711107],
            [81.05395476420108, 29.756123521583334],
            [81.05335507557635, 29.755814708781827],
            [81.05305963839163, 29.755607334111232],
            [81.0529280756702, 29.755550959209472],
            [81.05273507666254, 29.755474334273174],
            [81.0504228891051, 29.75435189582339],
            [81.050238688165, 29.754255645881415],
            [81.0496765138601, 29.753961896724206],
            [81.04890470039169, 29.753811084014387],
            [81.0485027205242, 29.753742570962913],
            [81.04768982602474, 29.753604022307854],
            [81.0475007633496, 29.753525084315243],
            [81.04739407587596, 29.753468708514163],
            [81.04735944478261, 29.753452709574958],
            [81.04723795177011, 29.75339658378539],
            [81.04714782531192, 29.753389896426654],
            [81.0470576385992, 29.753331272320395],
            [81.04691801345564, 29.75331777079856],
            [81.0468482008838, 29.75330652117907],
            [81.04674944453132, 29.753310344197075],
            [81.04608457574261, 29.75333608369334],
            [81.04596982674616, 29.75327752164037],
            [81.0458302016026, 29.75327752164037],
            [81.04563307650534, 29.753257333659064],
            [81.04548538804028, 29.753198708653542],
            [81.04532926393443, 29.75316495889581],
            [81.0446808887121, 29.752964395690356],
            [81.04457813757102, 29.752894521065343],
            [81.04445495113515, 29.752860709254378],
            [81.04444404685535, 29.752853429242407],
            [81.04428270038636, 29.75274570844772],
            [81.04411007641897, 29.752644271216184],
            [81.04400338894533, 29.75259464662571],
            [81.04387182532452, 29.75254508408841],
            [81.04371607533665, 29.75251583363888],
            [81.0430018256738, 29.752315272232067],
            [81.0428137018909, 29.75230408376649],
            [81.04267338786661, 29.752279271471252],
            [81.04256651243463, 29.752295147203313],
            [81.04245988881286, 29.752308708979797],
            [81.04240232590666, 29.752315459291083],
            [81.04236970120081, 29.75233127207059],
            [81.0422136391482, 29.752344833847076],
            [81.04216032553865, 29.752369709094864],
            [81.04202907578127, 29.75236295878358],
            [81.04193863905698, 29.75239233423889],
            [81.04182233962933, 29.752427103827813],
            [81.04102488918733, 29.75266552219614],
            [81.04046357553364, 29.752740147040186],
            [81.04016107597295, 29.752762834237444],
            [81.03990707595085, 29.75267489672916],
            [81.03978670079567, 29.752449396222687],
            [81.03977345108393, 29.752262146580847],
            [81.03959895113076, 29.751520021531803],
            [81.03953105231625, 29.75113405319405],
            [81.03947038854767, 29.7507892090548],
            [81.03943188767153, 29.750642583588387],
            [81.03943188857085, 29.750486958606302],
            [81.0394701385361, 29.75025008347461],
            [81.03931338850214, 29.749959708573726],
            [81.0391438887795, 29.749681709243532],
            [81.0386800769241, 29.749501396971937],
            [81.03799676483891, 29.74882151130373],
            [81.03768282590175, 29.748509147079517],
            [81.03670845093433, 29.74739733411974],
            [81.03640663755635, 29.746537959059594],
            [81.03617445059047, 29.74607102206005],
            [81.03573382595533, 29.74560639631818],
            [81.03531638854054, 29.74479677175367],
            [81.0350243211156, 29.74435309481953],
            [81.03450426386075, 29.743563083569484],
            [81.03341426395718, 29.7423700204601],
            [81.03299676358984, 29.74232952218972],
            [81.0321934666552, 29.742850060581873],
            [81.03151326352634, 29.74329083360516],
            [81.03111901333187, 29.74323002144837],
            [81.02764624258009, 29.743500463775433],
            [81.02629638806792, 29.743605583730755],
            [81.0255313258101, 29.74405008354455],
            [81.02504451379332, 29.744578020659162],
            [81.0249912577404, 29.744847605932307],
            [81.02492863884567, 29.745164583678672],
            [81.02460426417554, 29.74532708397777],
            [81.0241868879146, 29.745730895764268],
            [81.02397820113316, 29.74608508385961],
            [81.02339863843645, 29.746610833822956],
            [81.02277288835819, 29.747542583623385],
            [81.02261082602888, 29.74816970876509],
            [81.02219338861403, 29.748350271947515],
            [81.02184563786597, 29.748108895709038],
            [81.02128901417672, 29.747461583485347],
            [81.02087748081112, 29.746872539236335],
            [81.020709138516, 29.74663158388057],
            [81.02043076416851, 29.74600452079204],
            [81.02015251302811, 29.745781209235474],
            [81.01982770038813, 29.745560208936524],
            [81.01818163887157, 29.745528834288336],
            [81.01723107615328, 29.746135771448735],
            [81.01637313910913, 29.74617652152932],
            [81.0154686378674, 29.745975834217404],
            [81.01495876363612, 29.745813458924033],
            [81.01423970080162, 29.74578195927006],
            [81.01349782666341, 29.745924145682807],
            [81.01293251552323, 29.745972735153657],
            [81.01231545199659, 29.746025771771997],
            [81.01152701366078, 29.745944646628175],
            [81.01090101357096, 29.74558152196971],
            [81.01025151239742, 29.74515520914565],
            [81.00934726296589, 29.744446958860124],
            [81.00909226379702, 29.743932708527723],
            [81.00882865451882, 29.743460843243383],
            [81.00862851309546, 29.743102583917164],
            [81.00693551236407, 29.741322896634415],
            [81.00589213781194, 29.740634897282746],
            [81.0048258260511, 29.740603395830078],
            [81.00324901413074, 29.741068083625237],
            [81.00243763858623, 29.741392959217762],
            [81.00173529594719, 29.741650072692778],
            [81.00155651342243, 29.741715520854655],
            [81.00097682571999, 29.742284021890157],
            [81.00000001358984, 29.742638209086124],
            [80.99922126365044, 29.742320083804884],
            [80.99888385780383, 29.74213368312303],
            [80.99852720106952, 29.74193664705865],
            [80.99775976370216, 29.741968208765854],
            [80.9970653890565, 29.741873458893053],
            [80.99567651386008, 29.741810208674224],
            [80.99472663912314, 29.74145827158202],
            [80.99395920175579, 29.741266520833335],
            [80.99348420053548, 29.74145827158202],
            [80.99271676406744, 29.741363458756666],
            [80.99134171864546, 29.74131046170851],
            [80.99019513879466, 29.741266270821768],
            [80.98985395039955, 29.741148958757492],
            [80.9889528261121, 29.74046989686832],
            [80.98862395213376, 29.73996008379089],
            [80.98800295047585, 29.739003583748456],
            [80.98756432683228, 29.738173397084665],
            [80.98727226390389, 29.73760039674096],
            [80.98679753607746, 29.736616160707285],
            [80.98639526393032, 29.73578214652889],
            [80.98595701350541, 29.734315834308347],
            [80.98573801419803, 29.733963896316823],
            [80.98518040575118, 29.733720791581334],
            [80.9851532633125, 29.73370895920118],
            [80.98456882539102, 29.73329383394332],
            [80.98424001346592, 29.732847146078996],
            [80.98398426336314, 29.732113958492448],
            [80.98341875257341, 29.73121199423821],
            [80.98314407533888, 29.730773896698054],
            [80.98270576376007, 29.730169271694592],
            [80.98241338876693, 29.729848959262085],
            [80.98168263834316, 29.729181146093083],
            [80.98128088870209, 29.728702833568946],
            [80.98033113897094, 29.727904146559183],
            [80.98019566060134, 29.727818634522407],
            [80.97967345036949, 29.727489022200643],
            [80.97912557618446, 29.727010708777243],
            [80.97846795143488, 29.72640602172055],
            [80.97799313907217, 29.72592770919647],
            [80.9777225123841, 29.725501334319176],
            [80.97671488858128, 29.723917584129254],
            [80.97664170085386, 29.723184458595938],
            [80.9767239321638, 29.722825755004635],
            [80.97693438791174, 29.721907709073548],
            [80.97598457702668, 29.72197295848531],
            [80.9751375874298, 29.72212527755954],
            [80.97510738909477, 29.722130708565373],
            [80.97470880866467, 29.722142540945583],
            [80.97397470107165, 29.7221643342167],
            [80.97298838850338, 29.722385208610547],
            [80.97259013902374, 29.722496326144608],
            [80.97247138894352, 29.722529458967415],
            [80.97094232652722, 29.722959959034938],
            [80.97013838837597, 29.723246271698144],
            [80.96933451407659, 29.72340627098265],
            [80.96834776263921, 29.723884271442046],
            [80.96721495050963, 29.72417045909947],
            [80.96655713780171, 29.724425209156152],
            [80.96560720101155, 29.724711458866807],
            [80.96352451414509, 29.72515752080301],
            [80.96293179616549, 29.725296655816067],
            [80.96257426349155, 29.725380583247386],
            [80.96151445113105, 29.72573220917417],
            [80.96096645104092, 29.725986959230852],
            [80.95976051413646, 29.726527958998133],
            [80.95899295086406, 29.727197709306893],
            [80.95892108963665, 29.727279980187006],
            [80.95840788791378, 29.727867521668827],
            [80.95734795054756, 29.729333458872077],
            [80.95654376418344, 29.730034708834808],
            [80.95588563851146, 29.73070445914351],
            [80.95457026400652, 29.730864209315882],
            [80.9536433255791, 29.730913951717525],
            [80.9534009510931, 29.730926958612315],
            [80.95234107667937, 29.731310022139837],
            [80.95201201384344, 29.731628083569262],
            [80.95135401407657, 29.73201127120325],
            [80.9507692640903, 29.732489270763267],
            [80.94978207558239, 29.733476895442436],
            [80.94922482236774, 29.734145146581284],
            [80.94919720059039, 29.734178271310213],
            [80.94857551364908, 29.734879584225496],
            [80.94824638875991, 29.7352945206257],
            [80.94769832571723, 29.735517646921892],
            [80.94671157607843, 29.735420271028715],
            [80.94614528467719, 29.73537107811279],
            [80.94488482547439, 29.73526158385505],
            [80.94345957539912, 29.735229396219722],
            [80.9426524293662, 29.73532575857695],
            [80.94159588784004, 29.73545189658779],
            [80.939838263625, 29.734763084248982],
            [80.93897689206995, 29.734249909505763],
            [80.93863707604027, 29.734047458623365],
            [80.93814111252237, 29.73378691963069],
            [80.9379064515216, 29.73366364685984],
            [80.93717582700287, 29.73327983419705],
            [80.93637226386892, 29.732769584049095],
            [80.93556882664012, 29.732130834270663],
            [80.93543074383382, 29.73196262867242],
            [80.93418126355454, 29.730440583666052],
            [80.93308551338578, 29.72977002216885],
            [80.93198982616963, 29.72941983425801],
            [80.93045538850544, 29.72909870883842],
            [80.93031248353435, 29.72906071877719],
            [80.9286613255598, 29.728621770478355],
            [80.92779682616265, 29.72807539726182],
            [80.92636707587991, 29.727366333989153],
            [80.92424320096302, 29.726381646495838],
            [80.92289188864981, 29.725859708758605],
            [80.9225958264363, 29.725611396049146],
            [80.92235526858082, 29.725368975697734],
            [80.9222083886063, 29.725220958980117],
            [80.92210895146701, 29.72518027185214],
            [80.92169007603621, 29.724954459280923],
            [80.92131801391525, 29.724762458520672],
            [80.9208794514256, 29.724651708809347],
            [80.91986813770444, 29.72469620906287],
            [80.91976195115319, 29.724619397067556],
            [80.9192895130007, 29.72474320853229],
            [80.91906557551596, 29.72475883425284],
            [80.91745145072395, 29.72486839595973],
            [80.91719147200888, 29.72491917887703],
            [80.916208075942, 29.72511127226744],
            [80.91547107612934, 29.72519420864586],
            [80.91464596074258, 29.72509216796925],
            [80.91427938808334, 29.725046834044235],
            [80.91340232695586, 29.724940271576315],
            [80.91305563830713, 29.724987333998342],
            [80.91238596534009, 29.725077438872745],
            [80.91237095115855, 29.725079458750088],
            [80.91225744502515, 29.725071734473033],
            [80.91155557632885, 29.725023975076454],
            [80.91022813831796, 29.724933646270813],
            [80.9087568267662, 29.724697959143555],
            [80.90865663779368, 29.72468214726331],
            [80.9082655756958, 29.7245239592134],
            [80.90791828719927, 29.724258383117956],
            [80.90768995113041, 29.724083771648793],
            [80.90607420036412, 29.72346220881394],
            [80.90521938846405, 29.72272620904738],
            [80.90512901379299, 29.722647209001536],
            [80.90452413877802, 29.722425708679566],
            [80.90351495195353, 29.72205727082644],
            [80.90246063804807, 29.72149933412703],
            [80.9020843257311, 29.721494520955446],
            [80.90117363895234, 29.720799084210398],
            [80.89990304439004, 29.720745919888316],
            [80.89958132631722, 29.720732458835982],
            [80.89944376241976, 29.720725583518913],
            [80.89881982537469, 29.720560458998023],
            [80.89840970034743, 29.720327771109794],
            [80.8982352021929, 29.720077272148444],
            [80.8977328876627, 29.71935952142485],
            [80.89744597155442, 29.718565481212067],
            [80.89733826245089, 29.718267395523924],
            [80.89705526558811, 29.718075821042362],
            [80.89682788909585, 29.717921896678263],
            [80.8966871389004, 29.71782702179968],
            [80.89665324704976, 29.71759325292635],
            [80.89657070097712, 29.717023895736247],
            [80.89611582568489, 29.71640770904861],
            [80.89513542166242, 29.716151637887833],
            [80.89403663897974, 29.71586464713579],
            [80.8931185768609, 29.715539083561907],
            [80.89299332558159, 29.715493834173174],
            [80.89240076318475, 29.714958771729982],
            [80.89216715439073, 29.714472430957983],
            [80.89188695172152, 29.713889083914125],
            [80.89164988863149, 29.71389339706269],
            [80.89117063901386, 29.71377120887422],
            [80.89073151354859, 29.713843021538253],
            [80.89010295218952, 29.713833459046953],
            [80.88927487533527, 29.71382173728341],
            [80.88841188769851, 29.71380952089271],
            [80.88724582673359, 29.713720522184417],
            [80.88469632607462, 29.71352889554214],
            [80.8838675765275, 29.713363458956508],
            [80.88356845032376, 29.71334739706475],
            [80.88328822157422, 29.713063694234165],
            [80.88291282566644, 29.712683646133428],
            [80.88204257600415, 29.712159522144304],
            [80.88177157609744, 29.712102834278426],
            [80.88161532608649, 29.712068895663037],
            [80.88052882994162, 29.71215588168866],
            [80.87997126376285, 29.712200520437705],
            [80.8787798338223, 29.711345178837007],
            [80.87787595041482, 29.710696271216023],
            [80.87644631794325, 29.709935553582966],
            [80.87640232670606, 29.709912146028785],
            [80.87595528450885, 29.70961758028716],
            [80.87258995036598, 29.707400084146627],
            [80.87188413904187, 29.707130958427058],
            [80.8708181384464, 29.70692239665135],
            [80.8690665765613, 29.706577771047364],
            [80.86854078882641, 29.706494365222852],
            [80.86734913765264, 29.706305334024023],
            [80.86689595038786, 29.70632520904121],
            [80.86638620206162, 29.706347272108985],
            [80.86609601332043, 29.705478458664004],
            [80.8659005753513, 29.70500458429416],
            [80.8652975754228, 29.70420764556644],
            [80.86527863749916, 29.704139896938727],
            [80.86509701311672, 29.703501397171806],
            [80.86508662504775, 29.702868582019505],
            [80.86507338792654, 29.702062145551622],
            [80.86475195134153, 29.700877521680354],
            [80.86421626386954, 29.7000875212222],
            [80.86390040308117, 29.699891152454768],
            [80.86317676269749, 29.69944127199716],
            [80.86247879975684, 29.698061802810003],
            [80.86240820207695, 29.69792227119592],
            [80.86212316555168, 29.697723586674215],
            [80.86058210258204, 29.696649389657637],
            [80.86049926333038, 29.696591646887043],
            [80.86010295099044, 29.696108459138145],
            [80.8600068890068, 29.69599108412126],
            [80.85972513860435, 29.69585314700521],
            [80.8591563884566, 29.695845771665063],
            [80.8584068404034, 29.69598678086527],
            [80.85832151272768, 29.696002833763828],
            [80.8569084691506, 29.695877310889216],
            [80.85657382692062, 29.69584758379898],
            [80.85649596811447, 29.695862300304952],
            [80.85519757550776, 29.696107709103558],
            [80.85355213902005, 29.695796834156624],
            [80.852682135772, 29.695359422799186],
            [80.85169057565957, 29.694860896213413],
            [80.85022913776453, 29.694387771878212],
            [80.84857540593032, 29.693852430645222],
            [80.84719407694513, 29.693405271536108],
            [80.84643763918575, 29.6925855835579],
            [80.84573263905008, 29.692129021137475],
            [80.84497326410491, 29.691322896734334],
            [80.8444217638534, 29.69039733426962],
            [80.843724522169, 29.68949887017675],
            [80.84210538815319, 29.687412459217683],
            [80.84169795209885, 29.68730139564292],
            [80.84139838882464, 29.687219833428628],
            [80.84090707570107, 29.68728014556234],
            [80.84036551385742, 29.687265958757052],
            [80.83964347516633, 29.68782186748524],
            [80.83805738853772, 29.689043020918746],
            [80.83683538884219, 29.68924902052595],
            [80.836618950804, 29.68928489628098],
            [80.83625582614553, 29.68936564730683],
            [80.83562001265352, 29.689504708575498],
            [80.83466120135341, 29.689645645829955],
            [80.8334912013579, 29.689820083729956],
            [80.8330705767458, 29.690081272033126],
            [80.83265613856997, 29.69053864564205],
            [80.83262895116525, 29.690705521142945],
            [80.83247188816716, 29.690811396528773],
            [80.83215751305886, 29.69125764552399],
            [80.83144563850544, 29.691516145753553],
            [80.8310165755546, 29.691443396895295],
            [80.83088820092979, 29.69134627101363],
            [80.83056495041217, 29.691102208500183],
            [80.830004763609, 29.690442708664136],
            [80.82974870054215, 29.689871772264553],
            [80.82953488852434, 29.68908195886536],
            [80.8287895771773, 29.687912458892924],
            [80.82816695044448, 29.68751914579201],
            [80.82774995099948, 29.687362958733615],
            [80.82749118816787, 29.68714968361104],
            [80.82703176230604, 29.686771020466267],
            [80.8270027007141, 29.686746147017118],
            [80.82678376345996, 29.68656764598012],
            [80.82612376360089, 29.686329958760666],
            [80.82541101400716, 29.686319959198784],
            [80.82507238867987, 29.686432333985067],
            [80.82502295204768, 29.68644808381208],
            [80.8246955754405, 29.68655814554205],
            [80.82446107002244, 29.68672966244401],
            [80.82441845115068, 29.686760833845483],
            [80.82428863850993, 29.68677414650972],
            [80.82332276303515, 29.686865396221094],
            [80.82284676356733, 29.687065583509934],
            [80.82279376292189, 29.687088021594946],
            [80.82217188892162, 29.68757664664497],
            [80.82168538807025, 29.687675333749723],
            [80.82135307598372, 29.687638708859367],
            [80.82077720050745, 29.68732889691114],
            [80.82010182583866, 29.687203833590104],
            [80.81824432561518, 29.68714045926481],
            [80.81759688838576, 29.68689595878152],
            [80.81634824897259, 29.68581328565466],
            [80.81615332541571, 29.685644271565934],
            [80.81509801236342, 29.68510364591657],
            [80.81415338876792, 29.68439177046389],
            [80.81365320203372, 29.68420833394748],
            [80.81342695059334, 29.684126833786422],
            [80.81294870192113, 29.68411933344055],
            [80.81292163862275, 29.684078709265066],
            [80.81253626383756, 29.684292457431013],
            [80.81161232554842, 29.68466339629873],
            [80.81154036539562, 29.684694046093398],
            [80.81049857544895, 29.685137770691597],
            [80.80931013845219, 29.685481209190527],
            [80.80904713801505, 29.685557521162707],
            [80.80854545031235, 29.685620636483236],
            [80.80821401416546, 29.685662333549885],
            [80.80778673457019, 29.685881941698312],
            [80.8067128271353, 29.686433896107474],
            [80.80626701341197, 29.68667008415713],
            [80.8056938260093, 29.686976020926693],
            [80.8050741373616, 29.687130770868407],
            [80.8046918886198, 29.687224958664956],
            [80.80399982613113, 29.68720364563177],
            [80.80353556911126, 29.687188269023466],
            [80.80307145058703, 29.687172896012385],
            [80.80223663870999, 29.687146895712715],
            [80.8018990134288, 29.6872433956662],
            [80.80109663919859, 29.68747452143208],
            [80.80089407590094, 29.687519333750288],
            [80.80044463790978, 29.687615645745495],
            [80.79999495170574, 29.687865395571578],
            [80.79989122480026, 29.687922272295054],
            [80.79982682614718, 29.687957584175194],
            [80.7991730127244, 29.68818445884574],
            [80.79848507542596, 29.688208209041647],
            [80.79794707579697, 29.688367584196726],
            [80.79769688800104, 29.688313020529506],
            [80.79750963745988, 29.688452645673124],
            [80.79707701679092, 29.688445939428618],
            [80.7968605140016, 29.688442584058066],
            [80.79552126249632, 29.688575895960867],
            [80.79497601343235, 29.689102895981932],
            [80.79460295036591, 29.689614397086814],
            [80.79409932552352, 29.68997683376392],
            [80.7927126374783, 29.691034833990443],
            [80.79147182576418, 29.69244727052518],
            [80.79110289238463, 29.693163252282318],
            [80.79067738895037, 29.693989020576907],
            [80.79040738908981, 29.694593145557292],
            [80.79000420053347, 29.69528277088324],
            [80.78957657559852, 29.695744521492543],
            [80.78911807603845, 29.695845333695218],
            [80.78878641146378, 29.695886876078532],
            [80.78757120075039, 29.696039083636833],
            [80.78705145106369, 29.696390146588044],
            [80.78665551374104, 29.696836145571694],
            [80.785318315388, 29.699329195785822],
            [80.78528582558044, 29.69938977052152],
            [80.78457107589452, 29.70014658419757],
            [80.78239470035521, 29.70179877189588],
            [80.7816132641409, 29.702126833786394],
            [80.781614264187, 29.70216508375165],
            [80.7801557005252, 29.702097334224618],
            [80.77990481575472, 29.702027277936565],
            [80.77850588883575, 29.7016366457147],
            [80.7776059507541, 29.701387083846896],
            [80.77688820092976, 29.701392584100518],
            [80.77684551370953, 29.70140827097498],
            [80.77667101375636, 29.701468896972074],
            [80.77629045034405, 29.701639708805544],
            [80.77506963775363, 29.702183521652273],
            [80.77434234432377, 29.702308911427167],
            [80.77357970034296, 29.702440395907615],
            [80.77342682638698, 29.702467209194424],
            [80.77314821461846, 29.70232469093179],
            [80.7725241390778, 29.70200545858512],
            [80.77178320113387, 29.701995209011727],
            [80.77107556868259, 29.7022311281641],
            [80.77070620093048, 29.70235427143251],
            [80.76982363775073, 29.702255708434222],
            [80.76915328039962, 29.702042478277804],
            [80.76887538808876, 29.701954083913904],
            [80.76737851330779, 29.701703271988436],
            [80.76631432664493, 29.701687896279452],
            [80.76584435263482, 29.702282055872217],
            [80.7657833255401, 29.70235920871056],
            [80.76464001382561, 29.702745145572067],
            [80.76415113876408, 29.70309164626252],
            [80.76356832681688, 29.70411702088387],
            [80.76154239256743, 29.704227502597234],
            [80.76096557550096, 29.704258959083745],
            [80.76031176387687, 29.70429389684591],
            [80.76012445038316, 29.704525896752784],
            [80.76008326413137, 29.704577708494526],
            [80.75912438897944, 29.705767020531596],
            [80.75868907574034, 29.706088771879365],
            [80.75847970547409, 29.70631452419599],
            [80.75801651235224, 29.706813959096962],
            [80.75657370211474, 29.70786483309564],
            [80.75635838912842, 29.707947834225195],
            [80.75628688852913, 29.707974771618467],
            [80.75600688910669, 29.707971958539133],
            [80.75571845044618, 29.70796695920785],
            [80.75482920080702, 29.707694646290918],
            [80.75403763912578, 29.70763445826367],
            [80.75395526392435, 29.707627583845976],
            [80.7533162011818, 29.707310583616618],
            [80.7530462624751, 29.70710764350298],
            [80.75265745048102, 29.70681533416041],
            [80.75212688734604, 29.706609020689825],
            [80.75161545099235, 29.706400583919844],
            [80.75093438901092, 29.70607220906527],
            [80.75053654602482, 29.705937950176292],
            [80.75002745150573, 29.70576614728992],
            [80.75000007614267, 29.705756896863363],
            [80.74984913842707, 29.705661834026444],
            [80.74976408684319, 29.70557995704945],
            [80.74960970112687, 29.705431334188745],
            [80.74923976410389, 29.705200520487665],
            [80.74823876380793, 29.7048354586895],
            [80.74780345146814, 29.70477820874737],
            [80.74731187754116, 29.704758957859667],
            [80.74725926360418, 29.70475689661356],
            [80.74716094432216, 29.704724817796148],
            [80.74708532572726, 29.70470014579513],
            [80.74695510749228, 29.70468640595294],
            [80.74688926272933, 29.704679458690123],
            [80.74685389059465, 29.704667517492],
            [80.74643232619098, 29.70452520877143],
            [80.746040264047, 29.704468083835025],
            [80.74595320157903, 29.704429520905705],
            [80.74553920047379, 29.704390395900077],
            [80.7452338878337, 29.704428146741634],
            [80.74431820082435, 29.70440833377762],
            [80.7442089511822, 29.704446458737152],
            [80.74370751349102, 29.70450414664907],
            [80.74351113752903, 29.704485708748507],
            [80.74320582578827, 29.70452352074392],
            [80.74300963778461, 29.704561458644434],
            [80.74292232620445, 29.704599646556517],
            [80.74235538819067, 29.7046368955763],
            [80.74222801900794, 29.704711241630548],
            [80.742159013128, 29.704751520466345],
            [80.74185363843475, 29.704807334191173],
            [80.74146613945084, 29.7048424994818],
            [80.74080682577443, 29.704902334075484],
            [80.7402832629623, 29.705018584040488],
            [80.7399996383727, 29.705036083948187],
            [80.73962888836257, 29.705132333890162],
            [80.73936720093565, 29.705149896750413],
            [80.73917063791464, 29.705226208722593],
            [80.73910526259783, 29.705284708722388],
            [80.73897438875707, 29.70530252159415],
            [80.73896821940787, 29.705307154002014],
            [80.73882163890755, 29.70541720853737],
            [80.73834138834445, 29.705628334280277],
            [80.73797007535876, 29.705819334095054],
            [80.73781745141429, 29.705895709019785],
            [80.73742501245442, 29.705874645998165],
            [80.73712001367772, 29.705817646966864],
            [80.7366188889506, 29.705606896241306],
            [80.73640095084329, 29.705568084199797],
            [80.73544270072011, 29.705146583647945],
            [80.7345057627308, 29.704860521895625],
            [80.73429420261539, 29.70476448149566],
            [80.7337869508072, 29.704534209186477],
            [80.7335693256639, 29.70440070842602],
            [80.7334607640031, 29.704285458507172],
            [80.7333735765294, 29.704264958461124],
            [80.73302501369346, 29.704036395763126],
            [80.73267657586337, 29.703902645890423],
            [80.73243701355739, 29.70384345880865],
            [80.73217545203556, 29.7038248959023],
            [80.73206620149404, 29.703863020861775],
            [80.73169563854293, 29.70388258381422],
            [80.7312374510476, 29.703958333710204],
            [80.73103835643508, 29.70395793531054],
            [80.73008207582717, 29.70395602065389],
            [80.72986407566663, 29.703919458716086],
            [80.72955888803233, 29.703918833687283],
            [80.72903582614254, 29.703841083699103],
            [80.7286870141944, 29.703840395717748],
            [80.72846913903959, 29.70378352169223],
            [80.7280111377039, 29.703764521715357],
            [80.72713938887176, 29.70362964589151],
            [80.72698713904526, 29.70357070792187],
            [80.72642045104305, 29.703495083931045],
            [80.72628761668022, 29.703436409462768],
            [80.7261588256693, 29.703379521947454],
            [80.72576670057282, 29.703263646100424],
            [80.72543995079315, 29.70320658411663],
            [80.7248732007377, 29.703167021141155],
            [80.72257241478519, 29.70317963413288],
            [80.721886075582, 29.703183395996973],
            [80.72079626363671, 29.703068271983227],
            [80.72055676338397, 29.70301139615907],
            [80.72035371535173, 29.702957038436637],
            [80.72005551275174, 29.7028772092159],
            [80.71978986650919, 29.70286602704556],
            [80.71909632553508, 29.702836834152606],
            [80.71881288890376, 29.702779834222042],
            [80.71794151418959, 29.70251189560753],
            [80.71748407582947, 29.7024160836354],
            [80.71717926231315, 29.70228008365899],
            [80.71711382674175, 29.702223583751447],
            [80.7167653259591, 29.7021078329102],
            [80.71656932681304, 29.70197427189521],
            [80.7164169510815, 29.701935583960108],
            [80.71617738877552, 29.70180195909319],
            [80.71604670109446, 29.70174533417986],
            [80.71569832711617, 29.70159120926695],
            [80.7154585759526, 29.70151395930185],
            [80.7153934506473, 29.701457458494986],
            [80.71517563844503, 29.70138027058306],
            [80.71463107556372, 29.701149020710773],
            [80.71450057584099, 29.701053895820678],
            [80.71423920137812, 29.7009586459248],
            [80.71397801397421, 29.700804708970168],
            [80.71389107651203, 29.70070977113909],
            [80.71358632594826, 29.700537709247953],
            [80.71348602815772, 29.700419759564284],
            [80.71345563736782, 29.700384020506192],
            [80.7130859503564, 29.70011702168324],
            [80.71295520062205, 29.700078333748138],
            [80.71282470089926, 29.699981145813297],
            [80.71254145132696, 29.699867708927684],
            [80.71225820085533, 29.69973177100445],
            [80.71184463861925, 29.699559396149255],
            [80.7114740127156, 29.699502209159675],
            [80.7107768879431, 29.699311145493027],
            [80.71036270067816, 29.699251645447134],
            [80.70999220067955, 29.699253083463077],
            [80.7096868259863, 29.699308834235353],
            [80.70938120038215, 29.6994435212016],
            [80.70916307611515, 29.69949939697966],
            [80.70896657604669, 29.69963433395742],
            [80.70881338912665, 29.699787396771],
            [80.7087045756557, 29.69980520874344],
            [80.70870413768586, 29.69992020865078],
            [80.7085490693841, 29.700074148303372],
            [80.70822345095155, 29.70039739612298],
            [80.70793970135617, 29.700568208855827],
            [80.7075856193809, 29.700665727741182],
            [80.70752532703227, 29.700682333722796],
            [80.7069580140012, 29.700949520504025],
            [80.70669607566344, 29.701158771160408],
            [80.70632470152384, 29.70144664594602],
            [80.70612820055607, 29.70163795872486],
            [80.70593132547037, 29.701903709288786],
            [80.7058215128526, 29.70224858400502],
            [80.70581574639965, 29.702368151568976],
            [80.70579845063804, 29.702726771523317],
            [80.705535576106, 29.70326077096786],
            [80.70547013783664, 29.703337395904157],
            [80.70533882602604, 29.703490458717738],
            [80.7051640760613, 29.703605146560335],
            [80.70507682563505, 29.703622958532776],
            [80.70496770099868, 29.703719708497772],
            [80.70448763749454, 29.703966772048943],
            [80.70440038886693, 29.703986897077698],
            [80.70433495059757, 29.70404314697373],
            [80.70411670042546, 29.704119333940184],
            [80.7039859506911, 29.70421383380142],
            [80.70383313878835, 29.704233770871895],
            [80.70363670077319, 29.704348395761883],
            [80.70333163904394, 29.704424396568697],
            [80.7030695757004, 29.704538833500408],
            [80.70267682647443, 29.70476808417982],
            [80.70239326393806, 29.704844146140488],
            [80.7019355135132, 29.70497620798568],
            [80.70185789752401, 29.704986061857312],
            [80.70149932613305, 29.705031583740663],
            [80.701281388925, 29.705107771606436],
            [80.70106332671122, 29.705109583740352],
            [80.70086707575501, 29.705183583555595],
            [80.70040913737188, 29.70525920844574],
            [80.70023482537692, 29.705335522216615],
            [80.6997115125763, 29.705449397072073],
            [80.69938426367287, 29.705583958133218],
            [80.69912251239407, 29.70562177102795],
            [80.69859907548698, 29.705773957901897],
            [80.69849026381468, 29.705773708789707],
            [80.69825026353891, 29.70586789568688],
            [80.69822403301276, 29.705874586642892],
            [80.69735613867493, 29.706095959261177],
            [80.6960048884149, 29.706131209088085],
            [80.69532970080513, 29.70605520918059],
            [80.69515551381596, 29.705996147104543],
            [80.69508155177226, 29.70598667814272],
            [80.69485038913422, 29.705957084152146],
            [80.69443657598725, 29.70584333430247],
            [80.69421920085551, 29.70574583430289],
            [80.69404482680739, 29.705727397301587],
            [80.69389257608157, 29.70566839727877],
            [80.69360932560994, 29.705649647313408],
            [80.69334795114708, 29.705572395549666],
            [80.69315188904852, 29.70557195847914],
            [80.69265082637457, 29.705476020601907],
            [80.6921060134818, 29.70545670856029],
            [80.69191007548966, 29.705397583531692],
            [80.69180126381735, 29.705417708560446],
            [80.69156176266529, 29.705340395642793],
            [80.69077757542482, 29.705205520718266],
            [80.6897547009188, 29.704763208955],
            [80.6890817058561, 29.70463822387501],
            [80.68870907716223, 29.70456902194286],
            [80.68799107552775, 29.704244770479818],
            [80.68781676263353, 29.704206020491483],
            [80.68772970196426, 29.704167458461484],
            [80.6875991383896, 29.704090458507892],
            [80.68731613792949, 29.703995021553055],
            [80.68703363749245, 29.703743958716757],
            [80.68692482582009, 29.70363095890167],
            [80.68677282600515, 29.703477209006053],
            [80.68672945080357, 29.703380084023706],
            [80.68655563883175, 29.70319020926081],
            [80.68651257569485, 29.703036708477384],
            [80.68642588914355, 29.702865020704166],
            [80.68623400259719, 29.70245682652137],
            [80.68620932699889, 29.702404333992888],
            [80.68616645092101, 29.702156146289155],
            [80.68603638916807, 29.70192570850469],
            [80.68597113885704, 29.70181052063907],
            [80.68577626296423, 29.70144689595753],
            [80.68568982552506, 29.70119852119484],
            [80.6854729504164, 29.700816770677477],
            [80.68545145032425, 29.700681395729873],
            [80.68536476287358, 29.700509771808584],
            [80.68508251244805, 29.700184272086517],
            [80.684843326958, 29.70003027128007],
            [80.68458251367213, 29.699781520600766],
            [80.68449545120416, 29.69972489568744],
            [80.68432113830994, 29.699686083645872],
            [80.68423463791828, 29.699476145907454],
            [80.68414751339708, 29.699437583877454],
            [80.68384395083774, 29.698920334306024],
            [80.68373595035388, 29.698633583672972],
            [80.6836272007348, 29.698518270801628],
            [80.68354063918923, 29.698287958922208],
            [80.68330270105889, 29.69775277057397],
            [80.68308588890272, 29.697371020955927],
            [80.68291226398986, 29.69712020903046],
            [80.68284695072623, 29.697043396135882],
            [80.68265151365648, 29.69683308427949],
            [80.6824128884441, 29.696431020775037],
            [80.68239151335769, 29.696336270902236],
            [80.6821742011785, 29.696125896992612],
            [80.6817838261627, 29.69547527076719],
            [80.68147957562195, 29.6951677709759],
            [80.68137120102017, 29.69501414608601],
            [80.68121920210456, 29.6948987091082],
            [80.68111045158611, 29.694747333422754],
            [80.68104538833404, 29.694688522257536],
            [80.68078940890416, 29.69449565185289],
            [80.68058882591362, 29.694344520783034],
            [80.6804582011851, 29.694382583689332],
            [80.68048063747148, 29.69424727079496],
            [80.68039395092018, 29.694170395847152],
            [80.68024170109368, 29.69409558394409],
            [80.67970038836228, 29.693616020463026],
            [80.67909357530829, 29.693098021756327],
            [80.6789852007065, 29.693039083786744],
            [80.67889863916093, 29.692944146854927],
            [80.6783567634539, 29.692520959175],
            [80.67826970188531, 29.692482397144943],
            [80.67820513775695, 29.692367209279325],
            [80.67809657609615, 29.692290272278285],
            [80.67779457565922, 29.69179320888952],
            [80.67760020068874, 29.691467959179022],
            [80.6775139503086, 29.691276020472003],
            [80.67740607662921, 29.691083958557897],
            [80.67725607690647, 29.69060539602225],
            [80.67704026389714, 29.690221397199764],
            [80.67701895176327, 29.690126582575772],
            [80.6769538885112, 29.690070020614996],
            [80.67695451354001, 29.689973020638433],
            [80.67684657600876, 29.689801333764592],
            [80.67678251370211, 29.689532771020595],
            [80.67682651393255, 29.689399771182536],
            [80.67680513884613, 29.68932302213983],
            [80.67671845139546, 29.689264146223422],
            [80.6766988875437, 29.688806145787055],
            [80.67661276396797, 29.688575896860186],
            [80.67654957580231, 29.688156147288396],
            [80.67651993594632, 29.688071301649302],
            [80.6764423253531, 29.687849145721543],
            [80.67635588881325, 29.687733833749462],
            [80.67633463873267, 29.68760070890562],
            [80.67616170090184, 29.68734989608089],
            [80.67603188916041, 29.687236771260018],
            [80.67568457548282, 29.687082522240644],
            [80.67529351338499, 29.686966522287207],
            [80.67453270042387, 29.686867645425536],
            [80.67438007558007, 29.686905646278603],
            [80.67403226277878, 29.686925084225322],
            [80.67377070035758, 29.68700114708531],
            [80.67366188778595, 29.68700089617448],
            [80.67341923271147, 29.687118880032358],
            [80.67326957563046, 29.687191645977748],
            [80.67309545069457, 29.687191208907223],
            [80.67300845117916, 29.687229396819248],
            [80.67261657609419, 29.68728477077559],
            [80.67239920096239, 29.68728420869934],
            [80.67198688878392, 29.6870734588731],
            [80.67159582578677, 29.686957395967113],
            [80.67135720057439, 29.68684177103097],
            [80.6711183253505, 29.68670808411082],
            [80.67085807593952, 29.686610459105452],
            [80.67059776357598, 29.686418084227284],
            [80.67046738885898, 29.686381645496567],
            [80.6703371391477, 29.686304645543032],
            [80.67001195059112, 29.68611208360585],
            [80.66994695119092, 29.686035208657984],
            [80.66964338863153, 29.685842708774032],
            [80.66925282565745, 29.685670270966284],
            [80.66857907606357, 29.68551520895977],
            [80.66838338898225, 29.685514708936694],
            [80.66810026351641, 29.685570395857155],
            [80.66779557590519, 29.68562833378064],
            [80.66764326402546, 29.68568433276579],
            [80.66701238871082, 29.68566470865943],
            [80.666382325484, 29.6854330208173],
            [80.6662522007785, 29.685337958879757],
            [80.66599120043361, 29.6852425210256],
            [80.6656005754063, 29.685031771199306],
            [80.66523126341212, 29.68478270845526],
            [80.66510126371242, 29.684649270647355],
            [80.66490601370162, 29.684513458629283],
            [80.66481888738178, 29.684495146633765],
            [80.66473220083049, 29.68443858377367],
            [80.66447188756763, 29.68424614684227],
            [80.66418945098246, 29.684112334017072],
            [80.6640808254698, 29.68405339604743],
            [80.66397220085645, 29.68399677203348],
            [80.66373345063829, 29.68382470924297],
            [80.66362495103073, 29.68372970845934],
            [80.66356057576002, 29.683555833534967],
            [80.663387388817, 29.68330727081394],
            [80.66326160064239, 29.68320785345975],
            [80.66297507663853, 29.682981396973958],
            [80.66286688909571, 29.682886334137038],
            [80.66271476337567, 29.68280927213027],
            [80.66260632672066, 29.68269395925887],
            [80.66225876303156, 29.68252170851008],
            [80.6615848254794, 29.682330395731242],
            [80.66080126236841, 29.68227202073723],
            [80.66045688947395, 29.682298663152835],
            [80.65955957561397, 29.68236808362019],
            [80.65942882587967, 29.682329396584464],
            [80.65934163840592, 29.682347208556905],
            [80.65853645109638, 29.682291020714104],
            [80.65812307591926, 29.682192958638154],
            [80.65790576374008, 29.6820976466891],
            [80.65766707557515, 29.681925521845415],
            [80.65749173205785, 29.681821838107396],
            [80.65740663910515, 29.68177152103891],
            [80.65723282713333, 29.681696646183298],
            [80.65692801271769, 29.681675521108446],
            [80.65684076409002, 29.681713646967296],
            [80.65682026674199, 29.681713860106584],
            [80.65666638914263, 29.681715459101213],
            [80.65647013818636, 29.681771333979952],
            [80.65590401315973, 29.681790208951043],
            [80.65559932554851, 29.681733021062144],
            [80.65542526356512, 29.681732521039066],
            [80.65536026236629, 29.68167595907829],
            [80.65453332585236, 29.68155877112042],
            [80.65442463738714, 29.68150208415392],
            [80.65316351358535, 29.68115595848076],
            [80.65264057580197, 29.68119514553956],
            [80.65222657559605, 29.681288834212353],
            [80.65194238893014, 29.68148208413095],
            [80.65159320106545, 29.681596208997917],
            [80.65104838907195, 29.681615083969007],
            [80.65085251313303, 29.68157620897489],
            [80.6504219519116, 29.68156134498014],
            [80.65028582603009, 29.681556646022443],
            [80.65006763881053, 29.68161245884795],
            [80.64987132580109, 29.681611958824874],
            [80.64945657556058, 29.681822895710127],
            [80.64915830281348, 29.682061286199428],
            [80.6486913891963, 29.682434458983153],
            [80.64848783844303, 29.682567734913107],
            [80.64834145039765, 29.68266358375746],
            [80.64805682576196, 29.68298995851984],
            [80.64788126281007, 29.683314271136737],
            [80.64783688846171, 29.683544271850735],
            [80.64777101402115, 29.683638896717753],
            [80.64772551372153, 29.684098895447733],
            [80.64774670084961, 29.68430877113292],
            [80.64770207559036, 29.684518458859827],
            [80.64763613909662, 29.684710020750913],
            [80.64750451252331, 29.684883333599032],
            [80.64732957549955, 29.685015959319117],
            [80.64669707600933, 29.68516770912254],
            [80.64656626332243, 29.685167334105245],
            [80.64645688867455, 29.685225709099257],
            [80.64571507569025, 29.68541545885637],
            [80.64508157615387, 29.685761145660422],
            [80.64486288891123, 29.685952333433477],
            [80.64486251389394, 29.686065084136374],
            [80.64477482549785, 29.686200209072467],
            [80.64464345073475, 29.686371271816768],
            [80.64457751244231, 29.686486145819003],
            [80.64444588856696, 29.686715895622115],
            [80.6440297021091, 29.687231333958948],
            [80.64372326351787, 29.687498958710023],
            [80.64357057572153, 29.68757527068226],
            [80.64330776414204, 29.68782495845511],
            [80.64308932601165, 29.687957459169468],
            [80.64287063876901, 29.688148583990028],
            [80.64278326333698, 29.68820702193659],
            [80.64260845041969, 29.68832158387403],
            [80.6423680130734, 29.688454020736515],
            [80.64206188744623, 29.68872164548759],
            [80.64197457586602, 29.68875977224576],
            [80.64193063768886, 29.68885664631722],
            [80.641712075452, 29.68900945911929],
            [80.64155895058514, 29.689124021056784],
            [80.64105645079462, 29.689391145784782],
            [80.64085982572044, 29.689544020640085],
            [80.64064157554833, 29.68962002054758],
            [80.64058543806755, 29.689670022853306],
            [80.64057582611355, 29.68967858349987],
            [80.64022682530782, 29.689754334295117],
            [80.64015335609258, 29.68975919243286],
            [80.63996507672698, 29.689771646244537],
            [80.63985649348245, 29.689820456948553],
            [80.63979045086865, 29.689850146267304],
            [80.63975559854208, 29.689841278951917],
            [80.63963813898897, 29.68981139627897],
            [80.63883101364041, 29.689906209104322],
            [80.63854738905081, 29.69002052103025],
            [80.63826370060934, 29.690058021860295],
            [80.6377399507382, 29.690209958722676],
            [80.63721657588434, 29.690267208664807],
            [80.63675851339474, 29.690362958583705],
            [80.63619170218539, 29.69039977143234],
            [80.63558088894695, 29.69051533431525],
            [80.6352756383601, 29.69053258421144],
            [80.63516663872946, 29.69057064621842],
            [80.63449076313827, 29.690571022135032],
            [80.63414270032541, 29.690436959298268],
            [80.63390326392454, 29.690303208526302],
            [80.63318526408875, 29.690053083682926],
            [80.63309826367401, 29.6899964587696],
            [80.63292401373229, 29.68995764582877],
            [80.63248876344574, 29.689746646890285],
            [80.63231495057454, 29.689592709036333],
            [80.6322062630087, 29.689554084053782],
            [80.63209782545437, 29.6894387720817],
            [80.63190220042628, 29.68932539724932],
            [80.63148863819015, 29.689114457666165],
            [80.6313147641651, 29.689055333536885],
            [80.63111888732686, 29.688921708669966],
            [80.63068370089212, 29.68878739672101],
            [80.63061838852786, 29.688730833860973],
            [80.63046607574887, 29.68869208387264],
            [80.63027045072079, 29.688576458936495],
            [80.62990020073374, 29.688442334046556],
            [80.62981388740104, 29.688288709156666],
            [80.62937901393042, 29.68800102053075],
            [80.62859526376047, 29.68780933363388],
            [80.62848607617155, 29.687809020669818],
            [80.62811613734993, 29.687674895779878],
            [80.62792063912627, 29.68754127181228],
            [80.62765945172237, 29.687425458917858],
            [80.62731101389221, 29.687329771951454],
            [80.62683257546303, 29.687118647107923],
            [80.6263318257532, 29.68698414720069],
            [80.62604851412766, 29.68692689545992],
            [80.62565688905426, 29.68681302060446],
            [80.6252860769909, 29.686830020489083],
            [80.62492432559719, 29.68702077209099],
            [80.62476151233398, 29.687018021064887],
            [80.62414782666093, 29.687138084155322],
            [80.62335482606443, 29.687447146068962],
            [80.62317738802608, 29.687557145745757],
            [80.62291313753133, 29.6876669592628],
            [80.62256138749808, 29.687771958708993],
            [80.6218589504303, 29.68794820874291],
            [80.62107088891042, 29.688065458753954],
            [80.62072207606303, 29.68805770929589],
            [80.62046201281169, 29.68799608415202],
            [80.62011401295138, 29.687970271810627],
            [80.6195885759521, 29.688034147058318],
            [80.61836757540334, 29.688028333840577],
            [80.6181071389334, 29.687984771579977],
            [80.61645245111976, 29.68787402186865],
            [80.61619045162809, 29.687886770657997],
            [80.61575151412114, 29.687993834048257],
            [80.61568246867103, 29.687992190986904],
            [80.61531557585317, 29.687983458569818],
            [80.61507445052553, 29.688034646182018],
            [80.61498763896844, 29.688014084082738],
            [80.61446332612167, 29.688059958500332],
            [80.6135918255024, 29.688023583621543],
            [80.61333176315043, 29.68796189552512],
            [80.61238213752569, 29.687577957856547],
            [80.6114333257874, 29.687157834166783],
            [80.61119751365436, 29.687001459150054],
            [80.61102470082932, 29.686940084017692],
            [80.61052820041618, 29.686740083787868],
            [80.61035482551483, 29.686696708586283],
            [80.60983213864233, 29.68666814611811],
            [80.60972554739618, 29.6866503512328],
            [80.60939188812517, 29.686594645426624],
            [80.60878851317938, 29.686550021066694],
            [80.60826276411535, 29.686634147248128],
            [80.60773388900782, 29.686851395575502],
            [80.6069601383997, 29.687255147107408],
            [80.60628720089369, 29.68798402064607],
            [80.606215889152, 29.688211647149842],
            [80.60614845168976, 29.688285959029827],
            [80.60590695044544, 29.689196520802852],
            [80.60574426398665, 29.689575022069675],
            [80.60544507572973, 29.690180896231425],
            [80.60510463916785, 29.6906897092627],
            [80.60479107614731, 29.69100683359852],
            [80.60450176334575, 29.691229271014095],
            [80.60416970037141, 29.691415520609837],
            [80.60381751416702, 29.691540770989832],
            [80.60302670072173, 29.691754958924264],
            [80.60245995066623, 29.691744272280403],
            [80.60189707578945, 29.69158014690629],
            [80.60111688873349, 29.69139289546581],
            [80.6002792008245, 29.690898521949293],
            [80.59967151362952, 29.69077264564112],
            [80.59949901376854, 29.690693145572254],
            [80.59923901346974, 29.69062920917071],
            [80.59880338906521, 29.690603083865255],
            [80.59819157578067, 29.690648584164876],
            [80.5976683888851, 29.6906380207281],
            [80.59670632563649, 29.690732083518867],
            [80.59613988854505, 29.690703271938503],
            [80.59579113775084, 29.690695458628568],
            [80.59500188732727, 29.690850958604926],
            [80.59447870043175, 29.690842584118002],
            [80.5937842007803, 29.69071414564138],
            [80.59326032590337, 29.69072152098147],
            [80.59273951411734, 29.690616146517982],
            [80.59208863877973, 29.690487833946406],
            [80.59165651363725, 29.690328583797168],
            [80.59062207564835, 29.689849459185268],
            [80.59023620084008, 29.689593334065194],
            [80.5900637639316, 29.689513896049505],
            [80.5894637623428, 29.68910145796599],
            [80.58937732580301, 29.68908089586671],
            [80.58886188746618, 29.688763458566825],
            [80.58825807634918, 29.688504459213448],
            [80.58765270041187, 29.688301833862567],
            [80.58652157541042, 29.688183208788132],
            [80.58573988738596, 29.68805445914603],
            [80.5846944506884, 29.687994834094354],
            [80.58347113888203, 29.688065396700722],
            [80.5833843884788, 29.68804483370218],
            [80.58268307646284, 29.68816445882277],
            [80.58207276234816, 29.68815127116426],
            [80.58181201381342, 29.68810758389793],
            [80.58154970045837, 29.688120333586596],
            [80.58076776332166, 29.68798920883495],
            [80.58067945079608, 29.688025021637486],
            [80.58048157566424, 29.688078522306],
            [80.57967420120349, 29.68808045854638],
            [80.57914988745739, 29.688108145974184],
            [80.5786298886585, 29.68796214553663],
            [80.57822176282417, 29.68772395919342],
            [80.57754388804005, 29.68694583428288],
            [80.57715851415418, 29.68667164627948],
            [80.57698382534335, 29.686666583995702],
            [80.57671876276078, 29.6867943965442],
            [80.57638551358065, 29.687016583948264],
            [80.57620657547312, 29.687185145677972],
            [80.57596276297119, 29.68733327211288],
            [80.57569813745914, 29.687440708721795],
            [80.57543501381491, 29.687494020532654],
            [80.57532010833637, 29.68749069124243],
            [80.5752603259034, 29.687488959148197],
            [80.57521285608851, 29.687471289268615],
            [80.57493626419728, 29.687368333981453],
            [80.5745721385934, 29.68711227091461],
            [80.57434138874413, 29.686764146048574],
            [80.57411645031397, 29.686185959314628],
            [80.57401807707265, 29.68578408376851],
            [80.5734988265097, 29.684778522111174],
            [80.57330745077832, 29.68458395918242],
            [80.57281557557843, 29.68421245913771],
            [80.57219876296261, 29.683605896994607],
            [80.57207226342427, 29.6834318341119],
            [80.57175282603214, 29.683139771183562],
            [80.57154464377032, 29.68264298028936],
            [80.5714858883631, 29.68250277058644],
            [80.57132545110875, 29.68196539598631],
            [80.57126520102827, 29.68177345907793],
            [80.57130202107146, 29.68162062379281],
            [80.57133851286216, 29.68146914558463],
            [80.57140988845566, 29.681241583832104],
            [80.57146576243503, 29.680783834306567],
            [80.57130526402676, 29.680246458807062],
            [80.57124163879064, 29.680169520906702],
            [80.57102595168635, 29.67924845864951],
            [80.57056238804381, 29.678628895906854],
            [80.56983713877298, 29.678001708711918],
            [80.56955595044684, 29.677899270534965],
            [80.56936038747199, 29.67787608331463],
            [80.56914222903072, 29.67780716646763],
            [80.56897082544327, 29.677753021884484],
            [80.56890682608923, 29.677694146867452],
            [80.5685183891124, 29.67753495787207],
            [80.56813188747668, 29.677296833582034],
            [80.56794020057987, 29.677120270584055],
            [80.5678271953688, 29.676984408203907],
            [80.56766551345277, 29.67679002154233],
            [80.56753751384525, 29.676672333561385],
            [80.56740826238143, 29.676613271485337],
            [80.56715257613052, 29.67637783347027],
            [80.56695932531267, 29.67625989547787],
            [80.56682895059566, 29.676239147218894],
            [80.56650607599465, 29.676077959030636],
            [80.56599232658465, 29.67570408387644],
            [80.56515457572317, 29.675209520602948],
            [80.56483407713102, 29.67495358344115],
            [80.56386870077995, 29.67432333405469],
            [80.56341701358434, 29.674084958853825],
            [80.56276988841967, 29.673803145498823],
            [80.5621268875459, 29.673370209167842],
            [80.56172076270309, 29.673055334036462],
            [80.56152876374153, 29.672878646932077],
            [80.56140413839034, 29.67264814619506],
            [80.56142620055874, 29.671809084122003],
            [80.5613868255416, 29.671655584237897],
            [80.56083688831183, 29.6709927721987],
            [80.56064520051564, 29.670818459304485],
            [80.56055888898157, 29.670777584218172],
            [80.56012488875268, 29.670692645948918],
            [80.55953638859353, 29.670679459189728],
            [80.55940638889376, 29.670638396145137],
            [80.55905932702638, 29.670574083826978],
            [80.55872995212565, 29.67064289545334],
            [80.55833345182742, 29.670788208808858],
            [80.55809408917094, 29.670958660913527],
            [80.55786670188684, 29.671120583847937],
            [80.55726926246655, 29.671432083823674],
            [80.55674238835059, 29.67155439611855],
            [80.55628457587255, 29.671543833581097],
            [80.556089387915, 29.67150258347749],
            [80.55563357552916, 29.671415333950563],
            [80.55550357672877, 29.671374270905915],
            [80.55518113919828, 29.67119727083741],
            [80.55498957550856, 29.6710206457862],
            [80.55454563777101, 29.67047552082869],
            [80.55427651295076, 29.669935521107448],
            [80.55415770081731, 29.669474895550024],
            [80.55403513851087, 29.669167708722853],
            [80.55384345071474, 29.668991146624194],
            [80.55342282610258, 29.668389646764865],
            [80.55290870077596, 29.66803602074583],
            [80.5527791390461, 29.667995020653734],
            [80.55251726276151, 29.667989584251984],
            [80.55172851236102, 29.668124522129062],
            [80.55127038871751, 29.66811620789673],
            [80.55101045137127, 29.668052208542633],
            [80.55029195061309, 29.667997833733068],
            [80.54996757594296, 29.667895270550332],
            [80.54970920071912, 29.667756770458652],
            [80.54938838736422, 29.667521084230714],
            [80.54883570090692, 29.66697558425585],
            [80.54849376337654, 29.666699209101296],
            [80.54817251385049, 29.6664837711092],
            [80.54791376360936, 29.66636333390079],
            [80.54765338919259, 29.66631958368191],
            [80.54726082612626, 29.66631152215905],
            [80.54660363844721, 29.666410770440734],
            [80.54620670107846, 29.666574083727028],
            [80.54594332562402, 29.66662508428027],
            [80.54537713764483, 29.666593770785937],
            [80.54492432539735, 29.666393770556112],
            [80.54473007633203, 29.666311771271296],
            [80.54445395118893, 29.66604020928827],
            [80.54415432586148, 29.665822646198194],
            [80.5435817625883, 29.665200334228075],
            [80.54326159944333, 29.664815964884895],
            [80.54309557560038, 29.664616647240507],
            [80.54271307594774, 29.664245396308047],
            [80.54234945036688, 29.66396895910026],
            [80.54187588896116, 29.66373045889361],
            [80.54122676370423, 29.663545458456213],
            [80.54070576395992, 29.66346020902148],
            [80.54044426359195, 29.66345477082109],
            [80.54024645051334, 29.663508270590285],
            [80.53983057612027, 29.66368603867977],
            [80.5395188881862, 29.663819270542945],
            [80.5393214510242, 29.663854709227508],
            [80.53893301404742, 29.66371345900899],
            [80.53854895047374, 29.66340083398137],
            [80.53725820030576, 29.66274664644061],
            [80.53635776399966, 29.662175084112903],
            [80.53620553845491, 29.662045655482643],
            [80.5360380756967, 29.661903272118366],
            [80.53503163899899, 29.661216208061603],
            [80.53454001291135, 29.660842333806727],
            [80.53390070105655, 29.660296333808787],
            [80.53306938844156, 29.659594208805743],
            [80.53260251349525, 29.65910758384797],
            [80.53196476376291, 29.65850302089774],
            [80.53190157559732, 29.6584260838967],
            [80.53136676316564, 29.65803402085345],
            [80.53046751306533, 29.65742620865268],
            [80.53008432543135, 29.65707520865402],
            [80.5296358883856, 29.656721645587538],
            [80.52937826409567, 29.656583208448353],
            [80.52912188806476, 29.656388333454856],
            [80.52873401406356, 29.656208709164673],
            [80.52860595060417, 29.656111272117585],
            [80.52789451402066, 29.65581095790941],
            [80.5273138253728, 29.65551339562677],
            [80.5266247639218, 29.655195146239066],
            [80.5264318890205, 29.655077146193378],
            [80.52578570094937, 29.65477933389917],
            [80.52533532586466, 29.65450252077477],
            [80.52462976365274, 29.65399239653192],
            [80.5243073890748, 29.653815333510863],
            [80.52391913735835, 29.653656021308393],
            [80.52346526301153, 29.65351227187392],
            [80.5227475761398, 29.65344202043292],
            [80.52269024705731, 29.65344920781473],
            [80.52248532663657, 29.65347489694898],
            [80.52195726361686, 29.653655771296883],
            [80.52156345049292, 29.653706208874496],
            [80.52103976357427, 29.653715584306838],
            [80.52077988918057, 29.65365377120463],
            [80.52032757605684, 29.65345358391579],
            [80.51985682593181, 29.653120270883846],
            [80.5197504505229, 29.65302289678931],
            [80.5196223879629, 29.6529254588429],
            [80.51883676270648, 29.652146645951007],
            [80.51798363883393, 29.651444208883163],
            [80.51759738900836, 29.65120595868808],
            [80.51721301336994, 29.650913583695],
            [80.51663238857395, 29.650615958459753],
            [80.51559507635176, 29.650289583697372],
            [80.51539920131216, 29.65026633352454],
            [80.51514007605374, 29.65018414628139],
            [80.5148817008299, 29.65006364612043],
            [80.51455745206482, 29.64996095883123],
            [80.51423551275877, 29.649781582753917],
            [80.51385195100681, 29.649448521532122],
            [80.51347857587564, 29.648750146701957],
            [80.51326895110128, 29.648440333854353],
            [80.51311798370801, 29.648227502996917],
            [80.51278607541713, 29.647759583937727],
            [80.51242607590274, 29.64737027102126],
            [80.51221251389643, 29.647211583847593],
            [80.51178145085333, 29.647031771599018],
            [80.51145913832863, 29.646852395521705],
            [80.5104887005931, 29.64646758371208],
            [80.50978126419403, 29.64603189545568],
            [80.50888420214426, 29.6453608339354],
            [80.50862670106147, 29.64520427096039],
            [80.50820038913673, 29.64485077084646],
            [80.5080723886299, 29.644751021642378],
            [80.50786238883825, 29.6444592716781],
            [80.50734463834442, 29.64345352206243],
            [80.50695451334019, 29.641760209266295],
            [80.50681363813896, 29.64053258431136],
            [80.50684574573461, 29.639941647888804],
            [80.50690088766589, 29.638926771953038],
            [80.50692482582014, 29.63885014611742],
            [80.50687070102208, 29.638428145542548],
            [80.50679182598202, 29.638121083721103],
            [80.50636982540715, 29.63761420872879],
            [80.50571120061147, 29.63698689562881],
            [80.50509182582715, 29.63651539726783],
            [80.5049238261737, 29.636282397314858],
            [80.50465532548293, 29.635740020685432],
            [80.50446457567966, 29.63554527159704],
            [80.50419101360444, 29.635176583732402],
            [80.5031265130782, 29.634254520529737],
            [80.50284701277945, 29.6341135841746],
            [80.5025228260676, 29.634010895986137],
            [80.50232620099342, 29.63402595873106],
            [80.50174526233405, 29.633746272272674],
            [80.50100270201312, 29.63300358424806],
            [80.50034882563784, 29.632204834285744],
            [80.50018282697596, 29.631895146443924],
            [80.50000445094469, 29.631547083631062],
            [80.49959882612501, 29.630419958814116],
            [80.4994072633346, 29.630074084051785],
            [80.49938820040518, 29.629864209265918],
            [80.49926126289705, 29.629556958586818],
            [80.49913776349706, 29.629019646039865],
            [80.49914182573474, 29.62871508343443],
            [80.49918745104009, 29.628561896514384],
            [80.49916682598831, 29.62848508272043],
            [80.49926557604556, 29.627646333611438],
            [80.4992157005442, 29.626500208817617],
            [80.49924038783371, 29.626272459106758],
            [80.49913838852586, 29.62575552070075],
            [80.4990762624596, 29.625525208821387],
            [80.49870651339495, 29.625176396873258],
            [80.49886801364795, 29.624890522179896],
            [80.49886443614486, 29.624885064194416],
            [80.49880426250678, 29.624793271292504],
            [80.4987963169965, 29.624788718924265],
            [80.4985664511808, 29.62465702130453],
            [80.49804513847243, 29.62453552109747],
            [80.49735107589152, 29.624314083728052],
            [80.49683063732414, 29.624154208549953],
            [80.49630845047466, 29.624091334247737],
            [80.49569788904643, 29.624082207927586],
            [80.49474132605144, 29.62391620746706],
            [80.49352013754435, 29.62392052061557],
            [80.4924761388628, 29.62377214596779],
            [80.4919529510679, 29.623765645668072],
            [80.4910833255351, 29.62362020910541],
            [80.49047276230817, 29.6236133337884],
            [80.48951307596775, 29.623636708967013],
            [80.48933845100879, 29.623654021815696],
            [80.48907576263639, 29.623709520777822],
            [80.48863963730946, 29.623721334272204],
            [80.48785670102598, 29.623596520962735],
            [80.48724651371572, 29.623569333557953],
            [80.48672570103031, 29.62342970931371],
            [80.48602920038735, 29.623361645923296],
            [80.48559326391808, 29.62335539563503],
            [80.4853320126623, 29.62331377051413],
            [80.48506976315912, 29.62334889623463],
            [80.4848088266661, 29.623327584100764],
            [80.48419726339307, 29.62337708368551],
            [80.48376170104171, 29.623350584262084],
            [80.48350088865516, 29.623290896257913],
            [80.48315238877177, 29.623285020986998],
            [80.48305090567482, 29.623269350300347],
            [80.48247807620231, 29.623180895681855],
            [80.48236982570694, 29.62314208364029],
            [80.48226076402312, 29.623139458519233],
            [80.48121407546927, 29.62314433284473],
            [80.48086657563204, 29.6230640836406],
            [80.48069338868902, 29.6229843956134],
            [80.4799983890145, 29.622823771300034],
            [80.47965170036576, 29.622702896121837],
            [80.47932763865964, 29.622527897044847],
            [80.47906738745002, 29.622427646019105],
            [80.47865757538688, 29.62215533400149],
            [80.4783353878679, 29.621863021061642],
            [80.47790445162917, 29.621572647060077],
            [80.47721051315472, 29.621333020902227],
            [80.47677451283357, 29.62132683356657],
            [80.47642463878611, 29.62139989628821],
            [80.47589688873046, 29.62165945861716],
            [80.47554613874337, 29.621768583253584],
            [80.47467363897732, 29.621796770704464],
            [80.47441276363821, 29.62175508353033],
            [80.47380213745873, 29.621766209043358],
            [80.47336707602977, 29.621701333749627],
            [80.47295983872561, 29.621606665715092],
            [80.4724983884899, 29.621499395480782],
            [80.47197901382049, 29.62126270920669],
            [80.47145726404153, 29.621179396012394],
            [80.47108782614231, 29.62109902090316],
            [80.47087013804651, 29.621075583671313],
            [80.4707399521871, 29.621036708677195],
            [80.47004488776145, 29.620894084294605],
            [80.46937482538794, 29.620539459128793],
            [80.46902745055644, 29.62043889603825],
            [80.46874407687773, 29.620435458829434],
            [80.46830570144704, 29.620582584318868],
            [80.46793238836909, 29.620750271907582],
            [80.4676003271934, 29.621051208446545],
            [80.46697701337854, 29.621808896263587],
            [80.46646920039336, 29.622203895593373],
            [80.46594295040688, 29.622368708948784],
            [80.4652442014588, 29.622435833447014],
            [80.46406203363603, 29.62301761297249],
            [80.46377126393276, 29.62316070859987],
            [80.46306813888356, 29.623496271735576],
            [80.46284757565513, 29.623646521469823],
            [80.46267151357955, 29.62373827210365],
            [80.46207570193218, 29.624171208434575],
            [80.46187620082605, 29.624359896092358],
            [80.46167482553284, 29.624663583657536],
            [80.46165045030807, 29.624814645479546],
            [80.46112957556949, 29.625245645570146],
            [80.46098617956858, 29.625839654976176],
            [80.46098432606584, 29.625847334287073],
            [80.46096070986891, 29.62586621375482],
            [80.46065301402541, 29.626112208911366],
            [80.46049932618303, 29.626186020768273],
            [80.46032420120099, 29.626223647503366],
            [80.4601500133125, 29.626202645635658],
            [80.45962901356819, 29.625986208496784],
            [80.45954288909309, 29.62590920854319],
            [80.4589356380693, 29.625635958532712],
            [80.4586113884049, 29.62542264653797],
            [80.45806913857996, 29.625208333597755],
            [80.4577246379817, 29.625049020495965],
            [80.45756163855884, 29.625050645570866],
            [80.45654901362616, 29.624960770922883],
            [80.45622295092852, 29.62491883373724],
            [80.45580470052658, 29.62491258344903],
            [80.45570076407904, 29.624912207532418],
            [80.45543882574128, 29.624947272099007],
            [80.4548496385001, 29.625044021164683],
            [80.45475507658557, 29.625041396043628],
            [80.45449201409525, 29.625171145731827],
            [80.45431720117796, 29.62520877066828],
            [80.45405420074081, 29.625320459271848],
            [80.45344151331523, 29.625466833827375],
            [80.45272170134552, 29.62547958351604],
            [80.45211195200505, 29.625377896272937],
            [80.45185020072626, 29.625374522016614],
            [80.45150163878964, 29.62531445809583],
            [80.45132813798324, 29.62523477096795],
            [80.45124170054407, 29.625157708961126],
            [80.45113963918305, 29.624999395905434],
            [80.4508733256435, 29.624587707856506],
            [80.45064245078851, 29.624067959069123],
            [80.44996070082573, 29.623198958565126],
            [80.4497495759822, 29.622794270838995],
            [80.44934576239706, 29.622217396215888],
            [80.44915563852197, 29.62187145850106],
            [80.44903101317078, 29.621523521593303],
            [80.44864976357576, 29.620888084017906],
            [80.44811713919466, 29.62019333345563],
            [80.44756201377595, 29.619557145845647],
            [80.44724532561133, 29.61897839703545],
            [80.44660688879696, 29.618111771640997],
            [80.44622095103614, 29.6177424587475],
            [80.44589857555889, 29.617511020916936],
            [80.44540245106231, 29.61723602082577],
            [80.44395507676546, 29.616589270678332],
            [80.44365095033118, 29.616547395545922],
            [80.4433678257646, 29.61656208417287],
            [80.44293057718443, 29.616670708786216],
            [80.44258157547938, 29.61670533358432],
            [80.44196982604672, 29.616851646985992],
            [80.44155232567937, 29.617075458565637],
            [80.44122088773383, 29.617358271067417],
            [80.44110970095198, 29.61751114592272],
            [80.44101870215144, 29.617738770627795],
            [80.44057188748269, 29.61842258363538],
            [80.44043870058562, 29.618573145434368],
            [80.43981126247985, 29.61963745890165],
            [80.4396997006807, 29.619788208658974],
            [80.4393678256647, 29.62010927112601],
            [80.43903970082158, 29.62020045878421],
            [80.43886532587413, 29.62021764572779],
            [80.43851670098502, 29.620234270595176],
            [80.43799713745801, 29.620053833317797],
            [80.43739026235079, 29.61991370905048],
            [80.43650607679405, 29.619424958994728],
            [80.43598757536637, 29.61919039602003],
            [80.43546795068545, 29.619012270899646],
            [80.43490845186363, 29.61864214591833],
            [80.43464845156484, 29.618562146725765],
            [80.43308570054472, 29.618293771940102],
            [80.43291095057998, 29.61833139597718],
            [80.4326503261517, 29.618307708733823],
            [80.43240410976193, 29.618343944217713],
            [80.43203920084852, 29.61839764543504],
            [80.43125663868301, 29.618328833808675],
            [80.43088832583561, 29.618230208757154],
            [80.43075945118778, 29.61813270875757],
            [80.43054651331101, 29.61784302093872],
            [80.43042195091238, 29.617497334134725],
            [80.43027351331204, 29.61726664723801],
            [80.43012795084434, 29.61686220862407],
            [80.4301289940579, 29.616800461172375],
            [80.43013857633429, 29.616232897230475],
            [80.43028282579183, 29.6154304591484],
            [80.43026188867526, 29.61411077059563],
            [80.43018251361218, 29.61365027094331],
            [80.43003695114442, 29.61324808423177],
            [80.42984688842324, 29.612902145617568],
            [80.42967682572498, 29.612669083611365],
            [80.42954851405273, 29.61253539579195],
            [80.42933351403053, 29.612378832816944],
            [80.42890038794263, 29.612221332748334],
            [80.42768270049629, 29.612071708942267],
            [80.42715907563087, 29.61210327064947],
            [80.42689663906867, 29.612174334178235],
            [80.42566795111509, 29.61271495892828],
            [80.42505491655123, 29.613144735041544],
            [80.42505076348203, 29.613147646147013],
            [80.42465320108442, 29.61348652058649],
            [80.42454207545649, 29.613619147205952],
            [80.4241020128976, 29.613881146697622],
            [80.42357526288811, 29.614122459084285],
            [80.4226123254985, 29.614398021251702],
            [80.42147932541059, 29.614402083489438],
            [80.42121888894064, 29.614340084227535],
            [80.42052201238101, 29.614312207942078],
            [80.41991307602768, 29.614248708611058],
            [80.41954463907388, 29.61412745931483],
            [80.41941545056267, 29.614029895463375],
            [80.41900888864933, 29.613565708590613],
            [80.41881857681591, 29.613219708822555],
            [80.41873695074975, 29.61287427203007],
            [80.41880963845409, 29.612414395607914],
            [80.41892326239872, 29.612128396808146],
            [80.41906245047176, 29.611614708551997],
            [80.41910088839535, 29.610581708579048],
            [80.41926720092061, 29.609723020600995],
            [80.41928113861371, 29.608861334283176],
            [80.41937770151975, 29.608288833962547],
            [80.41943132539541, 29.607657395672334],
            [80.41952495111565, 29.607256334012675],
            [80.41951296585074, 29.606938082826332],
            [80.41950982541817, 29.606854708478068],
            [80.41947298199256, 29.606809746872386],
            [80.41901826318235, 29.606254833693697],
            [80.41884576332137, 29.606157020729995],
            [80.41849801347263, 29.6060946464508],
            [80.41806538830707, 29.60591677044266],
            [80.41728332616464, 29.60577345897798],
            [80.41658945064268, 29.605571895726484],
            [80.41615482538492, 29.6055090834775],
            [80.41467407634758, 29.605511583592772],
            [80.41432645060524, 29.605469458448795],
            [80.41380376373274, 29.605462646084277],
            [80.41362895081551, 29.6055002089675],
            [80.41240988830577, 29.605485834203932],
            [80.41102138902596, 29.60516158364021],
            [80.41057026300729, 29.605001583456385],
            [80.41036920067813, 29.605781270489274],
            [80.40993676347091, 29.606512458882946],
            [80.40959145168421, 29.607571146191503],
            [80.40951782688626, 29.607961084136775],
            [80.40951545177671, 29.608367083973803],
            [80.40972657572092, 29.608913896059505],
            [80.40975951249158, 29.609349459310124],
            [80.40968563768212, 29.60978664673638],
            [80.40937882587218, 29.610392145880894],
            [80.40842870112374, 29.611121084170748],
            [80.40812313847209, 29.611449021954854],
            [80.40802980233354, 29.611562289767903],
            [80.40763788857777, 29.6120378971313],
            [80.40747520032028, 29.612380020821377],
            [80.4074189504243, 29.612833208985478],
            [80.40766570191067, 29.61341177163598],
            [80.40817645118233, 29.613904927470458],
            [80.40823363907123, 29.61396014584409],
            [80.40889138882659, 29.6143985221741],
            [80.40933676368076, 29.614556147248436],
            [80.40970988880036, 29.614823958159207],
            [80.40988663885736, 29.615120272182878],
            [80.40997376337856, 29.615510959263418],
            [80.40989801258326, 29.616212084220365],
            [80.40974901380599, 29.617303271229048],
            [80.4096716388351, 29.618347333762472],
            [80.4096673256866, 29.619080397242612],
            [80.40970507472946, 29.61950317213376],
            [80.40975095094569, 29.62001695931525],
            [80.41022495032126, 29.62142214551568],
            [80.41098445117154, 29.62278345829003],
            [80.41156770108859, 29.623767333695525],
            [80.41199123770554, 29.624417942833873],
            [80.4123703253303, 29.625000270945804],
            [80.41249213850142, 29.625084272121512],
            [80.4127488256978, 29.625347083701],
            [80.4131917004367, 29.62561070826763],
            [80.41356270045833, 29.625756708705183],
            [80.41377945056126, 29.625829895533286],
            [80.41433645106645, 29.625940584090756],
            [80.41481126252978, 29.626050958784788],
            [80.41505895110976, 29.626088145751396],
            [80.41539951267742, 29.62613477110284],
            [80.41557526358758, 29.62616264558966],
            [80.41598820079491, 29.62619152102195],
            [80.41637057544176, 29.6262021465119],
            [80.41663888907351, 29.626230458069244],
            [80.41667899074287, 29.626230635235686],
            [80.41697976270592, 29.62623195903774],
            [80.4173623253111, 29.62621558418192],
            [80.41757926337237, 29.626198520445428],
            [80.41767251857192, 29.62618537505506],
            [80.41801376362434, 29.62613727121817],
            [80.41866445100368, 29.626122083467408],
            [80.41888170112964, 29.626123083513562],
            [80.4190467006448, 29.626159834308964],
            [80.41956226308804, 29.626306457976682],
            [80.41978945072265, 29.62637052208197],
            [80.4198682133474, 29.626407428460084],
            [80.41996457570468, 29.6264525825207],
            [80.42025282550753, 29.626607209255326],
            [80.42027245860714, 29.626623513964034],
            [80.42042757547227, 29.626752333753245],
            [80.42062307549458, 29.626942708539218],
            [80.42075957099746, 29.62716136070827],
            [80.42078682675071, 29.62720502189427],
            [80.42090938815778, 29.62744245910227],
            [80.42120576333537, 29.62799414551347],
            [80.42133888907853, 29.628193270702923],
            [80.42143138794825, 29.628265834300862],
            [80.42147251394545, 29.628356333078386],
            [80.42173963867344, 29.628601021519955],
            [80.42188332605468, 29.62876408389542],
            [80.42218095039061, 29.62909920906128],
            [80.42232963890177, 29.62928708283266],
            [80.42239657544167, 29.62937089605009],
            [80.42265245055023, 29.629778021838263],
            [80.42283607592424, 29.63018483376311],
            [80.42285520180616, 29.63045564571155],
            [80.42287426293694, 29.63072639650602],
            [80.4228615141476, 29.631159458742104],
            [80.4227979509647, 29.63143889608824],
            [80.4227970759244, 29.631583271450893],
            [80.4226925126494, 29.631817395556425],
            [80.4225255759946, 29.632105396247084],
            [80.42226588865987, 29.632311833824133],
            [80.42211026367778, 29.632428458806373],
            [80.42192363906474, 29.632572022081206],
            [80.42178876414022, 29.63266164581836],
            [80.42149820038168, 29.632894958735392],
            [80.42127938903263, 29.633182770568453],
            [80.4211844512015, 29.63352745912499],
            [80.421119637961, 29.634023458615786],
            [80.42111795083281, 29.634312208441713],
            [80.42114988845663, 29.63454239621467],
            [80.42116488734973, 29.634799647285945],
            [80.42121313867545, 29.634944208808236],
            [80.4213253255034, 29.6354161469377],
            [80.42134051235485, 29.63564633381128],
            [80.42148582660968, 29.636019146866204],
            [80.42161563835111, 29.636292645988874],
            [80.42177632561697, 29.636924959319458],
            [80.42188845039175, 29.637412708429792],
            [80.42196795046061, 29.637857459154475],
            [80.42196645039144, 29.638114584320647],
            [80.4219819511062, 29.63828608413553],
            [80.422013388707, 29.63848702055958],
            [80.42204538838405, 29.63864502065121],
            [80.4219782629865, 29.63893120920801],
            [80.42197762176988, 29.638934983662637],
            [80.42194420116402, 29.63913183356732],
            [80.42189699664914, 29.63919837350619],
            [80.42181176340222, 29.639318521132907],
            [80.42163051313776, 29.639459770452106],
            [80.42153076303435, 29.639518020440335],
            [80.4212846374761, 29.639659083599895],
            [80.42080732589744, 29.639916396724402],
            [80.42036282608359, 29.640144520553235],
            [80.41988595067613, 29.64034095856846],
            [80.4195734506543, 29.64045464546558],
            [80.41891532678096, 29.640724709178016],
            [80.41873426357546, 29.640838959050768],
            [80.41830607566493, 29.641094334136255],
            [80.41792682616216, 29.64136552110199],
            [80.4176135140525, 29.64162133415732],
            [80.41726682540371, 29.642007771041847],
            [80.41706888911801, 29.642194145643373],
            [80.41696945107935, 29.64230877053336],
            [80.41670451260325, 29.642751959135637],
            [80.41657220164586, 29.64290702114215],
            [80.4162913253844, 29.643264458488034],
            [80.41614213864881, 29.6434803335506],
            [80.41599307601967, 29.64375039546445],
            [80.41589338796956, 29.643966520538527],
            [80.41574338914614, 29.644338021482554],
            [80.41572632540965, 29.644437208610327],
            [80.41569251269937, 29.64465364664852],
            [80.4157728878086, 29.64492689575968],
            [80.41583713807353, 29.645155020487834],
            [80.41599820035674, 29.645701645514578],
            [80.41611151403521, 29.64598864705846],
            [80.41619101230543, 29.646419834208018],
            [80.41618932607662, 29.64670627097769],
            [80.41617020109396, 29.647177647030844],
            [80.41613445034466, 29.647664771112375],
            [80.41613288732293, 29.647937709058056],
            [80.41626195082841, 29.64833977076387],
            [80.41653857599454, 29.648742520451037],
            [80.41691320118332, 29.64927427069108],
            [80.41717395061738, 29.649561895465183],
            [80.41751676318813, 29.649908521161365],
            [80.41790895033785, 29.65019677096427],
            [80.41816820060205, 29.650468583858128],
            [80.41823438800668, 29.650699021642595],
            [80.41824932574582, 29.65095845896576],
            [80.41819888906753, 29.65114320849233],
            [80.41811545086745, 29.651386458018692],
            [80.41798170099474, 29.65180314629822],
            [80.4179010156194, 29.651971754792726],
            [80.41774901400584, 29.6522893962387],
            [80.41763338906969, 29.652473833700526],
            [80.41760278154317, 29.65250933893492],
            [80.41738538752566, 29.652761521427124],
            [80.41723527808568, 29.652918419849243],
            [80.41707163834548, 29.65308945921123],
            [80.4167412004461, 29.65346020922135],
            [80.41622945112834, 29.653974520707607],
            [80.41594945080658, 29.654158271087397],
            [80.41570276407134, 29.65421583399359],
            [80.41557573393305, 29.654206572775138],
            [80.41547301246959, 29.654199084120478],
            [80.4153258887788, 29.65412845856156],
            [80.4150312007294, 29.653953458585306],
            [80.41453988850509, 29.653779833672445],
            [80.41388388883047, 29.653661896579308],
            [80.41276895072656, 29.653472020917093],
            [80.41242413896288, 29.65348402057117],
            [80.41214495072893, 29.65352564569207],
            [80.41176657536721, 29.65369539542621],
            [80.41161795070792, 29.653825583983576],
            [80.41137051393815, 29.654068082575975],
            [80.41102288909514, 29.65455377223884],
            [80.4109388258662, 29.65491208372572],
            [80.41097026256762, 29.655126520772342],
            [80.41103370074472, 29.655528334265284],
            [80.4110812011366, 29.655785708543647],
            [80.41103001262502, 29.65615764565888],
            [80.4109800759698, 29.65625895878395],
            [80.41085208085889, 29.656493143144075],
            [80.4108472631907, 29.656501959198124],
            [80.41061651244218, 29.656701708517062],
            [80.41056820086186, 29.656744333684117],
            [80.41028657546514, 29.65698670906943],
            [80.41015432566167, 29.657130520557132],
            [80.40992345080667, 29.657300897118773],
            [80.40972532566332, 29.657514396172473],
            [80.40957657599824, 29.65771445845553],
            [80.40951170070446, 29.657820083829847],
            [80.4094272633576, 29.65795739591715],
            [80.40917738852573, 29.658558582812418],
            [80.40902826294405, 29.658830833676063],
            [80.40881320086856, 29.659116396304682],
            [80.40851613770963, 29.659401520963456],
            [80.40818620073264, 29.65967302089325],
            [80.40775826283362, 29.659871834017963],
            [80.40737976246612, 29.659998708573596],
            [80.40695307552409, 29.66002614598989],
            [80.40655888918144, 29.660024395909204],
            [80.40569082577105, 29.65973402100832],
            [80.40539595066264, 29.65964695854035],
            [80.40501951333994, 29.65942870926756],
            [80.40464320102296, 29.659242083755203],
            [80.40405320079464, 29.659038646316503],
            [80.40339863733732, 29.65874695930478],
            [80.402726888735, 29.658500333723453],
            [80.40210376377775, 29.658398270563794],
            [80.40187407602787, 29.658367895961703],
            [80.40126670089757, 29.658380958614373],
            [80.40103651402399, 29.658436333470092],
            [80.4003955123431, 29.658620645926135],
            [80.4000220823533, 29.658766678739312],
            [80.39981951276036, 29.658845895521722],
            [80.3996211376055, 29.659088583871096],
            [80.39955143115367, 29.659208790853086],
            [80.39950501354559, 29.65928883411243],
            [80.3994553880558, 29.659390084285008],
            [80.39943820111216, 29.659505084192347],
            [80.39942013912815, 29.65977570908177],
            [80.39958176348767, 29.660177957846543],
            [80.39984163878069, 29.66066633378432],
            [80.40003745086773, 29.66085445846653],
            [80.40033201301208, 29.661000146839342],
            [80.40064245088848, 29.661245208499622],
            [80.4008551999076, 29.66136120845306],
            [80.40100195037974, 29.661546833919317],
            [80.401066388603, 29.661734333572667],
            [80.40103226382797, 29.661934958831353],
            [80.40081713790067, 29.66222045850742],
            [80.40066857619388, 29.66239120918698],
            [80.40042595079706, 29.66258864544966],
            [80.40035520113167, 29.662646959289816],
            [80.39991057541278, 29.662875021065418],
            [80.39968007557508, 29.662975522102727],
            [80.39946620150403, 29.663044458734817],
            [80.39877601320251, 29.66319927072982],
            [80.39843051345747, 29.663312708514752],
            [80.39760907539858, 29.663437583877453],
            [80.39682101297944, 29.663476833888808],
            [80.39655807549485, 29.663504957487874],
            [80.39622951268194, 29.66353283377333],
            [80.39539138860175, 29.66368689663301],
            [80.39519451351606, 29.663685958640144],
            [80.3947473076422, 29.663721590678904],
            [80.39417632537715, 29.663767083783966],
            [80.39342095061642, 29.663792959077853],
            [80.39302651246362, 29.66389039612494],
            [80.3925815755793, 29.664161271025876],
            [80.39221870093235, 29.66447539612267],
            [80.39203645062173, 29.664718208578506],
            [80.39185413735862, 29.665017395936104],
            [80.39152263735986, 29.665545958978896],
            [80.39128882531907, 29.66619002105267],
            [80.39115545046371, 29.66657739682944],
            [80.39110420079828, 29.666877209215897],
            [80.39105772113697, 29.66723688317569],
            [80.39103545032583, 29.667409208568188],
            [80.39105107604638, 29.667551395880253],
            [80.39106607673813, 29.66779508427578],
            [80.3911316391139, 29.66822627142534],
            [80.3912273260803, 29.668528958944364],
            [80.39131388762587, 29.668894770777115],
            [80.39147995103895, 29.66936702187064],
            [80.39158426250378, 29.66966977054352],
            [80.39165463805114, 29.669794145883202],
            [80.39176857585915, 29.67006764680451],
            [80.39184676291785, 29.670322896884215],
            [80.3918894510374, 29.670634333907458],
            [80.39183426414002, 29.670958958589097],
            [80.39178901385196, 29.67106702202551],
            [80.391619326171, 29.671260208991498],
            [80.39125332548065, 29.671608146798576],
            [80.39063863886213, 29.671914395632882],
            [80.39034513881717, 29.672021334017415],
            [80.39005138876064, 29.672159771156544],
            [80.38948182562581, 29.672373771132698],
            [80.38897270052973, 29.672572146287564],
            [80.38886788904188, 29.67261227043997],
            [80.38866288768219, 29.672703833115463],
            [80.38805788856075, 29.672958209054173],
            [80.38777288890776, 29.67309439608954],
            [80.38707813834549, 29.673449896295722],
            [80.3868590176296, 29.67361901650446],
            [80.38681957606263, 29.6736494585557],
            [80.38669476365249, 29.67373464683652],
            [80.38649782561424, 29.673997645475026],
            [80.38639070107007, 29.674089646120308],
            [80.3862112008863, 29.674397833892954],
            [80.38601232570835, 29.674816459312183],
            [80.38594188900703, 29.674989834213534],
            [80.38587770079533, 29.675248753527228],
            [80.38583463855775, 29.675422458479716],
            [80.38585151253733, 29.675512771097544],
            [80.3857452009803, 29.67584839718586],
            [80.38564763892742, 29.67616595859215],
            [80.38555045099258, 29.676386583873807],
            [80.3854003253648, 29.676803209200784],
            [80.38531101369239, 29.67715245911876],
            [80.38523095154721, 29.677463334065692],
            [80.38517613876786, 29.677880333510643],
            [80.38505963879135, 29.67844833272443],
            [80.38502095085624, 29.67907077059965],
            [80.3850446380996, 29.67948139564993],
            [80.38510395108653, 29.679763646075457],
            [80.38518013895231, 29.680082020468944],
            [80.38527382672578, 29.68040277177056],
            [80.38539382596434, 29.680683084157067],
            [80.38564282575584, 29.681209771214014],
            [80.38572938820079, 29.68131620867615],
            [80.38587501362105, 29.681578584084434],
            [80.38590982547811, 29.681641895457176],
            [80.38611645101344, 29.682024083944384],
            [80.38638176270825, 29.682738082696403],
            [80.38655313841667, 29.68318102218649],
            [80.3866559507116, 29.683422833696852],
            [80.38670707716994, 29.683567459071014],
            [80.38680145112613, 29.683818270996483],
            [80.38691145080287, 29.684321833785646],
            [80.38692701357087, 29.684624146287376],
            [80.38695013873797, 29.685079958673214],
            [80.38686182531302, 29.68531645878761],
            [80.38680436492956, 29.68539522411038],
            [80.38672943881261, 29.685497928486654],
            [80.38671263857748, 29.68552095742632],
            [80.38664047877512, 29.685582662609818],
            [80.3865552005621, 29.685655584137976],
            [80.3862791383716, 29.68581220916616],
            [80.38554888797086, 29.686149458530736],
            [80.3851395138775, 29.6863347089797],
            [80.38480044968105, 29.686597083488664],
            [80.38469313807792, 29.686720583787974],
            [80.3844430761871, 29.68695183366026],
            [80.38412201372, 29.687166896635006],
            [80.38392601367462, 29.687274272090008],
            [80.3834987628577, 29.68742795903313],
            [80.3831965753617, 29.687519021685546],
            [80.38280451231844, 29.68773377169623],
            [80.38244751384173, 29.68802758380599],
            [80.38232213755668, 29.688196146435075],
            [80.38208901259793, 29.688553771739237],
            [80.38196195098328, 29.68900202082665],
            [80.38192538904553, 29.689173333582517],
            [80.38188819938097, 29.689450583777443],
            [80.38185113742009, 29.68969858352284],
            [80.38186663903423, 29.690070833602135],
            [80.38184994312041, 29.69034005824716],
            [80.38182720106454, 29.69070677209993],
            [80.38185620060324, 29.691776083999116],
            [80.38187357550515, 29.691839334217946],
            [80.38188563811178, 29.69276877096206],
            [80.38192020085665, 29.69292458390248],
            [80.38191494971522, 29.693777208651284],
            [80.38188807617382, 29.694210207934816],
            [80.38182595100687, 29.69444002069048],
            [80.38180407589743, 29.694697020850867],
            [80.38176251282977, 29.6948389590508],
            [80.38168882597859, 29.695262708807036],
            [80.38154238757119, 29.695972584167464],
            [80.38145913912803, 29.69633527085614],
            [80.38141701308473, 29.69656527067076],
            [80.38136438745659, 29.696874020519658],
            [80.38133245073209, 29.697131021579423],
            [80.38131820097425, 29.697830271449902],
            [80.38129663792955, 29.698026395601744],
            [80.38125513781443, 29.69815927043402],
            [80.38118051386965, 29.698734146763513],
            [80.381056512648, 29.699105770914628],
            [80.38101432455147, 29.699344709091122],
            [80.38102351292486, 29.699531958732962],
            [80.38110313889882, 29.69977145898571],
            [80.381112949603, 29.699859458547223],
            [80.38130426238189, 29.70009945882299],
            [80.38158588777856, 29.70047295895995],
            [80.3816764504079, 29.700606458821085],
            [80.38162245061568, 29.70088370901601],
            [80.38158245056968, 29.70103014562477],
            [80.38139838812515, 29.70129545911817],
            [80.3811636380915, 29.701490584123235],
            [80.3809090130406, 29.701613458494364],
            [80.38047188766751, 29.701744521192722],
            [80.37990282545508, 29.70189070868929],
            [80.37949588762518, 29.70204895879249],
            [80.37923206610702, 29.70215297887694],
            [80.37913970033696, 29.70218939602387],
            [80.37899670093708, 29.702330834200666],
            [80.37884351311772, 29.70245420859493],
            [80.37845163893206, 29.703462959248213],
            [80.37841045088169, 29.703611645960734],
            [80.37833838730677, 29.70376239661738],
            [80.37825538797586, 29.70401920881949],
            [80.37819307574989, 29.70427602192092],
            [80.37810982550809, 29.704575645449722],
            [80.37789251242958, 29.705249083878186],
            [80.3776965123842, 29.70576252122345],
            [80.37755251383749, 29.70607083400182],
            [80.37735695086263, 29.70651433376952],
            [80.37722088793367, 29.706791146893977],
            [80.37699145109463, 29.707173521540824],
            [80.37689726419745, 29.707254270768033],
            [80.3766936379011, 29.707801458770348],
            [80.37650401404909, 29.70822014624281],
            [80.376300075688, 29.708814708731893],
            [80.37616363774174, 29.709303520840876],
            [80.37602645066022, 29.70986239553315],
            [80.37593107575861, 29.710188959153186],
            [80.37587620092597, 29.710443584204086],
            [80.37586421656039, 29.710797703051583],
            [80.37585963811188, 29.71093302044261],
            [80.37595063781174, 29.711366521547802],
            [80.37608245054469, 29.711610772019583],
            [80.37625276235514, 29.71218227049542],
            [80.37627795056704, 29.71239214618066],
            [80.37624988902121, 29.712637895822297],
            [80.37618163857184, 29.712869958681722],
            [80.37593913908006, 29.713218458565052],
            [80.37567051248425, 29.713463021101575],
            [80.37499907594668, 29.713703458447924],
            [80.37465763754, 29.71391614631318],
            [80.37436691819875, 29.714221886131213],
            [80.37419426365437, 29.71440345925231],
            [80.37386020058784, 29.714880083748938],
            [80.37372801373692, 29.71530583359737],
            [80.37378124460884, 29.71556126444085],
            [80.37381176310242, 29.715707709143487],
            [80.37403870072552, 29.71626145859949],
            [80.37446863781742, 29.716577020812906],
            [80.3749985759236, 29.716897584156243],
            [80.37569507566724, 29.71738133398145],
            [80.37614795086728, 29.717627083623086],
            [80.37673495095726, 29.71794564597485],
            [80.37707463748461, 29.71818189607768],
            [80.3772279503097, 29.718417209086965],
            [80.3773400759838, 29.71867033406869],
            [80.37739057561464, 29.718857833722097],
            [80.37735788885556, 29.719128333605738],
            [80.3773040761223, 29.719479958633258],
            [80.37720876417319, 29.719849458585713],
            [80.37716657607666, 29.719980084213546],
            [80.37707207531611, 29.72027289627721],
            [80.37697801342466, 29.720443896069014],
            [80.37689457612385, 29.720560834015316],
            [80.37667613889278, 29.720776334060645],
            [80.37650970046235, 29.720946958835157],
            [80.37626088862919, 29.721108209076647],
            [80.37611563732685, 29.72119777076057],
            [80.37600170041821, 29.721233333551538],
            [80.37580445031517, 29.721367708453045],
            [80.37517301292428, 29.721554208959617],
            [80.37499801384735, 29.72159402104728],
            [80.37481995078014, 29.72164958386128],
            [80.37442188746019, 29.721557459109476],
            [80.37380557576677, 29.721453021739592],
            [80.37345813798277, 29.721473958856166],
            [80.37299545117918, 29.721483022223765],
            [80.372377950582, 29.721502645430803],
            [80.37156820101171, 29.721487583585144],
            [80.37109251360869, 29.721507833619683],
            [80.37092093555282, 29.721525829053803],
            [80.37078382671228, 29.721540209213344],
            [80.37035876394583, 29.72162845878637],
            [80.37015263843358, 29.721695146214074],
            [80.36971407594393, 29.721916333571983],
            [80.36957220069655, 29.722028459246076],
            [80.36935232544954, 29.72221914609679],
            [80.36914736096202, 29.722470647802197],
            [80.36901538904903, 29.72263258422646],
            [80.36867870086144, 29.723057334028795],
            [80.36837976261603, 29.723538646691225],
            [80.36810713763435, 29.72399745831609],
            [80.36803772705952, 29.72418105581113],
            [80.3679632631941, 29.724378020829022],
            [80.36776745110706, 29.724848520942544],
            [80.36767407539833, 29.725364645462093],
            [80.36761701341447, 29.726273458953017],
            [80.36762488877764, 29.72706970880006],
            [80.36762918933567, 29.727124616907588],
            [80.36764857692037, 29.727372147206893],
            [80.36762951399095, 29.728393896661032],
            [80.36766388787817, 29.729043709],
            [80.36771345041547, 29.729337146092462],
            [80.36785126252579, 29.72991977188002],
            [80.36811738900633, 29.730561708855817],
            [80.36832088849826, 29.730876208969903],
            [80.36853713857812, 29.731269709129833],
            [80.36890751357095, 29.731664022276846],
            [80.36940620113535, 29.732103958930622],
            [80.3698800134519, 29.732433333831352],
            [80.37049407594088, 29.732918958743028],
            [80.37118482541939, 29.733492959132832],
            [80.37132432555723, 29.73380939728588],
            [80.3713608254418, 29.73412314556674],
            [80.37136982585685, 29.734238208426632],
            [80.37138476359604, 29.734425583973575],
            [80.37130645063223, 29.73460558418043],
            [80.3711118256503, 29.734895646117252],
            [80.37091745067988, 29.73512033363653],
            [80.37046445137338, 29.735589583692388],
            [80.36989613919553, 29.736001958823408],
            [80.36928876406529, 29.736513334023186],
            [80.36855188835904, 29.73711664691575],
            [80.36824176254737, 29.737363271597758],
            [80.36751776243483, 29.737975709011835],
            [80.36711701283991, 29.738343645942564],
            [80.36675438820453, 29.738723147255484],
            [80.36615920068664, 29.739349645569746],
            [80.36592532299534, 29.739713994205545],
            [80.365886388646, 29.739774647182287],
            [80.36583270091847, 29.7401443341937],
            [80.36606462078566, 29.74053396187287],
            [80.36608720096359, 29.740571897075426],
            [80.36644382712097, 29.741101459264996],
            [80.3665830754486, 29.74146077079797],
            [80.3667845757476, 29.74214745893812],
            [80.36685882557441, 29.74257408382698],
            [80.3668035136713, 29.743200958957118],
            [80.36666123372902, 29.74394357953264],
            [80.36665713731708, 29.7439649591156],
            [80.36664120133042, 29.744501771639477],
            [80.36666401263415, 29.744928208570002],
            [80.36676207560936, 29.74572714649065],
            [80.36693832564328, 29.7463325215287],
            [80.36731782605688, 29.747333646830384],
            [80.3674950131844, 29.747772084314306],
            [80.36778813911138, 29.748177271164195],
            [80.36813263881027, 29.74863908382673],
            [80.36840007560306, 29.74902158347936],
            [80.36868113892348, 29.749336458610742],
            [80.36914076263616, 29.749889020961575],
            [80.36928932614154, 29.750000271595297],
            [80.36908301267096, 29.750078270695667],
            [80.3692964505708, 29.750261959022225],
            [80.36974451259925, 29.750645333715227],
            [80.37044901271184, 29.75099158349485],
            [80.37107188855686, 29.750756458443846],
            [80.37124395044799, 29.750785583887648],
            [80.37157920061969, 29.750665333738198],
            [80.37187726382473, 29.7503283955391],
            [80.37198157618883, 29.750083020914758],
            [80.37204670149413, 29.749999895678684],
            [80.37212420057148, 29.749903270719415],
            [80.37248620107738, 29.749580147006213],
            [80.3729507638667, 29.749336458610742],
            [80.3734785768749, 29.749214895451132],
            [80.37407057539679, 29.749161333628706],
            [80.37463632630545, 29.749175272221066],
            [80.37499345158659, 29.749271709222057],
            [80.37510432540438, 29.749260958726325],
            [80.37520120037516, 29.749272647214923],
            [80.37556351384512, 29.749263083824303],
            [80.37587963813485, 29.749348084146845],
            [80.37659970101544, 29.74938752211648],
            [80.37666895061164, 29.7494239590485],
            [80.37740220115074, 29.749574021723788],
            [80.37805295058325, 29.749635770974123],
            [80.37857926352223, 29.749651771711967],
            [80.37888382612772, 29.749675708966834],
            [80.37907757606934, 29.749690208736183],
            [80.37967320065775, 29.74967945913977],
            [80.38014413874106, 29.749717709105028],
            [80.38033782573012, 29.749743458493867],
            [80.38060051410253, 29.749852959046848],
            [80.38083032595881, 29.75000064571327],
            [80.3817593256324, 29.750266646288765],
            [80.38219407589594, 29.750521334292216],
            [80.38289995107192, 29.75065095897463],
            [80.38368995063075, 29.750618522227057],
            [80.38418863819516, 29.750602833553955],
            [80.38452113913934, 29.75060433362313],
            [80.38499970077561, 29.750552458928894],
            [80.38556726381825, 29.750435333923576],
            [80.38576945119928, 29.750393583796892],
            [80.38645576342282, 29.750342647095522],
            [80.38749420069684, 29.750419647049114],
            [80.38801120115608, 29.75085739565236],
            [80.38810170083286, 29.751383395627272],
            [80.38797551246, 29.75161739562634],
            [80.38770845158382, 29.75222745883019],
            [80.38749120055854, 29.7526685834884],
            [80.38732226381148, 29.753085145862826],
            [80.38719445036367, 29.75359208380769],
            [80.38702451267119, 29.75422514717286],
            [80.38692688856509, 29.754858520804135],
            [80.38682095112603, 29.75522120929145],
            [80.38675582582079, 29.755674270651127],
            [80.38656551398736, 29.756199021467694],
            [80.38660151384886, 29.75708795904211],
            [80.38659882577525, 29.75752327228122],
            [80.3866380757866, 29.757922708865976],
            [80.38685370083766, 29.758341020421824],
            [80.38687351380167, 29.75853964648752],
            [80.38730807610688, 29.75881458362619],
            [80.38792882595459, 29.759306958849095],
            [80.38848876274625, 29.759489959194298],
            [80.3895883260405, 29.75984020915837],
            [80.39023145102072, 29.76004389570926],
            [80.39124813819109, 29.760319271717094],
            [80.39185051309079, 29.760376208695106],
            [80.39272351287985, 29.760362146895602],
            [80.3931925129242, 29.760129709018884],
            [80.39350626390302, 29.759822084221867],
            [80.39392357541271, 29.759535270636263],
            [80.39425776258571, 29.75926389571225],
            [80.39461263866241, 29.759046708538847],
            [80.39519726274352, 29.7586162705245],
            [80.39569676239574, 29.758471959013775],
            [80.39615501374294, 29.758311708818383],
            [80.39692463916083, 29.758224897261243],
            [80.39760876333384, 29.758291208772334],
            [80.39771401279154, 29.758300709210403],
            [80.39835670160056, 29.758538208471577],
            [80.39858426335314, 29.75874002083532],
            [80.399246139198, 29.75928664676138],
            [80.39926463915185, 29.75964989552631],
            [80.39929195066304, 29.76031995879913],
            [80.39903838861079, 29.761009021149448],
            [80.3987020754405, 29.761641396533264],
            [80.39819745145127, 29.762581958790406],
            [80.39798620070263, 29.763160708499925],
            [80.39797976335547, 29.763201333574727],
            [80.3978672635634, 29.76390458362971],
            [80.39788507553584, 29.76444827057128],
            [80.39806826294006, 29.76510327199844],
            [80.39864495050409, 29.765956333817826],
            [80.39901657555458, 29.766393334185068],
            [80.39931582586473, 29.76677589589093],
            [80.39950101336115, 29.76704970887704],
            [80.39952082002986, 29.76707744756618],
            [80.39985163744319, 29.767540771089784],
            [80.39999570074104, 29.767776022045837],
            [80.40024263748779, 29.76821252149074],
            [80.4004668879366, 29.768921833875595],
            [80.40056913815528, 29.76922908365532],
            [80.40074609955292, 29.76970312619835],
            [80.4007530135408, 29.769721646836558],
            [80.40088582542057, 29.770155334101446],
            [80.4009037623988, 29.770626834261066],
            [80.40090157614691, 29.77099002097276],
            [80.40102232542006, 29.771662772319132],
            [80.4010207003451, 29.77193345926179],
            [80.40114232465862, 29.772459584242483],
            [80.40128457582256, 29.773006084263443],
            [80.40134538887867, 29.773259022186153],
            [80.40160238903906, 29.773749708482285],
            [80.40160182606348, 29.773839834041155],
            [80.4018282636635, 29.77418608382078],
            [80.40209663754985, 29.7744962707863],
            [80.40271701238026, 29.775078833621308],
            [80.403523325641, 29.775808771957315],
            [80.40387520068003, 29.77604489705442],
            [80.4043107009781, 29.776247708564938],
            [80.40474576240706, 29.77646620874856],
            [80.40509838838, 29.776630209116888],
            [80.40523709531573, 29.776749840532773],
            [80.40556376235781, 29.777031583740666],
            [80.40581157594352, 29.777323646669004],
            [80.40589470117959, 29.77747064625339],
            [80.40599645047587, 29.77765158355379],
            [80.40611888777653, 29.7780491459514],
            [80.40634570039384, 29.77839527072524],
            [80.4063640123893, 29.778794584102798],
            [80.40635818658109, 29.778860727440644],
            [80.40631932597614, 29.779301959118186],
            [80.40619276348531, 29.77966452170034],
            [80.4058364511913, 29.78011633390173],
            [80.40559476378735, 29.78058670900947],
            [80.40536326300423, 29.781039084186432],
            [80.40537963786005, 29.781765458509142],
            [80.4056478885393, 29.782163708888106],
            [80.40591626242565, 29.782473895853684],
            [80.40624738830638, 29.78273033393782],
            [80.40682782604341, 29.782967522033573],
            [80.40711863891408, 29.78304102092642],
            [80.40755267241798, 29.783241239691506],
            [80.40763701263808, 29.78328014616187],
            [80.40830095062842, 29.783499708444822],
            [80.40857032546023, 29.783647458963117],
            [80.40883845113365, 29.784009645628714],
            [80.40886788864219, 29.78428277063341],
            [80.40884507553989, 29.784645772084787],
            [80.40886101332518, 29.785067646754555],
            [80.40886295046482, 29.78511727044571],
            [80.4087356379394, 29.785606208459797],
            [80.40858807537944, 29.785912334087016],
            [80.40846038783667, 29.786403522204864],
            [80.40843820066249, 29.78665602125841],
            [80.40837151863076, 29.786790442025335],
            [80.40831201408821, 29.78691039719712],
            [80.4082064498678, 29.787216646031425],
            [80.40814332555408, 29.78732689571973],
            [80.40798370038755, 29.787923959223406],
            [80.40791820096428, 29.78844927121696],
            [80.40795745097563, 29.78886677068499],
            [80.40795395081426, 29.78946445921747],
            [80.40820007547319, 29.790045334025024],
            [80.40857138755956, 29.790574772108073],
            [80.40924301385405, 29.791247771667372],
            [80.40934445108559, 29.79164745916296],
            [80.4093605759299, 29.79197464601316],
            [80.40936320105095, 29.792028770811157],
            [80.40936051297734, 29.792482146034274],
            [80.40915032612673, 29.792824083564653],
            [80.40887751318672, 29.79325820879933],
            [80.40858382608275, 29.793728333895558],
            [80.40860338903525, 29.793911147181745],
            [80.40860020093857, 29.79445477117082],
            [80.40884895071855, 29.7946002086328],
            [80.40962901366811, 29.794549583996172],
            [80.41038088916673, 29.793955145613552],
            [80.41096576236009, 29.79350439551149],
            [80.41135591794131, 29.793075595162634],
            [80.41211663737295, 29.792239520637395],
            [80.41253470071592, 29.791896270996176],
            [80.41282595065718, 29.791843459208337],
            [80.4136165140909, 29.79175670880511],
            [80.41451007597931, 29.791814833787612],
            [80.41484245101833, 29.791852396670834],
            [80.41530807590703, 29.792235709310603],
            [80.41572220111874, 29.792564584188256],
            [80.41574213908848, 29.79269095872081],
            [80.41584426250279, 29.79303645846585],
            [80.41588232540903, 29.793598397148344],
            [80.4159417004492, 29.794124209164977],
            [80.4160857007945, 29.7943797722088],
            [80.41641601368815, 29.794780458851164],
            [80.41681663557932, 29.79513158655351],
            [80.41685057599335, 29.795161333428837],
            [80.41767920053474, 29.795710896540697],
            [80.41826020034802, 29.795875896955124],
            [80.41922626378113, 29.79604252154519],
            [80.41970376241875, 29.796137083459712],
            [80.42006134365613, 29.796138676159046],
            [80.420265326084, 29.796139583574984],
            [80.42076413865414, 29.796141770726194],
            [80.42261482561378, 29.79611152202915],
            [80.42325938860995, 29.79613233413994],
            [80.4236755139139, 29.79606195859259],
            [80.4241945126667, 29.79621089621594],
            [80.42456870078496, 29.796248584104944],
            [80.42522238740332, 29.796467958429616],
            [80.4261141389564, 29.796853084102622],
            [80.42663345067325, 29.79694552181843],
            [80.42735970088955, 29.797203584078204],
            [80.42821132559226, 29.797369708645192],
            [80.42868888808175, 29.79746195930204],
            [80.42922907576127, 29.797536521193535],
            [80.43063995107292, 29.798106522298212],
            [80.43099176405872, 29.798378709310043],
            [80.43141663796752, 29.798599333692323],
            [80.43159033572545, 29.798767705665114],
            [80.43183038816193, 29.799000397150678],
            [80.43191207538206, 29.79925339712662],
            [80.43217901305104, 29.799852271864893],
            [80.43219776391572, 29.79996064556741],
            [80.43234026239327, 29.800777833430345],
            [80.43277301346387, 29.801433834004285],
            [80.4331970123323, 29.801871020531223],
            [80.43386032619316, 29.80223702122163],
            [80.43398438856872, 29.802309708925975],
            [80.43423232536156, 29.80259952085123],
            [80.4345628873674, 29.802982147308285],
            [80.43495407537034, 29.803674146844457],
            [80.4352626390596, 29.804291208572465],
            [80.4354669506393, 29.804925896113275],
            [80.43581745061493, 29.80543720925988],
            [80.43612695049842, 29.805889645590696],
            [80.43645888846697, 29.80601964618978],
            [80.43708263845303, 29.806076459061387],
            [80.4381855760036, 29.805880396063458],
            [80.43898876412021, 29.805412396964584],
            [80.43953326314966, 29.804762770785317],
            [80.43988988750834, 29.80418458405137],
            [80.44049963774813, 29.80297133386],
            [80.44096032535879, 29.80239358419658],
            [80.44151507576021, 29.801705708951374],
            [80.44207970071767, 29.801164397119294],
            [80.44211908742602, 29.801133395689703],
            [80.44278951402492, 29.80060570858666],
            [80.44305445070239, 29.800401584065924],
            [80.44339507612193, 29.800136833548095],
            [80.44412445058299, 29.79984889670925],
            [80.44441695058185, 29.799633583722937],
            [80.44506270068314, 29.799435583585364],
            [80.44587520217891, 29.799132208984304],
            [80.44660351364132, 29.79902695862728],
            [80.44756026279595, 29.798956521026696],
            [80.44804932671514, 29.798904459273444],
            [80.44829863767205, 29.798905459319542],
            [80.44859013852414, 29.798870583610608],
            [80.44889970046086, 29.798889895652223],
            [80.44923457561521, 29.798909396551437],
            [80.44996151291355, 29.79905902125688],
            [80.45010720218568, 29.799021271314643],
            [80.45023188779146, 29.799042083425434],
            [80.45041813918584, 29.799151083955394],
            [80.45077151339467, 29.799206771775175],
            [80.45158057588293, 29.799537208775234],
            [80.4521193255465, 29.79986427061965],
            [80.45290651292527, 29.800411145657904],
            [80.45336151412266, 29.800866395967432],
            [80.45428251252798, 29.801668771096956],
            [80.45504648210954, 29.802181944940855],
            [80.45506976285935, 29.80219758415126],
            [80.45560995143819, 29.802271958983795],
            [80.45594176350164, 29.80243802149755],
            [80.45644082608334, 29.802458146526305],
            [80.45689795058001, 29.8025322092941],
            [80.45727188868676, 29.802569834230496],
            [80.45813395092114, 29.802537271577876],
            [80.45901901331689, 29.80250477097843],
            [80.4596843254713, 29.80250752200459],
            [80.46046463843237, 29.8024204586373],
            [80.46086020073773, 29.802349896930252],
            [80.46103537158518, 29.802305511789996],
            [80.46135970038989, 29.80222333354004],
            [80.4616299511614, 29.802224458591922],
            [80.46256626232321, 29.802101959238087],
            [80.46286313752381, 29.80209414592815],
            [80.46323170038272, 29.802086583529046],
            [80.46395932566242, 29.802125646481443],
            [80.46419761452836, 29.8021637570518],
            [80.46476982616662, 29.802255272063235],
            [80.46566363806647, 29.802276896261787],
            [80.46646463903193, 29.80218989584705],
            [80.46740157612192, 29.80194108401389],
            [80.46790157579716, 29.80179645863973],
            [80.46827670100902, 29.80165358424557],
            [80.46885970091455, 29.80145745919441],
            [80.46904770059098, 29.801311645815872],
            [80.46961070047348, 29.801043083971194],
            [80.46973526287212, 29.801025584063495],
            [80.47017276236318, 29.800898770661775],
            [80.47038111459682, 29.800742803037963],
            [80.47079920042285, 29.800429833570035],
            [80.47115301350084, 29.80034102102138],
            [80.47159063889694, 29.80012620895741],
            [80.47207013852613, 29.79992745878525],
            [80.47249720048546, 29.799748646582827],
            [80.47278932546703, 29.799587395442018],
            [80.47337251243158, 29.799352896319135],
            [80.47422620107835, 29.799139708430857],
            [80.47491338744294, 29.79897777200665],
            [80.47537101286201, 29.798889395629146],
            [80.47543746466732, 29.79887394347776],
            [80.4758290141977, 29.798782897013155],
            [80.47628726374626, 29.798638083680657],
            [80.47682901354818, 29.79840564580394],
            [80.47728713899033, 29.798281084304676],
            [80.47789959708876, 29.79805994101349],
            [80.47818307598823, 29.797957583775542],
            [80.47894401395513, 29.7975432706055],
            [80.47938207552244, 29.797290145623776],
            [80.4797371386581, 29.796966708946513],
            [80.4801757641003, 29.796605270516864],
            [80.48059407565614, 29.796117397300122],
            [80.48102276269043, 29.79585970915832],
            [80.48128251387698, 29.795702834118572],
            [80.48207288755378, 29.7955976467141],
            [80.48282088877306, 29.79574489630994],
            [80.48362988830877, 29.79609320913437],
            [80.48440857619488, 29.796312770518],
            [80.48457432574457, 29.796405959167714],
            [80.4857806385657, 29.796372270563893],
            [80.48640488857478, 29.79624839614661],
            [80.48671688767428, 29.79624958415104],
            [80.48723732534233, 29.796125271763913],
            [80.48767388863911, 29.796108958961327],
            [80.48823601258198, 29.79600283356399],
            [80.48920771386497, 29.79595423689858],
            [80.48995182551641, 29.795917022053004],
            [80.49051301416432, 29.795919147150983],
            [80.49088795051858, 29.795812333772233],
            [80.49124045058636, 29.79580239716296],
            [80.49140782611033, 29.795796271880477],
            [80.49230238894415, 29.795673396610027],
            [80.49294738901085, 29.795603709043974],
            [80.49409207578873, 29.79537120911408],
            [80.4945074510581, 29.795426958987036],
            [80.4950685138009, 29.79553964673738],
            [80.49542032588738, 29.795847771557476],
            [80.49554426415654, 29.796012896977686],
            [80.4958327010184, 29.796521522050625],
            [80.49608076281703, 29.796829271853483],
            [80.49609813771895, 29.797555646176193],
            [80.49615838869875, 29.797919083798718],
            [80.49624970046335, 29.798408896853175],
            [80.4961223258847, 29.798915958904445],
            [80.49597488743115, 29.799314647253198],
            [80.49574413848126, 29.79967695892458],
            [80.49534688904777, 29.8001084590382],
            [80.49488632554352, 29.80072483368417],
            [80.49436295068966, 29.80148302152429],
            [80.49443282531468, 29.802047208511908],
            [80.49520163864474, 29.80212233427835],
            [80.49557582586374, 29.80215983420902],
            [80.49595070196341, 29.8020710837136],
            [80.49649101285002, 29.80212727065708],
            [80.49665682535226, 29.80220008426653],
            [80.49667520119965, 29.80272570922415],
            [80.49677813760104, 29.802906583572053],
            [80.49683982569746, 29.80303539706597],
            [80.49683782560521, 29.80343464569239],
            [80.49691801365549, 29.804014645459574],
            [80.49697888786545, 29.80432395918342],
            [80.49720551252443, 29.804778208547532],
            [80.49741220101231, 29.805013583610048],
            [80.49782651418235, 29.805324208545414],
            [80.49823020096306, 29.80566858413789],
            [80.49856182596756, 29.8058886455446],
            [80.49922557599962, 29.806218272255535],
            [80.4998692630561, 29.806419208679586],
            [80.49990638347288, 29.806477566586466],
            [80.5000001386955, 29.8066249591746],
            [80.50057982549862, 29.806963270638505],
            [80.5006507640216, 29.80706277162966],
            [80.50075001410192, 29.807200708745768],
            [80.50085620065317, 29.807374833681706],
            [80.50101932598119, 29.807594270958873],
            [80.50109707596931, 29.807761458524567],
            [80.50119588807985, 29.807980646689543],
            [80.5014733891856, 29.808155395754966],
            [80.50175838793933, 29.80833239672279],
            [80.50193595098341, 29.808513521082205],
            [80.50199932530865, 29.808712271254365],
            [80.50199088876855, 29.808980646040027],
            [80.50194695059133, 29.80916770862291],
            [80.50145570042031, 29.80937339616537],
            [80.50107846360027, 29.809523603631476],
            [80.50102357617715, 29.809545458955824],
            [80.5004274506664, 29.80971014730551],
            [80.50011882582322, 29.809803709173877],
            [80.49999988868399, 29.809871021630386],
            [80.49962301339139, 29.80997327094974],
            [80.49922595101691, 29.810353022274228],
            [80.49907913849148, 29.810623084188023],
            [80.499057137477, 29.810823771499884],
            [80.49899251399336, 29.811258896780714],
            [80.49911570042929, 29.811584208544446],
            [80.49952988769422, 29.811912834309908],
            [80.49986145064548, 29.812148708496125],
            [80.50001945073711, 29.812218021944204],
            [80.50049013880891, 29.8127736455873],
            [80.5005967021761, 29.81293420874681],
            [80.50072401380226, 29.813135459034243],
            [80.50097982595827, 29.813398083554773],
            [80.50124938874836, 29.813746458432377],
            [80.50137678401143, 29.813937345831903],
            [80.50146220072003, 29.814065333748204],
            [80.50163920078853, 29.814366022074296],
            [80.50170607707383, 29.814571520759102],
            [80.50170755555928, 29.814633996661655],
            [80.50171195054617, 29.814819708462835],
            [80.50176770041912, 29.815056770653484],
            [80.50184495038422, 29.81532552225508],
            [80.50192957568942, 29.815537834203724],
            [80.50206463857228, 29.8156872088976],
            [80.50213482706067, 29.81595364654362],
            [80.50226201278173, 29.816179709126345],
            [80.50247757577955, 29.816665459043747],
            [80.50273282585931, 29.81704089632035],
            [80.50291695035702, 29.81733933364336],
            [80.50294401275602, 29.817357520633095],
            [80.50299320117534, 29.817477208706293],
            [80.50309451340115, 29.81759039558034],
            [80.5032418889021, 29.81773083371104],
            [80.50340320119682, 29.81787577204932],
            [80.50359136904649, 29.817962302118588],
            [80.50382357669679, 29.81806908402109],
            [80.50397588857646, 29.818137333571144],
            [80.50416076400813, 29.818226021114015],
            [80.50437801233545, 29.818357645888682],
            [80.50452126354554, 29.8184326466494],
            [80.50471488848137, 29.818507771516465],
            [80.50490920049924, 29.818573958921093],
            [80.50512670063677, 29.818606333615435],
            [80.50549701267704, 29.81865958427244],
            [80.50567513869674, 29.818671521873284],
            [80.50594101246782, 29.81878308367243],
            [80.5064610760179, 29.819035396566335],
            [80.5068240136174, 29.819334521870758],
            [80.50711151248635, 29.819664896817585],
            [80.50722445114758, 29.81990895843171],
            [80.50744426254272, 29.82025264604283],
            [80.50757226394887, 29.820724584172297],
            [80.50762870180318, 29.820959396259184],
            [80.50774207573625, 29.821311709268002],
            [80.50777945066119, 29.821469708460313],
            [80.50802745040659, 29.82174808370712],
            [80.50811220071756, 29.822073209311213],
            [80.50843651243514, 29.822469208687096],
            [80.50858409567951, 29.822641855137533],
            [80.50869376350641, 29.8227701470247],
            [80.50898963776166, 29.82310508423228],
            [80.50941995166949, 29.823325521555603],
            [80.51002307660377, 29.823587146030036],
            [80.51014741956783, 29.823613173309354],
            [80.51027538859836, 29.823639958717195],
            [80.51041957600268, 29.823712708474773],
            [80.51052464739462, 29.823763925764638],
            [80.51067601408681, 29.823837708843257],
            [80.510875853338, 29.823904280258375],
            [80.51145670116586, 29.824097772094547],
            [80.51149963749833, 29.82411370898052],
            [80.51153264711405, 29.824166757290072],
            [80.51155451233092, 29.82420189560105],
            [80.51161045106153, 29.82426977103313],
            [80.51172895202956, 29.824308521021464],
            [80.51188082593944, 29.82433389719165],
            [80.51205588886825, 29.824372896292175],
            [80.5122420134582, 29.82440064667253],
            [80.51225566606621, 29.824399071959647],
            [80.5122799504594, 29.82439627057147],
            [80.51251938865892, 29.82442427096339],
            [80.51328970115884, 29.824625584203375],
            [80.51377470104165, 29.82470408422614],
            [80.5141958256769, 29.824755208885847],
            [80.51449861661791, 29.824895591258553],
            [80.5145343259984, 29.824912146878148],
            [80.51510463916782, 29.825137520580142],
            [80.51534713776027, 29.825212895458833],
            [80.51562545095385, 29.825238646646255],
            [80.51591820096428, 29.825228521179383],
            [80.5159798890607, 29.825242270914146],
            [80.5161412004561, 29.82532402198609],
            [80.51635870059363, 29.825338395850338],
            [80.5166177628995, 29.82533933384326],
            [80.51685963916105, 29.825349209298622],
            [80.51713432538884, 29.82532089684196],
            [80.51736063798313, 29.825265334028018],
            [80.51740982550308, 29.825188834097446],
            [80.51791470040314, 29.82517939661193],
            [80.51865757548677, 29.825112146208653],
            [80.5189859503414, 29.825138208561555],
            [80.51952070161911, 29.825302520994626],
            [80.51982751252973, 29.825384897095375],
            [80.52012326357783, 29.825555145953274],
            [80.52040795116608, 29.825736646229302],
            [80.52066451335668, 29.825836772249318],
            [80.52102088860318, 29.825968897047005],
            [80.52164788873915, 29.82626445923745],
            [80.52237538901306, 29.82649714622636],
            [80.52309145080989, 29.826822333883626],
            [80.52348326384163, 29.827049271506723],
            [80.52412963897171, 29.82754339681037],
            [80.5241947633777, 29.82759320845986],
            [80.52437257553402, 29.827735958747553],
            [80.52483526413619, 29.82809402202156],
            [80.52502020162109, 29.82825033408568],
            [80.5253433891861, 29.82863727189266],
            [80.52564770088077, 29.829191084301158],
            [80.52578838902298, 29.829665208682513],
            [80.52584382593187, 29.830039833871297],
            [80.52578770104162, 29.830556209301676],
            [80.52566488872367, 29.830880583971805],
            [80.52552038745597, 29.83121614620825],
            [80.52534051405354, 29.831495208537092],
            [80.52525751292393, 29.83168214701351],
            [80.52535670095102, 29.83183139580234],
            [80.52552688865507, 29.83209364710416],
            [80.52577626256453, 29.83222539598529],
            [80.52611826394678, 29.83240033390831],
            [80.52720176355058, 29.832864333722057],
            [80.52743713771378, 29.83287870848568],
            [80.52763738885449, 29.832865895844463],
            [80.52805870054863, 29.83288770890067],
            [80.52866907581728, 29.832932771230446],
            [80.52891145210191, 29.833046396074394],
            [80.52911732580401, 29.833284022139935],
            [80.52930245034787, 29.83338389545048],
            [80.52957345115391, 29.833459334180986],
            [80.52989451362095, 29.833548458794382],
            [80.5300579510137, 29.833733959254857],
            [80.53011370088666, 29.83405220864256],
            [80.53011851405824, 29.834544020889894],
            [80.53017888734581, 29.834632272261615],
            [80.53039926351528, 29.83482020898549],
            [80.53064120093075, 29.835026333598478],
            [80.53088282538215, 29.83529333422007],
            [80.53103882538153, 29.83555558372325],
            [80.53115182699526, 29.83579958418352],
            [80.5312762005363, 29.83588802081556],
            [80.53156163905845, 29.835999521460792],
            [80.53173957711982, 29.836126583974703],
            [80.53187426408607, 29.836311958530132],
            [80.53195895054517, 29.836513021758606],
            [80.53207882567733, 29.836885645955874],
            [80.53252176336872, 29.837333833889375],
            [80.53257488812062, 29.837735458524662],
            [80.53291188837301, 29.83822170846514],
            [80.53330307547662, 29.83853439554599],
            [80.53365945072312, 29.83869127148506],
            [80.53399526297108, 29.838674396606166],
            [80.53422407568058, 29.83863233351542],
            [80.53478895154893, 29.83853958373487],
            [80.53520379172164, 29.838449106541077],
            [80.53531795076287, 29.83842420881024],
            [80.53556826356458, 29.83836189568501],
            [80.5359079509912, 29.838275209133656],
            [80.53607945080608, 29.83825089686144],
            [80.53630782554575, 29.838240458430448],
            [80.53666545084991, 29.83819208389758],
            [80.53700070192093, 29.838292396077293],
            [80.53731395107803, 29.83846727194708],
            [80.53734295061673, 29.838492209248102],
            [80.5375558884935, 29.83867339656007],
            [80.53769738872359, 29.83899870922312],
            [80.53775282563248, 29.839316958610823],
            [80.53782976263352, 29.8396713958183],
            [80.53800701361286, 29.839958458516094],
            [80.5381658887448, 29.840358333970016],
            [80.53820876302404, 29.840468958675558],
            [80.53819457531944, 29.840599772261726],
            [80.53822701296633, 29.84085477053128],
            [80.53821282616104, 29.841553959247847],
            [80.53823058597283, 29.841689675038538],
            [80.5382665759418, 29.841964709303966],
            [80.53827013905573, 29.842167770825995],
            [80.53836670106244, 29.842556083696365],
            [80.53855063760193, 29.842931145955674],
            [80.53884851374795, 29.84335402067154],
            [80.53920776412707, 29.84372970885903],
            [80.53938551333084, 29.843899521545723],
            [80.5394918257872, 29.844060020853306],
            [80.53954807568323, 29.844279021060004],
            [80.53954763771338, 29.84437827114033],
            [80.53956082537184, 29.844601646548824],
            [80.53959482604046, 29.844944647077853],
            [80.53955088876256, 29.84521970922225],
            [80.53956763773635, 29.845443083731368],
            [80.53963113886601, 29.845630522230863],
            [80.53984338876143, 29.8460485837752],
            [80.53988926317902, 29.846120896462253],
            [80.54019795187406, 29.846597895976174],
            [80.54045332606023, 29.846995834290396],
            [80.54062751304934, 29.84715883371325],
            [80.54079151251835, 29.84723383357459],
            [80.54095551288668, 29.8472975838165],
            [80.54140507588357, 29.847373583723936],
            [80.54163720079623, 29.84725933385124],
            [80.54186501345964, 29.847176646585012],
            [80.54226051371182, 29.84705395927284],
            [80.54246988847461, 29.847007333921397],
            [80.54313495061751, 29.84680664571016],
            [80.54363788747855, 29.846673020843298],
            [80.5441525757808, 29.846591333623223],
            [80.54459570143052, 29.84651845885986],
            [80.54500282542011, 29.84639577154769],
            [80.54530538883267, 29.84629302130594],
            [80.54537199891865, 29.84628542203467],
            [80.54540126285804, 29.84628208375119],
            [80.54550876331882, 29.846323083843288],
            [80.545652137736, 29.846366396991698],
            [80.54583138790827, 29.84643020928678],
            [80.54607007607319, 29.846491896483883],
            [80.54626107678735, 29.84660764552649],
            [80.54648432539136, 29.84680695867428],
            [80.54658295044288, 29.846895209146624],
            [80.5467437006613, 29.84703783352927],
            [80.54704788914881, 29.84722614616976],
            [80.54728026407298, 29.847414145846187],
            [80.54746074541714, 29.84762218151849],
            [80.54747063795963, 29.84763358402273],
            [80.54777195131459, 29.847828646974506],
            [80.5481773890753, 29.848163897146208],
            [80.54863051248822, 29.848463145657718],
            [80.54907170099824, 29.848823334029703],
            [80.54928026367327, 29.84897520883885],
            [80.54939638863249, 29.849070333728946],
            [80.54947376270405, 29.84919014680787],
            [80.54953282567942, 29.849357270521693],
            [80.54966363836627, 29.84953589656442],
            [80.54987757538987, 29.849800584129696],
            [80.5499968875464, 29.8498798971396],
            [80.55007595144406, 29.849923021430357],
            [80.55023045047494, 29.850097270472702],
            [80.55050201335729, 29.85019970864971],
            [80.55069613741688, 29.850268022051637],
            [80.55100688915667, 29.850287146134917],
            [80.55141563732184, 29.8501013336097],
            [80.5518636390957, 29.849951646851082],
            [80.55198576253298, 29.84991377190312],
            [80.55247670063926, 29.849863522283783],
            [80.55302082555073, 29.849842770427585],
            [80.55399020118688, 29.850006271672157],
            [80.55426466618144, 29.850218991912982],
            [80.55433220077049, 29.850271334254728],
            [80.55443320093156, 29.850407021267074],
            [80.55454007546416, 29.85059914613373],
            [80.5546526373094, 29.85081377113869],
            [80.55476557686995, 29.851028459096142],
            [80.55496763744657, 29.85126370915293],
            [80.55514845064056, 29.851629770997192],
            [80.55522488761852, 29.851977396739528],
            [80.55528901377704, 29.852327333739538],
            [80.55524645066322, 29.852509834061664],
            [80.55524088835637, 29.853042147277336],
            [80.55518001234776, 29.85334420886818],
            [80.55505338780364, 29.85352195897127],
            [80.55477732561314, 29.853775958993367],
            [80.5546089509424, 29.853953584090675],
            [80.55379557530563, 29.854564395530474],
            [80.55350966014277, 29.8548734340618],
            [80.55341070054345, 29.854980395828647],
            [80.55322720107455, 29.85522114613906],
            [80.55309420033711, 29.855595147198358],
            [80.55309276411981, 29.855924459146536],
            [80.55315057703757, 29.85631039600804],
            [80.55325732566513, 29.856518271601033],
            [80.55351863897408, 29.85697258391764],
            [80.55365845117666, 29.857171522048134],
            [80.55392051362088, 29.857382208921877],
            [80.55430832556885, 29.857622584214937],
            [80.55433369634306, 29.85764447371355],
            [80.55468370079222, 29.857946458862045],
            [80.55510626344335, 29.858444145480973],
            [80.55544876394936, 29.858826521027197],
            [80.55593070164065, 29.85928820868395],
            [80.55613220104033, 29.85958445975507],
            [80.55613070097115, 29.85991602090769],
            [80.55599145084483, 29.860400583720036],
            [80.55599707700355, 29.860664459197494],
            [80.55599245089093, 29.861201333774602],
            [80.55606988881436, 29.861530958686842],
            [80.55652145100424, 29.862051272248493],
            [80.55677070080725, 29.862241584081914],
            [80.5570845138393, 29.862405020575295],
            [80.55742876262735, 29.86259795842909],
            [80.55803070045653, 29.86299470873888],
            [80.55826795060551, 29.86311508389406],
            [80.55885357563204, 29.863536583546534],
            [80.55922295057877, 29.863704771158325],
            [80.55971601378309, 29.86392064622089],
            [80.56007857546592, 29.864082021468164],
            [80.560493575718, 29.8644014588603],
            [80.56089895142554, 29.8646870205896],
            [80.56094838805774, 29.864707520635648],
            [80.56127770090524, 29.86485070889313],
            [80.56190520106423, 29.865184396043105],
            [80.562183450406, 29.865322897034105],
            [80.56250245072755, 29.865545021485616],
            [80.56268951331043, 29.865676459201268],
            [80.56288070108354, 29.865832771265445],
            [80.56324307660674, 29.866111396523763],
            [80.56330582590323, 29.866411583927515],
            [80.56320870092088, 29.866659396613898],
            [80.56302907573132, 29.866961084086824],
            [80.56280176309093, 29.867158834212887],
            [80.56254057568708, 29.867338458503127],
            [80.56225607695643, 29.867511147221705],
            [80.56172526380993, 29.86763339656403],
            [80.56146420141181, 29.867709333518974],
            [80.56078013839272, 29.867797208974082],
            [80.56045082644454, 29.8678773970243],
            [80.56001513818813, 29.86815339716162],
            [80.5595893253871, 29.868300833816477],
            [80.55919701413097, 29.868576958959522],
            [80.55898545041828, 29.868865020804094],
            [80.55885670077618, 29.86908339598199],
            [80.5586867630837, 29.86924964555476],
            [80.5584632635688, 29.869598584307255],
            [80.5584381383095, 29.86996785763057],
            [80.55843763918574, 29.86997520868897],
            [80.55849501413365, 29.870241584281757],
            [80.5586247008693, 29.87062327184657],
            [80.55939545043975, 29.871557395857167],
            [80.55973683848435, 29.87193411466768],
            [80.55984657555905, 29.872055209280518],
            [80.56028357592635, 29.872449146510974],
            [80.5605047003317, 29.872785958805025],
            [80.56085057599336, 29.873229271513708],
            [80.56109783289872, 29.873642985735273],
            [80.56138820060505, 29.87412883367881],
            [80.5615791383666, 29.874339210286394],
            [80.56190732706153, 29.874615521589078],
            [80.56219663896377, 29.874828520619758],
            [80.56221336005859, 29.874837323183954],
            [80.56305913736935, 29.87528252087168],
            [80.56337832564924, 29.875466209198237],
            [80.563611450608, 29.875505334203865],
            [80.56424313890977, 29.875850333925825],
            [80.56445713888593, 29.876042770857225],
            [80.56519945099393, 29.876791897128385],
            [80.56522770049804, 29.876821271684378],
            [80.56551795219178, 29.87713127159094],
            [80.5658142635175, 29.87735558409298],
            [80.56623370192386, 29.877632209259104],
            [80.56661888785146, 29.877971770780675],
            [80.56694157539346, 29.878390147087657],
            [80.5673365756225, 29.878858334144866],
            [80.56758263912758, 29.879132084178366],
            [80.56763088865466, 29.87918639603538],
            [80.5676547638563, 29.87922258385521],
            [80.56767301379858, 29.879328646300053],
            [80.56775895031535, 29.87950489723329],
            [80.56793107605836, 29.87973102096987],
            [80.56803557548147, 29.87996595896186],
            [80.56815763866416, 29.880243771233097],
            [80.56818601407338, 29.880422084311817],
            [80.56831201358864, 29.880837521634362],
            [80.56849970120032, 29.88136377162084],
            [80.56866688786664, 29.881840209058453],
            [80.56877626251458, 29.882440645919132],
            [80.56887595056475, 29.882637208940082],
            [80.56908226403533, 29.883059771591263],
            [80.56955145113864, 29.884037958784802],
            [80.56957520043522, 29.88415983400921],
            [80.56961413748252, 29.88436070927935],
            [80.56970570105733, 29.884818896774732],
            [80.5697221388657, 29.885285895827508],
            [80.56986726246424, 29.886071334024905],
            [80.57036882606053, 29.886977521495453],
            [80.57050976241567, 29.88715845879591],
            [80.57072913763966, 29.887763708828174],
            [80.57079726398263, 29.887878959646343],
            [80.57082645058028, 29.887948960176402],
            [80.57088063833083, 29.888059709887727],
            [80.57092395057992, 29.888138771986803],
            [80.57094426356696, 29.888159146127748],
            [80.57101201399331, 29.888430021028682],
            [80.57109757549279, 29.888705522042244],
            [80.5713084503248, 29.88918895890339],
            [80.5715895756984, 29.889740209143383],
            [80.57167045083071, 29.89004952196791],
            [80.57176801378284, 29.89023027220935],
            [80.57178223656103, 29.890273238219436],
            [80.5719990127443, 29.890928084063887],
            [80.5719881381421, 29.891428770821165],
            [80.57216513731129, 29.891659458617198],
            [80.5722570759034, 29.891946209250193],
            [80.57252263760972, 29.892359896492053],
            [80.57277763857724, 29.892656270770317],
            [80.57305995105594, 29.892932333860188],
            [80.57332601368466, 29.89329633355902],
            [80.57363488853935, 29.893793584006744],
            [80.5737649511916, 29.894026333948204],
            [80.57383232570135, 29.89416420901108],
            [80.57399863732729, 29.89455270894041],
            [80.57411082595394, 29.89481470843208],
            [80.5741126353899, 29.894817047568722],
            [80.57424726390019, 29.89499108347178],
            [80.57437201335779, 29.895266708591805],
            [80.57441188749868, 29.895564583838507],
            [80.57439195042821, 29.8959028341485],
            [80.5742716391249, 29.896261146534755],
            [80.57415357612672, 29.896635209647286],
            [80.5739038271999, 29.897216333566973],
            [80.57358901412175, 29.89856420867136],
            [80.57343114173386, 29.898949203942664],
            [80.57318807566924, 29.89954195879443],
            [80.57318424365798, 29.90044624419886],
            [80.57318388842577, 29.900529895538284],
            [80.57318251246306, 29.900852458973873],
            [80.57315813903693, 29.901026083886734],
            [80.5729995993521, 29.901565810214038],
            [80.57282120083778, 29.9021731457741],
            [80.57276588893467, 29.902389520859742],
            [80.57279604859883, 29.902614864884185],
            [80.57281451347905, 29.90275283347654],
            [80.57287276346733, 29.90306658355604],
            [80.57293238851895, 29.90357883379619],
            [80.57302651246363, 29.904039270496014],
            [80.57309832602698, 29.90435077227039],
            [80.57306013901427, 29.90473864717086],
            [80.57291595071064, 29.905302084123946],
            [80.57287845077991, 29.90553439609556],
            [80.5725252636301, 29.906230208757165],
            [80.57241013781766, 29.90642608379676],
            [80.57208745117498, 29.90702952079579],
            [80.57180320155658, 29.90773464683656],
            [80.57163068910506, 29.908161528032167],
            [80.57157651394505, 29.908295583674317],
            [80.57144913846702, 29.90864258528711],
            [80.57142938845556, 29.909016958665745],
            [80.57158295219153, 29.90943927130536],
            [80.57163954742725, 29.909560502615136],
            [80.57168945080758, 29.90966739693289],
            [80.57177445113007, 29.91014139720778],
            [80.57187657544364, 29.91033570922565],
            [80.57203488760001, 29.910776083849214],
            [80.57212451403512, 29.911155334251305],
            [80.57227113860222, 29.91169270885149],
            [80.57237020072427, 29.91206064668154],
            [80.57240620058576, 29.912281834938767],
            [80.57242570058565, 29.9124037092638],
            [80.57247938831318, 29.91271745844398],
            [80.57258007640945, 29.91324108420872],
            [80.57257926342231, 29.913430583954323],
            [80.5725873258445, 29.913895271749425],
            [80.57266870099983, 29.91425189700749],
            [80.57265714651015, 29.9143598390354],
            [80.57264238863536, 29.914497708702356],
            [80.57275601257999, 29.91487702115768],
            [80.57274351290283, 29.915149896150865],
            [80.57288132681185, 29.915545147290743],
            [80.57299863797681, 29.91589064703578],
            [80.57308888764209, 29.916206709272274],
            [80.5731362639275, 29.91633095870685],
            [80.57329538817169, 29.916581833584814],
            [80.57331363631533, 29.916600908205396],
            [80.57355513845891, 29.91685333351461],
            [80.57366851419062, 29.91706577226762],
            [80.57402945079855, 29.9177368337879],
            [80.57417501416563, 29.917915521883856],
            [80.57434351384217, 29.91810783380953],
            [80.57462295208762, 29.918539520982165],
            [80.57481788913435, 29.91897777050775],
            [80.57502732595037, 29.920113083651927],
            [80.57529182645669, 29.92095077156091],
            [80.5753611390054, 29.92116983382084],
            [80.57539607586824, 29.921395522285593],
            [80.57551926410281, 29.921968833794722],
            [80.57571457706615, 29.922470208533355],
            [80.5757797635253, 29.922668958705515],
            [80.57582632592425, 29.92290820894675],
            [80.57592036533265, 29.923246433176416],
            [80.57598451397422, 29.923477147052722],
            [80.5762789655019, 29.92395811887212],
            [80.57646738875906, 29.924265896553948],
            [80.57650656772398, 29.924369757458408],
            [80.57655601334938, 29.92450083454594],
            [80.57668919574985, 29.924700057761527],
            [80.5768355757013, 29.924919021995322],
            [80.57702076319771, 29.92506620863867],
            [80.57734863802926, 29.92538989712608],
            [80.5780023884995, 29.925797959108593],
            [80.57825870067853, 29.92604014653557],
            [80.57863420090769, 29.926431583650697],
            [80.57890282570492, 29.926603833500167],
            [80.57952713866655, 29.927557772273417],
            [80.57995076341706, 29.92820652071441],
            [80.57995001338247, 29.92838470878735],
            [80.58005238860693, 29.928836146870708],
            [80.58024645061329, 29.92909389706574],
            [80.58032213845598, 29.929342270929112],
            [80.58038607665617, 29.929764333557216],
            [80.58050113861674, 29.93020227191738],
            [80.58050657591781, 29.930364709263984],
            [80.58046945100443, 29.930651083980365],
            [80.58040888885921, 29.93080652190349],
            [80.58038976297723, 29.931045522133218],
            [80.58050983056427, 29.931610020286257],
            [80.58054532590614, 29.931776896686472],
            [80.58054445086577, 29.931995708934892],
            [80.58069932581327, 29.932190146857863],
            [80.58084220110675, 29.932389146142214],
            [80.58100588851102, 29.932509147179474],
            [80.5810390761925, 29.93253414653367],
            [80.58125351413844, 29.932821272184015],
            [80.58135336406656, 29.932947455160956],
            [80.58157201353765, 29.933223770960296],
            [80.58180463847333, 29.933481709113664],
            [80.5819227635248, 29.93380464666717],
            [80.58204207568133, 29.933920021591803],
            [80.58228020087063, 29.934263647149635],
            [80.58233896796906, 29.93443236805939],
            [80.58236595032838, 29.934509833861796],
            [80.58253813902394, 29.934832895521765],
            [80.58277632716585, 29.935158459095646],
            [80.58284138861927, 29.93539327208191],
            [80.58293870066058, 29.935743209081863],
            [80.58297357547019, 29.93598245842378],
            [80.58310363812245, 29.936325708964375],
            [80.58337182674848, 29.936682959251243],
            [80.58392938843059, 29.937210272236314],
            [80.58407245078303, 29.937373146653442],
            [80.58436426280053, 29.937739521461822],
            [80.5845040138492, 29.93792264681275],
            [80.58468920044629, 29.938067584251655],
            [80.58498132542786, 29.938355021966743],
            [80.58502951020381, 29.938397656126995],
            [80.58512457663795, 29.938481770617273],
            [80.58534532602602, 29.9386538972596],
            [80.58539270051278, 29.938780396797938],
            [80.58549023288799, 29.93902814023653],
            [80.58549938888575, 29.939051396704656],
            [80.58552111200976, 29.93907039758085],
            [80.58563651391404, 29.939171333889988],
            [80.58581563908052, 29.939302770706377],
            [80.58599476334768, 29.939427459010062],
            [80.58642113912435, 29.939825710288346],
            [80.58665926251501, 29.940081333586704],
            [80.58687388841929, 29.94034820920257],
            [80.58703513776146, 29.94049083358516],
            [80.5871779510017, 29.940642397228885],
            [80.58729695109349, 29.940839020504427],
            [80.58735288892473, 29.941085084009444],
            [80.58742420066636, 29.941225146223587],
            [80.58752532673248, 29.941356271874497],
            [80.58755745051599, 29.941415021885803],
            [80.58761463840489, 29.94151895923261],
            [80.5877159506307, 29.94162302068588],
            [80.58785901298313, 29.941781396694125],
            [80.58796513838047, 29.941867459115997],
            [80.58809163881813, 29.941969334317378],
            [80.588204763639, 29.942105022229043],
            [80.58847601355723, 29.942423958698782],
            [80.58867820093826, 29.942783209077902],
            [80.58894620070663, 29.943117897173295],
            [80.58918995115539, 29.943526959201847],
            [80.58938951341537, 29.94373508390703],
            [80.58967576312602, 29.944042771656655],
            [80.59004295092149, 29.944348397260796],
            [80.59027888895963, 29.94516570923014],
            [80.59046938875133, 29.94544827172041],
            [80.59058845089635, 29.94562683391132],
            [80.5907789506881, 29.945918395917317],
            [80.59105220069858, 29.946415520459936],
            [80.5912090136851, 29.946842334206394],
            [80.59121673796216, 29.94686467066805],
            [80.59129801419203, 29.947099708484814],
            [80.59139882549545, 29.947282771782568],
            [80.5916070140525, 29.94762177122783],
            [80.59175613873487, 29.94781395904704],
            [80.59216963801845, 29.948437771985652],
            [80.59231863769503, 29.948582646472005],
            [80.59272445047304, 29.948965084970723],
            [80.59307045114048, 29.94922108418575],
            [80.59384882606253, 29.9500219592461],
            [80.59411151263623, 29.95016264648899],
            [80.59445883440776, 29.950345885154547],
            [80.59457163817063, 29.950405396891597],
            [80.59479845168727, 29.950597833822997],
            [80.59530870093585, 29.951007708838745],
            [80.5954877010966, 29.951188709091696],
            [80.59604251265188, 29.951706958709224],
            [80.59647807590255, 29.95210527114142],
            [80.59660878696599, 29.952295413002958],
            [80.59668632651284, 29.952408208671955],
            [80.596986638023, 29.952941458981172],
            [80.59711795163224, 29.953056895959037],
            [80.59729970102046, 29.95312058504703],
            [80.59737501384586, 29.95314564555514],
            [80.59762620078857, 29.953241147261167],
            [80.59779295038442, 29.953401833627765],
            [80.59788263887276, 29.95349233420393],
            [80.598306388629, 29.953840959093043],
            [80.59927670045943, 29.95451164559603],
            [80.6000350142046, 29.955093647254046],
            [80.6004173888515, 29.955270708476462],
            [80.60047206673255, 29.955301887072494],
            [80.6012208260803, 29.955728833918613],
            [80.60141195090085, 29.955873770458197],
            [80.60203901309006, 29.956320021252054],
            [80.60217452743257, 29.956528355499245],
            [80.60217870118618, 29.95653477216206],
            [80.6025308253374, 29.956905709231137],
            [80.60313382616522, 29.95730227068327],
            [80.60394001352086, 29.957904708535523],
            [80.6044865126425, 29.958260520806448],
            [80.60467145192604, 29.958412209455958],
            [80.60532282548809, 29.958770583895387],
            [80.60566370091914, 29.9588911470089],
            [80.60604038735403, 29.959023083848308],
            [80.60647375446024, 29.959035408157604],
            [80.60666013805496, 29.959040708761734],
            [80.60727070038257, 29.95907864666225],
            [80.60773801239941, 29.959068772106207],
            [80.6080733255236, 29.959033647285025],
            [80.60840282543012, 29.95890833395248],
            [80.6089322626139, 29.95883095988097],
            [80.60951576254246, 29.958670271715732],
            [80.60994076235636, 29.958556458913506],
            [80.6104313883979, 29.958618834091965],
            [80.61076907573232, 29.95871458401092],
            [80.61100226274431, 29.958771646894036],
            [80.61127957589179, 29.958837834298663],
            [80.611335887841, 29.958851584033425],
            [80.61176551376752, 29.959044583940454],
            [80.61226788765293, 29.959183647007762],
            [80.61287707581647, 29.959235083732153],
            [80.61306226241356, 29.959255958795495],
            [80.61335313913611, 29.959396708990994],
            [80.61364288810887, 29.959835083522364],
            [80.61398638866098, 29.960045896301096],
            [80.61440895041278, 29.960277209125934],
            [80.61501820062949, 29.960324083589626],
            [80.61546782567967, 29.96046302075183],
            [80.61586445098368, 29.960649147140487],
            [80.61652626297672, 29.960743584049226],
            [80.61745201339977, 29.96102139721978],
            [80.61800757588895, 29.961140272305784],
            [80.6185363259907, 29.961417021578313],
            [80.61901170042967, 29.96176577147321],
            [80.61930188737227, 29.962089209949113],
            [80.61964482584807, 29.962433084619192],
            [80.62014676356233, 29.962759270523975],
            [80.62041126406865, 29.962875147270267],
            [80.62086057705403, 29.963083959057542],
            [80.62096913871483, 29.963072958550242],
            [80.62116095061742, 29.96312989552831],
            [80.62204188782295, 29.963443708560362],
            [80.62277613750882, 29.96375939577956],
            [80.6229785470224, 29.963880520969326],
            [80.62316257619199, 29.963990646551167],
            [80.62365713856616, 29.964143083436625],
            [80.62423051392716, 29.964257521267655],
            [80.62453970894046, 29.96420607105341],
            [80.62501663830727, 29.964126708580807],
            [80.62533213756814, 29.964102772225203],
            [80.62566951373714, 29.96403614685073],
            [80.6259283260315, 29.96393077058866],
            [80.62609376351645, 29.96378014673644],
            [80.62621595080554, 29.963744396886455],
            [80.62648157546437, 29.96372483483333],
            [80.62691176346715, 29.963757583645645],
            [80.62750420085825, 29.96408183420931],
            [80.6281602625861, 29.964446896906793],
            [80.62879688816594, 29.964728397297733],
            [80.62982438878458, 29.964729021427274],
            [80.63025438882903, 29.964836271876493],
            [80.6304981374791, 29.964873021772576],
            [80.63074735400721, 29.964981756103214],
            [80.63077388850422, 29.964993333975258],
            [80.63118926377354, 29.965118583455933],
            [80.63155507560629, 29.96515796027171],
            [80.63198538861485, 29.965177208461455],
            [80.63273176385985, 29.965111584032456],
            [80.63323070053644, 29.96503852131076],
            [80.63366151356803, 29.964926958612295],
            [80.63392720117946, 29.964889333675842],
            [80.63465926281481, 29.964747022257313],
            [80.63487470080685, 29.964736333814812],
            [80.63542763907435, 29.964512334276833],
            [80.63598807588903, 29.96427702216687],
            [80.63623611700325, 29.96416530658371],
            [80.63659532601355, 29.96400352214488],
            [80.63681921853214, 29.963954017164212],
            [80.63711938884876, 29.963887647197225],
            [80.63775882570928, 29.963657083507655],
            [80.63795976303271, 29.963608020993433],
            [80.63896557560088, 29.96317989693472],
            [80.63967326291083, 29.962868334006487],
            [80.64016951331246, 29.96256964667191],
            [80.64092438894943, 29.96211158418231],
            [80.64119432675682, 29.96178745862437],
            [80.6413535760068, 29.96144958423099],
            [80.64137676232775, 29.96130302081781],
            [80.6417442010341, 29.960532522158246],
            [80.64218351265902, 29.96002170903472],
            [80.64243195037426, 29.959729146982625],
            [80.64287057671578, 29.959498021216746],
            [80.64324407595342, 29.9593681456235],
            [80.64356663848963, 29.959357771943644],
            [80.64396876314794, 29.959288958518698],
            [80.64436370042449, 29.959134333582654],
            [80.64456526367604, 29.95901539644342],
            [80.64491026249868, 29.958792959027903],
            [80.64518376252067, 29.958550083619514],
            [80.64534551278524, 29.958412958591225],
            [80.64565463855081, 29.95827614562768],
            [80.64607120092523, 29.95805845843114],
            [80.64635188832904, 29.95779758399135],
            [80.6466754509114, 29.95759089730211],
            [80.64677313886932, 29.95753477151254],
            [80.64743020064333, 29.95716208346346],
            [80.64780776301791, 29.956887897258696],
            [80.64808107598094, 29.956701396752123],
            [80.64831113874811, 29.95655314621081],
            [80.64874195088038, 29.956416709163875],
            [80.64910820068297, 29.956336459959687],
            [80.64971845094578, 29.956175708841954],
            [80.65004901295163, 29.956014146535665],
            [80.65033976376913, 29.95592695906197],
            [80.65075826238393, 29.95588970914281],
            [80.6510212637204, 29.955865584828928],
            [80.65131570085896, 29.955855083445385],
            [80.65183938777756, 29.95583170916609],
            [80.6522410753654, 29.955783146674946],
            [80.65244195153491, 29.955752084990763],
            [80.6530737639431, 29.95562964679084],
            [80.65328170069, 29.95561670914384],
            [80.65404601381255, 29.95548783449601],
            [80.65457726402957, 29.95543964702216],
            [80.65508620116731, 29.955465771428237],
            [80.65602213731182, 29.955524583492775],
            [80.65621838736877, 29.955525146468403],
            [80.65699863737728, 29.955276771705712],
            [80.65735770069739, 29.95519652160226],
            [80.65765176281866, 29.955183708961044],
            [80.65813938872185, 29.95518502107194],
            [80.65853401303434, 29.955210834312595],
            [80.65902807538544, 29.95542639731042],
            [80.65931795116256, 29.955607583723065],
            [80.65963295040041, 29.955813709235372],
            [80.65989063764289, 29.955915896501494],
            [80.6603278880217, 29.95609070851941],
            [80.66055695074272, 29.95614764729612],
            [80.66086551263334, 29.95614845848462],
            [80.66160463754403, 29.956100708980614],
            [80.66189870056462, 29.95606308404416],
            [80.66256970093099, 29.955976896616562],
            [80.66301973067607, 29.95598950960823],
            [80.66310038817244, 29.95599177050383],
            [80.66351613755978, 29.95608083396337],
            [80.66365542365895, 29.956118552429302],
            [80.66395326383213, 29.95619920902635],
            [80.66434732606831, 29.95638745871429],
            [80.66478407552472, 29.95661414722514],
            [80.66506713893739, 29.95669608355746],
            [80.66549013865904, 29.95676020971598],
            [80.66592745109108, 29.956797521688316],
            [80.66620726345451, 29.956786897097686],
            [80.66680263803136, 29.956838083810567],
            [80.66743357539923, 29.956878021803334],
            [80.66798926379357, 29.956971896635764],
            [80.66855582589073, 29.957029708654204],
            [80.66933205053374, 29.95710359155754],
            [80.66935888810224, 29.957106145632167],
            [80.66942076775427, 29.95711854098795],
            [80.66980351382114, 29.95719520909165],
            [80.67028376258565, 29.95729570922964],
            [80.67078901250301, 29.957430084131147],
            [80.67128395079374, 29.957410897095258],
            [80.67307863786891, 29.957386146853253],
            [80.67313113849133, 29.9573794585952],
            [80.67337520100477, 29.957298895527686],
            [80.67378482600895, 29.957105896519977],
            [80.67402895057569, 29.95701177167598],
            [80.67445251237359, 29.956913583694984],
            [80.6748975140091, 29.956896646762914],
            [80.67520538791837, 29.95701014660102],
            [80.67554907552949, 29.957184709506805],
            [80.67583626413233, 29.95719220895336],
            [80.67613701271301, 29.95731020899899],
            [80.67736801372291, 29.95796289647052],
            [80.67765870068854, 29.957986145744087],
            [80.6779502006413, 29.958074835085597],
            [80.67821007593432, 29.9583213338625],
            [80.67855432562169, 29.958610896675566],
            [80.67878420043058, 29.958731020919913],
            [80.67910607678408, 29.958831021034825],
            [80.67943601286174, 29.958852146109734],
            [80.67966563855845, 29.958906833883304],
            [80.67997970070269, 29.95916027182915],
            [80.68035426383824, 29.95948370850641],
            [80.68063738840482, 29.959764145898646],
            [80.68071732554421, 29.959978583844645],
            [80.68079288748186, 29.960244958538055],
            [80.68086863917642, 29.960551897152357],
            [80.68105238775752, 29.960705772053814],
            [80.68122851368503, 29.96082120993094],
            [80.6815426378825, 29.960934770923018],
            [80.68177251359072, 29.961061647277234],
            [80.68204045040653, 29.961204396665607],
            [80.68242263889374, 29.961597771819754],
            [80.6831847010132, 29.96206883400953],
            [80.68320295455277, 29.962079571914785],
            [80.68384313874992, 29.962456146833745],
            [80.68404201302855, 29.96264383354611],
            [80.68435938917452, 29.96295814570192],
            [80.68443551318842, 29.96314552214818],
            [80.68448801291152, 29.96355396004725],
            [80.68449041679935, 29.963577050140827],
            [80.6845103259908, 29.963768272088146],
            [80.68462270077708, 29.964555708579155],
            [80.68474382596685, 29.965117772267433],
            [80.68493613789252, 29.966133271433364],
            [80.68525670033648, 29.966668646840617],
            [80.68543620052031, 29.96694202185688],
            [80.68568857546745, 29.967211021671346],
            [80.68593338801548, 29.967437209259856],
            [80.68607870047168, 29.96757289717152],
            [80.68629763412855, 29.96771909006401],
            [80.68672957580935, 29.968007521529955],
            [80.68730357619916, 29.9683698341006],
            [80.68793882581622, 29.968932958988944],
            [80.68826370050942, 29.969226959956302],
            [80.68859257538708, 29.969595458963283],
            [80.6887149506345, 29.96971527114289],
            [80.68916601370063, 29.970250958614884],
            [80.68929195116266, 29.970472270978576],
            [80.68930563794487, 29.97096633422899],
            [80.68941493165386, 29.971340457596114],
            [80.68944213794435, 29.971433585091916],
            [80.6898014503767, 29.971921646266992],
            [80.69021445053653, 29.97230383385488],
            [80.69076882681998, 29.972966021764535],
            [80.69092920112178, 29.97315364642367],
            [80.69141145087781, 29.973576584092143],
            [80.69157957553705, 29.97377777142708],
            [80.69177001417489, 29.974184209233897],
            [80.69234401366538, 29.974679583695888],
            [80.69315951260148, 29.975209271790504],
            [80.69354995146915, 29.97547177220457],
            [80.69384095049952, 29.97563270858268],
            [80.69410145082134, 29.975813770888863],
            [80.69449004517952, 29.975990543428907],
            [80.69451513806325, 29.976001958523625],
            [80.69480982611265, 29.976203395870073],
            [80.69484743036469, 29.976248426723544],
            [80.6952684506785, 29.97675258396464],
            [80.69576613909612, 29.97709433353674],
            [80.69660095097316, 29.977583459509106],
            [80.69681210189702, 29.97766609461462],
            [80.69726770114357, 29.977844396901446],
            [80.69761257585975, 29.97797152056927],
            [80.69783845048414, 29.978120897061785],
            [80.69818257606511, 29.97851420926338],
            [80.69826921675099, 29.978717520796977],
            [80.69829707594937, 29.97878289611384],
            [80.69855657532577, 29.97920308365542],
            [80.69878582600512, 29.979512583538963],
            [80.6990003260043, 29.979598833919113],
            [80.69936838794081, 29.979680772050017],
            [80.69987463790426, 29.979774583929952],
            [80.70026601296621, 29.97973477184229],
            [80.70056920050826, 29.97972195920113],
            [80.70109113734617, 29.979671208659397],
            [80.70135945097792, 29.979723709281814],
            [80.7013715360676, 29.979726346993345],
            [80.70173538737828, 29.97980577151918],
            [80.70195676269452, 29.979826583629972],
            [80.70204182596956, 29.979878647181863],
            [80.70224095205833, 29.98000089652419],
            [80.70255988762875, 29.980466333454558],
            [80.70270813817012, 29.980708021757835],
            [80.70275802086604, 29.98085594764393],
            [80.70282763738561, 29.981062396012817],
            [80.70292870049917, 29.981267896496263],
            [80.70296570040682, 29.981344708491577],
            [80.70310007530827, 29.981577395480485],
            [80.70322382561909, 29.981789647174537],
            [80.70339488836345, 29.98198852145316],
            [80.70367170058859, 29.98237264708007],
            [80.70376138817755, 29.982589397183006],
            [80.70390638856901, 29.982736334714104],
            [80.7041792635622, 29.983136209268707],
            [80.7043175756956, 29.98333727159786],
            [80.70453901306507, 29.983707708643863],
            [80.70514188888711, 29.984327083428184],
            [80.70540182533404, 29.984605146610306],
            [80.70566863799729, 29.984889897151106],
            [80.70608401416598, 29.98548408372352],
            [80.70628701363484, 29.985730459293336],
            [80.70682588920346, 29.986106083628897],
            [80.70705713907574, 29.9862058337323],
            [80.70734851312346, 29.986348584019993],
            [80.70777888908452, 29.98663602173508],
            [80.70808432583101, 29.986742709208727],
            [80.70859163879317, 29.9868205221494],
            [80.70908751237886, 29.986844210292134],
            [80.70941188884763, 29.986808833660803],
            [80.7096687010498, 29.98693114595568],
            [80.70985338762381, 29.987233833474704],
            [80.71020007537322, 29.987536896010965],
            [80.71028245057471, 29.987848334832847],
            [80.7103324510818, 29.98825220867252],
            [80.7107135136178, 29.988652334137953],
            [80.71108257560041, 29.989165208507643],
            [80.71147632577186, 29.989319458426337],
            [80.71193926258695, 29.989417397295085],
            [80.71258082544477, 29.98957220929009],
            [80.71303451363195, 29.989717583799518],
            [80.71344632578734, 29.989932771780047],
            [80.71370107584403, 29.989989708758117],
            [80.71406426345504, 29.990189021006586],
            [80.71432295074362, 29.99045802172037],
            [80.71447570059314, 29.99062527223856],
            [80.71454476313039, 29.990717959066615],
            [80.71479420089173, 29.991052272144714],
            [80.71488838868822, 29.991318646838124],
            [80.71498926294419, 29.991700085290745],
            [80.71523801362349, 29.992163021206522],
            [80.7156050755139, 29.992716458597727],
            [80.71584976395548, 29.993019272021854],
            [80.71607182545443, 29.993202458526696],
            [80.71651545112724, 29.99358233395759],
            [80.71669807555583, 29.99373614680576],
            [80.71681332547467, 29.993986771672212],
            [80.71691451269464, 29.994289209179726],
            [80.71720526261282, 29.994673333907258],
            [80.71739726247375, 29.995057208623336],
            [80.71739607536864, 29.995138395820334],
            [80.71740220065112, 29.995251209475782],
            [80.717452326164, 29.995524271527984],
            [80.71748701301527, 29.995740895725817],
            [80.71755370044298, 29.995878583729734],
            [80.71757107534489, 29.9959170837065],
            [80.71763695068478, 29.996097583936375],
            [80.71785370078771, 29.996420645596345],
            [80.7179832004644, 29.996563021766065],
            [80.71822357575746, 29.996800396920833],
            [80.71863963900819, 29.99721402210946],
            [80.71894620080656, 29.99772221011193],
            [80.71921420057498, 29.998074709280445],
            [80.71947726396462, 29.998397896845518],
            [80.72002988746937, 29.99887495931199],
            [80.72030720061684, 29.999229583578483],
            [80.72057245115775, 29.99959339711762],
            [80.72071576352175, 29.999776396563504],
            [80.72091995099498, 29.999973084590238],
            [80.72093282568943, 30.000006958454435],
            [80.72109920116725, 30.000183271440903],
            [80.72131195108574, 30.000325833770262],
            [80.7218118257552, 30.000543396860337],
            [80.72199245099085, 30.000615958659637],
            [80.72228907618, 30.000657208763243],
            [80.72271307594775, 30.000676146686885],
            [80.72330663839068, 30.00063902177351],
            [80.72361720037355, 30.000560770862933],
            [80.72373910617489, 30.00050632230898],
            [80.72397920087946, 30.000399083550917],
            [80.72419038777622, 30.0003025215442],
            [80.72462651310315, 30.000145583551898],
            [80.72498426341309, 30.00000870943444],
            [80.7256489505387, 29.999913020669396],
            [80.72586476354803, 29.99988195988459],
            [80.72619857570379, 29.99984658415258],
            [80.72682188772, 29.999845646159713],
            [80.72727826308142, 29.99984883425634],
            [80.7276558884086, 30.000018771049497],
            [80.72799845096779, 30.00008039619337],
            [80.72850551301906, 30.000300271440437],
            [80.7287529956543, 30.000385506485998],
            [80.7289268876658, 30.00044539683762],
            [80.72918182568077, 30.00044602096716],
            [80.72958032697056, 30.00043995863723],
            [80.72986545073002, 30.000420333631553],
            [80.73040338830577, 30.000356021313394],
            [80.73071876346023, 30.00030477164796],
            [80.73127982620309, 30.000208958776454],
            [80.73176195095334, 30.000169333747806],
            [80.7324503253223, 30.000057959906997],
            [80.73250676407594, 30.00004677144142],
            [80.73270251231116, 30.000011084543985],
            [80.73328988831776, 29.999865647082004],
            [80.73350313735995, 29.999834521546006],
            [80.73390451288304, 29.99971352226129],
            [80.73426588836014, 29.999666896909844],
            [80.73485945080307, 29.999627458940154],
            [80.73534620076663, 29.99957202113194],
            [80.73609020090208, 29.99949233400406],
            [80.73664207617094, 29.999432521893425],
            [80.73700582585826, 29.9994490838082],
            [80.73744151231602, 29.999465709574906],
            [80.73844463860922, 29.999506083738822],
            [80.73870413798562, 29.999522333588857],
            [80.73932057558414, 29.999528083854045],
            [80.73982138914585, 29.999531334003905],
            [80.7403215758801, 29.999690209135906],
            [80.7406151379783, 29.99990058394485],
            [80.74076970086105, 30.000000146089917],
            [80.74103170035272, 30.00016758366712],
            [80.74154057543728, 30.00055658451879],
            [80.74171626249557, 30.000653896560152],
            [80.74201926387798, 30.000859772060892],
            [80.74234757578006, 30.001167083893847],
            [80.74250495084289, 30.001332147260825],
            [80.74257520048519, 30.00148339704117],
            [80.74259707559463, 30.00153083358117],
            [80.74273538862735, 30.001788270812085],
            [80.74299195081795, 30.002059458677138],
            [80.7433060129622, 30.002490895838207],
            [80.74365913895815, 30.003005835051283],
            [80.74376101236089, 30.003057958857767],
            [80.74397407614276, 30.003114771729372],
            [80.74429863877123, 30.00314245915723],
            [80.74458088739812, 30.003233208845586],
            [80.74506751235589, 30.003358209214014],
            [80.74538713860562, 30.00340845883335],
            [80.74560720091165, 30.003485584692044],
            [80.7459404509911, 30.00365764658318],
            [80.7464215756952, 30.004010459615074],
            [80.74683307668522, 30.0043857089334],
            [80.74712620171289, 30.004790084594788],
            [80.74725582549598, 30.004916647085622],
            [80.74765370085765, 30.005167771975152],
            [80.74789426410905, 30.005337395804247],
            [80.74806570097138, 30.005457271835667],
            [80.74846788768292, 30.005785145767902],
            [80.74870395072679, 30.005954772294956],
            [80.74907863886813, 30.006287021428932],
            [80.74940701282344, 30.006580896491243],
            [80.75001151282112, 30.007258771275303],
            [80.75001195079096, 30.007247146638576],
            [80.7504602007777, 30.00791145874689],
            [80.75060007593282, 30.008200521536878],
            [80.7508452005456, 30.00850777131666],
            [80.75117763763791, 30.008889584786573],
            [80.75137020047441, 30.00922602206333],
            [80.75133445062443, 30.009410958648857],
            [80.75152901265386, 30.009722584529698],
            [80.75170151341416, 30.010189833593984],
            [80.75196320084109, 30.010894146647615],
            [80.75205032536229, 30.011185271583088],
            [80.75227226545275, 30.01152393378254],
            [80.75233007567249, 30.01161214558408],
            [80.75266295073465, 30.011964646551235],
            [80.75301338775768, 30.012285584012545],
            [80.7532759511243, 30.012608646571834],
            [80.75332826288906, 30.012823021565225],
            [80.75337938754876, 30.013357708991123],
            [80.75339657539172, 30.013632959093854],
            [80.75364076381027, 30.014197334039807],
            [80.75376307610514, 30.01452014568821],
            [80.75388520044169, 30.014795521696044],
            [80.75390232533209, 30.014946709423157],
            [80.75400713771927, 30.01528302079481],
            [80.75426926311604, 30.01578877163456],
            [80.75444445105063, 30.01597189698549],
            [80.75460201407174, 30.016170584205156],
            [80.7547410753404, 30.016707708793774],
            [80.75474070032311, 30.016858833568392],
            [80.75479326299876, 30.016951397189302],
            [80.75482788779686, 30.01710489707341],
            [80.7550198256045, 30.01771652150029],
            [80.75512457593851, 30.01793102149952],
            [80.75514176378141, 30.01806864655083],
            [80.75519498296217, 30.018292586733537],
            [80.75522863919042, 30.01843420927139],
            [80.75526345104748, 30.01864858426478],
            [80.75535088853275, 30.01880214710144],
            [80.75540282617953, 30.019030021818082],
            [80.75550745060843, 30.019291897203345],
            [80.75561226299561, 30.019641709197572],
            [80.7557867638481, 30.020086396969702],
            [80.75592626308662, 30.02042277219323],
            [80.75606626234821, 30.020666645849076],
            [80.75636357551866, 30.021140896135478],
            [80.75672630537474, 30.02152730963769],
            [80.75676657611666, 30.021570209097945],
            [80.75722251350822, 30.021954583837044],
            [80.75802870086392, 30.0226123965449],
            [80.75822151371193, 30.02275033366101],
            [80.75862495048113, 30.02301052191808],
            [80.75902813903747, 30.0233631469917],
            [80.75922020095157, 30.023805521707516],
            [80.75927276362722, 30.023913959261847],
            [80.75935920106633, 30.024493834922623],
            [80.7593585139843, 30.02475546029632],
            [80.75935782600294, 30.025060022002492],
            [80.75939157576067, 30.02556533397302],
            [80.75947820115812, 30.02605270896538],
            [80.75956545068505, 30.026267146012003],
            [80.75954732574854, 30.02655814684107],
            [80.7598095761511, 30.02704802104938],
            [80.75988645109891, 30.027289583447555],
            [80.75998388904532, 30.027598772165675],
            [80.7599307633941, 30.027720521484923],
            [80.75987751273709, 30.028027146235843],
            [80.75954270053523, 30.028437084204143],
            [80.75919070049048, 30.028774771538508],
            [80.7588735132021, 30.029155459057222],
            [80.75848601331893, 30.02966002189254],
            [80.758168763078, 30.030069958961462],
            [80.75792213749668, 30.030344709041117],
            [80.75757001334551, 30.03069589699811],
            [80.75756080069044, 30.03070832113218],
            [80.75741132617185, 30.03090989607489],
            [80.75738493916367, 30.03101578584989],
            [80.75735795050906, 30.03112408400932],
            [80.75726437155356, 30.031550581194438],
            [80.75723376312772, 30.03169008403023],
            [80.75727453659067, 30.031966040100713],
            [80.75728576372705, 30.032042022021756],
            [80.757355325388, 30.03220908368229],
            [80.75738945106235, 30.032714395652874],
            [80.75747657558355, 30.03298977076139],
            [80.75758113795922, 30.03329446017125],
            [80.75766813837396, 30.033646522269237],
            [80.757737638881, 30.033982771587716],
            [80.75778976268748, 30.03425802079107],
            [80.75778913765862, 30.034517459013557],
            [80.75766507618238, 30.034900708700775],
            [80.75764713920415, 30.035112709483997],
            [80.75761101343755, 30.035419396288148],
            [80.7575924505312, 30.03584795921597],
            [80.75760945041588, 30.036062272156187],
            [80.75757376261913, 30.03632158357425],
            [80.75764301401392, 30.03677964696317],
            [80.75758907537556, 30.037237396488706],
            [80.75751782568716, 30.03760496020078],
            [80.75744663805199, 30.038094335285393],
            [80.75744576391094, 30.038446208525727],
            [80.75744532594109, 30.038628896806188],
            [80.75744464965089, 30.038918639483654],
            [80.757444325895, 30.03905745883469],
            [80.75742557592963, 30.039422896549468],
            [80.75744295083155, 30.039621396710118],
            [80.75750418117309, 30.03973675095034],
            [80.75768745041557, 30.0400820204689],
            [80.75795070086423, 30.040326083881723],
            [80.75849420074684, 30.04072408424912],
            [80.75919538775702, 30.041320895942647],
            [80.75959870131908, 30.041657709136018],
            [80.75991445059145, 30.041888396932052],
            [80.76026470055552, 30.042333395869548],
            [80.76056238874332, 30.042670084057193],
            [80.76084232611254, 30.043128458611534],
            [80.7611050756388, 30.04354170878287],
            [80.76082232608951, 30.044136710141117],
            [80.76057588846652, 30.04433470848005],
            [80.7604876388935, 30.044503834984027],
            [80.76020595054422, 30.044778459158636],
            [80.76018583450872, 30.044818595901518],
            [80.76015288874487, 30.044884333645143],
            [80.76013495086733, 30.045067021925547],
            [80.76022238835259, 30.0452363336899],
            [80.76050301280384, 30.045527833642666],
            [80.7606146357569, 30.0456216904887],
            [80.76097620099097, 30.045925709004337],
            [80.76129182615693, 30.046185710202394],
            [80.76164245113824, 30.046506646764385],
            [80.7620805756581, 30.04695177070772],
            [80.76248370036257, 30.047381083670132],
            [80.76265857533303, 30.04773102156946],
            [80.76271026296831, 30.048067208834652],
            [80.76270888880424, 30.04863339681384],
            [80.76272551277225, 30.049138708784426],
            [80.76295282541264, 30.049520335195382],
            [80.76326876354267, 30.049690084030203],
            [80.76354957595225, 30.049767271942073],
            [80.76418188838352, 30.04991945971534],
            [80.76460295186479, 30.050181960129407],
            [80.76507638916411, 30.05048733482272],
            [80.76539207548393, 30.05076308404915],
            [80.76554976351082, 30.050977646101558],
            [80.76597107610434, 30.051145396642767],
            [80.76646251333437, 30.05123864644645],
            [80.76695395056441, 30.05149895880993],
            [80.76722731209082, 30.05185153172289],
            [80.76732170043618, 30.05197327204894],
            [80.76778192669462, 30.052071531076365],
            [80.76797157662702, 30.05211202125281],
            [80.76849813867824, 30.052311458507063],
            [80.76916557593063, 30.05242089610755],
            [80.76995582550103, 30.05254414729461],
            [80.77042995078165, 30.052727646763515],
            [80.77075128484398, 30.05283399519277],
            [80.77099157560082, 30.052913522241283],
            [80.77153607552953, 30.053065647062],
            [80.77201216582887, 30.053108776748672],
            [80.77222113859472, 30.053127709276396],
            [80.77287088888045, 30.053192084547106],
            [80.77315169319616, 30.05335711284056],
            [80.77346738850923, 30.05354264657592],
            [80.77376545081495, 30.053910833518216],
            [80.77430963867891, 30.054065210241333],
            [80.77485438861919, 30.054127021544844],
            [80.77536395078573, 30.05409633487801],
            [80.77604913795739, 30.05411327181008],
            [80.77662602157369, 30.054114270956916],
            [80.77669907620145, 30.054114396861962],
            [80.77743682604876, 30.054237459191427],
            [80.7778407637403, 30.054359895592768],
            [80.77801620078708, 30.05445270922519],
            [80.7780641031759, 30.05447443414789],
            [80.77852526382992, 30.0546835849795],
            [80.77892420668684, 30.054885412631734],
            [80.77919188809523, 30.055020833444757],
            [80.77952520112717, 30.055280833743552],
            [80.77994613780407, 30.055649146590895],
            [80.78031445065142, 30.05603102211404],
            [80.78077045099559, 30.05635208458108],
            [80.78119145062499, 30.056675334199383],
            [80.78142357553764, 30.056948647162415],
            [80.78148920086596, 30.05702545915767],
            [80.78189251262938, 30.057438958441253],
            [80.7821374519819, 30.057899521046124],
            [80.78224586615386, 30.058088732109354],
            [80.7823651387402, 30.058296897284038],
            [80.78257520058509, 30.058556646671946],
            [80.78278532538252, 30.05886370849339],
            [80.78311820044468, 30.059322208952835],
            [80.78348645033947, 30.05975139690952],
            [80.78378382556315, 30.060315771855414],
            [80.78414928845893, 30.060582707725814],
            [80.78420482609192, 30.060623271646705],
            [80.78467876341426, 30.060912771507276],
            [80.78531045081672, 30.06125214596983],
            [80.78567857570579, 30.06160233388067],
            [80.78581851291409, 30.06195445893121],
            [80.78608107538139, 30.06241277143232],
            [80.78616807579613, 30.062812146863166],
            [80.78639557549548, 30.063299771867037],
            [80.7866052641217, 30.063760271519357],
            [80.78664988848163, 30.06385913848851],
            [80.78679782605889, 30.064186897307536],
            [80.78699001297878, 30.064692522242183],
            [80.78720007572298, 30.064983834236614],
            [80.78753307579086, 30.065412959240746],
            [80.78763788907736, 30.06565670968945],
            [80.78788301369019, 30.06609920851173],
            [80.78793557546652, 30.066223396792395],
            [80.78830376330808, 30.066573583803915],
            [80.78865432533689, 30.066926021818517],
            [80.78900507532404, 30.06727852188635],
            [80.78951395040855, 30.067631209013143],
            [80.78967157548288, 30.067890835193964],
            [80.78984670046492, 30.06815052162932],
            [80.79022144616289, 30.068538801224747],
            [80.79023263732643, 30.068550397083186],
            [80.79051307561798, 30.068855333806653],
            [80.79082845077244, 30.06932952203988],
            [80.7909680129635, 30.0697268335266],
            [80.79103801259424, 30.069880272256796],
            [80.79104235182314, 30.069938176006076],
            [80.7910723253276, 30.070338209740612],
            [80.79103682548919, 30.07046220916368],
            [80.79101863849945, 30.07076670881662],
            [80.79087726417447, 30.07108677213688],
            [80.7907534509111, 30.07154677176618],
            [80.79075288883479, 30.071806147036114],
            [80.79094520076046, 30.072248583805163],
            [80.79117313842966, 30.072524146871956],
            [80.79157620108089, 30.07312027148339],
            [80.79217307572691, 30.073396396626435],
            [80.79280576407479, 30.07333652156325],
            [80.79321581895493, 30.073515731266014],
            [80.79343757558371, 30.07361264670635],
            [80.79380601343684, 30.07404183376366],
            [80.79426182672199, 30.074516209055844],
            [80.79471782616679, 30.074929709238745],
            [80.79499788854179, 30.07557302217731],
            [80.79526113809112, 30.075740334748673],
            [80.79559438817051, 30.076061209257432],
            [80.79598007591983, 30.07642945915228],
            [80.79622513758011, 30.076919335159232],
            [80.79636488862877, 30.0773932086297],
            [80.79675038752043, 30.077851771142377],
            [80.79785620110283, 30.078541396468324],
            [80.7982068260842, 30.078938958865933],
            [80.79846970061624, 30.07929120982152],
            [80.79876801383284, 30.0795984596013],
            [80.79920663837572, 30.079919460015105],
            [80.79971576347174, 30.080195397199873],
            [80.80031295108188, 30.08036320889505],
            [80.80103263894517, 30.080747771592428],
            [80.80122588886371, 30.08090145853555],
            [80.80154107605989, 30.08135983398921],
            [80.80155870097332, 30.081450021601256],
            [80.80171607603614, 30.081725521715498],
            [80.80197870055662, 30.082215458876362],
            [80.80208376295536, 30.08252233363885],
            [80.80218851418863, 30.082827022149388],
            [80.80243351379568, 30.08339352219332],
            [80.80267845045017, 30.08396008429048],
            [80.80297613863797, 30.084434208671837],
            [80.80315076269761, 30.084984834782347],
            [80.80323813812964, 30.085260208991485],
            [80.80339563909752, 30.085625833765278],
            [80.80372895033088, 30.085946584167573],
            [80.80413276301664, 30.086208896623305],
            [80.80457126345311, 30.086622333853654],
            [80.80481682603573, 30.086897896920448],
            [80.80516763807611, 30.08724795982556],
            [80.80560607555998, 30.087708834495174],
            [80.80616745036758, 30.088350270548574],
            [80.80685170044569, 30.08894902207976],
            [80.80714957569245, 30.08933070874525],
            [80.80753576256546, 30.089667333980344],
            [80.80797445096022, 30.090004084221164],
            [80.80839607561848, 30.090126521521825],
            [80.80909857563881, 30.090357583435775],
            [80.81020545042128, 30.090589272177226],
            [80.81062707597886, 30.090711708578567],
            [80.8114177005665, 30.090850459581077],
            [80.81182151415163, 30.091094647100363],
            [80.8123128875298, 30.09141564661485],
            [80.81269895119567, 30.091799647235973],
            [80.8130147004681, 30.09218127094897],
            [80.81355888743275, 30.09250239726788],
            [80.81403307566598, 30.092764771776842],
            [80.81447220113125, 30.092916459527032],
            [80.81521013893683, 30.092949085132204],
            [80.81570232620146, 30.09298127186827],
            [80.81601776340909, 30.093516397263954],
            [80.81606938899114, 30.094035271910343],
            [80.8160158874233, 30.09455395859834],
            [80.81601482532398, 30.09512014657753],
            [80.81606713798806, 30.095257834581446],
            [80.81652363745599, 30.09562614652947],
            [80.81671657530978, 30.095793334994482],
            [80.81683913761617, 30.095978458639024],
            [80.81714296277755, 30.096340736136142],
            [80.81717245064812, 30.09637589693017],
            [80.81724400880404, 30.09640841191873],
            [80.81750613779809, 30.096527521727808],
            [80.81778751408257, 30.09659108401138],
            [80.8183493259607, 30.096819646709378],
            [80.81859513765556, 30.097034270815016],
            [80.81891145080289, 30.09709570979919],
            [80.81968495050012, 30.097067333490713],
            [80.82010701402754, 30.096943897043275],
            [80.82065188897354, 30.096883709016026],
            [80.82089801273315, 30.096915645740523],
            [80.82119638890231, 30.09706939653546],
            [80.82130182631829, 30.097191396765595],
            [80.82168770112656, 30.097588959163204],
            [80.82196788850729, 30.09809452204462],
            [80.82210763865663, 30.098658646979004],
            [80.82219476317783, 30.0990715221331],
            [80.82240551300407, 30.099317708845263],
            [80.82284432550529, 30.099622833527008],
            [80.82347663883587, 30.099822146674796],
            [80.82426738752997, 30.099915708543165],
            [80.82493545071054, 30.099932397262364],
            [80.82563876371802, 30.099748333918512],
            [80.82623663751087, 30.09959802213109],
            [80.82681657612414, 30.099736397216986],
            [80.82727338765676, 30.099980584736272],
            [80.82795455485888, 30.100053977509106],
            [80.82799382645396, 30.10005820881935],
            [80.82804048777837, 30.100003217074857],
            [80.82833846914508, 30.099652036312477],
            [80.82848707581797, 30.09947689694127],
            [80.82874447437803, 30.099068904206604],
            [80.82880413810057, 30.098974334198203],
            [80.8290157638665, 30.098667834453067],
            [80.82928020052088, 30.098178708480646],
            [80.82963232557142, 30.09782952151528],
            [80.83012513786485, 30.09752333473415],
            [80.83021295036741, 30.09749701697382],
            [80.83052970058526, 30.0974020845386],
            [80.83125051350044, 30.097249646753824],
            [80.83169510504513, 30.097187627706887],
            [80.83179532549394, 30.097173646846386],
            [80.8321397451532, 30.097193339301214],
            [80.83288520039173, 30.097235959072293],
            [80.83334201372304, 30.097297397157206],
            [80.83392182553189, 30.09748314672987],
            [80.8345542009157, 30.097820021976474],
            [80.83518676245916, 30.097881709173578],
            [80.83576707698904, 30.097805772218692],
            [80.83641757551078, 30.09762383397282],
            [80.83717382531182, 30.097410584031252],
            [80.83777458862642, 30.097265715840138],
            [80.83780701278346, 30.097257897134284],
            [80.83822888745323, 30.09725839715736],
            [80.83849942330977, 30.09735302112506],
            [80.83884376382872, 30.097473459232788],
            [80.8392473885562, 30.09768827129676],
            [80.83958082569461, 30.098024770626694],
            [80.84010757570411, 30.098361459713658],
            [80.84077520091483, 30.098605897244397],
            [80.84102107556225, 30.09874370845546],
            [80.84150288015383, 30.09894464218155],
            [80.84175901246851, 30.0990514600569],
            [80.84246145133494, 30.099433521739684],
            [80.84277751357143, 30.099709083907157],
            [80.84298511577055, 30.099864336569908],
            [80.84314607553102, 30.099984709926446],
            [80.84349720053541, 30.100244522266962],
            [80.84388351241421, 30.10061264715597],
            [80.84426920106284, 30.101131896819652],
            [80.8446733258134, 30.101333147107084],
            [80.84556920075806, 30.10160714625283],
            [80.84597332550862, 30.10176102205355],
            [80.84609011057023, 30.101808932536244],
            [80.84653563830909, 30.101991708950266],
            [80.84686932545907, 30.102219958684202],
            [80.84699201367056, 30.102344146964867],
            [80.84714945078656, 30.102863146616983],
            [80.84715095085573, 30.10292135163911],
            [80.84716601360071, 30.1035060215857],
            [80.84739388741804, 30.10399352158379],
            [80.84752039055365, 30.10433775328471],
            [80.84755145043914, 30.104422271570627],
            [80.84763888882372, 30.104713334452867],
            [80.84765144875541, 30.10495041462997],
            [80.8476729506462, 30.105356271474818],
            [80.84763751286101, 30.10561552173897],
            [80.84768926254947, 30.10613446023723],
            [80.8478642634251, 30.106639959266772],
            [80.84759491737162, 30.106922939941796],
            [80.84752970033549, 30.10699145928845],
            [80.84713242662025, 30.107196786202792],
            [80.84708988868749, 30.107218771928842],
            [80.84670320089208, 30.107310771674804],
            [80.8463333879755, 30.10758552175446],
            [80.84576810651293, 30.108133092868],
            [80.8454356388437, 30.10845514548862],
            [80.84531267543969, 30.108553341563493],
            [80.84499563743879, 30.10880652230321],
            [80.84467863900807, 30.10911064693886],
            [80.84453949769971, 30.109266790829793],
            [80.84437945075109, 30.10944639713358],
            [80.84409732533135, 30.10981377198806],
            [80.8440412948699, 30.109871150533195],
            [80.84371013841223, 30.11021027228628],
            [80.84358651400646, 30.110440209148408],
            [80.84358582692442, 30.110882333852658],
            [80.8436383257482, 30.111112459572382],
            [80.84372570118023, 30.11146452167037],
            [80.84376472366313, 30.111518543945692],
            [80.84402407555069, 30.111877583883427],
            [80.84419913758018, 30.112335708426258],
            [80.8443916383635, 30.112809583695366],
            [80.8445405130343, 30.11322258385519],
            [80.84458413914678, 30.1133444590796],
            [80.84482938876533, 30.11394020867374],
            [80.84518026375821, 30.1143533967919],
            [80.84544345035499, 30.11473720855537],
            [80.8457063887389, 30.115134459787555],
            [80.84603988882986, 30.115547647006338],
            [80.84625076276257, 30.115701271896228],
            [80.84668970116883, 30.116051459807068],
            [80.84718138751106, 30.11628208375123],
            [80.84776126407115, 30.116465459113726],
            [80.84828807613388, 30.116878834290844],
            [80.84881545117219, 30.117093772259807],
            [80.84962376362586, 30.117263834058747],
            [80.85027401393467, 30.11750820863699],
            [80.85066051287242, 30.117601147275195],
            [80.85122307568446, 30.117692021969276],
            [80.85192613868043, 30.117769458993394],
            [80.8523480133502, 30.11786245878551],
            [80.85301588857243, 30.11801658369842],
            [80.85357807546785, 30.118170583605604],
            [80.85424570067858, 30.118536772254288],
            [80.8545969506888, 30.11873564653291],
            [80.85521207617643, 30.11884458411032],
            [80.85596820097174, 30.118858959773206],
            [80.85617901285121, 30.11904414547098],
            [80.8563189509589, 30.1193803965881],
            [80.85651176290759, 30.119976085028327],
            [80.85658145047364, 30.120206271901964],
            [80.85673963852355, 30.120267334969583],
            [80.85691538763507, 30.12029683363204],
            [80.85747820045862, 30.120268147057402],
            [80.85826913890969, 30.120375021590007],
            [80.85877876402878, 30.120560522050482],
            [80.85918301378513, 30.120712084794945],
            [80.85981551417467, 30.120942833744834],
            [80.86018476231698, 30.121065021933248],
            [80.8606589505502, 30.121311396603744],
            [80.86106332531227, 30.121372708783554],
            [80.86136207559935, 30.1213572719206],
            [80.86197776316328, 30.121082709799282],
            [80.86271663896179, 30.120853397066696],
            [80.86341988811739, 30.120793271092623],
            [80.86352563759817, 30.120793334045175],
            [80.86426363745699, 30.120947521910637],
            [80.86468520186065, 30.121238896857676],
            [80.86514182543493, 30.121543896533638],
            [80.86570395117644, 30.12198883431728],
            [80.8661432016475, 30.12220358522734],
            [80.86658232531414, 30.122476960243546],
            [80.86689845140245, 30.122799833945237],
            [80.86717926381203, 30.123136210068083],
            [80.8673725757838, 30.12338002077138],
            [80.86788201294462, 30.123703084229987],
            [80.86832101340411, 30.124114084297617],
            [80.86879495072645, 30.124527396522183],
            [80.8692692639654, 30.124972147246865],
            [80.86972576343328, 30.12536958463869],
            [80.87021782569218, 30.12561595930913],
            [80.87088576386697, 30.125815021546032],
            [80.87136020031306, 30.125921584013952],
            [80.87202826349363, 30.126014709711114],
            [80.87269626372165, 30.12615295979134],
            [80.8728672005609, 30.126157647057823],
            [80.8732940763606, 30.12616708454334],
            [80.87335302871935, 30.126160098609716],
            [80.87380407559766, 30.12610664650458],
            [80.87435836554619, 30.12609499219019],
            [80.8744193881443, 30.12609370885764],
            [80.87457348877552, 30.126170153030216],
            [80.87466545075, 30.126215772040325],
            [80.87474821805586, 30.126231200809343],
            [80.87482370085314, 30.126245271602045],
            [80.8753687017043, 30.126200647242115],
            [80.87575563771259, 30.126246145743096],
            [80.87594876352466, 30.126383896699508],
            [80.87601895111374, 30.126553083458077],
            [80.8764228267521, 30.12681295875109],
            [80.8765086094848, 30.126870625079334],
            [80.87665126354506, 30.126966521587747],
            [80.8768633983272, 30.127016213627428],
            [80.87703813839943, 30.127057147169637],
            [80.87756532547945, 30.127258334504575],
            [80.87796920111782, 30.12748658513783],
            [80.87819776381582, 30.1275950217929],
            [80.87860195061955, 30.127839084306345],
            [80.8788478261663, 30.128130209241817],
            [80.87926932581877, 30.128527645734323],
            [80.8794798256335, 30.1288030217421],
            [80.87960276295718, 30.12909408462434],
            [80.8796905134065, 30.12924527145219],
            [80.87969038840077, 30.129322022293536],
            [80.87966028809188, 30.129597685185047],
            [80.87963695068476, 30.12981139647883],
            [80.8796362627034, 30.13033020907193],
            [80.8797410759899, 30.13088071017671],
            [80.88003951241365, 30.131386209206255],
            [80.88035526258534, 30.131828645975304],
            [80.880425326068, 30.13209033430155],
            [80.88042495105071, 30.13248733372353],
            [80.880372075411, 30.1326090839421],
            [80.88030132574562, 30.132976709707464],
            [80.88014263857195, 30.133312646961144],
            [80.88003688819191, 30.133571959278527],
            [80.87984270207909, 30.13398464647429],
            [80.8796639519299, 30.13426183371672],
            [80.87959638856256, 30.134365521951338],
            [80.87938463869017, 30.134809708801072],
            [80.87917332678768, 30.135206522063413],
            [80.8788210137788, 30.1357249587399],
            [80.87831051361934, 30.136168896477443],
            [80.87802876411621, 30.13638289555422],
            [80.87790551382847, 30.13654970900194],
            [80.87795763853427, 30.13688589716645],
            [80.8780806388105, 30.137145333590297],
            [80.87834363834827, 30.137711771581053],
            [80.87816732536186, 30.138108583944017],
            [80.8778856379119, 30.138476021751046],
            [80.87777945046133, 30.13884133356072],
            [80.87779701242226, 30.13904208382513],
            [80.87784895096837, 30.13956095847152],
            [80.87795363745045, 30.14017233378621],
            [80.87795307717283, 30.140614460289157],
            [80.87788276277934, 30.140815146701698],
            [80.87781201401327, 30.141088021694884],
            [80.87753020065827, 30.14153208443821],
            [80.87719563846798, 30.141867896686108],
            [80.87677313876941, 30.142203584827655],
            [80.87636801397275, 30.142661085240945],
            [80.87601576301711, 30.1431050841324],
            [80.87557538749422, 30.14376108380702],
            [80.87531101289301, 30.14423445905311],
            [80.87513438874112, 30.14484564730884],
            [80.87504607531616, 30.145181646615754],
            [80.87497532565084, 30.1454432090369],
            [80.87493957580085, 30.14573189680965],
            [80.87484570096836, 30.14608370889607],
            [80.87490345093357, 30.146589021765976],
            [80.87500807536247, 30.147277084070197],
            [80.87497251347077, 30.14768983421851],
            [80.87486638897275, 30.147978521991263],
            [80.87446163739469, 30.14832995906039],
            [80.87432063808706, 30.14849895876],
            [80.87412695109799, 30.148774021803717],
            [80.87403845061408, 30.149200271675227],
            [80.87398501289806, 30.149660335156398],
            [80.87394932600063, 30.150147522190423],
            [80.87394901393588, 30.15043852212017],
            [80.87391338909168, 30.15059183494526],
            [80.87387207603552, 30.150700084541313],
            [80.87373688904626, 30.151049583571478],
            [80.87363101366043, 30.151446458886994],
            [80.87341963790601, 30.151845520454458],
            [80.87320770097466, 30.152379896714933],
            [80.873136763351, 30.152792583910696],
            [80.87266145186459, 30.15326583505032],
            [80.8727489505037, 30.153647146698518],
            [80.87297745204785, 30.15383014704372],
            [80.873257950594, 30.15442808378907],
            [80.87320432581902, 30.15500764648573],
            [80.87316851391586, 30.15560320902091],
            [80.87316776388127, 30.156200958707302],
            [80.87322007564603, 30.156460396929845],
            [80.87320201276276, 30.156812271968818],
            [80.87300807576213, 30.157254146661614],
            [80.87325363744549, 30.157759646590478],
            [80.87342938835565, 30.157974146589652],
            [80.87346401315375, 30.15821777113331],
            [80.87319963855253, 30.158706959158906],
            [80.8729001391302, 30.15918039645817],
            [80.87286401246428, 30.159807396594147],
            [80.87298670067577, 30.1602496472035],
            [80.87310951389298, 30.160387334308098],
            [80.87317957647633, 30.160694209070527],
            [80.87309126395076, 30.160953522287286],
            [80.87300313848414, 30.16112033483563],
            [80.8728795761316, 30.16132095919494],
            [80.8724746383939, 30.16176264682872],
            [80.87217501396577, 30.162175146965524],
            [80.87180526400181, 30.16254245886745],
            [80.87122432534238, 30.16289152082709],
            [80.87080157563224, 30.16330389595811],
            [80.87044926262342, 30.163747896648147],
            [80.87015001231327, 30.164022771733585],
            [80.86993826334026, 30.164435396876115],
            [80.86977932615503, 30.164861521741898],
            [80.86955032548718, 30.165274083931877],
            [80.86921538917892, 30.165824147066758],
            [80.86895101277906, 30.166284021690274],
            [80.86883389137103, 30.16644337616094],
            [80.8688328544527, 30.166444787197236],
            [80.8687042010381, 30.166619833938228],
            [80.8683235099221, 30.166747983732535],
            [80.86827540878312, 30.166764176025936],
            [80.86798251398187, 30.16686277229917],
            [80.86745476392628, 30.16695470909258],
            [80.86678626367518, 30.166983333613985],
            [80.86604782584652, 30.166876647039658],
            [80.86566038801658, 30.167119770660918],
            [80.86513201293212, 30.16765383395733],
            [80.86497307574689, 30.1680371465971],
            [80.86477907579376, 30.168510583896364],
            [80.86466028974064, 30.168840747502543],
            [80.86462007565603, 30.168952521541712],
            [80.8643458975452, 30.169074008258917],
            [80.86398527120338, 30.169263711251347],
            [80.86310360914433, 30.17028882686793],
            [80.86226838717636, 30.171009367288548],
            [80.86153148359114, 30.171404870238632],
            [80.86081350983568, 30.172012851511965],
            [80.86062647513177, 30.172461580837364],
            [80.86034207442725, 30.173143893775716],
            [80.85986707860286, 30.173614062039405],
            [80.85929650373072, 30.174178830888366],
            [80.85840502017561, 30.17504681965505],
            [80.8579346819401, 30.175842699880718],
            [80.85757319494707, 30.17645437916633],
            [80.85624164793376, 30.177589242649503],
            [80.85634470484433, 30.178525145519075],
            [80.85589844685592, 30.179293341014954],
            [80.8557273454407, 30.17979924294076],
            [80.85582031915249, 30.180109683515127],
            [80.85589702862501, 30.18035277296218],
            [80.85596802110734, 30.180577741069953],
            [80.85603881843679, 30.181021840685446],
            [80.8556697375684, 30.181618239590136],
            [80.85432487789086, 30.182492241223997],
            [80.85351331348869, 30.183033857026942],
            [80.85208294896898, 30.184006235499396],
            [80.85184234794605, 30.184537200631382],
            [80.8516418207135, 30.184979722835976],
            [80.8510096260934, 30.185088817794735],
            [80.85018453678703, 30.185550998280007],
            [80.85013962194603, 30.186176396723397],
            [80.84946238298267, 30.18698727494285],
            [80.84894916776994, 30.18756194622688],
            [80.8486530794761, 30.18789348579577],
            [80.84750354715106, 30.188445987892067],
            [80.84681029845655, 30.188922623180588],
            [80.84661441262512, 30.189217813951018],
            [80.84636178316981, 30.189598513160888],
            [80.84626490370238, 30.189744502806604],
            [80.8461106501864, 30.1899769532738],
            [80.84487275677236, 30.19077282360695],
            [80.84394856217648, 30.191296915220505],
            [80.84370307873417, 30.191530318069795],
            [80.84349718884425, 30.191726076197483],
            [80.8429359759146, 30.192097810065945],
            [80.842440973772, 30.192307290948747],
            [80.84236878519135, 30.192770323991283],
            [80.84224182070352, 30.19311392976408],
            [80.84198313251562, 30.193338015636982],
            [80.84163744840959, 30.19351638357432],
            [80.84117999566035, 30.193752420537805],
            [80.84091814185882, 30.194234320457554],
            [80.84087621546502, 30.194671130168558],
            [80.84084238476828, 30.19520105028829],
            [80.84028024104038, 30.19613137915985],
            [80.84017179898939, 30.19636408593385],
            [80.83971543172186, 30.19734339278341],
            [80.83954931884603, 30.198092773562678],
            [80.83938885461203, 30.198746674219706],
            [80.83910786413668, 30.199152155147885],
            [80.83901502981985, 30.199773602869527],
            [80.83845914267533, 30.200212166258495],
            [80.83766659983377, 30.20082708670077],
            [80.83658217752554, 30.201766300874112],
            [80.83545376847667, 30.202700675795597],
            [80.83502950430824, 30.20330171879931],
            [80.83444024691994, 30.203950297268477],
            [80.8345768089726, 30.20446610702527],
            [80.83464527885661, 30.204921924807024],
            [80.83468010240483, 30.205153752943374],
            [80.83397552045398, 30.205725792811165],
            [80.83320543278455, 30.20627843969828],
            [80.8329793441215, 30.206440689985925],
            [80.83240133905059, 30.20691739362286],
            [80.83160936637921, 30.207143163925934],
            [80.83101593253929, 30.20699200587643],
            [80.83039680506857, 30.20739941854839],
            [80.83000865317683, 30.20774983578633],
            [80.82949658909638, 30.208228999968412],
            [80.8289848163962, 30.20853628302308],
            [80.82894268315835, 30.209059025653573],
            [80.82890915463383, 30.209374093239887],
            [80.82894879854825, 30.210290831556676],
            [80.82833345092808, 30.21116168826137],
            [80.8280649088685, 30.211639470185446],
            [80.82757609675951, 30.21250913528843],
            [80.82718751319322, 30.213473932476063],
            [80.82671553459431, 30.214198141231293],
            [80.82615356443551, 30.21489207430983],
            [80.82568265153321, 30.215385599765682],
            [80.82492607258024, 30.21581673925118],
            [80.82421279508446, 30.21650800943712],
            [80.82382430414839, 30.217015962716573],
            [80.82388135264239, 30.217395605223032],
            [80.82416388275703, 30.217894518517255],
            [80.82409348292794, 30.218677817227558],
            [80.82369640346633, 30.219371959848104],
            [80.82334070630861, 30.219980217213333],
            [80.82292654692264, 30.22049617266032],
            [80.8224971916921, 30.22049559169824],
            [80.82247990492374, 30.22049556921519],
            [80.82195408751119, 30.220622886237265],
            [80.8215735564745, 30.22127407363962],
            [80.82159761243992, 30.221653672079356],
            [80.8217697777531, 30.222219672999586],
            [80.82157952887223, 30.222534524748596],
            [80.82117510284877, 30.22271301409438],
            [80.82078673961638, 30.22312785606573],
            [80.8206371104144, 30.223779356432203],
            [80.81996408747273, 30.224264229510652],
            [80.8198881217395, 30.224363662153337],
            [80.81963341215231, 30.224697051627686],
            [80.81929929692507, 30.224753885183702],
            [80.81813164955605, 30.225117510764562],
            [80.81746340201443, 30.225238330184823],
            [80.81618538354866, 30.225075407204315],
            [80.81527884804046, 30.224723214704625],
            [80.81481308375686, 30.224615133281816],
            [80.81454872804142, 30.224883225680344],
            [80.81440406939237, 30.225029927589162],
            [80.81383371755209, 30.225716631917066],
            [80.81381534799999, 30.22671206810753],
            [80.81361996668818, 30.227691481077045],
            [80.81311631306744, 30.228567418951286],
            [80.81208433292568, 30.229114999058027],
            [80.81134841499744, 30.229382500601957],
            [80.81066796905156, 30.229393456143157],
            [80.80996714356888, 30.229279049788374],
            [80.80927877819312, 30.229833072638257],
            [80.80838350309625, 30.230521054003475],
            [80.80649880467615, 30.23150600409889],
            [80.80574002778013, 30.231980850635807],
            [80.80500552538473, 30.232422861126167],
            [80.80490853620006, 30.232810636201975],
            [80.80453621417502, 30.233400912522143],
            [80.80414522582157, 30.233604630549337],
            [80.8039651437764, 30.233698458617027],
            [80.8033800844234, 30.23411534204945],
            [80.80222436835999, 30.23461491364742],
            [80.80180488139024, 30.2351791797754],
            [80.8014659529914, 30.235783492714063],
            [80.80055753070553, 30.23634310395113],
            [80.80024691116608, 30.236993141120593],
            [80.7993460953461, 30.23718274069097],
            [80.79850589742927, 30.237981160602146],
            [80.79791899356678, 30.238278416215962],
            [80.79754259671353, 30.23846905090602],
            [80.79742496269193, 30.238850820309153],
            [80.79661857478743, 30.239905904034288],
            [80.79596968155562, 30.241244109627985],
            [80.79558897874841, 30.24185702548141],
            [80.79532627958417, 30.242029964211554],
            [80.79474079754982, 30.242415391157408],
            [80.79454650261908, 30.242775753098556],
            [80.79445787712939, 30.24294012848418],
            [80.79386789668615, 30.243283823289858],
            [80.79346682513392, 30.243517464459444],
            [80.79255032154015, 30.24459622103882],
            [80.7918406251448, 30.24538883582619],
            [80.7911306139867, 30.246324678441226],
            [80.79068185948029, 30.247237063140005],
            [80.79026323406106, 30.248131138541282],
            [80.79003964101668, 30.24860866865521],
            [80.78914458175711, 30.249263707853913],
            [80.7889913669581, 30.24948499413722],
            [80.7885667223764, 30.250098293101814],
            [80.7883834117651, 30.250950223574023],
            [80.78832119396799, 30.251240659628763],
            [80.78826592882962, 30.251498640050272],
            [80.78813342631662, 30.252117155082715],
            [80.78794375569981, 30.252709828995478],
            [80.78779303472083, 30.25318078686388],
            [80.787622328108, 30.253714190957226],
            [80.78707046363104, 30.254590585687026],
            [80.78702089839578, 30.255258917764877],
            [80.7864292191332, 30.25546683382737],
            [80.78602877440846, 30.256253951958342],
            [80.78555913404688, 30.257243866311455],
            [80.78518624095233, 30.258001186305762],
            [80.78495804068109, 30.25860954349571],
            [80.78465414896988, 30.259247616984],
            [80.78443232309331, 30.260082767006168],
            [80.7839295624994, 30.260672956092094],
            [80.78358450162352, 30.261078017936256],
            [80.7829576543723, 30.261626033314826],
            [80.78281341750528, 30.261870963674085],
            [80.78255739490788, 30.26230571663558],
            [80.78144964778306, 30.26267985529114],
            [80.78100201383194, 30.26301331311396],
            [80.78080799499304, 30.263693334579784],
            [80.78040844059711, 30.264044775246248],
            [80.77988452884796, 30.26466456281935],
            [80.779547127498, 30.264890778286826],
            [80.77900355477027, 30.265074868610327],
            [80.77848649045922, 30.265700631279174],
            [80.77869219239085, 30.26595163296156],
            [80.77906238751922, 30.266435661576566],
            [80.77909589715796, 30.266823636301808],
            [80.77894387216202, 30.267163553055582],
            [80.77845525070933, 30.267365637014564],
            [80.77801388233485, 30.267955717282575],
            [80.77722867256523, 30.268569082797],
            [80.77641291102708, 30.26951183760127],
            [80.77620369814224, 30.26975361493743],
            [80.77567045143024, 30.27023052631779],
            [80.77502333975536, 30.27080926253751],
            [80.77485079492828, 30.2711989900414],
            [80.77473582110133, 30.27145867827545],
            [80.77441715712615, 30.27189498346678],
            [80.77358854337666, 30.272820973109447],
            [80.7731304314243, 30.273493364727074],
            [80.77284299280996, 30.274099210110535],
            [80.77251743013534, 30.274537885015434],
            [80.77190592801622, 30.274928318487184],
            [80.77173446057697, 30.275333839884865],
            [80.7714310697881, 30.275696161448764],
            [80.77094059932926, 30.27607725366238],
            [80.77057183592154, 30.276162546264572],
            [80.77044950564022, 30.27630813031601],
            [80.77023534288685, 30.276563001781142],
            [80.76965666872036, 30.277020324128728],
            [80.76911129734856, 30.27734879431148],
            [80.76872981033256, 30.277930590924086],
            [80.76840724150105, 30.27825229011114],
            [80.76767323553145, 30.278086275261444],
            [80.76684822806334, 30.27802035405614],
            [80.76609673837385, 30.278269667711072],
            [80.76536416412495, 30.278659853869215],
            [80.76486335865712, 30.278744893761882],
            [80.76445295933667, 30.27859774309144],
            [80.76424776012607, 30.27852416685687],
            [80.76369099434396, 30.279005372499967],
            [80.76332662592313, 30.27954421569308],
            [80.76315453075705, 30.280193216843543],
            [80.76322481996954, 30.280704199938953],
            [80.76358073836042, 30.281397123978138],
            [80.76341465875953, 30.281831289682316],
            [80.7632698148501, 30.282585374815255],
            [80.76340597310724, 30.283158990295192],
            [80.76303071119844, 30.283644848131303],
            [80.76250195749941, 30.283901705299513],
            [80.76239572868002, 30.284608115572155],
            [80.76221443075144, 30.285182643863948],
            [80.76215180106482, 30.28538111704495],
            [80.76174898212986, 30.285881690487656],
            [80.7615764382021, 30.286707339172438],
            [80.76159026078199, 30.287257048873755],
            [80.76174968360107, 30.287867819844053],
            [80.76146156689788, 30.288707580690357],
            [80.76178431919101, 30.289472062885466],
            [80.76192673852825, 30.28973069171809],
            [80.76225540386383, 30.290327527693307],
            [80.76221585437821, 30.29075714642522],
            [80.76188412145513, 30.291429726001127],
            [80.76211966289219, 30.29185984475606],
            [80.76227270052476, 30.292280265222132],
            [80.76230438903639, 30.292829371478376],
            [80.7624229790373, 30.293846521799594],
            [80.76274039115617, 30.294553700992537],
            [80.76250285052618, 30.29494954028911],
            [80.7621501687953, 30.295178067913582],
            [80.76184020935824, 30.295941398976424],
            [80.76136495183113, 30.29679991428469],
            [80.76104459083524, 30.29731018421768],
            [80.76123623996057, 30.29769248062354],
            [80.76144902854986, 30.298008421451527],
            [80.76164978780753, 30.29846428959536],
            [80.76146975882239, 30.298962880932265],
            [80.76110057633059, 30.29918182717961],
            [80.76117591883366, 30.29987901760262],
            [80.7611973038126, 30.300136871219706],
            [80.76099802753697, 30.300628263483645],
            [80.76081283554396, 30.301440625584462],
            [80.7606782196242, 30.302031120439892],
            [80.76062575407536, 30.30211717566715],
            [80.75996227743724, 30.303424128920483],
            [80.75978820915856, 30.303631433443968],
            [80.75902963730795, 30.30453483391551],
            [80.75862298905975, 30.305386564738228],
            [80.7584876033203, 30.305670125475956],
            [80.75766608252377, 30.306332239641222],
            [80.75713107853647, 30.30683732678125],
            [80.75680547539241, 30.307079236317747],
            [80.75637789002764, 30.307396911038666],
            [80.75542738486598, 30.307914345870415],
            [80.75461358115189, 30.308224347575617],
            [80.75442951061348, 30.308208885531712],
            [80.75330557209452, 30.308114465710048],
            [80.75203059153864, 30.30800105310618],
            [80.75034547156633, 30.307962042314443],
            [80.74961669335579, 30.307829354541127],
            [80.74838326777365, 30.307153112026526],
            [80.74804373772838, 30.30696695685964],
            [80.74699738102436, 30.306843854060673],
            [80.74649657195931, 30.306784930480205],
            [80.74486716588706, 30.306489336813513],
            [80.7434456264146, 30.306844076193215],
            [80.74303289515206, 30.30694706745328],
            [80.7415328745426, 30.3076751819641],
            [80.74020317743475, 30.307973331504115],
            [80.73931154639081, 30.308043173753504],
            [80.73763282059826, 30.30947868059667],
            [80.73722005156418, 30.30984999178372],
            [80.73672424003172, 30.310469537439246],
            [80.73619208149944, 30.31089099392426],
            [80.73572515978833, 30.311608588165882],
            [80.73593551840952, 30.312169994449732],
            [80.73589221965028, 30.312590306997095],
            [80.73603475050339, 30.313129834574283],
            [80.7360524059938, 30.313729047457628],
            [80.7354779406545, 30.314527586978613],
            [80.73558336637933, 30.315229624747417],
            [80.73568169915114, 30.315402402498933],
            [80.7359886917248, 30.315941816761494],
            [80.73622579618359, 30.316281272163053],
            [80.73569554781136, 30.31700351230228],
            [80.73507560645413, 30.317310200905126],
            [80.73463301590101, 30.317663798145816],
            [80.73431450660922, 30.31792096468081],
            [80.73357451834937, 30.31835272919517],
            [80.73318144896473, 30.318706420864658],
            [80.73261954535582, 30.31891295646784],
            [80.73204500447343, 30.319212562909513],
            [80.73179342272834, 30.31924321720078],
            [80.73106232336761, 30.319332291452156],
            [80.73093696147163, 30.31950372471715],
            [80.73079302677684, 30.31970055393748],
            [80.73043298049771, 30.320047144560135],
            [80.72974286504126, 30.320342921688564],
            [80.72927095479076, 30.32083966851593],
            [80.72844418465144, 30.321292709191255],
            [80.72815573070244, 30.321380464137178],
            [80.72734531203656, 30.32162701147746],
            [80.72661851413318, 30.321747240942443],
            [80.72647696084312, 30.321902755307974],
            [80.72625416819534, 30.322147521091267],
            [80.72549652264644, 30.32222649955338],
            [80.72547771782246, 30.322309176027716],
            [80.72540249762721, 30.322639877428458],
            [80.72500104476245, 30.323022172934998],
            [80.72416639296472, 30.323328358816752],
            [80.72328263368667, 30.323871690526175],
            [80.72293006976696, 30.32408844242775],
            [80.72257764973881, 30.324642709893226],
            [80.72147926635512, 30.324783604879485],
            [80.72043383955014, 30.325171674932903],
            [80.71965172434767, 30.325861082622907],
            [80.71963702582815, 30.326228213761112],
            [80.71962506214697, 30.32652704228923],
            [80.71952263386254, 30.326669952656232],
            [80.71902954907449, 30.327357916035055],
            [80.7187378773512, 30.32780365691326],
            [80.71846761398928, 30.328216678656872],
            [80.71835662236032, 30.328386297089992],
            [80.71791833776115, 30.328639584848986],
            [80.71746851216216, 30.329064147592362],
            [80.71734299558284, 30.329182613486864],
            [80.71711686195368, 30.32929081092226],
            [80.71670228078568, 30.329489170788634],
            [80.71643343835257, 30.329671205261945],
            [80.71592175018867, 30.329609223087232],
            [80.71558249263796, 30.329873460092188],
            [80.71502061331068, 30.330036949645546],
            [80.71393894292794, 30.3300847189347],
            [80.71299302340935, 30.33029391023581],
            [80.71212538447895, 30.33055339702173],
            [80.71160057610575, 30.330742019029003],
            [80.71117135937078, 30.330708845736694],
            [80.71074665003795, 30.33054677351481],
            [80.70988355358321, 30.330663027077094],
            [80.70943694935579, 30.330927008674564],
            [80.70920407530787, 30.331021842184327],
            [80.70883353034321, 30.33117273763179],
            [80.70850641094216, 30.331512177744855],
            [80.70775001545093, 30.331836336577737],
            [80.70766725444031, 30.332165812202504],
            [80.70753696515885, 30.33268449259532],
            [80.70716002061852, 30.33311691721201],
            [80.70646111338971, 30.333537877271283],
            [80.70599845536447, 30.333640677875053],
            [80.70538344319141, 30.333614228813644],
            [80.70483794231723, 30.33380993927733],
            [80.70426494197358, 30.333540090502822],
            [80.70375293814766, 30.33357831978367],
            [80.7032274597795, 30.333978170056582],
            [80.70259877251539, 30.334377782908405],
            [80.70209295872314, 30.33441533499979],
            [80.70161604734278, 30.33445073951009],
            [80.70109603235608, 30.334406583696932],
            [80.6999150804167, 30.334416450159097],
            [80.6995403536045, 30.33453541158002],
            [80.69939073969095, 30.33483682565901],
            [80.69926164380985, 30.335096901500833],
            [80.69893259806099, 30.335350005798205],
            [80.69855885690578, 30.335129475844667],
            [80.69838670148516, 30.334677159123657],
            [80.69771046436648, 30.334078010092128],
            [80.69690374371288, 30.333923688227685],
            [80.69616951561073, 30.33388984853775],
            [80.69511314855305, 30.333985399706478],
            [80.69483741551437, 30.334010338806138],
            [80.69426533517714, 30.333754212786744],
            [80.6936843731354, 30.333264156915334],
            [80.69320540680411, 30.33335733477321],
            [80.69312474750916, 30.333373026144272],
            [80.69263030204684, 30.333075858664017],
            [80.69183358365302, 30.333124835742638],
            [80.69167777341056, 30.33378371684512],
            [80.69141289608837, 30.334107683223067],
            [80.69102629282924, 30.33420886055052],
            [80.69065034563698, 30.334139570484865],
            [80.69029317359116, 30.333901390436893],
            [80.68998919644434, 30.33369868144905],
            [80.68948604554464, 30.333565276016714],
            [80.6888430950329, 30.333837146467204],
            [80.68850711101442, 30.334119963465582],
            [80.68845471471337, 30.3341640680174],
            [80.68790405532798, 30.33423914342177],
            [80.68756580231997, 30.334161643445157],
            [80.68732125057534, 30.334105611185066],
            [80.68704354712207, 30.334289339081806],
            [80.68689322454276, 30.334388790610262],
            [80.68645476367647, 30.3344679480374],
            [80.68561890498853, 30.334578828150427],
            [80.68502454304826, 30.33478893676005],
            [80.68444667467435, 30.335169622480123],
            [80.68443398883755, 30.335447193733046],
            [80.68442940769108, 30.33554744206083],
            [80.6840527644236, 30.336134016771496],
            [80.68352962249412, 30.336651905760903],
            [80.68298820184413, 30.337271252666255],
            [80.68219537571605, 30.337847727091003],
            [80.68186240442645, 30.338470332240092],
            [80.68158223952878, 30.33879297301735],
            [80.68154519465509, 30.33883563505657],
            [80.6813841134861, 30.339021135517044],
            [80.68085727174577, 30.33919488363705],
            [80.67985518416947, 30.339278372199146],
            [80.6793453396158, 30.33912975563379],
            [80.67910595807359, 30.33883810999083],
            [80.67869530154701, 30.339044827257],
            [80.6785478693887, 30.339119041110905],
            [80.67814930604567, 30.339121408126516],
            [80.67747102836535, 30.339310026536566],
            [80.67706292861135, 30.339379054899496],
            [80.6764202523928, 30.33931766088142],
            [80.67577114512233, 30.339585222679943],
            [80.67549349113176, 30.339926474926926],
            [80.67517784348274, 30.340067831265458],
            [80.67479819108371, 30.340449919028572],
            [80.67472275595048, 30.34096933686544],
            [80.67370345804818, 30.34113748670569],
            [80.6731080978605, 30.341306394674405],
            [80.67223548208187, 30.341914788736574],
            [80.67167227085866, 30.34203174197137],
            [80.67136460739079, 30.341987876639223],
            [80.67127889390588, 30.3419756566513],
            [80.6707189157454, 30.34224888867533],
            [80.67041681008777, 30.34288112106691],
            [80.67038798411824, 30.342941446690475],
            [80.66990270904284, 30.343356236501165],
            [80.66965218400117, 30.343672234885787],
            [80.66957744943977, 30.343766500024003],
            [80.66903508270298, 30.34388256203067],
            [80.66864927084725, 30.34419495863051],
            [80.6681920914919, 30.34454133431518],
            [80.66791613092477, 30.34475041050308],
            [80.66753540743315, 30.345749146306105],
            [80.66747970882159, 30.34589525825959],
            [80.66712363844522, 30.34658510122148],
            [80.66730904987281, 30.34734021787682],
            [80.66714271576376, 30.347539745962536],
            [80.66693244257823, 30.347791980615455],
            [80.66683782310713, 30.348307900988914],
            [80.666798657632, 30.34852145220333],
            [80.66657102033639, 30.348612286427908],
            [80.66643473887217, 30.34866666753271],
            [80.66641972379125, 30.34871079816486],
            [80.66626171740438, 30.349175201774187],
            [80.66588555617352, 30.349236723496062],
            [80.66524561209536, 30.349636126805876],
            [80.66487348971981, 30.350170119055804],
            [80.6646265457785, 30.350524477122974],
            [80.66443644438647, 30.350508372963077],
            [80.66378948020042, 30.35045356647896],
            [80.66344766947441, 30.350610021535317],
            [80.6630235770765, 30.350804137500973],
            [80.66268636278545, 30.350970355597497],
            [80.66286660850722, 30.351281131618975],
            [80.6628837028207, 30.351310605100423],
            [80.66283262132845, 30.351897711310357],
            [80.66282639262397, 30.351969307237766],
            [80.66289037579025, 30.352389892279746],
            [80.6629815157844, 30.353123293005638],
            [80.66274444190253, 30.35339183056857],
            [80.66255827954103, 30.353486320537343],
            [80.66212677403144, 30.353705338730435],
            [80.66168851371401, 30.354181871496223],
            [80.66126658778285, 30.354383614612175],
            [80.66070832462952, 30.354632845529466],
            [80.66047932755896, 30.35473507776169],
            [80.6604931816151, 30.355006579490123],
            [80.6606875421964, 30.35542237654147],
            [80.6607426130812, 30.355682299498596],
            [80.66117206184123, 30.355733236199967],
            [80.66196365499871, 30.355665661141416],
            [80.66239322786521, 30.35596636745396],
            [80.66248908390418, 30.356512943017947],
            [80.66233596443328, 30.35691419263594],
            [80.6621249205287, 30.35744836654891],
            [80.66208739181974, 30.35792520868148],
            [80.66165235287383, 30.35870549646154],
            [80.6616136883211, 30.358774844083825],
            [80.66160096651146, 30.359318525629533],
            [80.66176868557642, 30.360131197097132],
            [80.66192648511924, 30.360617286958302],
            [80.66193635967534, 30.360880381824188],
            [80.66183710060176, 30.361095166908456],
            [80.66174270146456, 30.36123493774221],
            [80.66147574850703, 30.36163020147268],
            [80.66139491294496, 30.36209098710941],
            [80.661248595946, 30.362295344554582],
            [80.66113716274992, 30.362450979429184],
            [80.6610337281241, 30.363266151012112],
            [80.6608244927562, 30.36389227610772],
            [80.66030753546443, 30.36445759803979],
            [80.66020341465594, 30.364571458506077],
            [80.66007925515356, 30.365024206002374],
            [80.65987749674912, 30.365863371497426],
            [80.6598575641753, 30.365946278198237],
            [80.65984797560367, 30.36601561322999],
            [80.65979460803482, 30.366401519514568],
            [80.65950755163226, 30.367003904306785],
            [80.65957559793554, 30.367925390144705],
            [80.6595804857509, 30.367991575750693],
            [80.65958013771325, 30.368095531983272],
            [80.6595790297485, 30.368427508622688],
            [80.65957739478102, 30.36891709324931],
            [80.6591899155822, 30.369997972228703],
            [80.65901607663068, 30.37063267056135],
            [80.65880212611728, 30.372190910582106],
            [80.65866561982256, 30.37288939782752],
            [80.65860020763353, 30.37322409761407],
            [80.65848057801634, 30.373599674285572],
            [80.65834858631825, 30.374014054005443],
            [80.65840329657493, 30.374369773646208],
            [80.65841640779104, 30.374455014987007],
            [80.65830595935262, 30.374726738847983],
            [80.65796502186834, 30.3750578998023],
            [80.65764862958065, 30.375420179098057],
            [80.65768012653666, 30.375691094468493],
            [80.65769952851048, 30.3758579861572],
            [80.65767227545518, 30.376483146280236],
            [80.65754030713953, 30.377102779170002],
            [80.65750477042889, 30.377414317816545],
            [80.65744035199072, 30.37797906418251],
            [80.65721478133713, 30.37817737008959],
            [80.65689093277041, 30.37846207476497],
            [80.6565987007695, 30.37924969112038],
            [80.65630818647367, 30.380047302541016],
            [80.65590618592182, 30.380441434024988],
            [80.65554821347934, 30.380874373053928],
            [80.65551318938225, 30.38119752194814],
            [80.6549820974459, 30.38145622182725],
            [80.65493885894125, 30.38147728394955],
            [80.65458567089206, 30.381854593614662],
            [80.65427056283625, 30.382033742163514],
            [80.65409191610911, 30.382021165144693],
            [80.65369704088579, 30.381993363503],
            [80.65354691166073, 30.382134889813358],
            [80.6533772815364, 30.38229479916572],
            [80.65281792570676, 30.38264385752808],
            [80.65270147339436, 30.38273847609986],
            [80.65231529101794, 30.38305224776309],
            [80.65186747540372, 30.383059855128238],
            [80.65164223300269, 30.38306368084426],
            [80.6511002349879, 30.383280416558023],
            [80.65072553155807, 30.38368867099541],
            [80.65060766101482, 30.383817095082918],
            [80.65028739534705, 30.384273492927434],
            [80.64987924882831, 30.38509837359112],
            [80.64981984680855, 30.385218426789038],
            [80.64945758999585, 30.38554331407272],
            [80.6490418603936, 30.386021856823277],
            [80.64873773485863, 30.386592303092357],
            [80.64797658555102, 30.38702706774501],
            [80.6475525624009, 30.387273589904282],
            [80.64749157757433, 30.387309045675977],
            [80.64708780715665, 30.38734351489137],
            [80.64653952737734, 30.387431375057986],
            [80.64603562464441, 30.387685414650207],
            [80.64570178371042, 30.388080798889803],
            [80.64523557246378, 30.388285810142065],
            [80.64510387934064, 30.3882841104234],
            [80.64479634267713, 30.38828014261452],
            [80.64446666560417, 30.388275887921964],
            [80.64399127138012, 30.38834753421139],
            [80.6436133070086, 30.38855118029278],
            [80.64346976711613, 30.388628519290762],
            [80.64277130864906, 30.388926609475504],
            [80.64192708986337, 30.389366816825202],
            [80.64134147832664, 30.389753193455192],
            [80.64088698974285, 30.390101461313463],
            [80.64034829134062, 30.390568527815333],
            [80.63994815598267, 30.391066878133984],
            [80.63968819705269, 30.391864797122764],
            [80.63977524513149, 30.39232109064585],
            [80.63976397482764, 30.3929724642079],
            [80.63954573364873, 30.393376697776432],
            [80.6394847758018, 30.393450021301476],
            [80.63922745548274, 30.393759530178215],
            [80.6391957615752, 30.393820694869248],
            [80.63903271089094, 30.394135362257202],
            [80.63876736682056, 30.39440198516354],
            [80.63843763668763, 30.394503408005903],
            [80.63802719599835, 30.394708210615477],
            [80.63798304648049, 30.395010917020272],
            [80.6381004547722, 30.395310730305994],
            [80.63814444780809, 30.395423070018694],
            [80.63820172652851, 30.395788871059608],
            [80.63813226019573, 30.396367215174905],
            [80.63804828420103, 30.39700669520289],
            [80.63774859592104, 30.397693551516227],
            [80.63744229672466, 30.398265450190422],
            [80.6372213521837, 30.398436708087672],
            [80.63693568073711, 30.39843046769198],
            [80.63628363718016, 30.3983786991177],
            [80.6358735481258, 30.398466198656195],
            [80.63567343997732, 30.398711529213756],
            [80.63561488511891, 30.39898580804868],
            [80.6354725341302, 30.39943209661402],
            [80.63560681280421, 30.399906613999065],
            [80.635914087765, 30.400438251823914],
            [80.6360232339851, 30.401010547998453],
            [80.63596126979684, 30.401402485136657],
            [80.63597174420067, 30.401799712087097],
            [80.63607338198108, 30.402125775684055],
            [80.63616145978358, 30.402560641060802],
            [80.63612934049672, 30.40307067986805],
            [80.6358360356046, 30.403527225201344],
            [80.63565469270998, 30.403710568188217],
            [80.6355490331614, 30.403817391459484],
            [80.63511964105862, 30.404253938568445],
            [80.63514345150912, 30.40451641649946],
            [80.6349507573716, 30.404807431717643],
            [80.63472257418749, 30.404908756533928],
            [80.63448689065751, 30.405165477904518],
            [80.63429062081553, 30.405596819737468],
            [80.63391481032028, 30.405697097742916],
            [80.63387295137568, 30.405708268222043],
            [80.63367251407533, 30.405988997893985],
            [80.63293198802091, 30.40675285955689],
            [80.63229517088547, 30.407161793881755],
            [80.63157566918187, 30.40747528585581],
            [80.63115296893443, 30.408063472151582],
            [80.6305171590397, 30.40863401105082],
            [80.62997846153684, 30.408840717525152],
            [80.6298729144035, 30.40888121669485],
            [80.62969700341404, 30.40894871621032],
            [80.62966296047728, 30.40902990700465],
            [80.6293355703803, 30.409810696606428],
            [80.62921603159464, 30.410274966216775],
            [80.6291528506236, 30.410520349834314],
            [80.62880548838262, 30.41076383947967],
            [80.6284747833846, 30.41099565142821],
            [80.62836125836543, 30.4114077756484],
            [80.62828898344986, 30.41187283126618],
            [80.62842877766599, 30.41225654770159],
            [80.62848202562503, 30.41240270731913],
            [80.62857729350736, 30.41307769348083],
            [80.62845997514779, 30.413440998003693],
            [80.62807478292495, 30.41395480586965],
            [80.62799676044222, 30.414058879913455],
            [80.62791782514756, 30.414505149593026],
            [80.62804876463883, 30.41464305073623],
            [80.62845640933585, 30.415072364598018],
            [80.62854730381508, 30.41549586254405],
            [80.62878774385933, 30.4161776718621],
            [80.62943512173354, 30.416121813171173],
            [80.63006303108409, 30.416205827836734],
            [80.63053697919827, 30.41667394924343],
            [80.63073216895447, 30.417012319163234],
            [80.63070142563032, 30.41745361109531],
            [80.63084472540379, 30.41801747252839],
            [80.63030482280936, 30.418410558100845],
            [80.63025543114327, 30.418417824622964],
            [80.62971161919592, 30.418497826513544],
            [80.62958743631117, 30.418516094442282],
            [80.62892986102435, 30.418898388150126],
            [80.62879937659, 30.419046148560938],
            [80.628527418006, 30.419354116899],
            [80.62849797959814, 30.4193775676207],
            [80.62841973947945, 30.41943989693374],
            [80.62781046857833, 30.41992526014269],
            [80.62775940417322, 30.41996593917679],
            [80.62721733601131, 30.42052597309521],
            [80.62705451465416, 30.420736990919465],
            [80.62676357587839, 30.42111404697573],
            [80.62645340599994, 30.42227338370975],
            [80.62633852570247, 30.42248613812484],
            [80.62607889592437, 30.422966960656765],
            [80.62561375217297, 30.423588662886516],
            [80.62496553882863, 30.424539259779067],
            [80.62464168846321, 30.424946524062875],
            [80.6244000073545, 30.425475766993088],
            [80.62447791292539, 30.425893422043828],
            [80.62453766837876, 30.426213778543115],
            [80.62421764103135, 30.427238718791898],
            [80.62419368309207, 30.427357928425693],
            [80.62417551678675, 30.427448322881844],
            [80.62414752179075, 30.427587621571547],
            [80.62401298770925, 30.4282570301379],
            [80.62399030500865, 30.428369893256047],
            [80.62414568087854, 30.42915808607688],
            [80.62439575895718, 30.430394132283425],
            [80.62431748646287, 30.43105036218452],
            [80.62428385811364, 30.431199221566885],
            [80.62423491790719, 30.43141586015389],
            [80.62421239798385, 30.431515544606725],
            [80.62410730770614, 30.431980727928305],
            [80.62409292305, 30.432140193914847],
            [80.62401290497166, 30.43302719884622],
            [80.62368009735866, 30.433829503828576],
            [80.62362927217322, 30.43392332560103],
            [80.62335408592236, 30.43443130675945],
            [80.62309341472934, 30.434840857119923],
            [80.6229521950878, 30.435062729761228],
            [80.62237903736275, 30.43545494478991],
            [80.62179958618202, 30.43569197820227],
            [80.62158586049912, 30.43588416782012],
            [80.6215552376841, 30.435911705960507],
            [80.62133652615984, 30.436108378698748],
            [80.62119449892703, 30.43626828355451],
            [80.6209113293944, 30.436587094119147],
            [80.62059634994165, 30.43694171568768],
            [80.61991179319477, 30.437637951930014],
            [80.61918089168483, 30.437850659580306],
            [80.61871088709779, 30.43837322324572],
            [80.6185652400938, 30.438535155173327],
            [80.61847193812952, 30.438617238095105],
            [80.61826837208775, 30.438796325490102],
            [80.61824350853118, 30.438818197901583],
            [80.61811346386531, 30.438932605155628],
            [80.61806326370873, 30.438976768163343],
            [80.61794322759795, 30.43908237015529],
            [80.61784567184031, 30.439185502608893],
            [80.617217031341, 30.439850081815848],
            [80.6168579527324, 30.440385982427188],
            [80.61679714687091, 30.440476731216222],
            [80.61635101478754, 30.44114254159507],
            [80.615838241142, 30.441928776591794],
            [80.61537299306923, 30.442888214719403],
            [80.61486568100645, 30.443665345879083],
            [80.61467310288145, 30.4439603450939],
            [80.61460108157473, 30.44404392988349],
            [80.61379431505566, 30.444980222159586],
            [80.61370592338972, 30.44516441680446],
            [80.61350262084932, 30.445588068534562],
            [80.61295996273213, 30.44586246967731],
            [80.61279863245096, 30.44594404718009],
            [80.61263635608304, 30.44598631171897],
            [80.61249731459947, 30.44602252382049],
            [80.61231229257828, 30.44607071129434],
            [80.6115299183711, 30.446274470690298],
            [80.61002762067818, 30.446449022804188],
            [80.60915891964851, 30.446649327004877],
            [80.60860596968979, 30.447125089051667],
            [80.60838478862718, 30.44731539369053],
            [80.60761160279327, 30.44774274882883],
            [80.60696277970857, 30.448074990768248],
            [80.60636756970763, 30.44877739006455],
            [80.60580187365758, 30.44899052219489],
            [80.60420231788271, 30.449355680220492],
            [80.6029628749368, 30.449244495237338],
            [80.60109194064063, 30.44908786930978],
            [80.59946731176706, 30.44887547462349],
            [80.59211110834104, 30.445748264770657],
            [80.58448433668286, 30.445610182863675],
            [80.57510315049007, 30.442446501904612],
            [80.57020262774915, 30.438894168131412],
            [80.56736483771715, 30.43737396313793],
            [80.56484684030949, 30.43447063051525],
            [80.56253673648126, 30.431546476788526],
            [80.56082615760113, 30.429763522268786],
            [80.5585026277941, 30.42800483775312],
            [80.55575242273619, 30.42645843011252],
            [80.55342122530936, 30.424837765450548],
            [80.55094706175754, 30.423374348147718],
            [80.55069118934699, 30.422767397497466],
            [80.54908527176792, 30.420755096970197],
            [80.54771833373417, 30.419422970793505],
            [80.54636840277965, 30.418202615958023],
            [80.54458973173081, 30.41731080325104],
            [80.5430514908337, 30.416250810126826],
            [80.54145208794358, 30.415811243094424],
            [80.54099990791946, 30.41528896361484],
            [80.53952862514598, 30.415355842598103],
            [80.53762093106116, 30.416997479450174],
            [80.53680855007457, 30.417631453828562],
            [80.53405001369725, 30.42092188523685],
            [80.53343126933765, 30.42177288491075],
            [80.53088106900617, 30.423045113541207],
            [80.52912204364748, 30.423651640610785],
            [80.52720495704318, 30.425256612103],
            [80.5277332089205, 30.4259130128753],
            [80.5282256515926, 30.426500288157797],
            [80.52936514298699, 30.427998607250004],
            [80.52991105575069, 30.42896196282436],
            [80.53041185312458, 30.430084084712803],
            [80.53066870759483, 30.431766309767454],
            [80.53063702357986, 30.433077334251607],
            [80.53070308957598, 30.434425864062405],
            [80.53055285782818, 30.43525644462926],
            [80.52996649265958, 30.436201422557644],
            [80.53068341600692, 30.437058958504167],
            [80.53278979551271, 30.43874947800606],
            [80.53688234844168, 30.44080073896299],
            [80.53798839494851, 30.441163228700077],
            [80.53950498376804, 30.443641485965884],
            [80.54048815230647, 30.44570318175647],
            [80.54242945495542, 30.448360132514154],
            [80.545811333926, 30.448582775874456],
            [80.54766194983915, 30.44856353128199],
            [80.5497268391224, 30.448801186125877],
            [80.55108768334998, 30.44924795942586],
            [80.55269763618702, 30.44999920270112],
            [80.55419205042284, 30.45074350770676],
            [80.55500610594709, 30.45133578861578],
            [80.55522335787174, 30.452362745144626],
            [80.5575678679628, 30.45271215783987],
            [80.55925606451581, 30.453354482423492],
            [80.56123975131351, 30.454115601154115],
            [80.5615342954714, 30.454976777556283],
            [80.56152616380149, 30.455702972014592],
            [80.56362988401202, 30.457126105985083],
            [80.56521943842642, 30.45851417089233],
            [80.56682577508951, 30.4596837670922],
            [80.56719675172877, 30.46023423402272],
            [80.56679985572879, 30.461488469917015],
            [80.56665133359223, 30.462973439472705],
            [80.56672035116333, 30.464006240695483],
            [80.56894808248808, 30.467556180473423],
            [80.57005787916785, 30.464387741201335],
            [80.571828584022, 30.464447729579092],
            [80.57404133713806, 30.46441843776074],
            [80.57504454347094, 30.464759602773483],
            [80.5767866651725, 30.46465603774601],
            [80.57790417893477, 30.463973601600458],
            [80.57924743691973, 30.464387408452183],
            [80.58010929950461, 30.465749571985214],
            [80.58344837074577, 30.46796101388975],
            [80.58590661809603, 30.468548130891577],
            [80.58652082267787, 30.468546825975295],
            [80.58711968482567, 30.468549016723784],
            [80.58809971383079, 30.468416108616566],
            [80.59106075594917, 30.468278710194397],
            [80.59287378919407, 30.468248724998716],
            [80.59445908801655, 30.46850094975906],
            [80.59585579540868, 30.46843005170558],
            [80.59773727875245, 30.467962311611416],
            [80.5988354804731, 30.467995930068184],
            [80.59950656897303, 30.46918777549547],
            [80.59959850306853, 30.46974948664956],
            [80.59978296930865, 30.47114093030973],
            [80.60005960886389, 30.47226403155986],
            [80.60008659661918, 30.472373598662728],
            [80.6007330248093, 30.47307929217567],
            [80.60144422487116, 30.473036768632028],
            [80.60210032796783, 30.472997535707748],
            [80.60309289711955, 30.472895528306026],
            [80.60372779240373, 30.47295417219732],
            [80.60416181871301, 30.47299425947756],
            [80.60583736180484, 30.47301842965686],
            [80.60678290990342, 30.473059680659787],
            [80.60724818045924, 30.473079975660426],
            [80.60738792791062, 30.473085511886893],
            [80.60803075791318, 30.47311097529132]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "DOTI",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [81.18678869179823, 29.397861843616734],
            [81.17066754010649, 29.411629591502106],
            [81.16371077652003, 29.394756774288194],
            [81.14398553956491, 29.390940315631738],
            [81.09568042847815, 29.407842792723134],
            [81.06401767652011, 29.40550454340334],
            [81.00847722847985, 29.419843234476232],
            [80.98687711781893, 29.40517190093375],
            [80.93935299694164, 29.397839048465617],
            [80.92659172453651, 29.425480599161105],
            [80.9082703449273, 29.441461988081823],
            [80.87336343171904, 29.41166741222816],
            [80.85200851388987, 29.405943056291026],
            [80.85316286664082, 29.378659512183752],
            [80.83581845018469, 29.38121761574412],
            [80.81467573911041, 29.364847327146045],
            [80.78580241793152, 29.366428128956272],
            [80.75766281300918, 29.344741129163076],
            [80.77612144266078, 29.315862012330655],
            [80.77208254158086, 29.301502061526698],
            [80.73766861662018, 29.30220137926506],
            [80.74382702924225, 29.27484246443707],
            [80.75689578283637, 29.26837492226701],
            [80.73896258330359, 29.2469725844865],
            [80.7068373554084, 29.2314023981308],
            [80.704509738989, 29.192391032084007],
            [80.62938495917136, 29.176391420045636],
            [80.61980719420926, 29.181108359548915],
            [80.5922011960291, 29.163044404268163],
            [80.58872748230124, 29.147399267727142],
            [80.56749967585499, 29.14488493167146],
            [80.5669733414248, 29.119248955966036],
            [80.52675305176925, 29.105959712871538],
            [80.53165135923717, 29.091540125183787],
            [80.56117175461715, 29.068159677107843],
            [80.59681807320382, 29.04819735810635],
            [80.61565463822133, 29.059156998567776],
            [80.64336414886169, 29.028627011066586],
            [80.70365034277457, 29.00960815733857],
            [80.73850206511058, 29.01081291140894],
            [80.76357144267247, 28.999871307723012],
            [80.76071156124063, 28.98950524257284],
            [80.79886274489016, 28.976842649317703],
            [80.80592668257901, 28.94396524942297],
            [80.8259140271964, 28.931741139974616],
            [80.85059159638313, 28.974712865593162],
            [80.88197512521474, 28.967117029257658],
            [80.88109977506284, 28.94583025860765],
            [80.91081183574627, 28.9311270658915],
            [80.92415007828902, 28.942831826084245],
            [80.95330527904753, 28.935845093838502],
            [80.98105119931388, 28.938782292352755],
            [80.99863235284245, 28.954935441416534],
            [81.0189205689916, 28.95222032554883],
            [81.04816547389906, 28.97802434575099],
            [81.10382977658456, 28.96567151749217],
            [81.14529160615716, 28.993467822745774],
            [81.14510490312833, 29.033817799021396],
            [81.10197074286359, 29.100782609941074],
            [81.06503444451718, 29.13650335789396],
            [81.04329633266188, 29.17545130771875],
            [81.0268342949157, 29.189024013953603],
            [81.03545557688508, 29.199032997762735],
            [81.063787281266, 29.1982827412008],
            [81.09965092058097, 29.214799900709757],
            [81.11509935990604, 29.234347203371016],
            [81.09789249576691, 29.247281770199564],
            [81.12069026104498, 29.299370488490656],
            [81.13568161932407, 29.30488491365312],
            [81.15326280238372, 29.38160499978504],
            [81.17000548911318, 29.386671240168813],
            [81.18678869179823, 29.397861843616734]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        Name: "KAILALI",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.98105119931388, 28.938782292352755],
            [80.95330527904753, 28.935845093838502],
            [80.92415007828902, 28.942831826084245],
            [80.91081183574627, 28.9311270658915],
            [80.88109977506284, 28.94583025860765],
            [80.88197512521474, 28.967117029257658],
            [80.85059159638313, 28.974712865593162],
            [80.8259140271964, 28.931741139974616],
            [80.80592668257901, 28.94396524942297],
            [80.79886274489016, 28.976842649317703],
            [80.76071156124063, 28.98950524257284],
            [80.76357144267247, 28.999871307723012],
            [80.73850206511058, 29.01081291140894],
            [80.70365034277457, 29.00960815733857],
            [80.64336414886169, 29.028627011066586],
            [80.61565463822133, 29.059156998567776],
            [80.59681807320382, 29.04819735810635],
            [80.56117175461715, 29.068159677107843],
            [80.53426291192966, 29.0297660740939],
            [80.53553104856769, 29.01401606548146],
            [80.50146215209838, 28.993357401017],
            [80.48573172499421, 28.998240565906734],
            [80.48958089417687, 28.985753719832598],
            [80.4681980490448, 28.938657998021224],
            [80.48168467302857, 28.936842830385146],
            [80.50786228423883, 28.89686213798038],
            [80.49715909705958, 28.854117632395322],
            [80.51470806063094, 28.83510437131982],
            [80.51999890127837, 28.810960941597436],
            [80.55028759784724, 28.777877084995357],
            [80.5358200949073, 28.768218187776316],
            [80.55008796263373, 28.744047491502357],
            [80.53822203689941, 28.738739969145684],
            [80.55941314144266, 28.72313686822855],
            [80.53817206184256, 28.71335215076863],
            [80.55426209404806, 28.69079313659498],
            [80.59046002243988, 28.679785911761098],
            [80.58023688775428, 28.661339633650076],
            [80.6062994153137, 28.65242352906592],
            [80.61162226840848, 28.639356936214195],
            [80.65128289198012, 28.633973854670284],
            [80.66765928933576, 28.64194912242548],
            [80.67744054314363, 28.60440473845258],
            [80.69565920394986, 28.603508355386946],
            [80.69032368462035, 28.58713619010849],
            [80.71467573458781, 28.567944084608673],
            [80.73845477782851, 28.574274784467136],
            [80.76808476019256, 28.565611639077215],
            [80.7695816171004, 28.549766069020006],
            [80.78931671884519, 28.54361225717673],
            [80.78459689600248, 28.52934258917591],
            [80.81552182940408, 28.520720643485543],
            [80.84453547199635, 28.49547184528117],
            [80.86560339036532, 28.48962652157486],
            [80.8758960500442, 28.50982289620842],
            [80.90866947926112, 28.4976371814323],
            [80.90154431896299, 28.485912370480563],
            [80.91570034174002, 28.457706039136113],
            [80.97989435570018, 28.455321239661494],
            [80.96490599336664, 28.444791656835893],
            [80.97915459933594, 28.43441467083401],
            [80.99976586693727, 28.454068414316232],
            [81.01751905851056, 28.451700729036304],
            [81.01680472429021, 28.41583242303908],
            [81.06738595382062, 28.39451096516038],
            [81.05920102890062, 28.40852462668337],
            [81.08499319775686, 28.43734514966448],
            [81.08939576154222, 28.454679721465617],
            [81.15277240842477, 28.501888696268104],
            [81.16745113429685, 28.52332357857457],
            [81.24865196771826, 28.594146902537414],
            [81.28631595311658, 28.645498922526688],
            [81.2853381305857, 28.6733143244241],
            [81.26703395120057, 28.7062722758416],
            [81.27049211404982, 28.72864398850447],
            [81.25612423195447, 28.729984867937763],
            [81.25956143339151, 28.750166408430008],
            [81.2457017942531, 28.78199814278072],
            [81.22611770988784, 28.797051990830415],
            [81.24227216842736, 28.81039682867388],
            [81.23375426282928, 28.82948081662724],
            [81.20024421343048, 28.8626801691522],
            [81.1952103851946, 28.833061241521015],
            [81.20507067363006, 28.81753743894808],
            [81.1577072481563, 28.843902379004177],
            [81.14443264980447, 28.87245173529451],
            [81.12551819455238, 28.85137152424275],
            [81.10179127398044, 28.84526454359203],
            [81.07392639300556, 28.875154965498353],
            [81.05763988600506, 28.873613153911236],
            [81.02159655001009, 28.91946054455933],
            [80.98105119931388, 28.938782292352755]
          ]
        ]
      }
    },

    {
      type: "Feature",
      properties: {
        Name: "KANCHANPUR",
        Province: "Sudurpaschim"
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.48573172499421, 28.998240565906734],
            [80.47183464564384, 28.996846778789983],
            [80.47829424957848, 29.017708589115724],
            [80.46052362990818, 29.03681689579257],
            [80.42034247118282, 29.026498629368646],
            [80.4152463842961, 29.032673051655244],
            [80.34737852013428, 29.05408772944852],
            [80.30128463178144, 29.058529637563936],
            [80.27234041040803, 29.045944536863985],
            [80.25127787780836, 29.052564415623152],
            [80.22774501725945, 29.04558504196757],
            [80.18653777685519, 29.086740315791403],
            [80.18551261240606, 29.135199313088],
            [80.1444739747175, 29.103041976072586],
            [80.12859747481727, 29.06109954203713],
            [80.13658172738357, 29.058927027295518],
            [80.11804112331616, 28.98171880710912],
            [80.1003490963608, 28.986498714351264],
            [80.05858430074385, 28.916451360540453],
            [80.06484975309563, 28.83740096577619],
            [80.07508629877904, 28.824410925895346],
            [80.11821765297772, 28.82884794903081],
            [80.17143333814695, 28.782148064882996],
            [80.2161553869326, 28.75543358624684],
            [80.25069832501164, 28.757865014022165],
            [80.26517062875052, 28.74358395661362],
            [80.25629955284064, 28.72943448722236],
            [80.27812887241735, 28.709816514158998],
            [80.31737848375721, 28.69989152835046],
            [80.32599737923074, 28.666406068847817],
            [80.376435459225, 28.628385837022115],
            [80.4147811338605, 28.639222718010828],
            [80.46061228540937, 28.621683835946996],
            [80.47167250079276, 28.582697976418434],
            [80.47916681225045, 28.58554686270284],
            [80.52283693271237, 28.55287024004517],
            [80.52177920965208, 28.576806435513014],
            [80.50358570229086, 28.665271846699934],
            [80.53990117658331, 28.690695410574836],
            [80.55426209404806, 28.69079313659498],
            [80.53817206184256, 28.71335215076863],
            [80.55941314144266, 28.72313686822855],
            [80.53822203689941, 28.738739969145684],
            [80.55008796263373, 28.744047491502357],
            [80.5358200949073, 28.768218187776316],
            [80.55028759784724, 28.777877084995357],
            [80.51999890127837, 28.810960941597436],
            [80.51470806063094, 28.83510437131982],
            [80.49715909705958, 28.854117632395322],
            [80.50786228423883, 28.89686213798038],
            [80.48168467302857, 28.936842830385146],
            [80.4681980490448, 28.938657998021224],
            [80.48958089417687, 28.985753719832598],
            [80.48573172499421, 28.998240565906734]
          ]
        ]
      }
    }
  ]
};

export default mapData;

import { Box, InputAdornment, Typography, TextField } from "@mui/material";
import { round } from "mathjs";
import React from "react";
import { VendorCommission, Source } from "../../../../interfaces/Commission";

export default function ServiceProviderCommissionRow({
  item,
  onChange,
  index,
  isSaved = false
}: {
  item: VendorCommission;
  onChange: (item: VendorCommission, changer?: string) => void;
  index: number;
  isSaved: boolean;
}): JSX.Element {
  const changeHandler = ({ target }) => {
    if (target.name === "rateAmt") {
      onChange({ ...item, [target.name]: Number(target.value) }, "rateAmt");
    } else if (target.name === "rate") {
      let value = Number(target.value);
      value = Math.min(value, 100); // Ensure the rate does not exceed 100
      onChange({ ...item, [target.name]: value }, "rate");
    } else {
      onChange({ ...item, [target.name]: Number(target.value) });
    }
  };

  return (
    <>
      {item.isSubItem && item.packageName && item.allocatedAmt && (
        <Box display="flex">
          <Typography>{`Package Name: ${item.packageName}, Rs: ${item.netAllocatedAmt}`}</Typography>
        </Box>
      )}

      <Box
        borderTop={`${index === 0 || item.isSubItem ? "" : "0.5px solid #c1bdbd"}`}
        mb="10px"
        gap={2}
        pt={`${item.isSubItem ? "10px" : "30px"}`}
        display="flex"
        alignItems="center"
      >
        <TextField
          sx={{ minWidth: "200px" }}
          value={item.serviceProviderFullName || ""}
          variant="outlined"
          label="Service Provider"
          disabled
        />
        <TextField
          sx={{ minWidth: "200px" }}
          value={item.description}
          variant="outlined"
          label={item.source === Source.STOCKS ? "Product" : "Service"}
          disabled
        />
        <TextField
          sx={{ flexGrow: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="gray">Rs</Typography>
              </InputAdornment>
            )
          }}
          value={round(item.netAmount || 0, 2)}
          variant="outlined"
          label="Total Amt"
          disabled
        />
        <TextField
          type="number"
          sx={{ flexGrow: 1 }}
          onChange={changeHandler}
          onFocus={(e) => e.target.select()}
          value={round(item.allocatedAmt || 0, 2)}
          variant="outlined"
          label="Allocated Amt"
          name="allocatedAmt"
          data-testmation="allocation"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="gray">Rs</Typography>
              </InputAdornment>
            )
          }}
          disabled={isSaved}
        />
        <TextField
          type="number"
          sx={{ flexGrow: 1 }}
          onChange={changeHandler}
          onFocus={(e) => e.target.select()}
          value={round(item.materialChargePercent || 0, 2)}
          variant="outlined"
          label="Material Charge (%)"
          name="materialChargePercent"
          data-testmation="materialCharge"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography color="gray">%</Typography>
              </InputAdornment>
            )
          }}
          disabled={isSaved}
        />
        <TextField
          type="number"
          sx={{ flexGrow: 1 }}
          onFocus={(e) => e.target.select()}
          name="labCharge"
          onChange={changeHandler}
          value={round(item.labCharge || 0, 2)}
          variant="outlined"
          label="Lab Charge"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="gray">Rs</Typography>
              </InputAdornment>
            )
          }}
          disabled={isSaved}
        />
        <TextField
          type="number"
          sx={{ flexGrow: 1 }}
          onFocus={(e) => e.target.select()}
          name="rate"
          onChange={changeHandler}
          value={round(item.rate || 0, 2)}
          variant="outlined"
          label="Rate (%)"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography color="gray">%</Typography>
              </InputAdornment>
            )
          }}
          disabled={isSaved}
        />
        <TextField
          type="number"
          sx={{ flexGrow: 1 }}
          onFocus={(e) => e.target.select()}
          name="rateAmt"
          onChange={changeHandler}
          value={round(item.rateAmt || 0, 2)}
          variant="outlined"
          label="Rate (Amt)"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="gray">Rs</Typography>
              </InputAdornment>
            )
          }}
          disabled={isSaved}
        />
        <TextField
          type="number"
          sx={{ flexGrow: 1 }}
          onFocus={(e) => e.target.select()}
          name="tds"
          onChange={changeHandler}
          value={round(item.tds || 0, 2)}
          variant="outlined"
          label="TDS"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography color="gray">%</Typography>
              </InputAdornment>
            )
          }}
          disabled={isSaved}
        />
      </Box>
    </>
  );
}

import React, { useEffect } from "react";
import { initialize, cleanup } from "./fb";

function FacebookChat(): JSX.Element {
  useEffect(() => {
    initialize();

    return () => {
      cleanup();
    };
  }, []);

  return (
    <div>
      <div id="fb-root" />
      <div id="fb-customer-chat" className="fb-customerchat" />
    </div>
  );
}

export default FacebookChat;

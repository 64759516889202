import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import KeyboardArrowDownSharp from "@mui/icons-material/KeyboardArrowDownSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { fetchClientByReferenceId } from "../../actions/client";
import { updateUserId, getByUserReferenceId, verifyUserAccessToken } from "../../actions/user";
import { loginViaFacebook } from "../../actions/authentication";
import localStorage from "../../helpers/localStorage";
import style from "./style.module.css";
const logo = require("../../../assets/images/okhati_full_green.png");

interface signupProps {
  client;
  userDetails: [];
  match;
  fetchClientByReferenceId: (referenceId: string) => void;
  updateUserId: (userId: string, data: object) => void;
  getByUserReferenceId: (referenceId: string) => void;
  verifyUserAccessToken: (token: object) => void;
  redirectToLogin: () => void;
}

const Signup: React.FC<signupProps> = ({
  client,
  userDetails,
  match,
  fetchClientByReferenceId,
  updateUserId,
  getByUserReferenceId,
  verifyUserAccessToken,
  redirectToLogin
}) => {
  const referenceId = match.params.referenceId;

  const [user, setUser] = React.useState(null);
  const [fbLoggedIn, setFbLoggedIn] = React.useState(false);
  const [buttonRendered, setButtonRendered] = React.useState(false);

  const onStatusChange = (response) => {
    if (response.status === "connected") {
      window.FB.api("/me?fields=id,name,picture", (meResponse) => {
        const userId = response.authResponse.userID;
        localStorage.setItem(
          "FacebookResponse",
          JSON.stringify({
            userId: response.authResponse.userID,
            token: response.authResponse.accessToken,
            username: user.username,
            facebookSpId: user.facebookSpId,
            referenceId
          })
        );
        if (user.facebook && user.facebookSpId) {
          let data = {
            token: response.authResponse.accessToken,
            userId: userId,
            referenceId,
            user
          };
          verifyUserAccessToken(data);
        } else {
          updateUserId(referenceId, { userId });
        }
      });
    } else if (response.status === "not_authorized") {
      setFbLoggedIn(true);
    }
  };

  const initUserSession = () => {
    if (window.FB) {
      window.FB.XFBML.parse();
      window.FB.Event.subscribe("xfbml.render", () => {
        setButtonRendered(true);
      });
      window.FB.Event.subscribe("auth.statusChange", onStatusChange);
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          onStatusChange(response);
        } else if (response.status === "not_authorized") {
          setFbLoggedIn(true);
        }
      });
    } else {
      setTimeout(initUserSession, 100);
    }
  };

  React.useEffect(() => {
    fetchClientByReferenceId(referenceId);
    getByUserReferenceId(referenceId);
  }, []);

  React.useEffect(() => {
    if (user && !buttonRendered) initUserSession();
    if (client?.response?.status === 404) {
      redirectToLogin();
    }
    return () => {
      if (window.FB) {
        window.FB.Event.unsubscribe("auth.statusChange", onStatusChange);
      }
    };
  }, [user]);

  React.useEffect(() => {
    if (userDetails.length > 0) {
      setUser(userDetails[0]);
    }
  }, [userDetails]);

  return (
    <Box className={style.signUpContainer}>
      <Box className={style.signupLogo}>
        <img className={style.logo} src={logo} />
      </Box>

      <Box className={style.signUpPanel}>
        <Box>
          {/* <Typography variant="h6" className={style.userGreeting}>
            {fbLoggedIn ? (
              <b>Hi {capitalize(client?.firstName)}! Let's sign up!</b>
            ) : (
              <b>Hi {capitalize(client?.firstName)}!</b>
            )}
          </Typography> */}
          {!fbLoggedIn && (
            <Typography variant="h6" className={style.userGreeting}>
              Thank you for choosing <b>Okhati.</b>
            </Typography>
          )}
          {fbLoggedIn ? (
            <Typography variant="body1" className={style.signUpContent}>
              <Box>Please click the button below to signup with facebook</Box>
              <Box>and easily access your appointments and health records</Box>
              <Box>easily anytime in future.</Box>
            </Typography>
          ) : (
            <Typography variant="body1" className={style.signUpContent}>
              <Box>
                <b>Please signup</b> for <b>Okhati</b> system with your facebook credentials.
              </Box>
              {/* <Box>to be able to manage your appointments,</Box>
              <Box>access your health records anytime in future and connect with your doctor.</Box> */}
            </Typography>
          )}
          <Typography className={style.arrowDownBody}>
            <KeyboardArrowDownSharp className={style.arrowDown} />
          </Typography>
        </Box>

        <Box>
          {!buttonRendered && <CircularProgress size={25} className={style.spinner} />}
          <Box
            className="fb-login-button"
            data-width="265"
            data-size="large"
            data-button-type="continue_with"
            data-auto-logout-link="false"
            data-use-continue-as="true"
          />
        </Box>

        {buttonRendered && (
          <Box>
            <Typography variant="caption">
              Signing up, you accept Okhati's <Link color="textPrimary">terms and conditions</Link>.
            </Typography>
          </Box>
        )}

        {!fbLoggedIn && buttonRendered && (
          <Box marginTop={4}>
            <Link color="textPrimary">Sign up with email</Link>
            <Box marginTop={1}>
              <Link color="textPrimary" underline="none">
                Help
              </Link>
              <Link color="textPrimary" underline="none" style={{ paddingLeft: "24px" }}>
                नेपालीमा
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default connect(
  (state) => ({
    client: state.clients.lastTouched,
    userDetails: state.userContext
  }),
  (dispatch) => ({
    fetchClientByReferenceId: async (referenceId) => {
      await dispatch(fetchClientByReferenceId(referenceId));
    },
    updateUserId: async (refId, attrs) => {
      await dispatch(updateUserId(refId, attrs));
    },
    getByUserReferenceId: async (referenceId) => {
      await dispatch(getByUserReferenceId(referenceId));
    },
    verifyUserAccessToken: async (data) => {
      await dispatch(verifyUserAccessToken(data));
      if (data.user.facebookSpId) {
        await dispatch(
          loginViaFacebook({
            userId: data.userId,
            token: data.token
          })
        );
      }
    },
    redirectToLogin: () => {
      dispatch(push("/"));
    }
  })
)(Signup);

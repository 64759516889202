import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getRcDepartments } from "../slices/departmentSlice";
import { RootState } from "../store/index";
import { Department } from "../interfaces/DepartmentInterface";

const useGetDepartments = (
  options: { showDeletedDepartments?: boolean; resourceCentreId?: number | null } = {
    showDeletedDepartments: false,
    resourceCentreId: null
  }
): Department[] => {
  const dispatch = useDispatch();
  const departments: Department[] = useSelector((state: RootState) => state.departments);
  const hasDepartments = departments.length > 0;
  React.useEffect(() => {
    if (!hasDepartments) {
      dispatch(getRcDepartments(options.resourceCentreId || null));
    }
  }, [hasDepartments, dispatch, options?.resourceCentreId]);
  return options.showDeletedDepartments
    ? departments
    : departments.filter((department) => department.active);
};
export default useGetDepartments;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getResourceCentrePublicProfileApi,
  setResourceCentrePublicProfileApi,
  uploadBannerResourceCentre
} from "../api/resourceCentre";

export interface PublicProfileState {
  profileDetails: {
    publicName: string;
    bannerUrl: string;
    event: {
      ocHours: Array<{
        name: string;
        startTime: string;
        endTime: string;
        day: number;
        isHoliday: boolean;
      }>;
      closingDays: Array<Date | moment.Moment>;
    };
    blurHash: { logo: string | null; banner: string | null };
    about: string;
    address: { lat: number; lng: number } | null;
    accreditation: string;
    nphlCategory: string;
    okhatiIndex: number;
  };
}

export const getResourceCentrePublicProfile = createAsyncThunk(
  "resourceCentre/publicProfile",
  async (resourceCentreId: number) => {
    const response = await getResourceCentrePublicProfileApi(resourceCentreId);
    return response;
  }
);

export const setResourceCentrePublicProfile = createAsyncThunk(
  "resourceCentre/publicProfileSet",
  async (data: PublicProfileState["profileDetails"] & { resourceCentreId: number }) => {
    const response = await setResourceCentrePublicProfileApi(data);
    return response;
  }
);

export const updateResourceCentreBanner = createAsyncThunk(
  "resourceCentre/publicBannerSet",
  async (rcObj: { id: number; formData: FormData }) => {
    const response = await uploadBannerResourceCentre(rcObj);
    return response;
  }
);

const initialState: PublicProfileState = {
  profileDetails: {
    publicName: "",
    bannerUrl: "",
    about: "",
    event: {
      closingDays: [],
      ocHours: [
        {
          name: "sunday",
          startTime: "10:00",
          endTime: "18:00",
          day: 0,
          isHoliday: false
        },
        {
          name: "monday",
          startTime: "10:00",
          endTime: "18:00",
          day: 1,
          isHoliday: false
        },
        {
          name: "tuesday",
          startTime: "10:00",
          endTime: "18:00",
          day: 2,
          isHoliday: false
        },
        {
          name: "wednesday",
          startTime: "10:00",
          endTime: "18:00",
          day: 3,
          isHoliday: false
        },
        {
          name: "thursday",
          startTime: "10:00",
          endTime: "18:00",
          day: 4,
          isHoliday: false
        },
        {
          name: "friday",
          startTime: "10:00",
          endTime: "18:00",
          day: 5,
          isHoliday: false
        },
        {
          name: "saturday",
          startTime: "10:00",
          endTime: "18:00",
          day: 6,
          isHoliday: false
        }
      ]
    },
    address: null,
    blurHash: { logo: null, banner: null },
    accreditation: null,
    nphlCategory: null,
    okhatiIndex: null
  }
};

const publicProfileSlice = createSlice({
  name: "publicProfileSlice",
  initialState,
  reducers: {
    updateProfile: (draft, { payload }) => {
      draft.profileDetails = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getResourceCentrePublicProfile.fulfilled, (draft, { payload }) => {
      if (payload.event.ocHours.length) {
        draft.profileDetails = payload;
      }
    });
    builder.addCase(updateResourceCentreBanner.fulfilled, (draft, { payload }) => {
      draft.profileDetails.bannerUrl = payload.s3ResourceURL;
    });
    // builder.addCase(setResourceCentrePublicProfile.fulfilled, (draft, { payload }) => ({}));
  }
});

export default publicProfileSlice.reducer;
export const { updateProfile } = publicProfileSlice.actions;

import { PatchMedicalTestInterface } from "../actions/medicalTest";
import { FilterProps } from "../containers/Reports/SummaryReport/CentralMonitoringLabReport";
import { LabRecord, LabSummary, LabTestRecordResultData, Test } from "../interfaces/Lab";
import { LabTest, LabTestGroup } from "../interfaces/LabInterfaces";
import { Delete, Get, Patch, Post } from "./apiHelper";

const labTestRecordEndpointRoot = "/api/labTestRecord";
const labTestWithPCREndpointRoot = "/api/labTestRecord/createClientWithPCRTestReport";

export async function getLabTestsRecords(filters?: unknown): Promise<unknown> {
  const query = Object.keys(filters || {})
    .filter((key) => filters[key] !== undefined)
    .map((queryParamKey) => `${queryParamKey}=${filters[queryParamKey]}`)
    .join("&");
  const response = await Get(
    `${labTestRecordEndpointRoot}${query.length ? `?${query}` : ""}`,
    true
  );
  return response.data;
}

export async function getLabTestRecord(labTestRecordId: number, rcId?: number): Promise<LabTest> {
  const response = await Get(
    `${labTestRecordEndpointRoot}/${labTestRecordId}?resourceCentreId=${rcId || ""}`,
    true
  );
  return response.data;
}
// eslint-disable-next-line max-len
// eslint-disable-next-line camelcase
export async function getPreviouslyEditedLabs(): Promise<
  // eslint-disable-next-line camelcase
  { created_at: string; updated_at: string }[]
> {
  const response = await Get(`/api/updatableLabTests/recentlyUpdated`, true);
  // eslint-disable-next-line camelcase
  return response.data as { created_at: string; updated_at: string }[];
}

export async function updateMasterLabData(data: Array<LabTestGroup>): Promise<Array<LabTestGroup>> {
  const response = await Post(`/api/updatableLabTests`, data, true);
  return response.data as Array<LabTestGroup>;
}

export async function getUpdateableTests(testIds: Array<number>): Promise<Array<LabTestGroup>> {
  const response = await Get(`/api/updatableLabTests?testIds=${testIds}`, true);
  return response.data as Array<LabTestGroup>;
}

export async function getLabTestRecordsByClientId(
  clientId: number,
  query: { [key: string]: string | number }
): Promise<{ results: LabRecord[] }> {
  const response = await Get(`/api/clients/${clientId}/labTestRecord`, true, query);
  return response.data as { results: LabRecord[] };
}

export async function getLabTestsRecordByUUID(uuid: string): Promise<unknown> {
  const response = await Get(`${labTestRecordEndpointRoot}/${uuid}/external`, false);
  return response.data;
}

export async function postLabTestRecord(data: {
  info: Record<string, string>;
  requestedTests: Array<Test>;
}): Promise<unknown> {
  const response = await Post(labTestRecordEndpointRoot, data, true);
  return response.data;
}

export async function updatePostedLabTestRecord(data: {
  info: Record<string, string>;
  requestedTests: Array<Test>;
  id?: number;
}): Promise<LabRecord> {
  const response = await Patch(`${labTestRecordEndpointRoot}/${data.id}/tests`, data, true);
  return response.data as LabRecord;
}

export async function patchLabTestsRecord(
  id: number,
  data: PatchMedicalTestInterface
): Promise<unknown> {
  const response = await Patch(`${labTestRecordEndpointRoot}/${id}`, data, true);
  return response.data;
}

export async function patchLabTestRecordStatus(
  labTestId: number,
  status: string | Record<string, unknown>,
  remark: string,
  id: number,
  name: string,
  collectionDate: string
): Promise<unknown> {
  const response = await Patch(
    `${labTestRecordEndpointRoot}/${labTestId}/status`,
    { status, remark, id, name, collectionDate },
    true
  );
  return response.data;
}

export const labTestsAttachmentsUrl = (labEntryId: number): string => {
  if (labEntryId) {
    return `/api/attachments/labTest/${labEntryId}`;
  }
  return `/api/attachments/labTest` as string;
};

export async function batchFinaliseLabTests(
  labTestIds: Array<number>,
  requireApproval: boolean
): Promise<unknown> {
  const response = await Post(
    `${labTestRecordEndpointRoot}/batchFinalise`,
    { labTestIds, requireApproval },
    true
  );
  return response.data;
}

export async function createlabTestWithPCR(testsData: Record<string, unknown>): Promise<unknown> {
  return Post(`${labTestWithPCREndpointRoot}`, testsData, true);
}

export async function postTestGroupData(data: Record<string, unknown>): Promise<unknown> {
  const response = await Post(`/api/labTestGroup`, data, true);
  return response.data;
}

export async function getLabSummaryData(filters: FilterProps): Promise<LabSummary[]> {
  const response = await Get(`/api/labSummary?from=${filters.from}&until=${filters.until}`, true);
  return response?.data as LabSummary[];
}

export async function batchDeleteLabTestRecords(testIds: Array<number>): Promise<unknown> {
  const response = await Delete(
    `${labTestRecordEndpointRoot}/batchDelete?testIds=${testIds}`,
    true
  );
  return response.data;
}
export async function batchDispatchLabTestRecords(testIds: Array<number>): Promise<unknown> {
  const response = await Patch(
    `${labTestRecordEndpointRoot}/batchDispatch`,
    {
      testIds
    },
    true
  );
  return response.data;
}

export async function dispatchLabTestRecord(testId: number): Promise<unknown> {
  const response = await Patch(`${labTestRecordEndpointRoot}/${testId}/dispatch`, {}, true);
  return response.data;
}

export async function assessLabTestRecord(testId: number): Promise<unknown> {
  const response = await Patch(`${labTestRecordEndpointRoot}/${testId}/assessed`, {}, true);
  return response.data;
}

export const getInternalLabInfo = async (labRecordId: number): Promise<string[]> => {
  const response = await Get(`/api/getInternalStockInfo/${labRecordId}`, true);
  return response?.data as string[];
};

interface AssessmentLabRes {
  results: Array<LabTestRecordResultData>;
  total: number;
}
export const getPrescribedLabTests = async ({
  page,
  pageSize
}: {
  page: number;
  pageSize: number;
}): Promise<AssessmentLabRes> => {
  const response = await Get(`/api/prescribedLabs?page=${page}&pageSize=${pageSize}`, true);
  return response?.data as AssessmentLabRes;
};

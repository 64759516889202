import * as React from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownSharp from "@mui/icons-material/KeyboardArrowDownSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchClientByReferenceId } from "../../actions/client";
import { getByUserReferenceId } from "../../actions/user";
import * as AuthenicationActions from "../../actions/authentication";
import localStorage from "../../helpers/localStorage";
import { RootState } from "../../store";
import style from "./style.module.css";
import logo from "../../../assets/images/okhati_full_green.png";

interface messengerProps {
  fetchClientByReferenceId: (referenceId) => void;
  getByUserReferenceId: (referenceId) => void;
  loginViaFacebook: (credentials) => void;
}

const Messenger: React.FC<messengerProps> = ({
  fetchClientByReferenceId,
  getByUserReferenceId,
  loginViaFacebook,
  match
}) => {
  const [buttonRendered, setButtonRendered] = React.useState(false);

  const bindEvents = () => {
    if (window.FB) {
      window.FB.Event.subscribe("send_to_messenger", (e) => {
        if (e.event === "opt_in") {
          const { userId, token } = JSON.parse(localStorage.getItem("FacebookResponse"));
          loginViaFacebook({ userId, token });
        } else if (e.event === "rendered") {
          setButtonRendered(true);
        }
      });
    } else {
      setTimeout(bindEvents, 1000);
    }
  };

  React.useEffect(() => {
    const { referenceId } = JSON.parse(localStorage.getItem("FacebookResponse"));
    fetchClientByReferenceId(referenceId);
    getByUserReferenceId(referenceId);
    bindEvents();
  }, []);

  return (
    <Box className="panel-body">
      <Box className={style.signupLogo}>
        <img className={style.logo} src={logo} />
      </Box>
      <Box className={style.signUpPanel}>
        <Typography variant="h6" className={style.userGreeting}>
          {/* <b>You have successfully registered with Okhati!</b> */}
          <Box>
            <b>Please subscribe our Okhati page</b>
          </Box>
          <Box>
            <b>to get connected with Okhati support team</b>
          </Box>
        </Typography>

        <Typography variant="body1" className={style.signUpContent}>
          {/* <Box>Please click the button below to get reminders</Box>
          <Box>for your appointments, medicine schedules etc. right on </Box>
          <Box>your Facebook messenger</Box> */}
          <Box>Click the button below to continue...</Box>
        </Typography>

        <Typography className={style.arrowDownBody}>
          <KeyboardArrowDownSharp className={style.arrowDown} />
        </Typography>

        <Box style={{ marginBottom: 10 }}>
          {!buttonRendered && <CircularProgress size={25} className={style.spinner} />}
          <Box
            className={`${style.messengerBtn} fb-send-to-messenger`}
            messenger_app_id={"1155561214640322"}
            page_id={"194506957840321"}
            data-ref={match.params.userId}
            data-color={"blue"}
            data-size={"xlarge"}
            cta_text={"SEND_TO_MESSENGER"}
          />
        </Box>

        <Typography variant="body1">
          <a className={style.link}>No thanks</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    client: state.clients.lastTouched,
    userDetails: state.userContext
  }),
  (dispatch) => ({
    fetchClientByReferenceId: async (referenceId) => {
      await dispatch(fetchClientByReferenceId(referenceId));
    },
    getByUserReferenceId: async (referenceId) => {
      await dispatch(getByUserReferenceId(referenceId));
    },
    loginViaFacebook: (credentials) => {
      dispatch(AuthenicationActions.loginViaFacebook(credentials));
    }
  })
)(Messenger);

import * as React from "react";
import { connect } from "react-redux";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { suppliersSelector } from "../../reducers/suppliers";
import { supplierActions } from "../../actions";
import { t } from "../../components/translate";
import { IThunkDispatch, RootState } from "../../store";

const optionsForSelect = (suppliers = [], filter?) => {
  let supplierOptions = [...suppliers];
  if (filter) {
    supplierOptions = filter
      .map((id) => suppliers.find((supplier) => supplier.id === id))
      .filter((s) => Boolean(s));
  }
  return supplierOptions.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
    supplier
  }));
};

const SupplierMultiSelect = ({
  supplierIds,
  suppliers,
  onChange,
  isDisabled = false,
  actions,
  variant = "standard"
}) => {
  React.useEffect(() => {
    if (!suppliers || !suppliers.length) actions.loadSuppliers();
  }, []);
  if (!suppliers) return null;
  return (
    <Autocomplete
      data-testmation="supplierAutocomplete"
      multiple
      value={optionsForSelect(suppliers, supplierIds)}
      options={optionsForSelect(suppliers)}
      getOptionLabel={(option) => `${option.label}`}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.value}>
          <div>{option.supplier.name}</div>
        </li>
      )}
      onChange={(e, values) => {
        onChange(
          values.map((item) => (item as { value: string }).value),
          values
        );
      }}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant={variant as "standard" | "filled" | "outlined"}
          label={t("suppliers.supplier")}
          placeholder={t("suppliers.searchAndSelect")}
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

export default connect(
  (state: RootState) => ({
    suppliers: suppliersSelector(state)
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      loadSuppliers: () => dispatch(supplierActions.getSuppliers())
    }
  })
)(SupplierMultiSelect);

import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import { EmptyView, List } from "../../components/OList";
import { getSupplierLedger } from "../../slices/supplierSlice";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { RootState } from "../../store";
import SupplierTransactionInfo from "../Reports/SupplierTransactionInfo";

interface Props {
  supplierId: number;
}

interface ListState {
  id: number;
  date: Date;
  supplierName: string;
  voucherType: string;
  reference: number;
  paymentMode: string;
  debit: number;
  credit: number;
  balance: number;
  remarks: string;
  stockTransactionId: number;
  ageingDays: number;
  ledgerId: number;
  isOpeningBalance: boolean;
}

const selectData = (item): ListState => ({
  id: item.supplier.id,
  ledgerId: item.id,
  date: new Date(item.transactionDate),
  supplierName: item.supplier.name,
  voucherType: item.voucherType,
  reference: item.supplierInvoiceId,
  paymentMode: item.paymentMethod,
  debit: item.transactionType === "debit" ? item.amount : 0.0,
  credit: item.transactionType === "credit" ? item.amount : 0.0,
  balance: Number(item.dueAmount),
  remarks: "",
  stockTransactionId: item.stockTransactionId,
  ageingDays: item.supplier?.ageingDays,
  isOpeningBalance: item.isOpeningBalance || false
});

export default function SupplierLedger(props: Props): JSX.Element {
  const { supplierId } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSupplierLedger(supplierId));
  }, [supplierId, dispatch]);

  const ledgers = useSelector((state: RootState) => state.suppliers.ledgers);

  const listLedgers = React.useMemo(
    () => [...ledgers].sort((a, b) => a.id - b.id).map((ledger) => selectData(ledger)),
    [ledgers]
  );

  const [selectedSupplier, setSelectedSupplier] = React.useState(null);

  return (
    <div style={{ height: "calc(100vh - 350px)" }}>
      <List<ListState>
        automation="clientLabs"
        rowHeight={40}
        data={listLedgers}
        onRowClick={(row) => setSelectedSupplier(row)}
        activeRow={(selectedSupplier && selectedSupplier?.stockTransactionId) || null}
        columns={[
          {
            key: "transactionDate",
            label: "Date",
            sortable: true,
            formatter: ({ date, isOpeningBalance }) => (
              <Typography>
                {isOpeningBalance ? "Opening Balance" : convertADtoBS(new Date(date))?.formatted4}
              </Typography>
            )
          },
          {
            key: "voucherType",
            label: "Voucher Type",
            sortable: false,
            formatter: ({ voucherType }) => (
              <Typography style={{ fontSize: "12px" }}>{startCase(voucherType) || ""}</Typography>
            )
          },
          {
            key: "reference",
            label: "Invoice No",
            formatter: ({ reference }) => <Typography>{reference || ""}</Typography>
          },
          {
            key: "id",
            label: "Id",
            formatter: ({ ledgerId }) => <Typography>{ledgerId}</Typography>
          },
          {
            key: "paymentMode",
            label: "Payment Mode",
            formatter: ({ paymentMode }) => <Typography>{paymentMode || ""}</Typography>
          },
          {
            key: "debit ",
            label: "Debit",
            formatter: ({ debit }) => <Typography>{debit}</Typography>
          },
          {
            key: "credit",
            label: "Credit",
            formatter: ({ credit }) => <Typography>{credit}</Typography>
          },
          {
            key: "balance",
            label: "Running balance",
            segmentable: true,
            formatter: ({ balance }) => <Typography>{balance.toFixed(2)}</Typography>
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No Ledger for the supplier yet...
          </Box>
        </EmptyView>
      </List>

      {selectedSupplier && (
        <SupplierTransactionInfo
          selectedSupplier={selectedSupplier}
          setOpenSupplierDetails={setSelectedSupplier}
        />
      )}
    </div>
  );
}

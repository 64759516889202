import * as React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import Panel from "../../components/Panel";
import FormTextField from "../../components/FormTextField/FormTextField";
import StatefulButton from "../../components/StatefulButton/StatefulButton";
import { patchAssociateCompany, postRcAssociateCompany } from "../../slices/associateCompanySlice";
import { useAppDispatch } from "../../store/hooks";
import { AssociateCompany } from "../../api/associateCompanies";
import { t, tl } from "../../components/translate";

interface Props {
  createMode: boolean;
  onClose: () => void;
  onSuccess: (value: AssociateCompany) => void;
  selectedCompany: AssociateCompany | null;
  defaultName?: string;
}

function CompanyCreateEdit({
  selectedCompany,
  createMode,
  onClose,
  onSuccess,
  defaultName
}: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid }
  } = useForm<FieldValues>({
    defaultValues: createMode
      ? { name: defaultName || "", discountRate: 0 }
      : { name: selectedCompany.name, discountRate: selectedCompany.discountRate },
    mode: "all"
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedCompany) {
      setValue("name", selectedCompany.name);
      setValue("discountRate", selectedCompany.discountRate);
    }
  }, [selectedCompany]);

  const onSubmit = async (data: { name: string; discountRate: number }) => {
    const formData = { ...data, discountRate: Number(data.discountRate) };
    if (!createMode && selectedCompany) {
      await dispatch(
        patchAssociateCompany({
          data: { ...formData, id: selectedCompany.id },
          onSuccess
        })
      );
    } else {
      await dispatch(postRcAssociateCompany({ data: formData, onSuccess }));
    }
  };

  return (
    <Panel
      title={createMode ? `${t("createCompany")}` : "Edit company"}
      onClose={onClose}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={4}>
            <Button data-testmation="companyCancel" onClick={onClose}>
              <Typography>{tl("cancel")}</Typography>
            </Button>
            <StatefulButton
              form="createCompanyForm"
              type="submit"
              data-testmation="companySave"
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{createMode ? `${t("save")}` : "Update"}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          px: 4,
          pb: 4,
          pt: 2,
          height: "calc(100vh - 100px)"
        }}
        component="form"
        id="createCompanyForm"
      >
        <Stack gap={1}>
          <FormTextField
            name="name"
            control={control}
            rules={{ required: { value: true, message: "Name is required" } }}
            label={t("companyName")}
            placeholder={t("companyName")}
            dataTestmation="nameInput"
            errors={errors}
          />
          <FormTextField
            name="discountRate"
            control={control}
            rules={{
              required: { value: true, message: "Discount rate is required" },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Please enter valid rate"
              }
            }}
            label={t("discountRate")}
            placeholder={t("discountRate")}
            dataTestmation="discountRateInput"
            errors={errors}
          />
        </Stack>
      </Box>
    </Panel>
  );
}

export default CompanyCreateEdit;

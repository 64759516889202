import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

class PermanentDrawerLeft extends React.Component<{ classes: any }, { active: number }> {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }
  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Gallery
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar} />
          <Divider />
          <List>
            {React.Children.map(children, (child: any, index: number) => {
              return (
                <ListItem
                  button
                  selected={this.state.active === index}
                  onClick={() => {
                    this.setState({ active: index });
                  }}
                >
                  <ListItemText>{child.props.name}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <main className={classes.content}>
          {React.Children.map(children, (child: any, index) => {
            if (this.state.active === index) {
              return (
                <React.Fragment>
                  <Typography variant="h4">{child.props.name}</Typography>
                  <Typography variant="body1">{child.props.description}</Typography>
                  <br />
                  {child.props.children}
                </React.Fragment>
              );
            }
          })}
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(PermanentDrawerLeft);

export class GalleryItem extends React.Component<{
  name: string;
  description: string | JSX.Element;
}> {
  render() {
    return null;
  }
}

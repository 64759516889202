import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import { ChildGeneralLedger } from "../../../../interfaces/Accounts";

interface LedgerSelectProps {
  onChange: (v: ChildGeneralLedger) => void;
  selected: ChildGeneralLedger | null;
  options: ChildGeneralLedger[];
}

export default function LedgerSelect({
  onChange,
  selected,
  options
}: LedgerSelectProps): JSX.Element {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => `${option.ledgerName}`}
      renderOption={(props, option) => (
        //   eslint-disable-next-line react/jsx-props-no-spreading
        <Box key={option.id} component="li" {...props}>
          {`${option.ledgerName}`}
        </Box>
      )}
      onChange={(e, v) => {
        onChange(v as ChildGeneralLedger);
      }}
      fullWidth
      value={selected}
      renderInput={(params) => (
        <TextField
          //   eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          sx={{ backgroundColor: "white", maxWidth: "400px" }}
          variant="outlined"
          label="Select ledger"
          placeholder="Select ledger"
          data-testmation="selectLedger"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

import { createSelector } from "reselect";
import { ReducerBuilder } from "./ReducerBuilder";
import { ServiceActions } from "../interfaces/ActionTypes";
import { ProductInterface } from "../interfaces/ProductInterface";
import { RootState } from "../store";

const INITIAL_STATE = {
  collection: [],
  lastTouched: null
};

function createServices(state, payload) {
  const newCollection = [...state.collection].filter(({ id }) => id !== payload.id);
  newCollection.push(payload);
  return { collection: newCollection, lastTouched: payload };
}

function updateServices(state, payload) {
  const newCollection = [...state.collection].filter(({ id }) => id !== payload.id);
  newCollection.push(payload);
  return { collection: newCollection, lastTouched: payload };
}

function getServices(state, payload) {
  return { ...state, collection: payload };
}

function setService(state, payload) {
  const newCollection = state.collection.filter(({ id }) => payload.id !== id);
  newCollection.push(payload);
  return { ...state, collection: newCollection };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(ServiceActions.CREATE_SERVICES, createServices)
  .mapAction(ServiceActions.GET_SERVICES, getServices)
  .mapAction(ServiceActions.UPDATE_SERVICES, updateServices)
  .mapAction(ServiceActions.GET_SERVICE, setService)

  .build();
export default reducer;

export const servicesSelector = (state: RootState): Array<ProductInterface> =>
  state.services.collection;

export const activeServicesSelector = createSelector(servicesSelector, (services) =>
  services.filter(({ active }) => active)
);

export const servicesSortedSelector = createSelector(activeServicesSelector, (services) =>
  services.sort((a, b) =>
    `${a.name || ""}`.toLowerCase() > `${b.name || ""}`.toLowerCase() ? 1 : -1
  )
);

export const getSPSpecificServices = (
  id: number,
  services: Array<ProductInterface>
): Array<Partial<ProductInterface>> =>
  /**
   * New Implementation:
   * If service is a package, it will have a document with products array.
   * Each product in the products array will have document with rates array.
   */
  services.filter((service) => {
    if (service?.document?.products?.length) {
      // If service is a package
      return service.document.products.some((product) =>
        product?.document?.rates?.some((rate) => rate.serviceProviderId === id)
      );
    }
    // If service is not a package
    return service?.document?.rates?.some((rate) => rate.serviceProviderId === id);
  });

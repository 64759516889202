/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import moment from "moment";
import { isEmpty, omit } from "lodash";
import { getNextVoucherNumber } from "../api/accounts";
import { getTaxations, patchTaxation } from "../api/stockProducts";
import { getDueBillsForRecordPayment } from "../api/bill";
import { getTrnxHavingDue } from "../api/stock";
import {
  ChildGeneralLedger,
  ItemInterface,
  PaymentOptions,
  PaymentType,
  ReceiptType,
  VendorType,
  VoucherEntryProps,
  VoucherType
} from "../interfaces/Accounts";
import { BillDocumentType } from "../interfaces/BillInterfaces";
import { TaxationInterface } from "../interfaces/ProductInterface";
import { Entry as StockTransactions } from "../interfaces/StockInterfaces";

const template = {
  sNo: 1,
  ledgerId: null,
  referenceVoucherId: null,
  debtorCreditorId: null,
  debtorCreditorType: null,
  ledgerType: null,
  debit: 0,
  credit: 0
};

export const getTemplateJournalItem = (): ItemInterface => template;

export const getPaymentTemplateItem = (): Partial<ItemInterface> =>
  omit(template, ["debtorCreditorId", "debtorCreditorType"]);

interface InitialStateInterface {
  voucherEntry: VoucherEntryProps;
  taxations: TaxationInterface[];
  stockTrnxHavingDue: StockTransactions[];
  billHavingDue: BillDocumentType[];
  paymentVoucher: VoucherEntryProps;
  receiptVoucher: VoucherEntryProps;
  contraVoucher: VoucherEntryProps;
  lastTouchedLedger: ChildGeneralLedger | null;
}

export const initialState: InitialStateInterface = {
  voucherEntry: {} as VoucherEntryProps,
  taxations: [],
  stockTrnxHavingDue: [],
  billHavingDue: [],
  paymentVoucher: {} as VoucherEntryProps & { entityId: number | null },
  receiptVoucher: {} as VoucherEntryProps,
  contraVoucher: {} as VoucherEntryProps,
  lastTouchedLedger: null
};

export const updateSerialNo = <T>(state: T[]): T[] =>
  state.map((item, i) => ({ ...item, sNo: i + 1 }));

const updateTotalAmt = (state: VoucherEntryProps): VoucherEntryProps =>
  produce(state, (draft) => {
    draft.totalDrAmt = draft.document.reduce((acc, curr) => acc + curr.debit, 0);
    draft.totalCrAmt = draft.document.reduce((acc, curr) => acc + curr.credit, 0);
  });

export const getNextTrackingNumber = createAsyncThunk(
  "accounts/voucherEntry/date",
  async ({ type, date }: { type: string; date: Date | string }) => {
    const response = await getNextVoucherNumber(type, date);
    return response;
  }
);

export const setVoucherNumber = createAsyncThunk(
  "accounts/voucher/date",
  async ({
    type,
    date,
    voucherType
  }: {
    type: string;
    date: Date | string;
    voucherType: string;
  }) => {
    const response = await getNextVoucherNumber(type, date);
    return { response, voucherType };
  }
);

export const taxationPatch = createAsyncThunk(
  "accounts/patchTaxation",
  async (data: Partial<TaxationInterface>) => {
    const response = await patchTaxation(data);
    return response as Partial<TaxationInterface>;
  }
);

export const getDueTransactions = createAsyncThunk(
  "accounts/getDueTransactions",
  async (data: { supplierId: number; transactionType: string }) => {
    const response = await getTrnxHavingDue(data.supplierId, {
      transactionType: data.transactionType
    });
    return response as StockTransactions[];
  }
);

export const getDueBills = createAsyncThunk("accounts/getDueBills", async (clientId: string) => {
  const response = await getDueBillsForRecordPayment(clientId);
  return response.bills;
});

export const getTaxation = createAsyncThunk("accounts/getTaxation", async () => {
  const response = await getTaxations();
  return response as TaxationInterface[];
});

const voucherEntrySlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setLastTouchedLedger: (state, { payload }) => {
      state.lastTouchedLedger = payload;
    },
    clearLastTouchedLedger: (state) => {
      state.lastTouchedLedger = null;
    },
    updateVoucherEntry: (state, action: PayloadAction<VoucherEntryProps>) => {
      state.voucherEntry = updateTotalAmt(action.payload);
    },
    clearJournalVoucher: (state) => {
      state.voucherEntry = {} as VoucherEntryProps;
    },
    addNewRow: (state, { payload }) => {
      state[payload].document = updateSerialNo([
        ...state[payload].document,
        getTemplateJournalItem()
      ]);
    },
    clearAllRow: (state, { payload }) => {
      state[payload].document = [];
      state[payload].totalCrAmt = 0;
      state[payload].totalDrAmt = 0;
    },
    removeRow: (state, { payload }) => {
      const newItemsState = state[payload.type].document.filter(
        (journal) => journal.sNo !== payload.sNo
      );
      state[payload.type].document = updateSerialNo(newItemsState);
      state[payload.type] = updateTotalAmt(state[payload.type]);
    },
    initVoucherEntry: (state) => {
      state.voucherEntry = {
        voucherNumber: "",
        date: moment().toISOString(),
        remarks: "",
        voucherType: "createJournal",
        attachmentId: null,
        totalDrAmt: 0,
        totalCrAmt: 0,
        document: updateSerialNo([getTemplateJournalItem(), getTemplateJournalItem()])
      };
    },
    setSupplierTransactionEmpty: (state) => {
      state.stockTrnxHavingDue = [];
    },
    setClientTransactionEmpty: (state) => {
      state.billHavingDue = [];
    },
    initPaymentVoucher: (state) => {
      state.paymentVoucher = {
        paymentType: PaymentType.VENDOR_PAYMENT,
        vendorType: VendorType.SUPPLIER,
        voucherNumber: "",
        date: moment().toISOString(),
        document: updateSerialNo<Partial<ItemInterface>>([
          getPaymentTemplateItem(),
          getPaymentTemplateItem()
        ]),
        remarks: "",
        voucherType: VoucherType.PAYMENT_VOUCHER,
        attachmentId: null,
        totalDrAmt: 0,
        totalCrAmt: 0,
        paymentMethod: PaymentOptions.cash,
        entityId: null
      };
    },
    initReceiptVoucher: (state) => {
      state.receiptVoucher = {
        receiptType: ReceiptType.CUSTOMER,
        voucherNumber: "",
        date: moment().toISOString(),
        document: updateSerialNo([getPaymentTemplateItem(), getPaymentTemplateItem()]),
        remarks: "",
        voucherType: VoucherType.RECEIPT_VOUCHER,
        attachmentId: null,
        totalDrAmt: 0,
        totalCrAmt: 0,
        paymentMethod: PaymentOptions.cash,
        entityId: null
      };
    },
    updatePaymentVoucher: (state, action: PayloadAction<VoucherEntryProps>) => {
      state.paymentVoucher = updateTotalAmt(action.payload);
    },
    updateReceiptVoucher: (state, action: PayloadAction<VoucherEntryProps>) => {
      state.receiptVoucher = updateTotalAmt(action.payload);
    },
    updateContraVoucher: (state, action: PayloadAction<VoucherEntryProps>) => {
      state.contraVoucher = updateTotalAmt(action.payload);
    },
    updateVoucher: (state, action: PayloadAction<VoucherEntryProps>) => {
      state[action.payload.voucherType] = updateTotalAmt(action.payload);
    },
    clearVoucherState: (state) => {
      if (!isEmpty(state.receiptVoucher)) {
        state.receiptVoucher = {} as VoucherEntryProps;
      }
      if (!isEmpty(state.contraVoucher)) {
        state.contraVoucher = {} as VoucherEntryProps;
      }
      if (!isEmpty(state.paymentVoucher)) {
        state.paymentVoucher = {} as VoucherEntryProps;
      }
    },
    initContraVoucher: (state) => {
      state.contraVoucher = {
        voucherNumber: "",
        date: moment().toISOString(),
        document: updateSerialNo([getTemplateJournalItem(), getTemplateJournalItem()]),
        remarks: "",
        voucherType: VoucherType.CONTRA_VOUCHER,
        attachmentId: null,
        totalDrAmt: 0,
        totalCrAmt: 0,
        paymentMethod: ""
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNextTrackingNumber.fulfilled, (state, { payload }) => {
      state.voucherEntry.voucherNumber = payload.nextNumber;
    });
    builder.addCase(getNextTrackingNumber.rejected, (state) => {
      state.voucherEntry.voucherNumber = "";
    });
    builder.addCase(getTaxation.fulfilled, (state, { payload }) => {
      state.taxations = payload;
    });
    builder.addCase(taxationPatch.fulfilled, (state, { payload }) => {
      const index = state.taxations.findIndex((tx) => tx.id === payload.id);
      if (index !== -1) {
        state.taxations[index].ledgerId = payload.ledgerId as number;
      }
    });
    builder.addCase(getDueTransactions.fulfilled, (state, { payload }) => {
      state.stockTrnxHavingDue = payload;
    });
    builder.addCase(getDueBills.fulfilled, (state, { payload }) => {
      state.billHavingDue = payload;
    });
    builder.addCase(setVoucherNumber.fulfilled, (state, { payload }) => {
      state[payload.voucherType].voucherNumber = payload.response.nextNumber;
    });
  }
});

export const {
  updateVoucherEntry,
  addNewRow,
  clearAllRow,
  removeRow,
  initVoucherEntry,
  setSupplierTransactionEmpty,
  setClientTransactionEmpty,
  initPaymentVoucher,
  updatePaymentVoucher,
  initReceiptVoucher,
  updateReceiptVoucher,
  initContraVoucher,
  updateVoucher,
  updateContraVoucher,
  clearVoucherState,
  setLastTouchedLedger,
  clearLastTouchedLedger,
  clearJournalVoucher
} = voucherEntrySlice.actions;
export default voucherEntrySlice.reducer;

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Divider, Menu, MenuItem } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import { ReverseMap } from "../../../helpers/types";

export enum SearchType {
  PRODUCT = "product",
  SUPPLIER = "supplier",
  SUPPLIER_INVOICE_ID = "supplierInvoiceId"
}
const searchOptions = Object.values(SearchType);
export type ButtonTypes = ReverseMap<typeof SearchType>;

interface Props {
  selectedOptionButton: ButtonTypes;
  onChange: (value: ButtonTypes) => void;
}

export default function StockTrxSearchButton({
  selectedOptionButton,
  onChange
}: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleSearchButtonChange = (key) => {
    onChange(key);
  };
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      width={`${selectedOptionButton === SearchType.PRODUCT ? "200px" : "290px"}`}
    >
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ whiteSpace: "pre" }}>
        <Divider orientation="vertical" flexItem sx={{ marginRight: "10px" }} />
        {startCase(selectedOptionButton)}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {searchOptions.map((btn) => (
          <MenuItem
            onClick={() => {
              handleSearchButtonChange(btn);
              setAnchorEl(null);
            }}
            key={btn}
          >
            {startCase(btn)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

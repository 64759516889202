import { tableNodeTypes } from "prosemirror-tables";
import { Node, NodeType } from "prosemirror-model";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";

interface TableProps {
  state: EditorState;
  rowsCount: number;
  colsCount: number;
  withHeaderRow: boolean;
  cellContent: Node | null;
}
interface InsertTableProps extends TableProps {
  dispatch: (transaction: Transaction) => void;
}

function createTable({ state, rowsCount, colsCount, withHeaderRow, cellContent }: TableProps) {
  const types = tableNodeTypes(state.schema);
  const headerCells = [];
  const cells = [];
  const createCell = (cellType: NodeType, content: Node | null) =>
    content ? cellType.createChecked(null, content) : cellType.createAndFill();

  for (let index = 0; index < colsCount; index += 1) {
    const cell = createCell(types.cell, cellContent);

    if (cell) {
      cells.push(cell);
    }

    if (withHeaderRow) {
      const headerCell = createCell(types.header_cell, cellContent);

      if (headerCell) {
        headerCells.push(headerCell);
      }
    }
  }

  const rows = [];

  for (let index = 0; index < rowsCount; index += 1) {
    rows.push(types.row.createChecked(null, withHeaderRow && index === 0 ? headerCells : cells));
  }

  return types.table.createChecked(null, rows);
}

export default function insertTable({
  state,
  dispatch,
  rowsCount,
  colsCount,
  withHeaderRow,
  cellContent
}: InsertTableProps): void {
  const offset = state.tr.selection.anchor + 1;

  const nodes = createTable({ state, rowsCount, colsCount, withHeaderRow, cellContent });
  const tr = state.tr.replaceSelectionWith(nodes).scrollIntoView();
  const resolvedPos = tr.doc.resolve(offset);

  tr.setSelection(TextSelection.near(resolvedPos));

  dispatch(tr);
}

import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { t } from '../../components/translate';

const ProductExtraInfo = ({ info, clearInfo }) => {
  return (
    <Paper>
      <Box padding="0px 16px 20px 16px" display="inline-table">
        {clearInfo && (
          <Box textAlign={'right'}>
            <IconButton aria-label="delete" color="primary" onClick={clearInfo} size="large">
              <ClearIcon />
            </IconButton>
          </Box>
        )}
        {Object.keys(info).map((k) => (
          <Typography
            component="div"
            style={{ width: '200px', float: 'left', paddingBottom: '8px' }}
          >
            <Box>
              <Box fontWeight={600} fontSize={'0.7em'}>
                {t(k)}
              </Box>{' '}
              {info[k] || '-'}
            </Box>
          </Typography>
        ))}
      </Box>
    </Paper>
  );
};

export default ProductExtraInfo;

import React from "react";
import startCase from "lodash/startCase";
import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import capatalize from "lodash/capitalize";
import Panel from "../../../components/Panel";
import PrintDischarge from "../Print/PrintDischarge";
import { IPD } from "../../../interfaces/IpdInterface";
import { t, tl } from "../../../components/translate";

interface MaternityInterface {
  procedures: string;
  dateOfDelivery: string;
  weightOfBaby: string;
}

interface RowInterface {
  label: string;
  data?: string;
}

const Row: React.FC<RowInterface> = ({ label, data }) => (
  <Box mt={0.2}>
    <Box display="flex" minWidth="500px">
      <Box mr="5" width="260px">
        <Typography>{label} :</Typography>
      </Box>
      <Box>{data || ""}</Box>
    </Box>
  </Box>
);

const IpdInfo = ({ ipdData }) => {
  const clientInfo = ipdData.ipdRecord?.patientRelatedInformation;
  const getFullName = () =>
    `${capatalize(clientInfo.firstName)} ${capatalize(clientInfo.lastName)}`;

  return (
    <Box>
      <Box fontWeight="bold" fontSize="22px">
        {getFullName()}
      </Box>
      <Box width="50%">
        <Row label={t("ipdNumber")} data={ipdData?.ipdNumber} />
        <Row label={t("bedNumber")} data={ipdData?.bed?.number} />
        <Row label={t("wardName")} data={ipdData?.ward?.name} />
        <Row
          label={t("docInCharge")}
          data={startCase(
            ipdData?.doctorInCharge?.fullname || ipdData?.ipdRecord?.doctorInCharge?.fullname
          )}
        />
        <Row
          label={t("authorisedPersional")}
          data={startCase(
            ipdData?.authorizedPersonnelInfo?.fullname ||
              ipdData?.ipdRecord?.authorizedPersonnelInfo?.fullname
          )}
        />
        <Row label={t("case")} data={ipdData?.ipdRecord?.case} />
      </Box>
    </Box>
  );
};

const TitleContent = ({ ipdData }) => (
  <Box
    width="100%"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    style={{ height: "40px" }}
    mb={3}
  >
    <Box component="span" fontWeight="600" fontSize="25px" pr={2}>
      {tl("dischargeDetails")}
    </Box>
    <Box>
      <PrintDischarge ipdData={ipdData} />
    </Box>
  </Box>
);

const DischargeSummary = ({ ipdData }) => {
  const ipdCase = ipdData.ipdRecord.case;
  const maternity = {} as MaternityInterface;
  const {
    conditionDuringDischarge,
    instructionsOnDischarge,
    progressResult,
    finalDiagnosis = {},
    remarks = ""
  } = ipdData?.ipdRecord?.dischargeSummary;
  if (ipdCase === "Maternity") {
    const { procedures, dateOfDelivery, weightOfBaby } = ipdData.ipdRecord.dischargeSummary;
    maternity.procedures = procedures;
    maternity.dateOfDelivery = dateOfDelivery;
    maternity.weightOfBaby = weightOfBaby;
  }

  return (
    <Box mt={2}>
      <Row label={tl("conditionDuringDischarge")} data={conditionDuringDischarge} />
      <Row label={tl("instructionOnDischarge")} data={instructionsOnDischarge} />
      <Row label={tl("ProgressResult")} data={progressResult} />
      <Row label={tl("finalDiagnosis")} data={finalDiagnosis?.diagnosis} />
      {ipdCase === "Maternity" && (
        <Box>
          <Row label="Procedures" data={maternity.procedures} />
          <Row label="Date Of Delivery" data={maternity.dateOfDelivery} />
          <Row label="Weight of Baby" data={maternity.weightOfBaby} />
        </Box>
      )}
      <Row label={tl("remarks")} data={remarks} />
    </Box>
  );
};

export const DischargeContent = ({ ipdData }: { ipdData: IPD }): JSX.Element => (
  <>
    <TitleContent ipdData={ipdData} />
    <IpdInfo ipdData={ipdData} />
    <Box fontWeight="bold" fontSize="22px" mt={3}>
      {tl("dischargeSummary")}:
    </Box>
    <DischargeSummary ipdData={ipdData} />
  </>
);

const DischargeDetails = ({ onCancel, ipdData }) => (
  <Panel onClose={onCancel} wrapperStyle={{ minWidth: "796px", maxWidth: "796px" }}>
    <Box style={{ padding: "0 35px" }} data-testmation="dischargeInfoPanel">
      <DischargeContent ipdData={ipdData} />
    </Box>
  </Panel>
);

Row.defaultProps = {
  data: ""
};

export default connect(
  (state, { id }) => ({
    ipdData: state.ipd.collection.find((item) => item.id === id)
  }),
  null
)(DischargeDetails);

import React from "react";
import imageCompression from "browser-image-compression";
import { Box, ButtonGroup, Button, Slider, Dialog, DialogTitle, Typography } from "@mui/material";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils";
import { tl } from "../translate";

interface ImageCropInterface {
  image: any;
  isOpen: boolean;
  dynamicRatio: boolean;
  onClose?: () => void;
  onSave: (croppedImage) => void;
  onCancel?: () => void;
  defaultRatio?: { x: number; y: number } | null;
}

const ImageCrop: React.FC<ImageCropInterface> = (props) => {
  const { image, isOpen, dynamicRatio, onClose, onSave, onCancel, defaultRatio = null } = props;

  const [ratio, setRatio] = React.useState(defaultRatio || { x: 1, y: 1 });
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

  const onCropComplete = React.useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    const compressedCroppedImage = await imageCompression(croppedImage, {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    });
    onSave(compressedCroppedImage);
  }, [croppedAreaPixels]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography>
          <Box component="span" fontWeight="600">
            {tl("assessment.cropImage")}
          </Box>
        </Typography>
      </DialogTitle>
      <Box height="600px">
        <Box style={{ position: "absolute", top: "50px", left: 0, right: 0, bottom: "90px" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={Number(ratio.x) / Number(ratio.y)}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            padding: "20px"
          }}
        >
          {dynamicRatio && (
            <Box textAlign="center">
              <ButtonGroup color="primary" size="small" variant="outlined">
                <Button
                  data-testmation="imageCrop-1x1"
                  onClick={() => {
                    setRatio({ x: 1, y: 1 });
                  }}
                >
                  <Typography>{tl("imageCrop.1x1")}</Typography>
                </Button>
                <Button
                  data-testmation="imageCrop-2x1"
                  onClick={() => {
                    setRatio({ x: 2, y: 1 });
                  }}
                >
                  <Typography>{tl("imageCrop.2x1")}</Typography>
                </Button>
                <Button
                  data-testmation="imageCrop-6x1"
                  onClick={() => {
                    setRatio({ x: 6, y: 1 });
                  }}
                >
                  <Typography>{tl("imageCrop.6x1")}</Typography>
                </Button>
              </ButtonGroup>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Typography>
              <Box component="span" fontWeight="600" pr={2}>
                {tl("imageCrop.zoom")}
              </Box>
            </Typography>
            <Slider
              data-testmation="zoomImage"
              color="secondary"
              value={zoom}
              min={1}
              max={7}
              step={0.1}
              onChange={(e, imgZoom) => setZoom(Number(imgZoom))}
            />

            <Box width="30%" display="flex" justifyContent="space-between">
              <Button
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                  if (onCancel) {
                    onCancel();
                  }
                }}
                variant="text"
                color="primary"
              >
                <Typography>{tl("cancel")}</Typography>
              </Button>
              <Button
                data-testmation="saveImage"
                onClick={async () => {
                  await showCroppedImage();
                  if (onClose) {
                    onClose();
                  }
                }}
                variant="contained"
                color="primary"
              >
                <Typography>{tl("imageCrop.save")}</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

ImageCrop.defaultProps = {
  onClose: () => ({}),
  onCancel: () => ({})
};

export default ImageCrop;

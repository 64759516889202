import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { tl } from "../../../components/translate";
import List from "../../../components/List";
import AudienceCreateEdit from "./AudienceCreateEdit";
import { audiencesSelector } from "../../../reducers/audience";
import * as audienceActions from "../../../actions/audience";
import AudienceInfo from "./AudienceInfo";
import { AudienceData, getFormattedRulesFromAudience } from "./AudienceHelpers";
import "./AudienceList.scss";
import Can from "../../Policy/Can";
import { IThunkDispatch, RootState } from "../../../store";
import { multipleHeaders } from "../Campaigns/CampaignList";
import { getServices } from "../../../actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

interface AudienceListProps {
  actions;
  mode: string;
  audienceList: AudienceData[];
  match: any;
  policies: { [k: string]: boolean };
}

const AudienceList: React.FC<AudienceListProps> = ({
  actions,
  mode,
  audienceList,
  match,
  policies
}) => {
  React.useEffect(() => {
    if (!audienceList || !audienceList.length) actions.loadAudience();
  }, [actions, audienceList]);
  const [selectedAudience, setSelectedAudience] = React.useState(null);
  const dispatch = useAppDispatch();
  const services = useAppSelector((state) =>
    state.services.collection.filter(({ active }) => active)
  );

  React.useEffect(() => {
    if (!services?.length) dispatch(getServices());
    const audienceId = match.params.id;
    if (audienceId && audienceList.length > 0) {
      const queryAudience = audienceList.find((audience) => audience.id === Number(audienceId));
      setSelectedAudience(queryAudience);
    } else {
      setSelectedAudience(null);
    }
  }, [match.params.id, audienceList]);
  return (
    <Box className="audienceList">
      <List
        testmationLabel="createAudience"
        automation="audienceList"
        rowHeight={40}
        multipleHeaders={multipleHeaders(match)}
        createLabel={tl("campaign.createAudience")}
        hideCreateButton={!policies["campaign:createAudience"]}
        onCreateNew={() => actions.goto("/campaign/audience/new")}
        defaultSortColumn="name"
        data={audienceList || []}
        columns={[
          {
            key: "name",
            label: "Name",
            sortable: true,
            sortFunction: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
            formatter: (a) => <Typography>{a.name ? a.name : ""}</Typography>
          },
          {
            key: "type",
            label: "Type",
            sortable: true,
            sortFunction: (a, b) => (a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1),
            formatter: (a) => <Typography>{a.type ? capitalize(a.type) : ""}</Typography>
          },
          {
            key: "isReady",
            label: "Is Ready",
            formatter: ({ isReady }) => <Typography>{isReady ? "Yes" : "No"}</Typography>
          },
          {
            key: "isBatch",
            label: "Is Batch",
            formatter: ({ isBatch }) => <Typography>{isBatch ? "Yes" : "No"}</Typography>
          },
          {
            key: "rules",
            label: "Rule",
            formatter: (a) => (
              <Typography>
                {a.rules ? getFormattedRulesFromAudience(a as AudienceData, services) : ""}
              </Typography>
            )
          }
        ]}
        isLoading={!audienceList}
        activeRow={selectedAudience && selectedAudience.id}
        onRowClick={async (audience) => {
          const notSelected = !selectedAudience || selectedAudience.id !== audience.id;
          setSelectedAudience(notSelected ? audience : null);
          actions.goto(notSelected ? `/campaign/audience/${audience.id}` : "/campaign/audience/");
        }}
      />
      {(selectedAudience || ["create", "edit"].includes(mode)) && (
        <>
          {["create", "edit"].includes(mode) ? (
            <Can
              policyAccessKey={["campaign:editAudience", "campaign:createAudience"]}
              partialCheck
            >
              <AudienceCreateEdit
                navigateTo={() => ({})}
                onCancel={() => {
                  setSelectedAudience(null);
                  actions.goto("/campaign/audience");
                }}
                mode={mode}
                audienceId={selectedAudience?.id}
              />
            </Can>
          ) : (
            <AudienceInfo
              audience={selectedAudience}
              onClose={() => {
                setSelectedAudience(null);
                actions.goto("/campaign/audience");
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state: RootState, { audienceId }: { audienceId?: number }) => ({
  audienceList: audiencesSelector(state),
  audience: state.audience.collection.find(({ id }) => id === Number(audienceId)),
  policies: state.userContext.policies || {}
});

const mapDispatchToProps = (dispatch: IThunkDispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    goto: (url) => dispatch(push(url)),
    loadAudience: () => dispatch(audienceActions.getAudiences())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AudienceList);

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { omit } from "lodash";
import { push } from "connected-react-router";
import { Box } from "@mui/material";
import style from "./style.module.css";
import { serviceProviderActions } from "../../actions";
import ServiceProviderList from "../ServiceProvider/ServiceProviderList";
import { tl } from "../../components/translate";
import * as NotificationActions from "../../actions/notification";
import { removeGeneralErrorByKey } from "../../actions/error";
import { serviceProvidersSortedSelector } from "../../reducers/serviceProvider";
import Panel from "../../components/Panel";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { IThunkDispatch, RootState } from "../../store";
import { ChildGeneralLedger } from "../../interfaces/Accounts";
import SPCreateEdit, { ALL_DEPARTMENT } from "./UserSettings/SPCreateEdit";
import { serviceProviderSignature, spProfileImagePresignedS3 } from "../../api/user";

interface ServiceProvidersProps {
  serviceProviders: ServiceProvider[];
  resourceCentre: ResourceCentre;
  onSave: (data: Partial<ServiceProvider>, afterSave: (id: number) => void) => void;
  goBack: () => void;
  editSPMode: boolean;
  serviceProviderId: number;
  createSPMode: boolean;
  isAccountSubscribed: boolean;
  spLedger: ChildGeneralLedger;
}

function ServiceProviders({
  onSave,
  resourceCentre,
  serviceProviders,
  goBack,
  editSPMode,
  serviceProviderId,
  createSPMode,
  isAccountSubscribed,
  spLedger
}: ServiceProvidersProps): JSX.Element {
  let selectedServiceProvider: Partial<ServiceProvider> | undefined;
  const [selectedSPId, setSelectedSPId] = React.useState<number | null>(null);

  if (editSPMode) {
    const foundSp = serviceProviders.find((sp) => sp.id === Number(serviceProviderId));
    selectedServiceProvider = !isAccountSubscribed
      ? (omit(
          {
            ...foundSp,
            serviceTagIds: foundSp?.serviceTagIds || [],
            userGroup: foundSp?.user?.userGroups ? foundSp.user.userGroups[0]?.name : "",
            departmentId: foundSp?.departmentId || ALL_DEPARTMENT
          },
          ["openingBalance", "openingBalanceDate", "openingBalance", "ledgerId"]
        ) as ServiceProvider)
      : {
          ...foundSp,
          serviceTagIds: foundSp?.serviceTagIds || [],
          userGroup: foundSp?.user?.userGroups ? foundSp.user.userGroups[0]?.name : "",
          departmentId: foundSp?.departmentId || ALL_DEPARTMENT
        };
  }

  return (
    <div className={style.listContainer}>
      <ServiceProviderList
        resourceCentre={resourceCentre}
        resourceCentreId={resourceCentre.id}
        navigateToResourceSettingsSp
        selectedSPId={selectedSPId}
        setSelectedSPId={setSelectedSPId}
      />
      {createSPMode || editSPMode ? (
        <Panel
          title={
            createSPMode
              ? tl("employee.createServiceProvider")
              : tl("employee.updateServiceProvider")
          }
          onClose={() => {
            goBack();
          }}
        >
          {(createSPMode || serviceProviders.length > 0) && (
            <Box pl={5} pr={5} width="100%" className={style.serviceProviderForm} component="form">
              <SPCreateEdit
                spLedger={spLedger}
                isAccountSubscribed={isAccountSubscribed}
                serviceProvider={selectedServiceProvider}
                createSPMode={createSPMode}
                resourceCentre={resourceCentre}
                onCancel={() => {
                  goBack();
                }}
                onSave={(data) => {
                  onSave(
                    {
                      ...data,
                      resourceCentreId: resourceCentre.id,
                      departmentId: data.departmentId === ALL_DEPARTMENT ? null : data.departmentId
                    },
                    (id) => setSelectedSPId(id)
                  );
                }}
              />
            </Box>
          )}
        </Panel>
      ) : (
        ""
      )}
    </div>
  );
}

export default connect(
  (state: RootState) => ({
    serviceProviders: serviceProvidersSortedSelector(state)
  }),
  (dispatch: IThunkDispatch, ownProps: Partial<ServiceProvidersProps>) => ({
    actions: bindActionCreators({ ...serviceProviderActions }, dispatch),
    onSave: async (data, afterSave) => {
      dispatch(async (dispatchI, getState) => {
        if (data.id) {
          let profileData;
          if (data.profileImage && typeof data.profileImage !== "string") {
            profileData = await spProfileImagePresignedS3(data.profileImage);
          }
          if (data.hasUpdatedSignature) {
            const { signature, signatureKey } = await serviceProviderSignature(data.signature);
            dispatchI(
              serviceProviderActions.putResourceCentreServiceProvider({
                ...data,
                ...(profileData?.profileImage ? profileData : null),
                signature,
                signatureKey
              })
            );
          } else {
            dispatchI(
              serviceProviderActions.putResourceCentreServiceProvider({
                ...data,
                ...(profileData || null)
              })
            );
          }
          dispatchI(push(`/resourcecentres/${ownProps.resourceCentre.id}/serviceProviders`));
        } else {
          const { signature, signatureKey } = await serviceProviderSignature(data.signature);
          let profileData;
          if (data.profileImage) {
            profileData = await spProfileImagePresignedS3(data.profileImage);
          }
          await dispatchI(
            serviceProviderActions.postResourceCentreServiceProvider(
              {
                ...data,
                publicBooking: false,
                signature,
                signatureKey,
                profileImage: profileData?.profileImage || "",
                profileKey: profileData?.profileKey || "",
                blurHash: profileData?.blurHash || ""
              },
              (sp: ServiceProvider) => afterSave(sp.id)
            )
          );
          const currentState = getState();
          const hasErrorForSp = currentState.error.find(
            ({ key }) => key === "POST_RESOURCECENTRESERVICEPROVIDER"
          );
          if (hasErrorForSp) {
            const phoneDuplicationError = currentState.error.find(
              ({ key, data: datum }) =>
                key === "POST_RESOURCECENTRESERVICEPROVIDER" && datum.type === "UniqueViolation"
            );
            const userLimitError = currentState.error.find(
              ({ key, status }) => key === "POST_RESOURCECENTRESERVICEPROVIDER" && status === 400
            );
            if (userLimitError) {
              dispatchI(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: tl("userLimitReached"),
                  autoTimeout: true
                })
              );
              dispatchI(removeGeneralErrorByKey("POST_RESOURCECENTRESERVICEPROVIDER"));
            } else if (phoneDuplicationError) {
              dispatchI(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: tl("phoneNumberAlreadyExists"),
                  autoTimeout: true
                })
              );
              dispatchI(removeGeneralErrorByKey("POST_RESOURCECENTRESERVICEPROVIDER"));
            }
          } else {
            dispatchI(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: tl("successServiceProviderCreation"),
                autoTimeout: true
              })
            );
            dispatchI(push(`/resourcecentres/${ownProps.resourceCentre.id}/serviceProviders`));
            dispatch(
              serviceProviderActions.getResourceCentreServiceProviders({
                resourceCentreId: ownProps.resourceCentre.id
              })
            );
          }
        }
      });
    },
    navigateTo: (url) => {
      dispatch(push(url));
    },
    goBack: () => dispatch(push(`/resourcecentres/${ownProps.resourceCentre.id}/serviceProviders`))
  })
)(ServiceProviders);

import React from "react";
import { Box, Button, TextField, Typography, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { addNewRow, updateVoucherEntry } from "../../../../slices/journalEntry";
import { t, tl } from "../../../../components/translate";
import { RootState } from "../../../../store";
import NrsFormat from "../../../../components/FormattingInput/NrsInput";
import { VoucherEntryProps, VoucherType, VoucherTypes } from "../../../../interfaces/Accounts";

const useStyles = makeStyles({
  input: {
    marginTop: "5px",
    marginRight: "4px"
  }
});

export enum paymentOptions {
  cash = "cash",
  card = "card",
  cheque = "cheque",
  onlinePayment = "onlinePayment",
  balance = "balance",
  bankTransfer = "bankTransfer",
  khalti = "khalti",
  eSewa = "eSewa",
  fonePay = "fonePay"
}

export default function Summary(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const voucherEntry: VoucherEntryProps = useSelector(
    (state: RootState) => state.accounts.voucherEntry
  );

  React.useEffect(() => {
    if (
      [VoucherType.PAYMENT_VOUCHER, VoucherType.RECEIPT_VOUCHER].includes(
        voucherEntry.voucherType as VoucherTypes
      )
    ) {
      dispatch(updateVoucherEntry({ ...voucherEntry, paymentMethod: "", chequeNo: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherEntry.voucherType, dispatch]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ backgroundColor: "#f6f6f6", padding: "10px 50px 10px 0" }}
      >
        <Typography marginRight="10px">{tl("Total")}</Typography>
        <TextField
          sx={{ width: "170px", marginRight: "10px" }}
          disabled
          value={voucherEntry.totalDrAmt}
          margin="none"
          InputProps={{
            disableUnderline: true,
            inputComponent: NrsFormat,
            classes: { input: classes.input },
            startAdornment: tl("rs")
          }}
        />
        <TextField
          sx={{ width: "170px" }}
          disabled
          value={voucherEntry.totalCrAmt}
          margin="none"
          InputProps={{
            disableUnderline: true,
            inputComponent: NrsFormat,
            classes: { input: classes.input },
            startAdornment: tl("rs")
          }}
        />
      </Box>
      <Box m={2} display="flex" flexDirection="column">
        <Button
          sx={{ width: "150px", marginBottom: "50px" }}
          variant="outlined"
          onClick={() => dispatch(addNewRow("voucherEntry"))}
        >
          {tl("accounts.addAnEntryLine")}
        </Button>
        {[VoucherType.PAYMENT_VOUCHER, VoucherType.RECEIPT_VOUCHER].includes(
          voucherEntry.voucherType as VoucherTypes
        ) && (
          <>
            <TextField
              sx={{ width: "200px", marginBottom: "10px" }}
              variant="outlined"
              select
              value={voucherEntry.paymentMethod}
              label={tl("accounts.paymentMethod")}
              onChange={({ target }) => {
                dispatch(updateVoucherEntry({ ...voucherEntry, paymentMethod: target.value }));
                if (target.value !== "cheque" && voucherEntry?.chequeNo) {
                  dispatch(
                    updateVoucherEntry({
                      ...voucherEntry,
                      chequeNo: "",
                      paymentMethod: target.value
                    })
                  );
                }
              }}
            >
              {Object.keys(paymentOptions).map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {voucherEntry?.paymentMethod === "cheque" && (
              <TextField
                value={voucherEntry.chequeNo}
                sx={{ width: "200px", marginBottom: "10px" }}
                variant="outlined"
                label={tl("chequeNo")}
                onChange={({ target }) =>
                  dispatch(updateVoucherEntry({ ...voucherEntry, chequeNo: target.value }))
                }
              />
            )}
          </>
        )}
        <Typography>{tl("accounts.narration")}</Typography>
        <TextField
          value={voucherEntry.remarks}
          onChange={({ target }) =>
            dispatch(updateVoucherEntry({ ...voucherEntry, remarks: target.value }))
          }
          variant="outlined"
          rows={4}
          multiline
          sx={{ width: "500px" }}
          placeholder={t("accounts.narration")}
        />
      </Box>
    </>
  );
}

import { push } from "connected-react-router";
import { actionCreatorAsync, actionCreator } from "./actionHelpers";
import * as api from "../api/ipd";
import { replaceActiveIpd, setActiveBed } from "../slices/bedManagementSlice";
import { AppThunk } from "../store";
import { IPD, IMedicine } from "../interfaces/IpdInterface";
import { IpdFilter } from "../containers/IPD/IpdList";

export enum Type {
  GET_IPD_RECORDS = "GET_IPD_RECORDS",
  GET_IPD_RECORD = "GET_IPD_RECORD",
  CREATE_IPD_RECORD = "CREATE_IPD_RECORD",
  UPDATE_IPD_RECORD = "UPDATE_IPD_RECORD",
  DELETE_IPD_RECORD = "DELETE_IPD_RECORD",
  CREATE_DISCHARGE_SUMMARY = "CREATE_DISCHARGE_SUMMARY",

  CREATE_IPD_PROGRESS_RECORD = "CREATE_IPD_PROGRESS_RECORD",
  CREATE_IPD_PRESCRIBE_MEDICATION = "CREATE_IPD_PRESCRIBE_MEDICATION"
}

export const setSelectedIpdRecord = actionCreator(Type.GET_IPD_RECORD);

export const getIpdRecords =
  ({
    filter,
    page,
    pageSize
  }: {
    filter: IpdFilter;
    page: number | string;
    pageSize: number | string;
  }): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_IPD_RECORDS, async () =>
        api.getIpdRecords({ filter, page, pageSize })
      )
    );
  };

export const createIpdRecord =
  (data: Partial<IPD>): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CREATE_IPD_RECORD, async () => api.createIpdRecord(data))
    );
  };

export const saveIpdRecord =
  (ipdData: Partial<IPD>, stayOnPage = false): AppThunk =>
  async (dispatch) => {
    if (ipdData.id) {
      await dispatch(
        actionCreatorAsync(Type.UPDATE_IPD_RECORD, async () => {
          const data = await api.updateIpdRecord(ipdData.id, ipdData);
          if (data.id && !stayOnPage)
            dispatch(push(`/ipd/${data.id}?filter=${IpdFilter.ADMITTED}`));
          return data;
        })
      );
    } else {
      await dispatch(
        actionCreatorAsync(Type.CREATE_IPD_RECORD, async () => {
          const data = await api.createIpdRecord(ipdData);
          dispatch(setActiveBed(data));
          if (data.id && !stayOnPage)
            dispatch(push(`/ipd/${data.id}?filter=${IpdFilter.ADMITTED}`));
          return data;
        })
      );
    }
  };

export const createDischargeSummary =
  (ipdData: Partial<IPD>): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CREATE_DISCHARGE_SUMMARY, async () => {
        const data = await api.createDischargeSummary(ipdData.id, ipdData);
        dispatch(replaceActiveIpd(data));
        dispatch(setSelectedIpdRecord(data));
        dispatch(push(`/ipd/${data.id}?filter=${IpdFilter.DISCHARGED}`));
        return data;
      })
    );
  };

export const createIpdProgressRecord =
  (ipdData: Partial<IPD>): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CREATE_IPD_PROGRESS_RECORD, async () => {
        const data = await api.createProgressRecord(ipdData.id, ipdData);
        dispatch(replaceActiveIpd(data));
        return data;
      })
    );
  };

export const createIpdPrescribeMedication =
  (id: number, medications: Array<IMedicine>): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CREATE_IPD_PRESCRIBE_MEDICATION, async () =>
        api.prescribedMedications(id, medications)
      )
    );
  };

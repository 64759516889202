import React from "react";
import { Box, Typography, Menu, Checkbox } from "@mui/material";
import "./EyeExamination.scss";

const Popper = ({
  checkStatus,
  setCheckStatus
}: {
  checkStatus: Record<string, boolean>;
  setCheckStatus: (v) => void;
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const CustomMenuItem = ({ checked, label, subItem = false, onChange }) => (
    <Box className="menuItem">
      <Box className={`${subItem ? "subItem" : ""}`} fontWeight={`${subItem ? "" : 600}`}>
        {label}
      </Box>
      <Checkbox
        checked={checked}
        onChange={onChange}
        inputProps={{ "aria-label": "checkbox" }}
        size="small"
      />
    </Box>
  );

  return (
    <Box style={{ cursor: "pointer", width: "100%" }}>
      <Box style={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
        <Typography onClick={handleClick}>
          <u>Add/Remove Items</u>
        </Typography>
      </Box>
      <Menu
        id="eye-examination"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <CustomMenuItem
          checked={checkStatus.vision}
          label="Vision"
          onChange={() => setCheckStatus({ ...checkStatus, vision: !checkStatus.vision })}
        />
        {checkStatus.vision && (
          <Box>
            <CustomMenuItem
              checked={checkStatus.autoRefraction}
              subItem
              label="Auto Refraction"
              onChange={() =>
                setCheckStatus({ ...checkStatus, autoRefraction: !checkStatus.autoRefraction })
              }
            />
            <CustomMenuItem
              checked={checkStatus.refraction}
              subItem
              label="Refraction"
              onChange={() =>
                setCheckStatus({ ...checkStatus, refraction: !checkStatus.refraction })
              }
            />

            <CustomMenuItem
              checked={checkStatus.unaided}
              subItem
              label="Unaided"
              onChange={() => setCheckStatus({ ...checkStatus, unaided: !checkStatus.unaided })}
            />

            <CustomMenuItem
              checked={checkStatus.aided}
              subItem
              label="Aided"
              onChange={() => setCheckStatus({ ...checkStatus, aided: !checkStatus.aided })}
            />

            <CustomMenuItem
              checked={checkStatus.fieldOfVision}
              subItem
              label="Field Of Vision"
              onChange={() =>
                setCheckStatus({
                  ...checkStatus,
                  fieldOfVision: !checkStatus.fieldOfVision
                })
              }
            />

            <CustomMenuItem
              subItem
              checked={checkStatus.colorVision}
              label="Color Vision"
              onChange={() =>
                setCheckStatus({
                  ...checkStatus,
                  colorVision: !checkStatus.colorVision
                })
              }
            />

            <CustomMenuItem
              checked={checkStatus.bestCorrected}
              subItem
              label="Best corrected visual activity"
              onChange={() =>
                setCheckStatus({ ...checkStatus, bestCorrected: !checkStatus.bestCorrected })
              }
            />

            <CustomMenuItem
              checked={checkStatus.pinHole}
              subItem
              label="Pin Hole"
              onChange={() => setCheckStatus({ ...checkStatus, pinHole: !checkStatus.pinHole })}
            />
          </Box>
        )}

        <CustomMenuItem
          checked={checkStatus.anteriorSegment}
          label="Anterior Segment"
          onChange={() =>
            setCheckStatus({ ...checkStatus, anteriorSegment: !checkStatus.anteriorSegment })
          }
        />

        <CustomMenuItem
          checked={checkStatus.posteriorSegment}
          label="Posterior Segment"
          onChange={() =>
            setCheckStatus({ ...checkStatus, posteriorSegment: !checkStatus.posteriorSegment })
          }
        />

        <CustomMenuItem
          checked={checkStatus.glassPrescription}
          label="Glass Prescription"
          onChange={() =>
            setCheckStatus({
              ...checkStatus,
              glassPrescription: !checkStatus.glassPrescription
            })
          }
        />
      </Menu>
    </Box>
  );
};

export default Popper;

import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import produce from "immer";
import { tl } from "../../../components/translate";
import Can from "../../Policy/Can";
import {
  deserializeNode,
  proseMirrorOptions,
  RichTextfield
} from "../../../components/RichTextfield";
import { useParsedProseMirror } from "../../Lab/LabEntry/LabTestSubRow";
import { AsyncAction } from "../../../actions/actionHelpers";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import AssessmentLabels from "./AssessmentLabels";
import AssessmentVitals from "./AssessmentVitals";

interface AssessmentSettingsProps {
  actions: { [x: string]: (options?: Record<string, any>, next?: any) => AsyncAction<any> };
  resourceCentre: ResourceCentre;
}

export default function AssessmentSettings({
  resourceCentre,
  actions
}: AssessmentSettingsProps): JSX.Element {
  const [assessmentRemarksState, setAssessmentRemarksState] = useParsedProseMirror(
    resourceCentre.settings.printSettings.assessmentPrintSettings.assessmentRemarks || "",
    proseMirrorOptions
  );

  return (
    <Box marginTop="15px">
      <Box pl="16px">
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={
                  resourceCentre.settings.printSettings.assessmentPrintSettings.includeLetterhead
                }
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.settings.printSettings.assessmentPrintSettings.includeLetterhead =
                      !resourceCentre.settings.printSettings.assessmentPrintSettings
                        .includeLetterhead;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.includeLetterhead")}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={
                  resourceCentre.settings.printSettings.assessmentPrintSettings.includeFooter
                }
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.settings.printSettings.assessmentPrintSettings.includeFooter =
                      !resourceCentre.settings.printSettings.assessmentPrintSettings.includeFooter;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.includeFooter")}
          />
          {!resourceCentre.settings.printSettings.assessmentPrintSettings.includeFooter && (
            <Can policyAccessKey="deny" superAdminPass>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.assessmentPrintSettings
                        .hideFooterLogoCompletely
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.assessmentPrintSettings.hideFooterLogoCompletely =
                          !resourceCentre.settings.printSettings.assessmentPrintSettings
                            .hideFooterLogoCompletely;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.hideFooterCompletely")}
              />
            </Can>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={
                  resourceCentre.settings.printSettings.assessmentPrintSettings
                    .showAssessmentRemarks
                }
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.settings.printSettings.assessmentPrintSettings.showAssessmentRemarks =
                      !draft.settings.printSettings.assessmentPrintSettings.showAssessmentRemarks;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.showAssessmentRemarks")}
          />
        </Box>
        {resourceCentre.settings.printSettings.assessmentPrintSettings.showAssessmentRemarks && (
          <Box display="flex" mt="16px" flexWrap="wrap">
            <Typography pr={2}>{tl("settings.assessmentRemarks")}</Typography>
            <Box width="400px">
              <RichTextfield state={assessmentRemarksState} setState={setAssessmentRemarksState} />
              <Box
                width="100%"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "8px"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.printSettings.assessmentPrintSettings.assessmentRemarks =
                        deserializeNode(assessmentRemarksState.doc);
                    });
                    actions.putResourceCentre(newRc);
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <AssessmentLabels resourceCentre={resourceCentre} />
      <AssessmentVitals resourceCentre={resourceCentre} />
    </Box>
  );
}

import * as React from "react";
import { TextField, Autocomplete } from "@mui/material";
import * as supportAdminApi from "../../../api/supportAdmin";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";

interface Props {
  onChange: (v) => void;
  modifier: (v) => Array<Partial<ResourceCentre>>;
}

// For SuperAdmin only
const ClinicSelect = ({ onChange, modifier = null }: Props): JSX.Element => {
  const [clinicList, setClinicList] = React.useState<Array<ResourceCentre>>([]);

  const getRCs = async () => {
    const response = (await supportAdminApi.getResourceCentres()) as Array<Partial<ResourceCentre>>;
    setClinicList(modifier ? modifier(response) : response);
  };

  React.useEffect(() => {
    getRCs();
  }, []);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={clinicList}
      getOptionLabel={(option) => `${option.name} [${option.id}]` || ""}
      style={{ marginTop: "5px" }}
      fullWidth
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          [{option.id}] {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Select clinic"
          placeholder="Select clinic"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      )}
      onChange={(e, v) => {
        onChange(v);
      }}
    />
  );
};

export default ClinicSelect;

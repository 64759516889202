import * as React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { LabStatuses } from "../../interfaces/Lab";

interface LabStatusAutoSelectProps {
  onChange: (values) => void;
  isDisabled?: boolean;
  variant?: string;
}

const LabStatusAutoSelect: React.FC<LabStatusAutoSelectProps> = ({
  onChange,
  isDisabled,
  variant
}) => (
  <Autocomplete
    multiple
    options={Object.values(LabStatuses)}
    onChange={(e, values) => {
      onChange(values);
    }}
    renderOption={(props, option) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li {...props} key={option}>
        {option}
      </li>
    )}
    disabled={isDisabled}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant={variant}
        label="Status"
        placeholder="Select status of lab reports"
        margin="dense"
        fullWidth
      />
    )}
  />
);

LabStatusAutoSelect.defaultProps = {
  isDisabled: false,
  variant: "standard"
};

export default LabStatusAutoSelect;

import React from "react";
import {
  CircularProgress,
  Link,
  Typography,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Portal
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { usePreviousReleaseNotes } from "../../api/knowledgeBase";
import { ReleaseNote } from "../../api/prismicQuery";

import ReleaseNoteContent from "../Modals/ReleaseNoteModal/ReleaseNoteContent";

export default function PreviousReleaseNotes(): JSX.Element {
  const { data = [], isLoading } = usePreviousReleaseNotes();
  const [selectedReleaseNote, setSelectedReleaseNote] = React.useState<ReleaseNote | null>(null);
  return (
    <div>
      {isLoading && <CircularProgress size={30} />}
      {data.map((releaseNote, index) => (
        <div key={releaseNote.id}>
          <Box display="flex">
            <Typography>{index + 1}. </Typography>
            <Link
              onClick={() => setSelectedReleaseNote(releaseNote)}
              sx={{ mx: "10px", cursor: "pointer" }}
              fontSize="15px"
              key={releaseNote.id}
            >
              {releaseNote.data.version}
            </Link>
          </Box>
        </div>
      ))}
      {selectedReleaseNote && (
        <Portal>
          <Dialog
            fullWidth
            maxWidth="md"
            open={Boolean(selectedReleaseNote)}
            aria-labelledby="release-note-modal"
            aria-describedby="release note"
          >
            <DialogTitle
              id="release-note-dialog-title"
              sx={{
                background: "#009654",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Box ml={1} color="#fff" fontSize={20} fontWeight={700} component="span">
                What&apos;s New
              </Box>
              <IconButton
                aria-label="close release note"
                component="button"
                onClick={() => setSelectedReleaseNote(null)}
              >
                <CloseIcon sx={{ color: "#fff" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ReleaseNoteContent releaseNotes={[selectedReleaseNote]} />
            </DialogContent>
          </Dialog>
        </Portal>
      )}
    </div>
  );
}

import MoneyIcon from "@mui/icons-material/Money";
import { Box, Radio, Typography } from "@mui/material";
import React from "react";
import { PaymentMethods, PaymentMethodsType } from "../../../interfaces/PublicLabInterface";
import { StateProps } from "./ScheduleDetails";

interface Props {
  currentState: StateProps;
  onChange: (newState: StateProps) => void;
}
export default function PaymentDetails({ currentState, onChange }: Props): JSX.Element {
  return (
    <Box display="flex" alignItems="center">
      <Radio
        data-testmation="codRadio"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...currentState, paymentMethod: event.target.value as PaymentMethodsType });
        }}
        value={PaymentMethods.CASH_ON_DELIVERY}
        checked={currentState.paymentMethod === PaymentMethods.CASH_ON_DELIVERY}
        name="radio-buttons"
      />
      <MoneyIcon />
      <Typography>Cash On Delivery</Typography>
    </Box>
  );
}

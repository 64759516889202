import { Get } from "./apiHelper";
import localStorageObj from "../helpers/localStorage";
const accessingRCsRoot = "/api/accessingResourceCentres";

export const getAccessingResourceCentres = async (ids) => {
  const key = `acccessingRCs${ids.join("")}`;
  const cachedAccesingRCs = localStorageObj.getItem(key);
  if (cachedAccesingRCs) {
    return JSON.parse(cachedAccesingRCs);
  }
  const response = await Get(`${accessingRCsRoot}`, true);
  localStorageObj.setItem(key, JSON.stringify(response.data));
  return response.data;
};

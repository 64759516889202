import * as React from "react";
import {
  Box,
  FormGroup,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  Alert
} from "@mui/material";
import { chain } from "mathjs";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { startCase, pickBy } from "lodash";
import produce from "immer";
import { tl } from "../../../components/translate";
import NrsFormat from "../../../components/FormattingInput/NrsInput";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import { paymentOptionsEnum, PaymentMethodSelect } from "../Editor/BillSummary";
import Panel from "../../../components/Panel";
import * as billActions from "../../../actions/bill";
import * as NotificationActions from "../../../actions/notification";
import classNames from "../../../helpers/classNames";
import * as billsApi from "../../../api/bill";
import { TrackingNumType, TransactionType } from "../../../interfaces/BillInterfaces";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import ClientSearch from "../../../components/ClientSearch";
import { getClientById } from "../../../actions/client";
import { IThunkDispatch, RootState } from "../../../store";
import { Client } from "../../../interfaces/ClientInterface";
import { getCurrentSubscription } from "../../../slices/subscriptionSlice";
import { hasFiscalYear2079Started } from "../../../actions/helpers/billHelper";
import { ChildGeneralLedger, LedgerType, VoucherCodes } from "../../../interfaces/Accounts";
import { getNextBillNumber } from "../../../api/bill";
import Can from "../../Policy/Can";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../accounts/hooks";
import LedgerSelect from "../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import Modal from "../../../components/Modal/Modal";
import { updateClient } from "../../../api/client";

interface RecordPaymentProps {
  refundMode?: boolean;
  clientId?: number;
  clients?: Array<Client>;
  billId?: number;
  onClose: () => void;
  recordPayment: (data) => void;
  lastTouchedClient: Client;
  getClientByIdAction: (id: number) => void;
  showDisc?: boolean;
  loadBills: ({ page, pageSize, filter, sortColumn, sortDirection }) => void;
}

const useStyles = makeStyles({
  footer: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    right: "32px",
    paddingTop: "8px"
  },
  sameWidthWrapper: {
    width: "450px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  boldText: {
    fontWeight: 600,
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end"
  }
});

const RupeeDisplay = ({ amount, fontWeight = 600 }) => (
  <Box width="95px" display="flex" flexDirection="row" justifyContent="space-between">
    <Typography style={{ fontWeight }}>{tl("rs")}</Typography>{" "}
    <Typography style={{ fontWeight }}>{Number(amount).toFixed(2) || 0}</Typography>
  </Box>
);

interface PaymentDistributionInterface {
  id: number;
  paid: boolean;
  amount: number;
  billNumber: string;
  dueAmount: number;
  sNo: number;
  isSelected: boolean;
  paidAmount?: number;
}

const BillNumber = ({ trackingNumber, refundMode }): JSX.Element => {
  const has2069Started = hasFiscalYear2079Started();
  return (
    <Box display="flex">
      {refundMode ? (
        <Typography width="140px">
          {has2069Started ? tl("billing.refundNumber") : tl("accounts.voucherNumber")}
        </Typography>
      ) : (
        <Typography width="140px">
          {has2069Started ? tl("reports.billNumber") : tl("accounts.voucherNumber")}
        </Typography>
      )}
      {trackingNumber && <Typography>{trackingNumber}</Typography>}
    </Box>
  );
};

/**
 * Disable the save button if the RC is accountSubscribed and
 *  the payment method is not mapped with any ledger.
 */

export const checkAccountRelatedValidation = (
  isAccountSubscribed: boolean,
  paymentMethods: { [key: string]: { ledgerId: number } },
  selectedPaymentMethod: string
): boolean => {
  if (!isAccountSubscribed) {
    return false;
  }
  return !paymentMethods[selectedPaymentMethod]?.ledgerId;
};

const showAlert = (client: Client, isAccountSubscribed: boolean): boolean => {
  if (!isAccountSubscribed || !client) return false;
  return !client.ledgerId;
};

const RecordPayment: React.FC<RecordPaymentProps> = ({
  refundMode = false,
  onClose,
  clients,
  clientId,
  billId: prioritizedBillId,
  recordPayment,
  lastTouchedClient,
  getClientByIdAction,
  showDisc,
  loadBills
}) => {
  const styles = useStyles({});
  const [client, setClient] = React.useState(clients.find(({ id }) => id === clientId));
  const [takeLastTouchedClient, setTakeLastTouchedClient] = React.useState(false);
  const [trackingNumber, setTrackingNumber] = React.useState(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (takeLastTouchedClient && lastTouchedClient) {
      setClient(lastTouchedClient);
      setTakeLastTouchedClient(false);
    }
  }, [takeLastTouchedClient, lastTouchedClient]);

  const [showLedgerSelect, setShowLedgerSelect] = React.useState(false);
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger>(null);
  const { assets } = extractSecondaryData(useCoaAll());
  const allAssetsLedgers = getAllChildGl(assets);
  const paymentMethods = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment
  );

  React.useEffect(() => {
    if (clientId) {
      getClientByIdAction(clientId);
    }
  }, [clientId, getClientByIdAction]);

  React.useEffect(() => {
    setClient(clients.find(({ id }) => id === clientId));
  }, [clients, clientId]);

  const [paymentInfoState, setPaymentInfo] = React.useState({
    paidAmount: 0,
    remarks: "",
    paymentMethod: paymentOptionsEnum.cash,
    receivedOn: new Date(),
    trxType: refundMode ? TransactionType.DEBIT : TransactionType.CREDIT,
    discountAmt: 0,
    currentPaidAmt: 0
  });

  const [dueBills, setDueBills] = React.useState([]);
  const [currentBalance, setCurrentBalance] = React.useState(0);
  const [paymentDistribution, setPaymentDistribution] = React.useState<
    Partial<PaymentDistributionInterface>[]
  >([{ id: null, amount: 0 }] as Partial<PaymentDistributionInterface>[]);
  const [saving, setSaving] = React.useState(false);

  const { isAccountSubscribed, resourceCentreId, hasCurrentSubscription } = useSelector(
    (state: RootState) => ({
      isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed,
      resourceCentreId: state.userContext.resourceCentre.id,
      hasCurrentSubscription: state.subscriptions.currentSubscription
    })
  );

  React.useEffect(() => {
    if (!hasCurrentSubscription) {
      dispatch(getCurrentSubscription(resourceCentreId));
    }
  }, [resourceCentreId, dispatch, hasCurrentSubscription]);

  const savePayment = async (trxData) => {
    await recordPayment(trxData);
    await loadBills({
      page: 0,
      pageSize: 100,
      filter: "all",
      sortColumn: "issueDate",
      sortDirection: "DESC"
    });
  };

  React.useEffect(() => {
    setClient(clients.find(({ id }) => id === clientId));
    setPaymentInfo({ ...paymentInfoState, paidAmount: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  React.useEffect(() => {
    (async () => {
      if (!isAccountSubscribed) return;
      let type;
      let res;
      const fiscalYear2079Started = hasFiscalYear2079Started();
      if (refundMode) {
        type = fiscalYear2079Started ? VoucherCodes.PM : TrackingNumType.REFUND_NUMBER;
      } else {
        type = fiscalYear2079Started ? VoucherCodes.RC : TrackingNumType.RECEIPT_NUMBER;
      }
      try {
        if (fiscalYear2079Started) {
          res = await getNextBillNumber(type, paymentInfoState.receivedOn);
        } else {
          res = await getNextBillNumber(type);
        }
        setTrackingNumber(res.nextNumber);
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error.data?.message || "Sorry, something went wrong.",
            autoTimeout: true
          })
        );
      }
    })();
  }, [isAccountSubscribed, refundMode, dispatch, paymentInfoState.receivedOn]);

  React.useEffect(() => {
    if (client) {
      billsApi
        .getDueBillsForRecordPayment(client.id.toString())
        .then(({ bills, balance }) => {
          if (prioritizedBillId) {
            const repositionedBills = bills.filter((bill) => bill.id !== prioritizedBillId);
            const prioritizedBill = bills.find((bill) => bill.id === prioritizedBillId);
            repositionedBills.unshift(prioritizedBill);
            setDueBills(repositionedBills);
          } else setDueBills(bills);
          setCurrentBalance(Number(balance));
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  React.useEffect(() => {
    let remainingBalance = paymentInfoState.paidAmount;
    const amtDst = [] as Partial<PaymentDistributionInterface[]>;
    dueBills?.forEach((bill, index) => {
      if (remainingBalance > 0) {
        const paidAmount =
          remainingBalance > bill.summary.dueAmount ? bill.summary.dueAmount : remainingBalance;
        remainingBalance = chain(remainingBalance).subtract(paidAmount).done();
        amtDst.push({
          id: bill.id,
          paid: paidAmount === bill.summary.dueAmount,
          amount: paidAmount,
          billNumber: bill.billNumber,
          dueAmount: bill.summary.dueAmount,
          sNo: index,
          isSelected: true
        });
      } else {
        amtDst.push({
          id: bill.id,
          paid: false,
          amount: 0,
          billNumber: bill.billNumber,
          dueAmount: bill.summary.dueAmount,
          sNo: index,
          isSelected: false
        });
      }
    });
    setPaymentDistribution([
      ...amtDst,
      {
        id: null,
        amount: remainingBalance > 0 ? remainingBalance : 0
      }
    ]);
  }, [paymentInfoState.paidAmount, dueBills]);

  const checkBoxClickHandler = (index) => {
    setPaymentDistribution(
      produce(paymentDistribution, (draft) => {
        const id = draft.findIndex((trx) => trx.sNo === index);
        const adv = draft.findIndex((bill) => bill.id === null);
        if (id !== -1 && adv !== -1) {
          if (draft[id].isSelected) {
            draft[adv].amount = chain(draft[id].amount).add(draft[adv].amount).done();
            draft[id].amount = 0;
            if (draft[id].paid === true) {
              draft[id].paid = false;
            }
            draft[id].isSelected = false;
          } else if (draft[adv].amount > 0) {
            const remainingBalance = draft[adv].amount;
            const paidAmount =
              remainingBalance > draft[id].dueAmount ? draft[id].dueAmount : remainingBalance;
            draft[adv].amount = chain(remainingBalance).subtract(paidAmount).done();
            draft[id].amount = paidAmount;
            draft[id].paid = draft[id].paidAmount === draft[id].dueAmount;
            draft[id].isSelected = true;
          }
        }
      })
    );
  };

  return (
    <Panel
      wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
      title={refundMode ? "Refund" : "Record Receipt"}
      onClose={onClose}
      footer={
        <Box className={classNames(styles.footer)}>
          <Box mr={5}>
            <Button data-testmation="billing.recordPayment.cancel" onClick={onClose}>
              <Typography>{tl("cancel")}</Typography>
            </Button>
          </Box>
          <StatefulButton
            variant="contained"
            color="primary"
            data-testmation="billingRecordPaymentSave"
            disabled={
              !client ||
              saving ||
              !paymentInfoState.remarks ||
              !paymentInfoState.paidAmount ||
              checkAccountRelatedValidation(
                isAccountSubscribed,
                paymentMethods,
                paymentInfoState.paymentMethod
              )
            }
            isLoading={saving}
            circularProgressProps={{ size: 16 }}
            onClick={async () => {
              const filteredDistribution = paymentDistribution.filter(
                (distribution) => distribution.amount > 0
              );
              setSaving(true);
              await savePayment({
                paymentInfo: paymentInfoState,
                clientId: client.id,
                paymentDistribution: filteredDistribution,
                prioritizedBillId
              });
              setSaving(false);
              onClose();
            }}
          >
            <Typography>{tl("save")}</Typography>
          </StatefulButton>
        </Box>
      }
    >
      <Box
        p={4}
        // consistent with other info or form panels
        style={{
          height: `calc(100vh - 100px)`,
          overflowY: "auto"
        }}
      >
        <Can policyAccessKey="account:listAccount">
          <BillNumber refundMode={refundMode} trackingNumber={trackingNumber} />
        </Can>

        <FormGroup>
          <>
            <Box component="div">
              <ClientSearch
                variant="outlined"
                label={tl("billing.client")}
                margin="dense"
                isDisabled={Boolean(clientId)}
                setClient={setClient}
                client={client}
                data-testmation="recordPaymentClientSearch"
              />
              {showAlert(client, isAccountSubscribed) && (
                <Alert
                  action={
                    <Button
                      onClick={() => setShowLedgerSelect(true)}
                      variant="outlined"
                      color="inherit"
                      size="small"
                    >
                      Map Ledger
                    </Button>
                  }
                  severity="warning"
                >
                  Client is not Mapped with ledger.
                </Alert>
              )}
              <Modal
                open={showLedgerSelect}
                title="Select Ledger for Supplier"
                footer={
                  <>
                    <Button
                      disabled={!client}
                      onClick={() => {
                        setShowLedgerSelect(false);
                        setSelectedLedger(null);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={!selectedLedger}
                      onClick={async () => {
                        try {
                          const res = await updateClient(
                            client.id,
                            pickBy({
                              ...client,
                              ledgerId: selectedLedger.id,
                              gender: Number(client.gender)
                            })
                          );
                          if (res?.ledgerId) {
                            setClient({
                              ...client,
                              ledgerId: res.ledgerId
                            });
                          }
                          setShowLedgerSelect(false);
                          setSelectedLedger(null);
                        } catch (error) {
                          dispatch(
                            NotificationActions.notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Can't update the client ledger.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      Save
                    </Button>
                  </>
                }
              >
                <LedgerSelect
                  options={
                    allAssetsLedgers?.filter(
                      (lg) => lg.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER
                    ) || []
                  }
                  selected={selectedLedger}
                  onChange={(value) => setSelectedLedger(value)}
                />
              </Modal>
              <Box mt={2}>
                <Typography variant="h3" fontSize="14px" fontWeight={500}>
                  {`${refundMode ? "Refund" : "Receipt"} Information`}
                </Typography>
                <Grid container spacing={1} mt={1}>
                  <Grid item md={showDisc ? 4 : 6}>
                    <TextField
                      label={
                        refundMode
                          ? tl("billing.recordPayment.refund")
                          : tl("billing.recordPayment.receipt")
                      }
                      data-testmation="billingRecordPayment"
                      value={paymentInfoState.currentPaidAmt}
                      margin="dense"
                      variant="outlined"
                      onFocus={(e) => e.target.select()}
                      onChange={(e) => {
                        let paidAmount = Number(e.target.value);
                        if (refundMode) {
                          if (currentBalance > 0) {
                            if (paidAmount > currentBalance) paidAmount = currentBalance;
                          } else {
                            paidAmount = 0;
                          }
                        }
                        setPaymentInfo({
                          ...paymentInfoState,
                          paidAmount: paidAmount + paymentInfoState.discountAmt,
                          currentPaidAmt: paidAmount
                        });
                      }}
                      InputProps={{
                        inputComponent: NrsFormat,
                        startAdornment: "रू",
                        style: {
                          textAlign: "right"
                        }
                      }}
                    />
                  </Grid>
                  {showDisc && !isAccountSubscribed && (
                    <Grid item md={4}>
                      <TextField
                        label={tl("billing.discount")}
                        data-testmation="billing.recordPayment.discount"
                        value={paymentInfoState.discountAmt}
                        margin="dense"
                        variant="outlined"
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => {
                          setPaymentInfo({
                            ...paymentInfoState,
                            discountAmt: Number(e.target.value),
                            paidAmount: paymentInfoState.currentPaidAmt + Number(e.target.value)
                          });
                        }}
                        InputProps={{
                          inputComponent: NrsFormat,
                          startAdornment: "रू",
                          style: {
                            textAlign: "right"
                          }
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item md={showDisc ? 4 : 6}>
                    <PaymentMethodSelect
                      selectedPaymentMethod={paymentInfoState.paymentMethod}
                      onPaymentMethodChange={(e, v) => {
                        if (!v) return;
                        setPaymentInfo({ ...paymentInfoState, paymentMethod: v });
                      }}
                      TxtFieldProps={{
                        label: refundMode
                          ? tl("billing.paymentMethod")
                          : tl("billing.receiptMethod"),
                        fullWidth: true
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Grid container spacing={1} mt={1}>
              <Grid item md={9}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label={tl("billing.remarks")}
                  required
                  data-testmation="billing.recordPayment.remarks"
                  value={paymentInfoState.remarks}
                  margin="dense"
                  placeholder="Advance or Due"
                  variant="outlined"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setPaymentInfo({ ...paymentInfoState, remarks: e.target.value });
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item md={3}>
                <CalendarDropdown
                  TextFieldProps={{
                    fullWidth: true,
                    variant: "outlined",
                    margin: "dense",
                    label: tl(`${refundMode ? "billing.refundDate" : "billing.receiptDate"}`)
                  }}
                  className="billActionCalendarPopper"
                  date={paymentInfoState.receivedOn}
                  onChange={(date) => setPaymentInfo({ ...paymentInfoState, receivedOn: date })}
                  data-testmation="billing.recordPayment.calendarDropdown"
                />
              </Grid>
            </Grid>
          </>
        </FormGroup>
        {client && (
          <>
            {!refundMode && (
              <Box mt={2}>
                <Typography style={{ fontWeight: 600 }}>
                  {tl("billing.recordPayment.describePaymentDistribution")}
                </Typography>
                <div>
                  {paymentDistribution.map((amtDst, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box display="flex" flexDirection="row" key={i} alignItems="center">
                      {amtDst.id && (
                        <Checkbox
                          size="small"
                          checked={amtDst.isSelected}
                          onChange={() => checkBoxClickHandler(i)}
                        />
                      )}
                      <Box width="375px" mx="8px">
                        {amtDst.id ? (
                          <Typography>
                            {tl("billing.recordPayment.dueClearance")} {amtDst.billNumber} (Due of
                            Rs. {amtDst.dueAmount.toFixed(2)})
                          </Typography>
                        ) : (
                          <>
                            <Typography>{tl("billing.recordPayment.advance")}</Typography>
                            <RupeeDisplay
                              amount={paymentDistribution.find((amtD) => !amtD.id).amount}
                              fontWeight={400}
                            />
                          </>
                        )}
                      </Box>
                      {amtDst.id && <RupeeDisplay amount={amtDst.amount} fontWeight={400} />}
                    </Box>
                  ))}
                </div>
              </Box>
            )}
            <Box mt={2}>
              <Typography style={{ fontWeight: 600 }}>{`${startCase(
                `${client.firstName} ${client.lastName}`
              )}'s Account`}</Typography>
              <Grid container style={{ maxWidth: "500px" }}>
                <Grid container className={styles.boldText}>
                  <Grid item sm={6} />
                  <Grid item sm={3}>
                    {tl("billing.recordPayment.current")}
                  </Grid>
                  <Grid item sm={3}>
                    {tl("billing.recordPayment.afterPayment")}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={6}>
                    {tl("billing.recordPayment.dueAmount")}
                  </Grid>
                  <Grid item sm={3} className={styles.boldText}>
                    <RupeeDisplay
                      amount={dueBills?.reduce(
                        (acc, bill) => acc + Number(bill.summary.dueAmount),
                        0
                      )}
                    />
                  </Grid>
                  <Grid item sm={3} className={styles.boldText}>
                    <RupeeDisplay
                      amount={paymentDistribution?.reduce((acc, amtDst) => {
                        if (amtDst.id && !amtDst.paid) {
                          // eslint-disable-next-line no-param-reassign
                          acc = acc + Number(amtDst.dueAmount) - Number(amtDst.amount);
                        }
                        return acc;
                      }, 0)}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={6}>
                    {tl("billing.recordPayment.deposit")}
                  </Grid>
                  <Grid item sm={3} className={styles.boldText}>
                    <RupeeDisplay amount={currentBalance > 0 ? currentBalance : 0} />
                  </Grid>
                  <Grid item sm={3} className={styles.boldText}>
                    <RupeeDisplay
                      amount={
                        refundMode
                          ? chain(currentBalance > 0 ? currentBalance : 0)
                              .subtract(paymentInfoState.paidAmount)
                              .done()
                          : chain(paymentDistribution.find((amtDst) => !amtDst.id).amount)
                              .add(currentBalance > 0 ? currentBalance : 0)
                              .done()
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={6}>
                    {tl("billing.recordPayment.openBills")}
                  </Grid>
                  <Grid item sm={3} className={styles.boldText}>
                    {dueBills?.length}
                  </Grid>
                  <Grid item sm={3} className={styles.boldText}>
                    {paymentDistribution.filter((amtDst) => amtDst.id && !amtDst.paid).length}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Panel>
  );
};

RecordPayment.defaultProps = {
  refundMode: false,
  clientId: undefined,
  clients: [],
  billId: undefined,
  showDisc: false
};

export default connect(
  (state: RootState) => ({
    clients: state.clients.collection?.filter((client) => client.active),
    lastTouchedClient: state.clients.lastTouched
  }),
  (dispatch: IThunkDispatch) => ({
    recordPayment: (trxData) => {
      dispatch(billActions.recordPayment(trxData));
    },
    loadBills: (query?) => dispatch(billActions.fetchBills(query && query)),
    getClientByIdAction: (id) => dispatch(getClientById(id))
  })
)(RecordPayment);

import { useEffect, useState } from "react";
import * as NotificationActions from "../actions/notification";
import { getNextIpdNumber } from "../api/ipd";
import { useAppDispatch } from "../store/hooks";
import { errorFetchMessage, MODULE } from "../helpers/messages";

export interface NextIpdNumber {
  nextNumber: number;
}
const useGetNextIpdNumber = (enabled: boolean): NextIpdNumber | null => {
  const [ipdNumber, setIpdNumber] = useState<NextIpdNumber | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNextIpdNumber();
        setIpdNumber(response);
      } catch (err) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: err?.message || errorFetchMessage(MODULE.IPD_NUMBER),
            autoTimeout: true
          })
        );
      }
    };

    if (enabled) {
      fetchData();
    }
  }, []);

  return ipdNumber;
};
export default useGetNextIpdNumber;

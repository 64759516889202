import { ResourceCentre } from "../interfaces/ResourceCentreInterface";
import { Get, Patch, Post } from "./apiHelper";

const userGroupsRoot = "/api/admin/userGroups";

interface Statement {
  effect: string;
  actions: string;
}
interface Policy {
  id: number;
  statements: Statement[];
  superPolicy: boolean;
  resourceCentreId: number | null;
  name: string;
}
export interface UserGroupsType {
  id: number;
  name: string;
  resourceCentreId: number | null;
  policyId: number;
  userGroupType: string | null;
  description: string | null;
  isDepartment: boolean;
  resourceCentre: ResourceCentre; // Replace 'any' with the actual type if available
  policy: Policy;
}

export const getUserGroups = async (): Promise<Array<UserGroupsType>> => {
  const response = await Get(userGroupsRoot, true);
  return response.data as Array<UserGroupsType>;
};

export const postUserGroups = async (payload: Partial<UserGroupsType>): Promise<UserGroupsType> => {
  const response = await Post(userGroupsRoot, payload, true);
  return response.data as UserGroupsType;
};

export const patchUserGroup = async (
  department: Partial<UserGroupsType>
): Promise<UserGroupsType> => {
  const response = await Patch(`${userGroupsRoot}/${department.id}`, department, true);
  return response.data as UserGroupsType;
};

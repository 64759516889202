import { Get, Post } from "./apiHelper";
import { Claim, ClientWithBalance, EmployerResponse } from "../interfaces/ssf";

const ssfRoot = "/api/ssf";

export const getSsfClient = async (id: string): Promise<ClientWithBalance> => {
  const response = await Get(`${ssfRoot}/client/${id}`);
  return response.data as ClientWithBalance;
};

export const getSsfClaims = async (): Promise<Claim[]> => {
  const response = await Get(`${ssfRoot}/claims`);
  return response.data as Claim[];
};

export const getSsfClaimById = async (id: number): Promise<Claim> => {
  const response = await Get(`${ssfRoot}/claim/${id}`);
  return response.data as Claim;
};

export const postSsfClaim = async (data: Record<string, unknown>): Promise<ClientWithBalance> => {
  const response = await Post(`${ssfRoot}/claim`, data);
  return response.data as ClientWithBalance;
};

export const getEmployer = async (paitentUuid: string): Promise<EmployerResponse> => {
  const response = await Get(`${ssfRoot}/employer/${paitentUuid}`);
  return response.data as EmployerResponse;
};

export const getActiveClaimCode = async (clientId: number): Promise<{ claimCode: string }> => {
  const response = await Get(`${ssfRoot}/claimCode/${clientId}`);
  return response.data as { claimCode: string };
};

export const getClaimStatus = async (claimUuid: string): Promise<Claim> => {
  const response = await Get(`${ssfRoot}/claim/status/${claimUuid}`);
  return response.data as Claim;
};

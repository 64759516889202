import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography, TextField, Button, Checkbox } from "@mui/material";
import queryString from "query-string";
import produce from "immer";
import Panel from "../../../components/Panel";
import { tl, t } from "../../../components/translate";
import { getBatchLabTests } from "../../../actions/assessments";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import ClientSearch from "../../../components/ClientSearch";
import * as NotificationActions from "../../../actions/notification";
import { clientSearchSecondaryLabel } from "../../../models/Client";
import { uploadClientImage } from "../../../actions/client";
import { postMedicalTest } from "../../../actions/medicalTest";
import AddLabtests from "../../Lab/LabTestCreate/AddLabtests";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import { RootState } from "../../../store";

const LabTestCreate = ({
  navigateTo,
  loadLabTests,
  labTests,
  savedSuccessfully,
  queryClient,
  showLabIdentifier,
  restrictTestCreation,

  saveMedicalTest
}) => {
  const [tests, setTests] = React.useState([]);
  const [client, setClient] = React.useState(null);

  const [createAnother, setCreateAnother] = React.useState(false);
  const [referrer, setReferrer] = React.useState("");
  const [labIdentifier, setLabIdentifier] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (restrictTestCreation) {
      navigateTo("/medical");
    }
  });

  React.useEffect(() => {
    if (!labTests?.length) loadLabTests();
  }, []);

  React.useEffect(() => {
    if (queryClient)
      setClient({
        ...queryClient,
        profileImage: queryClient.customerDetails?.profileImageS3Url
      });
  }, [queryClient]);

  React.useEffect(() => {
    const medicalTest = labTests.find((item) => item.id === 705);
    if (medicalTest) setTests([medicalTest]);
  }, [labTests]);

  const footer = (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginRight: "32px"
      }}
    >
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={createAnother}
          data-testmation="footerCheckbox"
          onChange={() => setCreateAnother(!createAnother)}
        />
        <Typography>
          <Box>{tl("services.createNewAfterSave")}</Box>
        </Typography>
      </Box>
      <Box>
        <Button onClick={() => navigateTo("/medical")} data-testmation="cancelLabCreate">
          {tl("cancel")}
        </Button>
        <StatefulButton
          variant="contained"
          color="primary"
          disabled={!client || loading}
          onClick={async () => {
            await setLoading(true);
            const data = {
              info: {
                clientId: client.id,

                referrers: referrer,
                labIdentifier
              },
              requestedTests: tests
            };
            await saveMedicalTest(data);
            await setLoading(false);
            savedSuccessfully();
            if (createAnother) {
              setClient(null);
              navigateTo("/medical/create");
            }
          }}
          isLoading={loading}
          circularProgressProps={{ size: 16 }}
          data-testmation="createLab"
        >
          {tl("save")}
        </StatefulButton>
      </Box>
    </Box>
  );

  return (
    <Panel title="Create Medical Report" onClose={() => navigateTo("/medical")} footer={footer}>
      <Box
        p={5}
        width="100%"
        height="calc(100vh - 125px)"
        style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}
      >
        <Box width="100%" display="flex">
          <ClientSearch
            autoFocus={createAnother && !client}
            client={client}
            setClient={setClient}
            variant="outlined"
            margin="dense"
            label={tl("lab.customer")}
            placeholder={t("lab.selectCustomer")}
            secondaryText={[
              { type: clientSearchSecondaryLabel.CUSTOMER_NUMBER },
              {
                type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER,
                label: t("client.externalIdentifier.short")
              }
            ]}
            style={{ width: "260px" }}
          />
        </Box>
        <Box width="40%">
          <ReferrerCreateSelect
            onReferrerChange={(v) => {
              if (v) {
                setReferrer(v.referrer);
              }
            }}
            textFieldProps={{ style: { width: "260px" } }}
          />

          {showLabIdentifier && (
            <TextField
              margin="dense"
              variant="outlined"
              label={tl("lab.labIdentifier")}
              fullWidth
              value={labIdentifier}
              onChange={(e) => setLabIdentifier(e.target.value)}
            />
          )}
        </Box>
        <Box mt={3} width="100%">
          <Box>
            <Typography style={{ fontWeight: 600 }}>{tl("lab.testParticulars")}</Typography>
          </Box>

          <AddLabtests
            isMedicalTest
            labTests={labTests}
            handleModifyLabtests={(v: any[]) => {
              setTests(v);
            }}
            handleRemoveLabtest={(i) => {
              const mutatedTests = produce(tests, (draft) => {
                draft.splice(i, 1);
              });
              setTests(mutatedTests);
            }}
            tests={tests}
            allowRemovingTest
          />
        </Box>
      </Box>
    </Panel>
  );
};

function mapStateToProps(state: RootState) {
  const resourceCentre =
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;

  const { labTests } = state.assessments;
  const { resourceCentreId } = state.userContext;
  const { user } = state.userContext;
  const queryClientId = queryString.parse(window.location.search)?.cId;
  const queryClient = state.clients.collection.find(
    (client) => client.id === Number(queryClientId)
  );
  const showLabIdentifier = resourceCentre?.labSettings?.enableLabIdentifier;
  const restrictTestCreation = resourceCentre?.labSettings?.enableTestCreationFromBillOnly;
  const allowClientPictureUpload = resourceCentre?.settings?.clientSettings?.enablePictureUpload;

  return {
    user,
    labTests,
    resourceCentreId,
    queryClient,
    showLabIdentifier,
    restrictTestCreation,
    allowClientPictureUpload
  };
}

export default connect(mapStateToProps, (dispatch) => ({
  uploadPhoto: (id, image, next) => dispatch(uploadClientImage(id, image, next)),
  navigateTo: (url) => {
    dispatch(push(url));
  },
  loadLabTests: () => dispatch(getBatchLabTests()),
  saveMedicalTest: (data) => dispatch(postMedicalTest(data)),

  savedSuccessfully: () => {
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "success",
        message: tl("labTest.successSaving"),
        timeout: 1000
      })
    );
  }
}))(LabTestCreate);

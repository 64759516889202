import { SupplierLedgerInterface } from "../containers/Stock/SuppplierInterface";
import { StockTransactions, Supplier } from "../interfaces/StockInterfaces";
import { SupplierLedger } from "../interfaces/SupplierLedger";
import { Get, Post } from "./apiHelper";

const suppliersRootUrl = "/api/suppliers";

export const getSupplierBalance = async (id: number): Promise<SupplierLedgerInterface> => {
  const response = await Get(`${suppliersRootUrl}/${id}/balance`, true);
  return response.data as Promise<SupplierLedgerInterface>;
};

export const getSupplierTransactionsApi = async (id: number): Promise<Array<StockTransactions>> => {
  const response = await Get(`${suppliersRootUrl}/${id}/transactions`, true);
  return response.data as Array<StockTransactions>;
};

export const getSupplierLedgerApi = async (id: number): Promise<Array<SupplierLedger>> => {
  const response = await Get(`${suppliersRootUrl}/${id}/ledger`, true);
  return response.data as Array<SupplierLedger>;
};

export const batchUploadSupplier = async (data: Array<Partial<Supplier>>): Promise<string> => {
  const response = await Post(`${suppliersRootUrl}/batch`, { suppliers: data }, true);
  return response.data as string;
};
export default getSupplierBalance;

import * as React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import startCase from "lodash/startCase";
import { tl } from "../../components/translate";
import { RootState } from "../../store";

interface ServiceCategoryAutoSelectProps {
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
}

export const SeriveCategoryAutoSelect = ({
  value,
  disabled = false,
  onChange
}: ServiceCategoryAutoSelectProps): JSX.Element => {
  const resourceCentre = useSelector((state: RootState) =>
    state?.resources?.resourceCentres.find(
      (rc) => rc?.id === state?.userContext?.resourceCentreId || state?.userContext?.resourceCentre
    )
  );

  const serviceCategoryOptions =
    resourceCentre && resourceCentre?.settings?.serviceSettings.serviceTypes;

  return (
    <Autocomplete
      id="service-select"
      options={serviceCategoryOptions}
      getOptionLabel={(option) => startCase(option)}
      value={value}
      disabled={disabled}
      onChange={(e, val) => onChange(val)}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="standard"
          label={tl("reports.serviceCategory")}
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

export default SeriveCategoryAutoSelect;

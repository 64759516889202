import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getDepartmentSps } from "../slices/departmentServiceProviderSlice";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";

const useDepartmentServiceProviders = (): Array<ServiceProvider> => {
  const dispatch = useDispatch();
  const serviceProviderList = useSelector((state: RootState) =>
    state.departmentServiceProviders.filter(({ active }) => active)
  ).sort((a, b) =>
    `${a.firstName || ""}${a.lastName || ""}`.toLowerCase() >
    `${b.firstName || ""}${b.lastName || ""}`.toLowerCase()
      ? 1
      : -1
  );

  React.useEffect(() => {
    dispatch(getDepartmentSps());
  }, [dispatch]);

  return serviceProviderList;
};

export default useDepartmentServiceProviders;

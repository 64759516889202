import React from "react";
import { equalTo, get, limitToFirst, orderByChild, query, ref, startAt } from "firebase/database";
import { Box, Typography } from "@mui/material";
import { match as MatchProps } from "react-router";
import moment from "moment-timezone";
import "./chat.scss";
import List, { MultipleHeader } from "../../components/List";
import { publicHeadersInfo } from "./VerificationCodes";
import { db } from "../../../firebase/config";
import { ChatProfileResponse, getUserAndRcProfiles } from "../../api/user";
import { Chat } from "../../hooks/useGetFirebaseChat";
import PageControl from "../../components/PageControl";
import ChatSearch from "./ChatSearch";
import MessageThreadPreview from "./MessageThreadPreview";

export interface ChatListData {
  chatItems: Record<
    string,
    {
      from: number;
      message: string;
      read: boolean;
      timestamp: number;
    }
  >;
  lastMsgTimestamp: number;
  resourceCentreId: number;
  userId: number;
  key: string;
  replyForFirstMessageByRc?: {
    from: number;
    isSystemMessage: boolean;
    message: string;
    read: boolean;
    timestamp: number;
  };
  chatClosedBy?: number;
  firstMessage: {
    from: number;
    message: string;
    read: boolean;
    timestamp: number;
  };
}

export interface ProcessedChatData {
  id: string;
  key: string;
  rcName: string;
  userName: string;
  phone: string;
  firstMessage: string;
  firstMessageDate: string;
  status: string;
  userId: number;
  resourceCentreId: number;
  replyForFirstMessageByRcDate: string;
}

export interface SearchType {
  by: SEARCH_BY;
  queryId: number | null;
}

export enum SEARCH_BY {
  RESOURCECENTRE_ID = "resourceCentreId",
  USER_ID = "userId"
}

const pageSize = 25;
export default function ChatStats({ match }: { match: MatchProps }): JSX.Element {
  const [chatList, setChatList] = React.useState<Array<ChatListData>>([]);
  const [selectedChat, setSelectedChat] = React.useState<ChatListData | null>(null);
  const [userAndRc, setUserAndRc] = React.useState<ChatProfileResponse>({
    users: {},
    resourceCentres: {}
  });
  const [page, setPage] = React.useState<number>(0);
  const [search, setSearch] = React.useState<SearchType>({
    by: SEARCH_BY.RESOURCECENTRE_ID,
    queryId: null
  });

  React.useEffect(() => {
    let filters = [
      orderByChild("resourceCentreId"),
      startAt(page * pageSize),
      limitToFirst(pageSize)
    ];
    if (search.queryId) {
      filters = [orderByChild(search.by), equalTo(search.queryId)];
    }
    const chatListQuery = query(ref(db, "chats"), ...filters);
    get(chatListQuery).then((snapshot) => {
      const chatData = [] as ChatListData[];
      snapshot.forEach((snap) => {
        const chat = snap.val() as Chat;
        const sortedChatItems = Object.values(chat.chatItems).sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        const firstMessage = sortedChatItems[0];
        const replyForFirstMessageByRc = sortedChatItems.find(
          (item) => item.from === chat.resourceCentreId
        );
        chatData.push({
          ...chat,
          key: snap.key,
          firstMessage,
          replyForFirstMessageByRc
        });
      });
      setChatList(chatData);
    });
  }, [page, search]);

  React.useEffect(() => {
    (async () => {
      if (chatList.length) {
        const payload = chatList.reduce(
          (acc, curr) => {
            acc.userIds.push(curr.userId);
            acc.rcIds.push(curr.resourceCentreId);
            return acc;
          },
          { userIds: [], rcIds: [] }
        );
        const userAndRcData = await getUserAndRcProfiles(payload.userIds, payload.rcIds);
        setUserAndRc(userAndRcData);
      }
    })();
  }, [chatList]);

  const processedData = chatList.map((item) => ({
    id: item.key,
    key: item.key,
    rcName: userAndRc.resourceCentres[item.resourceCentreId]?.name || "",
    userName: userAndRc.users[item.userId]?.name || "",
    phone: userAndRc.users[item.userId]?.phone || "",
    firstMessage: item.firstMessage?.message || "",
    firstMessageDate: moment(new Date(item.firstMessage?.timestamp)).format("YYYY-MM-DD"),
    status: item.chatClosedBy ? "Closed" : "Open",
    userId: item.userId,
    resourceCentreId: item.resourceCentreId,
    replyForFirstMessageByRcDate: item.replyForFirstMessageByRc?.timestamp
      ? moment(new Date(item.replyForFirstMessageByRc.timestamp)).format("YYYY-MM-DD")
      : "Not replied"
  }));

  return (
    <Box p={2} height="calc(100% - 10px)" className="chatList">
      <MultipleHeader multipleHeaders={publicHeadersInfo(match)} />
      <List<ProcessedChatData>
        data={processedData}
        automation="chatList"
        withoutSearch
        hideCreateButton
        additionalHeaderFilters={<ChatSearch onSearch={setSearch} />}
        onRowClick={(rowData) => {
          setSelectedChat(rowData);
        }}
        columns={[
          {
            key: "rcName",
            label: "Resource Centre Name",
            sortable: true,
            formatter: ({ rcName }) => <Typography>{rcName}</Typography>
          },
          {
            key: "userName",
            label: "Mobile User",
            sortable: true,
            formatter: ({ userName }) => <Typography>{userName}</Typography>
          },
          {
            key: "phone",
            label: "Phone",
            sortable: true,
            formatter: ({ phone }) => <Typography>{phone}</Typography>
          },
          {
            key: "firstMessage",
            label: "First Message",
            sortable: true,
            formatter: ({ firstMessage }) => <Typography>{firstMessage}</Typography>
          },
          {
            key: "firstMessageDate",
            label: "First Message Sent Date",
            sortable: true,
            formatter: ({ firstMessageDate }) => <Typography>{firstMessageDate}</Typography>
          },
          {
            key: "replyForFirstMessageByRcDate",
            label: "Reply By Rc",
            sortable: true,
            formatter: ({ replyForFirstMessageByRcDate }) => (
              <Typography>{replyForFirstMessageByRcDate}</Typography>
            )
          },
          {
            sortable: true,
            key: "status",
            label: "Status",
            formatter: ({ status }) => <Typography>{status}</Typography>
          }
        ]}
      />
      <Box className="navigatePage">
        <PageControl
          page={page}
          pageSize={pageSize}
          onSetPage={(v) => {
            setPage(v);
          }}
          maximumDataCount={search.queryId ? 0 : 1000}
        />
      </Box>
      {selectedChat && (
        <MessageThreadPreview onClose={() => setSelectedChat(null)} data={selectedChat} />
      )}
    </Box>
  );
}

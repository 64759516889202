import React from "react";

export const Patch = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M371.2,308.364c-4.655-4.655-11.636-4.655-16.291,0L205.964,457.309c-41.891,41.891-109.382,41.891-151.273,0
			c-41.891-41.891-41.891-109.382,0-151.273l53.527-53.527l128,128c4.655,4.655,11.636,4.655,16.291,0
			c4.655-4.655,4.655-11.636,0-16.291L116.364,228.073c-4.655-4.655-11.636-4.655-16.291,0L38.4,289.745
			c-51.2,51.2-51.2,133.818,0,183.855C64,499.2,96.582,512,130.327,512c33.745,0,66.327-12.8,91.927-38.4L371.2,324.655
			C375.855,320,375.855,313.018,371.2,308.364z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx="232.727" cy="232.727" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="279.273" cy="279.273" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="290.909" cy="221.091" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="244.364" cy="174.545" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="337.455" cy="267.636" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="174.545" cy="244.364" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="267.636" cy="337.455" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="221.091" cy="290.909" r="11.636" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M473.6,38.4c-51.2-51.2-133.818-51.2-183.855,0L140.8,187.345c-4.655,4.655-4.655,11.636,0,16.291s11.636,4.655,16.291,0
			L306.036,54.691c41.891-41.891,109.382-41.891,151.273,0c41.891,41.891,41.891,109.382,0,151.273l-53.527,53.527l-128-128
			c-4.655-4.655-11.636-4.655-16.291,0c-4.655,4.655-4.655,11.636,0,16.291l136.145,136.145c2.327,2.327,5.818,3.491,8.146,3.491
			c3.491,0,5.818-1.164,8.145-3.491l61.673-61.673C524.8,171.055,524.8,88.436,473.6,38.4z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

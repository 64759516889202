import { Box, Button } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tl } from "../../../components/translate";
import { LabRecord, LabStatuses } from "../../../interfaces/Lab";

interface ShowTestResultProps {
  medicalTest: LabRecord;
  navigateTo: (v) => void;
  id: number;
}

const ShowTestResult: React.FC<ShowTestResultProps> = ({
  medicalTest,
  navigateTo,
  id
}): JSX.Element => {
  const testResult = medicalTest.results.data[0];

  const getItemReadings = (item) => {
    if (item.formData?.reading) return item.formData?.reading;
    if (item.info?.defaultValue) return item.info?.defaultValue;
    return " ";
  };

  const getItemRanges = (item) => {
    if (item.ranges) {
      if (item.ranges.male) return `${item.ranges.male.min}-${item.ranges.male.max}`;
      if (item.ranges.default) return `${item.ranges.default.min}-${item.ranges.default.max}`;
      return " ";
    }
    return " ";
  };

  return (
    <Box fontWeight="600">
      {medicalTest.status === LabStatuses.INCOMPLETE_RESULT && (
        <Box display="flex" justifyContent="flex-end" mb={3}>
          <Button
            data-testmation="updateTestResult"
            onClick={() => navigateTo(`/medical/entry/${id}`)}
          >
            {tl("lab.updateTestResults")}
          </Button>
        </Box>
      )}
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="none">Test</TableCell>
              <TableCell padding="none" align="right">
                Reading
              </TableCell>
              <TableCell padding="none" align="right">
                Unit
              </TableCell>
              <TableCell padding="none" align="right">
                Reference Range
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testResult.labTests.map((item) => (
              <>
                <TableRow key={item.name}>
                  <TableCell component="th" scope="row" padding="none">
                    {item.name}
                  </TableCell>
                  <TableCell align="right" padding="none">
                    {getItemReadings(item)}
                  </TableCell>
                  <TableCell align="right" padding="none">
                    {item.unit ? item.unit : ""}
                  </TableCell>
                  <TableCell align="right" padding="none">
                    {getItemRanges(item)}
                  </TableCell>
                </TableRow>

                {item.subTests.map((subItem) => (
                  <TableRow key={subItem.name}>
                    <TableCell component="th" scope="row" padding="none">
                      {subItem.name}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {getItemReadings(subItem)}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {subItem.unit ? subItem.unit : ""}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {getItemRanges(subItem)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShowTestResult;

import "./styles.scss";
import { Box, TextField } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Test } from "../../../interfaces/Lab";
import { setTestGroupDraft } from "../../../slices/labTestSettingsSlice";
import { RootState } from "../../../store";
import LabTestTypeSelector from "../../ResourceCentre/ModuleSettings/LabTestTypeSelector";
import LabTestCategorySelector from "../../ResourceCentre/ModuleSettings/LabTestCategorySelector";

export const filterTestsByParentId = (selectedLabTest: Test, labTests: Test[]): Test[] =>
  labTests.filter((labTest) => labTest.parentId === selectedLabTest.id);

export const addSubTest = (selectedLabTests: Test[], labTests: Test[]): Test[] =>
  selectedLabTests.map((selectedLabTest) => ({
    ...selectedLabTest,
    subTests: filterTestsByParentId(selectedLabTest, labTests)
  }));

const TestGroupNameAndCategoryEdit = (): JSX.Element => {
  const draftTestGroup = useSelector((state: RootState) => state.labSettings.draftGroup);

  const [draftState, setDraftState] = useState({
    name: draftTestGroup.name
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    setDraftState({ name: draftTestGroup.name });
  }, [draftTestGroup]);

  return (
    <Box display="flex" gap={1} flexDirection="column">
      <TextField
        label="Lab test group name"
        margin="dense"
        value={draftState.name}
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
        onBlur={() => {
          dispatch(
            setTestGroupDraft({
              ...draftTestGroup,
              name: draftState.name
            })
          );
        }}
        onChange={(event) => {
          setDraftState({ ...draftState, name: event.target.value });
        }}
      />
      <Box mt={1}>
        <LabTestCategorySelector
          value={draftTestGroup.category}
          onChange={(value) =>
            dispatch(
              setTestGroupDraft({
                ...draftTestGroup,
                category: value
              })
            )
          }
        />
      </Box>

      {!draftTestGroup?.id && (
        <LabTestTypeSelector
          value={draftTestGroup.type}
          onChange={(value) => {
            dispatch(
              setTestGroupDraft({
                ...draftTestGroup,
                type: value
              })
            );
          }}
        />
      )}
    </Box>
  );
};

export default TestGroupNameAndCategoryEdit;

import React from "react";
import { useSelector } from "react-redux";
import startCase from "lodash/startCase";
import { Autocomplete, TextField } from "@mui/material";
import { RootState } from "../../store";

interface StockCategoriesAutoSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const StockCategoriesSelect = ({
  value,
  onChange
}: StockCategoriesAutoSelectProps): JSX.Element => {
  const resourceCentre = useSelector((state: RootState) =>
    state?.resources?.resourceCentres.find(
      (rc) => rc?.id === state?.userContext?.resourceCentreId || state?.userContext?.resourceCentre
    )
  );

  const stockCategoryOptions = resourceCentre
    ? [
        ...(resourceCentre?.settings?.stockSettings?.productCategory?.internal || []),
        ...(resourceCentre?.settings?.stockSettings?.productCategory?.sellable || [])
      ]
    : [];

  return (
    <Autocomplete
      id="tags-standard"
      options={stockCategoryOptions}
      getOptionLabel={(option) => startCase(option)}
      value={value}
      onChange={(e, v) => {
        onChange(v || []);
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          variant="standard"
          label="Filter by category"
          placeholder="Products.."
        />
      )}
    />
  );
};

export default StockCategoriesSelect;

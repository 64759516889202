import { Autocomplete, Box, createFilterOptions, TextField } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import useAssociateCompanies from "../../hooks/useAssociateCompanies";
import { AssociateCompany } from "../../api/associateCompanies";
import CompanyCreateEdit from "../../containers/AsscoiateCompany/CompanyCreateEdit";
import { tl, t } from "../translate";

interface Props {
  initialValue: AssociateCompany | null;
  onChange: (value: AssociateCompany | null) => void;
  width?: string;
}

type AssociateCompanyNewType = AssociateCompany & { inputValue?: string };

const filter = createFilterOptions<AssociateCompanyNewType>();

const AssociateCompanyCreateSelect = ({ width, initialValue, onChange }: Props): JSX.Element => {
  const [value, setValue] = useState<AssociateCompanyNewType | null>(null);
  const [openCreatePanel, toggleOpenCreatePanel] = React.useState(false);
  const [companyNameToCreate, setCompanyNameToCreate] = useState("");

  const companies = useAssociateCompanies();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Box>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            toggleOpenCreatePanel(true);
          } else if (newValue && newValue.inputValue) {
            setCompanyNameToCreate(newValue.inputValue);
            toggleOpenCreatePanel(true);
          } else {
            setValue(newValue);
            onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered: Partial<AssociateCompanyNewType>[] = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`
            });
          }

          return filtered;
        }}
        options={companies}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        autoHighlight
        clearOnBlur
        handleHomeEndKeys
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputLabelProps={{ shrink: true }}
            label={tl("CreateClient.AssociateCompany")}
            placeholder={t("CreateClient.AssociateCompany")}
            variant="outlined"
            sx={{ width: width || "100%" }}
          />
        )}
      />
      {openCreatePanel && (
        <CompanyCreateEdit
          createMode
          defaultName={companyNameToCreate}
          onClose={() => {
            toggleOpenCreatePanel(false);
          }}
          onSuccess={(company) => {
            onChange(company);
            toggleOpenCreatePanel(false);
            setValue(company);
          }}
          selectedCompany={null}
        />
      )}
    </Box>
  );
};

export default AssociateCompanyCreateSelect;

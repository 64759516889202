import React from "react";
import { Box, Checkbox, Divider, Typography, Button, FormControlLabel } from "@mui/material";
import produce from "immer";
import { round } from "mathjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getBillsForSPCommission,
  saveServiceProviderCommission,
  updateSpBillOnSelect,
  updateSpCommissionItem
} from "../../../../slices/commission";
import {
  VendorCommission as ReferrerCommissionProps,
  VendorCommissionBill
} from "../../../../interfaces/Commission";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { findSpPayableAmt, formatData } from "./helper";
import { RootState } from "../../../../store";
import { EmptyView } from "../../../../components/OList";
import ServiceProviderCommissionRow from "./ServiceProviderCommissionRow";
import { BillDocumentType } from "../../../../interfaces/BillInterfaces";
import PageControl from "../../../../components/PageControl/index";

const showSubItem = (isCommissionPaid, isSaved) => {
  if (isSaved || isCommissionPaid) return false;
  return true;
};

interface ServiceProviderCommission {
  selectedBill?: BillDocumentType;
}

export default function ServiceProviderCommission({
  selectedBill
}: ServiceProviderCommission): JSX.Element {
  const dispatch = useDispatch();
  const spCommissionItems = useSelector((state: RootState) => state.commissions.serviceProviders);
  const totalBill = useSelector((state: RootState) => state.commissions.totalNumber);
  const [checkAllStatus, setCheckAllStatus] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState({ page: 0, size: 5 });

  React.useEffect(() => {
    (async () => {
      dispatch(getBillsForSPCommission({ id: selectedBill?.id, filters }));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters]);

  const billSelectHandler = ({ target }, sNo?: number) => {
    if (!sNo) setCheckAllStatus(target.checked);
    dispatch(updateSpBillOnSelect({ checked: target.checked, sNo }));
  };

  return (
    <div>
      {spCommissionItems.length > 0 ? (
        <>
          <Box
            sx={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
            my="8px"
            p="10px"
            border="2px solid lightgreen"
            borderRadius="8px"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checkAllStatus}
                onChange={(e) => billSelectHandler(e)}
                size="small"
                color="primary"
                data-testmation="selectBills"
              />
              <Typography>Select all Bills</Typography>
            </div>
            {spCommissionItems.map((commissionItem: VendorCommissionBill, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`${commissionItem.billId}-${index}`} display="flex" width="100%">
                <div style={{ marginTop: "20px" }}>
                  <Checkbox
                    onChange={(e) => billSelectHandler(e, commissionItem.sNo)}
                    size="small"
                    color="primary"
                    checked={commissionItem.isSelected}
                    disabled={commissionItem.isSaved || commissionItem.isServiceProviderPaid}
                  />
                </div>
                <Box
                  bgcolor={`${
                    commissionItem.isSaved || commissionItem.isServiceProviderPaid ? "#e0e0e0" : ""
                  }`}
                  p="10px"
                  my="8px"
                  border="2px solid lightgreen"
                  borderRadius="8px"
                  width="100%"
                >
                  <>
                    <Box
                      borderBottom={`${
                        showSubItem(commissionItem.isServiceProviderPaid, commissionItem.isSaved)
                          ? "0.5px solid #c1bdbd"
                          : ""
                      }`}
                      justifyContent="space-between"
                      display="flex"
                      mt="8px"
                      pb="20px"
                    >
                      <Box display="flex" alignItems="centre">
                        <Typography fontWeight={600}>
                          {`Bill Number: ${commissionItem.billNumber}`}
                        </Typography>
                        <Divider sx={{ height: 20, mx: "15px" }} orientation="vertical" />
                        <Typography fontWeight={600}>
                          {`Paid Amount: ${rupeeDisplay(commissionItem.paidAmount)}`}
                        </Typography>
                        <Divider sx={{ height: 20, mx: "15px" }} orientation="vertical" />
                        {(commissionItem.isSaved || commissionItem.isServiceProviderPaid) && (
                          <Typography fontWeight={600}>
                            <FormControlLabel
                              sx={{ margin: "0" }}
                              control={<Checkbox size="small" sx={{ padding: "0" }} checked />}
                              label="Saved:"
                              labelPlacement="start"
                            />
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    {[...commissionItem.billItems]
                      .sort((a, b) => a.internalSNo - b.internalSNo)
                      ?.map((subItem, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={i} mt={`${i === 0 || subItem.isSubItem ? "8px" : "15px"}`}>
                          <ServiceProviderCommissionRow
                            isSaved={commissionItem.isSaved || commissionItem.isServiceProviderPaid}
                            index={i}
                            onChange={(updatedItem: ReferrerCommissionProps, rateAmt?: string) => {
                              const calculatedState = findSpPayableAmt(updatedItem, rateAmt);
                              const itemIndex = commissionItem.billItems.findIndex(
                                (item) => item.internalSNo === updatedItem.internalSNo
                              );
                              const updatedBill = produce(commissionItem, (draft) => {
                                draft.billItems[itemIndex] = calculatedState;
                              });
                              dispatch(updateSpCommissionItem(updatedBill));
                            }}
                            item={subItem}
                          />
                          <Box
                            display="flex"
                            flexDirection="row-reverse"
                            mr="10px"
                            data-testmation="amountPayable"
                          >
                            <Typography fontWeight={500}>
                              {`Amount Payable to Service Provider: ${rupeeDisplay(
                                round(subItem.netPayableToVendor, 2)
                              )}`}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                  </>
                </Box>
              </Box>
            ))}
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <PageControl
              page={filters.page}
              onSetPage={(val) => {
                setCheckAllStatus(false);
                setFilters({ ...filters, page: val });
              }}
              pageSize={filters.size}
              maximumDataCount={totalBill}
            />
          </Box>
          <Box flexDirection="row-reverse" display="flex">
            <Button
              data-testmation="saveCommission"
              disabled={!spCommissionItems.some((item) => item.isSelected)}
              variant="contained"
              onClick={() => {
                const formattedData = formatData(spCommissionItems);
                if (formattedData.length) {
                  dispatch(saveServiceProviderCommission(formattedData));
                }
              }}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No Commissionable Items were found !
          </Box>
        </EmptyView>
      )}
    </div>
  );
}

ServiceProviderCommission.defaultProps = {
  selectedBill: {} as BillDocumentType
};

import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { RequestFormResponse, getIpdRequestForms } from "../../../api/requestForms";
import List from "../../../components/List";
import { getFullName } from "../../../helpers/formatters";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { RequestType } from "./LabRequestForm";
import LabRequestDetails from "./LabRequestDetails";
import { notificationAdd } from "../../../actions/notification";
import PageControl from "../../../components/PageControl";

const PAGE_SIZE = 15;
const LabRequestList = (): JSX.Element => {
  const [labRequestList, setLabRequestList] = React.useState<RequestFormResponse>({
    results: [],
    total: 0
  });
  const [labRequestDetails, setLabRequestDetails] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      try {
        const requestForms = await getIpdRequestForms({
          page,
          pageSize: PAGE_SIZE
        });
        setLabRequestList(requestForms);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get request forms",
            autoTimeout: true
          })
        );
      }
    })();
  }, [page]);

  return (
    <Box pt={3} height="100%" minWidth={900}>
      <List
        withoutSearch
        data={labRequestList.results}
        rowHeight={50}
        automation="RequestForms"
        hideCreateButton
        onRowClick={(row) => setLabRequestDetails(row)}
        columns={[
          {
            key: "ipdNumber",
            label: "Ipd Number",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.ipd?.ipdNumber}</Box>
              </Typography>
            )
          },
          {
            key: "requestedDate",
            label: "Requested Date",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{convertADtoBS(new Date(row.created_at)).formatted4}</Box>
              </Typography>
            ),
            sortable: true
          },
          {
            key: "requestedItemsType",
            label: "Requested Item Type",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>
                  {row.requestedItemsType === RequestType.SERVICE ? "Lab service" : "Products"}
                </Box>
              </Typography>
            )
          },
          {
            key: "clientName",
            label: "Client",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{getFullName(row.client)}</Box>
              </Typography>
            )
          },
          {
            key: "requestedItems",
            label: "Requested Items",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.requestedItems.map((item) => item.name || item.brand).join(", ")}</Box>
              </Typography>
            )
          },
          {
            key: "status",
            label: "Status",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.status}</Box>
              </Typography>
            )
          }
        ]}
      />
      <Box className="navigatePage">
        <PageControl
          page={page}
          pageSize={PAGE_SIZE}
          onSetPage={(v) => {
            setPage(v);
          }}
          maximumDataCount={labRequestList.total || 0}
        />
      </Box>
      {labRequestDetails && (
        <LabRequestDetails
          onStatusChange={(value) => {
            const updatedList = labRequestList.results.map((item) =>
              item.id === value.id ? value : item
            );
            setLabRequestList({ ...labRequestList, results: updatedList });
            setLabRequestDetails(value);
          }}
          onClose={() => setLabRequestDetails(null)}
          labRequest={labRequestDetails}
        />
      )}
    </Box>
  );
};

export default LabRequestList;

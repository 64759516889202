import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const ProgressReports = [
  "Cured",
  "Recovered",
  "Unchanged",
  "Worsened",
  "Left Against Medical Advice",
  "Expired",
  "Brought Dead",
  "Absconded"
];

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ProgressReportSelect = ({ value, onChange }: Props): JSX.Element => {
  const [progressReport, setProgressReport] = useState(value);
  return (
    <Autocomplete
      freeSolo
      options={ProgressReports}
      getOptionLabel={(option) => option}
      value={value}
      onChange={(_, v) => {
        setProgressReport(v || "");
      }}
      onInputChange={(_, v) => {
        setProgressReport(v);
      }}
      onBlur={() => onChange(progressReport)}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          margin="dense"
          label="Progress Report"
          placeholder="Select or type progress"
          fullWidth
        />
      )}
    />
  );
};

export default ProgressReportSelect;

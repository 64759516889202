import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { CalendarFunctions } from "../components/Calendar";

const useNextMulDartaNumber = (): string => {
  const [nextMulDartaNumber, setNextMulDartaNumber] = useState<string>("");
  const { results: data } = useAppSelector((state) => state.hmis.mulDarta || {});

  const getFiscalYear = (date) => {
    const bsDate = CalendarFunctions.convertADtoBS(date);
    return bsDate.bsMonth <= 3
      ? `${bsDate.bsYear - 1}/${bsDate.bsYear}`
      : `${bsDate.bsYear}/${bsDate.bsYear + 1}`;
  };

  const getNextMulDartaNo = () => {
    if (!data.length) {
      return 1;
    }

    // filter data for current fiscal year
    const currentFiscalYear = getFiscalYear(new Date());
    const currentFiscalYearData = data.filter((obj) => obj.fiscalYear === currentFiscalYear);

    if (!currentFiscalYearData.length) {
      return 1;
    }

    const highestNumber = Math.max(
      ...currentFiscalYearData.map((obj) => parseInt(obj.mulDartaNumber, 10))
    );

    return highestNumber + 1;
  };

  useEffect(() => {
    const mulDartaNo = getNextMulDartaNo().toString();
    setNextMulDartaNumber(mulDartaNo);
  }, [data]);

  return nextMulDartaNumber;
};

export default useNextMulDartaNumber;

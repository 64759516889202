import * as React from 'react';
import './style.scss';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CalendarDropdown from '../../components/CalendarDropdown/CalendarDropdown';
import without from 'lodash/without';
import * as moment from 'moment';
import { spFullNameSelector } from '../../reducers/serviceProvider';
import { tl } from '../../components/translate';

const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface SlotsProps {
  serviceProvider: any;
  onChange: (data: SlotsState) => void;
}

export interface SlotsState {
  slotDuration: number;
  resourceCentre: any;
  resourceCentreServiceProvider: any;
  fromDate: Date;
  toDate: Date;
  fromTime: string;
  toTime: string;
  capacity: number;
  every: Array<number>;
}

const className = 'slots';
export default class Slots extends React.Component<SlotsProps, SlotsState> {
  constructor(props) {
    super(props);
    const weekLater = moment()
      .add('week', 1)
      .toDate();
    this.state = {
      slotDuration: 10,
      resourceCentre: this.props.resourceCentre,
      resourceCentreServiceProvider: this.props.resourceCentreServiceProvider,
      fromDate: new Date(),
      toDate: weekLater,
      fromTime: '07:00',
      toTime: '11:30',
      capacity: 1,
      every: [],
    };
  }

  componentDidMount() {
    this.propagateData();
  }

  toggleCheckDay(i) {
    this.setState(
      {
        every: this.isChecked(i) ? without(this.state.every, i) : this.state.every.concat(i),
      },
      () => {
        this.propagateData();
      },
    );
  }

  isChecked(i) {
    return this.state.every.includes(i);
  }

  isEveryDayChecked() {
    return this.state.every.length == 7;
  }

  propagateData() {
    this.props.onChange(Object.assign({}, this.state));
  }

  onChange(propName, e) {
    this.setState(
      {
        [propName]: e.target.value,
      },
      () => {
        this.propagateData();
      },
    );
  }

  render() {
    return (
      <div className={`${className}`}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Typography variant="h5">{spFullNameSelector(this.props.serviceProvider)}</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl className={`${className}__formcontrol`}>
                <CalendarDropdown
                  date={this.state.fromDate}
                  label={tl('booking.fromDate')}
                  placeholder=""
                  onChange={(d) => this.onChange('fromDate', { target: { value: d } })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl className={`${className}__formcontrol`}>
                <CalendarDropdown
                  date={this.state.toDate}
                  label={tl('booking.toDate')}
                  placeholder=""
                  onChange={(d) => this.onChange('toDate', { target: { value: d } })}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl className={`${className}__formcontrol`}>
                <TextField
                  id="time"
                  label={tl('booking.fromTime')}
                  type="time"
                  defaultValue={this.state.fromTime}
                  onChange={(e) => this.onChange('fromTime', e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 600, // 10 min
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl className={`${className}__formcontrol`}>
                <TextField
                  id="time"
                  label={tl('booking.toTime')}
                  type="time"
                  defaultValue={this.state.toTime}
                  onChange={(e) => this.onChange('toTime', e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 600, // 10 min
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl className={`${className}__formcontrol`}>
                <InputLabel htmlFor="to-date-time">{tl('booking.slotDuration')}</InputLabel>
                <Select
                  value={this.state.slotDuration}
                  onChange={(val) => this.onChange('slotDuration', val)}
                  inputProps={{
                    name: 'slot-duration',
                    id: 'slot-duration',
                  }}
                >
                  <MenuItem value={10}>10 minutes </MenuItem>
                  <MenuItem value={5}>5 minutes</MenuItem>
                  <MenuItem value={15}>15 minutes</MenuItem>
                  <MenuItem value={30}>30 minutes</MenuItem>
                  <MenuItem value={60}>60 minutes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                className={`${className}__formcontrol`}
                id="slot-Capacity"
                label={tl('booking.slotCapacity')}
                value="1 Person"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Typography>
                <Box component="div" fontWeight={400} fontSize={'0.75rem'} paddingTop={'8px'}>
                  {tl('booking.repeat')}
                </Box>
              </Typography>
              {WEEK_DAYS.map((d, i) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={i}
                        value={d}
                        onChange={() => this.toggleCheckDay(i)}
                        checked={this.isChecked(i)}
                      />
                    }
                    label={tl(d)}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

import * as React from "react";
import { Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import List from "../../components/OList";
import { tl } from "../../components/translate";
import { rupeeDisplay } from "../../helpers/rupee";
import "./BillList.scss";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { RootState } from "../../store";
import { BillType } from "../../interfaces/BillInterfaces";
import { showInfoColumn } from "../../actions/helpers/billHelper";

const Item = ({ children, width = "initial" }) => (
  <Typography>
    <Box component="span" width={width} fontWeight={500} fontSize="11px">
      {children}
    </Box>
  </Typography>
);

const processBillItems = (billItems) =>
  billItems.reduce((acc, item) => {
    acc.push(item);
    if (item.subItems) {
      item.subItems.forEach((subItem) => {
        acc.push({
          ...subItem,
          description: ` - - -${subItem.description}`
        });
      });
    }
    return acc;
  }, []);

const BillItems = ({ bill }: { bill: BillType }): JSX.Element => {
  const billItemsProcessed = React.useMemo(
    () => processBillItems(bill.document.billItems),
    [bill.document.billItems]
  );
  const serviceProviders = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );

  const getServiceProviderNameFromId = (serviceProviderId) => {
    const sp = serviceProviders.find((item) => item.id === serviceProviderId);
    return spFullNameSelector(sp);
  };
  const showInfo = showInfoColumn(bill);

  return (
    <>
      <List
        automation="BillItems"
        rowHeight={30}
        columns={[
          {
            key: "description",
            label: tl("billItem.description"),
            formatter: ({ description, serviceProviderId }) => (
              <Item>
                <Box>
                  {description}{" "}
                  <span style={{ fontWeight: "550" }}>
                    {serviceProviderId && `(${getServiceProviderNameFromId(serviceProviderId)})`}
                  </span>
                </Box>
              </Item>
            )
          },
          ...(showInfo
            ? [
                {
                  key: "info",
                  label: tl("billItem.info"),
                  formatter: ({ info }) => (
                    <Item width="200px">
                      <Box>{info || ""}</Box>
                    </Item>
                  )
                }
              ]
            : []),
          {
            key: "quantity",
            label: tl("billItem.quantity"),
            formatter: ({ quantity, unit }) => (
              <Item>
                {quantity} {unit}
              </Item>
            )
          },
          {
            key: "perUnit",
            label: tl("billItem.perUnit"),
            formatter: ({ perUnit }) => <Item>{rupeeDisplay(perUnit)}</Item>
          },
          {
            key: "total",
            label: tl("billItem.grossTotal"),
            formatter: ({ grossTotal }) => <Item>{rupeeDisplay(grossTotal)}</Item>
          }
        ]}
        data={billItemsProcessed}
        activeRow={null}
        adjustHeightToContents
      />
    </>
  );
};

export default BillItems;

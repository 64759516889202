import React from "react";
import Typography from "@mui/material/Typography";

export default function SectionHeading({ name }: { name: string }): JSX.Element {
  return (
    <Typography variant="h5" fontWeight={600} mb={2}>
      {name}
    </Typography>
  );
}

import { push } from "connected-react-router";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/client";
import * as billsApi from "../api/bill";
import { updateClientName, updateClientImage, updateLabTestOnClientEdit } from "./labTest";
import { updateClientImageMedical } from "./medicalTest";
import * as NotificationActions from "./notification";
import { IThunkDispatch, RootState } from "../store";
import { Client } from "../interfaces/ClientInterface";
import { updateBillOnClientEdit } from "./bill";
import { commonErrorMessage } from "../helpers/messages";

export enum Type {
  CLIENTS_GET = "CLIENTS_GET",
  CLIENT_GET = "CLIENT_GET",
  CLIENT_UPDATE = "CLIENT_UPDATE",
  CLIENT_CREATE = "CLIENT_CREATE",
  GET_CLIENT_VISITS = "GET_CLIENT_VISITS",
  CLIENT_DELETE = "DELETE_CLIENT",
  GET_CLIENT_BILLS = "GET_CLIENT_BILLS",
  CLEAR_LAST_TOUCHED = "CLEAR_LAST_TOUCHED",
  UPLOAD_CLIENT_PICTURE = "UPLOAD_CLIENT_PICTURE",
  WALK_IN_CUSTOMER_GET = "WALK_IN_CUSTOMER_GET"
}

export const uploadClientImage =
  (id: number, pictureData: Blob, next?: (data) => void) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPLOAD_CLIENT_PICTURE, async () => {
        const data = await api.uploadClientPicture(id, pictureData);
        if (data.clientId && data.profileImageS3Url) {
          dispatch(updateClientImage(data.clientId, data.profileImageS3Url));
          dispatch(updateClientImageMedical(data.clientId, data.profileImageS3Url));
        }
        // eslint-disable-next-line no-unused-expressions
        next && next(data);
        return data;
      })
    );
  };

export const fetchClientByReferenceId =
  (referenceId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.CLIENT_GET, async () => api.fetchClientByReferenceId(referenceId))
    );
  };

export const getClients =
  (query?: Record<string, string>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.CLIENTS_GET, async () => {
        try {
          const response = await api.getBatchClient(query);
          return response;
        } catch (err) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err?.message || "Something Went Wrong!.",
              autoTimeout: true
            })
          );
        }
        return null;
      })
    );
  };

export const getClientById =
  (id: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(actionCreatorAsync(Type.CLIENT_GET, async () => api.getClientDetails(id)));
  };

export const saveClient =
  (clientData: Client, stayOnCurrentPage = false) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    if (clientData.id) {
      await dispatch(
        actionCreatorAsync(Type.CLIENT_UPDATE, async () => {
          const data = await api.updateClient(clientData.id, clientData);
          if (data.id && !stayOnCurrentPage) dispatch(push(`/clients/${data.id}`));
          dispatch(updateBillOnClientEdit(data));
          dispatch(updateLabTestOnClientEdit(data));
          dispatch(updateClientName(data.firstName, data.lastName, data.id));
          return data;
        })
      );
    } else {
      await dispatch(
        actionCreatorAsync(Type.CLIENT_CREATE, async (): Promise<unknown> => {
          try {
            const data = await api.createClient(clientData);
            if (data.id && clientData.image) {
              await dispatch(uploadClientImage(data.id, clientData.image));
            }

            if (data.id && !stayOnCurrentPage) dispatch(push(`/clients/${data.id}`));
            return data;
          } catch (e) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message:
                  e.data === "Client limit reached"
                    ? "Client Limit Reached! Please contact administrator!"
                    : e.message || "Error Occured",
                autoTimeout: true
              })
            );
          }
          return null;
        })
      );
    }
  };

export const saveBatchClient =
  (clientData: Client) =>
  (dispatch: IThunkDispatch): IThunkDispatch | { type: Type; payload: Client } =>
    dispatch({ type: Type.CLIENT_CREATE, payload: clientData });

export const getClientVisits =
  (id: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(actionCreatorAsync(Type.GET_CLIENT_VISITS, async () => api.getClientVisits(id)));
  };

export const getClientBills =
  (clientId: string) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_CLIENT_BILLS, async () => billsApi.getBills({ clientId }))
    );
  };

export const deleteClient =
  (id: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    dispatch(
      actionCreatorAsync(Type.CLIENT_DELETE, async () => {
        try {
          await api.deleteClient(id);
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Client Deleted Successfully!",
              autoTimeout: true
            })
          );
          return id;
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: error?.message || commonErrorMessage,
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );
  };

export const clearLastTouched = (): { type: Type } => ({ type: Type.CLEAR_LAST_TOUCHED });

export const getWalkInClient =
  (resourceCentreId: number, next?: (v, a) => void) =>
  async (dispatch: IThunkDispatch, getState: RootState): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.WALK_IN_CUSTOMER_GET, async () => {
        const client = await api.getWalkInClient(resourceCentreId);

        if (next && client[0]) {
          const {
            bills: { draft }
          } = getState();

          next(draft, client[0]);
        }
        return client;
      })
    );
  };

import writeXlsxFile from "write-excel-file";

enum extensions {
  csv = "csv",
  pdf = "pdf",
  xlsx = "xlsx",
  xml = "xml"
}

const types = {
  csv: "text/csv",
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xml: "text/xml"
};

export const download = (content: string, name: string, type: extensions): void => {
  const link = document.createElement("a");
  document.body.appendChild(link);
  if (typeof content === "object") {
    // eslint-disable-next-line no-param-reassign
    content = URL.createObjectURL(content);
    link.href = content;
    link.download = `${name}.${extensions[type]}`;
    link.click();
    window.URL.revokeObjectURL(content);
    return;
  }

  link.setAttribute(
    "href",
    `data:${types[type]};charset=utf-8,\uFEFF${encodeURIComponent(content)}`
  );
  link.setAttribute("download", `${name}.${extensions[type]}`);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 1000);
};

export const downloadCSV = (
  fileName: string,
  rows: any[],
  columns: any[],
  rowProcessor: (row) => any[]
): void => {
  const header = `${columns.join(",")}\n`;
  const content =
    header +
    rows.reduce((aggr, row) => {
      const processedRow = rowProcessor(row);
      return aggr.concat(
        processedRow
          ? `${rowProcessor(row)
              .map((d) => (d ? `"${d}"` : ""))
              .join(",")}\n`
          : ""
      );
    }, "");
  download(content, fileName, extensions.csv);
};

export const downloadExcel = (
  fileName: string,
  headerRow: { value: string }[],
  dataRow: any[]
): Promise<void> =>
  writeXlsxFile([headerRow].concat(dataRow), {
    fileName: `${fileName}.${extensions.xlsx}`
  });

export const downloadXML = (fileName: string, data: string): void => {
  download(data, fileName, extensions.xml);
};

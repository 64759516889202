import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";

interface Props {
  serviceProvider: ServiceProvider | null;
  onChange: (value: ServiceProvider | null) => void;
  // eslint-disable-next-line react/require-default-props
  variant?: "filled" | "outlined" | "standard";
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  error?: {
    value: boolean;
    message: string;
  };
}

export default function ServiceProviderSelect({
  serviceProvider,
  onChange,
  variant = "outlined",
  required = false,
  fullWidth = false,
  error,
  label = "Service Provider",
  placeholder = "Select service provider"
}: Props): JSX.Element {
  const serviceProviders = useSelector((state: RootState) =>
    state.resources.resourceCentreServiceProviders.filter((sp) => sp.active)
  );

  return (
    <Autocomplete
      disablePortal
      value={serviceProvider}
      onChange={(_, value) => onChange(value)}
      options={serviceProviders}
      sx={{ width: fullWidth ? "100%" : 260 }}
      getOptionLabel={(option: ServiceProvider) => `${option.firstName} ${option.lastName}`}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {`${option.firstName} ${option.lastName}`}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth={fullWidth}
          required={required}
          InputLabelProps={{
            shrink: true
          }}
          label={label}
          placeholder={placeholder}
          data-testmation="serviceProviderSelect"
          variant={variant}
          error={Boolean(error?.value)}
          helperText={error?.message || ""}
        />
      )}
    />
  );
}

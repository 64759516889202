import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from "@mui/material";
import * as moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { connect, useSelector } from "react-redux";
import VitalRow, { vitals as vitalsModel } from "./VitalRow";
import { tl } from "../../../components/translate";
import { AssessmentVitals } from ".";
import { RootState } from "../../../store";

interface VitalsPopUpInterface {
  isOpen: boolean;
  onClose: () => void;
  aVitals?: AssessmentVitals[];
  onSave: (data) => void;
  vitalSettings: any;
}

const VitalsPopUp: React.FC<VitalsPopUpInterface> = ({
  isOpen,
  onClose,
  aVitals,
  onSave,
  vitalSettings
}) => {
  const defaultVitals: AssessmentVitals[] = [];
  const vitalsOrder = useSelector(
    (state: RootState) =>
      state.userContext?.resourceCentre?.settings?.assessmentSettings?.vitalsOrder
  );
  if (vitalSettings) {
    vitalsOrder.forEach((item) => {
      if (vitalSettings[item]) {
        const vitalInfo = vitalsModel.find((vt) => vt.dbKey === item);
        const formattedDefaultVital = {
          name: vitalInfo.name,
          reading: vitalInfo.defaultValue,
          type: vitalInfo.type,
          unit: vitalInfo.unit,
          extraInfo: null,
          date: moment()
        };
        if (vitalInfo) defaultVitals.push(formattedDefaultVital);
      }
    });
  }

  const [vitals, setVitals] = React.useState<AssessmentVitals[]>([]);

  React.useEffect(() => {
    setVitals(aVitals?.length ? aVitals : defaultVitals);
  }, [aVitals]);

  return (
    <Dialog fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle
        style={{
          borderBottom: "1px solid #ececec",
          background: "#f9f9f9",
          textTransform: "uppercase"
        }}
      >
        {tl("assessment.addVital")}
      </DialogTitle>
      <DialogContent style={{ padding: "24px 32px" }}>
        <Box style={{ width: "100%", display: "flex" }}>
          <Box width="30%">
            <Typography style={{ fontWeight: 600 }}>{tl("assessment.vital")}</Typography>
          </Box>
          <Box width="30%" pl="20px">
            <Typography style={{ fontWeight: 600 }}>{tl("assessment.reading")}</Typography>
          </Box>
          <Box width="40%" pl="42px">
            <Typography style={{ fontWeight: 600 }}>{tl("assessment.extraInfo")}</Typography>
          </Box>
        </Box>
        <Box style={{ width: "100%" }}>
          {vitals?.map((vi, index) => (
            <VitalRow
              key={vi.name}
              vital={vi}
              onChange={(newObj) => {
                const newArr = vitals;
                newArr[index] = newObj;
                setVitals(newArr);
              }}
              onRemove={() => {
                const newArr = cloneDeep(vitals);
                newArr.splice(index, 1);
                setVitals(newArr);
              }}
            />
          ))}
        </Box>
        <Box
          data-testmation="vitals.AddNewButton"
          onClick={() => {
            setVitals([
              ...vitals,
              {
                name: null,
                reading: null,
                unit: null,
                extraInfo: null,
                date: moment()
              }
            ]);
          }}
          style={{ cursor: "pointer", width: "80px" }}
        >
          <Typography>
            <u>{tl("assessment.addNew")}</u>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          background: "#00800014",
          borderTop: "1px solid #00800017",
          justifyContent: "flex-start"
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          padding="0px 24px"
          alignContent="center"
        >
          <Box />
          <Box>
            <Button
              style={{ marginRight: "8px" }}
              data-testmation="vitals.cancelButton"
              color="primary"
              onClick={() => {
                onClose();
              }}
            >
              {tl("assessment.cancel")}
            </Button>
            <Button
              data-testmation="vitalsSaveButton"
              variant="contained"
              color="primary"
              onClick={async () => {
                const filteredVitals = vitals.filter((vi) => vi.name && vi.reading);
                filteredVitals.forEach((element) => {
                  // eslint-disable-next-line no-param-reassign
                  delete element.type;
                });
                await onSave(filteredVitals);
                await setVitals(defaultVitals);
                await onClose();
              }}
            >
              {tl("assessment.save")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

VitalsPopUp.defaultProps = {
  aVitals: []
};

const mapStateToProps = (state) => {
  const rc =
    state.resources.resourceCentres.find((rC) => rC.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  return {
    vitalSettings: rc?.settings.vitalSettings
  };
};

export default connect(mapStateToProps, null)(VitalsPopUp);

import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Delete, Edit } from "@mui/icons-material";
import { match as MatchProps } from "react-router";
import { Box, IconButton, Typography } from "@mui/material";

import Panel from "../../../components/Panel";
import { tl } from "../../../components/translate";
import { rupeeDisplay } from "../../../helpers/rupee";
import OpdRequestStatusChip from "./OpdRequestStatusChip";
import { getFullName } from "../../../helpers/formatters";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import useMobileScreen from "../../../hooks/useMobileScreen";
import HandleOpdRequestStatus from "./HandleOpdRequestStatus";
import { notificationAdd } from "../../../actions/notification";
import { OPDRequestStatus } from "../../../enum/OpdRequestForm";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { deleteOpdRequestForm, getOpdRequestForm, RequestForm } from "../../../api/requestForms";

interface Props {
  onClose: () => void;
  match?: MatchProps;
  selectedRow: Partial<RequestForm> | null;
  opdRequestData: { results: RequestForm[]; total: number };
  // eslint-disable-next-line max-len
  setOpdRequestData: React.Dispatch<
    React.SetStateAction<{ results: RequestForm[]; total: number }>
  >;
  isInfoRenderedInReport?: boolean;
}

const OpdRequestInfo = ({
  onClose,
  selectedRow,
  match,
  opdRequestData,
  setOpdRequestData,
  isInfoRenderedInReport
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isMobileScreen = useMobileScreen();
  const requestId = Number(match?.params?.id || selectedRow?.id);
  const [request, setRequest] = React.useState(selectedRow || null);
  const [dialogToggle, setDialogToggle] = React.useState(false);
  const dialogToggler = () => setDialogToggle(!dialogToggle);

  const deleteOpdRequestFn = async () => {
    try {
      if (!requestId) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to delete request",
            autoTimeout: true
          })
        );
        return;
      }

      await deleteOpdRequestForm(requestId);
      setOpdRequestData((prev) => ({
        ...prev,
        total: prev.total === 0 ? 0 : prev.total - 1,
        results: opdRequestData.results.filter((item) => item.id !== requestId)
      }));
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Request deleted successfully",
          autoTimeout: true
        })
      );
      onClose();
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to delete",
          autoTimeout: true
        })
      );
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const res = await getOpdRequestForm(requestId);
        setRequest(res);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: errorFetchMessage(MODULE.OPD_REQUEST_FORM),
            autoTimeout: true
          })
        );
      }
    })();
  }, [selectedRow, dispatch, requestId]);

  const titleJsx = (
    <Box
      style={{ width: "100%" }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={1}
    >
      <Box display="flex" sx={{ alignItems: "center" }}>
        <Typography>
          <Box component="span" fontWeight="600" fontSize="16px" pr={2}>
            {tl("opd.labOrderInformation")}
          </Box>
        </Typography>
        <Box pr={2}>{request?.status && <OpdRequestStatusChip status={request.status} />}</Box>
      </Box>
      <Box display="flex">
        {!isInfoRenderedInReport && (
          <Box display="flex" alignItems="center">
            <IconButton
              style={{ cursor: "pointer", padding: "5px" }}
              onClick={() => setDialogToggle(!dialogToggle)}
            >
              <Delete />
            </IconButton>
          </Box>
        )}

        {request?.status === OPDRequestStatus.ORDERED && match && !isInfoRenderedInReport && (
          <Box display="flex" alignItems="center" ml="8px">
            <IconButton
              style={{ cursor: "pointer", padding: "5px" }}
              onClick={() => dispatch(push(`${match.url}/edit`))}
            >
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Panel onClose={() => onClose()} titleJsx={titleJsx}>
      {dialogToggle && (
        <OkhatiDialog
          title={tl("opd.deleteLabOrder")}
          description="Are you sure you want to delete this lab order form?"
          next={deleteOpdRequestFn}
          cancel={dialogToggler}
          readMode={false}
        />
      )}
      <Box
        pt={4}
        display="flex"
        flexDirection="column"
        gap="5px"
        px={`${isMobileScreen ? "16px" : "32px"}`}
      >
        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestList.client")}
          </Typography>
          <Typography>{getFullName(request?.client) || ""}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestInfo.supplierName")}
          </Typography>
          <Typography>{request?.supplier?.name ?? ""}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestInfo.serviceProvider")}
          </Typography>
          <Typography>{getFullName(request?.serviceProvider)}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestInfo.service")}
          </Typography>
          <Typography>
            {request?.requestedItems?.map((item) => (
              <Typography key={item.id}>{item?.name || ""}</Typography>
            ))}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestList.quantity")}
          </Typography>
          <Typography>{request?.requestedItems[0]?.quantity || 0}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestInfo.price")}
          </Typography>
          <Typography>{rupeeDisplay(request?.requestedItems[0]?.price) || 0}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestInfo.total")}
          </Typography>
          <Typography>{rupeeDisplay(request?.requestedItems[0]?.total) || 0}</Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography width="30%" fontWeight={600}>
            {tl("opd.requestInfo.sentDate")}
          </Typography>
          <Typography>{convertADtoBS(new Date(request?.sentDate)).formatted4} BS</Typography>
        </Box>

        {request?.deliveryDate && (
          <Box display="flex" alignItems="center">
            <Typography width="30%" fontWeight={600}>
              {tl("opd.requestInfo.deliveryDate")}
            </Typography>
            <Typography>{convertADtoBS(new Date(request.deliveryDate)).formatted4} BS</Typography>
          </Box>
        )}

        {request?.status === OPDRequestStatus.ORDERED && !isInfoRenderedInReport && (
          <Box display="flex" alignItems="center">
            <Typography width="30%" fontWeight={600}>
              {tl("opd.requestInfo.status")}
            </Typography>
            <HandleOpdRequestStatus
              opdRequest={request}
              setOpdRequest={setRequest}
              setOpdRequestData={setOpdRequestData}
            />
          </Box>
        )}
      </Box>
    </Panel>
  );
};

export default OpdRequestInfo;

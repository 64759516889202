import { Allergy, GenericMedicine } from "../interfaces/Allergy";
import { Get, Post, Delete, Put } from "./apiHelper";
import { AllergyModalState } from "../containers/Assessment/Allergies";

const allergiesRoot = "/api/allergies";

export async function getClientAllergies(clientId: number): Promise<Array<Allergy>> {
  const response = await Get(`${allergiesRoot}/${clientId}`, true);
  return response.data as Array<Allergy>;
}

export async function postClientAllergy(
  clientId: number,
  data: AllergyModalState | Allergy
): Promise<unknown> {
  const response = await Post(`${allergiesRoot}/${clientId}`, data, true);
  return response.data;
}

export async function putClientAllergy(data: Allergy): Promise<unknown> {
  const response = await Put(`${allergiesRoot}/${data.id}`, data, true);
  return response.data;
}

export async function genericMedicines(q: string): Promise<Array<GenericMedicine>> {
  const response = await Get(`${allergiesRoot}/genericMedicines?q=${q}`, true);
  return response.data as Array<GenericMedicine>;
}

export async function deleteAllergy(id: number): Promise<unknown> {
  const response = await Delete(`${allergiesRoot}/${id}`, true);
  return response.data;
}

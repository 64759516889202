import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import uniq from "lodash/uniq";
import omit from "lodash/omit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ColumnSelector from "./ColumnSelector";
import Modal from "../Modal/Modal";
import List from "../List";
import { notificationAdd as addNotification } from "../../actions/notification";
import {
  checkRequiredFields,
  generateColumns,
  makeSinglePayload,
  parseRawFile,
  transformPayload,
  validatePayload
} from "./csvHelpers";
import "./CsvUploader.css";
import ImportStatusModal from "./ImportStatusModal";

function generateSingleMessage(messages: Array<string>): string {
  return messages?.join(", ");
}

const hasExceededLimit = (limit, data) => {
  if (!limit) return false;
  return data?.length > +limit;
};

export const MenuItem = ({
  onClick,
  children
}: {
  onClick: () => void;
  children: ReactNode;
}): JSX.Element => (
  <Typography
    className="okhati-list-menu-item"
    sx={{ cursor: "pointer", margin: "3px", fontSize: "10px", fontWeight: "700" }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

const CsvUploader = ({
  buttonText = "Import from CSV",
  columns = [],
  requiredFieldInfoText,
  createDataApi,
  runAfterSave,
  runAfterSaveAction,
  notificationAdd,
  useBatchUpload = false,
  renderAdditionalInfo,
  limit,
  maxUploadLimitText
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>();
  const [showModal, setShowModal] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [ongoing, setOngoing] = React.useState(false);
  const [importing, setImporting] = React.useState(false);
  const [columnMap, setColumnMap] = React.useState([]);
  const [counter, setCounter] = React.useState({ success: 0, fail: 0 });
  const [fieldErrors, setFieldErrors] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [internalErrorState, setInternalErrorState] = React.useState([]);
  const [redownloadData, setRedownloadData] = React.useState([]);

  const requiredFields = columns.filter((column) => column.isRequired).map((col) => col.value);
  const selectedColumns = columnMap.filter((col) => col.selected);
  const csvFields = data?.meta?.fields || [];
  const minColCount = Math.max(columns.length, csvFields.length);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const resetInput = () => {
    fileInputRef.current.value = null;
  };

  const filterEmptyUploadFields = (itemsToBeFiltered) =>
    itemsToBeFiltered.filter((item) => Object.keys(item).length > 1);

  const handleChange = async (event) => {
    const rawCsvFile = event.target.files[0];
    try {
      setOngoing(true);
      const { data: colData, meta } = await parseRawFile(rawCsvFile, {
        header: true,
        skipEmptyLines: false
      });
      setData({ data: filterEmptyUploadFields(colData), meta });
      setColumnMap(generateColumns(columns, Math.max(columns.length, meta.fields.length)));
      setShowModal(true);
    } catch (err) {
      notificationAdd();
    } finally {
      resetInput();
      setOngoing(false);
    }
  };

  const generatedValidatedData = (updatedColumns) => {
    const currentSelectedCols = updatedColumns.filter((item) => Boolean(item.selected));
    const payload = transformPayload(data.data || [], currentSelectedCols);
    const validators = currentSelectedCols;
    return validatePayload(payload, validators);
  };

  function getErrors(errorData) {
    const invalidRows = errorData
      .map((item, i) => ({ ...item, index: i }))
      .filter((item) => !item.isValid);
    const errorState = invalidRows.map((item) => ({
      id: item.index,
      message: generateSingleMessage(item.message)
    }));

    return { errorState, invalidRows };
  }

  const hasFilledUpRequiredFields = (requiredColumnFields, updatedColumns) => {
    const filledFields = [];

    updatedColumns.forEach((col) => {
      if (col.selected) {
        if (col.isCompoundField) {
          if (Array.isArray(col.compoundTo)) {
            col.compoundTo.forEach((f) => filledFields.push(f));
          }
        } else {
          filledFields.push(col.value);
        }
      }
    });

    return requiredColumnFields.every((item) => filledFields.includes(item));
  };

  const handleColumnChange = (index, value) => {
    const foundCol = columns.find((col) => col.value === value);

    const updatedColumns = columnMap.map((column, i) =>
      i === index
        ? {
            label: foundCol ? foundCol.label : column.label,
            value: foundCol ? foundCol.value : column.value,
            formatter: foundCol ? foundCol.formatter : column.formatter,
            isCompoundField: foundCol ? foundCol.isCompoundField : column.isCompoundField,
            validator: foundCol ? foundCol.validator : column.validator,
            mapTo: value ? data.meta.fields[index] : "",
            selected: value,
            compoundTo: foundCol ? foundCol.compoundTo || null : column.compoundTo
          }
        : column
    );
    setColumnMap(updatedColumns);
    if (useBatchUpload)
      if (hasFilledUpRequiredFields(requiredFields, updatedColumns)) {
        const { errorState } = getErrors(generatedValidatedData(updatedColumns));
        setInternalErrorState(errorState);
      } else {
        setInternalErrorState([]);
      }
  };

  const handleBatchPostClients = async (clients) => {
    const { invalidRows, errorState } = getErrors(clients);

    const errorIndexes = errorState.map((item) => item.id);
    const dataWithErrors = data.data.filter((item, i) => errorIndexes.includes(i));

    setRedownloadData(dataWithErrors);

    setInternalErrorState(errorState.map((item, i) => ({ ...item, id: i })));
    const formattedClients = clients
      .filter((item) => item.isValid)
      .map((item) => omit(item, ["isValid", "message"]));

    try {
      const response = await createDataApi(formattedClients);
      setCounter({ success: clients.length - invalidRows.length, fail: invalidRows.length });
      setData({
        ...data,
        data: dataWithErrors
      });
      setMessage("");
      if (runAfterSave) {
        runAfterSave();
      }
      setOngoing(false);
      return response;
    } catch (error) {
      setCounter({ success: 0, fail: clients.length });
      if (error?.data?.type === "UniqueViolation") {
        setMessage("Phone number already exists");
      } else {
        setMessage(error?.data?.message || "");
      }
      setOngoing(false);
    }
    return null;
  };

  const handlePostClients = (clients) =>
    new Promise<void>((resolve) => {
      function allSettledPromises(promises) {
        const wrappedPromises = promises.map((promise, index) =>
          Promise.resolve(promise).then(
            (val) => ({ status: "success", value: val, index, message: [] }),
            (err) => ({
              status: "failed",
              message: typeof err?.message === "string" ? [err.message] : err.message,
              index
            })
          )
        );
        return Promise.all(wrappedPromises);
      }

      try {
        const allPromises = clients.map((client) => {
          if (!client.isValid) {
            return Promise.reject(new Error(client.message));
          }
          return createDataApi(omit(client, ["isValid", "message"]));
        });
        allSettledPromises(allPromises).then((results) => {
          const successResults = results
            .filter((result) => result.status === "success")
            .map((client) => client);
          const rejectedClientsMessages = results
            .filter((result) => result.status === "failed")
            .reduce((allMessage, msg) => [...allMessage, ...msg.message], []);
          const successIndexes = successResults.map((client) => client.index);
          const failedClients = data.data.filter((client, i) => !successIndexes.includes(i));
          setCounter({
            success: successResults.length,
            fail: results.length - successResults.length
          });
          setFieldErrors(uniq(rejectedClientsMessages));
          setData({ ...data, data: failedClients });
          if (successResults.length) {
            if (runAfterSave) {
              runAfterSave();
            }
            runAfterSaveAction();
          }
          setOngoing(false);
          resolve();
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Failed to createData", err);
        setOngoing(false);
      }
    });

  const handleImport = async () => {
    setOngoing(true);
    setImporting(true);
    setFieldErrors([]);
    setCounter({ success: 0, fail: 0 });
    const updatedSelectedColumns = selectedColumns.map((col) => {
      if (col.value === "dateOfBirth") {
        return { ...col, value: "dob" };
      }
      return col;
    });
    const payload = transformPayload(data.data || [], updatedSelectedColumns);
    const validators = columns.filter((col) => Object.keys(payload[0] || {}).includes(col.value));
    try {
      if (useBatchUpload) {
        await handleBatchPostClients(validatePayload(payload, validators));
      } else {
        await handlePostClients(validatePayload(payload, validators));
      }
    } catch (err) {
      setCounter({ success: 0, fail: data?.data?.length || 0 });
      setOngoing(false);
      // eslint-disable-next-line no-console
      console.log("Batch create request error", err);
    }
  };

  const listColumns = data?.meta?.fields
    ? data.meta.fields.map((col) => ({ key: col, label: col }))
    : [];
  const listData = data?.data || [];
  const hasRequiredFields = checkRequiredFields(
    Object.keys(makeSinglePayload(data?.data[0] || [], selectedColumns)),
    requiredFields
  );

  return (
    <>
      <MenuItem onClick={handleClick}>{buttonText}</MenuItem>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        multiple={false}
        onChange={handleChange}
        className="hiddenFileInput"
      />
      <Modal
        title="Import from CSV"
        fullScreen
        open={showModal}
        footer={
          <>
            <Button
              data-testmation="import"
              disabled={!hasRequiredFields || ongoing || hasExceededLimit(limit, listData)}
              onClick={handleImport}
              variant="contained"
              color="primary"
            >
              Import
            </Button>
            <Button
              data-testmation="global.cancel"
              onClick={() => {
                setShowModal(false);
              }}
              color="primary"
            >
              Close
            </Button>
          </>
        }
      >
        <Box
          component="div"
          height={20}
          color="warning.main"
          display="flex"
          justifyContent="space-between"
        >
          <div>
            <Typography style={{ fontSize: "12px" }}>{requiredFieldInfoText}</Typography>
            {maxUploadLimitText && listData?.length > limit && (
              <Typography color="error" style={{ fontSize: "12px" }}>
                {maxUploadLimitText}
              </Typography>
            )}
          </div>
          {renderAdditionalInfo && renderAdditionalInfo()}
        </Box>
        {useBatchUpload && internalErrorState?.length > 0 && !redownloadData.length && (
          <Box color="error.main" display="flex" gap={1}>
            <Typography>
              Some errors were found! If you proceed, only the rows without error will be uploaded!
            </Typography>
            <Tooltip
              arrow
              title={
                <Stack>
                  {internalErrorState.map((item) => (
                    <Typography variant="subtitle2" key={item.id}>
                      {item.message}
                    </Typography>
                  ))}
                </Stack>
              }
            >
              <ErrorOutlineIcon color="error" />
            </Tooltip>
          </Box>
        )}
        <ImportStatusModal
          setShowModal={setShowModal}
          ongoing={ongoing}
          importing={importing}
          setImporting={setImporting}
          counter={counter}
          setFieldErrors={setFieldErrors}
          fieldErrors={fieldErrors}
          message={message}
          redownLoadData={redownloadData}
        />
        <div className="tableWrap">
          <Box display="flex" flexDirection="column">
            <ColumnSelector
              columns={columnMap}
              allColumns={generateColumns(columns, minColCount)}
              onChange={handleColumnChange}
            />
            <Box height="calc(100vh - 244px)">
              <Box component="div" height="calc(100%)">
                <List
                  showListHeader={false}
                  hideCreateButton
                  withoutSearch
                  testmationLabel="csvDataList"
                  automation="CsvDataList"
                  title=""
                  createLabel=""
                  rowHeight={40}
                  defaultSortOrder={-1}
                  isResponsive={false}
                  columns={listColumns}
                  data={listData}
                  isLoading={!listData.length}
                  errorRowIndexes={internalErrorState.map((item) => item.id)}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
    </>
  );
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    notificationAdd: () => {
      dispatch(
        addNotification({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to read the data",
          autoTimeout: true
        })
      );
    },
    runAfterSaveAction: (data = null) => {
      if (ownProps.runAfterSaveAction) {
        dispatch(ownProps.runAfterSaveAction(data));
      }
    }
  };
}

export default connect(null, mapDispatchToProps)(CsvUploader);

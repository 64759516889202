import PrintIcon from "@mui/icons-material/Print";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientLedger } from "../../../api/client";
import List, { EmptyView } from "../../../components/OList";
import { RootState } from "../../../store";
import PrintClientLedger from "./PrintClientLedger";
import "./styles.scss";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { ClientLedger as ClientLedgerInterface } from "../../../interfaces/ClientInterface";

interface Props {
  clientId: number;
}

function ClientLedger({ clientId }: Props): JSX.Element {
  const [clientLedgers, setClientLedgers] = React.useState<ClientLedgerInterface[]>([]);
  const client = useSelector((state: RootState) =>
    state.clients.collection.find((item) => item.id === clientId)
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const data = await getClientLedger(clientId);
        // Descending order as the latest ledger should be at the beginning.
        setClientLedgers(data.sort((a, b) => b.id - a.id));
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            variant: "error",
            autoTimeout: true
          })
        );
      }
    })();
  }, [clientId, dispatch]);

  return (
    <div className="clientLedger">
      <Typography fontSize="small" fontWeight="bold">
        Ledgers are in Descending Order
      </Typography>
      <List
        automation="clientLabs"
        rowHeight={40}
        data={clientLedgers}
        adjustHeightToContents
        columns={[
          {
            key: "id",
            label: "ID",
            formatter: ({ id }) => <Typography>{id}</Typography>,
            sortable: true,
            sortFunction: (a, b) => (a.id > b.id ? 1 : -1)
          },
          {
            key: "transactionDate",
            label: "Date",
            formatter: ({ transactionDate }) => (
              <Typography>
                {transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : ""}
              </Typography>
            ),
            sortFunction: (a, b) =>
              new Date(a.transactionDate).getTime() > new Date(b.transactionDate).getTime()
                ? 1
                : -1,
            sortable: true
          },
          {
            key: "voucherType",
            label: "Voucher Type",
            formatter: (row) => <Typography>{row.voucherType}</Typography>
          },
          {
            key: "documentNumber",
            label: "Document Number",
            formatter: (row) => (
              <Typography>
                <Box component="span">{row.documentNumber}</Box>
              </Typography>
            )
          },
          {
            key: "amount",
            label: "Amount",
            formatter: ({ amount }) => <Typography>{amount}</Typography>
          },
          {
            key: "balance",
            label: "Balance",
            formatter: ({ balance }) => <Typography>{balance}</Typography>
          },
          {
            key: "",
            label: "",
            formatter: (row) => (
              <div>
                <PrintClientLedger
                  buttonText={<PrintIcon htmlColor="black" />}
                  client={client}
                  data={row}
                />
              </div>
            )
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No client ledger created yet for the client...
          </Box>
        </EmptyView>
      </List>
    </div>
  );
}

export default ClientLedger;

import { startCase } from "lodash";
import moment from "moment";
import {
  isRequired,
  isValidMobileNumber,
  isEmail,
  isBSDate,
  isADDateString,
  isADDate
} from "../helpers/validators";
import { TransactionType } from "../interfaces/Accounts";
import { convertADtoBS, convertBStoAD } from "../components/Calendar/functions/calendarFunctions";
import { ALL_DEPARTMENT } from "../containers/ResourceCentre/UserSettings/SPCreateEdit";

interface OptionType {
  value: string | number;
  label: string;
  description?: string;
}

interface InputFieldProps {
  key: string;
  label: string;
  value: string;
  inputType: string;
  editable: boolean;
  creatable: boolean;
  required: boolean;
  options: Array<OptionType>;
}

const fields = ({
  isPhoneEmailRequired
}: {
  isPhoneEmailRequired: boolean;
}): Array<Record<string, unknown>> => [
  {
    key: "firstName",
    label: "firstname",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Firstname is required" })]
  },
  {
    key: "lastName",
    label: "lastname",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Lastname is required" })]
  },
  {
    key: "phone",
    label: "mobileNumber",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: isPhoneEmailRequired,
    mobileNumber: true,
    validators: [
      isRequired({ msg: "Phone number required" }),
      isValidMobileNumber({ msg: "Should be a valid mobile number" })
    ]
  },
  {
    key: "email",
    label: "email",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: isPhoneEmailRequired,
    validators: [
      isRequired({ msg: "Email id is required" }),
      isEmail({ msg: "Should be a valid email" })
    ]
  },
  {
    key: "panNumber",
    label: "panNumber",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false
  }
];

export const extraFieldForAccountUser = [
  // Date Field that uses the ToggleInputDate Component
  {
    key: "openingBalanceDate",
    label: "openingBalanceDate",
    value: "",
    inputType: "bsDate",
    showAgeField: false,
    editable: true,
    creatable: true,
    required: false,
    // Formatter how it looks in Nepali Date
    formatter: (dateString: Date | moment.Moment | string): Date | moment.Moment | string => {
      if (!dateString) return dateString;
      const isValid = moment(dateString).toISOString() === dateString;
      if (!isValid) return dateString;
      return convertADtoBS(moment.utc(dateString)).formatted4;
    },
    // ReverseFormatter
    // For Converting AD --> BS and BS --> AD
    reverseFormatter: (
      dateString: Date | moment.Moment | string
    ): Date | moment.Moment | string | null => {
      if (!dateString) return null;
      const isValid = isBSDate({ msg: "" })(dateString);
      if (!dateString || !isValid) return dateString;
      const [year, month, date] = dateString.split("-");
      return moment
        .utc(
          // when we have a date string without timezone, it is better to force them at +0 UTC
          moment(convertBStoAD(Number(year), Number(month), Number(date))).format("YYYYMMDD")
        )
        .toISOString();
    },
    // AD Formatter
    // To Convert From Nepali Date to English Date
    ADFormatter: (
      dateString: Date | moment.Moment | string
    ): Date | moment.Moment | string | null => {
      if (!dateString) return null;
      const isValid = isADDate({ msg: "" })(dateString);
      if (!dateString || !isValid) return dateString;
      return moment.utc(dateString).toISOString();
    },
    validators: [isADDateString({ msg: "Date must be in specified format" })]
  },
  {
    key: "openingBalance",
    label: "openingBalance",
    value: "",
    inputType: "number",
    editable: true,
    creatable: true,
    required: false
  },
  {
    key: "transactionType",
    label: "transactionType",
    value: "",
    inputType: "select",
    editable: true,
    creatable: true,
    required: false,
    options: [
      { value: TransactionType.DEBIT, label: startCase(TransactionType.DEBIT) },
      { value: TransactionType.CREDIT, label: startCase(TransactionType.CREDIT) }
    ]
  }
];

export const getUserGroupOption = (
  userGroups: Array<OptionType>,
  departmentId: number | null | string,
  hideUserGroup: boolean
): Array<InputFieldProps> => {
  if (hideUserGroup) {
    return [];
  }
  return [
    {
      key: "userGroup",
      label: "userGroup",
      value: "",
      inputType: "select",
      editable: true,
      creatable: true,
      required: true,
      options: userGroups.filter((group) =>
        departmentId === ALL_DEPARTMENT ? !group.isDepartmentGroup : group.isDepartmentGroup
      ),
      validators: [isRequired({ msg: "Usergroup is required" })]
    }
  ];
};

export const getDepartmentOptions = (departments: Array<OptionType>): Array<InputFieldProps> => [
  {
    key: "departmentId",
    label: "department",
    value: "",
    inputType: "select",
    editable: true,
    creatable: true,
    required: true,
    options: [...[{ label: startCase(ALL_DEPARTMENT), value: ALL_DEPARTMENT }], ...departments]
  }
];

export default fields;

import React from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";

import { isNepaliPhoneNumber, validate } from "../../helpers/validators";

import styles from "./styles.module.css";

interface PropsType {
  searchFn: (query: string) => Promise<Array<any>>;
  itemRenderer: (item: any) => JSX.Element;
  onItemSelect: (item: any) => void;
  onChange: (string) => void;
  label: string;
  name: string;
}

const Search: React.FC<PropsType> = ({
  searchFn,
  itemRenderer,
  label,
  name,
  onItemSelect,
  onChange
}) => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (query.length > 2) {
      timeoutRef.current = setTimeout(
        () => {
          searchFn(query).then((results) => {
            setItems(results);
            setLoading(false);
          });
        },
        query.length === 3 ? 0 : 500
      );
    } else if (query.length === 0) {
      setItems([]);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, [query, searchFn, loading]);

  const checkValidation = (e) => {
    const err = validate(e, [isNepaliPhoneNumber({ msg: "Should be valid phone number" })]);
    setError(err);
  };

  return (
    <div className={styles.root}>
      <Autocomplete
        id={name}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(item) => item.name}
        options={items}
        loading={loading}
        freeSolo
        onChange={(e, value) => {
          onItemSelect(value);
        }}
        renderInput={(params) => (
          <div>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="standard"
              label={label}
              onBlur={(e) => {
                checkValidation(e.currentTarget.value);
              }}
              onChange={(e) => {
                if (e.currentTarget.value.length > 2) {
                  setOpen(true);
                  setLoading(true);
                }
                onChange(e.currentTarget.value);
                setQuery(e.currentTarget.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
            {name === "phone" && error && !error.isValid && error.messages.length ? (
              <FormHelperText className={styles.colorRed}>
                {error.messages.join("\n")}
              </FormHelperText>
            ) : (
              ""
            )}
          </div>
        )}
        renderOption={(item) => itemRenderer(item)}
      />
    </div>
  );
};

export default Search;

import React from "react";
import { match as MatchProps } from "react-router";
import StockList from "../StockList";
import useQueryParams from "../../../hooks/useQueryParams";
import { MultipleHeadersInterface } from "../../../components/List";
import StockTransactionsList from "../StockTransactions/StockTransactionsList";
import BillList, { Mode } from "../../Billing/BillList";
import { CentralStoreTabs } from "../../../actions/stock";

export const centralStoreHeaders = (tab: CentralStoreTabs): MultipleHeadersInterface => ({
  headers: [
    {
      key: CentralStoreTabs.PRODUCTS,
      title: "Stock",
      goto: `/stock/kitchenPharmacy?tab=${CentralStoreTabs.PRODUCTS}`
    },

    {
      key: CentralStoreTabs.STOCK_REQUESTED,
      title: "Stock Requested",
      goto: `/stock/kitchenPharmacy?tab=${CentralStoreTabs.STOCK_REQUESTED}`
    },
    {
      key: CentralStoreTabs.STOCK_RECEIVED,
      title: "Stocks Received",
      goto: `/stock/kitchenPharmacy?tab=${CentralStoreTabs.STOCK_RECEIVED}`
    }
  ],
  url: tab
});

const CentralStore = ({ match, id }: { match: MatchProps; id: number }): JSX.Element => {
  const [currentTab, setCurrentTab] = React.useState(CentralStoreTabs.PRODUCTS);
  const query = useQueryParams();
  const tab = query.get("tab");
  React.useEffect(() => {
    if (!tab) {
      setCurrentTab(CentralStoreTabs.PRODUCTS);
    } else {
      setCurrentTab(tab);
    }
  }, [tab]);
  return (
    <>
      {currentTab === CentralStoreTabs.PRODUCTS && (
        <StockList match={match} isInternal={false} id={id} isKitchenPharmacyTab />
      )}
      {currentTab === CentralStoreTabs.STOCK_REQUESTED && (
        <StockTransactionsList match={match} isCentralStoreTab />
      )}
      {currentTab === CentralStoreTabs.STOCK_RECEIVED && (
        <BillList
          // eslint-disable-next-line react/jsx-props-no-spreading
          match={match}
          mode={Mode.Show}
          billId={Number(id)}
          isKitchenPharmacy
        />
      )}
    </>
  );
};

export default CentralStore;

import { Print } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import usePrintStyles from "../../hooks/usePrintStyles";
import { RootState } from "../../store";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { ChildGeneralLedger, TransactionType } from "../../interfaces/Accounts";
import { useGetAllChildLedgers } from "./hooks";
import { DocumentInterface, DocumentItemInterface } from "../../interfaces/AccountReportsInterface";
import { rupeeDisplay } from "../../helpers/rupee";
import { separateByComma } from "../../helpers/formatters";
import logo from "../../../assets/images/poweredByOkhati.png";

interface PrintVoucherInterface {
  voucherTitle: string;
  voucherData: DocumentInterface;
  filter: string;
  createdBy: string;
  getLedgerTransactionItems: (data: DocumentInterface) => DocumentItemInterface | [];

  showSubLedger: (
    allLedgers: ChildGeneralLedger[],
    item: DocumentInterface,
    filter: string
  ) => string;
}

const tableHeader = ["SN", "General/Sub Ledger Desc", "Ref.", "Debit Rs.", "Credit Rs."];
const tableRowWidth = ["5%", "45%", "20%", "15%", "15%"];

const findFromDocument = (documents: DocumentItemInterface) =>
  documents.find((item) => item.subLedgerName)?.subLedgerName || "";

const VoucherText = ({ text, fontSize }: { text: string; fontSize?: string }): JSX.Element => (
  <Typography fontSize={fontSize}>{text || ""}</Typography>
);

VoucherText.defaultProps = {
  fontSize: "3.2mm"
};

const getVoucherDate = (voucherDate) => {
  if (!voucherDate) {
    return "";
  }
  return `${convertADtoBS(new Date(voucherDate)).formatted4}`;
};

const PrintVoucher = ({
  voucherTitle,
  voucherData,
  filter,
  getLedgerTransactionItems,
  showSubLedger,
  createdBy
}: PrintVoucherInterface): JSX.Element => {
  const printRef = React.useRef(null);
  const resourceCentre = useSelector((state: RootState) => state.userContext.resourceCentre);

  const ledgerTransactionItems = getLedgerTransactionItems(voucherData);
  const allLedgers = useGetAllChildLedgers();
  const voucherDate = getVoucherDate(voucherData.voucherDate);

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const totalDebitAmount =
    voucherData?.document.reduce(
      (total, item) =>
        item.transactionType === TransactionType.DEBIT ? total + item?.amount : total,
      0
    ) || 0;

  const totalCreditAmount =
    voucherData?.document.reduce(
      (total, item) =>
        item.transactionType === TransactionType.CREDIT ? total + item?.amount : total,
      0
    ) || 0;

  const tableFooter = [
    "",
    "Voucher Total",
    "",
    rupeeDisplay((totalDebitAmount || 0).toFixed(2), true),
    rupeeDisplay((totalCreditAmount || 0).toFixed(2), true)
  ];

  return (
    <>
      <Box
        className="actionBtn"
        component="button"
        onMouseEnter={() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          usePrintStyles({
            pageStyle: "size: 13cm 24.5cm; margin: 0mm"
          }).applyPrintStyles();
        }}
        style={{ backgroundColor: "white", border: 0 }}
      >
        <IconButton
          disableRipple
          size="small"
          sx={{
            mt: "-5px",
            color: "black",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            }
          }}
          onClick={handlePrint}
        >
          <Print />
        </IconButton>
      </Box>
      <iframe title="printContents" className="displayContents">
        <Box style={{ padding: "10px" }} ref={printRef}>
          <Box style={{ display: "flex" }}>
            <Box style={{ flex: 0.2 }}>
              <img
                src={resourceCentre?.resourceCentreLogo?.s3ResourceURL}
                width="80px"
                alt="logo"
              />
            </Box>
            <Box style={{ flex: 0.5 }}>
              <VoucherText text={resourceCentre?.name} />
              <VoucherText text={resourceCentre?.address} />
              <VoucherText text={separateByComma(resourceCentre?.email, resourceCentre?.web)} />
            </Box>
            <Box sx={{ flex: 0.3 }}>
              <VoucherText text={resourceCentre?.phone || ""} />
            </Box>
          </Box>
          <Box mt={2} sx={{ borderTop: "0.5px solid black" }}>
            <Typography fontSize="3.2mm" m={2} textAlign="center">
              {voucherTitle}
            </Typography>
          </Box>
          <Box mt={5} display="flex" justifyContent="space-between">
            <div>
              <VoucherText
                text={`Party Name : ${
                  voucherData?.subLedgerName || findFromDocument(voucherData.document)
                }`}
              />
              <VoucherText text={`Voucher No : ${voucherData?.voucherNumber || ""}`} />
            </div>
            <div>
              <VoucherText
                text={`Voc. Date: ${convertADtoBS(new Date(voucherData?.date)).formatted4}`}
              />
              <VoucherText text={`Doc. Date: ${voucherDate}`} />
            </div>
          </Box>
          {voucherData?.document?.length ? (
            <>
              <Box mt={3} display="flex" borderBottom="0.5px solid grey">
                {tableHeader.map((item, index) => (
                  <Typography
                    sx={{ backgroundColor: "#d4d2d2" }}
                    p="3px"
                    key={item}
                    fontWeight="bold"
                    fontSize="3mm"
                    width={tableRowWidth[index]}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
              {ledgerTransactionItems?.map((item, index) => (
                <Box
                  sx={{ backgroundColor: `${index % 2 === 0 ? "#F2F2F2" : ""}` }}
                  key={item?.id}
                  display="flex"
                >
                  <Typography fontSize="2.5mm" p="5px" width={tableRowWidth[0]}>
                    {index + 1}
                  </Typography>
                  <Typography fontSize="2.5mm" p="5px" width={tableRowWidth[1]}>
                    {`${
                      allLedgers.find((lg) => item.ledgerId === lg.id)?.code || ""
                    }-${showSubLedger(allLedgers, item, filter)}`}
                  </Typography>
                  <Typography fontSize="2.5mm" width={tableRowWidth[2]}>
                    {item.referenceVoucherId || ""}
                  </Typography>
                  <Typography fontSize="2.5mm" p="5px" width={tableRowWidth[3]}>
                    {item.transactionType === TransactionType.DEBIT &&
                      rupeeDisplay((item.amount || 0).toFixed(2), true)}
                  </Typography>
                  <Typography fontSize="2.5mm" p="5px" width={tableRowWidth[4]}>
                    {item.transactionType === TransactionType.CREDIT &&
                      rupeeDisplay((item.amount || 0).toFixed(2), true)}
                  </Typography>
                </Box>
              ))}
              <Box display="flex" borderTop="0.5px solid grey">
                {tableFooter.map((item, index) => (
                  <Typography
                    fontSize="3mm"
                    sx={{ backgroundColor: "#d4d2d2" }}
                    p="3px"
                    key={item}
                    width={tableRowWidth[index]}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </>
          ) : (
            <Typography fontSize="3.2mm" mt={5} ml={5}>
              There is no document in this voucher.
            </Typography>
          )}
          <Typography fontSize="3.2mm" mt={2}>
            Narration: {voucherData?.remarks}
          </Typography>
          <Box mt={10} display="flex" justifyContent="space-between">
            <Box>
              <Typography fontSize="3.2mm">Created By: </Typography>
              <Typography fontSize="3.2mm">{createdBy || ""}</Typography>
            </Box>
            <Typography fontSize="3.2mm">Approved By: </Typography>
            <Typography fontSize="3.2mm" mr={10}>
              Authorised By:
            </Typography>
          </Box>
          <Box
            sx={{ width: "95%" }}
            mb="0.3cm"
            position="absolute"
            display="flex"
            bottom="0px"
            alignItems="end"
            justifyContent="space-between"
          >
            <Typography fontSize="0.3cm">
              Note: This is an electronically generated voucher.
            </Typography>
            <img src={logo} alt="logo" width={80} />
          </Box>
        </Box>
      </iframe>
    </>
  );
};
export default PrintVoucher;

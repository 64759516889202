import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { startCase } from "lodash";
import Panel from "../../../../components/Panel";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { DayBookReport } from "../../../../interfaces/AccountReportsInterface";
import { tl } from "../../../../components/translate";
import { TransactionType } from "../../../../interfaces/Accounts";

interface DayBookInfoPanelProps {
  handleClose: () => void;
  dayBook: DayBookReport;
}

enum DayBookHeaders {
  VOUCHER_CODE = "voucherCode",
  LEDGER_NAME = "ledgerName",
  DR = "dr",
  CR = "cr"
}

const header = Object.values(DayBookHeaders);

const Header = () => (
  <Box display="flex" borderBottom="0.5px solid grey">
    {header.map((item) => (
      <Typography
        sx={{
          "&:nth-child(2)": {
            flexGrow: 1
          },
          flexBasis: "130px"
        }}
        p="5px"
        key={item}
        fontWeight="bold"
      >
        {startCase(item)}
      </Typography>
    ))}
  </Box>
);

const DayBookInfoPanel = ({ handleClose, dayBook }: DayBookInfoPanelProps): JSX.Element => {
  const dayBookDetails = [
    { label: tl("accounts.voucherNumber"), value: dayBook.voucherNumber },
    { label: tl("stock.date"), value: convertADtoBS(new Date(dayBook.date)).formatted4 }
  ];

  return (
    <Panel wrapperStyle={{ minWidth: "760px" }} onClose={handleClose} title="DayBook Information">
      <Box p={5} width="100%">
        {dayBookDetails.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box display="flex" key={index}>
            <Typography fontSize="medium" width="200px">
              {item.label}
            </Typography>
            <Typography>{item.value}</Typography>
          </Box>
        ))}

        <Paper sx={{ margin: "20px 0" }}>
          <Header />
          {dayBook.document?.map((item, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              p="5px"
              display="flex"
            >
              <Typography width="130px">{item.ledgerCode}</Typography>
              <Typography flexGrow={1}>{item.ledgerName}</Typography>
              <Typography width="120px">
                {item.transactionType === TransactionType.DEBIT && item.amount}
              </Typography>
              <Typography width="120px">
                {item.transactionType === TransactionType.CREDIT && item.amount}
              </Typography>
            </Box>
          ))}
        </Paper>

        {dayBook?.remarks && (
          <Typography>
            Remarks: <p>{dayBook?.remarks}</p>
          </Typography>
        )}
      </Box>
    </Panel>
  );
};

export default DayBookInfoPanel;

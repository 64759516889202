import { FilterProps } from "../containers/accounts/BankReconciliation/Header";
import { PSecondaryLedger } from "../containers/accounts/CoaTree";
import { Balance } from "../interfaces/AccountReportsInterface";
import {
  ChildGeneralLedger,
  JournalVouchers,
  PrimaryLedger,
  TrackingNumber,
  VoucherEntryProps,
  AccountingVoucherSeriesInterface,
  VoucherInterface,
  PastFiscalPeriod,
  ClosureBalance
} from "../interfaces/Accounts";
import { BankReconciliation } from "../interfaces/BankReconciliationInterface";
import { BillDocumentType } from "../interfaces/BillInterfaces";
import { StockTransactions } from "../interfaces/StockInterfaces";
import { Get, Post, Patch } from "./apiHelper";

export const getCoaAll = async (): Promise<PrimaryLedger[]> => {
  const response = await Get("/api/coa/all", true);
  return response.data as PrimaryLedger[];
};

export const postAccGroup = async (acGroup: PSecondaryLedger): Promise<PSecondaryLedger> => {
  const response = await Post("/api/accountingGroup", acGroup, true);
  return response.data as PSecondaryLedger;
};

export const patchAccGroup = async (acGroup: PSecondaryLedger): Promise<PSecondaryLedger> => {
  const response = await Patch(`/api/accountingGroup/${acGroup.id}`, acGroup, true);
  return response.data as PSecondaryLedger;
};

export const postChildGl = async (childGl: ChildGeneralLedger): Promise<ChildGeneralLedger> => {
  const response = await Post("/api/childGeneralLedger", childGl, true);
  return response.data as ChildGeneralLedger;
};

export const patchChildGl = async (childGl: ChildGeneralLedger): Promise<ChildGeneralLedger> => {
  const response = await Patch(`/api/childGeneralLedger/${childGl.id}`, childGl, true);
  return response.data as ChildGeneralLedger;
};

export async function getNextVoucherNumber(
  type: string,
  date: Date | string
): Promise<TrackingNumber> {
  const response = await Get(`/api/trackingNumber/${type}/?date=${date}`, true);
  return response.data as TrackingNumber;
}

export const getJournals = async (): Promise<JournalVouchers[]> => {
  const response = await Get("/api/journals", true);
  return response.data as JournalVouchers[];
};

export const postJournal = async (journal: VoucherEntryProps): Promise<VoucherEntryProps> => {
  const response = await Post("/api/journals", journal, true);
  return response.data as VoucherEntryProps;
};

export const getAccountingVoucherSeries = async <T>(): Promise<T[]> => {
  const response = await Get("/api/vouchers", true);
  return response.data as Promise<T[]>;
};

export const patchAccountingVoucherSeries = async <T>(
  id: number,
  data: Partial<AccountingVoucherSeriesInterface>
): Promise<T> => {
  const response = await Patch(`/api/vouchers/${id}`, data, true);
  return response.data as Promise<T>;
};

export const getPaymentVouchers = async (): Promise<VoucherInterface[]> => {
  const response = await Get("/api/paymentVouchers", true);
  return response.data as VoucherInterface[];
};

export const postPaymentVoucher = async (data: VoucherInterface): Promise<VoucherInterface> => {
  const response = await Post("/api/paymentVouchers", data, true);
  return response.data as VoucherInterface;
};

export const getReceiptVouchers = async (): Promise<VoucherInterface[]> => {
  const response = await Get("/api/receiptVouchers", true);
  return response.data as VoucherInterface[];
};

export const postReceiptVoucher = async (data: VoucherInterface): Promise<VoucherInterface> => {
  const response = await Post("/api/receiptVouchers", data, true);
  return response.data as VoucherInterface;
};

export const getPurchaseVouchers = async ({
  queryKey
}: {
  queryKey: unknown;
}): Promise<StockTransactions[]> => {
  const response = await Get(`/api/purchaseVouchers/${queryKey[1]}`, true);
  return response.data as StockTransactions[];
};

export const getPaymentVoucherById = async (id: number): Promise<VoucherInterface> => {
  const response = await Get(`/api/paymentVouchers/${id}`, true);
  return response.data as VoucherInterface;
};

export const getReceiptVoucherById = async (id: number): Promise<VoucherInterface> => {
  const response = await Get(`/api/receiptVouchers/${id}`, true);
  return response.data as VoucherInterface;
};

export const getContraVoucherById = async (id: number): Promise<VoucherInterface> => {
  const response = await Get(`/api/contraVouchers/${id}`, true);
  return response.data as VoucherInterface;
};

export const getStockTransactionVoucherById = async (
  voucherCode: string,
  id: number
): Promise<VoucherInterface> => {
  const response = await Get(`/api/purchaseVouchers/${voucherCode}/${id}`);
  return response.data as VoucherInterface;
};

export const getSalesVoucherById = async (
  voucherCode: string,
  id: number
): Promise<VoucherInterface> => {
  const response = await Get(`/api/salesVOuchers/${voucherCode}/${id}`);
  return response.data as VoucherInterface;
};

export const getSalesVouchers = async ({
  queryKey
}: {
  queryKey: unknown;
}): Promise<BillDocumentType[]> => {
  const response = await Get(`/api/salesVouchers/${queryKey[1]}`, true);
  return response.data as BillDocumentType[];
};

export const getContraVouchers = async (): Promise<VoucherInterface[]> => {
  const response = await Get(`/api/contraVouchers`, true);
  return response.data as VoucherInterface[];
};

export const postContraVoucher = async (data: VoucherInterface): Promise<VoucherInterface> => {
  const response = await Post("/api/contraVouchers", data, true);
  return response.data as VoucherInterface;
};

export const getBankReconciliations = async (
  filters: FilterProps
): Promise<BankReconciliation[]> => {
  const response = await Get(
    `/api/bankReconciliations?until=${filters.until?.toISOString()}&ledgerIds=${
      filters.ledgerIds
    }&includeClearedVouchers=${filters.includeClearedVouchers}`
  );
  return response.data as BankReconciliation[];
};

export const patchBankReconciliations = async (data: {
  vouchers: Partial<BankReconciliation>[];
}): Promise<string> => {
  const response = await Patch(`/api/bankReconciliations`, data, true);
  return response.data as Promise<string>;
};
export const getPastFiscalPeriods = async (rcId: number): Promise<PastFiscalPeriod[]> => {
  const response = await Get(`/api/resourceCentres/${rcId}/pastFiscalPeriods`, true);
  return response.data as PastFiscalPeriod[];
};

export const getResourceCentreClosureBalance = async (
  rcId: number,
  fiscalPeriodId: number
): Promise<ClosureBalance[]> => {
  const response = await Get(
    `/api/resourceCentres/${rcId}/fiscalPeriod/${fiscalPeriodId}/closureBalance`,
    true
  );
  return response.data as Promise<ClosureBalance[]>;
};

export const postResourceCentreClosure = async (
  rcId: number,
  fiscalPeriodId: number
): Promise<ClosureBalance[]> => {
  const response = await Post(
    `/api/resourceCentres/${rcId}/fiscalPeriod/${fiscalPeriodId}/close`,
    {},
    true
  );
  return response.data as Promise<ClosureBalance[]>;
};

export const getLedgerRunningBalance = async (
  id: number,
  entityName: string,
  date: Date | string
): Promise<Balance> => {
  const response = await Get(`/api/ledgerRunningBalance/${id}/${entityName}/${date}`, true);
  return response.data as Balance;
};

export async function batchUploadCOA(data: { [key: string]: string }[]): Promise<unknown> {
  const response = await Patch(`/api/coa/batchUpdate`, { data }, true);
  return response.data;
}

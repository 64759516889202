import { isRequired } from "../helpers/validators";
import {tl} from '../../app/components/translate';

export const fields = [
  {
    key: "email",
    label: "username",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    validators: [isRequired({ msg: "Username is required" })]
  },
  {
    key: "password",
    label: "password",
    value: "",
    inputType: "password",
    editable: true,
    creatable: true,
    validators: [isRequired({ msg: "Password is required" })]
  }
];

import * as PapaParse from "papaparse";
import * as moment from "moment";
import { startCase } from "lodash";
import produce from "immer";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import {
  isRequired,
  hasLength,
  isADDateString,
  isValidPalika,
  isSellableOrInternalCategory,
  productTypeValidator,
  isNumber,
  validateBoolean,
  isValidProductType,
  isNepaliPhoneNumber,
  isEmail,
  CSVSexValidator,
  qtyValidator,
  transactionTypeValidator
} from "../../helpers/validators";
import * as calFns from "../Calendar/functions/calendarFunctions";
import {
  ProductType,
  ProductTypeInfo,
  SellableProductCategory
} from "../../containers/Stock/StockCreateEdit/CreateStockProduct";

export const DEFAULT_COL = {
  label: "",
  value: "",
  mapTo: "",
  selected: ""
};

export function palikaFormatter(p: string | number): { palika: string } {
  if (!p) return null;
  const [, palikaId] = p.toString().split("-");
  if (Number.isInteger(Number(palikaId))) {
    return { palika: palikaId };
  }
  return null;
}

export function palikaFormatterClient(p: string | number): { palikaId: string } {
  if (!p) return null;
  const [, palikaId] = p.toString().split("-");
  if (Number.isInteger(Number(palikaId))) {
    return { palikaId };
  }
  return null;
}

export function sampleCollectionDateFormatter(
  date: string
): string | { sampleCollectionDate: string } {
  if (!date) return null;

  let tmpTime;
  let timeInS = 0; // in seconds
  // eslint-disable-next-line prefer-const
  let [month, day, year] = date.split("/");

  if (year?.toString()?.length > 4) {
    [year, tmpTime] = year.split(" ");
    const [hrs, mins] = tmpTime.toString().split(":");
    timeInS += Number(hrs) * 60 * 60;
    timeInS += Number(mins) * 60;
  }

  const converted = calFns.convertBStoAD(Number(year), Number(month), Number(day));
  if (month && day && year) {
    return { sampleCollectionDate: moment(converted).add(timeInS, "s").toISOString() };
  }
  return date;
}

//  function to convert dob in mm/dd/yyyy (BS) format into yyyy/mm/dd (AD) format
export function dateOfBirthFormatter(date: string): string | { dob: string } {
  if (!date) return null;

  let tmpTime;
  let timeInS = 0; // in seconds
  // eslint-disable-next-line prefer-const
  let [month, day, year] = date.split("/"); // getting month, day and year from input

  // if the date has timestamps
  if (year?.toString()?.length > 4) {
    [year, tmpTime] = year.split(" ");
    const [hrs, mins] = tmpTime.toString().split(":");
    timeInS += Number(hrs) * 60 * 60;
    timeInS += Number(mins) * 60;
  }

  // comverting into AD after processing the date
  const converted = calFns.convertBStoAD(Number(year), Number(month), Number(day));

  // returning the date based on conditions
  if (month && day && year) {
    return { dob: moment(converted).add(timeInS, "s").toISOString() };
  }
  return date;
}

export function productTypeFormatter(type = ""): ProductType {
  return startCase(type) === ProductTypeInfo.Sellable
    ? ProductType.Sellable
    : ProductType.InternalUse;
}

const categoryMap = {
  medicine: "Medicine",
  cosmetics: "Cosmetics",
  suppliments: "Suppliments",
  kits: "Kits",
  medicalitems: "MedicalItems",
  derma: "Derma",
  surgical: "Surgical",
  hair: "Hair",
  stationery: "Stationery",
  medicalkits: "MedicalKits",
  labaccessories: "LabAccessories"
};

const categoryFormatter = (value = ""): string =>
  // Default value as Medicine as per create product UI
  categoryMap[value?.toLowerCase()] || SellableProductCategory.Medicine;

export function narcoticsFormatter(value = "no"): { isNarcotics: boolean } {
  return {
    isNarcotics: value.toLowerCase() === "yes"
  };
}

export function fullNameFormatter(fname = ""): { firstName: string; lastName: string } {
  const [firstName = "", ...lastName] = fname.split(" ");
  return { firstName, lastName: lastName.join(" ") };
}

export function passportFormatter(passportNo: number): {
  passportInfo: { passportNumber: number };
} {
  return { passportInfo: { passportNumber: passportNo } };
}

export function ageFormatter(age = 0): string {
  return moment().subtract(age, "years").utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}

export function labAgeFormatter(age = 0): { dob: string } {
  return { dob: moment().subtract(age, "years").utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") };
}

export function clientAgeFormatter(age = 0): { dob: string } {
  return { dob: moment().subtract(age, "years").toISOString() };
}

export function ageSexFormatter(ageSex = ""): { dob: string; gender: number } {
  const [age = 0, gender = ""] = ageSex.trim().split("/");
  let genderValue;
  if (gender.trim().toLowerCase().startsWith("m")) {
    genderValue = 1;
  } else if (gender.trim().toLowerCase().startsWith("f")) {
    genderValue = 2;
  } else {
    genderValue = 3;
  }
  return { dob: ageFormatter(Number(age)), gender: genderValue };
}

export function sexFormatter(sex = "M"): { gender: number } {
  let genderValue;
  if (sex.trim().toLowerCase().startsWith("m")) {
    genderValue = 1;
  } else if (sex.trim().toLowerCase().startsWith("f")) {
    genderValue = 2;
  } else {
    genderValue = 3;
  }
  return { gender: genderValue };
}

export function phoneNumberFormatter(number = ""): { phone: string } {
  return {
    phone: number.toString().trim()
  };
}

export function parseRawFile(rawFile: string, config = {}): Promise<unknown> {
  return new Promise((resolve, reject) => {
    PapaParse.parse(rawFile, {
      complete: (results) => {
        resolve(results);
      },
      error: (error) => {
        console.log(error, "error");
        reject(new Error("Unable to read file"));
      },
      ...config
    });
  });
}

export function generateColumns(
  availableColumns: Array<unknown>,
  targetColCount: number
): Array<unknown> {
  return Array.from(Array(targetColCount).keys()).map((index) =>
    availableColumns[index] ? { ...DEFAULT_COL, ...availableColumns[index] } : { ...DEFAULT_COL }
  );
}

export function makeSinglePayload(data = {}, transformers = []): Record<string, unknown> {
  let singlePayload = {};

  transformers.forEach((transformer) => {
    singlePayload = {
      ...singlePayload,
      [transformer.value]: data[transformer.mapTo]
    };

    if (transformer.formatter) {
      singlePayload = {
        ...singlePayload,
        ...transformer.formatter(data[transformer.mapTo])
      };
    }
    if (transformer.isCompoundField && singlePayload[transformer.value]) {
      delete singlePayload[transformer.value];
    }
  });
  return omitBy(singlePayload, isUndefined);
}

export function transformPayload(payload = [], transformers = []): Array<unknown> {
  return produce([], (draft) => {
    payload.forEach((data) => {
      draft.push(makeSinglePayload(data, transformers));
    });
  });
}

export function checkRequiredFields(fields = [], requiredFields = []): boolean {
  let hasAllFields = true;
  requiredFields.forEach((reqField) => {
    if (!fields.includes(reqField)) {
      hasAllFields = false;
    }
  });
  return hasAllFields;
}

export function validatePayload(payloads = [], validators = []): Array<unknown> {
  return payloads.map((payload) => {
    let isValidPayload = true;
    let message = [];
    validators.forEach((validator) => {
      if (!validator.validator) return;
      if (validator.isCompoundField) return;
      const isValid = validator?.validator(payload[validator.value]);
      if (!isValid) {
        message = [
          ...message,
          `${payload.name ? `${payload.name}: ` : ""}${validator.validator.msg}`
        ];
      }
      if (!isValid && isValidPayload) {
        isValidPayload = false;
      }
    });
    return { ...payload, isValid: isValidPayload, message };
  });
}

export const tekuLabImportColumns = [
  {
    label: "Identifier",
    value: "externalIdentifier",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: true
  },
  {
    label: "First name",
    value: "firstName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "First name is missing." }),
    isRequired: true
  },
  {
    label: "Last name",
    value: "lastName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Last name is missing." }),
    isRequired: true
  },
  {
    label: "First and last name",
    value: "fullName",
    formatter: fullNameFormatter,
    validator: null,
    isCompoundField: true,
    isRequired: false
  },
  {
    label: "Ethnicity",
    value: "ethnicity",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Age",
    value: "dob",
    formatter: labAgeFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Gender",
    value: "gender",
    formatter: sexFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Age/Sex",
    value: "ageSex",
    formatter: ageSexFormatter,
    isCompoundField: true,
    validator: null,
    isRequired: false
  },
  {
    label: "Address",
    value: "address",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "City",
    value: "city",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Email",
    value: "email",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Nationality",
    value: "nationality",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Passport Number",
    value: "passportInfo",
    formatter: passportFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Phone",
    value: "phone",
    formatter: phoneNumberFormatter,
    isCompoundField: false,
    validator: hasLength({ gt: 9, lt: 10, msg: "Phone number is not valid." }),
    isRequired: true
  },
  {
    label: "Secondary phone",
    value: "secondaryPhone",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Result",
    value: "result",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: true
  },
  {
    label: "Sample colln. date (MM/DD/YYYY) BS",
    value: "sampleCollectionDate",
    formatter: sampleCollectionDateFormatter,
    isCompoundField: false,
    validator: isADDateString({
      msg: "Sample collection date is not in correct format. Make sure it is (MM/DD/YYYY) BS"
    }),
    isRequired: false
  },
  {
    label: "Palika",
    value: "palika",
    formatter: palikaFormatter,
    isCompoundField: false,
    validator: isValidPalika({
      msg: "Palika is not in valid format"
    }),
    isRequired: false
  },
  {
    label: "Ward no.",
    value: "wardNo",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "E-Gene",
    value: "eGene",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "N-Gene",
    value: "nGene",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Orf11ab-Gene",
    value: "Orf11abGene",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "RdRP gene",
    value: "RdRPGene",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  }
];

export const clientImportColumns = [
  {
    label: "Identifier",
    value: "externalIdentifier",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "First name",
    value: "firstName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "First name is missing." }),
    isRequired: true
  },
  {
    label: "Last name",
    value: "lastName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Last name is missing." }),
    isRequired: true
  },
  {
    label: "First and last name",
    value: "fullName",
    formatter: fullNameFormatter,
    validator: null,
    isCompoundField: true,
    isRequired: false
  },
  {
    label: "Ethnicity",
    value: "ethnicity",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Age",
    value: "dob",
    formatter: ageFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Gender",
    value: "gender",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Age/Sex",
    value: "ageSex",
    formatter: ageSexFormatter,
    isCompoundField: true,
    validator: null,
    isRequired: false
  },
  {
    label: "Address",
    value: "address",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "City",
    value: "city",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Email",
    value: "email",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Phone",
    value: "phone",
    formatter: phoneNumberFormatter,
    isCompoundField: false,
    validator: hasLength({ gt: 9, lt: 10, msg: "Phone number is not valid." }),
    isRequired: true
  },
  {
    label: "Secondary phone",
    value: "secondaryPhone",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  }
];

export const productImportColumns = [
  {
    label: "Product Name",
    value: "name",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Product Name is missing." }),
    isRequired: true
  },
  {
    label: "Category",
    value: "category",
    formatter: null,
    isCompoundField: false,
    validator: isSellableOrInternalCategory({ msg: "Invalid Catogory." }),
    isRequired: true
  },
  {
    label: "Unit",
    value: "unit",
    formatter: null,
    isCompoundField: null,
    isRequired: true
  },
  {
    label: "Package",
    value: "package",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Package is missing." }),
    isRequired: true
  },
  {
    label: "Unit Per Package",
    value: "unitsPerPackage",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Description",
    value: "description",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Product Type",
    value: "productType",
    formatter: productTypeFormatter,
    isCompoundField: false,
    validator: productTypeValidator({ msg: "Invalid Product Type." }),
    isRequired: true
  },
  {
    label: "Non Discountable ",
    value: "nonDiscountable",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Product code",
    value: "productCode",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Unit price excluding VAT",
    value: "unitPriceExcVAT",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  }
];

export const stockTransactionImportColumns = [
  {
    label: "Medicine Name",
    value: "medicineName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Meidicine Name is missing." }),
    isRequired: true
  },
  {
    label: "Generic Name",
    value: "genericName",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Batch No",
    value: "batchNumber",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Batch Number is missing" }),
    isRequired: true
  },
  {
    label: "Available Qty",
    value: "availableQty",
    formatter: null,
    isCompoundField: false,
    validator: qtyValidator({ msg: "Available Qty must be a number" }),
    isRequired: true
  },
  {
    label: "Is Narcotics",
    value: "isNarcotics",
    formatter: narcoticsFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Exp Date",
    value: "expiryDate",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Unit",
    value: "unit",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Unit is required" }),
    isRequired: true
  },
  {
    label: "package",
    value: "package",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Package is required" }),
    isRequired: true
  },
  {
    label: "Sales Rate",
    value: "salesRate",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Sales Rate must be a number" }),
    isRequired: true
  },
  {
    label: "Purchase Rate",
    value: "purchaseRate",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Purchase Rate must be a number" }),
    isRequired: true
  },
  {
    label: "Minimum stock quantity",
    value: "minStockQty",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Minimum stock quantity must be a number" }),
    isRequired: false
  },
  {
    label: "Units Per Package",
    value: "unitPerPackage",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Units per package must be a number" }),
    isRequired: false
  },
  {
    label: "Category",
    value: "category",
    formatter: null,
    isCompoundField: false,
    validator: isSellableOrInternalCategory({ msg: "Invalid Catogory." }),
    isRequired: false
  },
  {
    label: "Product Type",
    value: "productType",
    formatter: null,
    isCompoundField: false,
    validator: productTypeValidator({ msg: "invalid product type" }),
    isRequired: false
  },
  {
    label: "Vat Percent",
    value: "vatPct",
    formatter: null,
    isCompoundField: false,
    validator: false,
    isRequired: false
  }
];

export const coaColumns = [
  {
    label: "Primary Ledger",
    value: "primaryLedgerName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Primary Ledger Name is required." }),
    isRequired: true
  },
  {
    label: "Secondary Ledger",
    value: "secondaryLedgerName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Secondary Ledger Name is required." }),
    isRequired: true
  },
  {
    label: "Accounting Group",
    value: "accountingGroupName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Accounting Group is required." }),
    isRequired: true
  },
  {
    label: "Code",
    value: "code",
    formatter: null,
    isCompoundField: false,
    validator: false,
    isRequired: true
  },
  {
    label: "Ledger Name",
    value: "ledgerName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Ledger Name is required." }),
    isRequired: false
  },
  {
    label: "Transaction Type",
    value: "transactionType",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Opening Balance",
    value: "openingBalance",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Ledger Type",
    value: "ledgerType",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Ledger Type is required." }),
    isRequired: true
  },
  {
    label: "Ledger Nature",
    value: "ledgerNature",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Ledger Nature is required." }),
    isRequired: true
  }
];

export const serviceImportColumns = [
  {
    label: "Service Name",
    value: "name",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Service Name is missing." }),
    isRequired: true
  },
  {
    label: "Category",
    value: "category",
    formatter: categoryFormatter,
    isCompoundField: false,
    validator: isRequired({ msg: "Category is required" }),
    isRequired: true
  },
  {
    label: "Unit",
    value: "unit",
    formatter: null,
    isCompoundField: null,
    isRequired: false
  },
  {
    label: "Product Type",
    value: "productType",
    formatter: null,
    isCompoundField: false,
    validator: isValidProductType({ msg: "Product type must be either single or package" }),
    isRequired: true
  },
  {
    label: "Service Price Exc VAT",
    value: "servicePriceExcVAT",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Service Price excluding vat should be number." }),
    isRequired: true
  },
  {
    label: "Vat Percentage",
    value: "vatPct",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Vat Percentage must be a number" }),
    isRequired: false
  },
  {
    label: "Gross Total Price ",
    value: "grossTotalPrice",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Gross total price must be a number" }),
    isRequired: true
  },
  {
    label: "Public Booking",
    value: "publicBooking",
    formatter: null,
    isCompoundField: false,
    validator: validateBoolean({ msg: "Public booking should be either true or false" }),
    isRequired: false
  },
  {
    label: "Handled By Okhati",
    value: "handledByOkhati",
    formatter: null,
    isCompoundField: false,
    validator: validateBoolean({ msg: "Handled By Okhati should be either true or false" }),
    isRequired: false
  },
  {
    label: "Service Provider Rate Unit",
    value: "serviceProviderRateUnit",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Material Charge",
    value: "materialCharge",
    formatter: null,
    isCompoundField: false,
    validator: isNumber({ msg: "Material charge must be a number" }),
    isRequired: false
  },
  {
    label: "Department Id",
    value: "departmentId",
    formatter: null,
    isCompundField: false,
    validator: null,
    isRequired: false
  }
];

export const employeeUploadColumns = [
  {
    label: "First Name*",
    value: "firstName",
    formatter: null,
    isCompoundField: false,
    isRequired: true,
    validator: isRequired({ msg: "First name is missing." })
  },
  {
    label: "Last Name*",
    value: "lastName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Last name is missing." }),
    isRequired: true
  },
  {
    label: "First and last name",
    value: "fullName",
    formatter: fullNameFormatter,
    validator: null,
    isCompoundField: true,
    compoundTo: ["firstName", "lastName"],
    isRequired: false
  },
  {
    label: "Phone*",
    value: "phone",
    formatter: phoneNumberFormatter,
    isCompoundField: false,
    validator: isNepaliPhoneNumber({ msg: "Phone number is not valid" }),
    isRequired: true
  },
  {
    label: "Email",
    value: "email",
    formatter: null,
    isCompoundField: false,
    validator: isEmail({ msg: "Email must be a valid one" }),
    isRequired: true
  }
];

export const serviceProviderUploadColumns = [
  {
    label: "Title",
    value: "title",
    formatter: null,
    isCompoundField: false,
    isRequired: false,
    validator: null
  },
  {
    label: "First Name*",
    value: "firstName",
    formatter: null,
    isCompoundField: false,
    isRequired: true,
    validator: isRequired({ msg: "First name is missing." })
  },
  {
    label: "Last Name*",
    value: "lastName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Last name is missing." }),
    isRequired: true
  },
  {
    label: "First and last name",
    value: "fullName",
    formatter: fullNameFormatter,
    validator: null,
    isCompoundField: true,
    compoundTo: ["firstName", "lastName"],
    isRequired: false
  },
  {
    label: "Registration Number*",
    value: "registrationNumber",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Resgitration number is missing." }),
    isRequired: true
  },
  {
    label: "Phone*",
    value: "phone",
    formatter: phoneNumberFormatter,
    isCompoundField: false,
    validator: isNepaliPhoneNumber({ msg: "Phone number is not valid" }),
    isRequired: true
  },
  {
    label: "Email",
    value: "email",
    formatter: null,
    isCompoundField: false,
    validator: isEmail({ msg: "Email must be a valid one" }),
    isRequired: false
  },
  {
    label: "Gender",
    value: "gender",
    formatter: sexFormatter,
    isCompoundField: false,
    validator: CSVSexValidator({ msg: "Please enter in proper format i.e M/F/O" }),
    isRequired: false
  },
  {
    label: "Address",
    value: "address",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "City",
    value: "city",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Speciality",
    value: "speciality",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Speciality is missing." }),
    isRequired: true
  },
  {
    label: "Qualification",
    value: "qualification",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Qualification is missing." }),
    isRequired: true
  },
  {
    label: "TDS %",
    value: "tds",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  }
];

export const supplierImportColumns = [
  {
    label: "Name",
    value: "name",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "Name is missing." }),
    isRequired: true
  },
  {
    label: "Address",
    value: "address",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Email",
    value: "email",
    formatter: null,
    isCompoundField: false,
    validator: isEmail({ msg: "Email must be a valid one" }),
    isRequired: false
  },
  {
    label: "Phone",
    value: "phone",
    formatter: phoneNumberFormatter,
    isCompoundField: false,
    validator: hasLength({ gt: 9, lt: 10, msg: "Phone number is not valid." }),
    isRequired: false
  },
  {
    label: "Land Line",
    value: "landline",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Pan No",
    value: "panNo",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Opening Balance",
    value: "openingBalance",
    formatter: null,
    isCompundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Transaction type",
    value: "transactionType",
    formatter: null,
    isCompoundField: false,
    validator: transactionTypeValidator({
      msg: "Transaction type can be either debit or credit only."
    }),
    isRequired: false
  }
];

import { Box, Typography, Paper } from "@mui/material";
import React from "react";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import { rupeeDisplay } from "../../helpers/rupee";
import { DocumentInterface } from "../../interfaces/AccountReportsInterface";
import { TransactionType as StockTransactionType } from "../../interfaces/StockInterfaces";
import { TransactionType } from "../../interfaces/Accounts";
import { useGetAllChildLedgers } from "./hooks";
import { paymentOptions } from "./JournalVoucher/VoucherEntry/Summary";
import { Filter } from "./VoucherList";
import PrintVoucher from "./PrintVoucher";

interface VoucherItemView {
  data: DocumentInterface;
  onClose: () => void;
  filter?: string;
}

const getVoucherTitle = (filter) => {
  let title;
  if (filter === Filter.SALES) title = "Sales";
  if (filter === Filter.CREDIT_NOTE) title = "Credit Note";
  if (filter === Filter.DEBIT_NOTE) title = "Debit Note";
  if (filter === Filter.JOURNAL) title = "Journal";
  if (filter === Filter.PAYMENT) title = "Payment";
  if (filter === Filter.PURCHASE) title = "Purchase";
  if (filter === Filter.CONTRA) title = "Contra";
  if (filter === Filter.RECEIPT) title = "Receipt";
  return `${title} Voucher`;
};

const getAmountValue = (data, filter) => {
  switch (filter) {
    case Filter.PURCHASE:
    case Filter.DEBIT_NOTE:
      return data.totalAmount;
    case Filter.SALES:
    case Filter.CREDIT_NOTE:
      return data.total;
    case Filter.PAYMENT:
    case Filter.RECEIPT:
      return data.entryAmount;
    default:
      return 0;
  }
};

const getCreatorName = (data, filter) => {
  switch (filter) {
    case Filter.SALES:
    case Filter.CREDIT_NOTE:
      return data.createdBy;
    default:
      return data.creator?.name;
  }
};

const showSubLedger = (allLedgers, item, filter) => {
  if (filter === Filter.JOURNAL) {
    const subLedgerName = item.subLedgerName ? ` (${item.subLedgerName})` : "";
    return `${allLedgers.find((gl) => gl.id === item.ledgerId)?.ledgerName || ""}${subLedgerName}`;
  }
  return `${allLedgers.find((gl) => gl.id === item.ledgerId)?.ledgerName || ""}`;
};

const HideAmount = [Filter.CONTRA, Filter.JOURNAL];
const header = ["Ledger Name", "Debit", "Credit"];
const Header = () => (
  <Box display="flex" borderBottom="0.5px solid grey">
    {header.map((item) => (
      <Typography
        sx={{
          "&:first-child": {
            flexGrow: 1
          },
          flexBasis: "100px"
        }}
        p="5px"
        key={item}
        fontWeight="bold"
      >
        {item}
      </Typography>
    ))}
  </Box>
);

const getLedgerTransactionItems = (data) => {
  if (
    [StockTransactionType.PURCHASE, StockTransactionType.PURCHASE_RETURN].includes(
      data.transactionType
    )
  )
    return data.ledgerTransactions;
  return data.document || [];
};

export default function VoucherItemView({
  data,
  onClose,
  filter
}: VoucherItemView): JSX.Element | null {
  const allLedgers = useGetAllChildLedgers();
  if (!data) return null;
  const voucherDate = data?.date;
  const beforeTable = [
    { label: tl("accounts.voucherNumber"), value: data.voucherNumber },
    { label: tl("accounts.voucherDate"), value: convertADtoBS(new Date(data.date)).formatted4 },
    ...(filter === Filter.PURCHASE && voucherDate
      ? [
          {
            label: tl("accounts.invoiceDate"),
            value: convertADtoBS(new Date(voucherDate)).formatted4
          }
        ]
      : []),
    ...(!HideAmount.includes(filter as Filter)
      ? [
          {
            label: tl("billing.amount"),
            value: rupeeDisplay(getAmountValue(data, filter)) || 0
          }
        ]
      : []),
    ...(![Filter.CONTRA, Filter.JOURNAL].includes(filter as Filter)
      ? [{ label: tl("accounts.ledgerName"), value: data.subLedgerName || "" }]
      : [])
  ];
  const afterTable = [
    { label: tl("accounts.narration"), value: data.remarks },
    ...(![Filter.PURCHASE, Filter.DEBIT_NOTE].includes(filter as Filter)
      ? [{ label: tl("accounts.createdBy"), value: getCreatorName(data, filter) || "" }]
      : []),
    ...([Filter.PAYMENT, Filter.CONTRA, Filter.RECEIPT].includes(filter as Filter) &&
    data?.paymentMethod === paymentOptions.cheque
      ? [{ label: tl("accounts.chequeNumber"), value: data.chequeNumber }]
      : [])
  ];
  const ledgerTransactionItems = getLedgerTransactionItems(data);
  return (
    <Panel
      onClose={onClose}
      title={getVoucherTitle(filter)}
      printButton={
        <PrintVoucher
          voucherTitle={getVoucherTitle(filter)}
          voucherData={data}
          filter={filter}
          getLedgerTransactionItems={getLedgerTransactionItems}
          showSubLedger={showSubLedger}
          createdBy={getCreatorName(data, filter)}
        />
      }
    >
      <Box m={5}>
        {beforeTable.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box display="flex" key={index}>
            <Typography fontSize="medium" width="200px">
              {item.label}
            </Typography>
            <Typography>{item.value}</Typography>
          </Box>
        ))}
      </Box>
      {data.document?.length || data.ledgerTransactions?.length ? (
        <Paper sx={{ margin: "40px" }}>
          <Header />
          {ledgerTransactionItems?.map((item, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              display="flex"
            >
              <Typography
                sx={{
                  "&:first-child": {
                    flexGrow: 1
                  }
                }}
                p="5px"
              >
                {showSubLedger(allLedgers, item, filter)}
              </Typography>
              <Typography p="5px" width="100px">
                {item.transactionType === TransactionType.DEBIT && rupeeDisplay(item.amount)}
              </Typography>
              <Typography p="5px" width="100px">
                {item.transactionType === TransactionType.CREDIT && rupeeDisplay(item.amount)}
              </Typography>
            </Box>
          ))}
        </Paper>
      ) : (
        <Typography ml={5}>There is no document in this voucher.</Typography>
      )}
      <Box m={5}>
        {afterTable.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box display="flex" key={index}>
            <Typography fontSize="medium" width="200px">
              {item.label}
            </Typography>
            <Typography>{item.value}</Typography>
          </Box>
        ))}
      </Box>
    </Panel>
  );
}

VoucherItemView.defaultProps = {
  filter: ""
};

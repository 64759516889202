import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { push } from "connected-react-router";
import * as moment from "moment";
import { getToken, removeAuthData } from "../auth/authentication";
import { store } from "../store";
import localStorage from "../helpers/localStorage";
import { turnOnMaintenance } from "../slices/appStatusSlice";
import { notificationAdd } from "../actions/notification";

interface QueryParams {
  [key: string]: unknown;
}
interface Headers {
  [key: string]: string;
}

interface GetCachedParams {
  url: string;
  withToken?: boolean;
  maxAge?: number;
  params?: QueryParams;
  headers?: Headers;
  forceUpdate?: boolean;
}
/**
 *
 * @param url
 * @param expirationInSeconds
 */
const removeExpiredCache = (cacheKey: string, expirationInSeconds: number) => {
  const { localStorage: browserLocalStorage } = window;
  Object.keys(localStorage).forEach((key) => {
    // go through the localStorage data and remove the expired ones matching the keys to the prefix
    if (key.includes(cacheKey)) {
      const dataObj = JSON.parse(browserLocalStorage.getItem(key)!);
      if (
        !dataObj.timestamp ||
        moment(dataObj.timestamp).isAfter(moment().subtract(expirationInSeconds, "seconds"))
      ) {
        return;
      }
      window.localStorage.removeItem(key);
    }
  });
};

const removeExpiredCachedClientsSearch = () => {
  // remove cache older than 10 seconds
  removeExpiredCache("/api/clients/search?q", 10);
};

const headersWithToken = (headers = {}) => ({
  ...headers,
  authorization: `${getToken()}`
});

const handleError = (error: AxiosError) => {
  if (error.response) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: error.response.status,
      message:
        (error.response.data && (error.response.data as { message }).message) ||
        error.response.statusText ||
        error.message,
      data: error.response.data
    });
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ message: error.message });
};

const request = (config: AxiosRequestConfig, withToken: boolean): Promise<AxiosResponse> => {
  const finalConfig = withToken ? { ...config, headers: headersWithToken(config.headers) } : config;
  // eslint-disable-next-line consistent-return
  return Axios.request(finalConfig).catch((error) => {
    const { dispatch } = store;
    if (error.response && [401, 403].includes(error.response.status)) {
      removeAuthData();
      localStorage.removeItem("billCounter");
      dispatch(push("/login"));
    } else if (error.response && error.response.status > 500) {
      dispatch(turnOnMaintenance());
    } else {
      if (error.response && error.response.status === 413) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Data size is too large. Please try again.",
            autoTimeout: true
          })
        );
      }
      return handleError(error);
    }
  }) as Promise<AxiosResponse>;
};

const getCachedResult = (key, maxAge) => {
  let stored = localStorage.getItem(key);
  if (stored) {
    stored = JSON.parse(stored);
    if (
      !stored?.timestamp ||
      !stored?.data ||
      moment(stored.timestamp).add(maxAge, "millisecond").isBefore(moment())
    ) {
      stored = null;
    }
  }
  return stored;
};

export const Get = (
  url: string,
  withToken = true,
  params: QueryParams = {}
): Promise<AxiosResponse> => {
  const config = { url, params, method: "get" as Method };
  return request(config, withToken);
};

export const GetCached = async ({
  url,
  withToken = true,
  maxAge = 12 * 60 * 60 * 1000,
  params = {},
  headers = {},
  forceUpdate = false
}: GetCachedParams): Promise<AxiosResponse<unknown, any> | undefined | unknown> => {
  const trimmedUrl = url.replace(/\s{2,}/g, " ").trim();
  const storeResults = getCachedResult(trimmedUrl, maxAge);
  if (storeResults && !forceUpdate) {
    return storeResults as unknown;
  }
  const config = { url: trimmedUrl, params, method: "get" as Method, headers };
  const result = await request(config, withToken);
  removeExpiredCachedClientsSearch();
  localStorage.setItem(
    trimmedUrl,
    JSON.stringify({
      timestamp: moment().toISOString(),
      ...result
    })
  );
  return result;
};

export const Put = (
  url: string,
  data: Record<string, unknown>,
  withToken = true,
  params: QueryParams = {},
  headers: Headers = {}
): Promise<AxiosResponse> => {
  const config = {
    url,
    params,
    method: "put" as Method,
    data,
    headers
  };
  return request(config, withToken);
};

export const Patch = (
  url: string,
  data: Record<string, unknown>,
  withToken = true,
  params: QueryParams = {},
  headers: Headers = {}
): Promise<AxiosResponse> => {
  const config = {
    url,
    params,
    method: "patch" as Method,
    data,
    headers
  };
  return request(config, withToken);
};

export const Post = (
  url: string,
  data: Record<string, unknown>,
  withToken = true,
  params: QueryParams = {},
  headers: Headers = {}
): Promise<AxiosResponse> => {
  const config = {
    url,
    params,
    method: "post" as Method,
    data,
    headers
  };
  return request(config, withToken);
};

export const Delete = (
  url: string,
  withToken = true,
  params: QueryParams = {},
  headers: Headers = {}
): Promise<AxiosResponse> => {
  const config = {
    url,
    params,
    method: "delete" as Method,
    headers
  };
  return request(config, withToken);
};

const processOptions = (url, options) => {
  let urlMut = url;
  urlMut += "?";
  Object.keys(options).forEach((option) => {
    urlMut += `&${option}=${options[option]}`;
  });
  return encodeURI(urlMut);
};

/**
 * supports crude queries that follow REST paradigm
 * @param resource
 */
export const api = (
  resource: string,
  rootUrl = "/api"
): {
  get: (id) => Promise<AxiosResponse>;
  getAll: (options) => Promise<AxiosResponse>;
  post: (data) => Promise<AxiosResponse>;
  put: (id, data) => Promise<AxiosResponse>;
  delete: (id) => Promise<AxiosResponse>;
} => {
  const resourceUrl = `${rootUrl}/${resource.toLowerCase()}s`;
  return {
    get: async (id) => Get(`${resourceUrl}/${id}`, true),
    getAll: async (options) => Get(processOptions(resourceUrl, options), true),
    post: async (data) => Post(`${resourceUrl}/`, data, true),
    put: async (id, data) => Put(`${resourceUrl}/${id}`, data, true),
    delete: async (id) => Delete(`${resourceUrl}/${id}`, true)
  };
};

import { Typography } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import LabEntryInformation from "../LabEntry/LabEntryInformation";

function EntryHeader(): JSX.Element {
  const client = useSelector((state: RootState) => state.labRecords.entryDraft?.client);
  const serviceProvider = useSelector(
    (state: RootState) => state.labRecords.entryDraft?.serviceProvider
  );
  const createdDate = useSelector((state: RootState) => state.labRecords.entryDraft?.created_at);
  const id = useSelector((state: RootState) => state.labRecords.entryDraft?.id);
  const customer = useSelector((state: RootState) => state.labRecords.entryDraft?.customer);
  const allowPictureUpload = useSelector(
    (state: RootState) => state.resources.resourceCentres && state.resources.resourceCentres[0]
  )?.settings?.clientSettings?.enablePictureUpload;
  const referrers = useSelector((state: RootState) => state.labRecords.entryDraft?.referrers);
  return (
    <div>
      <Typography variant="h6">Lab Test Results</Typography>
      <LabEntryInformation
        client={client}
        serviceProvider={serviceProvider}
        orderedOn={createdDate}
        testId={id}
        customer={customer}
        allowUpload={allowPictureUpload}
        printMode={false}
        referrers={referrers}
      />
    </div>
  );
}

export default React.memo(EntryHeader);

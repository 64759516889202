import { Delete, Get, Patch, Post } from "./apiHelper";
import { DaybookInterface } from "../interfaces/Daybook";

const daybookRoot = "/api/daybook";

export interface DaybookGetResponse {
  daybookRecords: {
    results: DaybookInterface[];
    total: number;
  };
  totalReceipt: number;
  openingBalance: number;
  closingBalance: number;
}

// post daybook data
export const postDaybookData = async (
  data: Partial<DaybookInterface>
): Promise<DaybookInterface> => {
  const response = await Post(daybookRoot, data, true);
  return response.data as DaybookInterface;
};

// patch daybook data
export const patchDaybookData = async (
  id: string | number,
  payload: Partial<DaybookInterface>
): Promise<DaybookInterface> => {
  const response = await Patch(`${daybookRoot}/${id}`, payload, true);
  return response.data as DaybookInterface;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDaybookData = async ({
  page,
  pageSize,
  daybookTrxType,
  from,
  until
}: {
  page: number;
  pageSize: number;
  clientId?: number;
  daybookTrxType?: string;
  from?: Date;
  until?: Date;
}): Promise<DaybookGetResponse> => {
  const params = new URLSearchParams();

  if (from) params.append("from", from.toISOString());
  if (until) params.append("until", until.toISOString());
  if (daybookTrxType) params.append("daybookTrxType", daybookTrxType);
  if (page !== undefined) params.append("page", page.toString());
  if (pageSize !== undefined) params.append("pageSize", pageSize.toString());

  const queryString = params.toString();
  const url = `${daybookRoot}${queryString ? `?${queryString}` : ""}`;

  const response = await Get(url, true);
  return response.data as Promise<DaybookGetResponse>;
};

// get daybook data by id
export const getDaybookEntryById = async (id: string | number): Promise<DaybookInterface> => {
  const response = await Get(`${daybookRoot}/${id}`, true);
  return response.data as DaybookInterface;
};

// daybook category search
export const patchDaybookEntry = async (
  id: string | number,
  payload: Partial<DaybookInterface>
): Promise<DaybookInterface> => {
  const response = await Patch(`${daybookRoot}/${id}`, payload, true);
  return response.data as DaybookInterface;
};

export const deleteDaybookEntry = async (id: string | number): Promise<void> => {
  await Delete(`${daybookRoot}/${id}`, true);
};

import React from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import "./LabStatusChip.scss";
import { t } from "../../components/translate";
import classNames from "../../helpers/classNames";

interface LabStatusChipInterface {
  status: string;
}

const LabStatusChip: React.FC<LabStatusChipInterface> = ({ status }) => {
  const modifiedStatus = status.replace(/ /g, "");
  const statusText = t(`lab.status.${modifiedStatus}`);

  return (
    <Box component="span">
      <Chip
        className={classNames("labStatus", modifiedStatus)}
        size="medium"
        label={
          <Box
            style={{
              whiteSpace: "normal",
              margin: "auto",
              textAlign: "center"
            }}
          >
            {statusText}
          </Box>
        }
      />
    </Box>
  );
};

export default LabStatusChip;

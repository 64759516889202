/* eslint-disable @typescript-eslint/no-use-before-define */
import "prosemirror-view/style/prosemirror.css";
import "prosemirror-tables/style/tables.css";
import React from "react";
import { keymap } from "prosemirror-keymap";
import { baseKeymap, Command, toggleMark } from "prosemirror-commands";
import { DOMParser, DOMSerializer, Fragment, MarkType, Node, Schema } from "prosemirror-model";
import { history, redo, undo } from "prosemirror-history";
import { EditorState, Transaction } from "prosemirror-state";
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  tableNodes
} from "prosemirror-tables";
import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import { ArrowDropDown } from "@mui/icons-material";
import ProseMirror from "./ProseMirror";
import useProseMirror from "./useProsemirror";
import styles from "./style.module.css";
import insertTable from "./commands";

const mySchema = new Schema({
  nodes: {
    text: {
      group: "inline"
    },
    customKeyword: {
      selectable: true,
      draggable: false,
      attrs: { keyword: {} },
      inline: true,
      content: "inline*",
      group: "inline",
      toDOM(node) {
        return ["span", `{{${node.attrs.keyword}}}`];
      },
      parseDOM: [
        {
          tag: "span",
          getAttrs(dom) {
            const re = /{([^}]+)}/g;
            let text;
            // eslint-disable-next-line no-cond-assign
            while ((text = re.exec(dom.innerHTML))) {
              return { keyword: text[1].replace("{", "") };
            }
            return { keyword: "" };
          }
        }
      ]
    },
    paragraph: {
      group: "block",
      content: "inline*",
      toDOM() {
        return ["p", 0];
      },
      parseDOM: [{ tag: "p" }]
    },
    table: {
      content: "tr+",
      toDOM: () => ["table", ["tbody", 0]]
    },
    tr: {
      content: "td+",
      toDOM: () => ["tr", 0]
    },
    td: {
      content: "inline*",
      toDOM: () => ["td", 0]
    },

    doc: {
      content: "block+"
    }
  },

  marks: {
    list: {
      toDOM() {
        return ["li", 0];
      },
      parseDOM: [{ tag: "li" }]
    },
    bulletList: {
      content: "list+",
      group: "block",
      toDOM() {
        return ["ul", 0];
      },
      parseDOM: [{ tag: "ul" }]
    },
    orderedList: {
      content: "list+",
      group: "block",
      toDOM() {
        return ["ol", 0];
      },
      parseDOM: [{ tag: "ol" }]
    },
    alignLeft: {
      toDOM() {
        return ["p", { style: "text-align: left;" }, 0];
      },
      parseDOM: [{ tag: "p", style: "text-align:left" }]
    },
    alignCenter: {
      toDOM() {
        return ["p", { style: "text-align: center;" }, 0];
      },
      parseDOM: [{ tag: "p", style: "text-align:center" }]
    },
    alignRight: {
      toDOM() {
        return ["p", { style: "text-align: right;" }, 0];
      },
      parseDOM: [{ tag: "p", style: "text-align:right" }]
    },
    increaseFontSize: {
      toDOM() {
        return ["span", { style: "font-size: 12px;" }, 0];
      },
      parseDOM: [{ tag: "span", style: "font-size: 12px" }]
    },
    decreaseFontSize: {
      toDOM() {
        return ["span", { style: "font-size: 9px;" }, 0];
      },
      parseDOM: [{ tag: "span", style: "font-size: 9px" }]
    },
    bold: {
      toDOM() {
        return ["strong", 0];
      },
      parseDOM: [{ tag: "strong" }]
    },
    italic: {
      toDOM() {
        return ["em", 0];
      },
      parseDOM: [{ tag: "em" }]
    },

    underline: {
      toDOM() {
        return ["u", 0];
      },
      parseDOM: [{ tag: "u" }]
    },
    link: {
      attrs: { href: {} },
      toDOM(node) {
        return ["a", { href: node.attrs.href }, 0];
      },
      parseDOM: [
        {
          tag: "a",
          getAttrs(dom) {
            return { href: dom.href };
          }
        }
      ],
      inclusive: false
    }
  }
});

export const customSchema = new Schema({
  nodes: mySchema.spec.nodes.append(
    tableNodes({
      tableGroup: "block",
      cellContent: "block+",
      cellAttributes: {}
    })
  ),
  marks: mySchema.spec.marks
});

const toggleBold = toggleMarkCommand(customSchema.marks.bold);
const toggleItalic = toggleMarkCommand(customSchema.marks.italic);
const toggleUnderline = toggleMarkCommand(customSchema.marks.underline);
const toggleList = toggleMarkCommand(customSchema.marks.list);
const toggleOrderedList = toggleMarkCommand(customSchema.marks.orderedList);
const toggleBulletedList = toggleMarkCommand(customSchema.marks.bulletList);
const toggleAlignLeft = toggleMarkCommand(customSchema.marks.alignLeft);
const toggleAlignCenter = toggleMarkCommand(customSchema.marks.alignCenter);
const toggleAlignRight = toggleMarkCommand(customSchema.marks.alignRight);
const makeFontBig = toggleMarkCommand(customSchema.marks.increaseFontSize);
const makeFontSmall = toggleMarkCommand(customSchema.marks.decreaseFontSize);

export const proseMirrorOptions: Parameters<typeof useProseMirror>[0] & { schema: Schema } = {
  schema: customSchema,
  plugins: [
    history(),
    keymap({
      ...baseKeymap,
      "Mod-z": undo,
      "Mod-y": redo,
      "Mod-Shift-z": redo,
      "Mod-b": toggleBold,
      "Mod-i": toggleItalic,
      "Ctrl-q": toggleLink,
      "Shift-Ctrl-8": toggleBulletedList,
      "Shift-Ctrl-9": toggleOrderedList
    })
  ]
};

export const serializeContent = (content: string): Node => {
  const domNode = document.createElement("div");
  domNode.innerHTML = content;
  return DOMParser.fromSchema(proseMirrorOptions.schema).parse(domNode);
};

export const deserializeNode = (node: Fragment): string => {
  const div = document.createElement("div");
  const f = DOMSerializer.fromSchema(proseMirrorOptions.schema).serializeFragment(node);
  div.appendChild(f);
  return div.innerHTML;
};

export const htmlToProseMirror = (html: string): Node => {
  const parser = DOMParser.fromSchema(customSchema);
  return parser.parse(document.createRange().createContextualFragment(html));
};

interface RichTextfieldProps {
  state: EditorState;
  setState: (state: EditorState) => void;
  // eslint-disable-next-line react/require-default-props
  onChange?: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  customKeyWords?: {
    label: string;
    value: string;
  }[];
  minHeight?: number;
  showFontSizeToggleButtons?: boolean;
  editorFontSize?: number;
}

export const RichTextfield = ({
  state,
  customKeyWords,
  setState,
  minHeight = 200,
  showFontSizeToggleButtons = false,
  editorFontSize
}: RichTextfieldProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={styles.proseMirrorMain}>
      {customKeyWords && (
        <Box display="flex" flexDirection="row" marginTop="8px" marginBottom="4px">
          {customKeyWords.map((keyword) => (
            <Typography key={keyword.label}>
              <Box
                className="keywordSelect"
                onClick={() => {
                  insertCustomKeyword(keyword.value, state, (tr: Transaction) =>
                    setState(state.apply(tr))
                  );
                }}
              >
                {keyword.label}
              </Box>
            </Typography>
          ))}
        </Box>
      )}
      <div className={styles.proseMirrorMenu}>
        <Button
          className={styles.bold}
          isActive={isBold(state)}
          onClick={() => toggleBold(state, (tr) => setState(state.apply(tr)))}
        >
          B
        </Button>
        <Button
          className={styles.italic}
          isActive={isItalic(state)}
          onClick={() => toggleItalic(state, (tr) => setState(state.apply(tr)))}
        >
          I
        </Button>
        <Button
          className={styles.underline}
          isActive={isUnderline(state)}
          onClick={() => toggleUnderline(state, (tr) => setState(state.apply(tr)))}
        >
          U
        </Button>
        <Button
          className={styles.link}
          isActive={isLink(state)}
          onClick={() => toggleLink(state, (tr) => setState(state.apply(tr)))}
        >
          Link
        </Button>
        <Button
          className={styles.link}
          isActive={isList(state)}
          onClick={() => toggleList(state, (tr) => setState(state.apply(tr)))}
        >
          <FormatListBulletedIcon color="inherit" />
        </Button>
        <Button
          isActive={isAlignLeft(state)}
          onClick={() => toggleAlignLeft(state, (tr) => setState(state.apply(tr)))}
        >
          <FormatAlignLeftIcon />
        </Button>
        <Button
          isActive={isAlignCenter(state)}
          onClick={() => toggleAlignCenter(state, (tr) => setState(state.apply(tr)))}
        >
          <FormatAlignCenterIcon />
        </Button>
        <Button
          isActive={isAlignRight(state)}
          onClick={() => toggleAlignRight(state, (tr) => setState(state.apply(tr)))}
        >
          <FormatAlignRightIcon />
        </Button>
        {showFontSizeToggleButtons && (
          <>
            <Button
              isActive={isFontDecreased(state)}
              onClick={() => {
                makeFontSmall(state, (tr) => setState(state.apply(tr)));
              }}
            >
              <TextDecreaseIcon />
            </Button>
            <Button
              isActive={isFontIncreased(state)}
              onClick={() => {
                makeFontBig(state, (tr) => setState(state.apply(tr)));
              }}
            >
              <TextIncreaseIcon />
            </Button>
          </>
        )}
        <ButtonGroup ref={anchorRef} aria-label="split button">
          <MuiButton
            sx={{
              border: "1px solid #ccc",
              color: "#000",
              textTransform: "capitalize",
              whiteSpace: "pre",
              fontWeight: 400
            }}
            color="inherit"
            onClick={() =>
              insertTable({
                state,
                dispatch: (tr) => setState(state.apply(tr)),
                rowsCount: 2,
                colsCount: 2,
                withHeaderRow: true,
                cellContent: null
              })
            }
          >
            Insert Table
          </MuiButton>
          <MuiButton
            color="inherit"
            sx={{ border: "1px solid #ccc" }}
            size="small"
            aria-controls={open ? "split-button-menu" : ""}
            aria-expanded={open ? "true" : "false"}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDown />
          </MuiButton>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1
          }}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        addRowAfter(state, (tr) => setState(state.apply(tr)));
                      }}
                    >
                      Add Row After
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        addRowBefore(state, (tr) => setState(state.apply(tr)));
                      }}
                    >
                      Add Row Before
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        deleteRow(state, (tr) => setState(state.apply(tr)));
                      }}
                    >
                      Delete Row
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        addColumnAfter(state, (tr) => setState(state.apply(tr)));
                      }}
                    >
                      Add Column After
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        addColumnBefore(state, (tr) => setState(state.apply(tr)));
                      }}
                    >
                      Add Column Before
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        deleteColumn(state, (tr) => setState(state.apply(tr)));
                      }}
                    >
                      Delete Column
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={styles.proseMirrorContainer}>
        <ProseMirror
          className={styles.proseMirror}
          style={{ minHeight, ...(editorFontSize ? { fontSize: editorFontSize } : {}) }}
          state={state}
          onChange={(e) => {
            setState(e);
          }}
        />
      </div>
    </div>
  );
};

function insertCustomKeyword(keyword, state, dispatch) {
  const type = customSchema.nodes.customKeyword;
  const { $from } = state.selection;
  if (!$from.parent.canReplaceWith($from.index(), $from.index(), type)) return false;
  dispatch(state.tr.replaceSelectionWith(type.create({ keyword })));
  return true;
}

function toggleMarkCommand(mark: MarkType): Command {
  return (state: EditorState, dispatch: ((tr: Transaction) => void) | undefined) =>
    toggleMark(mark)(state, dispatch);
}

function isBold(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.bold);
}

function isItalic(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.italic);
}

function isFontIncreased(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.increaseFontSize);
}

function isFontDecreased(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.decreaseFontSize);
}

function isAlignLeft(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.alignLeft);
}

function isAlignCenter(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.alignCenter);
}

function isAlignRight(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.alignRight);
}

function isUnderline(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.underline);
}

function isList(state: EditorState): boolean {
  return isMarkActive(state, customSchema.marks.list);
}

function isLink(state: EditorState): boolean {
  const { doc, selection } = state;
  return doc.rangeHasMark(selection.from, selection.to, customSchema.marks.link);
}

function toggleLink(state, dispatch) {
  const { doc, selection } = state;
  if (selection.empty) return false;
  let attrs = null;
  if (!doc.rangeHasMark(selection.from, selection.to, customSchema.marks.link)) {
    attrs = { href: prompt("Link to where?", "") };
    if (!attrs.href) return false;
  }

  return toggleMark(customSchema.marks.link, attrs)(state, dispatch);
}

function isMarkActive(state: EditorState, mark: MarkType): boolean {
  const { from, $from, to, empty } = state.selection;
  return empty
    ? !!mark.isInSet(state.storedMarks || $from.marks())
    : state.doc.rangeHasMark(from, to, mark);
}

function Button({
  children,
  onClick,
  isActive,
  className = ""
}: {
  children: React.ReactNode;
  isActive: boolean;
  className?: string;
  onClick: () => void;
}) {
  return (
    <button
      className={className}
      style={{
        backgroundColor: isActive ? "#efeeef" : "#fff",
        color: isActive ? "blue" : "black"
      }}
      onMouseDown={handleMouseDown}
      type="button"
    >
      {children}
    </button>
  );

  function handleMouseDown(e: React.MouseEvent) {
    e.preventDefault(); // Prevent editor losing focus
    onClick();
  }
}

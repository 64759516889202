import { Box } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Route, match as matchInterface } from "react-router";
import { tl } from "../../components/translate";
import BalanceSheet from "../accounts/Reports/BalanceSheet";
import CustomerVendorReport from "../accounts/Reports/CustomerVendorReport";
import DayBook from "../accounts/Reports/DayBook";
import GeneralLedger from "../accounts/Reports/GeneralLedger";
import ProfitLoss from "../accounts/Reports/ProfitLoss";
import TrialBalance from "../accounts/Reports/TrialBalance";
import BankReconciliationReport from "./BankReconciliationReport";
import BillPaymentRecordsReport from "./BillPaymentRecordsReport";
import ClientLedgerReport from "./ClientLedgerReport";
import ClientReport from "./ClientReport";
import CommissionReport from "./CommissionReport";
import CustomerDueReport from "./CustomerDueReport";
import DueReport from "./DueReport";
import HmisLabReport from "./HmisLabReport";
import HmisReport from "./HmisReport";
import IRDReport from "./IRDReport";
import LabReport from "./LabReport";
import OtherReport from "./OtherReports";
import PatientAssessmentsReport from "./PatientAssessments";
import AppointmentReport from "./AppointmentReport";
import ReceiptReport from "./ReceiptReport";
import ReportTypesList from "./ReportTypesList";
import ReportsAccount from "./ReportsAccount";
import ReportsFinancial from "./ReportsFinancial";
import ReportsPatient from "./ReportsPatient";
import ReportsStock from "./ReportsStock";
import messageReport from "./SMSReport";
import SalesByServicesReport from "./SalesByServicesReport";
import SalesReport from "./SalesReport";
import ServiceProviderCharges from "./ServiceProviderCharges";
import StockAuditReport from "./StockAuditReport";
import StockCashFlowReport from "./StockCashFlowReport";
import StockTransactionReport from "./StockTransactionReport";
import CentralMonitoringLabReport from "./SummaryReport/CentralMonitoringLabReport";
import ServiceCenterSummaryReport from "./SummaryReport/ServiceCenterSummaryReport";
import SuperAdminSummaryReport from "./SummaryReport/SuperAdminSummaryReport";
import SupplierLedgerReport from "./SupplierLedgerReport";
import HistoryReport from "./HistoryReport";
import ReminderReport from "./ReminderReport";
import BranchReports from "./BranchReports";
import StockHistoryReport from "./StockHistoryReport";
import StockExpiryReport from "./StockExpiryReport";
import StockLedgerReport from "./StockLedgerReport";
import StockSummaryReport from "./StockSummaryReport";
import LabReferralReport from "./LabReferralReport";
import DaybookReport from "./DaybookReport";

interface ReportsInterface {
  match: matchInterface<{ [x: string]: string }>;
  actions: {
    navigateTo: (url: string) => void;
  };
}

const Reports = ({ match, actions }: ReportsInterface): JSX.Element => (
  <Box overflow="hidden">
    <Box margin="20px 32px">
      {match.params.reportType && (
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="primary"
            href="/reports'"
            onClick={(e) => {
              actions.navigateTo("/reports");
              e.preventDefault();
            }}
          >
            {tl("reports.reports")}
          </Link>
          <Link
            color="primary"
            href="/reports'"
            onClick={(e) => {
              actions.navigateTo(`/reports/${match.params.reportType}`);
              e.preventDefault();
            }}
          >
            {tl(`reports.${match.params.reportType}`)}
          </Link>
          {match.params.reportName && (
            <Box fontWeight={700} color="textPrimary">
              {tl(`reports.${match.params.reportName}`)}
            </Box>
          )}
        </Breadcrumbs>
      )}
    </Box>
    <Box>
      <Route exact path="/reports" component={ReportTypesList} />
      <Route exact path="/reports/account" component={ReportsAccount} />
      <Route exact path="/reports/branchReports" component={BranchReports} />
      <Route exact path="/reports/stock" component={ReportsStock} />
      <Route exact path="/reports/patient" component={ReportsPatient} />
      <Route exact path="/reports/financial" component={ReportsFinancial} />
      <Route exact path="/reports/otherReport" component={OtherReport} />
      <Route exact path="/reports/patient/appointmentReport" component={AppointmentReport} />
      {/* this daybook report is different from account -> daybook, it is a mini account module */}
      <Route exact path="/reports/daybookReport" component={DaybookReport} />

      <Route
        exact
        path="/reports/patient/patientAssessmentReport"
        component={PatientAssessmentsReport}
      />
      <Route
        exact
        path="/reports/financial/serviceProviderChargesReport"
        component={ServiceProviderCharges}
      />
      <Route exact path="/reports/financial/salesReport" component={SalesReport} />
      <Route
        exact
        path="/reports/financial/salesByServiceReport"
        component={SalesByServicesReport}
      />
      <Route exact path="/reports/financial/dueReport" component={DueReport} />
      <Route exact path="/reports/billPaymentRecordsReport" component={BillPaymentRecordsReport} />
      <Route exact path="/reports/financial/receiptReport" component={ReceiptReport} />
      <Route
        exact
        path="/reports/otherReport/serviceCenterSummaryReport"
        component={ServiceCenterSummaryReport}
      />
      <Route exact path="/reports/superAdminSummaryReport" component={SuperAdminSummaryReport} />
      <Route exact path="/reports/labReport" component={LabReport} />
      <Route
        exact
        path="/reports/centralMonitoringLabReport"
        component={CentralMonitoringLabReport}
      />
      <Route exact path="/reports/messageReport" component={messageReport} />
      <Route exact path="/reports/stock/stockAuditReport" component={StockAuditReport} />
      <Route exact path="/reports/stock/stockCashFlowReport" component={StockCashFlowReport} />
      <Route exact path="/reports/stock/stockLedger" component={StockLedgerReport} />
      <Route exact path="/reports/stock/supplierLedgerReport" component={SupplierLedgerReport} />
      <Route exact path="/reports/stock/stockExpiry" component={StockExpiryReport} />
      <Route exact path="/reports/stock/stockHistory" component={StockHistoryReport} />
      <Route exact path="/reports/stock/stockSummary" component={StockSummaryReport} />
      <Route exact path="/reports/irdReport" component={IRDReport} />
      <Route exact path="/reports/financial/commissionReport" component={CommissionReport} />
      <Route exact path="/reports/financial/clientLedger" component={ClientLedgerReport} />
      <Route exact path="/reports/account/balanceSheet" component={BalanceSheet} />
      <Route exact path="/reports/account/trialBalance" component={TrialBalance} />
      <Route exact path="/reports/account/profitLoss" component={ProfitLoss} />
      <Route exact path="/reports/account/generalLedger" component={GeneralLedger} />
      <Route exact path="/reports/customerDueReport" component={CustomerDueReport} />
      <Route exact path="/reports/account/customerReport" component={CustomerVendorReport} />
      <Route exact path="/reports/account/vendorReport" component={CustomerVendorReport} />
      <Route exact path="/reports/account/dayBookReport" component={DayBook} />
      <Route
        exact
        path="/reports/account/bankReconciliation"
        component={BankReconciliationReport}
      />
      <Route exact path="/reports/otherReport/hmis/" component={HmisReport} />
      <Route exact path="/reports/stock/stockTransactions" component={StockTransactionReport} />
      <Route exact path="/reports/otherReport/hmislab" component={HmisLabReport} />
      <Route exact path="/reports/patient/clientReport" component={ClientReport} />
      <Route exact path="/reports/otherReport/history" component={HistoryReport} />
      <Route exact path="/reports/otherReport/labReferralReport" component={LabReferralReport} />
      <Route exact path="/reports/patient/reminders" component={ReminderReport} />

      <Route
        exact
        path="/reports/branchReports/salesByServiceReport"
        render={() => <SalesByServicesReport isBranchReport />}
      />
      <Route
        exact
        path="/reports/branchReports/stockAuditReport"
        render={() => <StockAuditReport isBranchReport />}
      />
      <Route
        exact
        path="/reports/branchReports/dueReport"
        render={() => <DueReport isBranchReport />}
      />
      <Route
        exact
        path="/reports/branchReports/receiptReport"
        render={() => <ReceiptReport isBranchReport />}
      />
    </Box>
  </Box>
);

export default connect(null, (dispatch) => ({
  actions: { navigateTo: (url) => dispatch(push(url)) }
}))(Reports);

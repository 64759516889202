import moment from "moment";
import { TransactionType as StockTransactionType } from "./StockInterfaces";

export interface AccountingGroup {
  id: number;
  accountingGroupName: string;
  childGeneralLedgers: ChildGeneralLedger[];
  groupName?: string;
  parentId?: number;
  balanceRequired?: boolean;
  balance: number;
  code: string;
}

export interface SecondaryLedger {
  id: number;
  code: string;
  secondaryLedgerName: string;
  accountingGroups: AccountingGroup[];
}

export interface PrimaryLedger {
  primaryLedgerName: string;
  secondaryLedgers: SecondaryLedger[];
}

export interface ItemInterface {
  sNo?: number;
  ledgerId: null | number;
  referenceVoucherId?: number | null;
  debtorCreditorId?: null | number;
  debtorCreditorType?: SubLedgerMappingTypes;
  ledgerType: LedgerTypes;
  debit?: number;
  credit?: number;
  vendorType?: string;
  id?: number;
  inputValue: unknown;
  subLedgerMapping: SubLedgerMappingTypes;
  ledgerName?: string;
  subLedgerName?: string;
}

export interface VoucherEntryProps {
  chequeNumber?: string;
  clientId?: number;
  voucherNumber: string;
  date: Date | string;
  document: Partial<ItemInterface>[];
  remarks: string;
  voucherType: string | VoucherTypes;
  totalDrAmt?: number;
  totalCrAmt?: number;
  attachmentId: number | null;
  paymentMethod?: string;
  receiptType?: ReceiptTypes;
  referrerId?: number;
  employeeId?: number;
  supplierId?: number;
  paymentType?: PaymentTypes;
  vendorType?: VendorTypes;
  serviceProviderId?: number;
  entityId?: number;
}

export const LedgerNature = [
  { value: "balancedSheet", label: "BS" },
  { value: "profitLoss", label: "P&L" }
];

export enum NatureOfLedger {
  BALANCE_SHEET = "balancedSheet",
  PROFIT_LOSS = "profitLoss"
}

export enum LedgerType {
  NORMAL_LEDGER = "normalLedger",
  BANK_LEDGER = "bankLedger",
  CASH_LEDGER = "cashLedger",
  DEBTOR_CREDITOR_LEDGER = "debtorCreditorLedger"
}

export type LedgerTypes =
  | LedgerType.BANK_LEDGER
  | LedgerType.CASH_LEDGER
  | LedgerType.DEBTOR_CREDITOR_LEDGER
  | LedgerType.NORMAL_LEDGER;

export enum TransactionType {
  DEBIT = "debit",
  CREDIT = "credit"
}

export type TransactionTypes = TransactionType.CREDIT | TransactionType.DEBIT | "";

export type BankReconciliationInfo = {
  bankName: string;
  bankBranch: string;
  accountNumber: string;
  bankAccountOpeningDate: Date;
};

export enum SubLedgerMapping {
  CUSTOMER = "customer",
  VENDOR = "vendor"
}

export const SubLedgerMappingOptions = [SubLedgerMapping.CUSTOMER, SubLedgerMapping.VENDOR];
export const LedgerTypeOptions = [
  LedgerType.NORMAL_LEDGER,
  LedgerType.BANK_LEDGER,
  LedgerType.CASH_LEDGER,
  LedgerType.DEBTOR_CREDITOR_LEDGER
];

export type SubLedgerMappingTypes = SubLedgerMapping.CUSTOMER | SubLedgerMapping.VENDOR;
export interface ChildGeneralLedger {
  id: number;
  resourceCentreId: number;
  grandParentId: number;
  code: string;
  okhatiIntegrated: boolean;
  ledgerName: string;
  ledgerType: LedgerTypes;
  parentId: number;
  ledgerNature: string;
  validFrom: Date | string;
  validTo: Date | string;
  openingBalance: number;
  transactionType: TransactionTypes;
  openingBalanceDate: Date | string;
  bankAccountNumber: number;
  bankReconciliationFrom: Date | string;
  subLedgerMapping: SubLedgerMapping.CUSTOMER | SubLedgerMapping.VENDOR | "";
  bankName: string;
  bankBranch: string;
  bankRecoInfo: {
    bankName: string;
    bankBranch: string;
    accountNumber: string;
    bankAccountOpeningDate: Date | string;
  };
  deactivated: boolean;
  remarks: string;
  parentLedgerName?: string;
  inputValue?: string;
  subLedgerType?: string | null;
}

export enum PrimaryLedgerNames {
  EQUITY_AND_LIABILITIES = "Equity & Liabilities",
  ASSETS = "Assests",
  INCOME = "Incomes",
  EXPENSES = "Expenses"
}

export interface ExtractInterface {
  equityAndLiabilities: PrimaryLedger;
  assets: PrimaryLedger;
  income: PrimaryLedger;
  expenses: PrimaryLedger;
  isLoading: boolean;
  error: Error;
}

export enum PrimaryLedgers {
  EQUITY_AND_LIABILITIES = "equityAndLiability",
  ASSET = "asset",
  INCOME = "income",
  EXPENSE = "expense"
}

export interface MHeaderInterface {
  headers?: Array<{ key: string; title: string | JSX.Element; goto: string }>;
  url: string;
}

export interface FiscalPeriodProps {
  from: moment.Moment | Date | string;
  to: moment.Moment | Date | string;
  allowPeriod: boolean;
  resourceCentreId: number;
  localCalendar: boolean;
  fiscalStartMonth: number;
  id: number;
}
export enum VendorCodes {
  SUPPLIER = "10301001",
  SERVICE_PROVIDER = "10301002",
  EMPLOYEE = "10302003",
  OTHER = "10301003"
}

export enum SubLedgerTypes {
  SUPPLIER = "supplier",
  SERVICE_PROVIDER = "serviceProvider",
  EMPLOYEE = "employee",
  REFERRER = "referrer"
}

export enum CustomerType {
  CLIENT = "client"
}

export enum ClientCodes {
  CLIENT = "20302001"
}

export enum ProductCodes {
  PURCHASE_LEDGER = "40101001",
  SALES_LEDGER = "30101002"
}

export enum ServiceCodes {
  OTHER_INCOME = "30103001"
}

export enum KnownLedgers {
  VAT_ON_SALES = "10303001"
}

export enum AccountTypes {
  SAVING = "saving",
  CURRENT = "current"
}

type AccountType = AccountTypes.CURRENT | AccountTypes.SAVING;

export interface BankInfoProps {
  accountType: AccountType | "";
  bankAccountNumber: string;
  bankBranch: string;
  bankName: string;
  ifscCode: string;
  nameOfBeneficiary: string;
  swiftCode: string;
  sNo?: number;
}
export interface JournalVouchers {
  id: number;
  resourceCentreId: number;
  branchId: number;
  voucherNumber: string;
  date: Date;
  document: Partial<ItemInterface>[];
  remarks: string;
  attachmentId: number;
  creator: { userId: number; name: string };
}

export enum TrackingNumType {
  SALES = "SL",
  CREDIT_NOTE = "CN",
  PURCHASE = "PR",
  DEBIT_NOTE = "DN",
  PAYMENT = "PM",
  RECEIPT = "RC",
  JOURNAL = "JV",
  CONTRA = "CO"
}
export enum VoucherCodes {
  SL = "SL",
  CN = "CN",
  PR = "PR",
  DN = "DN",
  PM = "PM",
  RC = "RC",
  JV = "JV",
  CO = "CO"
}

export interface TrackingNumber {
  nextNumber: string;
}

export enum VoucherType {
  JOURNAL_VOUCHER = "journalVoucher",
  PAYMENT_VOUCHER = "paymentVoucher",
  RECEIPT_VOUCHER = "receiptVoucher",
  CONTRA_VOUCHER = "contraVoucher"
}

export type VoucherTypes =
  | VoucherType.JOURNAL_VOUCHER
  | VoucherType.PAYMENT_VOUCHER
  | VoucherType.RECEIPT_VOUCHER;
export interface AccountingVoucherSeriesInterface {
  id: number;
  voucherName: string;
  voucherCode: string;
  fiscalYear: string | Date;
  customPrefix: string;
  startingVoucher: string | number;
  endVoucher: string | Date;
  digitsCount: number;
  warnOnChange: boolean;
}

export enum PaymentType {
  VENDOR_PAYMENT = "vendor",
  OTHER_PAYMENT = "other"
}

export type PaymentTypes = PaymentType.VENDOR_PAYMENT | PaymentType.OTHER_PAYMENT;

export enum VendorType {
  SUPPLIER = "supplier",
  SERVICE_PROVIDER = "serviceProvider",
  EMPLOYEE = "employee",
  REFERRER = "referrer"
}

export enum TypeOfCustomer {
  SUPPLIER = "supplier",
  CLIENT = "client",
  OTHER = "other"
}

export type TypeOfCustomers =
  | TypeOfCustomer.CLIENT
  | TypeOfCustomer.SUPPLIER
  | TypeOfCustomer.OTHER;

export enum ReceiptType {
  VENDOR = "vendor",
  CUSTOMER = "customer",
  OTHER = "other"
}

export type ReceiptTypes = ReceiptType.CUSTOMER | ReceiptType.OTHER | ReceiptType.VENDOR;

export type VendorTypes =
  | VendorType.EMPLOYEE
  | VendorType.SERVICE_PROVIDER
  | VendorType.REFERRER
  | VendorType.SUPPLIER;

export enum Columns {
  ACCOUNT_DESCRIPTION = "accountDescription",
  REFERENCE_VOUCHER = "referenceVoucher",
  DEBIT = "debit",
  CREDIT = "credit"
}

export interface VoucherInterface {
  chequeNo?: string;
  voucherNumber: string;
  date: Date | string;
  document: Partial<ItemInterface>[];
  remarks: string;
  voucherType: string | VoucherTypes;
  amount: number;
  attachmentId: number | null;
  paymentMethod?: string;
  paymentType?: PaymentTypes;
  vendorType?: VendorTypes;
  entityId?: number;
  ledgerTransactions?: Partial<ItemInterface>[];
}

export enum PaymentOptions {
  cash = "cash",
  card = "card",
  cheque = "cheque",
  onlinePayment = "onlinePayment",
  balance = "balance",
  bankTransfer = "bankTransfer",
  khalti = "khalti",
  eSewa = "eSewa",
  fonePay = "fonePay"
}

export interface CustomerVendorReportProps {
  credit: number;
  debit: number;
  document: unknown[];
  openingBalance: boolean;
  referenceNumber: string;
  remarks: string;
  sourceLedgerName: string;
  subLedgerId: number;
  subLedgerName: string;
  voucherCode: string;
  voucherId: number;
  voucherNumber: string;
}

export enum HiddenLedgerCodes {
  PROFIT_AND_LOSS = "10103001",
  ROUND_OFF = "40105002",
  TRADE_DISCOUNT_ON_SALE = "30101003",
  ADVANCE_RECEIVED_FROM_CUSTOMERS = "10302001",
  ADVANCES_TO_VENDORS = "20306001"
}

export interface PastFiscalPeriod {
  allowPeriod: boolean;
  closureDate: Date | string;
  from: Date | string;
  id: number;
  lastActivity: {
    entity: string;
    entityId: number;
    event: string;
    id: number;
    message: string;
    resourceCentreId: number;
    userId: number;
    username: string;
  };
  resourceCentreId: number;
  to: Date | string;
  isDirty?: boolean;
  status?: string;
}

export interface ClosureBalance {
  balance: number;
  branchId: number;
  entityId: number;
  entityName: string;
  fiscalPeriodId: number;
  id: number;
  resourceCentreId: number;
  ledgerCode: string;
}

export enum AppliedAccount {
  PURCHASE = StockTransactionType.PURCHASE,
  PURCHASE_RETURN = StockTransactionType.PURCHASE_RETURN,
  INTERNAL_USE = StockTransactionType.INTERNAL_USE,
  INTERNAL_RETURN = StockTransactionType.INTERNAL_RETURN
}

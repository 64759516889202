import { Get } from "./apiHelper";
import { SubscriptionStatusType } from "../containers/ResourceCentre";

interface SummaryData {
  [key: string]: number | string;
}

const getSummaryReports = async ({
  id,
  from,
  until,
  subscriptionStatus,
  showInSingleRow = false
}: {
  id: number | null;
  from: Date;
  until: Date;
  subscriptionStatus?: SubscriptionStatusType;
  showInSingleRow?: boolean;
}): Promise<SummaryData[]> => {
  const response = await Get(
    `/api/reports/stat?resourceCentreIds=${id}&from=${from.toISOString()}&until=${until.toISOString()}&showInSingleRow=${showInSingleRow}${
      subscriptionStatus ? `&subscriptionStatus=${subscriptionStatus}` : ""
    }`,
    true
  );
  return response.data as SummaryData[];
};

export default getSummaryReports;

import React, { useEffect, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import produce from "immer";
import ServiceProviderSelect from "../../ResourceCentre/ModuleSettings/ServiceProviderSelect";
import { RootState } from "../../../store";
import { useAuthorizerHandler } from "./EntryActions";
import { useAppSelector } from "../../../store/hooks";
import { spFromIdSelector } from "../../../reducers/serviceProvider";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { LabStatuses } from "../../../interfaces/Lab";

export interface BaseAuthorizedSpObject {
  currentIdSP1: number | null;
  currentIdSP2: number | null;
  currentIdSP3: number | null;
  currentIdSP4: number | null;
  finalAuthorizedSP1: number | null;
  finalAuthorizedSP2: number | null;
  finalAuthorizedSP3: number | null;
  finalAuthorizedSP4: number | null;
  authorizedSP1Dynamic: string | null;
  authorizedSP2Dynamic: string | null;
  authorizedSP3Dynamic: string | null;
  authorizedSP4Dynamic: string | null;
}

export interface AuthorizedSpIds extends BaseAuthorizedSpObject {
  authorizedSP1Id: number | null;
  authorizedSP2Id: number | null;
  authorizedSP3Id: number | null;
  authorizedSP4Id: number | null;
}

interface AuthorizedSpState extends BaseAuthorizedSpObject {
  authorizedSP1: ServiceProvider | null;
  authorizedSP2: ServiceProvider | null;
  authorizedSP3: ServiceProvider | null;
  authorizedSP4: ServiceProvider | null;
}

interface SignatureSelectorProps {
  label: string;
  serviceProvider: ServiceProvider | null;
  onServiceProviderChange: (value: ServiceProvider | null) => void;
  dynamicServiceProvider: string | null;
  onDynamicServiceProviderChange: (value: string | null) => void;
}

const labSignatureDynamicOptions = ["Entered by", "Assessed by", "Approved by"];

const SignatureSelector = ({
  label,
  serviceProvider,
  onServiceProviderChange,
  dynamicServiceProvider,
  onDynamicServiceProviderChange
}: SignatureSelectorProps) => (
  <Box display="flex" alignItems="center" mt={2}>
    <Typography sx={{ width: "500px" }}>{label}</Typography>
    <ServiceProviderSelect
      dataTestmation="authorizedSP1"
      value={serviceProvider}
      onChange={(v) => {
        onServiceProviderChange(v);
      }}
    />
    <Typography pl={3} pr={3}>
      OR
    </Typography>
    <Autocomplete
      options={labSignatureDynamicOptions}
      onChange={(_, v) => {
        onDynamicServiceProviderChange(v);
      }}
      value={dynamicServiceProvider}
      style={{ width: "100%" }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} />}
    />
  </Box>
);

const isLabResultNotReady = (status: LabStatuses): boolean =>
  [
    LabStatuses.SAMPLE_TAKEN,
    LabStatuses.ORDERED,
    LabStatuses.INCOMPLETE_RESULT,
    LabStatuses.PENDING_APPROVAL
  ].includes(status || "");

interface Props {
  onSpChange: (value: AuthorizedSpIds) => void;
}

const LabSpecificSpSignature = ({ onSpChange }: Props): JSX.Element => {
  const entryDraft = useAppSelector((state) => state.labRecords.entryDraft);
  const serviceProviders: ServiceProvider[] = useAppSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );

  const {
    authorizedSP1Dynamic,
    authorizedSP2Dynamic,
    authorizedSP3Dynamic,
    authorizedSP4Dynamic,
    finalAuthorizedSP1,
    finalAuthorizedSP2,
    finalAuthorizedSP3,
    finalAuthorizedSP4,
    currentIdSP1,
    currentIdSP2,
    currentIdSP3,
    currentIdSP4
  } = useAuthorizerHandler(entryDraft);

  const initialState = {
    authorizedSP1:
      spFromIdSelector(
        serviceProviders,
        isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP1 : currentIdSP1
      ) || null,
    authorizedSP2:
      spFromIdSelector(
        serviceProviders,
        isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP2 : currentIdSP2
      ) || null,
    authorizedSP3:
      spFromIdSelector(
        serviceProviders,
        isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP3 : currentIdSP3
      ) || null,
    authorizedSP4:
      spFromIdSelector(
        serviceProviders,
        isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP4 : currentIdSP4
      ) || null,
    authorizedSP1Dynamic,
    authorizedSP2Dynamic,
    authorizedSP3Dynamic,
    authorizedSP4Dynamic,
    currentIdSP1: currentIdSP1 || null,
    currentIdSP2: currentIdSP2 || null,
    currentIdSP3: currentIdSP3 || null,
    currentIdSP4: currentIdSP4 || null,
    finalAuthorizedSP1: isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP1 : currentIdSP1,
    finalAuthorizedSP2: isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP2 : currentIdSP2,
    finalAuthorizedSP3: isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP3 : currentIdSP3,
    finalAuthorizedSP4: isLabResultNotReady(entryDraft?.status) ? finalAuthorizedSP4 : currentIdSP4
  };

  const [labSp, setLabSp] = useState<AuthorizedSpState>(initialState);

  useEffect(() => {
    onSpChange({
      ...labSp,
      authorizedSP1Id: isLabResultNotReady(entryDraft?.status)
        ? labSp.finalAuthorizedSP1
        : labSp.currentIdSP1,
      authorizedSP2Id: isLabResultNotReady(entryDraft?.status)
        ? labSp.finalAuthorizedSP2
        : labSp.currentIdSP2,
      authorizedSP3Id: isLabResultNotReady(entryDraft?.status)
        ? labSp.finalAuthorizedSP3
        : labSp.currentIdSP3,
      authorizedSP4Id: isLabResultNotReady(entryDraft?.status)
        ? labSp.finalAuthorizedSP4
        : labSp.currentIdSP4
    });
  }, [labSp]);

  useEffect(() => {
    setLabSp(initialState);
  }, [entryDraft]);

  return (
    <Box my={2}>
      <Typography fontWeight={600}>Signature Settings For This Lab Test</Typography>
      <SignatureSelector
        label="Authorized Signature 1"
        serviceProvider={labSp.authorizedSP1}
        onServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP1 = v;
            draft.finalAuthorizedSP1 = v?.id || null;
            draft.currentIdSP1 = v?.id || null;
          });
          setLabSp(newValue);
        }}
        dynamicServiceProvider={labSp.authorizedSP1Dynamic}
        onDynamicServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP1Dynamic = v;
          });
          setLabSp(newValue);
        }}
      />
      <SignatureSelector
        label="Authorized Signature 2"
        serviceProvider={labSp.authorizedSP2}
        onServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP2 = v;
            draft.finalAuthorizedSP2 = v?.id || null;
            draft.currentIdSP2 = v?.id || null;
          });
          setLabSp(newValue);
        }}
        dynamicServiceProvider={labSp.authorizedSP2Dynamic}
        onDynamicServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP2Dynamic = v;
          });
          setLabSp(newValue);
        }}
      />
      <SignatureSelector
        label="Authorized Signature 3"
        serviceProvider={labSp.authorizedSP3}
        onServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP3 = v;
            draft.finalAuthorizedSP3 = v?.id || null;
            draft.currentIdSP3 = v?.id || null;
          });
          setLabSp(newValue);
        }}
        dynamicServiceProvider={labSp.authorizedSP3Dynamic}
        onDynamicServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP3Dynamic = v;
          });
          setLabSp(newValue);
        }}
      />
      <SignatureSelector
        label="Authorized Signature 4"
        serviceProvider={labSp.authorizedSP4}
        onServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP4 = v;
            draft.finalAuthorizedSP4 = v?.id || null;
            draft.currentIdSP4 = v?.id || null;
          });
          setLabSp(newValue);
        }}
        dynamicServiceProvider={labSp.authorizedSP4Dynamic}
        onDynamicServiceProviderChange={(v) => {
          const newValue = produce(labSp, (draft) => {
            draft.authorizedSP4Dynamic = v;
          });
          setLabSp(newValue);
        }}
      />
    </Box>
  );
};

export default LabSpecificSpSignature;

import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import React, { JSX, useState } from "react";
import { IBookableResource } from "../../interfaces/BookableResources";
import ResourcePanel from "./ResourcePanel";

interface Props {
  value: IBookableResource | null;
  onChange: (value: IBookableResource | null) => void;
  bookableResources: IBookableResource[];
}
const filter = createFilterOptions<IBookableResource>();

const BookableResourceSelectCreate = ({
  value,
  onChange,
  bookableResources
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState("");
  const [openCreatePanel, toggleOpenCreatePanel] = useState(false);
  const [newName, setNewName] = useState("");

  return (
    <>
      <Autocomplete
        fullWidth
        disableClearable
        inputValue={inputValue}
        onInputChange={(_, newValue) => setInputValue(newValue)}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.name;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            label="Bookable Resource"
            placeholder="Search and select bookable resources"
            margin="dense"
            fullWidth
          />
        )}
        value={value || null}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            toggleOpenCreatePanel(true);
          } else if (newValue && newValue.inputValue) {
            toggleOpenCreatePanel(true);
          } else {
            onChange(newValue);
          }
        }}
        options={bookableResources}
        filterOptions={(options, params) => {
          const filtered: Partial<IBookableResource & { inputValue: string }>[] = filter(
            options,
            params
          );

          if (params.inputValue !== "") {
            setNewName(params.inputValue);
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`
            });
          }

          return filtered;
        }}
      />
      {openCreatePanel && (
        <ResourcePanel
          handleClose={(data) => {
            toggleOpenCreatePanel(false);
            if (data) {
              onChange(data);
            }
          }}
          title="Create Resource"
          newNameToCreate={newName}
        />
      )}
    </>
  );
};

export default BookableResourceSelectCreate;

import * as React from "react";
import { connect, useSelector } from "react-redux";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import { Box, Typography, ButtonGroup, Button, Checkbox, FormControlLabel } from "@mui/material";
import Close from "@mui/icons-material/Close";
import queryString from "query-string";
import { chain, round } from "mathjs";
import produce from "immer";
import {
  serviceProviderActions,
  getServices,
  createServices,
  updateServices,
  getService,
  updateServiceUnderPackage
} from "../../actions";
import { getBatchLabTests } from "../../actions/assessments";
import * as NotificationActions from "../../actions/notification";
import { removeGeneralErrorByKey } from "../../actions/error";
import { tl } from "../../components/translate";
import Panel from "../../components/Panel";
import CommonServiceFields from "./CommonServiceFields";
import RateRow from "./RateRow";
import Footer from "./Footer";
import "./ServiceList.scss";
import styles from "./Service.module.css";
import {
  spFullNameSelector,
  spFromIdSelector,
  serviceProvidersSortedSelector
} from "../../reducers/serviceProvider";
import {
  MedicineInterface,
  ProductInterface,
  ProductType,
  ServiceProviderRate,
  SpecialLabCategories,
  TaxationInterface
} from "../../interfaces/ProductInterface";
import Can from "../Policy/Can";
import { IThunkDispatch, RootState } from "../../store";
import { getStockProducts } from "../../actions/stockProductActions";
import useCan from "../../hooks/useCan";
import { findByCode } from "../accounts/hooks";
import { ServiceCodes } from "../../interfaces/Accounts";
import { getTaxationOptions } from "../Stock/StockCreateEdit/CreateStockProduct";
import { Test } from "../../interfaces/Lab";
import useTaxations from "../../hooks/useTaxations";
import { getCategory, specialLabCategories } from "./ServiceList";
import { commonErrorMessage } from "../../helpers/messages";
import DepartmentSelect from "../../components/DepartmentSelect";
import { ServiceActions } from "../../interfaces/ActionTypes";
import { SSF_SERVICE_ORIGIN } from ".";

export const calculateServicePriceExVAT = (
  priceIncTax: number | string,
  taxRate: string | number
): string =>
  round(
    chain(priceIncTax)
      .divide(1 + Number(taxRate) / 100)
      .done(),
    2
  );

export interface StateProductinfo {
  publicBooking: boolean;
  productName: string;
  productPrice: number;
  vatPct: number;
  servicePriceExVAT: number;
  productUnits: string;
  productType: ProductType | SpecialLabCategories;
  isTaxable: false;
  materialCharge: number;
  serviceProviderRateUnit: string;
  serviceProviderId?: number | null;
  info?: MedicineInterface;
  productisableId?: number | null;
  productisableName?: "labTestGroup" | "medicine" | "";
  labCharge?: number;
  salesLedgerId?: number | null;
  salesTaxationId?: number;
  specialLabTest?: string;
  departmentId?: number;
  publicDescription?: string;
  instructions?: string;
  handledByOkhati?: boolean;
  assignedRc?: number;
  document: {
    rates: Array<ServiceProviderRate | { serviceProviderId: string; rate: string }>;
  };
  origin: string | null;
}

export const findServicePriceExVat = (
  updatedKeys: string[],
  productData: StateProductinfo,
  updates: string | { [key: string]: string },
  isAccountSubscribed: boolean,
  vatRuleOptions: TaxationInterface[]
): string => {
  if (updatedKeys.includes("vatPct") || updatedKeys.includes("salesTaxationId")) {
    if (isAccountSubscribed) {
      const selectedVatRule = vatRuleOptions.find((rule) => rule.id === updates.salesTaxationId);
      return calculateServicePriceExVAT(productData.productPrice, selectedVatRule.rate);
    }
    return calculateServicePriceExVAT(productData.productPrice, updates.vatPct);
  }
  return calculateServicePriceExVAT(updates.productPrice, productData.vatPct);
};

interface CreateProductInterface {
  actions: {
    goBack: (
      isPublicTab: boolean,
      isSsfTab: boolean,
      productId?: number,
      packgeId?: number
    ) => void;
    loadServiceProviders: (id: number) => void;
    navigateTo: (url: string) => void;
    createService: ({
      data,
      createNewAfter,
      isPublicTab,
      isOpd,
      onSuccess
    }: {
      data: Partial<MedicineInterface | ProductInterface>;
      createNewAfter: boolean;
      isPublicTab: boolean;
      isOpd?: boolean;
      onSuccess: () => void;
    }) => void;
    updateService: ({
      data,
      isPublicTab,
      redirectAfterUpdate
    }: {
      data: Partial<MedicineInterface | ProductInterface>;
      isPublicTab: boolean;
      redirectAfterUpdate: boolean;
    }) => void;
    updateServiceUnderPackage: ({
      data,
      packageId,
      isPublicTab,
      redirectAfterUpdate
    }: {
      data: Partial<MedicineInterface | ProductInterface>;
      isPublicTab: boolean;
      redirectAfterUpdate: boolean;
      packageId: string;
    }) => void;
    getService: (id: number) => void;
    loadServices: () => void;
    loadStockProducts: (query) => void;
    getLabTests: () => void;
    createSuccessNotification: () => void;
    goToStockServices: () => void;
  };
  resourceCentreId: number;
  serviceProviders: [];
  productId?: number;
  product: ProductInterface & { salesLedgerId: number };
  labTests: Array<Test>;
  handleClose: () => void;
  location: { state: { filter: string } };
  isAccount: boolean;
  isPublicTab?: boolean;
  isOpd?: boolean;
  serviceName?: string;
  redirectAfterUpdate?: boolean;
  productUnderPackage?: any;
  isForEditingServiceUnderPackage?: boolean;
  packageId?: number;
  setActivePackageProduct?: React.Dispatch<React.SetStateAction<any>>;
  isSsfTab?: boolean;
}

const usedSP = (productData) =>
  productData.document?.rates?.map((rate) => rate.serviceProviderId).filter((id) => Boolean(id));

const CreateProduct: React.FC<CreateProductInterface> = (props) => {
  const {
    resourceCentreId,
    serviceProviders,
    actions,
    productId,
    product,
    labTests,
    handleClose,
    isAccount = false,
    isPublicTab = false,
    isOpd = false,
    serviceName = "",
    redirectAfterUpdate = true,
    productUnderPackage,
    packageId,
    isForEditingServiceUnderPackage = false,
    setActivePackageProduct,
    isSsfTab = false
  } = props;

  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );

  const taxations = useTaxations();
  const { salesTaxationOptions } = getTaxationOptions(taxations);
  const serviceLedger = findByCode(ServiceCodes.OTHER_INCOME);
  const [isServiceProviderSingle, setisServiceProviderSingle] = React.useState(false);
  const [productData, setProductData] = React.useState<StateProductinfo>({
    productName: serviceName || "",
    productPrice: 0,
    vatPct: 0,
    servicePriceExVAT: 0,
    productUnits: "pcs",
    productType: isOpd ? ProductType.labTest : ProductType.consultation,
    isTaxable: false,
    publicBooking: false,
    materialCharge: 0,
    serviceProviderRateUnit: "percentage",
    serviceProviderId: null,
    productisableId: null,
    productisableName: "",
    labCharge: 0,
    salesLedgerId: null,
    publicDescription: "",
    instructions: "",
    specialLabTest: "",
    handledByOkhati: false,
    document: {
      rates: []
    },
    origin: null
  });

  React.useEffect(() => {
    if (serviceLedger && isAccountSubscribed) {
      setProductData({ ...productData, salesLedgerId: serviceLedger.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountSubscribed, serviceLedger]);

  const [title, setTitle] = React.useState<JSX.Element>(null);
  const [saving, setSaving] = React.useState(false);
  const [showDepartmentField, setShowDepartmentField] = React.useState(false);
  const panelHeader = () => {
    if (productId) {
      return setTitle(tl("services.editService"));
    }

    if (isForEditingServiceUnderPackage) {
      return setTitle(tl("services.editServiceUnderPackage"));
    }

    return setTitle(tl("services.createService"));
  };

  React.useEffect(() => {
    actions.loadServiceProviders(resourceCentreId);
    panelHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (productData.productType === "labTest") actions.getLabTests();
  }, [productData.productType, actions]);

  const [createNewAfter, setCreateNewAfter] = React.useState(false);

  const createNewAfterToggler = () => {
    setCreateNewAfter(!createNewAfter);
  };

  React.useEffect(() => {
    if (window.location) {
      if (queryString.parse(window.location.search).createAnotherAfterSave) {
        setCreateNewAfter(true);
      } else {
        setCreateNewAfter(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  React.useEffect(() => {
    if (productId && !isAccount) {
      actions.getService(productId);
    }
  }, [productId, isAccount, actions]);

  const { location } = props;

  React.useEffect(() => {
    if (location?.state) {
      const { filter } = location.state;
      if (Object.values(ProductType).includes(filter as ProductType)) {
        setProductData({ ...productData, productType: filter as ProductType });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (productId && product) {
      setProductData({
        publicBooking: product.publicBooking,
        productName: product.name,
        productPrice: product.grossTotalPrice ? product.grossTotalPrice : 0,
        vatPct: product.vatPct ? product.vatPct : 0,
        servicePriceExVAT:
          Number(product.vatPct) !== 0 ? product.servicePriceExcVAT : product.grossTotalPrice || 0,
        productUnits: product.unit,
        productType: getCategory(product.category),
        isTaxable: false,
        materialCharge: product.materialCharge ? product.materialCharge : 0,
        serviceProviderRateUnit: product.serviceProviderRateUnit
          ? product.serviceProviderRateUnit
          : "percentage",
        serviceProviderId: product.serviceProviderId,
        info: product.info,
        productisableId: product.productisableId,
        productisableName: product.productisableName,
        labCharge: product.labCharge || 0,
        salesLedgerId: product.salesLedgerId || null,
        salesTaxationId: product.salesTaxationId || null,
        departmentId: product.departmentId,
        publicDescription: product.publicDescription,
        instructions: product.instructions,
        specialLabTest: specialLabCategories.includes(product.category) ? product.category : "",
        handledByOkhati: product.handledByOkhati,
        document: {
          ...product?.document,
          rates: product?.document?.rates || []
        },
        origin: product?.origin || null
      });
      setisServiceProviderSingle(Boolean(product.serviceProviderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  React.useEffect(() => {
    if (productUnderPackage && isForEditingServiceUnderPackage) {
      setProductData({
        publicBooking: productUnderPackage.publicBooking,
        productName: productUnderPackage.name,
        productPrice: productUnderPackage.grossTotalPrice ? productUnderPackage.grossTotalPrice : 0,
        vatPct: productUnderPackage.vatPct ? productUnderPackage.vatPct : 0,
        servicePriceExVAT:
          Number(productUnderPackage.vatPct) !== 0
            ? productUnderPackage.servicePriceExcVAT
            : productUnderPackage.grossTotalPrice || 0,
        productUnits: productUnderPackage.unit,
        productType: getCategory(productUnderPackage.category),
        isTaxable: false,
        materialCharge: productUnderPackage.materialCharge ? productUnderPackage.materialCharge : 0,
        serviceProviderRateUnit: productUnderPackage.serviceProviderRateUnit
          ? productUnderPackage.serviceProviderRateUnit
          : "percentage",
        serviceProviderId: productUnderPackage.serviceProviderId,
        info: productUnderPackage.info,
        productisableId: productUnderPackage.productisableId,
        productisableName: productUnderPackage.productisableName,
        labCharge: productUnderPackage.labCharge || 0,
        salesLedgerId: productUnderPackage.salesLedgerId || null,
        salesTaxationId: productUnderPackage.salesTaxationId || null,
        departmentId: productUnderPackage.departmentId,
        publicDescription: productUnderPackage.publicDescription,
        instructions: productUnderPackage.instructions,
        specialLabTest: specialLabCategories.includes(productUnderPackage.category)
          ? productUnderPackage.category
          : "",
        handledByOkhati: productUnderPackage.handledByOkhati,
        document: {
          rates: productUnderPackage.document.rates || []
        },
        origin: productUnderPackage.origin || null
      });
      setisServiceProviderSingle(Boolean(productUnderPackage.serviceProviderId));
    }
  }, [isForEditingServiceUnderPackage, productUnderPackage]);

  const resetForm = () => {
    setProductData({
      publicBooking: false,
      productName: "",
      productPrice: 0,
      vatPct: 0,
      servicePriceExVAT: 0,
      productUnits: "pcs",
      productType: createNewAfter ? productData.productType : ProductType.consultation,
      isTaxable: false,
      rates: [],
      materialCharge: 0,
      serviceProviderRateUnit: "percentage",
      serviceProviderId: null,
      productisableId: null,
      productisableName: null,
      labCharge: 0,
      salesLedgerId: serviceLedger && isAccountSubscribed ? serviceLedger.id : null,
      publicDescription: "",
      instructions: "",
      handledByOkhati: false,
      document: {
        rates: []
      },
      origin: null
    });
  };

  const onSuccess = () => {
    resetForm();
    actions.loadServices();
  };

  const saveProduct = async () => {
    setSaving(true);
    let newRates;
    if (productData.document.rates.length > 0) {
      newRates = productData.document.rates.filter(
        (item) => item.serviceProviderId !== "" && item.rate !== ""
      );
    } else {
      newRates = [];
    }
    const serviceData = {
      name: productData.productName,
      publicBooking: productData.publicBooking,
      grossTotalPrice: productData.productPrice,
      vatPct: Number(productData.vatPct) || 0,
      servicePriceExcVAT: productData.servicePriceExVAT,
      unit: productData.productUnits,
      productType: "single",
      category: productData.productType,
      materialCharge: productData.materialCharge,
      serviceProviderRateUnit: productData.serviceProviderRateUnit,
      serviceProviderId: productData.serviceProviderId,
      info: productData.info || undefined,
      productisableId: productData.productisableId,
      productisableName: productData.productisableName,
      labCharge: productData.labCharge,
      voucherAssociation: "sales",
      salesLedgerId: productData.salesLedgerId,
      salesTaxationId: productData.salesTaxationId,
      intangible: true,
      departmentId: productData.departmentId,
      publicDescription: productData.publicDescription || "",
      instructions: productData.instructions || "",
      specialLabTest: productData.specialLabTest,
      handledByOkhati: productData.handledByOkhati,
      document: {
        rates: [...newRates]
      }
    };

    // if intention is to update product under package
    if (isForEditingServiceUnderPackage && packageId) {
      await actions.updateServiceUnderPackage({
        data: { ...serviceData, id: productUnderPackage.id },
        packageId,
        redirectAfterUpdate,
        isPublicTab
      });

      setSaving(false);
      if (handleClose) {
        handleClose();
      }
      actions.goBack(isPublicTab, isSsfTab, productId, packageId);
      return;
    }

    if (productId) {
      if (isPublicTab && !product?.resourceCentreId) {
        await actions.createService({
          data: { ...serviceData, referenceId: productId },
          createNewAfter,
          isPublicTab,
          onSuccess
        });
      } else {
        await actions.updateService({
          data: { ...serviceData, id: productId },
          isPublicTab,
          redirectAfterUpdate
        });
        if (handleClose) {
          handleClose();
        }
      }
      actions.goBack(isPublicTab, isSsfTab, productId, packageId);
    } else {
      await actions.createService({
        data: serviceData,
        createNewAfter,
        isPublicTab,
        isOpd,
        onSuccess
      });
      if (isOpd && handleClose) {
        handleClose();
      }
      actions.goBack(isPublicTab, isSsfTab, productId, packageId);
    }

    setSaving(false);
  };

  const addAnotherServiceProviderField = () => {
    const newSP = { serviceProviderId: "", rate: "" };
    setProductData({
      ...productData,
      document: {
        rates: [...productData.document.rates, newSP]
      }
    });
  };

  const usedServiceProviders = React.useMemo(() => usedSP(productData), [productData]);
  const showAccountLedger = useCan("account:listAccount", {});

  const footer = (
    <Footer
      editable={!productId}
      saveFn={saveProduct}
      saveDisabled={
        !(
          productData?.productName?.length > 0 &&
          (isAccountSubscribed ? Boolean(productData.salesLedgerId) : true)
        ) ||
        saving ||
        productData?.productPrice?.toString() === "" ||
        (productData?.productType === ProductType.labTest && !productData?.productisableId)
      }
      importing={isPublicTab && !product?.resourceCentreId && product?.publicBooking}
      goBack={() => {
        if (handleClose) {
          handleClose();
        }
        if (setActivePackageProduct) {
          setActivePackageProduct(null);
        }
        actions.goBack(isPublicTab, isSsfTab, productId, packageId);
      }}
      createNewAfter={createNewAfter}
      createNewAfterToggler={createNewAfterToggler}
      isAccount={isAccount}
    />
  );

  return (
    <Can policyAccessKey="services:createProduct">
      <Panel
        title={title}
        onClose={() => {
          if (handleClose) {
            handleClose();
          }
          if (setActivePackageProduct) {
            setActivePackageProduct(null);
          }
          if (!isAccount && !isOpd) {
            actions.goBack(isPublicTab, isSsfTab, productId, packageId);
          }
        }}
        footer={footer}
      >
        <Box p={5} style={{ width: "100%" }} className={styles.boxHeight}>
          <CommonServiceFields
            isAccount={isAccount}
            isOpd={isOpd}
            showAccountLedger={showAccountLedger}
            labTests={labTests}
            fieldData={productData}
            isPublicTab={isPublicTab}
            isForEditingServiceUnderPackage={isForEditingServiceUnderPackage}
            isSsfService={productData.origin === SSF_SERVICE_ORIGIN || isSsfTab}
            onUpdate={(updates: string | { [key: string]: string } = {}) => {
              const updatedKeys = Object.keys(updates);
              if (typeof updates === "string") return;
              if (
                updatedKeys.includes("vatPct") ||
                updatedKeys.includes("productPrice") ||
                updatedKeys.includes("salesTaxationId")
              ) {
                const mutatedUpdates = produce(updates, (draft) => {
                  draft.servicePriceExVAT = findServicePriceExVat(
                    updatedKeys,
                    productData,
                    updates,
                    isAccountSubscribed,
                    salesTaxationOptions
                  );
                });
                setProductData({ ...productData, ...mutatedUpdates });
              } else {
                setProductData({ ...productData, ...updates });
              }
            }}
          />

          {isPublicTab && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={productData.publicBooking}
                  onChange={() =>
                    setProductData({ ...productData, publicBooking: !productData.publicBooking })
                  }
                />
              }
              label="Public Booking"
            />
          )}

          <Can policyAccessKey="services:addPayables">
            <Box>
              {productData.document?.rates?.length > 0 ? (
                <>
                  <Typography style={{ marginBottom: "16px" }}>
                    <Box component="span" fontWeight="600">
                      {tl("services.serviceProviderPayableRates")}
                    </Box>
                  </Typography>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="0.5px solid lightgray"
                    pb={1}
                  >
                    {" "}
                    <Box width="50%">
                      <Typography>
                        <Box component="span" fontWeight="500">
                          {tl("serviceProvider")}
                        </Box>
                      </Typography>
                    </Box>
                    <Box width="20%" />
                    <Box
                      width="20%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>
                        <Box component="span" fontWeight="500" pr={2}>
                          {tl("rate")}
                        </Box>
                      </Typography>
                      <ButtonGroup size="small" variant="outlined">
                        <Button
                          data-testmation="serviceProviderRateUnitRupee"
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setProductData({
                              ...productData,
                              serviceProviderRateUnit: "rupee"
                            });
                          }}
                        >
                          <Typography>
                            <Box component="span">{tl("rs")}</Box>
                          </Typography>
                        </Button>
                        <Button
                          data-testmation="serviceProviderRateUnitPercentage"
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setProductData({
                              ...productData,
                              serviceProviderRateUnit: "percentage"
                            });
                          }}
                        >
                          <Typography>%</Typography>
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box width="10%" />
                  </Box>
                  {productData?.document?.rates.map((rate, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                      <RateRow
                        serviceProviders={serviceProviders}
                        usedServiceProviders={usedServiceProviders}
                        rate={rate}
                        onChange={(newRate) => {
                          const newProductData = cloneDeep(productData);
                          newProductData.document.rates[index] = newRate;
                          if (!newRate.serviceProviderId) {
                            newProductData.document.rates[index].serviceProviderId = null;
                          }
                          setProductData(newProductData);
                        }}
                        onRemove={() => {
                          const newProductData = cloneDeep(productData);
                          newProductData.document.rates.splice(index, 1);
                          setProductData(newProductData);
                        }}
                        serviceProviderRateUnit={productData.serviceProviderRateUnit}
                      />
                      {productData.document?.rates?.length === 1 &&
                        productData.document?.rates[0] &&
                        Number(productData.document?.rates[0]?.serviceProviderId ?? 0) !== 0 && (
                          <Box display="flex" alignItems="center">
                            <Checkbox
                              checked={isServiceProviderSingle}
                              onChange={(e) => {
                                setisServiceProviderSingle(!isServiceProviderSingle);
                                if (e.target.checked) {
                                  setProductData({
                                    ...productData,
                                    serviceProviderId: Number(
                                      productData.document.rates[0].serviceProviderId
                                    )
                                  });
                                } else {
                                  setProductData({
                                    ...productData,
                                    serviceProviderId: null
                                  });
                                }
                              }}
                              style={{ marginLeft: "-8px" }}
                            />
                            <Typography>
                              This product is for {"  "}
                              {spFullNameSelector(
                                spFromIdSelector(
                                  serviceProviders,
                                  productData.document?.rates[0]?.serviceProviderId
                                )
                              )}
                              {"  "}
                              only
                            </Typography>
                          </Box>
                        )}
                    </React.Fragment>
                  ))}

                  {!isServiceProviderSingle && (
                    <Typography style={{ marginTop: "32px" }}>
                      <Box
                        component="span"
                        data-testmation="productCreateAddAnotherServiceProviderField"
                        onClick={() => addAnotherServiceProviderField()}
                        className={styles.addServiceProvider}
                      >
                        {tl("services.addAnother")}
                      </Box>
                    </Typography>
                  )}
                </>
              ) : (
                <Typography>
                  <Box
                    component="span"
                    data-testmation="productCreateAddServiceProviderPayableRates"
                    onClick={() => addAnotherServiceProviderField()}
                    className={styles.addServiceProvider}
                  >
                    {tl("services.addServiceProviderPayableRates")}
                  </Box>
                </Typography>
              )}
            </Box>
          </Can>
          <Box>
            {!showDepartmentField && !productData.departmentId && (
              <Typography
                className={styles.addServiceProvider}
                component="span"
                onClick={() => setShowDepartmentField(true)}
              >
                {tl("service.create.addDepartment")}
              </Typography>
            )}
            {(showDepartmentField || productData.departmentId) && (
              <Box display="flex" alignItems="center" mt={2}>
                <DepartmentSelect
                  placeholderText="Select department"
                  style={{ width: "310px", marginTop: "8px" }}
                  id={productData.departmentId}
                  onChange={(department) =>
                    setProductData({ ...productData, departmentId: department.id })
                  }
                />
                <Close
                  onClick={() => setShowDepartmentField(false)}
                  sx={{ cursor: "pointer", marginLeft: "15px" }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Panel>
    </Can>
  );
};

function mapStateToProps(state, ownProps) {
  let product =
    [
      ...(ownProps.isPublicTab ? state?.publicServices?.collection : []),
      ...state.services.collection
    ].find((item) => ownProps.productId === item.id) || {};

  if (ownProps.isAccount && ownProps.productId) {
    const data = state.stockProducts.collection.find((item) => ownProps.productId === item.id);
    product = {
      name: data.name,
      grossTotalPrice: data.unitPriceIncVAT || 0,
      vatPct: data.vatPct || 0,
      servicePriceExcVAT: data.unitPriceExcVAT || 0,
      unit: data.unit,
      category: data.category,
      materialCharge: data.serviceDetails?.materialCharge || 0,
      serviceProviderRateUnit: data.serviceDetails?.serviceProviderRateUnit,
      serviceProviderId: data.serviceDetails?.serviceProviderId,
      info: undefined,
      productisableId: data.serviceDetails?.productisableId,
      productisableName: data.serviceDetails?.productisableName,
      salesLedgerId: data.salesLedgerId || null,
      labCharge: data.serviceDetails?.labCharge,
      document: {
        ...(data.document?.rates ? { rates: [...data.document.rates] } : {}),
        ...(data.document?.products ? { products: [...data.document.products] } : {})
      }
    };
  }
  const serviceProviders = serviceProvidersSortedSelector(state);
  const { resourceCentreId } = state.userContext;
  const { labTests } = state.assessments;
  let productServiceProviders = [];
  if (product && serviceProviders?.length) {
    productServiceProviders = serviceProviders?.filter((sp) => {
      const productSP = product?.document?.rates?.map((rate) => rate.serviceProviderId);
      if (productSP?.includes(sp.id)) return sp;
      return false;
    });
    const productServiceProvidersIds = productServiceProviders?.map((sp) => sp.id);
    product = {
      ...product,
      document: {
        rates: product?.document?.rates?.filter((rate) => {
          if (productServiceProvidersIds?.includes(rate.serviceProviderId)) {
            return rate;
          }
          return false;
        })
      }
    };
  }

  return {
    serviceProviders,
    resourceCentreId,
    product,
    labTests
  };
}

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  actions: {
    goBack: (isPublicTab, isSsfTab, productId, packageId) => {
      let basePath = "/services";
      if (isPublicTab) {
        basePath = "/publicServices";
      } else if (isSsfTab) {
        basePath = "/socialSecurityFundServices";
      }

      if (productId) {
        dispatch(push(`${basePath}/product/${productId}`));
      } else if (packageId) {
        dispatch(push(`${basePath}/package/${packageId}`));
      } else {
        dispatch(push(basePath));
      }
    },
    goToStockServices: () => dispatch(push("/stock/stockServices")),
    navigateTo: (url) => {
      dispatch(push(url));
    },
    loadServiceProviders: (id) =>
      dispatch(
        serviceProviderActions.getResourceCentreServiceProviders({
          resourceCentreId: id
        })
      ),
    createSuccessNotification: () =>
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Completed Successfully",
          autoTimeout: true
        })
      ),
    createService: async ({ data, createNewAfter, isPublicTab, isOpd, onSuccess }) => {
      await dispatch(async (dispatchInner, getState) => {
        await dispatchInner(createServices(data));
        const currentState = getState();
        const errorObject = currentState.error.filter(({ key }) => key === "CREATE_SERVICES");
        const rootPath = isPublicTab ? "/publicServices" : "/services";
        if (errorObject.length) {
          dispatchInner(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorObject[0].message || commonErrorMessage,
              autoTimeout: true
            })
          );
          dispatchInner(removeGeneralErrorByKey("CREATE_SERVICES"));
        } else {
          dispatchInner(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("services.successProductCreation"),
              autoTimeout: true
            })
          );
          onSuccess();
          if (isOpd) return;
          if (createNewAfter) {
            dispatchInner(
              push({
                pathName: `${rootPath}/newProduct/?createNewAfterSave=${createNewAfter}`,
                state: { filter: currentState.services.lastTouched.category }
              } as Record<string, unknown>)
            );
          } else {
            dispatchInner(push(`${rootPath}/product/${currentState.services.lastTouched.id}`));
          }
        }
      });
    },
    updateServiceUnderPackage: async ({ data, packageId, redirectAfterUpdate, isPublicTab }) => {
      dispatch(async (dispatchInner, getState) => {
        await dispatchInner(updateServiceUnderPackage({ data, packageId }));
        await dispatchInner(getService(packageId));
        const currentState = getState();
        const rootPath = isPublicTab ? "/publicServices" : "/services";
        const errorObject = currentState.error.filter(
          ({ key }) => key === ServiceActions.UPDATE_SERVICE_UNDER_PACKAGE
        );
        if (errorObject.length) {
          dispatchInner(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorObject[0].message || commonErrorMessage,
              autoTimeout: true
            })
          );
          dispatchInner(removeGeneralErrorByKey(ServiceActions.UPDATE_SERVICE_UNDER_PACKAGE));
        } else {
          dispatchInner(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("services.successUpdateProduct"),
              autoTimeout: true
            })
          );

          if (redirectAfterUpdate) {
            dispatchInner(push(`${rootPath}/package/${packageId}`));
          }
        }
      });
    },
    updateService: async ({ data, isPublicTab, redirectAfterUpdate }) => {
      await dispatch(async (dispatchInner, getState) => {
        await dispatchInner(updateServices(data));
        const rootPath = isPublicTab ? "/publicServices" : "/services";
        const currentState = getState();
        const errorObject = currentState.error.filter(({ key }) => key === "UPDATE_SERVICES");
        if (errorObject.length) {
          dispatchInner(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorObject[0].message || commonErrorMessage,
              autoTimeout: true
            })
          );
          dispatchInner(removeGeneralErrorByKey("UPDATE_SERVICES"));
        } else {
          dispatchInner(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("services.successUpdateProduct"),
              autoTimeout: true
            })
          );
          if (redirectAfterUpdate) {
            dispatchInner(push(`${rootPath}/product/${currentState.services.lastTouched.id}`));
          }
        }
      });
    },
    getService: (id) => dispatch(getService(id)),
    loadServices: () => dispatch(getServices()),
    loadStockProducts: (query) => dispatch(getStockProducts(query)),
    getLabTests: () => dispatch(getBatchLabTests(true))
  }
}))(CreateProduct);

CreateProduct.defaultProps = {
  isPublicTab: false,
  isOpd: false,
  serviceName: ""
};

import { BillDocumentType, BillItemType } from "./BillInterfaces";
import { Receipts } from "./StockInterfaces";
import { Referrer } from "./ReferrerInterface";
import { Client } from "./ClientInterface";

export interface RelatedInformation {
  customerFirstName: string;
  customerLastName: string;
  serviceProviderFullName: string;
  serviceName: string;
  packageDetails: Record<string, unknown>;
}

export interface Commission {
  id: number;
  clientId: number;
  resourceCentreId: number;
  serviceProviderId: number;
  serviceId: number;
  qty: number;
  billNo: number;
  productPriceExcVat: number;
  serviceProviderRate: number;
  discountPercent: number;
  discountAmount: number;
  netAmount: number;
  paidAmount: number;
  materialChargePercent: number;
  materialChargeAmount: number;
  labCharge: number;
  standardPayable: number;
  tds: number;
  netPayableToDoctor: number;
  relatedInformation: RelatedInformation;
  recordPaymentId: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
}

export interface BillSubItemProps {
  labCharge: number;
  materialCharge: number;
  payableToReferrer: number;
  tds: number;
  ratePct: number;
  rateAmt: number;
  grossTotal: number;
  sNo: number;
  allocatedAmt: number;
  description: string;
  source: string;
  servicePriceExcVAT?: number;
  billNumber: string;
}

export interface BillDataInterface {
  totalNetPayableToVendor: number;
  id: number;
  billNumber: string;
  referrerId: number;
  total: number;
  isSelected: boolean;
  billItems: BillSubItemProps[];
  grossTotalPayable: number;
  paidAmount: number;
  paymentInfo?: {
    paidAmount: number;
  };
  qty?: number;
  subItems?: { [key: string]: string | number | { [key: string]: string | number } };
  productData: { id: number; servicePriceExcVAT: number };
}

export enum Source {
  SERVICE = "services",
  STOCKS = "stocks"
}

export interface VendorCommission {
  internalSNo: number;
  billId: number;
  clientId: number;
  discountAmount: number;
  discountPercent: number;
  materialChargeAmount: number;
  materialChargePercent: number;
  netAmount: number;
  source: string;
  recordPaymentId: number;
  billNumber: string;
  description: string;
  vendorId?: number;
  isSelected: boolean;
  paidAmount: number;
  standardPayable: number;
  netPayableToVendor: number;
  rate: number;
  rateAmt: number;
  labCharge: number;
  allocatedAmt: number;
  qty?: number;
  tds: number;
  serviceId: number;
  relatedInformation: {
    customerFirstName: string;
    customerLastName: string;
    packageDetail: { [key: string]: string | [] | { [key: string]: string } };
    serviceName: string;
    serviceProviderFullName: string;
    source: string;
  };
  subItems?: { [key: string]: string | number | { [key: string]: string | number } };
  productData: {
    servicePriceExcVAT: number;
    unitPriceExcVAT: number;
    id: number;
  };
  serviceProviderFullName?: string;
  isSubItem?: boolean;
  packageName?: string;
}

export interface VendorCommissionBill {
  paidAmount: number;
  billId: number;
  billNumber: string;
  recordPaymentId: number;
  isSelected: boolean;
  sNo: number;
  billItems: VendorCommission[];
  totalNetPayableToVendor: number;
  isSaved: boolean;
  isReferrerPaid: boolean;
  isServiceProviderPaid: boolean;
}

export interface BillDocumentProps extends Partial<Omit<BillDocumentType, "id">> {
  subItems?: { [key: string]: string };
  clientId: number;
  id: number;
  total: number;
  receipts: Receipts[];
  referrer: Referrer;
  billItems: Array<Partial<BillItemType>> | undefined;
  referrerCommission: ReferrerCommission[];
}

export interface ServiceProviderCommissionBill extends Partial<Omit<BillDocumentType, "id">> {
  billNumber: string;
  client: Client;
  clientId: number;
  id: number;
  document: { billItems: Array<Partial<BillItemType>> | undefined };
  receipts: Receipts[];
  referrer: Referrer;
  status: string;
  isDraft: boolean;
  total: number;
  serviceProviderCommissions: VendorCommission[];
}

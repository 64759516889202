import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Box, Button } from "@mui/material";
import { match as IMatch } from "react-router";
import { push } from "connected-react-router";
import startCase from "lodash/startCase";
import { MultipleHeader } from "../../../components/List";
import { hmisMultipleHeaders } from "../index";
import * as hmisActions from "../../../actions/hmis";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CreateMulDartaPanel } from "./CreateMulDartaPanel";
import ListWithNestedHeader, {
  getSerialNumber
} from "../../../components/ListWithNestedHeader/Index";
import { HMIS } from "../../../interfaces/HmisInterface";
import { ethnicityMappedValue } from "../../../models/Client";
import useAddressOptions from "../../../hooks/useAddressOptions";
import { t } from "../../../components/translate";
import MulDartaInfoPanel from "./MulDartaInfoPanel";
import CreateEditOpdRegisterPanel from "../OpdRegister/CreateEditOpdRegiterPanel";
import CreateEditImnciAboveTwoMonthsPanel from "../ImnciRegister/AboveTwoMonths/CreateEditImnciAboveTwoMonthsPanel";
import CreateEditImnciBelowTwoMonthsPanel from "../ImnciRegister/BelowTwoMonths/CreateEditImnciBelowTwoMonthsPanel";
import useNextMulDartaNumber from "../../../hooks/useNextMulDartaNumber";
import useNextOpdNumber from "../../../hooks/useNextOpdNumber";

interface Props {
  match: IMatch<{ [x: string]: string }>;
  mode: string;
}

const MulDartaList = ({ match, mode }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const addressOptions = useAddressOptions();
  const { results: data, total } = useAppSelector((state) => state.hmis.mulDarta || {});
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMulDarta, setSelectedMulDarta] = useState<HMIS | null>(null);
  const [createOpdRegister, setCreateOpdRegister] = useState(false);
  const [createAboveTwoMonthsImnc, setCreateAboveTwoMonthsImnc] = useState<boolean>(false);
  const [createBelowTwoMonthsImnc, setCreateBelowTwoMonthsImnc] = useState<boolean>(false);
  const nextMulDartaNumber = useNextMulDartaNumber();
  const nextOpdNumber = useNextOpdNumber();

  useEffect(() => {
    (async () => {
      await dispatch(
        hmisActions.getMulDarta({ pageSize: pagination.pageSize, page: pagination.page })
      );
      setIsLoading(false);
    })();
  }, [pagination.page]);

  return (
    <div>
      <Box sx={{ m: 2, display: "flex", justifyContent: "space-between" }}>
        <MultipleHeader multipleHeaders={hmisMultipleHeaders(match)} />
        <Button variant="contained" onClick={() => dispatch(push("/hmis/mulDarta/new"))}>
          {t("hmis.createMulDarta")}
        </Button>
      </Box>
      <Box sx={{ px: 2, height: "calc(100vh - 160px)" }}>
        <ListWithNestedHeader<HMIS>
          dataTestmation="mulDartaList"
          emptyViewContent="There are no items to show, please add new mul darta by clicking the create button."
          isLoading={isLoading}
          minWidth={950}
          onRowClick={(row) => {
            if (selectedMulDarta?.id === row.id) {
              setSelectedMulDarta(null);
            } else {
              setSelectedMulDarta(row);
            }
          }}
          pagination={{
            total,
            page: pagination.page,
            onPageChange: (page) => {
              setIsLoading(true);
              setPagination((prev) => ({ ...prev, page }));
            },
            rowsPerPage: pagination.pageSize
          }}
          data={data}
          columns={[
            [
              {
                label: t("hmis.serialNumber"),
                key: "index",
                formatter: (_, index) =>
                  getSerialNumber({
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                    currentIndex: index
                  })
              },
              {
                label: t("hmis.registrationNumber"),
                key: "mulDartaNumber",
                children: [
                  {
                    label: t("hmis.firstTime"),
                    key: "firstTime",
                    formatter: ({ mulDartaNumber = "-", followup }) =>
                      followup ? "" : mulDartaNumber
                  },
                  {
                    label: t("hmis.lastTime"),
                    key: "repeating",
                    formatter: ({ mulDartaNumber = "-", followup }) =>
                      followup ? mulDartaNumber : ""
                  }
                ]
              },
              {
                label: t("hmis.patient's"),
                key: "clientName",
                children: [
                  {
                    label: t("hmis.name"),
                    key: "firstName",
                    formatter: ({ client }) => client.firstName
                  },
                  {
                    label: t("hmis.surname"),
                    key: "lastName",
                    formatter: ({ client }) => client.lastName
                  }
                ]
              },
              {
                label: t("hmis.ethnicity"),
                key: "ethnicity",
                formatter: ({ client }) =>
                  client.ethnicity ? ethnicityMappedValue[client.ethnicity] : ""
              },
              {
                label: t("hmis.age"),
                key: "age",
                children: [
                  {
                    label: t("hmis.female"),
                    key: "female",
                    formatter: ({ client: { gender, dob } }) => {
                      if (gender === "2" && dob) {
                        return `${moment().diff(moment(dob), "year")} Years`;
                      }
                      return "";
                    }
                  },
                  {
                    label: t("hmis.male"),
                    key: "male",
                    formatter: ({ client: { gender, dob } }) => {
                      if (gender === "1" && dob) {
                        return `${moment().diff(moment(dob), "year")} Years`;
                      }
                      return "";
                    }
                  }
                ]
              },
              {
                label: t("hmis.address"),
                key: "address",
                children: [
                  {
                    label: t("hmis.province"),
                    key: "pradesh",
                    formatter: ({ client: { palikaId } }) =>
                      palikaId
                        ? addressOptions.find((item) => item.palikaId === palikaId)?.provinceId
                        : ""
                  },
                  {
                    label: t("hmis.district"),
                    key: "district",
                    formatter: ({ client: { palikaId } }) =>
                      palikaId
                        ? addressOptions.find((item) => item.palikaId === palikaId)?.districtName
                        : ""
                  },
                  {
                    label: t("hmis.palika"),
                    key: "palika",
                    formatter: ({ client: { palikaId } }) =>
                      palikaId
                        ? addressOptions.find((item) => item.palikaId === palikaId)?.palikaName
                        : ""
                  },
                  {
                    label: t("hmis.wardNo"),
                    key: "ward",
                    formatter: ({ client }) => client.wardNo || ""
                  }
                ]
              },
              {
                label: t("hmis.contactNumber"),
                key: "phone",
                formatter: ({ client }) => client.phone || ""
              },
              {
                label: t("hmis.serviceType"),
                key: "serviceType",
                formatter: ({ himsServiceType }) => startCase(himsServiceType)
              },
              {
                label: t("hmis.serviceCharge"),
                key: "serviceCharge",
                formatter: ({ serviceCharge }) => serviceCharge
              },
              {
                label: t("hmis.referrerOrganization"),
                key: "referrerId",
                formatter: ({ referrer }) => referrer?.referrer || ""
              }
            ]
          ]}
        />
      </Box>
      {mode === "create" && (
        <CreateMulDartaPanel
          onCancel={() => dispatch(push("/hmis/mulDarta"))}
          nextMulDartaNumber={nextMulDartaNumber}
        />
      )}
      {selectedMulDarta && (
        <MulDartaInfoPanel
          onClose={() => setSelectedMulDarta(null)}
          data={selectedMulDarta}
          onCreateOutpatientRegister={() => setCreateOpdRegister(true)}
          onCreateAboveTwoMonthsImnc={() => setCreateAboveTwoMonthsImnc(true)}
          onCreateBelowTwoMonthsImnc={() => setCreateBelowTwoMonthsImnc(true)}
        />
      )}
      {createOpdRegister && selectedMulDarta && (
        <CreateEditOpdRegisterPanel
          mulDarta={selectedMulDarta}
          onClose={() => setCreateOpdRegister(false)}
          nextOpdNumber={nextOpdNumber}
        />
      )}
      {createAboveTwoMonthsImnc && selectedMulDarta && (
        <CreateEditImnciAboveTwoMonthsPanel
          mulDarta={selectedMulDarta}
          onClose={() => setCreateAboveTwoMonthsImnc(false)}
        />
      )}
      {createBelowTwoMonthsImnc && selectedMulDarta && (
        <CreateEditImnciBelowTwoMonthsPanel
          mulDarta={selectedMulDarta}
          onClose={() => setCreateBelowTwoMonthsImnc(false)}
        />
      )}
    </div>
  );
};

export default MulDartaList;

import React from "react";
import { useForm, Controller, FieldValues } from "react-hook-form";
import { Button, Box, Typography } from "@mui/material";
import { pickBy } from "lodash";
import { useSelector } from "react-redux";
import InputPhoneNumber from "../../../components/InputPhoneNumber";
import {
  isValidMobileNumber,
  isEmail,
  validate,
  isISODateString
} from "../../../helpers/validators";
import ReactHookFormInput from "../../../components/ReactHookFormInput";
import ToggleInputDate from "../../../components/ToggleADBS";
import { fields } from "../../../models/Client";
import { Supplier, SupplierInfo } from "../../../interfaces/StockInterfaces";
import { t, tl } from "../../../components/translate";
import VendorMapping from "../../../components/VendorMapping";
import { RootState } from "../../../store";
import { ledgerFindBySubLedgerType } from "../../accounts/hooks";
import { TransactionType, SubLedgerTypes } from "../../../interfaces/Accounts";
import BankInfo from "./BankInfo";

const phoneField = {
  key: "phone",
  label: "phone",
  value: "",
  inputType: "phone",
  editable: true,
  creatable: true,
  required: false,
  validators: [isValidMobileNumber({ msg: "Should be a valid mobile number" })]
};

const SupplierCreationForm = ({
  data,
  onSave,
  onCancel,
  createMode
}: {
  onSave: (data) => void;
  data: Partial<Supplier>;
  onCancel: () => void;
  createMode: boolean;
}): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: data || {
      address: "",
      agingDays: "",
      bankInfo: [],
      detail: "",
      email: "",
      name: "",
      transactionType: TransactionType.CREDIT,
      openingPayableBalance: 0,
      openingBalanceDate: "",
      panNo: "",
      phone: "",
      landline: "",
      type: ""
    }
  });

  const { phone, openingBalanceDate, ledgerId, bankInfo } = watch();

  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );

  const [showMore, setShowMore] = React.useState<boolean>(false);
  const supplierGl = ledgerFindBySubLedgerType(SubLedgerTypes.SUPPLIER);

  React.useEffect(() => {
    if (isAccountSubscribed && supplierGl && createMode) {
      setValue("ledgerId", supplierGl.id);
    }
  }, [isAccountSubscribed, supplierGl, setValue, createMode]);
  const onSubmit = (submittedData: SupplierInfo) => {
    onSave(pickBy(submittedData));
  };

  const bsDateField = fields.find((field) => field.key === "dob");

  return (
    <form>
      <Box minWidth="30vw">
        <ReactHookFormInput
          testmation="supplierName"
          control={control}
          name="name"
          rules={{ required: "Supplier Name is Required" }}
          label={t("SupplierName")}
          placeholder={t("supplier.create.supplierName")}
          errors={errors}
          fullWidth
        />
        <ReactHookFormInput
          testmation="supplierAddress"
          control={control}
          name="address"
          label={t("Address")}
          placeholder={t("supplier.create.supplierAddress")}
          fullWidth
        />

        <ReactHookFormInput
          testmation="supplierEmail"
          control={control}
          name="email"
          label={t("Email")}
          placeholder={t("supplier.create.email")}
          errors={errors}
          fullWidth
          rules={{
            required: false,
            validate: {
              validEmail: (value): boolean | string => {
                if (value)
                  return validate(value, [isEmail({ msg: "Enter valid email." })]).isValid
                    ? true
                    : "Enter a valid email";
                return true;
              }
            }
          }}
        />

        <Controller
          control={control}
          name="phone"
          rules={{
            required: false
          }}
          render={({ field }) => (
            <InputPhoneNumber
              field={phoneField}
              key="phone"
              data={{ phone }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              onChange={(value) => {
                field.onChange(value);
              }}
              isFocused={() => ({})}
              isBlurred={() => ({})}
            />
          )}
        />

        <Controller
          control={control}
          name="landline"
          rules={{
            required: false
          }}
          render={({ field }) => (
            <ReactHookFormInput
              control={control}
              name="landline"
              label={tl("supplier.create.landline")}
              placeholder={t("supplier.create.landline")}
              fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />
          )}
        />

        <ReactHookFormInput
          testmation="supplierPanNo"
          control={control}
          name="panNo"
          label={t("PanNo.")}
          placeholder={t("supplier.create.panNo")}
          errors={errors}
          fullWidth
        />

        <ReactHookFormInput
          testmation="supplierDetail"
          control={control}
          name="detail"
          label={t("supplier.create.details")}
          placeholder={t("supplier.create.details")}
          errors={errors}
          fullWidth
          multiline
        />

        <Typography
          mt={1}
          mb={2}
          fontWeight={600}
          fontSize="16px"
          color="primary"
          sx={{
            cursor: "pointer"
          }}
          onClick={() => {
            setShowMore(!showMore);
          }}
          data-testmation="showMoreBtn"
        >
          {showMore ? "Show Less" : "Show More"}
        </Typography>
      </Box>

      {showMore && (
        <>
          <Box>
            <Typography fontWeight="medium" mb={2}>
              {tl("accountInformation")}
            </Typography>
          </Box>
          <Box display="flex" width="100%">
            <ReactHookFormInput
              testmation="openingPayableBalance"
              style={{ marginRight: "10px", width: "200px" }}
              control={control}
              name="openingPayableBalance"
              label={tl("openingBalance")}
              errors={errors}
              type="number"
            />

            <ReactHookFormInput
              testmation="type"
              style={{ marginRight: "10px", width: "150px" }}
              control={control}
              name="transactionType"
              label="Type"
              errors={errors}
              select
              options={[
                { value: "debit", label: "Debit" },
                { value: "credit", label: "Credit" }
              ]}
            />
            <Box mt="10px" width={1}>
              <Controller
                name="openingBalanceDate"
                control={control}
                rules={{
                  required: false,
                  validate: (value) => (value ? !!isISODateString(value) : true)
                }}
                render={({ field }) => (
                  <ToggleInputDate
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    key="openingBalanceDate"
                    field={{
                      ...bsDateField,
                      label: "openingBalanceDate",
                      key: "openingBalanceDate"
                    }}
                    data={{ openingBalanceDate }}
                    changeDate={(value) => {
                      setValue("openingBalanceDate", value);
                    }}
                    isFocused={() => ({})}
                    isBlurred={() => ({})}
                    showAgeField={false}
                    label="openingBalanceDate"
                    error={!!errors?.openingBalanceDate}
                    helperText={errors?.openingBalanceDate?.message || ""}
                  />
                )}
              />
            </Box>
          </Box>

          <Box display="flex">
            <ReactHookFormInput
              testmation="agingDays"
              style={{ marginRight: "10px" }}
              control={control}
              name="agingDays"
              label="AgingDays"
              errors={errors}
              type="string"
            />
            {isAccountSubscribed && supplierGl && (
              <Box sx={{ width: "300px" }}>
                <VendorMapping
                  subLedgerType={SubLedgerTypes.SUPPLIER}
                  onChange={(v) => {
                    if (v) {
                      setValue("ledgerId", v.id);
                    }
                  }}
                  id={ledgerId}
                />
              </Box>
            )}
          </Box>
          <BankInfo data={bankInfo} updateBankInfo={(value) => setValue("bankInfo", value)} />
        </>
      )}

      <Box display="flex" justifyContent="flex-end" mr="32px">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          data-testmation="supplierCreate"
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {createMode ? tl("serviceProvider.create") : tl("serviceProvider.save")}
        </Button>
      </Box>
    </form>
  );
};

export default SupplierCreationForm;

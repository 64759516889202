import * as moment from "moment";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import { AudienceData } from "../Audience/AudienceHelpers";

export interface CampaignData {
  id?: number;
  resourceCentreId?: number;
  name: string;
  audienceId?: number;
  audience?: AudienceData;
  activated?: boolean;
  type: campaignType;
  mediaType: string;
  message: string;
  status?: string;
  active?: boolean;
  trigger?: {
    triggerType?: triggerType;
    props?: any;
  };
}

export enum campaignType {
  INSTANTANEOUS = "instantaneous",
  ONE_TIME = "oneTime",
  AUTOMATED = "automated"
}

export enum triggerType {
  CLIENT_BIRTHDAY = "clientBirthday",
  CLIENT_CREATE = "clientCreate",
  BOOKING_CREATE = "bookingCreate",
  BOOKING_CONFIRM = "bookingConfirm",
  BOOKING_CANCEL = "bookingCancel",
  BOOKING_REMINDER = "bookingReminder"
}
export enum campaignStatus {
  ACTIVE = "active",
  ON_SCHEDULE = "onSchedule",
  COMPLETED = "completed"
}

export const statusColor = {
  active: "#009856",
  onSchedule: "#ffba00",
  deactivated: "#9a9a9a"
};

export const getFormattedTriggerFromCampaign = (trigger: {
  props: Record<string, unknown>;
  triggerType: string;
}): string => {
  if (trigger?.triggerType === triggerType.CLIENT_CREATE) return "On Client Creation";
  if (!trigger && !trigger?.props && !trigger?.triggerType) return "-";
  let output = "";
  const triggerProps = trigger.props;
  const triggerPropsKeys = Object.keys(triggerProps);
  if (triggerPropsKeys.includes("sendAtTime") || triggerPropsKeys.includes("sendBeforeDays")) {
    output += "Time: ";
  }
  if (triggerPropsKeys.includes("sendAtTime")) {
    output +=
      trigger.triggerType !== triggerType.CLIENT_BIRTHDAY
        ? `{ ${calFns.bsFullDate(triggerProps.sendAtTime)} ${moment(triggerProps.sendAtTime).format(
            "hh:mm a"
          )}}`
        : `7am in the morning`;
  }
  if (triggerPropsKeys.includes("sendBeforeDays")) {
    output += triggerPropsKeys.includes("followUp")
      ? `{ Follow up date - ${triggerProps.sendBeforeDays} days }`
      : `{ Send Before - ${triggerProps.sendBeforeDays} days }`;
  }

  return output;
};

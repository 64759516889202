import * as React from "react";
import produce from "immer";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import startCase from "lodash/startCase";
import { Controller, FieldValues, useForm } from "react-hook-form";
import Panel from "../../../components/Panel";
import { t } from "../../../components/translate";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { Client } from "../../../interfaces/ClientInterface";
import { clientFullNameSelector } from "../../../reducers/client";
import { notificationAdd } from "../../../actions/notification";
import {
  createErrorMessage,
  createSuccessMessage,
  MODULE,
  updateErrorMessage,
  updateSuccessMessage
} from "../../../helpers/messages";
import Signature from "../../ResourceCentre/UserSettings/Signature";
import FormTextField from "../../../components/FormTextField/FormTextField";
import { patchConsentForm, postConsentForm } from "../../../api/consentForm";
import { useAppDispatch } from "../../../store/hooks";

const healthConditions = [
  "heartDisease",
  "asthma",
  "thyroidDisorder",
  "bloodPressure",
  "bloodDyscrasias",
  "epilepsy",
  "diabetes",
  "pregnancy"
];

export const acceptStatement =
  "I confirm the accuracy of the information provided. I am committed to notifying the institution of any changes to my medication or health status prior to any medical procedures. I understand that I will not hold healthcare workers responsible for any unforeseen issues arising from potential inaccuracies in my statements. I consent to these terms by signing this form.";

const GroupedRadioButtons = ({
  value,
  onChange
}: {
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => (
  <RadioGroup row value={value} onChange={onChange}>
    <FormControlLabel
      value="true"
      control={<Radio size="small" />}
      label="Yes"
      labelPlacement="end"
    />
    <FormControlLabel
      value="false"
      control={<Radio size="small" />}
      label="No"
      labelPlacement="end"
    />
  </RadioGroup>
);

export const StyledTypography = ({ text }: { text: string }): JSX.Element => (
  <Typography sx={{ fontSize: "1rem" }} gutterBottom>
    {text}
  </Typography>
);

export interface ConsentData {
  // eslint-disable-next-line camelcase
  created_at: string;
  id?: number;
  signatureId?: number;
  signature?: string;
  hasUpdatedSignature?: boolean;
  resourceCentreId?: number;
  clientId?: number;
  client?: Client;
  medicalConditions: {
    heartDisease: boolean;
    asthma: boolean;
    thyroidDisorder: boolean;
    bloodPressure: boolean;
    bloodDyscrasias: boolean;
    epilepsy: boolean;
    diabetes: boolean;
    pregnancy: boolean;
    others: string;
  };
  allergies: string;
  currentMedications: string;
  isAccepted: boolean;
}

interface Props<T extends boolean> {
  isEditMode: T;
  data: T extends true ? ConsentData : null;
  onClose: () => void;
  client: Client;
  afterSave: (value: ConsentData) => void;
}

const ConsentCreateEditForm = <T extends boolean>({
  isEditMode,
  data,
  client,
  afterSave,
  onClose
}: Props<T>): JSX.Element => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isSubmitting, errors }
  } = useForm<FieldValues>({
    defaultValues:
      isEditMode && data
        ? {
            client,
            medicalConditions: data.medicalConditions,
            allergies: data.allergies || "",
            currentMedications: data.currentMedications || "",
            isAccepted: data.isAccepted
          }
        : {
            client,
            medicalConditions: {
              heartDisease: false,
              asthma: false,
              thyroidDisorder: false,
              bloodPressure: false,
              bloodDyscrasias: false,
              epilepsy: false,
              diabetes: false,
              pregnancy: false,
              others: ""
            },
            allergies: "",
            currentMedications: "",
            isAccepted: false
          },
    mode: "onChange"
  });

  const { medicalConditions } = watch();
  const dispatch = useAppDispatch();

  const [showMedicationAndAllergy, setShowMedicationAndAllergy] = React.useState(
    isEditMode && data
      ? { medication: Boolean(data.currentMedications), allergies: Boolean(data.allergies) }
      : { medication: false, allergies: false }
  );
  const [signature, setSignature] = React.useState(
    isEditMode && data
      ? { hasUpdatedSignature: false, signature: data.signature }
      : { hasUpdatedSignature: false, signature: "" }
  );

  React.useEffect(() => {
    if (!isEditMode) setValue("client", client);
  }, [client]);

  const onSubmit = async ({
    client: selectedClient,
    medicalConditions: conditions,
    allergies,
    currentMedications,
    isAccepted
  }: FieldValues) => {
    try {
      const formData: Partial<ConsentData> = {
        clientId: selectedClient.id,
        medicalConditions: conditions,
        allergies: showMedicationAndAllergy.allergies ? allergies : null,
        currentMedications: showMedicationAndAllergy.medication ? currentMedications : null,
        isAccepted,
        signature: signature.signature,
        hasUpdatedSignature: signature.hasUpdatedSignature,
        ...(isEditMode && { signatureId: data?.signatureId || null })
      };

      const response =
        isEditMode && data?.id
          ? await patchConsentForm({ data: formData, id: data.id })
          : await postConsentForm(formData);
      afterSave(response);
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: isEditMode
            ? updateSuccessMessage(MODULE.CONSENT_FORM)
            : createSuccessMessage(MODULE.CONSENT_FORM),
          variant: "success",
          autoTimeout: true
        })
      );
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: isEditMode
            ? updateErrorMessage(MODULE.CONSENT_FORM)
            : createErrorMessage(MODULE.CONSENT_FORM),
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Panel
      onClose={onClose}
      title={isEditMode ? t("consent.update") : t("consent.form")}
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%",
            mx: 4
          }}
        >
          <Button onClick={onClose}>{t("global.cancel")}</Button>
          <StatefulButton
            data-testmation="consentSave"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            form="consentForm"
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
            circularProgressProps={{ size: 16 }}
          >
            {t("global.save")}
          </StatefulButton>
        </Box>
      }
    >
      <Box
        sx={{
          px: 5,
          pt: 1,
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
        component="form"
        id="consentForm"
      >
        <Box display="flex" gap={3}>
          <StyledTypography text="Patient Name:" />
          <StyledTypography text={startCase(clientFullNameSelector(client))} />
        </Box>
        <div>
          <StyledTypography text="Do you have any of the problems mentioned below ?" />
          <Grid container columnSpacing={7} rowSpacing={1}>
            {healthConditions.map((item) => (
              <Grid
                item
                xs={6}
                key={item}
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography>{startCase(item)}</Typography>
                <GroupedRadioButtons
                  value={medicalConditions[item]}
                  onChange={(event) => {
                    const updatedValue = produce(medicalConditions, (draft) => {
                      draft[item] = event.target.value === "true";
                    });
                    setValue("medicalConditions", updatedValue);
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} mt={1}>
              <TextField
                fullWidth
                multiline
                label="Others"
                placeholder="If any others, write here"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={medicalConditions.others}
                onChange={(event) => {
                  const updatedValue = produce(medicalConditions, (draft) => {
                    draft.others = event.target.value;
                  });
                  setValue("medicalConditions", updatedValue);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <StyledTypography text="Are you currently taking any medications ?" />
          <GroupedRadioButtons
            value={showMedicationAndAllergy.medication}
            onChange={(event) => {
              setShowMedicationAndAllergy((prev) => ({
                ...prev,
                medication: (event.target as HTMLInputElement).value === "true"
              }));
            }}
          />
          {showMedicationAndAllergy.medication && (
            <FormTextField
              style={{ mt: 2 }}
              control={control}
              name="currentMedications"
              fullWidth
              multiline
              label="Medications"
              placeholder="If yes, mention the name of medicines"
              dataTestmation="currentMedications"
              errors={errors}
            />
          )}
        </div>
        <div>
          <StyledTypography text="Are you allergic to any medications ?" />
          <GroupedRadioButtons
            value={showMedicationAndAllergy.allergies}
            onChange={(event) => {
              setShowMedicationAndAllergy((prev) => ({
                ...prev,
                allergies: (event.target as HTMLInputElement).value === "true"
              }));
            }}
          />
          {showMedicationAndAllergy.allergies && (
            <FormTextField
              style={{ mt: 2 }}
              control={control}
              name="allergies"
              fullWidth
              label="Medications"
              placeholder="If yes, mention the name of medicines"
              multiline
              errors={errors}
              dataTestmation="allergies"
            />
          )}
        </div>
        <div>
          <FormControlLabel
            sx={{ alignItems: "flex-start" }}
            control={
              <Controller
                name="isAccepted"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            labelPlacement="end"
            label={acceptStatement}
          />
        </div>
        <div>
          <Signature signature={signature.signature} setSignatureData={setSignature} />
          <Divider sx={{ width: "200px", mb: 1, mt: 2 }} />
          <Typography>Patient&apos;s Signature</Typography>
        </div>
      </Box>
    </Panel>
  );
};

export default ConsentCreateEditForm;

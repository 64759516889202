import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import * as calendarFns from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { Booking } from "../../interfaces/BookingInterfaces";
import { clientFullNameSelector } from "../../reducers/client";
import { slotStartTimeSelector } from "../../reducers/slots";
import BookingStatus from "../Calendar/Booking/BookingStatus";
import "./BookingList.scss";

const getDay = (date: moment.Moment): string => {
  if (date.isSame(moment(), "day")) {
    return t("booking.today");
  }
  if (date.isSame(moment().add("day", 1), "day")) {
    return t("booking.tomorrow");
  }
  return "";
};

function BookingLists({
  filteredBookings,
  selectedBooking,
  onBookingSelect
}: {
  filteredBookings: Booking[];
  selectedBooking: Booking;
  onBookingSelect: (val: Booking | null) => void;
}): JSX.Element {
  return (
    <Box className="listTab">
      <List
        automation="BookingList"
        data={filteredBookings}
        rowHeight={50}
        defaultSortColumn="date"
        defaultSortOrder={-1}
        adjustHeightToContents
        isResponsive
        columns={[
          {
            key: "date",
            label: tl("booking.date"),
            sortable: true,
            sortFunction: (a, b) => (new Date(a.start) > new Date(b.start) ? 1 : -1),
            cellRenderer: (row) => {
              const date = moment(row.start);
              return (
                <Box display="flex" width={0.8} height={1} alignItems="center">
                  <Typography>
                    {calendarFns.bsFullDate(date)}
                    <br />
                    {slotStartTimeSelector(row)} {getDay(date)}
                  </Typography>
                </Box>
              );
            }
          },
          {
            key: "customerName",
            label: tl("booking.customerName"),
            cellRenderer: (row) => (
              <Box display="flex" color={row.client?.active ? "inherit" : "#db2323"}>
                <Typography>{clientFullNameSelector(row.client)}</Typography>
              </Box>
            ),
            sortable: true,
            sortFunction: (a, b) =>
              clientFullNameSelector(a.client) > clientFullNameSelector(b.client) ? 1 : -1
          },
          {
            key: "clinic",
            label: tl("booking.clinic"),
            hideInNarrowView: true,
            cellRenderer: (row) => (
              <Box display="flex">
                <Typography>{row.resourceCentre?.name}</Typography>
              </Box>
            ),
            sortable: true,
            sortFunction: (a, b) =>
              a.resourceCentre?.name.toLowerCase() > b.resourceCentre?.name.toLowerCase() ? 1 : -1
          },
          {
            key: "status",
            label: tl("booking.status"),
            cellRenderer: (row) => (
              <Box display="flex">
                <BookingStatus booking={row} hideLabel />
              </Box>
            ),
            sortable: false
          },
          {
            key: "sampleCollection",
            label: "Collect Sample From",
            cellRenderer: (row) => (
              <Box display="flex">
                <Typography>{row.collectionAtHome ? "Home" : "Lab"}</Typography>
              </Box>
            ),
            sortable: false
          },
          {
            key: "paymentMethod",
            label: "Payment Method",
            cellRenderer: (row) => (
              <Box display="flex">
                <Typography>{row.payment ? row.payment?.provider : "cash"}</Typography>
              </Box>
            ),
            sortable: false
          }
        ]}
        activeRow={selectedBooking && selectedBooking.id}
        onRowClick={(item) => {
          const isAlreadySelected = selectedBooking && selectedBooking.id === item.id;
          if (isAlreadySelected) {
            onBookingSelect(null);
          } else {
            onBookingSelect(item);
          }
        }}
      >
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No items to show.
          </Box>
        </EmptyView>
      </List>
    </Box>
  );
}

export default BookingLists;

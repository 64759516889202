import RemoveIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import produce from "immer";
import { chain } from "mathjs";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { getDiscountField } from "../../../actions/helpers/billHelper";
import * as NotificationActions from "../../../actions/notification";
import { putBatchService } from "../../../api/services";
import { putStockProduct } from "../../../api/stockProducts";
import DepartmentSelect from "../../../components/DepartmentSelect";
import NrsFormat from "../../../components/FormattingInput/NrsInput";
import Modal from "../../../components/Modal/Modal";
import { tl } from "../../../components/translate";
import classNames from "../../../helpers/classNames";
import hasOwnProperty from "../../../helpers/object";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { ChildGeneralLedger } from "../../../interfaces/Accounts";
import {
  BillDocumentType,
  BillItemFieldTypes,
  BillItemSource,
  BillItemType,
  StockUnitTypes
} from "../../../interfaces/BillInterfaces";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";
import { Stock, StockProducts } from "../../../interfaces/StockInterfaces";
import { unitOfProducts } from "../../../models/Service";
import { RootState } from "../../../store";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../accounts/hooks";
import LedgerSelect from "../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import styles from "./BillEditor.module.css";
import BillProductItem from "./BillProductItem";
import BillQuantity from "./BillQuantity";
import useIsSsfBilling from "../../../hooks/useIsSsfBilling";

const getUnitOptions = (valueUnit) => {
  const options = unitOfProducts.map((item) => ({ label: tl(`billing.${item}`), value: item }));
  if (valueUnit && !options.find(({ value }) => value === valueUnit)) {
    options.push({ label: valueUnit, value: valueUnit });
  }
  return options;
};

const getUnitValue = (valueUnit) => {
  const unitOptions = getUnitOptions(valueUnit);
  return unitOptions.find(({ value }) => (valueUnit ? value === valueUnit : value === "pcs"));
};

const handleStockUnitChange = (billItem, relatedStockBatchOptions) => {
  const updatedBillItem = produce(billItem, (bI) => {
    const newBillItem = bI;
    if (relatedStockBatchOptions && newBillItem.batchInfo?.batchId) {
      const relatedBatchOption = relatedStockBatchOptions.find(
        (item) => newBillItem.batchInfo.batchId === item.batchId
      );
      const relatedProduct = relatedBatchOption.product;
      if (newBillItem.stockUnitType === StockUnitTypes.package) {
        newBillItem.perUnit *= relatedProduct.unitsPerPackage;
      }
      if (newBillItem.stockUnitType === StockUnitTypes.unit) {
        newBillItem.perUnit = relatedBatchOption.unitPriceExcVAT;
      }
    }
    return newBillItem;
  });
  return updatedBillItem;
};

interface BillItemProps {
  focusAddItemButton: () => void;
  item: BillItemType;
  draft: BillDocumentType;
  products: ProductInterface[];
  onRowRemove: (val: number) => void;
  updateDraft: (bill: BillDocumentType) => void;
  isCreditNote: boolean;
  selectedStockProducts: StockProducts[];
  setSelectedStockProducts: (val: BillItemType[]) => void;
  batchOptions: { number: Stock[] };
  getBatchOptions: (id: number) => void;
  isVatRegistered: boolean;
  itemRef: React.RefObject<HTMLInputElement> | null;
  creditableQuantity?: number;
}
// commenting this code for now as it is not in use after latest change on onChnage handler
// leaving it here for future reference

// const hasSufficientQuantity = ({
//   enteredQuantity,
//   unitsPerPackage,
//   stockUnitType,
//   availableQuantity
// }) => {
//   if (stockUnitType === StockUnitTypes.unit) {
//     return availableQuantity >= enteredQuantity;
//   }
//   const totalSalesQuantity = Number(enteredQuantity) * Number(unitsPerPackage || 1);
//   return availableQuantity >= totalSalesQuantity;
// };

const BillItem = ({
  item,
  draft,
  onRowRemove,
  products,
  updateDraft,
  isCreditNote,
  selectedStockProducts,
  setSelectedStockProducts,
  batchOptions,
  getBatchOptions,
  isVatRegistered,
  itemRef,
  focusAddItemButton,
  creditableQuantity
}: BillItemProps) => {
  const { isSsfBilling } = useIsSsfBilling();

  const updateBillItem = (billItem, relatedStockBatchOptions?) => {
    const updatedBillItem = handleStockUnitChange(billItem, relatedStockBatchOptions);
    updateDraft({
      ...draft,
      billItems: draft.billItems
        .filter((billItemX) => billItemX.sNo !== item.sNo)
        .concat(updatedBillItem)
    });
  };
  const dispatch = useDispatch();

  const [showBatchPopup, setShowBatchPopup] = React.useState(false);
  const [notEnoughQuantityMsg, setNotEnoughQuantityMsg] = React.useState("");
  const [isOutOfStock, setIsOutOfStock] = React.useState(false);
  const isMobileScreen = useMobileScreen();
  const services = useSelector((state: RootState) => state.services.collection);
  React.useEffect(() => {
    let timerRef;
    if (notEnoughQuantityMsg) {
      timerRef = setTimeout(() => {
        setNotEnoughQuantityMsg("");
      }, 12000);
    }
    return () => timerRef && clearTimeout(timerRef);
  }, [notEnoughQuantityMsg]);
  const currBatchOptions = batchOptions[(item.productId || item.productData?.id) as number];
  const selectedBatchOption = currBatchOptions?.find(
    (option) => option.batchId === item?.batchInfo?.batchId
  );

  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (
      item?.description &&
      (item.source === BillItemSource.stocks || item.source === BillItemSource.services) &&
      !item.salesLedgerId &&
      isAccountSubscribed
    ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [item, isAccountSubscribed]);

  const [selectedBatchAvailableQuantity, setSelectedBatchAvailableQuantity] = React.useState(0);

  const [showLedgerSelect, setShowLedgerSelect] = React.useState<boolean>(false);
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger | null>(null);
  const { income } = extractSecondaryData(useCoaAll());
  const allIncomeLedgers = getAllChildGl(income);
  const [openInfoModal, setOpenInfoModal] = React.useState<boolean>(false);
  const [infoText, setInfoText] = React.useState<string>("");
  const quantityRef = React.useRef();

  React.useEffect(() => {
    if (!selectedBatchOption) {
      setSelectedBatchAvailableQuantity(0);
    } else {
      setSelectedBatchAvailableQuantity(
        item.stockUnitType === StockUnitTypes.unit
          ? Number(selectedBatchOption.quantity)
          : Math.floor(
              Number(selectedBatchOption.quantity) / selectedBatchOption.product.unitsPerPackage
            )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatchOption, item.unit]);

  const discountField = getDiscountField(draft.settings.discountSettings);
  const selectedProduct =
    products.find(
      (product) =>
        product.id === selectedBatchOption?.productId && hasOwnProperty(product, "stocks")
    ) || selectedBatchOption?.product;
  const stockProductUnits = selectedProduct
    ? [
        {
          label: selectedProduct.unit,
          value: selectedProduct.unit,
          type: "unit"
        },

        {
          label: selectedProduct.package,
          value: selectedProduct.package,
          type: "package"
        }
      ]
    : [];

  function renderLabel(label: string) {
    return isMobileScreen ? (
      <Box fontSize="10px" fontWeight="500" mr={1}>
        {label}
      </Box>
    ) : null;
  }
  const cells = draft.settings.showFields
    .filter((column) => {
      if ([BillItemFieldTypes.discountAmt, BillItemFieldTypes.discountPercent].includes(column)) {
        return discountField === column;
      }
      return column;
    })
    .map((column) => {
      switch (column) {
        case "delivered":
        case "returned":
          if (isCreditNote) {
            return null;
          }
          return (
            <Box
              key="delivered"
              flexBasis="116px"
              padding="4px"
              height="50px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("DELIVERED")}
              <Checkbox
                checked={item.delivered}
                onChange={(e) => {
                  updateBillItem({
                    ...item,
                    delivered: e.target.checked
                  });
                }}
                color="primary"
                value="checkedG"
                inputProps={{
                  "aria-label": "checkbox with default color"
                }}
              />
            </Box>
          );
        case "department":
          return (
            <Box
              key="department"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`], {
                [styles[`rowCell-${column}-mobile`]]: isMobileScreen
              })}
            >
              {renderLabel("DEPT")}
              <DepartmentSelect
                disabled={draft.type !== "invoice"}
                style={{ backgroundColor: "white", width: "70px" }}
                showShortCode
                id={item.departmentId}
                onChange={(department) => updateBillItem({ ...item, departmentId: department?.id })}
              />
            </Box>
          );
        case "description":
          return (
            <Box
              key="description"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`], {
                [styles[`rowCell-${column}-mobile`]]: isMobileScreen
              })}
            >
              {renderLabel("DESCRIPTION")}
              <BillProductItem
                focusAddItemButton={focusAddItemButton}
                quantityRef={quantityRef}
                itemRef={itemRef}
                products={products}
                billItem={item}
                updateBillItem={updateBillItem}
                disable={isCreditNote}
                batchOptions={batchOptions}
                getBatchOptions={getBatchOptions}
                totalQuantity={selectedBatchAvailableQuantity}
                isCreditNote={isCreditNote}
                selectedStockProducts={selectedStockProducts}
                setSelectedStockProducts={setSelectedStockProducts}
                setShowBatchPopup={setShowBatchPopup}
                setNotEnoughQuantityMsg={setNotEnoughQuantityMsg}
                setIsOutOfStock={setIsOutOfStock}
                draft={draft}
              />
              {showAlert && (
                <Box display="flex" justifyContent="center">
                  <Alert
                    sx={{ zIndex: 999, marginTop: "-20px" }}
                    action={
                      <Button
                        data-testmation="mapLedgerButton"
                        variant="outlined"
                        color="inherit"
                        onClick={() => {
                          setShowLedgerSelect(true);
                        }}
                      >
                        Map Ledger
                      </Button>
                    }
                    severity="warning"
                  >
                    This item is not mapped with ledger.
                  </Alert>
                </Box>
              )}
              <Modal
                open={showLedgerSelect}
                title="Select Ledger for Service"
                footer={
                  <>
                    <Button
                      onClick={() => {
                        setShowLedgerSelect(false);
                        setSelectedLedger(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-testmation="saveLedgerMappingButton"
                      disabled={!selectedLedger}
                      onClick={async () => {
                        try {
                          if (item.source === BillItemSource.services && !item.productId) {
                            updateBillItem({
                              ...item,
                              salesLedgerId: selectedLedger?.id
                            });
                            setShowLedgerSelect(false);
                            setSelectedLedger(null);
                            return;
                          }
                          if (item.source === BillItemSource.stocks) {
                            const res = await putStockProduct({
                              id: item.productId || item.productData?.id,
                              salesLedgerId: selectedLedger?.id
                            } as StockProducts);
                            if (res?.salesLedgerId) {
                              updateBillItem({
                                ...item,
                                salesLedgerId: res.salesLedgerId
                              });
                              setShowLedgerSelect(false);
                              setSelectedLedger(null);
                            }
                          } else {
                            const selectedService = services.find(
                              (service) => service.id === item.productId
                            );
                            const res = await putBatchService({
                              id: item.productId,
                              salesLedgerId: selectedLedger?.id,
                              products: selectedService?.products || [],
                              productType: selectedService.productType,
                              name: selectedService.name
                            } as ServiceInterface);
                            if (res?.salesLedgerId) {
                              updateBillItem({
                                ...item,
                                salesLedgerId: res.salesLedgerId
                              });
                              setShowLedgerSelect(false);
                              setSelectedLedger(null);
                            }
                          }
                        } catch (error) {
                          dispatch(
                            NotificationActions.notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Sorry ! Product is not mapped with ledger.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      Save
                    </Button>
                  </>
                }
              >
                <LedgerSelect
                  options={allIncomeLedgers || []}
                  selected={selectedLedger}
                  onChange={(value) => setSelectedLedger(value)}
                />
              </Modal>
            </Box>
          );
        case "quantity":
          return (
            <Box
              key="quantity"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("QUANTITY")}
              <BillQuantity
                quantityRef={quantityRef}
                infoText={infoText}
                setInfoText={setInfoText}
                item={item}
                column={column}
                isCreditNote={isCreditNote}
                batchOptions={currBatchOptions}
                showBatchPopup={showBatchPopup}
                setShowBatchPopup={setShowBatchPopup}
                updateBillItem={updateBillItem}
                selectedBatchAvailableQuantity={selectedBatchAvailableQuantity}
                selectedStockProducts={selectedStockProducts}
                setSelectedStockProducts={setSelectedStockProducts}
                notEnoughQuantityMsg={notEnoughQuantityMsg}
                setNotEnoughQuantityMsg={setNotEnoughQuantityMsg}
                creditableQuantity={creditableQuantity}
              />
            </Box>
          );
        case "unit":
          return (
            <Box
              key="unit"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("UNIT")}
              <CreatableSelect
                isDisabled={
                  isOutOfStock || (isCreditNote && item.source === BillItemSource.services)
                }
                options={
                  stockProductUnits.length ? stockProductUnits : getUnitOptions(item[column])
                }
                value={getUnitValue(item[column])}
                filterOption={({ value }, searchTxt) => value.toLowerCase().includes(searchTxt)}
                onFocus={(e) => e.target.select()}
                // eslint-disable-next-line consistent-return
                onChange={(e) => {
                  // if the item is from services
                  if (item.source === BillItemSource.services) {
                    updateBillItem(
                      {
                        ...item,
                        unit: e.value,
                        stockUnitType: e.type,
                        stockProductUnitsPerPackage: selectedProduct?.unitsPerPackage
                      },
                      batchOptions[item.productData?.id || item.productId]
                    );
                  }

                  // if the item is from stocks
                  if (item.source === BillItemSource.stocks) {
                    let { quantity } = item;
                    if (e.type !== item?.stockUnitType) {
                      if (e.type === StockUnitTypes.unit) {
                        quantity = Math.abs(item.quantity) * selectedProduct?.unitsPerPackage;
                      } else {
                        quantity = Math.floor(
                          Math.abs(item.quantity) / selectedProduct?.unitsPerPackage
                        );
                      }
                    }

                    updateBillItem(
                      {
                        ...item,
                        unit: e.value,
                        stockUnitType: e.type,
                        quantity,
                        stockProductUnitsPerPackage: selectedProduct?.unitsPerPackage
                      },
                      batchOptions[item.productData?.id || item.productId]
                    );
                  }
                }}
              />
            </Box>
          );
        case "perUnit":
          return (
            <Box
              key="perUnit"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("PRICE")}
              <TextField
                value={item[column]}
                margin="none"
                disabled={isCreditNote || isOutOfStock || isSsfBilling}
                onFocus={(e) => e.target.select()}
                variant="outlined"
                InputProps={{
                  inputComponent: NrsFormat,
                  classes: { input: styles.rowInputRoot },
                  startAdornment: tl("rs")
                }}
                onChange={(e) => {
                  if (Number(e.target.value) !== item[column]) {
                    updateBillItem({
                      ...item,
                      perUnit: e.target.value,
                      calculationBasis: "perUnit"
                    });
                  }
                }}
              />
            </Box>
          );
        case BillItemFieldTypes.discountAmt:
          return (
            <Box
              key={BillItemFieldTypes.discountAmt}
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("DISCOUNT AMT")}
              <TextField
                value={item[column]}
                disabled={isCreditNote || isOutOfStock || item.nonDiscountable}
                margin="none"
                onFocus={(e) => e.target.select()}
                variant="outlined"
                InputProps={{
                  inputComponent: NrsFormat,
                  classes: { input: styles.rowInputRoot },
                  startAdornment: tl("rs")
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ allowNegative: isCreditNote }}
                onChange={(e) => {
                  const discountAmt = Number(e.target.value);
                  const maxDiscAmt = chain(item.perUnit || 0)
                    .multiply(item.quantity || 0)
                    .done();
                  if (discountAmt !== item[column]) {
                    updateBillItem({
                      ...item,
                      discountAmt: discountAmt <= maxDiscAmt ? discountAmt : maxDiscAmt
                    });
                  }
                }}
              />
            </Box>
          );
        case BillItemFieldTypes.discountPercent:
          return (
            <Box
              key={BillItemFieldTypes.discountPercent}
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("DISCOUNT %")}
              <TextField
                value={item[column]}
                disabled={isCreditNote || item.nonDiscountable}
                margin="none"
                onFocus={(e) => e.target.select()}
                variant="outlined"
                InputProps={{
                  inputComponent: NrsFormat,
                  classes: { input: styles.rowInputRoot },
                  endAdornment: "%"
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ allowNegative: isCreditNote }}
                onChange={(e) => {
                  const disctPct = Number(e.target.value);
                  if (disctPct !== item[column]) {
                    updateBillItem({
                      ...item,
                      discountPercent: disctPct <= 100 ? disctPct : 100
                    });
                  }
                }}
              />
            </Box>
          );
        case BillItemFieldTypes.info:
          return (
            <Box pl={1}>
              {renderLabel("INFO")}
              <Tooltip title={`${item[column] ? item[column] : "Add Info"}`}>
                <IconButton
                  disabled={isCreditNote}
                  onClick={() => {
                    setOpenInfoModal((prev) => !prev);
                    setInfoText(item[column]);
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
              <Dialog open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
                <Box minWidth="500px">
                  <DialogTitle>Info</DialogTitle>
                  <Divider />
                  <TextField
                    sx={{ padding: "20px" }}
                    autoFocus
                    multiline
                    maxRows={4}
                    rows={3}
                    value={infoText}
                    fullWidth
                    variant="outlined"
                    onChange={({ target }) => setInfoText(target.value)}
                  />
                </Box>
                <DialogActions>
                  <Button variant="outlined" onClick={() => setOpenInfoModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      updateBillItem({
                        ...item,
                        info: infoText
                      });
                      setOpenInfoModal(false);
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          );
        case BillItemFieldTypes.VATPercent:
          return (
            <Box
              key={BillItemFieldTypes.VATPercent}
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("VAT %")}
              <TextField
                value={item[column]}
                margin="none"
                disabled={Boolean(item.productId) || isCreditNote || !isVatRegistered}
                onFocus={(e) => e.target.select()}
                variant="outlined"
                InputProps={{
                  classes: { input: styles.rowInputRoot },
                  endAdornment: "%"
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ allowNegative: isCreditNote }}
                onChange={(e) => {
                  if (Number(e.target.value) !== item[column]) {
                    updateBillItem({
                      ...item,
                      vatPct: Number(e.target.value)
                    });
                  }
                }}
              />
            </Box>
          );
        case "grossTotal":
          return (
            <Box
              key="grossTotal"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("TOTAL")}
              <TextField
                value={item[column]}
                margin="none"
                disabled
                variant="outlined"
                onFocus={(e) => e.target.select()}
                InputProps={{
                  inputComponent: NrsFormat,
                  classes: { input: styles.rowInputRoot },
                  startAdornment: tl("rs")
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ allowNegative: isCreditNote }}
                onChange={(e) => {
                  if (item.grossTotal !== Number(e.target.value)) {
                    updateBillItem({
                      ...item,
                      grossTotal: e.target.value,
                      calculationBasis: "grossTotal"
                    });
                  }
                }}
              />
            </Box>
          );
        case "total":
          return (
            <Box
              key="total"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("TOTAL")}
              <TextField
                value={item[column]}
                margin="none"
                disabled
                variant="outlined"
                onFocus={(e) => e.target.select()}
                InputProps={{
                  inputComponent: NrsFormat,
                  classes: { input: styles.rowInputRoot },
                  startAdornment: tl("rs")
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ allowNegative: isCreditNote }}
                onChange={(e) => {
                  if (item.total !== Number(e.target.value)) {
                    updateBillItem({
                      ...item,
                      total: e.target.value,
                      calculationBasis: "total"
                    });
                  }
                }}
              />
            </Box>
          );
        case BillItemFieldTypes.type:
          return (
            <Box
              key="itemType"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("Type")}
              <TextField
                disabled
                value={item[column]}
                InputProps={{ classes: { input: styles.rowInputRoot } }}
                margin="none"
                variant="outlined"
              />
            </Box>
          );
        default:
          return (
            <Box
              key="itemDefault"
              flexBasis="116px"
              padding="4px"
              className={classNames(styles.rowCell, styles[`rowCell-${column}`])}
            >
              {renderLabel("VAT AMT")}
              <TextField
                disabled={isCreditNote}
                value={item[column]}
                InputProps={{ classes: { input: styles.rowInputRoot } }}
                margin="none"
                variant="outlined"
              />
            </Box>
          );
      }
    });

  if (isCreditNote && !item.returned) return null;
  return (
    <Box
      className={classNames(styles.billRow, "billRow")}
      display="flex"
      flexWrap="wrap"
      my={isMobileScreen ? 2 : 0}
    >
      {cells}
      <Box
        key="removeItem"
        className="rowDeleteButton"
        flexBasis="60px"
        padding="4px"
        display="flex"
        height="40px"
        justifyContent="center"
        alignItems="center"
        mt={isMobileScreen ? "16px" : "0"}
        onClick={() => {
          if (isCreditNote) {
            const itemsToReturn = draft.billItems.filter((billItem) => billItem.returned);
            if (itemsToReturn.length <= 1) {
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "At least one product is required for credit note.",
                  autoTimeout: true
                })
              );
              return;
            }
            updateBillItem({
              ...item,
              returned: !item.returned
            });
            onRowRemove(item.sNo);
          } else {
            if (draft.billItems.length <= 1) {
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "At least one item is required for invoice.",
                  autoTimeout: true
                })
              );
              return;
            }
            onRowRemove(item.sNo);
            setSelectedStockProducts((prevState) =>
              prevState.filter((product) => product.batchId !== item?.batchInfo?.batchId)
            );
          }
        }}
      >
        <RemoveIcon color="error" />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isVatRegistered: state.userContext.resourceCentre.settings.billingSettings.vatRegistered
});

export default connect(mapStateToProps, null)(BillItem);

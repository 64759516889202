import * as React from 'react';
import Modal, { Footer } from '../../../components/Modal/Modal';
import { connect } from 'react-redux';
import { serviceProviderActions } from '../../../actions/index';
import { bookSlot } from '../../../actions/slot';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Schedule from '@mui/icons-material/Schedule';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { navigateRemoveModal } from '../../../actions/navigation';
import { spFullNameSelector } from '../../../reducers/serviceProvider';
import { slotFromTimeSelector, slotUntilTimeSelector } from '../../../reducers/slots';
import { CalendarFunctions as calFns } from '../../../components/Calendar';
import { tl } from '../../../components/translate';
import styles from './styles.module.css';

interface propsType {
  serviceProviderId: number;
  serviceProvider: any;
  slot: any;
  next?: () => void;
  bookSlot: (slotId, serviceProviderId, reasonOfVisit, clientName, clientPhoneNo) => void;
  getServiceProvider: (id: number) => void;
  onClose: () => void;
}

interface StateType {
  clientId?: null,
  clientName: string,
  clientAddress: string,
  clientPhoneNo: string,
  reasonOfVisit: string
}
class SlotBookModal extends React.Component<propsType, StateType> {
  state: Readonly<StateType> = {
    clientId: null,
    clientName: '',
    clientAddress: '',
    clientPhoneNo: '',
    reasonOfVisit: ''
  }
  public componentDidMount() {
    if (!this.props.serviceProvider) {
      this.props.getServiceProvider(this.props.serviceProviderId);
    }
  }
  private handleChange = (field, value) => {
    this.setState({ [field]: value })
  }
  public render() {
    if (!this.props.serviceProvider) return null;
    const { serviceProvider, slot, serviceProviderId, next} = this.props;
    const { state } = this;
    return (
      <Modal open={true}
        saveLabel={tl('booking.save')}
        onSave={() => 
          this.props.bookSlot(slot.id, serviceProviderId, state.reasonOfVisit, state.clientName, state.clientPhoneNo, next)}
        onClose={() => this.props.onClose()} title={'Book an appointment'}
      >
        <Grid container style={{ flexGrow: 1 }} >
          <Grid item xs={12} md={6} lg={6}>
            <Avatar alt="Remy Sharp" src="https://picsum.photos/100/100/?random" className={styles.bigAvatar} />
            <Typography component="div">
              <Box fontWeight={900} fontSize={'1.3em'} component='div'>
                {spFullNameSelector(serviceProvider)}
              </Box>
              <Box fontWeight={300} fontSize={'0.8em'} component='span'>
                {serviceProvider.speciality}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography component="div">
              <Box className={styles.box} component='span' fontWeight={900}><Schedule className={styles.icon} /></Box>
              <Box className={styles.box} component='span' fontWeight={900} fontSize={'1.1em'} m={1}>{slotFromTimeSelector(slot)}</Box>
              <Box className={styles.box} component='span' fontWeight={300} m={1}>{'-'}</Box>
              <Box className={styles.box} component='span' fontWeight={300} m={1}>{slotUntilTimeSelector(slot)}</Box>
            </Typography>
            <Typography component="div">
              <CalendarToday className={styles.icon} />
              <Box className={styles.box} component='span' fontWeight={500} m={1}>{calFns.bsFullDate(slot.from)}</Box>
              <Box className={styles.box} component='span' fontWeight={300} fontSize={11} m={1}>{calFns.adFullDate(slot.frm)}</Box>
            </Typography>
          </Grid>

          <Grid item xs={12} lg={12}>
            <TextField
              id="outlined-name"
              className={styles.input}
              label="Client name"
              value={state.clientName}
              onChange={(e) => this.handleChange('clientName', e.target.value)}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              id="outlined-clientPhoneNo"
              className={styles.input}
              label="Phone no."
              value={state.clientPhoneNo}
              onChange={(e) => this.handleChange('clientPhoneNo', e.target.value)}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              id="outlined-reasonOfVisit"
              className={styles.input}
              label="Reason of visit"
              value={state.reasonOfVisit}
              onChange={(e) => this.handleChange('reasonOfVisit', e.target.value)}
              margin="dense"
              multiline
              maxRows="6"
            />
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default connect(({ resources, slots }, { serviceProviderId, slotId }) => {
  return ({
    serviceProvider: resources.resourceCentreServiceProviders.find(sp => sp.id === serviceProviderId),
    slot: slots.find(s => s.id === slotId)
  })
}, (dispatch) => ({
  getServiceProvider: (id) => {
    dispatch(serviceProviderActions.getResourceCentreServiceProvider(id))
  },
  bookSlot: async (slotId, serviceProviderId, reasonOfVisit, clientName, clientPhoneNo, next) => {
    await dispatch(bookSlot(slotId, { serviceProviderId, reasonOfVisit, clientName, clientPhoneNo }));
    if (next) next();
    dispatch(navigateRemoveModal('SlotBookModal'));
  },
  onClose: () => dispatch(navigateRemoveModal('SlotBookModal'))
}))(SlotBookModal);
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bed } from "../api/wards";
import { fetchBeds, fetchWards } from "../slices/bedManagementSlice";
import { RootState } from "../store";

export interface BedType extends Bed {
  name: string;
}

export default function useIpdBeds(): BedType[] {
  const wards = useSelector((state: RootState) => state.bedManagement.wards);
  const beds = useSelector((state: RootState) => state.bedManagement.beds);
  const hasWards = wards.length > 0;
  const hasBeds = beds.length > 0;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!hasWards) {
      dispatch(fetchWards());
    }
    if (!hasBeds) {
      dispatch(fetchBeds());
    }
  }, [dispatch, hasWards, hasBeds]);

  const formattedBeds = React.useMemo(
    () =>
      wards.flatMap((ward) =>
        beds
          .filter((bed) => bed.wardId === ward.id)
          .map((bed) => ({ ...bed, name: `${ward.shortCode}-${bed.number}` }))
      ),
    [wards, beds]
  );
  return formattedBeds;
}

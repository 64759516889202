import { Typography } from "@mui/material";
import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { AgeGroupStatInterface } from ".";

const StatByAgeGroup = ({
  ageGroupStatistics
}: {
  ageGroupStatistics: AgeGroupStatInterface[];
}): JSX.Element => (
  <ResponsiveContainer width="100%" aspect={1}>
    <div
      style={{
        fontFamily: "Poppins, sans-serif",
        fontSize: "12px"
      }}
    >
      <Typography marginBottom="30px" display="flex-start">
        <b>Stats By Age Group</b>
        <br />
        <span>Showing the data from 2022-02-01</span>
        <hr />
      </Typography>
      <BarChart width={400} height={350} data={ageGroupStatistics}>
        <XAxis dataKey="ageGroup" />
        <YAxis />
        <Tooltip contentStyle={{ fontWeight: "bold", borderRadius: "10px" }} />
        <Bar dataKey="female" stackId="a" fill="#f20f66" />
        <Bar dataKey="male" stackId="a" fill="#1079b2" />
      </BarChart>
    </div>
  </ResponsiveContainer>
);

export default StatByAgeGroup;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { makePaymentIntent } from "../../api/subscriptions";
import useCan from "../../hooks/useCan";
import { paySMSInvoiceSuperAdmin } from "../../slices/subscriptionSlice";
import { SubscriptionInvoices } from "../../interfaces/Subscription";
import * as NotificationActions from "../../actions/notification";

interface KhaltiPaymentSMSProps {
  invoiceId?: number;
  generateInvoiceAction?: (cbAction: (invoice: SubscriptionInvoices) => void) => void;
  afterAction?: () => void;
  onOkClick: () => void;
  buttonText?: string;
}

enum DialogStateTypes {
  error = "error",
  success = "success"
}

export default function KhaltiPaymentSMS({
  invoiceId,
  generateInvoiceAction,
  afterAction,
  onOkClick,
  buttonText
}: KhaltiPaymentSMSProps): JSX.Element {
  const dispatch = useDispatch();

  const canUseSuperAdminActions = useCan("deny", { superAdminPass: true });

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<string>("");
  const [dialogStateType, setDialogStateType] = React.useState<DialogStateTypes>();

  async function onPaySuperAdmin(id: number): Promise<void> {
    dispatch(paySMSInvoiceSuperAdmin(id));
    setDialogOpen(true);
    setDialogState("Transaction has been successfully completed!");
    setDialogStateType(DialogStateTypes.success);
    if (afterAction) afterAction();
  }

  async function onPay(id: number): Promise<void> {
    try {
      const response = await makePaymentIntent(id);
      window.open(response.payment_url, "_self");
    } catch ({ message }) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: message as string,
          autoTimeout: true,
          timeout: 10000
        })
      );
    }
  }

  return (
    <div>
      <Button
        data-testmation="purchaseSmsPay"
        variant="contained"
        color="primary"
        style={{ marginLeft: "8px", padding: "5px 10px", height: "35px" }}
        onClick={() => {
          if (invoiceId) {
            if (canUseSuperAdminActions) {
              onPaySuperAdmin(invoiceId);
            } else {
              onPay(invoiceId);
            }
          } else if (generateInvoiceAction) {
            generateInvoiceAction((invoice: SubscriptionInvoices) => {
              onPay(invoice.id);
            });
          }
        }}
      >
        {buttonText}
      </Button>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Alert!</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            {dialogStateType === DialogStateTypes.success ? (
              <CheckCircleIcon htmlColor="green" fontSize="large" />
            ) : (
              <CancelIcon htmlColor="red" fontSize="large" />
            )}
          </Box>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            {dialogState}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              onOkClick();
            }}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

KhaltiPaymentSMS.defaultProps = {
  invoiceId: undefined,
  generateInvoiceAction: undefined,
  afterAction: () => ({}),
  buttonText: "Pay Now"
};

import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";
import { push } from "connected-react-router";
import {
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import startCase from "lodash/startCase";
import { useState } from "react";
import { resourceCentreActions, ResourceCentreActionsType } from "../../actions";
import { navigateRemoveModal, showDialog } from "../../actions/navigation";
import List, { Filters, ListRowActions } from "../../components/List";
import { fields } from "../../models/ResourceCentre";
import Form from "../../components/Form";
import Modal from "../../components/Modal/Modal";
import { tl } from "../../components/translate";
import AddressSelect, { AddressOption } from "../Address/AddressSelect";
import * as addressActions from "../../actions/addressOptions";
import { IThunkDispatch, RootState } from "../../store";
import hasOwnProperty from "../../helpers/object";
import { ResourceCentre as IResourceCentre } from "../../interfaces/ResourceCentreInterface";
import useCan from "../../hooks/useCan";
import { getResourceCentreName } from "../Reports/DueReport";
import { EmptyView } from "../../components/OList/List";
import { ListActions, Menu, MenuItem } from "../../components/OList";
import { downloadCSV } from "../../helpers/files";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { deleteEverything } from "../../api/resourceCentre";
import { notificationAdd } from "../../actions/notification";
import {
  Subscription,
  SUBSCRIPTION_MODE,
  SubscriptionModeType
} from "../../interfaces/Subscription";
import { ReverseMap } from "../../helpers/types";
import { useAppSelector } from "../../store/hooks";
import StatefulButton from "../../components/StatefulButton/StatefulButton";

export interface ResourceCentreProps {
  actions: ResourceCentreActionsType;
  navigateTo: (any) => void;
  goBack: () => void;
  resourceCentres: Array<IResourceCentre & { subscription: Subscription }>;
  editMode?: boolean;
  createMode?: boolean;
  resourceId?: number;
  getAddressOptions: () => void;
  addressOptions: Array<AddressOption>;
  authenticated: boolean;
}

export interface State {
  formData: Record<string, unknown>;
  addressFormData: Record<string, unknown>;
  errors: unknown;
  saveAttempted: boolean;
  addressOptions: [];
  isKitchenPharmacy: boolean;
}

const docColumns = [
  "Name",
  "Address",
  "City",
  "Phone",
  "Status",
  "Features",
  "Plan",
  "Main Branch Id",
  "Subscription Start",
  "Subscription End",
  "Trial Mode",
  "Sales Person",
  "Lead By",
  "MOU Link"
];

const docRowProcessor = ({ name, address, city, phone, status, subscription, branch }) => {
  const {
    leadBy,
    mouLink,
    salesPerson,
    packageName,
    features,
    subscriptionStart,
    subscriptionEnd,
    isTrial
  } = subscription || {};

  const activeFeatures = Object.keys(features || {}).reduce((acc, key) => {
    if (features[key].subscribed) {
      return [...acc, key];
    }
    return acc;
  }, []);
  return [
    name,
    address,
    city,
    phone,
    status,
    activeFeatures.join(", "),
    packageName || "",
    branch?.headResourceCentreId || "",
    subscriptionStart ? convertADtoBS(new Date(subscriptionStart)).formatted4 : "",
    subscriptionEnd ? convertADtoBS(new Date(subscriptionEnd)).formatted4 : "",
    isTrial ? "True" : "False",
    salesPerson || "",
    leadBy || "",
    mouLink || ""
  ];
};

export enum SUBSCRIPTION_STATUS_FILTER {
  ALL = "all",
  TERMINATED = "terminated",
  PAUSED = "paused",
  ACTIVE = "active"
}

const StatusColorMap = {
  [SUBSCRIPTION_STATUS_FILTER.TERMINATED]: "error",
  [SUBSCRIPTION_STATUS_FILTER.ACTIVE]: "primary",
  [SUBSCRIPTION_STATUS_FILTER.PAUSED]: "warning"
};

export type SubscriptionStatusType = ReverseMap<typeof SUBSCRIPTION_STATUS_FILTER>;

enum RC_ACTIONS {
  CREATE = "POST_RESOURCECENTRE",
  UPDATE = "PUT_RESOURCECENTRE"
}

type RcActionsType = ReverseMap<typeof RC_ACTIONS>;

const getSubscriptionStatus = (subscription: Subscription): SUBSCRIPTION_STATUS_FILTER => {
  if (!subscription || !subscription.activeSubscription) {
    return SUBSCRIPTION_STATUS_FILTER.TERMINATED;
  }
  if (subscription.isInvoicePaused) {
    return SUBSCRIPTION_STATUS_FILTER.PAUSED;
  }
  return SUBSCRIPTION_STATUS_FILTER.ACTIVE;
};

const extractSubscriptionDetails = (rcs: Array<IResourceCentre & { subscription: Subscription }>) =>
  rcs.map((item) => {
    let mode = "";
    if (item.subscription?.isTrial) {
      mode = SUBSCRIPTION_MODE.TRIAL;
    } else if (item.subscription?.isTest) {
      mode = SUBSCRIPTION_MODE.TEST;
    } else if (!item.subscription?.isTrial && !item.subscription?.isTest) {
      mode = SUBSCRIPTION_MODE.PRODUCTION;
    }
    return {
      ...item,
      packageName: item.subscription?.packageName || "",
      salesPerson: item.subscription?.salesPerson || "",
      initialAmount: Number(item.subscription?.initialAmount || 0),
      paymentPrice: Number(item.subscription?.paymentPrice || 0),
      paymentType: item.subscription?.paymentType || "",
      status: getSubscriptionStatus(item.subscription),
      mode
    };
  });

const filterData = {
  filters: [
    { key: SUBSCRIPTION_STATUS_FILTER.ALL, title: startCase(SUBSCRIPTION_STATUS_FILTER.ALL) },
    {
      key: SUBSCRIPTION_STATUS_FILTER.ACTIVE,
      title: startCase(SUBSCRIPTION_STATUS_FILTER.ACTIVE)
    },
    {
      key: SUBSCRIPTION_STATUS_FILTER.PAUSED,
      title: startCase(SUBSCRIPTION_STATUS_FILTER.PAUSED)
    },
    {
      key: SUBSCRIPTION_STATUS_FILTER.TERMINATED,
      title: startCase(SUBSCRIPTION_STATUS_FILTER.TERMINATED)
    }
  ]
};

const modeFilterData = {
  filters: [
    { key: SUBSCRIPTION_MODE.ALL, title: startCase(SUBSCRIPTION_MODE.ALL) },
    {
      key: SUBSCRIPTION_MODE.PRODUCTION,
      title: startCase(SUBSCRIPTION_MODE.PRODUCTION)
    },
    {
      key: SUBSCRIPTION_MODE.TEST,
      title: startCase(SUBSCRIPTION_MODE.TEST)
    },
    {
      key: SUBSCRIPTION_MODE.TRIAL,
      title: startCase(SUBSCRIPTION_MODE.TRIAL)
    }
  ]
};
export const ResourceCentre = ({
  authenticated,
  createMode,
  editMode,
  resourceId,
  resourceCentres,
  addressOptions,
  actions,
  getAddressOptions,
  navigateTo,
  goBack
}: ResourceCentreProps): JSX.Element => {
  const [state, setState] = React.useState<State>({
    formData: null,
    addressFormData: null,
    errors: { name: ["Name is required"] },
    saveAttempted: false,
    addressOptions: []
  });
  const [isDestroyModalOpen, setIsDestroyModalOpen] = React.useState(false);
  const [selectedRcIdToDestroy, setSelectedRcIdToDestroy] = React.useState(null);
  const errorsList = useAppSelector((rootState) => rootState.error);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const rcsWithSubscriptionData = extractSubscriptionDetails(resourceCentres);

  function getResourceCentre(id) {
    return (
      rcsWithSubscriptionData.find((rc) => Number(rc.id) === Number(id)) || ({} as IResourceCentre)
    );
  }
  const dispatch = useDispatch();
  const resourceCentreStatus = React.useMemo(() => {
    const all = rcsWithSubscriptionData.length;
    let active = 0;
    let paused = 0;
    let terminated = 0;
    let test = 0;
    let trial = 0;
    let production = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < all; i++) {
      // if rc is new, there will be no subscription
      const { subscription } = rcsWithSubscriptionData[i];
      if (!subscription || !subscription.activeSubscription) {
        terminated += 1;
      } else if (subscription && !subscription.isInvoicePaused) {
        active += 1;
        if (subscription.isTest) {
          test += 1;
        } else if (subscription.isTrial) {
          trial += 1;
        } else {
          production += 1;
        }
      } else if (subscription && subscription.isInvoicePaused) {
        paused += 1;
      }
    }
    return { all, active, paused, terminated, test, trial, production };
  }, [rcsWithSubscriptionData]);

  React.useEffect(() => {
    actions.getResourceCentres();
    if (!addressOptions.length) {
      getAddressOptions();
    }
    const currentResourceCentre = getResourceCentre(resourceId);
    setState(
      (prevState) =>
        ({
          ...prevState,
          formData: editMode
            ? getResourceCentre(resourceId)
            : ({ customPrefix: "OKT" } as Record<string, unknown>),
          addressFormData: {
            id: currentResourceCentre?.addressData?.id,
            palikaId: currentResourceCentre?.addressData?.palikaId,
            place: currentResourceCentre?.addressData?.place || ""
          }
        } as State)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteRc = async () => {
    setIsSubmitting(true);
    try {
      if (selectedRcIdToDestroy) {
        await deleteEverything({ id: selectedRcIdToDestroy });
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: "Resource centre deleted successfully.",
            autoTimeout: true,
            variant: "success"
          })
        );
        dispatch(resourceCentreActions.getResourceCentres());
      }
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: err?.data?.message || "Failed to delete resource centre",
          autoTimeout: true,
          variant: "error"
        })
      );
    }
    setIsSubmitting(false);
    setIsDestroyModalOpen(false);
    setSelectedRcIdToDestroy(null);
  };

  const handleNotification = (action: RcActionsType) => {
    const errorData = errorsList.filter((item) => item.key === action);
    if (errorData.length) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: errorData[0].message || "Something went wrong",
          autoTimeout: true,
          variant: "error"
        })
      );
    } else {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message:
            action === RC_ACTIONS.CREATE
              ? "Resource center created successfully"
              : "Resource center updated successfully",
          autoTimeout: true,
          variant: "success"
        })
      );
    }
  };

  const onSave = async () => {
    const { formData, addressFormData, errors } = state;
    const size = Object.keys(errors || {}).length;
    const data = {
      ...formData,
      addressFormData
    } as {
      id?: number;
      settings?: { billingSettings: { panNo: string } };
    };
    if (!data || size > 0) {
      setState((prevState) => ({ ...prevState, saveAttempted: true }));
    } else {
      setIsSubmitting(true);
      if (data.id) {
        if (data.settings.billingSettings.panNo === null) {
          data.settings.billingSettings.panNo = "";
        }
        if (hasOwnProperty(data, "location") && data.location) {
          data.location = (data.location as Array<unknown>).map((item) => Number(item));
        }
        await actions.putResourceCentre(data);
        handleNotification(RC_ACTIONS.UPDATE);
      }
      if (!data.id) {
        await actions.postResourceCentre({ ...data, localCalendar: true, fiscalStartMonth: 3 });
        handleNotification(RC_ACTIONS.CREATE);
      }
      setIsSubmitting(false);
      goBack();
    }
  };

  function onChange(newFormData, errors) {
    setState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, ...newFormData },
      errors
    }));
  }

  const showCreateButton = useCan("deny", { superAdminPass: true, restrictSupportAdmin: true });

  const [filter, setFilter] = React.useState<SubscriptionStatusType>(
    SUBSCRIPTION_STATUS_FILTER.ALL
  );

  const [showPublicRcsOnly, setShowPublicRcsOnly] = React.useState(false);

  const [modeFilter, setModeFilter] = React.useState<SubscriptionModeType>(SUBSCRIPTION_MODE.ALL);

  const resourceCentreOptions = React.useMemo(() => {
    let finalRcs = rcsWithSubscriptionData;

    if (showPublicRcsOnly) {
      finalRcs = finalRcs.filter((rc) => !!rc.publicBooking);
    }

    if (filter !== SUBSCRIPTION_STATUS_FILTER.ALL) {
      finalRcs = finalRcs.filter((rc) => {
        const { subscription } = rc;
        if (filter === SUBSCRIPTION_STATUS_FILTER.TERMINATED) {
          return !subscription || !subscription.activeSubscription;
        }
        if (filter === SUBSCRIPTION_STATUS_FILTER.ACTIVE) {
          return subscription && subscription.activeSubscription && !subscription.isInvoicePaused;
        }
        return subscription && subscription.activeSubscription && subscription.isInvoicePaused;
      });
    }

    return modeFilter === SUBSCRIPTION_MODE.ALL
      ? finalRcs
      : finalRcs.filter((rc) => {
          if (modeFilter === SUBSCRIPTION_MODE.TEST) {
            return !!rc.subscription?.isTest;
          }
          if (modeFilter === SUBSCRIPTION_MODE.TRIAL) {
            return !!rc.subscription?.isTrial;
          }
          return !rc.subscription?.isTrial && !rc.subscription?.isTest;
        });
  }, [rcsWithSubscriptionData, filter, modeFilter]);

  if (!authenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Box overflow="auto hidden">
      <Box
        sx={{
          minWidth: 1600,
          overflowY: "auto",
          height: "calc(100vh - 80px)",
          borderBottom: "1px solid lightgrey",
          "& .okhati-list-row": { pr: "20px" }
        }}
      >
        <List
          testmationLabel="ResourceCentre"
          automation=""
          title="Resource Centres"
          createLabel={tl("resource.createResourceCentre")}
          rowHeight={50}
          onCreateNew={() => {
            navigateTo("/resourcecentres/new");
          }}
          additionalHeaderContent={
            <Box display="flex" gap={2}>
              <div>
                <Filters filter={filter} onFilter={setFilter} filterData={filterData} />
                <Filters filter={modeFilter} onFilter={setModeFilter} filterData={modeFilterData} />
              </div>
              <FormGroup>
                <FormControlLabel
                  onChange={() => setShowPublicRcsOnly(!showPublicRcsOnly)}
                  control={<Checkbox checked={showPublicRcsOnly} />}
                  label="Show public rcs only"
                />
              </FormGroup>
            </Box>
          }
          hideCreateButton={!showCreateButton}
          onRowClick={({ id }) => navigateTo(`/resourcecentres/${id}/details`)}
          data={resourceCentreOptions}
          isResponsive
          adjustHeightToContents
          defaultSortColumn="name"
          columns={[
            {
              key: "name",
              label: tl("resource.name"),
              sortable: true
            },
            {
              key: "address",
              label: tl("resource.address"),
              hideInNarrowView: true
            },
            {
              key: "city",
              label: tl("resource.city"),
              sortable: true
            },
            {
              key: "phone",
              label: tl("resource.phone"),
              hideInNarrowView: true
            },
            {
              key: "packageName",
              label: tl("resource.subscriptionPlan"),
              sortable: true,
              hideInNarrowView: true,
              formatter: (r) => <Typography>{r.packageName}</Typography>
            },
            {
              key: "status",
              label: tl("resource.status"),
              hideInNarrowView: true,
              sortable: true,
              formatter: ({ status }) => (
                <Typography>
                  <Chip color={StatusColorMap[status]} label={startCase(status)} />
                </Typography>
              )
            },
            {
              key: "statusUpdatedAt",
              label: "Status Updated At",
              sortable: true,
              sortFunction: (a, b) =>
                new Date(a.subscription?.statusUpdatedAt || 0) >
                new Date(b.subscription?.statusUpdatedAt || 0)
                  ? 1
                  : -1,
              formatter: ({ status, subscription }) => {
                if (status === SUBSCRIPTION_STATUS_FILTER.ACTIVE || !subscription) return "";
                return (
                  <Typography title="Paused or terminated date">
                    {subscription.statusUpdatedAt
                      ? convertADtoBS(new Date(subscription.statusUpdatedAt)).formatted4
                      : ""}
                  </Typography>
                );
              }
            },
            {
              key: "subscriptionStart",
              label: "Subs Start",
              formatter: ({ subscription }) => {
                const { subscriptionStart } = subscription || {};
                return (
                  <Typography>
                    {subscriptionStart ? convertADtoBS(new Date(subscriptionStart)).formatted4 : ""}
                  </Typography>
                );
              }
            },
            {
              key: "subscriptionEnd",
              label: "Subs End",
              formatter: ({ subscription }) => {
                const { subscriptionEnd } = subscription || {};
                return (
                  <Typography>
                    {subscriptionEnd ? convertADtoBS(new Date(subscriptionEnd)).formatted4 : ""}
                  </Typography>
                );
              }
            },
            {
              key: "salesPerson",
              label: "Sales Person",
              sortable: true,
              formatter: ({ salesPerson }) => <Typography>{salesPerson}</Typography>
            },
            {
              key: "initialAmount",
              label: "Initial Amt",
              sortable: true,
              formatter: ({ initialAmount }) => <Typography>{initialAmount}</Typography>
            },
            {
              key: "paymentPrice",
              label: "Price",
              sortable: true,
              formatter: ({ paymentPrice }) => <Typography>{paymentPrice}</Typography>
            },
            {
              key: "paymentType",
              label: "Payment Type",
              sortable: true,
              formatter: ({ paymentType }) => <Typography>{startCase(paymentType)}</Typography>
            },
            {
              key: "mode",
              label: "Mode",
              sortable: true,
              formatter: ({ mode }) => <Typography>{startCase(mode)}</Typography>
            },
            {
              key: "mainBranch",
              label: "Main Branch",
              hideInNarrowView: true,
              formatter: (r) =>
                r.branch?.headResourceCentreId ? (
                  <Typography>
                    {getResourceCentreName(resourceCentreOptions, r.branch?.headResourceCentreId)}
                  </Typography>
                ) : (
                  ""
                ),
              sortable: true,
              sortFunction: (a, b) =>
                a.branch?.headResourceCentreId > b.branch?.headResourceCentreId ? 1 : -1
            },
            {
              key: "",
              label: "",
              cellRenderer: ({ id, status }) => (
                <ListRowActions
                  onEditRow={() => {
                    navigateTo(`/resourcecentres/${id}/edit`);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(status === SUBSCRIPTION_STATUS_FILTER.TERMINATED && {
                    onDeleteRow: () => {
                      setSelectedRcIdToDestroy(id);
                      setIsDestroyModalOpen(true);
                    }
                  })}
                />
              )
            }
          ]}
        >
          <EmptyView>
            <Box textAlign="center" padding="50px">
              No items to show.
            </Box>
          </EmptyView>
          <ListActions>
            {({ getProcessedData }) => (
              <Menu>
                <MenuItem
                  onClick={async () =>
                    downloadCSV("ResourceCentres", getProcessedData(), docColumns, (row) =>
                      docRowProcessor(row)
                    )
                  }
                >
                  {tl("reports.excel")}
                </MenuItem>
              </Menu>
            )}
          </ListActions>
        </List>
        <Modal
          open={editMode || createMode || false}
          title={editMode ? `${getResourceCentre(resourceId).name}` : tl("resource.resourceCentre")}
          footer={
            <>
              <Button
                data-testmation="globalCancel"
                onClick={() => {
                  setState((prevState) => ({ ...prevState, formData: null }));
                  goBack();
                }}
                color="primary"
              >
                {tl("global.cancel")}
              </Button>
              <StatefulButton
                data-testmation="globalSave"
                isLoading={isSubmitting}
                disabled={isSubmitting}
                onClick={onSave}
                variant="contained"
                color="primary"
                circularProgressProps={{ size: 16 }}
              >
                {tl("global.save")}
              </StatefulButton>
            </>
          }
        >
          <Form
            fields={
              editMode
                ? fields.map((field) =>
                    field.key === "startingCustomerNumber"
                      ? { ...field, editable: false, disabled: true }
                      : field
                  )
                : fields
            }
            data={editMode ? getResourceCentre(resourceId) : { startingCustomerNumber: 0 }}
            onChange={(data, errors) => onChange(data, errors)}
            showErrors={state?.saveAttempted}
          />
          <AddressSelect
            onChange={(v) => {
              setState((prevState) => ({
                ...prevState,
                addressFormData: {
                  ...prevState?.addressFormData,
                  palikaId: v
                }
              }));
            }}
            selectedOption={state?.addressFormData?.palikaId as number}
          />
          <FormControlLabel
            label="Is Central Store"
            control={
              <Checkbox
                checked={Boolean(state.formData?.isKitchenPharmacy)}
                onChange={({ target }) =>
                  setState({
                    ...state,
                    formData: { ...state.formData, isKitchenPharmacy: target.checked }
                  })
                }
              />
            }
          />
        </Modal>
        <OkhatiDialog
          open={isDestroyModalOpen}
          title="Are you sure you want to delete resource centre?"
          description="This action will remove all the data of the selected resource centre permanently."
          next={onDeleteRc}
          cancel={() => {
            setIsSubmitting(false);
            setIsDestroyModalOpen(false);
          }}
          readMode={false}
          nextButtonText="Yes"
          cancelButtonText="Cancel"
          isLoading={isSubmitting}
          promptBeforeSave
          promptComparisonValue={
            resourceCentres.find(({ id }) => id === selectedRcIdToDestroy)?.name
          }
        />
      </Box>
      <Box
        height="40px"
        width="100%"
        bgcolor="#f0f0f0"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        px={2}
        gap={2}
      >
        <Typography>
          All: <b>{resourceCentreStatus.all}</b>
        </Typography>
        <Typography>
          Active: <b>{resourceCentreStatus.active}</b>
        </Typography>
        <Typography>
          Paused: <b>{resourceCentreStatus.paused}</b>
        </Typography>
        <Typography>
          Terminated: <b>{resourceCentreStatus.terminated}</b>
        </Typography>
        <Divider orientation="vertical" />
        <Typography>
          Production: <b>{resourceCentreStatus.production}</b>
        </Typography>
        <Typography>
          Test: <b>{resourceCentreStatus.test}</b>
        </Typography>
        <Typography>
          Trial: <b>{resourceCentreStatus.trial}</b>
        </Typography>
        <Typography>(Active Subscription Only)</Typography>
      </Box>
    </Box>
  );
};

ResourceCentre.defaultProps = {
  editMode: false,
  resourceId: null,
  createMode: false
};

export default connect(
  (state: RootState) => ({
    resourceCentres: state.resources.resourceCentres,
    permissionGroup: state.userContext?.userCreds?.userGroups[0],
    mode: state.userContext?.mode,
    addressOptions: state.addressOptions.collection,
    policies: state.userContext.policies || {},
    authenticated: state.authenticated
  }),
  (dispatch: IThunkDispatch) => ({
    actions: bindActionCreators(resourceCentreActions, dispatch),
    navigateTo: (url) => {
      dispatch(push(url));
    },
    getAddressOptions: () => dispatch(addressActions.getAddressOptions()),
    goBack: () => dispatch(push("/resourcecentres")),
    deleteResourceDialog: (id, title, description) => {
      dispatch(
        showDialog({
          title,
          description,
          next: () => {
            dispatch(resourceCentreActions.deleteResourceCentre(id));
            dispatch(navigateRemoveModal("Dialog"));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    }
  })
)(ResourceCentre);

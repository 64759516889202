import * as React from "react";
import { Box, Checkbox, Typography, TextField } from "@mui/material";
import { Moment } from "moment";
import { tl, t } from "../../components/translate";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";

export const SampleDetails: React.FC<{
  values: any;
  maxValidDate: Moment;
  customRemarksLabel?: string;
  onChange: (labRemarks) => void;
}> = ({ values, maxValidDate, onChange, customRemarksLabel }) => (
  <>
    <div>
      <CalendarDropdown
        placeholder={t("labTest.sampleTakenDateLabel")}
        label={t("labTest.sampleTakenDateLabel")}
        maxValidDate={maxValidDate}
        date={values?.collectionDate ? new Date(values?.collectionDate) : new Date()}
        onChange={(collectionDate) => onChange({ ...values, collectionDate })}
        format="formatted3"
        TextFieldInputProps={{ disableUnderline: true }}
        withTimeSelector
      />
    </div>
    <div>
      <TextField
        data-testmation="sampleTakenRemarksField"
        size="small"
        style={{ minWidth: "140px" }}
        margin="dense"
        sx={{
          border: "none",
          "& fieldset": { border: "none" }
        }}
        variant="outlined"
        label={customRemarksLabel || tl("booking.note")}
        value={values?.remarks || ""}
        inputProps={{ maxLength: 20 }}
        InputLabelProps={{
          style: {
            color: "#767676",
            marginTop: "5px"
          }
        }}
        onChange={({ target }) => onChange({ ...values, remarks: target.value })}
      />
    </div>
  </>
);

interface SampleTaken {
  value: boolean;
  dateAndRemarks: { collectionDate: Date; remarks?: string };
  maxValidDate?: Moment;
  onChange: (value, dateAndRemarks) => void;
}

const SampleTaken: React.FC<SampleTaken> = ({ value, dateAndRemarks, maxValidDate, onChange }) => (
  <Box display="flex" alignItems="center">
    <Box display="flex">
      <Typography style={{ fontWeight: 600 }}>{tl("labTest.sampleTaken")}</Typography>
      <Checkbox
        style={{ height: "18px" }}
        checked={value}
        onChange={() => onChange(!value, dateAndRemarks)}
        data-testmation="sampleTakenCheck"
      />
    </Box>
    <Box display="flex" alignItems="center" ml={2}>
      {value && dateAndRemarks && (
        <SampleDetails
          values={dateAndRemarks}
          onChange={(dateAndRemark) => onChange(value, dateAndRemark)}
          maxValidDate={maxValidDate}
        />
      )}
    </Box>
  </Box>
);

SampleTaken.defaultProps = {
  maxValidDate: null
};

SampleDetails.defaultProps = {
  customRemarksLabel: ""
};

export default SampleTaken;

import * as React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import { tl } from '../../components/translate';

const isVideoAppointment = ({ value = false, editable = true, onChange }) => {
	return (
		<FormGroup row >
			<FormControlLabel
				control={
					<Checkbox
						disabled={!editable}
						checked={value}
						onChange={() => onChange(!value)}
					/>
				}
				label={
					<Box display="flex" >
						<Box component="span" marginRight="8px">{tl('booking.isVideoAppointment')}</Box> <VideocamIcon />
					</Box>}
				color="primary"
			/>
		</FormGroup>
	)
};

export default isVideoAppointment;
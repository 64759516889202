import React from "react";

export const Syrup = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 512 512"
    >
   <g>
	<g>
		<path d="M332.645,131.86c-12.289-4.153-20.545-15.718-20.545-28.776V92.871h4.295c5.632,0,10.199-4.567,10.199-10.199V10.199
			C326.593,4.567,322.026,0,316.394,0H195.607c-5.633,0-10.199,4.567-10.199,10.199v72.472c0,5.632,4.566,10.199,10.199,10.199
			h4.295v10.213c0,13.058-8.256,24.622-20.545,28.776c-39.007,13.186-65.214,49.725-65.214,90.924v279.016h0.001
			c0,5.632,4.566,10.199,10.199,10.199h263.315c5.632,0,10.199-4.567,10.199-10.199V222.784
			C397.857,181.585,371.65,145.045,332.645,131.86z M205.806,20.398h100.389v52.074H205.806V20.398z M185.89,151.184
			c20.582-6.958,34.411-26.287,34.411-48.1V92.871h71.399v10.213c0,21.813,13.829,41.143,34.411,48.1
			c29.777,10.065,50.081,37.421,51.29,68.648H134.599C135.809,188.606,156.113,161.249,185.89,151.184z M377.459,491.602H134.542
			v-68.984h242.917V491.602z M377.459,402.219H134.542V240.23h242.917V402.219z"/>
	</g>
</g>
<g>
	<g>
		<path d="M251.168,455.366h-7.247c-5.633,0-10.199,4.567-10.199,10.199s4.566,10.199,10.199,10.199h7.247
			c5.633,0,10.199-4.567,10.199-10.199S256.801,455.366,251.168,455.366z"/>
	</g>
</g>
<g>
	<g>
		<path d="M350.214,455.366H284.99c-5.632,0-10.199,4.567-10.199,10.199s4.566,10.199,10.199,10.199h65.224
			c5.632,0,10.199-4.567,10.199-10.199S355.846,455.366,350.214,455.366z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
    </svg>
  );
};

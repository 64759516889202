import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, ButtonGroup, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { push } from "connected-react-router";
import { round } from "mathjs";
import * as moment from "moment";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { connect, useDispatch } from "react-redux";
import OccupiedBed from "../../Svgicons/OccupiedBed";
import * as BillActions from "../../actions/bill";
import { CalendarFunctions } from "../../components/Calendar";
import OkhatiDialog from "../../components/Dialog/Dialog";
import List, { Filters, MultipleHeader } from "../../components/List";
import PageControl from "../../components/PageControl";
import { t, tl } from "../../components/translate";
import { rupeeDisplay } from "../../helpers/rupee";
import useDebounce from "../../hooks/useDebounce";
import useMobileScreen from "../../hooks/useMobileScreen";
import { BillType, DocumentTypes, VisitType } from "../../interfaces/BillInterfaces";
import { clientFullNameSelector } from "../../reducers/client";
import { history } from "../../store";
import Can from "../Policy/Can";
import "./BillList.scss";
import BillListSearch from "./BillListSearch";
import BillShow from "./BillShow/BillShow";
import CommissionPanel, { Header } from "./Comission/ComissionPanel";
import RecordPayment from "./RecordPayment/RecordRefundPayment";
import BillStatusChip from "./common/BillStatusChip";
import { getCustomerNumber } from "../Client/ClientList";
import MessageCreate, { MESSAGE_TYPE } from "../Campaign/InstantMessage/MessageCreate";
import useQueryParams from "../../hooks/useQueryParams";
import { multipleHeaders } from "../Stock/StockList";
import { centralStoreHeaders } from "../Stock/CentralStore";
import { CentralStoreTabs } from "../../actions/stock";
import { getMessageTemplate, MessageTemplateEnum } from "../../helpers/messages";

export const enum BillFilter {
  ALL = "all",
  BILLED = "billed",
  DRAFT = "draft",
  CANCELLED = "cancelled",
  CREDITED = "credited"
}

export enum Mode {
  Show = "show"
}
const sortActions = { ascending: "ASC", descending: "DESC" };

const Bills = ({
  bills = [],
  mode,
  billId,
  actions,
  totalBills,
  isKitchenPharmacy,
  match = null
}) => {
  const [selectedBill, setSelectedBill] = React.useState<BillType | null>(null);
  const [recordPayment, setRecordPayment] = React.useState({
    open: false,
    refundMode: false
  });
  const dispatch = useDispatch();
  const query = useQueryParams();
  const filterParam = query.get("filter") as BillFilter;
  const [filter, setFilter] = React.useState(filterParam || BillFilter.BILLED); // all, draft, billed, cancelled
  const [openCommissionPanel, setOpenCommissionPanel] = React.useState<{
    referrer: boolean;
    serviceProvider: boolean;
  }>({
    referrer: false,
    serviceProvider: false
  });
  const [showMessagePanel, setShowMessagePanel] = React.useState(false);

  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedBillNumber, setSelectedBillNumber] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [isPageChanged, setIsPageChanged] = React.useState(false);
  const pageSize = 20;
  const pageLimit = 9;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [hasCreditNote, setHasCreditNote] = React.useState(false);

  React.useEffect(() => {
    const creditNotes = bills.some((bill) => bill.referenceTo === selectedBill?.id);
    setHasCreditNote(Boolean(creditNotes));
  }, [selectedBill, bills]);

  React.useEffect(() => {
    if (billId && mode === Mode.Show) {
      const bill = bills.find(({ id }) => billId === id);
      if (bill) setSelectedBill(bill);
      else setSelectedBill({ id: billId });
    } else {
      setSelectedBill(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bills, billId]);

  React.useEffect(() => {
    dispatch(BillActions.clearDraft());
  }, [dispatch]);

  const delay = isPageChanged ? 300 : 0;
  const dependencies = React.useMemo(
    () => ({
      delay,
      page,
      selectedClient,
      filter,
      selectedBill
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, filter, selectedClient, selectedBillNumber]
  );

  const debouncedCallback = React.useCallback(async () => {
    actions.loadBills({
      clientId: selectedClient?.id,
      page,
      pageSize,
      filter,
      sortColumn: "issueDate",
      sortDirection: sortActions.descending,
      billNumber: selectedBillNumber,
      isForKitchenPharmacy: isKitchenPharmacy
    });
    setIsPageChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, selectedClient, selectedBillNumber]);

  useDebounce(dependencies, debouncedCallback);

  React.useEffect(() => {
    // set initialPage when another client/filter is selected
    setPage(0);
  }, [selectedClient, filter]);

  const handleViewClose = () => {
    setSelectedBill(null);
    actions.navigateTo(
      isKitchenPharmacy
        ? `/stock/kitchenPharmacy?tab=${CentralStoreTabs.STOCK_RECEIVED}`
        : "/billing/bills"
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useHotkeys(
    "alt + b",
    () => {
      handleClose();
      actions.clearDraft();
      actions.gotoBilling();
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  const menuOpenHandle = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const isMobileScreen = useMobileScreen();

  const customButtonGroup = (
    <ButtonGroup color="primary" variant="contained" ref={anchorEl} aria-label="split button">
      <Tooltip arrow title="Alt + B">
        <Button
          onClick={() => {
            handleClose();
            actions.clearDraft();
            actions.gotoBilling();
          }}
          data-testmation="createBill"
        >
          {isMobileScreen ? "Create" : tl("billing.createNew")}
        </Button>
      </Tooltip>
      <Button onClick={menuOpenHandle} size="small" data-testmation="createBillDropdown">
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testmation="billing.createButton"
      >
        <Can policyAccessKey="bill:recordPayment">
          <MenuItem
            onClick={() => {
              handleClose();
              if (selectedBill) handleViewClose();
              setRecordPayment({ open: true, refundMode: false });
            }}
            data-testmation="recordReceipt"
          >
            {tl("billing.recordReceipt")}
          </MenuItem>
        </Can>
        <MenuItem
          onClick={() => {
            handleClose();
            if (selectedBill) handleViewClose();
            setRecordPayment({ open: true, refundMode: true });
          }}
          data-testmation="createRefund"
        >
          {tl("billing.createRefund")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenCommissionPanel({ ...openCommissionPanel, serviceProvider: true });
            setAnchorEl(null);
          }}
          data-testmation="billing.createDoctorFees"
        >
          {tl("billing.createDoctorFees")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenCommissionPanel({ ...openCommissionPanel, referrer: true });
            setAnchorEl(null);
          }}
          data-testmation="billing.createReferrerFees"
        >
          {tl("billing.createReferrerFees")}
        </MenuItem>
      </Menu>
    </ButtonGroup>
  );

  const filterData = {
    filters: [
      { key: BillFilter.BILLED, title: tl(`billing.filter.billed`) },
      { key: BillFilter.CREDITED, title: tl(`billing.filter.credited`) },
      { key: BillFilter.DRAFT, title: tl(`billing.filter.draft`) },
      { key: BillFilter.CANCELLED, title: tl(`billing.filter.cancelled`) },
      { key: BillFilter.ALL, title: tl(`billing.filter.all`) }
    ]
  };

  return (
    <Can policyAccessKey="bill:listBill">
      <OkhatiDialog
        open={openWarningModal}
        title="Warning"
        description="Bill Lists are limited to 10 pages only. Please navigate to Sales reports to view more."
        readMode={false}
        next={() => {
          history.push("/reports/financial/salesReport");
        }}
        nextButtonText="Go to sales report"
        cancel={() => setOpenWarningModal(false)}
      />
      <Box height="calc(100% - 4px)" mt={-1} className="billingList">
        <Box height="calc(100% - 44px)">
          <List
            automation="Bills"
            data={bills}
            hideCreateButton
            customButtonGroup={isKitchenPharmacy ? <></> : customButtonGroup}
            rowHeight={50}
            withoutSearch
            additionalHeaderContent={
              <Box display="flex" flexDirection="column">
                {isKitchenPharmacy ? (
                  <>
                    <MultipleHeader
                      multipleHeaders={multipleHeaders(match, isKitchenPharmacy, false)}
                    />
                    <MultipleHeader
                      multipleHeaders={centralStoreHeaders(CentralStoreTabs.STOCK_RECEIVED)}
                      useAsSubHeader
                    />
                  </>
                ) : (
                  <Filters filter={filter} onFilter={setFilter} filterData={filterData} />
                )}
              </Box>
            }
            additionalHeaderFilters={
              !isKitchenPharmacy ? (
                <BillListSearch
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                  onBillNumberChange={(billNo) => {
                    setSelectedClient(null);
                    setSelectedBillNumber(billNo);
                  }}
                  billSearchDisabled={filter === "draft"}
                />
              ) : (
                <></>
              )
            }
            isResponsive
            columns={[
              {
                key: "billNumber",
                label: tl("billing.billNumber"),
                sortable: true,
                hideInNarrowView: true,
                formatter: (row) => (
                  <Box flex={1} flexDirection="column" alignItems="center">
                    <Typography>
                      {row.isDraft ? "-" : <Box component="span">{row.billNumber}</Box>}
                    </Typography>
                    {row.type === DocumentTypes.CREDITNOTE && row.related && (
                      <Box fontSize="small" color="gray">
                        CN:{row.related.billNumber}
                      </Box>
                    )}
                  </Box>
                )
              },
              {
                key: "name",
                label: tl("billing.client"),
                sortable: false,
                formatter: (row) => (
                  <Box
                    component="div"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start"
                    }}
                  >
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography>
                        {`${clientFullNameSelector(row.client).toUpperCase()} ${
                          row.client?.active ? "" : "(Deleted)"
                        }`}
                      </Typography>
                      {row.visitType === VisitType.IPD && (
                        <OccupiedBed width={20} height={10} color="gray" />
                      )}
                    </Box>
                    <Typography fontSize="small" color="gray">
                      {getCustomerNumber(row.customer?.customerNumber)}
                      {row.customer?.externalIdentifier
                        ? ` (${t("client.externalIdentifier.short")}: ${
                            row.customer?.externalIdentifier
                          })`
                        : ""}
                    </Typography>
                  </Box>
                )
              },
              {
                key: "date",
                label: tl("reports.date"),
                formatter: ({ issueDate, createdAt }) => (
                  <div>
                    <Typography>
                      {CalendarFunctions.convertADtoBS(moment(issueDate)).formatted3}
                    </Typography>
                    {createdAt && !moment(issueDate).isSame(createdAt, "day") && (
                      <Typography fontSize="0.8rem" color="gray">
                        {CalendarFunctions.convertADtoBS(moment(createdAt)).formatted3}
                      </Typography>
                    )}
                  </div>
                ),
                sortable: true,
                sortFunction: (a, b) => (a.createdAt > b.createdAt ? 1 : -1)
              },
              {
                key: "total",
                label: tl("billing.total"),
                sortable: false,
                formatter: ({ total }) => rupeeDisplay(total),
                hideInNarrowView: true
              },
              ...(["all", "billed"].includes(filter)
                ? [
                    {
                      key: "paidAmount",
                      label: tl("billing.paidAmount"),
                      cellRenderer: (row) => {
                        if (row.isDraft) {
                          return null;
                        }
                        const paymentInfo = row.paymentInfo || {
                          paid: true,
                          paidAmount: row.summary.totalAmount
                        };
                        return (
                          <Box display="flex">
                            {!paymentInfo.paid && rupeeDisplay(paymentInfo.paidAmount)}
                            {paymentInfo.paid && <CheckIcon color="primary" />}
                          </Box>
                        );
                      }
                    },
                    {
                      key: "dueAmount",
                      label: tl("billing.dueAmount"),
                      cellRenderer: (row) => {
                        if (row.isDraft) {
                          return null;
                        }
                        return (
                          <Box display="flex" justifyContent="flex-end">
                            {!row.paymentInfo.paid &&
                              rupeeDisplay(round(row.total - row.paymentInfo.paidAmount, 2))}
                          </Box>
                        );
                      }
                    }
                  ]
                : []),
              ...(isKitchenPharmacy
                ? []
                : [
                    {
                      key: "status",
                      label: tl("billing.status"),
                      sortable: false,
                      cellRenderer: (row) => (
                        <Box display="flex">
                          <BillStatusChip bill={row} />
                        </Box>
                      )
                    }
                  ])
            ]}
            activeRow={selectedBill && selectedBill.id}
            onRowClick={(bill) => {
              if (bill.client?.active) {
                const isAlreadySelected = selectedBill && selectedBill.id === bill.id;
                if (isAlreadySelected)
                  actions.navigateTo(
                    isKitchenPharmacy
                      ? `/stock/kitchenPharmacy?tab=${CentralStoreTabs.STOCK_RECEIVED}`
                      : "/billing/bills"
                  );
                else
                  actions.navigateTo(
                    isKitchenPharmacy
                      ? `/stock/kitchenPharmacy/${bill.id}?tab=${CentralStoreTabs.STOCK_RECEIVED}`
                      : `/billing/bills/${bill.id}`
                  );
              }
            }}
          />
        </Box>
        <Box height="40px" borderTop="1px solid lightgray" display="flex" justifyContent="flex-end">
          <Box width="200px">
            <PageControl
              page={page}
              onSetPage={(val) => {
                if (val > pageLimit) {
                  setOpenWarningModal(true);
                  return;
                }
                setPage(val);
                setIsPageChanged(true);
              }}
              pageSize={pageSize}
              maximumDataCount={totalBills}
            />
          </Box>
        </Box>

        {selectedBill && (
          <BillShow
            setShowMessagePanel={setShowMessagePanel}
            billId={selectedBill.id}
            navigateTo={actions.navigateTo}
            handleViewClose={handleViewClose}
            isForKitchenPharmacy={isKitchenPharmacy}
            hasCreditNote={hasCreditNote}
          />
        )}
        {recordPayment.open && (
          <RecordPayment
            onClose={() => setRecordPayment({ ...recordPayment, open: false })}
            refundMode={recordPayment.refundMode}
          />
        )}
        {(openCommissionPanel.serviceProvider || openCommissionPanel.referrer) && (
          <CommissionPanel
            handleClose={() => setOpenCommissionPanel({ referrer: false, serviceProvider: false })}
            header={openCommissionPanel.serviceProvider ? Header.SERVICE_PROVIDER : Header.REFERRER}
          />
        )}
        {showMessagePanel && selectedBill && (
          <MessageCreate
            panelTitle={selectedBill.isDraft ? "Send Estimate" : "Send Invoice"}
            defaultSubject={
              getMessageTemplate({
                messageTemplateType: MessageTemplateEnum.SEND_BILL_INVOICE_OR_ESTIMATE
              }).subject
            }
            initialHTMLMessage={
              getMessageTemplate({
                billUuid: selectedBill.uuid,
                messageTemplateType: MessageTemplateEnum.SEND_BILL_INVOICE_OR_ESTIMATE
              }).emailBody
            }
            onCancel={() => {
              setShowMessagePanel(false);
            }}
            client={selectedBill.client}
            messageType={MESSAGE_TYPE.EMAIL}
          />
        )}
      </Box>
    </Can>
  );
};

export default connect(
  (state) => ({
    bills: state.bills.collection,
    draft: state.bills.draft,
    totalBills: state.bills.total
  }),
  (dispatch) => ({
    actions: {
      loadBills: (query?) => dispatch(BillActions.fetchBills(query && query)),
      clearDraft: () => dispatch(BillActions.clearDraft()),
      gotoBilling: () => dispatch(push("/billing/new")),
      navigateTo: (url) => dispatch(push(url))
    }
  })
)(Bills);

import {
  isRequired,
  hasLength,
  isNumber,
  isEmail,
  isGeolocation,
  isValidPhoneNumber
} from "../helpers/validators";

export interface ResourceCentreType {
  name: string;
  address: string;
  city: string;
  phone: string;
}

export const places = [
  {
    id: 1,
    city: "Kathmandu",
    district: "Kathmandu"
  },
  {
    id: 2,
    city: "Pokhara",
    district: "Kaski"
  },
  {
    id: 3,
    city: "Lalitpur",
    district: "Lalitpur"
  },
  {
    id: 4,
    city: "Bharatpur",
    district: "Chitwan"
  },
  {
    id: 5,
    city: "Biratnagar",
    district: "Morang"
  },
  {
    id: 6,
    city: "Birganj",
    district: "Parsa"
  },
  {
    id: 7,
    city: "Janakpur",
    district: "Dhanusha"
  },
  {
    id: 8,
    city: "Ghorahi",
    district: "Dang"
  },
  {
    id: 9,
    city: "Hetauda",
    district: "Makawanpur"
  },
  {
    id: 10,
    city: "Dhangadhi",
    district: "Kailali"
  },
  {
    id: 11,
    city: "Tulsipur",
    district: "Dang"
  },
  {
    id: 12,
    city: "Itahari",
    district: "Sunsari"
  },
  {
    id: 13,
    city: "Nepalgunj",
    district: "Banke"
  },
  {
    id: 14,
    city: "Butwal",
    district: "Rupandehi"
  },
  {
    id: 15,
    city: "Dharan",
    district: "Sunsari"
  },
  {
    id: 16,
    city: "Kalaiya",
    district: "Bara"
  },
  {
    id: 17,
    city: "Jitpur Simara",
    district: "Bara"
  },
  {
    id: 18,
    city: "Birtamod",
    district: "Jhapa"
  },
  {
    id: 19,
    city: "Damak",
    district: "Jhapa"
  },
  {
    id: 20,
    city: "Budhanilkantha",
    district: "Kathmandu"
  },
  {
    id: 21,
    city: "Gokarneshwar",
    district: "Kathmandu"
  },
  {
    id: 22,
    city: "Bhimdatta",
    district: "Kanchanpur"
  },
  {
    id: 23,
    city: "Birendranagar",
    district: "Surkhet"
  },
  {
    id: 24,
    city: "Tilottama",
    district: "Rupandehi"
  },
  {
    id: 25,
    city: "Tokha",
    district: "Kathmandu"
  },
  {
    id: 26,
    city: "Tikapur",
    district: "Kailali"
  },
  {
    id: 27,
    city: "Lahan",
    district: "Siraha"
  },
  {
    id: 28,
    city: "Triyuga",
    district: "Udayapur"
  },
  {
    id: 29,
    city: "Chandragiri",
    district: "Kathmandu"
  },
  {
    id: 30,
    city: "Madhyapur Thimi",
    district: "Bhaktapur"
  },
  {
    id: 31,
    city: "Siraha",
    district: "Siraha"
  },
  {
    id: 32,
    city: "Bhaktapur",
    district: "Bhaktapur"
  },
  {
    id: 33,
    city: "Tarakeshwar",
    district: "Kathmandu"
  },
  {
    id: 34,
    city: "Sundar Haraincha",
    district: "Morang"
  },
  {
    id: 35,
    city: "Suryabinayak",
    district: "Bhaktapur"
  },
  {
    id: 36,
    city: "Godawari Lalitpur",
    district: "Lalitpur"
  },
  {
    id: 37,
    city: "Godawari Kailali",
    district: "Kailali"
  },
  {
    id: 38,
    city: "Barahachhetra",
    district: "Sunsari"
  },
  {
    id: 39,
    city: "Kapilvastu",
    district: "Kapilvastu"
  },
  {
    id: 40,
    city: "Lamki Chuha",
    district: "Kailali"
  },
  {
    id: 41,
    city: "Ghodaghodi",
    district: "Kailali"
  },
  {
    id: 42,
    city: "Banganga",
    district: "Kapilvastu"
  },
  {
    id: 43,
    city: "Lumbini Sanskritik",
    district: "Rupandehi"
  },
  {
    id: 44,
    city: "Chandrapur",
    district: "Rautahat"
  },
  {
    id: 45,
    city: "Kohalpur",
    district: "Banke"
  },
  {
    id: 46,
    city: "Vyas",
    district: "Tanahun"
  },
  {
    id: 47,
    city: "Ratnanagar",
    district: "Chitwan"
  },
  {
    id: 48,
    city: "Barahathwa",
    district: "Sarlahi"
  },
  {
    id: 49,
    city: "Rajbiraj",
    district: "Saptari"
  },
  {
    id: 50,
    city: "Barbardiya",
    district: "Bardiya"
  },
  {
    id: 51,
    city: "Shivaraj",
    district: "Kapilvastu"
  },
  {
    id: 52,
    city: "Gulariya",
    district: "Bardiya"
  },
  {
    id: 53,
    city: "Gaushala",
    district: "Mahottari"
  },
  {
    id: 54,
    city: "Bardibas",
    district: "Mahottari"
  },
  {
    id: 55,
    city: "Belbari",
    district: "Morang"
  },
  {
    id: 56,
    city: "Kirtipur",
    district: "Kathmandu"
  },
  {
    id: 57,
    city: "Bhadrapur",
    district: "Jhapa"
  },
  {
    id: 58,
    city: "Nagarjun",
    district: "Kathmandu"
  },
  {
    id: 59,
    city: "Dudhauli",
    district: "Sindhuli"
  },
  {
    id: 60,
    city: "Kamalamai",
    district: "Sindhuli"
  },
  {
    id: 61,
    city: "Buddhabhumi",
    district: "Kapilvastu"
  },
  {
    id: 62,
    city: "Shivasatakshi",
    district: "Jhapa"
  },
  {
    id: 63,
    city: "Inaruwa",
    district: "Sunsari"
  },
  {
    id: 64,
    city: "Siddharthanagar",
    district: "Rupandehi"
  },
  {
    id: 65,
    city: "Pathari-Shanischare",
    district: "Morang"
  },
  {
    id: 66,
    city: "Kawasoti",
    district: "Nawalpur"
  },
  {
    id: 67,
    city: "Krishnanagar",
    district: "Kapilvastu"
  },
  {
    id: 68,
    city: "Mahalaxmi",
    district: "Lalitpur"
  },
  {
    id: 69,
    city: "Kageshwari-Manohara",
    district: "Kathmandu"
  },
  {
    id: 70,
    city: "Arjundhara",
    district: "Jhapa"
  },
  {
    id: 71,
    city: "Ishwarpur",
    district: "Sarlahi"
  },
  {
    id: 72,
    city: "Rajapur",
    district: "Bardiya"
  },
  {
    id: 73,
    city: "Ramgram",
    district: "Parasi"
  },
  {
    id: 74,
    city: "Lalbandi",
    district: "Sarlahi"
  },
  {
    id: 75,
    city: "Gaindakot",
    district: "Nawalpur"
  },
  {
    id: 76,
    city: "Jaleshwar",
    district: "Mahottari"
  },
  {
    id: 77,
    city: "Nilkantha",
    district: "Dhading"
  },
  {
    id: 78,
    city: "Baglung",
    district: "Baglung"
  },
  {
    id: 79,
    city: "Rapti",
    district: "Chitwan"
  },
  {
    id: 80,
    city: "Suryodaya",
    district: "Ilam"
  },
  {
    id: 81,
    city: "Krishnapur",
    district: "Kanchanpur"
  },
  {
    id: 82,
    city: "Duhabi",
    district: "Sunsari"
  },
  {
    id: 83,
    city: "Katari",
    district: "Udayapur"
  },
  {
    id: 84,
    city: "Khairhani",
    district: "Chitwan"
  },
  {
    id: 85,
    city: "Bansgadhi",
    district: "Bardiya"
  },
  {
    id: 86,
    city: "Sainamaina",
    district: "Rupandehi"
  },
  {
    id: 87,
    city: "Banepa",
    district: "Kavrepalanchok"
  },
  {
    id: 88,
    city: "Changunarayan",
    district: "Bhaktapur"
  },
  {
    id: 89,
    city: "Sunwal",
    district: "Parasi"
  },
  {
    id: 90,
    city: "Bardghat",
    district: "Parasi"
  },
  {
    id: 91,
    city: "Ratuwamai",
    district: "Morang"
  },
  {
    id: 92,
    city: "Gauriganga",
    district: "Kailali"
  },
  {
    id: 93,
    city: "Maharajganj",
    district: "Kapilvastu"
  },
  {
    id: 94,
    city: "Urlabari",
    district: "Morang"
  },
  {
    id: 95,
    city: "Mahagadhimai",
    district: "Bara"
  },
  {
    id: 96,
    city: "Bidur",
    district: "Nuwakot"
  },
  {
    id: 97,
    city: "Madhyabindu",
    district: "Nawalpur"
  },
  {
    id: 98,
    city: "Punarbas",
    district: "Kanchanpur"
  },
  {
    id: 99,
    city: "Belauri",
    district: "Kanchanpur"
  },
  {
    id: 100,
    city: "Devdaha",
    district: "Rupandehi"
  },
  {
    id: 101,
    city: "Gauradaha",
    district: "Jhapa"
  },
  {
    id: 102,
    city: "Rangeli",
    district: "Morang"
  },
  {
    id: 103,
    city: "Bhajani",
    district: "Kailali"
  },
  {
    id: 104,
    city: "Ramdhuni",
    district: "Sunsari"
  },
  {
    id: 105,
    city: "Waling",
    district: "Syangja"
  },
  {
    id: 106,
    city: "Golbazar",
    district: "Siraha"
  },
  {
    id: 107,
    city: "Sunawarshi",
    district: "Morang"
  },
  {
    id: 108,
    city: "Garuda",
    district: "Rautahat"
  },
  {
    id: 109,
    city: "Tansen",
    district: "Palpa"
  },
  {
    id: 110,
    city: "Mirchaiya",
    district: "Siraha"
  },
  {
    id: 111,
    city: "Simraungadh",
    district: "Bara"
  },
  {
    id: 112,
    city: "Manara Shisawa",
    district: "Mahottari"
  },
  {
    id: 113,
    city: "Bedkot",
    district: "Kanchanpur"
  },
  {
    id: 114,
    city: "Kalyanpur",
    district: "Siraha"
  },
  {
    id: 115,
    city: "Gorkha",
    district: "Gorkha"
  },
  {
    id: 116,
    city: "Phidim",
    district: "Pancthar"
  },
  {
    id: 117,
    city: "Chaudandigadhi",
    district: "Udayapur"
  },
  {
    id: 118,
    city: "Ilam",
    district: "Ilam"
  },
  {
    id: 119,
    city: "Shuklagandaki",
    district: "Tanahun"
  },
  {
    id: 120,
    city: "Godaita",
    district: "Sarlahi"
  },
  {
    id: 121,
    city: "Lamahi",
    district: "Dang"
  },
  {
    id: 122,
    city: "Dhangadimai",
    district: "Siraha"
  },
  {
    id: 123,
    city: "Rupakot Majhuwagadhi",
    district: "Khotang"
  },
  {
    id: 124,
    city: "Shuklaphanta",
    district: "Kanchanpur"
  },
  {
    id: 125,
    city: "Bhangaha",
    district: "Mahottari"
  },
  {
    id: 126,
    city: "Paunauti",
    district: "Kavrepalanchok"
  },
  {
    id: 127,
    city: "Gujara",
    district: "Rautahat"
  },
  {
    id: 128,
    city: "Malangwa",
    district: "Sarlahi"
  },
  {
    id: 129,
    city: "Chautara Sangachokgadhi",
    district: "Sindhupalchok"
  },
  {
    id: 130,
    city: "Madhuwan",
    district: "Bardiya"
  },
  {
    id: 131,
    city: "Sabaila",
    district: "Dhanusha"
  },
  {
    id: 132,
    city: "Bhanu",
    district: "Tanahun"
  },
  {
    id: 133,
    city: "Hanumannagar Kankalini",
    district: "Saptari"
  },
  {
    id: 134,
    city: "Dhanushadham",
    district: "Dhanusha"
  },
  {
    id: 135,
    city: "Manthali",
    district: "Ramechhap"
  },
  {
    id: 136,
    city: "Khadak",
    district: "Saptari"
  },
  {
    id: 137,
    city: "Melamchi",
    district: "Sindhupalchok"
  },
  {
    id: 138,
    city: "Balara",
    district: "Sarlahi"
  },
  {
    id: 139,
    city: "Mithila",
    district: "Dhanusha"
  },
  {
    id: 140,
    city: "Putalibazar",
    district: "Syangja"
  },
  {
    id: 141,
    city: "Dakneshwari",
    district: "Saptari"
  },
  {
    id: 142,
    city: "Thakurbaba",
    district: "Bardiya"
  },
  {
    id: 143,
    city: "Surunga",
    district: "Saptari"
  },
  {
    id: 144,
    city: "Hariwan",
    district: "Sarlahi"
  },
  {
    id: 145,
    city: "Gurbhakot",
    district: "Surkhet"
  },
  {
    id: 146,
    city: "Sitganga",
    district: "Arghakhanchi"
  },
  {
    id: 147,
    city: "Bodebarsain",
    district: "Saptari"
  },
  {
    id: 148,
    city: "Kolhabi",
    district: "Bara"
  },
  {
    id: 149,
    city: "Shahidnagar",
    district: "Dhanusha"
  },
  {
    id: 150,
    city: "Brindaban",
    district: "Rautahat"
  },
  {
    id: 151,
    city: "Devchuli",
    district: "Nawalpur"
  },
  {
    id: 152,
    city: "Kshireshwarnath",
    district: "Dhanusha"
  },
  {
    id: 153,
    city: "Belaka",
    district: "Udayapur"
  },
  {
    id: 154,
    city: "Balawa",
    district: "Mahottari"
  },
  {
    id: 155,
    city: "Kabilasi",
    district: "Sarlahi"
  },
  {
    id: 156,
    city: "Kalika",
    district: "Chitwan"
  },
  {
    id: 157,
    city: "Thaha",
    district: "Makawanpur"
  },
  {
    id: 158,
    city: "Dullu",
    district: "Dailekh"
  },
  {
    id: 159,
    city: "Ishnath",
    district: "Rautahat"
  },
  {
    id: 160,
    city: "Bheriganga",
    district: "Surkhet"
  },
  {
    id: 161,
    city: "Sandhikharka",
    district: "Arghakhanchi"
  },
  {
    id: 162,
    city: "Rajpur",
    district: "Rautahat"
  },
  {
    id: 163,
    city: "Gadhimai",
    district: "Rautahat"
  },
  {
    id: 164,
    city: "Bagmati",
    district: "Sarlahi"
  },
  {
    id: 165,
    city: "Kankai",
    district: "Jhapa"
  },
  {
    id: 166,
    city: "Belkotgadhi",
    district: "Nuwakot"
  },
  {
    id: 167,
    city: "Bahudarmai",
    district: "Parsa"
  },
  {
    id: 168,
    city: "Kushma",
    district: "Parbat"
  },
  {
    id: 169,
    city: "Loharpatti",
    district: "Mahottari"
  },
  {
    id: 170,
    city: "Besisahar",
    district: "Lamjung"
  },
  {
    id: 171,
    city: "Mahakali",
    district: "Kanchanpur"
  },
  {
    id: 172,
    city: "Purchaudi",
    district: "Baitadi"
  },
  {
    id: 173,
    city: "Hansapur",
    district: "Dhanusha"
  },
  {
    id: 174,
    city: "Kamala",
    district: "Dhanusha"
  },
  {
    id: 175,
    city: "Pyuthan",
    district: "Pyuthan"
  },
  {
    id: 176,
    city: "Katahariya",
    district: "Rautahat"
  },
  {
    id: 177,
    city: "Palungtar",
    district: "Gorkha"
  },
  {
    id: 178,
    city: "Parsagadhi",
    district: "Parsa"
  },
  {
    id: 179,
    city: "Shambhunath",
    district: "Saptari"
  },
  {
    id: 180,
    city: "Panchkhal",
    district: "Kavrepalanchok"
  },
  {
    id: 181,
    city: "Madi",
    district: "Chitwan"
  },
  {
    id: 182,
    city: "Sukhipur",
    district: "Siraha"
  },
  {
    id: 183,
    city: "Paroha",
    district: "Rautahat"
  },
  {
    id: 184,
    city: "Haripur",
    district: "Sarlahi"
  },
  {
    id: 185,
    city: "Ganeshman Charnath",
    district: "Dhanusha"
  },
  {
    id: 186,
    city: "Galyang",
    district: "Syangja"
  },
  {
    id: 187,
    city: "Dhankuta",
    district: "Dhankuta"
  },
  {
    id: 188,
    city: "Phatuwa Bijayapur",
    district: "Rautahat"
  },
  {
    id: 189,
    city: "Baudhimai",
    district: "Rautahat"
  },
  {
    id: 190,
    city: "Bangad Kupinde",
    district: "Salyan"
  },
  {
    id: 191,
    city: "Haripurwa",
    district: "Sarlahi"
  },
  {
    id: 192,
    city: "Rampur",
    district: "Palpa"
  },
  {
    id: 193,
    city: "Chhedagad",
    district: "Jajarkot"
  },
  {
    id: 194,
    city: "Kanchanrup",
    district: "Saptari"
  },
  {
    id: 195,
    city: "Parashuram",
    district: "Dadeldhura"
  },
  {
    id: 196,
    city: "Nagarain",
    district: "Dhanusha"
  },
  {
    id: 197,
    city: "Dasharathchand",
    district: "Baitadi"
  },
  {
    id: 198,
    city: "Nijgadh",
    district: "Bara"
  },
  {
    id: 199,
    city: "Madhav Narayan",
    district: "Rautahat"
  },
  {
    id: 200,
    city: "Gaur",
    district: "Rautahat"
  },
  {
    id: 201,
    city: "Pachrauta",
    district: "Bara"
  },
  {
    id: 202,
    city: "Bagchaur",
    district: "Salyan"
  },
  {
    id: 203,
    city: "Sanphebagar",
    district: "Achham"
  },
  {
    id: 204,
    city: "Shaarda",
    district: "Salyan"
  },
  {
    id: 205,
    city: "Aathabiskot",
    district: "Rukum West"
  },
  {
    id: 206,
    city: "Mithila Bihari",
    district: "Dhanusa"
  },
  {
    id: 207,
    city: "Bheri",
    district: "Jajarkot"
  },
  {
    id: 208,
    city: "Beni",
    district: "Myagdi"
  },
  {
    id: 209,
    city: "Bungal",
    district: "Bajhang"
  },
  {
    id: 210,
    city: "Galkot",
    district: "Baglung"
  },
  {
    id: 211,
    city: "Dipayal Silgadhi",
    district: "Doti"
  },
  {
    id: 212,
    city: "Musikot Rukum",
    district: "Western Rukum"
  },
  {
    id: 213,
    city: "Deumai",
    district: "Ilam"
  },
  {
    id: 214,
    city: "Pokhariya",
    district: "Parsa"
  },
  {
    id: 215,
    city: "Musikot Gulmi",
    district: "Gulmi"
  },
  {
    id: 216,
    city: "Rolpa",
    district: "Rolpa"
  },
  {
    id: 217,
    city: "Mandandeupur",
    district: "Kavrepalanchok"
  },
  {
    id: 218,
    city: "Bhumikasthan",
    district: "Arghakhanchi"
  },
  {
    id: 219,
    city: "Mai",
    district: "Ilam"
  },
  {
    id: 220,
    city: "Resunga",
    district: "Gulmi"
  },
  {
    id: 221,
    city: "Mangalsen",
    district: "Achham"
  },
  {
    id: 222,
    city: "Bideha",
    district: "Dhanusha"
  },
  {
    id: 223,
    city: "Panchapuri",
    district: "Surkhet"
  },
  {
    id: 224,
    city: "Dhulikhel",
    district: "Kavrepalanchok"
  },
  {
    id: 225,
    city: "Dewahi Gonahi",
    district: "Rautahat"
  },
  {
    id: 226,
    city: "Letang Bhogateni",
    district: "Morang"
  },
  {
    id: 227,
    city: "Shikhar",
    district: "Doti"
  },
  {
    id: 228,
    city: "Aurahi",
    district: "Mahottari"
  },
  {
    id: 229,
    city: "Shadanand",
    district: "Bhojpur"
  },
  {
    id: 230,
    city: "Bhimeshwar",
    district: "Dolakha"
  },
  {
    id: 231,
    city: "Jaimini",
    district: "Baglung"
  },
  {
    id: 232,
    city: "Bhimad",
    district: "Tanahun"
  },
  {
    id: 233,
    city: "Rajdevi",
    district: "Rautahat"
  },
  {
    id: 234,
    city: "Khandbari",
    district: "Sankhuwasabha"
  },
  {
    id: 235,
    city: "Dhunibeshi",
    district: "Dhading"
  },
  {
    id: 236,
    city: "Matihani",
    district: "Mahottari"
  },
  {
    id: 237,
    city: "Karjanha",
    district: "Siraha"
  },
  {
    id: 238,
    city: "Swargadwari",
    district: "Pyuthan"
  },
  {
    id: 239,
    city: "Patan",
    district: "Baitadi"
  },
  {
    id: 240,
    city: "Lekbeshi",
    district: "Surkhet"
  },
  {
    id: 241,
    city: "Ramgopalpur",
    district: "Mahottari"
  },
  {
    id: 242,
    city: "Halesi Tuwachung",
    district: "Khotang"
  },
  {
    id: 243,
    city: "Namobuddha",
    district: "Kavrepalanchok"
  },
  {
    id: 244,
    city: "Aathabis",
    district: "Dailekh"
  },
  {
    id: 245,
    city: "Ramechhap",
    district: "Ramechhap"
  },
  {
    id: 246,
    city: "Siddhicharan",
    district: "Okhaldhunga"
  },
  {
    id: 247,
    city: "Panchadewal Binayak",
    district: "Achham"
  },
  {
    id: 248,
    city: "Chaurjahari",
    district: "Rukum West"
  },
  {
    id: 249,
    city: "Chainpur",
    district: "Sankhuwasabha"
  },
  {
    id: 250,
    city: "Bhojpur",
    district: "Bhojpur"
  },
  {
    id: 251,
    city: "Narayan",
    district: "Dailekh"
  },
  {
    id: 252,
    city: "Sundarbazar",
    district: "Lamjung"
  },
  {
    id: 253,
    city: "Barhabise",
    district: "Sindhupalchok"
  },
  {
    id: 254,
    city: "Maulapur",
    district: "Rautahat"
  },
  {
    id: 255,
    city: "Taplejung(Phungling)",
    district: "Taplejung"
  },
  {
    id: 256,
    city: "Dhorpatan",
    district: "Baglung"
  },
  {
    id: 257,
    city: "Chamunda Bindrasaini",
    district: "Dailekh"
  },
  {
    id: 258,
    city: "Chapakot",
    district: "Syangja"
  },
  {
    id: 259,
    city: "Nalgad",
    district: "Jajarkot"
  },
  {
    id: 260,
    city: "Bhirkot",
    district: "Syangja"
  },
  {
    id: 261,
    city: "Shankharapur",
    district: "Kathmandu"
  }
];

export const fields = [
  {
    key: "name",
    label: "name",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [
      isRequired({ msg: "Name is required" }),
      hasLength({ gt: 2, lt: 100, msg: "Name length should be between 3 and 100 charceters" })
    ]
  },
  {
    key: "customPrefix",
    label: "voucherCustomPrefix",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [
      isRequired({ msg: "Custom Prefix is required" }),
      hasLength({ gt: 2, lt: 4, msg: "Custom Prefix length should be between 3 and 4 charceters" })
    ]
  },
  {
    key: "startingCustomerNumber",
    label: "startingCustomerNumber",
    value: 0,
    inputType: "number",
    editable: true,
    creatable: true,
    required: true
  },
  {
    key: "address",
    label: "address",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Address is required" })]
  },
  {
    key: "city",
    label: "city",
    value: "",
    inputType: "autoComplete",
    editable: true,
    creatable: true,
    required: true,
    options: places.map((item) => ({
      value: item.id,
      label: item.city === item.district ? item.city : `${item.city}, ${item.district}`
    })),
    validators: [isRequired({ msg: "City is required" })]
  },
  {
    key: "phone",
    label: "phone",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: false,
    validators: [isNumber({ msg: "Should be a valid phone number" })]
  },
  {
    key: "optionalPhone",
    label: "resourceCentre.optionalPhone",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: false
  },
  {
    key: "mobileNumber",
    label: "mobileNumber",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: false,
    validators: [isValidPhoneNumber({ msg: "Should be a valid phone number" })]
  },
  {
    key: "registrationNumber",
    label: "registrationNumber",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false,
    validators: [isRequired({ msg: "Registration number is required" })]
  },
  {
    key: "email",
    label: "email",
    value: "",
    inputType: "email",
    editable: true,
    creatable: true,
    required: true,
    validators: [
      isRequired({ msg: "Email  is required" }),
      isEmail({ msg: "Please enter a valid email address" })
    ]
  },
  {
    key: "web",
    label: "web",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false,
    validators: []
  },
  {
    key: "slogan",
    label: "slogan",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false,
    validators: []
  },
  {
    key: "shortName",
    label: "shortName",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false,
    validators: []
  },
  {
    key: "location",
    label: "location",
    value: "",
    inputType: "geoLocation",
    editable: true,
    creatable: true,
    required: false,
    validators: [
      isGeolocation({ msg: "Please enter in valid geolocation format, i.e 12.345678,23.456789" })
    ]
  }
];

import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function Spinner() {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="999"
      width="100%"
      height="100%"
    >
      <CircularProgress />
    </Box>
  );
}

export default Spinner;

import {
  ProductListFilterType,
  StockTrxListFilterType
} from "../containers/Stock/StockCreateEdit/CreateStockProduct";
import { ProductSearchByType } from "../containers/Stock/ProductSearchBar";

export interface TaxationInterface {
  activated: boolean;
  active: boolean;
  code: string;
  description: string;
  id: number;
  ledgerId: number | null;
  name: string;
  rate: string;
  type: TaxType.PURCHASE | TaxType.SALES;
}

export enum ServiceType {
  single = "single",
  package = "package"
}

export enum ProductType {
  consultation = "consultation",
  labTest = "labTest",
  medication = "medication",
  imaging = "imaging",
  procedures = "procedures",
  video = "video",
  nursing = "nursing",
  physiotherapy = "physiotherapy",
  vaccination = "vaccination",
  others = "others",
  home = "home",
  allopathy = "allopathy",
  naturopathy = "naturopathy",
  exercise = "exercise"
}

export enum SpecialLabCategories {
  covid = "covid",
  foreignMedical = "foreignMedical"
}
export interface ServiceProviderRate {
  id: number;
  rate: number | string;
  resourceCentreId: number;
  serviceId: number;
  serviceProviderId: number | string;
  unit: "rupee" | "percent";
  // eslint-disable-next-line camelcase
  updated_at?: string;
  // eslint-disable-next-line camelcase
  created_at?: string;
}

export interface MedicineInterface {
  brand: string;
  genericName: string;
  strength: string;
  code: string;
  genericCode: string;
  form: string;
}

export interface ProductInterface {
  publicBooking: boolean;
  id?: number;
  name: string;
  productPrice: number;
  vatPct: number;
  servicePriceExVAT: number;
  productUnits: string;
  category: ProductType | SpecialLabCategories;
  isTaxable: boolean;
  rates: ServiceProviderRate[];
  materialCharge: number;
  serviceProviderRateUnit: "rupee" | "percentage";
  serviceProviderId?: number;
  active: boolean;
  grossTotalPrice: number;
  productType: "single" | "package";
  products?: [];
  resourceCentreId: number;
  servicePriceExcVAT: number;
  serviceServices?: [];
  unit: "pcs";
  info?: MedicineInterface;
  productisableId?: number;
  productisableName?: "labTestGroup" | "medicine";
  labCharge?: number;
  entity?: string;
  structuredInfo: {
    brand: string;
    code: string;
    form: string;
    genericCode: string;
    genericName: string;
    isNarcotics: boolean;
    strength: string;
  };
  salesTaxationId?: number;
  departmentId: number;
  publicDescription?: string;
  instructions?: string;
  referenceId: number;
  package: string;
  unitsPerPackage: number;
  handledByOkhati?: boolean;
  document: {
    rates?: ServiceProviderRate[] | [];
    products?: ProductInterface[];
  };
  origin: string | null;
}

export enum ProductTypeInfo {
  Sellable = "Sellable",
  InternalUse = "InternalUse"
}

export const initialQuery = {
  page: 0,
  pageSize: 100,
  search: "",
  filter: "all"
};

export interface QueryProps {
  page?: number;
  pageSize?: number;
  search?: string;
  productType?: number;
  intangible?: boolean;
  limit?: number;
  transactionType?: number;
  supplierInvoiceId?: string;
  searchType?: string;
  filter?: ProductListFilterType | StockTrxListFilterType;
  searchBy?: ProductSearchByType;
  excludeStockAndRates?: boolean;
  withQuantity?: boolean;
  isKitchenPharmacyBranch?: boolean;
}

export const initialUnitOptions = [
  { value: "TAB", label: "TAB" },
  { value: "PAIR", label: "PAIR" },
  { value: "BOX", label: "BOX" },
  { value: "ROLL", label: "ROLL" },
  { value: "PCS", label: "PCS" },
  { value: "JAR", label: "JAR" },
  { value: "TUBE", label: "TUBE" },
  { value: "CAP", label: "CAP" },
  { value: "AMP", label: "AMP" }
];

export const initialPackageOptions = [
  { value: "STRIP", label: "STRIP" },
  { value: "BOX", label: "BOX" },
  { value: "PCS", label: "PCS" },
  { value: "PKT", label: "PKT" }
];

export enum VoucherAssociations {
  PURCHASE = "purchase",
  SALES = "sales",
  BOTH = "both"
}

export type VoucherAssociationsType =
  | VoucherAssociations.PURCHASE
  | VoucherAssociations.SALES
  | VoucherAssociations.BOTH;

export enum TaxType {
  PURCHASE = "purchase",
  SALES = "sales"
}

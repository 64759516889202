import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/schedules";
import * as NotificationActions from "./notification";
import hasOwnProperty from "../helpers/object";
import { IThunkDispatch } from "../store";

const datesAreOnSameDay = (first, second) => {
  const [firstDate, secondDate] = [new Date(first), new Date(second)];
  // check 0:00 as 11:59:59 to avoid calculating it as next day
  const processedSecondDate = new Date(secondDate.getTime() - 1);
  return (
    firstDate.getFullYear() === processedSecondDate.getFullYear() &&
    firstDate.getMonth() === processedSecondDate.getMonth() &&
    firstDate.getDate() === processedSecondDate.getDate()
  );
};

export enum Type {
  GET_SCHEDULES = "GET_SCHEDULES",
  GET_SCHEDULE = "GET_SCHEDULE",
  POST_SCHEDULE = "POST_SCHEDULE",
  PUT_SCHEDULE = "GET_SCHEDULE",
  DELETE_SCHEDULE = "DELETE_SCHEDULE"
}

export const getSchedules =
  (
    serviceProviderId: number,
    start: Date,
    end: Date,
    resourceId?: number,
    publicEventChecked?: boolean
  ) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    dispatch(
      actionCreatorAsync(Type.GET_SCHEDULES, async () =>
        api.getSchedules(serviceProviderId, start, end, resourceId, publicEventChecked)
      )
    );
  };

export const deleteSchedule =
  (id: number, next: () => void) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    dispatch(
      // eslint-disable-next-line
      actionCreatorAsync(Type.DELETE_SCHEDULE, async () => {
        const res = await api.deleteSchedule(id);
        if (res) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Schedule sucessfully deleted",
              autoTimeout: true
            })
          );
          next();
          return { id };
        }
      })
    );
  };

export const saveSchedule =
  (sData: { tz: unknown, id: number, start: Date, end: Date }) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    const scheduleData = sData;
    try {
      scheduleData.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      console.log("no support for Intl.DateTimeFormat().resolvedOptions()");
    }

    if (!datesAreOnSameDay(scheduleData.start, scheduleData.end)) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Please create schedule within the same day!",
          autoTimeout: true
        })
      );
    } else if (scheduleData.id) {
       dispatch(
        actionCreatorAsync(Type.PUT_SCHEDULE, async () => {
          const data = await api.putSchedule(scheduleData.id, scheduleData);
          return data;
        })
      );
    } else {
       dispatch(
        actionCreatorAsync(Type.POST_SCHEDULE, async () => {
          const data = await api.postSchedule(scheduleData);
          if (hasOwnProperty(data, "status") && data.status === 405) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: data.data,
                autoTimeout: true
              })
            );
          }
          return data;
        })
      );
    }
  };

import React from "react";
import upperFirst from "lodash/upperFirst";
import startCase from "lodash/startCase";
import { Typography, Box } from "@mui/material";
import { t } from "../../../components/translate";

const IntraoralAssessmentContent = ({
  intraOralDoc
}: {
  intraOralDoc: Record<string, unknown>;
}): JSX.Element => {
  const oralTypes = ["permanent", "primary"];
  const intraOralDataDisplay = (data: string, item: string, type: string) => {
    let st = upperFirst(data);
    if (data !== "impacted" && data !== "missing") {
      if (data === "decayed") {
        st = startCase(Object.keys(intraOralDoc[type][item][data].values)[0]);
        if (Object.keys(intraOralDoc[type][item][data].values)[0] === "caries") {
          st += `(Class ${
            intraOralDoc[type][item][data].values[
              Object.keys(intraOralDoc[type][item][data].values)[0]
            ].level
          })`;
        }
      } else if (data === "mobile" || data === "fractured") {
        st += `(Class ${intraOralDoc[type][item][data].level})`;
      }
    }
    return st;
  };

  const quadrantMapper = {
    permanent: {
      1: t("history.upperRight"),
      2: t("history.upperLeft"),
      3: t("history.lowerLeft"),
      4: t("history.lowerRight")
    },
    primary: {
      5: t("history.upperRight"),
      6: t("history.upperLeft"),
      7: t("history.lowerLeft"),
      8: t("history.lowerRight")
    }
  };
  return (
    <Box>
      {oralTypes.map((type) => (
        <Box key={type}>
          {Object.keys(intraOralDoc[type]).length !== 0 && (
            <Box width="100%">
              <Typography>
                <Box fontWeight={600} component="span">
                  {type === "permanent" ? t("assessment.permanent") : t("assessment.primary")}
                </Box>
              </Typography>
              {Object.keys(intraOralDoc[type]).map((item) => (
                <Box key={item} display="flex" width="100%" justifyContent="flex-start" gap={2}>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {`${item} (${quadrantMapper[type][item.charAt(0)]})`}
                    </Box>
                  </Typography>

                  <Box display="flex" justifyContent="flex-start">
                    <Typography>
                      {Object.keys(intraOralDoc[type][item])
                        .filter((val) => intraOralDoc[type][item][val]?.checked)
                        .map(
                          (data, index) =>
                            `${index ? ", " : ""}${intraOralDataDisplay(data, item, type)}`
                        )}
                      {"others" in intraOralDoc[type][item] &&
                        `  ${intraOralDoc[type][item].others}`}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default IntraoralAssessmentContent;

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import Chip from "@mui/material/Chip";
import * as moment from "moment-timezone";
import intersection from "lodash/intersection";
import { tl, t } from "../../components/translate";
import Filters from "./Filters";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import * as reportsApi from "../../api/reports";
import * as serviceActions from "../../actions/services";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import { downloadCSV } from "../../helpers/files";
import BookingStatus from "../Calendar/Booking/BookingStatus";
import "./Reports.scss";
import useMobileScreen from "../../hooks/useMobileScreen";
import Can from "../Policy/Can";
import { notificationAdd } from "../../actions/notification";
import { commonErrorMessage } from "../../helpers/messages";
import { PatientFlowProps } from "../../interfaces/ReportInterface";
import { RootState } from "../../store";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  getDefaultKeyValuesColumns,
  patientFlowDefaultCols,
  patientFlowHiddenCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import useGetDepartments from "../../hooks/department";
import { Department } from "../../interfaces/DepartmentInterface";
import PatientFlowDetailView from "./PatientFlowDetailView";
import { ReminderInterface } from "../../interfaces/ReminderInterface";
import useDepartmentSubscription from "../../hooks/useDepartmentSubscription";
import { getCustomerNumber } from "../Client/ClientList";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const ServiceChip = ({ serviceId, services }) => {
  const service = React.useMemo(
    () => (services || []).find(({ id }) => id === serviceId),
    [serviceId, services]
  );
  return <Chip size="small" label={service ? service.name : serviceId} />;
};

const spName = ({ spTitle, spFirstName, spLastName }) =>
  `${capitalize(spTitle)}${spTitle ? " " : ""}${capitalize(spFirstName)} ${capitalize(spLastName)}`;
export const clientName = ({
  clientFirstName,
  clientLastName
}: {
  clientFirstName: string;
  clientLastName: string;
}): string => `${capitalize(clientFirstName)} ${capitalize(clientLastName)}`;

const or = (val) => val || "";
const paidStatus = (val) => (val ? "paid" : "");

const getDepartmentName = (id: number, departmentList: Department[]): string => {
  const foundDepartment = departmentList.find((item) => item.id === id);
  return foundDepartment ? foundDepartment.name : "-";
};

const docColumns = () => [
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.clientPhone"),
  t("reports.clientSecondaryPhone"),
  t("reports.clientEmail"),
  t("reports.clientDOB"),
  t("reports.clientGender"),
  t("clients.address"),
  t("reports.department"),
  t("reports.serviceProvider"),
  t("reports.reasonOfVisit"),
  t("reports.remarks"),
  t("reports.referredBy"),
  t("reports.knownUsFrom"),
  t("reports.status"),
  t("reports.services"),
  t("reports.from"),
  t("reports.fromAD"),
  t("reports.duration"),
  t("reports.price"),
  t("reports.paymentStatus")
];

const docRowProcessor = (
  {
    customerNumber,
    clientFirstName,
    clientLastName,
    clientCity,
    clientPhone,
    secondaryPhone,
    clientEmail,
    clientDOB,
    clientGender,
    clientAddress,
    departmentId,
    services,
    reasonOfVisit,
    remarks,
    referredBy,
    knownFrom,
    status,
    start,
    duration,
    paymentInfo,
    spName: spFullName,
    clientName: clientFullName,
    // eslint-disable-next-line camelcase
    __meta__row_type
  },
  servicesObjs,
  departmentList
): string[] | null => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return null;
  const dob = clientDOB ? calFns.bsShortDate(clientDOB) : "";
  const { amount, isPaid } = paymentInfo || {};
  return [
    or(getCustomerNumber(customerNumber)),
    or(clientFullName),
    or(clientFirstName),
    or(clientLastName),
    or(clientPhone),
    or(secondaryPhone),
    or(clientEmail),
    dob,
    clientGender ? t(`clients.gender.${clientGender}`) : "",
    `${or(clientAddress)} ${or(clientCity)}`,
    getDepartmentName(departmentId, departmentList),
    or(spFullName),
    or(reasonOfVisit),
    or(remarks),
    or(referredBy),
    or(knownFrom),
    or(status),
    (services || [])
      .map((sId) => (servicesObjs.find(({ id }) => id === sId) || { name: sId }).name)
      .toString(),
    calFns.bsShortDate(start),
    calFns.adShortDate(start),
    or(duration),
    or(amount),
    paidStatus(isPaid)
  ];
};

const filterDataByServices = (rows = [] as PatientFlowProps[], serviceIds = [] as number[]) => {
  if (!serviceIds.length) return rows;
  return rows.filter(({ services = [] }) => Boolean(intersection(services, serviceIds).length));
};

enum CustomerType {
  CLIENT = "client"
}

enum VendorType {
  SERVICE_PROVIDER = "serviceProvider"
}

const getFullName = (row, type) => {
  if (!row) return "";
  if (type === CustomerType.CLIENT) {
    return `${row.clientFirstName} ${row.clientLastName}`.toUpperCase();
  }
  return `${capitalize(row.title)} ${capitalize(row.firstName)} ${capitalize(row.lastName)}`;
};

const addFullName = (data = [] as PatientFlowProps[]): PatientFlowProps[] =>
  data.map((row) => ({
    ...row,
    clientName: getFullName(row, CustomerType.CLIENT),
    spName: getFullName(row?.serviceProvider, VendorType.SERVICE_PROVIDER)
  }));

const AppointmentReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const services = useSelector((state: RootState) => state.services.collection);
  const { mode } = useSelector((state: RootState) => state.userContext);
  const serviceProviderId = useSelector((state: RootState) =>
    mode === VendorType.SERVICE_PROVIDER ? state.userContext.userCreds.authenticableId : null
  );
  const { isDepartmentSubscribed } = useDepartmentSubscription();
  const deptId = useSelector((state: RootState) => state.userContext.user.departmentId);
  const servicObjs = services;
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    serviceProviderIds: serviceProviderId ? [serviceProviderId] : [],
    serviceIds: [],
    clientIds: [],
    onlyPublic: false,
    departmentId: deptId || null,
    bookingStatus: []
  });
  const [reportData, setReportData] = React.useState<PatientFlowProps[]>([]);
  const departments = useGetDepartments();

  React.useEffect(() => {
    if (!services || !services.length) {
      dispatch(serviceActions.getServices());
    }
  }, [services, dispatch]);
  React.useEffect(() => {
    (async () => {
      try {
        const res = await reportsApi.patientFlowReport(filters);
        const result = addFullName(filterDataByServices(res, filters.serviceIds));
        setReportData(result);
      } catch (error) {
        notificationAdd({
          id: new Date().getTime(),
          message: commonErrorMessage,
          autoTimeout: true,
          variant: "error"
        });
      }
    })();
  }, [filters]);

  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const handleViewClose = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(
      patientFlowDefaultCols,
      patientFlowHiddenCols,
      !isDepartmentSubscribed
    )
  );
  const [open, setOpen] = React.useState(false);

  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState<ReminderInterface | null>(null);
  const isMobileScreen = useMobileScreen();
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:patientFlowReport">
      <Box mt={4} overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1000px" : "auto"}>
          <Box margin="0px 32px">
            <Box marginTop="32px">
              <Filters
                filters={filters}
                onSetFilters={(updatedFilters) => setFilters(updatedFilters)}
                disableDepartment={!!deptId}
                showFuturePeriods
              />
            </Box>
          </Box>
          <Box
            sx={{
              overflowX: "auto",
              mx: "auto"
            }}
          >
            <Box
              marginTop="32px"
              className="patientFlowReportList"
              width={isMobileScreen ? "960px" : "auto"}
            >
              <List
                automation="patientFlowReportList"
                data={reportData}
                rowHeight={50}
                defaultSortColumn="date"
                defaultSortOrder={-1}
                adjustHeightToContents
                columns={[
                  {
                    key: "name",
                    label: tl("reports.client"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      getFullName(a, CustomerType.CLIENT).toLowerCase() >
                      getFullName(b, CustomerType.CLIENT).toLowerCase()
                        ? 1
                        : -1,
                    formatter: (row) => (
                      <Typography
                        component="div"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start"
                        }}
                      >
                        <Box
                          style={{
                            textDecoration: `${row?.active ? "underline" : ""}`,
                            cursor: "pointer",
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                          onClick={(e) => {
                            if (row?.active) {
                              e.stopPropagation();
                              setSelectedClientId(row.client.id);
                              setClientInfoPanel(true);
                            }
                          }}
                        >
                          {`${row.clientName} ${row?.active ? "" : "(deleted)"}`}
                        </Box>
                        <Box fontSize="small" color="gray">
                          {getCustomerNumber(row.customerNumber)}
                        </Box>
                      </Typography>
                    )
                  },
                  {
                    key: "serviceProvider",
                    label: tl("reports.serviceProvider"),
                    sortable: true,
                    sortFunction: (a, b) =>
                      spName(a).toLowerCase() > spName(b).toLowerCase() ? 1 : -1,
                    formatter: (row) => <Typography>{row.spName}</Typography>,
                    segmentable: true,
                    segmentBy: ({ sp }) => sp
                  },
                  {
                    key: "date",
                    label: tl("reports.datetime"),
                    sortable: true,
                    sortFunction: (a, b) => (new Date(a.start) > new Date(b.start) ? 1 : -1),
                    formatter: ({ start }) => (
                      <Typography>{`${calFns.convertADtoBS(moment(start)).formatted2} - ${moment(
                        start
                      ).format("h:mm a")}`}</Typography>
                    )
                  },
                  {
                    key: "services",
                    label: tl("reports.services"),
                    formatter: ({ services: bookedServices }) => (
                      <Typography
                        sx={{
                          width: "100%",
                          overflow: "hidden"
                        }}
                      >
                        {(bookedServices || []).map((s) => (
                          <ServiceChip key={s} serviceId={s} services={servicObjs} />
                        ))}
                      </Typography>
                    )
                  },
                  {
                    key: "department",
                    label: tl("reports.department"),
                    formatter: ({ departmentId }) => (
                      <Typography>{getDepartmentName(departmentId, departments)}</Typography>
                    ),
                    sortable: false
                  },
                  {
                    key: "status",
                    label: tl("reports.status"),
                    formatter: ({ status }) => <BookingStatus booking={{ status }} hideLabel />,
                    sortable: true,
                    segmentable: true,
                    segmentBy: ({ status }) => status
                  },
                  {
                    key: "reasonOfVisit",
                    label: tl("reports.remarks"),
                    formatter: ({ remarks }) => (
                      <Typography
                        sx={{ width: 220, height: 40, overflow: "hidden" }}
                        title={remarks}
                      >
                        {remarks}
                      </Typography>
                    )
                  },
                  {
                    key: "clientPhone",
                    label: "Client Phone",
                    formatter: ({ clientPhone }) => <Typography>{clientPhone}</Typography>
                  },
                  {
                    key: "clientEmail",
                    label: "Client Email",
                    formatter: ({ clientEmail }) => <Typography>{clientEmail}</Typography>
                  },
                  {
                    key: "clientDOB",
                    label: "Client DOB",
                    formatter: ({ clientDOB }) => (
                      <Typography>{clientDOB ? calFns.bsShortDate(clientDOB) : ""}</Typography>
                    )
                  },
                  {
                    key: "clientGender",
                    label: "Client Gender",
                    formatter: ({ clientGender }) => <Typography>{clientGender}</Typography>
                  },
                  {
                    key: "clientAddress",
                    label: "Client Address",
                    formatter: ({ clientAddress }) => <Typography>{clientAddress}</Typography>
                  },
                  {
                    key: "clientName",
                    label: "Client Name",
                    formatter: ({ clientName: clientFullName }) => (
                      <Typography>{clientFullName}</Typography>
                    )
                  }
                ].filter((row) => listColumns[row.key])}
                onRowClick={(row) => setSelectedEvent(row)}
                segementSummaryRenderer={(acc) => (
                  <Typography>
                    <Box
                      component="span"
                      display="flex"
                      padding="8px 32px 4px 24px"
                      fontWeight="500"
                    >
                      {acc.segment}
                    </Box>
                  </Typography>
                )}
              >
                <EmptyView>
                  <Box textAlign="center" padding="50px">
                    No items to show.
                  </Box>
                </EmptyView>
                <ListActions>
                  {({ getProcessedData }) => (
                    <Menu>
                      <MenuItem
                        onClick={() =>
                          downloadCSV(
                            `${t("reports.appointmentReport")}-${
                              calFns.convertADtoBS(filters.from).formatted
                            }-${calFns.convertADtoBS(filters.until).formatted}`,
                            getProcessedData(),
                            docColumns(),
                            (row) => docRowProcessor(row, services, departments)
                          )
                        }
                      >
                        {tl("reports.excel")}
                      </MenuItem>
                      <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                    </Menu>
                  )}
                </ListActions>
              </List>
              <ListHeaderShowHideDialog
                onChange={(updatedColumns) => setListColumns(updatedColumns)}
                requiredColumns={["serviceProvider", "status"]}
                open={open}
                onClose={() => setOpen(false)}
                columns={listColumns}
              />
              {showClientInfoPanel &&
                (clientEditMode ? (
                  <ClientCreateEdit
                    setEditMode={setClientEditMode}
                    clientId={selectedClientId}
                    mode="edit"
                    onCancel={() => {
                      setClientEditMode(false);
                    }}
                    stayOnCurrentPage
                  />
                ) : (
                  <ClientInfo
                    id={selectedClientId}
                    handleViewClose={handleViewClose}
                    stayOnCurrentPage
                    setEditMode={setClientEditMode}
                  />
                ))}
            </Box>
            {selectedEvent && (
              <PatientFlowDetailView
                onClose={() => setSelectedEvent(null)}
                data={{ ...selectedEvent, clientId: selectedEvent.client.id }}
              />
            )}
          </Box>
        </Box>{" "}
      </Box>
    </Can>
  );
};

export default AppointmentReport;

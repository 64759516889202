import * as React from 'react';
import { connect } from 'react-redux';

import './Modals.scss';
import AssessmentModal from './Assessment/AssessmentModal';
import ServiceProviderModal from './Assessment/ServiceProviderModal';
import ClientCreateModal from './Client/ClientCreateModal';
import SupplierCreateModal from './Supplier/SupplierCreateModal';
import ServiceProviderShow from './ServiceProvider/ServiceProviderShow';
import SlotBookModal from './Slot/SlotBookModal';
import ChangePassword from './User/ChangePassword';
import { ModalInterface } from '../../actions/navigation';
import Dialog from '../../components/Dialog/Dialog';
import ReferrerCreateModal from './Referrer/ReferrerCreateModal';

const Modals = {
  ServiceProviderShow,
  SlotBookModal,
  Dialog,
  ChangePassword,
  ClientCreateModal,
  AssessmentModal,
  ServiceProviderModal,
  SupplierCreateModal,
  ReferrerCreateModal
};

/*
  To have ReactModals on root level so that no other element's z-index can have effect,
  they are rendered in seperate node on top level instead of inside the main react app root.
 */
class ReactModals extends React.Component<any, any> {
  public render(): JSX.Element {
    const {
      navigation: { modals }
    } = this.props;

    if (!modals.length) {
      return null;
    }

    return (
      <div className="ReactModals">
        {modals.map(({ component, props }: ModalInterface, index) => {
          const ModalComponent = Modals[component];
          return <ModalComponent {...props} key={index} index={index} />;
        })}
      </div>
    );
  }
}

export default connect(
  ({ navigation }) => ({ navigation }),
  () => ({})
)(ReactModals);

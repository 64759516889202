import React from "react";
import { Box, Button } from "@mui/material";
import Panel from "../../../../components/Panel";
import EditScreen from "../EditScreen";
import * as NotificationActions from "../../../../actions/notification";
import { removeSelectedTests } from "../../../../slices/labTestSettingsSlice";
import { RootState } from "../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import LabGroupResetToDefault from "./LabGroupResetToDefault";

interface Props {
  groupName: string;
  onClose: () => void;
}

const LabTestGroupEditPanel = ({ onClose, groupName }: Props): JSX.Element => {
  const [isAboutToReset, setIsAboutToReset] = React.useState(false);
  const selectedTestsUUIDs = useAppSelector((state: RootState) => state.labSettings.checkedUUIDs);
  const dispatch = useAppDispatch();

  return (
    <Panel
      onClose={onClose}
      title={`Edit ${groupName || ""}`}
      wrapperStyle={{ minWidth: "70%" }}
      genericButton={
        <Box display="flex">
          <Button variant="outlined" color="primary" onClick={() => setIsAboutToReset(true)}>
            Reset To Defaults
          </Button>
          {selectedTestsUUIDs.length > 0 && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                dispatch(removeSelectedTests());
                dispatch(
                  NotificationActions.notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "warning",
                    message: "Selected Tests have been removed!",
                    autoTimeout: true
                  })
                );
              }}
              style={{ marginLeft: "8px" }}
            >
              Remove Selected Tests
            </Button>
          )}
        </Box>
      }
    >
      <Box px={2} pb={2}>
        <EditScreen onCancel={onClose} />
        <LabGroupResetToDefault isOpen={isAboutToReset} onClose={() => setIsAboutToReset(false)} />
      </Box>
    </Panel>
  );
};

export default LabTestGroupEditPanel;

import React from "react";
import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import Panel from "../../components/Panel";
import { StockProducts } from "../../interfaces/StockInterfaces";

const StockProductDetails = ({
  selectedProduct,
  onClose
}: {
  selectedProduct: StockProducts;
  onClose: () => void;
}): JSX.Element => {
  if (!selectedProduct) return null;
  return (
    <Panel onClose={onClose} title="Product Details">
      <Box ml={4}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontSize="20px" fontWeight="bold">
            {startCase(selectedProduct.name)}
          </Typography>
        </Box>
        {(selectedProduct.genericName || selectedProduct.structuredInfo?.genericName) && (
          <Box display="flex" alignItems="center" mb={2}>
            <Typography fontWeight="bold" width="140px">
              Generic Name:
            </Typography>
            <Typography ml={3}>
              {startCase(selectedProduct.genericName || selectedProduct.structuredInfo.genericName)}
            </Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontWeight="bold" width="140px">
            Category:
          </Typography>
          <Typography ml={3}>{startCase(selectedProduct.category)}</Typography>
        </Box>
        {selectedProduct.productCode && (
          <Box display="flex" alignItems="center" mb={2}>
            <Typography fontWeight="bold" width="140px">
              Product Code:
            </Typography>
            <Typography ml={3}>{selectedProduct.productCode}</Typography>
          </Box>
        )}
        {selectedProduct.manufactureBy && (
          <Box display="flex" alignItems="center" mb={2}>
            <Typography fontWeight="bold" width="140px">
              Manufactured By:
            </Typography>
            <Typography ml={3}>{startCase(selectedProduct.manufactureBy)}</Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontWeight="bold" width="140px">
            Unit:
          </Typography>
          <Typography ml={3}>{selectedProduct.unit}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontWeight="bold" width="140px">
            Package:
          </Typography>
          <Typography ml={3}>{startCase(selectedProduct.package)}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontWeight="bold" width="140px">
            Units Per Package:
          </Typography>
          <Typography ml={3}>{selectedProduct.unitsPerPackage}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontWeight="bold" width="140px">
            Units Price Exc VAT:
          </Typography>
          <Typography ml={3}>Rs. {selectedProduct.unitPriceExcVAT}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography fontWeight="bold" width="140px">
            Units Price Inc VAT:
          </Typography>
          <Typography ml={3}>Rs. {selectedProduct.unitPriceIncVAT}</Typography>
        </Box>
        {selectedProduct.unitPriceIncVAT > 0 && (
          <Box display="flex" alignItems="center" mb={2}>
            <Typography fontWeight="bold" width="140px">
              VAT Percentage:
            </Typography>
            <Typography ml={3}>{selectedProduct.unitPriceIncVAT}</Typography>
          </Box>
        )}
      </Box>
    </Panel>
  );
};

export default StockProductDetails;

export const getNewSchedule = (defaults) => {
  const {
    serviceProviderId,
    resourceCentreId,
    start,
    end,
    duration = 15,
    bookableResource
  } = defaults;
  return {
    id: null,
    start,
    end,
    eStart: start,
    eEnd: null,
    duration: 15,
    consultServiceId: null,
    videoServiceId: null,
    isVideoAppointment: false,
    daysInWeek: null,
    resourceCentreId,
    serviceProviderId,
    resourceId: null,
    video: null,
    onSite: true,
    active: true,
    homeServiceId: null,
    bookableResource,
    onHome: false,
    oVideoServicePrice: 0,
    oConsultServicePrice: 0,
    oHomeServicePrice: 0
  };
};

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import { startCase } from "lodash";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { useState } from "react";
import { getTemplateBatchInfo } from "../../../actions/helpers/billHelper";
import * as NotificationActions from "../../../actions/notification";
import { EntityType, getStockProducts } from "../../../actions/stockProductActions";
import { t, tl } from "../../../components/translate";
import useCan from "../../../hooks/useCan";
import { BillItemSource, StockUnitTypes } from "../../../interfaces/BillInterfaces";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { Referrer } from "../../../interfaces/ReferrerInterface";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";
import { IThunkDispatch, RootState } from "../../../store";
import ServiceProviderSelect from "../../ServiceProvider/ServiceProviderAutoSelect";
import styles from "./BillEditor.module.css";
import { scannerDetected } from "../../../actions/bill";
import DebouncedTextFieldWithScannerDetect from "../../../components/DebounceTextFieldWithScannerDetect";
import { commonErrorMessage } from "../../../helpers/messages";
import ServiceProviderCommissionDialog from "./ServiceProviderCommissionDialog";
import { useAppDispatch } from "../../../store/hooks";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontWeight: "normal"
  }
}))(Tooltip);

interface ProductDataProps {
  id?: number;
  name?: string;
  productId: number;
  description: string;
  delivered: boolean;
  serviceProviderId: number;
  active: boolean;
  productisableId: number;
  productisableName: string;
}

interface SubItemInterface {
  subItems: ProductDataProps[];
}

export function referrerPriceModifier({
  referrers,
  billReferrerId,
  perUnit,
  action,
  isStockProduct,
  productId
}: {
  referrers: Array<Referrer>;
  billReferrerId: number;
  perUnit: number;
  action: () => void;
  isStockProduct: boolean;
  productId: number;
}): number {
  if (!billReferrerId) return perUnit;

  const relatedReferrer = referrers.find((referrer) => referrer.id === billReferrerId);
  if (!relatedReferrer.productService || !relatedReferrer.productService?.length) {
    return perUnit;
  }
  const toFindFrom = relatedReferrer.productService
    .filter((el) => (isStockProduct ? Boolean(el.product) : Boolean(el.service)))
    .map((el) =>
      el.product ? { ...el.product, price: el.price } : { ...el.service, price: el.price }
    );

  const relatedProduct = toFindFrom.find((el) => Number(el.id) === Number(productId));
  if (relatedProduct) {
    action();
    return relatedProduct.price;
  }
  return perUnit;
}

export const getProductData = ({
  id,
  name,
  serviceProviderId,
  active,
  productisableId,
  productisableName
}: Partial<ProductDataProps>): ProductDataProps => ({
  productId: id,
  description: name,
  delivered: true,
  serviceProviderId,
  active,
  productisableId,
  productisableName
});
export const generateSubItems = (
  products: ProductInterface[],
  allProducts?: ServiceInterface[],
  serviceProviderId?: number | null
): SubItemInterface => {
  const subItems = (products || []).map((item) => {
    const service = allProducts?.find((serviceItem) => item.id === serviceItem.id);
    const spId = service?.document?.rates?.find(
      (rate) => rate.serviceProviderId === serviceProviderId
    )?.serviceProviderId;
    return getProductData({ ...item, ...(spId ? { serviceProviderId: spId } : {}) });
  });
  return { subItems };
};

const BillProductSubItem = ({
  subItem,
  updateBillItem,
  billItem,
  subItems,
  products,
  disable,
  packageId
}) => {
  const [showServiceCommissionPopup, setShowServiceCommissionPopup] = useState(false);
  const productRates = products
    .find(({ id }) => id === packageId)
    ?.document.products.find(({ id }) => id === subItem.productId)?.document?.rates;

  const chosenProductContainsRates = Boolean(productRates?.length);
  const getServiceProvidersFromProductRates = productRates
    ? productRates.map(({ serviceProviderId }) => serviceProviderId)
    : [];
  return (
    <Box key={subItem.productId} display="flex" alignItems="center">
      <Checkbox
        disabled={disable}
        checked={subItem.delivered}
        onChange={(e) => {
          const updatedSubItem = { ...subItem, delivered: e.target.checked };
          updateBillItem({
            ...billItem,
            subItems: subItems.map((item) =>
              item.productId === updatedSubItem.productId ? updatedSubItem : item
            )
          });
        }}
        color="primary"
      />

      <Box flexGrow={1} style={{ color: subItem.active !== false ? "inherit" : "#db2323" }}>
        {subItem.description}
      </Box>
      {chosenProductContainsRates && (
        <Box flexBasis="180px" display="inline-block" alignSelf="flex-end">
          <ServiceProviderSelect
            hideLabel
            disabled={disable}
            withSpAssign
            serviceProviderId={subItem.serviceProviderId}
            isClearable
            placeholder={t("billing.selectServiceProvider")}
            limitBy={getServiceProvidersFromProductRates}
            onChange={(id, sp) => {
              if (id) {
                const updatedSubItem = { ...subItem, serviceProviderId: id };
                updateBillItem({
                  ...billItem,
                  subItems: subItems.map((item) =>
                    item.productId === updatedSubItem.productId ? updatedSubItem : item
                  )
                });
              } else if (sp) {
                setShowServiceCommissionPopup(true);
              } else {
                const updatedSubItem = { ...subItem, serviceProviderId: null };
                updateBillItem({
                  ...billItem,
                  subItems: subItems.map((item) =>
                    item.productId === updatedSubItem.productId ? updatedSubItem : item
                  )
                });
              }
            }}
          />
        </Box>
      )}
      {subItem.productId && subItem.source === BillItemSource.services && (
        <ServiceProviderCommissionDialog
          serviceId={billItem.productId}
          openDialog={showServiceCommissionPopup}
          onCancel={() => setShowServiceCommissionPopup(false)}
          onSave={(spId) => {
            const updatedSubItem = { ...subItem, serviceProviderId: spId };
            updateBillItem({
              ...billItem,
              subItems: subItems.map((item) =>
                item.productId === updatedSubItem.productId ? updatedSubItem : item
              )
            });
          }}
        />
      )}
      <Box
        key="removeItem"
        className="rowDeleteButton"
        flexBasis="60px"
        padding="4px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          updateBillItem({
            ...billItem,
            subItems: subItems.filter((item) => subItem.productId !== item.productId)
          });
        }}
      >
        <RemoveIcon color="error" />
      </Box>
    </Box>
  );
};

const selectVatPct = (product, isAccountSubscribed) => {
  if (isAccountSubscribed) {
    return product?.salesTaxation?.rate || 0;
  }
  return product?.vatPct || 0;
};

const BIllProductItem = ({
  billItem,
  updateBillItem,
  products,
  disable,
  batchOptions,
  getBatchOptions,
  totalQuantity,
  isCreditNote,
  selectedStockProducts,
  setSelectedStockProducts,
  setShowBatchPopup,
  setNotEnoughQuantityMsg,
  setIsOutOfStock,
  loadStockProducts,
  itemRef,
  quantityRef,
  draft,
  focusAddItemButton
}) => {
  const [collapseSubItems, setCollapseSubItems] = React.useState(false);
  const [showServiceCommissionPopup, setShowServiceCommissionPopup] = useState(false);
  const stockProducts = useSelector((state: RootState) => state.stockProducts.collection);
  const isScanning = useSelector((state: RootState) => state.bills.isScanning);
  const currBatchOptions = batchOptions[billItem?.productId || billItem.productData?.id] || [];
  const isAccountSubscribed =
    useSelector(
      (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
    ) || false;
  const productOptions = React.useMemo(() => {
    const formattedProducts = products.map((product) => {
      const genericName = product?.structuredInfo?.genericName
        ? `(${product.structuredInfo.genericName})`
        : "";
      return {
        value: product.name || "",
        label: `${product.name || ""} ${genericName}`,
        product,
        barCode: product.barCode
      };
    });

    // filter medication products if number of batches get exhausted
    const productIdsToFilter = [];
    const productsCount = selectedStockProducts.reduce(
      (total, curr) =>
        total[curr.productId]
          ? { ...total, [curr.productId]: total[curr.productId] + 1 }
          : { ...total, [curr.productId]: 1 },
      {}
    );
    Object.keys(productsCount).forEach((productKey) => {
      if (productsCount[productKey] === batchOptions?.[productKey]?.length) {
        productIdsToFilter.push(Number(productKey));
      }
    });
    const filteredProducts = formattedProducts.filter(
      (product) => !productIdsToFilter.includes(product?.product?.id)
    );
    return filteredProducts.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
  }, [products, batchOptions, selectedStockProducts]);

  const { subItems } = billItem;

  const productRates =
    billItem.productData?.document?.rates ||
    products.find(({ id }) => id === billItem.productId)?.document?.rates;

  const chosenProductContainsRates = Boolean(productRates?.length);
  const getServiceProvidersFromProductRates = productRates
    ? productRates.map(({ serviceProviderId }) => serviceProviderId)
    : [];

  const referrers = useSelector((state: RootState) => state.referrers.referrers);
  const billReferrerId = useSelector((state: RootState) => state.bills.draft.referrerId);

  const dispatch = useAppDispatch();
  const [barCode, setBarCode] = React.useState<string>("");

  const onChangeHandler = React.useCallback(
    async (val) => {
      try {
        const newValue = val || { value: "" };
        const product = newValue.product || { grossTotalPrice: billItem.grossTotal };
        let { batchInfo } = billItem;
        let { quantity } = billItem;
        let perUnit =
          product.source === BillItemSource.services
            ? product?.servicePriceExcVAT
            : product.unitPriceExcVAT;

        // For accounts user
        //  products are linked to taxation rules so use vat rate from the rule defined
        // For non-accounts user
        //  products aren't linked to any tax rules, so use the value for vat defined
        const vatPct = selectVatPct(product, isAccountSubscribed);
        setIsOutOfStock(false);
        setNotEnoughQuantityMsg("");
        if (
          newValue.product?.id &&
          newValue.product?.entity === EntityType.PRODUCT &&
          !newValue.product?.intangible
        ) {
          const batchSelectOptions = await getBatchOptions(newValue.product.id);
          const selectedBatchIds = selectedStockProducts
            .filter((stockProduct) => newValue.product.id === stockProduct.productId)
            .map((prod) => prod.batchId);
          const initBatchOption = batchSelectOptions.find(
            (batch) =>
              !selectedBatchIds.includes(batch.batchId) && moment().isBefore(batch.expiryDate)
          );
          if (initBatchOption) {
            quantity = 1;
            batchInfo = {
              ...batchInfo,
              batchId: initBatchOption.batchId,
              expiryDate: initBatchOption.expiryDate
            };
            perUnit = initBatchOption.unitPriceExcVAT;
            setSelectedStockProducts((prevState) => [...prevState, initBatchOption]);
          }
          if (batchSelectOptions.length) {
            setShowBatchPopup(true);
          } else {
            quantity = 0;
            batchInfo = { ...batchInfo, quantity };
            setNotEnoughQuantityMsg("This product is currently out of stock.");
            setIsOutOfStock(true);
          }
        } else {
          quantity = 1;
          batchInfo = getTemplateBatchInfo();
          setSelectedStockProducts((prevState) =>
            prevState.filter((batch) => batch.batchId !== billItem.batchInfo.batchId)
          );
        }

        if (val?.product) {
          const perUnitPrice = referrerPriceModifier({
            referrers,
            billReferrerId,
            perUnit,
            action: () => {
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "warning",
                  message: "Bill item price has been updated with referrer specific price!",
                  autoTimeout: false
                })
              );
            },
            isStockProduct: Boolean(batchInfo.batchId),
            productId: val.product.id
          });

          perUnit = perUnitPrice;
        }
        const updatedBillItem = {
          ...billItem,
          description: newValue.value,
          calculationBasis: "perUnit",
          perUnit: Number(perUnit),
          vatPct: Number(vatPct),
          unit: product?.unit,
          serviceProviderRateUnit: newValue.product
            ? newValue.product.serviceProviderRateUnit
            : "percentage",
          ...(product.id ? { productId: product.id } : {}),
          ...(product.code ? { code: product.code } : {}),
          ...(product?.document?.products && product.productType === "package"
            ? generateSubItems(product?.document?.products)
            : { subItems: null }),

          productType: product.productType,
          batchInfo,
          quantity,
          serviceProviderId: null,
          nonDiscountable: newValue.product?.nonDiscountable || false,
          source: product.source,
          salesLedgerId: product.salesLedgerId,
          departmentId: product.departmentId,
          salesTaxationId: product.salesTaxationId,
          type: startCase(product.category || ""),
          // default calculation on unit(change from the unit selection)
          stockUnitType: StockUnitTypes.unit,
          stockProductUnitsPerPackage: +product.unitsPerPackage || 1,
          ...(product.materialCharge ? { materialCharge: product.materialCharge } : {}),
          ...(product.labCharge ? { labCharge: product.labCharge } : {})
        };
        await updateBillItem(updatedBillItem);
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    },
    [billItem]
  );

  React.useEffect(() => {
    (async () => {
      try {
        if (barCode && isScanning) {
          const product = stockProducts.find(
            ({ active, productType, barCode: productBarCode }) =>
              active && productType === 1 && productBarCode === barCode
          );
          if (!product) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getTime(),
                message: "Product Not Found.",
                variant: "error",
                autoTimeout: true
              })
            );
            return;
          }

          const isProductSelected = draft?.billItems.some((item) => item.productId === product.id);
          if (isProductSelected) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getTime(),
                message: "This product is already selected, Please select another product.",
                variant: "error",
                autoTimeout: true
              })
            );
          } else {
            await onChangeHandler({
              value: product.name || "",
              label: `${product.name || ""} ${product.genericName}`,
              product: { ...product, source: BillItemSource.stocks }
            });
            quantityRef.current.focus();
          }
          dispatch(scannerDetected(false));
        }
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCode, stockProducts]);

  React.useEffect(() => {
    if (itemRef?.current) {
      itemRef?.current?.focus();
    }
  }, [itemRef]);

  const canAccessStockList = useCan("stock:listStock", {});

  return (
    <Box>
      <Box display="flex">
        <Box flexGrow={1}>
          <LightTooltip
            placement="top-end"
            title={
              <Box>
                <Typography fontSize={13} gutterBottom>
                  {billItem.description}
                </Typography>
                {billItem.labCharge && (
                  <Typography fontSize={12}>{`Lab charge:  ${billItem.labCharge}`}</Typography>
                )}
                {Number(billItem.materialCharge) > 0 && (
                  <Typography
                    fontSize={12}
                  >{`Material charge:  ${billItem.materialCharge}%`}</Typography>
                )}
              </Box>
            }
          >
            <Grid
              container
              spacing={0}
              sx={{
                border: "1px",
                borderColor: "rgba(0, 0, 0, 0.23)",
                borderStyle: "solid",
                borderRadius: "5px"
              }}
            >
              <Grid
                item
                xs={!billItem.subItems && chosenProductContainsRates ? 7 : 12}
                sx={{
                  height: "100%"
                }}
              >
                <Autocomplete
                  disabled={disable}
                  id="product-solo"
                  data-testmation="billProductItem"
                  className="productAutocompleteSolo"
                  options={productOptions}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : `${option.label} ${option.barCode || ""}`
                  }
                  isOptionEqualToValue={(option, value) => option.value === value}
                  value={billItem.description}
                  onChange={(_, val) => {
                    focusAddItemButton();
                    onChangeHandler(val);
                  }}
                  renderInput={(params) => (
                    <DebouncedTextFieldWithScannerDetect
                      data-testmation="selectNewProduct"
                      inputRef={itemRef}
                      debounceAt={1000}
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" }
                      }}
                      onChange={({ target, barCodeDetected }) => {
                        if (barCodeDetected) {
                          dispatch(scannerDetected(barCodeDetected));
                          setBarCode(target.value);
                        } else {
                          dispatch(scannerDetected(barCodeDetected));
                          setBarCode("");
                        }
                        const query = (target.value || "").trim();
                        if (canAccessStockList && query.length > 2) {
                          loadStockProducts({ page: 0, pageSize: 100, search: query });
                        }
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      classes={{ root: styles.productInputRoot }}
                      placeholder={t("billing.enterDescriptionOrSelectAProduct")}
                      onBlur={({ target }) => {
                        if (billItem.description !== target.value) {
                          updateBillItem({
                            ...billItem,
                            description: target.value,
                            subItems: null,
                            productData: null
                          });
                        }
                      }}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props} key={option?.product?.id + option?.label}>
                      {option?.label}
                    </li>
                  )}
                />
              </Grid>
              {!billItem.subItems && chosenProductContainsRates && (
                <Grid item xs={5}>
                  <ServiceProviderSelect
                    withSpAssign
                    hideLabel
                    isClearable
                    disabled={billItem.subItems || disable}
                    limitBy={getServiceProvidersFromProductRates}
                    placeholder={t("billing.selectServiceProvider")}
                    serviceProviderId={billItem.serviceProviderId}
                    onChange={(id, sp) => {
                      if (id) {
                        updateBillItem({
                          ...billItem,
                          serviceProviderId: id
                        });
                      } else if (sp) {
                        setShowServiceCommissionPopup(true);
                      } else {
                        updateBillItem({
                          ...billItem,
                          serviceProviderId: null
                        });
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </LightTooltip>
        </Box>
        {billItem.productId && billItem.source === BillItemSource.services && (
          <ServiceProviderCommissionDialog
            serviceId={billItem.productId}
            openDialog={showServiceCommissionPopup}
            onCancel={() => setShowServiceCommissionPopup(false)}
            onSave={(spId) => {
              updateBillItem({
                ...billItem,
                serviceProviderId: spId
              });
            }}
          />
        )}
        {isCreditNote ? (
          <Box display="flex" alignItems="center" flexBasis="100px" marginLeft="4px">
            <Typography style={{ fontSize: "12px" }}>{billItem?.batchInfo?.batchId}</Typography>
          </Box>
        ) : (
          currBatchOptions?.length > 0 &&
          totalQuantity > 0 && (
            <Box display="flex" alignItems="center" flexBasis="100px" marginLeft="4px">
              <Typography onClick={() => setShowBatchPopup(true)} style={{ fontSize: "12px" }}>
                {billItem?.batchInfo?.batchId}
              </Typography>
            </Box>
          )
        )}
      </Box>
      {subItems && (
        <Box>
          <Box style={{ cursor: "pointer" }} margin="4px 0">
            {collapseSubItems ? (
              <Box onClick={() => setCollapseSubItems(!collapseSubItems)} display="flex">
                <ChevronRightIcon /> {tl("billing.showPackageItems")}
              </Box>
            ) : (
              <Box onClick={() => setCollapseSubItems(!collapseSubItems)} display="flex">
                <ExpandMoreIcon /> {tl("billing.collapsePackageItems")}
              </Box>
            )}
          </Box>
          {!collapseSubItems &&
            [...subItems].map((subItem) => (
              <BillProductSubItem
                key={subItem.productId}
                products={products}
                subItem={subItem}
                updateBillItem={updateBillItem}
                billItem={billItem}
                subItems={subItems}
                disable={disable}
                packageId={billItem.productId}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default connect(null, (dispatch: IThunkDispatch) => ({
  loadStockProducts: (query) => dispatch(getStockProducts(query))
}))(BIllProductItem);

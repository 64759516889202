import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEstimates, getEstimatesTemplate, postEstimatesTemplate } from "../api/estimates";
import { Estimate, EstimatesData } from "../containers/Client/Estimates/interfaces";

const fetchEstimateTemplate = createAsyncThunk("estimates/getTemplate", async () => {
  const response = await getEstimatesTemplate();
  return response;
});

const createEstimateTemplate = createAsyncThunk(
  "estimates/createTemplate",
  async (data: EstimatesData) => {
    const response = await postEstimatesTemplate(data);
    return response;
  }
);

const fetchEstimatesByClient = createAsyncThunk(
  "estimates/getEstimates",
  async (clientId: number) => {
    const response = await getEstimates(clientId);
    return response;
  }
);

interface State {
  template: Estimate;
  collection: { results: Estimate[]; total: number | null };
}

const initialState: State = {
  template: {} as Estimate,
  collection: { results: [], total: null }
};

const estimatesSlice = createSlice({
  name: "estimates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEstimateTemplate.fulfilled, (draft, { payload }) => {
      draft.template = payload;
    });
    builder.addCase(fetchEstimatesByClient.fulfilled, (draft, { payload }) => {
      draft.collection = payload;
    });
  }
});

export default estimatesSlice.reducer;

export { fetchEstimateTemplate, fetchEstimatesByClient, createEstimateTemplate };

import * as React from "react";
import { Box, Checkbox, Button, Typography } from "@mui/material";
import { tl } from "../../components/translate";

interface FooterInterface {
  editable: boolean;
  saveFn: () => void;
  saveDisabled: boolean;
  goBack: () => void;
  createNewAfter: boolean;
  createNewAfterToggler: () => void;
  isAccount?: boolean;
  disableCreateNewAfter?: boolean;
  importing?: boolean;
  saveText?: string;
}

const Footer: React.FC<FooterInterface> = ({
  editable,
  saveFn,
  saveDisabled,
  goBack,
  disableCreateNewAfter = false,
  createNewAfter,
  createNewAfterToggler,
  isAccount,
  importing = false,
  saveText = tl("save")
}) => (
  <Box display="flex" width="100%" justifyContent="space-between" mr="32px">
    <Box display="flex" alignItems="center">
      {editable && !isAccount && !disableCreateNewAfter && (
        <>
          <Checkbox
            checked={createNewAfter}
            data-testmation="footerCheckbox"
            onChange={createNewAfterToggler}
          />
          <Typography component="div">{tl("services.createNewAfterSave")}</Typography>
        </>
      )}
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Button
        onClick={() => goBack()}
        data-testmation="footerCancel"
        style={{ marginRight: "32px" }}
      >
        <Typography>{tl("cancel")}</Typography>
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          await saveFn();
        }}
        data-testmation="footerSaveUpdate"
        disabled={saveDisabled}
      >
        {importing ? (
          <Typography>Make Public</Typography>
        ) : (
          <Typography>{editable ? saveText : tl("update")}</Typography>
        )}
      </Button>
    </Box>
  </Box>
);

Footer.defaultProps = {
  isAccount: false,
  importing: false,
  disableCreateNewAfter: false
};

export default Footer;

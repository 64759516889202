import { Box, Typography } from "@mui/material";
import React from "react";
import { tl } from "../../components/translate";
import CreateBooking from "./CreateBooking";
import ManageBooking from "./ManageBooking";

enum Header {
  CREATE_BOOKING = "createBooking",
  MANAGE_BOOKING = "manageBooking"
}

type HeaderTypes = Header.CREATE_BOOKING | Header.MANAGE_BOOKING;

export default function Bookings(): JSX.Element {
  const [selectedHeader, setSelectedHeader] = React.useState<HeaderTypes>(Header.CREATE_BOOKING);

  return (
    <>
      <Box display="flex" m={2}>
        {Object.values(Header).map((item) => (
          <Box
            sx={{ cursor: "pointer" }}
            mr="15px"
            key={item}
            onClick={() => setSelectedHeader(item)}
          >
            <Typography fontSize="1.2rem" color={`${selectedHeader === item ? "" : "gray"}`}>
              {tl(`booking.${item}`)}
            </Typography>
          </Box>
        ))}
      </Box>
      {selectedHeader === Header.CREATE_BOOKING ? <CreateBooking /> : <ManageBooking />}
    </>
  );
}

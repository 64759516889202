import { Box, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import * as moment from "moment";
import queryString from "query-string";
import React, { useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Assessment } from "app/interfaces/AssessmentInterfaces";
import { capitalize } from "lodash";
import logo from "../../../../assets/images/poweredByOkhati.png";
import { getClientById } from "../../../actions/client";
import * as calendarFns from "../../../components/Calendar/functions/calendarFunctions";
import { t, tl } from "../../../components/translate";
import usePrintShortcut from "../../../hooks/usePrintShortcut";
import usePrintStyles from "../../../hooks/usePrintStyles";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { RootState } from "../../../store";
import { getLocalDateInAD, scaleFont } from "../../Billing/PrintBill/BillPrintHelpers";
import { CustomEl } from "../../Lab/LabEntry/LabTestSubRow";
import DoctorLetterHead from "../../Lab/LabPrint/LabprintComponents/DoctorLetterHead";
import InfoField from "../../Lab/LabPrint/LabprintComponents/LabPrintInfofield";
import LabPrintLetterHead from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import LabPrintLetterHeadCentered from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import AdvancedIntraOralContent, {
  hasContentAdvancedIntraOral
} from "../../Modals/Assessment/AdvancedIntraOralContent";
import {
  formatAssessmentForPrint,
  getFollowupFormat
} from "../../Modals/Assessment/AssessmentContent";
import ExtraOralContent from "../../Modals/Assessment/ExtraOralContent";
import EyeAssessmentContent from "../../Modals/Assessment/EyeAssessmentContent";
import IntraoralAssessmentContent from "../../Modals/Assessment/IntraoralAssessmentContent";
import {
  getMedicineMultiplier,
  showFrequencyUnit,
  showMeal,
  showTimeUnit
} from "../Medication/Medication";
import { countableTypes } from "../Medication/medicineType";
import "./index.css";
import PrintRow from "./PrintRow";
import {
  EditableTableView,
  filterEmptyTableData
} from "../../../components/EditableTable/EditableTable";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { Client } from "../../../interfaces/ClientInterface";
import { getCustomerNumber } from "../../Client/ClientList";
import FooterQR from "../../AssessmentQR/FooterQR";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";
import { Allergy } from "../../../interfaces/Allergy";
import DiagnosisRenderer, { hasDiagnosis } from "../../Modals/Assessment/DiagnosisRenderer";
import { SORT_ORDER } from "../AssessmentForm/OPDComponents";
import { getAssessmentBaseUrl } from "../../../helpers/urls";
import { getFullName } from "../../../helpers/formatters";
import { clientFullNameSelector } from "../../../reducers/client";
import { showDOBAndGender } from "../../Billing/PrintBill/EightyMmPrint/ClientInfo";

enum VITALS_SHORT_NAME {
  "RR" = "RR",
  "BMI" = "BMI",
  "BSA(m2)" = "BSA(m2)",
  "OFC" = "OFC",
  "WFH" = "WFH",
  "SPO2" = "SPO2",
  "Bruit" = "Bruit",
  "pulse" = "pulse",
  "Scalp" = "Scalp",
  "Spine" = "Spine",
  "height" = "height",
  "Oedema" = "Oedema",
  "others" = "others",
  "Pallor" = "Pallor",
  "weight" = "weight",
  "Ascites" = "Ascites",
  "Bodyfat" = "Bodyfat",
  "Icterus" = "Icterus",
  "Clubbing" = "Clubbing",
  "Cyanosis" = "Cyanosis",
  "Jaundice" = "Jaundice",
  "Lymph node" = "Lymph node",
  "saturation" = "saturation",
  "Dysmpophism" = "Dysmpophism",
  "respiration" = "respiration",
  "temperature" = "temperature",
  "glucoseLevel" = "glucoseLevel",
  "bloodPressure" = "BP",
  "headCircumference" = "headCircumference"
}
interface AssessmentPrintProps {
  buttonText: string;
  assessment: Assessment;
  resourceCentre: ResourceCentre;
  client: Client;
  clientAllergies: Allergy[];
  isRiskInfoVisible: boolean;
}

const AssessmentPrintComponents = ({
  componentName,
  assessment,
  assessmentLabels
}): JSX.Element | null => {
  const vitalToPrint = formatAssessmentForPrint(assessment.vitals);
  const questionnaireToPrint = formatAssessmentForPrint(assessment.questionnaire);
  const servicesAndTests = [...(assessment?.tests || []), ...(assessment?.services || [])];
  const tabularComponentData =
    assessment.tabularComponent && filterEmptyTableData(assessment.tabularComponent);
  const productMedication = assessment.productMedication ? assessment.productMedication : [];
  switch (componentName) {
    case SORT_ORDER.CHIEF_COMPLAINTS:
      return assessment.symptoms?.length > 0 && Array.isArray(assessment.symptoms) ? (
        <PrintRow label={assessmentLabels.chiefComplaints}>
          {assessment.symptoms.map((data) => (
            <p key={data.symptom}>{data.symptom}</p>
          ))}
        </PrintRow>
      ) : null;
    case SORT_ORDER.PAST_HISTORY_OF_ALLERGY:
      return assessment.pastHistoryOfAllergy ? (
        <PrintRow label={assessmentLabels.pastHistoryOfAllergy}>
          {assessment.pastHistoryOfAllergy}
        </PrintRow>
      ) : null;
    case SORT_ORDER.HOPI:
      return assessment.hopi?.length > 0 ? (
        <PrintRow label={assessmentLabels.hopi}>
          <span style={{ whiteSpace: "pre-line" }}>{assessment.hopi}</span>
        </PrintRow>
      ) : null;
    case SORT_ORDER.EXAMINATION_OR_STATUS:
      return (
        <>
          {assessment.assessment?.length > 0 && (
            <PrintRow label={assessmentLabels.examination}>
              <span style={{ whiteSpace: "pre-line" }}>{assessment.assessment.trim()}</span>
            </PrintRow>
          )}
          {assessment.assessmentImage && (
            <PrintRow label={tl("assessment.image")}>
              <img
                src={assessment.assessmentImage}
                alt="AssessmentImage"
                className="assessment-image"
              />
            </PrintRow>
          )}
        </>
      );
    case SORT_ORDER.INVESTIGATION:
      return assessment.investigation ? (
        <PrintRow label={assessmentLabels.investigation}>
          <span style={{ whiteSpace: "pre-line" }}>{assessment.investigation}</span>
        </PrintRow>
      ) : null;
    case SORT_ORDER.INTRA_ORAL:
      return assessment.intraOral &&
        (Object.keys(assessment.intraOral.primary).length !== 0 ||
          Object.keys(assessment.intraOral.permanent).length !== 0) ? (
        <PrintRow label={assessmentLabels.intraoral}>
          <IntraoralAssessmentContent intraOralDoc={assessment.intraOral} />
        </PrintRow>
      ) : null;
    case SORT_ORDER.EXTRA_ORAL:
      return Object.keys(assessment.extraOral || {}).some((key) => !!assessment.extraOral[key]) ? (
        <PrintRow label={assessmentLabels.extraOral} forceBreakPage>
          <ExtraOralContent assessmentDocument={assessment} mode="print" />
        </PrintRow>
      ) : null;
    case SORT_ORDER.ADVANCED_INTRA_ORAL:
      return assessment.advancedIntraOral &&
        hasContentAdvancedIntraOral(assessment.advancedIntraOral) ? (
        <PrintRow label={assessmentLabels.advancedIntraOral} forceBreakPage>
          <AdvancedIntraOralContent assessmentDocument={assessment} mode="print" />
        </PrintRow>
      ) : null;
    case SORT_ORDER.EYE:
      return (assessment?.eye && Object.keys(assessment?.eye).length) > 0 ? (
        <PrintRow label={assessmentLabels.eye}>
          <EyeAssessmentContent eyeExaminationDoc={assessment?.eye} />
        </PrintRow>
      ) : null;
    case SORT_ORDER.QUESTIONNAIRE:
      return questionnaireToPrint.length > 0 ? (
        <PrintRow label={assessmentLabels.questionnaire} forceBreakPage>
          <Box width="100%">
            {questionnaireToPrint.map((questionnaire, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} display="flex" flexWrap="wrap">
                <Box display="flex" width="33%" gap={1}>
                  <Typography fontWeight={600}>{`${startCase(questionnaire.name1)} :`}</Typography>
                  <Typography>{questionnaire.value1}</Typography>
                </Box>
                {questionnaire.name2 && (
                  <Box display="flex" width="33%" gap={1}>
                    <Typography fontWeight={600}>
                      {`${startCase(questionnaire.name2)} :`}
                    </Typography>
                    <Typography>{questionnaire.value2}</Typography>
                  </Box>
                )}
                {questionnaire.name3 && (
                  <Box display="flex" width="33%" gap={1}>
                    <Typography fontWeight={600}>
                      {`${startCase(questionnaire.name3)} :`}
                    </Typography>
                    <Typography>{questionnaire.value3}</Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </PrintRow>
      ) : null;
    case SORT_ORDER.VITALS:
      return vitalToPrint.length > 0 ? (
        <PrintRow label={assessmentLabels.vitals}>
          {vitalToPrint.map((vital, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} display="flex" flexWrap="wrap">
              <Box display="flex" width="33%" gap={0.5}>
                <Typography fontWeight={600}>
                  {`${startCase(VITALS_SHORT_NAME[vital.name1])} :`}
                </Typography>
                <Typography>{vital.value1}</Typography>
              </Box>
              {vital.name2 && (
                <Box display="flex" width="33%" gap={0.5}>
                  <Typography fontWeight={600}>
                    {`${startCase(VITALS_SHORT_NAME[vital.name2])} :`}
                  </Typography>
                  <Typography>{vital.value2}</Typography>
                </Box>
              )}
              {vital.name3 && (
                <Box display="flex" width="33%" gap={0.5}>
                  <Typography fontWeight={600}>
                    {`${startCase(VITALS_SHORT_NAME[vital.name3])} :`}
                  </Typography>
                  <Typography>{vital.value3}</Typography>
                </Box>
              )}
            </Box>
          ))}
        </PrintRow>
      ) : null;
    case SORT_ORDER.PROVISIONAL_DIAGNOSIS:
      return assessment.provisionalDiagnosis?.length > 0 ? (
        <PrintRow label={assessmentLabels.provisionalDiagnosis}>
          {assessment.provisionalDiagnosis}
        </PrintRow>
      ) : null;
    case SORT_ORDER.LAB_TEST:
      return servicesAndTests.length > 0 ? (
        <PrintRow label={assessmentLabels.labTest}>
          {servicesAndTests.map((data, index) => (
            <Typography key={data.id}>{`${index + 1}. ${data.name}`}</Typography>
          ))}
        </PrintRow>
      ) : null;
    case SORT_ORDER.DIAGNOSIS:
      return hasDiagnosis(assessment.diagnosis) ? (
        <PrintRow label={assessmentLabels.diagnosis}>
          <DiagnosisRenderer data={assessment.diagnosis} />
        </PrintRow>
      ) : null;
    case SORT_ORDER.TABULAR:
      return assessment.tabularComponent && !!tabularComponentData ? (
        <EditableTableView tableState={tabularComponentData} />
      ) : null;
    case SORT_ORDER.TREATMENT_PLAN:
      return assessment.treatmentPlan?.length > 0 ? (
        <PrintRow label={assessmentLabels.treatmentPlan}>{assessment.treatmentPlan}</PrintRow>
      ) : null;
    case SORT_ORDER.TREATMENT_GIVEN:
      return assessment.treatmentGiven?.length > 0 ? (
        <PrintRow label={assessmentLabels.treatmentGiven}>{assessment.treatmentGiven}</PrintRow>
      ) : null;
    case SORT_ORDER.MEDICATION:
      return (assessment.medication?.length > 0 || assessment.productMedication?.length > 0) &&
        (Array.isArray(assessment.medication) || Array.isArray(assessment.productMedication)) ? (
        <PrintRow label={tl("assessment.medicationAndSupplements")}>
          {[...assessment.medication, ...productMedication].map((data, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`med-${index}`}>
              <Typography>
                {`${index + 1}. ${data.genericName || ""}${data.genericName && data.brand && "/"} ${
                  data.brand
                }${" "}${data.strength}${" "} ${data.form ? `(${data.form})` : ""}
                  `}
              </Typography>
              <Box pl={2}>
                <Typography>
                  {data.frequency === 0 ? "" : `${data.frequency} ${t("assessment.times")}`}
                  {data.frequency !== 0 && showTimeUnit(data.times)}
                  {` ${data.meal ? showMeal(data.meal) : ""}`}
                  {data.frequency !== 0 &&
                    ` ${
                      countableTypes.includes(data.form)
                        ? data.frequency *
                          getMedicineMultiplier(data.times, data.duration, data.frequencyType)
                        : ""
                    } ${data.form} (${data.duration} ${showFrequencyUnit(
                      data.frequencyType,
                      data.duration
                    )})`}
                </Typography>
              </Box>

              <Box pl={0.5}>
                <Typography>{data.remark && <>Remarks: {data.remark}</>}</Typography>
              </Box>
            </Box>
          ))}
        </PrintRow>
      ) : null;
    case SORT_ORDER.FOLLOWUP:
      return assessment.followup ? (
        <PrintRow label={assessmentLabels.followup}>
          {`Follow up in ${assessment.followup.days} ${getFollowupFormat(assessment.followup)}`}
        </PrintRow>
      ) : null;
    case SORT_ORDER.ADVICE:
      return assessment.advice ? (
        <PrintRow label={assessmentLabels.advice}>
          <span style={{ whiteSpace: "pre-line" }}>{assessment.advice}</span>
        </PrintRow>
      ) : null;
    case SORT_ORDER.OTHERS:
      return assessment.others?.length > 0 ? (
        <PrintRow label={assessmentLabels.others}>{assessment.others}</PrintRow>
      ) : null;
    default:
      return null;
  }
};

interface AssessmentClientInfo {
  client: Client;
  customerNumber: string;
  showDobOnPrint?: boolean;
  hideRegistrationInfo?: boolean;
}

const AssessmentClientInfo = ({
  client,
  customerNumber,
  hideRegistrationInfo,
  showDobOnPrint
}: AssessmentClientInfo): JSX.Element => (
  <>
    <Typography
      sx={{
        textAlign: "left",
        fontSize: scaleFont("0.4cm"),
        fontWeight: 600,
        paddingBottom: "8px",
        textTransform: "uppercase"
      }}
    >
      {clientFullNameSelector(client)}
      {showDOBAndGender(client.gender, client.dob)}
    </Typography>
    {customerNumber && <InfoField label={t("billPrint.customerNumber")} data={customerNumber} />}
    {!hideRegistrationInfo && client.registrationNo && (
      <InfoField label={t("bills.registrationNo")} data={client.registrationNo} />
    )}
    {showDobOnPrint && client?.dob && (
      <InfoField label={t("billPrint.customerDob")} data={getLocalDateInAD(client.dob)} />
    )}
    {(client.address || client.city) && (
      <InfoField
        label={t("address")}
        data={
          <>
            {client.address && `${capitalize(client.address)},`}
            {client.city && ` ${capitalize(client.city)}`}
          </>
        }
      />
    )}
    {client.phone && (
      <InfoField
        label={t("billing.phoneNo")}
        data={client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}
      />
    )}
  </>
);

const AssessmentPrint = ({
  buttonText,
  assessment,
  resourceCentre,
  client,
  clientAllergies,
  isRiskInfoVisible
}: AssessmentPrintProps) => {
  const reactToPrintRef = useRef(null);
  const componentRef = useRef();
  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();
  const showLetterhead = Boolean(
    resourceCentre?.settings.printSettings.assessmentPrintSettings?.includeLetterhead
  );
  const { assessmentRemarks, showAssessmentRemarks } =
    resourceCentre.settings.printSettings.assessmentPrintSettings;

  const clinicNameFontScale: number = resourceCentre?.settings.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const assessmentLabels = useAssessmentLabel();
  const qs = queryString.parse(window.location.search);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const clientId = qs.clientId || assessment?.clientId;
    if (!client && clientId) {
      dispatch(getClientById(Number(clientId)));
    }
  }, [qs.clientId, assessment?.clientId, dispatch]);

  usePrintShortcut(reactToPrintRef);
  const renderBtn = () => (
    <ReactToPrint
      onAfterPrint={() => {
        window.focus();
      }}
      trigger={() => (
        <Typography
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            fontSize: "0.95em",
            textTransform: "none",
            padding: "5px 15px"
          }}
          ref={reactToPrintRef}
        >
          {buttonText || "Print"}
        </Typography>
      )}
      content={() => componentRef.current}
    />
  );

  const getLetterHead = () => {
    if (showLetterhead) {
      if (centralizedHeader) {
        return (
          <LabPrintLetterHeadCentered
            resourceCentre={resourceCentre}
            titleFontScale={clinicNameFontScale}
          />
        );
      }
      return (
        <LabPrintLetterHead resourceCentre={resourceCentre} titleFontScale={clinicNameFontScale} />
      );
    }
    return <div />;
  };

  const getDoctorLetterHead = () => {
    if (showLetterhead)
      return (
        <DoctorLetterHead
          serviceProvider={assessment.assessmentDocument.serviceProvider}
          titleFontScale={clinicNameFontScale}
          isCentered={centralizedHeader}
        />
      );
    return <div />;
  };

  const sortOrder = useSelector(
    (state: RootState) => state.userContext.resourceCentre?.settings?.assessmentSettings?.sortOrder
  );
  return (
    <>
      {renderBtn()}
      <iframe className="ifrm" title="Assessment Print">
        <div ref={componentRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <Box height="4.5cm" />
                </td>
              </tr>
            </tfoot>
            <thead>
              {!showLetterhead && (
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {buttonText === "Print Doctor" ? getDoctorLetterHead() : getLetterHead()}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle, padding: 0 }}>
                    <Box>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "0.5cm",
                          fontWeight: 600,
                          textTransform: "uppercase"
                        }}
                      >
                        {tl("patientReport")}
                      </Typography>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid lightgrey"
                        px="10mm"
                        pb="1mm"
                      >
                        {client && (
                          <>
                            <Box component="div" width="50%">
                              <AssessmentClientInfo
                                client={client}
                                customerNumber={getCustomerNumber(client?.customerNumber)}
                                hideRegistrationInfo
                              />
                            </Box>
                            <Box display="flex" flexDirection="column">
                              {client.registrationNo && (
                                <InfoField
                                  label={t("assessment.registrationNumber")}
                                  data={client.registrationNo}
                                />
                              )}
                              {client.ipdNo && <InfoField label={t("ipdNo")} data={client.ipdNo} />}
                              <InfoField
                                label="Created On"
                                data={
                                  calendarFns.convertADtoBS(moment(assessment.createdDate))
                                    .formatted2
                                }
                              />
                              {assessment?.client?.ssfId && (
                                <InfoField label="SSF Id" data={assessment.client.ssfId} />
                              )}
                              {assessment.claimCode && (
                                <InfoField label="Claim Code" data={assessment.claimCode} />
                              )}
                              {assessment?.referToSp && (
                                <InfoField
                                  label="Refer To"
                                  data={getFullName(assessment.referToSp)}
                                />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box px="10mm">
                      {isRiskInfoVisible && !!clientAllergies.length && (
                        <Box display="flex" gap={2} flexWrap="wrap">
                          <Typography>Risk Information:</Typography>
                          <Box>
                            {clientAllergies.map((item) => (
                              <Box key={item.id}>
                                <Typography fontWeight={600}>{item.name}</Typography>
                                {item.information && <Typography>{item.information}</Typography>}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                      {assessment && (
                        <Box mt="4mm">
                          {sortOrder.map((componentName) => (
                            <AssessmentPrintComponents
                              key={componentName}
                              componentName={componentName}
                              assessment={assessment}
                              assessmentLabels={assessmentLabels}
                            />
                          ))}
                          {assessment.violence && (
                            <PrintRow label={tl("assessment.violence")}>There is violence</PrintRow>
                          )}
                          {assessment.surgicalProcedure && (
                            <PrintRow label={tl("assessment.surgicalProcedure")}>
                              {assessment.surgicalProcedure}
                            </PrintRow>
                          )}
                        </Box>
                      )}
                    </Box>

                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      px="10mm"
                    >
                      {assessment.assessmentDocument && (
                        <Box>
                          {assessment.assessmentDocument.serviceProvider?.signature && (
                            <img
                              src={assessment.assessmentDocument.serviceProvider?.signature}
                              alt="Service Provider Signature"
                              style={{ justifyContent: "flex-start", textAlign: "left" }}
                              height="80"
                              width="160"
                            />
                          )}

                          <Typography
                            fontWeight="600"
                            fontSize="0.775em"
                            paddingTop="0.2cm"
                            textAlign="left"
                            borderTop="1px solid lightgrey"
                          >
                            {spFullNameSelector(assessment.assessmentDocument.serviceProvider)}
                          </Typography>

                          {assessment.assessmentDocument.serviceProvider?.designation ? (
                            <Typography fontSize="0.770em" style={{ whiteSpace: "pre-wrap" }}>
                              {assessment.assessmentDocument.serviceProvider?.designation}
                            </Typography>
                          ) : (
                            <>
                              <Typography fontSize="0.770em">
                                {assessment.assessmentDocument.serviceProvider?.qualification}
                              </Typography>

                              <Typography fontSize="0.770em">
                                {assessment.assessmentDocument.serviceProvider
                                  ?.registrationNumber &&
                                  assessment.assessmentDocument.serviceProvider?.registrationNumber}
                              </Typography>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                    {showAssessmentRemarks && (
                      <Box px="10mm">
                        <Typography style={{ marginTop: "18px", padding: "0.2cm" }}>
                          <CustomEl data={assessmentRemarks} />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>

          <Box component="div" width="100%" className="billFooterPos">
            <Box
              paddingTop="0.5rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                {assessment.assessmentUUID && (
                  <FooterQR url={`${getAssessmentBaseUrl()}/${assessment.assessmentUUID}`} />
                )}

                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          </Box>
        </div>
      </iframe>
    </>
  );
};

export default connect((state: RootState, { clientId }: { clientId: number }) => ({
  userContext: state.userContext,
  resourceCentre:
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre,
  client: state.clients.collection?.find(({ id }) => Number(clientId) === id)
}))(AssessmentPrint);

import { Typography, Box } from "@mui/material";
import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { PieChartFormat } from ".";

export const CustomTooltip = ({
  active,
  payload
}: {
  active: boolean;
  payload: Array<Record<string, unknown>>;
}): JSX.Element => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <Box
          style={{
            width: "auto",
            height: "30px",
            padding: "2px 0px 2px 2px",
            borderRadius: "5px",
            color: payload[0].name === "Female" ? "#f20f66" : "#1079b2",
            backgroundColor: "white",
            fontSize: "12px",
            fontWeight: "14px"
          }}
        >
          <Typography>{`${payload[0].name} : ${payload[0].value}`}</Typography>
        </Box>
      </div>
    );
  }

  return <></>;
};

const StatByGender = ({ genderCount }: { genderCount: PieChartFormat[] }): JSX.Element => {
  const COLORS = ["#1079b2", "#f20f66"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer aspect={1}>
      <div
        style={{
          fontFamily: "Poppins, sans-serif",
          fontSize: "12px"
        }}
      >
        <Typography marginBottom="30px">
          <b>Stats By Gender Group</b>
          <br />
          <span>Showing the data from 2022-02-01</span>
          <hr />
        </Typography>
        <PieChart width={400} height={350}>
          <Pie
            data={genderCount}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
          >
            {genderCount.map((entry, index) => (
              <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend iconType="circle" iconSize={15} />
        </PieChart>
      </div>
    </ResponsiveContainer>
  );
};

export default StatByGender;

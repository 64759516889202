export interface PublicLabInterface {
  id: number;
  name: string;
  publicDescription: string;
  type: string;
  category: string;
}

export interface PublicService {
  grossTotalPrice: string;
  id: number;
  name: string;
  productisableId: number;
  resourceCentreId: 1;
  resourceCentre: {
    about: string;
    address: string;
    city: string;
    govRegNo: number | string;
    id: number;
    location: number[];
    name: string;
    phone: string;
    primaryColor: string;
    slogan: string;
  };
}

export interface FilterProps {
  referenceId: number | null;
  rcIds: number[];
  size: number;
  page: number;
}

export interface PublicServiceInfo {
  hasMore: boolean;
  results: PublicLabInterface[];
}

export interface PublicLabDetailsProps {
  publicServices: PublicService[];
  labDetails: string;
}

export interface PublicEvent {
  active: boolean;
  daysInWeek: number[];
  duration: number;
  id: number;
  resourceCentreId: number;
  schedules: { start: string; end: string }[];
  start: string;
  exceptionDays: number[];
}

export interface PublicSchedule {
  day: number;
  schedules: string[];
}

export enum PaymentMethods {
  CASH_ON_DELIVERY = "cashOnDelivery"
}

export type PaymentMethodsType = PaymentMethods.CASH_ON_DELIVERY;

export interface ResourceCentreLocation {
  address: string;
  city: string;
  name: string;
  rcIds: number[];
}

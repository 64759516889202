import { useQuery } from "react-query";
import moment from "moment";
import { getBalanceSheetReport } from "../../../../../api/accountReports";
import {
  BalanceSheetInterface,
  FiltersInterface
} from "../../../../../interfaces/AccountReportsInterface";
import { queryKeys } from "../../../../../react-query/constants";

export default function useBalanceSheet(filters: FiltersInterface): BalanceSheetInterface[] {
  const { periodDate, showPreviousYearPeriod } = filters;

  const previousYear = showPreviousYearPeriod
    ? moment(periodDate).subtract(1, "year").toISOString()
    : "";
  const queryParams = `date=${periodDate.toISOString()}&previousYearDate=${previousYear}`;

  const { data = [] } = useQuery(
    [queryKeys.account, "balanceSheetReport", { periodDate, showPreviousYearPeriod }],
    () => getBalanceSheetReport(queryParams),
    {
      refetchOnMount: true
    }
  );
  return data;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { push } from "connected-react-router";
import queryString from "query-string";
import { Dispatch } from "redux";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/user";
import { saveAuthData } from "../auth/authentication";
import * as NotificationActions from "./notification";
import { navigateRemoveModal } from "./navigation";
import { tl } from "../components/translate";
import { resourceCentreEmployeeActions, serviceProviderActions, resourceCentreActions } from ".";
import sessionStorage from "../helpers/sessionStorage";
import { loginAsAdminImpersonater } from "./authentication";
import mixpanelAnalytics from "../mixpanel-analytics/mixpanelAnalytics";
import { updateTwoFactorInfo } from "../slices/twoFactorInfoSlice";

export enum Type {
  GET_ACCOUNT = "GET_ACCOUNT",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  UPDATE_USER = "UPDATE_USER",
  GET_BY_REFERENCEID = "GET_BY_REFERENCEID",
  VERIFY_TOKEN = "VERIFY_TOKEN",
  CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
  RESET_PASSWORD = "RESET_PASSWORD",
  UPDATE_USER_RC = "UPDATE_USER_RC"
}

const impersonatingRCId = () => queryString.parse(window.location.search).impersonateResourceCentre;

export const adminImpersonating = (): unknown => !!impersonatingRCId();

export const isImpersonationSessionOn = (): unknown => sessionStorage.getItem("user");

export const getAccount: any = () => async (dispatch: Dispatch) => {
  await dispatch(
    actionCreatorAsync(Type.GET_ACCOUNT, async () => {
      if (adminImpersonating() && !isImpersonationSessionOn()) {
        await loginAsAdminImpersonater(impersonatingRCId());
      }
      const response = await api.getAccount();

      // Indentify user for analytics.
      mixpanelAnalytics.identify(String(response?.user.id));

      if (response?.user?.resourceCentre?.id) {
        dispatch(resourceCentreActions.getResourceCentre(response.user.resourceCentre.id));
      }
      if (response.policies?.terminated) {
        dispatch(push("/termination"));
      }
      if (response?.userCreds?.userGroups[0] === "resourceCentreReadOnlyEmployee") {
        dispatch(push("/lab/labRecords?status=Ordered"));
      } else if (response?.userCreds?.userGroups[0] === "resourceCentreReportOnlyAccessEmployee") {
        dispatch(push("/reports"));
      }
      return response;
    })
  );
};

export const setPermission =
  (entityId: number, entityName: string, permissionGroupName: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    await api.updatePermission(entityId, entityName, permissionGroupName);
    if (entityName === "serviceProvider") {
      dispatch(serviceProviderActions.getResourceCentreServiceProvider(entityId));
    } else if (entityName === "employee") {
      dispatch(resourceCentreEmployeeActions.getResourceCentreEmployee(entityId));
    }
  };

export const updatePassword =
  (passwords: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.CHANGE_PASSWORD, async () => {
        try {
          const response = await api.updatePassword(passwords);
          if (response.required2FAToken) {
            dispatch(updateTwoFactorInfo(response));
            return;
          }
          saveAuthData(response);
          await dispatch(getAccount());
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("main.passwordChanged"),
              autoTimeout: true
            })
          );

          dispatch(navigateRemoveModal("ChangePassword"));
        } catch (error) {
          if (error && error.data && Array.isArray(error.data)) {
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: error.data.map((er) => {
                  if (er.msg.toString().startsWith("password_length")) {
                    return tl("passwordLengthInvalid");
                  }
                  return er.msg;
                }),
                autoTimeout: true
              })
            );
          }
        }
      })
    );
  };

export const updateUserId =
  (referenceId: string, attrs: Record<string, unknown>) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_USER, async () => api.updateUserId(referenceId, attrs))
    );
    window.location.href = `${window.location.origin}/messenger/${attrs.userId}`;
  };

export const getByUserReferenceId =
  (referenceId: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.GET_BY_REFERENCEID, () => api.getByUserReferenceId(referenceId))
    );
  };

export const verifyUserAccessToken =
  (token: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.VERIFY_TOKEN, async () => {
        const response = await api.verifyUserAccessToken(token);
        if (response) {
          saveAuthData(response);
          if (!token.user.facebookSpId) {
            window.location.href = `${window.location.origin}/messenger/${token.userId}`;
          }
        }
        return response;
      })
    );
  };

export const profilePicUpload =
  (userId: string, pic: unknown) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.CHANGE_PROFILE_PICTURE, async () => api.uploadProfilePic(userId, pic))
    );
  };

export const updateUser =
  (userObj: unknown) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(actionCreatorAsync(Type.UPDATE_ACCOUNT, () => api.userUpdate(userObj)));
  };

export const resetPasswordFor =
  (userId: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.RESET_PASSWORD, async () => api.resetPasswordFor(userId))
    );
  };

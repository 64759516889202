import * as React from "react";
import { connect } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import * as serviceActions from "../../actions/services";
import { servicesSortedSelector } from "../../reducers/services";
import { tl, t } from "../../components/translate";

const optionsForSelect = (services = [], filter?) => {
  if (filter) {
    services = filter
      .map((id) => services.find((service) => service.id === id))
      .filter((s) => Boolean(s));
  }
  return services.map((service) => ({ label: service.name, value: service.id }));
};
export const ServiceProviderAutoSelect = ({
  services,
  values = [],
  disabled = false,
  actions,
  onChange,
  relatedFilters
}) => {
  const [stateServices, setStateServices] = React.useState(services);
  React.useEffect(() => {
    if (!services || !services.length) actions.loadServices();
  }, []);

  React.useEffect(() => {
    if (relatedFilters && (relatedFilters.serviceType || relatedFilters.productCategory)) {
      let filteredServices = [];
      if (relatedFilters.serviceType) {
        filteredServices = services.filter(
          (service) => service.productType === relatedFilters.serviceType
        );
      }
      if (relatedFilters.productCategory) {
        filteredServices = (relatedFilters.serviceType ? filteredServices : services).filter(
          (service) => service.category === relatedFilters.productCategory
        );
      }
      setStateServices(filteredServices);
    } else {
      setStateServices(services);
    }
  }, [services, relatedFilters]);

  if (!stateServices) return null;
  return (
    <>
      <Autocomplete
        multiple
        id="service-select"
        options={optionsForSelect(stateServices)}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.label + option.value}>
            {option.label}
          </li>
        )}
        value={optionsForSelect(services, values)}
        disabled={disabled}
        onChange={(e, values) => {
          onChange(
            values.map(({ value }) => value),
            values
          );
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="standard"
            label={tl("reports.services")}
            placeholder={t("chooseMore")}
            margin="dense"
            fullWidth
          />
        )}
      />
    </>
  );
};

export default connect(
  (state) => ({
    services: servicesSortedSelector(state)
  }),
  (dispatch) => ({
    actions: {
      loadServices: () => dispatch(serviceActions.getServices())
    }
  })
)(ServiceProviderAutoSelect);

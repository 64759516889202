import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@mui/material";
import { tl } from "../../../components/translate";
import styles from "../Lab.module.css";
import * as calendarFns from "../../../components/Calendar/functions/calendarFunctions";
import { clientFullNameSelector } from "../../../reducers/client";
import { ageFormatter, getDisplayAgeFromDOB } from "../../../helpers/formatters";
import ImageUploader from "../../../components/ImageUploader/ImageUploader";
import { uploadClientImage } from "../../../actions/client";
import { ServiceProviderRate } from "../../../interfaces/ProductInterface";
import { LabClient, LabCustomer } from "../../../interfaces/Lab";
import { IThunkDispatch, RootState } from "../../../store";

interface LabEntryHeaderInformationInterface {
  client: LabClient;
  serviceProvider: ServiceProviderRate;
  orderedOn: string;
  testId: number;
  printMode: boolean;
  customer: LabCustomer;
  uploadPhoto: (id: number, image: File) => void;
  allowUpload: boolean;
  referrers?: string;
}

const LabEntryHeaderInformation: React.FC<LabEntryHeaderInformationInterface> = ({
  client,
  serviceProvider,
  orderedOn,
  testId,
  printMode,
  customer,
  uploadPhoto,
  allowUpload,
  referrers = ""
}) => {
  const getClientGender = (clientGender) => {
    if (Number(clientGender) === 1) {
      return "Male";
    }
    if (Number(clientGender) === 2) {
      return "Female";
    }
    return "";
  };

  return (
    <>
      {printMode ? (
        <Box>
          {client && Object.keys(client).length > 0 && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                flexDirection="column"
              >
                <Box width="100%" display="flex">
                  <Typography style={{ width: "100px" }}>{tl("lab.client")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {clientFullNameSelector(client)} {client.dob && ", "}
                    </Box>
                  </Typography>
                  <Typography style={{ paddingLeft: "16px" }}>
                    {`${client.dob ? `${ageFormatter(client.dob)} old` : ""}  ${getClientGender(
                      client.gender
                    )}`}
                  </Typography>
                </Box>
                {testId && (
                  <Box width="100%" display="flex">
                    <Typography style={{ width: "100px" }}>{tl("lab.testId")}</Typography>
                    <Typography>{testId}</Typography>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box className={styles.labEntryHeaderInformation}>
          {client && Object.keys(client).length > 0 && (
            <Box display="flex" flexDirection="row">
              <Box>
                <Box width="100%">
                  <Box width="450px" display="flex">
                    <Typography style={{ width: "100px" }}>{tl("lab.client")}</Typography>
                    <Typography>
                      <Box component="span" fontWeight="600">
                        {clientFullNameSelector(client)}
                        {client.dob && `, ${getDisplayAgeFromDOB(client.dob)}`}
                        {client.dob && (client.gender === "1" || client.gender === "2") && "/"}
                        {client.gender && getClientGender(client.gender)}
                      </Box>
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography style={{ width: "100px" }}>{tl("lab.phoneNo")}</Typography>
                    <Typography>{client.phone}</Typography>
                  </Box>
                  {client.email && (
                    <Box style={{ display: "flex", width: "100%" }}>
                      <Typography style={{ width: "100px" }}>{tl("email")}</Typography>
                      <Typography>{client.email}</Typography>
                    </Box>
                  )}
                  <Box width="300px" display="flex">
                    <Typography style={{ width: "100px" }}>{tl("lab.testId")}</Typography>
                    <Typography>{testId}</Typography>
                  </Box>
                </Box>
                <Box pt={3}>
                  <Box width="300px" display="flex">
                    <Typography style={{ width: "100px" }}>{tl("lab.orderedBy")}</Typography>
                    <Typography>
                      {serviceProvider
                        ? clientFullNameSelector(serviceProvider)
                        : tl("labTest.orderBy.self")}
                    </Typography>
                  </Box>
                  <Box width="300px" display="flex">
                    <Typography style={{ width: "100px" }}>{tl("lab.orderedOn")}</Typography>
                    <Typography>{calendarFns.bsFullDate(orderedOn)}</Typography>
                  </Box>
                </Box>
                {referrers && (
                  <Box width="300px" display="flex">
                    <Typography style={{ width: "100px" }}>Referred By</Typography>
                    <Typography>{referrers}</Typography>
                  </Box>
                )}
              </Box>
              {allowUpload && (
                <Box>
                  <ImageUploader
                    action={(image) => {
                      uploadPhoto(client.id, image);
                    }}
                    initialImage={customer?.customerDetails?.profileImageS3Url || ""}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

LabEntryHeaderInformation.defaultProps = {
  referrers: ""
};

const mapStateToProps = (state: RootState) => ({
  mode: state.userContext.mode,
  labTechnicians: state.resources.resourceCentreServiceProviders.filter((item) => item.role === "3")
});

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  uploadPhoto: (id, image) => dispatch(uploadClientImage(id, image))
}))(LabEntryHeaderInformation);

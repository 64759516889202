import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography, Link, Chip } from "@mui/material";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { round } from "mathjs";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import * as reportsApi from "../../api/reports";
import { downloadCSV } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import { tl, t } from "../../components/translate";
import styles from "./Reports.module.css";
import useMobileScreen from "../../hooks/useMobileScreen";
import "./Reports.scss";
import Filters from "./Filters";
import BillShow from "../Billing/BillShow/BillShow";
import { notificationAdd } from "../../actions/notification";
import { DocumentTypes } from "../../interfaces/BillInterfaces";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import Can from "../Policy/Can";
import { getCustomerNumber } from "../Client/ClientList";

const or = (val) => val || "";

const docColumns = () => [
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.services"),
  t("reports.billNumber"),
  t("reports.billingDateBS"),
  t("reports.billingDateAD"),
  t("reports.paymentMethod"),
  t("reports.paidAmount"),
  t("reports.remarks"),
  t("reports.creditNoteFor"),
  t("reports.referrer")
];

const docRowProcessor = ({
  customerNumber,
  clientFirstName,
  clientLastName,
  clientName,
  services,
  receiptInfo,
  receivedOn,
  paidAmount,
  paymentMethod,
  remarks,
  referrers,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: any = {}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    or(getCustomerNumber(customerNumber)),
    or(clientName),
    or(clientFirstName),
    or(clientLastName),
    services,
    receiptInfo.billNumber,
    calFns.bsShortDate(receivedOn),
    calFns.adShortDate(receivedOn),
    paymentMethod || "",
    paidAmount,
    or(remarks),
    or(receiptInfo.type === DocumentTypes.CREDITNOTE && receiptInfo.related?.billNumber),
    referrers
  ];
};

const selectData = (item) => ({
  clientName: `${capitalize(item.clientFirstName)} ${capitalize(item.clientLastName)}`,
  clientId: item.clientId,
  customerNumber: item.customerNumber,
  clientFirstName: item.clientFirstName,
  clientLastName: item.clientLastName,
  active: item.active,
  services: item.services,
  receiptInfo: {
    billNumber: item.billNumber,
    billId: item.billId,
    receiptId: item.receiptId,
    related: item.related,
    type: item.type
  },
  issueDate: new Date(item.issueDate),
  receivedOn: new Date(item.receivedOn),
  paidAmount: item.paidAmount,
  paymentMethod: item.paymentMethod,
  remarks: item.remarks,
  referrers: item.referrers
});

const processData = (data) => data.map((item) => selectData(item));
const ReceiptReport = ({ actions }) => {
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    clientIds: [],
    referrersName: [],
    billType: null
  });
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    reportsApi.billPaymentRecords(filters).then((data) => {
      const grouppedData = data.sort((a, b) =>
        new Date(a.receivedOn) > new Date(b.receivedOn) ? -1 : 1
      );
      setReportData(processData(grouppedData));
    });
  }, [filters]);
  const applyFilters = (filtersParam) => {
    setFilters(filtersParam);
  };

  const [selectedBill, setSelectedBill] = React.useState(null);

  const isMobileScreen = useMobileScreen();
  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const handleViewClose = () => {
    setSelectedClientId(null);
    setClientInfoPanel(false);
  };
  const [clientEditMode, setClientEditMode] = React.useState(false);

  return (
    <Can policyAccessKey="report:billPaymentReport">
      <Box marginTop="32px">
        <Box margin="0px 32px">
          <Box marginTop="32px">
            <Filters
              filters={filters}
              onSetFilters={(filtersParam) => applyFilters(filtersParam)}
            />
          </Box>
        </Box>
        <Box
          width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
          style={{ overflowX: isMobileScreen ? "scroll" : "auto" }}
          mx="auto"
        >
          <Box
            className={["receiptReportList", styles.billPaymentListStyles].join(" ")}
            marginTop="32px"
            width={isMobileScreen ? "960px" : "auto"}
          >
            <List
              automation="salesReportList"
              data={reportData}
              rowHeight={50}
              defaultSortColumn="receivedOnBS"
              defaultSortOrder={-1}
              activeRow={0}
              adjustHeightToContents
              columns={[
                {
                  key: "referrers",
                  label: "Referrer",
                  segmentable: true,
                  sortable: true,
                  segmentBy: (row) => row.referrers,
                  formatter: ({ referrers }) => <Typography>{referrers}</Typography>
                },
                {
                  key: "clientName",
                  label: tl("reports.client"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.clientName).toLowerCase() > or(b.clientName).toLowerCase() ? 1 : -1,
                  formatter: ({
                    clientFirstName,
                    clientLastName,
                    clientId,
                    customerNumber,
                    active
                  }) => (
                    <Typography
                      component="div"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      <Box
                        style={{
                          textDecoration: `${active ? "underline" : ""}`,
                          cursor: "pointer",
                          width: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                        onClick={() => {
                          if (active) {
                            setSelectedClientId(clientId);
                            setClientInfoPanel(true);
                          }
                        }}
                      >
                        {`${clientFirstName} ${clientLastName}
                        ${active ? "" : "(deleted)"}`}
                      </Box>
                      <Box fontSize="small" color="gray">
                        {getCustomerNumber(customerNumber)}
                      </Box>
                    </Typography>
                  )
                },
                {
                  key: "services",
                  label: tl("reports.services"),
                  formatter: ({ services }) => {
                    const title = (services || []).length ? `- ${services.join("\n- ")}` : "";
                    return (
                      <Box title={title} justifyContent="center" alignItems="center">
                        {services.map(
                          (service, index) =>
                            index < 2 &&
                            service.length > 0 && (
                              <Chip
                                size="small"
                                label={service}
                                key={`${service}_${service.id}`}
                                style={{
                                  maxWidth: services.length > 1 ? "140px" : "100%"
                                }}
                              />
                            )
                        )}
                        {services.length > 2 && "..."}
                      </Box>
                    );
                  }
                },
                {
                  key: "receiptInfo",
                  label: tl("reports.billNumber"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.receiptInfo.billNumber).toLowerCase() >
                    or(b.receiptInfo.billNumber).toLowerCase()
                      ? 1
                      : -1,
                  formatter: ({ receiptInfo }) => (
                    <Box flex={1} flexDirection="column" alignItems="center">
                      <Link onClick={() => setSelectedBill(receiptInfo.billId)}>
                        {receiptInfo.billNumber}
                      </Link>
                      {receiptInfo.type === DocumentTypes.CREDITNOTE && receiptInfo.related && (
                        <Box fontSize="small" color="gray">
                          {tl("bills.creditNoteFor")} {receiptInfo.related.billNumber}
                        </Box>
                      )}
                    </Box>
                  )
                },
                {
                  key: "receivedOnBS",
                  label: tl("reports.billingDateBS"),
                  formatter: ({ receivedOn }) => receivedOn && calFns.bsFullDate(receivedOn),
                  sortable: true,
                  sortFunction: (a, b) => (new Date(a.receivedOn) > new Date(b.receivedOn) ? 1 : -1)
                },
                {
                  key: "receivedOnAD",
                  label: tl("reports.billingDateAD"),
                  formatter: ({ receivedOn }) =>
                    receivedOn && moment(receivedOn).format("MMMM DD, YYYY"),
                  sortable: true,
                  sortFunction: (a, b) => (new Date(a.receivedOn) > new Date(b.receivedOn) ? 1 : -1)
                },
                {
                  key: "paymentMethod",
                  label: tl("reports.paymentMethod"),
                  formatter: ({ paymentMethod }) => paymentMethod
                },
                {
                  key: "paidAmount",
                  label: tl("reports.paidAmount"),
                  formatter: ({ paidAmount }) => (paidAmount ? rupeeDisplay(paidAmount) : "")
                }
              ]}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight={500}
                      >
                        {acc.segment || "N/A"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box flexBasis="200px" pt="4px">
                    {rupeeDisplay(
                      round(
                        acc.rows.reduce(
                          (totalAmount, cur) => totalAmount + Number(cur.paidAmount),
                          0
                        ) || 0,
                        2
                      )
                    )}
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={() =>
                        downloadCSV(
                          t("reports.billPaymentRecordsReport", "en"),
                          getProcessedData(),
                          docColumns(),
                          (row) => docRowProcessor(row)
                        )
                      }
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
          </Box>
        </Box>
        <Box className="totalBar">
          <Box display="flex" paddingLeft="20px">
            {t("reports.totalReceipt")}
          </Box>
          <Box display="flex" flexBasis="192px" paddingRight="26px" justifyContent="flex-end">
            <Typography>
              {rupeeDisplay(
                Number.parseFloat(
                  reportData.reduce((acc, cur) => acc + Number(cur.paidAmount), 0)
                ).toFixed(2) || 0
              )}
            </Typography>
          </Box>
        </Box>
        {selectedBill && (
          <BillShow
            billId={selectedBill}
            navigateTo={actions.navigateTo}
            handleViewClose={() => setSelectedBill(null)}
            hideActions
          />
        )}
        {showClientInfoPanel &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClientId}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClientId}
              handleViewClose={handleViewClose}
              stayOnCurrentPage
              setEditMode={setClientEditMode}
            />
          ))}
      </Box>
    </Can>
  );
};

export default connect(null, (dispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    addNotification: (statusCode) => {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: statusCode === 200 ? "success" : "error",
          message:
            statusCode === 200 ? tl("reports.downloadSuccess") : tl("reports.downloadFailure"),
          autoTimeout: true
        })
      );
    }
  }
}))(ReceiptReport);

import * as React from 'react';
import Box from '@mui/material/Box';

const RowLines = () => {
  return (<Box position="absolute" height="2400px" width={'100%'}>
    {
      Array.from(Array(24)).map((i, idxHour) => {
        return (
          <Box key={idxHour} style={{ boxSizing: 'border-box' }} borderTop="1px solid lightgrey" height="100px" width={'100%'}>

          </Box>
        )
      })

    }

  </Box>);
};

export default RowLines;
import React from "react";
import { Booking } from "../../interfaces/BookingInterfaces";
import { Entity } from "./Filters";
import { ReminderInterface } from "../../interfaces/ReminderInterface";
import PatientFlowDetailView from "./PatientFlowDetailView";
import { LabTest } from "../../interfaces/LabInterfaces";
import AssessmentInfoPanel from "../Assessment/AssessmentInfoPanel";
import { AssessmentDocument } from "../../interfaces/AssessmentInterfaces";

interface Props {
  data: ReminderInterface & { booking: Booking; labTest: LabTest; assessment: AssessmentDocument };
  onClose: () => void;
  selectedEntity: Entity;
}

export default function ReminderDetailView({
  data,
  onClose,
  selectedEntity
}: Props): JSX.Element | null {
  if (!data) return null;
  const { booking, assessment } = data;
  if (selectedEntity === Entity.BOOKING) {
    return (
      <PatientFlowDetailView data={{ ...booking, clientId: booking.client.id }} onClose={onClose} />
    );
  }
  if (selectedEntity === Entity.ASSESSMENT) {
    return <AssessmentInfoPanel onClose={onClose} assessment={assessment} disableCarryForward />;
  }
  return null;
}

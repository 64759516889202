import * as React from 'react';
const __html = require('./TermsandConditions.html');
const template = { __html: __html };

export default () => {
  return (
    <div
      style={{ width: 'calc(100% - 50px)', margin: 'auto' }}
      dangerouslySetInnerHTML={template}
    />
  );
};

import React from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MultipleHeader } from "../../../components/List";
import { ipdMultipleHeaders } from "../IpdList";
import { fetchActiveIpds, fetchBeds, fetchWards } from "../../../slices/bedManagementSlice";
import { RootState } from "../../../store";
import Beds from "./Beds";
import BedStats from "./BedStats";
import Header from "../../DashboardV2/Header";
import Can from "../../Policy/Can";
import CreateIpdAdmission from "../IpdAdmission/CreateIpdAdmission";
import { MODE } from "../../Assessment/AssessmentForm/Form";
import { t } from "../../../components/translate";

const BedManagement = (): JSX.Element => {
  const beds = useSelector((state: RootState) => state.bedManagement?.beds);
  const wards = useSelector((state: RootState) => state.bedManagement?.wards);
  const activeIpds = useSelector((state: RootState) => state.bedManagement?.activeIPDs);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [isCreateIpdPanelOpen, setIsCreateIpdPanelOpen] = React.useState(false);

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  React.useEffect(() => {
    if (!beds.length) {
      dispatch(fetchBeds());
    }
  }, [beds.length, dispatch]);

  React.useEffect(() => {
    if (!wards.length) {
      dispatch(fetchWards());
    }
  }, [wards.length, dispatch]);

  React.useEffect(() => {
    dispatch(fetchActiveIpds());
  }, [dispatch]);
  return (
    <>
      <Box px={2.5} pt={2} display="flex" justifyContent="space-between" alignItems="flex-start">
        <MultipleHeader multipleHeaders={ipdMultipleHeaders("/beds")} />
        <Can policyAccessKey="ipd:createIpd">
          <Button variant="contained" onClick={() => setIsCreateIpdPanelOpen(true)}>
            {t("createIPD")}
          </Button>
        </Can>
      </Box>
      <Box pl={3} mt={2}>
        <Header selectedDate={selectedDate} onDateChange={onDateChange} showButtonGroup={false} />
      </Box>
      <Box p={2} mt={2}>
        <BedStats beds={beds} activeIpds={activeIpds} selectedDate={selectedDate} />
        <Beds beds={beds} wards={wards} activeIpds={activeIpds} />
      </Box>
      {isCreateIpdPanelOpen && (
        <CreateIpdAdmission
          mode={MODE.CREATE}
          onCancel={() => setIsCreateIpdPanelOpen(false)}
          stayOnPage={false}
        />
      )}
    </>
  );
};

export default BedManagement;

import React from "react";
import { push } from "connected-react-router";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import List from "../../../components/List";
import { BillStatus, BillType } from "../../../interfaces/BillInterfaces";
import { bsFullDate } from "../../../components/Calendar/functions/calendarFunctions";
import { getFullName } from "../../../helpers/formatters";

const AssessmentBills = ({ bills }: { bills: Array<BillType> }): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <List
      data={bills}
      rowHeight={50}
      isResponsive
      hideCreateButton
      withoutSearch
      columns={[
        {
          key: "client",
          label: "Patient Name",
          sortable: true,
          formatter: ({ client }) => <Typography>{getFullName(client)}</Typography>
        },
        {
          key: "services",
          label: "Services",
          sortable: true,
          formatter: ({ document }) => (
            <Typography>
              {document.billItems.map(({ description }) => description).join(", ")}
            </Typography>
          )
        },
        {
          key: "billStatus",
          label: "Bill Status",
          formatter: () => <Typography>{BillStatus.draft}</Typography>
        },
        {
          key: "createdDate",
          label: "Created Date",
          formatter: ({ created_at: createdAt }) => <Typography>{bsFullDate(createdAt)}</Typography>
        }
      ]}
      onRowClick={(row) => dispatch(push(`/billing/bills/${row.id}`))}
    />
  );
};

export default AssessmentBills;

import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import Barcode from "react-barcode";
import startCase from "lodash/startCase";
import { QRCodeSVG } from "qrcode.react";
import {
  DefaultLetterHead,
  InfoField,
  scaleFont,
  CenteredLetterHead
} from "../../components/Print/Print";
import { rangeSwitchAccToGender, getGender } from "./LabPrint/LabPrintFunctions";
import usePrintStyles from "../../hooks/usePrintStyles";
import logo from "../../../assets/images/poweredByOkhati.png";
import { RootState } from "../../store";
import hasOwnProperty from "../../helpers/object";
import { LabTest } from "../../interfaces/Lab";
import { getTestReportBaseUrl } from "../../helpers/urls";
import CustomHeader from "./LabPrint/CustomHeader";

interface SubRowProps {
  lt: LabTest;
  children?: React.ReactNode;
  gender: "1" | "2";
  showRanges?: boolean;
  isChild?: boolean;
  isLast?: boolean;
}

export const SubRow = ({
  lt,
  children = <></>,
  isChild = false,
  showRanges = true,
  isLast = false,
  gender
}: SubRowProps): JSX.Element => {
  const clientGender = getGender(lt, gender);
  return (
    <>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box
          padding="0cm 0.1cm"
          border="1px solid black"
          borderTop={isChild ? "1px solid grey" : "1px solid black"}
          borderBottom={isLast ? "1px solid black" : "0px"}
          borderRight="0px"
          width={showRanges ? "50%" : "75%"}
        >
          <Typography
            style={{
              fontSize: scaleFont("0.33cm", 0.8),
              paddingLeft: isChild ? "0.2cm" : "1px"
            }}
          >
            {lt.name}
          </Typography>
        </Box>
        <Box
          padding="0cm 0.1cm"
          border="1px solid black"
          borderTop={isChild ? "1px solid grey" : "1px solid black"}
          borderBottom={isLast ? "1px solid black" : "0px"}
          width="25%"
          borderRight={showRanges ? "0px" : "1px solid black"}
        >
          <Typography
            style={{
              fontSize: scaleFont("0.33cm", 0.8)
            }}
          >
            {hasOwnProperty(lt, "formData") && lt.formData.reading}
          </Typography>
        </Box>
        {showRanges && (
          <Box
            padding="0cm 0.1cm"
            border="1px solid black"
            borderTop={isChild ? "1px solid grey" : "1px solid black"}
            borderBottom={isLast ? "1px solid black" : "0px"}
            width="25%"
            display="flex"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: scaleFont("0.33cm", 0.8)
              }}
            >
              {!(lt.subTests?.length > 0) && hasOwnProperty(lt, "ranges") && (
                <>{rangeSwitchAccToGender(Number(clientGender), lt.ranges)}</>
              )}
            </Typography>
            <Typography
              style={{
                fontSize: scaleFont("0.32cm", 0.8)
              }}
            >
              {lt.unit && lt.unit}
            </Typography>
          </Box>
        )}
      </Box>
      {children}
    </>
  );
};

SubRow.defaultProps = {
  children: <></>,
  showRanges: true,
  isChild: false,
  isLast: false
};

const CustomizedInfoField = ({ label, data, smallWidth = false, valueStyle = {} }) => (
  <Box>
    {data && (
      <InfoField
        label={label}
        data={data}
        labelStyle={{ width: smallWidth ? "88px" : "140px", fontSize: scaleFont("0.28cm", 1) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1), ...valueStyle }}
      />
    )}
  </Box>
);

const RenderNamedBlockTestGroup = ({ blockName, testGroupData, gender }) => (
  <Box width="100%" mt="0.10cm">
    <Box
      style={{
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: "0.28cm"
      }}
      mb="0.06cm"
    >
      {blockName}
    </Box>
    <Box>
      {testGroupData.map((lt, index, array) => {
        const isLastGroup = index === array.length - 1;
        const isLast = isLastGroup && lt.subTests?.length === 0;
        return (
          <SubRow key={lt.id} lt={lt} isLast={isLast} gender={gender}>
            {lt.subTests?.map((sT, i, arr) => (
              <>
                <SubRow
                  key={sT.id}
                  lt={sT}
                  isChild
                  isLast={isLastGroup && i === arr.length - 1}
                  gender={gender}
                />
              </>
            ))}
          </SubRow>
        );
      })}
    </Box>
  </Box>
);

const getFormattedDateInAD = (date: string): string => `${moment(date).format("YYYY-MM-DD")}`;

const AuthorizedSpInfo = ({ sp }) => (
  <Box mt="0.2cm">
    <Box height="1.1cm" mb="0.2cm">
      {sp?.signature && <img src={sp.signature} height="40px" width="80px" alt="sp-signature" />}
    </Box>
    <Typography
      style={{
        fontSize: "0.28cm",
        textAlign: "left",
        fontWeight: 600
      }}
    >
      {startCase(sp.title)} {startCase(sp.name)}
    </Typography>
  </Box>
);

export const MedicalPrintView = ({
  resourceCentre,
  labRecord,
  showBarCode,
  showQrCode,
  centralizedHeader,
  assessment,
  forwardRef,
  showReferrer
}: Record<string, unknown>): JSX.Element => {
  const { authorizedSP1, authorizedSP2 } = labRecord?.results?.approvedBy || {};
  const recentAssessment = assessment?.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )?.[0];
  const { client } = labRecord;
  const profileImage = labRecord.customer.customerDetails?.profileImageS3Url || null;

  const clientName = `${capitalize(client.firstName)} ${capitalize(client.lastName)}`;

  const includeLetterhead = resourceCentre?.settings?.medicalSettings?.includeLetterhead;
  const customHeaderImage = resourceCentre?.settings?.medicalSettings?.customHeaderImage;

  const getClientGender = (clientGender) => {
    if (clientGender === "1") {
      return "m";
    }
    if (clientGender === "2") {
      return "f";
    }
    return "-";
  };

  const gender = getClientGender(client.gender);

  let greeting = "Sir/Madam";

  if (gender === "m") {
    greeting = "Sir";
  }
  if (gender === "f") {
    greeting = "Madam";
  }

  const showVital = (vital) => {
    if (vital) return `${vital.reading} ${vital.unit && vital.unit}`;
    return "-";
  };

  const clientInfoDiv = (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      border="1px solid black"
      p="0.2cm"
      minHeight="2.5cm"
    >
      <Box style={{ maxWidth: "6cm" }}>
        <CustomizedInfoField label="S.N." data={labRecord.labIdentifier || ""} smallWidth />
        <InfoField
          label="Name:"
          data={clientName}
          labelStyle={{ width: "2.32cm", fontSize: scaleFont("0.28cm", 1), paddingTop: "0.1cm" }}
          valueStyle={{
            fontSize: scaleFont("0.34cm", 1),
            fontWeight: "700",
            lineHeight: "1",
            padding: "0.13cm 0"
          }}
        />
        <CustomizedInfoField label="Marital status:" data={client.maritalStatus} smallWidth />
        <CustomizedInfoField label="Address:" data={client.address} smallWidth />
        {showBarCode && (
          <div>
            <CustomizedInfoField label="Test Id:" data={labRecord.id} smallWidth />
            <Barcode
              value={labRecord.id}
              width={2}
              displayValue={false}
              fontSize={5}
              height={20}
              margin={2}
              font="arial"
            />
          </div>
        )}
      </Box>
      <Box>
        <CustomizedInfoField
          label="Age:"
          data={client.dob ? moment().diff(client.dob, "years") : ""}
          smallWidth
        />
        <CustomizedInfoField
          label="Passport no:"
          data={client.passportInfo && client.passportInfo.passportNumber}
          smallWidth
        />
        <CustomizedInfoField label="Nationality:" data={client.nationality} smallWidth />
        <CustomizedInfoField
          label="Applied to:"
          data={client.appliedCountry}
          smallWidth
          valueStyle={{ fontSize: scaleFont("0.34cm", 1), fontWeight: "700" }}
        />
        <CustomizedInfoField
          label="Exam date:"
          data={getFormattedDateInAD(labRecord?.results?.examDate || new Date().toISOString())}
          smallWidth
        />
      </Box>
      <Box>
        <CustomizedInfoField label="Sex:" data={gender} />
        <CustomizedInfoField
          label="Passport issue place:"
          data={client.passportInfo?.issuingAuthority || ""}
        />
        <CustomizedInfoField
          label="Passport issue date:"
          data={moment(client.passportInfo?.issueDate).format("YYYY-MM-DD") || ""}
        />
        <CustomizedInfoField
          label="Passport expiration date:"
          data={moment(client.passportInfo?.expiryDate).format("YYYY-MM-DD") || ""}
        />
        {showReferrer && (
          <CustomizedInfoField label="Recruiting agency:" data={labRecord.referrers || ""} />
        )}
      </Box>
    </Box>
  );

  const clientIllnessesDiv = (
    <>
      {recentAssessment && (
        <Box width="100%" mt="0.42cm">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 900,
              fontSize: "0.40cm",
              marginTop: "-0.2cm"
            }}
          >
            {recentAssessment.presentHealthStatus && (
              <Box component="span" marginLeft="2.5cm" p="0 0.2cm" border="0.5px solid black">
                {recentAssessment.presentHealthStatus.toUpperCase()}
              </Box>
            )}
          </Typography>
          <Typography
            style={{
              fontSize: scaleFont("0.28cm"),
              fontWeight: 600,
              marginTop: `${recentAssessment.presentHealthStatus ? "-0.4cm" : "10px"}`
            }}
          >
            General Examination
          </Typography>
          <Box>
            <Typography style={{ fontSize: "0.28cm", fontWeight: 600, width: "115%" }}>
              Past history of serious illness, major surgery and significant psychological problem
              including (Epilepsy and Depression):{" "}
              <span style={{ fontWeight: 400 }}>
                {recentAssessment.hopi && recentAssessment.hopi}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography style={{ fontSize: "0.28cm", fontWeight: 600 }}>
              Past history of allergy :{" "}
              <span style={{ fontWeight: 400 }}>
                {recentAssessment.pastHistoryOfAllergy && recentAssessment.pastHistoryOfAllergy}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );

  const labTests = (labRecord.results?.data.length && labRecord.results.data[0]?.labTests) || [];

  const getRequiredCategoryTests = (cName) => labTests.filter((item) => item.category === cName);

  const [
    systemeticGroup,
    hematologyGroup,
    bioChemGroup,
    urineGroup,
    serologyGroup,
    radiologyGroup,
    specialGroup,
    others
  ] = [
    "Systemic",
    "Haematology",
    "Biochemistry",
    "Urine Examination",
    "Serology",
    "Radiology",
    "Special",
    "Others"
  ].map((item) => getRequiredCategoryTests(item));
  const clinicalImpression = specialGroup.filter((item) => item.name === "Clinical impression");
  radiologyGroup.push(clinicalImpression[0]);

  const vitalsObj: any = {};

  (labRecord?.results?.vitals || recentAssessment?.vitals)?.forEach((vital) => {
    vitalsObj[vital.name.replace(/\s/g, "").toLowerCase()] = vital;
  });

  if (!recentAssessment || !labRecord) return null;

  const {
    height,
    bloodpressure,
    clubbing,
    weight,
    jaundice,
    oedema,
    pulse,
    pallor,
    ascites,
    temperature,
    cyanosis,
    lymphnode
  } = vitalsObj;

  const clientVitalsDiv = (
    <Box display="flex" justifyContent="space-between" border="1px solid black" p="0.1cm">
      <Box>
        <CustomizedInfoField label="Height" data={showVital(height)} smallWidth />
        <CustomizedInfoField label="BP" data={showVital(bloodpressure)} smallWidth />
        <CustomizedInfoField label="Clubbing" data={showVital(clubbing)} smallWidth />
      </Box>
      <Box>
        <CustomizedInfoField label="Weight" data={showVital(weight)} smallWidth />
        <CustomizedInfoField label="Jaundice" data={showVital(jaundice)} smallWidth />
        <CustomizedInfoField label="Oedema" data={showVital(oedema)} smallWidth />
      </Box>
      <Box>
        <CustomizedInfoField label="Pulse" data={showVital(pulse)} smallWidth />
        <CustomizedInfoField label="Pallor" data={showVital(pallor)} smallWidth />
        <CustomizedInfoField label="Ascities" data={showVital(ascites)} smallWidth />
      </Box>
      <Box>
        <CustomizedInfoField label="Temperature" data={showVital(temperature)} smallWidth />
        <CustomizedInfoField label="Cyanosis" data={showVital(cyanosis)} smallWidth />
        <CustomizedInfoField label="Lymphnode" data={showVital(lymphnode)} smallWidth />
      </Box>
    </Box>
  );
  const mainLabDataDisplay = (
    <Box display="flex" width="100%" mt="0.07cm">
      <Box width="50%">
        <Typography style={{ fontWeight: 600, fontSize: scaleFont("0.28cm") }}>
          Systemic Examination
        </Typography>
        <Box display="flex">
          <Typography style={{ width: "75%", fontWeight: 700, fontSize: "0.28cm" }}>
            Examination
          </Typography>
          <Typography style={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
            Reading
          </Typography>
        </Box>
        <Box mt="0.04cm" mr="4px">
          {systemeticGroup.map((lt, index, array) => {
            const isLastGroup = index === array.length - 1;
            const isLast = isLastGroup && lt.subTests?.length === 0;
            return (
              <SubRow key={lt.id} lt={lt} showRanges={false} isLast={isLast} gender={client.gender}>
                {lt.subTests?.map((sT, i, arr) => (
                  <Box key={sT.id}>
                    <SubRow
                      lt={sT}
                      isChild
                      showRanges={false}
                      isLast={isLastGroup && i === arr.length - 1}
                      gender={client.gender}
                    />
                  </Box>
                ))}
              </SubRow>
            );
          })}
        </Box>
        <Typography style={{ fontWeight: 600, fontSize: "0.28cm", marginTop: "0.21cm" }}>
          RADIOLOGY
        </Typography>
        <Box mt="0.06cm" mr="4px">
          {radiologyGroup.map((lt, index, array) => (
            <SubRow
              key={lt.id}
              lt={lt}
              showRanges={false}
              isLast={index === array.length - 1}
              gender={client.gender}
            >
              {lt.subTests?.map((sT, i, arr) => (
                <SubRow
                  key={sT.id}
                  lt={sT}
                  isChild
                  showRanges={false}
                  isLast={i === arr.length - 1}
                  gender={client.gender}
                />
              ))}
            </SubRow>
          ))}
        </Box>
        <Box mt="0.75cm">
          <Typography style={{ fontSize: "0.3cm" }}>Dear {greeting},</Typography>
          <Typography style={{ fontSize: "0.3cm", width: "92%" }}>
            The above mentioned medical report of {clientName} is{" "}
            <b>{recentAssessment.presentHealthStatus}</b> for the mentioned job.
          </Typography>
        </Box>
        {showQrCode && (
          <QRCodeSVG size={80} value={`${getTestReportBaseUrl()}/${labRecord.uuid}`} />
        )}
      </Box>
      <Box width="50%">
        <Typography style={{ fontWeight: 600, fontSize: scaleFont("0.28cm") }}>
          Laboratory examination
        </Typography>
        <Box width="100%" display="flex">
          <Box width="100%" display="flex">
            <Typography style={{ width: "50%", fontWeight: 700, fontSize: "0.28cm" }}>
              Examination
            </Typography>
            <Typography style={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
              Reading
            </Typography>
            <Typography style={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
              Range
            </Typography>
          </Box>
        </Box>
        <RenderNamedBlockTestGroup
          blockName="Hematology"
          testGroupData={hematologyGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Biochemistry"
          testGroupData={bioChemGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Serology"
          testGroupData={serologyGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Urine"
          testGroupData={urineGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Others"
          testGroupData={others}
          gender={client.gender}
        />
      </Box>
    </Box>
  );

  return (
    <div ref={forwardRef}>
      <Box component="table" width="100%">
        {!includeLetterhead && <Box style={{ height: "2.7cm" }} />}
        <tbody>
          <tr>
            <td>
              {includeLetterhead && (
                <div>
                  {customHeaderImage ? (
                    <CustomHeader imageUrl={customHeaderImage} />
                  ) : (
                    <>
                      {centralizedHeader ? (
                        <CenteredLetterHead resourceCentre={resourceCentre} />
                      ) : (
                        <DefaultLetterHead resourceCentre={resourceCentre} />
                      )}
                    </>
                  )}
                </div>
              )}
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "0.35cm",
                  marginTop: "0.2cm",
                  marginBottom: "0.1cm",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  whiteSpace: "nowrap"
                }}
              >
                {resourceCentre?.settings?.medicalSettings?.customPrintTitle || ""}
              </Typography>
              <Box display="flex" width="100%" justifyContent="space-between" pt="0.1cm">
                <Box flexGrow={1} pr="8px">
                  {clientInfoDiv}
                  {clientIllnessesDiv}
                </Box>

                {profileImage ? (
                  <Box width="3cm" display="flex" justifyContent="flex-end">
                    <img
                      src={profileImage}
                      style={{
                        height: "3cm",
                        width: "2.5cm",
                        objectFit: "cover"
                      }}
                      alt="profileImage"
                    />
                  </Box>
                ) : (
                  <Box
                    height="3cm"
                    width="2.5cm"
                    border="1px solid lightgrey"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography style={{ fontSize: "0.2cm", textAlign: "center" }}>
                      Photo
                      <br />
                      2.5*3cm
                    </Typography>
                  </Box>
                )}
              </Box>
              {clientVitalsDiv}
              <Typography style={{ display: "flex", justifyContent: "center", fontWeight: 600 }}>
                {client.presentHealthStatus}
              </Typography>
              {mainLabDataDisplay}
              <Box display="flex">
                <Box mt="1.5cm" width="25%">
                  <Typography
                    style={{
                      fontSize: "0.28cm",
                      fontWeight: 600
                    }}
                  >
                    (Official Stamp)
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" width="65%">
                  {authorizedSP1 && <AuthorizedSpInfo sp={authorizedSP1} />}
                  {authorizedSP2 && <AuthorizedSpInfo sp={authorizedSP2} />}
                </Box>
              </Box>
            </td>
          </tr>
        </tbody>
      </Box>
      <Box
        component="div"
        paddingTop="0.2cm"
        display="flex"
        width="100%"
        className="borderTopBlack1"
        justifyContent="space-between"
      >
        <Typography style={{ fontSize: "0.25cm", marginRight: "0.40cm" }}>
          Recommended by Ministry of Health and Population, Government of Nepal. This report is
          valid for two months from the date of Medical Examination.
        </Typography>
        <Box component="span" marginLeft={2} flexGrow={1} />
        <Box className="FooterPrintOkhatiLogo">
          <img src={logo} alt="logo" height="100%" width="100%" />
        </Box>
      </Box>
    </div>
  );
};

const ForeignEmploymentPrint = ({
  resourceCentre,
  labRecord,
  showBarCode,
  showQrCode,
  showReferrer,
  centralizedHeader,
  buttonText,
  assessment = null
}) => {
  usePrintStyles({ pageStyle: "size: A4; margin: 1mm 10mm" }).runPrintStyles();
  const printRef = useRef();

  return (
    <div style={{ width: "100%" }}>
      <ReactToPrint
        trigger={() => (
          <Typography
            component="span"
            style={{
              width: "100%",
              height: "100%",
              padding: "5px 15px",
              fontSize: "0.95em",
              display: "flex",
              alignItems: "center",
              textTransform: "none"
            }}
          >
            {buttonText || "Print"}
          </Typography>
        )}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printContainer">
        <MedicalPrintView
          forwardRef={printRef}
          resourceCentre={resourceCentre}
          labRecord={labRecord}
          showBarCode={showBarCode}
          showQrCode={showQrCode}
          centralizedHeader={centralizedHeader}
          assessment={assessment}
          showReferrer={showReferrer}
        />
      </iframe>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const rc =
    state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;

  return {
    resourceCentre: rc,
    centralizedHeader: rc?.settings?.printSettings.centralizedHeader,
    showBarCode: rc?.settings?.medicalSettings.showBarCode,
    showQrCode: rc?.settings?.medicalSettings.showQrCode,
    showReferrer: rc?.settings?.medicalSettings.showReferrer
  };
};

export default connect(mapStateToProps, null)(ForeignEmploymentPrint);

import { BillType } from "../interfaces/BillInterfaces";
import { Referrer, ReferrerLedger } from "../interfaces/ReferrerInterface";
import { Delete, Get, Patch, Post } from "./apiHelper";

const referrerRoot = "/api/referrer";

export async function getReferrers(): Promise<Referrer[]> {
  const response = await Get(referrerRoot, true);
  return response?.data as Array<Referrer>;
}

export async function postReferrer(data: Referrer): Promise<Referrer> {
  const response = await Post(referrerRoot, data, true);
  return response.data as Referrer;
}

export async function patchReferrer(data: Referrer, id: number): Promise<Referrer> {
  const response = await Patch(`${referrerRoot}/${id}`, data, true);
  return response.data as Referrer;
}

export async function deleteReferrer(id: number): Promise<number> {
  const response = await Delete(`${referrerRoot}/${id}`, true);
  return response.data as number;
}

export async function getReferrerSalesTransactions(id: number): Promise<Partial<BillType>[]> {
  const response = await Get(`/api/referrers/salesTransactions/${id}`, true);
  return response?.data as Partial<BillType>[];
}

export async function getReferrerLedgers(id: number): Promise<ReferrerLedger[]> {
  const response = await Get(`/api/referrers/ledgers/${id}`, true);
  return response?.data as ReferrerLedger[];
}

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { Box } from "@mui/material";
import { supplierActions } from "../../actions";
import SuppliersList from "../Suppliers/SuppliersList";
import { tl } from "../../components/translate";
import * as NotificationActions from "../../actions/notification";
import { removeGeneralErrorByKey } from "../../actions/error";
import { suppliersSortedSelector } from "../../reducers/suppliers";
import Panel from "../../components/Panel";
import style from "./style.module.css";
import Can from "../Policy/Can";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { Supplier } from "../../interfaces/StockInterfaces";
import { RootState } from "../../store";
import SupplierCreationForm from "./CreateSupplierForm/SupplierForm";

interface SuppliersProps {
  createMode: boolean;
  editMode: boolean;
  suppliers: Supplier[];
  resourceCentre: ResourceCentre;
  onSave: (data) => void;
  goBack: () => void;
  supplierId: number;
}
interface OwnProps {
  resourceCentre: ResourceCentre;
}

const Suppliers: React.FC<SuppliersProps> = ({
  resourceCentre,
  suppliers,
  goBack,
  editMode,
  createMode,
  supplierId,
  onSave
}) => {
  let supplier;
  if (editMode) {
    supplier = suppliers.find((sp) => sp.id === Number(supplierId));
  }

  return (
    <div className={style.listContainer}>
      <Can policyAccessKey="clinic:listSuppliers" superAdminPass>
        <SuppliersList resourceCentreId={resourceCentre.id} navigateToResourceSettingsSp />
      </Can>
      {createMode || editMode ? (
        <Can
          policyAccessKey={["clinic:createSuppliers", "clinic:editSuppliers"]}
          partialCheck
          superAdminPass
        >
          <Panel
            title={createMode ? tl("employee.createSupplier") : tl("employee.updateSupplier")}
            onClose={() => {
              goBack();
            }}
          >
            {(createMode || suppliers.length > 0) && (
              <Box p="30px" width="100%">
                <SupplierCreationForm
                  createMode={createMode}
                  data={supplier}
                  onCancel={() => {
                    goBack();
                  }}
                  onSave={(data) => {
                    onSave({ ...data, resourceCentreId: resourceCentre.id });
                  }}
                />
              </Box>
            )}
          </Panel>
        </Can>
      ) : (
        ""
      )}
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    suppliers: suppliersSortedSelector(state)
  }),
  (dispatch, ownProps: OwnProps) => ({
    actions: bindActionCreators({ ...supplierActions }, dispatch),
    onSave: async (data) => {
      if (data.id) {
        dispatch(supplierActions.putSupplier(data));
        dispatch(push(`/resourcecentres/${ownProps.resourceCentre.id}/supplier`));
      } else {
        try {
          await dispatch(supplierActions.postSupplier(data));
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("successSupplier"),
              autoTimeout: true
            })
          );
          dispatch(supplierActions.getSuppliers());
          dispatch(push(`/resourcecentres/${ownProps.resourceCentre.id}/supplier`));
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: tl("phoneNumberAlreadyExists"),
              autoTimeout: true
            })
          );
          dispatch(removeGeneralErrorByKey("POST_SUPPLIER"));
        }
      }
    },
    navigateTo: (url) => {
      dispatch(push(url));
    },
    goBack: () => dispatch(push(`/resourcecentres/${ownProps.resourceCentre.id}/supplier`))
  })
)(Suppliers);

import { PostLabTestInterface } from "../actions/medicalTest";
import { LabRecord } from "../interfaces/Lab";
import { Get, Post } from "./apiHelper";

const medicalTestRecordEndpointRoot = "/api/medicalLabTests";
const labTestRecordEndpointRoot = "/api/labTestRecord";

export async function getLabTestRecord(
  testId?: number,
  clientId?: number
): Promise<Array<LabRecord>> {
  const filters = { testId, clientId };
  const query = Object.keys(filters || {})
    .filter((key) => filters[key] !== undefined)
    .map((queryParamKey) => `${queryParamKey}=${filters[queryParamKey]}`)
    .join("&");
  const response = await Get(
    `${medicalTestRecordEndpointRoot}${query.length ? `?${query}` : ""}`,
    true
  );
  return response.data as Array<LabRecord>;
}

export async function getMedicalRecordById(id: number): Promise<LabRecord> {
  const res = await Get(`${medicalTestRecordEndpointRoot}/${id}`);
  return res.data as LabRecord;
}

export async function postLabTestRecord(data: PostLabTestInterface): Promise<LabRecord> {
  const response = await Post(
    labTestRecordEndpointRoot,
    { ...data, info: { ...data.info, labTestType: "medicalTest" } },
    true
  );
  return response.data as LabRecord;
}

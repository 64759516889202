import React from "react";

export const BodyLotion = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 357.044 357.044"
    >
      <g>
        <g>
          <path
            d="M219.539,2.013C204.066,0.349,184.962,0,171.66,0c-12.267,0-21.014,0.284-21.38,0.295
			c-4.044,0.133-7.253,3.45-7.253,7.496v29.297c0,3.956,3.064,7.189,6.948,7.472v6.312h-2.924c-3.849,0-7.072,2.914-7.461,6.743
			l-4.38,43.136c-7.59,2.45-14.163,5.636-19.223,9.466c-4.464,2.801-7.928,7.022-9.796,11.944c-1.055,2.555-1.589,5.177-1.589,7.796
			v202.84c0,13.37,10.877,24.247,24.246,24.247h89.478c13.37,0,24.247-10.877,24.247-24.247v-202.84c0-2.618-0.534-5.24-1.589-7.796
			c-1.868-4.922-5.333-9.143-9.797-11.944c-5.06-3.829-11.633-7.016-19.222-9.465l-4.38-43.136
			c-0.389-3.829-3.612-6.743-7.461-6.743h-2.924v-6.284c0,0,2.908-0.251,3.919-0.734c10.643-5.09,35.332-6.587,43.947-6.734
			c4.091-0.07,7.373-3.407,7.373-7.499V18.771C252.441,7.153,236.628,3.851,219.539,2.013z M227.574,129.957v202.84
			c0,5.099-4.148,9.247-9.247,9.247h-89.478c-5.099,0-9.246-4.148-9.246-9.247v-202.84c0-0.669,4.324-19.768,53.985-19.768
			C218.121,110.189,227.574,129.289,227.574,129.957z M173.588,95.189c-7.982,0-15.703,0.652-22.925,1.858l3.165-31.175h39.52
			l3.165,31.175C189.292,95.841,181.571,95.189,173.588,95.189z M182.202,50.872h-17.227v-6.284h17.227V50.872z M237.441,22.398
			c-10.52,0.564-29.419,2.241-41.156,7.19h-38.258V15.119c24.655-2.191,74.52,3.315,79.414,5.746V22.398z"
          />
          <path
            d="M137.708,145.833c-4.142,0-7.5,3.358-7.5,7.5V272.72c0,4.142,3.358,7.5,7.5,7.5h71.762c4.143,0,7.5-3.358,7.5-7.5V153.333
			c0-4.142-3.357-7.5-7.5-7.5H137.708z M201.969,265.22h-56.762V160.833h56.762V265.22z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import React from "react";
import { startCase } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Typography } from "@mui/material";
import Panel from "../../components/Panel";
import UserGroupCreateEdit from "./UserGroupCreateEdit";
import { UserGroupsType } from "../../api/userGroups";

const fields = [
  "name",
  "description",
  "displayName",
  "relatedResourceCentre",
  "userGroupType",
  "statements"
];

export const replaceCommaWithLineBreak = (jsonString: string): string =>
  jsonString.replace(/,/g, ",\n");

const UserGroupDetail = ({
  userGroup,
  onClose
}: {
  userGroup: UserGroupsType;
  onClose: () => void;
}): JSX.Element => {
  const formattedUserGroupData = {
    id: userGroup.id || null,
    name: userGroup.name,
    description: userGroup.description,
    displayName: userGroup.policy?.name,
    relatedResourceCentre: userGroup.resourceCentre?.name || "",
    userGroupType: userGroup?.userGroupType,
    statements: replaceCommaWithLineBreak(JSON.stringify(userGroup?.policy?.statements)),
    resourceCentreId: userGroup?.resourceCentreId || null,
    policy: userGroup?.policy
  };
  const [editing, setEditing] = React.useState(false);
  const editButton = <EditIcon style={{ cursor: "pointer" }} onClick={() => setEditing(true)} />;

  return (
    <Panel
      title={userGroup?.policy?.name || "User Group Detail"}
      onClose={onClose}
      editButton={editButton}
    >
      <Box px={4}>
        {fields.map((field) => (
          <Box
            key={field}
            display="flex"
            flexDirection={field === "statements" ? "column" : "row"}
            sx={{ wordWrap: "break-word" }}
          >
            <Typography
              sx={{
                width: "30%",
                fontWeight: "600"
              }}
            >
              {startCase(field)}:{" "}
            </Typography>
            {field === "statements" ? (
              <pre style={{ wordBreak: "break-all" }}>{formattedUserGroupData[field]}</pre>
            ) : (
              <Typography>{formattedUserGroupData[field]}</Typography>
            )}
          </Box>
        ))}
      </Box>
      {editing && (
        <UserGroupCreateEdit
          onClose={() => setEditing(false)}
          userGroup={formattedUserGroupData}
          createMode={false}
        />
      )}
    </Panel>
  );
};

export default UserGroupDetail;

/* eslint-disable camelcase */
import React from "react";
import { Box, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../../components/OList";
import useMobileScreen from "../../../hooks/useMobileScreen";
import styles from "../Reports.module.css";
import { t, tl } from "../../../components/translate";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import { downloadCSV } from "../../../helpers/files";
import Can from "../../Policy/Can";
import { SummaryReportInterface } from "../../../interfaces/ReportInterface";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";

const docColumns = () => [
  t("reports.date"),
  t("resourceCentre.opdCount"),
  t("resourceCentre.billCount"),
  t("resourceCentre.bookingCount"),
  t("resourceCentre.clientCount"),
  t("resourceCentre.serviceCount"),
  t("resourceCentre.smsCount"),
  t("resourceCentre.labCount"),
  t("resourceCentre.attachmentCount")
];

const superAdminDocColumns = () => [t("resourceCentre.name"), ...docColumns()];

const superAdminDocRowProcessor = ({
  date,
  assessmentCount,
  billCount,
  bookingCount,
  clientCount,
  serviceCount,
  smsCount,
  finalisedLabCount,
  attachmentCount,
  name,
  __meta__row_type
}: {
  date: Date;
  assessmentCount: number;
  billCount: number;
  bookingCount: number;
  clientCount: number;
  serviceCount: number;
  smsCount: number;
  name: string;
  finalisedLabCount: number;
  attachmentCount: number;
  __meta__row_type: string;
}) => {
  if (__meta__row_type === "segment_summary") return null;
  return [
    name,
    calFns.adShortDate(date) || "",
    assessmentCount ?? "",
    billCount ?? "",
    bookingCount ?? "",
    clientCount ?? "",
    serviceCount ?? "",
    smsCount ?? "",
    finalisedLabCount ?? "",
    attachmentCount ?? ""
  ];
};

const docRowProcessor = ({
  date,
  assessmentCount,
  billCount,
  bookingCount,
  clientCount,
  serviceCount,
  smsCount,
  finalisedLabCount,
  attachmentCount,
  __meta__row_type
}: {
  date: Date;
  assessmentCount: number;
  billCount: number;
  bookingCount: number;
  clientCount: number;
  serviceCount: number;
  smsCount: number;
  finalisedLabCount: number;
  attachmentCount: number;
  name: string;
  __meta__row_type: string;
}) => {
  if (__meta__row_type === "segment_summary") return null;
  return [
    calFns.adShortDate(date) || "",
    assessmentCount ?? "",
    billCount ?? "",
    bookingCount ?? "",
    clientCount ?? "",
    serviceCount ?? "",
    smsCount ?? "",
    finalisedLabCount ?? "",
    attachmentCount ?? ""
  ];
};

interface SummaryReportTableProps {
  summaryReportData: SummaryReportInterface[];
  isSuperAdmin: boolean;
  resourceCentres: ResourceCentre[];
  showInSingleRow?: boolean;
}

function sortFunction(a: number, b: number) {
  return a > b ? 1 : -1;
}

const SummaryReportTable = ({
  summaryReportData,
  isSuperAdmin,
  resourceCentres,
  showInSingleRow = false
}: SummaryReportTableProps): JSX.Element => {
  const isMobileScreen = useMobileScreen();

  const totalNumberOfRcs = React.useMemo(() => {
    const uniqueIds = new Set(summaryReportData.map((row) => row.resourceCentreId));
    return uniqueIds.size;
  }, [summaryReportData]);

  const summaryDataWithRcName = React.useMemo(
    () =>
      summaryReportData.map((row) => ({
        ...row,
        name:
          resourceCentres?.find((item) => row.resourceCentreId === item.id)?.name ||
          row.resourceCentreId
      })),
    [summaryReportData, resourceCentres]
  );

  const columns = [
    {
      key: "date",
      label: tl("resourceCentre.date"),
      formatter: ({ date }) => (
        <Typography fontSize="0.75rem">
          {showInSingleRow ? "-" : calFns.bsFullDate(date)}
        </Typography>
      ),
      sortable: true,
      sortFunction: (a: { date: string | number | Date }, b: { date: string | number | Date }) =>
        new Date(b.date) > new Date(a.date) ? 1 : -1
    },
    {
      key: "assessmentCount",
      label: tl("resourceCentre.opdCount"),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.assessmentCount, b.assessmentCount)
    },
    {
      key: "billCount",
      label: tl("resourceCentre.billCount"),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.billCount, b.billCount)
    },
    {
      key: "bookingCount",
      label: tl("resourceCentre.bookingCount"),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.bookingCount, b.bookingCount)
    },
    {
      key: "clientCount",
      label: tl("resourceCentre.clientCount"),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.clientCount, b.clientCount)
    },
    {
      key: "serviceCount",
      label: tl("resourceCentre.serviceCount"),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.serviceCount, b.serviceCount)
    },
    {
      key: "smsCount",
      label: tl("resourceCentre.smsCount"),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.smsCount, b.smsCount)
    },
    {
      key: "finalisedLabCount",
      label: tl("resourceCentre.finalisedLabCount"),
      formatter: ({ finalisedLabCount }) => (
        <Typography fontSize="0.75rem">{finalisedLabCount || 0}</Typography>
      ),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.finalisedLabCount, b.finalisedLabCount)
    },
    {
      key: "attachmentCount",
      label: tl("resourceCentre.attachmentCount"),
      formatter: ({ attachmentCount }) => (
        <Typography fontSize="0.75rem">{attachmentCount || 0}</Typography>
      ),
      sortable: true,
      sortFunction: (a: SummaryReportInterface, b: SummaryReportInterface) =>
        sortFunction(a.attachmentCount, b.attachmentCount)
    }
  ];

  if (isSuperAdmin) {
    columns.unshift({
      key: "name",
      label: tl("resourceCentre.name"),
      formatter: ({ name }) => <Typography fontSize="0.75rem">{name}</Typography>,
      sortable: true,
      segmentable: !showInSingleRow,
      segmentBy: ({ name }) => name
    });
  }

  return (
    <>
      <Can policyAccessKey="report:summaryReport" superAdminPass>
        <Box
          width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
          style={{ overflowX: "auto" }}
          mx="auto"
        >
          <Box
            marginTop="32px"
            className={["summaryReportList", styles.salesSummaryListStyles].join(" ")}
            width={isMobileScreen ? "960px" : "auto"}
          >
            <List<SummaryReportInterface>
              automation="summaryReportList"
              data={summaryDataWithRcName}
              rowHeight={50}
              defaultSortColumn={isSuperAdmin ? "name" : "date"}
              defaultSortOrder={isSuperAdmin ? 1 : -1}
              activeRow={1}
              adjustHeightToContents
              columns={columns}
              segementSummaryRenderer={(acc) => (
                <Box className="okhati-list-row">
                  <Typography fontWeight={500} className="okhati-list-rowcell">
                    {acc.segment}
                  </Typography>
                  <div className="okhati-list-rowcell">-</div>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.assessmentCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.billCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.bookingCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.clientCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.serviceCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.smsCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.finalisedLabCount, 0)}
                  </Typography>
                  <Typography className="okhati-list-rowcell">
                    {acc.rows.reduce((total, current) => total + current.attachmentCount, 0)}
                  </Typography>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={() => {
                        downloadCSV(
                          t("reports.summaryReport", "en"),
                          getProcessedData(),
                          isSuperAdmin ? superAdminDocColumns() : docColumns(),
                          (row) =>
                            isSuperAdmin
                              ? superAdminDocRowProcessor({
                                  ...row,
                                  name:
                                    resourceCentres?.find(
                                      (item) => row.resourceCentreId === item.id
                                    )?.name || row.resourceCentreId
                                })
                              : docRowProcessor(row)
                        );
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <Box className="totalBar" pr="20px">
              <Typography
                sx={{
                  flex: "1 1 400px !important",
                  pl: "35px",
                  ml: "-15px"
                }}
              >
                {tl("reports.total")}
              </Typography>
              {isSuperAdmin && (
                <Typography sx={{ display: "flex", justifyContent: "flex-end", flex: "0 1 180px" }}>
                  {`RCs: ${totalNumberOfRcs}`}
                </Typography>
              )}
              {[
                { key: "assessmentCount", label: t("resourceCentre.opdCount") },
                { key: "billCount", label: t("resourceCentre.billCount") },
                { key: "bookingCount", label: t("resourceCentre.bookingCount") },
                { key: "clientCount", label: t("resourceCentre.clientCount") },
                { key: "serviceCount", label: t("resourceCentre.serviceCount") },
                { key: "smsCount", label: t("resourceCentre.smsCount") },
                { key: "finalisedLabCount", label: t("resourceCentre.labCount") },
                { key: "attachmentCount", label: t("resourceCentre.attachmentCount") }
              ].map((item) => (
                <Typography
                  key={item.key}
                  sx={{
                    "&:nth-last-child(-n+8)": {
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: "0 1 180px"
                    }
                  }}
                >
                  {`${startCase(item.label)}: ${summaryReportData?.reduce(
                    (acc, reportData) => acc + Number(reportData[item.key] || 0),
                    0
                  )}`}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Can>
    </>
  );
};

export default SummaryReportTable;

import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Box, Button, Typography, Tabs, Tab } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import produce from "immer";
import { Test } from "../../../interfaces/Lab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "280px"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "260px"
  },
  paper: { borderRadius: "4px" },
  selectedItemsContainer: {
    marginTop: "8px",
    minHeight: "32px",
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    flexWrap: "wrap"
  },
  filteredTests: {
    height: "32px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between"
  }
}));

export const getLTCategories = (lts: Array<Test>): Array<string> => {
  const categories = [];
  lts.forEach((el) => {
    if (!categories.includes(el.category)) categories.push(el.category);
  });
  return categories;
};

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  tests: Array<Test>;
  handleModifyLabtests: (v: Array<Test>) => void;
  labTests: Array<Test>;
}

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

function VerticalTabs(props) {
  const { labTests, addTempTest, isAlreadySelected, removeTempTest } = props;
  const classes = useStyles();
  const uniqueCategories = getLTCategories(labTests);
  const [selectedTest, setSelectedTest] = useState(uniqueCategories[0]);

  const getSelectedLabTests = () => labTests.filter((item) => item.category === selectedTest);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {uniqueCategories.map((item, i) => (
          // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
          <Tab label={item} key={i} {...a11yProps(i)} onClick={() => setSelectedTest(item)} />
        ))}
      </Tabs>
      <TabPanel style={{ overflowY: "auto", width: "100%" }}>
        {getSelectedLabTests().map((item) => (
          <Box
            key={item.id}
            className={classes.filteredTests}
            onClick={() => {
              if (!isAlreadySelected(item.id)) addTempTest(item);
              else removeTempTest(item.id);
            }}
            style={isAlreadySelected(item.id) ? { color: "lightgrey" } : {}}
          >
            <Typography>{item.name}</Typography>
            {isAlreadySelected(item.id) && (
              <Typography style={{ color: "#292929" }}>UNSELECT</Typography>
            )}
          </Box>
        ))}
      </TabPanel>
    </div>
  );
}

export const LabTestCreateDialog = ({ open, setOpen, ...props }: Props): JSX.Element => {
  const classes = useStyles();
  const { tests, handleModifyLabtests, labTests } = props;

  const [draftTests, setDraftTests] = useState<Array<Test>>([]);

  const addTempTest = (test) => {
    const mutatedTempTests = produce(draftTests, (draft) => {
      draft.push({
        ...test,
        sampleTaken: true,
        sampleInfo: { collectionDate: new Date(), remarks: "" }
      });
    });
    setDraftTests(mutatedTempTests);
  };

  const handleDoneClick = () => {
    setOpen(false);
    handleModifyLabtests(draftTests);
    setDraftTests(tests);
  };

  const handleCancelClick = () => {
    setOpen(false);
    setDraftTests(tests);
  };

  useEffect(() => {
    setDraftTests(tests);
  }, [tests]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="alert-dialog-title" style={{ fontWeight: 600 }}>
        Select Tests/TestGroups
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Typography style={{ paddingLeft: "24px", fontWeight: 600 }}>Selected Items</Typography>
        <Box className={classes.selectedItemsContainer}>
          <Autocomplete
            id="combo-box-demo-2"
            freeSolo
            multiple
            options={labTests}
            value={draftTests}
            onChange={(e, v) => {
              if (v) setDraftTests(v as Test[]);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(params, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...params} key={option.id}>
                {option.name}
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                placeholder="Select labtest"
                margin="dense"
                style={{ marginTop: "12px" }}
              />
            )}
          />
        </Box>
        <Box pt="16px">
          <VerticalTabs
            labTests={labTests}
            addTempTest={addTempTest}
            isAlreadySelected={(testId) => Boolean(draftTests.find((item) => item.id === testId))}
            removeTempTest={(testId) => {
              const result = produce(draftTests, (draft) =>
                draft.filter((item) => item.id !== testId)
              );
              setDraftTests(result);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", border: "1px solid lightgrey" }}>
        <Button onClick={handleCancelClick}>Cancel</Button>
        <Button onClick={handleDoneClick} variant="contained" color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

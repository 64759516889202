import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import * as React from "react";
import { InputValue, Placeholder, PlaceholderInput } from "../../../components/VariableInput";

interface Props {
  onUpdate: (v: InputValue) => void;
  value: InputValue;
  placeholders: Array<Placeholder>;
}

const defaultValue = { rootValue: "", references: [] };

export default function FormulaDialog(props: Props): JSX.Element {
  const { onUpdate, value, placeholders } = props;

  const [openDialog, setOpenDialog] = React.useState(false);
  return (
    <div>
      <Typography
        sx={{ textDecoration: "underline", color: "green", cursor: "pointer" }}
        onClick={() => setOpenDialog(true)}
      >
        {value?.rootValue ? "Edit Formula" : "Formula"}
      </Typography>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Assign formula for this test</DialogTitle>
        <DialogContent>
          <PlaceholderInput
            id={`${Math.floor(Math.random() * 10000)}`}
            inputValue={value || defaultValue}
            rows={8}
            fullWidth
            onUpdate={(v) => {
              onUpdate(v);
            }}
            placeholderRenderer={(onNodeAdd) => (
              <Box mt="8px">
                {placeholders.map((placeholder) => (
                  <Button
                    key={placeholder.reference}
                    variant="outlined"
                    onClick={() => onNodeAdd(placeholder)}
                    type="button"
                  >
                    {placeholder.name}
                  </Button>
                ))}
              </Box>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenDialog(false)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

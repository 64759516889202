import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles.scss";
import moment from "moment";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { EmptyView } from "../../../../components/OList";
import DayBookInfoPanel from "./DayBookInfoPanel";
import { DayBookReport } from "../../../../interfaces/AccountReportsInterface";

const DayBookList = ({ data }: { data: DayBookReport[] }): JSX.Element => {
  const [dayBookInfo, setDayBookInfo] = React.useState(null);
  if (!data) {
    return (
      <EmptyView>
        <Box textAlign="center" padding="50px">
          No items to show.
        </Box>
      </EmptyView>
    );
  }

  return (
    <div>
      {data.map((dayBook) => (
        <Box
          key={dayBook.id}
          className="body-cell"
          onClick={() => {
            setDayBookInfo(dayBook);
          }}
        >
          <Typography className="body-field">
            {convertADtoBS(moment(dayBook.date)).formatted4}
          </Typography>
          <Typography className="body-field">{dayBook.voucherNumber}</Typography>
          <Typography className="body-field flex-1">{dayBook.debitLedgerName}</Typography>
          <Typography className="body-field">{dayBook.entryAmount}</Typography>
          <Typography className="body-field">{dayBook.remarks}</Typography>
        </Box>
      ))}
      {dayBookInfo && (
        <DayBookInfoPanel
          handleClose={() => {
            setDayBookInfo(null);
          }}
          dayBook={dayBookInfo}
        />
      )}
    </div>
  );
};

export default DayBookList;

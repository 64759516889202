import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import {
  applyMiddleware, compose, createStore
} from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AnyAction } from 'typescript-fsa';
import createRootReducer from '../reducers';
import ongoing from '../middleware/ongoing';
import { logger } from '../middleware/logger';

export const history = createBrowserHistory();

function configureStore(preloadedState?) {
  let middleware = applyMiddleware(
    thunk,
    ongoing,
    logger,
    routerMiddleware(history)
  );

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }
  const storeC = createStore(
    createRootReducer(history),
    preloadedState,
    compose(middleware)
  );
  return storeC;
}
export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>

export type IThunkDispatch = ThunkDispatch< RootState, unknown, AnyAction>

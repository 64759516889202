import React from "react";
import { Autocomplete, Box, Button, Checkbox, Chip, Paper, TextField } from "@mui/material";
import useGetDepartments from "../../hooks/department";
import { Department } from "../../interfaces/DepartmentInterface";

interface Props {
  onChange: (value: Department[]) => void;
}

const DepartmentMultiSelect = ({ onChange }: Props): JSX.Element => {
  const departments = useGetDepartments();
  const [value, setValue] = React.useState<Department[]>([]);
  const [selectedValue, setSelectedValue] = React.useState<Department[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Autocomplete
      multiple
      fullWidth
      sx={{ maxWidth: 400 }}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      limitTags={2}
      options={departments}
      disableClearable
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={(_, values, reason) => {
        setValue(values);
        if (reason === "removeOption") {
          setSelectedValue(values);
          onChange(values);
        }
      }}
      renderTags={(_, getTagProps) =>
        selectedValue.map((option, index) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Chip label={option.name} size="small" {...getTagProps({ index })} key={option.id} />
        ))
      }
      PaperComponent={(props) => (
        <Paper onMouseDown={(event) => event.preventDefault()}>
          {props.children}
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onChange(value);
                setSelectedValue(value);
                setIsOpen(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Paper>
      )}
      renderOption={(props, option, { selected }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          <Checkbox checked={selected} />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          label="Departments"
          placeholder="Search and select deparment"
          size="small"
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

export default DepartmentMultiSelect;

import { TextField, Autocomplete } from "@mui/material";

import React from "react";
import { connect } from "react-redux";

function SpecialitySearch({
  onChange,
  value,
  onFocus,
  onBlur,
  helperText,
  errors,
  required,
  options = []
}) {
  const optionsHash = {};

  options.forEach((option) => {
    optionsHash[option.id] = {
      ...option
    };
  });

  return (
    <Autocomplete
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => option.noun || ""}
      onChange={(e, v) => {
        onChange(v ? [v.id] : []);
      }}
      value={optionsHash[value ? value[0] : null] || null}
      onFocus={(e) => {
        onFocus(e);
      }}
      onBlur={(e) => {
        onBlur(e);
      }}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {option.noun || ""}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          label="Select Speciality"
          margin="dense"
          error={errors}
          required={required}
          helperText={helperText}
        />
      )}
    />
  );
}

export default connect(
  (state) => ({ options: state.serviceTags.collection.filter((item) => Boolean(item.noun)) }),
  null
)(SpecialitySearch);

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import unique from "lodash/uniq";
import { produce } from "immer";
import {
  getLabTestGroupsMinimal as getLabTestGroupsMinimalApi,
  getLabTests as getLabTestsApi,
  postTestGroupData as postTestGroupDataApi,
  getLabTestGroupById as getLabTestGroupByIdApi,
  labTestGroupsMasterApi,
  getDefaultLabTestGroupById
} from "../api/labTestSettings";
import { DocumentRecordGroup, DocumentRecordTest, Test } from "../interfaces/Lab";
import { notificationAdd } from "../actions/notification";

export const getLabTestGroups = createAsyncThunk("labTestSettings/getLabTests", async () => {
  const response = await getLabTestGroupsMinimalApi();
  return response;
});

export const getLabTestGroupsMaster = createAsyncThunk(
  "labTestSettings/getLabTestsMaster",
  async () => {
    const response = await labTestGroupsMasterApi();
    return response;
  }
);

export const getLabTests = createAsyncThunk("labTestSettings/labTests", async () => {
  const response = await getLabTestsApi();
  return response;
});

export const getDefaultLabTestGroupForReset = createAsyncThunk(
  "labTestSettings/getDefaultTest",
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await getDefaultLabTestGroupById(id);
      return response;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to get data for default lab test",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const postTestGroup = createAsyncThunk(
  "labTestSettings/postTestGroup",
  async (draftGroup: DocumentRecordGroup) => {
    const response = await postTestGroupDataApi(draftGroup);
    return response;
  }
);

export const getLabTestGroupById = createAsyncThunk(
  "labTestSettings/labTestGroupById",
  async (id: number, thunkAPI) => {
    const response = await getLabTestGroupByIdApi(id);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    thunkAPI.dispatch(setTestGroupDraft(response.documentRecord));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    thunkAPI.dispatch(setInitialState(response.documentRecord));
    return response;
  }
);

interface State {
  labTestGroups: Array<Test>;
  labTests: Array<Test>;
  draftGroup: DocumentRecordGroup;
  checkedUUIDs: Array<string>;
  initState: DocumentRecordGroup; // for tracking if changes have been done from initial state
}

const initialState: State = {
  labTestGroups: [],
  labTests: [],
  draftGroup: null,
  checkedUUIDs: [],
  initState: null
};

// assigns unique id at each level of test to all its containing tests
// unique id is assigned to later find and perform operations on that containing object later
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function assignUniqueIdAtLevel(tests: DocumentRecordGroup["labTests"]) {
  if (!tests) return undefined;
  return tests.map((test) => {
    if (test.subTests) {
      return { ...test, subTests: assignUniqueIdAtLevel(test.subTests), uuid: uuidv4() };
    }
    return { ...test, uuid: uuidv4() };
  });
}

// depth first search to find related uuid and return its containing object
export function findDFS<T extends { uuid: string; subTests?: Array<T> }, K>(
  arrTest: Array<T>,
  id: K,
  key = "uuid"
): T | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const elTest of arrTest) {
    if (elTest[key] === id) {
      return elTest;
    }
    if (elTest.subTests) {
      const found = findDFS(elTest.subTests, id, key);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

// finds and returns the parent node of the node found with matching uuid
function findParentDFS(parentRoot, id) {
  // eslint-disable-next-line no-restricted-syntax
  for (const childEl of parentRoot.labTests || parentRoot.subTests || []) {
    if (childEl.uuid === id) {
      return parentRoot;
    }
    if (childEl.subTests) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const found = findParentDFS(childEl, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

// deletes node and all its children if uuid matches
function deleteDFS(root, uuid, parent?, idx?) {
  if (root.uuid === uuid) {
    if (parent) {
      (parent.labTests || parent.subTests).splice(idx, 1);
      if (!parent.labTests?.length) {
        // eslint-disable-next-line no-param-reassign
        delete parent.labTests;
      }
      if (!parent.subTests?.length) {
        // eslint-disable-next-line no-param-reassign
        delete parent.subTests;
      }
    } else return null;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const [i, e] of (root.labTests || root.subTests || []).entries()) {
    deleteDFS(e, uuid, root, i);
  }
  return root;
}

// first finds the node with uuid passed in as argument
// recursively collects all its children tree's uuids and returns the collection
function collectTreeUUIDs(state, uuid) {
  const foundObject = findDFS(state.draftGroup.labTests, uuid);
  if (!foundObject) return [];
  const uuidCollection = [uuid];
  if (foundObject.subTests) {
    const collectSubTreeUUIDs = (tests) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const test of tests) {
        uuidCollection.push(test.uuid);
        if (test.subTests) {
          collectSubTreeUUIDs(test.subTests);
        }
      }
    };
    collectSubTreeUUIDs(foundObject.subTests);
  }
  return unique(uuidCollection);
}

// finds the node with uuid passed in as argument
// sets value of that objects key passed in as arg with value passed in as arg
function updateDraftFieldByUUID(state, uuid, key, value) {
  const foundObject = findDFS(state.draftGroup.labTests, uuid);
  foundObject[key] = value;
  return state.draftGroup;
}

function swapObjectValues(draft, propertyType, index1, index2) {
  const temp = draft[propertyType][index1];
  draft[propertyType][index1] = draft[propertyType][index2];
  draft[propertyType][index2] = temp;
}

function reorderDraftFieldByUUID(state, uuid, index, direction) {
  const parent = findParentDFS(state.draftGroup, uuid);
  const propertyType = parent.labTests ? "labTests" : "subTests";
  if (direction === "up" && index !== 0) {
    swapObjectValues(parent, propertyType, index, index - 1);
  } else if (direction === "down" && index !== parent[propertyType].length - 1) {
    swapObjectValues(parent, propertyType, index, index + 1);
  }
  return state.draftGroup;
}

function removeTestsByUUIDFromDraft(state) {
  const { checkedUUIDs } = state;
  checkedUUIDs.forEach((id) => {
    deleteDFS(state.draftGroup, id);
  });
  // eslint-disable-next-line no-param-reassign
  state.checkedUUIDs = [];
}

const labSettingsSlice = createSlice({
  name: "labSettings",
  initialState,
  reducers: {
    setTestGroupDraft: (draft, action: PayloadAction<DocumentRecordGroup>) => {
      draft.draftGroup = {
        ...action.payload,
        labTests: action.payload && assignUniqueIdAtLevel(cloneDeep(action.payload.labTests))
      };
    },
    removeDraftGroup: (draft) => {
      draft.draftGroup = null;
    },
    setInitialState: (draft, action: PayloadAction<DocumentRecordGroup>) => {
      draft.initState = action.payload;
    },
    updateDraft: (
      draft,
      { payload }: { payload: { uuid: string; key: string; value: unknown } }
    ) => {
      draft.draftGroup = updateDraftFieldByUUID(draft, payload.uuid, payload.key, payload.value);
      draft.checkedUUIDs = [];
    },
    reorderDraft: (
      draft,
      { payload }: { payload: { uuid: string; index: number; direction: "up" | "down" } }
    ) => {
      draft.draftGroup = reorderDraftFieldByUUID(
        draft,
        payload.uuid,
        payload.index,
        payload.direction
      );
    },
    addTestsAtParentLevel: (draft, action: PayloadAction<Array<DocumentRecordTest>>) => {
      draft.draftGroup.labTests = [...(draft.draftGroup.labTests || []), ...action.payload];
      draft.checkedUUIDs = [];
    },
    setCheckedUUIDs: (draft, action: PayloadAction<string>) => {
      const treeUUIDCollection = collectTreeUUIDs(draft, action.payload);
      if (draft.checkedUUIDs.includes(action.payload)) {
        draft.checkedUUIDs = draft.checkedUUIDs.filter((id) => !treeUUIDCollection.includes(id));
      } else {
        const newCheckedUUIDs = [...draft.checkedUUIDs, ...treeUUIDCollection];
        draft.checkedUUIDs.push(...[...new Set(newCheckedUUIDs)]);
      }
    },
    removeSelectedTests: (draft) => {
      removeTestsByUUIDFromDraft(draft);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLabTestGroups.fulfilled, (draft, { payload }) => {
      draft.labTestGroups = payload;
    });
    builder.addCase(getLabTestGroupsMaster.fulfilled, (draft, { payload }) => {
      draft.labTestGroups = payload;
    });
    builder.addCase(getLabTests.fulfilled, (draft, { payload }) => {
      draft.labTests = payload;
    });
    builder.addCase(postTestGroup.fulfilled, (draft, { payload }) => {
      const indexToUpdate = draft.labTestGroups.findIndex(
        (testGroup) => testGroup.id === payload.referenceId || testGroup.id === payload.id
      );
      if (indexToUpdate !== -1) {
        draft.labTestGroups[indexToUpdate].documentRecord = payload.documentRecord;
        draft.labTestGroups[indexToUpdate].name = payload.documentRecord.name;
        draft.labTestGroups[indexToUpdate].category = payload.documentRecord.category;
      } else {
        draft.labTestGroups.push(payload);
      }
    });
    builder.addCase(getLabTestGroupById.fulfilled, (draft, { payload }) => {
      const indexToUpdate = draft.labTestGroups.findIndex(
        (testGroup) => testGroup.id === payload.id
      );
      draft.labTestGroups[indexToUpdate] = produce(payload, (subDraft) => {
        subDraft.documentRecord.stockReagent?.sort((a, b) => a.sNo - b.sNo);
      });
    });
    builder.addCase(getDefaultLabTestGroupForReset.fulfilled, (draft, { payload }) => {
      const oldDraft = payload;
      draft.draftGroup = {
        ...oldDraft.documentRecord,
        labTests:
          oldDraft.documentRecord.labTests &&
          assignUniqueIdAtLevel(oldDraft.documentRecord.labTests)
      };
      draft.checkedUUIDs = [];
    });
  }
});

export default labSettingsSlice.reducer;
export const {
  setTestGroupDraft,
  updateDraft,
  reorderDraft,
  setCheckedUUIDs,
  removeSelectedTests,
  addTestsAtParentLevel,
  setInitialState,
  removeDraftGroup
} = labSettingsSlice.actions;

import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient
} from "react-query";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  getContraVouchers,
  getJournals,
  getPaymentVouchers,
  getReceiptVouchers,
  postContraVoucher,
  postJournal,
  postPaymentVoucher,
  postReceiptVoucher
} from "../../api/accounts";
import { JournalVouchers, VoucherInterface, VoucherEntryProps } from "../../interfaces/Accounts";
import * as NotificationActions from "../../actions/notification";
import { Filter } from "../../containers/accounts/VoucherList";

// Journal Voucher
export const useJournalVouchers = (filter: string): UseQueryResult<JournalVouchers[], Error> =>
  useQuery<JournalVouchers[], Error>("journalVouchers", getJournals, {
    enabled: filter === Filter.JOURNAL
  });

export const usePostJournalVoucher = (): UseMutationResult<
  VoucherEntryProps,
  Error,
  VoucherEntryProps
> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation<VoucherEntryProps, Error, VoucherEntryProps>(postJournal, {
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries("journalVouchers");
    },
    onError: (error) => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || "Sorry! Something Went Wrong.",
          autoTimeout: true
        })
      );
    }
  });
};

// For Payment Voucher
export const usePaymentVouchers = (
  voucherType: string
): UseQueryResult<VoucherInterface[], Error> =>
  useQuery<VoucherInterface[], Error>("paymentVouchers", getPaymentVouchers, {
    enabled: voucherType === Filter.PAYMENT
  });

export const usePostPaymentVoucher = (): UseMutationResult<
  VoucherInterface,
  Error,
  VoucherInterface
> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation<VoucherInterface, Error, VoucherInterface>(postPaymentVoucher, {
    retry: 0,
    onSuccess: (newPaymentVoucher) => {
      queryClient.setQueryData("paymentVouchers", (oldData: VoucherInterface[]) => [
        ...(oldData || []),
        newPaymentVoucher
      ]);
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Successfully created payment voucher.",
          autoTimeout: true
        })
      );
      dispatch(push("/accounts/vouchers/payment"));
    },
    onError: (error) => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error.data?.message || "Sorry! Something Went Wrong.",
          autoTimeout: true
        })
      );
    }
  });
};

// For Receipt
export const useReceiptVouchers = (filter: string): UseQueryResult<VoucherInterface[], Error> =>
  useQuery<VoucherInterface[], Error>("receiptVouchers", getReceiptVouchers, {
    enabled: filter === Filter.RECEIPT
  });

export const usePostReceiptVoucher = (): UseMutationResult<
  VoucherInterface,
  Error,
  VoucherInterface
> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation<VoucherInterface, Error, VoucherInterface>(postReceiptVoucher, {
    retry: 0,
    onSuccess: (newPaymentVoucher) => {
      queryClient.setQueryData("receiptVouchers", (oldData: VoucherInterface[]) => [
        ...(oldData || []),
        newPaymentVoucher
      ]);
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Successfully create receipt voucher.",
          autoTimeout: true
        })
      );
      dispatch(push("/accounts/vouchers/receipt"));
    },
    onError: (error) => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error.data?.message || "Sorry! Something Went Wrong.",
          autoTimeout: true
        })
      );
    }
  });
};

// For Contra
export const useContraVoucher = (filter: string): UseQueryResult<VoucherInterface[], Error> =>
  useQuery<VoucherInterface[], Error>("receiptVouchers", getContraVouchers, {
    enabled: filter === Filter.CONTRA
  });

export const usePostContraVoucher = (): UseMutationResult<
  VoucherInterface,
  Error,
  VoucherInterface
> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation<VoucherInterface, Error, VoucherInterface>(postContraVoucher, {
    retry: 0,
    onSuccess: (newContraVoucher) => {
      queryClient.setQueryData("receiptVouchers", (oldData: VoucherInterface[]) => [
        ...(oldData || []),
        newContraVoucher
      ]);
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Successfully create contra voucher.",
          autoTimeout: true
        })
      );
      dispatch(push("/accounts/vouchers/contra"));
    },
    onError: (error) => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error.data?.message || "Sorry! Something Went Wrong.",
          autoTimeout: true
        })
      );
    }
  });
};

import * as React from "react";
import * as ReactDOM from "react-dom";
import Box from "@mui/material/Box";
import { useHotkeys } from "react-hotkeys-hook";
import Draggable from "react-draggable";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import classNames from "../../helpers/classNames";
import useMobileScreen from "../../hooks/useMobileScreen";

const useStyles = makeStyles({
  root: {
    zIndex: 1201,
    minWidth: "740px",
    maxWidth: "740px",
    background: "white",
    borderLeft: "1px solid grey",
    height: "100vh",
    position: "absolute",
    top: 0,
    right: 0,
    boxShadow: "0 -1px 50px 10px #00000057",
    overflowY: "auto"
  },
  handle: {
    cursor: "all-scroll"
  },
  rootSm: {
    minWidth: "100% !important",
    width: "100% !important"
  },
  header: {
    height: "50px"
  },
  footer: {
    height: "50px",
    display: "flex"
  }
});

interface PanelProps {
  title?: any;
  children: React.ReactNode;
  titleJsx?: any;
  onClose?: () => void;
  footer?: any;
  deleteButton?: any;
  editButton?: any;
  printButton?: any;
  genericButton?: React.ReactNode;
  messageButton?: any;
  wrapperStyle?: any;
}

const Panel: React.FC<PanelProps> = ({
  title,
  titleJsx,
  onClose,
  children,
  footer,
  deleteButton,
  editButton,
  printButton,
  messageButton,
  genericButton,
  wrapperStyle
}) => {
  const styles = useStyles({});
  const defaultPosition = { x: 0, y: 0 };
  const [position, setPosition] = React.useState(defaultPosition);
  const matchesSMScreen = useMediaQuery("(max-width:840px)");
  const isMobile = useMobileScreen();

  useHotkeys(
    "esc",
    () => {
      if (onClose) {
        onClose();
      }
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );
  const jsx = (
    <Draggable
      handle=".handle"
      defaultPosition={defaultPosition}
      position={position}
      onStop={(e, p) => {
        setPosition({ x: p.x, y: 0 });
      }}
      grid={[25, 25]}
      scale={1}
    >
      <Box
        className={classNames(styles.root, { [styles.rootSm]: matchesSMScreen })}
        style={wrapperStyle}
        id="portalPanel"
      >
        <Box className={classNames(styles.header)} display="flex">
          {onClose && (
            <Box display="flex" paddingLeft="16px" flexBasis="60px" alignItems="center">
              <Tooltip arrow title="Esc">
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  data-testmation="panelCloseIcon"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Box
            className={classNames("handle", styles.handle)}
            display="flex"
            flexGrow={1}
            alignItems="center"
            pt={1}
          >
            {title ? (
              <Typography>
                <Box component="span" fontSize="1.4em" fontWeight={600}>
                  {title}
                </Box>
              </Typography>
            ) : (
              titleJsx
            )}
          </Box>
          <Box display="flex" alignItems="center" gap={2} mr={isMobile ? 2 : 4}>
            {genericButton && <Box data-testmation="genericButton">{genericButton}</Box>}
            {messageButton && <Box data-testmation="messageButton">{messageButton}</Box>}
            {printButton && <Box data-testmation="printButton">{printButton}</Box>}
            {deleteButton && <Box data-testmation="deleteButton">{deleteButton}</Box>}
            {editButton && <Box data-testmation="editButton">{editButton}</Box>}
          </Box>
        </Box>
        {children}
        {footer && (
          <Box className={classNames(styles.footer)} pl={3}>
            {footer}
          </Box>
        )}
      </Box>
    </Draggable>
  );
  return ReactDOM.createPortal(jsx, document.getElementById("portal"));
};

export default Panel;

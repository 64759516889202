import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import { startCase } from "lodash";
import produce from "immer";
import { connect, useSelector } from "react-redux";

import { push } from "connected-react-router";
import { tl } from "../../../components/translate";
import { patchMedicalTest } from "../../../actions/medicalTest";
import VitalsPlain from "../../Assessment/Vitals/VitalsPlain";
import { LabStatuses } from "../../../interfaces/Lab";
import { RootState } from "../../../store";

interface Vital {
  date: string;
  extraInfo: null;
  name: string;
  reading: string;
  unit: string;
}
interface Props {
  vitals: Array<Vital>;
  id: number;
  medicalTest: any;
  updateTest: (data, id) => void;
  nextAction: () => void;
}

function ShowVitals({ vitals, updateTest, id, medicalTest, nextAction }: Props) {
  const [showAddVitals, setShowAddVitals] = React.useState(true);

  const rc = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre
  );

  const authorizedSP = rc?.settings?.medicalSettings;

  React.useEffect(() => {
    if (vitals) {
      setShowAddVitals(false);
    }
  }, [vitals]);

  return (
    <>
      {!showAddVitals && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            href="#"
            onClick={() => {
              setShowAddVitals(true);
            }}
          >
            {tl("medical.updateVitals")}
          </Button>
        </Box>
      )}
      {showAddVitals && (
        <VitalsPlain
          onSave={async (filteredVitals) => {
            const results = produce(medicalTest.results, (draft) => {
              draft.vitals = filteredVitals;
            });
            const toPost = {
              attachments: [],
              authorizedSP1Id: authorizedSP.authorizedSP1,
              authorizedSP2Id: authorizedSP.authorizedSP2,
              authorizedSP3Id: authorizedSP.authorizedSP3,
              authorizedSP4Id: authorizedSP.authorizedSP4,
              final: medicalTest.status !== LabStatuses.INCOMPLETE_RESULT,
              updatedTestResults: results
            };
            await updateTest(toPost, id);
            nextAction();
          }}
        />
      )}
      <Box mt="16px">
        {vitals && (
          <>
            {vitals.map((item, i) => (
              <Typography key={item.name}>
                {i + 1}. {startCase(item.name)}: {item.reading}
                {item.unit}
              </Typography>
            ))}
          </>
        )}
      </Box>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const medicalTest = state.medicalTest.collection.find((item) => item.id === Number(ownProps.id));

  return {
    medicalTest
  };
}

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (url) => {
    dispatch(push(url));
  },
  updateTest: (data, id) => dispatch(patchMedicalTest(data, id))
});
export default connect(mapStateToProps, mapDispatchToProps)(ShowVitals);

import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, ButtonGroup, Menu, MenuItem, Typography } from "@mui/material";
import Panel from "../../../components/Panel";
import { tl } from "../../../components/translate";
import { HMIS, HMISServiceType } from "../../../interfaces/HmisInterface";
import { clientFullNameWithPhoneSelector } from "../../../reducers/client";

export const InfoRow = ({ label, value }: { label: string; value: string }): JSX.Element => (
  <Box sx={{ display: "flex", mb: 0.5 }}>
    <Typography width={200}>{label}</Typography>:
    <Typography fontWeight={600} textAlign="right" ml={2}>
      {value || "-"}
    </Typography>
  </Box>
);

interface Props {
  onClose: () => void;
  onCreateOutpatientRegister: () => void;
  onCreateAboveTwoMonthsImnc: () => void;
  onCreateBelowTwoMonthsImnc: () => void;
  data: HMIS;
}

const MulDartaInfoPanel = ({
  onClose,
  data,
  onCreateOutpatientRegister,
  onCreateAboveTwoMonthsImnc,
  onCreateBelowTwoMonthsImnc
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleIMNCMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleIMNCMenuClose = () => {
    setAnchorEl(null);
  };

  const imciMenuItems = (
    <ButtonGroup
      component="div"
      color="primary"
      variant="contained"
      ref={anchorEl}
      aria-label="split button"
    >
      <Button
        onClick={() => {
          handleIMNCMenuClose();
          onCreateAboveTwoMonthsImnc();
        }}
      >
        {tl("hmis.createNewImnci241AboveTwoMonths")}
      </Button>
      <Button onClick={(e) => handleIMNCMenuOpen(e)} size="small">
        <ArrowDropDownIcon />
      </Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleIMNCMenuClose}
        data-testmation="imnciDropdown"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem
          onClick={() => {
            handleIMNCMenuClose();
            onCreateBelowTwoMonthsImnc();
          }}
        >
          {tl("hmis.createNewImnci241BelowTwoMonths")}
        </MenuItem>
      </Menu>
    </ButtonGroup>
  );

  return (
    <Panel onClose={onClose} title="Mul Darta">
      <Box sx={{ px: 4, pt: 2 }}>
        <div>
          <Typography gutterBottom variant="h5" component="p">
            {clientFullNameWithPhoneSelector(data.client)}
          </Typography>
          <InfoRow label="Is first time ?" value={!data.followup ? "Yes" : "No"} />
          <InfoRow label="Mul Darta Number" value={data.mulDartaNumber} />
          <InfoRow label="Service Type" value={data.himsServiceType} />
          <InfoRow label="Service Charge" value={data.serviceCharge} />
          <InfoRow label="Referrer Organization" value={data.referrer?.referrer} />
        </div>
        <Box mt={2}>
          {data.himsServiceType !== HMISServiceType.IMCI && (
            <Button variant="contained" onClick={onCreateOutpatientRegister}>
              {tl("hmis.createOPDRegister")}
            </Button>
          )}
          {data.himsServiceType === HMISServiceType.IMCI && imciMenuItems}
        </Box>
      </Box>
    </Panel>
  );
};

export default MulDartaInfoPanel;

import React, { useState } from "react";
import { Box, IconButton, Popper, Grow, Paper, ClickAwayListener } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "../../../helpers/classNames";
import PopUpContent from "./PopUpContent";
import styles from "./Intraoral.module.css";

const numbers = ["1", "2", "3", "4", "5", "6", "7", "8"];
const alphabets = ["A", "B", "C", "D", "E"];

interface IntraOralContentProps {
  themeColor: string;
  numbering: string;
  type: number;
  stateValue: number;
  onNumberSave: () => void;
  onNodeDelete: (v) => void;
}

function IntraOralContent(props: IntraOralContentProps): JSX.Element {
  const { themeColor, numbering, type, stateValue, onNumberSave, onNodeDelete } = props;

  const useStyles = makeStyles((theme) => ({
    intraOralDefaultBtn: {
      height: "32px",
      width: "32px",
      padding: 0,
      margin: "0px 4px"
    },
    intraOralSelectedBtn: {
      backgroundColor: `${themeColor || "#009654"}`,
      color: "white",
      "&:hover": {
        opacity: 0.5,
        backgroundColor: `${themeColor || "#009654"}`
      }
    },
    intraOralActiveBtn: {
      border: `3px solid ${themeColor || "#009654"}`
    },
    popper: {
      zIndex: theme.zIndex.tooltip
    }
  }));

  const classes = useStyles();
  const [quarterIndex, setQuarterIndex] = useState(numbers);

  React.useEffect(() => {
    if (numbering === "alphabets") {
      setQuarterIndex(alphabets);
    } else {
      setQuarterIndex(numbers);
    }
  }, []);

  const [popUpValues, setPopUpValues] = useState({
    quarter: null,
    quarterNum: null
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement, event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const isIconSelected = (quarterAlpha, quarterNum, numVal) => {
    let key;
    if (numbering === "alphabets") {
      key = quarterAlpha;
    } else {
      key = quarterNum;
    }
    key += numVal;
    if (key in stateValue[type]) return true;
    return false;
  };

  const isIconActive = (quarterAlpha, quarterNum, numVal) => {
    if (
      (popUpValues.quarter === quarterAlpha || popUpValues.quarter === quarterNum) &&
      popUpValues.quarterNum === numVal &&
      open
    )
      return true;
    return false;
  };

  return (
    <>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center top"
            }}
          >
            <Paper variant="outlined">
              <ClickAwayListener
                onClickAway={() => {
                  setOpen((prevOpen) => !prevOpen);
                }}
              >
                <Box height="380px" width="380px">
                  <PopUpContent
                    onNumberSave={onNumberSave}
                    stateValue={stateValue}
                    onNodeDelete={onNodeDelete}
                    type={type}
                    quarter={popUpValues.quarter}
                    quarterNum={popUpValues.quarterNum}
                    closePopUp={() => setOpen((prevOpen) => !prevOpen)}
                  />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Box className={styles.mainIntraBox}>
        <Box className={styles.subIntraBoxContainer}>
          <Box className={styles.subIntraBox}>
            <Box
              className={styles.contentIntraBox}
              borderBottom="1px solid black"
              justifyContent="flex-end"
              pr={2}
            >
              {quarterIndex
                .slice(0)
                .reverse()
                .map((q) => (
                  <IconButton
                    data-testmation="intraOralIconButton"
                    key={q}
                    size="medium"
                    className={classNames(classes.intraOralDefaultBtn, {
                      [classes.intraOralSelectedBtn]: isIconSelected(5, 1, q),
                      [classes.intraOralActiveBtn]: isIconActive(1, 5, q)
                    })}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                      setPopUpValues({
                        quarter: numbering === "alphabets" ? 5 : 1,
                        quarterNum: q
                      });
                      handleClick("bottom-start", e);
                    }}
                  >
                    {q}
                  </IconButton>
                ))}
            </Box>
            <Box className={styles.contentIntraBox} justifyContent="flex-end" pr={2}>
              {quarterIndex
                .slice(0)
                .reverse()
                .map((q) => (
                  <IconButton
                    data-testmation="intraOralIconButton"
                    key={q}
                    size="medium"
                    className={classNames(classes.intraOralDefaultBtn, {
                      [classes.intraOralSelectedBtn]: isIconSelected(8, 4, q),
                      [classes.intraOralActiveBtn]: isIconActive(4, 8, q)
                    })}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                      setPopUpValues({
                        quarter: numbering === "alphabets" ? 8 : 4,
                        quarterNum: q
                      });
                      handleClick("bottom-start", e);
                    }}
                  >
                    {q}
                  </IconButton>
                ))}
            </Box>
          </Box>
          <Box className={styles.subIntraBox}>
            <Box className={styles.contentIntraBox} borderBottom="1px solid black" pl={2}>
              {quarterIndex.map((q) => (
                <IconButton
                  data-testmation="intraOralIconButton"
                  key={q}
                  size="medium"
                  className={classNames(classes.intraOralDefaultBtn, {
                    [classes.intraOralSelectedBtn]: isIconSelected(6, 2, q),
                    [classes.intraOralActiveBtn]: isIconActive(2, 6, q)
                  })}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    setPopUpValues({
                      quarter: numbering === "alphabets" ? 6 : 2,
                      quarterNum: q
                    });
                    handleClick("bottom-start", e);
                  }}
                >
                  {q}
                </IconButton>
              ))}
            </Box>
            <Box className={styles.contentIntraBox} pl={2}>
              {quarterIndex.map((q) => (
                <IconButton
                  data-testmation="intraOralIconButton"
                  key={q}
                  size="medium"
                  className={classNames(classes.intraOralDefaultBtn, {
                    [classes.intraOralSelectedBtn]: isIconSelected(7, 3, q),
                    [classes.intraOralActiveBtn]: isIconActive(3, 7, q)
                  })}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    setPopUpValues({
                      quarter: numbering === "alphabets" ? 7 : 3,
                      quarterNum: q
                    });
                    handleClick("bottom-start", e);
                  }}
                >
                  {q}
                </IconButton>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default IntraOralContent;

import { Client, Customer } from "../interfaces/ClientInterface";
import { IPD, MedicationSummary } from "../interfaces/IpdInterface";
import { ServiceInterface } from "../interfaces/ServiceInterface";
import { Get, Patch, Post, Delete } from "./apiHelper";
import { RequestFormStatus } from "../containers/IPD/LabRequest/LabRequestDetails";
import { OPDRequestStatus } from "../enum/OpdRequestForm";

const ipdRequestFormRoot = "/api/ipd-request-forms";
const opdRequestFormRoot = "/api/opd-request-forms";

export interface RequestForm {
  id: number;
  resourceCentreId: number;
  clientId: number;
  ipdId: number;
  requestedItems: Array<ServiceInterface>;
  formType: string;
  status: OPDRequestStatus | RequestFormStatus;
  requestedByUserId: number;
  requestedItemsType: string;
  client: Client;
  ipd: IPD;
  customer?: Customer;
}

interface Payload {
  ipdId: number;
  requestedItems: Array<ServiceInterface | MedicationSummary>;
  requestedItemsType: string;
}

interface OpdRequestFormPayload {
  clientId: number;
  serviceId: number;
  serviceProviderId: number;
  sentDate: string;
  deliveryDate: string;
  quantity: number;
  price: number;
  requestStatus: string;
  total: number;
  status: OPDRequestStatus;
}

export interface RequestFormResponse {
  results: Array<RequestForm>;
  total: number;
}

export const getIpdRequestForms = async ({
  page,
  pageSize
}: {
  page: number;
  pageSize: number;
}): Promise<RequestFormResponse> => {
  const response = await Get(
    `${ipdRequestFormRoot}?page=${page?.toString() || ""}&pageSize=${pageSize || ""}`,
    true
  );
  return response.data as RequestFormResponse;
};

export const postIpdRequestForm = async (payload: Partial<Payload>): Promise<RequestForm> => {
  const response = await Post(ipdRequestFormRoot, payload, true);
  return response.data as RequestForm;
};

export const getIpdRequestForm = async (id: string | number): Promise<RequestForm> => {
  const response = await Get(`${ipdRequestFormRoot}/${id}`, true);
  return response.data as RequestForm;
};

export const updateStatus = async (id: number, status: RequestFormStatus): Promise<RequestForm> => {
  const response = await Patch(`${ipdRequestFormRoot}/${id}/updateStatus`, { status }, true);
  return response.data as RequestForm;
};

// OPD Request Form
export const getOpdRequestForm = async (id: string | number): Promise<RequestForm> => {
  const response = await Get(`${opdRequestFormRoot}/${id}`, true);
  return response.data as RequestForm;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getOpdRequests = async ({
  page,
  pageSize,
  from,
  until,
  clientIds = [],
  serviceProviderIds = []
}: {
  page: number;
  pageSize: number;
  clientId?: number;
  from?: Date;
  until?: Date;
  clientIds?: Array<number>;
  serviceProviderIds?: Array<number>;
}): Promise<{
  results: RequestForm[];
  total: number;
}> => {
  const params = new URLSearchParams();

  if (from) params.append("from", from.toISOString());
  if (until) params.append("until", until.toISOString());
  if (clientIds.length > 0) params.append("clientIds", clientIds.join(","));
  if (serviceProviderIds.length > 0)
    params.append("serviceProviderIds", serviceProviderIds.join(","));
  if (page !== undefined) params.append("page", page.toString());
  if (pageSize !== undefined) params.append("pageSize", pageSize.toString());

  const queryString = params.toString();
  const url = `${opdRequestFormRoot}${queryString ? `?${queryString}` : ""}`;

  const response = await Get(url, true);
  return response.data as Promise<{ results: RequestForm[]; total: number }>;
};

export const postOpdRequest = async (
  payload: Partial<OpdRequestFormPayload>
): Promise<RequestForm> => {
  const response = await Post(opdRequestFormRoot, payload, true);
  return response.data as RequestForm;
};

export const patchOpdRequest = async (
  id: string | number,
  payload: Partial<OpdRequestFormPayload>
): Promise<RequestForm> => {
  const response = await Patch(`${opdRequestFormRoot}/${id}`, payload, true);
  return response.data as RequestForm;
};

export const updateOpdRequestStatus = async (
  id: string | number,
  payload: Partial<OpdRequestFormPayload>
): Promise<RequestForm> => {
  const response = await Patch(`${opdRequestFormRoot}/${id}/updateStatus`, payload, true);
  return response.data as RequestForm;
};

export const deleteOpdRequestForm = async (id: string | number): Promise<void> => {
  await Delete(`${opdRequestFormRoot}/${id}`, true);
};

import React from "react";
import { Typography, Link, Box } from "@mui/material";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import moment from "moment";
import { registerPrint } from "../../../actions/bill";
import usePrintStyles from "../../../hooks/usePrintStyles";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import LabPrintLetterHead from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import LabPrintLetterHeadCentered from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import logo from "../../../../assets/images/poweredByOkhati.png";
import { BillClientInfo, InfoField } from "../../Billing/PrintBill/BillPrintHelpers";
import { dateWithZeroBeforeSingleDigits } from "../../../helpers/formatters";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import PaymentDescriptionV2 from "../../Billing/PrintBill/PrintPaymentHistory/PaymentDescriptionV2";
import { RootState } from "../../../store";
import { getCustomerNumber } from "../ClientList";

const PrintBill = ({ buttonText, resourceCentre, client, useBordered = false, data }) => {
  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();

  const printRef = React.useRef();

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showFooter = resourceCentre?.settings?.printSettings.billPrintSettings?.includeFooter;
  const template: string = resourceCentre?.settings?.printSettings.billPrintSettings.template;
  const isBordered = useBordered || template === "borderedTemplate";
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const hideRcLogo = Boolean(customTitle);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Link
            onClick={() => ({})}
            type="submit"
            component="button"
            className="actionBtn"
            onMouseEnter={() => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).applyPrintStyles();
            }}
            style={{
              width: "100%",
              minHeight: "20px"
            }}
          >
            <Typography component="span" style={{ color: "#009654", fontSize: "0.65rem" }}>
              <Box className="actionBtnLabel" component="span" onClick={() => ({})}>
                {buttonText}
              </Box>
            </Typography>
          </Link>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterhead && (
                <tr>
                  <td>
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {showLetterhead &&
                        (centralizedHeader ? (
                          <LabPrintLetterHeadCentered
                            resourceCentre={{
                              ...resourceCentre,
                              panNo,
                              name: customTitle || resourceCentre.name
                            }}
                            titleFontScale={clinicNameFontScale}
                            hideRcLogo={hideRcLogo}
                          />
                        ) : (
                          <LabPrintLetterHead
                            resourceCentre={{
                              ...resourceCentre,
                              panNo,
                              name: customTitle || resourceCentre.name
                            }}
                            titleFontScale={clinicNameFontScale}
                            hideRcLogo={hideRcLogo}
                          />
                        ))}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="0.8cm"
                      width="100%"
                      justifyContent="center"
                    >
                      {data.transactionType === "debit" ? "Debit Invoice" : "Receipt"}
                    </Box>
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="1cm"
                      justifyContent="space-between"
                      borderTop="1px solid black"
                      borderRight={isBordered ? "1px solid black" : ""}
                      borderBottom={isBordered ? "1px solid black" : ""}
                      borderLeft={isBordered ? "1px solid black" : ""}
                      p="0.3cm"
                    >
                      <Box component="div" width="50%">
                        <BillClientInfo
                          client={client}
                          customerNumber={getCustomerNumber(client.customerNumber)}
                        />
                      </Box>
                      <Box component="div" width="40%">
                        <InfoField
                          label="Transaction Date"
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                convertADtoBS(moment(data.transactionDate)).formatted
                              )}
                            </>
                          }
                        />

                        <InfoField
                          label="Date AD"
                          data={<>{moment(data.transactionDate).format("DD/MM/YYYY")}</>}
                        />
                      </Box>
                    </Box>
                    <PaymentDescriptionV2
                      relatedSettings={{ showReferrer: false, isBordered, headerColor }}
                      receivedOn={data.transactionDate}
                      paidAmount={data.amount || ""}
                      paymentMethod={data.paymentMethod || "-"}
                      remarks={data.remarks || "-"}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showFooter ? (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <Box>
                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          ) : (
            <Box width="100%" className="billFooterPos">
              <Box style={{ paddingRight: "10mm" }} mt={2} display="flex" justifyContent="flex-end">
                <img src={logo} alt="logo" height="35px" />
              </Box>
              <Box height="2cm" />
            </Box>
          )}
        </div>
      </iframe>
    </>
  );
};

const mapStateToProps = (state: RootState, { billId }) => {
  const billData = state.bills.collection.find(({ id }) => Number(billId) === id);

  const { resourceCentres } = state.resources;
  const rcId = state.userContext.resourceCentreId;

  const getResourceCentre = (id, rcs) => rcs.find((rc) => rc.id === id);

  return {
    SPs: state.resources.resourceCentreServiceProviders,
    user: state.userContext.user,
    resourceCentre: getResourceCentre(rcId, resourceCentres) || state.userContext.resourceCentre,
    billData
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendPrintInfo: (id) => dispatch(registerPrint(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintBill);

import { Get } from './apiHelper';

export const serviceProviderSearch = async (search) => {
  const response = await Get(`/api/serviceProviders/search?q=` + search);
  return response.data;
};

export const getResourceCentres = async () => {
  const response = await Get('/api/resourceCentres');
  return response.data;
};

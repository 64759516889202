import { Autocomplete, Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import { push } from "connected-react-router";
import produce from "immer";
import startCase from "lodash/startCase";
import queryString from "query-string";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { serviceProviderActions as spActions } from "../../../actions";
import { getAccessingResourceCentres } from "../../../actions/accessingResourceCentres";
import { getBatchLabTests } from "../../../actions/assessments";
import { getClientById, uploadClientImage } from "../../../actions/client";
import * as actions from "../../../actions/labTest";
import ClientSearch from "../../../components/ClientSearch";
import ClientImageUploader from "../../../components/ImageUploader/ClientImageUploader";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { t, tl } from "../../../components/translate";
import { LabRecord, Test } from "../../../interfaces/Lab";
import { clientSearchSecondaryLabel } from "../../../models/Client";
import { getLabTestGroups } from "../../../slices/labTestSettingsSlice";
import { IThunkDispatch, RootState } from "../../../store";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import LabInfo from "../LabInfo";
import { LabTestStatusTypes } from "../LabList";
import SampleTaken from "../SampleTaken";
import AddLabtests from "./AddLabtests";
import { useAppSelector } from "../../../store/hooks";
import hasOwnProperty from "../../../helpers/object";
import { Type } from "../../../actions/labTest";

const getTestsFromRecord = (labRecord: LabRecord, labTests: Array<Test>) => {
  if (!labRecord) return [];
  if (!labTests) return [];
  return labRecord.results.data
    .map(({ id, sampleTaken, collectionDate, remarks }) => {
      const labTest = labTests.find(
        (test) => test.id === Number(id) || test?.documentRecord?.id === Number(id)
      );
      // Map sample taken information of all tests within the lab records
      const currentTestInRecord =
        labRecord?.results.data.find((lab) => lab.id === labTest?.id) || {};
      if (labTest) {
        return {
          ...labTest,
          sampleTaken: Boolean(sampleTaken),
          sampleInfo: {
            collectionDate,
            remarks
          },
          sampleContainerInfo: currentTestInRecord.sampleInfo || {}
        };
      }
      return labTest;
    })
    .filter(Boolean);
};

export enum MODE {
  CREATE = "create",
  EDIT = "edit"
}

const LabTestCreate = ({
  serviceProviders,
  navigateTo,
  labTests,
  postLabTest,
  loadServiceProviders,
  resourceCentreId,
  user,
  queryClient,
  showLabIdentifier,
  restrictTestCreation,
  uploadPhoto,
  allowClientPictureUpload,
  queryClientId,
  getClientFromId,
  accessingLabCentreIds,
  accessingLabCentreSubscribed,
  accessingResourceCentres,
  loadAccessingResourceCentres,
  id = null,
  labRecordType,
  onClose,
  isForSampleTaken = false,
  runAfterSave
}) => {
  const location = useLocation();
  const statusTab = queryString.parse(location.search)?.status || LabTestStatusTypes.ORDERED;
  const [serviceProvider, setServiceProvider] = React.useState(null);
  const [tests, setTests] = React.useState<Array<Test>>([]);
  const [client, setClient] = React.useState(null);
  const [sampleTaken, setSampleTaken] = React.useState({
    isSampleTaken: true,
    dateAndRemarks: { collectionDate: new Date(), remarks: "" }
  });
  const [createAnother, setCreateAnother] = React.useState(false);
  const [referrer, setReferrer] = React.useState("");
  const [extraReferrer, setExtraReferrer] = React.useState<{ name: string; id: number | null }>({
    name: "",
    id: null
  });
  const [referrerId, setReferrerId] = React.useState(null);
  const [labIdentifier, setLabIdentifier] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [accessingResourceCentreId, setAccessingResourceCentreId] = React.useState<string | number>(
    ""
  );
  const ongoing = useAppSelector((state) => state.ongoing);

  const requireReferrer = useSelector(
    (state: RootState) =>
      state.resources?.resourceCentres[0]?.settings?.formSettings?.requireReferrer
  );

  const showExtraReferrer = useSelector(
    (state: RootState) => state.resources?.resourceCentres[0]?.labSettings.enableExtraReferrer
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (restrictTestCreation && !isForSampleTaken) {
      navigateTo(`/lab/labRecords/${labRecordType}?status=Ordered`);
    }
  }, []);

  React.useEffect(() => {
    if (queryClientId && !queryClient) {
      getClientFromId(queryClientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClientId, queryClient]);

  React.useEffect(() => {
    if (!labTests?.length) dispatch(getLabTestGroups());
  }, [dispatch, labTests?.length]);

  React.useEffect(() => {
    if (queryClient) {
      setClient({
        ...queryClient,
        profileImage: queryClient.customerDetails?.profileImageS3Url
      });
    }
  }, [queryClient]);

  const isMedicalTest = queryString.parse(window.location.search)?.medical;

  React.useEffect(() => {
    if (isMedicalTest) {
      const medicalTest = labTests.find((item) => item.id === 705);
      if (medicalTest) setTests([medicalTest]);
    }
  }, [labTests, isMedicalTest]);

  React.useEffect(() => {
    if (!serviceProviders?.length && resourceCentreId) {
      loadServiceProviders(resourceCentreId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCentreId]);

  React.useEffect(() => {
    if (accessingLabCentreSubscribed && accessingLabCentreIds.length > 0) {
      loadAccessingResourceCentres(accessingLabCentreIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessingLabCentreIds, accessingLabCentreSubscribed]);

  React.useEffect(() => {
    if (!accessingResourceCentreId && accessingResourceCentres.length) {
      setAccessingResourceCentreId(Number(localStorage.getItem("accessingRcId")));
    }
  }, [accessingResourceCentres, accessingResourceCentreId]);

  const accessingRc = accessingResourceCentres.find(
    (item) => item.id === accessingResourceCentreId
  );

  const relatedLabTestRecord = useSelector((state: RootState) =>
    state.labTest.labTestRecords.find((record) => record.id === Number(id))
  );

  React.useEffect(() => {
    if (relatedLabTestRecord?.status === LabTestStatusTypes.SAMPLE_TAKEN) {
      setSampleTaken({
        isSampleTaken: true,
        dateAndRemarks: {
          collectionDate: relatedLabTestRecord.collectionDate || new Date(),
          remarks: relatedLabTestRecord?.remarks[0]?.remark || ""
        }
      });
    }
  }, [relatedLabTestRecord]);

  React.useEffect(() => {
    // Even if only one test is sample taken, the status for record will be sample taken
    if (tests.length) {
      const isSampleTaken = tests.find((test) => test.sampleTaken);
      setSampleTaken({
        ...sampleTaken,
        isSampleTaken: Boolean(isSampleTaken)
      });
    }
  }, [tests]);

  React.useEffect(() => {
    if (relatedLabTestRecord) {
      const relevantTests = getTestsFromRecord(relatedLabTestRecord, labTests);
      if (relevantTests.length) {
        setTests(relevantTests);
        setClient(relatedLabTestRecord.client);
        setReferrer(relatedLabTestRecord.referrers);
        setReferrerId(relatedLabTestRecord.referrerId);
        if (relatedLabTestRecord.extraReferrer) {
          setExtraReferrer(relatedLabTestRecord.extraReferrer);
        }
      }
    }
  }, [relatedLabTestRecord, labTests]);
  const footer = (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginRight: "32px"
      }}
    >
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={createAnother}
          data-testmation="footerCheckbox"
          onChange={() => setCreateAnother(!createAnother)}
        />
        <Typography>
          <Box>{tl("services.createNewAfterSave")}</Box>
        </Typography>
      </Box>
      <Box>
        <Button
          onClick={() => {
            if (onClose) {
              onClose();
            }
            navigateTo(`/lab/labRecords/${labRecordType}/${id || ""}`);
          }}
          data-testmation="cancelLabCreate"
        >
          {tl("cancel")}
        </Button>
        <StatefulButton
          style={{ marginLeft: "16px" }}
          variant="contained"
          color="primary"
          disabled={
            !client ||
            loading ||
            !tests.length ||
            (requireReferrer && !referrer) ||
            hasOwnProperty(ongoing, Type.POST_OR_EDIT_LAB_TEST_RECORD)
          }
          onClick={async () => {
            await setLoading(true);
            const data = {
              info: {
                clientId: client.id,
                accessingResourceCentreId,
                serviceProviderId: serviceProvider?.id,
                sampleTaken: {
                  ...sampleTaken,
                  id: user.id,
                  name: `${user.firstName} ${user.lastName}`
                },
                referrers: referrer,
                referrerId,
                labIdentifier,
                extraReferrer
              },
              requestedTests: tests.map((item) => ({
                ...item,
                sampleTaken: Boolean(item.sampleTaken),
                sampleInfo: {
                  ...item.sampleInfo,
                  collectedBy: {
                    id: user.id,
                    name: `${user.firstName} ${user.lastName}`
                  }
                }
              }))
            };
            postLabTest(
              relatedLabTestRecord ? { ...data, id, mode: "edit" } : data,
              createAnother,
              labRecordType
            );
            setLoading(false);
            if (runAfterSave) {
              runAfterSave();
            }
            if (createAnother) {
              setClient(null);
            }
          }}
          isLoading={loading}
          circularProgressProps={{ size: 16 }}
          data-testmation="createLab"
        >
          {tl("save")}
        </StatefulButton>
      </Box>
    </Box>
  );

  const getPanelTitle = (labId, sampleTakenTitle = false) => {
    if (labId && sampleTakenTitle) {
      return "Mark Sample Taken";
    }
    if (labId && !sampleTakenTitle) {
      return tl("lab.updateLabTest");
    }
    return tl("lab.createLabTest");
  };

  return (
    <Panel
      title={getPanelTitle(id, isForSampleTaken)}
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          navigateTo(`/lab/labRecords/${labRecordType}?status=${statusTab}`);
        }
      }}
      footer={footer}
    >
      <Box
        p={5}
        width="100%"
        height="calc(100vh - 125px)"
        style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}
      >
        {isForSampleTaken && relatedLabTestRecord ? (
          <LabInfo labObj={relatedLabTestRecord} />
        ) : (
          <>
            <Box width="100%" display="flex">
              <ClientSearch
                isDisabled={Boolean(id)}
                autoFocus={createAnother && !client}
                client={client}
                setClient={setClient}
                variant="outlined"
                margin="dense"
                label={tl("lab.customer")}
                placeholder={t("lab.customer")}
                secondaryText={[
                  { type: clientSearchSecondaryLabel.CUSTOMER_NUMBER },
                  {
                    type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER,
                    label: t("client.externalIdentifier.short")
                  }
                ]}
                style={{ width: "260px" }}
              />
              {allowClientPictureUpload && client && (
                <Box ml="32px">
                  <ClientImageUploader
                    initialImage={client?.profileImage}
                    action={(image) => {
                      uploadPhoto(client.id, image, (data) => {
                        setClient({
                          ...client,
                          profileImage: data.profileImageS3Url
                        });
                      });
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box width="40%">
              <Autocomplete
                data-testmation="labTestSelectSP"
                disabled={Boolean(id) || isForSampleTaken}
                options={serviceProviders}
                getOptionLabel={(option) => startCase(`${option.firstName} ${option.lastName}`)}
                renderOption={(params, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...params} key={option.id}>
                    {startCase(`${option.firstName} ${option.lastName}`)}
                  </li>
                )}
                value={serviceProvider}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={tl("lab.orderedBy")}
                    placeholder={t("lab.selectSP")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(e, value) => {
                  setServiceProvider(value);
                }}
              />

              {accessingLabCentreSubscribed && accessingLabCentreIds.length > 0 && (
                <Box>
                  <Autocomplete
                    data-testmation="labTestSelectSP"
                    options={accessingResourceCentres}
                    getOptionLabel={(option) => startCase(`${option.name}`)}
                    value={accessingRc ? { ...accessingRc } : null}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        label="Accessing Resource Centre"
                        placeholder={t("lab.selectSP")}
                      />
                    )}
                    disabled={Boolean(id)}
                    onChange={(_, v) => {
                      const accessingRcId = v.id || "";
                      setAccessingResourceCentreId(accessingRcId);
                      localStorage.setItem("accessingRcId", accessingRcId);
                    }}
                  />
                </Box>
              )}

              <ReferrerCreateSelect
                onReferrerChange={(item) => {
                  if (item) {
                    setReferrer(item.referrer);
                    setReferrerId(item.id);
                  }
                }}
                disabled={Boolean(id)}
                referrerId={referrerId}
                useSettings
                width="264px"
              />
              {showExtraReferrer && (
                <ReferrerCreateSelect
                  label="Extra Referrer"
                  onReferrerChange={(item) => {
                    if (item) {
                      setExtraReferrer({ name: item.referrer, id: item.id });
                    }
                  }}
                  disabled={Boolean(id)}
                  referrerId={extraReferrer.id}
                  useSettings
                  width="264px"
                />
              )}

              {showLabIdentifier && (
                <TextField
                  disabled={Boolean(id)}
                  margin="dense"
                  variant="outlined"
                  label={tl("lab.labIdentifier")}
                  fullWidth
                  value={labIdentifier}
                  onChange={(e) => setLabIdentifier(e.target.value)}
                />
              )}
            </Box>
          </>
        )}
        <Box mt={3} width="100%">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5
            }}
          >
            <Typography style={{ fontWeight: 600 }}>{tl("lab.testParticulars")}</Typography>

            <AddLabtests
              width="340px"
              isDisabled={isForSampleTaken}
              isMedicalTest={Boolean(isMedicalTest)}
              labTests={
                labRecordType === "all"
                  ? labTests
                  : labTests.filter((test) => test.type === labRecordType)
              }
              handleModifyLabtests={(v) => {
                setTests(v);
              }}
              handleRemoveLabtest={(i) => {
                const mutatedTests = produce(tests, (draft) => {
                  draft.splice(i, 1);
                });
                setTests(mutatedTests);
              }}
              tests={tests}
              mode={id ? MODE.EDIT : MODE.CREATE}
              allowRemovingTest={!isForSampleTaken}
            />
          </Box>

          {!isMedicalTest && (
            <Box mt={2}>
              <SampleTaken
                value={sampleTaken.isSampleTaken}
                dateAndRemarks={sampleTaken.dateAndRemarks}
                onChange={(value, dateAndRemarks) => {
                  setSampleTaken({ isSampleTaken: value, dateAndRemarks });
                  setTests((prevTests) =>
                    prevTests.map((test) => ({
                      ...test,
                      sampleTaken: value
                    }))
                  );
                }}
                disabled={Boolean(id)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Panel>
  );
};

function mapStateToProps(state: RootState) {
  const resourceCentre =
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;

  const serviceProviders = state.resources.resourceCentreServiceProviders.filter((sp) => sp.active);
  const labTests = state.labSettings.labTestGroups;
  const { resourceCentreId } = state.userContext;
  const { user } = state.userContext;
  const queryClientId = queryString.parse(window.location.search)?.cId;
  const queryClient = state.clients.collection.find(
    (client) => client.id === Number(queryClientId)
  );
  const showLabIdentifier = resourceCentre?.labSettings?.enableLabIdentifier;
  const restrictTestCreation = resourceCentre?.labSettings?.enableTestCreationFromBillOnly;
  const allowClientPictureUpload = resourceCentre?.settings?.clientSettings?.enablePictureUpload;
  const accessingLabCentreIds = resourceCentre?.labSettings?.accessingLabCentreIds;
  const accessingLabCentreSubscribed =
    resourceCentre?.subscriptions?.features?.accessingLab?.subscribed;
  const accessingResourceCentres = state.accessingResourceCentres.collection;

  return {
    user,
    serviceProviders,
    labTests,
    resourceCentreId,
    queryClient,
    showLabIdentifier,
    restrictTestCreation,
    allowClientPictureUpload,
    queryClientId,
    accessingLabCentreIds,
    accessingLabCentreSubscribed,
    accessingResourceCentres
  };
}

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  uploadPhoto: (id, image, next) => dispatch(uploadClientImage(id, image, next)),
  navigateTo: (url) => {
    dispatch(push(url));
  },
  loadLabTests: () => dispatch(getBatchLabTests()),
  postLabTest: (data, createAnother, labRecordType) =>
    dispatch(actions.postLabTestRecord(data, createAnother, labRecordType)),
  loadServiceProviders: (id) =>
    dispatch(spActions.getResourceCentreServiceProviders({ resourceCentreId: id })),
  getClientFromId: (id) => dispatch(getClientById(id)),
  loadAccessingResourceCentres: (ids) => dispatch(getAccessingResourceCentres(ids))
}))(LabTestCreate);

import { Box, Link, Typography } from "@mui/material";
import { push } from "connected-react-router";
import { startCase } from "lodash";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { match as MatchProps } from "react-router";
import { CentralStoreTabs, clearEntry, getStockTransactions } from "../../../actions/stock";
import { getStockProducts } from "../../../actions/stockProductActions";
import { productWithOpeningStock } from "../../../api/stockProducts";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import { stockTransactionImportColumns } from "../../../components/CsvUploader/csvHelpers";
import CsvUploader from "../../../components/CsvUploader/CsvUploader";
import List, { Filters, MultipleHeader } from "../../../components/List";
import { ListActions, Menu, MenuItem } from "../../../components/OList";
import PageControl from "../../../components/PageControl";
import Panel from "../../../components/Panel";
import Search from "../../../components/Search";
import { tl } from "../../../components/translate";
import { ProductInterface, QueryProps } from "../../../interfaces/ProductInterface";
import {
  Status,
  StockItemInterface,
  Supplier,
  TransactionType
} from "../../../interfaces/StockInterfaces";
import { getStockProductFromId } from "../../../reducers/stockProducts";
import { IThunkDispatch, RootState } from "../../../store";
import Can from "../../Policy/Can";
import ProductCreate from "../../Services/ProductCreate";
import EntryButton from "../EntryButton";
import CreateStockProduct, { StockTrxListFilters } from "../StockCreateEdit/CreateStockProduct";
import { multipleHeaders } from "../StockList";
import "../StockTransactionsList.scss";
import StockRecordPayment from "./StockRecordPayment";
import StockTransactionView from "./StockTransactionView";
import StockTrxSearchButton, { ButtonTypes, SearchType } from "./StockTrxSearchButton";
import SupplierShow from "../../Suppliers/SupplierShow";
import useMobileScreen from "../../../hooks/useMobileScreen";
import useKitchenPharmacy from "../../../hooks/useKitchenPharmacy";
import { centralStoreHeaders } from "../CentralStore";

function applyExposeKeys(list, stockProducts) {
  return list.map((item) => ({
    ...item,
    trxItems: item?.stockTransactionItems?.map(
      (trxItem, i) =>
        `${i !== 0 ? ", " : ""}
          ${getStockProductFromId(trxItem.productId, stockProducts)?.name || ""}`
    ),
    items: item.product?.map((a) => a.name),
    supplierName: item.supplier?.name
  }));
}

export const showItemsName = (
  items: StockItemInterface[],
  fontSize = "1rem"
): JSX.Element | string => {
  if (!items) return "";
  return (
    <Box>
      {items?.slice(0, 3).map((item, i) => (
        <Typography
          component="span"
          //  eslint-disable-next-line react/no-array-index-key
          key={`${i}${item?.batchId}`}
          style={{
            fontSize
          }}
        >
          {`${item.productName || ""}`} {i === 2 && items.length > 3 && "..."}
        </Typography>
      ))}
    </Box>
  );
};

interface MatchInterface extends MatchProps {
  params: { id: number };
}

interface StockTransactionsListProps {
  actions: {
    navigateTo: (url: string) => void;
    goto: (url: string) => void;
    loadStockProducts: (query: QueryProps) => Promise<void>;
    loadStockTransactions: (query: QueryProps & { filter: string }) => Promise<void>;
    clearDraft: () => void;
    createSuccessNotification: () => void;
  };
  suppliers: Supplier;
  match: MatchInterface;
  stockProducts: ProductInterface[];
  stockTransactionData: StockItemInterface[];
  totalNoOfTrx: number;
  isCentralStoreTab?: boolean;
}

function downloadStockUploadSample() {
  window.open(
    "https://docs.google.com/spreadsheets/d/1xHWLSFfxfOu2WYsU6lr8U9qjIq9Ocdu7TP_lHLuDXGo",
    "_blank"
  );
}

export const initialQuery = {
  page: 0,
  pageSize: 15,
  search: "",
  filter: "all"
};

export const StockTransactionsList = ({
  actions,
  match,
  suppliers,
  stockProducts,
  stockTransactionData,
  totalNoOfTrx,
  isCentralStoreTab
}: StockTransactionsListProps): JSX.Element => {
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedSupplierId, setSelectedSupplierId] = React.useState(null);
  const [query, setQuery] = React.useState<QueryProps & { filter: string }>({
    ...initialQuery,
    searchType: SearchType.PRODUCT
  });
  const [filter, setFilter] = React.useState(
    isCentralStoreTab ? TransactionType.KITCHEN_PHARMACY_PURCHASE : StockTrxListFilters.ALL
  );
  const isMobile = useMobileScreen();

  const isKitchenPharmacy = Boolean(useKitchenPharmacy());
  const isCentralStore = useSelector(
    (state: RootState) => state.userContext.resourceCentre?.isKitchenPharmacy
  );
  React.useEffect(() => {
    if (
      query?.search?.length > 2 ||
      query?.search?.length === 0 ||
      query?.supplierInvoiceId?.length > 2
    ) {
      actions.loadStockTransactions({ ...query, filter });
    }
  }, [query, actions, filter]);

  React.useEffect(() => {
    actions.clearDraft();
    if (!stockTransactionData.length) {
      actions.loadStockProducts({ page: 0, pageSize: 100 });
    }
  }, [actions]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (stockTransactionData) {
      setSelectedRow(stockTransactionData.find((item) => item.id === Number(match.params.id)));
    }
  }, [match.params.id, stockTransactionData]);

  const [showCreatePanel, setShowCreatePanel] = React.useState(false);
  const [showRecordPayment, setShowRecordPayment] = React.useState<boolean>(false);
  const [showCreateServicePanel, setShowCreateServicePanel] = React.useState<boolean>(false);

  const filtersData = {
    filters: [
      { key: StockTrxListFilters.ALL, title: tl(`stock.all`) },
      { key: StockTrxListFilters.PURCHASE, title: tl(`stock.purchase`) },
      { key: StockTrxListFilters.PURCHASE_RETURN, title: tl(`stock.purchaseReturn`) },
      { key: StockTrxListFilters.EXPIRY_OR_DISCARDMENT, title: tl(`stock.expiryOrDiscardment`) },
      { key: StockTrxListFilters.EXCESS, title: tl(`stock.excess`) },
      { key: StockTrxListFilters.SALES, title: tl(`stock.sales`) },
      { key: StockTrxListFilters.SALES_RETURN, title: tl(`stock.salesReturn`) },
      { key: StockTrxListFilters.OPENING_STOCK, title: tl(`stock.openingStock`) },
      { key: StockTrxListFilters.ADJUSTMENT, title: tl(`stock.adjustment`) },
      { key: StockTrxListFilters.OPENING_STOCK_ADJUSTMENT, title: "Opening Stock Adjustment" }
    ]
  };

  const [selectedOptionButton, setSelectedOptionButton] = React.useState<ButtonTypes>(
    SearchType.PRODUCT
  );

  const getPlaceHolder = (selectedBtn: ButtonTypes) =>
    `Enter ${startCase(selectedBtn)} ${
      selectedBtn === SearchType.SUPPLIER_INVOICE_ID ? "" : "name"
    }`;

  return (
    <Box className="stockTransactionsList">
      <Box height="100%">
        <List
          automation="StockTransactionsList"
          hideCreateButton
          defaultSortColumn="transactionType"
          additionalHeaderContent={
            <Box display="flex" flexDirection="column">
              {isCentralStoreTab ? (
                <MultipleHeader
                  multipleHeaders={centralStoreHeaders(CentralStoreTabs.STOCK_REQUESTED)}
                  useAsSubHeader
                />
              ) : (
                <Filters filter={filter} onFilter={setFilter} filterData={filtersData} />
              )}
            </Box>
          }
          additionalHeaderFilters={
            <Search
              onSearch={(value) => {
                setQuery({
                  ...query,
                  search: value.trim()
                });
              }}
              value={query.search}
              testmation={query.search}
              customDebounce={1000}
              alwaysFocus
              optionButton={
                <StockTrxSearchButton
                  selectedOptionButton={selectedOptionButton}
                  onChange={(option) => {
                    setSelectedOptionButton(option);
                    setQuery({ ...initialQuery, searchType: option, search: "" });
                  }}
                />
              }
              placeHolder={getPlaceHolder(selectedOptionButton)}
            />
          }
          withoutSearch
          isResponsive
          multipleHeaders={multipleHeaders(match, isKitchenPharmacy, isCentralStore)}
          customCreateButton={
            <EntryButton
              navigateTo={actions.navigateTo}
              suppliers={suppliers}
              setShowCreatePanel={setShowCreatePanel}
              setShowRecordPayment={setShowRecordPayment}
              setShowCreateServicePanel={setShowCreateServicePanel}
            />
          }
          columns={[
            {
              key: "items",
              label: tl("stock.items"),
              formatter: (item) => showItemsName(item?.stockTransactionItems, "0.75rem")
            },
            {
              key: "supplierName",
              label: tl("stock.supplier"),
              sortable: true,
              hideInNarrowView: true,
              formatter: ({ supplier }) => (
                <Typography
                  onClick={(event) => {
                    event.stopPropagation();
                    if (supplier && supplier.id !== selectedSupplierId) {
                      setSelectedSupplierId(supplier.id);
                    } else {
                      setSelectedSupplierId(null);
                    }
                  }}
                  sx={supplier ? { textDecoration: "underline" } : {}}
                >
                  {supplier?.name || "-"}
                </Typography>
              )
            },
            {
              key: "transactionType",
              label: tl("stock.transactionType"),
              formatter: ({ transactionType, related }) => (
                <Box>
                  <Typography>{tl(transactionType)}</Typography>
                  {transactionType === TransactionType.PURCHASE_RETURN &&
                    related?.supplierInvoiceId && (
                      <Typography
                        fontSize="small"
                        color="gray"
                      >{`Debit Note for ${related?.supplierInvoiceId}`}</Typography>
                    )}
                </Box>
              )
            },
            {
              key: "status",
              label: tl("stock.status"),
              hideInNarrowView: true,
              sortable: true,
              formatter: (item) => (
                <Box>
                  <Typography
                    style={{
                      padding: "5px",
                      borderRadius: "10px",
                      fontSize: "12px",
                      color: "white",
                      backgroundColor: `${
                        item.status === Status.Finalize || !item.status ? "#009654" : "#A5A5A5"
                      }`
                    }}
                  >
                    {item.status === Status.Finalize || !item.status
                      ? Status.Finalize
                      : Status.Draft}
                  </Typography>
                </Box>
              )
            },
            {
              key: "date",
              label: tl("stock.date"),
              sortable: true,
              formatter: (item) => (
                <Box>
                  <Typography>{calFns.convertADtoBS(new Date(item.date))?.formatted4}</Typography>
                </Box>
              )
            },
            {
              key: "totalAmount",
              label: isMobile ? "Total" : tl("stock.totalAmount"),
              sortable: true,
              formatter: (item) => <Typography>रू {item.totalAmount}</Typography>
            }
          ]}
          data={applyExposeKeys(stockTransactionData || [], stockProducts)}
          isLoading={false}
          data-testmation="stockTransationsListRows"
          activeRow={selectedRow && selectedRow.id}
          onRowClick={({ id }) => {
            const getRedirectPath = (selectedId?) => {
              if (selectedId) {
                return isCentralStoreTab
                  ? `/stock/kitchenPharmacy/${selectedId}?tab=${CentralStoreTabs.STOCK_REQUESTED}`
                  : `/stock/stockTransactions/${selectedId}`;
              }
              return isCentralStoreTab
                ? `/stock/kitchenPharmacy?tab=${CentralStoreTabs.STOCK_REQUESTED}`
                : `/stock/stockTransactions`;
            };
            actions.navigateTo(getRedirectPath(id));
          }}
        >
          <ListActions>
            {() => (
              <Menu>
                <Can policyAccessKey="stock:importCSV">
                  <CsvUploader
                    buttonText="Upload stock transactions (.csv)"
                    columns={stockTransactionImportColumns}
                    requiredFieldInfoText="Note: Medicine Name, Batch No, Package, Unit, Availably Qty, Sales Rate, Purchase Rate are required fields."
                    createDataApi={productWithOpeningStock}
                    useBatchUpload
                    runAfterSave={() => actions.loadStockTransactions(query)}
                    renderAdditionalInfo={() => (
                      <Typography
                        style={{
                          cursor: "pointer",
                          position: "fixed",
                          right: 20
                        }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={downloadStockUploadSample}>{tl("stock.viewSample")}</Link>
                      </Typography>
                    )}
                  />
                  <MenuItem onClick={downloadStockUploadSample}>{tl("stock.viewSample")}</MenuItem>
                </Can>
              </Menu>
            )}
          </ListActions>
        </List>
        <Box className="navigatePage">
          <PageControl
            page={query.page}
            pageSize={query.pageSize}
            onSetPage={(value) => setQuery({ ...query, page: value })}
            maximumDataCount={totalNoOfTrx}
          />
        </Box>
        {showCreatePanel && (
          <Panel onClose={() => setShowCreatePanel(false)} title="Create Product">
            <CreateStockProduct
              productName=""
              handleClose={() => setShowCreatePanel(false)}
              saveAction={() => {
                actions.createSuccessNotification();
                actions.loadStockProducts(query);
              }}
              mode="Create"
            />
          </Panel>
        )}
        {showRecordPayment && <StockRecordPayment onClose={() => setShowRecordPayment(false)} />}
        {showCreateServicePanel && (
          <ProductCreate isAccount handleClose={() => setShowCreateServicePanel(false)} />
        )}
        {selectedRow && (
          <StockTransactionView
            handlePanelClose={() => {
              actions.navigateTo(
                isCentralStoreTab
                  ? `/stock/kitchenPharmacy?tab=${CentralStoreTabs.STOCK_REQUESTED}`
                  : "/stock/stockTransactions"
              );
            }}
            data={selectedRow}
            products={stockProducts || []}
            onSupplierClick={(spId) => setSelectedSupplierId(spId)}
          />
        )}
        {selectedSupplierId && (
          <SupplierShow
            supplierId={selectedSupplierId}
            handleViewClose={() => setSelectedSupplierId(null)}
          />
        )}
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    stockProducts: state.stockProducts.collection,
    stockTransactionData: state.stock.stockTransactions,
    totalNoOfTrx: state.stock.total
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      navigateTo: (url) => dispatch(push(url)),
      goto: (url) => dispatch(push(url)),
      clearDraft: () => dispatch(clearEntry()),
      loadStockProducts: (query: QueryProps) => dispatch(getStockProducts(query)),
      loadStockTransactions: (query: QueryProps & { filter: string }) =>
        dispatch(getStockTransactions(query))
    }
  })
)(StockTransactionsList);

import React from "react";
import {
  BottomNavigation,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Typography
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EmailIcon from "@mui/icons-material/Email";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import MoreIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BillIcon from "../../../assets/icons/Bill.icon";
import FlaskIcon from "../../../assets/icons/Flask.icon";
import BedIcon from "../../../assets/icons/Bed.icon";
import prescriptionIcon from "../../../assets/images/prescription.svg";
import useCan from "../../hooks/useCan";
import { RootState } from "../../store";

enum MoreMenuItems {
  bill = "Bills",
  reports = "Reports",
  calendar = "Calendar",
  lab = "Lab",
  dashboard = "Dashboard",
  opd = "OPD",
  service = "Services",
  clients = "Clients",
  stock = "Stock",
  messaging = "Messaging",
  medical = "Medical",
  ipd = "Ipd",
  hmis = "hmis",
  accounts = "Accounts",
  settings = "Settings"
}

const MenuItems = [
  {
    name: MoreMenuItems.dashboard,
    url: "/dashboard",
    policyKey: "allow",
    isForMoreMenu: false,
    policy: {
      superAdminPassReverse: true,
      supportAccountAdminPass: true
    }
  },
  {
    name: MoreMenuItems.bill,
    url: "/billing/new",
    policyKey: "bill:listBill",
    isForMoreMenu: false,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.calendar,
    url: "/calendar",
    policyKey: ["booking:listBooking", "booking:listSchedule"],
    isForMoreMenu: false,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.lab,
    url: "/lab/labRecords/all?status=Ordered",
    policyKey: "lab:listLab",
    isForMoreMenu: true,
    policy: { superAdminPassReverse: true }
  },
  {
    name: MoreMenuItems.reports,
    url: "/reports",
    policyKey: "report:*",
    isForMoreMenu: false,
    policy: { superAdminPass: true }
  },
  {
    name: MoreMenuItems.opd,
    url: "/assessment/new",
    policyKey: "opd:listOpd",
    isForMoreMenu: true,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.service,
    url: "/services",
    policyKey: "services:listService",
    isForMoreMenu: true,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.clients,
    url: "/clients",
    policyKey: "clients:listClient",
    isForMoreMenu: true,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.stock,
    url: "/stock/stockProducts",
    policyKey: "stock:listStock",
    isForMoreMenu: true,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.messaging,
    url: "/campaign/campaigns",
    policyKey: ["campaign:listCampaign", "campaign:listTemplate", "campaign:listAudience"],
    isForMoreMenu: true,
    policy: {
      partialCheck: true,
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.medical,
    url: "/medical",
    policyKey: "medical:listMedical",
    isForMoreMenu: true,
    policy: {
      superAdminPassReverse: true
    }
  },
  {
    name: MoreMenuItems.ipd,
    url: "/beds",
    policyKey: "ipd:listIpd",
    isForMoreMenu: true,
    policy: { superAdminPassReverse: true }
  },
  {
    name: MoreMenuItems.hmis,
    url: "/hmis/mulDarta",
    policyKey: "hmis:listHmis",
    isForMoreMenu: true,
    policy: { superAdminPassReverse: true }
  },
  {
    name: MoreMenuItems.accounts,
    url: "/accounts/vouchers/sales",
    policyKey: "account:listAccount",
    isForMoreMenu: true,
    policy: { superAdminPassReverse: true }
  },
  {
    name: MoreMenuItems.settings,
    url: "",
    policyKey: "allow",
    isForMoreMenu: true,
    policy: { superAdminPassReverse: true }
  }
];

const MenuIcon = ({ menuItem }) => {
  switch (menuItem) {
    case MoreMenuItems.bill:
      return <BillIcon sx={{ fontSize: "15px" }} />;
    case MoreMenuItems.calendar:
      return <CalendarTodayIcon sx={{ fontSize: "15px" }} />;
    case MoreMenuItems.lab:
      return <FlaskIcon sx={{ fontSize: "15px" }} />;
    case MoreMenuItems.dashboard:
      return <DashboardIcon />;
    case MoreMenuItems.opd:
      return <img src={prescriptionIcon} width="20px" height="20px" alt="Prescription" />;
    case MoreMenuItems.service:
      return <NoteAddIcon />;
    case MoreMenuItems.clients:
      return <PeopleRoundedIcon />;
    case MoreMenuItems.stock:
      return <AllInboxIcon />;
    case MoreMenuItems.messaging:
      return <EmailIcon />;
    case MoreMenuItems.medical:
      return <AddBoxIcon />;
    case MoreMenuItems.reports:
      return <AssessmentIcon sx={{ fontSize: "15px" }} />;
    case MoreMenuItems.ipd:
      return <BedIcon />;
    case MoreMenuItems.hmis:
      return <BedIcon />;
    case MoreMenuItems.accounts:
      return <MonetizationOnIcon />;
    case MoreMenuItems.settings:
      return <SettingsIcon />;
    default:
      return null;
  }
};

const MoreMenuItem = ({ itemUrl, itemName, policy, policyAccessKey, handleClose }) => {
  const dispatch = useDispatch();
  const showMenuItem = useCan(policyAccessKey, policy);
  if (!showMenuItem) return null;
  return (
    <MenuItem
      onClick={() => {
        handleClose();
        dispatch(push(itemUrl));
      }}
    >
      <ListItemIcon>
        <MenuIcon menuItem={itemName} />
      </ListItemIcon>
      {itemName.toUpperCase()}
    </MenuItem>
  );
};

const BottomBar = (): JSX.Element => {
  const [showBottomBar, setShowBottomBar] = React.useState(true);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const resourceCentre = useSelector((state: RootState) => state.userContext.resourceCentre) || {};

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!showBottomBar && (
        <Paper
          sx={{
            position: "fixed",
            bottom: 10,
            left: 0,
            right: 0,
            zIndex: 99999999,
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            marginX: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={() => setShowBottomBar(!showBottomBar)}
          elevation={3}
        >
          <MenuOpenIcon
            sx={{
              color: "#009654"
            }}
          />
        </Paper>
      )}
      <Slide direction="right" in={showBottomBar} unmountOnExit mountOnEnter>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999999,
            backgroundColor: "transparent"
          }}
          elevation={3}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-0.5rem",
              left: "0.1rem",
              height: "20px",
              width: "20px",
              borderRadius: "10px",
              backgroundColor: "#009654",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999
            }}
            onClick={() => {
              setShowBottomBar(false);
              handleClose();
            }}
          >
            <CloseIcon sx={{ color: "#fff", fontSize: "15px" }} />
          </Box>
          <BottomNavigation
            showLabels
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around"
            }}
          >
            {MenuItems.filter(({ isForMoreMenu }) => !isForMoreMenu).map(({ name, url }) => (
              <Box
                key={name}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => dispatch(push(url))}
              >
                <MenuIcon menuItem={name} />
                <Typography fontSize="12px">{name.toUpperCase()}</Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={handleClick}
            >
              <MoreIcon sx={{ fontSize: "15px" }} />
              <Typography fontSize="12px">More</Typography>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="bottom-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClick}
              sx={{
                padding: 10
              }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              {MenuItems.filter(({ isForMoreMenu }) => isForMoreMenu).map(
                ({ name, url, policy, policyKey }) => (
                  <MoreMenuItem
                    key={name}
                    itemName={name}
                    itemUrl={
                      name === MoreMenuItems.settings
                        ? `/resourcecentres/${resourceCentre.id}/details`
                        : url
                    }
                    policy={policy}
                    policyAccessKey={policyKey}
                    handleClose={handleClose}
                  />
                )
              )}
              <MenuItem>Close</MenuItem>
            </Menu>
          </BottomNavigation>
        </Paper>
      </Slide>
    </>
  );
};

export default BottomBar;

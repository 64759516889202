import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResourceCentreUserGroups } from "../api/user";
import { ResourceCentreUserGroups } from "../interfaces/User";

export const getRCUserGroups = createAsyncThunk(
  "resourceCentre/getResourceCentreUserGroups",
  async (resourceCentreId: number) => {
    const response = await getResourceCentreUserGroups(resourceCentreId);
    return response;
  }
);

interface State {
  collection: Array<ResourceCentreUserGroups>;
}

const initialState: State = {
  collection: []
};

const resourceCentreUserGroupsSlice = createSlice({
  name: "resourceCentreUserGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRCUserGroups.fulfilled, (draft, { payload }) => {
      draft.collection = [...payload.globalUserGroups, ...payload.resourceCentreUserGroups];
    });
  }
});

export default resourceCentreUserGroupsSlice.reducer;

import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography } from "@mui/material";
import * as moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import { chain, abs } from "mathjs";
import PrintIcon from "@mui/icons-material/Print";
import * as clientActions from "../../../actions/client";
import List, { EmptyView } from "../../../components/OList";
import { tl } from "../../../components/translate";
import { rupeeDisplay } from "../../../helpers/rupee";
import { CalendarFunctions } from "../../../components/Calendar";
import "./styles.scss";
import { clientBillsSorted } from "../../../reducers/client";
import { getClientBalance } from "../../../api/bill";
import PrintPaymentHistory from "../../Billing/PrintBill/PrintPaymentHistory/PrintPaymentHistory";
import { BillDocumentType, BillStatus } from "../../../interfaces/BillInterfaces";

const ClientBills = ({
  clientId,
  clientBills,
  fetchClientBills,
  shouldFetchBills,
  navigateTo,
  client
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalDue, setTotalDue] = React.useState(0);
  const [balance, setBalance] = React.useState(0);

  React.useEffect(() => {
    fetchClientBills(clientId);
    getClientBalance(clientId).then(({ balance: clientBalance }) => setBalance(clientBalance || 0));
  }, [clientId, shouldFetchBills]);

  React.useEffect(() => {
    let totalDueAmt = 0;
    if (clientBills.length) {
      clientBills.forEach((bill) => {
        const dueAmt = chain(bill.summary?.totalAmount)
          .subtract(bill.paymentInfo?.paidAmount || 0)
          .done();
        totalDueAmt += dueAmt;
      });
    }
    setTotalDue(totalDueAmt);
  }, [clientBills]);
  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        {Boolean(balance) && (
          <>
            <Box display="flex" justifyContent="flex-end" pt="4px" mr={2}>
              <Typography style={{ fontSize: "0.7rem", fontWeight: 600 }}>
                {balance > 0 ? tl("balance.advance") : tl("balance.due")} :{" "}
                {rupeeDisplay(abs(balance))}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" pt="4px" ml={2}>
              <PrintPaymentHistory
                mode="clientBills"
                client={client}
                clientBills={clientBills}
                clientBalance={balance}
                buttonText={<PrintIcon htmlColor="black" style={{ cursor: "pointer" }} />}
              />
            </Box>
          </>
        )}
      </Box>
      <Box className="clientBills">
        <List
          automation="clientBills"
          rowHeight={40}
          title="clientBills"
          data={clientBills}
          adjustHeightToContents
          onRowClick={(row) => {
            navigateTo(`/billing/bills/${row.id}`);
          }}
          columns={[
            {
              key: "billNumber",
              label: tl("billing.billNumber"),
              sortable: true,

              formatter: (row) => (
                <Typography>
                  <Box fontSize="0.7rem">{row.isDraft ? "-" : row.billNumber}</Box>
                </Typography>
              )
            },
            {
              key: "date",
              label: tl("billing.issueDate"),
              formatter: ({ issueDate }) => (
                <Typography>
                  <Box fontSize="0.7rem">
                    {CalendarFunctions.convertADtoBS(moment(issueDate)).formatted3}
                    {", "}
                    {CalendarFunctions.convertADtoBS(moment(issueDate)).bsYear}
                  </Box>
                </Typography>
              ),
              sortable: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sortFunction: (rowA, rowB) =>
                moment(rowB.issueDate).unix() - moment(rowA.issueDate).unix()
            },
            {
              key: "total",
              label: tl("billing.total"),
              sortable: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sortFunction: (a, b) => Number(a.total) - Number(b.total),
              formatter: ({ total }) => (
                <Typography>
                  <Box fontSize="0.7rem">{rupeeDisplay(total)}</Box>
                </Typography>
              )
            },
            {
              key: "paidAmount",
              label: tl("billing.paidAmount"),
              cellRenderer: (row) => {
                if (row.isDraft) {
                  return null;
                }
                const paymentInfo = row.paymentInfo || {
                  paid: true,
                  paidAmount: row.summary.totalAmount
                };
                return (
                  <Typography>
                    <Box fontSize="0.7rem" display="flex">
                      {!paymentInfo?.paid && rupeeDisplay(paymentInfo.paidAmount)}
                      {paymentInfo?.paid && <CheckIcon color="primary" />}
                    </Box>
                  </Typography>
                );
              }
            },
            {
              key: "balance",
              label: tl("billing.balance"),
              sortable: true,
              cellRenderer: (row) => (
                <Box display="flex">
                  <Typography style={{ fontSize: "0.7rem" }}>
                    {rupeeDisplay(
                      chain(row.summary?.totalAmount)
                        .subtract(row.paymentInfo?.paidAmount || 0)
                        .done()
                    )}
                  </Typography>
                </Box>
              )
            }
          ]}
        >
          <EmptyView>
            <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
              No bills issued yet for the client...
            </Box>
          </EmptyView>
        </List>
      </Box>
    </Box>
  );
};

/**
 * Before 2022-12-28 date, when the bill was cancelled then related receipts are deleted
 * from database table.
 * After this date, when the bill was cancelled then only bill's status will change and
 * credit note against that bill will made.
 * It will be OK to hide cancelled bill before this date, but now have to show
 * cancelled bill as well as Credit Note.
 */
export const shouldIncludeCancelledBill = (bill: BillDocumentType): boolean => {
  if (moment(bill.issueDate).isAfter(moment("2022-12-28").endOf("day"))) {
    return true;
  }
  return bill.status !== BillStatus.cancelled;
};

export default connect(
  (state, { clientId, client }) => ({
    clientBills: clientBillsSorted(state, clientId).filter((bill) =>
      shouldIncludeCancelledBill(bill)
    ),
    client: client || state.clients.collection.find(({ id }) => id === clientId)
  }),
  (dispatch) => ({
    fetchClientBills: (clientId) => dispatch(clientActions.getClientBills(clientId)),
    navigateTo: (url) => dispatch(push(url))
  })
)(ClientBills);

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useDispatch } from "react-redux";
import React from "react";
import isPlainObject from "lodash/isPlainObject";
import hasOwnProperty from "../../../../helpers/object";
import { Test } from "../../../../interfaces/Lab";
import { updateLabMasterDataRanges } from "../../../../api/labTestSettings";
import { notificationAdd } from "../../../../actions/notification";

const { useState, useEffect } = React;

interface MaxMinRangesProps {
  onChange: (v: Range) => void;
  value: Range;
}

enum ModalActions {
  SAVE = "save",
  CANCEL = "cancel"
}

function MaxMinRanges({ onChange, value }: MaxMinRangesProps): JSX.Element {
  return (
    <div>
      <TextField
        label="Min"
        style={{ marginLeft: "16px" }}
        onChange={(e) => onChange({ ...value, min: e.target.value })}
        value={value.min}
        data-testmation="minRange"
      />
      <TextField
        label="Max"
        onChange={(e) => onChange({ ...value, max: e.target.value })}
        value={value.max}
        data-testmation="maxRange"
      />
    </div>
  );
}

interface Range {
  min: string;
  max: string;
}

export interface MaleFemaleChildRange {
  male: Range;
  female: Range;
  children: Range;
}

export interface DefaultRange {
  default: Range;
}

export interface SpecialRange {
  other: Range & { label: string };
}

interface Props {
  ranges: MaleFemaleChildRange | DefaultRange | SpecialRange | Record<string, never>;
  onUpdate: (v: this["ranges"]) => void;
  buttonText?: string;
  labTest?: Test;
  testGroupId?: number;
  isSubTest?: boolean;
  showUpdateMasterDataConfirmation?: boolean;
}

enum Status {
  SUCCESS = "success",
  ERROR = "error"
}

function sanitizedMFCRangeState(ranges: MaleFemaleChildRange): MaleFemaleChildRange {
  return {
    ...ranges,
    male: ranges?.male || { min: "", max: "" },
    female: ranges?.female || { min: "", max: "" },
    children: ranges?.children || { min: "", max: "" }
  };
}

function sanitizedDefaultRangeState(ranges: DefaultRange): DefaultRange {
  return {
    ...ranges,
    default: ranges?.default || { min: "", max: "" }
  };
}

function sanitizedSpecialRangeState(ranges: SpecialRange): SpecialRange {
  return {
    ...ranges,
    other: {
      label: ranges.other?.label || "",
      max: ranges.other?.max || "",
      min: ranges.other?.min || ""
    }
  };
}

export function handleRangesSanitization(
  ranges: Props["ranges"] = {} as Props["ranges"]
): Props["ranges"] {
  if (isPlainObject(ranges) && Object.keys(ranges).length === 0) {
    return {};
  }
  if (hasOwnProperty(ranges, "default")) {
    return sanitizedDefaultRangeState(ranges as DefaultRange);
  }
  if (hasOwnProperty(ranges, "male")) {
    return sanitizedMFCRangeState(ranges as MaleFemaleChildRange);
  }
  if (hasOwnProperty(ranges, "other")) {
    return sanitizedSpecialRangeState(ranges as SpecialRange);
  }
  return sanitizedDefaultRangeState(ranges as DefaultRange);
}

export interface RangeEditProps {
  labTest: Test;
  isSubTest: boolean;
}

export function AdvancedRangesEdit(props: Props): JSX.Element {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    ranges,
    onUpdate,
    buttonText,
    showUpdateMasterDataConfirmation,
    testGroupId,
    isSubTest,
    labTest
  } = props;
  const [selectedRangedType, setSelectedRangeType] = useState<
    "disableRanges" | "default" | "mfc" | "other"
  >("default");

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [panicValues, setPanicValues] = React.useState({
    min: "",
    max: ""
  });

  const dispatch = useDispatch();

  const [trackedValues] = React.useState(ranges);

  const handleSelectedRange = (rangeValues) => {
    if (hasOwnProperty(rangeValues, "default")) {
      setSelectedRangeType("default");
    }
    if (hasOwnProperty(rangeValues, "male")) {
      setSelectedRangeType("mfc");
    }
    if (hasOwnProperty(rangeValues, "other")) {
      setSelectedRangeType("other");
    }
    if (isPlainObject(rangeValues) && Object.keys(rangeValues).length === 0) {
      setSelectedRangeType("disableRanges");
    }
    if (hasOwnProperty(rangeValues, "panicValues")) {
      setPanicValues(rangeValues.panicValues);
    }
  };
  useEffect(() => {
    handleSelectedRange(ranges);
  }, []);

  const updateRangeOnSave = (rangeValues) => {
    if (selectedRangedType === "disableRanges") {
      onUpdate({});
    }
    if (selectedRangedType === "default") {
      onUpdate({
        default: rangeValues.default || { min: "", max: "" }
      });
    }
    if (selectedRangedType === "mfc") {
      onUpdate({
        male: rangeValues?.male || { min: "", max: "" },
        female: rangeValues?.female || { min: "", max: "" },
        children: rangeValues?.children || { min: "", max: "" }
      });
    }
    if (selectedRangedType === "other") {
      onUpdate({
        other: {
          label: rangeValues.other?.label || "",
          max: rangeValues.other?.max || "",
          min: rangeValues.other?.min || ""
        }
      });
    }
  };

  const handleTypeChange = (type: "default" | "mfc" | "other" | "disableRanges") => {
    if (type === "disableRanges") onUpdate({});
    if (type === "default") {
      onUpdate(sanitizedDefaultRangeState(ranges as DefaultRange));
    }
    if (type === "mfc") {
      onUpdate(sanitizedMFCRangeState(ranges as MaleFemaleChildRange));
    }
    if (type === "other") {
      onUpdate(sanitizedSpecialRangeState(ranges as SpecialRange));
    }
  };

  const handleCloseModal = (action = ModalActions.CANCEL): void => {
    if (action === "cancel") {
      handleSelectedRange(trackedValues);
      onUpdate(trackedValues);
    } else {
      updateRangeOnSave(ranges);
    }
    setOpenModal(false);
  };

  const showNotification = (variant, errorMsg?) => {
    dispatch(
      notificationAdd({
        id: new Date().getTime(),
        message:
          variant === Status.SUCCESS
            ? "Successfully updated master data."
            : errorMsg || "Sorry, Couldn't update ranges.",
        variant,
        autoTimeout: true
      })
    );
  };

  const updateLabMasterData = async () => {
    try {
      await updateLabMasterDataRanges(
        {
          isSubTest,
          labTest
        } as RangeEditProps,
        testGroupId
      );
      showNotification(Status.SUCCESS);
      setOpenDialog(false);
      setOpenModal(false);
    } catch (error) {
      showNotification(Status.ERROR, error?.data?.message);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip arrow title="Edit ranges">
        <Button variant="text" onClick={() => setOpenModal(true)} color="primary">
          {selectedRangedType === "default" && hasOwnProperty(ranges, "default") ? (
            <>
              <Typography>Min: {ranges?.default?.min}</Typography>
              <Typography>Max: {ranges?.default?.max}</Typography>
            </>
          ) : (
            buttonText
          )}
        </Button>
      </Tooltip>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal(ModalActions.CANCEL)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Edit Lab Ranges</DialogTitle>
        <Box p={3}>
          <Box display="flex" alignItems="center">
            <Typography>Ranges</Typography>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                onChange={() => {
                  setSelectedRangeType("disableRanges");
                  handleTypeChange("disableRanges");
                }}
                checked={selectedRangedType === "disableRanges"}
              />
              <Typography>Disable Ranges</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                onChange={() => {
                  setSelectedRangeType("default");
                  handleTypeChange("default");
                }}
                checked={selectedRangedType === "default"}
                data-testmation="defaultRange"
              />
              <Typography>Default Ranges</Typography>
            </Box>
            {selectedRangedType === "default" && (
              <Box p={3}>
                <Typography style={{ textDecoration: "underline", fontWeight: 600 }}>
                  Default:
                </Typography>
                {hasOwnProperty(ranges, "default") && (
                  <MaxMinRanges
                    onChange={(v) => {
                      onUpdate({ ...ranges, default: v });
                    }}
                    value={ranges.default}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                onChange={() => {
                  setSelectedRangeType("mfc");
                  handleTypeChange("mfc");
                }}
                checked={selectedRangedType === "mfc"}
              />
              <Typography>Male Female Children Ranges</Typography>
            </Box>
            {selectedRangedType === "mfc" && (
              <Box p={3}>
                <Box>
                  <Typography style={{ textDecoration: "underline", fontWeight: 600 }}>
                    Male
                  </Typography>
                  {hasOwnProperty(ranges, "male") &&
                    hasOwnProperty(ranges, "female") &&
                    hasOwnProperty(ranges, "children") && (
                      <MaxMinRanges
                        onChange={(v) => {
                          onUpdate({
                            ...ranges,
                            male: v,
                            female: ranges.female,
                            children: ranges.children
                          });
                        }}
                        value={ranges.male}
                      />
                    )}
                </Box>
                <Box mt="16px">
                  <Typography style={{ textDecoration: "underline", fontWeight: 600 }}>
                    Female
                  </Typography>
                  {hasOwnProperty(ranges, "male") &&
                    hasOwnProperty(ranges, "female") &&
                    hasOwnProperty(ranges, "children") && (
                      <MaxMinRanges
                        onChange={(v) => {
                          onUpdate({
                            ...ranges,
                            male: ranges.male,
                            female: v,
                            children: ranges.children
                          });
                        }}
                        value={ranges.female}
                      />
                    )}
                </Box>
                <Box mt="16px">
                  <Typography style={{ textDecoration: "underline", fontWeight: 600 }}>
                    Children
                  </Typography>
                  {hasOwnProperty(ranges, "male") &&
                    hasOwnProperty(ranges, "female") &&
                    hasOwnProperty(ranges, "children") && (
                      <MaxMinRanges
                        onChange={(v) => {
                          onUpdate({
                            ...ranges,
                            male: ranges.male,
                            female: ranges.female,
                            children: v
                          });
                        }}
                        value={ranges.children}
                      />
                    )}
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                onChange={() => {
                  setSelectedRangeType("other");
                  handleTypeChange("other");
                }}
                checked={selectedRangedType === "other"}
              />
              <Typography>Special Ranges</Typography>
            </Box>
            {selectedRangedType === "other" && (
              <Box p={3}>
                <Box>
                  <Typography style={{ textDecoration: "underline", fontWeight: 600 }}>
                    Special
                  </Typography>
                  {hasOwnProperty(ranges, "other") && (
                    <TextField
                      label="Label"
                      placeholder="Type your content here..."
                      style={{ width: "340px" }}
                      value={ranges.other.label}
                      multiline
                      rows={8}
                      onChange={(e) =>
                        onUpdate({
                          ...ranges,
                          other: { ...ranges.other, label: e.target.value }
                        })
                      }
                    />
                  )}
                  {hasOwnProperty(ranges, "other") && (
                    <MaxMinRanges
                      value={ranges.other}
                      onChange={(v) => {
                        onUpdate({
                          ...ranges,
                          other: { ...v, label: ranges.other.label }
                        });
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}
            {selectedRangedType !== "disableRanges" && (
              <Box mt={3}>
                <Box display="flex" alignItems="center">
                  <Typography>Panic Values</Typography>
                </Box>
                <Box p={3}>
                  <MaxMinRanges
                    onChange={(v) => {
                      setPanicValues(v);
                      onUpdate({
                        ...ranges,
                        panicValues: v
                      });
                    }}
                    value={panicValues}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <DialogActions>
          <Button onClick={() => handleCloseModal(ModalActions.CANCEL)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (showUpdateMasterDataConfirmation) {
                setOpenDialog(true);
              } else {
                handleCloseModal(ModalActions.SAVE);
              }
            }}
            autoFocus
            color="primary"
            variant="contained"
            data-testmation="saveRanges"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Do you want to update the Master Data as well?</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenModal(false);
              setOpenDialog(false);
            }}
            data-testmation="updateRangeOption"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateRangeOnSave(ranges);
              updateLabMasterData();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

AdvancedRangesEdit.defaultProps = {
  buttonText: "Edit",
  labTest: null,
  isSubTest: false,
  showUpdateMasterDataConfirmation: false
};

export default AdvancedRangesEdit;

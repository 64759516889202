import React from "react";
import { Box } from "@mui/material";
import { getSettingBasedDate } from "../LabPrintFunctions";
import LabGovPrintInfoField from "./LabGovPrintInfoField";
import { LabPrintLabInfoProps } from "./LabPrintLabInfo";

const LabGovPrintLabInfo: React.FC<LabPrintLabInfoProps> = ({
  dateSetting,
  showPrintedOnTime,
  showReportOnTime,
  showSampleTakenTime,
  showSampleTakenDate,
  showReportOnDate,
  showPrintedOnDate,
  collectionDate,
  sampleRemarks,
  resultDate,
  modifiedDate
}) => (
  <Box flexGrow={1}>
    {showSampleTakenDate && collectionDate && (
      <LabGovPrintInfoField
        label="Sample Collected"
        data={getSettingBasedDate(dateSetting, collectionDate, showSampleTakenTime)}
      />
    )}
    {sampleRemarks && <LabGovPrintInfoField label="Sample remarks" data={sampleRemarks} />}
    {showReportOnDate && resultDate && (
      <LabGovPrintInfoField
        label="Reported On"
        data={getSettingBasedDate(dateSetting === "BS" ? "BS" : "AD", resultDate, showReportOnTime)}
      />
    )}
    {showReportOnDate && resultDate && (
      <LabGovPrintInfoField
        label=""
        data={getSettingBasedDate(dateSetting === "BS" ? "AD" : "BS", resultDate, showReportOnTime)}
      />
    )}
    {modifiedDate && (
      <LabGovPrintInfoField
        label="Modified Date"
        data={getSettingBasedDate(dateSetting === "BS" ? "AD" : "BS", modifiedDate, true)}
      />
    )}
    {showPrintedOnDate && (
      <LabGovPrintInfoField
        label="Printed On"
        data={getSettingBasedDate(dateSetting, new Date().toISOString(), showPrintedOnTime)}
      />
    )}
  </Box>
);

export default LabGovPrintLabInfo;

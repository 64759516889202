/* eslint-disable no-restricted-globals */
import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography, TextField, FormControlLabel, Checkbox } from "@mui/material";
import queryString from "query-string";
import Autocomplete from "@mui/material/Autocomplete";
import { chain, round } from "mathjs";
import Close from "@mui/icons-material/Close";
import "./ServiceList.scss";
import produce from "immer";
import styles from "./Service.module.css";
import Footer from "./Footer";
import CommonServiceFields from "./CommonServiceFields";
import Panel from "../../components/Panel";
import { t, tl } from "../../components/translate";
import { removeGeneralErrorByKey } from "../../actions/error";
import * as NotificationActions from "../../actions/notification";
import { getServices, createServices, updateServices, getService } from "../../actions";
import { calculateServicePriceExVAT } from "./ProductCreate";
import Can from "../Policy/Can";
import { IThunkDispatch } from "../../store";
import { ServiceInterface } from "../../interfaces/ServiceInterface";
import { ProductInterface } from "../../interfaces/ProductInterface";
import DepartmentSelect from "../../components/DepartmentSelect";
import ProductsTable from "./ProductsTable";
import { commonErrorMessage } from "../../helpers/messages";

interface CreatePackageInterface {
  actions: {
    goBack: (isPublicTab: boolean, packageId: number | string) => void;
    navigateTo: (url) => void;
    createService: ({
      data,
      createNewAfter,
      isPublicTab,
      onSuccess
    }: {
      data: Partial<ProductInterface>;
      createNewAfter: boolean;
      isPublicTab: boolean;
      onSuccess: () => void;
    }) => void;
    updateService: ({
      data,
      isPublicTab
    }: {
      data: Partial<ProductInterface>;
      isPublicTab: boolean;
    }) => void;
    getService: (id) => void;
    loadServices: () => void;
  };
  services: ServiceInterface[];
  packageId: number;
  service: ServiceInterface;
  handleClose: () => void;
  isPublicTab?: boolean;
}

export interface PackageDataProps {
  id?: number;
  resourceCentreId?: number;
  packageName: string;
  packagePrice: number;
  servicePriceExVAT: number;
  vatPct: number;
  isTaxable: boolean;
  products: ProductInterface[];
  refTotalExcVAT: number;
  refTotalIncVAT: number;
  departmentId?: number;
  publicBooking?: boolean;
  publicDescription?: string;
  instructions?: string;
  referenceId?: number;
  handledByOkhati?: boolean;
  assignedRc?: number;
  document: {
    products: ProductInterface[];
  };
}

const CreatePackage: React.FC<CreatePackageInterface> = (props) => {
  const { services, actions, packageId, service, handleClose, isPublicTab = false } = props;

  const [packageData, setPackageData] = React.useState<PackageDataProps>({
    packageName: "",
    packagePrice: 0,
    servicePriceExVAT: 0,
    vatPct: 0,
    isTaxable: false,
    products: [],
    refTotalExcVAT: 0,
    refTotalIncVAT: 0,
    publicBooking: false,
    publicDescription: "",
    instructions: "",
    referenceId: null,
    resourceCentreId: null,
    handledByOkhati: false,
    document: {
      products: []
    }
  });

  const [saving, setSaving] = React.useState(false);
  const [serviceList, setServiceList] = React.useState(null);
  const [showDepartmentField, setShowDepartmentField] = React.useState(false);
  const [packageClinicPriceError, setPackageClinicPriceError] = React.useState("");

  React.useEffect(() => {
    let filteredServices = services.filter(
      (item: ServiceInterface) => Boolean(item.active) && item.productType === "single"
    );
    filteredServices = isPublicTab
      ? filteredServices.filter((item) => item.publicBooking)
      : filteredServices.filter((item) => !item.publicBooking);
    setServiceList(filteredServices);
  }, [services, isPublicTab]);

  React.useEffect(() => {
    if (packageId) {
      actions.getService(packageId);
    }
  }, [packageId, actions]);

  React.useEffect(() => {
    const isExistingService = packageId && service && Object.keys(service).length > 0;
    if (isExistingService) {
      const serviceProducts = service.document.products;
      let refTotalExcVAT = 0;
      let refTotalIncVAT = 0;
      (serviceProducts || []).forEach((item) => {
        refTotalExcVAT = round(
          chain(refTotalExcVAT)
            .add(item.vatPct !== 0 ? item.servicePriceExcVAT : item.grossTotalPrice || 0)
            .done(),
          2
        );
        refTotalIncVAT = round(
          chain(refTotalIncVAT)
            .add(item.grossTotalPrice || 0)
            .done(),
          2
        );
      });
      setPackageData({
        packageName: service.name,
        packagePrice: service.grossTotalPrice ? service.grossTotalPrice : 0,
        servicePriceExVAT:
          service.vatPct !== 0 ? service.servicePriceExcVAT : service.grossTotalPrice || 0,
        vatPct: service.vatPct ? service.vatPct : 0,
        isTaxable: false,
        products: serviceProducts,
        refTotalExcVAT,
        refTotalIncVAT,
        departmentId: service.departmentId,
        publicBooking: service.publicBooking,
        publicDescription: service.publicDescription || "",
        instructions: service.instructions || "",
        referenceId: service.referenceId,
        resourceCentreId: service.resourceCentreId,
        handledByOkhati: service.handledByOkhati,
        document: service.document
      });
    }
  }, [service, packageId]);

  const [createNewAfter, setCreateNewAfter] = React.useState(false);

  const createNewAfterToggler = () => {
    setCreateNewAfter(!createNewAfter);
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location && queryString.parse(location.search).createAnotherAfterSave) {
      setCreateNewAfter(true);
    } else {
      setCreateNewAfter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const resetForm = () => {
    setPackageData({
      packageName: "",
      packagePrice: 0,
      vatPct: 0,
      servicePriceExVAT: 0,
      isTaxable: false,
      products: [],
      refTotalExcVAT: 0,
      refTotalIncVAT: 0,
      document: {
        products: []
      }
    });
  };

  const onSuccess = () => {
    resetForm();
    actions.loadServices();
  };

  const savePackage = async () => {
    setSaving(true);
    if (packageId) {
      const data = {
        name: packageData.packageName,
        grossTotalPrice: packageData.packagePrice,
        vatPct: packageData.vatPct ? packageData.vatPct : 0,
        servicePriceExcVAT: packageData.servicePriceExVAT ? packageData.servicePriceExVAT : 0,
        productType: "package",
        products: packageData.products,
        departmentId: packageData.departmentId,
        publicDescription: packageData.publicDescription,
        instructions: packageData.instructions,
        publicBooking: packageData.publicBooking,
        resourceCentreId: packageData.resourceCentreId,
        referenceId: packageData.referenceId,
        handledByOkhati: packageData.handledByOkhati,
        document: packageData.document
      };
      if (isPublicTab && !packageData?.resourceCentreId) {
        await actions.createService({
          data: { ...data, referenceId: packageId, publicBooking: packageData.publicBooking },
          createNewAfter,
          isPublicTab,
          onSuccess
        });
      } else {
        await actions.updateService({
          data: { ...data, id: packageId },
          isPublicTab
        });
      }
      setPackageClinicPriceError("");
    } else {
      const data = {
        name: packageData.packageName,
        grossTotalPrice: packageData.packagePrice,
        vatPct: packageData.vatPct ? packageData.vatPct : 0,
        servicePriceExcVAT: packageData.servicePriceExVAT ? packageData.servicePriceExVAT : 0,
        productType: "package",
        products: packageData.products,
        departmentId: packageData.departmentId,
        publicDescription: packageData.publicDescription,
        instructions: packageData.instructions,
        publicBooking: packageData.publicBooking,
        handledByOkhati: packageData.handledByOkhati,
        document: packageData.document
      };
      await actions.createService({
        data: { ...data, referenceId: packageId, publicBooking: packageData.publicBooking },
        createNewAfter,
        isPublicTab,
        onSuccess
      });
      setPackageClinicPriceError("");
    }
    setSaving(false);
  };
  const [showAutoComplete, setShowAutoComplete] = React.useState(true);

  const footer = (
    <Footer
      editable={!packageId}
      saveFn={savePackage}
      saveDisabled={
        saving || !(packageData.packageName?.length > 0 && packageData.products?.length > 0)
      }
      goBack={() => {
        if (handleClose && !packageId) {
          handleClose();
        }
        actions.goBack(isPublicTab, packageId);
      }}
      createNewAfter={createNewAfter}
      createNewAfterToggler={createNewAfterToggler}
      importing={isPublicTab && !packageData?.resourceCentreId && packageData?.publicBooking}
    />
  );

  return (
    <Can policyAccessKey="services:createPackage">
      <Panel
        title={packageId ? tl("services.editPackage") : tl("services.createPackage")}
        onClose={() => {
          if (handleClose) {
            handleClose();
          }
          actions.goBack(isPublicTab, packageId);
        }}
        footer={footer}
      >
        <Box p={5} style={{ width: "100%" }} className={styles.boxHeight}>
          <Box>
            <Typography>
              <Box fontWeight="600" pb={2}>
                {tl("services.productUnderPackage")}
              </Box>
            </Typography>
          </Box>
          {showAutoComplete && (
            <Box gap={2} display="flex">
              <Autocomplete
                fullWidth
                id="combo-box"
                disabled={isPublicTab}
                options={
                  packageData.products?.length > 0
                    ? serviceList.filter((option) => {
                        const productsId = packageData.products.map((product) => product.id);
                        return !productsId.includes(option.id);
                      })
                    : serviceList
                }
                getOptionLabel={(option) => option.name}
                value={null}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    margin="dense"
                    variant="outlined"
                    data-testmation="packageCreateDropDown"
                    label={tl("services.findAddProducts")}
                    placeholder={t("services.findAddProducts")}
                    InputLabelProps={{ shrink: true }}
                    value=""
                    sx={{ flexGrow: 1 }}
                  />
                )}
                renderOption={(renderProps, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...renderProps} key={option.id} data-testmation="productDropdownItem">
                    {option.name}
                  </li>
                )}
                onChange={(e, value) => {
                  if (value) {
                    const refTotalExcVAT = round(
                      chain(packageData.refTotalExcVAT)
                        .add(
                          value.vatPct !== 0 ? value.servicePriceExcVAT : value.grossTotalPrice || 0
                        )
                        .done(),
                      2
                    );
                    const refTotalIncVAT = round(
                      chain(packageData.refTotalIncVAT)
                        .add(value.grossTotalPrice || 0)
                        .done(),
                      2
                    );
                    const refVatPct = round(
                      chain(chain(refTotalIncVAT).subtract(refTotalExcVAT).done())
                        .divide(refTotalExcVAT)
                        .multiply(100)
                        .done(),
                      2
                    );

                    setPackageData({
                      ...packageData,
                      products: [...packageData.products, { id: value.id } as ProductInterface],
                      refTotalExcVAT,
                      refTotalIncVAT,
                      servicePriceExVAT: refTotalExcVAT,
                      packagePrice: refTotalIncVAT,
                      vatPct: refVatPct,
                      document: {
                        products: [...packageData.document.products, value]
                      }
                    });
                    setShowAutoComplete(false);
                    setTimeout(() => setShowAutoComplete(true), 0);
                  }
                }}
              />
            </Box>
          )}
          {packageData.document &&
            packageData.document.products &&
            packageData.document.products?.length > 0 && (
              <ProductsTable
                packageData={packageData}
                setPackageData={setPackageData}
                isPublicTab={isPublicTab}
                services={services}
              />
            )}
          <Box pt={1}>
            <CommonServiceFields
              fieldData={packageData}
              isPublicTab={isPublicTab}
              packageClinicPriceError={packageClinicPriceError}
              setPackageClinicPriceError={setPackageClinicPriceError}
              onUpdate={(updates: { [key: string]: string } = {}) => {
                const updatedState = produce(updates, (draft) => {
                  const updatedKeys = Object.keys(draft);
                  if (updatedKeys.includes("vatPct") || updatedKeys.includes("packagePrice")) {
                    if (
                      updatedKeys.includes("packagePrice") &&
                      Number(updates.packagePrice) > packageData.refTotalIncVAT &&
                      !isPublicTab
                    ) {
                      draft.packagePrice = String(packageData.refTotalIncVAT);
                    }
                    draft.servicePriceExVAT = updatedKeys.includes("vatPct")
                      ? calculateServicePriceExVAT(packageData.packagePrice, draft.vatPct)
                      : calculateServicePriceExVAT(draft.packagePrice, packageData.vatPct);
                  }
                });
                setPackageData({ ...packageData, ...updatedState });
              }}
            />
            {isPublicTab && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={packageData.publicBooking}
                    onChange={() =>
                      setPackageData({
                        ...packageData,
                        publicBooking: !packageData.publicBooking
                      })
                    }
                  />
                }
                label="Public Booking"
              />
            )}
            <Box>
              {!showDepartmentField && !packageData.departmentId && (
                <Typography
                  className={styles.addServiceProvider}
                  component="span"
                  onClick={() => setShowDepartmentField(true)}
                >
                  Add department
                </Typography>
              )}
              {(showDepartmentField || packageData.departmentId) && (
                <Box display="flex" alignItems="center" mt={2}>
                  <DepartmentSelect
                    style={{ width: "310px", marginTop: "8px" }}
                    id={packageData.departmentId}
                    onChange={(department) =>
                      setPackageData({ ...packageData, departmentId: department.id })
                    }
                    placeholderText="Select department"
                  />
                  <Close
                    onClick={() => setShowDepartmentField(false)}
                    sx={{ cursor: "pointer", marginLeft: "15px" }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Panel>
    </Can>
  );
};

function mapStateToProps(state, ownProps) {
  const allServices = [
    ...(ownProps.isPublicTab ? state?.publicServices?.collection : []),
    ...state.services.collection
  ];
  const selectedService = allServices.find((s) => ownProps.packageId === s.id);
  const services = selectedService
    ? allServices.filter((service) => service.id !== selectedService.id)
    : allServices;
  return { services, service: selectedService };
}

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  actions: {
    goBack: (isPublicTab, packageId) => {
      const basePath = isPublicTab ? `/publicServices` : `/services`;
      const path = packageId ? `${basePath}/package/${packageId}` : basePath;
      dispatch(push(path));
    },
    navigateTo: (url) => {
      dispatch(push(url));
    },
    createService: async ({ data, createNewAfter, isPublicTab, onSuccess }) => {
      await dispatch(async (innerDispatch, getState) => {
        await innerDispatch(createServices(data));
        const currentState = getState();
        const errorObject = currentState.error.filter(({ key }) => key === "CREATE_SERVICES");
        const rootPath = isPublicTab ? "/publicServices" : "/services";
        if (errorObject.length) {
          innerDispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorObject[0].message || commonErrorMessage,
              autoTimeout: true
            })
          );
          innerDispatch(removeGeneralErrorByKey("CREATE_SERVICES"));
        } else {
          innerDispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("services.successPackageCreation"),
              autoTimeout: true
            })
          );
          onSuccess();
          if (createNewAfter) {
            innerDispatch(push(`${rootPath}/newPackage/?createNewAfterSave=${createNewAfter}`));
          } else {
            innerDispatch(push(`${rootPath}/package/${currentState.services.lastTouched.id}`));
          }
        }
      });
    },
    updateService: async ({ data, isPublicTab }) => {
      await dispatch(async (innerDispatch, getState) => {
        await innerDispatch(updateServices(data));
        const currentState = getState();
        const errorObject = currentState.error.filter(({ key }) => key === "UPDATE_SERVICES");
        const rootPath = isPublicTab ? "/publicServices" : "/services";
        if (errorObject.length) {
          innerDispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorObject[0].message || commonErrorMessage,
              autoTimeout: true
            })
          );
          innerDispatch(removeGeneralErrorByKey("UPDATE_SERVICES"));
        } else {
          innerDispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("services.successPackageUpdate"),
              autoTimeout: true
            })
          );
          innerDispatch(push(`${rootPath}/package/${currentState.services.lastTouched.id}`));
        }
      });
    },
    getService: (id) => dispatch(getService(id)),
    loadServices: () => dispatch(getServices())
  }
}))(CreatePackage);

CreatePackage.defaultProps = {
  isPublicTab: false
};

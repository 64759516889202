import { Chip } from "@mui/material";
import React from "react";

export default function UserChip({ allowed }: { allowed: boolean }): JSX.Element {
  return (
    <div>
      <Chip
        size="small"
        label={`${allowed ? "Allowed" : "Not Allowed"}`}
        color={`${allowed ? "success" : "default"}`}
      />
    </div>
  );
}

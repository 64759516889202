import produce from "immer";
import { ReducerBuilder } from "./ReducerBuilder";
import * as HmisActions from "../actions/hmis";
import { HMIS_PAGE_SIZE } from "../containers/Hmis/constants";

const INITIAL_STATE = {
  collection: [],
  mulDarta: { results: [], total: 0 },
  opdRegister: { results: [], total: 0 },
  imnci241: { results: [], total: 0 },
  imnci242: { results: [], total: 0 },
  formData: [],
  summary: []
};

const setMulDarta = (state, payload) => ({ ...state, mulDarta: payload });

const setOpdRegister = (state, payload) => ({ ...state, opdRegister: payload });

const setImnci241 = (state, payload) => (payload ? { ...state, imnci241: payload } : state);

const setImnci242 = (state, payload) => (payload ? { ...state, imnci242: payload } : state);

const postImnci241 = (state, payload) => {
  if (!payload) return state;
  return produce(state, (draft) => {
    if (draft.imnci241.results.length >= HMIS_PAGE_SIZE) {
      draft.imnci241.results.pop();
    }
    draft.imnci241.results.unshift(payload);
    draft.imnci241.total += 1;
  });
};

const postImnci242 = (state, payload) => {
  if (!payload) return state;
  return produce(state, (draft) => {
    if (draft.imnci242.results.length >= HMIS_PAGE_SIZE) {
      draft.imnci242.results.pop();
    }
    draft.imnci242.results.unshift(payload);
    draft.imnci242.total += 1;
  });
};

const setAllHmis = (state, payload) => ({ ...state, collection: payload });

const setHmis = (state, payload) =>
  produce(state, (draft) => {
    draft.collection = draft.collection.filter((item) => item.id !== payload.id);
  });

function postHmis(state, payload) {
  return produce(state, (draft) => {
    draft.collection.push(payload);
  });
}

const getHmisFormData = (state, payload) => ({ ...state, formData: payload });

const getHmisSummary = (state, payload) => ({ ...state, summary: payload });

const postSyncHmisSummary = (state, payload) => {
  if (!payload) return state;
  return produce(state, (draft) => {
    const index = draft.summary.findIndex((item) => Number(item.id) === Number(payload.id));
    if (index !== -1) draft.summary[index].syncDate = payload.syncDate;
  });
};

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(HmisActions.Type.GET_IMNCI_241, setImnci241)
  .mapAction(HmisActions.Type.GET_IMNCI_242, setImnci242)
  .mapAction(HmisActions.Type.POST_IMNCI_241, postImnci241)
  .mapAction(HmisActions.Type.POST_IMNCI_242, postImnci242)
  .mapAction(HmisActions.Type.GET_MUL_DARTA, setMulDarta)
  .mapAction(HmisActions.Type.GET_OPD_REGISTER, setOpdRegister)
  .mapAction(HmisActions.Type.GET_ALL_HMIS, setAllHmis)
  .mapAction(HmisActions.Type.GET_HMIS_BY_ID, setHmis)
  .mapAction(HmisActions.Type.POST_HMIS, postHmis)
  .mapAction(HmisActions.Type.GET_HMIS_FORM_DATA, getHmisFormData)
  .mapAction(HmisActions.Type.GET_HMIS_SUMMARY, getHmisSummary)
  .mapAction(HmisActions.Type.POST_SYNC_HMIS_SUMMARY, postSyncHmisSummary)
  .build();
export default reducer;

import React from "react";

export const NasalDrops = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 215.562 215.562"
    >
      <path
        d="M88.318,59.706l12.073-52.67c2.151-9.382,12.628-9.382,14.779,0l12.073,52.67H88.318z M141.276,78.425v-5.27
	c0-1.902-1.547-3.449-3.449-3.449H77.735c-1.902,0-3.449,1.547-3.449,3.449v5.27c0,1.902,1.547,3.449,3.449,3.449h60.092
	C139.729,81.874,141.276,80.328,141.276,78.425z M147.923,118.019v83.045c0,8.007-6.49,14.497-14.497,14.497H82.135
	c-8.006,0-14.497-6.49-14.497-14.497v-83.045c0-5.687,2.864-10.698,7.227-13.684c2.624-1.796,4.074-4.875,4.074-8.055v-4.406h57.683
	v4.406c0,3.18,1.45,6.259,4.074,8.055C145.059,107.322,147.923,112.333,147.923,118.019z M137.564,130.373H77.997v35.667h59.567
	V130.373z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import { Claim, ClaimBooking } from "../interfaces/ssf";
import { ACCIDENT_SUB_PRODUCT, MEDICAL_SUB_PRODUCT, SCHEME_TYPE } from "../../map/ssf";

export const isSsfIdValid = (str: string): boolean =>
  /^\d{11}$/.test(str) || /^[SIJK]\d{11}$/.test(str);

// ssf code mapping for all medical products
export const SSF_MEDICINE_CODE = "ADJ02";

export const getTotalBookedAmount = ({
  bookings,
  subProduct,
  scheme
}: {
  scheme: { value: number; label: string };
  subProduct: { value: number; label: string };
  bookings: ClaimBooking[];
}): number =>
  bookings.reduce((total, item) => {
    const response = item.response[0];

    if (scheme.value === 2) {
      const medicalBooking = response.medical.find(
        (med) => med.type === subProduct.label && med.bookedAmount > 0
      );
      if (medicalBooking) {
        // eslint-disable-next-line no-param-reassign
        total += medicalBooking.bookedAmount;
      }
    }

    if (scheme.value === 1) {
      const accidentBooking = response.accident.find((acc) => acc.bookedAmount > 0);
      if (accidentBooking) {
        // eslint-disable-next-line no-param-reassign
        total += accidentBooking.bookedAmount;
      }
    }
    return total;
  }, 0);

export const getSchemeFromClaim = ({
  data
}: {
  data: Claim;
}): {
  scheme: { value: number; label: string };
  subProduct: { value: number; label: string };
} => {
  const scheme = data.scheme === 1 ? SCHEME_TYPE.ACCIDENT.label : SCHEME_TYPE.MEDICAL.label;
  let subProduct = "";
  if (data.scheme === 2) {
    subProduct =
      data.subProduct === 1 ? MEDICAL_SUB_PRODUCT.IPD.label : MEDICAL_SUB_PRODUCT.OPD.label;
  } else if (data.scheme === 1) {
    subProduct =
      data.subProduct === 14
        ? ACCIDENT_SUB_PRODUCT.WORK_RELATED.label
        : ACCIDENT_SUB_PRODUCT.NON_WORK_RELATED.label;
  }

  return {
    scheme: { value: data.scheme, label: scheme },
    subProduct: { value: data.subProduct, label: subProduct }
  };
};

export enum ATTACHMENT_TYPE {
  BILL = "BILL",
  OPD_PRESCRIPTION = "OPD_PRESCRIPTION",
  DISCHARGE_SUMMARY = "DISCHARGE_SUMMARY",
  LAB_REPORT = "LAB_REPORT"
}

import { Imnci241Base, Imnci242Base } from "app/containers/Hmis/interfaces";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/hmis";
import { commonErrorMessage, errorFetchMessage, MODULE } from "../helpers/messages";
import { notificationAdd } from "./notification";
import { AppThunk, IThunkDispatch } from "../store";
import { HMIS } from "../interfaces/HmisInterface";
import { HmisQueryParams, FORM_TYPE } from "../api/hmis";

export enum Type {
  GET_MUL_DARTA = "GET_MUL_DARTA",
  GET_OPD_REGISTER = "GET_OPD_REGISTER",
  GET_IMNCI_241 = "GET_IMNCI_241",
  GET_IMNCI_242 = "GET_IMNCI_242",
  POST_IMNCI_241 = "POST_IMNCI_241",
  POST_IMNCI_242 = "POST_IMNCI_242",
  GET_ALL_HMIS = "GET_ALL_HMIS",
  GET_HMIS_BY_ID = "GET_HMIS_BY_ID",
  POST_HMIS = "POST_HMIS",
  GET_HMIS_FORM_DATA = "GET_HMIS_FORM_DATA",
  GET_HMIS_SUMMARY = "GET_HMIS_SUMMARY",
  POST_SYNC_HMIS_SUMMARY = "POST_SYNC_HMIS_SUMMARY",
  PATCH_HMIS = "PATCH_HMIS",
  DELETE_HMIS = "DELETE_HMIS"
}

export const getOpdRegister =
  ({ page, pageSize }: HmisQueryParams): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch(
      actionCreatorAsync(Type.GET_OPD_REGISTER, async () => api.getOpdRegister({ page, pageSize }))
    );

export const getMulDarta =
  ({ page, pageSize }: HmisQueryParams): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch(
      actionCreatorAsync(Type.GET_MUL_DARTA, async () => api.getMulDarta({ page, pageSize }))
    );

export const getImnci241 =
  ({ page, pageSize }: HmisQueryParams): AppThunk =>
  async (dispatch: IThunkDispatch) =>
    dispatch(
      actionCreatorAsync(Type.GET_IMNCI_241, async () => {
        try {
          const response = await api.getImnci({
            page,
            pageSize,
            form: FORM_TYPE.IMNCI241
          });
          return response;
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.message || errorFetchMessage(MODULE.IMNCI_REGISTER),
              autoTimeout: true
            })
          );
        }
        return null;
      })
    );

export const getImnci242 =
  ({ page, pageSize }: HmisQueryParams): AppThunk =>
  async (dispatch: IThunkDispatch) =>
    dispatch(
      actionCreatorAsync(Type.GET_IMNCI_242, async () => {
        try {
          const response = await api.getImnci({
            page,
            pageSize,
            form: FORM_TYPE.IMNCI242
          });
          return response;
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.message || errorFetchMessage(MODULE.IMNCI_REGISTER),
              autoTimeout: true
            })
          );
        }
        return null;
      })
    );

export const postImnci241 =
  ({ data, afterSave }: { data: Imnci241Base; afterSave: () => void }): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch(
      actionCreatorAsync(Type.POST_IMNCI_241, async () => {
        try {
          const response = await api.createImnci({ ...data, form: FORM_TYPE.IMNCI241 });
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Successfully created",
              autoTimeout: true
            })
          );
          afterSave();
          return response;
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.message || errorFetchMessage(MODULE.IMNCI_REGISTER),
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );

export const postImnci242 =
  ({ data, afterSave }: { data: Imnci242Base; afterSave: () => void }): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch(
      actionCreatorAsync(Type.POST_IMNCI_242, async () => {
        try {
          const response = await api.createImnci({ ...data, form: FORM_TYPE.IMNCI242 });
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Successfully created",
              autoTimeout: true
            })
          );
          afterSave();
          return response;
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.message || errorFetchMessage(MODULE.IMNCI_REGISTER),
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );

export const getHmisById =
  (id: number): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch(actionCreatorAsync(Type.GET_HMIS_BY_ID, async () => api.getHmisById(id)));

export const postHmis =
  (hmisData: HMIS) =>
  (dispatch: IThunkDispatch): Promise<void> => {
    dispatch(
      actionCreatorAsync(Type.POST_HMIS, async () => {
        const data = await api.postHmis(hmisData);
        return data;
      })
    );
  };

export const patchHmis =
  (id: number, data: HMIS): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch(actionCreatorAsync(Type.PATCH_HMIS, async () => api.patchHmis(id, data)));

export const deleteHmis =
  (id: number): AppThunk =>
  (dispatch) => {
    dispatch(actionCreatorAsync(Type.DELETE_HMIS, async () => api.deleteHmis(id)));
  };

export const getHmisFormData = (): AppThunk => (dispatch) => {
  dispatch(actionCreatorAsync(Type.GET_HMIS_FORM_DATA, async () => api.getHmisFormData()));
};

export const getHmisSummary = (): AppThunk => (dispatch) => {
  dispatch(actionCreatorAsync(Type.GET_HMIS_SUMMARY, async () => api.getHmisSummary()));
};

export const postSyncHmisSummary =
  (id: number): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.POST_SYNC_HMIS_SUMMARY, async () => {
        try {
          const response = await api.postSyncHmisSummary(id);
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: commonErrorMessage,
              variant: "success",
              autoTimeout: true
            })
          );
          return response;
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: error?.data?.message || commonErrorMessage,
              variant: "error",
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );
  };

import { Get, Post, Delete, Patch } from './apiHelper';

const feedRoot = '/api/feed';

export async function getFeeds(query?) {
  const response = await Get(`${feedRoot}`, true, { ...query });
  return response.data;
}

export async function getFeed(feedId) {
  const response = await Get(`${feedRoot}/${feedId}`, true);
  return response.data;
}

export async function postDraft(draft) {
  const response = await Post(`${feedRoot}`, draft, true);
  return response.data;
}

export async function patchDraft(draftId, draft) {
  const response = await Patch(`${feedRoot}/${draftId}`, draft, true);
  return response.data;
}

export async function publishFeed(feedId) {
  const response = await Patch(`${feedRoot}/${feedId}/publish`, {}, true);
  return response.data;
}

export async function deactiveFeed(feedId) {
  const response = await Patch(`${feedRoot}/${feedId}/deactivate`, {}, true);
  return response.data;
}

export async function deleteDraft(feedId) {
  const response = await Delete(`${feedRoot}/${feedId}`, true);
  return response.data;
}

export async function uploadBannerImg(img) {
  const response = await Post(
    `${feedRoot}/image/upload`,
    img,
    true,
    {},
    {
      'Content-Type': 'multipart/form-data'
    }
  );
  return response.data;
}

export async function deleteBannerImg(imageUrl) {
  const response = await Delete(`${feedRoot}/image/upload?imgUrl=${imageUrl}`, true);
  return response.data;
}

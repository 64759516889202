import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as moment from "moment";
import Popover from "@mui/material/Popover";
import { IconButton } from "@mui/material";
import Calendar, { CalendarFunctions as calFns, CalendarData as calData } from "../Calendar";
import styles from "./style.module.css";
import { t, tl } from "../translate";

interface PropsType {
  date: moment.Moment | Date;
  onChange: (date: moment.Moment) => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  nepaliDate: {
    fontSize: "18px",
    fontWeight: 700
  },
  englishDate: {
    fontSize: "15px",
    fontWeight: 400
  }
});

const DatePickers: React.FC<PropsType> = ({ date, onChange }) => {
  const classes = useStyles();
  const today = moment();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedDate, setSelectedDate] = React.useState(moment(date) || moment());
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const anchorEl = React.useRef(null);

  const next = () => {
    setSelectedDate(selectedDate.clone().add(1, "d"));
  };

  const previous = () => {
    setSelectedDate(selectedDate.clone().subtract(1, "d"));
  };

  const onClose = () => {
    setOpenCalendar(false);
    anchorEl.current = null;
  };

  const onDateClick = (e) => {
    setOpenCalendar(true);
    anchorEl.current = e.currentTarget;
  };

  React.useEffect(() => {
    setOpenCalendar(false);
    onChange(selectedDate);
  }, [selectedDate]);

  return (
    <Box display="flex" alignItems="center" flexDirection={isSmScreen ? "row" : "column"}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={previous}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box className={styles.dateDisplay} onClick={(e) => onDateClick(e)}>
          <Typography className={classes.nepaliDate}>
            {`${calFns.convertADtoBS(selectedDate).formatted5}, ${
              calData.bsDaysFull(t)[selectedDate.day()]
            }`}
          </Typography>
          <Typography className={classes.englishDate}>
            {selectedDate.format("Do MMMM YYYY")}
          </Typography>
        </Box>
        <Popover
          id="render-props-popover"
          open={openCalendar}
          anchorEl={anchorEl.current}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Calendar
            defaultDate={selectedDate.toDate()}
            onChange={(clickedDate) => setSelectedDate(moment(clickedDate))}
          />
        </Popover>
        <IconButton onClick={next}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      {!selectedDate.isSame(today, "day") && (
        <Button
          className={styles.gotoToday}
          variant="outlined"
          size="small"
          onClick={() => setSelectedDate(moment(today))}
        >
          {tl("dashboard.selectToday")}
        </Button>
      )}
    </Box>
  );
};

export default DatePickers;

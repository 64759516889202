import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store";
import { getUserAccesingRcs } from "../../../slices/userBranchSlice";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";

export default function BranchMultiSelect({
  onChange,
  initialValue
}: {
  onChange: (ids: number[]) => void;
  initialValue: number[];
}): JSX.Element {
  const [value, setValue] = React.useState([]);
  const [rcOptions, setRcOptions] = React.useState<Partial<Array<ResourceCentre>>>([]);

  const resourceCentres: Array<ResourceCentre> =
    useSelector((state: RootState) => state.userBranch.collection) || [];
  const currRc: ResourceCentre = useSelector(
    (state: RootState) => state.userContext?.resourceCentre
  );

  React.useEffect(() => {
    const isAssignedToOwnRc = resourceCentres.some((rc) => rc.id === currRc.id);
    if (!isAssignedToOwnRc) {
      setRcOptions([...resourceCentres, currRc]);
    } else {
      setRcOptions(resourceCentres);
    }
  }, [currRc, resourceCentres]);

  React.useEffect(() => {
    if (initialValue?.length) {
      const mappedRcs = rcOptions.filter((rc) =>
        Boolean(initialValue.some((val) => val === rc.id))
      );
      setValue(mappedRcs);
    }
  }, [initialValue, rcOptions]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!resourceCentres?.length) {
      dispatch(getUserAccesingRcs());
    }
  }, [resourceCentres?.length, dispatch]);

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_, newValue) => {
        if (newValue?.length <= 0) {
          return;
        }
        setValue(newValue);
        onChange(newValue.map(({ id }) => id));
      }}
      options={rcOptions || []}
      getOptionLabel={(option) => `${option.name}`}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.id}
            label={`${option.name}`}
            //   eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ marginTop: "8px" }}
      renderInput={(params) => (
        //   eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} fullWidth label="Select Branch" />
      )}
    />
  );
}

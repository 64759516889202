import { Box, Button } from "@mui/material";
import React from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import StatefulButton from "../../../../components/StatefulButton/StatefulButton";
import ServiceProviderSelect from "../../../../components/ServiceProviderSelect/ServiceProviderSelect";
import { ReverseMap } from "../../../../helpers/types";
import { useAppDispatch } from "../../../../store/hooks";
import * as hmisActions from "../../../../actions/hmis";
import { CONFIRMATION, MUAC_OPTIONS, OUTCOME, PANELS, RESULT_OPTIONS } from "../../constants";
import { Imnci241Base } from "../../interfaces";
import CollapsableInputs from "./CollapsableInputs";

interface Props {
  onClose: () => void;
  mulDartaId?: number;
  clientId?: number;
}

export type PanelsType = ReverseMap<typeof PANELS>;

const ImnciAboveTwoMonthsForm = ({ onClose, mulDartaId, clientId }: Props): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FieldValues>({
    defaultValues: {
      serviceProvider: null,
      weight: "",
      temperature: "",
      referredBy: "",
      generalDangerSign: CONFIRMATION.NO,
      gdsUnableToDrink: false,
      vomiting: false,
      gdsLethargicUnconscious: false,
      convulsion: false,
      acuteRespiratoryInfection: CONFIRMATION.NO,
      ariDays: "",
      respiratoryRate: "",
      chestIndrawing: false,
      striodor: false,
      wheezing: false,
      diarrhoea: CONFIRMATION.NO,
      diarrhoeaDays: "",
      diarrhoeaUnableToDrink: false,
      diarrhoeaLethargicUnconscious: false,
      sunkenEyes: false,
      blood: false,
      drinksEagerly: false,
      irritable: false,
      skinPinchSlowly: false,
      skinPinchVerySlowly: false,
      fever: CONFIRMATION.NO,
      feverDays: "",
      stiffNeck: false,
      nasalDischarge: false,
      generalRash: false,
      hazyCornea: false,
      pusFromEyeOralUlcer: false,
      falciparum: false,
      nonFalciparum: false,
      malariaRisk: CONFIRMATION.NO,
      microscopic: CONFIRMATION.NO,
      rdt: CONFIRMATION.NO,
      earInfection: CONFIRMATION.NO,
      earInfectionDays: "",
      earPain: false,
      earDischarge: false,
      tenderSwellingBehindTheEar: false,
      // nutrition options
      severeWasting: false,
      oedemaFeet: false,
      normalWeight: false,
      lowWeight: false,
      veryLowWeight: false,
      somePallor: false,
      severePallor: false,
      muac: MUAC_OPTIONS.GREEN,
      majorClassification: "",
      numberOfClassification: "",
      icd: null,
      medicines: "",
      regularFollowUp: false,
      intermediateVisit: false,
      food: false,
      fluid: false,
      treatmentOutcome: OUTCOME.IMPROVED,
      referredToId: null,
      followUpDate: null,
      result: RESULT_OPTIONS.IMPROVED,
      remarks: ""
    }
  });

  const { generalDangerSign, acuteRespiratoryInfection, diarrhoea, earInfection, fever, icd } =
    watch();

  const dispatch = useAppDispatch();

  const onSubmit = (data: FieldValues) => {
    const postData: Imnci241Base = {
      clientId,
      muldartaId: mulDartaId,
      serviceProviderId: data.serviceProvider.id,
      referredToId: data.referredToId,
      document: {
        vitalsAndReferral: {
          weight: data.weight,
          temperature: data.temperature,
          referredBy: data.referredBy
        },
        patientSignAndSymptoms: {
          generalDangerSign: {
            value: data.generalDangerSign === CONFIRMATION.YES,
            options: {
              unableToDrink: data.gdsUnableToDrink,
              vomiting: data.vomiting,
              lethargicUnconscious: data.gdsLethargicUnconscious,
              convulsion: data.convulsion
            }
          },
          acuteRespiratoryInfection: {
            value: data.acuteRespiratoryInfection === CONFIRMATION.YES,
            options: {
              days: data.ariDays,
              respiratoryRate: data.respiratoryRate,
              chestIndrawing: data.chestIndrawing,
              striodor: data.striodor,
              wheezing: data.wheezing
            }
          },
          diarrhoea: {
            value: data.diarrhoea === CONFIRMATION.YES,
            options: {
              days: data.diarrhoeaDays,
              unableToDrink: data.diarrhoeaUnableToDrink,
              lethargicUnconscious: data.diarrhoeaLethargicUnconscious,
              sunkenEyes: data.sunkenEyes,
              blood: data.blood,
              drinksEagerly: data.drinksEagerly,
              irritable: data.irritable,
              skinPinchSlowly: data.skinPinchSlowly,
              skinPinchVerySlowly: data.skinPinchVerySlowly
            }
          },
          fever: {
            value: data.fever === CONFIRMATION.YES,
            options: {
              days: data.feverDays,
              stiffNeck: data.stiffNeck,
              nasalDischarge: data.nasalDischarge,
              generalRash: data.generalRash,
              hazyCornea: data.hazyCornea,
              pusFromEyeOralUlcer: data.pusFromEyeOralUlcer,
              falciparum: data.falciparum,
              nonFalciparum: data.nonFalciparum,
              malariaRisk: data.malariaRisk === CONFIRMATION.YES,
              microscopic: data.microscopic === CONFIRMATION.YES,
              rdt: data.rdt === CONFIRMATION.YES
            }
          },
          earInfection: {
            value: data.earInfection === CONFIRMATION.YES,
            options: {
              days: data.earInfectionDays,
              earPain: data.earPain,
              earDischarge: data.earDischarge,
              tenderSwellingBehindTheEar: data.tenderSwellingBehindTheEar
            }
          },
          nutritionStatus: {
            severeWasting: data.severeWasting,
            oedemaFeet: data.oedemaFeet,
            normalWeight: data.normalWeight,
            lowWeight: data.lowWeight,
            veryLowWeight: data.veryLowWeight,
            somePallor: data.somePallor,
            severePallor: data.severePallor,
            muac: data.muac
          }
        },
        classificationAndCode: {
          majorClassification: data.majorClassification,
          numberOfClassification: data.numberOfClassification,
          icd: data.icd
            ? {
                diagnosis: data.icd.diagnosis,
                icdCode: data.icd.code || data.icd.icdCode
              }
            : null
        },
        medicines: data.medicines,
        treatmentOutcome: data.treatmentOutcome,
        counsellingToMother: {
          regularFollowUp: data.regularFollowUp,
          intermediateVisit: data.intermediateVisit,
          food: data.food,
          fluid: data.fluid
        },
        followUp: {
          date: data.followUpDate,
          result: data.result
        },
        remarks: data.remarks
      }
    };
    return dispatch(hmisActions.postImnci241({ data: postData, afterSave: onClose }));
  };

  return (
    <div>
      <Box
        component="form"
        id="opdRegisterForm"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pb: 2
        }}
      >
        <Controller
          name="serviceProvider"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field: { onChange, value }, fieldState }) => (
            <ServiceProviderSelect
              serviceProvider={value}
              onChange={onChange}
              fullWidth
              error={{
                value: Boolean(fieldState.error),
                message: fieldState.error?.message || ""
              }}
            />
          )}
        />
        <CollapsableInputs
          control={control}
          errors={errors}
          setValue={setValue}
          showGeneralDangerSign={generalDangerSign === CONFIRMATION.YES}
          showAcuteRespiratoryInfection={acuteRespiratoryInfection === CONFIRMATION.YES}
          showDiarrhoea={diarrhoea === CONFIRMATION.YES}
          showEarInfection={earInfection === CONFIRMATION.YES}
          showFever={fever === CONFIRMATION.YES}
          icd={icd}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 4,
          px: 4,
          py: 2,
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <Button data-testmation="hmisCancel" onClick={onClose}>
          Cancel
        </Button>
        <StatefulButton
          data-testmation="mulDartaSave"
          type="submit"
          form="opdRegisterForm"
          variant="contained"
          color="primary"
          circularProgressProps={{ size: 16 }}
          isLoading={isSubmitting}
          disabled={isSubmitting || !clientId}
        >
          Save
        </StatefulButton>
      </Box>
    </div>
  );
};

export default ImnciAboveTwoMonthsForm;

import React from "react";
import produce from "immer";
import { Box, Checkbox, Typography, Grid } from "@mui/material";
import { tl, t } from "../../../components/translate";
import styles from "../style.module.css";
import { SettingsType } from "./index";
import HtmlTooltip from "../../../components/HtmlTooltip/index";
import { ResourceCentreActionsType } from "../../../actions";

interface Props {
  rcSettings: SettingsType;
  setRcSettings: React.Dispatch<React.SetStateAction<SettingsType>>;
  actions: ResourceCentreActionsType;
}

const EmailSettings = ({ rcSettings, setRcSettings, actions }: Props): JSX.Element => (
  <Grid container>
    <Grid item xs={12}>
      <Box pl={2} width="100%" display="flex" flexWrap="wrap" gap={4}>
        <Typography fontSize="14px" fontWeight="600" flexBasis="100px">
          {tl("resourceCentre.sendEmailFor")}
        </Typography>
        <Box display="flex" flexDirection="column" rowGap="8px">
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.creation}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.creation = !draft.settings.emailSettings.creation;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingCreation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.confirmation}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.confirmation =
                      !draft.settings.emailSettings.confirmation;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingConfirmation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.cancellation}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.cancellation =
                      !draft.settings.emailSettings.cancellation;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingCancellation")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              className={styles.optPadding}
              size="small"
              checked={rcSettings.settings.emailSettings.assessment}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.assessment =
                      !draft.settings.emailSettings.assessment;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.assessment")}
          </Box>

          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.serviceProviders}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.serviceProviders =
                      !draft.settings.emailSettings.serviceProviders;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.serviceProviderBookingCreationAndDataChange")}
          </Box>

          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.bookingConfirmationSP}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.bookingConfirmationSP =
                      !draft.settings.emailSettings.bookingConfirmationSP;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.serviceProviderBookingConfirmation")}
          </Box>

          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.labTests}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.labTests = !draft.settings.emailSettings.labTests;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.labTestUpdates")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.labTestsDispatched}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.labTestsDispatched =
                      !draft.settings.emailSettings.labTestsDispatched;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.labTestDispatched")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.campaign}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.emailSettings.campaign = !draft.settings.emailSettings.campaign;
                });
                actions.putResourceCentre(newState);
              }}
            />
            {tl("resourceCentre.campaign")}
          </Box>

          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.referrer}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.emailSettings.referrer = !draft.settings.emailSettings.referrer;
                });
                actions.putResourceCentre(newState);
              }}
            />
            {tl("resourceCenter.referrer")}
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              className={styles.optPadding}
              size="small"
              checked={rcSettings.settings.emailSettings.remindClient}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.remindClient =
                      !draft.settings.emailSettings.remindClient;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingReminderToClient")}
            <HtmlTooltip description={tl("resourceCentreEmail.bookingReminder")} />
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              className={styles.optPadding}
              size="small"
              checked={rcSettings.settings.emailSettings.nextDaySummarySP}
              onChange={() => {
                setRcSettings((prevState) => {
                  const newState = produce(prevState, (draft) => {
                    draft.settings.emailSettings.nextDaySummarySP =
                      !draft.settings.emailSettings.nextDaySummarySP;
                  });
                  actions.putResourceCentre(newState);
                  return newState;
                });
              }}
            />
            {tl("resourceCentre.bookingSummaryToSP")}
            <HtmlTooltip description={t("resourceCentreEmail.bookingSummary")} />
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.salesSummary}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.emailSettings.salesSummary =
                    !draft.settings.emailSettings.salesSummary;
                });
                actions.putResourceCentre(newState);
              }}
            />
            {tl("resourceCenter.salesSummary")}
            <HtmlTooltip description={tl("resourceCenterEmail.salesSummary")} />
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.publicBooking}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.emailSettings.publicBooking =
                    !draft.settings.emailSettings.publicBooking;
                });
                actions.putResourceCentre(newState);
              }}
            />
            Send For Public Booking
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              className={styles.optPadding}
              checked={rcSettings.settings.emailSettings.billReminder}
              onChange={() => {
                const newState = produce(rcSettings, (draft) => {
                  draft.settings.emailSettings.billReminder =
                    !draft.settings.emailSettings.billReminder;
                });
                actions.putResourceCentre(newState);
              }}
            />
            Send For Bill Reminders
          </Box>
        </Box>
      </Box>
    </Grid>
  </Grid>
);

export default EmailSettings;

import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Route } from "react-router";
import { Box, Button, Menu, Divider, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { tl, t } from "../../components/translate";
import styles from "./Lab.module.css";
import LabTestCreate from "./LabTestCreate/LabTestCreate";
import ClientSearch from "../../components/ClientSearch";
import { clientSearchSecondaryLabel } from "../../models/Client";

import DebouncedTextField from "../../components/DebouncedTextField";
import MedicalLabList from "./MedicalLabList";
import { getMedicalTest } from "../../actions/medicalTest";
import MedicalDetails from "./MedicalDetails";
import EnterTestResult from "./EnterTestResult";

interface LabInterface {
  navigateTo: (path) => void;
  loadMedicalTest: (testId?, clientId?) => void;
}

const useStyles = makeStyles((theme) => ({
  settingPosition: {
    width: "350px",
    display: "flex",
    marginBottom: "0px",
    justifyContent: "flex-end",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.down(undefined)]: {
      width: "180px",
      marginRight: "15px"
    }
  },
  searchBox: {
    width: "300px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    height: "35px",
    borderRadius: "5px",
    transition: "width .5s,background 1s ease",
    backgroundColor: "#ececec",
    [theme.breakpoints.down("sm")]: {
      width: "240px"
    }
  },
  focused: {
    width: "200px",
    backgroundColor: "#ececec"
  },
  searchIcon: {
    position: "absolute",
    width: "40px"
  },
  clientSearch: {
    width: "200px",
    marginTop: "5px",
    [theme.breakpoints.down(undefined)]: {
      width: "180px"
    }
  },
  clientSearchFocused: {
    paddingLeft: "35px"
  },
  container: {
    [theme.breakpoints.down(undefined)]: {
      height: "90px",
      flexDirection: "column",
      marginBottom: "10px"
    }
  },
  label: {
    fontSize: "0.8rem",
    padding: "2px 0px",
    [theme.breakpoints.down(undefined)]: {
      fontSize: "11px"
    }
  }
}));

export const SearchBox = (): JSX.Element => {
  const [testId, setTestId] = React.useState();
  const [client, setClient] = React.useState(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const openSearchMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setTestId("");
    setClient(null);
  };
  const closeSearchMenu = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    dispatch(getMedicalTest(testId, client?.id));
  }, [testId, client?.id]);

  const searchComponentMapper = {
    LABTEST: {
      label: tl("lab.testId"),
      component: (
        <Box ml="3" display="flex">
          <DebouncedTextField
            value={testId}
            placeholder="Search ..."
            inputProps={{ style: { marginLeft: "35px", marginTop: "4px" } }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{ disableUnderline: true }}
            onChange={(e) => {
              setTestId(e.target.value);
            }}
            debounceAt={300}
          />
          {testId && (
            <IconButton
              aria-label="clear"
              size="small"
              onClick={() => setTestId("")}
              style={{ padding: 0 }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      )
    },
    CLIENT: {
      label: tl("lab.client"),
      component: (
        <ClientSearch
          className={`${classes.clientSearch} ${classes.clientSearchFocused}`}
          client={client}
          setClient={setClient}
          margin="none"
          placeholder="Search..."
          secondaryText={[
            {
              type: clientSearchSecondaryLabel.CUSTOMER_NUMBER
            },
            {
              type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER,
              label: t("client.externalIdentifier.short")
            }
          ]}
        />
      )
    }
  };
  const menuItems = Object.keys(searchComponentMapper);
  const [active, setActive] = React.useState(menuItems[0]);

  const toggleSearch = (
    <Box>
      <Button style={{ width: "80px" }} onClick={openSearchMenu}>
        {searchComponentMapper[active].label} <ExpandMoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeSearchMenu}>
        {menuItems.map((item) => (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setActive(item);
            }}
            key={item}
          >
            {searchComponentMapper[item].label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );

  return (
    <Box className={`${classes.searchBox}`}>
      <SearchIcon className={classes.searchIcon} />
      <Box>{searchComponentMapper[active].component}</Box>
      <Divider style={{ height: 20, margin: 4 }} orientation="vertical" />
      <Box>{toggleSearch}</Box>
    </Box>
  );
};

const Lab: React.FC<LabInterface> = ({ navigateTo, loadMedicalTest }) => {
  React.useEffect(() => {
    loadMedicalTest();
  }, []);

  return (
    <Box className={styles.labRoot}>
      <Box>
        <Box>
          <MedicalLabList />
        </Box>
        <Route exact path="/medical/create" render={() => <LabTestCreate />} />
        <Route
          exact
          path="/medical/details/:id"
          render={(props) => (
            <MedicalDetails handleClose={() => navigateTo("/medical")} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/medical/entry/:id"
          render={(props) => (
            <EnterTestResult
              handleClose={() => navigateTo("/medical")}
              id={props.match.params.id}
            />
          )}
        />
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => {
    dispatch(push(path));
  },
  loadMedicalTest: (testId?, clientId?) => {
    dispatch(getMedicalTest(testId, clientId));
  }
});

export default connect(null, mapDispatchToProps)(Lab);

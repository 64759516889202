import React from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { match as MatchProps } from "react-router";
import PurchaseEntry from "../PurchaseEntry/PurchaseEntry";
import { Mode } from "../../../interfaces/StockInterfaces";
import { RootState } from "../../../store";
import { notificationAdd } from "../../../actions/notification";
import { getBill } from "../../../api/bill";
import { productsGetByReferenceIds } from "../../../api/stockProducts";
import { ProductInterface } from "../../../interfaces/ProductInterface";

const KitchenPharmacyPurchaseEntry = ({
  match,
  location
}: {
  match: MatchProps;
  location: { pathname: string };
}): JSX.Element => {
  const billId = queryString.parse(window.location.search)?.billId;
  const [selectedBill, setSelectedBill] = React.useState(null);

  const [stockProducts, setStockProducts] = React.useState([]);

  const bills = useSelector((state: RootState) => state.bills.collection);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchAndSetBills = async () => {
      try {
        const bill = await getBill(Number(billId), true);
        setSelectedBill(bill);
      } catch (err) {
        dispatch(
          notificationAdd({
            message: err?.data?.message || "Failed to fetch bill data.",
            autoTimeout: true,
            variant: "error",
            id: new Date().getUTCMilliseconds()
          })
        );
      }
    };
    if (billId) {
      const bill = bills.find((b) => b.id === Number(billId));
      if (bill) {
        setSelectedBill(bill);
      } else {
        fetchAndSetBills();
      }
    }
  }, [billId, bills]);

  React.useEffect(() => {
    const getStockRelatedProds = async (productIds) => {
      try {
        const products = await productsGetByReferenceIds(productIds);
        setStockProducts(products);
      } catch (err) {
        dispatch(
          notificationAdd({
            message: err?.data?.message || "Failed to fetch producs",
            autoTimeout: true,
            variant: "error",
            id: new Date().getUTCMilliseconds()
          })
        );
      }
    };
    if (selectedBill) {
      const productIds = selectedBill?.document?.billItems.map((item) => item.productData.id);
      getStockRelatedProds(productIds);
    }
  }, [selectedBill]);

  const stockItems = selectedBill?.document?.billItems.map((item) => {
    const product: ProductInterface = stockProducts.find(
      (stckProd) => stckProd?.kitchenPharmacyProductId === item.productData.id
    );

    const productData = {
      sNo: item.sNo,
      productName: product?.name || "",
      productId: product?.id || null,
      expiryDate: "2025-01-01T04:35:54.342Z",
      quantity: item.quantity,
      unit: product?.unit || "pcs",
      price: item.perUnit,
      batchId: item.batchInfo?.batchId || item.batchId,
      grossTotal: item.grossTotal,
      package: product?.package || "Box",
      unitsPerPackage: item.stockProductUnitsPerPackage || 1,
      unitPriceExcVAT: item.productData.unitPriceExcVAT,
      unitPriceIncVAT: item.productData.unitPriceIncVAT,
      calculationBasis: item.calculationBasis,
      isFree: false,
      vatPct: item.vatPct,
      discountPct: 0,
      netTotal: item.quantity * item.perUnit,
      discountAmt: 0,
      salesVatPct: 0,
      hasFreeItems: false,
      freeItemQuantity: 0,
      carryingChargePct: 0,
      carryingChargeAmt: 0,
      carryingChargePctOfFreeItems: 0,
      carryingChargeAmtOfFreeItems: 0,
      rateOfFreeItems: 0,
      netTotalOfFreeItems: 0,
      discountPctOfFreeItems: 0,
      discountAmtOfFreeItems: 0,
      vatPctOfFreeItems: 0,
      vatAmtOfFreeItems: 0,
      grossTotalOfFreeItems: 0,
      grossTotalIncVatOfFreeItems: 0,
      currency: "Rs",
      purchaseLedgerId: product?.purchaseLedgerId || null,
      purchaseTaxationId: null,
      purchasePricePerUnit:
        item.stockUnitType === "package"
          ? item.perUnit / item.stockProductUnitsPerPackage
          : item.perUnit,
      purchasePricePerPackage:
        item.stockUnitType === "package"
          ? item.perUnit
          : item.perUnit * item.stockProductUnitsPerPackage,
      paidAll: true,
      paidAmount: 0,
      productType: item.productData.productType,
      packagePriceExcVAT: item.productData.unitPriceExcVAT * item.stockProductUnitsPerPackage,
      packagePriceIncVAT: item.productData.unitPriceIncVAT * item.stockProductUnitsPerPackage,
      salesPriceCalculationOn: product?.unit || "unit",
      enteredQuantityUnit: item.stockUnitType,
      internalExpenseLedgerId: null,
      purchasePriceCalculationOn: item.stockUnitType,
      priceCalculationOn: product?.priceCalculaitonOn || "unit",
      profitMargin: 0,
      vatAmt: 0,
      totalIncVat: item.grossTotal
    };
    return productData;
  });
  return (
    <PurchaseEntry
      match={match}
      location={location}
      mode={Mode.Create}
      billStock={stockItems || []}
      kitchenPharmacyBillId={Number(billId)}
      isKitchenPhramacyEntry
    />
  );
};

export default KitchenPharmacyPurchaseEntry;

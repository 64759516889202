import { OnDemandProducts } from '../interfaces/OnDemandProductInterface';
import { Get, Post, Delete, Put, Patch } from './apiHelper';

const root = '/api/onDemandProducts';

export async function getOnDemandProudcts() {
  const response = await Get(`${root}`, true);
  return response.data as Promise<Array<OnDemandProducts>>;
}

export async function postOnDemandProduct(data) {
  const response = await Post(`${root}`, data, true);
  return response.data as Promise<OnDemandProducts>;
}

export async function patchOnDemandProduct(data, id) {
  const response = await Patch(`${root}/${id}`, data, true);
  return response.data as Promise<OnDemandProducts>;
}

export async function deleteOnDemandProduct(id) {
  const response = await Delete(`${root}/${id}`, true);
  return response.data;
}

import React, { useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Menu, MenuItem, Button, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import { startCase } from "lodash";
import { SearchType } from "./ChatStats";
import PublicResourceCentreSelector from "./PublicResourceCentreSelector";
import UserSelect from "./UserSelect";

const useStyles = makeStyles(() => ({
  buttonController: {
    paddingLeft: "0px"
  },
  header: {
    position: "relative",
    borderRadius: "5px",
    height: "35px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    transition: "width .5s, background 1s ease",
    backgroundColor: "#ececec",
    width: "300px"
  },
  searchIcon: {
    position: "absolute",
    width: "35px"
  },
  divider: {
    height: 20,
    marginRight: 5
  }
}));

interface Props {
  onSearch: (value: SearchType) => void;
}

enum FILTERS {
  USER = "USER",
  RESOURCECENTRE = "RESOURCECENTRE"
}

const ChatSearch = ({ onSearch }: Props): JSX.Element => {
  const toggleRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeFilter, setActiveFilter] = React.useState(FILTERS.USER);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <SearchIcon className={classes.searchIcon} />
      <Box width="350px">
        {activeFilter === FILTERS.USER ? (
          <UserSelect onSearch={onSearch} />
        ) : (
          <PublicResourceCentreSelector onSearch={onSearch} />
        )}
      </Box>
      <Divider className={classes.divider} orientation="vertical" />
      <div>
        <Button aria-haspopup="true" onClick={handleClick} className={classes.buttonController}>
          {startCase(activeFilter)} <ExpandMoreIcon />
        </Button>
        <Menu
          ref={toggleRef}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {Object.keys(FILTERS).map((item) => (
            <MenuItem
              key={item}
              onClick={() => {
                setAnchorEl(null);
                setActiveFilter(item);
              }}
            >
              {startCase(item)}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Box>
  );
};

export default ChatSearch;

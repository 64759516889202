import React, { useState, useRef } from "react";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import * as NotificationActions from "../../../actions/notification";
import { tl } from "../../../components/translate";

interface Props {
  image: string | null;
  onSave: (base64Image: string) => void;
}

function toBase64(file: File): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onload = ({ target: { result } }) => {
      resolve(result.toString());
    };
    reader.readAsDataURL(file);
  });
}

function ServiceProviderSignatureUploader(props: Props): JSX.Element {
  const fileSelector = useRef(null);
  const dispatch = useDispatch();

  const [tmpImage, setTmpImage] = useState<null | string>(null);
  const [cropperOpen, setCropperOpen] = useState<boolean>(false);

  const handleUploadClick = () => {
    if (fileSelector.current) {
      fileSelector.current.click();
    }
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile || selectedFile.size >= 2097152) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: !selectedFile
            ? "Sorry !, Couldn't find File"
            : "Sorry !, Selected File is too large.",
          autoTimeout: true
        })
      );
      return;
    }
    setCropperOpen(true);
    setTmpImage(URL.createObjectURL(selectedFile));
  };

  const { image, onSave } = props;

  return (
    <Box mt="8px" display="flex">
      <img
        src={image || tmpImage}
        alt=""
        style={{
          height: "120px",
          width: "220px",
          borderRadius: "8px",
          border: "1px solid lightgrey",
          objectFit: "contain"
        }}
      />
      <Box display="flex" alignItems="center">
        <Button onClick={handleUploadClick}>{tl("Upload")}</Button>
        <input
          className="signatureUpload"
          type="file"
          onChange={handleImageChange}
          accept="image/*"
          style={{ display: "none" }}
          ref={fileSelector}
        />
      </Box>
      <ImageCrop
        image={tmpImage}
        isOpen={cropperOpen}
        onCancel={() => {
          setCropperOpen(false);
          setTmpImage(null);
        }}
        onSave={async (croppedImage) => {
          setTmpImage(croppedImage);
          setCropperOpen(false);
          const base64EncodedImage = await toBase64(croppedImage);
          onSave(base64EncodedImage);
        }}
        dynamicRatio
      />
    </Box>
  );
}

export default ServiceProviderSignatureUploader;

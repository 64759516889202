import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import EditableTable, { TableState } from "../../../../components/EditableTable/EditableTable";

interface Props {
  onSave: (v) => void;
  initValue: TableState;
  isDialog?: boolean;
}

export default function Culture(props: Props): JSX.Element {
  const { onSave, initValue, isDialog = true } = props;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [cultureState, setCultureState] = React.useState<TableState>();

  if (isDialog) {
    return (
      <div>
        <Typography
          onClick={handleClickOpen}
          style={{ padding: "4px 8px", width: "240px" }}
          role="button"
        >
          Interpretation Template
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">Interpretation Template</DialogTitle>
          <Box p="16px">
            <EditableTable
              hideLabel={false}
              maxColCount={10}
              onChange={(v) => setCultureState(v)}
              initValue={initValue}
              hideControls={false}
            />
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose();
                onSave(cultureState);
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <EditableTable
      maxColCount={10}
      onChange={(v) => onSave(v)}
      initValue={initValue}
      hideControls={false}
    />
  );
}

Culture.defaultProps = {
  isDialog: true
};

import { useState, useEffect, useCallback } from "react";

type ParticularsType = Array<string>;

const useParticulars = (): {
  particulars: ParticularsType;
  addParticular: (newParticular: string) => void;
} => {
  const getInitialParticulars = () => {
    const storedParticulars = localStorage.getItem("particulars");
    return storedParticulars ? JSON.parse(storedParticulars) : [];
  };

  const [particulars, setParticulars] = useState<ParticularsType>(getInitialParticulars);

  // Save the updated array to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("particulars", JSON.stringify(particulars));
  }, [particulars]);

  // Function to add a new value if it doesn't exist in the array
  const addParticular = useCallback(
    (newParticular: string) => {
      if (!particulars.includes(newParticular)) {
        setParticulars((prev) => [...prev, newParticular]);
      }
    },
    [particulars]
  );

  return { particulars, addParticular };
};

export default useParticulars;

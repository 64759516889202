import React from 'react';
import { Box, Typography } from '@mui/material';
import Panel from '../../../components/Panel';
import { tl } from '../../../components/translate';
import { connect } from 'react-redux';
import { FeedInterface } from '../../../interfaces/FeedInterfaces';
import { CalendarFunctions } from '../../../components/Calendar';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface RowInfoProps {
  label: string;
  value?: string | boolean;
}

interface FeedInfoProps {
  feed: FeedInterface;
  handleViewClose: () => void;
}

const RowInfo = ({ label, value }: RowInfoProps): JSX.Element => (
  <Box display="flex" alignItems="center" mb={'5px'}>
    <Box minWidth="30%" fontSize={'16px'} fontWeight={600}>
      {`${label} :`}
    </Box>
    <Box fontSize={'14px'}>
      {typeof value === 'boolean' ? (
        value ? (
          <CheckIcon color="primary" />
        ) : (
          <ClearIcon color="error" />
        )
      ) : (
        <Typography>{value || ''}</Typography>
      )}
    </Box>
  </Box>
);

const FeedInfo = ({ handleViewClose, feed }: FeedInfoProps) => {
  if (!feed) return null;
  const {
    action,
    actionProps,
    bgImage,
    deactivated,
    endDate,
    isBanner,
    startDate,
    title,
    type,
    description
  } = feed;
  return (
    <Panel
      onClose={handleViewClose}
      titleJsx={
        <Box component="span" fontWeight="600" fontSize="16px" pr={2}>
          {tl('feed.details')}
        </Box>
      }
    >
      <Box p={4} component="div">
        <RowInfo label="Title" value={title} />
        <RowInfo label="Description" value={description} />
        <RowInfo label="Type" value={type} />
        <RowInfo label="Action" value={action} />
        <RowInfo label="Action Label" value={actionProps?.actionLabel} />
        <RowInfo label="Start Date" value={CalendarFunctions.bsFullDate(startDate)} />
        <RowInfo label="End Date" value={CalendarFunctions.bsFullDate(endDate)} />
        <RowInfo label="Published" value={!deactivated} />
        <RowInfo label="Banner Mode" value={isBanner} />
        <RowInfo label="Banner Image" />
        <Box>
          <img src={bgImage} width={'300px'} />
        </Box>
      </Box>
    </Panel>
  );
};

export default connect(
  (state, { feedId }) => ({
    feed: state.feeds.collection.find((item) => item.id === feedId)
  }),
  null
)(FeedInfo);

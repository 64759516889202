import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import HtmlTooltip from "../../../components/HtmlTooltip";
import List, { EmptyView } from "../../../components/OList";
import {
  ErrorState,
  PriceCalculatedOn,
  Stock,
  TransactionItemInterface,
  TransactionType
} from "../../../interfaces/StockInterfaces";
import { RootState } from "../../../store";
import { t, tl } from "../../../components/translate";

interface Props {
  onRowRemove: (id: number) => void;
  onEdit: (id: number) => void;
  errors: ErrorState;
  stocks: Stock[];
}

const hiddenColsForKitchenPharmacyEntry = [
  "unit",
  "batchId",
  "expiryDate",
  "free",
  "rate",
  "sale",
  "amount"
];

const getErrorMsg = (idx, errors): { hasError: boolean; errorMessage: string } => {
  const { entryErrors } = errors || {};
  const error = entryErrors.find((item) => item.rowIdx === idx);
  if (error) {
    let errorMessage;
    switch (error.errorColumn) {
      case "quantity":
        errorMessage = "Insufficient Quantity";
        break;
      case "batchId":
        errorMessage = "Multiple Items Entered With Same Batch ID";
        break;
      default:
        errorMessage = "Error, Please Review Your Entries";
    }
    return { hasError: true, errorMessage };
  }
  return {
    hasError: false,
    errorMessage: ""
  };
};

export const getPackageName = (
  stocks: Stock[],
  product: Partial<TransactionItemInterface>
): string => stocks.find((s) => s.productId === product.productId)?.product?.package || "";

function EntryItemsView(props: Props): JSX.Element {
  const { onRowRemove, onEdit, errors, stocks } = props;

  const stockItems = useSelector((state: RootState) => state.stock.entry.stockItems, shallowEqual);
  const { transactionType } = useSelector((state: RootState) => state.stock.entry);

  const isKitchenPharmacyPurchaseEntry = useSelector(
    (state: RootState) =>
      state.stock?.entry?.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
  );

  return (
    <Box className="stockItems__container">
      <List
        automation="stockItems"
        rowHeight={40}
        data={stockItems.map((el, i) => ({ ...el, idx: i }))}
        columns={[
          { key: `${t("StockEntry.SNo")}`, formatter: (r) => <Typography>{r.idx + 1}</Typography> },
          {
            key: `${t("StockEntry.ProductName")}`,
            formatter: (r) => <Typography>{r.productName}</Typography>
          },
          {
            key: `${t("StockEntry.Unit")}`,
            formatter: (r) => (
              <Typography>
                {r.enteredQuantityUnit === "unit" ? r.unit : r.package || getPackageName(stocks, r)}
              </Typography>
            )
          },
          {
            key: `${t("StockEntry.BatchId")}`,
            formatter: (r) => <Typography>{r.batchId}</Typography>
          },
          {
            key: `${t("StockEntry.ExpiryDate")}`,
            formatter: (r) => <Typography>{moment(r.expiryDate).format("MMM YYYY")}</Typography>
          },
          {
            key: `${
              transactionType === TransactionType.ADJUSTMENT
                ? "Avail Qty"
                : `${t("StockEntry.Qty")}`
            }`,
            formatter: (r) => <Typography>{r.quantity}</Typography>
          },
          {
            key: `${t("StockEntry.Free")}`,
            formatter: (r) => <Typography>{r?.freeItemQuantity}</Typography>
          },
          { key: `${t("StockEntry.Rate")}`, formatter: (r) => <Typography>{r.price}</Typography> },
          {
            key: `${t("StockEntry.Sale")}`,
            formatter: (r) => (
              <Typography>
                {r.salesPriceCalculationOn === r.unit ||
                r.salesPriceCalculationOn === PriceCalculatedOn.unit
                  ? `${r.unitPriceExcVAT} / ${r.unit}`
                  : `${r.packagePriceExcVAT} / ${r.package || getPackageName(stocks, r)}`}
              </Typography>
            )
          },
          {
            key: `${t("StockEntry.Amount")}`,
            formatter: (r) => (
              <Typography>{(r.grossTotal + r.grossTotalOfFreeItems).toFixed(2)}</Typography>
            )
          },
          {
            key: `${t("StockEntry.Action")}`,
            formatter: (r) => (
              <Box>
                <IconButton onClick={() => onEdit(r.sNo)} data-testmation="editEntryButton">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onRowRemove(r.idx)} data-testmation="deleteEntryButton">
                  <DeleteIcon />
                </IconButton>
              </Box>
            )
          },
          {
            key: "",
            formatter: ({ idx }) => {
              const { hasError, errorMessage } = getErrorMsg(idx, errors);
              if (hasError) {
                return <HtmlTooltip alert description={errorMessage} />;
              }
              return "";
            }
          }
        ].filter(({ key }) =>
          isKitchenPharmacyPurchaseEntry ? !hiddenColsForKitchenPharmacyEntry.includes(key) : true
        )}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            {tl("StockEntry.NoItemsAddedYet...")}
          </Box>
        </EmptyView>
      </List>
    </Box>
  );
}

export default React.memo(EntryItemsView);

import * as React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import styles from "../Reports.module.css";
import { tl } from "../../../components/translate";
import { getUserAccesingRcs } from "../../../slices/userBranchSlice";
import Can from "../../Policy/Can";
import useCan from "../../../hooks/useCan";
import { PolicyAccessKeys } from "../../../interfaces/policy";

const BranchReports = (): JSX.Element => {
  const dispatch = useDispatch();
  const canAccessBranchRcs = useCan(PolicyAccessKeys.CmsReport, { superAdminPass: true });
  React.useEffect(() => {
    if (canAccessBranchRcs) {
      dispatch(getUserAccesingRcs());
    }
  }, [canAccessBranchRcs, dispatch]);

  return (
    <Box height="100%" padding="0 32px">
      <Typography fontSize="20px" fontWeight={600} lineHeight="40px" component="div">
        Central Reporting For Branches
      </Typography>
      <Box marginTop="32px">
        <Can policyAccessKey={PolicyAccessKeys.SalesByServiceReport}>
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/branchReports/salesByServiceReport"))}
            data-testmation="salesByServiceReportCard"
          >
            <Grid container>
              <Grid xs={8} lg={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.salesByServiceReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4} lg={4}>
                <PostAddIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey={PolicyAccessKeys.DueReport}>
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/branchReports/dueReport"))}
            data-testmation="dueReport"
          >
            <Grid container>
              <Grid xs={8} lg={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.dueReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4} lg={4}>
                <AssignmentReturnIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey={PolicyAccessKeys.ReceiptReport}>
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/branchReports/receiptReport"))}
            data-testmation="receiptReport"
          >
            <Grid container>
              <Grid xs={8} lg={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.receiptReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4} lg={4}>
                <ReceiptIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey={PolicyAccessKeys.StockAuditReport}>
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/branchReports/stockAuditReport"))}
            data-testmation="stockAuditReport"
          >
            <Grid container>
              <Grid xs={8} lg={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    Stock Audit Report
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4} lg={4}>
                <VerifiedUserIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
      </Box>
    </Box>
  );
};

export default BranchReports;

import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography } from "@mui/material";
import Panel from "../../../components/Panel";
import * as NotificationActions from "../../../actions/notification";
import { tl } from "../../../components/translate";
import ReferrerCommission from "./ReferrerCommission";
import { clearVendorCommission } from "../../../slices/commission";
import ServiceProviderCommission from "./ServiceProviderCommission";
import { BillDocumentType } from "../../../interfaces/BillInterfaces";

export enum Header {
  SERVICE_PROVIDER = "serviceProvider",
  REFERRER = "referrer"
}

interface CommissionPanel {
  handleClose: () => void;
  selectedBill?: BillDocumentType;
  header: Header.REFERRER | Header.SERVICE_PROVIDER;
}

function CommissionPanel({ handleClose, selectedBill, header }: CommissionPanel): JSX.Element {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(clearVendorCommission());
  }, [dispatch]);
  const [selectedHeader, setSelectedHeader] = React.useState<
    Header.REFERRER | Header.SERVICE_PROVIDER
  >(header || Header.SERVICE_PROVIDER);

  return (
    <Panel
      wrapperStyle={{
        zIndex: 1300,
        width: "calc(100vw - 80px)",
        maxWidth: "calc(100vw - 80px)"
      }}
      onClose={handleClose}
      title={tl("commission.adjustment")}
    >
      <div style={{ padding: "10px" }}>
        <Box display="flex">
          {(header ? [header] : [Header.SERVICE_PROVIDER, Header.REFERRER]).map((item) => (
            <Box
              sx={{ cursor: "pointer" }}
              mr="15px"
              key={item}
              onClick={() => setSelectedHeader(item)}
            >
              <Typography fontSize="1.2rem" color={`${selectedHeader === item ? "" : "gray"}`}>
                {tl(`commission.${item}`)}
              </Typography>
            </Box>
          ))}
        </Box>
        {selectedHeader === Header.REFERRER ? (
          <ReferrerCommission selectedBill={selectedBill} />
        ) : (
          <ServiceProviderCommission selectedBill={selectedBill} />
        )}
      </div>

      <div id="endMarker" />
    </Panel>
  );
}

const mapStateToProps = (state) => ({
  serviceProviders: state.resources.resourceCentreServiceProviders
});

const mapDispatchToProps = (dispatch) => ({
  push: (url) => dispatch(push(url)),
  saveNotification: () =>
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "success",
        message: "Commission Saved Successfully!",
        autoTimeout: true
      })
    )
});

CommissionPanel.defaultProps = {
  selectedBill: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPanel);

import * as React from 'react';
import './Timeline.scss';

export enum EventVariantEnum { small = 'small', big = 'big' }
export enum EventColourEnum {
	green = 'green',
	blue = 'blue',
	red = 'red',
	orange = 'orange',
	gray = 'gray',
	teal = 'teal'
}

interface EventProps {
	variant?: EventVariantEnum
	colour?: EventColourEnum
	automation: string
}

export const Event: React.SFC<EventProps> = ({ children, automation }) => <div data-automation={automation}>{children}</div>;

Event.defaultProps = {
	variant: EventVariantEnum.big,
	colour: EventColourEnum.teal
}

interface TimelineProps {
	defaultShowOnly?: number;
	showMoreLabel?: string;
	className?: string;
	automation: string;
}

interface TimelineState {
	showAll: boolean;
}

class Timeline extends React.Component<TimelineProps, TimelineState> {

	public state = { showAll: false };

	render() {
		const { showAll } = this.state;
		const { defaultShowOnly, children, showMoreLabel, className } = this.props;
		return (
			<div
				className={`okhati-timeline${className ? ` ${className}` : ''}`}
				data-testmation={this.props.automation}
			>
				{
					React.Children.map(children, (child, i) => {
						if (!child) return null;
						return (
							(showAll || !defaultShowOnly || i < defaultShowOnly) && <div
								className="okhati-timeline-event">
								<div className="okhati-timeline-indicator">
									<div className={`okhati-timeline-dot  dot-${child.props.variant} dot-${child.props.colour}`}></div>
									<div className="okhati-timeline-line"></div>
								</div>
								<div className="okhati-timeline-content">{child}</div>
							</div>
						)
					})
				}
				{
					!showAll && defaultShowOnly && (React.Children.count(children) > defaultShowOnly) &&
					<div className="okhati-timeline-showmore" data-automation={`${this.props.automation}-show-more`}
						onClick={() => this.setState({ showAll: !this.state.showAll })}> {showMoreLabel || 'Show more...'} </div>
				}
			</div>
		);
	}
}

export default Timeline;
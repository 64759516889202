import React from "react";
import moment from "moment";
import { Box } from "@mui/material";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import Headers, { Reports } from "../Headers";
import BalanceSheetList from "./BalanceSheetList";
import useBalanceSheet from "./hooks/useBalanceSheet";
import Filters from "../Filters";

const BalanceSheet = (): React.ReactElement => {
  const [filters, setFilters] = React.useState({
    periodDate: new Date(),
    showPreviousYearPeriod: false
  });
  const data = useBalanceSheet(filters);
  const headers = filters.showPreviousYearPeriod
    ? [
        { key: "description", label: "Description", className: "flex-1" },
        { key: "ledgerName", label: `${convertADtoBS(moment(filters.periodDate)).formatted4}` },
        {
          key: "ledgerType",
          label: `${convertADtoBS(moment(filters.periodDate).subtract(1, "year")).formatted4}`
        }
      ]
    : [
        { key: "description", label: "Description", className: "flex-1" },
        { key: "ledgerName", label: `${convertADtoBS(moment(filters.periodDate)).formatted4}` }
      ];

  return (
    <Box className="bSheetPLossReport">
      <Box minWidth="900px">
        <Box margin="0 2rem">
          <Filters filters={filters} setFilters={setFilters} />
        </Box>
        <Headers data={data} filters={filters} report={Reports.BALANCE_SHEET} headers={headers} />
        <Box
          sx={{
            height: "calc(100vh - 230px)",
            overflow: "auto",
            scrollbarGutter: "stable"
          }}
        >
          <BalanceSheetList data={data} showPreviousYearPeriod={filters.showPreviousYearPeriod} />
        </Box>
      </Box>
    </Box>
  );
};

export default BalanceSheet;

import * as React from 'react';
import { Box, Typography, IconButton, Radio, Button } from '@mui/material';
import * as moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import classNames from '../../../helpers/classNames';
import { tl } from '../../../components/translate';
import CalendarDropdown from '../../../components/CalendarDropdown/CalendarDropdown';

interface RecurrenceProps {
  repeatsEvery: string;
  repeatsOnDays: number[];
  repeatsUntil: any;
  onChange: (value) => void;
  startDate: Date;
}

const numAndDays = [
  { value: 0, label: 'Su' },
  { value: 1, label: 'Mo' },
  { value: 2, label: 'Tu' },
  { value: 3, label: 'We' },
  { value: 4, label: 'Th' },
  { value: 5, label: 'Fr' },
  { value: 6, label: 'Sa' },
];

const useStyles = makeStyles((theme) => ({
  defBtn: {
    height: '24px',
    width: '24px',
    padding: 0,
    margin: '0px 4px',
    border: '1px solid #009654',
  },
  selectedBtn: {
    backgroundColor: '#009654',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009654',
    },
  },
}));

const Recurrence: React.FC<RecurrenceProps> = ({
  repeatsEvery = 'week',
  repeatsOnDays = [0],
  repeatsUntil,
  onChange,
  startDate,
}) => {
  const classes = useStyles();
  return (
    <Box width="370px">
      <Box display="flex" alignItems="center" mb={1}>
        <Box pr={2}>
          <Typography style={{ fontWeight: 600 }}>{tl('slot.repeatsEvery')}</Typography>
        </Box>
        <Box>
          <Typography>{repeatsEvery}</Typography>
        </Box>
      </Box>
      <Box mb={1}>
        <Box mb={0.5}>
          <Typography style={{ fontWeight: 600 }}>{tl('slot.repeatsOn')}</Typography>
        </Box>
        <Box display="flex">
          {numAndDays.map((nd) => {
            return (
              <IconButton
                key={nd.label}
                data-testmation={nd.label}
                size="small"
                className={classNames(classes.defBtn, {
                  [classes.selectedBtn]: repeatsOnDays.includes(nd.value),
                })}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={() => {
                  let newRepeatOnDays = repeatsOnDays;
                  newRepeatOnDays.includes(nd.value)
                    ? (newRepeatOnDays = newRepeatOnDays.filter((nrd) => nrd != nd.value))
                    : newRepeatOnDays.push(nd.value);
                  onChange({
                    repeatsEvery: repeatsEvery,
                    repeatsOnDays: newRepeatOnDays,
                    repeatsUntil: repeatsUntil,
                  });
                }}
              >
                <Typography style={{ fontSize: '9px' }}>{nd.label}</Typography>
              </IconButton>
            );
          })}
        </Box>
      </Box>
      <Box mb={1}>
        <Box mb={0.5}>
          <Typography style={{ fontWeight: 600 }}>{tl('slot.stopOn')}</Typography>
        </Box>
        <Box>
          <Box display="flex" alignItems="center" height="18px" mb="8px">
            <Radio
              data-testmation="slotNever"
              size="small"
              checked={repeatsUntil?.toISOString() ? false : true}
              onChange={async () => {
                await onChange({
                  repeatsEvery: repeatsEvery,
                  repeatsOnDays: repeatsOnDays,
                  repeatsUntil: null,
                });
              }}
            />
            <Typography>{tl('slot.never')}</Typography>
          </Box>
          <Box display="flex" alignItems="center" height="18px">
            <Radio
              data-testmation="customDateSlot"
              size="small"
              checked={repeatsUntil?.toISOString() ? true : false}
              onChange={async () => {
                await onChange({
                  repeatsEvery: repeatsEvery,
                  repeatsOnDays: repeatsOnDays,
                  repeatsUntil: moment(startDate).add(1, 'month').toDate(),
                });
              }}
            />
            <CalendarDropdown
              date={repeatsUntil.isValid() && new Date(repeatsUntil)}
              allowNull={true}
              onChange={(date) => {
                onChange({
                  repeatsEvery: repeatsEvery,
                  repeatsOnDays: repeatsOnDays,
                  repeatsUntil: date,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Recurrence;

import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import "./EyeExamination.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import produce from "immer";
import Popper from "./Popper";
import styles from "../Assessment.module.css";

export const autoRefractionHeaders = ["Auto Refraction", "SPH", "CYL", "Axis"];
export const refractionHeaders = ["Refraction", "SPH", "CYL", "Axis"];
export const unAidedHeaders = ["Unaided", "R-Eye", "L-Eye", "Both", "Results"];
export const aidedHeaders = ["Aided", "R-Eye", "L-Eye", "Both", "Results"];
export const pinHoleHeaders = ["Pin Hole", "R-Eye", "L-Eye", "Both"];
export const bestCorrectedHeaders = ["Best corrected visual acuity", "R-Eye", "L-Eye", "Both"];
export const anteriorSegmentHeaders = ["Anterior Segment", "R-Eye", "L-Eye"];
export const posteriorHeaders = ["Posterior Segment", "R-Eye", "L-Eye"];
export const glassPrescriptionHeaders = ["Glass Prescription", "SHP", "CYL", "Axis"];
export const fieldOfVisionHeaders = ["Field Of Vision", "R-Eye", "L-Eye", "Both", "Results"];
export const colorVisionHeaders = ["Color Vision", "Eye", "Results"];

export const initialState = {
  vision: {
    autoRefraction: {
      OD: {
        sph: null,
        cyl: null,
        axis: null
      },
      OS: {
        sph: null,
        cyl: null,
        axis: null
      }
    },
    refraction: {
      OD: {
        sph: null,
        cyl: null,
        axis: null
      },
      OS: {
        sph: null,
        cyl: null,
        axis: null
      }
    },
    unAided: {
      Distance: {
        rEye: null,
        lEye: null,
        both: null,
        results: ""
      },
      Near: {
        rEye: null,
        lEye: null,
        both: null,
        results: ""
      }
    },
    aided: {
      Distance: {
        rEye: null,
        lEye: null,
        both: null,
        results: ""
      },
      Near: {
        rEye: null,
        lEye: null,
        both: null,
        results: ""
      }
    },
    fieldOfVision: {
      "Horizontal Plane": {
        rEye: null,
        lEye: null,
        both: null,
        results: ""
      },
      "Vertial Plane": {
        rEye: null,
        lEye: null,
        both: null,
        results: ""
      }
    },
    colorVision: {
      Ishiara: {
        eye: "",
        results: ""
      },
      "Lantern/Others": {
        eye: "",
        results: ""
      }
    },
    bestCorrectiveVisualAcuity: {
      Distance: {
        rEye: null,
        lEye: null,
        both: null
      },
      Near: {
        rEye: null,
        lEye: null,
        both: null
      }
    },
    pinHole: {
      Distance: {
        rEye: null,
        lEye: null,
        both: null
      },
      Near: {
        rEye: null,
        lEye: null,
        both: null
      }
    }
  },
  anteriorSegment: {
    Lids: {
      rEye: null,
      lEye: null
    },
    Conjunctiva: {
      rEye: null,
      lEye: null
    },
    Cornea: {
      rEye: null,
      lEye: null
    },
    "A/C": {
      rEye: null,
      lEye: null
    },
    "Pupil/Iris": {
      rEye: null,
      lEye: null
    },
    Lens: {
      rEye: null,
      lEye: null
    }
  },
  posteriorSegment: {
    Vitreous: {
      rEye: null,
      lEye: null
    },
    Fundus: {
      rEye: null,
      lEye: null
    }
  },
  glassPrescription: {
    OD: {
      sph: null,
      cyl: null,
      axis: null
    },
    OS: {
      sph: null,
      cyl: null,
      axis: null
    }
  }
};

const Title = ({ title }) => <Box className="title">{title}</Box>;

const Header = ({ index, header }) => (
  <Box className={`${index === 0 ? "mainHeader" : "header"}`}>
    <Typography>{header}</Typography>
  </Box>
);

export const sortColumnOrder = ["sph", "cyl", "axis", "rEye", "lEye", "both"];
export const sortRowOrder = [
  "OD",
  "OS",
  "Distance",
  "Near",
  "Vitreous",
  "Funds",
  "Lids",
  "Cornea",
  "Conjunctiva",
  "A/C",
  "Pupil/Iris",
  "Lens, ...fieldStyle",
  "Horizontal Plane",
  "Vertial Plane"
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Row = ({ test, data, onChange, fieldStyle = (i) => ({}) }) => (
  <Box display="flex">
    <Box style={{ textAlign: "end", borderRight: "1px solid black" }}>
      <Box className="subHeader">{test}</Box>
    </Box>
    <Box style={{ width: "500px", padding: "5px 20px" }}>
      {Object.keys(data).map((col, index) => {
        const style = fieldStyle(index);
        const styleProps = {
          inputProps: { style: { width: "100px", padding: "10px" } },
          style: { margin: "5px 10px" }
        };
        if (Object.keys(style).length > 0) {
          delete styleProps.inputProps;
          styleProps.style = { ...styleProps.style, ...style };
        }
        return (
          <TextField
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            value={data[col]}
            variant="outlined"
            onChange={(e) => {
              onChange(e.target.value, test, col);
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...styleProps}
          />
        );
      })}
    </Box>
  </Box>
);

function hasValue(test) {
  return Object.keys(test).some((row) => {
    const containsValue = Object.keys(test[row]).some((col) => Boolean(test[row][col]));
    return containsValue;
  });
}

interface Props {
  assessmentEyeExamination: typeof initialState;
  onChange: (v) => void;
}

const EyeExamination = ({ assessmentEyeExamination, onChange }: Props): JSX.Element => {
  const eyeAssessmentData =
    assessmentEyeExamination && Object.keys(assessmentEyeExamination).length > 0
      ? assessmentEyeExamination
      : null;
  const [state, setState] = React.useState(eyeAssessmentData || initialState);

  const {
    vision: visionData,
    anteriorSegment: anteriorSegData,
    posteriorSegment: posteriorSegData,
    glassPrescription: glassPrescriptionData
  } = state;

  const showAutoRefraction = hasValue(visionData.autoRefraction);
  const showRefraction = hasValue(visionData.refraction);
  const showUnaided = hasValue(visionData.unAided);
  const showAided = hasValue(visionData.aided);
  const showBestCorrectiveVisualAcuity = hasValue(visionData.bestCorrectiveVisualAcuity);
  const showPinHole = hasValue(visionData.pinHole);
  const showAnteriorSegment = hasValue(anteriorSegData);
  const showPosteriorSegment = hasValue(posteriorSegData);
  const showGlassPrescription = hasValue(glassPrescriptionData);
  const showFieldOfVision = hasValue(visionData.fieldOfVision);
  const showColorVision = hasValue(visionData.colorVision);

  const [checkStatus, setCheckStatus] = React.useState({
    vision: false,
    autoRefraction: false,
    refraction: false,
    unaided: false,
    aided: false,
    bestCorrected: false,
    pinHole: false,
    anteriorSegment: false,
    posteriorSegment: false,
    glassPrescription: false,
    fieldOfVision: false,
    colorVision: false
  });

  const sortData = (data) =>
    Object.keys(data)
      .sort((a, b) => sortRowOrder.indexOf(a) - sortRowOrder.indexOf(b))
      .reduce((a, b) => {
        const sortedInside = Object.keys(data[b])
          .sort((a1, b1) => sortColumnOrder.indexOf(a1) - sortColumnOrder.indexOf(b1))
          .reduce((x, y) => {
            // eslint-disable-next-line no-param-reassign
            x[y] = data[b][y];
            return x;
          }, {});
        // eslint-disable-next-line no-param-reassign
        a[b] = sortedInside;
        return a;
      }, {});

  const sortedAnteriorData = sortData(anteriorSegData);
  const sortedPosteriorsegmentData = sortData(posteriorSegData);
  const sortedGlassPrescriptionData = sortData(glassPrescriptionData);
  const sortedAutoRefractionData = sortData(visionData.autoRefraction);
  const sortedRefractionData = sortData(visionData.refraction);
  const sortedUnaidedData = sortData(visionData.unAided);
  const sortedFieldOfVisionData = sortData(visionData.fieldOfVision);
  const sortedColorVisionData = sortData(visionData.colorVision);
  const sortAidedData = sortData(visionData.aided);
  const bestCorrectedData = sortData(visionData.bestCorrectiveVisualAcuity);
  const pinHoleData = sortData(visionData.pinHole);

  React.useEffect(() => {
    if (eyeAssessmentData) {
      setState(eyeAssessmentData);
    }
  }, [assessmentEyeExamination]);

  const commonStyle = {
    wraperBox: { marginTop: "20px", marginLeft: "15px", paddingLeft: "15px" },
    rowInputWrapper: { borderBottom: "1px solid black", width: "800px" },
    commanMargin: { marginTop: "30px" },
    addNew: { marginTop: "20px", marginLeft: "15px", marginBottom: "5px" }
  };

  // state for new anterior Segment

  const [addanteriorSegment, setaddanteriorSegment] = useState("");
  const anteriorSortArray = ["Lids", "Cornea", "Conjunctiva", "A/C", "Pupil/Iris", "Lens"];

  // state for New Posterior Segment

  const [addposteriorSegment, setaddposteriorSegment] = useState("");
  const posteriorSortArray = ["Vitreous", "Fundus"];

  // function that sort segment data

  const sortFunction = (arr, sortingArray) =>
    arr.sort((a, b) => {
      if (sortingArray.indexOf(a) === sortingArray.indexOf(b)) {
        return 0;
      }
      if (sortingArray.indexOf(a) === -1) {
        return 1;
      }
      if (sortingArray.indexOf(b) === -1) {
        return -1;
      }
      if (sortingArray.indexOf(a) < sortingArray.indexOf(b)) {
        return -1;
      }
      return 1;
    });

  return (
    <Box className={styles.assesmentField}>
      <Popper checkStatus={checkStatus} setCheckStatus={setCheckStatus} />

      {(checkStatus.vision ||
        showAutoRefraction ||
        showRefraction ||
        showUnaided ||
        showAided ||
        showBestCorrectiveVisualAcuity ||
        showPinHole) && (
        <>
          <Box ml="16%">
            <Title title="Vision" />
          </Box>
          <Box style={{ marginLeft: "15px", paddingLeft: "15px" }}>
            {(checkStatus.autoRefraction || showAutoRefraction) && (
              <>
                <Box>
                  <Box className="headerContainer">
                    {autoRefractionHeaders.map((item, index) => (
                      <Box key={item}>
                        <Header index={index} header={item} />
                      </Box>
                    ))}
                  </Box>
                  <Box style={commonStyle.rowInputWrapper}>
                    {Object.keys(sortedAutoRefractionData).map((test) => (
                      <Row
                        key={test}
                        test={test}
                        data={sortedAutoRefractionData[test]}
                        onChange={(value, row, col) => {
                          const updatedState = produce(state, (draft) => {
                            draft.vision.autoRefraction[row][col] = value;
                          });
                          onChange(updatedState);
                          setState(updatedState);
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </>
            )}
            {(checkStatus.refraction || showRefraction) && (
              <Box style={commonStyle.commanMargin}>
                <Box className="headerContainer">
                  {refractionHeaders.map((item, index) => (
                    <Box key={item}>
                      <Header index={index} header={item} />
                    </Box>
                  ))}
                </Box>

                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(sortedRefractionData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      data={sortedRefractionData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.refraction[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {(checkStatus.unaided || showUnaided) && (
              <Box style={commonStyle.commanMargin}>
                <Box display="flex" borderBottom="1px solid black">
                  {unAidedHeaders.map((item, i) => (
                    <Typography
                      sx={{
                        width: i === 0 ? "200px" : "100px",
                        pl: i === 0 && "64px",
                        mx: "6px",
                        px: "4px",
                        textAlign: "center"
                      }}
                      key={item}
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(sortedUnaidedData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      fieldStyle={() => ({ width: "100px", margin: "4px", padding: "4px" })}
                      data={sortedUnaidedData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.unAided[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {(checkStatus.aided || showAided) && (
              <Box style={commonStyle.commanMargin}>
                <Box display="flex" borderBottom="1px solid black">
                  {aidedHeaders.map((item, i) => (
                    <Typography
                      sx={{
                        width: i === 0 ? "200px" : "100px",
                        pl: i === 0 && "64px",
                        mx: "6px",
                        px: "4px",
                        textAlign: "center"
                      }}
                      key={item}
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(sortAidedData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      fieldStyle={() => ({ width: "100px", margin: "4px", padding: "4px" })}
                      data={sortAidedData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.aided[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {(checkStatus.fieldOfVision || showFieldOfVision) && (
              <Box style={commonStyle.commanMargin}>
                <Box display="flex" borderBottom="1px solid black">
                  {fieldOfVisionHeaders.map((item, i) => (
                    <Typography
                      sx={{
                        width: i === 0 ? "200px" : "100px",
                        pl: i === 0 && "64px",
                        mx: "6px",
                        px: "4px",
                        textAlign: "center"
                      }}
                      key={item}
                    >
                      {item}
                    </Typography>
                  ))}
                </Box>
                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(sortedFieldOfVisionData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      fieldStyle={() => ({ width: "100px", margin: "4px", padding: "4px" })}
                      data={sortedFieldOfVisionData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.fieldOfVision[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {(checkStatus.colorVision || showColorVision) && (
              <Box style={commonStyle.commanMargin}>
                <Box display="flex" borderBottom="1px solid black">
                  {colorVisionHeaders.map((item, i) => {
                    let width = "100px";
                    if (i === 0) {
                      width = "200px";
                    }
                    if (i === 1) {
                      width = "260px";
                    }
                    return (
                      <Typography
                        sx={{
                          width,
                          pl: i === 0 && "64px",
                          mx: "6px",
                          px: "4px",
                          textAlign: "center"
                        }}
                        key={item}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </Box>
                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(sortedColorVisionData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      fieldStyle={(idx) => ({
                        width: idx === 0 ? "260px" : "100px",
                        margin: "4px",
                        padding: "4px"
                      })}
                      data={sortedColorVisionData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.colorVision[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {(checkStatus.bestCorrected || showBestCorrectiveVisualAcuity) && (
              <Box style={commonStyle.commanMargin}>
                <Box className="headerContainer">
                  {bestCorrectedHeaders.map((item, index) => (
                    <Box key={item}>
                      <Header index={index} header={item} />
                    </Box>
                  ))}
                </Box>
                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(bestCorrectedData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      data={bestCorrectedData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.bestCorrectiveVisualAcuity[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {(checkStatus.pinHole || showPinHole) && (
              <Box style={commonStyle.commanMargin}>
                <Box className="headerContainer">
                  {pinHoleHeaders.map((item, index) => (
                    <Box key={item}>
                      <Header index={index} header={item} />
                    </Box>
                  ))}
                </Box>
                <Box style={commonStyle.rowInputWrapper}>
                  {Object.keys(pinHoleData).map((test) => (
                    <Row
                      key={test}
                      test={test}
                      data={pinHoleData[test]}
                      onChange={(value, row, col) => {
                        const updatedState = produce(state, (draft) => {
                          draft.vision.pinHole[row][col] = value;
                        });
                        onChange(updatedState);
                        setState(updatedState);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}

      {(checkStatus.anteriorSegment || showAnteriorSegment) && (
        <Box style={commonStyle.wraperBox}>
          <Box>
            <Title title="Anterior Segment" />
          </Box>
          <Box className="headerContainer">
            {anteriorSegmentHeaders.map((item, index) => (
              <Box key={item}>
                <Header index={index} header={item} />
              </Box>
            ))}
          </Box>

          <Box style={commonStyle.rowInputWrapper}>
            <Box>
              {sortFunction(Object.keys(sortedAnteriorData), anteriorSortArray).map((test) => (
                <Row
                  key={test}
                  test={test}
                  data={sortedAnteriorData[test]}
                  onChange={(value, row, col) => {
                    const updatedState = produce(state, (draft) => {
                      draft.anteriorSegment[row][col] = value;
                    });
                    onChange(updatedState);
                    setState(updatedState);
                  }}
                />
              ))}
            </Box>
            <Box style={commonStyle.addNew}>
              <TextField
                style={{ width: "4rem" }}
                value={addanteriorSegment}
                onChange={(e) => setaddanteriorSegment(e.target.value)}
                placeholder="add new"
              />
              <AddCircleOutlineIcon
                onClick={() => {
                  if (addanteriorSegment !== "") {
                    const updateState = produce(state, (draft) => {
                      draft.anteriorSegment = {
                        ...state.anteriorSegment,
                        [addanteriorSegment]: { rEye: null, lEye: null }
                      };
                    });
                    setState(updateState);
                    setaddanteriorSegment("");
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}

      {(checkStatus.posteriorSegment || showPosteriorSegment) && (
        <Box style={commonStyle.wraperBox}>
          <Box>
            <Title title="Posterior Segment" />
          </Box>
          <Box className="headerContainer">
            {posteriorHeaders.map((item, index) => (
              <Box key={item}>
                <Header index={index} header={item} />
              </Box>
            ))}
          </Box>

          <Box style={commonStyle.rowInputWrapper}>
            <Box>
              {sortFunction(Object.keys(sortedPosteriorsegmentData), posteriorSortArray).map(
                (test) => (
                  <Row
                    key={test}
                    test={test}
                    data={sortedPosteriorsegmentData[test]}
                    onChange={(value, row, col) => {
                      const updatedState = produce(state, (draft) => {
                        draft.posteriorSegment[row][col] = value;
                      });
                      onChange(updatedState);
                      setState(updatedState);
                    }}
                  />
                )
              )}
            </Box>
            <Box style={commonStyle.addNew}>
              <TextField
                style={{ width: "4rem" }}
                value={addposteriorSegment}
                onChange={(e) => setaddposteriorSegment(e.target.value)}
                placeholder="add new"
              />
              <AddCircleOutlineIcon
                onClick={() => {
                  if (addposteriorSegment !== "") {
                    const updateState = produce(state, (draft) => {
                      draft.posteriorSegment = {
                        ...state.posteriorSegment,
                        [addposteriorSegment]: { rEye: null, lEye: null }
                      };
                    });

                    setState(updateState);
                    setaddposteriorSegment("");
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      )}

      {(checkStatus.glassPrescription || showGlassPrescription) && (
        <Box style={commonStyle.wraperBox}>
          <Box>
            <Title title="Glass Prescription" />
          </Box>
          <Box className="headerContainer">
            {glassPrescriptionHeaders.map((item, index) => (
              <Box key={item}>
                <Header index={index} header={item} />
              </Box>
            ))}
          </Box>
          <Box style={{ display: "flex", width: "800px" }}>
            <Box style={commonStyle.rowInputWrapper}>
              {Object.keys(sortedGlassPrescriptionData).map((test) => (
                <Row
                  key={test}
                  test={test}
                  data={sortedGlassPrescriptionData[test]}
                  onChange={(value, row, col) => {
                    const updatedState = produce(state, (draft) => {
                      draft.glassPrescription[row][col] = value;
                    });
                    onChange(updatedState);
                    setState(updatedState);
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EyeExamination;

import { Get, Post } from './apiHelper';

const vitalsRoot = '/api/vitals';

export const getVitals = async (clientId) => {
  const response = await Get(`${vitalsRoot}/${clientId}`, true);
  return response.data;
};

export const postVitals = async (data) => {
  const response = await Post(vitalsRoot, data, true);
  return response.data;
};

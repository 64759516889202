import { Button, Checkbox, FormControlLabel, Grid, Box } from "@mui/material";
import React from "react";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import PeriodPicker from "../../../components/PeriodPicker";
import hasOwnProperty from "../../../helpers/object";
import { FiltersInterface } from "../../../interfaces/AccountReportsInterface";
import { Reports } from "./Headers";
import MultipleLedgerSelection from "./MultipleLedgerSelection";
import { tl } from "../../../components/translate";

const GridItem = ({ children }: { children: React.ReactNode }) => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    {children}
  </Grid>
);

const Filters = ({
  ledgerFilter,
  filters,
  setFilters,
  report,
  isForAccountUser = false,
  showMonthOnly = false
}: {
  ledgerFilter?: string[];
  filters: FiltersInterface;
  setFilters: (filters) => void;
  report?: string;
  isForAccountUser?: boolean;
  showMonthOnly?: boolean;
}): React.ReactElement => {
  const [changes, setChanges] = React.useState(filters);
  const onChange = (key, val) => {
    setChanges({ ...changes, [key]: val });
  };
  const onSave = () => {
    setFilters(changes);
  };
  return (
    <Box bgcolor="#F0F0F0" padding="0.5rem 1rem" borderRadius="0.3rem">
      <Grid container columnSpacing={4} rowSpacing={0} alignItems="center">
        {hasOwnProperty(filters, "periodDate") && (
          <GridItem>
            <CalendarDropdown
              label={report === Reports.TRIAL_BALANCE ? "As On" : "Period"}
              disable={false}
              fullwidth
              TextFieldProps={{
                fullWidth: true,
                variant: "outlined",
                margin: "dense"
              }}
              date={changes.periodDate}
              onChange={(newDate) => {
                onChange("periodDate", new Date(newDate));
              }}
            />
          </GridItem>
        )}
        {hasOwnProperty(filters, "from") && hasOwnProperty(filters, "until") && (
          <GridItem>
            <PeriodPicker
              start={changes.from}
              end={changes.until}
              onChange={({ start, end }) => {
                setChanges({
                  ...changes,
                  from: start.toDate(),
                  until: end.toDate()
                });
              }}
              forAccountUser={isForAccountUser}
              limitToMonth
              showMonthOnly={showMonthOnly}
            />
          </GridItem>
        )}
        {hasOwnProperty(filters, "ledgerIds") && (
          <GridItem>
            <MultipleLedgerSelection
              filters={filters}
              ledgerFilter={ledgerFilter}
              onChange={(newValue) => onChange("ledgerIds", newValue)}
            />
          </GridItem>
        )}
        {hasOwnProperty(filters, "showExtraColumns") && (
          <GridItem>
            <FormControlLabel
              sx={{ paddingLeft: "10px" }}
              control={
                <Checkbox
                  checked={changes.showExtraColumns}
                  onClick={() => onChange("showExtraColumns", !changes.showExtraColumns)}
                />
              }
              label="Show Opening Dr/Cr And During the period Dr/Cr"
            />
          </GridItem>
        )}
        {hasOwnProperty(filters, "showTrialBalanceSummary") && (
          <GridItem>
            <FormControlLabel
              sx={{ paddingLeft: "10px" }}
              control={
                <Checkbox
                  checked={changes.showTrialBalanceSummary}
                  onClick={() =>
                    onChange("showTrialBalanceSummary", !changes.showTrialBalanceSummary)
                  }
                />
              }
              label="Summary"
            />
          </GridItem>
        )}
        {hasOwnProperty(filters, "showPreviousYearPeriod") && (
          <GridItem>
            <FormControlLabel
              sx={{ marginLeft: "10px" }}
              control={
                <Checkbox
                  checked={changes.showPreviousYearPeriod}
                  onClick={() =>
                    onChange("showPreviousYearPeriod", !changes.showPreviousYearPeriod)
                  }
                />
              }
              label="Compare with Previous Year"
            />
          </GridItem>
        )}

        <Grid item xs lg>
          <Box display="flex" alignItems="center" justifyContent="end">
            <Button variant="contained" onClick={onSave} disabled={!Object.values(changes).length}>
              {tl("applyFilter")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;

Filters.defaultProps = {
  ledgerFilter: null,
  report: ""
};

import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "../../helpers/classNames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "min-content"
  },
  formControl: {
    paddingRight: 2,
    width: 200,
    boxSizing: "border-box"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  hideItem: {
    display: "none"
  }
}));

interface columns {
  selected: unknown;
  value: string;
  label: string;
}

interface allColumns {
  value: string;
  label: string;
}

interface ColumnSelectorProps {
  columns: columns[];
  allColumns: allColumns[];
  onChange: (v1, v2) => void;
}

const checkRelatedColumns = (selectedCols, selectableCols: allColumns[]) => {
  let columnParams = selectableCols;
  if (selectedCols.includes("dob")) {
    columnParams = columnParams.filter((c) => c.value !== "dateOfBirth" && c.value !== "ageSex");
  }

  if (selectedCols.includes("dateOfBirth")) {
    columnParams = columnParams.filter((c) => c.value !== "dob" && c.value !== "ageSex");
  }

  if (selectedCols.includes("gender")) {
    columnParams = columnParams.filter((c) => c.value !== "ageSex");
  }

  if (selectedCols.includes("ageSex")) {
    columnParams = columnParams.filter(
      (c) => c.value !== "dob" && c.value !== "dateOfBirth" && c.value !== "gender"
    );
  }
  return columnParams;
};

function ColumnSelector({ columns, allColumns, onChange }: ColumnSelectorProps): JSX.Element {
  const classes = useStyles();
  const selectedColumns = columns.map((c) => c.selected).filter(Boolean);
  let selectableItems = allColumns.filter((c) => c.value);

  selectableItems = checkRelatedColumns(selectedColumns, selectableItems);

  return (
    <div className={classes.root}>
      {columns.map((column, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FormControl className={classes.formControl} key={`form-c-${index}`}>
          <Select
            data-testmation="columnSelector"
            value={column.selected}
            onChange={(e) => {
              onChange(index, e.target.value);
            }}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* eslint-disable-next-line react/no-array-index-key */}
            <MenuItem key={index} value="">
              None
            </MenuItem>
            {selectableItems.map((c, i) => {
              const showItem = !selectedColumns.includes(c.value);
              return (
                <MenuItem
                  data-testmation={c.value}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`menu-item${i}`}
                  className={classNames({ [classes.hideItem]: !showItem })}
                  value={c.value}
                >
                  {c.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ))}
    </div>
  );
}

export default ColumnSelector;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { Test } from "../../../interfaces/Lab";
import StockReagent, { ReagentProps } from "../SettingsV2/StockReagent";
import StockReagentListView from "../SettingsV2/StockReagentListView";

interface Props {
  selectedLabGroup: Test;
  onClose: () => void;
  cancelledProductIdx: (idx: number) => void;
  onAddNewInternalStock: (product: ReagentProps) => void;
}

const getErrorMsg = (stockData: Test) => {
  const insufficientProduct = stockData?.stockReagent?.find(
    (stock) => (stock?.availableQty || 0) < stock.quantity || !stock.availableQty
  );
  return insufficientProduct ? (
    <Typography color="red">
      There is insufficient quantity of{" "}
      <span style={{ fontWeight: "bold" }}>{`${insufficientProduct.productName}`}</span> in stock.
    </Typography>
  ) : (
    ""
  );
};

export default function ReagentDialog({
  selectedLabGroup,
  onClose,
  cancelledProductIdx,
  onAddNewInternalStock
}: Props): JSX.Element {
  return (
    <Dialog sx={{ minWidth: "950px" }} open={!isEmpty(selectedLabGroup)}>
      <DialogTitle>Internal Stock</DialogTitle>
      <DialogContent width="900px">
        <Box>
          <StockReagentListView
            showAvailableQty
            data={selectedLabGroup?.stockReagent}
            onRemove={(index) => cancelledProductIdx(index)}
          />
          {getErrorMsg(selectedLabGroup)}
          <StockReagent
            stockReagent={selectedLabGroup?.stockReagent}
            onSave={(value) => onAddNewInternalStock(value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

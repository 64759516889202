import React, { FunctionComponent } from "react";
import { Modal, Grid, Button, Typography } from "@mui/material";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import styles from "./styles.module.scss";
import Print from "../../Assessment/Print/Print";
import { tl } from "../../../components/translate";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import AssessmentContent from "./AssessmentContent";
import { RootState } from "../../../store";
import { UserMode } from "../../../interfaces/User";
import MultipleButtonGroup from "../../../components/MultipleButtonGroup";

interface AssessmentModalProps {
  data: any;
  setAssessmentCopy: (data) => void;
  setIsDialogOpen: (status: boolean) => void;
  authenticable: UserMode;
  onClose: () => void;
  goToEdit: (url) => void;
}

const AssessmentModal: FunctionComponent<AssessmentModalProps> = ({
  data,
  setAssessmentCopy,
  setIsDialogOpen,
  authenticable,
  onClose,
  goToEdit
}) => (
  <Modal open onClose={() => onClose()}>
    <Grid container className={styles.modalRoot}>
      <Grid item xs={12} md={10} lg={8} className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <Typography variant="h6" component="h3" display="inline" style={{ fontSize: "1.16rem" }}>
            {tl("assessment.assessment")}
          </Typography>
          <Typography variant="subtitle1" component="span" display="initial">
            {convertADtoBS(moment(data.createdDate) || moment()).formatted2}
          </Typography>
        </div>
        <div className={styles.modalBody}>
          <AssessmentContent data={data} />
        </div>
        <div className={styles.modalFooter}>
          <Button
            sx={{ margin: "0 5px" }}
            onClick={() => onClose()}
            variant="text"
            color="secondary"
          >
            {tl("cancel")}
          </Button>
          {authenticable !== "client" && moment().diff(moment(data.created_at), "day", true) < 1 && (
            <Button
              onClick={() => {
                goToEdit(`/assessment/new/${data.id}/edit/?clientId=${data.clientId}`);
                onClose();
              }}
              variant="contained"
              color="primary"
              sx={{ margin: "0 5px" }}
            >
              {tl("edit")}
            </Button>
          )}
          {setAssessmentCopy && (
            <Button
              sx={{ margin: "0 5px" }}
              onClick={() => {
                setIsDialogOpen(true);
                onClose();
              }}
              variant="contained"
              color="primary"
            >
              {tl("assessment.carryForward")}
            </Button>
          )}
          <MultipleButtonGroup
            printFrom="assessment"
            options={[
              {
                label: "Print Default",
                onClickAction: () => ({}),
                component: (
                  <Print
                    buttonText="Print Default"
                    key="assessmentPrint"
                    assessment={data}
                    clientId={data.clientId}
                  />
                )
              },
              {
                label: "Print Doctor",
                onClickAction: () => ({}),
                component: (
                  <Print
                    buttonText="Print Doctor"
                    key="assessmentPrint"
                    assessment={data}
                    clientId={data.clientId}
                  />
                )
              }
            ]}
          />
        </div>
      </Grid>
    </Grid>
  </Modal>
);

export default connect(
  (state: RootState) => ({
    authenticable: state.userContext?.userCreds?.authenticable
  }),
  (dispatch) => ({
    goToEdit: (url) => dispatch(push(url))
  })
)(AssessmentModal);

import { isRequired } from '../helpers/validators';
export const fields = [
  {
    key: 'type',
    label: 'type',
    value: 'Static',
    inputType: 'select',
    editable: true,
    creatable: true,
    options: [
      { value: 'static', label: 'Static' },
      { value: 'dynamic', label: 'Dynamic' }
    ],
    required: true
  },
  {
    key: 'name',
    label: 'name',
    value: '',
    inputType: 'text',
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: 'Audience name is required' })]
  }
];

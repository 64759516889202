import * as React from 'react';
import { Box, Button } from '@mui/material';
import { connect } from 'react-redux';
import * as feedActions from '../../../actions/feed';
import { push } from 'connected-react-router';
import StatefulButton from '../../../components/StatefulButton/StatefulButton';
import styles from './FeedEditor.module.css';
import * as NotificationActions from '../../../actions/notification';

const FeedActions = ({ draft, actions }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Box
      className={styles.root}
      position={'fixed'}
      bottom={0}
      padding={'8px 32px'}
    >
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          data-testmation="goBack"
          variant="text"
          className={styles.billActionBtn}
          disabled={false}
          onClick={() => {
            actions.goBack(draft);
          }}
        >
          Go back
        </Button>
        <Button
          data-testmation="feedSaveAsDraft"
          className={styles.billActionBtn}
          disabled={false}
          onClick={async () => {
            try {
              setLoading(true);
              await actions.saveAsDraft(draft);
            } catch (error) {
              setLoading(false);
              actions.showErrorMessage();
            }
          }}
        >
          Save as draft
        </Button>
        <StatefulButton
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={async () => {
            if (!window.confirm('Do you really want to publish?')) {
              return;
            }
            try {
              setLoading(true);
              await actions.publishFeed();
            } catch (error) {
              setLoading(false);
              actions.showErrorMessage();
            }
          }}
          isLoading={loading}
          circularProgressProps={{ size: 16 }}
          data-testmation="feedPublish"
          className={styles.feedActionBtn}
        >
          <span>Publish</span>
        </StatefulButton>
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      showErrorMessage: () => {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: 'error',
            message: 'Oops! something went wrong.',
            autoTimeout: true,
          })
        );
      },
      saveAsDraft: async () => {
        await dispatch(feedActions.saveAsDraft());
        dispatch(feedActions.clearDraft());
        dispatch(push(`/feeds`));
      },
      goBack: () => {
        dispatch(feedActions.clearDraft());
        dispatch(push(`/feeds`));
      },
      publishFeed: async () => {
        await dispatch(feedActions.publishDraft());
        dispatch(feedActions.clearDraft());
        dispatch(push(`/feeds`));
      },
    },
  };
}

export default connect(null, mapDispatchToProps)(FeedActions);

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { updateUser } from "../../../actions/user";
import { IThunkDispatch, RootState } from "../../../store";
import { tl } from "../../../components/translate";

interface DesignationProps {
  designationText: string;
  onUserUpdate: (userObj) => Promise<void>;
}

export const Designation: React.FC<DesignationProps> = ({
  designationText,
  onUserUpdate
}): JSX.Element => (
  <Box mt={4}>
    <Typography style={{ fontWeight: 600 }}>{tl("signature.designation")} </Typography>
    <Typography style={{ textDecoration: "underline", fontSize: "0.75rem" }}>
      {tl("signature.designationNote")}{" "}
    </Typography>
    <TextField
      placeholder="Signature block"
      defaultValue={designationText}
      multiline
      rows={5}
      sx={{
        marginTop: "10px",
        width: "300px"
      }}
      onBlur={(e) => onUserUpdate({ designation: e.target.value })}
      variant="outlined"
    />
  </Box>
);

export default connect(
  (state: RootState) => ({
    designationText: state.userContext?.user?.designation
  }),
  (dispatch: IThunkDispatch) => ({
    onUserUpdate: (userObj) => {
      dispatch(updateUser(userObj));
    }
  })
)(Designation);

import React from "react";
import { Box, Typography } from "@mui/material";
import { tl } from "../../../components/translate";
import LabEntryAttachment from "./LabEntryAttachment";
import styles from "../Lab.module.css";
import {
  proseMirrorOptions,
  RichTextfield,
  deserializeNode
} from "../../../components/RichTextfield";
import { useParsedProseMirror } from "./LabTestSubRow";
import { LabRecord } from "../../../interfaces/Lab";

interface LabEntryFooterProps {
  onCommentChange: (val) => void;
  onFilesChange: (files) => void;
  labRecord: LabRecord;
  printMode: boolean;
  status: string;
}

const LabEntryFooter = ({
  onCommentChange,
  onFilesChange,
  labRecord,
  printMode,
  status
}: LabEntryFooterProps): JSX.Element => {
  const [comment, setComment] = React.useState("");
  const [state, setState] = useParsedProseMirror(
    labRecord.results.comment || "",
    proseMirrorOptions
  );

  React.useEffect(() => {
    if (labRecord.results.comment) setComment(labRecord.results.comment);
  }, [labRecord]);

  React.useEffect(() => {
    onCommentChange(deserializeNode(state.doc));
  }, [state]);

  return (
    <Box className={styles.labEntryFooter}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="70%">
          <Typography>
            <Box component="span" fontWeight="600">
              {tl("lab.comments")}:
            </Box>
          </Typography>
          {printMode ? <>{comment}</> : <RichTextfield state={state} setState={setState} />}
        </Box>
      </Box>
      {!printMode && (
        <Box>
          <LabEntryAttachment
            requireAuthentication
            readOnly={status === "Result Ready"}
            labEntryId={labRecord.id}
            onFilesChange={onFilesChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default LabEntryFooter;

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  setEmail: (value) => void;
  onResetPassword: () => void;
}
export default function ForgotPasswordModal({
  isOpen,
  onClose,
  email,
  setEmail,
  onResetPassword
}: Props): JSX.Element {
  return (
    <Dialog open={isOpen}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontWeight: "bold" }}>Reset Your Password</Typography>
        <Typography sx={{ cursor: "pointer" }} onClick={() => onClose()}>
          X
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: 10
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
          py={3}
        >
          <Typography mb={2}>Please enter your email for password recovery.</Typography>
          <TextField
            autoFocus
            fullWidth
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            variant="outlined"
            label="Email"
            InputLabelProps={{ shrink: true }}
          />
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => onResetPassword()}
              sx={{ marginTop: "10px" }}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { Booking } from "../interfaces/BookingInterfaces";

export const getDefaultSmsSubscriptions = (): { sendToSp: boolean; sendToClient: boolean } => ({
  sendToSp: true,
  sendToClient: true
});

export const getNewBooking = (defaults: Partial<Booking>): Partial<Booking> => {
  const { serviceProviderId, resourceCentreId, start, end, bookableResource } = defaults;
  return {
    id: null,
    start,
    end,
    clientId: null,
    clientName: null,
    clientPhoneNo: null,
    clientEmail: null,
    clientAddress: null,
    services: [],
    isVideoAppointment: false,
    paymentInfo: {
      amount: 0,
      isPaid: false,
      required: true
    },
    followUp: false,
    remarks: "",
    reasonOfVisit: "",
    reminders: [],
    resourceCentreId,
    serviceProviderId,
    resourceId: null,
    referredBy: "",
    knownFrom: "",
    bookableResource,
    ...getDefaultSmsSubscriptions()
  };
};

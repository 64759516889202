import React, { ReactElement } from "react";
import {
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Tooltip,
  Box
} from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import useCan from "../../hooks/useCan";
import useMobileScreen from "../../hooks/useMobileScreen";
import { tl } from "../../components/translate";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const DashboardButtonGroup = (): ReactElement => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const tempOptions = [
    {
      title: tl("button.createbill"),
      goto: "/billing/new",
      policy: useCan("bill:finalizeBill", {}),
      shortCut: "Alt + B"
    },
    {
      title: "Create Lab",
      goto: "/lab/labRecords/all/create",
      policy: useCan("lab:createOrder", {}),
      shortCut: "Alt + L"
    },
    {
      title: "Create Client",
      goto: "/clients/new",
      policy: useCan("clients:createClient", {}),
      shortCut: "Alt + C"
    }
  ];
  const options = tempOptions.filter((option) => option.policy);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    goto: string
  ) => {
    event.preventDefault();
    dispatch(push(goto));
    setOpen(false);
  };

  useHotkeys(
    "alt + b, alt + c, alt + l",
    (_, handler) => {
      switch (handler.key) {
        case "alt+b":
          dispatch(push("/billing/new"));
          mixpanelAnalytics.track(EVENT.DASHBOARD_CREATE_BILL_USING_SHORTCUT, {
            rcId: rc.id,
            rcName: rc.name
          });
          break;
        case "alt+c":
          mixpanelAnalytics.track(EVENT.DASHBOARD_CREATE_CLIENT_USING_SHORTCUT, {
            rcId: rc.id,
            rcName: rc.name
          });
          dispatch(push("/clients/new"));
          break;
        case "alt+l":
          mixpanelAnalytics.track(EVENT.DASHBOARD_CREATE_LAB_USING_SHORTCUT, {
            rcId: rc.id,
            rcName: rc.name
          });
          dispatch(push("/lab/labRecords/all/create"));
          break;
        default:
          break;
      }
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  const isMobileView = useMobileScreen();
  return (
    <Box display="flex" justifyContent={`${isMobileView ? "start" : "end"}`}>
      {options?.length > 0 && (
        <>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
            <Tooltip arrow title={options[0].shortCut}>
              <Button
                onClick={() => {
                  mixpanelAnalytics.track(EVENT.DASHBOARD_CREATE_BILL, {
                    rcId: rc.id,
                    rcName: rc.name
                  });
                  dispatch(push(options[0].goto));
                }}
              >
                {options[0].title}
              </Button>
            </Tooltip>
            {options.length > 1 && (
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select create"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            )}
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options?.slice(1)?.map((option) => (
                        <Tooltip key={option?.title} arrow title={option?.shortCut}>
                          <MenuItem
                            key={option?.title}
                            onClick={(event) => {
                              handleMenuItemClick(event, option?.goto);
                              if (option.goto.includes("lab")) {
                                mixpanelAnalytics.track(EVENT.DASHBOARD_CREATE_LAB, {
                                  rcId: rc.id
                                });
                              } else {
                                mixpanelAnalytics.track(EVENT.DASHBOARD_CREATE_CLIENT, {
                                  rcId: rc.id
                                });
                              }
                            }}
                          >
                            {option?.title}
                          </MenuItem>
                        </Tooltip>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Box>
  );
};

export default DashboardButtonGroup;

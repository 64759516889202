import React from 'react';
import { Box, Typography } from '@mui/material';
import Select, { components } from 'react-select';
import styles from './Assessment.module.css';
import { tl } from '../../../components/translate';

const PresentHealthStatus = (props) => {
  const { assessmentPresentHealthStatus, onChange } = props;

  const options = [
    { value: 'Fit', label: 'Fit' },
    { value: 'Unfit', label: 'Unfit' }
  ];
  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography>
          <Box component="span" fontWeight="600">
            Present Health Status
          </Box>
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <Select
          value={options.find((opt) => opt.value === assessmentPresentHealthStatus)}
          onChange={(newValue) => {
            onChange(newValue.value);
          }}
          id="controllable-states-demo"
          options={options}
          fullWidth
          placeholder="Select Present Health Status"
        />
      </Box>
    </Box>
  );
};

export default PresentHealthStatus;

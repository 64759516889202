import { ReducerBuilder } from './ReducerBuilder';
import * as VitalsActions from '../actions/vitals';

const INITIAL_STATE = {
  collection: [],
};

const setVitals = (state, payload) => {
  return { ...state, collection: payload };
};

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(VitalsActions.Type.GET_VITALS_BY_ID, setVitals)
  .mapAction(VitalsActions.Type.POST_VITALS, setVitals)
  .build();

export default reducer;

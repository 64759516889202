import React from "react";
import Capitalize from "lodash/capitalize";
import moment from "moment";
import { Box, Typography, Paper, Link, Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { push } from "connected-react-router";
import { connect, useDispatch } from "react-redux";
import { round } from "mathjs";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloseIcon from "@mui/icons-material/Close";
import { getStockProductById } from "../../../actions/stockProductActions";
import {
  TransactionItemInterface,
  StockTransactions,
  StockProducts,
  Status,
  TransactionType,
  DiscountBasis,
  paymentOptionsEnum
} from "../../../interfaces/StockInterfaces";
import styles from "../../Billing/BillActions/BillActions.module.css";
import Panel from "../../../components/Panel";
import { tl, t } from "../../../components/translate";
import StockRecordPayment from "./StockRecordPayment";
import StockProductEdit from "../StockCreateEdit/StockProductEdit";
import Can from "../../Policy/Can";
import { IThunkDispatch, RootState } from "../../../store";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import Modal from "../../../components/Modal/Modal";
import { paymentCancellation } from "../../../actions/stock";
import { rupeeDisplay } from "../../../helpers/rupee";
import useIsAccountSubscribed from "../../../hooks/accounts";

const trxTypes = {
  purchase: "Purchase",
  purchaseReturn: "Purchase Return",
  expiryOrDiscardment: "Expiry Or Discardment",
  internalUse: "Internal Use",
  internalReturn: "Internal Return",
  editPurchase: "Purchase Return",
  openingStockAdjustment: "Opening Stock Adjustment"
};

const returnTypes = {
  purchase: "purchaseReturn",
  internalUse: "internalReturn",
  editPurchase: "editPurchase",
  openingStock: "openingStock"
};

const editableTransaction = {
  purchase: "purchase",
  openingStock: "openingStock",
  purchaseReturn: "purchaseReturn"
};

interface StockTransactionViewProps {
  handlePanelClose: () => void;
  data: StockTransactions;
  products: Array<ProductInterface>;
  navigateTo: (url: string) => void;
  stockProducts: Array<StockProducts>;
  updateTrxRecordPaymentById: (id: number) => void;
  getProductById: (id: number) => void;
  wrapperStyle?: { [k: string]: string | number };
  onSupplierClick?: (spId: number) => void;
  transactionType: string;
  disableProductSelect?: boolean;
  showCreateBillButton?: boolean;
}

interface TransactionItemProps extends TransactionItemInterface {
  idx: number;
  setSelectedId: (productId: number) => void;
  transactionType: string;
  disableProductSelect: boolean;
}

const useStyles = makeStyles(() => ({
  otherFields: {
    flexBasis: "100px",
    textAlign: "center"
  },
  headerFields: {
    flexBasis: "100px",
    textAlign: "center",
    fontWeight: 600
  }
}));

export const TransactionItem: React.FC<TransactionItemProps> & {
  Headers: React.FunctionComponent;
} = ({
  idx,
  setSelectedId,
  productId,
  productName,
  expiryDate,
  batchId,
  quantity,
  unit,
  grossTotal,
  grossTotalOfFreeItems,
  transactionType,
  disableProductSelect
}) => {
  const isKitchenPharmacyEntryFrom = transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE;
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Box
      p="8px"
      display="flex"
      width="100%"
      minHeight="40px"
      alignItems="center"
      borderBottom="1px solid lightgrey"
    >
      <Typography style={{ width: "30px" }}>{idx}</Typography>
      <Typography
        style={{
          flexBasis: "140px",
          textDecoration: `${disableProductSelect ? "" : "underline"}`,
          cursor: "pointer"
        }}
        onClick={() => {
          if (!disableProductSelect) {
            setSelectedId(productId);
            dispatch(getStockProductById(productId));
          }
        }}
      >
        {productName}
      </Typography>
      {!isKitchenPharmacyEntryFrom && (
        <>
          <Typography className={classes.otherFields}>{moment(expiryDate).format("L")}</Typography>
          <Typography data-testmation="batchNumber" className={classes.otherFields}>
            {batchId}
          </Typography>
        </>
      )}
      <Typography
        data-testmation="quantity"
        className={classes.otherFields}
      >{`${quantity}`}</Typography>
      <Typography className={classes.otherFields}>{unit}</Typography>
      {!isKitchenPharmacyEntryFrom && (
        <>
          <Typography className={classes.otherFields}>
            {rupeeDisplay(grossTotal + (grossTotalOfFreeItems || 0))}
          </Typography>
        </>
      )}
    </Box>
  );
};

export const Headers: React.FunctionComponent = ({ transactionType }): JSX.Element => {
  const classes = useStyles();
  const isKitchenPharmacyPurchaseEntry =
    transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE;
  return (
    <Box p="8px" display="flex" mb="8px" borderBottom="2px solid lightgrey">
      <Typography style={{ width: "30px", fontWeight: 600 }}>SN</Typography>
      <Typography style={{ flexBasis: "140px", fontWeight: 600 }}>Product Name</Typography>
      {!isKitchenPharmacyPurchaseEntry && (
        <>
          <Typography className={classes.headerFields}>Expiry Date</Typography>
          <Typography className={classes.headerFields}>Batch Id</Typography>
        </>
      )}
      <Typography className={classes.headerFields}>Quantity</Typography>
      <Typography className={classes.headerFields}>Unit</Typography>
      {!isKitchenPharmacyPurchaseEntry && (
        <>
          <Typography className={classes.headerFields}>Total</Typography>
        </>
      )}
    </Box>
  );
};

TransactionItem.Headers = Headers;

const HistoryHeader = (): JSX.Element => (
  <Box display="flex" ml="20px">
    {["Date", "Amount", "Payment Method"].map((item) => (
      <Box key={item}>
        <Typography fontWeight={600} width="150px">
          {item}
        </Typography>
      </Box>
    ))}
  </Box>
);

const findDueAmountFromMultipleTrx = (data: StockTransactions): number => {
  const totalPaidFromReturnedTrx = data.returnedTrx.reduce(
    (acc, curr) => acc + curr.totalAmount,
    0
  );
  const dueAmount = round(data.totalAmount - data.paidAmount - totalPaidFromReturnedTrx || 0, 2);
  return dueAmount < 0 || !dueAmount ? 0 : dueAmount;
};

export interface PaymentHistoryProps {
  receivedOn: Date;
  id?: number;
  amount: number;
  paymentMethod: string;
  stockTransactionId: number;
  paymentRecordId: number;
}

const PaymentHistory = ({
  paymentHistory,
  transactionType,
  showPaymentCancellation
}): JSX.Element => {
  const [openCancelModal, setOpenCancelModal] = React.useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = React.useState({} as PaymentHistoryProps);
  const dispatch = useDispatch();
  const handlePaymentCancel = () => {
    dispatch(paymentCancellation(selectedPayment));
    setOpenCancelModal(false);
  };

  if (!paymentHistory?.length) return null;
  return (
    <>
      <Typography m="10px 0" fontWeight="bold">
        Payment History
      </Typography>
      <HistoryHeader />
      {paymentHistory.map(
        ({ receivedOn, id, paidAmount, paymentMethod, remarks, stockTransactionId, cancelled }) => (
          <div key={id}>
            <Box display="flex" alignItems="center">
              <ArrowRightAltIcon />
              <Typography width="130px" mr={3}>
                {convertADtoBS(moment(receivedOn)).formatted4}
              </Typography>
              <Typography width="130px" mr={3}>
                {paidAmount || 0}
              </Typography>
              <Typography width="150px">{paymentMethod || ""}</Typography>
              {transactionType === TransactionType.PURCHASE &&
                paymentMethod !== paymentOptionsEnum.credit &&
                (cancelled ? (
                  <Typography>Cancelled</Typography>
                ) : (
                  showPaymentCancellation && (
                    <IconButton
                      onClick={() => {
                        setOpenCancelModal(true);
                        setSelectedPayment({
                          amount: paidAmount,
                          paymentMethod,
                          receivedOn,
                          stockTransactionId,
                          paymentRecordId: id
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                ))}
            </Box>
            <Typography ml="20px">{remarks}</Typography>
          </div>
        )
      )}
      <Modal
        open={openCancelModal}
        title="Payment Cancellation"
        footer={
          <Button onClick={() => handlePaymentCancel()} variant="outlined">
            Ok
          </Button>
        }
        onClose={() => setOpenCancelModal(false)}
      >
        <Typography>
          {`Do you want cancel the payment of ${selectedPayment.amount} amount, paid through 
          ${selectedPayment.paymentMethod} ?`}
        </Typography>
      </Modal>
    </>
  );
};

const getSumOfIndividualVatAmt = (data: StockTransactions): number =>
  data.stockTransactionItems.reduce((acc, cur) => acc + (Number(cur.vatAmt) || 0), 0);

const StockTransactionView: React.FC<StockTransactionViewProps> = ({
  handlePanelClose,
  data,
  navigateTo,
  stockProducts,
  getProductById,
  wrapperStyle,
  onSupplierClick,
  disableProductSelect = false,
  showCreateBillButton = false
}) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  React.useEffect(() => {
    setSelectedProduct(stockProducts.find((item) => item.id === selectedId));
  }, [selectedId, stockProducts, data.id]);
  React.useEffect(() => setOpenMenu(false), [data.id]);
  React.useEffect(() => {
    setSelectedProduct(null);
  }, [data.id]);
  const isAccountSubscribed = useIsAccountSubscribed();

  React.useEffect(() => {
    const foundProduct = stockProducts.find((item) => item.id === selectedId);
    if (selectedId && !foundProduct) {
      getProductById(selectedId);
    }
  }, [selectedId, getProductById, stockProducts]);

  const dueAmount = findDueAmountFromMultipleTrx(data);

  return (
    <Panel
      onClose={handlePanelClose}
      title={`${t(data.transactionType)} ${
        data.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
          ? "Request"
          : "Transaction"
      }`}
      wrapperStyle={wrapperStyle}
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-end"
        padding="16px 24px"
        pb={2}
        className={styles.root}
      >
        {showCreateBillButton && (
          <Link
            component="button"
            className={styles.actionBtn}
            onClick={() => {
              navigateTo(`/billing/new?purchaseRequisitionFormId=${data.id}`);
            }}
          >
            <Typography style={{ fontSize: "0.675rem" }} className={styles.actionBtn}>
              Stock Transfer
            </Typography>
          </Link>
        )}
      </Box>

      {data.transactionType === TransactionType.PURCHASE_RETURN && data.status === Status.Draft && (
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          padding="16px 24px"
          pb={2}
          className={styles.root}
        >
          <Link
            component="button"
            className={styles.actionBtn}
            onClick={() => {
              navigateTo(
                `/stock/stockTransactions/${data.id}/${editableTransaction[data.transactionType]}`
              );
            }}
          >
            <Typography style={{ fontSize: "0.675rem" }} className={styles.actionBtn}>
              {tl("stock.editDraft")}
            </Typography>
          </Link>
        </Box>
      )}

      {returnTypes[data.transactionType] && (
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          padding="16px 24px"
          pb={2}
          className={styles.root}
        >
          {data.status === Status.Draft && (
            <Link
              component="button"
              className={styles.actionBtn}
              onClick={() => {
                navigateTo(
                  `/stock/stockTransactions/${data.id}/${editableTransaction[data.transactionType]}`
                );
              }}
            >
              <Typography style={{ fontSize: "0.675rem" }} className={styles.actionBtn}>
                {tl("stock.editDraft")}
              </Typography>
            </Link>
          )}
          {(data.status !== Status.Draft || !data.status) &&
            data.transactionType === TransactionType.OPENING_STOCK && (
              <Link
                data-testmation="createOpeningStockAdjustment"
                component="button"
                className={styles.actionBtn}
                onClick={() => navigateTo(`/stock/${data.id}/openingStockAdjustment`)}
              >
                <Typography style={{ fontSize: "0.675rem" }} className={styles.actionBtn}>
                  Create Opening Stock Adjustment
                </Typography>
              </Link>
            )}
          {(data.status !== Status.Draft || !data.status) &&
            data.transactionType !== TransactionType.OPENING_STOCK && (
              <>
                {data?.settings?.discountSettings?.discountBasis === DiscountBasis.INLINE &&
                  data.transactionType !== TransactionType.EDIT_PURCHASE && (
                    <Link
                      component="button"
                      className={styles.actionBtn}
                      onClick={() => navigateTo(`/stock/stockTransactions/${data.id}/editPurchase`)}
                    >
                      <Typography style={{ fontSize: "0.675rem" }} className={styles.actionBtn}>
                        {tl("stock.editPurchase")}
                      </Typography>
                    </Link>
                  )}
                <Can policyAccessKey="stock:returnPurchaseEntry">
                  <Link
                    data-testmation="createPurchaseReturn"
                    component="button"
                    className={styles.actionBtn}
                    onClick={() =>
                      navigateTo(
                        data.transactionType === TransactionType.PURCHASE
                          ? `/stock/purchaseReturn/${data.id}`
                          : `/stock/internalReturn/${data.id}`
                      )
                    }
                  >
                    <Typography style={{ fontSize: "0.675rem" }} className={styles.actionBtn}>
                      Create {trxTypes[returnTypes[data.transactionType]]}
                    </Typography>
                  </Link>
                </Can>
              </>
            )}
        </Box>
      )}
      <Box p={5}>
        <Box display="flex">
          <Typography style={{ width: "250px", fontWeight: 600 }}>Invoice No: </Typography>
          <Typography>{data.id || "-"}</Typography>
        </Box>

        <Box display="flex">
          <Typography style={{ width: "250px", fontWeight: 600 }}>Suplier Invoice No: </Typography>
          <Typography>{data.supplierInvoiceId || "-"}</Typography>
        </Box>

        <Box display="flex">
          <Typography style={{ width: "250px", fontWeight: 600 }}>Transaction Date: </Typography>
          <Typography>{moment(data.date).format("LL")}</Typography>
        </Box>

        <Box display="flex">
          <Typography style={{ width: "250px", fontWeight: 600 }}>Transaction Type: </Typography>
          <Typography>{tl(data.transactionType)}</Typography>
        </Box>

        <Box display="flex">
          <Typography sx={{ width: "250px", fontWeight: 600 }}>Supplier:</Typography>
          <Typography
            onClick={() => data.supplier && onSupplierClick && onSupplierClick(data.supplier.id)}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {data.supplier?.name || "-"}
          </Typography>
        </Box>

        {data.note && (
          <Box display="flex">
            <Typography style={{ width: "250px", fontWeight: 600 }}>Note:</Typography>
            <Typography>{data.note}</Typography>
          </Box>
        )}

        {![
          TransactionType.OPENING_STOCK,
          TransactionType.ADJUSTMENT,
          TransactionType.KITCHEN_PHARMACY_PURCHASE
        ].includes(data.transactionType as TransactionType) && (
          <>
            <Box display="flex" mt="32px" data-testmation="paymentType">
              <Typography style={{ width: "250px", fontWeight: 600 }}>Payment Type:</Typography>
              <Typography>{Capitalize(data.paymentType)}</Typography>
            </Box>

            {[TransactionType.SALES_RETURN].includes(data.transactionType as TransactionType) ? (
              <Box display="flex">
                <Typography sx={{ width: "250px", fontWeight: 600 }}>Total Amount:</Typography>
                <Typography>{rupeeDisplay(data.totalAmount)}</Typography>
              </Box>
            ) : (
              <>
                <Box display="flex">
                  <Typography sx={{ width: "250px", fontWeight: 600 }}>Gross Total</Typography>
                  {/* taxAmt = taxable amount /gross amount */}
                  <Typography>{rupeeDisplay(data.summary?.taxAmt)}</Typography>
                </Box>

                {data.transactionType === TransactionType.SALES && (
                  <Box display="flex">
                    <Typography sx={{ width: "250px", fontWeight: 600 }}>
                      Total discount:
                    </Typography>
                    <Typography>
                      {rupeeDisplay(round(data.summary?.discountAmt || 0, 2))}
                    </Typography>
                  </Box>
                )}

                <Box display="flex">
                  <Typography sx={{ width: "250px", fontWeight: 600 }}>
                    Round Off Amount:
                  </Typography>
                  <Typography>{rupeeDisplay(Number(data.summary?.roundOffAmt || 0))}</Typography>
                </Box>

                <Box display="flex">
                  <Typography sx={{ width: "250px", fontWeight: 600 }}>Total Vat:</Typography>
                  <Typography>{rupeeDisplay(round(getSumOfIndividualVatAmt(data), 2))}</Typography>
                </Box>

                <Box display="flex">
                  <Typography sx={{ width: "250px", fontWeight: 600 }}>
                    Total including Vat:
                  </Typography>
                  <Typography>{rupeeDisplay(round(data.summary?.totalIncVat || 0, 2))}</Typography>
                </Box>
              </>
            )}

            {data.transactionType !== TransactionType.PURCHASE_RETURN && (
              <Box mt="10px">
                <Box display="flex" data-testmation="paidAmount">
                  <Typography sx={{ width: "250px", fontWeight: 600 }}>Paid Amount:</Typography>
                  <Typography>{rupeeDisplay(round(data.paidAmount || 0, 2))}</Typography>
                </Box>

                <Box display="flex" data-testmation="dueAmount">
                  <Typography sx={{ width: "250px", fontWeight: 600 }}>Due Amount:</Typography>
                  <Typography>
                    {rupeeDisplay(
                      round(Number(data.totalAmount) - Number(data.paidAmount) || 0, 2)
                    )}
                  </Typography>
                </Box>
                {data.transactionType === TransactionType.PURCHASE && data.related && (
                  <Box display="flex">
                    <Typography style={{ width: "250px", fontWeight: 600 }}>
                      Due Amount After Debit Note:
                    </Typography>
                    <Typography>{rupeeDisplay(round(dueAmount || 0, 2))}</Typography>
                  </Box>
                )}
              </Box>
            )}

            {data.status === Status.Finalize && (
              <>
                <PaymentHistory
                  showPaymentCancellation={!data.referenceTo && !isAccountSubscribed}
                  paymentHistory={data?.receipts}
                  transactionType={data.transactionType}
                />
                {dueAmount > 0 &&
                  [TransactionType.PURCHASE].includes(data.transactionType as TransactionType) && (
                    <div>
                      <Button
                        onClick={() => setOpenMenu(true)}
                        style={{ cursor: "pointer" }}
                        data-testmation="billInfo.recordPayment"
                      >
                        {tl("billing.recordPayment")}
                      </Button>
                      <div>
                        {openMenu && (
                          <StockRecordPayment entry={data} onClose={() => setOpenMenu(false)} />
                        )}
                      </div>
                    </div>
                  )}
              </>
            )}
          </>
        )}

        <Typography style={{ fontWeight: 600, marginTop: "32px" }}>Transaction Items</Typography>
        <Paper style={{ marginTop: "8px" }}>
          <TransactionItem.Headers transactionType={data.transactionType} />
          {data?.stockTransactionItems?.map((trxItem, i) => (
            <TransactionItem
              transactionType={data.transactionType}
              disableProductSelect={disableProductSelect}
              key={`${trxItem.batchId}${trxItem.productId}`}
              setSelectedId={setSelectedId}
              productName={trxItem.productName || ""}
              idx={i + 1}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...trxItem}
            />
          ))}
        </Paper>
      </Box>
      {selectedProduct && (
        <StockProductEdit
          wrapperStyle={{ minWidth: "645px", maxWidth: "680px" }}
          data={selectedProduct}
          id={selectedId}
          handleClose={() => {
            setSelectedProduct(null);
            setSelectedId(null);
          }}
          editMode
        />
      )}
    </Panel>
  );
};

StockTransactionView.defaultProps = {
  wrapperStyle: {},
  onSupplierClick: () => null
};

export default connect(
  (state: RootState) => ({
    stockProducts: state.stockProducts.collection
  }),
  (dispatch: IThunkDispatch) => ({
    navigateTo: (url) => dispatch(push(url)),
    getProductById: (id) => dispatch(getStockProductById(id))
  })
)(StockTransactionView);

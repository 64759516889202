import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import List from "../../components/List";
import { tl } from "../../components/translate";
import ReferrerCreateEdit from "./ReferrerCreateEdit";
import { getReferrers } from "../../actions/referrers";
import { Referrer } from "../../interfaces/ReferrerInterface";
import Can from "../Policy/Can";
import { RootState } from "../../store";
import ReferrerDetails from "./ReferrerDetails";

export enum Mode {
  CREATE = "create",
  EDIT = "edit",
  READ_ONLY = "readOnly"
}

export type Modes = typeof Mode[keyof typeof Mode];

const { useEffect, useState } = React;

function Referrers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReferrers());
  }, [dispatch]);

  const [selected, setSelected] = useState(null);
  const [mode, setMode] = useState<Modes | null>(null);
  const referrers: Array<Referrer> = useSelector((state: RootState) => state.referrers.referrers);
  const closePanel = () => setMode(null);
  const setEditMode = () => setMode(Mode.EDIT);

  return (
    <Can policyAccessKey="clinic:listReferrers">
      <div style={{ height: "calc(100vh - 80px)" }}>
        <List<Referrer>
          automation="ReferresList"
          title={tl("settings.referrers")}
          createLabel={tl("settings.createReferrer")}
          testmationLabel="createReferrer"
          rowHeight={50}
          defaultSortColumn="referrer"
          columns={[
            { key: "referrer", label: tl("referrer.name"), sortable: true },
            { key: "email", label: tl("referrer.email"), sortable: true },
            { key: "phone", label: tl("referrer.phone"), sortable: true },
            { key: "address", label: tl("referrer.address"), sortable: true }
          ]}
          data={referrers}
          activeRow={null}
          onRowClick={(row) => {
            setSelected(row);
            setMode(Mode.READ_ONLY);
          }}
          onCreateNew={() => setMode("create")}
        />
        {mode === Mode.READ_ONLY && (
          <ReferrerDetails onClose={closePanel} onEdit={setEditMode} referrer={selected} />
        )}
        {[Mode.EDIT, Mode.CREATE].includes(mode as Modes) && (
          <Can policyAccessKey={["clinic:createReferrers", "clinic:editReferrers"]} partialCheck>
            <ReferrerCreateEdit
              mode={mode}
              handleClose={() => setMode(mode === Mode.CREATE ? null : Mode.READ_ONLY)}
              selected={selected}
            />
          </Can>
        )}
      </div>
    </Can>
  );
}

function mapStateToProps(state) {
  return { referrers: state.referrers.referrers || [] };
}

export default connect(mapStateToProps, { push, getReferrers })(Referrers);

import React from "react";
import { match as matchInterface } from "react-router";
import { tl } from "../../components/translate";
import MulDartaList from "./MulDarta/MulDartaList";
import OpdRegisterList from "./OpdRegister/OpdRegisterList";
import ImnciRegisterAboveTwoMonthsList from "./ImnciRegister/AboveTwoMonths/ImnciRegisterAboveTwoMonthsList";
import ImnciRegisterBelowTwoMonthsList from "./ImnciRegister/BelowTwoMonths/ImnciRegisterBelowTwoMonthsList";

interface Props {
  match: matchInterface<{ [x: string]: string }>;
  mode?: string;
}

export const hmisMultipleHeaders = (match: {
  url: string;
}): {
  headers: Array<{ key: string; title: JSX.Element | string; goto: string }>;
  url: string;
} => ({
  headers: [
    { key: "mulDarta", title: tl("hmis.mulDarta"), goto: "/hmis/mulDarta" },
    { key: "opd", title: tl("hmis.opdRegister"), goto: "/hmis/opd" },
    { key: "aboveTwoMonths", title: "Imnci Above 2 Months", goto: "/hmis/imnci/aboveTwoMonths" },
    { key: "belowTwoMonths", title: "Imnci Below 2 Months", goto: "/hmis/imnci/belowTwoMonths" }
  ],
  url: match.url
});

const Hmis = ({ match, mode }: Props): JSX.Element => (
  <div>
    {match.path.includes("mulDarta") && <MulDartaList match={match} mode={mode} />}
    {match.path.includes("opd") && <OpdRegisterList match={match} mode={mode} />}
    {match.path.includes("aboveTwoMonths") && (
      <ImnciRegisterAboveTwoMonthsList match={match} mode={mode} />
    )}
    {match.path.includes("belowTwoMonths") && (
      <ImnciRegisterBelowTwoMonthsList match={match} mode={mode} />
    )}
  </div>
);

export default Hmis;

import { BillDocumentProps } from "../containers/Billing/Comission/ReferrerCommission";
import { Commission, VendorCommission } from "../interfaces/Commission";
import { Get, Post } from "./apiHelper";

const commissionRoot = "/api/commission";

export async function getCommissionsByBillId(billId: number): Promise<Commission[]> {
  const response = await Get(`${commissionRoot}/${billId}`);
  return response.data as Commission[];
}

export async function postComission(data: Commission): Promise<Commission> {
  const response = await Post(`${commissionRoot}`, data, true);
  return response.data as Commission;
}

export async function getBillsForReferrerCommission(
  referrerId: number
): Promise<BillDocumentProps[]> {
  const response = await Get(`${commissionRoot}/referrer/${referrerId}`);
  return response.data as BillDocumentProps[];
}

export async function postReferrerCommission(
  data: VendorCommission[]
): Promise<VendorCommission[]> {
  const response = await Post(`${commissionRoot}/referrer`, data, true);
  return response.data as VendorCommission[];
}

export async function getBillsForServiceProviderCommission({
  id,
  filters
}: {
  id: number;
  filters: { page: number; size: number };
}): Promise<{ results: BillDocumentProps[]; total: number }> {
  const response = await Get(
    `${commissionRoot}/serviceProvider?billId=${id}&page=${filters.page}&size=${filters.size}`
  );
  return response.data as { results: BillDocumentProps[]; total: number };
}

export async function postServiceProviderCommission(
  data: VendorCommission[]
): Promise<VendorCommission[]> {
  const response = await Post(`${commissionRoot}/serviceProvider`, data, true);
  return response.data as VendorCommission[];
}

import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { RootState } from "../store/index";
import { ResourceCentreLocation } from "../interfaces/PublicLabInterface";
import { getResourceCentreLocations } from "../slices/publicLab";

// eslint-disable-next-line import/prefer-default-export
export const useResourceCentreLocations = (): ResourceCentreLocation[] => {
  const dispatch = useDispatch();
  const resourceCentreLocations = useSelector(
    (state: RootState) => state.public.resourceCentreLocations
  );
  const hasRcLocations = resourceCentreLocations?.length > 0;
  React.useEffect(() => {
    if (!hasRcLocations) {
      dispatch(getResourceCentreLocations(dispatch));
    }
  });
  return resourceCentreLocations;
};

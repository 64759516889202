import { createResourceActions } from "./actionHelpers";

export * from "./slot";
export * from "./services";
export const resourceCentreActions = createResourceActions("resourceCentre");
export const resourceCentreEmployeeActions = createResourceActions("resourceCentreEmployee");
export const serviceProviderActions = createResourceActions("resourceCentreServiceProvider");
export const supplierActions = createResourceActions("supplier");

export type ResourceCentreActionsType = typeof resourceCentreActions;

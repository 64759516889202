import produce from "immer";
import { ReducerBuilder } from "./ReducerBuilder";
import { Type } from "../actions/bookableResource";
import { IBookableResource } from "../interfaces/BookableResources";
import { IServiceTag } from "../interfaces/ServiceTagsInterface";

interface State {
  collection: Array<IBookableResource>;
  serviceTags: Array<IServiceTag>;
}

const INITIAL_STATE: State = {
  collection: [],
  serviceTags: []
};

function setBookableResources(state, payload) {
  return { ...state, collection: payload };
}

function setServiceTags(state, payload) {
  return { ...state, serviceTags: payload };
}

function setBookableResource(state, payload) {
  const newState = produce<State>(state, (draft) => {
    draft.collection.push(payload);
  });
  return newState;
}

function updateBookableResource(state, payload) {
  const newState = produce<State>(state, (draft) => {
    const idx = draft.collection.findIndex((item) => item.id === payload.id);
    draft.collection[idx] = payload;
  });
  return newState;
}

function deactivateBookableResource(state, payload) {
  const newState = produce<State>(state, (draft) => {
    draft.collection = draft.collection.filter((el) => el.id !== payload.id);
  });
  return newState;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(Type.GET_BOOKING_RESOURCE, setBookableResources)
  .mapAction(Type.POST_BOOKING_RESOURCE, setBookableResource)
  .mapAction(Type.UPDATE_BOOKING_RESOURCE, updateBookableResource)
  .mapAction(Type.DEACTIVATE_BOOKING_RESOURCE, deactivateBookableResource)
  .mapAction(Type.GET_SERVICETAGS, setServiceTags)
  .mapAction(Type.UPLOAD_ICON_IMAGE, setBookableResource)
  .mapAction(Type.UPDATE_ICON_IMAGE, updateBookableResource)
  .build();

export default reducer;

import { ManualInvoiceInterface } from "../containers/ResourceCentreInvoices/ManualnvoiceCreatePanel";
import {
  KhaltiVerificationPayload,
  PendingInvoice,
  Subscription,
  SubscriptionHistory,
  SubscriptionInvoice,
  SubscriptionInvoices,
  SubscriptionPaymentVerificationResponse
} from "../interfaces/Subscription";
import { Get, Patch, Post } from "./apiHelper";

const subscriptionRoot = "/api/subscriptions";

export const subscriptionPost = async (data: Partial<Subscription>): Promise<Subscription> => {
  const response = await Post(`${subscriptionRoot}`, data, true);
  return response.data as Subscription;
};

export const subscriptionPatch = async (data: Partial<Subscription>): Promise<Subscription> => {
  const response = await Patch(`${subscriptionRoot}/${data.id}`, data, true);
  return response.data as Subscription;
};

export const subscriptionsTemplatesGet = async (): Promise<Array<Subscription>> => {
  const response = await Get(`${subscriptionRoot}/templates`, true);
  return response.data as Array<Subscription>;
};

export const subscriptionGet = async (rcId: number): Promise<Subscription> => {
  const response = await Get(`${subscriptionRoot}/resourceCentres/${rcId}`, true);
  return response.data as Subscription;
};

export const terminateSubscription = async (sid: number): Promise<unknown> => {
  const response = await Patch(`${subscriptionRoot}/terminate/${sid}`, true);
  return response.data;
};

export const subscriptionInvoicePost = async (
  data: Partial<SubscriptionInvoice>
): Promise<SubscriptionInvoice> => {
  const response = await Post("/api/subscriptionInvoices", data, true);
  return response.data as SubscriptionInvoice;
};

export const manualSubscriptionInvoicePost = async (
  data: ManualInvoiceInterface & { resourceCentreId: number }
): Promise<SubscriptionInvoices> => {
  const response = await Post("/api/subscriptionInvoices/create/superAdmin", data, true);
  return response.data as SubscriptionInvoices;
};

export const verifySubscriptionInvoice = async (id: number): Promise<SubscriptionInvoices> => {
  const response = await Patch(`/api/subscriptionInvoices/${id}/verify/superAdmin`, {}, true);
  return response.data as SubscriptionInvoices;
};

export const patchInvoiceAmountSuperAdmin = async (
  id: number,
  amount: number
): Promise<SubscriptionInvoices> => {
  const response = await Patch(
    `/api/subscriptionInvoices/${id}/patchAmount/superAdmin`,
    { amount },
    true
  );
  return response.data as SubscriptionInvoices;
};

export const subscriptionInvoicePatch = async (
  data: Partial<SubscriptionInvoice>,
  id: number
): Promise<SubscriptionInvoice> => {
  const response = await Patch(`/api/subscriptionInvoices/${id}`, data, true);
  return response.data as SubscriptionInvoice;
};

export const subscriptionInvoicesGet = async (
  rcId: number
): Promise<Array<SubscriptionInvoice>> => {
  const response = await Get(`/api/subscriptionPayment/resourceCentres/${rcId}`, true);
  return response.data as Array<SubscriptionInvoice>;
};

export const subscriptionInvoiceWarningsGet = async (): Promise<Array<PendingInvoice>> => {
  const response = await Get(`/api/subscriptionInvoices/warnings`, true);
  return response.data as Array<PendingInvoice>;
};

export const activateSubscription = async (sid: number): Promise<unknown> => {
  const response = await Patch(`${subscriptionRoot}/activate/${sid}`, true);
  return response.data;
};

export const getSubscriptionHistory = async (rcId: number): Promise<Array<SubscriptionHistory>> => {
  const response = await Get(`/api/subscriptions/history/${rcId}`, true);
  return response.data as Array<SubscriptionHistory>;
};

export const fetchSubscriptionInvoices = async (
  resourceCentreId: number
): Promise<Array<SubscriptionInvoices>> => {
  const response = await Get(
    `/api/subscriptionInvoices?resourceCentreId=${resourceCentreId}`,
    true
  );
  return response.data as Array<SubscriptionInvoices>;
};

export const postSMSInvoice = async (
  data: {
    smsQuantity: number;
    invoiceAmount: number;
    invoiceNumber: number;
  },
  resourceCentreId: number
): Promise<SubscriptionInvoices> => {
  const response = await Post(`/api/smsInvoices?resourceCentreId=${resourceCentreId}`, data, true);
  return response.data as SubscriptionInvoices;
};

export const cancelInvoice = async (id: number): Promise<SubscriptionInvoices> => {
  const response = await Patch(`/api/subscriptionInvoices/${id}/cancel`, true);
  return response.data as SubscriptionInvoices;
};

export const makePaymentIntent = async (invoiceId: number): Promise<Record<string, any>> => {
  const response = await Post(
    `/api/subscriptionInvoices/intent`,
    { subscriptionInvoiceId: invoiceId },
    true
  );
  return response.data;
};

export const paySMSInvoiceSuperAdminApi = async (
  invoiceId: number
): Promise<SubscriptionInvoices> => {
  const response = await Post(`/api/subscriptionInvoices/${invoiceId}/superAdminPay`, {}, true);
  return response.data as SubscriptionInvoices;
};

export const confirmPayment = async ({
  pidx,
  // eslint-disable-next-line camelcase
  purchase_order_id,
  provider
}: KhaltiVerificationPayload): Promise<SubscriptionPaymentVerificationResponse> => {
  const response = await Post(
    `/api/subscriptionInvoices/verify`,
    { pidx, purchase_order_id, provider },
    true
  );
  return response.data as SubscriptionPaymentVerificationResponse;
};

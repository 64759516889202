import * as React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { Redirect, Route } from 'react-router';
import FeedEditor from './Editor';
import FeedList from './FeedList';
import { isAdmin } from '../NavigationBar/SideBar';
import { MODE } from '../../interfaces/FeedInterfaces';

const Feed = ({ match, isAdmin }) => {
  if (!isAdmin) {
    return <Redirect to="/" />;
  }
  return (
    <Box height={'100%'}>
      <Route
        exact
        path={`${match.path}/new`}
        render={(props) => {
          return <FeedEditor {...props} />;
        }}
      />
      <Route
        exact
        path={`${match.path}/:feedId/edit`}
        render={(props) => {
          return <FeedEditor {...props} mode={'edit'} feedId={props.match.params.feedId} />;
        }}
      />

      <Route
        exact
        path={`${match.path}/:feedId?`}
        render={(props) => {
          const feedId = props.match.params.feedId;
          return (
            (typeof feedId === 'undefined' || isNaN(feedId)) && (
              <FeedList {...props} mode={MODE.Show} feedId={feedId} />
            )
          );
        }}
      />
    </Box>
  );
};

export default connect((state) => {
  return {
    isAdmin: isAdmin(state?.userContext?.mode)
  };
})(Feed);

import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { tl } from "../../../components/translate";
import List from "../../../components/List";
import TemplateCreateEdit from "./TemplateCreateEdit";
import * as templateActions from "../../../actions/template";
import { templatesSelector } from "../../../reducers/template";
import TemplateInfo from "./TemplateInfo";
import "./TemplateList.scss";
import { TemplateData } from "./TemplateHelpers";
import Can from "../../Policy/Can";
import { multipleHeaders } from "../Campaigns/CampaignList";

interface TemplateListProps {
  actions;
  mode: string;
  templateList: TemplateData[];
  match: any;
  policies: { [k: string]: boolean };
}

const TemplateList: React.FC<TemplateListProps> = ({
  actions,
  match,
  mode,
  templateList,
  policies
}) => {
  React.useEffect(() => {
    if (!templateList || !templateList.length) actions.loadTemplate();
  }, []);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  React.useEffect(() => {
    const templateId = match.params.id;
    if (templateId && templateList.length > 0) {
      const queryTemplate = templateList.find((template) => template.id === Number(templateId));
      setSelectedTemplate(queryTemplate);
    } else {
      setSelectedTemplate(null);
    }
  }, [match.params.id, templateList]);
  return (
    <Box className="templateList">
      <List
        testmationLabel="createTemplate"
        automation="templateList"
        rowHeight={40}
        multipleHeaders={multipleHeaders(match)}
        createLabel={tl("campaign.createTemplate")}
        hideCreateButton={!policies["campaign:createTemplate"]}
        onCreateNew={() => actions.goto("/campaign/template/new")}
        defaultSortColumn="name"
        data={templateList || []}
        columns={[
          {
            key: "name",
            label: "Name",
            sortable: true,
            sortFunction: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
            formatter: (a) => <Typography>{a.name ? a.name : ""}</Typography>
          },
          {
            key: "mediaType",
            label: "Type",
            sortable: true,
            sortFunction: (a, b) =>
              a.mediaType.toLowerCase() > b.mediaType.toLowerCase() ? 1 : -1,
            formatter: (a) => <Typography>{a.mediaType ? capitalize(a.mediaType) : ""}</Typography>
          },
          {
            key: "message",
            label: "Content",
            formatter: (a) => (
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                {a.message ? capitalize(a.message) : ""}
              </Typography>
            )
          }
        ]}
        isLoading={!templateList}
        activeRow={selectedTemplate && selectedTemplate.id}
        onRowClick={async (template) => {
          const notSelected = !selectedTemplate || selectedTemplate.id !== template.id;
          setSelectedTemplate(notSelected ? template : null);
          actions.goto(notSelected ? `/campaign/template/${template.id}` : "/campaign/template/");
        }}
      />
      {(selectedTemplate || ["create", "edit"].includes(mode)) && (
        <>
          {["create", "edit"].includes(mode) ? (
            <Can
              policyAccessKey={["campaign:createTemplate", "campaign:editTemplate"]}
              partialCheck
            >
              <TemplateCreateEdit
                onCancel={() => {
                  setSelectedTemplate(null);
                  actions.goto("/campaign/template");
                }}
                mode={mode}
                templateId={selectedTemplate?.id}
              />
            </Can>
          ) : (
            <TemplateInfo
              template={selectedTemplate}
              onClose={() => {
                setSelectedTemplate(null);
                actions.goto("/campaign/template");
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state, { templateId }: { templateId?: number }) => ({
  templateList: templatesSelector(state),
  template: state.template.collection.find(({ id }) => id === Number(templateId)),
  policies: state.userContext.policies || {}
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    goto: (url) => dispatch(push(url)),
    loadTemplate: () => dispatch(templateActions.getTemplates())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);

import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import FaceIcon from "@mui/icons-material/Face";
import PhoneIcon from "@mui/icons-material/Phone";
import * as notificationActions from "../../actions/notification";
import { serviceProviderActions } from "../../actions/index";
import { fetchSlot, bookSlot, removeSlot } from "../../actions/slot";
import { updateBooking } from "../../actions/booking";
import { navigateRemoveModal } from "../../actions/navigation";
import { tl, t } from "../../components/translate";
import { clientSearch } from "../../api/bookings";
import ActionBar from "../../components/ActionBar/ActionBar";
import ClientNameWithInfoModal from "../Client/ClientInfoModal";
import ServicesSelect from "../Services/ServicesAutoSelect";
import EventProvider from "./EventProvider";
import FollowUp from "./FollowUp";
import IsVideoAppointment from "./IsVideoAppointment";
import Search from "./SearchInput";
import { isNepaliPhoneNumber } from "../../helpers/validators";
import styles from "./BookingCreateEdit.module.css";
import { clientFullNameSelector } from "../../reducers/client";
import StatefulButton from "../../components/StatefulButton/StatefulButton";
import { RootState } from "../../store";

interface propsType {
  serviceProviderId: number;
  serviceProvider: any;
  slot: any;
  booking?: any;
  next?: () => void;
  bookSlot: (
    {
      slotId,
      serviceProviderId,
      reasonOfVisit,
      clientId,
      clientName,
      clientPhoneNo,
      followUp,
      reminders,
      services
    },
    next
  ) => void;
  updateBooking: (
    bookingId,
    { reasonOfVisit: string, services, followUp, reminders },
    next
  ) => void;
  deleteSlot: (slotId, next: () => void) => void;
  getServiceProvider: (id: number) => void;
  onClose: () => void;
  resourceCentreVideoSetting?: boolean;
}

interface StateType {
  client: any;
  clientId?: null;
  clientName: string;
  clientAddress: string;
  clientPhoneNo: string;
  reasonOfVisit: string;
  errors: {};
  showBox: boolean;
  followUp: boolean;
  reminders: Array<any>;
  services: Array<number>;
  isVideoAppointment: boolean;
  isLoading: boolean;
}
class SlotBookModal extends React.Component<propsType, StateType> {
  constructor(props) {
    super(props);
    const { booking } = props;
    const client = booking ? booking.client : null;
    this.state = {
      client: client ? client : null,
      clientId: client ? client.id : null,
      showBox: false,
      errors: {},
      clientName: client ? clientFullNameSelector(client) : "",
      clientAddress: client ? client.address : "",
      clientPhoneNo: client ? client.phone : "",
      reasonOfVisit: booking ? booking.reasonOfVisit : "",
      followUp: booking ? booking.followUp : false,
      reminders: (booking || {}).reminders,
      services: booking ? booking.services : [],
      isVideoAppointment: Boolean(booking?.isVideoAppointment),
      isLoading: false
    };
    this.getStateDataFn = this.getStateDataFn.bind(this);
  }

  public componentDidMount() {
    if (!this.props.serviceProvider) {
      this.props.getServiceProvider(this.props.serviceProviderId);
    }
    if (!this.props.slot) {
      this.props.fetchSlot(this.props.slotId);
    }
  }

  private handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  getDefaultReminders = (targetDate) => {
    const remindOn = targetDate.subtract(3, "day").toDate();
    return [
      {
        on: remindOn,
        remark: ""
      }
    ];
  };

  getStateDataFn(data) {
    this.setState({
      showBox: true,
      clientName: clientFullNameSelector(data),
      clientPhoneNo: data.phone
    });
  }

  public render() {
    if (!this.props.serviceProvider) return null;
    const {
      serviceProvider,
      slot,
      serviceProviderId,
      next,
      bookSlot,
      onClose,
      booking,
      updateBooking
    } = this.props;
    const { state } = this;
    const isEditMode = !!booking;
    const actions = [];
    if (!isEditMode) {
      actions.push({
        label: tl("booking.removeSlot"),
        action: () => this.props.deleteSlot(slot.id, onClose),
        Icon: DeleteIcon
      });
    }
    async function search(q) {
      return clientSearch(q).then((result) => {
        return result.map((item) => {
          item.name = clientFullNameSelector(item);
          return item;
        });
      });
    }
    return (
      <div className={styles.root}>
        <DialogTitle>
          <Box component={"span"} fontSize={"1.2rem"} fontWeight={500}>
            {isEditMode ? tl("booking.editAppointment") : tl("booking.bookAppointment")}
          </Box>
          <ActionBar actions={actions} />
        </DialogTitle>
        <DialogContent>
          <Grid container style={{ flexGrow: 1 }}>
            {slot && <EventProvider slot={slot} serviceProvider={serviceProvider} />}

            {this.state.showBox || isEditMode ? (
              <Card classes={{ root: styles.clientInfoRoot }}>
                <Grid container>
                  <Grid container>
                    <Grid item xs={1} lg={1}>
                      <FaceIcon className={styles.icon} />
                    </Grid>
                    <Grid item xs={9} lg={9}>
                      <ClientNameWithInfoModal
                        fontWeight={600}
                        client={this.state.client}
                        clientId={this.state.clientId}
                      />
                    </Grid>
                    <Grid item xs={2} lg={2} className={styles.clearIconContainer}>
                      {!isEditMode && (
                        <IconButton
                          className={styles.clearIcon}
                          onClick={() => {
                            this.setState({
                              showBox: false,
                              client: null,
                              clientPhoneNo: undefined,
                              clientName: undefined
                            });
                          }}
                          size="large"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={1} lg={1}>
                      <PhoneIcon className={styles.icon} />
                    </Grid>
                    <Grid item xs={11} lg={11}>
                      <Typography>
                        <Box
                          className={styles.alignVerticallyMiddle}
                          component={"span"}
                          fontSize={"0.875rem"}
                          fontWeight={500}
                        >
                          {state.clientPhoneNo}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <>
                <Grid item xs={12} lg={12} className={styles.mrgnTop}>
                  <Search
                    name="name"
                    searchFn={search}
                    onChange={(clientName) => {
                      this.setState({
                        clientName: clientName.length > 50 ? clientName.slice(0, 50) : clientName
                      });
                    }}
                    label={t("booking.clientname")}
                    onItemSelect={(client) => {
                      this.setState({
                        showBox: true,
                        client: client,
                        clientPhoneNo: client.phone,
                        clientName: client.name
                      });
                    }}
                    itemRenderer={({ name, phone }) => (
                      <>
                        <ListItem button className={styles.drpDwnPadding}>
                          <ListItemText>
                            <div className={styles.primaryText}>{name}</div>
                            <div className={styles.sndryText}>{phone}</div>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={12} className={styles.mrgnTop}>
                  <Search
                    name="phone"
                    searchFn={search}
                    onChange={(phoneNum) => {
                      this.setState({ clientPhoneNo: phoneNum });
                    }}
                    label={t("phone")}
                    onItemSelect={(client) => {
                      this.setState({
                        showBox: true,
                        client: client,
                        clientPhoneNo: client.phone,
                        clientName: client.name
                      });
                    }}
                    itemRenderer={({ name, phone }) => (
                      <>
                        <ListItem button className={styles.drpDwnPadding}>
                          <ListItemText>
                            <div className={styles.primaryText}>{phone}</div>
                            <div className={styles.sndryText}>{name}</div>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </>
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} lg={12}>
              <TextField
                id="outlined-reasonOfVisit"
                className={styles.input}
                label={tl("booking.reasonOfVisit")}
                value={state.reasonOfVisit}
                onChange={(e) => this.handleChange("reasonOfVisit", e.target.value)}
                margin="dense"
                multiline
                maxRows="6"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <ServicesSelect
                values={this.state.services}
                onChange={(val) => this.setState({ services: val })}
              />
            </Grid>
            {this.props.slot && (
              <Grid item xs={12} lg={12}>
                <FollowUp
                  value={this.state.followUp}
                  editable={true}
                  reminders={this.state.reminders}
                  maxValidDate={moment(this.props.slot.from)}
                  defaultDate={moment(this.props.slot.from).add(1, "month").toDate()}
                  onChange={(followUp, reminders) => {
                    if (followUp && (!reminders || reminders.length == 0)) {
                      reminders = this.getDefaultReminders(moment(this.props.slot.from));
                    }
                    this.setState({ followUp, reminders });
                  }}
                />
              </Grid>
            )}
            {this.props.resourceCentreVideoSetting && (
              <Grid item xs={12} lg={12}>
                <IsVideoAppointment
                  value={this.state.isVideoAppointment}
                  onChange={(val) => {
                    this.setState({ isVideoAppointment: val });
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={() => onClose()}
            title={"Book an appointment"}
          >
            {tl("booking.cancel")}
          </Button>
          <StatefulButton
            variant="contained"
            circularProgressProps={{ size: 16 }}
            color="primary"
            disabled={
              !(state.clientName && isNepaliPhoneNumber({ msg: "" })(state.clientPhoneNo)) ||
              (!this.state.showBox &&
                this.state.errors &&
                this.state.errors.messages &&
                this.state.errors.messages.length) ||
              this.state.isLoading
            }
            isLoading={this.state.isLoading}
            onClick={async () => {
              this.setState({ isLoading: true });
              if (isEditMode) {
                await updateBooking(
                  booking.id,
                  {
                    reasonOfVisit: state.reasonOfVisit,
                    services: state.services,
                    followUp: state.followUp,
                    reminders: state.reminders,
                    isVideoAppointment: state.isVideoAppointment
                  },
                  next
                );
              } else {
                const bookData = {
                  slotId: slot.id,
                  serviceProviderId,
                  reasonOfVisit: state.reasonOfVisit,
                  followUp: state.followUp,
                  reminders: state.followUp ? state.reminders : [],
                  services: state.services,
                  isVideoAppointment: state.isVideoAppointment
                };

                if (state.client) {
                  bookData["clientId"] = state.client.id;
                } else {
                  bookData["clientName"] = state.clientName;
                  bookData["clientPhoneNo"] = state.clientPhoneNo;
                }

                await bookSlot({ ...bookData }, next);
              }
              this.setState({ isLoading: false });
            }}
          >
            {tl("booking.save")}
          </StatefulButton>
        </DialogActions>
      </div>
    );
  }
}

export default connect(
  ({ resources, slots, userContext }: RootState, { serviceProviderId, slotId }) => {
    return {
      serviceProvider: resources.resourceCentreServiceProviders.find(
        (sp) => sp.id === serviceProviderId
      ),
      slot: slots.find((s) => s.id === slotId),
      resourceCentreVideoSetting:
        userContext?.resourceCentre?.subscriptions?.features?.video?.subscribed
    };
  },
  (dispatch) => ({
    fetchSlot: (slotId) => {
      dispatch(fetchSlot(slotId));
    },
    getServiceProvider: (id) => {
      dispatch(serviceProviderActions.getResourceCentreServiceProvider(id));
    },
    bookSlot: async (
      {
        slotId,
        serviceProviderId,
        reasonOfVisit,
        clientId,
        clientName,
        clientPhoneNo,
        followUp,
        reminders,
        services,
        isVideoAppointment
      },
      next
    ) => {
      await dispatch(
        bookSlot(slotId, {
          serviceProviderId,
          reasonOfVisit,
          clientId,
          clientName,
          clientPhoneNo,
          followUp,
          reminders,
          services,
          isVideoAppointment
        })
      );
      if (next) next();
      dispatch(navigateRemoveModal("SlotBookModal"));
    },
    deleteSlot: async (slotId, next) => {
      await dispatch(removeSlot(slotId));
      dispatch(
        notificationActions.notificationAdd({ id: 1, message: "Deleted slot", variant: "success" })
      );
      next();
    },
    updateBooking: async (bookingId, updateAttrs, next) => {
      await dispatch(updateBooking(bookingId, updateAttrs));
      next();
    }
  })
)(SlotBookModal);

import * as React from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import AudienceList from "./Audience/AudienceList";
import TemplateList from "./MessageTemplate/TemplateList";
import CampaignList from "./Campaigns/CampaignList";
import { getRCFromState } from "../../actions/resourceCentre";
import { RootState } from "../../store";

interface CampaignInterface {
  match: any;
  mode?: string;
}

const Campaign: React.FC<CampaignInterface> = ({ match, mode }) => (
  <Box height="100%" pt="20px">
    <Box>{match.path.includes("campaigns") && <CampaignList match={match} mode={mode} />}</Box>
    <Box>{match.path.includes("audience") && <AudienceList match={match} mode={mode} />}</Box>
    <Box>{match.path.includes("template") && <TemplateList match={match} mode={mode} />}</Box>
  </Box>
);

Campaign.defaultProps = {
  mode: undefined
};

export default connect(
  (state: RootState) => {
    const resourceCentre = getRCFromState(state);
    return {
      resourceCentreId: state.userContext?.resourceCentreId,
      subscriptions: resourceCentre?.subscriptions.features
    };
  },
  () => ({})
)(Campaign);

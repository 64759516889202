import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { getEmployer } from "../../../api/ssf";
import { notificationAdd as addNotification } from "../../../actions/notification";
import { useAppDispatch } from "../../../store/hooks";
import { commonErrorMessage } from "../../../helpers/messages";

type Employer = { name: string; eSSID: string };

interface EmployerSelectorProps {
  ssfUUID: string;
  value: Employer;
  onChange: (value: Employer) => void;
}

const EmployerSelector = ({ ssfUUID, value, onChange }: EmployerSelectorProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [employers, setEmployers] = useState<Employer[]>([]);

  useEffect(() => {
    if (ssfUUID) {
      (async () => {
        try {
          const res = await getEmployer(ssfUUID);
          const formattedEmployers = res.company[0].map((item) => ({
            name: item.name,
            eSSID: item.E_SSID
          }));
          setEmployers(formattedEmployers);
        } catch (e) {
          dispatch(
            addNotification({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: (typeof e.data === "string" && e.data) || e.message || commonErrorMessage,
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, [ssfUUID]);

  return (
    <Autocomplete
      disablePortal
      fullWidth
      disableClearable
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option.name}
      options={employers}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label="Employer" variant="outlined" fullWidth />
      )}
    />
  );
};

export default EmployerSelector;

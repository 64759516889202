import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from "@mui/material";
import Portal from "@mui/material/Portal";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { RootState } from "../../../store";
import { getMaintenanceStatus } from "../../../api/prismicQuery";

type MaintenanceMessage = {
  title: string;
  message: string;
};

function formatMessage(statusMessages) {
  return statusMessages.map(({ data: { title, message } }) => ({ title, message }));
}

function MaintenanceModal(): JSX.Element {
  const [message, setMessage] = React.useState<MaintenanceMessage | null>(null);
  const inMaintenance = useSelector((state: RootState) => state.inMaintenance);

  const getServiceStatus = React.useCallback(async () => {
    try {
      const statusMessage = await getMaintenanceStatus();
      const formattedMessage = formatMessage(statusMessage);
      if (formattedMessage.length) {
        setMessage(formattedMessage[0]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Fetching maintenance status error:", error);
    }
  }, []);

  React.useEffect(() => {
    getServiceStatus();
  }, [inMaintenance, getServiceStatus]);

  const showMessage = !!message;

  if (!showMessage) {
    return null;
  }
  return (
    <Portal>
      <Dialog
        open={showMessage}
        aria-labelledby="service-down-alert-modal"
        aria-describedby="service temporarily down for maintenance"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center", fontSize: "1rem" }}>
          {message.title}
        </DialogTitle>
        <DialogContent>
          <Box component="div" display="flex" justifyContent="center" mb={3}>
            <CloudOffIcon color="error" style={{ fontSize: 64 }} />
          </Box>
          <DialogContentText id="alert-dialog-description">{message.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Check status
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
}

export default MaintenanceModal;

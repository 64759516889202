import { ResourceCentre } from "../interfaces/ResourceCentreInterface";
import { useAppSelector } from "../store/hooks";

const useCurrentResourceCentre = (): ResourceCentre =>
  useAppSelector((state) =>
    (state.resources?.resourceCentres || []).find(
      (item: ResourceCentre) => item.id === state.userContext?.resourceCentreId
    )
  );
export default useCurrentResourceCentre;

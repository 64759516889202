import axios from 'axios';
import { Get, Post, Delete, Put } from './apiHelper';

const templateRoot = '/api/template';

export async function getTemplateDetails(id, next) {
  const response = await Get(`${templateRoot}/${id}`, true);
  if (response) {
    if (next) next(response.data);
    return response.data;
  }
}

export async function getBatchTemplate() {
  const response = await Get(`${templateRoot}`, true);
  if (response) return response.data;
}

export async function createTemplate(templateData) {
  const response = await Post(`${templateRoot}`, templateData, true);
  return response.data;
}

export async function updateTemplate(id, templateData) {
  const response = await Put(`${templateRoot}/${id}`, templateData, true);
  return response.data;
}

export const deleteTemplate = async (id) => {
  const response = await Delete(`${templateRoot}/${id}`, true);
  return response.data;
};

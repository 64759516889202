import React from "react";
import { chain } from "mathjs";
import { t } from "../../../components/translate";
import { BillSummaryRow } from "../BillPrintSummary";
import { rupeeDisplay } from "../../../helpers/rupee";
import TenderAmountPrint from "./TenderAmountPrint";
import { BillType } from "../../../interfaces/BillInterfaces";

interface Props {
  billData: BillType;
  clientBalance: number;
  smallFont?: boolean;
  noMarginTop?: boolean;
}

const BillPrintAmounts = ({
  billData,
  clientBalance,
  smallFont = false,
  noMarginTop = false
}: Props): JSX.Element => (
  <>
    <BillSummaryRow
      label={t("billing.grandTotal")}
      value={rupeeDisplay(billData.document.summary.totalAmount.toFixed(2))}
      smallFont={smallFont}
      noMarginTop={noMarginTop}
      isGrandTotal
    />
    {billData?.document?.paymentInfo?.paymentDistribution?.paymentFromSsf && (
      <BillSummaryRow
        label={t("billing.paymentFromSSF")}
        value={rupeeDisplay(billData.document.paymentInfo.paymentDistribution.paymentFromSsf)}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    {clientBalance > 0 && (
      <BillSummaryRow
        label={t("billing.advanceRemaining")}
        value={rupeeDisplay(clientBalance.toFixed(2))}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    {billData.paymentInfo?.paidAmount > 0 && (
      <BillSummaryRow
        label={t("reports.paidAmount")}
        value={rupeeDisplay(billData.paymentInfo.paidAmount.toFixed(2))}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    {!billData.paymentInfo?.paid && (
      <BillSummaryRow
        label={t("reports.dueAmount")}
        value={rupeeDisplay(
          chain(billData.summary?.totalAmount || 0)
            .subtract(billData.paymentInfo.paidAmount || 0)
            .done()
            .toFixed(2)
        )}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    {!!billData.paymentInfo?.tenderAmount && (
      <TenderAmountPrint
        tenderAmount={billData.paymentInfo.tenderAmount}
        changeAmount={billData.paymentInfo.changeAmount}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
  </>
);

export default BillPrintAmounts;

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import style from './style.module.css';
import { I18nContext } from '../../../index';

const AppSettings: React.FC = () => {
  return (
    <>
      <I18nContext.Consumer>
        {({ changeLanguage, language }) => (
          <RadioGroup
            aria-label="Language"
            name="lang"
            className={style.radioWrapper}
            value={language || 'np'}
            onChange={(e) => changeLanguage(e.target.value)}
          >
            <FormControlLabel
              value="en"
              className={style.languageEnglish}
              control={<Radio />}
              label="English"
            />
            <FormControlLabel value="np" control={<Radio />} label="Nepali" />
          </RadioGroup>
        )}
      </I18nContext.Consumer>
    </>
  );
};

export default AppSettings;

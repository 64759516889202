import { Get, Post, Patch } from "./apiHelper";
import { Estimate, EstimatesData } from "../containers/Client/Estimates/interfaces";
import { pageSize } from "../containers/Assessment/AssessmentList";

const estimatesRoot = "/api/treatmentEstimates";

export async function getEstimates(
  clientId: number
): Promise<{ results: Estimate[]; total: number }> {
  const response = await Get(`${estimatesRoot}?clientId=${clientId}&pageSize=${pageSize}`, true);
  return response.data as { results: Estimate[]; total: number };
}

export async function getEstimatesTemplate(): Promise<Estimate> {
  const response = await Get(`${estimatesRoot}/template`, true);
  return response.data as Estimate;
}

export async function postEstimatesTemplate(data: Partial<EstimatesData>): Promise<Estimate> {
  const response = await Post(`${estimatesRoot}/template`, data, true);
  return response.data as Estimate;
}

export async function postEstimates(data: Partial<Estimate>): Promise<Estimate> {
  const response = await Post(estimatesRoot, data, true);
  return response.data as Estimate;
}

export async function patchEstimates({
  data,
  id
}: {
  data: Partial<Estimate>;
  id: number;
}): Promise<Estimate> {
  const response = await Patch(`${estimatesRoot}/${id}`, data, true);
  return response.data as Estimate;
}

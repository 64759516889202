import { isRequired, isValidMobileNumber, isEmail, isNotEmptyArr } from "../helpers/validators";
import { places } from "./ResourceCentre";

export const Roles = {
  1: "Doctor",
  2: "Nurse",
  3: "Lab Technician",
  4: "Pharmacist",
  5: "Radiographer",
  6: "Health Assistant",
  7: "Dental Hygienist",
  8: "Microbiologist",
  9: "Other"
};

export const Specialities = {
  1: "Allergy and Immunology",
  2: "Anesthesiology",
  3: "Cardiology",
  4: "Cardiovascular Surgery",
  5: "Clinical Laboratory Sciences",
  6: "Dermatology",
  7: "Dietetics",
  8: "Emergency Medicine",
  9: "Endocrinology",
  10: "Family Medicine",
  11: "Forensic Medicine",
  12: "Gastroenterology",
  13: "General Physician",
  14: "General Surgery",
  15: "Geriatrics",
  16: "Gynecology",
  17: "Hepatology",
  18: "Hospital Medicine",
  19: "Infectious Disease",
  20: "Intensive Care Medicine",
  21: "Internal Medicine",
  22: "Medical Research",
  23: "Nephrology",
  24: "Neurology",
  25: "Neurosurgery",
  26: "Obstetrics and Gynecology",
  27: "Occupational Health",
  28: "Oncology",
  29: "Ophthalmology",
  30: "Oral and Maxillofacial Surgery",
  31: "Orthopedic Surgery",
  32: "ENT / Otorhinolaryngology",
  33: "Palliative Care",
  34: "Pathology",
  35: "Pediatrics",
  36: "Pediatric Surgery",
  37: "Physical Medicine and Rehabilitation / Physiatry",
  38: "Plastic Surgery",
  39: "Podiatry",
  40: "Proctology",
  41: "Psychiatry",
  42: "Medical Officer",
  43: "Pulmonology",
  44: "Public Health",
  45: "Radiology",
  46: "Rheumatology",
  47: "Surgical Oncology",
  48: "Thoracic Surgery",
  49: "Transplant Surgery",
  50: "Urgent Care Medicine",
  51: "Urology",
  52: "Vascular Surgery",
  53: "Physiotherapy",
  54: "Homeopathy",
  55: "Ayurved",
  56: "Surgery",
  57: "Dental Care",
  58: "Dental Surgery",
  59: "Periodontitis",
  60: "Orthodontist"
};

export const fields = [
  {
    key: "title",
    label: "title",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false,
    validators: [isRequired({ msg: "Title is required" })]
  },
  {
    key: "firstName",
    label: "firstName",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "First Name is required" })]
  },
  {
    key: "lastName",
    label: "lastName",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Last Name is required" })]
  },
  {
    key: "gender",
    label: "gender",
    value: "",
    inputType: "select",
    editable: true,
    creatable: true,
    required: true,
    options: [
      { value: 1, label: "Male" },
      { value: 2, label: "Female" },
      { value: 3, label: "Other" }
    ],
    validators: [isRequired({ msg: "Gender is required" })]
  },
  {
    key: "phone",
    label: "mobileNumber",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: true,
    mobileNumber: true,
    validators: [
      isRequired({ msg: "Phone number is required" }),
      isValidMobileNumber({ msg: "Should be a valid mobile number" })
    ]
  },
  {
    key: "address",
    label: "address",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false
  },
  {
    key: "city",
    label: "city",
    value: "",
    inputType: "autoComplete",
    editable: true,
    creatable: true,
    required: false,
    options: places.map((item) => ({
      value: item.id,
      label: item.city === item.district ? item.city : `${item.city}, ${item.district}`
    }))
  },
  {
    key: "registrationNumber",
    label: "registrationNumber",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Registration number is required" })]
  },

  {
    key: "role",
    label: "Permission Group",
    value: "",
    inputType: "select",
    editable: true,
    creatable: true,
    options: [
      {
        value: 1,
        label: Roles["1"]
      },
      {
        value: 2,
        label: Roles["2"]
      },
      {
        value: 3,
        label: Roles["3"]
      },
      {
        value: 4,
        label: Roles["4"]
      },
      {
        value: 5,
        label: Roles["5"]
      },
      {
        value: 6,
        label: Roles["6"]
      },
      {
        value: 7,
        label: Roles["7"]
      },
      {
        value: 8,
        label: Roles["8"]
      },
      { value: 9, label: Roles["9"] }
    ],
    required: true,
    validators: [isRequired({ msg: "Role is required" })]
  },

  {
    key: "specialities",
    label: "speciality",
    value: "",
    inputType: "conditionalSelectSpeciality",
    editable: true,
    creatable: true,
    required: true,
    validators: [isNotEmptyArr({ msg: "Speciality_select is required" })]
  },
  {
    key: "serviceTagIds",
    label: "serviceList",
    value: "",
    inputType: "connectedServiceListField",
    editable: true,
    creatable: true,
    required: false,
    validators: [isNotEmptyArr({ msg: "Service tag is required" })]
  },
  {
    key: "speciality",
    label: "speciality",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Speciality is required" })]
  },

  {
    key: "email",
    label: "email",
    value: "",
    inputType: "email",
    editable: true,
    creatable: true,
    required: false,
    validators: [isEmail({ msg: "Should be a valid email" })]
  },
  {
    key: "qualification",
    label: "qualification",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Qualification is required" })]
  },
  {
    key: "resourceCentreId",
    label: "resourceCentre",
    value: "",
    inputType: "select",
    editable: false,
    creatable: false,
    required: true,
    options: []
  },
  {
    key: "details",
    label: "bio",
    value: "",
    inputType: "textArea",
    editable: true,
    creatable: true,
    required: false
  },
  {
    key: "tds",
    label: "tds",
    value: "",
    inputType: "number",
    editable: true,
    creatable: true,
    required: false
  }
];

import React from "react";
import { Typography, Box } from "@mui/material";
import { match as MatchProps, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import List, { Filters, MultipleHeadersInterface } from "../../components/List";
import { tl } from "../../components/translate";
import EntryButton from "./EntryButton";
import "./accountStyle.scss";
import {
  useContraVoucher,
  useJournalVouchers,
  usePaymentVouchers,
  useReceiptVouchers
} from "../../hooks/query/useJournalVoucher";
import { getAllChildGlWithLedgerTypeFilter } from "./hooks";
import { RootState } from "../../store";
import { getCurrentSubscription } from "../../slices/subscriptionSlice";
import { EmptyView } from "../../components/OList";
import VoucherItemView from "./VoucherItemView";
import { TransactionType } from "../../interfaces/Accounts";
import {
  useCreditNoteVouchers,
  useDebitNoteVouchers,
  usePurchaseVouchers,
  useSalesVouchers
} from "../../hooks/query/useVouchers";
import { clearVoucherState } from "../../slices/journalEntry";
import { rupeeDisplay } from "../../helpers/rupee";

export enum Filter {
  ALL = "all",
  SALES = "sales",
  CREDIT_NOTE = "creditNote",
  PURCHASE = "purchase",
  DEBIT_NOTE = "debitNote",
  PAYMENT = "payment",
  RECEIPT = "receipt",
  JOURNAL = "journal",
  CONTRA = "contra"
}

export const multipleHeaders = (match: MatchProps): MultipleHeadersInterface => ({
  headers: [
    { key: "vouchers", title: tl(`accounts.vouchers`), goto: "/accounts/vouchers/sales" },
    { key: "coa", title: tl(`accounts.coa`), goto: "/accounts/coa" }
  ],
  url: match.url
});

const filterTitle = {
  filters: [
    { key: Filter.SALES, title: "Sales" },
    { key: Filter.CREDIT_NOTE, title: "Credit Note" },
    { key: Filter.PURCHASE, title: "Purchase" },
    { key: Filter.DEBIT_NOTE, title: "Debit Note" },
    { key: Filter.PAYMENT, title: "Payment" },
    { key: Filter.RECEIPT, title: "Receipt" },
    { key: Filter.JOURNAL, title: "Journal" },
    { key: Filter.CONTRA, title: "Contra" }
  ]
};

const filterData = (
  filter,
  journalVouchers,
  paymentVouchers,
  receiptVouchers,
  salesVouchers,
  purchaseVouchers,
  creditNoteVouchers,
  debitNoteVouchers,
  contraVouchers
) => {
  if (filter === Filter.JOURNAL)
    return (
      journalVouchers.map((item) => ({
        ...item,
        ledgerId: item.document.find((doc) => doc.transactionType === TransactionType.DEBIT)
          ?.ledgerId,
        entryAmount: item.document.reduce(
          (pre, curr) => (curr.transactionType === TransactionType.DEBIT ? pre + curr.amount : pre),
          0
        )
      })) || []
    );
  if (filter === Filter.SALES || filter === Filter.CREDIT_NOTE) {
    const vouchers = filter === Filter.SALES ? salesVouchers : creditNoteVouchers;
    return (
      vouchers.map((item) => ({
        ...item,
        ledgerId: item.ledgerTransactions?.find(
          (doc) => doc.transactionType === TransactionType.CREDIT
        )?.ledgerId,
        date: item.issueDate,
        voucherNumber: item.billNumber,
        entryAmount: item.total || 0,
        document: item.ledgerTransactions,
        createdBy: item.document?.enteredBy?.name,
        remarks: item?.remarks || item.document?.remarks
      })) || []
    );
  }
  if (filter === Filter.PAYMENT) {
    return (
      paymentVouchers?.map((item) => ({
        ...item,
        ledgerId: item.document.find((doc) => doc.transactionType === TransactionType.DEBIT)
          ?.ledgerId
      })) || []
    );
  }
  if (filter === Filter.RECEIPT) {
    return (
      receiptVouchers?.map((item) => ({
        ...item,
        ledgerId: item.document.find((doc) => doc.transactionType === TransactionType.DEBIT)
          ?.ledgerId
      })) || []
    );
  }
  if (filter === Filter.PURCHASE || filter === Filter.DEBIT_NOTE) {
    const vouchers = filter === Filter.PURCHASE ? purchaseVouchers : debitNoteVouchers;
    return (
      vouchers?.map((item) => ({
        ...item,
        ledgerId: item.ledgerTransactions?.find(
          (doc) => doc.transactionType === TransactionType.DEBIT
        )?.ledgerId,
        voucherNumber: item?.voucherNumber,
        entryAmount: item.totalAmount,
        remarks: item.note,
        document: item.ledgerTransactions
      })) || []
    );
  }
  if (filter === Filter.CONTRA) {
    return (
      contraVouchers?.map((item) => ({
        ...item,
        ledgerId: item.document.find((doc) => doc.transactionType === TransactionType.DEBIT)
          ?.ledgerId
      })) || []
    );
  }
  return [];
};

export default function VoucherList({ match }: { match: MatchProps }): JSX.Element {
  const param: { filter: string } = useParams();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [filter, setFilter] = React.useState<string | null>(null);

  const { currentSubscription, rcId } = useSelector((state: RootState) => ({
    currentSubscription: state.subscriptions.currentSubscription,
    rcId: state.userContext.resourceCentreId
  }));

  React.useEffect(() => {
    dispatch(clearVoucherState());
  }, [dispatch]);

  React.useEffect(() => {
    if (param) {
      setFilter(param.filter);
    }
  }, [param]);

  React.useEffect(() => {
    if (!currentSubscription) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [currentSubscription, dispatch, rcId]);
  const { data: paymentVouchers = [] } = usePaymentVouchers(filter);
  const { data: receiptVouchers = [] } = useReceiptVouchers(filter);
  const { data: journalVouchers = [] } = useJournalVouchers(filter);
  const { data: purchaseVouchers = [] } = usePurchaseVouchers(filter);
  const { data: salesVouchers = [] } = useSalesVouchers(filter);
  const { data: creditNoteVouchers = [] } = useCreditNoteVouchers(filter);
  const { data: debitNoteVouchers = [] } = useDebitNoteVouchers(filter);
  const { data: contraVouchers = [] } = useContraVoucher(filter);
  const allChildGl = getAllChildGlWithLedgerTypeFilter();
  const filteredData = filterData(
    filter,
    journalVouchers,
    paymentVouchers,
    receiptVouchers,
    salesVouchers,
    purchaseVouchers,
    creditNoteVouchers,
    debitNoteVouchers,
    contraVouchers
  );

  return (
    <Box overflow="auto hidden">
      <Box className="vouchersList">
        <List
          automation="vouchersList"
          additionalHeaderContent={
            <Filters
              filter={filter}
              onFilter={(value) => {
                dispatch(push(`/accounts/vouchers/${value}`));
              }}
              filterData={filterTitle}
            />
          }
          multipleHeaders={multipleHeaders(match)}
          rowHeight={40}
          data={filteredData?.sort((a, b) => b.id - a.id)}
          isResponsive
          activeRow={selectedItem?.id}
          customCreateButton={<EntryButton />}
          onRowClick={(item) => {
            setSelectedItem(item);
          }}
          columns={[
            {
              key: "date",
              label: "Date",
              sortable: true,
              formatter: ({ date }) => (
                <Box>
                  <Typography>{convertADtoBS(new Date(date)).formatted4}</Typography>
                </Box>
              )
            },
            {
              key: "voucherNumber",
              label: "Voucher Number",
              sortable: true,
              formatter: ({ voucherNumber }) => <Typography>{voucherNumber}</Typography>
            },
            {
              key: "ledger",
              label: "Ledger",
              formatter: ({ ledgerId }) => (
                <Typography>
                  {allChildGl.find((child) => child.id === ledgerId)?.ledgerName ||
                    "Not Mapped with any Ledgers"}
                </Typography>
              )
            },
            {
              key: "entryAmount",
              label: "Entry Amount",
              formatter: ({ entryAmount }) => <Typography>{rupeeDisplay(entryAmount)}</Typography>
            },
            {
              key: "narration",
              label: "Narration",
              formatter: ({ remarks }) => <Typography>{remarks}</Typography>
            }
          ]}
        >
          <EmptyView>
            <Box textAlign="center" padding="50px">
              No items to show.
            </Box>
          </EmptyView>
        </List>
        {selectedItem && (
          <VoucherItemView
            filter={filter}
            data={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        )}
      </Box>
    </Box>
  );
}

export enum NotificationActions {
  NOTIFICATION_ADD = "NOTIFICATION_ADD",
  NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE"
}

export enum ServiceActions {
  SERVICES = "SERVICES",
  CREATE_SERVICES = "CREATE_SERVICES",
  UPDATE_SERVICES = "UPDATE_SERVICES",
  DELETE_SERVICES = "DELETE_SERVICES",
  GET_SERVICES = "GET_SERVICES",
  GET_SERVICE = "GET_SERVICE",
  UPDATE_SERVICE_UNDER_PACKAGE = "UPDATE_SERVICE_UNDER_PACKAGE"
}

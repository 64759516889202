import React, { useState } from "react";
import { Box, Popper, Typography, Autocomplete } from "@mui/material";

import { connect } from "react-redux";
import { push } from "connected-react-router";
import { getResourceCentreMinimalBookings } from "../../api/bookings";
import DebouncedTextField from "../DebouncedTextField";

interface IMinimalBooking {
  id: number;
  date: string;
  serviceProviderId: number;
  clientName: string;
}

function exposeClientName(bookings): Array<IMinimalBooking> {
  return bookings.map((item) => ({
    ...item,
    clientName: `${item.client.firstName} ${item.client.lastName}`
  }));
}

function BookingSearch({ navigateTo, onBlur }) {
  const [options, setOptions] = useState<Array<IMinimalBooking>>([]);

  function handleClick(option) {
    navigateTo(
      `/calendar?bookingId=${option.id}&serviceProviderId=${option.serviceProviderId}&date=${option.start}`
    );
  }

  const CustomizedPopper = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Popper {...props} style={{ width: "248px", marginLeft: "-30px" }} placement="bottom-start" />
  );

  return (
    <Autocomplete
      id="combo-box-demo"
      options={options as Array<IMinimalBooking>}
      PopperComponent={CustomizedPopper}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      getOptionLabel={(option) => `Id: ${option.id} ${option.clientName}`}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          <Box mb="2px" onClick={() => handleClick(option)}>
            <Typography>
              <Box fontWeight="700">ID</Box>
              {option.id}
            </Typography>
            <Typography>
              Client:
              {option.clientName}
            </Typography>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <DebouncedTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          margin="dense"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true
          }}
          onChange={(e) => {
            getResourceCentreMinimalBookings(e.target.value).then((res) =>
              setOptions(exposeClientName(res))
            );
          }}
          debounceAt={1000}
        />
      )}
    />
  );
}

export default connect(null, (dispatch) => ({
  navigateTo: (url) => dispatch(push(url))
}))(BookingSearch);

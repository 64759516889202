import moment from "moment";
import { convertADtoBS, convertBStoAD } from "../components/Calendar/functions/calendarFunctions";
import { isBSDate, isADDate, isADDateString } from "../helpers/validators";

const acceptFutureDate = true;

export const bsDateField = {
  value: "",
  inputType: "bsDate",
  editable: true,
  creatable: true,
  required: false,
  formatter: (dateString: string): string => {
    if (!dateString) return dateString;
    const isValid = moment(dateString).toISOString() === dateString;
    if (!isValid) return dateString;
    // when we have a date string without timezone, it is better to force them at +0 UTC
    return convertADtoBS(moment.utc(dateString)).formatted4;
  },
  reverseFormatter: (dateString: string): string => {
    if (!dateString) return null;
    const isValid = isBSDate({ msg: "" })(dateString, acceptFutureDate);
    if (!dateString || !isValid) return dateString;
    const [year, month, date] = dateString.split("-");
    return moment
      .utc(moment(convertBStoAD(Number(year), Number(month), Number(date))).format("YYYYMMDD"))
      .toISOString();
  },
  ADFormatter: (dateString: string): string => {
    if (!dateString) return null;
    const isValid = isADDate({ msg: "" })(dateString, acceptFutureDate);
    if (!dateString || !isValid) return dateString;
    return moment.utc(dateString).toISOString();
  },
  validators: [isADDateString({ msg: "Date must be in specified format" })]
};

export const DEBTORCREDITOR_FIELD = [
  "ledgerName",
  "ledgerType",
  "subLedgerMapping",
  "parentId",
  "ledgerNature",
  "validFrom",
  "validTo",
  "openingBalanceDate",
  "subLedgerType"
];

const manufactureOptions: Array<string> = [
  "A S HERBAL INDUSTRY",
  "Aadee Remedies Pvt Ltd",
  "ABHICOM PHARMACEUTICALS PVT LTD",
  "Accord Pharmaceuticals Pvt Ltd",
  "Alive Pharmaceutical Pvt Ltd",
  "Alliance Pharmaceuticals Private Limited",
  "AMTECH MED PVT LTD",
  "Apex Pharmaceuticals Pvt Ltd",
  "Apple international Pharmaceuticals Pvt Ltd",
  "Arrow Pharmaceuticals Pvt Ltd",
  "Arya Pharmalab Pvt Ltd",
  "Asian Pharmaceuticals Pvt Ltd",
  "Big B formulation pvt ltd",
  "Biogain Remedies Private Limited",
  "Chemidrug Industries Pvt Ltd",
  "CITIZEN PHARMACEUTICALS PVT LTD",
  "Corel Pharmaceuticals",
  "CTL Pharmaceuticals Pvt Ltd",
  "CTL Pharmaceuticals Pvt. Ltd. ,Chitwan Unit",
  "Curex Pharmaceuticals Pvt Ltd",
  "DENIUM LABORATORIES PVT LTD",
  "Derren Pharmaceuticals Private Limited",
  "DEURALI JANTA PHARMACEUTICALS PVT LTD",
  "Divine Health Care Pvt Ltd",
  "Elixir Life Science Private Limited",
  "EMVOLIO LIFE SCIENCES PVT LTD",
  "Evans Life Sciences Pvt Ltd",
  "Everest Parenterals Pvt Ltd",
  "Everest Pharmaceuticals Pvt Ltd",
  "Florid Laboratories pvt ltd",
  "GD Pharmaceutical Pvt Ltd",
  "Genetica Laboratory Pvt Ltd",
  "Global Pharmaceuticals Pvt Ltd",
  "Grace Pharmaceuticals Pvt Ltd",
  "Gurans Pharmaceutical Pvt Ltd",
  "Harvard Pharmaceuticals Private Limited",
  "Hester Biosciences Nepal Pvt ltd",
  "HIMALAYAN ESSENCE PVT LTD",
  "Hukam Pharmaceuticals Pvt Ltd",
  "IDEAL PHARMACEUTICALS COMPANY PRIVATE LIMITED",
  "Innovative pharma lab private limited",
  "Jio Phramaceuticals Pvt Ltd",
  "JJ Laboratories Pvt Ltd",
  "Kalika Pharmaceuticals Pvt Ltd",
  "KANTIPUR PHARMACEUTICALS LAB PVT LTD",
  "Kasturi Pharmaceuticals Pvt Ltd",
  "Keva Pharmaceuticals Pvt Ltd",
  "Life Solutions Pvt Ltd",
  "LIFESTAR PHARMACEUTICALS PRIVATE LIMITED",
  "Livecare Pharmaceuticals Pvt Ltd",
  "Lomus Parenterals and Formulation Pvt Ltd",
  "LOMUS PHARMACEUTICALS PVT LTD",
  "Lucius Pharmaceuticals Pvt Ltd",
  "Lyka pharmaceuticals Private Limited",
  "M.D.H. Pharmaceuticals Pvt.Ltd",
  "Magnus Pharma Pvt Ltd",
  "MANOJ CHEMICAL WORKS",
  "MANOJ PHARMACEUTICAL WORKS",
  "Mark Formulations Private Limited",
  "Maruti Pharma Private Limited",
  "MAX PHARMA PVT LTD",
  "Medrik Pharmaceuticals Pvt Ltd",
  "MEERA BIOTECH PRIVATE LIMITED",
  "Mesa Pharmaceutical Pvt Ltd",
  "NATIONAL HEALTHCARE PVT LTD",
  "Nepal Aushadhi Limited",
  "nepal ayurved",
  "Nepal CRS Company",
  "Nepal Pharmaceuticals Laboratory PVt Ltd",
  "Nepal Remedies Pvt Ltd",
  "Nippon Pharmaceuticals Pvt Ltd",
  "Nova Genetica Private Limited",
  "NUMARKS PHARMACEUTICALS P LTD",
  "OHM Pharmaceuticals Laboratories Pvt Ltd",
  "OMNICA LABORATORIES PVT LTD",
  "OSHIN UDYOG",
  "Panas Pharmaceuticals Pvt Ltd",
  "PHARMACO INDUSTRIES PVT LTD",
  "PHARMONICS LIFE SCIENCES PVT LTD",
  "Pokhara Pharmaceuticals Pvt ltd",
  "Prakash Udhyog",
  "Prime Pharmaceuticals Pvt Ltd",
  "QbD Pharmaceuticals Pvt Ltd",
  "Qmed Formulation P Ltd",
  "Quest Pharmaceuticals Private Limited",
  "Rajdevi Pharmaceuticals Private Limited",
  "Rhododendron Biotech Private Limited",
  "Rijalco Hygiene Industries",
  "Royal Pharmaceuticals Pvt Ltd",
  "Royal Sasa Nepal Pharmaceuticlas",
  "S R DRUG LABORATORIES PVT LTD",
  "Samar Pharma Company Pvt Ltd",
  "SHIV PHARMACEUTICAL LABORATORIES",
  "SHIVAM PHARMACEUTICAL PVT LTD",
  "Siddhartha Pharmaceuticals Pvt Ltd",
  "Simca Laboratories Pvt Ltd",
  "Sopan Pharmaceuticals Limited",
  "Sumy Pharnaceuticals Pvt Ltd",
  "Supreme chemicals",
  "Supreme Health Care Pvt Ltd",
  "Taurus Pharma Pvt Ltd",
  "Time Pharmaceuticals Pvt Ltd",
  "TIZIG PHARMA PVT LTD",
  "UNIQUE PHARMACEUTICALS PRIVATE LIMITED",
  "Universal Formulations Pvt Ltd",
  "Vega Pharmaceuticals Pvt Ltd",
  "Vijayadeep Laboratories Limited",
  "Zydin Biotech Pvt Ltd"
];

export default manufactureOptions;

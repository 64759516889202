import React from "react";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  Paper
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { round, chain } from "mathjs";
import { cloneDeep } from "lodash";
import { t } from "../../components/translate";
import { PackageDataProps } from "./PackageCreate";
import { ServiceInterface } from "../../interfaces/ServiceInterface";

interface TablePropsType {
  packageData: PackageDataProps;
  setPackageData: (data: PackageDataProps) => void;
  services: ServiceInterface[];
  isPublicTab: boolean;
}

const ProductsTable = ({
  packageData,
  setPackageData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  services,
  isPublicTab = false
}: TablePropsType): JSX.Element => {
  const getTableHeaderCell = (text) => (
    <Typography>
      <Box fontWeight={600}>{text}</Box>
    </Typography>
  );

  return (
    <Box pt={2} mb={1} data-testmation="productTable">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">{getTableHeaderCell(t("symbolNumber"))}</TableCell>
              <TableCell>{getTableHeaderCell(t("products"))}</TableCell>
              <TableCell align="right">{getTableHeaderCell(t("price.excludingTax"))}</TableCell>
              <TableCell align="right">{getTableHeaderCell(t("price.includingTax"))}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {packageData.document &&
              packageData.document.products &&
              packageData.document.products.map((item, index) => {
                const product = item;
                return product ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: product.active ? "inherit" : "#db2323"
                      }}
                    >
                      <Typography>{product.name}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {product.vatPct !== 0
                          ? product.servicePriceExcVAT
                          : product.grossTotalPrice}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>{product.grossTotalPrice}</Typography>
                    </TableCell>
                    <TableCell>
                      {!isPublicTab && (
                        <Close
                          style={{ cursor: "pointer" }}
                          data-testmation="packageCreateRemoveButton"
                          onClick={() => {
                            // TODO: have to remove this line and get the products from the document
                            const sps = cloneDeep(packageData.products);
                            const servicesUnderPackage = cloneDeep(packageData.document.products);
                            const refTotalExcVAT = round(
                              chain(packageData.refTotalExcVAT)
                                .subtract(
                                  product.vatPct !== 0
                                    ? product.servicePriceExcVAT
                                    : product.grossTotalPrice || 0
                                )
                                .done(),
                              2
                            );
                            const refTotalIncVAT = round(
                              chain(packageData.refTotalIncVAT)
                                .subtract(product.grossTotalPrice || 0)
                                .done(),
                              2
                            );
                            const refVatPct = round(
                              chain(chain(refTotalIncVAT).subtract(refTotalExcVAT).done())
                                .divide(refTotalExcVAT)
                                .multiply(100)
                                .done(),
                              2
                            );
                            // TODO: have to remove this line and get the products from the document
                            sps.splice(index, 1);
                            servicesUnderPackage.splice(index, 1);
                            setPackageData({
                              ...packageData,
                              // eslint-disable-next-line max-len
                              // TODO: have to remove this line and get the products from the document
                              products: sps,
                              refTotalExcVAT,
                              refTotalIncVAT,
                              packagePrice: refTotalIncVAT,
                              servicePriceExVAT: refTotalExcVAT,
                              vatPct: refVatPct,
                              document: {
                                products: servicesUnderPackage
                              }
                            });
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : null;
              })}
            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell>
                <Typography>{getTableHeaderCell(t("Total"))}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{getTableHeaderCell(packageData.refTotalExcVAT.toFixed(2))}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{getTableHeaderCell(packageData.refTotalIncVAT.toFixed(2))}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductsTable;

import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView } from "../../components/OList";
import { getSupplierTransactions } from "../../slices/supplierSlice";
import { RootState } from "../../store";
import { showItemsName } from "../Stock/StockTransactions/StockTransactionsList";
import StockTransactionView from "../Stock/StockTransactions/StockTransactionView";

interface Props {
  supplierId: number;
}

export default function SupplierTransactions(props: Props): JSX.Element {
  const { supplierId } = props;

  const dispatch = useDispatch();

  const supplierTransactions = useSelector((state: RootState) => state.suppliers.transactions);
  const stockProducts = useSelector((state: RootState) => state.stockProducts.collection);

  React.useEffect(() => {
    dispatch(getSupplierTransactions(supplierId));
  }, [supplierId, dispatch]);

  const [selectedTransaction, setSelectedTransaction] = React.useState(null);

  return (
    <div style={{ height: "calc(100vh - 350px)" }}>
      <Box display="flex" justifyContent="flex-end">
        <Typography mr={1} component="div">
          Total transaction Valuation:{" "}
          <b>
            Rs{" "}
            {(supplierTransactions.reduce((acc, cur) => cur.totalAmount + acc, 0) || 0).toFixed(2)}
          </b>
        </Typography>
      </Box>
      <List
        automation="clientLabs"
        rowHeight={50}
        data={supplierTransactions}
        onRowClick={(row) => setSelectedTransaction(row)}
        defaultSortColumn="id"
        defaultSortOrder={-1}
        columns={[
          {
            key: "id",
            label: "Transaction Id",
            sortable: true,
            formatter: ({ id }) => <Typography>{id}</Typography>
          },
          {
            key: "productName",
            label: "Product Name",
            sortable: true,
            formatter: ({ stockTransactionItems }) => (
              <Typography>{showItemsName(stockTransactionItems, "12px")}</Typography>
            )
          },
          {
            key: "transactionType",
            label: "Transaction Type",
            sortable: true,
            formatter: ({ transactionType }) => (
              <Typography>{startCase(transactionType)}</Typography>
            )
          },
          {
            key: "date",
            label: "Date",
            sortable: true,
            formatter: ({ date }) => (
              <Typography>{convertADtoBS(new Date(date))?.formatted4}</Typography>
            )
          },
          {
            key: "totalAmount",
            label: "Total Amount",
            sortable: true,
            formatter: ({ totalAmount }) => (
              <Typography>Rs. {(totalAmount || 0).toFixed(2)}</Typography>
            )
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No transactions for the supplier yet...
          </Box>
        </EmptyView>
      </List>

      {selectedTransaction && (
        <StockTransactionView
          handlePanelClose={() => {
            setSelectedTransaction(null);
          }}
          data={selectedTransaction}
          products={stockProducts || []}
          wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
        />
      )}
    </div>
  );
}

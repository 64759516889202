import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Patch, Post } from "./apiHelper";

export const generateFileName = (id: number, fileData: Blob): string => {
  const uuid = uuidv4();
  return `${uuid}-${id}.${fileData.type.split("/")[1]}`;
};

export const subscriptionDocumentUploadActions = {
  presignedDataGetter: async (fileName: string): Promise<unknown> => {
    const response = await Post(`/api/subscriptionInvoices/document`, { fileName });
    return response.data;
  },
  afterUploadAction: async (s3BucketUrl: string, sId: number, s3Key: string): Promise<unknown> => {
    const response = await Patch(
      `/api/subscriptionInvoices/${sId}/addDocument`,
      { url: s3BucketUrl, key: s3Key },
      true
    );
    return response.data;
  },
  deleteFile: async (s3Key: string, invoiceId: number): Promise<unknown> => {
    const response = await Patch(
      `/api/subscriptionInvoices/${invoiceId}/removeDocument`,
      { s3Key },
      true
    );
    return response.data;
  }
};

export const fileUploader = async ({
  s3UploadGetterFunction,
  afterUploadAction,
  pictureData,
  id
}: {
  s3UploadGetterFunction: (fileName: string) => Promise<unknown>;
  afterUploadAction: (s3BucketUrl: string, sId: number, s3Key: string) => Promise<unknown>;
  pictureData: Blob;
  id: number;
}): Promise<{ id: string; url: string }> => {
  const fileName = generateFileName(id, pictureData);
  const s3PostData = (await s3UploadGetterFunction(fileName)) as {
    id: number;
    data: Record<string, any>;
  };

  const s3FileName = `${s3PostData.id}/${fileName}`;
  const formData = new FormData();
  [
    "key",
    "bucket",
    "acl",
    "X-Amz-Algorithm",
    "X-Amz-Credential",
    "X-Amz-Date",
    "Policy",
    "X-Amz-Signature"
  ].forEach((k) => {
    formData.append(k, s3PostData.data.fields[k]);
  });
  formData.append("file", pictureData, s3FileName);
  const response = await axios.post(s3PostData.data.url, formData, {
    headers: {
      "Content-Type": "application/octet-stream"
    }
  });
  const s3BucketUrl = response.headers.location;
  const s3FileKey = s3PostData.data.fields.key;

  await afterUploadAction(s3BucketUrl, id, s3FileKey);

  return { id: s3FileKey, url: s3BucketUrl } as { id: string; url: string };
};

import * as React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {
  Box,
  Tooltip,
  Grow,
  Button,
  ButtonGroup,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from "@mui/material";

interface Props {
  onClientCreate: () => void;
  onBookingCreate: () => void;
}

const ClientActionButtons = ({ onClientCreate, onBookingCreate }: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box mx={1}>
      <Tooltip title="Add as Client" arrow>
        <ButtonGroup variant="outlined" ref={anchorRef} size="small">
          <Button onClick={onClientCreate}>
            <PersonAddAlt1Icon />
          </Button>
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={() => {
                      handleToggle();
                      onBookingCreate();
                    }}
                  >
                    Add Client And Create Booking
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
export default ClientActionButtons;

import React, { JSX, useEffect, useState } from "react";
import { match as IMatch } from "react-router";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination
} from "@mui/material";
import moment from "moment-timezone";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { MultipleHeader } from "../../../components/List";
import { hmisMultipleHeaders } from "../index";
import {
  EmptyView,
  getSerialNumber,
  LoadingView,
  TableCell,
  TableRow
} from "../../../components/ListWithNestedHeader/Index";
import { ethnicityMappedValue } from "../../../models/Client";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import useAddressOptions from "../../../hooks/useAddressOptions";
import * as hmisActions from "../../../actions/hmis";
import { HMIS } from "../../../interfaces/HmisInterface";
import { Client } from "../../../interfaces/ClientInterface";
import { Referrer } from "../../../interfaces/ReferrerInterface";
import { translate as t } from "../../../../translations/translate";
import CreateEditOpdRegisterPanel from "./CreateEditOpdRegiterPanel";
import useNextOpdNumber from "../../../hooks/useNextOpdNumber";

interface Props {
  match: IMatch<{ [x: string]: string }>;
}

interface OpdRegister {
  id: number;
  entryDate: string;
  serviceProviderId: number;
  document: {
    isFirstTime: boolean;
    isTBPatient: boolean;
    researchBasedExamination: string;
    provisionalDiagnosis: string;
    diagnosis: { icdCode?: string; diagnosis: string };
    medications: string;
    surgicalProcedure: string;
    sexualViolence: boolean;
    freeServiceCode: string;
    opdNumber: string;
  };
  referredTo: Referrer;
  clientId: number;
  mulDartaId: number;
  mulDarta: HMIS;
  client: Client;
}

const headerRows = [
  [
    { label: t("hmis.serialNumber"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.registeredDate"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.mulDartaNo"), colSpan: 1, rowSpan: 3 },
    { label: "OPD", colSpan: 2, rowSpan: 1 },
    { label: t("hmis.patient's"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.ethnicity"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.age"), colSpan: 2, rowSpan: 1 },
    { label: t("hmis.address"), colSpan: 2, rowSpan: 1 },
    { label: t("hmis.possibleTbPatient"), colSpan: 2, rowSpan: 1 },
    { label: t("hmis.researchBasedExamination"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.provisionalDiagnosis"), colSpan: 1, rowSpan: 3 },
    { label: "ICD Code", colSpan: 1, rowSpan: 3 },
    { label: t("hmis.medicationsAndSuggestions"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.surgicalProcedure"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.sexualViolence"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.freeServiceCode"), colSpan: 1, rowSpan: 3 },
    { label: t("hmis.referring"), colSpan: 1, rowSpan: 1 }
  ],
  [
    { label: t("hmis.registrationNumber"), colSpan: 2, rowSpan: 1 },
    { label: t("hmis.name"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.female"), colSpan: 1, rowSpan: 2 },
    { label: t("hmis.male"), colSpan: 1, rowSpan: 2 },
    { label: t("hmis.district"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.palika"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.yes"), colSpan: 1, rowSpan: 2 },
    { label: t("hmis.no"), colSpan: 1, rowSpan: 2 },
    { label: t("hmis.fromOrganization"), colSpan: 1, rowSpan: 1 }
  ],
  [
    { label: t("hmis.new"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.old"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.surname"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.wardNo"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.contactNumber"), colSpan: 1, rowSpan: 1 },
    { label: t("hmis.toOrganization"), colSpan: 1, rowSpan: 1 }
  ]
];

function OpdRegisterList({ match }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { results, total } = useAppSelector((state) => state.hmis.opdRegister);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [isLoading, setIsLoading] = useState(true);
  const [createOpdRegister, setCreateOpdRegister] = useState(false);
  const addressOptions = useAddressOptions();
  const nextOpdNumber = useNextOpdNumber();

  useEffect(() => {
    (async () => {
      await dispatch(
        hmisActions.getOpdRegister({ pageSize: pagination.pageSize, page: pagination.page })
      );
      setIsLoading(false);
    })();
  }, [pagination.page]);

  return (
    <div>
      <Box sx={{ m: 2, display: "flex", justifyContent: "space-between" }}>
        <MultipleHeader multipleHeaders={hmisMultipleHeaders(match)} />
        <Button variant="contained" onClick={() => setCreateOpdRegister(true)}>
          {t("hmis.createOPDRegister")}
        </Button>
      </Box>
      <Box sx={{ px: 2, height: "calc(100vh - 160px)" }}>
        <TableContainer data-testmation="" className="nestedHeaderList">
          <Table
            size="small"
            className="nestedHeaderTable"
            sx={{
              minWidth: 1000
            }}
          >
            <TableHead>
              {headerRows.map((row) => (
                <TableRow key={Math.random()}>
                  {row.map((cell) => (
                    <TableCell
                      isHeaderCell
                      rowSpan={cell.rowSpan}
                      colSpan={cell.colSpan}
                      key={cell.label}
                    >
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {results.map((row: OpdRegister, rowIndex: number) => (
                <React.Fragment key={row.id}>
                  <TableRow
                    hover
                    sx={{
                      "&:hover + tr": { background: "rgba(0, 0, 0, 0.04)" }
                    }}
                  >
                    <TableCell rowSpan={2}>
                      {getSerialNumber({
                        page: pagination.page,
                        pageSize: pagination.pageSize,
                        currentIndex: rowIndex
                      })}
                    </TableCell>
                    <TableCell rowSpan={2}>
                      {convertADtoBS(moment(row.entryDate)).formatted4}
                    </TableCell>
                    <TableCell rowSpan={2}>{row.mulDarta?.mulDartaNumber || ""}</TableCell>
                    <TableCell rowSpan={2}>
                      {row.document.isFirstTime && row.document.opdNumber}
                    </TableCell>
                    <TableCell rowSpan={2}>
                      {!row.document.isFirstTime && row.document.opdNumber}
                    </TableCell>
                    <TableCell rowSpan={1}>{row.client.firstName || ""}</TableCell>

                    <TableCell rowSpan={2}>
                      {row.client.ethnicity ? ethnicityMappedValue[row.client.ethnicity] : ""}
                    </TableCell>
                    <TableCell rowSpan={2}>
                      {row.client.gender === "2" && row.client.dob
                        ? `${moment().diff(moment(row.client.dob), "year")} Years`
                        : ""}
                    </TableCell>
                    <TableCell rowSpan={2}>
                      {row.client.gender === "1" && row.client.dob
                        ? `${moment().diff(moment(row.client.dob), "year")} Years`
                        : ""}
                    </TableCell>
                    <TableCell rowSpan={1}>
                      {row.client.palikaId
                        ? addressOptions.find((item) => item.palikaId === row.client.palikaId)
                            ?.districtName
                        : ""}
                    </TableCell>
                    <TableCell rowSpan={1}>
                      {row.client.palikaId
                        ? addressOptions.find((item) => item.palikaId === row.client.palikaId)
                            ?.palikaName
                        : ""}
                    </TableCell>
                    <TableCell rowSpan={2}>{row.document.isTBPatient && "Yes"}</TableCell>
                    <TableCell rowSpan={2}>{!row.document.isTBPatient && "No"}</TableCell>
                    <TableCell rowSpan={2}>{row.document.researchBasedExamination || ""}</TableCell>
                    <TableCell rowSpan={2}>{row.document.provisionalDiagnosis || ""}</TableCell>
                    <TableCell rowSpan={2}>
                      {row.document.diagnosis?.icdCode || row.document.diagnosis?.diagnosis || "-"}
                    </TableCell>
                    <TableCell rowSpan={2}>{row.document.medications || ""}</TableCell>
                    <TableCell rowSpan={2}>{row.document.surgicalProcedure || ""}</TableCell>
                    <TableCell rowSpan={2}>{row.document.sexualViolence ? "Yes" : "No"}</TableCell>
                    <TableCell rowSpan={2}>{row.document.freeServiceCode || ""}</TableCell>
                    <TableCell rowSpan={1}>{row.mulDarta.referrer?.referrer || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell rowSpan={1}>{row.client.lastName}</TableCell>
                    <TableCell rowSpan={1}>{row.client.wardNo}</TableCell>
                    <TableCell rowSpan={1}>{row.client.phone}</TableCell>
                    <TableCell rowSpan={1}>{row.referredTo?.referrer || "-"}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {isLoading && <LoadingView />}
          {!isLoading && results.length === 0 && <EmptyView content="No items to dispaly." />}
        </TableContainer>
        <TablePagination
          component="div"
          nextIconButtonProps={{
            disabled: pagination.page >= Math.ceil(total / pagination.pageSize) - 1 || isLoading
          }}
          backIconButtonProps={{
            disabled: pagination.page === 0 || isLoading
          }}
          rowsPerPageOptions={[]}
          count={total}
          rowsPerPage={pagination.pageSize}
          page={pagination.page}
          onPageChange={(_, page) => {
            setIsLoading(true);
            setPagination({
              page,
              pageSize: pagination.pageSize
            });
          }}
        />
      </Box>
      {createOpdRegister && (
        <CreateEditOpdRegisterPanel
          onClose={() => setCreateOpdRegister(false)}
          nextOpdNumber={nextOpdNumber}
        />
      )}
    </div>
  );
}

export default OpdRegisterList;

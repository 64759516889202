import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, match as matchInterface, useLocation } from "react-router";
import {
  Box,
  Button,
  Typography,
  ButtonGroup,
  Menu,
  Divider,
  IconButton,
  Grid
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import queryString from "query-string";
import classNames from "../../helpers/classNames";
import { tl, t } from "../../components/translate";
import LabList, { LabTestStatusTypes } from "./LabList";
import styles from "./Lab.module.css";
import LabSettings from "./Settings";
import LabTestCreate from "./LabTestCreate/LabTestCreate";
import ClientSearch from "../../components/ClientSearch";
import { showLabComponentsBasedOnPermission } from "./LabDetails";
import { clientSearchSecondaryLabel } from "../../models/Client";
import DebouncedTextField from "../../components/DebouncedTextField";
import Can from "../Policy/Can";
import { IThunkDispatch, RootState } from "../../store";
import useMobileScreen from "../../hooks/useMobileScreen";
import { ReverseMap } from "../../helpers/types";
import { TestTypes } from "../../interfaces/Lab";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

interface LabInterface {
  match: matchInterface<{ [x: string]: string }>;
  navigateTo: (path) => void;
  permissionGroup: string;
  focused?: boolean;
  restrictTestCreation: boolean;
}
export type LabRecordType = ReverseMap<typeof TestTypes>;
const useStyles = makeStyles((theme) => ({
  settingPosition: {
    width: "350px",
    display: "flex",
    marginBottom: "0px",
    justifyContent: "flex-end",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.down(undefined)]: {
      width: "180px",
      marginRight: "15px"
    }
  },
  searchBox: {
    width: "300px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    height: "35px",
    borderRadius: "5px",
    transition: "width .5s,background 1s ease",
    backgroundColor: "#ececec"
  },
  focused: {
    width: "200px",
    backgroundColor: "#ececec"
  },
  searchIcon: {
    position: "absolute",
    width: "40px"
  },
  clientSearch: {
    width: "200px",
    marginTop: "5px",
    [theme.breakpoints.down(undefined)]: {
      width: "180px"
    }
  },
  clientSearchFocused: {
    paddingLeft: "35px"
  },
  container: {
    [theme.breakpoints.down(undefined)]: {
      height: "90px",
      flexDirection: "column",
      marginBottom: "10px"
    }
  },
  label: {
    fontSize: "0.8rem",
    padding: "2px 0px",
    [theme.breakpoints.down(undefined)]: {
      fontSize: "11px"
    }
  }
}));

interface LabHeaderCategoryFilterProps {
  className: string;
  onClick: () => void;
  dataTestmation: string;
  label: React.ReactNode;
}

const LabHeaderCategoryFilter = ({
  className,
  onClick,
  dataTestmation,
  label
}: LabHeaderCategoryFilterProps) => (
  <Typography
    fontSize={16}
    fontWeight={600}
    color="gray"
    className={className}
    onClick={onClick}
    data-testmation={dataTestmation}
    component="span"
  >
    {label}
  </Typography>
);

const Lab: React.FC<LabInterface> = ({
  match,
  navigateTo,
  permissionGroup,
  restrictTestCreation
}) => {
  const location = useLocation();
  const [client, setClient] = React.useState(null);
  const statusTab = queryString.parse(location.search)?.status || LabTestStatusTypes.ORDERED;
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [testId, setTestId] = React.useState("");
  const labRecordType = (match.params.type || "") as LabRecordType;
  const rc = useCurrentResourceCentre();

  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focused, setFocused] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openSearchMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setTestId("");
    setClient(null);
  };
  const closeSearchMenu = () => {
    setAnchorEl(null);
  };

  const searchComponentMapper = {
    LABTEST: {
      label: tl("lab.testId"),
      component: (
        <Box ml="3" display="flex">
          <DebouncedTextField
            value={testId}
            placeholder="Search ..."
            inputProps={{ style: { marginLeft: "35px", padding: "2px 4px 1px 0" } }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{ disableUnderline: true }}
            onChange={(e) => {
              setTestId(e.target.value);
            }}
            debounceAt={300}
          />
          {testId && (
            <IconButton
              aria-label="clear"
              size="small"
              onClick={() => setTestId("")}
              style={{ padding: 0 }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      )
    },
    CLIENT: {
      label: tl("lab.client"),
      component: (
        <ClientSearch
          className={`${classes.clientSearch} ${focused ? classes.clientSearchFocused : ""}`}
          client={client}
          setClient={setClient}
          margin="none"
          placeholder={`${focused ? "Search..." : ""}`}
          secondaryText={[
            {
              type: clientSearchSecondaryLabel.CUSTOMER_NUMBER
            },
            {
              type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER,
              label: t("client.externalIdentifier.short")
            }
          ]}
        />
      )
    }
  };
  const menuItems = Object.keys(searchComponentMapper);
  const [active, setActive] = React.useState(menuItems[0]);

  const toggleSearch = (
    <Box>
      <Button style={{ width: "80px" }} onClick={openSearchMenu}>
        {searchComponentMapper[active].label} <ExpandMoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeSearchMenu}>
        {menuItems.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              setAnchorEl(null);
              setActive(item);
            }}
          >
            {searchComponentMapper[item].label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
  const isMobileScreen = useMobileScreen();

  return (
    <Box
      className={styles.labRoot}
      style={{ overflowY: match.path.includes("settings") ? "visible" : "hidden" }}
    >
      <Grid container className={styles.labHeader}>
        <Grid item xs={12} sm={12} md={6} display="flex" alignItems="flex-start">
          <Box display="flex" gap={2}>
            <LabHeaderCategoryFilter
              className={classNames(styles.filter, {
                [styles.active]: labRecordType.includes("all")
              })}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.LAB_RECORD_TYPE_ALL_TAB, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                navigateTo(`/lab/labRecords/all?status=${statusTab}`);
              }}
              dataTestmation="allLabTab"
              label={tl("labs.all")}
            />

            <LabHeaderCategoryFilter
              className={classNames(styles.filter, {
                [styles.active]: labRecordType.includes("pathology")
              })}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.LAB_RECORD_TYPE_PATHOLOGY_TAB, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                navigateTo(`/lab/labRecords/${TestTypes.PATHOLOGY}?status=${statusTab}`);
              }}
              dataTestmation="pathologyLabTab"
              label={tl("lab.pathology")}
            />
            <LabHeaderCategoryFilter
              className={classNames(styles.filter, {
                [styles.active]: labRecordType.includes("radiology")
              })}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.LAB_RECORD_TYPE_RADIOLOGY_TAB, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                navigateTo(`/lab/labRecords/${TestTypes.RADIOLOGY}?status=${statusTab}`);
              }}
              dataTestmation="radiologyLabTab"
              label={tl("lab.radiology")}
            />

            {showLabComponentsBasedOnPermission(permissionGroup) && (
              <LabHeaderCategoryFilter
                className={classNames(styles.filter, {
                  [styles.active]: match.url.includes("settings")
                })}
                onClick={() => navigateTo("/lab/settings")}
                dataTestmation="upperSettingsTab"
                label={tl("lab.masterData")}
              />
            )}
          </Box>
        </Grid>
        {!match.path.includes("settings") && (
          <Grid item xs={12} sm={12} md={6} className="subHeadContainer">
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={0.5}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Can policyAccessKey="lab:searchLab">
                <Box mr={3} mb={1} className={classes.settingPosition}>
                  <Box className={`${classes.searchBox}`}>
                    <SearchIcon className={classes.searchIcon} />
                    <Box>{searchComponentMapper[active].component}</Box>
                    <Divider style={{ height: 20, margin: 4 }} orientation="vertical" />
                    <Box>{toggleSearch}</Box>
                  </Box>
                </Box>
              </Can>

              {!restrictTestCreation && (
                <Can policyAccessKey="lab:createOrder">
                  <Box>
                    <ButtonGroup
                      variant="contained"
                      color="primary"
                      ref={anchorRef}
                      aria-label="split button"
                    >
                      <Button
                        data-testmation="createLabTest"
                        onClick={() => {
                          navigateTo(`${match.url}/create`);
                        }}
                        color="primary"
                        className={styles.buttonPosition}
                      >
                        {isMobileScreen ? "Create" : tl("lab.createLabTest")}
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Can>
              )}
            </Box>
          </Grid>
        )}
      </Grid>

      <Box>
        {match.path.includes("settings") && <LabSettings />}
        {match.path.includes("labRecords") && (
          <Route
            path={`${match.url}/:labId?`}
            render={(props) => {
              const { labId } = props.match.params;
              return (
                <LabList
                  labRecordType={labRecordType}
                  labList={undefined}
                  classNames={undefined}
                  labTabs={undefined}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  setTestId={setTestId}
                  testId={testId}
                  labId={Number(labId)}
                  clientId={client?.id}
                />
              );
            }}
          />
        )}
        <Can policyAccessKey="lab:createOrder">
          <Route
            exact
            path={`${match.url}/create`}
            render={() => <LabTestCreate labRecordType={labRecordType} />}
          />
        </Can>
        <Can policyAccessKey="lab:createOrder">
          <Route
            exact
            path={`${match.url}/edit/:id`}
            render={(props) => {
              const { id } = props.match.params;
              return <LabTestCreate id={id} labRecordType={labRecordType} />;
            }}
          />
        </Can>
        {showLabComponentsBasedOnPermission(permissionGroup) && (
          <Route
            exact
            path={`${match.url}/entry/:labId?`}
            render={(props) => {
              const { labId } = props.match.params;
              return (
                <LabList
                  labRecordType={labRecordType}
                  testId={testId}
                  setTestId={setTestId}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  labId={Number(labId)}
                  mode="entry"
                  clientId={client?.id}
                />
              );
            }}
          />
        )}
      </Box>
    </Box>
  );
};

Lab.defaultProps = {
  focused: false
};

export default connect(
  (state: RootState) => {
    const resourceCentre =
      state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre;

    return {
      permissionGroup: state.userContext?.userCreds?.userGroups[0],
      restrictTestCreation: resourceCentre?.labSettings?.enableTestCreationFromBillOnly
    };
  },
  (dispatch: IThunkDispatch) => ({
    navigateTo: (path) => {
      dispatch(push(path));
    }
  })
)(Lab);

export const getAppBaseUrl = (): string => {
  const localUrl =
    window.location.hostname + (window.location.port ? `:${window.location.port}` : "");
  if (process.env.REACT_APP_ENV === "development") {
    return localUrl.toString();
  }
  if (process.env.REACT_APP_ENV === "test") {
    return "https://okhati.dev";
  }
  if (process.env.REACT_APP_ENV === "production") {
    return "https://okhati.app";
  }
  return `${window.location.protocol}//${localUrl.toString()}`;
};

export const getPublicReportUrl = (): string => {
  const localUrl =
    window.location.hostname + (window.location.port ? `:${window.location.port}` : "");
  if (process.env.REACT_APP_ENV === "development") {
    return `https://${localUrl.toString()}`;
  }
  if (process.env.REACT_APP_ENV === "test") {
    return "https://okhati.dev";
  }
  if (process.env.REACT_APP_ENV === "production") {
    return "https://okhati.com.np";
  }
  return `${window.location.protocol}//${localUrl.toString()}/my-lab`;
};

export const getTestReportBaseUrl = (): string => `${getPublicReportUrl()}/my-lab`;

export const getAssessmentBaseUrl = (): string => `${getPublicReportUrl()}/my-report`;

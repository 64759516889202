import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import styles from "../Assessment.module.css";
import DebouncedTextField from "../../../components/DebouncedTextField";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

interface AdviceInterface {
  advice: string;
  onChange: (value) => void;
}

const Advice: FC<AdviceInterface> = ({ advice, onChange }) => {
  const assessmentLabels = useAssessmentLabel();
  return (
    <>
      <Box className={styles.assessmentRow} height="auto">
        <Box className={styles.assessmentLabel}>
          <Typography component="span" fontWeight="600">
            {assessmentLabels.advice}
          </Typography>
        </Box>
        <Box className={styles.assessmentField}>
          <DebouncedTextField
            margin="dense"
            id="outlined-basic"
            data-testmation="adviceTextField"
            fullWidth
            multiline
            variant="outlined"
            rows="3"
            rowsMax="3"
            placeholder="Type your advice here"
            value={advice}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            showTextCounter
            FormHelperTextProps={{
              style: { position: "absolute", right: 0, bottom: 0, margin: 5 }
            }}
            debounceAt={400}
          />
        </Box>
      </Box>
    </>
  );
};

export default Advice;

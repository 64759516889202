import { Action } from "redux-actions";
import cloneDeep from "lodash/cloneDeep";
import { Resources } from "../interfaces/Resources";

type ActionHandler<T, V> = {
  [key: string]: (state: T, payload: V) => T;
};

export class ReducerBuilder<T, V> {
  initialState: T;

  actions: ActionHandler<T, V> = {};

  constructor(initialState: T) {
    this.initialState = initialState;
    return this;
  }

  mapAction(type: string, fn: (state: T, payload: V) => T): this {
    this.actions[type] = fn;
    return this;
  }

  build(): (state: T, action: Action<V>) => T {
    return (state: T = this.initialState, action: Action<V>) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const type in this.actions) {
        if (action.type === type) {
          return this.actions[type](state, action.payload);
        }
      }
      return state;
    };
  }

  static create<T, V>(initialState: T): ReducerBuilder<T, V> {
    return new ReducerBuilder<T, V>(initialState);
  }
}

export const buildResourcesReducer = (
  resourcesP: Record<string ,any>
): ((state: Resources, action: any) => Resources) => {
  const initialState = {} as Resources;
  resourcesP.forEach((resource) => {
    initialState[`${resource}s`] = [];
  });
  const builder = ReducerBuilder.create<Resources, {id?: number} & unknown>(initialState);

  resourcesP.forEach((resource) => {
    // .mapAction(TodoActions.Type.ADD_TODO, _addTodo)
    // GET_RESOURCES
    const resources = `${resource}s`;
    builder.mapAction(`GET_${resource.toUpperCase()}s`, (state, payload) => {
      const newState = cloneDeep(state);
      newState[resources] = payload;
      return newState;
    });
    // GET_RESOURCE
    builder.mapAction(`GET_${resource.toUpperCase()}`, (state, payload) => {
      const newState = cloneDeep(state);
      const elIndex = newState[resources].findIndex((i) => i.id === payload.id);
      if (elIndex >= 0) {
        // element is not present in state already
        newState[resources][elIndex] = payload;
      } else {
        newState[resources].push(payload);
      }
      return newState;
    });
    // POST_RESOURCE
    builder.mapAction(`POST_${resource.toUpperCase()}`, (state, payload) => {
      const newState = cloneDeep(state);
      const elIndex = newState[resources].findIndex((i) => i.id === payload.id);
      if (elIndex >= 0) {
        // element is not present in state already
        newState[resources][elIndex] = payload;
      } else {
        newState[resources].push(payload);
      }
      return newState;
    });
    // PUT_RESOURCE
    builder.mapAction(`PUT_${resource.toUpperCase()}`, (state, payload) => {
      const newState = cloneDeep(state);
      const elIndex = newState[resources].findIndex((i) => i.id === payload.id);
      if (elIndex >= 0) {
        // element is not present in state already
        newState[resources][elIndex] = payload;
      } else {
        newState[resources].push(payload);
      }
      return newState;
    });
    // DELETE_RESOURCE
    builder.mapAction(`DELETE_${resource.toUpperCase()}`, (state, payload) => {
      const newState = cloneDeep(state);
      newState[resources] = newState[resources].filter((i) => i.id !== payload);
      return newState;
    });
  });
  return builder.build();
};

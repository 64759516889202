import * as React from "react";
import { Alert, AlertTitle, Box, IconButton, Tooltip, Typography } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import SaveIcon from "@mui/icons-material/Save";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect, useState } from "react";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";
import useAudioRecorder from "../../../hooks/useAudioRecorder";
import styles from "../Assessment.module.css";
import { formatToHourMinuteSecondString } from "../../../hooks/useElapsedTime";

interface Props {
  value: string | null;
  onChange: (value: string | null) => void;
}

enum MAX_RECORD_TIME {
  SECOND = 180,
  MINUTE = 3
}

const RecordAudio = ({ value, onChange }: Props): JSX.Element => {
  const assessmentLabels = useAssessmentLabel();
  const [permissionError, setPermissionError] = useState<Error | null>(null);
  const [blobURI, setBlobURI] = useState<string | null>(value);
  const [recordTime, setRecordTime] = useState(0);

  const {
    startRecording,
    isRecording,
    stopRecording,
    recordingTime,
    isPaused,
    recordingBlob,
    togglePauseResume
  } = useAudioRecorder({
    onNotAllowedOrFound: setPermissionError,
    mediaRecorderOptions: { mimeType: "audio/webm" }
  });

  const onRecordingStart = () => {
    setRecordTime(0);
    setBlobURI(null);
    setPermissionError(null);
    startRecording();
  };

  const onRecordingComplete = () => {
    setRecordTime(recordingTime);
    stopRecording();
  };

  useEffect(() => {
    setBlobURI(value);
  }, [value]);

  useEffect(() => {
    if (recordingBlob) {
      const URI = window.URL.createObjectURL(new Blob([recordingBlob]));
      setBlobURI(URI);
      onChange(URI);
    } else {
      onChange(null);
    }
  }, [recordingBlob]);

  useEffect(() => {
    // do not allow more than 3 minutes
    if (recordingTime >= MAX_RECORD_TIME.SECOND) {
      onRecordingComplete();
    }
  }, [recordingTime]);

  return (
    <div>
      <Box className={styles.assessmentRow} height="auto" alignItems="flex-start">
        <Box className={styles.assessmentLabel} pt="4px">
          <Typography component="span" fontWeight="600">
            {assessmentLabels.recordPrescription}
          </Typography>
        </Box>
        <Box className={styles.assessmentField}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "space-between",
              width: "22rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center"
              }}
            >
              <Tooltip title="Start recording" arrow>
                <IconButton
                  onClick={onRecordingStart}
                  disabled={isRecording}
                  color="primary"
                  size="small"
                  sx={{ background: "#F1F3F4" }}
                >
                  <MicIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <div>
                {isRecording && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1
                    }}
                  >
                    <Typography>{formatToHourMinuteSecondString(recordingTime, true)}</Typography>
                    <Typography
                      sx={{
                        animation: !isPaused ? `${styles.pulse} 2s infinite` : "none",
                        display: "flex",
                        gap: 1,
                        alignItems: "center"
                      }}
                    >
                      <Box
                        sx={{
                          height: "0.5rem",
                          width: "0.5rem",
                          display: "inline-block",
                          borderRadius: "50%",
                          background: "red",
                          verticalAlign: "middle"
                        }}
                        component="span"
                      />
                      <span>{isPaused ? "Paused" : "Recording"}</span>
                    </Typography>
                  </Box>
                )}
                {!isRecording && !blobURI && <Typography>Start Recording</Typography>}
                {blobURI && (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography>{formatToHourMinuteSecondString(recordTime, true)}</Typography>
                    <Typography>Done</Typography>
                  </Box>
                )}
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1
              }}
            >
              <Tooltip title={isPaused ? "Resume" : "Pause"} arrow>
                <IconButton
                  onClick={togglePauseResume}
                  disabled={!isRecording}
                  color="info"
                  size="small"
                >
                  {isPaused ? (
                    <PlayCircleIcon fontSize="large" />
                  ) : (
                    <PauseCircleIcon fontSize="large" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Save/Stop recording" arrow>
                <IconButton
                  color="error"
                  onClick={onRecordingComplete}
                  disabled={!isRecording}
                  size="small"
                >
                  <SaveIcon fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete recording" arrow>
                <IconButton
                  color="error"
                  onClick={() => {
                    setBlobURI(null);
                    setRecordTime(0);
                  }}
                  disabled={!blobURI}
                  size="small"
                >
                  <DeleteForeverIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              py: 1,
              px: 2
            }}
          >
            {permissionError && (
              <Alert severity="error">
                <AlertTitle>{`Error ! ${permissionError.message || ""}`}</AlertTitle>
                Please allow microphone permission to record audio.
              </Alert>
            )}
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            {!!blobURI && <audio controls src={blobURI} />}
            {recordTime >= MAX_RECORD_TIME.SECOND && (
              <Alert severity="info">{`Maximum recording time is ${MAX_RECORD_TIME.MINUTE} minutes.`}</Alert>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default RecordAudio;

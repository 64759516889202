import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { RootState } from "../../../store";
import { useParsedProseMirror } from "../LabEntry/LabTestSubRow";
import {
  proseMirrorOptions,
  RichTextfield,
  deserializeNode
} from "../../../components/RichTextfield";
import { setAttachments, toggleComment, updateComment } from "../../../slices/labRecordSlice";
import { LabStatuses } from "../../../interfaces/Lab";
import LabEntryAttachment from "../LabEntry/LabEntryAttachment";

function EntryFooter({ id }: { id: number }): JSX.Element {
  const comment = useSelector((state: RootState) =>
    state.labTest.labTestRecords.find((el) => el.id === id)
  )?.results.comment;
  const showComment = useSelector(
    (state: RootState) => state.labRecords.entryDraft?.results?.showComment
  );

  const labStatus = useSelector((state: RootState) => state.labRecords.entryDraft?.status);
  const labEntryId = useSelector((state: RootState) => state.labRecords.entryDraft?.id);
  const dispatch = useDispatch();

  const [proseState, setProseState] = useParsedProseMirror(comment || "", proseMirrorOptions);

  React.useEffect(() => {
    dispatch(updateComment(deserializeNode(proseState.doc)));
  }, [proseState, dispatch]);

  return (
    <Box py="8px">
      <Box display="flex" gap={2} alignItems="center">
        <Typography variant="h6">Comments: </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={!!showComment} />}
            label="Show Comment"
            onChange={() => dispatch(toggleComment(!showComment))}
          />
        </FormGroup>
      </Box>
      <RichTextfield
        state={proseState}
        setState={setProseState}
        minHeight={70}
        showFontSizeToggleButtons
        editorFontSize={12}
      />
      <LabEntryAttachment
        requireAuthentication
        readOnly={labStatus === LabStatuses.RESULT_READY}
        labEntryId={labEntryId}
        onFilesChange={(files) => {
          dispatch(setAttachments(files));
        }}
      />
    </Box>
  );
}

export default React.memo(EntryFooter);

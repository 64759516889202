import React, { ComponentType } from "react";
import FacebookChat from "./FacebookChat";

function withFacebookChat<T>(Component: ComponentType<T>): ComponentType<T> {
  return (hocProps) => (
    <>
      {
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...(hocProps as T)} />
      }
      <FacebookChat />
    </>
  );
}
export default withFacebookChat;

import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { startCase } from "lodash";
import { headers } from "./Headers";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import "./styles.scss";
import { tl } from "../../components/translate";
import { AccountingGroup, ChildGeneralLedger, PrimaryLedger } from "../../interfaces/Accounts";
import CreateAccountGL from "./CreateAccountGL";
import { EmptyView } from "../../components/OList";

const LedgerNature = {
  balancedSheet: "BS",
  profitLoss: "P&L"
};

const displayData = (data: ChildGeneralLedger, key: string): string => {
  if (["validFrom", "validTo"].includes(key)) {
    if (data[key]) return convertADtoBS(moment(data[key || ""])).formatted2 || "";
    return "";
  }
  if (["bankRecoInfo", "okhatiIntegrated"].includes(key)) {
    return data[key] ? "Yes" : "No";
  }
  if (["ledgerType"].includes(key)) {
    return startCase(data.ledgerType);
  }
  if (["ledgerNature"].includes(key)) {
    return LedgerNature[data.ledgerNature];
  }
  return data[key];
};

interface AccountListProps {
  data: PrimaryLedger;
  setSelectedParent: (parent: Partial<AccountingGroup>) => void;
  header: string;
}
export interface ParentInterface {
  parentId: number;
  parentLedgerName: string;
  header: string;
}

export default function AccountList({
  data,
  setSelectedParent,
  header
}: AccountListProps): JSX.Element {
  const [openCreateAccountGL, setOpenCreateAccountGL] = useState<boolean>(false);
  const [parentInfo, setParentInfo] = useState({} as ParentInterface);
  const [selectedChild, setSelectedChild] = useState<ChildGeneralLedger | null>(null);

  const closeHandler = () => {
    setSelectedChild(null);
    setOpenCreateAccountGL(false);
    setParentInfo(null);
  };

  if (!data) {
    return (
      <EmptyView>
        <Box textAlign="center" padding="50px">
          No items to show.
        </Box>
      </EmptyView>
    );
  }

  return (
    <Box>
      {data.secondaryLedgers?.map((secondaryLedger, index) => (
        <div key={secondaryLedger.id}>
          <Typography
            sx={{ borderTop: `${index !== 0 ? "1px solid #5a607f" : ""}` }}
            className="accounts-list-secondary-name"
          >
            {secondaryLedger.secondaryLedgerName}
          </Typography>
          {secondaryLedger.accountingGroups?.map((acGroup) => (
            <Box key={acGroup.id}>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedParent({
                    parentId: secondaryLedger.id,
                    groupName: acGroup.accountingGroupName,
                    id: acGroup.id
                  });
                }}
                className="accounting-groups-name"
              >
                {acGroup.accountingGroupName}
              </Box>
              <div>
                {acGroup.childGeneralLedgers
                  ?.sort((a, b) => Number(a.code) - Number(b.code))
                  .map((child) => (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedChild(child);
                        setOpenCreateAccountGL((prev) => !prev);
                        setParentInfo({
                          parentId: acGroup.id,
                          parentLedgerName: acGroup.accountingGroupName,
                          header
                        });
                      }}
                      key={child.id}
                      className="account-child-ledger"
                    >
                      {headers.map((item) => (
                        <Typography className="child" key={item.key}>
                          {displayData(child, item.key)}
                        </Typography>
                      ))}
                    </Box>
                  ))}
                <Button
                  data-testmation="addAccountGl"
                  onClick={(e) => {
                    e.stopPropagation();
                    setParentInfo({
                      parentId: acGroup.id,
                      parentLedgerName: acGroup.accountingGroupName,
                      header
                    });
                    setOpenCreateAccountGL(true);
                  }}
                  sx={{ m: "20px 0px 20px 60px" }}
                  variant="contained"
                >
                  {tl("accounts.addAccountGl")}
                </Button>
              </div>
            </Box>
          ))}
        </div>
      ))}
      {openCreateAccountGL && (
        <CreateAccountGL data={selectedChild} parentInfo={parentInfo} onClose={closeHandler} />
      )}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import React from "react";

export enum Size {
  SMALL = "small",
  LARGE = "large",
  MEDIUM = "medium"
}

interface Props {
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  label?: string | JSX.Element;
  value: string | number;
  size?: Size.LARGE | Size.MEDIUM | Size.SMALL;
  alignCenter?: boolean;
}

const getFontSize = (size) => {
  if (size === Size.LARGE) {
    return "0.5cm";
  }
  if (size === Size.MEDIUM) {
    return "0.3cm";
  }
  return "0.18cm";
};

export default function InfoField(props: Props): JSX.Element {
  const { size, labelStyle, valueStyle, label, value, alignCenter } = props;
  return (
    <Box
      justifyContent={`${alignCenter ? "center" : "normal"}`}
      display="flex"
      alignItems="center"
      gap={1}
    >
      {label && (
        <Typography
          fontSize={getFontSize(size)}
          sx={{ width: "2cm", color: "black", ...labelStyle }}
        >
          {label}
        </Typography>
      )}
      <Typography sx={{ color: "black", ...valueStyle }} fontSize={getFontSize(size)}>
        {`${value}`}
      </Typography>
    </Box>
  );
}

InfoField.defaultProps = {
  label: "",
  labelStyle: {},
  valueStyle: {},
  size: Size.SMALL,
  alignCenter: false
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Stage, Layer, Line, Text, Rect, Image } from "react-konva";
import produce from "immer";
import useImage from "use-image";
import { legendMapId, Tooth as ToothI } from "./TeethMarkerComponent";

export const dentalIconsMappings = [
  /*  eslint-disable global-require */
  { name: "Bridge", path: require("../../../../assets/icons/dental/Bridge.svg"), mappedId: 15 },
  {
    name: "CervicalAbasion",
    path: require("../../../../assets/icons/dental/CervicalAbasion.svg"),
    mappedId: 3
  },
  { name: "Crack", path: require("../../../../assets/icons/dental/Crack.svg"), mappedId: 8 },
  { name: "Crown", path: require("../../../../assets/icons/dental/Crown.svg"), mappedId: 6 },
  {
    name: "DentalCaries",
    path: require("../../../../assets/icons/dental/DentalCaries.svg"),
    mappedId: 1
  },
  { name: "Filled", path: require("../../../../assets/icons/dental/Filled.svg"), mappedId: 4 },
  { name: "Fracture", path: require("../../../../assets/icons/dental/Fracture.svg"), mappedId: 7 },
  {
    name: "ImpactedTooth",
    path: require("../../../../assets/icons/dental/ImpactedTooth.svg"),
    mappedId: 12
  },
  {
    name: "Implant",
    path: require("../../../../assets/icons/dental/Implant.svg"),
    mappedId: 13
  },
  {
    name: "Mobile",
    path: require("../../../../assets/icons/dental/Mobile.svg"),
    mappedId: 9
  },

  {
    name: "Percussion",
    path: require("../../../../assets/icons/dental/Percussion.svg"),
    mappedId: 16
  },

  {
    name: "PitNFissure",
    path: require("../../../../assets/icons/dental/PitNFissure.svg"),
    mappedId: 5
  },

  {
    name: "RCT",
    path: require("../../../../assets/icons/dental/RCT.svg"),
    mappedId: 14
  },

  {
    name: "RootStumps",
    path: require("../../../../assets/icons/dental/RootStumps.svg"),
    mappedId: 10
  },

  {
    name: "SecondaryCaries",
    path: require("../../../../assets/icons/dental/SecondaryCaries.svg"),
    mappedId: 2
  },
  {
    name: "ToothMissing",
    path: require("../../../../assets/icons/dental/ToothMissing.svg"),
    mappedId: 11
  }
  /* eslint-enable */
];

const Marker = (props) => {
  const { x, y, mappingId } = props;
  const [image] = useImage(
    dentalIconsMappings.find((el) => el.mappedId === mappingId).path,
    "anonymous"
  );
  return <Image image={image} x={x} y={y} />;
};

export interface ShapeState {
  isHovered: boolean;
  isActive: boolean;
  bindingCoords: Array<number>; // [x1, y1, x2, y2, x3, y3 ...]
  startCoords: [number, number]; // x1 , y1
}

export enum TeethTypes {
  Flat = "Flat",
  Sharp = "Sharp"
}

export interface InitialStateSharp {
  base: ShapeState;
  u: ShapeState; // upper
  r: ShapeState; // right
  d: ShapeState; // down
  l: ShapeState; // left
}

export interface InitialStateFlat {
  base: ShapeState;
  center: ShapeState;
  u: ShapeState; // upper
  r: ShapeState; // right
  d: ShapeState; // down
  l: ShapeState; // left
}

export type InitialState = InitialStateFlat | InitialStateSharp;

const TEETH_HEIGHT = 60; // 30 = base, 60 = root
const BASE_HEIGHT = (TEETH_HEIGHT + TEETH_HEIGHT / 2) / 4;
const TEETH_WIDTH = TEETH_HEIGHT; // keep same
const X_START = 1;
const Y_START = 40;

const getRelativeObjectCoords = (index: number) => ({
  rX: X_START + index * (TEETH_WIDTH + 18),
  rY: Y_START
});

const generateBindingCoordsFlat = (
  i: number,
  reverseBase: boolean
): Record<
  keyof InitialStateFlat,
  { bindingCoords: Array<number>; startCoords: [number, number] }
> => {
  const { rX, rY } = getRelativeObjectCoords(i);
  const bh = BASE_HEIGHT;
  const th = TEETH_HEIGHT;
  const baseCoords = [0, 0, 0, bh, th, bh, th, 0];
  const lCoords = [0, 0, 0, th, th / 3, (th * 2) / 3, th / 3, th / 3];
  const uCoords = [0, 0, th / 3, th / 3, (th * 2) / 3, th / 3, th, 0];
  const rCoords = [th, 0, (th * 2) / 3, th / 3, (th * 2) / 3, (th * 2) / 3, th, th];
  const dCoords = [th, th, (th * 2) / 3, (th * 2) / 3, th / 3, (th * 2) / 3, 0, th];
  const centerCoords = [0, 0, 0, th / 3, th / 3, th / 3, th / 3, 0];
  const lurdStartCoords: [number, number] = reverseBase ? [rX, rY] : [rX, rY + bh];
  return {
    base: { bindingCoords: baseCoords, startCoords: reverseBase ? [rX, rY + th] : [rX, rY] },
    l: { bindingCoords: lCoords, startCoords: lurdStartCoords },
    d: { bindingCoords: dCoords, startCoords: lurdStartCoords },
    r: { bindingCoords: rCoords, startCoords: lurdStartCoords },
    u: { bindingCoords: uCoords, startCoords: lurdStartCoords },
    center: {
      bindingCoords: centerCoords,
      startCoords: reverseBase ? [rX + th / 3, rY + th / 3] : [rX + th / 3, rY + bh + th / 3]
    }
  };
};

const generateBindingCoordsSharp = (
  i: number,
  reverseBase: boolean
): Record<
  keyof InitialStateSharp,
  { bindingCoords: Array<number>; startCoords: [number, number] }
> => {
  const { rX, rY } = getRelativeObjectCoords(i);
  const bh = BASE_HEIGHT;
  const th = TEETH_HEIGHT;
  const baseCoords = [0, 0, 0, bh, th, bh, th, 0];
  const lCoords = [0, 0, 0, th, th / 3, th / 2, 0, 0];
  const uCoords = [0, 0, th / 3, th / 2, (th * 2) / 3, th / 2, th, 0];
  const rCoords = [th, 0, (th * 2) / 3, th / 2, th, th, th, 0];
  const dCoords = [0, th, th / 3, th / 2, (th * 2) / 3, th / 2, th, th];
  const lurdStartCoords: [number, number] = reverseBase ? [rX, rY] : [rX, rY + bh];
  return {
    base: { bindingCoords: baseCoords, startCoords: reverseBase ? [rX, rY + th] : [rX, rY] },
    l: { bindingCoords: lCoords, startCoords: lurdStartCoords },
    d: { bindingCoords: dCoords, startCoords: lurdStartCoords },
    r: { bindingCoords: rCoords, startCoords: lurdStartCoords },
    u: { bindingCoords: uCoords, startCoords: lurdStartCoords }
  };
};

export const getInitialStateSharp = (i: number, reverseBase: boolean): InitialStateSharp => {
  const { base, l, d, r, u } = generateBindingCoordsSharp(i, reverseBase);
  return {
    base: {
      isActive: false,
      isHovered: false,
      bindingCoords: base.bindingCoords,
      startCoords: base.startCoords
    },
    l: {
      isActive: false,
      isHovered: false,
      bindingCoords: l.bindingCoords,
      startCoords: l.startCoords
    },
    d: {
      isActive: false,
      isHovered: false,
      bindingCoords: d.bindingCoords,
      startCoords: d.startCoords
    },
    r: {
      isActive: false,
      isHovered: false,
      bindingCoords: r.bindingCoords,
      startCoords: r.startCoords
    },
    u: {
      isActive: false,
      isHovered: false,
      bindingCoords: u.bindingCoords,
      startCoords: u.startCoords
    }
  };
};

export const getInitialStateFlat = (i: number, reverseBase: boolean): InitialStateFlat => {
  const { base, l, d, r, u, center } = generateBindingCoordsFlat(i, reverseBase);
  return {
    base: {
      isActive: false,
      isHovered: false,
      bindingCoords: base.bindingCoords,
      startCoords: base.startCoords
    },
    l: {
      isActive: false,
      isHovered: false,
      bindingCoords: l.bindingCoords,
      startCoords: l.startCoords
    },
    d: {
      isActive: false,
      isHovered: false,
      bindingCoords: d.bindingCoords,
      startCoords: d.startCoords
    },
    r: {
      isActive: false,
      isHovered: false,
      bindingCoords: r.bindingCoords,
      startCoords: r.startCoords
    },
    u: {
      isActive: false,
      isHovered: false,
      bindingCoords: u.bindingCoords,
      startCoords: u.startCoords
    },
    center: {
      isActive: false,
      isHovered: false,
      bindingCoords: center.bindingCoords,
      startCoords: center.startCoords
    }
  };
};

export const getInitialState = (i: number, type: TeethTypes, reverseBase: boolean): InitialState =>
  type === TeethTypes.Flat
    ? getInitialStateFlat(i, reverseBase)
    : getInitialStateSharp(i, reverseBase);

const Tooth = ({
  i,
  tooth,
  shapeState,
  resetActiveRest,
  setState
}: {
  i: number;
  tooth: ToothI;
  resetActiveRest: (v: keyof InitialState) => void;
  shapeState: InitialState;
  setState: (v: InitialState) => void;
}) => {
  const { rX } = getRelativeObjectCoords(i);
  const { t, b } = tooth;

  const orderByActiveFirst = (
    state: InitialState
  ): Array<ShapeState & { shapeKey: keyof InitialState }> => {
    const ret = [];
    const keyWithActive = Object.keys(state).find((k) => state[k as keyof InitialState].isActive);
    const keysWithoutActive = Object.keys(state).filter(
      (k) => !state[k as keyof InitialState].isActive
    );
    if (keyWithActive) {
      ret.push({
        ...state[keyWithActive as keyof InitialState],
        shapeKey: keyWithActive as keyof InitialState
      });
    }
    ret.push(
      ...keysWithoutActive.map((k) => ({
        ...state[k as keyof InitialState],
        shapeKey: k as keyof InitialState
      }))
    );
    return ret;
  };

  const getSelectedPosition = (
    shape: InitialState,
    selectedTooth: ToothI,
    shapeType: keyof InitialState
  ) => {
    if (!selectedTooth.v[shapeType].legendId) return [null, null];
    const subShapeBindingCoords = shape[shapeType].bindingCoords;

    const textLength = selectedTooth.v[shapeType].legendId.toString().split("").length;

    const adjustmentX = () => {
      let base =
        -3 - (textLength > 1 ? 4 : 0) + legendMapId[selectedTooth.v[shapeType].legendId].offset.x;
      if (selectedTooth.type === TeethTypes.Sharp && shapeType === "r") {
        base += -2;
      }
      return base;
    };
    const adjustmentY = () => {
      let base = -3 + legendMapId[selectedTooth.v[shapeType].legendId].offset.y;
      if (selectedTooth.type === TeethTypes.Sharp && ["r", "l"].includes(shapeType)) {
        base += +3;
      }
      return base;
    };
    const subShapeMidPoint = [
      subShapeBindingCoords.filter((el, idx) => idx % 2 === 0).reduce((acc, cur) => acc + cur) /
        Math.floor(subShapeBindingCoords.length / 2) +
        adjustmentX(),
      subShapeBindingCoords.filter((el, idx) => idx % 2 !== 0).reduce((acc, cur) => acc + cur) /
        Math.floor(subShapeBindingCoords.length / 2) +
        adjustmentY()
    ];
    return [
      subShapeMidPoint[0] + shape[shapeType].startCoords[0],
      subShapeMidPoint[1] + shape[shapeType].startCoords[1]
    ];
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Text text={(t || "").toString()} x={rX + TEETH_WIDTH / 2 - 8} y={Y_START / 2} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Text
        text={(b || "").toString()}
        x={rX + TEETH_WIDTH / 2 - 8}
        y={Y_START + TEETH_HEIGHT + BASE_HEIGHT + 10}
      />
      {orderByActiveFirst(shapeState)
        .reverse()
        .map((shape) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Line
            key={shape.shapeKey}
            x={shape.startCoords[0]}
            y={shape.startCoords[1]}
            points={shape.bindingCoords}
            closed
            onClick={() => {
              resetActiveRest(shape.shapeKey);
            }}
            onTap={() => {
              resetActiveRest(shape.shapeKey);
            }}
            stroke={shape.isActive ? "#1aa3ff" : "black"}
            shadowBlur={shape.isHovered ? 20 : 0}
            onMouseEnter={() => {
              setState({
                ...shapeState,
                [shape.shapeKey]: { ...shapeState[shape.shapeKey], isHovered: true }
              });
            }}
            onMouseLeave={() => {
              setState({
                ...shapeState,
                [shape.shapeKey]: { ...shapeState[shape.shapeKey], isHovered: false }
              });
            }}
          />
        ))}
      {Object.keys(tooth.v)
        .filter((key) => tooth.v[key].legendId)
        .map((el) => (
          <Marker
            key={el}
            x={getSelectedPosition(shapeState, tooth, el as keyof InitialState)[0]}
            y={getSelectedPosition(shapeState, tooth, el as keyof InitialState)[1]}
            mappingId={tooth.v[el].legendId}
          />
        ))}
    </>
  );
};

const Teeth = ({
  teeth,
  setState,
  startIndex,
  endIndex,
  resetActiveRest,
  onLoad,
  hidden
}: {
  teeth: Array<{ shapeState: InitialState; tooth: ToothI }>;
  setState: (v: Array<{ shapeState: InitialState; tooth: ToothI }>) => void;
  startIndex: number;
  endIndex: number;
  resetActiveRest: (shapeKey: keyof InitialState, toothIndex: number) => void;
  onLoad: (stageRef) => void;
  hidden?: boolean;
}): JSX.Element => {
  const r = React.useRef();

  React.useEffect(() => {
    // does not load immediately, no idea why
    setTimeout(() => {
      onLoad(r.current);
    }, 1000);
  }, [r]);

  return (
    <>
      <div style={hidden ? { display: "none" } : {}}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Stage
          height={TEETH_HEIGHT + BASE_HEIGHT + Y_START * 2}
          width={1 + 16 * TEETH_WIDTH + 16 * 18}
          ref={r}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Layer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Rect
              fill="white"
              height={TEETH_HEIGHT + BASE_HEIGHT + Y_START * 2}
              width={1 + 16 * TEETH_WIDTH + 16 * 18}
              x={0}
              y={0}
            />
            {teeth.slice(startIndex, endIndex + 1).map((tooth, k) => (
              <Tooth
                // eslint-disable-next-line react/no-array-index-key
                key={k}
                i={k}
                resetActiveRest={(shapeKey) => {
                  resetActiveRest(shapeKey, startIndex + k);
                }}
                tooth={tooth.tooth}
                shapeState={tooth.shapeState}
                setState={(newShapeState) => {
                  const updatedTeeth = produce(teeth, (draft) => {
                    draft[startIndex + k].shapeState = newShapeState;
                  });
                  setState(updatedTeeth);
                }}
              />
            ))}
          </Layer>
        </Stage>
      </div>
    </>
  );
};

Teeth.defaultProps = {
  hidden: false
};

export default Teeth;

import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "../Assessment.module.css";
import IntraOralAdvancedForm, { IntraOralAdvancedData } from "./IntraOralAdvancedForm";
import { hasContentAdvancedIntraOral } from "../../Modals/Assessment/AdvancedIntraOralContent";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

interface Props {
  data: IntraOralAdvancedData;
  onChange: (v: IntraOralAdvancedData) => void;
  onCancel: () => void;
}

export default function IntraOralAdvanced(props: Props): JSX.Element {
  const { data, onChange, onCancel } = props;
  const [showForm, setShowForm] = React.useState(false);
  const assessmentLabels = useAssessmentLabel();
  const hasData = React.useMemo(() => hasContentAdvancedIntraOral(data), [data]);

  return (
    <Box className={styles.assessmentRow} height="auto">
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.advancedIntraOral}{" "}
        </Typography>
      </Box>
      <Box className={styles.assessmentField} display="flex" alignItems="center">
        <Button onClick={() => setShowForm(true)}>Intra Oral Form View</Button>
        {hasData && <CheckCircleOutlineIcon color="primary" />}
      </Box>
      <IntraOralAdvancedForm
        open={showForm}
        handleClose={() => setShowForm(false)}
        data={data}
        onChange={onChange}
        onCancel={onCancel}
      />
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getSupplierLedgerReport } from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { tl, t } from "../../components/translate";
import Filters, { FilterProps } from "./Filters";
import List, { EmptyView, ListActions, Menu, MenuItem, SORT_ORDER } from "../../components/OList";
import { downloadExcel } from "../../helpers/files";
import "./Reports.scss";
import useMobileScreen from "../../hooks/useMobileScreen";
import { notificationAdd } from "../../actions/notification";
import SupplierTransactionInfo from "./SupplierTransactionInfo";
import Can from "../Policy/Can";
import { Supplier } from "../../interfaces/StockInterfaces";
import { formatHeaderForExcel, formatDataForExcel } from "../accounts/Reports/helper";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  getDefaultKeyValuesColumns,
  supplierLedgerDefaultCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store";

interface ReportData {
  id: number;
  date: Date;
  supplierName: string;
  voucherType: string;
  reference: string;
  paymentMode: string;
  debit: number;
  credit: number;
  balance: number;
  remarks: string;
  stockTransactionId: number;
  ageingDays: number | string;
  isOpeningBalance?: boolean;
}

const docColumns = () => [
  t("reports.date"),
  t("reports.supplierName"),
  t("reports.creditPeriod"),
  t("reports.voucherType"),
  t("reports.reference"),
  t("reports.paymentMode"),
  t("reports.debit"),
  t("reports.credit"),
  t("reports.balance"),
  t("reports.remarks")
];
const or = (val) => val || "";

const docRowProcessor = ({
  date,
  supplierName,
  ageingDays,
  voucherType,
  reference,
  paymentMode,
  debit,
  credit,
  balance,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
ReportData & { __meta__row_type: string }) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return null;
  let remarks = "";
  if (voucherType === "purchase") {
    remarks = `Goods worth ${Number(credit || 0).toFixed(2)} purchased from ${supplierName} `;
  } else if (voucherType === "purchaseReturn") {
    remarks = `Goods worth ${Number(debit || 0).toFixed(2)} returned to ${supplierName} `;
  } else if (voucherType === "payment") {
    remarks = `Payment of ${Number(debit || 0).toFixed(2)} to ${supplierName} `;
  } else {
    remarks = `Receipt of ${Number(credit || 0).toFixed(2)} from ${supplierName} `;
  }
  return [
    calFns.bsShortDate(date),
    or(supplierName),
    or(ageingDays),
    or(voucherType),
    or(reference),
    or(paymentMode),
    Number(debit || 0).toFixed(2),
    Number(credit || 0).toFixed(2),
    Number(balance || 0).toFixed(2),
    remarks
  ];
};

const selectData = (item): ReportData => ({
  id: item.supplier?.id,
  date: new Date(item.transactionDate),
  supplierName: item.supplier?.name,
  voucherType: item.voucherType,
  reference: item.supplierInvoiceId,
  paymentMode: item.paymentMethod,
  debit: item.transactionType === "debit" ? item.amount : 0.0,
  credit: item.transactionType === "credit" ? item.amount : 0.0,
  balance: Number(item.dueAmount),
  remarks: "",
  stockTransactionId: item.stockTransactionId,
  ageingDays: item.supplier?.ageingDays,
  isOpeningBalance: item.isOpeningBalance || false
});

const processData = (data) => [...data].map((item) => selectData(item));

const showAgingDays = (agingDays) => {
  if (agingDays === null) return "";
  return (
    <Box>
      {agingDays > 0 ? (
        <Typography style={{ color: `${agingDays < 6 ? "orange" : ""}` }}>
          {`${agingDays} ${agingDays > 1 ? "days" : "day"} (due in)`}
        </Typography>
      ) : (
        <Typography style={{ color: "red" }}>
          {`${Math.abs(agingDays)} ${Math.abs(agingDays) > 1 ? "days" : "day"} (overdue)`}
        </Typography>
      )}
    </Box>
  );
};

const SupplierLedgerReport = (): JSX.Element => {
  const suppliers = useAppSelector((state: RootState) =>
    state.resources.suppliers.filter((item: Supplier) => Boolean(item.active))
  );

  const [reportData, setReportData] = React.useState<ReportData[]>([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [openSupplierDetails, setOpenSupplierDetails] = React.useState(false);
  const [filters, setFilters] = React.useState<FilterProps>({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    supplierIds: [],
    ignoreZeroDue: false,
    supplierAgeingDays: null,
    invoiceNumber: null
  });
  const applyFilters = (filter) => {
    setFilters(filter);
  };

  function handleAgeingDaysFilter(data, f) {
    if (f.supplierAgeingDays === null || f.supplierAgeingDays === "") return data;
    return data.filter((item) => {
      if (!item.supplier?.ageingDays && item.supplier?.ageingDays !== 0) {
        return false;
      }
      const dueInDays = moment(item.date)
        .add(item.supplier?.ageingDays, "days")
        .diff(moment(), "d");
      return dueInDays <= Number(f.supplierAgeingDays);
    });
  }

  useEffect(() => {
    (async () => {
      const data = await getSupplierLedgerReport(filters);
      setReportData(processData(handleAgeingDaysFilter(data, filters)));
    })();
  }, [filters]);

  const isMobileScreen = useMobileScreen();
  const dispatch = useDispatch();

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(supplierLedgerDefaultCols)
  );
  const [open, setOpen] = React.useState(false);

  return (
    <Can policyAccessKey="report:supplierLedgerReport">
      <Box marginTop="32px">
        <Box margin="0px 32px">
          <Box marginTop="32px">
            <Filters
              filters={filters}
              onSetFilters={(filter) => {
                applyFilters(filter);
              }}
              showTillDate
            />
          </Box>
        </Box>
        <Box
          sx={{
            overflowX: "auto",
            width: isMobileScreen ? "calc(100vw - 16px)" : "auto",
            mx: "auto"
          }}
        >
          <Box
            className="supplierLedgerReportList"
            marginTop="32px"
            width={isMobileScreen ? "1100px" : "auto"}
          >
            <List
              automation="supplierLedgerReportList"
              data={reportData}
              rowHeight={40}
              defaultSortColumn="supplierName"
              defaultSortOrder={1}
              activeRow={(selectedSupplier && selectedSupplier?.stockTransactionId) || 0}
              adjustHeightToContents
              columns={[
                {
                  key: "date",
                  label: tl("reports.supplierDate"),
                  formatter: ({ date }) => (moment(date).isValid() ? calFns.bsFullDate(date) : "")
                },
                {
                  key: "supplierName",
                  label: tl("reports.supplierName"),
                  sortable: true,
                  formatter: (row) =>
                    row.isOpeningBalance ? (
                      "Opening Balance"
                    ) : (
                      <Typography>
                        {or(row.supplierName)}
                        {!suppliers.find((item) => item.id === row.id) && ` (Deleted)`}
                      </Typography>
                    ),
                  sortFunction: (a, b, sortOrder): SORT_ORDER => {
                    if (a.supplierName === b.supplierName) {
                      return -1 * sortOrder * a.id > b.id ? -1 : 1;
                    }
                    return or(a.supplierName).toLowerCase() > or(b.supplierName).toLowerCase()
                      ? 1
                      : -1;
                  },
                  segmentable: true,
                  segmentBy: (row) =>
                    `${row.supplierName} ${
                      !suppliers.find((item) => item.id === row.id) ? ` (Deleted)` : ""
                    }`
                },
                {
                  key: "agingDate",
                  label: tl("reports.agingDate"),
                  formatter: ({ ageingDays, date }) => {
                    const dueInDays = moment(date).add(ageingDays, "days").diff(moment(), "d");
                    return showAgingDays(ageingDays ? dueInDays : null);
                  }
                },
                {
                  key: "voucherType",
                  label: tl("reports.voucherType"),
                  sortFunction: (a, b) =>
                    or(a.voucherType).toLowerCase() > or(b.voucherType).toLowerCase() ? 1 : -1,
                  formatter: ({ voucherType }) => or(voucherType)
                },
                {
                  key: "reference",
                  label: tl("reports.reference"),
                  formatter: ({ reference }) => or(reference)
                },
                {
                  key: "paymentMode",
                  label: tl("reports.paymentMode"),
                  formatter: ({ paymentMode }) => or(paymentMode)
                },
                {
                  key: "debit",
                  label: tl("reports.debit"),
                  formatter: ({ debit }) => or(debit)
                },
                {
                  key: "credit",
                  label: tl("reports.credit"),
                  formatter: ({ credit }) => or(credit)
                },
                {
                  key: "balance",
                  label: tl("reports.balance"),
                  segmentable: true,
                  formatter: ({ balance }) => or(balance.toFixed(2))
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                  <Typography>
                    <Box component="span" />
                  </Typography>
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight="500"
                      >
                        {acc.segment || "Clinic"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexBasis="200px"
                    paddingRight="30px"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      <Box component="span" />
                    </Typography>
                  </Box>
                </Box>
              )}
              onRowClick={async (supplier) => {
                if (!supplier.isOpeningBalance) {
                  setSelectedSupplier(supplier);
                  setOpenSupplierDetails(true);
                }
              }}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            `${t("reports.supplierLedgerReport")}-${
                              calFns.convertADtoBS(filters.from).formatted
                            }-${calFns.convertADtoBS(filters.until).formatted}`,
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["supplierName"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
        </Box>
        {openSupplierDetails && selectedSupplier && (
          <SupplierTransactionInfo
            selectedSupplier={selectedSupplier}
            setOpenSupplierDetails={setOpenSupplierDetails}
          />
        )}
      </Box>
    </Can>
  );
};

export default SupplierLedgerReport;

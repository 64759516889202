import * as React from "react";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { round } from "mathjs";
import { Currency } from "../../interfaces/StockInterfaces";

const InrToNprRate = 1.6;

export const currencyStartAdornment = (currency: Currency, onChange: (e) => void): JSX.Element => (
  <TextField
    style={{ width: "150px", marginRight: "10px" }}
    select
    inputProps={{ style: { marginLeft: "5px" } }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={{ disableUnderline: true }}
    value={currency}
    onChange={(e) => onChange(e)}
  >
    {Object.values(Currency).map((val) => (
      <MenuItem value={val} key={val}>
        {val}
      </MenuItem>
    ))}
  </TextField>
);

export const convertToINR = (value: number): number => round(Number(value) / InrToNprRate, 2);

export const convertToRs = (value: number): number => round(Number(value) * InrToNprRate, 2);

export const processPrice = (currency: Currency, price: number): number => {
  if (currency === Currency.Inr) {
    return round(price * InrToNprRate, 2);
  }
  return price;
};

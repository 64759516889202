import { Delete, Get, Patch, Post } from "./apiHelper";

const associateCompaniesRoot = "/api/associateCompanies";

export interface AssociateCompany {
  id: number;
  name: string;
  discountRate: number;
  resourceCentreId: number;
  active: boolean;
  // eslint-disable-next-line camelcase
  created_at: string;
}

export const getAssociateCompanies = async (): Promise<AssociateCompany[]> => {
  const response = await Get(associateCompaniesRoot, true);
  return response.data as Array<AssociateCompany>;
};

export const postAssociateCompany = async (
  payload: Partial<AssociateCompany>
): Promise<AssociateCompany> => {
  const response = await Post(associateCompaniesRoot, payload, true);
  return response.data as AssociateCompany;
};

export const getAssociateCompany = async (id: number): Promise<AssociateCompany> => {
  const response = await Get(`${associateCompaniesRoot}/${id}`, true);
  return response.data as AssociateCompany;
};

export const putAssociateCompany = async (
  department: Partial<AssociateCompany>
): Promise<AssociateCompany> => {
  const response = await Patch(`${associateCompaniesRoot}/${department.id}`, department, true);
  return response.data as AssociateCompany;
};

export const deleteAssociateCompany = async (
  department: Partial<AssociateCompany>
): Promise<string> => {
  const response = await Delete(`${associateCompaniesRoot}/${department.id}`, true);
  return response.data as string;
};

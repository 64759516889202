import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import classNames from "classnames";
import styles from "./styles.module.css";
import { tl } from "../translate";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) => {
  const { fullScreen } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} width="lg" fullScreen={Boolean(fullScreen)} />;
};

export interface EditCreateDialogType {
  open: boolean;
  title?: string | React.ReactElement<string>;
  footer?: React.ReactElement<string>;
  fullScreen?: boolean;
  onClose?: () => void;
  onSave?: () => void;
  saveLabel?: string | React.ReactElement<string>;
  children: React.ReactNode;
  saveAndClose?: boolean;
  alert?: boolean;
  disableSaveBtn?: boolean;
}

export const Footer = ({ children }: { children: React.ReactNode }): JSX.Element => <>{children}</>;

function Modal(props: EditCreateDialogType): JSX.Element {
  const {
    alert,
    saveAndClose,
    title,
    saveLabel,
    fullScreen,
    open,
    onClose,
    children,
    onSave,
    footer,
    disableSaveBtn = false
  } = props;

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} scroll="paper">
      {title ? (
        <DialogTitle
          classes={{ root: styles.title }}
          className={classNames({
            [styles.alert]: alert
          })}
        >
          <Typography color="inherit">
            <Box component="span" fontSize="1rem" fontWeight={600}>
              {title}
            </Box>
          </Typography>
        </DialogTitle>
      ) : null}
      <DialogContent classes={{ root: styles.content }}>{children}</DialogContent>
      <DialogActions
        className={classNames(styles.actions, {
          [styles.alert]: alert
        })}
      >
        {onClose && (
          <Button data-testmation="globalCancel" onClick={() => onClose()} color="primary">
            {tl("global.cancel")}
          </Button>
        )}
        {onSave && (
          <Button
            data-testmation="globalSave"
            disabled={disableSaveBtn}
            onClick={() => {
              onSave();
              if (saveAndClose) {
                onClose();
              }
            }}
            variant="contained"
            color="primary"
          >
            {saveLabel || tl("global.save")}
          </Button>
        )}
        {footer && <Footer>{footer}</Footer>}
      </DialogActions>
    </Dialog>
  );
}

Modal.defaultProps = {
  title: "",
  footer: "",
  fullScreen: false,
  onClose: null,
  onSave: null,
  saveLabel: "",
  saveAndClose: false,
  alert: false,
  disableSaveBtn: false
};

export default withMobileDialog()(Modal);

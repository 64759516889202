import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Box, Link, Typography } from "@mui/material";
import Panel from "../../../components/Panel";
import { getHistory } from "../../../api/history";
import { Client } from "../../../interfaces/ClientInterface";
import ActivityTimeline from "../../../components/ActivityTimeline/ActivityTimeline";
import BillShow from "../../Billing/BillShow/BillShow";
import AssessmentInfoPanel from "../../Assessment/AssessmentInfoPanel";
import { getAssessmentById } from "../../../api/assessment";
import { getLabTestRecord } from "../../../api/labTest";
import LabDetails from "../../Lab/LabDetails";
import { IpdInfo } from "../../IPD/IpdInfo";
import { getIpdtDetails } from "../../../api/ipd";
import { IpdStatus } from "../../../interfaces/IpdInterface";
import PatientFlowDetailView from "../../Reports/PatientFlowDetailView";
import { getBooking } from "../../../api/bookings";
import { notificationAdd } from "../../../actions/notification";

export enum Entity {
  booking = "booking",
  bill = "bill",
  resourceCentre = "resourceCentre",
  assessment = "assessment",
  labTestRecord = "labTestRecord",
  subscription = "subscription",
  fiscalPeriod = "fiscalPeriod",
  ipd = "ipd",
  client = "client",
  serviceProvider = "serviceProvider",
  employee = "employee"
}

export enum EntityRoutes {
  booking = "/calendar",
  bill = "/billing/bills",
  assessment = "/assessment/assessments",
  labTestRecord = "/lab/labRecords/all",
  ipd = "/ipd",
  client = "client"
}

const HISTORY_DATA_LIMIT = 20;

interface ClientActivityProps {
  onClose: () => void;
  client: Client;
}

const ClientActivity = ({ onClose, client }: ClientActivityProps): JSX.Element => {
  const [clientHistory, setClientHistory] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const getClientHistory = async () => {
      const clientActivityHstory = await getHistory({
        clientId: client.id,
        pageSize: HISTORY_DATA_LIMIT
      });
      setClientHistory(clientActivityHstory);
    };
    getClientHistory();
  }, [client.id]);

  const [selectedLabDetails, setSelectedLabDetails] = React.useState(null);
  const [selectedAssessmentDetails, setSelectedAssessmentDetails] = React.useState(null);
  const [selectedBill, setSelectedBill] = React.useState(null);
  const [selectedIpd, setSelectedIpd] = React.useState(null);
  const [selectedBooking, setSelectedBooking] = React.useState(null);

  const resetStatesForSelectedData = () => {
    setSelectedLabDetails(null);
    setSelectedAssessmentDetails(null);
    setSelectedBill(null);
    setSelectedIpd(null);
    setSelectedBooking(null);
  };

  const onClick = async (history) => {
    resetStatesForSelectedData();
    let selectedData;
    try {
      switch (history.entity) {
        case Entity.booking:
          if (selectedBooking?.id !== history.entityId) {
            selectedData = await getBooking(history.entityId);
          }
          setSelectedBooking(selectedData);
          break;
        case Entity.bill:
          setSelectedBill(history.entityId);
          break;
        case Entity.assessment:
          if (selectedAssessmentDetails?.id !== history.entityId) {
            selectedData = await getAssessmentById(history.entityId);
            setSelectedAssessmentDetails(selectedData);
          }
          break;
        case Entity.labTestRecord:
          if (selectedLabDetails?.id !== history.entityId) {
            selectedData = await getLabTestRecord(history.entityId);
            setSelectedLabDetails(selectedData[0]);
          }
          break;
        case Entity.ipd:
          if (selectedIpd?.id !== history.entityId) {
            selectedData = await getIpdtDetails(history.entityId);
            setSelectedIpd(selectedData);
          }
          break;
        default:
          break;
      }
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to get selected data.",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Panel
      title="Client Activity"
      onClose={onClose}
      wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
    >
      {clientHistory.length ? (
        <Box sx={{ marginLeft: "20px", marginTop: "10px" }}>
          <ActivityTimeline onActivityClick={onClick} activities={clientHistory} showTime />
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(push("/reports/otherReport/history"))}
          >
            <Typography color="green" marginBottom="10px">
              Show More
            </Typography>
          </Link>
        </Box>
      ) : (
        <Box display="flex" height="100%" alignItems="center" justifyContent="center">
          <Typography>No Activities For Client Yet.</Typography>
        </Box>
      )}
      {selectedBill && (
        <BillShow
          billId={selectedBill}
          handleViewClose={() => setSelectedBill(null)}
          hideActions
          wrapperStyle={{ minWidth: "660px", maxWidth: "660px" }}
        />
      )}
      {selectedLabDetails && (
        <LabDetails
          hideActionButton
          editMode={false}
          setEditMode={() => ({})}
          labObj={selectedLabDetails}
          labId={selectedLabDetails.id}
          handleViewClose={() => setSelectedLabDetails(null)}
          navigateTo={(path) => dispatch(push(path))}
          wrapperStyle={{ minWidth: "650px", maxWidth: "650px" }}
        />
      )}
      {selectedAssessmentDetails && (
        <AssessmentInfoPanel
          onClose={() => setSelectedAssessmentDetails(null)}
          assessment={selectedAssessmentDetails}
          wrapperStyle={{ minWidth: "650px", maxWidth: "650px" }}
        />
      )}
      {selectedIpd && (
        <Panel
          title={selectedIpd?.status === IpdStatus.Admitted ? "Admission Info" : "Discharge Info"}
          wrapperStyle={{ minWidth: "650px", maxWidth: "650px" }}
          onClose={() => setSelectedIpd(null)}
        >
          <Box pl={2}>
            <IpdInfo ipdRecord={selectedIpd} />
          </Box>
        </Panel>
      )}
      {selectedBooking && (
        <PatientFlowDetailView onClose={() => setSelectedBooking(null)} data={selectedBooking} />
      )}
    </Panel>
  );
};

export default ClientActivity;

import { useQuery, UseQueryResult } from "react-query";
import { getPurchaseVouchers, getSalesVouchers } from "../../api/accounts";
import { Filter } from "../../containers/accounts/VoucherList";
import { VoucherCodes } from "../../interfaces/Accounts";
import { BillDocumentType } from "../../interfaces/BillInterfaces";
import { StockTransactions } from "../../interfaces/StockInterfaces";

export const usePurchaseVouchers = (filter: string): UseQueryResult<StockTransactions[], Error> =>
  useQuery<StockTransactions[], Error>(["purchaseVouchers", VoucherCodes.PR], getPurchaseVouchers, {
    retry: 0,
    enabled: filter === Filter.PURCHASE
  });

export const useSalesVouchers = (filter: string): UseQueryResult<BillDocumentType[], Error> =>
  useQuery<BillDocumentType[], Error>(["salesVouchers", VoucherCodes.SL], getSalesVouchers, {
    retry: 0,
    enabled: filter === Filter.SALES
  });

export const useDebitNoteVouchers = (filter: string): UseQueryResult<StockTransactions[], Error> =>
  useQuery<StockTransactions[], Error>(
    ["debitNoteVouchers", VoucherCodes.DN],
    getPurchaseVouchers,
    {
      retry: 0,
      enabled: filter === Filter.DEBIT_NOTE
    }
  );

export const useCreditNoteVouchers = (filter: string): UseQueryResult<BillDocumentType[], Error> =>
  useQuery<BillDocumentType[], Error>(["creditNoteVouchers", VoucherCodes.CN], getSalesVouchers, {
    retry: 0,
    enabled: filter === Filter.CREDIT_NOTE
  });

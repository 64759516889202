import React from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import ImageCrop from '../ImageCrop/ImageCrop';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Dialog from '../Dialog/Dialog';
import styles from '../../containers/Lab/Lab.module.css';
import dummy from '../../../assets/images/dummyProfile.png';
import Webcam from 'react-webcam';
import CancelIcon from '@mui/icons-material/HighlightOff';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user'
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

function ClientImageUploader({
  readOnly,
  action,
  initialImage
}: {
  readOnly?: boolean;
  action: (...args) => void;
  initialImage: string;
}) {
  const webcamRef = React.useRef(null);

  React.useEffect(() => {
    setTmpImage(initialImage || dummy);
  }, [initialImage]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [cropperOpen, setCropperOpen] = React.useState(false);
  const [tmpImage, setTmpImage] = React.useState(initialImage);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const captureUserImage = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc?.size >= 2097152) {
      toggleDialog();
      return;
    } else {
      setTmpImage(imageSrc);
      setCropperOpen(true);
    }
  }, [webcamRef]);

  function toggleDialog() {
    setDialogOpen((dialogOpen) => !dialogOpen);
  }

  const classes = useStyles();
  const [openCamera, setOpenCamera] = React.useState(false); // @dev this state is used to open camera for user image

  const handleOpenCamera = () => {
    setOpenCamera(true);
  };

  const handleCloseCamera = () => {
    setOpenCamera(false);
  };

  const handleImageClick = (event) => {
    if (!readOnly) {
      setAnchorEl(event.currentTarget);
    }
  };

  function handleImageChange(e) {
    e.preventDefault();
    if (e.target.files[0]?.size >= 2097152) {
      toggleDialog();
      return;
    } else {
      const file = e.target.files[0];
      if (!file) return;
      const nameOnly = file.name.lastIndexOf('.') + 1;
      const ext = file.name.substr(nameOnly, file.name.length).toLowerCase();
      if (['jpg', 'jpeg', 'png', 'gif'].includes(ext) && file) {
        setTmpImage(URL.createObjectURL(file));
        setCropperOpen(true);
      } else {
        toggleDialog();
      }
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex">
      <img
        src={tmpImage}
        alt="client-image"
        onClick={handleImageClick}
        style={{
          height: '64px',
          width: '64px',
          borderRadius: '50%',
          border: '2px solid grey',
          cursor: `${readOnly ? '' : 'pointer'}`
        }}
      />
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <input
              type="file"
              onChange={handleImageChange}
              className={styles.imageInput}
              id="actual-btn"
              hidden
            />
            {/* @dev using label as button to upload */}
            <label className={styles.userImageLabel} htmlFor="actual-btn">
              Upload Image
            </label>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleOpenCamera();
              setAnchorEl(null);
            }}
          >
            Capture Image
          </MenuItem>
        </Menu>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCamera}
        onClose={handleCloseCamera}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openCamera}>
          <div className={classes.paper}>
            <Webcam
              audio={false}
              height={720}
              mirrored={true}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={1280}
              videoConstraints={videoConstraints}
            />

            <CancelIcon
              style={{
                marginTop: '-32px',
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                position: 'absolute',

                left: '36px',
                backgroundColor: '#fff',
                cursor: 'pointer'
              }}
              onClick={() => {
                handleCloseCamera();
              }}
            />

            <div
              style={{
                marginTop: '-32px',
                height: '50px',
                width: '50px',
                border: '5px #000',
                transform: 'rotateY(180deg)',
                borderRadius: '50%',
                position: 'absolute',
                marginBottom: '48px',
                bottom: 0,
                left: '50%',
                backgroundColor: '#fff',
                cursor: 'pointer'
              }}
              onClick={() => {
                captureUserImage();
                handleCloseCamera();
              }}
            ></div>
          </div>
        </Fade>
      </Modal>

      {dialogOpen && (
        <Dialog
          title="Cannot upload image"
          description="File size limit is only 2MB or wrong file format"
          next={toggleDialog}
          readMode={true}
        />
      )}
      <ImageCrop
        image={tmpImage}
        isOpen={cropperOpen}
        dynamicRatio={false}
        onCancel={() => {
          setCropperOpen(false);
          setTmpImage(initialImage);
        }}
        onSave={(croppedImage) => {
          action(croppedImage);
          setTmpImage(croppedImage);
          setCropperOpen(false);
        }}
      />
    </Box>
  );
}

export default ClientImageUploader;

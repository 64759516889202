import { chain, round } from "mathjs";
import { PriceCalculatedOn, StockItemInterface } from "../../interfaces/StockInterfaces";

const findSalesPriceAddingProfitMargin = (profitMargin: number, purchasePrice: number): number =>
  round(
    chain((profitMargin || 0) / 100)
      .multiply(purchasePrice)
      .add(purchasePrice)
      .done(),
    2
  );

const roundBy2 = (v: number): number => round(v || 0, 2);

/*
Finding Sales Price:
- salesPrice = profitMargin of purchasePrice + purchasePrice
- if Purchase price is 100 and profit margin is 10 %
- then Sales Price will be 110
*/

// if both = true the it will return { unitPriceExcVAT, packagePriceExcVAT }
export const calculateSalesPrice = (
  currentState: Partial<StockItemInterface>,
  value?: number,
  findBoth?: boolean
): number | { unitPriceExcVAT: number; packagePriceExcVAT: number } => {
  const {
    profitMargin,
    purchasePriceCalculationOn,
    purchasePricePerPackage,
    purchasePricePerUnit,
    unitsPerPackage
  } = currentState;

  if (findBoth) {
    if (purchasePriceCalculationOn === PriceCalculatedOn.unit) {
      return {
        unitPriceExcVAT: roundBy2(
          findSalesPriceAddingProfitMargin(Number(profitMargin), Number(value))
        ),
        packagePriceExcVAT: roundBy2(
          findSalesPriceAddingProfitMargin(
            Number(profitMargin),
            Number(value) * (unitsPerPackage || 1)
          )
        )
      };
    }
    return {
      packagePriceExcVAT: roundBy2(
        findSalesPriceAddingProfitMargin(Number(profitMargin), Number(value))
      ),
      unitPriceExcVAT: roundBy2(
        findSalesPriceAddingProfitMargin(
          Number(profitMargin),
          Number(value) / (unitsPerPackage || 1)
        )
      )
    };
  }
  const purchasePrice =
    purchasePriceCalculationOn === "unit"
      ? Number(purchasePricePerUnit || 0)
      : Number(purchasePricePerPackage || 0);
  return findSalesPriceAddingProfitMargin(Number(profitMargin), purchasePrice);
};

/**
 * Profit Margin is not saved on the table so we have to calculate on the fly.
 * Finding Profit Margin:
 * Profit Margin = ((Sales Price - Purchase Price)/purchasePrice)* 100
 */

export const findProfitMargin = (currentState: StockItemInterface): number => {
  const {
    purchasePriceCalculationOn,
    purchasePricePerPackage,
    purchasePricePerUnit,
    priceCalculationOn,
    unitPriceExcVAT,
    packagePriceExcVAT
  } = currentState;
  const purchasePrice =
    purchasePriceCalculationOn === "unit"
      ? Number(purchasePricePerUnit || 0)
      : Number(purchasePricePerPackage || 0);
  const salesPrice =
    priceCalculationOn === "unit" ? Number(unitPriceExcVAT || 0) : Number(packagePriceExcVAT || 0);
  if (salesPrice < purchasePrice || Number(purchasePrice) === 0) {
    return 0;
  }
  return round(
    chain(salesPrice - purchasePrice)
      .multiply(100)
      .divide(purchasePrice || 1)
      .done(),
    2
  );
};

export const isValidToCalculateSalesPrice = (profitMargin: number): boolean =>
  profitMargin < 100 && profitMargin >= 0;

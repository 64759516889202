import React from "react";
import { capitalize } from "lodash";
import { Box, Typography } from "@mui/material";
import BillDraftItems from "./BillDraftItems";
import Panel from "../../../../components/Panel";
import { tl } from "../../../../components/translate";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { scaleFont } from "../../PrintBill/BillPrintHelpers";
import { getFullName, separateByComma } from "../../../../helpers/formatters";
import {
  BillDocumentType,
  DiscountTypes,
  DocumentTypes
} from "../../../../interfaces/BillInterfaces";
import BilllEditorActions from "../BilllEditorActions";
import { BillEditorWarning } from "../BillEditor";
import Qr from "./Qr";

interface QrPaymentPanelProps {
  draft: BillDocumentType;
  handleViewClose: () => void;
  setWarningMsg: ({ message, type }: BillEditorWarning) => void;
  currentBillCounter: string;
  qrValue: Record<string, string> | null;
  qrS3Url: string | null;
}

const QrPaymentPanel = (props: QrPaymentPanelProps): JSX.Element => {
  const { draft, handleViewClose, setWarningMsg, currentBillCounter, qrValue, qrS3Url } = props;

  const isProductSelected = () =>
    draft?.billItems?.some((item) => item.productId && item.description?.trim());

  const footer = (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginRight: "32px",
        justifyContent: "flex-end",
        gap: "10px"
      }}
    >
      <BilllEditorActions
        draft={draft}
        setWarningMsg={setWarningMsg}
        isCreditNote={draft.type === DocumentTypes.CREDITNOTE}
        currentBillCounter={currentBillCounter}
        qr={{
          isQrPaymentPanelOpen: true
        }}
      />
    </Box>
  );

  return (
    <Panel onClose={handleViewClose} title={tl("billing.payUsingQrCode")} footer={footer}>
      <Box
        p={4}
        width="100%"
        height="calc(100vh - 125px)"
        style={{ display: "flex", flexDirection: "column", gap: "20px", overflowY: "auto" }}
      >
        <Box display="flex" alignItems="center">
          <Typography>{tl("qrPayment.billNumber")}</Typography>
          <Typography>{draft?.billNumber}</Typography>
        </Box>

        {/* client info */}
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={800}>{tl("qrPayment.clientInformation")}</Typography>
          <Box display="flex" alignItems="center">
            <Typography fontWeight={600} width="25%">
              {tl("qrPayment.clientName")}
            </Typography>
            <Typography>{getFullName(draft?.client)}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography fontWeight={600} width="25%">
              {tl("qrPayment.customerNumber")}
            </Typography>
            <Typography>{draft?.client?.customerNumber}</Typography>
          </Box>
          {draft?.client?.phone && (
            <Box display="flex" alignItems="center">
              <Typography>{tl("qrPayment.phone")}</Typography>
              <Typography>{draft.client.phone}</Typography>
            </Box>
          )}
          {draft?.client?.email && (
            <Box display="flex" alignItems="center">
              <Typography>{tl("qrPayment.email")}</Typography>
              <Typography>{draft.client.email}</Typography>
            </Box>
          )}
          {(draft?.client?.address || draft?.client?.city) && (
            <Box display="flex">
              <Typography>{tl("qrPayment.address")}</Typography>
              <Typography>
                {separateByComma(capitalize(draft.client.address), capitalize(draft.client.city))}
              </Typography>
            </Box>
          )}
        </Box>

        {/* bill items */}
        {draft?.billItems && draft?.billItems?.length > 0 && isProductSelected() && (
          <BillDraftItems draft={draft} />
        )}

        {/* qr  and summary */}
        <Box display="flex" justifyContent="space-between">
          <Box fontSize={scaleFont("0.3cm")} textTransform="uppercase">
            <Typography align="left" fontWeight={700} mb={1}>
              Scan To Pay with {draft?.paymentInfo?.paymentMethod}
            </Typography>
            {qrS3Url && <img src={qrS3Url} alt="qr" height="180px" />}
            {!qrS3Url && qrValue && <Qr qrValue={qrValue} />}
          </Box>

          {/* bill summary */}
          <Box width="50%" fontSize={scaleFont("0.3cm")} textTransform="uppercase">
            <Box display="flex" flexDirection="column" gap="5px">
              <Box display="flex" gap="10px" alignItems="flex-start" justifyContent="space-between">
                <Typography>{tl("qrPayment.grossTotalAmount")}</Typography>
                <Typography>{rupeeDisplay(draft?.summary?.totalPerUnitTimesQty)}</Typography>
              </Box>

              {Boolean(draft?.summary?.discount) && (
                <Box
                  display="flex"
                  gap="10px"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Typography>
                    {draft?.settings?.discountSettings?.discountType === DiscountTypes.percentage
                      ? tl("qrPayment.discountPercentage")
                      : tl("qrPayment.discount")}{" "}
                  </Typography>
                  <Typography>
                    {draft?.settings?.discountSettings?.discountType === DiscountTypes.percentage
                      ? `${draft?.summary?.discountPct} %`
                      : rupeeDisplay(draft?.summary?.discount)}
                  </Typography>
                </Box>
              )}

              <Box display="flex" gap="10px" alignItems="flex-start" justifyContent="space-between">
                <Typography>{tl("qrPayment.taxableAmount")}</Typography>
                <Typography>{rupeeDisplay(draft?.summary?.taxableAmount)}</Typography>
              </Box>

              <Box display="flex" gap="10px" alignItems="flex-start" justifyContent="space-between">
                <Typography>{tl("qrPayment.totalAmount")}</Typography>
                <Typography>{rupeeDisplay(draft?.summary?.totalAmount)}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Panel>
  );
};

export default QrPaymentPanel;

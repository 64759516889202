import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface InvestigationInterface {
  investigation: string;
  onChange: (value) => void;
}

const Investigation: FC<InvestigationInterface> = ({ investigation, onChange }) => {
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.investigation}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <DebouncedTextField
          margin="dense"
          id="outlined-basic"
          data-testmation="investigationTextField"
          fullWidth
          multiline
          variant="outlined"
          rows="3"
          InputLabelProps={{ shrink: true }}
          placeholder="Type investigation here"
          value={investigation}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          debounceAt={400}
        />
      </Box>
    </Box>
  );
};

export default Investigation;

import * as React from "react";
import Chip from "@mui/material/Chip";
import { startCase } from "lodash";
import { SubscriptionInvoiceStatus } from "../../interfaces/Subscription";

const ColorMapper = {
  [SubscriptionInvoiceStatus.paid]: "green",
  [SubscriptionInvoiceStatus.unpaid]: "red",
  [SubscriptionInvoiceStatus.cancelled]: "grey",
  [SubscriptionInvoiceStatus.pendingApproval]: "orange",
  [SubscriptionInvoiceStatus.unverified]: "blue"
};

interface Props {
  status: SubscriptionInvoiceStatus;
}

export default function SubscriptionInvoiceStatusChip(props: Props): JSX.Element {
  const { status } = props;

  return <Chip style={{ color: ColorMapper[status] }} label={startCase(status)} />;
}

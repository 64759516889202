import { Typography, Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { extractSecondaryData, useCoaAll } from "../../../accounts/hooks";
import LedgerSelect from "./LedgerSelect";
import { taxationPatch } from "../../../../slices/journalEntry";
import useTaxations from "../../../../hooks/useTaxations";
import { tl } from "../../../../components/translate";

export default function TaxationsMapping(): JSX.Element {
  const { equityAndLiabilities } = extractSecondaryData(useCoaAll());
  const dispatch = useDispatch();
  const taxations = useTaxations();
  const ledgerRelatedToTax =
    /*
    selecting  child ledger from "duties and taxes"
    accounting groups and equityAndLiabilities COA
    */
    equityAndLiabilities?.secondaryLedgers
      ?.find((sec) => sec.code === "103")
      ?.accountingGroups?.find((acc) => acc.code === "10303")?.childGeneralLedgers || [];

  return (
    <Box m="20px 0">
      <Typography fontWeight="medium">{tl("taxationMapping")}</Typography>
      <Box m="10px 0 0 10px">
        {[...taxations]
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <Box key={item.id} display="flex" mb="10px" flexWrap="wrap">
              <Typography width="200px">{item.description}</Typography>
              <Box maxWidth={400} width="100%">
                <LedgerSelect
                  selected={ledgerRelatedToTax.find((lg) => lg.id === item.ledgerId)}
                  options={ledgerRelatedToTax}
                  onChange={(v) => {
                    dispatch(taxationPatch({ ...item, ledgerId: v?.id || null }));
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import React from "react";

interface Props {
  onTerminate: () => void;
  open: boolean;
  handleClose: () => void;
}

export default function TerminateSubscriptionDialog({
  onTerminate,
  open,
  handleClose
}: Props): JSX.Element {
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to terminate this subscription?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After clicking agree, this subscription will be terminated!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onTerminate();
            }}
            color="primary"
            autoFocus
            variant="contained"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import React from "react";
import { Button } from "@mui/material";
import Modal from "../../components/Modal/Modal";

const SubscriptionExpiryModal = ({ expiresIn }: { expiresIn: string }): JSX.Element => {
  const [showModal, setShowModal] = React.useState(true);

  return (
    <Modal
      title="Notice"
      open={showModal}
      footer={<Button onClick={() => setShowModal((prevValue) => !prevValue)}>Ok</Button>}
    >
      <div>
        Your subscription is set to expire in {expiresIn}. Please contact Okhati Sales to renew
        subscription.
      </div>
    </Modal>
  );
};
export default SubscriptionExpiryModal;

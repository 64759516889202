import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";

interface Props {
  control: Control<FieldValues>;
  name: string;
  label: string;
  type: "checkbox" | "radio";
  radioOptions?: Array<{
    value: string;
    label: string;
  }>;
}

const InputComponents = ({
  control,
  name,
  label,
  type,
  radioOptions = [
    {
      label: "Yes",
      value: "yes"
    },
    {
      label: "No",
      value: "no"
    }
  ]
}: Props): JSX.Element => {
  switch (type) {
    case "checkbox":
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              // eslint-disable-next-line react/jsx-props-no-spreading
              control={<Checkbox {...field} checked={field.value} />}
              label={label}
            />
          )}
        />
      );
    case "radio":
      return (
        <FormControl
          sx={{
            width: "100%"
          }}
        >
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <FormLabel
              sx={{
                fontWeight: 500,
                fontSize: "1rem"
              }}
            >
              {label}
            </FormLabel>
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <RadioGroup row {...field}>
                  {type === "radio" &&
                    radioOptions.map((item) => (
                      <FormControlLabel
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                        key={item.value}
                      />
                    ))}
                </RadioGroup>
              )}
            />
          </Box>
        </FormControl>
      );
    default:
      return <></>;
  }
};

export default InputComponents;

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DrawingBoard from "../../Assessment/DrawingBoard";
import { tl } from "../../../components/translate";
import useMobileScreen from "../../../hooks/useMobileScreen";
import useFullScreen from "../../../hooks/useFullScreen";
import ServiceProviderSignatureUploader from "./SPSignatureUploader";

interface Props {
  signature?: string;
  setSignatureData: React.Dispatch<
    React.SetStateAction<{ hasUpdatedSignature: boolean; signature: string }>
  >;
}
function Signature({ signature = "", setSignatureData }: Props): JSX.Element {
  const [boardData, setBoardData] = useState([]);
  const [open, setOpen] = useState(false);

  const [image, setImage] = useState("");

  useEffect(() => {
    setImage(signature);
  }, [signature]);

  const isMobileScreen = useMobileScreen();

  const [requestFullScreen, exitFullScreen] = useFullScreen();

  return (
    <>
      {image ? (
        <Box display="flex">
          <img
            width="227px"
            style={{
              objectFit: "contain",
              border: "2px solid gray",
              borderRadius: "10px"
            }}
            src={image}
            alt="signature"
          />
          <Box
            onClick={() => {
              setOpen(!open);
              if (isMobileScreen) {
                requestFullScreen();
              }
            }}
            sx={{ display: "flex", flexBasis: "100px", cursor: "pointer", pt: 4, pl: 2 }}
          >
            {tl("account.edit")}
          </Box>
        </Box>
      ) : (
        <Typography>
          <Box
            onClick={() => {
              setOpen(!open);
            }}
            sx={{ cursor: "pointer" }}
          >
            <u>{tl("AddSignature")}</u>
          </Box>
        </Typography>
      )}
      <Dialog open={open} classes={{ paper: "slate" }}>
        <DrawingBoard
          closeModal={() => {
            setOpen(false);
            exitFullScreen();
          }}
          title={tl("signature.signature")}
          handleImageSave={(imageData, boardDataParams) => {
            setImage(imageData);
            setBoardData(boardDataParams);
            setSignatureData({ signature: imageData, hasUpdatedSignature: true });
          }}
          image={image}
          width={isMobileScreen ? 240 : 400}
          height={isMobileScreen ? 150 : 200}
          boardData={boardData}
          setBoardData={setBoardData}
          imageIndex={0}
        />
      </Dialog>

      <Box display="flex" alignItems="center" mt={2}>
        <Typography style={{ fontWeight: 600 }}>{tl("UploadSign")}</Typography>
      </Box>

      <ServiceProviderSignatureUploader
        image={image}
        onSave={(base64Image) => {
          setImage(base64Image);
          setSignatureData({ signature: base64Image, hasUpdatedSignature: true });
        }}
      />
    </>
  );
}

export default Signature;

import { FilterProps } from "../containers/Reports/Filters";
import { PaymentHistoryProps } from "../containers/Stock/StockTransactions/StockTransactionView";
import {
  Entry,
  Stock,
  StockTransactions,
  StockTrxPaymentInterface,
  PaymentHistory,
  QueryParams
} from "../interfaces/StockInterfaces";
import { Get, Patch, Post } from "./apiHelper";

const stockTransactionRoot = "/api/stockTransactions";
const stockRoot = "/api/stocks";

export const postStockTransaction = async (data: Entry): Promise<Entry> => {
  const url = `${stockTransactionRoot}`;
  const response = await Post(`${url}`, { data }, true);
  return response.data as Promise<Entry>;
};

export const getStockTransaction = async (query: QueryParams): Promise<StockTransactions[]> => {
  const response = await Get(`${stockTransactionRoot}`, true, query as { [key: string]: string });
  return response.data as Promise<StockTransactions[]>;
};

export const getStocks = async (): Promise<Stock[]> => {
  const url = `${stockRoot}`;
  const response = await Get(`${url}`, true);
  return response.data as Promise<Stock[]>;
};

export const getStockByProductId = async (id: number): Promise<Array<Stock>> => {
  const response = await Get(`/api/stock/${id}`, true);
  return response.data as Promise<Array<Stock>>;
};

export const recordPayment = async (trxData: StockTrxPaymentInterface): Promise<string> => {
  const response = await Post(`${stockTransactionRoot}/recordPayment`, trxData, true);
  return response.data as Promise<string>;
};

export const getStockByTransactionId = async (id: number): Promise<StockTransactions> => {
  const response = await Get(`/api/stockTransaction/${id}`, true);
  return response.data as Promise<StockTransactions>;
};

export const editOpeningStock = async (data: Entry): Promise<StockTransactions> => {
  const response = await Patch(`/api/stockTransaction/${data.id}/editOpeningStock`, data, true);
  return response.data as Promise<StockTransactions>;
};

export const saveStockAdjustment = async (data: Entry): Promise<StockTransactions> => {
  const response = await Patch(`/api/stockTransaction/${data.id}/adjustment`, data, true);
  return response.data as Promise<StockTransactions>;
};

export const getRecordPaymentByTrxId = async (id: number): Promise<PaymentHistory[]> => {
  const response = await Get(`${stockTransactionRoot}/${id}/recordPayment`, true);
  return response.data as Promise<PaymentHistory[]>;
};

export const getTrnxHavingDue = async (
  id: number,
  query: { [key: string]: string }
): Promise<Entry[]> => {
  const response = await Get(`${stockTransactionRoot}/${id}/dueTransactions`, true, query);
  return response.data as Promise<Entry[]>;
};

export const cancelPayment = async (data: PaymentHistoryProps): Promise<PaymentHistory[]> => {
  const response = await Post(`${stockTransactionRoot}/paymentCancel`, data, true);
  return response.data as Promise<PaymentHistory[]>;
};

export const getStockProductByIds = async (ids: number[]): Promise<Stock[]> => {
  const response = await Get(`/api/stocksByIds?productIds=${ids || []}`, true);
  return response.data as Promise<Stock[]>;
};

export const getReturnedRelatedTransactions = async (id: number): Promise<StockTransactions[]> => {
  const response = await Get(`/api/returnedStockTransactions/${id}`);
  return response?.data as Promise<StockTransactions[]>;
};

export const getPurchaseRequisitionForms = async (
  filters: FilterProps
): Promise<StockTransactions[]> => {
  const response = await Get(
    `${stockTransactionRoot}/purchaseRequisitionForms?branchIds=${
      filters.branchIds
    }&from=${filters.from.toISOString()}&until=${filters.until.toISOString()}`
  );
  return response.data as StockTransactions[];
};

import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";

interface Props {
  onLabEdit: () => void;
  onGroupEdit: () => void;
}

function LabMasterData({ onLabEdit, onGroupEdit }: Props): JSX.Element {
  return (
    <Box py={2} display="flex" gap={2} ml={1}>
      <Button onClick={onLabEdit} component={Paper}>
        <Typography fontWeight="700" textTransform="none" maxWidth={180} color="#555">
          Create or Edit Lab Test
        </Typography>
      </Button>
      <Button onClick={onGroupEdit} component={Paper}>
        <Typography fontWeight="700" textTransform="none" maxWidth={180} color="#555">
          Create or Edit Test Group
        </Typography>
      </Button>
    </Box>
  );
}

export default LabMasterData;

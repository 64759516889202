import React, { FunctionComponent } from "react";
import { Typography, Link, Box, Tooltip } from "@mui/material";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import "./PrintBill.scss";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { tl, t } from "../../../components/translate";
import { registerPrint } from "../../../actions/bill";
import {
  DocumentTypes,
  BillStatus,
  BillType,
  PrintTemplate
} from "../../../interfaces/BillInterfaces";
import { dateWithZeroBeforeSingleDigits } from "../../../helpers/formatters";
import { isOldFormatBill } from "../../../actions/helpers/billHelper";
import DescribeBillV1 from "./BillDescriptionV1";
import DescribeBillV2Half from "./BillDescriptionV2Half";
import usePrintStyles from "../../../hooks/usePrintStyles";
import { getSectionStyle, scaleFont } from "../../Lab/LabPrint/LabPrintFunctions";
import LabPrintLetterHead from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import { BillClientInfoSmall, checkWalkInCustomer, InfoField } from "./BillPrintHelpers";
import LabPrintLetterHeadCentered from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import logo from "../../../../assets/images/poweredByOkhati.png";
import { RootState } from "../../../store";
import { User } from "../../../interfaces/User";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import usePrintShortcut from "../../../hooks/usePrintShortcut";
import useOpenPrintWindow from "../../../hooks/useOpenPrintWindow";
import { getPrintTitle } from "./PrintBill";

interface PrintBillProps {
  showDobOnPrint?: boolean;
  hidePaymentMethodOnPrint?: boolean;
  buttonText?: string;
  billId: number;
  resourceCentre: ResourceCentre;
  billData: BillType;
  customerNumber: string;
  sendPrintInfo: (id) => void;
  user: User;
  SPs: ServiceProvider;
  forceTemplate?: string;
  labelStyle?: React.CSSProperties;
  clientBalance: number;
  addPrintCount?: boolean;
  onPrintClick?: () => void;
  isForPreview?: boolean;
}

const defaultProps = {
  buttonText: "Print",
  forceTemplate: null,
  labelStyle: {},
  addPrintCount: true,
  onPrintClick: () => null,
  showDobOnPrint: false
};

const PrintBill: FunctionComponent<PrintBillProps> = ({
  billId,
  billData,
  buttonText,
  resourceCentre,
  sendPrintInfo,
  user,
  SPs,
  forceTemplate,
  labelStyle,
  clientBalance,
  addPrintCount,
  onPrintClick,
  showDobOnPrint = false,
  hidePaymentMethodOnPrint = false,
  isForPreview = false
}) => {
  const printRef = React.useRef();
  const reactPrintRef = React.useRef(null);

  const getCustomPrintStyles = () => {
    if (forceTemplate === PrintTemplate.B4) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({
        pageStyle: "size: 5.5in 8.5in landscape; margin: 0mm"
      }).applyPrintStyles();
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({
        pageStyle: "size: A5; margin: 0mm"
      }).applyPrintStyles();
    }
  };
  usePrintShortcut(reactPrintRef, getCustomPrintStyles);

  useOpenPrintWindow(reactPrintRef, getCustomPrintStyles);

  const [isPrinting, setIsPrinting] = React.useState(false);
  if (!billData || !billData.document) {
    return null;
  }

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const showFooter = resourceCentre?.settings?.printSettings.billPrintSettings?.includeFooter;

  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showReferrer = resourceCentre?.settings?.printSettings.includeReferrer;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const customSubtitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
  const hideRcLogo = Boolean(customTitle);

  let letterHeadComponent = <Box />;
  if (showLetterhead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <LabPrintLetterHeadCentered
          resourceCentre={{
            ...resourceCentre,
            panNo
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    } else {
      letterHeadComponent = (
        <LabPrintLetterHead
          resourceCentre={{
            ...resourceCentre,
            panNo
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    }
  }
  return (
    <>
      <ReactToPrint
        onAfterPrint={() => {
          window.focus();
          if (addPrintCount) {
            sendPrintInfo(billId);
          }
          // eslint-disable-next-line react-hooks/rules-of-hooks
          usePrintStyles({
            pageStyle: "size: A4; margin: 0mm"
          }).applyPrintStyles();
          setIsPrinting(false);
        }}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className="actionPrintBtn"
            onMouseEnter={() => {
              getCustomPrintStyles();
            }}
            onMouseLeave={() => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              usePrintStyles({
                pageStyle: "size: A4; margin: 0mm"
              }).applyPrintStyles();
            }}
            style={{ textDecoration: "none", padding: "5px 15px" }}
          >
            <Tooltip title="Alt + P / Ctrl + P" arrow>
              <Typography component="span" style={labelStyle}>
                <Box
                  ref={reactPrintRef}
                  onClick={() => {
                    if (isPrinting) return;
                    setIsPrinting(true);
                  }}
                  className="actionBtnLabel"
                  component="span"
                >
                  {buttonText || "Print"}
                </Box>
              </Typography>
            </Tooltip>
          </Link>
        )}
        content={() => printRef.current}
      />
      <iframe title="halfBillPrintContent" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              <tr>
                <td>
                  <Box
                    style={{
                      ...getSectionStyle(headerColor).headerStyle,
                      paddingTop: "0.2cm",
                      marginBottom: "0"
                    }}
                  >
                    {letterHeadComponent}
                  </Box>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box
                    style={{
                      ...getSectionStyle(headerColor).bodyStyle,
                      marginTop: "0",
                      paddingTop: "0"
                    }}
                  >
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="0.8cm"
                      width="100%"
                      justifyContent="center"
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Box
                          fontSize="0.45cm"
                          marginTop="0.5cm"
                          marginBottom="0.3cm"
                          fontWeight={400}
                        >
                          <Typography component="span" sx={{ textTransform: "uppercase" }}>
                            {billData.type === DocumentTypes.CREDITNOTE && tl("billing.creditNote")}{" "}
                          </Typography>
                          {billData.type !== DocumentTypes.CREDITNOTE && (
                            <Box sx={{ height: "0.25cm" }}>
                              <Typography sx={{ textTransform: "uppercase" }}>
                                {`${customTitle || getPrintTitle(billData.status, billData.type)} ${
                                  billData.status === BillStatus.cancelled
                                    ? t("billing.cancelled")
                                    : (!isForPreview &&
                                        Boolean(billData?.printCount) &&
                                        ` (COPY - ${billData?.printCount})`) ||
                                      ""
                                }`}
                              </Typography>
                              <Typography sx={{ textTransform: "uppercase", fontSize: "0.28cm" }}>
                                {customSubtitle}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="space-between"
                      borderTop="1px solid black"
                      p="0.1cm"
                    >
                      <Box component="div" width="50%">
                        {checkWalkInCustomer(billData.client, "isWalkInCustomer") && (
                          <BillClientInfoSmall
                            client={billData.client}
                            customerNumber={billData.customer?.customerNumber}
                            showDobOnPrint={showDobOnPrint}
                          />
                        )}
                        <Barcode
                          value={billId}
                          width={2}
                          displayValue={false}
                          fontSize={5}
                          height={20}
                          margin={2}
                          font="arial"
                        />
                      </Box>

                      <Box component="div" width="50%">
                        {billData.ipd && (
                          <InfoField
                            label={<>IPD No.</>}
                            data={<>{billData.ipd.ipdNumber || ""}</>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        {billData.document.bed?.name && (
                          <InfoField
                            label={<>Bed No.</>}
                            data={<>{billData.document.bed.name}</>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        <InfoField
                          label={
                            <>
                              {billData.type === DocumentTypes.CREDITNOTE
                                ? tl("billing.creditNoteNumber")
                                : tl("billnumber")}
                            </>
                          }
                          data={<>{billData.billNumber}</>}
                          labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                        />
                        {billData.type === DocumentTypes.CREDITNOTE && (
                          <InfoField
                            label={<>{tl("billing.creditNoteForBill")}</>}
                            data={<>{billData.related?.billNumber}</>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        <InfoField
                          label={<> {tl("billing.issueDate")}</>}
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                convertADtoBS(moment(billData.issueDate)).formatted
                              )}{" "}
                              {moment(billData.issueDate).format("h:mm a")}
                            </>
                          }
                          labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                        />
                        <InfoField
                          label={<>{tl("billing.dateAD")}</>}
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                moment(billData.issueDate).format("YYYY/MM/DD")
                              )}{" "}
                            </>
                          }
                          labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                        />
                        {billData.document.referredBy && (
                          <InfoField
                            label={<>{tl("booking.referredBy")}</>}
                            data={<>{billData.document.referredBy.toUpperCase()}</>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        {billData.document.extraReferrer && (
                          <InfoField
                            label="Extra Referrer"
                            data={<>{billData.document.extraReferrer.name.toUpperCase()}</>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        {billData.ipd?.doctorInCharge && (
                          <InfoField
                            label={<>Consulted by</>}
                            data={
                              <>
                                {`${billData.ipd.doctorInCharge?.firstName || ""} ${
                                  billData.ipd.doctorInCharge?.lastName || ""
                                }`}
                              </>
                            }
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        {billData.insuranceNumber && (
                          <InfoField
                            label={tl("billing.insuranceNumber")}
                            data={<>{billData.insuranceNumber} </>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        {billData.claimNumber && (
                          <InfoField
                            label={tl("billing.claimNumber")}
                            data={<>{billData.claimNumber} </>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}
                        {billData?.document?.ssf?.id && (
                          <InfoField
                            label={tl("billing.ssid")}
                            data={<>{billData.document.ssf.id}</>}
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}

                        {billData?.document?.ssf?.id && billData?.document?.ssf?.scheme?.value && (
                          <InfoField
                            label={tl("billing.scheme")}
                            data={
                              <>
                                {`${billData.document.ssf.scheme.label} (${billData.document.ssf.scheme.value})`}
                              </>
                            }
                            labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                          />
                        )}

                        {billData?.document?.ssf?.id &&
                          billData?.document?.ssf?.subProduct?.value && (
                            <InfoField
                              label={tl("billing.subScheme")}
                              data={
                                <>
                                  {`${billData.document.ssf.subProduct.label} (${billData.document.ssf.subProduct.value})`}
                                </>
                              }
                              labelStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                              valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
                            />
                          )}
                      </Box>
                    </Box>

                    {/* check the version and load bill content accordingly,
                    may use switch case if third version comes */}
                    {isOldFormatBill(billData.document.version) ? (
                      <DescribeBillV1 billData={billData} SPs={SPs} />
                    ) : (
                      <DescribeBillV2Half
                        hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                        clientBalance={clientBalance}
                        billData={billData}
                        SPs={SPs}
                        relatedSettings={{
                          showReferrer,
                          isBordered: false,
                          headerColor
                        }}
                        user={user}
                      />
                    )}
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showFooter && (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <Box>
                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          )}
        </div>
      </iframe>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { resourceCentres } = state.resources;
  const rcId = state.userContext.resourceCentreId;

  const getResourceCentre = (id, item) => item.find((rc) => rc.id === id);

  return {
    SPs: state.resources.resourceCentreServiceProviders,
    user: state.userContext.user,
    resourceCentre: getResourceCentre(rcId, resourceCentres) || state.userContext.resourceCentre
  };
};

PrintBill.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch) => ({
  sendPrintInfo: (id) => dispatch(registerPrint(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintBill);

import React from 'react';
import { Box, FormControl, Select, MenuItem } from '@mui/material';

const romanNumbers = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];

function RowWithDropDown(props) {
  const { value, onChange, optionValues, maxItems, children } = props;

  return (
    <Box display="flex" justifyContent="space-between">
      <FormControl margin="dense">
        <Select
          data-testmation="intraOral.selectDropdown"
          value={value}
          onChange={onChange}
          native
          disableUnderline
          style={{ minWidth: '100px' }}
        >
          <option value="" style={{ display: 'none' }}></option>
          {!optionValues
            ? romanNumbers.map((rn, index) => {
                if (index < maxItems) {
                  return (
                    <option data-testmation="intraOral.dropDownValue" key={rn} value={rn}>
                      Class {rn}
                    </option>
                  );
                }
              })
            : optionValues.map((option) => {
                return (
                  <option
                    data-testmation="intraOral.dropDownValue"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
        </Select>
      </FormControl>
      {children}
    </Box>
  );
}

export default RowWithDropDown;

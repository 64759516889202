import { Box } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

import Medication from "../../Assessment/Medication/Medication";

const IpdMedication = ({ ipdClient, medication, setMedication }) => {
  React.useEffect(() => {
    if (ipdClient?.prescribedMedications) {
      setMedication(ipdClient.prescribedMedications);
    }
  }, [ipdClient?.prescribedMedications]);

  return (
    <Box width="90%">
      <Medication
        hideDefaultLabel
        medication={medication}
        onChange={(v) => {
          setMedication(v);
        }}
      />
    </Box>
  );
};
export default connect((state, { id }) => {
  const ipdClient = state.ipd.collection.find((item) => Number(id) === item.id);
  return { ipdClient };
}, null)(IpdMedication);

import React from "react";

export const SyrupDepo = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 511.999 511.999"
    >
      <g>
        <g>
          <g>
            <path
              d="M354.076,133.07c-10.346,0-18.762-8.417-18.762-18.762V99.459c10.398-0.537,18.67-9.113,18.67-19.643v-60.12
				C353.984,8.818,345.165,0,334.288,0H177.712c-10.877,0-19.696,8.818-19.696,19.696v60.12c0,10.532,8.272,19.108,18.67,19.643
				v14.848c0,10.346-8.417,18.762-18.762,18.762c-36.485,0-66.166,29.683-66.166,66.166c0,15.381,0,284.063,0,293.068
				c0,10.877,8.818,19.696,19.696,19.696h289.09c10.877,0,19.696-8.818,19.696-19.696c0-8.879,0-278.183,0-293.068
				C420.242,162.752,390.559,133.07,354.076,133.07z M314.592,39.392V60.12H197.408V39.392H314.592z M380.85,472.608H131.151
				V258.542H380.85V472.608z M380.851,219.152h-249.7v-19.916c0-14.763,12.01-26.775,26.775-26.775
				c32.066,0,58.154-26.088,58.154-58.154V99.511h79.843v14.797c0,32.066,26.088,58.154,58.154,58.154
				c14.763,0,26.774,12.011,26.774,26.775V219.152z"
            />
            <path
              d="M206.794,385.27h29.511v29.511c0,10.877,8.818,19.696,19.696,19.696c10.877,0,19.696-8.818,19.696-19.696V385.27h29.511
				c10.877,0,19.696-8.818,19.696-19.696c0-10.877-8.818-19.696-19.696-19.696h-29.511v-29.51c0-10.877-8.818-19.696-19.696-19.696
				c-10.877,0-19.696,8.818-19.696,19.696v29.51h-29.511c-10.877,0-19.696,8.818-19.696,19.696
				C187.098,376.452,195.917,385.27,206.794,385.27z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

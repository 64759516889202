import * as moment from "moment";
import PhoneNumber from "awesome-phonenumber";
import { upperFirst, camelCase } from "lodash";
import { convertADtoBS } from "../components/Calendar/functions/calendarFunctions";
import {
  DefaultInternalUseCategory,
  ProductTypeInfo,
  SellableProductCategory
} from "../containers/Stock/StockCreateEdit/CreateStockProduct";
import { isNewPhoneNumber, isStartWith970 } from "./phoneNumber";

export function isISODateString(dateStr: string): RegExpMatchArray {
  return moment(dateStr, moment.ISO_8601).isValid() && (dateStr || "").match(/^\d{4}-\d{2}-\d{2}/);
}

export function isValidEmail(value: string): boolean {
  const isValid = // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );
  return isValid;
}

interface ValidatorMessage {
  msg: string;
  splitter?: string;
}

interface ValidatorReturn {
  (data): boolean;
  msg: string;
  isValid?: boolean;
}

enum TransactionTypes {
  Debit = "debit",
  Credit = "credit"
}

export function isNotEmptyArr({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => data.length > 0;
  validator.msg = msg;
  return validator;
}

export function isGeolocation({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => {
    if (!data) {
      return false;
    }
    const isValidInput = data.length === 2;
    const hasValidFormat = data.every((item) => !Number.isNaN(item) || item?.length === 0);
    return isValidInput && hasValidFormat;
  };
  validator.msg = msg;
  return validator;
}

export function isRequired({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => !!data;
  validator.msg = msg;
  return validator;
}

export function isSellableOrInternalCategory({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) =>
    Object.values(SellableProductCategory)
      .map((val) => val.toLowerCase())
      .includes(data?.toLowerCase() as SellableProductCategory) ||
    Object.values(DefaultInternalUseCategory)
      .map((val) => val.toLowerCase())
      .includes(data?.toLowerCase() as DefaultInternalUseCategory);

  validator.msg = msg;
  return validator;
}

export function productTypeValidator({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) =>
    Object.values(ProductTypeInfo).includes(upperFirst(camelCase(data)) as ProductTypeInfo);
  validator.msg = msg;
  return validator;
}

export function transactionTypeValidator({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => Object.values(TransactionTypes).includes(data?.toLowerCase());
  validator.msg = msg;
  return validator;
}

export function isMaritalStatus({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) =>
    ["single", "married", "separated", "divorced", "widowed"].includes(data?.toLowerCase());
  validator.msg = msg;
  return validator;
}

export function isValidProductType({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => ["package", "single"].includes(data?.toLowerCase());
  validator.msg = msg;
  return validator;
}

export function hasLength({
  gt,
  lt,
  msg
}: {
  gt: number;
  lt: number;
  msg: string;
}): ValidatorReturn {
  const validator = (data) => {
    let valid = false;
    if (gt) valid = data?.length > gt;
    if (lt) valid = valid && data?.length <= lt;
    return valid;
  };
  validator.msg = msg;
  return validator;
}

export function isNumber({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => !Number.isNaN(data) && data?.match(/\d+/);
  validator.msg = msg;
  return validator;
}

export function qtyValidator({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => !Number.isNaN(data || 0) && (data || "0")?.match(/\d+/);
  validator.msg = msg;
  return validator;
}

export function isPhoneNumber({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => {
    if (!data) return true;
    return isNumber({ msg: "" })(data) && data.length >= 6 && data.length <= 15;
  };
  validator.msg = msg;
  return validator;
}

export function isValidPhoneNumber({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (number = "") => {
    if (number === null) return false;
    const ph = new PhoneNumber(number);
    return ph.isValid();
  };
  validator.msg = msg;
  return validator;
}

export function isValidMobileNumber({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (number = "") => {
    if (number === null) return false;
    const ph = new PhoneNumber(number);
    const phoneNumber = ph.getNumber("significant");
    if (ph.getCountryCode() === 977 && isStartWith970(number)) {
      return isNewPhoneNumber(phoneNumber);
    }
    return ph.isValid() && ph.isMobile();
  };
  validator.msg = msg;
  return validator;
}

export function isNepaliPhoneNumber({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (number = "") => {
    if (number === null) return false;
    // eslint-disable-next-line no-param-reassign
    number = number.toLowerCase();
    if (number.search("[a-z]") !== -1) return false;
    const re = new RegExp("^(98|97|96)");
    return (
      (re.test(number) && number.length === 10) ||
      (number.length === 9 && number.startsWith("0") && number[1] !== "0")
    );
  };
  validator.msg = msg;
  return validator;
}

export function isEmail({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) =>
    data?.match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  validator.msg = msg;
  return validator;
}

export function CSVSexValidator({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => {
    if (!data) return false;
    if ([1, 2, 3].includes(data)) return true;
    return false;
  };
  validator.msg = msg;
  return validator;
}

export function isADDateString({ msg }: ValidatorMessage): {
  (dateString: unknown): boolean | RegExpMatchArray;
  msg: string;
} {
  const validator = (dateString) => {
    try {
      if (!dateString) return true;
      return isISODateString(dateString);
    } catch (e) {
      return false;
    }
  };
  validator.msg = msg;
  return validator;
}

export function isValidPalika({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => {
    if (!data) return false;
    if (data >= 0 && data <= 999) {
      return true;
    }
    return false;
  };
  validator.msg = msg;
  return validator;
}

export function isBSDate({ msg, splitter = "-" }: ValidatorMessage): ValidatorReturn {
  const validator = (dateString, acceptFutureDate = false) => {
    try {
      if (!dateString) return true;
      let [year, month, date] = dateString.split(splitter);
      year = Number(year);
      month = Number(month);
      date = Number(date);
      const [curYear] = convertADtoBS(new Date()).formatted4.split("-");
      return (
        year >= curYear - 200 &&
        (year <= curYear || acceptFutureDate) &&
        month > 0 &&
        month < 13 &&
        date > 0 &&
        date < 33
      );
    } catch (e) {
      return false;
    }
  };
  validator.msg = msg;
  return validator;
}

export function isADDate({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (dateString, acceptFutureDate = false) => {
    try {
      if (!dateString) return true;
      let [year, month, date] = dateString.split("-");
      year = Number(year);
      month = Number(month);
      date = Number(date);
      const curYear = new Date().getFullYear();
      return (
        year >= curYear - 200 &&
        (year <= curYear || acceptFutureDate) &&
        month > 0 &&
        month < 13 &&
        date > 0 &&
        date < 33
      );
    } catch (e) {
      return false;
    }
  };
  validator.msg = msg;
  return validator;
}

export function isDateFormate({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => data.match(/^\d{4}-\d{2}-\d{2}$/);
  validator.msg = msg;
  return validator;
}

export function validate(
  data: unknown,
  validators: Array<ValidatorReturn>
): { isValid: boolean; messages: Array<string> } {
  const messages = [];
  const isValid = validators.reduce((aggr, validator) => {
    const valid = validator(data);
    if (!valid) messages.push(validator.msg);
    return aggr && valid;
  }, true);
  return { isValid, messages };
}

export function validateBoolean({ msg }: ValidatorMessage): ValidatorReturn {
  const validator = (data) => ["true", "false"].includes(data?.toLowerCase());
  validator.msg = msg;
  return validator;
}

import {
  BillDocumentType,
  BillType,
  BillTypeByUUID,
  Receipt,
  TrackingNumInterface
} from "../interfaces/BillInterfaces";
import { Stock } from "../interfaces/StockInterfaces";
import { Delete, Get, Patch, Post, Put } from "./apiHelper";

const billingRoot = "/api/bills";

export async function getBills(query?: Record<string, string | number>): Promise<BillType[]> {
  const response = await Get(`${billingRoot}`, true, { ...query });
  return response.data as BillType[];
}

export async function getBill(
  billId: number,
  isForKitchenPharmacy: boolean = false
): Promise<BillType> {
  const response = await Get(
    `${billingRoot}/${billId}?${isForKitchenPharmacy ? `isForKitchenPharmacy=${true}` : ""}`,
    true
  );
  return response.data as BillType[];
}

export async function getBillByUUID(uuid: string): Promise<BillTypeByUUID | null> {
  const response = await Get(`${billingRoot}/byUUID/${uuid}`, false);
  return response.data as BillTypeByUUID;
}

export async function getBillByNumber(billNumber: string): Promise<BillType> {
  const response = await Get(`${billingRoot}/byNumber/${billNumber}`, true);
  return response.data as BillType;
}

export async function getNextBillNumber(
  type = "billing",
  date?: string | Date
): Promise<TrackingNumInterface> {
  const response = await Get(`/api/trackingNumber/${type}`, true, { date });
  return response.data as TrackingNumInterface;
}

// eslint-disable-next-line max-len
export async function finaliseBill(billId: number, isForCancellingBill = false): Promise<unknown> {
  const response = await Post(`${billingRoot}/draft/${billId}/finalise`, { finalise: true }, true, {
    // This flag is used to differentiate between finalising a bill in the normal case or while
    // cancelling the bill. When true, it indicates that this finalisation is part of the bill
    // cancellation process. Server needs this flag to handle the finalisation such that the bill
    // cancellation process doesnot create extra entries in client ledger and payment records table.
    isForCancellingBill
  });
  return response.data as unknown;
}

export async function postDraft(draft: BillType): Promise<BillType> {
  const response = await Post(`${billingRoot}/draft`, draft, true);
  return response.data as BillType;
}

export async function putDraft(draftId: number, draft: BillType): Promise<BillType> {
  const response = await Put(`${billingRoot}/draft/${draftId}`, draft, true);
  return response.data as BillType;
}

export async function updatePaymentInfo(
  billId: number,
  paymentInfo: Record<string, unknown>
): Promise<unknown> {
  const response = await Patch(`${billingRoot}/${billId}/updatePaymentInfo`, paymentInfo, true);
  return response.data as unknown;
}

export async function getClientBalance(
  clientId: number
): Promise<{ balanceAmount: number; balance: number }> {
  const response = await Get(`${billingRoot}/clientBalance/${clientId}`, true);
  return response.data as { balanceAmount: number; balance: number };
}

export async function getDueBillsForRecordPayment(
  clientId: string
): Promise<{ bills: Array<BillDocumentType & { id: number }>; balance: number }> {
  const response = await Get(`${billingRoot}/recordPayment`, true, { clientId });
  return response.data as { bills: Array<BillDocumentType & { id: number }>; balance: number };
}

export async function recordPayment(trxData: Record<string, unknown>): Promise<unknown> {
  const response = await Patch(`${billingRoot}/recordPayment`, trxData, true);
  return response.data as unknown;
}

export async function cancelBill(billId: number, creditNoteData: BillType): Promise<BillType> {
  const response = await Post(`${billingRoot}/${billId}/cancel`, creditNoteData, true);
  return response.data as BillType;
}

export async function registerPrint(billId: number): Promise<unknown> {
  const response = await Post(`${billingRoot}/${billId}/registerPrint`, { addCount: 1 }, true);
  return response.data as unknown;
}

export async function deleteDraft(billId: number): Promise<unknown> {
  const response = await Delete(`${billingRoot}/draft/${billId}`, true);
  return response.data as unknown;
}

export async function receiptCancel(data: Receipt): Promise<BillDocumentType> {
  const response = await Post(`${billingRoot}/receiptCancel/${data.billId}`, data, true);
  return response?.data as BillDocumentType;
}

export const getStockAndServiceByIds = async (
  productIds: number[],
  serviceIds: number[]
): Promise<Stock[]> => {
  const response = await Post(`/api/serviceAndProduct`, { productIds, serviceIds }, true);
  return response?.data as Promise<Stock[]>;
};

export const getBillFromPurchaseTransaction = async (trxId: number): Promise<BillDocumentType> => {
  const response = await Get(`/api/stockTransactionBill/${trxId}`, true);
  return response.data as BillDocumentType;
};

export const getDraftAssessmentBills = async (): Promise<Array<BillType>> => {
  const response = await Get(`/api/assessmentBills`, true);
  return response.data as Array<BillType>;
};

export const getCreditableQuantities = async (
  billId: number
): Promise<{ [itemId: number]: number }> => {
  const response = await Get(`${billingRoot}/creditableQuantity/${billId}`, true);
  return response.data as { [itemId: number]: number };
};

import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/reminders";
import { IThunkDispatch } from "../store";
import { MarkReadParams } from "../api/reminders";

export enum Type {
  GET_REMINDERS = "GET_REMINDERS",
  REMINDER_MARK_READ = "REMINDER_MARK_READ"
}

export const getReminders =
  () =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_REMINDERS, async () => {
        let reminders;
        await api
          .getReminders()
          .then((result) => {
            reminders = result;
          })
          .catch(() => {
            reminders = [];
          });
        return reminders;
      })
    );
  };

export const markReminderRead =
  ({ id, done }: MarkReadParams) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.REMINDER_MARK_READ, async () => api.markReminderRead({ id, done }))
    );
    dispatch(getReminders());
  };

export const markAllReminderRead =
  (ids: number[]) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(actionCreatorAsync(Type.REMINDER_MARK_READ, async () => api.markAllAsRead(ids)));
    dispatch(getReminders());
  };

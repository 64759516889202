import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { deleteUserBranch, getUserBranches, postUserBranches } from "../api/resourceCentre";
import { ResourceCentre } from "../interfaces/ResourceCentreInterface";
import { notificationAdd } from "../actions/notification";

export const getUserAccesingRcs = createAsyncThunk(
  "userBranch/getUserBranches",
  async (id?: number) => {
    const response = await getUserBranches(id);
    return response;
  }
);

export const assignRcToUser = createAsyncThunk(
  "userBranch/postUserBranches",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await postUserBranches(data);
      return {
        userBranchId: response.id,
        name: data.name,
        id: data.resourceCentreId
      };
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message || "Failed to assign branch to user",
          autoTimeout: true
        })
      );
      return rejectWithValue(e);
    }
  }
);

export const unassignRcForUser = createAsyncThunk(
  "userBranch/delete",
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await deleteUserBranch(id);
      return id;
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message || "Failed to unassign user branch",
          autoTimeout: true
        })
      );
      return rejectWithValue(e);
    }
  }
);
interface State {
  collection: Array<ResourceCentre>;
  selectedBranchForDashboard: number | null;
}

const initialState: State = {
  collection: [],
  selectedBranchForDashboard: null
};

const userBranchSlice = createSlice({
  name: "userBranch",
  initialState,
  reducers: {
    updateSelectedBranchForDashboard: (draft, { payload }) => {
      draft.selectedBranchForDashboard = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAccesingRcs.fulfilled, (draft, { payload }) => {
      draft.collection = payload;
    });

    builder.addCase(assignRcToUser.fulfilled, (draft, { payload }) => {
      draft.collection.push(payload);
      return draft;
    });

    builder.addCase(unassignRcForUser.fulfilled, (draft, { payload }) => {
      draft.collection = draft.collection.filter((branch) => branch.userBranchId !== payload);
      return draft;
    });
  }
});

export const { updateSelectedBranchForDashboard } = userBranchSlice.actions;
export default userBranchSlice.reducer;

import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { AssociateCompany } from "../../api/associateCompanies";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";

interface Props {
  data: AssociateCompany;
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

function CompanyShow({ data, onClose, onEdit, onDelete }: Props): JSX.Element {
  const editButton = <EditIcon style={{ cursor: "pointer" }} onClick={onEdit} />;
  const deleteButton = <DeleteIcon style={{ cursor: "pointer" }} onClick={onDelete} />;

  return (
    <Panel
      onClose={onClose}
      editButton={editButton}
      deleteButton={deleteButton}
      title={tl("companyDetails")}
    >
      <Box px={4} pb={4} pt={2}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1">{tl("createdDate")}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="body1">{moment(data.created_at).format("YYYY-MM-DD")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">{tl("name")}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="body1">{data.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">{tl("DiscountRate")}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="body1">{data.discountRate}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
}

export default CompanyShow;

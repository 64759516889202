import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { RootState } from "../store/index";
import { AddressOptions } from "./query/useAddressOption";
import { getAddressOptions } from "../actions/addressOptions";

const useAddressOptions = (): AddressOptions[] => {
  const dispatch = useDispatch();
  const addressOptions: AddressOptions[] = useSelector(
    (state: RootState) => state.addressOptions.collection
  );
  const hasAddressOptions = addressOptions.length > 0;
  React.useEffect(() => {
    if (!hasAddressOptions) {
      dispatch(getAddressOptions());
    }
  }, [hasAddressOptions, dispatch]);
  return addressOptions;
};
export default useAddressOptions;

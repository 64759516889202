import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { round } from "mathjs";
import { useDispatch, useSelector } from "react-redux";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import Filters from "./Filters";
import { getStockAuditReports } from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { downloadExcel } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import { tl, t } from "../../components/translate";
import { getStyleBasedOnExpiryDate } from "../Stock/StockList";
import Can from "../Policy/Can";
import { ProductTypeInfo } from "../../interfaces/ProductInterface";
import { RootState } from "../../store";
import { supplierActions } from "../../actions";
import { Stock, Supplier } from "../../interfaces/StockInterfaces";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import { notificationAdd } from "../../actions/notification";
import useQueryParams from "../../hooks/useQueryParams";
import { ProductType } from "../Stock/StockCreateEdit/CreateStockProduct";
import {
  getDefaultKeyValuesColumns,
  stockAuditDefaultCols,
  stockAuditHiddenCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import { BranchReportProps } from "./SalesByServicesReport";
import { or } from "./ReceiptReport";
import { getResourceCentreName } from "./DueReport";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const docColumns = (isBranchReport) => [
  ...(isBranchReport ? [t("reports.resourceCentreName")] : []),
  t("reports.stock.productName"),
  t("stock.genericName"),
  t("reports.productId"),
  t("reports.productType"),
  t("reports.unit"),
  t("reports.package"),
  t("reports.unitsPerPackage"),
  t("reports.category"),
  t("reports.batchId"),
  t("reports.expiryDate"),
  t("reports.minStockQty"),
  t("reports.avgPurchasePrice"),
  t("reports.totalPurchasedQty"),
  t("reports.availableStock"),
  t("reports.totalStockValue"),
  t("reports.totalSellingPrice"),
  t("product.manufactureBy"),
  t("reports.supplier")
];
interface DocRowProcessorInterface {
  productName: string;
  productId: number;
  productType: number;
  unit: string;
  package: string;
  unitsPerPackage: number;
  category: string;
  batchId: string;
  expiryDate: Date;
  minStockQuantity: number;
  avgPurchasePrice: number;
  totalPurchasedQuantity: number;
  quantity: number;
  perUnitSellingPrice: number;
  manufactureBy: string;
  genericName: string;
  supplierName: string;
  // eslint-disable-next-line camelcase
  __meta__row_type: string;
}

const makeDocRowProcessor =
  (isBranchReport) =>
  ({
    productName,
    productId,
    productType,
    unit,
    package: pckg,
    unitsPerPackage,
    category,
    batchId,
    expiryDate,
    minStockQuantity,
    avgPurchasePrice,
    totalPurchasedQuantity,
    quantity,
    perUnitSellingPrice,
    manufactureBy,
    supplierName,
    genericName,
    resourceCentreName,
    // eslint-disable-next-line camelcase
    __meta__row_type
  }: DocRowProcessorInterface) => {
    // eslint-disable-next-line camelcase
    if (__meta__row_type === "segment_summary") return;
    const productTyp = productType === 1 ? ProductTypeInfo.Sellable : ProductTypeInfo.InternalUse;
    // eslint-disable-next-line consistent-return
    return [
      ...(isBranchReport ? [resourceCentreName] : []),
      productName,
      genericName,
      productId,
      productTyp,
      unit,
      pckg,
      unitsPerPackage,
      category,
      batchId,
      moment(expiryDate).format("L"),
      minStockQuantity,
      avgPurchasePrice,
      totalPurchasedQuantity,
      quantity,
      avgPurchasePrice * quantity,
      perUnitSellingPrice * quantity,
      manufactureBy,
      supplierName
    ];
  };

const addSupplierName = (data: Stock[], suppliers: Supplier[]): Stock[] =>
  data.map((item: Stock) => ({
    ...item,
    supplierName: suppliers.find((supplier) => supplier.id === item.supplierId)?.name || ""
  }));

const StockAuditReport = ({ isBranchReport = false }: BranchReportProps): JSX.Element => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentre?.id);
  const query = useQueryParams();
  const filterParam = query.get("filter");

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    includeBelowZero: false,
    stockProductCategory: filterParam === "totalStockValuation" ? "" : "Medicine",
    onlyExpired: false,
    onlyBelowMinStock: filterParam === "showOnlyMinStocks",
    stockExpiringIn: filterParam === "showExpiring" ? 90 : "",
    productType: ProductType.Sellable,
    ...(isBranchReport
      ? { resourceCentreIds: resourceCentreId ? [resourceCentreId] : [] }
      : { productItems: [] })
  });
  const applyFilters = (filter) => {
    setFilters(filter);
  };
  const resourceCentres = useSelector((state: RootState) => state.userBranch.collection) || [];
  useEffect(() => {
    (async () => {
      const responseData = await getStockAuditReports({
        ...filters,
        productCategory: filters.stockProductCategory
      });
      setData(
        responseData.map((item) => ({
          ...item,
          genericName: item.structuredInfo?.genericName || "",
          brandName: item.structuredInfo?.brandName || "",
          resourceCentreName: getResourceCentreName(resourceCentres, item.resourceCentreId)
        }))
      );
    })();
  }, [filters, resourceCentres]);
  const suppliers = useSelector((state: RootState) => state.resources.suppliers);

  const stockData = React.useMemo(() => addSupplierName(data, suppliers), [data, suppliers]);

  React.useEffect(() => {
    (async () => {
      dispatch(supplierActions.getSuppliers(rcId));
    })();
  }, [dispatch, rcId]);

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(
      [...stockAuditDefaultCols, ...(isBranchReport ? ["resourceCentre"] : [])],
      stockAuditHiddenCols
    )
  );
  const [open, setOpen] = React.useState(false);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:stockAuditReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1200px" : "auto"}>
          <Box m="32px">
            <Filters
              filters={filters}
              onSetFilters={(filter) => applyFilters(filter)}
              showPeriod={false}
            />
          </Box>
          <Box className="stockAuditReportList">
            <List
              automation="stockAuditList"
              data={stockData}
              rowHeight={50}
              activeRow={0}
              adjustHeightToContents
              defaultSortColumn="productName"
              defaultSortOrder={-1}
              columns={[
                ...(isBranchReport
                  ? [
                      {
                        key: "resourceCentre",
                        label: "Branch",
                        formatter: ({ resourceCentreName }) => (
                          <Typography>{resourceCentreName}</Typography>
                        ),
                        segmentable: true,
                        segmentBy: ({ resourceCentreName }) => resourceCentreName,
                        sortable: true,
                        sortFunction: (a, b) =>
                          or(a.resourceCentreName).toLowerCase() >
                          or(b.resourceCentreName).toLowerCase()
                            ? 1
                            : -1
                      }
                    ]
                  : []),
                {
                  key: "productName",
                  label: tl("reports.stock.productName"),
                  ...(isBranchReport
                    ? {}
                    : { segmentable: true, segmentBy: ({ productName }) => productName }),
                  sortable: true,
                  formatter: ({ productName }) => <Typography>{productName}</Typography>
                },
                {
                  key: "batchId",
                  label: tl("reports.batchId"),
                  sortable: true,
                  formatter: ({ batchId }) => <Typography>{batchId}</Typography>
                },
                {
                  key: "supplierName",
                  label: tl("reports.supplier"),
                  sortable: false,
                  formatter: ({ supplierName }) => (
                    <Typography
                      sx={{
                        marginRight: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {supplierName}
                    </Typography>
                  )
                },
                {
                  key: "expiryDate",
                  label: tl("reports.expiryDate"),
                  sortable: true,
                  formatter: ({ expiryDate }) => (
                    <Typography style={getStyleBasedOnExpiryDate(expiryDate)}>
                      {moment(expiryDate).format("L")}
                    </Typography>
                  )
                },
                {
                  key: "minimumStockQuantity",
                  label: tl("reports.minStockQty"),
                  sortable: true,
                  formatter: ({ minStockQuantity }) => <Typography>{minStockQuantity}</Typography>
                },
                {
                  key: "avgPurchasePrice",
                  label: tl("reports.avgPurchasePrice"),
                  sortable: true,
                  formatter: ({ avgPurchasePrice }) => (
                    <Typography>{rupeeDisplay(round(avgPurchasePrice || 0, 0))}</Typography>
                  )
                },
                {
                  key: "totalPurchasedQuantity",
                  label: tl("reports.totalPurchasedQty"),
                  sortable: true,
                  formatter: ({ totalPurchasedQuantity }) => (
                    <Typography>{totalPurchasedQuantity}</Typography>
                  )
                },
                {
                  key: "quantity",
                  label: tl("reports.availableStock"),
                  sortable: true,
                  formatter: ({ quantity }) => <Typography>{quantity}</Typography>
                },
                {
                  key: "avgTotalPurchasePrice",
                  label: tl("reports.totalStockValue"),
                  sortable: true,
                  formatter: ({ avgPurchasePrice, quantity }) => (
                    <Typography>{Math.floor(avgPurchasePrice * quantity)}</Typography>
                  )
                },
                {
                  key: "genericName",
                  label: "Generic Name",
                  formatter: ({ genericName }) => <Typography>{genericName}</Typography>
                },
                {
                  key: "productType",
                  label: "Product Type",
                  formatter: ({ productType }) => (
                    <Typography>
                      {productType === 1 ? ProductTypeInfo.Sellable : ProductTypeInfo.InternalUse}
                    </Typography>
                  )
                },
                {
                  key: "unit",
                  label: "Unit",
                  sortable: true,
                  formatter: ({ unit }) => <Typography>{unit}</Typography>
                },
                {
                  key: "package",
                  label: "Package",
                  formatter: (row) => <Typography>{row.package}</Typography>
                },
                {
                  key: "unitsPerPackage",
                  label: "Units Per Package",
                  formatter: ({ unitsPerPackage }) => <Typography>{unitsPerPackage}</Typography>
                },
                {
                  key: "category",
                  label: "Category",
                  formatter: ({ category }) => <Typography>{category}</Typography>
                },
                {
                  key: "manufactureBy",
                  label: "Manufacture By",
                  sortable: true,
                  formatter: ({ manufactureBy }) => <Typography>{manufactureBy}</Typography>
                }
              ].filter((col) => listColumns[col.key])}
              segementSummaryRenderer={(acc) => (
                <Box
                  style={{ background: "#e6e6e6" }}
                  display="flex"
                  flexGrow={1}
                  paddingRight="30px"
                >
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight={500}
                      >
                        {acc.segment || "N/A"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box flexBasis="200px" pt="4px" textAlign="right">
                    <Typography>
                      {rupeeDisplay(
                        Math.floor(
                          acc.rows.reduce(
                            (prev, cur) => prev + cur.avgPurchasePrice * cur.quantity,
                            0
                          ) || 0
                        )
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            t("reports.stockAuditReport", "en"),
                            formatHeaderForExcel(docColumns(isBranchReport)),
                            formatDataForExcel(
                              getProcessedData(),
                              makeDocRowProcessor(isBranchReport)
                            )
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["productName"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="192px" paddingRight="26px" justifyContent="flex-end">
              <Typography>
                {rupeeDisplay(
                  data
                    .reduce((acc, cur) => {
                      if (!cur.avgPurchasePrice || !cur.quantity) return acc;
                      return acc + Number(cur.avgPurchasePrice * cur.quantity);
                    }, 0)
                    .toFixed(2) || 0
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Can>
  );
};

export default StockAuditReport;

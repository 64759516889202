import { MedicineInterface } from "../interfaces/ProductInterface";
import { Get, Post } from "./apiHelper";

const medicinesRoot = "/api/medicines";

export async function getMedicineSearch(search: string): Promise<Array<MedicineInterface>> {
  const servicesRoot = `${medicinesRoot}/search?q=${search}`;
  const response = await Get(`${servicesRoot}`, true);
  return response.data as Array<MedicineInterface>;
}

export async function addNewMedicine(data: Record<string, unknown>): Promise<unknown> {
  const response = await Post(`${medicinesRoot}/new`, data);
  return response.data;
}

export async function getGenericMedicineSearch(search: string): Promise<Array<{ name: string }>> {
  const servicesRoot = `${medicinesRoot}/generic/search?q=${search}`;
  const response = await Get(`${servicesRoot}`, true);
  return response.data as Array<{ name: string }>;
}

import { Get, Post, Delete, Put } from './apiHelper';

const campaignRoot = '/api/campaign';

export async function getCampaignDetails(id, next) {
  const response = await Get(`${campaignRoot}/${id}`, true);
  if (response) {
    if (next) next(response.data);
    return response.data;
  }
}

export async function getBatchCampaign() {
  const response = await Get(`${campaignRoot}`, true);
  if (response) return response.data;
}

export async function createCampaign(campaignData) {
  const response = await Post(`${campaignRoot}`, campaignData, true);
  return response.data;
}

export async function updateCampaign(id, campaignData) {
  const response = await Put(`${campaignRoot}/${id}`, campaignData, true);
  return response.data;
}

export const deleteCampaign = async (id) => {
  const response = await Delete(`${campaignRoot}/${id}`, true);
  return response.data;
};

export const sendMessage = async (messageData) => {
  const response = await Post(`/api/sendMessage`, messageData, true);
  return response.data;
};

import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { separateByComma } from "../../../../helpers/formatters";
import { RootState } from "../../../../store";
import InfoField, { Size } from "./InfoField";

export default function PrintHeader(): JSX.Element {
  const { resourceCentres } = useSelector((state: RootState) => state.resources);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const resourceCentre = resourceCentres.find((rc) => rc.id === rcId);

  const { name, slogan, address, city, registrationNumber, mobileNumber, phone, email, settings } =
    resourceCentre;
  const { panNo } = settings?.billingSettings || {};
  return (
    <Box mt={1} p="0cm 0.5cm">
      <InfoField size={Size.LARGE} value={name} valueStyle={{ fontWeight: "medium" }} />
      {slogan && <InfoField value={slogan} />}
      <InfoField value={separateByComma(mobileNumber, phone)} />
      <InfoField value={separateByComma(address, city)} />
      <InfoField value={email} />
      {panNo && <InfoField labelStyle={{ width: "0.7cm" }} label="Pan No: " value={panNo} />}
      {registrationNumber && (
        <InfoField labelStyle={{ width: "0.7cm" }} label="Reg No: " value={registrationNumber} />
      )}
    </Box>
  );
}

import React from "react";
import { TextField, MenuItem } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const discount = {
  percent: {
    label: "%",
    value: "percent"
  },
  amount: {
    label: "Rs.",
    value: "amount"
  }
};

interface DiscountPropsType {
  onChange: (e) => void;
  fieldValue: string;
  disabled?: boolean;
}
const DiscountTypeSelect = ({
  onChange,
  fieldValue,
  disabled = false
}: DiscountPropsType): JSX.Element => (
  <TextField
    disabled={disabled}
    select
    InputProps={{
      disableUnderline: true,
      style: { color: "#009654", backgroundColor: "transparent" }
    }}
    SelectProps={{
      IconComponent: (props) => (
        <KeyboardArrowDownOutlinedIcon
          style={{ marginLeft: "-10px", cursor: "pointer" }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )
    }}
    value={discount[fieldValue]?.value}
    onChange={(e) => onChange(e.target.value)}
  >
    {Object.values(discount).map(({ label, value }) => (
      <MenuItem value={value} key={value}>
        {label}
      </MenuItem>
    ))}
  </TextField>
);

DiscountTypeSelect.defaultProps = {
  disabled: false
};

export default DiscountTypeSelect;

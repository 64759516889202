import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Alert
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import produce from "immer";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { t, tl } from "../../../components/translate";
import { AudienceData } from "../Audience/AudienceHelpers";
import { audiencesSelector } from "../../../reducers/audience";
import * as audienceActions from "../../../actions/audience";
import * as templateActions from "../../../actions/template";
import * as serviceActions from "../../../actions/services";
import * as campaignActions from "../../../actions/campaigns";
import { keywords, SmsTemplateBox } from "../MessageTemplate/SmsTemplateBox";
import { CampaignData, campaignType, triggerType } from "./CampaignHelpers";
import { TemplateData } from "../MessageTemplate/TemplateHelpers";
import { templatesSelector } from "../../../reducers/template";
import { servicesSortedSelector } from "../../../reducers/services";
import {
  proseMirrorOptions,
  serializeContent,
  RichTextfield,
  deserializeNode
} from "../../../components/RichTextfield";
import useProseMirror from "../../../components/RichTextfield/useProsemirror";
import { IThunkDispatch, RootState } from "../../../store";

interface CampaignCreateEditProps {
  mode: string;
  onCancel: () => any;
  actions: any;
  audienceList: AudienceData[];
  templateList: TemplateData[];
  servicesList: any;
  campaign: CampaignData;
}

const mediaTypeList = [
  { label: "SMS", value: "sms" },
  { label: "Email", value: "email" }
];

const CampaignCreateEdit: React.FC<CampaignCreateEditProps> = ({
  onCancel,
  mode,
  actions,
  audienceList,
  templateList,
  servicesList,
  campaign
}) => {
  const [saving, setSaving] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState<TemplateData>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [saveAttempted, setSaveAttempted] = React.useState(null);
  const [selectedAudience, setSelectedAudience] = React.useState<AudienceData>();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [formData, setFormData] = React.useState<CampaignData>(
    campaign || {
      name: "",
      mediaType: "sms",
      message: "",
      activated: true,
      type: campaignType.INSTANTANEOUS
    }
  );
  const [state, setState] = useProseMirror({
    ...proseMirrorOptions,
    doc: serializeContent(formData.mediaType === "email" ? formData.message : "")
  });

  React.useEffect(() => {
    if (selectedTemplate) {
      const data: CampaignData = cloneDeep(formData);
      data.mediaType = selectedTemplate.mediaType;
      data.message = selectedTemplate.message;
      setFormData(data);
      if (selectedTemplate.mediaType === "email") {
        const { tr } = state;
        tr.replaceWith(
          tr.selection.from,
          tr.selection.to,
          serializeContent(selectedTemplate.message)
        );
        // tr.insertText(selectedTemplate.message);
        // tr.doc = serializeContent(selectedTemplate.message); // Replaces selection with 'hello'
        setState(state.apply(tr));
      }
    }
  }, [selectedTemplate]);

  React.useEffect(() => {
    if (!audienceList || !audienceList.length) actions.loadAudience();
    if (!templateList || !templateList.length) actions.loadTemplate();
    if (!servicesList || !servicesList.length) actions.loadServices();
  }, []);

  return (
    <Panel
      onClose={onCancel}
      title={mode === "edit" ? tl("campaign.updateCampaign") : tl("campaign.createCampaign")}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              data-testmation="campaignCancel"
              onClick={onCancel}
              style={{ marginRight: "32px" }}
            >
              <Typography>{tl("client.cancel")}</Typography>
            </Button>
            <StatefulButton
              data-testmation="campaignSave"
              variant="contained"
              color="primary"
              disabled={saving}
              onClick={async () => {
                if (formData.mediaType === "email") {
                  formData.message = deserializeNode(state.doc);
                }
                if (!formData.audienceId || !formData.name || !formData.message) {
                  setSaveAttempted(true);
                } else {
                  setSaving(true);
                  await actions.onSave(formData).then(() => {
                    setSaving(false);
                    actions.navigateTo("/campaign/campaigns");
                  });
                }
              }}
              isLoading={saving}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{tl("client.save")}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      }
    >
      <Box pl={5} pr={5} className="templateForm">
        <TextField
          data-testmation="name"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder="Name"
          margin="dense"
          fullWidth
          label={tl("campaign.name")}
          required
          error={!formData.name && saveAttempted}
          value={formData.name}
          onChange={(e) => {
            const data: CampaignData = cloneDeep(formData);
            data.name = e.target.value;
            setFormData(data);
          }}
        />

        <TextField
          data-testmation="audience"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder="Audience"
          select
          margin="dense"
          label={tl("campaign.audience")}
          fullWidth
          error={!formData.audienceId && saveAttempted}
          required
          value={formData.audienceId || ""}
          onChange={(e) => {
            const data = produce(formData, (draft) => {
              draft.audienceId = Number(e.target.value);
            });
            setFormData(data);
            setSelectedAudience(() => audienceList?.find((item) => item.id === data.audienceId));
          }}
        >
          {audienceList.map((audience) => (
            <MenuItem key={audience.id} value={audience.id} id={`unqxsmi-${audience.id}`}>
              {audience.name}
            </MenuItem>
          ))}
        </TextField>
        {selectedAudience?.isBatch && !selectedAudience?.isReady && (
          <Alert severity="warning">
            Audience data will be available next day. Messages wont be sent right away.
          </Alert>
        )}

        <Box
          sx={{
            display: "flex",
            mt: 1,
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder="Type"
            select
            margin="dense"
            label={tl("campaign.type")}
            style={{ flexBasis: "200px", marginRight: "10px" }}
            value={formData.mediaType || ""}
            onChange={(e) => {
              const data: CampaignData = cloneDeep(formData);
              data.mediaType = e.target.value;
              setFormData(data);
            }}
          >
            {mediaTypeList.map((media) => (
              <MenuItem key={media.value} value={media.value} id={`unqxsmi-${media.value}`}>
                {media.label}
              </MenuItem>
            ))}
          </TextField>

          <Box
            data-testmation="loadTemplate"
            onClick={() => {
              setDialogOpen(true);
            }}
            alignSelf="center"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link>{tl("campaign.loadExixtingTemplate")}</Link>
          </Box>
          <Dialog disableEscapeKeyDown open={dialogOpen} onClose={handleClose}>
            <DialogTitle>{tl("campaign.selectTemplate")}</DialogTitle>
            <DialogContent>
              {templateList?.map((template, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography key={i}>
                  <Button
                    data-testmation="existingTemplate"
                    onClick={() => {
                      setSelectedTemplate(template);
                      handleClose();
                    }}
                  >
                    {template.name}
                  </Button>
                </Typography>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {tl("campaign.cancel")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        {formData.mediaType === "sms" && (
          <SmsTemplateBox
            showCustomKeywords
            showError={saveAttempted}
            content={formData.message}
            onChange={(content) => {
              const newFormData: CampaignData = cloneDeep(formData);
              newFormData.message = content;
              setFormData(newFormData);
            }}
          />
        )}
        {formData.mediaType === "email" && (
          <RichTextfield customKeyWords={keywords} state={state} setState={setState} />
        )}

        <RadioGroup
          value={formData.type}
          onChange={(e) => {
            const data: CampaignData = cloneDeep(formData);
            if (e.target.value === campaignType.INSTANTANEOUS) {
              data.type = e.target.value;
              delete data.trigger;
            }
            if (e.target.value === campaignType.AUTOMATED) {
              data.type = e.target.value;
              data.trigger = {
                props: {
                  sendAtTime: moment("7:00")
                },
                triggerType: triggerType.CLIENT_BIRTHDAY
              };
            }
            setFormData(data);
          }}
        >
          <FormControlLabel
            control={<Radio />}
            label={t("campaign.type.instantaneous")}
            value="instantaneous"
          />
          <FormControlLabel control={<Radio />} label={t("campaign.type.7am")} value="automated" />
        </RadioGroup>
      </Box>
    </Panel>
  );
};

export default connect(
  (state: RootState, { campaignId }: { campaignId: number }) => ({
    campaign: state.campaigns.collection.find(({ id }) => Number(campaignId) === id),
    audienceList: audiencesSelector(state),
    templateList: templatesSelector(state),
    servicesList: servicesSortedSelector(state)
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      onSave: async (data) => {
        await dispatch(campaignActions.saveCampaign(data));
      },
      navigateTo: (url) => dispatch(push(url)),
      loadAudience: () => dispatch(audienceActions.getAudiences()),
      loadTemplate: () => dispatch(templateActions.getTemplates()),
      loadServices: () => dispatch(serviceActions.getServices())
    }
  })
)(CampaignCreateEdit);

import {
  Box, Checkbox, FormControlLabel, Typography
} from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import Panel from '../../../components/Panel';
import * as campaignActions from '../../../actions/campaigns';
import { navigateRemoveModal, showDialog } from '../../../actions/navigation';
import { t } from '../../../components/translate';
import { CampaignData } from './CampaignHelpers';
import Can from '../../Policy/Can';

interface CampaignInfoProps {
  campaign: CampaignData
  onClose: any
  deleteCampaign: any
  saveCampaign: any
  navigateTo: any
}

const CampaignInfo: React.FC<CampaignInfoProps> = ({
  campaign,
  navigateTo,
  onClose,
  deleteCampaign,
  saveCampaign,
}) => {
  const editButton = (
    <Can policyAccessKey="campaign:editCampaign">
      <EditIcon
        data-testmation="editButton"
        style={{ cursor: 'pointer' }}
        onClick={() => navigateTo(`/campaign/campaigns/${campaign.id}/edit`)}
      />
    </Can>
  );

  const deleteButton = (
    <Can policyAccessKey="campaign:deleteCampaign">
      <DeleteIcon
        data-testmation="deleteButton"
        style={{ cursor: 'pointer' }}
        onClick={() => deleteCampaign(campaign.id, campaign.name)}
      />
    </Can>
  );

  return (
    <Panel
      onClose={onClose}
      editButton={editButton}
      deleteButton={deleteButton}
    >
      <Box px="32px" width="100%">
        <Typography variant="h6">{campaign.name}</Typography>
        <Box marginTop="32px" width="100%">
          <Box display="flex">
            <Box width="25%" fontSize="14px" fontWeight={600}>
              <Typography style={{ fontWeight: 600 }}>
                {'Campaign Type: '}
              </Typography>
            </Box>
            <Box fontSize="14px">
              <Typography>
                {capitalize(t(`campaign.type.${campaign.type}`))}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={1} width="100%">
          <Box display="flex">
            <Box width="25%" fontSize="14px" fontWeight={600}>
              <Typography style={{ fontWeight: 600 }}>
                {'Media Type: '}
              </Typography>
            </Box>
            <Box fontSize="14px">
              <Typography>{capitalize(campaign.mediaType)}</Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={1} width="100%">
          <Box display="flex">
            <Box width="25%" fontSize="14px" fontWeight={600}>
              <Typography style={{ fontWeight: 600 }}>{'Status: '}</Typography>
            </Box>
            <Box fontSize="14px">
              <Typography>
                {capitalize(t(`campaign.status.${campaign.status}`))}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            label=""
            control={(
              <Checkbox
                size="small"
                checked={campaign.activated}
                onChange={async () => {
                  const data: CampaignData = cloneDeep(campaign);
                  data.activated = !data.activated;
                  await saveCampaign(data);
                }}
              />
            )}
          />
          <Typography>Activated</Typography>
        </Box>

        <Box mt={1} width="100%">
          <Box display="flex">
            <Box width="25%" fontSize="14px" fontWeight={600}>
              <Typography style={{ fontWeight: 600 }}>
                {'Audience: '}
              </Typography>
            </Box>
            <Box fontSize="14px">
              <Typography>
                {capitalize((campaign.audience?.name).toString())}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={1} width="100%">
          <Box width="25%" fontSize="14px" fontWeight={600}>
            <Typography style={{ fontWeight: 600 }}>{'Content: '}</Typography>
          </Box>
          <Box mt={0.5} fontSize="14px" whiteSpace="pre-line">
            {campaign.mediaType === 'sms' && (
              <Typography>{capitalize(campaign.message)}</Typography>
            )}
            {campaign.mediaType === 'email' && (
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: campaign.message }} />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Panel>
  );
};

export default connect(
  () => {},
  (dispatch) => ({
    navigateTo: (url) => dispatch(push(url)),
    saveCampaign: (data) => dispatch(campaignActions.saveCampaign(data)),
    deleteCampaign: (id, name) => {
      dispatch(
        showDialog({
          title: `Delete campaign`,
          description: `Are you sure you want to delete ${name}?`,
          next: () => {
            dispatch(campaignActions.deleteCampaign(id));
            dispatch(navigateRemoveModal('Dialog'));
            dispatch(push('/campaign/campaigns'));
          },
          onCancel: () => dispatch(navigateRemoveModal),
        })
      );
    },
  })
)(CampaignInfo);

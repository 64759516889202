import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AssociateCompany,
  deleteAssociateCompany,
  getAssociateCompanies,
  getAssociateCompany,
  postAssociateCompany,
  putAssociateCompany
} from "../api/associateCompanies";
import { notificationAdd } from "../actions/notification";
import { createSuccessMessage, MODULE, updateSuccessMessage } from "../helpers/messages";

export const getRcAssociateCompany = createAsyncThunk(
  "associateCompanies",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAssociateCompanies();
      return response;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to get associate companies",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const postRcAssociateCompany = createAsyncThunk(
  "department/postDepartment",
  async (
    {
      data,
      onSuccess
    }: { data: Partial<AssociateCompany>; onSuccess: (associateCompany: AssociateCompany) => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postAssociateCompany(data);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: createSuccessMessage(MODULE.ASSOCIATE_COMPANY),
          autoTimeout: true
        })
      );
      onSuccess(response);
      return response;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to create associate company",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const getAssociateCompanyDetails = createAsyncThunk(
  "associateCompanies/getDetails",
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAssociateCompany(id);
      return response;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to get associate company",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const patchAssociateCompany = createAsyncThunk(
  "associateCompanies/patch",
  async (
    {
      data,
      onSuccess
    }: { data: Partial<AssociateCompany>; onSuccess: (associateCompany: AssociateCompany) => void },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await putAssociateCompany(data);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: updateSuccessMessage(MODULE.ASSOCIATE_COMPANY),
          autoTimeout: true
        })
      );
      onSuccess(response);
      return response as AssociateCompany;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to update associate company",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const deleteRcAssociateCompany = createAsyncThunk(
  "associateCompanies/delete",
  async (data: Partial<AssociateCompany>, { rejectWithValue, dispatch }) => {
    try {
      await deleteAssociateCompany(data);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Associate company deleted successfully.",
          autoTimeout: true
        })
      );
      return data;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to delete associate company",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

interface State {
  selectedAssociateCompany: AssociateCompany;
  collection: AssociateCompany[];
}

const initialState: State = {
  selectedAssociateCompany: {} as AssociateCompany,
  collection: []
};

const associateCompanySlice = createSlice({
  name: "associateCompanies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRcAssociateCompany.fulfilled, (draft, { payload }) => {
      draft.collection = payload;
    });
    builder.addCase(
      postRcAssociateCompany.fulfilled,
      (draft, { payload }: { payload: AssociateCompany }) => {
        draft.collection.push(payload as AssociateCompany);
      }
    );
    builder.addCase(
      patchAssociateCompany.fulfilled,
      (draft, { payload }: { payload: AssociateCompany }) => {
        const index = draft.collection.findIndex((company) => company.id === payload.id);
        if (index !== -1) draft.collection[index] = payload;
      }
    );

    builder.addCase(deleteRcAssociateCompany.fulfilled, (draft, { payload }) => {
      draft.collection = draft.collection.filter((company) => company.id !== payload.id);
    });
  }
});

export default associateCompanySlice.reducer;

import { Get, Post, Delete } from './apiHelper';

const slotsRoot = '/api/slots';

export async function postBatchSlots(slots, serviceProviderId) {
  const response = await Post(`${slotsRoot}/batchCreate`, { slots, serviceProviderId }, true);
  return response.data;
}

export async function getSlots(from, until, serviceProviderId, resourceCentreId, filterEmptySlots = false) {
  const response = await Get(`${slotsRoot}`, true, {
    from,
    until,
    serviceProviderId,
    resourceCentreId,
    ...(filterEmptySlots ? { filterEmptySlots: 'true' } : {})
  });
  return response.data;
}

export async function getSlot(slotId) {
  const response = await Get(`${slotsRoot}/${slotId}`, true);
  return response.data;
}

export async function bookSlot(slotId, bookData) {
  const response = await Post(`${slotsRoot}/${slotId}/book`, bookData, true);
  return response.data;
}

export async function removeSlot(slotId) {
  const response = await Delete(`${slotsRoot}/${slotId}`, true);
  return response.data;
}

import { Box } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationAdd } from "../../../actions/notification";
import { getInternalLabInfo } from "../../../api/labTest";
import Panel from "../../../components/Panel";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import { createEntry, fetchLabEntry } from "../../../slices/labRecordSlice";
import { RootState } from "../../../store";
import EntryActions from "./EntryActions";
import EntryCore from "./EntryCore";
import EntryFooter from "./EntryFooter";
import EntryHeader from "./EntryHeader";
import { LabRecordType } from "../index";
import LabSpecificSpSignature, { AuthorizedSpIds } from "./LabSpecificSpSignature";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

interface Props {
  id: number;
  onClose: () => void;
  labRecordType: LabRecordType;
}

export default function LabEntry({ id, onClose, labRecordType }: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const [labSp, setLabSp] = React.useState<AuthorizedSpIds>({
    authorizedSP1Dynamic: null,
    authorizedSP2Dynamic: null,
    authorizedSP3Dynamic: null,
    authorizedSP4Dynamic: null,
    finalAuthorizedSP1: null,
    finalAuthorizedSP2: null,
    finalAuthorizedSP3: null,
    finalAuthorizedSP4: null,
    currentIdSP1: null,
    currentIdSP2: null,
    currentIdSP3: null,
    currentIdSP4: null,
    authorizedSP1Id: null,
    authorizedSP2Id: null,
    authorizedSP3Id: null,
    authorizedSP4Id: null
  });

  const records = useSelector((state: RootState) => state.labTest.labTestRecords);
  const [internalLabInfo, setInternalLabInfo] = React.useState<string[]>([] as string[]);
  const [skipInternalStock, setSkipInternalStock] = React.useState<boolean>(false);
  const currentRC = useCurrentResourceCentre();

  React.useEffect(() => {
    if (currentRC?.labSettings.enableInternalStock) {
      (async () => {
        try {
          const response = await getInternalLabInfo(id);
          setInternalLabInfo(response);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              variant: "error",
              message: error?.data?.message || errorFetchMessage(MODULE.INTERNAL_STOCK),
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, [id, dispatch, currentRC]);

  React.useEffect(() => {
    const relatedRecord = records.find((el) => el.id === id);

    if (relatedRecord) {
      dispatch(createEntry(relatedRecord));
    } else {
      dispatch(fetchLabEntry(id));
    }
  }, [id, dispatch, records]);

  if (!records) return null;

  return (
    <Panel wrapperStyle={{ minWidth: "900px" }} onClose={onClose}>
      <Box sx={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
        <Box px={4}>
          <EntryHeader />
        </Box>
        <Box>
          <EntryCore />
        </Box>
        <Box px={4}>
          <EntryFooter id={id} />
          <LabSpecificSpSignature onSpChange={(value) => setLabSp(value)} />
        </Box>
      </Box>
      <EntryActions
        authorizedServiceProviders={labSp}
        labRecordType={labRecordType}
        internalLabInfo={internalLabInfo}
        skipInternalStock={skipInternalStock}
        setSkipInternalStock={(value) => setSkipInternalStock(value)}
      />
    </Panel>
  );
}

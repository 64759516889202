/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ResourceCentre from "./containers/ResourceCentre";
import ResourceCentreShow, { SETTING_ROUTES } from "./containers/ResourceCentre/ResourceCentreShow";
import Schedule from "./containers/Schedule";
import Calendar from "./containers/Calendar";
import Login from "./containers/User/Login";
import Signup from "./containers/User/Signup";
import Messenger from "./containers/User/Messenger";
import Slots from "./containers/Slots";
import Book from "./components/Book";
import ClientList from "./containers/Client/ClientList";
import Stock from "./containers/Stock/index";
import PurchaseEntry from "./containers/Stock/PurchaseEntry/PurchaseEntry";
import Account from "./containers/User/Account";
import Gallery from "./containers/Gallery/Gallery";
import Terms from "./containers/Pages/Terms";
import Reports from "./containers/Reports/Reports";
import Billing from "./containers/Billing";
import EnsureUserComponent from "./containers/EnsureUserComponent";
import Services from "./containers/Services";
import AssessmentQR from "./containers/AssessmentQR/AssessmentQR";
import LabQR from "./containers/Lab/LabPrint/LabQR";
import Lab from "./containers/Lab";
import Bookings from "./containers/Bookings";
import Campaign from "./containers/Campaign";
import Feed from "./containers/Feed";
import IpdList from "./containers/IPD/IpdList";
import OnDemandProducts from "./containers/OndemandProduct";
import { Mode } from "./interfaces/StockInterfaces";
import Medical from "./containers/Medical";
import Hmis from "./containers/Hmis";
import { RootState } from "./store";
import SubscriptionTerminated from "./containers/SubscriptionTerminated";
import ResourceCentreInvoices from "./containers/ResourceCentreInvoices/ResourceCentreInvoices";
import Spinner from "./containers/TwilioVideo/Twilio/components/Spinner";
import Suppliers from "./containers/Suppliers";
import AccountSettings from "./containers/accounts/AccountSuperAdminSettings";
import CoaTree from "./containers/accounts/CoaTree";
import Subscriptions from "./containers/Subscriptions";
import withFacebookChat from "./components/FacebookChat/withFacebookChat";
import VerifyPayment from "./containers/ResourceCentreInvoices/VerifyPayment";
import BedManagement from "./containers/IPD/BedManagement/BedManagement";
import AssessmentHome from "./containers/Assessment/Home";
import { navigateAddModal } from "./actions/navigation";
import { UserMode } from "./interfaces/User";
import BillByUUID from "./containers/Billing/BillByUUID/BillByUUID";
import VerificationCodes from "./containers/OkhatiPublic/VerificationCodes";
import UserGroups from "./containers/UserGroupPolicy";
import KitchenPharmacyPurchaseEntry from "./containers/Stock/KitchenPharmacy/KitchenPharmacyPurchaseEntry";
import DashboardV3 from "./containers/DashboardV3/Index";
import Daybook from "./containers/Daybook";
import ChatStats from "./containers/OkhatiPublic/ChatStats";
import NotificationList from "./containers/Notifications/NotificationList";
import MobileUsers from "./containers/OkhatiPublic/MobileUsers";
import ResourceCentreWithNotifications from "./containers/Notifications/Index";
import MyMedicalReport from "./containers/Medical/MyMedicalReport";

const Service = React.lazy(() => import("./containers/Lab/Admin/PublicServices/PublicService"));
const AdminLab = React.lazy(() => import("./containers/Lab/Admin"));
const MasterDataControl = React.lazy(() => import("./containers/MasterDataControl"));
const LabEdit = React.lazy(() => import("./containers/Lab/Admin/Lab"));
const LabGroupEdit = React.lazy(() => import("./containers/Lab/Admin/LabGroup"));

const VoucherEntry = React.lazy(() => import("./containers/accounts/JournalVoucher"));
const VoucherList = React.lazy(() => import("./containers/accounts/VoucherList"));
const AccountReports = React.lazy(() => import("./containers/accounts/Reports"));
const BankReconciliation = React.lazy(() => import("./containers/accounts/BankReconciliation"));
const PaymentVoucher = React.lazy(() => import("./containers/accounts/PaymentVoucher"));
const FiscalClosure = React.lazy(() => import("./containers/accounts/FiscalClosure"));
const SmsProviders = React.lazy(() => import("./containers/SmsProviders/Index"));
const RequestAccountDeletion = React.lazy(
  () => import("./containers/RequestAccountDeletion/RequestAccountDeletion")
);

function renderResourceRoutes(resource, Component) {
  return [
    <Route key={`/${resource}`} exact path={`/${resource}`} component={Component} />,
    <Route
      key={`/${resource}/new`}
      exact
      path={`/${resource}/new`}
      render={(props) => <Component {...props} createMode />}
    />,
    <Route
      key={`/${resource}/:id/edit`}
      exact
      path={`/${resource}/:id/edit`}
      render={(props) => <Component {...props} editMode resourceId={props.match.params.id} />}
    />
  ];
}

type PrivateRouteProps = {
  component?: React.ComponentType;
} & RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, ...rest }) => {
  const dispatch = useDispatch();
  const authenticated = useSelector((state: RootState) => state.authenticated);
  const user = useSelector((state: RootState) => state.userContext.userCreds);
  const mode = useSelector((state: RootState) => state.userContext.mode);

  React.useEffect(() => {
    if (user?.tempPassword && mode !== UserMode.Admin) {
      dispatch(
        navigateAddModal({
          component: "ChangePassword",
          props: {
            next: () => ({})
          }
        })
      );
    }
  }, [user?.tempPassword, dispatch, mode]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticated && rest.render && user) {
          return rest.render(props);
        }
        if (authenticated) {
          return <EnsureUserComponent component={component} {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

PrivateRoute.defaultProps = { component: null };

const Routes = (): JSX.Element => (
  <Switch>
    <PrivateRoute exact path="/" component={DashboardV3} />
    <PrivateRoute exact path="/dashboard" component={DashboardV3} />
    <PrivateRoute path="/daybook" component={Daybook} />
    <Route exact path="/termination" component={SubscriptionTerminated} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/my-report/:uuid" component={AssessmentQR} />
    <Route exact path="/my-lab/:uuid" component={LabQR} />
    <Route exact path="/my-medical/:uuid" component={MyMedicalReport} />
    <Route exact path="/my-bill/:uuid" component={withFacebookChat(BillByUUID)} />
    <Route exact path="/tos" component={Terms} />
    <Route exact path="/signup/:referenceId" component={Signup} />
    <Route exact path="/messenger/:userId" component={Messenger} />
    {renderResourceRoutes("resourcecentres", ResourceCentre)}
    <PrivateRoute
      exact
      path="/resourcecentres/:id/details"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/details/edit"
      render={(props) => <ResourceCentreShow {...props} editMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/employees"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/referrers"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/serviceProviders"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/nonUserEmployee"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/allUsers"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path={`/resourcecentres/:id/${SETTING_ROUTES.BOOKABLE_RESOURCE}`}
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/supplier"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/associateCompanies"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/associateCompanies/create"
      render={(props) => <ResourceCentreShow {...props} createMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/associateCompanies/:companyId/edit"
      render={(props) => <ResourceCentreShow {...props} editMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/settings"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/summaryReport"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/public"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/subscriptions"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute exact path="/resourcecentres/:id/payments" component={ResourceCentreShow} />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/billsMaterializedView"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/accountSettings"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/moduleSettings"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/otherSettings"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/externalSettings"
      render={(props) => <ResourceCentreShow {...props} />}
    />
    <PrivateRoute exact path="/resourcecentres/:id/supplier" component={ResourceCentreShow} />
    <PrivateRoute exact path="/resourcecentres/:id/referrers" component={ResourceCentreShow} />
    <PrivateRoute exact path="/suppliers" component={Suppliers} />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/employees/create"
      render={(props) => <ResourceCentreShow {...props} createMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/nonUserEmployee/create"
      render={(props) => <ResourceCentreShow {...props} createMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/employees/:employeeId/edit"
      render={(props) => (
        <ResourceCentreShow
          {...props}
          editMode
          employeeId={
            props.match.params.employeeId ? Number(props.match.params.employeeId) : undefined
          }
        />
      )}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/nonUserEmployee/:employeeId/edit"
      render={(props) => (
        <ResourceCentreShow
          {...props}
          editMode
          employeeId={
            props.match.params.employeeId ? Number(props.match.params.employeeId) : undefined
          }
        />
      )}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/supplier/create"
      render={(props) => <ResourceCentreShow {...props} createMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/supplier/:supplierId/edit"
      render={(props) => (
        <ResourceCentreShow
          {...props}
          editMode
          supplierId={
            props.match.params.supplierId ? Number(props.match.params.supplierId) : undefined
          }
        />
      )}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/serviceProviders/create"
      render={(props) => <ResourceCentreShow {...props} createSPMode />}
    />
    <PrivateRoute
      exact
      path="/resourcecentres/:id/serviceProviders/:serviceProviderId/edit"
      render={(props) => (
        <ResourceCentreShow
          {...props}
          editSPMode
          serviceProviderId={
            props.match.params.serviceProviderId
              ? Number(props.match.params.serviceProviderId)
              : undefined
          }
        />
      )}
    />
    <PrivateRoute
      exact
      path="/schedule"
      render={(props) => <EnsureUserComponent component={Schedule} {...props} />}
    />
    <PrivateRoute
      exact
      path="/calendar"
      render={(props) => <EnsureUserComponent component={Calendar} {...props} />}
    />
    <PrivateRoute
      exact
      path="/schedule/addSlots"
      render={(props) => <EnsureUserComponent component={Schedule} {...props} openAddSlotModal />}
    />
    <PrivateRoute exact path="/createSlots" component={Slots} />
    <PrivateRoute exact path="/bookSlot" component={Book} />
    <PrivateRoute exact path="/account" component={Account} />
    <Route exact path="/gallery" component={Gallery} />
    <PrivateRoute path="/services" render={(props) => <Services {...props} />} />
    <PrivateRoute path="/publicServices" render={(props) => <Services {...props} isPublicTab />} />
    <PrivateRoute
      path="/socialSecurityFundServices"
      render={(props) => <Services {...props} isSsfTab />}
    />
    <PrivateRoute exact path="/clients" render={(props) => <ClientList {...props} />} />
    <PrivateRoute exact path="/stock" render={(props) => <Stock {...props} />} />
    <PrivateRoute exact path="/campaign" render={(props) => <Campaign {...props} />} />
    <PrivateRoute exact path="/ipd" render={(props) => <IpdList {...props} />} />
    <PrivateRoute exact path="/ipd/new" render={(props) => <IpdList {...props} mode="create" />} />
    <PrivateRoute exact path="/ipd/:id" render={(props) => <IpdList {...props} mode="read" />} />
    <PrivateRoute exact path="/beds" component={BedManagement} />
    <PrivateRoute
      exact
      path="/ipd/:id/edit"
      render={(props) => <IpdList {...props} mode="edit" />}
    />

    <PrivateRoute
      exact
      path="/campaign/audience/new"
      render={(props) => <Campaign {...props} mode="create" />}
    />
    <PrivateRoute
      exact
      path="/campaign/audience/:id?"
      render={(props) => <Campaign {...props} />}
    />
    <PrivateRoute
      exact
      path="/campaign/audience/:id/edit"
      render={(props) => <Campaign {...props} mode="edit" />}
    />
    <PrivateRoute
      exact
      path="/campaign/template/new"
      render={(props) => <Campaign {...props} mode="create" />}
    />
    <PrivateRoute
      exact
      path="/campaign/template/:id?"
      render={(props) => <Campaign {...props} />}
    />
    <PrivateRoute
      exact
      path="/campaign/template/:id/edit"
      render={(props) => <Campaign {...props} mode="edit" />}
    />

    <PrivateRoute
      exact
      path="/campaign/campaigns/message"
      render={(props) => <Campaign {...props} mode="message" />}
    />

    <PrivateRoute
      exact
      path="/campaign/campaigns/new"
      render={(props) => <Campaign {...props} mode="create" />}
    />
    <PrivateRoute
      exact
      path="/campaign/campaigns/:id?"
      render={(props) => <Campaign {...props} />}
    />
    <PrivateRoute
      exact
      path="/campaign/campaigns/:id/edit"
      render={(props) => <Campaign {...props} mode="edit" />}
    />
    <PrivateRoute path="/feeds" render={(props) => <Feed {...props} />} />
    <PrivateRoute path="/medical" render={(props) => <Medical {...props} />} />
    <PrivateRoute
      exact
      path="/stock/stockProducts/:id?"
      render={(props) => (
        <Stock {...props} id={props.match.params.id ? Number(props.match.params.id) : undefined} />
      )}
    />
    <PrivateRoute
      exact
      path="/stock/stockTransactions/:id?"
      render={(props) => <Stock {...props} />}
    />
    <PrivateRoute
      exact
      path="/stock/stockInternal/:id?"
      render={(props) => (
        <Stock {...props} id={props.match.params.id ? Number(props.match.params.id) : undefined} />
      )}
    />
    <PrivateRoute
      exact
      path="/stock/stockServices/:id?"
      render={(props) => (
        <Stock {...props} id={props.match.params.id ? Number(props.match.params.id) : undefined} />
      )}
    />
    <Route exact path="/stock/stockRecords/:id" render={(props) => <Stock {...props} />} />
    <Route exact path="/stock/stockTransactions/:id" render={(props) => <Stock {...props} />} />
    <Route
      exact
      path="/stock/stockTransactions/:id/purchase"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Edit} />}
    />
    <Route
      exact
      path="/stock/stockTransactions/:id/purchaseReturn"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Edit} />}
    />
    <Route
      exact
      path="/stock/stockTransactions/:id/editPurchase"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Edit} />}
    />
    <PrivateRoute
      exact
      path="/stock/kitchenPharmacy/:id?"
      render={(props) => (
        <Stock {...props} id={props.match.params.id ? Number(props.match.params.id) : undefined} />
      )}
    />
    <PrivateRoute exact path="/stock/stockTransfers" render={(props) => <Stock {...props} />} />
    <Route
      exact
      path="/stock/purchase"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Create} />}
    />
    <Route
      exact
      path="/stock/kitchen/purchase"
      render={(props) => <KitchenPharmacyPurchaseEntry {...props} />}
    />
    <Route exact path="/stock/openingStock" render={(props) => <PurchaseEntry {...props} />} />
    <Route
      exact
      path="/stock/stockTransactions/:id/editOpeningStock"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Edit} />}
    />
    <Route
      exact
      path="/stock/stockTransactions/:id/openingStock"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Edit} />}
    />
    <Route
      exact
      path="/stock/purchaseReturn/:id?"
      render={(props) => <PurchaseEntry {...props} />}
    />
    <Route
      exact
      path="/stock/:id/openingStockAdjustment/"
      render={(props) => <PurchaseEntry {...props} mode={Mode.Edit} />}
    />
    <Route
      exact
      path="/stock/expiryOrDiscardment"
      render={(props) => <PurchaseEntry {...props} />}
    />
    <Route exact path="/stock/excess" render={(props) => <PurchaseEntry {...props} />} />
    <Route exact path="/stock/internalUse" render={(props) => <PurchaseEntry {...props} />} />
    <Route
      exact
      path="/stock/internalReturn/:id?"
      render={(props) => <PurchaseEntry {...props} />}
    />
    <Route exact path="/stock/:id/adjustment/" render={(props) => <PurchaseEntry {...props} />} />
    <PrivateRoute
      exact
      path="/stock/kitchenPharmacyPurchase/"
      render={(props) => <PurchaseEntry {...props} />}
    />

    <PrivateRoute
      exact
      path="/clients/new"
      render={(props) => <ClientList {...props} mode="create" />}
    />
    <PrivateRoute
      exact
      path="/clients/new/corporate"
      render={(props) => <ClientList {...props} mode="create" isCorporateClient />}
    />
    <PrivateRoute
      exact
      path="/clients/:id"
      render={(props) => (
        <ClientList
          {...props}
          clientId={props.match.params.id ? Number(props.match.params.id) : undefined}
          mode="read"
        />
      )}
    />
    <PrivateRoute
      exact
      path="/clients/:id/edit"
      render={(props) => (
        <ClientList
          {...props}
          clientId={props.match.params.id ? Number(props.match.params.id) : undefined}
          mode="edit"
        />
      )}
    />
    <PrivateRoute path="/assessment" component={AssessmentHome} />
    <PrivateRoute exact path="/lab/settings" component={Lab} />
    <PrivateRoute exact path="/lab/labTests" component={Lab} />
    <PrivateRoute exact path="/lab/labTestGroups" component={Lab} />
    <PrivateRoute path="/lab/labRecords/:type" component={Lab} />
    <PrivateRoute path="/lab" component={Lab} />
    <PrivateRoute path="/billing" render={(props) => <Billing {...props} />} />
    <PrivateRoute
      path="/reports/:reportType?/:reportName?"
      render={(props) => <Reports {...props} />}
    />
    <PrivateRoute exact path="/bookings" component={Bookings} />
    <PrivateRoute exact path="/userGroups" component={UserGroups} />
    <PrivateRoute exact path="/ondemandProduct" component={OnDemandProducts} />

    <PrivateRoute exact path="/hmis" render={(props) => <Hmis {...props} />} />
    <PrivateRoute exact path="/hmis/opd" render={(props) => <Hmis {...props} />} />
    <PrivateRoute exact path="/hmis/imnci/aboveTwoMonths" render={(props) => <Hmis {...props} />} />
    <PrivateRoute
      exact
      path="/hmis/imnci/aboveTwoMonths/new"
      render={(props) => <Hmis {...props} mode="create" />}
    />
    <PrivateRoute exact path="/hmis/imnci/belowTwoMonths" render={(props) => <Hmis {...props} />} />
    <PrivateRoute
      exact
      path="/hmis/imnci/belowTwoMonths/new"
      render={(props) => <Hmis {...props} mode="create" />}
    />
    <PrivateRoute
      path="/hmis/mulDartaNew/new"
      render={(props) => <Hmis {...props} mode="create" />}
    />
    <PrivateRoute
      exact
      path="/hmis/mulDarta/new"
      render={(props) => <Hmis {...props} mode="create" />}
    />
    <PrivateRoute
      exact
      path="/hmis/mulDarta/:id?"
      render={(props) => <Hmis {...props} mode="read" />}
    />
    <PrivateRoute
      exact
      path="/hmis/hmisReport"
      render={(props) => <Hmis {...props} mode="read" />}
    />
    <PrivateRoute
      path="/public/verificationCodes"
      render={(props) => <VerificationCodes {...props} />}
    />
    <PrivateRoute path="/public/chatStats" render={(props) => <ChatStats {...props} />} />
    <PrivateRoute path="/public/mobileUsers" component={MobileUsers} />
    <PrivateRoute path="/accountSettings" component={AccountSettings} />
    <PrivateRoute exact path="/resourceCentreInvoices" render={() => <ResourceCentreInvoices />} />
    <PrivateRoute exact path="/payment/:paymentProvider" component={VerifyPayment} />
    <React.Suspense
      fallback={
        <div>
          <Spinner />
        </div>
      }
    >
      <Route exact path="/userDeletion" component={RequestAccountDeletion} />
      <Switch>
        <PrivateRoute exact path="/accounts/bankReconciliation" component={BankReconciliation} />
        <PrivateRoute
          exact
          path="/accounts/vouchers/:filter?"
          render={(props) => <VoucherList {...props} />}
        />
        <PrivateRoute exact path="/accounts/coa" render={(props) => <CoaTree {...props} />} />
        <PrivateRoute
          path="/accounts/journalEntry"
          render={(props) => <VoucherEntry {...props} />}
        />
        <PrivateRoute
          path="/accounts/receiptVoucher"
          render={(props) => <PaymentVoucher {...props} />}
        />
        <PrivateRoute
          path="/accounts/paymentVoucher"
          render={(props) => <PaymentVoucher {...props} />}
        />
        <PrivateRoute
          exact
          path="/accounts/contraVoucher"
          render={(props) => <PaymentVoucher {...props} />}
        />
        <PrivateRoute
          path="/accounts/reports/:reportType?"
          render={(props) => <AccountReports {...props} />}
        />
        <PrivateRoute exact path="/accounts/fiscalClosure" component={FiscalClosure} />

        <PrivateRoute path="/resourceCentreSubscriptions" component={Subscriptions} />
        <PrivateRoute
          path="/resourceCentreNotifications"
          component={ResourceCentreWithNotifications}
        />
        <PrivateRoute path="/sentNotifications" component={NotificationList} />
        <PrivateRoute path="/masterDataControl" exact component={MasterDataControl} />
        <PrivateRoute path="/masterDataControl/adminlab" exact component={AdminLab} />
        <PrivateRoute path="/masterDataControl/adminlab/lab" exact component={LabEdit} />
        <PrivateRoute path="/masterDataControl/adminlab/labGroup" exact component={LabGroupEdit} />
        <PrivateRoute path="/masterDataControl/service" component={Service} />
        <PrivateRoute path="/smsProviders" exact component={SmsProviders} />
      </Switch>
    </React.Suspense>
  </Switch>
);

export default Routes;

import { useSelector } from "react-redux";
import { CanActions, OptActions } from "../interfaces/policy";
import { RootState } from "../store";
import { PermissionGroups } from "../interfaces/User";

export default function useCan(
  policyAccessKey: CanActions | "allow" | Array<CanActions> | "deny" | OptActions,
  {
    partialCheck = false,
    superAdminPass = false,
    superAdminPassReverse = false,
    restrictSupportAdmin = false,
    supportAccountAdminPass = false,
    superAdminAgentPass = false,
    superAdminAgentPassReverse = false,
    publicAdminPass = false,
    publicAdminPassReverse = false
  }: {
    partialCheck?: boolean;
    superAdminPass?: boolean;
    superAdminPassReverse?: boolean;
    restrictSupportAdmin?: boolean;
    supportAccountAdminPass?: boolean;
    superAdminAgentPass?: boolean;
    superAdminAgentPassReverse?: boolean;
    publicAdminPass?: boolean;
    publicAdminPassReverse?: boolean;
  }
): boolean {
  let userPolicies = useSelector((state: RootState) => state.userContext.policies);
  const userGroup = useSelector(
    (state: RootState) =>
      state.userContext?.userCreds?.userGroups?.length && state.userContext.userCreds.userGroups[0]
  );

  const isSupportAccountAdmin =
    useSelector((state: RootState) => state.userContext?.userCreds?.userGroupType) ===
      "supportAdminAccount" && userGroup === "supportAdmin";

  if (["superAdmin", "supportAdmin", "superAdminAgent"].includes(userGroup)) {
    userPolicies = {} as CanActions;
  }

  if (isSupportAccountAdmin) {
    if (supportAccountAdminPass) return true;
    return false;
  }

  if (
    superAdminPassReverse &&
    ["superAdmin", "supportAdmin", "superAdminAgent"].includes(userGroup) &&
    !isSupportAccountAdmin
  )
    return false;

  if (superAdminAgentPassReverse && userGroup === "superAdminAgent" && !isSupportAccountAdmin)
    return false;
  if (publicAdminPassReverse && userGroup === PermissionGroups.PublicBookingAdmin) return false;

  if (
    !isSupportAccountAdmin &&
    superAdminAgentPass &&
    (restrictSupportAdmin
      ? ["superAdmin"].includes(userGroup)
      : ["superAdmin", "supportAdmin", "superAdminAgent"].includes(userGroup))
  )
    return true;

  if (
    !isSupportAccountAdmin &&
    superAdminPass &&
    (restrictSupportAdmin
      ? ["superAdmin"].includes(userGroup)
      : ["superAdmin", "supportAdmin"].includes(userGroup))
  )
    return true;
  if (publicAdminPass && [PermissionGroups.PublicBookingAdmin].includes(userGroup)) return true;

  if (policyAccessKey === "deny") return false;
  if (policyAccessKey === "allow") return true;
  if (
    userPolicies &&
    typeof policyAccessKey === "string" &&
    !policyAccessKey.includes("*") &&
    userPolicies[policyAccessKey]
  ) {
    return true;
  }
  if (userPolicies && typeof policyAccessKey === "string" && policyAccessKey.includes("*")) {
    const policiesModuleArray = Object.keys(userPolicies).map((el) => el.split(":")[0]);
    const moduleToCheck = policyAccessKey.split(":")[0];
    if (policiesModuleArray.find((el) => el === moduleToCheck)) {
      return true;
    }
    return false;
  }
  if (
    userPolicies &&
    !partialCheck &&
    Array.isArray(policyAccessKey) &&
    policyAccessKey.every((key) => !!userPolicies[key])
  ) {
    return true;
  }
  if (
    userPolicies &&
    partialCheck &&
    Array.isArray(policyAccessKey) &&
    policyAccessKey.some((key) => !!userPolicies[key])
  ) {
    return true;
  }
  if (policyAccessKey) return false;
  return false;
}

import { useQuery } from "react-query";
import { getDayBookReport } from "../../../../../api/accountReports";
import { DayBookReport, FiltersInterface } from "../../../../../interfaces/AccountReportsInterface";
import { queryKeys } from "../../../../../react-query/constants";

export default function useBalanceSheet(filters: FiltersInterface): DayBookReport[] {
  const { from, until } = filters;

  const queryParams = `from=${from.toISOString()}&until=${until.toISOString()}`;

  const { data = [] } = useQuery([queryKeys.account, "dayBookReport", { from, until }], () =>
    getDayBookReport(queryParams)
  );
  return data as DayBookReport[];
}

import React from "react";
import { tl } from "../../components/translate";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";

interface CreatedDateProps {
  createdDate?: Date | string;
  onChange: (v) => void;
  width?: string;
}

const CreatedDate: React.FC<CreatedDateProps> = ({ createdDate, onChange, width }) => (
  <CalendarDropdown
    date={createdDate ? new Date(createdDate) : null}
    TextFieldProps={{
      margin: "dense",
      variant: "outlined"
    }}
    maxValidDate={new Date()}
    width={width}
    fullwidth={!width}
    label={tl("assessment.createdDate")}
    format="formatted2"
    onChange={(v) => onChange(v)}
  />
);

export default CreatedDate;

import { Box, Typography } from "@mui/material";
import React from "react";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { getDisplayAgeFromDOB } from "../../helpers/formatters";
import { getGenderString } from "../Campaign/Audience/AudienceHelpers";
import { Client } from "../../interfaces/ClientInterface";

interface Props {
  client: Client;
  open: boolean;
  setOpen: (value) => void;
}
const CreateClientConfirmation = ({ client, open, setOpen, onConfirm }: Props): JSX.Element => (
  <OkhatiDialog
    readMode={false}
    open={open}
    title="Do you want to proceed?"
    description={
      <div>
        <Typography>Client will be created with following info</Typography>
        <Box sx={{ marginLeft: 2 }}>
          {client?.name && <Typography>Name: {client.name}</Typography>}
          {client?.dob && <Typography>Name: {getDisplayAgeFromDOB(client.dob)}</Typography>}
          {client?.gender && (
            <Typography>Gender: {getGenderString(Number(client.gender))}</Typography>
          )}
          {client?.email && <Typography>Email: {client.email}</Typography>}
          {client?.phone && <Typography>Phone: {client.phone}</Typography>}
        </Box>
      </div>
    }
    next={() => {
      onConfirm();
      setOpen(false);
    }}
    cancel={() => setOpen(false)}
  />
);

export default CreateClientConfirmation;

import React from "react";

function usePrintShortcut(ref: React.RefObject<HTMLElement>, applyPrintStyles?: () => void): void {
  React.useEffect(() => {
    const handlePrint = (event) => {
      if ((event.ctrlKey || event.altKey) && event.key === "p" && ref?.current) {
        event.preventDefault();
        if (applyPrintStyles) {
          applyPrintStyles();
        }
        ref.current.click();
      }
    };

    window.addEventListener("keydown", handlePrint);

    return () => {
      window.removeEventListener("keydown", handlePrint);
    };
  }, [ref, applyPrintStyles]);
}

export default usePrintShortcut;

/* eslint-disable react/jsx-props-no-spreading */
import { Box, TextField, TextFieldProps, Autocomplete } from "@mui/material";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { navigateAddModal } from "../../actions/navigation";
import { getReferrers as getReferrersAction } from "../../actions/referrers";
import { Referrer } from "../../interfaces/ReferrerInterface";
import { RootState } from "../../store";

const { useState, useRef, useEffect } = React;

interface OwnProps {
  onReferrerChange: (v: Referrer | null) => void;
  useSettings?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
  showLabel?: boolean;
  referrerId?: number;
  disabled?: boolean;
  width?: string;
  isFocusedInitially?: boolean;
  textFieldWidth?: string;
  label?: string;
  placeholder?: string;
  error?: {
    value: boolean;
    message: string;
  };
  isBookingWindow?: boolean;
}
type MapStateProps = { referrers: Array<Referrer>; isSubscribed: boolean };
type MapDispatchProps = { onCreate: ({ next, referrer }) => void; getReferrers: () => void };
type ReduxProps = MapDispatchProps & MapStateProps;
type Props = ReduxProps & OwnProps;

function ReferrerCreateSelect(props: Props) {
  const {
    referrers,
    onCreate,
    getReferrers,
    onReferrerChange,
    useSettings = false,
    isSubscribed,
    textFieldProps = {},
    referrerId,
    disabled = false,
    width = "210px",
    isFocusedInitially = false,
    textFieldWidth,
    label = "Referrer select or create",
    placeholder = "Referrer select or create",
    error,
    isBookingWindow = false
  } = props;

  const requireReferrer = useSelector(
    (state: RootState) =>
      state.resources?.resourceCentres[0]?.settings?.formSettings?.requireReferrer
  );

  const [value, setValue] = useState<Referrer | null>(null);
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (referrerId) {
      const relatedReferrer = referrers?.find((referrer) => referrer.id === referrerId) || null;
      setValue(relatedReferrer);
    }
  }, [referrerId, referrers]);

  useEffect(() => {
    if (isFocusedInitially) {
      textFieldRef.current?.focus();
    }
  }, [isFocusedInitially]);

  useEffect(() => {
    if (!referrers.length) {
      getReferrers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      onReferrerChange(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (!isSubscribed && useSettings) return null;

  return (
    <Box>
      <Autocomplete<Partial<Referrer> & { inputValue?: string }, false, true, true>
        options={referrers}
        clearOnBlur
        style={{ width: textFieldWidth }}
        freeSolo
        forcePopupIcon
        disabled={disabled}
        value={value}
        onChange={(_, v) => {
          if (typeof v === "string") {
            return;
          }
          if (v?.inputValue) {
            onCreate({
              next: (data?) => {
                setValue(data || null);
              },
              referrer: v.inputValue
            });
          } else {
            setValue((v as Referrer) || null);
          }
        }}
        filterOptions={(options, params) => {
          if (params.inputValue !== "") {
            options.push({
              inputValue: params.inputValue,
              referrer: `Add "${params.inputValue}"`
            });
          }
          return options.filter((opt) => {
            const filterString = (opt.referrer || "").toLowerCase();
            return (
              filterString.includes(params.inputValue.toLowerCase()) || filterString.includes("add")
            );
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            label={isBookingWindow ? "" : `${label} ${requireReferrer ? "*" : ""}`}
            placeholder={`${placeholder} ${requireReferrer ? "*" : ""}`}
            data-testmation="createReferrer"
            margin="dense"
            inputRef={textFieldRef}
            error={error ? error.value : requireReferrer && !value}
            helperText={error?.message || ""}
            variant={isBookingWindow ? undefined : "outlined"}
            InputLabelProps={{ shrink: true }}
            sx={{
              width
            }}
            InputProps={
              isBookingWindow
                ? {
                    ...params.InputProps,
                    disableUnderline: true,
                    style: {
                      width: "200px",
                      fontSize: "12px"
                    }
                  }
                : params.InputProps
            }
          />
        )}
        renderOption={(ownProps, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...ownProps} key={option.id} style={{ fontSize: "12px" }}>
            {option?.referrer}
          </li>
        )}
        getOptionLabel={(option) => option.referrer || ""}
      />
    </Box>
  );
}

const mapStateToProps = (state): MapStateProps => ({
  referrers: state.referrers.referrers,
  isSubscribed: state?.userContext?.resourceCentre?.subscriptions?.features?.referrer?.subscribed
});

const mapDispatchToProps = (dispatch): MapDispatchProps => ({
  onCreate: ({ next, referrer }) =>
    dispatch(
      navigateAddModal({
        component: "ReferrerCreateModal",
        props: {
          next,
          defaultData: {
            referrer
          }
        }
      })
    ),
  getReferrers: () => dispatch(getReferrersAction())
});

ReferrerCreateSelect.defaultProps = {
  useSettings: false,
  textFieldProps: {},
  showLabel: false,
  referrerId: null,
  disabled: false,
  width: "210px",
  isFocusedInitially: false,
  textFieldWidth: "300px"
};

export default connect<MapStateProps, MapDispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ReferrerCreateSelect);

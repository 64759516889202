import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { RootState } from "../store/index";
import { Referrer } from "../interfaces/ReferrerInterface";
import { getReferrers } from "../actions/referrers";

const useReferrers = (): Referrer[] => {
  const dispatch = useDispatch();
  const referrers: Referrer[] = useSelector((state: RootState) => state.referrers.referrers);
  const hasReferrers = referrers.length > 0;
  React.useEffect(() => {
    if (!hasReferrers) {
      dispatch(getReferrers());
    }
  }, [hasReferrers, dispatch]);
  return referrers;
};
export default useReferrers;

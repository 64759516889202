import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography, Link } from "@mui/material";
import capitalize from "lodash/capitalize";
import { tl, t } from "../../components/translate";
import * as serviceActions from "../../actions/services";
import Filters from "./Filters";
import List, { EmptyView, ListActions, Menu, MenuItem, SORT_ORDER } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import * as reportsApi from "../../api/reports";
import { downloadExcel, downloadXML } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import BillShow from "../Billing/BillShow/BillShow";
import "./Reports.scss";
import { notificationAdd } from "../../actions/notification";
import { clientName } from "./AppointmentReport";
import Can from "../Policy/Can";
import { IThunkDispatch } from "../../store";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import {
  getDefaultKeyValuesColumns,
  serviceProviderChargeDefaultCols,
  serviceProviderChargeHiddenCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import { getCustomerNumber } from "../Client/ClientList";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const or = (val) => val || "";

const docColumns = () => [
  t("reports.customerNumber"),
  t("reports.client"),
  t("reports.clientFirstName"),
  t("reports.clientLastName"),
  t("reports.spName"),
  t("reports.serviceName"),
  t("reports.quantity"),
  t("reports.billingDateBS"),
  t("reports.billingDateAD"),
  t("reports.billNumber"),
  t("reports.productPrice"),
  t("reports.serviceProviderRate"),
  t("reports.discountPct"),
  t("reports.discountAmt"),
  t("reports.netAmount"),
  t("reports.materialCharge"),
  t("reports.labCharge"),
  t("reports.discountedSpPayableAfterMaterialCharge"),
  t("reports.discountedSpPayableAfterAllCharge"),
  t("reports.unDiscountedSpPayableAfterMaterialCharge"),
  t("reports.unDiscountedSpPayableAfterAllCharge")
];

const docRowProcessor = ({
  customerNumber,
  clientFirstName,
  clientLastName,
  billingDate,
  spName,
  serviceName,
  quantity,
  billInfo,
  productPrice,
  discountPct,
  discountAmt,
  netAmount,
  serviceProviderRate,
  materialCharge,
  labCharge,
  discountedSpPayableAfterMaterialCharge,
  discountedSpPayableAfterAllCharge,
  unDiscountedSpPayableAfterMaterialCharge,
  unDiscountedSpPayableAfterAllCharge,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    or(customerNumber),
    clientName({ clientFirstName, clientLastName }),
    or(clientFirstName),
    or(clientLastName),
    spName,
    serviceName,
    quantity,
    calFns.bsShortDate(billingDate),
    calFns.adShortDate(billingDate),
    billInfo.billNumber,
    productPrice,
    or(serviceProviderRate),
    Number(discountPct || 0).toFixed(2),
    Number(discountAmt || 0).toFixed(2),
    Number(netAmount || 0).toFixed(2),
    Number(materialCharge || 0).toFixed(2),
    Number(labCharge || 0).toFixed(2),
    Number(discountedSpPayableAfterMaterialCharge || 0).toFixed(2),
    Number(discountedSpPayableAfterAllCharge || 0).toFixed(2),
    Number(unDiscountedSpPayableAfterMaterialCharge || 0).toFixed(2),
    Number(unDiscountedSpPayableAfterAllCharge || 0).toFixed(2)
  ];
};

const selectData = (item) => ({
  billingDate: new Date(item.issueDate),
  spName: item.serviceProviderName
    .split(" ")
    .map((name) => capitalize(name))
    .join(" "),
  customerNumber: item.customerNumber,
  clientFirstName: item.clientFirstName,
  clientLastName: item.clientLastName,
  serviceName:
    item.packageName === "" ? item.serviceName : `${item.serviceName} (${item.packageName})`,
  quantity: item.quantity,
  serviceProviderRate: item.serviceProviderRate,
  productPrice: item.productPrice,
  discountPct: item.discountPct,
  discountAmt: item.discountAmt,
  netAmount: item.netAmount,
  billInfo: {
    billNumber: item.billNumber,
    billId: item.billId
  },
  materialCharge: item.materialCharge,
  labCharge: item.labCharge,
  discountedSpPayableAfterMaterialCharge: item.discountedSpPayableAfterMaterialCharge,
  discountedSpPayableAfterAllCharge: item.discountedSpPayableAfterAllCharge,
  unDiscountedSpPayableAfterMaterialCharge: item.unDiscountedSpPayableAfterMaterialCharge,
  unDiscountedSpPayableAfterAllCharge: item.unDiscountedSpPayableAfterAllCharge
});

const processData = (data) =>
  data
    .sort((a, b) => (new Date(a.issueDate) < new Date(b.issueDate) ? 1 : -1))
    .map((item) => selectData(item));

const ServiceProviderCharges = ({ services, serviceProviderId, actions }) => {
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    serviceProviderIds: serviceProviderId ? [serviceProviderId] : [],
    serviceIds: []
  });
  const [reportData, setReportData] = React.useState([]);
  React.useEffect(() => {
    if (!services || !services.length) {
      actions.loadServices();
    }
  }, []);
  React.useEffect(() => {
    reportsApi.serviceProviderChargesReport(filters).then((data) => {
      setReportData(processData(data));
    });
  }, [filters]);
  const applyFilters = (f) => {
    setFilters(f);
  };
  const [selectedBill, setSelectedBill] = React.useState(null);

  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(serviceProviderChargeDefaultCols, serviceProviderChargeHiddenCols)
  );
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:serviceProviderChargesReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1000px" : "auto"}>
          <Box margin="0px 32px">
            <Filters filters={filters} onSetFilters={(f) => applyFilters(f)} />
          </Box>

          <Box className="serviceProviderChargesReportList" marginTop="32px">
            <List
              automation="serviceProviderChargesReportList"
              data={reportData}
              rowHeight={40}
              defaultSortColumn="spName"
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              columns={[
                {
                  key: "spName",
                  label: tl("reports.spName"),
                  sortable: true,
                  sortFunction: (a, b, sortOrder): SORT_ORDER => {
                    if (a.spName === b.spName) {
                      return -1 * sortOrder * (new Date(a.from) > new Date(b.from) ? 1 : -1);
                    }
                    return or(a.spName).toLowerCase() > or(b.spName).toLowerCase() ? 1 : -1;
                  },
                  segmentable: true,
                  formatter: ({ spName }) => <Typography>{or(spName)}</Typography>,
                  segmentBy: (row) => row.spName
                },
                {
                  key: "serviceName",
                  label: tl("reports.serviceName"),
                  sortable: true,
                  sortFunction: (a, b) =>
                    or(a.serviceName).toLowerCase() > or(b.serviceName).toLowerCase() ? 1 : -1,
                  formatter: ({ serviceName }) => <Typography>{or(serviceName)}</Typography>
                },
                {
                  key: "quantity",
                  label: tl("reports.quantity"),
                  segmentable: true,
                  formatter: ({ quantity }) => <Typography>{or(quantity)}</Typography>
                },
                {
                  key: "billInfo",
                  label: tl("reports.billNumber"),
                  formatter: ({ billInfo }) => (
                    <Typography>
                      <Link onClick={() => setSelectedBill(billInfo.billId)}>
                        {billInfo.billNumber}
                      </Link>
                    </Typography>
                  )
                },
                {
                  key: "billingDate",
                  label: tl("reports.billingDate"),
                  formatter: ({ billingDate }) => (
                    <Typography>{calFns.bsFullDate(billingDate)}</Typography>
                  ),
                  sortable: true,
                  sortFunction: (a, b) =>
                    new Date(a.billingDate) > new Date(b.billingDate) ? 1 : -1
                },
                {
                  key: "discountedSpPayableAfterAllCharge",
                  label: tl("reports.discountedSpPayableAfterAllCharge"),
                  formatter: ({ discountedSpPayableAfterAllCharge }) => (
                    <Typography>
                      {discountedSpPayableAfterAllCharge
                        ? rupeeDisplay((discountedSpPayableAfterAllCharge || 0).toFixed(2))
                        : ""}
                    </Typography>
                  )
                },
                {
                  key: "unDiscountedSpPayableAfterAllCharge",
                  label: tl("reports.unDiscountedSpPayableAfterAllCharge"),
                  formatter: ({ unDiscountedSpPayableAfterAllCharge }) => (
                    <Typography>
                      {unDiscountedSpPayableAfterAllCharge
                        ? rupeeDisplay((unDiscountedSpPayableAfterAllCharge || 0).toFixed(2))
                        : ""}
                    </Typography>
                  )
                },
                {
                  key: "client",
                  label: "Client",
                  formatter: ({ clientFirstName, clientLastName, customerNumber }) => (
                    <Box display="flex" flexDirection="column">
                      <Typography>{`${clientFirstName} ${clientLastName}`}</Typography>
                      <Typography mt="-5px " fontSize="small" color="gray">
                        {getCustomerNumber(customerNumber)}
                      </Typography>
                    </Box>
                  )
                },
                {
                  key: "productPrice",
                  label: "Product Price",
                  formatter: ({ productPrice }) => <Typography>{or(productPrice)}</Typography>
                },
                {
                  key: "discountPct",
                  label: "Discount Pct",
                  formatter: ({ discountPct }) => <Typography>{or(discountPct)}</Typography>
                },
                {
                  key: "discountAmt",
                  label: "Dis Amt",
                  formatter: ({ discountAmt }) => <Typography>{or(discountAmt)}</Typography>
                },
                {
                  key: "netAmount",
                  label: "Net Amt",
                  formatter: ({ netAmount }) => <Typography>{or(netAmount)}</Typography>
                },

                {
                  key: "serviceProviderRate",
                  label: "Service Provider Rate",
                  formatter: ({ serviceProviderRate }) => (
                    <Typography>{or(serviceProviderRate)}</Typography>
                  )
                },
                {
                  key: "serviceProviderCharge",
                  label: "Service Provider Charge",
                  formatter: ({ serviceProviderCharge }) => (
                    <Typography>{or(serviceProviderCharge)}</Typography>
                  )
                },
                {
                  key: "discountedServiceProviderCharge",
                  label: "Discounted SP Charge",
                  formatter: ({ discountedServiceProviderCharge }) => (
                    <Typography>{or(discountedServiceProviderCharge)}</Typography>
                  )
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                  <Box display="flex" flexGrow={1} flexBasis={500}>
                    <Typography>
                      <Box
                        component="span"
                        // flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight="500"
                      >
                        {acc.segment || "Clinic"}
                      </Box>
                    </Typography>
                  </Box>
                  {listColumns?.quantity && (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexBasis="590px"
                      paddingRight="30px"
                      justifyContent="flex-start"
                    >
                      <Typography>
                        <Box component="span">
                          {acc.rows.reduce((ac, cur) => ac + cur.quantity, 0) || 0}
                        </Box>
                      </Typography>
                    </Box>
                  )}
                  {listColumns?.discountedSpPayableAfterAllCharge && (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexBasis="210px"
                      justifyContent="flex-start"
                      paddingRight="40px"
                    >
                      <Typography>
                        <Box component="span">
                          {rupeeDisplay(
                            acc.rows
                              // eslint-disable-next-line max-len
                              .reduce(
                                (ac, cur) => ac + (cur.discountedSpPayableAfterAllCharge || 0),
                                0
                              )
                              .toFixed(2) || 0
                          )}
                        </Box>
                      </Typography>
                    </Box>
                  )}

                  {listColumns?.unDiscountedSpPayableAfterAllCharge && (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexBasis="250px"
                      justifyContent="flex-end"
                      paddingRight="40px"
                    >
                      <Typography>
                        <Box component="span">
                          {rupeeDisplay(
                            acc.rows
                              // eslint-disable-next-line max-len
                              .reduce(
                                (ac, cur) => ac + (cur.unDiscountedSpPayableAfterAllCharge || 0),
                                0
                              )
                              .toFixed(2) || 0
                          )}
                        </Box>
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            `${t("reports.serviceProviderChargesReport")}-${
                              calFns.convertADtoBS(filters.from).formatted
                            }-${calFns.convertADtoBS(filters.until).formatted}`,
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        reportsApi
                          .getServiceProviderChargesTally(filters)
                          .then((response) => {
                            downloadXML(
                              `${t("reports.serviceProviderChargesReport")}-${
                                calFns.convertADtoBS(filters.from).formatted
                              }-${calFns.convertADtoBS(filters.until).formatted}`,
                              (response as { data: unknown; status: number }).data
                            );
                            actions.addNotification(
                              (response as { data: unknown; status: number }).status
                            );
                          })
                          .catch(() => actions.addNotification(500))
                      }
                    >
                      {tl("reports.tally")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["spName"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
        </Box>

        {selectedBill && (
          <>
            <BillShow
              billId={selectedBill}
              handleViewClose={() => setSelectedBill(null)}
              hideActions
            />
          </>
        )}
      </Box>
    </Can>
  );
};

const mapStateToProps = (state) => {
  const { mode } = state.userContext;
  const serviceProviderId =
    mode === "serviceProvider" ? state.userContext.userCreds.authenticableId : null;

  return {
    services: state.services.collection,
    serviceProviderId
  };
};

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  actions: {
    loadServices: () => dispatch(serviceActions.getServices()),
    navigateTo: (url) => dispatch(push(url)),
    addNotification: (statusCode) => {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: statusCode === 200 ? "success" : "error",
          message:
            statusCode === 200 ? tl("reports.downloadSuccess") : tl("reports.downloadFailure"),
          autoTimeout: true
        })
      );
    }
  }
}))(ServiceProviderCharges);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserGroupsType, getUserGroups, patchUserGroup, postUserGroups } from "../api/userGroups";
import { RootState } from "../store/index";
import { UserGroupForm } from "../containers/UserGroupPolicy/UserGroupCreateEdit";
import { notificationAdd } from "../actions/notification";

export const fetchUserGroups = createAsyncThunk("userGroups", async (_, { rejectWithValue }) => {
  try {
    const response = await getUserGroups();
    return response as UserGroupsType[];
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUserGroup = createAsyncThunk(
  "userGroups/postUserGroup",
  async (data: UserGroupForm, { rejectWithValue, dispatch }) => {
    try {
      const response = await postUserGroups(data);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "User Group Created Successfully.",
          autoTimeout: true
        })
      );
      return response as UserGroupsType;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to create user group",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const updateUserGroup = createAsyncThunk(
  "userGroups/patchUserGroup",
  async (data: Partial<UserGroupForm>, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchUserGroup(data);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "User Group Updated Successfully.",
          autoTimeout: true
        })
      );
      return response as UserGroupsType;
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to create user group",
          autoTimeout: true
        })
      );
      return rejectWithValue(err);
    }
  }
);

const userGroupSlice = createSlice({
  name: "userGroups",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserGroups.fulfilled, (_, { payload }) => payload);
    builder.addCase(
      createUserGroup.fulfilled,
      (state: RootState, { payload }: { payload: UserGroupsType }) => {
        state.unshift(payload as UserGroupsType);
      }
    );
    builder.addCase(
      updateUserGroup.fulfilled,
      (state: RootState, { payload }: { payload: UserGroupsType }) => {
        const index = state.findIndex((userGroup) => userGroup.id === payload.id);
        // eslint-disable-next-line no-param-reassign
        if (index !== -1) state[index] = payload;
      }
    );
  }
});

export default userGroupSlice.reducer;

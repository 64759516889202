import React from "react";
import { makeStyles } from "@mui/styles";
import { HmisSummary } from "../../../interfaces/HmisInterface";
import { tl } from "../../../components/translate";

interface SummaryDataInterface {
  ageGroup: string;
  recentMale: number;
  recentFemale: number;
  totalMale: number;
  totalFemale: number;
}

const getValue = (val: number | undefined): number => val || 0;

const formatData = (summaryData: HmisSummary): SummaryDataInterface[] =>
  summaryData?.displayData?.map((item) => ({
    ageGroup: item.ageGroup || "",
    recentMale: getValue(item?.recent?.male),
    recentFemale: getValue(item?.recent?.female),
    totalMale: getValue(item?.total?.male),
    totalFemale: getValue(item?.total?.female)
  }));

const rows = ["ageGroup", "recentMale", "recentFemale", "totalMale", "totalFemale"];
const mainHeader = ["ageGroup", "newClients", "totalNewAndOldClient"];
const subHeader = ["clients.gender.1", "clients.gender.2", "clients.gender.1", "clients.gender.2"];

const useStyles = makeStyles({
  style: {
    borderCollapse: "collapse",
    padding: "10px",
    border: ".5px solid gray"
  }
});

export default function HmisTableDetails({ data }: { data: HmisSummary }): JSX.Element {
  const hmisData = React.useMemo(() => formatData(data), [data]);
  const classes = useStyles();
  return (
    <div>
      {hmisData?.length > 0 ? (
        <table className={classes.style}>
          <tr>
            {mainHeader.map((item, index) => (
              <th
                className={classes.style}
                key={item}
                rowSpan={index === 0 ? 2 : 1}
                colSpan={index !== 0 ? 2 : 1}
              >
                {tl(`${item}`)}
              </th>
            ))}
          </tr>
          <tr>
            {subHeader.map((item, index) => (
              <th
                className={classes.style}
                // eslint-disable-next-line react/no-array-index-key
                key={`${item}-${index}`}
              >
                {tl(`${item}`)}
              </th>
            ))}
          </tr>
          {hmisData?.map((item) => (
            <tr className={classes.style} key={item.ageGroup}>
              {rows.map((cl) => (
                <td className={classes.style} key={cl}>
                  {item[cl]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      ) : (
        "No Data Found"
      )}
    </div>
  );
}

import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import produce from "immer";
import { tl } from "../../../components/translate";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { useFiscalPeriod } from "../../../hooks/query/useFiscalPeriods";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { resourceCentreActions } from "../../../actions";
import useAccountingVoucherSeries from "../../accounts/AccountingVoucherSeries/hooks/useAccountingVoucherSeries";
import { VoucherCodes } from "../../../interfaces/Accounts";
import { notificationAdd } from "../../../actions/notification";

export interface Settings {
  customPrefix: string;
  startFrom: number;
  digit: number;
}

const makeLabTestNumber = (year, settings, slPrefix) =>
  `${convertADtoBS(year ? new Date(year) : new Date()).bsYear}-${
    settings.customPrefix || slPrefix
  }-${`${settings.startFrom}`.padStart(settings.digit, "0")}`;

function LabTestNumberSettings({
  resourceCentre
}: {
  resourceCentre: ResourceCentre;
}): JSX.Element {
  const [testNumberSettings, setTestNumberSettings] = React.useState({
    customPrefix: resourceCentre.labSettings.customPrefix,
    digit: resourceCentre.labSettings.digit,
    startFrom: resourceCentre.labSettings.startFrom
  });
  const { data: fiscalPeriods } = useFiscalPeriod(resourceCentre.id);
  const sortedFiscalPeriods = fiscalPeriods?.sort((a, b) => a.id - b.id)[0];
  const dispatch = useDispatch();
  const voucherData = useAccountingVoucherSeries();

  // will be using SL custom prefix if nothing is set in customPrefix of lab settings
  const slPrefix = voucherData.find(
    (voucher) => voucher.voucherCode === VoucherCodes.SL
  )?.customPrefix;
  const onSave = () => {
    dispatch(
      resourceCentreActions.putResourceCentre(
        produce(resourceCentre, (draft) => {
          draft.labSettings.startFrom = testNumberSettings.startFrom;
          draft.labSettings.customPrefix = testNumberSettings.customPrefix;
          draft.labSettings.digit = testNumberSettings.digit;
        })
      )
    );
  };

  return (
    <Box mt={1}>
      <Typography style={{ fontWeight: 600, marginTop: "10px" }}>
        {tl("clinicSettings.labTestNumber")}
      </Typography>
      <Typography style={{ fontWeight: 400, marginTop: "10px" }}>
        Note*: If current settings is already used for previous lab records, new settings will be
        implemented from next fiscal year only.
      </Typography>
      <Box display="flex" alignItems="center" maxWidth="25vw" mt="8px">
        <Typography sx={{ width: "160px" }}>Custom Prefix</Typography>
        <TextField
          style={{
            width: "100%"
          }}
          inputProps={{
            maxLength: 4
          }}
          variant="outlined"
          value={testNumberSettings?.customPrefix || slPrefix}
          onChange={(e) => {
            setTestNumberSettings({
              ...testNumberSettings,
              customPrefix: e.target.value
            });
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" maxWidth="25vw" mt="8px">
        <Typography sx={{ width: "160px" }}>Digit</Typography>
        <TextField
          type="number"
          style={{
            width: "100%"
          }}
          variant="outlined"
          value={testNumberSettings?.digit}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (Number.isNaN(value) || value < 0 || value > 9) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Digit can be from 1 to 9 only",
                  autoTimeout: true
                })
              );
              return;
            }
            setTestNumberSettings({
              ...testNumberSettings,
              digit: value
            });
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" maxWidth="25vw" mt="8px">
        <Typography sx={{ width: "160px" }}>Start From</Typography>
        <TextField
          type="number"
          style={{
            width: "100%"
          }}
          variant="outlined"
          value={testNumberSettings?.startFrom}
          onChange={(e) => {
            if (e.target.value.length > 9) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Starting number length must be less than 10",
                  autoTimeout: true
                })
              );
              return;
            }
            setTestNumberSettings({
              ...testNumberSettings,
              startFrom: Number(e.target.value)
            });
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" maxWidth="50vw" mt="8px">
        <Typography sx={{ width: "160px" }}>Preview</Typography>
        <TextField
          disabled
          sx={{
            width: "100%",
            border: "none",
            "& fieldset": { border: "none" }
          }}
          value={makeLabTestNumber(sortedFiscalPeriods?.from, testNumberSettings, slPrefix)}
          variant="outlined"
        />
      </Box>
      <Button
        disabled={!testNumberSettings?.digit || !testNumberSettings?.startFrom}
        onClick={() => {
          onSave();
        }}
        variant="contained"
      >
        Save
      </Button>
    </Box>
  );
}

export default LabTestNumberSettings;

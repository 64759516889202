import { actionCreatorAsync } from './actionHelpers';
import * as api from '../api/template';
import { push } from 'connected-react-router';

export enum Type {
  TEMPLATES_GET = 'TEMPLATES_GET',
  TEMPLATE_GET = 'TEMPLATE_GET',
  TEMPLATE_UPDATE = 'TEMPLATE_UPDATE',
  TEMPLATE_CREATE = 'TEMPLATE_CREATE',
  TEMPLATE_DELETE = 'TEMPLATE_DELETE',
}

export const getTemplates = () => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.TEMPLATES_GET, async () => {
        return api.getBatchTemplate();
      })
    );
  };
};

export const getTemplateById = (id, next?) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.TEMPLATE_GET, async () => {
        return api.getTemplateDetails(id, next);
      })
    );
  };
};

export const saveTemplate = (templateData, stayOnPage = false) => {
  return async (dispatch) => {
    if (templateData.id) {
      await dispatch(
        actionCreatorAsync(Type.TEMPLATE_UPDATE, async () => {
          const data = await api.updateTemplate(templateData.id, templateData);
          return data;
        })
      );
    } else {
      await dispatch(
        actionCreatorAsync(Type.TEMPLATE_CREATE, async () => {
          const data = await api.createTemplate(templateData);
          if (data.id && !stayOnPage)
            dispatch(push(`campaign/templates/${data.id}`));
          return data;
        })
      );
    }
  };
};

export const deleteTemplate = (id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.TEMPLATE_DELETE, async () => {
        await api.deleteTemplate(id);
        return id;
      })
    );
  };
};

import React from "react";

export const MouthWash = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 511.999 511.999"
    >
      <g>
        <g>
          <path
            d="M373.531,221.236c1.394-1.393,2.202-3.344,2.202-5.314v-80.157c0-3.246-2.187-6.216-5.285-7.178l-66.856-22.286V95.186
			h8.517c4.38,0,7.914-3.904,7.478-8.262l-8.016-80.157C311.187,2.926,307.954,0,304.093,0h-96.188
			c-3.861,0-7.094,2.926-7.478,6.767l-8.016,80.157c-0.436,4.359,3.098,8.262,7.478,8.262h8.517v11.116l-66.856,22.284
			c-3.098,0.963-5.285,3.932-5.285,7.178v80.157c0,1.971,0.809,3.922,2.202,5.314l21.845,21.846v122.025l-21.845,21.845
			c-1.395,1.393-2.202,3.345-2.202,5.314v80.157c0,1.608,0.54,3.221,1.503,4.509l24.047,32.063c1.42,1.893,3.646,3.006,6.012,3.006
			h176.344c2.366,0,4.592-1.113,6.012-3.006l24.047-32.063c0.963-1.287,1.503-2.901,1.503-4.509v-80.157
			c0-1.97-0.809-3.923-2.202-5.314l-21.845-21.844V243.081L373.531,221.236z M297.292,15.029l6.513,65.127h-16.267
			c0.029-0.437-7.112-65.127-7.112-65.127H297.292z M246.768,15.029h18.536l7.236,65.127h-32.285L246.768,15.029z M208.194,80.157
			l6.513-65.127h16.958l-6.513,65.127H208.194z M218.298,118.847c3.069-1.023,5.138-3.895,5.138-7.129V95.186h65.127v16.532
			c0,3.234,2.07,6.106,5.138,7.129l28.21,9.403H190.088L218.298,118.847z M340.414,496.971h-168.83l-12.775-17.033h194.38
			L340.414,496.971z M360.704,399.781v65.127H151.295v-65.127h141.109c4.15,0,7.515-3.365,7.515-7.515
			c0-4.15-3.365-7.515-7.515-7.515H161.922l11.219-11.219c1.41-1.409,2.201-3.32,2.201-5.313v-128.25
			c0-1.993-0.792-3.905-2.201-5.313l-11.219-11.219h188.154l-11.219,11.219c-1.41,1.409-2.201,3.32-2.201,5.313v128.25
			c0,1.993,0.792,3.905,2.201,5.313l11.22,11.219H328.14c-4.15,0-7.515,3.365-7.515,7.515c0,4.15,3.365,7.515,7.515,7.515H360.704z
			 M151.295,208.407V143.28h209.409v65.127H151.295z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M298.129,304.844c4.672-5.302,11.734-13.317,11.734-32.118c0-18.024-15.186-31.616-35.324-31.616
			c-6.576,0-13.025,2.287-18.486,6.438c-5.478-4.15-11.962-6.438-18.593-6.438c-20.138,0-35.324,13.592-35.324,31.616
			c0,18.8,7.062,26.814,11.735,32.118c2.931,3.327,2.931,3.327,1.917,5.559c-8.455,18.599-3.679,42.914,3.018,53.315
			c3.921,6.088,8.275,7.365,11.239,7.365c8.622,0,10.938-9.046,13.391-18.623c2.991-11.68,6.202-22.163,12.565-22.163
			s9.574,10.483,12.565,22.163c2.452,9.578,4.768,18.623,13.391,18.623c2.964,0,7.319-1.278,11.239-7.365
			c6.697-10.401,11.472-34.716,3.018-53.315C295.197,308.17,295.197,308.17,298.129,304.844z M286.852,294.909
			c-3.951,4.484-9.362,10.625-4.321,21.714c4.912,10.805,3.364,24.428,0.766,32.78c-0.059-0.227-0.116-0.451-0.171-0.669
			c-3.205-12.516-8.569-33.464-27.124-33.464s-23.919,20.949-27.124,33.464c-0.056,0.218-0.113,0.442-0.171,0.669
			c-2.598-8.352-4.146-21.975,0.766-32.78c5.04-11.089-0.371-17.231-4.321-21.714c-3.741-4.246-7.983-9.06-7.983-22.182
			c0-9.611,8.536-16.586,20.295-16.586c4.612,0,9.404,2.452,12.819,6.559c2.958,3.558,8.597,3.558,11.556,0
			c3.467-4.169,8.098-6.56,12.704-6.56c11.759,0,20.295,6.976,20.295,16.586C294.833,285.85,290.593,290.663,286.852,294.909z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import * as moment from "moment/moment";
import { useEffect, useRef, useState } from "react";

interface UseElapsedTimeReturn {
  formattedTime: string;
  startTimer: () => void;
  stopTimer: () => void;
  elapsedTime: number;
  isStarted: boolean;
  setElapsedTime: (seconds: number) => void;
}

export interface UserEnteredTimeFormat {
  hour: number;
  minute: number;
  second: number;
}
export function convertToHourMinuteSecondObj(seconds: number): {
  hour: number;
  minute: number;
  second: number;
} {
  const hour = Math.floor(seconds / 3600);
  const remainingSec = seconds % 3600;
  const minute = Math.floor(remainingSec / 60);
  const second = Math.floor(remainingSec % 60);
  return { hour, minute, second };
}

export function formatToHourMinuteSecondString(
  seconds: number,
  withoutHour: boolean = false
): string {
  const { hour, minute, second } = convertToHourMinuteSecondObj(seconds);
  if (withoutHour)
    return `${minute.toString().padStart(2, "0")}
  : ${second.toString().padStart(2, "0")}`;

  return `${hour.toString().padStart(2, "0")} 
  : ${minute.toString().padStart(2, "0")}
  : ${second.toString().padStart(2, "0")}`;
}

export function userEnteredTimeObjToSeconds(obj: UserEnteredTimeFormat): number {
  return Number(obj.hour) * 3600 + Number(obj.minute) * 60 + Number(obj.second);
}

// initialTime in milliseconds
export default function useElapsedTime(
  initialTime: number | null,
  startTime = moment()
): UseElapsedTimeReturn {
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [time, setTime] = useState(Math.floor((initialTime || moment().diff(startTime)) / 1000));
  const [formattedTime, setFormattedTime] = useState(formatToHourMinuteSecondString(time));
  const [isStarted, setIsStarted] = useState(false);

  const startTimer = () => {
    if (!intervalRef.current) {
      setIsStarted(true);
      intervalRef.current = setInterval(() => {
        const timeDiff = Math.floor(moment().diff(startTime) / 1000);
        setTime(timeDiff);
        setFormattedTime(formatToHourMinuteSecondString(timeDiff));
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = null;
    setIsStarted(false);
  };

  // setElapsedTime is only called when user manually enters a time
  const setElapsedTime = (seconds: number) => {
    setTime(seconds);
    setFormattedTime(formatToHourMinuteSecondString(seconds));
    stopTimer();
  };

  useEffect(() => () => stopTimer(), []);

  return { formattedTime, startTimer, stopTimer, elapsedTime: time, isStarted, setElapsedTime };
}

import { Box, InputAdornment, Typography, TextField } from "@mui/material";
import { round } from "mathjs";
import React from "react";
import { VendorCommission, Source } from "../../../../interfaces/Commission";

export default function ReferrerCommissionRow({
  item,
  onChange,
  index,
  isSaved = false
}: {
  item: VendorCommission;
  onChange: (item: VendorCommission, changer?: string) => void;
  index: number;
  isSaved: boolean;
}): JSX.Element {
  const changeHandler = ({ target }) => {
    if (target.name === "rateAmt") {
      onChange({ ...item, [target.name]: Number(target.value) }, "rateAmt");
    } else {
      onChange({ ...item, [target.name]: Number(target.value) });
    }
  };

  return (
    <Box
      borderTop={`${index !== 0 ? "0.5px solid #c1bdbd" : ""}`}
      mb="10px"
      gap={2}
      pt="30px"
      display="flex"
      alignItems="center"
    >
      <TextField
        sx={{ minWidth: "200px" }}
        value={item.description}
        variant="outlined"
        label={item.source === Source.SERVICE ? "Service" : "Product"}
        disabled
      />
      <TextField
        sx={{ flexGrow: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography color="gray">Rs</Typography>
            </InputAdornment>
          )
        }}
        value={round(item.netAmount || 0, 2)}
        variant="outlined"
        label="Total Amt"
        disabled
      />
      <TextField
        type="number"
        sx={{ flexGrow: 1 }}
        onChange={changeHandler}
        onFocus={(e) => e.target.select()}
        value={round(item.allocatedAmt || 0, 2)}
        variant="outlined"
        label="Allocated Amt"
        name="allocatedAmt"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography color="gray">Rs</Typography>
            </InputAdornment>
          )
        }}
        disabled={isSaved}
      />
      <TextField
        type="number"
        sx={{ flexGrow: 1 }}
        onChange={changeHandler}
        onFocus={(e) => e.target.select()}
        value={round(item.materialChargePercent || 0, 2)}
        variant="outlined"
        label="Material Charge (%)"
        name="materialChargePercent"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color="gray">%</Typography>
            </InputAdornment>
          )
        }}
        disabled={isSaved}
      />
      <TextField
        type="number"
        sx={{ flexGrow: 1 }}
        onFocus={(e) => e.target.select()}
        name="labCharge"
        onChange={changeHandler}
        value={round(item.labCharge || 0, 2)}
        variant="outlined"
        label="Lab Charge"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography color="gray">Rs</Typography>
            </InputAdornment>
          )
        }}
        disabled={isSaved}
      />
      <TextField
        type="number"
        sx={{ flexGrow: 1 }}
        onFocus={(e) => e.target.select()}
        name="rate"
        onChange={changeHandler}
        value={round(item.rate || 0, 2)}
        variant="outlined"
        label="Rate (%)"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color="gray">%</Typography>
            </InputAdornment>
          )
        }}
        disabled={isSaved}
      />
      <TextField
        type="number"
        sx={{ flexGrow: 1 }}
        onFocus={(e) => e.target.select()}
        name="rateAmt"
        onChange={changeHandler}
        value={round(item.rateAmt || 0, 2)}
        variant="outlined"
        label="Rate (Amt)"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography color="gray">Rs</Typography>
            </InputAdornment>
          )
        }}
        disabled={isSaved}
      />
      <TextField
        type="number"
        sx={{ flexGrow: 1 }}
        onFocus={(e) => e.target.select()}
        name="tds"
        onChange={changeHandler}
        value={round(item.tds || 0, 2)}
        variant="outlined"
        label="TDS"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color="gray">%</Typography>
            </InputAdornment>
          )
        }}
        disabled={isSaved}
      />
    </Box>
  );
}

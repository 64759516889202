import React from "react";

export const Capsules = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 380.738 380.739"
    >
      <g>
        <path
          d="M345.698,35.031C302.871-7.818,237.54-11.92,199.79,25.829L96.977,128.655l-71.141,71.129
             c-37.75,37.761-33.637,103.082,9.19,145.943c42.838,42.826,108.17,46.928,145.919,9.189l71.129-71.141l102.825-102.814
             C392.66,143.201,388.536,77.87,345.698,35.031z M162.61,336.559c-27.583,27.583-76.578,23.447-109.227-9.19
             c-32.637-32.648-36.773-81.633-9.19-109.228l71.129-71.129L233.74,265.43L162.61,336.559z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

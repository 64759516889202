import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";
import { startCase } from "lodash";
import { Entity } from "./Filters";
import useCan from "../../hooks/useCan";

interface Props {
  onChange: (value: string | null) => void;
  value: string | null;
  isReminderReport: boolean;
  entityOptions: string[];
}

export default function ModuleSelect({
  onChange,
  value,
  isReminderReport,
  entityOptions = []
}: Props): JSX.Element {
  const isSuperAdmin = useCan("deny", { superAdminPass: true, supportAccountAdminPass: true });

  return (
    <Autocomplete
      value={value}
      onChange={(_, val) => onChange(val)}
      options={
        entityOptions.length
          ? entityOptions
          : Object.values(Entity).filter((entity) => {
              if (isSuperAdmin) return true;

              if (isReminderReport) {
                return [Entity.BOOKING, Entity.ASSESSMENT].includes(entity);
              }
              return entity !== Entity.RESOURCE_CENTRE;
            })
      }
      getOptionLabel={(option) => startCase(option)}
      renderOption={(renderProps, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...renderProps} key={option}>
          <Typography>{startCase(option)}</Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          //   eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Select Module"
        />
      )}
    />
  );
}

import { ReducerBuilder } from './ReducerBuilder';
import * as ReferrersActions from '../actions/referrers';
import produce from 'immer';

interface State {
  referrers: Array<any>;
}

const INITIAL_STATE: State = {
  referrers: []
};

function getReferrers(state, payload) {
  return { ...state, referrers: payload };
}

function postReferrer(state, payload) {
  const newState = produce(state as State, (state) => {
    state.referrers.push(payload);
  });
  return newState;
}

function patchReferrer(state, payload) {
  const newState = produce(state as State, (state) => {
    state.referrers = state.referrers.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  });
  return newState;
}

function deleteReferrer(state, payload) {
  const newState = produce(state as State, (state) => {
    state.referrers = state.referrers.filter((item) => item.id !== payload);
  });
  return newState;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(ReferrersActions.Type.GET_REFERRERS, getReferrers)
  .mapAction(ReferrersActions.Type.POST_REFERRER, postReferrer)
  .mapAction(ReferrersActions.Type.PATCH_REFERRER, patchReferrer)
  .mapAction(ReferrersActions.Type.DELETE_REFERRER, deleteReferrer)
  .build();
export default reducer;

export enum DaybookTransactionEnum {
  EXPENSE = "expense",
  INCOME = "income"
}

export enum DaybookFormMode {
  CREATE = "create",
  EDIT = "edit"
}

export const DaybookParticulars = {
  OPENING_BALANCE: "opening balance",
  CLOSING_BALANCE: "closing balance"
};

import { createSelector } from 'reselect';
import startCase from 'lodash/startCase';

import { ReducerBuilder } from './ReducerBuilder';
import * as TemplateActions from '../actions/template';

const INITIAL_STATE = {
  collection: [],
  lastTouched: null
};

function setTemplates(state, payload) {
  return { ...state, collection: payload || [] };
}

function setTemplate(state, payload) {
  const newCollection = [...state.collection].filter(({ id }) => id !== payload.id);
  newCollection.push(payload);
  return { ...state, collection: newCollection, lastTouched: payload };
}

function removeTemplate(state, id) {
  const newCollection = [...state.collection].filter((template) => template.id !== id);
  return { ...state, collection: newCollection };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(TemplateActions.Type.TEMPLATES_GET, setTemplates)
  .mapAction(TemplateActions.Type.TEMPLATE_GET, setTemplate)
  .mapAction(TemplateActions.Type.TEMPLATE_CREATE, setTemplate)
  .mapAction(TemplateActions.Type.TEMPLATE_UPDATE, setTemplate)
  .mapAction(TemplateActions.Type.TEMPLATE_DELETE, removeTemplate)
  .build();
export default reducer;

export const templatesSelector = (state) => state.template.collection;

import React from "react";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Zoom from "@mui/material/Grow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styles from "./styles.module.css";

interface PropsType {
  open?: boolean;
  anchorEl: () => any;
  onClose?: (e) => void;
  popperZIndex?: number;
}

export default class OPoppoer extends React.Component<PropsType> {
  public render(): JSX.Element {
    const { open = true, anchorEl, children, onClose, popperZIndex = 1299 } = this.props;
    const panelElement = document.getElementById("portal");
    return (
      <ClickAwayListener
        onClickAway={(e: any) => {
          // https://github.com/mui-org/material-ui/issues/12034
          // https://github.com/mui-org/material-ui/issues/18586
          // check body (fallsback to body if doesnt find node), check select options, check panel
          // if not 3 of the above then close
          if (e.target.nodeName === "BODY" && e.type === "click") {
            return;
          }
          if (e.target.id?.includes("unqxsmi")) return;
          if (panelElement.contains(e.target)) return;
          // eslint-disable-next-line no-unused-expressions
          onClose && onClose(e);
        }}
      >
        <Popper
          style={{ zIndex: popperZIndex }}
          open={open}
          anchorEl={() => anchorEl().current}
          placement="right-start"
          transition
          popperOptions={{
            modifiers: [
              {
                name: "computeStyles",
                options: {
                  adaptive: false
                }
              },
              {
                name: "preventOverflow",
                options: {
                  mainAxis: true,
                  altAxis: true
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Zoom {...TransitionProps} timeout={150}>
              <Paper className={styles.paper}>{children}</Paper>
            </Zoom>
          )}
        </Popper>
      </ClickAwayListener>
    );
  }
}

OPoppoer.defaultProps = {
  open: false,
  onClose: () => null
};

import { ReverseMap } from "../helpers/types";

export interface SubscriptionFeatures {
  bill: { default: true; subscribed: boolean };
  booking: { subscribed: boolean };
  campaign: { subscribed: boolean };
  choiceModules: number;
  clients: { limit: number; default: true; nonModule: true; subscribed: true };
  clinic: { default: true; nonModule: true; subscribed: true };
  doctors: { subscribed: boolean };
  hmis: { subscribed: boolean };
  ipd: { subscribed: boolean };
  lab: { subscribed: boolean };
  medical: { subscribed: boolean };
  report: { default: true; subscribed: boolean };
  services: { subscribed: boolean };
  stock: { subscribed: boolean };
  users: { limit: number; default: true; nonModule: true; subscribed: true };
}

export enum PackageTypes {
  Starter = "Starter",
  GrowthLite = "Growth Lite",
  GrowthPlus = "Growth Plus",
  Custom = "Custom"
}

export enum PaymentTypes {
  Monthly = "monthly",
  Quarterly = "quarterly",
  Yearly = "yearly"
}

export enum SUBSCRIPTION_MODE {
  ALL = "all",
  TRIAL = "trial",
  TEST = "test",
  PRODUCTION = "production"
}

export type SubscriptionModeType = ReverseMap<typeof SUBSCRIPTION_MODE>;

export interface Subscription {
  activeSubscription: boolean;
  createdBy: null | number;
  isTrial: boolean;
  isTest: boolean;
  // eslint-disable-next-line camelcase
  created_at: string;
  features: SubscriptionFeatures;
  id: number;
  initialAmount: number;
  isCustomised: boolean;
  isTemplate: boolean;
  notes: string;
  packageBasedOn: null | number;
  packageName: string;
  packageType: PackageTypes;
  paid: boolean;
  paymentPrice: number;
  paymentType: PaymentTypes;
  resourceCentreId: number;
  subscriptionEnd: string;
  subscriptionMonthlyPrice: number;
  subscriptionStart: string;
  subscriptionYearlyPrice: number;
  trialUntil: string;
  requireInvoiceVerification: boolean;
  salesPerson: string;
  leadBy: string;
  mouLink: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
  migrationDate: string;
  statusUpdatedAt?: string;
  isInvoicePaused?: boolean;
}

export enum SubscriptionInvoiceEntity {
  SMS = "SMS",
  SUBSCRIPTION = "SUBSCRIPTION"
}

export enum SubscriptionInvoiceCurrencies {
  NPR = "NPR",
  USD = "USD"
}

export enum SubscriptionInvoiceStatus {
  paid = "paid",
  unpaid = "unpaid",
  cancelled = "cancelled",
  pendingApproval = "pendingApproval",
  unverified = "unverified"
}

export interface SubscriptionInvoices {
  // eslint-disable-next-line camelcase
  created_at: string;
  id: number;
  invoiceNumber: string;
  invoiceForEntity: SubscriptionInvoiceEntity;
  forEntityId: number;
  relatedEntityDetails: Record<string, any>;
  invoiceAmount: number;
  currency: SubscriptionInvoiceCurrencies;
  status: SubscriptionInvoiceStatus;
  paymentId: number;
  resourceCentreId: number;
}

export interface SubscriptionInvoice {
  // eslint-disable-next-line camelcase
  created_at: string;
  id: number;
  notes: string;
  paidAmount: number;
  paymentMethod: string;
  paymentType: string;
  resourceCentreId: number;
  status: string;
  subscriptionId: number;
  totalAmount: number;
  // eslint-disable-next-line camelcase
  updated_at: string;
}

export interface SubscriptionHistory {
  // eslint-disable-next-line camelcase
  created_at: string;
  entity: "subscription";
  entityId: number;
  event: string;
  id: number;
  infoData: null;
  message: null;
  resourceCentreId: number;
  // eslint-disable-next-line camelcase
  updated_at: string;
  userId: number;
  username: string;
}

export interface SmsHistory {
  id: number;
  entity: string;
  event: string;
  userId: number;
  username: string;
  message: string;
  // eslint-disable-next-line camelcase
  created_at: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
  entityId: number;
  infoData: {
    data: {
      subscribed: boolean;
      volumeLeft: number;
    };
    info: string;
    userId: number;
    volume: number;
  };
  resourceCentreId: number;
  clientId: number;
}

export interface PendingInvoice {
  id: number;
  expiresOn: Date;
  hasExpired: boolean;
  severity: string;
  invoiceAmount: number;
  paymentType: string;
  otherDetails: string;
}

export interface KhaltiVerificationPayload {
  // eslint-disable-next-line camelcase
  purchase_order_id: string;
  pidx: string;
  provider: string;
}

export interface SubscriptionPaymentVerificationResponse {
  status: "paid" | "unpaid";
  // eslint-disable-next-line camelcase
  updated_at: string;
  id: number;
  invoiceNumber: string;
  invoiceForEntity: string;
  forEntityId: number;
  relatedEntityDetails: {
    quantity: number;
    invoiceAmount: number;
  };
  invoiceAmount: string;
  currency: string;
  paymentId: number;
  resourceCentreId: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  payment: {
    id: number;
    productId: number;
    productEntity: string;
    paidForEntityId: number;
    serviceProviderId: number;
    provider: string;
    transactionId: string;
    processId: number;
    referenceId: number;
    bank: string;
    amount: number;
    processingAmount: string;
    trxStatus: string;
    remarks: {
      fee: number;
      pidx: string;
      status: string;
      refunded: boolean;
      // eslint-disable-next-line camelcase
      total_amount: number;
      // eslint-disable-next-line camelcase
      transaction_id: string;
    };
    description: string;
    trxDateTime: string;
    resourceCentreId: number;
    // eslint-disable-next-line camelcase
    created_at: string;
    // eslint-disable-next-line camelcase
    updated_at: string;
    validationStatusCode: string;
    validationMsg: string;
    verificationStatusCode: string;
    verificationMsg: string;
    processAmountCurrency: string;
    stripeIntentId: number;
    bookableResourceId: number;
    paidForEntity: string;
    refundRequested: string;
    refunded: string;
    details: string;
    providerTransactionId: string;
  };
}

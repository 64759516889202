import { chain, round } from "mathjs";
import {
  BillDataInterface,
  BillDocumentProps,
  VendorCommission,
  VendorCommissionBill
} from "../../../../interfaces/Commission";

export const findRateAmt = (item: VendorCommission): VendorCommission => {
  const amountAfterMaterialCharge = chain(1 - item.materialChargePercent / 100)
    .multiply(item.allocatedAmt)
    .done();
  const amountAfterLabCharge = Number(amountAfterMaterialCharge) - item.labCharge;
  const rateAmt = chain(item.rate * amountAfterLabCharge)
    .divide(100)
    .done();
  return { ...item, rateAmt };
};

export const findReferralPayableAmt = (
  item: VendorCommission,
  changer?: string
): VendorCommission => {
  const amountAfterMaterialCharge = chain(1 - item.materialChargePercent / 100)
    .multiply(item.allocatedAmt)
    .done();
  const materialChargeAmount = item.materialChargePercent * item.allocatedAmt || 0;
  const amountAfterLabCharge = Number(amountAfterMaterialCharge) - item.labCharge;
  let { rate } = item;
  let { rateAmt } = item;
  if (changer === "rateAmt") {
    rate = chain(item.rateAmt / (amountAfterLabCharge || 1))
      .multiply(100)
      .done();
  } else {
    rateAmt = chain(item.rate * amountAfterLabCharge)
      .divide(100)
      .done();
  }

  const amountAfterTds = chain(1 - item.tds / 100)
    .multiply(rateAmt)
    .done();
  return {
    ...item,
    materialChargeAmount,
    rateAmt,
    rate,
    paidAmount: item.allocatedAmt,
    standardPayable: amountAfterTds,
    netPayableToVendor: amountAfterTds
  };
};

export const calculateTotalPayableOfBill = (bill: VendorCommissionBill): VendorCommissionBill => ({
  ...bill,
  totalNetPayableToVendor: bill.billItems.reduce(
    (prev, curr) => prev + Number(curr.netPayableToVendor),
    0
  )
});

export const calculateGrossTotalPayable = (bills: BillDataInterface[]): BillDataInterface[] =>
  bills.map((bill) => ({
    ...bill,
    grossTotalPayable: bill.billItems.reduce((prev, curr) => prev + curr.payableToReferrer, 0)
  }));

export const formatBillData = (bills: BillDocumentProps[]): VendorCommissionBill[] => {
  const items = [] as VendorCommissionBill[];
  let index = 0;
  [...bills]
    .sort((a, b) => b.id - a.id)
    .forEach((bill) => {
      const recordPaymentIds = [
        ...new Set(bill.referrerCommission.map((item) => item.recordPaymentId))
      ];
      bill.receipts.forEach((receipt) => {
        index += 1;
        const rows = [] as VendorCommission[];
        let internalSNo = 0;
        if (recordPaymentIds.includes(receipt.id)) {
          bill.referrerCommission
            .filter((refCommission) => refCommission.recordPaymentId)
            .forEach((item) => {
              internalSNo += 1;
              rows.push(
                findRateAmt({
                  ...item,
                  internalSNo,
                  qty: item.qty || 0,
                  allocatedAmt: item.paidAmount || 0,
                  description: item.relatedInformation?.serviceName,
                  netPayableToVendor: item.netPayableToVendor || 0,
                  rate: item.rate || 0
                })
              );
            });
        } else {
          bill.billItems?.forEach((billItem) => {
            const allocatedAmt = round(
              (receipt.paidAmount * (billItem?.grossTotal || 0)) / (bill.total || 1),
              2
            );
            internalSNo += 1;
            const row = findReferralPayableAmt({
              internalSNo,
              billId: bill.id,
              clientId: bill.clientId,
              billNo: bill.billNumber,
              source: billItem?.source || "services",
              description: billItem.description || "",
              qty: billItem?.quantity,
              allocatedAmt,
              labCharge:
                billItem.source === "services" ? Number(billItem.productData?.labCharge) || 0 : 0,
              materialChargeAmount: 0,
              materialChargePercent: Number(billItem.productData?.materialCharge) || 0,
              rate: Number(bill.referrer.rate) || 0,
              tds: Number(bill.referrer.tds) || 0,
              vendorId: bill.referrer.id,
              discountAmount: billItem.discountAmt || 0,
              discountPercent: billItem.discountPercent || 0,
              standardPayable: 0,
              netPayableToVendor: 0,
              netAmount: billItem?.grossTotal || 0,
              paidAmount: 0,
              rateAmt: 0,
              recordPaymentId: receipt.id,
              serviceId: billItem.productData?.id || billItem.productId,
              productPriceExcVat:
                billItem.source === "services"
                  ? +(billItem?.productData?.servicePriceExcVAT || 0)
                  : +(billItem?.productData?.unitPriceExcVAT || 0),
              relatedInformation: {
                customerFirstName: bill.client?.firstName || "",
                customerLastName: bill.client?.lastName || "",
                packageDetail: billItem?.subItems || [],
                serviceName: billItem.description || "",
                referrer: bill.referrer.referrer,
                source: billItem?.source || "services"
              }
            });
            rows.push(row);
          });
        }
        const item = {
          isReferrerPaid: recordPaymentIds.includes(receipt.id),
          sNo: index,
          billItems: rows,
          paidAmount: receipt.paidAmount,
          billId: bill.id,
          billNumber: bill?.billNumber || "",
          recordPaymentId: receipt.id,
          isSelected: false,
          totalNetPayableToVendor: 0,
          isSaved: false
        };
        items.push(calculateTotalPayableOfBill(item));
      });
    });
  return items;
};

export const formatData = (data: VendorCommissionBill[]): VendorCommission[] => {
  const referrerCommissions = [] as VendorCommission[];
  data.forEach((item) => {
    if (item.isSelected) {
      item.billItems.forEach((billItem: VendorCommission) => {
        referrerCommissions.push(billItem);
      });
    }
  });
  return referrerCommissions;
};

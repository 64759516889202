import { ReducerBuilder } from './ReducerBuilder';
import * as OngoingActions from '../actions/ongoing';

const INITIAL_STATE = {
};
type StateType = { [key: string]: boolean }

function _ongoingAdd(state: StateType, payload: string): StateType {
  return { ...state, [payload]: true };
}

function _ongoingRemove(state: StateType, payload: string): StateType {
  const { [payload]: removed, ...rest } = state;
  return rest;
}

const reducer = ReducerBuilder.create<StateType, string>(INITIAL_STATE)
  .mapAction(OngoingActions.Type.ONGOING_ADD, _ongoingAdd)
  .mapAction(OngoingActions.Type.ONGOING_REMOVE, _ongoingRemove)
  .build();

export default reducer;

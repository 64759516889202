import { Get, Put, Post, Patch } from "./apiHelper";
import { ResourceCentreNotification } from "../interfaces/ReminderInterface";

const remindersRoot = "/api/reminders";

export interface MarkReadParams {
  id: number;
  done: boolean;
}

export interface MarkLabReadParams {
  done: boolean;
  entityId: number;
}

export async function getReminders(): Promise<ResourceCentreNotification> {
  const response = await Get(remindersRoot, true);
  return response.data;
}

export async function markReminderRead({
  id,
  done
}: MarkReadParams): Promise<ResourceCentreNotification> {
  await Put(`${remindersRoot}/${id}`, { done }, true);
  return {};
}

export async function markAllAsRead(ids: number[]): Promise<ResourceCentreNotification> {
  await Patch(`${remindersRoot}/markAllAsRead`, { ids }, true);
}

export const getResourceCentreNotification = async (
  rcId?: number
): Promise<ResourceCentreNotification[]> => {
  const resourceCentreId = rcId ? `resourceCentreId=${rcId}` : "";
  const response = await Get(`${remindersRoot}/rcNotifications?${resourceCentreId}`);
  return response.data as ResourceCentreNotification[];
};

export const getLatestRcNotifications = async (): Promise<ResourceCentreNotification[]> => {
  const response = await Get(`${remindersRoot}/latestRcNotifications`);
  return response.data as ResourceCentreNotification[];
};

export const postResourceCentreNotification = async (
  ids: number[],
  remark: string,
  type: string
): Promise<ResourceCentreNotification> => {
  const response = await Post(
    `${remindersRoot}/rcNotifications`,
    { rcIds: ids.join(), remark, type },
    true
  );
  return response.data;
};

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { Box, IconButton, Typography } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import { history } from "../../store";
import OkhatiDialog from "../Dialog/Dialog";

interface pageControlProps {
  page: number;
  onSetPage: (page) => void;
  pageSize?: number;
  maximumDataCount: number;
  allowNextAtEnd?: boolean;
  endAlertMessage?: string;
  pushTo?: string;
  ignoreMaxDataCount?: boolean;
  disableNextButton?: boolean;
}

const PageControl: React.FC<pageControlProps> = ({
  page = 0,
  onSetPage,
  pageSize = 50,
  maximumDataCount,
  allowNextAtEnd = false,
  endAlertMessage,
  pushTo,
  disableNextButton = false,
  ignoreMaxDataCount = false
}) => {
  const [disablePageChange, setDisablePageChange] = React.useState({
    disablePrevious: true,
    disableNext: true
  });
  const [showEndOfResultDialog, setShowEndOfResultDialog] = React.useState(false);
  React.useEffect(() => {
    let disableNext = true;
    let disablePrevious = true;

    if (!disableNextButton && (ignoreMaxDataCount || maximumDataCount > (page + 1) * pageSize))
      disableNext = false;
    else disableNext = true;

    if (page > 0) disablePrevious = false;

    setDisablePageChange({ disableNext, disablePrevious });
  }, [page, maximumDataCount, disableNextButton, ignoreMaxDataCount]);

  // report/clientReport or report/labReport
  const report = pushTo?.split("/").pop();

  return (
    <>
      {showEndOfResultDialog && (
        <OkhatiDialog
          title="End of Results"
          description={endAlertMessage || ""}
          next={() => {
            if (pushTo) {
              history.push(pushTo);
            }
          }}
          cancel={() => {
            setShowEndOfResultDialog(false);
          }}
          nextButtonText={`Visit ${startCase(report)}`}
          readMode={false}
        />
      )}
      <Box
        display="flex"
        flexBasis="192px"
        justifyContent="space-evenly"
        alignItems="center"
        color="black"
      >
        <IconButton
          onClick={() => onSetPage(0)}
          disabled={disablePageChange.disablePrevious}
          data-testmation="setPageZero"
          size="large"
        >
          <SkipPreviousIcon
            style={{ color: "black", opacity: disablePageChange.disablePrevious ? 0.25 : 1 }}
          />
        </IconButton>
        <IconButton
          onClick={() => onSetPage(page - 1)}
          disabled={disablePageChange.disablePrevious}
          data-testmation="gotoPreviousPage"
          size="large"
        >
          <ChevronLeftIcon
            style={{ color: "black", opacity: disablePageChange.disablePrevious ? 0.25 : 1 }}
          />
        </IconButton>
        <Typography style={{ padding: "12px" }}>{page + 1}</Typography>
        <IconButton
          // eslint-disable-next-line consistent-return
          onClick={() => {
            if (allowNextAtEnd && disablePageChange.disableNext) {
              setShowEndOfResultDialog(true);
              return;
            }
            onSetPage(page + 1);
          }}
          disabled={!allowNextAtEnd && disablePageChange.disableNext}
          data-testmation="gotoNextPage"
          size="large"
        >
          <ChevronRightIcon
            style={{
              color: "black",
              opacity: !allowNextAtEnd && disablePageChange.disableNext ? 0.25 : 1
            }}
          />
        </IconButton>
      </Box>
    </>
  );
};

PageControl.defaultProps = {
  allowNextAtEnd: false,
  pageSize: 50,
  endAlertMessage: "",
  pushTo: ""
};
export default PageControl;

import React from "react";
import { AccountingGroup, PrimaryLedger, PrimaryLedgerNames } from "../../../interfaces/Accounts";
import AccountingList from "../AccountList";

interface IncomeProps {
  data: PrimaryLedger;
  setSelectedParent: (data: Partial<AccountingGroup>) => void;
}

export default function Income({ data, setSelectedParent }: IncomeProps): JSX.Element {
  return (
    <AccountingList
      header={PrimaryLedgerNames.INCOME}
      setSelectedParent={setSelectedParent}
      data={data}
    />
  );
}

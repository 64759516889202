// eslint-disable-next-line import/prefer-default-export
export const getFooterHeight = ({
  showQrWhenFooterIsHidden,
  showFooter
}: {
  showQrWhenFooterIsHidden: boolean;
  showFooter: boolean;
}): string => {
  if (!showFooter && showQrWhenFooterIsHidden) {
    return "5cm";
  }
  return "3.5cm";
};

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Search from "@mui/icons-material/Search";
import {
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { Ward } from "../../api/wards";

interface Filter {
  selectedWard: number | null;
  bedNumber: string | number | null;
}
interface PropsInterface {
  wards: Array<Ward>;
  filter: Filter;
  setFilter: (value) => void;
}

const WardBedSearch = ({ wards, filter, setFilter }: PropsInterface): JSX.Element => (
  <div>
    <TextField
      variant="outlined"
      placeholder="Search..."
      sx={{
        backgroundColor: "#ECECEC",
        border: "none",
        "& fieldset": { border: "none" },
        borderRadius: "5px"
      }}
      fullWidth
      value={filter.bedNumber}
      onChange={(e) =>
        setFilter({
          ...filter,
          bedNumber: e.target.value
        })
      }
      InputLabelProps={{
        shrink: !!filter.bedNumber,
        style: {
          marginLeft: filter.bedNumber ? "0px" : "20px"
        }
      }}
      InputProps={{
        startAdornment: (
          <Search
            sx={{
              paddingRight: "5px"
            }}
          />
        ),
        endAdornment: (
          <InputAdornment position="start">
            <Divider
              orientation="vertical"
              style={{ height: 20, marginRight: 15 }}
              color="#e2e2e2"
            />
            <FormControl>
              <InputLabel shrink={false} size="small" sx={{ color: "primary" }}>
                {!filter.selectedWard && <Typography color="primary">Ward</Typography>}
              </InputLabel>
              <Select
                id="wardSelect"
                data-testmation="wardSelect"
                value={filter.selectedWard}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    selectedWard: Number(e.target.value)
                  })
                }
                variant="outlined"
                sx={{
                  border: "none",
                  "& fieldset": { border: "none" },
                  width: "80px"
                }}
                IconComponent={(props) => (
                  <ExpandMoreIcon
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    style={{
                      color: "#009654",
                      marginTop: "2px"
                    }}
                  />
                )}
              >
                {wards.map((ward) => (
                  <MenuItem key={ward.id} value={ward.id} data-testmation="wardMenuItem">
                    {ward.shortCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputAdornment>
        )
      }}
    />
  </div>
);

export default WardBedSearch;

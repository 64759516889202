import { Capsules } from "../../../Svgicons/Capsules";
import { Cream } from "../../../Svgicons/Cream";
import { EyeDrops } from "../../../Svgicons/EyeDrop";
import { BodyLotion } from "../../../Svgicons/BodyLotion";
import { Pills } from "../../../Svgicons/2pills";
import { Flask } from "../../../Svgicons/Flask";
import { Gel } from "../../../Svgicons/Gel";
import { Inhalator } from "../../../Svgicons/Inhalator";
import { Injection } from "../../../Svgicons/Injection";
import { MouthWash } from "../../../Svgicons/MouthWash";
import { NasalDrops } from "../../../Svgicons/NasalDrop";
import { NasalSpray } from "../../../Svgicons/NasalSpray";
import { Ointment } from "../../../Svgicons/Ointment";
import { Patch } from "../../../Svgicons/Patch";
import { Powder } from "../../../Svgicons/Powder";
import { Spray } from "../../../Svgicons/Spray";
import { DrySyrup } from "../../../Svgicons/DrySyrup";
import { Syrup } from "../../../Svgicons/Syrup";
import { Vial } from "../../../Svgicons/Vial";
import { Ampoul } from "../../../Svgicons/Ampoul";
import { Bottle } from "../../../Svgicons/Bottle";
import { Cartridge } from "../../../Svgicons/Cartridge";
import { SyrupDepo } from "../../../Svgicons/Syrupdepo";
import Tube from "../../../Svgicons/Tube";

export const countableTypes = ["Injection", "Tablet", "Capsule"];

export const medicineType = {
  AMP: Ampoul,
  Capsule: Capsules,
  Cartridge,
  Cream,
  Drop: EyeDrops,
  "Dry Syrup": DrySyrup,
  "D-SY": SyrupDepo,
  "Ear Drop": EyeDrops,
  "Ear/Eye": EyeDrops,
  EYE: EyeDrops,
  "Eye drop": EyeDrops,
  "Eye Ointment": Ointment,
  "F-SUS": Bottle,
  Gel,
  Inhaler: Inhalator,
  Injection,
  Lotion: BodyLotion,
  Mouthwash: MouthWash,
  "Nasal Drop": NasalDrops,
  "Nasal Spray": NasalSpray,
  Ointment,
  PATCH: Patch,
  Powder,
  Solution: Flask,
  Spray,
  Suspension: Bottle,
  Syrup,
  Tablet: Pills,
  VIAL: Vial,
  Facewash: BodyLotion,
  Serum: BodyLotion,
  Shampoo: Bottle,
  Toothpaste: Tube
};

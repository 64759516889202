import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

export type DebouncedTextFieldProps = TextFieldProps & {
  debounceAt?: number;
  setDebounceLoading?: (val: boolean) => void;
  onChange: (value: {
    target: EventTarget & (HTMLTextAreaElement | HTMLInputElement);
    barCodeDetected: boolean;
  }) => void;
};

const DebouncedTextFieldWithScannerDetect = ({
  value: val,
  onChange,
  debounceAt,
  ...otherProps
}: DebouncedTextFieldProps): JSX.Element => {
  const [value, setValue] = React.useState(val);
  const [inputChangeTime, setInputChangeTime] = React.useState(0);
  const timer = React.useRef<NodeJS.Timeout | null>(null);
  const [barCodeDetected, setBarCodeDetected] = React.useState(false);

  React.useEffect(() => {
    setValue(val);
  }, [val]);

  const debouncedOnChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(e.target.value);
    const currentTime = new Date().getTime();
    setInputChangeTime(currentTime);
    const timeDiff = currentTime - inputChangeTime;
    // time between consecutive input events is very short for scanner
    if (timeDiff < 50) {
      setBarCodeDetected(true);
    } else {
      setBarCodeDetected(false);
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }
    const { target } = e;
    timer.current = setTimeout(
      () => {
        onChange({ target, barCodeDetected });
      },
      barCodeDetected ? 200 : debounceAt
    );
  };

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      value={value}
      onChange={debouncedOnChangeHandler}
    />
  );
};

export default DebouncedTextFieldWithScannerDetect;

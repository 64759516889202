import { Box, Button, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";
import Panel from "../../components/Panel";
import * as NotificationActions from "../../actions/notification";
import { SubscriptionInvoiceEntity } from "../../interfaces/Subscription";
import { postManualInvoice } from "../../slices/subscriptionSlice";
import ClinicSelect from "../Reports/SummaryReport/ClinicSelect";

interface Props {
  handlePanelClose: () => void;
}

export interface ManualInvoiceInterface {
  invoiceForEntity: SubscriptionInvoiceEntity;
  invoiceAmount: number;
  invoiceDueAt: string;
  details: string;
}

const initialState = {
  invoiceForEntity: SubscriptionInvoiceEntity.SUBSCRIPTION,
  invoiceAmount: 0,
  invoiceDueAt: moment().add(45, "d").toISOString(),
  details: ""
};

export default function ManualInvoiceCreatePanel(props: Props): JSX.Element {
  const { handlePanelClose } = props;

  const dispatch = useDispatch();

  const [state, setState] = React.useState<ManualInvoiceInterface>(initialState);

  const [resourceCentre, setResourceCentre] = React.useState(null);

  return (
    <Panel onClose={handlePanelClose} title="Create Invoice Manually">
      <Box p={5} height="calc(100vh - 110px)" style={{ overflowY: "auto" }}>
        <ClinicSelect
          onChange={(v) => {
            if (v) {
              setResourceCentre(v);
            }
          }}
          value={null}
        />
        <TextField
          id="outlined-select-currency"
          select
          variant="outlined"
          style={{ marginTop: "32px" }}
          value={state.invoiceForEntity}
          fullWidth
          label="Select Invoice For Entity"
          onChange={(e) => {
            setState({ ...state, invoiceForEntity: e.target.value as SubscriptionInvoiceEntity });
          }}
          helperText="Invoice For Entity Type"
        >
          <MenuItem
            key={SubscriptionInvoiceEntity.SUBSCRIPTION}
            value={SubscriptionInvoiceEntity.SUBSCRIPTION}
          >
            {SubscriptionInvoiceEntity.SUBSCRIPTION}
          </MenuItem>
        </TextField>
        <Box mt="16px">
          <CalendarDropdown
            placeholder="Invoice Due Date"
            label="Select Invoice Due Date"
            minValidDate={moment()}
            date={new Date(state.invoiceDueAt)}
            onChange={(date) => setState({ ...state, invoiceDueAt: date })}
            format="formatted3"
            TextFieldInputProps={{ variant: "outlined" }}
          />
        </Box>

        <TextField
          variant="outlined"
          fullWidth
          label="Invoice Amount"
          type="number"
          value={state.invoiceAmount}
          style={{ marginTop: "32px" }}
          helperText="Total invoice amount to be paid by the Resource Centre"
          onFocus={(e) => e.target.select()}
          onChange={(e) => setState({ ...state, invoiceAmount: Number(e.target.value) })}
          InputProps={{
            startAdornment: <Box mr="16px">Rs</Box>
          }}
        />

        <TextField
          variant="outlined"
          fullWidth
          rows={8}
          multiline
          onChange={(e) => {
            setState({ ...state, details: e.target.value });
          }}
          style={{ marginTop: "16px" }}
          value={state.details}
          label="Invoice details"
          placeholder="Details about the invoice"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" px={5} py={2}>
        <Button onClick={handlePanelClose}>Close</Button>
        <Button
          onClick={async () => {
            await dispatch(postManualInvoice({ ...state, resourceCentreId: resourceCentre.id }));
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: "Invoice successfully created!",
                autoTimeout: true
              })
            );
            handlePanelClose();
          }}
          disabled={!state.details || !state.invoiceAmount || !resourceCentre}
          variant="contained"
        >
          Create
        </Button>
      </Box>
    </Panel>
  );
}

/* eslint-disable camelcase */
import { Box, Typography } from "@mui/material";
import { push } from "connected-react-router";

import * as React from "react";
import { match as MatchProps } from "react-router";
import { connect, useDispatch } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import List, { EmptyView } from "../../components/OList";
import { tl } from "../../components/translate";
import useMobileScreen from "../../hooks/useMobileScreen";
import Can from "../Policy/Can";
import "./Reports.scss";
import { getPurchaseRequisitionForms } from "../../api/stock";
import { StockTransactions } from "../../interfaces/StockInterfaces";
import { commonErrorMessage } from "../../helpers/messages";
import StockTransactionView from "../Stock/StockTransactions/StockTransactionView";
import Filters from "./Filters";
import { multipleHeaders } from "../Stock/StockList";
import useKitchenPharmacy from "../../hooks/useKitchenPharmacy";
import { useAppSelector } from "../../store/hooks";
import { MultipleHeader } from "../../components/List";

const addProductNames = (trxData) =>
  trxData.map((trx) => ({
    ...trx,
    products: trx.stockTransactionItems?.map((item) => item.productName).join(", ")
  }));

const PurchaseRequisitionForm = ({ match }: { match: MatchProps }) => {
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    branchIds: []
  });

  const dispatch = useDispatch();
  const [stockTransactions, setStockTransactions] = React.useState<StockTransactions[]>(
    [] as StockTransactions[]
  );
  const [selectedRow, setSelectedRow] = React.useState<Partial<StockTransactions | null>>(null);
  React.useEffect(() => {
    (async () => {
      try {
        const response = await getPurchaseRequisitionForms(filters);
        setStockTransactions(addProductNames(response));
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    })();
  }, [filters]);

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const isMobileScreen = useMobileScreen();
  const isRcKitchenPharmacy = Boolean(useKitchenPharmacy());
  const isCentralStore =
    useAppSelector((state) => state.userContext.resourceCentre?.isKitchenPharmacy) || false;

  return (
    <Can policyAccessKey="report:receiptReport">
      <Box marginTop="32px">
        <Box ml={2}>
          <MultipleHeader
            multipleHeaders={multipleHeaders(match, isRcKitchenPharmacy, isCentralStore)}
          />
        </Box>
        <Box margin="0px 32px">
          <Box marginTop="32px">
            <Filters filters={filters} onSetFilters={(newFilters) => applyFilters(newFilters)} />
          </Box>
        </Box>
        <Box
          width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
          style={{ overflowX: isMobileScreen ? "scroll" : "auto" }}
          mx="auto"
        >
          <Box marginTop="32px" width={isMobileScreen ? "960px" : "auto"}>
            <List
              automation="purchaseRequestForm"
              data={stockTransactions}
              rowHeight={50}
              defaultSortOrder={-1}
              activeRow={1}
              adjustHeightToContents
              defaultSortColumn="resourceCentre"
              defaultSe
              columns={[
                {
                  key: "date",
                  label: tl("reports.date"),
                  sortable: true,
                  formatter: ({ date }) => (
                    <Typography>{calFns.convertADtoBS(new Date(date)).formatted4}</Typography>
                  )
                },
                {
                  key: "products",
                  label: "Products"
                },
                {
                  // : { segmentable: true, segmentBy: ({ productName }) => productName }),
                  segmentable: true,
                  segmentBy: ({ resourceCentre }) => resourceCentre.name,
                  key: "resourceCentre",
                  label: "Resource Centre",
                  formatter: ({ resourceCentre }) => <Typography>{resourceCentre.name}</Typography>
                }
              ]}
              onRowClick={(row) => setSelectedRow(row)}
              segementSummaryRenderer={(acc) => (
                <Box
                  style={{ background: "#e6e6e6" }}
                  display="flex"
                  flexGrow={1}
                  paddingRight="30px"
                >
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight={500}
                      >
                        {acc.segment || "N/A"}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
            </List>
          </Box>
          {selectedRow && (
            <StockTransactionView
              data={{ ...selectedRow, returnedTrx: [] }}
              handlePanelClose={() => setSelectedRow(null)}
              disableProductSelect
              showCreateBillButton
            />
          )}
        </Box>
      </Box>
    </Can>
  );
};

export default connect(null, (dispatch) => ({
  actions: {
    navigateTo: (url) => dispatch(push(url)),
    addNotification: (statusCode) => {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: statusCode === 200 ? "success" : "error",
          message:
            statusCode === 200 ? tl("reports.downloadSuccess") : tl("reports.downloadFailure"),
          autoTimeout: true
        })
      );
    }
  }
}))(PurchaseRequisitionForm);

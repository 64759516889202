import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import useMobileScreen from "../../../hooks/useMobileScreen";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import * as ipdClientActions from "../../../actions/ipd";
import Diagnosis from "../../Assessment/Diagnosis/Diagnosis";
import {
  DischargeSummary,
  MaternityDischargeSummary,
  IPD,
  PrescriptionContext
} from "../../../interfaces/IpdInterface";
import ServiceProviderSelect from "../../../components/ServiceProviderSelect/ServiceProviderSelect";
import { useAppSelector } from "../../../store/hooks";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import ProgressReportSelect from "./ProgressReportSelect";
import Medication from "../../Assessment/Medication/Medication";
import { postIpdRequestForm } from "../../../api/requestForms";
import { RequestType } from "../LabRequest/LabRequestForm";
import { notificationAdd } from "../../../actions/notification";
import { MEDICINE_COMPONENT } from "../../ResourceCentre/ModuleSettings/IpdSettings";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";
import IpdProductMedication from "../IpdProgress/IpdProductMedication";

interface DischargeForm {
  ipdData: IPD;
  setOpenDischargeForm: (boolean) => void;
  setOpenDischargeDetails: (boolean) => void;
  createDischargeSummary: (dischargeSummary: DischargeSummary) => void;
}

const defaultProps: TextFieldProps = {
  margin: "dense",
  color: "primary",
  variant: "outlined",
  type: "text",
  select: false,
  multiline: false,
  fullWidth: true,
  InputLabelProps: { shrink: true }
};

export function CustomTextField(props: TextFieldProps): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...defaultProps} {...props}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </TextField>
  );
}

const DischargeForm: React.FC<DischargeForm> = ({
  ipdData,
  setOpenDischargeForm,
  setOpenDischargeDetails,
  createDischargeSummary
}) => {
  const rc = useCurrentResourceCentre();
  const medicationInput = rc.settings.ipdSettings.medicationComponent;
  const isMobileScreen = useMobileScreen();
  const existingDischargeSummary = ipdData.ipdRecord.dischargeSummary;
  const medications =
    ipdData.prescribedMedications?.filter(
      (item) => item.prescriptionContext === PrescriptionContext.DISCHARGE
    ) || [];
  const [dischargeSummary, setSummaryData] = React.useState({
    id: ipdData.id,
    ipdCase: ipdData.ipdRecord.case,
    remarks: existingDischargeSummary?.remarks || "",
    finalDiagnosis: existingDischargeSummary?.finalDiagnosis || {},
    instructionsOnDischarge: existingDischargeSummary?.instructionsOnDischarge || "",
    authorizedPersonnelId: ipdData.authorizedPersonnelId,
    doctorInChargeId: ipdData.doctorInChargeId,
    dischargePrintTitle: existingDischargeSummary?.dischargePrintTitle || "Discharge Form",
    provisionalDiagnosis: ipdData.ipdRecord.provisionalDiagnosis || "",
    hopi: ipdData.ipdRecord.hopi || "",
    prescribedMedicineOnDischarge: medications,
    conditionDuringDischarge: existingDischargeSummary?.conditionDuringDischarge || "",
    progressResult: existingDischargeSummary?.progressResult || ""
  } as DischargeSummary | MaternityDischargeSummary);

  const serviceProviders =
    useAppSelector((state) => state.resources.resourceCentreServiceProviders) || [];

  const [relatedServiceProviders, setRelatedServiceProviders] = React.useState<{
    authorizedPersonnel?: ServiceProvider;
    doctorInCharge?: ServiceProvider;
  }>(() => ({
    authorizedPersonnel: serviceProviders.find(
      (item) => item.id === dischargeSummary.authorizedPersonnelId
    ),
    doctorInCharge: serviceProviders.find((item) => item.id === dischargeSummary.doctorInChargeId)
  }));

  const [saving, setSaving] = React.useState(false);
  const checkValue = (data) => {
    if (data.conditionDuringDischarge && data.instructionsOnDischarge && data.progressResult) {
      if (data.ipdCase === "Maternity") {
        if (data.procedures && data.weightOfBaby) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const disable = checkValue(dischargeSummary);
  const rem = 1000 - dischargeSummary?.instructionsOnDischarge?.length;
  const displayRemainingChar = `${rem}/1000`;

  if (!ipdData) return null;

  return (
    <Panel
      onClose={() => setOpenDischargeForm(false)}
      wrapperStyle={{ minWidth: "800px", maxWidth: "800px" }}
      title="Discharge Form"
      footer={
        <Box
          width="100%"
          alignItems="center"
          display="flex"
          flexDirection="row-reverse"
          gap={2}
          px={4}
        >
          <StatefulButton
            data-testmation="save"
            variant="contained"
            color="primary"
            disabled={saving || !disable}
            onClick={async () => {
              if (dischargeSummary) {
                setSaving(true);
                await createDischargeSummary(
                  dischargeSummary,
                  medicationInput === MEDICINE_COMPONENT.MEDICATION
                ).then(() => {
                  setSaving(false);
                  setOpenDischargeForm(false);
                  if (setOpenDischargeDetails) {
                    setOpenDischargeDetails(true);
                  }
                });
              }
            }}
            isLoading={saving}
            circularProgressProps={{ size: 16 }}
          >
            Save
          </StatefulButton>
          <Button onClick={() => setOpenDischargeForm(false)}>Cancel</Button>
        </Box>
      }
    >
      <Box
        sx={{
          pt: 2,
          display: "flex",
          gap: 1,
          flexDirection: "column",
          height: "calc(100vh - 100px)",
          overflowY: "auto"
        }}
        px={`${isMobileScreen ? "16px" : "32px"}`}
      >
        <Box display="flex" gap={1}>
          <Typography fontWeight="bold">Case:</Typography>
          <Typography>{ipdData?.ipdRecord.case}</Typography>
        </Box>
        <CustomTextField
          data-testmation="formTitle"
          value={dischargeSummary.dischargePrintTitle}
          type="text"
          onChange={(e) =>
            setSummaryData({
              ...dischargeSummary,
              dischargePrintTitle: e.target.value
            })
          }
          label="Discharge form print title"
        />
        <CustomTextField
          value={dischargeSummary.conditionDuringDischarge}
          data-testmation="conditionDuringDischarge"
          type="text"
          onChange={(e) =>
            setSummaryData({
              ...dischargeSummary,
              conditionDuringDischarge: e.target.value
            })
          }
          label="Condition During Discharge"
        />
        <CustomTextField
          data-testmation="adviceOnDischarge"
          type="text"
          multiline
          rows={3}
          label="Advice on Discharge"
          value={dischargeSummary.instructionsOnDischarge}
          onChange={(e) => {
            if (e.target.value.length <= 1000) {
              setSummaryData({
                ...dischargeSummary,
                instructionsOnDischarge: e.target.value
              });
            }
          }}
        />
        <Box fontSize={14} display="flex" flexDirection="row-reverse">
          {displayRemainingChar}
        </Box>
        <ProgressReportSelect
          value={dischargeSummary.progressResult}
          onChange={(value) =>
            setSummaryData({
              ...dischargeSummary,
              progressResult: value
            })
          }
        />
        <CustomTextField
          data-testmation="provisionalDiagonosis"
          label="Provisional Diagnosis"
          multiline
          minRows={2}
          onChange={(e) =>
            setSummaryData({ ...dischargeSummary, provisionalDiagnosis: e.target.value })
          }
          value={dischargeSummary.provisionalDiagnosis}
        />
        <CustomTextField
          data-testmation="hopi"
          label="HOPI"
          multiline
          minRows={2}
          value={dischargeSummary.hopi}
          onChange={(e) => setSummaryData({ ...dischargeSummary, hopi: e.target.value })}
        />
        {medicationInput === MEDICINE_COMPONENT.MEDICATION ? (
          <Medication
            hideDefaultLabel
            customLabel="Prescribe Medicine"
            medication={dischargeSummary.prescribedMedicineOnDischarge}
            onChange={(v) =>
              setSummaryData({ ...dischargeSummary, prescribedMedicineOnDischarge: v })
            }
          />
        ) : (
          <IpdProductMedication
            productMedication={dischargeSummary.prescribedMedicineOnDischarge}
            onChange={(v) =>
              setSummaryData({ ...dischargeSummary, prescribedMedicineOnDischarge: v })
            }
          />
        )}

        {ipdData?.ipdRecord?.case === "Maternity" && (
          <Box>
            <CustomTextField
              type="text"
              onChange={(e) =>
                setSummaryData({
                  ...dischargeSummary,
                  procedures: e.target.value
                })
              }
              data-testmation="MaternityProcedures"
              label="Procedures"
            />
            <CustomTextField
              InputLabelProps={{
                shrink: true
              }}
              type="date"
              onChange={(e) =>
                setSummaryData({
                  ...dischargeSummary,
                  dateOfDelivery: e.target.value
                })
              }
              label="Date of Delivery"
            />
            <CustomTextField
              type="text"
              onChange={(e) =>
                setSummaryData({
                  ...dischargeSummary,
                  weightOfBaby: e.target.value
                })
              }
              label="Baby Weight"
            />
          </Box>
        )}
        <Box>
          <Diagnosis
            data-testmation="diagnosisTextField"
            label="Final Diagnosis"
            hidetitle
            diagnosis={dischargeSummary.finalDiagnosis}
            onChange={(v) => {
              setSummaryData({ ...dischargeSummary, finalDiagnosis: v });
            }}
          />
        </Box>
        <CustomTextField
          value={dischargeSummary.remarks}
          type="text"
          label="Remarks"
          minRows={2}
          multiline
          onChange={(e) => setSummaryData({ ...dischargeSummary, remarks: e.target.value })}
        />
        <Divider sx={{ my: 2 }} />
        <Typography mb={2}>Signatures On Discharge Form</Typography>
        <ServiceProviderSelect
          fullWidth
          label="Authorized Personnel"
          variant="standard"
          serviceProvider={relatedServiceProviders.authorizedPersonnel}
          onChange={(sp) => {
            setRelatedServiceProviders({ ...relatedServiceProviders, authorizedPersonnel: sp });
            setSummaryData({ ...dischargeSummary, authorizedPersonnelId: sp?.id });
          }}
        />
        <ServiceProviderSelect
          fullWidth
          label="Doctor In Charge"
          variant="standard"
          serviceProvider={relatedServiceProviders.doctorInCharge}
          onChange={(sp) => {
            setRelatedServiceProviders({ ...relatedServiceProviders, doctorInCharge: sp });
            setSummaryData({ ...dischargeSummary, doctorInChargeId: sp?.id });
          }}
        />
      </Box>
    </Panel>
  );
};

export default connect(
  (state) => {
    const serviceProviders = state.resources.resourceCentreServiceProviders || [];
    return { serviceProviders };
  },
  (dispatch) => ({
    createDischargeSummary: async (dischargeSummary: DischargeSummary) => {
      await dispatch(ipdClientActions.createDischargeSummary(dischargeSummary));
      // create request form for prescribed medications
      const medicationFromStock = dischargeSummary.prescribedMedicineOnDischarge?.filter((item) =>
        Boolean(item.isFromStock)
      );
      if (medicationFromStock?.length) {
        try {
          await postIpdRequestForm({
            ipdId: dischargeSummary.id,
            requestedItems: dischargeSummary.prescribedMedicineOnDischarge,
            requestedItemsType: RequestType.PRODUCT
          });
        } catch (err) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err.message || "Failed to create request form",
              autoTimeout: true
            })
          );
        }
      }
    },
    navigateTo: (url) => dispatch(push(url))
  })
)(DischargeForm);

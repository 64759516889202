import * as React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import cloneDeep from "lodash/cloneDeep";
import MenuItem from "@mui/material/MenuItem";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Autocomplete, Typography } from "@mui/material";
import { validate } from "../../helpers/validators";
import { tl, t } from "../translate";
import ToggleInputDate from "../ToggleADBS";
import capitalizedName from "../../helpers/nameCapitalizer";
import styles from "./style.module.css";
import InputPhoneNumber from "../InputPhoneNumber";
import ServiceTagsSearch from "../ServiceTagsSearch";
import SpecialitySearch from "../SpecialitySearch/SpecialitySearch";
import hasOwnProperty from "../../helpers/object";

export interface PropType {
  fields: Record<string, undefined>;
  data: { [key: string]: string };
  footer?: JSX.Element;
  title?: JSX.Element;
  classNames?: { [key: string]: string };
  onChange?: (data, errors?, errorsCount?: number) => void;
  onSave?: (data) => void;
  translator?: (label: string) => JSX.Element;
  showErrors?: boolean;
  disableFlex?: boolean;
}
export interface StateType {
  data: { [key: string]: string };
  errors: { [key: string]: string };
  isFocused: boolean;
  isPasswordVisible: boolean;
}

export default class Form extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      data: props.data,
      errors: {},
      isFocused: false,
      isPasswordVisible: false
    };
  }

  public componentDidMount(): void {
    document.addEventListener("keypress", (event) => {
      const { isFocused } = this.state;
      if (event.key === "Enter" && isFocused) {
        this.onSave();
      }
    });
  }

  private onChange(key, value) {
    const { onChange } = this.props;
    const { data: stateData } = this.state;
    const data = cloneDeep(stateData);
    data[key] = value;
    this.setState({ data });
    if (onChange) {
      onChange(data);
    }
  }

  private onSave() {
    const { onSave } = this.props;
    const { data } = this.state;
    const isValid = this.validateData();
    if (isValid && onSave) onSave(data);
  }

  private getFieldValue(f) {
    const { data } = this.state;
    const fVal = data[f.key];
    if (typeof fVal === "number") return fVal;
    return f.formatter ? f.formatter(fVal) : fVal || "";
  }

  private togglePasswordVisibilityIcon = () => {
    const { isPasswordVisible } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      isPasswordVisible: !isPasswordVisible
    }));
  };

  private validateData() {
    const { data } = this.state;
    const { onChange, fields } = this.props;
    let isValid = true;
    const errors = {};
    let errorsCount = 0;
    fields.forEach((f) => {
      if (f.validators && f.validators.length) {
        if (f.required || (!f.required && data[f.key]?.length > 0)) {
          const validationInfo = validate(data[f.key] || "", f.validators);
          if (!validationInfo.isValid) {
            errors[f.key] = validationInfo.messages;
            isValid = false;
            errorsCount += 1;
          }
        }
      }
      if (f.inputType === "nested" && data[f.key]) {
        f.children?.forEach((child) => {
          if (child.validators && child.validators.length) {
            const validationInfo = validate(data[f.key][child.key] || "", child.validators);
            if (!validationInfo.isValid) {
              if (!errors[f.key]) errors[f.key] = {};
              errors[f.key][child.key] = validationInfo.messages;
              isValid = false;
              errorsCount += 1;
            }
          }
        });
      }
    });
    this.setState({ errors }, () => {
      onChange(data, errors, errorsCount);
    });
    return isValid;
  }

  private createField(field, k) {
    const { errors: stateErrors, data, isFocused, isPasswordVisible } = this.state;
    const { showErrors } = this.props;

    const errors = stateErrors[field.key];
    switch (field.inputType) {
      case "textArea": {
        return (
          <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={t(field.label)}
            margin="dense"
            fullWidth
            label={tl(field.label)}
            data-testmation={field.key}
            key={field.key}
            error={showErrors ? !!errors : false}
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
            multiline
            rows={4}
            maxRows={8}
            value={this.getFieldValue(field)}
            onChange={(e) => this.onChange(field.key, e.target.value)}
          />
        );
      }
      case "geoLocation": {
        return (
          <TextField
            key={k}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={data[field.key]?.join(",")}
            margin="dense"
            fullWidth
            label={tl("geoLocation")}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false }, () => this.validateData())}
            onChange={(e) => {
              let v = e.target.value;
              // removes all except numbers, comma, dot
              v = v.replace(/ +?/g, "");
              v = v.replace(/[A-Za-z!@#$%^&*()]/g, "");

              const convertToArr = (p) => p.split(",");

              this.onChange(field.key, convertToArr(v));
            }}
            error={showErrors ? !!errors : false}
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
          />
        );
      }

      case "connectedServiceListField": {
        const stateSpecialities = (data && data.specialities) || [];
        const currValue = data[field.key] || stateSpecialities;
        return (
          <ServiceTagsSearch
            key={k}
            value={currValue}
            required={hasOwnProperty(field, "required") ? field.required : true}
            onChange={(v) => this.onChange(field.key, v)}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false }, () => this.validateData())}
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
            error={showErrors ? !!errors : false}
          />
        );
      }

      case "autoComplete": {
        return (
          <Autocomplete
            key={k}
            options={field.options.map((item) => item.label)}
            value={data[field.key]}
            getOptionLabel={(option) => option}
            onChange={(e, v) => {
              this.onChange(field.key, v);
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                placeholder={t(field.label)}
                margin="dense"
                label={tl(field.label)}
                required={hasOwnProperty(field, "required") ? field.required : true}
                onFocus={() => this.setState({ isFocused: true })}
                onBlur={() => this.setState({ isFocused: false })}
                helperText={
                  showErrors ? (
                    <>
                      {(errors || []).map((er) => (
                        <React.Fragment key={er}>
                          - {er} <br />
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    ""
                  )
                }
                error={showErrors ? !!errors : false}
              />
            )}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false }, () => this.validateData())}
          />
        );
      }

      case "conditionalSelectSpeciality": {
        return (
          <SpecialitySearch
            key={k}
            onChange={(v) => {
              this.onChange(field.key, v);
            }}
            value={data[field.key]}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false }, () => this.validateData())}
            errors={showErrors ? !!errors : false}
            required
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
          />
        );
      }

      case "select":
        return (
          <TextField
            disabled={field.disabled}
            key={k}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={t(field.label)}
            select
            error={showErrors ? !!errors : false}
            margin="dense"
            data-testmation={field.key}
            label={tl(field.label)}
            className={styles.input}
            value={data[field.key] || ""}
            onChange={(e) => this.onChange(field.key, e.target.value)}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => this.setState({ isFocused: false })}
            SelectProps={{
              MenuProps: {
                className: styles.menu
              }
            }}
            required={hasOwnProperty(field, "required") ? field.required : true}
            helperText={field.placeholder}
          >
            {field.options.map((option) => (
              // unique 'unqxsmi' id kept to prevent clickawaylistner
              // click away event firing on select of options
              <MenuItem
                key={option.value}
                value={option.value}
                id={`unqxsmi-${option.value}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <Typography>{option.label}</Typography>
                {/**
                 * show description only if the option is not selected i.e. in dropdown menu only
                 */}
                {data[field.key] !== option.value && (
                  <Typography fontSize="10px" color="grey">
                    {option.description || ""}
                  </Typography>
                )}
              </MenuItem>
            ))}
          </TextField>
        );
      case "password":
        return (
          <TextField
            key={k}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {isPasswordVisible ? (
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={this.togglePasswordVisibilityIcon}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{ cursor: "pointer" }}
                      onClick={this.togglePasswordVisibilityIcon}
                    />
                  )}
                </InputAdornment>
              )
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={t(field.label)}
            margin="dense"
            data-testmation={field.key}
            error={showErrors ? !!errors : false}
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
            className={styles.input}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => {
              this.setState({ isFocused: false }, () => {
                this.validateData();
              });
            }}
            type={isPasswordVisible ? "text" : field.inputType || ""}
            autoComplete="new-password"
            label={tl(field.label)}
            required={hasOwnProperty(field, "required") ? field.required : true}
            value={this.getFieldValue(field)}
            onChange={(e) => this.onChange(field.key, e.target.value)}
          />
        );
      case "bsDate":
        return (
          <ToggleInputDate
            key={k}
            field={field}
            data={data}
            showAgeField={field.showAgeField}
            error={showErrors ? !!errors : false}
            className={styles.input}
            isFocused={() => this.setState({ isFocused: true })}
            isBlurred={() => {
              this.setState({ isFocused: false }, () => {
                this.validateData();
              });
            }}
            changeDate={(value) => this.onChange(field.key, value)}
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
          />
        );
      case "phone":
        return (
          <InputPhoneNumber
            key={k}
            field={field}
            data={data}
            errorMessages={errors}
            className={styles.input}
            focussed={isFocused}
            error={showErrors ? !!errors : false}
            onChange={(value) => {
              this.onChange(field.key, value);
            }}
            isFocused={() => this.setState({ isFocused: true })}
            isBlurred={() => {
              this.setState({ isFocused: false }, () => {
                this.validateData();
              });
            }}
          />
        );
      case "nested":
        return field.children.map((child) => (
          <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            label={tl(child.label)}
            placeholder={t(child.label)}
            margin="dense"
            key={child.key}
            data-testmation={child.key}
            className={styles.input}
            onFocus={() => this.setState({ isFocused: true })}
            error={showErrors && errors ? !!errors[child.key] : false}
            value={data[field.key] ? data[field.key][child.key] : ""}
            required={hasOwnProperty(child, "required") ? child.required : true}
            onBlur={() => {
              this.setState({ isFocused: false }, () => {
                this.validateData();
              });
            }}
            helperText={
              showErrors && errors ? (
                <>
                  {(errors[child.key] || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
            onChange={(e) => {
              const { onChange } = this.props;
              const updatedData = cloneDeep(data);
              if (!updatedData[field.key]) updatedData[field.key] = {};
              updatedData[field.key][child.key] = e.target.value;
              this.setState({ data: updatedData });
              if (onChange) onChange(updatedData);
            }}
          />
        ));

      default:
        return (
          <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder={t(field.label)}
            disabled={field.disabled}
            margin="dense"
            autoCapitalize="none"
            data-testmation={field.key}
            key={field.key}
            error={showErrors ? !!errors : false}
            helperText={
              showErrors ? (
                <>
                  {(errors || []).map((er) => (
                    <React.Fragment key={er}>
                      - {er} <br />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                ""
              )
            }
            className={styles.input}
            onFocus={() => this.setState({ isFocused: true })}
            onBlur={() => {
              this.setState({ isFocused: false }, () => {
                this.validateData();
              });
            }}
            type={field.inputType || ""}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(field.inputType === "password" ? { autoComplete: "new-password" } : {})}
            label={tl(field.label)}
            required={hasOwnProperty(field, "required") ? field.required : true}
            value={this.getFieldValue(field)}
            onChange={(e) => {
              let { value } = e.target;
              if (field.inputType === "bsDate") {
                value = field.reverseFormatter(value);
              }

              if (
                (field.label === "firstName" ||
                  field.label === "lastName" ||
                  field.label === "name") &&
                field.inputType !== "bsDate"
              ) {
                value = capitalizedName(value);
              }

              this.onChange(field.key, value);
            }}
          />
        );
    }
  }

  render(): JSX.Element {
    const { fields, title, classNames = {}, footer, disableFlex } = this.props;
    return (
      <div className={`${disableFlex ? "" : styles.root} ${classNames.root}`}>
        <div className={`${styles.paper} ${classNames.paper}`}>
          {title}
          {fields.map((f, k) => this.createField(f, k))}
          {footer}
        </div>
      </div>
    );
  }
}

Form.defaultProps = {
  footer: <></>,
  title: <></>,
  classNames: {},
  onChange: () => ({}),
  onSave: () => ({}),
  translator: <></>,
  showErrors: true,
  disableFlex: false
};

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { clientFullNameSelector } from "../../reducers/client";
import List from "../../components/List";
import * as assessmentActions from "../../actions/assessments";
import { RootState } from "../../store";
import AssessmentListSearch from "./AssessmentListSearch";
import { Client } from "../../interfaces/ClientInterface";
import PageControl from "../../components/PageControl";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import {
  medicationFormatter,
  medicationRemarkFormatter,
  opdDurationFormatter,
  spNameFormatter,
  symptomsFormatter
} from "../Reports/PatientAssessments";
import AssessmentInfoPanel from "./AssessmentInfoPanel";
import { Assessment } from "../../interfaces/AssessmentInterfaces";
import { getAssessmentById } from "../../api/assessment";
import { notificationAdd } from "../../actions/notification";
import useQueryParams from "../../hooks/useQueryParams";
import { getCustomerNumber } from "../Client/ClientList";
import useMobileScreen from "../../hooks/useMobileScreen";
import { t } from "../../components/translate";
import DiagnosisRenderer from "../Modals/Assessment/DiagnosisRenderer";

export const pageSize = 20;

export default function AssessmentList({
  setAssessmentCopy
}: {
  setAssessmentCopy: React.Dispatch<React.SetStateAction<Assessment>>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({
    client: {} as Client,
    searchQuery: ""
  });

  const [assessmentInfoPanel, setAssessmentInfoPanel] = React.useState<{
    show: boolean;
    assessmentId: null | number;
    data: null | Assessment;
  }>({
    show: false,
    assessmentId: null,
    data: null
  });

  const assessments = useSelector((state: RootState) => state.assessments?.collection);

  const [page, setPage] = React.useState(0);
  const query = useQueryParams();
  const assessmentId = query.get("id");
  const isMobileView = useMobileScreen();

  useEffect(() => {
    setAssessmentInfoPanel({
      show: true,
      assessmentId,
      data: null
    });
  }, [assessmentId]);

  useEffect(() => {
    dispatch(
      assessmentActions.getAssessments({
        clientId: searchParams.client?.id,
        searchQuery: searchParams.searchQuery,
        page,
        pageSize
      })
    );
  }, [searchParams, page, pageSize]);

  useEffect(() => {
    const fetchSelectedAssesment = async () => {
      try {
        const assessment = await getAssessmentById(assessmentInfoPanel.assessmentId);
        setAssessmentInfoPanel({
          ...assessmentInfoPanel,
          data: assessment
        });
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get selected assessment.",
            autoTimeout: true
          })
        );
      }
    };
    if (assessmentInfoPanel.assessmentId) {
      fetchSelectedAssesment();
    }
  }, [assessmentInfoPanel.assessmentId]);

  const customButtonGroup = (
    <Button
      onClick={() => dispatch(push("/assessment/new"))}
      data-testmation="createOPD"
      variant="contained"
    >
      {isMobileView ? "Create" : "Create New Prescription"}
    </Button>
  );

  return (
    <Box height="calc(100% - 44px)">
      <Box height="100%">
        <List<Assessment>
          automation="opd"
          data={assessments?.results || []}
          hideCreateButton
          customButtonGroup={customButtonGroup}
          rowHeight={50}
          withoutSearch
          defaultSortColumn="id"
          defaultSortOrder={-1}
          onRowClick={(row) => {
            const showPanel =
              !assessmentInfoPanel.data?.id || assessmentInfoPanel.data.id !== row.id;
            setAssessmentInfoPanel({
              show: showPanel,
              assessmentId: row.id,
              data: null
            });
          }}
          additionalHeaderFilters={
            <AssessmentListSearch
              selectedClient={searchParams.client}
              setSearchParams={setSearchParams}
              onQueryChange={(value) => {
                setSearchParams({ client: {} as Client, searchQuery: value });
              }}
            />
          }
          isResponsive
          columns={[
            {
              key: "id",
              label: `${t("id")}`,
              sortable: false,
              formatter: ({ id }) => <Typography>{id}</Typography>
            },
            {
              key: "name",
              label: `${t("customer")}`,
              formatter: ({ assessmentDocument }) => (
                <Typography
                  component="div"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start"
                  }}
                >
                  <Typography>
                    {clientFullNameSelector(assessmentDocument?.client).toUpperCase()}
                  </Typography>
                  <Typography fontSize="small" color="gray">
                    {getCustomerNumber(assessmentDocument.customerNumber)}
                  </Typography>
                </Typography>
              )
            },
            {
              key: "serviceProvider",
              label: `${t("serviceProvider")}`,
              hideInNarrowView: true,
              sortable: true,
              formatter: ({ assessmentDocument }) => (
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  title={spNameFormatter(assessmentDocument.serviceProvider)}
                >
                  {spNameFormatter(assessmentDocument.serviceProvider)}
                </Typography>
              ),
              segmentBy: spNameFormatter
            },
            {
              key: "date",
              label: `${t("date")}`,
              sortable: true,
              sortFunction: (a, b) => (new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1),
              formatter: ({ createdDate }) => (
                <Typography>{`${calFns.convertADtoBS(moment(createdDate)).formatted2}`}</Typography>
              )
            },
            {
              key: "symptoms",
              label: `${t("symptoms")}`,
              hideInNarrowView: true,
              formatter: ({ assessmentDocument }) => (
                <Typography width={150} height={40} overflow="hidden" paddingRight={5}>
                  {symptomsFormatter(assessmentDocument)}
                </Typography>
              )
            },
            {
              key: "opdDuration",
              label: `${t("OPDDuration")}`,
              sortable: true,
              hideInNarrowView: true,
              formatter: ({ assessmentDocument }) => (
                <Typography>
                  {opdDurationFormatter(Number(assessmentDocument.opdDuration))}
                </Typography>
              )
            },
            {
              key: "diagnosis",
              label: `${t("Diagnosis")}`,
              hideInNarrowView: true,
              formatter: ({ assessmentDocument }) => (
                <DiagnosisRenderer data={assessmentDocument.diagnosis} renderInline />
              )
            },
            {
              key: "medication",
              label: `${t("Medication")}`,
              hideInNarrowView: true,
              formatter: ({ assessmentDocument }) => (
                <Typography width={150} overflow="hidden">
                  {medicationFormatter(assessmentDocument)}
                </Typography>
              )
            },
            {
              key: "medicationRemark",
              label: `${t("MedicationRemark")}`,
              hideInNarrowView: true,
              formatter: ({ assessmentDocument }) => (
                <Typography
                  width={220}
                  overflow="hidden"
                  title={medicationRemarkFormatter(assessmentDocument)}
                >
                  {medicationRemarkFormatter(assessmentDocument)}
                </Typography>
              )
            }
          ]}
        />
      </Box>
      <Box height="40px" borderTop="1px solid lightgray" display="flex" justifyContent="flex-end">
        <Box width="200px">
          <PageControl
            page={page}
            onSetPage={(val) => {
              setPage(val);
            }}
            pageSize={pageSize}
            maximumDataCount={assessments?.total}
            allowNextAtEnd
            endAlertMessage="The assessments shown here are created in past 30 days. If you want to view older assessments, Please visit Patient Prescription Report."
            pushTo="/reports/patient/patientAssessmentReport"
          />
        </Box>
      </Box>
      {assessmentInfoPanel.show && assessmentInfoPanel.data && (
        <AssessmentInfoPanel
          onClose={() => setAssessmentInfoPanel({ show: false, data: null, assessmentId: null })}
          assessment={assessmentInfoPanel.data}
          setAssessmentCopy={setAssessmentCopy}
        />
      )}
    </Box>
  );
}

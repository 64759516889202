import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { t } from '../../components/translate';
import { TextField } from '@mui/material';
import { DocumentTypes } from '../../interfaces/BillInterfaces';

export const BillTypeAutoSelect = ({ billType, onChange, variant = 'standard' }) => {
  return (
    <Autocomplete
      value={billType}
      options={[DocumentTypes.INVOICE, DocumentTypes.CREDITNOTE]}
      getOptionLabel={(option) => t(`billing.${option}`)}
      onChange={(e, value) => {
        onChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          margin="dense"
          label={t('billType')}
          placeholder={t('reports.billType')}
          fullWidth
        />
      )}
    />
  );
};

import React from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getSummaryReports from "../../../api/summaryReport";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import SummaryReportTable from "./SummaryReportTable";
import Filters from "../Filters";
import { resourceCentreActions } from "../../../actions";
import { SUBSCRIPTION_STATUS_FILTER } from "../../ResourceCentre";

function SuperAdminSummaryReport({ resourceCentres, actions }) {
  const [summaryReportData, setSummaryReportData] = React.useState([]);

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(moment().subtract(1, "days")),
    until: calFns.endOfDay(moment().subtract(1, "days")),
    clinicSelect: null,
    subscriptionStatus: SUBSCRIPTION_STATUS_FILTER.ACTIVE,
    showInSingleRow: false
  });

  React.useEffect(() => {
    getSummaryReports({
      id: filters.clinicSelect,
      from: filters.from,
      until: filters.until,
      subscriptionStatus: filters.subscriptionStatus,
      showInSingleRow: filters.showInSingleRow
    }).then((response) => setSummaryReportData(response));
    if (!resourceCentres?.length) {
      actions.getResourceCentres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Box marginTop="32px" marginBottom="40px">
      <Box margin="0px 32px">
        <Box marginTop="32px">
          <Filters filters={filters} onSetFilters={(newFilter) => setFilters(newFilter)} />
        </Box>
      </Box>
      <SummaryReportTable
        summaryReportData={summaryReportData}
        isSuperAdmin
        showInSingleRow={filters.showInSingleRow}
        resourceCentres={resourceCentres}
      />
    </Box>
  );
}

export default connect(
  (state) => ({ resourceCentres: state.resources.resourceCentres }),
  (dispatch) => ({
    actions: bindActionCreators(resourceCentreActions, dispatch)
  })
)(SuperAdminSummaryReport);

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { debounce } from "lodash";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import { Control, Controller, FieldValues, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneNumber from "awesome-phonenumber";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import MaterialUiPhoneNumber from "../../components/PhoneNumber";
import ToggleInputDate from "../../components/ToggleADBS";
import FormTextField from "../../components/FormTextField/FormTextField";
import { CustomerType } from "../../interfaces/Accounts";
import { bsDateField } from "../../models/Accounts";
import { RootState } from "../../store";
import { ledgerFindBySubLedgerType } from "../accounts/hooks";
import AddressSelect from "../Address/AddressSelect";
import {
  ethnicityOptions,
  genderOptions,
  maritalStatusOptions,
  fields,
  bloodGroups
} from "../../models/Client";
import { isISODateString } from "../../helpers/validators";
import { uploadClientImage } from "../../actions/client";
import { Client as ClientInterface } from "../../interfaces/ClientInterface";
import { getRCFromState } from "../../actions/resourceCentre";
import { isNewPhoneNumber, isStartWith970 } from "../../helpers/phoneNumber";
import ClientImageUploader from "../../components/ImageUploader/ClientImageUploader";
import useAssociateCompanies from "../../hooks/useAssociateCompanies";
import { AssociateCompany } from "../../api/associateCompanies";
import AssociateCompanyCreateSelect from "../../components/AssociateCompanyCreateSelect/Index";
import KnownUsFromSelect from "../../components/KnownUsFromSelect/KnownUsFromSelect";
import AccountAndLedgerFields from "./AccountAndLedgerFields";
import { getFullName } from "../../helpers/formatters";
import { getClientsWithPhone } from "../../api/client";
import { notificationAdd } from "../../actions/notification";
import { infoIconColor } from "../Lab/LabTestCreate/AddLabtests";
import { t } from "../../components/translate";

interface ClientFormPropsInterface {
  client: ClientInterface;
  mode: string;
  formState: Partial<ClientInterface>;
  control: Control<FieldValues>;
  errors: FieldErrors<ClientInterface>;
  fromBill?: boolean;
  isCorporateClient: boolean;
  sameClient: ClientInterface;
  setSameClient: (data) => void;
}

const useStyles = makeStyles({
  root: { marginTop: "10px" },
  input: { margin: "10px 0" },
  imgUpload: {
    display: "flex",
    justifyContent: "center"
  }
});

const isValidPhone = (value) => {
  const ph = new PhoneNumber(value);
  const phoneNumber = ph.getNumber("significant");
  if (ph.getCountryCode() === 977 && isStartWith970(value)) {
    return isNewPhoneNumber(phoneNumber);
  }
  return ph.isValid() && ph.isMobile();
};

const Form = ({
  client,
  mode,
  control,
  errors,
  formState,
  fromBill = false,
  isCorporateClient,
  sameClient,
  setSameClient
}: ClientFormPropsInterface): React.ReactElement => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState(client);
  const formSettings = useSelector(
    (state: RootState) => state.resources.resourceCentres[0]?.settings?.formSettings || {}
  );
  const clientImage = useWatch({
    control,
    name: "image"
  });
  const { requireKnownUsFrom, requirePhoneNumber, allowClientDuplication } = formSettings;
  const { isAccountSubscribed, requiredEmail } = useSelector((state: RootState) => ({
    isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed,
    requiredEmail: state.resources?.resourceCentres[0]?.settings?.formSettings?.requireEmail
  }));

  const allowUpload = useSelector((state: RootState) => {
    const rc = getRCFromState(state);
    return rc?.settings.clientSettings.enablePictureUpload;
  });

  const ledgerForClient = ledgerFindBySubLedgerType(CustomerType.CLIENT);

  const fullNameRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (mode === "create" && isAccountSubscribed && ledgerForClient) {
      setFormData((prevData) => ({
        ...prevData,
        ledgerId: ledgerForClient.id
      }));
    }
  }, [mode, isAccountSubscribed, ledgerForClient]);

  React.useEffect(() => {
    if (fromBill) {
      fullNameRef.current.select();
    }
  }, [fromBill]);

  const [selectedCompany, setSelectedCompany] = React.useState<AssociateCompany | null>(null);
  const [clientsWithSamePhone, setClientsWithSamePhone] = React.useState({
    phone: "",
    results: []
  });

  const companies = useAssociateCompanies();

  React.useEffect(() => {
    if (client?.associatedCompanyId) {
      const associatedCompany = companies.find(
        (company) => company.id === client.associatedCompanyId
      );
      setSelectedCompany(associatedCompany || null);
    }
  }, [client?.associatedCompanyId, companies]);

  React.useEffect(() => {
    const fetchClientWithPhone = async () => {
      try {
        const clients = await getClientsWithPhone({ phone: formState.phone });
        setClientsWithSamePhone({
          phone: formState.phone,
          results: mode === "edit" ? clients.filter((c) => c.id !== formState.id) : clients
        });
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: err?.data?.message || "Failed to get clients with the phone number",
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    };
    const debouncedFetch = debounce(fetchClientWithPhone, 1000);
    if (
      formState.phone &&
      formState.phone !== clientsWithSamePhone.phone &&
      isValidPhone(formState.phone) &&
      !allowClientDuplication
    ) {
      debouncedFetch();
    }
  }, [
    formState.phone,
    clientsWithSamePhone.phone,
    formState.firstName,
    mode,
    formState.id,
    dispatch,
    allowClientDuplication
  ]);

  React.useEffect(() => {
    const clientData = clientsWithSamePhone.results.find((clientInfo) => {
      const clientFullName = `${clientInfo.firstName} ${clientInfo.lastName}`;
      return (
        clientInfo.phone === formState.phone &&
        clientFullName?.trim().toLowerCase() === formState?.fullName?.trim().toLowerCase()
      );
    });
    setSameClient(clientData || null);
  }, [clientsWithSamePhone.results, formState?.fullName, formState?.phone, setSameClient]);

  return (
    <Box className={styles.root}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        {!isCorporateClient && allowUpload && (
          <Grid item xs={12} mb={1}>
            <Box className={styles.imgUpload}>
              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <ClientImageUploader
                    action={(image) => {
                      field.onChange(image);
                      if (client?.id && mode === "edit") {
                        dispatch(uploadClientImage(client.id, image));
                      }
                    }}
                    initialImage={
                      (clientImage && URL.createObjectURL(clientImage)) ||
                      client?.customerDetails?.profileImageS3Url ||
                      ""
                    }
                  />
                )}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <FormTextField
            dataTestmation="fullNameInput"
            fullWidth
            control={control}
            inputRef={fullNameRef}
            name="fullName"
            errors={errors}
            rules={{
              required: "Full name can't be empty."
            }}
            label={t("CreateClient.FullName")}
            placeholder={t("CreateClient.EgName")}
          />
        </Grid>

        <Grid item xs={12} sm={6} display="flex" gap={1}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: requirePhoneNumber ? "Phone number is required" : false,
              validate: (value) =>
                (value ? isValidPhone(value) : true) || "Please enter a valid phone number"
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <MaterialUiPhoneNumber
                data-testmation="clientPhoneNumberInput"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                fullWidth
                disableAreaCodes
                variant="outlined"
                defaultCountry="np"
                errorMessages={errors?.phone}
                error={!!errors?.phone}
                label={t("CreateClient.PhoneNo.")}
                helperText={errors?.phone?.message || ""}
              />
            )}
          />
          {clientsWithSamePhone.results.length > 0 && (
            <Box mt={1}>
              <Tooltip
                title={
                  <Box>
                    <Typography>
                      {`Following clients found with same phone number${
                        sameClient ? " and name!" : "!"
                      }`}
                    </Typography>
                    {sameClient ? (
                      <Box display="flex">
                        <Typography width="120px">{`- ${getFullName(sameClient)}`}</Typography>
                      </Box>
                    ) : (
                      clientsWithSamePhone.results.map((clientData, i) => (
                        <Box key={clientData.id} display="flex">
                          <Typography width="120px">{`${i + 1}. ${getFullName(
                            clientData
                          )}`}</Typography>
                        </Box>
                      ))
                    )}
                  </Box>
                }
              >
                <InfoIcon
                  sx={{
                    color: infoIconColor,
                    cursor: "pointer"
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            dataTestmation="emailInput"
            fullWidth
            control={control}
            name="email"
            errors={errors}
            rules={{
              required: requiredEmail ? "Email is Required" : false,
              pattern: {
                value:
                  // eslint-disable-next-line max-len
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter the valid email"
              }
            }}
            label={`${t("clients.email")} ${requiredEmail ? "*" : ""}`}
            placeholder="Eg: Email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="knownUsFrom"
            rules={{
              required: {
                value: requireKnownUsFrom && mode !== "edit",
                message: "Known Us From is required"
              }
            }}
            render={({ field, fieldState }) => (
              <KnownUsFromSelect
                required={requireKnownUsFrom && mode !== "edit"}
                error={{
                  value: Boolean(fieldState.error),
                  message: fieldState.error?.message || ""
                }}
                value={formState.knownUsFrom || formData?.knownUsFrom}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                isLabelShrunk
              />
            )}
          />
        </Grid>

        {!isCorporateClient && (
          <>
            <Grid item xs={12} sm={8}>
              <Controller
                name="dob"
                control={control}
                rules={{
                  validate: (value) =>
                    (value ? !!isISODateString(value) : true) || "Please enter valid date"
                }}
                render={({ field }) => (
                  <ToggleInputDate
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    data-testmation="clientAgeInput"
                    key="dob"
                    field={fields[4]}
                    data={{
                      dob: formState.dob
                    }}
                    changeDate={(value) => {
                      field.onChange(value);
                    }}
                    label="dob"
                    error={!!errors?.dob?.message}
                    helperText={errors?.dob?.message || ""}
                    isFocused={() => ({})}
                    isBlurred={() => ({})}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: "Gender is Required"
                }}
                render={({ field }) => (
                  <TextField
                    data-testmation="clientGenderSelect"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    label={t("CreateClient.Gender")}
                    placeholder={t("CreateClient.Gender")}
                    select
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors?.gender}
                    helperText={errors?.gender?.message || " "}
                  >
                    {genderOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        data-testmation={`option${option.value}`}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <FormTextField
            dataTestmation="addressInput"
            fullWidth
            control={control}
            name="address"
            errors={errors}
            label={t("CreateClient.Address")}
            placeholder={t("CreateClient.EgAddress")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="palikaId"
            control={control}
            render={({ field }) => (
              <AddressSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                selectedOption={formState.palikaId}
                cssStyle={{ marginTop: "0px" }}
              />
            )}
          />
        </Grid>
        {!isCorporateClient && (
          <>
            <Grid item xs={12} sm={6}>
              <FormTextField
                dataTestmation="internalNotesInput"
                fullWidth
                control={control}
                name="internalNotes"
                errors={errors}
                label={t("CreateClient.InternalNotes")}
                placeholder={t("CreateClient.EgInternalNotes")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                dataTestmation="insuranceNumberInput"
                fullWidth
                control={control}
                name="insuranceNumber"
                errors={errors}
                label={t("CreateClient.InsuranceNumber")}
                placeholder={t("CreateClient.EgInsuranceNumber")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                dataTestmation="nationalIdentityInput"
                fullWidth
                control={control}
                name="nationalIdentityNumber"
                errors={errors}
                label={t("CreateClient.NIDNo.")}
                placeholder={t("CreateClient.EgNIDNo")}
                rules={{
                  pattern: { value: /^\d+$/, message: "Please enter valid nin" }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                dataTestmation="registrationNoInput"
                fullWidth
                control={control}
                name="registrationNo"
                errors={errors}
                label={t("CreateClient.RegNo.")}
                placeholder={t("CreateClient.EgRegNo.")}
              />
            </Grid>
          </>
        )}
        {isCorporateClient && (
          <>
            <Grid item xs={12} sm={6}>
              <FormTextField
                dataTestmation="panVatInput"
                fullWidth
                control={control}
                name="panVatNumber"
                errors={errors}
                label="PAN/VAT Number"
                placeholder="Enter pan/vat number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                dataTestmation="paymentTermInput"
                fullWidth
                control={control}
                name="paymentTerm"
                errors={errors}
                label="Payment Term (In Days)"
                rules={{
                  pattern: { value: /^\d+$/, message: "Please enter valid days" }
                }}
                placeholder="Enter payment term in days"
              />
            </Grid>
            {isAccountSubscribed && (
              <Grid item xs={12}>
                <Accordion sx={{ mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ color: "primary.main" }}>Account</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Grid container rowSpacing={1} columnSpacing={1}>
                        <AccountAndLedgerFields
                          ledgerForClient={ledgerForClient}
                          formData={formData}
                          control={control}
                          errors={errors}
                          formState={formState}
                        />
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {!isCorporateClient && (
        <Box mb={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: "primary.main" }} data-testmation="moreFields">
                {t("CreateClient.More")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="secondaryPhone"
                      control={control}
                      rules={{
                        validate: (value) =>
                          (value ? isValidPhone(value) : true) ||
                          "Please enter a valid phone number"
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <MaterialUiPhoneNumber
                          data-testmation="clientSecondaryPhoneNumberInput"
                          onChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          fullWidth
                          disableAreaCodes
                          variant="outlined"
                          defaultCountry="np"
                          errorMessages={errors?.secondaryPhone}
                          error={!!errors?.secondaryPhone}
                          label={t("CreateClient.AdditionalPhone")}
                          helperText={errors?.secondaryPhone?.message || " "}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="associatedCompanyId"
                      render={({ field }) => (
                        <AssociateCompanyCreateSelect
                          initialValue={selectedCompany}
                          onChange={(value) => {
                            field.onChange(value?.id || null);
                            setSelectedCompany(value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      dataTestmation="occupationInput"
                      fullWidth
                      control={control}
                      name="occupation"
                      errors={errors}
                      label={t("CreateClient.Occupation")}
                      placeholder={t("CreateClient.EgOccupation")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="maritalStatus"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          data-testmation="maritalStatusSelect"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          label={t("CreateClient.MaritalStatus")}
                          select
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.maritalStatus}
                          helperText={errors?.maritalStatus?.message || ""}
                        >
                          {maritalStatusOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              id={`unqxsmi-${option.value}`}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      dataTestmation="bloodGroupInput"
                      fullWidth
                      control={control}
                      name="bloodGroup"
                      errors={errors}
                      label={t("CreateClient.BLoodGroup")}
                      placeholder={t("CreateClient.EgBloodGroup")}
                      select
                      options={bloodGroups}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="ethnicity"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          data-testmation="ethnicitySelect"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          label={t("CreateClient.Ethnicity")}
                          select
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={!!errors?.ethnicity}
                          helperText={errors?.ethnicity?.message || ""}
                        >
                          {ethnicityOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              id={`unqxsmi-${option.value}`}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      dataTestmation="nationalityInput"
                      fullWidth
                      control={control}
                      name="nationality"
                      errors={errors}
                      label={t("CreateClient.Nationality")}
                      placeholder={t("CreateClient.EgNationality")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      dataTestmation="appliedCountryInput"
                      fullWidth
                      control={control}
                      name="appliedCountry"
                      errors={errors}
                      label={t("CreateClient.AppliedCountry")}
                      placeholder={t("CreateClient.EgAppliedCountry")}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: "primary.main" }} data-testmation="passportFields">
                {t("CreateClient.Passport")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      dataTestmation="passportNumberInput"
                      fullWidth
                      control={control}
                      name="passportInfo.passportNumber"
                      errors={errors}
                      label={t("CreateClient.PassportNo.")}
                      placeholder={t("CreateClient.EgPassportNo.")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      dataTestmation="passportIssuePlaceInput"
                      fullWidth
                      control={control}
                      name="passportInfo.issuingAuthority"
                      errors={errors}
                      label={t("CreateClient.PassportIssuePlace")}
                      placeholder={t("CreateClient.EgPassportIssuePlace")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="passportInfo.issueDate"
                      control={control}
                      rules={{
                        validate: (value) =>
                          (value ? !!isISODateString(value) : true) || "Please enter valid date"
                      }}
                      render={({ field }) => (
                        <ToggleInputDate
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          data-testmation="passportIssueDate"
                          key="passportIssueDate"
                          field={{ ...bsDateField, key: "passportIssueDate" }}
                          data={{
                            passportIssueDate: formState.passportInfo?.issueDate || ""
                          }}
                          changeDate={(value) => {
                            field.onChange(value);
                          }}
                          label="passportIssueDate"
                          error={!!errors?.passportInfo?.issueDate?.message}
                          helperText={errors?.passportInfo?.issueDate?.message || ""}
                          isFocused={() => ({})}
                          isBlurred={() => ({})}
                          showAgeField={false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="passportInfo.expiryDate"
                      control={control}
                      rules={{
                        validate: (value) =>
                          (value ? !!isISODateString(value) : true) || "Please enter valid date"
                      }}
                      render={({ field }) => (
                        <ToggleInputDate
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          data-testmation="passportExpiryDate"
                          key="passportExpiryDate"
                          field={{ ...bsDateField, key: "passportExpiryDate" }}
                          data={{
                            passportExpiryDate: formState.passportInfo?.expiryDate || ""
                          }}
                          changeDate={(value) => {
                            field.onChange(value);
                          }}
                          label="passportExpiryDate"
                          error={!!errors?.passportInfo?.expiryDate?.message}
                          helperText={errors?.passportInfo?.expiryDate?.message || ""}
                          isFocused={() => ({})}
                          isBlurred={() => ({})}
                          showAgeField={false}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {isAccountSubscribed && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ color: "primary.main" }} data-testmation="accountFields">
                  Account
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <AccountAndLedgerFields
                      ledgerForClient={ledgerForClient}
                      formData={formData}
                      control={control}
                      errors={errors}
                      formState={formState}
                    />
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      )}
    </Box>
  );
};

Form.defaultProps = {
  fromBill: false
};
export default Form;

import axios from "axios";
import { Get, GetCached, Post, Delete, Put } from "./apiHelper";
import { ReverseMap } from "../helpers/types";
import { ICD } from "../interfaces/AssessmentInterfaces";

const servicesRoot = "/api/symptoms";

const diagnosisRoot = "/api/icd";

const labTest = "/api/labTests1";

const assessmentsRoot = "/api/assessments";

const assessmentRoot = "/api/assessment";

export const assessmentAttachmentsUrl = (assessmentId: number): string => {
  if (assessmentId) {
    return `/api/attachments/assessment/${assessmentId}`;
  }
  return `/api/attachments/assessment`;
};

export const deleteAttachmentEndPoint = (): string => `/api/attachments/assessment`;

export async function getBatchSymptoms(forceUpdate?: boolean): Promise<any> {
  const response = await GetCached({
    url: servicesRoot,
    forceUpdate
  });
  return response.data;
}

export async function getBatchDiagnosis(): Promise<ICD[]> {
  const response = await Get(`${diagnosisRoot}`, true);

  return response.data as ICD[];
}

export async function getLabTest(forceUpdate?: boolean): Promise<any> {
  const response = await GetCached({ url: `${labTest}`, forceUpdate });

  return response.data;
}

export async function saveAssessment(data: unknown): Promise<any> {
  const response = await Post(`${assessmentsRoot}`, data, true);

  return response.data;
}

export async function getAssessmentById(assessmentId: number): Promise<any> {
  const response = await Get(`${assessmentsRoot}/${assessmentId}`, true);
  return response.data;
}

export async function getAssessmentByUUID(assessmentId: number): Promise<any> {
  const response = await Get(`${assessmentsRoot}/byUUID/${assessmentId}`, false);
  return response.data;
}

export async function updateAssessment(
  assessmentId: number,
  assessmentData: unknown
): Promise<any> {
  const response = await Put(`${assessmentsRoot}/${assessmentId}`, assessmentData, true);
  return response.data;
}

export async function deleteAssessment(assessmentId: number): Promise<any> {
  const response = await Delete(`${assessmentsRoot}/${assessmentId}`, true);
  return response.data;
}

export async function getAssessments({
  clientId,
  searchQuery,
  page,
  pageSize,
  limit
}: {
  clientId?: number;
  searchQuery?: string;
  limit?: number;
  pageSize?: number;
  page?: number;
}): Promise<any> {
  const response = await Get(
    `${assessmentsRoot}/?${clientId ? `clientId=${clientId}` : ""}${
      searchQuery ? `&searchQuery=${searchQuery}` : ""
    }&page=${page || 0}${pageSize ? `&pageSize=${pageSize}` : ""}${limit ? `&limit=${limit}` : ""}`,
    true
  );
  return response.data;
}

export async function getRecentAssessments(id: number): Promise<any> {
  const response = await Get(`${assessmentsRoot}/recent/?clientId="${id}`, true);
  return response.data;
}

export async function getChildren(id: number): Promise<ICD[]> {
  const response = await Get(`${diagnosisRoot}/${id}/children`, true);
  return response.data as ICD[];
}
export async function getSibblings(id: number): Promise<any> {
  const response = await Get(`${diagnosisRoot}/${id}/parents`, true);
  return response.data;
}
export async function searchDisease(diseaseName: string): Promise<ICD[]> {
  const response = await Get(`${diagnosisRoot}/search/?q=${diseaseName}`, true);

  return response.data as ICD[];
}

export async function saveTemplateAssessment(data: unknown): Promise<any> {
  const response = await Post(`${assessmentRoot}/template`, data, true);

  return response.data;
}
export async function getTemplateAssessment(): Promise<any> {
  const response = await Get(`${assessmentRoot}/template`, true);

  return response.data;
}
export async function searchDiagnosis(diagnosisName: string): Promise<any> {
  const response = await Get(`${diagnosisRoot}/search/?q=${diagnosisName}`, true);

  return response.data;
}

enum S3UploadDataType {
  AUDIO = "assessmentAudio",
  IMAGE = "assessmentImage"
}
type S3UploadDataTypes = ReverseMap<typeof S3UploadDataType>;

export const getS3UploadPostData = async (
  fileName: string,
  type: S3UploadDataTypes
): Promise<unknown> => {
  const response = await Post(`${assessmentRoot}/${type}`, { fileName });
  return response.data;
};

// convert base64 string to blob
export const dataURLtoBlob = (dataURL: string): Blob => {
  const byteString = atob(dataURL.split(",")[1]);
  const arrayBufferObj = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBufferObj);
  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBufferObj], { type: "image/svg+xml" });
};

export const uploadAssessmentImage = async (pictureData: string): Promise<unknown> => {
  const assessmentImageInfo = {
    assessmentImageKey: "",
    assessmentImage: ""
  };
  try {
    const fileName = `${Date.now()}-assessment.svg`;
    const postData = (await getS3UploadPostData(fileName, S3UploadDataType.IMAGE)) as {
      id: number;
      data: Record<string, unknown>;
    };
    const s3FileName = `${postData.id}/${fileName}`;
    const formData = new FormData();
    [
      "key",
      "bucket",
      "acl",
      "X-Amz-Algorithm",
      "X-Amz-Credential",
      "X-Amz-Date",
      "Policy",
      "X-Amz-Signature"
    ].forEach((k) => {
      formData.append(k, postData.data.fields[k]);
    });
    formData.append("Content-Type", "image/svg+xml");
    const blobImage = dataURLtoBlob(pictureData);

    formData.append("file", blobImage, s3FileName);
    const response = await axios.post(postData.data.url, formData, {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    });
    assessmentImageInfo.assessmentImage =
      response.headers.location || `${postData.data.url}/${postData.data.fields.key}` || "";
    assessmentImageInfo.assessmentImageKey = postData.data.fields.key;
    return assessmentImageInfo;
  } catch (err) {
    assessmentImageInfo.assessmentImage = "";
    assessmentImageInfo.assessmentImageKey = "";
    return assessmentImageInfo;
  }
};

export const uploadAssessmentAudio = async (blobURI: string): Promise<unknown> => {
  const assessmentAudioInfo = {
    prescriptionAudioUrl: ""
  };

  try {
    const fileName = `${Date.now()}-assessment.webm`;
    const postData = (await getS3UploadPostData(fileName, S3UploadDataType.AUDIO)) as {
      id: number;
      data: Record<string, unknown>;
    };

    const s3FileName = `${postData.id}/${fileName}`;
    const formData = new FormData();
    [
      "key",
      "bucket",
      "acl",
      "X-Amz-Algorithm",
      "X-Amz-Credential",
      "X-Amz-Date",
      "Policy",
      "X-Amz-Signature"
    ].forEach((key) => {
      formData.append(key, postData.data.fields[key]);
    });

    const blobAudio = await fetch(blobURI).then((r) => r.blob());
    formData.append("Content-Type", "audio/webm;codecs=opus");
    formData.append("file", blobAudio, s3FileName);

    await axios.post(postData.data.url, formData, {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    });
    assessmentAudioInfo.prescriptionAudioUrl = `${postData.data.url}/${postData.data.fields.key}`;
    return assessmentAudioInfo;
  } catch (err) {
    return assessmentAudioInfo;
  }
};

import React from "react";
import { Box } from "@mui/material";
import Barcode from "react-barcode";
import InfoField from "./LabPrintInfofield";
import { getSettingBasedDate, scaleFont } from "../LabPrintFunctions";

const ModifiedInfoField = ({
  label,
  data,
  fontScale
}: {
  label: string;
  data: JSX.Element | string;
  fontScale: number;
}) => (
  <InfoField
    label={label}
    data={data}
    labelStyle={{ width: "2.9cm", fontSize: scaleFont("0.28cm", fontScale) }}
    valueStyle={{ fontSize: scaleFont("0.28cm", fontScale) }}
  />
);

// used in fixed footer (repeating) template, a5 and block
const ModifiedInfoField1 = ({
  label,
  data,
  fontScale
}: {
  label: string;
  data: JSX.Element | string;
  fontScale: number;
}) => (
  <InfoField
    label={label}
    data={data}
    labelStyle={{ width: "2.9cm", fontSize: scaleFont("0.3cm", fontScale) }}
    valueStyle={{ fontSize: scaleFont("0.3cm", fontScale) }}
  />
);

export interface LabPrintLabInfoProps {
  dateSetting: string;
  labTestId?: string | number;
  collectionDate: string;
  sampleRemarks: string;
  resultDate: string;
  labIdentifier?: string;
  showPrintedOnTime: boolean;
  showReportOnTime: boolean;
  showSampleTakenTime: boolean;
  showPrintedOnDate: boolean;
  showReportOnDate: boolean;
  showSampleTakenDate: boolean;
  modifiedDate: string;
  mfField?: "mf" | "mf1";
  fontScale?: number;
}

const mfs = {
  mf: ModifiedInfoField,
  mf1: ModifiedInfoField1
};

const LabPrintLabInfo: React.FC<LabPrintLabInfoProps> = ({
  dateSetting,
  showPrintedOnTime,
  showReportOnTime,
  showSampleTakenTime,
  collectionDate,
  sampleRemarks,
  resultDate,
  labTestId,
  labIdentifier,
  modifiedDate,
  showSampleTakenDate,
  showPrintedOnDate,
  showReportOnDate,
  mfField = "mf",
  fontScale = 1.2
}) => {
  const InfoFieldItem = mfs[mfField];
  return (
    <div>
      {labTestId && (
        <Box mt={0.6}>
          <Barcode
            value={labTestId.toString()}
            width={1}
            displayValue={false}
            fontSize={5}
            height={20}
            margin={0}
            font="arial"
          />
          <InfoFieldItem label="Test ID" data={labTestId} />
        </Box>
      )}
      {labIdentifier && <InfoFieldItem fontScale={fontScale} label="Lab Id" data={labIdentifier} />}
      {showSampleTakenDate && collectionDate && (
        <InfoFieldItem
          fontScale={fontScale}
          label="Sample Collected"
          data={getSettingBasedDate(dateSetting, collectionDate, showSampleTakenTime)}
        />
      )}
      {sampleRemarks && (
        <InfoFieldItem fontScale={fontScale} label="Sample remarks" data={sampleRemarks} />
      )}
      {showReportOnDate && resultDate && (
        <InfoFieldItem
          fontScale={fontScale}
          label="Reported On"
          data={getSettingBasedDate(
            dateSetting === "BS" ? "BS" : "AD",
            resultDate,
            showReportOnTime
          )}
        />
      )}
      {showReportOnDate && resultDate && (
        <InfoFieldItem
          fontScale={fontScale}
          label=""
          data={getSettingBasedDate(
            dateSetting === "BS" ? "AD" : "BS",
            resultDate,
            showReportOnTime
          )}
        />
      )}
      {modifiedDate && (
        <InfoFieldItem
          fontScale={fontScale}
          label="Modified Date"
          data={getSettingBasedDate(dateSetting === "BS" ? "AD" : "BS", modifiedDate, true)}
        />
      )}
      {showPrintedOnDate && (
        <InfoFieldItem
          fontScale={fontScale}
          label="Printed On"
          data={getSettingBasedDate(dateSetting, new Date().toISOString(), showPrintedOnTime)}
        />
      )}
    </div>
  );
};

LabPrintLabInfo.defaultProps = {
  mfField: "mf"
};

export default LabPrintLabInfo;

import React from "react";
import { Box, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import * as NotificationActions from "../../../../actions/notification";
import OkhatiDialog from "../../../../components/Dialog/Dialog";
import { getDefaultLabTestGroupForReset } from "../../../../slices/labTestSettingsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LabGroupResetToDefault = ({ isOpen, onClose }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const draftTestGroup = useAppSelector((state: RootState) => state.labSettings.draftGroup);

  return (
    <OkhatiDialog
      title={
        <Box display="flex" alignItems="center">
          <WarningIcon color="warning" />
          <Typography ml={1} fontSize="1.2rem" fontWeight="600">
            Warning
          </Typography>
        </Box>
      }
      open={isOpen}
      next={() => {
        dispatch(getDefaultLabTestGroupForReset(draftTestGroup.id));
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: "Draft has been reset to defaults!",
            autoTimeout: true
          })
        );
        onClose();
      }}
      cancel={onClose}
      description="Are you sure you want to reset the lab settings to default?"
      readMode={false}
    />
  );
};

export default LabGroupResetToDefault;

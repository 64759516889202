import { Box, Typography, TextField } from "@mui/material";
import React from "react";
import "./Template.scss";
import { t, tl } from "../../../components/translate";

interface SmsTemplateBoxProps {
  content: string;
  onChange: (content: string) => any;
  showError?: boolean;
  showCustomKeywords?: boolean;
}

export const keywords = [
  { label: "First Name", value: "clientFirstName" },
  { label: "Last Name", value: "clientLastName" },
  { label: "Due", value: "clientDueAmount" },
  { label: "Booking Date", value: "bookingDate" },
  { label: "Booking Time", value: "bookingStartTime" },
  { label: "Doctor Name", value: "bookingServiceProviderName" },
  { label: "Clinic Name", value: "resourceCentreName" }
];

export const SmsTemplateBox: React.FC<SmsTemplateBoxProps> = ({
  content,
  onChange,
  showError,
  showCustomKeywords
}) => {
  const [length, setLength] = React.useState(content.length);

  React.useEffect(() => {
    setLength(content.length);
  }, [content]);
  return (
    <Box mt={1} mb={1}>
      <Box display="flex" flexDirection="row" marginTop="4px" marginBottom="4px">
        {showCustomKeywords &&
          keywords.map((keyword, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={i}>
              <Box
                data-testmation="selectContent"
                className="keywordSelect"
                onClick={() => {
                  onChange(`${content}{{${keyword.value}}}`);
                }}
              >
                {keyword.label}
              </Box>
            </Typography>
          ))}
      </Box>

      <TextField
        data-testmation="content"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        placeholder={t("campaign.phContent")}
        margin="dense"
        fullWidth
        label={tl("campaign.content")}
        multiline
        rows={8}
        value={content}
        error={content === "" && showError}
        onChange={(e) => {
          onChange(e.target.value);
          setLength(e.target.value.length);
        }}
      />

      <Typography variant="caption" style={{ marginLeft: "auto" }}>
        Count : {Math.ceil((length || 1) / 152)} SMS ({length} characters)
      </Typography>
    </Box>
  );
};

SmsTemplateBox.defaultProps = {
  showError: false,
  showCustomKeywords: false
};

import React, { Component } from 'react';
import { Button, Typography, Box } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

interface Props {
  children: React.ReactNode;
  user: any;
}

interface State {
  hasError: Boolean;
  hasRetried: Boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false, hasRetried: false };

  static getDerivedStateFromError = () => ({ hasError: true });

  componentDidCatch = (error, errorInfo) => {
    if (this.state.hasRetried) {
      window.location.reload();
      return;
    }
    this.setState({ hasError: true });

    Sentry.withScope((scope) => {
      scope.setTag('component', '<ErrorBoundary />');
      scope.setLevel(Sentry.Severity.Critical);
      scope.setUser({
        id: this.props.user?.user?.id,
        username: this.props.user?.userCreds?.username,
        email: this.props.user?.user?.email,
      });
      scope.setExtras({
        userRole: this.props.user?.userCreds?.authenticable,
        resourceCentreId: this.props.user?.resourceCentre?.id,
        resourceCentreName: this.props.user?.resourceCentre?.name,
      });
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  };

  resetState = () => {
    this.setState({ hasError: false, hasRetried: true });
  };
  render() {
    if (this.state.hasError) {
      return (
        <Box
          component="div"
          display="flex"
          height="100vh"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" component="h1" align="center">
            Oops! something went wrong.
          </Typography>
          <Button color="primary" onClick={this.resetState}>
            Try again
          </Button>
        </Box>
      );
    }

    return this.props.children || null;
  }
}

function mapStateToProps(state) {
  return {
    user: state.userContext,
  };
}

export default connect(mapStateToProps, null)(ErrorBoundary);

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import styles from './Assessment.module.css';
import { tl, t } from '../../../components/translate';
import TextBox from '../../../components/TextBox/TextBox';

const HOPI = (props) => {
  const { assessmentHOPI, onChange } = props;

  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography>
          <Box component={'span'} fontWeight={'600'}>
            {tl('assessment.hopi')}
          </Box>
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <TextBox
          onFocus={(e) => e.target.select()}
          margin="dense"
          id="outlined-basic"
          data-testmation="hopiTextField"
          fullWidth
          multiline
          variant={'outlined'}
          rows="3"
          rowsMax="3"
          placeholder={t('assessment.typeHOPI')}
          value={assessmentHOPI}
          onSave={(value) => onChange(value)}
          showTextCounter={true}
          inputProps={{ maxLength: 255 }}
          FormHelperTextProps={{
            style: { position: 'absolute', right: 0, bottom: 0, margin: 5 }
          }}
        />
      </Box>
    </Box>
  );
};

export default HOPI;

import React from "react";
import { Box, Typography } from "@mui/material";
import { isEmpty, startCase } from "lodash";
import produce from "immer";
import Modal from "../../../components/Modal/Modal";
import { LabRecord, LabTestRecordResultData } from "../../../interfaces/Lab";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";

interface Props {
  data: LabRecord[];
  selectedLabTest: LabTestRecordResultData;
  onClose: () => void;
}

enum Headings {
  NAME = "name",
  READING = "reading",
  DATE = "date"
}
const headings = Object.values(Headings);

const filterAndFormatLabTests = (
  data: LabRecord[],
  selectedLabTest: LabTestRecordResultData
): LabRecord[] => {
  const relatedTests = data.filter((test) =>
    test.results.data.some((labTest) => labTest.id === selectedLabTest.id)
  );
  return relatedTests.map((test) =>
    produce(test, (draft) => {
      draft.results.data = draft.results.data.filter(
        (labTest) => labTest.id === selectedLabTest.id
      );
    })
  );
};

const RowView = ({ labTest, subTest = false, resultDate, index }) => (
  <>
    <Box display="flex">
      <Typography pl={subTest ? 2 : 1} sx={{ flexBasis: "400px" }}>
        {labTest.name}
      </Typography>
      <Typography sx={{ flexBasis: "150px" }}>{labTest.formData?.reading || ""}</Typography>
      {!subTest && index === 0 && (
        <Typography sx={{ flexBasis: "150px" }}>
          {resultDate ? convertADtoBS(new Date(resultDate)).formatted4 : ""}
        </Typography>
      )}
    </Box>
    {labTest.subTests && (
      <Box>
        {labTest?.subTests?.map((st, i) => (
          <RowView index={i} subTest key={st.id} labTest={st} resultDate={resultDate} />
        ))}
      </Box>
    )}
  </>
);

export default function RelatedLabTests({ data, selectedLabTest, onClose }: Props): JSX.Element {
  const formattedData = filterAndFormatLabTests(data, selectedLabTest);

  return (
    <Modal
      title={`${selectedLabTest.name}`}
      onClose={() => onClose()}
      open={!isEmpty(selectedLabTest)}
    >
      <Box width="700px">
        <Box display="flex">
          {headings.map((heading) => (
            <Typography
              key={heading}
              sx={{
                "&:first-child": {
                  flexGrow: 1
                },
                flexBasis: "150px",
                fontWeight: "bold"
              }}
            >
              {startCase(heading)}
            </Typography>
          ))}
        </Box>
        {formattedData.map((test) =>
          test?.results.data.map((datum) => (
            <>
              <Typography fontWeight="bold" sx={{ textDecoration: "underline" }}>
                {datum.name}
              </Typography>
              {datum.labTests.map((labTest, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <RowView key={i} index={i} labTest={labTest} resultDate={test.resultDate} />
              ))}
            </>
          ))
        )}
      </Box>
    </Modal>
  );
}

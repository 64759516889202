import React from 'react';
import { Box, Select, MenuItem } from '@mui/material';
import * as feedActions from '../../../actions/feed';
import { Label } from './GenericTextField';

const FEEDTYPEOPTIONS = [
  { label: 'Daily advice', value: feedActions.FeedTypes.DAILY },
  { label: 'News', value: feedActions.FeedTypes.NEWS }
];

const SelectFeedType = ({ selectdType, onChange }) => {
  return (
    <Box component="div" display={'flex'}>
      <Label>Feed type</Label>
      <Select
        autoWidth
        variant="outlined"
        margin="dense"
        name="type"
        value={selectdType || ''}
        onChange={onChange}
        placeholder="Feed type"
      >
        {FEEDTYPEOPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectFeedType;

import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { startCase } from "lodash";
import produce from "immer";
import {
  ORDER_ACTION,
  OrderActionType,
  OrderButtons
} from "../../ResourceCentre/ModuleSettings/AssessmentLabels";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resourceCentreActions } from "../../../actions";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { removeGeneralErrorByKey } from "../../../actions/error";
import { DASHBOARD_CARD_ITEMS } from "../Index";

interface Props {
  resourceCentre: ResourceCentre;
}
const DashboardCardOrder = ({ resourceCentre }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const apiErrors = useAppSelector((state) => state.error);
  const [isDirty, setIsDirty] = useState(false);
  const [cardItemOrder, setCardItemOrder] = useState(
    resourceCentre.settings.dashboardSettings.cardItemsOrder
  );

  const handleOrdering = ({
    action,
    label
  }: {
    action: OrderActionType;
    label: DASHBOARD_CARD_ITEMS;
  }) => {
    setIsDirty(true);
    const currentIndex = cardItemOrder.indexOf(label);
    let updatedIndex: number = -1;
    if (action === ORDER_ACTION.UP) {
      updatedIndex = currentIndex - 1;
    } else if (action === ORDER_ACTION.DOWN) {
      updatedIndex = currentIndex + 1;
    }
    if (updatedIndex >= 0) {
      setCardItemOrder(() =>
        produce(cardItemOrder, (draft) => {
          draft.splice(currentIndex, 1);
          draft.splice(updatedIndex, 0, label);
        })
      );
    }
  };

  return (
    <Box m={2} maxWidth="25rem">
      <Typography variant="h6" mb={2}>
        Order Cards
      </Typography>

      <Box display="flex" mb={2} gap={2}>
        <Typography width="260px" variant="body1" fontWeight="bold">
          Name
        </Typography>
        <Typography width="100px" variant="body1" fontWeight="bold" textAlign="right">
          Ordering
        </Typography>
      </Box>
      <div>
        {cardItemOrder.map((item, index) => (
          <div key={item}>
            <Box display="flex" my={1} gap={2}>
              <Typography flexGrow={1}>{startCase(item)}</Typography>
              <OrderButtons
                isDisabled={{ up: index < 1, down: index > cardItemOrder.length - 2 }}
                onClick={{
                  up: () => {
                    handleOrdering({
                      action: ORDER_ACTION.UP,
                      label: item
                    });
                  },
                  down: () => {
                    handleOrdering({
                      action: ORDER_ACTION.DOWN,
                      label: item
                    });
                  }
                }}
              />
            </Box>
          </div>
        ))}
        <Button
          variant="contained"
          disabled={!isDirty}
          onClick={async () => {
            setIsDirty(false);
            const updatedRc = produce(resourceCentre, (draft) => {
              draft.settings = {
                ...draft.settings,
                dashboardSettings: {
                  ...draft.settings.dashboardSettings,
                  cardItemsOrder: cardItemOrder
                }
              };
            });
            await dispatch(resourceCentreActions.putResourceCentre(updatedRc));
            const foundErrorObj = apiErrors.filter(({ key }) => key === "PUT_RESOURCECENTRE");
            if (foundErrorObj.length) {
              setIsDirty(true);
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: foundErrorObj[0].message || commonErrorMessage,
                  autoTimeout: true
                })
              );
              dispatch(removeGeneralErrorByKey("PUT_RESOURCECENTRE"));
            } else {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "success",
                  message: "Card items ordering updated successfully.",
                  autoTimeout: true
                })
              );
            }
          }}
        >
          Save
        </Button>
      </div>
      <Box mt={2}>
        <Typography variant="h6" mb={2}>
          Preview
        </Typography>
        <Box
          sx={{
            backgroundColor: "#e5e4e4",
            padding: 1,
            borderRadius: 1,
            display: "flex",
            gap: 1,
            flexWrap: "wrap"
          }}
        >
          {cardItemOrder.map((item) => (
            <Box
              key={item}
              sx={{
                borderRadius: "4px",
                backgroundColor: "#fff",
                p: 2,
                minWidth: "40%",
                flex: 1
              }}
            >
              {startCase(item)}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCardOrder;

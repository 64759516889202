import { Box, Typography } from "@mui/material";
import React from "react";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { ageFormatter } from "../../../helpers/formatters";
import ClientImageUploader from "../../../components/ImageUploader/ClientImageUploader";
import { IPD } from "../../../interfaces/IpdInterface";
import { Client } from "../../../interfaces/ClientInterface";
import { clientFullNameSelector } from "../../../reducers/client";
import { tl } from "../../../components/translate";

interface IpdProps {
  ipd: IPD;
  client: Client;
}

const Info = ({ label, value }: { label: string; value: string }) => (
  <Box display="flex" justifyContent="space-between" width="50%">
    <Typography fontWeight={600}>{label}</Typography>
    <Typography fontWeight={500}>{value}</Typography>
  </Box>
);

const IpdClientInfo = ({ ipd, client }: IpdProps): JSX.Element | null => {
  if (!ipd) return null;
  const age = ageFormatter(client.dob);

  return (
    <Box px={2}>
      <Box display="flex" alignItems="center" gap={4}>
        <Typography variant="h6"> {clientFullNameSelector(client).toUpperCase()} </Typography>
        <ClientImageUploader readOnly initialImage="" />
      </Box>
      <Typography>
        {age} {client.gender ? tl(`clients.gender.${client.gender}`) : ""}
      </Typography>

      <Info label="Patient Id" value={client.customerNumber} />
      <Info label="Ipd Number" value={ipd.ipdNumber} />
      <Info label="Bed No." value={ipd.bedName} />
      <Info
        label="Admission Date"
        value={convertADtoBS(new Date(ipd.dateOfAdmission)).formatted4}
      />
      <Info label="Phone No." value={client.phone} />
    </Box>
  );
};

export default IpdClientInfo;

import { Box, Button, LinearProgress, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Modal from "../Modal/Modal";
import { downloadCSV } from "../../helpers/files";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 240,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: 480
    }
  }
}));

interface counter {
  fail: number;
  success: number;
}

interface message {
  data: { data: unknown };
}

interface ImportStatusModalProps {
  setShowModal: (v) => void;
  ongoing: boolean;
  importing: boolean;
  setImporting: (v) => void;
  counter: counter;
  fieldErrors: unknown[];
  setFieldErrors: (v) => void;
  message: message;
  redownLoadData: unknown[];
}

function ImportStatusModal({
  setShowModal,
  ongoing,
  importing,
  setImporting,
  counter,
  fieldErrors,
  setFieldErrors,
  message = null,
  redownLoadData
}: ImportStatusModalProps): JSX.Element {
  const classes = useStyles();

  let content = <></>;
  if (counter.success === 0) {
    content = (
      <Box fontSize={16} color="error.main">
        Importing failed. Please verify your data are correct.
      </Box>
    );
  } else if (counter.fail > 0) {
    content = (
      <Box fontSize={16} color="warning.main">
        <Typography>
          Some data were not imported. The failed data are shown in the table.
        </Typography>
        {redownLoadData.length > 0 && (
          <Typography style={{ cursor: "pointer" }}>
            You can download the failed data{" "}
            <Link
              onClick={() => {
                downloadCSV(
                  "Data with errors client upload",
                  redownLoadData.map((item) => Object.values(item)),
                  Object.keys(redownLoadData[0]),
                  (row) => row
                );
              }}
            >
              here
            </Link>
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Modal
      title={ongoing ? "Importing..." : "Import status"}
      open={importing}
      footer={
        <Button
          disabled={ongoing}
          data-testmation="global.cancel"
          onClick={() => {
            setImporting(false);
            setFieldErrors([]);
            if (counter.fail === 0) {
              setShowModal(false);
            }
          }}
          color="primary"
        >
          Ok
        </Button>
      }
    >
      <Box data-testmation="importPanel" component="div" className={classes.root}>
        {ongoing && <LinearProgress color="primary" />}
        {!ongoing && (
          <>
            <Box component="div" fontSize={14} mb={1}>
              Successful: {counter.success}
            </Box>
            <Box component="div" fontSize={14} mb={2}>
              Failed: {counter.fail}
            </Box>
            {counter.fail === 0 && (
              <Box data-testmation="successMessage" fontSize={16} color="success.main">
                Wohoo! Import successful.
              </Box>
            )}
            {content}
            {message && <Typography color="error.main">{message}</Typography>}
            {fieldErrors.length ? (
              <>
                <Box component="div" mt={2}>
                  <Box component="div" fontWeight="bold" fontSize={14} mb="4px">
                    Possible errors:
                  </Box>
                  {fieldErrors.map((error, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={i} fontSize={14} component="span" display="block">
                      - {error}
                    </Box>
                  ))}
                </Box>
              </>
            ) : null}
          </>
        )}
      </Box>
    </Modal>
  );
}

export default ImportStatusModal;

import React from "react";
import { Box, Typography } from "@mui/material";
import * as moment from "moment-timezone";
import { getLatestRcNotifications } from "../../api/reminders";
import { ResourceCentreNotification } from "../../interfaces/ReminderInterface";
import List from "../../components/List";
import "./notificationList.scss";
import { MatchProps, notificationMulitpleHeaders } from "./SendNotificationPanel";

const NotificationList = ({ match }: { match: MatchProps }): JSX.Element => {
  const [notifications, setNotifications] = React.useState<ResourceCentreNotification[]>([]);
  React.useEffect(() => {
    (async () => {
      const rcNotifications = await getLatestRcNotifications();
      setNotifications(rcNotifications);
    })();
  }, []);
  return (
    <Box height="100%" className="chatList" py={2}>
      <List
        multipleHeaders={notificationMulitpleHeaders(match)}
        data={notifications}
        automation="notificationLists"
        withoutSearch
        hideCreateButton
        columns={[
          {
            key: "rcId",
            label: "Rc Id",
            formatter: ({ resourceCentreId }) => <Typography>{resourceCentreId}</Typography>
          },
          {
            key: "rcName",
            label: "Resource Centre",
            formatter: ({ resourceCentre }) => <Typography>{resourceCentre?.name}</Typography>
          },
          {
            key: "phone",
            label: "Phone",
            formatter: ({ resourceCentre }) => <Typography>{resourceCentre?.phone}</Typography>
          },
          {
            key: "content",
            label: "Content",
            formatter: ({ remark }) => <Typography>{remark}</Typography>
          },
          {
            key: "createdDate",
            label: "Sent Date",
            // eslint-disable-next-line camelcase
            formatter: ({ created_at }) => (
              <Typography>{moment(new Date(created_at)).format("YYYY-MM-DD")}</Typography>
            )
          },
          {
            key: "status",
            label: "Status",
            formatter: ({ resourceCentre }) => (
              <Typography>
                {resourceCentre?.notificationSettings?.showNotification ? "Active" : "Not Active"}
              </Typography>
            )
          }
        ]}
      />
    </Box>
  );
};

export default NotificationList;

import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";

export default function SubscriptionTerminated(): JSX.Element {
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        Notice about your account!
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", marginTop: "64px", fontSize: "1.5rem" }}
        >
          Your okhati account has been terminated/expired.
          <br />
          Please contact Okhati Support (9851154810) for more information.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

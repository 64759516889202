import * as React from "react";
import CurrencyInput from "react-currency-input-field";
import NumberFormat from "react-number-format";

export const NrsFormatInputCurrency = (props: Record<string, any>): JSX.Element => {
  const { inputRef, onChange, allowNegative = false, ...other } = props;

  return (
    <CurrencyInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      ref={inputRef}
      onValueChange={(v) => {
        onChange({ target: { value: v || 0 } });
      }}
      intlConfig={{ locale: "en-IN", currency: "INR" }}
      prefix="&zwnj;"
      allowDecimals
      decimalsLimit={4}
      allowNegativeValue={allowNegative}
    />
  );
};

const NrsFormatInput = (props: Record<string, any>): JSX.Element => {
  const { inputRef, onChange, allowNegative = false, ...other } = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      thousandsGroupStyle="lakh"
      allowNegative={allowNegative}
      isNumericString
      // prefix="रू "
    />
  );
};

export default NrsFormatInput;

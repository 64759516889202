import { Menu, MenuItem } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import moment from "moment";
import { useDispatch } from "react-redux";
import { downloadExcel } from "../../../helpers/files";
import {
  ChildGeneralLedgers,
  ProfitAndLossAccountingGroups,
  TrialBalanceInterface
} from "../../../interfaces/AccountReportsInterface";
import { t } from "../../../components/translate";
import * as NotificationActions from "../../../actions/notification";
import { formatDataForExcel, formatHeaderForExcel } from "./helper";

const getFileName = (fileName: string | undefined): string => {
  const fName = t(`report.${fileName}`);
  return `${fName}-${moment().format("YYYY-MM-DD")}`;
};

export default function CsvDownload({
  data,
  columns,
  fileName,
  docRowProcessor
}: {
  data: TrialBalanceInterface[] | ProfitAndLossAccountingGroups[];
  columns: string[];
  fileName: string | undefined;
  docRowProcessor: (item: ChildGeneralLedgers) => ChildGeneralLedgers[];
}): JSX.Element {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <div>
      <MenuIcon
        sx={{ marginRight: "5px", cursor: "pointer" }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={async () => {
            try {
              await downloadExcel(
                getFileName(fileName),
                formatHeaderForExcel(columns),
                formatDataForExcel(data, docRowProcessor)
              );
              setAnchorEl(null);
            } catch (e) {
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Failed to download Excel.",
                  autoTimeout: true
                })
              );
            }
          }}
        >
          Export to Excel
        </MenuItem>
      </Menu>
    </div>
  );
}

import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import moment from "moment-timezone";
import produce from "immer";
import { floor } from "mathjs";
import { useAppSelector } from "../../../store/hooks";
import { Stock, Supplier } from "../../../interfaces/StockInterfaces";
import { tl } from "../../../components/translate";

interface BatchListProps {
  stocks: Stock[];
  isInternal: boolean;
  updateBatch: (list: Stock[]) => void;
  unitsPerPackage: number;
}

interface BatchRowProps {
  batchNo: string;
  quantity: number;
  expiryDate: string;
  perUnitSalesPriceExcVAT: string;
  isInternal: boolean;
  purchasePrice: number;
  onRowChange: (fieldKey: string, value: string) => void;
  unitsPerPackage: number;
  supplier: string;
}

const showQtyInPackage = (qty: number, unitsPerPackage: number): string => {
  const noOfPackage = floor(qty / unitsPerPackage);
  return `${qty}(${noOfPackage} package)`;
};

const commonStyles = {
  fontSize: "0.875rem",
  height: "100%",
  display: "flex",
  alignItems: "center"
};

const BatchRow = ({
  batchNo,
  quantity,
  expiryDate,
  perUnitSalesPriceExcVAT,
  isInternal,
  purchasePrice,
  onRowChange,
  unitsPerPackage,
  supplier
}: BatchRowProps): JSX.Element => {
  const [expDate, setExpDate] = React.useState(expiryDate);
  return (
    <Box display="flex" height="60px" gap={1}>
      <Typography sx={{ ...commonStyles, flexBasis: "70px", justifyContent: "flex-start" }}>
        {batchNo}
      </Typography>
      <Typography sx={{ ...commonStyles, flexBasis: "80px" }} data-testmation="batchQty">
        {showQtyInPackage(quantity, unitsPerPackage)}
      </Typography>
      <TextField
        sx={{ ...commonStyles, flexBasis: "150px" }}
        value={expDate ? moment(expDate).format("YYYY-MM-DD") : ""}
        variant="outlined"
        margin="dense"
        type="date"
        fullWidth
        onChange={(event) => {
          const { value } = event.target;
          setExpDate(value);
          if (moment(value).isValid()) {
            onRowChange("expiryDate", new Date(value).toISOString());
          } else {
            onRowChange("expiryDate", "");
            setExpDate("");
          }
        }}
      />
      <TextField
        sx={{ ...commonStyles, flexBasis: "150px" }}
        value={purchasePrice || 0}
        variant="outlined"
        margin="dense"
        fullWidth
        onChange={(e) => {
          onRowChange("purchasePrice", e.target.value);
        }}
      />
      {!isInternal && (
        <TextField
          sx={{ ...commonStyles, flexBasis: "150px" }}
          value={perUnitSalesPriceExcVAT}
          variant="outlined"
          margin="dense"
          fullWidth
          onChange={(e) => {
            onRowChange("unitPriceExcVAT", e.target.value);
          }}
        />
      )}
      <Box sx={{ ...commonStyles, flexBasis: "100px" }} title={supplier}>
        <Typography
          sx={{
            maxWidth: "80px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre"
          }}
        >
          {supplier}
        </Typography>
      </Box>
    </Box>
  );
};

const headers = [
  { name: "Batch", style: { flexBasis: "70px" } },
  { name: "Qty", style: { flexBasis: "80px" } },
  { name: "Expiry", style: { flexBasis: "150px" } },
  {
    name: "Purchase price excl. Vat per unit",
    style: { flexBasis: "150px" }
  },
  {
    name: "Sales price excl. VAT per unit",
    style: { flexBasis: "150px" },
    isHiddenForInternal: true
  },
  { name: "Supplier", style: { flexBasis: "100px" } }
];

const Headers = ({ isInternal }: { isInternal: boolean }): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      borderBottom: "1px solid lightgrey",
      gap: 1,
      pb: 0.5
    }}
  >
    {headers.map((item) => {
      if (isInternal && item.isHiddenForInternal) return null;
      return (
        <Typography
          sx={{ fontWeight: 600, fontSize: "0.875rem", ...(item.style as React.CSSProperties) }}
          key={item.name}
        >
          {item.name}
        </Typography>
      );
    })}
  </Box>
);

const BatchList = ({
  stocks,
  isInternal,
  updateBatch,
  unitsPerPackage
}: BatchListProps): JSX.Element => {
  const suppliers = useAppSelector((state) => state.resources.suppliers);

  return (
    <Box>
      <Typography style={{ marginBottom: "8px", fontSize: "1rem", fontWeight: 600 }}>
        {tl("product.variants")}
      </Typography>
      <Headers isInternal={isInternal} />
      {!stocks.length && (
        <Typography style={{ textAlign: "center", marginTop: "16px" }}>
          {tl("product.noStockFound")}
        </Typography>
      )}
      {stocks.map(
        ({ batchId, expiryDate, quantity, unitPriceExcVAT, purchasePrice, supplierId }, i) => (
          <BatchRow
            unitsPerPackage={unitsPerPackage}
            key={batchId}
            batchNo={batchId}
            expiryDate={expiryDate}
            quantity={Number(quantity) || 0}
            perUnitSalesPriceExcVAT={unitPriceExcVAT}
            purchasePrice={purchasePrice}
            isInternal={isInternal}
            supplier={
              suppliers.find((item: Supplier) => item.id === supplierId)?.name || supplierId
            }
            onRowChange={(fieldKey, value) => {
              const updatedList = produce(stocks, (draft) => {
                draft[i] = {
                  ...draft[i],
                  [fieldKey]: value
                };
              });
              updateBatch(updatedList);
            }}
          />
        )
      )}
    </Box>
  );
};

export default BatchList;

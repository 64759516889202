import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import * as NotificationActions from "../../actions/notification";
import { tl } from "../../components/translate";
import { confirmPayment } from "../../api/subscriptions";
import { useAppSelector } from "../../store/hooks";

interface RouteParams {
  paymentProvider: string;
}

export default function VerifyPayment(): JSX.Element {
  const params = useParams<RouteParams>();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const parsedQuery = queryString.parse(search);
  const rcId = useAppSelector((state) => state.userContext.resourceCentreId);
  // eslint-disable-next-line camelcase
  const { purchase_order_id, pidx, provider } = parsedQuery;

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const response = await confirmPayment({
          pidx: pidx as string,
          provider: params.paymentProvider,
          // eslint-disable-next-line camelcase
          purchase_order_id: purchase_order_id as string
        });
        if (response.status === "paid") {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("resourceCentreInvoices.payment.success"),
              autoTimeout: true,
              timeout: 10000
            })
          );
          history.push(`/resourcecentres/${rcId}/payments`);
        } else {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: "Your payment is still pending verification, please check again later.",
              autoTimeout: true,
              timeout: 10000
            })
          );
        }
      } catch ({ message }) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: message as string,
            autoTimeout: true,
            timeout: 10000
          })
        );
      }
    };
    // eslint-disable-next-line camelcase
    if (purchase_order_id && pidx) {
      verifyPayment();
    }
    // eslint-disable-next-line camelcase
  }, [dispatch, history, params.paymentProvider, pidx, provider, purchase_order_id]);

  return (
    <Box component={Container} my={4}>
      <Box textAlign="center">
        <CircularProgress />
        <Typography variant="h5" component="h2">
          Verifying your payment
        </Typography>
      </Box>
    </Box>
  );
}

import * as React from "react";
import { Box, Button } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DOMParser as PDOMParser } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { useAppDispatch } from "../../store/hooks";
import {
  customSchema,
  proseMirrorOptions,
  RichTextfield,
  deserializeNode
} from "../../components/RichTextfield";
import useProseMirror from "../../components/RichTextfield/useProsemirror";
import { postResourceCentreNotifications } from "../../slices/resourceCentreNotificationSlice";
import { ResourceCentreNotification } from "../../interfaces/ReminderInterface";
import { notificationAdd } from "../../actions/notification";
import Panel from "../../components/Panel";
import { commonErrorMessage, commonSuccessMessage } from "../../helpers/messages";
import { resourceCentreActions } from "../../actions";

export interface MatchProps {
  url: string;
}
export const notificationMulitpleHeaders = (
  match: MatchProps
): {
  headers: Array<{ key: string; title: JSX.Element | string; goto: string }>;
  url: string;
} => ({
  headers: [
    {
      key: "resourceCentreNotifications",
      title: "Notifications",
      goto: "/resourceCentreNotifications"
    },
    { key: "sentNotifications", title: "Sent Notifications", goto: "/sentNotifications" }
  ],
  url: match.url
});

const SendNotificationPanel = ({
  onClose,
  selectedRcIds
}: {
  onClose: () => void;
  selectedRcIds: number[];
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = React.useState<Partial<ResourceCentreNotification>[]>(
    [] as ResourceCentreNotification[]
  );

  function useParsedProseMirror(
    document,
    options,
    schema = customSchema
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): [EditorState<any>, React.Dispatch<React.SetStateAction<EditorState<any>>>] {
    const parser = new DOMParser();
    const doc = PDOMParser.fromSchema(schema).parse(
      parser.parseFromString(`${document}`, "text/html").documentElement
    );
    const [state, setState] = useProseMirror({
      ...options,
      doc
    });
    return [state, setState];
  }

  const [state, setState] = useParsedProseMirror("", proseMirrorOptions);
  const dispatchHandler = async () => {
    const remark = deserializeNode(state.doc);
    if (remark !== "<p></p>") {
      try {
        await dispatch(
          postResourceCentreNotifications({
            ids: selectedRcIds,
            data: remark,
            type: "NotificationToResourceCentre"
          })
        ).unwrap();
        setNotifications([{ remark }, ...notifications]);
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: commonSuccessMessage,
            variant: "success",
            autoTimeout: true
          })
        );
        dispatch(resourceCentreActions.getResourceCentres());
        onClose();
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            variant: "error",
            autoTimeout: true
          })
        );
      }
    }
  };

  return (
    <Panel onClose={onClose} title="Send Notification">
      <Box px={2}>
        <Box display="flex" flexDirection="column" gap="16px" marginTop="16px">
          <RichTextfield state={state} setState={setState} />

          <Button
            variant="contained"
            type="submit"
            sx={{ maxWidth: "fit-content" }}
            onClick={dispatchHandler}
            disabled={deserializeNode(state.doc) === "<p></p>"}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Panel>
  );
};

export default SendNotificationPanel;

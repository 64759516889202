import { Typography } from "@mui/material";
import React from "react";
import { Diagnosis as IDiagnosis } from "../../../interfaces/AssessmentInterfaces";
import hasOwnProperty from "../../../helpers/object";

interface Props {
  data: IDiagnosis | IDiagnosis[];
  renderInline?: boolean;
}

export const hasDiagnosis = (data: IDiagnosis | IDiagnosis[]): boolean =>
  (Array.isArray(data) && !!data.length) ||
  (typeof data === "object" && hasOwnProperty(data, "diagnosis"));

const DiagnosisRenderer = ({ data, renderInline }: Props): JSX.Element | null => {
  if (Array.isArray(data) && !!data.length) {
    if (renderInline) {
      return (
        <Typography>
          {data.map((item, index) =>
            index < data.length - 1 ? `${item.diagnosis}, ` : item.diagnosis
          )}
        </Typography>
      );
    }
    return (
      <>
        {data.map((item) => (
          <Typography key={item.icdCode}>{item.diagnosis}</Typography>
        ))}
      </>
    );
  }

  if (!Array.isArray(data) && typeof data === "object" && hasOwnProperty(data, "diagnosis")) {
    return <Typography>{data.diagnosis}</Typography>;
  }

  return null;
};

export default DiagnosisRenderer;

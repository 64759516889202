import { Box, InputAdornment, TextField } from "@mui/material";
import moment from "moment";
import Popover from "@mui/material/Popover";
import React from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { isBSDate } from "../../helpers/validators";
import { convertADtoBS, convertBStoAD } from "../Calendar/functions/calendarFunctions";
import Calendar from "../Calendar";
import DebouncedTextField from "../DebouncedTextField";

interface AdBsDateInputProps {
  value: string | Date;
  onChange: (value: string) => void;
}

const re = /[a-z]/i;
const englishMonths = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "july",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
];

const isValidDate = (value) => {
  if (!value) return false;
  return moment(value).isValid();
};

const checkingLength = (str) => {
  if (re.test(str)) return str.length > 11;
  return str.length > 10;
};

const AdBsDateInput = ({ value, onChange }: AdBsDateInputProps): JSX.Element => {
  const [internalState, setInternalState] = React.useState({
    adFormat: moment(value).format("YYYY-MM-DD"),
    bsFormat: convertADtoBS(moment(value)).dotFormatted
  });
  const [error, setError] = React.useState({ bsDateError: true, adDateError: true });

  React.useEffect(() => {
    setError({
      bsDateError: isValidDate(value),
      adDateError: isValidDate(value)
    });
  }, [value]);
  const [state, setState] = React.useState({ open: false, anchorEl: null });
  const changeHandler = ({ target }) => {
    const inputValue = target.value;
    if (checkingLength(inputValue)) return;
    if (target.name === "bsDate") {
      setInternalState({ ...internalState, bsFormat: inputValue });
      const isValidBSDate = isBSDate({ msg: "", splitter: "." })(inputValue, true);
      if (isValidBSDate) {
        const [year, month, day] = inputValue.split(".");
        const currentDate = moment(
          moment(convertBStoAD(Number(year), Number(month), Number(day))).format("YYYYMMDD")
        ).toISOString();
        setInternalState({
          bsFormat: inputValue,
          adFormat: moment(currentDate).format("YYYY-MM-DD")
        });
        onChange(currentDate);
        setError({ ...error, bsDateError: true });
      } else {
        setError({ ...error, bsDateError: false });
      }
    }
    if (target.name === "adDate") {
      setInternalState({ ...internalState, adFormat: inputValue });
      const [year, month, day] = inputValue.includes("-")
        ? inputValue.split("-")
        : inputValue.split("/");
      const monthIndex = re.test(month)
        ? englishMonths.indexOf(month?.toLowerCase()) + 1
        : Number(month);
      if (year.length === 4 && day && monthIndex) {
        const currentDate = moment(`${year}-${monthIndex}-${day}`);
        if (!currentDate.isValid()) {
          setError({ ...error, adDateError: false });
          return;
        }
        setError({ ...error, adDateError: true });
        setInternalState({
          adFormat: inputValue,
          bsFormat: convertADtoBS(currentDate).dotFormatted
        });
        onChange(currentDate.toISOString());
        return;
      }
      setError({ ...error, adDateError: false });
    }
  };

  const setDate = (date) => {
    setInternalState({
      adFormat: moment(date).format("YYYY-MM-DD"),
      bsFormat: convertADtoBS(moment(date)).dotFormatted
    });
  };

  const resetError = () => {
    setError({ adDateError: true, bsDateError: true });
  };

  const setToday = () => setDate(new Date());

  return (
    <Box display="flex" gap={2}>
      <DebouncedTextField
        debounceAt={1000}
        error={!error.bsDateError}
        variant="outlined"
        label="BS:YYYY.MM.DD"
        name="bsDate"
        InputLabelProps={{
          shrink: true
        }}
        onChange={changeHandler}
        value={internalState.bsFormat}
        onFocus={(e) => e.target.select()}
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={(e) => setState({ open: true, anchorEl: e.currentTarget })}
              sx={{ cursor: "default" }}
              position="end"
            >
              <CalendarTodayIcon sx={{ color: "black", width: "12px" }} fontSize="small" />
            </InputAdornment>
          )
        }}
      />
      <Popover
        open={state.open}
        anchorEl={state.anchorEl}
        onClose={() => {
          setState({
            open: false,
            anchorEl: null
          });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Calendar
          minValidDate=""
          defaultDate={new Date(value)}
          onChange={(newDate) => {
            setDate(newDate);
            onChange(newDate.toISOString());
            setState({ open: false, anchorEl: null });
          }}
        />
      </Popover>
      <DebouncedTextField
        debounceAt={1000}
        label="AD:YYYY-MM-DD"
        InputLabelProps={{
          shrink: true
        }}
        onChange={changeHandler}
        name="adDate"
        value={internalState.adFormat}
        onFocus={(e) => e.target.select()}
        variant="outlined"
        error={!error.adDateError}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ cursor: "pointer", marginTop: "5px" }} position="end">
              <TextField
                InputProps={{ disableUnderline: true, style: { width: "17px" } }}
                type="date"
                onChange={({ target }) => {
                  if (!target.value) {
                    resetError();
                    setToday();
                  } else {
                    setDate(target.value);
                    resetError();
                    onChange(moment(target.value).toISOString());
                  }
                }}
              />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default AdBsDateInput;

import actionCreatorFactory from "typescript-fsa";
import { AppThunk, IThunkDispatch } from "../store";

export const actionCreator: any = actionCreatorFactory();

export interface ModalActionInterface<T> {
  type: string;
  payload?: T;
}

export interface ModalInterface<T> {
  component: string;
  props?: {
    actions?: { [key: string]: ModalActionInterface<T> };
    payload?: T;
    [key: string]: unknown;
  };
}

interface DialogInterface {
  title: string | JSX.Element;
  description: string | JSX.Element;
  next: () => void;
  onCancel: () => void;
  readMode?: boolean;
}

export const navigateAddModal = actionCreator("NAVIGATE_ADD_MODAL");
export const navigateRemoveModal = actionCreator("NAVIGATE_REMOVE_MODAL");
export const navigateChangeModal = actionCreator("NAVIGATE_CHANGE_MODAL");

export const showDialog =
  ({ title, description, next, onCancel, readMode }: DialogInterface): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      navigateAddModal({
        component: "Dialog",
        props: {
          title,
          description,
          next,
          readMode,
          cancel: () => {
            dispatch(navigateRemoveModal("Dialog"));
            if (onCancel) {
              onCancel();
            }
          }
        }
      })
    );
  };

export const showAssessmentModal =
  ({ data, setAssessmentCopy, setIsDialogOpen }: Record<string, unknown>): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      navigateAddModal({
        component: "AssessmentModal",
        props: {
          data,
          setAssessmentCopy,
          setIsDialogOpen,
          onClose: () => {
            dispatch(navigateRemoveModal("AssessmentModal"));
          }
        }
      })
    );
  };

export const showServiceProviderModal =
  ({
    title,
    data,
    saveFn
  }: {
    title: string | JSX.Element;
    data: Record<string, unknown>;
    saveFn: () => void;
  }): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      navigateAddModal({
        component: "ServiceProviderModal",
        props: {
          title,
          data,
          saveFn,
          cancel: () => {
            dispatch(navigateRemoveModal("ServiceProviderModal"));
          }
        }
      })
    );
  };

import React, { useState, useEffect } from "react";
import { Box, Checkbox, TextField, Typography, Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import upperFirst from "lodash/upperFirst";
import { tl, t } from "../../../components/translate";
import RowWithDropDown from "./RowWithDropDown";

const decayedValues = [
  { value: "caries", label: "Caries" },
  { value: "reversiblePulpitis", label: "Reversible Pulpitis" },
  { value: "irreversiblePulpitis", label: "Irreversible Pulpitis" }
];

const quarterIdentifier = (quarter) => {
  let namedQuarter;
  if (quarter === 1 || quarter === 5) namedQuarter = t("history.upperRight");
  if (quarter === 2 || quarter === 6) namedQuarter = t("history.upperLeft");
  if (quarter === 3 || quarter === 7) namedQuarter = t("history.lowerLeft");
  if (quarter === 4 || quarter === 8) namedQuarter = t("history.lowerRight");
  return namedQuarter;
};

interface PopUpContentProps {
  quarter: number;
  quarterNum: number;
  onNumberSave: (v1, v2) => void;
  closePopUp: (v) => void;
  stateValue: any;
  onNodeDelete: (v) => void;
  type: number;
}

function PopUpContent(props: PopUpContentProps): JSX.Element {
  const { quarter, quarterNum, onNumberSave, closePopUp, stateValue, onNodeDelete, type } = props;

  const comboString = quarter + quarterNum;

  const [checkedStateValues, setCheckedStateValues] = useState({
    decayed: { checked: false, values: {} },
    mobile: { checked: false, values: {} },
    fractured: { checked: false, value: {} },
    impacted: { checked: false },
    missing: { checked: false },
    others: ""
  });

  useEffect(() => {
    if (stateValue[type][comboString]) {
      setCheckedStateValues(stateValue[type][comboString]);
    } else {
      setCheckedStateValues({
        decayed: {
          checked: false,
          values: {
            caries: {}
          }
        },
        mobile: { checked: false, values: {} },
        fractured: { checked: false, value: {} },
        impacted: { checked: false },
        missing: { checked: false },
        others: ""
      });
    }
  }, [stateValue, type, comboString]);

  return (
    <Box p={2}>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" pb={1}>
        <Typography>
          <Box component="span" fontWeight="600">{`Patient: ${quarterIdentifier(
            quarter
          )} Number: ${quarterNum}`}</Box>
        </Typography>
        <Box display="flex" width="15%" justifyContent="space-between">
          <DeleteForeverIcon
            data-testmation="intraOral.deleteButton"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              onNodeDelete(comboString);
              closePopUp(e);
            }}
          />
          <CloseIcon
            data-testmation="intraOral.closeButton"
            style={{ cursor: "pointer" }}
            onClick={(e) => closePopUp(e)}
          />
        </Box>
      </Box>
      {["decayed", "mobile", "fractured", "impacted", "missing"].map((item) => (
        <Box
          key={item}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="50%" display="flex" alignItems="center">
            <Checkbox
              data-testmation="intraOralCheckBox"
              checked={checkedStateValues[item].checked}
              onChange={() => {
                if (item === "decayed") {
                  setCheckedStateValues({
                    ...checkedStateValues,
                    [item]: {
                      ...checkedStateValues[item],
                      checked: !checkedStateValues[item].checked,
                      values: {
                        caries: { level: "I" }
                      }
                    }
                  });
                } else if (item === "mobile" || item === "fractured") {
                  setCheckedStateValues({
                    ...checkedStateValues,
                    [item]: {
                      ...checkedStateValues[item],
                      checked: !checkedStateValues[item].checked,
                      level: "I"
                    }
                  });
                } else {
                  setCheckedStateValues({
                    ...checkedStateValues,
                    [item]: {
                      ...checkedStateValues[item],
                      checked: !checkedStateValues[item].checked
                    }
                  });
                }
              }}
            />{" "}
            <Typography>{upperFirst(item)}</Typography>
          </Box>
          <Box display="flex">
            {checkedStateValues[item].checked && item === "decayed" && (
              <Box>
                <RowWithDropDown
                  item={item}
                  value={
                    checkedStateValues[item].values
                      ? Object.keys(checkedStateValues[item].values)[0]
                      : ""
                  }
                  onChange={(e) => {
                    setCheckedStateValues({
                      ...checkedStateValues,
                      [item]: {
                        ...checkedStateValues[item],
                        values: {
                          [e.target.value]: e.target.value === "caries" ? { level: "I" } : {}
                        }
                      }
                    });
                  }}
                  optionValues={decayedValues}
                >
                  <Box width="100px">
                    {Object.keys(checkedStateValues[item].values)[0] &&
                      "level" in
                        checkedStateValues[item].values[
                          Object.keys(checkedStateValues[item].values)[0]
                        ] && (
                        <RowWithDropDown
                          maxItems={6}
                          value={
                            checkedStateValues[item].values
                              ? checkedStateValues[item].values[
                                  Object.keys(checkedStateValues[item].values)[0]
                                ].level
                              : "I"
                          }
                          onChange={(e) => {
                            const keyVal = Object.keys(checkedStateValues[item].values)[0];
                            setCheckedStateValues({
                              ...checkedStateValues,
                              [item]: {
                                ...checkedStateValues[item],
                                values: {
                                  [keyVal]: { level: e.target.value }
                                }
                              }
                            });
                          }}
                        />
                      )}
                  </Box>
                </RowWithDropDown>
              </Box>
            )}
            {checkedStateValues[item].checked && (item === "mobile" || item === "fractured") && (
              <>
                <RowWithDropDown
                  item={item}
                  value={checkedStateValues[item].level ? checkedStateValues[item].level : "I"}
                  onChange={(e) => {
                    setCheckedStateValues({
                      ...checkedStateValues,
                      [item]: {
                        ...checkedStateValues[item],

                        level: e.target.value
                      }
                    });
                  }}
                  maxItems={item === "mobile" ? 3 : 9}
                />
              </>
            )}
          </Box>
        </Box>
      ))}
      <Box pt={1} width="100%" alignItems="center">
        <Typography>{tl("assessment.others")}</Typography>
        <TextField
          data-testmation="intraOral.othersTextField"
          fullWidth
          variant="outlined"
          margin="dense"
          multiline
          rows={2}
          value={checkedStateValues.others}
          onChange={(e) => {
            setCheckedStateValues({
              ...checkedStateValues,
              others: e.target.value
            });
          }}
        />
      </Box>

      <Box width="100%" display="flex" justifyContent="flex-end" mt={1} alignItems="center">
        <Button
          data-testmation="intraOral.cancelButton"
          color="secondary"
          style={{ marginRight: "10px" }}
          onClick={(e) => closePopUp(e)}
        >
          <Typography>{tl("assessment.cancel")}</Typography>
        </Button>
        <Button
          data-testmation="intraOralOkButton"
          variant="contained"
          color="primary"
          onClick={(e) => {
            const arr = Object.keys(checkedStateValues)
              .slice(0, Object.keys(checkedStateValues).length - 1)
              .map((item) => {
                if (item === "others") return false;
                return checkedStateValues[item].checked;
              });
            if (arr.every((val) => val === false) && checkedStateValues.others.length === 0) {
              onNodeDelete(comboString);
            } else {
              onNumberSave(comboString, checkedStateValues);
            }
            closePopUp(e);
          }}
        >
          <Typography>{tl("assessment.ok")}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default PopUpContent;

import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import { ReagentProps } from "./StockReagent";

const getHeaders = (showAvailableQty) => {
  const headers = ["S.N", "Product Name", "Batch Id", "Used Qty", "Action"];
  if (!showAvailableQty) return headers;
  headers.splice(4, 0, ...["Available Qty"]);
  return headers;
};
export default function StockReagentListView({
  data = [],
  onRemove,
  showAvailableQty = false
}: {
  data: ReagentProps[];
  onRemove: (id: number) => void;
  showAvailableQty?: boolean;
}): JSX.Element | null {
  if (!data?.length) return null;
  return (
    <Box mb={2}>
      <Box borderBottom="1px solid gray" mb={1} display="flex" className="container">
        {getHeaders(showAvailableQty).map((heading) => (
          <Typography fontWeight="bold" key={heading}>
            {heading}
          </Typography>
        ))}
      </Box>
      {[...data]
        .sort((a, b) => a.sNo - b.sNo)
        .map((item, index) => {
          const hasError =
            (Number(item?.availableQty || 0) < item.quantity || !item.availableQty) &&
            showAvailableQty;

          return (
            <Box
              className="container"
              sx={{ border: `${hasError ? "1px solid red" : ""}` }}
              key={`${item.productId}-${item.batchId}`}
              display="flex"
              alignItems="center"
            >
              <Typography>{`${index + 1}.`}</Typography>
              <Typography>{item.productName}</Typography>
              <Typography>{item.batchId}</Typography>
              <Typography>{item.quantity}</Typography>
              {showAvailableQty && (
                <Typography color={`${hasError ? "red" : ""}`}>{item.availableQty}</Typography>
              )}
              <div>
                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => onRemove(index)} />
              </div>
            </Box>
          );
        })}
    </Box>
  );
}

StockReagentListView.defaultProps = {
  showAvailableQty: false
};

import React from "react";
import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import moment from "moment";
import { IPD } from "../../../interfaces/IpdInterface";
import * as calendarFns from "../../../components/Calendar/functions/calendarFunctions";

interface IProgressRecordVital {
  reading: string;
  name: string;
  unit: string;
  extraInfo?: string;
}
interface IpdVitalsProps {
  ipdRecord: IPD;
}

const IpdVitalsshow: React.FC<IpdVitalsProps> = ({ ipdRecord }) => (
  <Box
    mb={3}
    pl={2}
    style={{
      margin: "auto"
    }}
  >
    {ipdRecord.ipdRecord?.progressRecord
      .map((record) =>
        record.vitals ? (
          <Box mt={2} style={{ width: "100%" }} key={record.id}>
            <Typography
              fontWeight={600}
              fontSize="14px"
              color="primary.main"
              sx={{ textTransform: "uppercase" }}
            >
              {`${calendarFns.convertADtoBS(moment(record.date)).formatted2} ${moment(
                record.date
              ).format("hh:mm:ss a")}`}
            </Typography>
            {record.vitals?.map((vital: IProgressRecordVital, index: number) => (
              <Typography
                component="span"
                fontSize="14px"
                key={vital.name}
                sx={{ wordBreak: "break-word" }}
              >
                <Typography fontWeight="bold" display="inline">
                  {startCase(vital.name)}
                </Typography>
                {`: ${vital.reading} ${vital.unit} ${vital.extraInfo || ""}`}
                {index === record.vitals.length - 1 ? "" : ", "}
              </Typography>
            ))}
          </Box>
        ) : (
          ""
        )
      )
      .reverse()}
  </Box>
);

export default IpdVitalsshow;

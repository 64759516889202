import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient
} from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { resourceCentreActions } from "../../actions";
import {
  getFiscalPeriod,
  patchFiscalPeriod,
  postFiscalPeriod,
  getMasterFiscalPeriods,
  postMasterFiscalPeriod
} from "../../api/resourceCentre";
import { FiscalPeriodProps } from "../../interfaces/Accounts";
import { RootState } from "../../store";

export const useFiscalPeriod = (rcId: number): UseQueryResult<FiscalPeriodProps[], Error> =>
  useQuery<FiscalPeriodProps[], Error>(["fiscalPeriods", rcId], getFiscalPeriod, {
    staleTime: 36 * 100000,
    enabled: !!rcId
  });

export const usePostFiscalPeriod = (): UseMutationResult<
  Partial<FiscalPeriodProps>,
  Error,
  Partial<FiscalPeriodProps>
> => {
  const resourceCentre = useSelector((state: RootState) => state.userContext.resourceCentre);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Partial<FiscalPeriodProps>, Error, Partial<FiscalPeriodProps>>(
    postFiscalPeriod,
    {
      onSuccess: () => {
        dispatch(resourceCentreActions.getResourceCentre(resourceCentre.id));
      },
      onSettled: () => {
        queryClient.invalidateQueries("fiscalPeriods");
      }
    }
  );
};

export const usePatchFiscalPeriod = (): UseMutationResult<
  Partial<FiscalPeriodProps>,
  Error,
  Partial<FiscalPeriodProps>
> => {
  const queryClient = useQueryClient();
  return useMutation<Partial<FiscalPeriodProps>, Error, Partial<FiscalPeriodProps>>(
    patchFiscalPeriod,
    {
      onSettled: () => {
        queryClient.invalidateQueries("fiscalPeriods");
      }
    }
  );
};

export const useMasterFiscalPeriods = (): UseQueryResult<FiscalPeriodProps[], Error> =>
  useQuery<FiscalPeriodProps[], Error>("masterFiscalPeriods", getMasterFiscalPeriods);

export const usePostNewFiscalPeriod = (): UseMutationResult<
  FiscalPeriodProps,
  Error,
  Partial<FiscalPeriodProps>
> => {
  const queryClient = useQueryClient();
  return useMutation<FiscalPeriodProps, Error, Partial<FiscalPeriodProps>>(postMasterFiscalPeriod, {
    onSuccess: (data) => {
      queryClient.setQueryData("masterFiscalPeriods", (old: FiscalPeriodProps[]) => [...old, data]);
    },
    retry: 0
  });
};

import { useSpring, animated } from "react-spring";
import React from "react";
import { Tooltip } from "@visx/xychart";
import "./styles.css";
import { WeeklyRevenuesData } from "../../../interfaces/DashboardInterface";

const AnimatedTooltip = animated(Tooltip);
interface Accessors {
  x: {
    sales: (d: WeeklyRevenuesData) => number;
    receipt: (d: WeeklyRevenuesData) => number;
  };
  date: (d: WeeklyRevenuesData) => string | Date;
}
interface Props {
  accessors: Accessors;
}
const RenderToolTip = ({
  setTopValue,
  setLeftValue,
  top,
  left,
  tooltipData,
  colorScale,
  accessors
}) => {
  React.useEffect(() => {
    setTopValue(top);
  }, [setTopValue, top]);
  React.useEffect(() => {
    setLeftValue(left);
  }, [left, setLeftValue]);
  return (
    <>
      <div className="toolTip">
        {Object.keys(tooltipData?.datumByKey ?? {})
          .filter((num) => num)
          .map((type) => {
            const value =
              tooltipData?.nearestDatum?.datum &&
              accessors?.x[type](tooltipData?.nearestDatum?.datum);

            return (
              <div key={type}>
                <h2
                  style={{
                    color: colorScale?.(type)
                  }}
                  className="toolTipValue"
                >
                  <span style={{ textTransform: "capitalize" }}>{type}:</span>
                  <span style={{ marginLeft: "2px" }}>
                    {value == null || Number.isNaN(value) ? "–" : `Rs ${value}`}
                  </span>
                </h2>
              </div>
            );
          })}
        <h3 className="toolTipDate">
          {(tooltipData?.nearestDatum?.datum &&
            accessors?.date(tooltipData?.nearestDatum?.datum)) ||
            "No date"}
        </h3>
      </div>
    </>
  );
};

const ChartToolTip = ({ accessors }: Props): React.ReactElement => {
  const [topValue, setTopValue] = React.useState(null);
  const [leftValue, setLeftValue] = React.useState(null);
  const animation = useSpring({ top: topValue, left: leftValue, delay: 200 });
  return (
    <AnimatedTooltip
      showDatumGlyph
      showSeriesGlyphs
      snapTooltipToDatumX
      snapTooltipToDatumY
      top={animation.top}
      left={animation.left}
      renderTooltip={({ tooltipData, colorScale, tooltipTop, tooltipLeft }) => (
        <RenderToolTip
          tooltipData={tooltipData}
          accessors={accessors}
          colorScale={colorScale}
          setTopValue={setTopValue}
          setLeftValue={setLeftValue}
          top={tooltipTop}
          left={tooltipLeft}
        />
      )}
      detectBounds
      className="toolTipContainer"
    />
  );
};
export default ChartToolTip;

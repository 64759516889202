import React from "react";
import { connect } from "react-redux";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { startCase } from "lodash";
import { getStockByTransactionId } from "../../api/stock";
import { t } from "../../components/translate";
import Panel from "../../components/Panel";
import useMobileScreen from "../../hooks/useMobileScreen";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { Headers, TransactionItem } from "../Stock/StockTransactions/StockTransactionView";
import StockProductEdit from "../Stock/StockCreateEdit/StockProductEdit";
import { getStockProducts } from "../../actions/stockProductActions";
import { StockProducts, StockTransactions } from "../../interfaces/StockInterfaces";
import * as NotificationActions from "../../actions/notification";
import { IThunkDispatch, RootState } from "../../store";
import { initialQuery, QueryProps } from "../../interfaces/ProductInterface";

interface SelectedSupplierProps {
  date: Date;
  supplierName: string;
  voucherType: string;
  reference: string;
  paymentMode: string;
  debit: number;
  credit: number;
  balance: number;
  remarks: string;
  stockTransactionId: number;
}
interface SupplierTransactionInfoProps {
  selectedSupplier: SelectedSupplierProps;
  setOpenSupplierDetails: (x: boolean) => void;
  stockProducts?: Array<StockProducts>;
  loadStockProducts?: (query: QueryProps) => void;
  showErrorMsg?: () => void;
}

function SupplierTransactionInfo({
  selectedSupplier,
  setOpenSupplierDetails,
  stockProducts,
  loadStockProducts,
  showErrorMsg
}: SupplierTransactionInfoProps): JSX.Element {
  const [stockTransaction, setStockTransaction] = React.useState({} as StockTransactions);
  const isMobileScreen = useMobileScreen();
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  React.useEffect(() => {
    getStockByTransactionId(selectedSupplier.stockTransactionId)
      .then((transaction) => setStockTransaction(transaction))
      .catch(() => {
        showErrorMsg();
      });
  }, [selectedSupplier, showErrorMsg]);
  React.useEffect(() => {
    loadStockProducts(initialQuery);
  }, [loadStockProducts]);
  React.useEffect(() => {
    setSelectedProduct(stockProducts?.find((item) => item.id === selectedId));
  }, [selectedId, stockProducts]);
  return (
    <>
      <Panel
        title="Stock Info"
        wrapperStyle={{ minWidth: "710px" }}
        onClose={() => setOpenSupplierDetails(false)}
      >
        <Box pl={5}>
          <Box px={`${isMobileScreen ? "16px" : "32px"}`} width="100%">
            <Box pt={5}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    {startCase(selectedSupplier.supplierName)}
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {t("reports.supplierDate")} :{" "}
                    </Typography>
                    <Typography>{calFns.bsFullDate(selectedSupplier.date)}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {t("reports.voucherType")} :{" "}
                    </Typography>
                    <Typography>{selectedSupplier.voucherType}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {t("reports.invoiceNumber")} :{" "}
                    </Typography>
                    <Typography>{selectedSupplier.reference}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      {t("reports.paymentMode")} :{" "}
                    </Typography>
                    <Typography>{selectedSupplier.paymentMode}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>{t("reports.debit")} : </Typography>
                    <Typography>{selectedSupplier.debit}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>{t("reports.credit")} : </Typography>
                    <Typography>{selectedSupplier.credit}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>{t("reports.balance")} : </Typography>
                    <Typography>{selectedSupplier.balance}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="50%"
                    fontSize="14px"
                    fontWeight={600}
                  >
                    <Typography style={{ fontWeight: 600 }}>{t("reports.remarks")} : </Typography>
                    <Typography>{selectedSupplier.remarks}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Typography style={{ fontWeight: 600, marginTop: "32px" }}>
              Transaction Items
            </Typography>
            <Paper style={{ marginTop: "8px" }}>
              <Headers />
              {stockTransaction?.stockTransactionItems?.map((trxItem, i) => {
                const idx = i + 1;
                return (
                  <TransactionItem
                    transactionType={data.transactionType}
                    key={idx}
                    setSelectedId={setSelectedId}
                    productName={trxItem.productName || ""}
                    idx={idx}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...trxItem}
                  />
                );
              })}
            </Paper>
          </Box>
        </Box>
      </Panel>
      {selectedProduct && (
        <StockProductEdit
          editMode
          wrapperStyle={{ minWidth: "645px", maxWidth: "680px" }}
          data={selectedProduct}
          id={selectedId}
          handleClose={() => {
            setSelectedProduct(null);
            setSelectedId(null);
          }}
        />
      )}
    </>
  );
}
SupplierTransactionInfo.defaultProps = {
  stockProducts: [],
  loadStockProducts: null,
  showErrorMsg: null
};

export default connect(
  (state: RootState) => ({
    stockProducts: state.stockProducts.collection
  }),
  (dispatch: IThunkDispatch) => ({
    loadStockProducts: (query: QueryProps) => dispatch(getStockProducts(query)),
    showErrorMsg: () =>
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Cannot get items",
          autoTimeout: true
        })
      )
  })
)(SupplierTransactionInfo);

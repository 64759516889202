import * as moment from "moment-timezone";
import * as calendarData from "./calendarData";
import { t } from "../../translate";

export const toDevanagariDigits = (number: number): string =>
  String(number)
    .split("")
    .map((ed) => calendarData.nepaliNumbers(t)[Number(ed)])
    .join("");

export const getMonthDaysNumFormMinBsYear = (bsMonth: number, yearDiff: number): number => {
  let yearCount = 0;
  let monthDaysFromMinBsYear = 0;
  if (yearDiff === 0) return 0;

  const bsMonthData = calendarData.extractedBsMonthData[bsMonth - 1];
  for (let i = 0; i < bsMonthData.length; i += 1) {
    if (bsMonthData[i] === 0) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const bsMonthUpperDaysIndex = i % 2;
    if (yearDiff > yearCount + bsMonthData[i]) {
      yearCount += bsMonthData[i];
      monthDaysFromMinBsYear +=
        calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] * bsMonthData[i];
    } else {
      monthDaysFromMinBsYear +=
        calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] * (yearDiff - yearCount);
      yearCount = yearDiff - yearCount;
      break;
    }
  }

  return monthDaysFromMinBsYear;
};

export const getTotalDaysNumFromMinBsYear = (
  bsYear: number,
  bsMonth: number,
  bsDate: number
): number => {
  if (bsYear < calendarData.minBsYear || bsYear > calendarData.maxBsYear) {
    return null;
  }

  let daysNumFromMinBsYear = 0;
  const diffYears = bsYear - calendarData.minBsYear;
  for (let month = 1; month <= 12; month += 1) {
    if (month < bsMonth) {
      daysNumFromMinBsYear += getMonthDaysNumFormMinBsYear(month, diffYears + 1);
    } else {
      daysNumFromMinBsYear += getMonthDaysNumFormMinBsYear(month, diffYears);
    }
  }

  if (bsYear > 2085 && bsYear < 2088) {
    daysNumFromMinBsYear += bsDate - 2;
  } else if (bsYear === 2085 && bsMonth > 5) {
    daysNumFromMinBsYear += bsDate - 2;
  } else if (bsYear > 2088) {
    daysNumFromMinBsYear += bsDate - 4;
  } else if (bsYear === 2088 && bsMonth > 5) {
    daysNumFromMinBsYear += bsDate - 4;
  } else {
    daysNumFromMinBsYear += bsDate;
  }

  return daysNumFromMinBsYear;
};

export const getBsMonthDays = (bsYear: number, bsMonth: number): number => {
  let yearCount = 0;
  const totalYears = bsYear + 1 - calendarData.minBsYear;
  const bsMonthData = calendarData.extractedBsMonthData[bsMonth - 1];
  for (let i = 0; i < bsMonthData.length; i += 1) {
    if (bsMonthData[i] === 0) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const bsMonthUpperDaysIndex = i % 2;
    yearCount += bsMonthData[i];
    if (totalYears <= yearCount) {
      if ((bsYear === 2085 && bsMonth === 5) || (bsYear === 2088 && bsMonth === 5)) {
        return calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex] - 2;
      }
      return calendarData.bsMonthUpperDays[bsMonth - 1][bsMonthUpperDaysIndex];
    }
  }

  return null;
};

export const convertBStoAD = (bsYear: number, bsMonth: number, bsDate: number): Date => {
  const daysNumFromMinBsYear = getTotalDaysNumFromMinBsYear(bsYear, bsMonth, bsDate);
  const { year, month, date } = calendarData.minAdDateEqBsDate.ad;
  const adDate = new Date(year, month, date - 1);
  adDate.setDate(adDate.getDate() + daysNumFromMinBsYear);
  return adDate;
};

export const convertADtoBS = (
  adYear: number | moment.Moment | Date,
  adMonth?: number,
  adDate?: number
): {
  bsYear: number;
  bsMonth: number;
  bsDate: number;
  formatted: string;
  formatted2: string;
  formatted3: string;
  formatted4: string;
  formatted5: string;
  dotFormatted: string;
} => {
  let newAdYear = adYear;
  let newAdMonth = adMonth;
  let newAdDate = adDate;
  if (moment.isMoment(newAdYear) || moment.isDate(newAdYear)) {
    newAdMonth = moment(newAdYear).month() + 1;
    newAdDate = moment(newAdYear).date();
    newAdYear = moment(newAdYear).year();
  }
  let bsYear = newAdYear + 57;
  let bsMonth = (newAdMonth + 9) % 12;
  bsMonth = bsMonth === 0 ? 12 : bsMonth;
  let bsDate = 1;

  if (newAdMonth < 4) {
    bsYear -= 1;
  }

  const bsMonthFirstAdDate = convertBStoAD(bsYear, bsMonth, 1);
  if (newAdDate >= 1 && newAdDate < bsMonthFirstAdDate.getDate()) {
    if (newAdMonth === 4) {
      const bsYearFirstAdDate = convertBStoAD(bsYear, 1, 1);
      if (newAdDate < bsYearFirstAdDate.getDate()) {
        bsYear -= 1;
      }
    }
    bsMonth = bsMonth !== 1 ? bsMonth - 1 : 12;
    const bsMonthDays = getBsMonthDays(bsYear, bsMonth);
    bsDate = bsMonthDays - (bsMonthFirstAdDate.getDate() - newAdDate) + 1;
  } else {
    bsDate = newAdDate - bsMonthFirstAdDate.getDate() + 1;
  }

  return {
    bsYear,
    bsMonth,
    bsDate,
    formatted: `${bsYear}/${bsMonth}/${bsDate}`,
    formatted2: `${calendarData.bsMonths(t)[bsMonth - 1]} ${bsDate}, ${bsYear}`,
    formatted3: `${calendarData.bsMonths(t)[bsMonth - 1]} ${bsDate}`,
    formatted4: `${bsYear}-${bsMonth}-${bsDate}`,
    formatted5: `${bsDate} ${calendarData.bsMonths(t)[bsMonth - 1]} ${bsYear}`,
    dotFormatted: `${bsYear}.${bsMonth}.${bsDate}`
  };
};

export const bsFullDate = (date: string | Date | moment.Moment): string =>
  convertADtoBS(moment(date).year(), moment(date).month() + 1, moment(date).date()).formatted2;

export const bsShortDate = (date: string | Date | moment.Moment): string =>
  convertADtoBS(moment(date).year(), moment(date).month() + 1, moment(date).date()).formatted4;

export const adFullDate = (date: string | Date | moment.Moment): string =>
  moment(date).format("ddd MMM DD, YYYY");

export const adShortDate = (date: string | Date | moment.Moment): string =>
  moment(date).format("YYYY-MM-DD");

export const startOfDay = (date: string | Date | moment.Moment): moment.Moment =>
  moment.tz(date, "Asia/kathmandu").startOf("day");

export const endOfDay = (date: string | Date | moment.Moment): moment.Moment =>
  moment.tz(date, "Asia/kathmandu").endOf("day");

export const startOfMonth = (date: string | Date | moment.Moment): moment.Moment => {
  const { bsYear, bsMonth } = convertADtoBS(moment(date));
  const startADDateOfTheMonth = convertBStoAD(bsYear, bsMonth, 1);
  return startOfDay(startADDateOfTheMonth);
};

export const endOfMonth = (date: string | Date | moment.Moment): moment.Moment => {
  const { bsYear, bsMonth } = convertADtoBS(moment(date));
  const endADDateOfTheMonth = convertBStoAD(bsYear, bsMonth, getBsMonthDays(bsYear, bsMonth));
  return endOfDay(endADDateOfTheMonth);
};

export const startOfYear = (date: string | Date | moment.Moment): moment.Moment => {
  const bsDate = convertADtoBS(moment(date));
  const startADDateOfTheYear = convertBStoAD(bsDate.bsYear, 1, 1);
  return startOfDay(startADDateOfTheYear);
};

export const endOfYear = (date: string | Date | moment.Moment): moment.Moment => {
  const { bsYear } = convertADtoBS(moment(date));
  const endADDateOfTheyear = convertBStoAD(bsYear, 12, getBsMonthDays(bsYear, 12));
  return endOfDay(endADDateOfTheyear);
};

export enum dateMode {
  "day" = "day",
  "week" = "week",
  "month" = "month",
  "year" = "year"
}

export const startOf = (date: Date, mode?: dateMode): moment.Moment => {
  switch (mode) {
    case dateMode.week:
      return startOfDay(moment(date).startOf("week"));
    case dateMode.month:
      return startOfMonth(date);
    case dateMode.year:
      return startOfYear(date);
    default:
      return startOfDay(date);
  }
};

export const endOf = (date: Date, mode?: dateMode): moment.Moment => {
  switch (mode) {
    case dateMode.week:
      return endOfDay(moment(date).endOf("week"));
    case dateMode.month:
      return endOfMonth(date);
    case dateMode.year:
      return endOfYear(date);
    default:
      return endOfDay(date);
  }
};

export const getLast12Months = (): {
  bsMonth: number;
  bsYear: number;
  label: string;
  start: moment.Moment;
  end: moment.Moment;
}[] => {
  const { bsMonth, bsYear } = convertADtoBS(moment());
  const last12Months = [] as {
    bsMonth: number;
    bsYear: number;
    label: string;
    start: moment.Moment;
    end: moment.Moment;
  }[];
  let monthsTrack = bsMonth + 11;
  let months = 12;
  while (months > 0) {
    const month = monthsTrack % 12;
    const year = monthsTrack < 12 ? bsYear - 1 : bsYear;
    last12Months.push({
      bsMonth: month + 1,
      bsYear: year,
      label: `${calendarData.bsMonths(t)[month]} ${toDevanagariDigits(year)}`,
      start: startOf(convertBStoAD(year, month + 1, 1), dateMode.month),
      end: endOf(convertBStoAD(year, month + 1, 1), dateMode.month)
    });
    months -= 1;
    monthsTrack -= 1;
  }
  return last12Months;
};

// Fiscal year start from shrawan to Ashad
// Fiscal Period of 76/77 means 2076/Sharawan/1 to 2077/Ashad/endOfTheMonth
export const getFiscalPeriodInterval = (
  year: number,
  month: number
): {
  currentFiscalYear: { [key: string]: Date };
  previousFiscalYear: { [key: string]: Date | number };
} => {
  if (month < 3) {
    return {
      currentFiscalYear: {
        startOfYear: convertBStoAD(year - 1, 4, 1),
        endOfYear: convertBStoAD(year, 3, getBsMonthDays(year, 3))
      },
      previousFiscalYear: {
        startOfYear: convertBStoAD(year - 2, 4, 1),
        endOfYear: convertBStoAD(year - 1, 3, getBsMonthDays(year - 1, 3)),
        startYear: year - 2,
        endYear: year - 1
      }
    };
  }
  return {
    currentFiscalYear: {
      startOfYear: convertBStoAD(year, 4, 1),
      endOfYear: convertBStoAD(year + 1, 3, getBsMonthDays(year + 1, 3))
    },
    previousFiscalYear: {
      startOfYear: convertBStoAD(year - 1, 4, 1),
      endOfYear: convertBStoAD(year, 3, getBsMonthDays(year, 3)),
      startYear: year - 1,
      endYear: year
    }
  };
};

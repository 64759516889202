import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import produce from "immer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store";
import { resourceCentreActions } from "../../actions";
import List, { MultipleHeader } from "../../components/List";
import SendNotificationPanel, {
  MatchProps,
  notificationMulitpleHeaders
} from "./SendNotificationPanel";

const ResourceCentreWithNotifications = ({ match }: { match: MatchProps }): JSX.Element => {
  const dispatch = useAppDispatch();
  const resourceCentres = useAppSelector((state: RootState) => state.resources.resourceCentres);
  const [showSendNotificationPanel, setShowSendNotificationPanel] = useState(false);
  const [selectedRcIds, setSelectedRcIds] = useState<number[]>([]);

  React.useEffect(() => {
    dispatch(resourceCentreActions.getResourceCentres());
  }, [dispatch]);

  return (
    <Box py={2} height="100%">
      <Box px={2}>
        <MultipleHeader multipleHeaders={notificationMulitpleHeaders(match)} />
      </Box>
      <List
        rowHeight={50}
        defaultSortColumn="name"
        customCreateButton={
          <Box ml={2}>
            <Button
              variant="contained"
              onClick={() => setShowSendNotificationPanel(true)}
              disabled={!selectedRcIds.length}
            >
              Send Notification
            </Button>
          </Box>
        }
        isResponsive
        multiSelectable
        onMultiSelect={setSelectedRcIds}
        columns={[
          {
            key: "name",
            label: "Name",
            sortable: true
          },
          {
            key: "id",
            label: "ID",
            sortable: true
          },
          { key: "address", label: "Address", sortable: true },
          {
            key: "showNotification",
            label: "Show notification",
            sortable: true,
            sortFunction: (a, b) => {
              const aShowNotification = a.settings?.notificationSettings?.showNotification;
              const bShowNotification = b.settings?.notificationSettings?.showNotification;

              if (aShowNotification && !bShowNotification) {
                return -1;
              }
              if (!aShowNotification && bShowNotification) {
                return 1;
              }
              return 0;
            },
            formatter: (row) => (
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={row.settings?.notificationSettings?.showNotification || false}
                      onChange={({ target }) => {
                        const newState = produce(row, (draft) => {
                          if (draft.settings.notificationSettings) {
                            draft.settings.notificationSettings.showNotification =
                              !draft.settings.notificationSettings.showNotification;
                          } else {
                            draft.settings.notificationSettings = {
                              showNotification: target.checked
                            };
                          }
                        });
                        dispatch(resourceCentreActions.putResourceCentre(newState));
                        return newState;
                      }}
                      sx={{ maxWidth: "fit-content" }}
                    />
                  }
                  label=""
                />
              </Box>
            )
          }
        ]}
        data={(resourceCentres || []).filter(
          (item) =>
            item.subscription &&
            item.subscription.activeSubscription &&
            !item.subscription.isInvoicePaused
        )}
        isLoading={!resourceCentres}
      />
      {showSendNotificationPanel && (
        <SendNotificationPanel
          onClose={() => setShowSendNotificationPanel(false)}
          selectedRcIds={selectedRcIds}
        />
      )}
    </Box>
  );
};

export default ResourceCentreWithNotifications;

import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { SETTING_ROUTES } from "../ResourceCentreShow";
import SectionHeading from "../SectionHeading";
import { t, tl } from "../../../components/translate";

interface UserSettingsProps {
  resourceCentreId: number;
  children: React.ReactNode;
}

const VendorSettings = ({ resourceCentreId, children }: UserSettingsProps): JSX.Element => {
  const { SUPPLIER, REFERRERS, ASSOCIATE_COMPANIES } = SETTING_ROUTES;
  const [value, setValue] = React.useState(SUPPLIER);
  const dispatch = useDispatch();

  const tabs = [
    { value: SUPPLIER, label: `${t("vendor.supplier")}`, testId: "suppliersTab" },
    { value: REFERRERS, label: `${t("vendor.referrers")}`, testId: "referrersTab" },
    {
      value: ASSOCIATE_COMPANIES,
      label: `${t("vendor.associateCompanies")}`,
      testId: "associateCompaniesTab"
    }
  ];

  const navigateTo = (path: string) => {
    dispatch(push(path));
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <SectionHeading name={tl("vendors.head")} />
      <Box sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              data-testmation={tab.testId}
              onClick={() => navigateTo(`/resourcecentres/${resourceCentreId}/${tab.value}`)}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
    </Box>
  );
};

export default VendorSettings;

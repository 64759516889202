import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import logo from "../../../../assets/images/poweredByOkhati.png";
import { getSectionStyle } from "../../Lab/LabPrint/LabPrintFunctions";
import { RootState } from "../../../store";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import BankPaperLetterHeadCentered from "../../Billing/PrintBill/BankPaperLetterHeadCentered";
import BankPaperLetterHead from "../../Billing/PrintBill/BankPaperLetterHead";
import { EditableTableView } from "../../../components/EditableTable/EditableTable";
import IntraoralAssessmentContent from "../../Modals/Assessment/IntraoralAssessmentContent";
import { BillClientInfo } from "../../Billing/PrintBill/BillPrintHelpers";
import InfoField from "../../Lab/LabPrint/LabprintComponents/LabPrintInfofield";
import { t } from "../../../components/translate";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { isValidEstimateData } from "./helpers";
import { Estimate } from "./interfaces";
import { getCustomerNumber } from "../ClientList";

interface Props {
  data: Estimate;
}

const EstimatePrint = React.forwardRef(
  ({ data }: Props, ref: React.ForwardedRef<HTMLDivElement | null>) => {
    const resourceCentre = useSelector(
      (state: RootState) =>
        state.resources.resourceCentres.find(
          (rc: ResourceCentre) => rc.id === state.userContext.resourceCentreId
        ) || state.userContext.resourceCentre
    );
    const headerColor =
      (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
        resourceCentre?.settings.printSettings.labPrintSettings.color) ||
      null;
    const showLetterhead = Boolean(
      resourceCentre?.settings.printSettings.assessmentPrintSettings?.includeLetterhead
    );
    const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
    const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
    const panNo = resourceCentre?.settings?.billingSettings?.panNo;
    const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
    const customSubtitle =
      resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
    const hideRcLogo = Boolean(customTitle);
    let letterHeadComponent;
    if (showLetterhead) {
      if (centralizedHeader) {
        letterHeadComponent = (
          <BankPaperLetterHeadCentered
            resourceCentre={{
              ...resourceCentre,
              panNo,
              subtitle: customSubtitle
            }}
            hideRcLogo={hideRcLogo}
          />
        );
      } else {
        letterHeadComponent = (
          <BankPaperLetterHead
            resourceCentre={{
              ...resourceCentre,
              panNo,
              subtitle: customSubtitle
            }}
            titleFontScale={clinicNameFontScale}
            hideRcLogo={hideRcLogo}
          />
        );
      }
    }

    const isValidData = isValidEstimateData(data.estimates);
    return (
      <iframe className="ifrm" title="Estimates Print">
        <div ref={ref}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <Box height="4.5cm" />
                </td>
              </tr>
            </tfoot>
            <thead>
              {!showLetterhead && (
                <tr style={{ borderBottom: "1px solid lightgrey" }}>
                  <td>
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle, padding: 0 }}>
                    <div>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "0.5cm",
                          fontWeight: 600,
                          textTransform: "uppercase"
                        }}
                      >
                        Estimates
                      </Typography>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid lightgrey"
                        px="10mm"
                        pb="1mm"
                      >
                        {data.client && (
                          <>
                            <Box component="div" width="45%">
                              <BillClientInfo
                                client={data.client}
                                customerNumber={getCustomerNumber(data.client.customerNumber)}
                              />
                            </Box>
                            <Box component="div" width="50%">
                              {data.client.registrationNo && (
                                <InfoField
                                  label={t("assessment.registrationNumber")}
                                  data={data.client.registrationNo}
                                />
                              )}
                              {data.client.ipdNo && (
                                <InfoField label={t("ipdNo")} data={data.client.ipdNo} />
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </div>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      px="10mm"
                    >
                      {isValidData.intraOral && (
                        <IntraoralAssessmentContent intraOralDoc={data.estimates.intraOral} />
                      )}
                      {isValidData.tabular && (
                        <EditableTableView tableState={data.estimates.tabular} />
                      )}
                      {data.validUntil && (
                        <>
                          <Typography fontWeight={600} component="span">
                            Valid until:
                          </Typography>
                          <Typography component="span">
                            {convertADtoBS(new Date(data.validUntil)).formatted4}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>

          <Box
            component="div"
            width="100%"
            className="billFooterPos"
            borderTop="1px solid lightgrey"
            px="10mm"
            py="5mm"
            display="flex"
            justifyContent="flex-end"
          >
            <img src={logo} alt="logo" height="35px" />
          </Box>
        </div>
      </iframe>
    );
  }
);

export default EstimatePrint;

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import { omit, startCase } from "lodash";
import * as React from "react";
import DepartmentSelect from "../../components/DepartmentSelect";
import PeriodPicker from "../../components/PeriodPicker";
import ReferrersAutoSelect from "../../components/Referrers/ReferrersAutoSelect";
import { tl } from "../../components/translate";
import hasOwnProperty from "../../helpers/object";
import { VendorType } from "../../interfaces/Accounts";
import { VisitType } from "../../interfaces/BillInterfaces";
import { TestTypes } from "../../interfaces/Lab";
import { ProductInterface, ServiceType } from "../../interfaces/ProductInterface";
import { Referrer } from "../../interfaces/ReferrerInterface";
import { TransactionType } from "../../interfaces/StockInterfaces";
import { BillTypeAutoSelect } from "../Billing/BillTypeAutoSelect";
import ClientsAutoSelectMulti from "../Client/ClientMultiSelect";
import LabStatusAutoSelect from "../Lab/LabStatusAutoSelect";
import LabTestsGroupNameAutoSelect from "../Lab/LabTestsGroupNameAutoSelect";
import LabTestsNameAutoSelect from "../Lab/LabTestsNameAutoSelect";
import SupplierMultiSelect from "../ResourceCentre/SupplierMultiSelect";
import ServiceProviderMultiAutoSelect from "../ServiceProvider/ServiceProviderMultiAutoSelect";
import SeriveCategoryAutoSelect from "../Services/ServiceCategoryAutoSelect";
import ServiceTypeAutoSelect from "../Services/ServiceTypeAutoSelect";
import ServicesAutoSelect from "../Services/ServicesAutoSelect";
import StockCategoriesSelect from "../Stock/StockCategoriesSelect";
import { ProductType, ProductTypeInfo } from "../Stock/StockCreateEdit/CreateStockProduct";
import StockProductsAutoSelectMulti from "../Stock/StockProductsAutoSelectMulti";
import EmployeeMultiSelect, { IdSelector } from "./EmployeeMultiSelect";
import SelectVisitType from "./SelectVisitType";
import ClinicSelect from "./SummaryReport/ClinicSelect";
import ModuleSelect from "./ModuleSelect";
import ShowClientByDateSelect from "../../components/ShowClientBySelect/ShowClientByDateSelect";
import KnownUsFromSelect from "../../components/KnownUsFromSelect/KnownUsFromSelect";
import { ReverseMap } from "../../helpers/types";
import useDepartmentSubscription from "../../hooks/useDepartmentSubscription";
import { BookingStatus, BookingStatusType } from "../Bookings/ManageBooking";
import BranchMultiSelect from "./BranchReports/BranchMultiSelect";
import { paymentOptions, paymentOptionsEnum } from "../Billing/Editor/BillSummary";
import { SUBSCRIPTION_STATUS_FILTER, SubscriptionStatusType } from "../ResourceCentre";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";
import { BillCounters } from "../Billing/Editor/BillSettings";
import KitchenPharmacyBranchSelect from "./SelectKitchenPharmacyBranch";
import { DaybookTransactionEnum } from "../../enum/Daybook";
import SelectPaymentMethods from "./SelectPaymentMethods";

/* eslint-disable react/jsx-props-no-spreading */
const Boxed = ({ children, ...props }) => (
  <Box {...props} paddingBottom="8px">
    {children}
  </Box>
);

interface IFilterModifierProps {
  clinicSelect: () => void;
}

export const vendorTypeListFilters = [
  "serviceProviderIds",
  "supplierIds",
  "referrersName",
  "employeeIds"
];

export enum Entity {
  BOOKING = "booking",
  BILL = "bill",
  RESOURCE_CENTRE = "resourceCentre",
  ASSESSMENT = "assessment",
  LAB_TEST_RECORD = "labTestRecord",
  SUBSCRIPTION = "subscription",
  FISCAL_PERIOD = "fiscalPeriod",
  IPD = "ipd",
  USER = "user"
}

export enum SHOW_CLIENT_BY {
  CREATED_DATE = "createdDate",
  LAST_VISITED_DATE = "lastVisitedDate"
}

export type ShowClientByType = ReverseMap<typeof SHOW_CLIENT_BY>;

export enum BILLING_SOURCES {
  All = "all",
  SERVICES = "services",
  STOCKS = "stocks"
}

export enum STOCK_STATUSES {
  EXPIRED = "expired",
  EXPIRING_IN_90_DAYS = "expiringIn90Days",
  LOW_BELOW_MINIMUM_STOCK = "lowBelowMinimumStock"
}

export type ReceiptModeType = ReverseMap<typeof paymentOptionsEnum>;

export type BillingSourcesType = ReverseMap<typeof BILLING_SOURCES>;

export type StockStatusType = ReverseMap<typeof STOCK_STATUSES>;

export enum FILTERS {
  FROM = "from",
  UNTIL = "until",
  INCLUDE_BELOW_ZERO = "includeBelowZero",
  STOCK_PRODUCT_CATEGORY = "stockProductCategory",
  PRODUCT_ITEMS = "productItems",
  PRODUCT_TYPE = "productType",
  ONLY_EXPIRED = "onlyExpired",
  ONLY_BELOW_MIN_STOCK = "onlyBelowMinStock",
  STOCK_EXPIRING_IN = "stockExpiringIn",
  PRODUCT_CATEGORY = "productCategory",
  INVOICE_NUMBER = "invoiceNumber",
  USER_PERIOD_FILTER = "usePeriodFilter",
  SERVICE_PROVIDER_IDS = "serviceProviderIds",
  BOOKING_STATUS = "bookingStatus",
  SERVICE_TYPE = "serviceType",
  INCLUDE_INTERNAL_USE_ITEMS = "includeInternalUseItems",
  SUPPLIERS_IDS = "supplierIds",
  IGNORE_ZERO_DUE = "ignoreZeroDue",
  CLIENT_IDS = "clientIds",
  SERVICE_IDS = "serviceIds",
  BILL_TYPE = "billType",
  INCLUDE_DRAFTS = "includeDrafts",
  ONLY_PUBLIC = "onlyPublic",
  REFERRERS_NAME = "referrersName",
  SUPPLIER_AGEING_DAYS = "supplierAgeingDays",
  LEDGER_IDS = "ledgerIds",
  SUB_LEDGER_TYPE = "subLedgerType",
  VENDOR_TYPE = "vendorType",
  EMPLOYEE_IDS = "employeeIds",
  PERIOD_DATE = "periodDate",
  DEPARTMENT_ID = "departmentId",
  MESSAGE_STATUS = "messageStatus",
  TRANSACTION_TYPE = "transactionType",
  CLINIC_SELECT = "clinicSelect",
  LAB_TEST_GROUP_IDS = "labTestGroupIds",
  LAB_RECORD_TYPE = "labRecordType",
  LAB_TESTS_IDS = "labTestsIds",
  LAB_STATUS = "labStatus",
  SHOW_CANCELLED_BILL = "showCancelledBill",
  VISIT_TYPE = "visitType",
  ENTITY = "entity",
  ENTITY_ID = "entityId",
  KNOWN_US_FROM = "knownUsFrom",
  SHOW_CLIENT_BY_DATE = "showClientByDate",
  BILLING_SOURCE = "billingSource",
  RESOURCE_CENTRE_IDS = "resourceCentreIds",
  RECEIPT_MODE = "receiptMode",
  SUBSCRIPTION_STATUS = "subscriptionStatus",
  STOCK_STATUS = "stockStatus",
  COUNTER = "counter",
  SHOW_IN_SINGLE_ROW = "showInSingleRow",
  BRANCH_IDS = "branchIds",
  SHOW_LAB_TEST_IN_SINGLE_ROW = "showLabTestInSingleRow",
  DAYBOOK_TRX_TYPE = "daybookTrxType",
  PAYMENT_METHODS = "paymentMethods"
}

export interface FilterProps {
  [FILTERS.FROM]: Date;
  [FILTERS.UNTIL]: Date;
  [FILTERS.INCLUDE_BELOW_ZERO]?: boolean;
  [FILTERS.STOCK_PRODUCT_CATEGORY]?: string;
  [FILTERS.PRODUCT_ITEMS]?: ProductInterface[] | ProductInterface;
  [FILTERS.PRODUCT_TYPE]?: ProductType.Sellable | ProductType.InternalUse;
  [FILTERS.ONLY_EXPIRED]?: boolean;
  [FILTERS.ONLY_BELOW_MIN_STOCK]?: boolean;
  [FILTERS.STOCK_EXPIRING_IN]?: Date;
  [FILTERS.PRODUCT_CATEGORY]?: string;
  [FILTERS.INVOICE_NUMBER]?: number | null;
  [FILTERS.USER_PERIOD_FILTER]?: boolean;
  [FILTERS.SERVICE_PROVIDER_IDS]?: number[];
  [FILTERS.BOOKING_STATUS]?: BookingStatusType[];
  [FILTERS.SERVICE_TYPE]?: string;
  [FILTERS.INCLUDE_INTERNAL_USE_ITEMS]?: boolean;
  [FILTERS.SUPPLIERS_IDS]?: number[];
  [FILTERS.IGNORE_ZERO_DUE]?: boolean;
  [FILTERS.CLIENT_IDS]?: number[];
  [FILTERS.SERVICE_IDS]?: number[];
  [FILTERS.BILL_TYPE]?: string;
  [FILTERS.INCLUDE_DRAFTS]?: boolean;
  [FILTERS.ONLY_PUBLIC]?: boolean;
  [FILTERS.REFERRERS_NAME]?: Array<Referrer>;
  [FILTERS.SUPPLIER_AGEING_DAYS]?: number | null;
  [FILTERS.LEDGER_IDS]?: number[];
  [FILTERS.SUB_LEDGER_TYPE]?: string;
  [FILTERS.VENDOR_TYPE]?: string;
  [FILTERS.EMPLOYEE_IDS]?: number[];
  [FILTERS.PERIOD_DATE]?: Date;
  [FILTERS.DEPARTMENT_ID]?: number;
  [FILTERS.MESSAGE_STATUS]?: string;
  [FILTERS.CLINIC_SELECT]?: number;
  [FILTERS.TRANSACTION_TYPE]?: TransactionType | null;
  [FILTERS.LAB_TEST_GROUP_IDS]?: number[];
  [FILTERS.LAB_TESTS_IDS]?: number[];
  [FILTERS.LAB_STATUS]?: string;
  [FILTERS.SHOW_CANCELLED_BILL]?: boolean;
  [FILTERS.VISIT_TYPE]?: VisitType.IPD | VisitType.OPD;
  [FILTERS.ENTITY]?: Entity;
  [FILTERS.ENTITY_ID]?: number | null;
  [FILTERS.KNOWN_US_FROM]?: string;
  [FILTERS.SHOW_CLIENT_BY_DATE]?: ShowClientByType;
  [FILTERS.BILLING_SOURCE]?: BillingSourcesType;
  [FILTERS.RECEIPT_MODE]?: ReceiptModeType[];
  [FILTERS.DAYBOOK_TRX_TYPE]?: string | null;
  [FILTERS.SUBSCRIPTION_STATUS]?: SubscriptionStatusType;
  [FILTERS.STOCK_STATUS]?: StockStatusType[];
  [FILTERS.COUNTER]?: string;
  [FILTERS.SHOW_IN_SINGLE_ROW]?: boolean;
  [FILTERS.BRANCH_IDS]?: Array<number>;
  [FILTERS.SHOW_GROUP_WISE_REPORT]?: boolean;
  [FILTERS.PAYMENT_METHODS]?: Array<string>;
}

interface FiltersInterface {
  filters: FilterProps;
  onSetFilters: (filter: FilterProps, vendorType?: string) => void;
  showPeriod?: boolean;
  showPeriodToggle?: boolean;
  modifiers?: IFilterModifierProps;
  forAccountUser?: boolean;
  showTillDate?: boolean;
  vendorTypes?: string[];
  employeeIdSelector?: IdSelector.EMPLOYEE | IdSelector.USER;
  disableDepartment?: boolean;
  hideEmployeeSelect?: boolean;
  isReminderReport?: boolean;
  showFuturePeriods?: boolean;
  entityOptions?: string[];
  showMonthOnly?: boolean;
  hideFiscalYear?: boolean;
  singularProduct?: boolean;
  disableMonthsBefore?: boolean;
}

const checkVendorType = (
  filters: FilterProps,
  changes: FilterProps,
  key: string,
  vendorType: string
): boolean =>
  changes.vendorType
    ? changes.vendorType === vendorType
    : hasOwnProperty(filters, key) || filters.vendorType === vendorType;

const GridItem = ({ children }: { children: React.ReactNode }) => {
  const { isRnWebView } = useIsReactNativeWebView();
  return (
    <Grid item xs={isRnWebView ? 4 : 12} sm={isRnWebView ? 4 : 6} md={4} lg={3}>
      {children}
    </Grid>
  );
};

const Filters = ({
  filters,
  onSetFilters,
  showPeriod = true,
  showPeriodToggle = false,
  modifiers = {} as IFilterModifierProps,
  forAccountUser,
  showTillDate,
  vendorTypes,
  employeeIdSelector,
  disableDepartment = false,
  hideEmployeeSelect = false,
  isReminderReport = false,
  showFuturePeriods = false,
  entityOptions = [],
  showMonthOnly = false,
  hideFiscalYear = false,
  singularProduct = false,
  disableMonthsBefore = false
}: FiltersInterface): JSX.Element => {
  const [changes, setChanges] = React.useState<FilterProps>(filters);
  const { isDepartmentSubscribed } = useDepartmentSubscription();

  const onChange = (key, val) => {
    const filter = { ...changes, [key]: val };
    if (key === "serviceType" && val === ServiceType.package) filter.productCategory = null;
    setChanges(filter);
  };
  const onSave = () => {
    onSetFilters({
      ...filters,
      ...changes
    });
    // allow to save null value: visitType
    if (hasOwnProperty(filters, FILTERS.VISIT_TYPE)) {
      setChanges({ visitType: changes.visitType } as FilterProps);
    }
  };

  return (
    <Box bgcolor="#F0F0F0" padding="0.5rem 1rem" borderRadius="0.3rem">
      <Grid container columnSpacing={4} rowSpacing={0} alignItems="center">
        <>
          {hasOwnProperty(filters, FILTERS.BRANCH_IDS) && (
            <GridItem>
              <Boxed>
                <KitchenPharmacyBranchSelect
                  onChange={(val) => onChange(FILTERS.BRANCH_IDS, val)}
                />
              </Boxed>
            </GridItem>
          )}

          {hasOwnProperty(filters, FILTERS.RESOURCE_CENTRE_IDS) && (
            <GridItem>
              <Boxed>
                <BranchMultiSelect
                  onChange={(val) => onChange(FILTERS.RESOURCE_CENTRE_IDS, val)}
                  initialValue={filters.resourceCentreIds}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.CLINIC_SELECT) && (
            <GridItem>
              <Boxed display="flex" alignItems="flex-end">
                <ClinicSelect
                  value={changes.stockProductCategory || filters.stockProductCategory}
                  onChange={(val) => onChange(FILTERS.CLINIC_SELECT, val?.id || null)}
                  modifier={modifiers.clinicSelect}
                />
              </Boxed>
            </GridItem>
          )}
          {showPeriod && (
            <GridItem>
              <Boxed display="flex" alignItems="flex-end">
                {showPeriodToggle && (
                  <Checkbox
                    style={{ marginRight: "4px", marginLeft: "-9px" }}
                    checked={
                      hasOwnProperty(changes, FILTERS.USER_PERIOD_FILTER)
                        ? changes.usePeriodFilter
                        : filters.usePeriodFilter
                    }
                    onChange={() =>
                      onChange(
                        FILTERS.USER_PERIOD_FILTER,
                        hasOwnProperty(changes, FILTERS.USER_PERIOD_FILTER)
                          ? !changes.usePeriodFilter
                          : !filters.usePeriodFilter
                      )
                    }
                  />
                )}
                <Box flex={1}>
                  <PeriodPicker
                    start={changes.from || filters.from}
                    end={changes.until || filters.until}
                    onChange={({ start, end }) => {
                      setChanges({
                        ...changes,
                        from: start?.toDate(),
                        until: end.toDate()
                      });
                    }}
                    forAccountUser={forAccountUser}
                    limitToMonth
                    showTillDate={showTillDate}
                    showFuturePeriods={showFuturePeriods}
                    showMonthOnly={showMonthOnly}
                    hideFiscalYear={hideFiscalYear}
                    disableMonthsBefore={disableMonthsBefore}
                  />
                </Box>
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.BILLING_SOURCE) && (
            <GridItem>
              <Boxed display="flex" alignItems="center">
                <FormControl fullWidth>
                  <InputLabel id="billing-source-label">Billing Source</InputLabel>
                  <Select
                    labelId="billing-source-label"
                    label="billing-source-label"
                    onChange={(val) => onChange(FILTERS.BILLING_SOURCE, val.target.value)}
                    defaultValue={filters.billingSource}
                  >
                    {Object.values(BILLING_SOURCES).map((source) => (
                      <MenuItem value={source} key={source}>
                        {startCase(source)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.DEPARTMENT_ID) && isDepartmentSubscribed && (
            <GridItem>
              <Boxed display="flex" alignItems="center">
                <DepartmentSelect
                  fullWidth
                  disableClearable={false}
                  variant="standard"
                  id={changes.departmentId || filters.departmentId}
                  onChange={(val) => onChange(FILTERS.DEPARTMENT_ID, val?.id)}
                  showDeleted
                  disabled={disableDepartment}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.VENDOR_TYPE) && (
            <GridItem>
              <Boxed>
                <TextField
                  fullWidth
                  label="Select Vendor Type"
                  InputLabelProps={{ shrink: true }}
                  select
                  value={changes.vendorType || filters.vendorType}
                  onChange={({ target }) => {
                    setChanges({
                      ...omit(changes, vendorTypeListFilters),
                      vendorType: target.value,
                      ...(target.value === VendorType.SUPPLIER && {
                        supplierIds: []
                      })
                    } as FilterProps);
                  }}
                >
                  {(vendorTypes || Object.values(VendorType)).map((vendor) => (
                    <MenuItem value={vendor} key={vendor}>
                      {startCase(vendor)}
                    </MenuItem>
                  ))}
                </TextField>
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.COUNTER) && (
            <GridItem>
              <Boxed>
                <Autocomplete
                  fullWidth
                  disableClearable={false}
                  value={changes.counter || filters.counter}
                  onChange={(_e: React.SyntheticEvent, newValue: SubscriptionStatusType) => {
                    onChange(FILTERS.COUNTER, newValue);
                  }}
                  getOptionLabel={(option) => startCase(BillCounters[option])}
                  options={Object.keys(BillCounters)}
                  renderInput={(params) => <TextField {...params} label="Select Counter" />}
                />
              </Boxed>
            </GridItem>
          )}
          {checkVendorType(
            filters,
            changes,
            FILTERS.SERVICE_PROVIDER_IDS,
            VendorType.SERVICE_PROVIDER
          ) && (
            <GridItem>
              <Boxed>
                <ServiceProviderMultiAutoSelect
                  serviceProviderIds={changes.serviceProviderIds || filters.serviceProviderIds}
                  onChange={(val) => onChange(FILTERS.SERVICE_PROVIDER_IDS, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SUBSCRIPTION_STATUS) && (
            <GridItem>
              <Boxed display="flex" alignItems="center">
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={changes.subscriptionStatus || filters.subscriptionStatus}
                  onChange={(_e: React.SyntheticEvent, newValue: SubscriptionStatusType) => {
                    onChange(FILTERS.SUBSCRIPTION_STATUS, newValue);
                  }}
                  getOptionLabel={(option) => startCase(option)}
                  options={Object.values(SUBSCRIPTION_STATUS_FILTER)}
                  renderInput={(params) => <TextField {...params} label="Subscription Status" />}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.BOOKING_STATUS) && (
            <GridItem>
              <Boxed display="flex" alignItems="center">
                <Autocomplete
                  fullWidth
                  multiple
                  value={changes.bookingStatus || filters.bookingStatus}
                  onChange={(_e: React.SyntheticEvent, newValue: BookingStatusType[]) => {
                    onChange(FILTERS.BOOKING_STATUS, newValue);
                  }}
                  getOptionLabel={(option) => startCase(option)}
                  options={Object.values(BookingStatus)}
                  renderInput={(params) => <TextField {...params} label="Booking Status" />}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SERVICE_TYPE) && (
            <GridItem>
              <Boxed>
                <ServiceTypeAutoSelect
                  value={changes.serviceType || filters.serviceType}
                  onChange={(val) => onChange(FILTERS.SERVICE_TYPE, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.TRANSACTION_TYPE) && (
            <GridItem>
              <Boxed>
                <Autocomplete
                  value={changes.transactionType || filters.transactionType}
                  onChange={(_e: React.SyntheticEvent, newValue: string | null) => {
                    onChange(FILTERS.TRANSACTION_TYPE, newValue);
                  }}
                  getOptionLabel={(option) => startCase(option)}
                  options={Object.values(TransactionType).filter((type) =>
                    [
                      TransactionType.PURCHASE,
                      TransactionType.PURCHASE_RETURN,
                      TransactionType.SALES,
                      TransactionType.SALES_RETURN,
                      TransactionType.INTERNAL_USE,
                      TransactionType.INTERNAL_RETURN,
                      TransactionType.OPENING_STOCK
                    ].includes(type)
                  )}
                  renderInput={(params) => <TextField {...params} label="Transaction Type" />}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.DAYBOOK_TRX_TYPE) && (
            <GridItem>
              <Boxed>
                <Autocomplete
                  value={changes.transactionType || filters.transactionType}
                  onChange={(_, newValue: string | null) => {
                    onChange(FILTERS.DAYBOOK_TRX_TYPE, newValue);
                  }}
                  getOptionLabel={(option) => startCase(option)}
                  options={Object.values(DaybookTransactionEnum)}
                  renderInput={(params) => <TextField {...params} label="Transaction Type" />}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.INCLUDE_INTERNAL_USE_ITEMS) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.INCLUDE_INTERNAL_USE_ITEMS)
                          ? changes.includeInternalUseItems
                          : filters.includeInternalUseItems
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.INCLUDE_INTERNAL_USE_ITEMS,
                          hasOwnProperty(changes, FILTERS.INCLUDE_INTERNAL_USE_ITEMS)
                            ? !changes.includeInternalUseItems
                            : !filters.includeInternalUseItems
                        )
                      }
                    />
                  }
                  label="Include Items For Internal Use"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.PRODUCT_CATEGORY) && (
            <GridItem>
              <Boxed>
                <SeriveCategoryAutoSelect
                  disabled={(changes.serviceType || filters.serviceType) === ServiceType.package}
                  value={
                    (changes.serviceType || filters.serviceType) === ServiceType.package
                      ? null
                      : changes.productCategory || filters.productCategory
                  }
                  onChange={(val) => onChange(FILTERS.PRODUCT_CATEGORY, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SERVICE_IDS) && (
            <GridItem>
              <Boxed>
                <ServicesAutoSelect
                  relatedFilters={{
                    productCategory: changes.productCategory || filters.productCategory,
                    serviceType: changes.serviceType || filters.serviceType
                  }}
                  values={changes.serviceIds || filters.serviceIds}
                  onChange={(val) => onChange(FILTERS.SERVICE_IDS, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {checkVendorType(filters, changes, FILTERS.SUPPLIERS_IDS, VendorType.SUPPLIER) && (
            <GridItem>
              <Boxed>
                <SupplierMultiSelect
                  supplierIds={changes.supplierIds || filters.supplierIds}
                  onChange={(val) => onChange(FILTERS.SUPPLIERS_IDS, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SHOW_CLIENT_BY_DATE) && (
            <GridItem>
              <Boxed>
                <ShowClientByDateSelect
                  value={filters.showClientByDate}
                  onChange={(val) => onChange(FILTERS.SHOW_CLIENT_BY_DATE, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.CLIENT_IDS) && (
            <GridItem>
              <Boxed>
                <ClientsAutoSelectMulti
                  clientIds={filters.clientIds}
                  onChange={(val) => onChange(FILTERS.CLIENT_IDS, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.KNOWN_US_FROM) && (
            <GridItem>
              <Boxed>
                <KnownUsFromSelect
                  value={filters.knownUsFrom}
                  variant="standard"
                  onChange={(val) => onChange(FILTERS.KNOWN_US_FROM, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SUPPLIER_AGEING_DAYS) && (
            <GridItem>
              <Boxed>
                <TextField
                  label="Ageing Days"
                  onChange={(e) => onChange(FILTERS.SUPPLIER_AGEING_DAYS, e.target.value)}
                  fullWidth
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.PRODUCT_TYPE) && (
            <GridItem>
              <Boxed>
                <FormControl fullWidth>
                  <InputLabel id="product-type-label">Filter By Product Nature</InputLabel>
                  <Select
                    labelId="product-type-label"
                    label="Filter By Product Nature"
                    onChange={(val) => onChange(FILTERS.PRODUCT_TYPE, val.target.value)}
                    defaultValue={filters.productType}
                  >
                    <MenuItem value={ProductType.Sellable}>{ProductTypeInfo.Sellable}</MenuItem>
                    <MenuItem value={ProductType.InternalUse}>
                      {startCase(ProductTypeInfo.InternalUse)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.STOCK_STATUS) && (
            <GridItem>
              <Boxed>
                <Autocomplete
                  multiple
                  fullWidth
                  limitTags={1}
                  value={changes?.stockStatus || filters.stockStatus}
                  onChange={(_, newValue) => {
                    if (newValue.length > 0) {
                      onChange(FILTERS.STOCK_STATUS, newValue);
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        label={startCase(option)}
                        {...getTagProps({ index })}
                        {...(value.length < 2 ? { onDelete: undefined } : {})}
                        key={option}
                      />
                    ))
                  }
                  options={Object.values(STOCK_STATUSES)}
                  renderInput={(params) => <TextField {...params} label="Stock Statuses" />}
                  getOptionLabel={(option) => startCase(option)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.PRODUCT_ITEMS) && (
            <GridItem>
              <Boxed>
                <StockProductsAutoSelectMulti
                  singularProduct={singularProduct}
                  onChange={(val) => onChange(FILTERS.PRODUCT_ITEMS, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.STOCK_PRODUCT_CATEGORY) && (
            <GridItem>
              <Boxed>
                <StockCategoriesSelect
                  value={changes.stockProductCategory || filters.stockProductCategory}
                  onChange={(val) => onChange(FILTERS.STOCK_PRODUCT_CATEGORY, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.INVOICE_NUMBER) && (
            <GridItem>
              <Boxed>
                <TextField
                  label="Invoice Number"
                  onChange={(e) => onChange(FILTERS.INVOICE_NUMBER, e.target.value)}
                  fullWidth
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.IGNORE_ZERO_DUE) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.IGNORE_ZERO_DUE)
                          ? changes.ignoreZeroDue
                          : filters.ignoreZeroDue
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.IGNORE_ZERO_DUE,
                          hasOwnProperty(changes, FILTERS.IGNORE_ZERO_DUE)
                            ? !changes.ignoreZeroDue
                            : !filters.ignoreZeroDue
                        )
                      }
                    />
                  }
                  label="Ignore Zero Due"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SHOW_IN_SINGLE_ROW) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.SHOW_IN_SINGLE_ROW)
                          ? changes.showInSingleRow
                          : filters.showInSingleRow
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.SHOW_IN_SINGLE_ROW,
                          hasOwnProperty(changes, FILTERS.SHOW_IN_SINGLE_ROW)
                            ? !changes.showInSingleRow
                            : !filters.showInSingleRow
                        )
                      }
                    />
                  }
                  label="Show in single row"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.ONLY_EXPIRED) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.ONLY_EXPIRED)
                          ? changes.onlyExpired
                          : filters.onlyExpired
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.ONLY_EXPIRED,
                          hasOwnProperty(changes, FILTERS.ONLY_EXPIRED)
                            ? !changes.onlyExpired
                            : !filters.onlyExpired
                        )
                      }
                    />
                  }
                  label="Show Expired"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.INCLUDE_BELOW_ZERO) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.INCLUDE_BELOW_ZERO)
                          ? changes.includeBelowZero
                          : filters.includeBelowZero
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.INCLUDE_BELOW_ZERO,
                          hasOwnProperty(changes, FILTERS.INCLUDE_BELOW_ZERO)
                            ? !changes.includeBelowZero
                            : !filters.includeBelowZero
                        )
                      }
                    />
                  }
                  label="Show below zero stocks"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.ONLY_BELOW_MIN_STOCK) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.ONLY_BELOW_MIN_STOCK)
                          ? changes.onlyBelowMinStock
                          : filters.onlyBelowMinStock
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.ONLY_BELOW_MIN_STOCK,
                          hasOwnProperty(changes, FILTERS.ONLY_BELOW_MIN_STOCK)
                            ? !changes.onlyBelowMinStock
                            : !filters.onlyBelowMinStock
                        )
                      }
                    />
                  }
                  label="Show Only Below Min Stock"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.BILL_TYPE) && (
            <GridItem>
              <Boxed>
                <BillTypeAutoSelect
                  billType={changes.billType || filters.billType}
                  onChange={(val) => onChange(FILTERS.BILL_TYPE, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.MESSAGE_STATUS) && (
            <GridItem paddingLeft="10px" paddingTop="8px">
              <Boxed>
                <Autocomplete
                  value={changes.messageStatus || filters.messageStatus}
                  onChange={(_e: React.SyntheticEvent, newValue: string | null) => {
                    onChange(FILTERS.MESSAGE_STATUS, newValue);
                  }}
                  options={["Pass", "Fail"]}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="SMS Status" />}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, "referrerIds") && (
            <GridItem>
              <Boxed>
                <ReferrersAutoSelect
                  isDisabled={false}
                  multiple
                  onChange={(val) => onChange("referrersName", val)}
                  variant="standard"
                />
              </Boxed>
            </GridItem>
          )}
          {checkVendorType(filters, changes, FILTERS.EMPLOYEE_IDS, VendorType.EMPLOYEE) &&
            !hideEmployeeSelect && (
              <GridItem>
                <Boxed>
                  <EmployeeMultiSelect
                    idSelector={employeeIdSelector}
                    onChange={(val) => onChange(FILTERS.EMPLOYEE_IDS, val)}
                  />
                </Boxed>
              </GridItem>
            )}
          {hasOwnProperty(filters, FILTERS.ONLY_PUBLIC) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.ONLY_PUBLIC)
                          ? changes.onlyPublic
                          : filters.onlyPublic
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.ONLY_PUBLIC,
                          hasOwnProperty(changes, FILTERS.ONLY_PUBLIC)
                            ? !changes.onlyPublic
                            : !filters.onlyPublic
                        )
                      }
                    />
                  }
                  label="Show Public Only"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.RECEIPT_MODE) && (
            <GridItem>
              <Boxed display="flex" alignItems="center">
                <Autocomplete
                  fullWidth
                  multiple
                  value={changes?.receiptMode || filters.receiptMode}
                  onChange={(_, newValue: ReceiptModeType[]) => {
                    onChange(FILTERS.RECEIPT_MODE, newValue);
                  }}
                  getOptionLabel={(option) => startCase(option)}
                  options={paymentOptions}
                  renderInput={(params) => <TextField {...params} label="Receipt Mode" />}
                />
              </Boxed>
            </GridItem>
          )}
          {checkVendorType(filters, changes, FILTERS.REFERRERS_NAME, VendorType.REFERRER) && (
            <GridItem>
              <Boxed>
                <ReferrersAutoSelect onChange={(val) => onChange(FILTERS.REFERRERS_NAME, val)} />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.VISIT_TYPE) && (
            <GridItem>
              <Boxed>
                <SelectVisitType
                  internalValue={changes?.visitType}
                  value={filters?.visitType}
                  onChange={(val) => onChange(FILTERS.VISIT_TYPE, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.PAYMENT_METHODS) && (
            <GridItem>
              <Boxed>
                <SelectPaymentMethods onChange={(val) => onChange(FILTERS.PAYMENT_METHODS, val)} />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.LAB_STATUS) && (
            <GridItem>
              <Boxed>
                <LabStatusAutoSelect onChange={(val) => onChange(FILTERS.LAB_STATUS, val)} />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.LAB_TEST_IDS) && (
            <GridItem>
              <Boxed>
                <LabTestsNameAutoSelect
                  multiple
                  isDisabled={false}
                  onChange={(val) => onChange(FILTERS.LAB_TEST_IDS, val)}
                  variant="standard"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.LAB_TEST_GROUP_IDS) && (
            <GridItem>
              <Boxed>
                <LabTestsGroupNameAutoSelect
                  onChange={(val) => onChange(FILTERS.LAB_TEST_GROUP_IDS, val)}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.LAB_RECORD_TYPE) && (
            <GridItem>
              <Boxed>
                <FormControl fullWidth>
                  <InputLabel id="lab-record-type-label">Lab Type</InputLabel>
                  <Select
                    labelId="lab-record-type-label"
                    label="Lab Type"
                    onChange={(val) => onChange(FILTERS.LAB_RECORD_TYPE, val.target.value)}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {Object.values(TestTypes)
                      .filter((type) => type !== TestTypes.CYTOLOGY)
                      .map((type) => (
                        <MenuItem value={type} key={type}>
                          {startCase(type)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SHOW_LAB_TEST_IN_SINGLE_ROW) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.SHOW_LAB_TEST_IN_SINGLE_ROW)
                          ? changes.showLabTestInSingleRow
                          : filters.showLabTestInSingleRow
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.SHOW_LAB_TEST_IN_SINGLE_ROW,
                          hasOwnProperty(changes, FILTERS.SHOW_LAB_TEST_IN_SINGLE_ROW)
                            ? !changes.showLabTestInSingleRow
                            : !filters.showLabTestInSingleRow
                        )
                      }
                    />
                  }
                  label="Show group wise "
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.STOCK_EXPIRING_IN) && (
            <GridItem>
              <Boxed display="flex" alignItems="center">
                <Typography>Show items expiring in</Typography>{" "}
                <TextField
                  value={changes.stockExpiringIn || filters.stockExpiringIn}
                  onChange={(e) => onChange(FILTERS.STOCK_EXPIRING_IN, e.target.value)}
                  style={{ width: "48px", marginLeft: "16px" }}
                  type="number"
                />
                <Typography style={{ marginLeft: "16px" }}>days</Typography>
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.SHOW_CANCELLED_BILL) && (
            <GridItem>
              <Boxed>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        hasOwnProperty(changes, FILTERS.SHOW_CANCELLED_BILL)
                          ? changes.showCancelledBill
                          : filters.showCancelledBill
                      }
                      onChange={() =>
                        onChange(
                          FILTERS.SHOW_CANCELLED_BILL,
                          hasOwnProperty(changes, FILTERS.SHOW_CANCELLED_BILL)
                            ? !changes.showCancelledBill
                            : !filters.showCancelledBill
                        )
                      }
                    />
                  }
                  label="Show Cancelled Bill"
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.ENTITY) && (
            <GridItem>
              <Boxed>
                <ModuleSelect
                  isReminderReport={isReminderReport}
                  value={changes.entity || filters.entity}
                  onChange={(value) => onChange(FILTERS.ENTITY, value)}
                  entityOptions={entityOptions}
                />
              </Boxed>
            </GridItem>
          )}
          {hasOwnProperty(filters, FILTERS.ENTITY_ID) && (
            <GridItem>
              <Boxed>
                <TextField
                  label="Enter Id..."
                  onChange={({ target }) => onChange(FILTERS.ENTITY_ID, target.value)}
                />
              </Boxed>
            </GridItem>
          )}
        </>
        <Grid item xs lg>
          <Box display="flex" alignItems="center" justifyContent="end">
            <Button
              data-testmation="applyFilterButton"
              disabled={!Object.values(changes).length}
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              {tl("reports.applyFilter")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
Filters.defaultProps = {
  modifiers: {},
  showPeriodToggle: false,
  showPeriod: true,
  forAccountUser: false,
  showTillDate: false,
  vendorTypes: null,
  employeeIdSelector: IdSelector.USER,
  disableDepartment: false,
  hideEmployeeSelect: false
};

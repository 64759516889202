import React from "react";
import { XYChart, AreaSeries, Axis } from "@visx/xychart";

import { curveCardinal } from "@visx/curve";
import { scaleOrdinal } from "@visx/scale";

import moment from "moment";
import { LegendOrdinal } from "@visx/legend";
import { Box, Typography } from "@mui/material";
import { format } from "d3-format";
import ChartToolTip from "./ChartToolTip";
import theme from "./customTheme";
import "./styles.css";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { useDashboardWeeklyRevenue } from "../hooks/useDashboard";
import { WeeklyRevenuesData } from "../../../interfaces/DashboardInterface";
import { roundTwoDecimalPoint } from "../../../helpers/number";

interface RevenueChartPropsInterface {
  width: number;
  height: number;
}

const RevenueChart = ({ width, height }: RevenueChartPropsInterface): React.ReactElement => {
  const weeklyData = useDashboardWeeklyRevenue();
  const data = weeklyData?.data || [];

  const bottomTickFormat = (d, i) => {
    if (i === 0 || data.length - 1 === i) {
      return "";
    }
    const dateObj = new Date(d);
    return convertADtoBS(moment(dateObj))?.formatted3;
  };
  const leftTickFormat = (d) => {
    const f = format(".2s");
    return f(d);
  };

  const toolTipDate = (d) => convertADtoBS(moment(d.date))?.formatted3;

  const getSales = (d: WeeklyRevenuesData) => Number(d.totalSale);
  const getReceipts = (d: WeeklyRevenuesData) => Number(d.totalReceipt);
  const getDate = (d: WeeklyRevenuesData) => moment(d?.date);

  const grey = "#5A607F";
  const colorScale = scaleOrdinal({
    domain: ["sales", "receipt"],
    range: ["#009654", "rgba(0, 150, 84, 0.5)"]
  });
  const accessors = {
    x: {
      sales: getSales,
      receipt: getReceipts
    },
    date: toolTipDate
  };

  const margin = {
    top: 36,
    left: 0,
    bottom: 0,
    right: 0
  };
  const axisLeftTickLabelProps = {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "12px",
    color: "#000"
  };
  const axisBottomTickLabelProps = {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    dy: "12px"
  };

  return (
    <div>
      <Box sx={{ ml: "20px", mt: "12px" }}>
        <LegendOrdinal
          scale={colorScale}
          direction="row"
          labelMargin="0 75px 0 0"
          shape="circle"
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            color: grey,
            display: "flex",
            textTransform: "capitalize",
            fontFamily: "Poppins"
          }}
          shapeMargin="0 8px 0 0"
        />
        <Box display="flex">
          <Typography className="total" flex="0 0 142px">
            Rs {roundTwoDecimalPoint(weeklyData?.sumSales) || 0}
          </Typography>
          <Typography className="total">
            Rs {roundTwoDecimalPoint(weeklyData?.sumReceipts) || 0}
          </Typography>
        </Box>
      </Box>
      <XYChart
        width={width}
        height={Math.min(328, height)}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
        theme={theme}
        margin={margin}
        horizontal="auto"
      >
        <Axis
          orientation="bottom"
          numTicks={6}
          strokeWidth={1}
          tickFormat={(d, i) => bottomTickFormat(d, i)}
          hideAxisLine
          top={height - (height / 4 + 45)}
          tickStroke="rgba(0, 0, 0, 0.5)"
          tickLabelProps={() => axisBottomTickLabelProps}
        />
        <Axis
          orientation="left"
          stroke="transparent"
          tickLabelProps={() => axisLeftTickLabelProps}
          tickFormat={(d) => leftTickFormat(d)}
          hideZero
          left={52}
          numTicks={4}
          hideTicks
        />
        <AreaSeries
          dataKey="sales"
          data={data}
          xAccessor={getDate}
          yAccessor={getSales}
          fillOpacity={0.2}
          curve={curveCardinal}
        />
        <AreaSeries
          dataKey="receipt"
          data={data}
          xAccessor={getDate}
          yAccessor={getReceipts}
          fillOpacity={0.2}
          curve={curveCardinal}
        />

        <ChartToolTip accessors={accessors} />
      </XYChart>
    </div>
  );
};
export default RevenueChart;

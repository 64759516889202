function requestFullScreen() {
  var el: any = document.body;

  var requestMethod =
    el.requestFullScreen ||
    el.webkitRequestFullScreen ||
    el.mozRequestFullScreen ||
    el.msRequestFullScreen;

  if (requestMethod) {
    requestMethod.call(el);
  } else if (typeof window.ActiveXObject !== 'undefined') {
    var wscript = new ActiveXObject('WScript.Shell');

    if (wscript !== null) {
      wscript.SendKeys('{F11}');
    }
  }
}

function exitFullScreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  }
}

export default function useFullScreen() {
  return [requestFullScreen, exitFullScreen];
}

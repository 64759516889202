
import { push } from 'connected-react-router';
import { actionCreatorAsync } from './actionHelpers';
import * as api from '../api/audience';

export enum Type {
  AUDIENCES_GET = 'AUDIENCES_GET',
  AUDIENCE_GET = 'AUDIENCE_GET',
  AUDIENCE_UPDATE = 'AUDIENCE_UPDATE',
  AUDIENCE_CREATE = 'AUDIENCE_CREATE',
  AUDIENCE_DELETE = 'AUDIENCE_DELETE',
  AUDIENCE_SIZE_GET = 'AUDIENCE_SIZE_GET'
}

export const getAudiences = () => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.AUDIENCES_GET, async () => {
        return api.getBatchAudience();
      })
    );
  };
};

export const getAudienceSizeByRules = (audienceData, next) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.AUDIENCE_SIZE_GET, async () => {
        return api.getAudienceSizeByRules(audienceData, next);
      })
    );
  };
};

export const getAudienceById = (id, next?) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.AUDIENCE_GET, async () => {
        return api.getAudienceDetails(id, next);
      })
    );
  };
};

export const saveAudience = (audienceData, stayOnPage = false) => {
  return async (dispatch) => {
    if (audienceData.id) {
      await dispatch(
        actionCreatorAsync(Type.AUDIENCE_UPDATE, async () => {
          const data = await api.updateAudience(audienceData.id, audienceData);

          return data;
        })
      );
    } else {
      await dispatch(
        actionCreatorAsync(Type.AUDIENCE_CREATE, async () => {
          const data = await api.createAudience(audienceData);
          if (data.id && !stayOnPage) dispatch(push(`campaign/audiences/${data.id}`));
          //   if (data.id && !stayOnPage) dispatch(push(`/audiences/${data.id}`));
          return data;
        })
      );
    }
  };
};

export const deleteAudience = (id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.AUDIENCE_DELETE, async () => {
        await api.deleteAudience(id);
        return id;
      })
    );
  };
};

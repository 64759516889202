import * as api from "../api/accessingResourceCentres";
import { actionCreatorAsync } from "./actionHelpers";

export enum Type {
  GET_ACCESSING_RCS = "GET_ACCESSING_RCS",
}

export const getAccessingResourceCentres = (ids) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_ACCESSING_RCS, async () => {
        return await api.getAccessingResourceCentres(ids);
      })
    );
  };
};

import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search } from "@mui/icons-material";
import ClientSearch from "../../../components/ClientSearch";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    height: "35px",
    padding: "0 10px",
    display: "flex",
    borderRadius: "5px",
    alignItems: "center",
    gap: "10px",
    overflow: "hidden",
    backgroundColor: "#ededed"
  },

  clientSearch: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "180px",
      marginRight: "15px"
    },
    "&::placeholder": {
      color: theme.palette.text.secondary
    },
    "&:focus": {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`
    }
  }
}));

interface OpdRequestClientSearchProps {
  selectedClient: any;
  setSelectedClient: React.Dispatch<React.SetStateAction<any>>;
}

const OpdRequestClientSearch = ({
  selectedClient,
  setSelectedClient
}: OpdRequestClientSearchProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.searchBox}>
      <Search />
      <ClientSearch
        client={selectedClient}
        setClient={setSelectedClient}
        margin="none"
        placeholder="Search client..."
        className={classes.clientSearch}
      />
    </Box>
  );
};

export default OpdRequestClientSearch;

export enum OPDFormMode {
  CREATE = "create",
  EDIT = "edit"
}

export enum OPDRequestStatus {
  ORDERED = "ordered",
  DELIVERED = "delivered",
  CANCELLED = "cancelled"
}

import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Edit } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import Panel from "../../../components/Panel";
import { EditableTableView } from "../../../components/EditableTable/EditableTable";
import IntraoralAssessmentContent from "../../Modals/Assessment/IntraoralAssessmentContent";
import EstimatePrint from "./EstimatePrint";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { isValidEstimateData } from "./helpers";
import { Estimate } from "./interfaces";

interface Props {
  data: Estimate;
  onClose: () => void;
  setEditMode: () => void;
}

function EstimateInfo({ data, onClose, setEditMode }: Props): JSX.Element {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const isValidData = isValidEstimateData(data.estimates);

  return (
    <Panel
      onClose={onClose}
      title="Estimate"
      printButton={
        <Button variant="contained" size="small" onClick={handlePrint}>
          Print
        </Button>
      }
      editButton={
        <IconButton size="small" onClick={setEditMode} data-testmation="estimatesEditButton">
          <Edit />
        </IconButton>
      }
    >
      <Box px={5} pt={1}>
        {isValidData.intraOral && (
          <IntraoralAssessmentContent intraOralDoc={data.estimates.intraOral} />
        )}
        {isValidData.tabular && <EditableTableView tableState={data.estimates.tabular} />}
        {data.validUntil && (
          <>
            <Typography gutterBottom fontWeight={600}>
              Valid until:
            </Typography>
            <Typography>{convertADtoBS(new Date(data.validUntil)).formatted4}</Typography>
          </>
        )}
        <EstimatePrint ref={componentRef} data={data} />
      </Box>
    </Panel>
  );
}

export default EstimateInfo;

import { useQuery, useMutation, UseMutationResult, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { PSecondaryLedger } from "./CoaTree";
import * as NotificationActions from "../../actions/notification";
import {
  getCoaAll,
  postAccGroup,
  postChildGl,
  patchAccGroup,
  patchChildGl
} from "../../api/accounts";
import {
  ChildGeneralLedger,
  ExtractInterface,
  HiddenLedgerCodes,
  PrimaryLedger,
  PrimaryLedgerNames,
  SubLedgerTypes
} from "../../interfaces/Accounts";
import { RootState } from "../../store";
import { setLastTouchedLedger } from "../../slices/journalEntry";
import { TrialBalanceInterface } from "../../interfaces/AccountReportsInterface";

export const useCoaAll = (): {
  data: PrimaryLedger[] | undefined;
  isLoading: boolean;
  error: Error | null;
  refetch: () => void;
} => {
  const isAccountSubscribed =
    useSelector(
      (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
    ) || false;
  const { data, isLoading, error, refetch } = useQuery<PrimaryLedger[], Error>(
    "coaAll",
    getCoaAll,
    {
      enabled: isAccountSubscribed
    }
  );
  return { data, isLoading, error, refetch };
};

export const usePostAccGroup = (): UseMutationResult<PSecondaryLedger, Error, PSecondaryLedger> => {
  const queryClient = useQueryClient();
  return useMutation<PSecondaryLedger, Error, PSecondaryLedger>(postAccGroup, {
    retry: 1,
    onSettled: () => {
      queryClient.invalidateQueries("coaAll");
    }
  });
};

export const usePatchAccGroup = (): UseMutationResult<
  PSecondaryLedger,
  Error,
  PSecondaryLedger
> => {
  const queryClient = useQueryClient();
  return useMutation<PSecondaryLedger, Error, PSecondaryLedger>(patchAccGroup, {
    retry: 1,
    onSettled: () => {
      queryClient.invalidateQueries("coaAll");
    }
  });
};

export const usePostChildGl = (): UseMutationResult<
  ChildGeneralLedger,
  Error,
  ChildGeneralLedger
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<ChildGeneralLedger, Error, ChildGeneralLedger>(postChildGl, {
    retry: 0,
    onSettled: () => {
      queryClient.invalidateQueries("coaAll");
    },
    onSuccess: (newlyCreatedLedger) => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: `Successfully Created Child GL`,
          autoTimeout: true
        })
      );
      dispatch(setLastTouchedLedger(newlyCreatedLedger));
    },
    onError: () => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: `Can't create a child ledger.`,
          autoTimeout: true
        })
      );
    }
  });
};

export const usePatchChildGl = (): UseMutationResult<
  ChildGeneralLedger,
  Error,
  ChildGeneralLedger
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<ChildGeneralLedger, Error, ChildGeneralLedger>(patchChildGl, {
    retry: 0,
    onSettled: () => {
      queryClient.invalidateQueries("coaAll");
    },
    onSuccess: () => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Successfully Updated Ledger.",
          autoTimeout: true
        })
      );
    },
    onError: () => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry! Something went wrong.",
          autoTimeout: true
        })
      );
    }
  });
};

export const extractSecondaryData = ({
  data = [],
  isLoading,
  error
}: {
  data: PrimaryLedger[] | undefined;
  isLoading: boolean;
  error: Error | null;
}): ExtractInterface => {
  const equityAndLiabilities = data.find(
    (primaryLedger) => primaryLedger.primaryLedgerName === PrimaryLedgerNames.EQUITY_AND_LIABILITIES
  );
  const assets = data.find(
    (primaryLedger) => primaryLedger.primaryLedgerName === PrimaryLedgerNames.ASSETS
  );
  const income = data.find(
    (primaryLedger) => primaryLedger.primaryLedgerName === PrimaryLedgerNames.INCOME
  );
  const expenses = data.find(
    (primaryLedger) => primaryLedger.primaryLedgerName === PrimaryLedgerNames.EXPENSES
  );
  return { equityAndLiabilities, assets, income, expenses, isLoading, error };
};

export const getAllChildGl = (
  data: PrimaryLedger | TrialBalanceInterface,
  showAllLedgers: boolean = false
): ChildGeneralLedger[] => {
  const childGl = [];
  data?.secondaryLedgers?.forEach((secondaryLedger) => {
    secondaryLedger.accountingGroups.forEach((group) => {
      group?.childGeneralLedgers?.forEach((ledger) => {
        if (showAllLedgers) {
          childGl.push(ledger);
          return;
        }
        if (!Object.values(HiddenLedgerCodes).includes(ledger.code as HiddenLedgerCodes)) {
          childGl.push(ledger);
        }
      });
    });
  });
  return childGl;
};

export const getAllChildGlWithLedgerTypeFilter = (filters?: string[]): ChildGeneralLedger[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { equityAndLiabilities, assets, income, expenses } = extractSecondaryData(useCoaAll());

  let allChildGl = [
    ...getAllChildGl(equityAndLiabilities),
    ...getAllChildGl(assets),
    ...getAllChildGl(income),
    ...getAllChildGl(expenses)
  ];
  if (filters?.length) {
    allChildGl = allChildGl.filter((childGl) => filters.includes(childGl.ledgerType));
  }
  return allChildGl;
};

export const findByCode = (searchCode: string): ChildGeneralLedger | null => {
  const allChildLedgers = getAllChildGlWithLedgerTypeFilter();
  return allChildLedgers.find(({ code }) => searchCode === code) || null;
};

export const useGetAllChildLedgers = (): ChildGeneralLedger[] => {
  const { equityAndLiabilities, assets, income, expenses } = extractSecondaryData(useCoaAll());

  const allChildGl = [
    ...getAllChildGl(equityAndLiabilities, true),
    ...getAllChildGl(assets, true),
    ...getAllChildGl(income, true),
    ...getAllChildGl(expenses, true)
  ];
  return allChildGl;
};
export const getVendorsGl = (): ChildGeneralLedger[] => {
  const selectedGl = [];
  const allChildGl = getAllChildGlWithLedgerTypeFilter();
  allChildGl.forEach((ledger) => {
    if (Object.values(SubLedgerTypes).includes(ledger.subLedgerType as SubLedgerTypes)) {
      selectedGl.push(ledger);
    }
  });
  return selectedGl;
};

export const ledgerFindBySubLedgerType = (subLedgerType: string): ChildGeneralLedger | null => {
  const allLedgers = getAllChildGlWithLedgerTypeFilter();
  return allLedgers.find((ledger) => ledger.subLedgerType === subLedgerType) || null;
};

import * as React from 'react';
import { WeekEventsCalendar } from '../../components/Calendar/';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import AddSlot from './AddSlot';
import { t } from '../../components/translate';
import times from 'lodash/times';
import * as moment from 'moment';
import * as calendarData from '../../components/Calendar/functions/calendarData';
import * as calendarFns from '../../components/Calendar/functions/calendarFunctions';
import { fetchSlotsWithBookings } from '../../actions/slot';
import EventPill from './EventPill';
import { navigateAddModal } from '../../actions/navigation';
import LinearProgress from '@mui/material/LinearProgress';
import { serviceProviderActions as actions } from '../../actions';
import queryString from 'query-string';
import CalendarHeader from './CalendarHeader';
import styles from './styles.module.css';

interface PropsType {
  slots: Array<any>;
  serviceProviders: Array<any>;
  resourceCentreId: number;
  fetchSlots: (
    from: moment.Moment,
    until: moment.Moment,
    serviceProviderId: number,
    resourceCentreId: number
  ) => void;
  openSlotBookModal: (spId, slotId, next) => void;
  navigateTo: (route: string) => void;
  loadServiceProviders: () => void;
  user: any;
}

interface StateType {
  selectedDate: moment.Moment;
  days: Array<moment.Moment>;
  currentTime: moment.Moment;
  mode: 'day' | 'week' | 'month';
  serviceProviderId: number;
  loading: boolean;
}
class Schedule extends React.Component<PropsType, StateType> {
  fetchSlotsTimeout: any;
  constructor(props) {
    super(props);

    const { serviceProviders } = props;
    const params = queryString.parse(props.location.search);
    const spId = Number(sessionStorage.getItem('spID'));
    this.state = {
      days: this.getDays(),
      currentTime: moment(),
      selectedDate: moment(params.date),
      serviceProviderId:
        // if the current user serviceProvider choose that, otherwise choose one from sessionStorage
        // or then the first one in the serviceProviders list
        props.user.role === 'serviceProvider' && !spId
          ? props.user.id
          : Number(params.serviceProviderId) ||
            spId ||
            (serviceProviders && serviceProviders.length
              ? serviceProviders[0].id
              : null),
      mode: 'week',
      loading: false,
    };
    this.fetchSlotsTimeout = null;
  }

  componentDidMount() {
    this.props.loadServiceProviders();
    this.fetchSlots(this.state.selectedDate, this.state.mode, 0);
    sessionStorage.setItem(
      'spID',
      this.state.serviceProviderId == null
        ? '1'
        : this.state.serviceProviderId.toString()
    );
  }

  componentWillUnmount() {
    clearTimeout(this.fetchSlotsTimeout);
  }

  fetchSlots(
    selectedDate = this.state.selectedDate,
    mode = this.state.mode,
    timeout = 600
  ) {
    const { serviceProviderId } = this.state;
    const { resourceCentreId } = this.props;
    if (!serviceProviderId || !resourceCentreId) return;
    clearTimeout(this.fetchSlotsTimeout);
    this.setState({ loading: true });
    this.fetchSlotsTimeout = setTimeout(() => {
      this.props.fetchSlots(
        moment(selectedDate).startOf(mode),
        moment(selectedDate).endOf(mode),
        serviceProviderId,
        resourceCentreId
      );
      this.setState({ loading: false });
    }, timeout);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.search &&
      this.props.location.search != prevProps.location.search
    ) {
      const paramsData = queryString.parse(this.props.location.search);
      this.setState(
        {
          serviceProviderId: Number(paramsData.serviceProviderId),
          selectedDate: moment(paramsData.date),
        },
        () => this.fetchSlots()
      );
      sessionStorage.setItem('spID', Number(paramsData.serviceProviderId));
    }
  }

  getMonthYearForTheWeek = () => {
    const day1 = moment(this.state.selectedDate).startOf('week');
    const day7 = moment(this.state.selectedDate).endOf('week');
    const day1BS = calendarFns.convertADtoBS(day1);
    const day7BS = calendarFns.convertADtoBS(day7);
    const endMonth = ` - ${calendarData.bsMonths(t)[day7BS.bsMonth - 1]} ${
      day7BS.bsYear
    }`;
    return `${calendarData.bsMonths(t)[day1BS.bsMonth - 1]} ${day1BS.bsYear}${
      day1BS.bsMonth !== day7BS.bsMonth ? endMonth : ''
    }`;
  };

  focusToday = () => {
    const today = moment();
    this.fetchSlots(today, this.state.mode);
    this.setState({
      selectedDate: today,
      days: this.getDays(),
    });
  };

  getDays(date = moment()) {
    const startDay = moment(date).startOf('week');
    const days = [];
    times(7, (i) => {
      days.push(moment(startDay).add(i, 'day'));
    });
    return days;
  }

  goBefore = (count, mode) => {
    const selectedDate = moment(this.state.selectedDate).subtract(count, mode);
    this.fetchSlots(moment(selectedDate), mode);
    this.setState({
      selectedDate,
      days: this.getDays(selectedDate),
    });
  };

  goAfter = (count, mode) => {
    const selectedDate = moment(this.state.selectedDate).add(count, mode);
    this.fetchSlots(moment(selectedDate), mode);
    this.setState({
      selectedDate,
      days: this.getDays(selectedDate),
    });
  };

  render() {
    const { serviceProviderId, selectedDate } = this.state;
    const { user, resourceCentreId } = this.props;
    if (!this.props.serviceProviders) return null;
    return (
      <div className={styles.weekcalendarContainer}>
        {this.state.loading && (
          <LinearProgress className={styles.okhatiLoader} color="secondary" />
        )}
        <CalendarHeader
          user={user}
          serviceProviderId={serviceProviderId}
          resourceCentreId={resourceCentreId}
          selectedDate={selectedDate}
          getMonthYearForTheWeek={this.getMonthYearForTheWeek}
          getFullDate={() => {
            const dateBS = calendarFns.convertADtoBS(selectedDate);
            return `${
              calendarData.bsDaysFull(t)[moment(selectedDate).day()]
            }, ${dateBS.formatted2}`;
          }}
          focusToday={this.focusToday}
          onServiceProviderSelect={(serviceProviderId) => {
            sessionStorage.setItem('spID', serviceProviderId);
            this.setState({ serviceProviderId }, () => {
              this.fetchSlots();
            });
          }}
          navigateTo={this.props.navigateTo}
          goBefore={this.goBefore}
          goAfter={this.goAfter}
        />
        <WeekEventsCalendar
          selectedDate={this.state.selectedDate}
          events={this.props.slots}
          renderEventContent={(event, style) => {
            return (
              <EventPill
                style={style}
                event={event}
                onSlotClicked={(e) => {
                  this.props.openSlotBookModal(
                    this.state.serviceProviderId,
                    event.id,
                    () => {
                      this.fetchSlots();
                    }
                  );
                }}
              />
            );
          }}
        />
        {this.props.openAddSlotModal && (
          <AddSlot
            serviceProviderId={this.state.serviceProviderId}
            handleClose={() => this.props.navigateTo('/schedule')}
            next={() =>
              this.fetchSlots(this.state.selectedDate, this.state.mode, 0)
            }
          />
        )}
      </div>
    );
  }
}

export default connect(
  ({ userContext, resources, slots }) => ({
    slots,
    resourceCentreId: userContext.resourceCentreId,
    serviceProviders: resources.resourceCentreServiceProviders,
    user: { ...userContext.user, role: userContext.mode },
  }),
  (dispatch) => ({
    loadServiceProviders: (id) =>
      dispatch((dispatch, getState) => {
        dispatch(
          actions.getResourceCentreServiceProviders({
            resourceCentreId: getState().userContext.resourceCentreId,
          })
        );
      }),
    fetchSlots: (from, until, serviceProviderId, resourceCentreId) => {
      dispatch(
        fetchSlotsWithBookings(from, until, serviceProviderId, resourceCentreId)
      );
    },
    navigateTo: (url) => {
      dispatch(push(url));
    },
    openSlotBookModal: (spId, slotId, next) => {
      dispatch(
        navigateAddModal({
          component: 'SlotBookModal',
          props: {
            serviceProviderId: spId,
            slotId: slotId,
            next: next,
          },
        })
      );
    },
  })
)(Schedule);

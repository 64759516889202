import React from "react";
import { connect } from "react-redux";
import produce from "immer";
import { Box, Typography } from "@mui/material";
import styles from "./Assessment.module.css";
import { getBatchLabTests } from "../../actions/assessments";
import AddLabtests from "../Lab/LabTestCreate/AddLabtests";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface LabTestInterface {
  getLabTests: () => void;
  labTests: [];
  assessmentLabTests: [];
  onChange: (value) => void;
}

const LabTest: React.FC<LabTestInterface> = (props) => {
  const { getLabTests, labTests, assessmentLabTests, onChange } = props;
  const assessmentLabels = useAssessmentLabel();
  React.useEffect(() => {
    getLabTests();
  }, []);

  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.labTest}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <AddLabtests
          isMedicalTest={false}
          labTests={labTests}
          handleModifyLabtests={(v: any[]) => {
            onChange(v);
          }}
          handleRemoveLabtest={(i) => {
            const mutatedTests = produce(assessmentLabTests, (draft) => {
              draft.splice(i, 1);
            });
            onChange(mutatedTests);
          }}
          tests={assessmentLabTests}
          allowRemovingTest
        />
      </Box>
    </Box>
  );
};

export default connect(
  (state) => ({ labTests: state.assessments.labTests }),
  (dispatch) => ({ getLabTests: () => dispatch(getBatchLabTests()) })
)(LabTest);

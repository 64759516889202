import { Typography, Box, Link } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { notificationAdd } from "../../../actions/notification";
import { getReferrerSalesTransactions } from "../../../api/referrers";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import List from "../../../components/List";
import { EmptyView } from "../../../components/OList";
import { commonErrorMessage } from "../../../helpers/messages";
import { BillType } from "../../../interfaces/BillInterfaces";
import BillShow from "../../Billing/BillShow/BillShow";
import BillStatusChip from "../../Billing/common/BillStatusChip";
import { t } from "../../../components/translate";
import "./styles.scss";

const getProductName = (billItems): string =>
  billItems.length > 1 ? `${billItems[0].description}...` : billItems[0].description;

export default function Transactions({ referrerId }: { referrerId }): JSX.Element {
  const [data, setData] = React.useState<Partial<BillType>[]>([]);
  const [selectedBillId, setSelectedBillId] = React.useState<number | null>(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const getReferrerTransactions = async () => {
      try {
        const transactions = await getReferrerSalesTransactions(referrerId);
        setData(transactions);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    };
    if (referrerId) getReferrerTransactions();
  }, [referrerId, dispatch]);

  return (
    <Box className="referrerTransactionList">
      <List
        rowHeight={40}
        automation="clientLabs"
        data={data}
        hideCreateButton
        withoutSearch
        columns={[
          {
            key: "document",
            label: `${t("prodName")}`,
            sortable: true,
            formatter: ({ document }) => (
              <Typography>{getProductName(document.billItems)}</Typography>
            )
          },

          {
            key: "issueDate",
            label: `${t("date")}`,
            sortable: true,
            formatter: ({ issueDate }) => (
              <Typography>{convertADtoBS(new Date(issueDate))?.formatted4}</Typography>
            )
          },
          {
            key: "billNumber",
            label: `${t("billNumber")}`,
            sortable: true,
            formatter: ({ billNumber, id }) => (
              <Link component="button" onClick={() => setSelectedBillId(id as number)}>
                {billNumber}
              </Link>
            )
          },
          {
            key: "totalAmount",
            label: `${t("totalAmount")}`,
            sortable: true,
            formatter: ({ totalAmount }) => (
              <Typography>Rs. {(totalAmount || 0).toFixed(2)}</Typography>
            )
          },
          {
            key: "status",
            label: `${t("status")}`,
            sortable: true,
            formatter: ({ status }) => <BillStatusChip bill={{ status }} />
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No transactions for the referrer yet...
          </Box>
        </EmptyView>
      </List>
      {selectedBillId && (
        <BillShow
          hideActions={false}
          billId={selectedBillId}
          handleViewClose={() => setSelectedBillId(null)}
          hideBillingActions
        />
      )}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import { DOMParser as PDOMParser } from "prosemirror-model";
import React from "react";
import { EditorState } from "prosemirror-state";
import {
  customSchema,
  deserializeNode,
  proseMirrorOptions,
  RichTextfield
} from "../../../../components/RichTextfield";
import useProseMirror from "../../../../components/RichTextfield/useProsemirror";
import Modal from "../../../../components/Modal/Modal";

function useParsedProseMirror(
  document,
  proseMirrorOptionsParams,
  schema = customSchema
): [EditorState<any>, React.Dispatch<React.SetStateAction<EditorState<any>>>] {
  const parser = new DOMParser();
  const doc = PDOMParser.fromSchema(schema).parse(
    parser.parseFromString(`${document}`, "text/html").documentElement
  );
  const [state, setState] = useProseMirror({
    ...proseMirrorOptionsParams,
    doc
  });
  return [state, setState];
}

interface Props {
  additionaldata: string;
  onSave: (v: string) => void;
  label?: string;
  isDialog?: boolean;
}

const AdditionalDataEdit = ({
  additionaldata,
  onSave,
  label,
  isDialog = true
}: Props): JSX.Element => {
  const [state, setState] = useParsedProseMirror(additionaldata || "", proseMirrorOptions);

  const [openModal, setOpenModal] = React.useState(false);

  const additionalDataView = (
    <Box
      style={{ whiteSpace: "pre", width: "100%" }}
      onBlur={() => {
        if (!isDialog) {
          onSave(deserializeNode(state.doc));
        }
      }}
    >
      <Typography gutterBottom>Additional Test Data</Typography>
      <RichTextfield
        state={state}
        setState={setState}
        editorFontSize={12}
        showFontSizeToggleButtons
      />
    </Box>
  );

  if (isDialog) {
    return (
      <Box style={{ width: "100px" }}>
        <Typography
          onClick={() => setOpenModal(true)}
          style={{ padding: "4px 8px", width: "240px" }}
          role="button"
        >
          {label}
        </Typography>
        <Modal
          title="Additional Test Data"
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={() => {
            onSave(deserializeNode(state.doc));
            setOpenModal(false);
          }}
        >
          {additionalDataView}
        </Modal>
      </Box>
    );
  }
  return additionalDataView;
};

AdditionalDataEdit.defaultProps = {
  label: "More Details",
  isDialog: true
};

export default AdditionalDataEdit;

import * as moment from "moment";
import { getPrismicDocuments, getPrismicRef, prismicServiceStatusRootUrl } from "./prismicHelper";

const isDevOrCypressEnvironment =
  process.env.REACT_APP_ENV === "development" || !process.env.REACT_APP_ENV || window.Cypress;

const isCypressEnvironment = !process.env.REACT_APP_ENV || window.Cypress;

export type ReleaseNote = {
  id: string;
  // eslint-disable-next-line camelcase
  data: { released_on: string; body: unknown; version: string };
};

export async function getMaintenanceStatus(): Promise<Array<unknown>> {
  if (isCypressEnvironment) {
    return [];
  }

  const ref = await getPrismicRef(prismicServiceStatusRootUrl);

  return getPrismicDocuments(
    prismicServiceStatusRootUrl,
    '/documents/search?q=[[at(document.type,"okhati_suite_service_status")]]',
    ref
  );
}

/**
 * Returns only the latest 5 release notes
 * that are published in last 7 days
 */
export async function getReleaseNotes(): Promise<Array<ReleaseNote>> {
  if (isDevOrCypressEnvironment) {
    return [];
  }

  const ref = await getPrismicRef(prismicServiceStatusRootUrl);
  const lastWeek = moment().subtract(8, "days").toISOString().substring(0, 10);
  return getPrismicDocuments(
    prismicServiceStatusRootUrl,
    `/documents/search?q=%5B%5Bat(document.type,%22release_notes%22)%5D%5Bdate.after(my.release_notes.released_on,%20%22${lastWeek}%22)%5D%5D&orderings=%5Bmy.release_notes.released_on%20desc%5D&pageSize=10`,
    ref
  );
}

export async function getLatestReleaseNotes(): Promise<Array<ReleaseNote>> {
  if (isCypressEnvironment) {
    return [];
  }
  const ref = await getPrismicRef(prismicServiceStatusRootUrl);
  return getPrismicDocuments(
    prismicServiceStatusRootUrl,
    `/documents/search?q=%5B%5Bat(document.type,%22release_notes%22)%5D%5D&orderings=%5Bmy.release_notes.released_on%20desc%5D&pageSize=10`,
    ref
  );
}

import React from "react";

export const Ampoul = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 511.992 511.992"
    >
      <g>
        <g>
          <path
            d="M292.59,198.128c7.359-13.415,13.204-38.204,6.078-83.4C291.246,67.727,287.996,36.883,287.996,32
			c0-17.68-14.328-32-32-32s-32,14.32-32,32c0,4.883-3.25,35.728-10.672,82.728c-7.125,45.196-1.281,69.985,6.078,83.4
			l-27.407,36.539v0.508v27.97v227.534c0,11.782,9.547,21.313,21.329,21.313h85.344c11.782,0,21.329-9.531,21.329-21.313V302.285
			v-22.281v-45.337L292.59,198.128z M234.402,118.048c6.75-42.797,10.922-77.994,10.922-86.048c0-5.883,4.797-10.672,10.672-10.672
			c5.875,0,10.672,4.789,10.672,10.672c0,8.055,4.172,43.251,10.922,86.048c5.469,34.665,2.391,53.946-1.156,64.017
			c-1.859,5.281-3.953,8.313-5.406,9.938h-30.062c-1.453-1.625-3.547-4.657-5.407-9.938
			C232.011,171.994,228.933,152.713,234.402,118.048z M213.323,252.863v-11.087l21.344-28.445h0.016h42.625h0.016l21.344,28.445
			v42.837c-2.688,0.25-5.36,0.391-8.016,0.391C263.48,285.004,238.324,272.145,213.323,252.863z M298.669,490.679h-0.001h-85.344
			v-211.44c22.141,15.016,48.172,27.094,77.329,27.094c2.672,0,5.344-0.109,8.016-0.312V490.679z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import * as calFns from "../../../../components/Calendar/functions/calendarFunctions";
import List from "../../../../components/List";
import { EmptyView, ListActions, Menu, MenuItem } from "../../../../components/OList";
import useMobileScreen from "../../../../hooks/useMobileScreen";
import {
  FiltersInterface,
  GeneralLedgerInterface
} from "../../../../interfaces/AccountReportsInterface";
import Filters from "../Filters";
import "../styles.scss";
import * as NotificationActions from "../../../../actions/notification";
import ReportDetailView from "../ReportDetailView";
import { getGeneralLedgerReport } from "../../../../api/accountReports";
import { useCurrentActiveFiscalPeriod } from "../../../../hooks/accounts";
import { downloadExcel } from "../../../../helpers/files";
import { tl } from "../../../../components/translate";
import { formatDataForExcel, formatHeaderForExcel } from "../helper";

const calculateRunningBalance = (balance, debit, credit) => balance + debit - credit;

const docColumns = () => [
  "Date",
  "Destination Ledger Name",
  "Account Ledger",
  "Voucher Number",
  "Subledger",
  "Debit",
  "Credit"
];

const docRowProcessor = ({
  date,
  destinationLedgerName,
  sourceLedgerName,
  voucherNumber,
  subLedgerName,
  debit,
  credit,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    calFns.bsFullDate(date) || "",
    destinationLedgerName || "",
    sourceLedgerName || "",
    voucherNumber || "",
    subLedgerName || "",
    debit || "",
    credit || ""
  ];
};

const formatData = (data) => {
  let preVoucherNumber = "";
  let balance = 0;
  return data.map((item, index) => {
    if (preVoucherNumber === item.destinationLedgerCode) {
      balance = calculateRunningBalance(balance, item.debit, item.credit);
    } else {
      preVoucherNumber = item.destinationLedgerCode;
      balance = 0;
      balance = calculateRunningBalance(balance, item.debit, item.credit);
    }
    return {
      ...item,
      runningBalance: balance,
      ...(item.openingBalance && { debit: null, credit: null }),
      id: index
    };
  });
};

export default function GeneralLedger(): JSX.Element {
  const dispatch = useDispatch();
  const query = queryString.parse(window.location.search);
  const activeFiscalPeriod = useCurrentActiveFiscalPeriod();

  const fromDate =
    activeFiscalPeriod?.from && query?.date
      ? calFns.startOfDay(activeFiscalPeriod.from).toDate()
      : calFns.startOfDay(new Date()).toDate();
  const untilDate = query.date ? new Date(query.date) : new Date();
  const [filters, setFilters] = React.useState<FiltersInterface>({
    from: new Date(fromDate),
    until: untilDate,
    ledgerIds: query.ledgerId ? [Number(query.ledgerId)] : []
  });

  const isMobileScreen = useMobileScreen();

  const [data, setData] = React.useState<GeneralLedgerInterface[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getGeneralLedgerReport(filters);
        setData(response);
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error?.data || "Sorry! Something went wrong.",
            autoTimeout: true
          })
        );
      }
    })();
  }, [filters, dispatch]);

  const [selectedRow, setSelectedRow] = React.useState(null);
  return (
    <Box overflow="auto hidden">
      <Box px={4} width={isMobileScreen ? "960px" : "auto"}>
        <Filters
          filters={filters}
          setFilters={(newFilters) => setFilters({ ...filters, ...newFilters })}
          isForAccountUser
        />
      </Box>
      <Box className="reportListStyle" width={isMobileScreen ? "960px" : "auto"}>
        <List
          automation="labReportList"
          data={formatData(data)}
          rowHeight={50}
          defaultSortColumn="destinationLedgerName"
          defaultSortOrder={-1}
          adjustHeightToContents
          hideCreateButton
          withoutSearch
          columns={[
            {
              key: "destinationLedgerName",
              label: "Destination Ledger Name",
              sortable: true,
              segmentable: true,
              segmentBy: (row) => row.destinationLedgerName,
              sortFunction: (a, b) => (a.created_at > b.created_at ? 1 : -1),
              formatter: () => <Typography />
            },
            {
              key: "sourceLedgerName",
              label: "Account Ledger",
              sortable: true,
              formatter: (row) => (
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginRight: "5px"
                  }}
                >
                  {row.sourceLedgerName}
                </Typography>
              )
            },
            {
              key: "date",
              label: "Date",
              sortable: true,
              sortFunction: (a, b) => (a.created_at > b.created_at ? 1 : -1),
              formatter: ({ date }) => <Typography>{calFns.bsFullDate(date)}</Typography>
            },
            {
              key: "voucherCode",
              label: "Voucher Number",
              sortable: true,
              formatter: ({ voucherNumber }) => <Typography>{voucherNumber}</Typography>
            },
            {
              key: "subLedger",
              label: "Sub Ledger",
              sortable: true,
              formatter: ({ subLedger }) => <Typography>{subLedger}</Typography>
            },
            {
              key: "debit",
              label: "Debit",
              formatter: ({ debit }) => <Typography>{debit}</Typography>
            },
            {
              key: "credit",
              label: "Credit",
              formatter: ({ credit }) => <Typography>{credit}</Typography>
            },
            {
              key: "runningBalance",
              label: "Running Balance",
              formatter: ({ runningBalance }) => (
                <Typography>{runningBalance.toFixed(2)}</Typography>
              )
            },
            {
              key: "description",
              label: "Description",
              formatter: ({ description }) => <Typography>{description}</Typography>
            }
          ]}
          activeRow={selectedRow && selectedRow.id}
          onRowClick={(item) => {
            if (!selectedRow) {
              setSelectedRow(item);
            } else {
              setSelectedRow(null);
            }
          }}
          segementSummaryRenderer={(acc) => (
            <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
              <Box display="flex" flexGrow={1}>
                <Typography
                  component="span"
                  flexGrow={1}
                  display="flex"
                  padding="8px 32px 4px 20px"
                  fontWeight={500}
                >
                  {acc.segment || ""}
                </Typography>
              </Box>
            </Box>
          )}
        >
          <EmptyView>
            <Box textAlign="center" padding="50px">
              No items to show.
            </Box>
          </EmptyView>
          <ListActions>
            {({ getProcessedData }) => (
              <Menu>
                <MenuItem
                  onClick={async () => {
                    try {
                      await downloadExcel(
                        "GeneralLedgerReport",
                        formatHeaderForExcel(docColumns()),
                        formatDataForExcel(getProcessedData(), docRowProcessor)
                      );
                    } catch (e) {
                      dispatch(
                        NotificationActions.notificationAdd({
                          id: new Date().getUTCMilliseconds(),
                          variant: "error",
                          message: "Failed to download Excel.",
                          autoTimeout: true
                        })
                      );
                    }
                  }}
                >
                  {tl("reports.excel")}
                </MenuItem>
              </Menu>
            )}
          </ListActions>
        </List>
      </Box>
      {selectedRow && (
        <ReportDetailView
          generalLedgerReport
          handleViewClose={() => setSelectedRow(null)}
          data={selectedRow}
        />
      )}
    </Box>
  );
}

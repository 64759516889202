import React from "react";
import { makeStyles } from "@mui/styles";
import { StatusBackgroundMap } from "../../Dashboard/FlowItem";
import { BookingStatus } from "../../Bookings/ManageBooking";

const useStyles = makeStyles({
  indicator: (props: { bgColor: string; border: string }) => ({
    background: props.bgColor,
    borderRadius: "50%",
    width: "8px",
    height: "8px",
    marginRight: "4px",
    display: "inline-block",
    border: props.border
  })
});

const StatusIndicator = ({ status }: { status: string }): React.ReactElement => {
  const isReservedStatus = status === BookingStatus.reserved;
  const styleProps = {
    bgColor: StatusBackgroundMap[status],
    ...(isReservedStatus && { border: "1px solid black" })
  };
  const classes = useStyles(styleProps);

  return <div className={classes.indicator} />;
};
export default StatusIndicator;

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import startCase from "lodash/startCase";
import { fetchBeds, fetchWards } from "../../../slices/bedManagementSlice";
import { RootState } from "../../../store";
import CreateWard from "./CreateWard";
import { resourceCentreActions } from "../../../actions";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import HtmlTooltip from "../../../components/HtmlTooltip";

export enum MEDICINE_COMPONENT {
  /**
   * Product Medication Component.
   * Shows products available in stocks
   */
  PRODUCT_MEDICATION = "productMedication",
  /**
   * Medication Component.
   * Shows generic medication from master data
   */
  MEDICATION = "medication"
}

const IpdSettings = ({ resourceCentre }: { resourceCentre: ResourceCentre }): JSX.Element => {
  const dispatch = useDispatch();
  const wards = useSelector((state: RootState) => state.bedManagement?.wards);
  const beds = useSelector((state: RootState) => state.bedManagement?.beds) || [];

  const [startIpdFrom, setStartIpdFrom] = React.useState(0);
  React.useEffect(() => {
    setStartIpdFrom(resourceCentre.settings.ipdSettings?.startIpdNumberFrom || 0);
  }, [resourceCentre]);
  React.useEffect(() => {
    dispatch(fetchWards());
    dispatch(fetchBeds());
  }, [dispatch]);

  return (
    <Box px={2} display="flex" flexDirection="column" gap={2}>
      <FormControlLabel
        label="Include letterhead on print"
        control={
          <Checkbox
            checked={Boolean(
              resourceCentre.settings.printSettings.ipdPrintSettings.includeLetterhead
            )}
            onChange={(_, checked) => {
              const newRc = produce(resourceCentre, (draft) => {
                draft.settings.printSettings.ipdPrintSettings.includeLetterhead = checked;
              });
              dispatch(resourceCentreActions.putResourceCentre(newRc));
            }}
          />
        }
      />
      <FormControl sx={{ mb: 1 }}>
        <FormLabel>
          <Typography>Medication Component</Typography>{" "}
        </FormLabel>
        <RadioGroup
          row
          value={resourceCentre.settings.ipdSettings.medicationComponent}
          onChange={(event) => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.ipdSettings.medicationComponent = event.target
                .value as MEDICINE_COMPONENT;
            });
            dispatch(resourceCentreActions.putResourceCentre(newRc));
          }}
        >
          <FormControlLabel
            value={MEDICINE_COMPONENT.MEDICATION}
            control={<Radio />}
            label={
              <Box display="flex" gap={1} alignItems="center">
                <span>{startCase(MEDICINE_COMPONENT.MEDICATION)}</span>
                <HtmlTooltip title="" description="Shows generic medicines." />
              </Box>
            }
          />
          <FormControlLabel
            value={MEDICINE_COMPONENT.PRODUCT_MEDICATION}
            control={<Radio />}
            label={
              <Box display="flex" gap={1} alignItems="center">
                <span>{startCase(MEDICINE_COMPONENT.PRODUCT_MEDICATION)}</span>
                <HtmlTooltip
                  title=""
                  description="Shows products from stock, it will also create bill request form when prescribed."
                />
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>
      <Box display="flex" gap={2}>
        <TextField
          label="Start Ipd Number From"
          InputLabelProps={{ shrink: true }}
          value={startIpdFrom}
          variant="outlined"
          onChange={(e) => setStartIpdFrom(Number(e.target.value))}
        />
        <Button
          variant="contained"
          onClick={() => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.ipdSettings.startIpdNumberFrom = startIpdFrom;
            });
            dispatch(resourceCentreActions.putResourceCentre(newRc));
          }}
        >
          Update
        </Button>
      </Box>
      <CreateWard wardList={wards} bedList={beds} />
    </Box>
  );
};

export default IpdSettings;

import { ReducerBuilder } from "./ReducerBuilder";
import * as AssessmentActions from "../actions/assessments";

const INITIAL_STATE = {
  collection: { results: [], total: null },
  clientSpecific: [],
  symptoms: [],
  labTests: [],
  lastTouched: null,
  template: {}
};

function setAssessments(state, payload) {
  return { ...state, collection: payload };
}

function setClientAssessments(state, payload) {
  return { ...state, clientSpecific: payload };
}

function setAssessment(state, payload) {
  if (!payload) return state;
  const list = state.clientSpecific.filter(({ id }) => payload.id !== id);
  list.push(payload);
  return { ...state, clientSpecific: list };
}

function putAssessment(state, payload) {
  const newState = setAssessment(state, payload);
  newState.lastTouched = payload;
  return newState;
}

function getSymptoms(state, payload) {
  return { ...state, symptoms: payload };
}

function getLabTests(state, payload) {
  return { ...state, labTests: payload };
}

function setTemplateAsessment(state, payload) {
  return { ...state, template: payload };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(AssessmentActions.Type.GET_ASSESSMENTS, setAssessments)
  .mapAction(AssessmentActions.Type.GET_ASSESSMENTS_BY_CLIENT, setClientAssessments)
  .mapAction(AssessmentActions.Type.GET_ASSESSMENT_BY_ID, setAssessment)
  .mapAction(AssessmentActions.Type.CREATE_ASSESSMENT, putAssessment)
  .mapAction(AssessmentActions.Type.UPDATE_ASSESSMENT, putAssessment)
  .mapAction(AssessmentActions.Type.GET_SYMPTOMS, getSymptoms)
  .mapAction(AssessmentActions.Type.GET_ASSESSMENT_BY_UUID, setAssessment)
  .mapAction(AssessmentActions.Type.GET_LABTEST, getLabTests)
  .mapAction(AssessmentActions.Type.CREATE_TEMPLATE_ASSESSMENT, setTemplateAsessment)
  .mapAction(AssessmentActions.Type.GET_TEMPLATE_ASSESSMENT, setTemplateAsessment)
  .build();
export default reducer;

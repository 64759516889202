import React from "react";
import { keys } from "lodash";
import { Dialog, DialogContent, DialogTitle, Box, IconButton } from "@mui/material";
import Portal from "@mui/material/Portal";
import CloseIcon from "@mui/icons-material/Close";
import { getReleaseNotes, ReleaseNote } from "../../../api/prismicQuery";
import localStorageObject from "../../../helpers/localStorage";
import ReleaseNoteContent from "./ReleaseNoteContent";

const releaseNoteKey = "release_notes";

function ReleaseNoteModal(): JSX.Element {
  const [newReleaseNotes, setNewReleaseNotes] = React.useState<null | ReleaseNote[]>(null);

  function getSeenReleaseNotesId() {
    return JSON.parse(localStorageObject.getItem(releaseNoteKey)) || {};
  }

  const initializeReleaseNotes = React.useCallback(async () => {
    try {
      const latestReleaseNotes = await getReleaseNotes();

      if (latestReleaseNotes.length === 0) {
        return;
      }

      const seenReleasedNotes = getSeenReleaseNotesId();
      const unseenReleaseNotes = latestReleaseNotes.filter(
        (latestReleaseNote) => !keys(seenReleasedNotes).includes(latestReleaseNote.id)
      );

      if (unseenReleaseNotes.length === 0) {
        return;
      }
      setNewReleaseNotes(unseenReleaseNotes);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error fetching release notes:", error);
    }
  }, []);

  const handleCloseReleaseNote = () => {
    let lastSeenReleaseNotes = getSeenReleaseNotesId();

    // to reset release note in local storage
    if (keys(lastSeenReleaseNotes).length > 14) {
      lastSeenReleaseNotes = {};
    }

    const allSeenReleaseNotes = newReleaseNotes.reduce(
      (seenFlags, releaseNote) => ({ ...seenFlags, [releaseNote.id]: true }),
      lastSeenReleaseNotes
    );

    localStorageObject.setItem(
      releaseNoteKey,
      JSON.stringify(allSeenReleaseNotes as Record<string, boolean>)
    );
    setNewReleaseNotes(null);
  };

  React.useEffect(() => {
    initializeReleaseNotes();
  }, [initializeReleaseNotes]);

  const showModal = Boolean(newReleaseNotes?.length);

  if (!showModal) {
    return null;
  }

  return (
    <Portal>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showModal}
        aria-labelledby="release-note-modal"
        aria-describedby="release note"
      >
        <DialogTitle
          id="release-note-dialog-title"
          sx={{
            background: "#009654",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Box ml={1} color="#fff" fontSize={20} fontWeight={700} component="span">
            What&apos;s New
          </Box>
          <IconButton
            aria-label="close release note"
            component="button"
            onClick={handleCloseReleaseNote}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ReleaseNoteContent releaseNotes={[newReleaseNotes[0]]} />
        </DialogContent>
      </Dialog>
    </Portal>
  );
}

export default ReleaseNoteModal;

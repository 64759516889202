import { useCallback, useEffect, useState } from "react";
import localStorage from "../helpers/localStorage";

export const SHOW_DOB_ON_LAB_PRINT: string = "showDobOnLabPrint";

const useHandleShowDobOnLabPrint = (): { showDob: boolean; toggleShowDob: () => void } => {
  const [showDob, setShowDob] = useState<boolean>(
    JSON.parse(localStorage.getItem(SHOW_DOB_ON_LAB_PRINT) || "false")
  );

  const toggleShowDob = useCallback(() => {
    setShowDob((prev) => !prev);
  }, []);

  useEffect(() => {
    localStorage.setItem(SHOW_DOB_ON_LAB_PRINT, JSON.stringify(showDob));
  }, [showDob]);

  return { showDob, toggleShowDob };
};

export default useHandleShowDobOnLabPrint;

import { Box, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import List from "../../../components/List";
import { EmptyView } from "../../../components/OList";
import { tl } from "../../../components/translate";
import AccountingVoucherPanel from "./AccountingVoucherPanel";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import useAccountingVoucherSeries from "./hooks/useAccountingVoucherSeries";
import { AccountingVoucherSeriesInterface } from "../../../interfaces/Accounts";
import { useFiscalPeriod } from "../../../hooks/query/useFiscalPeriods";
import { getCurrentSubscription } from "../../../slices/subscriptionSlice";
import { RootState } from "../../../store";

export const formatVoucherDisplay = (
  row: AccountingVoucherSeriesInterface,
  type: string
): string => {
  if (row.startingVoucher && row.digitsCount && row.customPrefix) {
    if (type === "sampleDisplay") {
      return `${convertADtoBS(new Date()).bsYear}${row.voucherCode}-${
        row.customPrefix
      }-${`${row.startingVoucher}`.padStart(row.digitsCount, "0")}`;
    }
    if (type === "nextFiscal") {
      return `${Number(convertADtoBS(new Date()).bsYear) + 1}${row.voucherCode}-${
        row.customPrefix
      }-${`${row.startingVoucher}`.padStart(row.digitsCount, "0")}`;
    }
  }
  return "";
};

const AccountingVoucherSeriesList = ({
  voucherId,
  resourceCentreId
}: {
  resourceCentreId: number;
  voucherId?: number;
}): React.ReactElement => {
  const data = useAccountingVoucherSeries();
  const dispatch = useDispatch();
  const [selectedVoucher, setSelectedVoucher] =
    React.useState<AccountingVoucherSeriesInterface | null>(null);

  const handleViewClose = () => {
    setSelectedVoucher(null);
  };
  const hasCurrentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  React.useEffect(() => {
    if (!hasCurrentSubscription) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [dispatch, hasCurrentSubscription, rcId]);

  React.useEffect(() => {
    if (voucherId && data) {
      setSelectedVoucher(data.find((d) => d.id === voucherId));
    }
  }, [voucherId, data]);

  const { data: fiscalPeriods } = useFiscalPeriod(resourceCentreId);
  const sortedFiscalPeriods = fiscalPeriods?.sort((a, b) => a.id - b.id)[0];

  return (
    <Box overflow="auto hidden" height="100%">
      <Box height="100%" minWidth={900}>
        <List
          automation="AccountingVoucherList"
          title={tl("account.accountingVouchers")}
          rowHeight={50}
          defaultSortColumn="fiscal"
          defaultSortOrder={-1}
          isResponsive
          withoutSearch
          columns={[
            {
              key: "voucherName",
              label: tl("account.voucherType")
            },
            {
              key: "voucherCode",
              label: tl("account.voucherCode")
            },
            {
              key: "fiscalYear",
              label: tl("account.fiscal"),
              formatter: () => (
                <Typography>
                  {sortedFiscalPeriods?.from
                    ? convertADtoBS(moment(sortedFiscalPeriods?.from)).bsYear
                    : ""}
                </Typography>
              )
            },
            {
              key: "customPrefix",
              label: tl("account.customPrefix")
            },
            {
              key: "startingVoucher",
              label: tl("account.startVoucher")
            },
            {
              key: "endVoucher",
              label: tl("account.endVoucher"),
              formatter: () => <Typography>Continuing</Typography>
            },
            {
              key: "sampleDisplay",
              label: tl("account.sampleDisplay"),
              formatter: (row) => (
                <Typography>{formatVoucherDisplay(row, "sampleDisplay")}</Typography>
              )
            },
            {
              key: "nextFiscal",
              label: tl("account.nextFiscal"),
              formatter: (row) => <Typography>{formatVoucherDisplay(row, "nextFiscal")}</Typography>
            }
          ]}
          data={data}
          isLoading={!data}
          activeRow={selectedVoucher && selectedVoucher.id}
          onRowClick={(voucher: AccountingVoucherSeriesInterface) => {
            setSelectedVoucher(voucher);
          }}
          hideCreateButton
        >
          <EmptyView>
            <Box textAlign="center" padding="50px">
              No items to show.
            </Box>
          </EmptyView>
        </List>
        {selectedVoucher && (
          <AccountingVoucherPanel
            voucherSeries={data}
            onClose={handleViewClose}
            selectedId={selectedVoucher?.id}
          />
        )}
      </Box>
    </Box>
  );
};

AccountingVoucherSeriesList.defaultProps = {
  voucherId: undefined
};

export default AccountingVoucherSeriesList;

import React from "react";
import { Box, Typography } from "@mui/material";
import produce from "immer";
import moment from "moment";
import { LabTestStatusTypes } from "../LabList";
import LabTestSubRow from "./LabTestSubRow";

interface LabTestRowInterface {
  status: string;
  gender: string;
  labTest: Record<string, unknown>;
  onRowChange?: (newRow) => void;
  disabled: boolean;
  printMode: boolean;
  resultDate: string;
  dob: string;
}

const LabTestRow: React.FC<LabTestRowInterface> = ({
  labTest,
  gender,
  status,
  onRowChange,
  disabled,
  printMode,
  resultDate,
  dob
}) => {
  const [labTestRow, setLabTestRow] = React.useState<Record<string, any>>({});

  const [disable, setDisable] = React.useState(false);
  React.useEffect(() => {
    setLabTestRow(labTest);
  }, [labTest]);

  React.useEffect(() => {
    if (onRowChange && Object.keys(labTestRow).length) onRowChange(labTestRow);
  }, [labTestRow]);

  React.useEffect(() => {
    if (disabled) {
      setDisable(true);
    } else if (status === LabTestStatusTypes.RESULT_READY) {
      if (resultDate && moment().diff(moment(resultDate), "hours") < 24) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(false);
    }
  }, [labTestRow]);

  if (!labTest) return null;

  return (
    <Box>
      {labTestRow?.labTests?.length > 1 && (
        <Box>
          <Typography>
            <Box component="span" fontWeight="600">
              {labTestRow.name}
            </Box>
          </Typography>
        </Box>
      )}
      {labTestRow.labTests &&
        labTestRow.labTests.length > 0 &&
        labTestRow.labTests.map((lt, index) => (
          <LabTestSubRow
            pl={{
              paddingLeft: `${labTestRow.labTests.length > 1 ? "30px" : ""}`
            }}
            status={status}
            setAdditionalData={undefined}
            disabled={disable}
            index={index}
            key={lt.id}
            gender={gender}
            dob={dob}
            test={lt}
            onSubRowChange={(newSubTest) => {
              const newRow = produce(labTestRow.labTests, (draft) => {
                draft.splice(index, 1, newSubTest);
              });
              setLabTestRow({ ...labTestRow, labTests: newRow });
            }}
            printMode={printMode}
          >
            {lt.subTests && lt.subTests.length > 0 && (
              <>
                {lt.subTests.map((subT, ind) => (
                  <LabTestSubRow
                    pl={{ paddingLeft: "60px" }}
                    status={status}
                    index={`${index}${ind}`}
                    disabled={disable}
                    key={subT.id}
                    gender={gender}
                    setAdditionalData={undefined}
                    dob={dob}
                    test={subT}
                    subTest
                    onSubRowChange={(newSubTest) => {
                      const newRow = produce(labTestRow.labTests, (draft) => {
                        draft[index].subTests.splice(ind, 1, newSubTest);
                      });
                      setLabTestRow({ ...labTestRow, labTests: newRow });
                    }}
                    printMode={printMode}
                  />
                ))}
              </>
            )}
          </LabTestSubRow>
        ))}
    </Box>
  );
};

LabTestRow.defaultProps = {
  onRowChange: () => ({})
};

export default LabTestRow;

import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import CreateAssessment from "./CreateAssessment";
import { tl } from "../../../components/translate";

const nameMapper = {
  hopi: "Hopi",
  presentHealthStatus: "Present Health Status",
  pastHistoryOfAllergy: "Past History Of Allergy"
};
interface Props {
  assessment: {
    hopi: string;
    pastHistoryOfAllergy: string;
    presentHealthStatus: string;
  };
  id: number;
  navigateTo: (v: string) => void;
}

function ShowAssessment({ assessment, navigateTo, id }: Props) {
  const [showAddAssessment, setShowAddAssessment] = React.useState(true);

  React.useEffect(() => {
    if (assessment) {
      setShowAddAssessment(false);
    }
  }, [assessment]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          data-testmation="addAssessmentButton"
          onClick={() => {
            setShowAddAssessment(true);
          }}
        >
          {tl("medical.updateAssessment")}
        </Button>
      </Box>
      {showAddAssessment && (
        <CreateAssessment handleClose={() => navigateTo(`/medical/details/${id}`)} id={id} />
      )}
      <Box mt="16px">
        {Object.keys(assessment).map((item, i) => (
          <Typography key={item}>
            {i + 1}. {nameMapper[item]}: {assessment[item]}
          </Typography>
        ))}
      </Box>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (url) => {
    dispatch(push(url));
  }
});

export default connect(null, mapDispatchToProps)(ShowAssessment);

import { actionCreator, actionCreatorAsync } from './actionHelpers';
import * as api from '../api/slots';
import * as moment from 'moment';

export enum Type {
  GET_SLOTS = 'GET_SLOTS',
  SLOTS_ADD = 'SLOT_ADD',
  SLOT_REMOVE = 'SLOT_REMOVE',
  CREATE_SLOTS = 'CREATE_SLOTS',
  SLOT_GET = 'SLOT_GET',
  BOOK_SLOT = 'BOOK_SLOT',
  GET_DASHBOARD_SLOTS = 'GET_DASHBOARD_SLOTS'
}

export const createSlots = (slots, serviceProviderId) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CREATE_SLOTS, async () => {
        return api.postBatchSlots(slots, serviceProviderId);
      }),
    );
  };
};

export const fetchSlotsForDashboard = (
  from: moment.Moment,
  until: moment.Moment,
  serviceProviderId,
  resourceCentreId,
  filterEmptySlots
) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_DASHBOARD_SLOTS, async () => {
        return api.getSlots(
          from.toISOString(),
          until.toISOString(),
          serviceProviderId,
          resourceCentreId,
          filterEmptySlots
        );
      }),
    );
  };
};

export const fetchSlotsWithBookings = (
  from: moment.Moment,
  until: moment.Moment,
  serviceProviderId,
  resourceCentreId,
) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_SLOTS, async () => {
        return api.getSlots(
          from.toISOString(),
          until.toISOString(),
          serviceProviderId,
          resourceCentreId,
        );
      }),
    );
  };
};

export const bookSlot = (slotId, bookData) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.BOOK_SLOT, async () => {
        return api.bookSlot(slotId, bookData);
      }),
    );
  };
};

export const fetchSlot = (slotId) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.SLOT_GET, async () => {
        return api.getSlot(slotId);
      }),
    );
  };
};

export const removeSlot = (slotId) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.SLOT_REMOVE, async () => {
        return api.removeSlot(slotId);
      }),
    );
  };
};

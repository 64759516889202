import React from "react";
import { Box, TextField, Checkbox, MenuItem, FormControlLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Panel from "../../../components/Panel";
import { tl } from "../../../components/translate";
import { PSecondaryLedger } from "../CoaTree";
import { usePostAccGroup, usePatchAccGroup } from "../hooks";
import * as NotificationActions from "../../../actions/notification";
import { AccountingGroup } from "../../../interfaces/Accounts";
import FormTextField from "../../../components/FormTextField/FormTextField";
import { ParentInterface } from "../AccountList";

interface AccountGroupCreateProps {
  setShowAccountGlCreate: (show: boolean) => void;
  secondaryLedgerList: PSecondaryLedger[];
  data?: Partial<AccountingGroup>;
  setSelectedParent: (val: ParentInterface) => void;
}

export default function AccountGroupCreate({
  setShowAccountGlCreate,
  secondaryLedgerList,
  data,
  setSelectedParent
}: AccountGroupCreateProps): JSX.Element {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const isBalanceRequired = watch("balanceRequired");
  React.useEffect(() => {
    setValue("balance", 0);
  }, [isBalanceRequired, setValue]);
  const {
    mutate: postMutate,
    isLoading: postLoading,
    error: postError,
    isSuccess: postSuccess
  } = usePostAccGroup();

  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    error: patchError,
    isSuccess: patchSuccess
  } = usePatchAccGroup();

  const patchAccGroup = (submittedData) => {
    patchMutate(submittedData);
  };
  const saveAccGroup = (submittedData) => {
    postMutate(submittedData);
  };
  const submitHandler: SubmitHandler<Partial<AccountingGroup>> = (
    submittedData: Partial<AccountingGroup>
  ) => {
    if (data?.parentId) {
      patchAccGroup({ ...submittedData, id: data.id });
      return;
    }
    saveAccGroup(submittedData);
  };

  if (postError || patchError) {
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "error",
        message: "Sorry! Something went wrong.",
        autoTimeout: true
      })
    );
    setShowAccountGlCreate(false);
    setSelectedParent(null);
  }

  if (postSuccess || patchSuccess) {
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "success",
        message: `Successfully ${postSuccess ? "Created " : "Updated "}  Account Group`,
        autoTimeout: true
      })
    );
    setShowAccountGlCreate(false);
    setSelectedParent(null);
  }

  const footer = (
    <Box sx={{ position: "absolute", bottom: "20px", right: "20px" }}>
      <LoadingButton
        data-testmation="footerSave"
        variant="contained"
        type="submit"
        loading={postLoading || patchLoading}
        onClick={handleSubmit(submitHandler)}
      >
        {data ? tl("update") : tl("save")}
      </LoadingButton>
    </Box>
  );

  return (
    <Panel
      footer={footer}
      title={tl("accounts.createAccountGroup")}
      onClose={() => {
        setShowAccountGlCreate(false);
        setSelectedParent(null);
      }}
    >
      <form>
        <Box m={3}>
          <FormTextField
            dataTestmation="accountGroupNameInput"
            fullWidth
            control={control}
            name="groupName"
            label="Accounting Group Name (Parent 3)"
            style={{ margin: "10px" }}
            rules={{ required: "Can't be empty", maxLength: 50 }}
            errors={errors}
            defaultValue={data?.id ? data.groupName : ""}
          />
          <Controller
            name="parentId"
            control={control}
            rules={{ required: "Can't be empty." }}
            defaultValue={data?.parentId || ""}
            render={({ field }) => (
              <TextField
                data-testmation="secondParentSelect"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                style={{ margin: "10px" }}
                label="Under Parent 2"
                select
                fullWidth
                disabled={Boolean(data?.parentId)}
                variant="outlined"
                error={!!errors.parentId}
                helperText={errors?.parentId?.message || ""}
              >
                {secondaryLedgerList.map((sec) => (
                  <MenuItem value={sec.parentId} key={sec.parentId}>
                    {sec.secondaryLedgerName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Box display="flex" alignContent="center" marginLeft="10px">
            <Controller
              name="balanceRequired"
              control={control}
              defaultValue={data?.balanceRequired || false}
              render={({ field }) => (
                <FormControlLabel
                  label="Budget Required"
                  control={
                    <Checkbox
                      data-testmation="budgetRequired"
                      size="small"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  }
                />
              )}
            />
            <FormTextField
              dataTestmation="budgetInput"
              control={control}
              name="balance"
              disabled={!isBalanceRequired}
              label="Budget"
              style={{ margin: "10px" }}
              rules={
                isBalanceRequired
                  ? { required: "Can't be empty", maxLength: 50 }
                  : { required: false }
              }
              errors={isBalanceRequired ? errors : {}}
              defaultValue={data?.balance || ""}
            />
          </Box>
        </Box>
      </form>
    </Panel>
  );
}

AccountGroupCreate.defaultProps = {
  data: null
};

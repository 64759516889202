import moment from "moment";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";

export interface AudienceData {
  id?: number;
  resourceCentreId?: number;
  type: "static" | "dynamic";
  name: string;
  isReady: boolean;
  isBatch: boolean;
  rules: {
    client: {
      ids?: string[];
      gender?: 1 | 2 | 3;
      age?: {
        gte?: number;
        lte?: number;
      };
      dob?: string;
    };
    booking?: {
      services?: [];
      age?: number;
    };
    service?: {
      id: number;
      duration: number;
    };
    clientLedger?: {
      dueAmount?: {
        gte: number;
        lte: number;
      };
    };
  };
}

export const getGenderString = (gender: number): string => {
  const genderMap = { "1": "Male", "2": "Female", "3": "Other" };
  if (!genderMap[gender]) return "None";
  return genderMap[gender];
};

export const getFormattedRulesFromAudience = (
  audience: AudienceData,
  services: ServiceInterface[]
): string => {
  if (audience.type === "static") return "-";
  let output = "{ ";
  const clientRules = audience.rules.client;
  if (clientRules?.age) {
    output += `  age > ${clientRules.age.gte},   age < ${clientRules.age.lte},`;
  }
  if (clientRules?.gender) {
    output += `  gender = '${getGenderString(clientRules.gender)}',`;
  }
  if (clientRules?.dob) {
    output += `  birthday = ${clientRules.dob === "today" && moment().format("YYYY-MM-DD")},`;
  }
  const ledgerRules = audience.rules.clientLedger;
  if (ledgerRules?.dueAmount) {
    output += `  due > ${ledgerRules.dueAmount.gte},   due < ${ledgerRules.dueAmount.lte},`;
  }
  const serviceRules = audience.rules.service;
  if (serviceRules) {
    output += `  received ${
      services.find((item) => item.id === serviceRules.id)?.name || ""
    } service in last ${serviceRules.duration} months`;
  }

  output = output.slice(0, -1);
  output += " }";
  return output;
};

import localStorage from "../helpers/localStorage";
import sessionStorage from "../helpers/sessionStorage";

interface UserData {
  message: string;
  userId: number;
  token: string;
}

export const sessionOrLocalStorageUserData = (): string | null =>
  sessionStorage.getItem("user") || localStorage.getItem("user");

export const saveAuthData = (userdata: UserData, storage = localStorage): void => {
  storage.setItem("user", JSON.stringify(userdata));
};

export const isTokenValid = (data?: UserData): boolean => {
  if (!data) {
    const user = sessionOrLocalStorageUserData();
    if (!user) return false;
  }
  return true;
};

export const isAuthenticated = (): boolean => {
  const user = sessionOrLocalStorageUserData();
  try {
    return Boolean(user) && isTokenValid(JSON.parse(user as string));
  } catch {
    return false;
  }
};

export const getToken = (): string => JSON.parse(sessionOrLocalStorageUserData() as string).token;

export const removeAuthData = (): void => {
  localStorage.removeItem("user");
};

import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import EditableTable from "../../../components/EditableTable/EditableTable";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { createEstimateTemplate, fetchEstimateTemplate } from "../../../slices/estiamtesSlice";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { defaultEstimateState } from "./helpers";
import { Estimate } from "./interfaces";

interface Props {
  onClose: () => void;
  open: boolean;
}
function Settings({ onClose, open }: Props): JSX.Element {
  const templateEstimate: Estimate = useAppSelector((state) => state.estimates.template);
  const dispatch = useAppDispatch();

  const [data, setData] = useState(
    templateEstimate.estimates?.tabular || { ...defaultEstimateState.tabular }
  );
  return (
    <Dialog fullScreen={false} open={open} onClose={onClose}>
      <DialogTitle>Estimates settings</DialogTitle>
      <DialogContent>
        <EditableTable
          maxColCount={10}
          onChange={(value) => setData(value)}
          initValue={data}
          hideControls={false}
          hideLabel={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={async () => {
            try {
              await dispatch(
                createEstimateTemplate({ ...defaultEstimateState, tabular: data })
              ).unwrap();
              await dispatch(fetchEstimateTemplate()).unwrap();
              onClose();
            } catch (e) {
              dispatch(
                notificationAdd({
                  id: new Date().getTime(),
                  message: commonErrorMessage,
                  variant: "error",
                  autoTimeout: true
                })
              );
            }
          }}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Settings;

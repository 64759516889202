import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import moment from "moment";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import useAnimationFrame from "../../../hooks/useRequestAnimationFrame";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "48px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "762px",
    maxWidth: "1440px"
  },
  header: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    textTransform: "uppercase"
  },
  englishDate: {
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6F6F6F",
    textAlign: "right"
  }
}));

const FullScreenHeader = (): React.ReactElement => {
  const classes = useStyles();
  const [date, setDate] = React.useState(moment().valueOf());

  useAnimationFrame((e) => {
    setDate(e.date);
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.header}> {moment(date).format("h:mm:ss a")}</Typography>
      <div>
        <Typography className={classes.header}>{`${
          convertADtoBS(moment(date)).formatted5
        }  `}</Typography>
        <Typography className={classes.englishDate}>
          {moment(date).format("MMMM Do YYYY")}
        </Typography>
      </div>
    </div>
  );
};

export default FullScreenHeader;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import useCan from "./useCan";
import { Subscription } from "../interfaces/Subscription";
import { RootState } from "../store";
import { getCurrentSubscription } from "../slices/subscriptionSlice";

export default function useSubscription(): Subscription | null {
  const dispatch = useDispatch();
  const isSuperAdmin = useCan("deny", { superAdminPass: true, supportAccountAdminPass: true });
  const { currentSubscription } = useSelector((state: RootState) => state.subscriptions);
  const { resourceCentreId } = useSelector((state: RootState) => state.userContext);
  React.useEffect(() => {
    if (isEmpty(currentSubscription) && resourceCentreId && !isSuperAdmin) {
      dispatch(getCurrentSubscription(resourceCentreId));
    }
  }, [dispatch, currentSubscription, resourceCentreId, isSuperAdmin]);

  if (isSuperAdmin) return null;
  return currentSubscription;
}

/* eslint-disable no-console */
import { AxiosResponse } from "axios";
import moment from "moment";
import { StateProps } from "../containers/Bookings/Public/ScheduleDetails";
import { Booking } from "../interfaces/BookingInterfaces";
import {
  FilterProps,
  PublicLabInterface,
  PublicServiceInfo
} from "../interfaces/PublicLabInterface";
import { Get, Post } from "./apiHelper";
import { MobileUser } from "../containers/OkhatiPublic/UserSelect";

const publicRoot = "/api/public/v1";

const getVal = <T>(val: T): T | string => val || "";

export const getPublicLabs = async (filters: FilterProps): Promise<PublicLabInterface[]> => {
  const response = await Get(
    `${publicRoot}/public/publicLabs?rcIds=${getVal(filters.rcIds)}`,
    true
  );
  return response?.data as PublicLabInterface[];
};

export const getResourceCentreLocations = async (): Promise<AxiosResponse> => {
  const response = await Get(`${publicRoot}/public/resourceCentreLocations`, true);
  return response as AxiosResponse;
};

export const getPublicServices = async (filters: FilterProps): Promise<PublicServiceInfo> => {
  const response = await Get(
    `${publicRoot}/public/labServices?referenceId=${getVal<number | null>(
      filters?.referenceId
    )}&rcIds=${getVal<number[] | null>(filters?.rcIds)}&page=${filters.page}&pageSize=${
      filters.size
    }`,
    true
  );
  return response?.data as PublicServiceInfo;
};

export const getPublicEvents = async (rcId: number): Promise<AxiosResponse> => {
  const response = await Get(
    `${publicRoot}/public/scheduleBlocks?rcId=${rcId}&start=${moment()
      .startOf("day")
      .toISOString()}&end=${moment().add(1, "week").startOf("day").toISOString()}`,
    true
  );
  return response as AxiosResponse;
};

export const postPublicBooking = async (data: Partial<StateProps>): Promise<Booking> => {
  const response = await Post(`${publicRoot}/public/book`, data);
  return response.data as Booking;
};

export async function searchMobileUsers(query: string): Promise<MobileUser[]> {
  const response = await Get(`${publicRoot}/users/search?q=${query}`, true);
  return response.data as MobileUser[];
}

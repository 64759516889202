import { Box, Typography } from "@mui/material";
import moment from "moment";
import * as React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { subscriptionInvoiceWarningsGet } from "../../api/subscriptions";
import { PendingInvoice } from "../../interfaces/Subscription";
import { RootState } from "../../store";

export default function PendingInvoices(): JSX.Element {
  const [pendingInvoices, setPendingInvoices] = React.useState<Array<PendingInvoice>>([]);
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext?.userCreds?.userGroups[0]
  );

  React.useEffect(() => {
    (async () => {
      if (permissionGroup === "resourceCentreAdmin") {
        const data = await subscriptionInvoiceWarningsGet();
        setPendingInvoices(data);
      }
    })();
  }, []);

  const dispatch = useDispatch();

  if (!pendingInvoices.length) return null;

  return (
    <Box p={1} border="2px solid orange" borderRadius="8px">
      {pendingInvoices.map((invoice) => (
        <Box
          key={invoice.id}
          display="flex"
          justifyContent="space-between"
          px="8px"
          alignItems="center"
        >
          <Box display="flex">
            <InfoIcon htmlColor="orange" />
            {invoice.otherDetails ? (
              <Typography>
                Okhati invoice of Rs {invoice.invoiceAmount} is due on{" "}
                {moment(invoice.expiresOn).format("DD/MM/YYYY")}
              </Typography>
            ) : (
              <Typography style={{ marginLeft: "16px" }}>
                Okhati {invoice.paymentType} subscription payment of Rs {invoice.invoiceAmount} is
                due on {moment(invoice.expiresOn).format("DD/MM/YYYY")}
              </Typography>
            )}
          </Box>
          <Typography
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              dispatch(push(`/resourceCentreInvoices?invoiceId=${invoice.id}`));
            }}
          >
            PAY ONLINE
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

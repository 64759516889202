import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import * as moment from "moment-timezone";

interface MessageBoxProps {
  message: string;
  displayName?: string;
  timestamp: number;
  photoURL?: string;
  alignMessage: "left" | "right";
}

const SenderThread = ({
  message,
  timestamp,
  photoURL,
  displayName,
  alignMessage
}: MessageBoxProps): JSX.Element => (
  <Box
    sx={{ display: "flex", justifyContent: alignMessage === "right" ? "flex-end" : "flex-start" }}
  >
    {alignMessage === "left" && (
      <Tooltip title={displayName}>
        <Avatar alt={displayName} src="/broken-image.png" />
      </Tooltip>
    )}
    <Box
      sx={{
        position: "relative",
        ...(alignMessage === "right" ? { marginRight: "10px" } : { marginLeft: "10px" }),
        marginRight: "10px",
        marginBottom: "10px",
        padding: "10px",
        backgroundColor: alignMessage === "right" ? "#96FFBA" : "#E9EAED",
        maxWidth: "90%",
        textAlign: "left",
        border: "1px solid none",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end"
      }}
    >
      <Typography p={0} m={0}>
        {message || "Empty Message"}
      </Typography>
      <Typography fontSize="10px" mt={1} ml={0.5} color="gray">
        {moment(timestamp).format("HH:mm")}
      </Typography>
    </Box>
    {alignMessage === "right" && <Avatar alt={displayName || ""} src={photoURL || ""} />}
  </Box>
);

export default SenderThread;

import { actionCreatorAsync } from './actionHelpers';
import * as api from '../api/campaigns';
import { push } from 'connected-react-router';

export enum Type {
  CAMPAIGNS_GET = 'CAMPAIGNS_GET',
  CAMPAIGN_GET = 'CAMPAIGN_GET',
  CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE',
  CAMPAIGN_CREATE = 'CAMPAIGN_CREATE',
  CAMPAIGN_DELETE = 'CAMPAIGN_DELETE',
}

export const getCampaigns = () => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CAMPAIGNS_GET, async () => {
        return api.getBatchCampaign();
      })
    );
  };
};

export const getCampaignById = (id, next?) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CAMPAIGN_GET, async () => {
        return api.getCampaignDetails(id, next);
      })
    );
  };
};

export const saveCampaign = (campaignData, stayOnPage = false) => {
  return async (dispatch) => {
    if (campaignData.id) {
      await dispatch(
        actionCreatorAsync(Type.CAMPAIGN_UPDATE, async () => {
          const data = await api.updateCampaign(campaignData.id, campaignData);
          return data;
        })
      );
    } else {
      await dispatch(
        actionCreatorAsync(Type.CAMPAIGN_CREATE, async () => {
          const data = await api.createCampaign(campaignData);
          if (data.id && !stayOnPage)
            dispatch(push(`campaign/campaigns/${data.id}`));
          return data;
        })
      );
    }
  };
};

export const deleteCampaign = (id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.CAMPAIGN_DELETE, async () => {
        await api.deleteCampaign(id);
        return id;
      })
    );
  };
};

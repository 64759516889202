/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBillsForReferrerCommission,
  getBillsForServiceProviderCommission,
  postReferrerCommission,
  postServiceProviderCommission
} from "../api/commission";
import { formatBillData } from "../containers/Billing/Comission/ReferrerCommission/helper";
import { formatBillDataForCommission } from "../containers/Billing/Comission/ServiceProviderCommission/helper";

import { RootState } from "../store/index";
import {
  BillDocumentProps,
  VendorCommission,
  VendorCommissionBill
} from "../interfaces/Commission";

export const getBillForCommission = createAsyncThunk(
  "commission/getBills",
  async (referrerId: number) => {
    const response = await getBillsForReferrerCommission(referrerId);
    return (await response) as BillDocumentProps[];
  }
);

export const saveReferrerCommission = createAsyncThunk(
  "commission/postReferrerCommission",
  async (data: VendorCommission[]) => {
    const response = await postReferrerCommission(data);
    return (await response) as VendorCommission[];
  }
);

export const getBillsForSPCommission = createAsyncThunk(
  "commission/getBillsForServiceProvider",
  async (
    filters: { id: number; filters: { page: number; size: number } },
    { getState }: { getState: () => RootState }
  ) => {
    const serviceProviders = getState()?.resources?.resourceCentreServiceProviders;
    const response = await getBillsForServiceProviderCommission(filters);
    const data = formatBillDataForCommission(response.results, serviceProviders);
    return (await { results: data, total: response.total }) as {
      results: VendorCommissionBill[];
      total: number;
    };
  }
);

export const saveServiceProviderCommission = createAsyncThunk(
  "commission/postSpCommission",
  async (data: VendorCommission[]) => {
    const response = await postServiceProviderCommission(data);
    return response as VendorCommission[];
  }
);

const initialState = {
  referrers: [] as VendorCommissionBill[],
  serviceProviders: [] as VendorCommissionBill[],
  totalNumber: 0,
  isPending: "idle"
};

const commissionSlice = createSlice({
  name: "commissions",
  initialState,
  reducers: {
    clearVendorCommission: (state: RootState) => ({ ...state, ...initialState }),
    updateBillOnSelect: (state: RootState, { payload }) => {
      if (!payload.sNo) {
        state.referrers = state.referrers.map((bill) => ({ ...bill, isSelected: payload.checked }));
      } else {
        const index = state.referrers.findIndex((bill) => bill.sNo === payload.sNo);
        state.referrers[index].isSelected = payload.checked;
      }
    },
    updateReferrerCommission: (state: RootState, { payload }) => {
      const index = state.referrers.findIndex((item) => item.sNo === payload.sNo);
      state.referrers[index] = payload;
    },
    updateSpCommissionItem: (state: RootState, { payload }) => {
      const index = state.serviceProviders.findIndex((item) => item.sNo === payload.sNo);
      state.serviceProviders[index] = payload;
    },
    updateSpBillOnSelect: (state: RootState, { payload }) => {
      if (!payload.sNo) {
        state.serviceProviders = state.serviceProviders.map((bill) => {
          if (!bill.isSaved && !bill.isServiceProviderPaid) {
            return {
              ...bill,
              isSelected: payload.checked
            };
          }
          return bill;
        });
      } else {
        const index = state.serviceProviders.findIndex((bill) => bill.sNo === payload.sNo);
        state.serviceProviders[index].isSelected = payload.checked;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBillForCommission.fulfilled, (state, { payload }) => {
      state.referrers = formatBillData(payload);
    });
    builder.addCase(saveReferrerCommission.fulfilled, (state, { payload }) => {
      const paymentIds = [...new Set(payload.map((item) => item.recordPaymentId))];
      return {
        ...state,
        referrers: state.referrers.map((item) => {
          if (paymentIds.includes(item.recordPaymentId)) {
            return {
              ...item,
              isSaved: true,
              isSelected: false,
              isReferrerPaid: true
            };
          }
          return item;
        })
      };
    });
    builder.addCase(getBillsForSPCommission.fulfilled, (state, { payload }) => {
      state.serviceProviders = payload.results;
      state.totalNumber = payload.total;
    });
    builder.addCase(saveServiceProviderCommission.fulfilled, (state, { payload }) => {
      const paymentIds = [...new Set(payload.map((item) => item.recordPaymentId))];
      return {
        ...state,
        serviceProviders: state.serviceProviders.map((item) => {
          if (paymentIds.includes(item.recordPaymentId)) {
            return { ...item, isSaved: true, isSelected: false, isServiceProviderPaid: true };
          }
          return item;
        })
      };
    });
  }
});

export const {
  clearVendorCommission,
  updateBillOnSelect,
  updateReferrerCommission,
  updateSpBillOnSelect,
  updateSpCommissionItem
} = commissionSlice.actions;
export default commissionSlice.reducer;

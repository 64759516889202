import React from "react";

export const EyeDrops = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 60 60"
    >
      <path
        d="M41.188,27.697C41.068,27.589,41,27.452,41,27.319V21h-1v-6h-3c-1.103,0-2-0.897-2-2V5c0-2.757-2.243-5-5-5s-5,2.243-5,5v8
	c0,1.103-0.897,2-2,2h-3v6h-1v6.319c0,0.132-0.068,0.27-0.188,0.378C16.317,29.938,15,32.894,15,36.247V37v16v3.415
	C15,58.392,16.608,60,18.585,60h22.83C43.392,60,45,58.392,45,56.415V53V37v-0.753C45,32.894,43.683,29.938,41.188,27.697z M39,26
	H21v-3h2v1c0,0.552,0.447,1,1,1s1-0.448,1-1v-1h2v1c0,0.552,0.447,1,1,1s1-0.448,1-1v-1h2v1c0,0.552,0.447,1,1,1s1-0.448,1-1v-1h2v1
	c0,0.552,0.447,1,1,1s1-0.448,1-1v-1h2V26z M27,13V5c0-1.654,1.346-3,3-3s3,1.346,3,3v8c0,0.728,0.195,1.411,0.537,2h-7.074
	C26.805,14.411,27,13.728,27,13z M22,17h1h14h1v4H22V17z M17,39h6v12h-6V39z M43,41h-8v2h8v1h-8v2h8v1h-8v2h8v2H30V39h13V41z M28,37
	v16h15v3.415C43,57.289,42.289,58,41.415,58h-22.83C17.711,58,17,57.289,17,56.415V53h8V37h-8v-0.753
	c0-2.769,1.089-5.211,3.147-7.062c0.362-0.325,0.609-0.74,0.741-1.185h18.223c0.132,0.445,0.379,0.86,0.741,1.185
	C41.911,31.036,43,33.478,43,36.247V37H28z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import { pick } from "lodash";
import moment from "moment";
import { PublicEvent, PublicSchedule } from "../../interfaces/PublicLabInterface";

export const getSchedule = (sc: { start: string; end: string }): string[] => {
  const startDate = moment(sc.start);
  const endDate = moment(sc.end);
  const schedules = [startDate.toISOString()] as string[];
  startDate.add(1, "h");
  while (startDate.isBefore(endDate)) {
    schedules.push(startDate.toISOString());
    startDate.add(1, "h");
  }
  return schedules;
};

export const formatPublicEvents = (events: PublicEvent[] = []): PublicSchedule[] =>
  events.map((item) => ({ day: item.daysInWeek[0], schedules: getSchedule(item.schedules[0]) }));

export const isValidData = <T,>(data: T, requiredFields: string[]): boolean =>
  !Object.values(pick(data, requiredFields)).some((item) => !item);

import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import CollapseBar from "../../../components/CollapseBar/CollapseBar";
import LabComments from "../Settings/LabMasterDataEdit/LabComments";
import AdditionalDataEdit from "../Settings/LabMasterDataEdit/AdditionalDataEdit";
import Culture from "../Settings/LabMasterDataEdit/Culture";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setTestGroupDraft } from "../../../slices/labTestSettingsSlice";
import StockReagentListView from "./StockReagentListView";
import StockReagent from "./StockReagent";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

const LabGroupAdditionalSettingsEdit = (): React.ReactElement => {
  const currentRC = useCurrentResourceCentre();
  const draftTestGroup = useAppSelector((state: RootState) => state.labSettings.draftGroup);
  const {
    additionalTestData,
    interpretationTemplate,
    stockReagent = [],
    sampleInfo,
    info
  } = draftTestGroup;

  const [draftSampleInfo, setDraftSampleInfo] = useState({
    sampleContainer: sampleInfo?.sampleContainer || "",
    sampleType: sampleInfo?.sampleType || ""
  });

  const dispatch = useAppDispatch();

  const updateStockReagentItem = (sNo: number) => {
    const filteredList = [...stockReagent]
      .filter((item) => item.sNo !== sNo)
      .sort((a, b) => a.id - b.id)
      .map((item, idx) => ({ ...item, sNo: idx + 1 }));
    dispatch(
      setTestGroupDraft({
        ...draftTestGroup,
        stockReagent: filteredList
      })
    );
  };

  return (
    <Box mt={2}>
      {currentRC?.labSettings.enableInternalStock && (
        <CollapseBar collapsed label="Add Reagent">
          <Box alignItems="flex-start">
            <StockReagentListView
              onRemove={(sNo) => updateStockReagentItem(sNo + 1)}
              data={stockReagent}
            />
            <StockReagent
              stockReagent={stockReagent}
              onSave={(value) => {
                dispatch(
                  setTestGroupDraft({
                    ...draftTestGroup,
                    stockReagent: [...stockReagent, value]
                  })
                );
              }}
            />
          </Box>
        </CollapseBar>
      )}
      <CollapseBar collapsed label="Add Sample Information">
        <div>
          <Box display="flex" alignItems="center">
            <Typography sx={{ width: "32%" }}>Sample Type</Typography>
            <TextField
              sx={{
                marginRight: "10px"
              }}
              label="Sample Type"
              margin="dense"
              value={draftSampleInfo.sampleType}
              variant="outlined"
              fullWidth
              onBlur={(event) => {
                dispatch(
                  setTestGroupDraft({
                    ...draftTestGroup,
                    sampleInfo: { ...draftTestGroup.sampleInfo, sampleType: event.target.value }
                  })
                );
              }}
              onChange={(event) => {
                setDraftSampleInfo({ ...draftSampleInfo, sampleType: event.target.value });
              }}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography sx={{ width: "32%" }}>Sample Container</Typography>
            <TextField
              label="Sample Container"
              margin="dense"
              value={draftSampleInfo.sampleContainer}
              variant="outlined"
              fullWidth
              onBlur={(event) => {
                dispatch(
                  setTestGroupDraft({
                    ...draftTestGroup,
                    sampleInfo: {
                      ...draftTestGroup.sampleInfo,
                      sampleContainer: event.target.value
                    }
                  })
                );
              }}
              onChange={(event) => {
                setDraftSampleInfo({ ...draftSampleInfo, sampleContainer: event.target.value });
              }}
            />
          </Box>
        </div>
      </CollapseBar>
      <CollapseBar collapsed label="Interpretation Template">
        <Culture
          onSave={(value) => {
            dispatch(
              setTestGroupDraft({
                ...draftTestGroup,
                interpretationTemplate: value
              })
            );
          }}
          initValue={interpretationTemplate}
          isDialog={false}
        />
      </CollapseBar>
      <CollapseBar collapsed label="Comments Shown After All Lab Tests">
        <LabComments
          onSave={(value) => {
            dispatch(
              setTestGroupDraft({
                ...draftTestGroup,
                info: { ...draftTestGroup.info, defaultRemarks: value }
              })
            );
          }}
          value={info?.defaultRemarks || ""}
        />
      </CollapseBar>
      <CollapseBar collapsed label="Comment Shown After Each Lab Test Group">
        <AdditionalDataEdit
          additionaldata={additionalTestData}
          onSave={(value) => {
            dispatch(
              setTestGroupDraft({
                ...draftTestGroup,
                additionalTestData: value
              })
            );
          }}
          isDialog={false}
        />
      </CollapseBar>
    </Box>
  );
};

export default LabGroupAdditionalSettingsEdit;

import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { uniq } from "lodash";
import { FilterProps } from ".";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { LabSummary } from "../../../../interfaces/Lab";
import { formatDataForLineChart, getPalikaNameById } from "./helper";
import { RootState } from "../../../../store";

const dateFormatter = (date) => {
  const nepaliDate = convertADtoBS(new Date(date));
  return `${nepaliDate.bsYear} ${nepaliDate.bsMonth}-${nepaliDate.bsDate}`;
};

interface TooltipInterface {
  color: string;
  dataKey: string;
  value: number;
}

const darkColors = [
  "#FF0000",
  "#FFA500",
  "#0000FF",
  "#800080",
  "#00FF00",
  "#FF00FF",
  "#000000",
  "#7FFD4",
  "#FFA500",
  "#FFA500"
];

const generateRandomDarkColor = (index) => {
  if (index < 10) return darkColors[index];
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export default function LineChartComponent({
  data,
  filters
}: {
  data: LabSummary[];
  filters: FilterProps;
}): JSX.Element {
  const uniquePalikaIds = uniq(data.filter((item) => item.palikaId).map((item) => item.palikaId));
  const addressOptions = useSelector((state: RootState) => state.addressOptions.collection);
  const itemsState = React.useMemo(
    () => formatDataForLineChart(data, filters.palikas, addressOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filters.palikas, addressOptions, filters.districts]
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      const currDate = payload && payload.length ? payload[0].payload.date : null;
      const date = dateFormatter(currDate);
      if (currDate) {
        return (
          <Box
            style={{
              border: "1px solid gray",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#fff"
            }}
          >
            <Typography>{`Date: ${date}`}</Typography>
            {(payload || []).map((item: TooltipInterface) => {
              const palika = getPalikaNameById(Number(item.dataKey.slice(9)), addressOptions);
              return (
                <Typography
                  key={palika}
                  color={`${item.color}`}
                >{`${palika} : ${item.value}`}</Typography>
              );
            })}
          </Box>
        );
      }
    }
    return null;
  };

  return (
    <>
      <Typography>Palika Comparison Graph</Typography>
      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart
          width={500}
          height={300}
          data={itemsState}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={dateFormatter}
            type="number"
            domain={["dataMin", "dataMax"]}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {uniquePalikaIds.map((item, index) => (
            <Line
              key={item}
              type="monotone"
              dataKey={`testCount${item}`}
              stroke={generateRandomDarkColor(index)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import { startCase } from "lodash";
import { connect, useSelector } from "react-redux";
import { Typography, Box, Checkbox, MenuItem, Select } from "@mui/material";
import { bindActionCreators } from "redux";
import { tl } from "../../../components/translate";
import styles from "./BillEditor.module.css";
import Can from "../../Policy/Can";
import { BillDocumentSettingsType, BillDocumentType } from "../../../interfaces/BillInterfaces";
import Modal from "../../../components/Modal/Modal";
import { resourceCentreActions, ResourceCentreActionsType } from "../../../actions";
import { RootState } from "../../../store";
import DiscountTypeSelect from "./DiscountTypeSelect";

export const BillCounters = {
  counter1: "Counter 1",
  counter2: "Counter 2",
  counter3: "Counter 3"
};

function BillSettings({
  draft,
  updateSettings,
  actions,
  updateBillCounter,
  isSettingsOpen,
  setIsSettingsOpen,
  onExtraReferrerSettingChange,
  isExtraReferrerEnabled
}: {
  draft: BillDocumentType;
  updateSettings: (value: BillDocumentSettingsType) => void;
  actions: ResourceCentreActionsType;
  updateBillCounter: (value) => void;
  isSettingsOpen: boolean;
  setIsSettingsOpen: (value: boolean) => void;
  onExtraReferrerSettingChange: (value: boolean) => void;
  isExtraReferrerEnabled: boolean;
}): JSX.Element {
  const [isDirty, setIsDirty] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [extraReferrer, toggleExtraReferrer] = React.useState(isExtraReferrerEnabled);

  const handleClick = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const resourceCentre = useSelector((s: RootState) => s.resources.resourceCentres[0] || {});
  const [settings, setSettings] = React.useState(draft.settings);
  const [currentCounter, setCurrentCounter] = React.useState(
    localStorage.getItem("billCounter") || ""
  );

  const isCounterBillingEnabled =
    resourceCentre?.settings?.billingSettings?.enableCounterBilling || false;
  const updateSetting = (key, val) => {
    setIsDirty(true);
    setSettings({ ...settings, [key]: val });
  };

  const departmentSubscription = useSelector(
    (state: RootState) =>
      state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
  );

  const onSave = () => {
    setIsDirty(false);
    setAnchorEl(null);
    updateSettings({ ...settings });
    if (currentCounter) {
      localStorage.setItem("billCounter", currentCounter);
      updateBillCounter(currentCounter);
    }
    onExtraReferrerSettingChange(extraReferrer);
    actions.putResourceCentre({
      ...resourceCentre,
      settings: {
        ...resourceCentre.settings,
        billingSettings: {
          ...resourceCentre.settings.billingSettings,
          extraColumns: settings?.extraColumnSettings,
          discountSettings: settings?.discountSettings
        }
      }
    });
    setIsSettingsOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Can policyAccessKey="bill:changeSettings">
      <div>
        <Button
          data-testmation="billing.settings"
          aria-describedby={id}
          onClick={handleClick}
          size="small"
          startIcon={<SettingsIcon />}
        >
          {tl("billing.settings")}
        </Button>
        <Modal open={isSettingsOpen}>
          <Box className={styles.billSettingsRoot}>
            <Box>
              <Typography component="span" fontSize="16px" fontWeight={600}>
                Discount Methods
              </Typography>

              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={settings?.discountSettings?.discountBasis === "inline"}
                  onChange={() => {
                    updateSetting("discountSettings", {
                      ...settings?.discountSettings,
                      discountBasis:
                        settings?.discountSettings?.discountBasis === "invoice"
                          ? "inline"
                          : "invoice"
                    });
                  }}
                />
                <Typography>Inline Discount</Typography>
                <Box
                  sx={{
                    marginLeft: "10px",
                    marginTop: "5px"
                  }}
                >
                  <DiscountTypeSelect
                    fieldValue={settings?.discountSettings?.discountType}
                    onChange={(value) => {
                      updateSetting("discountSettings", {
                        ...settings?.discountSettings,
                        discountType: value
                      });
                    }}
                  />
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={settings?.discountSettings?.discountBasis === "invoice"}
                  onChange={() => {
                    updateSetting("discountSettings", {
                      ...settings?.discountSettings,
                      discountBasis:
                        settings?.discountSettings?.discountBasis === "invoice"
                          ? "inline"
                          : "invoice"
                    });
                  }}
                />
                <Typography>Total Discount</Typography>
                <Box
                  sx={{
                    marginLeft: "10px",
                    marginTop: "5px"
                  }}
                >
                  <DiscountTypeSelect
                    fieldValue={settings?.discountSettings?.discountType}
                    onChange={(value) => {
                      updateSetting("discountSettings", {
                        ...settings?.discountSettings,
                        discountType: value
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box mt="20px">
              <Typography component="span" fontSize="16px" fontWeight={600}>
                Show/Hide Columns
              </Typography>

              {Object.keys(settings?.extraColumnSettings).map((colName) => (
                <Box display="flex" alignItems="center" key={colName}>
                  <Checkbox
                    checked={settings?.extraColumnSettings[colName]}
                    disabled={colName === "department" && !departmentSubscription}
                    onChange={() => {
                      updateSetting("extraColumnSettings", {
                        ...settings?.extraColumnSettings,
                        [colName]: !settings?.extraColumnSettings[colName]
                      });
                    }}
                  />
                  <Typography>{startCase(colName)}</Typography>
                </Box>
              ))}
              {isCounterBillingEnabled && (
                <Box display="flex" alignItems="center" px={2}>
                  <Typography>Set Counter</Typography>
                  <Select
                    variant="outlined"
                    id="counterSelect"
                    value={currentCounter}
                    sx={{
                      marginLeft: "5px",
                      height: "40px",
                      width: "180px"
                    }}
                    error={!currentCounter}
                    onChange={(e) => {
                      setIsDirty(true);
                      setCurrentCounter(e.target.value);
                    }}
                  >
                    {Object.keys(BillCounters).map((option) => (
                      <MenuItem key={option} value={option}>
                        {BillCounters[option]}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            </Box>

            <Box mt="20px">
              <Typography>
                <Box component="span" fontSize="16px" fontWeight={600}>
                  Extra Referrer
                </Box>
              </Typography>
              <Box display="flex" alignItems="center" p={0}>
                <Checkbox
                  checked={extraReferrer}
                  onChange={() => {
                    setIsDirty(true);
                    toggleExtraReferrer(!extraReferrer);
                  }}
                />
                <Typography>Enable Extra Referrer</Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="16px">
            <Button
              disabled={!isDirty}
              variant="contained"
              color="primary"
              onClick={onSave}
              data-testmation="billingSettingsSaveButton"
            >
              {tl("billing.save")}
            </Button>
            <Button
              onClick={() => setIsSettingsOpen(false)}
              data-testmation="billingSettingCloseButton"
            >
              Close
            </Button>
          </Box>
        </Modal>
      </div>
    </Can>
  );
}

export default connect(null, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch)
}))(BillSettings);

import * as React from 'react';
import Modal from '../../../components/Modal/Modal';
import { connect } from 'react-redux';
import { serviceProviderActions } from '../../../actions/index';

import styles from './styles.module.css';
import { navigateRemoveModal } from '../../../actions/navigation';

interface propsType {
  serviceProviderId: number;
  serviceProvider: any;
  getServiceProvider: (id: number) => void;
  onClose: () => void;
}

class ServiceProviderShow extends React.Component<propsType> {
  componentDidMount() {
    this.props.getServiceProvider(this.props.serviceProviderId)
  }
  render() {
    if (!this.props.serviceProvider) return;
    return (
      <Modal open={true} onClose={this.props.onClose} title={this.props.serviceProvider.firstName}>
        
      </Modal>
    );
  }
}

export default connect(({ resources }, { serviceProviderId }) => {
  return ({
    serviceProvider: resources.resourceCentreServiceProviders.find(sp => sp.id === serviceProviderId)
  })
}, (dispatch) => ({
  getServiceProvider: (serviceProviderId) => dispatch(serviceProviderActions.getResourceCentreServiceProvider(serviceProviderId)),
  onClose: () => dispatch(navigateRemoveModal('ServiceProviderShow'))
}))(ServiceProviderShow);
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography
} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { Type, updateLabTestsRecord } from "../../../actions/labTest";
import * as NotificationActions from "../../../actions/notification";
import { authorizedSpDeletedErrorMessage } from "../../../helpers/messages";
import useDeletedSps from "../../../hooks/useDeletedSps";
import { LabRecord, LabStatuses, TestTypes } from "../../../interfaces/Lab";
import { RootState } from "../../../store";
import Can from "../../Policy/Can";
import { getAuthorizedSPId } from "../LabEntry";
import { LabRecordType } from "../index";
import { MicrobiologySettings, ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { AuthorizedSpIds } from "./LabSpecificSpSignature";
import hasOwnProperty from "../../../helpers/object";
import { useAppDispatch } from "../../../store/hooks";

export const getSpSignatureGroup = (
  labRecord: LabRecord,
  rc: ResourceCentre
): MicrobiologySettings => {
  if (labRecord.results?.data[0]?.category === "Microbiology") {
    return rc?.labSettings.microbiologySettings;
  }
  if (labRecord?.type === TestTypes.PATHOLOGY) {
    return rc?.labSettings.pathologySettings;
  }
  return rc?.labSettings.radiologySettings;
};

export function useAuthorizerHandler(labRecordData: LabRecord): AuthorizedSpIds {
  const rc = useSelector((state: RootState) => state.resources.resourceCentres[0]);

  const userContext = useSelector((state: RootState) => state.userContext);

  const user = userContext?.user;
  const userType = userContext?.userCreds?.authenticable;
  const spSignatureGroup = getSpSignatureGroup(labRecordData || {}, rc);

  const [authorizedSP1Id, authorizedSP2Id, authorizedSP3Id, authorizedSP4Id] = [
    spSignatureGroup?.authorizedSP1,
    spSignatureGroup?.authorizedSP2,
    spSignatureGroup?.authorizedSP3,
    spSignatureGroup?.authorizedSP4
  ];
  const [authorizedSP1Dynamic, authorizedSP2Dynamic, authorizedSP3Dynamic, authorizedSP4Dynamic] = [
    spSignatureGroup?.authorizedSP1Dynamic,
    spSignatureGroup?.authorizedSP2Dynamic,
    spSignatureGroup?.authorizedSP3Dynamic,
    spSignatureGroup?.authorizedSP4Dynamic
  ];

  const isServiceProvider = userType === "serviceProvider";

  const finalAuthorizedSP1 = isServiceProvider ? user.id : authorizedSP1Id;
  const finalAuthorizedSP2 = isServiceProvider ? user.id : authorizedSP2Id;
  const finalAuthorizedSP3 = isServiceProvider ? user.id : authorizedSP3Id;
  const finalAuthorizedSP4 = isServiceProvider ? user.id : authorizedSP4Id;

  const currentIdSP1 = labRecordData?.results?.approvedBy?.authorizedSP1?.id;
  const currentIdSP2 = labRecordData?.results?.approvedBy?.authorizedSP2?.id;
  const currentIdSP3 = labRecordData?.results?.approvedBy?.authorizedSP3?.id;
  const currentIdSP4 = labRecordData?.results?.approvedBy?.authorizedSP4?.id;

  return {
    authorizedSP1Dynamic,
    authorizedSP2Dynamic,
    authorizedSP3Dynamic,
    authorizedSP4Dynamic,
    finalAuthorizedSP1,
    finalAuthorizedSP2,
    finalAuthorizedSP3,
    finalAuthorizedSP4,
    currentIdSP1,
    currentIdSP2,
    currentIdSP3,
    currentIdSP4,
    authorizedSP1Id,
    authorizedSP2Id,
    authorizedSP3Id,
    authorizedSP4Id
  };
}

export default function EntryActions({
  authorizedServiceProviders,
  skipInternalStock,
  internalLabInfo,
  setSkipInternalStock,
  labRecordType
}: {
  skipInternalStock: boolean;
  internalLabInfo: string[];
  setSkipInternalStock: (val: boolean) => void;
  labRecordType: LabRecordType;
  authorizedServiceProviders: AuthorizedSpIds;
}): JSX.Element {
  const entryDraft = useSelector((state: RootState) => state.labRecords.entryDraft);
  const ongoing = useSelector((state: RootState) => state.ongoing);
  const [isSaving, setIsSaving] = React.useState(false);
  const {
    authorizedSP1Dynamic,
    authorizedSP2Dynamic,
    authorizedSP3Dynamic,
    authorizedSP4Dynamic,
    finalAuthorizedSP1,
    finalAuthorizedSP2,
    finalAuthorizedSP3,
    finalAuthorizedSP4,
    currentIdSP1,
    currentIdSP2,
    currentIdSP3,
    currentIdSP4,
    authorizedSP1Id,
    authorizedSP2Id,
    authorizedSP3Id,
    authorizedSP4Id
  } = authorizedServiceProviders;

  const dispatch = useAppDispatch();
  const deletedSps = useDeletedSps(entryDraft?.type);

  const isSmsSubscribed = useSelector(
    (state: RootState) => state.userContext.resourceCentre.subscriptions.features.sms.subscribed
  );
  const isStockSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.stock?.subscribed
  );
  const isInternalStockEnabled = useSelector(
    (state: RootState) => state.userContext.resourceCentre.labSettings?.enableInternalStock
  );
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [sendToClient, setSendToClient] = React.useState(true);

  const hasDeletedSps = () => {
    if (!deletedSps.length) {
      return false;
    }
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getTime(),
        message: `${authorizedSpDeletedErrorMessage(deletedSps)}`,
        autoTimeout: true,
        variant: "error"
      })
    );
    return true;
  };

  return (
    <Box
      borderTop="1px solid lightgrey"
      pt="8px"
      display="flex"
      justifyContent="space-between"
      pr={3}
    >
      <Box pl="16px">
        {isSmsSubscribed && (
          <FormControlLabel
            control={<Checkbox checked={sendToClient} />}
            label="Send Message To Client When Finalized"
            onChange={() => {
              setSendToClient((p) => !p);
            }}
          />
        )}
      </Box>
      <Box>
        {entryDraft?.status !== LabStatuses.RESULT_READY && (
          <Button
            data-testmation="saveAsDraft"
            variant="text"
            sx={{ marginRight: "16px" }}
            disabled={hasOwnProperty(ongoing, Type.UPDATE_LAB_TEST_RECORD)}
            onClick={() => {
              const { attachments, results } = entryDraft;
              if (hasDeletedSps()) {
                return;
              }
              dispatch(
                updateLabTestsRecord(
                  entryDraft.id,
                  {
                    skipInternalStock,
                    requestApproval: false,
                    final: false,
                    attachments,
                    updatedTestResults: results,
                    sendToClient,
                    authorizedSP1Id: getAuthorizedSPId({
                      currentId: currentIdSP1,
                      staticId: null,
                      dynamicState: authorizedSP1Dynamic,
                      enteredById: finalAuthorizedSP1,
                      assessedById: null,
                      approvedById: null
                    }),
                    authorizedSP2Id: getAuthorizedSPId({
                      currentId: currentIdSP2,
                      staticId: null,
                      dynamicState: authorizedSP2Dynamic,
                      enteredById: finalAuthorizedSP2,
                      assessedById: null,
                      approvedById: null
                    }),
                    authorizedSP3Id: getAuthorizedSPId({
                      currentId: currentIdSP3,
                      staticId: null,
                      dynamicState: authorizedSP3Dynamic,
                      enteredById: finalAuthorizedSP3,
                      assessedById: null,
                      approvedById: null
                    }),
                    authorizedSP4Id: getAuthorizedSPId({
                      currentId: currentIdSP4,
                      staticId: null,
                      dynamicState: authorizedSP4Dynamic,
                      enteredById: finalAuthorizedSP4,
                      assessedById: null,
                      approvedById: null
                    })
                  },
                  false,
                  labRecordType
                )
              );
            }}
          >
            Save as Draft
          </Button>
        )}
        {entryDraft?.status !== LabStatuses.RESULT_READY && (
          <Can policyAccessKey="lab:approve">
            <Button
              data-testmation="requestApproval"
              variant="text"
              sx={{ marginRight: "16px" }}
              disabled={hasOwnProperty(ongoing, Type.UPDATE_LAB_TEST_RECORD)}
              onClick={() => {
                const { attachments, results } = entryDraft;
                if (hasDeletedSps()) {
                  return;
                }
                dispatch(
                  updateLabTestsRecord(
                    entryDraft.id,
                    {
                      skipInternalStock,
                      requestApproval: true,
                      final: false,
                      attachments,
                      sendToClient,
                      updatedTestResults: results,
                      authorizedSP1Id: getAuthorizedSPId({
                        currentId: currentIdSP1,
                        staticId: null,
                        dynamicState: authorizedSP1Dynamic,
                        enteredById: finalAuthorizedSP1,
                        assessedById: finalAuthorizedSP1,
                        approvedById: null
                      }),
                      authorizedSP2Id: getAuthorizedSPId({
                        currentId: currentIdSP2,
                        staticId: null,
                        dynamicState: authorizedSP2Dynamic,
                        enteredById: finalAuthorizedSP2,
                        assessedById: finalAuthorizedSP2,
                        approvedById: null
                      }),
                      authorizedSP3Id: getAuthorizedSPId({
                        currentId: currentIdSP3,
                        staticId: null,
                        dynamicState: authorizedSP3Dynamic,
                        enteredById: finalAuthorizedSP3,
                        assessedById: finalAuthorizedSP3,
                        approvedById: null
                      }),
                      authorizedSP4Id: getAuthorizedSPId({
                        currentId: currentIdSP4,
                        staticId: null,
                        dynamicState: authorizedSP4Dynamic,
                        enteredById: finalAuthorizedSP4,
                        assessedById: finalAuthorizedSP4,
                        approvedById: null
                      })
                    },
                    false,
                    labRecordType
                  )
                );
              }}
            >
              Request Approval
            </Button>
          </Can>
        )}
        <Can policyAccessKey="lab:finalize">
          <Button
            variant="contained"
            data-testmation="finalizeLab"
            disabled={hasOwnProperty(ongoing, Type.UPDATE_LAB_TEST_RECORD)}
            onClick={() => {
              if (hasDeletedSps()) {
                return;
              }
              setOpenModal(true);
            }}
          >
            Finalize
          </Button>
        </Can>
      </Box>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to finalize the lab test? This action cannot be undone!
          </Typography>
          {internalLabInfo.length > 0 && (
            <Box mt={1}>
              <Typography fontWeight="bold" component="h2">
                Internal Stock Information:
              </Typography>
              {internalLabInfo.map((info, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography key={index} color="error">
                  {info}
                </Typography>
              ))}
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={skipInternalStock}
                  onChange={() => setSkipInternalStock(!skipInternalStock)}
                />
                <Typography>Skip process to decrease stock quantity.</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            data-testmation="finalizeLabTest"
            variant="contained"
            disabled={isSaving}
            onClick={async () => {
              setIsSaving(true);
              if (hasDeletedSps()) {
                return;
              }
              const { attachments, results } = entryDraft;
              await dispatch(
                updateLabTestsRecord(
                  entryDraft.id,
                  {
                    skipInternalStock:
                      !isStockSubscribed || !isInternalStockEnabled ? true : skipInternalStock,
                    requestApproval: false,
                    final: true,
                    attachments,
                    sendToClient,
                    updatedTestResults: results,
                    authorizedSP1Id: getAuthorizedSPId({
                      currentId: currentIdSP1,
                      staticId: authorizedSP1Id,
                      dynamicState: authorizedSP1Dynamic,
                      enteredById: finalAuthorizedSP1,
                      assessedById: finalAuthorizedSP1,
                      approvedById: finalAuthorizedSP1
                    }),
                    authorizedSP2Id: getAuthorizedSPId({
                      currentId: currentIdSP2,
                      staticId: authorizedSP2Id,
                      dynamicState: authorizedSP2Dynamic,
                      enteredById: finalAuthorizedSP2,
                      assessedById: finalAuthorizedSP2,
                      approvedById: finalAuthorizedSP2
                    }),
                    authorizedSP3Id: getAuthorizedSPId({
                      currentId: currentIdSP3,
                      staticId: authorizedSP3Id,
                      dynamicState: authorizedSP3Dynamic,
                      enteredById: finalAuthorizedSP3,
                      assessedById: finalAuthorizedSP3,
                      approvedById: finalAuthorizedSP3
                    }),
                    authorizedSP4Id: getAuthorizedSPId({
                      currentId: currentIdSP4,
                      staticId: authorizedSP4Id,
                      dynamicState: authorizedSP4Dynamic,
                      enteredById: finalAuthorizedSP4,
                      assessedById: finalAuthorizedSP4,
                      approvedById: finalAuthorizedSP4
                    })
                  },
                  false,
                  labRecordType
                )
              );
              setIsSaving(false);
              setOpenModal(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import { omit, startCase } from "lodash";
import * as React from "react";
import chunk from "../../../helpers/array";
import { IntraOralAdvancedData } from "../../Assessment/IntraOralAdvanced/IntraOralAdvancedForm";
import { Legend, legendMapId } from "../../Assessment/IntraOralAdvanced/TeethMarkerComponent";
import Teeth, { dentalIconsMappings } from "../../Assessment/IntraOralAdvanced/Tooth";

const intraOralSpecialFieldKeys = ["teethData", "bpe"];

export const hasContentAdvancedIntraOral = (data: IntraOralAdvancedData): boolean => {
  const hasContentRest = (d: IntraOralAdvancedData) =>
    Object.keys(d)
      .filter((k) => !intraOralSpecialFieldKeys.includes(k))
      .some((field) => !!data[field]);
  const hasContentTeeth = (d: IntraOralAdvancedData) =>
    d.teethData.some((toothData) =>
      Object.keys(toothData.tooth.v).some((part) => toothData.tooth.v[part].legendId)
    );
  const hasContentBpe = (d: IntraOralAdvancedData) => d.bpe.some((datum) => !!datum);
  return hasContentRest(data) || hasContentTeeth(data) || hasContentBpe(data);
};

export const AdvancedIntraOralLabelField = ({
  label,
  data
}: {
  label: string;
  data: string;
}): JSX.Element => (
  <Box>
    <Typography width="auto" fontWeight={600}>
      {label}:{" "}
      <Typography ml="8px" component="span">
        {data}
      </Typography>
    </Typography>
  </Box>
);

const PrintLegend = () => {
  const chunks = chunk(Object.entries(legendMapId), 4);
  return (
    <Box display="flex" width="100%">
      {chunks.map((chunkArr, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box flex={1} key={i}>
          {chunkArr.map(([id, { name }]) => (
            <Box key={id} display="flex">
              <img
                src={dentalIconsMappings.find((el) => el.mappedId === Number(id))?.path}
                alt="dentalIcon"
              />
              <Typography variant="subtitle2" ml="16px">
                {name}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default function AdvancedIntraOralContent({
  assessmentDocument,
  mode = "view",
  componentLabel = "Intra Oral Examination"
}: {
  assessmentDocument: { advancedIntraOral: IntraOralAdvancedData };
  mode: "view" | "print";
  componentLabel?: string;
}): JSX.Element {
  const [teethImageFirstRow, setTeethImageFirstRow] = React.useState(null);
  const [teethImageSecondRow, setTeethImageSecondRow] = React.useState(null);

  if (!assessmentDocument.advancedIntraOral) return null;

  return (
    <div style={{ pageBreakInside: "auto" }}>
      <Teeth
        setState={() => ({})}
        resetActiveRest={() => ({})}
        startIndex={0}
        endIndex={15}
        teeth={assessmentDocument.advancedIntraOral.teethData}
        hidden
        onLoad={(r) => {
          if (r) {
            setTeethImageFirstRow(r.getStage().toDataURL({ mimeType: "image/jpeg", quality: 1 }));
          }
        }}
      />
      <Teeth
        setState={() => ({})}
        resetActiveRest={() => ({})}
        startIndex={16}
        endIndex={31}
        teeth={assessmentDocument.advancedIntraOral.teethData}
        hidden
        onLoad={(r) => {
          if (r) {
            setTeethImageSecondRow(r.getStage().toDataURL({ mimeType: "image/jpeg", quality: 1 }));
          }
        }}
      />
      <Box>
        {assessmentDocument?.advancedIntraOral && (
          <Box pb={2}>
            {mode === "view" && (
              <Typography>
                <Box pt={3} fontWeight={600} component="span">
                  {componentLabel}:
                </Box>
              </Typography>
            )}
            <img
              src={teethImageFirstRow}
              width={mode === "view" ? "800px" : "740px"}
              alt="teeth-first-row"
            />
            <img
              src={teethImageSecondRow}
              width={mode === "view" ? "800px" : "740px"}
              alt="teeth-second-row"
              style={{ marginTop: "-16px" }}
            />
            {mode === "view" ? (
              <Box mt="-32px">
                <Legend item={null} updateActiveItem={() => ({})} mini />
              </Box>
            ) : (
              <PrintLegend />
            )}
            <Box mt="16px">
              {Object.keys(
                omit(assessmentDocument.advancedIntraOral, intraOralSpecialFieldKeys)
              ).map((field) =>
                assessmentDocument.advancedIntraOral[field] ? (
                  <Box borderBottom={mode === "view" ? "1px solid lightgrey" : "none"} key={field}>
                    <AdvancedIntraOralLabelField
                      data={assessmentDocument.advancedIntraOral[field] || "-"}
                      label={startCase(field)}
                    />
                  </Box>
                ) : null
              )}
            </Box>
            {assessmentDocument.advancedIntraOral.bpe.some((el) => !!el) && (
              <Box sx={{ pageBreakInside: "avoid" }}>
                <Typography variant="subtitle1" fontSize="12px">
                  BPE
                </Typography>
                <Box display="flex" width="100%">
                  <Box flex={1} sx={{ border: "1px solid lightgrey" }}>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)">
                      {assessmentDocument.advancedIntraOral.bpe.slice(0, 6).map((el, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box flex={1} key={i} sx={{ border: "1px solid lightgrey" }}>
                          <Typography textAlign="center" variant="subtitle1" fontSize="12px">
                            {el || <span>&zwnj;</span>}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box flex={1} sx={{ border: "1px solid lightgrey" }}>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)">
                      {assessmentDocument.advancedIntraOral.bpe.slice(6, 12).map((el, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box flex={1} key={i + 6} sx={{ border: "1px solid lightgrey" }}>
                          <Typography textAlign="center" variant="subtitle1" fontSize="12px">
                            {el || <span>&zwnj;</span>}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box flex={1} sx={{ border: "1px solid lightgrey" }}>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)">
                      {assessmentDocument.advancedIntraOral.bpe.slice(12).map((el, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box flex={1} key={i + 12} sx={{ border: "1px solid lightgrey" }}>
                          <Typography textAlign="center" variant="subtitle1" fontSize="12px">
                            {el || <span>&zwnj;</span>}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
}

import * as React from "react";
import useCan from "../../hooks/useCan";
import { CanActions, OptActions } from "../../interfaces/policy";

interface CanProps {
  children: React.ReactNode | any;
  policyAccessKey: CanActions | "allow" | Array<CanActions> | "deny" | OptActions;
  /* partialCheck works on Array<CanActions>,
  it shows the component if only one of the accesskey matches */
  partialCheck?: boolean;
  superAdminPass?: boolean;
  /* superAdminpass works when userGroup === 'superAdmin' | 'supportAdmin',
   it shows the component regardless of the accessKey */
  superAdminPassReverse?: boolean;
  /* superAdminPassReverse is exactly opposite of superAdminPass,
   it hides the component for superAdmin regardless of accessKey */
  restrictSupportAdmin?: boolean;
  /* restrictSupportAdmin restricts the component to support admin but not superadmin */
  supportAccountAdminPass?: boolean;
  //  supportAccountAdmin is restricted by default for all policies,
  // this pass allows access to supportAccountAdmin
  superAdminAgentPass?: boolean;
  // allows access to userGroups => superAdmin, supportAdmin and superAdminAgent
  superAdminAgentPassReverse?: boolean;
  // denies access to superAdminAgent

  // okhati admin to access public related features only
  publicAdminPass?: boolean;
  publicAdminPassReverse?: boolean;
}

function Can(props: CanProps): JSX.Element {
  const {
    children,
    policyAccessKey,
    partialCheck,
    superAdminPass,
    superAdminPassReverse,
    restrictSupportAdmin,
    supportAccountAdminPass,
    superAdminAgentPass,
    superAdminAgentPassReverse,
    publicAdminPass,
    publicAdminPassReverse
  } = props;

  const canAccess = useCan(policyAccessKey, {
    partialCheck,
    superAdminPass,
    superAdminPassReverse,
    restrictSupportAdmin,
    supportAccountAdminPass,
    superAdminAgentPass,
    superAdminAgentPassReverse,
    publicAdminPass,
    publicAdminPassReverse
  });

  if (canAccess) return children;
  return null;
}

export default Can;

Can.defaultProps = {
  partialCheck: false,
  superAdminPass: false,
  superAdminPassReverse: false,
  restrictSupportAdmin: false,
  supportAccountAdminPass: false,
  superAdminAgentPass: false,
  superAdminAgentPassReverse: false
};

import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Button,
  Card,
  Badge,
  Avatar,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMore } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import CancelIcon from "@mui/icons-material/DeleteOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UndoIcon from "@mui/icons-material/Undo";
import MessageIcon from "@mui/icons-material/Message";
import ScheduleIcon from "@mui/icons-material/Schedule";
import RadioIcon from "@mui/icons-material/Radio";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import VideocamIcon from "@mui/icons-material/Videocam";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ClearIcon from "@mui/icons-material/Clear";
import moment, { Moment } from "moment";
import NotesIcon from "@mui/icons-material/Notes";
import EmailIcon from "@mui/icons-material/Email";
import AddBoxIcon from "@mui/icons-material/AddBox";
import produce from "immer";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { tl, t } from "../../../components/translate";
import ActionBar from "../../../components/ActionBar/ActionBar";
import BookingStatus from "./BookingStatus";
import FollowUp from "./FollowUp";
import IconWithField from "../../../components/IconWithField";
import CreateEditHeader from "./CreateEdtiHeader";
import styles from "./styles.module.css";
import * as bookingActions from "../../../actions/booking";
import { showDialog, navigateRemoveModal } from "../../../actions/navigation";
import { isEmail, validate } from "../../../helpers/validators";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import ClientSearchAndCreate from "./ClientSearchAndCreate";
import { getSPSpecificServices } from "../../../reducers/services";
import { serviceProvidersSortedSelector } from "../../../reducers/serviceProvider";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import { clientFullNameSelector } from "../../../reducers/client";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../../Client/ClientCreateEdit";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { IThunkDispatch, RootState } from "../../../store";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../../mixpanel-analytics/event";
import DepartmentSelect from "../../../components/DepartmentSelect";
import useDepartmentServiceProviders from "../../../hooks/useDepartmentServiceProviders";
import KnownUsFromSelect from "../../../components/KnownUsFromSelect/KnownUsFromSelect";
import ServicesSelectWithSpAssign from "./ServicesSelectWithSpAssign";

const useStyles = makeStyles({
  helperText: {
    fontSize: "0.625rem",
    marginTop: "4px"
  },
  chip: {
    height: "22px",
    margin: "0px 3px 2px 0px"
  }
});

interface BookingCreateEditProps {
  booking: Booking;
  onClose: () => void;
  onDelete: (bookingId, onClose) => void;
  onChange: (eventData) => void;
  onShowBookingHistory: () => void;
  onSave: (booking: Partial<Booking>) => void;
  resourceCentreId: number;
  serviceProvider: {
    firstName: string;
    lastName: string;
    speciality: string;
    title: string;
  };
  resourceCentre: ResourceCentre;
  services: Array<ProductInterface>;
  onTabChange: (tab) => void;
  events: [];
  serviceProviders: Array<ServiceProvider>;
}

const BookingCreateEdit: React.FC<BookingCreateEditProps> = (props) => {
  const {
    booking,
    onClose,
    onDelete,
    onShowBookingHistory,
    onSave,
    onChange,
    onTabChange,
    serviceProvider,
    resourceCentre,
    services,
    events,
    serviceProviders
  } = props;
  const requireKnownUsFrom = resourceCentre?.settings?.formSettings?.requireKnownUsFrom;
  const [bookingState, setBookingState] = React.useState<Partial<Booking>>(booking);
  const [editMode, setEditMode] = React.useState(false);
  const [clientEmail, setClientEmail] = React.useState("");
  const [knownUsFromValue, setKnownUsFromValue] = React.useState(
    bookingState?.client?.knownUsFrom || ""
  );
  const [bookingServiceOptions, setBookingServiceOptions] = React.useState([]);
  const [error, setError] = React.useState<{
    emailError: { isValid: boolean; message: string } | null;
    knownUsFromError: { isValid: boolean; message: string } | null;
  }>({
    emailError: null,
    knownUsFromError: {
      isValid: !requireKnownUsFrom || (requireKnownUsFrom && !!knownUsFromValue?.length),
      message: requireKnownUsFrom && !!knownUsFromValue?.length ? "Known us from is required" : ""
    }
  });
  const [videoServices, setVideoServices] = React.useState([]);
  const classes = useStyles();
  const [currentClient, setCurrentClient] = React.useState({
    id: null,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    sendEmail: false,
    knownUsFrom: "",
    internalNotes: ""
  });
  const spSpecificServices = React.useMemo(
    () => getSPSpecificServices(bookingState.serviceProviderId, services),
    [bookingState.serviceProviderId, services]
  );
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [selectedSpDepartmentId, setSelectedSpDepartmentId] = React.useState(null);
  React.useEffect(() => {
    if (booking) {
      setBookingState({
        id: booking.id ? booking.id : null,
        start: booking.start,
        end: booking.end,
        clientId: booking.clientId ? booking.clientId : null,
        departmentId: booking.departmentId || null,
        clientName: booking.clientName,
        clientPhoneNo: booking.clientPhoneNo,
        clientEmail: booking.clientEmail,
        clientGender: booking.clientGender,
        clientAddress: booking.clientAddress,
        clientInternalNotes: booking.client?.internalNotes || "",
        clientKnownFrom: booking.client?.knownUsFrom || "",
        services: booking.services,
        isVideoAppointment: booking.isVideoAppointment,
        followUp: booking.followUp,
        remarks: booking.remarks,
        reasonOfVisit: booking.reasonOfVisit,
        reminders: booking.reminders,
        resourceCentreId: booking.resourceCentreId,
        serviceProviderId: booking.serviceProviderId,
        resourceId: booking.resourceId,
        status: booking.status,
        bookingHistory: booking.bookingHistory,
        paymentInfo: booking.paymentInfo,
        throughPublic: booking.throughPublic,
        referredBy: booking.referredBy,
        knownFrom: booking.knownFrom,
        bookableResource: booking.bookableResource,
        sendToSp: booking.sendToSp,
        sendToClient: booking.sendToClient
      });
      setCurrentClient(
        bookingState.clientId
          ? {
              id: bookingState.clientId,
              firstName: bookingState.clientName,
              lastName: "",
              phone: bookingState.clientPhoneNo,
              email: bookingState.clientEmail,
              sendEmail: true,
              knownUsFrom: bookingState.client?.knownUsFrom || "",
              internalNotes: bookingState.client?.internalNotes || ""
            }
          : {
              id: null,
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              sendEmail: false,
              knownUsFrom: "",
              internalNotes: ""
            }
      );
      if (booking.id) {
        setEditMode(true);
      } else {
        setEditMode(false);
      }
      if (bookingState.client?.knownUsFrom) setKnownUsFromValue(bookingState.client.knownUsFrom);
    }
  }, [booking]);

  React.useEffect(() => {
    if (!editMode) {
      setBookingState({
        ...bookingState,
        clientId: currentClient.id,
        clientName: `${currentClient.firstName} ${currentClient.lastName}`,
        clientPhoneNo: currentClient.phone,
        clientKnownFrom: currentClient.knownUsFrom,
        clientInternalNotes: currentClient.internalNotes
      });
      // When client is in create mode there is no current client
      // Only require known us from during client creation
      if (requireKnownUsFrom && !currentClient.knownUsFrom && !currentClient) {
        setError((prevState) => ({
          ...prevState,
          knownUsFromError: { isValid: false, message: "Known us from is required" }
        }));
      } else {
        setKnownUsFromValue(currentClient.knownUsFrom || "");
        setError((prevState) => ({ ...prevState, knownUsFromError: null }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  React.useEffect(() => {
    if (booking.client) {
      setBookingState({
        ...bookingState,
        clientName: `${booking?.client?.firstName} ${booking?.client?.lastName}`,
        clientPhoneNo: booking?.client?.phone,
        clientInternalNotes: booking?.client?.internalNotes,
        clientKnownFrom: booking?.client?.knownUsFrom
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.client, currentClient]);

  React.useEffect(() => {
    if (bookingState.services && bookingState.services.length > 0) {
      const fullServices = [];
      bookingState.services.forEach((bS) => {
        spSpecificServices.forEach((service) => {
          if (service.id === bS) {
            fullServices.push(service);
          }
        });
      });
      setBookingServiceOptions(fullServices);
    } else {
      setBookingServiceOptions([]);
    }
  }, [bookingState.services, spSpecificServices]);

  React.useEffect(() => {
    if (spSpecificServices && bookingState.isVideoAppointment) {
      const filteredVideoServices = spSpecificServices.filter(
        (service) => service.category === "video"
      );
      if (bookingState.services?.length > 0) {
        const selectedVideoServices = [];
        bookingState.services.forEach((bS) => {
          filteredVideoServices.forEach((service) => {
            if (service.id === bS) {
              selectedVideoServices.push(service);
            }
          });
        });
        setBookingServiceOptions(selectedVideoServices);
        setBookingState({
          ...bookingState,
          services: selectedVideoServices.map((service) => service.id)
        });
      }
      setVideoServices(filteredVideoServices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spSpecificServices, bookingState.isVideoAppointment]);

  const getDefaultReminders = (targetDate) => {
    const remindOn = targetDate.subtract(3, "day").toDate();
    return [
      {
        on: remindOn,
        remark: ""
      }
    ];
  };

  let actions = [];
  if (booking.id) {
    actions = actions.concat([
      {
        label: tl("booking.activitiesHistory"),
        action: () => onShowBookingHistory(),
        Icon: AssignmentIcon,
        dataTestmation: "activitiesHistory"
      },
      {
        label: tl("booking.cancelBooking"),
        action: () => {
          mixpanelAnalytics.track(EVENT.BOOKING_STATUS_CANCELED, { rcId: resourceCentre.id });
          onDelete(bookingState.id, onClose);
        },
        Icon: CancelIcon,
        dataTestmation: "cancelBooking"
      }
    ]);
  }

  const [isLoading, setIsLoading] = React.useState(false);

  const isValidData = () => {
    let isValid = true;
    if (error) {
      Object.keys(error).forEach((key) => {
        if (error[key]) {
          if (!error[key].isValid) {
            isValid = false;
          }
        }
      });
    }
    if (
      !(bookingState.clientName && (bookingState.clientId ? true : bookingState.clientGender)) ||
      (resourceCentre?.settings?.formSettings?.requirePhoneNumber &&
        !(currentClient.phone || bookingState.clientPhoneNo || bookingState.client?.phone)) ||
      (resourceCentre?.settings?.formSettings?.requireEmail &&
        !(currentClient.email || bookingState.clientEmail || bookingState.client?.email)) ||
      (bookingState.isVideoAppointment && bookingState.services.length === 0) ||
      (resourceCentre?.settings?.formSettings?.requireReferrer && !bookingState.referrerId)
    ) {
      isValid = false;
    }
    return isValid;
  };

  const formSettingsEmail = resourceCentre?.settings?.formSettings?.requireEmail;

  const isSmsSubscribed = resourceCentre.subscriptions.features.sms.subscribed;

  const askForEmail = () => {
    let shouldAsk = false;
    if (!editMode && currentClient.id) {
      if (!currentClient.email) {
        shouldAsk = true;
      } else if (currentClient.email && !currentClient.sendEmail) {
        shouldAsk = true;
      }
    }
    return shouldAsk;
  };

  const getEmailErrorMsg = () => {
    if (formSettingsEmail && !clientEmail.length) return "Email is required";
    if (error?.emailError && clientEmail.length) {
      return error?.emailError?.messages[0];
    }
    if (!currentClient.sendEmail && formSettingsEmail) {
      return tl("booking.bouncedEmail");
    }
    return "";
  };

  const roundMinutes = (minutes, duration) => Math.round(minutes / duration) * duration;

  const getScheduleDuration = (evs) => {
    const scheduleDuration = evs.find((ev) => {
      if (ev.type === "schedule") {
        return (
          moment(ev.start).isSameOrBefore(bookingState.start) &&
          moment(ev.end).isAfter(bookingState.start)
        );
      }
      return false;
    });
    if (scheduleDuration) {
      return scheduleDuration.duration;
    }
    return null;
  };

  const duration = getScheduleDuration(events);

  const endTimeReconciliation = () => {
    const difference = moment(bookingState.end).diff(bookingState.start, "minutes");
    if (duration) {
      if (difference < duration) {
        setBookingState({
          ...bookingState,
          end: new Date(moment(bookingState.start).add(duration, "minutes").toISOString())
        });
      }
    } else if (difference < 10) {
      setBookingState({
        ...bookingState,
        end: new Date(moment(bookingState.start).add(10, "minutes").toISOString())
      });
    }
  };
  React.useEffect(() => {
    const selectedSp = serviceProviders.find((sp) => sp.id === bookingState.serviceProviderId);
    if (selectedSp) {
      setSelectedSpDepartmentId(selectedSp.departmentId);
    }
  }, [bookingState.serviceProviderId, serviceProviders]);

  const departmentId =
    useSelector((state: RootState) => state.userContext.user.departmentId) || null;
  React.useEffect(() => {
    if (!editMode && !booking.id && bookingState.serviceProviderId) {
      if (departmentId) {
        setBookingState({
          ...bookingState,
          departmentId
        });
      } else {
        const selectedServiceProvider = serviceProviders.find(
          (sp) => sp.id === bookingState.serviceProviderId
        );
        setBookingState({
          ...bookingState,
          departmentId: selectedServiceProvider ? selectedServiceProvider.departmentId : null
        });
      }
    }
  }, [bookingState.serviceProviderId, editMode, serviceProviders, booking.id]);

  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [currentSelectedClientId, setCurrentSelectedClientId] = React.useState(null);

  const serviceProviderList = useDepartmentServiceProviders();

  return (
    <Box className={styles.bookingCreateEdit} p={3}>
      <Box className={styles.bookingCreateEditBodyContainer}>
        <Box pb={2} display="flex">
          {booking.id && <BookingStatus booking={booking} hideLabel />}
          {booking.id && (
            <Typography component="span" style={{ marginLeft: "16px", fontSize: "0.875rem" }}>
              <Box component="span" fontWeight="700">
                ID
              </Box>{" "}
              {booking.id}
            </Typography>
          )}
          {!booking.id && (
            <CreateEditHeader
              activeTab="booking"
              onChange={(e, tab) => {
                if (tab === "schedule") {
                  onTabChange("schedule");
                }
              }}
            />
          )}
          <Box flexGrow={1} textAlign="right">
            <ActionBar actions={actions} onClose={onClose} />
          </Box>
        </Box>

        {bookingState.bookableResource && (
          <Box mb="16px" pl="4px">
            <IconWithField LabelIcon={<AddBoxIcon />}>
              <Typography style={{ fontSize: "14px", paddingLeft: "2px" }}>
                {bookingState.bookableResource.name}
              </Typography>
            </IconWithField>
          </Box>
        )}
        <Box
          sx={{
            height: "420px",
            overflowY: "auto"
          }}
        >
          <Box display="flex" alignItems="center" height="30px" py={3}>
            <Box pr={2}>
              <Avatar
                style={{ height: "30px", width: "30px" }}
                alt={serviceProvider && serviceProvider.firstName}
                src="https://picsum.photos/100/100/?random"
              />
            </Box>

            <Box width="200px">
              <Autocomplete
                options={serviceProviderList}
                getOptionLabel={(option) =>
                  option.title
                    ? `${
                        option.title.charAt(0).toUpperCase() + option.title.slice(1)
                      } ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`
                    : ` ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`
                }
                placeholder="Assign service provider"
                fullWidth
                value={
                  serviceProviderList.find((sp) => sp.id === bookingState.serviceProviderId) || null
                }
                onChange={(e, v) => {
                  if (typeof v === "string") return;
                  setBookingState({ ...bookingState, serviceProvider: v, serviceProviderId: v.id });
                }}
                disableClearable
                popupIcon={<ExpandMore style={{ color: "black" }} />}
                renderOption={(params, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...params} key={option.id} style={{ fontSize: "12px" }}>
                    {option.title
                      ? `${
                          option.title.charAt(0).toUpperCase() + option.title.slice(1)
                        } ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`
                      : ` ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    margin="dense"
                    data-testmation="serviceProviderSelectTextfield"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      style: {
                        fontSize: "12px"
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Box width="180px" ml={2}>
              <DepartmentSelect
                fullWidth
                onChange={(department) => {
                  setBookingState({ ...bookingState, departmentId: department.id });
                }}
                id={departmentId && !editMode ? departmentId : bookingState.departmentId}
                disabled={Boolean(selectedSpDepartmentId || departmentId)}
                isBookingWindow
                placeholderText="Select department"
              />
            </Box>
          </Box>
          <Box>
            <IconWithField LabelIcon={<ScheduleIcon />}>
              <Box component="div" display="flex">
                <CalendarDropdown
                  width="70px"
                  disable={false}
                  TextFieldProps={{
                    fullWidth: true,
                    margin: "dense"
                  }}
                  minValidDate={moment()}
                  date={new Date(bookingState.start)}
                  onChange={(newDate) => {
                    const [startHour, startMinute] = moment(new Date(bookingState.start))
                      .format("HH mm")
                      .split(" ")
                      .map(Number);
                    let startDate: Moment | Date = moment(newDate).set("hour", startHour);
                    startDate = startDate.set("minute", startMinute);
                    const [endHour, endMinute] = moment(new Date(bookingState.end))
                      .format("HH mm")
                      .split(" ")
                      .map(Number);
                    let endDate: Moment | Date = moment(newDate).set("hour", endHour);
                    endDate = endDate.set("minute", endMinute);
                    setBookingState({
                      ...bookingState,
                      start: new Date(startDate.toISOString()),
                      end: new Date(endDate.toISOString())
                    });
                  }}
                />
                <Box display="flex" alignItems="center">
                  <TextField
                    data-testmation="scheduleStartDate"
                    id="time"
                    type="time"
                    margin="dense"
                    size="small"
                    value={moment(bookingState.start).format("HH:mm")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 600 // 5 min
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "12px"
                      }
                    }}
                    onBlur={() => {
                      if (duration) {
                        endTimeReconciliation();
                      }
                    }}
                    onChange={({ target }) => {
                      const [hour, minute] = target.value.split(":").map(Number);
                      const roundedMinute = roundMinutes(minute, duration || 5);
                      let date: Moment | Date = moment(bookingState.start).set("hour", hour);
                      date = date.set("minute", roundedMinute || 0);
                      const difference = moment(bookingState.end).diff(
                        bookingState.start,
                        "minutes"
                      );
                      const endDate = new Date(
                        moment(date).add(difference, "minutes").toISOString()
                      );
                      date = new Date(moment(date).toISOString());
                      setBookingState({ ...bookingState, start: date, end: endDate });
                      onChange({ ...bookingState, start: date });
                    }}
                  />
                  <Typography style={{ marginLeft: "8px", marginRight: "8px" }}>-</Typography>
                  <TextField
                    data-testmation="scheduleEndDate"
                    id="time"
                    type="time"
                    margin="dense"
                    size="small"
                    value={moment(bookingState.end).format("HH:mm")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 600,
                      min: `${moment(bookingState.start).format("HH:mm")}`
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "12px"
                      }
                    }}
                    onBlur={() => {
                      endTimeReconciliation();
                    }}
                    onChange={({ target }) => {
                      const [hour, minute] = target.value.split(":").map(Number);
                      const roundedMinute = roundMinutes(minute, duration || 5);
                      let date: Moment | Date = moment(bookingState.end).set("hour", hour);
                      date = date.set("minute", roundedMinute || 0);
                      date = new Date(moment(date).toISOString());
                      setBookingState({ ...bookingState, end: date });
                      onChange({ ...bookingState, start: date });
                    }}
                  />
                </Box>
              </Box>
            </IconWithField>
          </Box>

          <Box mt="16px">
            {currentClient.id && !editMode ? (
              <Card style={{ padding: "8px" }} variant="outlined">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Box display="flex" alignItems="center" height="18px" mb="8px">
                      <Box pr={1} display="flex" alignItems="center">
                        <PersonIcon />
                      </Box>
                      <Box
                        fontWeight={600}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontSize: "12px"
                        }}
                        onClick={() => {
                          setCurrentSelectedClientId(currentClient.id);
                          setShowClientInfoPanel(true);
                        }}
                      >
                        {`${currentClient.firstName} ${currentClient.lastName}`.toUpperCase()}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" height="18px">
                      <Box pr={1} display="flex" alignItems="center">
                        <PhoneIcon />
                      </Box>
                      <Box fontSize={12}>{currentClient.phone}</Box>
                    </Box>
                  </Box>

                  <Box
                    data-testmation="clearIcon"
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      await setCurrentClient({
                        id: null,
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        sendEmail: false,
                        knownUsFrom: "",
                        internalNotes: ""
                      });
                      setClientEmail("");
                      setError({ ...error, emailError: null });
                    }}
                  >
                    <ClearIcon />
                  </Box>
                </Box>
              </Card>
            ) : (
              <>
                {editMode ? (
                  <>
                    <IconWithField LabelIcon={<PersonIcon />}>
                      <Typography
                        sx={{
                          textDecoration: `${booking.client?.active ? "underline" : ""}`,
                          cursor: "pointer"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (booking.client.active) {
                            setCurrentSelectedClientId(booking.client.id);
                            setShowClientInfoPanel(true);
                          }
                        }}
                      >
                        {booking.client &&
                          `${clientFullNameSelector(booking.client).toUpperCase()}
                          ${!booking.client.active ? "(deleted)" : ""}`}
                      </Typography>
                    </IconWithField>
                    <IconWithField LabelIcon={<PhoneIcon />}>
                      <Typography style={{ fontSize: "12px" }}>{booking?.client?.phone}</Typography>
                    </IconWithField>
                  </>
                ) : (
                  <ClientSearchAndCreate
                    currentClient={currentClient}
                    setCurrentClient={setCurrentClient}
                    bookingState={bookingState}
                    setBookingState={setBookingState}
                    error={error}
                    setError={setError}
                    formSettingsEmail={formSettingsEmail}
                  />
                )}
              </>
            )}
          </Box>
          {askForEmail() && (
            <Box mt={currentClient.id ? 1 : 0}>
              <IconWithField
                LabelIcon={
                  <Badge
                    variant="dot"
                    color="error"
                    invisible={!resourceCentre?.settings?.formSettings?.requireEmail}
                  >
                    <EmailIcon />
                  </Badge>
                }
                style={{ display: "flex", minHeight: "16px", marginBottom: "-10px" }}
                iconStyle={{ paddingTop: "8px" }}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  size="small"
                  data-testmation="clientEmailField"
                  placeholder={formSettingsEmail ? "Customer email* (required)" : "Customer email"}
                  value={clientEmail}
                  FormHelperTextProps={{ classes: { root: classes.helperText } }}
                  error={
                    (formSettingsEmail && !clientEmail.length) ||
                    (error?.emailError ? !error.emailError.isValid : false)
                  }
                  helperText={getEmailErrorMsg()}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: 0,
                      fontSize: "12px"
                    }
                  }}
                  onChange={(e) => {
                    setClientEmail(e.target.value);
                    if (e.target.value?.length) {
                      const emailError = validate(e.target.value, [
                        isEmail({ msg: "Email address invalid" })
                      ]);
                      setError({ ...error, emailError });
                    } else {
                      setError({ ...error, emailError: null });
                    }
                  }}
                  onBlur={async () => {
                    await setBookingState({
                      ...bookingState,
                      clientEmail
                    });
                  }}
                />
              </IconWithField>
            </Box>
          )}

          {resourceCentre?.subscriptions?.features?.video?.subscribed && !!serviceProvider && (
            <>
              <Box paddingTop="16px" />
              <IconWithField LabelIcon={<VideocamIcon />}>
                <Typography style={{ fontSize: "12px" }}>
                  {tl("booking.isVideoAppointment")}
                </Typography>
                <Checkbox
                  data-testmation="isVideoAppointment"
                  checked={bookingState.isVideoAppointment}
                  onChange={() => {
                    setBookingState({
                      ...bookingState,
                      isVideoAppointment: !bookingState.isVideoAppointment
                    });
                  }}
                />
              </IconWithField>
            </>
          )}

          <Box mt="8px">
            {!!serviceProvider && (
              <IconWithField
                LabelIcon={<CardGiftcardIcon />}
                style={{
                  display: "flex",
                  marginBottom: "0px",
                  minHeight: "18px"
                }}
                iconStyle={{
                  paddingTop: "8px"
                }}
              >
                <Box display="block" minWidth="50%">
                  <ServicesSelectWithSpAssign
                    value={bookingServiceOptions}
                    spSpecificServices={
                      bookingState.isVideoAppointment ? videoServices : spSpecificServices
                    }
                    assignableServices={services.filter((item) => {
                      const isAlreadyAssigned = (item.document.rates || []).some(
                        (rate) => rate.serviceProviderId === bookingState.serviceProviderId
                      );
                      return (
                        item.productType === "single" &&
                        !isAlreadyAssigned &&
                        !item.serviceProviderId
                      );
                    })}
                    selectedSP={
                      bookingState.serviceProvider ||
                      serviceProviders.filter(
                        (item) => item.id === bookingState.serviceProviderId
                      )[0]
                    }
                    selectedServicesId={bookingState.services || []}
                    onChange={(value) => {
                      let paymentAmount = 0;
                      const serviceIds = value.map((service) => {
                        if (Number(service.grossTotalPrice))
                          paymentAmount += Number(service.grossTotalPrice);
                        return service.id;
                      });
                      setBookingState({
                        ...bookingState,
                        services: serviceIds,
                        paymentInfo: { ...bookingState.paymentInfo, amount: paymentAmount }
                      });
                    }}
                    afterSpAssignSave={({ serviceIds, paymentAmount }) => {
                      setBookingState({
                        ...bookingState,
                        services: serviceIds,
                        paymentInfo: {
                          ...bookingState.paymentInfo,
                          amount: paymentAmount
                        }
                      });
                    }}
                    error={{
                      value: Boolean(
                        bookingState.isVideoAppointment && !bookingState.services?.length
                      ),
                      message:
                        bookingState.isVideoAppointment && !bookingState.services?.length
                          ? "Minimum one video service required"
                          : ""
                    }}
                    isBookingWindow
                  />
                </Box>
                {bookingState.isVideoAppointment && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignSelf="flex-start"
                    alignItems="center"
                    ml={1}
                    height="35px"
                  >
                    <Typography style={{ fontSize: "12px" }}>{tl("booking.payLater")}</Typography>
                    <Checkbox
                      checked={!bookingState.paymentInfo.required}
                      onChange={() => {
                        setBookingState({
                          ...bookingState,
                          paymentInfo: {
                            ...bookingState.paymentInfo,
                            required: !bookingState.paymentInfo.required
                          }
                        });
                      }}
                    />
                  </Box>
                )}
              </IconWithField>
            )}
            <IconWithField
              LabelIcon={<NotesIcon />}
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                data-testmation="bookingRemarks"
                multiline
                fullWidth
                maxRows={4}
                value={bookingState.remarks}
                placeholder={t("booking.note")}
                margin="dense"
                size="small"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: 0,
                    fontSize: "12px"
                  }
                }}
                onChange={(e) => {
                  if (e.target.value) {
                    return setBookingState({ ...bookingState, remarks: e.target.value });
                  }
                  return setBookingState({ ...bookingState, remarks: "" });
                }}
              />
            </IconWithField>
            <IconWithField
              LabelIcon={<UndoIcon />}
              style={{
                display: "flex",
                minHeight: "24px",
                alignItems: "center"
              }}
              iconStyle={{
                alignItems: "flex-start"
              }}
            >
              <Box flexGrow={0.6}>
                <ReferrerCreateSelect
                  referrerId={bookingState.referrerId}
                  useSettings
                  onReferrerChange={(referrer) => {
                    if (referrer) {
                      setBookingState({
                        ...bookingState,
                        referredBy: referrer.referrer,
                        referrerId: referrer.id
                      });
                    }
                  }}
                  isBookingWindow
                />
              </Box>
            </IconWithField>
            <Box pt="16px">
              <IconWithField
                LabelIcon={<EventNoteIcon />}
                style={{
                  display: "flex",
                  minHeight: "24px"
                }}
                iconStyle={{
                  alignItems: "flex-start"
                }}
              >
                <FollowUp
                  value={bookingState.followUp}
                  editable={!editMode}
                  reminders={bookingState.reminders}
                  maxValidDate={moment(bookingState.start)}
                  defaultDate={moment(bookingState.start).add(1, "month").toDate()}
                  onChange={(followUp, reminders) => {
                    let tempReminders = reminders;
                    if (followUp && (!reminders || reminders.length === 0)) {
                      tempReminders = getDefaultReminders(moment(bookingState.start));
                    }
                    setBookingState({ ...bookingState, followUp, reminders: tempReminders });
                  }}
                />
              </IconWithField>
            </Box>

            <IconWithField
              LabelIcon={<RadioIcon />}
              style={{
                display: "flex",
                minHeight: "24px",
                alignItems: "center"
              }}
              iconStyle={{
                alignItems: "flex-start"
              }}
            >
              <KnownUsFromSelect
                required={requireKnownUsFrom && !bookingState.clientId}
                error={{
                  value:
                    !bookingState.clientId &&
                    ((requireKnownUsFrom && !knownUsFromValue.length) ||
                      (error?.knownUsFromError ? !error.knownUsFromError.isValid : false)),
                  message: error?.knownUsFromError?.message || ""
                }}
                value={knownUsFromValue}
                width="200px"
                variant="standard"
                onChange={(value) => {
                  setKnownUsFromValue(value);
                  if (requireKnownUsFrom && !bookingState.clientId && !value.length) {
                    setError((prevState) => ({
                      ...prevState,
                      knownUsFromError: { isValid: false, message: "Known us from is required" }
                    }));
                  } else {
                    setBookingState({ ...bookingState, clientKnownFrom: value });
                    setError((prevState) => ({ ...prevState, knownUsFromError: null }));
                  }
                }}
                isBookingWindow
              />
            </IconWithField>

            <IconWithField
              LabelIcon={<DescriptionIcon />}
              style={{
                display: "flex",
                minHeight: "24px",
                alignItems: "center"
              }}
              iconStyle={{
                alignItems: "flex-start"
              }}
            >
              <TextField
                data-testmation="clientNotes"
                fullWidth
                multiline
                value={bookingState.clientInternalNotes}
                placeholder="Client Specific Notes"
                margin="dense"
                size="small"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: 0,
                    fontSize: "12px"
                  }
                }}
                onChange={(e) => {
                  setBookingState({ ...bookingState, clientInternalNotes: e.target.value });
                }}
              />
            </IconWithField>

            {isSmsSubscribed && (
              <IconWithField
                LabelIcon={<MessageIcon />}
                style={{
                  display: "flex",
                  minHeight: "24px",
                  alignItems: "center"
                }}
                iconStyle={{
                  alignItems: "flex-start"
                }}
              >
                <Typography fontSize="12px">Send SMS To: </Typography>
                <FormGroup row sx={{ ml: "16px" }}>
                  <FormControlLabel
                    control={<Checkbox checked={bookingState.sendToClient} />}
                    label="Client"
                    onChange={() => {
                      setBookingState({
                        ...bookingState,
                        sendToClient: !bookingState.sendToClient
                      });
                    }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px"
                      }
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={bookingState.sendToSp} />}
                    label="Doctor"
                    onChange={() => {
                      setBookingState({ ...bookingState, sendToSp: !bookingState.sendToSp });
                    }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px"
                      }
                    }}
                  />
                </FormGroup>
              </IconWithField>
            )}
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box />
        <Box>
          <Button
            color="primary"
            onClick={() => onClose()}
            style={{ paddingRight: "16px" }}
            data-testmation="bookingCancel"
          >
            {tl("booking.cancel")}
          </Button>
          <StatefulButton
            data-testmation={editMode ? "bookingUpdate" : "bookingBook"}
            variant="contained"
            color="primary"
            disabled={isLoading || !isValidData()}
            onClick={async () => {
              setIsLoading(true);
              const formattedData = produce(bookingState, (draft) => {
                draft.bookableResourceId = draft.bookableResource?.id || null;
                delete draft.bookableResource;
              });
              await onSave(formattedData);
              setIsLoading(false);
            }}
            isLoading={isLoading}
            circularProgressProps={{ size: 16 }}
          >
            <Typography>{editMode ? tl("booking.update") : tl("booking.book")}</Typography>
          </StatefulButton>
        </Box>
      </Box>
      {showClientInfoPanel &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={currentSelectedClientId}
            mode="edit"
            onCancel={() => {
              setClientEditMode(false);
            }}
            stayOnCurrentPage
            wrapperStyle={{ zIndex: "1300" }}
          />
        ) : (
          <ClientInfo
            setEditMode={setClientEditMode}
            id={currentSelectedClientId}
            handleViewClose={() => setShowClientInfoPanel(false)}
            stayOnCurrentPage
            wrapperStyle={{ zIndex: "1300" }}
          />
        ))}
    </Box>
  );
};

export default connect(
  (state: RootState, ownProps: { booking: Partial<Booking> }) => {
    const rcId = state.userContext.resourceCentreId;
    const spId = ownProps.booking.serviceProviderId;
    const resourceCentre =
      state.resources.resourceCentres?.find((rc) => rc.id === rcId) ||
      state.userContext.resourceCentre;
    const serviceProviderInArr = state.resources.resourceCentreServiceProviders.filter(
      (sp) => Number(sp.id) === Number(spId)
    );
    const serviceProvider = serviceProviderInArr[0];
    const services = state.services.collection.filter(({ active }) => active);
    return {
      rcId,
      serviceProvider,
      resourceCentre,
      services,
      serviceProviders: serviceProvidersSortedSelector(state as never)
    };
  },
  (dispatch: IThunkDispatch) => ({
    onDelete: (bookingId, onClose) => {
      dispatch(
        showDialog({
          title: tl("booking.cancel"),
          description: "Are you sure you want to cancel this booking?",
          next: async () => {
            await dispatch(bookingActions.cancelBooking(bookingId));
            dispatch(navigateRemoveModal("Dialog"));
            onClose();
          },
          onCancel: () => ({})
        })
      );
    }
  })
)(BookingCreateEdit);

import en from './en.json';
import np from './np.json';
import localStorage from '../app/helpers/localStorage';

const files = {
  np,
  en
};

export const translate = (key, language) => {
  const currentLanguage = language || localStorage.getItem('langauge') || 'en';
  return Object.prototype.hasOwnProperty.call(files[currentLanguage], key)
    ? files[currentLanguage][key]
    : `[${key}]`;
};

export const setLanguage = (language) => {
  localStorage.setItem('langauge', language);
};

export const getLanguage = () => localStorage.getItem('langauge');

import MoreIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, Grid, Popover, TextField, Typography } from "@mui/material";
import { push } from "connected-react-router";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import CancelIcon from "@mui/icons-material/HighlightOff";
import OkIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch } from "react-redux";
import EmptyBed from "../../../Svgicons/EmptyBed";
import OccupiedBed from "../../../Svgicons/OccupiedBed";
import { Bed, Ward, updateBed } from "../../../api/wards";
import Panel from "../../../components/Panel";
import WardBedSearch from "../../../components/WardBedSearch";
import { IPD, IpdStatus } from "../../../interfaces/IpdInterface";
import DischargeForm from "../Discharge/DischargeForm";
import CreateIpdAdmission from "../IpdAdmission/CreateIpdAdmission";
import IpdInfo from "../IpdInfo";
import IpdProgressRecord from "../IpdProgress/IpdProgressRecord";
import BedTransfer from "./BedTransferModal";
import RoomList from "./RoomList";
import { getDisplayAgeFromDOB } from "../../../helpers/formatters";
import { CalendarFunctions as calFns } from "../../../components/Calendar";
import { getClientGender } from "../../../components/ClientSearch";
import DischargeDetails from "../Discharge/DischargeDetails";
import { tl } from "../../../components/translate";
import { updateBedData } from "../../../slices/bedManagementSlice";
import { notificationAdd } from "../../../actions/notification";
import LabRequestForm, { RequestType } from "../LabRequest/LabRequestForm";

interface SelectedBedState extends Bed {
  isVacant: boolean;
  activeIpd: Partial<IPD>;
}

const formatBedName = (bed, wards) => {
  const bedNameFormat: Array<string> = [];
  if (bed.ward) {
    bedNameFormat.push(bed.ward?.shortCode);
  } else {
    const relatedWard = wards?.find((ward) => ward.id === bed.wardId);
    bedNameFormat.push(relatedWard.shortCode);
  }
  bedNameFormat.push(bed.number);

  return bedNameFormat.join("-");
};

const getDisplayNameAgeAndGender = (client) => {
  const ageAndGender = [];
  if (client.dob) {
    ageAndGender.push(getDisplayAgeFromDOB(client.dob));
  }
  if (client.gender) {
    ageAndGender.push(getClientGender(client.gender));
  }
  return `${client.firstName} ${client.lastName} ${ageAndGender.join("/")}`;
};

const Beds = ({
  beds,
  wards,
  activeIpds
}: {
  beds: Array<Bed>;
  wards: Array<Ward>;
  activeIpds: Array<Partial<IPD>>;
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const [filter, setFilter] = React.useState({
    selectedWard: null,
    bedNumber: ""
  });
  const [showProgressPanel, setShowProgressPanel] = React.useState(false);
  const [openDischargeForm, setOpenDischargeForm] = React.useState(false);
  const [showAdmissionPanel, setShowAdmissionPanel] = React.useState(false);
  const [showBedTransferModal, setShowBedTransferModal] = React.useState(false);
  const [openDischargeDetails, setOpenDischargeDetails] = React.useState(false);
  const [editingBedDetails, setEditingBedDetails] = React.useState({
    bedId: null,
    bedName: ""
  });

  const [labRequestFormInfo, setLabRequestFormInfo] = React.useState({
    open: false,
    type: RequestType.SERVICE
  });

  const [showIpdInfo, setShowIpdInfo] = React.useState(false);
  const [hoveredBed, setHoveredBed] = React.useState({
    bedId: null
  });

  const [filteredBeds, setFilteredBeds] = React.useState<
    Array<SelectedBedState> | Array<Bed> | null
  >(null);
  const [selectedBed, setSelectedBed] = React.useState<SelectedBedState | null>(null);

  const closeAllPanel = () => {
    setShowProgressPanel(false);
    setOpenDischargeForm(false);
    setShowAdmissionPanel(false);
  };
  const dispatch = useDispatch();
  const { patientRelatedInformation: client } = selectedBed?.activeIpd?.ipdRecord || {};

  React.useEffect(() => {
    let activeBeds: Array<Bed> | Array<SelectedBedState> =
      beds?.map((bed) => {
        const activeIpd = activeIpds.find((ipd) => ipd.bedId === bed.id);
        return {
          ...bed,
          isVacant: !activeIpd || !activeIpd.active,
          activeIpd: activeIpd || null,
          bedSlug: formatBedName(bed, wards).toLowerCase()
        };
      }) || [];

    if (filter.selectedWard) {
      activeBeds = activeBeds.filter(({ wardId }) => wardId === filter.selectedWard);
    }
    if (filter.bedNumber) {
      activeBeds = activeBeds.filter(
        ({ bedSlug }) => bedSlug.indexOf(filter.bedNumber.toLowerCase()) >= 0
      );
    }
    setFilteredBeds(activeBeds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beds, filter, activeIpds]);
  React.useEffect(() => {
    if (selectedBed) {
      const bed = activeIpds.find((ipd) => ipd.bedId === selectedBed.id);
      if (bed) {
        setSelectedBed({
          ...selectedBed,
          activeIpd: bed
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIpds]);

  const handleBedUpdate = async () => {
    try {
      if (editingBedDetails.bedId) {
        const response = await updateBed({
          id: editingBedDetails.bedId,
          customName: editingBedDetails.bedName
        });
        dispatch(updateBedData(response));
        setEditingBedDetails({
          bedId: null,
          bedName: ""
        });
      }
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to update bed name.",
          autoTimeout: false
        })
      );
    }
  };
  return (
    <Grid container px={3} spacing={2} justifyContent="flex-start" alignItems="flex-start">
      <Grid item container p={2} md={8} sm={12} spacing={2} mt={2}>
        <Popover
          disableScrollLock
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <Box py={1} minWidth="180px">
            {selectedBed?.isVacant && (
              <>
                <Typography
                  data-testmation="admitOption"
                  px={2}
                  py={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setShowAdmissionPanel(true);
                  }}
                >
                  Admit
                </Typography>
              </>
            )}
            {!selectedBed?.isVacant && (
              <>
                <Typography
                  data-testmation="createBillOption"
                  px={2}
                  py={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (client?.id && selectedBed?.id) {
                      dispatch(
                        push(
                          `/billing/ipd?clientId=${client.id}&bedId=${selectedBed.id}&ipdId=${selectedBed?.activeIpd?.id}`
                        )
                      );
                    }
                  }}
                >
                  Create Bill
                </Typography>
                <Divider />
                <Typography
                  data-testmation="labRequestOption"
                  px={2}
                  py={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setLabRequestFormInfo({
                      open: true,
                      type: RequestType.SERVICE
                    });
                  }}
                >
                  Lab Request Form
                </Typography>
                <Typography
                  data-testmation="transferBedOption"
                  px={2}
                  py={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setShowBedTransferModal(true);
                  }}
                >
                  Bed Transfer
                </Typography>
                <Divider />
                <Typography
                  data-testmation="progessRecordOption"
                  px={2}
                  py={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setShowProgressPanel(true);
                  }}
                >
                  Progress Record
                </Typography>
                <Divider />
                <Typography
                  data-testmation="dischargeOption"
                  px={2}
                  py={1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setAnchorEl(null);
                    setOpenDischargeForm(true);
                  }}
                >
                  Discharge
                </Typography>
              </>
            )}
          </Box>
        </Popover>
        <Grid item xs={8.5}>
          <Typography fontWeight="600" fontSize="20px">
            {tl("ipd.bedsOverview")}
          </Typography>
        </Grid>
        <Grid item xs={3.5}>
          <WardBedSearch wards={wards} filter={filter} setFilter={setFilter} />
        </Grid>
        {filteredBeds?.map((bed) => (
          <Grid item xs={6} sm={4} lg={2} key={bed.id} data-testmation="bedCard">
            <Box
              sx={{
                border: "0.5px solid rgba(90, 96, 127, 0.5)",
                textAlign: "center",
                borderRadius: "4px",
                "&:hover": {
                  boxShadow: "0px 15px 30px rgb(0 150 84 / 25%)",
                  cursor: "pointer"
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%"
              }}
            >
              <Box
                px={editingBedDetails?.bedId === bed.id ? 1 : 3}
                pt={3}
                pb={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  flexGrow: 1
                }}
              >
                {bed.isVacant ? <EmptyBed /> : <OccupiedBed />}
                <Box
                  display="flex"
                  onMouseEnter={() => {
                    setHoveredBed({
                      bedId: bed.id
                    });
                  }}
                  onMouseLeave={() => {
                    setHoveredBed({
                      bedId: null
                    });
                  }}
                  alignItems="center"
                >
                  {editingBedDetails?.bedId === bed.id ? (
                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                      <TextField
                        value={editingBedDetails.bedName}
                        fullWidth
                        onChange={(e) =>
                          setEditingBedDetails({
                            ...editingBedDetails,
                            bedName: e.target.value
                          })
                        }
                        sx={{
                          marginRight: "10px"
                        }}
                      />
                      <OkIcon onClick={handleBedUpdate} />
                      <CancelIcon
                        onClick={() =>
                          setEditingBedDetails({
                            bedId: null,
                            bedName: ""
                          })
                        }
                      />
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100px"
                      }}
                    >
                      {bed.customName || formatBedName(bed, wards)}{" "}
                    </Typography>
                  )}
                  {hoveredBed.bedId && hoveredBed.bedId === bed.id && !editingBedDetails?.bedId && (
                    <CreateIcon
                      sx={{ fontSize: "15px" }}
                      onClick={() => {
                        setEditingBedDetails({
                          bedId: bed.id,
                          bedName: bed.customName || formatBedName(bed, wards)
                        });
                      }}
                    />
                  )}
                </Box>
                {!bed.isVacant && bed.activeIpd?.client && (
                  <>
                    <Typography
                      fontSize="10px"
                      sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {getDisplayNameAgeAndGender(bed.activeIpd.client)}
                    </Typography>
                    <Typography
                      fontSize="10px"
                      sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      Admitted at{" "}
                      {`${
                        calFns.convertADtoBS(new Date(bed.activeIpd.dateOfAdmission)).formatted3
                      }`}
                    </Typography>
                  </>
                )}
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <MoreIcon
                  data-testmation="bedActionButton"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setSelectedBed(bed);
                    closeAllPanel();
                  }}
                  sx={{
                    cursor: "pointer",
                    marginRight: "5px"
                  }}
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid item container md={4} sm={12} mt={2}>
        <Grid item xs={12}>
          <RoomList wards={wards} beds={beds} activeIpds={activeIpds} />
        </Grid>
      </Grid>
      {showProgressPanel && selectedBed?.activeIpd && (
        <IpdProgressRecord
          onCancel={() => setShowProgressPanel(false)}
          ipdRecord={selectedBed?.activeIpd}
          afterSave={() => setShowIpdInfo(true)}
        />
      )}
      {openDischargeForm && selectedBed?.activeIpd && (
        <DischargeForm
          ipdData={selectedBed?.activeIpd as IPD}
          setOpenDischargeForm={setOpenDischargeForm}
          setOpenDischargeDetails={setOpenDischargeDetails}
        />
      )}
      {showAdmissionPanel && (
        <CreateIpdAdmission
          mode="create"
          onCancel={() => setShowAdmissionPanel(false)}
          stayOnPage={false}
          isFromDashboard
          showIpdPanel={() => {
            setShowAdmissionPanel(false);
            setShowIpdInfo(true);
          }}
          wardInfo={{
            wardId: selectedBed?.wardId,
            bedId: selectedBed?.id,
            roomType: selectedBed?.ward?.isRoom ? "Cabin" : "Ward"
          }}
        />
      )}
      {showIpdInfo && (
        <Panel
          title={
            selectedBed?.activeIpd?.status === IpdStatus.Admitted
              ? "Admission Info"
              : "Discharge Info"
          }
          wrapperStyle={{ minWidth: "860px" }}
          onClose={() => setShowIpdInfo(false)}
          editButton={selectedBed?.activeIpd?.status === IpdStatus.Admitted ? "" : ""}
        >
          <Box pl={5}>
            <IpdInfo
              id={selectedBed?.activeIpd?.id}
              ipdRecord={selectedBed?.activeIpd}
              onDischargeFormOpen={() => setOpenDischargeForm(true)}
            />
          </Box>
        </Panel>
      )}
      {showBedTransferModal && (
        <BedTransfer
          currentBed={selectedBed}
          wards={wards}
          open={showBedTransferModal}
          setOpen={setShowBedTransferModal}
          beds={beds}
        />
      )}

      {openDischargeDetails && selectedBed?.activeIpd && (
        <Box>
          <DischargeDetails
            id={selectedBed.activeIpd?.id}
            onCancel={() => setOpenDischargeDetails(false)}
          />
        </Box>
      )}
      {labRequestFormInfo.open && selectedBed?.activeIpd && (
        <div>
          <LabRequestForm
            onClose={() => {
              setLabRequestFormInfo({
                ...labRequestFormInfo,
                open: false
              });
            }}
            requestType={labRequestFormInfo.type}
            ipd={{
              ...selectedBed.activeIpd,
              bedName: formatBedName(selectedBed, wards)
            }}
          />
        </div>
      )}
    </Grid>
  );
};

export default Beds;

import { Button, Box, ButtonGroup, MenuItem, Menu } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

const dropDownButton = [
  {
    label: "Create Payment Voucher",
    url: "/accounts/paymentVoucher"
  },
  {
    label: "Create Receipt Voucher",
    url: "/accounts/receiptVoucher"
  },
  {
    label: "Create Contra Voucher",
    url: "/accounts/contraVoucher"
  },
  {
    label: "Create Sales Voucher",
    url: "/billing/new"
  },
  {
    label: "Create Credit Note",
    url: "/billing/bills"
  },
  {
    label: "Create Purchase Voucher",
    url: "/stock/purchase"
  },
  {
    label: "Create Debit Note",
    url: "/stock/purchaseReturn"
  },
  {
    label: "Bank Reconciliations",
    url: "/accounts/bankReconciliation"
  },
  {
    label: "Fiscal Closure",
    url: "/accounts/fiscalClosure"
  }
];

export default function EntryButton(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  return (
    <Box ml="10px">
      <ButtonGroup variant="contained">
        <Button sx={{ width: "200px" }} onClick={() => dispatch(push("/accounts/journalEntry"))}>
          Create Journal Voucher
        </Button>
        <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {dropDownButton.map((item) => (
          <MenuItem onClick={() => dispatch(push(item.url))} key={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

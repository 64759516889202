/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  cancelInvoice,
  fetchSubscriptionInvoices,
  getSubscriptionHistory,
  manualSubscriptionInvoicePost,
  patchInvoiceAmountSuperAdmin,
  paySMSInvoiceSuperAdminApi,
  postSMSInvoice,
  subscriptionGet,
  subscriptionInvoicePatch,
  subscriptionInvoicePost,
  subscriptionInvoicesGet,
  subscriptionPatch,
  subscriptionPost,
  subscriptionsTemplatesGet,
  terminateSubscription,
  verifySubscriptionInvoice
} from "../api/subscriptions";
import { ManualInvoiceInterface } from "../containers/ResourceCentreInvoices/ManualnvoiceCreatePanel";
import {
  SmsHistory,
  Subscription,
  SubscriptionHistory,
  SubscriptionInvoice,
  SubscriptionInvoices,
  SubscriptionInvoiceStatus
} from "../interfaces/Subscription";
import { getSmsHistory } from "../api/resourceCentre";

export const getSubscriptionTemplates = createAsyncThunk(
  "subscription/getSubscriptions",
  async () => {
    const response = await subscriptionsTemplatesGet();
    return response;
  }
);

export const getCurrentSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (id: number) => {
    const response = await subscriptionGet(id);
    return response;
  }
);

export const postSubscription = createAsyncThunk(
  "subscription/postSubscription",
  async (data: Subscription) => {
    const response = await subscriptionPost(data);
    return response;
  }
);

export const patchSubscription = createAsyncThunk(
  "subscription/patchSubscription",
  async (data: Subscription) => {
    const response = await subscriptionPatch(data);
    return response;
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/terminateSubscription",
  async (id: number) => {
    const response = await terminateSubscription(id);
    return response;
  }
);

export const getInvoices = createAsyncThunk("subscription/getInvoices", async (rcid: number) => {
  const response = await subscriptionInvoicesGet(rcid);
  return response;
});

export const postInvoice = createAsyncThunk(
  "subscription/postInvoice",
  async (data: Partial<SubscriptionInvoice>) => {
    const response = await subscriptionInvoicePost(data);
    return response;
  }
);

export const postManualInvoice = createAsyncThunk(
  "subscription/createManualInvoice",
  async (data: ManualInvoiceInterface & { resourceCentreId: number }) => {
    const response = await manualSubscriptionInvoicePost(data);
    return response;
  }
);

export const verifyInvoiceSuperAdmin = createAsyncThunk(
  "subscription/verifyInvoiceSubscription",
  async (id: number) => {
    const response = await verifySubscriptionInvoice(id);
    return response;
  }
);

export const patchInvoice = createAsyncThunk(
  "subscription/patchInvoice",
  async ({ data, id }: { data: Partial<SubscriptionInvoice>; id: number }) => {
    const response = await subscriptionInvoicePatch(data, id);
    return response;
  }
);

export const getHistory = createAsyncThunk("subscription/getHistory", async (id: number) => {
  const response = await getSubscriptionHistory(id);
  return response;
});

export const getSMSHistory = createAsyncThunk("subscription/getSMSHistory", async (id: number) => {
  const response = await getSmsHistory(id);
  return response;
});

export const getSubscriptionInvoices = createAsyncThunk(
  "subscription/getSubscriptionInvoices",
  async (resourceCentreId: number) => {
    const response = await fetchSubscriptionInvoices(resourceCentreId);
    return response;
  }
);

export const patchInvoiceAmount = createAsyncThunk(
  "subscription/patchAmountSubscriptionInvoice",
  async ({ id, amount }: { id: number; amount: number }) => {
    const response = await patchInvoiceAmountSuperAdmin(id, amount);
    return response;
  }
);

export const paySMSInvoiceSuperAdmin = createAsyncThunk(
  "subscription/paySubscriptionInvoiceSuperAdmin",
  async (invoiceId: number) => {
    const response = await paySMSInvoiceSuperAdminApi(invoiceId);
    return response;
  }
);

export const postSMSSubscriptionInvoice = createAsyncThunk(
  "subscription/postSubscriptionInvoice",
  async (data: {
    smsQuantity: number;
    invoiceAmount: number;
    invoiceNumber: number;
    action?: (v) => void;
    resourceCentreId: number;
  }) => {
    const response = await postSMSInvoice(
      {
        smsQuantity: data.smsQuantity,
        invoiceAmount: data.invoiceAmount,
        invoiceNumber: data.invoiceNumber
      },
      data.resourceCentreId
    );
    if (data.action) {
      data.action(response);
    }
    return response;
  }
);

export const cancelSubscriptionInvoice = createAsyncThunk(
  "subscription/cancelSubscriptionInvoice",
  async (id: number) => {
    const response = await cancelInvoice(id);
    return response;
  }
);

interface State {
  templates: Array<Subscription>;
  currentSubscription: Subscription | null;
  draftSubscription: Subscription | null;
  invoices: Array<SubscriptionInvoice>;
  subscriptionInvoices: Array<SubscriptionInvoices>;
  history: Array<SubscriptionHistory>;
  smsHistory: SmsHistory[];
}

const initialState: State = {
  templates: [],
  currentSubscription: null,
  draftSubscription: null,
  invoices: [],
  history: [],
  subscriptionInvoices: [],
  smsHistory: []
};

const authenticateSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateDraftSubscription: (state, action: PayloadAction<Subscription>) => {
      state.draftSubscription = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload;
    });
    builder.addCase(getCurrentSubscription.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentSubscription = payload;
      } else {
        state.currentSubscription = null;
      }
    });
    builder.addCase(postSubscription.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentSubscription = payload;
      }
    });
    builder.addCase(patchSubscription.fulfilled, (state, { payload }) => {
      state.currentSubscription = payload;
    });
    builder.addCase(cancelSubscription.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentSubscription = null;
      }
    });
    builder.addCase(getInvoices.fulfilled, (state, { payload }) => {
      state.invoices = payload;
    });
    builder.addCase(postInvoice.fulfilled, (state, { payload }) => {
      state.invoices.push(payload);
    });
    builder.addCase(patchInvoice.fulfilled, (state, { payload }) => {
      state.invoices = state.invoices.map((invoice) => {
        if (invoice.id === payload.id) return payload;
        return invoice;
      });
    });
    builder.addCase(getHistory.fulfilled, (state, { payload }) => {
      state.history = payload;
    });
    builder.addCase(getSMSHistory.fulfilled, (state, { payload }) => {
      state.smsHistory = payload;
    });

    // subscription invoices new

    builder.addCase(getSubscriptionInvoices.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices = [...payload].map((item) => ({
        ...item,
        status:
          item.relatedEntityDetails?.documentUrls &&
          item.status === SubscriptionInvoiceStatus.unpaid
            ? SubscriptionInvoiceStatus.pendingApproval
            : item.status
      }));
    });

    builder.addCase(postSMSSubscriptionInvoice.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices.push(payload);
    });

    builder.addCase(cancelSubscriptionInvoice.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices = state.subscriptionInvoices.map((sInvoice) => {
        if (sInvoice.id === payload.id) return payload;
        return sInvoice;
      });
    });

    builder.addCase(postManualInvoice.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices.push(payload);
    });

    builder.addCase(verifyInvoiceSuperAdmin.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices = state.subscriptionInvoices.map((sInvoice) => {
        if (sInvoice.id === payload.id) return payload;
        return sInvoice;
      });
    });

    builder.addCase(patchInvoiceAmount.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices = state.subscriptionInvoices.map((sInvoice) => {
        if (sInvoice.id === payload.id) return payload;
        return sInvoice;
      });
    });

    builder.addCase(paySMSInvoiceSuperAdmin.fulfilled, (state, { payload }) => {
      state.subscriptionInvoices = state.subscriptionInvoices.map((sInvoice) => {
        if (sInvoice.id === payload.id) return payload;
        return sInvoice;
      });
    });
  }
});

export const { updateDraftSubscription } = authenticateSlice.actions;
export default authenticateSlice.reducer;

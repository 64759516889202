import { Autocomplete, Box, Button, TextField } from "@mui/material";
import React from "react";
import Panel from "../../../components/Panel";
import * as NotificationActions from "../../../actions/notification";
import EditScreen from "../../Lab/SettingsV2/EditScreen";
import LabGroupResetToDefault from "../../Lab/SettingsV2/List/LabGroupResetToDefault";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store";
import {
  getLabTestGroupById,
  getLabTestGroups,
  getLabTests,
  removeDraftGroup,
  removeSelectedTests,
  setTestGroupDraft
} from "../../../slices/labTestSettingsSlice";
import { Test, TestTypes } from "../../../interfaces/Lab";
import hasOwnProperty from "../../../helpers/object";

interface Props {
  onClose: () => void;
}

function LabGroupEditPanel({ onClose }: Props): JSX.Element {
  const [isAboutToReset, setIsAboutToReset] = React.useState(false);
  const selectedTestsUUIDs = useAppSelector((state: RootState) => state.labSettings.checkedUUIDs);
  const testGroupsMaster = useAppSelector((state: RootState) => state.labSettings.labTestGroups);
  const [selecteGroup, setSelecteGroup] = React.useState(null);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getLabTestGroups());
    dispatch(getLabTests());
    return () => {
      dispatch(removeDraftGroup());
    };
  }, [dispatch]);

  return (
    <Panel onClose={onClose} title="Lab Test Group Create/Edit" wrapperStyle={{ minWidth: 900 }}>
      <Box px={4}>
        <Autocomplete<Test | { inputValue: string; name: string }>
          sx={{ mt: "16px" }}
          getOptionLabel={(o) => o.name}
          value={selecteGroup}
          options={testGroupsMaster}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={(hasOwnProperty(option, "id") && option?.id) || option.name}>
              <div>{option.name}</div>
            </li>
          )}
          filterOptions={(options, params) => {
            if (params.inputValue !== "") {
              options.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`
              });
            }
            return options.filter((o) =>
              o.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );
          }}
          onChange={(_, v) => {
            dispatch(removeDraftGroup());
            if (v) {
              if (hasOwnProperty(v, "inputValue") && v.inputValue) {
                dispatch(
                  setTestGroupDraft({
                    id: null,
                    code: "",
                    name: v.inputValue,
                    type: "pathology" as TestTypes,
                    common: false,
                    category: "Radiology",
                    labTests: [],
                    additionalTestData: null,
                    interpretationTemplate: null,
                    info: { defaultRemarks: "" }
                  })
                );

                setSelecteGroup({
                  name: v.inputValue,
                  code: "",
                  common: false,
                  info: null,
                  type: "pathology",
                  category: "Radiology",
                  resourceCentreId: null,
                  referenceId: null
                });
              } else {
                setSelecteGroup(v);
                dispatch(getLabTestGroupById(v.id));
                if (hasOwnProperty(v, "documentRecord")) {
                  dispatch(setTestGroupDraft(v.documentRecord));
                }
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              data-testmation="labSelect"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              margin="dense"
              fullWidth
              variant="outlined"
              label="Select Or Add Lab"
              placeholder="Select Or Add Lab"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        {selecteGroup?.id && (
          <Box display="flex" justifyContent="flex-end" gap={2} mt={1}>
            <Button variant="outlined" color="primary" onClick={() => setIsAboutToReset(true)}>
              Reset To Defaults
            </Button>
            {selectedTestsUUIDs.length > 0 && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  dispatch(removeSelectedTests());
                  dispatch(
                    NotificationActions.notificationAdd({
                      id: new Date().getUTCMilliseconds(),
                      variant: "warning",
                      message: "Selected Tests have been removed!",
                      autoTimeout: true
                    })
                  );
                }}
              >
                Remove Selected Tests
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box px={2}>
        <EditScreen onCancel={onClose} heightOffset={205} />
        <LabGroupResetToDefault isOpen={isAboutToReset} onClose={() => setIsAboutToReset(false)} />
      </Box>
    </Panel>
  );
}

export default LabGroupEditPanel;

import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import startCase from "lodash/startCase";
import { tl } from "../../components/translate";
import { ServiceTypes } from "../../models/Service";
import { ServiceType } from "../../interfaces/ProductInterface";

interface Props {
  value: ServiceType.package | ServiceType.single;
  disabled: boolean;
  onChange: (v: string) => void;
}
const optionsForSelect = (serviceTypes) =>
  serviceTypes.map((type) =>
    type === ServiceType.single ? { label: "service", value: type } : { label: type, value: type }
  );

export const ServiceTypeAutoSelect = ({
  value,
  disabled = false,
  onChange
}: Props): JSX.Element => (
  <Autocomplete
    id="service-select"
    options={optionsForSelect(ServiceTypes)}
    getOptionLabel={(option) => startCase(option.label)}
    value={optionsForSelect(ServiceTypes).find((option) => option.value === value)}
    disabled={disabled}
    onChange={(_, newValue) => onChange(newValue?.value)}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="standard"
        label={tl("reports.serviceAttribute")}
        margin="dense"
        fullWidth
      />
    )}
  />
);

export default ServiceTypeAutoSelect;

import * as React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import startCase from "lodash/startCase";
import produce from "immer";
import omit from "lodash/omit";
import Panel from "../../../components/Panel";
import { t } from "../../../components/translate";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { Client } from "../../../interfaces/ClientInterface";
import { clientFullNameSelector } from "../../../reducers/client";
import { notificationAdd } from "../../../actions/notification";
import {
  createErrorMessage,
  createSuccessMessage,
  MODULE,
  updateErrorMessage,
  updateSuccessMessage
} from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";
import { patchSurveyForm, postSurveyForm } from "../../../api/surveyForm";

export const StyledTypography = ({ text }: { text: string }): JSX.Element => (
  <Typography sx={{ fontSize: "1rem" }} gutterBottom>
    {text}
  </Typography>
);

interface SurveyQuestion {
  question: string;
  options?: string[];
  isVisible: boolean;
  answer?: string;
  customAnswer?: string;
}

export interface SurveyData {
  // eslint-disable-next-line camelcase
  created_at: string;
  id?: number;
  isTemplate?: boolean;
  resourceCentreId?: number;
  clientId?: number;
  client?: Client;
  document: { defaultQuestions: SurveyQuestion[]; customQuestions: SurveyQuestion[] };
}

interface Props<T extends boolean> {
  isEditMode: T;
  data: T extends true ? SurveyData : null;
  onClose: () => void;
  client: Client;
  afterSave: (value: SurveyData) => void;
  template: Partial<SurveyData>;
}

const SurveyCreateEditForm = <T extends boolean>({
  isEditMode,
  data,
  client,
  afterSave,
  onClose,
  template
}: Props<T>): JSX.Element => {
  const [formData, setFormData] = React.useState<Partial<SurveyData>>(
    isEditMode && data
      ? { ...data, client }
      : {
          client,
          document: template.document
        }
  );
  const [formState, setFormState] = React.useState({ isSubmitting: false });
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const updatedData = produce(formData, (draft) => {
      draft.client = client;
    });
    setFormData(updatedData);
  }, [client]);

  const onSubmit = async () => {
    try {
      setFormState((prevState) => ({ ...prevState, isSubmitting: true }));
      const updatedFormData = { ...omit(formData, "client"), clientId: formData.client?.id };
      const response =
        isEditMode && updatedFormData?.id
          ? await patchSurveyForm({ data: updatedFormData, id: updatedFormData.id })
          : await postSurveyForm(updatedFormData);
      afterSave(response);
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: isEditMode
            ? updateSuccessMessage(MODULE.SURVERY_FORM)
            : createSuccessMessage(MODULE.SURVERY_FORM),
          variant: "success",
          autoTimeout: true
        })
      );
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: isEditMode
            ? updateErrorMessage(MODULE.SURVERY_FORM)
            : createErrorMessage(MODULE.SURVERY_FORM),
          variant: "error",
          autoTimeout: true
        })
      );
    } finally {
      setFormState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  return (
    <Panel
      onClose={onClose}
      title={isEditMode ? t("survey.update") : t("survey.form")}
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%",
            mx: 4
          }}
        >
          <Button onClick={onClose}>{t("global.cancel")}</Button>
          <StatefulButton
            data-testmation="surveySave"
            variant="contained"
            onClick={onSubmit}
            type="submit"
            form="surveyForm"
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
            circularProgressProps={{ size: 16 }}
          >
            {t("global.save")}
          </StatefulButton>
        </Box>
      }
    >
      <Box
        sx={{
          px: 5,
          pt: 1,
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
        component="form"
        id="surveyForm"
      >
        <div>
          <Box display="flex" gap={2}>
            <StyledTypography text="Name:" />
            <StyledTypography text={startCase(clientFullNameSelector(formData.client))} />
          </Box>
          {formData.client?.phone && (
            <Box display="flex" gap={2}>
              <StyledTypography text="Phone:" />
              <StyledTypography text={client.phone} />
            </Box>
          )}
        </div>
        <div>
          <Grid container columnSpacing={7} rowSpacing={1}>
            {formData.document?.defaultQuestions.map((item, index) => {
              if (!item.isVisible) return null;
              return (
                <Grid item xs={12} mt={1} key={item.question}>
                  <StyledTypography text={item.question} />
                  <RadioGroup
                    row
                    value={formData.document?.defaultQuestions[index].answer}
                    onChange={(event) => {
                      const updatedData = produce(formData, (draft) => {
                        draft.document.defaultQuestions[index].answer = event.target.value;
                      });
                      setFormData(updatedData);
                    }}
                  >
                    {item.options?.map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio size="small" />}
                        label={startCase(option)}
                        labelPlacement="end"
                      />
                    ))}
                  </RadioGroup>
                  {(formData.document?.defaultQuestions[index].answer === "other" ||
                    formData.document?.defaultQuestions[index].answer === "yes") && (
                    <Box mt={1}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={
                          formData.document.defaultQuestions[index].answer === "other"
                            ? "Others"
                            : "Answer"
                        }
                        variant="outlined"
                        value={formData.document.defaultQuestions[index].customAnswer}
                        onChange={(event) => {
                          const updatedData = produce(formData, (draft) => {
                            draft.document.defaultQuestions[index].customAnswer =
                              event.target.value;
                          });
                          setFormData(updatedData);
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              );
            })}
            {formData.document?.customQuestions.map((item, index) => (
              <Grid item xs={12} mt={1} key={item.question}>
                <StyledTypography text={item.question} />
                {item.options && (
                  <RadioGroup
                    row
                    value={formData.document?.customQuestions[index].answer}
                    onChange={(event) => {
                      const updatedData = produce(formData, (draft) => {
                        draft.document.customQuestions[index].answer = event.target.value;
                      });
                      setFormData(updatedData);
                    }}
                  >
                    {item.options.map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio size="small" />}
                        label={startCase(option)}
                        labelPlacement="end"
                      />
                    ))}
                  </RadioGroup>
                )}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Answer"
                  variant="outlined"
                  value={formData.document.customQuestions[index].customAnswer}
                  onChange={(event) => {
                    const updatedData = produce(formData, (draft) => {
                      draft.document.customQuestions[index].customAnswer = event.target.value;
                    });
                    setFormData(updatedData);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    </Panel>
  );
};

export default SurveyCreateEditForm;

import * as React from 'react';
import { Box } from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmDone from '@mui/icons-material/AlarmOn';
import styles from './styles.module.css';
import { tl } from '../../../components/translate';
import { statuses } from '../BookingStatus/BookingStatus';
import VideocamIcon from '@mui/icons-material/Videocam';

const BookingProps: React.FC<{ booking: { followUp: boolean, status: string, isVideoAppointment: boolean } }> =
  ({ booking }) => {
    if (!booking) return null;
    const reminders = booking.reminders || [];
    const allDone = reminders.reduce((acc, r) => acc && r.done, true);
    const currStatus = statuses.find((s) => s.label === booking.status);
    return (
      <Box display={'flex'} alignItems={'center'}>
        {booking.followUp && (
          <Box
            component={'span'}
            fontWeight={500}
            fontSize={'0.7rem'}
            mr={'0.5rem'}
            className={styles.bookingType}
          >
            {tl('booking.followUp')}
          </Box>
        )}
        <Box
          component={'span'}
          fontWeight={500}
          fontSize={'0.7rem'}
          className={styles.bookingType}
          style={{ backgroundColor: currStatus.color }}
        >
          {booking.status}
        </Box>
        <Box flexGrow={1} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          {booking.isVideoAppointment && <VideocamIcon className={`${styles.reminderIcon} ${styles.icon}`} />}
          {!!reminders.length &&
            (allDone ? (
              <AlarmDone className={`${styles.reminderIcon} ${styles.icon}`} />
            ) : (
                <AlarmIcon className={`${styles.reminderIcon} ${styles.icon}`} />
              ))}
        </Box>
      </Box>
    );
  };

export default BookingProps;

import * as React from 'react';
import { Link, Box } from '@mui/material';
import { tl } from '../../../components/translate';
import RecordPayment from '../RecordPayment/RecordRefundPayment';
import { BillForList } from '../../../interfaces/BillInterfaces';

interface Props {
  showDisc: boolean,
  bill: BillForList
}

const PaymentInfoAction = ({ showDisc, bill }: Props): JSX.Element => {
  const [openMenu, setOpenMenu] = React.useState(false);
  React.useEffect(() => setOpenMenu(false), [bill.id]);
  return (
    <>
      <Link
        onClick={() => setOpenMenu(true)}
        style={{ cursor: 'pointer' }}
        data-testmation="billInfoRecordPayment"
      >
        {tl('billing.recordPayment')}
      </Link>
      <Box>
        {openMenu && (
          <RecordPayment
            showDisc={showDisc}
            onClose={() => setOpenMenu(false)}
            billId={bill.id}
            clientId={bill.client?.id}
          />
        )}
      </Box>
    </>
  );
};

export default PaymentInfoAction;

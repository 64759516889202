import React from "react";
import { AccountingGroup, PrimaryLedger, PrimaryLedgerNames } from "../../../interfaces/Accounts";
import AccountingList from "../AccountList";

interface ExpensesProps {
  data: PrimaryLedger;
  setSelectedParent: (data: Partial<AccountingGroup>) => void;
}

export default function Expenses({ data, setSelectedParent }: ExpensesProps): JSX.Element {
  return (
    <AccountingList
      header={PrimaryLedgerNames.EXPENSES}
      setSelectedParent={setSelectedParent}
      data={data}
    />
  );
}

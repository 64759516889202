import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { tl } from '../../components/translate';
import List, { InfoView } from '../../components/List';
import { OnDemandProducts } from '../../interfaces/OnDemandProductInterface';
import { getOnDemandProducts } from '../../actions/onDemandProducts';
import { IServiceTag } from '../../interfaces/ServiceTagsInterface';
import { connect } from 'react-redux';
import CreatePanel from './CreatePanel';

const { useState, useEffect } = React;

export interface ListItemState extends OnDemandProducts {
  serviceTagName: string;
}

interface Props {
  serviceTags: Array<IServiceTag>;
  getOnDemandProducts: () => void;
  ondemandProducts: Array<OnDemandProducts>;
}

function formatData(data: Array<OnDemandProducts>, serviceTags: Array<IServiceTag>): Array<State> {
  return data.map((item, i) => {
    return {
      ...item,
      serviceTagName: serviceTags.find((s) => s.id === item.serviceTagId)?.tag || ''
    };
  });
}

function OnDemandProduct(props: Props) {
  const [panelState, setPanelState] = useState<{
    mode: 'edit' | 'create';
    state?: ListItemState;
  } | null>(null);

  const { serviceTags, getOnDemandProducts, ondemandProducts } = props;

  useEffect(() => {
    getOnDemandProducts();
  }, []);

  return (
    <Box height="calc(100vh - 50px)">
      <List<ListItemState>
        automation="ondemandProduct"
        title={tl('ondemand.productList')}
        rowHeight={50}
        // defaultSortColumn={'serviceTag'}
        createLabel={tl('ondemand.productCreate')}
        columns={[
          {
            key: 'id',
            label: tl('ondemand.id'),
            formatter: ({ id }) => <Typography>{id}</Typography>
          },
          {
            key: 'serviceTag',
            label: tl('ondemand.serviceTag'),
            formatter: ({ serviceTagName }) => <Typography>{serviceTagName}</Typography>
          },
          {
            key: 'grossTotalPrice',
            label: tl('ondemand.grossTotalPrice')
          },
          {
            key: 'discount',
            label: tl('ondemand.discount')
          },
          {
            key: 'active',
            label: tl('ondemand.active'),
            formatter: ({ active }) => <Typography>{active.toString()}</Typography>
          }
        ]}
        onRowClick={(row) => setPanelState({ mode: 'edit', state: row })}
        data={formatData(ondemandProducts, serviceTags)}
        onCreateNew={() => setPanelState({ mode: 'create' })}
      ></List>
      {panelState && <CreatePanel {...panelState} handleClose={() => setPanelState(null)} />}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  serviceTags: state.serviceTags.collection,
  ondemandProducts: state.ondemandProducts.collection
});

const mapDispatchToProps = (dispatch) => ({
  getOnDemandProducts: () => dispatch(getOnDemandProducts())
});

export default connect(mapStateToProps, mapDispatchToProps)(OnDemandProduct);

import React, { useState, useEffect } from "react";
import Delete from "@mui/icons-material/Delete";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box, Typography, List, ListItem, CircularProgress, Menu, Tooltip } from "@mui/material";
import { startCase } from "lodash";
import PrintIcon from "@mui/icons-material/Print";
import Panel from "../../../components/Panel";
import BatchList from "./BatchList";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import CreateStockProduct, { isDirty } from "./CreateStockProduct";
import { deleteStockProduct, updateStockProduct } from "../../../actions/stockProductActions";
import { getStocksByProductId } from "../../../actions/stock";
import Can from "../../Policy/Can";
import { IThunkDispatch, RootState } from "../../../store";
import { StockProducts } from "../../../interfaces/StockInterfaces";
import { getStockProductAssociations } from "../../../api/stockProducts";
import { getReferrers } from "../../../actions/referrers";
import BarCodePrint from "./BarCodePrint";
import { getStockByProductId } from "../../../api/stock";
import { notificationAdd } from "../../../actions/notification";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";

const StockProductEdit = ({
  handleClose,
  data,
  updateProduct,
  id,
  loadStocksByProductId,
  deleteProduct,
  wrapperStyle,
  editMode
}: {
  handleClose: () => void;
  data: StockProducts;
  updateProduct: (data: StockProducts) => void;
  id: number;
  loadStocksByProductId: (id: number) => void;
  deleteProduct: () => void;
  wrapperStyle: unknown;
  editMode: boolean;
}) => {
  const dispatch = useDispatch();

  const [batchList, setBatchList] = useState([]);
  const [isFetchingBatch, setIsFetchingBatch] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startBatchList, setStartBatchList] = useState(data?.stocks || []);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productReferrerAssociations, setProductReferrerAssociations] = React.useState<
    Array<number>
  >([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [printMenuAnchorEl, setPrintMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCloseMenu = () => {
    setPrintMenuAnchorEl(null);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setIsFetchingBatch(true);
          const stockResponse = await getStockByProductId(id);
          const referrerAssociations = await getStockProductAssociations(id);
          setProductReferrerAssociations(referrerAssociations);
          setBatchList(stockResponse);
          setStartBatchList(stockResponse);
          setIsFetchingBatch(false);
        } catch (error) {
          setIsFetchingBatch(false);
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              variant: "error",
              message: error?.data?.message || errorFetchMessage(MODULE.STOCK),
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, [loadStocksByProductId, id]);

  const [productToUpdate, setProductToUpdate] = useState(null);

  const referrers = useSelector((state: RootState) => state.referrers.referrers);

  useEffect(() => {
    if (!referrers.length) {
      dispatch(getReferrers());
    }
  }, [dispatch, referrers.length]);

  const associatedReferrerNames = productReferrerAssociations
    .map((idx) => referrers.find((referrer) => referrer.id === idx))
    .filter(Boolean)
    .map((referrer) => referrer.referrer);

  const deleteDialogContent = (
    <Box>
      <Typography>
        Are you sure you want to delete this product? This action is irreversible.
      </Typography>
      {batchList.length > 0 && (
        <>
          <Typography style={{ fontWeight: 800, marginTop: "24px" }}>
            Deleting this product will delete the following stock inventory related to the product!
          </Typography>
          <List>
            {batchList.map((stock, i) => (
              //  eslint-disable-next-line react/no-array-index-key
              <ListItem style={{ display: "flex" }} key={`${i}${stock.batchId}`}>
                <Typography style={{ width: "25px", fontWeight: 600 }}>{i + 1}.</Typography>{" "}
                <Typography style={{ width: "160px", fontWeight: 600 }}>
                  Batch Id: {stock.batchId}
                </Typography>
                <Typography style={{ fontWeight: 600 }}>Quanitity: {stock.quantity}</Typography>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {associatedReferrerNames.length > 0 && (
        <>
          <Typography style={{ fontWeight: 800, marginTop: "24px" }}>
            This product is being used by following referrers for specific pricing!
          </Typography>
          <List>
            {associatedReferrerNames.map((name, i) => (
              <ListItem style={{ display: "flex" }} key={name}>
                <Typography style={{ width: "25px", fontWeight: 600 }}>{i + 1}.</Typography>{" "}
                <Typography style={{ width: "160px", fontWeight: 600 }}>
                  {startCase(name)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
  if (editMode && !data) return null;

  return (
    <Can policyAccessKey="stock:editProduct">
      <Panel
        wrapperStyle={wrapperStyle}
        onClose={handleClose}
        title={data.name}
        deleteButton={
          <Delete style={{ cursor: "pointer" }} onClick={() => setDeleteDialogOpen(true)} />
        }
        printButton={
          data.barCode ? (
            <Tooltip title="Barcode print" aria-label="barcodeprint">
              <PrintIcon
                onClick={(event) => setPrintMenuAnchorEl(event.currentTarget)}
                data-testomation="printButton"
                sx={{ color: "black", cursor: "pointer" }}
              />
            </Tooltip>
          ) : (
            ""
          )
        }
      >
        <Menu
          anchorEl={printMenuAnchorEl}
          open={Boolean(printMenuAnchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <BarCodePrint
            barCode={data?.barCode}
            productName={data?.name}
            handleMenuClose={handleCloseMenu}
          />
          <BarCodePrint
            isMultiplePrint
            barCode={data?.barCode}
            productName={data?.name}
            handleMenuClose={handleCloseMenu}
          />
        </Menu>
        <CreateStockProduct
          productName={data.name}
          saveAction={(product) => {
            setProductToUpdate(product);
            setDialogOpen(true);
          }}
          handleClose={handleClose}
          createMode={false}
          data={data}
          id={id}
          batchList={
            isFetchingBatch ? (
              <Box
                sx={{
                  padding: 2,
                  display: "flex",
                  placeItems: "center",
                  flexDirection: "column",
                  gap: "1rem"
                }}
              >
                <CircularProgress color="primary" size={20} />
                <Typography>Loading batches</Typography>
              </Box>
            ) : (
              <BatchList
                unitsPerPackage={data.unitsPerPackage}
                stocks={batchList}
                isInternal={data.productType === 2}
                updateBatch={(updatedList) => setBatchList(updatedList)}
              />
            )
          }
          isBatchDirty={isDirty(startBatchList, batchList)}
        />

        <OkhatiDialog
          title="Confirm Product Update"
          description="Are you sure you want update the product ?"
          disableConfirmBtn={isSaving}
          next={async () => {
            setIsSaving(true);
            await updateProduct({
              ...productToUpdate,
              stocks: batchList,
              id: data.id,
              name: productToUpdate.productName
            });
            setIsSaving(false);
            setDialogOpen(false);
          }}
          readMode={false}
          cancel={() => {
            setDialogOpen(false);
          }}
          open={dialogOpen}
        />
      </Panel>

      <OkhatiDialog
        title="Confirm Product Delete"
        disableConfirmBtn={isSaving}
        description={deleteDialogContent}
        next={async () => {
          setIsSaving(true);
          await deleteProduct(id);
          setDeleteDialogOpen(false);
          setIsSaving(false);
        }}
        readMode={false}
        cancel={() => {
          setDeleteDialogOpen(false);
        }}
        open={deleteDialogOpen}
      />
    </Can>
  );
};

export default connect(null, (dispatch: IThunkDispatch) => ({
  deleteProduct: (id) => dispatch(deleteStockProduct(id)),
  updateProduct: (product) => dispatch(updateStockProduct(product)),
  loadStocksByProductId: (id) => dispatch(getStocksByProductId(id))
}))(StockProductEdit);

import React from "react";
import {
  proseMirrorOptions,
  RichTextfield,
  deserializeNode
} from "../../../../components/RichTextfield";
import { useParsedProseMirror } from "../../LabEntry/LabTestSubRow";

interface Props {
  onSave: (v: string) => void;
  value: string;
}

export default function AlertDialog(props: Props): JSX.Element {
  const { value, onSave } = props;

  const [state, setState] = useParsedProseMirror(value || "", proseMirrorOptions);

  return (
    <div
      onBlur={() => {
        onSave(deserializeNode(state.doc));
      }}
      style={{ width: "100%", marginTop: "8px" }}
    >
      <RichTextfield
        state={state}
        setState={setState}
        editorFontSize={12}
        showFontSizeToggleButtons
      />
    </div>
  );
}

/* eslint-disable camelcase */
import * as React from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import { omit } from "lodash";
import { round } from "mathjs";
import { tl, t } from "../../components/translate";
import Filters, { FilterProps } from "./Filters";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import * as reportsApi from "../../api/reports";
import { downloadExcel } from "../../helpers/files";
import { rupeeDisplay } from "../../helpers/rupee";
import "./Reports.scss";
import styles from "./Reports.module.css";
import Can from "../Policy/Can";
import { notificationAdd } from "../../actions/notification";
import { formatHeaderForExcel, formatDataForExcel } from "../accounts/Reports/helper";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  commissionDefaultCols,
  commissionHiddenCols,
  getDefaultKeyValuesColumns
} from "../../components/ListHeaderShowHideDialog/helpers";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

const docColumns = () => [
  "id",
  "clientId",
  "clientName",
  "serviceName",
  "serviceProvider",
  "qty",
  "billNo",
  "productPriceExcVat",
  "serviceProviderRate",
  "discountPercent",
  "discountAmount",
  "netAmount",
  "paidAmount",
  "materialChargePercent",
  "materialChargeAmount",
  "labCharge",
  "standardPayable",
  "tds",
  "netPayableToDoctor",
  "created_at"
];

const docRowProcessor = ({
  id,
  clientId,
  relatedInformation,
  qty,
  billNo,
  productPriceExcVat,
  rate,
  discountPercent,
  discountAmount,
  netAmount,
  paidAmount,
  materialChargePercent,
  materialChargeAmount,
  labCharge,
  standardPayable,
  tds,
  vendorName,
  netPayableToVendor,
  created_at,
  __meta__row_type
}) => {
  if (__meta__row_type === "segment_summary") return null;
  return [
    id,
    clientId,
    `${relatedInformation.customerFirstName} ${relatedInformation.customerLastName}`,
    relatedInformation.serviceName,
    vendorName,
    qty,
    billNo,
    productPriceExcVat,
    rate,
    discountPercent,
    discountAmount,
    netAmount,
    paidAmount,
    materialChargePercent,
    materialChargeAmount,
    labCharge,
    standardPayable,
    tds || 0,
    netPayableToVendor,
    created_at
  ];
};

enum VendorTypes {
  SERVICE_PROVIDER = "serviceProvider",
  REFERRER = "referrer"
}

const vendorTypes = Object.values(VendorTypes);

const CommissionReport = (): JSX.Element => {
  const [filters, setFilters] = React.useState<FilterProps>({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    vendorType: "serviceProvider"
  });
  const [reportData, setReportData] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await reportsApi.commissionReport(filters);
        setReportData(
          response.map((item) => ({
            vendorName:
              filters.vendorType === "serviceProvider"
                ? startCase(item.relatedInformation.serviceProviderFullName)
                : startCase(item.relatedInformation.referrer),
            ...item
          }))
        );
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Something Went Wrong",
            autoTimeout: true
          })
        );
      }
    })();
  }, [filters, dispatch]);

  const applyFilters = (newFilters) => {
    if (newFilters.vendorType === "serviceProvider") {
      setFilters({ ...omit(newFilters, ["referrersName"]) });
      return;
    }
    if (newFilters.vendorType === "referrer") {
      setFilters({
        ...omit(newFilters, ["serviceProviderIds"]),
        referrersName: newFilters.referrersName?.map((item) => item.id)
      });
      return;
    }
    setFilters(newFilters);
  };
  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(commissionDefaultCols, commissionHiddenCols)
  );
  const [open, setOpen] = React.useState(false);

  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="allow">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1100px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              vendorTypes={vendorTypes}
              filters={filters}
              onSetFilters={(newFilters) => applyFilters(newFilters)}
            />
          </Box>
          <Box
            className={["commissionList", styles.commissionListStyles].join(" ")}
            marginTop="32px"
          >
            <List
              automation="commissionReport"
              data={reportData}
              rowHeight={40}
              activeRow={0}
              defaultSortColumn="vendorName"
              defaultSortOrder={-1}
              adjustHeightToContents
              columns={[
                {
                  key: "vendorName",
                  label: `${
                    filters.vendorType === "serviceProvider" ? "Service Provider" : "Referrer"
                  }`,
                  sortable: true,
                  segmentable: true,
                  segmentBy: (row) => row.vendorName,
                  formatter: ({ vendorName }) => <Typography>{vendorName}</Typography>
                },
                {
                  key: "service",
                  label: "service",
                  sortable: true,
                  formatter: ({ relatedInformation }) => (
                    <Typography>{relatedInformation.serviceName}</Typography>
                  )
                },
                {
                  key: "client",
                  label: "Client",
                  sortable: true,
                  formatter: ({ relatedInformation }) => (
                    <Typography>
                      {`${relatedInformation.customerFirstName} ${relatedInformation.customerLastName}`.toUpperCase()}
                    </Typography>
                  )
                },
                {
                  key: "qty",
                  label: "Qty",
                  formatter: ({ qty }) => <Typography>{qty}</Typography>
                },
                {
                  key: "productPriceExcVat",
                  label: "Price Exc Vat",
                  formatter: ({ productPriceExcVat }) => (
                    <Typography>{productPriceExcVat}</Typography>
                  )
                },
                {
                  key: "serviceProviderRate",
                  label: "SP Rate",
                  formatter: ({ serviceProviderRate }) => (
                    <Typography>{serviceProviderRate}</Typography>
                  )
                },
                {
                  key: "discountPercent",
                  label: "Dis %",
                  formatter: ({ discountPercent }) => <Typography>{discountPercent}</Typography>
                },
                {
                  key: "discountAmount",
                  label: "Dis Amount",
                  formatter: ({ discountAmount }) => <Typography>{discountAmount}</Typography>
                },
                {
                  key: "netAmount",
                  label: "Net Total",
                  formatter: ({ netAmount }) => <Typography>{netAmount}</Typography>
                },
                {
                  key: "paidAmount",
                  label: "Paid Amount",
                  formatter: ({ paidAmount }) => <Typography>{paidAmount}</Typography>
                },
                {
                  key: "netAmount",
                  label: "Net Total",
                  formatter: ({ netAmount }) => <Typography>{netAmount}</Typography>
                },
                {
                  key: "materialChargePercent",
                  label: "Material Charge %",
                  formatter: ({ materialChargePercent }) => (
                    <Typography>{materialChargePercent}</Typography>
                  )
                },
                {
                  key: "materialChargeAmount",
                  label: "Material Charge Amt",
                  formatter: ({ materialChargeAmount }) => (
                    <Typography>{materialChargeAmount}</Typography>
                  )
                },
                {
                  key: "standardPayable",
                  label: "Standard Payable",
                  formatter: ({ standardPayable }) => <Typography>{standardPayable}</Typography>
                },
                {
                  key: "tds",
                  label: "TDS",
                  formatter: ({ tds }) => <Typography>{tds}</Typography>
                },
                {
                  key: "created_at",
                  label: "Create At",
                  formatter: ({ created_at }) => (
                    <Typography>{calFns.convertADtoBS(new Date(created_at)).formatted4}</Typography>
                  )
                },
                {
                  key: "billNo",
                  label: "Bill Number",
                  segmentable: true,
                  formatter: ({ billNo }) => <Typography>{billNo}</Typography>
                },
                {
                  key: "rate",
                  label: "Rate",
                  sortable: true,
                  formatter: ({ rate }) => <Typography>{rate}</Typography>
                },
                {
                  key: "labCharge",
                  label: "Lab Charge",
                  sortable: true,
                  formatter: ({ labCharge }) => <Typography>{labCharge}</Typography>
                },
                {
                  key: "netPayableToVendor",
                  label: "Net Payable",
                  sortable: true,
                  formatter: ({ netPayableToVendor }) => (
                    <Typography>{netPayableToVendor}</Typography>
                  )
                }
              ].filter((row) => listColumns[row.key])}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight="500"
                      >
                        {acc.segment || "Clinic"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexBasis="540px"
                    justifyContent="flex-end"
                    paddingRight="30px"
                  >
                    <Typography>
                      <Box component="span" fontWeight={600}>
                        {rupeeDisplay(
                          round(
                            acc.rows.reduce(
                              (prev, cur) => prev + (Number(cur.netPayableToVendor) || 0),
                              0
                            ),
                            2
                          )
                        )}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            `${t("reports.commissionReport")}-${
                              calFns.convertADtoBS(filters.from).formatted
                            }-${calFns.convertADtoBS(filters.until).formatted}`,
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                    <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <ListHeaderShowHideDialog
              onChange={(updatedColumns) => setListColumns(updatedColumns)}
              requiredColumns={["vendorName"]}
              open={open}
              onClose={() => setOpen(false)}
              columns={listColumns}
            />
          </Box>
        </Box>
      </Box>
    </Can>
  );
};

export default CommissionReport;

import { createSelector } from 'reselect';
import startCase from 'lodash/startCase';

//selectors
export const supplierNameSelector = (supplier = { name: '' }) => {
  const { name } = supplier;
  return `${startCase(name)}`;
};

export const suppliersSelector = (state) => state.resources.suppliers;

export const suppliersSortedSelector = createSelector(suppliersSelector, (suppliers) =>
  suppliers.sort((a, b) =>
    `${a.name || ''}`.toLowerCase() >
    `${b.name || ''}`.toLowerCase()
      ? 1
      : -1,
  ),
);



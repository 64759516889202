import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import List, { EmptyView } from "../../components/OList";
import * as reportsApi from "../../api/reports";
import { tl } from "../../components/translate";
import useMobileScreen from "../../hooks/useMobileScreen";
import "./Reports.scss";
import { FilterProps } from "./Filters";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import * as NotificationActions from "../../actions/notification";
import Filters from "../accounts/Reports/Filters";
import { rupeeDisplay } from "../../helpers/rupee";
import { LedgerType } from "../../interfaces/Accounts";
import { BankReconciliationReportInterface } from "../../interfaces/BankReconciliationInterface";

/*
Here the Data is segmented by Ledger Name, Then
1. Opening Balance should be at first row
2. Closing Balance should be at last row
3. Between them the items should be sorted by date.
*/
const formatData = (
  rcData: BankReconciliationReportInterface[]
): BankReconciliationReportInterface[] => {
  const openingBalanceItems = rcData.filter((data) => data.openingBalance);
  const closingBalanceItems = rcData.filter((data) => data.closingBalance);
  const dataWithoutOpeningAndClosingBalance = [
    ...rcData.filter((data) => !data.openingBalance && !data.closingBalance)
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  return [
    ...openingBalanceItems,
    ...dataWithoutOpeningAndClosingBalance,
    ...closingBalanceItems
  ].map((item, index) => ({ ...item, id: index + 1 }));
};

const BankReconciliationReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState<FilterProps>({
    ledgerIds: [],
    periodDate: new Date()
  } as FilterProps);

  const [reportData, setReportData] = React.useState<BankReconciliationReportInterface[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await reportsApi.getBankReconciliationReport(filters);
        const formattedData = formatData(response);
        setReportData(formattedData);
        if (!response.length) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "info",
              message: "No Data Found.",
              autoTimeout: true
            })
          );
        }
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error.data ?? "Sorry ! Something Went Wrong.",
            autoTimeout: true
          })
        );
      }
    })();
  }, [filters, dispatch]);

  const isMobileScreen = useMobileScreen();

  return (
    <Box marginTop="32px">
      <Box margin="0px 32px">
        <Filters
          ledgerFilter={[LedgerType.BANK_LEDGER]}
          filters={filters as FilterProps}
          setFilters={(newFilter) => setFilters(newFilter)}
        />
      </Box>
      <Box
        width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}
        style={{ overflowX: isMobileScreen ? "scroll" : "auto" }}
        mx="auto"
      >
        <Box
          className="bankReconciliationReport"
          marginTop="32px"
          width={isMobileScreen ? "960px" : "auto"}
          height="calc(100vh - 225px)"
        >
          <List
            automation="bankReconciliationReport"
            data={reportData}
            rowHeight={50}
            defaultSortColumn="ledgerName"
            defaultSortOrder={-1}
            activeRow={0}
            adjustHeightToContents
            columns={[
              {
                key: "date",
                label: tl("reports.date"),
                sortable: true,
                formatter: ({ date }) => (
                  <Typography
                    style={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {convertADtoBS(new Date(date)).formatted4}
                  </Typography>
                )
              },
              {
                segmentable: true,
                segmentBy: ({ ledgerName }) => ledgerName,
                key: "ledgerName",
                label: tl("accounts.ledgerName"),
                sortable: true,
                formatter: ({ ledgerName }) => <Typography>{ledgerName}</Typography>
              },
              {
                key: "voucherNumber",
                label: tl("accounts.voucherNumber"),
                sortable: true,
                formatter: ({ voucherNumber }) => <Typography>{voucherNumber}</Typography>
              },
              {
                key: "amount",
                label: tl("billing.amount"),
                sortable: true,
                formatter: ({ amount }) => <Typography>{rupeeDisplay(amount)}</Typography>
              },
              {
                key: "bankReconciliationDate",
                label: tl("accounts.bankReconciliationDate"),
                sortable: true,
                formatter: ({ bankReconciliationDate }) => (
                  <Typography>
                    {bankReconciliationDate
                      ? convertADtoBS(new Date(bankReconciliationDate)).formatted4
                      : ""}
                  </Typography>
                )
              },
              {
                key: "openingClosingBalanceRemark",
                label: tl("accounts.openingClosingBalanceRemark"),
                sortable: true,
                formatter: ({ openingClosingBalanceRemark }) => (
                  <Typography>{openingClosingBalanceRemark}</Typography>
                )
              }
            ]}
            segementSummaryRenderer={(acc) => (
              <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                <Box display="flex" flexGrow={1}>
                  <Typography>
                    <Box
                      component="span"
                      flexGrow={1}
                      display="flex"
                      padding="8px 32px 4px 20px"
                      fontWeight={500}
                    >
                      {acc.segment || "N/A"}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default BankReconciliationReport;

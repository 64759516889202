import { Box, Button, Menu, Typography } from "@mui/material";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import BlockIcon from "@mui/icons-material/Block";
import Tooltip from "@mui/material/Tooltip";
import { push } from "connected-react-router";
import queryString from "query-string";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import produce from "immer";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import { uploadClientImage } from "../../actions/client";
import { clientFullNameSelector } from "../../reducers/client";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import {
  getMedicalTestById,
  patchMedicalTest,
  updateMedicalTestsRecordSample
} from "../../actions/medicalTest";
import ForeignEmploymentPrint from "../Lab/ForeignEmploymentPrint";
import ClientImageUploader from "../../components/ImageUploader/ClientImageUploader";
import ShowVitals from "./Assessment/ShowVitals";
import ShowAssessment from "./Assessment/ShowAssessment";
import ShowTestResult from "./Assessment/ShowTestResult";
import LabStatusChip from "../Lab/LabStatusChip";
import Collapse from "../../components/CollapseBar/CollapseBar";
import MedicalTestStatusTypes from "../../interfaces/Medical";
import OkhatiDialog from "../../components/Dialog/Dialog";
import styles from "./Lab.module.css";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";
import { LabStatuses } from "../../interfaces/Lab";
import { RootState } from "../../store";
import CopyToClipboard from "../../components/ClipboardCopy/ClipboardCopy";
import { getAppBaseUrl } from "../../helpers/urls";

interface Props {
  handleClose: () => void;
  id: number;
  medicalTest: any;
  navigateTo: (v) => void;
  uploadPhoto: (id: number, image: string) => void;
  updateSampleCollected: (
    medicalTestId: number,
    status: string,
    stateRemark?: string,
    id?: number,
    name?: string
  ) => void;
}

function MedicalDetails({
  handleClose,
  id,
  medicalTest,
  navigateTo,
  uploadPhoto,
  updateSampleCollected
}: Props) {
  const dispatch = useDispatch();

  const { openVitals } = queryString.parse(window.location.search);

  const [collapseAssessment, setCollapseAssessment] = React.useState(true);
  const [collapseVitals, setCollapseVitals] = React.useState(!openVitals);
  const [collapseTests, setCollapseTests] = React.useState(Boolean(openVitals));
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const clientInfoViewHandle = () => {
    setShowClientInfoPanel(false);
  };

  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const examDate = React.useRef(null);

  const rc = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre
  );

  const authorizedSP = rc?.settings?.medicalSettings;

  React.useEffect(() => {
    if (id) {
      dispatch(getMedicalTestById(id));
    }
  }, [id, dispatch]);

  if (!medicalTest) {
    return null;
  }

  return (
    <Box>
      <Panel
        titleJsx={
          <Box
            style={{ width: "100%" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={1}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{tl("medical.head.medicaltest")}</Typography>
              <Box component="span" ml="16px">
                <LabStatusChip status={medicalTest.status?.toLowerCase()} />
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {medicalTest.results.assessment && (
                <CopyToClipboard
                  TooltipProps={{
                    title: "Medical report link is copied to clipboard!"
                  }}
                >
                  {({ copy }) => (
                    <Tooltip title="Copy link" aria-label="copylink">
                      <FileCopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          copy(`${getAppBaseUrl()}/my-medical/${medicalTest.uuid}`);
                        }}
                      />
                    </Tooltip>
                  )}
                </CopyToClipboard>
              )}

              <Box display="flex">
                {medicalTest.status !== MedicalTestStatusTypes.CANCELED && (
                  <Tooltip
                    PopperProps={{ disablePortal: true }}
                    style={{ zIndex: 1, padding: 0 }}
                    title={tl("medical.cancelMedicalTest")}
                  >
                    <IconButton
                      onClick={() => setIsDialogOpen(true)}
                      data-testmation="cancelmedicaltest"
                      size="large"
                    >
                      <BlockIcon className={styles.icon} />
                    </IconButton>
                  </Tooltip>
                )}
                {medicalTest.results.assessment && (
                  <Box>
                    <ForeignEmploymentPrint
                      buttonText={<PrintIcon />}
                      labRecord={medicalTest}
                      assessment={[medicalTest.results.assessment]}
                    />
                  </Box>
                )}
                {medicalTest.status === MedicalTestStatusTypes.RESULT_READY && (
                  <>
                    <Button onClick={(e) => setAnchorEl(e.currentTarget)}>Edit Exam Date</Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <Box alignItems="center" gap={1} p={1} width="210px" display="flex">
                        <CalendarDropdown
                          disable={false}
                          TextFieldProps={{ fullWidth: true, variant: "outlined", margin: "dense" }}
                          date={
                            medicalTest.results.examDate
                              ? new Date(medicalTest.results.examDate)
                              : new Date()
                          }
                          onChange={(newDate) => {
                            examDate.current = moment(newDate).toISOString();
                          }}
                        />
                        <Button
                          disabled={saving}
                          onClick={async () => {
                            setSaving(true);
                            const updatedResult = produce(medicalTest.results, (draft) => {
                              draft.examDate = examDate.current;
                            });
                            const toPost = {
                              attachments: [],
                              authorizedSP1Id: authorizedSP.authorizedSP1,
                              authorizedSP2Id: authorizedSP.authorizedSP2,
                              authorizedSP3Id: authorizedSP.authorizedSP3,
                              authorizedSP4Id: authorizedSP.authorizedSP4,
                              final: medicalTest.status !== LabStatuses.INCOMPLETE_RESULT,
                              updatedTestResults: updatedResult
                            };
                            await dispatch(patchMedicalTest(toPost, id));
                            setAnchorEl(null);
                            setSaving(false);
                          }}
                          size="small"
                          variant="contained"
                        >
                          Save
                        </Button>
                      </Box>
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        }
        onClose={handleClose}
        wrapperStyle={{ minWidth: "860px" }}
      >
        {isDialogOpen && (
          <OkhatiDialog
            title={tl("medical.cancelMedicalTest")}
            description={tl("medical.areYouSureCancel")}
            next={async () => {
              await updateSampleCollected(id, MedicalTestStatusTypes.CANCELED);
              setIsDialogOpen(false);
            }}
            cancel={() => {
              setIsDialogOpen(false);
            }}
            readMode={false}
          />
        )}
        <Box px={5}>
          <Box display="flex" justifyContent="flex-end">
            {medicalTest.status === MedicalTestStatusTypes.ORDERED && (
              <Button
                data-testmation="addTestResult"
                variant="contained"
                justify-content="flex-end"
                color="primary"
                onClick={() => navigateTo(`/medical/entry/${id}`)}
              >
                {tl("lab.enterTestResults")}
              </Button>
            )}
          </Box>
          <Typography>
            <Box display="flex">
              <Typography style={{ width: "120px" }}>{tl("lab.testId")}</Typography>
              <Typography>
                <Box component="span" fontWeight="600">
                  {id}
                </Box>
              </Typography>
            </Box>
            <Box display="flex" mt="16px">
              <Box flexDirection="column" justifyContent="space-between" mr="20px">
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.client")}</Typography>
                  <Typography
                    style={{ fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      setShowClientInfoPanel(true);
                    }}
                  >
                    {clientFullNameSelector(medicalTest?.client).toUpperCase()}
                  </Typography>
                </Box>

                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.phoneNo")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {medicalTest.client?.phone}
                    </Box>
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.address")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {medicalTest.client?.address}
                    </Box>
                  </Typography>
                </Box>
                {medicalTest.client.email && (
                  <>
                    <Box display="flex">
                      <Typography style={{ width: "120px" }}>{tl("medical.email")}</Typography>
                      <Typography>
                        <Box component="span" fontWeight="600">
                          {medicalTest.client.email}
                        </Box>
                      </Typography>
                    </Box>
                  </>
                )}
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("medical.clientId")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {medicalTest.client.id}
                    </Box>
                  </Typography>
                </Box>
              </Box>

              <Box justifyContent="flex-end">
                <ClientImageUploader
                  action={(image) => {
                    uploadPhoto(medicalTest.clientId, image);
                  }}
                  initialImage={medicalTest.customer?.customerDetails?.profileImageS3Url || ""}
                />
              </Box>
            </Box>

            {medicalTest.referrers && (
              <>
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("medical.referredBy")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {medicalTest.referrers}
                    </Box>
                  </Typography>
                </Box>
              </>
            )}
          </Typography>
          {medicalTest.status === MedicalTestStatusTypes.ORDERED ||
          medicalTest.status === MedicalTestStatusTypes.CANCELED ? (
            <>
              <Typography style={{ paddingTop: "32px" }}>
                <Box component="span" fontWeight="600">
                  {tl("lab.testParticulars")}
                </Box>
              </Typography>
              <Typography>
                <Box>Medical Test</Box>
                {medicalTest.results.data[0].labTests.map((item) => (
                  <Box component="span" key={item.name}>
                    {item.name},
                  </Box>
                ))}
              </Typography>
            </>
          ) : (
            <Box marginTop="32px">
              <Collapse
                label={
                  <Box display="flex">
                    <Typography>{tl("medical.testResult")}</Typography>
                    <CheckCircleIcon
                      htmlColor={
                        medicalTest.status !== MedicalTestStatusTypes.INCOMPLETE_RESULT
                          ? "#009654"
                          : "#808080"
                      }
                      style={{ marginLeft: "16px" }}
                    />
                  </Box>
                }
                collapsed={collapseTests}
              >
                <ShowTestResult medicalTest={medicalTest} navigateTo={navigateTo} id={id} />
              </Collapse>
              <Collapse
                label={
                  <Box display="flex">
                    <Typography>{tl("medical.vitals")}</Typography>
                    {medicalTest.results.vitals && (
                      <CheckCircleIcon htmlColor="#009654" style={{ marginLeft: "16px" }} />
                    )}
                  </Box>
                }
                collapsed={collapseVitals}
                onCollapse={() => {
                  navigateTo({ pathname: `/medical/details/${id}`, search: "" });
                }}
              >
                <ShowVitals
                  vitals={medicalTest?.results?.vitals ? medicalTest?.results?.vitals : ""}
                  nextAction={() => {
                    setCollapseAssessment(false);
                    setCollapseVitals(true);
                    setCollapseTests(true);
                  }}
                  id={id}
                />
              </Collapse>

              <Collapse
                label={
                  <Box display="flex">
                    <Typography>{tl("medical.assessment")}</Typography>
                    {medicalTest.results.assessment && (
                      <CheckCircleIcon htmlColor="#009654" style={{ marginLeft: "16px" }} />
                    )}
                  </Box>
                }
                collapsed={collapseAssessment}
              >
                <ShowAssessment
                  assessment={
                    medicalTest?.results?.assessment ? medicalTest?.results?.assessment : ""
                  }
                  id={id}
                />
              </Collapse>
              <Box display="flex" justifyContent="flex-end">
                {medicalTest.results.assessment && (
                  <Box>
                    <ForeignEmploymentPrint
                      buttonText={
                        <Button variant="contained" color="primary">
                          {tl("lab.print")}
                        </Button>
                      }
                      labRecord={medicalTest}
                      assessment={[medicalTest.results.assessment]}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {showClientInfoPanel &&
          medicalTest?.client &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={medicalTest?.client?.id}
              mode="edit"
              onCancel={() => {
                setClientEditMode(false);
              }}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              setEditMode={setClientEditMode}
              id={medicalTest?.client.id}
              handleViewClose={clientInfoViewHandle}
              wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
              stayOnCurrentPage
            />
          ))}
      </Panel>
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  const medicalTest = state.medicalTest.collection.find((item) => item.id === Number(ownProps.id));

  return {
    medicalTest
  };
}

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (url) => {
    dispatch(push(url));
  },
  uploadPhoto: (id, image) => dispatch(uploadClientImage(id, image)),
  updateTest: (data, id) => dispatch(patchMedicalTest(data, id)),
  updateSampleCollected: (medicalTestId, status, remark, id, name) => {
    dispatch(updateMedicalTestsRecordSample({ medicalTestId, status, remark, id, name }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalDetails);

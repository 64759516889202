import React from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";
import moment from "moment-timezone";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../../mixpanel-analytics/event";
import usePrintStyles from "../../../hooks/usePrintStyles";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

export interface PrintLabBarCodeInterface {
  barCode: string;
  productName: string;
  handleMenuClose: () => void;
  isMultiplePrint?: boolean;
}

export const barCodePrintPageStyle = "@page { size: 50mm 25mm; }";

export const barCodePrintPageStyleForThreeLabels = "@page { size: 120mm 25mm; }";

export const PrintTypography = ({
  text,
  fontSize = "2.3mm",
  maxWidth = "auto"
}: {
  text: string;
  fontSize?: string;
  maxWidth?: string;
}): JSX.Element => (
  <Typography
    sx={{
      fontSize,
      fontFamily: "sans-serif",
      fontWeight: "bold",
      color: "#000000",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth
    }}
  >
    {text}
  </Typography>
);

const PrintContent = ({ name, barCode, date }: { name: string; barCode: string; date: string }) => (
  <Box display="flex" flexDirection="column">
    <PrintTypography text={name} fontSize="2.4mm" maxWidth="45mm" />
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Barcode value={barCode} width={1.1} displayValue={false} height={50} margin={0} />
    </Box>
    <Box display="flex" justifyContent="space-between">
      <PrintTypography text={barCode} />
      <PrintTypography text={date} />
    </Box>
  </Box>
);

const PrintBarCode = ({
  barCode,
  productName,
  handleMenuClose,
  isMultiplePrint = false
}: PrintLabBarCodeInterface): JSX.Element => {
  const currentDateAndTime = moment().format();
  const rc = useCurrentResourceCentre();
  const BSDate = convertADtoBS(moment.utc(currentDateAndTime.slice(0, 10))).formatted;
  const printRef = React.useRef(null);
  const print = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => {
      mixpanelAnalytics.track(EVENT.PRINT_LAB_BARCODE, { rcId: rc.id, rcName: rc.name });
    }
  });

  return (
    <div>
      <MenuItem
        onClick={() => {
          print();
          handleMenuClose();
        }}
        onMouseEnter={() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          usePrintStyles({
            pageStyle: isMultiplePrint ? barCodePrintPageStyleForThreeLabels : barCodePrintPageStyle
          }).applyPrintStyles();
        }}
        onMouseLeave={() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          usePrintStyles({
            pageStyle: "size: A4; margin: 0mm"
          }).applyPrintStyles();
        }}
      >
        {isMultiplePrint ? "3 Barcodes side by side" : "Single barcode"}
      </MenuItem>
      <iframe title="product_bar_code_container" style={{ display: "none" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            ...(isMultiplePrint
              ? { justifyContent: "space-evenly", gap: "2mm" }
              : { justifyContent: "center" })
          }}
          ref={printRef}
        >
          <PrintContent name={productName} date={BSDate} barCode={barCode} />
          {isMultiplePrint && (
            <>
              <PrintContent name={productName} date={BSDate} barCode={barCode} />
              <PrintContent name={productName} date={BSDate} barCode={barCode} />
            </>
          )}
        </Box>
      </iframe>
    </div>
  );
};

export default PrintBarCode;

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, Typography, Checkbox, Button } from "@mui/material";
import queryString from "query-string";
import { match as matchInterface } from "react-router";
import { tl } from "../../../components/translate";
import LabEntryInformation from "./LabEntryInformation";
import LabStickyFooter from "./LabStickyFooter";
import styles from "../Lab.module.css";
import * as labTestActions from "../../../actions/labTest";
import PathologyContent from "./PathologyContent";
import RadiologyContent from "./RadiologyContent";
import Panel from "../../../components/Panel";
import { serviceProviderActions as spActions } from "../../../actions";
import { getLabTestRecord } from "../../../actions/labTest";
import { LabRecord, LabStatuses } from "../../../interfaces/Lab";
import hasOwnProperty from "../../../helpers/object";
import { IThunkDispatch, RootState } from "../../../store";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { User } from "../../../interfaces/User";
import { getSpSignatureGroup } from "../EntryV2/EntryActions";

interface AuthorizedSPIdParams {
  currentId: number;
  staticId: number;
  dynamicState: string;
  enteredById: number;
  assessedById: number;
  approvedById: number;
}

export const getAuthorizedSPId = ({
  currentId,
  staticId,
  dynamicState,
  enteredById,
  assessedById,
  approvedById
}: AuthorizedSPIdParams): number => {
  if (currentId) return currentId;
  if (dynamicState === "Entered by") return enteredById;
  if (dynamicState === "Assessed by") return assessedById;
  if (dynamicState === "Approved by") return approvedById;
  return staticId;
};
interface LabEntryInterface {
  labRecord: LabRecord;
  nextLabRecord: LabRecord;
  navigateTo: (path) => void;
  match: matchInterface<{ labId: number }>;
  onSave: (labData: {
    labId: number;
    labRecordData: LabRecord;
    final: boolean;
    requestApproval: boolean;
    editAnother: boolean;
    authorizedSP1Id: number;
    authorizedSP2Id: number;
    authorizedSP3Id: number;
    authorizedSP4Id: number;
  }) => void;
  user: Partial<User>;
  userType: string;
  serviceProviders: ServiceProvider[];
  resourceCentreId: number;
  loadServiceProviders: (id) => void;
  authorizedSP1Id: number;
  authorizedSP2Id: number;
  authorizedSP3Id: number;
  authorizedSP4Id: number;
  authorizedSP1Dynamic: string;
  authorizedSP2Dynamic: string;
  authorizedSP3Dynamic: string;
  authorizedSP4Dynamic: string;
  getLabTestRecordById: (id: number) => void;
  showMethods: boolean;
  allowUpload: boolean;
}

const LabEntry: React.FC<LabEntryInterface> = ({
  labRecord,
  nextLabRecord,
  navigateTo,
  match,
  onSave,
  user,
  userType,
  serviceProviders,
  resourceCentreId,
  loadServiceProviders,
  authorizedSP1Id,
  authorizedSP2Id,
  authorizedSP3Id,
  authorizedSP4Id,
  authorizedSP1Dynamic,
  authorizedSP2Dynamic,
  authorizedSP3Dynamic,
  authorizedSP4Dynamic,
  getLabTestRecordById,
  showMethods,
  allowUpload
}) => {
  const [labRecordData, setLabRecordData] = React.useState(
    {} as LabRecord & { attachments?: Array<unknown> }
  );
  const [technicianId, setTechnicianId] = React.useState(
    queryString.parse(window.location.search)?.technicianId
      ? Number(queryString.parse(window.location.search)?.technicianId)
      : null
  );
  const [editAnother, setEditAnother] = React.useState(false);

  const isServiceProvider = userType === "serviceProvider";

  const finalAuthorizedSP1 = isServiceProvider ? user.id : authorizedSP1Id;
  const finalAuthorizedSP2 = isServiceProvider ? user.id : authorizedSP2Id;
  const finalAuthorizedSP3 = isServiceProvider ? user.id : authorizedSP3Id;
  const finalAuthorizedSP4 = isServiceProvider ? user.id : authorizedSP4Id;

  React.useEffect(() => {
    if (Object.keys(labRecord || {}).length) {
      if (hasOwnProperty(user, "role")) {
        if (user.role === "3" || user.role === "1") {
          setLabRecordData({
            ...labRecord,
            results: { ...labRecord.results, labTechnicianId: user.id }
          });
          setTechnicianId(user.id);
        }
      } else {
        setLabRecordData({
          ...labRecord,
          results: { ...labRecord.results, labTechnicianId: technicianId }
        });
      }
      setTimeout(() => {
        (document.getElementsByClassName("labTestReading")[0] as HTMLInputElement)?.focus();
      });
    }
  }, [labRecord]);

  React.useEffect(() => {
    if (!serviceProviders.length) loadServiceProviders(resourceCentreId);
  }, []);

  React.useEffect(() => {
    if (!labRecord?.remarks) getLabTestRecordById(match.params.labId);
  }, [match.params.labId]);
  return (
    <Panel
      onClose={() => {
        navigateTo("/lab/labRecords");
      }}
      wrapperStyle={{ maxwidth: "820px", minWidth: "820px" }}
      footer={
        labRecordData &&
        Object.keys(labRecordData).length > 0 && (
          <Box
            width={1}
            paddingRight={1.5}
            height="50px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={editAnother && Boolean(nextLabRecord)}
                data-testmation="footerCheckbox"
                onChange={() => setEditAnother(!editAnother)}
              />
              <Typography>
                <Box>
                  {labRecordData.status === LabStatuses.RESULT_READY
                    ? tl("labTest.showNextTestResult")
                    : tl("labTest.enterNextTestResultAfterSave")}
                </Box>
              </Typography>
            </Box>

            <LabStickyFooter
              status={labRecordData.status}
              resultDate={labRecordData.resultDate}
              skipButton={
                nextLabRecord && editAnother ? (
                  <Button
                    variant="contained"
                    disabled={!nextLabRecord}
                    onClick={async () => {
                      if (nextLabRecord) {
                        navigateTo(
                          `/lab/labRecords/entry/${nextLabRecord.id}?status=${nextLabRecord.status}`
                        );
                      }
                    }}
                    data-tesmation="lab.hopLabTests"
                  >
                    <Typography>
                      {labRecordData.status === LabStatuses.RESULT_READY
                        ? tl("lab.next")
                        : tl("lab.skip")}
                    </Typography>
                  </Button>
                ) : null
              }
              onClose={() => navigateTo("/lab/labRecords")}
              onSaveAsDraft={async () => {
                await onSave({
                  labId: match.params.labId,
                  labRecordData,
                  final: false,
                  requestApproval: false,
                  editAnother,
                  authorizedSP1Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP1?.id,
                    staticId: null,
                    dynamicState: authorizedSP1Dynamic,
                    enteredById: finalAuthorizedSP1,
                    assessedById: null,
                    approvedById: null
                  }),
                  authorizedSP2Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP2?.id,
                    staticId: null,
                    dynamicState: authorizedSP2Dynamic,
                    enteredById: finalAuthorizedSP2,
                    assessedById: null,
                    approvedById: null
                  }),
                  authorizedSP3Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP3?.id,
                    staticId: null,
                    dynamicState: authorizedSP3Dynamic,
                    enteredById: finalAuthorizedSP3,
                    assessedById: null,
                    approvedById: null
                  }),
                  authorizedSP4Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP4?.id,
                    staticId: null,
                    dynamicState: authorizedSP4Dynamic,
                    enteredById: finalAuthorizedSP4,
                    assessedById: null,
                    approvedById: null
                  })
                });
                if (editAnother && nextLabRecord) {
                  navigateTo(
                    `/lab/labRecords/entry/${nextLabRecord.id}?status=${nextLabRecord.status}`
                  );
                }
              }}
              onRequestApproval={async () => {
                await onSave({
                  labId: match.params.labId,
                  labRecordData,
                  final: false,
                  requestApproval: true,
                  editAnother,
                  authorizedSP1Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP1?.id,
                    staticId: null,
                    dynamicState: authorizedSP1Dynamic,
                    enteredById: finalAuthorizedSP1,
                    assessedById: finalAuthorizedSP1,
                    approvedById: null
                  }),
                  authorizedSP2Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP2?.id,
                    staticId: null,
                    dynamicState: authorizedSP2Dynamic,
                    enteredById: finalAuthorizedSP2,
                    assessedById: finalAuthorizedSP2,
                    approvedById: null
                  }),
                  authorizedSP3Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP3?.id,
                    staticId: null,
                    dynamicState: authorizedSP3Dynamic,
                    enteredById: finalAuthorizedSP3,
                    assessedById: finalAuthorizedSP3,
                    approvedById: null
                  }),
                  authorizedSP4Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP4?.id,
                    staticId: null,
                    dynamicState: authorizedSP4Dynamic,
                    enteredById: finalAuthorizedSP4,
                    assessedById: finalAuthorizedSP4,
                    approvedById: null
                  })
                });
                if (editAnother && nextLabRecord) {
                  navigateTo(
                    `/lab/labRecords/entry/${nextLabRecord.id}?status=${nextLabRecord.status}`
                  );
                }
              }}
              onFinalize={async () => {
                await onSave({
                  labId: match.params.labId,
                  labRecordData,
                  final: true,
                  requestApproval: false,
                  editAnother,
                  authorizedSP1Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP1?.id,
                    staticId: authorizedSP1Id,
                    dynamicState: authorizedSP1Dynamic,
                    enteredById: finalAuthorizedSP1,
                    assessedById: finalAuthorizedSP1,
                    approvedById: finalAuthorizedSP1
                  }),
                  authorizedSP2Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP2?.id,
                    staticId: authorizedSP2Id,
                    dynamicState: authorizedSP2Dynamic,
                    enteredById: finalAuthorizedSP2,
                    assessedById: finalAuthorizedSP2,
                    approvedById: finalAuthorizedSP2
                  }),
                  authorizedSP3Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP3?.id,
                    staticId: authorizedSP3Id,
                    dynamicState: authorizedSP3Dynamic,
                    enteredById: finalAuthorizedSP3,
                    assessedById: finalAuthorizedSP3,
                    approvedById: finalAuthorizedSP3
                  }),
                  authorizedSP4Id: getAuthorizedSPId({
                    currentId: labRecordData?.results?.approvedBy?.authorizedSP4?.id,
                    staticId: authorizedSP4Id,
                    dynamicState: authorizedSP4Dynamic,
                    enteredById: finalAuthorizedSP4,
                    assessedById: finalAuthorizedSP4,
                    approvedById: finalAuthorizedSP4
                  })
                });
                if (editAnother && nextLabRecord) {
                  navigateTo(
                    `/lab/labRecords/entry/${nextLabRecord.id}/?status=${nextLabRecord.status}`
                  );
                }
              }}
              positionControlByParent
            />
          </Box>
        )
      }
    >
      <Box className={styles.labEntry}>
        {labRecordData && Object.keys(labRecordData).length > 0 && (
          <>
            <Box className={styles.labEntryHeader}>
              <Box className={styles.labEntryHeaderText}>
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="16px">
                    {tl("lab.labTestResultsEntry")}
                  </Box>
                </Typography>
              </Box>
              <LabEntryInformation
                client={labRecordData.client}
                serviceProvider={labRecordData.serviceProvider}
                orderedOn={labRecordData.created_at}
                testId={labRecordData.id}
                customer={labRecordData.customer}
                allowUpload={allowUpload}
                printMode={false}
                referrers={labRecordData.referrers}
              />
            </Box>

            {labRecordData.type === "pathology" && (
              <PathologyContent
                labRecord={labRecordData}
                showMethods={showMethods}
                onLabRecordResultsChange={(val) => {
                  setLabRecordData({
                    ...labRecordData,
                    results: { ...labRecordData.results, data: val }
                  });
                }}
                onCommentChange={(val) => {
                  setLabRecordData({
                    ...labRecordData,
                    results: { ...labRecordData.results, comment: val }
                  });
                }}
                onFilesChange={(files) =>
                  setLabRecordData({ ...labRecordData, attachments: files })
                }
                printMode={false}
              />
            )}

            {(labRecordData.type === "radiology" || labRecordData.type === "cytology") && (
              <Box>
                <RadiologyContent
                  labRecord={labRecord}
                  onLabRecordResultsChange={(val) => {
                    setLabRecordData({
                      ...labRecordData,
                      results: { ...labRecordData.results, data: val }
                    });
                  }}
                  onCommentChange={(val) =>
                    setLabRecordData({
                      ...labRecordData,
                      results: { ...labRecordData.results, comment: val }
                    })
                  }
                  onFilesChange={(files) =>
                    setLabRecordData({ ...labRecordData, attachments: files })
                  }
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Panel>
  );
};

function mapStateToProps(state: RootState, ownProps) {
  const rc =
    state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  let activeLabTestIndex = 0;
  const labRecord = state.labTest?.labTestRecords?.find((lr, index) => {
    const isActive = Number(ownProps.match.params.labId) === lr.id;
    if (isActive) activeLabTestIndex = index;
    return isActive;
  });

  let nextLabRecord = null;
  if (labRecord) {
    if (state.labTest?.labTestRecords.length - 1 >= activeLabTestIndex + 1) {
      nextLabRecord = state.labTest.labTestRecords[activeLabTestIndex + 1];
    }
  }

  const { resourceCentreId } = state.userContext;
  const serviceProviders = state.resources.resourceCentreServiceProviders;
  const spSignatureGroup = getSpSignatureGroup(labRecord, rc);
  const [authorizedSP1Id, authorizedSP2Id, authorizedSP3Id, authorizedSP4Id] = [
    spSignatureGroup.authorizedSP1,
    spSignatureGroup.authorizedSP2,
    spSignatureGroup.authorizedSP3,
    spSignatureGroup.authorizedSP4
  ];

  const [authorizedSP1Dynamic, authorizedSP2Dynamic, authorizedSP3Dynamic, authorizedSP4Dynamic] = [
    spSignatureGroup.authorizedSP1Dynamic,
    spSignatureGroup.authorizedSP2Dynamic,
    spSignatureGroup.authorizedSP3Dynamic,
    spSignatureGroup.authorizedSP4Dynamic
  ];
  const showMethods: boolean = rc?.labSettings?.showMethods;
  const allowUpload = rc?.settings.clientSettings.enablePictureUpload;
  return {
    labRecord,
    nextLabRecord,
    user: state?.userContext?.user,
    userType: state?.userContext?.userCreds?.authenticable,
    serviceProviders,
    resourceCentreId,
    authorizedSP1Id,
    authorizedSP2Id,
    authorizedSP3Id,
    authorizedSP4Id,
    authorizedSP1Dynamic,
    authorizedSP2Dynamic,
    authorizedSP3Dynamic,
    authorizedSP4Dynamic,
    showMethods,
    allowUpload
  };
}

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  loadServiceProviders: (id) =>
    dispatch(spActions.getResourceCentreServiceProviders({ resourceCentreId: id })),
  navigateTo: (path) => {
    dispatch(push(path));
  },
  getLabTestRecordById: (id) => dispatch(getLabTestRecord(id)),
  onSave: async ({
    labId,
    labRecordData,
    final,
    requestApproval,
    editAnother,
    authorizedSP1Id,
    authorizedSP2Id,
    authorizedSP3Id,
    authorizedSP4Id
  }) => {
    const updatedTestData = {
      attachments: labRecordData.attachments,
      updatedTestResults: labRecordData.results,
      final,
      requestApproval,
      authorizedSP1Id,
      authorizedSP2Id,
      authorizedSP3Id,
      authorizedSP4Id
    };
    await dispatch(labTestActions.updateLabTestsRecord(labId, updatedTestData, editAnother));
  }
}))(LabEntry);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { round } from "mathjs";
import { Box, Typography } from "@mui/material";
import { getStockTransactionReports } from "../../api/reports";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";

import Filters from "./Filters";
import { notificationAdd } from "../../actions/notification";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { RootState } from "../../store";
import { getValue } from "../../helpers/formatters";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import ListHeaderShowHideDialog from "../../components/ListHeaderShowHideDialog/ListHeaderShowHideDialog";
import {
  getDefaultKeyValuesColumns,
  stockTransactionDefaultCols
} from "../../components/ListHeaderShowHideDialog/helpers";
import { TransactionType } from "../../interfaces/StockInterfaces";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

export interface StockTransactionData {
  date: string;
  productId: number;
  batchId: number;
  discountAmt: number;
  quantity: number;
  grossTotal: number;
  purchasePrice: number;
  totalIncVat: number;
  supplierId: number;
}
const docColumns = [
  "Product Name",
  "Supplier Name",
  "Date",
  "Batch No.",
  "Sales Price",
  "Purchase Price",
  "Quantity",
  "Discount Amount",
  "Gross Total",
  "Including Vat"
];

const docRowProcessor = ({
  productName,
  supplierName,
  date,
  batchId,
  unitPriceIncVAT,
  purchasePrice,
  quantity,
  discountAmt,
  grossTotal,
  totalIncVat,
  // eslint-disable-next-line camelcase
  __meta__row_type
}) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    productName,
    supplierName,
    date,
    batchId,
    unitPriceIncVAT,
    purchasePrice,
    quantity,
    discountAmt,
    grossTotal,
    totalIncVat
  ].map((item) => getValue(`${item}`));
};

export default function StockTransactionReport(): JSX.Element {
  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    transactionType: "purchase",
    supplierIds: []
  });
  const dispatch = useDispatch();
  const suppliers = useSelector((state: RootState) => state.resources.suppliers);

  const [stockTransactionData, setStockTransactionData] = React.useState<StockTransactionData[]>(
    [] as StockTransactionData[]
  );
  const [listColumns, setListColumns] = React.useState(
    getDefaultKeyValuesColumns(stockTransactionDefaultCols)
  );
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getStockTransactionReports(filters);
        setStockTransactionData(response?.map((item, index) => ({ ...item, id: index })));
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            message: error?.data?.message || "Sorry! Something went wrong.",
            autoTimeout: true
          })
        );
      }
    })();
  }, [filters, dispatch, suppliers]);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1100px" : "auto"}>
        <Box m={4}>
          <Filters filters={filters} onSetFilters={(newFilters) => setFilters(newFilters)} />
        </Box>
        <Box className="stockTransactionReport">
          <List
            automation="stockCashFlowList"
            data={stockTransactionData}
            rowHeight={50}
            adjustHeightToContents
            defaultSortColumn="productName"
            defaultSortOrder={-1}
            columns={[
              {
                key: "productName",
                label: tl("reports.stock.productName"),
                segmentable: true,
                segmentBy: ({ productName }) => productName,
                sortable: true,
                formatter: ({ productName }) => <Typography>{productName}</Typography>
              },
              {
                key: "supplierName",
                label: tl("reports.supplierName"),
                sortable: true,
                formatter: ({ supplierName }) => <Typography>{supplierName}</Typography>
              },
              {
                key: "date",
                label: "date",
                sortable: true,
                formatter: ({ date }) => (
                  <Typography>{calFns.convertADtoBS(new Date(date)).formatted3}</Typography>
                )
              },
              {
                key: "batchId",
                label: tl("reports.batchId"),
                sortable: true,
                formatter: ({ batchId }) => <Typography>{batchId}</Typography>
              },
              {
                key: "unitPriceIncVAT",
                label: "Sales Price",
                sortable: true,
                formatter: ({ unitPriceIncVAT }) => (
                  <Typography>{unitPriceIncVAT ? round(unitPriceIncVAT) : "-"}</Typography>
                )
              },
              {
                key: "purchasePrice",
                label: "Purchase Price",
                sortable: true,
                formatter: ({ purchasePrice, perUnit }) => (
                  <Typography>
                    {round(
                      [TransactionType.SALES, TransactionType.SALES_RETURN].includes(
                        filters.transactionType as TransactionType
                      )
                        ? +perUnit || 0
                        : +purchasePrice || 0,
                      2
                    )}
                  </Typography>
                )
              },
              {
                key: "quantity",
                label: tl("reports.quantity"),
                sortable: true,
                formatter: ({ quantity }) => <Typography>{quantity}</Typography>
              },
              {
                key: "discountAmt",
                label: "Discount Amt",
                sortable: true,
                formatter: ({ discountAmt }) => (
                  <Typography>{round(discountAmt || 0, 2)}</Typography>
                )
              },
              {
                key: "vatAmt",
                label: "Vat Amt",
                formatter: ({ vatAmt }) => <Typography>{vatAmt || 0}</Typography>
              },
              {
                key: "grossTotal",
                label: tl("reports.itemGrossTotal"),
                sortable: true,
                formatter: ({ grossTotal }) => <Typography>{round(grossTotal || 0, 2)}</Typography>
              }
            ].filter((row) => listColumns[row.key])}
            segementSummaryRenderer={(acc) => (
              <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                <Box display="flex" flexGrow={1}>
                  <Typography>
                    <Box
                      component="span"
                      flexGrow={1}
                      display="flex"
                      padding="8px 32px 4px 20px"
                      fontWeight={500}
                    >
                      {acc.segment || "N/A"}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
            <ListActions>
              {({ getProcessedData }) => (
                <Menu>
                  <MenuItem
                    onClick={async () => {
                      try {
                        await downloadExcel(
                          "Stock Transactions Report",
                          formatHeaderForExcel(docColumns),
                          formatDataForExcel(getProcessedData(), docRowProcessor)
                        );
                      } catch (err) {
                        dispatch(
                          notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: "Failed to download Excel.",
                            autoTimeout: true
                          })
                        );
                      }
                    }}
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                  <MenuItem onClick={() => setOpen(true)}>Show/Hide Columns</MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
          <ListHeaderShowHideDialog
            onChange={(updatedColumns) => setListColumns(updatedColumns)}
            requiredColumns={["productName"]}
            open={open}
            onClose={() => setOpen(false)}
            columns={listColumns}
          />
          <Box className="totalBar">
            <Box display="flex" paddingLeft="20px">
              {t("reports.total")}
            </Box>
            <Box display="flex" flexBasis="192px" paddingRight="26px" justifyContent="flex-end">
              <Typography>
                {round(
                  stockTransactionData.reduce((sum, row) => (+row.grossTotal || 0) + sum, 0),
                  2
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>{" "}
    </Box>
  );
}

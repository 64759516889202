import axios from "axios";
import { IBookableResource } from "../interfaces/BookableResources";
import { IServiceTag } from "../interfaces/ServiceTagsInterface";
import { Get, Post, Delete, Put } from "./apiHelper";

const bookableResourcesRoot = "/api/bookableResources";
const serviceTagsRoot = "/api/serviceTags";

export async function postBookableResource(
  bookableResource: IBookableResource
): Promise<IBookableResource> {
  const response = await Post(`${bookableResourcesRoot}`, bookableResource, true);
  return response.data as IBookableResource;
}

export async function updateBookableResource(
  bookableResource: IBookableResource
): Promise<IBookableResource> {
  const response = await Put(
    `${bookableResourcesRoot}/${bookableResource.id}`,
    bookableResource,
    true
  );
  return response.data as IBookableResource;
}

export async function getBookingResources(): Promise<Array<IBookableResource>> {
  const response = await Get(`${bookableResourcesRoot}`, true);
  return response.data as Array<IBookableResource>;
}

export async function deactivateBookableResource(id: number): Promise<unknown> {
  const response = await Delete(`${bookableResourcesRoot}/${id}`, true);
  return response.data;
}

export async function getServicetags(): Promise<Array<IServiceTag>> {
  const response = await Get(`${serviceTagsRoot}`, true);
  return response.data as Array<IServiceTag>;
}

export async function getMappedResourceNames(): Promise<Array<unknown>> {
  const response = await Get(`/api/mappedNames`, true);
  return response.data as Array<unknown>;
}

export const getS3UploadPostData = async (fileName: string): Promise<unknown> => {
  const response = await Post(`${bookableResourcesRoot}/profileImage`, { fileName });
  return response.data;
};

export const uploadIconImage = async (
  bookableResource: IBookableResource,
  pictureData: Blob
): Promise<unknown> => {
  const createUpdateResponse = bookableResource.id
    ? await updateBookableResource(bookableResource)
    : await postBookableResource(bookableResource);

  const fileName = `${createUpdateResponse.id}.${pictureData.type.split("/")[1]}`;
  const postData = (await getS3UploadPostData(fileName)) as {
    id: number;
    data: Record<string, any>;
  };
  const s3FileName = `${postData.id}/${fileName}`;
  const formData = new FormData();
  [
    "key",
    "bucket",
    "acl",
    "X-Amz-Algorithm",
    "X-Amz-Credential",
    "X-Amz-Date",
    "Policy",
    "X-Amz-Signature"
  ].forEach((k) => {
    formData.append(k, postData.data.fields[k]);
  });
  formData.append("file", pictureData, s3FileName);
  const imageResponse = await axios.post(postData.data.url, formData, {
    headers: {
      "Content-Type": "application/octet-stream"
    }
  });

  const response = await updateBookableResource({
    ...createUpdateResponse,
    details: {
      ...createUpdateResponse.details,
      iconUrl: imageResponse.headers.location
    }
  });

  return response;
};

import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { verifyInvoiceSuperAdmin } from "../../slices/subscriptionSlice";
import * as NotificationActions from "../../actions/notification";

interface Props {
  invoiceId: number;
  next: () => void;
}

export default function VerifyInvoiceButton(props: Props): JSX.Element {
  const { invoiceId, next } = props;

  const [confirmationOpen, setConfirmationOpen] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  return (
    <div>
      <Button onClick={() => setConfirmationOpen(true)} variant="contained">
        Verify Invoice
      </Button>

      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Verify Invoice?</DialogTitle>
        <Box width="100%" p="16px">
          <Typography>
            Are you sure you want to verify this invoice? This action cannot be undone!
          </Typography>
        </Box>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)}>Back</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(verifyInvoiceSuperAdmin(invoiceId));
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "success",
                  message: "Invoice successfully verified!",
                  autoTimeout: true
                })
              );
              setConfirmationOpen(false);
              next();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

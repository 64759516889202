import * as React from "react";
import ClientCreateEditForm from "./ClientCreateEditForm";

interface Props {
  clientId?: number;
  mode: string;
  onCancel: () => void;
  showLastCreated: () => void;
  stayOnCurrentPage: boolean;
  isCorporateClient?: boolean;
  setEditMode: () => void;
  afterSave?: () => void;
}

const ClientCreateEdit = (props: Props): JSX.Element => (
  <ClientCreateEditForm
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default ClientCreateEdit;

import * as api from "../api/addressOptions";
import { AppThunk, IThunkDispatch } from "../store";
import { actionCreatorAsync } from "./actionHelpers";

export enum Type {
  GET_ADDRESS_OPTIONS = "GET_ADDRESS_OPTIONS"
}

export const getAddressOptions = (): AppThunk => async (dispatch: IThunkDispatch) => {
  await dispatch(actionCreatorAsync(Type.GET_ADDRESS_OPTIONS, async () => api.getAddressOptions()));
};

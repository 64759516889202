import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database";
import { RootState } from "../store";
import { fetchUserProfiles, updateChatList, updateUnreadCount } from "../slices/chatSlice";
import { db } from "../../firebase/config";
import { PermissionGroups } from "../interfaces/User";

export interface ChatItem {
  from: number;
  message: string;
  timestamp: number;
  read: boolean;
}
export interface Chat {
  chatItems: { [key: number]: ChatItem };
  userId: number;
  lastMsgTimestmap: number;
  resourceCentreId: number;
}

export interface ChatThread {
  userId: string;
  resourceCentreId: string;
  lastMessage: ChatItem;
  chatKey: string;
  messages: ChatItem[];
  chatClosedBy: number;
}

export interface ReadableChats {
  [userId: string]: ChatThread;
}

export interface ChatList {
  [key: number]: Chat;
}

const useGetFirebaseChat = (
  resourceCentreId: number
): {
  formattedChats: ReadableChats;
  audioPlayerRef: HTMLAudioElement;
} => {
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext.userCreds?.userGroups[0]
  );
  const isSuperAdmin = [PermissionGroups.SuperAdmin, PermissionGroups.PublicBookingAdmin].includes(
    permissionGroup
  );
  const resourceCentres = useSelector((state: RootState) => state.resources.resourceCentres) || [];
  const hasPublicBookingEnabled = resourceCentres[0]?.publicBooking;
  const dispatch = useDispatch();
  const chatProfiles = useSelector((state: RootState) => state.chats.chatProfiles);
  const chats = useSelector((state: RootState) => state.chats.chatLists);
  const onChatChange = async (snapshot) => {
    const data: ChatList = snapshot.val();
    if (data) {
      const formattedChats = Object.keys(data)
        ?.map((key) => ({ ...data[key], chatKey: key }))
        ?.sort((a, b) => new Date(b.lastMsgTimestamp) - new Date(a.lastMsgTimestamp));
      dispatch(updateChatList(formattedChats));

      const profilesToBeFetched = Object.values(data)
        .filter((chat) => !chatProfiles[chat?.userId])
        .map((chat) => chat?.userId);
      if (profilesToBeFetched.length) {
        dispatch(fetchUserProfiles(Object.values(data).map((chat) => chat?.userId)));
      }
    } else {
      dispatch(updateChatList([]));
    }
  };

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const queries = isSuperAdmin
      ? [orderByChild("isFromOkhatiSupport"), equalTo(true)]
      : [orderByChild("resourceCentreId"), equalTo(resourceCentreId)];
    if ((resourceCentreId && hasPublicBookingEnabled) || isSuperAdmin) {
      const chatListQuery = query(ref(db, "chats"), ...queries);
      const subscribeChat = onValue(chatListQuery, onChatChange);
      return () => {
        subscribeChat();
      };
    }
  }, [resourceCentreId, hasPublicBookingEnabled]);

  const [formattedChats, setFormattedChats] = React.useState({});
  const audioPlayerRef = React.useRef<HTMLAudioElement | null>(null);

  React.useEffect(() => {
    let unreadMessages = 0;
    const readableChats = chats.reduce((acc, curr) => {
      const messages = Object.keys(curr.chatItems).map((key) => ({ ...curr.chatItems[key], key }));
      const lastMessage = messages[messages.length - 1];
      if (lastMessage?.from !== resourceCentreId && !lastMessage?.read) {
        unreadMessages += 1;
        audioPlayerRef.current?.play();
      }
      // eslint-disable-next-line no-param-reassign
      acc[curr.userId] = {
        messages: messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
        userId: curr.userId,
        resourceCentreId: curr.resourceCentreId,
        lastMessage,
        chatKey: curr.chatKey,
        chatClosedBy: curr.chatClosedBy
      };
      return acc;
    }, {});
    dispatch(updateUnreadCount(unreadMessages));
    setFormattedChats(readableChats);
  }, [chats, resourceCentreId]);
  return { formattedChats, audioPlayerRef };
};

export default useGetFirebaseChat;

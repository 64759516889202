import React from "react";
import { useDispatch } from "react-redux";
import { getBill } from "../../api/bill";
import BillShow from "./BillShow/BillShow";
import { BillType } from "../../interfaces/BillInterfaces";
import { notificationAdd } from "../../actions/notification";

interface PropType {
  bill: BillType;
  setShowParentBill: (value: boolean) => void;
  isForKitchenPharmacy?: boolean;
  setShowMessagePanel: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ParentBill = ({
  bill,
  setShowParentBill,
  isForKitchenPharmacy,
  setShowMessagePanel
}: PropType) => {
  const dispatch = useDispatch();
  const [parentBill, setParentBill] = React.useState<BillType | null>(null);

  async function fetchParentBill() {
    const result = await getBill(bill.referenceTo, isForKitchenPharmacy);
    setParentBill(result);
  }

  React.useEffect(() => {
    try {
      fetchParentBill();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to get parent bill",
          autoTimeout: true
        })
      );
    }
  }, []);

  const handleViewClose = () => {
    setShowParentBill(false);
    setShowMessagePanel(false);
  };

  if (!parentBill) return null;

  return (
    <BillShow
      billId={parentBill.id}
      wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
      isForKitchenPharmacy={isForKitchenPharmacy}
      handleViewClose={handleViewClose}
      setShowMessagePanel={setShowMessagePanel}
    />
  );
};

export default ParentBill;

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ServiceInterface } from "../interfaces/ServiceInterface";

export default function useGetBookedServices(serviceIds: number[]): ServiceInterface[] {
  const servicesList: ServiceInterface[] = useSelector((state: RootState) =>
    state.services.collection.filter(({ active }: { active: boolean }) => active)
  );
  return servicesList.filter((service) => serviceIds.some((sId) => sId === service.id));
}

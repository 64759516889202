import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import moment from "moment";
import startCase from "lodash/startCase";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { StatusBackgroundMap } from "../../Dashboard/FlowItem";
import { BookingStatus } from "../../Bookings/ManageBooking";
import useGetBookedServices from "../../../hooks/useGetBookedServices";
import HtmlTooltip from "../../../components/HtmlTooltip";
import { GROUP_BOOKINGS_BY, GroupBookingsByType, GroupedBooking } from "./index";

interface ActiveBookingsProps {
  bookings: Booking[];
  setClientId: (id: number) => void;
  setShowClientInfoPanel: (show: boolean) => void;
  setBooking: (booking: Booking) => void;
  handleClick: (e) => void;
  groupedBookings: GroupedBooking[];
  groupBookingsBy: GroupBookingsByType;
}

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px"
  },
  bookingDetail: {
    padding: "14px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid  rgba(90, 96, 127, 0.25)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1FFF3"
    },
    "&:last-child": {
      borderBottom: "none"
    }
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  time: {
    textTransform: "uppercase"
  },
  status: (props: { bgColor?: string; color?: string }) => ({
    padding: "4px 12px",
    background: props.bgColor,
    borderRadius: "16px",
    color: props.color,
    marginTop: "4px",
    textTransform: "capitalize"
  }),
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  ptName: {
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  drName: {
    fontWeight: 700
  },
  timeAndRemarkWrapper: {
    display: "flex",
    gap: 3,
    alignItems: "center",
    whiteSpace: "pre"
  },
  bookableResourceAndServiceContaier: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column"
  }
}));

interface Props {
  booking: Booking;
  setClientId: (id: number) => void;
  setShowClientInfoPanel: (show: boolean) => void;
  setBooking: (booking: Booking) => void;
  handleClick: (event) => void;
  showBookableResource: boolean;
}

const ActiveBookingDetail = ({
  setClientId,
  setShowClientInfoPanel,
  setBooking,
  booking,
  handleClick,
  showBookableResource
}: Props) => {
  const { start, serviceProvider, client, status, services, remarks } = booking;
  const bookedServices = useGetBookedServices(services);
  const isReservedStatus = status === BookingStatus.reserved;
  const styleProps = {
    bgColor: StatusBackgroundMap[status],
    color: isReservedStatus ? "#000000" : "#FFFFFF"
  };
  const classes = useStyles(styleProps);
  return (
    <Box
      className={classes.bookingDetail}
      onClick={(e) => {
        setBooking(booking);
        handleClick(e);
      }}
    >
      <div className={classes.timeContainer}>
        <Box className={classes.timeAndRemarkWrapper}>
          <Typography className={classes.time}>{moment(start).format("hh:mm a")}</Typography>
          {remarks && <HtmlTooltip description={remarks} />}
        </Box>
        <Box
          className={classes.status}
          sx={{ border: `${isReservedStatus ? "1px solid black" : ""}` }}
        >
          <Typography
            variant="h3"
            style={{ fontSize: "10px", fontWeight: 500, fontFamily: "Noto Sans Display" }}
          />
          {status}
        </Box>
      </div>
      <Box className={classes.bookableResourceAndServiceContaier}>
        {booking.bookableResource && showBookableResource && (
          <Typography fontWeight={400}>{booking.bookableResource.name}</Typography>
        )}
        <Typography fontWeight={400}>
          {bookedServices.map((service) => service.name).join(", ")}
        </Typography>
      </Box>
      <div className={classes.nameContainer}>
        <Typography className={classes.drName}>
          {serviceProvider ? spFullNameSelector(serviceProvider) : booking.bookableResource?.name}
        </Typography>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            setClientId(client?.id);
            setShowClientInfoPanel(true);
          }}
        >
          <Typography className={classes.ptName}>
            {`${client.firstName} ${client.lastName}`.toUpperCase()}
          </Typography>
        </Box>
      </div>
    </Box>
  );
};

const ActiveBookings = ({
  bookings,
  setClientId,
  setShowClientInfoPanel,
  setBooking,
  handleClick,
  groupedBookings,
  groupBookingsBy
}: ActiveBookingsProps): React.ReactElement => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {groupBookingsBy === GROUP_BOOKINGS_BY.NONE ? (
        <>
          {bookings?.map((booking) => (
            <ActiveBookingDetail
              showBookableResource
              key={booking.id}
              booking={booking}
              setClientId={setClientId}
              setShowClientInfoPanel={setShowClientInfoPanel}
              setBooking={setBooking}
              handleClick={handleClick}
            />
          ))}
        </>
      ) : (
        <>
          {groupedBookings.map((item) => (
            <React.Fragment key={item.groupName}>
              <Typography sx={{ backgroundColor: "#efefef", p: 1, my: 1, borderRadius: 1 }}>
                {startCase(item.groupName)}
              </Typography>
              {item.bookings.map((booking) => (
                <ActiveBookingDetail
                  showBookableResource={groupBookingsBy === GROUP_BOOKINGS_BY.SERVICE_PROVIDER}
                  key={booking.id}
                  booking={booking}
                  setClientId={setClientId}
                  setShowClientInfoPanel={setShowClientInfoPanel}
                  setBooking={setBooking}
                  handleClick={handleClick}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default ActiveBookings;

import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React from "react";
import useGetDepartments from "../../hooks/department";
import { Department } from "../../interfaces/DepartmentInterface";

interface DepartmentSelectProps {
  id: number;
  onChange: (value: Department) => void;
  style?: { [key: string]: string };
  showShortCode?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: "standard" | "filled" | "outlined";
  disableClearable?: boolean;
  showDeleted?: boolean;
  isBookingWindow?: boolean;
  placeholderText?: string;
}

export default function DepartmentSelect({
  id,
  onChange,
  style,
  showShortCode,
  disabled,
  fullWidth,
  variant,
  disableClearable,
  showDeleted = false,
  isBookingWindow = false,
  placeholderText = ""
}: DepartmentSelectProps): JSX.Element {
  const departments = useGetDepartments({ showDeletedDepartments: showDeleted });

  return (
    <Autocomplete
      value={departments.find((d) => d.id === id) || null}
      onChange={(_, value: Department) => {
        onChange(value);
      }}
      disabled={disabled}
      disableClearable={disableClearable}
      options={departments}
      getOptionLabel={(option) => (showShortCode ? option.shortCode : option.name)}
      renderOption={(renderProps, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...renderProps} key={option.id}>
          {showShortCode ? (
            <Typography>{option.shortCode || ""}</Typography>
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography>{option.name || ""}</Typography>
              <Box display="flex" alignItems="center">
                <Typography fontSize="10px" color="gray">
                  {option.shortCode || ""}
                </Typography>
                {!option.active && (
                  <Typography style={{ marginLeft: "8px", color: "#e5e5e5" }}>
                    ( deleted )
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </li>
      )}
      fullWidth={fullWidth}
      style={style}
      renderInput={(params) => (
        <TextField
          //   eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant={isBookingWindow ? undefined : variant}
          fullWidth
          // eslint-disable-next-line no-nested-ternary
          label={isBookingWindow ? "" : showShortCode ? "" : "Select Department"}
          placeholder={placeholderText}
          InputLabelProps={{ shrink: true }}
          InputProps={
            isBookingWindow
              ? {
                  ...params.InputProps,
                  disableUnderline: true,
                  style: {
                    fontSize: "12px"
                  }
                }
              : params.InputProps
          }
        />
      )}
    />
  );
}

DepartmentSelect.defaultProps = {
  style: {},
  showShortCode: false,
  disabled: false,
  fullWidth: false,
  variant: "outlined",
  disableClearable: true,
  showDeleted: false
};

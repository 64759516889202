import React from "react";
import { Box, Typography } from "@mui/material";
import "./styles.css";

function PrintRow({
  label,
  children
}: {
  label: JSX.Element | string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2
      }}
    >
      <Typography
        sx={{
          fontSize: "0.875em",
          fontWeight: 600,
          minWidth: "150px",
          width: "150px"
        }}
      >
        {label}
      </Typography>
      <Box width="100%">{children}</Box>
    </Box>
  );
}

export default PrintRow;

import React from "react";
import { Typography } from "@mui/material";
import InfoField from "./LabPrintInfofield";
import { getSettingBasedDate, scaleFont } from "../LabPrintFunctions";
import { clientFullNameSelector } from "../../../../reducers/client";
import { t } from "../../../../components/translate";
import { LabObjectClient } from "../../../../interfaces/LabInterfaces";
import { showDOBAndGender } from "../../../Billing/PrintBill/EightyMmPrint/ClientInfo";
import { getCustomerNumber } from "../../../Client/ClientList";

interface IpdObject {
  id: number;
  ipdNumber: string;
  bedNumber: string;
  consultant: string;
}
interface ClientInfoProps {
  client: LabObjectClient;
  customerNumber: string;
  customerIdentifier?: string;
  showNameAndAgeInDiffRow?: boolean;
  ipd: IpdObject | null;
  showDobOnLabPrint: boolean;
  ssfId?: string;
  claimCode?: string;
}

const ClientInfo: React.FC<ClientInfoProps> = ({
  client,
  customerNumber = null,
  customerIdentifier = null,
  showNameAndAgeInDiffRow = false,
  ipd,
  showDobOnLabPrint,
  ssfId,
  claimCode
}) => (
  <>
    {showNameAndAgeInDiffRow ? (
      <>
        <InfoField
          label={t("name")}
          data={<>{clientFullNameSelector(client)}</>}
          labelStyle={{ width: "140px", fontSize: scaleFont("0.33cm", 1.32) }}
          valueStyle={{ fontWeight: "bold", fontSize: scaleFont("0.33cm", 1.32) }}
        />
        <InfoField
          label={t("age/Sex")}
          data={<>{showDOBAndGender(client.gender, client.dob)}</>}
          labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        />
      </>
    ) : (
      <Typography
        sx={{
          fontSize: scaleFont("0.42cm", 1),
          fontWeight: 600,
          paddingBottom: "0.03cm",
          textTransform: "uppercase"
        }}
      >
        {clientFullNameSelector(client)}
        {showDOBAndGender(client.gender, client.dob)}
      </Typography>
    )}
    {customerNumber && (
      <InfoField
        label={t("labPrint.patientID")}
        data={<>{getCustomerNumber(customerNumber)}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {ssfId && (
      <InfoField
        label="SSF Id"
        data={ssfId}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {claimCode && (
      <InfoField
        label="Claim code"
        data={claimCode}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}

    {customerIdentifier && (
      <InfoField
        label={t("labPrint.customerIdentifier")}
        data={<>{customerIdentifier}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {showDobOnLabPrint && client && client.dob && (
      <InfoField
        label="Date of Birth"
        data={<>{getSettingBasedDate("BS", client.dob)}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {client.registrationNo && (
      <InfoField
        label="Registration ID"
        data={<>{client.registrationNo}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {ipd?.ipdNumber && (
      <InfoField
        label="Ipd No."
        data={<>{ipd.ipdNumber}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {ipd?.bedNumber && (
      <InfoField
        label="Bed No."
        data={<>{ipd.bedNumber}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {client.phone && (
      <InfoField
        label="Phone No."
        data={
          <>{client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}</>
        }
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {client.passportInfo?.passportNumber && (
      <InfoField
        label="Passport no"
        data={<>{client.passportInfo.passportNumber}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {client.address && (
      <InfoField
        label={t("address")}
        data={client.address}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {client.nationality && (
      <InfoField
        label={t("nationality")}
        data={client.nationality}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
  </>
);

ClientInfo.defaultProps = {
  customerIdentifier: null
};

export default ClientInfo;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Imnci241, Imnci241Base, Imnci242, Imnci242Base } from "app/containers/Hmis/interfaces";
import { HmisSummary } from "../interfaces/HmisInterface";
import { Get, Post, Patch, Delete } from "./apiHelper";
import { OpdRegister } from "../containers/Hmis/OpdRegister/OpdRegisterForm";
import { ReverseMap } from "../helpers/types";

const mulDarta = "/api/mulDarta";
const opdRegister = "/api/hmis/outpatient";
const hmisFormData = "/api/hmisFormData";
const imnci = "/api/hmis/imnci";
const hmisSummary = "/api/hmisSummary";
const hmisSummaryReport = "/api/reports/hmisSummary";
const hmisGenerateReport = "/api/hmisGenerateReport";

export enum FORM_TYPE {
  IMNCI241 = "IMNCI2.41",
  IMNCI242 = "IMNCI2.42"
}

export type HmisFormType = ReverseMap<typeof FORM_TYPE>;

export interface HmisQueryParams {
  page: number;
  pageSize: number;
  form?: HmisFormType;
}

export type OpdRegisterParams = HmisQueryParams;

export async function getOpdRegister({ page, pageSize }: OpdRegisterParams) {
  const response = await Get(`${opdRegister}?page=${page}&pageSize=${pageSize}`, true);
  return response.data;
}

export async function createOpdRegister(data: OpdRegister) {
  const response = await Post(opdRegister, data, true);
  return response.data;
}

export async function getImnci({
  page,
  pageSize,
  form
}: OpdRegisterParams): Promise<{ results: Imnci241[] | Imnci242[]; total: number }> {
  const response = await Get(
    `${imnci}?page=${page}&pageSize=${pageSize}&form=${form || FORM_TYPE.IMNCI241}`,
    true
  );
  return response.data;
}

export async function createImnci(data: Imnci241Base | Imnci242Base) {
  const response = await Post(imnci, data, true);
  return response.data;
}

export async function getMulDarta({ page, pageSize }: HmisQueryParams) {
  const response = await Get(`${mulDarta}?page=${page}&pageSize=${pageSize}`, true);
  return response.data;
}

export async function postHmis(data) {
  const response = await Post(mulDarta, data, true);
  return response.data;
}

export async function getHmisById(id) {
  const response = await Get(`${mulDarta}/${id}`, true);
  return response.data;
}

export async function patchHmis(id, data) {
  const response = await Patch(`${mulDarta}/${id}`, data, true);
  return response.data;
}

export async function deleteHmis(id) {
  const response = await Delete(`${mulDarta}/${id}`, true);
  return response.data;
}

export async function getHmisFormData() {
  const response = await Get(hmisFormData, true);
  return response.data;
}
export async function getHmisSummary(filters: { from: Date; until: Date }): Promise<HmisSummary[]> {
  const url = filters
    ? `${hmisSummaryReport}?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}`
    : hmisSummaryReport;
  const response = await Get(url, true);
  return response.data as HmisSummary[];
}
export async function postSyncHmisSummary(id) {
  const response = await Post(`${hmisSummary}/${id}/sync`, null, true);
  return response.data;
}

// only for test purposes
export async function postGenerateTestReport(rcId): Promise<HmisSummary[]> {
  const response = await Post(`${hmisGenerateReport}?resourceCentreId=${rcId || ""}`, null, true);
  return response.data as HmisSummary[];
}

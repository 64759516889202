import Portal from "@mui/material/Portal";
import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "../../helpers/classNames";
import styles from "./HubspotChatBot.module.css";

declare global {
  interface Window {
    HubSpotConversations: any;
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
  }
}

interface HubspotChatBot {
  show: boolean;
  onClose: () => void;
  onMessage: (message) => void;
}

function HubspotChatBot({ show, onClose, onMessage }: HubspotChatBot): JSX.Element {
  const onNewMessage = (payload) => {
    onMessage(payload);
  };

  React.useEffect(() => {
    function loadWidget() {
      window.HubSpotConversations.widget.load({ widgetOpen: true });
      window.HubSpotConversations.on("unreadConversationCountChanged", onNewMessage);
    }

    if (window.HubSpotConversations) {
      loadWidget();
    } else {
      window.hsConversationsOnReady = [
        () => {
          loadWidget();
        }
      ];
    }
    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.off("unreadConversationCountChanged", onNewMessage);
        window.HubSpotConversations.widget.remove({ resetWidget: true });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Portal>
      <div className={classNames(styles.root, { [styles.show]: show })}>
        <div id="hubspot-widget-wrapper" className={styles.content} />
        <IconButton
          size="small"
          className={styles.closeButton}
          aria-label="close support chat"
          component="button"
          onClick={onClose}
        >
          <CloseIcon sx={{ color: "#fff" }} />
        </IconButton>
      </div>
    </Portal>
  );
}

export default HubspotChatBot;

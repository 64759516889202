import { TextField } from "@mui/material";
import * as React from "react";
import { StockItemInterface } from "../../../../interfaces/StockInterfaces";
import { tl } from "../../../../components/translate";

interface Props {
  item: StockItemInterface;
  transactionType: string;
  onUpdate: (v) => void;
}

export default function StockFreeVat(props: Props): JSX.Element {
  const { item, transactionType, onUpdate } = props;
  return (
    <div>
      <TextField
        style={{ marginTop: "10px" }}
        value={item.vatPctOfFreeItems}
        margin="none"
        label={tl("StockEntry.FreeVat%")}
        type="number"
        InputLabelProps={{ shrink: true }}
        disabled={[
          "internalUse",
          "internalReturn",
          "excess",
          "expiryOrDiscardment",
          "editPurchase",
          "adjustment"
        ].includes(transactionType)}
        onFocus={(e) => e.target.select()}
        variant="outlined"
        InputProps={{
          endAdornment: "%"
        }}
        onChange={(e) => {
          onUpdate(Number(e.target.value));
        }}
      />
    </div>
  );
}

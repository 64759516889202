import React from "react";
import { Card, Typography, Box } from "@mui/material";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import RevenueChart from "./RevenueChart";

const ChartCard = (): React.ReactElement => (
  <Card
    sx={{
      boxShadow: "0px 4px 20px rgba(0, 150, 84, 0.12)",
      borderRadius: "8px",
      height: "100%",
      overflow: "hidden"
    }}
  >
    <Box sx={{ mt: "20px", ml: "20px" }}>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontWeight: "bold",
          fontSize: "20px"
        }}
      >
        Weekly Revenue
      </Typography>
    </Box>
    <div style={{ height: "444px" }}>
      <ParentSize>
        {({ width, height }) => <RevenueChart width={width} height={height} />}
      </ParentSize>
    </div>
  </Card>
);

export default ChartCard;

import * as React from "react";
import DoneIcon from "@mui/icons-material/Done";
import ReferredIcon from "@mui/icons-material/CallMade";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Status from "../../../components/Status/Status";

export const statuses = [
  {
    label: "reserved",
    color: "#FFFFFF"
  },
  {
    label: "handled",
    color: "#009654",
    icon: DoneIcon
  },
  {
    label: "referred",
    color: "#7522C8",
    icon: ReferredIcon
  },
  {
    label: "confirmed",
    color: "#2250C8",
    icon: AssignmentTurnedInIcon
  },
  { label: "cancelled", color: "#C81111" },
  { label: "publicReserved", color: "#6f8233" },
  { label: "draft", color: "lightgray" },
  { label: "waiting", color: "#504F4F" },
  { label: "engaged", color: "#9C970E" }
];
const BookingStatus: React.FC<{ booking: { status: string }; hideLabel: boolean }> = ({
  booking,
  hideLabel = false
}) => (
  <Status statuses={statuses} selected={booking.status} selectable={false} hideLabel={hideLabel} />
);

export default BookingStatus;

import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import { push } from "connected-react-router";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { getBills } from "../../api/bill";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import List from "../../components/List";
import { EmptyView } from "../../components/OList";
import { tl } from "../../components/translate";
import { rupeeDisplay } from "../../helpers/rupee";
import { BillType, VisitType } from "../../interfaces/BillInterfaces";
import { IPD } from "../../interfaces/IpdInterface";
import BillStatusChip from "../Billing/common/BillStatusChip";
import IpdBillPrintBtnGroup from "./PrintButtonGroup";
import { notificationAdd } from "../../actions/notification";
import { errorFetchMessage, MODULE } from "../../helpers/messages";

interface Props {
  ipdRecord: IPD;
}

export default function IpdBills({ ipdRecord }: Props): JSX.Element {
  const [bills, setBills] = React.useState<BillType[]>([] as BillType[]);
  const [selectedIdForPrint, setSelectedIdForPrint] = React.useState<number[]>([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const getBill = async () => {
      if (ipdRecord.clientId) {
        try {
          const clientBills = await getBills({
            clientId: ipdRecord.clientId,
            visitType: VisitType.IPD
          });
          setBills(clientBills.sort((a, b) => new Date(b.id).getTime() - new Date(a.id).getTime()));
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              variant: "error",
              autoTimeout: true,
              message: errorFetchMessage(MODULE.BILL)
            })
          );
        }
      }
    };
    getBill();
  }, [ipdRecord.clientId]);
  return (
    <div>
      <Box display="flex" justifyContent="flex-end" pt="4px" ml={2}>
        {bills.length > 0 && (
          <IpdBillPrintBtnGroup
            clientId={ipdRecord.clientId}
            bills={
              selectedIdForPrint.length
                ? bills.filter((bill) => selectedIdForPrint.includes(bill.id))
                : bills
            }
          />
        )}
      </Box>
      <Box className="ipdClientBills">
        <List
          automation="ipdClientBills"
          rowHeight={40}
          title="Client Bills"
          data={bills}
          withoutSearch
          hideCreateButton
          adjustHeightToContents
          multiSelectable
          onMultiSelect={(ids) => setSelectedIdForPrint(ids.map(Number))}
          onRowClick={(row) => {
            dispatch(push(`/billing/bills/${row.id}`));
          }}
          columns={[
            {
              key: "billNumber",
              label: tl("billing.billNumber"),
              sortable: true,

              formatter: (row) => (
                <Typography>
                  <Box fontSize="0.7rem">{row.isDraft ? "-" : row.billNumber}</Box>
                </Typography>
              )
            },
            {
              key: "date",
              label: tl("billing.issueDate"),
              formatter: ({ issueDate }) => (
                <Typography>
                  <Box fontSize="0.7rem">
                    {convertADtoBS(moment(issueDate)).formatted3}
                    {", "}
                    {convertADtoBS(moment(issueDate)).bsYear}
                  </Box>
                </Typography>
              ),
              sortable: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sortFunction: (rowA, rowB) =>
                moment(rowB.issueDate).unix() - moment(rowA.issueDate).unix()
            },
            {
              key: "total",
              label: tl("billing.total"),
              sortable: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sortFunction: (a, b) => Number(a.total) - Number(b.total),
              formatter: ({ total }) => (
                <Typography>
                  <Box fontSize="0.7rem">{rupeeDisplay(total)}</Box>
                </Typography>
              )
            },
            {
              key: "paidAmount",
              label: tl("billing.paidAmount"),
              cellRenderer: (row) => {
                if (row.isDraft) {
                  return null;
                }
                const paymentInfo = row.paymentInfo || {
                  paid: true,
                  paidAmount: row.summary.totalAmount
                };
                return (
                  <Typography>
                    <Box fontSize="0.7rem" display="flex">
                      {!paymentInfo?.paid && rupeeDisplay(paymentInfo.paidAmount)}
                      {paymentInfo?.paid && <CheckIcon color="primary" />}
                    </Box>
                  </Typography>
                );
              }
            },
            {
              key: "status",
              label: tl("billing.status"),
              sortable: false,
              cellRenderer: (row) => (
                <Box display="flex">
                  <BillStatusChip bill={row} />
                </Box>
              )
            }
          ]}
        >
          <EmptyView>
            <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
              No bills issued yet for the client...
            </Box>
          </EmptyView>
        </List>
      </Box>
    </div>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tooltip, Typography } from "@mui/material";
import List, { MultipleHeader } from "../../components/List";
import { EmptyView } from "../../components/OList";
import UserGroupDetail from "./UserGroupDetail";
import UserGroupCreateEdit from "./UserGroupCreateEdit";
import { fetchUserGroups } from "../../slices/userGroupSlice";
import { RootState } from "../../store";
import "./UserGroup.scss";
import useQueryParams from "../../hooks/useQueryParams";

const UserGroups = (): JSX.Element => {
  const [selectedUserGroup, setSelelctedUserGroup] = React.useState(null);
  const [showCreatePanel, setShowCreatePanel] = React.useState(false);
  const query = useQueryParams();
  const type = query.get("type");
  const dispatch = useDispatch();
  const userGroups = useSelector((state: RootState) => state?.userGroups || []);
  const [filteredUserGroups, setFilteredUserGroups] = React.useState(userGroups);

  React.useEffect(() => {
    if (!type) {
      setFilteredUserGroups(userGroups);
    } else {
      setFilteredUserGroups(userGroups.filter((ug) => ug.userGroupType === type));
    }
  }, [type, userGroups]);
  React.useEffect(() => {
    dispatch(fetchUserGroups());
  }, [dispatch]);

  React.useEffect(() => {
    if (selectedUserGroup) {
      setSelelctedUserGroup(userGroups.find((ug) => ug.id === selectedUserGroup.id));
    }
  }, [userGroups]);
  return (
    <Box height="100%" py={3} className="userGroupList">
      <Box px={2}>
        <MultipleHeader
          multipleHeaders={{
            headers: [
              { key: "all", title: "All", goto: "/userGroups" },
              {
                key: "Resource Centre Employee",
                title: "Employee",
                goto: "/userGroups?type=resourceCentreEmployee"
              },
              {
                key: "Service Providers",
                title: "Service Providers",
                goto: "/userGroups?type=serviceProvider"
              }
            ],
            url: window.location.pathname
          }}
        />
      </Box>
      <List
        withoutSearch
        createLabel="Create User Group"
        automation="vouchersList"
        data={filteredUserGroups}
        isResponsive
        activeRow={selectedUserGroup?.id}
        onRowClick={(item) => {
          setSelelctedUserGroup(item);
        }}
        onCreateNew={() => {
          setShowCreatePanel(true);
        }}
        columns={[
          {
            key: "name",
            label: "Name",
            formatter: ({ name }) => (
              <Tooltip title={name}>
                <Box width="30%" component="div" sx={{ textOverflow: "ellipsis" }}>
                  {name}
                </Box>
              </Tooltip>
            )
          },
          {
            key: "displayName",
            label: "Display Name",
            sortable: true,
            formatter: ({ policy }) => <Typography>{policy?.name}</Typography>
          },
          {
            key: "description",
            label: "Description",
            formatter: ({ description }) => <Typography>{description}</Typography>
          },
          {
            key: "userGroupType",
            label: "User Group Type",
            formatter: ({ userGroupType }) => (
              <Typography>{userGroupType || "Okhati Admin"}</Typography>
            )
          },
          {
            key: "relatedRc",
            label: "Related rc",
            formatter: ({ resourceCentre }) => <Typography>{resourceCentre?.name}</Typography>
          }
        ]}
      >
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No items to show.
          </Box>
        </EmptyView>
      </List>
      {selectedUserGroup && (
        <UserGroupDetail
          userGroup={selectedUserGroup}
          onClose={() => setSelelctedUserGroup(null)}
        />
      )}
      {showCreatePanel && <UserGroupCreateEdit onClose={() => setShowCreatePanel(false)} />}
    </Box>
  );
};

export default UserGroups;

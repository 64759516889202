import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from "@mui/material";
import produce from "immer";
import QuestionnaireRow, { defaultRow, QUESTIONNAIRE } from "./QuestionnaireRow";
import { tl } from "../../../components/translate";
import { Questionnaire } from "../../../interfaces/AssessmentInterfaces";

interface QuestionnairePopUpInterface {
  isOpen: boolean;
  onClose: () => void;
  previousQuestionnaire?: Questionnaire[];
  onSave: (data) => void;
}

const getRemainingDefaultQuestionnaires = (previousQuestionnaire: Questionnaire[]) =>
  QUESTIONNAIRE.filter(
    (defaultQuestion) =>
      !previousQuestionnaire.some(
        (previousQuestion) => previousQuestion.name === defaultQuestion.name
      )
  );

const QuestionnairePopUp: React.FC<QuestionnairePopUpInterface> = ({
  isOpen,
  onClose,
  previousQuestionnaire,
  onSave
}) => {
  const [questionnaire, setQuestionnaire] = React.useState<Questionnaire[]>([]);

  React.useEffect(() => {
    setQuestionnaire(previousQuestionnaire?.length ? previousQuestionnaire : [...QUESTIONNAIRE]);
  }, [previousQuestionnaire]);

  return (
    <Dialog fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle
        style={{
          borderBottom: "1px solid #ececec",
          background: "#f9f9f9",
          textTransform: "uppercase"
        }}
      >
        {tl("assessment.basicHealthQuestionnaire")}
      </DialogTitle>
      <DialogContent style={{ padding: "24px 32px" }}>
        <Box display="flex" marginLeft="0px">
          <Box justifyContent="center" alignItems="center" style={{ width: "20%" }}>
            <Typography style={{ fontWeight: 600, fontSize: "20px" }}>
              {tl("assessment.testName")}
            </Typography>
          </Box>
          <Box justifyContent="center" alignItems="center" style={{ width: "80%" }}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "20px"
              }}
            >
              {tl("assessment.reading")}
            </Typography>
          </Box>
        </Box>
        <Box width="100%" mb={1}>
          {questionnaire.map((question, index) => (
            <QuestionnaireRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              question={question}
              remainingQuestionnaires={getRemainingDefaultQuestionnaires(questionnaire)}
              onRemove={() => {
                const updatedQuestionnaire = produce(questionnaire, (draft) => {
                  draft.splice(index, 1);
                });
                setQuestionnaire(updatedQuestionnaire);
              }}
              onChange={(v) => {
                const updatedQuestionnaire = produce(questionnaire, (draft) => {
                  draft[index] = v;
                });
                setQuestionnaire(updatedQuestionnaire);
              }}
            />
          ))}
        </Box>
        <Button
          data-testmation="questionnaire.AddNewButton"
          onClick={() => {
            setQuestionnaire([...questionnaire, { ...defaultRow }]);
          }}
          variant="outlined"
        >
          {tl("assessment.addNew")}
        </Button>
      </DialogContent>
      <DialogActions
        style={{
          background: "#00800014",
          borderTop: "1px solid #00800017",
          justifyContent: "flex-start"
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          padding="0px 24px"
          alignContent="center"
        >
          <Button
            style={{ marginRight: "8px" }}
            data-testmation="questionnaire.cancelButton"
            color="primary"
            onClick={() => {
              onClose();
            }}
          >
            {tl("assessment.cancel")}
          </Button>
          <Button
            data-testmation="questionnaireSaveButton"
            variant="contained"
            color="primary"
            onClick={() => {
              const answeredQuestionList = questionnaire.filter((qi) => qi.name && qi.reading);
              onSave(answeredQuestionList);
              onClose();
            }}
          >
            {tl("assessment.save")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

QuestionnairePopUp.defaultProps = {
  previousQuestionnaire: []
};

export default QuestionnairePopUp;

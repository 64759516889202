import React, { FunctionComponent } from "react";
import { Typography, Link, Box, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import "../PrintBill.scss";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import usePrintStyles from "../../../../hooks/usePrintStyles";
import { getSectionStyle } from "../../../Lab/LabPrint/LabPrintFunctions";
import { RootState } from "../../../../store";
import usePrintShortcut from "../../../../hooks/usePrintShortcut";
import { checkWalkInCustomer, InfoField } from "../BillPrintHelpers";
import { BillStatus, BillType, DocumentTypes } from "../../../../interfaces/BillInterfaces";
import { tl, t } from "../../../../components/translate";
import { dateWithZeroBeforeSingleDigits } from "../../../../helpers/formatters";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import CorporateBillClientInfo from "./CorporateBillClientInfo";
import LabPrintLetterHeadCentered from "../../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import LabPrintLetterHead from "../../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import CorporateBillDescription from "./CorporateBillDescription";
import { registerPrint } from "../../../../actions/bill";
import logo from "../../../../../assets/images/poweredByOkhati.svg";
import greenLogo from "../../../../../assets/images/greenLogo.png";

interface PrintBillProps {
  buttonText?: string;
  labelStyle?: React.CSSProperties;
  onPrintClick: () => void;
  addPrintCount: boolean;
  billId: number;
  billData: BillType;
}

const getPrintTitle = ({
  status,
  type,
  printCount
}: {
  status: string;
  type: string;
  printCount: number;
}): string => {
  if (type === DocumentTypes.CREDITNOTE) {
    return t("billing.creditNote");
  }
  if (status === BillStatus.draft) {
    return t("billing.draft");
  }
  if (status === BillStatus.cancelled) {
    return `${t("billing.taxInvoice")} (${t("billing.cancelled")})`;
  }
  if (!printCount) {
    return t("billing.taxInvoice");
  }
  return t("billing.invoice");
};

const CorporateBillPrint: FunctionComponent<PrintBillProps> = ({
  buttonText,
  labelStyle,
  onPrintClick,
  addPrintCount,
  billId,
  billData
}) => {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const printRef = React.useRef(null);
  const reactToPrintRef = React.useRef(null);
  const dispatch = useAppDispatch();

  usePrintShortcut(reactToPrintRef);
  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();

  const { resourceCentres } = useSelector((state: RootState) => state.resources);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);

  const resourceCentre = resourceCentres.find((rc) => rc.id === rcId);
  const showLetterHead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const centralizedHeader = resourceCentre?.settings?.printSettings.centralizedHeader;
  const customSubtitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customSubtitle;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const SPs = useAppSelector((state) => state.resources.resourceCentreServiceProviders);
  const headerColor =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    "";
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const hideRcLogo = Boolean(customTitle);
  // toggling watermark on the basis of enableColor prop
  const showWatermark = resourceCentre?.settings.printSettings.labPrintSettings.enableColor;

  let letterHeadComponent = <></>;

  if (showLetterHead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <LabPrintLetterHeadCentered
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          hideRcLogo={hideRcLogo}
        />
      );
    } else {
      letterHeadComponent = (
        <LabPrintLetterHead
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
          additionalContent={<></>}
        />
      );
    }
  }

  return (
    <>
      <ReactToPrint
        onAfterPrint={() => {
          window.focus();
          if (addPrintCount) {
            dispatch(registerPrint(billId));
          }
          setIsPrinting(false);
        }}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className="actionPrintBtn"
            onMouseEnter={() => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              usePrintStyles({
                pageStyle: "size: A4; margin: 0mm"
              }).applyPrintStyles();
            }}
            onClick={() => {
              if (isPrinting) return;
              setIsPrinting(true);
            }}
            ref={reactToPrintRef}
            style={{ textDecoration: "none", padding: "5px 15px", cursor: "pointer" }}
          >
            <Tooltip title="Alt + P / Ctrl + P" arrow>
              <Typography component="span" style={labelStyle}>
                <Box className="actionBtnLabel" component="span">
                  {buttonText || "Print"}
                </Box>
              </Typography>
            </Tooltip>
          </Link>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents" style={{ position: "relative" }}>
        <Box ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterHead && (
                <tr>
                  <td>
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterHead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <Box
                      m="0.3cm 0cm"
                      textAlign="center"
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {/* note: first print title is "Tax Invoice */}
                      {/* second print title is "Invoice */}
                      {/* and third print will be first copy ("Invoice (Copy-1)") and so on... as per ird requirement */}
                      {getPrintTitle({
                        status: billData.status,
                        type: billData.type,
                        printCount: billData.printCount
                      })}
                      {billData.printCount > 1 && ` (COPY - ${billData.printCount - 1})`}
                    </Box>

                    <Box
                      sx={{
                        pageBreakInside: "avoid",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        my: 3
                      }}
                    >
                      <div>
                        <Typography sx={{ fontSize: "1.25rem", color: "grey" }}>Bill To</Typography>
                        {billData.client && <CorporateBillClientInfo client={billData.client} />}
                      </div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {checkWalkInCustomer(billData.client, "isWalkInCustomer") && (
                          <InfoField
                            label={
                              billData.type === DocumentTypes.CREDITNOTE
                                ? tl("billing.creditNoteNumber")
                                : tl("billnumber")
                            }
                            data={<>{billData.billNumber}</>}
                          />
                        )}
                        {billData.type === DocumentTypes.CREDITNOTE && (
                          <InfoField
                            label={tl("billing.creditNoteForBill")}
                            data={<>: {billData.related?.billNumber}</>}
                          />
                        )}
                        <InfoField
                          label={tl("billing.issueDate")}
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                convertADtoBS(moment(billData.issueDate)).formatted
                              )}
                              {moment(billData.issueDate).format("h:mm a")}
                            </>
                          }
                        />
                        <InfoField
                          label={tl("billing.dateAD")}
                          data={
                            <>
                              {dateWithZeroBeforeSingleDigits(
                                moment(billData.issueDate).format("YYYY/MM/DD")
                              )}
                            </>
                          }
                        />
                      </Box>
                    </Box>

                    <CorporateBillDescription
                      clientBalance={0}
                      billData={billData}
                      relatedSettings={{
                        showReferrer: false,
                        isBordered: false,
                        headerColor
                      }}
                      SPs={SPs}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showLetterHead && (
            <Box
              className="billFooterPos borderTopBlack1 billFooter"
              sx={{
                ...getSectionStyle(headerColor).footerStyle,
                paddingTop: "0.5rem",
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse"
              }}
            >
              <img src={logo} alt="logo" height="50px" />
            </Box>
          )}
          {/* watermark */}
          {showWatermark && (
            <img
              src={greenLogo}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 0.1
              }}
              height="500px"
              width="500px"
              alt=""
            />
          )}
        </Box>
      </iframe>
    </>
  );
};

export default CorporateBillPrint;

import React from "react";
import { Box, Typography } from "@mui/material";
import { KnowledgeBaseDocument } from "../../interfaces/KnowledgeBase";
import styles from "./styles.module.scss";

interface SearchResultListProps {
  data: KnowledgeBaseDocument[];
  onSelect: (KnowledgeBaseDocument) => void;
}

function SearchResultList({ data, onSelect }: SearchResultListProps): JSX.Element {
  if (data === undefined) {
    return null;
  }
  return (
    <Box component="div">
      {data.length > 0 ? (
        <>
          {data.map((article) => (
            <Box
              key={article.id}
              className={styles.SearchList}
              py="8px"
              px="16px"
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                onSelect(article);
              }}
            >
              <Typography>{article.data.title}</Typography>
              <Box display="flex">
                {article.tags.map((tag) => (
                  <Typography
                    key={tag}
                    style={{
                      color: "grey",
                      fontSize: "10px",
                      marginRight: 4
                    }}
                  >
                    {tag}
                  </Typography>
                ))}
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Typography
          style={{
            width: "100%",
            padding: "8px 16px"
          }}
        >
          No results.
        </Typography>
      )}
    </Box>
  );
}

export default SearchResultList;

import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/resourceCentre";
import { IThunkDispatch, RootState } from "../store";
import { ResourceCentre, Subscriptions } from "../interfaces/ResourceCentreInterface";
import { notificationAdd } from "./notification";
import { QrPaymentInfoType } from "../interfaces/QrPayment";

export enum Type {
  CHANGE_RESOURCE_CENTRE_PICTURE = "CHANGE_RESOURCE_CENTRE_PICTURE",
  UPDATE_USER_RC = "UPDATE_USER_RC"
}

export const uploadProfileResourceCentre =
  (rcObj: { formData: FormData; id: number }) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.CHANGE_RESOURCE_CENTRE_PICTURE, async () =>
        api.uploadProfileResourceCentre(rcObj)
      )
    );
  };

export const getRCFromState = (state: RootState): ResourceCentre =>
  state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
  state.userContext.resourceCentre;

export const getSubscriptions = (
  subscriptions: Subscriptions["features"]
): {
  billing: boolean;
  stock: boolean;
  campaign: boolean;
  ipd: boolean;
  medical: boolean;
  hmis: boolean;
} => ({
  billing: subscriptions?.billing?.subscribed,
  stock: subscriptions?.stock?.subscribed,
  campaign: subscriptions?.campaign?.subscribed,
  ipd: subscriptions?.ipd?.subscribed,
  medical: subscriptions?.ipd?.subscribed,
  hmis: subscriptions?.hmis?.subscribed
});

export const updateQrData =
  (resourceCentreId: number, qrData: QrPaymentInfoType) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    try {
      const updatedRc: ResourceCentre = await api.patchQrPaymentInfo(resourceCentreId, qrData);
      dispatch({
        type: Type.UPDATE_USER_RC,
        payload: updatedRc
      });
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error.message,
          autoTimeout: true
        })
      );
    }
  };

export const deleteQrImage =
  (rcId: number, paymentMethod: string, qrImageKey: string) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    try {
      const updatedRc = await api.deleteQrPaymentImage(rcId, paymentMethod, qrImageKey);
      dispatch({
        type: Type.UPDATE_USER_RC,
        payload: updatedRc
      });
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to delete qr image",
          autoTimeout: true
        })
      );
    }
  };

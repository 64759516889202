import React from "react";
import { Box } from "@mui/material";
import startCase from "lodash/startCase";
import moment from "moment";
import { IPD, PrescriptionContext } from "../../../interfaces/IpdInterface";
import AuthorizedSPInfo from "../../Lab/LabPrint/LabprintComponents/AuthorizedSPInfo";
import {
  getMedicineMultiplier,
  showFrequencyUnit,
  showMeal,
  showTimeUnit
} from "../../Assessment/Medication/Medication";
import { countableTypes } from "../../Assessment/Medication/medicineType";

export interface AuthorizeInfo {
  fullname: string;
  signature: string;
}

interface IpdCoreDataInterface {
  ipdData: IPD;
}

interface RowInterface {
  label?: string;
  value?: React.ReactNode;
  bL?: boolean;
  bB?: boolean;
  bR?: boolean;
  lineBreak?: boolean;
  width?: string;
}

const Row: React.FC<RowInterface> = ({
  label,
  value = "",
  bL = false,
  bB = false,
  bR = false,
  lineBreak,
  ...props
}) => (
  <Box
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    p={0.35}
    style={{
      whiteSpace: "pre-line",
      fontWeight: 500,
      borderLeft: `${bL ? "1px solid black" : ""}`,
      borderBottom: `${bB ? "1px solid black" : ""}`,
      borderRight: `${bR ? "1px solid black" : ""}`,
      fontSize: "0.37cm"
    }}
  >
    {label ? `${label}: ` : ""}
    {lineBreak && <br />}
    {value || ""}
  </Box>
);

const IpdCoreData: React.FC<IpdCoreDataInterface> = ({ ipdData }) => {
  const { dateOfAdmission, emergencyContact, ipdRecord, prescribedMedications, bed, ward } =
    ipdData;

  const authorizedPersonnelInfo =
    ipdData.authorizedPersonnelInfo || ipdData.ipdRecord.authorizedPersonnelInfo;
  const doctorInCharge = ipdData.doctorInCharge || ipdData.ipdRecord.doctorInCharge;
  const usedMedicines =
    prescribedMedications?.length > 0
      ? prescribedMedications
          .filter((item) => item.prescriptionContext !== PrescriptionContext.DISCHARGE)
          .map((item) => `${item.brand} ${item.strength ? `(${item.strength})` : ""}`)
          .join(", ")
      : "";

  const prescribedMedicines = prescribedMedications?.filter(
    (item) => item.prescriptionContext === PrescriptionContext.DISCHARGE
  );

  return (
    <Box mt={3}>
      <Box
        component="div"
        style={{ border: "1px solid black", pageBreakInside: "avoid" }}
        display="flex"
      >
        <Row label="Emergency Contact" width="15%" bR />
        <Box component="div" width="85%">
          <Row label="Name" value={emergencyContact.name} bB />
          <Row label="Contact" value={emergencyContact.phone} />
        </Box>
      </Box>
      <Box component="div" style={{ border: "1px solid black", pageBreakInside: "avoid" }} mt={1}>
        <Row label="Case Type" bB value={ipdRecord.case} />
        <Box borderBottom="1px solid black" display="grid" gridTemplateColumns="40% 30% 30%">
          <Row label="Department" value="" />
          <Row label="Ward" value={ward?.name} bL />
          <Row label="Bed Number" value={bed?.number} bL />
        </Box>

        <Row label="Admitting Doctor" value={startCase(authorizedPersonnelInfo?.fullname)} bB />
        <Row label="Authorized Personnel" value={startCase(doctorInCharge?.fullname)} bB />
        <Row label="Progress Result" value={ipdRecord?.dischargeSummary?.progressResult} bB />
        <Row label="Provisional Diagosis" value={ipdRecord?.provisionalDiagnosis} bB />
        <Row
          label="Final Diagnosis"
          value={ipdRecord?.dischargeSummary?.finalDiagnosis?.diagnosis}
          bB
        />
        <Row
          label="Condition at the time of Discharge"
          value={ipdRecord?.dischargeSummary?.conditionDuringDischarge}
          bB
        />
        <Row
          label="Advice/Instructions on Discharge"
          value={ipdRecord?.dischargeSummary?.instructionsOnDischarge}
          bB
          lineBreak
        />
        <Row label="Medicine Used" value={usedMedicines} bB />
        <Row label="Medicine On Discharge" lineBreak bB />
        {prescribedMedicines?.map((item, index) => (
          <Box display="grid" gridTemplateColumns="3% 50% 47%" key={item.id || item.genericName}>
            <Row value={index + 1} bB />
            <Row
              value={`${item.genericName || ""}${item.genericName && item.brand && "/"} ${
                item.brand
              } ${item.strength} ${item.form ? `(${item.form})` : ""} `}
              bL
              bB
            />
            <Row
              value={
                <>
                  {item.frequency === 0 ? "" : `${item.frequency} times/`}
                  {item.frequency !== 0 && showTimeUnit(item.times)}
                  {` ${item.meal ? showMeal(item.meal) : ""}`}
                  {item.frequency !== 0 &&
                    ` ${
                      countableTypes.includes(item.form)
                        ? item.frequency *
                          getMedicineMultiplier(item.times, item.duration, item.frequencyType)
                        : ""
                    } ${item.form} (${item.duration} ${showFrequencyUnit(
                      item.frequencyType,
                      item.duration
                    )})`}
                </>
              }
              bL
              bB
              width="100%"
            />
          </Box>
        ))}

        <Box display="grid" gridTemplateColumns="50% 50%">
          <Row bB label="Date of Admission" value={moment(dateOfAdmission).format("DD/MM/YYYY")} />
          <Row
            label="Date of Discharge"
            value={moment(dateOfAdmission).format("DD/MM/YYYY")}
            bL
            bB
          />
        </Box>
        <Row label="Remarks" value={ipdRecord?.dischargeSummary?.remarks} />
      </Box>

      {/* if the case is Maternity */}
      {ipdRecord.case === "Maternity" && (
        <Box component="div" style={{ border: "1px solid black" }} display="flex" mt={1}>
          <Row label="Maternity Purpose Only" width="15%" bR />
          <Box component="div" width="85%">
            <Row label="Procedures" value={ipdRecord.dischargeSummary?.procedures} bB />
            <Row
              label="Date of Delivery"
              value={moment(ipdRecord.dischargeSummary?.dateOfDelivery).format("DD/MM/YYYY")}
              bB
            />
            <Row label="Baby Weight" value={ipdRecord.dischargeSummary?.weightOfBaby} />
          </Box>
        </Box>
      )}

      {/* signature */}
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={1}>
        <AuthorizedSPInfo sp={authorizedPersonnelInfo} />
        <AuthorizedSPInfo sp={doctorInCharge} />
      </Box>
    </Box>
  );
};

Row.defaultProps = {
  value: "",
  bL: false,
  bB: false,
  bR: false,
  lineBreak: false,
  width: "100%"
};

export default IpdCoreData;

import * as React from "react";
import { push } from "connected-react-router";
import Zoom from "@mui/material/Zoom";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useLocation, useRouteMatch } from "react-router";
import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { connect, useDispatch, useSelector } from "react-redux";
import { Badge, Box, Divider, IconButton, Select, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import startCase from "lodash/startCase";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import * as AuthenicationActions from "../../actions/authentication";
import Notifications from "./Notifications";
import Chat from "../../Svgicons/Chat";
import { tl } from "../../components/translate";
import SideBar, { isAdmin } from "./SideBar";
import { toggleMobileDrawer } from "../../actions/ui";
import SearchBox from "./SearchBox";
import Can from "../Policy/Can";
import KnowledgeBase from "../KnowledgeBase";
import { AppDispatch, RootState } from "../../store";
import { IUser } from "../../interfaces/User";
import dummy from "../../../assets/images/dummyProfile.png";
import styles from "./styles.module.css";
import { UserCreds } from "../../interfaces/UserContext";
import useGetDepartments from "../../hooks/department";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useSubscription from "../../hooks/useSubscription";
import useCan from "../../hooks/useCan";
import { PolicyAccessKeys } from "../../interfaces/policy";
import { getUserAccesingRcs, updateSelectedBranchForDashboard } from "../../slices/userBranchSlice";
import MessagePanel from "../Messages/MessagePanel";
import useGetFirebaseChat from "../../hooks/useGetFirebaseChat";
import NotificationSound from "../../../assets/media/notification-sound.wav";
import ChatSupport from "../../Svgicons/ChatSupport";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const TwilioVideoLazy = React.lazy(() => import("../TwilioVideo"));

const showNotifications = (userCreds) => {
  if (userCreds?.authenticable === "client") return false;
  if (userCreds?.userGroups[0] === "resourceCentreReadOnlyEmployee") {
    return false;
  }
  return true;
};

interface INavigationBar {
  resourceCentreId: string;
  user: IUser;
  userCreds: UserCreds;
  resourceCentreLogo: string;
  resourceCentreName: string;
  resourceCentreVideoSetting: unknown;
  adminImpersonating: unknown;
  permissionGroup: string;
  goto: (path: string) => void;
  mode: string;
  showLiveSupport: boolean;
  logout: () => void;
  toggleMobileDrawerAction: (status: boolean) => void;
  setShowSupportChat: () => void;
  unreadSupportMessageCount: number;
}

function extractModuleNameFromPath(path: string, isRcUser: boolean) {
  const segments = path.slice(1).split("/");
  const nonEmptySegments = segments.filter((segment) => !!segment);
  const firstSegment = nonEmptySegments[0];
  if (!firstSegment) return "dashboard";
  if (firstSegment === "resourcecentres") {
    if (isRcUser) return "settings";
    return "";
  }
  if (firstSegment === "assessment") return "opd";
  if (firstSegment === "beds") return "ipd";
  return firstSegment;
}

const RC_SETTINGS_ROUTE = "/resourceCentres/:id/";
function NavigationBar({
  resourceCentreId,
  user,
  userCreds,
  resourceCentreLogo,
  resourceCentreName,
  resourceCentreVideoSetting,
  adminImpersonating,
  permissionGroup,
  goto,
  mode,
  logout,
  toggleMobileDrawerAction,
  setShowSupportChat,
  unreadSupportMessageCount,
  showLiveSupport
}: INavigationBar) {
  const [open, setOpen] = React.useState(false);
  const [hamMenuOpen, setHamMenuOpen] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const popperAnchor = React.useRef(null);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [showMessagePanel, setShowMessagePanel] = React.useState(
    Boolean(window.isRnWebView) || false
  );
  const location = useLocation();
  const resourceCentre = useCurrentResourceCentre();

  React.useEffect(() => {
    toggleMobileDrawerAction(hamMenuOpen);
  }, [hamMenuOpen, toggleMobileDrawerAction]);

  const isResourceCentreUser = ["employee", "serviceProvider"].includes(mode);

  const isReadOnlyUser =
    permissionGroup === "resourceCentreReadOnlyEmployee" ||
    permissionGroup === "resourceCentreReportOnlyAccessEmployee";

  const isSuperAdmin = ["superAdmin"].includes(permissionGroup);

  const departmentId = useSelector((state: RootState) => state.userContext.user?.departmentId);

  const showDepartment = Boolean(departmentId);

  const dispatch = useDispatch();
  const isDashboard = window.location.pathname === "/dashboard";
  const departments = useGetDepartments();
  const [userDepartment, setUserDepartment] = React.useState("");

  React.useEffect(() => {
    if (showDepartment) {
      const department = departments.find((dept) => dept.id === departmentId);
      if (department) {
        setUserDepartment(department.name);
      }
    }
  }, [departmentId, departments, showDepartment]);

  const canAccessBranchRcs = useCan(PolicyAccessKeys.CmsReport, { superAdminPass: true });

  React.useEffect(() => {
    if (canAccessBranchRcs) {
      dispatch(getUserAccesingRcs());
    }
  }, [canAccessBranchRcs, dispatch]);

  const branchOptions = useSelector((state: RootState) => state.userBranch?.collection) || [];
  const [selectedRc, setSelectedRc] = React.useState(null);
  const branchForDashboad = useSelector(
    (state: RootState) => state.userBranch.selectedBranchForDashboard
  );
  const hasBranches = Boolean(branchOptions.length);
  React.useEffect(() => {
    if (hasBranches) {
      dispatch(updateSelectedBranchForDashboard(resourceCentreId));
    }
  }, [resourceCentreId, dispatch, hasBranches]);
  const currentSubscription = useSubscription();

  // Message related
  const unreadCount = useSelector((state: RootState) => state.chats.unreadMessageCount);
  const { formattedChats, audioPlayerRef } = useGetFirebaseChat(Number(resourceCentreId));

  const match = useRouteMatch(RC_SETTINGS_ROUTE);
  const isRcSettingsPage = match && match.path === RC_SETTINGS_ROUTE;
  const resourceCentres = useSelector((state: RootState) => state.resources.resourceCentres) || [];
  React.useEffect(() => {
    if (isRcSettingsPage && isSuperAdmin) {
      setSelectedRc(resourceCentres.find((rc) => rc.id === Number(match.params.id)));
    }
  }, [isRcSettingsPage, resourceCentres, isSuperAdmin]);

  if (!user) return null;
  return (
    <>
      <Box className={styles.topBar} bgcolor={isMdScreen ? "white" : "primary.main"}>
        {!window.isRnWebView && (
          <IconButton
            onClick={() => {
              setHamMenuOpen(true);
            }}
            size="large"
          >
            <MenuIcon className={styles.topBarIcon} />
          </IconButton>
        )}
        {isSuperAdmin && isRcSettingsPage && selectedRc && (
          <Box className={styles.clinicSpecific}>
            <img
              className={styles.clinicLogo}
              src={selectedRc.resourceCentreLogo?.s3ResourceURL || dummy}
              alt="clinic logo"
            />
            <Box display="flex">
              <Box
                data-testmation="companyName"
                className={styles.clinicName}
                style={isMdScreen ? { color: "#292929" } : { color: "white", cursor: "pointer" }}
                display="flex"
                alignItems="center"
                gap={1}
              >
                {selectedRc.name}
              </Box>
            </Box>
          </Box>
        )}
        {(adminImpersonating || isResourceCentreUser) && (
          <Box className={styles.clinicSpecific}>
            <img
              className={styles.clinicLogo}
              src={resourceCentreLogo || dummy}
              alt="clinic logo"
            />
            <Box display="flex">
              {branchOptions.length && isDashboard ? (
                <Box>
                  <Select
                    variant="standard"
                    disableUnderline
                    id="branchDbSelect"
                    value={branchForDashboad || resourceCentreId}
                    sx={{
                      width: "210px",
                      fontWeight: "bold",
                      color: isMdScreen ? "#292929" : "#fff",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "16px",
                      lineHeight: "32px",
                      marginLeft: "10px"
                    }}
                    onChange={(e) => {
                      dispatch(updateSelectedBranchForDashboard(Number(e.target.value)));
                    }}
                  >
                    {branchOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : (
                <Box
                  data-testmation="companyName"
                  className={styles.clinicName}
                  onClick={() => {
                    if (!isMdScreen && !isReadOnlyUser) {
                      goto("/");
                    }
                  }}
                  style={isMdScreen ? { color: "#292929" } : { color: "white", cursor: "pointer" }}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  {resourceCentreName}
                  {currentSubscription?.isTrial && (
                    <Typography color="gray" fontSize="0.8rem">
                      (Trial Version)
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
            {showDepartment && userDepartment && (
              <Typography
                sx={{ alignSelf: "center", fontSize: "12px" }}
                style={isMdScreen ? { color: "#292929", marginLeft: "5px" } : { color: "white" }}
              >
                ({userDepartment})
              </Typography>
            )}
          </Box>
        )}
        <Box
          gap={1}
          ml={isResourceCentreUser ? 1 : 6}
          alignItems="center"
          display={isMdScreen ? "flex" : "none"}
        >
          {isResourceCentreUser && <ArrowForwardIosIcon color="action" sx={{ fontSize: 12 }} />}
          <Typography sx={{ color: isMdScreen ? "#292929" : "#ffffff" }}>
            {startCase(extractModuleNameFromPath(location.pathname, isResourceCentreUser))}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <Box display="flex" mr={isMdScreen ? 6 : 2} alignItems="center">
          {!isSuperAdmin && <SearchBox />}
          <Tooltip arrow title="Messages form mobile app" aria-label="messages">
            <IconButton
              onClick={() => {
                setShowMessagePanel(true);
                mixpanelAnalytics.track(EVENT.OPEN_DIGI_HEALTH_CHAT, {
                  rcId: resourceCentre.id,
                  rcName: resourceCentre.name
                });
              }}
            >
              <Badge color={isMdScreen ? "primary" : "secondary"} badgeContent={unreadCount || 0}>
                <Chat color={isMdScreen ? "#009654" : "#fff"} />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
        {resourceCentreVideoSetting && userCreds.authenticable === "serviceProvider" && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <TwilioVideoLazy />
          </React.Suspense>
        )}

        {showNotifications(userCreds) && (
          <Can policyAccessKey={["booking:listBooking", "booking:listSchedule"]} superAdminPass>
            <Notifications />
          </Can>
        )}
        <Divider
          sx={{
            height: 20
          }}
          orientation="vertical"
        />
        <Divider
          sx={{
            height: 20
          }}
          orientation="vertical"
        />
        <Tooltip arrow title="Help" aria-label="help">
          <IconButton
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <HelpOutlineIcon sx={{ color: isMdScreen ? "#009654" : "#fff" }} />
          </IconButton>
        </Tooltip>
        <Divider
          sx={{
            height: 20
          }}
          orientation="vertical"
        />
        <Tooltip arrow title="Live chat support" aria-label="live support">
          <IconButton
            onClick={() => {
              setShowSupportChat();
              mixpanelAnalytics.track(EVENT.OPEN_LIVE_CHAT_SUPPORT, {
                rcId: resourceCentre.id,
                rcName: resourceCentre.name
              });
            }}
          >
            <ChatSupport color={isMdScreen ? "#009654" : "#fff"} />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Account" aria-label="account">
          <Button
            className={styles.topButton}
            ref={(node) => {
              popperAnchor.current = node;
            }}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={() => setOpen((prevState) => !prevState)}
            data-testmation="account-button"
          >
            <Avatar
              alt={`${user.firstName} ${user.lastName}`}
              src={userCreds?.profileImage?.downloadLink || "https://picsum.photos/100/100/?random"}
              className={styles.smallProfileImage}
            />
            <ArrowDropDownIcon className={styles.topBarIcon} />
          </Button>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={popperAnchor.current}
          transition
          className={styles.menuPaper}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Zoom
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <div>
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpen(false);
                    }}
                  >
                    <MenuList>
                      {isResourceCentreUser && !isReadOnlyUser && (
                        <MenuItem
                          data-testmation="myCompany"
                          onClick={() => goto(`/resourcecentres/${resourceCentreId}/details`)}
                        >
                          {tl("navigation.myCompany")}
                        </MenuItem>
                      )}
                      <MenuItem
                        data-testmation="myProfile"
                        onClick={() => {
                          mixpanelAnalytics.track(EVENT.TOP_USER_MENU_GO_TO_PROFILE, {
                            rcId: resourceCentreId
                          });
                          goto("/account");
                        }}
                      >
                        {tl("navigation.myProfile")}
                      </MenuItem>
                      <MenuItem data-testmation="logout" onClick={() => logout()}>
                        {tl("navigation.logout")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </div>
            </Zoom>
          )}
        </Popper>
      </Box>
      <SideBar
        hamMenuOpen={hamMenuOpen}
        toggleHamMenu={(v) => {
          setHamMenuOpen(v);
        }}
      />
      {showHelp && (
        <KnowledgeBase
          handleViewClose={() => setShowHelp(false)}
          unreadSupportMessageCount={unreadSupportMessageCount}
          showLiveChat={() => setShowSupportChat(!showLiveSupport)}
        />
      )}
      {showMessagePanel && (
        <MessagePanel onClose={() => setShowMessagePanel(false)} formattedChats={formattedChats} />
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioPlayerRef} src={NotificationSound} />
    </>
  );
}

export default connect(
  ({ userContext, router }: RootState) => {
    const adminImpersonating = isAdmin(userContext.mode) && userContext.resourceCentreId;
    return {
      user: userContext.user,
      userCreds: userContext.userCreds,
      router,
      mode: userContext.mode,
      permissionGroup: userContext.userCreds?.userGroups[0],
      resourceCentreId: userContext.resourceCentreId,
      resourceCentreName: userContext.resourceCentre?.name,
      resourceCentreLogo: userContext.resourceCentre?.resourceCentreLogo?.s3ResourceURL,
      color: userContext.resourceCentre?.settings?.lookAndFeel,
      resourceCentreVideoSetting:
        userContext.resourceCentre?.subscriptions?.features?.video?.subscribed,
      adminImpersonating
    };
  },
  (dispatch: AppDispatch) => ({
    logout: () => dispatch(AuthenicationActions.logout()),
    goto: (path) => dispatch(push(path)),
    toggleMobileDrawerAction: (status) => dispatch(toggleMobileDrawer(status))
  })
)(NavigationBar);

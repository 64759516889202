import { types } from 'util';

import { Type } from '../actions/ui';

const initialState = {
  mobileDrawerOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Type.TOGGLE_MOBILE_DRAWER:
      return { ...initialState, mobileDrawerOpen: action.payload };
    default:
      return initialState;
  }
}

import { Box, TextField, Typography } from "@mui/material";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { clientSearch } from "../../api/bookings";
import { getClientDetails } from "../../api/client";
import { t } from "../../components/translate";
import { Client } from "../../interfaces/ClientInterface";
import { getCustomerNumber } from "./ClientList";

interface Props {
  onChange: (v: Array<number>) => void;
  isDisabled?: boolean;
  variant?: "standard" | "filled" | "outlined";
  clientIds?: number[];
}

const ClientsAutoSelectMulti = ({
  onChange,
  isDisabled = false,
  variant = "standard",
  clientIds
}: Props): JSX.Element => {
  const [opts, setOpts] = React.useState<Client[]>([] as Client[]);
  const [value, setValue] = React.useState<Client[]>([]);
  React.useEffect(() => {
    if (clientIds?.length && !opts.length) {
      const getClient = async () => {
        const res = await getClientDetails(+clientIds[0]);
        setValue([res]);
        setOpts([res]);
      };
      getClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      multiple
      options={opts}
      filterOptions={(options) => options}
      getOptionLabel={(option) =>
        `${option.firstName} ${option.lastName} (${getCustomerNumber(option.customerNumber) || ""})`
      }
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          <div>
            <Typography>
              {option.firstName} {option.lastName}
            </Typography>
            <Box display="flex">
              <Typography sx={{ fontSize: "10px", color: "gray" }}>{`${getCustomerNumber(
                option.customerNumber
              )}  ${option.phone}`}</Typography>
            </Box>
          </div>
        </li>
      )}
      value={value}
      onChange={(e, values) => {
        setValue(values);
        onChange(values.map(({ id }) => id));
        setOpts([]);
      }}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant={variant}
          label={t("clients.clients")}
          placeholder={t("clients.searchAndSelect")}
          onChange={async (e) => {
            if (e.target.value?.length > 2) {
              const clients = await clientSearch(e.target.value);
              setOpts(clients);
            }
          }}
          margin="dense"
          fullWidth
        />
      )}
    />
  );
};

ClientsAutoSelectMulti.defaultProps = {
  isDisabled: false,
  variant: "standard",
  clientIds: []
};
export default ClientsAutoSelectMulti;

import produce from "immer";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/stockProducts";
import * as NotificationActions from "./notification";
import { updateEntryOnProductUpdate } from "./stock";
import { ProductInterface } from "../interfaces/ProductInterface";
import { AppThunk, IThunkDispatch } from "../store";
import { QueryParams, StockProducts } from "../interfaces/StockInterfaces";
import hasOwnProperty from "../helpers/object";

export enum Type {
  GET_STOCK_PRODUCTS = "GET_STOCK_PRODUCTS",
  UPDATE_STOCK_PRODUCT = "UPDATE_STOCK_PRODUCT",
  DELETE_STOCK_PRODUCT = "DELETE_STOCK_PRODUCT",
  GET_STOCK_PRODUCTS_BY_ID = "GET_STOCK_PRODUCTS_BY_ID",
  GET_STOCK_PRODUCTS_BY_IDS = "GET_STOCK_PRODUCTS_BY_IDS"
}

export enum EntityType {
  PRODUCT = "product"
}

function addEntity(products: ProductInterface[] = [], entityType: string): ProductInterface[] {
  return produce(products, (draft) =>
    draft.forEach((product) => {
      const newProduct = product;
      newProduct.entity = entityType;
      return newProduct;
    })
  );
}

export const getStockProductById =
  (id: number): AppThunk =>
  (dispatch: IThunkDispatch) => {
    dispatch(
      actionCreatorAsync(Type.GET_STOCK_PRODUCTS_BY_ID, async () => {
        const response = await api.productGetById(id);
        return response;
      })
    );
  };

export const getStockProductsByIds =
  (ids: Array<number>): AppThunk =>
  (dispatch: IThunkDispatch) => {
    dispatch(
      actionCreatorAsync(Type.GET_STOCK_PRODUCTS_BY_IDS, async () => {
        const response = await api.productsGetByIds(ids);
        return response;
      })
    );
  };

export const getStockProducts =
  (query?: QueryParams): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_STOCK_PRODUCTS, async () => {
        try {
          const response = await api.getStockProducts(query);
          if (response.results) {
            return {
              results: addEntity(
                hasOwnProperty(response, "results") ? response.results : undefined,
                EntityType.PRODUCT
              ),
              total: hasOwnProperty(response, "total") ? response.total : undefined
            };
          }
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: response?.message || "Something Went Wrong!.",
              autoTimeout: true
            })
          );
          return null;
        } catch (err) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err?.data?.message || "Something Went Wrong!.",
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );
  };

export const deleteStockProduct =
  (id: number): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.DELETE_STOCK_PRODUCT, async () => {
        const res = await api.deleteStockProduct(id);
        if (res === "OK") {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Product deleted sucessfully!",
              autoTimeout: true
            })
          );
          return { id };
        }
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Could not delete product! Try again!",
            autoTimeout: true
          })
        );
        return null;
      })
    );
  };

export const updateStockProduct =
  (product: StockProducts): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_STOCK_PRODUCT, async () => {
        const data = await api.putStockProduct(product);
        dispatch(updateEntryOnProductUpdate(data.id, data));
        if (data) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Product updated sucessfully",
              autoTimeout: true
            })
          );
          return produce(data, (draft) => {
            draft.entity = EntityType.PRODUCT;
          });
        }
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Couldn't update product",
            autoTimeout: true
          })
        );
        return null;
      })
    );
  };

import { ResponseType } from "../helpers/types";
import { VisitType } from "../interfaces/BillInterfaces";
import { Get, GetCached, Patch, Post } from "./apiHelper";

const bookingsRoot = "/api/bookings";
const adminBookingsRoot = "/api/admin/bookings";

export const getResourceCentreMinimalBookings = async (bookingId: number): Promise<unknown> => {
  const response = (await Get(
    `/api/allBookingsMinimal?bookingId=${bookingId.toString()}`
  )) as ResponseType<unknown>;
  return response.data;
};

export const getBookings = async (
  serviceProviderId: number | null = null,
  start: Date,
  end: Date,
  resourceId?: number,
  publicEventChecked?: boolean
): Promise<unknown> => {
  const response = (await Get(`${bookingsRoot}/`, true, {
    start,
    end,
    serviceProviderId,
    resourceId,
    publicEventChecked
  })) as ResponseType<unknown>;
  return response.data;
};

export const getBookingsForSA = async (filters: Record<string, unknown>): Promise<unknown> => {
  const response = (await Get(`${adminBookingsRoot}/`, true, {
    ...filters
  })) as ResponseType<unknown>;
  return response.data;
};
export const getBooking = async (bookingId: number): Promise<unknown> => {
  const response = (await Get(`${bookingsRoot}/${bookingId}`, true)) as ResponseType<unknown>;
  return response.data;
};

export const updateBooking = async (
  bookingId: number,
  attributes: Record<string, unknown>
): Promise<unknown> => {
  const response = (await Patch(
    `${bookingsRoot}/${bookingId}`,
    attributes,
    true
  )) as ResponseType<unknown>;
  return response.data;
};

export const cancelBooking = async (bookingId: number): Promise<unknown> => {
  const response = (await Patch(
    `${bookingsRoot}/${bookingId}/cancellation`,
    { cancel: true },
    true
  )) as ResponseType<unknown>;
  return response.data;
};

export const deleteBooking = async (bookingId: number): Promise<unknown> => {
  const response = (await Patch(
    `${bookingsRoot}/${bookingId}/deletion`,
    { delete: true },
    true
  )) as ResponseType<unknown>;
  return response.data;
};

export const clientSearch = async (
  search: string,
  resourceCentreId?: number | null | undefined,
  visitType = VisitType.OPD
): Promise<unknown> => {
  const response = (await GetCached({
    url: `/api/clients/search?q=${search}&resourceCentreId=${
      resourceCentreId || ""
    }&visitType=${visitType}`,
    maxAge: 5000
  })) as ResponseType<unknown>;

  return response.data;
};

export const book = async (bookingData: Record<string, unknown>): Promise<unknown> => {
  const response = (await Post(`${bookingsRoot}/book`, bookingData)) as ResponseType<unknown>;
  return response.data;
};

export const getReferrer = async (rcId?: number): Promise<unknown> => {
  const response = (await Get(`/api/referrers?rdId=${rcId}`)) as ResponseType<unknown>;
  return response.data;
};

import * as React from "react";
import * as moment from "moment";
import styles from "./styles.module.css";
import * as calendarData from "../functions/calendarData";
import * as calFns from "../functions/calendarFunctions";
import classNames from "../../../helpers/classNames";
import { t } from "../../translate";

interface MonthViewProps {
  onDayClicked: (date: Date) => void;
  viewBsYear: number;
  viewBsMonth: number;
  defaultActiveDate: Date;
  maxValidDate?: Date;
  minValidDate?: Date;
  style?: React.CSSProperties;
}

interface MonthViewState {
  selectedDate: Date;
}

export default class MonthView extends React.Component<MonthViewProps, MonthViewState> {
  constructor(props: MonthViewProps) {
    super(props);
    this.state = {
      selectedDate: props.defaultActiveDate || new Date()
    };
  }

  private onDaySelect(date: Date) {
    const { onDayClicked } = this.props;
    this.setState({ selectedDate: date });
    onDayClicked(date);
  }

  private getDayInfo = (date: Date) => {
    const bsDate = calFns.convertADtoBS(date);
    return { adDate: new Date(date), ...bsDate };
  };

  private getDays() {
    const { viewBsMonth, viewBsYear } = this.props;

    const startDay = calFns.convertBStoAD(viewBsYear, viewBsMonth, 1);
    startDay.setDate(startDay.getDate() - startDay.getDay()); // Sunday, the first day in the view
    const lastDay = calFns.convertBStoAD(
      viewBsYear,
      viewBsMonth,
      calFns.getBsMonthDays(viewBsYear, viewBsMonth)
    );
    lastDay.setDate(lastDay.getDate() + (6 - lastDay.getDay())); // Saturday, last day in the view
    const days = [];
    while (startDay <= lastDay) {
      days.push(this.getDayInfo(startDay));
      startDay.setDate(startDay.getDate() + 1);
    }
    return days;
  }

  private isDateDisabled = (adDate) => {
    const { maxValidDate, minValidDate } = this.props;
    if (maxValidDate) {
      const disabled = moment(adDate).isAfter(moment(maxValidDate), "day");
      if (disabled) return disabled;
    }
    if (minValidDate) {
      const disabled = moment(adDate).isBefore(moment(minValidDate), "day");
      if (disabled) return disabled;
    }
    return false;
  };

  private isSameDate = (adDate: Date, toMatch: Date = new Date()) =>
    adDate.getDate() === toMatch.getDate() &&
    adDate.getMonth() === toMatch.getMonth() &&
    adDate.getFullYear() === toMatch.getFullYear();

  public render(): JSX.Element {
    const { style, viewBsMonth } = this.props;
    const { selectedDate } = this.state;
    return (
      <div className={`r-n-cal-month-view ${styles.calendar}`}>
        <div className={`r-n-cal-weekdays ${styles.weekdays}`}>
          {calendarData.bsDays(t).map((day) => (
            <div key={day} className={styles.weekday}>
              {day}
            </div>
          ))}
        </div>
        <div className={`r-n-cal-days ${styles.days}`} style={style}>
          {this.getDays().map(({ adDate, bsDate, bsMonth }, i) => (
            <button
              type="button"
              className={classNames("r-n-cal-day", styles.day, {
                [styles.weekend]: i % 7 === 6,
                [styles.dayMuted]: bsMonth !== viewBsMonth,
                [styles.today]: this.isSameDate(adDate),
                [styles.selectedDay]: this.isSameDate(adDate, selectedDate),
                [styles.disabled]: this.isDateDisabled(adDate)
              })}
              key={`${bsDate} ${bsMonth}`}
              onClick={() => {
                if (!this.isDateDisabled(adDate)) {
                  this.onDaySelect(adDate);
                }
              }}
            >
              {calFns.toDevanagariDigits(bsDate)}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

MonthView.defaultProps = {
  maxValidDate: "",
  minValidDate: "",
  style: {}
};

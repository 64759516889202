import * as React from "react";
import { connect } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { serviceProviderActions as actions } from "../../actions";
import { spFullNameSelector, serviceProvidersSortedSelector } from "../../reducers/serviceProvider";
import { t } from "../../components/translate";

const optionsForSelect = (serviceProviders = [], filter?) => {
  if (filter) {
    serviceProviders = filter
      .map((id) => serviceProviders.find((sp) => sp.id === id))
      .filter((s) => Boolean(s));
  }
  return serviceProviders.map((sp) => ({
    label: spFullNameSelector(sp),
    value: sp.id,
    serviceProvider: sp
  }));
};

export const ServiceProviderAutoSelect = ({
  resourceCentreId,
  serviceProviderIds = [],
  serviceProviders,
  onChange,
  loadServiceProviders,
  userPermissionGroup,
  variant = "standard"
}) => {
  React.useEffect(() => {
    if ((!serviceProviders || !serviceProviders.length) && resourceCentreId)
      loadServiceProviders(resourceCentreId);
  }, []);
  if (!serviceProviders) return null;
  return (
    <Autocomplete
      multiple
      value={optionsForSelect(serviceProviders, serviceProviderIds)}
      options={optionsForSelect(serviceProviders)}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      onChange={(e, values) => {
        onChange(
          values.map(({ value }) => value),
          values
        );
      }}
      disabled={["serviceProviderLimited", "serviceProviderFull"].includes(userPermissionGroup)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          margin="dense"
          label={t("serviceProvider")}
          placeholder={t("report.selectServiceProvider")}
          fullWidth
        />
      )}
    />
  );
};

export default connect(
  (state) => ({
    serviceProviders: serviceProvidersSortedSelector(state),
    resourceCentreId: state.userContext.resourceCentreId,
    userPermissionGroup: state.userContext.userCreds.userGroups[0]
  }),
  (dispatch) => ({
    loadServiceProviders: (id) =>
      dispatch(actions.getResourceCentreServiceProviders({ resourceCentreId: id }))
  })
)(ServiceProviderAutoSelect);

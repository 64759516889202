import React from "react";
import { Box, Button, Fade } from "@mui/material";
import { useLocation } from "react-router";
import {
  KnowledgeBaseDocumentTag,
  useKnowledgeBaseByTags,
  KnowledgeBaseDocumentTags
} from "../../api/knowledgeBase";

export const allTags = [
  KnowledgeBaseDocumentTag.dashboard,
  KnowledgeBaseDocumentTag.calendar,
  KnowledgeBaseDocumentTag.billing,
  KnowledgeBaseDocumentTag.opd,
  KnowledgeBaseDocumentTag.lab,
  KnowledgeBaseDocumentTag.services,
  KnowledgeBaseDocumentTag.clients,
  KnowledgeBaseDocumentTag.stock,
  KnowledgeBaseDocumentTag.messaging,
  KnowledgeBaseDocumentTag.reports,
  KnowledgeBaseDocumentTag.ipd,
  KnowledgeBaseDocumentTag.hmis,
  KnowledgeBaseDocumentTag.daybook
];

interface RelatedArticleListProps {
  onSelect: (KnowledgeBaseDocument) => void;
}

function RelatedArticleList({ onSelect }: RelatedArticleListProps): JSX.Element | null {
  const { pathname } = useLocation();
  const matchedTags = allTags.filter((tag) => pathname.includes(tag)) as KnowledgeBaseDocumentTags;

  const currentTags: KnowledgeBaseDocumentTags =
    matchedTags.length > 0 ? [...matchedTags, KnowledgeBaseDocumentTag.faq] : [];

  const { data, isLoading } = useKnowledgeBaseByTags(currentTags);

  if (!data?.length || isLoading) {
    return null;
  }

  return (
    <Fade in={!!data.length} timeout={300} unmountOnExit>
      <Box mt={1}>
        <Box component="ul" m={0} p={0} sx={{ listStyleType: "none" }}>
          {data.map((article) => (
            <Box key={article.id} component="li" onClick={() => onSelect(article)}>
              <Button
                fullWidth
                variant="text"
                sx={{
                  "&.MuiButton-text": {
                    color: "#626262",
                    fontSize: 14,
                    fontWeight: 500,
                    justifyContent: "flex-start",
                    textTransform: "none"
                  }
                }}
              >
                {article.data.title}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Fade>
  );
}

export default RelatedArticleList;

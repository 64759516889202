import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import Capitalize from 'lodash/capitalize';
import * as React from 'react';

interface Props<T> {
  toOrder: Array<T>;
  onSave: (v: Array<number>) => void;
}

function getOrderPairState<T extends { id: number }>(toOrder: Array<T>) {
  return toOrder.reduce((acc, cur, i) => {
    acc[cur.id] = i;
    return acc;
  }, {});
}

export default function OrderableSort<T extends { label: string; id: number }>(props: Props<T>) {
  const { toOrder, onSave } = props;

  const [pairState, setPairState] = React.useState<{ [k: string]: any }>(
    getOrderPairState(toOrder)
  );
  const allOptions = [...new Array(toOrder.length)].map((_, i) => i);

  return (
    <Box p={2}>
      {Object.keys(pairState).map((k) => {
        return (
          <Box display="flex" alignItems="center">
            <Select
              onChange={(e) => {
                setPairState({ ...pairState, [k]: e.target.value });
              }}
              value={pairState[k].toString()}
              style={{ width: '120px' }}
            >
              <MenuItem value={'None'}>None</MenuItem>
              {allOptions.map((item) => (
                <MenuItem value={item as any} disabled={Object.values(pairState).includes(item)}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Typography style={{ marginLeft: '16px' }}>
              {Capitalize(toOrder.find((el) => el.id === Number(k)).label)}
            </Typography>
          </Box>
        );
      })}
      <Box mt={'16px'}>
        <Button
          onClick={() => {
            const formattedEntries = Object.entries(pairState)
              .map((entry) => {
                if (entry[1] === 'None') {
                  return [entry[0], Infinity];
                } else return entry;
              })
              .sort((a, b) => a[1] - b[1])
              .map((entry) => Number(entry[0]));

            onSave(formattedEntries);
          }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

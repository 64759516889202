import React, { useState, useEffect } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { connect } from "react-redux";
import Filters from "./Filters";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import useMobileScreen from "../../hooks/useMobileScreen";
import List, { EmptyView } from "../../components/OList";
import { getSMSReport } from "../../api/reports";
import "./Reports.scss";
import Can from "../Policy/Can";
import PageControl from "../../components/PageControl";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";
import { t } from "../../components/translate";

const SMSReport = ({ resourceCentreId }) => {
  const [filters, setFilters] = useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    messageStatus: "Fail"
  });
  const [reportData, setReportData] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 50;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      const data = await getSMSReport({ ...filters, resourceCentreId, page, pageSize });
      setTotal(data.total);
      setReportData(data.results);
    })();
  }, [filters, page]);

  const SMS_STATUSES = {
    1000: "A required field is missing",
    1001: "Invalid IP Address",
    1002: "Invalid Token",
    1003: "Account Inactiv$",
    1004: "Account Inactive",
    1005: "Account has been expired",
    1006: "Account has been expired",
    1007: "Invalid Receiver",
    1008: "Invalid Sender",
    1010: "Text cannot be empty",
    1011: "No valid receiver",
    1012: "No Credits Available",
    1013: "Insufficient Credits",
    2000: "Network Error"
  };

  const isMobileScreen = useMobileScreen();
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Can policyAccessKey="report:smsReport">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1000px" : "auto"}>
          <Box mx="32px">
            <Filters
              filters={filters}
              onSetFilters={(newfilters) => {
                setFilters(newfilters);
                setPage(0);
              }}
            />
          </Box>
          <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}>
            <Box
              marginTop="32px"
              width={isMobileScreen ? "960px" : "auto"}
              height="calc(100vh - 260px)"
              className="smsReportList"
            >
              <List
                automation="smsReportList"
                data={reportData}
                rowHeight={40}
                defaultSortColumn="clientName"
                defaultSortOrder={-1}
                activeRow={0}
                adjustHeightToContents
                columns={[
                  {
                    key: "createdDate",
                    label: `${t("msgReport.Date")}`,
                    formatter: (row) => (
                      <Typography>
                        {calFns.convertADtoBS(new Date(row.created_at)).formatted}
                      </Typography>
                    )
                  },
                  {
                    key: "phone",
                    label: `${t("msgReport.PhNo.")}`,
                    formatter: (row) => <Typography>{row.phone}</Typography>
                  },
                  {
                    key: "status",
                    label:
                      filters.messageStatus === "Fail"
                        ? `${t("msgReport.reasonOfFailure")}`
                        : "SMS Status",
                    formatter: (row) => (
                      <Typography>{SMS_STATUSES[row.smsStatus] || row.smsStatus}</Typography>
                    )
                  },
                  {
                    key: "type",
                    label: `${t("msgReport.msgType")}`,
                    formatter: () => <Typography>SMS</Typography>
                  },
                  {
                    key: "sms",
                    label: `${t("msgReport.smsContent")}`,
                    formatter: (row) => (
                      <Tooltip title={row.sms}>
                        <Typography>{row.sms.substring(0, 20)}...</Typography>
                      </Tooltip>
                    )
                  }
                ]}
              >
                <EmptyView>
                  <Box textAlign="center" padding="50px">
                    {t("msgReport.noItemsToShow")}
                  </Box>
                </EmptyView>
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="labNavigatePage">
        <PageControl
          page={page}
          pageSize={pageSize}
          onSetPage={(v) => {
            setPage(v);
          }}
          maximumDataCount={total}
        />
      </Box>
    </Can>
  );
};

const mapStateToProps = (state) => ({
  resourceCentreId: state.userContext.resourceCentreId
});

export default connect(mapStateToProps, null)(SMSReport);

/* eslint-disable react/no-array-index-key */
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { EditableTableView } from "../../components/EditableTable/EditableTable";
import { removeCommasFromNumber } from "../../helpers/number";
import { LabClient, LabTestRecordResultData } from "../../interfaces/Lab";
import { RootState } from "../../store";
import "./labDetails.scss";
import {
  AdvancedLabRangeHandler,
  advancedLabRangeIndicator,
  scaleFont
} from "./LabPrint/LabPrintFunctions";
import { DataComment } from "./LabPrint/Templates/BlockCategory/BlockCategoryCoreData";
import { TestNameWithSampleInfo } from "./LabPrint/Templates/DefaultTemplate/DefaultTemplateCore";

interface Props {
  data: Array<LabTestRecordResultData>;
  headers: Array<string>;
  labClient: LabClient;
  comment: string | null;
  showComment: boolean;
  showSampleCollectedBy: boolean;
}

function GenerateRowLevel({ rowLevel, labClient, showMethods, showRangeFlag }) {
  const { gender, dob } = labClient;

  const rangeSymbol = advancedLabRangeIndicator(
    Number(removeCommasFromNumber(rowLevel.formData?.reading)),
    rowLevel.ranges,
    dob,
    gender
  );

  return (
    <div className="details_row_container">
      <div className="details_row_wrapper">
        <div className="details_row_cell">
          <Typography>{rowLevel.name}</Typography>
        </div>
        <div className="details_row_cell">
          <Typography sx={{ fontWeight: rangeSymbol ? 600 : 400 }}>
            {rowLevel.formData?.reading}
          </Typography>
        </div>
        {showRangeFlag && (
          <div className="details_row_cell">
            <Typography>
              {rowLevel.formData?.reading !== "" &&
                (rangeSymbol.highSymbol || rangeSymbol.lowSymbol || null)}
            </Typography>
          </div>
        )}
        <div className="details_row_cell">
          <Typography>{rowLevel.unit}</Typography>
        </div>
        <div className="details_row_cell">
          <AdvancedLabRangeHandler range={rowLevel.ranges} dob={dob} gender={gender} />
        </div>
        {showMethods && (
          <div className="details_row_cell">
            <Typography>{rowLevel.methods}</Typography>
          </div>
        )}
      </div>
      {rowLevel.subTests && (
        <Box pl="16px">
          {rowLevel?.subTests?.map((st, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <GenerateRowLevel
              showMethods={showMethods}
              rowLevel={st}
              key={i}
              labClient={labClient}
              showRangeFlag={showRangeFlag}
            />
          ))}
        </Box>
      )}
    </div>
  );
}

export default function LabDetailsCore(props: Props): JSX.Element {
  const labSettings = useSelector((state: RootState) => {
    const rc =
      state.resources.resourceCentres.find(
        (resourceCentre) => resourceCentre.id === state.userContext.resourceCentreId
      ) || state.userContext.resourceCentre;
    return rc?.labSettings;
  });

  const { showMethods, showRangeFlag } = labSettings;
  const { data, headers, labClient, comment, showComment, showSampleCollectedBy } = props;

  return (
    <div className="lab_details_container">
      <div className="lab_details_headers_wrapper">
        {headers.map((header) => (
          <Typography className="lab_details_header_cell" key={header}>
            {header}
          </Typography>
        ))}
      </div>

      <div className="lab_details_data_container">
        {data?.map((datum) => (
          <div
            className="group_container"
            key={datum.id}
            style={{ borderBottom: "1px solid lightgrey", marginBottom: "16px" }}
          >
            <TestNameWithSampleInfo
              test={{
                name: datum.name,
                style: {
                  fontSize: scaleFont("0.33cm", 1.105),
                  textDecoration: "underline",
                  fontWeight: 600
                }
              }}
              sampleInfo={{
                sampleType: datum.sampleInfo?.sampleType
              }}
              collectedBy={{ name: showSampleCollectedBy ? datum.collectedBy?.name : "" }}
            />
            {datum?.labTests?.map((labTest, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <GenerateRowLevel
                showMethods={showMethods}
                rowLevel={labTest}
                key={i}
                labClient={labClient}
                showRangeFlag={showRangeFlag}
              />
            ))}

            {datum.additionalTestData && (
              <>
                <Typography fontWeight={600} sx={{ textDecoration: "underline" }}>
                  Additional Test Data:
                </Typography>
                <Typography component="div">
                  <div
                    style={{ whiteSpace: "pre-wrap" }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: datum.additionalTestData }}
                  />
                </Typography>
              </>
            )}

            {datum.interpretationTemplate && datum.interpretationTemplate.show && (
              <Box mt="16px">
                <Typography fontWeight={600} sx={{ textDecoration: "underline" }}>
                  Interpretation Template:
                </Typography>
                <EditableTableView tableState={datum.interpretationTemplate} />
              </Box>
            )}
          </div>
        ))}
      </div>
      {showComment && comment && <DataComment comment={comment} />}
    </div>
  );
}

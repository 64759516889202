import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography
} from "@mui/material";
import * as React from "react";
import { fileUploader } from "../../api/uploader";

const ALLOWED_EXTENSIONS = ["jpg", "jpeg", "png", "pdf"];

const NON_IMAGE_FILE_EXTENSIONS = ["pdf"];

interface DialogProps {
  type: "success" | "error";
  open: boolean;
  setOpen: (v: boolean) => void;
  afterSuccessOkAction: () => void;
}

function SuccessErrorDialog(props: DialogProps) {
  const { type, open, setOpen, afterSuccessOkAction } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Alert!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {type === "error"
            ? "All files couldn't be successfully uploaded!"
            : "All files have been successfully uploaded!"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            if (type === "success") {
              afterSuccessOkAction();
            }
          }}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface FileStateItem {
  fileName: string;
  file: string;
  extension: string;
  fileBlob: Blob;
}

interface Props {
  fileUploaderParams: {
    s3UploadGetterFunction: (fileName: string) => Promise<unknown>;
    afterUploadAction: (s3BucketUrl: string, sId: number, s3Key: string) => Promise<unknown>;
    id: number;
  };
  next: () => void;
}

interface PreviewModeProps {
  items: Array<{ id: string; url: string }>;
  open: boolean;
  setOpen: (v: boolean) => void;
  title?: string;
  deleteProps:
    | {
        allowDeletion: false;
      }
    | {
        allowDeletion: true;
        onDelete: (s3Key: string) => void;
      };
}

function PreviewMode(props: PreviewModeProps) {
  const { items, open, setOpen, title, deleteProps } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box mt="16px">
          {items.length > 0 ? (
            <ImageList sx={{ width: 500, height: "auto" }}>
              {items.map((item) => (
                <Box key={item.id}>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    src={item.url}
                    onKeyDown={() => ({})}
                    alt="No Preview available"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "200px",
                      border: "4px solid lightgrey",
                      textAlign: "center",
                      objectFit: "cover",
                      lineHeight: "60px"
                    }}
                    onClick={() => {
                      window.open(item.url, "_blank");
                    }}
                  />
                  {deleteProps.allowDeletion && (
                    <Button onClick={() => deleteProps.onDelete(item.id)}>Remove</Button>
                  )}
                </Box>
              ))}
            </ImageList>
          ) : (
            <Typography>No files found!</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

PreviewMode.defaultProps = {
  title: "Successfully Uploaded Files"
};

export default function OkhatiUploader(props: Props): JSX.Element {
  const [filesState, setFilesState] = React.useState<Array<FileStateItem>>([]);

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [errorDialogState, setErrorDialogState] = React.useState<null | "error" | "success">(null);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState<boolean>(false);
  const [previewMode, setPreviewMode] = React.useState<boolean>(false);
  const [uploadedImages, setUploadedImages] = React.useState<Array<{ id: string; url: string }>>(
    []
  );

  const fileInputRef = React.useRef<HTMLInputElement>();

  const generateStateFromFiles = (fileList: FileList) => {
    const objectURLFiles: Array<FileStateItem> = [];
    Array.prototype.forEach.call(fileList, (file) => {
      const fileName = file.name.lastIndexOf(".") + 1;
      const extension = file.name.substr(fileName, file.name.length).toLowerCase();
      if (file && ALLOWED_EXTENSIONS.includes(extension?.toLowerCase())) {
        if (file.size > 2097152) {
          // eslint-disable-next-line no-alert
          window.alert(`File size too large for file ${file.name}, please upload files under 2Mib`);
        } else {
          const objectURLFile = URL.createObjectURL(file);
          objectURLFiles.push({ fileName, extension, file: objectURLFile, fileBlob: file });
        }
      }
    });
    return objectURLFiles;
  };

  const handleRemoveImage = (
    ref: React.MutableRefObject<HTMLInputElement> | null,
    removeAtIndex: number
  ) => {
    if (ref) {
      const dt = new DataTransfer();
      const { files } = ref.current;

      Array.prototype.forEach.call(files, (file, i) => {
        if (removeAtIndex !== i) dt.items.add(file);
      });
      // eslint-disable-next-line no-param-reassign
      ref.current.files = dt.files;
      setFilesState(generateStateFromFiles(dt.files));
    }
  };

  const handleAddImage = (e) => {
    e.preventDefault();
    const { files } = e.target;
    const stateFiles = generateStateFromFiles(files);
    setFilesState(stateFiles);
  };

  const { fileUploaderParams, next } = props;

  return (
    <Box>
      <Button onClick={() => setDialogOpen(true)}>Upload New Files</Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Okhati File Uploader</DialogTitle>
        <DialogContent>
          <input
            accept=".jpeg, .jpg, .png, .pdf"
            type="file"
            onChange={handleAddImage}
            id="actual-btn"
            multiple
            ref={fileInputRef}
            title="Uploader"
          />
          <Box mt="16px">
            <ImageList sx={{ width: 500, height: "auto" }}>
              {filesState
                .filter((item) => !NON_IMAGE_FILE_EXTENSIONS.includes(item.extension))
                .map((item, i) => (
                  <ImageListItem key={item.file} style={{ cursor: "pointer" }}>
                    <img src={item.file} alt="" />
                    <ImageListItemBar
                      title={
                        <Box
                          width="100%"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemoveImage(fileInputRef, i)}
                        >
                          <Typography>Remove File</Typography>
                        </Box>
                      }
                    />
                  </ImageListItem>
                ))}
            </ImageList>
            <Box>
              {filesState
                .filter((item) => NON_IMAGE_FILE_EXTENSIONS.includes(item.extension))
                .map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box key={i} width="50%" mt="8px" style={{ cursor: "pointer" }}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="120px"
                      border="1px solid lightgrey"
                    >
                      No Preview Available!
                    </Box>
                    <Box
                      style={{ backgroundColor: "rgba(0,0,0,0.8)", color: "white" }}
                      px="16px"
                      py="8px"
                    >
                      <Typography>Remove File</Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
          <Button
            onClick={async () => {
              try {
                const fileUploadPromises = filesState.map((file) =>
                  fileUploader({ ...fileUploaderParams, pictureData: file.fileBlob })
                );
                const responses = await Promise.all(fileUploadPromises);
                setUploadedImages(responses);
                setErrorDialogOpen(true);
                setErrorDialogState("success");
              } catch (e) {
                setErrorDialogOpen(true);
                setErrorDialogState("error");
              }
            }}
            autoFocus
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessErrorDialog
        type={errorDialogState}
        open={errorDialogOpen}
        setOpen={setErrorDialogOpen}
        afterSuccessOkAction={() => {
          setPreviewMode(true);
          setDialogOpen(false);
          next();
        }}
      />
      <PreviewMode
        open={previewMode}
        setOpen={setPreviewMode}
        items={uploadedImages}
        deleteProps={{ allowDeletion: false }}
      />
    </Box>
  );
}

OkhatiUploader.PreviewMode = PreviewMode;

import useCurrentResourceCentre from "./useCurrentResourceCentre";

const useIsDaybookSubscribed = (): { isDaybookSubscribed: boolean } => {
  const rc = useCurrentResourceCentre();

  const daybook = rc?.subscriptions?.features?.daybook;

  return {
    isDaybookSubscribed: daybook?.subscribed ?? false // Default to false if not defined
  };
};

export default useIsDaybookSubscribed;

import React from "react";
import { Box, Typography } from "@mui/material";
import { SurveyData, StyledTypography } from "./SurveyCreateEditForm";
import { toStartCase } from "../../../helpers/formatters";

const SurveyFormContent = ({ data }: { data: SurveyData }): JSX.Element | null => {
  if (!data) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2
      }}
    >
      {data.document.defaultQuestions.map((item) => {
        if (!item.answer && !item.customAnswer) return null;
        return (
          <div key={item.question}>
            <StyledTypography text={item.question} />
            <Typography whiteSpace="pre" mt={1}>
              {`${toStartCase(item.answer || "")} ${
                item.customAnswer ? ` (${toStartCase(item.customAnswer)})` : ""
              } `}
            </Typography>
          </div>
        );
      })}
      {data.document.customQuestions.map((item) => {
        if (!item.answer && !item.customAnswer) return null;
        return (
          <div key={item.question}>
            <StyledTypography text={item.question} />
            <Typography whiteSpace="pre" mt={1}>
              {`${toStartCase(item.answer || "")} ${
                item.customAnswer ? ` (${toStartCase(item.customAnswer)})` : ""
              } `}
            </Typography>
          </div>
        );
      })}
    </Box>
  );
};

export default SurveyFormContent;

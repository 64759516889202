import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { connect, RootStateOrAny } from "react-redux";
import SnackbarContent from "@mui/material/SnackbarContent";
import * as NotificationActions from "../../actions/notification";
import styles from "./styles.module.css";

export interface NotificationType {
  id: string | number;
  variant: "success" | "warning" | "error" | "info";
  message: string | React.ReactElement;
  autoTimeout?: boolean;
  timeout?: number;
}

interface notificaitonsProps {
  flashMessages: NotificationType[];
  removeNotification: (id: number) => void;
}

const IconsVariantMap = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
  close: <CloseIcon />
};

const OSnackbarContent = React.forwardRef(
  (props: Record<string, any>, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, message, onClose, variant, ...other } = props;
    return (
      <div ref={ref}>
        <SnackbarContent
          className={`${styles[variant]} ${className}`}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={styles.message}>
              {IconsVariantMap[variant]}
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={styles.close}
              onClick={onClose}
              size="large"
            >
              {IconsVariantMap.close}
            </IconButton>
          ]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        />
      </div>
    );
  }
);

function FlashMessages(props: notificaitonsProps) {
  const { removeNotification, flashMessages } = props;

  const handleClose = (id) => {
    removeNotification(id);
  };

  const getAutoHideDuration = (notification: NotificationType) => {
    if (!notification.autoTimeout) {
      return null;
    }
    if (notification.variant === "success") {
      return notification.timeout || 1500;
    }
    return notification.timeout || 2500;
  };

  return (
    <>
      {flashMessages.map((notification) => (
        <Snackbar
          key={notification.id}
          className={`notification-${notification.variant || "info"}`}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open
          autoHideDuration={getAutoHideDuration(notification)}
          onClose={() => handleClose(notification.id)}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
        >
          <OSnackbarContent
            onClose={() => handleClose(notification.id)}
            variant={notification.variant || "info"}
            message={notification.message}
          />
        </Snackbar>
      ))}
    </>
  );
}

export default connect(
  (state: RootStateOrAny) => ({
    flashMessages: state.flashMessages
  }),
  (dispatch) => ({
    removeNotification: (id) => {
      dispatch(NotificationActions.notificationRemove(id));
    }
  })
)(FlashMessages);

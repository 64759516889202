export function storageAvailable(type = 'localStorage') {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

const localStorageObj = () => {
  return storageAvailable('localStorage')
    ? window.localStorage
    : {
        setItem: () => null,
        getItem: () => null,
        removeItem: () => null
      };
};

export default localStorageObj();

import React from 'react';
import { TextField, Typography, Box } from '@mui/material';

export const Label = ({ children }) => (
  <Typography component={'span'} style={{ display: 'flex' }}>
    <Box
      component={'span'}
      display={'flex'}
      fontSize={'0.83em'}
      fontWeight={740}
      alignItems={'center'}
      width={'100px'}
    >
      {children}
    </Box>
  </Typography>
);

function GenericTextField({
  value,
  dataTestmation,
  onChange,
  placeholderText,
  maxLength,
  rows = 1,
  rowsMax = 3
}) {
  const formHelperTextProps = {
    style: { position: 'absolute', right: 0, bottom: -5, margin: 5 }
  };
  return (
    <TextField
      data-testmation={dataTestmation}
      margin="dense"
      variant="outlined"
      fullWidth
      multiline
      rows={rows}
      maxRows={rowsMax}
      value={value || ''}
      placeholder={placeholderText}
      inputProps={{ maxLength }}
      helperText={`${value?.length || 0}/${maxLength}`}
      onChange={onChange}
      FormHelperTextProps={formHelperTextProps}
    />
  );
}

export default GenericTextField;

import React from "react";
import { Box, Typography, Menu } from "@mui/material";
import { FieldValues, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import styles from "../style.module.css";

const colorThemes = ["#009654", "#005196", "#c271cc"];

interface ThemeProps {
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}
const Theme = ({ getValues, setValue }: ThemeProps): JSX.Element => {
  const [anchorElPrimary, setAnchorElPrimary] = React.useState(null);
  const openPrimary = Boolean(anchorElPrimary);

  const handleClickPrimary = (event) => {
    setAnchorElPrimary(event.currentTarget);
  };

  const handleClosePrimary = () => {
    setAnchorElPrimary(null);
  };

  return (
    <Box width="100%" display="flex" pb={2} alignItems="center" gap={3}>
      <Box minWidth="160px" fontWeight="600" display="flex">
        <Typography>
          <Box component="span" fontSize="14px" fontWeight="600">
            Application theme color (navigation bar color)
          </Box>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box
          className={styles.colorStyles}
          bgcolor={getValues("primaryColour")}
          onClick={handleClickPrimary}
        />
        <Menu
          anchorEl={anchorElPrimary}
          keepMounted
          open={openPrimary}
          onClick={handleClosePrimary}
        >
          <Box display="flex">
            {colorThemes.map((color) => (
              <Box
                key={color}
                className={styles.colorStyles}
                bgcolor={color}
                onClick={() => {
                  handleClosePrimary();
                  setValue("primaryColour", color);
                }}
              />
            ))}
          </Box>
        </Menu>
      </Box>
    </Box>
  );
};

export default Theme;

import * as React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Grid, TextField } from '@mui/material';
import { tl } from '../../components/translate';
import CalendarDropdown from '../../components/CalendarDropdown/CalendarDropdown';
import styles from './BookingCreateEdit.module.css';
import { Moment } from 'moment';

interface FollowUp {
  value: boolean;
  reminders: Array<{}>;
  defaultDate: Date;
  maxValidDate: Moment;
  editable: boolean;
  onChange: (value, reminders) => void;
}

const FollowUp: React.FC<FollowUp> = ({ value, reminders, maxValidDate, onChange, editable }) => {
  return (
    <FormGroup row classes={{ root: styles.followUpContainer }}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={!editable}
            checked={value}
            onChange={() => onChange(!value, reminders)}
          />
        }
        label={tl('booking.isFollowUp')}
        color="primary"
      />
      {value && reminders &&
        reminders.map((rem, i) => (
          <Reminder key={i} values={rem} onChange={(rem) => onChange(value, [rem])} maxValidDate={maxValidDate} />
        ))}
    </FormGroup>
  );
};

const Reminder: React.FC<{ values: any; maxValidDate: Moment, onChange: ({ }) => void }> =
  ({ values, maxValidDate, onChange }) => {
    return (
      <Grid container>
        <Grid item xs={4} xl={4}>
          <CalendarDropdown
            placeholder={'Remind on'}
            label={'Remind on'}
            maxValidDate={maxValidDate}
            date={new Date(values.on)}
            onChange={(on) => onChange({ ...values, on })}
            format={'formatted3'}
          />
        </Grid>
        <Grid item xs={8} xl={8}>
          <TextField
            className={styles.followUpRemark}
            label={'Remark'}
            value={values.remark}
            onChange={({ target }) => onChange({ ...values, remark: target.value })}
          ></TextField>
        </Grid>
      </Grid>
    );
  };

export default FollowUp;

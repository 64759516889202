import React from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import startCase from "lodash/startCase";
import ClientSearch from "../../../components/ClientSearch";
import MuiPhoneNumber from "../../../components/PhoneNumber";
import { IpdTypes } from "../../../interfaces/IpdInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { clientSearchSecondaryLabel } from "../../../models/Client";
import { RootState } from "../../../store";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import styles from "../styles.module.css";
import { IPDFormState } from "./CreateIpdAdmission";
import { isValidPhone } from "../../../helpers/phoneNumber";
import useGetNextIpdNumber from "../../../hooks/useGetNextIpdNumber";
import { t, tl } from "../../../components/translate";
import useIsSSFEnabled from "../../../hooks/useIsSSFEnabled";
import { useAppDispatch } from "../../../store/hooks";
import { getActiveClaimCode } from "../../../api/ssf";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1, 1, 1, 0),
        width: 250
      }
    }
  })
);

export default function IpdAdmissionForm({
  setIpdFormData,
  ipdFormData,
  serviceProviders,
  mode,
  isFromDashboard
}: {
  setIpdFormData: (data: IPDFormState) => void;
  ipdFormData: IPDFormState;
  serviceProviders: ServiceProvider[];
  mode: string;
  isFromDashboard?: boolean;
}): JSX.Element {
  const classes = useStyles();

  const [validPhone, setValidPhone] = React.useState(false);
  const { beds, wards } = useSelector((state: RootState) => state.bedManagement);

  const [wardValue, setWardValue] = React.useState({
    shortCode: "",
    id: null
  });

  const [bedValue, setBedValue] = React.useState({
    number: "",
    id: null
  });
  const { isSsfEnabled } = useIsSSFEnabled();
  const dispatch = useAppDispatch();
  const [isSsfIpd, setIsSsfIpd] = React.useState(false);

  const ipdNumber = useGetNextIpdNumber(mode === "create");

  React.useEffect(() => {
    if (ipdFormData?.wardNumber) {
      const ipdWard = wards.find((ward) => ward.id === ipdFormData.wardNumber);
      setWardValue(ipdWard);
      setIpdFormData({
        ...ipdFormData,
        roomType: ipdWard?.isRoom ? "Cabin" : "Ward"
      });
    }
  }, [ipdFormData.wardNumber, wards]);

  React.useEffect(() => {
    if (ipdFormData?.bedNumber) {
      setBedValue(beds.find((bed) => bed.id === ipdFormData.bedNumber));
    }
  }, [ipdFormData.bedNumber, beds]);

  React.useEffect(() => {
    if (ipdFormData.client?.id && isSsfIpd) {
      (async () => {
        try {
          const code = await getActiveClaimCode(ipdFormData.client.id);
          setIpdFormData({ ...ipdFormData, claimCode: code.claimCode });
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.msg || commonErrorMessage,
              autoTimeout: false
            })
          );
        }
      })();
    }
  }, [ipdFormData.client?.id]);
  const phoneErrorMsg = "Please, Enter the valid phone No !";
  return (
    <div style={{ width: "100%" }}>
      <form className={classes.root} noValidate autoComplete="off">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: "auto"
          }}
        >
          <Box>
            {isSsfEnabled && (
              <Box display="flex" gap={2} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isSsfIpd}
                      onChange={() => {
                        setIsSsfIpd(!isSsfIpd);
                        setIpdFormData({ ...ipdFormData, claimCode: "", client: {} });
                      }}
                    />
                  }
                  label="Is SSF"
                />
                {isSsfIpd && ipdFormData.client.id && (
                  <Typography>
                    {`Claim code: ${
                      ipdFormData.claimCode || "Not found, please create bill for ipd first."
                    }`}
                  </Typography>
                )}
              </Box>
            )}
            <ClientSearch
              data-testmation="clientSearch"
              autoFocus={"createAnother" && !ipdFormData.client}
              client={ipdFormData.client}
              setClient={(v) => setIpdFormData({ ...ipdFormData, client: v })}
              variant="outlined"
              isDisabled={mode === "edit"}
              margin="dense"
              label={tl("selectClient")}
              placeholder={t("selectClient")}
              secondaryText={[
                { type: clientSearchSecondaryLabel.CUSTOMER_NUMBER },
                {
                  type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER
                }
              ]}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <TextField
              label={tl("file/referenceNumber")}
              variant="outlined"
              placeholder={t("file/referenceNumber")}
              value={ipdFormData.referenceNumber}
              onChange={(e) => setIpdFormData({ ...ipdFormData, referenceNumber: e.target.value })}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Typography>
              IPD Number:{" "}
              <strong>
                {mode === "create" ? ipdNumber?.nextNumber || "" : ipdFormData.ipdNumber}
              </strong>
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "start" }}>
            <Autocomplete
              disabled={isFromDashboard}
              value={wardValue}
              options={wards}
              getOptionLabel={(item) => item.shortCode}
              data-testmation="wardNumber"
              id="wardNumber"
              onChange={(_, v) => {
                if (!v || typeof v === "string") return;
                setIpdFormData({
                  ...ipdFormData,
                  wardNumber: v.id
                });
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label={tl("wardName")}
                  placeholder={t("wardName")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Autocomplete
              disabled={isFromDashboard}
              value={bedValue}
              options={beds.filter((bed) => bed.wardId === ipdFormData.wardNumber)}
              getOptionLabel={(item) => item.number}
              data-testmation="bedNumber"
              id="bedNumber"
              onChange={(_, v) => {
                if (!v || typeof v === "string") return;
                setIpdFormData({
                  ...ipdFormData,
                  bedNumber: v.id
                });
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label={tl("bedNumber")}
                  placeholder={t("bedNumber")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Box>
          <Box>
            <TextField
              data-testmation="roomType"
              id="roomType"
              label={tl("roomType")}
              placeholder={t("roomType")}
              variant="outlined"
              size="small"
              disabled={Boolean(ipdFormData?.roomType)}
              margin="dense"
              value={ipdFormData.roomType}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setIpdFormData({ ...ipdFormData, roomType: e.target.value })}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "1rem 0"
            }}
          >
            <Typography style={{ marginBottom: "0.5rem " }}>
              {tl("emergencyContactDetails")}
            </Typography>
            <Box style={{ display: "flex", justifyContent: "start" }}>
              <TextField
                data-testmation="name"
                id="secondaryContactName"
                label={tl("guardianName")}
                placeholder={t("guardianName")}
                variant="outlined"
                size="small"
                required
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={ipdFormData.emergencyContact.name}
                onChange={(e) =>
                  setIpdFormData({
                    ...ipdFormData,
                    emergencyContact: {
                      ...ipdFormData.emergencyContact,
                      name: e.target.value
                    }
                  })
                }
              />
              <MuiPhoneNumber
                onlyCountries={["np"]}
                size="small"
                value={ipdFormData.emergencyContact.phone}
                defaultCountry="np"
                onChange={(value) => {
                  if (value && isValidPhone(value)) {
                    setValidPhone(false);
                    setIpdFormData({
                      ...ipdFormData,
                      emergencyContact: {
                        ...ipdFormData.emergencyContact,
                        phone: value
                      }
                    });
                  } else {
                    setValidPhone(true);
                    setIpdFormData({
                      ...ipdFormData,
                      emergencyContact: {
                        ...ipdFormData.emergencyContact,
                        phone: ""
                      }
                    });
                  }
                }}
                data-testmation="phone"
                label={tl("phoneNumber")}
                variant="outlined"
                error={validPhone}
                helperText={validPhone ? phoneErrorMsg : ""}
              />
            </Box>
            <Box>
              <TextField
                data-testmation="relation"
                id="relation"
                label={tl("relation")}
                placeholder={t("relation")}
                variant="outlined"
                size="small"
                required
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={ipdFormData.emergencyContact.relation}
                onChange={(e) =>
                  setIpdFormData({
                    ...ipdFormData,
                    emergencyContact: {
                      ...ipdFormData.emergencyContact,
                      relation: e.target.value
                    }
                  })
                }
              />
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "1rem 0 2rem 0"
            }}
          >
            <Typography style={{ marginBottom: "0.5rem " }}>{tl("caseInfo")}</Typography>
            <Box style={{ display: "flex", justifyContent: "start" }}>
              <Autocomplete
                data-testmation="labTestSelectSP"
                options={serviceProviders.map((item) => ({
                  ...item,
                  fullname: `${item.firstName} ${item.lastName}`
                }))}
                getOptionLabel={(option: any) => startCase(`${option.fullname} `)}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={tl("selectDoc")}
                    placeholder={t("selectDoc")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props} key={option.id}>
                    {startCase(option.fullname)}
                  </li>
                )}
                value={ipdFormData.doctorInCharge}
                onChange={(e, value) => {
                  setIpdFormData({
                    ...ipdFormData,
                    doctorInChargeId: value?.id,
                    doctorInCharge: value
                  });
                }}
              />
              <Autocomplete
                data-testmation="ipdAuthorized"
                options={serviceProviders.map((item) => ({
                  ...item,
                  fullname: `${item.firstName} ${item.lastName}`
                }))}
                getOptionLabel={(option: any) => startCase(`${option.fullname} `)}
                renderOption={(props, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props} key={option.id}>
                    {startCase(option.fullname)}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={tl("selectAuthorisedPersonal")}
                    placeholder={t("selectAuthorisedPersonal")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={ipdFormData.authorizedPersonnel}
                onChange={(e, value) => {
                  setIpdFormData({
                    ...ipdFormData,
                    authorizedPersonnelId: value?.id,
                    authorizedPersonnel: value
                  });
                }}
              />
            </Box>
            <Box style={{ display: "flex", justifyContent: "start" }}>
              <TextField
                id="ipd Case"
                data-testmation="ipdCase"
                select
                label={tl("IPDCase")}
                value={ipdFormData.ipdCase}
                onChange={(e) => setIpdFormData({ ...ipdFormData, ipdCase: e.target.value })}
                placeholder="Select Case"
                SelectProps={{
                  MenuProps: {
                    className: styles.menu
                  }
                }}
                variant="outlined"
                size="small"
                required
                margin="dense"
                InputLabelProps={{ shrink: true }}
              >
                {Object.values(IpdTypes).map((option) => (
                  <MenuItem style={{ height: "50px" }} key={option} value={option} id={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                data-testmation="expectedLengthofStay"
                id="ExpectedLengthofStay"
                label={tl("expectedLengthOfStay")}
                placeholder={t("enterDays")}
                variant="outlined"
                size="small"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={ipdFormData.expectedLengthOfStay}
                onChange={(e) =>
                  setIpdFormData({ ...ipdFormData, expectedLengthOfStay: Number(e.target.value) })
                }
              />
            </Box>
          </Box>

          <Box style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <TextField
              data-testmation="provisionalDiagonosis"
              style={{ width: "515px" }}
              id="Provisional Diagnosis"
              label={tl("provisionalDiagnosis")}
              variant="outlined"
              placeholder={t("provisionalDiagnosis")}
              multiline
              minRows={4}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setIpdFormData({ ...ipdFormData, provisionalDiagnosis: e.target.value })
              }
              value={ipdFormData.provisionalDiagnosis}
            />
          </Box>
          <Box style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <TextField
              data-testmation="conditionAtAdmission"
              style={{ width: "515px" }}
              id="Admission Condition"
              label={tl("conditionatAdmission")}
              variant="outlined"
              placeholder={t("conditionatAdmission")}
              multiline
              minRows={4}
              value={ipdFormData.admissionCondition}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setIpdFormData({ ...ipdFormData, admissionCondition: e.target.value })
              }
            />
          </Box>
          <Box style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <TextField
              data-testmation="hopi"
              style={{ width: "515px" }}
              id="History of Past/Present Illness"
              label="HOPI"
              variant="outlined"
              placeholder="History of Past/Present Illness"
              multiline
              minRows={4}
              value={ipdFormData.hopi}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setIpdFormData({ ...ipdFormData, hopi: e.target.value })}
            />
          </Box>

          {mode === "create" && (
            <ReferrerCreateSelect
              onReferrerChange={(value) => {
                if (value) {
                  setIpdFormData({ ...ipdFormData, referredBy: value?.referrer });
                }
              }}
            />
          )}
        </Box>
      </form>
    </div>
  );
}

IpdAdmissionForm.defaultProps = {
  isFromDashboard: false
};

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { connect } from "react-redux";
import { tl } from "../../../components/translate";
import styles from "../Lab.module.css";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { allowRecordEdit } from "../../../reducers/labTest";
import { PermissionGroups } from "../../../interfaces/User";
import Can from "../../Policy/Can";
import { RootState } from "../../../store";
import { LabStatuses } from "../../../interfaces/Lab";

interface LabStickyFooterInterface {
  status: LabStatuses;
  onClose: () => void;
  onSaveAsDraft: () => void;
  onRequestApproval: () => void;
  onFinalize: () => void;
  positionControlByParent?: boolean;
  skipButton?: React.ReactNode;
  resultDate?: string;
  permissionGroup: string;
}

const LabStickyFooter: React.FC<LabStickyFooterInterface> = ({
  status,
  onClose,
  onSaveAsDraft,
  onRequestApproval,
  onFinalize,
  positionControlByParent,
  skipButton,
  resultDate,
  permissionGroup
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Box
        className={positionControlByParent ? {} : styles.labStickyFooter}
        flexBasis="400px"
        flexGrow={skipButton ? 0.5 : null}
      >
        <Box className={styles.labFooterButtonContainer}>
          <Button onClick={onClose} data-tesmation="close">
            <Typography>{tl("lab.close")}</Typography>
          </Button>
          {skipButton}
          <Button
            disabled={
              status === LabStatuses.RESULT_READY || status === LabStatuses.PENDING_APPROVAL
            }
            onClick={async () => {
              await onSaveAsDraft();
            }}
            data-testmation="saveAsDraft"
          >
            <Typography>{tl("lab.saveDraft")}</Typography>
          </Button>

          <Button
            disabled={
              status === LabStatuses.RESULT_READY || status === LabStatuses.PENDING_APPROVAL
            }
            onClick={() => {
              onRequestApproval();
            }}
            data-testmation="requestApproval"
          >
            <Typography>{tl("lab.requestApproval")}</Typography>
          </Button>
          <Can policyAccessKey="lab:finalize">
            <Button
              variant="contained"
              color="primary"
              disabled={
                !allowRecordEdit({ status, resultDate }) ||
                permissionGroup === PermissionGroups.ResourceCentreLabTechnicianLimited
              }
              onClick={() => {
                setOpen(true);
              }}
              data-testmation="finalize"
            >
              <Typography>{tl("lab.finalize")}</Typography>
            </Button>
          </Can>
        </Box>
      </Box>
      <OkhatiDialog
        title="Confirm Lab Report"
        description="Are you sure
         you want  to finalize the lab report? This cannot be edited again!"
        next={() => {
          onFinalize();
          setOpen(false);
        }}
        cancel={() => {
          setOpen(false);
        }}
        open={open}
        readMode={false}
      />
    </>
  );
};

LabStickyFooter.defaultProps = {
  positionControlByParent: false,
  skipButton: undefined,
  resultDate: undefined
};

export default connect(
  ({ userContext }: RootState) => ({
    permissionGroup:
      userContext && userContext.userCreds.userGroups && userContext.userCreds.userGroups[0]
  }),
  null
)(LabStickyFooter);

import { ReducerBuilder } from './ReducerBuilder';
import { NotificationActions } from '../interfaces/ActionTypes';
import { NotificationType } from '../containers/FlashMessage/Notification';

const INITIAL_STATE = [];
type StateType = NotificationType[];

function _notificationAdd(state: StateType, payload: NotificationType): StateType {
  return state.concat(payload);
}

function _notificationRemove(state: StateType, payload: string): StateType {
  return state.filter((n) => n.id !== payload);
}

const reducer = ReducerBuilder.create<StateType, NotificationType | string>(INITIAL_STATE)
  .mapAction(NotificationActions.NOTIFICATION_ADD, _notificationAdd)
  .mapAction(NotificationActions.NOTIFICATION_REMOVE, _notificationRemove)
  .build();

export default reducer;

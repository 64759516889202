import React from "react";
import { useSelector } from "react-redux";
import { TestTypes } from "../interfaces/Lab";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";
import { RootState } from "../store";

const spKeys = ["authorizedSP1", "authorizedSP2", "authorizedSP3"];

const getSpIdsFromSettings = (settings = {}) => {
  if (!settings) return [];
  return Object.keys(settings)
    .filter((key) => spKeys.includes(key) && settings[key] !== null)
    .map((key) => settings[key]);
};

const useDeletedSps = (labType: TestTypes): ServiceProvider[] => {
  const serviceProviders = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );
  const resourceCentre = useSelector((state: RootState) => state.resources.resourceCentres[0]);
  const { pathologySettings, radiologySettings } = resourceCentre?.labSettings || {};

  const spIdsInPathology = getSpIdsFromSettings(pathologySettings);
  const spIdsInRadiology = getSpIdsFromSettings(radiologySettings);

  const memoizedDeletedSps = React.useMemo(() => {
    const deletedSpsList = [] as ServiceProvider[];
    if (labType === TestTypes.PATHOLOGY && spIdsInPathology.length) {
      spIdsInPathology.forEach((spId) => {
        const serviceProvider = serviceProviders.find((sp) => sp.id === spId);
        if (serviceProvider && !serviceProvider.active) {
          deletedSpsList.push(serviceProvider);
        }
      });
    }

    if (labType === TestTypes.RADIOLOGY && spIdsInRadiology.length) {
      spIdsInRadiology.forEach((spId) => {
        const serviceProvider = serviceProviders.find((sp) => sp.id === spId);
        if (serviceProvider && !serviceProvider.active) {
          deletedSpsList.push(serviceProvider);
        }
      });
    }
    return deletedSpsList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathologySettings, radiologySettings, serviceProviders, labType]);

  return memoizedDeletedSps as ServiceProvider[];
};

export default useDeletedSps;

import React from "react";
import ServiceCenterSumReport from "../Reports/SummaryReport/ServiceCenterSummaryReport";

const SummaryReport = ({ resourceCentreId }: { resourceCentreId: number }): JSX.Element => {
  if (!resourceCentreId) return null;

  return (
    <div>
      <ServiceCenterSumReport resourceCentreId={resourceCentreId} />
    </div>
  );
};

export default SummaryReport;

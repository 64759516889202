import { push } from "connected-react-router";
import { match as MatchProps } from "react-router";
import { connect, useDispatch } from "react-redux";
import React, { Dispatch, useEffect, useState } from "react";
import { Box, TextField, Button, Autocomplete } from "@mui/material";

import Panel from "../../../components/Panel";
import { t, tl } from "../../../components/translate";
import { IThunkDispatch, RootState } from "../../../store";
import ClientSearch from "../../../components/ClientSearch";
import { Client } from "../../../interfaces/ClientInterface";
import { Supplier } from "../../../interfaces/StockInterfaces";
import { getServices, supplierActions } from "../../../actions";
import { notificationAdd } from "../../../actions/notification";
import { clientSearchSecondaryLabel } from "../../../models/Client";
import { suppliersSortedSelector } from "../../../reducers/suppliers";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";
import { OPDFormMode, OPDRequestStatus } from "../../../enum/OpdRequestForm";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import ServiceProviderAutoSelect from "../../ServiceProvider/ServiceProviderAutoSelect";
import { getOpdRequestForm, patchOpdRequest, postOpdRequest } from "../../../api/requestForms";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";

interface OpdRequestFormProps {
  actions: {
    loadServices: () => void;
    loadSuppliers: () => void;
  };
  opdRequest?: any;
  services: ServiceInterface[];
  resourceCentreId: number;
  mode: string;
  match: MatchProps;
  onClose?: () => void;
  opdRequestData?: any;
  suppliers: Array<Supplier>;
  setOpdRequestData: Dispatch<React.SetStateAction<any>>;
}

const OpdRequestForm = (props: OpdRequestFormProps): JSX.Element => {
  const { services, actions, opdRequest, mode, match, suppliers, onClose, setOpdRequestData } =
    props;

  const dispatch = useDispatch();
  const opdRequestId = Number(match.params.id);
  const [client, setClient] = useState<Client | null>(null);
  const [service, setService] = useState<ServiceInterface | null>(null);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [requestData, setRequestData] = useState({
    serviceProviderId: null,
    clientId: null,
    serviceId: null,
    supplierId: null,
    sentDate: new Date().toISOString(),
    deliveryDate: null,
    quantity: 1,
    price: 0,
    requestStatus: OPDRequestStatus.ORDERED
  });

  const isFormFilled = () => {
    if (
      !client ||
      !service ||
      !supplier ||
      !requestData.requestStatus ||
      !requestData.sentDate ||
      requestData.price <= 0 ||
      requestData.quantity <= 0
    ) {
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!isFormFilled()) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Please fill all the required fields",
            autoTimeout: true
          })
        );
        setLoading(false);
        return;
      }

      const data = {
        clientId: Number(client.id),
        serviceId: Number(service.id),
        supplierId: Number(supplier.id),
        serviceProviderId: Number(requestData.serviceProviderId),
        sentDate: requestData.sentDate,
        deliveryDate: opdRequest?.deliveryDate || null,
        quantity: requestData.quantity,
        price: requestData.price,
        requestStatus: requestData.requestStatus,
        total: requestData.price * requestData.quantity || 0
      };

      if (mode === OPDFormMode.CREATE) {
        const res = await postOpdRequest(data);
        setOpdRequestData((prevData) => ({
          results: [res, ...prevData.results],
          total: prevData.total + 1
        }));
        setLoading(false);
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: "OPD Request created successfully",
            autoTimeout: true
          })
        );
      } else {
        const res = await patchOpdRequest(match.params.id, data);
        setOpdRequestData((prevData) => ({
          ...prevData,
          results: prevData.results.map((item) => (item.id === res?.id ? res : item))
        }));
        setLoading(false);
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "success",
            message: "OPD Request updated successfully",
            autoTimeout: true
          })
        );
      }

      dispatch(push("/assessment/labOrderForms"));
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.messsage || "Something went wrong.",
          autoTimeout: true
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchAndSetData = async () => {
      if (!isMounted) return;

      if (opdRequestId && opdRequest) {
        setClient(opdRequest.client);
        setService(opdRequest.requestedItems[0]);
        setSupplier(opdRequest.supplier);
        setRequestData({
          clientId: opdRequest.clientId,
          serviceId: opdRequest.requestedItems[0].serviceId,
          supplierId: opdRequest.supplierId,
          serviceProviderId: opdRequest.serviceProviderId,
          sentDate: opdRequest.sentDate,
          deliveryDate: opdRequest.deliveryDate,
          quantity: Number(opdRequest.requestedItems[0].quantity) || 1,
          price: Number(opdRequest.requestedItems[0].price) || 0,
          requestStatus: opdRequest.status
        });
      } else if (!opdRequest && opdRequestId) {
        try {
          const res = await getOpdRequestForm(opdRequestId);
          if (!isMounted) return;

          setClient(res?.client);
          setService(res?.requestedItems[0]);
          setSupplier(res?.supplier);
          setRequestData({
            clientId: res?.clientId,
            serviceId: res?.requestedItems[0]?.id,
            supplierId: res?.supplierId,
            serviceProviderId: res?.serviceProviderId,
            sentDate: res?.sentDate,
            deliveryDate: res?.deliveryDate,
            quantity: Number(res?.requestedItems[0].quantity) || 1,
            price: Number(res?.requestedItems[0].price) || 0,
            requestStatus: res?.status
          });
        } catch (err) {
          if (!isMounted) return;

          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err.message || "Failed to fetch request form",
              autoTimeout: true
            })
          );
        }
      }
    };

    fetchAndSetData();

    return () => {
      isMounted = false;
    };
  }, [opdRequest, opdRequestId, dispatch]);

  useEffect(() => {
    let isMounted = true;

    const fetchServices = async () => {
      if (isMounted) {
        await actions.loadServices();
        await actions.loadSuppliers();
      }
    };

    fetchServices();
    return () => {
      isMounted = false;
    };
  }, [actions]);

  const footer = (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginRight: "32px",
        justifyContent: "flex-end",
        gap: "10px"
      }}
    >
      <Button
        onClick={() => dispatch(push("/assessment/labOrderForms"))}
        data-testmation="cancelIpdRequestForm"
        style={{ height: "32px", width: "60px" }}
      >
        {tl("cancel")}
      </Button>
      <StatefulButton
        variant="contained"
        color="primary"
        disabled={loading || !isFormFilled()}
        onClick={handleSave}
        isLoading={loading}
        circularProgressProps={{ size: 16 }}
        data-testmation="createOpdRequest"
        style={{ height: "35px", width: "80px" }}
      >
        {mode === OPDFormMode.CREATE ? tl("save") : tl("update")}
      </StatefulButton>
    </Box>
  );

  return (
    <Panel
      title={mode === OPDFormMode.CREATE ? tl("opd.createOrderForm") : tl("opd.editOrderForm")}
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          dispatch(push("/assessment/labOrderForms"));
        }
      }}
      footer={footer}
    >
      <Box
        p={5}
        width="100%"
        height="calc(100vh - 125px)"
        style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}
      >
        <Box width="100%" display="flex" flexDirection="column" gap="15px">
          <ClientSearch
            data-testmation="clientSearch"
            autoFocus={!client}
            client={client}
            setClient={setClient}
            variant="outlined"
            margin="dense"
            label={tl("opd.requestForm.createOrAddClient")}
            placeholder={t("opd.requestForm.clientName")}
            style={{ width: "100%" }}
            isDisabled={loading}
            onChange={(value) => {
              setClient(value);
              setRequestData({ ...requestData, clientId: value?.id });
            }}
            secondaryText={[
              { type: clientSearchSecondaryLabel.CUSTOMER_NUMBER },
              {
                type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER
              }
            ]}
          />

          <ServiceProviderAutoSelect
            hideLabel={false}
            serviceProviderId={Number(requestData.serviceProviderId)}
            placeholder={t("opd.requestForm.serviceProviderName")}
            limitBy={[]}
            disabled={loading}
            isClearable={false}
            disableDefaultError
            onChange={(value) => {
              setRequestData({ ...requestData, serviceProviderId: value });
            }}
          />

          <Autocomplete
            fullWidth
            disabled={loading}
            options={services}
            getOptionLabel={(option) => option.name}
            value={service}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                margin="dense"
                variant="outlined"
                data-testmation="packageCreateDropDown"
                label={tl("opd.requestForm.selectService")}
                placeholder={t("opd.requestForm.serviceName")}
                InputLabelProps={{ shrink: true }}
                value=""
                sx={{ flexGrow: 1 }}
              />
            )}
            renderOption={(renderProps, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...renderProps} key={option.id}>
                {option.name}
              </li>
            )}
            onChange={(e, value) => {
              if (value) {
                setService(value);
                setRequestData({
                  ...requestData,
                  price: Number(value?.labCharge || 0),
                  serviceId: value?.id
                });
              }
            }}
          />

          <Autocomplete
            fullWidth
            options={suppliers}
            value={supplier}
            disabled={loading}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Supplier"
                placeholder="Supplier name"
                InputLabelProps={{ shrink: true }}
              />
            )}
            renderOption={(renderProps, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...renderProps} key={option.id}>
                {option.name}
              </li>
            )}
            onChange={(_, value) => {
              setSupplier(value);
              setRequestData({ ...requestData, supplierId: value?.id || null });
            }}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px"
            }}
          >
            <TextField
              fullWidth
              value={requestData.quantity}
              margin="dense"
              variant="outlined"
              label={tl("opd.requestForm.quantity")}
              placeholder={t("opd.requestForm.quantity")}
              type="number"
              onFocus={(e) => e.target.select()}
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setRequestData({ ...requestData, quantity: Number(e.target.value) });
              }}
            />

            <TextField
              fullWidth
              value={requestData.price}
              margin="dense"
              variant="outlined"
              label={tl("opd.requestForm.price")}
              placeholder={t("opd.requestForm.price")}
              type="number"
              onFocus={(e) => e.target.select()}
              disabled={loading}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setRequestData({ ...requestData, price: Number(e.target.value) });
              }}
            />

            <TextField
              fullWidth
              value={Number((requestData.price || 0) * requestData.quantity)}
              margin="dense"
              variant="outlined"
              label={tl("opd.requestForm.total")}
              placeholder={t("opd.requestForm.total")}
              type="text"
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box>
            <CalendarDropdown
              fullwidth
              TextFieldProps={{ variant: "outlined" }}
              label={t("opd.requestForm.sentDate")}
              placeholder={t("opd.requestForm.sentDatePlaceholder")}
              date={new Date(requestData.sentDate)}
              onChange={(val) => {
                setRequestData({ ...requestData, sentDate: val.toISOString() });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Panel>
  );
};

export default connect(
  (state: RootState) => {
    const services = state.services.collection;
    const { resourceCentreId } = state?.userContext;
    const suppliers = suppliersSortedSelector(state);
    return { services, resourceCentreId, suppliers };
  },
  (dispatch: IThunkDispatch) => ({
    actions: {
      loadServices: () => dispatch(getServices()),
      loadSuppliers: () => dispatch(supplierActions.getSuppliers())
    }
  })
)(OpdRequestForm);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from "redux";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/bookableResources";

export enum Type {
  GET_SERVICETAGS = "GET_SERVICETAGS",
}

export const getServiceTags: any =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    await dispatch(
      actionCreatorAsync(Type.GET_SERVICETAGS, async () => api.getServicetags())
    );
  };

import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import List from "../../../components/List";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { getSsfClaims } from "../../../api/ssf";
import { clientFullNameSelector } from "../../../reducers/client";
import ClaimDetails from "./ClaimDetails";
import ClaimForm from "./ClaimForm";
import { notificationAdd } from "../../../actions/notification";
import { useAppDispatch } from "../../../store/hooks";
import { commonErrorMessage } from "../../../helpers/messages";
import { Claim } from "../../../interfaces/ssf";
import BillShow from "../BillShow/BillShow";

const ClaimsList = (): JSX.Element => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<Claim | null>(null);
  const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null);
  const [selectedBillId, setSelectedBillId] = useState<number | null>(null);
  const [openClaimForm, setOpenClaimForm] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getSsfClaims();
        setClaims(response);
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: e.message || commonErrorMessage,
            autoTimeout: true
          })
        );
      }
    })();
  }, []);
  return (
    <Box pt={3} height="100%" minWidth={900}>
      <List<Claim>
        withoutSearch
        data={claims}
        rowHeight={50}
        hideCreateButton
        onRowClick={(row) => {
          setOpenClaimForm(false);
          if (row.id === selectedClaimId) {
            setSelectedClaimId(null);
          } else {
            setSelectedClaimId(row.id);
          }
        }}
        columns={[
          {
            key: "createdDate",
            label: "Created Date",
            formatter: (row) => (
              <Typography>{convertADtoBS(new Date(row.created_at)).formatted4}</Typography>
            ),
            sortable: true
          },
          {
            key: "clientName",
            label: "Client Name",
            formatter: (row) => <Typography>{clientFullNameSelector(row.client)}</Typography>
          },
          {
            key: "claimCode",
            label: "Claim Code",
            formatter: (row) => <Typography>{row.claimCode}</Typography>
          },
          {
            key: "status",
            label: "Status",
            formatter: (row) => <Typography>{row.status}</Typography>
          }
        ]}
        automation="claimList"
      />
      {selectedClaimId && (
        <ClaimDetails
          onClose={() => setSelectedClaimId(null)}
          id={selectedClaimId}
          onBillClick={(id) => setSelectedBillId(id)}
          setClaims={setClaims}
          onClaimCreate={(data) => {
            setSelectedClaimId(null);
            setSelectedClaim(data);
            setOpenClaimForm(true);
          }}
        />
      )}
      {openClaimForm && selectedClaim && (
        <ClaimForm
          onClose={() => {
            setSelectedClaim(null);
            setOpenClaimForm(false);
          }}
          claim={selectedClaim}
        />
      )}
      {selectedBillId && (
        <BillShow
          billId={selectedBillId}
          handleViewClose={() => setSelectedBillId(null)}
          hideBillingActions
        />
      )}
    </Box>
  );
};

export default ClaimsList;

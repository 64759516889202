import styles from './styles.module.css';
import * as React from 'react';
import * as calendarData from '../functions/calendarData';
import * as calFns from '../functions/calendarFunctions';
import { t } from '../../translate';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Typography } from '@mui/material';

enum Switch {
  month,
  year
}

export interface YearMonthSwitchProps {
  onSwitch: (year: number, month: number) => void;
  defaultYear: number;
  defaultMonth: number;
}

export interface YearMonthSwitchState {
  currentYear: number;
  currentMonth: number;
  activeSwitch: Switch;
}

const YearMonthSwitch: React.FC<YearMonthSwitchProps> = ({
  onSwitch,
  defaultYear,
  defaultMonth
}) => {
  const [state, setState] = React.useState({
    currentYear: defaultYear,
    currentMonth: defaultMonth,
    activeSwitch: Switch.month
  });

  React.useEffect(() => {
    setState({ ...state, currentYear: defaultYear, currentMonth: defaultMonth });
  }, [defaultMonth, defaultYear]);

  const setCurrent = (currentYear = state.currentYear, currentMonth = state.currentMonth) => {
    setState({ ...state, currentMonth, currentYear });
    onSwitch(currentYear, currentMonth);
  };

  const goBack = () => {
    if (state.activeSwitch === Switch.year && calendarData.minBsYear < state.currentYear) {
      setCurrent(state.currentYear - 1);
    }
    if (state.activeSwitch === Switch.month) {
      let currentMonth = state.currentMonth - 1;
      let currentYear = state.currentYear;
      if (currentMonth == 0) {
        currentMonth = 12;
        currentYear = state.currentYear - 1;
      }
      if (calendarData.minBsYear <= currentYear) {
        setCurrent(currentYear, currentMonth);
      }
    }
  };

  const goForward = () => {
    if (state.activeSwitch === Switch.year && calendarData.maxBsYear > state.currentYear) {
      setCurrent(state.currentYear + 1);
    }
    if (state.activeSwitch === Switch.month) {
      let currentMonth = state.currentMonth + 1;
      let currentYear = state.currentYear;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear = state.currentYear + 1;
      }
      if (calendarData.maxBsYear >= currentYear) {
        setCurrent(currentYear, currentMonth);
      }
    }
  };

  return (
    <div
      className={`r-n-cal-switch ${styles.switch}`}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div className={`r-n-cal-backBtn ${styles.btns} ${styles.backBtn}`} onClick={() => goBack()}>
        <ChevronLeftIcon />
      </div>
      <Typography
        className={`r-n-cal-yearBtn  ${
          state.activeSwitch == Switch.year ? styles.activeSwitch : ''
        }`}
        onClick={() => setState({ ...state, activeSwitch: Switch.year })}
        style={{ fontWeight: 700 }}
      >
        {calFns.toDevanagariDigits(state.currentYear)}
      </Typography>
      <Typography
        className={`r-n-cal-monthBtn   ${
          state.activeSwitch == Switch.month ? styles.activeSwitch : ''
        }`}
        onClick={() => setState({ ...state, activeSwitch: Switch.month })}
        style={{ fontWeight: 700 }}
      >
        {calendarData.bsMonths(t)[state.currentMonth - 1]}
      </Typography>
      <div
        className={`r-n-cal-forwardBtn ${styles.btns} ${styles.forwardBtn}`}
        onClick={() => goForward()}
        style={{ textAlign: 'right' }}
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
};

export default YearMonthSwitch;

import React from "react";
import { Typography, Box } from "@mui/material";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import "../PrintBill.scss";
import { abs } from "mathjs";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { tl } from "../../../../components/translate";
import capitalizedName from "../../../../helpers/nameCapitalizer";
import {
  DocumentTypes,
  BillDocumentType,
  BillPaymentInfoType,
  BillSummaryType
} from "../../../../interfaces/BillInterfaces";
import { dateWithZeroBeforeSingleDigits } from "../../../../helpers/formatters";

import usePrintStyles from "../../../../hooks/usePrintStyles";
import { getSectionStyle, scaleFont } from "../../../Lab/LabPrint/LabPrintFunctions";
import LabPrintLetterHead, {
  ResourceCentre
} from "../../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import { BillClientInfo, InfoField } from "../BillPrintHelpers";
import LabPrintLetterHeadCentered from "../../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import logo from "../../../../../assets/images/poweredByOkhati.png";

import PaymentDescriptionV2 from "./PaymentDescriptionV2";
import { getClientBalance } from "../../../../api/bill";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { Client, Customer } from "../../../../interfaces/ClientInterface";
import PrintClientBillDescription from "../../../Client/ClientInfo/PrintClientBillDescription";
import { RootState } from "../../../../store";
import { getCustomerNumber } from "../../../Client/ClientList";

interface PrintedByInterface {
  name: string;
  signature: string;
  userId: number;
}

interface BillDataInterface {
  billNumber: string;
  client: Client;
  clientId: number;
  // eslint-disable-next-line camelcase
  created_at: string | Date;
  customerNumber: string | null;
  insuranceNumber: string;
  claimNumber: string;
  customer: Customer;
  document: BillDocumentType;
  dueDate: string | Date;
  id: number;
  isDraft: boolean;
  isInternal: boolean;
  issueDate: string | Date;
  paymentInfo: BillPaymentInfoType;
  printCount: number;
  printedBy: PrintedByInterface;
  summary: BillSummaryType;
}

interface PrintPaymentHistoryProps {
  billData: BillDataInterface & { type: DocumentTypes; related: { billNumber: number } };
  buttonText?: string | JSX.Element;
  resourceCentre: ResourceCentre;
  customerNumber: string;
  receivedOn: string;
  paidAmount: string;
  paymentMethod: string;
  remarks: string | null;
  mode: string;
  client?: Client;
  clientBills?: any;
  clientBalance?: number;
}

const PrintPaymentHistory: React.FC<PrintPaymentHistoryProps> = ({
  buttonText,
  billData,
  resourceCentre,
  receivedOn,
  paidAmount,
  paymentMethod,
  remarks,
  customerNumber,
  mode,
  client,
  clientBills,
  clientBalance
}) => {
  const [balance, setBalance] = React.useState(0);
  const printRef = React.useRef();

  React.useEffect(() => {
    if (mode === "recordPayment") {
      getClientBalance(billData.clientId).then(({ balance: balanceAmount }) => {
        setBalance(balanceAmount || 0);
      });
    } else {
      setBalance(clientBalance);
    }
  }, [clientBalance, mode]);

  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();

  if (mode === "recordPayment") {
    if (!billData || billData.isDraft || !billData.document) {
      return null;
    }
  }

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;
  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const centralizedHeader: boolean = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showFooter = resourceCentre?.settings?.printSettings.billPrintSettings?.includeFooter;
  const showReferrer = resourceCentre?.settings?.printSettings.includeReferrer;
  const template: string = resourceCentre?.settings?.printSettings.labPrintSettings.template;
  const isBordered = template === "borderedTemplate";
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    null;
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const hideRcLogo = Boolean(customTitle);

  let letterHeadComponent = <Box />;
  if (showLetterhead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <LabPrintLetterHeadCentered
          resourceCentre={{
            ...resourceCentre,
            panNo,
            name: customTitle || resourceCentre.name
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    } else {
      letterHeadComponent = (
        <LabPrintLetterHead
          resourceCentre={{
            ...resourceCentre,
            panNo,
            name: customTitle || resourceCentre.name
          }}
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
        />
      );
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <ReactToPrint
        trigger={() => (
          <Typography
            component="span"
            style={{
              width: "100%",
              height: "80%"
            }}
          >
            {buttonText || "Print"}
          </Typography>
        )}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printIframe">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterhead && (
                <tr>
                  <td>
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterhead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="0.8cm"
                      width="100%"
                      justifyContent="center"
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <Box
                          fontSize="0.45cm"
                          marginTop="0.5cm"
                          marginBottom="0.3cm"
                          fontWeight={400}
                          style={{ textTransform: "uppercase" }}
                        >
                          {mode === "clientBills"
                            ? tl("billing.transaction")
                            : tl("billing.receipt")}
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      display="flex"
                      marginBottom="1cm"
                      justifyContent="space-between"
                      borderTop="1px solid black"
                      borderRight={isBordered && "1px solid black"}
                      borderBottom={isBordered && "1px solid black"}
                      borderLeft={isBordered && "1px solid black"}
                      p="0.3cm"
                    >
                      <Box component="div" width="50%">
                        <BillClientInfo
                          client={billData ? billData.client : client}
                          customerNumber={getCustomerNumber(
                            mode === "recordPayment" ? customerNumber : client.customerNumber
                          )}
                        />
                      </Box>
                      <Box component="div" width="40%">
                        {mode === "recordPayment" && (
                          <InfoField
                            label={
                              <>
                                {billData.type === DocumentTypes.CREDITNOTE
                                  ? tl("billing.creditNoteNumber")
                                  : tl("billnumber")}
                              </>
                            }
                            data={<>: {billData.billNumber}</>}
                          />
                        )}
                        {mode === "recordPayment" && billData.type === DocumentTypes.CREDITNOTE && (
                          <InfoField
                            label={<>{tl("billing.creditNoteForBill")}</>}
                            data={<>:{billData.related?.billNumber}</>}
                          />
                        )}
                        {mode === "recordPayment" && (
                          <InfoField
                            label={<> {tl("billing.issueDate")}</>}
                            data={
                              <>
                                :{" "}
                                {dateWithZeroBeforeSingleDigits(
                                  convertADtoBS(moment(billData.issueDate)).formatted
                                )}{" "}
                                {moment(billData.issueDate).format("h:mm a")}
                              </>
                            }
                          />
                        )}
                        {mode === "recordPayment" && (
                          <InfoField
                            label={<>{tl("billing.dateAD")}</>}
                            data={
                              <>
                                :{" "}
                                {dateWithZeroBeforeSingleDigits(
                                  moment(billData.issueDate).format("YYYY/MM/DD")
                                )}{" "}
                              </>
                            }
                          />
                        )}
                        {mode === "recordPayment" && billData.document.referredBy && (
                          <InfoField
                            label={<>{tl("booking.referredBy")}</>}
                            data={<>: {billData.document.referredBy.toUpperCase()}</>}
                          />
                        )}
                        {mode === "recordPayment" && billData.document.insuranceNumber && (
                          <InfoField
                            label={<>{tl("billing.insuranceNumber")}</>}
                            data={<>: {billData.document.insuranceNumber}</>}
                          />
                        )}
                        {mode === "recordPayment" && billData.document.claimNumber && (
                          <InfoField
                            label={<>{tl("billing.claimNumber")}</>}
                            data={<>: {billData.document.claimNumber}</>}
                          />
                        )}
                      </Box>
                    </Box>
                    {mode === "recordPayment" && (
                      <PaymentDescriptionV2
                        relatedSettings={{ showReferrer, isBordered, headerColor }}
                        receivedOn={receivedOn}
                        paidAmount={Number(paidAmount)}
                        paymentMethod={paymentMethod}
                        remarks={remarks}
                      />
                    )}
                    {mode === "clientBills" && (
                      <PrintClientBillDescription
                        relatedSettings={{ showReferrer, isBordered, headerColor }}
                        clientBills={clientBills}
                      />
                    )}
                    {mode === "clientLedger" && <div>Hello world</div>}

                    <Box
                      component="div"
                      display="flex"
                      marginBottom="1cm"
                      marginTop="1cm"
                      justifyContent="space-between"
                      borderTop="1px solid black"
                      borderRight={isBordered && "1px solid black"}
                      borderBottom={isBordered && "1px solid black"}
                      borderLeft={isBordered && "1px solid black"}
                      p="0.3cm"
                    >
                      <Box component="div" width="100%">
                        <Typography align="left">
                          <Box component="span" fontSize={scaleFont("0.3cm")} fontWeight={600}>
                            {balance > 0 ? tl("balance.advance") : tl("balance.due")} :{" "}
                            {rupeeDisplay(abs(balance))}
                          </Box>
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="row"
                      mt="32px"
                      width="100%"
                      justifyContent={mode === "recordPayment" ? "space-between" : "flex-end"}
                      alignItems="flex-end"
                      className="blockContent"
                    >
                      {mode === "recordPayment" && (
                        <Box component="div" width="60%">
                          <Box width="120px" component="div">
                            <Typography align="left">
                              <Box
                                component="span"
                                fontSize={scaleFont("0.3cm")}
                                fontWeight={600}
                                style={{ textTransform: "uppercase" }}
                              >
                                Authorized by
                              </Box>
                            </Typography>
                          </Box>
                          <Box component="div">
                            {billData.document.enteredBy.signature && (
                              <img
                                src={billData.document.enteredBy.signature}
                                alt="Service Provider Signature"
                                width="180px"
                                style={{ objectFit: "contain" }}
                              />
                            )}
                            <Box component="div">
                              <Typography>
                                <Box
                                  fontWeight="400"
                                  fontSize={scaleFont("0.33cm")}
                                  component="span"
                                >
                                  {capitalizedName(billData.document.enteredBy.name)}
                                </Box>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      <Box component="div" width="35%">
                        <InfoField
                          label={<>{tl("billing.printedDateTime")}</>}
                          data={
                            <>
                              {moment().format("YYYY/MM/DD")} {moment().format("h:mm a")}
                            </>
                          }
                          labelStyle={{ fontSize: scaleFont("0.3cm") }}
                          valueStyle={{ fontSize: scaleFont("0.3cm") }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showFooter ? (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <Box>
                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          ) : (
            <Box width="100%" className="billFooterPos">
              <Box style={{ paddingRight: "10mm" }} mt={2} display="flex" justifyContent="flex-end">
                <img src={logo} alt="logo" height="35px" />
              </Box>
              <Box height="2cm" />
            </Box>
          )}
        </div>
      </iframe>
    </div>
  );
};

PrintPaymentHistory.defaultProps = {
  buttonText: "Print",
  client: undefined,
  clientBills: undefined,
  clientBalance: undefined
};

const mapStateToProps = (state: RootState, { billData }) => {
  const { resourceCentres } = state.resources;
  const rcId = state.userContext.resourceCentreId;

  const getResourceCentre = (id, rcs) => rcs.find((rc) => Number(rc.id) === Number(id));

  const customerNumber = state.clients.collection?.find(
    (item) => Number(billData?.clientId) === item.id
  )?.customerNumber;

  return {
    SPs: state.resources.resourceCentreServiceProviders,
    user: state.userContext.user,
    resourceCentre: getResourceCentre(rcId, resourceCentres) || state.userContext.resourceCentre,
    billData,
    customerNumber
  };
};

export default connect(mapStateToProps, {})(PrintPaymentHistory);

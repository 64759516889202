import React from "react";

export const DrySyrup = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 297 297"
    >
      <g>
        <path
          d="M252.375,105.21c11.96,0,21.689-9.729,21.689-21.689c0-9.553-12.193-27.947-15.93-33.357
		c-1.308-1.892-3.46-3.021-5.76-3.021s-4.452,1.13-5.76,3.021c-3.736,5.41-15.93,23.805-15.93,33.357
		C230.686,95.481,240.415,105.21,252.375,105.21z M252.377,67.081c4.438,7.416,7.659,14.14,7.688,16.439
		c0,4.24-3.449,7.689-7.689,7.689c-4.239,0-7.688-3.448-7.689-7.688C244.714,81.227,247.937,74.501,252.377,67.081z"
        />
        <path
          d="M220.373,56.421c10.224,0,18.542-8.318,18.542-18.542c0-7.723-8.939-21.498-12.782-27.062
		c-1.308-1.892-3.46-3.021-5.76-3.021s-4.452,1.13-5.76,3.021c-3.843,5.563-12.782,19.339-12.782,27.062
		C201.831,48.103,210.149,56.421,220.373,56.421z M220.374,27.935c2.595,4.54,4.417,8.435,4.541,9.944
		c0,2.505-2.037,4.542-4.542,4.542c-2.488,0-4.517-2.012-4.542-4.494C215.975,36.354,217.793,32.458,220.374,27.935z"
        />
        <path
          d="M128.564,175.15h-8.214v-8.214c0-3.866-3.134-7-7-7s-7,3.134-7,7v8.214h-8.214c-3.866,0-7,3.134-7,7s3.134,7,7,7h8.214
		v8.214c0,3.866,3.134,7,7,7s7-3.134,7-7v-8.214h8.214c3.866,0,7-3.134,7-7S132.431,175.15,128.564,175.15z"
        />
        <path
          d="M294.612,116.907c-1.899-2.23-4.683-3.516-7.612-3.516H184.089c-3.497-8.203-7.963-15.998-13.33-23.154l-19.808-26.411
		c4.335-1.094,7.549-5.01,7.549-9.685V19.517c0-5.522-4.478-10-10-10H79.25c-5.522,0-10,4.478-10,10v34.625
		c0,4.675,3.214,8.59,7.549,9.685L56.991,90.237c-5.367,7.156-9.833,14.951-13.33,23.154H10c-5.522,0-10,4.478-10,10
		c0,5.522,4.478,10,10,10h27.27c-1.732,7.849-2.645,15.882-2.645,23.943v97.387c0,19.014,15.469,34.482,34.482,34.482h89.535
		c19.014,0,34.482-15.469,34.482-34.482v-97.387c0-6.765-0.651-13.51-1.877-20.145c9.161,3.925,17.692,8.547,25.467,12.777
		c13.287,7.229,24.763,13.472,35.306,13.847c0.613,0.021,1.217,0.032,1.815,0.032c29.029,0,40.842-25.243,43.037-38.864
		C297.339,122.09,296.513,119.138,294.612,116.907z M54.625,150.704h118.5c0.162,2.205,0,66.563,0,66.563h-118.5
		C54.625,217.267,54.463,152.909,54.625,150.704z M89.25,29.517h49.25v14.625H89.25V29.517z M101.563,64.142h24.625l28.571,38.096
		c6.426,8.568,11.27,18.252,14.371,28.467H58.62c3.101-10.215,7.945-19.899,14.371-28.467L101.563,64.142z M158.643,269.204H69.107
		c-7.985,0-14.482-6.497-14.482-14.482v-17.455h118.5v17.455C173.125,262.707,166.628,269.204,158.643,269.204z M252.729,143.826
		c-5.497-0.194-14.711-5.047-24.631-10.435h44.484C269.063,138.907,262.998,144.221,252.729,143.826z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./style.css";
import { connect, useSelector } from "react-redux";
import DrawingBoard from "../../Assessment/DrawingBoard";
import { updateUser } from "../../../actions/user";
import { tl } from "../../../components/translate";
import useMobileScreen from "../../../hooks/useMobileScreen";
import useFullScreen from "../../../hooks/useFullScreen";
import ServiceProviderSignatureUploader from "./ServiceProviderSignatureUploader";
import { IThunkDispatch, RootState } from "../../../store";
import HtmlTooltip from "../../../components/HtmlTooltip";
import { getFullName } from "../../../helpers/formatters";

interface Props {
  sign: string;
  onUserUpdate: (userObj) => void;
}

export function Signature({ sign, onUserUpdate }: Props): JSX.Element {
  const { signatureUploader } = useSelector((state: RootState) => state.userContext?.user);
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext?.userCreds?.userGroups[0]
  );
  const [boardData, setBoardData] = useState([]);
  const [open, setOpen] = useState(false);

  const [image, setImage] = useState("");

  useEffect(() => {
    setImage(sign);
  }, [sign]);

  const isMobileScreen = useMobileScreen();

  const [requestFullScreen, exitFullScreen] = useFullScreen();
  const canViewSignatureUploadBy = ["serviceProviderFull"].includes(permissionGroup);

  return (
    <>
      {image ? (
        <Box display="flex">
          <img
            width="227px"
            style={{
              objectFit: "contain",
              border: "2px solid gray",
              borderRadius: "10px"
            }}
            src={image}
            alt="signature"
          />
          <Box
            flexBasis="100px"
            display="flex"
            onClick={() => {
              setOpen(!open);
              if (isMobileScreen) {
                requestFullScreen();
              }
            }}
            pt={4}
            pl={2}
            style={{ cursor: "pointer" }}
          >
            {tl("account.edit")}
          </Box>
        </Box>
      ) : (
        <Typography>
          {" "}
          <Box
            onClick={() => {
              setOpen(!open);
            }}
            style={{ cursor: "pointer" }}
          >
            <u>Add Signature</u>
          </Box>
        </Typography>
      )}
      <Dialog open={open} classes={{ paper: "slate" }}>
        <DrawingBoard
          closeModal={() => {
            setOpen(false);
            exitFullScreen();
          }}
          title={tl("signature.signature")}
          handleImageSave={(imageData, boardDataParams) => {
            setImage(imageData);
            setBoardData(boardDataParams);
            const postData: { signature: string; hasUpdatedSignature?: boolean } = {
              signature: imageData
            };
            postData.hasUpdatedSignature = true;
            onUserUpdate(postData);
          }}
          image={image}
          width={isMobileScreen ? 240 : 400}
          height={isMobileScreen ? 150 : 200}
          boardData={boardData}
          setBoardData={setBoardData}
          imageIndex={0}
        />
      </Dialog>

      <Box display="flex" alignItems="center" mt={2}>
        <Typography style={{ fontWeight: 600 }}>OR, Upload Signature</Typography>
        {canViewSignatureUploadBy && signatureUploader && (
          <HtmlTooltip description={`Signature Upload by: ${getFullName(signatureUploader)}`} />
        )}
      </Box>

      <ServiceProviderSignatureUploader
        image={image}
        onSave={(base64Image) => {
          const postData: { signature: string; hasUpdatedSignature?: boolean } = {
            signature: base64Image
          };
          postData.hasUpdatedSignature = true;
          onUserUpdate(postData);
        }}
      />
    </>
  );
}

export default connect(
  (state: RootState) => ({
    sign: state.userContext.user.signature
  }),
  (dispatch: IThunkDispatch) => ({
    onUserUpdate: async (userObj) => {
      await dispatch(updateUser(userObj));
    }
  })
)(Signature);

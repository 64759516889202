import moment from "moment";
import { Post, Put, Get, Delete } from "./apiHelper";

const schedulesRoot = "/api/okhatiEvents";

export const getSchedules = async (
  serviceProviderId: number,
  start: Date,
  end: Date,
  resourceId?: number,
  publicEventChecked?: boolean
): Promise<unknown> => {
  const response = await Get(`/api/okhatiEventsWithSchedules/`, true, {
    start: moment(start).toISOString(),
    end: moment(end).toISOString(),
    video: true,
    onSite: true,
    serviceProviderId,
    resourceId,
    publicEventChecked
  });
  return response.data;
};

export const getSchedule = async (scheduleId: number): Promise<unknown> => {
  const response = await Get(`${schedulesRoot}/${scheduleId}`, true);
  return response.data;
};

export const postSchedule = async (data: Record<string, unknown>): Promise<unknown> => {
  try {
    const response = await Post(`${schedulesRoot}/`, data, true);
    return response.data;
  } catch (err) {
    return err;
  }
};
export const putSchedule = async (
  scheduleId: number,
  data: Record<string, unknown>
): Promise<unknown> => {
  const response = await Put(`${schedulesRoot}/${scheduleId}`, data, true);
  return response.data;
};

export const deleteSchedule = async (id: number): Promise<unknown> => {
  const response = await Delete(`${schedulesRoot}/?id=${id}`, true);
  return response.data;
};

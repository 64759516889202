import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Tooltip, Typography } from "@mui/material";
import { connect } from "react-redux";
import usePrintStyles from "../../../hooks/usePrintStyles";
import DefaultTemplate from "./Templates/DefaultTemplate";
import BorderedTemplate from "./Templates/BorderedTemplate";
import { LabObject, LabPrintSettings } from "../../../interfaces/LabInterfaces";
import { ResourceCentre } from "./LabprintComponents/LabPrintLetterhead";
import FixedFooterTemplate from "./Templates/FixedFooter";
import GovernmentTemplate from "./Templates/GovernmentTemplate";
import A5Template from "./Templates/A5/A5Template";
import BlockCategoryTemplate from "./Templates/BlockCategory/BlockCategoryTemplate";
import usePrintShortcut from "../../../hooks/usePrintShortcut";
import { PrintTemplate } from "../../../interfaces/BillInterfaces";

declare global {
  interface Window {
    Cypress: unknown;
  }
}
interface LabPrintProps {
  buttonText?: string | JSX.Element;
  labData: LabObject;
  resourceCentre: ResourceCentre;
  settings: LabPrintSettings;
  onPrintClick?: () => void;
  showDobOnLabPrint: boolean;
}

const LabPrint: React.FC<LabPrintProps> = ({
  buttonText,
  labData,
  resourceCentre,
  settings,
  onPrintClick,
  showDobOnLabPrint = false
}) => {
  const printRef = useRef();

  const printTemplate = settings.template;
  const reactToPrintRef = useRef(null);

  const getCustomPrintStyle = () => {
    if (printTemplate === PrintTemplate.A5) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      usePrintStyles({ pageStyle: "size: A5 landscape; margin: 0mm" }).applyPrintStyles();
    }
  };
  usePrintShortcut(reactToPrintRef, getCustomPrintStyle);

  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();

  return (
    <div style={{ width: "100%" }}>
      <ReactToPrint
        onAfterPrint={() => window.focus()}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          <Tooltip title="ALT + P / CTRL + P" arrow>
            <Typography
              data-testmation="labPrint"
              component="span"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                fontSize: "0.95em",
                textTransform: "none",
                padding: "5px 15px"
              }}
              ref={reactToPrintRef}
              onMouseOver={() => {
                getCustomPrintStyle();
              }}
            >
              {buttonText || "Print"}
            </Typography>
          </Tooltip>
        )}
        // print props here is not necessary at all
        // this is just done as a setup to make cypress testing possible
        print={(iframe) =>
          new Promise<void>((resolve) => {
            if (window.Cypress) {
              resolve();
              return;
            }
            iframe.contentWindow.print();
            resolve();
          })
        }
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printDisplay">
        <div ref={printRef}>
          {!printTemplate ||
            (printTemplate === PrintTemplate.Default && (
              <DefaultTemplate
                showAdditionalLabData={settings.additionallabdata}
                labData={labData}
                settings={settings}
                resourceCentre={resourceCentre}
                showDobOnLabPrint={showDobOnLabPrint}
              />
            ))}
          {printTemplate === PrintTemplate.Bordered && (
            <BorderedTemplate
              showAdditionalLabData={settings.additionallabdata}
              labData={labData}
              settings={settings}
              resourceCentre={resourceCentre}
              showDobOnLabPrint={showDobOnLabPrint}
            />
          )}
          {printTemplate === PrintTemplate.RepeatingFooter && (
            <FixedFooterTemplate
              showAdditionalLabData={settings.additionallabdata}
              labData={labData}
              settings={settings}
              resourceCentre={resourceCentre}
              showDobOnLabPrint={showDobOnLabPrint}
            />
          )}
          {printTemplate === PrintTemplate.Government && (
            <GovernmentTemplate
              showAdditionalLabData={settings.additionallabdata}
              labData={labData}
              settings={settings}
              resourceCentre={resourceCentre}
              showDobOnLabPrint={showDobOnLabPrint}
            />
          )}
          {printTemplate === PrintTemplate.A5 && (
            <A5Template
              showAdditionalLabData={settings.additionallabdata}
              labData={labData}
              settings={settings}
              resourceCentre={resourceCentre}
              showDobOnLabPrint={showDobOnLabPrint}
            />
          )}
          {printTemplate === PrintTemplate.BlockCategory && (
            <BlockCategoryTemplate
              showAdditionalLabData={settings.additionallabdata}
              labData={labData}
              settings={settings}
              resourceCentre={resourceCentre}
              showDobOnLabPrint={showDobOnLabPrint}
            />
          )}
        </div>
      </iframe>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const resourceCentre = () =>
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  const getLabPrintSettings = (): LabPrintSettings => {
    const rc = resourceCentre();
    const showLetterhead = Boolean(rc?.settings.printSettings.labPrintSettings?.includeLetterhead);
    const clinicNameFontScale: number = rc?.settings.printSettings.clinicNameFontScale || 1;
    const centralizedHeader = Boolean(rc?.settings.printSettings.centralizedHeader);
    const showFooter = Boolean(rc?.settings.printSettings.labPrintSettings?.includeFooter);
    const headerColor: string =
      (rc?.settings.printSettings.labPrintSettings.enableColor &&
        rc?.settings.printSettings.labPrintSettings.color) ||
      null;
    const dateSetting: string = rc?.settings.printSettings.labPrintSettings.dateFormat;
    const showSampleTakenTime = Boolean(
      rc?.settings.printSettings.labPrintSettings.showSampleTakenTime
    );
    const showReportOnTime = Boolean(rc?.settings.printSettings.labPrintSettings.showReportOnTime);
    const showPrintedOnTime = Boolean(
      rc?.settings.printSettings.labPrintSettings.showPrintedOnTime
    );
    const showSampleTakenDate = Boolean(
      rc?.settings.printSettings.labPrintSettings.showSampleTakenDate
    );
    const showReportOnDate = Boolean(rc?.settings.printSettings.labPrintSettings.showReportOnDate);
    const showPrintedOnDate = Boolean(
      rc?.settings.printSettings.labPrintSettings.showPrintedOnDate
    );
    const spaciousMargins = Boolean(
      rc?.settings.printSettings.labPrintSettings.enableSpaciousMargin
    );
    const template: string = rc?.settings.printSettings.labPrintSettings.template;
    const additionallabdata: boolean = rc?.labSettings?.additionallabdata;
    const showLabRemarks: boolean = rc?.labSettings?.showLabRemarks;
    const showMethods: boolean = rc?.labSettings?.showMethods;
    const showRanges: boolean = rc?.labSettings?.showRanges;
    const showUnit: boolean = rc?.labSettings?.showUnit;
    const showReading: boolean = rc?.labSettings?.showReading;
    const labRemarks: string = rc?.labSettings?.labRemarks;
    const customHeaderImage: string = rc?.labSettings?.customHeaderImage;
    const showPhoto: boolean = rc?.settings?.printSettings?.labPrintSettings?.showPhoto;
    const showCustomerIdentifier: boolean =
      rc?.settings?.printSettings?.labPrintSettings?.showCustomerIdentifier;
    const customTitle: string = rc?.settings?.printSettings?.labPrintSettings?.customTitle;
    const govTemplateHeaderText1: string =
      rc?.settings?.printSettings?.labPrintSettings?.govTemplateHeaderText1;
    const govTemplateHeaderText2: string =
      rc?.settings?.printSettings?.labPrintSettings?.govTemplateHeaderText2;
    const categoryOrder = rc?.labSettings?.categoryOrder;
    const hideFooterLogoCompletely = Boolean(
      rc?.settings.printSettings.labPrintSettings?.hideFooterLogoCompletely
    );
    const showQrWhenFooterIsHidden = Boolean(
      rc?.settings.printSettings.labPrintSettings?.showQrWhenFooterIsHidden
    );

    return {
      showLetterhead,
      showFooter,
      headerColor,
      dateSetting,
      showSampleTakenTime,
      showReportOnTime,
      showPrintedOnTime,
      showPrintedOnDate,
      showReportOnDate,
      showSampleTakenDate,
      spaciousMargins,
      template: ownProps.forceTemplate || template,
      centralizedHeader,
      additionallabdata,
      clinicNameFontScale,
      showLabRemarks,
      labRemarks,
      showMethods,
      showPhoto,
      showCustomerIdentifier,
      customTitle,
      govTemplateHeaderText1,
      govTemplateHeaderText2,
      categoryOrder,
      showRanges,
      showUnit,
      showReading,
      hideFooterLogoCompletely,
      showQrWhenFooterIsHidden,
      useCustomLabTestNumber: Boolean(rc?.labSettings?.useCustomLabTestNumber),
      showRangeFlag: Boolean(rc?.labSettings?.showRangeFlag),
      customHeaderImage
    };
  };
  return {
    resourceCentre: ownProps.resourceCentre || resourceCentre(),
    settings: ownProps.settings || getLabPrintSettings()
  };
};

LabPrint.defaultProps = {
  buttonText: "Print",
  onPrintClick: () => null
};

export default connect(mapStateToProps, null)(LabPrint);

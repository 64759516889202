import axios from "axios";
import { Client, ClientLedger, ClientVisit } from "../interfaces/ClientInterface";
import { Get, Post, Delete, Put } from "./apiHelper";

const clientRoot = "/api/clients";

const clientsRoot = "/api/clients";

export async function fetchClientByReferenceId(referenceId: number): Promise<Client> {
  try {
    const response = await axios.get(`${clientRoot}/reference/${referenceId}`);
    return response.data as Client;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return err;
  }
}

export async function batchUploadClient(data: Record<string, unknown>): Promise<unknown> {
  const response = await Post(`${clientsRoot}/batch`, { data }, true);
  return response.data;
}

export async function searchMuldartaClients(query: string): Promise<Client[]> {
  const response = await Get(`/api/searchMulDartaClient?q=${query}`, true);
  return response.data as Client[];
}

export async function getClientDetails(id: number): Promise<Client> {
  const response = await Get(`${clientsRoot}/${id}`, true);
  return response.data as Client;
}

export async function getBatchClient(query: Record<string, string>): Promise<unknown> {
  const response = await Get(`${clientsRoot}`, true, { ...query });
  return response.data;
}

export async function createClient(clientData: Partial<Client>): Promise<Client> {
  const response = await Post(`${clientsRoot}`, clientData, true);
  return response.data as Client;
}

export async function createBatchClient(clientsData: Record<string, unknown>): Promise<unknown> {
  return Post(`${clientsRoot}/batch`, clientsData, true);
}

export async function updateClient(id: number, clientData: Partial<Client>): Promise<Client> {
  const response = await Put(`${clientsRoot}/${id}`, clientData, true);
  return response.data as Client;
}

export async function updateClientCustomer(
  id: number,
  customerData: Record<string, unknown>
): Promise<unknown> {
  const response = await Put(`${clientsRoot}/${id}/customer`, customerData, true);
  return response.data;
}

export const getClientVisits = async (id: number): Promise<Array<ClientVisit>> => {
  const response = await Get(`${clientsRoot}/${id}/visits`, true);
  return response.data as Array<ClientVisit>;
};

export const deleteClient = async (id: number): Promise<unknown> => {
  const response = await Delete(`${clientsRoot}/${id}`, true);
  return response.data;
};

export const getS3UploadPostData = async (fileName: string): Promise<unknown> => {
  const response = await Post(`${clientsRoot}/profileImage`, { fileName });
  return response.data;
};

export const uploadClientPicture = async (id: number, pictureData: Blob): Promise<unknown> => {
  const fileName = `${id}.${pictureData.type.split("/")[1]}`;
  const postData = (await getS3UploadPostData(fileName)) as {
    id: number;
    data: Record<string, unknown>;
  };
  const s3FileName = `${postData.id}/${fileName}`;
  const formData = new FormData();
  [
    "key",
    "bucket",
    "acl",
    "X-Amz-Algorithm",
    "X-Amz-Credential",
    "X-Amz-Date",
    "Policy",
    "X-Amz-Signature"
  ].forEach((k) => {
    formData.append(k, postData.data.fields[k]);
  });
  formData.append("file", pictureData, s3FileName);
  const response = await axios.post(postData.data.url, formData, {
    headers: {
      "Content-Type": "application/octet-stream"
    }
  });
  await updateClientCustomer(id, {
    customerDetails: { profileImageS3Url: response.headers.location }
  });
  return { clientId: id, profileImageS3Url: response.headers.location };
};

export async function getClientLedger(clientId: number): Promise<ClientLedger[]> {
  const response = await Get(`/api/clientLedger/${clientId}`, true);
  return response.data as ClientLedger[];
}

export async function getWalkInClient(resourceCentreId: number): Promise<Client> {
  const response = await Get(`${clientRoot}/${resourceCentreId}/walkInCustomer`, true);
  return response.data as Client;
}

export async function getClientsWithPhone({ phone }: { phone: string }): Promise<Array<Client>> {
  const { data } = await Get(`${clientsRoot}/phone?phone=${phone || ""}`, true);
  return data as Array<Client>;
}

export async function getPublicClientDetail({ id }: { id: number }): Promise<Array<Client>> {
  const { data } = await Get(`${clientsRoot}/public/${id}`, true);
  return data as Array<Client>;
}

export async function createPublicClient({ clientId }: { clientId: number }): Promise<Client> {
  const { data } = await Post(`${clientsRoot}/public`, { clientId }, true);
  return data as Client;
}

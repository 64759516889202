/* eslint-disable camelcase */
import React from "react";
import { useDispatch } from "react-redux";
import { round } from "mathjs";
import { Box, Typography } from "@mui/material";
import { commonErrorMessage } from "../../helpers/messages";
import { notificationAdd } from "../../actions/notification";
import { getStockHistoryData } from "../../api/reports";
import Can from "../Policy/Can";
import List, { EmptyView } from "../../components/OList/List";
import { rupeeDisplay } from "../../helpers/rupee";
import { t, tl } from "../../components/translate";
import { ProductType } from "../Stock/StockCreateEdit/CreateStockProduct";
import Filters, { FilterProps } from "./Filters";
import { convertADtoBS, startOfDay } from "../../components/Calendar/functions/calendarFunctions";
import { ListActions, Menu, MenuItem } from "../../components/OList";
import useMobileScreen from "../../hooks/useMobileScreen";
import { downloadExcel } from "../../helpers/files";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";

const columns = () => [
  t("reports.stock.productName"),
  t("reports.batchId"),
  t("reports.avgPurchasePrice"),
  t("reports.availableStock"),
  t("reports.unit"),
  t("reports.package"),
  t("reports.date"),
  t("reports.totalStockValue")
];

const docRowProcessor = ({
  batchId,
  avgPurchasePrice,
  quantity,
  product,
  date,
  __meta__row_type
}) => {
  if (__meta__row_type === "segment_summary") return null;
  const { bsYear, bsMonth } = convertADtoBS(new Date(date));
  return [
    product.name,
    batchId,
    avgPurchasePrice,
    quantity,
    product.unit,
    product.package,
    `${bsYear}-${bsMonth}`,
    avgPurchasePrice * quantity
  ];
};

export default function StockHistoryReport(): JSX.Element {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState<FilterProps>({
    from: startOfDay(new Date()).toDate(),
    until: new Date(),
    productType: ProductType.Sellable
  });
  const [reportData, setReportData] = React.useState([]);

  React.useEffect(() => {
    const getStockHistory = async () => {
      try {
        const response = await getStockHistoryData(filters);
        setReportData(response);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: error?.data?.message || commonErrorMessage,
            variant: "error",
            autoTimeout: true
          })
        );
      }
    };

    getStockHistory();
  }, [filters]);
  const isMobileScreen = useMobileScreen();

  return (
    <Can policyAccessKey="report:stockAuditReport">
      <Box>
        <Box m="32px">
          <Filters filters={filters} onSetFilters={(filter) => setFilters(filter)} showMonthOnly />
        </Box>
        <Box
          sx={{
            overflowX: "auto",
            width: isMobileScreen ? "calc(100vw - 16px)" : "auto",
            mx: "auto"
          }}
        >
          <Box className="stockCashFlowReportList" width={isMobileScreen ? "1100px" : "auto"}>
            <List
              automation="stockCashFlowList"
              data={reportData}
              rowHeight={50}
              activeRow={1}
              adjustHeightToContents
              defaultSortColumn="productName"
              defaultSortOrder={-1}
              columns={[
                {
                  key: "productName",
                  label: tl("reports.stock.productName"),
                  segmentable: true,
                  segmentBy: ({ product }) => product.name,
                  sortable: true,
                  formatter: ({ product }) => <Typography>{product.name}</Typography>
                },
                {
                  key: "batchId",
                  label: tl("reports.batchId"),
                  sortable: true,
                  formatter: ({ batchId }) => <Typography>{batchId}</Typography>
                },
                {
                  key: "avgPurchasePrice",
                  label: tl("reports.avgPurchasePrice"),
                  sortable: true,
                  formatter: ({ avgPurchasePrice }) => (
                    <Typography>{rupeeDisplay(round(avgPurchasePrice || 0, 0))}</Typography>
                  )
                },
                {
                  key: "quantity",
                  label: tl("reports.quantity"),
                  sortable: true,
                  formatter: ({ quantity }) => <Typography>{quantity}</Typography>
                },
                {
                  key: "unit",
                  label: tl("reports.unit"),
                  sortable: true,
                  formatter: ({ product }) => <Typography>{product.unit}</Typography>
                },
                {
                  key: "package",
                  label: "Package",
                  formatter: ({ product }) => <Typography>{product.package}</Typography>
                },
                {
                  key: "date",
                  label: tl("resourceCentre.date"),
                  sortable: true,
                  formatter: ({ date }) => {
                    const { bsYear, bsMonth } = convertADtoBS(new Date(date));
                    return <Typography>{`${bsYear} - ${bsMonth}`}</Typography>;
                  }
                },
                {
                  key: "avgTotalPurchasePrice",
                  label: tl("reports.totalStockValue"),
                  sortable: true,
                  formatter: ({ avgPurchasePrice, quantity }) => (
                    <Typography>{Math.floor(avgPurchasePrice * quantity)}</Typography>
                  )
                }
              ]}
              segementSummaryRenderer={(acc) => (
                <Box style={{ background: "#e6e6e6" }} pr="30px" display="flex" flexGrow={1}>
                  <Box display="flex" flexGrow={1}>
                    <Typography>
                      <Box
                        component="span"
                        flexGrow={1}
                        display="flex"
                        padding="8px 32px 4px 20px"
                        fontWeight={500}
                      >
                        {acc.segment || "N/A"}
                      </Box>
                    </Typography>
                  </Box>
                  <Box flexBasis="200px" pt="4px" textAlign="right">
                    <Typography>
                      {rupeeDisplay(
                        Math.floor(
                          acc.rows.reduce(
                            (prev, cur) => prev + cur.avgPurchasePrice * cur.quantity,
                            0
                          ) || 0
                        )
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            "Stock History Report",
                            formatHeaderForExcel(columns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
            <Box className="totalBar">
              <Box display="flex" paddingLeft="20px" flexGrow={0.8}>
                {t("reports.totalProfitLoss")}
              </Box>
              <Box display="flex" flexBasis="230px" paddingRight="32px" justifyContent="flex-end">
                <Typography>sdfg</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Can>
  );
}

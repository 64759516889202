import { Get, Post, Delete, Put } from "./apiHelper";
import { ServiceInterface } from "../interfaces/ServiceInterface";

const servicesRoot = "/api/services";
const publicServiceRoot = "/api/publicServices";

export async function postBatchService(services: ServiceInterface): Promise<ServiceInterface> {
  const response = await Post(`${servicesRoot}`, services, true);
  return response.data as ServiceInterface;
}

export async function putBatchService(services: ServiceInterface): Promise<ServiceInterface> {
  const response = await Put(`${servicesRoot}/${services.id}`, services, true);
  return response.data as ServiceInterface;
}

export async function deleteBatchService(services: number): Promise<string> {
  const response = await Delete(`${servicesRoot}/${services}`, true);
  return response.data as string;
}

export async function getBatchService(): Promise<ServiceInterface[]> {
  const response = await Get(`${servicesRoot}`, true);
  return response.data as ServiceInterface[];
}
export async function getService(id: number): Promise<ServiceInterface> {
  const response = await Get(`${servicesRoot}/${id}`, true);
  return response.data as ServiceInterface;
}

export async function servicesSearch(data: string): Promise<ServiceInterface[]> {
  const response = await Get(`${servicesRoot}/search?q=${data}`, true);
  return response.data as ServiceInterface[];
}

export const getPublicServices = async (): Promise<ServiceInterface[]> => {
  const response = await Get(`${publicServiceRoot}`, true);
  return response.data as ServiceInterface[];
};

export const publicServiceDelete = async (id: number): Promise<string> => {
  const response = await Delete(`${publicServiceRoot}/${id}`, true);
  return response.data as string;
};

export const batchUploadService = async (data: Array<ServiceInterface>): Promise<string> => {
  const response = await Post(`${servicesRoot}/batch`, { services: data }, true);
  return response.data as string;
};

export async function updateServiceUnderPackage({
  data,
  packageId
}: {
  data: ServiceInterface;
  packageId: string;
}): Promise<ServiceInterface> {
  const response = await Put(`${servicesRoot}/serviceUnderPackage/${packageId}`, data, true);
  return response.data as ServiceInterface;
}

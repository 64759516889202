import { Alert, Box, Button, Grid } from "@mui/material";
import { round } from "mathjs";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEntry } from "../../../../actions/stock";
import { getStockByProductId } from "../../../../api/stock";
import Modal from "../../../../components/Modal/Modal";
import { AppliedAccount, ChildGeneralLedger } from "../../../../interfaces/Accounts";
import { t } from "../../../../components/translate";
import {
  DiscountTypes,
  PriceCalculatedOn,
  Stock,
  StockItemInterface,
  StockProducts,
  TransactionType
} from "../../../../interfaces/StockInterfaces";
import { getTemplateStockItem, updatesNo } from "../../../../reducers/stock";
import { RootState } from "../../../../store";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../../accounts/hooks";
import LedgerSelect from "../../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import StockBatch from "./StockBatch";
import StockCarryingCharge from "./StockCC";
import StockDiscount from "./StockDiscount";
import StockExpiryDate from "./StockExpiryDate";
import StockFreeQuantity from "./StockFreeQuantity";
import StockFreeVat from "./StockFreeVat";
import StockItem from "./StockItem";
import StockPrice from "./StockPrice";
import StockQuantity from "./StockQuantity";
import StockRate from "./StockRate";
import StockSalesPriceExclVat from "./StockSalesPriceExclVat";
import StockSalesVat from "./StockSalesVat";
import StockUnit from "./StockUnit";
import StockVat from "./StockVat";
import * as NotificationActions from "../../../../actions/notification";
import { putStockProduct } from "../../../../api/stockProducts";
import Can from "../../../Policy/Can";
import LedgerSelectionModal from "../../../../components/AccountLedgerAutocomplete/LedgerSelectionModal";
import { extractAdministrativeAndOtherExp } from "../../../../hooks/accounts";
import ProfitMargin from "./ProfitMargin";
import { calculateSalesPrice, findProfitMargin, isValidToCalculateSalesPrice } from "../../helper";

interface Props {
  cancelEdit: () => void;
  editRowId: null | number;
  stocks: Stock[];
  setStocks: (value: Stock[]) => void;
}

const errorCalculator = (rowState, entry) => {
  const { transactionType } = entry;
  const errorSchema = {
    productId: !rowState.productId,
    productName: !rowState.productName,
    expiryDate: !moment(rowState.expiryDate).isValid(),
    quantity:
      rowState.isFree || transactionType === TransactionType.ADJUSTMENT
        ? false
        : !rowState.quantity,
    price:
      rowState.isFree ||
      [TransactionType.ADJUSTMENT, TransactionType.KITCHEN_PHARMACY_PURCHASE].includes(
        entry.transactionType
      )
        ? false
        : !rowState.price,
    batchId: !rowState.batchId
  };
  const errors = {} as typeof errorSchema;
  Object.keys(errorSchema).forEach((k) => {
    if (errorSchema[k]) {
      errors[k] = true;
    }
  });
  return errors;
};

export default function EntryEnterer(props: Props): JSX.Element {
  const { editRowId, cancelEdit, stocks, setStocks } = props;
  const dispatch = useDispatch();

  const batchRef = React.useRef<HTMLInputElement>(null);
  const focusBatch = () => {
    if (batchRef) {
      batchRef.current.focus();
    }
  };
  const administrativeAndOtherExp = extractAdministrativeAndOtherExp(
    extractSecondaryData(useCoaAll())?.expenses
  );
  const [openLedgerSelectForInternalUse, setOpenLedgerSelectForInternalUse] =
    React.useState<boolean>(false);

  const [batchIdOptions, setBatchIdOptions] = React.useState([]);
  const [rowState, setRowState] = React.useState(getTemplateStockItem());
  const entry = useSelector((state: RootState) => state.stock.entry);
  const { transactionType } = entry;
  const [showLedgerSelect, setShowLedgerSelect] = React.useState<boolean>(false);
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger>(null);
  const { expenses } = extractSecondaryData(useCoaAll());
  const allLedgersOf = getAllChildGl(expenses);

  const discountSettings = useSelector(
    (state: RootState) => state.stock.entry?.settings?.discountSettings
  );

  const getBatchs = (productId) => {
    getStockByProductId(productId).then((data) => {
      setBatchIdOptions(data.map((i) => i.batchId));
      if (!stocks.some((item) => item.productId === productId)) {
        setStocks([...stocks, ...data]);
      }
    });
  };

  const shouldShowExtraFields =
    rowState.productId &&
    rowState.productType === 1 &&
    [TransactionType.PURCHASE, TransactionType.OPENING_STOCK].includes(
      transactionType as TransactionType
    );

  React.useEffect(() => {
    if (editRowId !== null && editRowId !== undefined) {
      setRowState(entry.stockItems.find((el) => el.sNo === editRowId) || getTemplateStockItem());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRowId]);

  const errors = errorCalculator(
    rowState,
    entry,
    editRowId !== null && editRowId !== undefined ? "edit" : "create"
  );

  const saveFn = () => {
    let updatedStockItems: Array<StockItemInterface> = [...entry.stockItems];
    if (editRowId !== null && editRowId !== undefined) {
      const foundIdx = entry.stockItems.findIndex((el) => el.sNo === editRowId);
      updatedStockItems[foundIdx] = {
        ...rowState,
        price: Number(rowState.price),
        netTotal: Number(rowState.netTotal),
        carryingChargePctOfFreeItems: rowState.carryingChargePctOfFreeItems || 0,
        rateOfFreeItems: rowState.rateOfFreeItems || 0,
        unitPriceExcVAT: rowState.unitPriceExcVAT || 0
      };
    } else {
      updatedStockItems = [
        ...entry.stockItems,
        {
          ...rowState,
          price: Number(rowState.price),
          netTotal: Number(rowState.netTotal),
          unitPriceExcVAT: rowState.unitPriceExcVAT || 0
        }
      ];
    }
    dispatch(
      updateEntry(
        updatesNo({
          ...entry,
          stockItems: updatedStockItems,
          currentStockItem: getTemplateStockItem()
        })
      )
    );
    setRowState(getTemplateStockItem());
    const itemFields = document.querySelectorAll(".stock_item_autocomplete");
    const listEl = document.querySelector(".ReactVirtualized__Grid");
    if (listEl && !editRowId) {
      setTimeout(() => {
        listEl.scrollTop = listEl.scrollHeight;
      }, 0);
    }
    if (itemFields.length) {
      const lastChild = itemFields[itemFields.length - 1];
      const innerChild = lastChild.lastChild.firstChild;
      if (innerChild) {
        (innerChild as HTMLElement).focus();
      }
    }
    if (editRowId !== null && editRowId !== undefined) {
      cancelEdit();
    }
    setRowState(getTemplateStockItem());
  };

  React.useEffect(() => {
    dispatch(updateEntry(updatesNo({ ...entry, currentStockItem: rowState })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowState, dispatch]);

  const showProfitMarginField =
    shouldShowExtraFields && transactionType === TransactionType.PURCHASE;
  const isPurchaseEntryForm = useSelector(
    (state: RootState) =>
      state.stock?.entry?.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
  );

  return (
    <Box p={2}>
      <Can policyAccessKey="account:listAccount">
        {!rowState.purchaseLedgerId &&
          rowState.productId &&
          Object.values(AppliedAccount).includes(transactionType) && (
            <Alert
              sx={{ position: "absolute", zIndex: 9999, top: "40px" }}
              action={
                <Button
                  onClick={() => setShowLedgerSelect(true)}
                  variant="outlined"
                  color="inherit"
                  size="small"
                >
                  Map Ledger
                </Button>
              }
              severity="warning"
            >
              This product is not mapped with ledger.
            </Alert>
          )}
        {!rowState.internalExpenseLedgerId &&
          [TransactionType.INTERNAL_USE, TransactionType.INTERNAL_RETURN].includes(
            transactionType
          ) &&
          rowState.productId && (
            <Alert
              sx={{ position: "absolute", zIndex: 9999, top: "40px" }}
              action={
                <Button
                  onClick={() => setOpenLedgerSelectForInternalUse(true)}
                  variant="outlined"
                  color="inherit"
                  size="small"
                >
                  Map Ledger
                </Button>
              }
              severity="warning"
            >
              This product is not mapped with ledger for Internal Use.
            </Alert>
          )}
        <LedgerSelectionModal
          options={administrativeAndOtherExp || []}
          onSave={async (lg) => {
            if (lg && rowState.productId) {
              try {
                const res = await putStockProduct({
                  id: rowState.productId,
                  internalExpenseLedgerId: lg?.id
                } as StockProducts);
                if (res?.internalExpenseLedgerId) {
                  setRowState({
                    ...rowState,
                    internalExpenseLedgerId: res.internalExpenseLedgerId
                  });
                }
                setOpenLedgerSelectForInternalUse(false);
                dispatch(
                  NotificationActions.notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "success",
                    message: "Successfully mapped with ledger.",
                    autoTimeout: true
                  })
                );
              } catch (error) {
                dispatch(
                  NotificationActions.notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "error",
                    message: "Sorry! Product is not mapped with ledger.",
                    autoTimeout: true
                  })
                );
              }
            }
          }}
          open={openLedgerSelectForInternalUse}
          title="Select Ledger for Internal Use"
          onCancel={() => setOpenLedgerSelectForInternalUse(false)}
        />
      </Can>
      <Modal
        open={showLedgerSelect}
        title="Select Ledger for Product"
        footer={
          <>
            <Button
              onClick={() => {
                setShowLedgerSelect(false);
                setSelectedLedger(null);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!selectedLedger}
              onClick={async () => {
                if (selectedLedger && rowState.productId) {
                  try {
                    const res = await putStockProduct({
                      id: rowState.productId,
                      purchaseLedgerId: selectedLedger.id
                    } as StockProducts);
                    if (res?.purchaseLedgerId) {
                      setRowState({ ...rowState, purchaseLedgerId: res.purchaseLedgerId });
                    }
                    setShowLedgerSelect(false);
                    setSelectedLedger(null);
                  } catch (error) {
                    dispatch(
                      NotificationActions.notificationAdd({
                        id: new Date().getUTCMilliseconds(),
                        variant: "error",
                        message: "Sorry ! Product is not mapped with ledger.",
                        autoTimeout: true
                      })
                    );
                  }
                }
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <LedgerSelect
          options={allLedgersOf || []}
          selected={selectedLedger}
          onChange={(value) => setSelectedLedger(value)}
        />
      </Modal>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE ? 3 : 5}
        >
          <StockItem
            stocks={stocks}
            batchRef={batchRef}
            focusBatch={focusBatch}
            item={rowState}
            updateStockItem={(s) => {
              if (transactionType !== TransactionType.PURCHASE_RETURN) {
                setRowState({
                  ...s,
                  ...(transactionType === TransactionType.PURCHASE && {
                    profitMargin: findProfitMargin(s) || 0
                  })
                });
              }
            }}
            getBatchs={getBatchs}
            entry={entry}
            error={errors.productId || errors.productName}
          />
        </Grid>
        {!isPurchaseEntryForm && (
          <>
            <Grid item xs={12} md={2} mt="-8px">
              <StockBatch
                batchRef={batchRef}
                value={rowState}
                options={batchIdOptions}
                transactionType={transactionType}
                onBatchUpdate={(v) => setRowState({ ...rowState, batchId: v })}
                error={errors.batchId}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StockExpiryDate
                transactionType={transactionType}
                value={rowState.expiryDate}
                onExpDateUpdate={(v) => {
                  setRowState({ ...rowState, expiryDate: v });
                }}
                error={errors.expiryDate}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={2}>
          <StockQuantity
            label={`${
              transactionType === TransactionType.ADJUSTMENT ? "Avail Qty" : t("StockEntry.Qty")
            }`}
            error={errors.quantity}
            value={rowState.quantity}
            onQtyUpdate={(v) => {
              let updatedState = {
                ...rowState,
                quantity: v,
                calculationBasis: "perUnit",
                netTotal: round(Number(v) * Number(rowState.price), 2)
              };
              if (rowState.freeItemQuantity) {
                if (v) {
                  updatedState = { ...updatedState, hasFreeItems: true, isFree: false };
                } else {
                  updatedState = {
                    ...updatedState,
                    hasFreeItems: false,
                    isFree: true,
                    carryingChargePctOfFreeItems: 0
                  };
                }
              } else {
                updatedState = { ...updatedState, hasFreeItems: false, isFree: false };
              }
              setRowState(updatedState as StockItemInterface);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <StockUnit
            item={rowState}
            transactionType={transactionType}
            onUnitSelect={() => {
              setRowState({
                ...rowState,
                enteredQuantityUnit: "unit",
                purchasePriceCalculationOn: "unit",
                price: rowState.purchasePricePerUnit,
                netTotal: round(
                  Number(rowState.quantity) * Number(rowState.purchasePricePerUnit),
                  2
                )
              });
            }}
            onPackageSelect={() => {
              setRowState({
                ...rowState,
                enteredQuantityUnit: "package",
                purchasePriceCalculationOn: "package",
                price: rowState.purchasePricePerPackage,
                netTotal: round(
                  Number(rowState.quantity) * Number(rowState.purchasePricePerPackage),
                  2
                )
              });
            }}
          />
        </Grid>
        {!isPurchaseEntryForm && (
          <>
            <Grid item xs={12} md={2} mt="-10px">
              <StockFreeQuantity
                transactionType={transactionType}
                value={rowState.freeItemQuantity}
                onFreeQtyUpdate={(v) => {
                  let updatedState = { ...rowState, freeItemQuantity: v };
                  if (v) {
                    if (rowState.quantity) {
                      updatedState = { ...updatedState, hasFreeItems: true, isFree: false };
                    } else {
                      updatedState = { ...updatedState, hasFreeItems: false, isFree: true };
                    }
                  } else {
                    updatedState = { ...updatedState, hasFreeItems: false, isFree: false };
                  }
                  setRowState({ ...updatedState });
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <StockCarryingCharge
                value={rowState.carryingChargePctOfFreeItems}
                onCarryingChargeUpdate={(v) => {
                  setRowState({
                    ...rowState,
                    carryingChargePctOfFreeItems: v
                  });
                }}
                disabled={!rowState.hasFreeItems}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <StockRate
                transactionType={transactionType}
                item={rowState}
                error={errors.price}
                onRateUpdate={(v) => {
                  setRowState({
                    ...rowState,
                    price: v,
                    netTotal: round(Number(v) * Number(rowState.quantity), 2),
                    ...(transactionType === TransactionType.PURCHASE &&
                      isValidToCalculateSalesPrice(rowState?.profitMargin) &&
                      (calculateSalesPrice(rowState, v, true) as {
                        unitPriceExcVAT: number;
                        packagePriceExcVAT: number;
                      }))
                  });
                }}
                onEnterPress={() => {
                  if (Object.keys(errors).length === 0) {
                    saveFn();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StockPrice
                error={false}
                transactionType={transactionType}
                item={rowState}
                onEnterPress={() => {
                  if (Object.keys(errors).length === 0) {
                    saveFn();
                  }
                }}
                onPriceUpdate={(v) => {
                  const currentPrice = v;
                  const price =
                    rowState.enteredQuantityUnit === PriceCalculatedOn.package
                      ? round(currentPrice / (rowState.quantity * rowState.unitsPerPackage), 2)
                      : round(currentPrice / rowState.quantity, 2);

                  setRowState({
                    ...rowState,
                    price,
                    netTotal: currentPrice
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <StockDiscount
                item={rowState}
                transactionType={transactionType}
                onDiscountUpdate={(v) => {
                  if (discountSettings.discountType === DiscountTypes.PERCENT) {
                    setRowState({
                      ...rowState,
                      discountPct: v <= 100 ? v : 100
                    });
                  } else if (discountSettings.discountType === DiscountTypes.AMOUNT) {
                    setRowState({
                      ...rowState,
                      discountAmt: v
                    });
                  }
                }}
                discountSettings={discountSettings}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <StockVat
                transactionType={transactionType}
                item={rowState}
                onVatUpdate={(v) => {
                  if (Number(v) !== rowState.vatPct) {
                    setRowState({
                      ...rowState,
                      vatPct: Number(v),
                      vatPctOfFreeItems: Number(v)
                    });
                  }
                }}
              />
            </Grid>
            {/* update this later to recieve values in callback */}
            <Grid item xs={12} md={1.5} mt="-4px">
              <StockFreeVat
                item={rowState}
                transactionType={transactionType}
                onUpdate={(v) => setRowState({ ...rowState, vatPctOfFreeItems: v })}
              />
            </Grid>
            {showProfitMarginField && (
              <Grid item xs={12} md={2} mt="8px">
                <ProfitMargin item={rowState} updateRow={setRowState} />
              </Grid>
            )}
            <Grid item xs={12} md={showProfitMarginField ? 3 : 4}>
              {shouldShowExtraFields && (
                <StockSalesPriceExclVat item={rowState} updateRow={setRowState} />
              )}
            </Grid>
            <Grid item xs={12} md={2} mt="8px">
              {shouldShowExtraFields && (
                <StockSalesVat
                  item={rowState}
                  onSalesVatUpdate={(v) => {
                    setRowState({ ...rowState, salesVatPct: v });
                  }}
                />
              )}
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          md={showProfitMarginField ? 3.5 : 4.5}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            sx={{ height: "36px", mt: "8px" }}
            onClick={() => {
              cancelEdit();
              setRowState(getTemplateStockItem());
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ height: "36px", mt: "8px", ml: "16px" }}
            disabled={Object.keys(errors).length > 0}
            onClick={() => {
              saveFn();
            }}
            data-testmation="addItem"
          >
            {editRowId !== null && editRowId !== undefined ? "Update Item" : "Add Item"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import * as React from "react";
import { ExtraOralData } from "../../Assessment/ExtraOral";
import { AdvancedIntraOralLabelField } from "./AdvancedIntraOralContent";

export default function ExtraOralContent({
  assessmentDocument,
  mode,
  componentLabel = "Extra Oral Examination"
}: {
  assessmentDocument: { extraOral: ExtraOralData };
  mode: "print" | "view";
  componentLabel?: string;
}): JSX.Element {
  return (
    <Box>
      {mode === "view" && (
        <Typography variant="subtitle1">
          <Box pt={3} fontWeight={600} component="span">
            {componentLabel}:
          </Box>
        </Typography>
      )}
      {Object.keys(assessmentDocument.extraOral).map((field) => (
        <AdvancedIntraOralLabelField
          key={field}
          label={startCase(field)}
          data={assessmentDocument.extraOral[field] || "-"}
        />
      ))}
    </Box>
  );
}

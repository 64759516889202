export enum policyModules {
  booking = "booking",
  bill = "bill",
  lab = "lab",
  stock = "stock",
  campaign = "campaign",
  report = "report",
  clients = "clients",
  dashboard = "dashboard",
  doctors = "doctors",
  services = "services",
  ipd = "ipd",
  clinic = "clinic",
  hmis = "hmis",
  medical = "medical",
  account = "account",
  payment = "payment",
  opd = "opd"
}

export enum opdPolicyActions {
  listMedical = "listOpd"
}

export enum medicalPolicyActions {
  listMedical = "listMedical"
}

export enum accountPolicyActions {
  listAccount = "listAccount"
}

export enum dashboardPolicyActions {
  accessStatistics = "accessStatistics",
  accessWeeklyRevenue = "accessWeeklyRevenue",
  accessBookings = "accessBookings",
  accessAppointments = "accessAppointments",
  financialKpi = "financialKpi",
  clientKpi = "clientKpi",
  sms = "smsKpi",
  labKpi = "labKpi",
  opdKpi = "opdKpi",
  stockKpi = "stockKpi"
}

export enum bookingPolicyActions {
  createSchedule = "createSchedule",
  listSchedule = "listSchedule",
  updateSchedule = "updateSchedule",
  deleteSchedule = "deleteSchedule",
  createBooking = "createBooking",
  listBooking = "listBooking",
  updateBooking = "updateBooking",
  cancelBooking = "cancelBooking",
  deleteBooking = "deleteBooking",
  viewOtherSpCalendar = "viewOtherSpCalendar"
}

export enum billPolicyActions {
  listBill = "listBill",
  createDraft = "createDraft",
  finalizeBill = "finalizeBill",
  createCreditNote = "createCreditNote",
  cancelBill = "cancelBill",
  viewHistory = "viewHistory",
  recordPayment = "recordPayment",
  printBill = "printBill",
  copyBill = "copyBill",
  changeSettings = "changeSettings",
  referralAndFees = "referralAndFees",
  changeBillIssueDate = "changeBillIssueDate"
}

export enum labPolicyActions {
  listLab = "listLab",
  createOrder = "createOrder",
  finalize = "finalize",
  approve = "approve",
  print = "print",
  cancel = "cancel",
  takeSample = "takeSample",
  batchFinalize = "batchFinalize",
  importCSV = "importCSV",
  searchLab = "searchLab",
  changeSettings = "changeSettings",
  printBarCode = "printBarCode"
}

export enum stockPolicyActions {
  createStockProduct = "createStockProduct",
  enterPurchaseEntry = "enterPurchaseEntry",
  finalizePurchaseEntry = "finalizePurchaseEntry",
  returnPurchaseEntry = "returnPurchaseEntry",
  editPurchaseEntry = "editPurchaseEntry",
  editProduct = "editProduct",
  importCSV = "importCSV",
  search = "search",
  expiryDiscard = "expiryDiscard",
  internalUse = "internalUse",
  internalRestock = "internalRestock",
  listStock = "listStock",
  quantityAdjustment = "quantityAdjustment"
}

export enum campaignPolicyActions {
  sendMessage = "sendMessage",
  createCampaign = "createCampaign",
  editCampaign = "editCampaign",
  deleteCampaign = "deleteCampaign",
  createAudience = "createAudience",
  editAudience = "editAudience",
  deleteAudience = "deleteAudience",
  createTemplate = "createTemplate",
  editTemplate = "editTemplate",
  deleteTemplate = "deleteTemplate",
  listCampaign = "listCampaign",
  listAudience = "listAudience",
  listTemplate = "listTemplate"
}

export enum reportPolicyActions {
  patientFlowReport = "patientFlowReport",
  patientAssessmentReport = "patientAssessmentReport",
  serviceProviderChargesReport = "serviceProviderChargesReport",
  salesReport = "salesReport",
  salesByServiceReport = "salesByServiceReport",
  summaryReport = "summaryReport",
  dueReport = "dueReport",
  billPaymentReport = "billPaymentReport",
  receiptReport = "receiptReport",
  smsReport = "smsReport",
  labReport = "labReport",
  stockAuditReport = "stockAuditReport",
  stockCashFlowReport = "stockCashFlowReport",
  supplierLedgerReport = "supplierLedgerReport",
  accessReportMenu = "accessReportMenu",
  feesAndReferralReport = "feesAndReferralReport",
  cmsReport = "cmsReport",
  historyReport = "historyReport"
}

export enum clientPolicyActions {
  createClient = "createClient",
  editClient = "editClient",
  importClient = "importClient",
  searchClient = "searchClient",
  listClient = "listClient"
}

export enum doctorPolicyActions {
  listDoctors = "listDoctors",
  createDoctors = "createDoctors",
  editDoctors = "editDoctors",
  editPublicBookingSetting = "editPublicBookingSetting",
  editPermissionGroup = "editPermissionGroup",
  resetPassword = "resetPassword",
  deleteDoctor = "deleteDoctor"
}

export enum servicePolicyActions {
  createProduct = "createProduct",
  editProduct = "editProduct",
  deleteProduct = "deleteProduct",
  createPackage = "createPackage",
  editPackage = "editPackage",
  deletePackage = "deletePackage",
  listService = "listService",
  addPayables = "addPayables",
  importCSV = "importCSV"
}

export enum ipdPolicyActions {
  createIpd = "createIpd",
  createProgressRecord = "createProgressRecord",
  prescribeMedication = "prescribeMedication",
  createDischarge = "createDischarge",
  listIpd = "listIpd",
  ipdBill = "ipdBill"
}

export enum clinicPolicyActions {
  listClinics = "listClinics",
  createResourceCentre = "createResourceCentre",
  listAdmin = "listAdmin",
  deleteAdmin = "deleteAdmin",
  editAdmin = "editAdmin",
  createAdmin = "createAdmin",
  changePermissionGroups = "changePermissionGroups",
  resetPasswords = "resetPasswords",
  deleteUsers = "deleteUsers",
  createSuppliers = "createSuppliers",
  editSuppliers = "editSuppliers",
  deleteSuppliers = "deleteSuppliers",
  listSuppliers = "listSuppliers",
  createReferrers = "createReferrers",
  editReferrers = "editReferrers",
  deleteReferrers = "deleteReferrers",
  listReferrers = "listReferrers",
  accessClinicAdminSetting = "accessClinicAdminSetting",
  accessPublicSettings = "accessPublicSettings",
  accessSettingsAndSubscriptions = "accessSettingsAndSubscriptions",
  publicBookingReport = "publicBookingReport"
}

export enum hmisPolicyActions {
  listHmis = "listHmis"
}

export enum paymentPolicyActions {
  listPayments = "listPayments"
}

export type CanActions =
  | `${policyModules.booking}:${bookingPolicyActions}`
  | `${policyModules.bill}:${billPolicyActions}`
  | `${policyModules.campaign}:${campaignPolicyActions}`
  | `${policyModules.clients}:${clientPolicyActions}`
  | `${policyModules.clinic}:${clinicPolicyActions}`
  | `${policyModules.doctors}:${doctorPolicyActions}`
  | `${policyModules.ipd}:${ipdPolicyActions}`
  | `${policyModules.lab}:${labPolicyActions}`
  | `${policyModules.report}:${reportPolicyActions}`
  | `${policyModules.services}:${servicePolicyActions}`
  | `${policyModules.stock}:${stockPolicyActions}`
  | `${policyModules.hmis}:${hmisPolicyActions}`
  | `${policyModules.medical}:${medicalPolicyActions}`
  | `${policyModules.dashboard}:${dashboardPolicyActions}`
  | `${policyModules.account}:${accountPolicyActions}`
  | `${policyModules.payment}:${paymentPolicyActions}`
  | `${policyModules.opd}:${opdPolicyActions}`
  | { terminated: true };

export type OptActions =
  | `${policyModules.booking}:*`
  | `${policyModules.bill}:*`
  | `${policyModules.campaign}:*`
  | `${policyModules.clients}:*`
  | `${policyModules.clinic}:*`
  | `${policyModules.doctors}:*`
  | `${policyModules.ipd}:*`
  | `${policyModules.lab}:*`
  | `${policyModules.report}:*`
  | `${policyModules.services}:*`
  | `${policyModules.stock}:*`
  | `${policyModules.hmis}:*`
  | `${policyModules.medical}:*`
  | `${policyModules.dashboard}:*`
  | `${policyModules.account}:*`
  | `${policyModules.payment}:*`;

export enum PolicyAccessKeys {
  CmsReport = "report:cmsReport",
  DueReport = "report:dueReport",
  SalesByServiceReport = "report:salesByServiceReport",
  ReceiptReport = "report:receiptReport",
  StockAuditReport = "report:stockAuditReport"
}

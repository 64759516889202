import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function CustomizedSnackbars(): JSX.Element {
  const classes = useStyles();
  const [online, setOnline] = React.useState(false);
  const [offline, setOffline] = React.useState(!navigator.onLine);

  React.useEffect(() => {
    const whenOnline = () => {
      setOffline(false);
      setOnline(true);
    };
    const whenOffline = () => {
      setOffline(true);
    };
    window.addEventListener("offline", whenOffline);
    window.addEventListener("online", whenOnline);
    return () => {
      window.removeEventListener("online", whenOnline);
      window.removeEventListener("offline", whenOffline);
    };
  });

  return (
    <div className={classes.root}>
      <Snackbar open={offline} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <div>
          <Alert severity="warning">
            Internet connection lost. Please make sure the computer is connected to the internet.
          </Alert>
        </div>
      </Snackbar>
      <Snackbar
        open={online}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOnline(false)}
      >
        <div>
          <Alert severity="success">Internet Connected</Alert>
        </div>
      </Snackbar>
    </div>
  );
}

import { History } from "../interfaces/HistoryInterface";
import { Get } from "./apiHelper";

const servicesRoot = "/api/history";

interface HistoryOptions {
  entityId?: number;
  entity?: string;
  clientId?: number;
  pageSize?: number;
}

// eslint-disable-next-line import/prefer-default-export
export async function getHistory(data: HistoryOptions): Promise<Array<History>> {
  const response = await Get(
    `${servicesRoot}/?entityId=${data.entityId || ""}&entity=${data.entity || ""}&clientId=${
      data.clientId || ""
    }&pageSize=${data.pageSize || ""}`,
    true
  );
  return response.data as Array<History>;
}

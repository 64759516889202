import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Typography, MenuItem, Box, Tooltip, Chip, Button } from "@mui/material";
import * as moment from "moment";
import { push } from "connected-react-router";
import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";
import * as reminderActions from "../../actions/reminders";
import classNames from "../../helpers/classNames";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import styles from "./styles.module.css";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { Client } from "../../interfaces/ClientInterface";
import { IThunkDispatch } from "../../store";
import { RemindersTypes } from "./Notifications";
import { MarkReadParams } from "../../api/reminders";

interface NotificationItemProps {
  notification: any;
  // eslint-disable-next-line react/no-unused-prop-types
  client: Client;
  // eslint-disable-next-line react/no-unused-prop-types
  serviceProvider: ServiceProvider;
  // eslint-disable-next-line react/no-unused-prop-types
  markReminderRead: (data: MarkReadParams) => void;
  navigateTo: (url: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  closeNotification: () => void;
  hideBadge?: boolean;
}

const NotificationTitle = ({
  date,
  module,
  hideBadge
}: {
  date?: string;
  module: string;
  hideBadge?: boolean;
}) => (
  <Box sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}>
    {date && (
      <Typography fontSize="0.70rem" fontWeight={400}>
        {convertADtoBS(moment(date)).formatted3}
      </Typography>
    )}
    {!hideBadge && (
      <Chip label={startCase(module)} size="small" color="primary" variant="outlined" />
    )}
  </Box>
);

const clientNameSelector = ({ firstName = "", lastName = "" }) =>
  `${capitalize(firstName)} ${capitalize(lastName)}`;

const RemindCustomerOnFollowup: React.FC<NotificationItemProps> = ({
  notification: { on, until, remark, data },
  client,
  serviceProvider,
  navigateTo,
  closeNotification,
  hideBadge
}) => (
  <Box
    component="span"
    flex={1}
    mr={2}
    onClick={(e) => {
      e.preventDefault();
      navigateTo(
        `/calendar/?clientId=${client.id}&serviceProviderId=${serviceProvider.id}&date=${
          until && until
        }`
      );
      closeNotification();
    }}
  >
    <NotificationTitle date={on} module={data?.entityName || ""} hideBadge={hideBadge} />
    <Box component="span" fontSize="0.78rem" fontWeight={500}>
      Remind customer{" "}
      <Box fontWeight={600} component="span">
        {clientNameSelector(client)}
      </Box>{" "}
      for followup on {convertADtoBS(moment(until)).formatted3}
    </Box>
    {remark && (
      <Box component="span" fontSize="0.73rem" fontWeight={500} ml={0.5}>
        <Box fontWeight={600} component="span">
          Remark:{" "}
        </Box>
        {remark}
      </Box>
    )}
  </Box>
);

const LabRecordReady: React.FC<NotificationItemProps> = ({
  notification: { on, data, remark },
  client,
  navigateTo,
  closeNotification,
  hideBadge
}) => (
  <Box
    component="span"
    flex={1}
    mr={2}
    onClick={(e) => {
      e.preventDefault();
      if (data.finalisedByResourceCentre) {
        navigateTo(`/lab/labRecords/${data.entityId}?status=Result%20Ready`);
      } else {
        navigateTo(`/lab/labRecords/${data.entityId}?status=Ordered`);
      }
      closeNotification();
    }}
  >
    <NotificationTitle date={on} module={data?.entityName || ""} hideBadge={hideBadge} />
    {data.finalisedByResourceCentre ? (
      <Box component="span" fontSize="0.78rem" fontWeight={500} ml="8px">
        {client && clientNameSelector(client)}
        &apos;s lab record has been finalized by {data.finalisedByResourceCentre.name}
      </Box>
    ) : (
      <Box>{remark}</Box>
    )}
  </Box>
);

const BookingRelateedReminder: React.FC<NotificationItemProps> = ({
  notification: { on, type, until, remark, data },
  client,
  serviceProvider,
  navigateTo,
  closeNotification,
  hideBadge
}) => (
  <Box
    component="span"
    flex={1}
    mr={2}
    onClick={(e) => {
      e.preventDefault();
      const date = moment().add(data.days, data.format).toISOString();
      navigateTo(
        `/calendar/?clientId=${client.id}&serviceProviderId=${serviceProvider.id}&date=${
          type === "PatientBookedFromPublicBooking" ? until : date
        }`
      );
      closeNotification();
    }}
  >
    <NotificationTitle date={on} module={data?.entityName || ""} hideBadge={hideBadge} />
    {type === "PatientBookedFromPublicBooking" ? (
      <Box fontSize="0.78rem" fontWeight={500}>
        <Box component="span">
          {clientNameSelector(client)} has booked a schedule for{" "}
          {spFullNameSelector(serviceProvider)} on{" "}
          <Box component="span" fontWeight={600}>
            {moment(until).format("dddd")}, {convertADtoBS(moment(until)).formatted3}{" "}
            {moment(until).format("LT")}
          </Box>
        </Box>
      </Box>
    ) : (
      <Box component="span" fontSize="0.78rem" fontWeight={500}>
        <Box fontWeight={600} component="span">
          {" "}
          {spFullNameSelector(serviceProvider)}
        </Box>{" "}
        has prescribed{" "}
        <Box fontWeight={600} component="span">
          {clientNameSelector(client)}
        </Box>{" "}
        to revisit in {data.days} {data.format}
      </Box>
    )}

    {remark && (
      <Box component="span" fontSize="0.73rem" fontWeight={500} ml={0.5}>
        <Box fontWeight={600} component="span">
          Remark:{" "}
        </Box>
        {remark}
      </Box>
    )}
  </Box>
);

const SubscriptionReminder: React.FC<NotificationItemProps> = (props) => {
  const { notification, navigateTo, hideBadge } = props;
  return (
    <Box
      flex={1}
      mr={2}
      onClick={() => {
        navigateTo(`/resourceCentreInvoices?invoiceId=${notification.data.entityId}`);
      }}
    >
      <NotificationTitle module={notification.data?.entityName || ""} hideBadge={hideBadge} />
      <Typography>{notification.remark}</Typography>
    </Box>
  );
};

const ExpiringStocksReminder: React.FC<Partial<NotificationItemProps>> = (props) => {
  const { notification, navigateTo, hideBadge } = props;
  return (
    <Box
      flex={1}
      mr={2}
      onClick={() => {
        navigateTo("/reports/stock/stockExpiry");
      }}
    >
      <NotificationTitle module={notification.data?.entityName || ""} hideBadge={hideBadge} />
      <Typography>{notification.remark}</Typography>
    </Box>
  );
};

const NotificationItemContainer: React.FC<NotificationItemProps> = (props) => {
  const { notification, markReminderRead, navigateTo, hideBadge } = props;
  const notificationBody = (notificationType) => {
    switch (notificationType) {
      case RemindersTypes.RemindCustomerOnFollowup:
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <RemindCustomerOnFollowup {...props} />;
      case RemindersTypes.LabRecordReady:
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <LabRecordReady {...props} />;
      case RemindersTypes.SubscriptionInvoiceCreated:
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <SubscriptionReminder {...props} />;

      case RemindersTypes.SubscriptionInvoiceDocumentUploaded:
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <SubscriptionReminder {...props} />;

      case RemindersTypes.ExpiringStocks:
        return (
          <ExpiringStocksReminder
            notification={notification}
            navigateTo={navigateTo}
            hideBadge={hideBadge}
          />
        );
      default:
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <BookingRelateedReminder {...props} />;
    }
  };
  return (
    <MenuItem
      className={classNames(styles.notiMenuItem, {
        [styles.notiMenuItemRead]: notification.done
      })}
    >
      {notificationBody(notification.type)}
      <Tooltip title={notification.done ? "Mark as unread" : "Mark as read"}>
        <Box
          className={classNames({
            [styles.markIcon]: true,
            [styles.markIconActive]: notification.done
          })}
          component="button"
          onClick={() => markReminderRead({ id: notification.id, done: !notification.done })}
        />
      </Tooltip>
      <Button
        size="small"
        variant="outlined"
        onClick={() => markReminderRead({ id: notification.id, done: !notification.done })}
        className={styles.markAsReadButton}
      >
        {notification.done ? "Mark as unread" : "Mark as read"}
      </Button>
    </MenuItem>
  );
};

const NotificationItem: FunctionComponent<NotificationItemProps> = (props) => {
  const { client, serviceProvider, notification } = props;
  if (
    (!client || !serviceProvider) &&
    notification.type !== RemindersTypes.LabRecordReady &&
    notification.type !== RemindersTypes.SubscriptionInvoiceCreated &&
    notification.type !== RemindersTypes.SubscriptionInvoiceDocumentUploaded &&
    notification.type !== RemindersTypes.ExpiringStocks
  ) {
    return null;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NotificationItemContainer {...props} />;
};

const matchDispatchToProps = (dispatch: IThunkDispatch) => ({
  markReminderRead: ({ id, done }: MarkReadParams) => {
    dispatch(
      reminderActions.markReminderRead({
        id,
        done
      })
    );
  },
  navigateTo: (url) => dispatch(push(url))
});

export default connect(null, matchDispatchToProps)(NotificationItem);

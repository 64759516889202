import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { LedgerInterface } from "../../interfaces/Employee";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { getVendorLedgers } from "../../api/user";
import { TransactionType, VendorType } from "../../interfaces/Accounts";
import { notificationAdd } from "../../actions/notification";
import { MODULE, errorFetchMessage } from "../../helpers/messages";
import List, { EmptyView } from "../../components/OList/List";
import useIsAccountSubscribed from "../../hooks/accounts";
import { t, tl } from "../../components/translate";

interface Props {
  serviceProvider: ServiceProvider;
}

export default function ServiceProviderTransactions({
  serviceProvider
}: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const [spLedgers, setSpLedgers] = React.useState<LedgerInterface[]>([]);
  const isAccountSubscribed = useIsAccountSubscribed();
  React.useEffect(() => {
    if (serviceProvider.id && isAccountSubscribed) {
      const getLedgers = async () => {
        try {
          const response = await getVendorLedgers(serviceProvider.id, VendorType.SERVICE_PROVIDER);
          setSpLedgers(response);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: error?.data?.message || errorFetchMessage(MODULE.SERVICE_PROVIDER_LEDGERS),
              autoTimeout: true,
              variant: "error"
            })
          );
        }
      };
      getLedgers();
    }
  }, [serviceProvider?.id, dispatch, isAccountSubscribed]);
  if (!serviceProvider) return null;
  return (
    <Box marginTop="32px" height="calc(100vh - 350px)" className="ledgerStyle">
      <Typography fontWeight="bold">{tl("ServiceProvideLedger")}</Typography>
      <List
        className="ledgerStyle"
        rowHeight={40}
        data={spLedgers}
        adjustHeightToContents
        columns={[
          {
            key: "transactionDate",
            label: `${t("date")}`,
            sortable: true,
            formatter: ({ transactionDate }) => (
              <Typography>
                {transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : ""}
              </Typography>
            )
          },
          {
            key: "voucherType",
            label: `${t("type")}`,
            formatter: (row) => <Typography>{row.voucherType}</Typography>
          },
          {
            key: "paymentMethod",
            label: `${t("paymentMethod")}`,
            formatter: (row) => <Typography>{row.paymentMethod}</Typography>
          },
          {
            key: "debit",
            label: `${t("debit")}`,
            formatter: ({ transactionType, amount }) => (
              <Typography>{transactionType === TransactionType.DEBIT ? amount : ""}</Typography>
            )
          },
          {
            key: "credit",
            label: `${t("credit")}`,
            formatter: ({ transactionType, amount }) => (
              <Typography>{transactionType === TransactionType.CREDIT ? amount : ""}</Typography>
            )
          },
          {
            key: "balance",
            label: `${t("balance")}`,
            formatter: ({ dueAmount }) => <Typography>{dueAmount}</Typography>
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            {tl("noLedger")}
          </Box>
        </EmptyView>
      </List>
    </Box>
  );
}

import { ReducerBuilder } from "./ReducerBuilder";
import { GeneralError } from "../interfaces/ErrorInterfaces";
import hasOwnProperty from "../helpers/object";

type payloadType = GeneralError | string;
export type stateType = payloadType[];

const addError = (state: stateType, payload: payloadType) => [...state, payload];

const removeError = (state: stateType, payload: payloadType) => {
  const index = state.findIndex((error) => JSON.stringify(error) === JSON.stringify(payload));
  return index >= 0 ? [...state.slice(0, index), ...state.slice(index + 1)] : state;
};

const removeGeneralErrorWithKey = (state: stateType, payload: payloadType) =>
  state.filter((error) => hasOwnProperty(error, "key") && error.key !== payload);

const reducer = ReducerBuilder.create<stateType, payloadType | string>([])
  .mapAction("ERROR_ADD", addError)
  .mapAction("ERROR_REMOVE", removeError)
  .mapAction("ERROR_REMOVE_WITH_KEY", removeGeneralErrorWithKey)
  .build();

export default reducer;

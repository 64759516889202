import { Get, Post, Patch } from "./apiHelper";
import { SurveyData } from "../containers/Client/Survey/SurveyCreateEditForm";

const surveyFormRoot = "/api/surveyForm";

export async function getSurveyForms(clientId: number): Promise<SurveyData[]> {
  const response = await Get(`${surveyFormRoot}?clientId=${clientId}`, true);
  return response.data as SurveyData[];
}

export async function getSurveyFormTemplate(): Promise<SurveyData> {
  const response = await Get(`${surveyFormRoot}/template`, true);
  return response.data as SurveyData;
}

export async function postSurveyFormTemplate(data: Partial<SurveyData>): Promise<SurveyData> {
  const response = await Post(`${surveyFormRoot}/template`, data, true);
  return response.data as SurveyData;
}

export async function postSurveyForm(data: Partial<SurveyData>): Promise<SurveyData> {
  const response = await Post(surveyFormRoot, data, true);
  return response.data as SurveyData;
}

export async function patchSurveyForm({
  data,
  id
}: {
  data: Partial<SurveyData>;
  id: number;
}): Promise<SurveyData> {
  const response = await Patch(`${surveyFormRoot}/${id}`, data, true);
  return response.data as SurveyData;
}

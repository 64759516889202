import {
  isRequired,
  isMaritalStatus,
  isEmail,
  CSVSexValidator,
  isNepaliPhoneNumber,
  isValidPalika
} from "../../helpers/validators";
import {
  ageSexFormatter,
  clientAgeFormatter,
  dateOfBirthFormatter,
  fullNameFormatter,
  palikaFormatterClient,
  passportFormatter,
  phoneNumberFormatter,
  sexFormatter
} from "./csvHelpers";

export const clientUploadSchema = [
  {
    label: "First Name*",
    value: "firstName",
    formatter: null,
    isCompoundField: false,
    isRequired: true,
    validator: isRequired({ msg: "Last name is missing." })
  },
  {
    label: "Last Name*",
    value: "lastName",
    formatter: null,
    isCompoundField: false,
    validator: isRequired({ msg: "First name is missing." }),
    isRequired: true
  },
  {
    label: "First and last name",
    value: "fullName",
    formatter: fullNameFormatter,
    validator: null,
    isCompoundField: true,
    compoundTo: ["firstName", "lastName"],
    isRequired: false
  },
  {
    label: "Phone*",
    value: "phone",
    formatter: phoneNumberFormatter,
    isCompoundField: false,
    validator: isNepaliPhoneNumber({ msg: "Phone number is not valid" }),
    isRequired: true
  },
  {
    label: "Ethnicity",
    value: "ethnicity",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Age",
    value: "dob",
    formatter: clientAgeFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Gender",
    value: "gender",
    formatter: sexFormatter,
    isCompoundField: false,
    validator: CSVSexValidator({ msg: "Please enter in proper format i.e M/F/O" }),
    isRequired: false
  },
  {
    label: "Age/Gender",
    value: "ageSex",
    formatter: ageSexFormatter,
    isCompoundField: true,
    compoundTo: ["dob, gender"],
    validator: null,
    isRequired: false
  },
  {
    label: "Address",
    value: "address",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "City",
    value: "city",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Email",
    value: "email",
    formatter: null,
    isCompoundField: false,
    validator: isEmail({ msg: "Email must be a valid one" }),
    isRequired: false
  },
  {
    label: "Passport Number",
    value: "passportInfo",
    formatter: passportFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Secondary Phone",
    value: "secondaryPhone",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Marital Status",
    value: "maritalStatus",
    formatter: null,
    isCompoundField: false,
    validator: isMaritalStatus({
      msg: "Single, Married, Separated, Divorced and Widowed are the only legal options"
    }),
    isRequired: false
  },
  {
    label: "Nationality",
    value: "nationality",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Occupation",
    value: "occupation",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Applied Country",
    value: "appliedCountry",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "IPD Number",
    value: "ipdNo",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Registration Number",
    value: "registrationNo",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Ward Number",
    value: "wardNo",
    formatter: null,
    isCompoundField: false,
    validator: null,
    isRequired: false
  },
  {
    label: "Palika",
    value: "palikaId",
    formatter: palikaFormatterClient,
    isCompoundField: false,
    validator: isValidPalika({
      msg: "Palika is not in valid format"
    }),
    isRequired: false
  },
  {
    label: "Date of Birth (MM/DD/YYYY) BS",
    value: "dateOfBirth",
    formatter: dateOfBirthFormatter,
    isCompoundField: false,
    validator: null,
    isRequired: false
  }
];

export const sampleClientUploadData = {
  data: [
    [
      "Amogh",
      "Rijal",
      "9851154810",
      "Nepali",
      "24",
      "M",
      "Naxal",
      "Kathmandu",
      "rcdrc@gmail.com",
      "214213003",
      "9851154810",
      "Single",
      "Nepali",
      "Software Engineer",
      "USA",
      "1234",
      "19239"
    ],
    [
      "Sita",
      "Shrestha",
      "9851154810",
      "Nepali",
      "23",
      "F",
      "Panipokhari",
      "Kathmandu",
      "rcdrc@gmail.com",
      "1203030230",
      "9851154810",
      "Married",
      "Nepali",
      "Data Scientist",
      "USA",
      "1235",
      "19232"
    ]
  ],
  headers: clientUploadSchema
    .filter((column) => !column.isCompoundField)
    .map((column) => column.label)
};

import React, { FunctionComponent } from "react";
import { Typography, Link, Box, Grid, Tooltip } from "@mui/material";
import moment from "moment";
import ReactToPrint from "react-to-print";
import "../Billing/PrintBill/PrintBill.scss";
import { useSelector } from "react-redux";
import { round } from "mathjs";
import { tl } from "../../components/translate";
import usePrintStyles from "../../hooks/usePrintStyles";
import { getSectionStyle, scaleFont } from "../Lab/LabPrint/LabPrintFunctions";
import { ResourceCentre } from "../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import { InfoField } from "../Billing/PrintBill/BillPrintHelpers";
import LabPrintLetterHeadCentered from "../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import logo from "../../../assets/images/poweredByOkhati.png";
import okhatiqr from "../../../assets/images/okhatiqr.png";
import { RootState } from "../../store";
import classNames from "../../helpers/classNames";
import {
  SubscriptionInvoiceEntity,
  SubscriptionInvoices,
  SubscriptionInvoiceStatus
} from "../../interfaces/Subscription";
import { numberToWords } from "../../helpers/rupee";
import usePrintShortcut from "../../hooks/usePrintShortcut";

interface PrintBillProps {
  buttonText?: string;
  labelStyle?: React.CSSProperties;
  invoice: SubscriptionInvoices;
  showLetterHead: boolean;
}

function getColumnsAndDataForDisplay(invoice: SubscriptionInvoices) {
  if (invoice.invoiceForEntity === SubscriptionInvoiceEntity.SMS) {
    const columns = ["SN", "Description", "QTY", "UNIT", "PRICE", "TOTAL"];
    const data = {
      [columns[0]]: 1,
      [columns[1]]: `${invoice.invoiceForEntity} - ${invoice.invoiceForEntity} Invoice Print`,
      [columns[2]]: invoice.relatedEntityDetails.quantity,
      [columns[3]]: "Pcs",
      [columns[4]]: 1.77,
      [columns[5]]: 1.77 * ((invoice.relatedEntityDetails.quantity || 1) as number)
    };
    return { data, columns };
  }
  const columns = ["SN", "Description", "PRICE", "TOTAL"];
  const data = {
    [columns[0]]: 1,
    [columns[1]]: `${invoice.invoiceForEntity} - ${
      invoice.invoiceForEntity
    } Invoice Print expiring on ${moment(invoice.relatedEntityDetails?.invoiceExpiresOn).format(
      "YYYY-MM-DD"
    )}`,
    [columns[2]]: invoice.invoiceAmount,
    [columns[3]]: invoice.invoiceAmount
  };
  return { columns, data };
}

const InvoicePrint: FunctionComponent<PrintBillProps> = ({
  buttonText,
  labelStyle,
  invoice,
  showLetterHead
}) => {
  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();

  const resourceCentre = useSelector(
    (state: RootState) =>
      state.userContext.resourceCentre ||
      state.resources.resourceCentres.find((item) => item.id === Number(invoice.resourceCentreId))
  );

  const printRef = React.useRef();
  const reactToPrintRef = React.useRef(null);

  usePrintShortcut(reactToPrintRef);

  const { columns, data } = getColumnsAndDataForDisplay(invoice);

  const clinicNameFontScale = resourceCentre?.settings?.printSettings.clinicNameFontScale;
  const headerColor: string =
    (resourceCentre?.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre?.settings.printSettings.labPrintSettings.color) ||
    "";
  const panNo = resourceCentre?.settings?.billingSettings?.panNo;
  const customTitle = resourceCentre?.settings?.printSettings?.billPrintSettings?.customTitle;
  const hideRcLogo = Boolean(customTitle);

  let letterHeadComponent = <Box />;

  const okhatiResourceCentre: Partial<ResourceCentre> = {
    name: "Nepal Land IT Solutions",
    address: "Tripura Marg, Teku",
    city: "Kathmandu",
    phone: "9851154810",
    web: "okhati.com.np",
    email: "info@okhati.com.np",
    panNo: 606847704
  };

  if (showLetterHead) {
    letterHeadComponent = (
      <LabPrintLetterHeadCentered
        resourceCentre={{
          ...okhatiResourceCentre
        }}
        titleFontScale={clinicNameFontScale}
        hideRcLogo={hideRcLogo}
      />
    );
  }
  return (
    <>
      <ReactToPrint
        onAfterPrint={() => window.focus()}
        trigger={() => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className="actionPrintBtn"
            onMouseEnter={() => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              usePrintStyles({
                pageStyle: "size: A4; margin: 0mm"
              }).applyPrintStyles();
            }}
            ref={reactToPrintRef}
            style={{ textDecoration: "none", padding: "5px 15px", cursor: "pointer" }}
          >
            <Tooltip title="Alt + P / Ctrl + P" arrow>
              <Typography component="span" style={labelStyle}>
                <Box className="actionBtnLabel" component="span">
                  {buttonText || "Print"}
                </Box>
              </Typography>
            </Tooltip>
          </Link>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td>
                  <div className="billFooterOffset" />
                </td>
              </tr>
            </tfoot>

            <thead>
              {!showLetterHead && (
                <tr>
                  <td>
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterHead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                    <Box component="div" display="flex" width="100%" justifyContent="center">
                      <Typography style={{ textAlign: "center" }}>
                        <Box
                          fontSize="0.45cm"
                          marginTop="0.5cm"
                          fontWeight={400}
                          style={{ textTransform: "uppercase" }}
                        >
                          {invoice.status === SubscriptionInvoiceStatus.paid
                            ? "Invoice Print"
                            : "Estimate Print"}
                        </Box>
                      </Typography>
                    </Box>
                    <Box component="div" display="flex" justifyContent="space-between" p="0.3cm">
                      <Box component="div" width="40%" />
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="row"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      className="blockContent"
                    >
                      <Box component="div" width="60%">
                        <Typography
                          style={{ fontSize: "1.25rem", color: "grey", marginBottom: "8px" }}
                        >
                          Bill To
                        </Typography>
                        <Typography style={{ fontWeight: 600, fontSize: "1.125rem" }}>
                          {customTitle}
                        </Typography>
                        <Typography>PAN: {panNo}</Typography>
                        <Box display="flex">
                          <Typography>{resourceCentre?.address}</Typography>
                          <Typography>, {resourceCentre?.city}</Typography>
                          <Typography>, Nepal</Typography>
                        </Box>

                        <Typography style={{ marginTop: "16px" }}>
                          {resourceCentre?.mobileNumber}
                        </Typography>
                        {resourceCentre?.email && <Typography>{resourceCentre?.email}</Typography>}
                      </Box>
                      <Box component="div" width="35%">
                        <InfoField
                          label={<>Invoice Date</>}
                          data={<>{moment(invoice.created_at).format("YYYY/MM/DD")}</>}
                        />
                        <InfoField
                          label={<>{tl("billing.printedDateTime")}</>}
                          data={
                            <>
                              {moment().format("YYYY/MM/DD")} {moment().format("h:mm a")}
                            </>
                          }
                          labelStyle={{ fontSize: scaleFont("0.3cm") }}
                          valueStyle={{ fontSize: scaleFont("0.3cm") }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box p={5}>
                    <Box>
                      <Box
                        component="div"
                        display="flex"
                        borderTop="1px solid black"
                        paddingBottom="0.2cm"
                        py="0.1cm"
                      >
                        {columns.map((column, i) => (
                          <Box
                            component="div"
                            key={column}
                            className={classNames({ grow1: i === 0, flex3: i === 1, flex1: i > 1 })}
                          >
                            <Typography component="div">
                              <Box
                                textAlign={i > 1 ? "right" : "left"}
                                style={{ textTransform: "uppercase" }}
                                fontWeight={600}
                                fontSize={scaleFont("0.27cm")}
                              >
                                {column}
                              </Box>
                            </Typography>
                            <Typography
                              component="div"
                              style={{ paddingTop: "16px", borderTop: "1px solid black" }}
                            >
                              <Box
                                textAlign={i > 1 ? "right" : "left"}
                                style={{ textTransform: "uppercase" }}
                                fontWeight={600}
                                fontSize={scaleFont("0.27cm")}
                              >
                                {["PRICE", "TOTAL"].includes(column)
                                  ? round(Number(data[column]) || 0, 2)
                                  : data[column]}
                              </Box>
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box component="div" display="flex" paddingTop="1cm" className="blockContent">
                      <Box component="div" width="50%">
                        {invoice.status === SubscriptionInvoiceStatus.unpaid && (
                          <Box width="100%" fontSize={scaleFont("0.3cm")} textTransform="uppercase">
                            <Typography align="left" fontWeight={700} mb={1}>
                              Scan To Pay
                            </Typography>
                            <img src={okhatiqr} alt="qr" height="250px" />
                          </Box>
                        )}
                      </Box>
                      <Box width="50%" component="div">
                        <Box paddingBottom="0.6cm" width="100%" textAlign="right">
                          <Box>
                            <Typography component="div">
                              <Box
                                textAlign="right"
                                fontSize={scaleFont("0.3cm")}
                                fontWeight={600}
                                style={{ textTransform: "uppercase" }}
                              >
                                {tl("billing.summary")}
                              </Box>
                            </Typography>
                            <Box component="div" marginTop="0.1cm">
                              <Grid container justifyContent="space-between">
                                <Typography align="left" component="div">
                                  <Box
                                    fontSize={scaleFont("0.3cm")}
                                    fontWeight={500}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {tl("billing.total")}
                                  </Box>
                                </Typography>
                                <Typography align="right" component="div">
                                  {invoice.invoiceForEntity === SubscriptionInvoiceEntity.SMS
                                    ? round(
                                        1.77 * (invoice.relatedEntityDetails.quantity as number),
                                        2
                                      )
                                    : round(invoice.invoiceAmount, 2)}
                                </Typography>
                              </Grid>
                            </Box>

                            {invoice.invoiceForEntity === SubscriptionInvoiceEntity.SMS && (
                              <Box component="div" marginBottom="1rem">
                                <Grid container justifyContent="space-between">
                                  <Typography align="left" component="div">
                                    <Box
                                      fontSize={scaleFont("0.3cm")}
                                      fontWeight={500}
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      {tl("billing.VAT")}
                                      <Box component="span" marginLeft="0.3rem">
                                        13%
                                      </Box>
                                    </Box>
                                  </Typography>
                                  <Typography align="right" component="div">
                                    {(
                                      0.13 *
                                      (1.77 * (invoice.relatedEntityDetails.quantity as number))
                                    ).toFixed(1)}
                                  </Typography>
                                </Grid>
                              </Box>
                            )}
                          </Box>
                        </Box>

                        <Box component="div">
                          <Typography align="right" component="div">
                            <Box
                              fontSize={scaleFont("0.3cm")}
                              fontWeight={600}
                              style={{ textTransform: "uppercase" }}
                            >
                              {tl("billing.grandTotal")}
                            </Box>
                          </Typography>
                        </Box>
                        <Box component="div">
                          <Typography align="right" component="div">
                            <Box fontWeight={500} fontSize={scaleFont("0.6cm")}>
                              {invoice.invoiceAmount}
                            </Box>
                          </Typography>
                        </Box>
                        <Box component="div">
                          <Typography align="right" component="div">
                            <Box fontWeight={400} fontSize={scaleFont("0.3cm")}>
                              {numberToWords(invoice.invoiceAmount)}
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          {showLetterHead && (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1 billFooter"
              style={{ ...getSectionStyle(headerColor).footerStyle }}
              flexDirection="row-reverse"
            >
              <Box>
                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          )}
        </div>
      </iframe>
    </>
  );
};

InvoicePrint.defaultProps = {
  buttonText: "Print",
  labelStyle: {}
};

export default InvoicePrint;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getRcAssociateCompany } from "../slices/associateCompanySlice";
import { AssociateCompany } from "../api/associateCompanies";

const useAssociateCompanies = (): Array<AssociateCompany> => {
  const companies = useSelector((state: RootState) => state.associateCompanies.collection) || [];

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!companies.length) {
      dispatch(getRcAssociateCompany());
    }
  }, [companies.length, dispatch]);
  return companies;
};

export default useAssociateCompanies;

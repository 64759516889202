import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
  Paper,
  Divider,
  ButtonGroup,
  MenuItem,
  Menu
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import moment from "moment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { round } from "mathjs";
import queryString from "query-string";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { push } from "connected-react-router";
import { RootState } from "../../store";
import List, { Filters } from "../../components/List";
import {
  cancelSubscriptionInvoice,
  getSubscriptionInvoices,
  patchInvoiceAmount,
  postSMSSubscriptionInvoice
} from "../../slices/subscriptionSlice";
import {
  SubscriptionInvoiceEntity,
  SubscriptionInvoices as SubscriptionInvoiceInterface,
  SubscriptionInvoiceStatus
} from "../../interfaces/Subscription";
import KhaltiPaymentSMS from "./KhaltiPaymentSMS";
import useCan from "../../hooks/useCan";
import { resourceCentreActions } from "../../actions";
import { getAccount } from "../../actions/user";
import Panel from "../../components/Panel";
import SubscriptionInvoiceStatusChip from "../ResourceCentreInvoices/SubscriptionInvoiceStatusChip";
import CopyToClipboard from "../../components/ClipboardCopy/ClipboardCopy";
import InvoicePrint from "../ResourceCentreInvoices/InvoicePrint";
import InvoiceDocumentUploader from "../ResourceCentreInvoices/InvoiceDocumentUploader";
import ManualInvoiceCreatePanel from "../ResourceCentreInvoices/ManualnvoiceCreatePanel";
import Can from "../Policy/Can";
import VerifyInvoiceButton from "../ResourceCentreInvoices/VerifyInvoiceButton";
import ContentEditableWithTextField from "../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import * as NotificationActions from "../../actions/notification";
import HtmlTooltip from "../../components/HtmlTooltip";
import Descriptions from "../../components/HtmlTooltip/descriptions";
import MultipleButtonGroup from "../../components/MultipleButtonGroup";
import useQueryParams from "../../hooks/useQueryParams";
import { ReverseMap } from "../../helpers/types";
import "../ResourceCentreInvoices/invoicesList.scss";
import { t, tl } from "../../components/translate";
import { SETTING_ROUTES } from "./ResourceCentreShow";

interface Props {
  resourceCentreId: number;
  invoices: Array<SubscriptionInvoiceInterface>;
}

enum INVOICE_FILTER {
  ALL = "all",
  SUBSCRIPTION = "subscription",
  SMS = "sms"
}

type InvoiceFilterType = ReverseMap<typeof INVOICE_FILTER>;
function LabelField({ label, value }: { label: string; value: string | JSX.Element }): JSX.Element {
  return (
    <Box display="flex">
      <Typography style={{ width: "240px", fontWeight: 600 }}>{label}</Typography>
      <Typography style={{ width: "auto" }}>{value}</Typography>
    </Box>
  );
}

export default function SubscriptionInvoices({ resourceCentreId, invoices }: Props): JSX.Element {
  const query = useQueryParams();
  const dispatch = useDispatch();
  const [smsAmount, setSmsAmount] = React.useState(0);
  const [addSMSDialog, setAddSMSDialog] = React.useState(Boolean(query.get("openDialog")));
  const [cancelSMS, setCancelSMS] = React.useState(false);
  const [cancelInvoiceId, setCancelInvoiceId] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const filterParam = query.get("filter") as InvoiceFilterType;
  const [filter, setFilter] = React.useState<InvoiceFilterType>(() =>
    Object.values(INVOICE_FILTER).indexOf(filterParam) !== -1 ? filterParam : INVOICE_FILTER.ALL
  );

  const canUseSuperAdminActions = useCan("deny", {
    superAdminPass: true,
    supportAccountAdminPass: true
  });

  const resourceCentres = useSelector((state: RootState) => state.resources.resourceCentres);

  const resourceCentresHashById = resourceCentres.reduce((acc, cur) => {
    // eslint-disable-next-line no-param-reassign
    acc[cur.id] = cur;
    return acc;
  }, {});

  const selectedInvoiceId = queryString.parse(window.location.search)?.invoiceId;

  React.useEffect(() => {
    if (canUseSuperAdminActions) {
      dispatch(resourceCentreActions.getResourceCentres());
    }
  }, [canUseSuperAdminActions, dispatch]);

  React.useEffect(() => {
    if (selectedInvoiceId) {
      const relatedSelectedItem = invoices.find(
        (invoice) => invoice.id === Number(selectedInvoiceId)
      );
      setSelectedItem(relatedSelectedItem);
    }
  }, [selectedInvoiceId, invoices]);

  React.useEffect(() => {
    dispatch(getSubscriptionInvoices(resourceCentreId));
  }, [dispatch, resourceCentreId]);

  const afterPaymentAction = (): void => {
    dispatch(getAccount());
    if (!canUseSuperAdminActions) {
      dispatch(resourceCentreActions.getResourceCentre(resourceCentreId));
    }
    dispatch(getSubscriptionInvoices(resourceCentreId));
  };

  const onOkClick = () => {
    setSelectedItem(null);
    setAddSMSDialog(false);
  };

  const smsRate = 2.0;

  const filterData = {
    filters: [
      { key: INVOICE_FILTER.ALL, title: `${t("allInvoices")}` },
      { key: INVOICE_FILTER.SMS, title: `${t("SMSInvoices")}` },
      { key: INVOICE_FILTER.SUBSCRIPTION, title: `${t("SubscriptionInvoices")}` }
    ]
  };

  const [state, setState] = React.useState(invoices);

  React.useEffect(() => {
    const sortedInvoices = [...invoices].sort((a, b) => b.id - a.id);
    if (filter === INVOICE_FILTER.ALL) {
      setState(sortedInvoices);
    }
    if (filter === INVOICE_FILTER.SMS) {
      setState(
        sortedInvoices.filter(
          (invoice) => invoice.invoiceForEntity === SubscriptionInvoiceEntity.SMS
        )
      );
    }
    if (filter === INVOICE_FILTER.SUBSCRIPTION) {
      setState(
        sortedInvoices.filter(
          (invoice) => invoice.invoiceForEntity === SubscriptionInvoiceEntity.SUBSCRIPTION
        )
      );
    }
  }, [filter, invoices]);

  const [manualInvoicePanelOpen, setManualInvoicePanelOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleButtonClose = () => {
    setAnchorEl(null);
  };

  const customButtonGroup = (
    <ButtonGroup
      color="primary"
      variant="contained"
      ref={anchorEl}
      aria-label="split button"
      sx={{ marginLeft: 2 }}
    >
      <Button
        data-testmation="purchaseSms"
        onClick={() => {
          setAddSMSDialog(true);
        }}
        disabled={!resourceCentreId}
      >
        {tl("purchaseSMS")}
      </Button>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
        <ArrowDropDownIcon />
      </Button>

      <Can policyAccessKey="deny" superAdminPass supportAccountAdminPass>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleButtonClose}
          data-testmation="billing.createButton"
        >
          <MenuItem
            onClick={() => {
              setManualInvoicePanelOpen(true);
              handleButtonClose();
            }}
            data-testmation="billing.recordPayment"
          >
            Create Manual Invoice
          </MenuItem>
        </Menu>
      </Can>
    </ButtonGroup>
  );

  return (
    <Box overflow="auto hidden">
      <Box
        height={canUseSuperAdminActions ? "calc(100vh - 130px)" : "calc(100vh - 105px)"}
        borderBottom="1px solid lightgrey"
        sx={{ overflowY: "auto", minWidth: 950 }}
        className="invoicesList"
      >
        <List
          title={tl("yourInvoices")}
          automation="subscription_invoices"
          rowHeight={50}
          data={[
            ...state.filter((invoice) => invoice.status === SubscriptionInvoiceStatus.unpaid),
            ...state.filter((invoice) => invoice.status !== SubscriptionInvoiceStatus.unpaid)
          ]}
          adjustHeightToContents
          onRowClick={(row) => {
            setSelectedItem(row);
          }}
          additionalHeaderContent={
            <Filters
              filter={filter}
              onFilter={(newFilter) => {
                setFilter(newFilter);
                dispatch(push(`/resourcecentres/${resourceCentreId}/payments?filter=${newFilter}`));
              }}
              filterData={filterData}
            />
          }
          createLabel="Purchase SMS"
          hideCreateButton
          customButtonGroup={customButtonGroup}
          onCreateNew={() => {
            setAddSMSDialog(true);
          }}
          columns={[
            {
              key: "id",
              label: `${t("InvoiceId")}`,
              sortable: true,
              formatter: ({ id }) => <Typography>{id}</Typography>
            },
            {
              key: "invoiceForEntity",
              label: `${t("InvoiceFor")}`,
              sortable: true,
              formatter: ({ invoiceForEntity }) => <Typography>{invoiceForEntity}</Typography>
            },
            {
              key: "resourceCentre",
              label: `${t("ResourceCenter")}`,
              sortable: true,
              formatter: ({ resourceCentreId: rcId }) => (
                <Typography>{resourceCentresHashById[rcId]?.name}</Typography>
              )
            },
            {
              key: "smsQuantity",
              label: `${t("SMSQuantity")}`,
              formatter: ({ relatedEntityDetails }) => (
                <Typography>{relatedEntityDetails.quantity || "-"}</Typography>
              )
            },
            {
              key: "invoiceAmount",
              label: `${t("InvoiceAmount")}`,
              sortable: true,
              formatter: ({ invoiceAmount }) => <Typography>Rs. {invoiceAmount}</Typography>
            },
            {
              key: "created_at",
              label: `${t("InvoiceCreatedAt")}`,
              sortable: true,
              // eslint-disable-next-line camelcase
              formatter: ({ created_at }) => (
                <Box display="flex" alignItems="center">
                  <Typography>{moment(created_at).format("YYYY-MM-DD")}</Typography>
                </Box>
              )
            },
            {
              key: "due_at",
              label: `${t("InvoiceDueAt")}`,
              sortable: true,
              formatter: ({ relatedEntityDetails }) => (
                <Box display="flex" alignItems="center">
                  {relatedEntityDetails.invoiceExpiresOn ? (
                    <Typography>
                      {moment(relatedEntityDetails.invoiceExpiresOn).format("YYYY-MM-DD")}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Box>
              )
            },
            {
              key: "status",
              label: `${t("InvoiceStatus")}`,
              sortable: true,
              formatter: ({ id, status }) => (
                <Box display="flex" alignItems="center" onClick={(e) => e.stopPropagation()}>
                  <SubscriptionInvoiceStatusChip status={status} />
                  {(status === SubscriptionInvoiceStatus.unpaid ||
                    status === SubscriptionInvoiceStatus.pendingApproval) && (
                    <KhaltiPaymentSMS
                      invoiceId={Number(id)}
                      afterAction={afterPaymentAction}
                      onOkClick={onOkClick}
                      buttonText={canUseSuperAdminActions ? "Mark Paid" : "Pay Now"}
                    />
                  )}
                </Box>
              )
            }
          ]}
        />
      </Box>
      <Box
        height="40px"
        width="100%"
        bgcolor="#ebebeb"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        px={2}
      >
        <Typography>
          {tl("totalDueAmount")}:{" "}
          <b>
            Rs{" "}
            {round(
              state
                .filter((item) =>
                  [
                    SubscriptionInvoiceStatus.unpaid,
                    SubscriptionInvoiceStatus.pendingApproval
                  ].includes(item.status)
                )
                .reduce((acc, cur) => acc + Number(cur.invoiceAmount), 0),
              2
            )}
          </b>
        </Typography>
      </Box>

      {selectedItem && (
        <Panel
          onClose={() => {
            setSelectedItem(null);
            dispatch(push(`/resourcecentres/${resourceCentreId}/${SETTING_ROUTES.PAYMENTS}`));
          }}
          printButton={
            selectedItem.invoiceAmount && (
              <Box marginRight="8px">
                <MultipleButtonGroup
                  billData={selectedItem}
                  options={[
                    {
                      label: "Print With Letter Head",
                      onClickAction: () => ({}),
                      component: (
                        <InvoicePrint
                          buttonText="Print With Letter Head"
                          invoice={selectedItem}
                          showLetterHead
                        />
                      )
                    },
                    {
                      label: "Print Without Letter Head",
                      onClickAction: () => ({}),
                      component: (
                        <InvoicePrint
                          buttonText="Print Without Letter Head"
                          invoice={selectedItem}
                          showLetterHead={false}
                        />
                      )
                    }
                  ]}
                />
              </Box>
            )
          }
          title={tl("InvoiceDetail")}
          deleteButton={
            selectedItem.status === SubscriptionInvoiceStatus.unpaid && (
              <Delete
                onClick={() => {
                  setCancelSMS(true);
                  setCancelInvoiceId(selectedItem.id);
                }}
                style={{ cursor: "pointer" }}
              />
            )
          }
        >
          <Box p={4}>
            <LabelField label={tl("InvoiceIds")} value={selectedItem.id} />
            <LabelField label={tl("InvoiceNumber")} value={selectedItem.invoiceNumber} />
            <LabelField label={tl("InvoiceType")} value={selectedItem.invoiceForEntity} />
            <Box mt="16px" />

            <ContentEditableWithTextField
              value={selectedItem.invoiceAmount}
              onSave={async (v) => {
                await dispatch(patchInvoiceAmount({ id: selectedItem.id, amount: v }));
                dispatch(
                  NotificationActions.notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "success",
                    message: "Invoice amount successfully updated!",
                    autoTimeout: true
                  })
                );
              }}
              label={<Box width="240px">{tl("Invoiceamount")} (Rs)</Box>}
              placeholder={tl("Invoiceamount")}
              width="200px"
              onFocus={(e) => e.target.select()}
              type="number"
              disabled={
                !canUseSuperAdminActions ||
                selectedItem.status !== SubscriptionInvoiceStatus.unverified
              }
            />
            <Box my="8px" display="flex">
              <LabelField
                label={tl("Status")}
                value={<SubscriptionInvoiceStatusChip status={selectedItem.status} />}
              />
              {selectedItem.status === SubscriptionInvoiceStatus.unverified && (
                <Box ml="16px">
                  <VerifyInvoiceButton
                    invoiceId={selectedItem.id}
                    next={() => setSelectedItem(null)}
                  />
                </Box>
              )}
              {(selectedItem.status === SubscriptionInvoiceStatus.unpaid ||
                selectedItem.status === SubscriptionInvoiceStatus.pendingApproval) && (
                <KhaltiPaymentSMS
                  invoiceId={Number(selectedItem.id)}
                  afterAction={afterPaymentAction}
                  onOkClick={onOkClick}
                  buttonText={canUseSuperAdminActions ? "Mark Paid" : "Pay Now"}
                />
              )}
            </Box>
            {selectedItem.relatedEntityDetails.quantity && (
              <LabelField label="Quantity" value={selectedItem.relatedEntityDetails.quantity} />
            )}
            {selectedItem.relatedEntityDetails.details && (
              <LabelField label="Details" value={selectedItem.relatedEntityDetails.details} />
            )}
            <Box mt="16px" />
            <Box mt="16px" />

            <Divider style={{ margin: "16px 0" }} />

            <Typography variant="h6">
              Payment can also be done on the following bank details.
            </Typography>
            <Paper style={{ marginTop: "16px" }} elevation={6}>
              <Box p={3}>
                <Typography style={{ fontSize: "1.25rem", fontWeight: 600, marginBottom: "16px" }}>
                  {tl("BankDetails")}
                </Typography>
                <Box display="flex" alignItems="center">
                  <LabelField label={tl("AccountNumber")} value="0400160132300015" />
                  <Box ml="16px">
                    <CopyToClipboard
                      TooltipProps={{
                        title: `${t("AfterCopy")}`
                      }}
                    >
                      {({ copy }) => (
                        <FileCopyIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            copy("0400160132300015");
                          }}
                        />
                      )}
                    </CopyToClipboard>
                  </Box>
                </Box>
                <LabelField label={tl("AccountName")} value="Nepalland IT Solution Pvt. Ltd." />
                <LabelField label={tl("BankName")} value="Prabhu Bank Ltd" />
                <LabelField label={tl("Branch")} value="Swayambhu Branch" />
              </Box>
            </Paper>

            <Box width="100%" py="8px">
              {!canUseSuperAdminActions && (
                <Box display="flex">
                  <Typography style={{ fontWeight: 600, marginTop: "8px" }}>
                    {tl("UploadProofOfPayments")}
                  </Typography>

                  <HtmlTooltip description={Descriptions.PAYMENT_PROOF} />
                </Box>
              )}
              <InvoiceDocumentUploader
                subscriptionInvoiceId={selectedItem.id}
                allowUpload={[
                  SubscriptionInvoiceStatus.pendingApproval,
                  SubscriptionInvoiceStatus.unpaid
                ].includes(selectedItem.status)}
              />
            </Box>
          </Box>
        </Panel>
      )}

      <Dialog
        open={addSMSDialog}
        onClose={() => setAddSMSDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add SMS</DialogTitle>
        <Box width="100%" borderTop="1px solid lightgrey" px="16px" mt="16px">
          <TextField
            data-testmation="numberOfSms"
            label="Type number of SMS to Add"
            margin="dense"
            type="number"
            value={smsAmount}
            fullWidth
            onChange={(e) => {
              if (Number(e.target.value) > 10000) return;
              setSmsAmount(Number(e.target.value));
            }}
            helperText="Max 10,000 SMS"
            onFocus={(e) => e.target.select()}
            variant="outlined"
          />
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>Cost per SMS:</Typography>{" "}
            <Typography>
              <b>Rs {smsRate}</b>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>Total Cost: </Typography>
            <Typography>Rs {smsRate * smsAmount}</Typography>
          </Box>

          <Alert variant="filled" severity="warning" style={{ marginTop: "16px" }}>
            SMS&apos;s will be added only after payment!
          </Alert>
        </Box>
        <DialogActions>
          <Button onClick={() => setAddSMSDialog(false)}>Cancel</Button>
          <Button
            data-testmation="confirmAndPayLater"
            onClick={() => {
              dispatch(
                postSMSSubscriptionInvoice({
                  smsQuantity: smsAmount,
                  invoiceAmount: smsAmount * smsRate,
                  invoiceNumber: Math.floor(Math.random() * 10000),
                  resourceCentreId
                })
              );
              setAddSMSDialog(false);
            }}
            disabled={!smsAmount}
            variant="contained"
          >
            Confirm and Pay Later
          </Button>
          {!canUseSuperAdminActions && (
            <KhaltiPaymentSMS
              generateInvoiceAction={(cbAction) =>
                dispatch(
                  postSMSSubscriptionInvoice({
                    smsQuantity: smsAmount,
                    invoiceAmount: smsAmount * smsRate,
                    invoiceNumber: Math.floor(Math.random() * 10000),
                    action: cbAction,
                    resourceCentreId
                  })
                )
              }
              afterAction={afterPaymentAction}
              onOkClick={onOkClick}
            />
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelSMS}
        onClose={() => setCancelSMS(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Invoice?</DialogTitle>
        <Box width="100%" p="16px">
          <Typography>Are you sure you want to cancel this invoice?</Typography>
        </Box>
        <DialogActions>
          <Button onClick={() => setCancelSMS(false)}>Back</Button>
          <Button
            data-testmation="deleteInvoiceConfirm"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(cancelSubscriptionInvoice(cancelInvoiceId));
              setCancelSMS(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {manualInvoicePanelOpen && (
        <ManualInvoiceCreatePanel handlePanelClose={() => setManualInvoicePanelOpen(false)} />
      )}
    </Box>
  );
}

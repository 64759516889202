import { Button } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionDocumentUploadActions } from "../../api/uploader";
import OkhatiUploader from "../../components/OkhatiUploader/OkhatiUploader";
import useCan from "../../hooks/useCan";
import { SubscriptionInvoiceStatus } from "../../interfaces/Subscription";
import { getSubscriptionInvoices } from "../../slices/subscriptionSlice";
import { RootState } from "../../store";
import Can from "../Policy/Can";

interface Props {
  subscriptionInvoiceId: number;
  allowUpload: boolean;
}

export default function InvoiceDocumentUploader(props: Props): JSX.Element {
  const { subscriptionInvoiceId, allowUpload } = props;
  const dispatch = useDispatch();

  const relatedSubscriptionInvoice = useSelector(
    (state: RootState) => state.subscriptions.subscriptionInvoices
  ).find((invoice) => invoice.id === subscriptionInvoiceId);

  const [previewModeOpen, setPreviewModeOpen] = React.useState(false);

  const canUseSuperAdminActions = useCan("deny", {
    superAdminPass: true,
    supportAccountAdminPass: true
  });

  return (
    <>
      {allowUpload && (
        <Can policyAccessKey="allow" superAdminPassReverse supportAccountAdminPass>
          <OkhatiUploader
            fileUploaderParams={{
              s3UploadGetterFunction: subscriptionDocumentUploadActions.presignedDataGetter,
              afterUploadAction: subscriptionDocumentUploadActions.afterUploadAction,
              id: subscriptionInvoiceId
            }}
            next={() => {
              dispatch(getSubscriptionInvoices(relatedSubscriptionInvoice.resourceCentreId));
            }}
          />
        </Can>
      )}
      {relatedSubscriptionInvoice.relatedEntityDetails?.documentUrls && (
        <>
          <Button onClick={() => setPreviewModeOpen(true)}>View Uploaded Files</Button>
          <OkhatiUploader.PreviewMode
            items={relatedSubscriptionInvoice.relatedEntityDetails.documentUrls}
            open={previewModeOpen}
            setOpen={setPreviewModeOpen}
            title="Following files have been uploaded for this Invoice!"
            deleteProps={{
              allowDeletion:
                !canUseSuperAdminActions &&
                relatedSubscriptionInvoice.status === SubscriptionInvoiceStatus.pendingApproval,
              onDelete: async (s3Key) => {
                await subscriptionDocumentUploadActions.deleteFile(s3Key, subscriptionInvoiceId);
                dispatch(getSubscriptionInvoices(relatedSubscriptionInvoice.resourceCentreId));
              }
            }}
          />
        </>
      )}
    </>
  );
}

import moment from "moment";
import "./mobileUserList.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { match as MatchProps } from "react-router";
import { MobileUser } from "../../interfaces/User";
import { getMobileUsers } from "../../api/user";
import { publicHeadersInfo } from "./VerificationCodes";
import List, { MultipleHeader } from "../../components/List";
import { notificationAdd } from "../../actions/notification";
import PageControl from "../../components/PageControl";

export interface ProcessedMobileUserData {
  id: number;
  userName: string;
  rcName: string;
  isGuest: boolean;
  createdAt: string;
}

const pageSize = 15;

export default function MobileUsers({ match }: { match: MatchProps }): JSX.Element {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [mobileUsers, setMobileUsers] = useState<Array<Partial<MobileUser>>>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const data = await getMobileUsers({ page, pageSize });
        setMobileUsers(data);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get mobile users",
            autoTimeout: true
          })
        );
      }
    })();
  }, [dispatch, page]);

  const processedData = mobileUsers.map((item, index) => ({
    id: index,
    userName: item.name ? item.name : "...",
    rcName: item.resourceCentre ? item.resourceCentre.name : "...",
    isGuest: item.isGuest ? "Yes" : "No",
    createdAt: moment(new Date(item.created_at as string)).format("YYYY-MM-DD")
  }));

  return (
    <Box p={2} height="calc(100% - 10px)" className="mobileUserList">
      <MultipleHeader multipleHeaders={publicHeadersInfo(match)} />
      <List<ProcessedMobileUserData>
        data={processedData}
        automation="mobileUserList"
        withoutSearch
        hideCreateButton
        columns={[
          {
            key: "rcName",
            label: "Resource Centre Name",
            sortable: true,
            formatter: ({ rcName }) => <Typography>{rcName}</Typography>
          },
          {
            key: "userName",
            label: "User name",
            sortable: true,
            formatter: ({ userName }) => <Typography>{userName}</Typography>
          },
          {
            key: "isGuest",
            label: "Is Guest",
            sortable: true,
            formatter: ({ isGuest }) => <Typography>{isGuest}</Typography>
          },
          {
            key: "createdAt",
            label: "Created Date",
            sortable: true,
            formatter: ({ createdAt }) => <Typography>{createdAt}</Typography>
          }
        ]}
      />

      <Box className="navigatePage">
        <PageControl
          page={page}
          pageSize={pageSize}
          onSetPage={(v) => {
            setPage(v);
          }}
          maximumDataCount={1000}
        />
      </Box>
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { equalTo, get, orderByChild, query, ref } from "firebase/database";
import Panel from "../../components/Panel";
import { ProcessedChatData } from "./ChatStats";
import { ChatList, ReadableChats } from "../../hooks/useGetFirebaseChat";
import SenderThread from "../../components/Messages/MessageBox";
import { isSameDate } from "../Messages/MessageThread";
import { db } from "../../../firebase/config";

interface Props {
  onClose: () => void;
  data: ProcessedChatData;
}

const MessageThreadPreview = ({ onClose, data }: Props): JSX.Element => {
  const [formattedChats, setFormattedChats] = React.useState<ReadableChats>({});
  const selectedChat = formattedChats[data.userId];

  React.useEffect(() => {
    const chatListQuery = query(
      ref(db, "chats"),
      ...[orderByChild("resourceCentreId"), equalTo(data.resourceCentreId)]
    );
    get(chatListQuery).then((snapshot) => {
      const chatList: ChatList = snapshot.val();

      if (chatList) {
        const groupedChats = Object.keys(chatList)
          ?.map((key) => ({ ...chatList[key], chatKey: key }))
          ?.sort((a, b) => new Date(b.lastMsgTimestamp) - new Date(a.lastMsgTimestamp))
          ?.reduce((acc, curr) => {
            const messages = Object.keys(curr.chatItems).map((key) => ({
              ...curr.chatItems[key],
              key
            }));
            const lastMessage = messages[messages.length - 1];
            // eslint-disable-next-line no-param-reassign
            acc[curr.userId] = {
              messages: messages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
              userId: curr.userId,
              resourceCentreId: curr.resourceCentreId,
              lastMessage,
              chatKey: curr.chatKey,
              chatClosedBy: curr.chatClosedBy
            };
            return acc;
          }, {});
        setFormattedChats(groupedChats);
      }
    });
  }, [data.resourceCentreId]);

  return (
    <Panel onClose={onClose}>
      <Box paddingX="16px" height="calc(100% - 100px)" style={{ overflowY: "auto" }}>
        {selectedChat?.messages?.map((userMessage, i) => {
          const previousMessage = selectedChat.messages[i - 1];
          return (
            <>
              {!isSameDate(previousMessage?.timestamp, userMessage.timestamp) && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flex={1}
                  mt={2}
                  mb={1}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#E9EAED",
                      borderRadius: "5px",
                      padding: 0.5,
                      fontWeight: "bold",
                      fontSize: "14px"
                    }}
                  >
                    {moment(new Date(userMessage.timestamp)).format("dddd, D MMM")}
                  </Typography>
                </Box>
              )}

              <SenderThread
                key={userMessage.timestamp}
                message={userMessage.message}
                timestamp={userMessage.timestamp}
                displayName={data.rcName}
                alignMessage={userMessage.from === data.resourceCentreId ? "right" : "left"}
              />
            </>
          );
        })}
      </Box>
    </Panel>
  );
};

export default MessageThreadPreview;

import { Box, Typography } from "@mui/material";
import { omit, startCase } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import List, { EmptyView } from "../../components/OList";
import {
  StockTransactions,
  TransactionItemInterface,
  TransactionType
} from "../../interfaces/StockInterfaces";
import { getSupplierTransactions } from "../../slices/supplierSlice";
import { RootState } from "../../store";
import { t, tl } from "../../components/translate";

interface Props {
  supplierId: number;
}

function formatTransactionsByProduct(transactions: Array<StockTransactions>) {
  const productsHash = {} as {
    [k: number]: {
      stockTrxId: number;
      id: number;
      transactions: Array<Omit<StockTransactions, "stockTransactionItems">>;
      productName: string;
      trxItemDetails: TransactionItemInterface;
      valuation: number;
      transactionType: string;
    };
  };

  transactions.forEach((trx) => {
    trx.stockTransactionItems.forEach((trxItem) => {
      productsHash[trxItem.productId] = {
        stockTrxId: trx.id,
        id: trxItem.productId,
        transactions: [
          ...(productsHash[trxItem.productId]?.transactions || []),
          omit(trx, "stockTransactionItems")
        ],
        productName: trxItem.productName,
        trxItemDetails: trxItem,
        valuation: (productsHash[trxItem.productId]?.valuation || 0) + trxItem.grossTotal,
        transactionType: trx.transactionType
      };
    });
  });
  return productsHash;
}

export default function SupplierProducts(props: Props): JSX.Element {
  const { supplierId } = props;

  const dispatch = useDispatch();

  const supplierTransactions = useSelector((state: RootState) =>
    state.suppliers.transactions.filter((item) => item.transactionType === TransactionType.PURCHASE)
  );

  React.useEffect(() => {
    dispatch(getSupplierTransactions(supplierId));
  }, [supplierId, dispatch]);

  const productsHash = React.useMemo(
    () => formatTransactionsByProduct(supplierTransactions),
    [supplierTransactions]
  );

  return (
    <div style={{ height: "calc(100vh - 350px)" }}>
      <Box display="flex" justifyContent="flex-end">
        <Typography mr={1} component="div">
          {tl("ProdPurchase")}:{" "}
          <b>
            Rs{" "}
            {(
              Object.values(productsHash).reduce((acc, cur) => cur.valuation + acc, 0) || 0
            ).toFixed(2)}
          </b>
        </Typography>
      </Box>
      <List
        automation="clientLabs"
        rowHeight={40}
        data={Object.values(productsHash)}
        columns={[
          {
            key: "stockTrxId",
            label: `${t("transactionId")}`,
            sortable: true,
            formatter: ({ stockTrxId }) => <Typography>{stockTrxId}</Typography>
          },
          {
            key: "productName",
            label: `${t("productname")}`,
            sortable: true,
            formatter: ({ productName }) => (
              <Typography style={{ fontSize: "12px" }}>{productName}</Typography>
            )
          },
          {
            key: "transactionType",
            label: `${t("transactionType")}`,
            sortable: true,
            formatter: ({ transactionType }) => (
              <Typography>{startCase(transactionType)}</Typography>
            )
          },
          {
            key: "valuation",
            label: `${t("totalValue")}`,
            sortable: true,
            formatter: ({ valuation }) => <Typography>Rs. {(valuation || 0).toFixed(2)}</Typography>
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No products for the supplier yet...
          </Box>
        </EmptyView>
      </List>
    </div>
  );
}

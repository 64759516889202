import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPublicServices,
  putBatchService,
  postBatchService,
  publicServiceDelete
} from "../api/services";
import { ServiceInterface } from "../interfaces/ServiceInterface";

interface State {
  collection: ServiceInterface[];
}

export const getPublicService = createAsyncThunk(
  "services/publicServices",
  async (_, { rejectWithValue }) => {
    try {
      return await getPublicServices();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updatePublicService = createAsyncThunk(
  "services/updatePublicService",
  async (data: ServiceInterface, { rejectWithValue }) => {
    try {
      return await putBatchService(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createPublicService = createAsyncThunk(
  "services/createPublicService",
  async (data: ServiceInterface, { rejectWithValue }) => {
    try {
      return await postBatchService(data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deletePublicService = createAsyncThunk(
  "services/deleteService",
  async (id: number, { rejectWithValue }) => {
    try {
      await publicServiceDelete(id);
      return id;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const initialState: State = {
  collection: []
};

const publicServiceSlice = createSlice({
  name: "publicServiceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublicService.fulfilled, (draft, { payload }) => {
      draft.collection = payload;
      return draft;
    });

    builder.addCase(updatePublicService.fulfilled, (draft, { payload }) => {
      const idx = draft.collection.findIndex((el) => el.id === payload.id);
      if (idx !== -1) draft.collection[idx] = payload;
      return draft;
    });

    builder.addCase(updatePublicService.rejected, (_, { payload }) => {
      console.log(payload);
    });

    builder.addCase(createPublicService.fulfilled, (draft, { payload }) => {
      draft.collection?.push(payload);
      return draft;
    });

    builder.addCase(createPublicService.rejected, (_, { payload }) => {
      console.log(payload);
    });

    builder.addCase(deletePublicService.fulfilled, (draft, { payload }) => {
      draft.collection = draft.collection?.filter((item) => payload !== item.id);
      return draft;
    });

    builder.addCase(deletePublicService.rejected, (_, { payload }) => {
      console.log(payload);
    });
  }
});

export default publicServiceSlice.reducer;

import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { getKitchenPharmacyBranch } from "../../api/resourceCentre";
import { notificationAdd } from "../../actions/notification";
import { commonErrorMessage } from "../../helpers/messages";

export default function KitchenPharmacyBranchSelect({
  onChange
}: {
  onChange: (ids: number[]) => void;
}): JSX.Element {
  const dispatch = useDispatch();
  const [branch, setBranch] = React.useState<number[]>([]);
  const [rcOptions, setRcOptions] = React.useState<{ resourceCentreName: string; rcId: number }[]>(
    []
  );

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getKitchenPharmacyBranch();
        setRcOptions(response);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: commonErrorMessage,
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    })();
  }, []);

  return (
    <Autocomplete
      multiple
      value={branch}
      onChange={(_, newValue) => {
        setBranch(newValue);
        onChange(newValue.map(({ rcId }) => rcId));
      }}
      options={rcOptions || []}
      getOptionLabel={(option) => `${option.resourceCentreName} [${option.rcId}]`}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            //   eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
            key={option.rcId}
            label={`${option.resourceCentreName} [${option.rcId}]`}
          />
        ))
      }
      style={{ marginTop: "8px" }}
      renderInput={(params) => (
        //   eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} fullWidth label="Select Branch" />
      )}
    />
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { RichText } from "prismic-reactjs";
import SmsIcon from "@mui/icons-material/Sms";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";

import {
  KnowledgeBaseDocumentTag,
  KnowledgeBaseExtraTag,
  useKnowledgeBaseByTags
} from "../../api/knowledgeBase";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import useMobileScreen from "../../hooks/useMobileScreen";
import SelectedArticle from "../KnowledgeBase/SelectedArticle";
import { salesPhoneNumber, supportEmail } from "../../constants";
import { KnowledgeBaseDocument } from "../../interfaces/KnowledgeBase";
import MessageCreate, { MESSAGE_TYPE } from "../Campaign/InstantMessage/MessageCreate";
import { RootState } from "../../store";
import { getMessageTemplate, MessageTemplateEnum } from "../../helpers/messages";

interface ComponentProps {
  setShowDaybookNotSubscribedPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DaybookNotSubscribedPanel({
  setShowDaybookNotSubscribedPanel
}: ComponentProps): JSX.Element {
  const isMobileScreen = useMobileScreen();
  const [showMessagePanel, setShowMessagePanel] = React.useState(false);
  const [messageType, setMessageType] = React.useState<MESSAGE_TYPE>(MESSAGE_TYPE.EMAIL);
  const { data = [], isLoading } = useKnowledgeBaseByTags([KnowledgeBaseDocumentTag.daybook]);
  const [selectedArticle, setSelectedArticle] = React.useState<KnowledgeBaseDocument | null>(null);

  const activeUser = useSelector((state: RootState) => state.userContext.user);
  const activeRc = useSelector((state: RootState) => state.userContext.resourceCentre);

  const whatIsDaybook = data.find((article) =>
    [KnowledgeBaseDocumentTag.daybook, KnowledgeBaseExtraTag.definition].every((tag) =>
      article.tags.includes(tag)
    )
  );

  const daybookFeatures = data.filter(
    (article) =>
      article.tags.includes(KnowledgeBaseDocumentTag.daybook) &&
      !article.tags.includes(KnowledgeBaseExtraTag.definition)
  );

  const onMessageBtnClick = (type: MESSAGE_TYPE) => {
    setMessageType(type);
    setShowMessagePanel(true);
  };

  return (
    <Panel title="Daybook" onClose={() => setShowDaybookNotSubscribedPanel((prev) => !prev)}>
      <Box pt={4} px={`${isMobileScreen ? "16px" : "32px"}`}>
        {selectedArticle ? (
          <SelectedArticle data={selectedArticle} onClose={() => setSelectedArticle(null)} />
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "25px", paddingBottom: "20px" }}
          >
            <div>
              <Typography variant="h6" component="p" mb={1}>
                {tl("daybook.wantToUseDaybook")}
              </Typography>
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Sales</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                          <Typography fontSize={16}>{salesPhoneNumber}</Typography>
                          <Tooltip title="Send SMS">
                            <SmsIcon
                              sx={{ cursor: "pointer", width: "0.9rem" }}
                              onClick={() => onMessageBtnClick(MESSAGE_TYPE.SMS)}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Email</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                          <Typography fontSize={16}>{supportEmail}</Typography>
                          <Tooltip title="Send Email">
                            <ForwardToInboxIcon
                              sx={{ cursor: "pointer", width: "0.9rem" }}
                              onClick={() => onMessageBtnClick(MESSAGE_TYPE.EMAIL)}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>

            <div>
              <Typography variant="h6" component="p" mb={1}>
                {whatIsDaybook?.data.title ?? ""}
              </Typography>
              <RichText
                render={
                  Array.isArray(whatIsDaybook?.data.article_content)
                    ? whatIsDaybook?.data.article_content
                    : []
                }
              />
            </div>

            <div>
              <Typography variant="h6" component="p">
                More about Daybook
              </Typography>

              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {daybookFeatures.map((article) => (
                    <Box
                      component="ul"
                      key={article.id}
                      onClick={() => setSelectedArticle(article)}
                    >
                      <Button
                        fullWidth
                        variant="text"
                        sx={{
                          "&.MuiButton-text": {
                            color: "#626262",
                            fontSize: 14,
                            fontWeight: 500,
                            paddingX: 0,
                            justifyContent: "flex-start",
                            textTransform: "none"
                          }
                        }}
                      >
                        {article.data.title}
                      </Button>
                    </Box>
                  ))}
                </>
              )}
            </div>
          </Box>
        )}
      </Box>

      {showMessagePanel && (
        <MessageCreate
          onCancel={() => {
            setShowMessagePanel(false);
          }}
          isReceiverOkhati
          messageType={messageType}
          defaultSubject={
            getMessageTemplate({
              messageTemplateType: MessageTemplateEnum.REQUEST_FOR_DAYBOOK,
              activeRc,
              activeUser
            }).subject
          }
          initialHTMLMessage={
            getMessageTemplate({
              messageTemplateType: MessageTemplateEnum.REQUEST_FOR_DAYBOOK,
              activeRc,
              activeUser
            }).emailBody
          }
          defaultSmsBody={
            getMessageTemplate({
              messageTemplateType: MessageTemplateEnum.REQUEST_FOR_DAYBOOK,
              activeRc,
              activeUser
            }).smsBody
          }
        />
      )}
    </Panel>
  );
}

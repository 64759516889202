import { push } from "connected-react-router";
import { omit } from "lodash";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/medicalTest";
import * as labApi from "../api/labTest";
import { AppThunk, IThunkDispatch } from "../store";
import { LabTestGroup } from "../interfaces/LabInterfaces";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";
import { Client } from "../interfaces/ClientInterface";
import { LabRecord, LabRecordApprovedBySp } from "../interfaces/Lab";
import { notificationAdd } from "./notification";

export enum Type {
  GET_MEDICAL_TEST = "GET_MEDICAL_TEST",
  POST_MEDICAL_TEST = "POST_MEDICAL_TEST",
  PATCH_MEDICAL_TEST = "PATCH_MEDICAL_TEST",
  UPDATE_CLIENT_IMAGE_MEDICAL = "UPDATE_CLIENT_IMAGE_MEDICAL",
  UPDATE_MEDICAL_TEST_SAMPLE = "UPDATE_MEDICAL_TEST_SAMPLE",
  GET_SINGLE_MEDICAL_TEST = "GET_SINGLE_MEDICAL_TEST"
}

export interface PostLabTestInterface {
  info: {
    clientId: number;
    referrers: string;
    labIdentifier: string;
  };
  requestedTests: Array<LabTestGroup>;
}

export const getMedicalTestById =
  (id: number): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_SINGLE_MEDICAL_TEST, async () => api.getMedicalRecordById(id))
    );
  };

export const getMedicalTest =
  (testId?: number, clientId?: number): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_MEDICAL_TEST, async () => api.getLabTestRecord(testId, clientId))
    );
  };

export const updateClientImageMedical =
  (id: number, profileImageS3Url: string): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({
      type: Type.UPDATE_CLIENT_IMAGE_MEDICAL,
      payload: { id, profileImageS3Url }
    });

export const postMedicalTest =
  (data: PostLabTestInterface): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.POST_MEDICAL_TEST, async () => {
        const response = await api.postLabTestRecord(data);
        if (response) {
          dispatch(push(`/medical/entry/${response[0].id}`));
        }
        return response;
      })
    );
  };

export interface PatchMedicalTestInterface {
  skipInternalStock?: boolean;
  attachments: Array<unknown>;
  authorizedSP1Id: number;
  requestApproval?: boolean;
  authorizedSP2Id: number;
  sendToClient: boolean;
  authorizedSP3Id: number;
  authorizedSP4Id: number;
  final: boolean;
  updatedTestResults: {
    examDate: string;
    modifiedDate: string;
    serviceProvider: Partial<ServiceProvider>;
    client: Partial<Client>;
    data: Array<LabRecord>;
    approvedBy?: Record<string, LabRecordApprovedBySp>;
  };
}

export const patchMedicalTest =
  (data: PatchMedicalTestInterface, id: number): AppThunk =>
  async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.PATCH_MEDICAL_TEST, async () => {
        try {
          const response = await labApi.patchLabTestsRecord(id, {
            ...data,
            updatedTestResults: omit(data.updatedTestResults, ["approvedBy"])
          });
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: "Successfully updated Medical test.",
              autoTimeout: true,
              variant: "success"
            })
          );
          return response;
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: error?.data?.message || "Sorry!, Couldn't update Medical Test.",
              autoTimeout: true,
              variant: "error"
            })
          );
          return null;
        }
      })
    );
  };

interface MedicalTestsRecordSampleParams {
  medicalTestId: number;
  status: string;
  remark?: string;
  id?: number;
  name?: string;
}

export const updateMedicalTestsRecordSample =
  ({ medicalTestId, status, remark, id, name }: MedicalTestsRecordSampleParams): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_MEDICAL_TEST_SAMPLE, () =>
        labApi.patchLabTestRecordStatus(medicalTestId, status, remark, id, name)
      )
    );
  };

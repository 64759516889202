import React from "react";
import { Autocomplete } from "@mui/material";
import { getStockProducts as getProducts } from "../../api/stockProducts";
import { ProductInterface } from "../../interfaces/ProductInterface";
import DebouncedTextField from "../../components/DebouncedTextField";

const StockProductsAutoSelectMulti = ({
  onChange,
  singularProduct = false
}: {
  onChange: (pIds: number[]) => void;
  singularProduct?: boolean;
}): JSX.Element => {
  const [products, setProducts] = React.useState<ProductInterface[]>([]);

  return (
    <Autocomplete
      multiple={!singularProduct}
      data-testmation="productSearchInput"
      id="tags-standard"
      options={products}
      getOptionLabel={(option: ProductInterface) => option.name}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      onChange={(_, v) => {
        if (singularProduct) {
          onChange(v?.id || null);
        } else {
          const pIds = v?.map((product) => product.id) || [];
          onChange(pIds);
        }
      }}
      renderInput={(params) => (
        <DebouncedTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          debounceAt={1000}
          variant="standard"
          label="Filter by product"
          placeholder="Products.."
          onChange={async ({ target }) => {
            const query = (target.value || "").trim();
            if (query.length > 2) {
              const response = (await getProducts({
                search: query,
                limit: 20,
                excludeStockAndRates: true
              })) as ProductInterface[];
              if (response?.length) {
                setProducts(response);
              } else {
                setProducts([]);
              }
            }
          }}
        />
      )}
    />
  );
};

export default StockProductsAutoSelectMulti;

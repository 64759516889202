import { Box, Button, TextField, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import Collapse from "../../../components/CollapseBar/CollapseBar";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { tl } from "../../../components/translate";
import {
  useMasterFiscalPeriods,
  usePostNewFiscalPeriod
} from "../../../hooks/query/useFiscalPeriods";
import Can from "../../Policy/Can";

interface NewFiscalPeriod {
  from: Date;
  to: Date;
}

const isValidToDate = (start: Date, end: Date): boolean => moment(start).isBefore(end);
const isValidFromDate = (previousDate: Date, currentDate: Date): boolean => {
  if (!previousDate) return true;
  return moment(previousDate).isBefore(currentDate);
};

const hasValue = (start: Date, end: Date): boolean => {
  if (start && end) return true;
  return false;
};

export default function AccountSettings(): JSX.Element {
  const [newFiscalPeriod, setNewFiscalPeriod] = React.useState<NewFiscalPeriod>(
    {} as NewFiscalPeriod
  );
  const { data: masterFiscalPeriods = [] } = useMasterFiscalPeriods();
  const postMasterFiscalPeriod = usePostNewFiscalPeriod();
  const [openCreationDialogue, setCreationDialogue] = React.useState<boolean>(false);
  const latestMasterFiscalPeriod = [...masterFiscalPeriods].sort((a, b) => a.id - b.id).pop();

  return (
    <Can policyAccessKey="deny" superAdminPass>
      <Collapse label={tl("accounts.masterFiscalPeriods")} collapsed={false}>
        <Typography fontWeight="bold" m="20px 0">
          Master FiscalPeriods
        </Typography>
        <Box gap={2}>
          {masterFiscalPeriods.map((period, index) => (
            <Box alignItems="center" key={period.id} display="flex">
              {`${index + 1}. `}
              <TextField
                label="Start Year"
                variant="outlined"
                sx={{ margin: "5px" }}
                value={convertADtoBS(moment(period.from)).formatted4}
                disabled
              />
              <TextField
                label="End Year"
                sx={{ margin: "5px" }}
                variant="outlined"
                value={convertADtoBS(moment(period.to)).formatted4}
                disabled
              />
            </Box>
          ))}
        </Box>
        <Box ml="10px">
          <Typography m="10px">Add Master Fiscal Period</Typography>
          <Box ml="10px" display="flex" alignItems="center" gap={2}>
            <CalendarDropdown
              allowNull
              label="Start Year"
              disable={false}
              fullwidth
              TextFieldProps={{
                variant: "outlined",
                margin: "dense",
                error:
                  latestMasterFiscalPeriod?.from && newFiscalPeriod.from
                    ? !moment(latestMasterFiscalPeriod.to).isBefore(newFiscalPeriod.from)
                    : false
              }}
              date={newFiscalPeriod.from ? new Date(newFiscalPeriod.from) : null}
              onChange={(newDate: Date) => {
                setNewFiscalPeriod({
                  ...newFiscalPeriod,
                  from: moment(newDate).tz("Asia/Kathmandu").startOf("day").toISOString()
                });
              }}
            />
            <CalendarDropdown
              allowNull
              disable={false}
              fullwidth
              TextFieldProps={{
                variant: "outlined",
                margin: "dense",
                error: hasValue(newFiscalPeriod.from, newFiscalPeriod.to)
                  ? !isValidToDate(newFiscalPeriod.from, newFiscalPeriod.to)
                  : false
              }}
              label="End Year"
              date={newFiscalPeriod.to ? new Date(newFiscalPeriod.to) : null}
              onChange={(newDate: Date) => {
                setNewFiscalPeriod({
                  ...newFiscalPeriod,
                  to: moment(newDate).tz("Asia/Kathmandu").endOf("day").toISOString()
                });
              }}
            />
            <Button
              disabled={
                !hasValue(newFiscalPeriod.from, newFiscalPeriod.to) ||
                !isValidToDate(newFiscalPeriod.from, newFiscalPeriod.to) ||
                !isValidFromDate(latestMasterFiscalPeriod?.to as Date, newFiscalPeriod.from)
              }
              onClick={() => {
                setCreationDialogue(true);
              }}
              variant="contained"
            >
              Add New Fiscal Period
            </Button>
          </Box>
          <OkhatiDialog
            readMode={false}
            title="Confirmation"
            description="You have set your Fiscal period. Once confirmed, cannot be edited, do you want to save it?"
            open={openCreationDialogue}
            next={() => {
              postMasterFiscalPeriod.mutate(newFiscalPeriod);
              setCreationDialogue(false);
              setNewFiscalPeriod({} as NewFiscalPeriod);
            }}
            cancel={() => {
              setCreationDialogue(false);
            }}
          />
        </Box>
      </Collapse>
    </Can>
  );
}

import * as React from 'react';
import { connect } from 'react-redux';
import * as serviceActions from '../../actions/services';
import { servicesSortedSelector } from '../../reducers/services';
import Chip from '@mui/material/Chip';

const optionsForSelect = (services = [], filter?) => {
  return filter
    .map((id) => services.find((service) => service.id === id))
    .filter((s) => Boolean(s));
};
export const ServiceProviderAutoSelect = ({ services, values = [], disabled = false, actions }) => {
  React.useEffect(() => {
    if (!services || !services.length) actions.loadServices();
  }, []);
  if (!services) return null;
  return optionsForSelect(services, values).map((service) => {
    return (
      <Chip
        style={{ marginRight: '8px' }}
        size={'small'}
        label={service.name}
        variant="outlined"
      ></Chip>
    );
  });
};

export default connect(
  (state) => ({
    services: servicesSortedSelector(state),
  }),
  (dispatch) => ({
    actions: {
      loadServices: () => dispatch(serviceActions.getServices()),
    },
  }),
)(ServiceProviderAutoSelect);

import { ServiceType, ProductType } from '../interfaces/ProductInterface';

export const exampleServices = [
  { name: 'Check up' },
  { name: 'XRay' },
  { name: 'Ultrasound' },
  { name: 'Echo' },
  { name: 'ECG' },
  { name: 'Whole body checkup' },
  { name: 'Vaccination' },
  { name: 'Surgery' },
  { name: 'Therapy' },
  { name: 'Counselling' },
  { name: 'Certification' }
];

export const unitOfProducts = ['pcs', 'kgs', 'hrs', 'visit'];

export const ServiceTypes = [ServiceType.single, ServiceType.package];

export const ProductCategory = [
  ProductType.consultation,
  ProductType.labTest,
  ProductType.medication,
  ProductType.imaging,
  ProductType.procedures,
  ProductType.video,
  ProductType.nursing,
  ProductType.physiotherapy,
  ProductType.vaccination,
  ProductType.others
];

import { Autocomplete, Typography, Box, Grid, Icon } from "@mui/material";
import React from "react";
import { cloneDeep } from "lodash";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch } from "react-redux";
import { getStockProducts } from "../../../api/stockProducts";
import Panel from "../../../components/Panel";
import styles from "../Assessment.module.css";
import { t, tl } from "../../../components/translate";
import { Medicine } from "../../../interfaces/AssessmentInterfaces";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import CreateStockProduct from "../../Stock/StockCreateEdit/CreateStockProduct";
import MedicationDialog from "../Medication/MedicationDialog";
import { countableTypes, medicineType } from "../Medication/medicineType";
import useMobileScreen from "../../../hooks/useMobileScreen";
import * as NotificationActions from "../../../actions/notification";
import {
  getMedicineMultiplier,
  showTimeUnit,
  showFrequencyUnit,
  showMeal
} from "../Medication/Medication";
import DebouncedTextField from "../../../components/DebouncedTextField";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

interface ProductNewInterface extends ProductInterface {
  inputValue?: string;
}

interface ProductSelectProps {
  productMedication: Medicine[];
  onChange: (productMedication: Medicine[]) => void;
}

const currentMedicineData: Medicine = {
  brand: "",
  duration: 0,
  form: "",
  frequency: 0,
  frequencyType: "",
  genericName: "",
  id: null,
  meal: "",
  remark: "",
  strength: "",
  times: ""
};

export default function ProductMedication({
  productMedication,
  onChange
}: ProductSelectProps): JSX.Element {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = React.useState<ProductInterface>(null);
  const [showCreatePanel, setShowCreatePanel] = React.useState<boolean>(false);
  const [productName, setProductName] = React.useState<string>("");
  const [stockProducts, setStockProducts] = React.useState<ProductNewInterface[]>([]);
  const [openMedicationDialog, setOpenMedicationDialog] = React.useState<boolean>(false);
  const isMobileScreen = useMobileScreen();
  const textChangeHandler = async ({ target }) => {
    try {
      const query = (target.value || "").trim();
      if (query.length > 2) {
        const productList = (await getStockProducts({
          search: query,
          transactionType: 1,
          limit: 20,
          intangible: false,
          excludeStockAndRates: true
        })) as ProductInterface[];
        if (productList?.length) {
          setStockProducts(productList as ProductNewInterface[]);
        } else {
          setStockProducts([]);
        }
      }
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Sorry !, something went wrong while getting products.",
          autoTimeout: true
        })
      );
    }
  };

  const handleModalSave = (data) => {
    if (data) {
      onChange([...productMedication, data]);
      setOpenMedicationDialog(false);
      setSelectedProduct(null);
      setStockProducts([]);
    }
  };
  const assessmentLabels = useAssessmentLabel();

  return (
    <>
      <Box className={styles.assessmentRow}>
        <Box className={styles.assessmentLabel}>
          <Typography component="span" fontWeight="600">
            {assessmentLabels.productMedication}
          </Typography>
        </Box>
        <Box className={styles.assessmentField}>
          <Autocomplete
            options={stockProducts}
            clearOnBlur
            fullWidth
            freeSolo
            value={selectedProduct || null}
            onChange={(_, val) => {
              if (typeof val === "string") {
                return;
              }
              if (val?.inputValue) {
                setShowCreatePanel(true);
                setProductName(val.inputValue);
              } else if (val) {
                setOpenMedicationDialog(true);
                setSelectedProduct(val);
              }
            }}
            filterOptions={(options, params) => {
              if (params.inputValue !== "") {
                options.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`
                } as ProductNewInterface);
              }
              return options;
            }}
            renderInput={(params) => (
              <DebouncedTextField
                debounceAt={1000}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                className="stock_item_autocomplete"
                placeholder="Select Medication Products"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onFocus={(e) => e.target.select()}
                onChange={textChangeHandler}
              />
            )}
            renderOption={(ownProps, option) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...ownProps}
                key={option.id || "customFilterKey"}
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
                onClick={(e) => {
                  ownProps.onClick(e);
                  e.stopPropagation();
                }}
                onKeyDown={null}
              >
                <Typography style={{ width: "80%" }}>{option?.name}</Typography>
              </li>
            )}
            getOptionLabel={(option) => option.name}
          />
        </Box>
      </Box>

      <MedicationDialog
        handleCancel={() => {
          setOpenMedicationDialog(false);
          setStockProducts([]);
        }}
        handleModalSave={handleModalSave}
        modalIsOpen={openMedicationDialog}
        data={{
          ...currentMedicineData,
          brand: selectedProduct?.name,
          id: selectedProduct?.id,
          strength: selectedProduct?.structuredInfo?.strength || "",
          form: selectedProduct?.structuredInfo?.form || "",
          genericName: selectedProduct?.structuredInfo?.genericName || ""
        }}
        isProductMedication
      />

      {showCreatePanel && (
        <Panel onClose={() => setShowCreatePanel(false)} title="Create Product">
          <CreateStockProduct
            productName={productName}
            handleClose={() => setShowCreatePanel(false)}
            saveAction={(savedItem) => {
              if (savedItem) {
                setOpenMedicationDialog(true);
                setSelectedProduct(savedItem);
              }
            }}
            mode="Create"
          />
        </Panel>
      )}

      <>
        <Box className={styles.medicationRow}>
          <Box className={styles.medicationLabel} />
          <Box className={styles.medicationField}>
            {productMedication?.map((item, index) => (
              <div key={item?.id + item?.duration}>
                <Grid container>
                  <Grid item xs={10} sm={4}>
                    <Box display="flex" alignItems="center" style={{ wordBreak: "break-word" }}>
                      <Box pt={1}>
                        <Icon style={{ color: "black" }}>
                          {item.form ? medicineType[item.form]() : medicineType.Tablet()}
                        </Icon>
                      </Box>
                      <Box pl={2}>
                        <Box fontSize="1.02rem" fontWeight="600">
                          {`${item.brand} ${item.strength} `}
                        </Box>
                        <Box>{item.genericName}</Box>
                        {item.remark && (
                          <Typography>
                            <Box component="span">
                              <b>{tl("assessment.medication.remark")}</b> {item.remark}
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {isMobileScreen && (
                    <Grid item xs={2}>
                      <Box pt={1}>
                        <HighlightOffIcon
                          style={{
                            padding: 0,
                            float: "right",
                            cursor: "pointer",
                            marginRight: 8
                          }}
                          onClick={() => {
                            const updatedMedicineData = cloneDeep(productMedication);
                            updatedMedicineData.splice(index, 1);
                            onChange(updatedMedicineData);
                          }}
                        >
                          X
                        </HighlightOffIcon>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={6} sm={3}>
                    <Box pt={1}>
                      <div>
                        {item.frequency === 0 ? "" : `${item.frequency} ${t("assessment.times")}`}
                        {showTimeUnit(item.times)}
                        <br />
                        {showMeal(item.meal)}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Box pt={1}>
                      {item.frequency !== 0 &&
                        `${
                          countableTypes.includes(item.form)
                            ? item.frequency *
                              getMedicineMultiplier(item.times, item.duration, item.frequencyType)
                            : ""
                        } ${item.form} (${item.duration} ${showFrequencyUnit(
                          item.frequencyType,
                          item.duration
                        )})`}
                    </Box>
                  </Grid>
                  {!isMobileScreen && (
                    <Grid item xs={2}>
                      <Box pt={1}>
                        <HighlightOffIcon
                          style={{
                            padding: 0,
                            float: "right",
                            cursor: "pointer",
                            marginRight: 8
                          }}
                          onClick={() => {
                            const updatedMedicineData = cloneDeep(productMedication);
                            updatedMedicineData.splice(index, 1);
                            onChange(updatedMedicineData);
                          }}
                        >
                          X
                        </HighlightOffIcon>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </div>
            ))}
          </Box>
        </Box>
      </>
    </>
  );
}

import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { bsMonths } from "../../../components/Calendar/functions/calendarData";
import { RootState } from "../../../store";
import { tl, t } from "../../../components/translate";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";

export default function CalendarSettings({
  resourceCentre,
  updateRc
}: {
  resourceCentre: ResourceCentre;
  updateRc: (rc: ResourceCentre) => void;
}): JSX.Element {
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext?.userCreds?.userGroups[0]
  );
  const disableEditCalendarAndMonth = !["superAdmin"].includes(permissionGroup);

  const typesOfCalendar = [
    { value: "ad", label: "Gergorian Calendar(AD)" },
    { value: "bs", label: "Bikram Sambat(BS)" }
  ];

  const months = resourceCentre.localCalendar ? bsMonths(t) : moment.months();

  return (
    <Box mt={2}>
      <TextField
        disabled={disableEditCalendarAndMonth}
        sx={{ width: "400px", mb: "20px" }}
        variant="outlined"
        label={tl("accounts.primaryCalendar")}
        select
        value={resourceCentre.localCalendar ? "bs" : "ad"}
        onChange={({ target }) => {
          updateRc({ ...resourceCentre, localCalendar: target.value === "bs" });
        }}
      >
        {typesOfCalendar.map((calendar) => (
          <MenuItem key={calendar.value} value={calendar.value}>
            {calendar.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        disabled={disableEditCalendarAndMonth}
        sx={{ width: "400px", marginLeft: "10px" }}
        variant="outlined"
        label={tl("accounts.startingMonth")}
        select
        value={resourceCentre.fiscalStartMonth || 0}
        onChange={({ target }) => {
          updateRc({ ...resourceCentre, fiscalStartMonth: Number(target.value) });
        }}
      >
        {months.map((calendar, index) => (
          <MenuItem key={calendar} value={index}>
            {calendar}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import useCan from "../../hooks/useCan";
import { getSubscriptionInvoices } from "../../slices/subscriptionSlice";
import { RootState } from "../../store";
import ClinicSelect from "../Reports/SummaryReport/ClinicSelect";
import SubscriptionInvoices from "../ResourceCentre/SubscriptionInvoices";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";

type FilterStateType = "all" | "paid" | "unpaid" | "pendingApproval";

export default function ResourceCentreInvoices({
  resourceCentre
}: {
  resourceCentre?: ResourceCentre;
}): JSX.Element {
  const resourceCentreId =
    useSelector((state: RootState) => state.userContext?.resourceCentre?.id) || resourceCentre?.id;
  const showClinicFilter = useCan("deny", { superAdminPass: true, supportAccountAdminPass: true });

  const [rcId, setRcId] = React.useState(resourceCentreId);

  const invoices = useSelector((state: RootState) => state.subscriptions.subscriptionInvoices);

  const [statusFilter, setStatusFilter] = React.useState<FilterStateType>("all");

  const getFormattedList = () =>
    statusFilter === "all"
      ? invoices
      : invoices.filter((item) => item.status === (statusFilter as string));

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSubscriptionInvoices(resourceCentreId));
  }, [dispatch, resourceCentreId]);

  return (
    <div>
      {showClinicFilter && (
        <Box width="100%" px={3} display="flex">
          <Box width="400px">
            <ClinicSelect
              onChange={(v) => {
                setRcId(v?.id);
              }}
              modifier={undefined}
            />
          </Box>
          <Box width="600px" ml="32px">
            <FormControl component="fieldset">
              <FormLabel component="legend">Status Filter</FormLabel>
              <RadioGroup
                row
                aria-label="filter"
                name="row-radio-buttons-group"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value as FilterStateType);
                }}
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel value="paid" control={<Radio />} label="Paid" />
                <FormControlLabel value="unpaid" control={<Radio />} label="Unpaid" />
                <FormControlLabel
                  value="pendingApproval"
                  control={<Radio />}
                  label="Pending Approval"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
      <SubscriptionInvoices
        resourceCentreId={rcId || resourceCentreId}
        invoices={getFormattedList()}
      />
    </div>
  );
}

import React from "react";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import Filters from "./Filters";
import { notificationAdd } from "../../actions/notification";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { getValue } from "../../helpers/formatters";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import {
  convertADtoBS,
  endOfMonth,
  startOfMonth
} from "../../components/Calendar/functions/calendarFunctions";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import { getStockLedgerReport } from "../../api/reports";
import { TransactionType } from "../../interfaces/StockInterfaces";
import { ReverseMap } from "../../helpers/types";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";

interface StockLedgerData {
  createdAt: string;
  invoiceNo: string;
  batchId: string;
  narration: string;
  stockIn: number;
  stockOut: number;
  balanceUnit: number;
  transactionType: ReverseMap<typeof TransactionType>;
}

const docColumns = [
  "Date",
  "Invoice No",
  "Batch No.",
  "Narration",
  "Transaction Type",
  "Stock In Units",
  "Stock Out Units",
  "Balance Units"
];
const docRowProcessor = ({
  createdAt,
  invoiceNo,
  batchId,
  narration,
  transactionType,
  stockOut,
  stockIn,
  balanceUnit,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
Partial<StockLedgerData & { __meta__row_type: string }>) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    createdAt || "",
    invoiceNo || "",
    batchId || "",
    narration,
    transactionType,
    stockIn,
    stockOut,
    balanceUnit
  ].map((item) => getValue(`${item}`));
};
const StockLedgerReport = (): JSX.Element => {
  const [filters, setFilters] = React.useState({
    from: startOfMonth(new Date()).toDate(),
    until: endOfMonth(new Date()).toDate(),
    productItems: null
  });

  const dispatch = useDispatch();
  const [stockLedgerData, setStockLedgerData] = React.useState<StockLedgerData[]>([]);

  const totals = stockLedgerData.reduce(
    (prev, cur) => ({
      stockIn: Math.floor(prev.stockIn + cur.stockIn),
      stockOut: Math.floor(prev.stockOut + cur.stockOut),
      balanceUnit: Math.floor(prev.stockIn + cur.stockIn) - Math.floor(prev.stockOut + cur.stockOut)
    }),
    {
      stockIn: 0,
      stockOut: 0,
      balanceUnit: 0
    }
  );

  React.useEffect(() => {
    if (filters.productItems) {
      (async () => {
        try {
          const response = await getStockLedgerReport(filters);
          setStockLedgerData(response);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              variant: "error",
              message: error?.data?.message || errorFetchMessage(MODULE.STOCK_LEDGER_REPORT),
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, [filters, dispatch]);
  const { isRnWebView } = useIsReactNativeWebView();

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1000px" : "auto"}>
        {" "}
        <Box m={4}>
          <Filters
            filters={filters}
            onSetFilters={(newFilters) => {
              if (newFilters.productItems) {
                setFilters(newFilters);
              } else {
                dispatch(
                  notificationAdd({
                    id: new Date().getTime(),
                    variant: "info",
                    message: "Please select a product.",
                    autoTimeout: true
                  })
                );
              }
            }}
            disableMonthsBefore
            showMonthOnly
            singularProduct
          />
        </Box>
        <Box className="stockLedgerReport">
          <List<StockLedgerData>
            automation="stockLedgerReportList"
            data={stockLedgerData}
            rowHeight={50}
            adjustHeightToContents
            columns={[
              {
                key: "createdAt",
                label: "date",
                formatter: ({ createdAt }) => (
                  <Typography>
                    {createdAt ? convertADtoBS(moment(createdAt)).formatted4 : ""}
                  </Typography>
                )
              },
              {
                key: "invoiceNo",
                label: "invoiceNo",
                formatter: ({ invoiceNo }) => <Typography>{invoiceNo || ""}</Typography>
              },
              {
                key: "batchId",
                label: tl("reports.batchId"),
                formatter: ({ batchId }) => <Typography>{batchId || ""}</Typography>
              },
              {
                key: "narration",
                label: "Narration",
                formatter: ({ narration }) => <Typography>{startCase(narration || "")}</Typography>
              },
              {
                key: "transactionType",
                label: "Transaction Type",
                formatter: ({ transactionType }) => (
                  <Typography>{startCase(transactionType || "")}</Typography>
                )
              },
              {
                key: "stockIn",
                label: "Stock In",
                formatter: ({ stockIn }) => <Typography>{stockIn}</Typography>
              },
              {
                key: "stockOut",
                label: "Stock Out",
                formatter: ({ stockOut }) => <Typography>{stockOut}</Typography>
              },
              {
                key: "balanceUnit",
                label: "Balance Unit",
                formatter: ({ balanceUnit }) => <Typography>{balanceUnit}</Typography>
              }
            ]}
          >
            <EmptyView>
              <Box textAlign="center" padding="50px">
                No items to show.
              </Box>
            </EmptyView>
            <ListActions>
              {({ getProcessedData }) => (
                <Menu>
                  <MenuItem
                    onClick={async () => {
                      try {
                        await downloadExcel(
                          "Stock Summary Report",
                          formatHeaderForExcel(docColumns),
                          formatDataForExcel(getProcessedData(), docRowProcessor)
                        );
                      } catch (err) {
                        dispatch(
                          notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: "Failed to download Excel.",
                            autoTimeout: true
                          })
                        );
                      }
                    }}
                  >
                    {tl("reports.excel")}
                  </MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
          <Box className="totalBar" px={3}>
            <Typography display="flex" flexGrow={1} flexBasis="1150px">
              {t("reports.total")}
            </Typography>
            <Typography flexBasis="250px" textAlign="right">
              {totals.stockIn}
            </Typography>
            <Typography flexBasis="250px" textAlign="right">
              {totals.stockOut}
            </Typography>
            <Typography flexBasis="250px" textAlign="right">
              {totals.balanceUnit}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StockLedgerReport;

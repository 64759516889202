import React from "react";
import { useSelector, useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import produce from "immer";
import { Box, Tooltip, Typography } from "@mui/material";
import styles from "../Assessment.module.css";
import { RootState } from "../../../store";
import { getServices } from "../../../actions/services";
import AddLabService from "./AddLabService";
import { ProductType } from "../../../interfaces/ProductInterface";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

const filterActiveLabService = (services) =>
  services.filter(
    (service) =>
      service.active && service.category === ProductType.labTest && !service.publicBooking
  );

interface LabServiceProps {
  assessmentServices: ServiceInterface[];
  onChange: (v) => void;
  onRemove: (v) => void;
  assessmentId: number | null;
}

export default function LabService({
  assessmentServices,
  onChange,
  onRemove,
  assessmentId
}: LabServiceProps): JSX.Element {
  const dispatch = useDispatch();
  const assessmentLabels = useAssessmentLabel();
  React.useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);
  const services = useSelector((state: RootState) => state.services.collection) || [];
  const filteredServices = filterActiveLabService(services);

  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel} sx={{ display: "flex", alignItems: "center" }}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.service}
        </Typography>
        {assessmentId && (
          <Tooltip title="Bill needs to be created manually for newly added service if the related bill is already finalized">
            <InfoIcon sx={{ color: "#676766" }} />
          </Tooltip>
        )}
      </Box>
      <Box className={styles.assessmentField}>
        <AddLabService
          labServices={filteredServices}
          handleAddServices={(v) => {
            onChange(v);
          }}
          services={assessmentServices}
          handleRemoveServices={(i) => {
            const mutatedServices = produce(assessmentServices, (draft) => {
              draft.splice(i, 1);
            });
            onRemove(mutatedServices);
          }}
        />
      </Box>
    </Box>
  );
}

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Authorize from '../../../auth/authorization';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { tl } from '../../../components/translate';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import * as moment from 'moment';
import Typography from '@mui/material/Typography';
import ServiceProviderAutoSelect from '../../ServiceProvider/ServiceProviderAutoSelect';
import useMobileScreen from '../../../hooks/useMobileScreen';
import styles from '../styles.module.css';
import { Box } from '@mui/material';

const CalendarHeader = ({
	user, serviceProviderId, resourceCentreId, selectedDate,
	getMonthYearForTheWeek, focusToday, onServiceProviderSelect, getFullDate,
	navigateTo, goBefore, goAfter }) => {
	const isMobileScreen = useMobileScreen();
	return <div className={styles.weekcalendarActionBar}>
		<Grid container>
			<Grid item xs={12} sm={6} md={6} lg={5} className={styles.weekcalendarActionBarLeft}>
				<Grid container>
					<Grid item xs={6} lg={5}>
						{Authorize(user).can('admin', 'Slot', {
							serviceProviderId,
							resourceCentreId,
						}) && (
								<Button
									variant="outlined"
									color="primary"
									onClick={() => navigateTo('/schedule/addSlots')}
									data-testmation="booking.addSlots"
								>
									{tl('booking.addSlots')}
									<AddIcon />
								</Button>
							)}
					</Grid>
					<Grid item xs={6} lg={7} data-testmation="serviceProviderSelector">
						<ServiceProviderAutoSelect
							resourceCentreId={resourceCentreId}
							serviceProviderId={sessionStorage.getItem('spID')}
							onChange={(serviceProviderId) => {
								onServiceProviderSelect(serviceProviderId);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			{!isMobileScreen && <Grid item xs={12} sm={3} lg={4}>
				<Typography variant="h6" gutterBottom align="center">
					{getMonthYearForTheWeek()}
				</Typography>
			</Grid>
			}
			<Grid item xs={12} md={3} lg={3} className={styles.weekcalendarActionBarRight}>
				<Box display="flex">
					{!moment(selectedDate).isSame(moment(), 'day') && (
						<Button variant="outlined" onClick={() => focusToday()}>
							{tl('booking.today')}
						</Button>
					)}
					<Button
						onClick={async () => {
							await navigateTo('/schedule');
							goBefore(1, isMobileScreen ? 'day' : 'week');
						}}
					>
						<KeyboardArrowLeft />
					</Button>
					{isMobileScreen &&
						<Box flexGrow="1" textAlign="center">
							<Typography component="span">
								<Box className={
									moment(selectedDate).day() === 6 ? styles.colorRed : ''
								} component="span" fontSize="1rem" fontWeight="500"> {getFullDate()}</Box>
							</Typography>
						</Box>
					}
					<Button
						onClick={async () => {
							await navigateTo('/schedule');
							goAfter(1, isMobileScreen ? 'day' : 'week');
						}}
					>
						<KeyboardArrowRight />
					</Button>
				</Box>

			</Grid>
		</Grid>
	</div>
};

export default CalendarHeader;
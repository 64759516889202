import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import { push } from "connected-react-router";
import { useEffect } from "react";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import {
  cancelSubscription,
  getCurrentSubscription,
  getHistory,
  getInvoices,
  getSMSHistory,
  getSubscriptionTemplates,
  updateDraftSubscription
} from "../../../slices/subscriptionSlice";
import { RootState } from "../../../store";
import ChooseSubscriptionDialog from "./ChooseSubscriptionDialog";
import TerminateSubscriptionDialog from "./TerminateSubscriptionDialog";
import { PackageTypes } from "../../../interfaces/Subscription";
import { t, tl } from "../../../components/translate";
import SectionHeading from "../SectionHeading";
import useQueryParams from "../../../hooks/useQueryParams";
import { ReverseMap } from "../../../helpers/types";

interface Props {
  resourceCentre: ResourceCentre;
  canAccessSettingsAndSubscriptions: boolean;
}

export const customForm = {
  id: `${Math.floor(Math.random() * 10)}z`,
  isTemplate: false,
  activeSubscription: true,
  resourceCentreId: null,
  packageName: "Custom",
  packageBasedOn: null,
  isCustomised: true,
  trialUntil: null,
  subscriptionStart: null,
  subscriptionEnd: new Date(moment().add(1, "y").toISOString()),
  subscriptionMonthlyPrice: 1500,
  subscriptionYearlyPrice: 12000,
  packageType: PackageTypes.Custom,
  paid: false,
  features: {
    clients: { subscribed: true, limit: 200, default: true, nonModule: true },
    booking: { subscribed: true },
    bill: { subscribed: true, default: true },
    lab: { subscribed: true },
    users: { subscribed: true, limit: 5, default: true, nonModule: true },
    stock: { subscribed: true },
    campaign: { subscribed: true },
    report: { subscribed: true, default: true },
    doctors: { subscribed: true, default: true, nonModule: true },
    services: { subscribed: true, default: true, nonModule: true },
    ipd: { subscribed: true },
    clinic: { subscribed: true, default: true, nonModule: true },
    medical: { subscribed: true },
    hmis: { subscribed: true },
    choiceModules: 9999,
    account: { subscribed: false },
    opd: { subscribed: true }
  },
  createdBy: null,
  notes: null
};

enum TAB_ITEMS {
  CURRENT_PLAN = "current plan",
  SMS = "sms",
  HISTORY = "history"
}

type TabItemType = ReverseMap<typeof TAB_ITEMS>;

function getOptions(templates, currentSubscription) {
  const ret = [...templates];
  if (currentSubscription?.packageType !== "Custom") {
    ret.push(customForm);
  }
  return ret.filter((el) => el.packageType !== currentSubscription?.packageType);
}

const getTabValue = (value) => {
  switch (value) {
    case TAB_ITEMS.HISTORY:
      return TAB_ITEMS.HISTORY;
    case TAB_ITEMS.SMS:
      return TAB_ITEMS.SMS;
    default:
      return TAB_ITEMS.CURRENT_PLAN;
  }
};

export default function Subscriptions({
  resourceCentre,
  canAccessSettingsAndSubscriptions
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const query = useQueryParams();
  const tabParam = query.get("tab");
  const [tab, setTab] = React.useState<TabItemType>(() => getTabValue(tabParam));

  const currentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );

  const smsHistory = useSelector((state: RootState) => state.subscriptions.smsHistory);

  const templates = useSelector((state: RootState) =>
    state.subscriptions.templates.map((template) => ({
      ...template,
      subscriptionEnd: new Date(moment().add(1, "y").toISOString())
    }))
  );

  const history = useSelector((state: RootState) => state.subscriptions.history);
  const [showModal, setShowModal] = React.useState(false);
  const [showTerminateModal, setShowTerminateModal] = React.useState(false);
  const [templateChanged, setTemplateChanged] = React.useState(false);

  React.useEffect(() => {
    dispatch(getSubscriptionTemplates());
    dispatch(getCurrentSubscription(resourceCentre.id));
    dispatch(getInvoices(resourceCentre.id));
    dispatch(getHistory(resourceCentre.id));
    dispatch(getSMSHistory(resourceCentre.id));
  }, [dispatch, resourceCentre.id]);

  useEffect(() => {
    if (tabParam) setTab(getTabValue(tabParam));
  }, [tabParam]);

  return (
    <>
      <Box width="100%">
        <SectionHeading name={tl("subscriptionInformation")} />
        <Box sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>
          <Tabs
            value={tab}
            onChange={(_, newValue) => {
              dispatch(push(`/resourcecentres/${resourceCentre.id}/subscriptions?tab=${newValue}`));
            }}
          >
            {Object.values(TAB_ITEMS).map((item) => (
              <Tab value={item} label={item} key={item} />
            ))}
          </Tabs>
        </Box>
        <Box pt={2}>
          {tab === TAB_ITEMS.CURRENT_PLAN && (
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="h5">{`Name: ${
                  currentSubscription?.packageName || "None"
                }`}</Typography>
                {canAccessSettingsAndSubscriptions && (
                  <>
                    {!currentSubscription && (
                      <FormControl style={{ width: "240px", marginLeft: "64px" }} margin="dense">
                        <InputLabel id="demo-simple-select-label">Buy New Plan</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Template"
                          value={null}
                          onChange={(e) => {
                            setShowModal(true);
                            dispatch(
                              updateDraftSubscription({
                                ...e.target.value,
                                migrationDate: moment().startOf("day").toISOString()
                              })
                            );
                            setTemplateChanged(true);
                          }}
                        >
                          {getOptions(templates, currentSubscription).map((item) => (
                            <MenuItem key={item.id} value={item}>
                              {item.packageName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {currentSubscription && (
                      <Box display="flex" ml="32px">
                        {currentSubscription?.isCustomised && (
                          <Typography
                            data-testmation="modifySubscription"
                            style={{
                              textDecoration: "underline",
                              marginRight: "32px",
                              cursor: "pointer"
                            }}
                            color="primary"
                            onClick={() => {
                              setShowModal(true);
                              dispatch(
                                updateDraftSubscription({
                                  ...currentSubscription,
                                  migrationDate: resourceCentre.accountsOpeningDate
                                    ? moment(resourceCentre.accountsOpeningDate)
                                        .tz("Asia/Kathmandu")
                                        .startOf("day")
                                        .toISOString()
                                    : moment().tz("Asia/Kathmandu").startOf("day").toISOString()
                                })
                              );
                              setTemplateChanged(false);
                            }}
                          >
                            {tl("modifySubscription")}
                          </Typography>
                        )}
                        <Typography
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                          color="primary"
                          onClick={() => setShowTerminateModal(true)}
                        >
                          {tl("terminateSubscription")}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>

              {currentSubscription && (
                <Box mt="32px">
                  <Box display="flex" flexDirection="column" mt="32px" rowGap="6px">
                    <Box display="flex">
                      <Typography style={{ width: "200px" }}>{tl("planStart")}</Typography>
                      <Typography>
                        {moment(currentSubscription?.subscriptionStart).format("YYYY-MM-DD")}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography style={{ width: "200px" }}>{tl("validTill")}</Typography>
                      <Typography>
                        {moment(currentSubscription?.subscriptionEnd).format("YYYY-MM-DD")}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography style={{ width: "200px" }}>{t("Subscription.status")}</Typography>
                      <Typography>Active</Typography>
                    </Box>
                    <Box display="flex">
                      <Typography style={{ width: "200px" }}>
                        {t("Subscription.paymentPlan")}
                      </Typography>
                      <Typography>
                        {currentSubscription?.paymentPrice} / {currentSubscription?.paymentType}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography style={{ width: "200px" }}>{t("trialMode")}</Typography>
                      <Typography>{Boolean(currentSubscription.trialMode).toString()}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography style={{ width: "200px" }}>{t("salesPerson")}</Typography>
                      <Typography>{currentSubscription?.salesPerson}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography style={{ width: "200px" }}>{t("leadBy")}</Typography>
                      <Typography>{currentSubscription?.leadBy}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography style={{ width: "200px", flexGrow: "0", flexShrink: "0" }}>
                        {tl("MOUlink")}
                      </Typography>
                      <a
                        href={currentSubscription?.mouLink}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Typography>{currentSubscription?.mouLink}</Typography>
                      </a>
                    </Box>
                  </Box>
                  <Typography variant="h5" marginTop="1rem">
                    {tl("subscriptions.Features")}
                  </Typography>
                  <Paper style={{ padding: "1rem", marginTop: "1rem" }} elevation={6}>
                    {currentSubscription &&
                      Object.keys(currentSubscription.features).map((module) => {
                        if (module === "choiceModules") return null;
                        if (currentSubscription.features[module].subscribed) {
                          return (
                            <Typography
                              key={module}
                              style={{ paddingTop: "4px", borderBottom: "1px solid lightgrey" }}
                            >
                              {startCase(module)}
                              {currentSubscription.features[module].limit &&
                                `(${currentSubscription.features[module].limit})`}
                            </Typography>
                          );
                        }
                        return null;
                      })}
                  </Paper>
                </Box>
              )}
            </>
          )}
          {tab === TAB_ITEMS.SMS && (
            <Box mt="8px">
              {currentSubscription && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "2rem",
                      flexWrap: "wrap"
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography variant="h5">
                        {resourceCentre.subscriptions.features.sms.volumeLeft}
                      </Typography>
                      <Typography fontWeight={700}>{tl("resourceCentre.smsLeft")}</Typography>
                    </Box>
                    <Button
                      onClick={() =>
                        dispatch(
                          push(
                            `/resourcecentres/${resourceCentre.id}/payments?filter=sms&openDialog=true`
                          )
                        )
                      }
                      variant="contained"
                    >
                      {tl("purchaseSMS")}
                    </Button>
                  </Box>
                  <Typography gutterBottom mt="2rem" mb="1rem" fontWeight={700}>
                    {tl("SMSHistory")}
                  </Typography>
                  <Box
                    sx={{
                      mt: "1rem",
                      maxHeight: "30rem",
                      height: "100%",
                      overflow: "auto",
                      maxWidth: "30rem",
                      width: "100%"
                    }}
                  >
                    {smsHistory
                      .map((item) => (
                        <Typography key={item.id} fontWeight={400}>
                          {item.infoData.volume} sms added on{" "}
                          {moment(item.created_at).format("DD-MM-YYYY")}.
                        </Typography>
                      ))
                      .reverse()}
                  </Box>
                </>
              )}
            </Box>
          )}
          {tab === TAB_ITEMS.HISTORY && (
            <Box mt="8px">
              {history.map((historyItem) => (
                <Box display="flex" key={historyItem.id}>
                  <Typography style={{ width: "160px" }}>
                    {moment(historyItem.created_at).format("YYYY-MM-DD")}
                  </Typography>
                  <Typography style={{ width: "240px" }}>{startCase(historyItem.event)}</Typography>
                  <Typography>by Super Admin</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <ChooseSubscriptionDialog
        showModal={showModal}
        setShowModal={setShowModal}
        templateChanged={templateChanged}
        resourceCentre={resourceCentre}
      />

      <TerminateSubscriptionDialog
        open={showTerminateModal}
        handleClose={() => setShowTerminateModal(false)}
        onTerminate={() => {
          dispatch(cancelSubscription(currentSubscription.id));
        }}
      />
    </>
  );
}

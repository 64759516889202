import { storageAvailable } from './localStorage';

const sessionStorageObj = () => {
  return storageAvailable('sessionStorage')
    ? window.sessionStorage
    : {
        setItem: () => null,
        getItem: () => null,
        removeItem: () => null
      };
};

export default sessionStorageObj();

import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import Panel from "../../../components/Panel";
import { tl } from "../../../components/translate";
import { DocumentInterface } from "../../../interfaces/AccountReportsInterface";
import { TransactionType } from "../../../interfaces/Accounts";

interface ReportDetailView {
  data: DocumentInterface;
  handleViewClose: () => void;
  generalLedgerReport?: boolean;
}

const header = ["Ledger Name", "Debit", "Credit"];
const Header = () => (
  <Box display="flex" borderBottom="0.5px solid grey">
    {header.map((item) => (
      <Typography
        sx={{
          "&:first-child": {
            flexGrow: 1
          },
          flexBasis: "100px"
        }}
        p="5px"
        key={item}
        fontWeight="bold"
      >
        {item}
      </Typography>
    ))}
  </Box>
);

export default function ReportDetailView({
  data,
  handleViewClose,
  generalLedgerReport
}: ReportDetailView): JSX.Element {
  const voucherDetails = [
    { label: tl("accounts.voucherNumber"), value: data.voucherNumber },
    {
      label: tl("stock.date"),
      value: data.date ? convertADtoBS(new Date(data.date)).formatted4 : ""
    },
    { label: tl("reports.balance"), value: data.runningBalance },
    {
      label: tl("accounts.destinationLedgerName"),
      value: generalLedgerReport ? data.destinationLedgerName : data.subLedgerName
    }
  ];

  return (
    <Panel onClose={handleViewClose} title="Details">
      <Box m={5}>
        {voucherDetails.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box display="flex" key={index}>
            <Typography fontSize="medium" width="200px">
              {item.label}
            </Typography>
            <Typography>{item.value || ""}</Typography>
          </Box>
        ))}
      </Box>
      {data.document?.length ? (
        <Paper sx={{ margin: "40px" }}>
          <Header />
          {data.document?.map((item, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              display="flex"
            >
              <Typography
                sx={{
                  "&:first-child": {
                    flexGrow: 1
                  }
                }}
                p="5px"
              >
                {item.ledgerName}
              </Typography>
              <Typography p="5px" width="100px">
                {item.transactionType === TransactionType.DEBIT && item.amount}
              </Typography>
              <Typography p="5px" width="100px">
                {item.transactionType === TransactionType.CREDIT && item.amount}
              </Typography>
            </Box>
          ))}
        </Paper>
      ) : (
        <Typography ml={5}>There is no document in this voucher.</Typography>
      )}
      <Box m={5} display="flex">
        <Typography fontSize="medium" width="200px">
          {tl("accounts.narration")}
        </Typography>
        <Typography>{data.remarks || ""}</Typography>
      </Box>
    </Panel>
  );
}

ReportDetailView.defaultProps = {
  generalLedgerReport: false
};

import { Autocomplete, Box, TextField } from "@mui/material";
import { startCase } from "lodash";
import * as React from "react";
import { StockItemInterface, TransactionType } from "../../../../interfaces/StockInterfaces";
import { tl } from "../../../../components/translate";

interface Props {
  value: StockItemInterface;
  options: Array<string>;
  transactionType: TransactionType;
  onBatchUpdate: (v) => void;
  error: boolean;
  batchRef?: React.RefObject<HTMLInputElement> | null;
}

export default function StockBatch(props: Props): JSX.Element {
  const { value, options, transactionType, onBatchUpdate, error, batchRef = null } = props;
  React.useEffect(() => {
    if (
      [TransactionType.INTERNAL_USE, TransactionType.EXPIRY_OR_DISCARDMENT].includes(
        transactionType as TransactionType
      ) &&
      value.productId &&
      options.length
    ) {
      onBatchUpdate(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, transactionType, value.productId]);

  return (
    <div>
      <Box>
        {[
          TransactionType.PURCHASE,
          TransactionType.OPENING_STOCK,
          TransactionType.EDIT_OPENING_STOCK
        ].includes(transactionType as TransactionType) ? (
          <Autocomplete
            data-testmation="batchInput"
            value={value.batchId}
            options={options}
            freeSolo
            disabled={[
              TransactionType.EDIT_PURCHASE,
              TransactionType.EDIT_OPENING_STOCK,
              TransactionType.ADJUSTMENT
            ].includes(transactionType as TransactionType)}
            getOptionLabel={(option) => startCase(option.toString())}
            isOptionEqualToValue={(option, v) => option === v}
            renderInput={(params) => (
              <TextField
                error={error}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                margin="dense"
                label={tl("StockEntry.Batch")}
                onChange={(e) => {
                  onBatchUpdate(e.target.value.toUpperCase());
                }}
                inputRef={batchRef}
              />
            )}
            onChange={(_, v) => {
              onBatchUpdate(v);
            }}
          />
        ) : (
          <Autocomplete
            disabled={[
              TransactionType.EDIT_PURCHASE,
              TransactionType.EDIT_OPENING_STOCK,
              TransactionType.ADJUSTMENT,
              TransactionType.PURCHASE_RETURN
            ].includes(transactionType as TransactionType)}
            value={value.batchId}
            freeSolo
            options={options}
            getOptionLabel={(option) => startCase(option)}
            isOptionEqualToValue={(option, v) => option === v}
            renderInput={(params) => (
              <TextField
                data-testmation="batch"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                placeholder="Select batch"
                variant="outlined"
                label="Batch"
                margin="dense"
                onChange={(e) => onBatchUpdate(e.target.value)}
                inputRef={batchRef}
              />
            )}
            onChange={(_, v) => {
              onBatchUpdate(v);
            }}
          />
        )}
      </Box>
    </div>
  );
}

StockBatch.defaultProps = {
  batchRef: null
};

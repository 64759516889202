import React from "react";

export const Vial = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 512 512"
    >
     <g>
	<g>
		<path d="M312.221,118.197V96.754h5.239c16.143,0,29.278-13.135,29.278-29.278V10.199C346.738,4.567,342.171,0,336.539,0H175.461
			c-5.632,0-10.199,4.567-10.199,10.199v57.277c0,16.143,13.135,29.278,29.278,29.278h5.239v21.442
			c-28.316,0.323-51.252,23.459-51.252,51.851v290.1c0,28.592,23.261,51.854,51.853,51.854H311.62
			c28.591,0,51.853-23.261,51.853-51.854V170.047C363.473,141.656,340.537,118.52,312.221,118.197z M185.66,20.398h140.68V38.7
			H185.66V20.398z M343.075,352.897v107.25c0,17.345-14.111,31.455-31.454,31.455H200.38c-17.344,0-31.454-14.111-31.454-31.455
			V268.324c24.354,3.137,53.163,12.121,70.506,35.579c30.627,41.424,65.179,48.014,103.643,48.993V352.897z M343.075,332.505
			c-35.503-0.859-61.785-6.298-87.241-40.729c-22.162-29.977-57.853-40.652-86.908-44.005v-77.723
			c0-17.345,14.111-31.455,31.454-31.455h9.598c5.632,0,10.199-4.567,10.199-10.199V86.555c0-5.632-4.567-10.199-10.199-10.199
			H194.54c-4.897,0-8.879-3.983-8.879-8.879v-8.378h140.68v8.378c0,4.897-3.983,8.879-8.879,8.879h-15.438
			c-5.632,0-10.199,4.567-10.199,10.199v41.838c0,5.632,4.567,10.199,10.199,10.199h9.598c17.344,0,31.454,14.111,31.454,31.455
			V332.505z"/>
	</g>
</g>
<g>
	<g>
		<path d="M243.448,458.131h-3.137c-5.632,0-10.199,4.567-10.199,10.199c0,5.632,4.567,10.199,10.199,10.199h3.137
			c5.632,0,10.199-4.567,10.199-10.199C253.647,462.698,249.081,458.131,243.448,458.131z"/>
	</g>
</g>
<g>
	<g>
		<path d="M315.619,458.131h-42.885c-5.632,0-10.199,4.567-10.199,10.199c0,5.632,4.567,10.199,10.199,10.199h42.885
			c5.632,0,10.199-4.567,10.199-10.199C325.819,462.698,321.252,458.131,315.619,458.131z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
    </svg>
  );
};

import React from "react";
import { Typography, Box } from "@mui/material";
import { chain } from "mathjs";
import startCase from "lodash/startCase";
import "./PrintBill.scss";
import { connect } from "react-redux";
import moment from "moment";
import { BillType, DiscountBasedOn, DocumentTypes } from "../../../interfaces/BillInterfaces";
import { scaleFont } from "../../../components/Print/Print";
import { tl } from "../../../components/translate";
import classNames from "../../../helpers/classNames";
import { spFullNameSelector, spFromIdSelector } from "../../../reducers/serviceProvider";
import capitalizedName from "../../../helpers/nameCapitalizer";
import hasOwnProperty from "../../../helpers/object";
import { RootState } from "../../../store";
import {
  BillColumns,
  getBillColumns,
  showUnitAfterQuantity,
  showInfoColumn,
  marginForNameColumn,
  getColumnTextStyles
} from "../../../actions/helpers/billHelper";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import BillPrintSummary from "../BillPrintSummary";

interface Props {
  billData: BillType;
  SPs: ServiceProvider[];
  relatedSettings: { [key: string]: string };
  clientBalance: number;
  hidePaymentMethodOnPrint: boolean;
  isvatResistered: boolean;
  user: { firstName: string; lastName: string };
}

const DescribeBillV2 = ({
  billData,
  SPs,
  relatedSettings,
  isvatResistered,
  user,
  clientBalance,
  hidePaymentMethodOnPrint
}: Props) => {
  if (!billData || !billData.document) return null;
  const showInfo = showInfoColumn(billData);

  const billColumns = getBillColumns(showInfo).filter((header) => {
    if (header === BillColumns.DISCOUNT_AMT) {
      return !(
        [BillColumns.DISCOUNT_AMT].includes(header) &&
        billData.document?.settings.discountSettings.discountBasis === DiscountBasedOn.invoice
      );
    }
    if (header === BillColumns.VAT_PCT) {
      if (isvatResistered) {
        return header;
      }
      return false;
    }
    return header;
  });

  const showIndividualVAT =
    Object.keys(billData.document.summary.taxAmtWithRates || {}).filter(
      (vatPct) => Number(vatPct) !== 0
    ).length > 1;
  let netTotal;
  if (billData.document.type === DocumentTypes.CREDITNOTE) {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .add(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  } else {
    netTotal = billData.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .subtract(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  }

  return (
    <Box>
      <Box>
        <Box
          component="div"
          display="flex"
          borderTop="1px solid black"
          borderRight={relatedSettings.isBordered && "1px solid black"}
          borderBottom={relatedSettings.isBordered && "1px solid black"}
          borderLeft={relatedSettings.isBordered && "1px solid black"}
          paddingBottom="0.1cm"
          p="0.1cm"
          className={!relatedSettings.isBordered && "borderBotBlack1"}
        >
          {billColumns.map((column, i) => (
            <Box
              mx={marginForNameColumn(column)}
              key={column}
              className={classNames({
                grow1: i === 0 || i === 2,
                flex3: i === 1 || (i === 2 && showInfo),
                flex1: i > 2
              })}
            >
              <Typography
                textAlign={getColumnTextStyles({ index: i, column })}
                sx={{ textTransform: "uppercase" }}
                fontWeight={600}
                fontSize={scaleFont("0.27cm", 0.85)}
                whiteSpace="pre"
              >
                {tl(`billing.billItem.${column}`)}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box component="div" paddingBottom="0.15cm">
          {billData.document.billItems.map((item, index) => (
            <Box
              key={item.sNo}
              display="flex"
              sx={
                relatedSettings.isBordered
                  ? {
                      pageBreakInside: "avoid"
                    }
                  : {}
              }
              borderTop={relatedSettings.isBordered && index !== 0 && "0.5px solid lightgrey"}
              borderBottom={
                index === billData.document.billItems.length - 1
                  ? relatedSettings.isBordered && "1px solid black"
                  : relatedSettings.isBordered && "0.5px solid lightgrey"
              }
              className={
                index === billData.document.billItems.length - 1 &&
                !relatedSettings.isBordered &&
                "borderBotBlack1"
              }
            >
              {billColumns.map((column, i) => (
                <Box
                  borderLeft={i === 0 && relatedSettings.isBordered && "1px solid black"}
                  borderRight={
                    i === billColumns.length - 1 && relatedSettings.isBordered && "1px solid black"
                  }
                  paddingLeft={i === 0 && "0.1cm"}
                  key={column}
                  className={classNames({
                    grow1: i === 0 || i === 2,
                    flex3: i === 1 || (i === 2 && showInfo),
                    flex1: i > 2
                  })}
                  paddingTop="0.1cm"
                  paddingBottom="0.1cm"
                  mx={marginForNameColumn(column)}
                >
                  <Typography component="div">
                    <Box
                      sx={getColumnTextStyles({
                        index: i,
                        column,
                        fontSize: scaleFont("0.33cm", 0.85)
                      })}
                    >
                      {["perUnit", "grossTotal"].includes(column) && item[column] !== null
                        ? item[column].toFixed(2)
                        : item[column]}{" "}
                      {showUnitAfterQuantity(column) && item.unit}
                      {column === "description" &&
                        item.serviceProviderId &&
                        `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                    </Box>
                    {column === "description" && (
                      <Box
                        sx={{ whiteSpace: "pre-wrap", display: "inline" }}
                        fontSize={scaleFont("0.23cm", 0.85)}
                      >
                        {item.batchInfo.batchId && `Batch ${item.batchInfo.batchId}`}
                        {item.batchInfo.expiryDate &&
                          `, Expiring ${moment(item.batchInfo.expiryDate).format("YYYY/MM/DD")}`}
                      </Box>
                    )}
                    {column === "description" &&
                      item.subItems &&
                      item.subItems.map((subitem) => (
                        <Box key={subitem.id} paddingTop="0.05cm" className="blockContent">
                          {`◦ ${subitem.description}`}
                          {hasOwnProperty(subitem, "serviceProviderId") &&
                            subitem.serviceProviderId && (
                              <span style={{ marginLeft: "8px" }}>
                                {`(${spFullNameSelector(
                                  spFromIdSelector(SPs, subitem.serviceProviderId)
                                )})`}
                              </span>
                            )}
                        </Box>
                      ))}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
      <Box component="div" display="flex">
        <Box component="div" width="60%">
          {Boolean(billData.document.remarks) && (
            <Box display="flex" alignItems="flex-start" gap={1}>
              <Typography fontSize={scaleFont("0.3cm", 0.85)} flexShrink={0}>
                {tl("billing.remarks")}:
              </Typography>
              <Typography fontSize={scaleFont("0.3cm", 0.85)} flexGrow={1} fontWeight={500}>
                {billData.document.remarks}
              </Typography>
            </Box>
          )}
          {!hidePaymentMethodOnPrint && Boolean(billData?.paymentInfo?.paymentMethod) && (
            <Box display="flex" alignItems="flex-start" gap={1}>
              <Typography fontSize={scaleFont("0.3cm", 0.85)} flexShrink={0}>
                {tl("billing.paymentMethod")}:
              </Typography>
              <Typography fontSize={scaleFont("0.3cm", 0.85)} flexGrow={1} fontWeight={500}>
                {startCase(billData.paymentInfo?.paymentMethod)}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="flex-start" gap={1}>
            <Typography fontSize={scaleFont("0.3cm", 0.85)} flexShrink={0}>
              {tl("billing.inWords")}:
            </Typography>
            <Typography fontSize={scaleFont("0.3cm", 0.85)} flexGrow={1} fontWeight={500}>
              {billData.document.summary.inWords} only
            </Typography>
          </Box>
          <Box mt={1}>
            <Box display="flex" alignItems="flex-start" gap={1}>
              <Typography fontSize={scaleFont("0.3cm", 0.85)} flexShrink={0}>
                {tl("billing.printedDateTime")}:
              </Typography>
              <Typography fontSize={scaleFont("0.3cm", 0.85)} flexGrow={1} fontWeight={500}>
                {moment().format("YYYY/MM/DD")} {moment().format("h:mm a")}
              </Typography>
            </Box>
            {Boolean(billData?.printCount) && (
              <Box display="flex" alignItems="flex-start" gap={1}>
                <Typography fontSize={scaleFont("0.3cm", 0.85)} flexShrink={0}>
                  {tl("billing.rePrintUser")}:
                </Typography>
                <Typography fontSize={scaleFont("0.3cm", 0.85)} flexGrow={1} fontWeight={500}>
                  {capitalizedName(`${user?.firstName} ${user?.lastName}`)}
                </Typography>
              </Box>
            )}
            {billData.document.enteredBy.name && (
              <Box display="flex" alignItems="flex-start" gap={1}>
                <Typography fontSize={scaleFont("0.3cm", 0.85)} flexShrink={0}>
                  {tl("billing.billedBy")}:
                </Typography>
                <Typography fontSize={scaleFont("0.3cm", 0.85)} flexGrow={1} fontWeight={500}>
                  {billData.document.enteredBy.name}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box width="40%">
          <Box pb="0.1cm" width="80%" ml="auto">
            <BillPrintSummary
              billData={billData}
              clientBalance={clientBalance}
              totalPerUnitTimesQty={billData.document.summary.totalPerUnitTimesQty}
              discount={billData.document.summary.discount}
              taxAmount={billData.document.summary.taxAmount}
              netTotal={netTotal}
              showIndividualVAT={showIndividualVAT}
              taxAmtWithRates={billData.document.summary.taxAmtWithRates}
              discountPercent={billData.document.summary.discountPct}
              smallFont
              noMarginTop
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    isvatResistered: (state.userContext as Record<string, any>).resourceCentre.settings
      .billingSettings.vatRegistered
  }),
  null
)(DescribeBillV2);

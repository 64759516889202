import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { createSlots } from '../../actions/slot';
import Slot from '../Slots';
import { createEvents, transformEventsToUIFormat } from '../../helpers/events';
import ScrollCalendar from '../../components/Calendar/components/ScrollCalendar';
import { tl } from '../../components/translate';
import styles from './styles.module.css';
import Modal, { Footer } from '../../components/Modal/Modal';
import StatefulButton from '../../components/StatefulButton/StatefulButton';

interface AddSlotProps {
  serviceProvider: any;
  handleClose: () => void;
  saveSlots: (slots, serviceProviderId) => void;
  next: () => void;
}

interface AddSlotState {
  open: boolean;
  data: { key: any };
  slots: Array<any>;
  openPreview: boolean;
  isSaving: boolean;
}

class AddSlot extends React.Component<AddSlotProps, AddSlotState> {
  state = {
    open: true,
    data: {},
    openPreview: false,
    slots: [],
    isSaving: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
    setTimeout(() => this.props.handleClose(), 120);
  }

  handleClosePreview() {
    this.setState({ openPreview: false });
  }

  removeSlot(id) {
    this.setState({
      slots: this.state.slots.map((s) => {
        if (id === s.id) {
          s.removed = !s.removed;
        }
        return s;
      }),
    });
  }

  showPreview() {
    const { fromDate, toDate, fromTime, toTime, slotDuration, every } =
      this.state.data;
    const slots = createEvents({
      fromDate,
      toDate,
      fromTime,
      toTime,
      duration: slotDuration,
      weekDays: every,
    });
    this.setState({ slots, openPreview: true });
  }

  async saveEvents() {
    await this.props.saveSlots(
      this.state.slots.filter((s) => !s.removed),
      this.props.serviceProvider.id
    );
    if (this.props.next) this.props.next();
    this.props.handleClose();
  }

  render() {
    const { data } = this.state;
    const { serviceProvider } = this.props;
    const checkStatus = data && data.every && data.every.length;
    return (
      <div>
        <Modal
          key="createSlotDialog"
          title={tl('booking.createSlots')}
          open={this.state.open}
          onClose={this.handleClose}
          footer={
            <Button
              onClick={() => this.showPreview()}
              color="primary"
              disabled={!checkStatus}
              variant="contained"
            >
              {tl('booking.preview&Save')}
            </Button>
          }
        >
          <Slot
            serviceProvider={serviceProvider}
            onChange={(data) => this.setState({ data })}
          />
        </Modal>
        <Modal
          key="previewSlotDialog"
          title={tl('booking.previewSlots')}
          open={this.state.openPreview}
          onClose={() => this.handleClosePreview()}
          footer={
            <StatefulButton
              onClick={async () => {
                this.setState({ isSaving: true });
                await this.saveEvents();
                this.setState({ isSaving: false });
              }}
              disabled={this.state.isSaving}
              color="primary"
              variant="contained"
              isLoading={this.state.isSaving}
              circularProgressProps={{ size: 16, style: { color: 'white' } }}
            >
              {tl('booking.confirm')}
            </StatefulButton>
          }
        >
          <ScrollCalendar
            onEventRemove={(id) => this.removeSlot(id)}
            events={transformEventsToUIFormat(this.state.slots, {
              owner: { name: 'Asish' },
              eventData: {},
            })}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state, { serviceProviderId }) => ({
    serviceProvider: state.resources.resourceCentreServiceProviders.find(
      (sp) => sp.id === serviceProviderId
    ),
    user: state.userContext,
  }),
  (dispatch) => ({
    saveSlots: async (slots, serviceProviderId) => {
      await dispatch(createSlots(slots, serviceProviderId));
    },
    navigateTo: (url) => {
      dispatch(push(url));
    },
  })
)(AddSlot);

import * as React from 'react';
import { Box, Checkbox, Typography, TextField } from '@mui/material';
import { tl } from '../../../components/translate';
import CalendarDropdown from '../../../components/CalendarDropdown/CalendarDropdown';
import { Moment } from 'moment';

interface FollowUp {
  value: boolean;
  reminders: Array<{}>;
  defaultDate: Date;
  maxValidDate: Moment;
  editable: boolean;
  onChange: (value, reminders) => void;
}

const FollowUp: React.FC<FollowUp> = ({ value, reminders, maxValidDate, onChange, editable }) => {
  return (
    <Box>
      <Box display="flex">
        <Typography style={{ fontSize: '12px' }}>{tl('booking.followUp')}</Typography>
        <Checkbox
          // disabled={!editable}
          style={{ height: '18px' }}
          checked={value}
          onChange={() => onChange(!value, reminders)}
          data-testmation="followUpCheck"
        />
      </Box>
      <Box display="flex" alignItems="center">
        {value &&
          reminders &&
          reminders.map((rem, i) => (
            <Reminder
              key={i}
              values={rem}
              onChange={(rem) => onChange(value, [rem])}
              maxValidDate={maxValidDate}
              editable={editable}
            />
          ))}
      </Box>
    </Box>
  );
};

const Reminder: React.FC<{
  values: any;
  maxValidDate: Moment;
  onChange: ({}) => void;
  editable: Boolean;
}> = ({ values, maxValidDate, onChange, editable }) => {
  return (
    <>
      <Box width="80px" marginRight="16px">
        <CalendarDropdown
          placeholder={'Remind on'}
          label={'Remind on'}
          maxValidDate={maxValidDate}
          date={new Date(values.on)}
          onChange={(on) => onChange({ ...values, on })}
          format={'formatted3'}
        />
      </Box>
      <TextField
        data-testmation="followUpRemarksField"
        size="small"
        InputProps={{
          style: {
            padding: 0,
            fontSize: '12px',
          },
        }}
        style={{ minWidth: '170px' }}
        margin="dense"
        label={'Remarks'}
        value={values.remark}
        onChange={({ target }) => onChange({ ...values, remark: target.value })}
      />
    </>
  );
};

export default FollowUp;

import { isRequired } from '../helpers/validators';
export const fields = [
  {
    key: 'name',
    label: 'name',
    value: '',
    inputType: 'text',
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: 'Template name is required' })]
  },
  {
    key: 'mediaType',
    label: 'type',
    value: 'sms',
    inputType: 'select',
    editable: true,
    creatable: true,
    options: [
      { value: 'sms', label: 'SMS' },
      { value: 'email', label: 'Email' }
      //   { value: 'all', label: 'SMS & Email' }
    ],
    required: true
  }
  //   {
  //     key: 'content',
  //     label: 'campaign.templateContent',
  //     value: '',
  //     inputType: 'textArea',
  //     editable: true,
  //     creatable: true,
  //     required: true,
  //     validators: [isRequired({ msg: 'Content is required' })]
  //   }
];

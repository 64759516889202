import React from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

interface Props {
  control: Control<FieldValues>;
  errors: FieldErrors;
  options: Array<{
    value: string;
    label: string;
    description?: string;
  }>;
  value: string;
}

const ResourceCentreSelect = ({ control, options, value }: Props): JSX.Element => (
  <Controller
    control={control}
    name="resourceCentreId"
    render={({ field }) => (
      <Autocomplete
        options={options}
        value={options.find((opt) => opt.value === value)}
        onChange={(e, rc) => field.onChange(rc?.value)}
        getOptionLabel={(option) => `${option.label}`}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.value}>
            <Box>
              <Typography>{option.label}</Typography>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            //   eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            data-testmation="rcId"
            fullWidth
            variant="outlined"
            label="Resource Centre"
            placeholder="Select resource centre"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    )}
  />
);

export default ResourceCentreSelect;

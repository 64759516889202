import React from "react";
import { Box, Typography } from "@mui/material";
import { scaleFont } from "../LabPrintFunctions";
import { Settings } from "../../../../interfaces/ResourceCentreInterface";

export interface ResourceCentre {
  resourceCentreLogo: any;
  name: string;
  slogan: string;
  address: string;
  city: string;
  registrationNumber: string;
  phone: string;
  mobileNumber: string;
  optionalPhone: string;
  web: string;
  email: string;
  panNo: number;
  settings: Settings;
}

interface LabPrintLetterHeadProps {
  resourceCentre: ResourceCentre;
  titleFontScale: number;
  hideRcLogo?: boolean;
  additionalContent?: JSX.Element;
}

const LabPrintLetterHead: React.FC<LabPrintLetterHeadProps> = ({
  resourceCentre: {
    resourceCentreLogo,
    name,
    slogan,
    address,
    city,
    registrationNumber,
    phone,
    mobileNumber,
    optionalPhone,
    web,
    email,
    panNo
  },
  titleFontScale = 1,
  hideRcLogo = false,
  additionalContent
}) => (
  <Box display="flex" justifyContent="space-between">
    <Box display="flex">
      {!hideRcLogo && resourceCentreLogo && (
        <Box mt="8px">
          <img
            src={resourceCentreLogo.s3ResourceURL}
            className="billLogo"
            alt="logo"
            style={{ width: "auto", height: "84px" }}
          />
        </Box>
      )}
      <Box pl={resourceCentreLogo ? 2 : 0}>
        <Typography style={{ fontWeight: 500, fontSize: scaleFont("0.6cm", titleFontScale) }}>
          {name}
        </Typography>
        {slogan && (
          <Typography style={{ fontSize: "0.35cm", fontWeight: 400 }}>{slogan}</Typography>
        )}
        <Typography style={{ textAlign: "left", textTransform: "capitalize", fontSize: "0.325cm" }}>
          {address} {city}
        </Typography>
        {registrationNumber && (
          <Typography
            style={{ textAlign: "left", textTransform: "capitalize", fontSize: "0.325cm" }}
          >
            Reg No: {registrationNumber}
          </Typography>
        )}
      </Box>
    </Box>
    <Box>
      <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>
        {mobileNumber || ""}
      </Typography>
      <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>
        {phone}
        {optionalPhone && `,${optionalPhone}`}
      </Typography>
      <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>{web || ""}</Typography>
      <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>{email || ""}</Typography>
      {Boolean(panNo) && (
        <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>Pan No: {panNo}</Typography>
      )}
      {additionalContent && additionalContent}
    </Box>
  </Box>
);

export default LabPrintLetterHead;

import * as React from "react";
import { match as MatchProps, Route } from "react-router";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ServiceList from "./ServiceList";
import PackageInfo from "./PackageInfo";
import ProductInfo from "./ProductInfo";
import ProductCreate from "./ProductCreate";
import PackageCreate from "./PackageCreate";
import { RootState } from "../../store";
import { getCurrentSubscription } from "../../slices/subscriptionSlice";
import { getPublicService } from "../../slices/publicServiceSlice";
import { getServices } from "../../actions/services";

export const SSF_SERVICE_ORIGIN = "ssf";

export enum PublicServiceStatus {
  PUBLIC = "Public",
  NOTPUBLIC = "Not Public"
}

const Service = ({
  match,
  isPublicTab = false,
  isSsfTab = false
}: {
  match: MatchProps;
  isPublicTab?: boolean;
  isSsfTab?: boolean;
}): JSX.Element => {
  const [selectedRow, setSelectedRow] = React.useState(null);
  const handleClose = () => {
    setSelectedRow(null);
  };

  const dispatch = useDispatch();

  const services = useSelector((state: RootState) => state.services.collection) || [];

  const activeSortedServices = services
    .filter(({ active }) => active)
    .sort((a, b) => (`${a.name || ""}`.toLowerCase() > `${b.name || ""}`.toLowerCase() ? 1 : -1));
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);

  const hasCurrentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );

  const publicBookingEnabled =
    useSelector((state: RootState) => state.resources.resourceCentres[0]?.publicBooking) || false;

  const publicServices = useSelector((state: RootState) => state.publicServices.collection) || [];

  const activePublicServices = publicServices.filter((service) => service.active);

  let servicesFiltered;
  if (isPublicTab) {
    servicesFiltered = activeSortedServices.filter((service) => service.referenceId);
    const referenceIds = servicesFiltered.map((service) => service.referenceId);
    servicesFiltered = [
      ...servicesFiltered,
      ...activePublicServices.filter((pbService) => !referenceIds.includes(pbService.id))
    ];
  } else {
    servicesFiltered = activeSortedServices.filter((service) => !service.referenceId);
  }

  // get ssf subscription from settings
  const resourceCentre = useSelector((state: RootState) => state.userContext.resourceCentre);
  const isSsfSubscribed = resourceCentre?.subscriptions?.features?.ssf?.subscribed || false;

  if (isSsfTab) {
    if (!isSsfSubscribed) {
      servicesFiltered = [];
    } else {
      servicesFiltered = activeSortedServices.filter(({ origin }) => origin === SSF_SERVICE_ORIGIN);
    }
  } else {
    servicesFiltered = activeSortedServices.filter(
      ({ referenceId, origin }) => !referenceId && origin !== SSF_SERVICE_ORIGIN
    );
  }

  React.useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  React.useEffect(() => {
    if (!hasCurrentSubscription) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [dispatch, hasCurrentSubscription, rcId]);

  React.useEffect(() => {
    if (publicBookingEnabled) {
      dispatch(getPublicService());
    }
  }, [publicBookingEnabled, dispatch]);

  if (isPublicTab && !publicBookingEnabled) return null;

  return (
    <Box height="100%" pt={2}>
      <ServiceList
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isPublicTab={isPublicTab}
        isSsfTab={isSsfTab}
        servicesFiltered={servicesFiltered}
      />
      <Route
        exact
        path={`${match.path}/newProduct`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <ProductCreate {...props} isSsfTab={isSsfTab} />}
      />
      <Route
        exact
        path={`${match.path}/newPackage`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <PackageCreate {...props} />}
      />
      <Route
        exact
        path={`${match.path}/product/:id/edit`}
        render={(props) => (
          <ProductCreate
            productId={Number(props.match.params.id)}
            isPublicTab={isPublicTab}
            handleClose={handleClose}
            isSsfTab={isSsfTab}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/package/:id/edit`}
        render={(props) => (
          <PackageCreate
            packageId={Number(props.match.params.id)}
            handleClose={handleClose}
            isPublicTab={isPublicTab}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/product/:id`}
        render={(props) => (
          <ProductInfo
            productId={Number(props.match.params.id)}
            handleClose={handleClose}
            isPublicTab={isPublicTab}
            isSsfTab={isSsfTab}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/package/:id`}
        render={(props) => (
          <PackageInfo
            packageId={Number(props.match.params.id)}
            handleClose={handleClose}
            isPublicTab={isPublicTab}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
    </Box>
  );
};

Service.defaultProps = {
  isPublicTab: false
};

export default Service;

import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import React from "react";
import Modal from "../../../components/Modal/Modal";
import { cancelReceipt } from "../../../actions/bill";
import { BillStatus, DocumentTypes, Receipt } from "../../../interfaces/BillInterfaces";
import BillStatusChip from "../common/BillStatusChip";

enum PaymentMethods {
  CASH = "cash",
  BALANCE = "balance"
}
interface Props {
  cancelled: boolean;
  billStatus: string;
  billType: string;
  paymentMethod: PaymentMethods.BALANCE | PaymentMethods.CASH;

  receiptInfo: {
    receiptId: number;
    paidAmount: number;
    billId: number;
    paymentMethod: string;
    receivedOn: string;
  };
}

export default function BillHistoryCancellation({
  cancelled,
  billStatus,
  billType,
  receiptInfo,
  paymentMethod
}: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const [selectedReceipt, setSelectedReceipt] = React.useState<Receipt>({} as Receipt);
  const [openCancellationModal, setOpenCancellationModal] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState(false);

  if (
    [BillStatus.draft].includes(billStatus as BillStatus) ||
    [DocumentTypes.CREDITNOTE].includes(billType as DocumentTypes)
  ) {
    return null;
  }
  if (paymentMethod === PaymentMethods.BALANCE) {
    return null;
  }
  return (
    <div>
      {cancelled || billStatus === BillStatus.cancelled ? (
        <BillStatusChip bill={{ status: BillStatus.cancelled }} />
      ) : (
        <Box fontWeight={500}>
          <Modal
            saveAndClose
            onClose={() => setOpenCancellationModal(false)}
            onSave={async () => {
              setIsSaving(true);
              await dispatch(cancelReceipt(selectedReceipt));
              setIsSaving(false);
            }}
            open={openCancellationModal}
            disableSaveBtn={isSaving}
          >
            This action cannot be undone. Are you sure you want to cancel this receipt?
          </Modal>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setSelectedReceipt(receiptInfo);
              setOpenCancellationModal(true);
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </div>
  );
}

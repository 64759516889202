import { TransactionTypes } from "./Accounts";
import { ResourceCentre } from "./ResourceCentreInterface";

export enum UserGroupTypes {
  resourceCentreEmployee = "resourceCentreEmployee",
  serviceProvider = "serviceProvider"
}
export interface ResourceCentreUserGroups {
  // eslint-disable-next-line camelcase
  created_at: string;
  id: number;
  name: string;
  policyId: number;
  resourceCentreId: number;
  // eslint-disable-next-line camelcase
  updated_at: string;
  userGroupType: UserGroupTypes;
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  resourceCentreId: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
  active: boolean;
  signature: string;
}

export enum PermissionGroups {
  ReasourceCentreReadOnlyEmployee = "resourceCentreReadOnlyEmployee",
  ResourceCentreReportOnlyAccessEmployee = "resourceCentreReportOnlyAccessEmployee",
  ServiceProviderLimited = "serviceProviderLimited",
  ResourceCentreFrontDeskEmployee = "resourceCentreFrontDeskEmployee",
  ResourceCentreAdmin = "resourceCentreAdmin",
  ServiceProviderFull = "serviceProviderFull",
  SupportAdmin = "supportAdmin",
  SuperAdmin = "superAdmin",
  ResourceCentreLabTechnicianLimited = "resourceCentreLabTechnicianLimited",
  ResourceCentreCampaignOnlyEmployee = "resourceCentreCampaignOnlyEmployee",
  SuperAdminAgent = "superAdminAgent",
  SelfDataAccessEmployee = "employeeWithOwnDataAccess",
  CmsAdmin = "cmsAdminWithAdminRights",
  PublicBookingAdmin = "publicBookingAdmin"
}

export const AdminPermissionGroup = [
  PermissionGroups.ResourceCentreAdmin,
  PermissionGroups.SupportAdmin,
  PermissionGroups.SuperAdmin,
  PermissionGroups.CmsAdmin
];

export enum UserMode {
  Employee = "employee",
  ServiceProvider = "serviceProvider",
  Admin = "admin",
  SuperAdmin = "superAdmin",
  Client = "client"
}

export interface Employee {
  active: boolean;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phone: string;
  signature: string;
  user: User;
  resourceCentreId: number;
  mapToLedger?: string;
  ledgerId: number | null;
  openingBalance: number;
  openingBalanceDate: Date | string | null;
  transactionType: TransactionTypes;
  nonUserEmployee: boolean;
  departmentId: number | string;
  panNumber?: string;
}

export interface User {
  id: number;
  username: string;
  tempPassword: string;
  resourceCentre: ResourceCentre;
  signature: string;
  firstName: string;
  lastName: string;
  enforcedTwoFactorAuth: boolean;
  verificationCode: string;
  verificationSentDate: string;
  phone: string;
}

export interface MobileUser {
  id: number;
  name: string;
  phone: string;
  isGuest: boolean;
  // eslint-disable-next-line camelcase
  created_at: string;
  resourceCentre: Partial<ResourceCentre>;
}

import * as React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import { push } from "connected-react-router";
import queryString from "query-string";
import List from "../../components/List";
import SupplierInfo from "./SupplierShow";
import { supplierActions as actions } from "../../actions";
import { tl } from "../../components/translate";
import { suppliersSortedSelector, supplierNameSelector } from "../../reducers/suppliers";
import { IThunkDispatch, RootState } from "../../store";
import { Supplier } from "../../interfaces/StockInterfaces";
import useCan from "../../hooks/useCan";
import { ListActions, Menu, MenuItem } from "../../components/OList";
import CsvUploader from "../../components/CsvUploader/CsvUploader";
import { supplierImportColumns } from "../../components/CsvUploader/csvHelpers";
import { batchUploadSupplier } from "../../api/suppliers";

interface Props {
  resourceCentreId: number;
  suppliers: Array<Supplier>;
  loadSuppliers: ({ resourceCentreId: number }) => void;
  navigateTo: (url: string) => void;
  navigateToResourceSettingsSp: boolean;
}

export const SuppliersList = ({
  resourceCentreId,
  suppliers,
  loadSuppliers,
  navigateTo,
  navigateToResourceSettingsSp
}: Props): JSX.Element => {
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  React.useEffect(() => {
    loadSuppliers({ resourceCentreId });
    const { sId } = queryString.parse(window.location.search);
    if (sId) {
      setSelectedSupplier(Number(sId));
    }
  }, [resourceCentreId, loadSuppliers]);

  const handleViewClose = () => {
    setSelectedSupplier(null);
    if (navigateToResourceSettingsSp) {
      navigateTo(`/resourcecentres/${resourceCentreId}/supplier`);
    }
  };

  const showCreateButton = useCan("clinic:createSuppliers", { superAdminPass: true });

  return (
    <>
      <List
        automation="suppliers"
        title={tl("employee.suppliers")}
        createLabel={tl("employee.createSupplier")}
        testmationLabel="CreateSupplier"
        rowHeight={50}
        defaultSortColumn="name"
        hideCreateButton={!showCreateButton}
        isResponsive
        columns={[
          {
            key: "name",
            label: tl("supplier.name"),
            sortable: true,
            sortFunction: (a, b) =>
              `${a.name}`.toLowerCase() > `${b.name}`.toLowerCase() ? 1 : -1,
            formatter: (r) => (
              <Typography variant="body2">{supplierNameSelector({ name: r.name })}</Typography>
            )
          },
          { key: "phone", label: tl("phone") },
          { key: "email", label: tl("email"), hideInNarrowView: true },
          { key: "address", label: tl("address") }
        ]}
        data={suppliers || []}
        isLoading={!suppliers}
        activeRow={selectedSupplier}
        onRowClick={({ id }) => {
          setSelectedSupplier(selectedSupplier === id ? null : id); // closes on same click
          if (window.location.pathname.includes("edit")) {
            navigateTo(`/resourcecentres/${resourceCentreId}/supplier?spId=${id}`);
          }
        }}
        onCreateNew={() => navigateTo(`/resourcecentres/${resourceCentreId}/supplier/create`)}
      >
        <ListActions>
          {() => (
            <Menu>
              <CsvUploader
                buttonText="Upload suppliers (.csv)"
                columns={supplierImportColumns}
                requiredFieldInfoText="Note: Name is mandatory for supplier upload."
                createDataApi={batchUploadSupplier}
                useBatchUpload
                limit={100}
                maxUploadLimitText="Maximum upload limit for supplier upload is 100"
                runAfterSave={() => loadSuppliers({ resourceCentreId })}
              />
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://docs.google.com/spreadsheets/d/1ODlS9Ayfxjy3CBgGjAIkbkQveOAYxJ7COuVNqz4bInU/edit#gid=0",
                    "_blank"
                  )
                }
              >
                {tl("viewSample")}
              </MenuItem>
            </Menu>
          )}
        </ListActions>
      </List>
      {selectedSupplier && (
        <SupplierInfo supplierId={selectedSupplier} handleViewClose={handleViewClose} />
      )}
    </>
  );
};

export default connect(
  (state: RootState) => ({
    suppliers: suppliersSortedSelector(state).filter((item) => Boolean(item.active)),
    policies: state.userContext.policies
  }),
  (dispatch: IThunkDispatch) => ({
    loadSuppliers: (id) => {
      dispatch(actions.getSuppliers(id));
    },
    navigateTo: (url) => dispatch(push(url))
  })
)(SuppliersList);

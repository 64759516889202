import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ClientImageUploader from "../../components/ImageUploader/ClientImageUploader";
import { tl } from "../../components/translate";
import { clientFullNameSelector } from "../../reducers/client";
import { RootState } from "../../store";
import { showLabComponentsBasedOnPermission } from "./LabDetails";
import * as calendarFns from "../../components/Calendar/functions/calendarFunctions";
import { LabTestStatusTypes } from "./LabList";
import { LabRecord } from "../../interfaces/Lab";
import { getCustomerNumber } from "../Client/ClientList";

function LabInfo({ labObj }: { labObj: LabRecord }): JSX.Element {
  const resourceCentre = useSelector(
    (state: RootState) =>
      state.resources?.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
      state?.userContext.resourceCentre
  );
  const useCustomLabTestNumber = resourceCentre?.labSettings.useCustomLabTestNumber;
  // eslint-disable-next-line camelcase
  const { client, serviceProvider, created_at } = labObj;
  const customer = useSelector(
    (state: RootState) => state.clients.collection?.find((c) => c.id === labObj.clientId) || {}
  );
  const { customerNumber } = customer;
  const allowUpload = resourceCentre?.settings.clientSettings.enablePictureUpload;
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext?.userCreds?.userGroups[0]
  );
  return (
    <Box pt={2}>
      <Box display="flex">
        <Typography style={{ width: "120px" }}>{tl("lab.testId")}</Typography>
        <Typography>
          <Box component="span" fontWeight="600">
            {useCustomLabTestNumber ? labObj.labTestNumber || labObj.id : labObj.id}
          </Box>
        </Typography>
      </Box>
      {labObj?.results?.billNumber && (
        <>
          <Box display="flex">
            <Typography style={{ width: "120px" }}>{tl("billnumber")}</Typography>
            <Typography
              style={{
                fontWeight: 600,
                textDecoration: "underline",
                cursor: "pointer"
              }}
            >
              {labObj.results.billNumber}
            </Typography>
          </Box>
          <Typography style={{ fontSize: "0.675rem" }}>
            Note: This lab test cannot be edited because bill has already been created.
          </Typography>
        </>
      )}
      <Box display="flex" mt="16px">
        <Box flexDirection="column" justifyContent="space-between" mr="20px">
          <Box display="flex">
            <Typography style={{ width: "120px" }}>{tl("lab.client")}</Typography>
            {showLabComponentsBasedOnPermission(permissionGroup, labObj.status, false) ? (
              <Typography
                style={{
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
              >
                {clientFullNameSelector(client)}
              </Typography>
            ) : (
              <Typography style={{ fontWeight: 600 }}>{clientFullNameSelector(client)}</Typography>
            )}
          </Box>
          {customerNumber && (
            <Box display="flex">
              <Typography style={{ width: "120px" }}>{tl("lab.customerNumber")}</Typography>
              <Typography fontWeight="600">{getCustomerNumber(customerNumber)}</Typography>
            </Box>
          )}
          {labObj?.customer?.externalIdentifier && (
            <Box display="flex">
              <Typography style={{ width: "120px" }}>
                {tl("labPrint.customerIdentifier")}
              </Typography>
              <Typography fontWeight="600">{labObj.customer.externalIdentifier}</Typography>
            </Box>
          )}
          <Box display="flex">
            <Typography style={{ width: "120px" }}>{tl("lab.phoneNo")}</Typography>
            <Typography fontWeight="600">{client.phone}</Typography>
          </Box>
          <Box display="flex">
            <Typography style={{ width: "120px" }}>{tl("lab.address")}</Typography>
            <Typography fontWeight="600">{client.address}</Typography>
          </Box>
        </Box>

        <div>
          {allowUpload && (
            <ClientImageUploader
              readOnly={[LabTestStatusTypes.RESULT_READY, LabTestStatusTypes.SAMPLE_TAKEN].includes(
                labObj.status
              )}
              action={() => {
                // none
              }}
              initialImage={customer?.customerDetails?.profileImageS3Url || ""}
            />
          )}
        </div>
      </Box>
      <Box justifyContent="space-between" pt={1}>
        <Box display="flex">
          <Typography style={{ width: "120px" }}>{tl("lab.orderedBy")}</Typography>
          <Typography>
            {serviceProvider ? clientFullNameSelector(serviceProvider) : tl("labTest.orderBy.self")}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography style={{ width: "120px" }}>{tl("lab.orderedOn")}</Typography>
          <Typography>{calendarFns.bsFullDate(created_at)}</Typography>
        </Box>
      </Box>
      {labObj.referrers && (
        <Box display="flex">
          <Typography style={{ width: "120px" }}>Referred By</Typography>
          <Typography>{labObj.referrers}</Typography>
        </Box>
      )}
      {labObj.extraReferrer?.name && (
        <Box display="flex">
          <Typography style={{ width: "120px" }}>Extra Referrer</Typography>
          <Typography>{labObj.extraReferrer.name}</Typography>
        </Box>
      )}
      {labObj.accessingResourceCentreId && (
        <Box mt="16px">
          {labObj.accessingResourceCentreId !== resourceCentre.id ? (
            <Typography>
              Referred By Lab:{" "}
              <Box component="span" ml="18px" fontWeight={600}>
                {labObj.accessingResourceCentre?.name}
              </Box>
            </Typography>
          ) : (
            <Typography>
              Referred Lab:{" "}
              <Box component="span" ml="18px" fontWeight={600}>
                {labObj.resourceCentre.name}
              </Box>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default LabInfo;

import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { push } from "connected-react-router";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip
} from "@mui/material";
import { Close } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import { tl, t } from "../../components/translate";
import { resourceCentreEmployeeActions as actions } from "../../actions";
import Info from "../../components/List/Info";
import { showDialog, navigateRemoveModal } from "../../actions/navigation";
import { resetPasswordFor } from "../../actions/user";
import Panel from "../../components/Panel";
import styles from "../ResourceCentre/style.module.css";
import { AdminPermissionGroup, Employee, User } from "../../interfaces/User";
import Can from "../Policy/Can";
import { IThunkDispatch, RootState } from "../../store";
import MessageCreate, { MESSAGE_TYPE } from "../Campaign/InstantMessage/MessageCreate";
import { ReverseMap } from "../../helpers/types";
import { notificationAdd } from "../../actions/notification";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import { getVendorLedgers, updateEmployeeTwoFactorAuth } from "../../api/user";
import { LedgerInterface } from "../../interfaces/Employee";
import EmployeeLedger from "./Ledgers";
import useIsAccountSubscribed from "../../hooks/accounts";
import { VendorType } from "../../interfaces/Accounts";
import { infoIconColor } from "../Lab/LabTestCreate/AddLabtests";
import { displayUserGroup } from "../ServiceProvider/ServiceproviderShow";
import { getResourceCentreBranches } from "../../api/resourceCentre";
import {
  assignRcToUser,
  getUserAccesingRcs,
  unassignRcForUser
} from "../../slices/userBranchSlice";
import useCan from "../../hooks/useCan";
import OkhatiDialog from "../../components/Dialog/Dialog";

enum EMPLOYEE_PASS_RESET_PERMISSION_GROUP {
  RESOURCE_CENTER_ADMIN = "resourceCentreAdmin",
  SUPER_ADMIN = "superAdmin",
  SUPPORT_ADMIN = "supportAdmin"
}

interface EmployeeShowProps {
  employeeId: number;
  employee: Employee;
  loadEmployee: (id: number) => void;
  navigateTo: (url: string) => void;
  onDeleteResourceCentreEmployee: (id: number, description: string) => void;
  onResetPassword: (employee: Employee) => void;
  permissionGroup: ReverseMap<typeof EMPLOYEE_PASS_RESET_PERMISSION_GROUP>;
  handleClose: () => void;
  user: User;
  isEmployeeOnlyTab: boolean;
}

export const EmployeeShow = ({
  employeeId,
  employee,
  loadEmployee,
  navigateTo,
  onDeleteResourceCentreEmployee,
  onResetPassword,
  permissionGroup,
  user,
  handleClose,
  isEmployeeOnlyTab
}: EmployeeShowProps): JSX.Element => {
  const dispatch = useDispatch();
  const [messagePanel, setMessagePanel] = React.useState({ show: false, type: MESSAGE_TYPE.SMS });
  React.useEffect(() => {
    loadEmployee(employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  const resourceCentres = useSelector((state: RootState) => state.resources.resourceCentres);
  const currEmployeeRc = resourceCentres.find((rc) => rc.id === employee?.resourceCentreId) || {};
  const isAccountSubscribed = useIsAccountSubscribed();
  const [employeeLedgers, setEmployeeLedgers] = React.useState<LedgerInterface[]>([]);
  const [assigningBranch, setAssigningBranch] = React.useState(false);
  const [rcBranches, setRcBranches] = React.useState([]);
  const [unassigningBranchInfo, setUnassigningBranchInfo] = React.useState({
    openModal: false,
    branchId: null
  });

  const { twoFactorAuthentication } = currEmployeeRc?.subscriptions?.features || {};

  React.useEffect(() => {
    if (employeeId && isAccountSubscribed) {
      const getLedgers = async () => {
        try {
          const response = await getVendorLedgers(employeeId, VendorType.EMPLOYEE);
          setEmployeeLedgers(response);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              message: error?.data?.message || errorFetchMessage(MODULE.EMPLOYEE_LEDGER),
              autoTimeout: true,
              variant: "error"
            })
          );
        }
      };
      getLedgers();
    }
  }, [employeeId, dispatch, isAccountSubscribed]);

  const isSuperAdmin = useCan("deny", { superAdminPass: true });

  React.useEffect(() => {
    if (employee?.user?.id) {
      dispatch(getUserAccesingRcs(employee.user.id));
    }
  }, [employee?.user?.id, dispatch]);

  const assignedResourceCentres =
    useSelector((state: RootState) => state.userBranch.collection) || [];

  React.useEffect(() => {
    const getSubBranches = async () => {
      try {
        const branchData = await getResourceCentreBranches(employee.resourceCentreId);
        setRcBranches(branchData);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: err?.data?.message || "Failed fetching branches for resource centre",
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    };
    if (employee?.resourceCentreId && isSuperAdmin) {
      getSubBranches();
    }
  }, [employee?.resourceCentreId, dispatch, isSuperAdmin]);
  const [assignedBranch, setAssignedBranch] = React.useState(null);

  const handleAssignBranch = (resourceCentreBranch) => {
    const payload = {
      resourceCentreId: resourceCentreBranch.id,
      name: resourceCentreBranch.name,
      userId: employee.user.id,
      employeeId: employee.id
    };
    dispatch(assignRcToUser(payload));
    setAssignedBranch(null);
  };

  const options = [...[currEmployeeRc], ...rcBranches]
    .filter((branch) => !assignedResourceCentres.find((rc) => rc.id === branch.id))
    .map((rc) => ({
      id: rc.id,
      name: rc.name
    }));
  if (!employee) return <Info>...</Info>;
  const { firstName, lastName } = employee;
  const isAllowedToEdit = AdminPermissionGroup.includes(permissionGroup) || user.id === employee.id;

  let editButton = null;
  let deleteButton = null;
  let resetPassButton = null;

  if (isAllowedToEdit) {
    editButton = (
      <EditIcon
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (isEmployeeOnlyTab) {
            navigateTo(
              `/resourcecentres/${employee.resourceCentreId}/nonUserEmployee/${employee.id}/edit`
            );
          } else {
            navigateTo(
              `/resourcecentres/${employee.resourceCentreId}/employees/${employee.id}/edit`
            );
          }
        }}
      />
    );

    if (["superAdmin", "resourceCentreAdmin"].includes(permissionGroup)) {
      deleteButton = (
        <Can policyAccessKey="clinic:deleteUsers" superAdminPass>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => onDeleteResourceCentreEmployee(employee.id, `${firstName} ${lastName}`)}
          />
        </Can>
      );
    }
  }

  if (Object.values(EMPLOYEE_PASS_RESET_PERMISSION_GROUP).includes(permissionGroup)) {
    resetPassButton = (
      <Can policyAccessKey="clinic:resetPasswords" superAdminPass supportAccountAdminPass>
        <Button onClick={() => onResetPassword(employee)} data-testmation="resetPassword">
          {tl("resetPassword.title")}
        </Button>
      </Can>
    );
  }

  const userGroupData = employee?.user?.userGroups
    ? {
        userGroup: employee.user.userGroups[0].name,
        description: employee.user.userGroups[0]?.description
      }
    : {};

  const twoFactorAuthHandler = async (enforcedTwoFactorAuth) => {
    try {
      await updateEmployeeTwoFactorAuth({
        id: employee.user.id,
        enforcedTwoFactorAuth,
        rcId: employee.resourceCentreId
      });
      dispatch(actions.getResourceCentreEmployee(employee.id));
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Sorry!, Something went wrong while updating Two Factor Authentication",
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Panel
      onClose={handleClose}
      editButton={editButton}
      deleteButton={deleteButton}
      genericButton={isEmployeeOnlyTab ? null : resetPassButton}
    >
      <Box pl={5} pr={5} width="100%" className={styles.boxHeight}>
        <Typography variant="h6">
          {firstName} {lastName}
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container>
              <Grid container mb={0.5}>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography variant="body1">{tl("employee.userGroup")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="centre"
                >
                  <Typography variant="body2">
                    {displayUserGroup(userGroupData.userGroup)}
                  </Typography>
                  {userGroupData.description && (
                    <Tooltip title={userGroupData.description}>
                      <InfoIcon
                        sx={{
                          color: infoIconColor,
                          marginLeft: "5px",
                          cursor: "pointer"
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="body1">{tl("employee.username")}</Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="body2">{employee.user?.username}</Typography>
              </Grid>
              {!isEmployeeOnlyTab && isAllowedToEdit && !!employee.user?.tempPassword && (
                <>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography variant="body1">{tl("employee.tempPassword")}</Typography>
                  </Grid>
                  {isAllowedToEdit && (
                    <Grid item xs={6} md={6} lg={6}>
                      <Typography variant="body2">{employee.user?.tempPassword}</Typography>
                    </Grid>
                  )}
                </>
              )}
              {employee.panNumber && (
                <>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography variant="body1">{tl("panNumber")}</Typography>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography variant="body2">{employee.panNumber}</Typography>
                  </Grid>
                </>
              )}
              <Box marginTop="32px" width="100%">
                {["email", "phone"].map((attr) => (
                  <Grid container key={attr}>
                    <Grid item xs={6} md={6} lg={6}>
                      <Typography variant="body1">{tl(attr)}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center"
                        }}
                      >
                        <Typography variant="body2">{employee[attr]}</Typography>
                        {attr === "email" && employee[attr] && (
                          <Tooltip title="Send Email">
                            <ForwardToInboxIcon
                              sx={{ cursor: "pointer", width: "0.9rem" }}
                              onClick={() => {
                                setMessagePanel((prevState) => ({
                                  show: !prevState.show,
                                  type: MESSAGE_TYPE.EMAIL
                                }));
                              }}
                            />
                          </Tooltip>
                        )}
                        {attr === "phone" && employee[attr] && (
                          <Tooltip title="Send SMS">
                            <SmsIcon
                              sx={{ cursor: "pointer", width: "0.9rem" }}
                              onClick={() => {
                                setMessagePanel((prevState) => ({
                                  show: !prevState.show,
                                  type: MESSAGE_TYPE.SMS
                                }));
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6} />
        </Grid>

        {isAllowedToEdit && twoFactorAuthentication?.subscribed && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={employee.user?.enforcedTwoFactorAuth}
                  onChange={({ target }) => {
                    twoFactorAuthHandler(target.checked);
                  }}
                />
              }
              label="Enable Two Factor Authentication"
            />
          </FormGroup>
        )}

        <Can policyAccessKey="deny" superAdminPass>
          <Box width="100%" mt={3}>
            {!assigningBranch && (
              <Box mb={2}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "30px",
                    color: "#009654",
                    borderBottom: "2px solid #009654",
                    cursor: "pointer"
                  }}
                  onClick={() => setAssigningBranch(!assigningBranch)}
                >
                  Assign Branches For Reports
                </Typography>
              </Box>
            )}
            {assigningBranch && (
              <>
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography sx={{ fontWeight: "bold" }}>Branches</Typography>
                  <Close
                    sx={{ cursor: "pointer", fontSize: "20px" }}
                    onClick={() => setAssigningBranch(!assigningBranch)}
                  />
                </Box>
                <Box my={2}>
                  <ul>
                    {assignedResourceCentres.map((rc, i) => (
                      <li key={rc.id} style={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ width: "300px" }}>
                          {i + 1}. {rc.name}
                        </Typography>
                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            setUnassigningBranchInfo({ openModal: true, branchId: rc.userBranchId })
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </Box>
                <Box display="flex" width="100%" alignItems="center" mt={1}>
                  <Box width="80%">
                    <Autocomplete
                      options={options}
                      filterSelectedOptions
                      getOptionLabel={(option) => option.name}
                      value={assignedBranch}
                      onChange={(e, v) => {
                        if (v) {
                          setAssignedBranch(v);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          label="Assign Branches For Report"
                          placeholder="Branches"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    disabled={!assignedBranch || !employee}
                    onClick={() => handleAssignBranch(assignedBranch)}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Can>
        {isAccountSubscribed && (
          <Box mt={2}>
            <EmployeeLedger data={employeeLedgers} />
          </Box>
        )}
      </Box>
      {messagePanel.show && (
        <MessageCreate
          onCancel={() => {
            setMessagePanel((prevState) => ({ ...prevState, show: false }));
          }}
          client={employee}
          messageType={messagePanel.type}
        />
      )}
      <OkhatiDialog
        open={unassigningBranchInfo.openModal}
        title="Warning"
        description="Are you sure you want to unassign user from the resource centre?"
        next={() => {
          if (unassigningBranchInfo.branchId) {
            dispatch(unassignRcForUser(unassigningBranchInfo.branchId));
          }
          setUnassigningBranchInfo({
            openModal: false,
            branchId: null
          });
        }}
        cancel={() => {
          setUnassigningBranchInfo({
            openModal: false,
            branchId: null
          });
        }}
        readMode={false}
      />
    </Panel>
  );
};

export default connect(
  ({ userContext, resources }: RootState, { employeeId }: { employeeId: number | string }) => ({
    user: { ...userContext.user, role: userContext.mode },
    permissionGroup: userContext?.userCreds?.userGroups[0],
    employee: resources.resourceCentreEmployees.find((sp) => sp.id === employeeId)
  }),
  (dispatch: IThunkDispatch) => ({
    loadEmployee: (id) => dispatch(actions.getResourceCentreEmployee(id)),
    navigateTo: (url) => dispatch(push(url)),
    onDeleteResourceCentreEmployee: (id, description) => {
      dispatch(
        showDialog({
          title: "Are you sure about deleting?",
          description,
          next: () => {
            dispatch(actions.deleteResourceCentreEmployee(id));
            dispatch(navigateRemoveModal("Dialog"));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    },
    onResetPassword: (employee) => {
      dispatch(
        showDialog({
          title: tl("resetPassword.title"),
          description: `${t("resetPassword.message")} ${employee.firstName} ${employee.lastName}?`,
          next: async () => {
            await dispatch(resetPasswordFor(employee.user.id));
            dispatch(navigateRemoveModal("Dialog"));
            await dispatch(actions.getResourceCentreEmployee(employee.id));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    }
  })
)(EmployeeShow);

import { Get } from './apiHelper';

export const videoAppointmentsRoot = '/api/appointments';

export async function getOnlineClients() {
  const response = await Get(`${videoAppointmentsRoot}/onlineClients`, true);
  return response.data;
}

export async function getVideoUrl(bookingId) {
  const response = await Get(`${videoAppointmentsRoot}/getVideoUrl/${bookingId}`, true);
  return response.data;
}

export const videoAppRoot =
  process.env.REACT_APP_ENV === 'production' ? 'https://v.okhati.app' : 'https://v.okhati.com.np';

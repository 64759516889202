import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { push } from "connected-react-router";
import List from "../../components/List";
import { deleteRcAssociateCompany } from "../../slices/associateCompanySlice";
import useAssociateCompanies from "../../hooks/useAssociateCompanies";
import CompanyCreateEdit from "./CompanyCreateEdit";
import { AssociateCompany } from "../../api/associateCompanies";
import CompanyShow from "./CompanyShow";
import { navigateRemoveModal, showDialog } from "../../actions/navigation";
import { t, tl } from "../../components/translate";

interface Props {
  editMode?: boolean;
  createMode?: boolean;
  match: {
    params: {
      companyId: string;
      id: string;
    };
  };
}

const AssociateCompanyList = ({ createMode, editMode, match }: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const companies: AssociateCompany[] = useAssociateCompanies();
  const [selectedCompany, setSelectedCompany] = React.useState<AssociateCompany | null>(null);

  useEffect(() => {
    if (editMode && companies?.length !== 0) {
      setSelectedCompany(companies.filter((item) => item.id === Number(match.params.companyId))[0]);
    }
  }, [companies]);

  if (editMode && !selectedCompany) return null;

  return (
    <>
      <List
        automation="associateCompanieslist"
        title={tl("associateCompanies")}
        createLabel={tl("createCompany")}
        testmationLabel="createCompany"
        rowHeight={50}
        defaultSortColumn="name"
        isResponsive
        columns={[
          {
            // eslint-disable-next-line camelcase
            key: "created_at",
            label: `${t("createdDate")}`,
            // eslint-disable-next-line camelcase
            formatter: ({ created_at }) => (
              <Typography>{moment(created_at).format("YYYY-MM-DD")}</Typography>
            )
          },
          {
            key: "name",
            label: `${t("companyName")}`,
            sortable: true,
            sortFunction: (a, b) =>
              `${a.name}`.toLowerCase() > `${b.name}`.toLowerCase() ? 1 : -1,
            formatter: ({ name }) => <Typography>{name}</Typography>
          },
          {
            key: "discountRate",
            label: `${t("discountRate")}`,
            formatter: ({ discountRate }) => <Typography>{discountRate}</Typography>
          }
        ]}
        data={companies}
        activeRow={selectedCompany?.id}
        onRowClick={(row) => {
          if (row.id === selectedCompany?.id) {
            setSelectedCompany(null);
          } else {
            setSelectedCompany(row);
          }
        }}
        onCreateNew={() =>
          dispatch(push(`/resourcecentres/${match.params.id}/associateCompanies/create`))
        }
      />

      {selectedCompany && (
        <CompanyShow
          onClose={() => setSelectedCompany(null)}
          data={selectedCompany}
          onDelete={() => {
            dispatch(
              showDialog({
                title: "Are you sure about deleting?",
                description: `${selectedCompany.name}`,
                next: () => {
                  dispatch(deleteRcAssociateCompany({ id: selectedCompany.id }));
                  dispatch(navigateRemoveModal("Dialog"));
                  setSelectedCompany(null);
                },
                onCancel: () => dispatch(navigateRemoveModal)
              })
            );
          }}
          onEdit={() => {
            dispatch(
              push(
                `/resourcecentres/${match.params.id}/associateCompanies/${selectedCompany.id}/edit`
              )
            );
          }}
        />
      )}
      {(createMode || editMode) && (
        <CompanyCreateEdit
          createMode={!!createMode}
          onSuccess={(company) => {
            setSelectedCompany(company);
            dispatch(push(`/resourcecentres/${match.params.id}/associateCompanies`));
          }}
          onClose={() => {
            dispatch(push(`/resourcecentres/${match.params.id}/associateCompanies`));
          }}
          selectedCompany={selectedCompany}
        />
      )}
    </>
  );
};

export default AssociateCompanyList;

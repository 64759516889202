import * as React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import Box from "@mui/material/Box";
import { Typography, Button, IconButton, Tooltip } from "@mui/material";
import * as moment from "moment";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import * as calendarFns from "../functions/calendarFunctions";
import * as calendarData from "../functions/calendarData";
import { t, tl } from "../../translate";
import classNames from "../../../helpers/classNames";
import styles from "./styles.module.css";

const getMonthYearForTheWeek = (date) => {
  const day1 = moment(date).startOf("week");
  const day7 = moment(date).endOf("week");
  const day1BS = calendarFns.convertADtoBS(day1);
  const day7BS = calendarFns.convertADtoBS(day7);
  const endMonth = ` - ${calendarData.bsMonths(t)[day7BS.bsMonth - 1]} ${day7BS.bsYear}`;
  return `${calendarData.bsMonths(t)[day1BS.bsMonth - 1]} ${day1BS.bsYear}${
    day1BS.bsMonth !== day7BS.bsMonth ? endMonth : ""
  }`;
};

const isWeekend = (day: Date | moment.Moment) => moment(day).day() === 6;

interface CalendarHeaderProps {
  selectedDate: Date;
  days: Array<moment.Moment>;
  goBack: () => void;
  goForward: () => void;
  goto: (date: Date) => void;
  isCalendarView: boolean;
  onCalendarViewChange: () => void;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  selectedDate,
  days,
  goBack,
  goForward,
  goto,
  isCalendarView,
  onCalendarViewChange
}): JSX.Element => (
  <Box className={styles.calendarHeader}>
    <Box className={styles.calendarControls} display="flex">
      <Box component="span" flexGrow={1} display="flex" alignItems="center">
        <Typography>
          <Box textAlign="center" className={styles.selectedDate} component="span">
            {getMonthYearForTheWeek(selectedDate)}
          </Box>
        </Typography>
      </Box>
      <Box
        component="span"
        className={styles.headerActionsContainer}
        display="flex"
        alignItems="center"
      >
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={() => goto(new Date())}>
            {tl("booking.today")}
          </Button>
          <Button
            onClick={async () => {
              goBack();
            }}
          >
            <Tooltip title="Previous">
              <KeyboardArrowLeft />
            </Tooltip>
          </Button>
          <Button
            data-testmation="rightArrow"
            onClick={async () => {
              goForward();
            }}
          >
            <Tooltip title="Next">
              <KeyboardArrowRight />
            </Tooltip>
          </Button>
        </Box>
      </Box>
      <IconButton onClick={() => onCalendarViewChange()}>
        {isCalendarView ? (
          <Tooltip title="Details">
            <ListIcon color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="Calendar">
            <CalendarMonthIcon color="primary" />
          </Tooltip>
        )}
      </IconButton>
    </Box>
    <Box className={styles.dayHeaders}>
      <div
        className={styles.timeColumnHeader}
        style={{ display: `${!isCalendarView ? "none" : ""}` }}
      />
      {days.map((day) => (
        <Box
          key={day.toISOString()}
          className={classNames(styles.dayHeader, {
            [styles.todayHeader]: moment(day).isSame(moment(), "date"),
            [styles.weekendHeader]: isWeekend(day),
            [styles.dayHeaderListView]: !isCalendarView
          })}
        >
          <Typography style={{ fontSize: "0.75rem" }}>
            {calendarData.bsDaysFull(t)[day.day()]}
          </Typography>
          <Typography
            style={{ fontWeight: 600, fontSize: "1.4rem" }}
            className={classNames({
              [styles.todayColumnHeaderDate]: moment(day).isSame(moment(), "date")
            })}
          >
            {calendarFns.convertADtoBS(moment(day)).bsDate}
          </Typography>
        </Box>
      ))}
      <div aria-hidden="true" className={styles.calScroll} />
    </Box>
  </Box>
);

export default CalendarHeader;

import { isRequired, isValidMobileNumber, isEmail } from "../helpers/validators";
import { TransactionTypes } from "../interfaces/Accounts";
import { ReferrerProductServiceEntities } from "../interfaces/ReferrerInterface";

export const fields = [
  {
    key: "referrer",
    label: "name",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: true,
    validators: [isRequired({ msg: "Referrer is required" })]
  },
  {
    key: "phone",
    label: "mobileNumber",
    value: "",
    inputType: "phone",
    editable: true,
    creatable: true,
    required: false,
    mobileNumber: true,
    validators: [isValidMobileNumber({ msg: "Should be a valid mobile number" })]
  },
  {
    key: "email",
    label: "email",
    value: "",
    inputType: "text",
    editable: true,
    creatable: true,
    required: false,
    validators: [isEmail({ msg: "Should be a valid email" })]
  },
  {
    key: "address",
    label: "address",
    value: "",
    inputType: "text",
    editable: false,
    creatable: false,
    required: false,
    validators: []
  },
  {
    key: "tds",
    label: "tds",
    value: 0,
    inputType: "number",
    editable: true,
    creatable: false,
    required: false,
    validators: []
  },
  {
    key: "rate",
    label: "rate",
    value: 0,
    inputType: "number",
    editable: true,
    creatable: false,
    required: false,
    validators: []
  }
];

export type FormState = {
  referrer: string;
  phone: string;
  email: string;
  address: string;
  productService: Array<{
    productId: number;
    serviceId: number;
    entityType: ReferrerProductServiceEntities;
    price: number;
    originalPrice: number | string;
    name: string;
  }>;
  ledgerId: number;
  openingBalance: number;
  tds: number;
  rate: number;
  openingBalanceDate: string;
  transactionType: TransactionTypes;
};

export function getInitialState(): FormState {
  return fields.reduce((draft, cur) => {
    if (cur.key === "productService") {
      draft[cur.key] = [];
    } else {
      draft[cur.key] = "";
    }
    return draft;
  }, {} as FormState);
}

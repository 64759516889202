import React from "react";
import { Typography, Box } from "@mui/material";

import moment from "moment";
import { getSectionStyle } from "../../../Lab/LabPrint/LabPrintFunctions";
import { tl } from "../../../../components/translate";
import { scaleFont } from "../../../../components/Print/Print";
import * as calFns from "../../../../components/Calendar/functions/calendarFunctions";

import { numberToWords } from "../../../../helpers/rupee";

interface RelatedSettingsInterface {
  headerColor: null | string;
  isBordered: boolean;
  showReferrer: boolean;
}
interface PaymentDescriptionV2PropsInterface {
  relatedSettings: RelatedSettingsInterface;
  receivedOn: Date | string;
  paidAmount: number;
  paymentMethod: string;
  remarks: string;
}

const PaymentDescriptionV2: React.FC<PaymentDescriptionV2PropsInterface> = ({
  relatedSettings,
  receivedOn,
  paidAmount,
  paymentMethod,
  remarks
}) => {
  const paymentColumns = ["paymentDate", "paymentAmount", "paymentMode", "remarks"];

  return (
    <Box>
      <Box borderBottom={relatedSettings.isBordered && "1px solid black"}>
        <Box
          component="div"
          display="flex"
          borderTop="1px solid black"
          borderRight={relatedSettings.isBordered && "1px solid black"}
          borderBottom={relatedSettings.isBordered && "1px solid black"}
          borderLeft={relatedSettings.isBordered && "1px solid black"}
          paddingBottom="0.2cm"
          p="0.3cm"
          className={!relatedSettings.isBordered && "borderBotBlack1"}
          style={
            relatedSettings.isBordered
              ? getSectionStyle(relatedSettings.headerColor).rawHeaderStyle
              : {}
          }
        >
          {paymentColumns.map((column) => (
            <Box component="div" key={column} className="flex1">
              <Typography component="div">
                <Box
                  textAlign="left"
                  style={{ textTransform: "uppercase" }}
                  fontWeight={600}
                  fontSize={scaleFont("0.27cm")}
                >
                  {tl(`billing.paymentItem.${column}`)}
                </Box>
              </Typography>
            </Box>
          ))}
        </Box>
        <Box component="div">
          <Box
            component="div"
            display="flex"
            style={
              relatedSettings.isBordered
                ? {
                    pageBreakInside: "avoid"
                  }
                : {}
            }
          >
            <Box
              paddingLeft="0.3cm"
              paddingRight="0.3cm"
              borderLeft={relatedSettings.isBordered && "1px solid black"}
              component="div"
              className="flex1"
              paddingTop="0.2cm"
              paddingBottom="0.2cm"
            >
              <Typography component="div">
                <Box className="blockContent" fontSize={scaleFont("0.33cm")} textAlign="left">
                  {calFns.convertADtoBS(moment(receivedOn)).formatted4}
                </Box>
              </Typography>
            </Box>
            <Box
              paddingLeft="0.3cm"
              paddingRight="0.3cm"
              component="div"
              className="flex1"
              paddingTop="0.2cm"
              paddingBottom="0.2cm"
            >
              <Typography component="div">
                <Box className="blockContent" fontSize={scaleFont("0.33cm")} textAlign="left">
                  {paidAmount}
                </Box>
              </Typography>
            </Box>
            <Box
              paddingLeft="0.3cm"
              paddingRight="0.3cm"
              component="div"
              className="flex1"
              paddingTop="0.2cm"
              paddingBottom="0.2cm"
            >
              <Typography component="div">
                <Box className="blockContent" fontSize={scaleFont("0.33cm")} textAlign="left">
                  {paymentMethod.toUpperCase()}
                </Box>
              </Typography>
            </Box>
            <Box
              paddingLeft="0.3cm"
              paddingRight="0.3cm"
              borderRight={relatedSettings.isBordered && "1px solid black"}
              component="div"
              className="flex1"
              paddingTop="0.2cm"
              paddingBottom="0.2cm"
            >
              <Typography component="div">
                <Box className="blockContent" fontSize={scaleFont("0.33cm")} textAlign="left">
                  {remarks}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            component="div"
            borderRight={relatedSettings.isBordered && "1px solid black"}
            borderLeft={relatedSettings.isBordered && "1px solid black"}
          >
            <Typography component="div">
              <Box
                fontSize={scaleFont("0.33cm")}
                textAlign="left"
                paddingLeft="0.3cm"
                paddingBottom="0.2cm"
              >
                {`${numberToWords(paidAmount)} only`}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDescriptionV2;

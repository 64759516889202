import React from "react";
import startCase from "lodash/startCase";
import moment from "moment-timezone";
import { useReactToPrint } from "react-to-print";
import { Box, Button, IconButton } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import { SurveyData, StyledTypography } from "./SurveyCreateEditForm";
import Panel from "../../../components/Panel";
import SurveyFormPrint from "./SurveyFormPrint";
import SurveyFormContent from "./SurveyFormContent";
import { clientFullNameSelector } from "../../../reducers/client";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";

interface Props {
  data: SurveyData;
  onClose: () => void;
  onEdit: () => void;
}

const SurveyFormShow = ({ data, onEdit, onClose }: Props): JSX.Element => {
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Panel
      onClose={onClose}
      title="Survey Form"
      printButton={
        <Button variant="contained" size="small" onClick={handlePrint}>
          Print
        </Button>
      }
      editButton={
        <IconButton size="small" onClick={onEdit} data-testmation="consentFormEditButton">
          <Edit />
        </IconButton>
      }
    >
      <Box px={5} pt={1}>
        {data.client && (
          <div>
            <StyledTypography text={`Date: ${convertADtoBS(moment(data.created_at)).formatted2}`} />
            <StyledTypography
              text={`Patient Name: ${startCase(clientFullNameSelector(data.client))}`}
            />
            <StyledTypography text={`Phone: ${data.client.phone}`} />
          </div>
        )}
        <SurveyFormContent data={data} />
        <SurveyFormPrint ref={componentRef} data={data} />
      </Box>
    </Panel>
  );
};

export default SurveyFormShow;

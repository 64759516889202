import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../api/apiHelper";
import ContentEditableWithTextField from "../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import DebouncedTextField from "../../../components/DebouncedTextField";
import HtmlTooltip from "../../../components/HtmlTooltip";
import Descriptions from "../../../components/HtmlTooltip/descriptions";
import {
  RichTextfield,
  deserializeNode,
  proseMirrorOptions
} from "../../../components/RichTextfield";
import { tl } from "../../../components/translate";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { PermissionGroups } from "../../../interfaces/User";
import { spFromIdSelector } from "../../../reducers/serviceProvider";
import { useParsedProseMirror } from "../../Lab/LabEntry/LabTestSubRow";
import Can from "../../Policy/Can";
import LabTestNumberSettings from "../Settings/LabTestNumberSettings";
import ServiceProviderSelect from "./ServiceProviderSelect";
import { ModuleSettingsProps } from "./index";
import { notificationAdd } from "../../../actions/notification";
import HeaderImageSettings from "./HeaderImageSettings";
import LabMasterData from "./LabMasterData";
import LabTestEditPanel from "./LabTestEditPanel";
import LabGroupEditPanel from "./LabGroupEditPanel";

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))({
  "&.Mui-expanded": {
    minHeight: "25px"
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0"
  }
});

const SectionTitle = ({
  text,
  fontWeight
}: {
  fontWeight?: number;
  text: string | JSX.Element;
}) => (
  <Typography fontWeight={fontWeight} gutterBottom variant="h6">
    {text}
  </Typography>
);
const SubTitle = ({
  text,
  gutterBottom
}: {
  gutterBottom?: boolean;
  text: string | JSX.Element;
}) => (
  <Typography gutterBottom={gutterBottom} fontWeight={600}>
    {text}
  </Typography>
);

export default function LabSettings({
  resourceCentre,
  actions,
  serviceProviders,
  userGroup
}: ModuleSettingsProps & {
  serviceProviders: ServiceProvider[];
  userGroup: PermissionGroups;
}): JSX.Element {
  const [resourceCentres, setResourceCentres] = useState([]);
  const [labEdit, setLabEdit] = useState(false);
  const [groupEdit, setGroupEdit] = useState(false);
  const isRcAdmin = [PermissionGroups.ResourceCentreAdmin, PermissionGroups.CmsAdmin].includes(
    userGroup
  );

  const { labSettings } = resourceCentre;
  const { radiologySettings, pathologySettings, microbiologySettings } =
    resourceCentre?.labSettings;
  const accessingLabDetails = labSettings?.accessingLabCentreIds
    .map((id) => resourceCentres.find((item) => item.id === id))
    .filter(Boolean);
  const [state, setState] = useParsedProseMirror(labSettings.labRemarks || "", proseMirrorOptions);

  const labSignatureDynamicOptions = ["Entered by", "Assessed by", "Approved by"];
  const [labSp, setLabSp] = useState({
    authorizedSP1: null,
    authorizedSP2: null,
    authorizedSP3: null,
    authorizedSP4: null
  });

  const [radiologySp, setRadiologySp] = useState({
    authorizedSP1: null,
    authorizedSP2: null,
    authorizedSP3: null,
    authorizedSP4: null
  });

  const [microbiologySp, setMicrobiologySp] = useState({
    authorizedSP1: null,
    authorizedSP2: null,
    authorizedSP3: null,
    authorizedSP4: null
  });

  useEffect(() => {
    setLabSp({
      authorizedSP1: spFromIdSelector(serviceProviders, pathologySettings.authorizedSP1) || null,
      authorizedSP2: spFromIdSelector(serviceProviders, pathologySettings.authorizedSP2) || null,
      authorizedSP3: spFromIdSelector(serviceProviders, pathologySettings.authorizedSP3) || null,
      authorizedSP4: spFromIdSelector(serviceProviders, pathologySettings.authorizedSP4) || null
    });
  }, [pathologySettings, serviceProviders]);

  useEffect(() => {
    setRadiologySp({
      authorizedSP1: spFromIdSelector(serviceProviders, radiologySettings.authorizedSP1) || null,
      authorizedSP2: spFromIdSelector(serviceProviders, radiologySettings.authorizedSP2) || null,
      authorizedSP3: spFromIdSelector(serviceProviders, radiologySettings.authorizedSP3) || null,
      authorizedSP4: spFromIdSelector(serviceProviders, radiologySettings.authorizedSP4) || null
    });
  }, [radiologySettings, serviceProviders]);

  useEffect(() => {
    setMicrobiologySp({
      authorizedSP1: spFromIdSelector(serviceProviders, microbiologySettings.authorizedSP1) || null,
      authorizedSP2: spFromIdSelector(serviceProviders, microbiologySettings.authorizedSP2) || null,
      authorizedSP3: spFromIdSelector(serviceProviders, microbiologySettings.authorizedSP3) || null,
      authorizedSP4: spFromIdSelector(serviceProviders, microbiologySettings.authorizedSP4) || null
    });
  }, [microbiologySettings, serviceProviders]);

  useEffect(() => {
    api("accessingResourceCentre")
      .getAll({ resourceCentreId: resourceCentre.id })
      .then((response) => {
        setResourceCentres(response.data);
      });
  }, [resourceCentre.id]);
  const dispatch = useDispatch();
  const fileUploader = React.useRef<HTMLInputElement>();
  const [tempImg, setTempImg] = React.useState(null);
  const [isCropperOpen, setIsCropperOpen] = React.useState(false);
  const handleImageChange = (e) => {
    if (!e?.target?.files?.length) {
      return;
    }
    if (e?.target?.files[0]?.size >= 200000) {
      // show error dialog if file size exceeds 200KB
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "File size needs to be less than 200KB.",
          autoTimeout: true
        })
      );
    }
    const file = e?.target?.files[0];
    const extensionIndex = file?.name?.lastIndexOf(".") + 1; // getting starting index of file extension
    // getting extension name
    const extension = file?.name?.substr(extensionIndex)?.toLowerCase();
    if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif") {
      setTempImg(URL.createObjectURL(file));
      setIsCropperOpen(true);
    } else {
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Invalid image type.",
          autoTimeout: true
        })
      );
    }
  };
  return (
    <Box marginTop={2}>
      <Box mb={2}>
        <SectionTitle text="General" />
        <Box display="flex" gap={1} flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resourceCentre.labSettings.enableTestCreationFromBillOnly}
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.enableTestCreationFromBillOnly =
                      !draft.labSettings.enableTestCreationFromBillOnly;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.enableTestCreationFromBillOnly")}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resourceCentre.labSettings.additionallabdata}
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.additionallabdata = !draft.labSettings.additionallabdata;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.additionallabdata")}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resourceCentre.labSettings.enableInternalStock}
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.enableInternalStock = !draft.labSettings.enableInternalStock;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.enableInternalStock")}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resourceCentre.labSettings.enableExtraReferrer}
                onChange={() => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.enableExtraReferrer = !draft.labSettings.enableExtraReferrer;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            }
            label={tl("settings.enableExtraReferrer")}
          />
        </Box>
        <Box my={2}>
          <Typography sx={{ fontWeight: 600 }}>
            {tl("clinicSettings.accessingResourceCentre")}
          </Typography>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={resourceCentres}
            getOptionLabel={(option) => option?.name}
            filterSelectedOptions
            value={accessingLabDetails}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Accessing Lab"
                placeholder="Type accessing labs"
                sx={{ width: "300px" }}
              />
            )}
            size="small"
            onChange={(_, e) => {
              const dataIds = [];
              e.forEach((data) => {
                dataIds.push(data?.id);
              });
              const newRc = produce(resourceCentre, (draft) => {
                draft.labSettings.accessingLabCentreIds = dataIds;
              });
              actions.putResourceCentre(newRc);
            }}
          />
        </Box>
        <Box mt={2} border="1px solid #E0E0E0" borderRadius={2} maxWidth={500} p={1}>
          <LabTestNumberSettings resourceCentre={resourceCentre} />
          <Can policyAccessKey="deny" superAdminPass>
            <Box display="flex" alignItems="center" mt="16px" maxWidth="500px">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.labSettings.useCustomLabTestNumber}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.labSettings.useCustomLabTestNumber =
                          !draft.labSettings.useCustomLabTestNumber;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("clinicSettings.useLabTestNumber")}
              />
            </Box>
          </Can>
        </Box>
        {isRcAdmin && (
          <Box my={2}>
            <Typography variant="h6">Manage Lab Tests And Groups</Typography>
            <LabMasterData
              onGroupEdit={() => setGroupEdit(true)}
              onLabEdit={() => setLabEdit(true)}
            />
          </Box>
        )}
        {labEdit && <LabTestEditPanel onClose={() => setLabEdit(false)} />}
        {groupEdit && <LabGroupEditPanel onClose={() => setGroupEdit(false)} />}
        {userGroup === PermissionGroups.SuperAdmin && (
          <>
            <Box display="flex" alignItems="center" mt="16px" maxWidth="500px">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.labSettings.syncWithIMU}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.labSettings.syncWithIMU = !draft.labSettings.syncWithIMU;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.labSyncWithIMU")}
              />
            </Box>
            <Box ml={2}>
              <Box mt="8px">
                <RadioGroup
                  row
                  value={resourceCentre.labSettings.syncRightAway || 0}
                  onChange={(e) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.syncRightAway = Number(e.target.value);
                    });
                    actions.putResourceCentre(newRc);
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Sync once per day"
                    value={0}
                    style={{ width: "180px" }}
                  />
                  <FormControlLabel value={1} control={<Radio />} label="Sync right away" />
                </RadioGroup>
              </Box>
              <Box display="flex" alignItems="center" mt={1} mb={1}>
                <Typography>Username</Typography>
                <DebouncedTextField
                  style={{ marginLeft: "16px" }}
                  value={resourceCentre.labSettings.usernameIMU || ""}
                  debounceAt={1000}
                  onChange={(e) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.usernameIMU = e.target.value;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              </Box>

              <Box display="flex" alignItems="center" mt={1} mb={1}>
                <Typography>Password </Typography>
                <DebouncedTextField
                  type="password"
                  style={{ marginLeft: "16px" }}
                  value={resourceCentre.labSettings.passwordIMU || ""}
                  debounceAt={1000}
                  onChange={(e) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.passwordIMU = e.target.value;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              </Box>
            </Box>
          </>
        )}
        <Divider sx={{ my: 2 }} />
      </Box>
      <Box px={0.5} maxWidth={1100}>
        <SectionTitle fontWeight={600} text={tl("settings.labPrint")} />
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SubTitle text="Print Header" />
          </StyledAccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={
                  <Checkbox
                    data-testmation="showCustomerId"
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.labPrintSettings.showCustomerIdentifier
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.showCustomerIdentifier =
                          !resourceCentre.settings.printSettings.labPrintSettings
                            .showCustomerIdentifier;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.showCustomerIdentifier")}
              />
              <FormControlLabel
                data-testmation="showPhoto"
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.settings.printSettings.labPrintSettings.showPhoto}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.showPhoto =
                          !resourceCentre.settings.printSettings.labPrintSettings.showPhoto;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.showPhoto")}
              />
              <FormControlLabel
                data-testmation="includeLetterhead"
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.labPrintSettings.includeLetterhead
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.includeLetterhead =
                          !resourceCentre.settings.printSettings.labPrintSettings.includeLetterhead;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.includeLetterhead")}
              />
              {userGroup === "superAdmin" && (
                <>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      data-testmation="includeFooter"
                      control={
                        <Checkbox
                          size="small"
                          checked={
                            resourceCentre.settings.printSettings.labPrintSettings.includeFooter
                          }
                          onChange={() => {
                            const newRc = produce(resourceCentre, (draft) => {
                              draft.settings.printSettings.labPrintSettings.includeFooter =
                                !resourceCentre.settings.printSettings.labPrintSettings
                                  .includeFooter;
                            });
                            actions.putResourceCentre(newRc);
                          }}
                        />
                      }
                      label={tl("settings.includeFooter")}
                    />
                    {!resourceCentre.settings.printSettings.labPrintSettings.includeFooter && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={
                              resourceCentre.settings.printSettings.labPrintSettings
                                .hideFooterLogoCompletely
                            }
                            onChange={() => {
                              const newRc = produce(resourceCentre, (draft) => {
                                draft.settings.printSettings.labPrintSettings.hideFooterLogoCompletely =
                                  !resourceCentre.settings.printSettings.labPrintSettings
                                    .hideFooterLogoCompletely;
                              });
                              actions.putResourceCentre(newRc);
                            }}
                          />
                        }
                        label={tl("settings.hideFooterCompletely")}
                      />
                    )}
                    {!resourceCentre.settings.printSettings.labPrintSettings.includeFooter && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={
                                resourceCentre.settings.printSettings.labPrintSettings
                                  .showQrWhenFooterIsHidden
                              }
                              onChange={() => {
                                const newRc = produce(resourceCentre, (draft) => {
                                  draft.settings.printSettings.labPrintSettings.showQrWhenFooterIsHidden =
                                    !resourceCentre.settings.printSettings.labPrintSettings
                                      .showQrWhenFooterIsHidden;
                                });
                                actions.putResourceCentre(newRc);
                              }}
                            />
                          }
                          label={tl("settings.showQrWhenFooterIsHidden")}
                        />
                        <HtmlTooltip description={Descriptions.LAB_FOOTER_SHOW_QR} />
                      </>
                    )}
                  </Box>
                </>
              )}
              <Box display="flex" alignItems="center" gap={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        resourceCentre.settings.printSettings.labPrintSettings.showSampleTakenDate
                      }
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.settings.printSettings.labPrintSettings.showSampleTakenDate =
                            !resourceCentre.settings.printSettings.labPrintSettings
                              .showSampleTakenDate;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showSampleTakenDate")}
                />
                {resourceCentre.settings.printSettings.labPrintSettings.showSampleTakenDate && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          resourceCentre.settings.printSettings.labPrintSettings.showSampleTakenTime
                        }
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.labPrintSettings.showSampleTakenTime =
                              !resourceCentre.settings.printSettings.labPrintSettings
                                .showSampleTakenTime;
                          });
                          actions.putResourceCentre(newRc);
                        }}
                      />
                    }
                    label={tl("settings.showSampleTakenTime")}
                  />
                )}
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        resourceCentre.settings.printSettings.labPrintSettings.showReportOnDate
                      }
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.settings.printSettings.labPrintSettings.showReportOnDate =
                            !resourceCentre.settings.printSettings.labPrintSettings
                              .showReportOnDate;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showReportOnDate")}
                />
                {resourceCentre.settings.printSettings.labPrintSettings.showReportOnDate && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          resourceCentre.settings.printSettings.labPrintSettings.showReportOnTime
                        }
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.labPrintSettings.showReportOnTime =
                              !resourceCentre.settings.printSettings.labPrintSettings
                                .showReportOnTime;
                          });
                          actions.putResourceCentre(newRc);
                        }}
                      />
                    }
                    label={tl("settings.showReportOnTime")}
                  />
                )}
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        resourceCentre.settings.printSettings.labPrintSettings.showPrintedOnDate
                      }
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.settings.printSettings.labPrintSettings.showPrintedOnDate =
                            !resourceCentre.settings.printSettings.labPrintSettings
                              .showPrintedOnDate;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showPrintedOnDate")}
                />
                {resourceCentre.settings.printSettings.labPrintSettings.showPrintedOnDate && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          resourceCentre.settings.printSettings.labPrintSettings.showPrintedOnTime
                        }
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.labPrintSettings.showPrintedOnTime =
                              !resourceCentre.settings.printSettings.labPrintSettings
                                .showPrintedOnTime;
                          });
                          actions.putResourceCentre(newRc);
                        }}
                      />
                    }
                    label={tl("settings.showPrintedOnTime")}
                  />
                )}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.labSettings.enableLabIdentifier}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.labSettings.enableLabIdentifier =
                          !draft.labSettings.enableLabIdentifier;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.labIdentifier")}
              />
              <Box display="flex" alignItems="center" mt="8px">
                <ContentEditableWithTextField
                  label={tl("settings.customLabPrintTitle")}
                  placeholder="Type title here ..."
                  saveOnFocusOut
                  value={resourceCentre.settings.printSettings.labPrintSettings.customTitle}
                  onSave={(val) => {
                    if (val !== null || val !== undefined) {
                      const newRC = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.customTitle = val;
                      });
                      actions.putResourceCentre(newRC);
                    }
                  }}
                  width="200px"
                />
              </Box>
              <Box mt={2}>
                <Typography style={{ fontWeight: 600 }}>
                  {tl("clinicSettings.labPrintDate")}
                </Typography>
                <RadioGroup
                  row
                  value={resourceCentre.settings.printSettings.labPrintSettings.dateFormat}
                  onChange={(e) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.printSettings.labPrintSettings.dateFormat = e.target.value;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                >
                  <FormControlLabel
                    data-testmation="labDateAd"
                    control={<Radio />}
                    label="Date A.D"
                    value="AD"
                    style={{ width: "180px" }}
                  />
                  <FormControlLabel
                    data-testmation="labDateBs"
                    value="BS"
                    control={<Radio />}
                    label="Date B.S"
                  />
                </RadioGroup>
              </Box>
              <Box mt={2}>
                <Typography sx={{ fontWeight: 600 }} gutterBottom>
                  Government Template Print Headers
                </Typography>
                <ContentEditableWithTextField
                  label="First Header"
                  placeholder="First Header"
                  saveOnFocusOut
                  value={
                    resourceCentre.settings.printSettings.labPrintSettings.govTemplateHeaderText1
                  }
                  onSave={(val) => {
                    if (val) {
                      const newRC = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.govTemplateHeaderText1 = val;
                      });
                      actions.putResourceCentre(newRC);
                    }
                  }}
                  width="300px"
                />
                <ContentEditableWithTextField
                  label="Second Header"
                  placeholder="Second Header"
                  saveOnFocusOut
                  value={
                    resourceCentre.settings.printSettings.labPrintSettings.govTemplateHeaderText2
                  }
                  onSave={(val) => {
                    if (val) {
                      const newRC = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.govTemplateHeaderText2 = val;
                      });
                      actions.putResourceCentre(newRC);
                    }
                  }}
                  width="300px"
                />
              </Box>

              <HeaderImageSettings
                labSettings={labSettings}
                actions={actions}
                resourceCentre={resourceCentre}
                fileUploaderRef={fileUploader}
                onImageChange={handleImageChange}
                tempImg={tempImg}
                isCropperOpen={isCropperOpen}
                setIsCropperOpen={setIsCropperOpen}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SubTitle gutterBottom text="Print body" />
          </StyledAccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.labSettings.showSampleCollectedBy}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.labSettings.showSampleCollectedBy =
                          !draft.labSettings.showSampleCollectedBy;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.showSampleTakenBy")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.labPrintSettings.enableSpaciousMargin
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.enableSpaciousMargin =
                          !resourceCentre.settings.printSettings.labPrintSettings
                            .enableSpaciousMargin;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.enableSpaciousMargins")}
              />
              <Typography fontWeight={600}>Result Columns:</Typography>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={resourceCentre.labSettings.showMethods}
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.labSettings.showMethods = !draft.labSettings.showMethods;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showMethods")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={resourceCentre.labSettings.showRangeFlag}
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.labSettings.showRangeFlag = !draft.labSettings.showRangeFlag;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showRangeFlag")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={resourceCentre.labSettings.showRanges}
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.labSettings.showRanges = !draft.labSettings.showRanges;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.ranges")}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={resourceCentre.labSettings.showUnit}
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.labSettings.showUnit = !draft.labSettings.showUnit;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showUnit")}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={resourceCentre.labSettings.showReading}
                      onChange={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.labSettings.showReading = !draft.labSettings.showReading;
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    />
                  }
                  label={tl("settings.showReading")}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SubTitle gutterBottom text="Print footer" />
          </StyledAccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={600}>Pathology Settings</Typography>
            <Box display="flex" alignItems="center" maxWidth="52vw" mt="8px" ml={2}>
              <Typography style={{ width: "500px" }}>Authorized Signature 1</Typography>
              <ServiceProviderSelect
                dataTestmation="authorizedSP1"
                value={labSp.authorizedSP1}
                onChange={(v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP1 = v ? v.id : null;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />

              <Typography pl={3} pr={3}>
                OR
              </Typography>

              <Autocomplete
                options={labSignatureDynamicOptions}
                onChange={(e, v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP1Dynamic = v;
                  });
                  actions.putResourceCentre(newRc);
                }}
                value={resourceCentre.labSettings?.pathologySettings.authorizedSP1Dynamic}
                style={{ width: "100%" }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} />}
              />
              <HtmlTooltip description={Descriptions.AUTHORIZED_SIGNATURE} />
            </Box>
            <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
              <Typography style={{ width: "500px" }}>Authorized Signature 2</Typography>
              <ServiceProviderSelect
                value={labSp.authorizedSP2}
                onChange={(v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP2 = v ? v.id : null;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
              <Typography pl={3} pr={3}>
                OR
              </Typography>

              <Autocomplete
                options={labSignatureDynamicOptions}
                onChange={(e, v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP2Dynamic = v;
                  });
                  actions.putResourceCentre(newRc);
                }}
                value={resourceCentre?.labSettings?.pathologySettings.authorizedSP2Dynamic}
                style={{ width: "100%" }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
              <Typography style={{ width: "500px" }}>Authorized Signature 3</Typography>
              <ServiceProviderSelect
                value={labSp.authorizedSP3}
                onChange={(v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP3 = v ? v.id : null;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
              <Typography pl={3} pr={3}>
                OR
              </Typography>

              <Autocomplete
                options={labSignatureDynamicOptions}
                onChange={(e, v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP3Dynamic = v;
                  });
                  actions.putResourceCentre(newRc);
                }}
                value={resourceCentre?.labSettings?.pathologySettings?.authorizedSP3Dynamic}
                style={{ width: "100%" }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
              <Typography style={{ width: "500px" }}>Authorized Signature 4</Typography>
              <ServiceProviderSelect
                value={labSp.authorizedSP4}
                onChange={(v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP4 = v ? v.id : null;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
              <Typography pl={3} pr={3}>
                OR
              </Typography>

              <Autocomplete
                options={labSignatureDynamicOptions}
                onChange={(e, v) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.labSettings.pathologySettings.authorizedSP4Dynamic = v;
                  });
                  actions.putResourceCentre(newRc);
                }}
                value={resourceCentre?.labSettings?.pathologySettings?.authorizedSP4Dynamic}
                style={{ width: "100%" }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box mt={3}>
              <Typography fontWeight={600}>Radiology Settings</Typography>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 1</Typography>
                <ServiceProviderSelect
                  value={radiologySp.authorizedSP1}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP1 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP1Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings?.radiologySettings?.authorizedSP1Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 2</Typography>
                <ServiceProviderSelect
                  value={radiologySp.authorizedSP2}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP2 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP2Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings?.radiologySettings?.authorizedSP2Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 3</Typography>
                <ServiceProviderSelect
                  value={radiologySp.authorizedSP3}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP3 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP3Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings.radiologySettings?.authorizedSP3Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 4</Typography>
                <ServiceProviderSelect
                  value={radiologySp.authorizedSP4}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP4 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.radiologySettings.authorizedSP4Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings.radiologySettings?.authorizedSP4Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Box>
            <Box mt={3}>
              <Typography fontWeight={600}>Microbiology Settings</Typography>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 1</Typography>
                <ServiceProviderSelect
                  value={microbiologySp.authorizedSP1}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP1 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP1Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings?.microbiologySettings?.authorizedSP1Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 2</Typography>
                <ServiceProviderSelect
                  value={microbiologySp.authorizedSP2}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP2 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP2Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings?.microbiologySettings?.authorizedSP2Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 3</Typography>
                <ServiceProviderSelect
                  value={microbiologySp.authorizedSP3}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP3 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP3Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings.microbiologySettings?.authorizedSP3Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" alignItems="center" mt="16px" maxWidth="50vw" ml={2}>
                <Typography style={{ width: "500px" }}>Authorized Signature 4</Typography>
                <ServiceProviderSelect
                  value={microbiologySp.authorizedSP4}
                  onChange={(v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP4 = v ? v.id : null;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
                <Typography pl={3} pr={3}>
                  OR
                </Typography>

                <Autocomplete
                  options={labSignatureDynamicOptions}
                  onChange={(e, v) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.labSettings.microbiologySettings.authorizedSP4Dynamic = v;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                  value={resourceCentre.labSettings.microbiologySettings?.authorizedSP4Dynamic}
                  style={{ width: "100%" }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mt="16px" maxWidth="500px">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.labSettings.showLabRemarks}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.labSettings.showLabRemarks = !draft.labSettings.showLabRemarks;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.showLabRemarks")}
              />
              <HtmlTooltip description={Descriptions.LAB_REMARKS} />
            </Box>
            {resourceCentre.labSettings.showLabRemarks && (
              <Box display="flex" mt="16px" flexWrap="wrap">
                <Typography pr={2}>{tl("settings.labRemarks")}</Typography>
                <Box width="400px">
                  <RichTextfield state={state} setState={setState} />
                  <Box
                    width="100%"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "8px"
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const newRc = produce(resourceCentre, (draft) => {
                          draft.labSettings.labRemarks = deserializeNode(state.doc);
                        });
                        actions.putResourceCentre(newRc);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

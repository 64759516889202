import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { tl } from '../../../components/translate';
import styles from './styles.module.css';
import classNames from '../../../helpers/classNames';
import Can from '../../Policy/Can';

const CreateEditHeader = ({ activeTab, onChange }) => (
  <Box>
    <Typography>
      <Can policyAccessKey="booking:createBooking">
        <Box
          component="span"
          onClick={(e) => onChange(e, 'booking')}
          className={classNames(styles.createHeader, {
            [styles.createHeader__active]: activeTab === 'booking',
          })}
          data-testmation="bookingTab"
        >
          {tl('booking')}
        </Box>
      </Can>
      <Can policyAccessKey="booking:createSchedule">
        <Box
          component="span"
          className={classNames(styles.createHeader, {
            [styles.createHeader__active]: activeTab === 'schedule',
          })}
          onClick={(e) => onChange(e, 'schedule')}
          data-testmation="scheduleTab"
        >
          {tl('Schedule')}
        </Box>
      </Can>
    </Typography>
  </Box>
);

export default CreateEditHeader;

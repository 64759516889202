import * as React from 'react';
import { connect } from 'react-redux';
import ClientShow from './ClientShow';
import { Dialog, Box, Zoom, DialogContent, Typography } from '@mui/material';
import * as clientActions from '../../actions/client';
import { TransitionProps } from 'react-transition-group/Transition';
import { clientFullNameSelector } from '../../reducers/client';
import styles from './style.module.css';
import { isReadOnlyUser } from '../NavigationBar/SideBar';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

export const ClientNameWithInfoModal = ({
  client,
  fontWeight,
  fontSize,
  clientId,
  actions,
  extraInfo,
  isReadOnlyUser = false
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  React.useEffect(() => {
    if (!client) {
      actions.loadClient(clientId);
    }
  }, []);
  if (!client) return '...';
  return (
    <Typography>
      <Box
        className={styles.clientName}
        component={'span'}
        fontWeight={fontWeight || 500}
        fontSize={fontSize || '14px'}
        onClick={(e) => {
          e.stopPropagation();
          !isReadOnlyUser && setModalOpen(true);
        }}
      >
        {clientFullNameSelector(client)}
      </Box>
      {extraInfo && (
        <Box fontSize="small" color="gray">
          {extraInfo}
        </Box>
      )}
      <ClientInfoModal
        client={client}
        open={modalOpen}
        onClose={(e) => {
          setModalOpen(false);
        }}
      />
    </Typography>
  );
};

const ClientInfoModal = ({ client, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        onClose();
        e.preventDefault();
        e.stopPropagation();
      }}
      TransitionComponent={Transition}
    >
      <DialogContent
        classes={{ root: styles.clientInfoModalRoot }}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <ClientShow client={client} showActionbar={true} />
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  ({ clients, userContext }, { clientId, client }) => {
    const permissionGroup =
      userContext?.userCreds?.userGroups && userContext.userCreds.userGroups[0];
    return {
      client: client || clients.collection?.find(({ id }) => Number(clientId) === id),
      isReadOnlyUser: isReadOnlyUser(permissionGroup)
    };
  },
  (dispatch) => ({
    actions: { loadClient: (clientId) => dispatch(clientActions.getClientById(clientId)) }
  })
)(ClientNameWithInfoModal);

import { ReducerBuilder } from './ReducerBuilder';
import * as ServiceTagActions from '../actions/serviceTags';
import { sendToSentry } from '../helpers/sentry';
import * as Sentry from '@sentry/browser';

const INITIAL_STATE = {
  collection: []
};

function setServiceTags(state, payload) {
  return { ...state, collection: payload };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(ServiceTagActions.Type.GET_SERVICETAGS, setServiceTags)
  .build();

export default reducer;

export function getSpeciality(tags, id) {
  if (Array.isArray(tags)) {
    return tags.find((item) => item.id === id)?.noun || '';
  } else {
    sendToSentry({
      tagKey: 'function',
      tagValue: 'getSpeciality',
      severity: Sentry.Severity.Debug,
      user: {},
      extras: {},
      error: tags
    });
    return '';
  }
}

import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { SETTING_ROUTES } from "../ResourceCentreShow";
import useCan from "../../../hooks/useCan";
import SectionHeading from "../SectionHeading";
import useIsAccountSubscribed from "../../../hooks/accounts";
import { tl } from "../../../components/translate";

interface UserSettingsProps {
  resourceCentreId: number;
  children: React.ReactNode;
}

const UserSettings = ({ resourceCentreId, children }: UserSettingsProps): JSX.Element => {
  const { EMPLOYEES, SERVICE_PROVIDERS, NON_USER_EMPLOYEE, ALL_USERS, BOOKABLE_RESOURCE } =
    SETTING_ROUTES;
  const isAccountSubscribed = useIsAccountSubscribed();
  const [value, setValue] = React.useState(EMPLOYEES);
  const dispatch = useDispatch();
  const canAccessSP = useCan("doctors:listDoctors", { superAdminPass: true });
  const canAccessAdmin = useCan("clinic:listAdmin", { superAdminPass: true });
  const navigateTo = (path: string) => {
    dispatch(push(path));
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <SectionHeading name={tl("UserSettings")} />
      <Box sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}>
        <Tabs
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          aria-label="wrapped label tabs example"
        >
          {canAccessAdmin && (
            <Tab
              value={EMPLOYEES}
              label={tl("AdministrativeUser")}
              onClick={() => navigateTo(`/resourcecentres/${resourceCentreId}/${EMPLOYEES}`)}
            />
          )}
          {canAccessSP && (
            <Tab
              value={SERVICE_PROVIDERS}
              label={tl("ServiceProviders")}
              onClick={() =>
                navigateTo(`/resourcecentres/${resourceCentreId}/${SERVICE_PROVIDERS}`)
              }
              data-testmation="serviceProviderTab"
            />
          )}
          {canAccessAdmin && isAccountSubscribed && (
            <Tab
              value={NON_USER_EMPLOYEE}
              label={tl("EmployeeOnly")}
              onClick={() =>
                navigateTo(`/resourcecentres/${resourceCentreId}/${NON_USER_EMPLOYEE}`)
              }
              data-testmation="employeeOnlyTab"
            />
          )}
          {canAccessAdmin && (
            <Tab
              value={ALL_USERS}
              label={tl("All")}
              onClick={() => navigateTo(`/resourcecentres/${resourceCentreId}/${ALL_USERS}`)}
              data-testmation="allTab"
            />
          )}
          <Tab
            value={BOOKABLE_RESOURCE}
            label={tl("booking.bookableResources")}
            onClick={() => navigateTo(`/resourcecentres/${resourceCentreId}/${BOOKABLE_RESOURCE}`)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
    </Box>
  );
};

export default UserSettings;

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Dialog,
  TextField,
  Autocomplete
} from "@mui/material";
import * as React from "react";
import WarningIcon from "@mui/icons-material/Warning";

import produce from "immer";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import {
  deleteAllergy,
  genericMedicines,
  getClientAllergies,
  postClientAllergy,
  putClientAllergy
} from "../../api/allergies";
import { Allergy, GenericMedicine } from "../../interfaces/Allergy";

interface GenericMedicineProps {
  value: GenericMedicine | null;
  onChange: (v: GenericMedicine) => void;
}

export enum AllergyTypes {
  MedicineAllergy = "MedicineAllergy",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Allergy = "Allergy",
  MedicalCondition = "MedicalCondition"
}

const initialState: AllergyModalState = {
  type: AllergyTypes.MedicineAllergy,
  name: "",
  information: "",
  extraInfo: null
};

const allergyLabels = {
  MedicineAllergy: "Medicine Allergy",
  Allergy: "Allergy",
  MedicalCondition: "Medical and Surgical Condition"
};

function GenericMedicinesSearch({ value, onChange }: GenericMedicineProps) {
  const [options, setOptions] = React.useState<Array<GenericMedicine>>([]);
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      style={{ width: "240px" }}
      onChange={(_, v) => {
        if (typeof v !== "string") {
          onChange(v);
        }
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Search Medicine"
          variant="outlined"
          margin="dense"
          onChange={async (e) => {
            if (e.target.value?.length > 2) {
              const medicines = await genericMedicines(e.target.value);
              setOptions(medicines);
            }
          }}
        />
      )}
    />
  );
}

interface AllergyItemProps {
  item: Allergy;
  onEditClick: () => void;
}

function AllergyItem({ item, onEditClick }: AllergyItemProps) {
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  return (
    <Box
      width="100%"
      display="flex"
      px="8px"
      py="4px"
      alignItems="center"
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      position="relative"
    >
      <WarningIcon htmlColor="#db2323" />
      <Typography style={{ marginLeft: "8px" }}>{item.name}</Typography>
      {showEdit && (
        <Typography
          sx={{
            textDecoration: "underlined",
            position: "absolute",
            cursor: "pointer",
            right: "4px",
            backgroundColor: "#f5fbf8",
            px: "2px"
          }}
          onClick={() => {
            onEditClick();
          }}
        >
          Edit
        </Typography>
      )}
    </Box>
  );
}

interface AllergiesProps {
  clientId: number | undefined;
}

function AddEditAllergyModal({
  openStatus,
  handleClose,
  clientId,
  onLocalUpdate,
  selectedItem,
  onLocalDelete,
  mode
}: AllergyModalProps) {
  const [state, setState] = React.useState<AllergyModalState | Allergy>(
    selectedItem || initialState
  );
  React.useEffect(() => {
    if (selectedItem) {
      setState(selectedItem);
    }
  }, [selectedItem]);

  return (
    <Dialog
      open={openStatus}
      onClose={() => {
        handleClose();
        setState(initialState);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography>Risk Information</Typography>
          {mode === "edit" && (
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await deleteAllergy(selectedItem.id);
                onLocalDelete(selectedItem.id);
                handleClose();
              }}
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          data-testmation="allergyType"
          id="combo-box-demo"
          freeSolo
          options={Object.keys(AllergyTypes)}
          getOptionLabel={(option) => allergyLabels[option]}
          style={{ width: "240px" }}
          renderInput={(params) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} label="Type" variant="outlined" margin="dense" />
          )}
          onChange={(e, v) => {
            setState({
              ...state,
              type: v,
              ...(v !== AllergyTypes.MedicineAllergy ? { extraInfo: null } : {})
            });
          }}
          value={state.type}
        />
        {state.type === AllergyTypes.MedicineAllergy ? (
          <GenericMedicinesSearch
            onChange={(v) => {
              setState({ ...state, extraInfo: v, name: v.name, medicineGenericId: v.id });
            }}
            value={state.extraInfo}
          />
        ) : (
          <TextField
            data-testmation="allergyName"
            label={state.type === AllergyTypes.Allergy ? "Name" : "Condition"}
            style={{ width: "360px" }}
            margin="dense"
            variant="outlined"
            value={state.name}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        )}
        <TextField
          label="Note"
          placeholder="Extra info regarding the allergy..."
          multiline
          style={{ marginTop: "16px" }}
          rows={4}
          variant="outlined"
          fullWidth
          value={state.information}
          onChange={(e) => setState({ ...state, information: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            setState(initialState);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          data-testmation="save"
          onClick={async () => {
            handleClose();
            setState(initialState);
            let response;
            if (mode === "create") {
              response = await postClientAllergy(clientId, state);
            }
            if (mode === "edit") {
              response = await putClientAllergy(state as Allergy);
            }
            onLocalUpdate(response as Allergy);
          }}
          color="primary"
          autoFocus
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Allergies({ clientId }: AllergiesProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [clientAllergies, setClientAllergies] = React.useState<Array<Allergy>>([]);
  const [selectedItem, setSelectedItem] = React.useState<Allergy>(null);
  const [mode, setMode] = useState<"create" | "edit" | null>(null);

  React.useEffect(() => {
    if (clientId) {
      (async () => {
        const response = await getClientAllergies(clientId);
        setClientAllergies(response);
      })();
    }
  }, [clientId]);

  return (
    <Box width={380}>
      <Box display="flex" justifyContent="space-between">
        <Typography sx={{ fontWeight: 600 }}>Risk Information</Typography>
        <Button
          disabled={!clientId}
          data-testmation="addRiskInformation"
          sx={{
            textDecoration: "underline",
            cursor: clientId ? "pointer" : "",
            color: clientId ? "inherit" : "#b7b5b5"
          }}
          onClick={() => {
            setDialogOpen(Boolean(clientId));
            setMode("create");
          }}
        >
          Add
        </Button>
      </Box>
      {clientId ? (
        <>
          <Box
            width={380}
            border={clientAllergies.length !== 0 ? "2px solid #d97016" : "0"}
            maxHeight="140px"
            borderRadius="5px"
            sx={{ overflowY: "auto" }}
          >
            {clientAllergies.map((item) => (
              <AllergyItem
                key={item.id}
                item={item}
                onEditClick={() => {
                  setMode("edit");
                  setSelectedItem(item);
                  setDialogOpen(true);
                }}
              />
            ))}
          </Box>
          {!clientAllergies.length && (
            <Typography color="grey" variant="subtitle1">
              No risk information to show.
            </Typography>
          )}
        </>
      ) : (
        <Typography color="grey" variant="subtitle1">
          Please select a client.
        </Typography>
      )}
      <AddEditAllergyModal
        openStatus={dialogOpen}
        mode={mode}
        handleClose={() => {
          setMode(null);
          setDialogOpen(false);
          setSelectedItem(null);
        }}
        clientId={clientId}
        onLocalUpdate={(savedItem) => {
          const updatedState = produce(clientAllergies, (draft) => {
            if (mode === "create") {
              draft.push(savedItem);
            } else {
              const allergyIndex = draft.findIndex((obj) => obj.id === savedItem.id);
              if (allergyIndex !== -1) {
                draft[allergyIndex] = savedItem;
              }
            }
          });
          setClientAllergies(updatedState);
        }}
        selectedItem={selectedItem}
        onLocalDelete={(id) => {
          const updatedState = produce(clientAllergies, (draft) =>
            draft.filter((el) => el.id !== id)
          );
          setClientAllergies(updatedState);
        }}
      />
    </Box>
  );
}

interface AllergyModalProps {
  openStatus: boolean;
  handleClose: () => void;
  clientId: number;
  onLocalUpdate: (v: Allergy) => void;
  selectedItem: Allergy;
  onLocalDelete: (v: number) => void;
  mode: string;
}

export interface AllergyModalState {
  type: string;
  name: string;
  information: string;
  extraInfo: GenericMedicine | null;
}

export default Allergies;

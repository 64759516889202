import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";
import produce from "immer";
import { omit } from "lodash";
import * as React from "react";
import RestForm from "./RestForm";
import TeethMarkerComponent, { TeethData } from "./TeethMarkerComponent";

export interface IntraOralAdvancedData {
  teethData: Array<TeethData>;
  softTissueExamination: string;
  occlusion: string;
  calculus: string;
  stain: string;
  cariesRisk: string;
  recallGap: string;
  attrition: string;
  abrasion: string;
  erosion: string;
  abfraction: string;
  bpe: Array<string>;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  data: IntraOralAdvancedData;
  onChange: (v: IntraOralAdvancedData) => void;
  onCancel: () => void;
}

export default function IntraOralAdvancedForm(props: Props): JSX.Element {
  const { open, handleClose, data, onChange, onCancel } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      PaperProps={{
        sx: { borderRadius: 0 }
      }}
    >
      <DialogTitle id="alert-dialog-title">Advanced Intra Oral Examination</DialogTitle>
      <DialogContent>
        <Box>
          <TeethMarkerComponent
            teethData={data.teethData}
            onChange={(teeth) => {
              const updatedState = produce(data, (draft) => {
                draft.teethData = teeth;
              });
              onChange(updatedState);
            }}
          />
          <RestForm
            data={omit(data, ["teethData"])}
            onChange={(v) => onChange({ ...data, ...v })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
            handleClose();
          }}
        >
          Cancel / Clear
        </Button>
        <Button onClick={handleClose} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from "react";

const Chat = ({
  color = "#009654",
  height = "20",
  width = "20"
}: {
  color?: string;
  height?: string;
  width?: string;
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6203 18.0125H19.5828L19.5453 18.0078C19.4187 17.9937 19.2922 17.9656 19.1703 17.9141L16.5125 16.9719L16.4937 16.9625C16.4375 16.9391 16.3765 16.925 16.3109 16.925C16.25 16.925 16.1937 16.9344 16.1375 16.9578C16.0765 16.9813 15.4906 17.2016 14.8812 17.3703C14.5484 17.4594 13.3953 17.7594 12.4719 17.7594C10.1047 17.7594 7.89217 16.85 6.24217 15.2C4.60623 13.5641 3.70623 11.3938 3.70623 9.08281C3.70623 8.49219 3.77185 7.89687 3.89842 7.32031C4.31092 5.41719 5.4031 3.6875 6.96873 2.44531C8.54842 1.18906 10.5547 0.5 12.6078 0.5C15.0547 0.5 17.3375 1.4375 19.0297 3.13437C20.6328 4.74219 21.5094 6.85156 21.5 9.08281C21.5 10.7422 21.0078 12.35 20.0797 13.7328L20.0562 13.7703C20.0375 13.7938 20.0234 13.8172 20.0047 13.8406C19.9812 13.8781 19.9672 13.9109 19.9578 13.9344L20.6937 16.5547C20.7219 16.6437 20.7359 16.7375 20.7453 16.8312V16.8875C20.7453 17.5063 20.239 18.0125 19.6203 18.0125ZM17.0469 15.5703L19.0578 16.2828L18.4812 14.225C18.3875 13.8828 18.4625 13.4984 18.7109 13.0766L18.7156 13.0672C18.7578 13.0016 18.8 12.9359 18.8469 12.8703C19.6015 11.7406 20 10.4281 20 9.07344C20.0047 7.24531 19.2828 5.51094 17.9656 4.18906C16.5547 2.77812 14.6515 2 12.6078 2C9.1156 2 6.06404 4.37188 5.35623 7.63906C5.2531 8.1125 5.20154 8.6 5.20154 9.08281C5.20154 13.0391 8.45935 16.2594 12.4672 16.2594C13.0531 16.2594 13.9203 16.0813 14.4828 15.9266C15.0359 15.7766 15.5844 15.5703 15.6078 15.5609C15.8328 15.4766 16.0672 15.4344 16.3109 15.4344C16.5594 15.4297 16.8078 15.4766 17.0469 15.5703Z"
      fill={color}
    />
    <path
      d="M2.37034 21.5C2.11253 21.5 1.86409 21.4109 1.66253 21.2516L1.64847 21.2422C1.3344 20.9797 1.18909 20.5625 1.26878 20.1641C1.40472 19.4656 1.68597 17.9984 1.78909 17.4547C1.78909 17.4547 1.78909 17.4547 1.78909 17.45C1.00628 16.2875 0.565654 14.9281 0.504717 13.5312C0.443779 12.1297 0.776592 10.7422 1.45628 9.51406C1.65784 9.15313 2.11253 9.02187 2.47815 9.22344C2.83909 9.425 2.97034 9.87969 2.76878 10.2453C1.64847 12.2609 1.7469 14.7031 3.03597 16.6203L3.04065 16.625C3.21409 16.8875 3.37815 17.2016 3.29847 17.5719C3.27972 17.6656 3.06409 18.7766 2.8719 19.7844L4.87347 19.0016C5.2344 18.8609 5.63284 18.8656 5.99378 19.0156C6.8469 19.3484 7.73753 19.5219 8.56253 19.5219C8.56722 19.5219 8.56722 19.5219 8.5719 19.5219C9.75315 19.5219 10.9203 19.2078 11.9422 18.6172C12.2985 18.4109 12.7578 18.5328 12.9688 18.8891C13.175 19.2453 13.0532 19.7047 12.6969 19.9156C11.4453 20.6422 10.0203 21.0219 8.57659 21.0219C8.5719 21.0219 8.5719 21.0219 8.56722 21.0219C7.55472 21.0219 6.47659 20.8109 5.44534 20.4078L5.42659 20.3984L2.80628 21.425C2.67503 21.4813 2.5344 21.5094 2.38909 21.5094C2.37503 21.5 2.37503 21.5 2.37034 21.5Z"
      fill={color}
    />
  </svg>
);

export default Chat;

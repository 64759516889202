import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserAndRcProfiles } from "../api/user";

export const fetchUserProfiles = createAsyncThunk(
  "chatProfiles",
  async (userIds: Array<number>, { rejectWithValue }) => {
    try {
      const response = await getUserAndRcProfiles(userIds);
      return response.users;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const chatSlice = createSlice({
  name: "chats",
  initialState: {
    chatLists: [],
    selectedChat: {},
    chatProfiles: {},
    unreadMessageCount: 0
  },
  reducers: {
    updateChatList: (draft, { payload }) => {
      draft.chatLists = payload;
    },
    updateSelectedChat: (draft, { payload }) => {
      draft.selectedChat = payload;
    },
    updateUnreadCount: (draft, { payload }) => {
      draft.unreadMessageCount = payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUserProfiles.fulfilled, (draft, { payload }) => {
      draft.chatProfiles = { ...draft.chatProfiles, ...payload };
    });
  }
});

export const { updateChatList, updateSelectedChat, updateUnreadCount } = chatSlice.actions;
export default chatSlice.reducer;

import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { connect } from "react-redux";
import usePrintStyles from "../../../hooks/usePrintStyles";
import LabPrintLetterHead, {
  ResourceCentre
} from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import LabPrintClientInfo from "../../Lab/LabPrint/LabprintComponents/LabPrintClientInfo";
import IpdCoreData from "./IpdCoreData";
import { IPD } from "../../../interfaces/IpdInterface";
import logo from "../../../../assets/images/poweredByOkhati.png";

interface PrintDischargeInterface {
  resourceCentre: ResourceCentre;
  ipdData: IPD;
}

const PrintDischarge: React.FC<PrintDischargeInterface> = ({ resourceCentre, ipdData }) => {
  usePrintStyles({ pageStyle: "size: A4; margin: 0mm" }).runPrintStyles();
  const patientInfo = ipdData ? ipdData.ipdRecord.patientRelatedInformation : null;
  const printRef = useRef(null);
  const includeLetterHead =
    resourceCentre.settings.printSettings?.ipdPrintSettings?.includeLetterhead;

  return (
    <div>
      <ReactToPrint
        trigger={() => <PrintIcon style={{ cursor: "pointer" }} />}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="dischargePrint">
        <div ref={printRef}>
          <Box component="table" width="100%">
            {/* <tfoot>
              <tr>
                <td>
                  footer offset
                  <div style={{ height: '1cm' }} />
                </td>
              </tr>
            </tfoot> */}
            <thead>
              <tr>
                <td>
                  {includeLetterHead ? (
                    <Box sx={{ margin: ".5cm 1cm" }}>
                      {resourceCentre && (
                        <LabPrintLetterHead resourceCentre={resourceCentre} titleFontScale={1} />
                      )}
                    </Box>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <Box sx={{ height: "3.5cm" }} />
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Box component="div" style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>
                      {ipdData.ipdRecord.dischargeSummary?.dischargePrintTitle || "Discharge Form"}
                    </Typography>
                  </Box>
                  {patientInfo && (
                    <Box
                      component="div"
                      style={{
                        border: "1px solid black",
                        margin: ".5cm 1cm"
                      }}
                      p="5px"
                    >
                      <LabPrintClientInfo
                        client={patientInfo}
                        customerNumber={ipdData?.customer?.customerNumber || null}
                        claimCode={ipdData.claimCode}
                        ssfId={ipdData.client?.ssfId}
                      />
                    </Box>
                  )}
                  <Box style={{ margin: ".5cm 1cm" }}>
                    <IpdCoreData ipdData={ipdData} />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              bottom: "10px",
              borderTop: "1px solid black",
              height: "1.5cm",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              pr: 2
            }}
          >
            <img src={logo} alt="logo" width={80} />
          </Box>
        </div>
      </iframe>
    </div>
  );
};

export default connect((state) => {
  const resourceCentre =
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;

  return { resourceCentre };
}, null)(PrintDischarge);

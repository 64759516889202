import React, { useState } from "react";
import { Box, Button, Popover, TextField, Typography } from "@mui/material";
import { ClockIcon } from "@primer/octicons-react";
import IconButton from "@mui/material/IconButton";
import StopCircleIcon from "@mui/icons-material/StopCircle";

import useElapsedTime, {
  userEnteredTimeObjToSeconds,
  convertToHourMinuteSecondObj,
  UserEnteredTimeFormat
} from "../../hooks/useElapsedTime";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface Props {
  opdDuration: number | null;
  assessmentId: number;
  opdTimeRef: React.RefObject<HTMLButtonElement>;
}

function OPDDuration({ opdTimeRef, assessmentId, opdDuration }: Props): JSX.Element {
  const { formattedTime, startTimer, stopTimer, isStarted, setElapsedTime, elapsedTime } =
    useElapsedTime(opdDuration);

  const [userEnteredTime, setUserEnteredTime] = useState<UserEnteredTimeFormat>({
    hour: 0,
    minute: 0,
    second: 0
  });

  const [totalMilliSeconds, setTotalMilliSeconds] = useState(0);

  const [pickerAnchorEl, setPickerAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const assessmentLabels = useAssessmentLabel();
  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPickerAnchorEl(event.currentTarget);
  };

  const handlePickerClose = () => {
    setPickerAnchorEl(null);
  };

  const handleUserEnteredTime = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setUserEnteredTime((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const isPickerOpen = Boolean(pickerAnchorEl);
  const pickerElId = isPickerOpen ? "picker" : undefined;

  // sync elapsed time with user entered time
  React.useEffect(() => {
    // pause syncing if picker is open
    if (!isPickerOpen) {
      // saving time as millisecond to sent to server
      setTotalMilliSeconds(elapsedTime * 1000);
      setUserEnteredTime(convertToHourMinuteSecondObj(elapsedTime));
    }
  }, [elapsedTime, isPickerOpen]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    // start timer if assessment is new
    if (!opdDuration && !assessmentId && !isStarted) {
      return startTimer();
    }
    // set elapsed time and stop timer if editing an old assessment
    if (assessmentId) {
      setUserEnteredTime(() =>
        opdDuration
          ? convertToHourMinuteSecondObj(opdDuration / 1000)
          : { hour: 0, minute: 0, second: 0 }
      );
      setElapsedTime(Number(opdDuration) / 1000);
      stopTimer();
    }
  }, [assessmentId, opdDuration]);

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center", position: "relative", mt: "2px" }}>
      <Typography
        component="legend"
        variant="caption"
        sx={{
          position: "absolute",
          top: "2px",
          left: "8px",
          backgroundColor: "#fff",
          zIndex: "2",
          px: 0.5,
          py: 0,
          lineHeight: 0.8
        }}
      >
        {assessmentLabels.opdDuration}
      </Typography>
      <Button
        color="inherit"
        variant="outlined"
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          borderColor: "#c4c4c4",
          py: "6px"
        }}
        onClick={handleAnchorClick}
        ref={opdTimeRef}
        value={totalMilliSeconds}
      >
        <ClockIcon />
        {formattedTime}
      </Button>
      <Popover
        id={pickerElId}
        open={isPickerOpen}
        anchorEl={pickerAnchorEl}
        onClose={handlePickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "center"
        }}
      >
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={2}
          component="form"
          id="picker-form"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1
            }}
          >
            <TextField
              name="hour"
              InputLabelProps={{ shrink: true }}
              label="hour"
              sx={{ width: "50px" }}
              variant="filled"
              size="small"
              autoFocus
              value={userEnteredTime.hour}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^\d$|^1\d$|^2[0-4]$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  handleUserEnteredTime(e);
                }
              }}
            />
            :
            <TextField
              name="minute"
              InputLabelProps={{ shrink: true }}
              label="minute"
              sx={{ width: "50px" }}
              variant="filled"
              size="small"
              value={userEnteredTime.minute}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^([0-5]?[0-9]|60)$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  handleUserEnteredTime(e);
                }
              }}
            />
          </Box>
          <Button
            variant="contained"
            size="small"
            form="picker-form"
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              handlePickerClose();
              setElapsedTime(userEnteredTimeObjToSeconds(userEnteredTime));
            }}
          >
            Ok
          </Button>
        </Box>
      </Popover>
      <IconButton
        size="small"
        color="error"
        onClick={stopTimer}
        disabled={!isStarted}
        title="stop timer"
      >
        <StopCircleIcon fontSize="large" />
      </IconButton>
    </Box>
  );
}

export default OPDDuration;

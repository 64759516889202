import React from "react";
import { Box, Typography } from "@mui/material";
import { tl } from "../../components/translate";
import { scaleFont } from "../../components/Print/Print";
import { rupeeDisplay } from "../../helpers/rupee";
import BillPrintAmounts from "./PrintBill/BillPrintAmounts";
import { BillType } from "../../interfaces/BillInterfaces";

export const BillSummaryRow = ({
  label,
  value,
  discountPercent,
  smallFont = false,
  noMarginTop = false,
  showVatPercent = false,
  isGrandTotal = false
}: {
  label: JSX.Element;
  value: string | number;
  showVatPercent?: boolean;
  discountPercent?: number;
  smallFont?: boolean;
  noMarginTop?: boolean;
  isGrandTotal?: boolean;
}): JSX.Element => (
  <Box mt={noMarginTop ? 0 : "0.1cm"}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Typography
        sx={{
          fontSize: scaleFont("0.3cm", smallFont ? 0.85 : 1.15),
          fontWeight: isGrandTotal ? 600 : 500
        }}
      >
        {label}{" "}
        {showVatPercent && (
          <Box component="span" ml="0.3rem">
            13%
          </Box>
        )}
        {Boolean(discountPercent) && (
          <Box component="span" ml="0.3rem">
            {`${discountPercent}%`}
          </Box>
        )}
      </Typography>
      <Typography
        sx={{
          textAlign: "right",
          fontWeight: isGrandTotal ? 600 : 500,
          fontSize: scaleFont("0.3cm", smallFont ? 0.85 : 1.15)
        }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

const BillPrintSummary = ({
  totalPerUnitTimesQty,
  netTotal,
  showIndividualVAT,
  discount,
  taxAmount,
  taxAmtWithRates,
  discountPercent,
  smallFont = false,
  noMarginTop = false,
  billData,
  clientBalance = 0
}: {
  totalPerUnitTimesQty: number;
  discount: number;
  taxAmount: number;
  netTotal: number;
  discountPercent?: number;
  showIndividualVAT?: boolean;
  taxAmtWithRates?: Record<string, number>;
  smallFont?: boolean;
  noMarginTop?: boolean;
  billData?: BillType;
  clientBalance: number;
}): JSX.Element => (
  <div>
    {Boolean(totalPerUnitTimesQty) && (
      <BillSummaryRow
        label={tl("billing.total")}
        value={rupeeDisplay(totalPerUnitTimesQty.toFixed(2))}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    <BillSummaryRow
      label={tl("billing.discount")}
      value={rupeeDisplay(discount.toFixed(2 || 0))}
      discountPercent={discountPercent}
      smallFont={smallFont}
      noMarginTop={noMarginTop}
    />
    {showIndividualVAT &&
      Object.keys(taxAmtWithRates || {})
        .filter((vatPct) => Number(vatPct) !== 0)
        .map((vatPct, index) => (
          <BillSummaryRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            label={tl("billing.VAT")}
            value={taxAmtWithRates[vatPct].toFixed(2)}
            smallFont={smallFont}
            noMarginTop={noMarginTop}
          />
        ))}
    {Boolean(netTotal) && (
      <BillSummaryRow
        label={tl("billing.taxableAmount")}
        value={rupeeDisplay(netTotal.toFixed(2))}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    {Boolean(taxAmount) && (
      <BillSummaryRow
        showVatPercent
        label={tl("billing.VAT")}
        value={rupeeDisplay(taxAmount.toFixed(2))}
        smallFont={smallFont}
        noMarginTop={noMarginTop}
      />
    )}
    {billData && (
      <BillPrintAmounts
        billData={billData}
        clientBalance={clientBalance}
        smallFont={smallFont}
        noMarginTop
      />
    )}
  </div>
);

export default BillPrintSummary;

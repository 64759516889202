import produce from "immer";
import { ReducerBuilder } from "./ReducerBuilder";
import * as stockProductActions from "../actions/stockProductActions";
import { ProductInterface } from "../interfaces/ProductInterface";

interface StateProductInterface {
  collection: ProductInterface[];
  lastTouched: ProductInterface | null;
  total: number;
}

const INITIAL_STATE: StateProductInterface = {
  collection: [],
  lastTouched: null,
  total: 0
};

function getStockProducts(state, payload) {
  if (!payload) {
    return state;
  }
  return { ...state, collection: payload.results, total: payload.total };
}

function getStockProductById(state, payload) {
  if (!payload) return state;
  return produce(state, (draft) => {
    const index = draft.collection.findIndex((product) => product.id === payload.id);
    if (index !== -1) {
      draft.collection[index] = payload;
    } else {
      draft.collection.push(payload);
    }
  });
}

function getStockProductByIds(state, payload) {
  return produce(state, (draft) => {
    draft.collection = [...draft.collection, ...(payload || [])];
  });
}

function deleteStockProduct(state, payload) {
  if (!payload) return state;
  return {
    ...state,
    collection: state.collection.filter((item) => item.id !== Number(payload.id))
  };
}

function updateStockProduct(state, payload) {
  if (!payload) return state;
  const idxToReplace = state.collection.findIndex((item) => item.id === Number(payload.id));
  const updatedState = produce(state, (draft) => {
    draft.collection[idxToReplace] = payload;
  });
  return updatedState;
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(stockProductActions.Type.GET_STOCK_PRODUCTS, getStockProducts)
  .mapAction(stockProductActions.Type.UPDATE_STOCK_PRODUCT, updateStockProduct)
  .mapAction(stockProductActions.Type.DELETE_STOCK_PRODUCT, deleteStockProduct)
  .mapAction(stockProductActions.Type.GET_STOCK_PRODUCTS_BY_ID, getStockProductById)
  .mapAction(stockProductActions.Type.GET_STOCK_PRODUCTS_BY_IDS, getStockProductByIds)
  .build();

export default reducer;

export function getStockProductFromId(id: number, products: ProductInterface[]): ProductInterface {
  return products.find((product) => product.id === id);
}

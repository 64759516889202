import React, { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ListHeader, Filters } from "../../components/List";
import Headers from "./Headers";
import EquityAndLiabilities from "./EquityAndLiabilities";
import Assets from "./Assets";
import Income from "./Income";
import Expenses from "./Expenses";
import AccountGroupCreate from "./CreateAccountGroup";
import {
  PrimaryLedger,
  AccountingGroup,
  MHeaderInterface,
  PrimaryLedgers
} from "../../interfaces/Accounts";
import { extractSecondaryData, useCoaAll } from "./hooks";
import * as NotificationActions from "../../actions/notification";
import Can from "../Policy/Can";
import { getCurrentSubscription } from "../../slices/subscriptionSlice";
import { RootState } from "../../store";
import { multipleHeaders } from "./VoucherList";
import ButtonGroupAccount from "./ButtonGroup";

interface MatchInterface extends MHeaderInterface {
  path?: string;
}

export interface PSecondaryLedger {
  code: string;
  secondaryLedgerName: string;
  parentId?: number;
  id?: number;
}

const filterData = [
  { key: "equityAndLiability", title: "Equity And Liability" },
  { key: "asset", title: "Assets" },
  { key: "income", title: "Income" },
  { key: "expense", title: "Expenses" }
];

const pickNameAndCode = (data: PrimaryLedger): PSecondaryLedger[] =>
  data?.secondaryLedgers.map((sec) => ({
    secondaryLedgerName: sec.secondaryLedgerName,
    code: sec.code,
    parentId: sec.id
  }));

const CoaTree = ({ match }: { match: MatchInterface }): JSX.Element => {
  const [showAccountGlCreate, setShowAccountGlCreate] = useState<boolean>(false);
  const [secondaryLedgerList, setSecondariLedgerList] = useState<PSecondaryLedger[]>([]);
  const [selectedParent, setSelectedParent] = useState<Partial<AccountingGroup>>(null);
  const dispatch = useDispatch();
  const resourceCentre = useSelector((state: RootState) => state.userContext?.resourceCentre);
  const [filter, setFilter] = React.useState("equityAndLiability");
  const { equityAndLiabilities, assets, income, expenses, error } =
    extractSecondaryData(useCoaAll()) || {};

  React.useEffect(() => {
    if (resourceCentre) {
      dispatch(getCurrentSubscription(resourceCentre.id));
    }
  }, [dispatch, resourceCentre]);

  React.useEffect(() => {
    if (filter === PrimaryLedgers.EQUITY_AND_LIABILITIES) {
      setSecondariLedgerList(pickNameAndCode(equityAndLiabilities));
    }
    if (filter === PrimaryLedgers.ASSET) {
      setSecondariLedgerList(pickNameAndCode(assets));
    }
    if (filter === PrimaryLedgers.INCOME) {
      setSecondariLedgerList(pickNameAndCode(income));
    }
    if (filter === PrimaryLedgers.EXPENSE) {
      setSecondariLedgerList(pickNameAndCode(expenses));
    }
  }, [equityAndLiabilities, assets, income, expenses, filter]);

  if (error)
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "error",
        message: "Something Went wrong!",
        autoTimeout: true
      })
    );
  return (
    <Can policyAccessKey="account:listAccount">
      <Box overflow="auto hidden">
        <Box minWidth="900px" mt={2}>
          <ListHeader
            testmationLabel="accounts"
            createLabel="Add Account Group"
            multipleHeaders={multipleHeaders(match)}
            customCreateButton={
              !secondaryLedgerList?.length ? (
                <></>
              ) : (
                <ButtonGroupAccount
                  setShowCreatePanel={(val) => setShowAccountGlCreate(val)}
                  setSelectedParent={(val) => setSelectedParent(val)}
                />
              )
            }
            onCreateClick={() => {
              setShowAccountGlCreate(true);
              setSelectedParent(null);
            }}
          />
          <Box m="-20px 0px 0 20px">
            <Filters filter={filter} onFilter={setFilter} filterData={{ filters: filterData }} />
          </Box>
          <Headers />
          <Box height="calc(100vh - 180px)" overflow="auto">
            {filter === PrimaryLedgers.EQUITY_AND_LIABILITIES && (
              <EquityAndLiabilities
                setSelectedParent={setSelectedParent}
                data={equityAndLiabilities}
              />
            )}
            {filter === PrimaryLedgers.ASSET && (
              <Assets setSelectedParent={setSelectedParent} data={assets} />
            )}
            {filter === PrimaryLedgers.INCOME && (
              <Income setSelectedParent={setSelectedParent} data={income} />
            )}
            {filter === PrimaryLedgers.EXPENSE && (
              <Expenses setSelectedParent={setSelectedParent} data={expenses} />
            )}
          </Box>
          {(showAccountGlCreate || selectedParent) && (
            <AccountGroupCreate
              setSelectedParent={setSelectedParent}
              data={selectedParent}
              secondaryLedgerList={secondaryLedgerList}
              setShowAccountGlCreate={setShowAccountGlCreate}
            />
          )}
        </Box>
      </Box>
    </Can>
  );
};

export default CoaTree;

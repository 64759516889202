import React from 'react';
import { encode } from 'blurhash';
import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import { Label } from './GenericTextField';
import { deleteBannerImg, uploadBannerImg } from '../../../api/feed';
import { loadingIcon } from '../../../components/StatefulButton/StatefulButton';

function convertURIToImageData(URI) {
  return new Promise(function (resolve, reject) {
    if (URI === null) return reject();
    const canvas = document.createElement('canvas'),
      context = canvas.getContext('2d'),
      image = new Image();
    image.onload = function () {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      resolve(context.getImageData(0, 0, canvas.width, canvas.height));
    };
    image.src = URI;
  });
}

function encodeImageDataToBlurHash(image) {
  return encode(image.data, image.width, image.height, 4, 3);
}

function getFromFileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => {
      reject(new Error('Failed to read file'));
      reader.abort();
    };
    reader.readAsDataURL(file);
  });
}

function BannerImageInput({ draft, onChange }) {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [previewImg, setPreviewImg] = React.useState(null);
  const [blurHashImg, setBlurHashImg] = React.useState('');
  const [uploading, setUploading] = React.useState(false);

  const onFileSelected = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  React.useEffect(() => {
    if (selectedFile) {
      if (selectedFile.size > 500 * 1024) {
        alert('File size must be under 0.5Mb');
        return;
      }

      async function handleReadFile() {
        try {
          setUploading(true);
          const previewImg = await getFromFileReader(selectedFile);
          const imgData = await convertURIToImageData(previewImg);
          setBlurHashImg(encodeImageDataToBlurHash(imgData));
          setUploading(false);
        } catch (error) {
          setUploading(false);
          console.log('Error', error);
        }
      }
      handleReadFile();
    }
  }, [selectedFile]);

  React.useEffect(() => {
    if (blurHashImg) {
      handleFileUpload();
    }
  }, [blurHashImg]);

  async function handleFileUpload() {
    try {
      if (!selectedFile) {
        return;
      }
      if (draft.bgImage) {
        await deleteBannerImg(draft.bgImage);
      }
      const fileData = new FormData();
      const cleanName = selectedFile.name.replace(/\s+/g, '');
      fileData.set('image', selectedFile, `${cleanName}`);
      setUploading(true);
      const { url } = await uploadBannerImg(fileData);
      setUploading(false);
      resetState();
      onChange({ bgImage: url, bgImageBlurHash: blurHashImg });
    } catch (error) {
      setUploading(false);
      console.log('Failed to upload image.');
    }
  }

  const resetState = () => {
    setPreviewImg(null);
    setBlurHashImg(null);
    setSelectedFile(null);
  };
  return (
    <Box component="div">
      <Box component="div" display="flex" mb={1}>
        <Label>Banner image url</Label>
        <Box component="div" display="flex" width="100%">
          <Box component="div">
            <input
              style={{ display: 'none' }}
              id="upload-feed-banner"
              accept="image/*"
              type="file"
              multiple={false}
              onChange={onFileSelected}
            />
            <label htmlFor="upload-feed-banner">
              <Button variant="contained" color="primary" component="span">
                {uploading ? <span>Uploading...</span> : <span>Upload</span>}
              </Button>
            </label>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={draft.isBanner}
                onChange={(e) => {
                  onChange({ isBanner: e.target.checked });
                }}
                name="isBanner"
                color="primary"
              />
            }
            label="Banner mode"
            labelPlacement="start"
          />
        </Box>
      </Box>

      <Box component="div" display={'flex'} mb={2}>
        <Label>
          <></>
        </Label>
        <Box display="flex" alignItems="center" justifyContent="center" height={200} width={320}>
          {uploading}
          {uploading ? (
            <span> {loadingIcon({ size: 24, thickness: 4 })}</span>
          ) : (
            <img
              src={previewImg || draft.bgImage}
              alt="banner-preview-image"
              style={{
                padding: 8,
                width: 320,
                height: 200,
                border: '1px solid #cdcdcd',
                borderRadius: 4
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BannerImageInput;

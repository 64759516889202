import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { Box, Typography } from "@mui/material";
import { t } from "../../components/translate";

interface Props {
  url: string;
}
const FooterQR = ({ url }: Props): JSX.Element => (
  <Box className="qrCodeContainer">
    <Box className="qrCode">
      <QRCodeSVG size={80} value={url} />
    </Box>
    <Box component="span" textAlign="left" pt={1}>
      <Typography sx={{ maxWidth: "130px", fontSize: "0.8rem" }}>
        {t("assessmentPrint.qrUrlFooter")}
      </Typography>
    </Box>
  </Box>
);

export default FooterQR;

import { RangeEditProps } from "../containers/Lab/Settings/LabMasterDataEdit/AdvancedRangesEdit";
import { DocumentRecordGroup, Test } from "../interfaces/Lab";
import { LabTest } from "../interfaces/LabInterfaces";
import { Delete, Get, Patch, Post, Put } from "./apiHelper";

export async function getLabTestGroups(): Promise<Array<Test>> {
  const response = await Get("/api/labTests1");
  return response.data as Array<Test>;
}

export async function getLabTestGroupsMinimal(): Promise<Array<Test>> {
  const response = await Get("/api/labTestGroupMinimal");
  return response.data as Array<Test>;
}

export async function getLabTestGroupById(id: number): Promise<Test> {
  const response = await Get(`/api/labTestGroup/${id}`, true);
  return response.data as Test;
}

export async function getDefaultLabTestGroupById(id: number): Promise<Test> {
  const response = await Get(`/api/labTestGroup/${id}/master`, true);
  return response.data as Test;
}

export async function getLabTests(): Promise<Array<Test>> {
  const response = await Get("/api/labTests");
  return response.data as Array<Test>;
}

export async function postTestGroupData(data: DocumentRecordGroup): Promise<Test> {
  const response = await Post(`/api/labTestGroup`, data, true);
  return response.data as Test;
}

export async function createLabTest(
  data: Omit<LabTest, "id"> & { defaultValue: string }
): Promise<LabTest> {
  const response = await Post(`/api/labTest/admin`, data, true);
  return response.data as LabTest;
}

export async function createUpdateLabTestRc(
  data: LabTest & { defaultValue: string }
): Promise<LabTest> {
  const response = await Post(`/api/labTestRc`, data, true);
  return response.data as LabTest;
}

export async function updateLabTest(data: LabTest & { defaultValue: string }): Promise<LabTest> {
  const response = await Put(`/api/labTest/admin`, data, true);
  return response.data as LabTest;
}

export async function deleteLabTest(id: number): Promise<LabTest> {
  const response = await Delete(`/api/labTest/${id}`, true);
  return response.data as LabTest;
}

export const labTestGroupsMasterApi = async (): Promise<Array<Test>> => {
  const response = await Get(`/api/labTestGroupsMaster`, true);
  return response.data as Array<Test>;
};

export const labTestGroupsMasterPostApi = async (data: Omit<Test, "id">): Promise<Test> => {
  const response = await Post(`/api/labTestGroupsMaster`, data, true);
  return response.data as Test;
};

export const labTestGroupsMasterUpdateApi = async (data: Test): Promise<Test> => {
  const response = await Put(`/api/labTestGroupsMaster`, data, true);
  return response.data as Test;
};

export const updateLabMasterDataRanges = async (
  data: RangeEditProps,
  labId: number
): Promise<string> => {
  const response = await Patch(`/api/labTestGroup/${labId}`, data, true);
  return response.data as string;
};

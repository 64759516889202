// data from SSF documentation

export const MEDICAL_SUB_PRODUCT = {
  IPD: { label: "IPD", value: 1 },
  OPD: { label: "OPD", value: 2 }
};

// reference for future use
export const MATERNITY_SUB_PRODUCT = {
  IPD: { label: "IPD", value: 3 },
  OPD: { label: "OPD", value: 4 }
};

export const ACCIDENT_SUB_PRODUCT = {
  WORK_RELATED: { label: "WORK_RELATED", value: 14 },
  NON_WORK_RELATED: { label: "NON_WORK_RELATED", value: 18 }
};

export const SCHEME_TYPE = {
  MATERNITY: { label: "MATERNITY", value: 2 },
  MEDICAL: { label: "MEDICAL", value: 2 },
  ACCIDENT: { label: "ACCIDENT", value: 1 }
};

import { Department } from "../interfaces/DepartmentInterface";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";
import { Delete, Get, Patch, Post } from "./apiHelper";

const root = "/api/departments";
const departmentSpRoot = "/api/departmentServiceProviders";

export const getDepartments = async (rcId: number | null): Promise<Department[]> => {
  const response = await Get(`${root}?resourceCentreId=${rcId || ""}`, true);
  return response.data as Department[];
};

export const postDepartment = async (department: Department): Promise<Department> => {
  const response = await Post(root, department, true);
  return response.data as Department;
};

export const putDepartment = async (department: Partial<Department>): Promise<Department> => {
  const response = await Patch(`${root}/${department.id}`, department, true);
  return response.data as Department;
};

export const deleteDepartment = async (department: Partial<Department>): Promise<string> => {
  const response = await Delete(`${root}/${department.id}`, true);
  return response.data as string;
};

export const getDepartmentServiceProviders = async (): Promise<Array<ServiceProvider>> => {
  const response = await Get(departmentSpRoot, true);
  return response.data as Array<ServiceProvider>;
};

import React, { useEffect } from "react";
import produce from "immer";
import moment from "moment-timezone";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FieldValues, useForm } from "react-hook-form";
import capitalize from "lodash/capitalize";
import Panel from "../../../components/Panel";
import { t } from "../../../components/translate";
import ClientSearch from "../../../components/ClientSearch";
import ServiceProviderSelect from "../../../components/ServiceProviderSelect/ServiceProviderSelect";
import EditableTable from "../../../components/EditableTable/EditableTable";
import { RootState } from "../../../store";
import IntraOral from "../../Assessment/IntraOral/IntraOral";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { patchEstimates, postEstimates } from "../../../api/estimates";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import Settings from "./Settings";
import { useAppSelector } from "../../../store/hooks";
import { defaultEstimateState, isValidEstimateData } from "./helpers";
import { Estimate, EstimatesData, Props } from "./interfaces";

const EstimatesCreateEdit = ({
  onClose,
  selectedClient,
  afterSave,
  editMode,
  selectedEstimate
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const template: Estimate = useAppSelector((state) => state.estimates.template);
  const themeColor = useSelector(
    (state: RootState) => state.userContext.resourceCentre.settings.lookAndFeel.primaryColour
  );
  const [showFields, setShowFields] = React.useState({
    permanent: true,
    primary: false
  });

  const isValidData = isValidEstimateData(selectedEstimate?.estimates || null);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isSubmitting }
  } = useForm<FieldValues>({
    defaultValues: editMode
      ? {
          client: selectedEstimate.client,
          serviceProvider: selectedEstimate.serviceProvider,
          validUntil: selectedEstimate.validUntil
            ? new Date(selectedEstimate.validUntil)
            : moment.utc().add("1", "month").toDate(),
          estimates: {
            intraOral: isValidData.intraOral
              ? selectedEstimate.estimates.intraOral
              : { ...defaultEstimateState.intraOral },
            tabular: isValidData.tabular
              ? selectedEstimate.estimates.tabular
              : { ...defaultEstimateState.tabular }
          }
        }
      : {
          client: selectedClient || null,
          serviceProvider: null,
          validUntil: moment.utc().add("1", "month").toDate(),
          estimates: {
            intraOral: { ...defaultEstimateState.intraOral },
            tabular: template?.estimates
              ? { ...template.estimates.tabular }
              : { ...defaultEstimateState.tabular }
          }
        },
    mode: "onChange"
  });

  const clientInfo = watch("client");

  useEffect(() => {
    if (!editMode) setValue("client", selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    if (template?.estimates && !editMode) setValue("estimates", template.estimates);
  }, [template]);

  const onSubmit = async ({ client, serviceProvider, estimates, validUntil }: FieldValues) => {
    try {
      const formData = {
        clientId: client.id,
        serviceProviderId: serviceProvider.id,
        validUntil,
        estimates
      };
      const res = editMode
        ? await patchEstimates({ data: formData, id: selectedEstimate.id })
        : await postEstimates(formData);
      afterSave(res);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: commonErrorMessage,
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Panel
      onClose={onClose}
      title={editMode ? t("estimates.edit") : t("estimates.create")}
      genericButton={
        <Button startIcon={<SettingsIcon />} onClick={() => setIsOpen(true)}>
          Settings
        </Button>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%",
            mx: 4
          }}
        >
          <Button onClick={onClose}>{t("global.cancel")}</Button>
          <StatefulButton
            data-testmation="estimateSave"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            form="estimateForm"
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
            circularProgressProps={{ size: 16 }}
          >
            {t("global.save")}
          </StatefulButton>
        </Box>
      }
    >
      <Box
        sx={{
          px: 5,
          pt: 1,
          overflowY: "auto",
          height: "calc(100vh - 100px)"
        }}
        component="form"
        id="estimateForm"
      >
        <Stack gap={1.5}>
          <div>
            <Controller
              name="client"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ClientSearch
                  isDisabled={editMode}
                  setClient={field.onChange}
                  client={field.value}
                  label="Search client"
                  variant="outlined"
                  margin="dense"
                  style={{ width: "260px" }}
                />
              )}
            />
            {clientInfo && (
              <Typography variant="subtitle2" fontWeight={500} gutterBottom>
                {clientInfo.phone || ""}
                {clientInfo.phone && clientInfo.email ? ", " : ""}
                {clientInfo.email || ""}
              </Typography>
            )}
          </div>

          <Controller
            name="serviceProvider"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ServiceProviderSelect
                required
                fullWidth
                serviceProvider={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />

          <Box my={1}>
            <Typography gutterBottom variant="h6">
              {t("assessment.intraOral")}
            </Typography>
            <Controller
              name="estimates"
              control={control}
              render={({ field }) => (
                <>
                  <IntraOral
                    themeColor={themeColor}
                    type="permanent"
                    numbering="normal"
                    showField={showFields.permanent}
                    onAddClicked={() => {
                      setShowFields({ ...showFields, permanent: true });
                    }}
                    onClearClicked={() => {
                      setShowFields({ ...showFields, permanent: false });
                    }}
                    stateValue={field.value.intraOral}
                    onNumberSave={(comboString: string, data: Record<string, unknown>) => {
                      const newData = produce(field.value, (draft: EstimatesData) => {
                        draft.intraOral.permanent[comboString] = data;
                      });
                      field.onChange(newData);
                    }}
                    onNodeDelete={(value: string) => {
                      const newData = produce(field.value, (draft: EstimatesData) => {
                        delete draft.intraOral.permanent[value];
                      });
                      field.onChange(newData);
                    }}
                  />
                  <IntraOral
                    themeColor={themeColor}
                    type="primary"
                    numbering="alphabets"
                    showField={showFields.primary}
                    onAddClicked={() => {
                      setShowFields({ ...showFields, primary: true });
                    }}
                    onClearClicked={() => {
                      setShowFields({ ...showFields, primary: false });
                    }}
                    stateValue={field.value.intraOral}
                    onNumberSave={(comboString: string, data: Record<string, unknown>) => {
                      const newData = produce(field.value, (draft: EstimatesData) => {
                        draft.intraOral.primary[comboString] = data;
                      });
                      field.onChange(newData);
                    }}
                    onNodeDelete={(value: string) => {
                      const newData = produce(field.value, (draft: EstimatesData) => {
                        delete draft.intraOral.primary[value];
                      });
                      field.onChange(newData);
                    }}
                  />
                </>
              )}
            />
          </Box>
          <Stack gap={0.5}>
            <Controller
              name="estimates"
              control={control}
              render={({ field }) => (
                <>
                  <Typography variant="h6">{capitalize(field.value.tabular.title)}</Typography>
                  <EditableTable
                    hideLabel
                    maxColCount={10}
                    onChange={(value) => {
                      const tableData = produce(field.value, (draft: EstimatesData) => {
                        draft.tabular = value;
                      });
                      field.onChange(tableData);
                    }}
                    hideControls
                    initValue={field.value.tabular}
                  />
                </>
              )}
            />
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="h6">Valid until: </Typography>
            <Controller
              name="validUntil"
              control={control}
              render={({ field }) => (
                <>
                  <CalendarDropdown
                    placeholder="Estimate valid date"
                    label="Date"
                    date={field.value}
                    onChange={(collectionDate) => field.onChange(collectionDate)}
                    format="formatted4"
                    minValidDate={new Date()}
                    TextFieldProps={{ variant: "outlined" }}
                  />
                </>
              )}
            />
          </Stack>
        </Stack>
      </Box>
      <Settings onClose={() => setIsOpen(false)} open={isOpen} />
    </Panel>
  );
};

export default EstimatesCreateEdit;

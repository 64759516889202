import { ReducerBuilder } from './ReducerBuilder';
import * as CampaignActions from '../actions/campaigns';

const INITIAL_STATE = {
  collection: [],
  lastTouched: null
};

function setCampaigns(state, payload) {
  return { ...state, collection: payload || [] };
}

function setCampaign(state, payload) {
  const newCollection = [...state.collection].filter(({ id }) => id !== payload.id);
  newCollection.push(payload);
  return { ...state, collection: newCollection, lastTouched: payload };
}

function removeCampaign(state, id) {
  const newCollection = [...state.collection].filter((campaign) => campaign.id !== id);
  return { ...state, collection: newCollection };
}

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(CampaignActions.Type.CAMPAIGNS_GET, setCampaigns)
  .mapAction(CampaignActions.Type.CAMPAIGN_GET, setCampaign)
  .mapAction(CampaignActions.Type.CAMPAIGN_CREATE, setCampaign)
  .mapAction(CampaignActions.Type.CAMPAIGN_UPDATE, setCampaign)
  .mapAction(CampaignActions.Type.CAMPAIGN_DELETE, removeCampaign)
  .build();
export default reducer;

export const campaignsSelector = (state) => state.campaigns.collection;

import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Bed, Ward } from "../../../api/wards";
import Modal from "../../../components/Modal/Modal";
import { transferBed } from "../../../slices/bedManagementSlice";

interface Props {
  beds: Array<Bed>;
  wards: Array<Ward>;
  currentBed: Bed;
  open: boolean;
  setOpen: (v) => void;
}

const BedTransfer = ({ beds, wards, currentBed, open, setOpen }: Props): JSX.Element => {
  const [bedOptions, setBedOptions] = React.useState<Array<Bed>>([]);
  const [selectedWard, setSelectedWard] = React.useState(currentBed.ward?.id);
  const [selectedBed, setSelectedBed] = React.useState<number | null>(null);

  React.useEffect(() => {
    const filteredBeds = beds
      .filter((bed) => bed.id !== currentBed.id)
      .filter((bed) => bed.wardId === selectedWard);
    setBedOptions(filteredBeds);
  }, [beds, currentBed.id, selectedWard]);
  const dispatch = useDispatch();
  return (
    <Modal
      open={open}
      onSave={() => {
        dispatch(
          transferBed({
            ipdId: currentBed.activeIpd?.id,
            bedId: selectedBed,
            wardId: selectedWard
          })
        );
        setOpen(false);
      }}
      onClose={() => {
        setOpen(false);
      }}
      title="Transfer Client's Bed"
    >
      <Box
        sx={{
          minWidth: "360px"
        }}
      >
        <Autocomplete
          data-testmation="wardAutocomplete"
          sx={{
            marginBottom: "15px"
          }}
          value={currentBed.ward}
          onChange={(_, v) => {
            if (!v || typeof v === "string") return;
            setSelectedWard(v.id);
          }}
          options={wards}
          getOptionLabel={(item) => item.name}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} variant="outlined" label="Ward" />}
        />
        <Autocomplete
          data-testmation="bedAutocomplete"
          options={bedOptions}
          getOptionLabel={(item) => item.number}
          onChange={(_, v) => {
            if (!v || typeof v === "string") return;
            setSelectedBed(v.id);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} variant="outlined" label="Bed" />}
        />
      </Box>
    </Modal>
  );
};

export default BedTransfer;

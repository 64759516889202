import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import { Box, Tooltip } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";
import moment from "moment-timezone";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { getDisplayAgeFromDOB } from "../../../helpers/formatters";
import { LabClient, LabTestRecordResultData } from "../../../interfaces/Lab";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../../mixpanel-analytics/event";
import { PrintTypography } from "../../Stock/StockCreateEdit/BarCodePrint";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

interface PrintLabBarCodeInterface {
  labTests: LabTestRecordResultData[];
  client: LabClient;
}

const PrintLabBarCode: React.FC<PrintLabBarCodeInterface> = ({
  labTests,
  client
}): JSX.Element | null => {
  const currentDateAndTime = moment().format();
  const rc = useCurrentResourceCentre();
  const BSDate = convertADtoBS(moment.utc(currentDateAndTime.slice(0, 10))).formatted;
  const printRef = React.useRef(null);
  const print = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => {
      mixpanelAnalytics.track(EVENT.PRINT_LAB_BARCODE, { rcId: rc.id, rcName: rc.name });
    }
  });

  const getFullName = (c: Partial<LabClient>): string => `${c?.firstName} ${c?.lastName}`;
  const fullName = getFullName(client);
  if (labTests.length === 0) return null;
  const value = labTests[0].testId;
  const gender = (g) => {
    if (g === "1") return "M";
    if (g === "2") return "F";
    return "";
  };

  return (
    <Box>
      <Tooltip title="Barcode print" aria-label="barcodeprint">
        <PrintIcon
          onClick={print}
          data-testomation="printButton"
          sx={{ cursor: "pointer", marginLeft: "10px", marginTop: "5px" }}
        />
      </Tooltip>
      <iframe title="lab_bar_code_container" style={{ display: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          ref={printRef}
        >
          <Box display="flex" flexDirection="column">
            <PrintTypography
              text={`${fullName}${(client.dob || client.gender || "") && ", "}
            ${client.dob ? `${getDisplayAgeFromDOB(client.dob)}` : ""}
            ${client.dob && client.gender ? "/" : ""}
            ${client.gender ? gender(client.gender) : ""}`}
              fontSize="2.5mm"
              maxWidth="45mm"
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Barcode
                value={value.toString()}
                width={1.1}
                displayValue={false}
                height={55}
                margin={0}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <PrintTypography text={value.toString()} />
              <PrintTypography text={BSDate} />
            </Box>
          </Box>
        </Box>
      </iframe>
    </Box>
  );
};

export default PrintLabBarCode;

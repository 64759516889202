import actionCreatorFactory from 'typescript-fsa';
import * as moment from 'moment';
import { actionCreatorAsync } from './actionHelpers';
import * as api from '../api/feed';

const actionCreator: any = actionCreatorFactory();

export enum Type {
  INIT_DRAFT = 'INIT_FEED_DRAFT',
  FETCH_FEEDS = 'FETCH_FEEDS',
  FETCH_FEED = 'FETCH_FEED',
  UPDATE_DRAFT = 'UPDATE_FEED_DRAFT',
  DELETE_FEED = 'DELETE_FEED',
  PUBLISH_FEED = 'PUBLISH_FEED',
  DEACTIVE_FEED = 'DEACTIVE_FEED',
  CLEAR_DRAFT = 'CLEAR_FEED_DRAFT'
}

export enum FeedTypes {
  DAILY = 'feedDaily',
  NEWS = 'feedNews'
}

export enum Actions {
  OPEN_NATIVE_VIEW = 'openNativeView',
  OPEN_WEB_VIEW = 'openWebView',
  OPEN_APP_STORE = 'openAppStore'
}

export enum NativeViewActions {
  OPEN_RC = 'openRc',
  OPEN_SP = 'openSp',
  OPEN_APPOINTMENTS = 'openAppointments'
}

export const getDraftTemplate = () => {
  return {
    type: FeedTypes.DAILY,
    title: '',
    description: '',
    isBanner: false,
    bgImage: '',
    bgImageBlurHash: '',
    action: Actions.OPEN_NATIVE_VIEW,
    actionProps: {},
    startDate: moment().toDate(),
    endDate: moment().add(3, 'days'),
    published: false,
    deactivated: false
  };
};

export const initDraft = () => {
  return (dispatch) => {
    dispatch(initDraftAction(getDraftTemplate()));
  };
};

export const loadFeed = (feedId) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.FETCH_FEED, async () => {
        const feed = await api.getFeed(feedId);
        return feed;
      })
    );
  };
};

export const loadFeedForEdit = (feedId) => {
  return async (dispatch, getState) => {
    dispatch(clearDraft());
    await dispatch(loadFeed(feedId));
    const { feeds } = getState();
    const feed = feeds.collection.find(({ id }) => id === feedId);
    dispatch(initDraftAction(feed));
  };
};

const saveDraft = async ({ feeds }) => {
  if (feeds.draft?.id) {
    return await api.patchDraft(feeds.draft.id, feeds.draft);
  } else {
    return await api.postDraft(feeds.draft);
  }
};

export const saveAsDraft = () => {
  return async (dispatch, getState) => {
    return await saveDraft(getState());
  };
};

export const publishDraft = (id?: string) => {
  return async (dispatch, getState) => {
    let feedId = id;

    if (!feedId) {
      const feed: any = await saveDraft(getState());
      feedId = feed.id;
    }
    await dispatch(
      actionCreatorAsync(Type.PUBLISH_FEED, async () => {
        return await api.publishFeed(feedId);
      })
    );
  };
};

export const fetchFeeds = (query = {}) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.FETCH_FEEDS, async () => {
        return api.getFeeds(query);
      })
    );
  };
};

export const deactivateFeed = (id) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.DEACTIVE_FEED, async () => {
        return api.deactiveFeed(id);
      })
    );
  };
};

export const deleteDraft = (id) => {
  return async (dispatch) => {
    await api.deleteDraft(id);
    dispatch(deleteFeed(id));
  };
};

export const initDraftAction = actionCreator(Type.INIT_DRAFT);
export const clearDraft = actionCreator(Type.CLEAR_DRAFT);
export const updateDraft = actionCreator(Type.UPDATE_DRAFT);
export const deleteFeed = actionCreator(Type.DELETE_FEED);

import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type DebouncedTextFieldProps = TextFieldProps & {
  debounceAt?: number;
  setDebounceLoading?: (val: boolean) => void;
};

/**
 * The `DebouncedTextField` is an extension to Material UI's TextField
 * debounceAt is the extra added property to that of TextField's
 *
 * ## Advanced Configuration
 * ```jsx
 * const [text, setText] = React.useState('');
 * return <DebouncedTextField value={text} onChange={(e) => setText(e.target.value) debounceAt={300} }/>;
 * ```
 *
 * For advanced TextField cases, please look at the source of TextField by clicking on the
 * "Edit this page" button above. Consider either:
 *
 * -   using the upper case props for passing values directly to the components
 * -   using the underlying components directly as shown in the demos
 * Demos:
 *
 * - [Autocomplete](https://material-ui.com/components/autocomplete/)
 * - [Pickers](https://material-ui.com/components/pickers/)
 * - [Text Fields](https://material-ui.com/components/text-fields/)
 *
 * API:
 *
 * - [TextField API](https://material-ui.com/api/text-field/)
 * - inherits [FormControl API](https://material-ui.com/api/form-control/)
 */

const DebouncedTextField = (props: DebouncedTextFieldProps) => {
  const [value, setValue] = React.useState(props.value);

  const timer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value);
    if (props.setDebounceLoading) {
      if (e.target.value.length > 2) {
        props.setDebounceLoading(true);
      } else {
        props.setDebounceLoading(false);
      }
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    const target = e.target;
    timer.current = setTimeout(() => {
      if (props.onChange) {
        props.onChange({ ...e, target });
        if (props.setDebounceLoading) {
          props.setDebounceLoading(false);
        }
      }
    }, props.debounceAt);
  };

  const textFieldProps = { ...props };
  delete textFieldProps.debounceAt;

  return (
    <TextField
      {...textFieldProps}
      value={value}
      onChange={props.debounceAt ? onChange : props.onChange}
    />
  );
};

export default DebouncedTextField;

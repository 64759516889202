import Typography from "@mui/material/Typography";
import { push } from "connected-react-router";
import startCase from "lodash/startCase";
import { Link } from "@mui/material";
import queryString from "query-string";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { serviceProviderActions as actions, serviceProviderActions } from "../../actions";
import { getServicetags } from "../../actions/bookableResource";
import List from "../../components/List";
import { t, tl } from "../../components/translate";
import { serviceProvidersSortedSelector, spFullNameSelector } from "../../reducers/serviceProvider";
import ServiceProviderInfo, { canUseSpecialities } from "./ServiceproviderShow";

import useCan from "../../hooks/useCan";
import useMobileScreen from "../../hooks/useMobileScreen";
import { IServiceTag } from "../../interfaces/ServiceTagsInterface";
import { getSpeciality } from "../../reducers/serviceTags";
import { IThunkDispatch, RootState } from "../../store";
import Can from "../Policy/Can";
import useGetDepartments from "../../hooks/department";
import { ColumnType } from "../../components/OList/ListInterface";
import { ListActions, Menu, MenuItem } from "../../components/OList";
import CsvUploader from "../../components/CsvUploader/CsvUploader";
import { serviceProviderUploadColumns } from "../../components/CsvUploader/csvHelpers";
import { batchUploadSp } from "../../api/user";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import UserChip from "../Modals/User/Chip";

interface Props {
  resourceCentreId: number;
  loadServiceProviders: (id: number) => void;
  navigateTo: (url: string) => void;
  navigateToResourceSettingsSp: boolean;
  loadServiceTags: () => void;
  serviceTags: Array<IServiceTag>;
  selectedSPId: number | null;
  setSelectedSPId: (id: number | null) => void;
  resourceCentre: ResourceCentre;
}

function downloadSpUpload() {
  window.open(
    "https://drive.google.com/file/d/11aV2x3OLXajgGzyNwZJV36DVZviDm1fB/view?usp=sharing",
    "_blank"
  );
}

export const ServiceProviderList = ({
  resourceCentreId,
  loadServiceProviders,
  navigateTo,
  navigateToResourceSettingsSp,
  loadServiceTags,
  serviceTags,
  selectedSPId,
  setSelectedSPId,
  resourceCentre
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    loadServiceProviders(resourceCentreId);
    loadServiceTags();
    const { spId } = queryString.parse(window.location.search);
    if (spId) {
      setSelectedSPId(Number(spId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serviceProviders = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );

  // Sort Deleted serviceProviders at the last
  const sortedServiceProviders = [...serviceProviders].sort((a, b) => {
    if (a.active && !b.active) return -1;
    if (!a.active && b.active) return 1;
    return 0;
  });
  const handleViewClose = () => {
    setSelectedSPId(null);
    if (navigateToResourceSettingsSp) {
      navigateTo(`/resourcecentres/${resourceCentreId}/serviceProviders`);
    }
  };

  const isMobileScreen = useMobileScreen();

  const showCreateButton = useCan("doctors:createDoctors", { superAdminPass: true });

  const departments = useGetDepartments();

  const departmentSubscription = useSelector(
    (state: RootState) =>
      state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
  );

  const listTitle = `${t("employee.serviceProviders")} (${sortedServiceProviders?.length || 0})`;
  const { twoFactorAuthentication } = resourceCentre?.subscriptions?.features || {};
  return (
    <Can policyAccessKey="doctors:listDoctors" superAdminPass>
      <List
        automation="serviceProviders"
        title={
          isMobileScreen ? (
            <Typography sx={{ lineHeight: "1.5", fontSize: "18px" }}>{listTitle}</Typography>
          ) : (
            listTitle
          )
        }
        createLabel={isMobileScreen ? "Create" : tl("employee.createServiceProvider")}
        testmationLabel="CreateServiceProvider"
        rowHeight={50}
        hideCreateButton={!showCreateButton}
        isResponsive
        columns={[
          {
            key: "name",
            label: tl("employee.name"),
            sortable: true,
            sortFunction: (a, b) =>
              `${a.firstName}${a.lastName}`.toLowerCase() >
              `${b.firstName}${b.lastName}`.toLowerCase()
                ? 1
                : -1,
            formatter: (r) => (
              <Typography variant="body2">
                {`${spFullNameSelector({
                  title: r.title,
                  firstName: r.firstName,
                  lastName: r.lastName
                })} ${r.active ? "" : "(Deleted)"} `}
              </Typography>
            )
          },
          {
            key: "role",
            label: tl("employee.speciality"),
            formatter: (row) => (
              <Typography component="div" variant="body2">
                {canUseSpecialities(row.user?.userGroups[0]?.name, row.specialities)
                  ? getSpeciality(serviceTags, row.specialities[0])
                  : row.speciality}
              </Typography>
            )
          },
          departmentSubscription
            ? {
                key: "department",
                label: tl("department"),
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    {departments.find((department) => department.id === row.departmentId)?.name ||
                      ""}
                  </Typography>
                )
              }
            : ({} as ColumnType<unknown>),
          {
            key: "userGroup",
            label: "User Group",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                {startCase(row.user?.userGroups[0]?.name || "-")}
              </Typography>
            )
          },
          { key: "phone", label: tl("employee.phone") },
          { key: "email", label: tl("employee.email"), hideInNarrowView: true },
          ...(twoFactorAuthentication?.subscribed
            ? [
                {
                  key: "twoFactorAuthentication",
                  label: "2FA",
                  formatter: (row) => <UserChip allowed={row?.user?.enforcedTwoFactorAuth} />
                }
              ]
            : [])
        ]}
        data={sortedServiceProviders || []}
        isLoading={!sortedServiceProviders}
        activeRow={selectedSPId}
        // eslint-disable-next-line consistent-return
        onRowClick={({ id, active }) => {
          if (!active) return null;
          setSelectedSPId(selectedSPId === id ? null : id); // closes on same click
          if (window.location.pathname.includes("edit")) {
            navigateTo(`/resourcecentres/${resourceCentreId}/serviceProviders?spId=${id}`);
          }
        }}
        onCreateNew={() =>
          navigateTo(`/resourcecentres/${resourceCentreId}/serviceProviders/create`)
        }
      >
        <ListActions>
          {() => (
            <Menu>
              <CsvUploader
                buttonText="Upload service providers (.csv)"
                columns={serviceProviderUploadColumns}
                requiredFieldInfoText="First name, Last name, Phone and email are mandatory for sp upload."
                createDataApi={batchUploadSp}
                useBatchUpload
                runAfterSave={() =>
                  dispatch(
                    serviceProviderActions.getResourceCentreServiceProviders({
                      resourceCentreId
                    })
                  )
                }
                renderAdditionalInfo={() => (
                  <Typography
                    style={{
                      cursor: "pointer",
                      position: "fixed",
                      right: 20
                    }}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link onClick={downloadSpUpload}>{tl("stock.viewSample")}</Link>
                  </Typography>
                )}
              />
              <MenuItem onClick={downloadSpUpload}>{tl("stock.viewSample")}</MenuItem>
            </Menu>
          )}
        </ListActions>
      </List>
      {selectedSPId && (
        <ServiceProviderInfo serviceProviderId={selectedSPId} handleViewClose={handleViewClose} />
      )}
    </Can>
  );
};

export default connect(
  (state: RootState) => ({
    serviceProviders: serviceProvidersSortedSelector(state),
    permissionGroup: state.userContext?.userCreds?.userGroups[0],
    rcPublicBookingStatus:
      state?.userContext?.resourceCentre?.subscriptions?.features?.publicBooking?.subscribed,
    serviceTags: state.serviceTags.collection,
    policies: state.userContext.policies || {}
  }),
  (dispatch: IThunkDispatch) => ({
    loadServiceProviders: (id) =>
      dispatch(actions.getResourceCentreServiceProviders({ resourceCentreId: id })),
    navigateTo: (url) => dispatch(push(url)),
    loadServiceTags: () => dispatch(getServicetags())
  })
)(ServiceProviderList);

import { filterEmptyTableData } from "../../../components/EditableTable/EditableTable";
import { EstimatesData } from "./interfaces";

export function isValidEstimateData(value: EstimatesData | null): {
  intraOral: boolean;
  tabular: boolean;
} {
  let intraOral = false;
  let tabular = false;
  if (value) {
    if (
      Object.keys(value.intraOral?.permanent || {}).length !== 0 ||
      Object.keys(value.intraOral?.primary || {}).length !== 0
    ) {
      intraOral = true;
    }
    if (value.tabular && filterEmptyTableData(value.tabular)) tabular = true;
  }
  return { intraOral, tabular };
}

export const defaultEstimateState = {
  intraOral: { permanent: {}, primary: {} },
  tabular: {
    title: "Treatment",
    nodes: [
      [
        { x: 0, y: 0, data: "heading 1" },
        { x: 1, y: 0, data: "heading 2" }
      ],
      [
        { x: 0, y: 1, data: "" },
        { x: 1, y: 1, data: "" }
      ],
      [
        { x: 0, y: 2, data: "" },
        { x: 1, y: 2, data: "" }
      ]
    ]
  }
};

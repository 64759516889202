import * as React from "react";
import { startCase } from "lodash";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { paymentOptionsEnum } from "../Billing/Editor/BillSummary";

const SelectPaymentMethods = ({
  multiple = true,
  onChange,
  isDisabled = false,
  variant = "standard"
}: {
  multiple: boolean;
  onChange: (referrer) => void;
  isDisabled: boolean;
  variant: "standard" | "filled" | "outlined";
}): JSX.Element => (
  <Autocomplete
    multiple={multiple}
    options={Object.values(paymentOptionsEnum)}
    getOptionLabel={(option) => startCase(option)}
    onChange={(_, values) => {
      onChange(values);
    }}
    disabled={isDisabled}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant={variant}
        label="Payment Method"
        placeholder="Payment Method"
        margin="dense"
        fullWidth
      />
    )}
  />
);

export default SelectPaymentMethods;

import { Box, Button } from "@mui/material";
import React from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import StatefulButton from "../../../../components/StatefulButton/StatefulButton";
import ServiceProviderSelect from "../../../../components/ServiceProviderSelect/ServiceProviderSelect";
import { ReverseMap } from "../../../../helpers/types";
import { useAppDispatch } from "../../../../store/hooks";
import * as hmisActions from "../../../../actions/hmis";
import { CONFIRMATION, OUTCOME, PANELS, RESULT_OPTIONS } from "../../constants";
import { Imnci242Base } from "../../interfaces";
import FormTextField from "../../../../components/FormTextField/FormTextField";
import CollapsableInputs from "./CollapsableInputs";

interface Props {
  onClose: () => void;
  mulDartaId?: number;
  clientId?: number;
}

export type PanelsType = ReverseMap<typeof PANELS>;

const ImnciBelowTwoMonthsForm = ({ onClose, mulDartaId, clientId }: Props): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FieldValues>({
    defaultValues: {
      serviceProvider: null,
      mothersName: "",
      weight: "",
      temperature: "",
      referredBy: "",
      pbsi: CONFIRMATION.NO,
      respiratoryRate: "",
      convulsion: false,
      severeChestIndrawing: false,
      nasalFlaring: false,
      grunting: false,
      unableToFeed: false,
      buldgingFontanelle: false,
      pbsiLethargicUnconscious: false,
      umbilicusInfectionToSkin: false,
      umbilicusRedOrWithPus: false,
      tempAbove37: false,
      tempBelow37: false,
      skinPustulesAbove10: false,
      skinPustulesBelow10: false,
      lessThanNormalMovement: false,
      jaundiceUpToHandsFeet: false,
      jaundice: false,
      pusFromEye: false,
      diarrhoea: CONFIRMATION.NO,
      diarrhoeaDays: "",
      blood: false,
      diarrhoeaLethargicUnconscious: false,
      restlessIrritable: false,
      sunkenEyes: false,
      skinPinchSlowly: false,
      skinPinchVerySlowly: false,
      breastFeedingProblem: CONFIRMATION.NO,
      breastFeedTimesIn24Hours: "",
      otherFoodDrinksFeedTimes: "",
      breastfed: false,
      difficultFeeding: false,
      receiveOtherFoodDrinks: false,
      feedByBottle: false,
      feedBySpoon: false,
      veryLowWeight: false,
      lowWeight: false,
      normalWeight: false,
      oralUlcerThrush: false,
      // assess breast feeding options
      includesAll4PointsOfAttachment: false,
      includesAll4PointsOfPosition: false,
      sucklingEffectively: false,
      majorClassification: "",
      numberOfClassification: "",
      icd: null,
      // medicines
      ampicillin: false,
      amoxycillin: false,
      otherMedicines: "",
      gentamycinDoseOne: false,
      gentamycinDoseTwo: false,
      gentamycinDoseThree: false,
      gentamycinDoseFour: false,
      gentamycinDoseFice: false,
      gentamycinDoseSix: false,
      gentamycinDoseSeven: false,
      regularFollowUp: false,
      intermediateVisit: false,
      keepWarm: false,
      breastFeeding: false,
      treatmentOutcome: OUTCOME.IMPROVED,
      referredToId: null,
      followUpDate: null,
      result: RESULT_OPTIONS.IMPROVED,
      remarks: ""
    }
  });

  const { diarrhoea, breastFeedingProblem, pbsi, icd } = watch();

  const dispatch = useAppDispatch();

  const onSubmit = (data: FieldValues) => {
    const postData: Imnci242Base = {
      clientId,
      muldartaId: mulDartaId,
      serviceProviderId: data.serviceProvider.id,
      referredToId: data.referredToId,
      document: {
        mothersName: data.mothersName,
        vitalsAndReferral: {
          weight: data.weight,
          temperature: data.temperature,
          referredBy: data.referredBy
        },
        patientSignAndSymptoms: {
          pbsi: {
            value: data.pbsi === CONFIRMATION.YES,
            options: {
              convulsion: data.convulsion,
              respiratoryRate: data.respiratoryRate,
              severeChestIndrawing: data.severeChestIndrawing,
              nasalFlaring: data.nasalFlaring,
              grunting: data.grunting,
              unableToFeed: data.unableToFeed,
              buldgingFontanelle: data.buldgingFontanelle,
              umbilicusInfectionToSkin: data.umbilicusInfectionToSkin,
              umbilicusRedOrWithPus: data.umbilicusRedOrWithPus,
              tempBelow37: data.tempBelow37,
              tempAbove37: data.tempAbove37,
              skinPustulesAbove10: data.skinPustulesAbove10,
              skinPustulesBelow10: data.skinPustulesBelow10,
              lethargicUnconscious: data.pbsiLethargicUnconscious,
              lessThanNormalMovement: data.lessThanNormalMovement,
              jaundiceUpToHandsFeet: data.jaundiceUpToHandsFeet,
              jaundice: data.jaundice,
              pusFromEye: data.pusFromEye
            }
          },
          diarrhoea: {
            value: data.diarrhoea === CONFIRMATION.YES,
            options: {
              days: data.diarrhoeaDays,
              lethargicUnconscious: data.diarrhoeaLethargicUnconscious,
              sunkenEyes: data.sunkenEyes,
              blood: data.blood,
              restlessIrritable: data.restlessIrritable,
              skinPinchSlowly: data.skinPinchSlowly,
              skinPinchVerySlowly: data.skinPinchVerySlowly
            }
          },
          breastFeedingProblem: {
            value: data.breastFeedingProblem === CONFIRMATION.YES,
            options: {
              breastfed: data.breastfed,
              difficultFeeding: data.difficultFeeding,
              breastFeedTimesIn24Hours: data.breastFeedTimesIn24Hours,
              receiveOtherFoodDrinks: data.receiveOtherFoodDrinks,
              otherFoodDrinksFeedTimes: data.otherFoodDrinksFeedTimes,
              feedByBottle: data.feedByBottle,
              feedBySpoon: data.feedBySpoon,
              veryLowWeight: data.veryLowWeight,
              lowWeight: data.lowWeight,
              normalWeight: data.normalWeight,
              oralUlcerThrush: data.oralUlcerThrush
            }
          },
          assessBreastFeeding: {
            includesAll4PointsOfAttachment: data.includesAll4PointsOfAttachment,
            includesAll4PointsOfPosition: data.includesAll4PointsOfPosition,
            sucklingEffectively: data.sucklingEffectively
          }
        },
        classificationAndCode: {
          majorClassification: data.majorClassification,
          numberOfClassification: data.numberOfClassification,
          icd: data.icd
            ? {
                diagnosis: data.icd.diagnosis,
                icdCode: data.icd.code || data.icd.icdCode
              }
            : null
        },
        medicines: {
          ampicillin: data.ampicillin === CONFIRMATION.YES,
          amoxycillin: data.amoxycillin === CONFIRMATION.YES,
          others: data.otherMedicines,
          gentamycin: {
            one: data.gentamycinDoseOne,
            two: data.gentamycinDoseTwo,
            three: data.gentamycinDoseThree,
            four: data.gentamycinDoseFour,
            five: data.gentamycinDoseFice,
            six: data.gentamycinDoseSix,
            seven: data.gentamycinDoseSeven
          }
        },
        treatmentOutcome: data.treatmentOutcome,
        counsellingToMother: {
          regularFollowUp: data.regularFollowUp,
          intermediateVisit: data.intermediateVisit,
          breastFeeding: data.breastFeeding,
          keepWarm: data.keepWarm
        },
        followUp: {
          date: data.followUpDate,
          result: data.result
        },
        remarks: data.remarks
      }
    };
    return dispatch(hmisActions.postImnci242({ data: postData, afterSave: onClose }));
  };

  return (
    <div>
      <Box
        component="form"
        id="opdRegisterForm"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pb: 2
        }}
      >
        <Controller
          name="serviceProvider"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field: { onChange, value }, fieldState }) => (
            <ServiceProviderSelect
              serviceProvider={value}
              onChange={onChange}
              fullWidth
              error={{
                value: Boolean(fieldState.error),
                message: fieldState.error?.message || ""
              }}
            />
          )}
        />
        <FormTextField
          fullWidth
          control={control}
          label="Mother's Name"
          dataTestmation="mothersName"
          errors={errors}
          name="mothersName"
          placeholder="Child's mother name"
        />
        <CollapsableInputs
          control={control}
          errors={errors}
          setValue={setValue}
          showPbsi={pbsi === CONFIRMATION.YES}
          showBreastFeedingProblem={breastFeedingProblem === CONFIRMATION.YES}
          showDiarrhoea={diarrhoea === CONFIRMATION.YES}
          icd={icd}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 4,
          px: 4,
          py: 2,
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <Button data-testmation="hmisCancel" onClick={onClose}>
          Cancel
        </Button>
        <StatefulButton
          data-testmation="mulDartaSave"
          type="submit"
          form="opdRegisterForm"
          variant="contained"
          color="primary"
          circularProgressProps={{ size: 16 }}
          isLoading={isSubmitting}
          disabled={isSubmitting || !clientId}
        >
          Save
        </StatefulButton>
      </Box>
    </div>
  );
};

export default ImnciBelowTwoMonthsForm;

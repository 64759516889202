import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Typography } from "@mui/material";

import List from "../../../components/List";
import { tl } from "../../../components/translate";
import { RequestForm } from "../../../api/requestForms";
import { getFullName } from "../../../helpers/formatters";
import PageControl from "../../../components/PageControl";
import OpdRequestStatusChip from "./OpdRequestStatusChip";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { Client } from "../../../interfaces/ClientInterface";
import OpdRequestClientSearch from "./OpdRequestClientSearch";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";

interface OpdRequestListProps {
  selectedRow: Partial<RequestForm> | null;
  setSelectedRow: Dispatch<SetStateAction<Partial<RequestForm | null>>>;
  opdRequestData: { results: RequestForm[]; total: number };
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  selectedClient: Partial<Client> | null;
  setSelectedClient: Dispatch<SetStateAction<Partial<Client | null>>>;
  isListForReport?: boolean;
}

export default function OpdRequestList(props: OpdRequestListProps): JSX.Element {
  const pageSize = 20;
  const dispatch = useDispatch();
  const isMobileScreen = useMobileScreen();
  const {
    selectedRow,
    setSelectedRow,
    opdRequestData,
    page,
    setPage,
    selectedClient,
    setSelectedClient,
    isListForReport
  } = props;

  const customButtonGroup = (
    <Button variant="contained" onClick={() => dispatch(push("/assessment/labOrderForms/create"))}>
      {isMobileScreen ? tl("create") : tl("opd.createOrderForm")}
    </Button>
  );

  return (
    <Box height="calc(100% - 30px)">
      <List
        automation="requestForm"
        data={opdRequestData?.results || []}
        rowHeight={50}
        hideCreateButton
        withoutSearch
        defaultSortColumn="id"
        defaultSortOrder={-1}
        isResponsive
        activeRow={selectedRow && selectedRow.id}
        onRowClick={(row) => {
          if (isListForReport) {
            if (selectedRow && Number(selectedRow.id) === Number(row.id)) {
              setSelectedRow(null);
            } else {
              setSelectedRow(row as Partial<RequestForm>);
            }
            return;
          }
          const unselect = selectedRow && Number(selectedRow.id) === Number(row.id);
          setSelectedRow(unselect ? null : (row as Partial<RequestForm>));
          if (unselect) {
            dispatch(push(`/assessment/labOrderForms`));
          } else {
            dispatch(push(`/assessment/labOrderForms/${row.id}`));
          }
        }}
        customButtonGroup={!isListForReport ? customButtonGroup : undefined}
        additionalHeaderFilters={
          !isListForReport ? (
            <OpdRequestClientSearch
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
            />
          ) : undefined
        }
        columns={[
          {
            key: "clientName",
            label: tl("opd.requestList.client"),
            sortable: true,
            sortFunction: (a, b) =>
              a.client.firstName.toLowerCase() > b.client.firstName.toLowerCase() ? 1 : -1,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{getFullName(row.client)}</Box>
              </Typography>
            )
          },
          {
            key: "supplierName",
            label: tl("opd.requestInfo.supplierName"),
            sortable: true,
            sortFunction: (a, b) =>
              a.supplier.name.toLowerCase() > b.supplier.name.toLowerCase() ? 1 : -1,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row?.supplier?.name || ""}</Box>
              </Typography>
            )
          },
          {
            key: "serviceProviderName",
            label: tl("opd.requestInfo.serviceProvider"),
            sortable: true,
            sortFunction: (a, b) =>
              // eslint-disable-next-line max-len
              a.serviceProvider.firstName.toLowerCase() > b.serviceProvider.firstName.toLowerCase()
                ? 1
                : -1,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{getFullName(row.serviceProvider)}</Box>
              </Typography>
            )
          },
          {
            key: "requestedItems",
            label: tl("opd.requestList.requestedItems"),
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.requestedItems.map((item) => item.name).join(", ")}</Box>
              </Typography>
            )
          },

          {
            key: "quantity",
            label: tl("opd.requestList.quantity"),
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.requestedItems[0].quantity}</Box>
              </Typography>
            )
          },
          {
            key: "price",
            label: tl("opd.requestList.price"),
            sortable: true,
            sortFunction: (a, b) =>
              a.requestedItems[0].price > b.requestedItems[0].price ? 1 : -1,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.requestedItems[0].price}</Box>
              </Typography>
            )
          },
          {
            key: "total",
            label: tl("opd.requestList.total"),
            sortable: true,
            sortFunction: (a, b) =>
              a.requestedItems[0].total > b.requestedItems[0].total ? 1 : -1,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.requestedItems[0].total}</Box>
              </Typography>
            )
          },
          {
            key: "sentDate",
            label: tl("opd.requestList.sentDate"),
            sortable: true,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{convertADtoBS(new Date(row.sentDate)).formatted4}</Box>
              </Typography>
            )
          },
          {
            key: "deliveryDate",
            label: tl("opd.requestList.deliveryDate"),
            sortable: true,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>
                  {row.deliveryDate ? convertADtoBS(new Date(row.deliveryDate)).formatted4 : "-"}
                </Box>
              </Typography>
            )
          },
          {
            key: "status",
            label: tl("opd.requestList.status"),
            sortable: false,
            formatter: (row) => <OpdRequestStatusChip status={row.status} />
          }
        ]}
      />
      {!isListForReport && (
        <Box height="40px" borderTop="1px solid lightgray" display="flex" justifyContent="flex-end">
          <Box width="200px">
            <PageControl
              page={page}
              onSetPage={(val) => {
                setPage(val);
              }}
              pageSize={pageSize}
              maximumDataCount={opdRequestData.total || 0}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

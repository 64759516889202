import React from "react";
import { useQuery } from "react-query";
import moment from "moment";
import {
  getDashboardKeyIndicatorsData,
  getDashboardWeeklyRevenueData
} from "../../../api/dashboard";
import { KeyIndicators, WeeklyRevenues } from "../../../interfaces/DashboardInterface";
import { queryKeys } from "../../../react-query/constants";
import { getBookings } from "../../../api/bookings";
import { getSchedules } from "../../../api/schedules";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { Schedule } from "../../../interfaces/ScheduleInterface";
import useCan from "../../../hooks/useCan";

interface PatientFlowInterface {
  bookings: Booking[];
  refetch: () => void;
  isBookingFetching: boolean;
}
interface BookingEventsInterface {
  bookings: { collection: Booking[] };
  schedules: {
    collection: Schedule[];
  };
}

export function useDashboardKeyIndicators(
  startTime: string,
  endTime: string,
  branchId?: string,
  refreshData?: boolean,
  departmentId?: boolean
): { data: KeyIndicators; refetch: () => void; isFetching: boolean } {
  const fallback = {};
  const isSuperAdmin = useCan("deny", { superAdminPass: true });
  const {
    data = fallback,
    refetch,
    isFetching
  } = useQuery(
    [
      queryKeys.dashboard,
      "keyIndicators",
      startTime,
      endTime,
      ...(branchId ? [branchId] : []),
      refreshData || false,
      ...(departmentId ? [departmentId] : [])
    ],
    () => getDashboardKeyIndicatorsData(startTime, endTime, branchId, refreshData, departmentId),
    {
      enabled: !isSuperAdmin
    }
  );
  return { data: data as KeyIndicators, refetch, isFetching };
}

export function useDashboardWeeklyRevenue(): WeeklyRevenues[] {
  const fallback = [];
  const { data = fallback } = useQuery(
    [queryKeys.dashboard, "weeklyRevenue"],
    getDashboardWeeklyRevenueData
  );
  return data as WeeklyRevenues[];
}

export function useDashboardPatientFlow(selectedDate: moment.Moment): PatientFlowInterface {
  const fallback = [];
  const {
    data: bookings = fallback,
    refetch,
    isFetching
  } = useQuery(
    [queryKeys.dashboard, "patientFlow", "bookings"],
    () => getBookings(null, moment(selectedDate).startOf("day"), moment(selectedDate).endOf("day")),
    {
      refetchInterval: 60000 // 1 minute
    }
  );
  React.useEffect(() => {
    const fetchBookingsTimer = setTimeout(() => refetch(), 500);
    return () => clearTimeout(fetchBookingsTimer);
  }, [selectedDate]);

  return { bookings, refetch, isBookingFetching: isFetching } as PatientFlowInterface;
}

export function useDashboardBookingEvents(): BookingEventsInterface {
  const fallback = [];
  const { data: bookings = fallback } = useQuery(
    [queryKeys.dashboard, "patientFlow", "bookings"],
    () => getBookings(null, moment().startOf("day"), moment().endOf("day"))
  );
  const { data: schedules = fallback } = useQuery(
    [queryKeys.dashboard, "patientFlow", "schedules"],
    () => getSchedules(null, moment().startOf("day"), moment().endOf("day"))
  );
  const events = {
    bookings: { collection: bookings } as { collection: Booking[] },
    schedules: {
      collection: schedules
    } as { collection: Schedule[] }
  };
  return events;
}

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getLabelForComponent } from "../containers/Assessment/AssessmentForm/Form";
import { ResourceCentre } from "../interfaces/ResourceCentreInterface";

interface AssessmentLabel {
  recordPrescription: string;
  vitals: string;
  questionnaire: string;
  chiefComplaints: string;
  pastHistoryOfAllergy: string;
  hopi: string;
  examination: string;
  eye: string;
  intraoral: string;
  advancedIntraOral: string;
  extraOral: string;
  provisionalDiagnosis: string;
  labTest: string;
  service: string;
  diagnosis: string;
  presentHealthStatus: string;
  medication: string;
  attachments: string;
  followup: string;
  others: string;
  treatmentPlan: string;
  treatmentGiven: string;
  advice: string;
  productMedication: string;
  investigation: string;
  opdDuration: string;
}

const useAssessmentLabel = (): AssessmentLabel => {
  const componentLabels = useSelector((state: RootState) => {
    const currentResourceCentre: ResourceCentre = (state.resources?.resourceCentres || []).find(
      (item: ResourceCentre) => item.id === state.userContext?.resourceCentreId
    );
    return currentResourceCentre?.settings?.assessmentSettings?.componentLabels || {};
  });
  const labels = {};
  Object.keys(componentLabels).forEach((component) => {
    labels[component] = getLabelForComponent(componentLabels[component]);
  });
  return labels as AssessmentLabel;
};

export default useAssessmentLabel;

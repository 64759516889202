import React from "react";
import { Box, Typography } from "@mui/material";
import produce from "immer";
import { tl } from "../../../components/translate";
import LabTestRow from "./LabTestRow";
import styles from "../Lab.module.css";
import LabEntryFooter from "./LabEntryFooter";

interface PathologyContentInterface {
  labRecord: any;
  onLabRecordResultsChange: (val) => void;
  onCommentChange: (val) => void;
  onFilesChange: (files) => void;
  printMode?: boolean;
  showMethods?: boolean;
}

const PathologyContent: React.FC<PathologyContentInterface> = ({
  labRecord,
  onLabRecordResultsChange,
  onCommentChange,
  onFilesChange,
  printMode,
  showMethods = false
}) => {
  if (!labRecord) return null;
  return (
    <Box>
      <Box className={styles.labEntryContent}>
        <Box className={styles.fifthWidthCent}>
          <Typography>
            <Box component="span" fontWeight="600">
              {tl("lab.test")}
            </Box>
          </Typography>
        </Box>
        <Box className={styles.twenWidthCent}>
          <Typography>
            <Box component="span" fontWeight="600">
              {tl("lab.reading")}
            </Box>
          </Typography>
        </Box>
        <Box className={styles.twenWidthCent} textAlign="left" pl={1.5}>
          <Typography>
            <Box component="span" fontWeight="600">
              {tl("lab.units")}
            </Box>
          </Typography>
        </Box>
        <Box className={styles.twenWidthCent}>
          <Typography>
            <Box component="span" fontWeight="600">
              {tl("lab.referenceRange")}
            </Box>
          </Typography>
        </Box>
        {showMethods && (
          <Box className={styles.twenWidthCent}>
            <Typography>
              <Box component="span" fontWeight="600">
                {tl("lab.methods")}
              </Box>
            </Typography>
          </Box>
        )}
      </Box>
      <Box borderTop="1px solid gray" borderBottom="1px solid gray">
        <Box p="8px 32px">
          {labRecord?.results.data.map((res, ind) => (
            <LabTestRow
              // eslint-disable-next-line react/no-array-index-key
              key={ind}
              status={labRecord.status}
              gender={labRecord?.client?.gender ? labRecord.client.gender : "0"}
              dob={labRecord?.client?.dob || ""}
              labTest={res}
              onRowChange={(newRow) => {
                const newResults = produce(labRecord.results.data, (draft) => {
                  draft.splice(ind, 1, newRow);
                });
                onLabRecordResultsChange(newResults);
              }}
              disabled={false}
              printMode={printMode}
              resultDate={labRecord.resultDate}
            />
          ))}
          {!printMode && (
            <LabEntryFooter
              onCommentChange={onCommentChange}
              onFilesChange={onFilesChange}
              labRecord={labRecord}
              printMode={printMode}
              status={labRecord.status}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

PathologyContent.defaultProps = {
  printMode: false,
  showMethods: false
};

export default PathologyContent;

const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      // Remove the MIME type prefix
      const base64String = result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = reject;
  });

export default fileToBase64;

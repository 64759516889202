import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import moment from "moment-timezone";
import List from "../../../components/List";
import { EmptyView } from "../../../components/OList";
import { Estimate } from "./EstimatesCreateEdit";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { notificationAdd } from "../../../actions/notification";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import { fetchEstimatesByClient } from "../../../slices/estiamtesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

interface Props {
  clientId: number;
  onRowClick: (value: Estimate) => void;
}
function EstimateList({ clientId, onRowClick }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const estimates = useAppSelector((state) => state.estimates.collection);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEstimatesByClient(clientId)).unwrap();
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: errorFetchMessage(MODULE.ESTIMATES),
            variant: "error",
            autoTimeout: true
          })
        );
      }
    })();
  }, [clientId]);

  return (
    <List
      automation="estimatesList"
      withoutSearch
      hideCreateButton
      rowHeight={40}
      data={estimates.results}
      adjustHeightToContents
      onRowClick={(row: Estimate) => {
        onRowClick(row);
      }}
      columns={[
        {
          key: "id",
          label: "ID",
          formatter: ({ id }) => <Typography>{id}</Typography>
        },
        {
          key: "created_at",
          label: "Date",
          // eslint-disable-next-line camelcase
          formatter: ({ created_at }) => (
            <Typography>{moment(created_at).format("DD-MM-YYYY")}</Typography>
          )
        },
        {
          key: "serviceProvider",
          label: "Service Provider",
          formatter: ({ serviceProvider }) => (
            <Typography>{spFullNameSelector(serviceProvider)}</Typography>
          )
        }
      ]}
    >
      <EmptyView>
        <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
          No estimates created for the client yet...
        </Box>
      </EmptyView>
    </List>
  );
}

export default EstimateList;

import * as React from "react";
import { FilesInput } from "../../components/FilesInput/FilesInput";
import { assessmentAttachmentsUrl, deleteAttachmentEndPoint } from "../../api/assessment";
import { getToken } from "../../auth/authentication";
import { tl } from "../../components/translate";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

/* eslint-disable  @typescript-eslint/explicit-module-boundary-types
 */ // eslint-disable-next-line arrow-body-style
const Attachment = ({ assessmentId, onfilesChange, readOnly = false }) => {
  const assessmentLabels = useAssessmentLabel();
  return (
    <FilesInput
      endpoint={assessmentAttachmentsUrl(assessmentId)}
      deleteEndpoint={deleteAttachmentEndPoint()}
      authToken={getToken()}
      attachmentDisabled={readOnly}
      label={assessmentLabels.attachments}
      chooseFileLabel={tl("assessment.addAttachmentsMax")}
      maxString={tl("assessment.maxCountString")}
      onfilesChange={onfilesChange}
    />
  );
};

export default Attachment;

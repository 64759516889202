import * as React from "react";
import { Box } from "@mui/material";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import DoneIcon from "@mui/icons-material/Done";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { t, tl } from "../../../components/translate";
import { OPDRequestStatus } from "../../../enum/OpdRequestForm";
import { RequestForm, updateOpdRequestStatus } from "../../../api/requestForms";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";

interface IProps {
  opdRequest: Partial<RequestForm>;
  setOpdRequest: React.Dispatch<React.SetStateAction<Partial<RequestForm>>>;
  // eslint-disable-next-line max-len
  setOpdRequestData: React.Dispatch<
    React.SetStateAction<{ results: RequestForm[]; total: number }>
  >;
}

const options = ["markOrdered", "markDelivered", "markCancelled"];

const HandleOpdRequestStatus = (props: IProps): JSX.Element => {
  const { opdRequest, setOpdRequest, setOpdRequestData } = props;

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [actionAfter, setActionAfter] = React.useState<string | null>(null);
  const [deliveryDate, setDeliveryDate] = React.useState(new Date().toISOString());
  const [openDeliveryDateSelect, setOpenDeliveryDateSelect] = React.useState(false);
  const [statusOptions, setStatusOptions] = React.useState(options);

  React.useEffect(() => {
    if (opdRequest?.status === OPDRequestStatus.ORDERED) {
      setStatusOptions(options.filter((item) => item !== "markOrdered"));
    } else if (opdRequest?.status === OPDRequestStatus.DELIVERED) {
      setStatusOptions(options.filter((item) => item !== "markDelivered"));
    } else if (opdRequest?.status === OPDRequestStatus.CANCELLED) {
      setStatusOptions(options.filter((item) => item !== "markCancelled"));
    }
  }, [opdRequest?.status]);

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  const handleClick = async (option: string, index: number) => {
    setOpenMenu(false);
    setSelectedIndex(index);

    if (option === "markDelivered") {
      setActionAfter(option);
      setOpenDeliveryDateSelect(true);
    } else {
      const res = await updateOpdRequestStatus(opdRequest.id, {
        status: OPDRequestStatus.CANCELLED
      });
      if (res) {
        setOpdRequest(res);
        setOpdRequestData((prev) => ({
          ...prev,
          results: prev.results.map((item) => (item.id === res.id ? res : item))
        }));
      }
    }
  };

  const handleMarkDelivered = async () => {
    const res = await updateOpdRequestStatus(opdRequest.id, {
      status: OPDRequestStatus.DELIVERED,
      deliveryDate
    });

    if (res) {
      setOpdRequest(res);
      setOpdRequestData((prev) => ({
        ...prev,
        results: prev.results.map((item) => (item.id === res.id ? res : item))
      }));
    }
  };

  return (
    <>
      <ButtonGroup
        size="small"
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          data-testmation={statusOptions[0]}
          onClick={() => {
            handleClick(statusOptions[0], 0);
          }}
        >
          {tl(`opd.statusAction.${statusOptions[0]}`)}
        </Button>
        {statusOptions.length > 1 && (
          <Button
            size="small"
            color="primary"
            aria-owns={openMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={() => setOpenMenu((prevOpen) => !prevOpen)}
            data-testmation="handleBookingDownArrow"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        style={{ zIndex: 1 }}
        open={openMenu}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {statusOptions.map((option, index) => {
                    if (index === 0) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={() => {
                          handleClick(option, index);
                        }}
                        data-testmation={option}
                      >
                        {tl(`opd.statusAction.${statusOptions[index]}`)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        style={{ zIndex: 1 }}
        open={openDeliveryDateSelect}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={() => setOpenDeliveryDateSelect(false)}>
                <div
                  style={{
                    padding: "16px",
                    border: "1px solid #908f8f",
                    boxShadow: "0px 0px 3px 3px #c7c7c754"
                  }}
                >
                  <CalendarDropdown
                    fullwidth
                    TextFieldProps={{ variant: "outlined" }}
                    label={t("opd.requestForm.deliveryDate")}
                    placeholder={t("opd.requestForm.sentDatePlaceholder")}
                    date={new Date(deliveryDate)}
                    maxValidDate={new Date(deliveryDate)}
                    onChange={(val) => {
                      setDeliveryDate(val.toISOString());
                    }}
                  />
                  <Box component="div" textAlign="right" marginTop="16px">
                    <Button
                      style={{ marginLeft: "32px" }}
                      onClick={() => setOpenDeliveryDateSelect(false)}
                    >
                      {tl("booking.cancel")}
                    </Button>
                    <Button
                      style={{ marginLeft: "8px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOpenDeliveryDateSelect(false);
                        handleMarkDelivered();
                      }}
                    >
                      {tl(`opd.statusAction.${actionAfter}`)}
                      <DoneIcon style={{ marginLeft: "5px" }} />
                    </Button>
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default HandleOpdRequestStatus;

import moment from "moment";
import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { AudienceData, getGenderString } from "./AudienceHelpers";
import Panel from "../../../components/Panel";
import * as audienceActions from "../../../actions/audience";
import { navigateRemoveModal, showDialog } from "../../../actions/navigation";
import ClientInfoModal from "../../Client/ClientInfoModal";
import Can from "../../Policy/Can";
import { useAppSelector } from "../../../store/hooks";

interface AudienceInfoProps {
  audience: AudienceData;
  onClose: any;
  onDeleteAudience: any;
  getAudienceSize: any;
  navigateTo: any;
}

const AudienceInfo: React.FC<AudienceInfoProps> = ({
  audience,
  navigateTo,
  onClose,
  getAudienceSize,
  onDeleteAudience
}) => {
  const editButton = (
    <Can policyAccessKey="campaign:editAudience">
      <EditIcon
        data-testmation="editButton"
        style={{ cursor: "pointer" }}
        onClick={() => navigateTo(`/campaign/audience/${audience.id}/edit`)}
      />
    </Can>
  );

  const deleteButton = (
    <Can policyAccessKey="campaign:deleteAudience">
      <DeleteIcon
        data-testmation="deleteButton"
        style={{ cursor: "pointer" }}
        onClick={() => onDeleteAudience(audience.id, audience.name)}
      />
    </Can>
  );
  const [audienceSize, setAudienceSize] = React.useState(0);
  const services = useAppSelector((state) =>
    state.services.collection.filter(({ active }) => active)
  );
  React.useEffect(() => {
    getAudienceSize(audience.id, (data) => {
      if (data?.audienceSize) setAudienceSize(data.audienceSize);
    });
  }, [audience.id]);
  return (
    <Panel onClose={onClose} editButton={editButton} deleteButton={deleteButton}>
      <Box px="32px" width="100%">
        <Typography variant="h6">{audience.name}</Typography>
        <Box marginTop="32px" width="100%">
          <Box display="flex">
            <Box width="25%" fontSize="14px" fontWeight={600}>
              <Typography style={{ fontWeight: 600 }}>{"Type: "}</Typography>
            </Box>
            <Box fontSize="14px">
              <Typography>{capitalize(audience.type)}</Typography>
            </Box>
          </Box>
          {audience.type === "static" && (
            <Box>
              <Box width="25%" fontSize="14px" mt={1} fontWeight={600}>
                <Typography style={{ fontWeight: 600 }}>{"Client list "}</Typography>
              </Box>
              {audience.rules.client?.ids?.map((id) => (
                <ClientInfoModal clientId={id} key={id} />
              ))}
            </Box>
          )}

          {audience.type === "dynamic" && (
            <Box>
              <Box display="flex" mt={1}>
                <Box width="25%" fontSize="14px" fontWeight={600}>
                  <Typography style={{ fontWeight: 600 }}>{"Number of clients: "}</Typography>
                </Box>
                <Box fontSize="14px">
                  <Typography>{`${audienceSize} ${
                    audience.isBatch && !audience.isReady
                      ? "(Audience data not updated/ready yet.)"
                      : ""
                  }`}</Typography>
                </Box>
              </Box>
              <Box mt={1} width="25%" fontSize="14px" fontWeight={600}>
                <Typography style={{ fontWeight: 800 }}>{"Rules list "}</Typography>
              </Box>

              <Box>
                {audience.rules.client?.age && (
                  <Box display="flex">
                    <Box width="25%" fontSize="14px" fontWeight={600}>
                      <Typography style={{ fontWeight: 600 }}>{"Age: "}</Typography>
                    </Box>
                    <Box fontSize="14px">
                      <Typography>{`Less than ${audience.rules.client?.age?.lte} and greater than ${audience.rules.client?.age?.gte} `}</Typography>
                    </Box>
                  </Box>
                )}
                {audience.rules.client?.gender && (
                  <Box display="flex">
                    <Box width="25%" fontSize="14px" fontWeight={600}>
                      <Typography style={{ fontWeight: 600 }}>{"Gender: "}</Typography>
                    </Box>
                    <Box fontSize="14px">
                      <Typography>
                        {`Gender is ${getGenderString(audience.rules.client?.gender)}  `}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {audience.rules.client?.dob && (
                  <Box display="flex">
                    <Box width="25%" fontSize="14px" fontWeight={600}>
                      <Typography style={{ fontWeight: 600 }}>{"DOB: "}</Typography>
                    </Box>
                    <Box fontSize="14px">
                      <Typography>
                        {audience.rules.client.dob === "today" && moment().format("YYYY-MM-DD")}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {audience.rules.clientLedger?.dueAmount && (
                  <Box display="flex">
                    <Box width="25%" fontSize="14px" fontWeight={600}>
                      <Typography style={{ fontWeight: 600 }}>{"Due: "}</Typography>
                    </Box>
                    <Box fontSize="14px">
                      <Typography>{`Less than ${audience.rules.clientLedger?.dueAmount?.lte} and greater than ${audience.rules.clientLedger?.dueAmount?.gte} `}</Typography>
                    </Box>
                  </Box>
                )}
                {audience.rules.service && (
                  <Box display="flex">
                    <Typography sx={{ width: "25%", fontWeight: 600 }}>{"Service: "}</Typography>

                    <Typography fontSize="14px">{`Has received ${
                      (services || []).find((item) => item.id === audience.rules.service?.id)
                        ?.name || ""
                    } service in the last ${audience.rules.service.duration} months`}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Panel>
  );
};

export default connect(
  () => {
    //
  },
  (dispatch) => ({
    navigateTo: (url) => dispatch(push(url)),
    getAudienceSize: async (audienceId, next) => {
      await dispatch(audienceActions.getAudienceById(audienceId, next));
    },
    onDeleteAudience: (id, name) => {
      dispatch(
        showDialog({
          title: `Delete audience`,
          description: `Are you sure you want to delete ${name}?`,
          next: () => {
            dispatch(audienceActions.deleteAudience(id));
            dispatch(navigateRemoveModal("Dialog"));
            dispatch(push("/campaign/audience"));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    }
  })
)(AudienceInfo);

import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getTrialBalanceReport } from "../../../../../api/accountReports";
import {
  TrialBalanceInterface,
  FiltersInterface
} from "../../../../../interfaces/AccountReportsInterface";
import { queryKeys } from "../../../../../react-query/constants";
import * as NotificationActions from "../../../../../actions/notification";

export default function useTrialBalance(filters: FiltersInterface): TrialBalanceInterface[] {
  const { periodDate } = filters;

  const pastDateSelected = moment().isAfter(periodDate);
  let queryParams;

  if (pastDateSelected) {
    // if the selected date is not today, then make the timestamp to be end of that day
    queryParams = `date=${moment(periodDate).endOf("day").toISOString()}`;
  } else {
    // if selected date is today, then let the timestamp be exactly as of the selected time
    queryParams = `date=${periodDate.toISOString()}`;
  }

  const dispatch = useDispatch();
  const { data = [] } = useQuery(
    [queryKeys.account, "trialBalanceReport", { periodDate }],
    () => getTrialBalanceReport(queryParams),
    {
      refetchOnMount: true,
      retry: 1,
      onError: (e) => {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: `${e.data}`,
            autoTimeout: true
          })
        );
      }
    }
  );
  return data;
}

import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import List from "../../components/OList";
import BillShow from "./BillShow/BillShow";
import { tl } from "../../components/translate";
import { rupeeDisplay } from "../../helpers/rupee";
import { BillType } from "../../interfaces/BillInterfaces";
import { CalendarFunctions } from "../../components/Calendar";
import { clientFullNameSelector } from "../../reducers/client";

interface PropType {
  billId: number;
  isForKitchenPharmacy?: boolean;
  setShowMessagePanel: (value: boolean) => void;
}

const Item = ({ children, width = "initial" }) => (
  <Typography component="span" width={width} fontWeight={500} fontSize="11px">
    {children}
  </Typography>
);

const BillCreditNoteList = ({
  isForKitchenPharmacy = false,
  billId,
  setShowMessagePanel
}: PropType): JSX.Element => {
  const bills = useSelector((state) => state.bills.collection);
  const [selectedBill, setSelectedBill] = React.useState<BillType | null>(null);

  const handleViewClose = () => {
    setSelectedBill(null);
    setShowMessagePanel(false);
  };

  const creditNotes = useMemo(
    () => bills.filter((bill) => bill.referenceTo === billId && bill.type === "creditNote"),
    [billId, bills]
  );

  if (!creditNotes.length) {
    return <Typography pt="10px">{tl("creditnote.notAvailable")}</Typography>;
  }

  return (
    <>
      <List
        automation="CreditNotes"
        rowHeight={30}
        data={creditNotes}
        adjustHeightToContents
        activeRow={selectedBill && selectedBill.id}
        onRowClick={(bill) => setSelectedBill(bill)}
        columns={[
          {
            key: "billNumber",
            label: tl("billing.billNumber"),
            sortable: true,
            formatter: ({ isDraft, billNumber }) => <Item>{isDraft ? "-" : billNumber}</Item>
          },
          {
            key: "name",
            label: tl("billing.client"),
            sortable: false,
            formatter: (row) => (
              <Item>
                {`${clientFullNameSelector(row.client).toUpperCase()} ${
                  row.client?.active ? "" : "(Deleted)"
                }`}
              </Item>
            )
          },
          {
            key: "date",
            label: tl("billing.date"),
            formatter: ({ issueDate }) => (
              <Item>{CalendarFunctions.convertADtoBS(moment(issueDate)).formatted2}</Item>
            ),
            sortable: true,
            sortFunction: (a, b) => (a.issueDate > b.issueDate ? 1 : -1)
          },
          {
            key: "total",
            label: tl("billing.total"),
            sortable: true,
            formatter: ({ total }) => <Item>{rupeeDisplay(total)}</Item>
          }
        ]}
      />

      {selectedBill && (
        <BillShow
          billId={selectedBill.id}
          wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
          setShowMessagePanel={setShowMessagePanel}
          handleViewClose={handleViewClose}
          isForKitchenPharmacy={isForKitchenPharmacy}
        />
      )}
    </>
  );
};

export default BillCreditNoteList;

import { push } from "connected-react-router";
import { actionCreatorAsync } from "./actionHelpers";
import * as api from "../api/bookings";
import { IThunkDispatch } from "../store";

export enum Type {
  GET_BOOKINGS = "GET_BOOKINGS",
  GET_BOOKINGS_MINIMAL = "GET_BOOKINGS_MINIMAL",
  GET_BOOKING = "GET_BOOKING",
  UPDATE_BOOKING = "UPDATE_BOOKING",
  CANCEL_BOOKING = "CANCEL_BOOKING",
  BOOK = "BOOK",
  GET_BOOKINGS_FOR_DASHBOARD = "GET_BOOKINGS_FOR_DASHBOARD",
  MODIFY_BOOKING = "MODIFY_BOOKING",
  DELETE_BOOKING = "DELETE_BOOKING"
}

export const getBookings =
  (
    serviceProviderId: number,
    start: Date,
    end: Date,
    resourceId: number,
    publicEventChecked: boolean
  ) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_BOOKINGS, () =>
        api.getBookings(serviceProviderId, start, end, resourceId, publicEventChecked)
      )
    );
  };

export const getBookingsForSA =
  (filters: Record<string, unknown>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_BOOKINGS, async () => api.getBookingsForSA(filters))
    );
  };

export const getBookingsForDashboard =
  (start: Date, end: Date) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_BOOKINGS_FOR_DASHBOARD, async () =>
        api.getBookings(null, start, end)
      )
    );
  };

export const getBooking =
  (bookingId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(actionCreatorAsync(Type.GET_BOOKING, async () => api.getBooking(bookingId)));
  };

export const updateBooking =
  (bookingId: number, attrs: Record<string, unknown>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.MODIFY_BOOKING, async () => api.updateBooking(bookingId, attrs))
    );
  };

export const cancelBooking =
  (bookingId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.MODIFY_BOOKING, async () => api.cancelBooking(bookingId))
    );
  };

export const deleteBooking =
  (bookingId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.DELETE_BOOKING, async () => {
        api.deleteBooking(bookingId);
        return bookingId;
      })
    );
  };

export const book =
  (bookingData: Record<string, unknown>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.BOOK, async () => {
        dispatch(push("/calendar"));
        const booking = await api.book(bookingData);
        return booking;
      })
    );
  };

import React from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { Test } from "../../../../interfaces/Lab";
import { addSubTest } from "../TestGroupNameAndCategoryEdit";
import { RootState } from "../../../../store";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: Test[]) => void;
}

const AddLabTestsDialog = ({ isOpen, onSave, onClose }: Props): React.ReactElement => {
  const labTests = useAppSelector((state: RootState) => state.labSettings.labTests);
  const [testsToAdd, setTestsToAdd] = React.useState<Test[]>([]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Add lab tests under this test group</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          id="combo-box-demo"
          options={labTests as Array<Test>}
          onChange={(e, v) => {
            if (typeof v === "string") return;
            if (!v) {
              setTestsToAdd([]);
              return;
            }
            setTestsToAdd(addSubTest(v, labTests));
          }}
          fullWidth
          getOptionLabel={(option) => option.name}
          renderOption={(params, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...params} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Select Lab Tests To Add Under This Test"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onSave(testsToAdd);
          }}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLabTestsDialog;

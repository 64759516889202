import React from "react";
import { Box, Typography, Button } from "@mui/material";
import moment from "moment";
import produce from "immer";
import { tl } from "../../components/translate";
import VitalRow, { vitals as vitalsModel } from "../Assessment/Vitals/VitalRow";
import { Vitals } from "../../interfaces/AssessmentInterfaces";

export const getDefaultVitals = (
  vitalsOrder: string[],
  vitalSettings: Record<string, boolean>
): Vitals[] => {
  const vitals: Vitals[] = [];
  vitalsOrder.forEach((item: string) => {
    if (vitalSettings[item]) {
      const vitalInfo = vitalsModel.find((vt) => vt.dbKey === item);
      if (vitalInfo) {
        vitals.push({
          name: vitalInfo.name,
          reading: vitalInfo.defaultValue,
          type: vitalInfo.type,
          unit: vitalInfo.unit,
          date: moment()
        });
      }
    }
  });
  return vitals;
};

interface Props {
  vitals: Vitals[];
  onChange: (data: Vitals[]) => void;
}

const MedicalVitals = ({ onChange, vitals }: Props): JSX.Element => (
  <Box mt={2}>
    <Box sx={{ width: "100%", display: "flex" }}>
      <Box width="30%">
        <Typography style={{ fontWeight: 600 }}>{tl("assessment.vital")}</Typography>
      </Box>
      <Box width="30%" pl="20px">
        <Typography style={{ fontWeight: 600 }}>{tl("assessment.reading")}</Typography>
      </Box>
      <Box width="40%" pl="42px">
        <Typography style={{ fontWeight: 600 }}>{tl("assessment.extraInfo")}</Typography>
      </Box>
    </Box>
    <Box sx={{ width: "100%" }}>
      {vitals.map((item, index) => (
        <VitalRow
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          vital={item}
          onChange={(newObj) => {
            const newArr = produce(vitals, (draft) => {
              draft[index] = newObj;
            });
            onChange(newArr);
          }}
          onRemove={() => {
            const newArr = produce(vitals, (draft) => {
              draft.splice(index, 1);
            });
            onChange(newArr);
          }}
        />
      ))}
    </Box>
    <Button
      onClick={() => {
        onChange([
          ...vitals,
          {
            name: null,
            reading: null,
            unit: null,
            extraInfo: null,
            date: moment()
          }
        ]);
      }}
    >
      {tl("assessment.addNew")}
    </Button>
  </Box>
);

export default MedicalVitals;

import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import List, { MultipleHeadersInterface } from "../../../components/List";
import { t, tl } from "../../../components/translate";
import CampaignCreateEdit from "./CampaignCreateEdit";
import * as campaignActions from "../../../actions/campaigns";
import { campaignsSelector } from "../../../reducers/campaigns";
import "./CampaignList.scss";
import CampaignInfo from "./CampaignInfo";
import {
  CampaignData,
  campaignStatus,
  getFormattedTriggerFromCampaign,
  statusColor
} from "./CampaignHelpers";
import EntryButton from "./EntryButton";
import MessageCreate from "../InstantMessage/MessageCreate";

interface CampaignListProps {
  actions: {
    loadCampaigns: () => void;
    goto: (path: string) => void;
  };
  campaignList: CampaignData[];
  mode: string;
  match: any;
}
export const multipleHeaders = (match: { url: string }): MultipleHeadersInterface => ({
  headers: [
    { key: "campaigns", title: tl(`campaign.campaigns`), goto: "/campaign/campaigns" },
    { key: "audience", title: tl(`campaign.audience`), goto: "/campaign/audience" },
    { key: "template", title: tl(`campaign.template`), goto: "/campaign/template" }
  ],
  url: match?.url
});

const getCampaignStatus = (status, activated) => {
  let text = "campaign.status";
  if (status !== campaignStatus.COMPLETED) {
    if (activated) {
      if (status === "active") {
        text += ".active";
      } else {
        text += `.${status}`;
      }
    } else {
      text += ".deactivated";
    }
  } else {
    text += ".completed";
  }
  return t(text);
};

const CampaignList: React.FC<CampaignListProps> = ({ actions, campaignList, mode, match }) => {
  React.useEffect(() => {
    actions.loadCampaigns();
  }, []);

  const [selectedCampaign, setSelectedCampaign] = React.useState(null);

  React.useEffect(() => {
    const campaignId = match.params.id;
    if (campaignId && campaignList.length > 0) {
      const queryAudience = campaignList.find((campaign) => campaign!.id === Number(campaignId));
      setSelectedCampaign(queryAudience);
    } else {
      setSelectedCampaign(null);
    }
  }, [match.params.id, campaignList]);

  return campaignList ? (
    <Box className="campaignList">
      <Box height="100%">
        <List
          testmationLabel="createCampaign"
          automation="campaignList"
          rowHeight={50}
          createLabel={tl("campaign.createCampaign")}
          customCreateButton={<EntryButton navigateTo={actions.goto} />}
          multipleHeaders={multipleHeaders(match)}
          defaultSortColumn="name"
          data={campaignList || []}
          isResponsive
          columns={[
            {
              key: "name",
              label: `${t("campaign.colName")}`,
              sortable: true,
              sortFunction: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
              formatter: ({ name }) => <Typography>{capitalize(name)}</Typography>
            },
            {
              key: "audience",
              label: `${t("campaign.audience")}`,
              sortable: true,
              formatter: ({ audience }) => <Typography>{capitalize(audience.name)}</Typography>
            },
            {
              key: "mediaType",
              label: `${t("campaign.colMedia")}`,
              hideInNarrowView: true,
              sortable: true,
              sortFunction: (a, b) =>
                a.mediaType.toLowerCase() > b.mediaType.toLowerCase() ? 1 : -1,
              formatter: ({ mediaType }) => <Typography>{capitalize(mediaType)}</Typography>
            },
            {
              key: "status",
              label: `${t("campaign.colStatus")}`,
              sortable: true,
              sortFunction: (a, b) => (a.status.toLowerCase() > b.status.toLowerCase() ? 1 : -1),
              formatter: ({ activated, status }) => (
                <Box display="flex" flexDirection="row">
                  {status !== campaignStatus.COMPLETED ? (
                    <Box
                      className="statusIndicator"
                      style={{
                        backgroundColor: statusColor[activated ? status : "deactivated"]
                      }}
                      mr={1.6}
                    />
                  ) : (
                    <Box mr={1}>
                      <CheckIcon color="primary" />
                    </Box>
                  )}
                  <Typography>{capitalize(getCampaignStatus(status, activated))}</Typography>
                </Box>
              )
            },
            {
              key: "trigger",
              label: `${t("campaign.colTrigger")}`,
              hideInNarrowView: true,
              formatter: ({ trigger }) => (
                <Typography>{getFormattedTriggerFromCampaign(trigger)}</Typography>
              )
            }
          ]}
          isLoading={!campaignList}
          activeRow={selectedCampaign && selectedCampaign.id}
          onRowClick={async (campaign) => {
            const notSelected = !selectedCampaign || selectedCampaign.id !== campaign.id;
            setSelectedCampaign(notSelected ? campaign : null);
            actions.goto(
              notSelected ? `/campaign/campaigns/${campaign.id}` : "/campaign/campaigns/"
            );
          }}
        />

        {mode === "message" && (
          <MessageCreate
            onCancel={() => {
              actions.goto("/campaign/campaigns");
            }}
          />
        )}

        {(selectedCampaign || ["create", "edit"].includes(mode)) && (
          <>
            {["create", "edit"].includes(mode) ? (
              <CampaignCreateEdit
                onCancel={() => {
                  setSelectedCampaign(null);
                  actions.goto("/campaign/campaigns");
                }}
                mode={mode}
                campaignId={selectedCampaign?.id}
              />
            ) : (
              <Box>
                <CampaignInfo
                  campaign={selectedCampaign}
                  onClose={() => {
                    setSelectedCampaign(null);
                    actions.goto("/campaign/campaigns");
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  ) : null;
};

const mapStateToProps = (state, { campaignId }: { campaignId?: number }) => ({
  campaignList: campaignsSelector(state),
  campaign: state.campaigns.collection.find(({ id }) => id === Number(campaignId))
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadCampaigns: () => dispatch(campaignActions.getCampaigns()),
    navigateTo: (url) => dispatch(push(url)),
    goto: (url) => dispatch(push(url))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);

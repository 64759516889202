import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.css';
import { CanActions } from '../../interfaces/policy';
import Can from '../../containers/Policy/Can';

interface PropsType {
  actions: Array<{
    label: string | JSX.Element;
    action: () => void;
    Icon: any;
    dataTestmation: string;
    accessKey: CanActions;
  }>
  onClose?: () => void;
  disableEdit?: boolean;
}

const ActionBar = ({ actions, onClose, disableEdit }: PropsType) => (
  <div className={styles.root}>
    {actions.map(({
      action, Icon, label, dataTestmation, accessKey
    }, i) => (
      <Can policyAccessKey={accessKey === undefined ? 'allow' : accessKey}>
        <Tooltip
          key={`${label}${i}`}
          PopperProps={{ disablePortal: true }}
          style={{ zIndex: 1 }}
          title={label}
        >
          <IconButton
            className={styles.button}
            onClick={action}
            data-testmation={dataTestmation}
            disabled={i === 2 && disableEdit}
            size="large">
            <Icon className={styles.icon} />
          </IconButton>
        </Tooltip>
      </Can>
    ))}
    {onClose && (
      <IconButton
        key="closeIconBtn"
        style={{ marginLeft: '16px' }}
        className={`${styles.button} ${styles.closeBtn}`}
        onClick={onClose}
        data-testmation="closeIcon"
        size="large">
        <CloseIcon className={styles.icon} />
      </IconButton>
    )}
  </div>
);

export default ActionBar;

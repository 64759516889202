import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { getReferrerLedgers } from "../../../api/referrers";
import { ReferrerLedger } from "../../../interfaces/ReferrerInterface";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { EmptyView } from "../../../components/OList/List";
import List from "../../../components/List";
import { TransactionType } from "../../../interfaces/BillInterfaces";

export default function Ledgers({ referrerId }: { referrerId: number }): JSX.Element {
  const [ledgers, setLedgers] = React.useState<ReferrerLedger[]>([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    const getLedgers = async () => {
      try {
        const referrerLedgers = await getReferrerLedgers(referrerId);
        setLedgers(referrerLedgers);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            message: commonErrorMessage,
            autoTimeout: true
          })
        );
      }
    };
    if (referrerId) getLedgers();
  }, [referrerId, dispatch]);

  return (
    <Box className="referrerLedgersList">
      <List
        rowHeight={40}
        automation="clientLabs"
        data={ledgers}
        hideCreateButton
        withoutSearch
        columns={[
          {
            key: "transactionDate",
            label: "Date",
            sortable: true,
            formatter: ({ transactionDate }) => (
              <Typography>{convertADtoBS(new Date(transactionDate))?.formatted4}</Typography>
            )
          },
          {
            key: "voucherType",
            label: "Voucher Type",
            sortable: true,
            formatter: ({ voucherType }) => <Typography>{voucherType}</Typography>
          },
          {
            key: "debit",
            label: "Debit",
            sortable: true,
            formatter: ({ amount, transactionType }) => (
              <Typography>{transactionType === TransactionType.DEBIT && amount}</Typography>
            )
          },
          {
            key: "credit",
            label: "Credit",
            sortable: true,
            formatter: ({ amount, transactionType }) => (
              <Typography>{transactionType === TransactionType.CREDIT && amount}</Typography>
            )
          },
          {
            key: "dueAmount",
            label: "Balance",
            sortable: true,
            formatter: ({ dueAmount }) => <Typography>{dueAmount}</Typography>
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No ledgers for the referrer yet...
          </Box>
        </EmptyView>
      </List>
    </Box>
  );
}

export enum MODE {
  Show = 'show',
  Edit = 'edit'
}

export interface FeedInterface {
  action: string;
  actionProps: { actionLabel: string };
  bgImage: string;
  deactivated: boolean;
  description: string;
  endDate: string;
  actionLabel: string;
  nativeViewAction: string;
  serviceTag: serviceTage;
  id: string;
  isBanner: boolean;
  published: boolean;
  publishedOn: string;
  startDate: string;
  title: string;
  type: string;
}

interface serviceTage {
  id: string;
  name: string;
}

import React from "react";
import { Box, Typography } from "@mui/material";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { scaleFont } from "./BillPrintHelpers";

interface LabPrintLetterHeadProps {
  resourceCentre: ResourceCentre;
  titleFontScale: number;
  hideRcLogo: boolean;
}

const BankPaperLetterHead: React.FC<LabPrintLetterHeadProps> = ({
  resourceCentre: {
    resourceCentreLogo,
    name,
    slogan,
    address,
    city,
    registrationNumber,
    phone,
    mobileNumber,
    optionalPhone,
    web,
    email,
    panNo
  },
  titleFontScale = 1,
  hideRcLogo = false
}) => (
  <Box display="flex" justifyContent="space-between">
    <Box display="flex">
      {!hideRcLogo && resourceCentreLogo && (
        <Box mt="8px">
          <img
            src={resourceCentreLogo.s3ResourceURL}
            className="billLogo"
            alt="logo"
            style={{ width: "auto", height: "84px" }}
          />
        </Box>
      )}
      <Box pl={resourceCentreLogo ? 2 : 0}>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: scaleFont("0.42cm", titleFontScale)
          }}
        >
          {name}
        </Typography>
        {slogan && <Typography style={{ fontSize: "0.3cm", fontWeight: 400 }}>{slogan}</Typography>}
        <Typography style={{ textAlign: "left", textTransform: "capitalize", fontSize: "0.3cm" }}>
          {address} {city}
        </Typography>
        {registrationNumber && (
          <Typography style={{ textAlign: "left", textTransform: "capitalize", fontSize: "0.3cm" }}>
            Reg No: {registrationNumber}
          </Typography>
        )}
      </Box>
    </Box>
    <div>
      <Typography style={{ textAlign: "right", fontSize: "0.3cm" }}>
        {mobileNumber || ""}
      </Typography>
      <Typography style={{ textAlign: "right", fontSize: "0.3cm" }}>
        {phone}
        {optionalPhone && `,${optionalPhone}`}
      </Typography>
      <Typography style={{ textAlign: "right", fontSize: "0.3cm" }}>{web || ""}</Typography>
      <Typography style={{ textAlign: "right", fontSize: "0.3cm" }}>{email || ""}</Typography>
      {Boolean(panNo) && (
        <Typography style={{ textAlign: "right", fontSize: "0.3cm" }}>Pan No: {panNo}</Typography>
      )}
    </div>
  </Box>
);

export default BankPaperLetterHead;

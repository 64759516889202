/* eslint-disable @typescript-eslint/no-shadow */
import * as React from "react";
import ProgressBar from "react-fine-uploader/progress-bar";

export interface FileProgressProps {
  id: number;
  uploader: any;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class FileProgress<FileProgressProps> extends ProgressBar {
  

  // eslint-disable-next-line max-len
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/explicit-module-boundary-types
  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    const percent = Math.round((this.state.bytesUploaded / this.state.totalSize) * 100 || 0);

    return <span hidden={this.state.hidden}>{percent}% / </span>;
  }
}
import { Autocomplete, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { SEARCH_BY, SearchType } from "./ChatStats";
import { useAppDispatch } from "../../store/hooks";
import DebouncedTextField from "../../components/DebouncedTextField";
import { notificationAdd } from "../../actions/notification";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import { searchMobileUsers } from "../../api/publicBooking";

export interface MobileUser {
  id: number;
  name: string;
  email: string;
  gender: number;
  username: string;
  resourceCentreId: number;
  phone: string;
  dob: string;
  isGuest: boolean;
}
interface Props {
  onSearch: (value: SearchType) => void;
}

const UserSelect = ({ onSearch }: Props): JSX.Element => {
  const [userList, setUserList] = useState<MobileUser[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <Autocomplete
      options={userList}
      onChange={(_, v) => {
        onSearch({ by: SEARCH_BY.USER_ID, queryId: v?.id || null });
      }}
      loading={loading}
      sx={{ paddingLeft: "35px" }}
      fullWidth
      popupIcon={null}
      getOptionLabel={(option) => option.name}
      renderOption={(params, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...params} key={`${option?.id} ${params.key}`}>
          <Stack>
            <Typography>{option.name}</Typography>
            <Typography variant="caption">{option.phone && option.phone}</Typography>
          </Stack>
        </li>
      )}
      renderInput={(params) => (
        <DebouncedTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          setDebounceLoading={(debounceLoading) => setLoading(debounceLoading)}
          InputLabelProps={{ shrink: true }}
          onChange={async (e) => {
            if (e.target.value?.length > 2) {
              setLoading(true);
              try {
                const response = await searchMobileUsers(e.target.value);
                setUserList(response);
                setLoading(false);
              } catch (err) {
                dispatch(
                  notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "error",
                    message: err?.data || errorFetchMessage(MODULE.USER),
                    autoTimeout: true
                  })
                );
              }
            }
          }}
          variant="standard"
          debounceAt={1000}
          placeholder="Search..."
          margin="dense"
          size="small"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default UserSelect;

import { ReducerBuilder } from './ReducerBuilder';
import { ModalInterface } from '../actions/navigation';

const path = location.pathname;
const [mod, view] = path.split('/').slice(1);
const app = { mod, view };

export const INITIAL_STATE = {
  modals: []
};

interface StateType {
  modals: ModalInterface[];
}

function navigateAddModal(state: StateType, payload: ModalInterface): StateType {
  const { modals } = state;
  return { ...state, modals: [...modals, payload] };
}

function navigateRemoveModal(state: StateType, payload: string): StateType {
  const { modals } = state;
  return { ...state, modals: modals.filter(({ component }) => component !== payload) };
}

function navigateChangeModal(state: StateType, payload: ModalInterface): StateType {
  const newState = { ...state, modals: state.modals.map((m) => ({ ...m })) };
  const modal = newState.modals.find(modal => modal.component === payload.component);
  if (modal) {
    modal.props = payload.props;
  }
  return newState;
}

export default ReducerBuilder.create(INITIAL_STATE)
  .mapAction('NAVIGATE_ADD_MODAL', navigateAddModal)
  .mapAction('NAVIGATE_REMOVE_MODAL', navigateRemoveModal)
  .mapAction('NAVIGATE_CHANGE_MODAL', navigateChangeModal)
  .build();

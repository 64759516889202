import React from "react";
import { push } from "connected-react-router";
import { Box, Chip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import List from "../../../components/List";
import { bsFullDate } from "../../../components/Calendar/functions/calendarFunctions";
import { getFullName } from "../../../helpers/formatters";
import { LabTestRecordResultData } from "../../../interfaces/Lab";
import "../../Lab/LabList.scss";
import { tl } from "../../../components/translate";
import { RootState } from "../../../store";
import hasOwnProperty from "../../../helpers/object";
import LabStatusChip from "../../Lab/LabStatusChip";
import { getTurnAroundTime } from "../../Lab/ListTab";
import LabDetails from "../../Lab/LabDetails";
import PageControl from "../../../components/PageControl";

const AssessmentLabs = ({
  labs,
  updateLabs,
  page,
  setPage,
  totalLabs
}: {
  labs: Array<LabTestRecordResultData>;
  updateLabs: (data) => void;
  page: number;
  setPage: (value) => void;
  totalLabs: number;
}): JSX.Element => {
  const dispatch = useDispatch();
  const currentRCId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const useCustomLabTestNumber = useSelector((state: RootState) =>
    Boolean(state.userContext?.resourceCentre?.labSettings?.useCustomLabTestNumber)
  );
  const [selectedLabTest, setSelectedLabTest] = React.useState(null);
  const markLabAsAssessed = (id) => {
    updateLabs(() =>
      labs.map((lab) => ({
        ...lab,
        ...(lab.id === id ? { isAssessedBySp: true } : {})
      }))
    );
  };
  return (
    <Box height="100%">
      <List
        data={labs}
        rowHeight={50}
        isResponsive
        hideCreateButton
        withoutSearch
        columns={[
          {
            key: "testId",
            label: tl("lab.testId"),
            cellRenderer: ({ id, accessingResourceCentre, resourceCentre, labTestNumber }) => (
              <Typography component="div">
                {useCustomLabTestNumber ? labTestNumber || id : id}
                {accessingResourceCentre && (
                  <div>
                    {accessingResourceCentre.id !== currentRCId ? (
                      <Typography style={{ fontSize: "0.75rem", color: "grey" }}>
                        {accessingResourceCentre.name}
                      </Typography>
                    ) : (
                      <Typography style={{ fontSize: "0.75rem", color: "grey" }}>
                        {resourceCentre.name}
                      </Typography>
                    )}
                  </div>
                )}
              </Typography>
            )
          },
          {
            key: "client",
            label: "Patient Name",
            sortable: true,
            formatter: ({ client }) => <Typography>{getFullName(client)}</Typography>
          },
          {
            key: "tests",
            label: tl("lab.tests"),
            cellRenderer: ({ results, related }) => {
              const allTests = [...results.data].filter(Boolean);
              if (related?.length > 0) {
                related.map((rl) => {
                  rl.results.data.map((resd) => allTests.push(resd));
                  return null;
                });
              }
              const filtered = allTests.filter(
                (test, index, tests) => tests.findIndex((item) => item.id === test.id) === index
              );

              if (!filtered.length) {
                return null;
              }

              const mainLabReading =
                filtered[0].labTests?.length > 0 &&
                filtered[0].labTests[0]?.subTests?.length > 0 &&
                hasOwnProperty(filtered[0].labTests[0], "formData") &&
                filtered[0].labTests[0].formData.reading;

              const readingColor = {
                Positive: "#FF6347",
                Negative: "#009654"
              };
              const testNamesString = filtered.map((lt, i) => {
                if (i < 2) {
                  return `${i ? ", " : ""}${lt.name}`;
                }
                return null;
              });

              return (
                <Box
                  title={testNamesString.join()}
                  sx={{
                    whiteSpace: "pre"
                  }}
                >
                  <Typography>
                    {testNamesString}
                    {filtered.length > 2 ? "..." : ""}
                  </Typography>
                  {mainLabReading && (
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: readingColor[mainLabReading],
                        fontWeight: 600
                      }}
                    >
                      {mainLabReading}
                    </Typography>
                  )}
                </Box>
              );
            }
          },
          {
            key: "createdAt",
            label: tl("lab.orderedOn"),
            // eslint-disable-next-line camelcase
            cellRenderer: ({ created_at }) => (
              <Box display="flex">
                <Typography>{bsFullDate(created_at)}</Typography>
              </Box>
            )
          },
          {
            key: "collectionDate",
            label: tl("lab.collected"),
            cellRenderer: ({ collectionDate }) => (
              <Box display="flex">
                <Typography>{collectionDate && bsFullDate(collectionDate)}</Typography>
              </Box>
            )
          },
          {
            key: "status",
            label: tl("lab.status"),
            cellRenderer: ({ status }) => (
              <Box display="flex" alignItems="center">
                <LabStatusChip status={status?.toLowerCase()} />
              </Box>
            ),
            sortable: false,
            sortFunction: (a, b) => (a > b ? 1 : -1)
          },
          {
            key: "turnAroundTime",
            label: tl("lab.turnAroundTime"),
            cellRenderer: (data) => <Box>{getTurnAroundTime(data)}</Box>
          },
          {
            key: "assessed",
            label: "Assessed Status",
            cellRenderer: ({ isAssessedBySp }) => (
              <Box>
                <Chip
                  size="medium"
                  color={isAssessedBySp ? "info" : "default"}
                  label={
                    <Box
                      style={{
                        whiteSpace: "normal",
                        margin: "auto",
                        textAlign: "center"
                      }}
                    >
                      {isAssessedBySp ? "Assessed" : "Unassessed"}
                    </Box>
                  }
                />
              </Box>
            )
          }
        ]}
        onRowClick={(row) => setSelectedLabTest(row)}
      />
      <Box className="labNavigatePage">
        <PageControl page={page} pageSize={10} onSetPage={setPage} maximumDataCount={totalLabs} />
      </Box>

      {selectedLabTest && (
        <LabDetails
          hideActionButton
          editMode={false}
          setEditMode={() => ({})}
          labObj={selectedLabTest}
          labId={selectedLabTest.id}
          handleViewClose={() => setSelectedLabTest(null)}
          navigateTo={(path) => dispatch(push(path))}
          isOpdTab
          updateLabs={markLabAsAssessed}
        />
      )}
    </Box>
  );
};

export default AssessmentLabs;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationAdd } from "../actions/notification";
import { getActiveIPD } from "../api/ipd";
import { addBeds, Bed, getBeds, getWards, postWards, transferIpdBed, Ward } from "../api/wards";
import { IPD } from "../interfaces/IpdInterface";

interface IState {
  wards: Array<Ward>;
  beds: Array<Bed>;
  activeIPDs: Array<Partial<IPD>>;
}

export interface WardPayload {
  id: number | null;
  name: string;
  shortCOde: string;
  isRoom: boolean;
}

const initialState: IState = {
  wards: [],
  beds: [],
  activeIPDs: []
};

export const fetchWards = createAsyncThunk("wards", async (_, { rejectWithValue, dispatch }) => {
  try {
    return await getWards();
  } catch (e) {
    dispatch(
      notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "error",
        message: "Failed to fetch wards",
        autoTimeout: true
      })
    );
    return rejectWithValue(e);
  }
});

export const fetchBeds = createAsyncThunk("beds", async (_, { rejectWithValue, dispatch }) => {
  try {
    return await getBeds();
  } catch (e) {
    dispatch(
      notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "error",
        message: "Failed to fetch beds",
        autoTimeout: true
      })
    );
    return rejectWithValue(e);
  }
});

export const createWards = createAsyncThunk(
  "wards/create",
  async (data: Partial<WardPayload>, { rejectWithValue, dispatch }) => {
    try {
      return await postWards(data);
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message || "Failed to create ward",
          autoTimeout: true
        })
      );
      return rejectWithValue(e);
    }
  }
);

export const createBeds = createAsyncThunk(
  "beds/create",
  async (data: Partial<Bed>, { rejectWithValue, dispatch }) => {
    try {
      return await addBeds(data);
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to create beds",
          autoTimeout: true
        })
      );
      return rejectWithValue(e);
    }
  }
);

export const fetchActiveIpds = createAsyncThunk(
  "ipd/active",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await getActiveIPD();
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to get active ipds",
          autoTimeout: true
        })
      );
      return rejectWithValue(e);
    }
  }
);

export const transferBed = createAsyncThunk(
  "beds/transfer",
  async (data: { ipdId: number; wardId: number; bedId: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await transferIpdBed(data.ipdId, {
        wardId: data.wardId,
        bedId: data.bedId
      });
      return response;
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to transfer beds",
          autoTimeout: true
        })
      );
      return rejectWithValue(e);
    }
  }
);

const wardSlice = createSlice({
  name: "bedManagement",
  initialState,
  reducers: {
    setActiveBed: (draft, action) => {
      draft.activeIPDs.push(action.payload);
    },
    replaceActiveIpd: (draft, action) => {
      const idx = draft.activeIPDs.findIndex((ipd) => ipd.id === action.payload.id);
      if (idx !== -1) draft.activeIPDs[idx] = action.payload;
      return draft;
    },
    updateWardData: (draft, action) => {
      const idx = draft.wards.findIndex((ward) => ward.id === action.payload.id);
      if (idx !== -1) {
        draft.wards[idx].name = action.payload.name;
      }
      return draft;
    },
    updateBedData: (draft, action) => {
      const idx = draft.beds.findIndex((bed) => bed.id === action.payload.id);
      if (idx !== -1) draft.beds[idx] = action.payload;
      return draft;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWards.fulfilled, (draft, { payload }) => {
      draft.wards = payload;
      return draft;
    });

    builder.addCase(fetchBeds.fulfilled, (draft, { payload }) => {
      draft.beds = payload;
      return draft;
    });

    builder.addCase(createWards.fulfilled, (draft, { payload }) => {
      if (payload.ward) {
        draft.wards.push(payload.ward);
      }
      if (payload.beds?.length) {
        draft.beds = [...draft.beds, ...payload.beds];
      }
    });

    builder.addCase(createBeds.fulfilled, (draft, { payload }) => {
      draft.beds = [...draft.beds, ...payload];
    });

    builder.addCase(fetchActiveIpds.fulfilled, (draft, { payload }) => {
      draft.activeIPDs = payload;
      return draft;
    });

    builder.addCase(transferBed.fulfilled, (draft, { payload }) => {
      const idx = draft.activeIPDs.findIndex((ipd) => ipd.id === payload.id);
      if (idx !== -1) draft.activeIPDs[idx] = payload;
      return draft;
    });
  }
});

export default wardSlice.reducer;
export const { setActiveBed, replaceActiveIpd, updateWardData, updateBedData } = wardSlice.actions;

import {
  Box,
  Checkbox,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Autocomplete,
  TextField
} from "@mui/material";
import React from "react";
import produce from "immer";
import { round } from "mathjs";
import { useDispatch, useSelector } from "react-redux";
import {
  VendorCommission as ReferrerCommissionProps,
  VendorCommissionBill
} from "../../../../interfaces/Commission";
import { rupeeDisplay } from "../../../../helpers/rupee";
import { Referrer } from "../../../../interfaces/ReferrerInterface";
import ReferrerCommissionRow from "./ReferrerCommissionRow";
import { calculateTotalPayableOfBill, findReferralPayableAmt, formatData } from "./helper";
import { RootState } from "../../../../store";
import {
  getBillForCommission,
  saveReferrerCommission,
  updateBillOnSelect,
  updateReferrerCommission
} from "../../../../slices/commission";
import { EmptyView } from "../../../../components/OList";
import { BillDocumentType } from "../../../../interfaces/BillInterfaces";
import useReferrers from "../../../../hooks/userReferrers";

const showSubItem = (isCommissionPaid, isSaved) => {
  if (isSaved || isCommissionPaid) return false;
  return true;
};

export default function ReferrerCommission({
  selectedBill
}: {
  selectedBill?: BillDocumentType;
}): JSX.Element {
  const dispatch = useDispatch();
  const [selectedReferrer, setSelectedReferrer] = React.useState<Referrer | null>(null);
  const referrerCommissionItems = useSelector((state: RootState) => state.commissions.referrers);
  const [checkAllStatus, setCheckAllStatus] = React.useState<boolean>(false);
  const referrers = useReferrers();

  React.useEffect(() => {
    if (selectedBill?.referrerId) {
      setSelectedReferrer(
        referrers?.find((referrer) => referrer.id === selectedBill.referrerId) || null
      );
      dispatch(getBillForCommission(selectedBill.referrerId));
    }
  }, [dispatch, referrers, selectedBill?.referrerId]);

  const billSelectHandler = ({ target }, sNo?: number) => {
    if (!sNo) setCheckAllStatus(target.checked);
    dispatch(updateBillOnSelect({ checked: target.checked, sNo }));
  };

  return (
    <div>
      <Box width="500px" mb={2}>
        <Autocomplete
          value={selectedReferrer}
          options={referrers}
          getOptionLabel={(option) => option.referrer}
          onChange={(_, val) => {
            setSelectedReferrer(val);
            if (selectedReferrer?.id !== val?.id && val?.id) {
              setCheckAllStatus(false);
              dispatch(getBillForCommission(val.id));
            }
          }}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              {option.referrer}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label="Referrers"
              placeholder="Search and select referrers"
              margin="dense"
              fullWidth
            />
          )}
        />
      </Box>
      {referrerCommissionItems.length > 0 ? (
        <>
          <Box
            sx={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
            my="8px"
            p="10px"
            border="2px solid lightgreen"
            borderRadius="8px"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checkAllStatus}
                onChange={(e) => billSelectHandler(e)}
                size="small"
                color="primary"
              />
              <Typography>Select all Bills</Typography>
            </div>
            {referrerCommissionItems.map((commissionItem: VendorCommissionBill, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`${commissionItem.billId}-${index}`} display="flex" width="100%">
                <div style={{ marginTop: "20px" }}>
                  <Checkbox
                    onChange={(e) => billSelectHandler(e, commissionItem.sNo)}
                    size="small"
                    color="primary"
                    checked={commissionItem.isSelected}
                    disabled={commissionItem.isSaved || commissionItem.isReferrerPaid}
                  />
                </div>
                <Box
                  bgcolor={`${
                    commissionItem.isSaved || commissionItem.isReferrerPaid ? "#e0e0e0" : ""
                  }`}
                  p="10px"
                  my="8px"
                  border="2px solid lightgreen"
                  borderRadius="8px"
                  width="100%"
                >
                  <>
                    <Box
                      borderBottom={`${
                        showSubItem(commissionItem.isReferrerPaid, commissionItem.isSaved)
                          ? "0.5px solid #c1bdbd"
                          : ""
                      }`}
                      justifyContent="space-between"
                      display="flex"
                      mt="8px"
                      pb="20px"
                    >
                      <Box display="flex" alignItems="centre">
                        <Typography fontWeight={600}>
                          {`Bill Number: ${commissionItem.billNumber}`}
                        </Typography>
                        <Divider sx={{ height: 20, mx: "15px" }} orientation="vertical" />
                        <Typography fontWeight={600}>
                          {`Paid Amount: ${commissionItem.paidAmount}`}
                        </Typography>
                        <Divider sx={{ height: 20, mx: "15px" }} orientation="vertical" />
                        {(commissionItem.isSaved || commissionItem.isReferrerPaid) && (
                          <Typography fontWeight={600}>
                            <FormControlLabel
                              sx={{ margin: "0" }}
                              control={<Checkbox size="small" sx={{ padding: "0" }} checked />}
                              label="Saved:"
                              labelPlacement="start"
                            />
                          </Typography>
                        )}
                      </Box>
                      <Typography fontWeight={600}>{`Total Payable: ${rupeeDisplay(
                        round(commissionItem.totalNetPayableToVendor, 2)
                      )}`}</Typography>
                    </Box>
                    {showSubItem(commissionItem.isReferrerPaid, commissionItem.isSaved) &&
                      commissionItem.billItems?.map((subItem, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={i} mt={`${i !== 0 ? "15px" : "8px"}`}>
                          <ReferrerCommissionRow
                            isSaved={commissionItem.isSaved || commissionItem.isReferrerPaid}
                            index={i}
                            onChange={(updatedItem: ReferrerCommissionProps, rateAmt?: string) => {
                              const calculatedState = findReferralPayableAmt(updatedItem, rateAmt);
                              const itemIndex = commissionItem.billItems.findIndex(
                                (item) => item.internalSNo === updatedItem.internalSNo
                              );
                              const updatedBill = produce(commissionItem, (draft) => {
                                draft.billItems[itemIndex] = calculatedState;
                              });
                              dispatch(
                                updateReferrerCommission(calculateTotalPayableOfBill(updatedBill))
                              );
                            }}
                            item={subItem}
                          />
                          <Box display="flex" flexDirection="row-reverse" mr="10px">
                            <Typography fontWeight={500}>
                              {`Amount Payable to Referrer: ${rupeeDisplay(
                                round(subItem.netPayableToVendor, 2)
                              )}`}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                  </>
                </Box>
              </Box>
            ))}
          </Box>
          <Box flexDirection="row-reverse" display="flex">
            <Button
              variant="contained"
              onClick={() => {
                const formattedData = formatData(referrerCommissionItems);
                dispatch(saveReferrerCommission(formattedData));
              }}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No items to show.
          </Box>
        </EmptyView>
      )}
    </div>
  );
}

ReferrerCommission.defaultProps = {
  selectedBill: {} as BillDocumentType
};

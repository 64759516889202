import * as React from "react";
import { Box, Button } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import produce from "immer";
import HOPI from "./HOPI";
import PastHistoryOfAllergy from "./PastHistoryOfAllergy";
import PresentHealthStatus from "./PresentHealthStatus";
import { patchMedicalTest } from "../../../actions/medicalTest";
import { LabStatuses } from "../../../interfaces/Lab";
import { RootState } from "../../../store";
import { notificationAdd } from "../../../actions/notification";

function CreateAssessment({ id, updateTest, medicalTest }) {
  const [assessment, setAssessment] = React.useState({
    hopi: "None",
    pastHistoryOfAllergy: "None",
    presentHealthStatus: "Fit"
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    const relatedAssessment = medicalTest?.results?.assessment;
    const defaultAssessment = {
      hopi: "None",
      pastHistoryOfAllergy: "None",
      presentHealthStatus: "Fit"
    };
    if (relatedAssessment) {
      if (relatedAssessment.hopi) {
        defaultAssessment.hopi = relatedAssessment.hopi;
      }
      if (relatedAssessment.pastHistoryOfAllergy) {
        defaultAssessment.pastHistoryOfAllergy = relatedAssessment.pastHistoryOfAllergy;
      }
      if (relatedAssessment.presentHealthStatus) {
        defaultAssessment.presentHealthStatus = relatedAssessment.presentHealthStatus;
      }
    }
    setAssessment(defaultAssessment);
  }, [medicalTest]);

  const rc = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre
  );

  const authorizedSP = rc?.settings?.medicalSettings;
  const [saving, setSaving] = React.useState(false);
  return (
    <Box width="110%" marginLeft="-16px" marginTop="16px">
      <Box>
        <HOPI
          assessmentHOPI={assessment.hopi}
          onChange={(value) => {
            setAssessment({ ...assessment, hopi: value });
          }}
        />
      </Box>
      <Box mt="8px">
        <PastHistoryOfAllergy
          assessmentPastHistoryOfAllergy={assessment.pastHistoryOfAllergy}
          onChange={(value) => {
            setAssessment({ ...assessment, pastHistoryOfAllergy: value });
          }}
        />
      </Box>
      <Box mt="8px">
        <PresentHealthStatus
          assessmentPresentHealthStatus={assessment.presentHealthStatus}
          onChange={(value) => {
            setAssessment({ ...assessment, presentHealthStatus: value });
          }}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" p="20px">
        <Button
          data-testmation="creaseAssessment"
          variant="contained"
          color="primary"
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            try {
              const copy: any = produce(medicalTest, (draft) => {
                draft.results.assessment = assessment;
              });
              const toPost = {
                attachments: [],
                authorizedSP1Id: authorizedSP.authorizedSP1,
                authorizedSP2Id: authorizedSP.authorizedSP2,
                authorizedSP3Id: authorizedSP.authorizedSP3,
                authorizedSP4Id: authorizedSP.authorizedSP4,
                final: medicalTest.status !== LabStatuses.INCOMPLETE_RESULT,
                updatedTestResults: copy.results
              };
              await updateTest(toPost, id);
            } catch (err) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: err?.data?.message || "Failed to update medical test",
                  autoTimeout: false
                })
              );
            }
            setSaving(false);
          }}
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  const medicalTest = state.medicalTest.collection.find((item) => item.id === Number(ownProps.id));

  return {
    medicalTest
  };
}
const mapDispatchToProps = (dispatch) => ({
  updateTest: (data, id) => dispatch(patchMedicalTest(data, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssessment);

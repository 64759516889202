import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

function copy(content: string): void {
  if (!navigator.clipboard) {
    console.error("Couldnt access copy api");
    return;
  }
  navigator.clipboard.writeText(content);
}

interface ChildProps {
  copy: (content: any) => void;
}

interface Props {
  TooltipProps?: Partial<TooltipProps>;
  children: (props: ChildProps) => React.ReactElement<any>;
}

interface State {
  showTooltip: boolean;
}

function CopyToClipboard(props: Props) {
  const [state, setState] = React.useState<State>({ showTooltip: false });

  function onCopy(content) {
    copy(content);
    setState({ showTooltip: true });
  }

  function handleOnTooltipClose() {
    setState({ showTooltip: false });
  }

  return (
    <Tooltip
      data-testmation="copyToClipboard"
      open={state.showTooltip}
      title="Copied to clipboard!"
      leaveDelay={1500}
      onClose={handleOnTooltipClose}
      {...(props.TooltipProps || {})}
    >
      {props.children({ copy: onCopy }) as React.ReactElement<any>}
    </Tooltip>
  );
}

export default CopyToClipboard;

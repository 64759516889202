import { Autocomplete, Button, Box, TextField } from "@mui/material";
import { isEmpty, pick } from "lodash";
import { useDispatch } from "react-redux";
import React from "react";
import { getStockProducts } from "../../../api/stockProducts";
import DebouncedTextField from "../../../components/DebouncedTextField";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { notificationAdd } from "../../../actions/notification";
import { Stock } from "../../../interfaces/StockInterfaces";

export interface ReagentProps {
  batchId: string;
  productName: string;
  productId: number | null;
  quantity: number;
  sNo: number;
  availableQty?: number;
}

interface Props {
  onSave: (val: ReagentProps) => void;
  stockReagent: ReagentProps[];
}

interface StockProductInterface extends ProductInterface {
  stocks: Stock[];
  id: number;
}

interface StateProps {
  product: Partial<StockProductInterface>;
  stock: Partial<Stock>;
  quantity: number;
  availableQty: number;
}

const isSelected = (reagentData, productId, batchId) =>
  reagentData?.some((item) => item.productId === productId && item.batchId === batchId);

export default function StockReagent({ onSave, stockReagent }: Props): JSX.Element {
  const [productList, setProductList] = React.useState<StockProductInterface[]>([]);
  const [state, setState] = React.useState<StateProps>({} as StateProps);
  const dispatch = useDispatch();
  const textChangeHandler = async ({ target }) => {
    try {
      const query = (target.value || "").trim();
      if (query.length > 2) {
        const response = (await getStockProducts({
          search: query,
          productType: 2,
          limit: 20,
          intangible: false
        })) as StockProductInterface[];
        setProductList(response as StockProductInterface[]);
      }
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || "Sorry !, something went wrong while getting products.",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Box display="flex" gap={2} justifyItems="center">
      <Autocomplete
        fullWidth
        options={productList}
        clearOnBlur
        value={state.product || null}
        onChange={(_, val) => {
          if (val) {
            setState({ ...state, product: pick(val, ["name", "id", "stocks"]) });
            if (!val?.stocks?.length) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Please, Make some purchase of this product.",
                  autoTimeout: true
                })
              );
            }
          }
        }}
        renderInput={(params) => (
          <DebouncedTextField
            debounceAt={1000}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder="Select Stock Products"
            variant="outlined"
            margin="dense"
            onFocus={(e) => e.target.select()}
            onChange={textChangeHandler}
          />
        )}
        getOptionLabel={(option) => option.name || ""}
      />
      <Autocomplete
        sx={{ width: "350px" }}
        options={state.product?.stocks || []}
        clearOnBlur
        value={state.stock || null}
        onChange={(_, val) => {
          if (isSelected(stockReagent, state.product?.id, val?.batchId)) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "You have already selected the same product with same batch.",
                autoTimeout: true
              })
            );
            return;
          }
          setState({
            ...state,
            stock: pick(val, ["batchId"]),
            availableQty: Number(val?.quantity || 0)
          });
        }}
        disabled={isEmpty(state.product)}
        renderInput={(params) => (
          <DebouncedTextField
            debounceAt={1000}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder="Select Batch Id"
            variant="outlined"
            margin="dense"
            onFocus={(e) => e.target.select()}
          />
        )}
        getOptionLabel={(option) => option.batchId || ""}
      />
      <TextField
        disabled={isEmpty(state.stock)}
        sx={{ marginTop: "7px", width: "250px" }}
        value={state.quantity}
        label="Quantity"
        onFocus={(e) => e.target.select()}
        variant="outlined"
        onChange={({ target }) => {
          setState({ ...state, quantity: Number(target.value) });
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      <Button
        size="small"
        variant="contained"
        sx={{ height: "30px", marginTop: "10px" }}
        disabled={!state.quantity}
        onClick={() => {
          onSave({
            productName: state.product?.name || "",
            productId: state.product?.id || null,
            batchId: state.stock?.batchId || "",
            quantity: state.quantity,
            sNo: (stockReagent?.length || 0) + 1,
            availableQty: state.availableQty
          });
          setState({ quantity: 0 } as StateProps);
        }}
      >
        Add
      </Button>
    </Box>
  );
}

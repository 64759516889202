import React from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON } from "react-leaflet";
import { LegendItem, LegendLabel, LegendThreshold } from "@visx/legend";
import { scaleThreshold } from "@visx/scale";
import { GeoJSON as LeafletGeoJSON } from "leaflet";
import capitalize from "lodash/capitalize";
import { Box, Typography } from "@mui/material";
import mapData from "../../../../../assets/maps/geoJsonNepal";
import { LabSummary } from "../../../../interfaces/Lab";
import { AddressOptions } from "../../../../hooks/query/useAddressOption";
import { getDistrictIdByName, getDistrictOfProvinceOne } from "./helper";

type Province =
  | "Province 1"
  | "Province 2"
  | "Bagmati"
  | "Gandaki"
  | "Karnali"
  | "Province 5"
  | "Sudurpaschim";

type FeatureType = "District" | "Province";

const getColor = (d) => {
  if (d > 400) return "red";
  if (d > 200) return "orange";
  if (d > 2) return "cyan";
  return "green";
};

interface GeoDataInterface {
  type: string;
  properties: {
    Name: string;
    Province: string;
    LabTestReady?: string;
    LabTestInProgress?: string;
  };
  geometry: {
    type: string;
    coordinates: number[][][];
  };
}

const thresholdScale = scaleThreshold({
  domain: [2, 200, 400],
  range: ["green", "cyan", "orange", "red"]
});

const legendGlyphSize = 15;

const getDistrictGeoJSON = (districts: string[], allDistricts: string[]): GeoDataInterface[] => {
  if (districts.includes("ALL"))
    return mapData.features.filter((feature) =>
      allDistricts.includes(feature.properties.Name)
    ) as GeoDataInterface[];
  return mapData.features.filter((feature) =>
    districts.includes(feature.properties.Name)
  ) as GeoDataInterface[];
};

const getBounds = (name: Province | string, type: FeatureType) => {
  const propertyName = type === "District" ? "Name" : "Province";
  const featureCollection = {
    ...mapData,
    features: mapData.features.filter((feature) => feature.properties[propertyName] === name)
  };
  const lFeatureCollection = new LeafletGeoJSON(featureCollection);
  return lFeatureCollection.getBounds();
};

const NepalMap = ({
  labSummary,
  addressMasterData,
  districts
}: {
  labSummary: LabSummary[];
  addressMasterData: AddressOptions[];
  districts;
}): JSX.Element => {
  const province = "Province 1";

  const getTotalLabTests = (feature) => {
    const districtId = getDistrictIdByName(capitalize(feature.properties.Name), addressMasterData);
    return labSummary
      ?.filter(
        (summaryReport) => summaryReport.provinceId === 1 && summaryReport.districtId === districtId
      )
      .reduce((prev, curr) => prev + Number(curr.testCount), 0);
  };

  const onEachDistrict = (selectedDistrict, layer) => {
    if (selectedDistrict.properties.Name) {
      const districtName: string = selectedDistrict.properties.Name;
      const total = getTotalLabTests(selectedDistrict);
      layer.bindPopup(`${districtName}<br/>${total}`);
      layer.on("mouseover", () => layer.openPopup());
      layer.on("mouseout", () => layer.closePopup());
    }
  };

  const geoData = React.useMemo(
    () =>
      getDistrictGeoJSON(
        districts.map((item) => item.toUpperCase()),
        getDistrictOfProvinceOne(addressMasterData).map((item) => item.toUpperCase())
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [districts, addressMasterData, labSummary]
  );

  return (
    <div
      style={{
        paddingTop: "5px"
      }}
    >
      <MapContainer
        bounds={getBounds(province, "Province")}
        style={{ height: "60vh" }}
        scrollWheelZoom={false}
        zoom={16}
        maxZoom={18}
        minZoom={5}
        download
      >
        <GeoJSON data={mapData} style={{ color: "lightgray" }} />
        {geoData.length > 0 && labSummary.length > 0 && (
          <GeoJSON
            key={`key-${geoData.length}`}
            data={geoData}
            style={(feature) => ({
              fillColor: getColor(getTotalLabTests(feature)),
              color: "white",
              weight: 2,
              opacity: 1,
              dashArray: "3",
              fillOpacity: 0.7
            })}
            onEachFeature={onEachDistrict}
          />
        )}
        <Box
          style={{
            width: "200px",
            position: "absolute",
            bottom: 0
          }}
        >
          <Typography>Total Lab Tests</Typography>
          <LegendThreshold scale={thresholdScale}>
            {(labels) =>
              labels.reverse().map((label) => (
                <LegendItem key={`legend-quantile-${label}`} margin="1px 0px">
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel
                    align="left"
                    margin="2px 0 0 10px"
                    style={{
                      fontFamily: "'Noto Sans Display', 'Poppins', sans-serif",
                      fontSize: "12px",
                      fontWeight: "10px",
                      color: "black"
                    }}
                  >
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))
            }
          </LegendThreshold>
        </Box>
      </MapContainer>
    </div>
  );
};

export default NepalMap;

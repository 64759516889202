import Info from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import EditableTable from "../../../components/EditableTable/EditableTable";
import { TestTypes } from "../../../interfaces/Lab";
import { updateEntryDraft, updateGroupRoot, updateName } from "../../../slices/labRecordSlice";
import { RootState } from "../../../store";
import {
  advancedLabRangeProcessor,
  showInterpretationTemplate
} from "../LabPrint/LabPrintFunctions";
import AdditionalDataEdit from "../Settings/LabMasterDataEdit/AdditionalDataEdit";
import AdvancedRangesEdit, {
  handleRangesSanitization
} from "../Settings/LabMasterDataEdit/AdvancedRangesEdit";
import "./entry.scss";
import { infoIconColor } from "../LabTestCreate/AddLabtests";
import ContentEditableWithTextField from "../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import { removeCommasFromNumber } from "../../../helpers/number";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffb02a"
      },
      "&:hover fieldset": {
        borderColor: "#ffb02a"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffb02a"
      }
    }
  }
});

function isReadingWithinRange(range, reading, clientGender, dob) {
  const getRanges = () => {
    const rangeLocal = advancedLabRangeProcessor(range, Number(clientGender), dob);
    if (typeof rangeLocal === "string" && range !== "") {
      // Return without parsing min and max so that if any of the value is 0,
      // it would be easier for conditional check below as
      // 0 would be falsy while "0" would be truthy
      return rangeLocal.split("-");
    }
    return [];
  };

  const [minRange, maxRange] = getRanges();

  return reading &&
    minRange &&
    maxRange &&
    !Number.isNaN(parseFloat(removeCommasFromNumber(reading)))
    ? parseFloat(removeCommasFromNumber(reading)) >= parseFloat(removeCommasFromNumber(minRange)) &&
        parseFloat(removeCommasFromNumber(reading)) <= parseFloat(removeCommasFromNumber(maxRange))
    : true;
}

function SelectInputComponent({ rowLevel, onChange }) {
  return (
    <Select
      fullWidth
      margin="dense"
      variant="outlined"
      value={rowLevel.formData?.reading}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      data-testmation="selectFlagEntry"
    >
      {rowLevel.info.options?.map((option, idx) => (
        <MenuItem value={option} data-testmation={`${option}${idx}`} key={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

function AutocompleteInputComponent({ rowLevel, onChange }) {
  return (
    <Autocomplete
      freeSolo
      value={rowLevel.formData.reading}
      onChange={(_, v) => {
        onChange(v);
      }}
      options={rowLevel.info.options || []}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Select or Type"
          margin="dense"
          variant="outlined"
        />
      )}
    />
  );
}

const ReadingInputTypes = {
  select: SelectInputComponent,
  autocomplete: AutocompleteInputComponent
};

const getPanicState = (reading, panicRange, labTest) => {
  if (reading === "") {
    return {
      state: "",
      showDialog: false,
      labTest: ""
    };
  }
  if (
    (panicRange.min && Number(reading) < Number(panicRange.min)) ||
    (panicRange.max && Number(reading) > Number(panicRange.max))
  ) {
    return {
      state: Number(reading) < Number(panicRange.min) ? "low" : "high",
      showDialog: true,
      labTest
    };
  }
  return {
    state: "",
    showDialog: false,
    labTest: ""
  };
};

function GenerateLevelRow({ rowLevel, groupIndex, testGroupId, isSubTest, setPanicData }) {
  const relatedClient = useSelector((state: RootState) => state.labRecords.entryDraft.client);
  const classes = useStyles();

  const isInRange = isReadingWithinRange(
    rowLevel.ranges,
    rowLevel.formData?.reading,
    relatedClient.gender,
    relatedClient.dob
  );

  const dispatch = useDispatch();

  const SpecialReadingComponent = ReadingInputTypes[rowLevel.info?.inputType];

  return (
    <div className="entry_row_container">
      <div className="entry_row_wrapper">
        <div className="entry_row_cell">
          <ContentEditableWithTextField
            label=""
            hidelabel
            placeholder="Lab"
            saveOnFocusOut
            value={rowLevel.name}
            onSave={(val) =>
              dispatch(
                updateEntryDraft({
                  groupIndex,
                  uuid: rowLevel.uuid,
                  key: "name",
                  value: val
                })
              )
            }
            width="250px"
            variant="outlined"
          />
        </div>
        <div className="entry_row_cell">
          {SpecialReadingComponent ? (
            <SpecialReadingComponent
              rowLevel={rowLevel}
              onChange={(v) => {
                dispatch(
                  updateEntryDraft({
                    groupIndex,
                    uuid: rowLevel.uuid,
                    key: "formData",
                    value: { ...(rowLevel.formData && rowLevel.formData), reading: v }
                  })
                );
              }}
            />
          ) : (
            <TextField
              value={rowLevel.formData?.reading || ""}
              className={classnames({ [classes.root]: !isInRange })}
              variant="outlined"
              onFocus={(e) => e.target.select()}
              onBlur={(e) => {
                if (rowLevel.ranges?.panicValues) {
                  const panicState = getPanicState(
                    e.target.value,
                    rowLevel.ranges.panicValues,
                    rowLevel.name
                  );
                  setPanicData(panicState);
                }
              }}
              onChange={(e) => {
                dispatch(
                  updateEntryDraft({
                    groupIndex,
                    uuid: rowLevel.uuid,
                    key: "formData",
                    value: { ...(rowLevel.formData && rowLevel.formData), reading: e.target.value }
                  })
                );
              }}
              data-testmation="enterValue"
            />
          )}
        </div>
        <div className="entry_row_cell">
          <Typography>{rowLevel.unit}</Typography>
        </div>
        <div className="entry_row_cell">
          <Typography data-testmation="editRange">
            <AdvancedRangesEdit
              ranges={handleRangesSanitization(rowLevel.ranges || {})}
              onUpdate={(v) => {
                dispatch(
                  updateEntryDraft({
                    groupIndex,
                    uuid: rowLevel.uuid,
                    key: "ranges",
                    value: v
                  })
                );
              }}
              labTest={rowLevel}
              testGroupId={testGroupId}
              showUpdateMasterDataConfirmation
              buttonText="Edit Range"
              isSubTest={isSubTest}
            />
          </Typography>
        </div>
        <div className="entry_row_cell">
          <Typography>{rowLevel.methods}</Typography>
        </div>
      </div>
      {rowLevel.subTests && (
        <Box pl="16px">
          {rowLevel.subTests.map((el) => (
            <GenerateLevelRow
              isSubTest
              testGroupId={testGroupId}
              rowLevel={el}
              key={el.uuid}
              groupIndex={groupIndex}
              setPanicData={setPanicData}
            />
          ))}
        </Box>
      )}
    </div>
  );
}

function EntryCore(): JSX.Element | null {
  const coreData = useSelector((state: RootState) => state.labRecords.entryDraft?.results?.data);
  const dispatch = useDispatch();
  const [panicData, setPanicData] = React.useState({
    showDialog: false,
    state: "",
    labTest: ""
  });
  if (!coreData) return null;

  return (
    <Box className="entry_edit_container">
      <OkhatiDialog
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon color="warning" />
            <Typography ml={1} fontSize="1.2rem" fontWeight="600">
              Warning
            </Typography>
          </Box>
        }
        open={panicData.showDialog}
        next={() =>
          setPanicData({
            showDialog: false,
            state: "",
            labTest: ""
          })
        }
        nextButtonText="Ok"
        description={`The value for ${panicData.labTest} is too ${panicData.state}. Please contact the client as soon as possible.`}
        readMode
      />
      {coreData.map((testGroup, groupIndex) => (
        <>
          {testGroup.type === TestTypes.RADIOLOGY ? (
            <Box px={4} my={4} display="flex" justifyContent="flex-start" alignItems="center">
              <Typography variant="h6" fontWeight={600} sx={{ width: "18%" }}>
                Test name:
              </Typography>
              <Box minWidth="120px" mt={1}>
                <ContentEditableWithTextField
                  label=""
                  hidelabel
                  placeholder="Lab"
                  saveOnFocusOut
                  value={testGroup.name}
                  onSave={(val) => dispatch(updateName({ groupIndex, value: val }))}
                  width="250px"
                  variant="outlined"
                  style={{
                    paddingTop: "5px"
                  }}
                />
              </Box>
              <Tooltip
                title={`Sample Type: ${testGroup.sampleInfo?.sampleType || "N/A"}, Container: ${
                  testGroup.sampleInfo?.sampleContainer || "N/A"
                }`}
                sx={{
                  cursor: "pointer",
                  marginLeft: "10px"
                }}
              >
                <Info sx={{ color: infoIconColor }} />
              </Tooltip>
            </Box>
          ) : (
            <>
              <Box
                className="entry_tests_wrapper"
                borderBottom="1px solid lightgrey"
                key={testGroup.id}
                px={4}
                pt={groupIndex === 0 ? 0 : 4}
                pb={2}
              >
                <Box display="flex">
                  <Box
                    my={4}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography variant="h6" fontWeight={600} sx={{ width: "18%" }}>
                      Test name:
                    </Typography>
                    <Box minWidth="120px" mt={1}>
                      <ContentEditableWithTextField
                        label=""
                        hidelabel
                        placeholder="Lab"
                        saveOnFocusOut
                        value={testGroup.name}
                        onSave={(val) => dispatch(updateName({ groupIndex, value: val }))}
                        width="250px"
                        variant="outlined"
                        style={{
                          paddingTop: "5px"
                        }}
                      />
                    </Box>
                    <Tooltip
                      title={`Sample Type: ${
                        testGroup.sampleInfo?.sampleType || "N/A"
                      }, Container: ${testGroup.sampleInfo?.sampleContainer || "N/A"}`}
                      sx={{
                        cursor: "pointer"
                      }}
                      style={{
                        marginLeft: "10px"
                      }}
                    >
                      <Info sx={{ color: infoIconColor }} />
                    </Tooltip>
                  </Box>
                </Box>
                {(testGroup.labTests || []).map((labTest) => (
                  <GenerateLevelRow
                    testGroupId={testGroup.id}
                    rowLevel={labTest}
                    key={labTest.uuid}
                    groupIndex={groupIndex}
                    setPanicData={setPanicData}
                  />
                ))}
                {testGroup.additionalTestData && (
                  <>
                    <Box display="flex" alignItems="center">
                      <Typography fontWeight={600} sx={{ textDecoration: "underline" }}>
                        Additional Test Data:
                      </Typography>
                      <Box ml="16px" color="green" sx={{ cursor: "pointer" }}>
                        <AdditionalDataEdit
                          label="Edit"
                          additionaldata={testGroup.additionalTestData}
                          onSave={(v) => {
                            dispatch(
                              updateGroupRoot({ groupIndex, key: "additionalTestData", value: v })
                            );
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography component="div">
                      <div
                        style={{ whiteSpace: "pre-wrap" }}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: testGroup.additionalTestData }}
                      />
                    </Typography>
                  </>
                )}

                {testGroup.interpretationTemplate &&
                  showInterpretationTemplate(testGroup.interpretationTemplate) && (
                    <Box mt="16px">
                      <Box display="flex" alignItems="center">
                        <Typography fontWeight={600} sx={{ textDecoration: "underline" }}>
                          Interpretation Template:
                        </Typography>
                        <FormControlLabel
                          sx={{ marginLeft: "16px" }}
                          control={
                            <Checkbox
                              onChange={(e) => {
                                dispatch(
                                  updateGroupRoot({
                                    groupIndex,
                                    key: "interpretationTemplate",
                                    value: {
                                      ...testGroup.interpretationTemplate,
                                      show: e.target.checked
                                    }
                                  })
                                );
                              }}
                              name="checkedA"
                              checked={!!testGroup.interpretationTemplate.show}
                            />
                          }
                          label="Show Template"
                        />
                      </Box>
                      <EditableTable
                        maxColCount={10}
                        onChange={(v) => {
                          dispatch(
                            updateGroupRoot({ groupIndex, key: "interpretationTemplate", value: v })
                          );
                        }}
                        initValue={testGroup.interpretationTemplate}
                      />
                    </Box>
                  )}
              </Box>
            </>
          )}
        </>
      ))}
    </Box>
  );
}

export default React.memo(EntryCore);

import * as api from '../api/vitals';
import { actionCreatorAsync } from './actionHelpers';

export enum Type {
  GET_VITALS_BY_ID = 'GET_VITALS_BY_ID',
  POST_VITALS = 'POST_VITALS',
}

export const getVitalsById = (clientId) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.GET_VITALS_BY_ID, async () => {
        return await api.getVitals(clientId);
      }),
    );
  };
};

export const postVitals = (data, clientId) => {
  return async (dispatch) => {
    await dispatch(
      actionCreatorAsync(Type.POST_VITALS, async () => {
        return await api.postVitals({ vitals: data, clientId });
      }),
    );
  };
};

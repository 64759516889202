/* eslint-disable no-param-reassign */
import { Box } from "@mui/material";
import { produce } from "immer";
import { round } from "mathjs";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { match as MatchProps } from "react-router";
import { supplierActions } from "../../../actions";
import * as stockActions from "../../../actions/stock";
import * as stockProductActions from "../../../actions/stockProductActions";
import { getStockProductById } from "../../../actions/stockProductActions";
import {
  Currency,
  DiscountSettings,
  Entry,
  ErrorState,
  Mode,
  paymentOptionsEnum,
  Status,
  Stock,
  StockItemInterface,
  StockProducts,
  StockTransactions,
  Supplier,
  TransactionType
} from "../../../interfaces/StockInterfaces";
import {
  defaultDiscountSettings,
  getTemplateStockItem,
  initialEntryState,
  updatesNo
} from "../../../reducers/stock";
import { suppliersSortedSelector } from "../../../reducers/suppliers";
import StockEntryActions from "./StockEntryActions";
import StockEntryItems from "./StockEntryItems";
import StockEntrySummary from "./StockEntrySummary";
import { getReturnedRelatedTransactions, getStockProductByIds } from "../../../api/stock";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { tl } from "../../../components/translate";
import { initialQuery, QueryProps } from "../../../interfaces/ProductInterface";
import { getCurrentSubscription } from "../../../slices/subscriptionSlice";
import { IThunkDispatch, RootState, store } from "../../../store";
import Can from "../../Policy/Can";
import { getPackageName } from "./EntryItemsView";
import { updateEntry } from "../../../actions/stock";

interface PurchaseEntryProps {
  entry: Entry;
  actions: {
    updateEntry: (entry: Entry) => void;
    applyEntry: (entry: Entry, discountSettings: DiscountSettings) => void;
    loadSuppliers: () => void;
    loadStocksByProductIds: (productId: number[]) => void;
    loadStockTransactions: (query: QueryProps) => void;
    loadStockProducts: (initialQuery: QueryProps) => void;
    onItemAdd: () => void;
    onRowRemove: (idx: number) => void;
    updateSetting: (setting) => void;
    getProductById: (id: number) => void;
  };
  match: MatchProps<{ [x: string]: string }>;
  location: { pathname: string };
  stockProducts: Array<StockProducts>;
  suppliers: Array<Supplier>;
  stockTransactions: Array<StockTransactions>;
  stockAdminSettings: DiscountSettings;
  mode?: Mode;
  kitchenPharmacyBillId?: number | null;
}

const getErrorState = (entry, stocks) => {
  const ret: ErrorState = { entryErrors: [] };
  const { stockItems } = entry;

  const checkGreaterThanMaxQty = (stkItems, trxItem) => {
    const maxCurrentStock =
      stkItems.find(
        (stock) => stock.batchId === trxItem.batchId && stock.productId === trxItem.productId
      )?.quantity || 0;
    const isError =
      [
        TransactionType.PURCHASE_RETURN,
        TransactionType.EXPIRY_OR_DISCARDMENT,
        TransactionType.INTERNAL_USE,
        TransactionType.OPENING_STOCK_ADJUSTMENT
      ].includes(entry.transactionType) &&
      (trxItem.enteredQuantityUnit === "package"
        ? (trxItem.quantity + (trxItem?.hasFreeItems ? trxItem.freeItemQuantity : 0)) *
          trxItem.unitsPerPackage
        : trxItem.quantity + (trxItem?.hasFreeItems ? trxItem.freeItemQuantity : 0)) >
        maxCurrentStock;
    return isError;
  };

  stockItems?.forEach((outerEl, i) => {
    const hasGreaterThanMaxQty = checkGreaterThanMaxQty(stocks, outerEl);
    if (hasGreaterThanMaxQty) {
      ret.entryErrors.push({ rowIdx: i, errorColumn: "quantity" });
    }

    stockItems.forEach((innerEl, j) => {
      if (!(outerEl.sNo === innerEl.sNo)) {
        if (outerEl.productId === innerEl.productId && outerEl.batchId === innerEl.batchId) {
          const errors = [];
          if (
            !ret.entryErrors.find((item) => i === item.rowIdx && item.errorColumn === "batchId")
          ) {
            errors.push({ rowIdx: i, errorColumn: "batchId" });
          }
          if (
            !ret.entryErrors.find((item) => j === item.rowIdx && item.errorColumn === "batchId")
          ) {
            errors.push({ rowIdx: j, errorColumn: "batchId" });
          }
          ret.entryErrors.push(...errors);
        }
      }
    });
  });
  return ret;
};

const getReturnedQuantity = (
  transactions: StockTransactions[],
  transactionId: number
): { [key: string]: number } => {
  const returnedTransactions = transactions.filter((item) => item.referenceTo === transactionId);
  const returnedQuantity = {};
  returnedTransactions.forEach((stockTransaction: StockTransactions) => {
    stockTransaction.stockTransactionItems.forEach((item) => {
      const index = `${item.batchId}-${item.productId}`;
      if (!returnedQuantity[index]) {
        returnedQuantity[index] = 0;
      }
      returnedQuantity[index] += Number(item.quantity || 0);
    }, {});
  });
  return returnedQuantity;
};

const getTransactionType = (path: string[], mode: string | undefined): string => {
  if (mode === Mode.Edit) return path[path.length - 1];
  return path[path.length - 2];
};

export const LOCALSTORAGE_STOCK_PURCHASE = "stockPurchaseEntry";
const today = new Date().toISOString();

const updateStockTransaction = (entry, relatedTrxItem, updatedStockItems, transactionType) => ({
  ...entry,
  transactionType,
  stockItems: updatedStockItems,
  supplierId: relatedTrxItem.supplier?.id || relatedTrxItem.supplierId || null,
  supplierInvoiceId: relatedTrxItem.supplierInvoiceId,
  summary: {
    ...entry.summary,
    roundOffAmt: relatedTrxItem?.summary?.roundOffAmt || "",
    discountAmt: relatedTrxItem?.summary?.discountAmt || 0,
    totalVatAmtOfFreeItems: relatedTrxItem?.summary?.totalVatAmtOfFreeItems,
    totalDiscountPct: relatedTrxItem?.summary?.totalDiscountPct || 0
  },
  settings: relatedTrxItem?.settings || {
    discountSettings: defaultDiscountSettings
  },
  paymentType: relatedTrxItem.paymentType || paymentOptionsEnum.credit,
  status: relatedTrxItem.status || Status.Draft,
  paidAll: relatedTrxItem.paidAmount === relatedTrxItem.totalAmount,
  paidAmount: relatedTrxItem.paidAmount,
  supplierLedgerId: relatedTrxItem?.supplier?.ledgerId,
  voucherDate:
    transactionType === TransactionType.PURCHASE
      ? relatedTrxItem?.voucherDate || relatedTrxItem.date
      : today,
  date: transactionType === TransactionType.PURCHASE ? relatedTrxItem.date : today,
  referenceTo: relatedTrxItem.referenceTo
});

const PurchaseEntry = ({
  actions,
  match,
  location,
  entry,
  suppliers,
  stockTransactions,
  stockProducts,
  mode,
  stockAdminSettings,
  billStock,
  kitchenPharmacyBillId = null
}: PurchaseEntryProps) => {
  const [errors, setErrors] = React.useState<ErrorState>({ entryErrors: [] });
  const [showDialog, setShowDialog] = React.useState(false);
  const dispatch = useDispatch();
  const [showRowRemovedMsg, setShowRowRemovedMsg] = React.useState<boolean>(false);

  const currentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );
  const [stocks, setStocks] = useState<Stock[]>([]);
  const [isStockFetched, setIsStockFetched] = useState(false);
  let selectedProductId = useSelector(
    (state: RootState) => state.stock.entry?.stockItems.map((item) => item.productId) || []
  );
  if (entry.transactionType === TransactionType.ADJUSTMENT) {
    selectedProductId = match.params.id;
  }

  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);

  const unsavedEntry = React.useRef(null);
  React.useEffect(() => {
    actions.applyEntry(initialEntryState, stockAdminSettings || defaultDiscountSettings);
    const incompleteEntry = JSON.parse(localStorage.getItem(LOCALSTORAGE_STOCK_PURCHASE));
    if (incompleteEntry && mode === Mode.Create) {
      unsavedEntry.current = incompleteEntry;
      setShowDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!currentSubscription && rcId) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [rcId, currentSubscription, dispatch]);

  React.useEffect(() => {
    (async () => {
      if (
        mode !== Mode.Create &&
        selectedProductId.length &&
        !isStockFetched &&
        [
          TransactionType.ADJUSTMENT,
          TransactionType.OPENING_STOCK_ADJUSTMENT,
          TransactionType.PURCHASE_RETURN
        ].includes(entry.transactionType)
      ) {
        setIsStockFetched(true);
        const res = await getStockProductByIds(selectedProductId);
        setStocks(res);
      }
    })();
  }, [selectedProductId]);

  React.useEffect(() => {
    if (!suppliers.length) {
      actions.loadSuppliers();
    }
    if (!stockTransactions.length) {
      actions.loadStockTransactions(initialQuery);
    }
    if (!stockProducts.length) {
      actions.loadStockProducts({ ...initialQuery, intangible: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);
  const isKitchenPharmacyEntry = Boolean(billStock?.length);
  React.useEffect(() => {
    if (isKitchenPharmacyEntry) {
      const kitchenPharmacySupplier = suppliers.find((supplier) => !!supplier.isKitchenPharmacy);
      dispatch(
        updateEntry(
          updatesNo({
            ...entry,
            supplierId: kitchenPharmacySupplier?.id || null,
            supplierLedgerId: kitchenPharmacySupplier?.ledgerId || null,
            stockItems: billStock,
            currentStockItem: getTemplateStockItem(),
            kitchenPharmacyBillId
          })
        )
      );
    }
  }, [billStock, dispatch, suppliers, isKitchenPharmacyEntry]);

  React.useEffect(() => {
    setErrors(getErrorState(entry, stocks));
  }, [entry, stocks]);

  React.useEffect(() => {
    if (mode === Mode.Create && Boolean(entry.supplierId)) {
      localStorage.setItem(
        LOCALSTORAGE_STOCK_PURCHASE,

        JSON.stringify({
          entry: { ...entry, currentStockItem: getTemplateStockItem() },
          supplierName: suppliers.find((supplier) => supplier.id === entry.supplierId)?.name
        })
      );
    }
  }, [mode, entry, suppliers]);

  React.useEffect(() => {
    if (match.params.id && stockTransactions.length) {
      const toGetProductIds = [];
      const relatedTrxItem = stockTransactions.find(
        (trxItem) => trxItem.id === Number(match.params.id)
      );
      if (relatedTrxItem?.stockTransactionItems?.length > 0) {
        const reduxStockProducts = store.getState().stockProducts.collection;
        relatedTrxItem.stockTransactionItems.forEach((item) => {
          const reduxContainsProduct = reduxStockProducts.find((p) => p.id === item.productId);
          if (!reduxContainsProduct) {
            toGetProductIds.push(item.productId);
          }
        });
      }
      if (toGetProductIds) {
        dispatch(stockProductActions.getStockProductsByIds(toGetProductIds));
      }
    }
  }, [actions, match.params.id, stockTransactions, dispatch]);

  const formatFoundStocks = (stocksProduct, product) =>
    stocksProduct.map((item, i) => ({
      batchId: item.batchId,
      calculationBasis: "perUnit",
      expiryDate: item.expiryDate,
      grossTotal: 0,
      package: product?.package || "Box",
      packagePriceExcVAT: 0,
      packagePriceIncVAT: 0,
      price: 0,
      productId: item.productId,
      quantity: Number(item.quantity),
      enteredQuantityUnit: "unit",
      productName: product?.name || "",
      sNo: i,
      unit: product?.unit || "Pcs.",
      unitPriceExcVAT: 0,
      unitPriceIncVAT: 0,
      unitsPerPackage: product?.unitsPerPackage || 0,
      vatPct: 0,
      discountPct: 0,
      salesPriceCalculationOn: product?.unit,
      freeItemQuantity: 0,
      hasFreeItems: false,
      carryingChargePct: 0,
      carryingChargeAmt: 0,
      carryingChargePctOfFreeItems: 0,
      carryingChargeAmtOfFreeItems: 0,
      rateOfFreeItems: 0,
      netTotalOfFreeItems: 0,
      discountPctOfFreeItems: 0,
      discountAmtOfFreeItems: 0,
      vatPctOfFreeItems: 0,
      vatAmtOfFreeItems: 0,
      grossTotalOfFreeItems: 0,
      grossTotalIncVatOfFreeItems: 0,
      discountAmt: 0,
      netTotal: 0,
      purchasePricePerUnit: 0,
      purchasePricePerPackage: 0,
      salesVatPct: 0,
      currency: Currency.Rs
    }));

  const calculateQuantity = (
    enteredQuantityUnit,
    hasFreeItems,
    quantity,
    unitsPerPackage,
    freeItemQuantity
  ) => {
    if (enteredQuantityUnit === "package") {
      return hasFreeItems
        ? quantity / unitsPerPackage - freeItemQuantity
        : quantity / unitsPerPackage;
    }
    if (hasFreeItems) return quantity - freeItemQuantity;
    return quantity;
  };

  function formatTransactionItems(trxItems, stkProducts) {
    return trxItems.map((item: StockItemInterface, i) => {
      const relatedProduct = stkProducts.find((product) => product.id === item.productId);
      return {
        batchId: item.batchId,
        calculationBasis: "perUnit",
        expiryDate: item.expiryDate,
        grossTotal: item.grossTotal,
        package: relatedProduct?.package || item.package || getPackageName(stocks, item),
        packagePriceExcVAT: item.packagePriceExcVAT,
        packagePriceIncVAT: item.packagePriceIncVAT,
        price: item.purchasePrice,
        productId: item.productId,
        productName: relatedProduct?.name || item.productName || "",
        quantity: calculateQuantity(
          item.enteredQuantityUnit,
          item.hasFreeItems,
          item.quantity,
          item.unitsPerPackage,
          item.freeItemQuantity
        ),
        sNo: i,
        unit: item.unit,
        enteredQuantityUnit: item.enteredQuantityUnit,
        unitPriceExcVAT: item.unitPriceExcVAT || 0,
        unitPriceIncVAT: item.unitPriceIncVAT || 0,
        unitsPerPackage: item.unitsPerPackage || 0,
        vatPct: item.vatPct,
        discountPct: item.discountPct,
        salesPriceCalculationOn: item.salesPriceCalculationOn,
        freeItemQuantity: item?.freeItemQuantity || 0,
        hasFreeItems: item?.hasFreeItems || false,
        carryingChargePct: item.carryingChargePct || 0,
        carryingChargeAmt: item.carryingChargeAmt || 0,
        carryingChargePctOfFreeItems: item.carryingChargePctOfFreeItems || 0,
        carryingChargeAmtOfFreeItems: item.carryingChargeAmtOfFreeItems || 0,
        rateOfFreeItems: item.rateOfFreeItems || 0,
        netTotalOfFreeItems: item.netTotalOfFreeItems || 0,
        discountPctOfFreeItems: item.discountPctOfFreeItems || 0,
        discountAmtOfFreeItems: item.discountAmtOfFreeItems || 0,
        vatPctOfFreeItems: item.vatPctOfFreeItems || 0,
        vatAmtOfFreeItems: item.vatAmtOfFreeItems || 0,
        grossTotalOfFreeItems: item.grossTotalOfFreeItems || 0,
        grossTotalIncVatOfFreeItems: item.grossTotalIncVatOfFreeItems || 0,
        discountAmt: item.discountAmt || 0,
        netTotal: item.netTotal || Number(item.quantity) * Number(item.purchasePrice),
        purchasePricePerUnit: Number(item.purchasePrice) || 0,
        purchasePricePerPackage:
          Number(item.purchasePricePerPackage) ||
          round(Number(item.purchasePrice) * Number(item.unitsPerPackage), 2),
        salesVatPct: Number(item.salesVatPct) || 0,
        currency: Currency.Rs,
        purchaseLedgerId: item.purchaseLedgerId,
        purchaseTaxationId: item.purchaseTaxationId,
        productType: item.productType || item?.productDetails?.productType,
        internalExpenseLedgerId: item.internalExpenseLedgerId
      };
    });
  }

  React.useEffect(() => {
    if (match.params.id) {
      const path = location.pathname.split("/");
      const transactionType = getTransactionType(path, mode);
      if (path[path.length - 2] === TransactionType.ADJUSTMENT) {
        const foundStocks = stocks.filter((item) => item.productId === Number(match.params.id));
        const product = stockProducts.find((item) => item.id === Number(foundStocks[0]?.productId));
        if (!product && foundStocks[0]?.productId) {
          actions.getProductById(foundStocks[0]?.productId);
        }
        actions.updateEntry({
          ...entry,
          id: Number(match.params.id),
          stockItems: formatFoundStocks(foundStocks, product),
          transactionType: TransactionType.ADJUSTMENT,
          supplierId: null,
          supplierInvoiceId: null,
          summary: {
            ...entry.summary,
            roundOffAmt: "",
            discountAmt: 0,
            totalVatAmtOfFreeItems: 0,
            totalDiscountPct: 0
          },
          settings: {
            discountSettings: defaultDiscountSettings
          },
          voucherDate: new Date().toISOString(),
          date: new Date().toISOString()
        });
      } else if (transactionType !== TransactionType.PURCHASE_RETURN) {
        const relatedTrxItem = stockTransactions.find(
          (trxItem) => trxItem.id === Number(match.params.id)
        );
        if (relatedTrxItem) {
          const updatedStockItems = formatTransactionItems(
            relatedTrxItem.stockTransactionItems,
            stockProducts
          );
          const updatedEntry = updateStockTransaction(
            entry,
            relatedTrxItem,
            updatedStockItems,
            transactionType
          );
          actions.updateEntry({ ...updatedEntry, id: Number(match.params.id) });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, stockTransactions, stockProducts, suppliers, stocks]);

  const updateStockEntry = async (transactionId: number, transactionType) => {
    const response = await getReturnedRelatedTransactions(transactionId);
    const relatedTrxItem = response.find((item) => item.id === transactionId);
    /*
      Possible to create multiple purchaseReturn
      if purchase return for the current transaction have been already done
      then fetch all the related returnedTransactions and reduce the returned quantity.
      If the reduced quantity become 0, remove it from the entry.
    */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const returnedQuantity = getReturnedQuantity(response, transactionId);
    const updatedStockItems = formatTransactionItems(
      relatedTrxItem?.stockTransactionItems,
      stockProducts
    );
    const preparedEntryData: Entry = updateStockTransaction(
      entry,
      relatedTrxItem,
      updatedStockItems,
      transactionType
    );

    const updatedData = produce(preparedEntryData, (draft) => {
      draft.id = transactionId;
      Object.keys(returnedQuantity).forEach((key) => {
        const [batchId, productId] = key.split("-");
        const index = draft?.stockItems?.findIndex(
          (item) => item.productId === +productId && item.batchId === batchId
        );
        if (index !== -1) {
          draft.stockItems[index].quantity -= returnedQuantity[key];
          draft.stockItems[index].netTotal = round(
            draft.stockItems[index].quantity * draft.stockItems[index].price || 0,
            2
          );
        }
      });
      draft.stockItems = draft.stockItems.filter((item) => Number(item.quantity));
    });
    if (preparedEntryData.stockItems.length > updatedData.stockItems.length) {
      setShowRowRemovedMsg(true);
    }
    actions.updateEntry({
      ...updatedData,
      id: Number(match.params.id),
      supplierLedgerId: suppliers?.find((supplier) => supplier.id === updatedData.supplierId)
        ?.ledgerId
    });
  };

  React.useEffect(() => {
    const transactionId = Number(match.params.id);
    if (transactionId) {
      const path = location.pathname.split("/");
      const transactionType = getTransactionType(path, mode);
      if (transactionType === TransactionType.PURCHASE_RETURN) {
        updateStockEntry(transactionId, transactionType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, suppliers, stocks]);

  return (
    <Can
      policyAccessKey={
        entry.transactionType === TransactionType.ADJUSTMENT
          ? "stock:quantityAdjustment"
          : "stock:enterPurchaseEntry"
      }
    >
      {showDialog && !isKitchenPharmacyEntry && (
        <OkhatiDialog
          title={tl("stock.recoverUnsavedPurchaseEntry")}
          description={`Unsaved stock purchase entry found for supplier ${unsavedEntry.current.supplierName}. Do you want to recover it?`}
          next={() => {
            actions.updateEntry(unsavedEntry.current.entry);

            setShowDialog(false);
          }}
          cancel={() => {
            localStorage.removeItem(LOCALSTORAGE_STOCK_PURCHASE);

            setShowDialog(false);
          }}
          readMode={false}
        />
      )}
      <Box>
        <Box height="calc(100vh - 100px)" overflow="auto" width="100%">
          <StockEntryItems
            stocks={stocks}
            setStocks={setStocks}
            errors={errors}
            supplierOptions={suppliers}
            updateEntry={actions.updateEntry}
            entry={entry}
            onRowRemove={actions.onRowRemove}
            setShowRowRemovedMsg={() => setShowRowRemovedMsg(false)}
            showRowRemovedMsg={showRowRemovedMsg}
          />
          {![TransactionType.ADJUSTMENT, TransactionType.KITCHEN_PHARMACY_PURCHASE].includes(
            entry.transactionType as TransactionType
          ) && <StockEntrySummary updateEntry={actions.updateEntry} entry={entry} />}
        </Box>
        <StockEntryActions entry={entry} errors={errors} />
      </Box>
    </Can>
  );
};

PurchaseEntry.defaultProps = {
  mode: undefined
};

export default connect(
  (state: RootState) => ({
    entry: state.stock.entry,
    suppliers: suppliersSortedSelector(state),
    stockTransactions: state.stock.stockTransactions,
    stockProducts: state.stockProducts.collection,
    stockAdminSettings:
      state.resources?.resourceCentres[0]?.settings?.stockSettings?.discountSettings
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      updateEntry: (entry) => {
        dispatch(stockActions.updateEntry(entry));
      },
      applyEntry: (entry, stockAdminSettings) => {
        const stockItems = [];
        const path = window.location.pathname.split("/");
        const transactionType = path.pop();
        const newEntry = {
          ...entry,
          summary: { ...entry.summary, roundOffAmt: "" },
          stockItems,
          transactionType,
          supplierInvoiceId: "",
          settings: { discountSettings: stockAdminSettings },
          supplierId: null
        };
        if (
          transactionType === "purchase" &&
          entry.transactionType === TransactionType.PURCHASE &&
          entry?.stockItems
        ) {
          dispatch(stockActions.updateEntry(entry));
        } else {
          dispatch(stockActions.updateEntry(newEntry as unknown as Entry));
        }
      },
      onItemAdd: () => dispatch(stockActions.addNewRow()),
      onRowRemove: (idx) => dispatch(stockActions.removeRow(idx)),
      loadSuppliers: () => dispatch(supplierActions.getSuppliers()),
      loadStocksByProductIds: (productIds: number[]) =>
        dispatch(stockActions.getStocksByProductIds(productIds)),
      loadStockTransactions: (query) => dispatch(stockActions.getStockTransactions(query)),
      loadStockProducts: (query) => dispatch(stockProductActions.getStockProducts(query)),
      updateSetting: (setting) => dispatch(stockActions.updateStockSettings(setting)),
      getProductById: (id) => dispatch(getStockProductById(id))
    }
  })
)(PurchaseEntry);

/* eslint-disable no-param-reassign */
import { abs, round } from "mathjs";
import { rupeeDisplay } from "../../../helpers/rupee";
import {
  ChildGeneralLedgers,
  ProfitAndLossAccountingGroups,
  ProfitLossInterface,
  TrialBalanceInterface
} from "../../../interfaces/AccountReportsInterface";
import { getAllChildGl } from "../hooks";

enum PrimaryLedgerNamesToBeMultiplyByMinus1 {
  EQUITY_LIABILITIES = "Equity & Liabilities",
  INCOME = "Incomes"
}

export const displayAmountByLedgerGroup = (code: string, balance: number): string =>
  code === PrimaryLedgerNamesToBeMultiplyByMinus1.EQUITY_LIABILITIES ||
  code === PrimaryLedgerNamesToBeMultiplyByMinus1.INCOME
    ? rupeeDisplay(round(balance || 0, 2) * -1)
    : rupeeDisplay(round(balance || 0, 2));

export const formatDataToDownload = (data: TrialBalanceInterface[]): ChildGeneralLedgers[] => {
  const extractedLedgerRows: ChildGeneralLedgers[] = data.reduce((ledgerRows, item) => {
    const rows = getAllChildGl(item, true);
    return ledgerRows.concat(rows);
  }, []);
  const { debitTotal, creditTotal } = extractedLedgerRows.reduce(
    (prev, curr) => {
      if (curr.debit) {
        prev.debitTotal += abs(curr.debit);
      }
      if (curr.credit) {
        prev.creditTotal += abs(curr.credit);
      }
      return prev;
    },
    { debitTotal: 0, creditTotal: 0 }
  );
  return [
    ...extractedLedgerRows,
    { ledgerName: "Total", debit: debitTotal, credit: creditTotal } as ChildGeneralLedgers
  ];
};

export const getAmountByLedgerGroup = (code: string, balance: number): number =>
  code === PrimaryLedgerNamesToBeMultiplyByMinus1.EQUITY_LIABILITIES ||
  code === PrimaryLedgerNamesToBeMultiplyByMinus1.INCOME
    ? round(balance || 0, 2) * -1
    : round(balance || 0, 2);

export const formatDataForProfitAndLoss = (
  data: ProfitLossInterface[]
): ProfitAndLossAccountingGroups[] =>
  data.reduce((ledgerGroups, item) => {
    let balanceTotal = 0;
    let totalPreviousBalance = 0;
    item.secondaryLedgers.forEach((secondaryLedger) => {
      secondaryLedger.accountingGroups.forEach((groupItem) => {
        const ledgerGroup = {
          ...groupItem,
          balance: getAmountByLedgerGroup(item.primaryLedgerName, groupItem.balance),
          previousYearBalance: getAmountByLedgerGroup(
            item.primaryLedgerName,
            groupItem.previousYearBalance
          )
        };
        ledgerGroups.push(ledgerGroup);
        balanceTotal += ledgerGroup.balance || 0;
        totalPreviousBalance += ledgerGroup.previousYearBalance || 0;
      });
    });
    ledgerGroups.push({
      code: "",
      accountingGroupName: "Total",
      balance: balanceTotal,
      previousYearBalance: totalPreviousBalance
    } as ProfitAndLossAccountingGroups);

    return ledgerGroups;
  }, [] as ProfitAndLossAccountingGroups[]);

export const formatHeaderForExcel = (columns: string[]): { value: string }[] =>
  columns.map((column) => ({ value: column }));

export const formatDataForExcel = (
  rows: unknown[],
  docRowProcessor: (arg: any) => any
): {
  value: string | number | boolean;
}[][] =>
  rows
    .map((row) => docRowProcessor(row)?.map((value) => ({ value })))
    .filter((val) => val !== undefined);

import { createSelector } from 'reselect';
import startCase from 'lodash/startCase';

//selectors
export const employeeFullNameSelector = (serviceProvider = { firstName: '', lastName: '' }) => {
  const { firstName, lastName } = serviceProvider;
  return `${startCase(firstName)} ${startCase(lastName)}`;
};

export const employeesSelector = (state) => state.resources.resourceCentreEmployees;

export const activeEmployees = createSelector(employeesSelector, (employees) =>
  employees.filter(({ active }) => active),
);

export const employeesSortedSelector = createSelector(activeEmployees, (employees) =>
  employees.sort((a, b) =>
    `${a.firstName || ''}${a.lastName || ''}`.toLowerCase() >
    `${b.firstName || ''}${b.lastName || ''}`.toLowerCase()
      ? 1
      : -1,
  ),
);

import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Checkbox, Chip, TextField, Typography } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import VideocamIcon from "@mui/icons-material/Videocam";
import { push } from "connected-react-router";
import PencilIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UndoIcon from "@mui/icons-material/Undo";
import RadioIcon from "@mui/icons-material/Radio";
import OkIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesIcon from "@mui/icons-material/Notes";
import EventNoteIcon from "@mui/icons-material/EventNote";
import moment from "moment";
import BlockIcon from "@mui/icons-material/Block";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { CheckIcon } from "@primer/octicons-react";
import { AccountTree } from "@mui/icons-material";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import { tl } from "../../../components/translate";
import ActionBar from "../../../components/ActionBar/ActionBar";
import BookingStatus from "./BookingStatus";
import { slotEndTimeSelector, slotStartTimeSelector } from "../../../reducers/slots";
import HandleBooking from "./HandleBooking";
import ServiceProviderWithAvatar from "../../../components/ServiceProviderWithAvatar";
import IconWithField from "../../../components/IconWithField";
import { getVideoUrl } from "../../../api/video";
import styles from "./styles.module.css";
import { navigateRemoveModal, showDialog } from "../../../actions/navigation";
import * as bookingActions from "../../../actions/booking";
import { updateBooking as updateBookingAction } from "../../../actions/booking";
import HistoryItems from "../../History/HistoryItems";
import ClickAwayTrigger from "../../../components/ClickAwayTrigger/ClickAwayTrigger";
import * as NotificationActions from "../../../actions/notification";
import { isResourceCentreUser } from "../../NavigationBar/SideBar";
import { clientFullNameSelector } from "../../../reducers/client";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../../Client/ClientCreateEdit";
import { serviceProvidersSortedSelector } from "../../../reducers/serviceProvider";
import { CanActions } from "../../../interfaces/policy";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { IThunkDispatch, RootState } from "../../../store";
import { UserMode } from "../../../interfaces/User";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../../mixpanel-analytics/event";
import useGetDepartments from "../../../hooks/department";

interface BookingInfoProps {
  booking: Booking & { resourceCentre: ResourceCentre };
  onClose: () => void;
  onEdit: (bookingId) => void;
  onCancel: (bookingId, onClose) => void;
  resourceCentre: ResourceCentre;
  serviceProvider: ServiceProvider;
  services: Array<ProductInterface>;
  updateBooking: (bookingId, v) => void;
  onDelete: (bookingId, onClose) => void;
  onLinkCopied: () => void;
  userMode: string;
  disableEdit?: boolean;
  fromSuperAdmin?: boolean;
  onShowLab?: () => void;
}

const BookingInfo: React.FC<BookingInfoProps> = ({
  booking,
  onClose,
  onEdit,
  onCancel,
  resourceCentre: rc,
  serviceProvider: sp,
  services,
  updateBooking,
  onDelete,
  onLinkCopied,
  userMode,
  disableEdit,
  fromSuperAdmin,
  onShowLab
}) => {
  const [filteredServices, setFilteredServices] = React.useState([]);
  const [videoUrl, setVideoUrl] = React.useState("");
  const [showHistory, setShowHistory] = React.useState(false);
  const [bookingRemarks, setBookingRemarks] = React.useState(booking && booking.remarks);
  React.useEffect(() => setBookingRemarks(booking.remarks), [booking.remarks]);
  const serviceProvider = sp || booking.serviceProvider;
  const resourceCentre = rc || booking.resourceCentre;
  const departments = useGetDepartments();

  const dispatch = useDispatch();

  React.useEffect(() => {
    const fS = [];
    if (services && booking.services) {
      booking.services.map((bS) => {
        services.map((service) => {
          if (Number(service.id) === Number(bS)) {
            fS.push(service);
          }
          return null;
        });
        return null;
      });
    }
    setFilteredServices(fS);
  }, [services, booking.services]);

  const awaitedClose = () => {
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const actions: Array<{
    label: string | React.ReactNode;
    action: () => void;
    Icon: unknown;
    dataTestmation: string;
    name: string;
    accessKey: CanActions | "allow";
  }> = [
    {
      label: tl("booking.activitiesHistory"),
      action: () => setShowHistory(!showHistory),
      Icon: AssignmentIcon,
      dataTestmation: "activitiesHistory",
      name: "activities",
      accessKey: "allow"
    },
    {
      label: tl("booking.cancelBooking"),
      action: () => {
        mixpanelAnalytics.track(EVENT.BOOKING_STATUS_CANCELED, { rcId: resourceCentre.id });
        onCancel(booking.id, onClose);
      },
      Icon: BlockIcon,
      dataTestmation: "cancelBooking",
      name: "cancel",
      accessKey: "booking:cancelBooking"
    },
    {
      label: tl("booking.editBooking"),
      action: () => onEdit(booking.id),
      Icon: PencilIcon,
      dataTestmation: "editBooking",
      name: "edit",
      accessKey: "booking:updateBooking"
    },
    {
      label: tl("booking.deleteBooking"),
      action: () => {
        mixpanelAnalytics.track(EVENT.BOOKING_STATUS_DELETED, { rcId: resourceCentre.id });
        onDelete(booking.id, onClose);
      },
      Icon: DeleteIcon,
      dataTestmation: "deleteBooking",
      name: "delete",
      accessKey: "booking:deleteBooking"
    }
  ];

  const getBookingActions = (actionsP, status) => {
    if (status === "cancelled") {
      const actionsForBooking = actionsP.filter((item) => item.name !== "cancel");
      return actionsForBooking;
    }
    const actionsForBooking = actionsP.filter((item) => item.name !== "delete");
    return actionsForBooking;
  };

  const [showClientInfoPanel, setClientInfoPanel] = React.useState(false);
  const handleViewClose = () => {
    setClientInfoPanel(false);
  };
  const [showActions, setShowActions] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);

  return (
    <Box>
      <Box data-testmation="bookingInfo" p={2} className={styles.bookingInfoContainer}>
        <Box className={styles.bookingBodyContainer}>
          {isResourceCentreUser(userMode as UserMode) && (
            <Box pb={2}>
              <BookingStatus booking={booking} hideLabel />
              <Typography component="span" style={{ marginLeft: "16px", fontSize: "0.875rem" }}>
                <Box component="span" fontWeight="700">
                  ID
                </Box>{" "}
                {booking.id}
              </Typography>
              {booking.throughPublic && (
                <Typography style={{ display: "inline-block" }}>
                  <Box ml="16px" component="span" fontSize="0.675rem" fontWeight={500}>
                    Public Booking
                  </Box>
                </Typography>
              )}
              <ActionBar
                actions={getBookingActions(actions, booking.status)}
                onClose={onClose}
                disableEdit={disableEdit}
              />
            </Box>
          )}
          <Box minHeight="300px" pb="16px">
            {booking.bookableResourceId && (
              <Box my="16px" pl="4px">
                <IconWithField LabelIcon={<AddBoxIcon />}>
                  <Typography style={{ fontSize: "14px", paddingLeft: "2px" }}>
                    {booking.bookableResource.name}
                  </Typography>
                </IconWithField>
              </Box>
            )}
            <ServiceProviderWithAvatar
              serviceProvider={serviceProvider}
              onServiceProviderChange={() => ({})}
            />
            {booking.departmentId && (
              <IconWithField LabelIcon={<AccountTree />}>
                <Typography style={{ fontSize: "12px" }}>
                  {departments.filter((item) => item.id === booking.departmentId)[0]?.name || ""}
                </Typography>
              </IconWithField>
            )}

            <Box pt={1} />
            <Box>
              <IconWithField LabelIcon={<ScheduleIcon />}>
                <Box width="100%" display="flex">
                  <Box marginRight={2}>
                    <Typography style={{ fontSize: "12px" }}>
                      {calFns.convertADtoBS(new Date(booking.start)).formatted4}
                    </Typography>
                  </Box>
                  <Typography style={{ fontSize: "12px" }}>
                    {slotStartTimeSelector(booking)} - {slotEndTimeSelector(booking)}
                  </Typography>
                </Box>
              </IconWithField>

              <IconWithField LabelIcon={<LocationOnIcon />}>
                <Typography style={{ fontSize: "12px" }}>
                  {resourceCentre &&
                    `${resourceCentre.name} - ${resourceCentre.address}, ${resourceCentre.city}`}
                </Typography>
              </IconWithField>
            </Box>

            <Box mt="16px">
              <IconWithField LabelIcon={<PersonIcon />}>
                <Typography
                  style={{
                    textDecoration: `${booking.client.active ? "underline" : ""}`,
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (booking.client.active) {
                      setClientInfoPanel(true);
                    }
                  }}
                >
                  {`${clientFullNameSelector(booking.client).toUpperCase()} ${
                    !booking.client.active ? "(deleted)" : ""
                  }`}
                </Typography>
              </IconWithField>
              <IconWithField LabelIcon={<PhoneIcon />}>
                <Typography sx={{ fontSize: "12px" }}>{booking.client?.phone}</Typography>
              </IconWithField>
              <IconWithField LabelIcon={<LocationOnIcon />}>
                {booking.client?.address ? (
                  <Typography sx={{ fontSize: "12px" }}>{booking.client.address}</Typography>
                ) : (
                  <Typography sx={{ fontSize: "12px", color: "lightgray" }}>
                    No address added
                  </Typography>
                )}
              </IconWithField>
            </Box>

            <Box mt="16px">
              {resourceCentre?.subscriptions?.features?.video?.subscribed && (
                <IconWithField LabelIcon={<VideocamIcon />}>
                  <Box display="flex" alignItems="center">
                    <Typography style={{ fontSize: "12px" }}>
                      {tl("booking.isVideoAppointment")}
                    </Typography>
                    <Checkbox
                      disabled
                      inputProps={{ "aria-label": "disabled checkbox" }}
                      checked={booking.isVideoAppointment}
                    />
                    {booking.isVideoAppointment && (
                      <Box
                        data-testmation="getVideoUrl"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getVideoUrl(booking.id).then((url) => {
                            setVideoUrl(url as string);
                          });
                        }}
                      >
                        {videoUrl ? (
                          <Box
                            onClick={() => {
                              navigator.clipboard.writeText(videoUrl);
                              onLinkCopied();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography style={{ fontSize: "12px" }} data-testmation="videoUrl">
                              <u>({videoUrl})</u>
                            </Typography>
                          </Box>
                        ) : (
                          <Typography style={{ fontSize: "12px" }} data-testmation="videoUrl">
                            <u>({tl("booking.showClientsVideoLink")})</u>
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </IconWithField>
              )}
              <Box paddingTop="8px" />
              <IconWithField
                LabelIcon={<CardGiftcardIcon />}
                style={{
                  display: "flex",
                  minHeight: "18px"
                }}
              >
                <>
                  {filteredServices.length ? (
                    filteredServices.map((service, index) => (
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: service.active ? "inherit" : "#DB2323"
                        }}
                        key={service.id}
                      >
                        {`${index ? ", " : ""} ${service.name}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography style={{ fontSize: "12px", color: "lightgray" }}>
                      {tl("bookings.noServicesSelected")}
                    </Typography>
                  )}
                  {booking.extra?.labRecordId && (
                    <Typography
                      onClick={() => {
                        if (fromSuperAdmin && onShowLab) {
                          onShowLab();
                        } else {
                          dispatch(
                            push(`/lab/labRecords/${booking.extra.labRecordId}?status=Ordered`)
                          );
                        }
                      }}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      fontSize="10px"
                      ml="16px"
                      mt="2px"
                    >
                      View created Lab
                    </Typography>
                  )}
                </>
                {booking.paymentInfo?.isPaid && (
                  <Chip
                    size="small"
                    style={{
                      backgroundColor: "#009654",
                      color: "#fff",
                      marginLeft: "16px"
                    }}
                    label={
                      <>
                        <CheckIcon /> {"  "} Rs {booking.paymentInfo?.amount} {"  "} Paid
                      </>
                    }
                  />
                )}
              </IconWithField>
              {booking.followUp && booking.reminders && booking.reminders.length > 0 && (
                <Box mt="12px">
                  <IconWithField
                    LabelIcon={<EventNoteIcon />}
                    style={{
                      display: "flex",
                      height: "18px"
                    }}
                    iconStyle={{
                      alignItems: "flex-start"
                    }}
                  >
                    <Typography style={{ fontSize: "12px" }}>
                      {booking.reminders[0] &&
                        `Follow up on: ${
                          calFns.convertADtoBS(moment(booking.reminders[0].on)).formatted3
                        }, ${booking.reminders[0].remark}`}
                    </Typography>
                  </IconWithField>
                </Box>
              )}
              <IconWithField
                LabelIcon={<NotesIcon />}
                style={{
                  display: "flex",
                  minHeight: "24px"
                }}
                iconStyle={{
                  alignItems: "flex-start",
                  paddingTop: "8px"
                }}
              >
                <Box width="100%">
                  <ClickAwayTrigger
                    onClickAway={() => {
                      setShowActions(false);
                    }}
                  >
                    <Box
                      width="100%"
                      pt={bookingRemarks?.length > 0 ? "0px" : "4px"}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <TextField
                        disabled={!isResourceCentreUser(userMode as UserMode)}
                        data-testmation="bookingRemarks"
                        multiline
                        fullWidth
                        maxRows={4}
                        value={bookingRemarks}
                        placeholder="Add note"
                        margin="dense"
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            padding: 0,
                            fontSize: "12px"
                          }
                        }}
                        onMouseDown={() => setShowActions(true)}
                        onChange={(e) => setBookingRemarks(e.target.value)}
                        onBlur={() => {
                          updateBooking(booking.id, {
                            remarks: bookingRemarks
                          });
                          setShowActions(false);
                        }}
                      />
                      {showActions && isResourceCentreUser(userMode as UserMode) && (
                        <Box display="flex" pt="4px" pl="4px">
                          <OkIcon
                            style={{ marginRight: "8px", cursor: "pointer" }}
                            onClick={() => {
                              updateBooking(booking.id, {
                                remarks: bookingRemarks
                              });
                              setShowActions(false);
                            }}
                            data-testmation="bookingRemarksOk"
                          />
                          <CancelIcon
                            style={{ marginRight: "8px", cursor: "pointer" }}
                            onClick={() => {
                              setBookingRemarks(booking.remarks);
                              setShowActions(false);
                            }}
                            data-testmation="bookingRemarksCancel"
                          />
                        </Box>
                      )}
                    </Box>
                  </ClickAwayTrigger>
                </Box>
              </IconWithField>

              {booking.referredBy && (
                <Box mt="4px">
                  <IconWithField
                    LabelIcon={<UndoIcon />}
                    style={{
                      display: "flex",
                      minHeight: "24px",
                      alignItems: "center"
                    }}
                    iconStyle={{
                      alignItems: "flex-start"
                    }}
                  >
                    <Box>
                      <Typography style={{ fontSize: "12px" }}>{booking.referredBy}</Typography>
                    </Box>
                  </IconWithField>
                </Box>
              )}

              {booking?.client?.knownUsFrom && (
                <Box mt="4px">
                  <IconWithField
                    LabelIcon={<RadioIcon />}
                    style={{
                      display: "flex",
                      minHeight: "24px",
                      alignItems: "center"
                    }}
                    iconStyle={{
                      alignItems: "flex-start"
                    }}
                  >
                    <Box>
                      <Typography style={{ fontSize: "12px" }}>
                        {booking?.client?.knownUsFrom}
                      </Typography>
                    </Box>
                  </IconWithField>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {isResourceCentreUser(userMode as UserMode) && (
          <Box>
            <HandleBooking
              booking={booking}
              serviceProvider={serviceProvider}
              onClose={awaitedClose}
            />
          </Box>
        )}
        <Box marginTop="16px">
          {showHistory && <HistoryItems entity="booking" entityId={booking.id} />}
        </Box>
      </Box>
      {showClientInfoPanel &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={() => setClientEditMode(false)}
            clientId={booking.client.id}
            mode="edit"
            stayOnCurrentPage
            onCancel={() => setClientEditMode(false)}
          />
        ) : (
          <ClientInfo
            id={booking.client.id}
            handleViewClose={handleViewClose}
            stayOnCurrentPage
            setEditMode={setClientEditMode}
          />
        ))}
    </Box>
  );
};

BookingInfo.defaultProps = {
  disableEdit: false,
  fromSuperAdmin: false,
  onShowLab: () => ({})
};

export default connect(
  (state: RootState, ownProps: { booking: { serviceProviderId: number } }) => {
    const spId = ownProps.booking.serviceProviderId;
    const { resourceCentre } = state.userContext;
    const serviceProviderInArr = state.resources.resourceCentreServiceProviders.filter(
      (sp) => Number(sp.id) === Number(spId)
    );
    const serviceProvider = serviceProviderInArr[0];
    const services = state.services.collection;
    return {
      resourceCentre,
      serviceProvider,
      services,
      userMode: state.userContext.mode,
      serviceProviders: serviceProvidersSortedSelector(state)
    };
  },
  (dispatch: IThunkDispatch) => ({
    navigateTo: (url) => dispatch(push(url)),
    onCancel: (bookingId, onClose) => {
      dispatch(
        showDialog({
          title: tl("booking.cancel"),
          description: "Are you sure you want to cancel this booking?",
          next: async () => {
            await dispatch(bookingActions.cancelBooking(bookingId));
            dispatch(navigateRemoveModal("Dialog"));
            if (onClose) {
              onClose();
            }
          },
          onCancel: () => ({})
        })
      );
    },
    updateBooking: async (bookingId, updateAttrs) => {
      await dispatch(updateBookingAction(bookingId, updateAttrs));
    },
    onDelete: (bookingId, onClose) => {
      dispatch(
        showDialog({
          title: tl("booking.deleteBooking"),
          description: "Are you sure you want to permanently delete this booking?",
          next: async () => {
            await dispatch(bookingActions.deleteBooking(bookingId));
            dispatch(navigateRemoveModal("Dialog"));
            if (onClose) {
              onClose();
            }
          },
          onCancel: () => ({})
        })
      );
    },
    onLinkCopied: () => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: tl("booking.linkCopied"),
          autoTimeout: true
        })
      );
    }
  })
)(BookingInfo);
